import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import AuthStore from "core/stores/auth/AuthStore";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import { injectIntl, WrappedComponentProps } from "react-intl";
import ProfileStore from "../../my-page/stores/profile/ProfileStore";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { ProfileDocumentType } from "../../my-page/stores/profile/enums";
import TailwindFlex from "library/components/_tailwind/flex";
import FileUpload, {
  FileUploadProcessStatus,
} from "library/components/file-upload";
import { inject, observer } from "mobx-react";
import TailwindButton from "library/components/_tailwind/button";
import TailwindList from "library/components/_tailwind/list";
import TailwindListItem from "library/components/_tailwind/list/list-item";
import TailwindText from "library/components/_tailwind/text";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindParagraph from "library/components/_tailwind/paragraph";
import { ACCEPTED_FILE_TYPES_FOR_ID_CERTIFICATION_FORM } from "./const";

type RegisterCertificationUploadStepProps = {
  authStore?: AuthStore;
  validationStore?: ValidationStore;
  profileStore?: ProfileStore;
};

const RegisterCertificationUploadStep: FunctionComponent<
  RegisterCertificationUploadStepProps & WrappedComponentProps
> = ({ authStore, validationStore, profileStore, intl }) => {
  const { clearErrors, validate, errors } = validationStore!;

  const [
    certificationFormErrorState,
    setCertificationFormErrorState,
  ] = useState<FileUploadProcessStatus>(undefined);

  const [certificationForm, setCertificationForm] = useState<
    string | undefined
  >(undefined);

  const {
    uploadDocument,
    modelProfile,
    //isCams
  } = profileStore!;

  const {
    updateRegistrationFormField,
    completeCertificationFormUploadStep,
  } = authStore!;

  const uploadIDCertificationForm = useCallback(async (files: File[]) => {
    try {
      setCertificationFormErrorState("processing");
      const media = await uploadDocument(
        files,
        ProfileDocumentType.CERTIFICATION_FORM
      );
      updateRegistrationFormField("model_document", {
        name: "document_id_cert_key",
        value: media.key,
      });
      setCertificationForm(media?.view_url);
      validate("document_id_cert_key", media.key);
      setCertificationFormErrorState("accepted");
    } catch (error) {
      setCertificationFormErrorState("rejected");
    }
  }, []);

  const denyReasons = useMemo(() => modelProfile?.deny_reasons || {}, [
    modelProfile,
  ]);
  const hasDenyReason = useMemo(() => !!Object.keys(denyReasons)?.length, [
    denyReasons,
  ]);
  const supportedFileTypes = useMemo(() => {
    const extensions: String[] = [];
    ACCEPTED_FILE_TYPES_FOR_ID_CERTIFICATION_FORM.forEach(type => {
      extensions.push(type.split(/[/|.]/)[1]);
    });
    return extensions;
  }, ACCEPTED_FILE_TYPES_FOR_ID_CERTIFICATION_FORM);

  useEffect(() => clearErrors(), [validationStore]);

  return (
    <TailwindFlex width={"w-192"} md={"w-11/12"} height={"h-full"}>
      <SimpleBar className='RegisterModal__form-scrollarea'>
        <div className='RegisterModal__form'>
          {hasDenyReason && (
            <TailwindList
              padding={["p-5"]}
              backgroundColor={"bg-yellow-100"}
              backgroundOpacity={"bg-opacity-70"}
              margin={["mb-2"]}>
              {Object.keys(denyReasons).map((denyReason, index) => (
                <>
                  <TailwindListItem textColor={"text-red-600"}>
                    <p key={`access-restriction-reason-denial-${index}`}>
                      {index + 1}. {denyReasons?.[denyReason]}
                    </p>
                  </TailwindListItem>
                </>
              ))}
            </TailwindList>
          )}
          <form noValidate autoComplete='false' className={"AuthModal__form"}>
            <div className='RegisterModal__cardTitle'>
              {intl.formatMessage({
                id: "uploadCertificationFormModal.certification",
                defaultMessage: "Certification",
              })}
            </div>
            <TailwindList margin={["mb-4"]}>
              <TailwindListItem listStyleType={"list-decimal"}>
                <TailwindText
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      {
                        id:
                          "uploadCertificationFormModal.printCertificationForm",
                        defaultMessage:
                          'Print the <a href="{href}" class="{class_names}" target="_blank">ID Certification</a> (.PDF) form.',
                      },
                      {
                        href:
                          "https://models.streamray.com/images/cams/models/Streamray_CertGovIssuedID_Indy_Model_090112.pdf",
                        class_names: "font-bold underline",
                      }
                    ),
                  }}
                />
              </TailwindListItem>
              <TailwindListItem listStyleType={"list-decimal"}>
                <TailwindText
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      {
                        id: "uploadCertificationFormModal.completeAndSign",
                        defaultMessage:
                          'Complete and Sign - See <a href="{href}" class="{class_names}" target="_blank">sample</a>',
                      },
                      {
                        href:
                          "https://secureimage.securedataimages.com/images/cams/models/Streamray_CertGovIssuedID_Indy_Model_090112_lrg.png",
                        class_names: "font-bold underline",
                      }
                    ),
                  }}
                />
              </TailwindListItem>
              <TailwindListItem listStyleType={"list-decimal"}>
                <TailwindTranslatedText
                  descriptor={{
                    id: "uploadCertificationFormModal.submitCompletedForm",
                    defaultMessage: "Submit completed and signed form",
                  }}
                />
              </TailwindListItem>
              <TailwindListItem listStyleType={"list-decimal"}>
                <TailwindTranslatedText
                  descriptor={{
                    id:
                      "uploadCertificationFormModal.uploadIDCertificationForm",
                    defaultMessage: "Upload the ID Certification form.",
                  }}
                />
              </TailwindListItem>
            </TailwindList>
            <TailwindFlex flexDirection={"flex-col"} margin={["mb-4"]}>
              <FileUpload
                dropzoneProps={{
                  onDrop: uploadIDCertificationForm,
                  accept: ACCEPTED_FILE_TYPES_FOR_ID_CERTIFICATION_FORM,
                }}
                error={
                  !!errors?.document_id_cert_key ||
                  !!denyReasons["document_id_cert"]
                }
                processStatus={certificationFormErrorState}
                acceptedText={"Photo Uploaded"}
                previewUrl={certificationForm}
              />
            </TailwindFlex>
            <TailwindParagraph margin={["mb-4"]}>
              <TailwindText
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(
                    {
                      id: "uploadCertificationFormModal.supportedFiles",
                      defaultMessage:
                        'File should be <span class="{class_name}">{file_types_list}</span> or <span class="{class_name}">{last_file_type}</span> and no larger than 20MB',
                    },
                    {
                      file_types_list: supportedFileTypes
                        .slice(0, supportedFileTypes.length - 1)
                        .join(", "),
                      last_file_type: supportedFileTypes[
                        supportedFileTypes.length - 1
                      ] as string,
                      class_name: "uppercase",
                    }
                  ),
                }}
              />
            </TailwindParagraph>
            <TailwindParagraph margin={["mb-4"]}>
              <TailwindTranslatedText
                descriptor={{
                  id: "uploadCertificationFormModal.emailInstructions",
                  defaultMessage:
                    "If you don't have access to a printer at home, please go to your closest print shop to print the document. You can either scan and upload form here to submit it or you can take a photo of the form using a webcam. You can also take a photo of the form using the camera on your smartphone or tablet and email it to us along with your mailing address at model_applications@cams.com",
                }}
              />
            </TailwindParagraph>
            <div className={"RegisterModal__submit"}>
              <TailwindButton
                justifyContent={"justify-center"}
                backgroundColor={"primary"}
                fullWidth={false}
                rounded={false}
                disabled={authStore!.isActionInProgress}
                showSpinner={authStore!.isActionInProgress}
                onClick={e => {
                  e.preventDefault();
                  completeCertificationFormUploadStep();
                }}>
                {intl.formatMessage({
                  id: "common.next",
                  defaultMessage: "Next",
                })}
              </TailwindButton>
            </div>
          </form>
        </div>
      </SimpleBar>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject(
    "authStore",
    "validationStore",
    "profileStore"
  )(observer(RegisterCertificationUploadStep))
);
