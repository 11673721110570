import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { inject, observer } from "mobx-react";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import MessageCenterNavigator from "./messages-navigator";
import MessageCenterReader from "./messages-reader";
import MessageStore from "./stores/messages/MessageStore";
import TailwindCard from "library/components/_tailwind/card";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindHeading from "library/components/_tailwind/heading";

import { BreakPoints } from "library/core/stores/layout/types";
import { MessageTabEnum } from "./stores/messages/consts";

type Props = {
  layoutStore?: LayoutStore;
  messageStore?: MessageStore;
};

const NoticesContainer: React.ComponentType<Props> = ({
  layoutStore,
  messageStore,
}) => {
  const { setBodyPadding, isMobileDevice, screenOrientation, windowWidth } =
    layoutStore!;

  const {
    selectedConversations,
    setSelectedConversations,
    getConversations,
    isComposing,
    setIsComposing,
    activeConversation,
    readConversation,
    getUnreadConversations,
    resetCrucialVariblesState,
    activeNewsAndPolicy,
    isSearchingView,
    setIsSearchingView,
    resetActiveConversation,
  } = messageStore!;

  const navigatorContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    resetActiveConversation();
    setBodyPadding(0);
    getConversations();
    getUnreadConversations();

    return () => {
      setBodyPadding(15);
      resetCrucialVariblesState();
    };
  }, []);

  const goBack = useCallback(() => {
    if (isComposing) {
      setIsComposing(!isComposing);
    } else if (isSearchingView) {
      setIsSearchingView(!isSearchingView);
    } else if (
      (selectedConversations && selectedConversations.length > 0) ||
      activeConversation
    ) {
      setSelectedConversations([]);
      readConversation(null);
    }
  }, [selectedConversations, activeConversation, isComposing, isSearchingView]);

  useEffect(() => {
    if (
      isMobileDevice &&
      screenOrientation === "landscape" &&
      windowWidth &&
      windowWidth <= BreakPoints.md &&
      activeNewsAndPolicy &&
      navigatorContainerRef?.current?.parentElement
    ) {
      navigatorContainerRef.current.parentElement.scrollTop = 130;
    }
  }, [activeNewsAndPolicy]);

  const shouldBefullWidth = useMemo(
    () =>
      isMobileDevice && (isSearchingView || isComposing || activeConversation),
    [activeConversation, isComposing, isSearchingView, isMobileDevice]
  );

  return (
    <TailwindCard
      ref={navigatorContainerRef}
      display={"flex"}
      height={"h-full"}
      flexDirection={"flex-col"}
      padding={shouldBefullWidth ? ["p-0"] : ["pt-3"]}
      backgroundColor={
        isMobileDevice ? "bg-quaternary-bg-color" : "bg-primary-bg-color"
      }>
      {(!isMobileDevice || (isMobileDevice && !activeNewsAndPolicy)) && (
        <TailwindFlex
          margin={["m-0"]}
          padding={shouldBefullWidth ? ["p-0"] : ["px-5", "pb-3"]}
          flexDirection={"flex-col"}
          borderWidth={["border-b-2"]}
          borderColor={"border-primary-bg-color"}>
          <TailwindFlex flexDirection={"flex-row"}>
            <TailwindFlex alignItems='items-center'>
              <TailwindHeading level={5}>
                Latest News & Policies
              </TailwindHeading>
            </TailwindFlex>
          </TailwindFlex>
        </TailwindFlex>
      )}
      <TailwindFlex
        width={"w-full"}
        flexGrow={"flex-grow"}
        position={"relative"}
        padding={isMobileDevice ? ["p-0"] : ["px-5"]}
        overflow={
          isMobileDevice &&
          screenOrientation === "landscape" &&
          windowWidth &&
          windowWidth <= BreakPoints.md
            ? []
            : ["overflow-hidden"]
        }>
        <TailwindFlex
          md={"w-full"}
          lg={"w-4/12"}
          width={"w-4/12"}
          flexGrow={"flex-grow"}>
          <MessageCenterNavigator
            goBack={goBack}
            messageType={MessageTabEnum.POLICY}
          />
        </TailwindFlex>
        {!isMobileDevice && activeConversation && (
          <TailwindFlex
            md={"w-full"}
            lg={"w-8/12"}
            width={"w-8/12"}
            overflow={["overflow-hidden"]}
            flexGrow={"flex-grow"}
            flexDirection={"flex-col"}>
            <MessageCenterReader />
          </TailwindFlex>
        )}
      </TailwindFlex>
    </TailwindCard>
  );
};
export default inject(
  "messageStore",
  "layoutStore",
  "showcaseStore"
)(observer(NoticesContainer));
