import React from "react";
import "./styles.scss";
import classnames from "classnames";

type KebabIconProps = {
  className?: string;
};

const KebabIcon: React.ComponentType<KebabIconProps> = ({ className }) => (
  <span className={classnames("KebabIcon", className && className)}>
    <svg
      version='1.2'
      xmlns='http://www.w3.org/2000/svg'
      overflow='visible'
      preserveAspectRatio='none'
      viewBox='0 0 24 24'>
      <g>
        <path
          d='M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z'
          vectorEffect='non-scaling-stroke'
        />
      </g>
    </svg>
  </span>
);

export default KebabIcon;
