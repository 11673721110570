import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindTranslatedNumber from "library/components/_tailwind/translated-number";
import { StudioModelEarningsLight } from "common/earnings/_stores/earnings/types";
import config from "core/config";

type Props = {
  periodTotal: StudioModelEarningsLight;
};

const EarningsTableSimpleRow: React.ComponentType<Props> = ({
  periodTotal,
}) => {
  if (periodTotal.source === "Total") {
    return (
      <TailwindFlex
        padding={["p-2", "pl-10", "pr-5"]}
        width={"w-full"}
        pseudoClasses={["odd:bg-gray-200", "even:bg-gray-100"]}>
        <TailwindFlex width={"w-full"} justifyContent={"justify-end"}>
          <TailwindText
            width={"w-full"}
            fontWeight={"font-bold"}
            textAlign={"text-right"}
            padding={["pl-1", "pr-1"]}
            className={"capitalize-first"}
            textOverflow={"overflow-ellipsis"}
            overflow={"overflow-hidden"}>
            Total: &nbsp;&nbsp;
            <TailwindTranslatedNumber
              value={periodTotal.tokens}
              maximumFractionDigits={0}
            />
          </TailwindText>
        </TailwindFlex>
      </TailwindFlex>
    );
  }

  return (
    <TailwindFlex
      padding={["p-2", "pl-5", "pr-5"]}
      width={"w-full"}
      pseudoClasses={["odd:bg-gray-200", "even:bg-gray-100"]}>
      <TailwindFlex
        width={config.showYouMakeColumnOnEarningsReport ? "w-3/12" : "w-9/12"}
        justifyContent={"justify-start"}
        overflow={"overflow-hidden"}>
        <TailwindText
          width={"w-full"}
          padding={["pl-1", "pr-1"]}
          fontWeight={"font-bold"}
          className={"capitalize-first"}
          textOverflow={"overflow-ellipsis"}
          overflow={"overflow-hidden"}>
          {periodTotal.source}
        </TailwindText>
      </TailwindFlex>
      {config.showYouMakeColumnOnEarningsReport && (
        <TailwindFlex
          width={"w-6/12"}
          justifyContent={"justify-start"}
          overflow={"overflow-hidden"}>
          <TailwindText
            width={"w-full"}
            padding={["pl-1", "pr-1"]}
            className={"capitalize-first"}
            textOverflow={"overflow-ellipsis"}
            overflow={"overflow-hidden"}>
            {periodTotal.youMake || ""}
          </TailwindText>
        </TailwindFlex>
      )}
      <TailwindFlex
        width={"w-3/12"}
        justifyContent={"justify-end"}
        overflow={"overflow-hidden"}>
        <TailwindText
          width={"w-full"}
          textAlign={"text-right"}
          padding={["pl-1", "pr-1"]}
          className={"capitalize-first"}
          textOverflow={"overflow-ellipsis"}
          overflow={"overflow-hidden"}>
          <TailwindTranslatedNumber
            value={periodTotal.tokens}
            maximumFractionDigits={0}
          />
        </TailwindText>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default EarningsTableSimpleRow;
