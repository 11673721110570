import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import StorybookSetupCode from "./setup-code";
import StorybookSetupDesigns from "./setup-designs";

type Props = {};

const StorybookSetup: React.ComponentType<Props> = ({}) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/code`}>
        <StorybookSetupCode />
      </Route>
      <Route path={`${path}/designs`}>
        <StorybookSetupDesigns />
      </Route>
    </Switch>
  );
};

export default StorybookSetup;
