import React, { useCallback } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindSpinner from "library/components/_tailwind/spinner";
import { format } from "date-fns";
import TailwindButton from "library/components/_tailwind/button";
import { inject, observer } from "mobx-react";
import AccountSettingsStore from "common/account-settings/stores/account-settings/AccountSettingsStore";
import TailwindBox from "library/components/_tailwind/box";
import TailwindText from "library/components/_tailwind/text";
import TailwindCard from "library/components/_tailwind/card";
import ModalStore from "library/core/stores/modal";
import BlockAddCountry from "./add-country-block";
import TailwindDivider from "library/components/_tailwind/divider";

type Props = {
  accountSettingsStore?: AccountSettingsStore;
  modalStore?: ModalStore;
};

const BlockedCountriesTable: React.ComponentType<Props> = ({
  accountSettingsStore,
  modalStore,
}) => {
  const { isBlockedCountriesLoading, blockedCountries, unblockCountry } =
    accountSettingsStore!;
  const { openSecondaryModal, closeSecondaryModal } = modalStore!;

  const onClickAddBlock = useCallback(() => {
    openSecondaryModal(
      <TailwindCard
        backgroundColor={"bg-primary-bg-color"}
        padding={["p-10"]}
        minWidth={"min-w-160"}>
        <BlockAddCountry onBlockCountrySuccess={closeSecondaryModal} />
      </TailwindCard>
    );
  }, []);

  return (
    <React.Fragment>
      <TailwindFlex
        margin={["mb-2"]}
        justifyContent={"justify-between"}
        alignItems={"items-center"}>
        <TailwindTranslatedText
          headingProps={{ level: 6 }}
          textColor='text-main-color'
          descriptor={{
            id: "accountSettings.blockUnblock.blockedCountries",
            defaultMessage: "Countries",
          }}
        />
        <TailwindButton
          fullWidth={false}
          size={"sm"}
          textColor='text-main-color'
          backgroundColor={"bg-primary-bg-color"}
          onClick={onClickAddBlock}>
          Add Block
        </TailwindButton>
      </TailwindFlex>
      <TailwindBox margin={["mb-4"]}>
        {isBlockedCountriesLoading ? (
          <TailwindSpinner />
        ) : blockedCountries.length === 0 ? (
          <TailwindCard
            backgroundColor={"primary"}
            display={"flex"}
            textColor='text-main-color'
            alignItems={"items-center"}
            padding={["p-4"]}>
            <TailwindText display={"block"}>
              No countries are blocked
            </TailwindText>
          </TailwindCard>
        ) : (
          <TailwindBox backgroundColor={"bg-primary-bg-color"}>
            <TailwindFlex
              textColor='text-main-color'
              backgroundColor={"bg-table-primary-bg-color"}
              padding={["p-3"]}
              height={"h-12"}
              justifyContent={"justify-center"}
              alignItems={"items-center"}>
              <TailwindBox width={"w-6/12"} padding={["p-2"]}>
                <TailwindTranslatedText
                  fontWeight={"font-bold"}
                  descriptor={{
                    id: "accountSettings.blockUnblock.countryName",
                    defaultMessage: "Country name",
                  }}
                />
              </TailwindBox>
              <TailwindBox width={"w-3/12"} padding={["p-2"]}>
                <TailwindTranslatedText
                  fontWeight={"font-bold"}
                  descriptor={{
                    id: "common.date",
                    defaultMessage: "Date",
                  }}
                />
              </TailwindBox>
              <TailwindBox width={"w-3/12"} />
            </TailwindFlex>
            {blockedCountries.map((countryData: any, index) => (
              <>
                {index > 0 && (
                  <TailwindDivider
                    color='bg-table-primary-bg-color'
                    opacity='opacity-100'
                    style={{ height: "1px" }}
                  />
                )}
                <TailwindFlex
                  textColor='text-main-color'
                  key={`row${index + 1}`}
                  padding={["px-3"]}
                  height={"h-16"}
                  justifyContent={"justify-center"}
                  alignItems={"items-center"}>
                  <TailwindFlex width={"w-6/12"} padding={["p-2"]}>
                    {countryData?.countryLabel}
                  </TailwindFlex>
                  <TailwindFlex width={"w-3/12"} padding={["p-2"]}>
                    {countryData?.created_at &&
                      format(new Date(countryData?.created_at), "MMM d, yyyy")}
                  </TailwindFlex>
                  <TailwindFlex
                    width={"w-3/12"}
                    padding={["p-2"]}
                    justifyContent={"justify-end"}>
                    <TailwindButton
                      backgroundColor={"bg-secondary-bg-color"}
                      rounded={false}
                      fullWidth={false}
                      justifyContent={"justify-center"}
                      width={"w-32"}
                      onClick={_ => {
                        unblockCountry(countryData);
                      }}>
                      <TailwindTranslatedText
                        fontWeight={"font-bold"}
                        descriptor={{
                          id: "accountSettings.blockUnblock.unblock",
                          defaultMessage: "Unblock",
                        }}
                      />
                    </TailwindButton>
                  </TailwindFlex>
                </TailwindFlex>
              </>
            ))}
          </TailwindBox>
        )}
      </TailwindBox>
    </React.Fragment>
  );
};

export default inject(
  "accountSettingsStore",
  "modalStore"
)(observer(BlockedCountriesTable));
