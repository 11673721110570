import React, { useMemo } from "react";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import { inject, observer } from "mobx-react";
import TailwindFlex from "library/components/_tailwind/flex";
import MyFansMemberTransactionsTableHead from "containers/model/my-fans/member-transactions-table-head";
import MyFansMemberTableBody from "containers/model/my-fans/member-table-body";
import MyFansMemberTransactionsTableBody from "containers/model/my-fans/member-transactions-table-body";
import MyFansMemberTransactionsTablePagination from "containers/model/my-fans/member-transactions-table-pagination";
import MyFansStore from "./stores/my-fans/MyFansStore";

type Props = {
  layoutStore?: LayoutStore;
  myFansStore?: MyFansStore;
};

const MyFansTable: React.ComponentType<Props> = ({
  layoutStore,
  myFansStore,
}) => {
  const { isMobileScreenSize } = layoutStore!;
  const { sortedSelectedMemberTransactions, selectedMemberId } = myFansStore!;

  const hasFans = useMemo(() => {
    return selectedMemberId && sortedSelectedMemberTransactions.length > 0;
  }, [selectedMemberId, sortedSelectedMemberTransactions]);

  return (
    <React.Fragment>
      {isMobileScreenSize ? (
        <TailwindFlex
          flexDirection={"flex-col"}
          textColor='text-main-color'
          overflow={["overflow-y-auto", "overflow-x-hidden"]}
          height={"h-auto"}
          width={"w-full"}>
          <MyFansMemberTableBody />
        </TailwindFlex>
      ) : (
        <TailwindFlex
          flexDirection={"flex-row"}
          height={"h-auto"}
          width={"w-full"}>
          <TailwindFlex
            flexDirection={"flex-col"}
            minWidth={"min-w-80"}
            overflow={["overflow-y-auto", "overflow-x-hidden"]}
            margin={["mr-2"]}
            borderRadius={"rounded-xl"}
            width={"w-96"}
            sm={"w-1/3"}
            md={"w-1/4"}
            lg={"w-1/4"}
            className={["bg-primary-bg-color"]}>
            <MyFansMemberTableBody />
          </TailwindFlex>
          <TailwindFlex
            width={"w-10/12"}
            sm={"w-2/3"}
            md={"w-3/4"}
            borderRadius={"rounded-xl"}
            overflow={["overflow-x-auto", "overflow-y-hidden"]}
            lg={"w-3/4"}
            className={["bg-body-bg-color", "text-main-color"]}
            flexDirection={"flex-col"}>
            <TailwindFlex
              flexDirection={"flex-col"}
              height={"h-full"}
              width={"w-full"}
              minWidth={"min-w-160"}>
              {hasFans && <MyFansMemberTransactionsTableHead />}
              <MyFansMemberTransactionsTableBody />
              <MyFansMemberTransactionsTablePagination />
            </TailwindFlex>
          </TailwindFlex>
        </TailwindFlex>
      )}
    </React.Fragment>
  );
};

export default inject("layoutStore", "myFansStore")(observer(MyFansTable));
