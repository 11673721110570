import React, { useCallback, useState } from "react";
import { inject, observer } from "mobx-react";
import ModalStore from "library/core/stores/modal";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindCard from "library/components/_tailwind/card";
import TailwindDropdown, {
  TailwindDropdownValue,
} from "library/components/_tailwind/dropdown";
import FileUpload, {
  FileUploadProcessStatus,
} from "library/components/file-upload";
import { injectIntl, WrappedComponentProps } from "react-intl";
import TailwindAccordion from "library/components/_tailwind/accordion";
import MoreInformation from "./more-information";
import DomesticDirections from "./domestic-directions";
import InternationalDirections from "./international-directions";
import {
  PAYMENT_COUNTRY_DROPDOWN_ITEMS,
  ACCEPTED_FILE_TYPES_FOR_TAX_FORM,
} from "./const";
import AccountSettingsStore from "common/account-settings/stores/account-settings/AccountSettingsStore";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";

interface UploadTaxFormModalProps {
  modalStore?: ModalStore;
  accountSettingsStore?: AccountSettingsStore;
  profileStore?: ProfileStore;
}

const UploadTaxFormModal: React.ComponentType<
  UploadTaxFormModalProps & WrappedComponentProps
> = ({ intl, accountSettingsStore, profileStore }) => {
  const { isTaxFormDenied } = profileStore!;
  const { uploadTaxForm } = accountSettingsStore!;
  const [paymentCountryValue, setPaymentCountryValue] = useState(0);
  const [uploadStatus, setUploadStatus] =
    useState<FileUploadProcessStatus>(undefined);

  const onPaymentMethodChange = useCallback((value: TailwindDropdownValue) => {
    if (typeof value === "number") {
      setPaymentCountryValue(value);
    }
  }, []);

  const onUploadTaxForm = useCallback(async (files: File[]) => {
    setUploadStatus("processing");
    const tax_file = await uploadTaxForm(files);

    if (tax_file) {
      setUploadStatus("accepted");
    } else {
      setUploadStatus("rejected");
    }
  }, []);

  return (
    <TailwindFlex width={"w-192"} md={"w-full"}>
      <TailwindCard
        backgroundColor={"primary"}
        height={"h-full"}
        width={"w-full"}
        padding={["p-4"]}>
        <TailwindTranslatedText
          headingProps={{
            level: 5,
            margin: ["mb-4"],
            textAlign: "text-center",
          }}
          descriptor={{
            id: "uploadTaxFormModal.title",
            defaultMessage: "Update and submit your current tax document",
          }}
        />
        {isTaxFormDenied && (
          <TailwindTranslatedText
            paragraphProps={{ margin: ["mb-4"], textColor: "text-red-500" }}
            descriptor={{
              id: "uploadTaxFormModal.description",
              defaultMessage:
                "Your tax document was denied. Please review your form and reupload.",
            }}
          />
        )}
        <TailwindDropdown
          items={PAYMENT_COUNTRY_DROPDOWN_ITEMS}
          value={paymentCountryValue as number}
          onChange={onPaymentMethodChange}
          name={"payment-country"}
          label={"Payment Country"}
          width={"w-full"}
          fullWidth={true}
          backgroundColor={"bg-primary-bg-color"}
        />
        {paymentCountryValue === 0 && <DomesticDirections />}
        {paymentCountryValue === 1 && <InternationalDirections />}
        <TailwindFlex margin={["mt-4"]}>
          <FileUpload
            processStatus={uploadStatus}
            dropzoneProps={{
              onDrop: onUploadTaxForm,
              accept: ACCEPTED_FILE_TYPES_FOR_TAX_FORM,
            }}
            acceptedText={"Photo Uploaded"}
          />
        </TailwindFlex>

        <TailwindAccordion
          buttonProps={{
            padding: ["pl-0"],
          }}
          label={intl.formatMessage({
            id: "uploadTaxFormModal.showHideMorePaymentInfo",
            defaultMessage: "More payment information",
          })}>
          <MoreInformation />
        </TailwindAccordion>
      </TailwindCard>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject(
    "modalStore",
    "accountSettingsStore",
    "profileStore"
  )(observer(UploadTaxFormModal))
);
