import { makeAutoObservable } from "mobx";
import { api } from "core/utility";
import config from "core/config";
import { PayoutInitialData } from "./consts";
import {
  CamsPayoutSetting,
  CamsPayoutSettingCheck,
  CamsPayoutSettingDirectDeposit,
  CamsPayoutSettingPaxum,
  CamsPayoutSettingWireTransfer,
} from "./types";
import { validationStore } from "library/core/stores/validation/ValidationStore";
import { snackbarStore } from "library/core/stores/snackbar/SnackbarStore";
import { logger } from "library/core/utility";
import {
  CAMS_MODEL_PAYOUT_METHOD_KEY,
  PAYOUT_METHOD_WIRE_TRANSFER_TYPES_ENUM,
} from "./enums";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";
import { authStore, profileStore } from "core/stores";

export default class PayoutStore {
  constructor() {
    makeAutoObservable(this);
  }
  activePayoutTab = PayoutInitialData.activePayoutTab;

  loadingPayoutMethods: boolean = PayoutInitialData.loadingPayoutMethods;
  submittingPaymentDetails: boolean = PayoutInitialData.submitPaymentDetails;
  submittingConfirmPaymentDetails: boolean =
    PayoutInitialData.submittingConfirmPaymentDetails;

  payoutSettings: CamsPayoutSetting[] = PayoutInitialData.payoutSettings;

  wireTransferType: PAYOUT_METHOD_WIRE_TRANSFER_TYPES_ENUM =
    PayoutInitialData.wireTransferType;

  currentPeriodTitle: string = "";
  showPayoutConfirmation: boolean = false;

  selectedPaymentMethod: CAMS_MODEL_PAYOUT_METHOD_KEY | null = null;
  selectedPaymentCountry: string | null = null;

  confirmationCode: string = "";

  setConfirmationCode = (code: string) => {
    this.confirmationCode = code;
  };

  setSelectedPaymentMethod = (method: CAMS_MODEL_PAYOUT_METHOD_KEY) => {
    this.selectedPaymentMethod = method;
    this.setShowPayoutConfirmation(false);
  };

  setSelectedPaymentCountry = (countryLabel: string) => {
    this.selectedPaymentCountry = countryLabel;

    // if country is selected does not have the active setting available
    const hasSelectedUnavailableMethodForCountry = !this.availableMethods.find(
      method => method.value === this.selectedPaymentMethod
    );
    if (hasSelectedUnavailableMethodForCountry) {
      // paxum is recommended so fallback to paxum
      this.selectedPaymentMethod = CAMS_MODEL_PAYOUT_METHOD_KEY.paxum;
    }

    this.setShowPayoutConfirmation(false);
  };

  public getPayoutMethods = async () => {
    this.loadingPayoutMethods = true;
    try {
      const { data } = await api
        .getGetPayoutMethodsEndpoint(authStore.userRole)
        .get();
      this.handlePayoutMethods(data?.results);
    } catch (error) {
      logger.log(
        "PayoutStore: error getPayoutMethods",
        config.showAjaxErrors ? error : ""
      );
    } finally {
      this.loadingPayoutMethods = false;
    }
  };

  get confirmedMethods() {
    return this.payoutSettings.filter(setting => setting.is_confirmed);
  }

  get availableMethods() {
    const paymentMethods = [
      {
        label: "E-mail",
        value: CAMS_MODEL_PAYOUT_METHOD_KEY.paxum,
      },
      {
        label: "Wire Transfer",
        value: CAMS_MODEL_PAYOUT_METHOD_KEY.wired_transfer,
      },
    ];

    if (this.selectedPaymentCountry === "United States") {
      paymentMethods.push({
        label: "Direct Deposit",
        value: CAMS_MODEL_PAYOUT_METHOD_KEY.direct_deposit,
      });
    }

    // check option is no longer available on legacy sites
    // thus it has been removed
    // https://jira.friendfinderinc.com/browse/CAMSRBT-5611
    /*if (
      this.selectedPaymentCountry === "United States" ||
      this.selectedPaymentCountry === "Canada"
    ) {
      paymentMethods.push({
        label: "Check",
        value: CAMS_MODEL_PAYOUT_METHOD_KEY.check,
      });
    }*/

    return paymentMethods;
  }

  public setWireTransferType = (
    type: PAYOUT_METHOD_WIRE_TRANSFER_TYPES_ENUM
  ) => {
    this.wireTransferType = type;
  };

  public resendCode = async (payoutMethod: string) => {
    try {
      await api
        .getGetPayoutMethodsEndpoint(authStore.userRole)
        .post({}, `${payoutMethod}/resend/`);
      snackbarStore.enqueueSnackbar({
        message: { id: "messages.success.payoutChangeConfirmationSent" },
        variant: SnackbarVariants.SUCCESS,
      });
    } catch (error) {
      if (error && error.response && error.response.data) {
        if (
          error.response.data.non_field_errors &&
          error.response.data.non_field_errors.length
        ) {
          if (
            error.response.data.non_field_errors[0] ===
            "Maximum code resend count hit"
          ) {
            snackbarStore.enqueueSnackbar({
              message: { id: "messages.error.resendCodeLimit" },
              closeButton: true,
              variant: SnackbarVariants.ERROR,
            });
          }
        }
      }
      logger.log(
        "error resending payout change code ",
        payoutMethod,
        config.showAjaxErrors ? error.error.response : ""
      );
    }
  };

  getPaymentMethodTitle = (methodName: CAMS_MODEL_PAYOUT_METHOD_KEY) => {
    switch (methodName) {
      case CAMS_MODEL_PAYOUT_METHOD_KEY.check: {
        return "Check";
      }
      case CAMS_MODEL_PAYOUT_METHOD_KEY.direct_deposit: {
        return "Direct Deposit";
      }
      case CAMS_MODEL_PAYOUT_METHOD_KEY.paxum: {
        return "E-Mail";
      }
      case CAMS_MODEL_PAYOUT_METHOD_KEY.wired_transfer: {
        return "Wire Transfer";
      }
    }
  };

  public confirmPaymentDetails = async (
    payoutMethod: CAMS_MODEL_PAYOUT_METHOD_KEY
  ) => {
    this.submittingConfirmPaymentDetails = true;
    try {
      const { data } = await api
        .getGetPayoutMethodsEndpoint(authStore.userRole)
        .post(
          { confirmation_code: this.confirmationCode },
          `${payoutMethod}/confirm/`
        );
      if (data?.is_confirmed) {
        snackbarStore.enqueueSnackbar({
          message: {
            id: "messages.success.confirmationCodeSuccess",
            parameters: {
              payoutMethod: this.getPaymentMethodTitle(payoutMethod),
            },
          },
          variant: SnackbarVariants.SUCCESS,
        });
        this.showPayoutConfirmation = false;
        const setting = this.payoutSettings.find(
          setting => setting.method_name === payoutMethod
        );

        if (setting) {
          setting.is_selected = true;
          setting.is_confirmed = true;
        }
      }
    } catch (error) {
      logger.log(
        "PayoutStore: error confirmPaymentDetails",
        payoutMethod,
        config.showAjaxErrors ? error : ""
      );
      validationStore.storeBackendErrors(error);
    } finally {
      this.submittingConfirmPaymentDetails = false;
    }
  };

  public setActivePayoutTab = (tab: number) => {
    this.activePayoutTab = tab;
  };

  get checkingDetails() {
    return this.payoutSettings.find(
      setting => setting.method_name === CAMS_MODEL_PAYOUT_METHOD_KEY.check
    );
  }

  get directDepositDetails() {
    return this.payoutSettings.find(
      setting =>
        setting.method_name === CAMS_MODEL_PAYOUT_METHOD_KEY.direct_deposit
    );
  }

  get paxumDetails() {
    return this.payoutSettings.find(
      setting => setting.method_name === CAMS_MODEL_PAYOUT_METHOD_KEY.paxum
    );
  }

  get wireTransferDetails() {
    return this.payoutSettings.find(
      setting =>
        setting.method_name === CAMS_MODEL_PAYOUT_METHOD_KEY.wired_transfer
    );
  }

  get selectedPayoutSetting() {
    return this.payoutSettings.find(setting => setting.is_selected);
  }

  get activePayoutSetting() {
    //active in terms of the form visible to the user
    return this.payoutSettings.find(
      setting => setting.method_name === this.selectedPaymentMethod
    );
  }

  public setPayoutFormCountry = (country: string) => {
    if (this.checkingDetails) {
      this.checkingDetails.payout_settings.country = country;
    }
    if (this.directDepositDetails) {
      this.directDepositDetails.payout_settings.country = country;
    }
    if (this.paxumDetails) {
      this.paxumDetails.payout_settings.country = country;
    }
    if (this.wireTransferDetails) {
      this.wireTransferDetails.payout_settings.country = country;
    }

    this.setSelectedPaymentCountry(country);
  };

  public setShowPayoutConfirmation = value => {
    this.showPayoutConfirmation = value;
  };

  public toggleHasModelsLocatedInUsa = () => {
    if (this.activePayoutSetting) {
      this.activePayoutSetting.has_models_located_in_usa =
        !this.activePayoutSetting.has_models_located_in_usa;
    }
  };

  public updatePaymentDetails = (
    activePayoutSetting: CamsPayoutSetting | undefined,
    name:
      | keyof CamsPayoutSettingDirectDeposit
      | keyof CamsPayoutSettingPaxum
      | keyof CamsPayoutSettingWireTransfer
      | keyof CamsPayoutSettingCheck,
    value: string
  ) => {
    const settings = activePayoutSetting?.payout_settings;
    if (settings) {
      if (name in settings) {
        settings[name] = value;
      } else {
        logger.log("PayoutStore: error updatePaymentDetails");
      }
    }
  };

  get shouldDisableWireTransferFormFields() {
    // once wire transfer form is filled, it cannot be edited anymore. user needs to contact CS to get it changed
    const shouldDisable =
      this.activePayoutSetting?.method_name ===
        CAMS_MODEL_PAYOUT_METHOD_KEY.wired_transfer &&
      this.activePayoutSetting?.is_confirmed;
    return shouldDisable;
  }

  public submitPaymentDetails = async (
    method_name: CAMS_MODEL_PAYOUT_METHOD_KEY
  ) => {
    const paymentMethod = this.payoutSettings.find(
      method => method.method_name === method_name
    );
    if (paymentMethod) {
      const settings = paymentMethod.payout_settings;
      let inputs = Object.keys(settings);

      if (
        method_name === CAMS_MODEL_PAYOUT_METHOD_KEY.wired_transfer &&
        this.wireTransferType ===
          PAYOUT_METHOD_WIRE_TRANSFER_TYPES_ENUM.DOMESTIC
      ) {
        inputs = [
          "country",
          "bank_name",
          "account_name",
          "bank_address",
          "account_number",
          "name_on_account",
        ];
      }

      const allInputsValid = validationStore.validateMultiple(
        inputs,
        settings,
        undefined,
        false
      );

      if (allInputsValid) {
        this.submittingPaymentDetails = true;
        try {
          await api
            .getGetPayoutMethodsEndpoint(authStore.userRole)
            .post(paymentMethod);
          snackbarStore.enqueueSnackbar({
            message: { id: "messages.success.payoutChangeConfirmationSent" },
            variant: SnackbarVariants.SUCCESS,
          });

          this.showPayoutConfirmation = true;
        } catch (error) {
          logger.log(
            "PayoutStore: error submitPaymentDetails",
            method_name,
            config.showAjaxErrors ? error.error.response : ""
          );
          validationStore.storeBackendErrors(error);
        } finally {
          this.submittingPaymentDetails = false;
        }
      }
    }
  };

  public handlePayoutMethods = (payoutMethodsFromBE: CamsPayoutSetting[]) => {
    // fill in method defaults if user has not set it yet
    const payoutMethods: CamsPayoutSetting[] = [];
    PayoutInitialData.payoutSettings.forEach(setting => {
      const settingComingFromBE: CamsPayoutSetting | undefined =
        payoutMethodsFromBE.find(
          _setting => _setting.method_name === setting.method_name
        );

      if (settingComingFromBE) {
        settingComingFromBE.is_saved = true;
      }

      if (settingComingFromBE?.has_models_located_in_usa === null) {
        settingComingFromBE.has_models_located_in_usa = false;
      }

      payoutMethods.push(settingComingFromBE ? settingComingFromBE : setting);
    });

    this.payoutSettings = payoutMethods;

    //if user has no form filled yet, set default country by geo
    if (!payoutMethodsFromBE.length) {
      this.setPayoutFormCountry(profileStore.geolocationCountry);
    }

    //set dropdown values of the selected setting or the one that is not confirmed yet
    const urlParams = new URLSearchParams(window.location.search);
    const unconfirmedSetting = payoutMethodsFromBE.find(
      setting => !setting.is_confirmed
    );
    const hasMethodNameInQuery = urlParams.has("method");
    const method_name = hasMethodNameInQuery
      ? (urlParams.get("method") as CAMS_MODEL_PAYOUT_METHOD_KEY)
      : this.selectedPayoutSetting?.method_name ||
        unconfirmedSetting?.method_name;
    const country =
      this.selectedPayoutSetting?.payout_settings?.country ||
      unconfirmedSetting?.payout_settings?.country;
    if (method_name && country) {
      this.setSelectedPaymentMethod(method_name);
      this.setPayoutFormCountry(country);
      if (hasMethodNameInQuery) {
        const confirmationCode = urlParams.has("code")
          ? urlParams.get("code")
          : null;
        if (confirmationCode) {
          this.setConfirmationCode(confirmationCode);
        }
        this.setShowPayoutConfirmation(true);
      }
    }
  };
}
