import React from "react";

type Props = {};

const QAWidgetSeo: React.ComponentType<Props> = () => {
  return (
    <React.Fragment>
      <div className="FeatureSwitch__Cobrand-item">
        <span>SEO Title: {document.title}</span>
      </div>
      <div className="FeatureSwitch__Cobrand-item">
        <span>
          SEO Description:{" "}
          {(document.querySelector('meta[name="description"]') as any)?.content}
        </span>
      </div>
      <div className="FeatureSwitch__Cobrand-item">
        <span>
          SEO Canonical:{" "}
          {(document.querySelector('link[rel="canonical"]') as any)?.href}
        </span>
      </div>
    </React.Fragment>
  );
};

export default QAWidgetSeo;
