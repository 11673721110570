import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import TailwindAnchor from "../../components/_tailwind/anchor";

type Props = {};

const StorybookArchitectureLayout: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Architecture &gt; Layout</StorybookHeading>
      <StorybookParagraph>
        The layout is the wrapper for the page container components and is in a
        folder of its own in src/layouts folder. The two main layouts are
        "landing" and "dashboard".
      </StorybookParagraph>
      <StorybookParagraph>
        Landing layout is the one you are expected to see when you are not
        logged in. It does not leak any information that should only be visible
        to logged in users. The dashboard layout is the one you are expected to
        see when you are logged in, which has a header and a sidebar and a
        dynamic content of body that accepts children.
      </StorybookParagraph>
      <StorybookParagraph>
        The structure of the layout is also "adaptive" meaning it has the
        following file and folder structure:
      </StorybookParagraph>
      <Code>
        {`src/layout/landing/index.tsx
src/layout/landing/aff/index.tsx      
src/layout/landing/cams/index.tsx      
src/layout/landing/passion/index.tsx      

src/layout/dashboard/index.tsx      
src/layout/dashboard/body/index.tsx      
src/layout/dashboard/header/index.tsx      
src/layout/dashboard/navigation-item/index.tsx      
src/layout/dashboard/sidebar/index.tsx      
`}
      </Code>
      <StorybookParagraph>
        The sidebar has additional logic inside itself. As there are not many
        differences on the sidebar between products, the sidebar container is
        reused in a way that inside you do userRole differentiation and show
        different route links. Header works the same way. The sidebar works
        connected with the routing system so the sidebar items are generated
        from Route constant file. The routing system has control over the look
        of the sidebar. An example can be:
      </StorybookParagraph>
      <Code>
        {`routes/common.ts
        
[AppCamsModelRouteKey.myPage]: {
  key: AppCamsModelRouteKey.myPage,
  link: "my-page",
  pageTitle: "My Page",
  sidebarOptions: {
    location: RouteSidebarLocations.top,
    index: 1,
    label: "My Page",
    hoverBackgroundGradient: "yellow",
    isCustomIcon: true,
    icon: "avatar",
  },
},
`}
      </Code>
      <StorybookParagraph>
        Refer to{" "}
        <TailwindAnchor to={"/architecture/routes"}>Routes page</TailwindAnchor>{" "}
        for more details about routes.
      </StorybookParagraph>
    </StorybookWrapper>
  );
};

export default StorybookArchitectureLayout;
