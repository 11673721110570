import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindText from "../../components/_tailwind/text";
import Code from "../../components/_tailwind/code";
import TailwindBox from "../../components/_tailwind/box";

type Props = {};

const StorybookComponentsText: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Text</StorybookHeading>
      <StorybookParagraph>
        Text component is a primitive that is also used in TailwindIcon (for
        sizing, coloring etc) as well as TailwindButton and some other
        components. Some components has{" "}
        <Code inline>textProps?: TailwindTextProps</Code> so that you can
        specify the textProps or some components directly extends the
        TailwindTextProps type like TailwindIcon so you don't need to pass it as
        nested object prop.
      </StorybookParagraph>
      <StorybookParagraph>
        Currently it takes the following props and applies TailwindCSS classes:
      </StorybookParagraph>
      <Code>
        {`textColor?: TTextColor;
textOpacity?: TTextOpacity;
fontFamily?: TFontFamily;
fontSize?: TFontSize;
fontSmoothing?: TFontSmoothing;
fontStyle?: TFontStyle;
fontWeight?: TFontWeight;
fontVariantNumeric?: TFontVariantNumeric;
letterSpacing?: TLetterSpacing;
lineHeight?: TLineHeight;
textAlign?: TTextAlign;
textDecoration?: TTextDecoration;
textTransform?: TTextTransform;
textOverflow?: TTextOverflow;
verticalAlign?: TVerticalAlign;
whiteSpace?: TWhitespace;
wordBreak?: TWordBreak;
position?: TPosition;
inset?: TInset[];
width?: TWidth;
height?: THeight;
margin?: (TMargin | undefined)[];
padding?: (TPadding | undefined)[];
className?: TClasses | string;
zIndex?: TZIndex;
display?: TDisplay;
alignItems?: TAlignItems;
justifyContent?: TJustifyContent;
userSelect?: TUserSelect;
cursor?: TCursor;
overflow?: TOverflow;
onClick?: (event: React.MouseEvent<HTMLElement>) => void;
as?: string;
htmlFor?: string;
pseudoClasses?: (TPseudoClasses | undefined)[];
dangerouslySetInnerHTML?: {
  __html: string;
};        
`}
      </Code>
      <StorybookParagraph>
        Here are some examples with the TailwindText component. As it is very
        flexible, you can create variants easily so not all combinations are
        showcased here.
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindText fontSize={"text-3xl"}>text-3xl</TailwindText>`}
      >
        <TailwindText fontSize={"text-3xl"}>text-3xl</TailwindText>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindText cursor={"cursor-pointer"}>cursor-pointer</TailwindText>`}
      >
        <TailwindText cursor={"cursor-pointer"}>cursor-pointer</TailwindText>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindText textColor={"text-red-500"}>text-red-500</TailwindText>`}
      >
        <TailwindText textColor={"text-red-500"}>text-red-500</TailwindText>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindText fontWeight={"font-bold"}>font-bold</TailwindText>`}
      >
        <TailwindText fontWeight={"font-bold"}>font-bold</TailwindText>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindText
  textColor={"text-yellow-500"}
  pseudoClasses={["hover:text-red-500"]}
>
  hover:text-red-500
</TailwindText>     
`}
      >
        <TailwindText
          textColor={"text-yellow-500"}
          pseudoClasses={["hover:text-red-500"]}
        >
          hover:text-red-500
        </TailwindText>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindText
  textColor={"text-red-500"}
  textOpacity={"text-opacity-30"}
>
  text-opacity-30
</TailwindText>
`}
      >
        <TailwindText
          textColor={"text-red-500"}
          textOpacity={"text-opacity-30"}
        >
          text-opacity-30
        </TailwindText>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindText textColor={"text-red-500"} margin={["mx-4", "my-8"]}>
  margin mx-4 my-8
</TailwindText>  
`}
      >
        <TailwindText textColor={"text-red-500"} margin={["mx-4", "my-8"]}>
          margin mx-4 my-8
        </TailwindText>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindText textColor={"text-red-500"} padding={["px-4", "py-8"]}>
  padding px-4 py-8
</TailwindText>
`}
      >
        <TailwindText textColor={"text-red-500"} padding={["px-4", "py-8"]}>
          padding px-4 py-8
        </TailwindText>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindText
  textColor={"text-red-500"}
  position={"absolute"}
  inset={["bottom-0", "right-20"]}
>
  position absolute bottom-0 right-20
</TailwindText>
`}
      >
        <TailwindBox position={"relative"} height={"h-20"}>
          <TailwindText
            textColor={"text-red-500"}
            position={"absolute"}
            inset={["bottom-0", "right-20"]}
          >
            position absolute bottom-0 right-20
          </TailwindText>
        </TailwindBox>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsText;
