import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";

type Props = {};

const StorybookArchitectureInternalizationAndLocalization: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>
        Architecture &gt; Internalization & Localization
      </StorybookHeading>
      <StorybookParagraph>
        We use react-intl for translations & localizations. Our i18n
        translations files are located in <Code inline>src/i18n</Code> as JSON
        files.
      </StorybookParagraph>
      <StorybookParagraph>
        The structure of the JSON files are highly inconsistent. We will soon
        need to start from scratch because Andrew, owner of the company is
        writing a translation system where we can manage different translations
        through an admin tool. The translation JSON files will no longer be
        relevant and we will need to rewrite the IDs of the translations however
        while we wait for that to happen, here are key points to know:
      </StorybookParagraph>
      <StorybookParagraph>
        - There is "common" and "verbiage" translations that hold common
        translations like "OK", "Yes, "No", "Edit", "Create" etc.
      </StorybookParagraph>
      <StorybookParagraph>
        - The containers must be named as such in translation files:{" "}
        <Code inline>landing.sign_up.heading</Code> or{" "}
        <Code inline>media_manager.album.title</Code>
      </StorybookParagraph>
      <StorybookParagraph>
        - No translation should have all uppercase or all lowercase
        translations. Text transforms should be done on component level.
      </StorybookParagraph>
      <StorybookParagraph>
        - We should not have too many nested translations (Andrew will give an
        example on how to structure the i18n)
      </StorybookParagraph>
      <StorybookParagraph>
        - Each translation MUST have a default message in the component. We will
        get the translation IDs and default messages from BE during app init
      </StorybookParagraph>
      <StorybookParagraph>
        - The units/currencies and text need to be translated with components
        such as TailwindTranslatedDate, TailwindTranslatedNumber,
        TailwindTranslatedText, TailwindTranslatedTime
      </StorybookParagraph>
      <StorybookParagraph>
        - LanguageStore will be used to do all language related functionality.
      </StorybookParagraph>
    </StorybookWrapper>
  );
};

export default StorybookArchitectureInternalizationAndLocalization;
