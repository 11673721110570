import { inject, observer } from "mobx-react";
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import ProductDropdown from "../broadcast-pricing-product-dropdown";
import PricingStore from "../_stores/pricing/PricingStore";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindFlex from "library/components/_tailwind/flex";
import StudioModelAccessRights from "../../studio-models-access-rights";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import TailwindSwitch from "library/components/_tailwind/switch";
import TailwindTooltip from "library/components/_tailwind/tooltip";
import TailwindBox from "library/components/_tailwind/box";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import { IShowType, ShowTab } from "../_stores/broadcast/enums";
import LanguageStore from "library/core/stores/language/LanguageStore";
import { PRODUCT_PRICE_OPTIONS_DEFAULTS } from "../_stores/pricing/consts";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";

interface TypeIfShowSelector {
  broadcastStore?: BroadcastStore;
  pricingStore?: PricingStore;
  profileStore?: ProfileStore;
  layoutStore?: LayoutStore;
  languageStore?: LanguageStore;
}

const BroadcastPrivateSettings: FunctionComponent<TypeIfShowSelector> = ({
  pricingStore,
  broadcastStore,
  profileStore,
  layoutStore,
  languageStore,
}) => {
  const {
    submitField,
    submitEnabled,
    modelProducts,
    voyeurPriceOptions,
    privateChatPriceOptions,
    formatPricesByID,
    submitPrice,
    submitVoyeurPrice,
  } = pricingStore!;
  const { isMobileScreenSize } = layoutStore!;
  const { intl } = languageStore!;

  const {
    modelProfile: { access_rights },
    isStudioModel,
  } = profileStore!;
  const [filteredPartyChatOptions, setFilteredPartyChatOptions] = useState<
    string[]
  >([]);
  const [priceChangeTimeout, setPriceChangeTimeout] = useState<number | null>(
    null
  );
  const [isPartyChatPriceOptionsDisabled, setIsPartyChatPriceOptionsDisabled] =
    useState<boolean>(false);
  const { isShowStarted, currentShowTab, currentShowType } = broadcastStore!;
  const { is_private_allowed } = modelProducts;
  const nlaPartyChatPrice = 20;

  const filterPartyChatPrices = (selectedPrivateChatPrice: number) => {
    const rawOptions = pricingStore?.partyChatPriceOptions || [];
    if (selectedPrivateChatPrice === pricingStore?.privateChatPriceOptions[0]) {
      return [nlaPartyChatPrice.toString()];
    }
    return rawOptions.filter(option => {
      const price = Number(option);
      return !isNaN(price) && price < selectedPrivateChatPrice;
    });
  };

  const setLowerPartyChatPriceIfNeeded = useCallback(
    (newPrivateChatPrice: number) => {
      setIsPartyChatPriceOptionsDisabled(true);
      if (newPrivateChatPrice === pricingStore?.privateChatPriceOptions[0]) {
        submitPrice("party_chat", nlaPartyChatPrice.toString());
        setIsPartyChatPriceOptionsDisabled(false);
        return;
      }
      const currentPartyChatPrice = Number(modelProducts?.party_chat);
      if (
        (!isNaN(currentPartyChatPrice) &&
          newPrivateChatPrice <= currentPartyChatPrice) ||
        currentPartyChatPrice === nlaPartyChatPrice
      ) {
        const rawOptions =
          pricingStore?.partyChatPriceOptions
            .map(option => Number(option))
            .filter(price => !isNaN(price)) || [];
        const lowerPriceOption = rawOptions
          .reverse()
          .find(price => price < newPrivateChatPrice);
        if (lowerPriceOption !== undefined) {
          submitPrice("party_chat", lowerPriceOption.toString());
        }
      }
      setIsPartyChatPriceOptionsDisabled(false);
    },
    [modelProducts?.party_chat]
  );

  useEffect(() => {
    if (modelProducts?.private_chat <= modelProducts?.party_chat) {
      setLowerPartyChatPriceIfNeeded(modelProducts?.private_chat);
    }
    return () => {
      if (priceChangeTimeout) {
        clearTimeout(priceChangeTimeout);
      }
    };
  }, []);

  useEffect(() => {
    const selectedPrivateChatPrice = modelProducts?.private_chat;
    if (selectedPrivateChatPrice !== undefined) {
      const newPartyChatOptions = filterPartyChatPrices(
        selectedPrivateChatPrice
      );
      setFilteredPartyChatOptions([
        PRODUCT_PRICE_OPTIONS_DEFAULTS.party_chat[0],
        ...newPartyChatOptions,
      ]);
    }
  }, [modelProducts?.private_chat, pricingStore?.partyChatPriceOptions]);

  const isAutoRecordOn = useMemo(() => {
    return modelProducts?.chat_settings?.is_recording_all_private_shows;
  }, [modelProducts?.chat_settings?.is_recording_all_private_shows]);

  const onChangeVoyeurChatPrice = useCallback((value: any) => {
    submitVoyeurPrice(value);
  }, []);

  const onChangePrivateChatPrice = useCallback(
    (value: any) => {
      if (priceChangeTimeout) {
        clearTimeout(priceChangeTimeout);
        setPriceChangeTimeout(null);
        setIsPartyChatPriceOptionsDisabled(false);
      }
      submitPrice("private_chat", value);
      setPriceChangeTimeout(
        setTimeout(setLowerPartyChatPriceIfNeeded, 1000, value)
      );
    },
    [setLowerPartyChatPriceIfNeeded]
  );

  const onChangePartyChatPrice = useCallback(
    (value: any) => submitPrice("party_chat", value),
    []
  );

  const onChangePrivateConsentNeeded = useCallback(() => {
    submitField(
      "is_private_consent_needed",
      !modelProducts?.is_private_consent_needed
    );
  }, [modelProducts]);

  const isPrivateShowEnabled = useMemo(() => {
    return is_private_allowed === undefined || is_private_allowed === true;
  }, [is_private_allowed]);

  const onChangeTogglePrivateShow = useCallback(() => {
    submitEnabled("is_private_allowed");
  }, []);

  const canPrivateToggle = useMemo(() => {
    return (
      (isShowStarted && currentShowType !== IShowType.FREE) ||
      currentShowTab !== ShowTab.FREE
    );
  }, [isShowStarted, currentShowType, currentShowTab]);

  return (
    <>
      <TailwindFlex
        flexDirection='flex-col'
        alignItems='items-center'
        minHeight={isMobileScreenSize ? "min-h-0" : "min-h-80"}>
        <TailwindFlex flexDirection='flex-row'>
          <TailwindTranslatedText
            textColor='text-main-color'
            fontWeight={"font-semibold"}
            descriptor={{
              id: "free-to-enter-private.set-pricing",
              defaultMessage: "Set Pricing:",
            }}
          />
          <TailwindTooltip
            content={intl.formatMessage({
              id: "free-to-enter-private.set-pricing-tt",
              defaultMessage:
                "Fan Club members get a 10% discount automatically applied on all paid shows based on your set prices.",
            })}>
            <TailwindFlex
              width={"w-auto"}
              alignItems={"items-center"}
              margin={["ml-2"]}>
              <TailwindIcon
                textColor={"text-gray-400"}
                name={CustomIconName["question-mark"]}
              />
            </TailwindFlex>
          </TailwindTooltip>
        </TailwindFlex>
        <StudioModelAccessRights
          isIndividualModel={!isStudioModel}
          shouldShow={!!access_rights?.is_pricing_settings_change_allowed}
          type={"pricing"}
          boxProps={{
            margin: ["mb-2"],
          }}>
          <TailwindFlex justifyContent='justify-between' margin={["mb-3"]}>
            <TailwindTranslatedText
              padding={["pt-2"]}
              textColor='text-main-color'
              margin={["mr-1"]}
              descriptor={{
                id: "free-to-enter-private.private",
                defaultMessage: "Private show",
              }}
            />
            <ProductDropdown
              dataTestId='private-chat-price-dropdown'
              className={["h-10"]}
              fullHeight={true}
              disabled={isShowStarted}
              options={privateChatPriceOptions}
              onChange={onChangePrivateChatPrice}
              price={modelProducts?.private_chat}
              currency={intl.formatMessage({
                id: `common.units.tokens-long`,
                defaultMessage: "tokens",
              })}
              unit={intl.formatMessage({
                id: `common.units.minute`,
                defaultMessage: "minute",
              })}
              direction={"bottom"}
            />
          </TailwindFlex>
          <TailwindFlex justifyContent='justify-between' margin={["mb-3"]}>
            <TailwindTranslatedText
              padding={["pt-2"]}
              textColor='text-main-color'
              margin={["mr-1"]}
              descriptor={{
                id: "free-to-enter-private.party",
                defaultMessage: "Party show",
              }}
            />
            <ProductDropdown
              dataTestId='party-chat-price-dropdown'
              className={["h-10"]}
              fullHeight={true}
              disabled={isShowStarted || isPartyChatPriceOptionsDisabled}
              options={filteredPartyChatOptions}
              onChange={onChangePartyChatPrice}
              direction={"bottom"}
              price={formatPricesByID("party_chat", "is_party_allowed")}
              currency={intl.formatMessage({
                id: `common.units.tokens-long`,
                defaultMessage: "tokens",
              })}
              unit={intl.formatMessage({
                id: `common.units.minute`,
                defaultMessage: "minute",
              })}
            />
          </TailwindFlex>
          <TailwindFlex justifyContent='justify-between' margin={["mb-1"]}>
            <TailwindTranslatedText
              padding={["pt-2"]}
              margin={["mr-1"]}
              textColor='text-main-color'
              descriptor={{
                id: "free-to-enter-private.voyeur",
                defaultMessage: "Voyeurs can watch",
              }}
            />
            <ProductDropdown
              dataTestId='voyeur-chat-price-dropdown'
              className={["h-10"]}
              fullHeight={true}
              disabled={isShowStarted}
              options={voyeurPriceOptions}
              onChange={onChangeVoyeurChatPrice}
              direction={"bottom"}
              price={formatPricesByID("voyeur_chat", "is_voyeur_allowed")}
              currency={intl.formatMessage({
                id: `common.units.tokens-long`,
                defaultMessage: "tokens",
              })}
              unit={intl.formatMessage({
                id: `common.units.minute`,
                defaultMessage: "minute",
              })}
            />
          </TailwindFlex>
          <TailwindBox
            margin={["mb-4"]}
            display='flex'
            justifyContent='justify-end'>
            {isAutoRecordOn &&
              !Number(formatPricesByID("voyeur_chat", "is_voyeur_allowed")) && (
                <TailwindTranslatedText
                  textColor={"text-gray-500"}
                  fontWeight={"font-light"}
                  fontSize={"text-sm"}
                  fontStyle={"italic"}
                  descriptor={{
                    id: "free-to-enter-private.disableRecordingPrivatePartyshows",
                    defaultMessage:
                      "Automatically record my Private and Party shows is disabled",
                  }}
                />
              )}
          </TailwindBox>
        </StudioModelAccessRights>

        <TailwindFlex
          textColor={
            !isPrivateShowEnabled ? "text-gray-500" : "text-main-color"
          }
          justifyContent='justify-between'
          margin={["mb-3"]}>
          <TailwindTranslatedText
            whiteSpace='whitespace-nowrap'
            paragraphProps={{
              fontWeight: "font-semibold",
            }}
            descriptor={{
              id: "free-to-enter-private.allow-private-shows",
              defaultMessage: "Allow private shows",
            }}
          />

          <TailwindBox height='h-5'>
            <TailwindSwitch
              data-testid='private-show-toggle'
              onLabel={intl.formatMessage({
                id: "settings.on",
                defaultMessage: "On",
              })}
              offLabel={intl.formatMessage({
                id: "settings.off",
                defaultMessage: "Off",
              })}
              size={"xs-wide"}
              onChange={onChangeTogglePrivateShow}
              isChecked={isPrivateShowEnabled}
              offBackgroundColor={"bg-toggle-disabled"}
              offLabelProps={{
                textColor: "text-white",
              }}
              onBackgroundColor={"bg-earnings-bg-color"}
              onLabelProps={{
                textColor: "text-main-color",
              }}
              pillBackgroundColor={
                isPrivateShowEnabled ? "bg-green-500" : "bg-gray-200"
              }
              disabled={canPrivateToggle}
            />
          </TailwindBox>
        </TailwindFlex>

        <TailwindFlex
          textColor={
            !isPrivateShowEnabled ? "text-gray-500" : "text-main-color"
          }
          justifyContent='justify-between'
          margin={["mb-3"]}>
          <TailwindTranslatedText
            whiteSpace='whitespace-nowrap'
            paragraphProps={{
              fontWeight: "font-semibold",
            }}
            descriptor={{
              id: "free-to-enter-private.member-takes-me-private-automatically",
              defaultMessage: "Ask me before going private",
            }}
          />

          <TailwindBox height='h-5'>
            <TailwindSwitch
              data-testid='private-show-consent-toggle'
              onLabel={intl.formatMessage({
                id: "settings.on",
                defaultMessage: "On",
              })}
              offLabel={intl.formatMessage({
                id: "settings.off",
                defaultMessage: "Off",
              })}
              size={"xs-wide"}
              onChange={onChangePrivateConsentNeeded}
              isChecked={modelProducts?.is_private_consent_needed}
              offBackgroundColor={"bg-toggle-disabled"}
              offLabelProps={{
                textColor: "text-white",
              }}
              onBackgroundColor={"bg-earnings-bg-color"}
              onLabelProps={{
                textColor: "text-main-color",
              }}
              pillBackgroundColor={
                modelProducts?.is_private_consent_needed
                  ? "bg-green-500"
                  : "bg-gray-200"
              }
              disabled={!isPrivateShowEnabled}
            />
          </TailwindBox>
        </TailwindFlex>
      </TailwindFlex>
    </>
  );
};
export default inject(
  "pricingStore",
  "broadcastStore",
  "profileStore",
  "layoutStore",
  "languageStore"
)(observer(BroadcastPrivateSettings));
