import React, { useMemo } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { inject, observer } from "mobx-react";
import AuthStore from "../auth/AuthStore";
import DashboardLayout from "containers/_layout/dashboard";
import LandingLayout from "containers/_layout/landing";
import AccessRestriction, {
  AccessRestrictionOptions,
} from "./access-restriction";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import TailwindSpinner from "library/components/_tailwind/spinner";
import RouteStore from "core/stores/route/RouteStore";

type Props = RouteProps & {
  authStore?: AuthStore;
  profileStore?: ProfileStore;
  routeStore?: RouteStore;
  isNested?: boolean;
  accessRestrictionOptions?: AccessRestrictionOptions;
  hasAccess?: boolean;
};

const PrivateRoute: React.ComponentType<Props> = ({
  authStore,
  profileStore,
  routeStore,
  isNested,
  accessRestrictionOptions,
  children,
  hasAccess = true,
  ...rest
}) => {
  const { isLoggedIn, isReady } = authStore!;
  const { profileLoaded } = profileStore!;
  const { getMainRouteForSite } = routeStore!;
  const location = useLocation();

  const wrappedChildren = useMemo(() => {
    let _children = children;

    if (_children) {
      if (accessRestrictionOptions) {
        _children = (
          <AccessRestriction {...accessRestrictionOptions}>
            {_children}
          </AccessRestriction>
        );
      }

      if (!isNested) {
        _children = <DashboardLayout>{_children}</DashboardLayout>;
      }
    }

    return _children;
  }, [children, isNested, accessRestrictionOptions]);

  if (isReady && (!isLoggedIn || profileLoaded)) {
    if (isLoggedIn && hasAccess && profileLoaded) {
      return <Route {...rest}>{wrappedChildren}</Route>;
    } else {
      return (
        <Redirect
          to={{
            pathname: isLoggedIn ? getMainRouteForSite() : "/",
            state: { from: location?.pathname },
          }}
        />
      );
    }
  }

  return (
    <LandingLayout>
      <TailwindSpinner />
    </LandingLayout>
  );
};

export default inject(
  "authStore",
  "profileStore",
  "routeStore"
)(observer(PrivateRoute));
