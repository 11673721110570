import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import FileUpload from "../../components/file-upload";
import { CustomIconName } from "../../components/_tailwind/icon/icons/enums";
import TailwindIcon from "../../components/_tailwind/icon";
import { FileRejection } from "react-dropzone";

type Props = {};

const StorybookComponentsFileUpload: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; File Upload</StorybookHeading>
      <StorybookParagraph>
        File Upload is an opinionated component that wraps{" "}
        <Code inline>react-dropzone</Code> package. It has not yet been
        completely refactored to use TailwindCSS therefore styling of it is not
        really flexible. It takes the props from Dropzone as dropzoneProps as
        well as the following opinionated props:
      </StorybookParagraph>
      <Code>{` previewUrl?: string;
showPreviewUrlAsBackgroundImage?: boolean;
processStatus?: FileUploadProcessStatus;
showStatus?: boolean;
fileName?: string;
shape?: "rectangle" | "circle" | "soft-rectangle";
boxProps?: TailwindBoxProps;
error?: boolean;
helperText?: string;
showHelperText?: boolean;
customIcon?: React.ReactElement;
acceptedText?: string;
dropzoneProps?: DropzoneProps;
stopPropagation?: boolean;  
`}</Code>
      <StorybookParagraph>Here are some examples:</StorybookParagraph>
      <StorybookComponentShowcase sourceCode={`<FileUpload />`}>
        Plain defaults
        <FileUpload />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<FileUpload textColor={"text-white"} borderColor={"border-white"} />`}
      >
        White example
        <FileUpload textColor={"text-white"} borderColor={"border-white"} />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<FileUpload
  textColor={"text-white"}
  borderColor={"border-white"}
  customIcon={<TailwindIcon name={CustomIconName.buzzmode} />}
/>
`}
      >
        Custom icon
        <FileUpload
          textColor={"text-white"}
          borderColor={"border-white"}
          customIcon={<TailwindIcon name={CustomIconName.buzzmode} />}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<FileUpload
  textColor={"text-white"}
  borderColor={"border-white"}
  helperText={"Some custom helper"}
/>
`}
      >
        Custom helper
        <FileUpload
          textColor={"text-white"}
          borderColor={"border-white"}
          helperText={"Some custom helper"}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<FileUpload
  textColor={"text-white"}
  borderColor={"border-white"}
  previewUrl={
    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb01-1b1f87ed74cf90fd190f20abb17c5414-9211b9.jpg"
  }
/>
`}
      >
        Preview URL
        <FileUpload
          textColor={"text-white"}
          borderColor={"border-white"}
          previewUrl={
            "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb01-1b1f87ed74cf90fd190f20abb17c5414-9211b9.jpg"
          }
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<FileUpload
  textColor={"text-white"}
  borderColor={"border-white"}
  shape={"circle"}
/>
`}
      >
        Circle without Preview
        <FileUpload
          textColor={"text-white"}
          borderColor={"border-white"}
          shape={"circle"}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<FileUpload
  textColor={"text-white"}
  borderColor={"border-white"}
  shape={"circle"}
  previewUrl={
    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb01-1b1f87ed74cf90fd190f20abb17c5414-9211b9.jpg"
  }
/>
`}
      >
        Circle with Preview
        <FileUpload
          textColor={"text-white"}
          borderColor={"border-white"}
          shape={"circle"}
          previewUrl={
            "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb01-1b1f87ed74cf90fd190f20abb17c5414-9211b9.jpg"
          }
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<FileUpload
  textColor={"text-white"}
  borderColor={"border-white"}
  shape={"circle"}
  previewUrl={
    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb01-1b1f87ed74cf90fd190f20abb17c5414-9211b9.jpg"
  }
  error={true}
  helperText={"Some error"}
/>
`}
      >
        Error
        <FileUpload
          textColor={"text-white"}
          borderColor={"border-white"}
          error={true}
          helperText={"Some error"}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<FileUpload
  textColor={"text-white"}
  borderColor={"border-white"}
  fileName={"some file name.jpg"}
  processStatus={"accepted"}
/>
`}
      >
        File name
        <FileUpload
          textColor={"text-white"}
          borderColor={"border-white"}
          fileName={"some file name.jpg"}
          processStatus={"accepted"}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<FileUpload
  textColor={"text-white"}
  borderColor={"border-white"}
  fileName={"some file name.jpg"}
  dropzoneProps={{
    maxFiles: 2
  }}
/>
`}
      >
        Max 2 files
        <FileUpload
          textColor={"text-white"}
          borderColor={"border-white"}
          dropzoneProps={{
            maxFiles: 2,
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<FileUpload
  textColor={"text-white"}
  borderColor={"border-white"}
  dropzoneProps={{
    maxFiles: 2,
    accept: ["video/quicktime"]
  }}
/>
`}
      >
        Only accept mov files
        <FileUpload
          textColor={"text-white"}
          borderColor={"border-white"}
          dropzoneProps={{
            maxFiles: 2,
            accept: ["video/quicktime"],
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<FileUpload
  textColor={"text-white"}
  borderColor={"border-white"}
  dropzoneProps={{
    maxFiles: 2,
    accept: ["video/quicktime"],
    onDropAccepted: (files: File[]) => alert(\`Files accepted, \${files.length}\`)
  }}
/>
`}
      >
        Files accepted event
        <FileUpload
          textColor={"text-white"}
          borderColor={"border-white"}
          dropzoneProps={{
            maxFiles: 2,
            accept: ["video/quicktime"],
            onDropAccepted: (files: File[]) =>
              alert(`Files accepted, ${files.length}`),
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<FileUpload
  textColor={"text-white"}
  borderColor={"border-white"}
  dropzoneProps={{
    maxFiles: 2,
    accept: ["video/quicktime"],
    onDropAccepted: (files: File[]) =>
      alert(\`Files accepted, \${files.length}\`),
    onDropRejected: (reasons: FileRejection[]) =>
      alert(
        \`Files rejected, \${JSON.stringify(reasons[0].errors, null, 2)}\`
      ),
  }}
/>
`}
      >
        Files rejected event
        <FileUpload
          textColor={"text-white"}
          borderColor={"border-white"}
          dropzoneProps={{
            maxFiles: 2,
            accept: ["video/quicktime"],
            onDropAccepted: (files: File[]) =>
              alert(`Files accepted, ${files.length}`),
            onDropRejected: (reasons: FileRejection[]) =>
              alert(
                `Files rejected, ${JSON.stringify(reasons[0].errors, null, 2)}`
              ),
          }}
        />
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsFileUpload;
