import LoginModal from "../../../common/login";
import React from "react";
import { modalStore } from "library/core/stores/modal";
import RegisterModal from "../../../common/sign-up";
import AccountSuspendedDisclaimer from "../../../common/account-suspended-disclaimer";
import UploadTaxFormModal from "../../../common/account-settings/upload-tax-form-modal";
import AccountAuditedDisclaimer from "common/account-audited-disclaimer";

export const openLoginModal = () =>
  modalStore.toggleModal(<LoginModal />, true, "auth", {
    scrollTop: true,
    showNativeCloseButton: true,
  });
export const openRegisterModal = () =>
  modalStore.toggleModal(<RegisterModal />, true, "auth", {
    scrollTop: true,
    showNativeCloseButton: true,
  });
export const openAccountSuspendedDisclaimerModal = (username?: string) =>
  modalStore.toggleModal(
    <AccountSuspendedDisclaimer user_name={username} />,
    true,
    "auth",
    {
      scrollTop: true,
      showNativeCloseButton: true,
    }
  );
export const openAccountAuditedDisclaimerModal = (showCloseButton: boolean) =>
  modalStore.toggleModal(
    <AccountAuditedDisclaimer isModal={true} />,
    true,
    "primary",
    {
      showNativeCloseButton: showCloseButton,
    }
  );
export const openUploadTaxFormModal = () =>
  modalStore.toggleModal(<UploadTaxFormModal />, true, "auth", {
    scrollTop: true,
    showNativeCloseButton: true,
  });
