import React, { useEffect, useMemo, useState } from "react";
import { inject, observer } from "mobx-react";
import BroadcastStore from "common/broadcast/_stores/broadcast/BroadcastStore";

type Props = {
  broadcastStore?: BroadcastStore;
};

const QAWidgetBroadcastShowTrail: React.ComponentType<Props> = ({
  broadcastStore,
}) => {
  const { showTrail, lastShowTrailDetail } = broadcastStore!;
  const [now, setNow] = useState<Date>(new Date());
  let timer;

  const currentShowDurationInSeconds = useMemo(() => {
    const startedAt = lastShowTrailDetail?.startedAt;
    if (startedAt) {
      const durationInSeconds = (now.getTime() - startedAt.getTime()) / 1000;
      return durationInSeconds;
    }

    return "Not started a show yet";
  }, [lastShowTrailDetail, now]);

  useEffect(() => {
    timer = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
      timer = undefined;
    };
  }, []);

  return (
    <React.Fragment>
      <div>
        {Object.keys(showTrail).length === 0 ? (
          <div>You have not yet started a show in this session</div>
        ) : (
          <React.Fragment>
            {Object.keys(showTrail).map(key => (
              <div key={key} className={"flex flex-col border border-black"}>
                <div>{key}</div>
                <div>Next Show Type: {showTrail[key].nextShowType}</div>
                <div>Previous Show Type: {showTrail[key].previousShowType}</div>
                <div>
                  Started At: {showTrail[key].startedAt.toLocaleString()}
                </div>
                <div>
                  Ended At (is empty for last active show):{" "}
                  {showTrail[key].endedAt?.toLocaleString()}
                </div>
                <div>
                  Duration in Seconds (is empty for last active show):{" "}
                  {showTrail[key].durationInSeconds}
                </div>
              </div>
            ))}
            <div className={"font-bold mt-4"}>
              Current Show Duration in Seconds: {currentShowDurationInSeconds}
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default inject("broadcastStore")(observer(QAWidgetBroadcastShowTrail));
