import React from "react";
import AccountSettingsStore from "../stores/account-settings/AccountSettingsStore";
import { inject, observer } from "mobx-react";
import ModalStore from "library/core/stores/modal";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindCard from "library/components/_tailwind/card";

interface IViewDocumentModalProps {
  accountSettingsStore?: AccountSettingsStore;
  modalStore?: ModalStore;
}

const ViewDocumentModal: React.ComponentType<IViewDocumentModalProps> = ({
  accountSettingsStore,
}) => {
  const {
    name,
    content,
    signature,
    // version, //version is hidden until further notice
    created_at,
  } = accountSettingsStore!.signingDocument;

  return (
    <TailwindFlex width={"w-192"} md={"w-full"}>
      <TailwindCard
        backgroundColor={"primary"}
        height={"h-full"}
        width={"w-full"}
        padding={["p-4"]}>
        <TailwindTranslatedText
          headingProps={{
            level: 5,
            margin: ["mb-4"],
            textAlign: "text-center",
          }}
          descriptor={{
            id: "viewDocumentModal.title",
            defaultMessage: "This is the {name}.",
          }}
          values={{ name }}
        />
        {/*
        hidden until further notice
        <TailwindText
          margin={["mb-4"]}
          width={"w-full"}
          textAlign={"text-right"}
          display={"block"}>
          VERSION: {version}
        </TailwindText>*/}
        <p
          className={
            "ViewDocumentModal__document-content normalize-html prettify-html"
          }
          dangerouslySetInnerHTML={{
            __html: content,
          }}></p>
        <TailwindTranslatedText
          paragraphProps={{
            margin: ["mt-4"],
            fontWeight: "font-bold",
          }}
          descriptor={{
            id: "viewDocumentModal.name",
            defaultMessage: "Name: {name}",
          }}
          values={{ name: signature }}
        />
        <TailwindTranslatedText
          paragraphProps={{
            fontWeight: "font-bold",
          }}
          descriptor={{
            id: "viewDocumentModal.date",
            defaultMessage: "Date: {date}",
          }}
          values={{ date: created_at }}
        />
      </TailwindCard>
    </TailwindFlex>
  );
};

export default inject(
  "modalStore",
  "accountSettingsStore"
)(observer(ViewDocumentModal));
