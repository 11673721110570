import React from "react";
import "./styles.scss";
import classnames from "classnames";

type CrownIconProps = {
  className?: string;
};

const CrownIcon: React.ComponentType<CrownIconProps> = ({
  className,
}) => (
  <span className={classnames("CrownIcon", className && className)}>
    <svg version="1.2" overflow="visible" preserveAspectRatio="none" viewBox="0 0 26 26" y="0px" x="0px" id="crown_1618959720044" width="18" height="18">
      <g transform="translate(1, 1)">
        <g transform="translate(1, 1)">
          <path 
            d="M15.2,16.8c0.7,0,1.3,0.6,1.4,1.3c0,0,0,0,0,0.1l0,0c0,0.7-0.6,1.3-1.4,1.4H6.8   c-0.7,0-1.3-0.6-1.4-1.3c0,0,0,0,0-0.1l0,0c0-0.7,0.6-1.3,1.4-1.4H15.2z M19.2,5.1c-0.9-0.1-1.7,0.6-1.8,1.5c0,0.4,0.2,0.7,0.4,0.9   L15.4,10l-0.9-4.5c0.7-0.1,1.3-0.7,1.3-1.5c-0.1-1-0.9-1.7-1.9-1.6c-1,0-1.8,0.7-1.9,1.6c0,0.5,0.3,1,0.7,1.3l-1.7,4.4l0,0L9.3,5.4   c0.5-0.2,0.9-0.7,0.9-1.3c-0.1-1-0.9-1.7-1.9-1.6c-1,0-1.8,0.7-1.9,1.6c0,0.8,0.6,1.4,1.4,1.5L6.7,10L4.2,7.5   C4.5,7,4.6,6.9,4.6,6.6C4.5,5.7,3.7,5,2.8,5.1C1.9,5,1.1,5.7,1,6.6c0.1,0.9,0.8,1.5,1.6,1.5l2.4,5.9c0,0.1,0.1,0.2,0.1,0.2v0.1l0,0   c0.2,0.5,0.7,0.8,1.2,0.8h9.1c0.5,0,1-0.3,1.2-0.8l0,0v-0.1c0-0.1,0.1-0.2,0.1-0.2l2.6-5.9c0.8,0,1.5-0.7,1.6-1.5   C20.9,5.7,20.1,5,19.2,5.1z" />
        </g>
      </g>
    </svg>
  </span>
);

export default CrownIcon;
