import React, { useCallback, useEffect } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import TailwindBox from "library/components/_tailwind/box";
import TailwindListItem from "library/components/_tailwind/list/list-item";
import TailwindButton from "library/components/_tailwind/button";
import TailwindIcon from "library/components/_tailwind/icon";
import ShowcaseStore from "core/stores/showcase/ShowcaseStore";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindList, {
  TailwindListType,
} from "library/components/_tailwind/list";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";

type Props = {
  layoutStore?: LayoutStore;
  showcaseStore?: ShowcaseStore;
  profileStore?: ProfileStore;
};
const TutorialContainer: React.FunctionComponent<
  WrappedComponentProps & Props
> = ({ layoutStore, showcaseStore, profileStore }) => {
  const { setBodyPadding, isMobileScreenSize } = layoutStore!;
  const { startWelcomeShowcase } = showcaseStore!;
  const { dismissTutorial, shouldShowTutorialToModel } = profileStore!;

  useEffect(() => {
    setBodyPadding(0);

    return () => {
      setBodyPadding(15);
    };
  }, []);

  const startTour = useCallback(() => {
    startWelcomeShowcase();
  }, []);

  const hideWelcome = useCallback(() => {
    dismissTutorial();
  }, []);

  return (
    <TailwindFlex
      width={"w-full"}
      height={"h-full"}
      flexGrow={"flex-grow-0"}
      overflow={["overflow-x-hidden", "overflow-y-auto"]}
      position={"relative"}
      alignItems={"items-start"}
      pseudoClasses={["md:flex-col-reverse", "md:justify-end"]}>
      <TailwindFlex
        backgroundColor={"bg-transparent"}
        height={"h-full"}
        width={"w-1/3"}
        overflow={["overflow-x-hidden", "overflow-y-auto"]}
        pseudoClasses={["md:w-full"]}
        flexDirection={"flex-col"}
        justifyContent={"justify-between"}>
        <TailwindFlex
          flexDirection={"flex-col"}
          justifyContent={"justify-between"}
          className={!isMobileScreenSize ? ["h-full"] : undefined}
          padding={["px-6", "py-10"]}>
          <TailwindBox>
            <TailwindFlex margin={["mt-0", "mb-6"]} flexDirection={"flex-col"}>
              <TailwindTranslatedText
                fontWeight={"font-bold"}
                fontSize={"text-2xl"}
                textColor='text-main-color'
                textAlign={"text-center"}
                margin={["mb-3"]}
                descriptor={{
                  id: "welcomePage.welcomeTitle",
                  defaultMessage: "Welcome to the new model center!",
                }}
              />

              <TailwindTranslatedText
                fontSize={"text-lg"}
                textAlign={"text-center"}
                textColor='text-main-color'
                descriptor={{
                  id: "welcomePage.welcomeDesc",
                  defaultMessage:
                    "Lots of things are different. But don't stress out. Here's what's new:",
                }}
              />
            </TailwindFlex>

            <TailwindFlex margin={["mb-2"]}>
              <TailwindBox
                margin={["mr-2.5"]}
                display={"flex"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                padding={["p-3"]}
                backgroundColor={"bg-pink-400"}
                backgroundOpacity={"bg-opacity-60"}
                borderRadius={"rounded-full"}
                width={"w-5"}
                height={"h-5"}>
                <TailwindIcon
                  name={"keyboard_arrow_right"}
                  fontSize={"text-xl"}
                  textColor={"text-white"}
                />
              </TailwindBox>
              <TailwindTranslatedText
                headingProps={{ level: 6 }}
                descriptor={{
                  id: "welcomePage.goLiveBroadcast.title",
                  defaultMessage: "Go Live/Broadcast",
                }}
              />
            </TailwindFlex>

            <TailwindFlex margin={["mb-8"]}>
              <TailwindList
                type={TailwindListType.ul}
                fontSize={"text-lg"}
                padding={["px-12"]}>
                <TailwindListItem listStyleType={"list-disc"}>
                  <TailwindTranslatedText
                    textColor='text-main-color'
                    descriptor={{
                      id: "welcomePage.goLiveBroadcast.point_1",
                      defaultMessage:
                        "High performance live streaming services delivering reliable broadcast-quality",
                    }}
                  />
                </TailwindListItem>
                <TailwindListItem listStyleType={"list-disc"}>
                  <TailwindTranslatedText
                    textColor='text-main-color'
                    descriptor={{
                      id: "welcomePage.goLiveBroadcast.point_2",
                      defaultMessage:
                        "Accept or decline Private Show requests from members",
                    }}
                  />
                </TailwindListItem>
                <TailwindListItem listStyleType={"list-disc"}>
                  <TailwindTranslatedText
                    textColor='text-main-color'
                    descriptor={{
                      id: "welcomePage.goLiveBroadcast.point_3",
                      defaultMessage:
                        "Record your broadcast in any show type and save it as a Recorded Show",
                    }}
                  />
                </TailwindListItem>
                <TailwindListItem listStyleType={"list-disc"}>
                  <TailwindTranslatedText
                    textColor='text-main-color'
                    descriptor={{
                      id: "welcomePage.goLiveBroadcast.point_4",
                      defaultMessage:
                        "Broadcast with your favorite mobile friendly device",
                    }}
                  />
                </TailwindListItem>
              </TailwindList>
            </TailwindFlex>

            <TailwindFlex margin={["mb-8"]}>
              <TailwindBox
                margin={["mt-0.5", "mr-2.5"]}
                display={"flex"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                padding={["p-3"]}
                backgroundColor={"bg-pink-400"}
                backgroundOpacity={"bg-opacity-60"}
                borderRadius={"rounded-full"}
                width={"w-5"}
                height={"h-5"}>
                <TailwindIcon
                  name={"keyboard_arrow_right"}
                  fontSize={"text-xl"}
                  textColor={"text-white"}
                />
              </TailwindBox>
              <TailwindTranslatedText
                textColor='text-main-color'
                descriptor={{
                  id: "welcomePage.myPage.description",
                  defaultMessage:
                    "My Page: Manage your profile and media including Promotional Listing photos",
                }}
              />
            </TailwindFlex>

            <TailwindFlex margin={["mb-8"]}>
              <TailwindBox
                margin={["mt-0.5", "mr-2.5"]}
                display={"flex"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                padding={["p-3"]}
                backgroundColor={"bg-pink-400"}
                backgroundOpacity={"bg-opacity-60"}
                borderRadius={"rounded-full"}
                width={"w-5"}
                height={"h-5"}>
                <TailwindIcon
                  name={"keyboard_arrow_right"}
                  fontSize={"text-xl"}
                  textColor={"text-white"}
                />
              </TailwindBox>
              <TailwindTranslatedText
                textColor='text-main-color'
                descriptor={{
                  id: "welcomePage.myFans.description",
                  defaultMessage:
                    "My Fans: View all your important Fan Club, Top Admirer/Spender, and/or Bounty Members",
                }}
              />
            </TailwindFlex>

            <TailwindFlex margin={["mb-8"]}>
              <TailwindBox
                margin={["mt-0.5", "mr-2.5"]}
                display={"flex"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                padding={["p-3"]}
                backgroundColor={"bg-pink-400"}
                backgroundOpacity={"bg-opacity-60"}
                borderRadius={"rounded-full"}
                width={"w-5"}
                height={"h-5"}>
                <TailwindIcon
                  name={"keyboard_arrow_right"}
                  fontSize={"text-xl"}
                  textColor={"text-white"}
                />
              </TailwindBox>
              <TailwindTranslatedText
                textColor='text-main-color'
                descriptor={{
                  id: "welcomePage.referrals.description",
                  defaultMessage:
                    "Referrals: Member referral spends on other models' programs earns you an additional 15% commission during member’s first year",
                }}
              />
            </TailwindFlex>

            <TailwindFlex margin={["mb-8"]}>
              <TailwindBox
                margin={["mt-0.5", "mr-2.5"]}
                display={"flex"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                padding={["p-3"]}
                backgroundColor={"bg-pink-400"}
                backgroundOpacity={"bg-opacity-60"}
                borderRadius={"rounded-full"}
                width={"w-5"}
                height={"h-5"}>
                <TailwindIcon
                  name={"keyboard_arrow_right"}
                  fontSize={"text-xl"}
                  textColor={"text-white"}
                />
              </TailwindBox>
              <TailwindTranslatedText
                textColor='text-main-color'
                descriptor={{
                  id: "welcomePage.earnings.description",
                  defaultMessage:
                    "Earnings: Summary report and two new tiered percentages added to the Tiered Payout Program",
                }}
              />
            </TailwindFlex>

            <TailwindFlex margin={["mb-8"]}>
              <TailwindBox
                margin={["mt-0.5", "mr-2.5"]}
                display={"flex"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                padding={["p-3"]}
                backgroundColor={"bg-pink-400"}
                backgroundOpacity={"bg-opacity-60"}
                borderRadius={"rounded-full"}
                width={"w-5"}
                height={"h-5"}>
                <TailwindIcon
                  name={"keyboard_arrow_right"}
                  fontSize={"text-xl"}
                  textColor={"text-white"}
                />
              </TailwindBox>
              <TailwindTranslatedText
                textColor='text-main-color'
                descriptor={{
                  id: "welcomePage.messageCenter.description",
                  defaultMessage:
                    "Message Center: Receive important announcements and contact Model Support for inquiries",
                }}
              />
            </TailwindFlex>

            <TailwindFlex margin={["mb-8"]}>
              <TailwindBox
                margin={["mt-0.5", "mr-2.5"]}
                display={"flex"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                padding={["p-3"]}
                backgroundColor={"bg-pink-400"}
                backgroundOpacity={"bg-opacity-60"}
                borderRadius={"rounded-full"}
                width={"w-5"}
                height={"h-5"}>
                <TailwindIcon
                  name={"keyboard_arrow_right"}
                  fontSize={"text-xl"}
                  textColor={"text-white"}
                />
              </TailwindBox>
              <TailwindTranslatedText
                textColor='text-main-color'
                descriptor={{
                  id: "welcomePage.topModels.description",
                  defaultMessage:
                    "Top Models: See all the current and past Contest Winners to scope out the competition",
                }}
              />
            </TailwindFlex>
            <TailwindFlex
              margin={["mt-8", "mb-4"]}
              flexDirection={"flex-col"}
              alignItems={"items-center"}>
              <TailwindButton
                data-testid={"start-tour-button"}
                size={"lg"}
                fullWidth={false}
                rounded={false}
                backgroundColor={"primary"}
                onClick={startTour}>
                Take the tour!
              </TailwindButton>
              {shouldShowTutorialToModel && (
                <TailwindButton
                  margin={["mt-4", "mb-8"]}
                  size={"sm"}
                  bold={false}
                  underline={true}
                  fullWidth={false}
                  onClick={hideWelcome}>
                  Thanks, Hide welcome now
                </TailwindButton>
              )}
            </TailwindFlex>
          </TailwindBox>
        </TailwindFlex>
      </TailwindFlex>

      <TailwindFlex
        backgroundColor={"bg-white"}
        width={"w-2/3"}
        pseudoClasses={["md:w-full"]}
        height={"h-full"}
        justifyContent={"justify-center"}
        alignItems={"items-center"}>
        {shouldShowTutorialToModel && (
          <TailwindButton
            className={["top-4", "right-4"]}
            position={"absolute"}
            zIndex={"z-10"}
            display={"flex"}
            alignItems={"items-center"}
            justifyContent={"justify-center"}
            padding={["p-0"]}
            fullWidth={false}
            backgroundColor={"primary"}
            backgroundOpacity={"bg-opacity-60"}
            borderRadius={"rounded-full"}
            width={"w-12"}
            height={"h-12"}
            onClick={hideWelcome}>
            <TailwindIcon name={"close"} fontSize={"text-4xl"} />
          </TailwindButton>
        )}
        <TailwindFlex
          justifyContent={"justify-center"}
          alignItems={"items-center"}
          className={["h-full", "w-full"]}
          backgroundColor={"bg-white"}>
          <video className='h-full w-full' controls>
            <source src={"/tutorial-video-for-model.mp4"} type={"video/mp4"} />
          </video>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject(
    "layoutStore",
    "showcaseStore",
    "profileStore"
  )(observer(TutorialContainer))
);
