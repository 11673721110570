import React, { useCallback, useState } from "react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { Popover } from "../../popover";
import TailwindBox from "library/components/_tailwind/box";

type Props = {
  onSelectEmoji: (emoji: string) => void;
};

const BroadcastChatEmojiPopover: React.FC<Props> = ({
  children,
  onSelectEmoji,
}) => {
  const [show, setShow] = useState(false);

  const onHide = useCallback(() => {
    setShow(false);
  }, []);

  const onShow = useCallback(() => {
    setShow(true);
  }, []);

  const onSelect = useCallback(
    _event => {
      setShow(false);
      onSelectEmoji(_event.native);
    },
    [onSelectEmoji]
  );

  return (
    <Popover
      placement='top-start'
      onHide={onHide}
      onShow={onShow}
      show={show}
      content={
        <TailwindBox borderWidth={["border-2"]} borderColor={"border-blue-500"}>
          <Picker
            data={data}
            onEmojiSelect={onSelect}
            icons={"solid"}
            skinTonePosition={"search"}
            theme={"auto"}
            noCountryFlags={true}
          />
        </TailwindBox>
      }>
      {children}
    </Popover>
  );
};

export default BroadcastChatEmojiPopover;
