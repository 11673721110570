import React, { useCallback, useEffect, useMemo } from "react";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import "./styles.scss";
import ViewDocumentModal from "../../common/account-settings/view-document-modal";
import AccountSettingsStore from "../../common/account-settings/stores/account-settings/AccountSettingsStore";
import ModalStore from "library/core/stores/modal";
import TailwindCard from "library/components/_tailwind/card";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import AuthStore from "core/stores/auth/AuthStore";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import { UserAccountStatus } from "../my-page/stores/profile/enums";
import UploadTaxFormModal from "../account-settings/upload-tax-form-modal";
import { format } from "date-fns";
import TailwindPill from "library/components/_tailwind/pill";
import TailwindBox from "library/components/_tailwind/box";
import TailwindText from "library/components/_tailwind/text";
import TailwindSpinner from "library/components/_tailwind/spinner";

type UpdateDocumentsProps = {
  modalStore?: ModalStore;
  accountSettingsStore?: AccountSettingsStore;
  authStore?: AuthStore;
  profileStore?: ProfileStore;
};

const UpdateDocuments: React.ComponentType<
  UpdateDocumentsProps & WrappedComponentProps
> = ({ modalStore, accountSettingsStore, intl, authStore, profileStore }) => {
  const { openPrimaryModal } = modalStore!;
  const { getDocuments, isLoadingDocuments, documents, setSigningDocument } =
    accountSettingsStore!;
  const { openRegisterModalToCompleteProfile, userRole } = authStore!;
  const {
    profile,
    isPendingTaxFormUpload,
    isPendingTaxFormApproval,
    isCurrentAccountStatus,
    isStudioModel,
  } = profileStore!;

  useEffect(() => {
    getDocuments();
  }, []);

  const onDocumentClicked = useCallback(document => {
    setSigningDocument(document);
    openPrimaryModal(<ViewDocumentModal />);
  }, []);

  const onNoDocumentsClicked = useCallback(() => {
    openRegisterModalToCompleteProfile(undefined, true);
  }, []);

  const onTaxFormClicked = useCallback(() => {
    openPrimaryModal(<UploadTaxFormModal />);
  }, []);

  const allowTaxFormUpload = useMemo(() => {
    const isApproved = isCurrentAccountStatus(UserAccountStatus.APPROVED);

    if (isApproved && userRole === "model" && !isStudioModel) {
      return true;
    } else if (!isApproved || userRole !== "studio") {
      return false;
    }
    return true;
  }, [profile, userRole]);

  return (
    <TailwindCard
      width={"w-full"}
      height={"h-full"}
      pseudoClasses={["md:h-auto"]}>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindTranslatedText
          headingProps={{ level: 5 }}
          descriptor={{
            id: "accountSettings.updateDocuments.title",
            defaultMessage: "Legal Documents",
          }}
        />
      </TailwindFlex>
      <TailwindBox
        backgroundColor={"bg-primary-bg-color"}
        borderRadius={"rounded-xl"}>
        <TailwindFlex
          borderRadius={"rounded-t-xl"}
          backgroundColor={"bg-table-primary-bg-color"}
          textColor={"text-main-color"}
          padding={["p-4"]}
          height={"h-12"}
          justifyContent={"justify-center"}
          alignItems={"items-center"}>
          <TailwindBox width={"w-5/12"}>
            <TailwindTranslatedText
              fontWeight='font-bold'
              descriptor={{
                id: "updateDocuments.legalDocuments",
                defaultMessage: "Name",
              }}
            />
          </TailwindBox>
          <TailwindBox width={"w-5/12"} padding={["p-2"]}>
            <TailwindTranslatedText
              fontWeight='font-bold'
              descriptor={{
                id: "updateDocuments.lastEdit",
                defaultMessage: "Last Edit",
              }}
            />
          </TailwindBox>
          <TailwindBox width={"w-2/12"} />
        </TailwindFlex>
        {isLoadingDocuments && (
          <TailwindFlex
            width={"w-full"}
            height={"h-full"}
            padding={["p-5"]}
            alignContent={"content-center"}>
            <TailwindSpinner />
          </TailwindFlex>
        )}
        {!isLoadingDocuments &&
          documents.length > 0 &&
          documents.map((document, index) => (
            <TailwindFlex
              key={index}
              padding={["p-3"]}
              height={"h-16"}
              textColor={"text-main-color"}
              justifyContent={"justify-center"}
              alignItems={"items-center"}
              borderColor={"border-table-secondary-color"}
              borderStyle={"border-solid"}
              borderWidth={[
                index < documents.length - 1 || allowTaxFormUpload
                  ? "border-b-2"
                  : "border-0",
              ]}>
              <TailwindBox width={"w-5/12"}>
                <TailwindText
                  className={
                    !!document.requires_signature
                      ? "UpdateDocuments__document-title--active"
                      : ""
                  }>
                  {document.name}
                </TailwindText>
              </TailwindBox>
              <TailwindBox width={"w-5/12"}>{document.created_at}</TailwindBox>
              <TailwindFlex
                width={"w-2/12"}
                alignItems={"items-center"}
                justifyContent={"justify-end"}>
                <TailwindButton
                  backgroundColor={"primary"}
                  rounded={false}
                  fullWidth={false}
                  width={"w-32"}
                  bold
                  justifyContent={"justify-center"}
                  onClick={() => onDocumentClicked(document)}>
                  View
                </TailwindButton>
              </TailwindFlex>
            </TailwindFlex>
          ))}
        {!isLoadingDocuments && allowTaxFormUpload && (
          <>
            {isPendingTaxFormUpload && (
              <TailwindFlex
                padding={["p-3"]}
                height={"h-16"}
                textColor={"text-main-color"}
                justifyContent={"justify-center"}
                alignItems={"items-center"}>
                <TailwindBox width={"w-5/12"}>
                  <TailwindText
                    className={"UpdateDocuments__document-title--active"}>
                    {intl.formatMessage({
                      id: "accountSettings.updateDocuments.updateTaxForm",
                      defaultMessage: "Update Your Tax Form",
                    })}
                  </TailwindText>
                </TailwindBox>
                <TailwindBox width={"w-5/12"}>
                  {profile?.tax_form?.modified_at &&
                    format(
                      new Date(profile.tax_form?.modified_at),
                      "MM/dd/yyyy"
                    )}
                </TailwindBox>
                <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
                  <TailwindButton
                    backgroundColor={"primary"}
                    rounded={false}
                    fullWidth={false}
                    width={"w-32"}
                    bold
                    justifyContent={"justify-center"}
                    onClick={_ => onTaxFormClicked()}>
                    Update
                  </TailwindButton>
                </TailwindFlex>
              </TailwindFlex>
            )}
            {!isPendingTaxFormUpload && (
              <TailwindFlex
                padding={["p-3"]}
                height={"h-16"}
                textColor={"text-main-color"}
                justifyContent={"justify-center"}
                alignItems={"items-center"}>
                <TailwindBox width={"w-5/12"}>
                  {intl.formatMessage({
                    id: "accountSettings.updateDocuments.updatedTaxForm",
                    defaultMessage: "Updated Tax Form",
                  })}
                  {isPendingTaxFormApproval && (
                    <TailwindPill
                      backgroundColor={"bg-yellow-500"}
                      textColor={"text-white"}
                      margin={["ml-1"]}>
                      <TailwindTranslatedText
                        display={"inline"}
                        descriptor={{
                          id: "accountSettings.updateDocuments.pending",
                          defaultMessage: "Pending",
                        }}
                      />
                    </TailwindPill>
                  )}
                </TailwindBox>
                <TailwindBox width={"w-5/12"}>
                  {profile?.tax_form.modified_at &&
                    format(
                      new Date(profile.tax_form.modified_at),
                      "MM/dd/yyyy"
                    )}
                </TailwindBox>
                <TailwindFlex
                  width={"w-2/12"}
                  alignItems={"items-center"}
                  justifyContent={"justify-end"}></TailwindFlex>
              </TailwindFlex>
            )}
          </>
        )}
        {!isLoadingDocuments && !documents.length && !allowTaxFormUpload && (
          <TailwindFlex
            padding={["p-3"]}
            height={"h-16"}
            justifyContent={"justify-center"}
            alignItems={"items-center"}>
            <TailwindFlex padding={["p-2"]}>
              <TailwindTranslatedText
                fontSize={"text-sm"}
                textColor={"text-main-color"}
                descriptor={{
                  id: "accountSettings.updateDocuments.noDocuments",
                  defaultMessage: "You have no signed documents.",
                }}
              />
            </TailwindFlex>
          </TailwindFlex>
        )}
      </TailwindBox>

      {!isLoadingDocuments && !documents.length && (
        <TailwindButton
          float={"float-right"}
          margin={["mt-4"]}
          pseudoClasses={["sm:w-full", "sm:m-0"]}
          fullWidth={false}
          onClick={() => onNoDocumentsClicked()}
          backgroundColor={"primary"}
          rounded={false}
          alignItems={"items-center"}
          textProps={{
            textColor: "text-white",
            textTransform: "capitalize",
          }}>
          <TailwindTranslatedText
            paragraphProps={{
              textTransform: "capitalize",
              fontWeight: "font-bold",
              textColor: "text-white",
            }}
            fontWeight={"font-bold"}
            descriptor={{
              id: "accountSettings.updateDocuments.updateDocuments",
              defaultMessage: "Update Your Documents",
            }}
          />
        </TailwindButton>
      )}
    </TailwindCard>
  );
};

export default injectIntl(
  inject(
    "modalStore",
    "accountSettingsStore",
    "authStore",
    "profileStore"
  )(observer(UpdateDocuments))
);
