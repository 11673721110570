import LocalStorage from "../local-storage";

const CACHE_TYPES = {
  store: "STORE",
};

export default class Cache {
  public cacheData(key: string, data: any, type: string = "store"): void {
    LocalStorage.set(key + "_" + CACHE_TYPES[type], data);
  }

  public getCachedData(key: string, type: string = "store"): any {
    return LocalStorage.get(key + "_" + CACHE_TYPES[type]);
  }

  public async getCachedDataOrFetch(
    key: string,
    fetchData: Function,
    timeout?: number,
    type: string = "store"
  ): Promise<any> {
    const storekey = key + "_" + CACHE_TYPES[type];
    const cachedData = LocalStorage.get(storekey);
    if (!!cachedData) {
      return cachedData;
    } else {
      const data = await fetchData();
      LocalStorage.set(storekey, data);
      if (!!timeout && timeout < 10000000) {
        setTimeout(_ => LocalStorage.set(storekey, data), timeout);
      }
      return data;
    }
  }

  public deleteCachedData(key: string, type: string = "store"): void {
    LocalStorage.remove(key + "_" + CACHE_TYPES[type]);
  }

  public deleteCache(type: string = "store"): void {
    const keyChoice: (key: string) => boolean = key => key.split("_").slice(-1).pop() === CACHE_TYPES[type];
    LocalStorage.clearSpecific(keyChoice);
  }
}
