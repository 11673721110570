import flat from "flat";
import localeUS from "./en-US.json";
import localeES from "./es.json";

// import localeCN from "./zh-CN.json";
import localeXX from "./xx-XX.json";

type LanguageTag = "default" | "en-US" | "zh-CN" | "es";
const defaultLang = flat(localeUS);
const LANGUAGES = [
  {
    key: "en-US",
    name: "English",
  },
  {
    key: "es",
    name: "Español"
  }
];

const LOCALES_DATA: { [name in LanguageTag]: any } = {
  default: defaultLang,
  "en-US": defaultLang,
  "es": flat(localeES),
  "zh-CN": flat(localeXX),
};

export { LANGUAGES, LOCALES_DATA };
