import React from "react";
import "./styles.scss";

type GiftCardIconProps = {
  className?: string;
};

const GiftCardIcon: React.ComponentType<GiftCardIconProps> = ({
  className,
}) => (
  <span className={`${className} camsIcon`}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
      <path d='M96.27,31.8c0-3.65-1.45-5.13-5-5.13H82L82.76,25a13.68,13.68,0,0,0-1.67-14,12,12,0,0,0-7.89-4.5A11.63,11.63,0,0,0,64.52,9L53.11,18A8.37,8.37,0,0,0,47,18L35.56,9a11.56,11.56,0,0,0-8.67-2.5A11.91,11.91,0,0,0,19,11,13.66,13.66,0,0,0,17.32,25l.75,1.67H8.54c-3.17,0-4.71,1.58-4.72,4.85q0,3.82,0,7.65c0,3.51,1.52,5.07,4.92,5.07H9.85V80.85A13.25,13.25,0,0,0,23.08,94.08H77A13.25,13.25,0,0,0,90.23,80.85V44.24h1.25c4.28,0,4.78-2.81,4.78-4.93,0-1.93,0-3.86,0-5.78ZM67.63,12.86a6.59,6.59,0,0,1,5-1.44,6.94,6.94,0,0,1,4.58,2.64,8.64,8.64,0,0,1,1,8.88l-1.69,3.73H58.7a9.44,9.44,0,0,0-1.57-5.43ZM52.15,23.15a4.11,4.11,0,0,1,1.55,3.29H46.39A4.1,4.1,0,0,1,48,23.09,3.41,3.41,0,0,1,52.15,23.15Zm-30.28-.21a8.64,8.64,0,0,1,1-8.88,6.94,6.94,0,0,1,4.58-2.64,6.59,6.59,0,0,1,5,1.44L43,21.24a9.6,9.6,0,0,0-1.58,5.42H23.56ZM38.12,89.08h-15a8.24,8.24,0,0,1-8.23-8.23V44.25H41.67V89.08Zm47.11-8.23A8.24,8.24,0,0,1,77,89.08H58.41V44.25H85.23ZM53.41,89.08H46.67V44.24h6.74ZM91.26,39.24H8.82v-.07q0-3.75,0-7.5H91.26a.53.53,0,0,1,0,.13v1.73C91.27,35.44,91.27,37.34,91.26,39.24Z' />
    </svg>
  </span>
);

export default GiftCardIcon;
