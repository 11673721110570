import React from "react";
import AuthStore from "core/stores/auth/AuthStore";
import CamsModelEarningsContainer from "./model";
import CamsStudioEarningsContainer from "./studio";
import { inject, observer } from "mobx-react";

type Props = {
  authStore?: AuthStore;
};

const CamsEarningsContainer: React.ComponentType<Props> = ({ authStore }) => {
  const { userRole } = authStore!;

  if (userRole === "model") {
    return <CamsModelEarningsContainer />;
  } else if (userRole === "studio") {
    return <CamsStudioEarningsContainer />;
  }

  return null;
};

export default inject("authStore")(observer(CamsEarningsContainer));
