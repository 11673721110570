import { validationStore } from "library/core/stores/validation/ValidationStore";
import { modalStore } from "library/core/stores/modal";
import { snackbarStore } from "library/core/stores/snackbar/SnackbarStore";
import { languageStore } from "library/core/stores/language/LanguageStore";
import { layoutStore } from "library/core/stores/layout/LayoutStore";
import { themeStore } from "library/core/stores/theme/ThemeStore";
import { recaptchaStore } from "library/components/_tailwind/recaptcha/store";
import { showcaseStore } from "core/stores/showcase/ShowcaseStore";
import { routeStore } from "core/stores/route/RouteStore";
import { Services, Stores } from "core/stores/types";
import { broadcastStreamService } from "common/broadcast/_stores/broadcast-stream/BroadcastStreamService";
import { toJS } from "mobx";
import {
  cache,
  dateProcess,
  formatter,
  history,
  logger,
  uuid,
} from "library/core/utility";
import { api, tokenHelper } from "core/utility";
import CookieStorage from "library/core/utility/cookie-storage";
import LocalStorage from "library/core/utility/local-storage";
import SessionStorage from "library/core/utility/session-storage";
import { broadcastStrategy } from "common/broadcast/_stores/BroadcastStrategy";
import { graylog } from "library/core/utility/logger/GrayLog";
import { UserRole } from "library/core/types";
import { getUserRoleFromDomain } from "library/core/stores/theme/utils";
import AuthStore from "core/stores/auth/AuthStore";
import EarningsStore from "common/earnings/_stores/earnings/EarningsStore";
import ModelStore from "containers/studio/studio-models/stores/models/ModelStore";
import PayoutStore from "common/payout/stores/payout/PayoutStore";
import GiftsAndAwardsStore from "containers/model/gifts-and-awards/stores/gifts-and-awards/GiftsAndAwards";
import PricingStore from "common/broadcast/_stores/pricing/PricingStore";
import MediaManagerStore from "containers/_components/media-manager/store/MediaManagerStore";
import ContestStore from "containers/model/contests/stores/contests/ContestsStore";
import BroadcastStore from "common/broadcast/_stores/broadcast/BroadcastStore";
import ChatStore from "common/broadcast/_stores/chat/ChatStore";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import AccountSettingsStore from "common/account-settings/stores/account-settings/AccountSettingsStore";
import MessageStore from "common/messages/stores/messages/MessageStore";
import StudioModelsAccessRightsStore from "containers/studio/studio-models/stores/studio-models-access-rights/StudioModelsAccessRightsStore";
import MyFansStore from "containers/model/my-fans/stores/my-fans/MyFansStore";
import ReferralsStore from "containers/model/referrals/stores/referrals/ReferralsStore";
import LeaderBoardStore from "common/leaderboard/store/LeaderBoardStore";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import NodeChatToken from "common/broadcast/_stores/nodechat/NodeChatToken";
import NodeChatSocket from "common/broadcast/_stores/nodechat/NodeChatSocket";
import NodeChatStore from "common/broadcast/_stores/nodechat/NodeChatStore";
import NotificationStore from "common/messages/stores/notifications/NoticationStore";
import AudioMessageRecorderStore from "common/messages/stores/audio/AudioMessageRecorderStore";

/*
  app stores should be instantiated in a particular order
  otherwise you will receive 'cannot access "store" before initialization' error
*/
export const authStore = new AuthStore();
export const profileStore = new ProfileStore();
export const broadcastStore = new BroadcastStore();
export const broadcastStreamStore = new BroadcastStreamStore();
export const chatStore = new ChatStore();
export const nodeChatToken = new NodeChatToken();
export const nodeChatSock = new NodeChatSocket();
export const nodeChatStore = new NodeChatStore();
export const earningsStore = new EarningsStore();
export const modelStore = new ModelStore();
export const payoutStore = new PayoutStore();
export const giftsAndAwardsStore = new GiftsAndAwardsStore();
export const pricingStore = new PricingStore();
export const mediaManagerStore = new MediaManagerStore();
export const contestsStore = new ContestStore();
export const accountSettingsStore = new AccountSettingsStore();
export const messageStore = new MessageStore();
export const studioModelsAccessRightsStore =
  new StudioModelsAccessRightsStore();
export const myFansStore = new MyFansStore();
export const referralsStore = new ReferralsStore();
export const leaderBoardStore = new LeaderBoardStore();
export const notificationStore = new NotificationStore();
export const audioMessageRecorderStore = new AudioMessageRecorderStore();

export const stores: Stores = {
  /* library stores start*/
  /* library stores should not be instantiated above, only app stores should be instantiated here */
  routeStore,
  snackbarStore,
  languageStore,
  validationStore,
  layoutStore,
  modalStore,
  themeStore,
  recaptchaStore,
  showcaseStore,
  /* library stores end*/
  /* app stores start*/
  earningsStore,
  authStore,
  modelStore,
  payoutStore,
  giftsAndAwardsStore,
  pricingStore,
  mediaManagerStore,
  contestsStore,
  broadcastStore,
  chatStore,
  nodeChatStore,
  profileStore,
  accountSettingsStore,
  messageStore,
  studioModelsAccessRightsStore,
  myFansStore,
  referralsStore,
  leaderBoardStore,
  broadcastStreamStore,
  notificationStore,
  audioMessageRecorderStore,
  /* app stores end*/
};

export const services: Services = {
  broadcastStreamService,
};

if (!(window as any).cams) {
  (window as any).cams = {};
}

((window as any) || {}).cams.modelsStudios = {
  stores,
  services,
  broadcastStrategy,
  utilities: {
    formatter,
    cookieStorage: CookieStorage,
    localStorage: LocalStorage,
    sessionStorage: SessionStorage,
    logger,
    dateProcess,
    cache,
    uuid,
    history,
    api,
    tokenHelper,
  },
};

export const getUserRole = (): UserRole | null => {
  return getUserRoleFromDomain();
};

export const getBaseLogPayload = () => {
  return {
    userRole: getUserRoleFromDomain(),
    isStudioModel: profileStore.isStudioModel ? "true" : "false",
    profileStatus: profileStore.profileAccountStatus,
    modelHandle:
      profileStore.modelProfile.screen_name ||
      profileStore.modelProfile.username,
    isTestModel: profileStore.isTestModel ? "true" : "false",
    isProfileBioFieldsCompleteForModel: profileStore.isProfileBioFieldsComplete
      ? "true"
      : "false",
    isProfileRegistrationCompleted: profileStore.profileRegistrationCompleted
      ? "true"
      : "false",
    accessRightsForStudioModel:
      studioModelsAccessRightsStore.accessRightsObject,
    geolocationCountry: profileStore.geolocationCountry,
    geolocationIP: profileStore.geolocationIP,
  };
};

export const logToGraylog = (
  logPrefix: string,
  message: string,
  data: Object
) => {
  graylog.logImmediate(logPrefix + " " + message, undefined, {
    ...getBaseLogPayload(),
    ...data,
    timestamp: new Date().getTime(),
  });
};

api.setInterceptorResponseFunctions(
  response => response,
  authStore.onAxiosResponseInterceptorError
);

((window as any) || {}).mobxToJS = toJS;
((window as any) || {}).getBaseLogPayload = getBaseLogPayload();
