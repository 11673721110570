import React from "react";
import { Box, Icon } from "@material-ui/core";
import TailwindBox from "library/components/_tailwind/box";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";

type Props = {
  remove: () => void;
  progress: number;
};

const UploadingTile: React.ComponentType<Props> = ({
  remove,
  progress = 0,
}) => {
  return (
    <TailwindBox
      className={["img-container", "MessageCenterReaderUploadingTile"]}
      height='h-full'
      position='relative'
      borderRadius={"rounded-md"}>
      <TailwindFlex
        alignItems='items-center'
        justifyContent='justify-center'
        textAlign='text-center'
        flexDirection='flex-col'
        position='absolute'
        gap='gap-1'
        height='h-full'>
        <TailwindFlex
          alignItems='items-center'
          justifyContent='justify-center'
          textAlign='text-center'>
          <TailwindTranslatedText
            descriptor={{
              id: "message-center.processing-media",
              defaultMessage: "Processing Media",
            }}></TailwindTranslatedText>
        </TailwindFlex>
        <TailwindFlex>
          <TailwindTranslatedText
            alignItems='items-center'
            justifyContent='justify-center'
            textAlign='text-center'
            descriptor={{
              id: "message-center.processing-media-description",
              defaultMessage: "Large files may take several minutes to upload!",
            }}></TailwindTranslatedText>
        </TailwindFlex>
        <TailwindFlex
          className={[
            "MessageCenterReaderUploadingTile__progress-bar-container",
          ]}>
          <Box className={"MessageCenterReaderUploadingTile__progress-bar"}>
            <Box
              className={"MessageCenterReaderUploadingTile__progress-bar-fill"}
              style={{
                width: `${progress}%`,
              }}></Box>
          </Box>
        </TailwindFlex>
      </TailwindFlex>
      <TailwindBox
        position='absolute'
        inset={["top-1", "right-1"]}
        onClick={() => remove()}
        className={["MessageCenterReaderUploadingTile__button-remove"]}>
        <Icon>close</Icon>
      </TailwindBox>
    </TailwindBox>
  );
};

export default UploadingTile;
