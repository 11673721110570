import React from "react";
import { FormattedMessage } from "react-intl";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";

type Props = {
  src: string;
  amount: number;
};

const MessageGift: React.ComponentType<Props> = ({ src, amount }) => {
  return (
    <div className='MessageGift'>
      <div className='MessageGift__image'>
        <img src={src} />
      </div>
      <div className='MessageGift__amount'>
        <FormattedMessage
          id='message-center.gift-received'
          defaultMessage='{amount} Gift Received'
          values={{
            amount: (
              <>
                <TailwindIcon
                  textColor={"text-gray-500"}
                  name={CustomIconName.token}
                  fontSize={"text-lg"}
                />
                {amount}
              </>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default MessageGift;
