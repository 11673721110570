import { inject, observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CHAT_MEMBER_COLOR_MAPPING } from "../_stores/chat/consts";
import TailwindIcon from "library/components/_tailwind/icon";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import { ClickAwayListener, Popper } from "@material-ui/core";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import NodeChatStore from "../_stores/nodechat/NodeChatStore";
import EmojiFlag from "./chat-item-user-country-flag";
import LanguageStore from "library/core/stores/language/LanguageStore";

interface Props {
  nodeChatStore?: NodeChatStore;
  broadcastStore?: BroadcastStore;
  layoutStore?: LayoutStore;
  userId?: string;
  userName?: string;
  chatTooltip?: boolean;
  onUserTierColor?: (color: string) => void;
  languageStore?: LanguageStore;
}

const ChatItemUsernameTooltip: React.FunctionComponent<Props> = ({
  children,
  userId,
  userName,
  nodeChatStore,
  broadcastStore,
  layoutStore,
  chatTooltip = true,
  onUserTierColor,
  languageStore
}) => {
  if (!userId || !nodeChatStore || !broadcastStore || !layoutStore) {
    return null;
  }
  
  const [nameColor, setNameColor] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);
  const [popperWidth, setPopperWidth] = useState(260);
  const { isPrivateConsentOpen, bountyOrderMember, fanClubOrderMember } = broadcastStore!;
  const { screenSize } = layoutStore!;
  const { intl } = languageStore!;

  const bountyOrdered = bountyOrderMember === userName;
  const fanClubOrdered = fanClubOrderMember === userName;

  const member = nodeChatStore?.allMemberHash?.[userId];
  const spendingTier = member?.sort;
  const isFanClub = !!member?.isFanClub || (fanClubOrdered && !member?.isFanClub);
  const isPremiere = !!member?.isPremiere;
  const isHighValue = !!member?.isHighValue;
  const hasToken = !!member?.hasTokens;
  const isBounty = !!member?.isBounty || (bountyOrdered && !member?.isBounty);
  const isBountyTarget = !!member?.isBountyTarget && !bountyOrdered;
  const memberRank = member?.topAdmirer || 0;
  const isTopAdmirer = memberRank >= 1 && memberRank <= 5;
  const showTooltip = useMemo(() => !!member, [userId, nodeChatStore?.allMemberHash]);

  // Define spending tier text locally
  const SpendingTierText = {
    0.8: intl.formatMessage({
      id: "broadcast.chatItem.spending-tier-text.08",
      defaultMessage: "Spent 1000+ tokens in the past two weeks",
    }),
    0.6: intl.formatMessage({
      id: "broadcast.chatItem.spending-tier-text.06",
      defaultMessage: 'Spent 250+ tokens in the past two weeks',
    }),
    0.4: intl.formatMessage({
      id: "broadcast.chatItem.spending-tier-text.04",
      defaultMessage: "Spent 50+ tokens in the past two weeks",
    })
  };

  useEffect(() => {
    const setColor = () => {
      if (isTopAdmirer) {
        setNameColor(CHAT_MEMBER_COLOR_MAPPING.TOP_ADMIRER);
      } else if (isBounty) {
        setNameColor(CHAT_MEMBER_COLOR_MAPPING.BOUNTY_COLOR_DEFAULT);
      } else if (isBountyTarget) {
        setNameColor(CHAT_MEMBER_COLOR_MAPPING.DEFAULT_COLOR_DEFAULT);
      }  else if (isPremiere && !isFanClub) {
        setNameColor(CHAT_MEMBER_COLOR_MAPPING.PREM_COLOR_DEFAULT);
      } else if (isFanClub && !isPremiere) {
        setNameColor(CHAT_MEMBER_COLOR_MAPPING.FAN_COLOR_DEFAULT);
      } else if (isFanClub && isPremiere) {
        setNameColor(CHAT_MEMBER_COLOR_MAPPING.FAN_COLOR_DEFAULT);
      } else if (spendingTier && spendingTier >= 0.8) {
        setNameColor(CHAT_MEMBER_COLOR_MAPPING.SPENT_TIER_THREE);
      } else if (spendingTier && spendingTier >= 0.6) {
        setNameColor(CHAT_MEMBER_COLOR_MAPPING.SPENT_TIER_TWO);
      } else if (spendingTier && spendingTier >= 0.4) {
        setNameColor(CHAT_MEMBER_COLOR_MAPPING.SPENT_TIER_ONE);
      } else if (hasToken) {
        setNameColor(CHAT_MEMBER_COLOR_MAPPING.PAID_COLOR_DEFAULT);
      } else {
        setNameColor(CHAT_MEMBER_COLOR_MAPPING.DEFAULT_COLOR_DEFAULT);
      }
    };
    setColor();
  }, [isFanClub, isPremiere, hasToken, isBounty, isBountyTarget, spendingTier, isTopAdmirer, bountyOrderMember, bountyOrdered]);

  useEffect(() => {
    if (nameColor) {
      onUserTierColor?.(nameColor);
    }
  }, [nameColor]);

  const hideTooltip = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    if (isPrivateConsentOpen) {
      setAnchorEl(null);
    }
  }, [isPrivateConsentOpen]);

  useEffect(() => {
    setAnchorEl(null);
  }, [screenSize]);

  const handleTooltipClick = (event: React.MouseEvent<HTMLElement>) => {
    let target: any;

    if (chatTooltip) {
      target = event.target;
    } else {
      target = event.currentTarget.closest(".BroadcastViewers__member-item");
      setPopperWidth(target?.clientWidth ? target.clientWidth - 30 : 260);
    }

    setAnchorEl(target as any);
  };

  return showTooltip ? (
    <>
      <span
        onClick={handleTooltipClick}
        className="ChatItem__UsernameLink"
        style={{ color: nameColor }}
      >
        {children}
      </span>

      <Popper
        style={
          chatTooltip
            ? { marginBottom: 10, maxWidth: 260 }
            : { width: popperWidth, marginLeft: 15, marginTop: -25 }
        }
        placement={chatTooltip ? "top" : "bottom-start"}
        className='ChatItem__UsernameWrapper'
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
      >
        <ClickAwayListener onClickAway={hideTooltip}>
          <div
            onClick={e => {
              e.stopPropagation();
            }}
            className={`ChatItem__UsernameTooltip ${
              chatTooltip ? "" : "ChatItem__MemberListTooltip"
            }`}
          >
            <div
              className='ChatItem__UsernameTooltipHeader'
              style={{ color: nameColor }}>
              {member?.username}
              <EmojiFlag code={member?.country ?? ''} />
            </div>

            <div onClick={hideTooltip} className="ChatItem__UsernameTooltipClose">
              <TailwindIcon name={"close"} />
            </div>

            <div className='ChatItem__UsernameTooltipBody'>
              {chatTooltip && isTopAdmirer && (
                <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--TopAdmirer'>
                  {intl.formatMessage(
                    {
                      id: "broadcast.chatItem.tooltip-member-labels.top-admirer",
                      defaultMessage: `Top Admirer #${memberRank}`,
                    },
                    { memberRank }
                  )}
                </div>
              )}
              {chatTooltip && (isBounty || (bountyOrdered && !isBounty)) && (  
                <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--Bounty'>
                  {intl.formatMessage({
                    id: "broadcast.chatItem.tooltip-member-labels.bounty",
                    defaultMessage: "Bounty",
                  })}
                </div>
              )}
              {chatTooltip && isFanClub && (
                <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--FanClub'>
                  {intl.formatMessage({
                    id: "broadcast.chatItem.tooltip-member-labels.fan-club",
                    defaultMessage: "Fun Club",
                  })}
                </div>
              )}
              {chatTooltip && isPremiere && (
                <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--Premiere'>
                  {intl.formatMessage({
                    id: "broadcast.chatItem.tooltip-member-labels.premiere",
                    defaultMessage: "Premiere",
                  })}
                </div>
              )}
              {chatTooltip && isHighValue && (
                <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--HighValue'>
                  {intl.formatMessage({
                    id: "broadcast.chatItem.tooltip-member-labels.high-value",
                    defaultMessage: "High Value",
                  })}
                </div>
              )}
              {chatTooltip && isBountyTarget && !bountyOrdered ? (
                <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--Bounty'>
                  {intl.formatMessage({
                    id: "broadcast.chatItem.tooltip-member-labels.bounty-target",
                    defaultMessage: "Bounty Target",
                  })}                  
                </div>
              ) : ''}               

              {chatTooltip && hasToken && (
                <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--HasToken'>
                  {intl.formatMessage({
                    id: "broadcast.chatItem.tooltip-member-labels.has-tokens",
                    defaultMessage: "Has tokens",
                  })}                
                </div>
              )}

              {chatTooltip && !hasToken && (
                <div className='ChatItem__UsernameTooltipBadge'>
                  {intl.formatMessage({
                    id: "broadcast.chatItem.tooltip-member-labels.no-tokens",
                    defaultMessage: "No tokens",
                  })}
                </div>
              )}

              {!chatTooltip && (isTopAdmirer || isBounty || isFanClub) && (
                <div className='ChatItem__UsernameTooltipSection'>
                  {isTopAdmirer && (
                    <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--TopAdmirer'>
                      {intl.formatMessage(
                        {
                          id: "broadcast.chatItem.tooltip-member-labels.top-admirer",
                          defaultMessage: `Top Admirer #{memberRank}`,
                        },
                        { memberRank }
                      )}
                    </div>
                  )}
                  {isBounty && (
                    <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--Bounty'>
                      {intl.formatMessage({
                        id: "broadcast.chatItem.tooltip-member-labels.bounty",
                        defaultMessage: "Bounty",
                      })}
                    </div>
                  )}
                  {isFanClub && (
                    <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--FanClub'>
                      {intl.formatMessage({
                        id: "broadcast.chatItem.tooltip-member-labels.fan-club",
                        defaultMessage: "Fun Club",
                      })}
                    </div>
                  )}
                </div>
              )}

              {!chatTooltip && (hasToken || spendingTier) && (
                <div className='ChatItem__UsernameTooltipSection'>
                  {spendingTier && spendingTier > 0 && SpendingTierText[spendingTier] ? (
                    <div
                      className={`ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--SpendingTier ChatItem__UsernameTooltipBadge--SpendingTier-${spendingTier}`}>
                      {`${SpendingTierText[spendingTier]}`}
                    </div>
                  ) : (
                    hasToken || (bountyOrdered && !hasToken) && (
                      <div className='ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--HasToken'>
                        {intl.formatMessage({
                          id: "broadcast.chatItem.tooltip-member-labels.has-tokens",
                          defaultMessage: "Has tokens",
                        })}
                      </div>
                    )
                  )}
                </div>
              )}

              {!chatTooltip &&
                !isTopAdmirer &&
                !isBounty &&
                !isBountyTarget &&
                !isPremiere &&
                !isHighValue &&
                !isFanClub &&
                !hasToken &&
                !spendingTier && (
                  <div className='ChatItem__UsernameTooltipSection'>
                    {intl.formatMessage({
                      id: "broadcast.chatItem.check-back-later",
                      defaultMessage: "Check Back Later",
                    })}
                  </div>
                )}
            </div>
            
            {chatTooltip &&
              (spendingTier && spendingTier > 0 && SpendingTierText[spendingTier] ? (
                <div
                  className={`ChatItem__UsernameTooltipBadge ChatItem__UsernameTooltipBadge--SpendingTier ChatItem__UsernameTooltipBadge--SpendingTier-${spendingTier}`}>
                  {`${SpendingTierText[spendingTier]}`}
                </div>
              ) : null)}
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  ) : (
    <span className='ChatItem__UsernameLink' style={{ color: nameColor }}>
      {children}
    </span>
  );
};

export default inject(
  "nodeChatStore",
  "broadcastStore",
  "layoutStore",
  "languageStore"
)(observer(ChatItemUsernameTooltip));
