import { TailwindDropdownItem } from "library/components/_tailwind/dropdown";
import { ModelMediaVisibility, VideoRejectReasonEnum } from "./enums";

export const MY_PAGE_ACCEPTED_IMAGE_EXTENSIONS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
];
export const MY_PAGE_ACCEPTED_VIDEO_EXTENSIONS = [
  "video/m4v",
  "video/avi",
  "video/mpg",
  "video/mp4",
  // "video/3gpp",
  // "video/flv",
  // "video/mkv",
  "video/quicktime",
];
export const MY_PAGE_ACCEPTED_FILE_EXTENSIONS = [
  ...MY_PAGE_ACCEPTED_IMAGE_EXTENSIONS,
  ...MY_PAGE_ACCEPTED_VIDEO_EXTENSIONS,
];
export const MY_PAGE_ACCEPTED_MAXIMUM_FILES_FOR_DEFAULT_ALBUM = 1;
export const MY_PAGE_ACCEPTED_MAXIMUM_FILES_FOR_VIDEO_ALBUM = 5;
export const MY_PAGE_ACCEPTED_MAXIMUM_FILES_FOR_CUSTOM_ALBUM = 5;
export const MY_PAGE_ACCEPTED_MAXIMUM_FILES_FOR_PROFILE_PHOTO = 1;
export const InitialData = {
  selectedAlbumId: null,
  selectedMediaId: null,
  selectedMediaType: null,
  isSavedSelectedAlbum: false,
  isSavedSelectedMedia: false,
  uploadQueue: [],
  isLoadingAlbums: true,
  hasLoadedAlbums: false,
  isLoadingImages: false,
  isLoadingVideos: false,
  promptMode: null,
  requiresSaving: false,
  activeAlbumFilter: "all",
  activeStatusFilter: "all",
  activeMediaSorting: "latest",
  isSaving: false,
  promptResolveCb: null,
  promptRejectCb: null,
  editorInst: null,
  formMediaName: "",
  formMediaDescription: "",
  formMediaPrice: 0,
  formMediaIsCover: false,
  formErrors: {},
  selectedAlbumIdForNewImage: "",
  pendingConversionMediasReaction: undefined,
  currentRoutePathNameReaction: undefined,
  defaultAlbumPrices: {
    photo_album_default: 0,
    video_default: 0,
    custom_video_default: 0,
  },
  albumNextHash: {},
};

export const MEDIA_MANAGER_VISIBILTY_DROPDOWN_ITEMS: TailwindDropdownItem[] = [
  {
    label: "Private",
    value: ModelMediaVisibility.PRIVATE,
  },
  {
    label: "Guests",
    value: ModelMediaVisibility.GUESTS,
  },
  {
    label: "Members",
    value: ModelMediaVisibility.MEMBERS,
  },
  {
    label: "Fan Club",
    value: ModelMediaVisibility.FAN_CLUB,
  },
];

export const MEDIA_MANAGER_ALBUM_PRICE_DROPDOWN_ITEMS: TailwindDropdownItem[] =
  [
    {
      label: "Free",
      value: 0,
    },
    {
      label: "20 tokens",
      value: 20,
    },
    {
      label: "50 tokens",
      value: 50,
    },
    {
      label: "100 tokens",
      value: 100,
    },
    {
      label: "150 tokens",
      value: 150,
    },
    {
      label: "200 tokens",
      value: 200,
    },
    {
      label: "250 tokens",
      value: 250,
    },
    {
      label: "500 tokens",
      value: 500,
    },
  ];

export const MEDIA_MANAGER_RECORDED_ALBUM_DEFAULT_ITEM_PRICE_DROPDOWN_ITEMS: TailwindDropdownItem[] =
  [
    {
      label: "Free",
      value: 0,
    },
    {
      label: "20 tokens",
      value: 20,
    },
    {
      label: "50 tokens",
      value: 50,
    },
    {
      label: "100 tokens",
      value: 100,
    },
    {
      label: "150 tokens",
      value: 150,
    },
    {
      label: "200 tokens",
      value: 200,
    },
    {
      label: "250 tokens",
      value: 250,
    },
    {
      label: "500 tokens",
      value: 500,
    },
  ];

export const VIDEO_REJECT_REASONS_MAP = {
  [VideoRejectReasonEnum.DENIED_VIOLATION_OF_TOS]: "Violation of Terms of Use",
  [VideoRejectReasonEnum.DENIED_VIDEO_UNPLAYABLE]: "Video unplayable",
  [VideoRejectReasonEnum.DENIED_PERSONAL_CONTACT_INFO]:
    "Personal contact information",
  [VideoRejectReasonEnum.DENIED_COMMERCIAL_SOLICITATION]:
    "Commercial Solicitation or Advertisement",
  [VideoRejectReasonEnum.DENIED_COMMERCIAL_VIDEO]:
    "Commercial Video/Celebrity/Public",
  [VideoRejectReasonEnum.DENIED_ILLEGAL_ACTIVITY]:
    "Illegal, violent or unsafe activity on the site",
  [VideoRejectReasonEnum.DENIED_STOLEN_CONTENT]: "Stolen from other member",
  [VideoRejectReasonEnum.DENIED_STOLEN_FROM_PORN_SITE]:
    "Stolen from known porn site",
  [VideoRejectReasonEnum.DENIED_INAPPROPRIATE]: "Inappropriate title",
  [VideoRejectReasonEnum.DENIED_INAPPROPRIATE_DESCRIPTION]:
    "Inappropriate description",
  [VideoRejectReasonEnum.DENIED_OTHER]: "Other",
  [VideoRejectReasonEnum.DENIED_VIDEO_COULD_NOT_PROCESS]:
    "Video could not be processed",
  [VideoRejectReasonEnum.DENIED_UNAUTHORIZED_PERFORMER]:
    "Unauthorized Performer",
};

export const NEW_ALBUM_DEFAULT_NAME = "New Album";

export const MEDIA_PER_PAGE_LIMIT = 50;
