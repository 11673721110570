import React from "react";
import "./styles.scss";
import classnames from "classnames";

type arrowLeftProps = {
  className?: string;
};

const ArrowLeft: React.ComponentType<arrowLeftProps> = ({
  className,
}) => (
  <span className={classnames("arrowLeft", className && className)}>
<svg xmlns="http://www.w3.org/2000/svg"  id="Layer_1" x="0px" y="0px" viewBox="0 0 386.258 386.258">
<polygon points="96.879,193.129 289.379,386.258 289.379,0 "/>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
  </span>
);

export default ArrowLeft;
