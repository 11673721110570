import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./styles.scss";
import { DropzoneProps, useDropzone } from "react-dropzone";
import CONFIG from "../../core/config";
import TailwindBox, { TailwindBoxProps } from "../_tailwind/box";
import shortid from "shortid";

export type FileUploadProcessStatus =
  | "processing"
  | "accepted"
  | "rejected"
  | "validation"
  | undefined;

export type FileUploadProps = TailwindBoxProps & {
  previewUrl?: string;
  showPreviewUrlAsBackgroundImage?: boolean;
  processStatus?: FileUploadProcessStatus;
  showStatus?: boolean;
  fileName?: string;
  shape?: "rectangle" | "circle" | "soft-rectangle";
  error?: boolean;
  helperText?: string;
  showHelperText?: boolean;
  customIcon?: React.ReactElement;
  acceptedText?: string;
  dropzoneProps?: DropzoneProps;
  stopPropagation?: boolean;
  altBaseClassName?: string;
  isUploading?: boolean;
  disable?: boolean;
};

const FileUploadMessages: React.ComponentType<FileUploadProps> = ({
  dropzoneProps: _dropzoneProps,
  previewUrl,
  processStatus = undefined,
  showStatus = true,
  fileName,
  shape = "circle",
  helperText,
  showHelperText = true,
  error,
  className = "",
  customIcon,
  showPreviewUrlAsBackgroundImage = false,
  acceptedText = "Your file(s) have been uploaded",
  borderColor = "border-gray-400",
  textColor = "text-gray-300",
  borderStyle = "border-solid",
  borderWidth = ["border"],
  stopPropagation,
  altBaseClassName,
  isUploading = false,
  disable = false,
  ...props
}) => {
  const [prevIsLoadingState, setPrevIsLoadingState] = useState<boolean>(false);
  const dropzoneProps: DropzoneProps = useMemo(() => {
    return {
      maxSize: CONFIG.maximumImageSize.size,
      accept: ["image/jpeg", "image/jpg", "image/png", "application/pdf"],
      maxFiles: 5,
      ..._dropzoneProps,
    };
  }, [_dropzoneProps]);

  const id = shortid.generate();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    inputRef,
  } = useDropzone({
    ...dropzoneProps,
  });

  const wrapperClass = useMemo(() => {
    let _className;
    if (altBaseClassName) {
      _className = `${altBaseClassName} ${altBaseClassName}-${shape} ${className}`;
    } else {
      _className = `GenericFileUploadDropzone GenericFileUploadDropzone--shape-${shape} ${className}`;
    }

    if (isDragActive) {
      _className += " GenericFileUploadDropzone__active";
    }
    if (isDragAccept) {
      _className += " GenericFileUploadDropzone__accept";
    }
    if (isDragReject || error) {
      _className += " GenericFileUploadDropzone__reject";
    }

    return _className;
  }, [isDragActive, isDragAccept, isDragReject, className, error]);

  const promptStyle = useMemo(() => {
    if (showPreviewUrlAsBackgroundImage) {
      return {
        backgroundImage: `url('${previewUrl}')`,
        backgroundSize: `cover`,
        backgroundPosition: `center center`,
      };
    }

    return {};
  }, [showPreviewUrlAsBackgroundImage, previewUrl]);

  const _onClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (stopPropagation) {
        event.preventDefault();
        event.stopPropagation();
        const inputEl = document.getElementById(id);
        inputEl?.click();
      }
    },
    [stopPropagation, id]
  );

  useEffect(() => {
    if (prevIsLoadingState && !isUploading && inputRef.current) {
      inputRef.current.value = "";
    }
    setPrevIsLoadingState(isUploading);
  }, [isUploading]);

  return (
    <TailwindBox
      {...(getRootProps({
        className: [wrapperClass] as any,
        onClick: _onClick,
      }) as any)}
      backgroundColor={"bg-gray-300"}
      borderColor={"border-main-color"}
      padding={["p-0"]}
      alignItems={"items-center"}
      justifyContent={"justify-center"}
      {...props}>
      {!disable && <input id={id} {...getInputProps()} />}
      {customIcon && React.cloneElement(customIcon)}
      {previewUrl && (
        <div
          className={"GenericFileUploadDropzone__prompt text-current"}
          style={promptStyle}>
          {fileName && fileName !== "" && (
            <div className={"GenericFileUploadDropzone__filename"}>
              {fileName}
            </div>
          )}
          {<img src={previewUrl} alt='' />}
        </div>
      )}
    </TailwindBox>
  );
};

export default FileUploadMessages;
