import React from "react";
import ProfileBadges, { ProfileBadgesProps } from "../../profile/badges";
import TailwindFlex from "../flex";

export type MediaBadgesOverlayProps = {} & ProfileBadgesProps;

const MediaBadgesOverlay: React.ComponentType<MediaBadgesOverlayProps> = ({
  ...profileBadgesProps
}) => {
  return (
    <TailwindFlex
      height={"h-full"}
      alignItems={"items-end"}
      justifyContent={"justify-end"}
      padding={["pb-4", "pr-4"]}>
      <ProfileBadges {...profileBadgesProps} />
    </TailwindFlex>
  );
};

export default MediaBadgesOverlay;
