import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { inject, observer } from "mobx-react";
import AuthStore from "core/stores/auth/AuthStore";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import { injectIntl, WrappedComponentProps } from "react-intl";
import ProfileStore from "../../my-page/stores/profile/ProfileStore";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { ProfileDocumentType } from "../../my-page/stores/profile/enums";
import TailwindFlex from "library/components/_tailwind/flex";
import FileUpload, {
  FileUploadProcessStatus,
} from "library/components/file-upload";
import { IMAGES_PATH } from "library/core/config/urls";
import TailwindInput from "library/components/_tailwind/input";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import TailwindDropdown, {
  TailwindDropdownValue,
} from "library/components/_tailwind/dropdown";
import moment from "moment";
import TailwindButton from "library/components/_tailwind/button";
import AccountDenyReasons from "../account-deny-reasons";
import { DenyReasonKeys } from "common/my-page/stores/profile/types";

type RegisterUploadStepProps = {
  authStore?: AuthStore;
  validationStore?: ValidationStore;
  profileStore?: ProfileStore;
};

const RegisterStudioUploadStep: FunctionComponent<
  RegisterUploadStepProps & WrappedComponentProps
> = ({ authStore, validationStore, profileStore, intl }) => {
  const { clearErrors, validate, errors } = validationStore!;

  const [frontOfIdImageErrorState, setFrontOfIdImageErrorState] =
    useState<FileUploadProcessStatus>(undefined);

  const [frontOfIdImage, setFrontOfIdImage] = useState<string | undefined>(
    undefined
  );

  const {
    uploadDocument,
    studioProfile,
    //isCams
  } = profileStore!;
  const {
    formRegistrationData,
    updateRegistrationFormField,
    completeDocumentUploadStep,
    countries,
  } = authStore!;
  const { issue_country, expire_date } = formRegistrationData!.studio_document;

  const uploadFrontImageOfId = useCallback(async (files: File[]) => {
    try {
      setFrontOfIdImageErrorState("processing");
      const media = await uploadDocument(files, ProfileDocumentType.ID_FRONT);
      updateRegistrationFormField("studio_document", {
        name: "document_front_key",
        value: media.key,
      });
      validate("document_front_key", media.key);
      setFrontOfIdImageErrorState("accepted");
      setFrontOfIdImage(media?.view_url);
    } catch (error) {
      setFrontOfIdImageErrorState("rejected");
    }
  }, []);

  const hasDenyReasonYourKey = useCallback(
    (reason: DenyReasonKeys) => {
      const res = studioProfile?.deny_reasons?.filter(
        item => item.property == reason
      );
      if (!!res?.length) {
        return res[0].text;
      } else {
        return "";
      }
    },
    [studioProfile]
  );
  useEffect(() => clearErrors(), [validationStore]);

  return (
    <TailwindFlex width={"w-192"} md={"w-11/12"} height={"h-full"}>
      <SimpleBar className='RegisterModal__form-scrollarea'>
        <div className='RegisterModal__form'>
          <AccountDenyReasons />
          <form noValidate autoComplete='false' className={"AuthModal__form"}>
            <div className='w-full flex mt-2 mb-4'>
              <TailwindDropdown
                backgroundColor={"primary"}
                fullWidth={true}
                name={"issue_country"}
                error={
                  errors?.issue_country ||
                  hasDenyReasonYourKey("issue_country") ||
                  ""
                }
                label={intl.formatMessage({
                  id: "common.issuedCountry",
                  defaultMessage: "ID Issued Country",
                })}
                value={issue_country}
                onClick={e => e.preventDefault()}
                onChange={(value: TailwindDropdownValue) => {
                  updateRegistrationFormField("studio_document", {
                    name: "issue_country",
                    value: value,
                  });
                  validate("issue_country", value);
                }}
                items={Object.entries(countries).map(
                  ([isoCode, countryName]) => ({
                    value: isoCode,
                    label: countryName,
                  })
                )}
              />
            </div>
            <div className='w-full flex mt-2 mb-4'>
              <TailwindInput
                required
                rounded={false}
                backgroundColor={"primary"}
                label={intl.formatMessage({
                  id: "common.expireDate",
                  defaultMessage: "Expiration Date",
                })}
                placeholder={"YYYY/MM/DD"}
                placeholderColor={"placeholder-gray-500"}
                errorProps={{
                  textAlign: "text-right",
                }}
                value={
                  moment(
                    expire_date ? new Date(expire_date as string) : new Date()
                  )
                    .utc()
                    .format("YYYY-MM-DD") || ""
                }
                type={TailwindInputType.date}
                name={"expire_date"}
                error={
                  errors?.expire_date
                    ? authStore!.loginRegisterErrors?.expire_date || ""
                    : hasDenyReasonYourKey("expire_date") || ""
                }
                onChange={e => {
                  updateRegistrationFormField("studio_document", {
                    name: "expire_date",
                    value: e.target.value,
                  });
                }}
                onBlur={() => validate("expire_date", expire_date)}
              />
            </div>
            <div className={"RegisterModal__uploadRow"}>
              <div className={"RegisterModal__uploadDocument mt-2 mb-4"}>
                <div className={"mb-4"}>
                  {intl.formatMessage({
                    id: "landing.documents.idDocument",
                    defaultMessage:
                      "Upload a clear photo of your proof of identity",
                  })}
                  .
                </div>
                <div>
                  <FileUpload
                    dropzoneProps={{
                      onDrop: uploadFrontImageOfId,
                      accept: ["image/jpeg", "image/jpg", "image/png"],
                      maxFiles: 1,
                    }}
                    error={
                      !!errors?.document_front_key ||
                      !!hasDenyReasonYourKey("document")
                    }
                    processStatus={frontOfIdImageErrorState}
                    previewUrl={frontOfIdImage}
                    acceptedText={"Photo Uploaded"}
                  />
                </div>
              </div>
              <div className={"RegisterModal__uploadExample"}>
                <span className={"RegisterModal__view-example"}>
                  {intl.formatMessage({
                    id: "common.example",
                    defaultMessage: "Example",
                  })}
                </span>
                <img
                  src={`${IMAGES_PATH}/registration/front_id_sample.png`}
                  alt='front of id'
                />
              </div>
            </div>
            <div className={"RegisterModal__submit"}>
              <TailwindButton
                justifyContent={"justify-center"}
                backgroundColor={"primary"}
                rounded={false}
                fullWidth={false}
                disabled={authStore!.isActionInProgress}
                showSpinner={authStore!.isActionInProgress}
                onClick={completeDocumentUploadStep}>
                {intl.formatMessage({
                  id: "common.next",
                  defaultMessage: "Next",
                })}
              </TailwindButton>
            </div>
          </form>
        </div>
      </SimpleBar>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject(
    "authStore",
    "validationStore",
    "profileStore"
  )(observer(RegisterStudioUploadStep))
);
