import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookSubHeading from "../_internal/sub-heading";
import TailwindAnchor from "../../components/_tailwind/anchor";

type Props = {};

const StorybookSetupDesigns: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Setup &gt; Designs</StorybookHeading>
      <StorybookParagraph>
        Please check all designs before starting coding.
      </StorybookParagraph>
      <StorybookSubHeading>Cams</StorybookSubHeading>
      <StorybookParagraph>
        Cams Members, Cams Studios & Cams Models, you can go to the actual
        running FE code to check the designs.
      </StorybookParagraph>
      <StorybookSubHeading>AFF</StorybookSubHeading>
      <StorybookParagraph>
        Design Guide:&nbsp;
        <TailwindAnchor
          to={
            "https://app.uxpin.com/design-system/aa06000e345a4f3a3077/ui-patterns/189547"
          }
        >
          https://app.uxpin.com/design-system/aa06000e345a4f3a3077/ui-patterns/189547
        </TailwindAnchor>
      </StorybookParagraph>
      <StorybookParagraph>
        AFF Registration:&nbsp;
        <TailwindAnchor
          to={
            "https://preview.uxpin.com/98908ad0bafd438fec65144a47efb0f8659ed68e#/pages//simulate/no-panels?mode=i"
          }
        >
          https://preview.uxpin.com/98908ad0bafd438fec65144a47efb0f8659ed68e#/pages//simulate/no-panels?mode=i
        </TailwindAnchor>
      </StorybookParagraph>
      <StorybookParagraph>
        AFF Billing Order Form:&nbsp;
        <TailwindAnchor
          to={
            "https://preview.uxpin.com/0751eaaf9a332ce51b2c093e40fa79eefdd40fe3#/pages/136966369/simulate/sitemap?mode=i"
          }
        >
          https://preview.uxpin.com/0751eaaf9a332ce51b2c093e40fa79eefdd40fe3#/pages/136966369/simulate/sitemap?mode=i
        </TailwindAnchor>
      </StorybookParagraph>
      <StorybookParagraph>
        AFF Profile:&nbsp;
        <TailwindAnchor
          to={
            "https://preview.uxpin.com/8f630a648c2741c78fe301cf6939ce18e0eb1c7d#/pages/134918594/specification/sitemap?mode=i"
          }
        >
          https://preview.uxpin.com/8f630a648c2741c78fe301cf6939ce18e0eb1c7d#/pages/134918594/specification/sitemap?mode=i
        </TailwindAnchor>
      </StorybookParagraph>
      <StorybookParagraph>
        AFF Homepage:&nbsp;
        <TailwindAnchor
          to={
            "https://preview.uxpin.com/b7eded224ee9315dc5a6ac677ab89128c40494ae#/pages/134517432/specification/sitemap?mode=i"
          }
        >
          https://preview.uxpin.com/b7eded224ee9315dc5a6ac677ab89128c40494ae#/pages/134517432/specification/sitemap?mode=i
        </TailwindAnchor>
      </StorybookParagraph>
    </StorybookWrapper>
  );
};

export default StorybookSetupDesigns;
