import React, { useCallback, useEffect, useMemo } from "react";
import { inject, observer } from "mobx-react";
import GoLiveButton from "containers/_layout/dashboard/header/go-live-button";
import EarningsPageButton from "containers/_layout/dashboard/header/earnings-page-button";
import AuthStore from "core/stores/auth/AuthStore";
import { injectIntl, WrappedComponentProps } from "react-intl";
import classnames from "classnames";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import TailwindText from "library/components/_tailwind/text";
import Logo from "library/components/logo";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindButton from "library/components/_tailwind/button";
import RouteStore from "core/stores/route/RouteStore";
import MessageStore from "common/messages/stores/messages/MessageStore";
import { UserAccountStatus } from "common/my-page/stores/profile/enums";

type HeaderProps = {
  layoutStore?: LayoutStore;
  authStore?: AuthStore;
  profileStore?: ProfileStore;
  routeStore?: RouteStore;
  messageStore?: MessageStore;
};

const Header: React.ComponentType<HeaderProps & WrappedComponentProps> = ({
  layoutStore,
  authStore,
  profileStore,
  routeStore,
  messageStore,
}) => {
  const { userRole } = authStore!;
  const {
    isMobileScreenSize,
    toggleMenu,
    isMenuOpen,
    isLiteMode,
    isMobileDevice,
    setIsBroadcastPage
  } = layoutStore!;
  const { currentRoutePathname } = routeStore!;

  const { isComposing, isSearchingView, activeConversation } = messageStore!;

  const onToggleMenuClicked = useCallback(() => {
    toggleMenu();
  }, []);

  const { profile, isModel, profileAccountStatus } = profileStore!;

  const isBroadCastPage = useMemo(
    () => currentRoutePathname === "/broadcast",
    [currentRoutePathname]
  );

  useEffect(() => {
    setIsBroadcastPage(isBroadCastPage);
  }, [isBroadCastPage]);

  if ((isComposing || isSearchingView || activeConversation) && isMobileDevice)
    return null;

  return (
    <header
      className={`DashboardHeader ${
        isMobileDevice ? "DashboardHeader--mobile" : ""
      } ${isLiteMode ? "DashboardHeader--litemode" : ""} ${
        isMenuOpen ? "DashboardHeader--menu-open" : ""
      }`}>
      <div className={"DashboardHeader__bg"}>
        <div className='DashboardHeader__left'>
          <TailwindButton
            backgroundColor={"bg-transparent"}
            rounded={false}
            fullWidth={false}
            onClick={onToggleMenuClicked}>
            <TailwindIcon name={"menu"} fontSize={"text-4xl"} />
          </TailwindButton>
          <a href='/' className={"ml-2 mr-4"}>
            <Logo
              variant={userRole}
              isMobile={isMobileScreenSize}
              className={`
                main-logo
                ${
                  userRole === "model" || userRole === "studio"
                    ? "DashboardSidebar__streamray-logo"
                    : "DashboardSidebar__cams-logo"
                }
              `}
            />
          </a>

          <span className={classnames("DashboardHeader__welcome")}>
            {!isMobileDevice && (
              <React.Fragment>
                {profile?.username !== "" && (
                  <TailwindText fontSize={"text-2xl"}>
                    {!isMobileScreenSize && userRole === "model" && (
                      <TailwindText fontSize={"text-2xl"}>
                        Welcome,{" "}
                      </TailwindText>
                    )}
                    <strong>{profile?.username}</strong>
                  </TailwindText>
                )}
              </React.Fragment>
            )}
          </span>
        </div>

        {isModel &&
          profileAccountStatus === UserAccountStatus.APPROVED &&
          !isBroadCastPage &&
          !isMobileDevice && (
            <div className='DashboardHeader__center'>
              <GoLiveButton />
            </div>
          )}

        <div className='DashboardHeader__right'>
          {isModel &&
            profileAccountStatus === UserAccountStatus.APPROVED &&
            !isBroadCastPage &&
            isMobileDevice && <GoLiveButton />}
          <EarningsPageButton />
        </div>
      </div>
    </header>
  );
};

export default injectIntl(
  inject(
    "layoutStore",
    "authStore",
    "profileStore",
    "routeStore",
    "messageStore"
  )(observer(Header))
);
