import React from "react";
import "./styles.scss";
import clsx from "clsx";

type SendAirplaneIconProps = {
  className?: string;
};

const SendAirplaneIcon: React.ComponentType<SendAirplaneIconProps> = ({
  className,
}) => (
  <span className={clsx("SendAirplaneIcon", className && className)}>
    <svg viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.5,30.5L25.4,46L45,2.4L46,0l-2.4,1.1L0,20.6L15.5,30.5z M25.1,41.9L17.5,30L40.9,6.6L25.1,41.9z M16,28.5L4.2,20.9  L39.4,5.1L16,28.5z'
        vectorEffect='non-scaling-stroke'
      />
      <g />
    </svg>
  </span>
);

export default SendAirplaneIcon;
