import React, { useEffect } from "react";
import _ from "lodash";
import BroadcastContainer from ".";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import { history } from "library/core/utility";
import { inject, observer } from "mobx-react";

type MemberVerificationBroadcastContainerProps = {
  profileStore?: ProfileStore;
};

const MemberVerificationBroadcastContainer: React.ComponentType<MemberVerificationBroadcastContainerProps> =
  ({ profileStore }) => {
    const { modelProfile } = profileStore!;

    useEffect(() => {
      if (!modelProfile.flags?.opted_in_for_member_verification) {
        history.replace("/");
      }
    }, [modelProfile]);

    return <BroadcastContainer variant={"member-verification"} />;
  };

export default inject("profileStore")(
  observer(MemberVerificationBroadcastContainer)
);
