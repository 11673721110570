import React from "react";
import "./styles.scss";
import classnames from "classnames";

type WhisperIconProps = {
  className?: string;
};

const WhisperIcon: React.ComponentType<WhisperIconProps> = ({ className }) => (
  <span className={classnames("WinkingEmojiIcon", className && className)}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      version='1.2'
      overflow='visible'
      preserveAspectRatio='none'
      viewBox='0 0 26 26'>
      <g>
        <polygon
          points='6.5,4.7 6.5,5.8 9.1,5.8 9.1,4.6'
          vectorEffect='non-scaling-stroke'
        />
        <path
          d='M15.8,0.1h-9c-1.9,0-3.4,1.5-3.4,3.4v3.7c0,1.5,1.1,2.9,2.6,3.3v3.6l4.2-3.5h5.7c1.9,0,3.4-1.5,3.4-3.4V3.5   C19.2,1.6,17.7,0.1,15.8,0.1z M18,7.2c0,1.2-1,2.2-2.2,2.2H9.7l-2.5,2.1V9.7L7,9.4H6.7c-1.2,0-2.2-1-2.2-2.2V3.5   c0-1.2,1-2.2,2.2-2.2h9c1.2,0,2.2,1,2.2,2.2v3.7H18z'
          vectorEffect='non-scaling-stroke'
        />
        <polygon
          points='9.9,4.7 10,5.8 12.6,5.8 12.5,4.6'
          vectorEffect='non-scaling-stroke'
        />
        <polygon
          points='13.4,4.7 13.5,5.8 16.1,5.8 16,4.6'
          vectorEffect='non-scaling-stroke'
        />
    </g>
    <g>
        <path
          d='M20.5,18c-0.1-0.2-0.3-0.3-0.5-0.3l0,0c0,0-0.7-0.1-2.6-1.9c-1.7-1.6-3-2.4-4.3-2.4H13c-0.6,0-1.2,0.2-1.7,0.6  c-0.5-0.4-1.1-0.6-1.7-0.6H9.5c-1.2,0-2.5,0.7-4.3,2.4c-1.9,1.8-2.6,1.9-2.6,1.9c-0.2,0-0.4,0.1-0.6,0.3c-0.1,0.2-0.1,0.5,0,0.8  c3.1,4.6,7,5.1,8.6,5.1c0.4,0,0.6,0,0.6,0c0.1,0,0.3,0,0.7,0c1.5,0,5.5-0.5,8.6-5.1C20.7,18.6,20.7,18.3,20.5,18z M11.1,22.5  c0,0-0.2,0-0.5,0c-1.1,0-3.8-0.3-6.3-3.1h14c-2.5,2.8-5.2,3.1-6.3,3.1c-0.3,0-0.5,0-0.5,0H11.1z M9.4,14.8L9.4,14.8  c0.4,0,0.8,0.2,1.2,0.5c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.3,0.2,0.5,0.2s0.3-0.1,0.5-0.2c0.1-0.1,0.1-0.1,0.1-0.2  c0.4-0.3,0.7-0.5,1.2-0.5c0.8,0,1.9,0.7,3.3,2c0.5,0.5,1,0.9,1.4,1.3h-13c0.4-0.3,0.9-0.8,1.4-1.3C7.5,15.4,8.6,14.8,9.4,14.8z'
          vectorEffect='non-scaling-stroke'
        />
      </g>
    </svg>
  </span>
);

export default WhisperIcon;
