import React from "react";
import BroadcastViewers from "./broadcast-viewers";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindBox from "library/components/_tailwind/box";
import TailwindCard from "library/components/_tailwind/card";
import ChatStore from "./_stores/chat/ChatStore";
import BroadcastStore from "./_stores/broadcast/BroadcastStore";
import PrivateRequestCard from "./broadcast-preview/broadcast-private-request";
import { inject, observer } from "mobx-react";
import ViewersSortSelect from './broadcast-tabs-viewers-sort-select';

type ViewerTabProps = {
  chatStore?: ChatStore;
  broadcastStore?: BroadcastStore;
};

const BroadcastViewersTab: React.ComponentType<ViewerTabProps> = ({
  broadcastStore,
}) => {
  const { isPrivateConsentOpen } = broadcastStore!;

  return (
    <TailwindBox
      width={"w-full"}
      flex={"flex-1"}
      display='flex'
      className={[isPrivateConsentOpen ? 'overflow-y-auto' : '']}
      flexDirection='flex-col'>
      {isPrivateConsentOpen && <PrivateRequestCard />}
      <TailwindFlex
        width={"w-full"}
        backgroundColor={"bg-primary-bg-color"}
        margin={["mb-3"]}
        height='h-screen'
        flexGrow={"flex-grow"}
        minHeight='min-h-0'>
        <TailwindCard
          width='w-full'
          display='flex'
          flexDirection='flex-col'
          height='h-full'
          padding={["p-3", "pt-1"]}>
          <ViewersSortSelect />
          <BroadcastViewers />
        </TailwindCard>
      </TailwindFlex>
    </TailwindBox>
  );
};

export default inject("broadcastStore")(observer(BroadcastViewersTab));
