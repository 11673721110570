import React, { useCallback } from "react";
import MediaManagerMediaThumbnail from "containers/_components/media-manager/media-manager-media-thumbnail";
import {
  ModelImage,
  ModelVideo,
} from "containers/_components/media-manager/store/types";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import MediaManagerManageMedia from "containers/_components/media-manager/media-manager-media-manage";
import MediaManagerPrompt from "containers/_components/media-manager/media-manager-prompt";
import { MyPagePromptMode } from "containers/_components/media-manager/store/enums";
import ModalStore from "library/core/stores/modal";
import { inject, observer } from "mobx-react";
import MediaManagerStore from "containers/_components/media-manager/store/MediaManagerStore";
import { MediaRating } from "./store/enums";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";

type Props = {
  media: ModelImage | ModelVideo;
  modalStore?: ModalStore;
  mediaManagerStore?: MediaManagerStore;
  profileStore?: ProfileStore;
};

const MediaManagerMasonryMedia: React.ComponentType<Props> = ({
  media,
  modalStore,
  mediaManagerStore,
  profileStore,
}) => {
  const { openSecondaryModal } = modalStore!;
  const {
    deleteMedia,
    setIsPromptOpen,
    setAlbumCoverImage,
    getMediaDateWithTime,
    getMediaDuration,
    selectedAlbum,
  } = mediaManagerStore!;
  const {
    setProfilePhoto,
    modelProfile: { non_nude_profile_image, profile_image },
    setProfilePictureByType,
  } = profileStore!;

  const onClickSetAlbumCoverOrProfilePicture = useCallback(
    async (media: ModelImage | ModelVideo) => {
      if (selectedAlbum) {
        setAlbumCoverImage(selectedAlbum, media as ModelImage);
      }
      if (media.id && selectedAlbum?.album_type === "profile") {
        setProfileImage(media.id);
      }
    },
    [selectedAlbum]
  );

  const setProfileImage = async (imgID: string) => {
    setProfilePhoto(imgID);
  };

  const onClickDeleteMedia = useCallback(
    async (media: ModelImage | ModelVideo) => {
      const isPremium =
        media.price_in_token !== undefined &&
        media.price_in_token !== null &&
        media.price_in_token > 0;
      setIsPromptOpen(
        <MediaManagerPrompt />,
        isPremium ? MyPagePromptMode.DELETE_PREMIUM : MyPagePromptMode.DELETE,
        async () => {
          await deleteMedia(media);
          setIsPromptOpen(null);
        },
        () => {
          setIsPromptOpen(null);
        }
      );
    },
    []
  );

  const onClickEditMedia = useCallback((media: ModelImage | ModelVideo) => {
    openSecondaryModal(<MediaManagerManageMedia media={media} />);
  }, []);
  return (
    <TailwindFlex>
      <MediaManagerMediaThumbnail
        actionsProps={{
          onDeleteClicked: () => onClickDeleteMedia(media),
          onEditClicked: () =>
            media.uploadProgress < 100 ? undefined : onClickEditMedia(media),
          onSetCoverOrProfilePicClicked:
            media.uploadProgress < 100 || media.type === "video"
              ? undefined
              : () => onClickSetAlbumCoverOrProfilePicture(media),
          setProfilePictureByType: setProfilePictureByType,
          isCover: selectedAlbum?.cover_image?.id === media.id,
          isProfile: selectedAlbum?.album_type === "profile",
          isApprovedImg: media.media_status && media.media_status === "active",
          isPGImg:
            media.media_rating === MediaRating.PG ||
            media.media_rating === MediaRating.PG13,
          isNonNudeProfilePic: media.id == non_nude_profile_image?.id,
          isProfilePic: media.id == profile_image?.id,
          isRejected: media.media_status === "rejected",
        }}
        statusProps={{
          status: media.media_status,
        }}
        purchasesProps={{
          num_purchases: media.num_purchases,
        }}
        titleProps={{
          name: getMediaDateWithTime(media),
          textProps: {
            margin: ["mt-1"],
          },
          status: media?.pending_title_status as string,
        }}
        videoDurationProps={
          media.type === "video"
            ? {
                duration: getMediaDuration((media as ModelVideo).length),
              }
            : undefined
        }
        priceProps={{
          priceInToken: media.price_in_token,
        }}
        bodyProps={{
          backgroundImage:
            media.media_status === "pending_conversion"
              ? // the URL for the image is defined during conversion
                // image component fetches image then shows the below brokenPlaceholderChildren
                // when image is indeed no longer being converted, the URL is still the same
                // thus image component useMemo does not cause a rerender and refetch of the image
                // if image is in pending_conversion, let's make it for sure it is broken
                // so brokenPlaceholderChildren is used then swapped with the actual image
                // when no longer pending_conversion
                "https://some-url-for-sure-that-is-broken/some-image-that-for-sure-is-broken.jpg"
              : media.type === "image"
              ? (media as ModelImage).images?.main || media.image
              : (media as ModelVideo).video_type === "recording" &&
                (media as ModelVideo).poster_image_recording
              ? (media as ModelVideo).poster_image_recording
              : (media as ModelVideo).poster_image,
          brokenPlaceholderChildren: (
            <TailwindFlex justifyContent='justify-center' width='w-full'>
              {(media.media_status === "pending_conversion" ||
                media.media_status === "pending") && (
                <TailwindText
                  width='w-full'
                  textColor='text-main-color'
                  textAlign='text-center'>
                  Processing media
                </TailwindText>
              )}
            </TailwindFlex>
          ),
        }}
        rejectionReasonProps={
          media.type === "video" && media.media_status === "rejected"
            ? {
                titleRejectionReason: (media as ModelVideo)
                  .title_rejection_reason,
                descriptionRejectionReason: (media as ModelVideo)
                  .description_rejection_reason,
                videoRejectionReason: (media as ModelVideo)
                  .video_rejection_reason,
              }
            : undefined
        }
        uploadProgressProps={{
          uploadProgress: media.uploadProgress,
        }}
        onClick={() =>
          media.uploadProgress < 100 ? undefined : onClickEditMedia(media)
        }
      />
    </TailwindFlex>
  );
};

export default inject(
  "mediaManagerStore",
  "modalStore",
  "profileStore"
)(observer(MediaManagerMasonryMedia));
