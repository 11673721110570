import React from "react";
import TailwindText, { TailwindTextProps } from "../text";
import { CustomFormatConfig, FormattedTime } from "react-intl";

export type TailwindTranslatedTimeProps = Intl.DateTimeFormatOptions &
  CustomFormatConfig & {
    value: string | number | Date | undefined;
  } & {
    textProps?: TailwindTextProps;
  };

const TailwindTranslatedTime: React.ComponentType<TailwindTranslatedTimeProps> = ({
  textProps,
  ...props
}) => {
  return (
    <TailwindText {...textProps}>
      <FormattedTime {...props} />
    </TailwindText>
  );
};

export default TailwindTranslatedTime;
