import React from "react";
import FileUpload, { FileUploadProps } from "library/components/file-upload";
import TailwindFlex, { TailwindFlexProps } from "library/components/_tailwind/flex";

export type MediaManagerMediaCardFileUploadProps = TailwindFlexProps & {
  fileUploadProps: FileUploadProps;
};

const MediaManagerMediaCardFileUpload: React.ComponentType<MediaManagerMediaCardFileUploadProps> = ({
  fileUploadProps,
  ...props
}) => {
  return (
    <TailwindFlex {...props}>
      <FileUpload
      height="h-full"
        {...{
          ...fileUploadProps,
          dropzoneProps: {
            ...fileUploadProps?.dropzoneProps,
          },
          stopPropagation: true,
        }}
      />
    </TailwindFlex>
  );
};

export default MediaManagerMediaCardFileUpload;
