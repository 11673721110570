export const NANO_CONFIG = {
  source: {
    entries: [],
    options: {
      adaption: { rule: "deviationOfMean" },
      switch: {
        method: "client",
        pauseOnError: false,
        forcePlay: true,
        fastStart: false,
        timeout: 10,
      },
    },
    startIndex: 0, // lowest
  },
  playback: {
    autoplay: true,
    automute: true,
    keepConnection: false,
    muted: false,
    reconnect: { minDelay: 1, maxDelay: 4, delaySteps: 8, maxRetries: 10 },
    flashplayer: "https://secureimage.securedataimages.com/images/cams/lib/nanoplayer.swf",
  },
  tweaks: {
    buffer: { min: 0.5, start: 0.6, target: 1.3, limit: 1.7, max: 4.0 },
    bufferDynamic: { offsetThreshold: 1, offsetStep: 0.2, cooldownTime: 5 },
  },
  style: {
    displayMutedAutoplay: false,
    controls: false,
    aspectratio: "16/9",
    height: "100%",
    width: "100%",
    view: true,
    backgroundColor: "transparent",
    displayAudioOnly: false,
  },
  events: {},
};

export const DEFAULT_NANO_POOL = ["stream10.cams.com","stream11.cams.com","stream12.cams.com","stream13.cams.com"]