import React, { useCallback, useMemo } from "react";
import NavigationItemContainer from "./navigation-item-container";
import { AppRoute, RouteSidebarOptions } from "core/stores/route/types";
import { inject, observer } from "mobx-react";
import NavigationItemIcon from "./navigation-item-icon";
import classnames from "classnames";
import NavigationItemAction from "./navigation-item-action";
import "./styles.scss";
import {
  AppCommonRouteKey,
  RouteSidebarActionKey,
} from "core/stores/route/enums";
import TailwindSwitch from "library/components/_tailwind/switch";
import TailwindButton from "library/components/_tailwind/button";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import TailwindIcon from "library/components/_tailwind/icon";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import { TBackgroundColor } from "library/styles/tailwind-classnames";
import TailwindText from "library/components/_tailwind/text";
import TailwindBadge from "library/components/_tailwind/badge";
import MessageStore from "common/messages/stores/messages/MessageStore";

export type NavigationItemProps = {
  sidebarOptions?: RouteSidebarOptions;
  route: AppRoute;
  onClick?: (
    event: React.MouseEvent<HTMLElement> | undefined,
    route: AppRoute,
    isClickingFromSubRoute?: boolean
  ) => void;
  preventDefault?: boolean;
  capitalize?: boolean;
  layoutStore?: LayoutStore;
  getNavigationItemIcon: (route: AppRoute) => string | JSX.Element | undefined;
  getNavigationItemLabel: (
    route: AppRoute
  ) => string | ((...args: any[]) => string) | undefined;
  isDropdownExpanded: boolean;
  isLast?: boolean;
  messageStore?: MessageStore;
};

const NavigationItem: React.FunctionComponent<NavigationItemProps> = ({
  route,
  sidebarOptions,
  onClick,
  preventDefault,
  capitalize = false,
  getNavigationItemLabel,
  getNavigationItemIcon,
  isDropdownExpanded,
  isLast,
  layoutStore,
  messageStore,
}) => {
  const { isMenuOpen, isOpenMenuOnMouseOver } = layoutStore!;
  const { unreadConversationMessagesCount, unreadNewsAndPolicyCount } =
    messageStore!;
  const hasDropdownMenu = useMemo(() => {
    return (
      Array.isArray(sidebarOptions?.action?.item) &&
      (sidebarOptions?.action?.item || []).length > 0
    );
  }, [sidebarOptions]);

  const _onClick = useCallback(
    (event?, _route?: AppRoute, isClickingFromSubRoute?: boolean) => {
      if (onClick) {
        if (event && isClickingFromSubRoute) {
          event.stopPropagation();
        }
        onClick(event, route, isClickingFromSubRoute);
      }
      sidebarOptions?.action?.callback?.();
    },
    [onClick, hasDropdownMenu, route, preventDefault]
  );

  const onClickedToggleDropdownMenuChevron = useCallback(
    event => {
      _onClick(event, undefined, false);
    },
    [_onClick]
  );

  const shouldShowLabel = useMemo(
    () => isMenuOpen || isOpenMenuOnMouseOver,
    [isMenuOpen, isOpenMenuOnMouseOver]
  );

  return (
    <NavigationItemContainer
      route={route}
      onClick={_onClick}
      capitalize={capitalize}
      hideHoverBackgroundGradient={!isMenuOpen}
      sidebarOptions={sidebarOptions}
      activeClassName={classnames(
        sidebarOptions?.hoverBackgroundGradient !== undefined &&
          `NavigationItem--background-gradient-${sidebarOptions?.hoverBackgroundGradient}`
      )}
      hasDropdownMenu={hasDropdownMenu}
      isDropdownExpanded={isDropdownExpanded && shouldShowLabel}
      getNavigationItemIcon={getNavigationItemIcon}
      getNavigationItemLabel={getNavigationItemLabel}
      isLast={isLast}
      preventDefault={preventDefault}>
      {sidebarOptions?.icon !== "" && (
        <NavigationItemIcon
          icon={sidebarOptions?.icon as CustomIconName}
          showBackground={sidebarOptions?.showIconBackground}
          isCustom={sidebarOptions?.isCustomIcon}
        />
      )}
      {route.key === AppCommonRouteKey.messages &&
        unreadConversationMessagesCount > 0 && (
          <TailwindBadge
            borderRadius={"rounded"}
            badgeContent={unreadConversationMessagesCount}
            style={{
              left: shouldShowLabel ? "-10px" : "-26px",
              top: "-10px",
            }}></TailwindBadge>
        )}
      {route.key === AppCommonRouteKey.notices && unreadNewsAndPolicyCount > 0 && (
        <TailwindBadge
          borderRadius={"rounded"}
          badgeContent={unreadNewsAndPolicyCount}
          style={{
            left: shouldShowLabel ? "-10px" : "-26px",
            top: "-10px",
          }}></TailwindBadge>
      )}
      {shouldShowLabel && (
        <TailwindText
          className={"NavigationItem__label"}
          fontSize={"text-base"}
          whiteSpace={"whitespace-nowrap"}
          overflow={"overflow-hidden"}
          textOverflow={"overflow-ellipsis"}
          pseudoClasses={["sm:text-lg", "md:text-lg", "lg:text-lg"]}>
          {sidebarOptions?.label}
        </TailwindText>
      )}
      {sidebarOptions?.action && (
        <NavigationItemAction>
          {hasDropdownMenu ? (
            <TailwindButton
              fullWidth={false}
              onClick={onClickedToggleDropdownMenuChevron}>
              <TailwindIcon
                name={
                  isDropdownExpanded && shouldShowLabel
                    ? "keyboard_arrow_up"
                    : "keyboard_arrow_down"
                }
              />
            </TailwindButton>
          ) : (
            <React.Fragment>
              {sidebarOptions?.action?.item ===
                RouteSidebarActionKey.buyMore && (
                <TailwindButton
                  fullWidth={false}
                  onClick={() => {}}
                  className={["NavigationItem__action--purple"]}>
                  Buy More
                </TailwindButton>
              )}
              {sidebarOptions?.action.item ===
                RouteSidebarActionKey.autoRenew && (
                <React.Fragment>
                  <div>Auto Renew</div>
                  <div>
                    <TailwindSwitch
                      onLabel={"ON"}
                      offLabel={"OFF"}
                      offBackgroundColor={"bg-gray-900" as TBackgroundColor}
                      isChecked={false}
                      size={"xs"}
                    />
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </NavigationItemAction>
      )}
    </NavigationItemContainer>
  );
};

export default inject("layoutStore", "messageStore")(observer(NavigationItem));
