import React from "react";
import TailwindBox from "library/components/_tailwind/box";
import TailwindList, {
  TailwindListType,
} from "library/components/_tailwind/list/";
import TailwindListItem from "library/components/_tailwind/list/list-item";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindText from "library/components/_tailwind/text";

interface Props {}

const OBSModalTabStep5ForObsSupportPage: React.FunctionComponent<Props> =
  ({}) => {
    return (
      <TailwindBox>
        <TailwindList type={TailwindListType.ol} margin={["ml-3"]}>
          <TailwindListItem>
            <TailwindText>Log in to your account</TailwindText>
          </TailwindListItem>
          <TailwindListItem>
            <TailwindText>Go to broadcast page (Go Live)</TailwindText>
          </TailwindListItem>
          <TailwindListItem>
            <TailwindText display={"inline-flex"} alignItems={"items-center"}>
              Click settings icon (
              <TailwindIcon display={"inline"} padding={['px-0.5']} name={"settings_extra"} />)
            </TailwindText>
          </TailwindListItem>
          <TailwindListItem>
            <TailwindText>Click "Use external software (OBS)"</TailwindText>
          </TailwindListItem>
          <TailwindListItem>
            <TailwindText>
              Click step 5 (Stream URL) and follow instructions
            </TailwindText>
          </TailwindListItem>
        </TailwindList>
      </TailwindBox>
    );
  };

export default OBSModalTabStep5ForObsSupportPage;
