import { observer, inject } from "mobx-react";
import React from "react";
import "./styles.scss";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindBox from "library/components/_tailwind/box";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindSwitch from "library/components/_tailwind/switch";
import TailwindDivider from "library/components/_tailwind/divider";
import { TInset } from "library/styles/tailwind-classnames";
import LanguageStore from "library/core/stores/language/LanguageStore";

interface Props {
  languageStore?: LanguageStore;
  close?: () => void;
}

const RecordedShowsModal: React.FunctionComponent<
  Props
> = ({ languageStore, close }) => {
  const { intl } = languageStore!;
  

  return (
    <div className={"RecordedShowsModal"}>
      <TailwindFlex
        backgroundColor={"bg-primary-bg-color"}
        flexDirection={"flex-col"}
        padding={["px-6", "pt-0",]}
        borderRadius={"rounded-xl"}>
        <div className={"RecordedShowsModal__closeIcon"}><TailwindIcon
            onClick={close}
            name={"close-thicker"}
            cursor={"cursor-pointer"}
            position={"relative"}
            inset={["left-2", "top-3"]}
            textColor={"text-main-color"}
            fontSize={"text-xs"}
            zIndex={"z-900"}
        /></div>
        <p className={"RecordedShowsModal__title"}>
          {intl.formatMessage({
                id: "recordedShows.header",
                defaultMessage: "Recorded Shows",
            })}
        </p>
        <TailwindDivider
              color='bg-header-bg-color'
              margin={["my-3"]}
              opacity='opacity-50'
              style={{ height: "2px" }}
            />
          <p className={"RecordedShowsModal__paragraph"}>
          {intl.formatMessage({
            id: "recordedShows.paragraph_1",
            defaultMessage:
              "All of your recorded shows will appear in this section. Paid shows are recorded automatically. This feature can be turned off in your Broadcaster settings"
          }, {})} 
          <TailwindText display={"inline-block"}>
            <TailwindIcon 
              fontSize={"text-base"} 
              name={"setting_side"}
              backgroundColor={'bg-gray-200'}
              style={{
                position: "relative",
                top: "3px",
              }}/>
          </TailwindText>.
          </p>
          <p className={"RecordedShowsModal__paragraph"}>
          {intl.formatMessage({
            id: "recordedShows.paragraph_2",
            defaultMessage:
              "If you turned automatic recording off, you can still manually record your shows by toggling the REC switch"
          }, {})}
          <TailwindText display={"inline-block"}>
            <TailwindBox>
              <TailwindSwitch
                onLabel={"REC"}
                offLabel={"REC"}
                size={"xs"}
                isChecked={true}
                offBackgroundColor={"bg-toggle-disabled"}
                offLabelProps={{
                  textColor: "text-white",
                }}
                onBackgroundColor={"bg-secondary-bg-color"}
                onLabelProps={{
                  textColor: "text-main-color",
                  inset: ["right-7" as TInset],
                }}
                pillBackgroundColor={"bg-pink-600"}
              />
            </TailwindBox>
            </TailwindText>.
          </p>
          <p className={"RecordedShowsModal__paragraph"}>
          {intl.formatMessage({
            id: "recordedShows.paragraph_3",
            defaultMessage:
              "Fan club members get full access to your shows but all other members can view them for a set price of 50 tokens."
          }, {})}
          </p>
      </TailwindFlex>
    </div>
  );
};

export default inject(
  "languageStore"
)(observer(RecordedShowsModal));
