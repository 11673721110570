import React from "react";
import UpdatePasswordForm from "containers/_components/update-password-form";

import TailwindFlex from "library/components/_tailwind/flex";
import TailwindCard from "library/components/_tailwind/card";

const UpdatePasswordContainer: React.ComponentType = () => {
  return (
    <TailwindFlex height={"h-full"} pseudoClasses={["md:h-auto"]}>
      <TailwindCard width={"w-full"} maxWidth={"max-w-xl"}>
        <UpdatePasswordForm
          className={"AccountSettings__password-reset h-full"}
          forWhere={"studio"}
          buttonProps={{ backgroundColor: "primary", rounded: false }}
          showForgotPassword={false} //requested not to be shown by Andrew
          showOldPassword={true}
          showTitle={false}
        />
      </TailwindCard>
    </TailwindFlex>
  );
};

export default UpdatePasswordContainer;
