import React, { useMemo } from "react";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import { MessageBlockType } from "./stores/messages/types";
import TailwindText from "library/components/_tailwind/text";
import { inject, observer } from "mobx-react";
import ModalStore from "library/core/stores/modal";
import MessageCenterUnblockModal from "./messages-unblock-modal";
import AuthStore from "core/stores/auth/AuthStore";
import { US_STATES } from "library/core/constants";

type Props = {
  memberName: string;
  onUnblock: () => void;
  blockType: MessageBlockType;
  countryISO: string;
  modalStore?: ModalStore;
  authStore?: AuthStore;
};

const MessageCenterReaderFooterBlocked: React.ComponentType<
  Props & WrappedComponentProps
> = ({
  memberName,
  onUnblock,
  countryISO,
  blockType,
  modalStore,
  authStore,
  intl,
}) => {
  const { countries } = authStore!;
  const { openPrimaryModal, closePrimaryModal } = modalStore!;
  const verifyBlock = () => {
    openPrimaryModal(
      <MessageCenterUnblockModal
        onAccept={onUnblock}
        onDecline={closePrimaryModal}
      />
    );
  };

  const countryStateLabel = useMemo(
    () => countries[countryISO] || US_STATES?.[countryISO],
    [countryISO]
  );

  const blockedMessage = useMemo(() => {
    switch (blockType) {
      case "country":
        return (
          <FormattedMessage
            id='accountSettings.blockUnblock.memberBlockedByCountry'
            defaultMessage='{member} is logged into the country {country} which you have blocked.'
            values={{
              member: memberName,
              country: <strong>{countryStateLabel}</strong>,
            }}
          />
        );
      case "state":
        return (
          <FormattedMessage
            id='accountSettings.blockUnblock.memberBlockedByState'
            defaultMessage='{member} is logged into the US State of {state} which you have blocked.'
            values={{
              member: memberName,
              state: <strong>{countryStateLabel}</strong>,
            }}
          />
        );
      default:
        return intl.formatMessage(
          {
            id: "accountSettings.blockUnblock.memberBlocked",
            defaultMessage: "You blocked {member} from contacting you.",
          },
          {
            member: memberName,
          }
        );
    }
  }, [blockType, countryStateLabel]);

  const unblockButtonText = useMemo(() => {
    switch (blockType) {
      case "country":
        return intl.formatMessage({
          id: "message-center.unblock-country",
          defaultMessage: "Unblock Country",
        });
      case "state":
        return intl.formatMessage({
          id: "message-center.unblock-state",
          defaultMessage: "Unblock State",
        });
      default:
        return intl.formatMessage({
          id: "message-center.unblock-member",
          defaultMessage: "Unblock Member",
        });
    }
  }, [blockType]);

  return (
    <TailwindFlex
      backgroundColor='bg-highlight-color'
      width='w-full'
      padding={["p-4"]}
      justifyContent='justify-center'
      alignItems='items-center'
      flexDirection='flex-col'
      textColor='text-secondary-color'>
      <TailwindText
        padding={["pb-2"]}
        fontSize='text-lg'
        fontWeight='font-bold'
        textAlign='text-center'>
        {blockedMessage}
      </TailwindText>
      <TailwindText padding={["pb-2"]}>
        {intl.formatMessage({
          id: "accountSettings.blockUnblock.cantMessage",
          defaultMessage:
            "You can't message them and you won't receive their message.",
        })}
      </TailwindText>

      <TailwindButton
        onClick={verifyBlock}
        fullWidth={false}
        backgroundColor={"bg-modal-button-color"}
        borderRadius={"rounded"}
        textColor={"text-white"}
        width={"w-auto"}
        alignItems={"items-center"}
        justifyContent={"justify-center"}
        padding={["px-6"]}>
        {unblockButtonText}
      </TailwindButton>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject("modalStore", "authStore")(observer(MessageCenterReaderFooterBlocked))
);
