import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindGrid from "../../components/_tailwind/grid";
import Code from "../../components/_tailwind/code";
import TailwindFlex from "../../components/_tailwind/flex";

type Props = {};

const StorybookComponentsGrid: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Grid</StorybookHeading>
      <StorybookParagraph>
        Grid is like Flex component, it is a wrapper of TailwindBox that is
        opinionated towards accomplishing CSS grid layout. It accepts all props
        TailwindBox does however there are the following props to make it
        opinionated{" "}
        <strong>
          (please beware that there was not many use cases for Grid yet, it
          might be missing some props, contact with the lead to get them added,
          or try to follow the same structure and naming it has to add new props
          to it that applies TailwindCSS Grid classes)
        </strong>
        :
      </StorybookParagraph>
      <Code>
        {`sm?: TGridTemplateColumns;
md?: TGridTemplateColumns;
lg?: TGridTemplateColumns;
xl?: TGridTemplateColumns;
xxl?: TGridTemplateColumns;
width?: TWidth;
gap?: TGap;
cols?: TGridTemplateColumns;
rows?: TGridTemplateRows;
inline?: boolean;
`}
      </Code>
      <StorybookParagraph>
        Examples have a background to visualize them easier:
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindGrid cols={"grid-cols-6"}>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 1</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 2</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 3</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 4</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 5</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 6</TailwindFlex>
</TailwindGrid>
`}>
        <TailwindGrid cols={"grid-cols-6"}>
          <TailwindFlex backgroundColor={"bg-red-500"}>col 1</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>col 2</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>col 3</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>col 4</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>col 5</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>col 6</TailwindFlex>
        </TailwindGrid>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindGrid cols={"grid-cols-6"} gap={"gap-2"}>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 1</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 2</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 3</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 4</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 5</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 6</TailwindFlex>
</TailwindGrid>
`}>
        <TailwindGrid cols={"grid-cols-6"} gap={"gap-2"}>
          <TailwindFlex backgroundColor={"bg-red-500"}>col 1</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>col 2</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>col 3</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>col 4</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>col 5</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>col 6</TailwindFlex>
        </TailwindGrid>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindGrid rows={"grid-rows-6"}>
  <TailwindFlex backgroundColor={"bg-red-500"}>row 1</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>row 2</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>row 3</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>row 4</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>row 5</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>row 6</TailwindFlex>
</TailwindGrid>
`}>
        <TailwindGrid rows={"grid-rows-6"}>
          <TailwindFlex backgroundColor={"bg-red-500"}>row 1</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>row 2</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>row 3</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>row 4</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>row 5</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>row 6</TailwindFlex>
        </TailwindGrid>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindGrid rows={"grid-rows-6"} gap={"gap-2"}>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 1</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 2</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 3</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 4</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 5</TailwindFlex>
  <TailwindFlex backgroundColor={"bg-red-500"}>col 6</TailwindFlex>
</TailwindGrid>
`}>
        <TailwindGrid rows={"grid-rows-6"} gap={"gap-2"}>
          <TailwindFlex backgroundColor={"bg-red-500"}>rows 1</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>rows 2</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>rows 3</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>rows 4</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>rows 5</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-red-500"}>rows 6</TailwindFlex>
        </TailwindGrid>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindGrid rows={"grid-rows-3"} gap={"gap-2"}>
  <TailwindGrid cols={"grid-cols-4"} backgroundColor={"bg-yellow-500"}>
    <TailwindFlex>nested grid row 1, col 1</TailwindFlex>
    <TailwindFlex>nested grid row 1, col 2</TailwindFlex>
    <TailwindFlex>nested grid row 1, col 3</TailwindFlex>
    <TailwindFlex>nested grid row 1, col 4</TailwindFlex>
  </TailwindGrid>
  <TailwindGrid cols={"grid-cols-4"} backgroundColor={"bg-red-500"}>
    <TailwindFlex>nested grid row 2, col 1</TailwindFlex>
    <TailwindFlex>nested grid row 2, col 2</TailwindFlex>
    <TailwindFlex>nested grid row 2, col 3</TailwindFlex>
    <TailwindFlex>nested grid row 2, col 4</TailwindFlex>
  </TailwindGrid>
  <TailwindGrid cols={"grid-cols-4"} backgroundColor={"bg-green-500"}>
    <TailwindFlex>nested grid row 3, col 1</TailwindFlex>
    <TailwindFlex>nested grid row 3, col 2</TailwindFlex>
    <TailwindFlex>nested grid row 3, col 3</TailwindFlex>
    <TailwindFlex>nested grid row 3, col 4</TailwindFlex>
  </TailwindGrid>
</TailwindGrid>
`}>
        <TailwindGrid rows={"grid-rows-3"} gap={"gap-2"}>
          <TailwindGrid cols={"grid-cols-4"} backgroundColor={"bg-yellow-500"}>
            <TailwindFlex>nested grid row 1, col 1</TailwindFlex>
            <TailwindFlex>nested grid row 1, col 2</TailwindFlex>
            <TailwindFlex>nested grid row 1, col 3</TailwindFlex>
            <TailwindFlex>nested grid row 1, col 4</TailwindFlex>
          </TailwindGrid>
          <TailwindGrid cols={"grid-cols-4"} backgroundColor={"bg-red-500"}>
            <TailwindFlex>nested grid row 2, col 1</TailwindFlex>
            <TailwindFlex>nested grid row 2, col 2</TailwindFlex>
            <TailwindFlex>nested grid row 2, col 3</TailwindFlex>
            <TailwindFlex>nested grid row 2, col 4</TailwindFlex>
          </TailwindGrid>
          <TailwindGrid cols={"grid-cols-4"} backgroundColor={"bg-green-500"}>
            <TailwindFlex>nested grid row 3, col 1</TailwindFlex>
            <TailwindFlex>nested grid row 3, col 2</TailwindFlex>
            <TailwindFlex>nested grid row 3, col 3</TailwindFlex>
            <TailwindFlex>nested grid row 3, col 4</TailwindFlex>
          </TailwindGrid>
        </TailwindGrid>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindGrid cols={"grid-cols-3"} gap={"gap-2"}>
  <TailwindGrid cols={"grid-cols-4"} backgroundColor={"bg-yellow-500"}>
    <TailwindFlex>nested grid cols 1, col 1</TailwindFlex>
    <TailwindFlex>nested grid cols 1, col 2</TailwindFlex>
    <TailwindFlex>nested grid cols 1, col 3</TailwindFlex>
    <TailwindFlex>nested grid cols 1, col 4</TailwindFlex>
  </TailwindGrid>
  <TailwindGrid cols={"grid-cols-4"} backgroundColor={"bg-red-500"}>
    <TailwindFlex>nested grid cols 2, col 1</TailwindFlex>
    <TailwindFlex>nested grid cols 2, col 2</TailwindFlex>
    <TailwindFlex>nested grid cols 2, col 3</TailwindFlex>
    <TailwindFlex>nested grid cols 2, col 4</TailwindFlex>
  </TailwindGrid>
  <TailwindGrid cols={"grid-cols-4"} backgroundColor={"bg-green-500"}>
    <TailwindFlex>nested grid cols 3, col 1</TailwindFlex>
    <TailwindFlex>nested grid cols 3, col 2</TailwindFlex>
    <TailwindFlex>nested grid cols 3, col 3</TailwindFlex>
    <TailwindFlex>nested grid cols 3, col 4</TailwindFlex>
  </TailwindGrid>
</TailwindGrid>
`}>
        <TailwindGrid cols={"grid-cols-3"} gap={"gap-2"}>
          <TailwindGrid cols={"grid-cols-4"} backgroundColor={"bg-yellow-500"}>
            <TailwindFlex>nested grid cols 1, col 1</TailwindFlex>
            <TailwindFlex>nested grid cols 1, col 2</TailwindFlex>
            <TailwindFlex>nested grid cols 1, col 3</TailwindFlex>
            <TailwindFlex>nested grid cols 1, col 4</TailwindFlex>
          </TailwindGrid>
          <TailwindGrid cols={"grid-cols-4"} backgroundColor={"bg-red-500"}>
            <TailwindFlex>nested grid cols 2, col 1</TailwindFlex>
            <TailwindFlex>nested grid cols 2, col 2</TailwindFlex>
            <TailwindFlex>nested grid cols 2, col 3</TailwindFlex>
            <TailwindFlex>nested grid cols 2, col 4</TailwindFlex>
          </TailwindGrid>
          <TailwindGrid cols={"grid-cols-4"} backgroundColor={"bg-green-500"}>
            <TailwindFlex>nested grid cols 3, col 1</TailwindFlex>
            <TailwindFlex>nested grid cols 3, col 2</TailwindFlex>
            <TailwindFlex>nested grid cols 3, col 3</TailwindFlex>
            <TailwindFlex>nested grid cols 3, col 4</TailwindFlex>
          </TailwindGrid>
        </TailwindGrid>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsGrid;
