import CookieStorage from "library/core/utility/cookie-storage";

const checkIfDefined = (variable: any) => {
  return variable !== null && variable !== undefined;
};

export const enableFrequentSocketLogs = false;

export default {
  apiUrl:
    process.env.REACT_APP_FEATURE_API_HOST ||
    process.env.API_HOST ||
    process.env.REACT_APP_API_HOST,
  enableLogger:
    process.env.REACT_APP_FEATURE_ENABLE_LOGGER ||
    process.env.ENABLE_LOGGER ||
    process.env.REACT_APP_ENABLE_LOGGER,
  googleCaptchaSiteKey:
    process.env.GOOGLE_CAPTCHA_SITE_KEY ||
    process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY ||
    "6LegdMgUAAAAAGG0zPmov91_dNqcy_GQmy1gYXMi",
  googleCaptchaSiteKeyV3:
    process.env.GOOGLE_CAPTCHA_SITE_KEY_V3 ||
    process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY_V3 ||
    "6Lc439IZAAAAAHMFh6CeuL-EJW4tSjq_ayZo-IqR",
  googleCaptchaBP: process.env.REACT_APP_GOOGLE_CAPTCHA_BYPASS,
  chatServer:
    process.env.REACT_APP_FEATURE_API_CHAT ||
    process.env.API_CHAT ||
    process.env.REACT_APP_API_CHAT,
  chatPath: process.env.API_CHAT_PATH || process.env.REACT_APP_API_CHAT_PATH,
  walletApiUrl:
    process.env.REACT_APP_FEATURE_API_PAYMENT ||
    process.env.API_PAYMENT ||
    process.env.REACT_APP_API_PAYMENT,
  broadcastApiUrl: process.env.REACT_APP_API_BROADCAST_PATH,
  broadcastApiSocket: process.env.REACT_APP_API_BROADCAST_SOCKET,
  broadcastEdge: process.env.REACT_APP_API_BROADCAST_EDGE || "edge",
  broadcastApp: process.env.REACT_APP_API_BROADCAST_APP || "origin",
  broadcastRtmp:
    process.env.REACT_APP_API_BROADCAST_RTMP ||
    "rtmp://wowza-new.logicbeta.com:1935",
  broadcastNanoWebsocket:
    process.env.REACT_APP_BROADCAST_NANO_WEBSOCKET ||
    "wss://nanoserver-new.logicbeta.com/ws",
  webrtcApp: process.env.REACT_APP_API_WEBRTC_APP || "webrtc",
  edgeLBPath:
    process.env.REACT_APP_EDGE_LB_PATH ||
    "https://wowza-new-edge-lb.logicbeta.com/redirect?type=client",
  whereami_url:
    process.env.REACT_APP_WHEREAMI_URL ||
    "https://edges-lb.streamray.com/whereami",
  rtmpPort: process.env.REACT_APP_RTMP_PORT || "1935",
  mockApi: "https://mocks-api.logicbeta.com",
  broadcastWsUrl: "ws://api.bomstudios.com:8000",
  modelWsUrl: "http://api.bomstudios.com:9000",
  localhostUrl: "http://localhost",
  membersUrl: process.env.REACT_APP_MEMBERS_URL || "https://cams.com",
  modelsUrl: process.env.REACT_APP_MODELS_URL || "https://models.streamray.com",
  studiosUrl:
    process.env.REACT_APP_STUDIOS_URL || "https://studios.streamray.com",
  legacyModelsUrl:
    process.env.REACT_APP_LEGACY_MODELS_URL || "https://models.streamray.com",
  legacyStudiosUrl:
    process.env.REACT_APP_LEGACY_STUDIOS_URL || "https://studios.streamray.com",
  userRole: process.env.REACT_APP_USER_ROLE || null,
  mocksEnabled: true,
  showAjaxErrors: false,
  useImageMocks: false,
  env: process.env.NODE_ENV,
  sentryEnv: process.env.REACT_APP_SENTRY_ENV,
  cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN || "streamray.com",
  showYouMakeColumnOnEarningsReport:
    CookieStorage.get("debugYouMakeOnEarningsReport") === true ||
    (checkIfDefined(
      process.env.REACT_APP_SHOW_YOU_MAKE_COLUMN_ON_EARNINGS_REPORT
    )
      ? process.env.REACT_APP_SHOW_YOU_MAKE_COLUMN_ON_EARNINGS_REPORT === "true"
      : false),
  maximumImageSize: {
    size: 99999999, // 99.99 MB
    width: 3840 * 3,
    height: 3840 * 3,
  },
  nanoPool: process.env.REACT_APP_NANO_POOL || '["nanoserver.dev.cams.run"]',
  nanoPoolEU:
    process.env.REACT_APP_NANO_POOL_EU || '["nanoserver.dev.cams.run"]',
  c2cResolution: process.env.REACT_APP_C2C_RESOLUTION || "480p",
  flightModeOn:
    process.env.REACT_APP_FLIGHT_MODE_ON !== undefined
      ? JSON.parse(process.env.REACT_APP_FLIGHT_MODE_ON)
      : false,
  enableGuestModels: !!process.env.REACT_APP_ENABLE_GUEST_MODELS,
  mcCharLimit: process.env.REACT_APP_MC_CHAR_LIMIT || 2000,
  disableWebrtcC2CVideo: process.env.DISABLE_WEBRTC_C2C_VIDEO || false,
  uploaderApiUrl:
    process.env.REACT_APP_API_UPLOADER_PATH || "https://uploader.prod.cams.run",
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  sentryRelease: process.env.REACT_APP_SENTRY_RELEASE,
  ffnSecret: process.env.REACT_APP_FFN_SECRET,
  classicCamsApiUrl: "https://classic.cams.com",
};
