import React from "react";
import BroadcastStartStreamingButton from "./broadcast-start-streaming-button";
import TailwindFlex from "library/components/_tailwind/flex";
import BroadcastStopStreamingButton from "common/broadcast/broadcast-control/broadcast-stop-streaming-button";
import BroadcastSwitchShowButton from "common/broadcast/broadcast-control/broadcast-switch-show-button";

type Props = {};

const BroadcastControlButtons: React.FC<Props> = () => {
  return (
    <>
      <TailwindFlex
        justifyContent={"justify-center"}
        gap={"gap-4"}
        padding={["px-4"]}>
        <BroadcastSwitchShowButton />
        <BroadcastStopStreamingButton />
      </TailwindFlex>
      <BroadcastStartStreamingButton />
    </>
  );
};

export default BroadcastControlButtons;
