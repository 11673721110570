import React, { useCallback, useEffect, useMemo } from "react";
import { inject, observer } from "mobx-react";
import StudioModelsAccessRightsStore from "containers/studio/studio-models/stores/studio-models-access-rights/StudioModelsAccessRightsStore";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import StudioModelAccessRights from "../../../common/studio-models-access-rights";
import MyFansStore from "containers/model/my-fans/stores/my-fans/MyFansStore";
import MyFansTable from "containers/model/my-fans/members-tab";
import {
  MY_FANS_SORTING_PROPERTY,
  MY_FANS_TAB,
} from "containers/model/my-fans/stores/my-fans/enums";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import { sortByOptions } from "containers/model/my-fans/stores/my-fans/consts";
import TailwindDropdown from "library/components/_tailwind/dropdown";
import TailwindTabHeader from "library/components/_tailwind/tabs/tab-header";
import Showcase from "library/components/_tailwind/showcase";
import ShowcaseStore from "core/stores/showcase/ShowcaseStore";
import { AppCamsModelRouteKey } from "core/stores/route/enums";
import TailwindSpinner from "library/components/_tailwind/spinner";
import TailwindInput from "library/components/_tailwind/input";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import { injectIntl, WrappedComponentProps } from "react-intl";
import DateProcess from "library/core/utility/date-process";

type MyFansContainerProps = {
  showcaseStore?: ShowcaseStore;
  myFansStore?: MyFansStore;
  layoutStore?: LayoutStore;
  studioModelsAccessRightsStore?: StudioModelsAccessRightsStore;
};

const MyFansContainer: React.ComponentType<
  MyFansContainerProps & WrappedComponentProps
> = ({
  showcaseStore,
  layoutStore,
  myFansStore,
  studioModelsAccessRightsStore,
  intl,
}) => {
  const {
    getMyFans,
    loadingMyFans,
    activeTab,
    setActiveTab,
    setActiveSortingProperty,
    activeSortingProperty,
    sortedSelectedMemberTransactions,
    selectedMemberId,
    setFromFilter,
    setToFilter,
    toFilter,
    fromFilter,
    getMemberTransactions,
  } = myFansStore!;
  const { isMobileScreenSize } = layoutStore!;
  const {
    nextWelcomeShowcasePage,
    getWelcomeShowcaseSteps,
    playingShowcase,
    disableShowcase,
  } = showcaseStore!;

  useEffect(() => {
    getMyFans(isMobileScreenSize);
  }, []);

  const firstDatePickerDate = useMemo(() => {
    return new Date(DateProcess.today.getFullYear() - 1, 0, 1)
      .toISOString()
      .split("T")[0];
  }, []);

  var lastDatePickerDate = useMemo(() => {
    return new Date(DateProcess.today.getFullYear(), 11, 31)
      .toISOString()
      .split("T")[0];
  }, []);

  const handleFromDateChange = useCallback((date: string) => {
    setFromFilter(date);
    getMemberTransactions();
  }, []);

  const handleToDateChange = useCallback((date: string) => {
    setToFilter(date);
    getMemberTransactions();
  }, []);

  const tabs = useMemo(() => {
    return [
      {
        id: "1",
        label: (
          <TailwindTranslatedText
            fontWeight={
              activeTab === MY_FANS_TAB.LAST_30_DAYS
                ? "font-bold"
                : "font-normal"
            }
            descriptor={{
              id: `dashboard.myFans.lastThirtyDays${
                isMobileScreenSize ? "Mobile" : ""
              }`,
              defaultMessage: `${isMobileScreenSize ? "" : "Last"} 30 days`,
            }}
          />
        ),
      },
      {
        id: "2",
        label: (
          <TailwindTranslatedText
            fontWeight={
              activeTab === MY_FANS_TAB.THIS_YEAR ? "font-bold" : "font-normal"
            }
            descriptor={{
              id: "dashboard.myFans.thisYear",
              defaultMessage: "This Year",
            }}
          />
        ),
      },
      {
        id: "3",
        label: (
          <TailwindTranslatedText
            fontWeight={
              activeTab === MY_FANS_TAB.DATE_RANGE ? "font-bold" : "font-normal"
            }
            descriptor={{
              id: "dashboard.myFans.date",
              defaultMessage: "Date",
            }}
          />
        ),
      },
    ];
  }, [activeTab, isMobileScreenSize]);

  const onChangeActiveSortingProperty = useCallback(value => {
    setActiveSortingProperty(value as MY_FANS_SORTING_PROPERTY);
  }, []);

  const activeTabIndex = useMemo(() => {
    switch (activeTab) {
      case MY_FANS_TAB.LAST_30_DAYS: {
        return 0;
      }
      case MY_FANS_TAB.THIS_YEAR: {
        return 1;
      }
      case MY_FANS_TAB.DATE_RANGE: {
        return 2;
      }

      default:
        return 0;
    }
  }, [activeTab]);

  const onClickTab = useCallback((tabIndex: number) => {
    let nextTab;

    switch (tabIndex) {
      case 0: {
        nextTab = MY_FANS_TAB.LAST_30_DAYS;
        break;
      }
      case 1: {
        nextTab = MY_FANS_TAB.THIS_YEAR;
        break;
      }
      case 2: {
        nextTab = MY_FANS_TAB.DATE_RANGE;
        break;
      }
    }

    if (nextTab) {
      setActiveTab(nextTab, isMobileScreenSize);
    }
  }, []);

  const hasFans = useMemo(() => {
    return selectedMemberId && sortedSelectedMemberTransactions.length > 0;
  }, [selectedMemberId, sortedSelectedMemberTransactions]);

  return (
    <StudioModelAccessRights
      shouldShow={
        studioModelsAccessRightsStore!.isEarningsAndTransactionsVisible
      }>
      {playingShowcase && (
        <Showcase
          steps={getWelcomeShowcaseSteps(AppCamsModelRouteKey.myFans)}
          onFinish={nextWelcomeShowcasePage}
          disableOverlay={true}
          showSkipButton={true}
          onSkipped={() => {
            disableShowcase();
          }}
        />
      )}
      <TailwindFlex
        flexDirection={"flex-col"}
        height={"h-full"}
        overflow={["overflow-hidden"]}
        className={["MyFans"]}>
        <TailwindFlex>
          <TailwindTranslatedText
            headingProps={{
              className: "MyFans__title",
              level: 5,
              margin: ["mb-3"],
            }}
            descriptor={{
              id: "dashboard.myFans.title",
              defaultMessage: "My Fans",
            }}
          />
        </TailwindFlex>

        <TailwindFlex>
          <TailwindTranslatedText
            paragraphProps={{
              textColor: "text-main-color",
            }}
            descriptor={{
              id: "dashboard.myFans.encouragementNote",
              defaultMessage:
                "The following are your most important members. We encourage you to send them messages to let them know about your schedule and fan club content.",
            }}
          />
        </TailwindFlex>
        {loadingMyFans ? (
          <TailwindFlex
            justifyContent={"justify-center"}
            alignItems={"items-center"}
            width={"w-full"}
            height={"h-full"}>
            <TailwindSpinner />
          </TailwindFlex>
        ) : (
          <React.Fragment>
            <TailwindFlex
              margin={["my-2", "mt-4"]}
              flexDirection={"flex-row"}
              width={"w-full"}
              justifyContent={"justify-between"}>
              <TailwindTabHeader
                tabs={tabs}
                activeTabIndex={activeTabIndex}
                setActiveTab={onClickTab}
                width={"w-auto"}
                margin={["mb-0"]}
                buttonProps={{
                  backgroundColor: "bg-primary-bg-color",
                }}
                activeButtonProps={{
                  backgroundColor: "bg-secondary-bg-color",
                }}
              />

              <TailwindFlex width={"w-auto"} gap={"gap-4"}>
                {activeTab === MY_FANS_TAB.DATE_RANGE && (
                  <>
                    <TailwindInput
                      className={[
                        "MyFansHeader",
                        "justify-center",
                        "items-center",
                      ]}
                      label={intl.formatMessage({
                        id: "dashboard.myFans.from",
                        defaultMessage: "From",
                      })}
                      labelProps={{
                        margin: ["mr-2"],
                      }}
                      flexProps={{
                        flexDirection: "flex-row",
                      }}
                      position='relative'
                      backgroundColor={"bg-primary-bg-color"}
                      borderColor={"border-transparent"}
                      placeholder={"YYYY/MM/DD"}
                      placeholderColor={"placeholder-gray-500"}
                      errorProps={{
                        textAlign: "text-right",
                      }}
                      value={fromFilter}
                      type={TailwindInputType.date}
                      name={"from"}
                      onChange={e => handleFromDateChange(e.target.value)}
                      min={firstDatePickerDate}
                      max={lastDatePickerDate}
                    />
                    <TailwindInput
                      className={[
                        "MyFansHeader",
                        "justify-center",
                        "items-center",
                      ]}
                      flexProps={{
                        flexDirection: "flex-row",
                      }}
                      label={intl.formatMessage({
                        id: "dashboard.myFans.to",
                        defaultMessage: "To",
                      })}
                      labelProps={{
                        margin: ["mr-2"],
                      }}
                      min={firstDatePickerDate}
                      max={lastDatePickerDate}
                      position='relative'
                      backgroundColor={"bg-primary-bg-color"}
                      borderColor={"border-transparent"}
                      placeholder={"YYYY/MM/DD"}
                      placeholderColor={"placeholder-gray-500"}
                      errorProps={{
                        textAlign: "text-right",
                      }}
                      value={toFilter}
                      type={TailwindInputType.date}
                      name={"to"}
                      onChange={e => handleToDateChange(e.target.value)}
                    />
                  </>
                )}
                {hasFans && (
                  <TailwindDropdown
                    fullWidth={false}
                    fullHeight={true}
                    width={"w-36"}
                    pseudoClasses={["xs:w-24"]}
                    backgroundColor={"bg-primary-bg-color"}
                    items={sortByOptions}
                    placeholder={"Sort By"}
                    value={activeSortingProperty}
                    name={"my-fans-transactions-sort"}
                    onChange={onChangeActiveSortingProperty}
                  />
                )}
              </TailwindFlex>
            </TailwindFlex>
            <TailwindFlex height={"h-full"} overflow={"overflow-hidden"}>
              <MyFansTable />
            </TailwindFlex>
          </React.Fragment>
        )}
      </TailwindFlex>
    </StudioModelAccessRights>
  );
};

export default injectIntl(
  inject(
    "studioModelsAccessRightsStore",
    "myFansStore",
    "layoutStore",
    "showcaseStore"
  )(observer(MyFansContainer))
);
