import React, { FunctionComponent } from "react";
import { inject, observer } from "mobx-react";
import AuthStore from "core/stores/auth/AuthStore";
import StudioAccountInfoStep from "./studio/studio-account-info-step";
import CreateAccountStep from "./create-account-step";
import ModelAccountInfoStep from "./model/model-account-info-step";
import RegisterUploadStep from "./model/upload-step";
import RegisterCertificationUploadStep from "./model/upload-certification-form-step";
import RegisterStudioUploadStep from "./studio/upload-step";
import AgreementStep from "./agreement-step";
import SummaryStep from "./summary-step";
import "./styles.scss";
import { RegistrationStep } from "core/stores/auth/enums";
import TailwindFlex from "library/components/_tailwind/flex";

type RegisterModalProps = {
  authStore?: AuthStore;
};

const RegisterModal: FunctionComponent<RegisterModalProps> = ({
  authStore,
}) => {
  const { registerActiveStep: activeStep } = authStore!;

  return (
    <TailwindFlex
      display={"flex"}
      justifyContent={"justify-center"}
      style={{maxHeight: "calc(100vh - 100px)"}}
      overflow={["overflow-y-auto"]}>
      {activeStep === RegistrationStep.AUTH_CREDENTIALS && (
        <CreateAccountStep />
      )}

      {/*Model Steps*/}
      {activeStep === RegistrationStep.MODEL_ACCOUNT_INFO && (
        <ModelAccountInfoStep />
      )}
      {activeStep === RegistrationStep.MODEL_DOCUMENT_SELECTION_AND_UPLOAD && (
        <RegisterUploadStep />
      )}
      {activeStep === RegistrationStep.MODEL_CERTIFICATION_UPLOAD && (
        <RegisterCertificationUploadStep />
      )}
      {activeStep === RegistrationStep.MODEL_AGREEMENT && <AgreementStep />}
      {activeStep === RegistrationStep.MODEL_SUMMARY && <SummaryStep />}

      {/*Studio Steps*/}
      {activeStep === RegistrationStep.STUDIO_ACCOUNT_INFO && (
        <StudioAccountInfoStep />
      )}
      {activeStep === RegistrationStep.STUDIO_DOCUMENT_SELECTION_AND_UPLOAD && (
        <RegisterStudioUploadStep />
      )}
      {activeStep === RegistrationStep.STUDIO_AGREEMENT && <AgreementStep />}
      {activeStep === RegistrationStep.STUDIO_SUMMARY && <SummaryStep />}
    </TailwindFlex>
  );
};
export default inject("authStore")(observer(RegisterModal));
