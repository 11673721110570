import React from "react";
import TailwindFlex from "../flex";
import { CustomIconName } from "../icon/icons/enums";
import TailwindIcon from "../icon";
import TailwindText from "../text";

export type FeedHeaderActivityDetailsProps = {
  iconName: CustomIconName | string;
  text: string;
};

const FeedHeaderActivityDetails: React.ComponentType<FeedHeaderActivityDetailsProps> = ({
  iconName,
  text,
}) => {
  return (
    <TailwindFlex
      padding={["py-5", "px-3"]}
      backgroundColor={"bg-gray-600"}
      alignItems={"items-center"}
    >
      <TailwindIcon name={iconName} margin={["mr-2"]} fontSize={"text-3xl"} />
      <TailwindText fontWeight={"font-bold"} fontSize={"text-base"}>
        {text}
      </TailwindText>
    </TailwindFlex>
  );
};

export default FeedHeaderActivityDetails;
