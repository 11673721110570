import React, { useMemo } from "react";
import TailwindButton, { TailwindButtonProps } from "../button";
import {
  TBackgroundColor,
  TBackgroundOpacity,
  TBorderColor,
  TFontWeight,
  THeight,
  TPseudoClasses,
  TTextColor,
  TWidth,
} from "../../../styles/tailwind-classnames";
import { TailwindTextProps } from "../text";
import { TailwindBoxProps } from "../box";

export type ToggleableButtonProps = Omit<TailwindButtonProps, "size"> & {
  borderColor?: TBorderColor;
  backgroundColor?: TBackgroundColor;
  backgroundOpacity?: TBackgroundOpacity;
  textProps?: TailwindTextProps;
  boxProps?: TailwindBoxProps;
  shape?: "square" | "circle";
  isSelected?: boolean;
  selectedBorderColor?: TBorderColor;
  selectedTextColor?: TTextColor;
  selectedFontWeight?: TFontWeight;
  selectedBackgroundColor?: TBackgroundColor;
  selectedBackgroundOpacity?: TBackgroundOpacity;
  hoverBorderColor?: TBorderColor;
  hoverTextColor?: TTextColor;
  hoverFontWeight?: TFontWeight;
  hoverBackgroundColor?: TBackgroundColor;
  hoverBackgroundOpacity?: TBackgroundOpacity;
  size?: "xs" | "sm" | "normal" | "lg" | "xl";
};

const ToggleableButton: React.ComponentType<ToggleableButtonProps> = ({
  borderColor = "border-transparent",
  backgroundColor = "bg-gray-500",
  backgroundOpacity,
  textProps,
  boxProps,
  children,
  shape = "square",
  isSelected,
  selectedBorderColor,
  selectedTextColor,
  selectedFontWeight,
  selectedBackgroundColor,
  selectedBackgroundOpacity,
  hoverBorderColor,
  hoverTextColor,
  hoverFontWeight,
  hoverBackgroundColor,
  hoverBackgroundOpacity,
  size,
  ...props
}) => {
  const sizeClasses = useMemo(() => {
    const classes: {
      width: TWidth;
      height: THeight;
    } = {
      width: "w-40",
      height: "h-40",
    };

    switch (size) {
      case "xs": {
        classes.width = "w-10";
        classes.height = "h-10";
        break;
      }
      case "sm": {
        classes.width = "w-20";
        classes.height = "h-20";
        break;
      }
      case "normal": {
        classes.width = "w-32";
        classes.height = "h-32";
        break;
      }
      case "lg": {
        classes.width = "w-40";
        classes.height = "h-40";
        break;
      }
      case "xl": {
        classes.width = "w-60";
        classes.height = "h-60";
        break;
      }
    }

    return classes;
  }, [size]);

  const selectedClasses = useMemo(() => {
    const classes: {
      borderColor: TBorderColor;
      textColor: TTextColor;
      fontWeight: TFontWeight;
      backgroundColor: TBackgroundColor;
      backgroundOpacity: TBackgroundOpacity;
    } = {
      borderColor: "border-transparent",
      textColor: "text-white",
      fontWeight: "font-normal",
      backgroundColor: "bg-gray-500",
      backgroundOpacity: "bg-opacity-100",
    };

    if (isSelected) {
      if (selectedBorderColor) {
        classes.borderColor = selectedBorderColor;
      }

      if (selectedTextColor) {
        classes.textColor = selectedTextColor;
      }

      if (selectedFontWeight) {
        classes.fontWeight = selectedFontWeight;
      }

      if (selectedBackgroundColor) {
        classes.backgroundColor = selectedBackgroundColor;
      }
    }
    return classes;
  }, [
    isSelected,
    selectedBorderColor,
    selectedTextColor,
    selectedFontWeight,
    selectedBackgroundColor,
    selectedBackgroundOpacity,
  ]);

  const _psuedoClasses = useMemo(() => {
    const classes: TPseudoClasses[] = [];

    if (hoverBorderColor) {
      classes.push(`hover:${hoverBorderColor}` as TPseudoClasses);
    }

    if (hoverTextColor) {
      classes.push(`hover:${hoverTextColor}` as TPseudoClasses);
    }

    if (hoverFontWeight) {
      classes.push(`hover:${hoverFontWeight}` as TPseudoClasses);
    }

    if (hoverBackgroundColor) {
      classes.push(`hover:${hoverBackgroundColor}` as TPseudoClasses);
    }

    if (hoverBackgroundOpacity) {
      classes.push(`hover:${hoverBackgroundOpacity}` as TPseudoClasses);
    }

    return classes;
  }, [
    hoverBorderColor,
    hoverTextColor,
    hoverFontWeight,
    hoverBackgroundColor,
    hoverBackgroundOpacity,
  ]);

  return (
    <TailwindButton
      {...props}
      height={sizeClasses?.height}
      width={sizeClasses?.width}
      fullWidth={false}
      rounded={shape !== "square"}
      borderRadius={shape === "circle" ? "rounded-full" : undefined}
      borderWidth={["border-2"]}
      borderColor={isSelected ? selectedClasses?.borderColor : borderColor}
      backgroundColor={
        isSelected ? selectedClasses?.backgroundColor : backgroundColor
      }
      backgroundOpacity={
        isSelected ? selectedClasses?.backgroundOpacity : backgroundOpacity
      }
      pseudoClasses={_psuedoClasses}
      textColor={selectedClasses?.textColor}
      alignItems={"items-center"}
      justifyContent={"justify-center"}
      textProps={{
        ...textProps,
        fontWeight: selectedClasses?.fontWeight,
        textColor: "text-current",
        textAlign: "text-center",
        pseudoClasses: [`hover:${hoverTextColor}` as TPseudoClasses],
      }}>
      {children}
    </TailwindButton>
  );
};

export default ToggleableButton;
