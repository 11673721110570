import { TailwindDropdownItem } from "library/components/_tailwind/dropdown";

export const MESSAGE_PRICE_DROPDOWN_ITEMS: TailwindDropdownItem[] = [
  {
    label: "5 Tokens / per message",
    value: 5,
  },
  {
    label: "6 Tokens / per message",
    value: 6,
  },
  {
    label: "7 Tokens / per message",
    value: 7,
  },
  {
    label: "8 Tokens / per message",
    value: 8,
  },
  {
    label: "9 Tokens / per message",
    value: 9,
  },
  {
    label: "10 Tokens / per message",
    value: 10,
  },
];