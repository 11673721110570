import React from "react";
import { FormattedMessage } from "react-intl";
import { makeAutoObservable } from "mobx";
import {
  AppCamsModelRouteKey,
  AppCommonRouteKey,
} from "core/stores/route/enums";
import { history } from "library/core/utility";
import TailwindIcon from "library/components/_tailwind/icon";
import { ShowcaseStep } from "library/components/_tailwind/showcase";
import LocalStorage from "library/core/utility/local-storage";
import { UserAccountStatus } from "../../../common/my-page/stores/profile/enums";
import TailwindText from "library/components/_tailwind/text";
import { CAMS_MODELS_STORAGE_KEYS } from "library/core/config/storage";
import { SHOWCASE_TARGET_CLASSNAMES, ShowcasePages } from "./const";
import { routeStore } from "core/stores/route/RouteStore";
import { ShowcaseType } from "./types";
import { profileStore } from "core/stores";

export default class ShowcaseStore {
  public currentPage: number = 0;
  public playingShowcase: boolean = false;
  public playingBroadcastShowcase: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  enableShowcase = (type: ShowcaseType = "welcome") => {
    if (type === "broadcast") {
      this.playingBroadcastShowcase = true;
    } else {
      this.playingShowcase = true;
    }
  };

  disableShowcase = (type: ShowcaseType = "welcome") => {
    if (type === "broadcast") {
      this.playingBroadcastShowcase = false;
    } else {
      this.playingShowcase = false;
    }
  };

  startWelcomeShowcase = () => {
    // check for stored value in case user signs in as another user
    const showCaseKey = CAMS_MODELS_STORAGE_KEYS.SHOWCASE_STEP;
    const storedPage = LocalStorage.get(
      `${showCaseKey}_${profileStore.profile.username}`
    );
    this.currentPage = storedPage ? storedPage : 0;
    this.enableShowcase();

    // welcome tutorial will change page to start the showcase
    if (this.currentPage < ShowcasePages.length) {
      history.push(
        `${routeStore.getSiteRouteURL(ShowcasePages[this.currentPage])}`
      );
    } else {
      history.push(`${routeStore.getSiteRouteURL(ShowcasePages[0])}`);
    }
  };

  nextWelcomeShowcasePage = () => {
    // skip these pages if user cannot access
    if (
      ((profileStore.isStudioModel ||
        !profileStore.isCurrentAccountStatus(UserAccountStatus.APPROVED)) &&
        ShowcasePages[this.currentPage + 1] == AppCommonRouteKey.payout) ||
      (!profileStore.isCurrentAccountStatus(UserAccountStatus.APPROVED) &&
        (ShowcasePages[this.currentPage + 1] == AppCamsModelRouteKey.myFans ||
          ShowcasePages[this.currentPage + 1] == AppCommonRouteKey.broadcast ||
          ShowcasePages[this.currentPage + 1] == AppCommonRouteKey.earnings))
    ) {
      this.currentPage++;
      this.nextWelcomeShowcasePage();
    } else {
      this.currentPage = (this.currentPage + 1) % ShowcasePages.length;
      LocalStorage.set(
        `${CAMS_MODELS_STORAGE_KEYS.SHOWCASE_STEP}_${profileStore.profile.username}`,
        this.currentPage
      );

      if (!this.currentPage) {
        this.disableShowcase();
        profileStore.setShouldShowTutorialToModel(false);
      } else {
        history.push(
          `${routeStore.getSiteRouteURL(ShowcasePages[this.currentPage])}`
        );
      }
    }
  };

  getWelcomeShowcaseSteps = (
    page: AppCommonRouteKey | AppCamsModelRouteKey
  ): ShowcaseStep[] => {
    if (page === AppCamsModelRouteKey.myPage) {
      return [
        {
          content: "Update your profile and media all on one page.",
          placement: "right",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.myPageNavItem}`,
          disableBeacon: true,
        },
        {
          content: "If you like, upload a tasteful nude for your profile.",
          placement: "left",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.myPageProfilePhotoNude}`,
          disableBeacon: true,
        },
        {
          content: "Upload or record videos and sell access for tokens.",
          placement: "right",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.myPageVideoTitle}`,
          disableBeacon: true,
        },
      ];
    } else if (page === AppCamsModelRouteKey.myFans) {
      return [
        {
          content: (
            <TailwindText>
              <FormattedMessage
                id='myFans.showcase-title'
                defaultMessage='View all your important fans based on earning. You can contact them by clicking the {icon} icon.'
                values={{
                  icon: (
                    <TailwindIcon
                      name={"mail"}
                      display={"inline-block"}
                      position={"relative"}
                      style={{
                        top: "2px",
                      }}
                    />
                  ),
                }}
              />
            </TailwindText>
          ),
          placement: "right",
          target: ".MyFans__title",
          disableBeacon: true,
        },
      ];
    } else if (page === AppCamsModelRouteKey.referrals) {
      return [
        {
          content:
            "Refer members and receive 15% commission on all tokens spent on other models. You will also earn 100% for the first 6 months and 80% thereafter if they spend on you. Just promote your profile page on social media.",
          placement: "bottom",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.referralsMemberLink}`,
          disableBeacon: true,
        },
        {
          content:
            "Refer other models and receive a percentage of whatever they make!",
          placement: "bottom",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.referralsModelLink}`,
          disableBeacon: true,
        },
      ];
    } else if (page === AppCommonRouteKey.broadcast) {
      return [
        {
          content:
            "Choose if you want members to enter your room for free or immediately have to pay per minute.",
          placement: "left",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.broadcastTokens}`,
          disableBeacon: true,
        },
        {
          content:
            "For free-to-enter rooms, you can do private shows, do a group show for those who tip a goal amount, or just get tips.",
          placement: "left-end",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.broadcastShowTypes}`,
          disableBeacon: true,
        },
        {
          content:
            'Click this icon to setup a "spin wheel" of fun awards for tips.',
          placement: "left",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.broadcastWheelOfFun}`,
          disableBeacon: true,
        },
        {
          content:
            'Type a message and click the "Save" checkbox to save it as a "Quick Reply"',
          placement: "right",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.quickReplyTitle}`,
          disableBeacon: true,
        },
      ];
    } else if (page === AppCommonRouteKey.broadcastMemberVerification) {
      return [
        {
          content:
            "Choose if you want members to enter your room for free or immediately have to pay per minute.",
          placement: "left",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.broadcastTokens}`,
          disableBeacon: true,
        },
        {
          content:
            "For free-to-enter rooms, you can do private shows, do a group show for those who tip a goal amount, or just get tips.",
          placement: "left-end",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.broadcastShowTypes}`,
          disableBeacon: true,
        },
        {
          content:
            'Click this icon to setup a "spin wheel" of fun awards for tips.',
          placement: "left",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.broadcastWheelOfFun}`,
          disableBeacon: true,
        },
        {
          content:
            'Type a message and click the "Save" checkbox to save it as a "Quick Reply"',
          placement: "right",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.quickReplyTitle}`,
          disableBeacon: true,
        },
      ];
    } else if (page === AppCommonRouteKey.earnings) {
      return [
        {
          content:
            "Our new earnings reports makes it more clear how you make money from tokens.",
          placement: "right",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.earningsYouMake}`,
          disableBeacon: true,
        },
        {
          content:
            "Your percent cut can increase up to 75% per Payment Period.",
          placement: "auto",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.earningsTier}`,
          disableBeacon: true,
        },
      ];
    } else if (page === AppCamsModelRouteKey.leaderboards) {
      return [
        {
          content:
            "Earn a $100 or $1000 bonus by being the model of the day or month based on tokens earned.",
          placement: "auto",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.leaderboardModelOfDay}`,
          disableBeacon: true,
        },
      ];
    } else if (page === AppCommonRouteKey.messages) {
      return [
        {
          content:
            "The message center lets you contact your most important members and reply to members who have sent you messages. We also use the message center for announcements and model support.",
          placement: "right",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.messagesTitle}`,
          disableBeacon: true,
        },
      ];
    } else if (page === AppCommonRouteKey.payout) {
      return [
        {
          content:
            "We have multiple ways to get paid based on your country location.",
          placement: "right",
          target: `.${SHOWCASE_TARGET_CLASSNAMES.payoutTitle}`,
          disableBeacon: true,
        },
      ];
    }

    return [];
  };
}

export const showcaseStore = new ShowcaseStore();
