import React from "react";
import "./styles.scss";
import classnames from "classnames";

type TrashIconProps = {
  className?: string;
};

const TrashIcon: React.ComponentType<TrashIconProps> = ({ className }) => (
  <span className={classnames("TrashIcon", className && className)}>
    <svg
      version='1.2'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      overflow='visible'
      preserveAspectRatio='none'
      viewBox='0 0 24 24'
      width='20'
      height='20'>
      <g>
        <path
          d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z'
          vectorEffect='non-scaling-stroke'
        />
      </g>
    </svg>
  </span>
);

export default TrashIcon;
