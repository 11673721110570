import React from "react";
import "./styles.scss";
import clsx from "clsx";

type QuestionMarkIconProps = {
  className?: string;
};

const QuestionMarkIcon: React.ComponentType<QuestionMarkIconProps> = ({
  className,
}) => (
  <span className={clsx("QuestionMarkIcon", className && className)}>
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  </span>
);

export default QuestionMarkIcon;
