import React, { useCallback, useMemo } from "react";
import TailwindText, { TailwindTextProps } from "../text";
import TailwindIcon, { TailwindIconProps } from "../icon";
import TailwindBox, { TailwindBoxProps } from "../box";
import { CustomIconName } from "../icon/icons/enums";
import {
  TBackgroundColor,
  TFontSize,
  THeight,
  TLineHeight,
  TPadding,
  TTextColor,
} from "../../../styles/tailwind-classnames";
import ThemeStore from "../../../core/stores/theme/ThemeStore";
import { inject, observer } from "mobx-react";

export const BUTTON_DEFAULT_ROUNDED = false;

export type TailwindButtonProps = Omit<TailwindBoxProps, "backgroundColor"> & {
  dataTestId?: string;
  isActive?: boolean;
  textProps?: TailwindTextProps;
  leftIconProps?: TailwindIconProps;
  settings?: boolean;
  rightIconProps?: TailwindIconProps;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  showSpinner?: boolean;
  disabled?: boolean;
  disabledProps?: {
    textColor?: TTextColor;
    backgroundColor?: TBackgroundColor;
  };
  cursor?: string;
  fullWidth?: boolean;
  backgroundColor?: "primary" | "secondary" | "tertiary" | TBackgroundColor;
  size?: "sm" | "normal" | "lg" | "xs";
  rounded?: boolean;
  bold?: boolean;
  underline?: boolean;
  type?: "button" | "submit" | "reset";
  lineHeight?: TLineHeight;
  themeStore?: ThemeStore;
};

const TailwindButton = React.forwardRef<HTMLButtonElement, TailwindButtonProps>(
  (
    {
      dataTestId,
      isActive,
      textProps,
      leftIconProps,
      rightIconProps,
      settings,
      onClick,
      showSpinner,
      disabled,
      disabledProps,
      fullWidth = true,
      backgroundColor = "bg-transparent",
      textColor = "text-main-color",
      children,
      pseudoClasses,
      width,
      height,
      cursor,
      size = "normal",
      padding,
      rounded = BUTTON_DEFAULT_ROUNDED,
      bold = true,
      underline = false,
      borderRadius,
      lineHeight,
      as = "button",
      type,
      themeStore,
      ...props
    },
    ref
  ) => {
    const _psuedoClasses = useMemo(() => {
      let classes = ["focus:outline-none"];
      if (pseudoClasses && pseudoClasses.length > 0) {
        classes = [...classes, ...(pseudoClasses as any)];
      }

      return classes;
    }, [pseudoClasses]);

    const _rightIconProps = useMemo(() => {
      if (showSpinner) {
        return {
          ...rightIconProps,
          iconProps: {
            size: 1,
          },
          name: CustomIconName.spinner,
        };
      }

      return rightIconProps;
    }, [showSpinner, rightIconProps]);

    const sizeClasses = useMemo(() => {
      const classes: {
        padding: TPadding[];
        fontSize: TFontSize;
        height: THeight;
      } = {
        padding: ["px-3", "py-2"],
        fontSize: "text-base",
        height: "h-auto",
      };

      switch (size) {
        case "xs": {
          classes.padding = ["px-0", "py-0"];
          classes.fontSize = "text-xs";
          classes.height = "h-8";
          break;
        }
        case "sm": {
          classes.padding = ["px-2", "py-0"];
          classes.fontSize = "text-sm";
          classes.height = "h-8";
          break;
        }
        case "normal": {
          classes.padding = ["px-3", "py-2"];
          classes.fontSize = "text-base";
          classes.height = "h-10";
          break;
        }
        case "lg": {
          classes.padding = ["px-4", "py-3"];
          classes.fontSize = "text-lg";
          classes.height = "h-12";
          break;
        }
      }

      if (padding) {
        classes.padding = padding as TPadding[];
      }

      if (textProps?.fontSize) {
        classes.fontSize = textProps?.fontSize as TFontSize;
      }

      if (height) {
        classes.height = height;
      }

      return classes;
    }, [size, padding, height, textProps]);

    const onClickButton = useCallback(
      (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        if (onClick) {
          onClick(event);
        }
      },
      [onClick]
    );
    const { currentThemePalette } = themeStore!;

    const themeColors = useMemo(() => {
      let colors: {
        backgroundColor: TBackgroundColor;
        textColor: TTextColor;
      } = {
        textColor: textColor || "text-main-color",
        backgroundColor: "bg-transparent",
      };

      if (disabled) {
        const textColorClass =
          currentThemePalette?.["button-disabled-text-color-tw-class"];

        if (settings) {
          const backgroundClass =
            currentThemePalette?.["button-disabled-background-color-tw-class"];

          if (backgroundClass) {
            colors.backgroundColor =
              disabledProps?.backgroundColor || backgroundClass;
          }
        }

        if (textColorClass) {
          colors.textColor = disabledProps?.textColor || textColorClass;
        }
      } else {
        switch (backgroundColor) {
          case "primary":
            {
              const backgroundClass =
                currentThemePalette?.["button-primary-bg-color-tw-class"];
              if (backgroundClass) {
                colors.backgroundColor = backgroundClass;
              }
              const textColorClass =
                currentThemePalette?.["button-primary-text-color-tw-class"];
              if (textColorClass) {
                colors.textColor = textColorClass;
              }
            }
            break;
          case "secondary":
            {
              const backgroundClass =
                currentThemePalette?.["button-secondary-bg-color-tw-class"];
              if (backgroundClass) {
                colors.backgroundColor = backgroundClass;
              }
              const textColorClass =
                currentThemePalette?.["button-secondary-text-color-tw-class"];
              if (textColorClass) {
                colors.textColor = textColorClass;
              }
            }
            break;
          case "tertiary":
            {
              const backgroundClass =
                currentThemePalette?.["button-tertiary-bg-color-tw-class"];
              if (backgroundClass) {
                colors.backgroundColor = backgroundClass;
              }
              const textColorClass =
                currentThemePalette?.["button-tertiary-text-color-tw-class"];
              if (textColorClass) {
                colors.textColor = textColorClass;
              }
            }
            break;
          default:
            colors = {
              backgroundColor: backgroundColor,
              textColor: textColor || textProps?.textColor || "text-white",
            };
        }
      }
      return colors;
    }, [
      currentThemePalette,
      backgroundColor,
      textProps,
      disabled,
      disabledProps,
      textColor,
    ]);

    const _borderRadius = useMemo(() => {
      if (borderRadius) {
        return borderRadius;
      }

      return rounded
        ? currentThemePalette?.["button-rounded-border-radius-tw-class"]
        : currentThemePalette?.["button-non-rounded-border-radius-tw-class"];

      if (rounded) {
        return currentThemePalette?.["button-rounded-border-radius-tw-class"];
      }
    }, [rounded, borderRadius]);

    return (
      <TailwindBox
        data-testid={dataTestId}
        data-is-active={isActive}
        data-is-enabled={!disabled}
        {...props}
        ref={ref}
        display={"inline-flex"}
        alignItems={"items-center"}
        padding={sizeClasses?.padding}
        borderRadius={_borderRadius}
        width={fullWidth ? "w-full" : width}
        height={sizeClasses?.height}
        {...themeColors}
        onClick={onClickButton}
        cursor={
          cursor ? cursor : !disabled ? "cursor-pointer" : "cursor-not-allowed"
        }
        pseudoClasses={_psuedoClasses as any}
        htmlElementProps={{
          ...props.htmlElementProps,
          type,
          disabled,
        }}
        as={as}>
        {leftIconProps && leftIconProps?.name && (
          <TailwindIcon
            {...leftIconProps}
            display={"inline-flex"}
            alignItems={"items-center"}
            margin={[
              ...(leftIconProps?.margin || []),
              React.Children.count(children) > 0 ? "mr-2" : undefined,
            ]}
          />
        )}
        <TailwindText
          {...textProps}
          fontSize={sizeClasses?.fontSize}
          fontWeight={bold ? "font-bold" : textProps?.fontWeight}
          textDecoration={underline ? "underline" : textProps?.textDecoration}
          textColor={textProps?.textColor}
          lineHeight={lineHeight}>
          {children}
        </TailwindText>
        {_rightIconProps && _rightIconProps?.name && (
          <TailwindIcon
            display={"inline-flex"}
            alignItems={"items-center"}
            iconProps={_rightIconProps?.iconProps}
            margin={
              _rightIconProps?.margin ||
              (React.Children.count(children) > 0 ? ["ml-2"] : undefined)
            }
            {..._rightIconProps}
          />
        )}
      </TailwindBox>
    );
  }
);

export default inject("themeStore")(observer(TailwindButton));
