import React, { ImgHTMLAttributes, useState } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import MessageCenterUnavailableAttachment from "./messages-unavailable-attachment";
import { Box } from "@material-ui/core";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindBox from "library/components/_tailwind/box";
import Avatar from "library/components/avatar";

type Props = {
  onClick?: () => void;
  isVideo?: boolean;
  isAudio?: boolean;
  isSenderCurrentUser?: boolean;
  username?: string;
} & ImgHTMLAttributes<HTMLImageElement>;

const MediaCarouselImage: React.ComponentType<Props> = ({
  onClick,
  isVideo,
  isAudio,
  isSenderCurrentUser,
  username,
  ...props
}) => {
  const [imageLoadError, setImageLoadError] = useState<boolean>(false);

  const onImageLoadError = () => {
    setImageLoadError(true);
  };

  const handleImageClick = () => {
    if (imageLoadError) {
      return;
    }

    onClick?.();
  };

  return (
    <TailwindBox
      borderRadius={["rounded-lg"]}
      overflow={"overflow-hidden"}
      className={["MessageMediaCarouselImage"]}>
      {imageLoadError ? (
        <TailwindFlex
          className={["error-image", "carouselIcon"]}
          textColor={isSenderCurrentUser ? "text-white" : "text-main-color"}
          style={{
            backgroundColor: isSenderCurrentUser ? "#84a3ba" : "#fff",
          }}>
          <MessageCenterUnavailableAttachment />
        </TailwindFlex>
      ) : isAudio ? (
        <TailwindFlex
          className={[props.className]}
          onClick={handleImageClick}
          cursor='cursor-pointer'>
          <Avatar
            username={username}
            randomBackgroundColor={isSenderCurrentUser ? false : true}
          />
        </TailwindFlex>
      ) : (
        <>
          <img
            style={{
              cursor: "pointer",
            }}
            {...props}
            onError={onImageLoadError}
            onClick={handleImageClick}
          />
          {isVideo && !imageLoadError && (
            <Box onClick={handleImageClick} className='carouselPlayButton'>
              <TailwindIcon name='play' />
            </Box>
          )}
        </>
      )}
    </TailwindBox>
  );
};

export default MediaCarouselImage;
