import React, { useCallback, useEffect, useMemo, useState } from "react";
import { inject, observer } from "mobx-react";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import { BroadcastStreamService } from "common/broadcast/_stores/broadcast-stream/BroadcastStreamService";
import TailwindList, {
  TailwindListType,
} from "library/components/_tailwind/list";
import TailwindListItem from "library/components/_tailwind/list/list-item";
import TailwindButton from "library/components/_tailwind/button";
import { BroadcastWebRTCExtendedMediaDeviceInfo } from "common/broadcast/_stores/broadcast-stream/types";

type Props = {
  broadcastStreamStore?: BroadcastStreamStore;
  broadcastStreamService?: BroadcastStreamService;
};

const QAWidgetDevices: React.ComponentType<Props> = ({
  broadcastStreamStore,
  broadcastStreamService,
}) => {
  const { excludedDevices, setExcludedDevices } = broadcastStreamStore!;
  const { gatherMediaDeviceDataForQAPurposes } = broadcastStreamService!;
  const [cameras, setCameras] = useState<
    BroadcastWebRTCExtendedMediaDeviceInfo[]
  >([]);
  const [microphones, setMicrophones] = useState<
    BroadcastWebRTCExtendedMediaDeviceInfo[]
  >([]);

  useEffect(() => {
    async function getMediaDeviceData() {
      try {
        const { cameras, microphones } =
          await gatherMediaDeviceDataForQAPurposes();
        setCameras(cameras);
        setMicrophones(microphones);
      } catch (error) {}
    }

    getMediaDeviceData();
  }, []);

  const excludedCameras = useMemo(() => {
    return excludedDevices.filter(device => device.kind === "videoinput");
  }, [excludedDevices]);

  const excludedMicrophones = useMemo(() => {
    return excludedDevices.filter(device => device.kind === "audioinput");
  }, [excludedDevices]);

  const shouldGetMinimumWebcamRequirementsError = useMemo(() => {
    if (cameras.length === excludedCameras.length) {
      return true;
    }
    return false;
  }, [cameras, excludedCameras]);

  const shouldGetNoMicrophoneError = useMemo(() => {
    if (microphones.length === excludedMicrophones.length) {
      return true;
    }
    return false;
  }, [microphones, excludedMicrophones]);

  const onClickExcludeUnexclude = useCallback(
    (device: BroadcastWebRTCExtendedMediaDeviceInfo) => {
      if (
        !excludedDevices.find(
          excludedDevice => excludedDevice.deviceId === device.deviceId
        )
      ) {
        setExcludedDevices([...excludedDevices, device]);
      } else {
        setExcludedDevices(
          excludedDevices.filter(
            excludedDevice => excludedDevice.deviceId !== device.deviceId
          )
        );
      }
    },
    [cameras, microphones, excludedDevices]
  );

  const onClickExcludeUnexcludeAllCameras = useCallback(() => {
    if (!excludedCameras.length) {
      setExcludedDevices([...excludedDevices, ...cameras]);
    } else {
      setExcludedDevices(
        excludedDevices.filter(device => device.kind !== "videoinput")
      );
    }
  }, [cameras, excludedCameras, excludedDevices]);

  const onClickExcludeUnexcludeAllMicrophones = useCallback(() => {
    if (!excludedMicrophones.length) {
      setExcludedDevices([...excludedDevices, ...microphones]);
    } else {
      setExcludedDevices(
        excludedDevices.filter(device => device.kind !== "audioinput")
      );
    }
  }, [microphones, excludedMicrophones]);

  return (
    <React.Fragment>
      <div className={"mb-4"}>
        <div className={"flex items-center justify-between mb-2"}>
          <b>Cameras</b>
          <TailwindButton
            size={"sm"}
            backgroundColor={"secondary"}
            fullWidth={false}
            onClick={onClickExcludeUnexcludeAllCameras}>
            {shouldGetMinimumWebcamRequirementsError
              ? "Unexclude all"
              : "Exclude all"}
          </TailwindButton>
        </div>
        {shouldGetMinimumWebcamRequirementsError && (
          <div className={"text-sm text-red-500 mb-4"}>
            Hint: You should get minimum webcam requirements error
          </div>
        )}
        <TailwindList type={TailwindListType.ol} margin={["ml-4"]}>
          {cameras.map(camera => (
            <TailwindListItem
              key={camera.deviceId}
              margin={["mb-4"]}
              borderWidth={["border"]}
              borderColor={"border-black"}>
              <div className={"flex items-center"}>
                <span className={"w-9/12"}>
                  <div>{camera.label}</div>
                  <div>
                    {excludedDevices.includes(camera) && (
                      <span className={"text-red-500"}>(Excluded)</span>
                    )}
                  </div>
                </span>
                <div className={"w-3/12"}>
                  <TailwindButton
                    size={"sm"}
                    justifyContent={"justify-center"}
                    backgroundColor={"secondary"}
                    textAlign={"text-center"}
                    onClick={() => onClickExcludeUnexclude(camera)}>
                    {excludedDevices.includes(camera) ? "Unexclude" : "Exclude"}
                  </TailwindButton>
                </div>
              </div>
            </TailwindListItem>
          ))}
        </TailwindList>
      </div>
      <div className={"mb-4"}>
        <div className={"flex items-center justify-between mb-2"}>
          <b>Microphones</b>
          <TailwindButton
            size={"sm"}
            backgroundColor={"secondary"}
            fullWidth={false}
            onClick={onClickExcludeUnexcludeAllMicrophones}>
            {shouldGetNoMicrophoneError ? "Unexclude all" : "Exclude all"}
          </TailwindButton>
        </div>
        {shouldGetNoMicrophoneError && (
          <div className={"text-sm text-red-500 mb-4"}>
            Hint: You should get no microphone error
          </div>
        )}
        <TailwindList type={TailwindListType.ol} margin={["ml-4"]}>
          {microphones.map(microphone => (
            <TailwindListItem
              key={microphone.deviceId}
              margin={["mb-4"]}
              borderWidth={["border"]}
              borderColor={"border-black"}>
              <div className={"flex items-center"}>
                <span className={"w-9/12"}>
                  <div>{microphone.label}</div>
                  <div>
                    {excludedDevices.includes(microphone) && (
                      <span className={"text-red-500"}>(Excluded)</span>
                    )}
                  </div>
                </span>
                <div className={"w-3/12"}>
                  <TailwindButton
                    size={"sm"}
                    justifyContent={"justify-center"}
                    backgroundColor={"secondary"}
                    textAlign={"text-center"}
                    onClick={() => onClickExcludeUnexclude(microphone)}>
                    {excludedDevices.includes(microphone)
                      ? "Unexclude"
                      : "Exclude"}
                  </TailwindButton>
                </div>
              </div>
            </TailwindListItem>
          ))}
        </TailwindList>
      </div>
      <div>
        <div>
          <b>Excluded Devices</b>
        </div>
        <div className={"text-sm text-red-500"}>
          Note: The excluded devices reset if you get an error.
        </div>
        <div className={"text-sm"}>
          This allows you to exclude devices so that you can test features like
          "you don't have any 720p webcam", "you don't have any microphone"
          errors.
        </div>
      </div>
    </React.Fragment>
  );
};

export default inject(
  "broadcastStreamService",
  "broadcastStreamStore"
)(observer(QAWidgetDevices));
