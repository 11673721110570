import config from "../../config";
import cookie from "js-cookie";
import {
  GRAYLOG_AVOID_PREFIXING_OBJECT_KEYS,
  GRAYLOG_LOG_SENDING_TIMER_IN_SECONDS,
} from "./consts";
import {
  GraylogPayload,
  GraylogPayloadBase,
  GraylogVersionHost,
} from "./types";
import axios from "axios";

const logPrefix = "[graylog.ts]";

const graylogAxios = axios.create({
  baseURL: config.graylogEndpoint,
});

export default class GrayLog {
  private graylogQueue: GraylogPayloadBase[] = [];
  private postInterval: any = null;
  private versionHost: GraylogVersionHost = {
    version: "1.1",
  };
  readonly messagePrefix: string | undefined = undefined;
  readonly intervalInSeconds: number = GRAYLOG_LOG_SENDING_TIMER_IN_SECONDS;

  constructor(
    messagePrefix?: string,
    intervalInSeconds: number = GRAYLOG_LOG_SENDING_TIMER_IN_SECONDS
  ) {
    this.clearQueue();
    this.messagePrefix = messagePrefix;
    this.intervalInSeconds = intervalInSeconds;
  }

  public logImmediate = (
    shortMessage: string,
    level: number = 1,
    data: Object | null = null
  ) => {
    this.log(shortMessage, level, data, true, false);
  };

  public log = (
    shortMessage: string,
    level: number = 1,
    data: Object | null = null,
    skipTimer: boolean = false,
    rewriteExistingInQueue: boolean = false
  ) => {
    console.log(logPrefix, "log started");
    const prefixedShortMessage = this.getPrefixedShortMessage(shortMessage);

    // per gelf payload spec, additional fields need to be prefixed with an underscore
    const prefixedData = {};

    const basePayload = {
      short_message: prefixedShortMessage,
      level,
    };

    if (data) {
      Object.keys(data)
        .filter(
          key =>
            !GRAYLOG_AVOID_PREFIXING_OBJECT_KEYS[key] ||
            GRAYLOG_AVOID_PREFIXING_OBJECT_KEYS[key] === false
        )
        .forEach(key => (prefixedData[`_${key}`] = data[key]));

      Object.keys(data)
        .filter(key => GRAYLOG_AVOID_PREFIXING_OBJECT_KEYS[key] === true)
        .forEach(key => (basePayload[key] = data[key]));
    }

    if (skipTimer) {
      const payload = {
        ...this.versionHost,
        ...basePayload,
        ...prefixedData,
      };

      console.log(logPrefix, "log skipping timer");
      this.postPayload(payload);
    } else {
      if (this.graylogQueue.length === 0) {
        this.setInterval();
      }

      console.log(logPrefix, "NOT skipping timer, adding to queue");

      const payload = {
        ...basePayload,
        short_message: prefixedShortMessage,
        level,
        ...prefixedData,
      };
      this.queuePayload(payload, rewriteExistingInQueue);
    }
  };

  private queuePayload = (
    payload: GraylogPayloadBase,
    rewriteExistingInQueue: boolean = false
  ) => {
    if (rewriteExistingInQueue) {
      this.graylogQueue = this.graylogQueue.filter(
        log => log.short_message !== payload.short_message
      );
    }
    this.graylogQueue.push(payload);
  };

  private isGraylogEnabled = () => {
    return config.graylogEnabled !== "false" ||
      (cookie.get("graylogEnabled") ?? "false") !== "false";
  };

  private postPayload = (payload: GraylogPayload) => {
    if (!this.isGraylogEnabled()) {
      return;
    }
    console.log(
      logPrefix,
      "postPayload starting axios post to",
      config.graylogEndpoint
    );

    const payloadWithCorrectedHost =
      this.getPayloadWithHostRemovedIfHostUndefined(payload);

    graylogAxios.post("/", payloadWithCorrectedHost).catch(() => {});
  };

  private setInterval = () => {
    this.clearInterval();
    this.postInterval = setInterval(
      this.postQueue,
      this.intervalInSeconds * 1000
    );
  };

  private clearInterval = () => {
    clearInterval(this.postInterval);
  };

  private get hasMessagePrefix() {
    return this.messagePrefix && this.messagePrefix.trim() !== "";
  }

  private getPrefixedShortMessage = (shortMessage: string) => {
    return this.hasMessagePrefix
      ? `${this.messagePrefix} ${shortMessage}`
      : shortMessage;
  };

  private postQueue = () => {
    this.clearInterval();
    if (this.graylogQueue.length > 0) {
      const shortMessage = `Data Dump containing logs from last ${this.intervalInSeconds} secs.`;
      const prefixedShortMessage = this.getPrefixedShortMessage(shortMessage);
      const payload = {
        ...this.versionHost,
        short_message: prefixedShortMessage,
        level: 1,
        data: JSON.stringify(this.graylogQueue),
      };
      this.postPayload(payload);
      this.clearQueue();
    }
  };

  private getPayloadWithHostRemovedIfHostUndefined = (
    payload: GraylogPayload
  ) => {
    const _payload = { ...payload };
    if (!_payload.host) {
      delete _payload.host;
    }

    return _payload;
  };

  private clearQueue = () => {
    this.graylogQueue = [];
  };
}

export const graylog = new GrayLog("[NEW MODEL CENTER]:");
