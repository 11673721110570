export enum MY_FANS_TAB {
  PAST_YEAR = "all",
  THIS_YEAR = "this_year",
  LAST_30_DAYS = "last_30_days",
  ONLY_FAN_CLUB = "only_fan_club",
  DATE_RANGE = "date_range",
}

export enum MY_FANS_SORTING_PROPERTY {
  TOKEN_AMOUNT = "-earning",
  DATE = "-created_date",
  ACTIVITY = "product",
}
