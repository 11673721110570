import React from "react";
import TailwindBox from "library/components/_tailwind/box";
import TailwindText from "library/components/_tailwind/text";
import "./styles.scss";
import TailwindList, {
  TailwindListType,
} from "library/components/_tailwind/list";
import TailwindListItem from "library/components/_tailwind/list/list-item";
import TailwindAnchor from "library/components/_tailwind/anchor";

type Props = {};
const ProtectKids = ({}: Props) => {
  return (
    <div className={"ProtectKids"}>
      <TailwindText
        display={"block"}
        fontSize={"text-3xl"}
        fontWeight={"font-bold"}
        padding={["py-2"]}
        margin={["mb-4"]}>
        Protect Your Kids: Keeping Children Away from Adult Sites
      </TailwindText>
      <TailwindBox>
        <p className={"mb-4"}>
          In our on-going efforts at Cams to make your experience as fun and
          safe as possible, we have created this document to help parents
          understand how to protect their children from inappropriate online
          material.
        </p>
        <p className={"mb-4"}>
          The first step to protect your family is to install parental control
          software on your computer. Some of the best-known and respected
          parental control software packages are CyberPatrol, Net Nanny and
          Safety Surf. Also, some ISPs (Internet Service Providers, the
          companies that let your modem connect to the Internet) provide content
          filtering as part of their basic service. Check with your ISP to see
          if this is an option they offer.
        </p>
        <p className={"mb-4"}>
          Parental control software works by blocking access to specific
          websites and online content. In most cases, when you buy the software
          it already contains a large list of sites that are inappropriate for
          children. You can then update the software through the manufacturer's
          website or, in some cases, the software will update itself.
        </p>
        <p className={"mb-4"}>
          Another way filtering software works is to block sites based on key
          words, such as "sex." However, filtering software doesn't have to just
          block access to adult-oriented material. You can configure the
          software to filter for topics such as tobacco, drugs and drug culture,
          alcohol, violence and racism.
        </p>
        <p className={"mb-4"}>
          Since there might be only one computer in your household, and you
          might be using the same computer as the children, you can set up the
          system to filter content during certain hours, such as when you're at
          work and not around to supervise your children's web surfing. You can
          also setup the system to create a password, that only you know, to
          override the restrictions.
        </p>
        <p className={"mb-4"}>
          Some programs can also block personal information, such as name,
          address and phone number, from being sent from your computer. This is
          an excellent way to protect children from potential online predators.
        </p>
        <p className={"mb-4"}>
          Many programs allow you to create different levels of security and
          filtering based on the different ages of the computer's users.
          Children can be given high security and heavy content filtering, but
          when you log on to the machine, you can set it to not filter any
          content at all.
        </p>
        <p className={"mb-4"}>
          One thing to remember is that by installing filtering software on your
          computer, you might also restrict childrens' access to legitimate
          sites. This is especially true if you rely on key words (such as
          "sex") for blocking. Certain key words appear on legitimate news and
          information sites, so it's best to experiment with different settings
          when configuring the filter controls.
        </p>
        <p className={"mb-4"}>
          Installing filtering software is no guarantee that children won't be
          exposed to some inappropriate material online. New websites pop up
          everyday, so it's important to keep your software up to date. Also,
          learn how your Internet browser software works so that you can check
          the sites your family has visited (this is the "History" feature,
          which displays a list of websites visited by the web browser).
        </p>
        <p className={"mb-4"}>
          Talking to your children about the possible dangers that exist on the
          Internet, and supervising their web surfing are still the best ways to
          protect them.
        </p>
        <p className={"mb-2"}>
          Some key general Internet-related safety points for your children:
        </p>
        <TailwindList type={TailwindListType.ul} margin={["mb-4"]}>
          <TailwindListItem padding={["py-2"]} margin={["ml-8"]}>
            Tell them to never give out personal information about themselves or
            the family to strangers they might meet online or in a chat room.
          </TailwindListItem>
          <TailwindListItem padding={["py-2"]} margin={["ml-8"]}>
            Remind them that online it's easy for people to pretend to be
            someone else. They can easily change their name, age and gender in
            an effort to get close to children.
          </TailwindListItem>
          <TailwindListItem padding={["py-2"]} margin={["ml-8"]}>
            Teach them that they should never meet a new online friend without
            an adult present.
          </TailwindListItem>
          <TailwindListItem padding={["py-2"]} margin={["ml-8"]}>
            Tell them to never give out their picture to a stranger, and that if
            they ever get a picture with sexual content, or something they find
            disturbing, that they should get off the computer immediately and
            tell you about it.
          </TailwindListItem>
          <TailwindListItem padding={["py-2"]} margin={["ml-8"]}>
            Let them know that if they encounter something or someone dangerous
            that they can tell you about it, and that you won't blame them or
            get mad.
          </TailwindListItem>
        </TailwindList>
        <p className={"mb-2"}>
          Here are some sites where you can get parental control software:
        </p>
        <TailwindList type={TailwindListType.ol} margin={["mb-4"]}>
          <TailwindListItem padding={["py-2"]} margin={["ml-8"]}>
            CyberPatrol <br />
            <TailwindAnchor to={"https://www.cyberpatrol.com/"}>
              https://www.cyberpatrol.com/
            </TailwindAnchor>
          </TailwindListItem>
          <TailwindListItem padding={["py-2"]} margin={["ml-8"]}>
            Safety Surf <br />
            <TailwindAnchor to={"http://www.safekids.com/"}>
              http://www.safekids.com/
            </TailwindAnchor>
          </TailwindListItem>
        </TailwindList>
        <p className={"mb-2"}>
          Good Internet safety information sites for both kids and adults are:
        </p>
        <TailwindList type={TailwindListType.ol}>
          <TailwindListItem padding={["py-2"]} margin={["ml-8"]}>
            GetNetWise.org <br />
            <TailwindAnchor to={"http://www.getnetwise.org/"}>
              http://www.getnetwise.org/
            </TailwindAnchor>
          </TailwindListItem>
          <TailwindListItem padding={["py-2"]} margin={["ml-8"]}>
            RestrictedToAdults.org <br />
            <TailwindAnchor to={"https://rtalabel.org"}>
              https://rtalabel.org
            </TailwindAnchor>
          </TailwindListItem>
        </TailwindList>
      </TailwindBox>
    </div>
  );
};

export default ProtectKids;
