import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import EarningsTableSimpleHead from "./earnings-table-simple-head";
import EarningsTableSimpleBody from "./earnings-table-simple-body";

const EarningsTableSimpleContainer: React.ComponentType = () => {
  return (
    <TailwindFlex flexDirection={"flex-row"} height={"h-auto"} width={"w-full"}>
      <TailwindFlex
        width={"w-full"}
        overflow={["overflow-x-auto", "overflow-y-hidden"]}
        className={["bg-gray-100"]}
        flexDirection={"flex-col"}>
        <TailwindFlex flexDirection={"flex-col"} height={"h-full"} width={"w-full"} minWidth={"min-w-160"}>
          <EarningsTableSimpleHead />
          <EarningsTableSimpleBody />
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default EarningsTableSimpleContainer;
