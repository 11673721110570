import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindFlex from "../../components/_tailwind/flex";

type Props = {};

const StorybookComponentsFlex: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Flex</StorybookHeading>
      <StorybookParagraph>
        Flex is a wrapper around TailwindBox but it already has some of the
        props preset. It is mainly used for creating layouts & responsive
        layouts. It accepts all props from TailwindBox but some of the
        "opinionated" props are as follows:
      </StorybookParagraph>
      <Code>
        {`xs?: TWidth;
sm?: TWidth;
md?: TWidth;
lg?: TWidth;
xl?: TWidth;
xxl?: TWidth;
width?: TWidth;
inline?: boolean;
`}
      </Code>
      <StorybookParagraph>
        Examples to create layout. Examples have background color for better
        visibility:
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindFlex backgroundColor={"bg-red-500"} flexWrap={"flex-wrap"}>
  <TailwindFlex backgroundColor={"bg-yellow-500"}>
    row 1
  </TailwindFlex>
  <TailwindFlex backgroundColor={"bg-yellow-500"}>
    row 1
  </TailwindFlex>
</TailwindFlex>
`}>
        <TailwindFlex backgroundColor={"bg-red-500"} flexWrap={"flex-wrap"}>
          <TailwindFlex backgroundColor={"bg-yellow-500"}>row 1</TailwindFlex>
          <TailwindFlex backgroundColor={"bg-yellow-500"}>row 1</TailwindFlex>
        </TailwindFlex>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindFlex backgroundColor={"bg-red-500"} flexWrap={"flex-wrap"}>
  <TailwindFlex width={"w-1/2"} backgroundColor={"bg-yellow-500"}>col 1</TailwindFlex>
  <TailwindFlex width={"w-1/2"} backgroundColor={"bg-yellow-500"}>col 2</TailwindFlex>
</TailwindFlex>  
`}>
        <TailwindFlex backgroundColor={"bg-red-500"} flexWrap={"flex-wrap"}>
          <TailwindFlex width={"w-1/2"} backgroundColor={"bg-yellow-500"}>
            col 1
          </TailwindFlex>
          <TailwindFlex width={"w-1/2"} backgroundColor={"bg-yellow-500"}>
            col 2
          </TailwindFlex>
        </TailwindFlex>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindFlex backgroundColor={"bg-red-500"} flexWrap={"flex-wrap"}>
  <TailwindFlex width={"w-1/6"} backgroundColor={"bg-yellow-500"}>
    col 1
  </TailwindFlex>
  <TailwindFlex width={"w-1/6"} backgroundColor={"bg-yellow-500"}>
    col 2
  </TailwindFlex>
  <TailwindFlex width={"w-1/6"} backgroundColor={"bg-yellow-500"}>
    col 3
  </TailwindFlex>
  <TailwindFlex width={"w-1/6"} backgroundColor={"bg-yellow-500"}>
    col 4
  </TailwindFlex>
  <TailwindFlex width={"w-1/6"} backgroundColor={"bg-yellow-500"}>
    col 5
  </TailwindFlex>
  <TailwindFlex width={"w-1/6"} backgroundColor={"bg-yellow-500"}>
    col 6
  </TailwindFlex>
</TailwindFlex>
`}>
        <TailwindFlex backgroundColor={"bg-red-500"} flexWrap={"flex-wrap"}>
          <TailwindFlex width={"w-1/6"} backgroundColor={"bg-yellow-500"}>
            col 1
          </TailwindFlex>
          <TailwindFlex width={"w-1/6"} backgroundColor={"bg-yellow-500"}>
            col 2
          </TailwindFlex>
          <TailwindFlex width={"w-1/6"} backgroundColor={"bg-yellow-500"}>
            col 3
          </TailwindFlex>
          <TailwindFlex width={"w-1/6"} backgroundColor={"bg-yellow-500"}>
            col 4
          </TailwindFlex>
          <TailwindFlex width={"w-1/6"} backgroundColor={"bg-yellow-500"}>
            col 5
          </TailwindFlex>
          <TailwindFlex width={"w-1/6"} backgroundColor={"bg-yellow-500"}>
            col 6
          </TailwindFlex>
        </TailwindFlex>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindFlex
  backgroundColor={"bg-red-500"}
  flexWrap={"flex-wrap"}
  height={"h-96"}
>
  <TailwindFlex
    width={"w-1/2"}
    alignItems={"items-center"}
    justifyContent={"justify-center"}
  >
    col 1 items-center justify-center
  </TailwindFlex>
  <TailwindFlex
    width={"w-1/2"}
    alignItems={"items-center"}
    justifyContent={"justify-center"}
  >
    col 1 items-center justify-center
  </TailwindFlex>
</TailwindFlex>
`}>
        <TailwindFlex
          backgroundColor={"bg-red-500"}
          flexWrap={"flex-wrap"}
          height={"h-96"}>
          <TailwindFlex
            width={"w-1/2"}
            alignItems={"items-center"}
            justifyContent={"justify-center"}>
            col 1 items-center justify-center
          </TailwindFlex>
          <TailwindFlex
            width={"w-1/2"}
            alignItems={"items-center"}
            justifyContent={"justify-center"}>
            col 1 items-center justify-center
          </TailwindFlex>
        </TailwindFlex>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindFlex
  backgroundColor={"bg-red-500"}
  flexWrap={"flex-wrap"}
  height={"h-96"}
>
  <TailwindFlex width={"w-1/2"} flexDirection={"flex-col"}>
    <TailwindFlex height={"h-1/2"}>
      col 1 flex-col, nested row 1, h-1/2
    </TailwindFlex>
    <TailwindFlex height={"h-1/2"}>
      col 1 flex-col, nested row 2, h-1/2
    </TailwindFlex>
  </TailwindFlex>
  <TailwindFlex width={"w-1/2"} flexDirection={"flex-col"}>
    <TailwindFlex height={"h-1/2"}>
      col 2 flex-col, nested row 1, h-1/2
    </TailwindFlex>
    <TailwindFlex height={"h-1/2"}>
      col 2 flex-col, nested row 2, h-1/2
    </TailwindFlex>
  </TailwindFlex>
</TailwindFlex>
`}>
        <TailwindFlex
          backgroundColor={"bg-red-500"}
          flexWrap={"flex-wrap"}
          height={"h-96"}>
          <TailwindFlex width={"w-1/2"} flexDirection={"flex-col"}>
            <TailwindFlex height={"h-1/2"}>
              col 1 flex-col, nested row 1, h-1/2
            </TailwindFlex>
            <TailwindFlex height={"h-1/2"}>
              col 1 flex-col, nested row 2, h-1/2
            </TailwindFlex>
          </TailwindFlex>
          <TailwindFlex width={"w-1/2"} flexDirection={"flex-col"}>
            <TailwindFlex height={"h-1/2"}>
              col 2 flex-col, nested row 1, h-1/2
            </TailwindFlex>
            <TailwindFlex height={"h-1/2"}>
              col 2 flex-col, nested row 2, h-1/2
            </TailwindFlex>
          </TailwindFlex>
        </TailwindFlex>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsFlex;
