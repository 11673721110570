import React, { useCallback, useMemo } from "react";
import TailwindBox, { TailwindBoxProps } from "../box";
import TailwindText, { TailwindTextProps } from "../text";
import TailwindInputBase, { TailwindInputType } from "../input-base";
import "./styles.scss";
import {
  TBackgroundColor,
  THeight,
  TInset,
  TWidth,
} from "../../../styles/tailwind-classnames";

export type TailwindSwitchProps = {
  "data-testid"?: string;
  name?: string;
  label?: string;
  labelProps?: TailwindTextProps;
  onLabel?: string;
  onLabelProps?: TailwindTextProps;
  onBackgroundColor?: TBackgroundColor;
  offLabel?: string;
  offLabelProps?: TailwindTextProps;
  offBackgroundColor?: TBackgroundColor;
  size?: "xs" | "xs-wide" | "sm" | "normal";
  isChecked?: boolean;
  onChange?: (isChecked: boolean) => void;
  boxProps?: TailwindBoxProps;
  pillBackgroundColor?: TBackgroundColor;
  disabled?: boolean;
};

const TailwindSwitch: React.ComponentType<TailwindSwitchProps> = ({
  "data-testid": dataTestId,
  name = "TailwindSwitch",
  label,
  labelProps,
  onLabel,
  onLabelProps,
  onBackgroundColor = "bg-green-500",
  offLabel,
  offLabelProps,
  offBackgroundColor = "bg-red-500",
  size = "normal",
  isChecked = false,
  boxProps,
  pillBackgroundColor,
  disabled = false,
  onChange = () => {},
}) => {
  const onInputChange = useCallback(() => {
    if (disabled) return;
    onChange(!isChecked);
  }, [isChecked, onChange, disabled]);

  const hasLabel = useMemo(() => {
    return onLabel || offLabel;
  }, [onLabel, offLabel]);

  const sizeClasses = useMemo(() => {
    const classes = {
      input: {
        width: "w-10",
        height: "h-6",
      },
      circle: {
        width: "w-4",
        height: "h-4",
      },
      onLabel: {
        right: "right-8",
      },
      offLabel: {
        right: "right-1",
      },
    };

    switch (size) {
      case "xs": {
        classes.input.width = "w-14";
        classes.input.height = "h-5";
        classes.circle.width = "w-4.5";
        classes.circle.height = "h-3";
        break;
      }
      case "xs-wide": {
        classes.input.width = "w-20";
        classes.input.height = "h-5";
        classes.circle.width = "w-4.5";
        classes.circle.height = "h-3";
        classes.onLabel.right = "right-7";
        classes.offLabel.right = "right-1.5";
        break;
      }
      case "sm": {
        classes.input.width = hasLabel ? "w-14" : "w-10";
        classes.input.height = "h-6";
        classes.circle.width = "w-4";
        classes.circle.height = "h-4";
        break;
      }
      case "normal": {
        classes.input.width = hasLabel ? "w-16" : "w-14";
        classes.input.height = "h-8";
        classes.circle.width = "w-6";
        classes.circle.height = "h-6";
        classes.onLabel.right = "right-9";
        classes.offLabel.right = "right-2";
        break;
      }
      default: {
        break;
      }
    }

    return classes;
  }, [size, hasLabel]);

  return (
    <React.Fragment>
      {label && (
        <TailwindText
          fontSize={"text-lg"}
          fontWeight={"font-bold"}
          margin={["mr-3"]}
          {...labelProps}>
          {label}
        </TailwindText>
      )}
      <TailwindBox
        {...boxProps}
        position={"relative"}
        display={"inline-flex"}
        alignItems={"items-center"}
        cursor={"cursor-pointer"}
        userSelect={"select-none"}
        className={["TailwindSwitch", `TailwindSwitch--size-${size}`]}
        backgroundColor={
          disabled
            ? "bg-gray-300"
            : isChecked
            ? onBackgroundColor
            : offBackgroundColor
        }
        borderRadius={"rounded-full"}>
        {offLabel && (
          <TailwindText
            {...offLabelProps}
            textColor={disabled ? "text-white" : offLabelProps?.textColor}
            as={"span"}
            fontWeight={offLabelProps?.fontWeight || "font-medium"}
            fontSize={"text-xs"}
            textTransform={"uppercase"}
            position={"absolute"}
            inset={offLabelProps?.inset || [sizeClasses.offLabel.right as TInset]}
            onClick={onInputChange}
            display={isChecked ? "hidden" : undefined}>
            {offLabel}
          </TailwindText>
        )}
        {onLabel && (
          <TailwindText
            {...onLabelProps}
            textColor={disabled ? "text-white" : onLabelProps?.textColor}
            as={"span"}
            fontWeight={onLabelProps?.fontWeight || "font-medium"}
            fontSize={"text-xs"}
            textTransform={"uppercase"}
            position={"absolute"}
            inset={onLabelProps?.inset || [sizeClasses.onLabel.right as TInset]}
            onClick={onInputChange}
            display={!isChecked ? "hidden" : undefined}>
            {onLabel}
          </TailwindText>
        )}
        <TailwindInputBase
          data-testid={dataTestId}
          data-is-enabled={isChecked}
          name={name}
          type={TailwindInputType.checkbox}
          appearance={"appearance-none"}
          cursor={"cursor-pointer"}
          width={sizeClasses.input.width as TWidth}
          height={sizeClasses.input.height as THeight}
          psuedoClasses={["focus:outline-none"]}
          onChange={onInputChange}
          checked={isChecked}
          className={["TailwindSwitch__input"]}
          disabled={disabled}
        />
        <TailwindBox
          as={"span"}
          width={sizeClasses.circle.width as TWidth}
          height={sizeClasses.circle.height as THeight}
          position={"absolute"}
          borderRadius={"rounded-full"}
          backgroundColor={
            disabled ? "bg-white" : pillBackgroundColor || "bg-gray-200"
          }
          zIndex={"z-10"}
          onClick={onInputChange}
          className={[
            "TailwindSwitch__circle",
            hasLabel && "TailwindSwitch__circle--has-label",
            isChecked && "TailwindSwitch__circle--checked",
          ]}
        />
      </TailwindBox>
    </React.Fragment>
  );
};

export default TailwindSwitch;
