import {
  PAYOUT_FILTER_MAP,
  PAYOUT_FILTER_MAP_CATEGORY_KEYS,
} from "common/earnings/_stores/earnings/consts";
import EarningsStore from "common/earnings/_stores/earnings/EarningsStore";
import { Popover } from "common/popover";
import { inject, observer } from "mobx-react";
import React, { useCallback, useMemo } from "react";
import TailwindButton from "library/components/_tailwind/button";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindCheckboxRadio from "library/components/_tailwind/checkbox-radio";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import { PayoutFilterMainType } from "common/earnings/_stores/earnings/types";
import "./styles.scss";
import { useIntl } from "react-intl";
import TailwindText from "library/components/_tailwind/text";

type Props = {
  earningsStore?: EarningsStore;
};

const PayoutDropdownComponent = ({ earningsStore }: Props) => {
  const { payoutFilters, setPayoutFilters } = earningsStore!;
  const intl = useIntl();

  const onChangeMainType = useCallback(
    key => {
      setPayoutFilters({
        category: key,
        productTypes: PAYOUT_FILTER_MAP[key],
      });
    },
    [setPayoutFilters]
  );

  const productTypeKeyString = useMemo(() => {
    return payoutFilters.productTypes.slice().sort().join(",");
  }, [payoutFilters]);

  const onToggleProductType = useCallback(
    (payoutFilterKey, product) => {
      const isSelectAllInCategory =
        productTypeKeyString ===
        PAYOUT_FILTER_MAP_CATEGORY_KEYS[payoutFilters.category];
      const isSelectNewFilter = payoutFilterKey !== payoutFilters.category;

      const productTypes =
        isSelectAllInCategory || isSelectNewFilter
          ? [product]
          : payoutFilters.productTypes.includes(product)
          ? payoutFilters.productTypes.filter(type => type !== product)
          : [...payoutFilters.productTypes, product];
      setPayoutFilters({
        category: payoutFilterKey,
        productTypes,
      });
    },
    [productTypeKeyString, payoutFilters, setPayoutFilters]
  );

  const activeLabel = useMemo(() => {
    const isSelectAllInCategory =
      productTypeKeyString ===
      PAYOUT_FILTER_MAP_CATEGORY_KEYS[payoutFilters.category];
    const isSelectAll = payoutFilters.category === PayoutFilterMainType.ALL;

    const productStrings = isSelectAllInCategory ? "All" : payoutFilters.productTypes
      .map(product => product.split("_").join(" "))
      .join(",");

    return `${intl.formatMessage({
      id: `earnings.payout_dropdown.category.${payoutFilters.category}`,
      defaultMessage: payoutFilters.category.split("_").join(" "),
    })}${isSelectAll ? `` : ` - ${productStrings}`}`;
  }, [intl, payoutFilters, productTypeKeyString]);

  return (
    <div>
      <Popover
        content={
          <TailwindFlex
            width='w-64'
            flexDirection='flex-col'
            padding={["px-10", "pb-10"]}
            backgroundColor={"bg-primary-bg-color"}
            className={["PayoutDropdownContent"]}>
            {Object.keys(PAYOUT_FILTER_MAP).map(payoutFilterKey => (
              <TailwindFlex
                flexDirection='flex-col'
                key={payoutFilterKey}
                className={["PayoutDropdownHeader"]}>
                <TailwindCheckboxRadio
                  boxProps={{
                    margin: ["my-2"],
                  }}
                  onChange={() => {
                    onChangeMainType(payoutFilterKey);
                  }}
                  type={TailwindInputType.radio}
                  isChecked={
                    payoutFilters.category ===
                    (payoutFilterKey as unknown as PayoutFilterMainType)
                  }
                  labelProps={{
                    textColor: "text-main-color",
                  }}
                  label={
                    <TailwindText textTransform='capitalize'>
                      {intl.formatMessage({
                        id: `earnings.payout_dropdown.category.${payoutFilterKey}`,
                        defaultMessage: payoutFilterKey.split("_").join(" "),
                      })}
                    </TailwindText>
                  }
                />

                {PAYOUT_FILTER_MAP[payoutFilterKey].length > 0 && (
                  <TailwindCheckboxRadio
                    boxProps={{
                      margin: ["my-2"],
                    }}
                    onChange={() => {
                      onChangeMainType(payoutFilterKey);
                    }}
                    type={TailwindInputType.radio}
                    isChecked={
                      payoutFilters.category ===
                        (payoutFilterKey as unknown as PayoutFilterMainType) &&
                      PAYOUT_FILTER_MAP_CATEGORY_KEYS[payoutFilterKey] ===
                        productTypeKeyString
                    }
                    labelProps={{
                      textColor: "text-main-color",
                    }}
                    label={"All"}
                  />
                )}

                {PAYOUT_FILTER_MAP[payoutFilterKey].map(productType => (
                  <TailwindCheckboxRadio
                    boxProps={{
                      margin: ["my-2"],
                    }}
                    key={productType}
                    onChange={() => {
                      onToggleProductType(payoutFilterKey, productType);
                    }}
                    type={TailwindInputType.checkbox}
                    isChecked={
                      payoutFilters.productTypes.includes(productType) &&
                      PAYOUT_FILTER_MAP_CATEGORY_KEYS[payoutFilterKey] !==
                        productTypeKeyString
                    }
                    labelProps={{
                      textColor: "text-main-color",
                    }}
                    label={
                      <TailwindText textTransform='capitalize'>
                        {intl.formatMessage({
                          id: `earnings.payout_dropdown.product.${productType}`,
                          defaultMessage: productType.split("_").join(" "),
                        })}
                      </TailwindText>
                    }></TailwindCheckboxRadio>
                ))}
              </TailwindFlex>
            ))}
          </TailwindFlex>
        }>
        <TailwindButton
          className={["PayoutDropdownButton"]}
          rightIconProps={{
            name: "keyboard_arrow_down",
            fontSize: "text-lg",
          }}
          margin={["ml-4"]}
          borderRadius={["rounded"]}
          borderWidth={["border-2"]}
          borderColor={"border-transparent"}
          pseudoClasses={["focus:outline-none"]}
          rounded
          display={"flex"}
          height={"h-10"}
          alignItems={"items-center"}
          justifyContent={"justify-between"}
          backgroundColor={"bg-primary-bg-color"}
          padding={["px-3", "py-1"]}
          style={{
            zIndex: 1,
          }}
          textProps={{
            fontSize: "text-sm",
            fontWeight: "font-bold",
            whiteSpace: "whitespace-nowrap",
            textOverflow: "overflow-ellipsis",
            overflow: 'overflow-hidden',
          }}>
          <TailwindText textTransform='capitalize'>{activeLabel}</TailwindText>
        </TailwindButton>
      </Popover>
    </div>
  );
};

export const PayoutDropdown = inject("earningsStore")(
  observer(PayoutDropdownComponent)
);
