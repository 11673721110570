import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import TailwindAnchor from "../../components/_tailwind/anchor";
import TailwindFlex from "../../components/_tailwind/flex";
import Gitflow from "./gitflow";

type Props = {};

const StorybookFlowGit: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Flow &gt; Git</StorybookHeading>
      <StorybookParagraph>
        We are USING an established flow of git such as
        https://nvie.com/posts/a-successful-git-branching-model/ Please check{" "}
        <TailwindAnchor to={"/flow/deployments"}>deployments</TailwindAnchor>{" "}
        page on the documentation for more info about deployment
      </StorybookParagraph>
      <StorybookHeading>
        Note: A code that is unfinished or finished but missing env vars should
        never get to master
      </StorybookHeading>
      <StorybookParagraph>Git & deployment flow diagram:</StorybookParagraph>
      <TailwindFlex padding={["p-4"]}>
        <Gitflow />
      </TailwindFlex>
      <Code>
        {`create branch from develop
create MR from your work branch to develop
get review for your work branch MR that is to develop
create MR from develop to QA for testing
create MR from approved branches to master or cherry-pick approved branch "merge commits" under one big MR and create MR to master
`}
      </Code>
      <StorybookParagraph>
        Branch names should contain a task number in the following way with a
        prefix of what the task is about:
      </StorybookParagraph>
      <Code>
        {`fix/cscban-123-some-task
feature/cscban-123-some-task
refactor/cscban-123-some-task    
`}
      </Code>
      <StorybookParagraph>
        The commit messages should contain the task number in the following way
        with a message on what has changed:
      </StorybookParagraph>
      <Code>
        {`fix(cscban-123): fixed issue with sidebar link not changing color for aff
feature(cscban-123): added theming functionality to sidebar
refactor(cscban-123): refactored sidebar code so different projects can have different navigation items in a DRY way
`}
      </Code>
      <StorybookParagraph fontWeight={"font-bold"} textColor={"text-red-500"}>
        If there is no task, please get it created or create one yourself. This
        is required!
      </StorybookParagraph>
      <StorybookParagraph>
        When you have an MR ready to be reviewed, send the MR link to{" "}
        <strong>cams-fe_reviews</strong> channel (ask lead for invite to
        channel)
      </StorybookParagraph>
      <StorybookParagraph>
        When you have an MR reviewed and merged to develop & deployed to QA,
        send the following message to <strong>cams qa_request</strong> in the
        following format:
      </StorybookParagraph>
      <Code>
        {`TASK: LINK-TO-TASK
DEVELOP (merged): MR LINK TO DEVELOP
QA (if you have an MR to QA): MR LINK TO QA
`}
      </Code>
    </StorybookWrapper>
  );
};

export default StorybookFlowGit;
