import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import TailwindFlex from "../../components/_tailwind/flex";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookSubHeading from "../_internal/sub-heading";

type Props = {};

const StorybookArchitectureUtilities: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Architecture &gt; Utilities</StorybookHeading>
      <StorybookParagraph>
        The utilities are as follows, they are kind of self explanatory, you can
        skim over them when needed:
      </StorybookParagraph>
      <Code>
        {`utilities/api
utilities/audio        
utilities/cache        
utilities/cookie-storage        
utilities/copy-to-clipboard        
utilities/date-process        
utilities/earning-helpers //will be moved to earnings container        
utilities/formatter        
utilities/hooks //react hooks folder        
utilities/image        
utilities/is-platform //existed when we had NextJS. isBrowser and isServer is part of isPlatform, like Angular
utilities/local-storage        
utilities/logger //needs to be used instead of console.log as not to leak logs to production        
utilities/nano        
utilities/session-storage        
utilities/token-helper //has token to currency and currency to token calculator functions
utilities/uuid //not used, please use shortid package instead, import shortid from "shortid"; shortid.generate()
utilities/wowza
utilities/misc.ts //has some functions that we did not have the class to put them in. mostly file & formData related functions        
`}
      </Code>
      <StorybookSubHeading>ApiStore (Axios Facade)</StorybookSubHeading>
      <TailwindFlex id={"api"} flexDirection={"flex-col"}>
        <StorybookParagraph>
          ApiStore manages the abstraction of axios as well as the endpoint URLs
          it makes calls to. It allows single endpoint like{" "}
          <Code inline>{`api.getMeEndpoint(userRole).post({somedata})`}</Code>{" "}
          to work out of the box.
        </StorybookParagraph>
        <StorybookParagraph>
          Here is a list of common endpoints used for all userRoles:
        </StorybookParagraph>
        <Code>
          {`getBaseEndpoint(userRole) //returns /studios/ or /models/ or /dating/ in the future
getMeEndpoint(userRole) // /studios/me/ or /models/me and so forth
getLoginEndpoint(userRole) // /models/jwt/token/ and so forth
getUpdatePasswordEndpoint(userRole) // /studios/update-password/ and so forth
getSetPasswordEndpoint(userRole) // /accounts/set-password/
getForgotPasswordEndpoint(userRole) // /studios/forgot-password/ and so forth
getForgotUsernameEndpoint(userRole) // /studios/forgot-username/ and so forth
getLogoutEndpoint(userRole) // /studios/jwt/logout/ and so forth
getTokenRefreshEndpoint(userRole) // /studios/jwt/refresh/ and so forth
getSignDocumentUploadEndpoint(userRole) // /studios/me/sign-document-upload/ and model equivalent, no documents for AFF members for now, unless they broadcast
`}
        </Code>
        <StorybookParagraph>
          ApiStore creates URLs inside itself therefore you can do{" "}
          <Code inline>api.models.get, api.models.put</Code> kind of function
          calls easily without repeating the URL each time as well as giving you
          the ability to plug in different axios instances easily for things
          like login/logout that does not have the Bearer token attached via
          rawAxios instance. It can also attach same Bearer token to multiple
          axios instances like BroadcastAxios therefore user is logged in on
          many levels of API. Example calls:
        </StorybookParagraph>
        <Code>
          {`const { data } = await api.modelsBase.get({
  pathParams: [screenName, "blockable-members"], 
}); //makes GET request to /models/{screenName}/blockable-members URL
          
const url =
  this.nextMessagesUrl !== "" && loadingMore
    ? this.nextMessagesUrl
    : \`conversations/{conversationId}/messages/?limit={20}\`;
const { data } = await api.messages.get(url);

await api.albums.post(album);

await api.albums.patch(updates, \`{
            media.id
          }/\`); //makes PATCH request to /models/me/albums/{albumId}/ with the updates

await api.albums.patch(
  updates,
  \`{(media as ModelVideo).album}/videos/{
            media.id
          }/\` //makes PATCH request to /models/me/albums/{albumId}/videos/{videoId} with the updates 
);
`}
        </Code>
      </TailwindFlex>
    </StorybookWrapper>
  );
};

export default StorybookArchitectureUtilities;
