import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Recorder from "core/utility/recorder";
import TailwindFlex from "library/components/_tailwind/flex";
import { MessagesAudioRecording } from "./stores/messages/types";
import { inject, observer } from "mobx-react";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import StudioModelAccessRights from "common/studio-models-access-rights";
import { formatter } from "library/core/utility";
import TailwindIcon from "library/components/_tailwind/icon";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import SendMessageButton from "./messages-send-message-button";
import MessagesAudioPlayer from "./messages-audio-player";
import {
  MSG_CENTER_MAX_AUDIO_DURATION_MINUTES,
  MSG_CENTER_MIN_AUDIO_DURATION_SECONDS,
} from "./stores/messages/consts";
import { Popover } from "common/popover";
import ModalCloseButton from "library/components/modal/modal-close-button";
import AudioMessageRecorderStore from "./stores/audio/AudioMessageRecorderStore";
import SnackbarStore from "library/core/stores/snackbar/SnackbarStore";
import { ISnackbarProps } from "library/core/stores/snackbar/interfaces";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";
import { Icon } from "@material-ui/core";

type MessagesAudioRecorderProps = {
  onRecordingSaved?: (audio: MessagesAudioRecording) => void;
  onSubmit: (audio?: MessagesAudioRecording) => void;
  onPricingClick?: () => void;
  onRemove?: () => void;
  price?: number;
  profileStore?: ProfileStore;
  audioMessageRecorderStore?: AudioMessageRecorderStore;
  snackbarStore?: SnackbarStore;
};

const MessagesAudioRecorder: React.ComponentType<MessagesAudioRecorderProps> =
  ({
    onRecordingSaved,
    onSubmit,
    onRemove,
    onPricingClick,
    price,
    profileStore,
    audioMessageRecorderStore,
    snackbarStore,
  }) => {
    const {
      isRecording,
      audioSrc,
      audioType,
      audioFile,
      audioDuration,
      recordingTime,
      saveAudio,
      setAudioDuration,
      start,
      stop,
      resetStore,
      queueAutoSubmit,
    } = audioMessageRecorderStore!;
    const { enqueueSnackbar } = snackbarStore!;
    const [recorder, setRecorder] = useState<Recorder | null>(null);
    const [showError, setShowError] = useState<boolean>(false);
    const audioWaveFormRef = useRef<HTMLDivElement>(null);

    const {
      isStudioModel,
      modelProfile: { access_rights },
    } = profileStore!;

    const shouldDisable = useMemo(() => {
      return (
        recordingTime < 10 ||
        recordingTime > MSG_CENTER_MAX_AUDIO_DURATION_MINUTES * 60
      );
    }, [recordingTime, MSG_CENTER_MAX_AUDIO_DURATION_MINUTES]);

    const handleMediaSaved = (audioURL: string, type: string, file: Blob) => {
      saveAudio(audioURL, type, file);
      const audio = document.createElement("audio");
      audio.onloadedmetadata = () => {
        if (audio.duration === Infinity || isNaN(Number(audio.duration))) {
          audio.currentTime = 1e101;
        } else {
          setAudioDuration(audio.duration);
        }
      };
      audio.ontimeupdate = () => {
        if (audio.duration !== Infinity && !isNaN(Number(audio.duration))) {
          setAudioDuration(audio.duration);
        }
      };
      audio.setAttribute("src", audioURL);
    };

    const handleMicrophonePermissionError = () => {
      enqueueSnackbar({
        message: {
          id: "message-center.error.mic-permission",
          defaultMessage:
            "Microphone permission is disabled for this browser. Please give permission to start recording.",
        },
        variant: SnackbarVariants.WARNING,
        options: {
          icon: <Icon fontSize='large'>warning</Icon>,
        },
      } as unknown as ISnackbarProps);
      stop();
      onRemove?.();
    };

    const handleRecordingStatus = (isRecording: boolean) => {
      if (isRecording) {
        start();
      } else {
        stop();
      }
    };

    const startRecording = () => {
      if (recorder) {
        recorder.start();
      }
    };

    const stopRecording = () => {
      if (recorder) {
        recorder.stop();
      }
    };

    useEffect(() => {
      if (!isRecording) {
        stopRecording();
      }
    }, [isRecording]);

    useEffect(() => {
      if (audioSrc && audioDuration && audioFile) {
        const data = {
          src: audioSrc,
          duration: audioDuration,
          type: audioType,
          price: price || 0,
          file: audioFile,
        };
        onRecordingSaved?.(data);
      }
    }, [audioSrc, audioDuration, audioType, audioFile]);

    useEffect(() => {
      if (recorder) {
        startRecording();
      }
    }, [recorder]);

    useEffect(() => {
      setRecorder(
        new Recorder({
          onMediaReady: handleMediaSaved,
          onPermissionError: handleMicrophonePermissionError,
          onRecording: handleRecordingStatus,
        })
      );

      return () => {
        stop();
        resetStore();
      };
    }, []);

    const generateBars = count => {
      const bars: Array<JSX.Element> = [];
      const barType = [
        "none",
        "none",
        "quiet",
        "normal",
        "quiet",
        "none",
        "none",
        "quiet",
        "none",
        "quiet",
        "none",
        "none",
        "none",
        "quiet",
        "normal",
        "quiet",
        "none",
        "none",
        "quiet",
        "none",
        "quiet",
        "none",
        "quiet",
        "normal",
        "loud",
        "normal",
        "quiet",
        "none",
        "quiet",
        "none",
        "none",
        "none",
        "quiet",
        "none",
        "quiet",
        "none",
      ];

      for (let i = 0; i < count; i++) {
        bars.push(
          <TailwindFlex
            className={[
              "MessagesAudioRecorder__waveform--bar",
              `MessagesAudioRecorder__waveform--bar-${
                barType[i % barType.length]
              }`,
            ]}></TailwindFlex>
        );
      }

      return bars;
    };

    const waveForm = useCallback(() => {
      const rect = audioWaveFormRef?.current?.getBoundingClientRect();

      const barWidth = 1;
      const gutter = 2;
      const barCount = rect
        ? Math.round((rect?.width - 10) / (barWidth + gutter))
        : 0;

      return (
        <TailwindFlex
          className={["MessagesAudioRecorder__waveform--container"]}
          flexDirection='flex-col'
          alignItems='items-center'
          width='w-full'
          justifyContent='justify-center'>
          <TailwindFlex
            className={
              showError
                ? [
                    "MessagesAudioRecorder__waveform--wrapper",
                    "MessagesAudioRecorder__waveform--static",
                  ]
                : ["MessagesAudioRecorder__waveform--wrapper"]
            }
            justifyContent='justify-between'
            width='w-auto'>
            {generateBars(barCount).map((bar, index) => (
              <TailwindFlex
                key={index}
                style={{
                  width: `${barWidth}px`,
                  height: "100%",
                  margin: `${gutter / 2}px`,
                }}>
                {bar}
              </TailwindFlex>
            ))}
          </TailwindFlex>
        </TailwindFlex>
      );
    }, [isRecording, showError]);

    const onHideError = () => {
      resetStore();
      setShowError(false);
      startRecording();
    };

    const onShowError = () => {
      setShowError(true);
    };

    return (
      <>
        <Popover
          placement='top-start'
          onHide={onHideError}
          onShow={onShowError}
          show={showError}
          content={
            <TailwindFlex
              className={["MessagesAudioRecorder__error"]}
              borderRadius={"rounded-lg"}
              backgroundColor='bg-primary-bg-color'
              boxShadow='shadow'>
              <ModalCloseButton
                className='MessagesAudioRecorder__error--close'
                closeModal={onHideError}
              />
              <TailwindTranslatedText
                descriptor={{
                  id: "message-center.error.audio_length",
                  defaultMessage:
                    "Voice message must be between {minimum_seconds} seconds to {maximum_minutes} minutes in length",
                }}
                values={{
                  minimum_seconds: MSG_CENTER_MIN_AUDIO_DURATION_SECONDS,
                  maximum_minutes: MSG_CENTER_MAX_AUDIO_DURATION_MINUTES,
                }}
              />
            </TailwindFlex>
          }
        />
        <TailwindFlex className={["MessagesAudioRecorder"]}>
          <TailwindFlex
            className={
              isRecording || showError
                ? ["MessagesAudioRecorder__container"]
                : [
                    "MessagesAudioRecorder__container",
                    "MessagesAudioRecorder__container--preview",
                  ]
            }
            justifyContent='justify-center'
            alignItems='items-center'
            flexDirection='flex-col'
            backgroundColor='bg-primary-bg-color'
            borderRadius='rounded-lg'>
            <TailwindFlex
              height='h-full'
              gap='gap-4'
              justifyContent='justify-center'
              alignItems='items-center'
              overflow={"overflow-hidden"}
              padding={["px-3"]}>
              {isRecording || showError ? (
                <TailwindFlex
                  onClick={() => {
                    stop();

                    if (shouldDisable) {
                      setShowError(true);
                    }
                  }}
                  width='w-auto'
                  justifyContent='justify-center'
                  alignItems='items-center'>
                  <TailwindIcon
                    name={CustomIconName.stopRecording}
                    textColor={shouldDisable ? "text-gray-300" : "text-red-500"}
                    fontSize='text-4xl'
                  />
                </TailwindFlex>
              ) : null}

              {isRecording || showError ? (
                <TailwindFlex ref={audioWaveFormRef}>{waveForm()}</TailwindFlex>
              ) : audioSrc && !showError ? (
                <TailwindFlex>
                  <MessagesAudioPlayer src={audioSrc} showDuration={false} />
                </TailwindFlex>
              ) : (
                <TailwindFlex />
              )}

              <TailwindFlex
                onClick={
                  isRecording
                    ? () => {
                        stop();
                        onRemove?.();
                      }
                    : onRemove
                }
                width='w-auto'
                justifyContent='justify-center'
                alignItems='items-center'>
                <TailwindIcon
                  name={CustomIconName.trash}
                  textColor='text-secondary-color'
                  fontSize='text-lg'
                />
              </TailwindFlex>

              <TailwindFlex width='w-auto'>
                {formatter.formatSeconds(recordingTime, "MM:SS")}
              </TailwindFlex>

              <TailwindFlex width='w-auto' padding={["py-2"]}>
                <SendMessageButton
                  disabled={shouldDisable}
                  onSubmit={() => {
                    if (isRecording) {
                      queueAutoSubmit();
                      stop();
                    } else {
                      onSubmit?.();
                    }
                  }}
                />
              </TailwindFlex>
            </TailwindFlex>

            <TailwindFlex
              justifyContent='justify-center'
              alignItems='items-center'
              padding={["p-3"]}
              className={["MessagesAudioRecorder__pricing"]}
              textColor={"text-white"}
              backgroundImage={"bg-gradient-to-b"}
              gradientColorStops={[
                "from-transparent",
                "from-gray-300",
                "to-gray-400",
              ]}>
              <TailwindFlex
                justifyContent={"justify-start"}
                alignItems={"items-center"}>
                <TailwindIcon
                  display={"inline-flex"}
                  alignItems={"items-center"}
                  name={"lock"}
                  fontSize={"text-sm"}
                />

                <TailwindTranslatedText
                  margin={["ml-1.5"]}
                  fontSize={"text-sm"}
                  descriptor={{
                    id: "message-center.attachment-price-label",
                    defaultMessage: "{token_amount} tokens",
                  }}
                  values={{
                    token_amount: price,
                  }}
                />

                <StudioModelAccessRights
                  isIndividualModel={!isStudioModel}
                  shouldShow={
                    !!access_rights?.is_pricing_settings_change_allowed &&
                    !isRecording &&
                    !showError
                  }
                  hideContent={true}
                  type={"pricing"}>
                  <TailwindIcon
                    margin={["ml-1.5"]}
                    onClick={onPricingClick}
                    cursor='cursor-pointer'
                    display={"inline-flex"}
                    alignItems={"items-center"}
                    name={"create"}
                    fontSize={"text-base"}
                  />
                </StudioModelAccessRights>
              </TailwindFlex>
            </TailwindFlex>
          </TailwindFlex>
        </TailwindFlex>
      </>
    );
  };

export default inject(
  "profileStore",
  "audioMessageRecorderStore",
  "snackbarStore"
)(observer(MessagesAudioRecorder));
