import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindPill from "../../components/_tailwind/pill";
import { CustomIconName } from "../../components/_tailwind/icon/icons/enums";

type Props = {};

const StorybookComponentsPill: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Pill</StorybookHeading>
      <StorybookParagraph>
        Pill is also wrapped by TailwindBox however it is opinionated to get
        some kind of consistent look. Currently it takes all props from
        TailwindBox as well as the following props:
      </StorybookParagraph>
      <Code>
        {`leftIconProps?: TailwindIconProps;
rightIconProps?: TailwindIconProps;
textProps?: TailwindTextProps;
backgroundColor?: TBackgroundColor;
padding?: TPadding[];
size?: "xs" | "sm" | "normal";
`}
      </Code>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindPill>Text</TailwindPill>`}
      >
        <TailwindPill>Text</TailwindPill>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindPill size={"sm"}>Text</TailwindPill>`}
      >
        <TailwindPill size={"sm"}>Text</TailwindPill>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindPill size={"normal"}>Text</TailwindPill>`}
      >
        <TailwindPill size={"normal"}>Text</TailwindPill>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindPill backgroundColor={"bg-red-500"}>Text</TailwindPill>`}
      >
        <TailwindPill backgroundColor={"bg-red-500"}>Text</TailwindPill>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindPill leftIconProps={{ name: CustomIconName.eye }}>
  Text
</TailwindPill>
`}
      >
        <TailwindPill leftIconProps={{ name: CustomIconName.eye }}>
          Text
        </TailwindPill>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsPill;
