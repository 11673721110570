import React, { useCallback, useState } from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindGrid from "../../components/_tailwind/grid";
import DragAndDropProvider from "../../components/_tailwind/drag-and-drop-provider";
import DraggableItem from "../../components/_tailwind/draggable-item";
import TailwindFlex from "../../components/_tailwind/flex";
import arrayMove from "array-move";
import Code from "../../components/_tailwind/code";
import { TBackgroundColor } from "../../styles/tailwind-classnames";

type Props = {};

const StorybookComponentsDragAndDrop: React.ComponentType<Props> = ({}) => {
  const [items, setItems] = useState<number[]>([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
  ]);

  const backgroundColorClasses: TBackgroundColor[] = [
    "bg-red-100",
    "bg-red-900",
    "bg-yellow-100",
    "bg-yellow-900",
    "bg-purple-100",
    "bg-purple-900",
    "bg-blue-100",
    "bg-blue-900",
    "bg-green-100",
    "bg-green-900",
    "bg-indigo-100",
    "bg-indigo-900",
  ];

  const reorder = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const reorderedItems = arrayMove(items, dragIndex, hoverIndex);

      setItems(reorderedItems);
    },
    [items]
  );

  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Drag and Drop</StorybookHeading>
      <StorybookParagraph>
        Drag and Drop components are a set of components that are currently only
        used on Media Manager to sort albums and medias of an album in a grid
        layout so that a member would see them in the same order. The components
        are created using <Code inline>react-dnd</Code>
      </StorybookParagraph>
      <StorybookParagraph>
        In the future, if more use cases come up, we'll extend the existing
        components. Here are some examples as showcases:
      </StorybookParagraph>
      <StorybookComponentShowcase
        backgroundColor={"bg-white"}
        sourceCode={`const [items, setItems] = useState<number[]>([
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
]);

const reorder = useCallback(
  (dragIndex: number, hoverIndex: number) => {
    const reorderedItems = arrayMove(items, dragIndex, hoverIndex);

    setItems(reorderedItems);
  },
  [items]
);

<DragAndDropProvider>
  <TailwindGrid
    cols={"grid-cols-3"}
    gap={"gap-2"}
    backgroundColor={"bg-gray-200"}
  >
    {items.map((item, index) => (
      <DraggableItem
        key={item}
        id={item.toString()}
        index={index}
        reorder={reorder}
        itemType={"some-type"}
      >
        <TailwindFlex
          width={"w-auto"}
          key={item}
          height={"h-96"}
          textColor={"text-main-color"}
          alignItems={"items-center"}
          justifyContent={"justify-center"}
          fontSize={"text-3xl"}
          fontWeight={"font-bold"}
        >
          {item}
        </TailwindFlex>
      </DraggableItem>
    ))}
  </TailwindGrid>
</DragAndDropProvider>      
      
`}>
        <DragAndDropProvider>
          <TailwindGrid
            cols={"grid-cols-3"}
            gap={"gap-2"}
            backgroundColor={"bg-gray-200"}>
            {items.map((item, index) => (
              <DraggableItem
                key={item}
                id={item.toString()}
                index={index}
                reorder={reorder}
                itemType={"some-type"}>
                <TailwindFlex
                  width={"w-auto"}
                  key={item}
                  height={"h-96"}
                  textColor={"text-main-color"}
                  alignItems={"items-center"}
                  justifyContent={"justify-center"}
                  fontSize={"text-3xl"}
                  fontWeight={"font-bold"}
                  backgroundColor={backgroundColorClasses[item - 1]}>
                  {item}
                </TailwindFlex>
              </DraggableItem>
            ))}
          </TailwindGrid>
        </DragAndDropProvider>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsDragAndDrop;
