export enum RouteSidebarLocations {
  "top" = "top",
  "bottom" = "bottom",
  "subMenu" = "subMenu",
}

export enum AppCommonRouteKey {
  "broadcast" = "broadcast",
  "broadcastMemberVerification" = "broadcastMemberVerification",
  "logout" = "logout",
  "login" = "login",
  "register" = "register",
  "landing" = "landing",
  "notifications" = "notifications",
  "notices" = "notices",
  "messages" = "messages",
  "compose" = "compose",
  "searchMessages" = "searchMessages",
  "contacts" = "contacts",
  "learnMore" = "learnMore",
  "accountClosed" = "accountClosed",
  "resetPassword" = "resetPassword",
  "resetUsername" = "resetUsername",
  "completeRegistration" = "completeRegistration",
  "contact" = "contact",
  "legalDocuments" = "legalDocuments",
  "updatePassword" = "updatePassword",
  "payout" = "payout",
  "studiosAcceptableUsePolicy" = "studiosAcceptableUsePolicy",
  "acceptableUsePolicy" = "acceptableUsePolicy",
  "recordKeepingRequirementsCompliance" = "recordKeepingRequirementsCompliance",
  "privacyPolicy" = "privacyPolicy",
  "protectKids" = "protectKids",
  "paymentRenewalTermsOfUse" = "paymentRenewalTermsOfUse",
  "personsAppearingOnStreamray" = "personsAppearingOnStreamray",
  "dmca" = "dmca",
  "cookies" = "cookies",
  "californiaAct" = "californiaAct",
  "percentagePayout" = "percentagePayout",
  "bountyLegalInfo" = "bountyLegalInfo",
  "performersAcknowledgementAndAgreement" = "performersAcknowledgementAndAgreement",
  "broadcastRelease" = "broadcastRelease",
  "broadcastAgreement" = "broadcastAgreement",
  "obsSupport" = "obsSupport",
  "earnings" = "earnings",
}

export enum AppVariantRouteKey {
  "tutorial" = "tutorial",
  "settings" = "settings",
  "myVideos" = "myVideos",
  "pineappleSupport" = "pineappleSupport",
}

export enum AppCamsModelRouteKey {
  "leaderboards" = "leaderboards",
  "albums" = "albums",
  "contests" = "contests",
  "referrals" = "referrals",
  "giftsAndAwards" = "giftsAndAwards",
  "myFans" = "myFans",
  "blocks" = "blocks",
  "myPage" = "myPage",
  "myPhotosRoot" = "myPhotosRoot",
  "myPhotosList" = "myPhotosList",
  "myPhotosCreate" = "myPhotosCreate",
  "myPhotosEdit" = "myPhotosEdit",
  "recordedVideos" = "recordedVideos",
  "uploadedVideos" = "uploadedVideos",
}

export enum AppCamsStudioRouteKey {
  "dashboard" = "dashboard",
  "models" = "studioModels",
}

export enum RouteSidebarActionKey {
  "buyMore" = "buyMore",
  "autoRenew" = "autoRenew",
}
