import React from "react";
import "./styles.scss";
import classnames from "classnames";

type ArrowsPointingCenterIconProps = {
  className?: string;
};

const ArrowsPointingCenterIcon: React.ComponentType<ArrowsPointingCenterIconProps> = ({
  className,
}) => (
  <span
    className={classnames("ArrowsPointingCenterIcon", className && className)}
  >
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <g>
        <path
          id="arrow-left"
          vectorEffect="non-scaling-stroke"
          d="M21.22,14.53
		c0.01-0.23-0.07-0.45-0.23-0.63c-0.15-0.17-0.36-0.27-0.59-0.26h-4.88l2.03-2c0.35-0.35,0.35-0.91,0.01-1.25l-0.01-0.01l-0.52-0.51
		c-0.34-0.35-0.9-0.35-1.25-0.01l-0.01,0.01l-4.53,4.47C11.09,14.51,11,14.74,11,14.98c-0.01,0.23,0.09,0.46,0.26,0.63l4.52,4.53
		c0.17,0.17,0.39,0.26,0.63,0.26c0.23,0.01,0.46-0.09,0.63-0.26l0.52-0.53c0.35-0.34,0.35-0.89,0.01-1.23
		c-0.01-0.01-0.01-0.01-0.01-0.01l-2.03-2.05h4.89c0.23,0.01,0.44-0.09,0.59-0.26c0.15-0.17,0.23-0.4,0.23-0.63L21.22,14.53
		L21.22,14.53L21.22,14.53z"
        />
      </g>
      <g>
        <path
          id="arrow-left_1_"
          vectorEffect="non-scaling-stroke"
          d="
		M2.77,8.53L2.77,8.53v0.89C2.77,9.65,2.85,9.88,3,10.05c0.15,0.17,0.36,0.27,0.59,0.26h4.89l-2.03,2.05
		c-0.01,0.01-0.01,0.01-0.01,0.01c-0.33,0.34-0.33,0.89,0.01,1.23l0.52,0.53c0.17,0.17,0.39,0.27,0.63,0.26
		c0.24,0,0.47-0.09,0.63-0.26l4.52-4.53c0.17-0.17,0.27-0.39,0.26-0.63c0-0.24-0.09-0.47-0.26-0.63L8.21,3.87L8.21,3.86
		C7.86,3.52,7.3,3.52,6.96,3.87L6.44,4.38L6.43,4.39C6.09,4.74,6.09,5.3,6.44,5.64l2.03,2H3.59C3.37,7.64,3.15,7.73,3.01,7.9
		C2.85,8.08,2.77,8.3,2.77,8.53L2.77,8.53z"
        />
      </g>
    </svg>
  </span>
);

export default ArrowsPointingCenterIcon;
