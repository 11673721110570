import React, { useMemo } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { MessageCenterConversationDetails } from "./stores/messages/types";
import TailwindFlex from "library/components/_tailwind/flex";
import MessageCenterSearchBox from "./messages-navigator-contact-conversation-search-box";
import MessageStore from "./stores/messages/MessageStore";
import { inject, observer } from "mobx-react";
import TailwindButton from "library/components/_tailwind/button";
import TailwindTooltip from "library/components/_tailwind/tooltip";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import TailwindText from "library/components/_tailwind/text";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import MessageCenterMenuButton from "./message-navigator-menu-button";

type Props = {
  conversation: MessageCenterConversationDetails | null;
  goBack: () => void;
  showBackButton: boolean;
  selectedConversations: string[];
  toggleIsComposing: () => void;
  // deleteConversations: () => void;
  selectAllOrNoneConversations: () => void;
  hasSelectedAllConversations: boolean;
  showSearchResultHeader?: boolean;
  searchResultMatchesUsername?: string;
  messageStore?: MessageStore;
  layoutStore?: LayoutStore;
};

const MessageCenterNavigatorHeader: React.ComponentType<
  Props & WrappedComponentProps
> = ({
  // conversation,
  goBack,
  showBackButton,
  // selectedConversations,
  toggleIsComposing,
  // deleteConversations,
  showSearchResultHeader,
  searchResultMatchesUsername,
  messageStore,
  layoutStore,
  intl,
}) => {
  const { isMobileDevice } = layoutStore!;
  const {
    isComposing,
    conversationsSearchTerm,
    setConversationsSearchTerm,
    isConversationsLoadingMore,
    isContactsLoadingMore,
    isSearchingView,
    activeConversation,
    isBulkMessageView,
    setIsBulkMessageView,
  } = messageStore!;

  const isSearchBoxDisabled = useMemo(
    () => isConversationsLoadingMore || isContactsLoadingMore,
    [isConversationsLoadingMore, isContactsLoadingMore]
  );

  if ((isSearchingView || activeConversation || !isComposing) && isMobileDevice)
    return null;

  return (
    <TailwindFlex
      borderRadius={!isMobileDevice ? "rounded-t-lg" : "rounded-none"}
      backgroundColor={
        isMobileDevice || isComposing || showSearchResultHeader
          ? "bg-lightBlue"
          : "bg-quaternary-bg-color"
      }
      alignItems={"items-center"}
      justifyContent={"justify-start"}
      padding={isMobileDevice || isComposing ? ["py-4", "px-2"] : ["p-3.5"]}
      height={
        !isMobileDevice
          ? "h-auto"
          : isMobileDevice && isComposing
          ? "h-14"
          : "h-0"
      }
      margin={["m-0"]}>
      <TailwindFlex
        width={"w-auto"}
        justifyContent={"justify-start"}
        alignItems={"items-center"}>
        {showBackButton && (
          <TailwindButton
            width='w-8'
            height='h-8'
            maxWidth='max-w-min'
            padding={["p-0"]}
            onClick={() => {
              if (isBulkMessageView) {
                setIsBulkMessageView(false);
              } else {
                goBack();
              }
            }}
            alignSelf='self-center'
            rightIconProps={{
              name: "keyboard_arrow_left",
              fontSize: "text-4xl",
              textColor: "text-gray-400",
            }}
          />
        )}
      </TailwindFlex>
      <TailwindFlex
        width={"w-auto"}
        padding={isMobileDevice ? ["p-0"] : ["pr-0"]}
        flexGrow={"flex-grow"}
        alignItems={"items-center"}
        maxWidth='max-w-full'>
        {!isComposing ? (
          <>
            {!isMobileDevice && !showSearchResultHeader ? (
              <MessageCenterSearchBox
                placeholder={intl.formatMessage({
                  id: "verbiage.messages",
                  defaultMessage: "Messages",
                })}
                setSearchTerm={setConversationsSearchTerm}
                searchTerm={conversationsSearchTerm}
                disabled={isSearchBoxDisabled}
                isMobile={isMobileDevice}
                style={{
                  width: "calc(100% - 40px)",
                }}
              />
            ) : !isMobileDevice && showSearchResultHeader ? (
              <TailwindFlex alignItems={"items-center"}>
                <TailwindText fontWeight='font-black'>
                  {searchResultMatchesUsername}
                </TailwindText>
              </TailwindFlex>
            ) : null}

            {!isMobileDevice && !showSearchResultHeader && (
              <>
                <TailwindTooltip
                  content={intl.formatMessage({
                    id: "message-center.start-conversation",
                    defaultMessage: "Start Conversation",
                  })}>
                  <TailwindButton
                    onClick={toggleIsComposing}
                    fullWidth={false}
                    padding={["p-3"]}
                    margin={["ml-3"]}
                    justifySelf={"justify-self-end"}
                    backgroundColor={"bg-tertiary-bg-color"}
                    height={"h-10"}
                    width={"w-10"}
                    borderRadius={"rounded-full"}
                    rightIconProps={{
                      name: "create",
                      fontSize: "text-xl",
                      textColor: "text-secondary-color",
                      borderRadius: "rounded-full",
                      style: {
                        position: "relative",
                        right: "4px",
                      },
                    }}
                  />
                </TailwindTooltip>
                <MessageCenterMenuButton />
              </>
            )}
          </>
        ) : isBulkMessageView ? (
          <TailwindTranslatedText
            justifyContent='justify-start'
            fontSize='text-base'
            fontWeight='font-bold'
            descriptor={{
              id: "message-center.select-recipients",
              defaultMessage: "Select Recipients",
            }}
          />
        ) : (
          <TailwindText
            justifyContent='justify-start'
            fontSize='text-base'
            fontWeight='font-bold'>
            {"New Message"}
          </TailwindText>
        )}
      </TailwindFlex>
    </TailwindFlex>
  );
};
export default injectIntl(
  inject("messageStore", "layoutStore")(observer(MessageCenterNavigatorHeader))
);
