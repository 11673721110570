import React, { useMemo } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import "./styles.scss";
import MediaManagerMediaCard from "./media-manager-media-card";
import MediaManagerMediaCardHeader from "./media-manager-media-card-header";
import MediaManagerMediaCardBody, {
  MediaManagerMediaCardBodyProps,
} from "./media-manager-media-card-body";
import MediaManagerMediaCardHeaderTitle, {
  MediaManagerMediaCardHeaderTitleProps,
} from "./media-manager-media-card-header-title";
import { MediaManagerMediaCardHeaderActionsProps } from "./media-manager-media-card-header-actions";
import MediaManagerMediaCardFooterActions from "./media-manager-media-card-footer-actions";
import MediaManagerMediaCardFooter from "./media-manager-media-card-footer";
import MediaManagerMediaCardFooterStatus, {
  MediaManagerMediaCardFooterStatusProps,
} from "./media-manager-media-card-footer-status";
import MediaManagerMediaCardFooterPrice, {
  MediaManagerMediaCardFooterPriceProps,
} from "./media-manager-media-card-footer-price";
import { MediaManagerMediaCardFileUploadProps } from "./media-manager-media-card-file-upload";
import MediaManagerMediaCardMediaCount, {
  MediaManagerMediaCardMediaCountProps,
} from "./media-manager-media-card-media-count";
import TailwindFlex from "library/components/_tailwind/flex";
import MediaManagerMediaCardFooterUploadProgress, {
  MediaManagerMediaCardFooterUploadProgressProps,
} from "./media-manager-media-card-footer-upload-progress";

import MediaManagerMediaCardPurchases, {
  MediaManagerMediaCardPurchasesProps,
} from "./media-manager-media-card-purchases";

export type MediaManagerAlbumProps = {
  bodyProps: MediaManagerMediaCardBodyProps;
  fileUploadProps?: MediaManagerMediaCardFileUploadProps;
  actionsProps: Omit<MediaManagerMediaCardHeaderActionsProps, "width">;
  statusProps: MediaManagerMediaCardFooterStatusProps;
  titleProps: Omit<MediaManagerMediaCardHeaderTitleProps, "width">;
  priceProps: MediaManagerMediaCardFooterPriceProps;
  mediaCountProps: MediaManagerMediaCardMediaCountProps;
  mediaUploadProgressProps: MediaManagerMediaCardFooterUploadProgressProps;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  purchasesProps?: MediaManagerMediaCardPurchasesProps;
};

const MediaManagerAlbum: React.ComponentType<
  MediaManagerAlbumProps & WrappedComponentProps
> = ({
  actionsProps,
  bodyProps,
  statusProps,
  fileUploadProps,
  titleProps,
  priceProps,
  mediaCountProps,
  mediaUploadProgressProps,
  purchasesProps,
  onClick,
}) => {
  const { uploadProgress } = mediaUploadProgressProps;
  const showFooter = useMemo(() => {
    if (
      statusProps.status !== "active" ||
      (priceProps.priceInToken && priceProps.priceInToken !== 0)
    ) {
      return true;
    }
    return false;
  }, [priceProps, statusProps]);

  const showUploadProgress = useMemo(() => {
    return uploadProgress < 100;
  }, [uploadProgress]);
  return (
    <MediaManagerMediaCard onClick={onClick}>
      <MediaManagerMediaCardHeader height={"h-10"}>
        <TailwindFlex alignItems={"items-center"} width={"w-8/12"}>
          <MediaManagerMediaCardHeaderTitle {...titleProps} width={"w-auto"} />
          <MediaManagerMediaCardMediaCount {...mediaCountProps} />
        </TailwindFlex>
        <MediaManagerMediaCardFooterPrice {...priceProps} />
      </MediaManagerMediaCardHeader>
      <MediaManagerMediaCardBody {...bodyProps}>
        {showFooter && (
          <MediaManagerMediaCardFooter height={"h-12"}>
            {showUploadProgress ? (
              <MediaManagerMediaCardFooterUploadProgress
                uploadProgress={uploadProgress}
              />
            ) : (
              <>
                <TailwindFlex width={"w-full"} margin={["mt-2"]}>
                  {purchasesProps && (
                    <MediaManagerMediaCardPurchases {...purchasesProps} />
                  )}
                  <MediaManagerMediaCardFooterStatus
                    {...statusProps}
                    fontSize={"text-lg"}
                  />
                </TailwindFlex>
                <MediaManagerMediaCardFooterActions
                  {...actionsProps}
                  fileUploadProps={fileUploadProps}
                />
              </>
            )}
          </MediaManagerMediaCardFooter>
        )}
      </MediaManagerMediaCardBody>
    </MediaManagerMediaCard>
  );
};

export default injectIntl(MediaManagerAlbum);
