import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import StudioPayoutHeader from "./studio-payout-header";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindSpinner from "library/components/_tailwind/spinner";
import EarningsStore from "../_stores/earnings/EarningsStore";
import StudioPayoutContainer from "./studio-payout-table";

type StudioPayoutEarningsProps = {
  earningsStore?: EarningsStore;
};

const StudioPayoutEarnings: React.FunctionComponent<StudioPayoutEarningsProps> = ({
  earningsStore,
}) => {
  const { loadingFilters, initPayoutPageData } = earningsStore!;

  useEffect(() => {
    initPayoutPageData();
  }, []);

  return (
    <TailwindFlex
      flexDirection={"flex-col"}
      padding={["p-0"]}
      overflow={"overflow-hidden"}
      height={"h-full"}>
      {loadingFilters && <TailwindSpinner size={6} />}
      {!loadingFilters && (
        <TailwindFlex
          flexDirection={"flex-col"}
          height={"h-full"}
          overflow={"overflow-hidden"}>
          <StudioPayoutHeader />
          <StudioPayoutContainer />
        </TailwindFlex>
      )}
    </TailwindFlex>
  );
};

export default inject("earningsStore")(observer(StudioPayoutEarnings));
