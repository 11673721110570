import React, { useCallback } from "react";
import TailwindButton from "library/components/_tailwind/button";
import BroadcastStrategy from "common/broadcast/_stores/BroadcastStrategy";
import BroadcastStore from "common/broadcast/_stores/broadcast/BroadcastStore";
import { observer, inject } from "mobx-react";
import LanguageStore from "library/core/stores/language/LanguageStore";

type Props = {
  broadcastStrategy?: BroadcastStrategy;
  broadcastStore?: BroadcastStore;
  languageStore?: LanguageStore;
};

const BroadcastStopStreamingButton: React.ComponentType<Props> = ({
  broadcastStrategy,
  broadcastStore,
  languageStore
}) => {
  const { intl } = languageStore!;
  const { isShowStarted } = broadcastStore!;
  const { disconnect } = broadcastStrategy!;

  const handleClickStop = useCallback(() => {
    disconnect();
  }, []);

  if (!isShowStarted) {
    return null;
  }

  return (
    <TailwindButton
      dataTestId={"stop-broadcasting-button"}
      className={["mt-4"]}
      textColor='text-white'
      backgroundColor={"bg-pink-600"}
      rounded={false}
      fullWidth={true}
      justifyContent={"justify-center"}
      onClick={handleClickStop}>
      {intl.formatMessage({
        id: "broadcast.broadcastPreview.stopStreaming",
        defaultMessage: "Stop Broadcasting",
      })}
    </TailwindButton>
  );
};

export default inject(
  "broadcastStrategy",
  "broadcastStore",
  "languageStore"
)(observer(BroadcastStopStreamingButton));
