import React from "react";
import "./styles.scss";
import classnames from "classnames";

type SearchIconProps = {
  className?: string;
};

const SearchIcon: React.ComponentType<SearchIconProps> = ({ className }) => (
  <span className={classnames("SearchIcon", className && className)}>
    <svg
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 18 18.00001"
    >
      <g transform="translate(0, 0)">
        <g transform="translate(0.00001000000000139778, 0) rotate(0)">
          <path
            d="M12.86449,11.32075h-0.81304l-0.28816,-0.27787c1.00858,-1.17324 1.61578,-2.6964 1.61578,-4.35334c0,-3.69468 -2.99485,-6.68954 -6.68954,-6.68954c-3.69468,0 -6.68954,2.99485 -6.68954,6.68954c0,3.69468 2.99485,6.68954 6.68954,6.68954c1.65695,0 3.1801,-0.6072 4.35334,-1.61578l0.27787,0.28816v0.81304l5.1458,5.13551l1.53345,-1.53345zM6.68954,11.32075c-2.56261,0 -4.63122,-2.06861 -4.63122,-4.63122c0,-2.56261 2.06861,-4.63122 4.63122,-4.63122c2.56261,0 4.63122,2.06861 4.63122,4.63122c0,2.56261 -2.06861,4.63122 -4.63122,4.63122z"
            style={{
              strokeWidth: 0,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
            }}
            vectorEffect="non-scaling-stroke"
          />
        </g>
        <defs>
          <path
            id="path-161463783384194531"
            d="M12.86449,11.32075h-0.81304l-0.28816,-0.27787c1.00858,-1.17324 1.61578,-2.6964 1.61578,-4.35334c0,-3.69468 -2.99485,-6.68954 -6.68954,-6.68954c-3.69468,0 -6.68954,2.99485 -6.68954,6.68954c0,3.69468 2.99485,6.68954 6.68954,6.68954c1.65695,0 3.1801,-0.6072 4.35334,-1.61578l0.27787,0.28816v0.81304l5.1458,5.13551l1.53345,-1.53345zM6.68954,11.32075c-2.56261,0 -4.63122,-2.06861 -4.63122,-4.63122c0,-2.56261 2.06861,-4.63122 4.63122,-4.63122c2.56261,0 4.63122,2.06861 4.63122,4.63122c0,2.56261 -2.06861,4.63122 -4.63122,4.63122z"
            vectorEffect="non-scaling-stroke"
          />
        </defs>
      </g>
    </svg>
  </span>
);

export default SearchIcon;
