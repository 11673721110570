import React, { useEffect, useState } from "react";
import TailwindFlex, { TailwindFlexProps } from "../flex";
import ReactCrop from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";
import TailwindSpinner from "../spinner";
import TailwindImage from "../image";

export type Crop = {
  unit: "px" | "%";
  width: number;
  height?: number;
  aspect: number;
  x?: number;
  y?: number;
};

export type TailwindImageCropProps = TailwindFlexProps & {
  src: File | null;
  crop: Crop;
  cropPreview: string;
  imageType: "profile_image" | "non_nude_profile_image" | string | null;
  setCropPreview: Function;
  onCropChange?: (crop: Crop) => void;
};

const TailwindImageCrop: React.ComponentType<TailwindImageCropProps> = ({
  src,
  imageType,
  crop,
  cropPreview,
  setCropPreview,
  onCropChange,
  ...props
}) => {
  const [dataUrl, setDataUrl] = useState<string | ArrayBuffer | null>();
  const [cropObj, setCropObj] = useState<Crop>(crop);
  const [circleCrop, setCircleCrop] = useState<any>();
  const [imageRef, setImageRef] = useState<any>();

  useEffect(() => {
    if (src) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setDataUrl(reader.result);
      });
      reader.readAsDataURL(src);
    }
  }, [src]);

  const makeClientCrop = async (_crop: Crop) => {
    if (imageRef && _crop?.width && _crop.height) {
      const result = await getCroppedImg(imageRef, _crop, false);
      if (setCropPreview) {
        setCropPreview(result);
      }
      const circleCroping = {
        x: _crop?.x,
        y: _crop?.y,
        width: _crop.width,
        height: _crop.height,
        unit: "px",
        aspect: 9 / 9,
      };
      const circleResult = await getCroppedImg(imageRef, circleCroping, true);

      setCircleCrop(circleResult);
    }
  };

  const getCroppedImg = (image, _crop, circle) => {
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    const scaleX: number = image.naturalWidth / image.width;
    const scaleY: number = image.naturalHeight / image.height;
    canvas.width = _crop.width;
    canvas.height = _crop.height;
    const ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");

    !circle &&
      ctx?.drawImage(
        image,
        _crop.x * scaleX,
        _crop.y * scaleY,
        _crop.width * scaleX,
        _crop.height * scaleY,
        0,
        0,
        _crop.width,
        _crop.height
      );
    circle &&
      ctx?.drawImage(
        image,
        _crop.x * scaleX,
        _crop.y * scaleY,
        _crop.width * scaleX,
        _crop.height * scaleY,
        0,
        0,
        _crop.width,
        _crop.height
      );

    return new Promise(resolve => {
      canvas.toBlob(blob => {
        if (blob) {
          const url = window.URL.createObjectURL(blob);
          //window.URL.revokeObjectURL(cropFileUrl);
          resolve(url);
        }
      });
    });
  };

  const handleChange = (_crop: Crop) => {
    setCropObj(_crop);
    if (onCropChange) {
      onCropChange(_crop);
    }
  };

  return (
    <TailwindFlex flexDirection={"flex-col"} {...props}>
      {dataUrl && (
        <>
          <TailwindFlex
            width='w-full'
            margin={["mt-4"]}
            alignItems='items-start'
            gap={"gap-2"}>
            <TailwindFlex width={"w-full"} margin={["mt-1"]}>
              <ReactCrop
                src={dataUrl}
                crop={cropObj}
                onChange={handleChange}
                onComplete={makeClientCrop}
                onImageLoaded={setImageRef}
                ruleOfThirds
              />
            </TailwindFlex>
            <TailwindFlex
              flexDirection={"flex-col"}
              alignItems={"items-center"}
              width='w-4/6'>
              <TailwindFlex justifyContent='justify-center' width={"w-full"}>
                How you will appear to members:
              </TailwindFlex>
              {Boolean(circleCrop) && (
                <TailwindImage
                  boxProps={{
                    width: "w-40",
                    height: "h-40",
                    borderRadius: "rounded-full",
                    display: "flex",
                    margin: ["mt-6"],
                    objectFit: "object-cover",
                    justifyContent: "justify-center",
                  }}
                  className={"rounded-full w-full object-cover"}
                  src={circleCrop}
                />
              )}
              {Boolean(cropPreview) && (
                <TailwindImage
                  boxProps={{ margin: ["mt-6"] }}
                  src={cropPreview}
                />
              )}
            </TailwindFlex>
          </TailwindFlex>
        </>
      )}

      {!dataUrl && <TailwindSpinner />}
    </TailwindFlex>
  );
};

export default TailwindImageCrop;
