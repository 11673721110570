import React, { useCallback, useEffect, useMemo, useState } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindInput from "library/components/_tailwind/input";
import TailwindButton from "library/components/_tailwind/button";
import AccountSettingsStore from "common/account-settings/stores/account-settings/AccountSettingsStore";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";

type Props = {
  accountSettingsStore?: AccountSettingsStore;
  onBlockMemberSuccess?: () => void;
};

const BlockAddMember: React.ComponentType<Props & WrappedComponentProps> = ({
  accountSettingsStore,
  onBlockMemberSuccess,
  intl,
}) => {
  const {
    clearBlockErrors,
    blockFormErrors,
    blockMember,
    isBlockingOrUnblocking,
    validateBlockFormInput,
  } = accountSettingsStore!;

  const [name, setName] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [site, setSite] = useState<string>("");
  const [isNameTouched, setIsNameTouched] = useState<boolean>(false);
  const [isSiteTouched, setIsSiteTouched] = useState<boolean>(false);
  const [isReasonTouched, setIsReasonTouched] = useState<boolean>(false);
  useEffect(() => {
    clearBlockErrors();
    setIsNameTouched(false);
    setIsSiteTouched(false);
    setIsReasonTouched(false);
    return () => {
      clearBlockErrors();
      setIsNameTouched(false);
      setIsSiteTouched(false);
      setIsReasonTouched(false);
    };
  }, []);

  const onBlockMemberClicked = useCallback(async () => {
    try {
      await blockMember({
        name,
        reason,
        site,
      });
      setName("");
      setReason("");
      setSite("");
      if (onBlockMemberSuccess) {
        onBlockMemberSuccess();
      }
    } catch (error) {}
  }, [name, reason, site, onBlockMemberSuccess]);

  const onChangeName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    },
    []
  );

  const onBlurName = useCallback(() => {
    validateBlockFormInput("name", name);
    setIsNameTouched(true);
  }, [name]);

  const onChangeReason = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setReason(event.target.value);
    },
    []
  );

  const onBlurReason = useCallback(() => {
    validateBlockFormInput("reason", reason);
    setIsReasonTouched(true);
  }, [reason]);

  const onChangeSite = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSite(event.target.value);
    },
    []
  );

  const onBlurSite = useCallback(() => {
    validateBlockFormInput("site", site);
    setIsSiteTouched(true);
  }, [site]);

  const isFormValid = useMemo(() => {
    return Object.values(blockFormErrors).every(v => v === null);
  }, [blockFormErrors]);

  const hasFormUntouchedInputs = useMemo(() => {
    return [isNameTouched, isSiteTouched, isReasonTouched].some(t => !t);
  }, [isNameTouched, isSiteTouched, isReasonTouched]);

  return (
    <TailwindFlex flexDirection={"flex-col"}>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindTranslatedText
          descriptor={{
            id: "accountSettings.blockUnblock.blockMember",
            defaultMessage: "Block Member",
          }}
          headingProps={{ level: 5 }}
        />
      </TailwindFlex>
      <TailwindFlex flexDirection={"flex-col"}>
        <TailwindFlex margin={["mb-4"]}>
          <TailwindInput
            label={"Member username"}
            backgroundColor={"secondary"}
            onChange={onChangeName}
            onBlur={onBlurName}
            placeholder={"Enter a member username"}
            value={name}
            error={blockFormErrors.name || undefined}
          />
        </TailwindFlex>
        <TailwindFlex margin={["mb-4"]}>
          <TailwindInput
            label={"Member site"}
            backgroundColor={"secondary"}
            onChange={onChangeSite}
            onBlur={onBlurSite}
            placeholder={"Enter the site member is viewing from"}
            value={site}
            error={blockFormErrors.site || undefined}
          />
        </TailwindFlex>
        <TailwindFlex margin={["mb-4"]}>
          <TailwindInput
            label={"Note"}
            backgroundColor={"secondary"}
            onChange={onChangeReason}
            onBlur={onBlurReason}
            placeholder={"Enter the reason for blocking the member"}
            value={reason}
            error={blockFormErrors.reason || undefined}
          />
        </TailwindFlex>
        <TailwindFlex justifyContent={"justify-end"}>
          <TailwindButton
            backgroundColor={"primary"}
            rounded={false}
            fullWidth={true}
            justifyContent={"justify-center"}
            onClick={onBlockMemberClicked}
            showSpinner={isBlockingOrUnblocking}
            disabled={
              isBlockingOrUnblocking || hasFormUntouchedInputs || !isFormValid
            }>
            {intl.formatMessage({
              id: "accountSettings.blockUnblock.block",
              defaultMessage: "Block",
            })}
          </TailwindButton>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject("accountSettingsStore")(observer(BlockAddMember))
);
