import React from "react";
import ProfileOnlineStatus, {
  ProfileOnlineStatusProps,
} from "../../profile/online-status";
import TailwindFlex from "../flex";

export type MediaProfileOnlineStatusOverlayProps = {} & ProfileOnlineStatusProps;

const MediaProfileOnlineStatusOverlay: React.ComponentType<MediaProfileOnlineStatusOverlayProps> = ({
  ...profileOnlineStatusProps
}) => {
  return (
    <TailwindFlex
      height={"h-full"}
      alignItems={"items-start"}
      justifyContent={"justify-start"}
      padding={["pl-4", "pt-2"]}>
      <ProfileOnlineStatus {...profileOnlineStatusProps} />
    </TailwindFlex>
  );
};

export default MediaProfileOnlineStatusOverlay;
