import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "core/stores/route/private-route";
import CamsStudioModelsListContainer from "./studio-models/models-list";
import {
  AppCamsStudioRouteKey,
  AppCommonRouteKey,
} from "core/stores/route/enums";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import { inject, observer } from "mobx-react";
import EarningsContainer from "../../common/earnings";
import NotFoundRoute from "core/stores/route/not-found-redirect";
import RouteStore from "core/stores/route/RouteStore";

type Props = { profileStore?: ProfileStore; routeStore?: RouteStore };

const CamsStudioRoutes: React.ComponentType<Props> = ({
  profileStore,
  routeStore,
}) => {
  const { getSiteRouteURL } = routeStore!;
  const { isStudio } = profileStore!;

  return (
    <Switch>
      <PrivateRoute exact path={getSiteRouteURL(AppCommonRouteKey.earnings)}>
        <EarningsContainer />
      </PrivateRoute>
      <PrivateRoute
        accessRestrictionOptions={{
          approvedOnly: ["studio"],
        }}
        hasAccess={isStudio}
        exact
        path={getSiteRouteURL(AppCamsStudioRouteKey.models)}>
        <CamsStudioModelsListContainer />
      </PrivateRoute>
      <NotFoundRoute />
    </Switch>
  );
};
export default inject("profileStore", "routeStore")(observer(CamsStudioRoutes));
