import React, { useEffect, useMemo } from "react";
import TailwindBox from "library/components/_tailwind/box";
import TailwindText from "library/components/_tailwind/text";
import "./styles.scss";
import AuthStore from "core/stores/auth/AuthStore";
import { inject, observer } from "mobx-react";
import LoadingResourceSpinner from "common/messages/messages-loading-spinner";
import { SIGNUP_AGREEMENT_TYPE } from "common/sign-up/enums";
import { Agreement } from "common/my-page/stores/profile/types";

type Props = {
  authStore?: AuthStore;
};
const BroadcastRelease: React.FC<Props> = ({ authStore }) => {
  const { getAgreements, agreements, isLoadingAgreements } = authStore!;

  useEffect(() => {
    getAgreements();
  }, []);

  const agreement: Agreement | undefined = useMemo(() => {
    return agreements.find(
      agreement =>
        agreement.agreement_type === SIGNUP_AGREEMENT_TYPE.BROADCAST_RELEASE
    );
  }, [agreements]);

  return (
    <div className={"BroadcastRelease"}>
      <TailwindText
        display={"block"}
        fontSize={"text-3xl"}
        fontWeight={"font-bold"}
        padding={["py-2"]}
        margin={["mb-4"]}>
        Broadcast Release
      </TailwindText>
      {isLoadingAgreements ? (
        <LoadingResourceSpinner />
      ) : agreement ? (
        <TailwindBox
          dangerouslySetInnerHTML={{
            __html: agreement.text,
          }}></TailwindBox>
      ) : null}
    </div>
  );
};

export default inject("authStore")(observer(BroadcastRelease));
