import React from "react";
import "./styles.scss";
import clsx from "clsx";

type NotesIconProps = {
  className?: string;
};

const NotesPencilIcon: React.ComponentType<NotesIconProps> = ({
  className,
}) => (
  <span className={clsx("NotesPencilIcon", className && className)}>
    <svg
      width='1000'
      height='871'
      viewBox='0 0 1000 871'
      xmlns='http://www.w3.org/2000/svg'>
      <path d='M628.237 163.252L86.4779 622.564L0 870.575L256.398 823.564L798.158 364.252L628.237 163.252Z' />
      <path d='M681.734 122.621L847.009 318.126C847.009 318.126 989.631 219.247 998.988 170.301C1011.01 107.372 913.257 5.37437 849.495 0.0974571C799.906 -4.00247 681.734 122.621 681.734 122.621Z' />
    </svg>
  </span>
);

export default NotesPencilIcon;
