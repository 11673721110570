import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindIcon from "library/components/_tailwind/icon";

type Props = {};

const MessageCenterNavigatorCheckMark: React.ComponentType<Props> = ({}) => {
  return (
    <TailwindFlex className={["MessageCenterCheckMark"]} position={"relative"}>
      <TailwindIcon name={"confirm-circle"} textColor={"text-green-500"} />
    </TailwindFlex>
  );
};

export default MessageCenterNavigatorCheckMark;
