import React from "react";
import "./styles.scss";
import clsx from "clsx";

type ArrowRightProps = {
  className?: string;
};

const ArrowRight: React.ComponentType<ArrowRightProps> = ({ className }) => (
  <span className={clsx("ArchivedIcon", className && className)}>
    <svg 
      version="1.2" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlnsXlink="http://www.w3.org/1999/xlink" 
      overflow="visible" 
      preserveAspectRatio="none" 
      viewBox="0 0 26 26" 
      xmlSpace="preserve" 
      height="12" 
      width="12" 
      y="0px" 
      x="0px" 
      id="Layer_1_1701280614630">
        <g 
          transform="translate(1, 1)">
            <polygon 
              points="6.947,0.998 17.948,11.999 6.947,23.002 7.875,23.929 19.803,11.999 7.875,0.071" 
              fill="#333333" 
              vectorEffect="non-scaling-stroke" 
              />
        </g>
    </svg>
  </span>
);

export default ArrowRight;
