
import React from "react";
import "./styles.scss";
import classnames from "classnames";

type BountyIconProps = {
  className?: string;
};

const BountyIcon: React.ComponentType<BountyIconProps> = ({ className }) => (
  <span className={classnames("BountyIcon", className && className)}>
    <svg width='15px' height='15px' viewBox='0 0 55 55'>
      <g
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'>
        <g>
          <circle
            fill='#FFB1C6'
            cx='27.5'
            cy='27.5'
            r='27.5'></circle>
          <text
            id='B'
            fontFamily='Lato'
            fontSize='36'
            fontWeight='normal'
            fill='#FFFFFF'>
            <tspan x='16' y='40'>
              B
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  </span>
);

export default BountyIcon;
