import { ChatState, IChatTab } from "../chat/enums";
import { MemberSortTypes } from "./types";

export enum NODECHAT_EVENTS {
  WHISPER = 'whisper',
  MESSAGE = 'message',
  MEMBER_JOINED = 'member_joined',
  MEMBER_LEFT = 'member_left',
  USER_COUNT = 'user_cnt',
  TIP = 'tip',
  GIFT = 'gift',
  APPINIT = "appinit",
  WHEEL = "wheel",
  CHAT_TYPE = "chat_type",
  CHAT_INFO = "chat_info",
  TIP_PROGRESS = "tip_progress",
  USER_KICKED = "user_kicked",
  UPDATE_USER_INFO = "update_user_info",
  ROOM_TOPIC = "room_topic",
  QUICK_TEXT = "quick_text"
}

export const MAX_MESSAGES = 200;
export const MESSAGE_MAX_CHARS = 200;

export const VIEWER_TYPE = {
  GUEST: "guest",
  MEMBER: "member",
  ADMIN: "admin",
};

export enum NODECHAT_CHAT_MODE {
  OFFLINE = 0,
  FREE = 1,
  NUDE = 2,
  PRIVATE = 3,
  ADMIN = 4,
  TIPPING = 6,
  PARTY = 10,
  EXCLUSIVE = 11
}

export const CHAT_TYPE = {
  OFFLINE: "offline",
  ADMIN: "admin",
  EXCLUSIVE: "exclusive",
  FREE: "free",
  GROUPSHOW: "groupshow",
  HAPPY_HOUR: "happyHour",
  PARTY: "party",
  PRIVATE: "private",
  NUDE: "nude",
  VOYEUR: "voyeur",
  CAM_2_CAM: "cam2cam",
  TIPPING: "tip",
  UNREG: "unreg",
  ZERO_BALANCE: "zero_balance",
};

export const COLORS = {
  MODEL_COLOR: "#ed2556",
  USERS_COLOR: "#6e993f",
  VIEWER_COLOR: "#2e94d9",
  VIEWER_FANCLUB_COLOR: "#0e84db",
  SYSTEM_COLOR: "#666666",
  BLACK_COLOR: "#000000",
  WARNING_COLOR: "#FF6600",
  GAME_COLOR: "#000000",
  MODEL_INFO_BG: "#00ff00",
  BROADCAST_COLOR: "#43640A",
  MSG_LINK_COLOR: "#689914",
  ADMIN_COLOR_DEFAULT: "#FF0000",
  HOTPID_COLOR_DEFAULT: "#FF1986",
  BOUNTY_COLOR_DEFAULT: "#FFB1C6",
  FAN_COLOR_DEFAULT: "#009900",
  PREM_COLOR_DEFAULT: "#FF007F",
  PAID_COLOR_DEFAULT: "#00CCCC",
  DEFAULT_COLOR_DEFAULT: "#666666",
  NEW_PROSPECT_COLOR_DEFAULT: "#75391C",
  SPENT_TIER_ONE: "#0000CC",
  SPENT_TIER_TWO: "#AD33FF",
  SPENT_TIER_THREE: "#5C0099",
  ONLINE: "#0d0",
  OFFLINE: "#e00",
};

export const TIMERS = {
  IDLE_TIME: 59,
  DISCONNECT_TIME: 60,
};

export const LINKS = {
  LINK1:
    "", //"https://secureimage.securedataimages.com/images/cams/models/Step1_Intro.pdf",
  LINK2:
    "", //"https://secureimage.securedataimages.com/images/cams/models/Step2_Install_FMLE_v5.6.pdf",
  LINK3:
    "", //"https://secureimage.securedataimages.com/images/cams/models/Step_3_How_to_broadcast_in_720p_using_OBS_",
  LINK4:
    "", //"https://secureimage.securedataimages.com/images/cams/models/Step_1_Introduction_to_OBS.pdf",
  LINK5:
    "", //"https://secureimage.securedataimages.com/images/cams/models/Step_2_Download_OBS_for_HD.pdf",
  CDN: "https://secureimage.securedataimages.com/images/cams/",
  /*CDN : "https://cams.com/images/cams/"*/
};

export const MEMBER_DELIMITER_CHARACTER = "@"


export const ENGLISH = {
  lang:"en",
  name:"", 
  icon:"flag_none"
};
export const ROMANIAN = {
  lang:"ro",
  name:"82,111,109,226,110,259", 
  icon:"flag_ro"
};
export const RUSSIAN = {
  lang:"ru",
  name:"1056,1091,769,1089,1089,1082,1080,1081", 
  icon:"flag_ru"
};
export const SPANISH = {
  lang:"es",
  name:"69,115,112,97,241,111,108", 
  icon:"flag_es"
};
export const PORTUGUESE = {
  lang:"pt",
  name:"80,111,114,116,117,103,117,234,115", 
  icon:"flag_pt"
};
export const HUNGARIAN = {
  lang:"hu",
  name:"712,77,594,607,594,114", 
  icon:"flag_hu"
};
export const GERMAN = {
  lang:"de",
  name:"68,101,117,116,115,99,104", 
  icon:"flag_de"
};
export const ITALIAN = {
  lang:"it",
  name:"73,116,97,108,105,97,110,111", 
  icon:"flag_it"
};
export const FRENCH = {
  lang:"fr",
  name:"70,114,97,110,231,97,105,115", 
  icon:"flag_fr"
};

export const INPUT_ICONS_AREA_WIDTH = 184;

export const InitialData = {
  roomTopic: "",
  modelName: "",
  chatinput: "",
  emoticonsShowing: false,
  focusInput: true,
  tipperName: "",
  showTranslation: false,
  translationHidden: false,
  numViewers: 0,
  quickTexts: [],
  currentFontSize: "",//sharedData.fontSize,
  sizerIsShowing: false,
  none: ENGLISH,
  de: GERMAN,
  es: SPANISH,
  it: ITALIAN,
  hu: HUNGARIAN,
  pt: PORTUGUESE,
  ro: ROMANIAN,
  ru: RUSSIAN,
  fr: FRENCH,
  selectedLang: "",//sharedData.selectedLang,
  inPrivateWith: "",
  whisperArray: [],
  whisperCountArray: [],
  inputIconsAreaWidth: INPUT_ICONS_AREA_WIDTH,
  kickbanwarningShowing: false,
  banconfirmShowing: false,
  memberbannedConfirmShowing: false,
  saveAsQt: false,
  showQT: false,
  deleteQuickTextPrompt: false,
  showTopTipper: false,
  showWhisper: false, //$cookies.get('showWhisperTabs') == 'true',
  useCloudflare: false, //sharedData.useCloudflare,
  nodeChatDisabled: true,
  chatState: ChatState.stopped,
  publicChatMessages: [],
  privateChatMessages: [],
  activeChatTab: IChatTab.PUBLIC_OR_PRIVATE,
  allMemberHash: {},
  publicMemberHash: {},
  privateMemberHash: {},
  voyeurMemberHash: {},
  numberOfOnlineGuests: 0,
  numberOfOnlinePublicMembers: 0,
  numberOfOnlinePrivateMembers: 0,
  publicMemberCount: 0,
  privateWhisperHash: {},
  publicWhisperHash: {},
  bufferedMessages: [],
  notifications: [],
  giftHash: {},
  wheelOfFunSpinnerTimestamp: 0,
  wheelOfFunSpinnerUser: '',
  wheelOfFunSpinnerReward: '',
  isPrivateChatReady: false,
  showTippingSum: 0,
  currentChatLanguage: 'en',
  shouldShowPrivatememberLeftConfirmation: false,
  fetchUserListTimeout: null,
  camExtension: null,
  camExtensionConnected: false,
  connectionProblems: false,
  reconnectTime: 0,
  isReconnecting: false,
  memberSortType: MemberSortTypes.TOP_SPENDER,
  sessionEarnings: 0,
  sessionPayoutPercent: 0,
  allTopAdmirerHash: [],
};

