import React, { useCallback } from "react";
import MediaManagerMediaThumbnail from "containers/_components/media-manager/media-manager-media-thumbnail";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindBox from "library/components/_tailwind/box";
import { FileRejection } from "react-dropzone";
import {
  MY_PAGE_ACCEPTED_FILE_EXTENSIONS,
  MY_PAGE_ACCEPTED_MAXIMUM_FILES_FOR_CUSTOM_ALBUM,
} from "containers/_components/media-manager/store/consts";
import MediaManagerPrompt from "containers/_components/media-manager/media-manager-prompt";
import { MyPagePromptMode } from "containers/_components/media-manager/store/enums";
import { logger } from "library/core/utility";
import { inject, observer } from "mobx-react";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import MediaManagerStore from "containers/_components/media-manager/store/MediaManagerStore";

type Props = {
  validationStore?: ValidationStore;
  mediaManagerStore?: MediaManagerStore;
};

const MediaManagerSidebarBodyUpload: React.ComponentType<Props> = ({
  mediaManagerStore,
  validationStore,
}) => {
  const { selectedAlbum, uploadModelMediaFile, setIsPromptOpen, isSaving } =
    mediaManagerStore!;
  const { showNotificationOnRejectedFiles } = validationStore!;

  const onDropRejected = useCallback((rejections: FileRejection[]) => {
    showNotificationOnRejectedFiles(
      rejections,
      MY_PAGE_ACCEPTED_MAXIMUM_FILES_FOR_CUSTOM_ALBUM,
      MY_PAGE_ACCEPTED_FILE_EXTENSIONS.join(", ")
    );
  }, []);

  const onDrop = useCallback(
    async (files: File[]) => {
      try {
        if (files && files.length && selectedAlbum) {
          let hasVideos = false;
          files.forEach(file => {
            if (
              selectedAlbum.album_type !== "videos" &&
              file?.type &&
              file?.type.match(/video/)
            ) {
              hasVideos = true;
            }
            uploadModelMediaFile(file, file.name, selectedAlbum.id);
          });
          if (hasVideos) {
            setIsPromptOpen(
              <MediaManagerPrompt />,
              MyPagePromptMode.VIDEOALBUM,
              async () => {
                setIsPromptOpen(null);
              },
              () => {
                setIsPromptOpen(null);
              }
            );
          }
        }
      } catch (error) {
        logger.log("MediaManagerStore: error uploading media", error);
      }
    },
    [selectedAlbum]
  );

  if (!selectedAlbum) {
    return null;
  }

  return (
    <TailwindBox margin={["mb-3"]}>
      <MediaManagerMediaThumbnail
        fileUploadProps={{
          backgroundColor: "bg-transparent",
          height: "h-60",
          minWidth: "min-w-64",
          inset: ["top-0", "left-0"],
          fileUploadProps: {
            borderWidth: ["border-8"],
            borderStyle: "border-dashed",
            borderColor: "border-gray-500",
            padding: ["p-4"],
            customIcon: <TailwindIcon name={"add"} />,
            textColor: "text-gray-700",
            helperText: `Drag and drop<br>or<br>click to upload ${
              selectedAlbum.album_type === "videos" ? "videos" : "photos"
            }`,
            isUploading: isSaving,
            dropzoneProps: {
              onDrop: files => onDrop(files),
              onDropRejected,
              accept:
                selectedAlbum.album_type === "videos"
                  ? ["videos/*", "video/*"]
                  : ["image/jpeg", "image/jpg", "image/png"],
            },
          },
        }}
        bodyProps={{
          backgroundImage: null,
        }}
      />
    </TailwindBox>
  );
};

export default inject(
  "mediaManagerStore",
  "validationStore"
)(observer(MediaManagerSidebarBodyUpload));
