import React from "react";
import "./styles.scss";

type PhotosIconProps = {
  className?: string;
};

const PhotosIcon: React.ComponentType<PhotosIconProps> = ({ className }) => (
  <span className={`${className} camsIcon`}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
      <path d='M72.52,6h-45A22.92,22.92,0,0,0,4.59,28.85v45A22.92,22.92,0,0,0,27.48,96.78h45A22.92,22.92,0,0,0,95.41,73.89v-45A22.92,22.92,0,0,0,72.52,6Zm-45,5h45A17.91,17.91,0,0,1,90.41,28.85V41.47H72.09a27.18,27.18,0,0,0-41.93,0H9.59V28.85A17.9,17.9,0,0,1,27.48,11ZM51.12,36.59A22.22,22.22,0,1,1,28.9,58.81,22.25,22.25,0,0,1,51.12,36.59Zm21.4,55.19h-45A17.91,17.91,0,0,1,9.59,73.89V46.47H26.87a27.22,27.22,0,1,0,48.5,0h15V73.89A17.92,17.92,0,0,1,72.52,91.78Z' />
      <path d='M72.51,34.82H77a7.18,7.18,0,0,0,7.42-6.88v-4A7.18,7.18,0,0,0,77,17.06H72.51a7.17,7.17,0,0,0-7.41,6.88v4A7.17,7.17,0,0,0,72.51,34.82ZM70.1,23.94a2.22,2.22,0,0,1,2.41-1.88H77a2.23,2.23,0,0,1,2.42,1.88v4A2.23,2.23,0,0,1,77,29.82H72.51a2.22,2.22,0,0,1-2.41-1.88Z' />
      <path d='M51.12,74.15A15.34,15.34,0,1,0,35.78,58.81,15.36,15.36,0,0,0,51.12,74.15Zm0-25.68A10.34,10.34,0,1,1,40.78,58.81,10.35,10.35,0,0,1,51.12,48.47Z' />
      <circle cx='24.53' cy='25.94' r='4.23' />
    </svg>
  </span>
);

export default PhotosIcon;
