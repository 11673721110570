import { CAMS_MODEL_PAYOUT_METHOD_KEY } from "./enums";
import { PAYOUT_CHECKING_DEFAULT, PAYOUT_DIRECT_DEFAULT, PAYOUT_PAXUM_DEFAULT, PAYOUT_WIRE_DEFAULT } from "./consts";

export const getDefaultPayoutSetting = (methodName: CAMS_MODEL_PAYOUT_METHOD_KEY) => {
  const defaults = {
    confirmation_code_resent_count: 0,
    has_models_located_in_usa: true, //by default it is assumed studio has models in US as product decision
    is_confirmed: false,
    is_selected: false,
    method_name: methodName,
    min_amount: null,
  };

  switch (methodName) {
    case CAMS_MODEL_PAYOUT_METHOD_KEY.check: {
      return {
        ...defaults,
        payout_settings: PAYOUT_CHECKING_DEFAULT,
      };
    }
    case CAMS_MODEL_PAYOUT_METHOD_KEY.direct_deposit: {
      return {
        ...defaults,
        payout_settings: PAYOUT_DIRECT_DEFAULT,
      };
    }
    case CAMS_MODEL_PAYOUT_METHOD_KEY.paxum: {
      return {
        ...defaults,
        payout_settings: PAYOUT_PAXUM_DEFAULT,
      };
    }
    case CAMS_MODEL_PAYOUT_METHOD_KEY.wired_transfer: {
      return {
        ...defaults,
        payout_settings: PAYOUT_WIRE_DEFAULT,
      };
    }
  }
};
