import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindAnchor from "../../components/_tailwind/anchor";
import { CustomIconName } from "../../components/_tailwind/icon/icons/enums";

type Props = {};

const StorybookComponentsAnchor: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Anchor</StorybookHeading>
      <StorybookParagraph>
        Anchor is a component that is extended version of TailwindButton. It has
        no opinions about styling but it has some opinions about external links
        as well as internal app links connected to Router.
      </StorybookParagraph>
      <StorybookParagraph>
        If a link is external, it opens a new tab, if a link is not internal, it
        uses local app history to change the route so that the app does not
        trigger a refresh.
      </StorybookParagraph>
      <StorybookParagraph>
        It takes all props from TailwindButton but also sub props TailwindButton
        has as <Code inline>textProps</Code> or{" "}
        <Code inline>leftIconProps</Code> as well as the following props:
      </StorybookParagraph>
      <Code>
        {`to: string;
underline?: boolean;
`}
      </Code>
      <StorybookParagraph>
        Here are some examples, try not to click on them to stay on this page
        (except the external link one that opens in new window)
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindAnchor to={"https://google.com"}>
  https://google.com
</TailwindAnchor>
`}
      >
        <TailwindAnchor to={"https://google.com"}>
          https://google.com
        </TailwindAnchor>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindAnchor to={"/components/grid"}>
  /components/grid
</TailwindAnchor>
`}
      >
        <TailwindAnchor to={"/components/grid"}>
          /components/grid
        </TailwindAnchor>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindAnchor to={"/components/grid"} onClick={() => alert("You clicked")}>
  /components/grid
</TailwindAnchor>
`}
      >
        <TailwindAnchor
          to={"/components/grid"}
          onClick={() => alert("You clicked")}
        >
          /components/grid with onClick
        </TailwindAnchor>
      </StorybookComponentShowcase>
      <StorybookParagraph>
        Some examples showcasing TailwindButtonProps
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindAnchor to={"/components/grid"} textColor={"text-red-500"} textProps={{
  textTransform: "uppercase",
}}>
  /components/grid text-red-500 uppercase
</TailwindAnchor>
      `}
      >
        <TailwindAnchor
          to={"/components/grid"}
          textColor={"text-red-500"}
          textProps={{
            textTransform: "uppercase",
          }}
        >
          /components/grid text-red-500 uppercase
        </TailwindAnchor>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindAnchor to={"/components/grid"} textColor={"text-red-500"} textProps={{
  textTransform: "uppercase",
  pseudoClasses: ["hover:text-blue-500"]
}}>
  /components/grid text-red-500 uppercase hover:text-blue-500
</TailwindAnchor>
      `}
      >
        <TailwindAnchor
          to={"/components/grid"}
          textColor={"text-red-500"}
          textProps={{
            textTransform: "uppercase",
            pseudoClasses: ["hover:text-blue-500"],
          }}
        >
          /components/grid text-red-500 uppercase hover:text-blue-500
        </TailwindAnchor>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindAnchor
  to={"/components/grid"}
  textColor={"text-red-500"}
  textProps={{
    textTransform: "uppercase",
  }}
  rightIconProps={{
    name: CustomIconName.spinner,
    textColor: "text-yellow-500"
  }}
>
  /components/grid text-red-500 textProps: uppercase rightIconProps: spinner text-yellow-500
</TailwindAnchor>
      `}
      >
        <TailwindAnchor
          to={"/components/grid"}
          textColor={"text-red-500"}
          textProps={{
            textTransform: "uppercase",
          }}
          rightIconProps={{
            name: CustomIconName.spinner,
            textColor: "text-yellow-500",
          }}
        >
          /components/grid text-red-500 textProps: uppercase rightIconProps:
          spinner text-yellow-500
        </TailwindAnchor>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsAnchor;
