import React, { useCallback, useEffect, useMemo } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import AuthStore from "core/stores/auth/AuthStore";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import config from "core/config";
import ProfileStore from "../../my-page/stores/profile/ProfileStore";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import TailwindInput from "library/components/_tailwind/input";
import TailwindDropdown from "library/components/_tailwind/dropdown";
import TailwindButton from "library/components/_tailwind/button";
import RecaptchaForm from "library/components/_tailwind/recaptcha";
import { NullableString } from "library/core/types";
import { RegistrationStep } from "core/stores/auth/enums";
import TailwindBox from "library/components/_tailwind/box";
import TailwindDropdownCountryPhoneNumbers from "library/components/_tailwind/dropdown-country-phone-numbers";

type StudioAccountInfoStepProps = {
  authStore?: AuthStore;
  validationStore?: ValidationStore;
  profileStore?: ProfileStore;
};

type CountryItemValues = {
  label: string;
  value: string;
};

const StudioAccountInfoStep: React.ComponentType<
  StudioAccountInfoStepProps & WrappedComponentProps
> = ({ profileStore, authStore, validationStore, intl }) => {
  const { validateMultipleObj, clearErrors, clearError, validate, errors } =
    validationStore!;

  const {
    submitAccountRegistration,
    formRegistrationData,
    updateRegistrationFormField,
    userRole,
    isActionInProgress,
    countries,
  } = authStore!;
  const {
    //isCams
  } = profileStore!;
  const {
    username,
    first_name,
    last_name,
    address,
    country,
    state,
    zip_code,
    city,
    phone_number,
    phone_number_country_code,
  } = formRegistrationData!.studio;

  const { googleCaptchaSiteKey } = config!;

  const validateRegistrationFields = useCallback(() => {
    return validateMultipleObj(
      {
        username,
        first_name,
        last_name,
        address,
        country,
        state,
        zip_code,
        city,
        phone_number,
        phone_number_country_code,
      },
      undefined,
      false
    );
  }, [
    username,
    first_name,
    last_name,
    address,
    country,
    state,
    zip_code,
    city,
    phone_number,
    phone_number_country_code,
  ]);

  const onCaptchaChange = async (recaptchaKey: NullableString) => {
    if (recaptchaKey && userRole && validateRegistrationFields()) {
      try {
        await submitAccountRegistration(recaptchaKey);
      } catch (error) {
        // do not remove as this is used to fallback to v2
        throw new Error(error);
      }
    }
  };

  const countryDropdownValues = useMemo(() => {
    const countryValues = Array<CountryItemValues>();
    for (const [isoCode, countryName] of Object.entries(countries)) {
      countryValues.push({
        label: countryName,
        value: isoCode,
      });
    }
    return countryValues;
  }, [countries]);

  useEffect(() => clearErrors(), [validationStore]);

  useEffect(() => {
    if (errors.email) {
      authStore?.setRegistrationStep(RegistrationStep.AUTH_CREDENTIALS);
    }
  }, [errors]);

  return (
    <TailwindFlex
      sm={"w-full"}
      width={"w-96"}
      className={["RegisterModal__form"]}
      height={"h-full"}>
      <RecaptchaForm
        margin={["mt-6"]}
        captchaSiteKey={googleCaptchaSiteKey}
        beforeExecuteRecaptcha={clearErrors}
        callback={onCaptchaChange}>
        <TailwindFlex width={"w-full"} margin={["mt-2", "mb-2"]}>
          <TailwindTranslatedText
            headingProps={{ level: 5, textAlign: "text-center" }}
            descriptor={{
              id: "landing.readyTosetupYourStudio.title",
              defaultMessage: "Ready to setup your studio-dashboard?",
            }}
          />
        </TailwindFlex>
        <TailwindFlex margin={["mt-2", "mb-4"]} width={"w-full"}>
          <TailwindInput
            required
            maxLength={16}
            rounded={false}
            backgroundColor={"transparent"}
            borderColor={"border-black"}
            borderWidth={["border"]}
            label={intl.formatMessage({
              id: "landing.readyTosetupYourStudio.studioName",
              defaultMessage: "Studio Name",
            })}
            errorProps={{
              textAlign: "text-right",
            }}
            value={username || ""}
            type={TailwindInputType.text}
            name={"username"}
            error={errors?.username || undefined}
            onChange={e => {
              clearError("username");
              updateRegistrationFormField("studio", e.target);
            }}
            onBlur={e => validate("username", e.target.value)}
          />
        </TailwindFlex>
        <TailwindFlex
          margin={["mt-2", "mb-4"]}
          width={"w-full"}
          pseudoClasses={["sm:flex-col", "md:flex-col"]}>
          <TailwindBox
            width={"w-6/12"}
            padding={["pr-1"]}
            pseudoClasses={[
              "sm:pr-0",
              "sm:mb-4",
              "md:pr-0",
              "sm:mb-4",
              "md:w-full",
            ]}
            className={["RegisterModal__form-firstname"]}>
            <TailwindInput
              required
              rounded={false}
              backgroundColor={"transparent"}
              borderColor={"border-black"}
              borderWidth={["border"]}
              label={intl.formatMessage({
                id: "landing.readyTosetupYourStudio.firstName",
                defaultMessage: "First Name",
              })}
              errorProps={{
                textAlign: "text-right",
              }}
              value={first_name || ""}
              type={TailwindInputType.text}
              name={"first_name"}
              error={errors?.first_name || undefined}
              onChange={e => {
                clearError("first_name");
                updateRegistrationFormField("studio", e.target);
              }}
              onBlur={e => validate("first_name", e.target.value)}
            />
          </TailwindBox>
          <TailwindBox
            width={"w-6/12"}
            padding={["pl-1"]}
            pseudoClasses={["sm:pl-0", "md:pl-0", "md:w-full"]}
            className={["RegisterModal__form-lastname"]}>
            <TailwindInput
              required
              rounded={false}
              backgroundColor={"transparent"}
              borderColor={"border-black"}
              borderWidth={["border"]}
              label={intl.formatMessage({
                id: "landing.readyTosetupYourStudio.lastName",
                defaultMessage: "Last Name",
              })}
              errorProps={{
                textAlign: "text-right",
              }}
              value={last_name || ""}
              type={TailwindInputType.text}
              name={"last_name"}
              error={errors?.last_name || undefined}
              onChange={e => {
                clearError("last_name");
                updateRegistrationFormField("studio", e.target);
              }}
              onBlur={e => validate("last_name", e.target.value)}
            />
          </TailwindBox>
        </TailwindFlex>

        <TailwindFlex
          margin={["mt-2", "mb-4"]}
          width={"w-full"}
          flexWrap={"flex-wrap"}>
          <TailwindBox width={"w-full"}>
            <TailwindDropdownCountryPhoneNumbers
              label={"Phone Number Country Code"}
              borderColor={"border-black"}
              borderWidth={["border"]}
              focusBorderColor={"md:border-black"}
              backgroundColor={"bg-white"}
              selectedItemButtonProps={{
                backgroundColor: "bg-transparent",
              }}
              fullWidth
              value={phone_number_country_code}
              onChange={value =>
                updateRegistrationFormField("studio", {
                  name: "phone_number_country_code",
                  value,
                })
              }
              onClick={e => e.preventDefault()}
              name={"phone_number_country_code"}
            />
          </TailwindBox>
          <TailwindBox width={"w-full"} margin={["mt-2"]}>
            <TailwindInput
              label={"Phone Number"}
              required
              rounded={false}
              backgroundColor={"transparent"}
              borderColor={"border-black"}
              borderWidth={["border"]}
              errorProps={{
                textAlign: "text-right",
              }}
              value={phone_number || ""}
              type={TailwindInputType.text}
              name={"phone_number"}
              error={errors?.phone_number || undefined}
              onChange={e => {
                clearError("phone_number");
                updateRegistrationFormField("studio", e.target);
              }}
              onBlur={e => validate("phone_number", e.target.value)}
            />
          </TailwindBox>
        </TailwindFlex>
        <TailwindFlex margin={["mt-2", "mb-4"]} width={"w-full"}>
          <TailwindInput
            required
            rounded={false}
            backgroundColor={"transparent"}
            borderColor={"border-black"}
            borderWidth={["border"]}
            label={intl.formatMessage({
              id: "landing.readyTosetupYourStudio.mailingAddress",
              defaultMessage: "Mailing Address",
            })}
            errorProps={{
              textAlign: "text-right",
            }}
            value={address || ""}
            type={TailwindInputType.text}
            name={"address"}
            error={errors?.address || undefined}
            onChange={e => {
              clearError("address");
              updateRegistrationFormField("studio", e.target);
            }}
            onBlur={e => validate("address", e.target.value)}
          />
        </TailwindFlex>

        <TailwindFlex margin={["mt-2", "mb-4"]} width={"w-full"}>
          <TailwindBox padding={["pr-1"]} width={"w-8/12"}>
            <TailwindInput
              required
              rounded={false}
              backgroundColor={"transparent"}
              borderColor={"border-black"}
              borderWidth={["border"]}
              label={intl.formatMessage({
                id: "landing.readyTosetupYourStudio.city",
                defaultMessage: "City",
              })}
              errorProps={{
                textAlign: "text-right",
              }}
              value={city || ""}
              type={TailwindInputType.text}
              name={"city"}
              error={errors?.city || undefined}
              onChange={e => {
                clearError("city");
                updateRegistrationFormField("studio", e.target);
              }}
              onBlur={e => validate("city", e.target.value)}
            />
          </TailwindBox>
          <TailwindBox padding={["pl-1"]} width={"w-4/12"}>
            <TailwindInput
              required
              rounded={false}
              backgroundColor={"transparent"}
              borderColor={"border-black"}
              borderWidth={["border"]}
              label={intl.formatMessage({
                id: "landing.readyTosetupYourStudio.state",
                defaultMessage: "State",
              })}
              errorProps={{
                textAlign: "text-right",
              }}
              value={state || ""}
              type={TailwindInputType.text}
              name={"state"}
              error={errors?.state || undefined}
              onChange={e => {
                clearError("state");
                updateRegistrationFormField("studio", e.target);
              }}
              onBlur={e => validate("state", e.target.value)}
            />
          </TailwindBox>
        </TailwindFlex>

        <TailwindFlex margin={["mt-2", "mb-4"]} width={"w-full"}>
          <TailwindBox padding={["pr-1"]} width={"w-4/12"}>
            <TailwindInput
              required
              rounded={false}
              backgroundColor={"transparent"}
              borderColor={"border-black"}
              borderWidth={["border"]}
              label={intl.formatMessage({
                id: "landing.readyTosetupYourStudio.zipCode",
                defaultMessage: "Zipcode",
              })}
              errorProps={{
                textAlign: "text-right",
              }}
              value={zip_code || ""}
              type={TailwindInputType.text}
              name={"zip_code"}
              error={errors?.zip_code || undefined}
              onChange={e => {
                clearError("zip-code");
                updateRegistrationFormField("studio", e.target);
              }}
              onBlur={e => validate("zip_code", e.target.value)}
            />
          </TailwindBox>
          <TailwindFlex padding={["pl-1"]} width={"w-8/12"}>
            <TailwindDropdown
              width={"w-full"}
              focusBorderColor={"md:border-black"}
              backgroundColor={"bg-white"}
              selectedItemButtonProps={{
                backgroundColor: "bg-transparent",
              }}
              items={countryDropdownValues || []}
              borderColor={"border-black"}
              borderWidth={["border"]}
              fullWidth
              label={intl.formatMessage({
                id: "landing.readyTosetupYourStudio.country",
                defaultMessage: "Country",
              })}
              value={country}
              name={"country"}
              error={
                errors?.country ||
                authStore!.loginRegisterErrors.country ||
                undefined
              }
              onChange={value =>
                updateRegistrationFormField("studio", {
                  name: "country",
                  value,
                })
              }
              onClick={e => e.preventDefault()}
            />
          </TailwindFlex>
        </TailwindFlex>

        <TailwindFlex
          alignItems={"items-center"}
          justifyContent={"justify-between"}>
          <TailwindButton
            type={"submit"}
            showSpinner={isActionInProgress}
            disabled={isActionInProgress}
            fullWidth
            size={"lg"}
            backgroundColor={"primary"}
            justifyContent={"justify-center"}
            textColor={"text-white"}
            rounded={false}>
            <TailwindTranslatedText
              descriptor={{
                id: "common.next",
                defaultMessage: "Next",
              }}
              textTransform={"uppercase"}
              fontWeight={"font-bold"}
            />
          </TailwindButton>
        </TailwindFlex>
      </RecaptchaForm>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject(
    "authStore",
    "validationStore",
    "profileStore"
  )(observer(StudioAccountInfoStep))
);
