import React from "react";
import TailwindText from "library/components/_tailwind/text";
import TailwindCard from "library/components/_tailwind/card";
import MediaManagerStore from "containers/_components/media-manager/store/MediaManagerStore";
import { inject, observer } from "mobx-react";

type Props = {
  mediaManagerStore?: MediaManagerStore;
};

const MediaManagerNoMedia: React.ComponentType<Props> = ({
  mediaManagerStore,
}) => {
  const { getEmptyAlbumMessage } = mediaManagerStore!;

  return (
    <TailwindCard
      width={"w-full"}
      backgroundColor={"primary"}
      padding={["p-6"]}>
      <TailwindText
        width={"w-full"}
        textAlign={"text-center"}
        display={"block"}>
        {getEmptyAlbumMessage()}
      </TailwindText>
    </TailwindCard>
  );
};

export default inject("mediaManagerStore")(observer(MediaManagerNoMedia));
