import React from "react";
import "./styles.scss";
import classnames from "classnames";

type WinkingEmojiIconProps = {
  className?: string;
};

const WinkingEmojiIcon: React.ComponentType<WinkingEmojiIconProps> = ({
  className,
}) => (
  <span className={classnames("WinkingEmojiIcon", className && className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 22 22"
    >
      <g transform="translate(0, 0)">
        <g transform="translate(0, 0) rotate(0)">
          <path
            d="M11,0c-6.07521,0 -11,4.92479 -11,11c0,6.07521 4.92479,11 11,11c6.07521,0 11,-4.92479 11,-11c0,-6.07521 -4.92479,-11 -11,-11zM3.4375,9.39583c0,-1.51892 1.23108,-2.75 2.75,-2.75c1.51892,0 2.75,1.23108 2.75,2.75c0,1.51892 -1.23108,2.75 -2.75,2.75c-1.51892,0 -2.75,-1.23108 -2.75,-2.75zM11.13017,18.88333c-2.36638,0 -4.61129,-1.0725 -6.006,-2.86917c-0.18608,-0.24017 -0.14254,-0.58529 0.09717,-0.77183c0.23971,-0.18608 0.58529,-0.14254 0.77183,0.09717c1.188,1.53037 3.10842,2.44383 5.137,2.44383c3.48746,0 6.325,-2.63175 6.325,-5.86667c0,-0.30388 0.24612,-0.55 0.55,-0.55c0.30388,0 0.55,0.24613 0.55,0.55c0,3.84129 -3.33071,6.96667 -7.425,6.96667zM18.71513,6.88188c-0.13796,0.27087 -0.46887,0.37858 -0.73975,0.24062c-1.07433,-0.54725 -2.38012,-0.45054 -3.40771,0.25254c-0.92263,0.63112 -1.3915,1.58812 -1.28746,2.62671c0.03025,0.30204 -0.19021,0.572 -0.49225,0.60225c-0.01879,0.00183 -0.03713,0.00275 -0.05546,0.00275c-0.27912,0 -0.51792,-0.21175 -0.54679,-0.495c-0.14346,-1.42954 0.51471,-2.79217 1.76092,-3.64421c1.35988,-0.93042 3.09512,-1.05462 4.52788,-0.32496c0.27087,0.1375 0.37858,0.46842 0.24062,0.73929z"
            style={{
              strokeWidth: 0,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
            }}
            vectorEffect="non-scaling-stroke"
          />
        </g>
        <defs>
          <path
            id="path-161457021763826251"
            d="M11,0c-6.07521,0 -11,4.92479 -11,11c0,6.07521 4.92479,11 11,11c6.07521,0 11,-4.92479 11,-11c0,-6.07521 -4.92479,-11 -11,-11zM3.4375,9.39583c0,-1.51892 1.23108,-2.75 2.75,-2.75c1.51892,0 2.75,1.23108 2.75,2.75c0,1.51892 -1.23108,2.75 -2.75,2.75c-1.51892,0 -2.75,-1.23108 -2.75,-2.75zM11.13017,18.88333c-2.36638,0 -4.61129,-1.0725 -6.006,-2.86917c-0.18608,-0.24017 -0.14254,-0.58529 0.09717,-0.77183c0.23971,-0.18608 0.58529,-0.14254 0.77183,0.09717c1.188,1.53037 3.10842,2.44383 5.137,2.44383c3.48746,0 6.325,-2.63175 6.325,-5.86667c0,-0.30388 0.24612,-0.55 0.55,-0.55c0.30388,0 0.55,0.24613 0.55,0.55c0,3.84129 -3.33071,6.96667 -7.425,6.96667zM18.71513,6.88188c-0.13796,0.27087 -0.46887,0.37858 -0.73975,0.24062c-1.07433,-0.54725 -2.38012,-0.45054 -3.40771,0.25254c-0.92263,0.63112 -1.3915,1.58812 -1.28746,2.62671c0.03025,0.30204 -0.19021,0.572 -0.49225,0.60225c-0.01879,0.00183 -0.03713,0.00275 -0.05546,0.00275c-0.27912,0 -0.51792,-0.21175 -0.54679,-0.495c-0.14346,-1.42954 0.51471,-2.79217 1.76092,-3.64421c1.35988,-0.93042 3.09512,-1.05462 4.52788,-0.32496c0.27087,0.1375 0.37858,0.46842 0.24062,0.73929z"
            vectorEffect="non-scaling-stroke"
          />
        </defs>
      </g>
    </svg>
  </span>
);

export default WinkingEmojiIcon;
