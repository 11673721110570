import { action, makeObservable, observable } from "mobx";
import { api } from "core/utility";
import { IContest } from "./interfaces";
import { logger } from "library/core/utility";

export default class ContestStore {
  contests: IContest[] = [];
  loading = false;

  public getContests = async () => {
    this.loading = true;
    try {
      this.contests = await api.contests.get().data.data;
    } catch (e) {
      logger.log("get Contest error");
    } finally {
      this.loading = false;
    }
  };

  constructor() {
    makeObservable(this, {
      contests: observable,
      loading: observable,
      getContests: action,
    });
  }
}
