import React from "react";
import "./styles.scss";
import classnames from "classnames";

type SharedMediaIconAltProps = {
  className?: string;
};

const SharedMediaIconAlt: React.ComponentType<SharedMediaIconAltProps> = ({
  className,
}) => (
  <span className={classnames("SharedMediaIconAlt", className && className)}>
    <svg 
      version="1.2" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlnsXlink="http://www.w3.org/1999/xlink" 
      overflow="visible" 
      preserveAspectRatio="none" 
      viewBox="0 0 17 17">
        <g transform="translate(0, 0)">
          <g transform="translate(0, 0) rotate(0)">
            <path 
              d="M8.97813,11.79375h-0.15938v-2.92187h0.15938c0.31875,0 0.6375,0.15937 0.85,0.425c0.26563,0.26563 0.37187,0.6375 0.37187,1.00938c0,0.05312 0,0.05312 0,0.10625c-0.05313,0.85 -0.58438,1.43437 -1.22188,1.38125zM17,8.5c0,4.675 -3.825,8.5 -8.5,8.5c-4.675,0 -8.5,-3.825 -8.5,-8.5c0,-4.675 3.825,-8.5 8.5,-8.5c4.675,0 8.5,3.825 8.5,8.5zM8.81875,8.18125v-2.92187h0.15938c0.69063,0 1.22188,0.6375 1.22188,1.43437c0,0.2125 0.15937,0.37188 0.31875,0.37188c0.15938,0 0.31875,-0.15938 0.31875,-0.37188c0,-1.22187 -0.79687,-2.17812 -1.80625,-2.17812h-0.15938v-1.0625c0,-0.2125 -0.15938,-0.37187 -0.31875,-0.37187c-0.15938,0 -0.31875,0.15937 -0.31875,0.37187v1.00938h-0.10625c-1.00937,0 -1.85937,0.95625 -1.9125,2.23125v0.05313c0,1.54063 1.16875,2.07188 1.75313,2.125h0.2125v2.92188c-0.31875,0 -0.58437,-0.10625 -0.85,-0.26562c-0.37187,-0.26562 -0.58438,-0.69063 -0.53125,-1.16875c0,-0.2125 -0.15938,-0.37187 -0.31875,-0.37187c-0.15938,0 -0.31875,0.15938 -0.31875,0.37187c0,0.74375 0.31875,1.43438 0.85,1.80625c0.37187,0.26563 0.74375,0.37188 1.16875,0.37188v0.95625c0,0.2125 0.15938,0.37188 0.31875,0.37188c0.15937,0 0.31875,-0.15938 0.31875,-0.37188v-0.95625h0.15938c0.95625,0 1.75313,-0.90313 1.80625,-2.01875c0,-0.05312 0,-0.10625 0,-0.15938c0,-0.58437 -0.2125,-1.16875 -0.58437,-1.59375c-0.31875,-0.37187 -0.79687,-0.58437 -1.22188,-0.58437zM8.075,5.25938c-0.69063,0 -1.275,0.6375 -1.275,1.4875v0.05313c0,1.275 1.16875,1.38125 1.22188,1.38125h0.15938v-2.92187z" 
              vectorEffect="non-scaling-stroke"/>
          </g>
          <defs>
            <path 
              id="path-1715031144015744" 
              d="M8.97813,11.79375h-0.15938v-2.92187h0.15938c0.31875,0 0.6375,0.15937 0.85,0.425c0.26563,0.26563 0.37187,0.6375 0.37187,1.00938c0,0.05312 0,0.05312 0,0.10625c-0.05313,0.85 -0.58438,1.43437 -1.22188,1.38125zM17,8.5c0,4.675 -3.825,8.5 -8.5,8.5c-4.675,0 -8.5,-3.825 -8.5,-8.5c0,-4.675 3.825,-8.5 8.5,-8.5c4.675,0 8.5,3.825 8.5,8.5zM8.81875,8.18125v-2.92187h0.15938c0.69063,0 1.22188,0.6375 1.22188,1.43437c0,0.2125 0.15937,0.37188 0.31875,0.37188c0.15938,0 0.31875,-0.15938 0.31875,-0.37188c0,-1.22187 -0.79687,-2.17812 -1.80625,-2.17812h-0.15938v-1.0625c0,-0.2125 -0.15938,-0.37187 -0.31875,-0.37187c-0.15938,0 -0.31875,0.15937 -0.31875,0.37187v1.00938h-0.10625c-1.00937,0 -1.85937,0.95625 -1.9125,2.23125v0.05313c0,1.54063 1.16875,2.07188 1.75313,2.125h0.2125v2.92188c-0.31875,0 -0.58437,-0.10625 -0.85,-0.26562c-0.37187,-0.26562 -0.58438,-0.69063 -0.53125,-1.16875c0,-0.2125 -0.15938,-0.37187 -0.31875,-0.37187c-0.15938,0 -0.31875,0.15938 -0.31875,0.37187c0,0.74375 0.31875,1.43438 0.85,1.80625c0.37187,0.26563 0.74375,0.37188 1.16875,0.37188v0.95625c0,0.2125 0.15938,0.37188 0.31875,0.37188c0.15937,0 0.31875,-0.15938 0.31875,-0.37188v-0.95625h0.15938c0.95625,0 1.75313,-0.90313 1.80625,-2.01875c0,-0.05312 0,-0.10625 0,-0.15938c0,-0.58437 -0.2125,-1.16875 -0.58437,-1.59375c-0.31875,-0.37187 -0.79687,-0.58437 -1.22188,-0.58437zM8.075,5.25938c-0.69063,0 -1.275,0.6375 -1.275,1.4875v0.05313c0,1.275 1.16875,1.38125 1.22188,1.38125h0.15938v-2.92187z" 
              vectorEffect="non-scaling-stroke"/>
          </defs>
        </g>
    </svg>
  </span>
);

export default SharedMediaIconAlt;
