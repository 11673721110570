import React from "react";
import { inject, observer } from "mobx-react";
import LanguageStore from "library/core/stores/language/LanguageStore";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";

interface Props {
  languageStore?: LanguageStore;
}

const OBSModalTabStep1: React.FunctionComponent<Props> = ({languageStore}) => {

  const { intl } = languageStore!;

  return (
    <TailwindTranslatedText
        paragraphProps={{
          margin: ["mb-2"],
        }}
        descriptor={{
          id: "broadcast-obs-modal.step-1-body",
          defaultMessage:
          "To begin broadcasting in HD, your computer needs to be able to run the OBS software. Click {here} to see OBS computer requirements.",
        }}
        values={{
          here: `<a class='text-indigo-700' href='https://help.streamray.com/models/knowledgebase/how-to-use-obs-for-broadcasting-in-hd-high-definition/' target='new'> ${intl.formatMessage({
            id: "payout.payoutSettings.here",
            defaultMessage: "Here",
          })} </a>`
        }}
      />
  );
};

export default inject(
    "languageStore"
  )(observer(OBSModalTabStep1));
