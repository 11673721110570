import React, { useEffect } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import ModelStore from "containers/studio/studio-models/stores/models/ModelStore";
import ModelTable from "./models-management/model-table";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindHeading from "library/components/_tailwind/heading";
import TailwindButton from "library/components/_tailwind/button";
import TailwindDropdown from "library/components/_tailwind/dropdown";
import TailwindText from "library/components/_tailwind/text";
import { FILTER_MODELS_LOGIN_TIME } from "./stores/models/interfaces";
import { lastUpdatePeriod, MODEL_TIMER_INTERVAL } from "./stores/models/consts";

type ModelsListProps = {
  modelStore?: ModelStore;
};

const CamsStudioModelsListContainer: React.ComponentType<
  ModelsListProps & WrappedComponentProps
> = ({ intl, modelStore }) => {
  const {
    getModels,
    pollModels,
    registerNewModel,
    setModelsFilter,
    models,
    sortedModels,
    activeModelFilter,
  } = modelStore!;

  useEffect(() => {
    getModels();
    const modelTimer = setInterval(pollModels, MODEL_TIMER_INTERVAL);

    return () => {
      clearInterval(modelTimer);
    };
  }, []);

  return (
    <TailwindFlex flexDirection={"flex-col"} height={"h-full"}>
      <TailwindFlex
        justifyContent={"justify-between"}
        alignItems={"items-center"}
        padding={["py-2"]}
        margin={["mb-4"]}>
        <TailwindFlex justifyContent={"justify-start"} width={"w-auto"}>
          <TailwindHeading level={5}>Models</TailwindHeading>
          <TailwindDropdown
            fullWidth={false}
            fullHeight={true}
            height={"h-12"}
            width={"w-48"}
            margin={["ml-4"]}
            backgroundColor={"primary"}
            items={lastUpdatePeriod}
            placeholder={"Show By Activity"}
            value={activeModelFilter}
            name={"studio-models-by-activity"}
            onChange={selected =>
              setModelsFilter(selected as FILTER_MODELS_LOGIN_TIME)
            }
          />
          <TailwindFlex alignItems={"items-center"}>
            <TailwindText
              alignItems={"items-center"}
              whiteSpace={"whitespace-nowrap"}
              margin={["ml-4"]}
              fontSize={"text-lg"}>
              Listed:&nbsp;
              <TailwindText fontSize={"text-lg"} fontWeight={"font-bold"}>
                {sortedModels.length}
              </TailwindText>
            </TailwindText>

            <TailwindText
              padding={["pl-2"]}
              className={"border-l-2 border-black"}
              margin={["ml-2"]}
              alignItems={"items-center"}
              whiteSpace={"whitespace-nowrap"}
              fontSize={"text-lg"}>
              Total Registered:&nbsp;
              <TailwindText fontSize={"text-lg"} fontWeight={"font-bold"}>
                {models.length}
              </TailwindText>
            </TailwindText>
          </TailwindFlex>
        </TailwindFlex>

        <TailwindButton
          backgroundColor={"bg-blue-400"}
          fullWidth={false}
          rounded={false}
          textProps={{
            textAlign: "text-center",
            textColor: "text-white",
          }}
          onClick={() => registerNewModel()}>
          {intl.formatMessage({
            id: "studio-model-management.add-new-model",
            defaultMessage: "Add New Model",
          })}
        </TailwindButton>
      </TailwindFlex>
      <TailwindFlex
        flexGrow={"flex-grow"}
        flexDirection={"flex-col"}
        height={"h-full"}
        overflow={["overflow-hidden"]}>
        <ModelTable />
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject("modelStore")(observer(CamsStudioModelsListContainer))
);
