import { ThemeColorPalette } from "./types";
import Color from "color";
import { ThemeColorContrast } from "./enums";
import { getUpdatedColorPalette } from "./utils";

export const DEFAULT_CAMS_THEME_COLOR_CONTRAST = ThemeColorContrast.light;

/* DARK */
const camsDarkTitleColor1 = "#111";
const camsDarkTitleColor2 = "#fff";
const camsDarkSkyBlueColor = "#1f1f1f";
const camsDarkPinkColor = "#da0070";
const camsDarkStrongColor = "#fff";
const camsDarkBorderColor1 = "#465bca";
const camsDarkPrimaryBgColor = "#1f1f1f";
const camsDarkSecondaryBgColor = "#43172e";
const camsDarkAccentBgColor = "#313131";
const camsDarkSidebarTextColor = "#fff";
const camsDarkSidebarActiveHoverColor = "#da0070";
const camsDarkSidebarBorderRadius = "30px";
const camsDarkHeaderTextColor = "#fff";
const camsDarkEarningsBgColor = "#1a353b";
const camsDarkBodyPadding = "10px";
const camsDarkHeaderHeight = "100px";
const camsDarkCardBorderRadius = "20px";
const camsDarkTabActiveTextColor = "#ef951b";
const camsDarkTabActiveTabLineColor = "#ef951b";
const camsDarkTabsBottomBorderColor = "#ef951b";
const camsDarkFeedCardBgColor = "#2a2d36";
const camsDarkFeedCardCommentBgColor = "#fff";
const camsDarkFeedCardCommentBorderRadius = "10px";

export const DEFAULT_CAMS_DARK_THEME_COLOR_PALETTE: ThemeColorPalette = {
  "primary-bg-color": camsDarkPrimaryBgColor,
  "primary-bg-color-darker": Color(camsDarkPrimaryBgColor).darken(0.1).hex(),
  "primary-bg-color-rgb": Color(camsDarkPrimaryBgColor).rgb().array(),
  "secondary-bg-color": camsDarkSecondaryBgColor,
  "secondary-bg-color-darker": Color(camsDarkSecondaryBgColor)
    .darken(0.3)
    .hex(),
  "tertiary-bg-color": camsDarkPrimaryBgColor,
  "quaternary-bg-color": "#e9eef2",
  "textarea-primary-color": camsDarkPrimaryBgColor,
  "secondary-bg-color-rgb": Color(camsDarkSecondaryBgColor).rgb().array(),
  "table-header-primary-bg-color": "#1e242b",
  "table-secondary-color": "border-black",
  "avatar-primary-bg-color": camsDarkPrimaryBgColor,
  "avatar-secondary-bg-color": camsDarkSecondaryBgColor,
  "card-primary-bg-color": "#fff",
  "go-live-gradient":
    "linear-gradient(90deg, rgba(19,12,138,1) 0%, rgba(155,20,136,1) 54%, rgba(204,2,149,1) 100%)",
  "go-live-text": "#000",
  "card-secondary-bg-color": "#1e242b",
  "toggle-disabled-color": "#1e242b",
  "earnings-bg-color": camsDarkEarningsBgColor,
  "tab-not-selected-color": camsDarkEarningsBgColor,
  "card-primary-bg-color-tw-class": "bg-gray-500",
  "card-secondary-bg-color-tw-class": "bg-gray-900",
  "dropdown-primary-bg-color-tw-class": "bg-pink-600",
  "dropdown-secondary-bg-color-tw-class": "bg-gray-900",
  "dropdown-primary-text-color-tw-class": "text-pink-600",
  "dropdown-secondary-text-color-tw-class": "text-pink-800",
  "dropdown-primary-focus-button-border-color-tw-class": "border-black",
  "dropdown-secondary-focus-button-border-color-tw-class": "border-black",
  "dropdown-primary-multiple-counter-background-color-tw-class": "bg-black",
  "dropdown-secondary-multiple-counter-background-color-tw-class": "bg-black",
  "dropdown-primary-multiple-counter-text-color-tw-class": "text-black",
  "dropdown-secondary-multiple-counter-text-color-tw-class": "text-black",
  "dropdown-primary-multiple-checkbox-border-color-tw-class": "border-white",
  "dropdown-secondary-multiple-checkbox-border-color-tw-class": "border-white",
  "input-primary-bg-color-tw-class": "bg-gray-100",
  "text-main-color": "#fff",
  "text-secondary-color": "#5c5c5c",
  "input-secondary-bg-color-tw-class": "bg-gray-900",
  "input-primary-text-color-tw-class": "text-black",
  "input-secondary-text-color-tw-class": "text-white",
  "input-primary-placeholder-color-tw-class": "placeholder-black",
  "input-secondary-placeholder-color-tw-class": "placeholder-gray-700",

  "input-error-text-color-tw-class": "text-red-500",
  "input-error-border-color-tw-class": "border-red-500",
  "input-warning-text-color-tw-class": "text-yellow-500",
  "input-warning-border-color-tw-class": "border-yellow-500",
  "input-disabled-background-color-tw-class": "bg-gray-500",
  "input-disabled-text-color-tw-class": "text-white",
  borderColor: "#2e2b66",
  "border-color-contrast": "#9092A6",
  "contrasting-bg-color": "#FFFFFF",
  "link-color": "#9092A6",
  "link-hover-active-color": "#ef951b",
  "modal-button-color": "#f472b7",
  "strong-color": camsDarkStrongColor,
  "strong-color-rgb": Color(camsDarkStrongColor).rgb().array(),
  "title-color-1": camsDarkTitleColor1,
  "title-color-1-rgb": Color(camsDarkTitleColor1).rgb().array(),
  "title-color-1-inversed": Color(camsDarkTitleColor1).negate().hex(),
  "title-color-1-darken-10": Color(camsDarkTitleColor1).darken(0.1).hex(),
  "title-color-2": camsDarkTitleColor2,
  "title-color-2-rgb": Color(camsDarkTitleColor2).rgb().array(),
  "title-color-3": "#8c94c1",
  "title-color-4": "#7e8bd9",
  "title-color-5": "#d5e8ff",
  "title-color-6": "#999",
  "body-color-1": "#FFFFFF",
  "body-color-2": "#999",
  "faded-color": "#a5a5a5",
  "scroll-bg-color": "#000",
  "scroll-bar-color": "#da0070",
  "scroll-bar-color-2": "#111",
  "scroll-bg-color-3": "#111",
  "orange-color": "#ef951b",
  "red-color": "#cd264f",
  "green-color": "#28a745",
  "error-red": "#ff1744",
  "border-color-1": camsDarkBorderColor1,
  "border-color-1-rgb": Color(camsDarkBorderColor1).rgb().array(),
  "sky-blue-color": camsDarkSkyBlueColor,
  "sky-blue-color-rgb": Color(camsDarkSkyBlueColor).rgb().array(),
  "pink-color": camsDarkPinkColor,
  "pink-color-rgb": Color(camsDarkPinkColor).rgb().array(),
  "darker-card-color": "#262a4f",
  "transparent-bg-color": "rgba(#262a4f,.3)",
  "image-overlay-bg-color": "rgba(#161833,.7)",
  "upload-overlay-bg-color": "#4D516E",
  "transparent-overlay-color": "rgba(#000000,.7)",
  "snackbar-bg": "#42476D",
  "snackbar-border": "#666",
  "image-editor-sidebar-bg": "#21244d",
  "image-editor-sidebar-header-bg": "#1e1f47",
  "yellow-color": "#eddc1f",
  "input-border-radius": "5px",
  "media-border-radius": "4px",
  "sidebar-width": "220px",
  "sidebar-border-right-color": "transparent",
  "sidebar-bg-color": camsDarkAccentBgColor,
  "sidebar-text-color": camsDarkSidebarTextColor,
  "sidebar-text-active-hover-color": camsDarkSidebarActiveHoverColor,
  "sidebar-border-radius": camsDarkSidebarBorderRadius,
  "header-bg-color": camsDarkAccentBgColor,
  "header-text-color": camsDarkHeaderTextColor,
  "header-height": camsDarkHeaderHeight,
  "logo-color": camsDarkTitleColor1,
  "body-padding": camsDarkBodyPadding,
  "body-bg-color": camsDarkAccentBgColor,
  "card-border-radius": camsDarkCardBorderRadius,
  "tab-active-tab-line-color": camsDarkTabActiveTabLineColor,
  "tab-active-text-color": camsDarkTabActiveTextColor,
  "tabs-bottom-border-color": camsDarkTabsBottomBorderColor,
  "feed-card-bg-color": camsDarkFeedCardBgColor,
  "feed-card-comment-bg-color": camsDarkFeedCardCommentBgColor,
  "feed-card-comment-border-radius": camsDarkFeedCardCommentBorderRadius,
  "form-elements-font-size": "1rem",
  "sidebar-text-font-size": "1rem",
  "dropdown-border-radius-tw-class": "rounded",
  "input-border-radius-tw-class": "rounded",
  "media-border-radius-tw-class": "rounded",
  "media-background-color-tw-class": "bg-red-500",
  "button-primary-bg-color-tw-class": "bg-pink-600",
  "button-secondary-bg-color-tw-class": "bg-blue-600",
  "button-tertiary-bg-color-tw-class": "bg-red-100",
  "button-primary-text-color-tw-class": "text-white",
  "button-secondary-text-color-tw-class": "text-white",
  "button-tertiary-text-color-tw-class": "text-black",
  "button-non-rounded-border-radius-tw-class": "rounded",
  "button-rounded-border-radius-tw-class": "rounded-full",
  "button-disabled-background-color-tw-class": "bg-gray-600",
  "gray-button": "rgb(75, 85, 99)",
  "button-disabled-text-color-tw-class": "text-white",
  "loading-indicator-accent-color": "#fff",
  "loading-indicator-bg-color": "#fff",
  "highlight-color": camsDarkPrimaryBgColor,
  "lightGreen-bg-color": "#00b326",
  "lightPink-bg-color": "#f472b7",
  "lightRed-bg-color": "#ff0000",
  "lightGray-bg-color": "#9e9e9e",
  "lightPurple-bg-color": "#5c0099",
};

/* LIGHT */
const camsLightTitleColor1 = "#000";
const camsLightTitleColor2 = "#8c94c1";
const camsLightSkyBlueColor = "#3B70EB";
const camsLightPinkColor = "#f472b7";
const camsLightStrongColor = "#000";
const camsLightBorderColor1 = "#000";
const camsLightPrimaryBgColor = "#fff";
const camsLightBodyBgColor = "#dddddd";
const camsLightTertiaryBgColor = "#f5f5f5";
const camsLightControlColor = "#edf2f5";
const camsLightSecondaryBgColor = "#fbd5e7";
const camsLightSidebarTextColor = "#000";
const camsLightSidebarActiveHoverColor = "#303257";
const camsLightSidebarBorderRadius = "0px";
const camsLightHeaderTextColor = "#000";
const camsLightBodyPadding = "0px";
const camsLightHeaderHeight = "100px";
const camsLightCardBorderRadius = "20px";
const camsLightFeedCardBgColor = "#ef951b";
const camsLightFeedCardCommentBgColor = "#ef951b";
const camsLightEarningsBgColor = "#d4e8ee";
const camsLightFeedCardCommentBorderRadius = "10px";

export const DEFAULT_CAMS_LIGHT_THEME_COLOR_PALETTE: ThemeColorPalette = {
  "primary-bg-color": camsLightPrimaryBgColor,
  "primary-bg-color-darker": Color(camsLightPrimaryBgColor).darken(0.5).hex(),
  "primary-bg-color-rgb": Color(camsLightPrimaryBgColor).rgb().array(),
  "secondary-bg-color": camsLightSecondaryBgColor,
  "secondary-bg-color-darker": Color(camsLightSecondaryBgColor)
    .darken(0.3)
    .hex(),
  "secondary-bg-color-rgb": Color(camsLightSecondaryBgColor).rgb().array(),
  "earnings-bg-color": camsLightEarningsBgColor,
  "tertiary-bg-color": camsLightTertiaryBgColor,
  "quaternary-bg-color": "#e9eef2",
  "table-header-primary-bg-color": "#cce8ee",
  "table-secondary-color": "#ebf2f5",
  "toggle-disabled-color": "#9ca3af",
  "avatar-primary-bg-color": "#84a3ba",
  "avatar-secondary-bg-color": "#f472b7",
  "card-primary-bg-color": camsLightSecondaryBgColor,
  "card-secondary-bg-color": camsLightControlColor,
  "card-primary-bg-color-tw-class": "bg-gray-100",
  "card-secondary-bg-color-tw-class": "bg-gray-300",

  "dropdown-primary-bg-color-tw-class": "bg-gray-100",
  "go-live-gradient":
    "linear-gradient(90deg, rgba(138,150,242,1) 0%, rgba(244,114,183,1) 54%, rgba(237,85,207,1) 100%)",
  "go-live-text": "#fff",
  "dropdown-secondary-bg-color-tw-class": "bg-gray-300",
  "dropdown-primary-text-color-tw-class": "text-black",
  "dropdown-secondary-text-color-tw-class": "text-black",
  "dropdown-primary-focus-button-border-color-tw-class": "border-black",
  "dropdown-secondary-focus-button-border-color-tw-class": "border-black",
  "dropdown-primary-multiple-counter-background-color-tw-class": "bg-black",
  "dropdown-secondary-multiple-counter-background-color-tw-class": "bg-black",
  "dropdown-primary-multiple-counter-text-color-tw-class": "text-white",
  "dropdown-secondary-multiple-counter-text-color-tw-class": "text-white",
  "dropdown-primary-multiple-checkbox-border-color-tw-class": "border-black",
  "dropdown-secondary-multiple-checkbox-border-color-tw-class": "border-black",
  "tab-not-selected-color": "#9EA3AF",
  "input-primary-bg-color-tw-class": "bg-gray-100",
  "text-main-color": "#000",
  "text-secondary-color": "#5c5c5c",
  "textarea-primary-color": "#fafafa",
  "input-secondary-bg-color-tw-class": "bg-gray-300",
  "input-primary-text-color-tw-class": "text-black",
  "input-secondary-text-color-tw-class": "text-black",
  "input-error-text-color-tw-class": "text-red-500",
  "input-error-border-color-tw-class": "border-red-500",
  "input-warning-text-color-tw-class": "text-yellow-500",
  "input-warning-border-color-tw-class": "border-yellow-500",
  "input-primary-placeholder-color-tw-class": "placeholder-gray-700",
  "input-secondary-placeholder-color-tw-class": "placeholder-gray-700",
  "input-disabled-background-color-tw-class": "bg-gray-50",
  "input-disabled-text-color-tw-class": "text-gray-400",
  borderColor: Color(camsLightSecondaryBgColor).darken(0.3).hex(),
  "border-color-contrast": "#9092A6",
  "contrasting-bg-color": "#FFFFFF",
  "link-color": "#9092A6",
  "link-hover-active-color": "#715c20",
  "strong-color": camsLightStrongColor,
  "strong-color-rgb": Color(camsLightStrongColor).rgb().array(),
  "title-color-1": camsLightTitleColor1,
  "title-color-1-rgb": Color(camsLightTitleColor1).rgb().array(),
  "title-color-1-inversed": Color(camsLightTitleColor1).negate().hex(),
  "title-color-1-darken-10": Color(camsLightTitleColor1).darken(0.1).hex(),
  "title-color-2": camsLightTitleColor2,
  "title-color-2-rgb": Color(camsLightTitleColor2).rgb().array(),
  "title-color-3": "#8c94c1",
  "title-color-4": "#7e8bd9",
  "title-color-5": "#000",
  "title-color-6": "#999",
  "body-color-1": "#FFFFFF",
  "modal-button-color": "#f472b7",
  "body-color-2": "#999",
  "faded-color": "#a5a5a5",
  "scroll-bg-color": "#fff",
  "scroll-bar-color": "gray",
  "scroll-bar-color-2": "#000",
  "scroll-bg-color-3": "#fff",
  "orange-color": "#ef951b",
  "red-color": "#cd264f",
  "green-color": "#28a745",
  "error-red": "#ff1744",
  "border-color-1": camsLightBorderColor1,
  "border-color-1-rgb": Color(camsLightBorderColor1).rgb().array(),
  "sky-blue-color": camsLightSkyBlueColor,
  "sky-blue-color-rgb": Color(camsLightSkyBlueColor).rgb().array(),
  "pink-color": camsLightPinkColor,
  "pink-color-rgb": Color(camsLightPinkColor).rgb().array(),
  "darker-card-color": "#9092A6",
  "transparent-bg-color": "rgba(#262a4f,.3)",
  "image-overlay-bg-color": "rgba(#161833,.7)",
  "upload-overlay-bg-color": "#4D516E",
  "transparent-overlay-color": "rgba(#000000,.7)",
  "snackbar-bg": "#fff",
  "snackbar-border": "#000",
  "image-editor-sidebar-bg": "#c5c5d2",
  "image-editor-sidebar-header-bg": "#c5c5d2",
  "yellow-color": "#eddc1f",
  "input-border-radius": "5px",
  "media-border-radius": "4px",
  "sidebar-width": "220px",
  "sidebar-border-right-color": "transparent",
  "sidebar-bg-color": camsLightBodyBgColor,
  "sidebar-text-color": camsLightSidebarTextColor,
  "sidebar-text-active-hover-color": camsLightSidebarActiveHoverColor,
  "sidebar-border-radius": camsLightSidebarBorderRadius,
  "header-bg-color": camsLightBodyBgColor,
  "header-text-color": camsLightHeaderTextColor,
  "header-height": camsLightHeaderHeight,
  "logo-color": "#a15f7e",
  "body-padding": camsLightBodyPadding,
  "body-bg-color": "#ddd",
  "card-border-radius": camsLightCardBorderRadius,
  "tab-active-tab-line-color": "#303257",
  "tab-active-text-color": "#303257",
  "tabs-bottom-border-color": "#303257",
  "feed-card-bg-color": camsLightFeedCardBgColor,
  "feed-card-comment-bg-color": camsLightFeedCardCommentBgColor,
  "feed-card-comment-border-radius": camsLightFeedCardCommentBorderRadius,
  "form-elements-font-size": "1rem",
  "sidebar-text-font-size": "1rem",
  "dropdown-border-radius-tw-class": "rounded",
  "input-border-radius-tw-class": "rounded",
  "media-border-radius-tw-class": "rounded",
  "media-background-color-tw-class": "bg-gray-500",
  "button-primary-bg-color-tw-class": "bg-pink-600",
  "button-secondary-bg-color-tw-class": "bg-blue-400",
  "button-tertiary-bg-color-tw-class": "bg-gray-100",
  "button-primary-text-color-tw-class": "text-white",
  "button-secondary-text-color-tw-class": "text-white",
  "button-tertiary-text-color-tw-class": "text-white",
  "button-non-rounded-border-radius-tw-class": "rounded",
  "button-rounded-border-radius-tw-class": "rounded-full",
  "button-disabled-background-color-tw-class": "bg-gray-50",
  "gray-button": "#fafafa",
  "button-disabled-text-color-tw-class": "text-gray-400",
  "loading-indicator-accent-color": "#fbd5e7",
  "loading-indicator-bg-color": "#fff",
  "highlight-color": "#d8e8ef",
  "lightGreen-bg-color": "#00b326",
  "lightPink-bg-color": "#f472b7",
  "lightRed-bg-color": "#ff0000",
  "lightGray-bg-color": "#9e9e9e",
  "lightPurple-bg-color": "#5c0099",
};

export const CamsDarkThemePalette = (
  colors: ThemeColorPalette
): ThemeColorPalette =>
  getUpdatedColorPalette(DEFAULT_CAMS_DARK_THEME_COLOR_PALETTE, colors);

export const CamsLightThemePalette = (
  colors: ThemeColorPalette
): ThemeColorPalette =>
  getUpdatedColorPalette(DEFAULT_CAMS_LIGHT_THEME_COLOR_PALETTE, colors);
