import { PAYOUT_ITEMS } from "common/earnings/_stores/earnings/consts";
import EarningsStore from "common/earnings/_stores/earnings/EarningsStore";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindTranslatedNumber from "library/components/_tailwind/translated-number";
import { inject, observer } from "mobx-react";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";

type Props = {
  earningsStore?: EarningsStore;
  profileStore?: ProfileStore;
};

const PayoutOverview = ({ earningsStore }: Props) => {
  const intl = useIntl();
  const currentPeriodSums = earningsStore!.currentPeriodSums!;

  const rows = useMemo(() => {
    return PAYOUT_ITEMS;
  }, []);

  return (
    <TailwindFlex
      flexDirection={"flex-row"}
      height={"h-full"}
      width={"w-full"}
      textColor='text-main-color'
      borderRadius={"rounded-xl"}
      justifyContent='justify-around'
      backgroundColor={"bg-primary-bg-color"}
      padding={["p-4"]}>
      <TailwindText fontWeight='font-bold' textTransform='uppercase'>
        {intl.formatMessage({
          id: "payout.member.title",
          defaultMessage: "SEE HOW YOUR EARNINGS ADD UP",
        })}
      </TailwindText>

      <TailwindFlex
        margin={["my-1"]}
        justifyContent='justify-between'
        alignItems='items-center'
        flexDirection={"flex-row"}>
        <TailwindFlex flex='flex-1'>
          <TailwindText></TailwindText>
        </TailwindFlex>
        <TailwindText
          fontWeight='font-bold'
          width='w-20'
          textAlign='text-right'>
          {intl.formatMessage({
            id: "payout.member.spend-title",
            defaultMessage: "SPEND",
          })}
        </TailwindText>
        <TailwindText
          fontWeight='font-bold'
          width='w-20'
          textAlign='text-right'>
          {intl.formatMessage({
            id: "payout.member.spend-title",
            defaultMessage: "PAYOUT",
          })}
        </TailwindText>
      </TailwindFlex>

      {rows.map((item, index) => (
        <TailwindFlex
          margin={["my-1"]}
          key={index}
          justifyContent='justify-between'
          alignItems='items-center'
          flexDirection={"flex-row"}>
          <TailwindFlex flex='flex-1'>
            <TailwindText fontWeight='font-bold'>
              {intl.formatMessage(item.labelIntl)}
            </TailwindText>
          </TailwindFlex>
          <TailwindText width='w-20' textAlign='text-right'>
            <TailwindTranslatedNumber
              value={+(currentPeriodSums?.[item.spendKey] || 0)}
              style='currency'
              currency='USD'
            />
          </TailwindText>

          <TailwindText width='w-20' textAlign='text-right'>
            <TailwindTranslatedNumber
              value={+(currentPeriodSums?.[item.payoutKey] || 0)}
              style='currency'
              currency='USD'
            />
          </TailwindText>
        </TailwindFlex>
      ))}

      <TailwindFlex
        margin={["my-1"]}
        justifyContent='justify-between'
        alignItems='items-center'
        flexDirection={"flex-row"}>
        <TailwindFlex flex='flex-1'>
          <TailwindText fontWeight='font-bold'>
            {intl.formatMessage({
              id: "payout.member.tiered_member_spend_total",
              defaultMessage: "Chargebacks / Refunds",
            })}
          </TailwindText>
        </TailwindFlex>
        <TailwindText width='w-20' textAlign='text-right'></TailwindText>

        <TailwindText width='w-20' textAlign='text-right'>
          <TailwindTranslatedNumber
            textProps={{
              textColor: "text-red-500",
            }}
            value={-(currentPeriodSums?.payoutAdjustment || 0)}
            style='currency'
            currency='USD'
          />
        </TailwindText>
      </TailwindFlex>

      <TailwindFlex
        borderColor='border-main-color'
        borderWidth={["border-t"]}
        padding={["pt-2"]}
        margin={["my-1"]}
        justifyContent='justify-between'
        alignItems='items-center'
        flexDirection={"flex-row"}>
        <TailwindFlex flex='flex-1'>
          <TailwindText fontWeight='font-bold'>
            {intl.formatMessage({
              id: "payout.member.grand_total_label",
              defaultMessage: "GRAND TOTAL:",
            })}
          </TailwindText>
        </TailwindFlex>
        <TailwindText width='w-32' textAlign='text-right'>
          <TailwindTranslatedNumber
            textProps={{
              fontSize: "text-2xl",
              fontWeight: "font-bold",
            }}
            value={+(currentPeriodSums?.grandTotal || 0)}
            style='currency'
            currency='USD'
          />
        </TailwindText>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default inject(
  "earningsStore",
  "profileStore"
)(observer(PayoutOverview));
