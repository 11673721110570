import React, { useCallback } from "react";
import { inject, observer } from "mobx-react";
import "./styles.scss";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindDropdown, {
  TailwindDropdownValue,
} from "library/components/_tailwind/dropdown";
import EarningsStore from "../../_stores/earnings/EarningsStore";
import { Period } from "../../_stores/earnings/types";
import HistoricalEarnings from "../../historical-earnings";
import { PayoutDropdown } from "../payout-dropdown";
import TailwindButton from "library/components/_tailwind/button";
import TailwindBox from "library/components/_tailwind/box";
import config from "core/config";
import AuthStore from "core/stores/auth/AuthStore";

type PayoutDetailsProps = {
  earningsStore?: EarningsStore;
};

const PayoutDetails: React.FunctionComponent<PayoutDetailsProps> = ({
  earningsStore
}) => {
  const { currentPeriodId, periods, setModelCurrentPeriod } = earningsStore!;

  const onChangePeriod = useCallback((value: TailwindDropdownValue) => {
    setModelCurrentPeriod(value as number);
  }, []);

  const onDownloadClicked = useCallback(() => {
    const token = AuthStore.getAccessToken();
    window.open(`${config.walletApiUrl}/wallet/model/periods/${currentPeriodId}/transactions/csv?token=${token}`)
  }, [currentPeriodId]);

  return (
    <TailwindFlex flexDirection={"flex-col"}>
      <TailwindFlex
        margin={["mb-4"]}
        alignItems={"items-center"}
        justifyContent={"justify-between"}
        width={"w-full"}>
        <TailwindFlex
          margin={["mb-4"]}
          alignItems={"items-center"}
          width={"w-auto"}>
          <TailwindFlex width={"w-auto"} margin={["mr-4"]}>
            <TailwindTranslatedText
              descriptor={{
                id: "modelEarnings.title",
                defaultMessage: "Earnings Report",
              }}
              headingProps={{ level: 5 }}
            />
          </TailwindFlex>

          <TailwindFlex width={"w-auto"} display={"hidden"}>
            <PayoutDropdown />
          </TailwindFlex>
        </TailwindFlex>
        <HistoricalEarnings classNames={"ml-4"} />
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <p>
          This report shows all the different ways you can collect tokens and
          how they add up to your total earnings.
        </p>
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <p>
          Below is your current earnings report for this bi-weekly pay period.
          If you have questions, please <a href='#'>contact us</a>.
        </p>
      </TailwindFlex>
      <TailwindFlex justifyContent='justify-start' flexDirection='flex-row'>
        <TailwindBox>
          <TailwindDropdown
            fullHeight={false}
            fullWidth={false}
            height={"h-10"}
            shape={"square"}
            alignItems='items-start'
            backgroundColor={"bg-primary-bg-color"}
            name={"model-earning-year-pay-period"}
            value={currentPeriodId}
            onChange={onChangePeriod}
            items={(periods as Period[]).map(period => ({
              label: period.title || "",
              value: period.id,
            }))}
          />
        </TailwindBox>

        <TailwindButton
          margin={["ml-4"]}
          pseudoClasses={["sm:w-full", "sm:m-0"]}
          fullWidth={false}
          onClick={onDownloadClicked}
          backgroundColor={"primary"}
          rounded={false}
          alignItems={"items-center"}
          textProps={{
            textColor: "text-white",
            textTransform: "capitalize",
          }}>
          <TailwindTranslatedText
            paragraphProps={{
              textTransform: "capitalize",
              fontWeight: "font-bold",
              textColor: "text-white",
            }}
            fontWeight={"font-bold"}
            descriptor={{
              id: "modelEarnings.download",
              defaultMessage: "Download",
            }}
          />
        </TailwindButton>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default inject("earningsStore")(observer(PayoutDetails));
