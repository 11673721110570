import React from "react";
import TailwindCard from "library/components/_tailwind/card";
import TailwindFlex from "library/components/_tailwind/flex";
import BlockedCountriesTable from "./table-blocked-countries";
import BlockedMembersTable from "./table-blocked-members";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";

type BlockUnblock = {};
const BlockUnblock: React.FunctionComponent<BlockUnblock> = ({}) => {
  return (
    <TailwindFlex
      height={"h-full"}
      pseudoClasses={["md:h-auto", "md:flex-col"]}>
      <TailwindFlex
        width={"w-full"}
        md={"w-full"}
        margin={["mr-2"]}
        maxWidth={"max-w-5xl"}
        pseudoClasses={["md:m-0"]}>
        <TailwindCard
          display={"flex"}
          flexDirection={"flex-col"}
          width={"w-full"}>
          <TailwindTranslatedText
            headingProps={{ level: 5, margin: ["mb-2"] }}
            descriptor={{
              id: "accountSettings.blockUnblock.title",
              defaultMessage: "Blocks",
            }}
          />
          <TailwindTranslatedText
            textColor='text-main-color'
            descriptor={{
              id: "accountSettings.blockUnblock.description",
              defaultMessage:
                "You can block members either by their username or location below",
            }}
            margin={["mb-3"]}
          />
          <BlockedCountriesTable />
          <BlockedMembersTable />
        </TailwindCard>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default BlockUnblock;
