import { inject, observer } from "mobx-react";
import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import TailwindFlex from "library/components/_tailwind/flex";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import BroadcastStreamTypeSelector from "common/broadcast/broadcast-preview/broadcast-stream-type-selector";
import { BroadcastStreamType } from "common/broadcast/_stores/broadcast-stream/enums";
import BroadcastObsInstructions from "common/broadcast/broadcast-preview/broadcast-obs-instructions";
import BroadcastWebrtcStatus from "common/broadcast/broadcast-preview/broadcast-webrtc-status";
import BroadcastStore from "common/broadcast/_stores/broadcast/BroadcastStore";
import { BroadcastStreamState } from "common/broadcast/_stores/broadcast/enums";
import BroadcastStreamTypeSwitcher from "common/broadcast/broadcast-preview/broadcast-stream-type-switcher";

type Props = {
  broadcastStreamStore?: BroadcastStreamStore;
  broadcastStore?: BroadcastStore;
};

const BroadcastStartInstruction: React.FunctionComponent<
  Props & WrappedComponentProps
> = ({
  broadcastStreamStore,
  broadcastStore,
  //intl,
}) => {
  const { streamState } = broadcastStore!;
  const { streamType, mediaStream, obsStreamConnected } = broadcastStreamStore!;

  if (
    streamState === BroadcastStreamState.started ||
    (streamType === BroadcastStreamType.WEBRTC && mediaStream) ||
    (streamType === BroadcastStreamType.OBS && obsStreamConnected)
  ) {
    return null;
  }

  return (
    <TailwindFlex
      padding={["p-4"]}
      height={"h-full"}
      width={"w-full"}
      alignItems={"items-center"}
      justifyContent={"justify-center"}
      flexDirection={"flex-col"}
      position={"relative"}>
      <BroadcastStreamTypeSwitcher />
      {!streamType ? (
        <BroadcastStreamTypeSelector />
      ) : streamType === BroadcastStreamType.OBS ? (
        <BroadcastObsInstructions />
      ) : (
        <BroadcastWebrtcStatus />
      )}
    </TailwindFlex>
  );
};

export default injectIntl(
  inject(
    "broadcastStreamStore",
    "broadcastStore"
  )(observer(BroadcastStartInstruction))
);
