import React, { FunctionComponent } from "react";
import AuthStore from "core/stores/auth/AuthStore";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import "./styles.scss";
import ProfileStore from "../my-page/stores/profile/ProfileStore";
import { CAMS_SUPPORT_PHONE_NUMBER } from "library/core/config/urls";

type AccountClosedContainerProps = {
  authStore?: AuthStore;
  profileStore?: ProfileStore;
};

const AccountClosedContainer: FunctionComponent<AccountClosedContainerProps & WrappedComponentProps> = ({
  intl,
  authStore,
  profileStore,
}) => {
  const { accountSuspensionReason, userRole } = authStore!;

  const { profile } = profileStore!;

  const phoneNumbers = (
    <ul>
      <li>
        {CAMS_SUPPORT_PHONE_NUMBER} (
        {intl.formatMessage({
          id: `blocked-login-error.local`,
          defaultMessage: "Local",
        })}
        )
      </li>
      <li>
        (888)546-0188 (
        {intl.formatMessage({
          id: `blocked-login-error.toll-free-domestic`,
          defaultMessage: "Toll free domestic",
        })}
        )
      </li>
    </ul>
  );

  return (
    <div className='blocked-login-error'>
      <p>
        {intl.formatMessage({
          id: `blocked-login-error.dear`,
          defaultMessage: "Dear",
        })}{" "}
        {profile?.username},
      </p>

      {(accountSuspensionReason === "account_cancelled" || accountSuspensionReason === "account_closed") && (
        <React.Fragment>
          <p>
            {intl.formatMessage({
              id: `blocked-login-error.your-account-closed`,
              defaultMessage: "Your account has been closed. Please contact support if you wish to reactivate it:",
            })}
          </p>
          {phoneNumbers}
        </React.Fragment>
      )}

      {accountSuspensionReason === "account_suspended" && (
        <React.Fragment>
          <p>
            {intl.formatMessage({
              id: `blocked-login-error.there-is-something-wrong`,
              defaultMessage: "There is something wrong with your account.",
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: `blocked-login-error.for-further-assistance`,
              defaultMessage: "For further assistance, please contact the compliance department:",
            })}
          </p>
          {phoneNumbers}
          <p>
            {intl.formatMessage({
              id: `blocked-login-error.or-email`,
              defaultMessage: "or email",
            })}
            {userRole === "model" && <span> model_compliance@streamray.com</span>}
            {userRole === "studio" && <span> studio_compliance@streamray.com</span>}
          </p>
          <p>
            {intl.formatMessage({
              id: `blocked-login-error.the-cams-stuff`,
              defaultMessage: "The Cams Staff",
            })}
          </p>
        </React.Fragment>
      )}
      {(accountSuspensionReason === "account_cancelled" || accountSuspensionReason === "account_closed") && (
        <p>
          {intl.formatMessage({
            id: `blocked-login-error.sincerely`,
            defaultMessage: "Sincerely",
          })}
          ,<br />
          {intl.formatMessage({
            id: `blocked-login-error.the-cams-team`,
            defaultMessage: "The Cams Team",
          })}
        </p>
      )}
    </div>
  );
};

export default injectIntl(inject("authStore")(observer(AccountClosedContainer)));
