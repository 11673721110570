import React from "react";
import "./styles.scss";
import classnames from "classnames";

type PointsIconProps = {
  className?: string;
};

const PointsIcon: React.ComponentType<PointsIconProps> = ({ className }) => (
  <span className={classnames("PointsIcon", className && className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 20 17"
    >
      <g transform="translate(0, 0)">
        <g transform="translate(0, 0) rotate(0)">
          <path
            d="M5.12195,12.38571c0.29268,1.02 0.82927,1.94286 1.5122,2.76857c-0.29268,0 -0.68293,0 -0.97561,0c-3.12195,0 -5.65854,-0.87429 -5.65854,-1.99143c0,-0.53429 0.58537,-0.97143 1.5122,-1.36c0.92683,0.34 2.19512,0.58286 3.60976,0.58286zM0,9.27714c0,-0.48571 0.63415,-0.97143 1.5122,-1.36c0.87805,0.34 2,0.48571 3.26829,0.53429c0,0.38857 -0.09756,0.72857 -0.09756,1.11714c0,0.63143 0.09756,1.11714 0.2439,1.65143c-2.78049,-0.09714 -4.82927,-0.97143 -4.92683,-1.94286zM0,1.74857c0,-0.97143 2.53659,-1.7 5.65854,-1.74857c3.12195,0 5.65854,0.72857 5.65854,1.7c0,0.24286 -0.14634,0.43714 -0.43902,0.68c-0.82927,0.14571 -1.56098,0.43714 -2.2439,0.82571c-0.87805,0.14571 -1.85366,0.24286 -2.97561,0.24286c-3.12195,0 -5.65854,-0.72857 -5.65854,-1.7zM0,5.48857c0,-0.53429 0.58537,-0.97143 1.46341,-1.31143c1.07317,0.29143 2.53659,0.53429 4.19512,0.53429c0.39024,0 0.82927,-0.09714 1.21951,-0.09714c-0.78049,0.82571 -1.36585,1.79714 -1.65854,2.81714c-2.92683,-0.09714 -5.21951,-0.97143 -5.21951,-1.94286zM20,10c0,3.87234 -3.12766,7 -7,7c-3.87234,0 -7,-3.12766 -7,-7c0,-3.87234 3.12766,-7 7,-7c3.87234,0 7,3.12766 7,7zM15.92908,8.46099c0,-0.74468 -0.24823,-1.34043 -0.74468,-1.73759c-0.49645,-0.39716 -1.19149,-0.59574 -2.1844,-0.59574h-2.23404v7.79433h1.29078v-2.92908h0.79433c0.99291,0 1.73759,-0.19858 2.28369,-0.64539c0.49645,-0.39716 0.79433,-1.04255 0.79433,-1.88652zM14.29268,7.52857c0.2439,0.19429 0.39024,0.53429 0.39024,0.97143c0,0.43714 -0.14634,0.77714 -0.43902,1.02c-0.29268,0.24286 -0.78049,0.34 -1.41463,0.34h-0.68293v-2.67143h0.87805c0.58537,0 1.02439,0.09714 1.26829,0.34z"
            style={{
              strokeWidth: 0,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
            }}
            vectorEffect="non-scaling-stroke"
          />
        </g>
        <defs>
          <path
            id="path-1614639756584198279"
            d="M5.12195,12.38571c0.29268,1.02 0.82927,1.94286 1.5122,2.76857c-0.29268,0 -0.68293,0 -0.97561,0c-3.12195,0 -5.65854,-0.87429 -5.65854,-1.99143c0,-0.53429 0.58537,-0.97143 1.5122,-1.36c0.92683,0.34 2.19512,0.58286 3.60976,0.58286zM0,9.27714c0,-0.48571 0.63415,-0.97143 1.5122,-1.36c0.87805,0.34 2,0.48571 3.26829,0.53429c0,0.38857 -0.09756,0.72857 -0.09756,1.11714c0,0.63143 0.09756,1.11714 0.2439,1.65143c-2.78049,-0.09714 -4.82927,-0.97143 -4.92683,-1.94286zM0,1.74857c0,-0.97143 2.53659,-1.7 5.65854,-1.74857c3.12195,0 5.65854,0.72857 5.65854,1.7c0,0.24286 -0.14634,0.43714 -0.43902,0.68c-0.82927,0.14571 -1.56098,0.43714 -2.2439,0.82571c-0.87805,0.14571 -1.85366,0.24286 -2.97561,0.24286c-3.12195,0 -5.65854,-0.72857 -5.65854,-1.7zM0,5.48857c0,-0.53429 0.58537,-0.97143 1.46341,-1.31143c1.07317,0.29143 2.53659,0.53429 4.19512,0.53429c0.39024,0 0.82927,-0.09714 1.21951,-0.09714c-0.78049,0.82571 -1.36585,1.79714 -1.65854,2.81714c-2.92683,-0.09714 -5.21951,-0.97143 -5.21951,-1.94286zM20,10c0,3.87234 -3.12766,7 -7,7c-3.87234,0 -7,-3.12766 -7,-7c0,-3.87234 3.12766,-7 7,-7c3.87234,0 7,3.12766 7,7zM15.92908,8.46099c0,-0.74468 -0.24823,-1.34043 -0.74468,-1.73759c-0.49645,-0.39716 -1.19149,-0.59574 -2.1844,-0.59574h-2.23404v7.79433h1.29078v-2.92908h0.79433c0.99291,0 1.73759,-0.19858 2.28369,-0.64539c0.49645,-0.39716 0.79433,-1.04255 0.79433,-1.88652zM14.29268,7.52857c0.2439,0.19429 0.39024,0.53429 0.39024,0.97143c0,0.43714 -0.14634,0.77714 -0.43902,1.02c-0.29268,0.24286 -0.78049,0.34 -1.41463,0.34h-0.68293v-2.67143h0.87805c0.58537,0 1.02439,0.09714 1.26829,0.34z"
            vectorEffect="non-scaling-stroke"
          />
        </defs>
      </g>
    </svg>
  </span>
);

export default PointsIcon;
