import { CustomIconName } from "../icon/icons/enums";
import { TBorderColor, TTextColor } from "../../../styles/tailwind-classnames";
import React, { useMemo, useState } from "react";
import TailwindFlex, { TailwindFlexProps } from "../flex";
import TailwindIcon from "../icon";

export type AFFProfileActionButtonVariant = "outline" | "contained";
export type AFFProfileActionButtonProps = {
  icon: CustomIconName | string | undefined;
  iconHoverColor?: TTextColor;
  borderColor?: TBorderColor;
  variant?: AFFProfileActionButtonVariant;
};

const AffProfileActionButton: React.ComponentType<AFFProfileActionButtonProps> = ({
  icon,
  iconHoverColor,
  variant = "outline",
  borderColor,
}) => {
  const [isHover, setIsHover] = useState(false);
  const props: TailwindFlexProps = useMemo(() => {
    if (variant == "contained") {
      return {
        backgroundColor: "bg-white",
      };
    } else {
      return {
        backgroundColor: "bg-transparent",
        borderWidth: ["border-2"],
      };
    }
  }, [variant]);
  return (
    <TailwindFlex
      justifyContent={"justify-center"}
      alignItems={"items-center"}
      width={"w-9"}
      height={"h-9"}
      borderRadius={"rounded-full"}
      backgroundOpacity={"bg-opacity-20"}
      as={"TailwindButton"}
      cursor={"cursor-pointer"}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      borderColor={isHover && variant == "outline" ? borderColor : "border-white"}
      {...props}>
      <TailwindIcon
        name={icon}
        fontSize={"text-sm"}
        textColor={isHover && variant == "contained" ? iconHoverColor : "text-white"}
      />
    </TailwindFlex>
  );
};

export default AffProfileActionButton;
