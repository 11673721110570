import React, { useCallback } from "react";
import { ModelAlbum } from "containers/_components/media-manager/store/types";
import TailwindHeading from "library/components/_tailwind/heading";
import { inject, observer } from "mobx-react";
import MediaManagerStore from "containers/_components/media-manager/store/MediaManagerStore";
import RouteStore from "core/stores/route/RouteStore";
import { AppCamsModelRouteKey } from "core/stores/route/enums";
import { history } from "library/core/utility";
import {
  ModelMediaManagerMode,
  ModelMediaRouteMode,
} from "containers/_components/media-manager/store/enums";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindBox from "library/components/_tailwind/box";
import TailwindButton from "library/components/_tailwind/button";
import { Link } from "react-router-dom";
import TailwindIcon from "library/components/_tailwind/icon";

type Props = {
  mediaManagerStore?: MediaManagerStore;
  routeStore?: RouteStore;
};

const MediaManagerSidebarHeaderTitle: React.ComponentType<Props> = ({
  mediaManagerStore,
  routeStore,
}) => {
  const {
    getAlbumPageLeftColumnTitle,
    createDraftAlbum,
    createAlbum,
    selectedAlbum,
    managerMode,
    routeMode,
  } = mediaManagerStore!;
  const { getSiteRouteURL } = routeStore!;

  const onClickCreateAlbum = useCallback(async () => {
    const draftAlbum: ModelAlbum = createDraftAlbum();
    const createdAlbum = await createAlbum(draftAlbum);
    history.push(
      `${getSiteRouteURL(AppCamsModelRouteKey.myPhotosEdit)}/${createdAlbum.id}`
    );
  }, []);

  return (
    <React.Fragment>
      {routeMode === ModelMediaRouteMode.PHOTOS ? (
        <React.Fragment>
          {managerMode === ModelMediaManagerMode.LIST && (
            <TailwindFlex
              alignItems={"items-center"}
              justifyContent={"justify-start"}
              margin={["mb-2"]}
              pseudoClasses={["md:justify-between"]}>
              <TailwindFlex width={"w-auto"} alignItems={"items-center"}>
                <TailwindHeading width={"w-full"} level={5}>
                  Photo Albums
                </TailwindHeading>
              </TailwindFlex>
              <TailwindBox display={"flex"} width={"w-auto"} margin={["ml-4"]}>
                <TailwindButton
                  backgroundColor={"bg-gray-button"}
                  rounded={false}
                  textProps={{
                    fontSize: "text-base",
                  }}
                  fullWidth={false}
                  height={"h-10"}
                  margin={["mr-4"]}
                  onClick={onClickCreateAlbum}>
                  Create
                </TailwindButton>
              </TailwindBox>
            </TailwindFlex>
          )}
          {managerMode === ModelMediaManagerMode.EDIT && (
            <TailwindHeading margin={["mb-6"]} level={5}>
              <Link
                to={getSiteRouteURL(AppCamsModelRouteKey.myPhotosList)}
                className='flex align-middle'>
                <TailwindIcon
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }} 
                  margin={["mr-2"]}
                  name={"arrowLeft"}></TailwindIcon> 
                {"My Photo Albums"}
              </Link>
            </TailwindHeading>
          )}
        </React.Fragment>
      ) : (
        <TailwindHeading margin={["mb-6"]} level={5}>
          {selectedAlbum
            ? getAlbumPageLeftColumnTitle(selectedAlbum)
            : "Manage Album"}
        </TailwindHeading>
      )}
    </React.Fragment>
  );
};

export default inject(
  "mediaManagerStore",
  "routeStore"
)(observer(MediaManagerSidebarHeaderTitle));
