import React, { useCallback, useState } from "react";
import { Icon } from "@material-ui/core";
import "./style.scss";
import QAWidgetTheming from "./theming";
import QAWidgetSeo from "./SEO";
import QAWidgetProfile from "./profile";
import QABroadcaster from "./Viewer";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTabs from "library/components/_tailwind/tabs";
import { Tab } from "library/components/_tailwind/tabs/types";
import QAWidgetDevices from "containers/_layout/_components/qa-widget/devices";
import QAWidgetBroadcastShowTrail from "containers/_layout/_components/qa-widget/show-trail";
import QAWidgetMisc from "containers/_layout/_components/qa-widget/misc";
import { MODAL_WRAPPER_CLASSNAME } from "library/core/stores/modal/consts";
import UpcomingFeatures from "./upcoming-features";

type Props = {};

const QAWidget: React.ComponentType<Props> = () => {
  const [shouldShowWidget, setShouldShowWidget] = useState<boolean>(false);

  const tabs: Tab[] = [
    {
      id: "1",
      label: "Theming",
      component: <QAWidgetTheming />,
    },
    {
      id: "2",
      label: "SEO",
      component: <QAWidgetSeo />,
    },
    {
      id: "3",
      label: "Profile",
      component: <QAWidgetProfile />,
    },
    {
      id: "4",
      label: "Broadcaster",
      component: <QABroadcaster />,
    },
    {
      id: "5",
      label: "Devices",
      component: <QAWidgetDevices />,
    },
    {
      id: "6",
      label: "Show Trail",
      component: <QAWidgetBroadcastShowTrail />,
    },
    {
      id: "7",
      label: "Misc",
      component: <QAWidgetMisc />,
    },
    {
      id: "8",
      label: "Upcoming Features",
      component: <UpcomingFeatures />,
    },
  ];

  const onChangeShouldShowWidget = useCallback(() => {
    setShouldShowWidget(!shouldShowWidget);
  }, [shouldShowWidget]);

  const [activeTabId, setActiveTabId] = useState<number>(0);

  const onTabChange = useCallback(tab => {
    setActiveTabId(tab);
  }, []);

  return (
    <TailwindFlex
      width={"w-auto"}
      className={["FeatureSwitch__Cobrand"]}
      padding={!shouldShowWidget ? ["p-0"] : undefined}>
      <TailwindFlex
        display={shouldShowWidget ? "flex" : "hidden"}
        height={"h-full"}>
        <TailwindTabs
          onChange={value => onTabChange(value)}
          activeTabIndex={activeTabId}
          tabs={tabs}
          height={"h-full"}
          headerProps={{
            fitWidthAndScrollHorizontally: true,
          }}
          bodyProps={{
            flexGrow: "flex-grow",
            flexDirection: "flex-col",
            height: "h-full",
            className: [MODAL_WRAPPER_CLASSNAME, "FeatureSwitch__Cobrand__tab"],
          }}
        />
      </TailwindFlex>
      <div
        className='FeatureSwitch__Cobrand-hide-button'
        onClick={onChangeShouldShowWidget}>
        <Icon>code</Icon>
      </div>
    </TailwindFlex>
  );
};

export default QAWidget;
