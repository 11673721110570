import React, { useMemo } from "react";
import TailwindText, { TailwindTextProps } from "../_tailwind/text";
import TailwindBox, { TailwindBoxProps } from "../_tailwind/box";

export type ProfileOnlineStatusProps = TailwindBoxProps & {
  status?: "online" | "broadcasting" | "in-chat";
  textProps?: TailwindTextProps;
};

const ProfileOnlineStatus: React.ComponentType<ProfileOnlineStatusProps> = ({
  status,
  textProps,
  ...props
}) => {
  const statusText = useMemo(() => {
    switch (status) {
      case "online":
        return "Online";
      case "broadcasting":
        return "Broadcasting";
      case "in-chat":
        return "In Chat";
    }
  }, [status]);
  return (
    <TailwindBox
      {...props}
      backgroundImage={"bg-gradient-to-r"}
      gradientColorStops={["from-blue-500", "to-green-500"]}
      borderRadius={"rounded-full"}
      padding={["px-2"]}
    >
      <TailwindText
        {...textProps}
        fontSize={"text-base"}
        fontWeight={"font-bold"}
      >
        {statusText}
      </TailwindText>
    </TailwindBox>
  );
};

export default ProfileOnlineStatus;
