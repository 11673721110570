import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import TailwindAnchor from "../../components/_tailwind/anchor";

type Props = {};

const StorybookConventionsInstallingPackages: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Conventions &gt; Installing Packages</StorybookHeading>
      <StorybookParagraph>
        Due to overhead Installing packages is should be discussed with the lead
        of the project unless the package is already an established React
        package in the industry such as react-router, lodash etc but please
        check in any case so that the architecture is maintainable because there
        are already some in-house solutions that we unfortunately had to
        reinvent the wheel of; because of some constraints & decisions taken.
        The main packages used are:
      </StorybookParagraph>
      <StorybookParagraph>
        - TailwindCSS. Refer to{" "}
        <TailwindAnchor to={"/architecture/tailwindcss"}>
          TailwindCSS page
        </TailwindAnchor>{" "}
        for configuration of Tailwind as well as the architecture of components.
      </StorybookParagraph>
      <StorybookParagraph>
        - Material-UI (the idea is to get rid of this as soon as possible by
        gradually rewriting a component library that is created from scratch
        with TailwindCSS and replacing existing components)
      </StorybookParagraph>
      <StorybookParagraph>
        - axios (src/utility/api.ts has a Facade for axios, basically like a
        ApiService for REST endpoints we have. The Endpoints are differentiated
        in there via userRole so that the calls end up being made to correct
        URLs). Refer to{" "}
        <TailwindAnchor to={"/architecture/utilities#api"}>
          API Facade Utility
        </TailwindAnchor>{" "}
        for more info about the use of axios.
      </StorybookParagraph>
      <StorybookParagraph>
        - date-fns (there is src/utility/date-process.ts that we had implemented
        that we need to get rid of ASAP. Anything inside that is not in date-fns
        will need to be refactored into static functions)
      </StorybookParagraph>
      <StorybookParagraph>
        - history for changing routes programmatically. Refer to{" "}
        <TailwindAnchor to={"/architecture/routes"}>Routes page</TailwindAnchor>{" "}
        for more info about route structure as well as the use of history page.
      </StorybookParagraph>
      <StorybookParagraph>
        - hls.js for some of the streaming & video features we have as well as
        Nano proprietary video player for streaming only
      </StorybookParagraph>
      <StorybookParagraph>- lodash</StorybookParagraph>
      <StorybookParagraph>
        - MobX for State management. Refer to{" "}
        <TailwindAnchor to={"/architecture/routes"}>MobX page</TailwindAnchor>{" "}
        for more info about store structure as well as the use of MobX.
      </StorybookParagraph>
      <StorybookParagraph>
        - react-helmet for Meta management
      </StorybookParagraph>
      <StorybookParagraph>
        - react-image for lazy loading & error handling images (there is{" "}
        <Code inline>{`<Image />`}</Code> component in components)
      </StorybookParagraph>
      <StorybookParagraph>
        - react-intl for translations. Refer to{" "}
        <TailwindAnchor to={"/architecture/internalization-and-localization"}>
          Internalization & Localization page
        </TailwindAnchor>{" "}
        for more info about i18n structure as well as the components that can be
        used for i18n purposes.
      </StorybookParagraph>
      <StorybookParagraph>
        - react-masonry-css for masonry style layout
      </StorybookParagraph>
      <StorybookParagraph>
        - react-swipeable-views & its utils for swipeable cards (there is a
        component for that in components called{" "}
        <Code inline>{`<SwipeableSlider />`}</Code>)
      </StorybookParagraph>
      <StorybookParagraph>
        - validator.js. Refer to{" "}
        <TailwindAnchor to={"/architecture/mobx-and-stores#validation-store"}>
          Mobx & Stores page
        </TailwindAnchor>{" "}
        for more info about the ValidationStore Facade.
      </StorybookParagraph>
    </StorybookWrapper>
  );
};

export default StorybookConventionsInstallingPackages;
