import React, { useMemo } from "react";
import TailwindBox, {
  TailwindBoxProps,
} from "../../../components/_tailwind/box";

export enum TailwindListType {
  "ul" = "ul",
  "ol" = "ol",
}

type TailwindListProps = TailwindBoxProps & {
  type?: TailwindListType;
};
const TailwindList: React.ComponentType<TailwindListProps> = ({
  type,
  children,
  ...props
}) => {
  const listStyleType = useMemo(() => {
    switch (type) {
      case "ol":
        return "list-decimal";
      case "ul":
        return "list-disc";
      default:
        return "list-none";
    }
  }, [type]);
  return (
    <TailwindBox {...props} as={type} listStyleType={listStyleType}>
      {children}
    </TailwindBox>
  );
};
export default TailwindList;
