import React, { ImgHTMLAttributes, ReactNode, useMemo } from "react";
import { Img, ImgProps } from "react-image";
import ImageUnloader from "./unloader";
import ImageLoader from "./loader";
import shortid from "shortid";

export type ImageProps = {
  src: string;
  fallbacksrc?: string;
  brokenPlaceholderChildren?: ReactNode;
  showFallback?: boolean;
  showUnloader?: boolean;
  showLoader?: boolean;
} & ImgHTMLAttributes<HTMLImageElement> &
  ImgProps;

const Image: React.ComponentType<ImageProps> = ({
  src,
  fallbacksrc,
  brokenPlaceholderChildren,
  showFallback = true,
  showUnloader = true,
  showLoader = true,
  unloader,
  loader,
  ...props
}) => {
  const srcList = useMemo(() => {
    const list: string[] = [];

    if (src) {
      list.push(src);
      if (fallbacksrc) {
        list.push(fallbacksrc);
      }
    }
    return list;
  }, [src, fallbacksrc]);

  const key = useMemo(() => {
    return shortid.generate();
  }, [srcList])

  const _props = useMemo(() => {
    const adjustedProps: any = props;
    if (showUnloader) {
      adjustedProps.unloader = unloader || (
        <ImageUnloader>{brokenPlaceholderChildren || "This image is broken."}</ImageUnloader>
      );
    }

    if (showLoader) {
      adjustedProps.loader = loader || <ImageLoader />;
    }

    if (showFallback) {
      adjustedProps.fallbacksrc = fallbacksrc || "images/broken-image-placeholder.jpg";
    }

    return adjustedProps;
  }, [brokenPlaceholderChildren, showUnloader, showLoader, unloader, loader, showFallback, fallbacksrc, props]);

  //key is required so that when src changes img is rerendered
  //https://github.com/mbrevda/react-image/issues/356
  return <Img src={srcList} key={key} {..._props} />;
};

export default Image;
