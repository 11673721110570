import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import { inject, observer } from "mobx-react";
import { IStudioModel } from "../stores/models/interfaces";
import StudioModelManagementModelAccessRightsModal from "./access-rights-modal";
import ModelStore from "../stores/models/ModelStore";
import ModalStore from "library/core/stores/modal";
import ModelTableRow from "./model-table-row";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindSpinner from "library/components/_tailwind/spinner";

type Props = {
  modelStore?: ModelStore;
  modalStore?: ModalStore;
};

const ModelsTableBody: React.ComponentType<Props> = ({
  modelStore,
  modalStore,
}) => {
  const { isLoadingModels, models, impersonateModel, sortedModels } =
    modelStore!;
  const { openPrimaryModal } = modalStore!;

  const openAccessRightsModal = (model: IStudioModel) => {
    openPrimaryModal(
      <StudioModelManagementModelAccessRightsModal model={model} />
    );
  };

  if (isLoadingModels) {
    return (
      <TailwindFlex
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        width={"w-full"}
        height={"h-full"}>
        <TailwindSpinner />
      </TailwindFlex>
    );
  }

  if (!isLoadingModels && models.length && !sortedModels.length) {
    return (
      <TailwindFlex
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        width={"w-full"}
        height={"h-full"}>
        <TailwindTranslatedText
          descriptor={{
            id: "studio-model-management.model-list.empty-filter",
            defaultMessage:
              "There are no models for the selected period of time.",
          }}
        />
      </TailwindFlex>
    );
  }

  if (!isLoadingModels && !models.length) {
    return (
      <TailwindFlex
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        width={"w-full"}
        height={"h-full"}>
        <TailwindTranslatedText
          descriptor={{
            id: "studio-model-management.model-list.empty-state",
            defaultMessage: "You didn't add any models yet",
          }}
        />
      </TailwindFlex>
    );
  }

  return (
    <TailwindFlex
      height={"h-full"}
      flexDirection={"flex-col"}
      overflow={["overflow-y-auto", "overflow-x-hidden"]}>
      {sortedModels.map(model => (
        <TailwindFlex
          key={model.modelId}
          borderColor={"border-table-secondary-color"}
          borderStyle={"border-solid"}
          borderWidth={["border-b"]}>
          <ModelTableRow
            model={model}
            openAccessRightsModal={openAccessRightsModal}
            loginAs={impersonateModel}
          />
        </TailwindFlex>
      ))}
    </TailwindFlex>
  );
};

export default inject("modelStore", "modalStore")(observer(ModelsTableBody));
