import React from "react";
import "./styles.scss";

type LogoutIconProps = {
  className?: string;
};

const LogoutIcon: React.ComponentType<LogoutIconProps> = ({ className }) => (
  <span className={`${className} camsIcon`}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
      <path d='M77.74,78.15a39.82,39.82,0,1,1-28.15-68A39.56,39.56,0,0,1,77.74,21.85a2.5,2.5,0,0,0,3.54-3.54A44.82,44.82,0,1,0,49.59,94.82,44.56,44.56,0,0,0,81.28,81.69a2.5,2.5,0,0,0-3.54-3.54Z' />
      <path d='M93.79,46.41h0L65.2,23.5a3.55,3.55,0,0,0-3.77-.45,4.42,4.42,0,0,0-2.34,4v12H37a5.07,5.07,0,0,0-5.07,5.07V55.87A5.07,5.07,0,0,0,37,60.94H59.09v12a4.42,4.42,0,0,0,2.34,4,3.51,3.51,0,0,0,3.77-.46l28.59-22.9a4.79,4.79,0,0,0,0-7.18ZM64.09,71V58.44a2.49,2.49,0,0,0-2.5-2.5l-24.65-.07L37,44.06H61.59a2.49,2.49,0,0,0,2.5-2.5V29L90.27,50Z' />
    </svg>
  </span>
);

export default LogoutIcon;
