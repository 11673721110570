import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindInputBase, {
  TailwindInputType,
} from "../../components/_tailwind/input-base";

type Props = {};

const StorybookComponentsInputBase: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Input Base</StorybookHeading>
      <StorybookParagraph>
        Input Base is a bare input element that has no opinions or styling. It
        is used for thinks like TailwindCheckboxRadio as well as the regular
        input element we have called TailwindInput. TailwindInput is also
        created from TailwindInputBase.
      </StorybookParagraph>
      <StorybookParagraph>
        TailwindInputBase has some ease-of-access via left and rightIconProps
        for easily adding icons and customizing them. It can also be wrapped
        with a TailwindBox for easily styling the input's wrapper via{" "}
        <Code inline>wrapInput: boolean & boxProps: TailwindBoxProps</Code>
      </StorybookParagraph>
      <StorybookParagraph>
        <strong>
          TailwindInputBase should only be used to create other kind of Inputs
          such as a datepicker or a password input (which you already can do
          with TailwindInput already)
        </strong>
      </StorybookParagraph>
      <StorybookParagraph>
        It takes type prop that you can show different type of inputs easily:
      </StorybookParagraph>
      <Code>{`"button" = "button",
"checkbox" = "checkbox",
"color" = "color",
"date" = "date",
"datetime-local" = "datetime-local",
"email" = "email",
"file" = "file",
"hidden" = "hidden",
"image" = "image",
"month" = "month",
"number" = "number",
"password" = "password",
"radio" = "radio",
"range" = "range",
"reset" = "reset",
"search" = "search",
"submit" = "submit",
"tel" = "tel",
"text" = "text",
"time" = "time",
"url" = "url",
"week" = "week",
      
`}</Code>
      <StorybookParagraph>
        Currently it takes the following props:
      </StorybookParagraph>
      <Code>{`type?: TailwindInputType;
name?: string;
id?: string;
label?: React.ReactNode;
labelProps?: TailwindTextProps;
leftIconProps?: TailwindIconProps;
rightIconProps?: TailwindIconProps;
appearance?: TAppearance;
transitionProperty?: TTransitionProperty;
cursor?: TCursor;
width?: TWidth;
height?: THeight;
borderRadius?: TBorderRadius;
outlineColor?: TOutline;
backgroundColor?: TBackgroundColor;
opacity?: TOpacity;
position?: TPosition;
psuedoClasses?: TPseudoClasses[];
checked?: boolean;
zIndex?: TZIndex;
placeholder?: string;
placeholderOpacity?: TPlaceholderOpacity;
placeholderColor?: TPlaceholderColor;
onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
wrapInput?: boolean;
wrapperProps?: TailwindBoxProps;
boxProps?: TailwindBoxProps;
flexGrow?: TFlexGrow;
fontSize?: TFontSize;
fontWeight?: TFontWeight;
textDecoration?: TTextDecoration;
autoFocus?: boolean;
value?: string;
className?: (string | undefined | boolean)[];
disabled?: boolean;
src?: string;
`}</Code>
      <StorybookParagraph>
        There are not many examples to show:
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Checkbox"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.checkbox}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
  }}
/>
`}>
        <TailwindInputBase
          label={"Checkbox"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.checkbox}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Color"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.color}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
  }}
/>
`}>
        <TailwindInputBase
          label={"Color"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.color}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Date"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.date}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"Date"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.date}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"DateTime Local"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType["datetime-local"]}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"DateTime Local"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType["datetime-local"]}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Email"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.email}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"Email"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.email}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"File"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.file}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"File"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.file}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Hidden"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.hidden}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"Hidden"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.hidden}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Image"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.image}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"Image"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.image}
          src={"https://www.w3schools.com/tags/submit.gif"}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Month"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.month}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"Month"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.month}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Number"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.number}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"Number"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.number}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Password"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.password}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"Password"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.password}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Radio"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.radio}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"Radio"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.radio}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Range"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.range}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"Range"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.range}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Reset"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.reset}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"Reset"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.reset}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Search"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.search}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"Search"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.search}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Submit"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.submit}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"Submit"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.submit}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Telephone"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.tel}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"Telephone"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.tel}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Text"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.text}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"Text"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.text}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Time"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.time}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    timeColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"Time"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.text}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"URL"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.url}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"URL"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.url}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindInputBase
  label={"Week"}
  labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
  type={TailwindInputType.week}
  wrapperProps={{
    display: "flex",
    flexWrap: "flex-wrap",
    alignItems: "items-center",
    textColor: "text-main-color",
  }}
/>
`}>
        <TailwindInputBase
          label={"Week"}
          labelProps={{ width: "w-auto", margin: ["mr-2", "mb-0"] }}
          type={TailwindInputType.week}
          wrapperProps={{
            display: "flex",
            flexWrap: "flex-wrap",
            alignItems: "items-center",
            textColor: "text-main-color",
          }}
        />
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsInputBase;
