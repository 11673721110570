import React, { useMemo } from "react";
import MediaManagerStore from "containers/_components/media-manager/store/MediaManagerStore";
import { inject, observer } from "mobx-react";
import TailwindText from "library/components/_tailwind/text";
import {
  ModelMediaManagerMode,
  ModelMediaRouteMode,
} from "containers/_components/media-manager/store/enums";

type Props = { mediaManagerStore?: MediaManagerStore };

const MediaManagerSidebarHeaderDescription: React.ComponentType<Props> = ({
  mediaManagerStore,
}) => {
  const { routeMode, managerMode } = mediaManagerStore!;

  const description = useMemo(
    () =>
      routeMode === ModelMediaRouteMode.UPLOADED_VIDEOS
        ? "Upload custom videos and sell access for tokens"
        : routeMode === ModelMediaRouteMode.RECORDED_VIDEOS
        ? null
        : routeMode === ModelMediaRouteMode.PHOTOS &&
          managerMode === ModelMediaManagerMode.LIST
        ? "Create albums and sell access for tokens."
        : null,
    [routeMode, managerMode]
  );

  if (!description) {
    return null;
  }
  return (
    <TailwindText margin={["mb-3"]} textColor='text-main-color'>
      {description}
    </TailwindText>
  );
};

export default inject("mediaManagerStore")(
  observer(MediaManagerSidebarHeaderDescription)
);
