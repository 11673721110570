import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindCheckboxRadio from "../../components/_tailwind/checkbox-radio";
import { TailwindInputType } from "../../components/_tailwind/input-base";
import Code from "../../components/_tailwind/code";

type Props = {};

const StorybookComponentsCheckboxRadio: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Checkbox/Radio</StorybookHeading>
      <StorybookParagraph>
        Just like other components, TailwindCheckboxRadio is wrapped with the
        primitive components and can be extended if needed. It is just a simple
        box that has some kind of coloring in it therefore there is no single
        checkbox or a radio component, it is combined and can take
        <Code inline>type: TailwindInputType</Code> to show the appropriate
        component. Currently it takes the following props:
      </StorybookParagraph>
      <Code>
        {` label?: string;
labelProps?: TailwindTextProps;
boxProps?: TailwindBoxProps;
isChecked?: boolean;
onChange?: (isChecked: boolean) => void;
type: TailwindInputType.checkbox | TailwindInputType.radio;
name?: string;
gradientDirection?: TBackgroundImage;
gradientColorStops?: TGradientColorStops[];
disabled?: boolean 
`}
      </Code>
      <StorybookComponentShowcase
        sourceCode={`<TailwindCheckboxRadio
  type={TailwindInputType.checkbox}
  boxProps={{ margin: ["mr-2"] }}
/>
`}>
        <TailwindCheckboxRadio
          type={TailwindInputType.checkbox}
          boxProps={{ margin: ["mr-2"] }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindCheckboxRadio
  type={TailwindInputType.checkbox}
  isChecked={true}
  boxProps={{ margin: ["mr-2"] }}
/>
`}>
        <TailwindCheckboxRadio
          type={TailwindInputType.checkbox}
          isChecked={true}
          boxProps={{ margin: ["mr-2"] }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindCheckboxRadio
  type={TailwindInputType.radio}
  boxProps={{ margin: ["mr-2"] }}
/>
`}>
        <TailwindCheckboxRadio
          type={TailwindInputType.radio}
          boxProps={{ margin: ["mr-2"] }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindCheckboxRadio
  type={TailwindInputType.radio}
  isChecked={true}
/>
`}>
        <TailwindCheckboxRadio
          type={TailwindInputType.radio}
          isChecked={true}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindCheckboxRadio
  type={TailwindInputType.radio}
  isChecked={true}
  label={"Some label"}
/>
`}>
        <TailwindCheckboxRadio
          type={TailwindInputType.radio}
          isChecked={true}
          label={"Some label"}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindCheckboxRadio
  type={TailwindInputType.radio}
  isChecked={true}
  label={"Some text-red-500 label"}
  labelProps={{ textColor: "text-red-500" }}
/>
`}>
        <TailwindCheckboxRadio
          type={TailwindInputType.radio}
          isChecked={true}
          label={"Some text-red-500 label"}
          labelProps={{ textColor: "text-red-500" }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindCheckboxRadio
  type={TailwindInputType.radio}
  isChecked={true}
  label={"Some text-red-500 label"}
  labelProps={{ textColor: "text-red-500" }}
  disabled
/>
`}>
        <TailwindCheckboxRadio
          type={TailwindInputType.radio}
          isChecked={true}
          label={"Some text-red-500 label"}
          labelProps={{ textColor: "text-red-500" }}
          disabled
        />
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsCheckboxRadio;
