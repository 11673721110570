import React, { FunctionComponent } from "react";
import { inject, observer } from "mobx-react";
import AuthStore from "core/stores/auth/AuthStore";
import { injectIntl, WrappedComponentProps } from "react-intl";
import Logo from "library/components/logo";
import { openLoginModal, openRegisterModal } from "core/stores/auth/utils";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import TailwindButton from "library/components/_tailwind/button";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";

type LandingHeaderProps = {
  authStore?: AuthStore;
  layoutStore?: LayoutStore;
};
const LandingHeader: FunctionComponent<
  LandingHeaderProps & WrappedComponentProps
> = ({ layoutStore, authStore, intl }) => {
  const { userRole, isLoggedIn, logout } = authStore!;
  const { isMobileScreenSize } = layoutStore!;

  return (
    <TailwindFlex
      className={[
        `LandingLayout__header
            ${
              !isLoggedIn
                ? "LandingLayout__header--guest"
                : "LandingLayout__header--member"
            }`,
      ]}
      justifyContent={"justify-center"}>
      <TailwindFlex
        padding={["px-5"]}
        className={["LandingLayout__header__container"]}
        justifyContent={"justify-between"}
        alignItems={"items-center"}>
        <a href='/'>
          <Logo
            className={
              userRole === "model" || userRole === "studio"
                ? "LandingLayout__header-streamray-logo"
                : "LandingLayout__header-logo"
            }
            variant={userRole}
            style={!isLoggedIn ? "dark" : "light"}
            isMobile={isMobileScreenSize}
          />
        </a>
        {!isMobileScreenSize && (
          <TailwindTranslatedText
            textColor={"text-gray-200"}
            fontStyle={"italic"}
            fontSize={"text-2xl"}
            margin={["ml-10"]}
            width={"w-full"}
            descriptor={{
              id: "header.tagline",
              defaultMessage: "Where top models broadcast!",
            }}
          />
        )}
        {!isLoggedIn && (
          <TailwindFlex
            width={"w-auto"}
            height={"h-full"}
            alignItems={"items-center"}>
            <TailwindButton
              padding={["px-6"]}
              height={"h-8"}
              fullWidth={false}
              rounded={false}
              backgroundColor={"primary"}
              size={"sm"}
              textProps={{
                fontSize: "text-lg",
                whiteSpace: "whitespace-nowrap",
              }}
              onClick={openRegisterModal}>
              {intl.formatMessage({
                id: `landing.joinUs`,
                defaultMessage: "Join Us",
              })}
            </TailwindButton>
            <TailwindButton
              textColor={"text-white"}
              fullWidth={false}
              backgroundColor={"bg-gray-400"}
              rounded={false}
              size={"sm"}
              textProps={{
                fontSize: "text-lg",
                textTransform: "capitalize",
                whiteSpace: "whitespace-nowrap",
              }}
              onClick={openLoginModal}
              padding={["px-6"]}
              height={"h-8"}
              margin={["ml-4"]}>
              {intl.formatMessage({
                id: `landing.login`,
                defaultMessage: "Login",
              })}
            </TailwindButton>
          </TailwindFlex>
        )}
        {isLoggedIn && (
          <div>
            <TailwindButton
              justifyContent={"justify-center"}
              style={{ width: "110px" }}
              backgroundColor={"primary"}
              textColor={"text-white"}
              rounded={false}
              size={"sm"}
              onClick={() => logout()}>
              {intl.formatMessage({
                id: `common.logout`,
                defaultMessage: "Logout",
              })}
            </TailwindButton>
          </div>
        )}
      </TailwindFlex>
    </TailwindFlex>
  );
};
export default injectIntl(
  inject("modalStore", "authStore", "layoutStore")(observer(LandingHeader))
);
