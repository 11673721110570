import React from "react";
import "./styles.scss";
import clsx from "clsx";

type BuzzmodeIconProps = {
  className?: string;
};

const BuzzmodeIcon: React.ComponentType<BuzzmodeIconProps> = ({
  className,
}) => (
  <span className={clsx("BuzzmodeIcon", className && className)}>
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 362 362"
      xmlSpace="preserve"
      y="0px"
      x="0px"
      id="Layer_1_1568649310991"
    >
      <g transform="translate(1, 1)">
        <path
          d="M31.28,180.39c0.23,31.19,11.52,57.95,33.86,80.3c0.93,0.94,0.93,1.87,0,2.79l-18.16,18.15h-1.4h-1.4  C16.72,253.24,3,219.49,3,180.39c0-39.1,13.72-72.85,41.19-101.24h1.4h1.4L63.75,97.3c0.93,0.94,0.93,1.87,0,2.79  C41.86,122.43,31.04,149.21,31.28,180.39z M104.94,141.99c-10.71,11.17-16.06,24.33-16.06,39.45c0,15.13,5.34,28.28,16.06,39.45  v2.79l-17.45,16.06h-1.4h-2.1c-15.36-17.22-23.04-37.24-23.04-60.05c0-22.8,7.68-42.82,23.04-60.05h2.1h1.4l17.45,17.46V141.99z   M179.65,77.75l-60.75,113.11h60.75v92.17l60.75-113.11h-60.75V77.75z M299.74,180.39c0,23.28-7.68,43.06-23.04,59.35h-1.4h-2.09  l-17.46-17.45c-0.94-0.93-0.94-2.1,0-3.49c10.7-11.17,16.06-24.32,16.06-39.45c0-15.12-5.36-28.28-16.06-39.45  c-0.94-0.93-0.94-1.85,0-2.79l17.46-17.46h2.09h1.4C292.06,136.87,299.74,157.12,299.74,180.39z M357,180.73  c0,39.34-13.51,73.43-40.5,102.29h-1.4h-1.4l-18.15-17.46c-0.94-1.4-0.94-2.55,0-3.49c21.4-22.34,32.11-49.33,32.11-80.99  c0-31.65-10.71-58.65-32.11-80.99c-0.94-0.93-0.94-1.86,0-2.79l18.15-18.15h1.4h1.4C343.49,107.54,357,141.41,357,180.73z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  </span>
);

export default BuzzmodeIcon;
