import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookSubHeading from "../_internal/sub-heading";
import TailwindAnchor from "../../components/_tailwind/anchor";

type Props = {};

const StorybookConventionsFileAndFolderStructure: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>
        Conventions &gt; File and Folder Structure
      </StorybookHeading>
      <StorybookParagraph>
        Component files should be nested a max of 4 levels deep and ideally 3
        levels.
      </StorybookParagraph>
      <StorybookParagraph>Ideal:</StorybookParagraph>
      <Code>
        {`containers/{page-name}/index.tsx
containers/page-name/{userRole}.tsx
containers/page-name/some-component-used-by-several-userroles.tsx
`}
      </Code>
      <StorybookParagraph>
        If absolutely necessary{" "}
        <strong>
          (try to utilize components from components folder or this repository.
          If there is none, try to create a pure reusable component yourself if
          have seen on the design that there are other areas it will be used)
        </strong>
        :
      </StorybookParagraph>
      <Code>
        {`containers/{page-name}/component/{sub-component-folder}/index.tsx
containers/{page-name}/component/{sub-component-folder}/some-sub-component.tsx
containers/{page-name}/component/{sub-component-folder}/aff-sub-component.tsx
`}
      </Code>
      <StorybookParagraph>
        A component that is only used in header or sidebar for example, should
        not be extracted to components directory which only has reusable
        components that are used across multiple pages or even projects.
      </StorybookParagraph>
      <StorybookParagraph>
        If a component is literally pure and is not opinionated, it should be
        placed inside components folder or better yet extracted to this
        repository as it can be used across projects.
      </StorybookParagraph>
      <StorybookSubHeading>Types or Interfaces</StorybookSubHeading>
      <StorybookParagraph>
        Types should be preferred over interfaces as they are easier to compose.
      </StorybookParagraph>
      <StorybookParagraph>
        Types of a component should be inside the component itself and needs to
        be exported. the name of the type should match the name of the
        component.
      </StorybookParagraph>
      <StorybookParagraph>
        Types of a store or any generic class should have its own folder along
        with the types extracted to a types.ts file
      </StorybookParagraph>
      <Code>
        {`media-manager/index.tsx
media-manager/media-manager-album.tsx
media-manager/store/MediaManagerStore.ts
media-manager/store/types.ts
media-manager/store/consts.ts
media-manager/store/utils.ts
`}
      </Code>
      <StorybookParagraph>
        Please refer to&nbsp;
        <TailwindAnchor fullWidth={false} to={"/conventions/naming"}>
          Naming Conventions
        </TailwindAnchor>
        &nbsp; and&nbsp;
        <TailwindAnchor fullWidth={false} to={"/architecture/typescript"}>
          Typescript Architecture
        </TailwindAnchor>
        &nbsp;for more info.
      </StorybookParagraph>
      <StorybookSubHeading>Utils, Mocks</StorybookSubHeading>
      <StorybookParagraph>
        Utils and mocks of a component should be placed in the root folder where
        the component is placed
      </StorybookParagraph>
      <Code>
        {`header/index.tsx
header/utils.ts (any shared util will go here)
header/types.ts (any shared type will go here)
header/mocks.ts (mocks will go here always, no mocks in components)
header/logo.tsx (will use mocks from the mocks & utils from the root folder, will have its own types inside the component file as HeaderLogoProps)
header/go-live-button.tsx (will use mocks from the mocks & utils from the root folder, will have its own types inside the component file as HeaderGoLiveButtonProps)      
`}
      </Code>
    </StorybookWrapper>
  );
};

export default StorybookConventionsFileAndFolderStructure;
