import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindButton from "../../components/_tailwind/button";
import { CustomIconName } from "../../components/_tailwind/icon/icons/enums";

type Props = {};

const StorybookComponentsButton: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Button</StorybookHeading>
      <StorybookParagraph>
        TailwindButton is an extended version from TailwindBox. It takes all
        props from TailwindBox but also some opinions via the props below:
      </StorybookParagraph>
      <Code>{`textProps?: TailwindTextProps;
leftIconProps?: TailwindIconProps;
rightIconProps?: TailwindIconProps;
onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
showSpinner?: boolean;
disabled?: boolean;
fullWidth?: boolean;
backgroundColor?: TBackgroundColor;
size?: "sm" | "normal" | "lg";
rounded?: boolean;
bold?: boolean;
`}</Code>
      <StorybookParagraph>Here are some examples:</StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindButton>rounded fullWidth</TailwindButton>`}>
        <TailwindButton>rounded fullWidth</TailwindButton>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindButton backgroundColor={"bg-red-500"}>
  bg-red-500 rounded fullWidth
</TailwindButton>
        `}>
        <TailwindButton backgroundColor={"bg-red-500"}>
          bg-red-500 rounded fullWidth
        </TailwindButton>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindButton backgroundColor={"bg-red-500"} rounded={false}>
  bg-red-500 sharp fullWidth
</TailwindButton>
        `}>
        <TailwindButton backgroundColor={"bg-red-500"} rounded={false}>
          bg-red-500 sharp fullWidth
        </TailwindButton>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindButton backgroundColor={"bg-red-500"} rounded={false} fullWidth={false}>
  bg-red-500 sharp auto width
</TailwindButton>
        `}>
        <TailwindButton
          backgroundColor={"bg-red-500"}
          rounded={false}
          fullWidth={false}>
          bg-red-500 sharp auto width
        </TailwindButton>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindButton
  backgroundColor={"bg-red-500"}
  rounded={false}
  fullWidth={false}
  textProps={{
    textTransform: "uppercase"
  }}
>
  bg-red-500 sharp auto width uppercase
</TailwindButton>
        `}>
        <TailwindButton
          backgroundColor={"bg-red-500"}
          rounded={false}
          fullWidth={false}
          textProps={{
            textTransform: "uppercase",
          }}>
          bg-red-500 sharp auto width uppercase
        </TailwindButton>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindButton
  backgroundColor={"bg-red-500"}
  rounded={false}
  fullWidth={false}
  textProps={{
    textTransform: "uppercase",
  }}
  psuedoClasses={["hover:bg-blue-500"]}
>
  bg-red-500 sharp auto width uppercase hover:bg-blue-500
</TailwindButton>
        `}>
        <TailwindButton
          backgroundColor={"bg-red-500"}
          rounded={false}
          fullWidth={false}
          textProps={{
            textTransform: "uppercase",
          }}
          pseudoClasses={["hover:bg-blue-500"]}>
          bg-red-500 sharp auto width uppercase hover:bg-blue-500
        </TailwindButton>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindButton
  backgroundColor={"bg-red-500"}
  rounded={false}
  fullWidth={false}
  textProps={{
    textTransform: "uppercase",
  }}
  psuedoClasses={["hover:bg-blue-500"]}
  rightIconProps={{
    name: CustomIconName.star,
  }}
>
  bg-red-500 sharp auto width uppercase hover:bg-blue-500 rightIconProps
  name CustomIconName.star
</TailwindButton>
        `}>
        <TailwindButton
          backgroundColor={"bg-red-500"}
          rounded={false}
          fullWidth={false}
          textProps={{
            textTransform: "uppercase",
          }}
          pseudoClasses={["hover:bg-blue-500"]}
          rightIconProps={{
            name: CustomIconName.star,
          }}>
          bg-red-500 sharp auto width uppercase hover:bg-blue-500 rightIconProps
          name CustomIconName.star
        </TailwindButton>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindButton
  backgroundColor={"bg-red-500"}
  rounded={false}
  fullWidth={false}
  textProps={{
    textTransform: "uppercase",
  }}
  psuedoClasses={["hover:bg-blue-500"]}
  showSpinner
>
  bg-red-500 sharp auto width uppercase hover:bg-blue-500 showSpinner
</TailwindButton>
        `}>
        <TailwindButton
          backgroundColor={"bg-red-500"}
          rounded={false}
          fullWidth={false}
          textProps={{
            textTransform: "uppercase",
          }}
          pseudoClasses={["hover:bg-blue-500"]}
          showSpinner>
          bg-red-500 sharp auto width uppercase hover:bg-blue-500 showSpinner
        </TailwindButton>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindButton
  backgroundColor={"bg-red-500"}
  rounded={false}
  fullWidth={false}
  textProps={{
    textTransform: "uppercase",
  }}
  psuedoClasses={["hover:bg-blue-500"]}
  disabled
>
  bg-red-500 sharp auto width uppercase hover:bg-blue-500 disabled
</TailwindButton>
        `}>
        <TailwindButton
          backgroundColor={"bg-red-500"}
          rounded={false}
          fullWidth={false}
          textProps={{
            textTransform: "uppercase",
          }}
          pseudoClasses={["hover:bg-blue-500"]}
          disabled>
          bg-red-500 sharp auto width uppercase hover:bg-blue-500 disabled
        </TailwindButton>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindButton
  backgroundColor={"bg-red-500"}
  rounded={false}
  fullWidth={false}
  bold={false}
>
  bg-red-500 sharp auto width bold
</TailwindButton>
        `}>
        <TailwindButton
          backgroundColor={"bg-red-500"}
          rounded={false}
          fullWidth={false}
          bold={false}>
          bg-red-500 sharp auto width
        </TailwindButton>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindButton
  backgroundColor={"bg-red-500"}
  rounded={false}
  fullWidth={false}
  bold={false}
  size={"lg"}
>
  bg-red-500 sharp auto width lg
</TailwindButton>
        `}>
        <TailwindButton
          backgroundColor={"bg-red-500"}
          rounded={false}
          fullWidth={false}
          bold={false}
          size={"lg"}>
          bg-red-500 sharp auto width lg
        </TailwindButton>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindButton
  backgroundColor={"bg-red-500"}
  rounded={false}
  fullWidth={false}
  bold={false}
  size={"sm"}
>
  bg-red-500 sharp auto width sm
</TailwindButton>
        `}>
        <TailwindButton
          backgroundColor={"bg-red-500"}
          rounded={false}
          fullWidth={false}
          bold={false}
          size={"sm"}>
          bg-red-500 sharp auto width sm
        </TailwindButton>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindButton
  backgroundColor={"bg-red-500"}
  rounded={false}
  fullWidth={false}
  bold={false}
  height={"h-96"}
>
  bg-red-500 sharp auto width sm
</TailwindButton>
        `}>
        <TailwindButton
          backgroundColor={"bg-red-500"}
          rounded={false}
          fullWidth={false}
          bold={false}
          height={"h-96"}>
          bg-red-500 sharp auto width h-96
        </TailwindButton>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsButton;
