import React from "react";
import TailwindBox from "library/components/_tailwind/box";
import TailwindText from "library/components/_tailwind/text";
import "./styles.scss";
import { FALLBACK_CALIFORNIA_ACT } from "core/constants";

type Props = {};
const CaliforniaAct = ({}: Props) => {
  return (
    <div className={"CaliforniaAct"}>
      <TailwindText
        display={"block"}
        fontSize={"text-3xl"}
        fontWeight={"font-bold"}
        padding={["py-2"]}
        margin={["mb-4"]}>
        California Consumer Privacy Act Notice
      </TailwindText>
      <TailwindBox
        dangerouslySetInnerHTML={{
          __html: FALLBACK_CALIFORNIA_ACT,
        }}></TailwindBox>
    </div>
  );
};

export default CaliforniaAct;
