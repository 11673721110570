import { makeAutoObservable } from "mobx";
import { api } from "core/utility";
import { AWARD_SHOW_ITEMS, AWARD_SHOW_YEARS } from "./consts";
import { Award, AwardByYear } from "./interfaces";
import { GiftProps, GiftSortKey } from "./types";
import { logger } from "library/core/utility";
import { ORDINAL_NUMBERS } from "library/core/constants";

export default class GiftsAndAwardsStore {
  public gifts: GiftProps[] = [];
  public loadingGifts: boolean = false;
  public awards: Array<AwardByYear[]> = [];
  public loadingAwards: boolean = false;
  public giftSortKey: GiftSortKey = "price";
  public prevGiftSortKey: GiftSortKey = "count";

  constructor() {
    makeAutoObservable(this);
  }

  public getGifts = async () => {
    this.loadingGifts = true;
    try {
      const response = await api.walletModelGiftsRecievedLast30Days.get();
      this.handleGift(
        response.data.data.virtual_gifts.filter(gift => gift.count > 0)
      );
    } catch (error) {
      logger.log("getGifts Error ", error);
    } finally {
      this.loadingGifts = false;
    }
  };

  private handleGift = (gifts: GiftProps[]) => {
    this.gifts = gifts;
  };

  public setSortKey = (sortKey: GiftSortKey) => {
    if (this.giftSortKey !== sortKey) this.prevGiftSortKey = this.giftSortKey;
    this.giftSortKey = sortKey;
  };

  public get sortedGifts() {
    return [...this.gifts].sort((a, b) =>
      b[this.giftSortKey] === a[this.giftSortKey]
        ? b[this.prevGiftSortKey] - a[this.prevGiftSortKey]
        : b[this.giftSortKey] > a[this.giftSortKey]
        ? 1
        : -1
    );
  }

  public fetchAwards = async () => {
    this.loadingAwards = true;
    try {
      const awardData = await api.awards.get();
      const awards = this.formatAwardsResponse(awardData.data.data);
      this.handleAwards(awards);
    } catch (error) {
      logger.log("getAwards Error ");
    } finally {
      this.loadingAwards = false;
    }
  };

  public handleAwards = awards => {
    this.awards = awards;
  };

  public formatAwardsResponse = (awards: Award[]): Array<AwardByYear[]> => {
    const years: Array<string> = this.getLatestYearFromAwards(
      awards,
      AWARD_SHOW_YEARS
    );
    const yearAndAward: Array<{
      year: string;
      award: string;
    }> = this.formatAwardsToYearAndAward(awards);
    const chunkAwardsByYear: AwardByYear[] = this.chunkByYear(
      yearAndAward,
      years
    );
    const chunkAwardsByRow: Array<AwardByYear[]> =
      this.chunkByRow(chunkAwardsByYear);
    return chunkAwardsByRow;
  };

  private formatAwardsToYearAndAward = (
    awards: Award[]
  ): Array<{ year: string; award: string }> => {
    return awards.map(award => ({
      year: this.getYear(award),
      award: this.getAward(award),
    }));
  };

  private getAward = (award: Award) => {
    const item = award.award_title
      .slice(8, award.award_title.length - 5)
      .split(",")[0];
    const ordinal = !!item.split(" ")[1]
      ? ORDINAL_NUMBERS[item.split(" ")[1].slice(-1)]
      : "";
    return item + ordinal;
  };

  private getYear = (award: Award) => {
    return award.award_date.split("-")[0];
  };

  private getLatestYearFromAwards = (awards: Award[], range: number) => {
    return awards
      .map(award => this.getYear(award))
      .filter((year, index, arr) => arr.indexOf(year) === index)
      .slice(0, range);
  };

  private chunkByYear = (
    awards: Array<{ year: string; award: string }>,
    years: Array<string>
  ): AwardByYear[] => {
    return years.map(year => ({
      year: year,
      items: awards.filter(e => e.year === year).map(e => e.award),
      number: awards.filter(e => e.year === year).length,
    }));
  };

  private chunkByRow = (
    chunkAwardsByYear: AwardByYear[]
  ): Array<AwardByYear[]> => {
    const chunkAwardsByRow: Array<AwardByYear[]> = [];
    let needNum: number = AWARD_SHOW_ITEMS;
    chunkAwardsByYear.forEach(awardByYear => {
      const indexOfAllowInsertItems = this.findRowNotHaveEnoughItems(
        chunkAwardsByRow,
        AWARD_SHOW_ITEMS
      );
      if (needNum > 0 && indexOfAllowInsertItems > -1) {
        this.insertAwardByYearToRow(
          chunkAwardsByRow,
          indexOfAllowInsertItems,
          awardByYear,
          needNum
        );
        needNum =
          awardByYear.number < needNum ? needNum - awardByYear.number : needNum;
        this.removeItemsfromAwardByYear(awardByYear, needNum);
      }
      while (awardByYear.items.length > 0) {
        this.insertRowToChunkAwardsByRow(chunkAwardsByRow, awardByYear);
        needNum =
          awardByYear.number < needNum
            ? needNum - awardByYear.number
            : AWARD_SHOW_ITEMS - awardByYear.number;
        awardByYear.year = null;
        awardByYear.items.splice(0, AWARD_SHOW_ITEMS);
        awardByYear.number = awardByYear.number - AWARD_SHOW_ITEMS;
      }
    });
    return chunkAwardsByRow;
  };

  private insertRowToChunkAwardsByRow = (
    chunkAwardsByRow: Array<AwardByYear[]>,
    awardByYear: AwardByYear
  ) => {
    chunkAwardsByRow.push([
      {
        year: awardByYear.year,
        items: awardByYear.items.slice(0, AWARD_SHOW_ITEMS),
        number:
          awardByYear.number >= AWARD_SHOW_ITEMS
            ? AWARD_SHOW_ITEMS
            : awardByYear.number,
      },
    ]);
  };

  private insertAwardByYearToRow = (
    chunkAwardsByRow: Array<AwardByYear[]>,
    index: number,
    awardByYear: AwardByYear,
    needNum: number
  ) => {
    chunkAwardsByRow[index].push({
      year: awardByYear.year,
      items: awardByYear.items.slice(0, needNum),
      number: awardByYear.number < needNum ? awardByYear.number : needNum,
    });
  };

  private removeItemsfromAwardByYear = (
    awardByYear: AwardByYear,
    needNum: number
  ) => {
    awardByYear.year = null;
    awardByYear.number =
      awardByYear.number < needNum ? 0 : awardByYear.number - needNum;
    awardByYear.items.splice(0, needNum);
  };

  private findRowNotHaveEnoughItems = (
    awardsRow: Array<AwardByYear[]>,
    itemsNumber: number
  ): number => {
    return awardsRow.findIndex(e => {
      let number = 0;
      e.forEach(e => (number = number + e.number));
      return number < itemsNumber;
    });
  };
}
