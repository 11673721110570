import TailwindText from "library/components/_tailwind/text";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import React, { FunctionComponent } from "react";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import {
  AppCamsModelRouteKey,
  AppVariantRouteKey,
} from "core/stores/route/enums";
import { history } from "library/core/utility";
import RouteStore from "core/stores/route/RouteStore";
import ModalStore from "library/core/stores/modal";
import { inject, observer } from "mobx-react";

type Props = {
  routeStore?: RouteStore;
  modalStore?: ModalStore;
  isStudio: boolean;
};

const ApplicationPending: FunctionComponent<Props & WrappedComponentProps> = ({
  routeStore,
  modalStore,
  isStudio,
}) => {
  const { getSiteRouteURL } = routeStore!;
  const { closeAuthModal } = modalStore!;

  return isStudio ? (
    <TailwindTranslatedText
      textColor='text-main-color'
      descriptor={{
        id: "landing.summaryStep.text",
        defaultMessage:
          "Nice work, your application is now complete! All forms have been completed and all documents have been uploaded. Your application is now under review. You will receive a confirmation email once your application is approved.",
      }}
    />
  ) : (
    <TailwindText fontSize={"text-sm"} textColor='text-main-color'>
      <FormattedMessage
        id='landing.summaryStep.completeText'
        defaultMessage='Nice work, your application is now complete! All forms have been completed and all documents have been uploaded. Your application is now under review. You will receive a confirmation email once your application is approved and you are ready to start broadcasting. In the meantime, please click {here} to setup your profile and checkout the {welcomePage}.'
        values={{
          here: (
            <span
              className='underline text-pink-500 cursor-pointer'
              onClick={() => {
                history.replace(getSiteRouteURL(AppCamsModelRouteKey.myPage));
                closeAuthModal();
              }}>
              here
            </span>
          ),
          welcomePage: (
            <span
              className='underline text-pink-500 cursor-pointer'
              onClick={() => {
                history.replace(getSiteRouteURL(AppVariantRouteKey.tutorial));
                closeAuthModal();
              }}>
              Welcome page
            </span>
          ),
        }}
      />
    </TailwindText>
  );
};
export default injectIntl(
  inject("routeStore", "modalStore")(observer(ApplicationPending))
);
