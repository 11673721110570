import React, { useMemo } from "react";
import TailwindBox, { TailwindBoxProps } from "../box";
import {
  TBackgroundColor,
  TOverflow,
} from "../../../styles/tailwind-classnames";
import { inject, observer } from "mobx-react";
import ThemeStore from "../../../core/stores/theme/ThemeStore";

export type TailwindCardBackgroundColor =
  | "primary"
  | "secondary"
  | "transparent"
  | TBackgroundColor;

export type TailwindCardProps = Omit<TailwindBoxProps, "backgroundColor"> & {
  rounded?: boolean;
  backgroundColor?: TailwindCardBackgroundColor;
  themeStore?: ThemeStore;
};

const TailwindCard = React.forwardRef<HTMLDivElement, TailwindCardProps>(
  (
    {
      rounded = false,
      borderRadius,
      children,
      overflow,
      backgroundColor,
      themeStore,
      ...props
    },
    ref
  ) => {
    const { currentThemePalette } = themeStore!;

    const _borderRadius = useMemo(() => {
      if (rounded) {
        return "rounded-xl";
      }

      return borderRadius;
    }, [rounded, borderRadius]);

    const _overflow = useMemo(() => {
      if (rounded) {
        return ["overflow-hidden"] as TOverflow[];
      }

      return overflow || [];
    }, [overflow, rounded]);

    const _backgroundColor = useMemo(() => {
      let className: TBackgroundColor = "bg-transparent";

      if (backgroundColor) {
        switch (backgroundColor as TailwindCardBackgroundColor) {
          case "primary": {
            const _class =
              currentThemePalette?.["card-primary-bg-color-tw-class"];
            if (_class) {
              className = _class;
            }
            break;
          }
          case "secondary": {
            const _class =
              currentThemePalette?.["card-secondary-bg-color-tw-class"];
            if (_class) {
              className = _class;
            }
            break;
          }
          case "transparent": {
            className = "bg-transparent";
            break;
          }
          default: {
            className = backgroundColor as TBackgroundColor;
            break;
          }
        }
      }

      return className as TBackgroundColor;
    }, [backgroundColor]);

    return (
      <TailwindBox
        {...props}
        ref={ref}
        borderRadius={_borderRadius}
        overflow={_overflow}
        backgroundColor={_backgroundColor}>
        {children}
      </TailwindBox>
    );
  }
);

export default inject("themeStore")(observer(TailwindCard));
