import React, {
  ImgHTMLAttributes,
  ReactNode,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useState,
} from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import TailwindBox, {
  TailwindBoxClassName,
  TailwindBoxProps,
} from "library/components/_tailwind/box";
import TailwindIcon from "library/components/_tailwind/icon";
import VideoPlayer from "library/components/_tailwind/video-player";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import MessagesAudioPlayer from "./messages-audio-player";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import MessageCenterUnavailableAttachment from "./messages-unavailable-attachment";
import TailwindText from "library/components/_tailwind/text";
import { formatter } from "library/core/utility";

type Props = {
  imgSrc: string;
  videoSrc?: string;
  id: string;
  status?: string;
  isView?: boolean;
  removeImg?: (id: string) => void;
  width?: number;
  height?: number;
  isImageBackground?: boolean;
  topBar?: ReactNode;
  bottomBar?: ReactNode;
  boxProps?: TailwindBoxProps;
  imgBoxProps?: TailwindBoxProps;
  imgProps?: ImgHTMLAttributes<HTMLImageElement>;
  videoProps?: React.HTMLProps<HTMLVideoElement>;
  isVideo?: boolean;
  isAudio?: boolean;
  openCarousel?: boolean;
  onClickPhoto?: () => void;
  duration?: number;
  showGalleryVoiceImg?: boolean;
  isSenderCurrentUser?: boolean;
};

const ImgContainer: React.ComponentType<Props> = ({
  imgSrc,
  videoSrc,
  id,
  status,
  removeImg,
  isImageBackground = false,
  bottomBar,
  topBar,
  boxProps,
  imgProps,
  imgBoxProps,
  isVideo,
  videoProps,
  isAudio,
  openCarousel,
  onClickPhoto,
  duration,
  showGalleryVoiceImg,
  isSenderCurrentUser,
}) => {
  const [imageLoadError, setImageLoadError] = useState<boolean>(false);
  const [loadVideo, setLoadVideo] = useState<boolean>(false);
  const [showVideoError, setShowVideoError] = useState<boolean>(false);
  const [showTopBottomBar, setShowTopBottomBar] = useState<boolean>(true);
  const videoRef = useRef<HTMLVideoElement>(null);

  const playVideo = useCallback(() => {
    if (videoSrc && !loadVideo && openCarousel) {
      setLoadVideo(true);
      setShowTopBottomBar(false);
    } else if (onClickPhoto) {
      onClickPhoto();
    }
  }, [loadVideo, videoSrc]);

  const onHover = useCallback(() => {
    if (loadVideo) {
      setShowTopBottomBar(false);
    }
  }, [loadVideo]);

  const onBlur = useCallback(() => {
    setShowTopBottomBar(true);
  }, [loadVideo]);

  const classNames = useMemo(() => {
    let names: TailwindBoxClassName = ["img-container"];
    if (isAudio && boxProps?.className) {
      names = [
        ...boxProps.className,
        ...["img-container", "MessagesAudioTile"],
      ];
    } else if (isAudio) {
      names = ["img-container", "MessagesAudioTile"];
    } else if (boxProps?.className) {
      names = [...boxProps.className, ...["img-container"]];
    }

    return names;
  }, [isAudio, boxProps]);

  const onImageLoadError = () => {
    setImageLoadError(true);
  };

  useEffect(() => {
    if (loadVideo) {
      videoRef?.current?.play();
    }
  }, [loadVideo, videoRef]);

  useEffect(() => {
    setImageLoadError(false);
  }, [status]);

  return (
    <TailwindFlex
      {...boxProps}
      className={classNames}
      style={{
        ...boxProps?.style,
        backgroundImage: boxProps?.style?.backgroundImage
          ? boxProps?.style?.backgroundImage
          : isImageBackground && imgSrc
          ? `url(${imgSrc})`
          : "unset",
      }}
      onMouseEnter={onHover}
      onMouseLeave={onBlur}>
      {videoSrc && loadVideo && !showVideoError ? (
        <VideoPlayer
          src={videoSrc}
          videoRef={videoRef}
          useHls={videoSrc.includes(".m3u8")}
          fullHeight={true}
          fullWidth={true}
          autoPlay={false}
          controlsList={"nodownload noplaybackrate"}
          disablePictureInPicture={true}
          {...videoProps}
          wrapperProps={{
            display: "flex",
            justifyContent: "justify-center",
            backgroundColor: "bg-black",
          }}
          onError={() => {
            setShowVideoError(true);
          }}></VideoPlayer>
      ) : videoSrc && loadVideo && showVideoError ? (
        <TailwindFlex
          maxWidth={"max-w-sm"}
          padding={["p-1.5"]}
          flexDirection='flex-col'>
          <TailwindTranslatedText
            fontWeight='font-bold'
            descriptor={{
              id: "message-center.error.video-support-title",
              defaultMessage: "This video is not supported by your browser",
            }}
          />
        </TailwindFlex>
      ) : isAudio && !showGalleryVoiceImg ? (
        <TailwindFlex
          flexDirection='flex-row'
          width='w-full'
          height='h-full'
          alignItems='items-center'
          justifyContent='justify-center'
          gap='gap-1.5'
          style={{
            padding: "12px",
            paddingBottom: bottomBar && showTopBottomBar ? "40px" : "12px",
          }}>
          <MessagesAudioPlayer src={imgSrc} duration={duration} />
        </TailwindFlex>
      ) : isAudio && showGalleryVoiceImg ? (
        <TailwindFlex
          flexDirection='flex-col'
          width='w-full'
          height='h-full'
          alignItems='items-center'
          justifyContent='justify-center'
          cursor='cursor-pointer'
          style={{
            padding: "12px",
          }}
          onClick={onClickPhoto}>
          <TailwindFlex
            width='w-full'
            alignItems='items-center'
            justifyContent='justify-center'
            flexDirection='flex-row'>
            <TailwindIcon
              name={CustomIconName["voice-icon"]}
              className={`VoiceIcon${
                isSenderCurrentUser ? "-member" : "-model"
              }`}
            />
          </TailwindFlex>
          <TailwindFlex
            width='w-full'
            alignItems='items-center'
            justifyContent='justify-center'
            flexDirection='flex-row'>
            <TailwindText
              style={{
                color: isSenderCurrentUser ? "#ffffff" : "#84A3BA",
              }}>
              {duration ? formatter.formatSeconds(duration, "MM:SS") : null}
            </TailwindText>
          </TailwindFlex>
        </TailwindFlex>
      ) : imageLoadError ? (
        <TailwindFlex className={["error-image"]}>
          <MessageCenterUnavailableAttachment />
        </TailwindFlex>
      ) : isImageBackground ? null : (
        <TailwindBox {...imgBoxProps}>
          <img src={imgSrc} {...imgProps} onError={onImageLoadError} />
        </TailwindBox>
      )}
      {topBar && showTopBottomBar && !showVideoError && !imageLoadError ? (
        <TailwindFlex
          position='absolute'
          inset={["top-0"]}
          overflow={"overflow-hidden"}>
          {topBar}
        </TailwindFlex>
      ) : null}
      {bottomBar && showTopBottomBar && !showVideoError && !imageLoadError ? (
        <TailwindFlex
          position='absolute'
          inset={["bottom-0"]}
          overflow={"overflow-hidden"}
          zIndex={"z-10"}>
          {bottomBar}
        </TailwindFlex>
      ) : null}

      {(isVideo || videoSrc) && !loadVideo && !imageLoadError ? (
        <TailwindFlex
          position='absolute'
          className={["img-container__play-icon"]}
          onClick={playVideo}>
          <TailwindIcon name={CustomIconName.play} />
        </TailwindFlex>
      ) : null}
      {removeImg && (
        <TailwindButton
          position='absolute'
          inset={["-right-2", "-top-1", "left-auto"]}
          height={"h-auto"}
          padding={["p-0"]}
          borderRadius={"rounded-full"}
          backgroundColor={"bg-white"}
          onClick={() => removeImg(id)}
          fullWidth={false}
          fontSize='text-xs'
          justifyContent='justify-center'
          alignItems='items-center'
          style={{
            width: "20px",
            height: "20px",
          }}
          rightIconProps={{
            name: "close-thicker",
          }}
        />
      )}
    </TailwindFlex>
  );
};

export default ImgContainer;
