import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { inject, observer } from "mobx-react";
import DateFnsUtils from "@date-io/date-fns";
import { injectIntl, WrappedComponentProps } from "react-intl";
import AuthStore from "core/stores/auth/AuthStore";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import "./styles.scss";
import ProfileStore from "../my-page/stores/profile/ProfileStore";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import { SIGNUP_AGREEMENT_TYPE } from "./enums";
import {
  INDY_MODEL_AGREEMENT_STEPS,
  STUDIO_AGREEMENT_STEPS,
  STUDIO_MODEL_AGREEMENT_STEPS,
} from "./consts";
import TailwindInput from "library/components/_tailwind/input";

const dateFormatter = new DateFnsUtils();

type AgreementStepProps = {
  authStore?: AuthStore;
  validationStore?: ValidationStore;
  profileStore?: ProfileStore;
  classes?: string;
};

const AgreementStep: FunctionComponent<
  AgreementStepProps & WrappedComponentProps
> = ({ profileStore, authStore, validationStore, intl }) => {
  const { getAgreements } = authStore!;

  const scrollableNodeRef = useRef<HTMLDivElement>(null);

  const {
    agreements,
    completeAgreementStep,
    userRole,
    updateRegistrationFormField,
    formRegistrationData: {
      signed_agreement: { signature },
    },
  } = authStore!;
  const [pendingAgreementsIndex, setPendingAgreementIndex] = useState([0]);
  const [currentPendingAgreementStep, setPendingAgreementStep] = useState(0);
  const todaysDate = dateFormatter.format(new Date(), "MM/dd/yyyy");
  const { isStudio, isStudioModel, modelProfile } = profileStore!;
  const clickedNext = event => {
    event.preventDefault();
    const agreementIndex = pendingAgreementsIndex[currentPendingAgreementStep];
    const currentAgreementID = agreements[agreementIndex]?.id;

    if (pendingAgreementsIndex[currentPendingAgreementStep + 1] >= 0) {
      completeAgreementStep(false, currentAgreementID).then(isValid => {
        if (isValid) {
          setPendingAgreementStep(currentPendingAgreementStep + 1);
        }
      });
    } else {
      completeAgreementStep(true, currentAgreementID);
    }
  };

  const getAgreementIndex = agreement_step => {
    return agreements.findIndex(
      agreement => agreement.agreement_type === agreement_step
    );
  };

  useEffect(() => {
    getAgreements();
  }, []);

  useEffect(() => {
    if (agreements.length) {
      // map user role agreements to total agreement list provided so agreement step indeces are in order
      const agreement_steps: number[] = [];
      if (isStudioModel) {
        STUDIO_MODEL_AGREEMENT_STEPS.forEach(agreement_step => {
          agreement_steps.push(getAgreementIndex(agreement_step));
        });
      } else if (isStudio) {
        STUDIO_AGREEMENT_STEPS.forEach(agreement_step => {
          agreement_steps.push(getAgreementIndex(agreement_step));
        });
      } else {
        INDY_MODEL_AGREEMENT_STEPS.forEach(agreement_step => {
          agreement_steps.push(getAgreementIndex(agreement_step));
        });
      }
      setPendingAgreementIndex(agreement_steps);
    }
  }, [agreements, modelProfile]);

  useEffect(() => {
    if (scrollableNodeRef?.current) {
      scrollableNodeRef?.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [currentPendingAgreementStep]);

  const getSignUpAgreementTitle = useCallback(() => {
    const agreementIndex = pendingAgreementsIndex[currentPendingAgreementStep];
    const currentAgreementType = agreements[agreementIndex]?.agreement_type;

    if (currentAgreementType === SIGNUP_AGREEMENT_TYPE.BROADCASTING_AGREEMENT) {
      return intl.formatMessage({
        id: "agreementStep.broadcastAgreement",
        defaultMessage: "Broadcast Agreement",
      });
    } else if (
      currentAgreementType === SIGNUP_AGREEMENT_TYPE.BROADCAST_RELEASE
    ) {
      return intl.formatMessage({
        id: "agreementStep.broadcastRelease",
        defaultMessage: "Broadcast Release",
      });
    } else if (
      currentAgreementType === SIGNUP_AGREEMENT_TYPE.PERFORMANCE_AGREEMENT
    ) {
      return intl.formatMessage({
        id: "agreementStep.performanceAgreement",
        defaultMessage: "Performance Acknowledgement and Agreement",
      });
    } else {
      return "";
    }
  }, [currentPendingAgreementStep, pendingAgreementsIndex]);

  return (
    <TailwindFlex width={"w-192"} md={"w-11/12"} height={"h-full"}>
      <SimpleBar
        className='RegisterModal__form-scrollarea '
        scrollableNodeProps={{ ref: scrollableNodeRef }}>
        <div className='RegisterModal__form '>
          <h5 className={"RegisterModal__agreement-explanation-title"}>
            <strong>{getSignUpAgreementTitle()}</strong>
          </h5>
          <h6 className={"RegisterModal__agreement-explanation-description"}>
            <strong>
              {currentPendingAgreementStep === 0
                ? `Before you can start, we need you to sign ${
                    isStudio ? "one legal agreement" : "two legal agreements"
                  }.\n` +
                  "Among other things, this broadcast agreement defines legal terms and\n" +
                  "what happens if problems come up. Please scroll down to the bottom\n" +
                  "and type your name."
                : "This last document allows us to show your live video and collect money on your behalf.\n" +
                  "It also lets us record your shows to sell access to them on your behalf.\n" +
                  "You will always have an option to turn off recording as well as delete your videos."}
            </strong>
          </h6>
          {userRole === "model" && (
            <div className='RegisterModal__floating_pagination'>
              {currentPendingAgreementStep + 1}/2
            </div>
          )}
          <form noValidate autoComplete='false' className={"AuthModal__form"}>
            <div className={"w-full mt-4"}>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    agreements.length &&
                    agreements[
                      pendingAgreementsIndex[currentPendingAgreementStep]
                    ]
                      ? agreements[
                          pendingAgreementsIndex[currentPendingAgreementStep]
                        ]["text"]
                      : "",
                }}
              />
            </div>
            <div className={"w-full mt-2 mb-4"}>
              <TailwindInput
                boxProps={{
                  padding: ["p-0"],
                  textColor: "text-black",
                }}
                padding={["p-4"]}
                required
                name={"signature"}
                width={"w-full"}
                label={intl.formatMessage({
                  id: "common.legalNameSign",
                  defaultMessage: "Legal Name",
                })}
                error={validationStore!.errors?.signature || ""}
                onBlur={e =>
                  validationStore!.validate("signature", e.target.value)
                }
                value={signature || ""}
                onChange={e =>
                  updateRegistrationFormField("signed_agreement", e.target)
                }
                borderWidth={["border-2"]}
              />
              <span className='d-inline-block mb-2 text-main-color'>
                {intl.formatMessage({
                  id: "common.signatureInfo",
                  defaultMessage:
                    "To create a signature, please type in your legal name.",
                })}
                <br />
                {intl.formatMessage({
                  id: "common.signDate",
                  defaultMessage: "Date:",
                })}
                &nbsp;{todaysDate}
              </span>
            </div>
            <div className={"RegisterModal__submit"}>
              <TailwindButton
                justifyContent={"justify-center"}
                backgroundColor={"primary"}
                rounded={false}
                disabled={authStore!.isActionInProgress}
                showSpinner={authStore!.isActionInProgress}
                onClick={clickedNext}>
                {intl.formatMessage({
                  id: "common.next",
                  defaultMessage: "Next",
                })}
              </TailwindButton>
            </div>
          </form>
        </div>
      </SimpleBar>
    </TailwindFlex>
  );
};
export default injectIntl(
  inject(
    "authStore",
    "validationStore",
    "profileStore"
  )(observer(AgreementStep))
);
