import { makeAutoObservable } from "mobx";
import { api } from "core/utility";
import _ from "lodash";
import {
  FILTER_MODELS_LOGIN_TIME,
  IStudioModel,
  StudioModelAccessRights,
} from "./interfaces";
import { snackbarStore } from "library/core/stores/snackbar/SnackbarStore";
import { logger } from "library/core/utility";
import AuthStore from "core/stores/auth/AuthStore";
import config from "core/config";
import moment, { unitOfTime } from "moment";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";
import { authStore, profileStore } from "core/stores";

enum SortDirection {
  ASCENDING = "asc",
  DESCENDING = "desc",
}

const logPrefix = "[ModelStore]:";

export default class ModelStore {
  public isLoadingModels: boolean = true;
  public models: IStudioModel[] = [];
  public filteredModels: IStudioModel[] = [];
  public sortKey: string | undefined = undefined;
  public sortDirection: SortDirection = SortDirection.ASCENDING;
  public activeModelFilter: FILTER_MODELS_LOGIN_TIME =
    FILTER_MODELS_LOGIN_TIME.ALL;

  constructor() {
    makeAutoObservable(this);
  }

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  getModels = async (
    updateLoadingStatus = true,
    filter: FILTER_MODELS_LOGIN_TIME = FILTER_MODELS_LOGIN_TIME.ALL //by default "all" should be selected https://jira.friendfinderinc.com/browse/CAMSRBT-5778
  ) => {
    if (updateLoadingStatus) {
      this.isLoadingModels = true;
    }
    await profileStore.initProfile();

    const { profile } = profileStore;

    try {
      const {
        data: { results },
      } = await api
        .getStudioManagementModelsEndpoint(profile?.id)
        .get(undefined, "?page_size=2000");
      const {
        data: { results: accessRightResults },
      } = await api.studioManagementModelsAccessRights.get();

      const models: IStudioModel[] = results.map(model => {
        return {
          modelId: model.id,
          modified_at: model.modified_at,
          registered_at: model.registered_at,
          model_full_name: `${model.first_name} ${model.last_name}`,
          first_name: model.first_name,
          last_name: model.last_name,
          other_name: model.other_name,
          username: model.username,
          gender: model.gender,
          birthdate: model.birthdate,
          phone_number: model.phone_number,
          address: model.address,
          city: model.city,
          state: model.state,
          zip: model.zip,
          country: model.iso_country,
          email: model.email,
          password: "",
          password_confirm: "",
          screen_name: model.screen_name,
          profile_image: model.profile_image,
          document: {
            document_type: model.document?.document_type || "",
            issue_country: model.document?.issue_country || "",
            expire_date: model.document?.expire_date
              ? new Date(model.document?.expire_date).toISOString()
              : new Date().toISOString(),
            document_front_key_view_url: model.document?.document_front || "",
            document_back_key_view_url: model.document?.document_back || "",
            document_verification_key_view_url:
              model.document?.document_verification || "",
          },
          missing_signup_fields: model.missing_signup_fields,
          status: model.status,
          access_rights: accessRightResults.find(
            right => right.model === model.id
          ),
          is_online: model.is_online,
          last_login_datetime: model.last_login_datetime || "",
        };
      });

      this.models = models;
      this.setModelsFilter(filter);
    } catch (e) {
      logger.log("getting studio's models error", e);
    } finally {
      this.isLoadingModels = false;
    }
  };

  pollModels = () => {
    this.getModels(false, this.activeModelFilter);
  };

  updateFilteredModels = () => {
    if (this.activeModelFilter !== FILTER_MODELS_LOGIN_TIME.ALL) {
      if (this.activeModelFilter === FILTER_MODELS_LOGIN_TIME.ONLINE_NOW) {
        this.filteredModels = this.models.filter(model => model.is_online);
      } else {
        const unitOfTime = this.activeModelFilter as unitOfTime.Base;
        this.filteredModels = this.models.filter(model => {
          const res = moment().diff(model.modified_at, unitOfTime);

          return res === 0;
        });
      }
    } else {
      this.filteredModels = this.models;
    }
  };

  get sortedModels() {
    return this.sortModelsByLastLoginDate(this.filteredModels);
  }

  sortModelsByLastLoginDate = (models: IStudioModel[]) => {
    return models
      .slice()
      .sort(
        (modelA, modelB) =>
          new Date(modelB.modified_at).getTime() -
          new Date(modelA.modified_at).getTime()
      );
  };

  setModelsFilter = (filter: FILTER_MODELS_LOGIN_TIME) => {
    this.log("setModelsFilter started", filter);
    this.activeModelFilter = filter;
    this.updateFilteredModels();
    this.log("setModelsFilter finished");
  };

  impersonateModel = async (modelScreenName: string) => {
    try {
      const {
        data: { refresh },
      } = await api.impersonate.post({
        refresh_token: AuthStore.getRefreshToken(),
        screen_name: modelScreenName,
      });

      window.open(`${config.modelsUrl}/login?r=${refresh}`, "_blank");
    } catch (error) {}
  };

  registerNewModel = () => {
    try {
      authStore.removeReferralsFromCookies();
      authStore.removeModelAuthenticationKeysFromCookies();
      window.open(
        `${config.modelsUrl}/register?logout=model&studio=${profileStore.profile.username}`,
        "_blank"
      );
    } catch (error) {}
  };

  public saveStudioModelAccessRights = async (
    accessRights: Partial<StudioModelAccessRights>
  ) => {
    try {
      const { data } = await api.studioManagementModelsAccessRights.put(
        accessRights,
        `${accessRights?.id}/`
      );
      this.models = this.models.map(model => {
        if (model.modelId === accessRights.model) {
          return {
            ...model,
            access_rights: data,
          };
        }
        return model;
      });

      snackbarStore.enqueueSnackbar({
        message: {
          id: "studio-model-management.model-permission.saved",
          default: "Model's Access Rights have been saved",
        },
        variant: SnackbarVariants.SUCCESS,
      });
    } catch (error) {
      snackbarStore.enqueueSnackbar({
        message: {
          id: "studio-model-management.model-permission.errored",
          default: "Model's Access Rights could not be saved",
        },
        variant: SnackbarVariants.ERROR,
      });
    }
  };

  public sortModels = field => {
    const newDirection =
      this.sortKey === field
        ? this.sortDirection === SortDirection.DESCENDING
          ? SortDirection.ASCENDING
          : SortDirection.DESCENDING
        : SortDirection.ASCENDING;
    this.models = _.orderBy(
      this.models,
      [field],
      [newDirection]
    ) as IStudioModel[];
    this.sortKey = field;
    this.sortDirection = newDirection;
    this.updateFilteredModels();
  };

  public getStudioModel = (modelId: string): IStudioModel | undefined => {
    const selectedModel = this.models.find(model => model.modelId === modelId);
    return selectedModel;
  };
}
