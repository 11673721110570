import React from "react";
import TailwindButton, { TailwindButtonProps } from "../_tailwind/button";
import toast from "react-hot-toast";

type SnackbarActionButtonProps = TailwindButtonProps & {
  id: string | number | undefined;
  callbackParams?: any;
  onClick: (...args: any[]) => void;
  label: string;
};

const SnackbarActionButton: React.ComponentType<SnackbarActionButtonProps> = ({
  id,
  onClick,
  callbackParams = {},
  label = "",
  ...props
}) => {
  return (
    <TailwindButton
      size='sm'
      rounded={false}
      padding={["px-2"]}
      fullWidth={false}
      textProps={{ textTransform: "uppercase" }}
      onClick={() => {
        onClick(callbackParams);
        toast.dismiss(id);
      }}
      {...props}>
      {label}
    </TailwindButton>
  );
};

export default SnackbarActionButton;
