import React from "react";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";

import TailwindFlex from "library/components/_tailwind/flex";
import EarningsTableContainer from "../earnings-table/earnings-table-container";
import EarningsTableSimpleContainer from "../earnings-table-simple/earnings-table-simple-container";

type PayoutBasicTableProps = {
  detailsVisible: boolean;
  showDetails?: any;
  isSimple?: boolean;
};

const PayoutBasicTable: React.FunctionComponent<PayoutBasicTableProps & WrappedComponentProps> = ({
  showDetails,
  detailsVisible,
  isSimple,
}) => {
  return (
    <TailwindFlex flexDirection={"flex-col"} overflow={["overflow-hidden"]}>
      <TailwindFlex margin={["mb-4"]} overflow={["overflow-auto"]}>
        {isSimple ? (
          <EarningsTableSimpleContainer />
        ) : (
          <EarningsTableContainer detailsVisible={detailsVisible} showDetails={showDetails} />
        )}
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(inject("earningsStore")(observer(PayoutBasicTable)));
