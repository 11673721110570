import React from "react";
import clsx from "clsx";

type TextSizeIconProps = {
  className?: string;
};

const TextSizeIcon: React.FunctionComponent<TextSizeIconProps> = ({
  className = "",
}) => (
  <span className={clsx("TextSizeIcon", className && className)}>
    <svg viewBox="0 0 26 26">
      <path
        transform="translate(1, 1)"
        d="M9 4v3h5v12h3V7h5V4H9zm-6 8h3v7h3v-7h3V9H3v3z"
      />
    </svg>
  </span>
);

export default TextSizeIcon;
