import React from "react";
import "./styles.scss";
import classnames from "classnames";

type ScrollTopIconProps = {
  className?: string;
};

const ScrollTopIcon: React.ComponentType<ScrollTopIconProps> = ({
  className,
}) => (
  <span className={classnames("ScrollTopIcon", className && className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
      id="Layer_1_1611918293085"
      x="0px"
      y="0px"
      xmlSpace="preserve"
    >
      <g transform="translate(1, 1)">
        <g transform="translate(1, 1)">
          <path
            className="st0_1611918293085"
            d="M3.16,14.14h13.68c1.1,0,1.58-1.28,0.71-1.9l-6.84-4.87c-0.42-0.3-1.01-0.3-1.42,0l-6.84,4.87  C1.58,12.86,2.06,14.14,3.16,14.14z"
            vectorEffect="non-scaling-stroke"
          />
          <path
            className="st0_1611918293085"
            d="M12.97,20H7.03C6.46,20,6,19.54,6,18.97v-4.94C6,13.46,6.46,13,7.03,13h5.94c0.57,0,1.03,0.46,1.03,1.03v4.94  C14,19.54,13.54,20,12.97,20z"
            vectorEffect="non-scaling-stroke"
          />
          <path
            className="st0_1611918293085"
            d="M18,2H2C1.45,2,1,1.55,1,1v0c0-0.55,0.45-1,1-1h16c0.55,0,1,0.45,1,1v0C19,1.55,18.55,2,18,2z"
            vectorEffect="non-scaling-stroke"
          />
        </g>
      </g>
    </svg>
  </span>
);

export default ScrollTopIcon;
