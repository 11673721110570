import { ModelProfile, StudioProfile, TaxFormStatus } from "./types";
import { UserAccountStatus } from "common/my-page/stores/profile/enums";

export const EMPTY_MODEL_GROUPED_STAR_RATINGS = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  count: 0,
  score: 0,
};

export const PROFILE_ERROR_DEFAULTS = {
  fun_fact: null,
  about_me: null,
  first_name: null,
  last_name: null,
  age: null,
  astrological_sign: null,
  sexual_orientation: null,
  body_type: null,
  hair_color: null,
  eye_color: null,
  penis_size: null,
  breast_size: null,
  gender: null,
  birth_date: null,
  region: null,
  ethnicity: null,
  shaved: null,
  username: null,
  language: null,
  description: null,
  non_nude_profile_photo: null,
  nude_profile_photo: null,
  issue_country: null,
  document_type: null,
  expire_date: null,
  document_front_key: null,
  document_back_key: null,
  document_verification_key: null,
};
export const STUDIO_PROFILE_DEFAULTS: StudioProfile = {
  id: "",
  gender: "female",
  username: "",
  name: "",
  first_name: "",
  other_name: "",
  last_name: "",
  profile_image: "",
  missing_signup_fields: [],
  country: "",
  iso_country: "",
  signed_agreements: [],
  status: UserAccountStatus.PENDING,
  email: "",
  region: "",
  phone_number: "",
  state: "",
  city: "",
  zip_code: "",
  address: "",
  address2: "",
  deny_reasons: [],
  tax_form: {
    created_at: null,
    modified_at: null,
    status: TaxFormStatus.PENDING,
    tax_file: null,
  },
};
export const MODEL_PROFILE_DEFAULTS: ModelProfile = {
  id: "",
  screen_name: "",
  fun_fact: "",
  pending_fun_fact: "",
  about_me: "",
  gender: "female",
  username: "",
  name: "",
  first_name: "",
  other_name: "",
  last_name: "",
  age: 0,
  astrological_sign: "",
  sexual_orientation: "",
  body_type: "",
  hair_color: "",
  eye_color: "",
  penis_size: "",
  public_birthdate: "",
  birthdate: "",
  region: "",
  ethnicity: "",
  shaved: false,
  languages: [],
  description: "",
  non_nude_profile_image: null,
  pending_non_nude_profile_image: null,
  happy_hour_price: 0,
  whisper_message_price: 0,
  private_chat_price: 0,
  voyeur_chat_price: 0,
  party_chat_price: 0,
  nude_chat_price: 0,
  fan_club_private_chat_price: 0,
  fan_club_party_chat_price: 0,
  call_me_price: 0,
  text_me_price: 0,
  buzzmode_price: 0,
  wheel_of_fun_price: 0,
  connexion_price: 0,
  profile_image: null,
  pending_profile_image: null,
  favorites_stats: { growth: 0, last_30_days: 0 },
  missing_signup_fields: [],
  country: "",
  iso_country: "",
  private_show_rating: 0,
  kinks: [],
  attributes: [],
  product_pricing: {
    buzz_price: 3,
    buzz_status: "on",
    call_me: 30,
    cam2cam_chat: 10,
    chat_settings: null,
    connexion_one_way: 10,
    connexion_two_way: 10,
    is_happy_hour: true,
    is_party_allowed: true,
    is_voyeur_allowed: true,
    is_wheel_of_fun_allowed: true,
    nude_chat: 30,
    party_chat: 35,
    private_chat: 35,
    private_chat_status: "on",
    superbuzz_price: 9,
    text_me: 10,
    voyeur_chat: 20,
    wheel_of_fun: 20,
    wheel_of_fun_rewards: null,
    whisper_message: 1,
  },
  specialities: [],
  signed_agreements: [],
  tax_form: {
    created_at: null,
    modified_at: null,
    status: TaxFormStatus.PENDING,
    tax_file: null,
  },
  grouped_star_ratings: {},
  status: UserAccountStatus.PENDING,
  email: "",
  public_profile_image: "",
  public_profile_images: {},
  is_model_profile: false,
  is_model_studio: null,
  marital_status: "",
  height: 0,
  deny_reasons: [],
};

export const InitialData = {
  modelProfile: MODEL_PROFILE_DEFAULTS,
  studioProfile: STUDIO_PROFILE_DEFAULTS,
  profileErrors: PROFILE_ERROR_DEFAULTS,
  loadingProfile: false,
  profileRegistrationCompleted: false,
  profileInfoCompleted: false,
  profileCompletion: [],
  profileAccountStatus: null,
  alreadyOfferedToCompleteProfile: false,
  loadingProfileCompletion: false,
  isProfileImagesInProgress: false,
  isProfileBioFieldsComplete: false,
  profileLoaded: false,
  profileImage: null,
  nonNudeProfileImage: null,
  publicProfileImage: null,
  publicNonNudeProfileImage: null,
  activeAdmirerId: null,
  languages: [],
  languagesHashmap: {},
  kinks: null,
  kinksHashmap: null,
  specialities: null,
  specialitiesHashmap: null,
  attributes: null,
  attributesHashmap: null,
  nonNudeProfileImageProgress: 0,
  profileImageProgress: 0,
  shouldShowTutorialToModel: false,
  shouldHideBroadcastTutorialToModel: true,
};

export const STUDIO_PROFILE_VALIDATION_FIELDS = [
  "first_name",
  "last_name",
  "country",
  "city",
  "phone_number",
  "zip_code",
  "username",
  "address",
];

export const MODEL_PROFILE_FIELDS_TO_BE_VALIDATED = {
  female: ["breast_size"],
  male: ["penis_size"],
  trans: ["penis_size", "breast_size"],
};

const MODEL_COMMON_PROFILE_FIELDS_TO_BE_PATCHED = [
  "fun_fact",
  "iso_country",
  "gender",
  "ethnicity",
  "eye_color",
  "hair_color",
  "specialities",
  "kinks",
  "attributes",
  "astrological_sign",
  "languages",
  "age",
  "sexual_orientation",
  "body_type",
];

export const MODEL_PROFILE_FIELDS_TO_BE_PATCHED = {
  female: [
    ...MODEL_PROFILE_FIELDS_TO_BE_VALIDATED.female,
    ...MODEL_COMMON_PROFILE_FIELDS_TO_BE_PATCHED,
  ],
  male: [
    ...MODEL_PROFILE_FIELDS_TO_BE_VALIDATED.male,
    ...MODEL_COMMON_PROFILE_FIELDS_TO_BE_PATCHED,
  ],
  trans: [
    ...MODEL_PROFILE_FIELDS_TO_BE_VALIDATED.trans,
    ...MODEL_COMMON_PROFILE_FIELDS_TO_BE_PATCHED,
  ],
};

export const MODEL_2257_FLAG_CODES = [
  "AC",
  "AP",
  "CV",
  "ECM",
  "2U",
  "2M",
  "FN",
  "PS",
  "EBT",
  "2P",
  "2TS",
  "MF",
  "UA",
  "AD",
  "OV",
  "UM",
  "PVD",
  "NF",
  "VI",
  "I2",
  "SD",
  "AB",
  "CB",
  "B2",
  "DF",
  "UR",
  "NNN",
  "UO",
  "TMM",
  "TG",
  "LTY",
  "SP",
  "2F",
  "OSDM",
  "FF",
  "ESCM",
  "BA",
  "SA",
  "CP",
  "NC",
  "EOM",
  "SG",
  "EB",
  "CB2",
  "SI",
  "SSEX",
  "DTH",
  "BAS",
  "TIF",
  "PAU",
];

export const MODEL_2257_FLAG_ACTION_NAMES = [
  "EMPTY",
  "WARNING",
  "SUSPEND_24H",
  "SUSPEND_48H",
  "SUSPEND_72H",
  "SUSPEND_INDEFINITELY",
  "TERMINATION_BLACKLIST",
];
