import { injectIntl, WrappedComponentProps } from "react-intl";
import React, { useRef } from "react";
import TailwindCard from "library/components/_tailwind/card";
import TailwindBox from "library/components/_tailwind/box";
import useOutsideClick from "library/core/utility/hooks/useOutsideClick";

type Props = {
  children?: any;
  onClose: () => void;
};

const BroadcastingSettingsModal: React.FunctionComponent<
  Props & WrappedComponentProps
> = ({ onClose, children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useOutsideClick(containerRef, onClose);
  return (
    <TailwindBox>
      <TailwindCard
        position={"fixed"}
        display={"flex"}
        inset={["inset-0"]}
        backgroundColor={"bg-white"}
        backgroundOpacity={"bg-opacity-25"}
        backdropBlur={"backdrop-blur-lg"}
        backdropFilter={"backdrop-filter"}
        className={["broadcasting-settings-modal"]}
        blur={"blur"}
        zIndex={"z-900"}
        overflow={["overflow-y-hidden"]}
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        height={"h-full"}
        maxHeight={"max-h-full"}
        width={"w-full"}>
        <TailwindCard
          ref={containerRef}
          position={"relative"}
          inset={["inset-0"]}
          backgroundColor={"secondary"}
          zIndex={"z-900"}
          borderRadius={"rounded-md"}
          padding={["p-4"]}
          overflow={["overflow-y-auto"]}
          width={"w-1/2"}
          pseudoClasses={["md:w-full", "md:m-4"]}
          height={"h-auto"}
          maxHeight={"max-h-full"}>
          {children}
        </TailwindCard>
      </TailwindCard>
    </TailwindBox>
  );
};

export default injectIntl(BroadcastingSettingsModal);
