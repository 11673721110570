import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import StudioPayoutTableHead from "./studio-payout-table-head";
import StudioPayoutTableBody from "./studio-payout-table-body";
import StudioPayoutTableTotal from "./studio-payout-table-total";

type Props = {};

const StudioPayoutContainer: React.ComponentType<Props> = ({}) => {
  return (
    <TailwindFlex
      flexDirection={"flex-row"}
      margin={["mt-4"]}
      height={"h-full"}
      width={"w-full"}
      overflow={"overflow-hidden"}
      borderRadius={"rounded-xl"}>
      <TailwindFlex
        width={"w-full"}
        height={"h-full"}
        overflow={["overflow-y-hidden", "overflow-x-auto"]}
        backgroundColor={"bg-primary-bg-color"}
        flexDirection={"flex-col"}>
        <TailwindFlex
          minWidth={"min-w-280"}
          flexDirection={"flex-col"}
          height={"h-full"}
          width={"w-full"}>
          <StudioPayoutTableHead />
          <StudioPayoutTableBody />
          <StudioPayoutTableTotal />
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default StudioPayoutContainer;
