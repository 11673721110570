import { ThemeColorPalette } from "./types";
import Color from "color";
import { ThemeSite } from "./enums";
import config from "../../../core/config";
import { UserRole } from "../../types";

export const getUserRoleFromDomain = () => {
  let role: UserRole | null =
    config.env === "development" && config.userRole
      ? (config.userRole as UserRole)
      : null;
  const splitLocation = window.location.host.split(".");
  if (splitLocation && splitLocation.length) {
    const subdomain = splitLocation[0];
    let userRole;
    if (subdomain.includes("models")) {
      userRole = "model";
    } else if (subdomain.includes("studios")) {
      userRole = "studio";
    } else if (subdomain.includes("aff")) {
      userRole = "aff";
    }

    if (userRole) {
      role = userRole as UserRole;
    }
  }
  return role as UserRole;
};

export const getUpdatedColorPalette = (
  defaultThemePalette: ThemeColorPalette,
  colors: Partial<ThemeColorPalette>
): ThemeColorPalette => {
  return {
    ...defaultThemePalette,
    "primary-bg-color":
      colors["primary-bg-color"] || defaultThemePalette["primary-bg-color"],
    "primary-bg-color-rgb": Color(
      colors["primary-bg-color"] || defaultThemePalette["primary-bg-color"]
    )
      .rgb()
      .array(),
    "secondary-bg-color":
      colors["secondary-bg-color"] || defaultThemePalette["secondary-bg-color"],
    "secondary-bg-color-rgb": Color(
      colors["secondary-bg-color"] || defaultThemePalette["secondary-bg-color"]
    )
      .rgb()
      .array(),
    "strong-color":
      colors["strong-color"] || defaultThemePalette["strong-color"],
    "strong-color-rgb": Color(
      colors["strong-color"] || defaultThemePalette["strong-color"]
    )
      .rgb()
      .array(),
    "title-color-1":
      colors["title-color-1"] || defaultThemePalette["title-color-1"],
    "title-color-1-rgb": Color(
      colors["title-color-1"] || defaultThemePalette["title-color-1"]
    )
      .rgb()
      .array(),
    "title-color-1-inversed": Color(
      colors["title-color-1"] || defaultThemePalette["title-color-1"]
    )
      .negate()
      .hex(),
    "title-color-1-darken-10": Color(
      colors["title-color-1"] || defaultThemePalette["title-color-1"]
    )
      .darken(0.1)
      .hex(),
    "title-color-2":
      colors["title-color-2"] || defaultThemePalette["title-color-2"],
    "title-color-2-rgb": Color(
      colors["title-color-2"] || defaultThemePalette["title-color-2"]
    )
      .rgb()
      .array(),
    "border-color-1":
      colors["border-color-1"] || defaultThemePalette["border-color-1"],
    "border-color-1-rgb": Color(
      colors["border-color-1"] || defaultThemePalette["border-color-1"]
    )
      .rgb()
      .array(),
    "sky-blue-color":
      colors["sky-blue-color"] || defaultThemePalette["sky-blue-color"],
    "sky-blue-color-rgb": Color(
      colors["sky-blue-color"] || defaultThemePalette["sky-blue-color"]
    )
      .rgb()
      .array(),
    "pink-color": colors["pink-color"] || defaultThemePalette["pink-color"],
    "pink-color-rgb": Color(
      colors["pink-color"] || defaultThemePalette["pink-color"]
    )
      .rgb()
      .array(),
  };
};

export const getThemeSiteFromDomain = () => {
  const userRole = getUserRoleFromDomain();
  switch (userRole) {
    case "model":
      return ThemeSite.cams;
    case "studio":
      return ThemeSite.cams;
    case "aff":
      return ThemeSite.aff;
    default:
      return ThemeSite.cams;
  }

  return ThemeSite.cams;
};
