import { inject, observer } from "mobx-react";
import React, { useCallback, useMemo, useState } from "react";
import Modal from "library/components/modal";
import TailwindCard from "library/components/_tailwind/card";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import TailwindButton from "library/components/_tailwind/button";
import { MessageDescriptor } from "react-intl";

interface Props {
  broadcastStreamStore?: BroadcastStreamStore;
  errorContent?: React.ReactNode;
}

const BroadcastErrorModal: React.FunctionComponent<Props> = ({
  broadcastStreamStore,
}) => {
  const {
    clearErrors,
    broadcastError,
    broadcastErrorAction,
    setBroadcastError,
  } = broadcastStreamStore!;
  const [isActionInProgress, setIsActionInProgress] = useState<boolean>(false);

  const closeModal = useCallback(() => {
    clearErrors();
  }, []);

  const onClickBroadcastErrorAction = useCallback(async () => {
    if (broadcastErrorAction) {
      setIsActionInProgress(true);
      await broadcastErrorAction.onClick();
      setIsActionInProgress(false);
      setBroadcastError(null);
    }
  }, [broadcastErrorAction]);

  const errorComponent = useMemo(() => {
    if (typeof broadcastError === "object" && broadcastError?.id) {
      return (
        <TailwindTranslatedText
          fontWeight={"font-bold"}
          textAlign={"text-center"}
          descriptor={broadcastError as MessageDescriptor}
        />
      );
    } else if (typeof broadcastError === "string") {
      switch (broadcastError) {
      }
    }
  }, [broadcastError]);

  return (
    <Modal
      open={!!broadcastError}
      showNativeCloseButton={
        typeof broadcastError === "object" &&
        broadcastError?.id === "broadcast-error.stopping-stream"
          ? false
          : true
      }
      closeModal={closeModal}>
      <TailwindCard
        backgroundColor={"primary"}
        flexDirection={"flex-col"}
        height={"h-auto"}
        minHeight={"min-h-96"}
        maxHeight={"max-h-screen"}
        width={"w-192"}
        pseudoClasses={["md:w-full"]}
        padding={["p-4"]}
        display={"flex"}
        alignItems={"items-center"}
        justifyContent={"justify-center"}>
        {errorComponent}
        {broadcastErrorAction && (
          <TailwindButton
            disabled={isActionInProgress}
            showSpinner={isActionInProgress}
            margin={["mt-8"]}
            fullWidth={false}
            backgroundColor={"primary"}
            onClick={onClickBroadcastErrorAction}>
            {broadcastErrorAction.buttonLabel}
          </TailwindButton>
        )}
      </TailwindCard>
    </Modal>
  );
};

export default inject("broadcastStreamStore")(observer(BroadcastErrorModal));
