import React from "react";
import TailwindHeading from "../../components/_tailwind/heading";

type Props = {};

const StorybookHeading: React.ComponentType<Props> = ({ children }) => {
  return (
    <TailwindHeading level={5} margin={["mb-8"]}>
      {children}
    </TailwindHeading>
  );
};

export default StorybookHeading;
