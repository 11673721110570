import React from "react";
import MediaManagerSidebarBodyAlbumName from "containers/_components/media-manager/media-manager-sidebar-body-album-name";
import MediaManagerSidebarBodyDescriptionRecordedShows from "containers/_components/media-manager/media-manager-sidebar-body-description-recorded-shows";
import MediaManagerSidebarBodyUpload from "containers/_components/media-manager/media-manager-sidebar-body-upload";
import MediaManagerStore from "containers/_components/media-manager/store/MediaManagerStore";
import { inject, observer } from "mobx-react";
import MediaManagerSidebarBodyDescriptionVideos from "containers/_components/media-manager/media-manager-sidebar-body-description-videos";
import MediaManagerSidebarBodyDescriptionPhotosEdit from "./media-manager-sidebar-body-description-photos-edit";

type Props = {
  mediaManagerStore?: MediaManagerStore;
};

const MediaManagerSidebarBody: React.ComponentType<Props> = ({
  mediaManagerStore,
}) => {
  const { selectedAlbum } = mediaManagerStore!;

  if (!selectedAlbum) {
    return null;
  }

  return (
    <React.Fragment>
      {selectedAlbum?.album_type === "custom" && (
          <MediaManagerSidebarBodyAlbumName />
      )}
      {selectedAlbum?.album_type !== "profile" &&
        selectedAlbum?.album_type !== "recorded" && (
          <MediaManagerSidebarBodyUpload />
        )}
      {selectedAlbum?.album_type === "recorded" && (
        <MediaManagerSidebarBodyDescriptionRecordedShows />
      )}
      {selectedAlbum?.album_type === "videos" && (
        <MediaManagerSidebarBodyDescriptionVideos />
      )}
      {selectedAlbum?.album_type === "custom" && (
          <MediaManagerSidebarBodyDescriptionPhotosEdit />
      )}
    </React.Fragment>
  );
};

export default inject("mediaManagerStore")(observer(MediaManagerSidebarBody));
