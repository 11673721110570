import { logger } from "library/core/utility";
import { makeAutoObservable } from "mobx";
import { InitialData } from "./consts";
import { MSG_CENTER_MAX_AUDIO_DURATION_MINUTES } from "../messages/consts";

const logPrefix = "[AudioMessageRecorderStore]:";

export default class AudioMessageRecorderStore {
  isRecording: boolean = InitialData.isRecording;
  autoSubmit: boolean = InitialData.autoSubmit;
  audioSrc: string = InitialData.audioSrc;
  audioType: string = InitialData.audioType;
  audioFile: Blob | null = InitialData.audioFile;
  audioDuration: number = InitialData.audioDuration;
  recordingTime: number = InitialData.recordingTime;

  private recordingTimeInterval: NodeJS.Timeout | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  init = () => {
    this.log("init started");
  };

  resetStore = () => {
    this.log("resetStore started");
    Object.assign(this, InitialData);
    this.log("resetStore finished");
  };

  setAudioDuration = (duration: number) => {
    this.log("setAudioDuration started");
    this.audioDuration = duration;
    this.log("setAudioDuration finished", duration);
  };

  saveAudio = (audioURL: string, type: string, file: Blob) => {
    this.log("saveAudio started");
    this.audioSrc = audioURL;
    this.audioType = type;
    this.audioFile = file;
    this.log("saveAudio finished");
  };

  private clearRecordingInterval = () => {
    this.log("clearRecordingInterval started");
    if (this.recordingTimeInterval) {
      clearInterval(this.recordingTimeInterval);
    }
    this.log("clearRecordingInterval finished");
  };

  start = () => {
    this.log("start started");
    this.audioSrc = "";
    this.setAudioDuration(0);
    this.isRecording = true;

    this.clearRecordingInterval();
    this.recordingTimeInterval = setInterval(() => {
      const newTime = this.recordingTime + 1;

      this.recordingTime = newTime;

      if (newTime >= MSG_CENTER_MAX_AUDIO_DURATION_MINUTES * 60) {
        this.clearRecordingInterval();
        this.isRecording = false;
      }
      this.log("start recording time:", newTime);
    }, 1000);
    this.log("start finished");
  };

  stop = () => {
    this.log("stop started");
    this.isRecording = false;
    this.clearRecordingInterval();
    this.log("stop finished");
  };

  queueAutoSubmit = () => {
    this.log("queueAutoSubmit started");
    this.autoSubmit = true;
    this.log("queueAutoSubmit finished");
  };
}
