import React, { useMemo } from "react";
import SearchBox from "library/components/_tailwind/search-box";
import { inject, observer } from "mobx-react";
import TailwindBox from "library/components/_tailwind/box";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindList, {
  TailwindListType,
} from "library/components/_tailwind/list/";
import TailwindListItem from "library/components/_tailwind/list/list-item";
import TailwindIcon from "library/components/_tailwind/icon";
import CopyToClipboard from "library/core/utility/copy-to-clipboard";
import SnackbarStore from "library/core/stores/snackbar/SnackbarStore";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import LanguageStore from "library/core/stores/language/LanguageStore";

interface Props {
  broadcastStreamStore?: BroadcastStreamStore;
  languageStore?: LanguageStore;
  snackbarStore?: SnackbarStore;
}

const OBSModalTabStep5: React.FunctionComponent<Props> = ({
  broadcastStreamStore,
  languageStore,
  snackbarStore,
}) => {
  const {
    obsBroadcastUrl,
    obsBroadcastKey,
    hasPublishToken,
    isFetchingPublishToken,
    getPublishToken,
  } = broadcastStreamStore!;
  const { enqueueSnackbar } = snackbarStore!;
  
  const { intl } = languageStore!;

  const copyToClipboard = new CopyToClipboard();

  const broadcastUrlValue = useMemo(() => {
    return isFetchingPublishToken
      ? intl.formatMessage({
          id: "broadcast-obs-modal.loading-publish-token",
          defaultMessage: "Loading publish token",
        })
      : hasPublishToken
      ? obsBroadcastUrl
      : intl.formatMessage({
          id: "broadcast-obs-modal.publish-token-not-fetched",
          defaultMessage: "Publish token not fetched",
        });
  }, [intl, isFetchingPublishToken, hasPublishToken, obsBroadcastUrl]);

  return (
    <TailwindBox fontSize={"text-sm"}>
      <TailwindTranslatedText
        fontWeight={"font-bold"}
        textColor='text-main-color'
        fontSize={"text-sm"}
        descriptor={{
          id: 'broadcast-obs-modal.step-5-heading',
          defaultMessage: 'If you have done these steps before, you may ignore the steps and click "Start Streaming" in OBS.'
        }}
        margin={["mb-4"]} />
      <br />
      <br />
      <TailwindTranslatedText
        textColor='text-main-color'
        fontSize={"text-sm"}
        descriptor={{
          id: "broadcast-obs-modal.step-5-body",
          defaultMessage: "Open OBS Software and follow the steps:",
        }}
      />
      <TailwindList type={TailwindListType.ol} margin={["ml-3"]}>
        <TailwindListItem>
          <TailwindTranslatedText
            fontSize={"text-sm"}
            descriptor={{
              id: "broadcast-obs-modal.step-5-body-step-1",
              defaultMessage: 'Click "Settings" on bottom right',
            }}
          />
        </TailwindListItem>
        <TailwindListItem>
          <TailwindTranslatedText
            fontSize={"text-sm"}
            descriptor={{
              id: "broadcast-obs-modal.step-5-body-step-2",
              defaultMessage: 'Choose "Stream" on the top left',
            }}
          />
        </TailwindListItem>
        <TailwindListItem>
          <TailwindTranslatedText
            fontSize={"text-sm"}
            descriptor={{
              id: "broadcast-obs-modal.step-5-body-step-3",
              defaultMessage: 'Choose "Custom" in the "SERVICE" field',
            }}
          />
        </TailwindListItem>
        <TailwindListItem>
          <TailwindTranslatedText
            fontSize={"text-sm"}
            descriptor={{
              id: "broadcast-obs-modal.step-5-body-step-4",
              defaultMessage: 'Copy and paste this into the "SERVER" field:',
            }}
          />
          &nbsp;
          <TailwindBox
            backgroundColor={"bg-white"}
            borderRadius={"rounded-md"}
            width={"w-full"}
            height={"h-8"}
            display={"inline-flex"}>
            <SearchBox
              containerProps={{ inline: true }}
              inputProps={{
                "data-testid": "obs-broadcast-url",
                readOnly: true,
                defaultValue: broadcastUrlValue,
                value: broadcastUrlValue,
                id: obsBroadcastUrl,
                backgroundColor: "bg-transparent",
                flexProps: {
                  inline: true,
                },
              }}
              buttonProps={
                hasPublishToken
                  ? {
                      children: <TailwindIcon name='file_copy' />,
                      onClick: () => {
                        copyToClipboard.copy(obsBroadcastUrl);
                        enqueueSnackbar({
                          message: {
                            id: "admirers.referrals.myReferralLink.memberLinkCopy",
                            default: "LINK COPIED!",
                          },
                          closeButton: true,
                          variant: SnackbarVariants.SUCCESS,
                        });
                      },
                    }
                  : {
                      children: <TailwindIcon name='refresh' />,
                      onClick: () => {
                        if (!isFetchingPublishToken) {
                          getPublishToken();
                        }
                      },
                    }
              }
            />
          </TailwindBox>
        </TailwindListItem>
        <TailwindListItem>
          <TailwindTranslatedText
            fontSize={"text-sm"}
            descriptor={{
              id: "broadcast-obs-modal.step-5-body-step-5",
              defaultMessage:
                'Copy and paste this into the "STREAM KEY" field:',
            }}
          />
          &nbsp;
          <TailwindBox
            backgroundColor={"bg-white"}
            borderRadius={"rounded-md"}
            width={"w-full"}
            height={"h-8"}
            display={"inline-flex"}>
            <SearchBox
              containerProps={{ inline: true }}
              inputProps={{
                "data-testid": "obs-broadcast-key",
                readOnly: true,
                defaultValue: obsBroadcastKey,
                value: obsBroadcastKey,
                id: obsBroadcastKey,
                backgroundColor: "bg-transparent",
                flexProps: {
                  inline: true,
                },
              }}
              buttonProps={{
                children: <TailwindIcon name='file_copy' />,
                onClick: () => {
                  copyToClipboard.copy(obsBroadcastKey);
                  enqueueSnackbar({
                    message: {
                      id: "admirers.referrals.myReferralLink.memberLinkCopy",
                      default: "LINK COPIED!",
                    },
                    closeButton: true,
                    variant: SnackbarVariants.SUCCESS,
                  });
                },
              }}
            />
          </TailwindBox>
        </TailwindListItem>
        <TailwindListItem>
          <TailwindTranslatedText
            fontSize={"text-sm"}
            descriptor={{
              id: "broadcast-obs-modal.step-5-body-step-6",
              defaultMessage: 'Click "OK" to close the window',
            }}
          />
        </TailwindListItem>
        <TailwindListItem>
          <TailwindTranslatedText
            fontSize={"text-sm"}
            descriptor={{
              id: "broadcast-obs-modal.step-5-body-step-7",
              defaultMessage:
                'Click "Start Streaming" and you\'re ready to go live on our site!',
            }}
          />
        </TailwindListItem>
      </TailwindList>
    </TailwindBox>
  );
};

export default inject(
  "broadcastStreamStore",
  "languageStore",
  "snackbarStore"
)(observer(OBSModalTabStep5));
