export default class LocalStorage {
  static set(key: string, value: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      window["top"]?.postMessage(
        {
          action: "local_storage_set",
          key,
          value,
        },
        "*"
      );
    }
  }

  static remove(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      window["top"]?.postMessage(
        {
          action: "local_storage_remove",
          key,
        },
        "*"
      );
    }
  }

  // if you are using a get with cross domain, you will need to add a window event listener on 'message'
  static get(key: string): any {
    try {
      const data = localStorage.getItem(key);
      return !!data ? JSON.parse(data) : "";
    } catch (e) {
      window["top"]?.postMessage(
        {
          action: "local_storage_get",
          key,
        },
        "*"
      );
    }
    return null;
  }

  // use with caution
  static clear(): void {
    try {
      localStorage.clear();
    } catch (e) {
      window["top"]?.postMessage(
        {
          action: "local_storage_clear",
        },
        "*"
      );
    }
  }

  static clearSpecific(keyChoice: (key: string) => boolean): void {
    try {
      Object.keys(localStorage).forEach(
        key => keyChoice(key) && localStorage.removeItem(key)
      );
    } catch (e) {
      window["top"]?.postMessage(
        {
          action: "local_storage_clearSpecific",
          keyChoice: keyChoice,
        },
        "*"
      );
    }
  }
}
