import React, { useCallback, useMemo, useState } from "react";
import { inject, observer } from "mobx-react";
import TailwindTabs from "library/components/_tailwind/tabs";
import BroadcastSettingsTab from "./broadcast-tabs-settings";
import BroadcastViewersTab from "./broadcast-tabs-viewers";
import TailwindFlex from "library/components/_tailwind/flex";
import BroadcastStrategy from "common/broadcast/_stores/BroadcastStrategy";
import TailwindCard from "library/components/_tailwind/card";
import TailwindText from "library/components/_tailwind/text";
import TailwindBox from "library/components/_tailwind/box";
import BroadcastStore from "common/broadcast/_stores/broadcast/BroadcastStore";
import { IShowType, MainTab } from "common/broadcast/_stores/broadcast/enums";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import ShowcaseStore from "core/stores/showcase/ShowcaseStore";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import NodeChatStore from "./_stores/nodechat/NodeChatStore";
import TailwindHeading from "library/components/_tailwind/heading";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindButton from "library/components/_tailwind/button";
import { useCountdown } from "use-moment-countdown";
import LanguageStore from "library/core/stores/language/LanguageStore";

interface BroadcastTabsProps {
  broadcastStore?: BroadcastStore;
  nodeChatStore?: NodeChatStore;
  broadcastStrategy?: BroadcastStrategy;
  layoutStore?: LayoutStore;
  showcaseStore?: ShowcaseStore;
  languageStore?: LanguageStore;
}

const BroadcastTabs: React.FunctionComponent<BroadcastTabsProps> = ({
  broadcastStore,
  nodeChatStore,
  broadcastStrategy,
  layoutStore,
  showcaseStore,
  languageStore,
}) => {
  const { isShowStarted, currentMainTab, setCurrentMainTab, isStartingStream, currentShowType } =
    broadcastStore!;
  const { playingBroadcastShowcase } = showcaseStore!;
  const { disconnect } = broadcastStrategy!;
  const { memberCount, numberOfOnlineGuests, connectionProblems, reconnectTime, reconnectNow } = nodeChatStore!;
  const { start, time } = useCountdown({ s: reconnectTime ? reconnectTime : 0 });
  const { isMobileDevice, isLiteMode } = layoutStore!;
  const { intl } = languageStore!;

  const [isHoveringStopBroadcastButton, setIsHoveringStopBroadcastButton] =
    useState<boolean>(false);

  const onClickStopBroadcast = useCallback(() => {
    disconnect();
  }, []);

  const numberOfMembers = useMemo(() => {
    return isShowStarted ? memberCount : 0;
  }, [memberCount, isShowStarted]);

  const numberOfGuests = useMemo(() => {
    if(!isShowStarted) {
      return 0;
    } else if(currentShowType === IShowType.FREE || currentShowType === IShowType.TIPPING) {
      return numberOfOnlineGuests
    } else {
      return 0;
    }
  }, [isShowStarted, numberOfOnlineGuests, currentShowType]);

  const onMouseEnterStopBroadcastButton = useCallback(() => {
    setIsHoveringStopBroadcastButton(true);
  }, []);

  const onMouseLeaveStopBroadcastButton = useCallback(() => {
    setIsHoveringStopBroadcastButton(false);
  }, []);

  const openChatRules = useCallback(() => {
    if (currentMainTab === MainTab.SHOW_TYPES) {
      const url = isLiteMode
        ? "https://models.streamray.com/p/help.cgi?answer=251&stream=1&category=MODELS_TOP_QUESTIONS"
        : "https://help.streamray.com/models/knowledgebase/show-types-and-rules/";
      window.open(url, "_blank");
    }
  }, [currentMainTab]);
  const showConnectionProblem = useMemo(() => {
    if(connectionProblems && reconnectTime) {
      start();
      return true;
    }
    return false;
  }, [connectionProblems, reconnectTime]);

  return (
    <TailwindFlex
      id={"BroadcastTabs"}
      className={["BroadcastTabs"]}
      flexGrow='flex-grow'
      minHeight='min-h-0'
      flex='flex-1'
      borderRadius={"rounded-xl"}
      overflow={isMobileDevice ? "overflow-y-visible" : "overflow-hidden"}
      backgroundColor='bg-card-secondary-bg-color'
      position='relative'>
      {showConnectionProblem ? (
        <TailwindFlex flexDirection="flex-col" justifyContent="justify-center" alignItems="items-center" padding={['p-6']}>
          <TailwindIcon name="spinner" fontSize="text-xl" margin={['mb-4']} />
          <TailwindHeading textAlign="text-center" level={5} margin={['mb-4']}>We are having trouble connecting to our servers</TailwindHeading>
          <TailwindText margin={['mb-4']}>Attempting to reconnect in: {time.format('mm:ss')}</TailwindText>
          <TailwindButton backgroundColor="primary" fullWidth={false} onClick={() => {reconnectNow()}}>Reconnect Now</TailwindButton>
        </TailwindFlex>
      ) : (
      <TailwindTabs
        height='h-full'
        minHeight='min-h-0'
        activeTabIndex={Object.values(MainTab).indexOf(currentMainTab)}
        onChange={(_activeTabIndex, activeTabId) =>
          setCurrentMainTab(activeTabId as MainTab)
        }
        flexGrow={"flex-grow"}
        key={"broadcast-tabs"}
        tabs={[
          {
            dataTestId: "broadcast-viewers-tab",
            id: MainTab.VIEWERS,
            label: intl.formatMessage({
              id: 'broadcast.viewer-tab',
              defaultMessage: '{numberOfMembers} Member(s) / {numberOfGuests} Guest(s)'
            }, {
              numberOfMembers,
              numberOfGuests
            }),
            component: <BroadcastViewersTab />,
            isHidden: !playingBroadcastShowcase && !isShowStarted,
          },
          {
            dataTestId: "broadcast-show-types-tab",
            id: MainTab.SHOW_TYPES,
            label: intl.formatMessage({
              id: 'common.show-modes',
              defaultMessage: 'Show Types'
            }),
            component: <BroadcastSettingsTab />,
            rightIconProps: {
              name: CustomIconName["question-mark"],
              margin: ["ml-0.5"],
              onClick: openChatRules,
            },
          },
        ]}
        headerProps={{
          margin: ["mb-0"],
          width: "w-full",
          position: "relative",
          buttonContainerProps: {
            style: {
              width: isShowStarted
                ? isHoveringStopBroadcastButton
                  ? "calc(50% - 30px)"
                  : "calc(50% - 15px)"
                : "100%",
            },
          },
          buttonProps: {
            fullWidth: true,
            backgroundColor: "bg-tab-not-selected-color",
            textColor: "text-gray-300",

            justifyContent: "justify-center",
            borderRadius: ["rounded-t-none", "rounded-b-none"],
            rounded: false,
            textProps: {
              fontSize: "text-sm",
            },
          },

          activeButtonProps: {
            backgroundColor: "bg-card-secondary-bg-color",
            textColor: "text-main-color",
          },
          rightChild:
            isShowStarted || isStartingStream ? (
              <TailwindBox
                display={"inline-flex"}
                position='relative'
                inset={["right-0"]}
                width={isHoveringStopBroadcastButton ? "w-auto" : "w-8"}
                height={"h-full"}>
                <TailwindCard height={"h-full"}>
                  <TailwindBox
                    height={"h-full"}
                    display={"flex"}
                    alignItems={"items-center"}
                    justifyContent={"justify-center"}
                    width={isHoveringStopBroadcastButton ? "w-20" : "w-10"}
                    backgroundColor={"bg-red-500"}
                    cursor={"cursor-pointer"}
                    onClick={onClickStopBroadcast}
                    onMouseEnter={onMouseEnterStopBroadcastButton}
                    onMouseLeave={onMouseLeaveStopBroadcastButton}>
                    <TailwindBox
                      borderColor={"border-white"}
                      borderWidth={["border"]}
                      borderRadius={"rounded-full"}
                      margin={
                        isHoveringStopBroadcastButton ? ["ml-0"] : ["-ml-2"]
                      }
                      position='relative'
                      width={"w-6"}
                      height={"h-6"}>
                      <TailwindBox
                        data-testid={"broadcast-end-live-button"}
                        backgroundColor={"bg-white"}
                        position='absolute'
                        borderRadius={"rounded-sm"}
                        inset={["left-1/2", "top-1/2"]}
                        transform={["-translate-x-1/2", "-translate-y-1/2"]}
                        width={"w-3"}
                        height={"h-3"}></TailwindBox>
                    </TailwindBox>
                    {isHoveringStopBroadcastButton && (
                      <TailwindText
                        fontSize={"text-xs"}
                        margin={["ml-1"]}
                        textColor={"text-white"}
                        lineHeight={"leading-none"}
                        fontWeight={"font-bold"}>
                        {intl.formatMessage({
                          id: "common.end-live",
                          defaultMessage: 'End Live'
                        })}
                      </TailwindText>
                    )}
                  </TailwindBox>
                </TailwindCard>
              </TailwindBox>
            ) : undefined,
        }}
        bodyProps={{
          flex: "flex-1",
          minHeight: "min-h-0",
        }}
      />
      )}
      
    </TailwindFlex>
  );
};

export default inject(
  "broadcastStreamStore",
  "nodeChatStore",
  "broadcastStrategy",
  "layoutStore",
  "broadcastStore",
  "showcaseStore",
  "languageStore"
)(observer(BroadcastTabs));
