import React from "react";
import TailwindFlex from "../flex";
import TailwindHeading from "../heading";
import TailwindTranslatedText from "../translated-text";
import TailwindButton from "../button";
import TailwindIcon from "../icon";
import TailwindBox from "../box";

type ShowcaseTooltipProps = {
  index;
  step;
  backProps;
  primaryProps;
  tooltipProps;
  isLastStep;
  size;
  skipProps;
  showSkipButton?: boolean;
  hideBackButton?: boolean;
  showCloseButton?: boolean;
  enableDoneButton?: boolean;
  hideLastStepNextButton?: boolean;
  showStep?: boolean;
};

const ShowcaseTooltip: React.ComponentType<ShowcaseTooltipProps> = ({
  index,
  step,
  skipProps,
  isLastStep,
  size,
  primaryProps,
  tooltipProps,
  backProps,
  showSkipButton,
  hideBackButton,
  showCloseButton,
  enableDoneButton,
  hideLastStepNextButton,
  showStep,
}) => {
  return (
    <>
      {showCloseButton && (
        <TailwindBox
          className={["right-1"]}
          position={"absolute"}
          {...skipProps}>
          <TailwindIcon
            fontSize={"text-2xl"}
            name={"close"}
            textColor={"text-gray-400"}
          />
        </TailwindBox>
      )}
      <TailwindFlex
        borderRadius={"rounded-lg"}
        padding={["p-4", "pt-8"]}
        flexDirection={"flex-col"}
        backgroundColor={"bg-white"}
        minWidth={step?.minWidth || "min-w-md"}
        maxWidth={step?.maxWidth || "max-w-lg"}
        width={step?.width || "w-auto"}
        pseudoClasses={step?.pseudoClasses}
        position={"relative"}
        {...tooltipProps}>
        {step.title && (
          <TailwindHeading level={5}>{step.title}</TailwindHeading>
        )}
        <TailwindFlex width={"w-full"} margin={["mb-4"]}>
          {step.content}
        </TailwindFlex>
        <TailwindFlex width={"w-full"}>
          <TailwindFlex
            width={"w-1/3"}
            alignItems={"items-start"}
            justifyContent={"justify-start"}>
            {index > 0 && !hideBackButton && (
              <TailwindFlex width={"w-auto"}>
                <TailwindButton
                  backgroundColor={"bg-gray-400"}
                  textColor={"text-white"}
                  {...backProps}>
                  <TailwindTranslatedText
                    textColor='text-white'
                    descriptor={{
                      id: "common.back",
                      defaultMessage: "Back",
                    }}
                    headingProps={{ level: 6, textColor: "text-white" }}
                  />
                </TailwindButton>
              </TailwindFlex>
            )}
            {showSkipButton && !(isLastStep && enableDoneButton) && (
              <TailwindFlex width={"w-auto"}>
                <TailwindButton
                  backgroundColor={"bg-transparent"}
                  {...skipProps}>
                  <TailwindTranslatedText
                    descriptor={{
                      id: "common.skip",
                      defaultMessage: "Skip",
                    }}
                  />
                </TailwindButton>
              </TailwindFlex>
            )}
          </TailwindFlex>
          <TailwindFlex
            width={"w-1/3"}
            alignItems={"items-center"}
            justifyContent={"justify-center"}
            textColor='text-gray-400'>
            {showStep && `${index + 1}/${size}`}
          </TailwindFlex>
          <TailwindFlex
            width={"w-1/3"}
            alignItems={"items-end"}
            justifyContent={"justify-end"}>
            {(!isLastStep ||
              (isLastStep && !hideLastStepNextButton && !enableDoneButton)) && (
              <TailwindFlex width={"w-auto"}>
                <TailwindButton
                  backgroundColor={"primary"}
                  rounded={false}
                  {...primaryProps}>
                  <TailwindTranslatedText
                    descriptor={{
                      id: "common.next",
                      defaultMessage: "Next",
                    }}
                    headingProps={{ level: 6, textColor: "text-white" }}
                  />
                </TailwindButton>
              </TailwindFlex>
            )}
            {isLastStep && enableDoneButton && (
              <TailwindFlex width={"w-auto"}>
                <TailwindButton
                  backgroundColor={"primary"}
                  rounded={false}
                  {...primaryProps}>
                  <TailwindTranslatedText
                    descriptor={{
                      id: "common.done",
                      defaultMessage: "Done",
                    }}
                    headingProps={{ level: 6, textColor: "text-white" }}
                  />
                </TailwindButton>
              </TailwindFlex>
            )}
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
    </>
  );
};

export default ShowcaseTooltip;
