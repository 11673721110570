import React from "react";
import "./styles.scss";
import classnames from "classnames";

type AnatomyIconProps = {
  className?: string;
};

const AnatomyIcon: React.ComponentType<AnatomyIconProps> = ({ className }) => (
  <span className={classnames("AnatomyIcon", className && className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 26 26"
    >
      <g transform="translate(1, 1)">
        <g>
          <path
            d="M12 2c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm9 7h-6v13h-2v-6h-2v6H9V9H3V7h18v2z"
            vectorEffect="non-scaling-stroke"
          />
        </g>
      </g>
    </svg>
  </span>
);

export default AnatomyIcon;
