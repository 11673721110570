import { ThemeColorContrast, ThemeSite } from "./enums";
import { action, computed, makeObservable, observable } from "mobx";
import THEMES from "./index";
import {
  DEFAULT_CAMS_DARK_THEME_COLOR_PALETTE,
  DEFAULT_CAMS_LIGHT_THEME_COLOR_PALETTE,
  DEFAULT_CAMS_THEME_COLOR_CONTRAST,
} from "./cams";
import { ThemeColorPalette } from "./types";
import {
  DEFAULT_AFF_DARK_THEME_COLOR_PALETTE,
  DEFAULT_AFF_LIGHT_THEME_COLOR_PALETTE,
  DEFAULT_AFF_THEME_COLOR_CONTRAST,
} from "./aff";
import { site, userRole } from "../consts";

export default class ThemeStore {
  theme: ThemeColorContrast | undefined;
  themeSite: ThemeSite | undefined;
  currentThemePalette: ThemeColorPalette | undefined;

  constructor() {
    makeObservable<ThemeStore, "applyTheme">(this, {
      theme: observable,
      themeSite: observable,
      currentThemePalette: observable,
      updateCurrentThemePalette: action,
      setTheme: action,
      setThemeSite: action,
      setCurrentThemePalette: action,
      setColor: action,
      toggleTheme: action,
      applyTheme: action,
      styleTag: computed,
      defaultThemeContrast: computed,
      defaultThemeColorPalette: computed,
    });

    this.applyTheme();

  }

  updateCurrentThemePalette = () => {
    if (this.themeSite && this.theme && this.defaultThemeColorPalette) {
      this.setCurrentThemePalette(
        THEMES[this.themeSite][this.theme](this.defaultThemeColorPalette)
      );
    }
  };

  setTheme = (theme: ThemeColorContrast) => {
    this.theme = theme;
    this.updateCurrentThemePalette();
  };

  setThemeSite = (themeSite: ThemeSite) => {
    this.themeSite = themeSite;
    this.setTheme(this.defaultThemeContrast);
  };

  setCurrentThemePalette = (currentThemePalette: ThemeColorPalette) => {
    this.currentThemePalette = currentThemePalette;
  };

  setColor = (key: string, value: string) => {
    if (this.currentThemePalette) {
      this.currentThemePalette = this.getUpdatedThemePalette({
        ...this.currentThemePalette,
        [key]: value,
      });
    }
  };

  private getUpdatedThemePalette = (colors: ThemeColorPalette) => {
    if (this.theme && this.themeSite) {
      return THEMES[this.themeSite][this.theme](colors);
    }

    return this.defaultThemeColorPalette;
  };

  toggleTheme = (theme?: ThemeColorContrast) => {
    this.theme =
      theme || this.theme === ThemeColorContrast.dark
        ? ThemeColorContrast.light
        : ThemeColorContrast.dark;
  };

  applyTheme = (theme?: any) => {
    this.setThemeSite(site);
    // if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //   theme ? this.setTheme(theme) : this.setTheme(ThemeColorContrast.dark);
    // } else {
    theme ? this.setTheme(theme) : this.setTheme(ThemeColorContrast.light)
    // }
    this.updateCurrentThemePalette();
    this.addCSSVariablesToBody();
    this.applyThemeToBody(theme);

  };

  applyThemeToBody = (theme) => {

    // if ((theme && theme === ThemeColorContrast.dark) || window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //   const classToAdd = this.darkThemeClass
    //   if (classToAdd.trim() !== "") {
    //     document.body.classList.add(classToAdd);
    //   }
    // } else {
    if (theme) {
      const classToAdd = this.lightThemeClass
      if (classToAdd.trim() !== "") {
        document.body.classList.add(classToAdd);
      }
    }

    // }


  };

  private addCSSVariablesToBody = () => {
    const existingStyleTag = document.getElementById("theme-variables");
    if (existingStyleTag) {
      existingStyleTag.remove();
    }

    const lastElementInHead = document.head.lastChild;

    if (lastElementInHead) {
      document.head.insertBefore(this.styleTag, lastElementInHead.nextSibling);
    }
  };

  get styleTag() {
    const element = document.createElement("style");
    element.id = "theme-variables";
    element.appendChild(document.createTextNode(`:root {`));
    for (const cssVariable in this.currentThemePalette) {
      const variable = this.getCSSVariable(cssVariable);
      if (variable) {
        element.appendChild(variable);
      }
    }
    element.appendChild(document.createTextNode(`}`));

    return element;
  }

  private getCSSVariable = (variable: string) => {
    if (this.currentThemePalette) {
      return document.createTextNode(
        `--${variable}: ${this.currentThemePalette[variable]};`
      );
    }

    return undefined;
  };

  get defaultThemeContrast() {
    let theme: ThemeColorContrast = DEFAULT_CAMS_THEME_COLOR_CONTRAST;
    if (this.themeSite === "cams") {
      theme = DEFAULT_CAMS_THEME_COLOR_CONTRAST;
    } else if (this.themeSite === "aff") {
      theme = DEFAULT_AFF_THEME_COLOR_CONTRAST;
    }

    return theme;
  }

  get defaultThemeColorPalette() {
    let theme: ThemeColorPalette | undefined;
    if (
      this.themeSite === "cams" ||
      userRole === "model" ||
      userRole === "studio"
    ) {
      theme =
        this.theme === ThemeColorContrast.dark
          ? DEFAULT_CAMS_DARK_THEME_COLOR_PALETTE
          : DEFAULT_CAMS_LIGHT_THEME_COLOR_PALETTE;
    } else if (this.themeSite === "aff" || userRole === "aff") {
      theme =
        this.theme === ThemeColorContrast.dark
          ? DEFAULT_AFF_DARK_THEME_COLOR_PALETTE
          : DEFAULT_AFF_LIGHT_THEME_COLOR_PALETTE;
    }

    return theme;
  }

  private get lightThemeClass() {
    return this.getBodyThemeClass(ThemeColorContrast.light);
  }

  // private get darkThemeClass() {
  //   return this.getBodyThemeClass(ThemeColorContrast.dark);
  // }

  private getBodyThemeClass = (theme: ThemeColorContrast) => {
    if (this.theme && this.themeSite) {
      return `theme__${this.themeSite}--${theme}`;
    }

    return "";
  };
}

export const themeStore = new ThemeStore();
