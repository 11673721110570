import { BackdropVariant } from "./types";

export const InitialData = {
  modals: {
    primary: {
      active: false,
      content: null,
      modalProps: { backdropVariant: "none" as BackdropVariant },
    },
    auth: {
      active: false,
      content: null,
      modalProps: { backdropVariant: "none" as BackdropVariant },
    },
    secondary: {
      active: false,
      content: null,
      modalProps: { backdropVariant: "none" as BackdropVariant },
    },
  },
  shouldShowForgotPasswordInLoginModal: true,
};

export const MODAL_WRAPPER_CLASSNAME = "Modal__wrapper";
