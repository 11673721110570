import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindSwitch from "../../components/_tailwind/switch";
import Code from "../../components/_tailwind/code";

type Props = {};

const StorybookComponentsSwitch: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Switch</StorybookHeading>
      <StorybookParagraph>
        Switch is a component that is composed of several different Primitive
        components therefore the props of it are named like{" "}
        <Code inline>boxProps: TailwindBoxProps</Code>. It can be customized via
        some of the opinionated props however it can further be customized via
        the primitive components props if needed. Currently it takes the
        following props:
      </StorybookParagraph>
      <Code>
        {`name?: string;
label?: string;
labelProps?: TailwindTextProps;
onLabel?: string;
onLabelProps?: TailwindTextProps;
onBackgroundColor?: TBackgroundColor;
offLabel?: string;
offLabelProps?: TailwindTextProps;
offBackgroundColor?: TBackgroundColor;
size?: "xs" | "sm" | "normal";
isChecked?: boolean;
onChange?: (isChecked: boolean) => void;
boxProps?: TailwindBoxProps;  
`}
      </Code>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindSwitch
  isChecked={true}
  onChange={() => {}}
  boxProps={{ margin: ["mr-2"] }}
/>
      `}
      >
        <TailwindSwitch
          isChecked={true}
          onChange={() => {}}
          boxProps={{ margin: ["mr-2"] }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindSwitch
  isChecked={false}
  onChange={() => {}}
  boxProps={{ margin: ["mr-2"] }}
/>
`}
      >
        <TailwindSwitch
          isChecked={false}
          onChange={() => {}}
          boxProps={{ margin: ["mr-2"] }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindSwitch
  size={"sm"}
  isChecked={true}
  onChange={() => {}}
  boxProps={{ margin: ["mr-2"] }}
/>
`}
      >
        <TailwindSwitch
          size={"sm"}
          isChecked={true}
          onChange={() => {}}
          boxProps={{ margin: ["mr-2"] }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindSwitch
  size={"sm"}
  isChecked={false}
  onChange={() => {}}
  boxProps={{ margin: ["mr-2"] }}
/>
`}
      >
        <TailwindSwitch
          size={"sm"}
          isChecked={false}
          onChange={() => {}}
          boxProps={{ margin: ["mr-2"] }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindSwitch
  size={"xs"}
  isChecked={true}
  onChange={() => {}}
  boxProps={{ margin: ["mr-2"] }}
/>
`}
      >
        <TailwindSwitch
          size={"xs"}
          isChecked={true}
          onChange={() => {}}
          boxProps={{ margin: ["mr-2"] }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindSwitch
  size={"xs"}
  isChecked={false}
  onChange={() => {}}
  boxProps={{ margin: ["mr-2"] }}
/>
`}
      >
        <TailwindSwitch
          size={"xs"}
          isChecked={false}
          onChange={() => {}}
          boxProps={{ margin: ["mr-2"] }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`        <TailwindSwitch
  onLabel={"ON"}
  offLabel={"OFF"}
  size={"xs"}
  isChecked={true}
  onChange={() => {}}
  boxProps={{ margin: ["mr-2"] }}
/>
`}
      >
        <TailwindSwitch
          onLabel={"ON"}
          offLabel={"OFF"}
          size={"xs"}
          isChecked={true}
          onChange={() => {}}
          boxProps={{ margin: ["mr-2"] }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`
      <TailwindSwitch
          onLabel={"ON"}
          offLabel={"OFF"}
          size={"xs"}
          isChecked={false}
          onChange={() => {}}
          boxProps={{ margin: ["mr-2"] }}
        />
`}
      >
        <TailwindSwitch
          onLabel={"ON"}
          offLabel={"OFF"}
          size={"xs"}
          isChecked={false}
          onChange={() => {}}
          boxProps={{ margin: ["mr-2"] }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindSwitch
  onLabel={"ON"}
  offLabel={"OFF"}
  onBackgroundColor={"bg-yellow-500"}
  offBackgroundColor={"bg-blue-500"}
  size={"xs"}
  isChecked={true}
  onChange={() => {}}
  boxProps={{ margin: ["mr-2"] }}
/>
`}
      >
        <TailwindSwitch
          onLabel={"ON"}
          offLabel={"OFF"}
          onBackgroundColor={"bg-yellow-500"}
          offBackgroundColor={"bg-blue-500"}
          size={"xs"}
          isChecked={true}
          onChange={() => {}}
          boxProps={{ margin: ["mr-2"] }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindSwitch
  onLabel={"ON"}
  offLabel={"OFF"}
  onBackgroundColor={"bg-yellow-500"}
  offBackgroundColor={"bg-blue-500"}
  size={"xs"}
  isChecked={false}
  onChange={() => {}}
  boxProps={{ margin: ["mr-2"] }}
/>
`}
      >
        <TailwindSwitch
          onLabel={"ON"}
          offLabel={"OFF"}
          onBackgroundColor={"bg-yellow-500"}
          offBackgroundColor={"bg-blue-500"}
          size={"xs"}
          isChecked={false}
          onChange={() => {}}
          boxProps={{ margin: ["mr-2"] }}
        />
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsSwitch;
