import React, { useRef, useMemo } from "react";
import ReactTooltip, { TooltipProps } from "react-tooltip";
import { inject, observer } from "mobx-react";
import ReactDOMServer from "react-dom/server";
import shortid from "shortid";
import LayoutStore from "../../../core/stores/layout/LayoutStore";
import classnames from "classnames";
import "./style.scss";
import TailwindBox, { TailwindBoxProps } from "../box";
import { IntlProvider } from "react-intl";
import LanguageStore from "../../../core/stores/language/LanguageStore";

type Props = {
  children: React.ReactElement; //only accept one child
  content: React.ReactNode;
  layoutStore?: LayoutStore;
  boxProps?: TailwindBoxProps;
  languageStore?: LanguageStore;
  chatTooltip?: boolean;
} & TooltipProps;

const TailwindTooltip: React.ComponentType<Props> = ({
  children,
  html = true,
  delayHide = 0, //hide a bit late so user can hover inside the tooltip to be able to click links
  delayShow = 0,
  chatTooltip,
  delayUpdate = 500,
  place = "bottom",
  clickable = false,
  effect = "solid",
  className = "",
  layoutStore,
  boxProps = {},
  content,
  languageStore,
  ...props
}) => {
  const { isMobileDevice } = layoutStore!;
  //const { sanitizeHtml } = formatter;
  const triggerRef = useRef<HTMLElement>(null);
  const { language, messages } = languageStore!;
  const id = shortid.generate();
  const sanitizedHtml = useMemo(
    () =>
      ReactDOMServer.renderToString(
        <IntlProvider // when use renderToString in react-tooltip, it doesnt see intlprovider. So its create a bug.
          locale={language}
          messages={messages}>
          <TailwindBox
            {...boxProps}
            width={"w-full"}
            height={"h-full"}
            textAlign={boxProps?.textAlign || "text-center"}>
            {content}
          </TailwindBox>
        </IntlProvider>
      ),
    [content, boxProps, language, messages]
  );

  return (
    <React.Fragment>
      {React.cloneElement(React.Children.only(children), {
        ref: triggerRef,
        "data-tip": true,
        "data-for": id,
      })}
      <ReactTooltip
        {...props}
        className={classnames(
          "TailwindTooltip",
          isMobileDevice && "TailwindTooltip--mobile",
          chatTooltip && "TailwindTooltip--chatTooltip",
          className
        )}
        html={html}
        id={id}
        delayHide={delayHide}
        delayShow={delayShow}
        delayUpdate={delayUpdate}
        place={place}
        multiline={true}
        overridePosition={({ left, top }, _e, _t, node) => {
          // this is to fix positioning on mobile devices
          return {
            top,
            left: typeof node === "string" ? left : Math.max(left, 0),
          };
        }}
        effect={effect}>
        {sanitizedHtml}
      </ReactTooltip>
    </React.Fragment>
  );
};

export default inject(
  "layoutStore",
  "languageStore"
)(observer(TailwindTooltip));
