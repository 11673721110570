import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookSubHeading from "../_internal/sub-heading";

type Props = {};

const StorybookFlowClickUp: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Flow &gt; ClickUp</StorybookHeading>
      <StorybookParagraph>
        When an MR is created, the links to both Develop and QA MRs need to be
        linked in the task
      </StorybookParagraph>
      <StorybookParagraph>
        The task should has a comment on what was changed, describing what & how
        to test for QA people{" "}
        <strong>(including videos or screenshots if necessary)</strong>.
      </StorybookParagraph>
      <StorybookParagraph>
        Any change you make that is outside the task's main goal, should be
        documented on the task so side-effects of changes do not break
        functionality as QA people only test what's written on task title &
        description. They need to know what else you changed.
      </StorybookParagraph>
      <StorybookParagraph fontWeight={"font-bold"} textColor={"text-red-500"}>
        If there is no jira task, please get it created or create one yourself.
        This is required!
      </StorybookParagraph>
      <StorybookSubHeading>
        If a task requires BE support: create a subtask under the task with BE:
        prefix. Add backend tag (label) to it for filtering easily.
      </StorybookSubHeading>
    </StorybookWrapper>
  );
};

export default StorybookFlowClickUp;
