import * as React from "react";
import { Helmet } from "react-helmet";
import { UserRole } from "../../../core/types";

type PageTitleProps = {
  title: string;
  appendSuffix?: boolean;
  userRole: UserRole | null;
};

const PageTitle: React.FunctionComponent<PageTitleProps> = ({
  title,
  appendSuffix = true,
  userRole,
}) => (
  <Helmet>
    <title>
      {title}
      {appendSuffix
        ? ` | ${
            userRole === "passion"
              ? "Passion"
              : userRole === "aff"
              ? "AdultFriendFinder"
              : "Cams"
          }`
        : ""}
    </title>
  </Helmet>
);

export default PageTitle;
