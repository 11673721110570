import React from "react";

import "./styles.scss";
import TailwindIcon, { TailwindIconProps } from "../_tailwind/icon";

export type ModalCloseButtonProps = Omit<TailwindIconProps, "name"> & {
  closeModal: () => void;
};

const ModalCloseButton: React.ComponentType<ModalCloseButtonProps> = ({
  closeModal,
  inset,
  textColor,
  ...props
}) => (
  <TailwindIcon
    {...props}
    onClick={() => closeModal()}
    name={"close-thicker"}
    inset={inset || ["right-2", "top-2"]}
    pseudoClasses={["sm:top-4", "sm:right-8"]}
    position={"absolute"}
    cursor={"cursor-pointer"}
    textColor={textColor || "text-main-color"}
    fontSize={"text-xs"}
    zIndex={"z-900"}
  />
);

export default ModalCloseButton;
