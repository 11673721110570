import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import "./styles.scss";
import MediaManagerMediaCard from "./media-manager-media-card";
import MediaManagerMediaCardHeader from "./media-manager-media-card-header";
import { observer } from "mobx-react";
import MediaManagerMediaCardBody, {
  MediaManagerMediaCardBodyProps,
} from "./media-manager-media-card-body";
import MediaManagerMediaCardHeaderTitle, {
  MediaManagerMediaCardHeaderTitleProps,
} from "./media-manager-media-card-header-title";
import MediaManagerMediaCardHeaderActions, {
  MediaManagerMediaCardHeaderActionsProps,
} from "./media-manager-media-card-header-actions";
import MediaManagerMediaCardFileUpload, {
  MediaManagerMediaCardFileUploadProps,
} from "./media-manager-media-card-file-upload";
import MediaManagerMediaCardFooter from "./media-manager-media-card-footer";
import MediaManagerMediaCardFooterStatus, {
  MediaManagerMediaCardFooterStatusProps,
} from "./media-manager-media-card-footer-status";
import MediaManagerMediaCardFooterPrice, {
  MediaManagerMediaCardFooterPriceProps,
} from "./media-manager-media-card-footer-price";
import MediaManagerMediaCardFooterUploadProgress, {
  MediaManagerMediaCardFooterUploadProgressProps,
} from "./media-manager-media-card-footer-upload-progress";
import { MediaManagerMediaCardRejectReasonProps } from "./media-manager-media-card-rejection-reason";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindBox from "library/components/_tailwind/box";
import MediaManagerMediaCardVideoDuration, {
  MediaManagerMediaCardVideoDurationProps,
} from "./media-manager-media-card-video-duration";
import MediaManagerMediaCardFooterActions from "./media-manager-media-card-footer-actions";
import MediaManagerMediaCardPurchases, {
  MediaManagerMediaCardPurchasesProps,
} from "./media-manager-media-card-purchases";

type Props = {
  bodyProps: MediaManagerMediaCardBodyProps;
  fileUploadProps?: MediaManagerMediaCardFileUploadProps;
  actionsProps?: Omit<MediaManagerMediaCardHeaderActionsProps, "width">;
  statusProps?: MediaManagerMediaCardFooterStatusProps;
  titleProps?: Omit<MediaManagerMediaCardHeaderTitleProps, "width">;
  videoDurationProps?: MediaManagerMediaCardVideoDurationProps;
  priceProps?: MediaManagerMediaCardFooterPriceProps;
  uploadProgressProps?: MediaManagerMediaCardFooterUploadProgressProps;
  rejectionReasonProps?: MediaManagerMediaCardRejectReasonProps;
  purchasesProps?: MediaManagerMediaCardPurchasesProps;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const MediaManagerMediaThumbnail: React.ComponentType<
  Props & WrappedComponentProps
> = ({
  onClick,
  actionsProps,
  bodyProps,
  statusProps,
  fileUploadProps,
  titleProps,
  uploadProgressProps,
  priceProps,
  rejectionReasonProps,
  videoDurationProps,
  purchasesProps,
}) => {
  return (
    <MediaManagerMediaCard
      rejectionReasonProps={rejectionReasonProps}
      height={"h-auto"}
      onClick={onClick}>
      {(titleProps || priceProps) && (
        <MediaManagerMediaCardHeader height={"h-8"} margin={["mt-2"]} pseudoClasses={["md:mt-0"]}>
          <TailwindBox width='w-2/3'>
            {titleProps && titleProps.name && (
              <MediaManagerMediaCardHeaderTitle
                {...titleProps}
                textProps={{
                  textColor:"text-white",
                }}
                width={"w-auto"}
              />
            )}
          </TailwindBox>
          <TailwindBox>
            {actionsProps && (
              <MediaManagerMediaCardFooterActions {...actionsProps} />
            )}
          </TailwindBox>
        </MediaManagerMediaCardHeader>
      )}
      {videoDurationProps && (
        <TailwindBox
          position='absolute'
          zIndex='z-10'
          inset={[
            "right-2",
            priceProps && priceProps.priceInToken !== 0 ? "top-12" : "top-2",
          ]}>
        </TailwindBox>
      )}
      <MediaManagerMediaCardBody 
        {...bodyProps}
        isCover={actionsProps?.isCover}
        isProfilePic={actionsProps?.isProfilePic}
        isNonNudeProfilePic={actionsProps?.isNonNudeProfilePic}
        isProfile={actionsProps?.isProfile}
        boxProps={{
          minHeight:"min-h-24"
        }}
        imageProps={{
          height: "h-80"
        }}
        >
        {fileUploadProps && (
          <MediaManagerMediaCardFileUpload {...fileUploadProps} />
        )}
        {actionsProps && (
          <MediaManagerMediaCardHeaderActions 
            {...actionsProps} />
        )}
        {(priceProps || statusProps || actionsProps || uploadProgressProps || videoDurationProps) && (
          <MediaManagerMediaCardFooter height={"h-12"}>
            {priceProps && <MediaManagerMediaCardFooterPrice {...priceProps} />}
            {uploadProgressProps && uploadProgressProps.uploadProgress < 100 ? (
              <MediaManagerMediaCardFooterUploadProgress
                {...uploadProgressProps}
              />
            ) : (
              <React.Fragment>
                {statusProps && (
                  <TailwindFlex 
                    width={"w-full"} 
                    margin={["mt-2"]} 
                    style={{
                      display: statusProps ? "none" : ""
                    }}>
                    {statusProps && (
                      <TailwindBox margin={["mr-0"]}>
                        <MediaManagerMediaCardFooterStatus {...statusProps} />
                      </TailwindBox>
                    )}
                  </TailwindFlex>
                )}

                {purchasesProps && (
                  <TailwindFlex width={"w-full"} justifyContent={"justify-center"}>
                    {purchasesProps && (
                      <MediaManagerMediaCardPurchases {...purchasesProps} />
                    )}
                  </TailwindFlex>)}

                {videoDurationProps && (
                <MediaManagerMediaCardVideoDuration
                  duration={videoDurationProps.duration}
                  DurationBoxProps={{
                    backgroundColor: "bg-gray-600",
                    borderRadius: "rounded-3xl",
                    padding: ["py-1", "px-2"],
                    pseudoClasses: ["md:py-2"]
                  }}
                />)}

              </React.Fragment>
            )}
          </MediaManagerMediaCardFooter>
        )}
      </MediaManagerMediaCardBody>
    </MediaManagerMediaCard>
  );
};

export default injectIntl(observer(MediaManagerMediaThumbnail));
