import React from "react";
import "./styles.scss";
import classnames from "classnames";

type Props = {
  isExpanded?: boolean;
};

const NavigationItemDropdown: React.ComponentType<Props> = ({
  isExpanded,
  children,
}) => {
  return (
    <div
      className={classnames(
        "NavigationItem__dropdown",
        isExpanded && "NavigationItem__dropdown--expanded"
      )}
    >
      {children}
    </div>
  );
};

export default NavigationItemDropdown;
