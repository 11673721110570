import React from "react";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindParagraph from "library/components/_tailwind/paragraph";
import TailwindBox from "library/components/_tailwind/box";

interface Props {}

const OBSModalTabStep3: React.FunctionComponent<Props> = () => {
  return (
    <TailwindBox>
      <TailwindTranslatedText
        paragraphProps={{
          margin: ["mb-2"],
        }}
        descriptor={{
          id: "broadcast-obs-modal.step-3-body",
          defaultMessage:
            "Once OBS is installed, start the program first and then configure it. Learn how to configure OBS on Windows or Mac OS.",
        }}
      />
      <TailwindParagraph margin={["mb-2"]}>
        <a
          className={"text-indigo-700"}
          href='https://help.streamray.com/models/knowledgebase/how-to-use-obs-for-broadcasting-in-hd-high-definition/'
          target='new'>
          Windows
        </a>
      </TailwindParagraph>

      <TailwindParagraph margin={["mb-2"]}>
        <a
          className={"text-indigo-700"}
          href='https://help.streamray.com/models/knowledgebase/how-to-use-obs-for-broadcasting-in-hd-high-definition/'
          target='new'>
          Mac OS
        </a>
      </TailwindParagraph>
    </TailwindBox>
  );
};

export default OBSModalTabStep3;
