import React from "react";
import { inject, observer } from "mobx-react";
import BroadcastStore from "common/broadcast/_stores/broadcast/BroadcastStore";
import TailwindButton from "library/components/_tailwind/button";
import { BROADCAST_END_TIPPING_SHOW_EARLY_SHOW_STOP_WARNING_TIME_IN_MINUTES } from "common/broadcast/_stores/broadcast/consts";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindSwitch from "library/components/_tailwind/switch";
import { history } from "library/core/utility";

type Props = {
  broadcastStore?: BroadcastStore;
  profileStore?: ProfileStore;
};

const QAWidgetMisc: React.ComponentType<Props> = ({
  broadcastStore,
  profileStore,
}) => {
  const {
    endTippingShowEarlyShowStopWarningTimeInSeconds,
    setEndTippingShowEarlyShowStopWarningTimeInSeconds,
  } = broadcastStore!;
  const { modelProfile, toggleOptInToMemberVerification } = profileStore!;

  return (
    <TailwindFlex flexDirection='flex-col' gap='gap-4'>
      <TailwindFlex gap='gap-2'>
        <div>Opt in account to Member Verification</div>
        <TailwindSwitch
          onLabel={"On"}
          offLabel={"Off"}
          size={"xs"}
          onChange={async isChecked => {
            await toggleOptInToMemberVerification();

            if (isChecked) {
              history.push("/broadcast-member-verification");
            }
          }}
          isChecked={!!modelProfile.flags?.opted_in_for_member_verification}
          offBackgroundColor={"bg-toggle-disabled"}
          offLabelProps={{
            textColor: "text-white",
          }}
          onBackgroundColor={"bg-earnings-bg-color"}
          onLabelProps={{
            textColor: "text-main-color",
          }}
          pillBackgroundColor={
            !!modelProfile.flags?.opted_in_for_member_verification
              ? "bg-green-500"
              : "bg-gray-200"
          }
        />
      </TailwindFlex>

      <TailwindButton
        backgroundColor={"primary"}
        onClick={() =>
          endTippingShowEarlyShowStopWarningTimeInSeconds === 10
            ? setEndTippingShowEarlyShowStopWarningTimeInSeconds(
                BROADCAST_END_TIPPING_SHOW_EARLY_SHOW_STOP_WARNING_TIME_IN_MINUTES *
                  60
              )
            : setEndTippingShowEarlyShowStopWarningTimeInSeconds(10)
        }>
        {endTippingShowEarlyShowStopWarningTimeInSeconds === 10 ? (
          <React.Fragment>
            Set tipping show early show stop (curtain down) warning time to the
            default of 5 minutes
          </React.Fragment>
        ) : (
          <React.Fragment>
            Set tipping show early show stop (curtain down) warning time to{" "}
            <b>10 seconds</b> (normally 5 minutes)
          </React.Fragment>
        )}
      </TailwindButton>
    </TailwindFlex>
  );
};

export default inject("broadcastStore", "profileStore")(observer(QAWidgetMisc));
