import React, { useMemo } from "react";
import { HashMap } from "library/core/interfaces";
import TailwindDropdown, { TailwindDropdownValue } from "library/components/_tailwind/dropdown";

type Props = {
  name: string;
  value: TailwindDropdownValue;
  updateProperty: (name: string, option: string | string[]) => void;
  multiple?: boolean;
  values: HashMap<any>;
  label: string;
  error?: string;
};

const MyPageCamsModelSearchAttributes: React.ComponentType<Props> = ({
  name,
  multiple = false,
  updateProperty,
  values = {},
  value,
  label,
  error,
}) => {
  if (!values) {
    return null;
  }
  const _values = useMemo(() => {
    const val: any = [];
    Object.keys(values).map(value => val.push({ label: values[value], value }));
    return val;
  }, [values]);
  return (
    <TailwindDropdown
      name={name}
      fullWidth
      backgroundColor={"bg-primary-bg-color"}
      itemLabelProps={{
        htmlFor: label,
      }}
      label={label}
      onChange={item => updateProperty(name, item as string[])}
      multipleProps={multiple ? { placeholder: label } : undefined}
      value={value}
      items={_values}
      error={error}
      showSearch
    />
  );
};

export default MyPageCamsModelSearchAttributes;
