import React, { useCallback, useMemo, useState } from "react";
import "./styles.scss";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import AuthStore from "core/stores/auth/AuthStore";
import ModalStore from "library/core/stores/modal";
import LoginModal from "../../../common/login";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import TailwindInput from "library/components/_tailwind/input";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindFlex from "library/components/_tailwind/flex";
import RecaptchaForm from "library/components/_tailwind/recaptcha";
import config from "core/config";
import { NullableString } from "library/core/types";
import TailwindButton, {
  TailwindButtonProps,
} from "library/components/_tailwind/button";

type UpdatePasswordFormProps = {
  authStore?: AuthStore;
  validationStore?: ValidationStore;
  profileStore?: ProfileStore;
  modalStore?: ModalStore;
  buttonProps?: TailwindButtonProps;
  token?: string;
  forWhere?: "landing" | "account-settings" | "studio";
  className?: string;
  showForgotPassword?: boolean;
  showOldPassword?: boolean;
  showTitle?: boolean;
};

const UpdatePasswordForm: React.FunctionComponent<
  UpdatePasswordFormProps & WrappedComponentProps
> = ({
  profileStore,
  validationStore,
  authStore,
  modalStore,
  buttonProps,
  token,
  showForgotPassword = false,
  showOldPassword = false,
  forWhere,
  className = "",
  intl,
}) => {
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordCheck, setNewPasswordCheck] = useState<string>("");
  const [canLogIn, setCanLogIn] = useState<boolean>(false);

  const { profile } = profileStore!;

  const { isLoggedIn, resetPassword, isActionInProgress, changePassword } =
    authStore!;
  const { openAuthModal } = modalStore!;
  const { errors, validate, isEmpty, setErrorMessage } = validationStore!;
  const { googleCaptchaSiteKey } = config!;

  const onForgotPasswordClick = useCallback(
    async (recaptchaToken: NullableString) => {
      try {
        await resetPassword(profile?.username, recaptchaToken);
        setCanLogIn(true);
      } catch (error) {
        // do not remove as this is used to fallback to v2
        throw new Error(error);
      }
    },
    [profile]
  );

  const onLoginClick = useCallback(() => openAuthModal(<LoginModal />), []);

  const onUpdatePasswordClick = useCallback(async () => {
    // do not check old password strength as old models might have a PW not matching our new strength
    // only check for emptiness
    const oldPasswordIsValid = token || !isEmpty(oldPassword);

    if (!oldPasswordIsValid) {
      setErrorMessage("password", true, true);
    } else {
      setErrorMessage("password", false, true);
    }

    const newPasswordCheckIsValid = validate("new_password", newPassword);
    const newPasswordCheckMatchIsValid = validate(
      "passwordConfirm",
      newPasswordCheck
    );

    if (
      oldPasswordIsValid &&
      newPasswordCheckIsValid &&
      newPasswordCheckMatchIsValid
    ) {
      try {
        await changePassword(oldPassword, newPassword, newPasswordCheck, token);
        setOldPassword("");
        setNewPassword("");
        setNewPasswordCheck("");
        setCanLogIn(true);
      } catch (error) {
        // do not remove as this is used to fallback to v2
        throw new Error(error);
      }
    }
  }, [token, oldPassword, newPassword, newPasswordCheck]);

  const hasEmail = useMemo(() => profile?.email !== "", [profile]);

  const onCaptchaChange = async (recaptchaKey: NullableString) => {
    onForgotPasswordClick(recaptchaKey);
  };

  if (canLogIn && !isLoggedIn) {
    return (
      <TailwindFlex
        backgroundColor={"bg-white"}
        padding={["p-8"]}
        flexDirection={"flex-col"}>
        <TailwindTranslatedText
          headingProps={{
            level: 5,
            textAlign: "text-center",
            margin: ["mb-3"],
          }}
          descriptor={{
            id: "landing.resetPasswordAuth.passwordUpdateSuccess",
            defaultMessage: "Your password has been successfully updated",
          }}
        />
        <TailwindTranslatedText
          headingProps={{
            level: 6,
            textAlign: "text-center",
          }}
          descriptor={{
            id: "landing.resetPasswordAuth.loginWithNewPassword",
            defaultMessage: "You can now login with your new password.",
          }}
        />
        <TailwindFlex>
          <TailwindButton
            rounded={false}
            margin={["mt-6"]}
            justifyContent={"justify-center"}
            backgroundColor={"primary"}
            onClick={onLoginClick}>
            {intl.formatMessage({
              id: `landing.login`,
              defaultMessage: "Login",
            })}
          </TailwindButton>
        </TailwindFlex>
      </TailwindFlex>
    );
  }

  return (
    <TailwindFlex
      backgroundColor={"bg-transparent"}
      flexDirection={"flex-col"}
      maxWidth={"max-w-3xl"}
      className={[className]}>
      <RecaptchaForm
        className={"UpdatePasswordForm__container"}
        margin={["mt-6"]}
        captchaSiteKey={googleCaptchaSiteKey}
        callback={onCaptchaChange}>
        <TailwindFlex margin={["mb-4"]}>
          <TailwindTranslatedText
            headingProps={{
              level: 5,
            }}
            descriptor={{
              id: "landing.resetPasswordAuth.updateYourPassword",
              defaultMessage: "Update Your Password",
            }}
          />
        </TailwindFlex>
        {showOldPassword && (
          <TailwindFlex width={"w-full"} margin={["mb-4"]}>
            <TailwindInput
              label={intl.formatMessage({
                id: "common.oldPassword",
                defaultMessage: "Enter old password",
              })}
              placeholder={"Enter old password"}
              value={oldPassword}
              onChange={e => setOldPassword(e.target.value)}
              error={errors.password || undefined}
              type={TailwindInputType.password}
              backgroundColor={
                forWhere === "studio"
                  ? "bg-primary-bg-color"
                  : "bg-secondary-bg-color"
              }
              placeholderColor={"placeholder-gray-700"}
            />
            {showForgotPassword && <p>&nbsp;</p>}
          </TailwindFlex>
        )}
        <TailwindFlex width={"w-full"} margin={["mb-4"]}>
          <TailwindInput
            label={intl.formatMessage({
              id: "common.newPassword",
              defaultMessage: "Enter new password",
            })}
            placeholder={"Enter new password"}
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            error={errors.new_password || undefined}
            type={TailwindInputType.password}
            backgroundColor={
              forWhere === "studio"
                ? "bg-primary-bg-color"
                : "bg-secondary-bg-color"
            }
            placeholderColor={"placeholder-gray-700"}
          />
          {showForgotPassword && <p>&nbsp;</p>}
        </TailwindFlex>
        <TailwindFlex width={"w-full"} margin={["mb-4"]}>
          <TailwindInput
            label={intl.formatMessage({
              id: "common.newPasswordCheck",
              defaultMessage: "Re-enter new password",
            })}
            placeholder={"Re-enter new password"}
            onChange={e => setNewPasswordCheck(e.target.value)}
            value={newPasswordCheck}
            error={errors.passwordConfirm || undefined}
            type={TailwindInputType.password}
            backgroundColor={
              forWhere === "studio"
                ? "bg-primary-bg-color"
                : "bg-secondary-bg-color"
            }
            placeholderColor={"placeholder-gray-700"}
          />
          {showForgotPassword && <p>&nbsp;</p>}
        </TailwindFlex>
        {errors?.changePassword && (
          <div className={"mb-3 UpdatePasswordForm__error"}>
            {errors?.changePassword}
          </div>
        )}
        <TailwindFlex width={"w-full"}>
          <TailwindButton
            backgroundColor={"bg-yellow-500"}
            rounded={false}
            justifyContent={"justify-center"}
            fullWidth
            {...buttonProps}
            onClick={e => {
              e.preventDefault();
              onUpdatePasswordClick();
            }}
            showSpinner={isActionInProgress}
            disabled={isActionInProgress}>
            {intl.formatMessage({
              id: "common.updatePassword",
              defaultMessage: "Update Password",
            })}
          </TailwindButton>
        </TailwindFlex>
        {showForgotPassword && (
          <TailwindFlex margin={["mt-4"]}>
            <TailwindButton
              className={["UpdatePasswordForm__forgot-password-text"]}
              textColor={"text-indigo-600"}
              type={"submit"}>
              {hasEmail
                ? intl.formatMessage(
                    {
                      id: "accountSettings.passwordSettings.sendResetEmailToMyMail",
                      defaultMessage:
                        "I forgot my password, send reset link to {email}",
                    },
                    {
                      email: profile?.email,
                    }
                  )
                : intl.formatMessage({
                    id: "landing.welcomeBack.forgotPassword",
                    defaultMessage: "Forgot Password?",
                  })}
            </TailwindButton>
          </TailwindFlex>
        )}
      </RecaptchaForm>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject(
    "profileStore",
    "authStore",
    "modalStore",
    "validationStore"
  )(observer(UpdatePasswordForm))
);
