import React, { useCallback, useMemo, useState } from "react";
import {
  IStudioModel,
  StudioModelAccessRights,
} from "../stores/models/interfaces";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import ModelStore from "../stores/models/ModelStore";
import TailwindCard from "library/components/_tailwind/card";
import TailwindSwitch from "library/components/_tailwind/switch";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";

type Props = {
  modelStore?: ModelStore;
  model: IStudioModel;
};

const StudioModelManagementModelAccessRightsModal: React.ComponentType<
  Props & WrappedComponentProps
> = ({ modelStore, model }) => {
  const { models, saveStudioModelAccessRights } = modelStore!;

  const accessRights: StudioModelAccessRights | undefined = useMemo(
    () =>
      models.find(_model => _model.modelId === model.modelId)?.access_rights,
    [model, models]
  );

  const [isSavingPricingSettingsAllowed, setIsSavingPricingSettingsAllowed] =
    useState<boolean>(false);
  const [
    isSavingEarningsAndTransactionsVisible,
    setIsSavingEarningsAndTransactionsVisible,
  ] = useState<boolean>(false);
  const [
    isSavingTransactionDetailVisible,
    setIsSavingTransactionDetailVisible,
  ] = useState<boolean>(false);
  const [isSavingQuickTextChangesAllowed, setIsSavingQuickTextChangesAllowed] =
    useState<boolean>(false);
  const [isSavingAllowToInviteAllowed, setIsSavingAllowToInviteAllowed] =
    useState<boolean>(false);
  const [isSavingAllowToBeInvitedAllowed, setIsSavingAllowToBeInvitedAllowed] =
    useState<boolean>(false);

  const saveAccessRights = useCallback(
    async (right: Partial<StudioModelAccessRights>) => {
      await saveStudioModelAccessRights({
        id: accessRights?.id,
        model: accessRights?.model,
        ...right,
      });
    },
    [accessRights, saveStudioModelAccessRights]
  );

  const isPricingSettingsChangeAllowed = useMemo(
    () =>
      accessRights?.is_pricing_settings_change_allowed === undefined
        ? false
        : accessRights?.is_pricing_settings_change_allowed,
    [accessRights?.is_pricing_settings_change_allowed]
  );

  const isEarningsAndTransactionsVisible = useMemo(
    () =>
      accessRights?.is_earnings_and_transactions_visible === undefined
        ? false
        : accessRights?.is_earnings_and_transactions_visible,
    [accessRights?.is_earnings_and_transactions_visible]
  );

  const isTransactionDetailVisible = useMemo(
    () =>
      accessRights?.is_transaction_detail_visible === undefined
        ? false
        : accessRights?.is_transaction_detail_visible,
    [accessRights?.is_transaction_detail_visible]
  );

  const isQuickTextChangesAllowed = useMemo(
    () =>
      accessRights?.is_quick_text_changes_allowed === undefined
        ? false
        : accessRights?.is_quick_text_changes_allowed,
    [accessRights?.is_quick_text_changes_allowed]
  );

  // invite another model as a guest
  const isAllowToInviteAllowed = useMemo(
    () =>
      accessRights?.is_allow_to_invite_allowed === undefined
        ? false
        : accessRights?.is_allow_to_invite_allowed,
    [accessRights?.is_allow_to_invite_allowed]
  );
  // join to another model's session as a guest
  const isAllowToBeInvitedAllowed = useMemo(
    () =>
      accessRights?.is_allow_to_be_invited_allowed === undefined
        ? false
        : accessRights?.is_allow_to_be_invited_allowed,
    [accessRights?.is_allow_to_be_invited_allowed]
  );

  const onClickPricingSettingsChangeAllowed = useCallback(async () => {
    const opposite = !isPricingSettingsChangeAllowed;
    setIsSavingPricingSettingsAllowed(true);
    await saveAccessRights({
      is_pricing_settings_change_allowed: opposite,
    });
    setIsSavingPricingSettingsAllowed(false);
  }, [saveAccessRights, isPricingSettingsChangeAllowed]);

  const onClickEarningsAndTransactionsVisible = useCallback(async () => {
    const opposite = !isEarningsAndTransactionsVisible;
    setIsSavingEarningsAndTransactionsVisible(true);
    await saveAccessRights({
      is_earnings_and_transactions_visible: opposite,
    });
    setIsSavingEarningsAndTransactionsVisible(false);
  }, [saveAccessRights, isEarningsAndTransactionsVisible]);

  const onClickTransactionDetailVisible = useCallback(async () => {
    const opposite = !isTransactionDetailVisible;
    setIsSavingTransactionDetailVisible(true);
    await saveAccessRights({
      is_transaction_detail_visible: opposite,
    });
    setIsSavingTransactionDetailVisible(false);
  }, [saveAccessRights, isTransactionDetailVisible, accessRights]);

  const onClickQuickTextChangeAllowed = useCallback(async () => {
    const opposite = !isQuickTextChangesAllowed;
    setIsSavingQuickTextChangesAllowed(true);
    await saveAccessRights({
      is_quick_text_changes_allowed: opposite,
    });
    setIsSavingQuickTextChangesAllowed(false);
  }, [saveAccessRights, isQuickTextChangesAllowed]);

  const onClickAllowToInviteAllowed = useCallback(async () => {
    const opposite = !isAllowToInviteAllowed;
    setIsSavingAllowToInviteAllowed(true);
    await saveAccessRights({
      is_allow_to_invite_allowed: opposite,
    });
    setIsSavingAllowToInviteAllowed(false);
  }, [saveAccessRights, isAllowToInviteAllowed]);

  const onClickAllowToBeInvitedAllowed = useCallback(async () => {
    const opposite = !isAllowToBeInvitedAllowed;
    setIsSavingAllowToBeInvitedAllowed(true);
    await saveAccessRights({
      is_allow_to_be_invited_allowed: opposite,
    });
    setIsSavingAllowToBeInvitedAllowed(false);
  }, [saveAccessRights, isAllowToBeInvitedAllowed]);

  return (
    <TailwindCard
      backgroundColor={"primary"}
      width={"w-full"}
      pseudoClasses={["md:w-full"]}
      padding={["p-8", "pt-8"]}>
      <TailwindText margin={["mb-2"]} fontSize={"text-2xl"} display={"block"}>
        Edit Models Access Rights for <b>{model?.username}</b>
      </TailwindText>
      <TailwindText margin={["mb-4"]} display={"block"}>
        Use this form to edit what your models/streams can access and modify.
      </TailwindText>
      <TailwindFlex
        margin={["mb-2"]}
        justifyContent={"justify-start"}
        alignItems={"items-center"}>
        <TailwindSwitch
          size={"sm"}
          onChange={onClickEarningsAndTransactionsVisible}
          isChecked={isEarningsAndTransactionsVisible}
          boxProps={{
            pointerEvents: isSavingEarningsAndTransactionsVisible
              ? "pointer-events-none"
              : "pointer-events-auto",
          }}
        />
        <TailwindTranslatedText
          margin={["ml-2"]}
          descriptor={{
            id: "studio-model-management.model-permission.is_earnings_and_transactions_visible",
            defaultMessage: "Show detailed earnings?",
          }}
        />
      </TailwindFlex>
      <TailwindFlex
        margin={["mb-2"]}
        justifyContent={"justify-start"}
        alignItems={"items-center"}>
        <TailwindSwitch
          size={"sm"}
          onChange={onClickTransactionDetailVisible}
          isChecked={isTransactionDetailVisible}
          boxProps={{
            pointerEvents: isSavingTransactionDetailVisible
              ? "pointer-events-none"
              : "pointer-events-auto",
          }}
        />
        <TailwindTranslatedText
          margin={["ml-2"]}
          descriptor={{
            id: "studio-model-management.model-permission.is_transaction_detail_visible",
            defaultMessage: "Show transaction details?",
          }}
        />
      </TailwindFlex>
      <TailwindFlex
        margin={["mb-2"]}
        justifyContent={"justify-start"}
        alignItems={"items-center"}>
        <TailwindSwitch
          size={"sm"}
          onChange={onClickPricingSettingsChangeAllowed}
          isChecked={isPricingSettingsChangeAllowed}
          boxProps={{
            pointerEvents: isSavingPricingSettingsAllowed
              ? "pointer-events-none"
              : "pointer-events-auto",
          }}
        />
        <TailwindTranslatedText
          margin={["ml-2"]}
          descriptor={{
            id: "studio-model-management.model-permission.is_pricing_settings_change_allowed",
            defaultMessage: "Allow Pricing Changes?",
          }}
        />
      </TailwindFlex>
      <TailwindFlex
        margin={["mb-2"]}
        justifyContent={"justify-start"}
        alignItems={"items-center"}>
        <TailwindSwitch
          size={"sm"}
          onChange={onClickQuickTextChangeAllowed}
          isChecked={isQuickTextChangesAllowed}
          boxProps={{
            pointerEvents: isSavingQuickTextChangesAllowed
              ? "pointer-events-none"
              : "pointer-events-auto",
          }}
        />
        <TailwindTranslatedText
          margin={["ml-2"]}
          descriptor={{
            id: "studio-model-management.model-permission.is_quick_text_changes_allowed",
            defaultMessage: "Allow Quick Text changes?",
          }}
        />
      </TailwindFlex>
      <TailwindFlex
        margin={["mb-2"]}
        justifyContent={"justify-start"}
        alignItems={"items-center"}>
        <TailwindSwitch
          size={"sm"}
          onChange={onClickAllowToInviteAllowed}
          isChecked={isAllowToInviteAllowed}
          boxProps={{
            pointerEvents: isSavingAllowToInviteAllowed
              ? "pointer-events-none"
              : "pointer-events-auto",
          }}
        />
        <TailwindTranslatedText
          margin={["ml-2"]}
          descriptor={{
            id: "studio-model-management.model-permission.is_allow_to_invite_allowed",
            defaultMessage: "Allow guest models to broadcast?",
          }}
        />
      </TailwindFlex>
      <TailwindFlex
        justifyContent={"justify-start"}
        alignItems={"items-center"}>
        <TailwindSwitch
          size={"sm"}
          onChange={onClickAllowToBeInvitedAllowed}
          isChecked={isAllowToBeInvitedAllowed}
          boxProps={{
            pointerEvents: isSavingAllowToBeInvitedAllowed
              ? "pointer-events-none"
              : "pointer-events-auto",
          }}
        />
        <TailwindTranslatedText
          margin={["ml-2"]}
          descriptor={{
            id: "studio-model-management.model-permission.is_allow_to_be_invited_allowed",
            defaultMessage: "Allow broadcast on other streams?",
          }}
        />
      </TailwindFlex>
    </TailwindCard>
  );
};

export default injectIntl(
  inject("modelStore")(observer(StudioModelManagementModelAccessRightsModal))
);
