import React, { FunctionComponent } from "react";
import PleaseContactUs from "./please-contact-us";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";

const ApplicationIncomplete: FunctionComponent<{ isStudio: boolean }> = ({
  isStudio,
}) => {
  return (
    <React.Fragment>
      <TailwindTranslatedText
        textColor='text-main-color'
        fontSize={"text-sm"}
        descriptor={{
          id: "landing.summaryStep.incomplete",
          defaultMessage: isStudio
            ? "There are a few more details you need to complete before you can start. As soon as you're done, we'll review your application and activate your account so you can start making money."
            : "There are a few more details you need to complete before you can start broadcasting. As soon as you're done, we'll review your application and activate your account so you can start making money.",
        }}
      />
      <br />
      <br />
      <PleaseContactUs isStudio={isStudio} />
    </React.Fragment>
  );
};
export default ApplicationIncomplete;
