import React from "react";
import "./styles.scss";
import classnames from "classnames";

type DoubleHeartsIconProps = {
  className?: string;
};

const DoubleHeartsIcon: React.ComponentType<DoubleHeartsIconProps> = ({
  className,
}) => (
  <span className={classnames("DoubleHeartsIcon", className && className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 62 62"
      xmlSpace="preserve"
      y="0px"
      x="0px"
      id="Layer_1_1606361739361"
    >
      <g transform="translate(1, 1)">
        <path
          d="M37.56,28.24c0-5.51-4.46-9.97-9.97-9.97c-3.47,0-6.52,1.77-8.31,4.46c-1.79-2.69-4.84-4.46-8.31-4.46  C5.46,18.27,1,22.74,1,28.24c0,0.28,0.01,0.55,0.04,0.82h0c0,9.73,13.21,19.88,18.25,23.39c5.03-3.51,18.25-13.65,18.25-23.39h0  C37.55,28.79,37.56,28.52,37.56,28.24z"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M49.03,11.6c3.26,0,5.91,2.65,5.91,5.91c0,0.14-0.01,0.3-0.02,0.49l-0.14,1.71c-0.98,5.45-7.41,11.98-14.06,16.99  c-6.65-5.01-13.08-11.54-14.06-16.98l-0.14-1.72c-0.1-1.2,0.12-1.26,0.56-2.51c0.72-2.07,2.52-3.63,4.69-3.86  c2.22-0.24,4.35,0.78,5.57,2.61l2.21,2.51c0.56,0.84,1.78,0.84,2.34,0l2.21-2.51C45.2,12.59,47.04,11.6,49.03,11.6 M49.03,7.55  c-3.47,0-6.52,1.77-8.31,4.46c-1.79-2.69-4.84-4.46-8.31-4.46c-5.51,0-9.97,4.46-9.97,9.97c0,0.28,0.01,0.55,0.04,0.82h0  c0,9.73,13.21,19.88,18.25,23.39c5.03-3.51,18.25-13.65,18.25-23.39h0C58.99,18.07,59,17.8,59,17.52C59,12.01,54.54,7.55,49.03,7.55  L49.03,7.55z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  </span>
);

export default DoubleHeartsIcon;
