export default class TokenHelper {
  priceMultiplier = 10;
  public tokenToCurrency(amount) {
    return amount ? parseFloat(amount) / this.priceMultiplier : 0;
  }

  public currencyToToken(amount) {
    return amount ? Math.round(parseFloat(amount) * this.priceMultiplier) : 0;
  }
}
