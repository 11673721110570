import React, { useMemo } from "react";
import TailwindDropdownCountryFlags, {
  TailwindDropdownCountryFlagsProps,
  TailwindDropdownCountryFlagsTextValue,
} from "../dropdown-country-flags";
import {
  COUNTRY_LIST,
  COUNTRY_PHONE_NUMBERS_LIST,
} from "../../../core/constants";

type Props = {} & Omit<TailwindDropdownCountryFlagsProps, "flagValues">;

const TailwindDropdownCountryPhoneNumbers: React.ComponentType<Props> = ({
  ...props
}) => {
  const flagValues: TailwindDropdownCountryFlagsTextValue[] = useMemo(() => {
    return Object.entries(COUNTRY_LIST).map(([key, value]) => ({
      "countryCodeISO3166-1-alpha-2": key,
      label: `+${COUNTRY_PHONE_NUMBERS_LIST[key]} ${value}`,
    }));
  }, []);

  return <TailwindDropdownCountryFlags {...props} flagValues={flagValues} />;
};

export default TailwindDropdownCountryPhoneNumbers;
