import React from "react";
import StorybookHeading from "../_internal/heading";
import StorybookWrapper from "../_internal/wrapper";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookSubHeading from "../_internal/sub-heading";
import TailwindAnchor from "../../components/_tailwind/anchor";

type Props = {};

const StorybookArchitectureTailwindCSS: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Architecture &gt; TailwindCSS</StorybookHeading>
      <StorybookParagraph>
        The project just recently started using TailwindCSS. The primary reasons
        as to why:
      </StorybookParagraph>
      <StorybookParagraph>
        - There was Bootstrap, Material-UI, our custom css for things like
        responsiveness, typography etc. altogether. We only need one.
      </StorybookParagraph>
      <StorybookParagraph>
        - Not everyone followed BEM, nor if it was followed, it was structured
        nicely. It is just a "open to do in any way" kind of structure that gets
        out of hand pretty quickly. The css was also not consistent either and
        had duplicate properties on each line like{" "}
        <Code inline>{`height: 10px; two lines later height: 100%;`}</Code>.
      </StorybookParagraph>
      <StorybookParagraph>
        - A color HEX in one file did not match the color in another file.
      </StorybookParagraph>
      <StorybookParagraph>
        - Naming of global css got out of hand. Color orange in Cams that was
        used for links suddenly was overwritten with red color for AFF.
      </StorybookParagraph>
      <StorybookParagraph>
        - Theming got more complex because of new addition of products into the
        codebase. SCSS theming system we had before with custom mixins did not
        suffice.
      </StorybookParagraph>
      <StorybookParagraph>
        - The colors in the css that had names like{" "}
        <Code inline>{`title-color-1`}</Code> (exists until title-color-6) did
        not convey what color they were and where they were used. It was hard to
        change anything without breaking something else
      </StorybookParagraph>
      <StorybookParagraph>
        - Additional customization was required because of new products, Cams
        would have very soft radius around inputs whereas AFF would have
        completely rounded. This can be achieved with CSS Variables however it
        is also not so descriptive as you need to go the the :root and find CSS
        variables and what they are mapped to.
      </StorybookParagraph>
      <StorybookParagraph>
        - We kept reinventing the wheel because neither Bootstrap nor
        Material-UI had the utility classes we needed. We never setup Bootstrap
        in a way we overwrote SCSS variables it had and we kept having to write
        our own set of classes to use responsive utility classes that did
        max-width instead of min-width.
      </StorybookParagraph>
      <StorybookParagraph>
        - Material-UI components were overwritten by regular css with a lot of
        !important in them, that made it hard to maintain. We did not use
        useTheme or useStyles from Material-UI package.
      </StorybookParagraph>
      <StorybookParagraph>
        - We had to write too much CSS on simple things like positioning,
        sizing, that never matched one another. A font size in one file did not
        much a font size in another file. We tried making typography global
        classes but it was not done nicely in terms of naming either and no one
        knew what were they about because of lack of documentation.
      </StorybookParagraph>
      <StorybookParagraph>
        - Our designs are extremely custom meaning it is hard to shape
        Material-UI components easily
      </StorybookParagraph>
      <StorybookParagraph>
        - Some people used flex in css, while others used Material-UI grid. The
        MUI grid was not nice either and we again ended up writing css anyways.
      </StorybookParagraph>
      <StorybookParagraph>
        - Due to recent changes, with new people joining the team, it is now
        more important to have a consistent app structure more than ever.
        TailwindCSS provides the documentation we need out of the box as well as
        the customization is easy
      </StorybookParagraph>
      <StorybookParagraph>
        - Due to recent changes, we need to do live edits & rapid prototyping.
        With Tailwind, it is possible to write code without a single line of
        CSS.
      </StorybookParagraph>
      <StorybookSubHeading>Extending TailwindCSS</StorybookSubHeading>
      <StorybookParagraph>
        Read about the TailwindCSS&nbsp;
        <TailwindAnchor to={"https://tailwindcss.com/docs/configuration"}>
          Configuration
        </TailwindAnchor>
        &nbsp; page. Our app has <Code inline>src/tailwind.config.js</Code>{" "}
        file. When you open the file you'll already see some of the
        customizations we made but further customizations can be made.
      </StorybookParagraph>
      <StorybookSubHeading>
        React Components that use TailwindCSS
      </StorybookSubHeading>
      <StorybookParagraph>
        There are some <strong>primitive</strong> components that are wrapping
        TailwindCSS functionality into React's props. A good example can be the
        TailwindBox component that has the following props:
      </StorybookParagraph>
      <Code>
        {`width?: TWidth;
minWidth?: TMinWidth;
maxWidth?: TMaxWidth;
height?: THeight;
minHeight?: TMinHeight;
maxHeight?: TMaxHeight;
display?: TDisplay;
float?: TFloat;
flexDirection?: TFlexDirection;
flexWrap?: TFlexWrap;
flex?: TFlex;
flexGrow?: TFlexGrow;
flexShrink?: TFlexShrink;
order?: TOrder;
gap?: TGap;
gridCols?: TGridTemplateColumns;
gridRows?: TGridTemplateRows;
justifyContent?: TJustifyContent;
justifyItems?: TJustifyItems;
justifySelf?: TJustifySelf;
alignContent?: TAlignContent;
alignItems?: TAlignItems;
alignSelf?: TAlignSelf;
placeContent?: TPlaceContent;
placeItems?: TPlaceItems;
placeSelf?: TPlaceSelf;
margin?: (TMargin | undefined)[];
padding?: (TPadding | undefined)[];
space?: TSpace;
overflow?: TOverflow | (TOverflow | undefined)[];
zIndex?: TZIndex;
visibility?: TVisibility;
clear?: TClear;
boxSizing?: TBoxSizing;
position?: TPosition;
inset?: TInset[];
borderRadius?: TBorderRadius | TBorderRadius[];
borderWidth?: TBorderWidth;
borderColor?: TBorderColor;
borderOpacity?: TBorderOpacity;
borderStyle?: TBorderStyle;
divideWidth?: TDivideWidth;
divideColor?: TDivideColor;
divideOpacity?: TDivideOpacity;
divideStyle?: TDivideStyle;
ringWidth?: TRingWidth;
ringColor?: TRingColor;
ringOpacity?: TRingOpacity;
ringOffsetWidth?: TRingOffsetWidth;
ringOffsetColor?: TRingOffsetColor;
appearance?: TAppearance;
cursor?: TCursor;
outline?: TOutline;
pointerEvents?: TPointerEvents;
userSelect?: TUserSelect;
transitionProperty?: TTransitionProperty;
backgroundColor?: TBackgroundColor;
backgroundOpacity?: TBackgroundOpacity;
backgroundImage?: TBackgroundImage;
backgroundSize?: TBackgroundSize;
backgroundPosition?: TBackgroundPosition;
backgroundRepeat?: TBackgroundRepeat;
gradientColorStops?: TGradientColorStops[];
objectPosition?: TObjectPosition;
transform?: TTransforms[];
textColor?: TTextColor;
boxShadow?: TBoxShadow;
className?: (string | undefined | boolean)[];
id?: string;
style?: React.CSSProperties;
psuedoClasses?: (TPseudoClasses | undefined)[];
as?: string;
onClick?: (event: React.MouseEvent<any>) => void;
onMouseEnter?: (event: React.MouseEvent<any>) => void;
onMouseLeave?: (event: React.MouseEvent<any>) => void;
dangerouslySetInnerHTML?: {
  __html: string;
};
htmlElementProps?: any; //this is used for things like disabled
        
`}
      </Code>
      <StorybookParagraph>
        The above props use another library called tailwindcss-classnames that
        extract css classnames from tailwind.config.js file into Typescript
        Types so we can have autocompletion while passing props. It also helps
        us notice errors like passing margin class to the padding prop.
      </StorybookParagraph>
      <StorybookParagraph>
        Everytime tailwind.config.js changes,{" "}
        <Code inline>yarn generate-css-types</Code> command must be run so the
        correct types are generated.
      </StorybookParagraph>
      <StorybookParagraph>
        Tailwind React components have Tailwind prefix as not to conflict with
        Material-UI ones (such as Box, Input, Dropdown). These will later be
        renamed to be just Input, Dropdown etc. when Material-UI is removed.
      </StorybookParagraph>
      <StorybookParagraph>
        The TailwindBox primitive is used for other classes such as
        TailwindFlex, TailwindButton, TailwindDropdown. There is another
        primitive called TailwindText that also is similar to TailwindBox but
        has only text related props. TailwindText is also used on TailwindIcon,
        TailwindButton and so on.
      </StorybookParagraph>
      <StorybookParagraph textColor={"text-red-500"} fontWeight={"font-bold"}>
        If you happen to be implementing a new Tailwind component or one that
        extends an existing Tailwind component, use imports from{" "}
        <Code inline>src/styles/tailwind-classnames.ts</Code>
      </StorybookParagraph>
      <StorybookParagraph>
        <Code inline>src/styles/tailwind-classnames.ts</Code> has a default
        export called tw that you can use to write typesafe Tailwind however on
        very rare cases you mind need to use classnames package along with the
        "tw" helper that also uses classnames under the hood such as
      </StorybookParagraph>
      <Code>
        {`<div className={classnames("SomeClass", tw("bg-red-500"))} />
`}
      </Code>
      <StorybookParagraph>
        Please refer to components item on the sidebar for source code &
        showcasing of components.
      </StorybookParagraph>
    </StorybookWrapper>
  );
};

export default StorybookArchitectureTailwindCSS;
