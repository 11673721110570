import React, { useCallback, useMemo } from "react";
import TailwindButton, { TailwindButtonProps } from "../button";
import { history } from "../../../core/utility";

export type TailwindAnchorProps = TailwindButtonProps & {
  to: string;
  underline?: boolean;
  openInNewTab?: boolean;
};

const TailwindAnchor: React.ComponentType<TailwindAnchorProps> = ({
  onClick,
  children,
  to,
  padding = [],
  height = "h-full",
  underline = true,
  openInNewTab = false,
  ...props
}) => {
  const isExternal = useMemo(() => {
    return (
      openInNewTab ||
      to.includes("http://") ||
      to.includes("https://") ||
      to.includes("mailto:")
    );
  }, [to, openInNewTab]);

  const onClickLink = useCallback(
    (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      if (!isExternal) {
        event.preventDefault();
        history.push(to);
      }
      if (onClick) {
        onClick(event);
      }
    },
    [to, onClick, isExternal]
  );

  const htmlElementProps = useMemo(() => {
    if (isExternal) {
      return { href: to, target: "_blank", rel: "noreferrer" };
    }

    return { href: to };
  }, [isExternal, to]); 

  return (
    <TailwindButton
      {...props}
      as={"a"}
      fullWidth={false}
      display={"inline-flex"}
      rounded={false}
      padding={["p-0", ...padding]}
      onClick={onClickLink}
      htmlElementProps={htmlElementProps}
      height={height}
      textProps={{
        ...props.textProps,
        textDecoration: underline ? "underline" : undefined,
      }}>
      {children}
    </TailwindButton>
  );
};

export default TailwindAnchor;
