import React, { FunctionComponent } from "react";
import { UserRole } from "library/core/types";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";

type CreateAccountStepHeaderProps = {
  userRole: UserRole | null;
};

const CreateAccountStepHeader: FunctionComponent<CreateAccountStepHeaderProps> =
  ({ userRole }) => {
    const headers = {
      model: {
        id: "landing.createAccount.enterYourInfoModel",
        defaultMessage: "Create your new model account",
      },
      studio: {
        id: "landing.createAccount.enterYourInfoStudio",
        defaultMessage: "Create your new studio account",
      },
    };
    const sub_headers = {
      model: {
        id: "landing.createAccount.buildYourPersonalBrand",
        defaultMessage:
          "Build your personal brand and be your own boss. Set your own schedule, work from anywhere in the world, and live life on your own terms.",
      },
      studio: {
        id: "landing.createAccount.areYouAnEntrepreneur",
        defaultMessage:
          "Are you an entrepreneur? Manage yourself and other models while building your own successful business.",
      },
    };

    return (
      <>
        <TailwindTranslatedText
          fontSize={"text-2xl"}
          margin={["mb-4"]}
          textColor='text-main-color'
          textAlign={"text-center"}
          descriptor={{
            id: headers[userRole || "model"].id,
            defaultMessage: headers[userRole || "model"].defaultMessage,
          }}
        />
        <TailwindTranslatedText
          textAlign={"text-center"}
          textColor='text-main-color'
          descriptor={{
            id: sub_headers[userRole || "model"].id,
            defaultMessage: sub_headers[userRole || "model"].defaultMessage,
          }}
        />
      </>
    );
  };

export default CreateAccountStepHeader;
