import React from "react";
import "./styles.scss";
import classnames from "classnames";

type DurationIconProps = {
  className?: string;
  style?: React.CSSProperties;
};

const DurationIcon: React.ComponentType<DurationIconProps> = ({
  className,
}) => (
  <span className={classnames("DurationIcon", className && className)}>
    <svg
      version='1.2'
      overflow='visible'
      preserveAspectRatio='none'
      viewBox='0 0 24 24'
      y='0px'
      x='0px'>
      <g transform='translate(1, 1)'>
        <path
          d='M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12s12-5.373,12-12S18.627,0,12,0z M12,22 C6.478,22,2,17.523,2,12S6.478,2,12,2c5.523,0,10,4.477,10,10S17.523,22,12,22z M13,4h-2v9h7v-2h-5V4z'
          vectorEffect='non-scaling-stroke'
        />
      </g>
    </svg>
  </span>
);

export default DurationIcon;
