import {
  MY_FANS_SORTING_PROPERTY,
  MY_FANS_TAB,
} from "containers/model/my-fans/stores/my-fans/enums";

export const InitialData = {
  loadingMyFans: false,
  myFans: [],
  bountyMembers: [],
  fanClubMembers: [],
  topAdmirers: [],
  recentVisitors: [],
  referrals: [],
  isLoadingReferrals: true,
  selectedMemberTransactions: [],
  selectedMemberTransactionsPagination: {
    total: 0,
    page: 1,
    pageSize: 10,
    totalPages: 0,
  },
  loadingMemberTransactions: false,
  selectedMemberId: null,
  memberTransactionsPageNumber: 1,
  activeTab: MY_FANS_TAB.LAST_30_DAYS,
  activeSortingProperty: MY_FANS_SORTING_PROPERTY.DATE,
  fromFilter: "",
  toFilter: "",
};
export const sortByOptions = [
  {
    label: "Date",
    value: MY_FANS_SORTING_PROPERTY.DATE,
  },
  {
    label: "Activity",
    value: MY_FANS_SORTING_PROPERTY.ACTIVITY,
  },
  {
    label: "Tokens",
    value: MY_FANS_SORTING_PROPERTY.TOKEN_AMOUNT,
  },
];
export const mockModelPagination30Days = {
  total: 15,
  page: 1,
  pageSize: 10,
  totalPages: 2,
};
export const mockModel30Days = [
  {
    id: "61091",
    minutes_charged: null,
    broadcast: null,
    broadcast_start: null,
    broadcast_end: null,
    product: "tip",
    product_subtype: "tip1",
    website: "cams.com",
    member_spent_tokens: 30,
    member_spent_usd: 3,
    transaction_type: "tiered_transaction",
    package_id: null,
    package_name: null,
    created_date: "2021-04-13T13:50:17.319Z",
    note: "tip - tip1",
    percentage: 12,
    earning: 9.6,
    earning_usd: 0.96,
    refunded: false,
  },
  {
    id: "61092",
    minutes_charged: null,
    broadcast: null,
    broadcast_start: null,
    broadcast_end: null,
    product: "FanClub",
    product_subtype: "tip2",
    website: "cams.com",
    member_spent_tokens: 50,
    member_spent_usd: 5,
    transaction_type: "tiered_transaction",
    package_id: null,
    package_name: null,
    created_date: "2021-04-14T13:50:30.030Z",
    note: "tip - tip2",
    percentage: 10,
    earning: 16,
    earning_usd: 1.6,
    refunded: false,
  },
  {
    id: "61093",
    minutes_charged: null,
    broadcast: null,
    broadcast_start: null,
    broadcast_end: null,
    product: "Virtual Gift",
    product_subtype: "tip3",
    website: "cams.com",
    member_spent_tokens: 100,
    member_spent_usd: 10,
    transaction_type: "tiered_transaction",
    package_id: null,
    package_name: null,
    created_date: "2021-05-13T13:50:39.004Z",
    note: "tip - tip3",
    percentage: 5,
    earning: 32,
    earning_usd: 3.2,
    refunded: false,
  },
  {
    id: "61094",
    minutes_charged: null,
    broadcast: null,
    broadcast_start: null,
    broadcast_end: null,
    product: "tip",
    product_subtype: "tip3",
    website: "cams.com",
    member_spent_tokens: 200,
    member_spent_usd: 20,
    transaction_type: "tiered_transaction",
    package_id: null,
    package_name: null,
    created_date: "2021-05-13T13:50:39.004Z",
    note: "tip - tip3",
    percentage: 3.5,
    earning: 45,
    earning_usd: 5.8,
    refunded: false,
  },
  {
    id: "61095",
    minutes_charged: null,
    broadcast: null,
    broadcast_start: null,
    broadcast_end: null,
    product: "FanClub",
    product_subtype: "tip3",
    website: "cams.com",
    member_spent_tokens: 12,
    member_spent_usd: 4,
    transaction_type: "tiered_transaction",
    package_id: null,
    package_name: null,
    created_date: "2021-05-13T13:50:39.004Z",
    note: "tip - tip3",
    percentage: 1.5,
    earning: 3,
    earning_usd: 0.55,
    refunded: false,
  },
  {
    id: "61096",
    minutes_charged: null,
    broadcast: null,
    broadcast_start: null,
    broadcast_end: null,
    product: "tip",
    product_subtype: "tip3",
    website: "cams.com",
    member_spent_tokens: 100,
    member_spent_usd: 10,
    transaction_type: "tiered_transaction",
    package_id: null,
    package_name: null,
    created_date: "2021-05-13T13:50:39.004Z",
    note: "tip - tip3",
    percentage: 5,
    earning: 32,
    earning_usd: 3.2,
    refunded: false,
  },
  {
    id: "61097",
    minutes_charged: null,
    broadcast: null,
    broadcast_start: null,
    broadcast_end: null,
    product: "tip",
    product_subtype: "tip3",
    website: "cams.com",
    member_spent_tokens: 100,
    member_spent_usd: 10,
    transaction_type: "tiered_transaction",
    package_id: null,
    package_name: null,
    created_date: "2021-05-13T13:50:39.004Z",
    note: "tip - tip3",
    percentage: 5,
    earning: 32,
    earning_usd: 3.2,
    refunded: false,
  },
  {
    id: "61098",
    minutes_charged: null,
    broadcast: null,
    broadcast_start: null,
    broadcast_end: null,
    product: "FanClub",
    product_subtype: "tip3",
    website: "cams.com",
    member_spent_tokens: 100,
    member_spent_usd: 10,
    transaction_type: "tiered_transaction",
    package_id: null,
    package_name: null,
    created_date: "2021-05-13T13:50:39.004Z",
    note: "tip - tip3",
    percentage: 5,
    earning: 32,
    earning_usd: 3.2,
    refunded: false,
  },
  {
    id: "61099",
    minutes_charged: null,
    broadcast: null,
    broadcast_start: null,
    broadcast_end: null,
    product: "Virtual Gift",
    product_subtype: "tip3",
    website: "cams.com",
    member_spent_tokens: 100,
    member_spent_usd: 10,
    transaction_type: "tiered_transaction",
    package_id: null,
    package_name: null,
    created_date: "2021-05-03T13:50:39.004Z",
    note: "tip - tip3",
    percentage: 5,
    earning: 32,
    earning_usd: 3.2,
    refunded: false,
  },
  {
    id: "61100",
    minutes_charged: null,
    broadcast: null,
    broadcast_start: null,
    broadcast_end: null,
    product: "tip",
    product_subtype: "tip3",
    website: "cams.com",
    member_spent_tokens: 100,
    member_spent_usd: 10,
    transaction_type: "tiered_transaction",
    package_id: null,
    package_name: null,
    created_date: "2021-05-18T13:50:39.004Z",
    note: "tip - tip3",
    percentage: 5,
    earning: 32,
    earning_usd: 3.2,
    refunded: false,
  },
  {
    id: "61101",
    minutes_charged: null,
    broadcast: null,
    broadcast_start: null,
    broadcast_end: null,
    product: "tip",
    product_subtype: "tip3",
    website: "cams.com",
    member_spent_tokens: 100,
    member_spent_usd: 10,
    transaction_type: "tiered_transaction",
    package_id: null,
    package_name: null,
    created_date: "2021-03-13T13:50:39.004Z",
    note: "tip - tip3",
    percentage: 5,
    earning: 32,
    earning_usd: 3.2,
    refunded: false,
  },
  {
    id: "61102",
    minutes_charged: null,
    broadcast: null,
    broadcast_start: null,
    broadcast_end: null,
    product: "Wheel of Fun",
    product_subtype: "tip3",
    website: "cams.com",
    member_spent_tokens: 100,
    member_spent_usd: 10,
    transaction_type: "tiered_transaction",
    package_id: null,
    package_name: null,
    created_date: "2021-10-13T13:50:39.004Z",
    note: "tip - tip3",
    percentage: 5,
    earning: 32,
    earning_usd: 3.2,
    refunded: false,
  },
  {
    id: "61103",
    minutes_charged: null,
    broadcast: null,
    broadcast_start: null,
    broadcast_end: null,
    product: "FanClub",
    product_subtype: "tip3",
    website: "cams.com",
    member_spent_tokens: 100,
    member_spent_usd: 10,
    transaction_type: "tiered_transaction",
    package_id: null,
    package_name: null,
    created_date: "2021-07-13T13:50:39.004Z",
    note: "tip - tip3",
    percentage: 5,
    earning: 32,
    earning_usd: 3.2,
    refunded: false,
  },
  {
    id: "61104",
    minutes_charged: null,
    broadcast: null,
    broadcast_start: null,
    broadcast_end: null,
    product: "tip",
    product_subtype: "tip3",
    website: "cams.com",
    member_spent_tokens: 100,
    member_spent_usd: 10,
    transaction_type: "tiered_transaction",
    package_id: null,
    package_name: null,
    created_date: "2021-06-13T13:50:39.004Z",
    note: "tip - tip3",
    percentage: 5,
    earning: 32,
    earning_usd: 3.2,
    refunded: false,
  },
  {
    id: "61105",
    minutes_charged: null,
    broadcast: null,
    broadcast_start: null,
    broadcast_end: null,
    product: "Wheel of Fun",
    product_subtype: "tip3",
    website: "cams.com",
    member_spent_tokens: 100,
    member_spent_usd: 10,
    transaction_type: "tiered_transaction",
    package_id: null,
    package_name: null,
    created_date: "2021-05-20T13:50:39.004Z",
    note: "tip - tip3",
    percentage: 5,
    earning: 32,
    earning_usd: 3.2,
    refunded: false,
  },
];
