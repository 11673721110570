import React, { useMemo } from "react";
import TailwindButton from "library/components/_tailwind/button";
import { IShowType, ShowTab } from "common/broadcast/_stores/broadcast/enums";
import BroadcastStore from "common/broadcast/_stores/broadcast/BroadcastStore";
import { inject, observer } from "mobx-react";
import PricingStore from "common/broadcast/_stores/pricing/PricingStore";
import LanguageStore from "library/core/stores/language/LanguageStore";

type Props = {
  broadcastStore?: BroadcastStore;
  pricingStore?: PricingStore;
  languageStore?: LanguageStore;
};

const BroadcastSwitchShowButton: React.ComponentType<Props> = ({
  broadcastStore,
  pricingStore,
  languageStore
}) => {
  const { intl } = languageStore!;
  const {
    isShowStarted,
    currentShowTab,
    currentShowType,
    switchToShow,
    isStartingOrStoppingStream,
    isInAdminShow,
  } = broadcastStore!;
  const { areTippingFieldsValid } = pricingStore!;

  const shouldShowSwitch = useMemo(() => {
    return (
      (currentShowTab === ShowTab.FREE &&
        ![IShowType.FREE, IShowType.PRIVATE, IShowType.GROUP].includes(
          currentShowType
        )) ||
      (currentShowTab === ShowTab.TIPPING &&
        ![
          IShowType.CURTAIN_DROPPED,
          IShowType.TIPPING,
          IShowType.GROUP,
        ].includes(currentShowType)) ||
      (currentShowTab === ShowTab.NUDE &&
        ![IShowType.NUDE, IShowType.GROUP].includes(currentShowType))
    );
  }, [currentShowType, currentShowTab]);

  if (!isShowStarted || !shouldShowSwitch || isStartingOrStoppingStream) {
    return null;
  }

  return (
    <TailwindButton
      data-testid={`broadcast-switch-show-button-${currentShowTab}`}
      settings={true}
      disabled={!areTippingFieldsValid || isInAdminShow}
      className={["mt-4"]}
      backgroundColor={"secondary"}
      rounded={false}
      fullWidth={true}
      justifyContent={"justify-center"}
      onClick={() => switchToShow(currentShowTab)}>
      {intl.formatMessage(
        {
          id: "broadcast-control-button.switch",
          defaultMessage: "Switch to {activeShowTab} show",
        },
        {
          activeShowTab: currentShowTab,
        }
      )}
    </TailwindButton>
  );
};

export default inject(
  "broadcastStore",
  "pricingStore",
  "languageStore"
)(observer(BroadcastSwitchShowButton));
