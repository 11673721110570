import React, { useMemo } from "react";
import TailwindDropdown, { TailwindDropdownProps } from "../dropdown";
import "flag-icons/css/flag-icons.min.css";
import { COUNTRY_LIST } from "../../../core/constants";
import classNames from "classnames";

export type TailwindDropdownCountryFlagsProps = {
  flagValues: TailwindDropdownCountryFlagsTextValue[];
} & Omit<TailwindDropdownProps, "items">;

export type TailwindDropdownCountryFlagsTextValue = {
  "countryCodeISO3166-1-alpha-2": string;
  label: string;
};

const TailwindDropdownCountryFlags: React.ComponentType<TailwindDropdownCountryFlagsProps> =
  ({ flagValues, ...props }) => {
    const items = useMemo(() => {
      return Object.entries(COUNTRY_LIST).map(([key, value]) => {
        let _label = value;
        const flagValue = flagValues.find(
          val => val["countryCodeISO3166-1-alpha-2"] === key
        );
        if (flagValue?.label) {
          _label = flagValue.label;
        }

        return {
          label: _label,
          value: key,
          leftIconProps: {
            name: (
              <span
                className={classNames(
                  "fi",
                  "fis",
                  `fi-${key.toLowerCase()}`
                )}></span>
            ),
          },
        };
      });
    }, [flagValues]);

    return (
      <TailwindDropdown
        {...props}
        items={items}
        selectedItemButtonProps={{
          ...props.selectedItemButtonProps,
          leftIconProps: {
            name: (
              <span
                className={classNames(
                  "fi",
                  "fis",
                  `fi-${(props.value as string)?.toLowerCase()}`
                )}></span>
            ),
          },
        }}
      />
    );
  };

export default TailwindDropdownCountryFlags;
