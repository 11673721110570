import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import StorybookArchitectureFileAndFolderStructure from "./architecture-file-and-folder-structure";
import StorybookArchitectureAdaptiveAndResponsiveDesign from "./architecture-adaptive-and-responsive-design";
import StorybookArchitectureTailwindCSS from "./architecture-tailwindcss";
import StorybookArchitectureUtilities from "./architecture-utilities";
import StorybookArchitectureMobXAndStores from "./architecture-mobx-and-stores";
import StorybookArchitectureMonoRepo from "./architecture-monorepo";
import StorybookArchitectureLayout from "./architecture-layout";
import StorybookArchitectureTheming from "./architecture-theming";
import StorybookArchitectureInternalizationAndLocalization from "./architecture-i18n";
import StorybookArchitectureTests from "./architecture-tests";

type Props = {};

const StorybookArchitecture: React.ComponentType<Props> = ({}) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/monorepo`}>
        <StorybookArchitectureMonoRepo />
      </Route>
      <Route path={`${path}/file-and-folder-structure`}>
        <StorybookArchitectureFileAndFolderStructure />
      </Route>
      <Route exact path={`${path}/adaptive-and-responsive-design`}>
        <StorybookArchitectureAdaptiveAndResponsiveDesign />
      </Route>
      <Route exact path={`${path}/layout`}>
        <StorybookArchitectureLayout />
      </Route>
      <Route exact path={`${path}/tailwind-css`}>
        <StorybookArchitectureTailwindCSS />
      </Route>
      <Route exact path={`${path}/utilities`}>
        <StorybookArchitectureUtilities />
      </Route>
      <Route exact path={`${path}/mobx-and-stores`}>
        <StorybookArchitectureMobXAndStores />
      </Route>
      <Route exact path={`${path}/theming`}>
        <StorybookArchitectureTheming />
      </Route>
      <Route exact path={`${path}/i18n`}>
        <StorybookArchitectureInternalizationAndLocalization />
      </Route>
      <Route exact path={`${path}/tests`}>
        <StorybookArchitectureTests />
      </Route>
    </Switch>
  );
};

export default StorybookArchitecture;
