import React from "react";

interface Props {}

const NanoPlayerRoot: React.FunctionComponent<Props> = () => {
  return (
    <div id="NANO_ROOT" style={{ display: "none" }}>
      <div id="NANO_HOST"></div>
    </div>
  );
};

export default NanoPlayerRoot;
