import React, { useCallback } from "react";
import TailwindFlex, {
  TailwindFlexProps,
} from "library/components/_tailwind/flex";
import { MessageCenterAuditWarning } from "common/messages/stores/messages/types";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import { inject, observer } from "mobx-react";
import TailwindText from "library/components/_tailwind/text";
import TailwindAnchor from "library/components/_tailwind/anchor";
import TailwindBox from "library/components/_tailwind/box";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import MessageStore from "common/messages/stores/messages/MessageStore";
import TailwindButton from "library/components/_tailwind/button";
import ModalStore from "library/core/stores/modal";
import { MESSAGE_CENTER_AUDIT_NOTIFICATION_ID } from "common/messages/stores/messages/consts";
import TailwindImage from "library/components/_tailwind/image";

type Props = {
  profileStore?: ProfileStore;
  messageStore?: MessageStore;
  modalStore?: ModalStore;
  containerProps?: TailwindFlexProps;
  isModal?: boolean;
};

const AccountAuditedDisclaimer: React.ComponentType<Props> = ({
  profileStore,
  messageStore,
  modalStore,
  containerProps,
  isModal,
}) => {
  const { closePrimaryModal } = modalStore!;
  const {
    audits,
    markNotificationsAsRead,
    dismissNotification,
    hasAcknowledgedAllAudits,
  } = messageStore!;
  const {
    modelProfile,
    mock2257WarningReason,
    mock2257WarningResult,
    mock2257WarningShouldShowMockImage,
  } = profileStore!;

  const getViolationResultText = useCallback(
    (audit: MessageCenterAuditWarning) => {
      const noteText = (
        <React.Fragment>
          <br />
          <br />
          Please Note: Any suspension may cause a delay of payment, as payments
          cannot be processed on suspended accounts. Furthermore, a continuation
          of misconduct will ultimately result in termination of the account and
          all earnings will be forfeited.
        </React.Fragment>
      );
      if (
        (mock2257WarningResult ||
          audit.action_object?.warning_message?.name) === "EMPTY" ||
        (mock2257WarningResult ||
          audit.action_object?.warning_message?.name) === "WARNING" ||
        modelProfile.is_broadcast_allowed
      ) {
        return (
          <div>
            This is a <span className='text-red-500 font-bold'>warning</span>.
            If you or your models continue to ignore our warnings, we will have
            no alternative but to take more aggressive actions. This may include
            TERMINATION of the account. {noteText}
          </div>
        );
      } else {
        return (
          <div>
            This is a <span className='text-red-500 font-bold'>suspension</span>
            . If you or your models continue to ignore our warnings, we will
            have no alternative but to take more aggressive actions. This may
            include TERMINATION of the account. {noteText}
          </div>
        );
      }

      return undefined;
    },
    [modelProfile, mock2257WarningResult]
  );

  const getSuspensionDuration = useCallback(
    (audit: MessageCenterAuditWarning) => {
      if (
        mock2257WarningResult ||
        (audit.action_object?.warning_message?.name !== "EMPTY" &&
          mock2257WarningResult) ||
        audit.action_object?.warning_message?.name !== "WARNING"
      ) {
        switch (
          mock2257WarningResult ||
          audit.action_object?.warning_message?.name
        ) {
          case "SUSPEND_24H": {
            return "cannot broadcast for 24 hours";
          }
          case "SUSPEND_48H": {
            return "cannot broadcast for 48 hours";
          }
          case "SUSPEND_72H": {
            return "cannot broadcast for 72 hours";
          }
          case "SUSPEND_INDEFINITELY": {
            return "Suspended indefinitely";
          }
          case "TERMINATION_BLACKLIST": {
            return "Blacklisted";
          }
        }
      }
      return undefined;
    },
    [mock2257WarningResult]
  );

  const onClickAcknowledge = useCallback(() => {
    dismissNotification(MESSAGE_CENTER_AUDIT_NOTIFICATION_ID);
    markNotificationsAsRead(
      audits.map(audit => audit.id),
      false,
      false
    );
    closePrimaryModal();
  }, [audits]);

  return (
    <TailwindFlex
      {...containerProps}
      width={containerProps?.width || "w-192"}
      md={containerProps?.md || "w-11/12"}
      height={"h-full"}
      alignItems={"items-center"}
      justifyContent={"justify-center"}>
      <TailwindFlex
        justifyContent={"justify-center"}
        padding={["p-10"]}
        flexDirection={"flex-col"}
        backgroundColor={"bg-white"}>
        <TailwindText
          textColor={"text-red-500"}
          fontWeight={"font-bold"}
          fontSize={"text-2xl"}
          textAlign={"text-center"}
          margin={["mb-4"]}>
          Broadcast Violation Notification
        </TailwindText>
        <TailwindBox margin={["mb-4"]}>
          <TailwindBox margin={["mb-4"]}>
            <TailwindText>Dear {modelProfile.username},</TailwindText>
          </TailwindBox>
          <TailwindBox whiteSpace={"whitespace-pre-wrap"}>
            Your account is in violation of one or more{" "}
            <TailwindAnchor
              to={
                "https://docs.google.com/spreadsheets/d/11md84_POifyub5uDuSSu0_wYQO3JDBi6JRkInDcZOOw/edit#gid=0"
              }>
              Model Rules
            </TailwindAnchor>{" "}
            for broadcasting.
          </TailwindBox>
          <TailwindBox margin={["mb-4"]}>
            <TailwindText>
              Please see a list of your violations below, as well as your
              penalty for the violations, which is highlighted in red.
            </TailwindText>
          </TailwindBox>
          {!hasAcknowledgedAllAudits && (
            <TailwindBox margin={["mb-8"]}>
              <TailwindText>
                Once you have read this notification , please click "I
                Acknowledge" button at the bottom of the page.
              </TailwindText>
            </TailwindBox>
          )}
        </TailwindBox>
        <TailwindBox
          margin={["mb-8"]}
          padding={["pb-4"]}
          borderWidth={["border-b"]}
          borderColor={"border-black"}>
          {audits.map(audit => (
            <TailwindBox
              key={audit.id}
              margin={["mb-4"]}
              padding={["pt-4"]}
              borderWidth={["border-t"]}
              borderColor={"border-black"}>
              <b>Reference Audit ID:</b> {audit.id}
              <TailwindFlex
                borderWidth={["border-b"]}
                borderColor={"border-black"}
                flexDirection={"flex-col"}
                margin={["mt-4"]}
                padding={["pb-4"]}>
                <TailwindFlex width={"w-full"}>
                  {(mock2257WarningShouldShowMockImage ||
                    audit.data.striking_thumb) && (
                    <TailwindFlex
                      flexDirection={"flex-col"}
                      alignItems={"items-center"}>
                      <TailwindImage
                        src={
                          mock2257WarningShouldShowMockImage
                            ? "https://picsum.photos/200/300"
                            : audit.data.striking_thumb
                        }
                        boxProps={{ width: "w-48" }}
                      />
                      <TailwindText
                        fontSize={"text-sm"}
                        textAlign={"text-center"}>
                        Striking Snapshot taken in{" "}
                        {audit.action_object?.striking_thumb?.show_type} show
                      </TailwindText>
                    </TailwindFlex>
                  )}
                  <TailwindBox margin={["ml-4"]}>
                    {audit.action_object?.striking_thumb?.flags?.map(flag => (
                      <TailwindBox
                        key={`striking-thumbnail-penalty-flag-${audit.id}`}
                        margin={["mb-6"]}>
                        <TailwindBox margin={["mb-4"]}>
                          <TailwindTranslatedText
                            fontWeight={"font-bold"}
                            descriptor={{
                              id: `model2257.${
                                mock2257WarningReason || flag.code
                              }.subject`,
                            }}
                            values={{
                              modelStreamName: modelProfile.screen_name,
                            }}
                          />
                        </TailwindBox>
                        <TailwindBox>
                          <TailwindTranslatedText
                            descriptor={{
                              id: `model2257.${
                                mock2257WarningReason || flag.code
                              }.body`,
                            }}
                            values={{
                              modelStreamName: modelProfile.screen_name,
                            }}
                          />
                        </TailwindBox>
                      </TailwindBox>
                    ))}
                  </TailwindBox>
                </TailwindFlex>
              </TailwindFlex>
              {getViolationResultText(audit) && (
                <TailwindBox margin={["mt-6"]}>
                  {getViolationResultText(audit)}
                  {getSuspensionDuration(audit) && (
                    <TailwindBox margin={["mt-4"]}>
                      <TailwindBox>
                        <TailwindText
                          textColor={"text-red-500"}
                          fontWeight={"font-bold"}>
                          Penalty Status:{" "}
                        </TailwindText>
                        <TailwindText>
                          {getSuspensionDuration(audit)}
                        </TailwindText>
                      </TailwindBox>
                      {modelProfile.is_broadcast_disallowed_until && (
                        <TailwindBox>
                          <TailwindText
                            textColor={"text-red-500"}
                            fontWeight={"font-bold"}>
                            Penalty Ends on:{" "}
                          </TailwindText>
                          <TailwindText>
                            {new Date(
                              modelProfile.is_broadcast_disallowed_until!
                            ).toLocaleString()}
                          </TailwindText>
                        </TailwindBox>
                      )}
                    </TailwindBox>
                  )}
                </TailwindBox>
              )}
            </TailwindBox>
          ))}
        </TailwindBox>
        <TailwindFlex justifyContent={"justify-center"}>
          <TailwindButton
            fullWidth={false}
            backgroundColor={"primary"}
            onClick={onClickAcknowledge}
            disabled={isModal ? false : audits.every(audit => !audit.unread)}>
            {hasAcknowledgedAllAudits
              ? isModal
                ? "Close"
                : "You have previously acknowledged this audit"
              : "I Acknowledge"}
          </TailwindButton>
        </TailwindFlex>
        <TailwindFlex margin={["mt-8"]} flexDirection={"flex-col"}>
          <TailwindText
            textAlign={"text-center"}
            fontSize={"text-sm"}
            fontWeight={"font-bold"}>
            Streamray Compliance Team
          </TailwindText>
          <TailwindAnchor
            to={"/acceptable-use-policy"}
            margin={["mx-auto"]}
            size={"sm"}
            openInNewTab={true}>
            Acceptable Use Policy
          </TailwindAnchor>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default inject(
  "profileStore",
  "messageStore",
  "modalStore"
)(observer(AccountAuditedDisclaimer));
