import React from "react";
import TailwindButton from "../button";
import TailwindFlex from "../flex";

export type MediaBlurredOverlayProps = {
  buttonLabel?: string;
  children?: any;
};

const MediaBlurredOverlay: React.ComponentType<MediaBlurredOverlayProps> = ({
  buttonLabel,
  children,
}) => {
  return (
    <TailwindFlex
      height={"h-full"}
      alignItems={"items-center"}
      justifyContent={"justify-center"}
      style={{
        backdropFilter: "blur(0.75rem)",
      }}>
      {buttonLabel ? (
        <TailwindButton
          fullWidth={false}
          padding={["px-8"]}
          backgroundColor={"bg-gray-400"}
          backgroundOpacity={"bg-opacity-40"}>
          {buttonLabel}
        </TailwindButton>
      ) : (
        children
      )}
    </TailwindFlex>
  );
};

export default MediaBlurredOverlay;
