import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import BroadcastStore from "./_stores/broadcast/BroadcastStore";
import TailwindDropdown from "library/components/_tailwind/dropdown";
import TailwindTooltip from "library/components/_tailwind/tooltip";
import { inject, observer } from "mobx-react";
import NodeChatStore from "./_stores/nodechat/NodeChatStore";
import { MemberSortTypes } from "./_stores/nodechat/types";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import TailwindText from "library/components/_tailwind/text";
import LanguageStore from "library/core/stores/language/LanguageStore";

type ViewersSortSelectProps = {
  nodeChatStore?: NodeChatStore;
  broadcastStore?: BroadcastStore;
  languageStore?: LanguageStore;
};

const ViewersSortSelect: React.ComponentType<ViewersSortSelectProps> = ({
  nodeChatStore,
  languageStore
}) => {
  
  const { memberSortType, setMemberSortType } = nodeChatStore!;
  const { intl } = languageStore!;

  return (
    <TailwindFlex
      width={"w-full"}
      backgroundColor={"bg-primary-bg-color"}
      flexDirection="flex-col"
      justifyContent="justify-start"
      alignItems="items-start"
      gap="gap-2"
    >
      <TailwindFlex flexDirection={"flex-row"} alignItems="items-center">
        <TailwindText 
          whiteSpace={"whitespace-nowrap"} 
          fontWeight="font-bold" 
          fontSize="text-sm" 
          className="text-gray-400" 
          margin={['mr-2']}
        >
          {intl.formatMessage({
            id: 'verbiage.sort-by',
            defaultMessage: "Sort by:"
          })}
        </TailwindText>

        <TailwindDropdown 
          fullWidth={true} 
          backgroundColor={"primary"} 
          name="member-sort-picker" 
          onChange={(v) => setMemberSortType(v as MemberSortTypes)} 
          value={memberSortType} 
          direction="bottom"
          zIndex="z-900"
          thin={true}
          items={[
            {
              label: intl.formatMessage({
                id: 'broadcast.sort-top-spender',
                defaultMessage: 'Top Spender'
              }),
              value: MemberSortTypes.TOP_SPENDER
            },
            {
              label: intl.formatMessage({
                id: 'broadcast.sort-time-joined',
                defaultMessage: 'Time Joined'
              }),
              value: MemberSortTypes.TIME_JOINED
            },
            {
              label: intl.formatMessage({
                id: 'broadcast.sort-reg-date',
                defaultMessage: 'Registration Date'
              }),
              value: MemberSortTypes.REG_DATE
            },
            {
              label: intl.formatMessage({
                id: 'broadcast.sort-alpha',
                defaultMessage: 'Alphabetical'
              }),
              value: MemberSortTypes.ALPHA
            },
        ]}></TailwindDropdown>

        <TailwindTooltip
          content={
            intl.formatMessage({
              id: 'broadcast.sort-pref-tip',
              defaultMessage: "Set up your preferences for the order in which members appear in the members list."
            })
          }>
          <TailwindFlex
            width={"w-auto"}
            alignItems={"items-center"}
            margin={["ml-2"]}>
            <TailwindIcon
              textColor={"text-gray-400"}
              name={CustomIconName["question-mark"]}
            />
          </TailwindFlex>
        </TailwindTooltip>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default inject("nodeChatStore", "languageStore")(observer(ViewersSortSelect));
