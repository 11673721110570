import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindListItem from "../../components/_tailwind/list/list-item";
import TailwindList, {
  TailwindListType,
} from "../../components/_tailwind/list";

type Props = {};

const StorybookComponentsList: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; List</StorybookHeading>
      <StorybookParagraph>
        TailwindList is a wrapper of TailwindBox that means it can take all
        props from TailwindBox but has a prop type, to create the desired list.
      </StorybookParagraph>
      <StorybookParagraph>
        By defaultTailwindList creates a list without any list style. Type ol
        will apply utility class list-decimal for a numeric list. Type ul will
        apply utility class list-disc to create a bulleted list.
      </StorybookParagraph>
      <Code>{`type?: ol | ul`}</Code>

      <StorybookComponentShowcase
        sourceCode={`<TailwindList>
                        <TailwindListItem>some text</TailwindListItem>
                        <TailwindListItem>more text</TailwindListItem>
                    </TailwindList>`}
        height={"h-96"}>
        <TailwindList>
          <TailwindListItem>some text</TailwindListItem>
          <TailwindListItem>more text</TailwindListItem>
        </TailwindList>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindList type={TailwindListType.ul}>
                        <TailwindListItem>some text</TailwindListItem>
                        <TailwindListItem>more text</TailwindListItem>
                    </TailwindList>`}
        height={"h-96"}>
        <TailwindList type={TailwindListType.ul}>
          <TailwindListItem>some text</TailwindListItem>
          <TailwindListItem>more text</TailwindListItem>
        </TailwindList>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindList type={TailwindListType.ol}>
                        <TailwindListItem>some text</TailwindListItem>
                        <TailwindListItem>more text</TailwindListItem>
                    </TailwindList>`}
        height={"h-96"}>
        <TailwindList type={TailwindListType.ol}>
          <TailwindListItem>some text</TailwindListItem>
          <TailwindListItem>more text</TailwindListItem>
        </TailwindList>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsList;
