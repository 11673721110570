import { DEFAULT_LANDING_TITLE } from "./index";
import { AppRoute } from "./types";
import { CAMS_MODEL_LANDING_TITLE, CAMS_STUDIO_LANDING_TITLE } from "./cams";
import { UserRole } from "library/core/types";

export const isRouteVisibleOnSidebarForSite = (
  userRole: UserRole | null,
  isIndividualModel: boolean,
  profileLoaded: boolean,
  profileRegistrationComplete: boolean,
  route?: AppRoute
) => {
  if (!route?.hasAccess) {
    return true;
  }

  if (userRole) {
    if (Array.isArray(route.hasAccess)) {
      return route.hasAccess.includes(userRole);
    } else {
      return route.hasAccess(
        userRole,
        isIndividualModel,
        profileLoaded,
        profileRegistrationComplete
      );
    }
  }

  return false;
};

export const getLandingRouteMetaTitle = (userRole?: UserRole | null) => {
  if (userRole === "model") {
    return CAMS_MODEL_LANDING_TITLE;
  } else if (userRole === "studio") {
    return CAMS_STUDIO_LANDING_TITLE;
  } else {
    return DEFAULT_LANDING_TITLE;
  }
};
