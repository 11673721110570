import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindCard from "../../components/_tailwind/card";

type Props = {};

const StorybookComponentsCard: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Card</StorybookHeading>
      <StorybookParagraph>
        TailwindCard component is a wrapper around TailwindBox but has some
        opinions like border radius via <Code inline>rounded: boolean</Code>{" "}
        prop or{" "}
        <Code inline>
          backgroundColor: "primary" | "secondary" | "transparent" |
          TBackgroundColor
        </Code>
        . It has been created in a way the code is specific enough to say what
        we are working on is a "Card". In time we will add more opinions to it
        like a header, footer or corner action button areas so it is good to
        wrap code that needs to stand out from the rest with this component. It
        takes all props TailwindBox does. Here are some examples:
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindCard rounded>rounded</TailwindCard>`}>
        <TailwindCard rounded>rounded</TailwindCard>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindCard>rounded</TailwindCard>`}>
        <TailwindCard>sharp</TailwindCard>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindCard rounded backgroundColor={"bg-red-500"}>rounded</TailwindCard>`}>
        <TailwindCard rounded backgroundColor={"bg-red-500"}>
          rounded bg-red-500
        </TailwindCard>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindCard rounded backgroundColor={"bg-red-500"} padding={["p-4"]}>
          rounded bg-red-500 p-4
        </TailwindCard>`}>
        <TailwindCard rounded backgroundColor={"bg-red-500"} padding={["p-4"]}>
          rounded bg-red-500 p-4
        </TailwindCard>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={` <TailwindCard
  rounded
  backgroundColor={"bg-red-500"}
  padding={["p-4"]}
  width={"w-3/12"}
  psuedoClasses={["md:w-full"]}
>
  rounded bg-red-500 p-4 w-3/12 md:w-full
</TailwindCard>
        `}>
        <TailwindCard
          rounded
          backgroundColor={"bg-red-500"}
          padding={["p-4"]}
          width={"w-3/12"}
          pseudoClasses={["md:w-full"]}>
          rounded bg-red-500 p-4 w-3/12 md:w-full
        </TailwindCard>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsCard;
