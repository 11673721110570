import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import EarningsTableSimpleRow from "./earnings-table-simple-row";
import { inject, observer } from "mobx-react";
import EarningsStore from "common/earnings/_stores/earnings/EarningsStore";
import TailwindSpinner from "library/components/_tailwind/spinner";

type Props = {
  earningsStore?: EarningsStore;
};

const EarningsTableSimpleBody: React.ComponentType<Props> = ({
  earningsStore,
}) => {
  const { studioModelEarningsForPeriodLight, loadingEarnings } = earningsStore!;

  if (loadingEarnings) {
    return (
      <TailwindFlex
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        width={"w-full"}
        height={"h-full"}
        minHeight={"min-h-60"}>
        <TailwindSpinner />
      </TailwindFlex>
    );
  }

  return (
    <TailwindFlex
      flexDirection={"flex-col"}
      overflow={["overflow-y-auto", "overflow-x-hidden"]}>
      {studioModelEarningsForPeriodLight?.map((totalPeriod, index) => (
        <EarningsTableSimpleRow key={index} periodTotal={totalPeriod} />
      ))}
    </TailwindFlex>
  );
};

export default inject("earningsStore")(observer(EarningsTableSimpleBody));
