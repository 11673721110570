import React, { FunctionComponent, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import { useInterval } from "library/core/utility/hooks/interval";
import camsAudio from "library/core/utility/audio";

interface Props {
  broadcastStore?: BroadcastStore;
}

const COUNTER_SECONDS = 30;

const BroadcastPausedCounter: FunctionComponent<Props> = ({
  broadcastStore,
}) => {
  const { restoreFreeShow, broadcasterVariant } = broadcastStore!;
  const [timer, setTimer] = useState<number>(COUNTER_SECONDS);
  const [hasTimerExpired, setHasTimerExpired] = useState<boolean>(false);

  useInterval(() => {
    if (timer > 0) {
      setTimer(timer - 1);
    } else if (timer === 0) {
      // this fires twice therefore use the method with useState and useEffect
      // useEffect does not fire a second time when value is already true
      setHasTimerExpired(true);
    }
  }, 1000);

  useEffect(() => {
    if (timer === 30) {
      camsAudio.playEndPrivateChat();
    }
  }, []); //private show ending at start of timer audio

  useEffect(() => {
    if (hasTimerExpired) {
      restoreFreeShow();
    }
  }, [hasTimerExpired]);

  useEffect(() => {
    if (broadcasterVariant === "member-verification") {
      restoreFreeShow();
    }

    return () => {
      setTimer(COUNTER_SECONDS);
      setHasTimerExpired(false);
    };
  }, []);

  return (
    <div className='BroadcastOBSTimer paused h-64 z-10'>
      <div className={`BroadcastOBSTimer__video`}></div>
      <div className='BroadcastOBSTimer__overlay'>
        <div className='BroadcastOBSTimer__timer'>{timer}</div>
      </div>
    </div>
  );
};

export default inject("broadcastStore")(observer(BroadcastPausedCounter));
