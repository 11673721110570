import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { injectIntl, WrappedComponentProps } from "react-intl";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import AuthStore from "core/stores/auth/AuthStore";
import { inject, observer } from "mobx-react";
import { UserAccountStatus } from "../../../../common/my-page/stores/profile/enums";
import { UserRole } from "library/core/types";
import TailwindCard from "library/components/_tailwind/card";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindParagraph from "library/components/_tailwind/paragraph";
import TailwindButton from "library/components/_tailwind/button";
import {
  CAMS_MODEL_APPLICATIONS_EMAIL,
  CAMS_STUDIO_APPLICATIONS_EMAIL,
  CAMS_SUPPORT_PHONE_NUMBER,
} from "library/core/config/urls";

export type AccessRestrictionOptions = {
  approvedOnly?: UserRole[];
};

type AccessRestrictionProps = AccessRestrictionOptions & {
  profileStore?: ProfileStore;
  authStore?: AuthStore;
  children?: React.ReactNode;
};

const AccessRestriction: React.FunctionComponent<
  AccessRestrictionProps & WrappedComponentProps
> = ({ profileStore, authStore, intl, children, approvedOnly }) => {
  const { openRegisterModalToCompleteProfile } = authStore!;

  const location = useLocation();

  const {
    profileAccountStatus,
    modelProfile,
    studioProfile,
    profileRegistrationCompleted,
    isModel,
    isStudioModel,
  } = profileStore!;

  const { userRole } = authStore!;
  useEffect(() => {
    if (
      !profileRegistrationCompleted &&
      (studioProfile.id.length || modelProfile.id.length)
    ) {
      openRegisterModalToCompleteProfile();
    }
  }, [location, isModel]);

  const denyReasons =
    studioProfile?.deny_reasons || modelProfile?.deny_reasons || {};
  const hasDenyReason = useMemo(
    () => (Object.keys(denyReasons).length || 0) > 0,
    [denyReasons]
  );

  const shouldGiveAccess = useMemo(() => {
    if (
      userRole &&
      approvedOnly?.includes(userRole) &&
      profileAccountStatus !== UserAccountStatus.APPROVED
    ) {
      return false;
    }

    return true;
  }, [approvedOnly, userRole, profileAccountStatus]);

  const onClickResubmitApplication = useCallback(() => {
    openRegisterModalToCompleteProfile(false, true);
  }, []);

  if (shouldGiveAccess) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <TailwindCard
      backgroundColor={"primary"}
      height={"h-full"}
      pseudoClasses={["md:h-auto"]}>
      <TailwindFlex
        flexGrow={"flex-grow"}
        height={"h-full"}
        pseudoClasses={["md:h-auto"]}
        flexDirection={"flex-col"}
        alignItems={"items-center"}
        justifyContent={"justify-center"}>
        {profileAccountStatus === UserAccountStatus.DENIED && (
          <React.Fragment>
            <TailwindParagraph margin={["mb-6"]} fontWeight={"font-bold"}>
              Your application is almost complete and you're close to becoming
              an important part of the Streamray line-up.
            </TailwindParagraph>
            {hasDenyReason && (
              <>
                <TailwindParagraph
                  margin={["mt-4"]}
                  textDecoration={"underline"}>
                  The following needs your attention:
                </TailwindParagraph>

                <TailwindFlex
                  width={"w-auto"}
                  margin={["mb-5"]}
                  flexDirection={"flex-col"}
                  justifyContent={"justify-center"}>
                  {Object.keys(denyReasons).map((denyReason, index) => (
                    <TailwindParagraph
                      textColor={"text-red-500"}
                      width={"w-auto"}
                      margin={["mt-2"]}
                      key={`access-restriction-reason-denial-${index}`}>
                      {index + 1}. {denyReasons[denyReason]}
                    </TailwindParagraph>
                  ))}
                </TailwindFlex>

                <TailwindFlex
                  justifyContent={"justify-center"}
                  margin={["mb-10"]}>
                  <TailwindButton
                    rounded={false}
                    fullWidth={false}
                    onClick={onClickResubmitApplication}
                    backgroundColor={"secondary"}>
                    Resubmit your application
                  </TailwindButton>
                </TailwindFlex>

                {isStudioModel && (
                  <TailwindParagraph>
                    {intl.formatMessage({
                      id: "message.error.contact-studio-owner",
                      defaultMessage:
                        "Please contact your studio owner to get your application updated",
                    })}
                  </TailwindParagraph>
                )}
              </>
            )}

            <TailwindParagraph>
              {intl.formatMessage({
                id: "access-rights.have-questions",
                defaultMessage:
                  "If you have any questions, please contact us at any of these numbers:",
              })}
            </TailwindParagraph>
            <TailwindParagraph>
              {intl.formatMessage(
                {
                  id: "access-rights.contact-email",
                  defaultMessage: "Email: {email}",
                },
                {
                  email:
                    userRole === "studio"
                      ? CAMS_STUDIO_APPLICATIONS_EMAIL
                      : CAMS_MODEL_APPLICATIONS_EMAIL,
                }
              )}
            </TailwindParagraph>
            <TailwindParagraph>
              {intl.formatMessage(
                {
                  id: "access-rights.tel",
                  defaultMessage: "Tel (USA & International): {tel}",
                },
                { tel: CAMS_SUPPORT_PHONE_NUMBER }
              )}
            </TailwindParagraph>
          </React.Fragment>
        )}
        {profileAccountStatus === UserAccountStatus.PENDING && (
          <React.Fragment>
            <TailwindParagraph fontWeight={"font-bold"}>
              {intl.formatMessage({
                id: "access-rights.you-will-be-able-to-access",
                defaultMessage:
                  "You are restricted from accessing this page because your account has not been approved yet.",
              })}
            </TailwindParagraph>
          </React.Fragment>
        )}
      </TailwindFlex>
    </TailwindCard>
  );

  return null;
};

export default injectIntl(
  inject("profileStore", "authStore")(observer(AccessRestriction))
);
