import React, { useState } from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindText from "../../components/_tailwind/text";
import ReactModal from "../../components/modal";
import TailwindCard from "../../components/_tailwind/card";
import TailwindIcon from "../../components/_tailwind/icon";
import TailwindFlex from "../../components/_tailwind/flex";
import { CustomIconName } from "../../components/_tailwind/icon/icons/enums";
import TailwindPill from "../../components/_tailwind/pill";
import TailwindDivider from "../../components/_tailwind/divider";
import TailwindBox from "../../components/_tailwind/box";
import MediaProfileThumbnail from "../../components/_tailwind/media/media-profile-thumbnail";
import Avatar from "../../components/avatar";
import TailwindInput from "../../components/_tailwind/input";
import TailwindButton from "../../components/_tailwind/button";
import Media from "../../components/_tailwind/media";
import MediaProfileOverlay from "../../components/_tailwind/media/media-profile-overlay";
import MediaProfileOnlineStatusOverlay from "../../components/_tailwind/media/media-online-status-overlay";
import MediaBlurredOverlay from "../../components/_tailwind/media/media-blurred-overlay";
import MediaBadgesOverlay from "../../components/_tailwind/media/media-badges-overlay";

type Props = {};

const StorybookComponentsMedia: React.ComponentType<Props> = ({}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Media</StorybookHeading>
      <StorybookParagraph>
        Media components are a collection of components composed of several
        other components for maximum usability. Currently most of them are used
        in AFF but they will also be used at Cams. The name Media comes from the
        fact that it shows a photo therefore can serve the same purpose for any
        cover image essentially.
      </StorybookParagraph>
      <StorybookParagraph>
        These components are so far only used on AFF but will be used in other
        projects later.
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<Media
            src={
              "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478506438_55312_1288263904_0_main-7eeed670a72bddbc6722abdf76e3406c-d6625c.jpg"
            }
            height={"h-96"}
          />`}>
        Square
        <TailwindFlex width={"w-4/12"}>
          <Media
            src={
              "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478506438_55312_1288263904_0_main-7eeed670a72bddbc6722abdf76e3406c-d6625c.jpg"
            }
            height={"h-96"}
          />
        </TailwindFlex>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<Media
            src={
              "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478506438_55312_1288263904_0_main-7eeed670a72bddbc6722abdf76e3406c-d6625c.jpg"
            }
            height={"h-96"}
            rounded
          />`}>
        Rounded
        <TailwindFlex width={"w-4/12"}>
          <Media
            src={
              "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478506438_55312_1288263904_0_main-7eeed670a72bddbc6722abdf76e3406c-d6625c.jpg"
            }
            rounded
            height={"h-96"}
          />
        </TailwindFlex>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<Media
            src={
              "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478506438_55312_1288263904_0_main-7eeed670a72bddbc6722abdf76e3406c-d6625c.jpg"
            }
            rounded
            height={"h-96"}
          >
            <TailwindFlex
              height={"h-full"}
              alignItems={"items-center"}
              justifyContent={"justify-center"}
            >
              <TailwindText fontSize={"text-3xl"} textColor={"text-main-color"}>
                some child
              </TailwindText>
            </TailwindFlex>
          </Media>`}>
        With Children
        <TailwindFlex width={"w-4/12"}>
          <Media
            src={
              "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478506438_55312_1288263904_0_main-7eeed670a72bddbc6722abdf76e3406c-d6625c.jpg"
            }
            rounded
            height={"h-96"}>
            <TailwindFlex
              height={"h-full"}
              alignItems={"items-center"}
              justifyContent={"justify-center"}>
              <TailwindText fontSize={"text-3xl"} textColor={"text-main-color"}>
                some child
              </TailwindText>
            </TailwindFlex>
          </Media>
        </TailwindFlex>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<Media
            src={
              "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478506438_55312_1288263904_0_main-7eeed670a72bddbc6722abdf76e3406c-d6625c.jpg"
            }
            rounded
            height={"h-96"}
          >
            <MediaProfileOverlay
              username={"someusername"}
              age={25}
              gender={"male"}
              genderShort={"M"}
              isPremiumVIP={true}
              isVerified={true}
            />
          </Media>`}>
        Profile Overlay
        <TailwindFlex width={"w-4/12"}>
          <Media
            src={
              "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478506438_55312_1288263904_0_main-7eeed670a72bddbc6722abdf76e3406c-d6625c.jpg"
            }
            rounded
            height={"h-96"}>
            <MediaProfileOverlay
              username={"someusername"}
              age={25}
              gender={"male"}
              genderShort={"M"}
              isPremiumVIP={true}
              isVerified={true}
            />
          </Media>
        </TailwindFlex>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<Media
            src={
              "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478506438_55312_1288263904_0_main-7eeed670a72bddbc6722abdf76e3406c-d6625c.jpg"
            }
            rounded
            height={"h-96"}
          >
            <MediaProfileOnlineStatusOverlay status={"online"} />
            <MediaProfileOverlay
              username={"someusername"}
              age={25}
              gender={"male"}
              genderShort={"M"}
              isPremiumVIP={true}
              isVerified={true}
            />
          </Media>`}>
        Profile & Online Status Overlay
        <TailwindFlex width={"w-4/12"}>
          <Media
            src={
              "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478506438_55312_1288263904_0_main-7eeed670a72bddbc6722abdf76e3406c-d6625c.jpg"
            }
            rounded
            height={"h-96"}>
            <MediaProfileOnlineStatusOverlay status={"online"} />
            <MediaProfileOverlay
              username={"someusername"}
              age={25}
              gender={"male"}
              genderShort={"M"}
              isPremiumVIP={true}
              isVerified={true}
            />
          </Media>
        </TailwindFlex>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<Media
            src={
              "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478506438_55312_1288263904_0_main-7eeed670a72bddbc6722abdf76e3406c-d6625c.jpg"
            }
            rounded
            height={"h-96"}
          >
            <MediaBlurredOverlay>
              some blurred overlay child
            </MediaBlurredOverlay>
          </Media>`}>
        Blurred Overlay
        <TailwindFlex width={"w-4/12"}>
          <Media
            src={
              "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478506438_55312_1288263904_0_main-7eeed670a72bddbc6722abdf76e3406c-d6625c.jpg"
            }
            rounded
            height={"h-96"}>
            <MediaBlurredOverlay>
              some blurred overlay child
            </MediaBlurredOverlay>
          </Media>
        </TailwindFlex>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<Media
            src={
              "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478506438_55312_1288263904_0_main-7eeed670a72bddbc6722abdf76e3406c-d6625c.jpg"
            }
            rounded
            height={"h-96"}
          >
            <MediaBadgesOverlay isPremium={true} isVerified={true} />
          </Media>`}>
        Badges Overlay
        <TailwindFlex width={"w-4/12"}>
          <Media
            src={
              "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478506438_55312_1288263904_0_main-7eeed670a72bddbc6722abdf76e3406c-d6625c.jpg"
            }
            rounded
            height={"h-96"}>
            <MediaBadgesOverlay isPremium={true} isVerified={true} />
          </Media>
        </TailwindFlex>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<Modal
          className={"w-full h-full"}
          open={isModalOpen}
          showNativeCloseButton={true}
          closeModal={() => setIsModalOpen(false)}
          closeButtonProps={{ textColor: "text-white" }}
        >
          <TailwindCard
            backgroundColor={"primary"}
            rounded
            display={"flex"}
            overflow={"overflow-hidden"}
          >
            <TailwindBox width={"w-96"}>
              <MediaProfileThumbnail
                mediaProps={{
                  src:
                    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478506438_55312_1288263904_0_main-7eeed670a72bddbc6722abdf76e3406c-d6625c.jpg",
                  height: "h-96",
                  width: "w-full"
                }}
              />
            </TailwindBox>
            <TailwindFlex
              flexDirection={"flex-col"}
              height={"h-full"}
              style={{ width: "30rem" }}
            >
              <TailwindBox padding={["p-3"]}>
                <TailwindFlex
                  justifyContent={"justify-between"}
                  alignItems={"items-center"}
                >
                  <TailwindFlex margin={["mt-2"]}>
                    <Avatar
                      size={"small"}
                      photoURL={
                        "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Female_thumb05-81b4a3669710a8cd99ef51b66e8d2075.jpg"
                      }
                      borderSize={"none"}
                      className={"mr-2"}
                    />
                    <TailwindFlex flexDirection={"flex-col"} width={"w-auto"}>
                      <TailwindFlex alignItems={"items-center"}>
                        <TailwindText
                          fontSize={"text-sm"}
                          textColor={"text-white"}
                        >
                          memberusername
                        </TailwindText>
                        <TailwindIcon
                          fontSize={"text-xs"}
                          name={CustomIconName["gold-crown"]}
                          margin={["mx-2"]}
                        />
                        <TailwindIcon
                          fontSize={"text-xs"}
                          name={CustomIconName.verified}
                        />
                      </TailwindFlex>
                      <TailwindText
                        textColor={"text-gray-500"}
                        fontSize={"text-xs"}
                      >
                        26F • Long Beach, CA
                      </TailwindText>
                    </TailwindFlex>
                  </TailwindFlex>
                  <TailwindIcon
                    fontSize={"text-2xl"}
                    name={"more_vert"}
                    textColor={"text-white"}
                    cursor={"cursor-pointer"}
                  />
                </TailwindFlex>
                <TailwindFlex margin={["mt-3"]}>
                  <TailwindPill
                    size={"sm"}
                    width={"w-16"}
                    leftIconProps={{
                      name: "visibility",
                      fontSize: "text-lg",
                    }}
                    textColor={"text-gray-200"}
                  >
                    264
                  </TailwindPill>
                  <TailwindPill
                    size={"sm"}
                    width={"w-16"}
                    leftIconProps={{
                      name: "thumb_up",
                      fontSize: "text-lg",
                    }}
                    textColor={"text-gray-200"}
                  >
                    122
                  </TailwindPill>
                  <TailwindPill
                    size={"sm"}
                    width={"w-16"}
                    leftIconProps={{
                      name: "chat_bubble",
                      fontSize: "text-lg",
                    }}
                    textColor={"text-gray-200"}
                  >
                    0
                  </TailwindPill>
                </TailwindFlex>
              </TailwindBox>
              <TailwindDivider
                color={"bg-gray-800"}
                style={{ height: "1px" }}
              />
              <TailwindBox flexGrow={"flex-grow"} />
              <TailwindDivider
                color={"bg-gray-800"}
                style={{ height: "1px" }}
              />
              <TailwindFlex padding={["p-3"]}>
                <TailwindCard width={"w-auto"} flexGrow={"flex-grow"}>
                  <TailwindInput
                    backgroundColor={"bg-gray-500"}
                    placeholder={"Add a comment"}
                    width={"w-auto"}
                    flexGrow={"flex-grow-0"}
                    wrapInput
                    boxProps={{ backgroundColor: "bg-gray-800" }}
                  />
                </TailwindCard>
                <TailwindCard
                  width={"w-10"}
                  height={"h-10"}
                  display={"flex"}
                  alignItems={"items-center"}
                  justifyContent={"justify-center"}
                  backgroundColor={"secondary"}
                  margin={["mx-2"]}
                >
                  <TailwindIcon
                    fontSize={"text-xl"}
                    name={"thumb_up"}
                    textColor={"text-white"}
                    cursor={"cursor-pointer"}
                  />
                </TailwindCard>
                <TailwindCard
                  width={"w-10"}
                  height={"h-10"}
                  display={"flex"}
                  alignItems={"items-center"}
                  justifyContent={"justify-center"}
                  backgroundColor={"secondary"}
                >
                  <TailwindIcon
                    fontSize={"text-xl"}
                    name={"favorite"}
                    textColor={"text-white"}
                    cursor={"cursor-pointer"}
                  />
                </TailwindCard>
              </TailwindFlex>
            </TailwindFlex>
          </TailwindCard>
        </Modal>`}>
        <StorybookParagraph>Media Modal</StorybookParagraph>
        <StorybookParagraph fontWeight={"font-bold"}>
          Beware, for the purposes of the demo, it is inside a static modal. It
          should be opened through modalStore instead
        </StorybookParagraph>
        <TailwindButton onClick={() => setIsModalOpen(true)}>
          Open modal
        </TailwindButton>
        <ReactModal
          className={"w-full h-full"}
          open={isModalOpen}
          showNativeCloseButton={true}
          closeModal={() => setIsModalOpen(false)}
          closeButtonProps={{ textColor: "text-white" }}>
          <TailwindCard
            backgroundColor={"primary"}
            rounded
            display={"flex"}
            overflow={"overflow-hidden"}>
            <TailwindBox width={"w-96"}>
              <MediaProfileThumbnail
                mediaProps={{
                  src: "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478506438_55312_1288263904_0_main-7eeed670a72bddbc6722abdf76e3406c-d6625c.jpg",
                  height: "h-96",
                  width: "w-full",
                }}
              />
            </TailwindBox>
            <TailwindFlex
              flexDirection={"flex-col"}
              height={"h-full"}
              style={{ width: "30rem" }}>
              <TailwindBox padding={["p-3"]}>
                <TailwindFlex
                  justifyContent={"justify-between"}
                  alignItems={"items-center"}>
                  <TailwindFlex margin={["mt-2"]}>
                    <Avatar
                      size={"small"}
                      photoURL={
                        "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Female_thumb05-81b4a3669710a8cd99ef51b66e8d2075.jpg"
                      }
                      borderSize={"none"}
                      className={"mr-2"}
                    />
                    <TailwindFlex flexDirection={"flex-col"} width={"w-auto"}>
                      <TailwindFlex alignItems={"items-center"}>
                        <TailwindText
                          fontSize={"text-sm"}
                          textColor={"text-white"}>
                          memberusername
                        </TailwindText>
                        <TailwindIcon
                          fontSize={"text-xs"}
                          name={CustomIconName["gold-crown"]}
                          margin={["mx-2"]}
                        />
                        <TailwindIcon
                          fontSize={"text-xs"}
                          name={CustomIconName.verified}
                        />
                      </TailwindFlex>
                      <TailwindText
                        textColor={"text-gray-500"}
                        fontSize={"text-xs"}>
                        26F • Long Beach, CA
                      </TailwindText>
                    </TailwindFlex>
                  </TailwindFlex>
                  <TailwindIcon
                    fontSize={"text-2xl"}
                    name={"more_vert"}
                    textColor={"text-white"}
                    cursor={"cursor-pointer"}
                  />
                </TailwindFlex>
                <TailwindFlex margin={["mt-3"]}>
                  <TailwindPill
                    size={"sm"}
                    width={"w-16"}
                    leftIconProps={{
                      name: "visibility",
                      fontSize: "text-lg",
                    }}
                    textColor={"text-gray-200"}>
                    264
                  </TailwindPill>
                  <TailwindPill
                    size={"sm"}
                    width={"w-16"}
                    leftIconProps={{
                      name: "thumb_up",
                      fontSize: "text-lg",
                    }}
                    textColor={"text-gray-200"}>
                    122
                  </TailwindPill>
                  <TailwindPill
                    size={"sm"}
                    width={"w-16"}
                    leftIconProps={{
                      name: "chat_bubble",
                      fontSize: "text-lg",
                    }}
                    textColor={"text-gray-200"}>
                    0
                  </TailwindPill>
                </TailwindFlex>
              </TailwindBox>
              <TailwindDivider
                color={"bg-gray-800"}
                style={{ height: "1px" }}
              />
              <TailwindBox flexGrow={"flex-grow"} />
              <TailwindDivider
                color={"bg-gray-800"}
                style={{ height: "1px" }}
              />
              <TailwindFlex padding={["p-3"]}>
                <TailwindCard width={"w-auto"} flexGrow={"flex-grow"}>
                  <TailwindInput
                    backgroundColor={"bg-gray-500"}
                    placeholder={"Add a comment"}
                    width={"w-auto"}
                    flexGrow={"flex-grow-0"}
                    wrapInput
                    boxProps={{ backgroundColor: "bg-gray-800" }}
                  />
                </TailwindCard>
                <TailwindCard
                  width={"w-10"}
                  height={"h-10"}
                  display={"flex"}
                  alignItems={"items-center"}
                  justifyContent={"justify-center"}
                  backgroundColor={"secondary"}
                  margin={["mx-2"]}>
                  <TailwindIcon
                    fontSize={"text-xl"}
                    name={"thumb_up"}
                    textColor={"text-white"}
                    cursor={"cursor-pointer"}
                  />
                </TailwindCard>
                <TailwindCard
                  width={"w-10"}
                  height={"h-10"}
                  display={"flex"}
                  alignItems={"items-center"}
                  justifyContent={"justify-center"}
                  backgroundColor={"secondary"}>
                  <TailwindIcon
                    fontSize={"text-xl"}
                    name={"favorite"}
                    textColor={"text-white"}
                    cursor={"cursor-pointer"}
                  />
                </TailwindCard>
              </TailwindFlex>
            </TailwindFlex>
          </TailwindCard>
        </ReactModal>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsMedia;
