import React, { useCallback, useMemo } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import PayoutSettingsConfirmation from "./payout-settings-confirmation";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindHeading from "library/components/_tailwind/heading";
import PayoutStore from "./stores/payout/PayoutStore";
import { CAMS_MODEL_PAYOUT_METHOD_KEY } from "./stores/payout/enums";
import TailwindCard from "library/components/_tailwind/card";
import CamsPayoutWireTransferForm from "./payout-settings-wire-transfer-form";
import CamsPayoutDirectDepositForm from "./payout-settings-direct-deposit-form";
import CamsPayoutPaxumForm from "./payout-settings-paxum-form";
import CamsPayoutCheckForm from "./payout-settings-check-form";
import TailwindButton from "library/components/_tailwind/button";
import TailwindPill from "library/components/_tailwind/pill";

type PayoutSettingsFormProps = {
  payoutStore?: PayoutStore;
};

const PayoutSettingsFormWrapper: React.ComponentType<
  PayoutSettingsFormProps & WrappedComponentProps
> = ({ payoutStore, intl }) => {
  const {
    showPayoutConfirmation,
    selectedPaymentMethod,
    activePayoutSetting,
    submitPaymentDetails,
    submittingPaymentDetails,
    confirmPaymentDetails,
    submittingConfirmPaymentDetails,
    shouldDisableWireTransferFormFields,
    getPaymentMethodTitle,
  } = payoutStore!;

  const heading = useMemo(() => {
    if (selectedPaymentMethod) {
      return getPaymentMethodTitle(selectedPaymentMethod);
    }

    return "";
  }, [selectedPaymentMethod]);

  const onClickSubmit = useCallback(() => {
    if (selectedPaymentMethod) {
      if (showPayoutConfirmation) {
        confirmPaymentDetails(selectedPaymentMethod);
      } else {
        submitPaymentDetails(selectedPaymentMethod);
      }
    }
  }, [showPayoutConfirmation, selectedPaymentMethod]);

  const pillLabel = useMemo(() => {
    if (activePayoutSetting?.is_saved) {
      if (!activePayoutSetting.is_confirmed) {
        return "Not Confirmed";
      } else if (activePayoutSetting.is_selected) {
        return "Selected";
      }
    }

    return undefined;
  }, [activePayoutSetting]);

  const pillColor = useMemo(() => {
    if (activePayoutSetting) {
      if (!activePayoutSetting.is_confirmed) {
        return "bg-red-500";
      } else if (activePayoutSetting.is_selected) {
        return "bg-green-500";
      }
    }

    return undefined;
  }, [activePayoutSetting]);

  if (!selectedPaymentMethod) {
    return null;
  }

  return (
    <TailwindFlex
      flexDirection={"flex-col"}
      width={"w-full"}
      overflow={["overflow-hidden"]}>
      <TailwindCard
        backgroundColor={"primary"}
        padding={["p-4"]}
        width={"w-full"}
        overflow={["overflow-y-auto"]}>
        <TailwindFlex margin={["mb-4"]} justifyContent={"justify-between"}>
          <TailwindHeading level={6}>{heading}</TailwindHeading>
          {pillLabel && (
            <TailwindPill backgroundColor={pillColor}>{pillLabel}</TailwindPill>
          )}
        </TailwindFlex>
        {showPayoutConfirmation ? (
          <TailwindFlex margin={["mb-4"]}>
            <PayoutSettingsConfirmation />
          </TailwindFlex>
        ) : (
          <React.Fragment>
            {selectedPaymentMethod ===
              CAMS_MODEL_PAYOUT_METHOD_KEY.wired_transfer && (
              <CamsPayoutWireTransferForm />
            )}
            {selectedPaymentMethod ===
              CAMS_MODEL_PAYOUT_METHOD_KEY.direct_deposit && (
              <CamsPayoutDirectDepositForm />
            )}
            {selectedPaymentMethod === CAMS_MODEL_PAYOUT_METHOD_KEY.check && (
              <CamsPayoutCheckForm />
            )}
            {selectedPaymentMethod === CAMS_MODEL_PAYOUT_METHOD_KEY.paxum && (
              <CamsPayoutPaxumForm />
            )}
          </React.Fragment>
        )}
        <TailwindFlex>
          <TailwindButton
            backgroundColor={"secondary"}
            justifyContent={"justify-center"}
            textProps={{ textColor: "text-white" }}
            rounded={false}
            disabled={shouldDisableWireTransferFormFields}
            onClick={onClickSubmit}
            showSpinner={
              submittingPaymentDetails || submittingConfirmPaymentDetails
            }>
            {intl.formatMessage({
              id: "common.submit",
              defaultMessage: "Submit",
            })}
          </TailwindButton>
        </TailwindFlex>
      </TailwindCard>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject("payoutStore")(observer(PayoutSettingsFormWrapper))
);
