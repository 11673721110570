import React, { useCallback, useMemo } from "react";
import {
  GalleryImage,
  MessageCenterMessageAttachmentDetails,
  MessageCenterMessageDetails,
} from "common/messages/stores/messages/types";
import TailwindFlex from "library/components/_tailwind/flex";
import { modalStore } from "library/core/stores/modal";
import ImgContainer from "./message-image-container";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindPill from "library/components/_tailwind/pill";
import { TFontSize } from "library/styles/tailwind-classnames";
import TailwindBox from "library/components/_tailwind/box";
import ProgressBarCircular from "library/components/_tailwind/progress-bar-circular";
import { formatter } from "library/core/utility";
import MediaCarouselModal from "./message-carousel-modal";
import MessageCenterImage from "./message-reader-image";
import { SharedMediaTypeEnum } from "./stores/messages/enum";

type Props = {
  message: MessageCenterMessageDetails;
  backgroundColor: string;
  imageOptions?: {};
  onImageLoad?: (event) => void;
  isMCPhase2Active?: boolean;
  messageFontSize?: TFontSize;
  onCancelProgressClicked?: () => void;
  showSingleImg?: boolean;
  onMediaClick?: () => void;
  isSenderCurrentUser?: boolean;
  disableContextMenu?: boolean;
  showGalleryVoiceImg?: boolean;
};

const MessageCenterMessageThumbnails: React.ComponentType<Props> = ({
  message,
  backgroundColor,
  imageOptions,
  onImageLoad,
  isMCPhase2Active = false,
  messageFontSize,
  onCancelProgressClicked,
  showSingleImg,
  onMediaClick,
  isSenderCurrentUser,
  disableContextMenu,
  showGalleryVoiceImg,
}) => {
  const messageStatus = useMemo(() => message.status, [message]);

  const images = useMemo(
    () =>
      message?.attachments_data?.length
        ? message?.attachments_data?.map(attachment =>
            attachment?.attachment_type == SharedMediaTypeEnum.Video &&
            attachment?.thumbnail_url
              ? attachment?.thumbnail_url
              : attachment?.signed_url
          )
        : message?.signed_urls?.length
        ? message?.signed_urls
        : [],
    [message]
  );

  const attachmentDetails: Array<MessageCenterMessageAttachmentDetails> =
    useMemo(() => {
      let attachments: Array<MessageCenterMessageAttachmentDetails> = [];

      if (message?.attachments_data?.length) {
        attachments = message?.attachments_data;
      } else if (message?.signed_urls?.length) {
        // old method of handling attachments, can be removed when completely switched over to attachments_data
        attachments = message?.signed_urls.map(img => {
          return {
            signed_url: img,
            price: 0,
            attachment_type: SharedMediaTypeEnum.Image,
            is_purchased: true,
          } as MessageCenterMessageAttachmentDetails;
        });
      }
      return attachments;
    }, [message]);

  const formatFileSize = (bytes, decimals = 1) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const inProgressBar = (attachment: MessageCenterMessageAttachmentDetails) => {
    const loadingPercentage =
      attachment?.processing_data?.loaded && attachment?.processing_data?.total
        ? Math.floor(
            100 *
              (attachment?.processing_data?.loaded /
                attachment?.processing_data?.total)
          )
        : 0;

    const fontsize =
      messageFontSize === "text-sm" ||
      messageFontSize === "text-xs" ||
      messageFontSize === "text-base"
        ? "text-xs"
        : messageFontSize === "text-lg" || messageFontSize === "text-xl"
        ? "text-sm"
        : messageFontSize
        ? "text-base"
        : "text-sm";

    return loadingPercentage ? (
      <TailwindFlex
        margin={["mt-1.5", "ml-1.5"]}
        justifyContent='justify-start'
        alignItems='items-center'
        fontSize={fontsize}>
        <TailwindFlex
          width={"w-auto"}
          justifyContent='justify-center'
          alignItems='items-center'
          flexDirection='flex-row'
          padding={["p-1"]}
          gap={"gap-1"}
          borderRadius={"rounded"}
          textColor='text-white'
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}>
          <TailwindFlex
            position='relative'
            justifyContent='justify-center'
            alignItems='items-center'
            width='w-auto'>
            <TailwindBox
              position='relative'
              onClick={onCancelProgressClicked}
              style={{
                width: "22px",
                height: "22px",
              }}>
              <ProgressBarCircular progress={loadingPercentage}>
                <TailwindBox
                  backgroundColor={"bg-white"}
                  position='absolute'
                  borderRadius={"rounded-sm"}
                  inset={["left-1/2", "top-1/2"]}
                  transform={["-translate-x-1/2", "-translate-y-1/2"]}
                  style={{
                    width: "7px",
                    height: "7px",
                  }}></TailwindBox>
              </ProgressBarCircular>
            </TailwindBox>
          </TailwindFlex>
          <TailwindFlex
            flexDirection='flex-col'
            justifyContent='justify-center'
            alignItems='items-center'>
            <TailwindFlex
              justifyContent='justify-start'
              alignItems='items-center'>
              {attachment?.length
                ? formatter.formatSeconds(attachment.length, "MM:SS")
                : null}
            </TailwindFlex>
            <TailwindFlex
              justifyContent='justify-start'
              alignItems='items-center'>
              {attachment?.processing_data
                ? `${formatFileSize(
                    attachment?.processing_data.loaded
                  )}/${formatFileSize(attachment?.processing_data.total)}`
                : null}
            </TailwindFlex>
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
    ) : null;
  };

  const soldIndicator = useMemo(() => {
    const fontsize =
      messageFontSize === "text-sm" ||
      messageFontSize === "text-xs" ||
      messageFontSize === "text-base"
        ? "text-xs"
        : messageFontSize === "text-lg" || messageFontSize === "text-xl"
        ? "text-sm"
        : messageFontSize
        ? "text-base"
        : "text-sm";

    return (
      <TailwindFlex
        margin={["mt-1.5", "mr-1.5"]}
        justifyContent='justify-end'
        alignItems='items-center'>
        <TailwindPill
          backgroundColor={"bg-lightGreen-bg-color"}
          size={"xxs"}
          padding={["px-1.5", "py-0.5"]}
          textColor={"text-white"}
          textProps={{
            fontWeight: "font-bold",
            fontSize: fontsize,
          }}>
          <TailwindTranslatedText
            fontSize={fontsize}
            descriptor={{
              id: "message-center.attachment-sold",
              defaultMessage: "Sold",
            }}
          />
        </TailwindPill>
      </TailwindFlex>
    );
  }, [isMCPhase2Active, messageFontSize]);

  const videoHeader = useCallback(
    (attachment: MessageCenterMessageAttachmentDetails) => {
      return (
        <TailwindFlex>
          {attachment?.length ? (
            <TailwindFlex
              justifyContent={"justify-start"}
              alignItems={"items-center"}>
              <TailwindPill
                className={[
                  "MessagesReader__video-duration-pill",
                  `MessagesReader__video-duration-size-${
                    messageFontSize === "text-lg" ||
                    messageFontSize === "text-xl"
                      ? "medium"
                      : messageFontSize === "text-xs"
                      ? "xsmall"
                      : messageFontSize === "text-sm" ||
                        messageFontSize === "text-base"
                      ? "small"
                      : "large"
                  }`,
                ]}
                size={"xxs"}
                textColor={"text-white"}
                leftIconProps={{
                  name: "duration",
                }}>
                {formatter.formatSeconds(attachment?.length, "MM:SS")}
              </TailwindPill>
            </TailwindFlex>
          ) : null}
          {attachment?.is_purchased && attachment?.price ? soldIndicator : null}
        </TailwindFlex>
      );
    },
    [isMCPhase2Active, messageFontSize]
  );

  const tokenBar = useCallback(
    (attachment: MessageCenterMessageAttachmentDetails) => {
      return attachment?.price > 0 ? (
        <TailwindFlex
          textColor={"text-white"}
          backgroundImage={"bg-gradient-to-b"}
          gradientColorStops={[
            "from-transparent",
            "from-transparent",
            "to-black",
          ]}
          padding={["p-1.5"]}
          cursor={"cursor-pointer"}
          onClick={() => onClickPhoto?.(attachment, showSingleImg)}>
          <TailwindFlex
            justifyContent={"justify-start"}
            alignItems={"items-center"}>
            {attachment?.is_purchased ? null : (
              <TailwindIcon
                display={"inline-flex"}
                alignItems={"items-center"}
                name={"lock"}
                fontSize={"text-sm"}
              />
            )}
            <TailwindTranslatedText
              margin={["ml-1.5"]}
              fontSize={messageFontSize || "text-sm"}
              descriptor={{
                id: "message-center.attachment-price-label",
                defaultMessage: "{token_amount} tokens",
              }}
              values={{
                token_amount: attachment.price,
              }}
            />
          </TailwindFlex>
        </TailwindFlex>
      ) : null;
    },
    [isMCPhase2Active, messageFontSize]
  );

  const galleryMap = useMemo(() => {
    const bottomLeft = images.length == 3 ? 2 : images.length - 2;
    return images.map((img: string, idx: number) => {
      let data: GalleryImage;

      switch (idx % 5) {
        case 0:
          data = {
            width: "calc( 60% - 0.0625rem )",
            height: "180px",
            imageURL: img,
            className:
              idx === bottomLeft ? "MessagesReader__GalleryGrid-bl" : "",
          };
          break;
        case 1:
          data = {
            width: "calc( 40% - 0.0625rem )",
            height: "180px",
            imageURL: img,
            className:
              idx === bottomLeft ? "MessagesReader__GalleryGrid-bl" : "",
          };
          break;
        case 2:
          data = {
            width: "100%",
            height: "206px",
            imageURL: img,
            className:
              idx === bottomLeft ? "MessagesReader__GalleryGrid-bl" : "",
          };
          break;
        case 3:
          data = {
            width: "calc( 40% - 0.0625rem )",
            height: "180px",
            imageURL: img,
            className:
              idx === bottomLeft ? "MessagesReader__GalleryGrid-bl" : "",
          };
          break;
        case 4:
          data = {
            width: "calc( 60% - 0.0625rem )",
            height: "180px",
            imageURL: img,
            className:
              idx === bottomLeft ? "MessagesReader__GalleryGrid-bl" : "",
          };
          break;
        default:
          data = {
            width: "100%",
            height: "206px",
            imageURL: img,
            className:
              idx === bottomLeft ? "MessagesReader__GalleryGrid-bl" : "",
          };
      }
      return data;
    });
  }, [images]);

  const singleImage = useMemo(
    () => (
      <ImgContainer
        isSenderCurrentUser={isSenderCurrentUser}
        showGalleryVoiceImg={showGalleryVoiceImg}
        id={images[0]}
        status={messageStatus}
        imgSrc={images?.length > 0 ? images[0] : ""}
        videoSrc={
          attachmentDetails?.[0]?.attachment_type == SharedMediaTypeEnum.Video
            ? attachmentDetails?.[0]?.signed_url
            : ""
        }
        isAudio={attachmentDetails?.[0]?.attachment_type === "voice"}
        duration={attachmentDetails?.[0]?.length}
        openCarousel={showSingleImg}
        onClickPhoto={() => onClickPhoto?.(attachmentDetails[0], showSingleImg)}
        videoProps={{
          style: {
            maxWidth: "420px",
          },
        }}
        boxProps={{
          overflow: "overflow-hidden",
          position: "relative",
          style: {
            borderRadius: "0.5rem",
            backgroundColor,
          },
          className: ["MessagesReader__GalleryGrid--single"],
        }}
        imgBoxProps={{
          position: "relative",
          flex: "flex-auto",
        }}
        imgProps={{
          onLoad: onImageLoad,
          style: {
            height: "auto",
            width: "100%",
            maxWidth: "420px",
            maxHeight: "340px",
            objectFit: "cover",
            cursor: "pointer",
          },
          ...imageOptions,
          onClick: () => onClickPhoto?.(attachmentDetails[0], showSingleImg),
        }}
        topBar={
          isMCPhase2Active && attachmentDetails?.[0]?.processing_data
            ? inProgressBar(attachmentDetails?.[0])
            : isMCPhase2Active &&
              attachmentDetails?.[0]?.attachment_type ===
                SharedMediaTypeEnum.Video
            ? videoHeader(attachmentDetails?.[0])
            : isMCPhase2Active &&
              attachmentDetails?.[0]?.is_purchased &&
              attachmentDetails?.[0]?.price
            ? soldIndicator
            : null
        }
        bottomBar={isMCPhase2Active ? tokenBar(attachmentDetails?.[0]) : null}
      />
    ),
    [images, imageOptions, messageFontSize, attachmentDetails, messageStatus]
  );

  const quadGallery = useMemo(
    () => (
      <TailwindFlex width={"w-80"} height={"h-96"} gap='gap-0.5'>
        <TailwindFlex
          className={["MessagesReader__GalleryGrid__col"]}
          flexDirection='flex-col'
          style={{
            width: "calc(65% - 0.0625rem)",
          }}>
          <ImgContainer
            key={`gallery-thumbnail-${1}`}
            id={images[0]}
            status={messageStatus}
            imgSrc={images?.length > 0 ? images[0] : ""}
            openCarousel={showSingleImg}
            videoSrc={
              attachmentDetails?.[0]?.attachment_type ===
              SharedMediaTypeEnum.Video
                ? attachmentDetails?.[0].signed_url
                : ""
            }
            isAudio={attachmentDetails?.[0]?.attachment_type === "voice"}
            duration={attachmentDetails?.[0]?.length}
            boxProps={{
              className: galleryMap[0]?.className
                ? [galleryMap[0]?.className]
                : [],
              position: "relative",
              width: "w-full",
              height: "h-full",
              style: {
                borderRadius: "0.5rem 0px 0 0.5rem",
              },
            }}
            imgBoxProps={{
              position: "relative",
              width: "w-full",
              height: "h-full",
            }}
            imgProps={{
              onLoad: onImageLoad,
              style: {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                cursor: "pointer",
              },
              ...imageOptions,
              onClick: () =>
                onClickPhoto?.(attachmentDetails[0], showSingleImg),
            }}
            topBar={
              isMCPhase2Active && attachmentDetails?.[0]?.processing_data
                ? inProgressBar(attachmentDetails?.[0])
                : isMCPhase2Active &&
                  attachmentDetails?.[0]?.attachment_type ===
                    SharedMediaTypeEnum.Video
                ? videoHeader(attachmentDetails?.[0])
                : isMCPhase2Active &&
                  attachmentDetails?.[0]?.is_purchased &&
                  attachmentDetails?.[0]?.price
                ? soldIndicator
                : null
            }
            bottomBar={
              isMCPhase2Active ? tokenBar(attachmentDetails?.[0]) : null
            }
          />
        </TailwindFlex>
        <TailwindFlex
          className={["MessagesReader__GalleryGrid__col"]}
          flexDirection='flex-col'
          gap='gap-0.5'
          style={{
            width: "calc(35% - 0.0625rem)",
          }}>
          {images?.map((img, index) =>
            index > 0 ? (
              <ImgContainer
                key={`gallery-thumbnail-${index + 1}`}
                id={images[index]}
                status={messageStatus}
                imgSrc={img}
                videoSrc={
                  attachmentDetails?.[index]?.attachment_type ===
                  SharedMediaTypeEnum.Video
                    ? attachmentDetails?.[index]?.signed_url
                    : ""
                }
                isAudio={
                  attachmentDetails?.[index]?.attachment_type === "voice"
                }
                duration={attachmentDetails?.[index].length}
                boxProps={{
                  className: galleryMap[index]?.className
                    ? [galleryMap[index]?.className]
                    : [],
                  position: "relative",
                  width: "w-full",
                  style: {
                    width: "100%",
                    height: "100%",
                    backgroundColor,
                    borderRadius:
                      index === 1
                        ? "0 0.5rem 0 0"
                        : index === 2
                        ? "0"
                        : "0 0 0.5rem 0",
                  },
                }}
                imgBoxProps={{
                  position: "relative",
                  width: "w-full",
                  height: "h-full",
                }}
                imgProps={{
                  onLoad: onImageLoad,
                  style: {
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    cursor: "pointer",
                  },
                  ...imageOptions,
                  onClick: () =>
                    onClickPhoto?.(attachmentDetails[index], showSingleImg),
                }}
                topBar={
                  isMCPhase2Active &&
                  attachmentDetails?.[index]?.processing_data
                    ? inProgressBar(attachmentDetails?.[index])
                    : isMCPhase2Active &&
                      attachmentDetails?.[index]?.attachment_type ===
                        SharedMediaTypeEnum.Video
                    ? videoHeader(attachmentDetails?.[index])
                    : isMCPhase2Active &&
                      attachmentDetails?.[index]?.is_purchased &&
                      attachmentDetails?.[index]?.price
                    ? soldIndicator
                    : null
                }
                bottomBar={
                  isMCPhase2Active ? tokenBar(attachmentDetails?.[index]) : null
                }
              />
            ) : null
          )}
        </TailwindFlex>
      </TailwindFlex>
    ),
    [images, imageOptions, messageFontSize, attachmentDetails, messageStatus]
  );

  const standardGallery = useMemo(() => {
    return galleryMap?.map((img, index) => (
      <ImgContainer
        key={`gallery-thumbnail-${index + 1}`}
        id={images[index]}
        imgSrc={img.imageURL}
        openCarousel={showSingleImg}
        videoSrc={
          attachmentDetails?.[index]?.attachment_type ===
          SharedMediaTypeEnum.Video
            ? attachmentDetails?.[index]?.signed_url
            : ""
        }
        isAudio={attachmentDetails?.[index]?.attachment_type === "voice"}
        duration={attachmentDetails?.[index].length}
        boxProps={{
          className: img?.className ? [img?.className] : [],
          position: "relative",
          width: "w-full",
          style: {
            width: images.length === 3 && index === 2 ? "100%" : img.width,
            height: img.height,
            backgroundColor,
          },
        }}
        imgBoxProps={{
          width: "w-full",
          height: "h-full",
        }}
        imgProps={{
          onLoad: onImageLoad,
          style: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            cursor: "pointer",
          },
          ...imageOptions,
          onClick: () =>
            onClickPhoto?.(attachmentDetails[index], showSingleImg),
        }}
        topBar={
          isMCPhase2Active && attachmentDetails?.[index]?.processing_data
            ? inProgressBar(attachmentDetails?.[index])
            : isMCPhase2Active &&
              attachmentDetails?.[index]?.attachment_type ===
                SharedMediaTypeEnum.Video
            ? videoHeader(attachmentDetails?.[index])
            : isMCPhase2Active &&
              attachmentDetails?.[index]?.is_purchased &&
              attachmentDetails?.[index]?.price
            ? soldIndicator
            : null
        }
        bottomBar={
          isMCPhase2Active ? tokenBar(attachmentDetails?.[index]) : null
        }
      />
    ));
  }, [images, imageOptions, messageFontSize, message, messageStatus]);

  const onClickPhoto = useCallback(
    (attachment: MessageCenterMessageAttachmentDetails, singleImage) => {
      if (
        attachment?.signed_url &&
        attachment?.attachment_type === SharedMediaTypeEnum.Image
      ) {
        if (singleImage) {
          modalStore.openSecondaryModal(
            <MessageCenterImage imageURL={attachment?.signed_url} />
          );
        } else {
          onMediaClick;
          modalStore.openSecondaryModal(
            <MediaCarouselModal attachment={attachment} message={message} />,
            {
              showNativeCloseButton: false,
            }
          );
        }
      } else if (attachment?.signed_url && !singleImage) {
        modalStore.openSecondaryModal(
          <MediaCarouselModal attachment={attachment} message={message} />,
          {
            showNativeCloseButton: false,
          }
        );
      }
    },
    [isMCPhase2Active]
  );

  const onDisabledContextMenu = e => {
    e.preventDefault();
    return false;
  };

  if (!message?.signed_urls?.length && !message?.attachments_data?.length) {
    return null;
  }

  return (
    <TailwindFlex
      className={
        isSenderCurrentUser
          ? [
              "MessagesReader__GalleryGrid",
              "MessagesReader__GalleryGrid--sender",
            ]
          : ["MessagesReader__GalleryGrid"]
      }
      htmlElementProps={{
        onContextMenu: disableContextMenu ? onDisabledContextMenu : () => {},
      }}>
      <TailwindFlex
        borderRadius={"rounded"}
        placeItems={"place-items-center"}
        flexDirection={"flex-row"}
        flexWrap={"flex-wrap"}
        gap='gap-0.5'
        position='relative'
        width={
          images.length > 1 && images.length !== 4
            ? "w-80"
            : images.length === 1
            ? "w-full"
            : "w-auto"
        }>
        {images.length === 4
          ? quadGallery
          : images.length > 1
          ? standardGallery
          : images.length === 1
          ? singleImage
          : null}
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default MessageCenterMessageThumbnails;
