import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import PayoutStore from "./stores/payout/PayoutStore";
import { inject, observer } from "mobx-react";
import TailwindInput from "library/components/_tailwind/input";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import { CamsPayoutSettingCheck } from "./stores/payout/types";

type Props = {
  payoutStore?: PayoutStore;
  validationStore?: ValidationStore;
};

const CamsPayoutCheckForm: React.ComponentType<Props> = ({ payoutStore, validationStore }) => {
  const { errors, validate } = validationStore!;

  const { activePayoutSetting, updatePaymentDetails } = payoutStore!;

  return (
    <React.Fragment>
      <TailwindFlex margin={["mb-2"]}>
        Paper checks are available for US and Canada only and are free of charge.
      </TailwindFlex>
      <TailwindFlex margin={["mb-2"]}>Minimum payment is ${50}</TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>Delivery can take up to 2 weeks.</TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindInput
          backgroundColor={"secondary"}
          required={true}
          variant={"contained"}
          label={"Name"}
          error={errors["name"] || ""}
          onBlur={_ => validate("name", (activePayoutSetting?.payout_settings as CamsPayoutSettingCheck)?.name)}
          value={(activePayoutSetting?.payout_settings as CamsPayoutSettingCheck)?.name}
          onChange={e => updatePaymentDetails(activePayoutSetting, "name", e.target.value)}
          name={"name"}
        />
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindInput
          backgroundColor={"secondary"}
          required={true}
          variant={"contained"}
          label={"Address"}
          error={errors["address"] || ""}
          onBlur={_ => validate("address", (activePayoutSetting?.payout_settings as CamsPayoutSettingCheck)?.address)}
          value={(activePayoutSetting?.payout_settings as CamsPayoutSettingCheck)?.address}
          onChange={e => updatePaymentDetails(activePayoutSetting, "address", e.target.value)}
          name={"address"}
        />
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindInput
          backgroundColor={"secondary"}
          required={true}
          variant={"contained"}
          label={"City"}
          error={errors["city"] || ""}
          onBlur={_ => validate("city", (activePayoutSetting?.payout_settings as CamsPayoutSettingCheck)?.city)}
          value={(activePayoutSetting?.payout_settings as CamsPayoutSettingCheck)?.city}
          onChange={e => updatePaymentDetails(activePayoutSetting, "city", e.target.value)}
          name={"city"}
        />
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindInput
          backgroundColor={"secondary"}
          required={true}
          variant={"contained"}
          label={"State"}
          error={errors["state"] || ""}
          onBlur={_ => validate("state", (activePayoutSetting?.payout_settings as CamsPayoutSettingCheck)?.state)}
          value={(activePayoutSetting?.payout_settings as CamsPayoutSettingCheck)?.state}
          onChange={e => updatePaymentDetails(activePayoutSetting, "state", e.target.value)}
          name={"state"}
        />
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindInput
          backgroundColor={"secondary"}
          required={true}
          variant={"contained"}
          label={"Zip Code"}
          error={errors["zipcode"] || ""}
          onBlur={_ => validate("zipcode", (activePayoutSetting?.payout_settings as CamsPayoutSettingCheck)?.zipcode)}
          value={(activePayoutSetting?.payout_settings as CamsPayoutSettingCheck)?.zipcode}
          onChange={e => updatePaymentDetails(activePayoutSetting, "zipcode", e.target.value)}
          name={"zipcode"}
        />
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindInput
          backgroundColor={"secondary"}
          required={true}
          variant={"contained"}
          label={"Phone Number"}
          error={errors["phone_number"] || ""}
          onBlur={_ =>
            validate("phone_number", (activePayoutSetting?.payout_settings as CamsPayoutSettingCheck)?.phone_number)
          }
          value={(activePayoutSetting?.payout_settings as CamsPayoutSettingCheck)?.phone_number}
          onChange={e => updatePaymentDetails(activePayoutSetting, "phone_number", e.target.value)}
          name={"phone_number"}
        />
      </TailwindFlex>
    </React.Fragment>
  );
};

export default inject("payoutStore", "validationStore")(observer(CamsPayoutCheckForm));
