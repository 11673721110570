import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";

export type LoadingResourceSpinnerProps = {
  name?: string;
  loadingText?: string;
  showLoadingText?: boolean;
  showEllipsis?: boolean;
  show?: boolean;
};

const LoadingResourceSpinner: React.ComponentType<LoadingResourceSpinnerProps> =
  ({
    name = "",
    showLoadingText = true,
    loadingText = "Loading",
    showEllipsis = true,
    show = true,
  }) => {
    if (show) {
      return (
        <TailwindFlex
          height={"h-full"}
          alignItems={"items-center"}
          justifyContent={"justify-center"}
          flexDirection={"flex-col"}>
          <TailwindIcon name={CustomIconName.spinner} />
          {showLoadingText && (
            <React.Fragment>
              <br />
              <TailwindText
                textColor='text-main-color'
                margin={["mt-4"]}>{`${loadingText} ${name}${
                showEllipsis && "..."
              }`}</TailwindText>
            </React.Fragment>
          )}
        </TailwindFlex>
      );
    }
    return null;
  };

export default LoadingResourceSpinner;
