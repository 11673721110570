import React, { FunctionComponent, useCallback } from "react";
import "../styles.scss";
import CamsLandingHeader from "containers/_layout/landing/cams/header";
import { inject, observer } from "mobx-react";
import TailwindFlex from "library/components/_tailwind/flex";
import Modal from "library/components/modal";
import ModalStore from "library/core/stores/modal";
import AuthStore from "core/stores/auth/AuthStore";

type CamsLandingLayoutProps = {
  appendSuffix?: boolean;
  modalStore?: ModalStore;
  showHeader?: boolean;
  authStore?: AuthStore;
};

const CamsLandingLayout: FunctionComponent<CamsLandingLayoutProps> = ({
  children,
  showHeader = true,
  modalStore,
  authStore,
}) => {
  const { closeAuthModal } = modalStore!;
  const { isLoggedIn, isLoading } = authStore!;
  const onCloseAuthModalClicked = useCallback(() => {
    closeAuthModal();
  }, []);
  return (
    <TailwindFlex
      className={["LandingLayout"]}
      flexGrow={"flex-grow"}
      minHeight={"min-h-full"}
      overflow={["overflow-hidden"]}
      flexDirection={"flex-col"}>
      {showHeader && !isLoggedIn && !isLoading && <CamsLandingHeader />}
      <TailwindFlex
        className={["LandingLayout__body"]}
        display={"flex"}
        flexDirection={"flex-col"}
        minHeight={"min-h-full"}
        flexGrow={"flex-grow"}
        alignItems={"items-center"}
        overflow={["overflow-y-auto"]}
        backgroundColor={"bg-primary-bg-color"}>
        {children}
      </TailwindFlex>
      <Modal
        className={"LandingLayout__AuthModal"}
        open={modalStore!.modals["auth"].active}
        modalTop={"78px"}
        closeModal={onCloseAuthModalClicked}
        {...(modalStore!.modals["auth"].modalProps || {})}>
        {modalStore!.modals["auth"].content}
      </Modal>
    </TailwindFlex>
  );
};
export default inject("modalStore", "authStore")(observer(CamsLandingLayout));
