import React, { FunctionComponent, useMemo } from "react";
import BroadcastStore from "common/broadcast/_stores/broadcast/BroadcastStore";
import { inject, observer } from "mobx-react";
import TailwindText from "library/components/_tailwind/text";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import classnames from "classnames";

type Props = {
  broadcastStore?: BroadcastStore;
  layoutStore?: LayoutStore;
};

const BroadcastShowTypeIndicator: FunctionComponent<Props> = ({
  broadcastStore,
  layoutStore
}) => {
  const { currentShowType, getShowTypePrettyName } = broadcastStore!;
  const { isLiteMode } = layoutStore!;

  const nameAndIcon = useMemo(() => {
    return getShowTypePrettyName(currentShowType);
  }, [currentShowType]);

  if (currentShowType && nameAndIcon) {
    return (
      <div 
        className={classnames({
        'BroadcastShowTypeIndicator': true,
        'BroadcastShowTypeIndicator--litemode': isLiteMode,
      })}
        data-testid={"broadcast-show-type-indicator"}
        data-show-type={currentShowType?.toLowerCase()}
      >
        <span
          className={`BroadcastShowTypeIndicator__circle BroadcastShowTypeIndicator__circle--${nameAndIcon.iconColor}`}></span>
        <TailwindText textColor='text-main-color' fontSize='text-sm'>
          {nameAndIcon.badgeText}
        </TailwindText>
      </div>
    );
  }

  return null;
};

export default inject("broadcastStore", "layoutStore")(observer(BroadcastShowTypeIndicator));
