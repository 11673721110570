import React from "react";
import CookieStorage from "library/core/utility/cookie-storage";
import {
  CAMS_MODELS_STORAGE_KEYS,
  CAMS_STUDIOS_STORAGE_KEYS,
} from "library/core/config/storage";
import AuthStore from "core/stores/auth/AuthStore";
import { inject, observer } from "mobx-react";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import config from "core/config";

type Props = {
  classNames?: string;
  authStore?: AuthStore;
};

const HistoricalEarnings: React.ComponentType<Props> = ({
  classNames,
  authStore,
}) => {
  const { userRole } = authStore!;

  const legacyLoginUrl = () => {
    const token =
      userRole === "studio"
        ? CookieStorage.get(CAMS_STUDIOS_STORAGE_KEYS.ACCESS_TOKEN)
        : CookieStorage.get(CAMS_MODELS_STORAGE_KEYS.ACCESS_TOKEN);

    if (userRole === "studio") {
      return `${config.legacyStudiosUrl}/p/login.cgi?send_to_earnings=1&from_cams2=1&studio=1&studioToken=${token}`;
    } else {
      return `${config.legacyModelsUrl}/p/login.cgi?send_to_earnings=1&from_cams2=1&stream=1&modelToken=${token}`;
    }
  };

  const onClickViewHistoricalEarnings = () => {
    window.open(
      legacyLoginUrl(),
      "historical-earnings",
      "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=1050,height=850"
    );
  };

  return (
    <TailwindTranslatedText
      textColor='text-main-color'
      className={classNames}
      onClick={onClickViewHistoricalEarnings}
      fontSize={"text-sm"}
      textDecoration={"underline"}
      cursor={"cursor-pointer"}
      descriptor={{
        id: "modelEarnings.viewHistorical",
        defaultMessage: "Historical Earnings Report",
      }}
    />
  );
};

export default inject("authStore")(observer(HistoricalEarnings));
