import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";

type Props = {};

const StorybookConventionsOOPVsFunctional: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>
        Conventions &gt; OOP vs Functional Programming
      </StorybookHeading>
      <StorybookParagraph>
        Although object oriented programming is nice, we only use classes for
        Stores (because of MobX) and for utilities. The main idea for the use of
        classes for utilities are for organization purposes where people know
        that there is a formatter class that is responsible for formatting
        whereas having functional style utilities might get off-track and not so
        consistent.
      </StorybookParagraph>
      <StorybookParagraph>
        The good side of OOP, unfortunately does not scale well with MobX. The
        following method with OOP was tried but was unsuccessful:
      </StorybookParagraph>
      <Code>
        {`class ModelImage
class ModelVideo {
  album: ModelAlbum
}
class ModelAlbum {
  album: ModelAlbum
}

class MediaManagerStore (MobX store) {
  albums = [
    new ModelAlbum({
      images: [
        new ModelImage(//album instance),
        new ModelImage(//album instance),
        new ModelImage(//album instance),
      ],
      videos = [
        new ModelVideo(//album instance),
        new ModelVideo(//album instance),
        new ModelVideo(//album instance),
      ]
    }),
    new ModelAlbum({
      images: [
        new ModelImage(//album instance),
        new ModelImage(//album instance),
        new ModelImage(//album instance),
      ],
      videos = [
        new ModelVideo(//album instance),
        new ModelVideo(//album instance),
        new ModelVideo(//album instance),
      ]
    }),
    new ModelAlbum({
      images: [
        new ModelImage(//album instance),
        new ModelImage(//album instance),
        new ModelImage(//album instance),
      ],
      videos = [
        new ModelVideo(//album instance),
        new ModelVideo(//album instance),
        new ModelVideo(//album instance),
      ]
    }),
  ]
}      
`}
      </Code>
      <StorybookParagraph>
        With the above example, even though there are decorators from MobX like
        makeObservable or makeAutoObservable in the Media classes, if you try to
        update the MediaManager albums or try to update ModelAlbum from
        ModelImage or ModelVideo, it bugs out. This is mainly because the
        classes are not meant to be used when they are not a store. The MobX
        classes should be singletons and injected into the app via Provider and
        into each component they are used, to cause rerenders on React
        components.
      </StorybookParagraph>
    </StorybookWrapper>
  );
};

export default StorybookConventionsOOPVsFunctional;
