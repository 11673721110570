import React from "react";
import "./styles.scss";
import classnames from "classnames";

type PersonHoldingHandsUpIconProps = {
  className?: string;
};

const PersonHoldingHandsUpIcon: React.ComponentType<PersonHoldingHandsUpIconProps> = ({
  className,
}) => (
  <span
    className={classnames("PersonHoldingHandsUpIcon", className && className)}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          id="child"
          d="M17.71,7.7C17.9,7.51,18,7.26,18,6.99c0-0.27-0.1-0.52-0.29-0.71c-0.39-0.39-1.02-0.39-1.41-0.01  c0,0,0,0-0.01,0.01l-2.37,2.38h-3.84L7.71,6.28C7.52,6.09,7.27,5.99,7,5.99c-0.27,0-0.52,0.1-0.71,0.29C5.9,6.67,5.9,7.3,6.29,7.69  c0,0,0,0,0.01,0.01l3,3v8.58c0.06,0.64,0.63,1.12,1.27,1.06c0.56-0.05,1-0.5,1.06-1.06v-4h0.67v4c0.06,0.64,0.63,1.12,1.27,1.06  c0.56-0.05,1-0.5,1.06-1.06v-8.54l3-3l0,0L17.71,7.7z M13.65,4.34c-0.91-0.91-2.39-0.91-3.3,0C9.91,4.77,9.66,5.37,9.67,5.99  c0,1.29,1.04,2.33,2.33,2.33h0c1.29,0,2.33-1.04,2.33-2.33C14.34,5.37,14.1,4.77,13.65,4.34z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  </span>
);

export default PersonHoldingHandsUpIcon;
