import React, { useCallback, useState } from "react";
import "./styles.scss";
import { inject, observer } from "mobx-react";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import { Popover } from "../../popover";
import TailwindBox from "library/components/_tailwind/box";
import TailwindText from "library/components/_tailwind/text";
import NodeChatStore from "../_stores/nodechat/NodeChatStore";

export const TRANSLATIONS = [
  {
    label: "English",
    language: "en",
  },
  {
    label: "Deutsch",
    language: "de",
  },
  {
    label: "Español",
    language: "es",
  },
  {
    label: "Italiano",
    language: "it",
  },
  {
    label: "Magyar",
    language: "hu",
  },
  {
    label: "Portugués",
    language: "pt",
  },
  {
    label: "Romana",
    language: "ro",
  },
  {
    label: "Pусский",
    language: "ru",
  },
  {
    label: "Français",
    language: "fr",
  },
];

interface ITranslationPopoverProps {
  nodeChatStore?: NodeChatStore;
  children: any;
}

const BroadcastChatTranslationPopover = ({
  nodeChatStore,
  children,
}: ITranslationPopoverProps) => {
  const [show, setShow] = useState(false);

  const onHide = useCallback(() => {
    setShow(false);
  }, []);

  const onShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleClickNone = () => {
    nodeChatStore?.setLanguage("en");
    onHide();
  };

  const handleClickLanguage = language => () => {
    nodeChatStore?.setLanguage(language);
    onHide();
  };

  return (
    <Popover
      show={show}
      onHide={onHide}
      onShow={onShow}
      placement={"top-start"}
      content={
        <TailwindBox
          className={["TranslationPopover"]}
          borderWidth={["border-2"]}
          borderColor={"border-blue-500"}>
          {false && (
            <div
              onClick={handleClickNone}
              className='TranslationPopover__TranslationItem'>
              <TailwindIcon name={CustomIconName["cancel-circle"]} />
              <span>None</span>
            </div>
          )}
          {TRANSLATIONS.map(translation => (
            <TailwindBox
              onClick={handleClickLanguage(translation.language)}
              key={translation.language}
              className={[`TranslationPopover__TranslationItem`]}
              borderWidth={
                nodeChatStore?.currentChatLanguage === translation.language
                  ? ["border"]
                  : []
              }
              borderColor={
                nodeChatStore?.currentChatLanguage === translation.language
                  ? "border-main-color"
                  : undefined
              }>
              <TailwindBox
                as={"span"}
                height={"h-6"}
                width={"w-6"}
                className={[
                  "fi",
                  `fi-${
                    translation.language === "en" ? "us" : translation.language
                  }`,
                ]}
                borderRadius={"rounded-full"}
                backgroundSize={"bg-cover"}
                backgroundPosition={"bg-center"}
                backgroundRepeat={"bg-no-repeat"}
              />
              <TailwindText textColor={"text-main-color"}>
                {translation.label}
              </TailwindText>
            </TailwindBox>
          ))}
        </TailwindBox>
      }>
      {children}
    </Popover>
  );
};

export default inject("nodeChatStore")(observer(BroadcastChatTranslationPopover));
