import React from "react";
import TailwindText, { TailwindTextProps } from "../text";

export type TailwindParagraphProps = TailwindTextProps & {};

const TailwindParagraph: React.ComponentType<TailwindParagraphProps> = ({
  children,
  ...props
}) => {
  return (
    <TailwindText {...props} as={"p"}>
      {children}
    </TailwindText>
  );
};

export default TailwindParagraph;
