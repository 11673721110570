import TailwindFlex from "library/components/_tailwind/flex";
import TailwindCard from "library/components/_tailwind/card";
import React, { useCallback, useEffect, useMemo } from "react";
import PricingStore from "common/broadcast/_stores/pricing/PricingStore";
import { inject, observer } from "mobx-react";
import { MESSAGE_ATTACHMENT_TOKEN_PRICES } from "./stores/messages/consts";
import TailwindHeading from "library/components/_tailwind/heading";
import TailwindDivider from "library/components/_tailwind/divider";
import LanguageStore from "library/core/stores/language/LanguageStore";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindDropdown, {
  TailwindDropdownValue,
} from "library/components/_tailwind/dropdown";
import { imageType } from "./messages-reader-footer";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindPill from "library/components/_tailwind/pill";
import { formatter } from "library/core/utility";

type Props = {
  image: imageType;
  onSetPrice: (price: number) => void;
  isVideo?: boolean;
  pricingStore?: PricingStore;
  languageStore?: LanguageStore;
};

const MessagesAttachmentPricingModal: React.ComponentType<Props> = ({
  image,
  onSetPrice,
  isVideo = false,
  pricingStore,
  languageStore,
}) => {
  const { intl } = languageStore!;
  const {
    modelProducts,
    isLoading: loadingProducts,
    getPriceData,
  } = pricingStore!;

  const onChangePrice = useCallback((value: TailwindDropdownValue) => {
    if (value) {
      onSetPrice(parseInt(value?.toString()));
    }
  }, []);

  const attachmentPrices = useMemo(
    () =>
      MESSAGE_ATTACHMENT_TOKEN_PRICES.map(value => ({
        label: intl.formatMessage(
          {
            id: "message-center.attachment-price-dropdown-label",
            defaultMessage: "{token_amount} tokens / per media attachment",
          },
          {
            token_amount: value,
          }
        ),
        value,
      })),
    [MESSAGE_ATTACHMENT_TOKEN_PRICES]
  );

  useEffect(() => {
    if (!loadingProducts) {
      getPriceData();
    }
  }, []);

  const defaultAttachmentPrice = useMemo(
    () =>
      image?.price ||
      modelProducts?.conversation_message_attachment_price ||
      10,
    [modelProducts, image]
  );

  return (
    <TailwindCard
      className={
        image?.is_audio
          ? [
              "MessageAttachmentPricingModal",
              "MessageAttachmentPricingModal__compact",
            ]
          : ["MessageAttachmentPricingModal"]
      }
      backgroundColor={"bg-primary-bg-color"}
      borderRadius={"rounded"}
      width={"w-full"}
      padding={["p-8", "pt-8"]}
      display={"flex"}
      flexDirection={"flex-col"}
      fontSize={"text-base"}>
      <TailwindHeading level={5} margin={["mb-4"]}>
        {intl.formatMessage({
          id: "message-center.attachment-prices-modal-heading",
          defaultMessage: "Media Attachment Price",
        })}
      </TailwindHeading>
      <TailwindDivider
        color='bg-gray-300'
        margin={["my-3"]}
        opacity='opacity-50'
        style={{ height: "2px" }}
      />
      <TailwindFlex>
        <TailwindTranslatedText
          descriptor={{
            id: "message-center.attachment-prices-modal-sub-title",
            defaultMessage:
              "Set the token price members pay to unlock access to view a media attachment you send them.",
          }}
        />
      </TailwindFlex>
      <TailwindFlex margin={["mt-4"]}>
        <TailwindFlex width={"w-full"}>
          <TailwindDropdown
            fullWidth={true}
            className={["MessageAttachmentPricingModal-dropdown"]}
            backgroundColor={"bg-primary-bg-color"}
            borderColor={"border-gray-200"}
            value={defaultAttachmentPrice || attachmentPrices[0].value}
            onChange={onChangePrice}
            name={"attachment-price"}
            items={attachmentPrices}
          />
        </TailwindFlex>
      </TailwindFlex>

      {image?.is_audio ? null : (
        <TailwindFlex
          margin={["mt-5"]}
          width='w-full'
          height='h-full'
          flexDirection='flex-col'
          flexGrow='flex-grow'
          position='relative'>
          <TailwindFlex position='absolute' inset={["top-0", "left-0"]}>
            {image?.duration ? (
              <TailwindFlex
                justifyContent={"justify-start"}
                alignItems={"items-center"}>
                <TailwindPill
                  className={["MessagesReader__video-duration-pill"]}
                  size={"xxs"}
                  textColor={"text-white"}
                  leftIconProps={{
                    name: "duration",
                  }}>
                  {formatter.formatSeconds(image?.duration, "MM:SS")}
                </TailwindPill>
              </TailwindFlex>
            ) : null}
          </TailwindFlex>

        <TailwindFlex
          className={["MessageAttachmentPricingModal__image"]}
          width='w-full'
          height='h-full'
          flexGrow='flex-grow'
          style={{
            backgroundImage: image?.preview_url
              ? `url(${image?.preview_url})`
              : "unset",
          }}
        />
          {isVideo ? (
          <TailwindFlex
            position='absolute'
            className={["img-container__play-icon"]}>
            <TailwindIcon name='play' />
          </TailwindFlex>
        ) : null}
        </TailwindFlex>
      )}
    </TailwindCard>
  );
};

export default inject(
  "pricingStore",
  "languageStore"
)(observer(MessagesAttachmentPricingModal));
