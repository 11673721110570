import React from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { inject, observer } from "mobx-react";
import AuthStore from "../auth/AuthStore";
import LandingLayout from "containers/_layout/landing";
import TailwindSpinner from "library/components/_tailwind/spinner";
import RouteStore from "core/stores/route/RouteStore";
import DashboardLayout from "containers/_layout/dashboard";

type Props = RouteProps & {
  authStore?: AuthStore;
  routeStore?: RouteStore;
  redirectToMainPageWhenLoggedIn?: boolean;
  accessibleWhileLoggedIn?: boolean;
};

const PublicRoute: React.ComponentType<Props> = ({
  authStore,
  routeStore,
  redirectToMainPageWhenLoggedIn,
  accessibleWhileLoggedIn,
  children,
  ...rest
}) => {
  const { isLoggedIn, isReady } = authStore!;
  const { getMainRouteForSite } = routeStore!;
  const location = useLocation();

  const mainPageURL = getMainRouteForSite();

  const comingFromURL = (location?.state as any)?.from;
  const redirectURL = comingFromURL
    ? comingFromURL
    : redirectToMainPageWhenLoggedIn
    ? rest.path
      ? mainPageURL
      : "/"
    : "/";

  if (isReady) {
    if (rest.path) {
      if (isLoggedIn) {
        if (accessibleWhileLoggedIn) {
          return (
            <Route {...rest}>
              <DashboardLayout>{children}</DashboardLayout>
            </Route>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: redirectURL,
              }}
            />
          );
        }
      } else {
        return (
          <Route {...rest}>
            <LandingLayout>{children}</LandingLayout>
          </Route>
        );
      }
    } else {
      return (
        <Redirect
          to={{
            pathname: redirectURL,
          }}
        />
      );
    }
  }

  return (
    <LandingLayout>
      <TailwindSpinner />
    </LandingLayout>
  );
};

export default inject("authStore", "routeStore")(observer(PublicRoute));
