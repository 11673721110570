import React from "react";
import "./styles.scss";
import classnames from "classnames";

type AddFriendIconProps = {
  className?: string;
};

const AddFriendIcon: React.ComponentType<AddFriendIconProps> = ({
  className,
}) => (
  <span className={classnames("AddFriendIcon", className && className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 26 26"
    >
      <g transform="translate(1, 1)">
        <g>
          <path
            d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
            vectorEffect="non-scaling-stroke"
          />
        </g>
      </g>
    </svg>
  </span>
);

export default AddFriendIcon;
