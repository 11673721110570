import React from "react";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import MyFansMemberTableRowMobile from "containers/model/my-fans/member-table-row-mobile";
import MyFansMemberTableRowDesktop from "containers/model/my-fans/member-table-row-desktop";
import { MyFansPageMemberStats } from "containers/model/my-fans/stores/my-fans/types";
import { inject, observer } from "mobx-react";

type Props = {
  layoutStore?: LayoutStore;
  fan: MyFansPageMemberStats;
  showDesktopDivider?: boolean;
};

const MyFansMemberTableRow: React.ComponentType<Props> = ({
  layoutStore,
  fan,
  showDesktopDivider = false,
}) => {
  const { isMobileScreenSize } = layoutStore!;

  if (isMobileScreenSize) {
    return <MyFansMemberTableRowMobile fan={fan} />;
  }

  return (
    <MyFansMemberTableRowDesktop
      showDesktopDivider={showDesktopDivider}
      fan={fan}
    />
  );
};

export default inject("layoutStore")(observer(MyFansMemberTableRow));
