import {
  authStore,
  logToGraylog,
  messageStore,
  profileStore,
} from "core/stores";
import { logger } from "library/core/utility";
import { makeAutoObservable } from "mobx";
import NotificationSocket from "./NotificationSocket";
import { InitialData } from "./consts";
import { INotificationSocketMethods } from "./interfaces";

const logPrefix = "[NotificationStore]:";

export default class NotificationStore {
  notificationSocket: NotificationSocket | undefined =
    InitialData.notificaitonSocket;
  constructor() {
    makeAutoObservable(this);
  }

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  init = async () => {
    this.log("init started");
    try {
      const accessToken = authStore.getAccessToken();
      if (!!accessToken && accessToken !== "") {
        this.notificationSocket = new NotificationSocket();
        this.log("found token, connecting to Notification Socket");
        this.notificationSocket.connect(
          accessToken,
          this.handleAuthFailed,
          this.handleConnected,
          this.handleDisconnected,
          this.onMiddlewareTriggered,
          this.notificationSocketMethods
        );
      } else {
        this.log(
          "init did not find access token, getting a new access token and retrying"
        );
        //await this.handleAuthFailed();
        await this.init();
      }
    } catch (e) {}
  };

  get notificationSocketMethods(): INotificationSocketMethods {
    return {
      new_message_center_message: this.handleNewMessageCenterMessage,
      rejected_message_center_message: this.handleRejectedMessageCenterMessage,
      model_access_rights_modified_by_studio_message:
        this.handleMessageCenterAccessChange,
      model_attachment_purchased_by_member_message:
        this.handlePurchasedMessageCenterMessage,
    };
  }

  handleAuthFailed = async () => {
    const authResponse =
      await authStore.getAuthResponseFromBackendViaRefreshToken();
    return authResponse.access;
  };

  handleConnected = async () => {
    this.log("conencted to notification socket");
  };

  handleDisconnected = async () => {
    this.log("disconnected from notification socket");
  };

  handleSocketError = async () => {
    this.log("error from notification socket");
  };

  onMiddlewareTriggered = (eventName: string, args: any) => {
    const eventNames: string[] = [];
    const logExceptionEvents: string[] = ["ping", "pong"];
    if (eventName) {
      eventNames.push(eventName);
      if (!logExceptionEvents.includes(eventName)) {
        logToGraylog(
          logPrefix,
          `middleware received event with event name: ${eventName} and event data:`,
          args
        );
      }
    }
  };

  handleNewMessageCenterMessage = data => {
    this.log("handleNewMessageCenterMessage", data);
    messageStore.handleNewMessageReceived(data);
  };

  handleRejectedMessageCenterMessage = data => {
    this.log("handleRejectedMessageCenterMessage", data);
    messageStore.handleMessageRejection(data);
  };

  handleMessageCenterAccessChange = data => {
    this.log("handleMessageCenterAccessChange", data);
    const { modelProfile, setAccessRights } = profileStore!;

    setAccessRights({
      is_pricing_settings_change_allowed:
        typeof data?.is_pricing_settings_change_allowed === "boolean"
          ? data?.is_pricing_settings_change_allowed
          : modelProfile.access_rights?.is_pricing_settings_change_allowed,
    });
  };

  handlePurchasedMessageCenterMessage = data => {
    this.log("handlePurchasedMessageCenterMessage", data);
    messageStore.handleMessagePurchased(data);
  };

  resetStore = () => {
    this.log("resetStore started");
    this.notificationSocket?.closeSocket();
    Object.assign(this, InitialData);
    this.log("resetStore finished");
  };
}
