import { StudioModelsPermissions } from "../../../../../common/my-page/stores/profile/enums";
import { makeAutoObservable } from "mobx";
import { authStore, profileStore } from "core/stores";

export default class StudioModelsAccessRightsStore {
  public isPricingSettingChangeAllowed: boolean = false;
  public isEarningsAndTransactionsVisible: boolean = false;
  public isQuickTextChangesAllowed: boolean = false;
  public isTransactionDetailVisible: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  public initRights = () => {
    this.isPricingSettingChangeAllowed = this.getShouldAllowStudioModel(
      StudioModelsPermissions.IS_PRICING_SETTING_CHANGE_ALLOWED
    );
    this.isEarningsAndTransactionsVisible = this.getShouldAllowStudioModel(
      StudioModelsPermissions.IS_EARNINGS_AND_TRANSACTIONS_VISIBLE
    );
    this.isQuickTextChangesAllowed = this.getShouldAllowStudioModel(
      StudioModelsPermissions.IS_QUICK_TEXT_CHANGES_ALLOWED
    );
    this.isTransactionDetailVisible = this.getShouldAllowStudioModel(
      StudioModelsPermissions.IS_TRANSACTION_DETAIL_VISIBLE
    );
  };

  get accessRightsObject() {
    return {
      isPricingSettingChangeAllowed: this.isPricingSettingChangeAllowed,
      isEarningsAndTransactionsVisible: this.isEarningsAndTransactionsVisible,
      isQuickTextChangesAllowed: this.isQuickTextChangesAllowed,
      isTransactionDetailVisible: this.isTransactionDetailVisible,
    };
  }

  private getShouldAllowStudioModel = (permission: StudioModelsPermissions) => {
    const { userRole } = authStore;
    const { modelProfile } = profileStore;
    const isStudioModel =
      userRole === "model" && modelProfile.is_model_studio === false;

    if (isStudioModel && !modelProfile?.access_rights?.[permission]) {
      return false;
    } else {
      return true;
    }
  };
}
