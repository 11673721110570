const URL = "https://secureimage.securedataimages.com/images/cams";

class CamsAudio {
  playMemberEnter = () => {
    new Audio(`${URL}/sound/viewerWithCash.mp3`).play();
  };
  playMemberLeave = () => {
    new Audio(`${URL}/sound/viewerLeave.mp3`).play();
  };
  playStartPrivateChat = () => {
    new Audio(`${URL}/sound/startPrivate.mp3`).play();
  };
  playEndPrivateChat = () => {
    new Audio(`${URL}/sound/endPrivate.mp3`).play();
  };
  playGiftReceived = () => {
    new Audio(`${URL}/sound/GiftingSound.mp3`).play();
  };
  playGreatIdea = () => {
    new Audio(`${URL}/sound/GreatIdea.mp3`).play();
  };
  playCoinGameWin = () => {
    new Audio(`${URL}/sound/CoinGameWin.mp3`).play();
  };
  playFXGameWin2 = () => {
    new Audio(`${URL}/sound/FXGameWin2.mp3`).play();
  };
  playFXGameWin4 = () => {
    new Audio(`${URL}/sound/FXGameWin4.mp3`).play();
  };
  playSuperBuzz = () => {
    new Audio(`${URL}/camsviewer/sound/super_buzzclick.mp3`).play();
  };
  playBuzz = () => {
    new Audio(`${URL}/camsviewer/sound/buzzclick.mp3`).play();
  };
}

const camsAudio = new CamsAudio();
export default camsAudio;
