import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindButtonGroup from "../../components/_tailwind/button-group";
import TailwindButton from "../../components/_tailwind/button";

type Props = {};

const StorybookComponentsButtonGroup: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Button Group</StorybookHeading>
      <StorybookParagraph>
        Button Group is a HOC that basically manages the radius' of Buttons
        passed as children to it. If the Buttons are rounded, the buttons in
        between get their corners sharp so it looks like a group of buttons.
      </StorybookParagraph>
      <StorybookParagraph>It does not take any props.</StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindButtonGroup>
  <TailwindButton backgroundColor={"bg-red-500"}>
    button 1
  </TailwindButton>
  <TailwindButton backgroundColor={"bg-red-500"}>
    button 2
  </TailwindButton>
  <TailwindButton backgroundColor={"bg-red-500"}>
    button 3
  </TailwindButton>
</TailwindButtonGroup>
`}
      >
        <TailwindButtonGroup>
          <TailwindButton backgroundColor={"bg-red-500"}>
            button 1
          </TailwindButton>
          <TailwindButton backgroundColor={"bg-red-500"}>
            button 2
          </TailwindButton>
          <TailwindButton backgroundColor={"bg-red-500"}>
            button 3
          </TailwindButton>
        </TailwindButtonGroup>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsButtonGroup;
