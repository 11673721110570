import { Themes } from "./types";
import { ThemeColorContrast, ThemeSite } from "./enums";
import { CamsDarkThemePalette, CamsLightThemePalette } from "./cams";
import { AffDarkThemePalette, AffLightThemePalette } from "./aff";

const THEMES: Themes = {
  [ThemeSite.cams]: {
    [ThemeColorContrast.dark]: CamsDarkThemePalette,
    [ThemeColorContrast.light]: CamsLightThemePalette,
  },
  [ThemeSite.aff]: {
    [ThemeColorContrast.dark]: AffDarkThemePalette,
    [ThemeColorContrast.light]: AffLightThemePalette,
  },
};

export default THEMES;
