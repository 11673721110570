import React from "react";
import "./styles.scss";
import classnames from "classnames";

type FireAndPepperIconProps = {
  className?: string;
};

const FireAndPepperIcon: React.ComponentType<FireAndPepperIconProps> = ({
  className,
}) => (
  <span className={classnames("FireAndPepperIcon", className && className)}>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <g>
        <g transform="translate(0, 0)">
          <g transform="translate(-4.6307579146616025, -3.017372481339689)">
            <path
              vectorEffect="non-scaling-stroke"
              d="M17.77,17.2v-0.05c0.37-1.2,1.17-2.92,1.23-4.18
				c0.04-1.1-1.49-3.96-5.01-5.09C12.03,7.33,10.29,6.22,9,4.7c0,0-0.24,1.92,1.57,4.76c1.17,1.9,1.02,2.93,0.59,3.96
				c-0.3,0.63-0.53,1.3-0.69,1.98c-0.62-0.85-1.57-1.42-2.64-1.59c0,0,1.55,1.29,0.2,4.45s0.64,4.38,1.89,5.01
				c0.66,0.27,1.34,0.49,2.04,0.67c0.36-0.19,0.73-0.42,1.13-0.68c-1.21-0.74-2.34-2.1-1.86-4.57c0.1-0.71,0.34-1.4,0.71-2.02
				l1.17,1.83c0-1.01,0.2-2.02,0.59-2.96c0.49-1.16,0.99-2.91,0.59-4.1C16.05,12.94,17.27,14.96,17.77,17.2z"
            />
          </g>
        </g>
        <g transform="translate(0, 0)">
          <g transform="translate(-3.175360435083569, -9.113509526399252)">
            <path
              vectorEffect="non-scaling-stroke"
              d="M20.61,15.65h0.38c1.51,0.06,2.95,0.63,4.06,1.62l0.4,0.36
				c0.59,0.24,0.87,0.9,0.63,1.47c-1.86,3.98-4.74,7.44-8.36,10.07c-2.04,1.44-6.69,2.32-9.25,2.26c-1.35,0-2.69-0.12-4.02-0.33
				c-0.53-0.1-0.25-0.66,0.28-0.78c1.92-0.42,3.8,0.48,7.43-2.22c1.42-1.06,3.09-3.96,3.97-5.3c0.88-1.34,1.17-2.98,1.72-4.41
				l0.74-2.01C18.96,15.65,19.85,15.33,20.61,15.65z"
            />
          </g>
        </g>
      </g>
      <g transform="translate(0, 0)">
        <g transform="translate(-20.669301788071543, -4.156288578426411)">
          <path
            vectorEffect="non-scaling-stroke"
            d="M42.56,11.65l0.3-2.1l0.1-3.21c0.01-0.26-0.2-0.48-0.46-0.5H42.4
			c-0.26-0.05-0.51,0.1-0.59,0.34l-0.79,2.53l-1.76,1.59C40.47,10.44,41.62,10.91,42.56,11.65z"
          />
        </g>
      </g>
    </svg>
  </span>
);

export default FireAndPepperIcon;
