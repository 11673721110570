import {
  AppCamsModelRouteKey,
  AppCommonRouteKey,
  AppVariantRouteKey,
  RouteSidebarLocations,
} from "core/stores/route/enums";
import { AppRoute } from "core/stores/route/types";
import { PartialRecord, UserRole } from "library/core/types";

export const TUTORIAL_ROUTE: AppRoute = {
  key: AppVariantRouteKey.tutorial,
  link: "tutorial",
  pageTitle: "Tutorial",
  hasAccess: ["model"],
};

const VARIANT_ROUTES: PartialRecord<AppVariantRouteKey, AppRoute> = {
  [AppVariantRouteKey.myVideos]: {
    key: AppVariantRouteKey.myVideos,
    pageTitle: "My Videos",
    hasAccess: ["model"],
    sidebarOptions: {
      action: {
        item: [
          {
            key: AppCamsModelRouteKey.recordedVideos,
            link: "recorded-videos",
            pageTitle: "Recorded Videos",
            hasAccess: ["model"],
            sidebarOptions: {
              location: RouteSidebarLocations.subMenu,
              index: 1,
              icon: "",
              label: "Recorded",
            },
          },
          {
            key: AppCamsModelRouteKey.uploadedVideos,
            link: "uploaded-videos",
            pageTitle: "Uploaded Videos",
            hasAccess: ["model"],
            sidebarOptions: {
              location: RouteSidebarLocations.subMenu,
              index: 2,
              icon: "",
              label: "Uploaded",
            },
          },
        ],
        isExpanded: false,
      },
      location: RouteSidebarLocations.top,
      index: 3,
      icon: "gallery",
      label: "My Videos",
    },
  },
  [AppVariantRouteKey.settings]: {
    key: AppVariantRouteKey.settings,
    pageTitle: "Settings",
    hasAccess: ["model", "studio"],
    sidebarOptions: {
      action: {
        item: [
          {
            key: AppCommonRouteKey.payout,
            link: "payout",
            pageTitle: "Payout",
            hasAccess: (userRole: UserRole, isIndividualModel: boolean) => {
              if (userRole === "studio" || isIndividualModel) {
                return true;
              }

              return false;
            },
            sidebarOptions: {
              location: RouteSidebarLocations.subMenu,
              index: 1,
              icon: "",
              label: "Payout",
            },
          },
          {
            key: AppCommonRouteKey.legalDocuments,
            link: "legal-documents",
            pageTitle: "Legal Documents",
            hasAccess: ["model", "studio"],
            sidebarOptions: {
              location: RouteSidebarLocations.subMenu,
              index: 2,
              icon: "",
              label: "Legal Documents",
            },
          },
          {
            key: AppCommonRouteKey.updatePassword,
            link: "update-password",
            pageTitle: "Update Password",
            hasAccess: ["model", "studio"],
            sidebarOptions: {
              location: RouteSidebarLocations.subMenu,
              index: 3,
              icon: "",
              label: "Update Password",
            },
          },
          {
            key: AppCamsModelRouteKey.blocks,
            link: "blocks",
            pageTitle: "Blocks",
            hasAccess: ["model"],
            sidebarOptions: {
              location: RouteSidebarLocations.subMenu,
              index: 4,
              icon: "",
              label: "Blocks",
            },
          },
        ],
        isExpanded: true,
      },
      location: RouteSidebarLocations.bottom,
      index: 1,
      icon: "setting_side",
      label: "Settings",
    },
  },
};

export default VARIANT_ROUTES;
