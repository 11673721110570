import React, { useCallback, useMemo } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindList from "library/components/_tailwind/list";
import TailwindListItem from "library/components/_tailwind/list/list-item";
import TailwindButton from "library/components/_tailwind/button";
import { CAMS_MODEL_APPLICATIONS_EMAIL } from "library/core/config/urls";
import { AppCamsModelRouteKey } from "core/stores/route/enums";
import TailwindAnchor from "library/components/_tailwind/anchor";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import AuthStore from "core/stores/auth/AuthStore";
import { inject, observer } from "mobx-react";
import RouteStore from "core/stores/route/RouteStore";

type Props = {
  status: "pending" | "denied";
  profileStore?: ProfileStore;
  authStore?: AuthStore;
  routeStore?: RouteStore;
};

const BroadcastIsPendingOrDeniedError: React.ComponentType<Props> = ({
  status,
  profileStore,
  authStore,
  routeStore,
}) => {
  const { getSiteRouteURL } = routeStore!;
  const { openRegisterModalToCompleteProfile } = authStore!;
  const { isProfileBioFieldsComplete, modelProfile } = profileStore!;
  const title = useMemo(() => {
    if (status === "denied") {
      return (
        <React.Fragment>
          Your application is almost complete and you're close to becoming an
          important part of the Streamray line-up. <br /> <br /> The following
          needs your attention:
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          You will be able to broadcast after you complete your profile
        </React.Fragment>
      );
    }
  }, [status]);

  const onClickResubmitApplication = useCallback(() => {
    openRegisterModalToCompleteProfile(false, true);
  }, []);

  const bodyComponent = useMemo(() => {
    if (status === "denied") {
      return (
        <React.Fragment>
          <TailwindList textColor={"text-red-500"} margin={["mb-4"]}>
            {Object.values(modelProfile?.deny_reasons || []).map(
              (val, index) => (
                <TailwindListItem key={`reason_${index}`}>
                  {val.text}
                </TailwindListItem>
              )
            )}
          </TailwindList>
          <TailwindFlex justifyContent={"justify-center"} margin={["mb-10"]}>
            <TailwindButton
              rounded={false}
              fullWidth={false}
              onClick={onClickResubmitApplication}
              backgroundColor={"secondary"}>
              Resubmit your application
            </TailwindButton>
          </TailwindFlex>
          <TailwindText fontSize={"text-sm"} fontWeight={"font-bold"}>
            Please contact us for assistance:
          </TailwindText>
          <TailwindText
            fontSize={"text-sm"}
            textAlign={"text-center"}
            lineHeight={"leading-7"}>
            <strong>Email: </strong>
            <a href={`mailto:${CAMS_MODEL_APPLICATIONS_EMAIL}`}>
              {CAMS_MODEL_APPLICATIONS_EMAIL}
            </a>
            <br />
            <strong>Tel: </strong> (888)546-0188 (Toll free domestic)
          </TailwindText>
        </React.Fragment>
      );
    } else {
      return (
        <TailwindAnchor
          width={"w-auto"}
          backgroundColor={"bg-primary-bg-color"}
          underline={false}
          padding={["p-3", "pl-5", "pr-5"]}
          to={getSiteRouteURL(AppCamsModelRouteKey.myPage)}>
          <TailwindText
            fontSize={"text-2xl"}
            fontWeight={"font-bold"}
            textAlign={"text-center"}>
            Go to My Page
          </TailwindText>
        </TailwindAnchor>
      );
    }
  }, [status]);

  return (
    <TailwindFlex
      height={"h-full"}
      width={"w-full"}
      flexDirection={"flex-col"}
      justifyContent={"justify-center"}>
      <TailwindFlex
        width={"w-full"}
        alignItems={"items-center"}
        flexDirection={"flex-col"}
        margin={["mx-6"]}>
        <TailwindText
          fontSize={"text-lg"}
          margin={["mb-4"]}
          fontWeight={"font-bold"}
          textAlign={"text-center"}>
          {title}
        </TailwindText>
        {bodyComponent}
      </TailwindFlex>
      {modelProfile.status === "PENDING" && (
        <TailwindText
          fontSize={"text-3xl"}
          fontWeight={"font-bold"}
          textAlign={"text-center"}>
          {!isProfileBioFieldsComplete
            ? `...and `
            : `You will be able to broadcast `}
          after your account has been approved.
        </TailwindText>
      )}
    </TailwindFlex>
  );
};

export default inject(
  "authStore",
  "profileStore",
  "routeStore"
)(observer(BroadcastIsPendingOrDeniedError));
