import React from "react";
import Image, { ImageProps } from "../../image";
import TailwindBox, { TailwindBoxProps } from "../box";

type Props = {
  boxProps?: TailwindBoxProps;
} & ImageProps;

const TailwindImage: React.ComponentType<Props> = ({ boxProps, ...props }) => {
  return (
    <TailwindBox {...boxProps}>
      <Image {...props} />
    </TailwindBox>
  );
};

export default TailwindImage;
