import React, { FunctionComponent } from "react";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import TailwindList from "library/components/_tailwind/list";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindListItem from "library/components/_tailwind/list/list-item";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindParagraph from "library/components/_tailwind/paragraph";
import TailwindText from "library/components/_tailwind/text";
import { AppCommonRouteKey } from "core/stores/route/enums";
import ModalStore from "library/core/stores/modal";
import TailwindAnchor from "library/components/_tailwind/anchor";
import { CAMS_TAX_FORMS_EMAIL } from "library/core/config/urls";
import RouteStore from "core/stores/route/RouteStore";

interface MoreInformationProps {
  modalStore?: ModalStore;
  routeStore?: RouteStore;
}

const MoreInformation: FunctionComponent<
  MoreInformationProps & WrappedComponentProps
> = ({ intl, modalStore, routeStore }) => {
  const { closePrimaryModal } = modalStore!;
  const { getSiteRouteURL } = routeStore!;

  return (
    <TailwindFlex padding={["pl-6"]} flexDirection={"flex-col"}>
      <TailwindParagraph>
        <TailwindTranslatedText
          fontWeight={"font-bold"}
          descriptor={{
            id: "uploadTaxFormModal.pleaseNote",
            defaultMessage: "Please note:",
          }}
        />
      </TailwindParagraph>
      <TailwindList>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindText
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage(
                {
                  id: "uploadTaxFormModal.modesPaidDirectlyByStudio",
                  defaultMessage:
                    "<a href='{href}'>Models working for Studios</a> are paid directly by the Studio.",
                },
                {
                  href:
                    "https://streamray.com/p/cams/studio.cgi?cat=cat_topq&ans=c0&tab=common_questions&action=studio2_help",
                  class_names: "font-bold underline",
                }
              ),
            }}
          />
        </TailwindListItem>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindText
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "uploadTaxFormModal.paymentsIssuedWithinFiveDays",
                defaultMessage:
                  "All payments issued within FIVE business days of the pay period closing. This time frame does not include holidays or weekends.",
              }),
            }}
          />
        </TailwindListItem>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindTranslatedText
            descriptor={{
              id: "uploadTaxFormModal.statementWillDisplayStreamrayInc",
              defaultMessage:
                "Your statement will display a payout from Streamray Inc.",
            }}
          />
        </TailwindListItem>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindText
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "uploadTaxFormModal.toEnsureTimelyPayments",
                defaultMessage:
                  "To ensure timely payments, please log in to your account, select your payment method, and submit your tax form. If a payment method is not specified or tax form is not on file, payment will be withheld until all information is submitted.",
              }),
            }}
          />
        </TailwindListItem>
      </TailwindList>
      <TailwindParagraph margin={["mt-4"]}>
        <TailwindTranslatedText
          fontWeight={"font-bold"}
          descriptor={{
            id: "uploadTaxFormModal.paymentByPaperCheck",
            defaultMessage: "Payment by paper check – US & Canada Only",
          }}
        />
      </TailwindParagraph>
      <TailwindList>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindText
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "uploadTaxFormModal.minimumPayment",
                defaultMessage: "Minimum payment is $50.",
              }),
            }}
          />
        </TailwindListItem>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindTranslatedText
            descriptor={{
              id: "uploadTaxFormModal.earningsRollover50",
              defaultMessage:
                "Earnings less than $50 will rollover to next period.",
            }}
          />
        </TailwindListItem>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindTranslatedText
            descriptor={{
              id: "uploadTaxFormModal.paperChecksFree",
              defaultMessage: "Paper checks are free of charge.",
            }}
          />
        </TailwindListItem>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindTranslatedText
            descriptor={{
              id: "uploadTaxFormModal.deliveryUpToSevenDays",
              defaultMessage:
                "Delivery may take up to SEVEN business days (does not include weekends or holidays).",
            }}
          />
        </TailwindListItem>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindTranslatedText
            descriptor={{
              id: "uploadTaxFormModal.internationalMail",
              defaultMessage: "International mail can take up to 3 weeks.",
            }}
          />
        </TailwindListItem>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindTranslatedText
            descriptor={{
              id: "uploadTaxFormModal.streamrayNotResponsible",
              defaultMessage:
                "Streamray is not responsible for postal mail delays.",
            }}
          />
        </TailwindListItem>
      </TailwindList>

      <TailwindParagraph margin={["mt-4"]}>
        <TailwindTranslatedText
          fontWeight={"font-bold"}
          descriptor={{
            id: "uploadTaxFormModal.directDeposit",
            defaultMessage: "Payment by Direct Deposit - US Only",
          }}
        />
      </TailwindParagraph>
      <TailwindList>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindText
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "uploadTaxFormModal.minimumPayment",
                defaultMessage: "Minimum payment is $50.",
              }),
            }}
          />
        </TailwindListItem>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindTranslatedText
            descriptor={{
              id: "uploadTaxFormModal.earningsRollover50",
              defaultMessage:
                "Earnings less than $50 will rollover to next period.",
            }}
          />
        </TailwindListItem>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindTranslatedText
            descriptor={{
              id: "uploadTaxFormModal.timeReceiveRefund",
              defaultMessage: "Usually takes 2-3 days to receive once issued.",
            }}
          />
        </TailwindListItem>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindTranslatedText
            descriptor={{
              id: "uploadTaxFormModal.noTransferFees",
              defaultMessage: "No transfer fees.",
            }}
          />
        </TailwindListItem>
      </TailwindList>

      <TailwindParagraph margin={["mt-4"]}>
        <TailwindTranslatedText
          fontWeight={"font-bold"}
          descriptor={{
            id: "uploadTaxFormModal.paymentByPaxum",
            defaultMessage: "Payment by Paxum",
          }}
        />
      </TailwindParagraph>
      <TailwindList>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindText
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "uploadTaxFormModal.minimumPayment",
                defaultMessage: "Minimum payment is $50.",
              }),
            }}
          />
        </TailwindListItem>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindTranslatedText
            descriptor={{
              id: "uploadTaxFormModal.earningsWillRollover",
              defaultMessage:
                "Earning less than $50 will rollover to next period.",
            }}
          />
        </TailwindListItem>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindTranslatedText
            descriptor={{
              id: "uploadTaxFormModal.accountSetupAfterSubmittingInfo",
              defaultMessage:
                "Account is setup immediately after submitting all necessary information.",
            }}
          />
        </TailwindListItem>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindTranslatedText
            descriptor={{
              id: "uploadTaxFormModal.noTransferFees",
              defaultMessage: "No transfer fees.",
            }}
          />
        </TailwindListItem>
      </TailwindList>

      <TailwindParagraph margin={["mt-4"]}>
        <TailwindTranslatedText
          fontWeight={"font-bold"}
          descriptor={{
            id: "uploadTaxFormModal.paymentByWire",
            defaultMessage: "Payment by wire transfer",
          }}
        />
      </TailwindParagraph>
      <TailwindList>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindText
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: "uploadTaxFormModal.minimumPaymentByWire",
                defaultMessage: "Minimum payment by wire transfer is $120.",
              }),
            }}
          />
        </TailwindListItem>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindTranslatedText
            descriptor={{
              id: "uploadTaxFormModal.earningsRollover120",
              defaultMessage:
                "Earning less than $120 will rollover to next period.",
            }}
          />
        </TailwindListItem>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindTranslatedText
            descriptor={{
              id: "uploadTaxFormModal.paymentArrivesSoonerThanCheck",
              defaultMessage:
                "Payment arrives 2-3 days sooner than paper check.",
            }}
          />
        </TailwindListItem>
        <TailwindListItem listStyleType={"list-disc"}>
          <TailwindTranslatedText
            descriptor={{
              id: "uploadTaxFormModal.wireTransferFee",
              defaultMessage:
                "Wire transfer fee is $10 for domestic (U.S.), $20 for international.",
            }}
          />
        </TailwindListItem>
      </TailwindList>

      <TailwindParagraph margin={["mt-4"]}>
        <TailwindAnchor
          onClick={closePrimaryModal}
          display={"inline-block"}
          to={getSiteRouteURL(AppCommonRouteKey.payout)}>
          <TailwindTranslatedText
            fontWeight={"font-bold"}
            descriptor={{
              id: "uploadTaxFormModal.updatePaymentInfo",
              defaultMessage: "Update your payment information.",
            }}
          />
        </TailwindAnchor>
      </TailwindParagraph>

      <TailwindParagraph margin={["mt-4"]}>
        <TailwindText
          margin={["mr-1"]}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              {
                id: "uploadTaxFormModal.formsCanBeEmailedOrMailedTo",
                defaultMessage:
                  'Tax forms can be sent via email to <a class="{class_names}" href="mailto:{email}">{email}</a> or mail to:',
              },
              {
                email: CAMS_TAX_FORMS_EMAIL,
                class_names: "font-bold underline",
              }
            ),
          }}
        />
      </TailwindParagraph>

      <TailwindParagraph>
        <TailwindTranslatedText
          descriptor={{
            id: "uploadTaxFormModal.streamrayInc",
            defaultMessage: "Streamray Inc.",
          }}
        />
      </TailwindParagraph>
      <TailwindParagraph>
        <TailwindTranslatedText
          descriptor={{
            id: "uploadTaxFormModal.mailAttn",
            defaultMessage: "Attn: Accounting",
          }}
        />
      </TailwindParagraph>
      <TailwindParagraph>
        <TailwindTranslatedText
          descriptor={{
            id: "uploadTaxFormModal.mailAddress",
            defaultMessage: "1615 S. Congress Avenue, Suite 103 ",
          }}
        />
      </TailwindParagraph>
      <TailwindParagraph>
        <TailwindTranslatedText
          descriptor={{
            id: "uploadTaxFormModal.mailCityStateZip",
            defaultMessage: "Delray Beach, Florida 33445",
          }}
        />
      </TailwindParagraph>
      <TailwindParagraph>
        <TailwindTranslatedText
          descriptor={{
            id: "uploadTaxFormModal.mailThanks",
            defaultMessage: "Thank you! Your Streamray team",
          }}
        />
      </TailwindParagraph>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject("modalStore", "routeStore")(observer(MoreInformation))
);
