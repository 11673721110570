import React from "react";
import "./styles.scss";
import classnames from "classnames";

type SharedMediaIconProps = {
  className?: string;
};

const SharedMediaIcon: React.ComponentType<SharedMediaIconProps> = ({
  className,
}) => (
  <span className={classnames("SharedMediaIcon", className && className)}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      version='1.2'
      overflow='visible'
      preserveAspectRatio='none'
      viewBox='0 0  21.999980000000004 21.000010000000003'>
      <g>
        <path
          d='M18.45454,7.11864h-0.54545v-3.73729c0,-1.86864 -1.54545,-3.38136 -3.45454,-3.38136h-10.99999c-1.90909,0 -3.45454,1.51271 -3.45454,3.38136v10.5c0,1.86864 1.54545,3.38136 3.45454,3.38136h1.09091v0.35593c0,1.86864 1.54545,3.38136 3.45454,3.38136h10.54545c1.90909,0 3.45454,-1.51271 3.45454,-3.38136v-7.02966c-0.18182,-1.95763 -1.63636,-3.47034 -3.54545,-3.47034zM1.45454,13.79237v-10.41102c0,-1.0678 0.90909,-1.95763 2,-1.95763h11.0909c1.09091,0 2,0.88983 2,1.95763v10.5c0,1.0678 -0.90909,1.95763 -2,1.95763h-11.0909c-1.09091,0 -2,-0.88983 -2,-2.04661zM20.45453,17.52966c0,1.0678 -0.90909,1.95763 -2,1.95763h-10.54545c-1.09091,0 -2,-0.88983 -2,-1.95763v-0.35593h8.63636c1.90909,0 3.45454,-1.51271 3.45454,-3.38136v-5.33898h0.54545c1.09091,0 2,0.88983 2,1.95763v7.11864z'
          vectorEffect='non-scaling-stroke'
        />
        <path
          d='M12.741659587966566 8.453390353935754 C12.741659587966566 8.453390353935754 8.616661879632069 12.346399069564058 7.241662643520568 11.567797326438397 C5.866663407409069 10.789195583312734 7.241662643520568 5.338983381433107 7.241662643520568 5.338983381433107 C7.241662643520568 5.338983381433107 12.741659587966566 8.453390353935754 12.741659587966566 8.453390353935754 Z'
          vectorEffect='non-scaling-stroke'
        />
      </g>
    </svg>
  </span>
);

export default SharedMediaIcon;
