import React, { useMemo } from "react";
import { ModelMediaStatus } from "./store/types";
import TailwindText, {
  TailwindTextProps,
} from "library/components/_tailwind/text";
import TailwindPill from "library/components/_tailwind/pill";
import { TBackgroundColor } from "library/styles/tailwind-classnames";

export type MediaManagerMediaCardFooterStatusProps = TailwindTextProps & {
  status: ModelMediaStatus | undefined;
};

const MediaManagerMediaCardFooterStatus: React.ComponentType<MediaManagerMediaCardFooterStatusProps> =
  ({ status, ...props }) => {
    const statusLabel: { text: string; color: TBackgroundColor } | undefined =
      useMemo(() => {
        switch (status) {
          case "active": {
            return undefined;
          }
          case "new": {
            return undefined;
          }
          case "pending_conversion": {
            return { text: "Pending", color: "bg-gray-600" };
          }
          case "pending": {
            return { text: "Pending", color: "bg-gray-600" };
          }
          case "rejected": {
            return { text: "Denied", color: "bg-red-500" };
          }
          case "uploading": {
            return { text: "Uploading", color: "bg-gray-600" };
          }
          default: {
            return undefined;
          }
        }
      }, [status]);

    if (!statusLabel) {
      return null;
    }

    return (
      <TailwindPill
        backgroundColor={statusLabel?.color}
        size='sm'
        textColor={"text-white"}>
        <TailwindText {...props}>{statusLabel?.text}</TailwindText>
      </TailwindPill>
    );
  };

export default MediaManagerMediaCardFooterStatus;
