import React from "react";
import classnames from "classnames";
import "./styles.scss";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";

type Props = {
  showBackground?: boolean;
  icon: React.ReactElement | string;
  isCustom?: boolean;
};

const NavigationItem__icon: React.ComponentType<Props> = ({
  showBackground = false,
  icon,
}) => {
  if (!icon) {
    return null;
  }

  return (
    <span
      className={classnames(
        "NavigationItem__icon-container",
        showBackground && "NavigationItem__icon-container--background"
      )}>
      {typeof icon === "string" ? (
        <React.Fragment>
          <TailwindIcon
            name={icon as CustomIconName}
            className={"NavigationItem__icon"}
          />
        </React.Fragment>
      ) : (
        React.cloneElement(icon as React.ReactElement, {
          className: "NavigationItem__icon",
        })
      )}
    </span>
  );
};

export default NavigationItem__icon;
