import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import TailwindAnchor from "../../components/_tailwind/anchor";
import Code from "../../components/_tailwind/code";

type Props = {};

const StorybookArchitectureFileAndFolderStructure: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>
        Architecture &gt; File and Folder Structure
      </StorybookHeading>
      <StorybookParagraph>
        The{" "}
        <TailwindAnchor to={"/conventions/file-and-folder-structure"}>
          file and folder conventions
        </TailwindAnchor>{" "}
        apply to the architecture however there are things that we need to be
        aware in particular, to be able to maintain several products in single
        piece of code.
      </StorybookParagraph>
      <StorybookParagraph>
        There are containers however they are namespaced under special folders
        that convey the name of the product like aff.
      </StorybookParagraph>
      <Code>
        {`containers/common
containers/aff        
containers/passion        
containers/cams
containers/cams/common        
containers/cams/model        
containers/cams/studio        
`}
      </Code>
      <StorybookParagraph>
        The containers inside aff folder are page container that are only for
        AdultFriendFinder. The containers inside common folder are common for
        all 4 products. The folders are structured in a way it makes it easier
        for developer to find project related containers as well as have a
        central routes file where for example /my-page is common for all
        projects whereas /model/earnings is only proprietary to the model's
        functionality.
      </StorybookParagraph>
      <StorybookParagraph>
        As can be seen in cams/common, there are also routes that are shared
        only in Cams between studio and model userRoles
      </StorybookParagraph>
      <StorybookParagraph>
        The schema for the folder structure above could be deciphered as:
      </StorybookParagraph>
      <Code>
        {`{product-name}/{page-name}/index.tsx
{common}/{page-name}/index.tsx        
{common}/{page-name}/{user-role}.tsx        
{product-name}/{common}/index.tsx        
{product-name}/{common}/{user-role}.tsx        
{product-name}/{user-role}/index.tsx        
`}
      </Code>
      <StorybookParagraph>
        A thorough example to the deciphered version would have this many
        possible route & folder structures:
      </StorybookParagraph>
      <Code>
        {`common/my-page/index.tsx        
common/my-page/model.tsx        
common/my-page/studio.tsx        
common/my-page/dating-member.tsx        
common/my-page/passion-member.tsx
aff/home/index.tsx
cams/common/earnings/index.tsx
cams/common/earnings/model.tsx
cams/common/earnings/studio.tsx
cams/model/my-fans/index.tsx
cams/studio/models/index.tsx
`}
      </Code>
      <StorybookParagraph>
        Any additional products or user roles will follow above schema. Please
        refer to{" "}
        <TailwindAnchor to={"/architecture/adaptive-and-responsive-design"}>
          Adaptive & Responsive Design page
        </TailwindAnchor>{" "}
        for more details about how we show different containers/components based
        on product name and user role
      </StorybookParagraph>
    </StorybookWrapper>
  );
};

export default StorybookArchitectureFileAndFolderStructure;
