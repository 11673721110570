import React from "react";
import "./styles.scss";
import clsx from "clsx";

type PersonIconProps = {
  className?: string;
};

const PersonIcon: React.ComponentType<PersonIconProps> = ({ className }) => (
  <span className={clsx("PersonIcon", className && className)}>
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  </span>
);

export default PersonIcon;
