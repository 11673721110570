import React, { useMemo } from "react";
import HLS from "./HLS";
import ModalCloseButton from "../../modal/modal-close-button";
import classnames from "classnames";
import TailwindBox, { TailwindBoxProps } from "../box";

type Props = React.HTMLProps<HTMLVideoElement> & {
  src: string;
  wrapperProps?: TailwindBoxProps;
  className?: string;
  onClickCloseVideo?: () => void;
  fullHeight?: boolean;
  fullWidth?: boolean;
  useHls?: boolean;
  showControls?: boolean;
  controlsList?: string;
  videoRef?: React.Ref<HTMLVideoElement>;
  disablePictureInPicture?: boolean;
};

const VideoPlayer: React.ComponentType<Props> = ({
  src,
  wrapperProps,
  className,
  useHls = true,
  fullHeight = true,
  fullWidth = true,
  showControls = true,
  onClickCloseVideo,
  videoRef,
  ...props
}) => {
  // minio serves non-hls streams/static files
  const _useHls = useMemo(
    () => useHls && !src.includes("minio") && !src.includes("blob"),
    [src, useHls]
  );

  const classes = useMemo(() => {
    return classnames(
      "VideoPlayer",
      fullHeight && "h-full",
      fullWidth && "w-full",
      className && className
    );
  }, [className]);

  return (
    <TailwindBox {...wrapperProps}>
      {onClickCloseVideo && <ModalCloseButton closeModal={onClickCloseVideo} />}
      {_useHls ? (
        <HLS
          className={classes}
          videoProps={{ ...props, controls: showControls }}
          url={src}
        />
      ) : (
        <video
          className={classes}
          {...props}
          ref={videoRef}
          src={src}
          controls={showControls}></video>
      )}
    </TailwindBox>
  );
};

export default VideoPlayer;
