import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import ToggleableButton from "../../components/_tailwind/toggleable-button";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";

type Props = {};

const StorybookComponentsToggleableButton: React.ComponentType<Props> =
  ({}) => {
    return (
      <StorybookWrapper>
        <StorybookHeading>Components &gt; ToggleableButton</StorybookHeading>
        <StorybookParagraph>
          The ToggleableButton is an abstraction of TailwindButton that makes it
          easy to do on/off states for a button. It takes all the props
          TailwindButton does.
        </StorybookParagraph>
        <StorybookComponentShowcase
          sourceCode={`<ToggleableButton>non-active square</ToggleableButton>`}>
          <ToggleableButton>non-active square</ToggleableButton>
        </StorybookComponentShowcase>
        <StorybookComponentShowcase
          sourceCode={`<ToggleableButton shape={"circle"} boxProps={{ margin: ["ml-2"] }}>
  non-active circle
</ToggleableButton>
          `}>
          <ToggleableButton shape={"circle"} boxProps={{ margin: ["ml-2"] }}>
            non-active circle
          </ToggleableButton>
        </StorybookComponentShowcase>
        <StorybookComponentShowcase
          sourceCode={`<ToggleableButton
  shape={"circle"}
  boxProps={{ margin: ["ml-2"] }}
  isSelected={true}
  selectedTextColor={"text-red-500"}
  selectedBackgroundColor={"bg-yellow-500"}
  selectedBorderColor={"border-green-500"}
>
  selected circle
</ToggleableButton>
`}>
          <ToggleableButton
            shape={"circle"}
            boxProps={{ margin: ["ml-2"] }}
            isSelected={true}
            selectedTextColor={"text-red-500"}
            selectedBackgroundColor={"bg-yellow-500"}
            selectedBorderColor={"border-green-500"}>
            selected circle
          </ToggleableButton>
        </StorybookComponentShowcase>
        <StorybookComponentShowcase
          sourceCode={`<ToggleableButton
  shape={"circle"}
  boxProps={{ margin: ["ml-2"] }}
  isSelected={true}
  selectedTextColor={"text-red-500"}
  selectedBackgroundColor={"bg-yellow-500"}
  selectedBorderColor={"border-green-500"}
  hoverTextColor={"text-black"}
  hoverBackgroundColor={"bg-green-500"}
  hoverBorderColor={"border-red-500"}
>
  selected circle with hover color
</ToggleableButton>
`}>
          <ToggleableButton
            shape={"circle"}
            boxProps={{ margin: ["ml-2"] }}
            isSelected={true}
            selectedTextColor={"text-red-500"}
            selectedBackgroundColor={"bg-yellow-500"}
            selectedBorderColor={"border-green-500"}
            hoverTextColor={"text-black"}
            hoverBackgroundColor={"bg-green-500"}
            hoverBorderColor={"border-red-500"}>
            selected circle with hover color
          </ToggleableButton>
        </StorybookComponentShowcase>
      </StorybookWrapper>
    );
  };

export default StorybookComponentsToggleableButton;
