import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";

type Props = {};

const MyFansMemberTransactionsTableHead: React.ComponentType<Props> = ({}) => {
  return (
    <TailwindFlex
      pseudoClasses={["xs:p-3", "xs:pl-5", "xs:pr-5"]}
      padding={["p-5", "pl-10", "pr-10"]}
      backgroundColor={"bg-table-primary-bg-color"}
      borderRadius={"rounded-t-xl"}>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-start"}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          descriptor={{
            id: "dashboard.myFans.date",
            defaultMessage: "Date",
          }}
        />
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-start"}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          descriptor={{
            id: "dashboard.myFans.source",
            defaultMessage: "Source",
          }}
        />
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-start"}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          whiteSpace={"whitespace-nowrap"}
          descriptor={{
            id: "dashboard.myFans.activity",
            defaultMessage: "Activity",
          }}
        />
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          descriptor={{
            id: "dashboard.myFans.tokens",
            defaultMessage: "Tokens",
          }}
        />
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          descriptor={{
            id: "dashboard.myFans.averageCut",
            defaultMessage: "Your Cut",
          }}
        />
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          whiteSpace={"whitespace-nowrap"}
          descriptor={{
            id: "dashboard.myFans.earnings",
            defaultMessage: "Earnings",
          }}
        />
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default MyFansMemberTransactionsTableHead;
