import { inject, observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { IC2C } from "../../_stores/broadcast/interfaces";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import CamsNanoPlayer from "core/utility/nano/nano";

interface Props {
  broadcastStreamStore?: BroadcastStreamStore;
  c2c: IC2C;
}

const BroadcastC2CViewer: React.FunctionComponent<Props> = ({
  c2c,
  broadcastStreamStore,
}) => {
  const [nanoPlayer, setNanoPlayer] = useState<CamsNanoPlayer>();
  const { initializeCam2CamNanoPlayStream } = broadcastStreamStore!;
  const [muted, setMuted] = useState(false);
  const memberNameLC = c2c?.memberName?.toLowerCase() || "";
  const initializePlayer = useCallback(async () => {
    const player = await initializeCam2CamNanoPlayStream(
      memberNameLC,
      memberNameLC
    );
    setNanoPlayer(player);
  }, [c2c]);

  const destroyPlayer = useCallback(async () => {
    if (nanoPlayer) {
      nanoPlayer.stop();
    }
  }, [nanoPlayer]);

  const toggleMute = useCallback(async () => {
    if (muted) {
      nanoPlayer?.player.unmute();
    } else {
      nanoPlayer?.player.mute();
    }
    setMuted(!muted);
  }, [muted, nanoPlayer]);

  useEffect(() => {
    setTimeout(() => {
      initializePlayer();
    }, 100)
    return () => {
      destroyPlayer();
    };
  }, []);

  return (
    <div className='BroadcastC2CViewer'>
      <div id={memberNameLC} className='BroadcastC2CViewer__video'></div>
      <div className='BroadcastC2CViewer__info'>
        <span>{c2c.memberName}</span>
        <span className='BroadcastC2CViewer__flex'></span>
        <span
          onClick={toggleMute}
          className='BroadcastC2CViewer__icon material-icons'>
          {muted ? "volume_off" : "volume_up"}
        </span>
      </div>
    </div>
  );
};

export default inject("broadcastStreamStore")(observer(BroadcastC2CViewer));
