import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindBox from "../../components/_tailwind/box";

type Props = {};

const StorybookComponentsBox: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Box</StorybookHeading>
      <StorybookParagraph>
        Box is a primitive that can take shape of many elements. Basically its
        bare form can be thought as a div that takes almost all the classes of
        TailwindCSS. It is used as a primitive wrapper for many of the
        components such as TailwindFlex, TailwindGrid, TailwindDropdown,
        TailwindInput, TailwindButton. It is dynamically created by React via{" "}
        <Code inline>React.createElement</Code> Top Level API.
      </StorybookParagraph>
      <StorybookParagraph>
        Here are the list of props Box can take. It will be rarely used as most
        use cases can be handled with non-primitive components. If a new
        component is being created, it can then surely be used.
      </StorybookParagraph>
      <Code>
        {`width?: TWidth;
minWidth?: TMinWidth;
maxWidth?: TMaxWidth;
height?: THeight;
minHeight?: TMinHeight;
maxHeight?: TMaxHeight;
display?: TDisplay;
float?: TFloat;
flexDirection?: TFlexDirection;
flexWrap?: TFlexWrap;
flex?: TFlex;
flexGrow?: TFlexGrow;
flexShrink?: TFlexShrink;
order?: TOrder;
gap?: TGap;
gridCols?: TGridTemplateColumns;
gridRows?: TGridTemplateRows;
justifyContent?: TJustifyContent;
justifyItems?: TJustifyItems;
justifySelf?: TJustifySelf;
alignContent?: TAlignContent;
alignItems?: TAlignItems;
alignSelf?: TAlignSelf;
placeContent?: TPlaceContent;
placeItems?: TPlaceItems;
placeSelf?: TPlaceSelf;
margin?: (TMargin | undefined)[];
padding?: (TPadding | undefined)[];
space?: TSpace;
overflow?: TOverflow | (TOverflow | undefined)[];
zIndex?: TZIndex;
visibility?: TVisibility;
clear?: TClear;
boxSizing?: TBoxSizing;
position?: TPosition;
inset?: TInset[];
borderRadius?: TBorderRadius | TBorderRadius[];
borderWidth?: TBorderWidth;
borderColor?: TBorderColor;
borderOpacity?: TBorderOpacity;
borderStyle?: TBorderStyle;
divideWidth?: TDivideWidth;
divideColor?: TDivideColor;
divideOpacity?: TDivideOpacity;
divideStyle?: TDivideStyle;
ringWidth?: TRingWidth;
ringColor?: TRingColor;
ringOpacity?: TRingOpacity;
ringOffsetWidth?: TRingOffsetWidth;
ringOffsetColor?: TRingOffsetColor;
appearance?: TAppearance;
cursor?: TCursor;
outline?: TOutline;
pointerEvents?: TPointerEvents;
userSelect?: TUserSelect;
transitionProperty?: TTransitionProperty;
backgroundColor?: TBackgroundColor;
backgroundOpacity?: TBackgroundOpacity;
backgroundImage?: TBackgroundImage;
backgroundSize?: TBackgroundSize;
backgroundPosition?: TBackgroundPosition;
backgroundRepeat?: TBackgroundRepeat;
gradientColorStops?: TGradientColorStops[];
objectPosition?: TObjectPosition;
transform?: TTransforms[];
textColor?: TTextColor;
boxShadow?: TBoxShadow;
className?: (string | undefined | boolean)[];
id?: string;
style?: React.CSSProperties;
psuedoClasses?: (TPseudoClasses | undefined)[];
as?: string;
onClick?: (event: React.MouseEvent<any>) => void;
onMouseEnter?: (event: React.MouseEvent<any>) => void;
onMouseLeave?: (event: React.MouseEvent<any>) => void;
dangerouslySetInnerHTML?: {
  __html: string;
};
htmlElementProps?: any; //this is used for things like disabled
children?: any;
        
`}
      </Code>
      <StorybookParagraph>
        Here are some examples of TailwindBox but please use TailwindFlex
        instead of TailwindBox. Having Flexbox wrapper is much better than
        adding display block or stuff on TailwindBox. It is easy to align
        components inside via <Code inline>alignItems & justifyContent</Code>.
        TailwindFlex also gets TailwindBoxProps, it is a much better utility.
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindBox backgroundColor={"bg-red-500"} height={"h-20"}>
  bg-red-500 h-20
</TailwindBox>    
`}>
        <TailwindBox backgroundColor={"bg-red-500"} height={"h-20"}>
          bg-red-500 h-20
        </TailwindBox>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        height={"h-96"}
        sourceCode={`<TailwindBox
  backgroundColor={"bg-yellow-500"}
  backgroundOpacity={"bg-opacity-70"}
  height={"h-full"}
  width={"w-1/2"}
  borderColor={"border-blue-500"}
  borderStyle={"border-dashed"}
  borderWidth={["border-4"]}
>
  bg-yellow-500 bg-opacity-70 w-1/2 h-full border-4 border-blue-500
  border-dashed
</TailwindBox>
`}>
        <TailwindBox
          backgroundColor={"bg-yellow-500"}
          backgroundOpacity={"bg-opacity-70"}
          height={"h-full"}
          width={"w-1/2"}
          borderColor={"border-blue-500"}
          borderStyle={"border-dashed"}
          borderWidth={["border-4"]}>
          bg-yellow-500 bg-opacity-70 w-1/2 h-full border-4 border-blue-500
          border-dashed
        </TailwindBox>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        height={"h-96"}
        sourceCode={`<TailwindBox
  backgroundColor={"bg-yellow-500"}
  backgroundOpacity={"bg-opacity-70"}
  height={"h-full"}
  width={"w-1/2"}
  borderColor={"border-blue-500"}
  borderStyle={"border-dashed"}
  borderWidth={["border-4"]}
  psuedoClasses={["md:w-full"]}
>
  bg-yellow-500 bg-opacity-70 w-1/2 h-full border-4 border-blue-500
  border-dashed md:w-full
</TailwindBox>
`}>
        <TailwindBox
          backgroundColor={"bg-yellow-500"}
          backgroundOpacity={"bg-opacity-70"}
          height={"h-full"}
          width={"w-1/2"}
          borderColor={"border-blue-500"}
          borderStyle={"border-dashed"}
          borderWidth={["border-4"]}
          pseudoClasses={["md:w-full"]}>
          bg-yellow-500 bg-opacity-70 w-1/2 h-full border-4 border-blue-500
          border-dashed md:w-full
        </TailwindBox>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsBox;
