import React, { useCallback } from "react";
import TailwindText from "library/components/_tailwind/text";
import TailwindBox from "library/components/_tailwind/box";
import { inject, observer } from "mobx-react";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import TailwindButton from "library/components/_tailwind/button";
import { BroadcastStreamType } from "common/broadcast/_stores/broadcast-stream/enums";
import TailwindFlex from "library/components/_tailwind/flex";
import BroadcastObsModal from "common/broadcast/broadcast-obs-modal";
import ModalStore from "library/core/stores/modal";
import ShowcaseStore from "core/stores/showcase/ShowcaseStore";
import { BroadcastStreamState } from "../_stores/broadcast/enums";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { languageStore } from "library/core/stores/language/LanguageStore";

type Props = {
  broadcastStreamStore?: BroadcastStreamStore;
  modalStore?: ModalStore;
  showcaseStore?: ShowcaseStore;
  broadcastStore?: BroadcastStore;
};

const BroadcastStreamTypeSelector: React.ComponentType<
  Props & WrappedComponentProps
> = ({
  broadcastStreamStore,
  showcaseStore,
  modalStore,
  broadcastStore,
  intl,
}) => {
  const { setStreamType, streamType } = broadcastStreamStore!;
  const { openPrimaryModal } = modalStore!;
  const { enableShowcase } = showcaseStore!;
  const { streamState } = broadcastStore!;

  const onClickStreamTypeButton = useCallback(
    (streamType: BroadcastStreamType) => {
      setStreamType(streamType);
    },
    []
  );

  const onClickShowOBSModal = () => {
    openPrimaryModal(<BroadcastObsModal showStreamCredentialsStep={false} />);
  };

  return (
    <TailwindFlex
      width={"w-full"}
      height={"h-full"}
      alignItems={"items-start"}
      flexDirection={"flex-col"}
      justifyContent={"justify-between"}>
      <TailwindFlex
        width={"w-full"}
        height={"h-full"}
        alignItems={"items-center"}
        justifyContent={"justify-between"}
        margin={["mb-4"]}>
        <TailwindText
          display={"inline-flex"}
          alignItems={"items-start"}
          padding={["py-2", "px-3"]}
          fontSize='text-lg'
          textColor='text-main-color'>
          {languageStore.intl.formatMessage({
            id: "broadcast.broadcastPreview.how-stream",
            defaultMessage: "How do you want to stream?"
          })}
        </TailwindText>
        {streamState === BroadcastStreamState.stopped && !streamType && (
          <TailwindButton
            fullWidth={false}
            underline
            onClick={() => {
              enableShowcase("broadcast");
            }}>
            {intl.formatMessage({
              id: `broadcast.intro`,
              defaultMessage: "Intro",
            })}
          </TailwindButton>
        )}
      </TailwindFlex>
      <TailwindFlex
        flexDirection={"flex-col"}
        alignItems={"items-center"}
        justifyContent={"justify-center"}
        flexGrow={"flex-grow"}>
        <TailwindBox
          margin={["mb-2"]}
          display={"flex"}
          gap={"gap-8"}
          flexDirection={"flex-row"}
          justifyContent={"justify-between"}>
          <TailwindButton
            data-testid={"stream-type-use-obs-button"}
            fullWidth={false}
            backgroundColor={"primary"}
            onClick={() => onClickStreamTypeButton(BroadcastStreamType.OBS)}>
            {languageStore.intl.formatMessage({
              id: "broadcast.broadcastPreview.use-obs",
              defaultMessage: "Use OBS"
            })}
          </TailwindButton>
          <TailwindButton
            data-testid={"stream-type-use-webcam-button"}
            fullWidth={false}
            backgroundColor={"secondary"}
            onClick={() => onClickStreamTypeButton(BroadcastStreamType.WEBRTC)}>
            {languageStore.intl.formatMessage({
              id: "broadcast.broadcastPreview.use-webcam",
              defaultMessage: "Use Ny Webcam"
            })}
          </TailwindButton>
        </TailwindBox>
      </TailwindFlex>
      <TailwindFlex
        width={"w-full"}
        justifyContent={"justify-center"}
        alignItems={"items-center"}>
        <TailwindButton
          rightIconProps={{
            name: "question-mark",
          }}
          fullWidth={false}
          underline
          textProps={{
            fontSize: "text-sm",
          }}
          onClick={onClickShowOBSModal}>
          {languageStore.intl.formatMessage({
            id: "broadcast.broadcastPreview.what-is-obs",
            defaultMessage: "What is OBS and how can I use it?"
          })}
        </TailwindButton>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject(
    "broadcastStreamStore",
    "profileStore",
    "showcaseStore",
    "broadcastStore",
    "modalStore"
  )(observer(BroadcastStreamTypeSelector))
);
