import { SIGNUP_AGREEMENT_TYPE } from "./enums";

export const STUDIO_AGREEMENT_STEPS = [SIGNUP_AGREEMENT_TYPE.BROADCASTING_AGREEMENT];

export const STUDIO_MODEL_AGREEMENT_STEPS = [
  SIGNUP_AGREEMENT_TYPE.BROADCAST_RELEASE,
  SIGNUP_AGREEMENT_TYPE.PERFORMANCE_AGREEMENT,
];

export const INDY_MODEL_AGREEMENT_STEPS = [
  SIGNUP_AGREEMENT_TYPE.BROADCASTING_AGREEMENT,
  SIGNUP_AGREEMENT_TYPE.BROADCAST_RELEASE,
];
