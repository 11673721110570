import React, { useCallback } from "react";
import TailwindText from "library/components/_tailwind/text";
import TailwindBox from "library/components/_tailwind/box";
import TailwindIcon from "library/components/_tailwind/icon";
import { inject, observer } from "mobx-react";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import TailwindButton from "library/components/_tailwind/button";
import TailwindFlex from "library/components/_tailwind/flex";
import { BroadcastStreamType } from "common/broadcast/_stores/broadcast-stream/enums";
import LanguageStore from "library/core/stores/language/LanguageStore";

type Props = {
  broadcastStreamStore?: BroadcastStreamStore;
  languageStore?: LanguageStore;
};

const BroadcastWebrtcStatus: React.ComponentType<Props> = ({
  broadcastStreamStore,
  languageStore,
}) => {
  const { intl } = languageStore!;

  const {
    isGatheringMediaDeviceData,
    isConnectingToWebrtcStream,
    hasUserDeniedPermissionsToDevices,
    setStreamType,
  } = broadcastStreamStore!;

  const onClickTryAgain = useCallback(() => {
    setStreamType(BroadcastStreamType.WEBRTC);
  }, []);

  return (
    <React.Fragment>
      {hasUserDeniedPermissionsToDevices ? (
        <React.Fragment>
          <TailwindText display={"inline-flex"} alignItems={"items-center"}>
            <TailwindBox margin={["mr-2"]} textAlign={"text-center"}>
              {intl.formatMessage({
                id: 'broadcast.denied-perm-1',
                defaultMessage: 'You have denied browser the permissions'
              })}
              <br />
              {intl.formatMessage({
                id: 'broadcast.denied-perm-1',
                defaultMessage: 'to access your webcam and/or microphone.'
              })}
              <br />
              {intl.formatMessage({
                id: 'broadcast.denied-perm-1',
                defaultMessage: 'Reset your browser permissions and try again.'
              })}
            </TailwindBox>
          </TailwindText>
          <TailwindFlex
            margin={["mt-4"]}
            display={"inline-flex"}
            justifyContent={"justify-center"}>
            <TailwindButton
              fullWidth={false}
              backgroundColor={"secondary"}
              onClick={onClickTryAgain}>
              {intl.formatMessage({
                id: 'broadcast.try-again',
                defaultMessage: 'Try again'
              })}
            </TailwindButton>
          </TailwindFlex>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <TailwindFlex
            minHeight='min-h-52'
            flexDirection='flex-col'
            justifyContent='justify-center'
            alignItems={"items-center"}>
            <TailwindBox margin={["mr-2"]} textColor='text-main-color'>
              {intl.formatMessage({
                id: 'broadcast.getting-data-device',
                defaultMessage: 'Getting data from device(s)...'
              })}
            </TailwindBox>
            {isGatheringMediaDeviceData ? (
              <TailwindIcon name={"spinner"} iconProps={{ size: 1 }} />
            ) : (
              <TailwindIcon name={"check"} textColor={"text-green-500"} />
            )}
          </TailwindFlex>
          {!isGatheringMediaDeviceData && (
            <TailwindText display={"inline-flex"} alignItems={"items-center"}>
              <TailwindBox margin={["mr-2"]}>
              {intl.formatMessage({
                id: 'broadcast.prepare-stream',
                defaultMessage: "Preparing stream..."
              })}
              </TailwindBox>
              {isConnectingToWebrtcStream ? (
                <TailwindIcon name={"spinner"} iconProps={{ size: 1 }} />
              ) : (
                <TailwindIcon name={"check"} textColor={"text-green-500"} />
              )}
            </TailwindText>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default inject("broadcastStreamStore", "languageStore")(observer(BroadcastWebrtcStatus));
