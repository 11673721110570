import React from "react";
import TailwindFlex from "../../components/_tailwind/flex";

type Props = {};

const StorybookWrapper: React.ComponentType<Props> = ({ children }) => {
  return (
    <TailwindFlex
      flexDirection={"flex-col"}
      padding={["pb-8"]}
      maxHeight={"max-h-screen"}
      overflow={["overflow-y-auto"]}
    >
      {children}
    </TailwindFlex>
  );
};

export default StorybookWrapper;
