import TailwindFlex from "library/components/_tailwind/flex";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import React from "react";

type Props = {};

const MessageCenterUnavailableAttachment: React.ComponentType<Props> = ({}) => {
  return (
    <TailwindFlex
      className={["MessageCenterUnavailableAttachment"]}
      borderRadius={"rounded-lg"}
      flexDirection='flex-col'
      justifyContent='justify-center'
      alignItems='items-center'
      textAlign='text-center'
      gap={"gap-2"}>
      <TailwindIcon name={CustomIconName.broken} />
      <TailwindTranslatedText
        fontSize='text-sm'
        className='MessageCenterUnavailableAttachment__text'
        descriptor={{
          id: "message-center.attachment-unavailable",
          defaultMessage: "Attachment unavailable, check back later.",
        }}
      />
    </TailwindFlex>
  );
};

export default MessageCenterUnavailableAttachment;
