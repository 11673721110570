import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { MessageCenterConversationDetails } from "./stores/messages/types";
import { format } from "date-fns";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindHeading from "library/components/_tailwind/heading";
import TailwindBox from "library/components/_tailwind/box";
import "./styles.scss";
import TailwindParagraph from "library/components/_tailwind/paragraph";
import Avatar from "library/components/avatar";

type Props = {
  notice: MessageCenterConversationDetails | null;
};

const MessageCenterNewsAndPolicies: React.ComponentType<
  Props & WrappedComponentProps
> = ({ notice }) => {
  return (
    <TailwindFlex
      className={["MessagesReader__content"]}
      padding={["p-4"]}
      flexDirection={"flex-col"}
      flexGrow={"flex-grow"}
      overflow={["overflow-y-auto", "overflow-x-hidden"]}>
      {notice && (
        <TailwindFlex
          flexDirection='flex-col'
          textAlign={"text-center"}
          margin={["mx-auto", "mb-4", "mt-1"]}>
          <TailwindFlex justifyContent='justify-center'>
            <Avatar
              backgroundColor='bg-modal-button-color'
              size={"small"}
              username={notice?.participants[0]?.username}
            />
          </TailwindFlex>

          <TailwindParagraph
            textAlign={"text-center"}
            textColor='text-tab-not-selected-color'
            fontWeight='font-bold'>
            {notice?.participants[0]?.username}
          </TailwindParagraph>
        </TailwindFlex>
      )}
      <TailwindFlex
        className={["MessagesReader__note"]}
        padding={["p-4"]}
        flexDirection={"flex-col"}>
        {(notice?.title || notice?.start_date) && (
          <TailwindFlex
            width={"w-full"}
            margin={["mb-3"]}
            justifyContent={"justify-between"}>
            {notice?.title && (
              <TailwindHeading level={3}>{notice.title}</TailwindHeading>
            )}
            {notice?.start_date && (
              <TailwindParagraph fontSize={"text-sm"}>
                {format(new Date(notice.start_date), "MM/dd/yyyy")}
              </TailwindParagraph>
            )}
          </TailwindFlex>
        )}
        {notice?.body && (
          <TailwindFlex width={"w-full"}>
            <TailwindBox
              margin={["mb-4"]}
              width={"w-full"}
              style={{
                wordBreak: "break-word",
              }}
              dangerouslySetInnerHTML={{ __html: notice.body || "" }}
            />
          </TailwindFlex>
        )}
      </TailwindFlex>
    </TailwindFlex>
  );
};
export default injectIntl(MessageCenterNewsAndPolicies);
