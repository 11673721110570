import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindCard from "../../components/_tailwind/card";
import TailwindIcon from "../../components/_tailwind/icon";
import TailwindFlex from "../../components/_tailwind/flex";
import { CustomIconName } from "../../components/_tailwind/icon/icons/enums";
import TailwindPill from "../../components/_tailwind/pill";
import TailwindDivider from "../../components/_tailwind/divider";
import TailwindText from "../../components/_tailwind/text";
import TailwindBox from "../../components/_tailwind/box";
import Avatar from "../../components/avatar";

type Props = {};

const StorybookComponentsNotificationsMenu: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Notifications Menu</StorybookHeading>
      <StorybookParagraph>
        These components are so far only used on AFF but will be used in other
        projects later.
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindCard
  style={{ width: "500px" }}
  rounded
  borderRadius={["rounded-2xl"]}
  backgroundColor={"primary"}
  overflow={"overflow-hidden"}
  boxShadow={"shadow-md"}
>
  <TailwindFlex
    backgroundColor={"bg-gray-700"}
    height={"h-10"}
    alignItems={"items-center"}
    padding={["py-2", "px-3"]}
  >
    <TailwindText fontSize={"text-xs"} className={"flex-grow"}>
      Notifications
    </TailwindText>
    <TailwindText
      fontSize={"text-xs"}
      textColor={"text-pink-400"}
      fontWeight={"font-bold"}
      margin={["mx-4"]}
      cursor={"cursor-pointer"}
    >
      See All Notifications
    </TailwindText>
    <TailwindIcon
      name={"settings"}
      fontSize={"text-xl"}
      cursor={"cursor-pointer"}
    />
  </TailwindFlex>
  <TailwindBox height={"h-96"} overflow={"overflow-y-auto"}>
    <TailwindFlex
      backgroundColor={"bg-gray-800"}
      alignItems={"items-center"}
      padding={["p-3"]}
    >
      <Avatar
        size={"normal"}
        photoURL={
          "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_r20_s2_478202122_18894_1288112381_0_main-72c35fb164accfd85f9608fcc008fb27.jpg"
        }
        borderSize={"none"}
        className={"mr-2"}
      />
      <TailwindFlex
        flexDirection={"flex-col"}
        width={"w-auto"}
        flexGrow={"flex-grow"}
        margin={["mx-4"]}
      >
        <TailwindFlex>
          <TailwindText fontSize={"text-sm"}>
            Memberusername
          </TailwindText>
          <TailwindIcon
            name={CustomIconName["gold-crown"]}
            margin={["ml-3", "mr-2"]}
          />
          <TailwindIcon name={CustomIconName.verified} />
          <TailwindText
            className={"flex-grow"}
            textAlign={"text-right"}
            fontSize={"text-xs"}
            textColor={"text-gray-500"}
          >
            4:13 PM
          </TailwindText>
        </TailwindFlex>
        <TailwindText textColor={"text-gray-500"} fontSize={"text-xs"}>
          22F • Long Beach, CA
        </TailwindText>
        <TailwindText
          textColor={"text-white"}
          fontSize={"text-xs"}
          lineHeight={"leading-5"}
        >
          Viewed your Profile
        </TailwindText>
      </TailwindFlex>
      <TailwindFlex
        width={"w-12"}
        height={"h-12"}
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        borderRadius={"rounded-full"}
        backgroundColor={"bg-gray-700"}
        cursor={"cursor-pointer"}
      >
        <TailwindIcon name={"more_vert"} fontSize={"text-2xl"} />
      </TailwindFlex>
    </TailwindFlex>
    <TailwindDivider style={{ height: "1px" }} />
    <TailwindFlex
      backgroundColor={"bg-gray-800"}
      alignItems={"items-center"}
      padding={["p-3"]}
    >
      <Avatar
        size={"normal"}
        photoURL={
          "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478492188_50323_1288255844_0_main-a91692d785b731b7c020aba08dbd430a.jpg"
        }
        borderSize={"none"}
        className={"mr-2"}
      />
      <TailwindBox
        width={"w-auto"}
        flexGrow={"flex-grow"}
        margin={["mx-4"]}
      >
        <TailwindFlex flexDirection={"flex-col"}>
          <TailwindFlex>
            <TailwindText fontSize={"text-sm"}>
              Memberusername
            </TailwindText>
            <TailwindIcon
              name={CustomIconName["gold-crown"]}
              margin={["ml-3", "mr-2"]}
            />
            <TailwindIcon name={CustomIconName.verified} />
            <TailwindText
              className={"flex-grow"}
              textAlign={"text-right"}
              fontSize={"text-xs"}
              textColor={"text-gray-500"}
            >
              4:13 PM
            </TailwindText>
          </TailwindFlex>
          <TailwindText
            textColor={"text-gray-500"}
            fontSize={"text-xs"}
          >
            22F • Long Beach, CA
          </TailwindText>
          <TailwindText
            textColor={"text-white"}
            fontSize={"text-xs"}
            lineHeight={"leading-5"}
          >
            Send you a friend request
          </TailwindText>
        </TailwindFlex>
        <TailwindBox
          display={"grid"}
          gridCols={"grid-cols-2"}
          gap={"gap-2"}
          margin={["my-2"]}
          width={"w-10/12"}
        >
          <TailwindPill
            borderRadius={"rounded-full"}
            backgroundColor={"bg-gray-700"}
            width={"w-auto"}
            size={"sm"}
            cursor={"cursor-pointer"}
          >
            Ignore
          </TailwindPill>
          <TailwindPill
            margin={["ml-2"]}
            borderRadius={"rounded-full"}
            backgroundColor={"bg-pink-400"}
            width={"w-auto"}
            size={"sm"}
            cursor={"cursor-pointer"}
          >
            Accept
          </TailwindPill>
        </TailwindBox>
      </TailwindBox>
      <TailwindFlex
        width={"w-12"}
        height={"h-12"}
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        borderRadius={"rounded-full"}
        backgroundColor={"bg-gray-700"}
        cursor={"cursor-pointer"}
      >
        <TailwindIcon name={"more_vert"} fontSize={"text-2xl"} />
      </TailwindFlex>
    </TailwindFlex>
    <TailwindDivider style={{ height: "1px" }} />
    <TailwindFlex
      backgroundColor={"bg-gray-800"}
      alignItems={"items-center"}
      padding={["p-3"]}
    >
      <Avatar
        size={"normal"}
        photoURL={
          "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r20_s3_482189050_30846_1290016778_0_192x192-f64f4622a2a1d3a06d9d6e5beea86ab4.jpg"
        }
        borderSize={"none"}
        className={"mr-2"}
      />
      <TailwindBox
        width={"w-auto"}
        flexGrow={"flex-grow"}
        margin={["mx-4"]}
      >
        <TailwindFlex flexDirection={"flex-col"}>
          <TailwindFlex>
            <TailwindText fontSize={"text-sm"}>
              Memberusername
            </TailwindText>
            <TailwindIcon
              name={CustomIconName["gold-crown"]}
              margin={["ml-3", "mr-2"]}
            />
            <TailwindIcon name={CustomIconName.verified} />
            <TailwindText
              className={"flex-grow"}
              textAlign={"text-right"}
              fontSize={"text-xs"}
              textColor={"text-gray-500"}
            >
              4:13 PM
            </TailwindText>
          </TailwindFlex>
          <TailwindText
            textColor={"text-gray-500"}
            fontSize={"text-xs"}
          >
            22F • Long Beach, CA
          </TailwindText>
          <TailwindText
            textColor={"text-white"}
            fontSize={"text-xs"}
            lineHeight={"leading-5"}
          >
            New Match
          </TailwindText>
        </TailwindFlex>
        <TailwindBox
          display={"grid"}
          gridCols={"grid-cols-2"}
          gap={"gap-2"}
          margin={["my-2"]}
          width={"w-10/12"}
        >
          <TailwindPill
            borderRadius={"rounded-full"}
            backgroundColor={"bg-gray-700"}
            width={"w-auto"}
            size={"sm"}
            cursor={"cursor-pointer"}
          >
            Remove
          </TailwindPill>
          <TailwindPill
            margin={["ml-2"]}
            borderRadius={"rounded-full"}
            backgroundColor={"bg-pink-400"}
            width={"w-auto"}
            size={"sm"}
            cursor={"cursor-pointer"}
          >
            Add Friend
          </TailwindPill>
        </TailwindBox>
      </TailwindBox>
      <TailwindFlex
        width={"w-12"}
        height={"h-12"}
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        borderRadius={"rounded-full"}
        backgroundColor={"bg-gray-700"}
        cursor={"cursor-pointer"}
      >
        <TailwindIcon name={"more_vert"} fontSize={"text-2xl"} />
      </TailwindFlex>
    </TailwindFlex>
    <TailwindDivider style={{ height: "1px" }} />
    <TailwindFlex
      backgroundColor={"bg-gray-800"}
      alignItems={"items-center"}
      padding={["p-3"]}
    >
      <Avatar
        size={"normal"}
        photoURL={
          "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r20_s2_423832817_96542_116920323_14_main-f222c5ee82706605a67d94a4f1350526.jpg"
        }
        borderSize={"none"}
        className={"mr-2"}
      />
      <TailwindBox
        width={"w-auto"}
        flexGrow={"flex-grow"}
        margin={["mx-4"]}
      >
        <TailwindFlex flexDirection={"flex-col"}>
          <TailwindFlex>
            <TailwindText fontSize={"text-sm"}>
              Memberusername
            </TailwindText>
            <TailwindIcon
              name={CustomIconName["gold-crown"]}
              margin={["ml-3", "mr-2"]}
            />
            <TailwindIcon name={CustomIconName.verified} />
            <TailwindText
              className={"flex-grow"}
              textAlign={"text-right"}
              fontSize={"text-xs"}
              textColor={"text-gray-500"}
            >
              4:13 PM
            </TailwindText>
          </TailwindFlex>
          <TailwindText
            textColor={"text-gray-500"}
            fontSize={"text-xs"}
          >
            22F • Long Beach, CA
          </TailwindText>
          <TailwindText
            textColor={"text-white"}
            fontSize={"text-xs"}
            lineHeight={"leading-5"}
          >
            New Match
          </TailwindText>
        </TailwindFlex>
        <TailwindBox
          display={"grid"}
          gridCols={"grid-cols-2"}
          gap={"gap-2"}
          margin={["my-2"]}
          width={"w-10/12"}
        >
          <TailwindPill
            borderRadius={"rounded-full"}
            backgroundColor={"bg-gray-700"}
            width={"w-auto"}
            size={"sm"}
            cursor={"cursor-pointer"}
          >
            Remove
          </TailwindPill>
          <TailwindPill
            margin={["ml-2"]}
            borderRadius={"rounded-full"}
            backgroundColor={"bg-pink-400"}
            width={"w-auto"}
            size={"sm"}
            cursor={"cursor-pointer"}
          >
            Add Friend
          </TailwindPill>
        </TailwindBox>
      </TailwindBox>
      <TailwindFlex
        width={"w-12"}
        height={"h-12"}
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        borderRadius={"rounded-full"}
        backgroundColor={"bg-gray-700"}
        cursor={"cursor-pointer"}
      >
        <TailwindIcon name={"more_vert"} fontSize={"text-2xl"} />
      </TailwindFlex>
    </TailwindFlex>
    <TailwindDivider style={{ height: "1px" }} />
    <TailwindFlex alignItems={"items-center"} padding={["p-3"]}>
      <Avatar
        size={"normal"}
        photoURL={
          "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_r20_s2_478198127_96106_1288110080_0_main-7931b12f8c6182af28dc50d29f690bce.jpg"
        }
        borderSize={"none"}
        className={"mr-2"}
      />
      <TailwindFlex
        flexDirection={"flex-col"}
        width={"w-auto"}
        flexGrow={"flex-grow"}
        margin={["mx-4"]}
      >
        <TailwindFlex>
          <TailwindText fontSize={"text-sm"}>
            Memberusername
          </TailwindText>
          <TailwindIcon
            name={CustomIconName["gold-crown"]}
            margin={["ml-3", "mr-2"]}
          />
          <TailwindIcon name={CustomIconName.verified} />
          <TailwindText
            className={"flex-grow"}
            textAlign={"text-right"}
            fontSize={"text-xs"}
            textColor={"text-gray-500"}
          >
            4:13 PM
          </TailwindText>
        </TailwindFlex>
        <TailwindText textColor={"text-gray-500"} fontSize={"text-xs"}>
          22F • Long Beach, CA
        </TailwindText>
        <TailwindText
          textColor={"text-white"}
          fontSize={"text-xs"}
          lineHeight={"leading-5"}
        >
          Likes your photo
        </TailwindText>
      </TailwindFlex>
      <TailwindFlex
        width={"w-12"}
        height={"h-12"}
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        borderRadius={"rounded-full"}
        backgroundColor={"bg-gray-700"}
        cursor={"cursor-pointer"}
      >
        <TailwindIcon name={"more_vert"} fontSize={"text-2xl"} />
      </TailwindFlex>
    </TailwindFlex>
    <TailwindDivider style={{ height: "1px" }} />{" "}
    <TailwindFlex alignItems={"items-center"} padding={["p-3"]}>
      <Avatar
        size={"normal"}
        photoURL={
          "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478544890_29127_1288283537_0_main-bdf944b6285ecacf9f89a82d131d7f6c.jpg"
        }
        borderSize={"none"}
        className={"mr-2"}
      />
      <TailwindFlex
        flexDirection={"flex-col"}
        width={"w-auto"}
        flexGrow={"flex-grow"}
        margin={["mx-4"]}
      >
        <TailwindFlex>
          <TailwindText fontSize={"text-sm"}>
            Memberusername
          </TailwindText>
          <TailwindIcon
            name={CustomIconName["gold-crown"]}
            margin={["ml-3", "mr-2"]}
          />
          <TailwindIcon name={CustomIconName.verified} />
          <TailwindText
            className={"flex-grow"}
            textAlign={"text-right"}
            fontSize={"text-xs"}
            textColor={"text-gray-500"}
          >
            4:13 PM
          </TailwindText>
        </TailwindFlex>
        <TailwindText textColor={"text-gray-500"} fontSize={"text-xs"}>
          22F • Long Beach, CA
        </TailwindText>
        <TailwindText
          textColor={"text-white"}
          fontSize={"text-xs"}
          lineHeight={"leading-5"}
        >
          Likes your photo
        </TailwindText>
      </TailwindFlex>
      <TailwindFlex
        width={"w-12"}
        height={"h-12"}
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        borderRadius={"rounded-full"}
        backgroundColor={"bg-gray-700"}
        cursor={"cursor-pointer"}
      >
        <TailwindIcon name={"more_vert"} fontSize={"text-2xl"} />
      </TailwindFlex>
    </TailwindFlex>
  </TailwindBox>
</TailwindCard>
`}
        backgroundColor={"bg-white"}
      >
        <TailwindCard
          style={{ width: "500px" }}
          rounded
          borderRadius={["rounded-2xl"]}
          backgroundColor={"primary"}
          overflow={"overflow-hidden"}
          boxShadow={"shadow-md"}
        >
          <TailwindFlex
            backgroundColor={"bg-gray-700"}
            height={"h-10"}
            alignItems={"items-center"}
            padding={["py-2", "px-3"]}
          >
            <TailwindText fontSize={"text-xs"} className={"flex-grow"}>
              Notifications
            </TailwindText>
            <TailwindText
              fontSize={"text-xs"}
              textColor={"text-pink-400"}
              fontWeight={"font-bold"}
              margin={["mx-4"]}
              cursor={"cursor-pointer"}
            >
              See All Notifications
            </TailwindText>
            <TailwindIcon
              name={"settings"}
              fontSize={"text-xl"}
              cursor={"cursor-pointer"}
            />
          </TailwindFlex>
          <TailwindBox height={"h-96"} overflow={"overflow-y-auto"}>
            <TailwindFlex
              backgroundColor={"bg-gray-800"}
              alignItems={"items-center"}
              padding={["p-3"]}
            >
              <Avatar
                size={"normal"}
                photoURL={
                  "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_r20_s2_478202122_18894_1288112381_0_main-72c35fb164accfd85f9608fcc008fb27.jpg"
                }
                borderSize={"none"}
                className={"mr-2"}
              />
              <TailwindFlex
                flexDirection={"flex-col"}
                width={"w-auto"}
                flexGrow={"flex-grow"}
                margin={["mx-4"]}
              >
                <TailwindFlex>
                  <TailwindText fontSize={"text-sm"}>
                    Memberusername
                  </TailwindText>
                  <TailwindIcon
                    name={CustomIconName["gold-crown"]}
                    margin={["ml-3", "mr-2"]}
                  />
                  <TailwindIcon name={CustomIconName.verified} />
                  <TailwindText
                    className={"flex-grow"}
                    textAlign={"text-right"}
                    fontSize={"text-xs"}
                    textColor={"text-gray-500"}
                  >
                    4:13 PM
                  </TailwindText>
                </TailwindFlex>
                <TailwindText textColor={"text-gray-500"} fontSize={"text-xs"}>
                  22F • Long Beach, CA
                </TailwindText>
                <TailwindText
                  textColor={"text-white"}
                  fontSize={"text-xs"}
                  lineHeight={"leading-5"}
                >
                  Viewed your Profile
                </TailwindText>
              </TailwindFlex>
              <TailwindFlex
                width={"w-12"}
                height={"h-12"}
                justifyContent={"justify-center"}
                alignItems={"items-center"}
                borderRadius={"rounded-full"}
                backgroundColor={"bg-gray-700"}
                cursor={"cursor-pointer"}
              >
                <TailwindIcon name={"more_vert"} fontSize={"text-2xl"} />
              </TailwindFlex>
            </TailwindFlex>
            <TailwindDivider style={{ height: "1px" }} />
            <TailwindFlex
              backgroundColor={"bg-gray-800"}
              alignItems={"items-center"}
              padding={["p-3"]}
            >
              <Avatar
                size={"normal"}
                photoURL={
                  "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478492188_50323_1288255844_0_main-a91692d785b731b7c020aba08dbd430a.jpg"
                }
                borderSize={"none"}
                className={"mr-2"}
              />
              <TailwindBox
                width={"w-auto"}
                flexGrow={"flex-grow"}
                margin={["mx-4"]}
              >
                <TailwindFlex flexDirection={"flex-col"}>
                  <TailwindFlex>
                    <TailwindText fontSize={"text-sm"}>
                      Memberusername
                    </TailwindText>
                    <TailwindIcon
                      name={CustomIconName["gold-crown"]}
                      margin={["ml-3", "mr-2"]}
                    />
                    <TailwindIcon name={CustomIconName.verified} />
                    <TailwindText
                      className={"flex-grow"}
                      textAlign={"text-right"}
                      fontSize={"text-xs"}
                      textColor={"text-gray-500"}
                    >
                      4:13 PM
                    </TailwindText>
                  </TailwindFlex>
                  <TailwindText
                    textColor={"text-gray-500"}
                    fontSize={"text-xs"}
                  >
                    22F • Long Beach, CA
                  </TailwindText>
                  <TailwindText
                    textColor={"text-white"}
                    fontSize={"text-xs"}
                    lineHeight={"leading-5"}
                  >
                    Send you a friend request
                  </TailwindText>
                </TailwindFlex>
                <TailwindBox
                  display={"grid"}
                  gridCols={"grid-cols-2"}
                  gap={"gap-2"}
                  margin={["my-2"]}
                  width={"w-10/12"}
                >
                  <TailwindPill
                    borderRadius={"rounded-full"}
                    backgroundColor={"bg-gray-700"}
                    width={"w-auto"}
                    size={"sm"}
                    cursor={"cursor-pointer"}
                  >
                    Ignore
                  </TailwindPill>
                  <TailwindPill
                    margin={["ml-2"]}
                    borderRadius={"rounded-full"}
                    backgroundColor={"bg-pink-400"}
                    width={"w-auto"}
                    size={"sm"}
                    cursor={"cursor-pointer"}
                  >
                    Accept
                  </TailwindPill>
                </TailwindBox>
              </TailwindBox>
              <TailwindFlex
                width={"w-12"}
                height={"h-12"}
                justifyContent={"justify-center"}
                alignItems={"items-center"}
                borderRadius={"rounded-full"}
                backgroundColor={"bg-gray-700"}
                cursor={"cursor-pointer"}
              >
                <TailwindIcon name={"more_vert"} fontSize={"text-2xl"} />
              </TailwindFlex>
            </TailwindFlex>
            <TailwindDivider style={{ height: "1px" }} />
            <TailwindFlex
              backgroundColor={"bg-gray-800"}
              alignItems={"items-center"}
              padding={["p-3"]}
            >
              <Avatar
                size={"normal"}
                photoURL={
                  "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r20_s3_482189050_30846_1290016778_0_192x192-f64f4622a2a1d3a06d9d6e5beea86ab4.jpg"
                }
                borderSize={"none"}
                className={"mr-2"}
              />
              <TailwindBox
                width={"w-auto"}
                flexGrow={"flex-grow"}
                margin={["mx-4"]}
              >
                <TailwindFlex flexDirection={"flex-col"}>
                  <TailwindFlex>
                    <TailwindText fontSize={"text-sm"}>
                      Memberusername
                    </TailwindText>
                    <TailwindIcon
                      name={CustomIconName["gold-crown"]}
                      margin={["ml-3", "mr-2"]}
                    />
                    <TailwindIcon name={CustomIconName.verified} />
                    <TailwindText
                      className={"flex-grow"}
                      textAlign={"text-right"}
                      fontSize={"text-xs"}
                      textColor={"text-gray-500"}
                    >
                      4:13 PM
                    </TailwindText>
                  </TailwindFlex>
                  <TailwindText
                    textColor={"text-gray-500"}
                    fontSize={"text-xs"}
                  >
                    22F • Long Beach, CA
                  </TailwindText>
                  <TailwindText
                    textColor={"text-white"}
                    fontSize={"text-xs"}
                    lineHeight={"leading-5"}
                  >
                    New Match
                  </TailwindText>
                </TailwindFlex>
                <TailwindBox
                  display={"grid"}
                  gridCols={"grid-cols-2"}
                  gap={"gap-2"}
                  margin={["my-2"]}
                  width={"w-10/12"}
                >
                  <TailwindPill
                    borderRadius={"rounded-full"}
                    backgroundColor={"bg-gray-700"}
                    width={"w-auto"}
                    size={"sm"}
                    cursor={"cursor-pointer"}
                  >
                    Remove
                  </TailwindPill>
                  <TailwindPill
                    margin={["ml-2"]}
                    borderRadius={"rounded-full"}
                    backgroundColor={"bg-pink-400"}
                    width={"w-auto"}
                    size={"sm"}
                    cursor={"cursor-pointer"}
                  >
                    Add Friend
                  </TailwindPill>
                </TailwindBox>
              </TailwindBox>
              <TailwindFlex
                width={"w-12"}
                height={"h-12"}
                justifyContent={"justify-center"}
                alignItems={"items-center"}
                borderRadius={"rounded-full"}
                backgroundColor={"bg-gray-700"}
                cursor={"cursor-pointer"}
              >
                <TailwindIcon name={"more_vert"} fontSize={"text-2xl"} />
              </TailwindFlex>
            </TailwindFlex>
            <TailwindDivider style={{ height: "1px" }} />
            <TailwindFlex
              backgroundColor={"bg-gray-800"}
              alignItems={"items-center"}
              padding={["p-3"]}
            >
              <Avatar
                size={"normal"}
                photoURL={
                  "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r20_s2_423832817_96542_116920323_14_main-f222c5ee82706605a67d94a4f1350526.jpg"
                }
                borderSize={"none"}
                className={"mr-2"}
              />
              <TailwindBox
                width={"w-auto"}
                flexGrow={"flex-grow"}
                margin={["mx-4"]}
              >
                <TailwindFlex flexDirection={"flex-col"}>
                  <TailwindFlex>
                    <TailwindText fontSize={"text-sm"}>
                      Memberusername
                    </TailwindText>
                    <TailwindIcon
                      name={CustomIconName["gold-crown"]}
                      margin={["ml-3", "mr-2"]}
                    />
                    <TailwindIcon name={CustomIconName.verified} />
                    <TailwindText
                      className={"flex-grow"}
                      textAlign={"text-right"}
                      fontSize={"text-xs"}
                      textColor={"text-gray-500"}
                    >
                      4:13 PM
                    </TailwindText>
                  </TailwindFlex>
                  <TailwindText
                    textColor={"text-gray-500"}
                    fontSize={"text-xs"}
                  >
                    22F • Long Beach, CA
                  </TailwindText>
                  <TailwindText
                    textColor={"text-white"}
                    fontSize={"text-xs"}
                    lineHeight={"leading-5"}
                  >
                    New Match
                  </TailwindText>
                </TailwindFlex>
                <TailwindBox
                  display={"grid"}
                  gridCols={"grid-cols-2"}
                  gap={"gap-2"}
                  margin={["my-2"]}
                  width={"w-10/12"}
                >
                  <TailwindPill
                    borderRadius={"rounded-full"}
                    backgroundColor={"bg-gray-700"}
                    width={"w-auto"}
                    size={"sm"}
                    cursor={"cursor-pointer"}
                  >
                    Remove
                  </TailwindPill>
                  <TailwindPill
                    margin={["ml-2"]}
                    borderRadius={"rounded-full"}
                    backgroundColor={"bg-pink-400"}
                    width={"w-auto"}
                    size={"sm"}
                    cursor={"cursor-pointer"}
                  >
                    Add Friend
                  </TailwindPill>
                </TailwindBox>
              </TailwindBox>
              <TailwindFlex
                width={"w-12"}
                height={"h-12"}
                justifyContent={"justify-center"}
                alignItems={"items-center"}
                borderRadius={"rounded-full"}
                backgroundColor={"bg-gray-700"}
                cursor={"cursor-pointer"}
              >
                <TailwindIcon name={"more_vert"} fontSize={"text-2xl"} />
              </TailwindFlex>
            </TailwindFlex>
            <TailwindDivider style={{ height: "1px" }} />
            <TailwindFlex alignItems={"items-center"} padding={["p-3"]}>
              <Avatar
                size={"normal"}
                photoURL={
                  "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_r20_s2_478198127_96106_1288110080_0_main-7931b12f8c6182af28dc50d29f690bce.jpg"
                }
                borderSize={"none"}
                className={"mr-2"}
              />
              <TailwindFlex
                flexDirection={"flex-col"}
                width={"w-auto"}
                flexGrow={"flex-grow"}
                margin={["mx-4"]}
              >
                <TailwindFlex>
                  <TailwindText fontSize={"text-sm"}>
                    Memberusername
                  </TailwindText>
                  <TailwindIcon
                    name={CustomIconName["gold-crown"]}
                    margin={["ml-3", "mr-2"]}
                  />
                  <TailwindIcon name={CustomIconName.verified} />
                  <TailwindText
                    className={"flex-grow"}
                    textAlign={"text-right"}
                    fontSize={"text-xs"}
                    textColor={"text-gray-500"}
                  >
                    4:13 PM
                  </TailwindText>
                </TailwindFlex>
                <TailwindText textColor={"text-gray-500"} fontSize={"text-xs"}>
                  22F • Long Beach, CA
                </TailwindText>
                <TailwindText
                  textColor={"text-white"}
                  fontSize={"text-xs"}
                  lineHeight={"leading-5"}
                >
                  Likes your photo
                </TailwindText>
              </TailwindFlex>
              <TailwindFlex
                width={"w-12"}
                height={"h-12"}
                justifyContent={"justify-center"}
                alignItems={"items-center"}
                borderRadius={"rounded-full"}
                backgroundColor={"bg-gray-700"}
                cursor={"cursor-pointer"}
              >
                <TailwindIcon name={"more_vert"} fontSize={"text-2xl"} />
              </TailwindFlex>
            </TailwindFlex>
            <TailwindDivider style={{ height: "1px" }} />{" "}
            <TailwindFlex alignItems={"items-center"} padding={["p-3"]}>
              <Avatar
                size={"normal"}
                photoURL={
                  "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r30_s2_478544890_29127_1288283537_0_main-bdf944b6285ecacf9f89a82d131d7f6c.jpg"
                }
                borderSize={"none"}
                className={"mr-2"}
              />
              <TailwindFlex
                flexDirection={"flex-col"}
                width={"w-auto"}
                flexGrow={"flex-grow"}
                margin={["mx-4"]}
              >
                <TailwindFlex>
                  <TailwindText fontSize={"text-sm"}>
                    Memberusername
                  </TailwindText>
                  <TailwindIcon
                    name={CustomIconName["gold-crown"]}
                    margin={["ml-3", "mr-2"]}
                  />
                  <TailwindIcon name={CustomIconName.verified} />
                  <TailwindText
                    className={"flex-grow"}
                    textAlign={"text-right"}
                    fontSize={"text-xs"}
                    textColor={"text-gray-500"}
                  >
                    4:13 PM
                  </TailwindText>
                </TailwindFlex>
                <TailwindText textColor={"text-gray-500"} fontSize={"text-xs"}>
                  22F • Long Beach, CA
                </TailwindText>
                <TailwindText
                  textColor={"text-white"}
                  fontSize={"text-xs"}
                  lineHeight={"leading-5"}
                >
                  Likes your photo
                </TailwindText>
              </TailwindFlex>
              <TailwindFlex
                width={"w-12"}
                height={"h-12"}
                justifyContent={"justify-center"}
                alignItems={"items-center"}
                borderRadius={"rounded-full"}
                backgroundColor={"bg-gray-700"}
                cursor={"cursor-pointer"}
              >
                <TailwindIcon name={"more_vert"} fontSize={"text-2xl"} />
              </TailwindFlex>
            </TailwindFlex>
          </TailwindBox>
        </TailwindCard>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsNotificationsMenu;
