import React from "react";
import TailwindText from "library/components/_tailwind/text";
import TailwindBox from "library/components/_tailwind/box";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindSwitch from "library/components/_tailwind/switch";
import TailwindIcon from "library/components/_tailwind/icon";
import { TInset } from "library/styles/tailwind-classnames";

type Props = {};

const MediaManagerSidebarBodyDescriptionRecordedShows: React.ComponentType<Props> =
  ({}) => {
    return (
      <TailwindFlex flexDirection={"flex-col"}>
        <TailwindText textColor='text-main-color'>
          Paid shows are recorded automatically. This feature can be turned off
          in your Broadcaster settings{" "}
          <TailwindText display={"inline-block"}>
            <TailwindIcon fontSize={"text-xs"} name={"setting_side"} />
          </TailwindText>
          . If you turned automatic recording off, you can still manually record
          your shows by toggling the REC switch{" "}
          <TailwindText display={"inline-block"}>
            <TailwindBox>
              <TailwindSwitch
                onLabel={"REC"}
                offLabel={"REC"}
                size={"xs"}
                isChecked={true}
                offBackgroundColor={"bg-toggle-disabled"}
                offLabelProps={{
                  textColor: "text-white",
                }}
                onBackgroundColor={"bg-secondary-bg-color"}
                onLabelProps={{
                  textColor: "text-main-color",
                  inset: ["right-7" as TInset],
                }}
                pillBackgroundColor={"bg-pink-600"}
              />
            </TailwindBox>
          </TailwindText>
          . All of your recordings will show up in this section.
        </TailwindText>
        <TailwindText margin={["mt-4"]} textColor='text-main-color'>
          While fan club members get full access to your shows, you can set
          token prices for other members to view.
        </TailwindText>
      </TailwindFlex>
    );
  };

export default MediaManagerSidebarBodyDescriptionRecordedShows;
