import React, { useCallback, useEffect, useMemo } from "react";
import { inject, observer } from "mobx-react";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import NavigationItem from "containers/_layout/dashboard/navigation-item";
import AuthStore from "core/stores/auth/AuthStore";
import "./styles.scss";
import { AppRoute } from "core/stores/route/types";
import Avatar from "library/components/avatar";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import TailwindDivider from "library/components/_tailwind/divider";
import RouteStore from "core/stores/route/RouteStore";
import { useHistory, useLocation } from "react-router-dom";
import MessageStore from "common/messages/stores/messages/MessageStore";

type SidebarProps = {
  layoutStore?: LayoutStore;
  authStore?: AuthStore;
  profileStore?: ProfileStore;
  routeStore?: RouteStore;
  messageStore?: MessageStore;
};

const DashboardSidebar: React.ComponentType<SidebarProps> = ({
  layoutStore,
  authStore,
  profileStore,
  routeStore,
  messageStore
}) => {
  const {
    isMenuOpen,
    isOpenMenuOnMouseOver,
    toggleMenuOnMouseOver,
    toggleMenu,
    activeSidebarDropdownKey,
    setActiveSidebarDropdownKey,
    isMobileDevice,
    isLiteMode,
  } = layoutStore!;

  const {
    activeConversation,
    isComposing,
    isSearchingView,
  } = messageStore!;

  const location = useLocation()
  const history = useHistory()

  const { openRegisterModalToCompleteProfile } = authStore!;
  const {
    profile,
    profilePhotoURL,
    profileRegistrationCompleted,
    isCurrentAccountStatus,
    checkAccountApprovalStatus,
  } = profileStore!;

  const { routes, sidebarRoutes } = routeStore!;

  const onCompleteProfileClicked = useCallback(() => {
    const accountApproved = isCurrentAccountStatus("APPROVED");

    if (profileRegistrationCompleted && !accountApproved) {
      checkAccountApprovalStatus();
    }
    if (!profileRegistrationCompleted) {
      openRegisterModalToCompleteProfile(true, true);
    }
  }, [
    profileRegistrationCompleted,
    isCurrentAccountStatus,
    checkAccountApprovalStatus,
    openRegisterModalToCompleteProfile,
  ]);

  const getNavigationItemIcon = useCallback(
    (route: AppRoute) => {
      const sidebarOptions = route.sidebarOptions;
      if (sidebarOptions?.icon === "avatar") {
        return (
          <Avatar
            photoURL={profilePhotoURL}
            size={"extra-small"}
            borderSize={"none"}
            borderColor={"title-color-1"}
          />
        );
      } else {
        return sidebarOptions?.icon;
      }
    },
    [profilePhotoURL]
  );

  const getNavigationItemLabel = useCallback(
    (route: AppRoute) => {
      const sidebarOptions = route.sidebarOptions;
      return sidebarOptions?.label;
    },
    [profile]
  );

  const onClickNavigationItem = useCallback(
    (
      _event: React.MouseEvent<HTMLElement> | undefined,
      route: AppRoute,
      isClickingFromSubRoute?: boolean
    ) => {
      if(route.key === "earnings" && location.pathname === "/earnings") {
        history.go(0)
        history.push("/earnings")
      }
      if (route.key === "completeRegistration") {
        onCompleteProfileClicked();
      }
      if (isClickingFromSubRoute) {
        toggleMenu(false);
      } else {
        if (route.key === activeSidebarDropdownKey) {
          setActiveSidebarDropdownKey(null);
        } else if (route.key !== activeSidebarDropdownKey) {
          setActiveSidebarDropdownKey(route?.key);
        } else if (
          !activeSidebarDropdownKey &&
          (route?.sidebarOptions?.action?.item?.length || 0) > 0
        ) {
          setActiveSidebarDropdownKey(route.key);
        }
      }
    },
    [onCompleteProfileClicked, activeSidebarDropdownKey]
  );

  useEffect(() => {
    // expand the first item has has dropdown
    const firstRoute = Object.values(routes).find(
      value => value?.sidebarOptions?.action?.isExpanded
    );
    if (firstRoute) {
      setActiveSidebarDropdownKey(firstRoute.key);
    }
  }, []);

  const onMouseOverOnMenu = useCallback(() => {
    toggleMenuOnMouseOver(true);
  }, []);
  const onMouseLeaveOnMenu = useCallback(() => {
    toggleMenuOnMouseOver(false);
  }, []);

  const shouldBefullWidth = useMemo(() =>
    isMobileDevice && (isSearchingView || isComposing || activeConversation), 
  [activeConversation, isComposing, isSearchingView, isMobileDevice]);

  if(shouldBefullWidth) return null;

  return (
    <div
      className={`DashboardSidebar ${
        isMobileDevice ? "DashboardSidebar--mobile" : ""
      } ${isLiteMode ? "DashboardSidebar--litemode" : ""} ${
        isMenuOpen || (isOpenMenuOnMouseOver && !isMobileDevice)
          ? "DashboardSidebar--open"
          : ""
      }`}
      onMouseOver={onMouseOverOnMenu}
      onMouseLeave={onMouseLeaveOnMenu}>
      <nav className={"DashboardSidebar__navigation max-w-full"}>
        <div className={"DashboardSidebar__top"}>
          {sidebarRoutes.topRoutes.map(route =>
            route ? (
              <div key={`navigation-top-item-${route.key + Math.ceil(Math.random()*100)}`}>
                <NavigationItem
                  getNavigationItemIcon={getNavigationItemIcon}
                  getNavigationItemLabel={getNavigationItemLabel}
                  route={route}
                  sidebarOptions={
                    route.sidebarOptions
                      ? {
                          ...route.sidebarOptions,
                          icon: getNavigationItemIcon(route) || "",
                          label: getNavigationItemLabel(route) || "",
                        }
                      : undefined
                  }
                  isDropdownExpanded={route.key === activeSidebarDropdownKey}
                  preventDefault={!route.link}
                  onClick={onClickNavigationItem}
                />
              </div>
            ) : null
          )}
        </div>
        <div className={"DashboardSidebar__bottom"}>
          <TailwindDivider color={"bg-gray-400"} style={{ height: "1px" }} />
          {sidebarRoutes.bottomRoutes.map(route =>
            route ? (
              <div key={`navigation-bottom-item-${route.key}`}>
                <NavigationItem
                  getNavigationItemIcon={getNavigationItemIcon}
                  getNavigationItemLabel={getNavigationItemLabel}
                  route={route}
                  isDropdownExpanded={route.key === activeSidebarDropdownKey}
                  sidebarOptions={
                    route.sidebarOptions
                      ? {
                          ...route.sidebarOptions,
                          icon: getNavigationItemIcon(route) || "",
                          label: getNavigationItemLabel(route) || "",
                        }
                      : undefined
                  }
                  preventDefault={!route.link}
                  onClick={onClickNavigationItem}
                />
              </div>
            ) : null
          )}
        </div>
      </nav>
    </div>
  );
};

export default inject(
  "layoutStore",
  "authStore",
  "profileStore",
  "routeStore",
  "messageStore"
)(observer(DashboardSidebar));
