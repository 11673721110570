import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MessageCenterContact from "./messages-navigator-contact";
import LoadingResourceSpinner from "./messages-loading-spinner";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import MessageStore from "./stores/messages/MessageStore";
import { MessageCenterContactDetails } from "./stores/messages/types";
import LoyalFansModal from "./loyalFansModal";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import MessageContactListFilter from "./messages-contact-list-filter";
import MessageCenterSearchBox from "./messages-navigator-contact-conversation-search-box";
import TailwindBox from "library/components/_tailwind/box";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import ModalStore from "library/core/stores/modal";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import TailwindCheckboxRadio from "library/components/_tailwind/checkbox-radio";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import CookieStorage from "library/core/utility/cookie-storage";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import { useHistory } from "react-router-dom";
import RouteStore from "core/stores/route/RouteStore";
import { AppCommonRouteKey } from "core/stores/route/enums";

type Props = {
  messageStore?: MessageStore;
  isMobile?: boolean;
  modalStore?: ModalStore;
  layoutStore?: LayoutStore;
  routeStore?: RouteStore;
};

const MessageCenterContactList: React.ComponentType<
  Props & WrappedComponentProps
> = ({ messageStore, intl, isMobile, modalStore, layoutStore, routeStore }) => {
  const history = useHistory();
  const {
    contacts,
    createConversation,
    getContacts,
    hasMoreContacts: hasMore,
    isSearchingContacts: isSearching,
    isContactsLoading: isLoading,
    isContactsLoadingMore: isLoadingMore,
    selectedContacts,
    selectContacts,
    unSelectAllContacts,
    selectAllContacts,
    isComposing,
    contactsSearchTerm,
    setContactsSearchTerm,
    contactsFilterTypes,
    isConversationsLoadingMore,
    isSearchingConversations,
    isContactsLoadingMore,
    isSearchingContacts,
    isBulkMessageView,
    setIsBulkMessageView,
    getLoyalFansContacts,
    resetActiveConversation,
    setIsComposing,
    setCanCurrentMemberReceiveMsg,
  } = messageStore!;
  const { getSiteRouteURL } = routeStore!;

  const { openPrimaryModal } = modalStore!;
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [openLoyalFansModal, setOpenLoyalFansModal] = useState<boolean>(false);
  const { isMobileDevice } = layoutStore!;
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);

  const cellCache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 60,
  });

  const loadMoreContacts = useCallback(() => {
    if (!isLoading) {
      getContacts(true);
    }
  }, [isLoading, getContacts]);

  const selectContactAndCreateConversation = useCallback(
    async (contact: MessageCenterContactDetails, multiple?: boolean) => {
      selectContacts(contact, multiple);
      await createConversation();
      setCanCurrentMemberReceiveMsg(contact.can_receive_message);
    },
    [selectContacts, createConversation]
  );

  const handleContactClick = useCallback(
    (contact: MessageCenterContactDetails, multiple?: boolean) => {
      if (isBulkMessageView) {
        selectContacts(contact, true);
        setCanCurrentMemberReceiveMsg(true);
      } else {
        setCanCurrentMemberReceiveMsg(contact.can_receive_message);
        if (isMobileDevice) {
          history.replace(
            `${getSiteRouteURL(AppCommonRouteKey.messages)}${getSiteRouteURL(
              AppCommonRouteKey.compose
            )}`
          );
        }
        selectContactAndCreateConversation(contact, multiple);
      }
    },
    [selectedContacts, selectContacts, selectContactAndCreateConversation]
  );

  const hasContacts = useMemo(
    () => contacts && Array.isArray(contacts) && contacts.length > 0,
    [contacts]
  );

  const isLoadingDefaultContactList = useMemo(
    () =>
      isLoading &&
      !isLoadingMore &&
      !contactsFilterTypes.length &&
      !contactsSearchTerm,

    [isLoading, contactsFilterTypes, contactsSearchTerm]
  );

  const isSearchBoxDisabled = useMemo(
    () =>
      isConversationsLoadingMore ||
      isSearchingConversations ||
      isContactsLoadingMore ||
      isSearchingContacts,
    [
      isConversationsLoadingMore,
      isSearchingConversations,
      isContactsLoadingMore,
      isSearchingContacts,
    ]
  );

  const onOpenLoyalFansModal = () => {
    if (isMobileDevice) {
      openPrimaryModal(<LoyalFansModal isMobileDevice={isMobileDevice} />);
    } else {
      setOpenLoyalFansModal(true);
    }
  };

  const handleComposeClick = useCallback(async () => {
    await createConversation();
    // if (activeConversation) {
    //   readConversation(activeConversation?.id);
    // }
    setIsComposing(false);
  }, [createConversation, setIsComposing]);

  useEffect(() => {
    if (selectAllChecked && selectedContacts.length < contacts.length) {
      setSelectAllChecked(false);
    } else if (
      !selectAllChecked &&
      selectedContacts.length >= contacts.length
    ) {
      setSelectAllChecked(true);
    }

    if (selectedContacts.length > 1 && isBulkMessageView) {
      createConversation();
    } else {
      resetActiveConversation();
    }
  }, [selectedContacts, contacts]);

  useEffect(() => {
    resetActiveConversation();

    return () => {
      setIsBulkMessageView(false);
    };
  }, []);

  return (
    <TailwindFlex display='flex' flexDirection='flex-col'>
      {/* Search and Filter */}
      {CookieStorage.get("bulkMessage") && (
        <TailwindFlex
          backgroundColor='bg-lightBlue'
          padding={["p-3"]}
          borderWidth={isBulkMessageView ? [] : ["border-b-2"]}
          borderColor={"border-primary-bg-color"}
          alignItems={"items-center"}
          position={"relative"}>
          {isBulkMessageView ? (
            <TailwindFlex
              alignItems={"items-center"}
              justifyContent={"justify-center"}>
              <TailwindTranslatedText
                descriptor={{
                  id: "message-center.bulk-msg-heading",
                  defaultMessage: "Send bulk message to my most loyal fans",
                }}
              />
              <TailwindButton
                width='w-1'
                margin={["mt-1", "ml-2"]}
                padding={["p-1"]}
                height={"h-0.5"}
                display='inline-flex'
                fullWidth={false}
                onClick={onOpenLoyalFansModal}
                onMouseEnter={onOpenLoyalFansModal}>
                <TailwindIcon
                  width='w-1'
                  margin={["ml-1"]}
                  name={"question-mark"}
                />
              </TailwindButton>
              {openLoyalFansModal && !isMobileDevice && (
                <LoyalFansModal setOpenLoyalFansModal={setOpenLoyalFansModal} />
              )}
            </TailwindFlex>
          ) : (
            <TailwindFlex
              alignItems={"items-center"}
              onClick={() => {
                getLoyalFansContacts();
                unSelectAllContacts();
                setIsBulkMessageView(true);
              }}>
              <TailwindBox
                padding={["p-0"]}
                borderRadius={"rounded-full"}
                width={"w-12"}
                height={"h-12"}
                display={"inline-flex"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                backgroundColor={"bg-avatar-primary-bg-color"}>
                <TailwindIcon
                  name='people-icon'
                  fontSize='text-xl'
                  alignItems={"items-center"}
                  textColor={"text-white"}
                />
              </TailwindBox>
              <TailwindTranslatedText
                margin={["ml-3"]}
                descriptor={{
                  id: "message-center.bulk-msg-btn",
                  defaultMessage: "Bulk message my loyal fans",
                }}
              />
            </TailwindFlex>
          )}
        </TailwindFlex>
      )}
      {isComposing && !isBulkMessageView && (
        <TailwindFlex
          padding={["p-3"]}
          borderWidth={
            !isLoading && contacts?.length
              ? ["border-t-2"]
              : ["border-t-2", "border-b-2"]
          }
          borderColor={"border-primary-bg-color"}
          backgroundColor='bg-quaternary-bg-color'>
          <MessageCenterSearchBox
            placeholder={intl.formatMessage({
              id: "verbiage.contacts",
              defaultMessage: "Contacts",
            })}
            setSearchTerm={setContactsSearchTerm}
            searchTerm={contactsSearchTerm}
            disabled={isSearchBoxDisabled}
            isComposing={isComposing}
            isMobile={isMobile}
          />
          <TailwindFlex
            margin={["ml-2"]}
            width={"w-auto"}
            justifyContent='justify-center'
            alignItems='items-center'>
            <MessageContactListFilter isMobile={isMobileDevice} />
          </TailwindFlex>
        </TailwindFlex>
      )}
      {/* Search and Filter */}

      <TailwindFlex
        ref={scrollContainerRef}
        flexDirection={"flex-col"}
        width={"w-full"}
        height={"h-full"}
        maxHeight={"max-h-full"}
        borderRadius={"rounded-b-xl"}
        backgroundColor={"bg-quaternary-bg-color"}>
        {isBulkMessageView && selectedContacts && selectedContacts.length > 0 && (
          <TailwindFlex
            padding={["px-4", "pt-3", "pb-2"]}
            borderColor={"border-primary-bg-color"}
            flexDirection={"flex-row"}>
            <TailwindFlex width={"w-auto"}>
              <TailwindCheckboxRadio
                borderColor='border-main-color'
                boxProps={{
                  margin: ["mx-4"],
                  flexDirection: "flex-col",
                  textAlign: "text-center",
                }}
                onChange={() => {
                  if (selectAllChecked) {
                    setSelectAllChecked(false);
                    unSelectAllContacts();
                  } else {
                    setSelectAllChecked(true);
                    selectAllContacts();
                  }
                }}
                isChecked={selectAllChecked}
                type={TailwindInputType.radio}
                labelProps={{
                  margin: ["m-0"],
                  textColor: "text-main-color",
                  lineHeight: "leading-snug",
                }}
                label={"All"}
              />
            </TailwindFlex>
            <TailwindFlex alignItems='items-center' margin={["ml-4"]}>
              {selectedContacts.length} Selected
            </TailwindFlex>
          </TailwindFlex>
        )}

        {isLoadingDefaultContactList ? (
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={60}
                deferredMeasurementCache={cellCache}
                rowRenderer={({ index, key, style, parent }) => {
                  return (
                    <CellMeasurer
                      key={key}
                      cache={cellCache}
                      parent={parent}
                      columnIndex={0}
                      rowIndex={index}>
                      <div style={style}>
                        <MessageCenterContact
                          key={key}
                          isMobile={isMobile}
                          contact={contacts[index]}
                          onClick={handleContactClick}
                          preventSendBulkMsg={
                            !contacts[index].can_receive_bulk_message &&
                            isBulkMessageView
                          }
                          isSelected={
                            selectedContacts
                              ? selectedContacts.includes(
                                  contacts[index]?.id || ""
                                )
                              : false
                          }
                        />
                      </div>
                    </CellMeasurer>
                  );
                }}
                rowCount={contacts.length}
              />
            )}
          </AutoSizer>
        ) : !isLoading && hasContacts ? (
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={60}
                deferredMeasurementCache={cellCache}
                rowRenderer={({ index, key, parent, style }) => {
                  return (
                    <CellMeasurer
                      key={key}
                      cache={cellCache}
                      parent={parent}
                      columnIndex={0}
                      rowIndex={index}>
                      <div style={style}>
                        <MessageCenterContact
                          key={key}
                          isMobile={isMobile}
                          contact={contacts[index]}
                          onClick={handleContactClick}
                          preventSendBulkMsg={
                            !contacts[index].can_receive_bulk_message &&
                            isBulkMessageView
                          }
                          isSelected={
                            selectedContacts
                              ? selectedContacts.includes(
                                  contacts[index]?.id || ""
                                )
                              : false
                          }
                        />
                      </div>
                    </CellMeasurer>
                  );
                }}
                rowCount={contacts.length}
              />
            )}
          </AutoSizer>
        ) : (
          (isLoading || isSearching) &&
          !isLoadingMore && (
            <TailwindFlex height='h-full' justifyContent='justify-center'>
              <LoadingResourceSpinner
                name={intl.formatMessage({
                  id: "verbiage.contacts",
                  defaultMessage: "Contacts",
                })}
              />
            </TailwindFlex>
          )
        )}

        {!isLoading && !isSearching && !hasContacts && (
          <TailwindFlex
            height={"h-vh-full"}
            alignItems={"items-baseline"}
            justifyContent={"justify-center"}
            padding={["pt-3"]}>
            {intl.formatMessage({
              id: "message-center.no-contacts",
              defaultMessage: "There are currently no contacts in your list",
            })}
            .
          </TailwindFlex>
        )}
        {hasMore && !isLoading && !isLoadingMore && (
          <TailwindFlex
            alignItems={"items-center"}
            height={"h-full"}
            justifyContent={"justify-center"}>
            <TailwindButton
              justifyContent={"justify-center"}
              fullWidth
              onClick={loadMoreContacts}>
              {intl.formatMessage({
                id: "common.load-more",
                defaultMessage: "Load More",
              })}
            </TailwindButton>
          </TailwindFlex>
        )}
        {isMobile && selectedContacts.length > 1 ? (
          <TailwindFlex
            height='h-full'
            padding={["py-4"]}
            flexDirection='flex-col'
            alignItems={"items-center"}
            justifyContent={"justify-end"}>
            <TailwindButton
              fullWidth={false}
              fontWeight={"font-bold"}
              padding={["px-8"]}
              backgroundColor='bg-modal-button-color'
              textColor='text-go-live-text'
              justifyContent={"justify-center"}
              onClick={handleComposeClick}>
              {intl.formatMessage({
                id: "message-center.compose",
                defaultMessage: "Compose",
              })}
            </TailwindButton>
          </TailwindFlex>
        ) : null}
        {isLoadingMore && !hasContacts && <LoadingResourceSpinner />}
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject(
    "messageStore",
    "modalStore",
    "layoutStore",
    "routeStore"
  )(observer(MessageCenterContactList))
);
