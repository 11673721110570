import React from "react";
import "./styles.scss";
import classnames from "classnames";

type GiftIconProps = {
  className?: string;
};

const GiftIcon: React.ComponentType<GiftIconProps> = ({ className }) => (
  <span className={classnames("GiftIcon", className && className)}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      version='1.2'
      overflow='visible'
      preserveAspectRatio='none'
      viewBox='0 0 26 26'>
      <g transform='translate(1, 1)'>
        <g>
          <path
            d='M13.67,17.12c0.01,0.16-0.06,0.31-0.19,0.4c-0.14,0.1-0.31,0.15-0.48,0.14h-2c-0.17,0.01-0.34-0.04-0.48-0.14  c-0.13-0.09-0.2-0.24-0.19-0.4V9.67h3.33L13.67,17.12L13.67,17.12L13.67,17.12z M8.21,8C7.82,7.61,7.82,6.98,8.2,6.59  c0.19-0.19,0.45-0.3,0.72-0.3C9.2,6.28,9.46,6.4,9.64,6.61l1.31,1.68h-2C8.67,8.3,8.41,8.2,8.21,8z M16.08,7.29c0,0.55-0.45,1-1,1  h-2l1.3-1.68c0.18-0.21,0.44-0.33,0.72-0.32c0.55,0,1,0.45,1,1l0,0H16.08z M20,10c0.01-0.18-0.13-0.32-0.31-0.33  c-0.01,0-0.01,0-0.02,0h-4.59c1.29,0,2.33-1.04,2.33-2.33c0.01-1.29-1.03-2.33-2.32-2.34c0,0-0.01,0-0.01,0  c-0.68-0.03-1.33,0.27-1.75,0.8L12,7.52l-1.33-1.71C10.25,5.27,9.6,4.97,8.92,5C7.63,5,6.59,6.04,6.59,7.33c0,0,0,0.01,0,0.01  c0,1.29,1.04,2.33,2.33,2.33H4.34C4.16,9.66,4.01,9.79,4,9.97C4,9.98,4,9.99,4,10v3.33c-0.01,0.18,0.13,0.32,0.31,0.33  c0.01,0,0.01,0,0.02,0h1V18c0,0.55,0.45,1,1,1h11.33c0.55,0,1-0.45,1-1v-4.33h1c0.18,0.01,0.33-0.12,0.34-0.3c0-0.01,0-0.03,0-0.04  V10L20,10z'
            id='gift_1606882146761'
            vectorEffect='non-scaling-stroke'
          />
        </g>
      </g>
    </svg>
  </span>
);

export default GiftIcon;
