import React from "react";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";

export type MediaManagerMediaCardFooterPriceProps = {
  priceInToken: number | null;
};

const MediaManagerMediaCardFooterPrice: React.ComponentType<MediaManagerMediaCardFooterPriceProps> = ({
  priceInToken,
}) => {
  if (!priceInToken) {
    return null;
  }

  return (
    <TailwindFlex
      width={"w-auto"}
      padding={["px-2", "py-1"]}
      backgroundColor={"bg-gray-600"}
      borderRadius={"rounded-3xl"}>
      <TailwindText
        textColor={"text-white"}
        display={"flex"}
        fontSize={"text-base"}
        pseudoClasses={["md:text-xs"]}
        alignItems={"items-center"}>
        <TailwindIcon 
          height={"h-5"} 
          width={"w-5"}
          margin={["mr-1"]} 
          textColor={"text-white"} 
          name={CustomIconName.token} />
        {priceInToken}
      </TailwindText>
    </TailwindFlex>
  );
};

export default MediaManagerMediaCardFooterPrice;
