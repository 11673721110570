import React, { useMemo } from "react";
import TailwindBox, { TailwindBoxProps } from "../box";
import TailwindIcon, { TailwindIconProps } from "../icon";
import TailwindText, { TailwindTextProps } from "../text";
import {
  TBackgroundColor,
  TFontSize,
  THeight,
  TPadding,
} from "../../../styles/tailwind-classnames";

export type TailwindPillProps = TailwindBoxProps & {
  leftIconProps?: TailwindIconProps;
  rightIconProps?: TailwindIconProps;
  textProps?: TailwindTextProps;
  backgroundColor?: TBackgroundColor;
  padding?: TPadding[];
  size?: "xxs" | "xs" | "sm" | "normal";
};

const TailwindPill: React.ComponentType<TailwindPillProps> = ({
  leftIconProps,
  rightIconProps,
  textProps,
  backgroundColor = "bg-gray-900",
  padding = [],
  size = "xs",
  children,
  ...props
}) => {
  const sizeClasses = useMemo(() => {
    const classes: {
      height: THeight;
      fontSize: TFontSize;
      padding: TPadding[];
    } = {
      height: "h-5",
      fontSize: "text-xs",
      padding: ["px-2", "py-1"],
    };
    switch (size) {
      case "xxs": {
        classes.height = "h-auto";
        classes.fontSize = "text-xs";
        classes.padding = [];
        break;
      }
      case "xs": {
        classes.height = "h-5";
        classes.fontSize = "text-xs";
        classes.padding = ["px-2", "py-4"];
        break;
      }
      case "sm": {
        classes.height = "h-6";
        classes.fontSize = "text-sm";
        classes.padding = ["px-4", "py-2"];
        break;
      }
      case "normal": {
        classes.height = "h-8";
        classes.fontSize = "text-base";
        classes.padding = ["px-6", "py-3"];
        break;
      }
    }

    return classes;
  }, [size]);

  return (
    <TailwindBox
      {...props}
      backgroundColor={backgroundColor}
      borderRadius={"rounded-full"}
      height={sizeClasses?.height}
      padding={[...sizeClasses?.padding, ...padding]}
      display={"inline-flex"}
      alignItems={"items-center"}
      justifyContent={"justify-center"}>
      {leftIconProps && (
        <TailwindIcon
          {...{
            ...leftIconProps,
            fontSize: sizeClasses?.fontSize,
            margin: ["mr-1"],
            display: "inline-flex",
            alignItems: "items-center",
          }}
        />
      )}
      <TailwindText {...{ ...textProps, fontSize: sizeClasses?.fontSize }}>
        {children}
      </TailwindText>
      {rightIconProps && (
        <TailwindIcon
          {...{
            ...rightIconProps,
            fontSize: sizeClasses?.fontSize,
            margin: ["ml-2"],
            display: "inline-flex",
            alignItems: "items-center",
          }}
        />
      )}
    </TailwindBox>
  );
};

export default TailwindPill;
