import React from "react";
import "./styles.scss";
import clsx from "clsx";

type QuestionMarkIconProps = {
  className?: string;
};

const GuidelinesIcon: React.ComponentType<QuestionMarkIconProps> = ({
  className,
}) => (
  <span className={clsx("QuestionMarkIconKebab", className && className)}>
    <svg 
      version="1.2" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlnsXlink="http://www.w3.org/1999/xlink" 
      overflow="visible" 
      preserveAspectRatio="none" 
      viewBox="0 0 26 26" 
      xmlSpace="preserve" 
      height="24" 
      width="24" 
      y="0px" 
      x="0px" 
      id="Layer_1_1709659050924"
    >
      <g transform="translate(1, 1)">
        <path 
          d="M11.961,0.199C5.5,0.199,0.244,5.456,0.244,11.917c0,6.462,5.257,11.717,11.718,11.717  c6.462,0,11.718-5.255,11.718-11.717C23.68,5.456,18.424,0.199,11.961,0.199z M11.961,22.234c-5.689,0-10.318-4.628-10.318-10.317  S6.272,1.6,11.961,1.6c5.689,0,10.318,4.628,10.318,10.317S17.65,22.234,11.961,22.234z M11.194,15.739h1.64v1.89h-1.64V15.739z   M15.533,9.167c0,1.592-1.064,2.73-2.805,3.056l0.004,1.709h-1.479l-0.008-2.684l0.22-0.222l0.221-0.071  c1.511-0.034,2.379-0.665,2.379-1.734c0-0.978-0.747-1.642-1.815-1.642c-0.862,0-1.58,0.389-2.257,1.222L9.75,9.103L8.688,8.214  l0.235-0.292c0.953-1.189,1.988-1.719,3.355-1.719C14.164,6.204,15.533,7.45,15.533,9.167z"
          fill="#333333" 
          vectorEffect="non-scaling-stroke" 
          style={{ fill: "rgb(92, 92, 92)" }}
        />
      </g>
    </svg>
  </span>
);

export default GuidelinesIcon;
