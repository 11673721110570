import cookie from "js-cookie";

export interface CookieOptions {
  path?: string;
  expires?: number | Date;
  domain?: string;
  secure?: boolean;
  sameSite?: string;
}

export default class CookieStorage {
  static set(key: string, value: any, options?: CookieOptions): void {
    options ? cookie.set(key, value, options) : cookie.set(key, value);
  }

  static setPostMessage(key: string, value: any): void {
    window["top"]?.postMessage(
      {
        action: "cookies_set",
        key,
        value,
      },
      "*"
    );
  }

  static remove(key: string): void {
    cookie.remove(key);
  }

  static removePostMessage(key: string): void {
    window["top"]?.postMessage(
      {
        action: "cookies_remove",
        key,
      },
      "*"
    );
  }

  static get(key: string, json = true): any {
    return json ? cookie.getJSON(key) : cookie.get(key);
  }

  static getPostMessage(key: string): void {
    window["top"]?.postMessage(
      {
        action: "cookies_get",
        key,
      },
      "*"
    );
  }

  static getAll(): any {
    return cookie.get();
  }

  static getAllPostMessage(): void {
    window["top"]?.postMessage(
      {
        action: "cookies_getAll",
      },
      "*"
    );
  }
}
