import React, { useEffect } from "react";
import AuthStore from "core/stores/auth/AuthStore";
import { inject, observer } from "mobx-react";
import { usePrevious } from "library/core/utility/hooks";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindHeading from "library/components/_tailwind/heading";
import { history } from "library/core/utility";

type LogoutProps = {
  authStore?: AuthStore;
};

const LogoutContainer: React.ComponentType<LogoutProps> = ({ authStore }) => {
  const { logout, logoutInProgress } = authStore!;
  const previousLogoutInProgress = usePrevious(logoutInProgress);

  useEffect(() => {
    logout();
  }, []);

  useEffect(() => {
    if (previousLogoutInProgress && !logoutInProgress) {
      history.push("/");
    }
  }, [previousLogoutInProgress, logoutInProgress]);

  if (logoutInProgress) {
    return (
      <TailwindFlex
        flex={"flex-1"}
        alignItems={"items-center"}
        justifyContent={"justify-center"}>
        <TailwindHeading level={4}>Logging out, please wait...</TailwindHeading>
      </TailwindFlex>
    );
  }

  return null;
};

export default inject("authStore")(observer(LogoutContainer));
