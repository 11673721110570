import React, { useState } from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindCard from "../../components/_tailwind/card";
import TailwindIcon from "../../components/_tailwind/icon";
import TailwindFlex from "../../components/_tailwind/flex";
import { CustomIconName } from "../../components/_tailwind/icon/icons/enums";
import TailwindSwitch from "../../components/_tailwind/switch";
import TailwindPill from "../../components/_tailwind/pill";
import TailwindDropdown from "../../components/_tailwind/dropdown";
import TailwindButton from "../../components/_tailwind/button";
import TailwindText from "../../components/_tailwind/text";
import TailwindBox from "../../components/_tailwind/box";

type Props = {};

const StorybookComponentsKinksSearchBar: React.ComponentType<Props> = ({}) => {
  const [isMoreFilterCardOpen, setIsMoreFilterCardOpen] =
    useState<boolean>(false);

  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Kinks Search Bar</StorybookHeading>
      <StorybookParagraph>
        These components are so far only used on AFF but will be used in other
        projects later.
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`const [isMoreFilterCardOpen, setIsMoreFilterCardOpen] = useState<boolean>(
          false
        );

        <TailwindCard
          backgroundColor={"primary"}
          width={"w-full"}
          padding={["px-3", "py-3"]}
          borderRadius={"rounded-2xl"}
          boxShadow={"shadow-md"}
        >
          <TailwindFlex>
            <TailwindFlex flexGrow={"flex-grow"} margin={["mx-4"]}>
              <TailwindBox margin={["mr-2"]}>
                <TailwindDropdown
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Man",
                      value: "man",
                    },
                    {
                      label: "Woman",
                      value: "woman",
                    },
                    {
                      label: "Group",
                      value: "group",
                    },
                    {
                      label: "Couple",
                      value: "couple(man/woman)",
                    },
                    {
                      label: "Couple(man/woman)",
                      value: "couple-both",
                    },
                    {
                      label: "Couple(2 women)",
                      value: "couple-2-women",
                    },
                    {
                      label: "Couple(2 men)",
                      value: "couple-2-men",
                    },
                    {
                      label: "TS/TV/TG",
                      value: "ts-tv-tg",
                    },
                  ]}
                  value={[]}
                  name={"search-type"}
                  multipleProps={{
                    placeholder: "I am looking for",
                  }}
                />
              </TailwindBox>
              <TailwindBox margin={["mx-2"]}>
                <TailwindDropdown
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "20-30",
                      value: "20-30",
                    },
                    {
                      label: "30-40",
                      value: "30-40",
                    },
                    {
                      label: "40-50",
                      value: "40-50",
                    },
                  ]}
                  value={"20-30"}
                  name={"between-age"}
                />
              </TailwindBox>
              <TailwindBox margin={["mx-2"]}>
                <TailwindDropdown
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Living Nearby",
                      value: "near",
                    },
                    {
                      label: "California, USA",
                      value: "california",
                    },
                    {
                      label: "Colorado, USA",
                      value: "colorado",
                    },
                    {
                      label: "Florida, USA",
                      value: "florida",
                    },
                  ]}
                  value={"near"}
                  name={"living"}
                />
              </TailwindBox>
              <TailwindButton
                margin={["mx-2"]}
                display={"flex"}
                alignItems={"items-center"}
                justifyContent={"justify-between"}
                width={"w-52"}
                fullWidth={false}
                rightIconProps={{ name: CustomIconName["filter-narrow-down"] }}
                backgroundColor={"bg-gray-800"}
                textProps={{
                  fontSize: "text-sm",
                }}
                borderWidth={isMoreFilterCardOpen ? "border-2" : undefined}
                onClick={() => setIsMoreFilterCardOpen(!isMoreFilterCardOpen)}
              >
                More Filters
              </TailwindButton>
              <TailwindFlex
                margin={["mx-2"]}
                justifyContent={"justify-between"}
                alignItems={"items-center"}
                backgroundColor={"bg-gray-800"}
                width={"w-40"}
                borderRadius={"rounded-full"}
                padding={["pl-4", "pr-2"]}
              >
                <TailwindText fontSize={"text-sm"}>Kink Search</TailwindText>
                <TailwindSwitch
                  offBackgroundColor={"bg-gray-700"}
                  size={"sm"}
                  isChecked={false}
                  onChange={() => {}}
                />
              </TailwindFlex>
            </TailwindFlex>
            <TailwindFlex
              width={"w-10"}
              height={"h-10"}
              alignItems={"items-center"}
              justifyContent={"justify-center"}
              cursor={"cursor-pointer"}
            >
              <TailwindIcon name={"close"} fontSize={"text-sm"} />
            </TailwindFlex>
          </TailwindFlex>
          <TailwindFlex margin={["mx-4", "mt-2"]}>
            <TailwindPill
              margin={["mr-2"]}
              backgroundColor={"bg-black"}
              rightIconProps={{ name: "close" }}
              cursor={"cursor-pointer"}
            >
              Text
            </TailwindPill>
            <TailwindPill
              margin={["mr-2"]}
              backgroundColor={"bg-black"}
              rightIconProps={{ name: "close" }}
              cursor={"cursor-pointer"}
            >
              Couple(2 women)
            </TailwindPill>
            <TailwindPill
              margin={["mr-2"]}
              textColor={"text-pink-400"}
              cursor={"cursor-pointer"}
            >
              Clear All
            </TailwindPill>
          </TailwindFlex>
          {isMoreFilterCardOpen && (
            <React.Fragment>
              <TailwindBox
                margin={["m-4", "mt-6"]}
                display={"grid"}
                gridCols={"grid-cols-4"}
                gap={"gap-4"}
                position={"relative"}
                zIndex={"z-10"}
              >
                <TailwindDropdown
                  zIndex={"z-50"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Status",
                      value: "status",
                    },
                  ]}
                  value={"status"}
                  name={"status"}
                />
                <TailwindDropdown
                  zIndex={"z-50"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Race",
                      value: "race",
                    },
                  ]}
                  value={"race"}
                  name={"race"}
                />
                <TailwindDropdown
                  zIndex={"z-50"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Sexual Orientation",
                      value: "s-orientation",
                    },
                  ]}
                  value={"s-orientation"}
                  name={"s-orientation"}
                />
                <TailwindDropdown
                  zIndex={"z-50"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Body Type",
                      value: "body-type",
                    },
                  ]}
                  value={"body-type"}
                  name={"body-type"}
                />
                <TailwindDropdown
                  zIndex={"z-40"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Marital Status",
                      value: "marital-status",
                    },
                  ]}
                  value={"marital-status"}
                  name={"marital-status"}
                />
                <TailwindDropdown
                  zIndex={"z-40"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Swing Style",
                      value: "swing-style",
                    },
                  ]}
                  value={"swing-style"}
                  name={"swing-style"}
                />
                <TailwindDropdown
                  zIndex={"z-40"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Smoking",
                      value: "smoking",
                    },
                  ]}
                  value={"smoking"}
                  name={"smoking"}
                />
                <TailwindDropdown
                  zIndex={"z-40"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Alcohol",
                      value: "alcohol",
                    },
                  ]}
                  value={"alcohol"}
                  name={"alcohol"}
                />
                <TailwindDropdown
                  zIndex={"z-30"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Drugs",
                      value: "drugs",
                    },
                  ]}
                  value={"drugs"}
                  name={"drugs"}
                />
                <TailwindDropdown
                  zIndex={"z-30"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Language(s)",
                      value: "languages",
                    },
                  ]}
                  value={"languages"}
                  name={"languages"}
                />
                <TailwindDropdown
                  zIndex={"z-30"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Education",
                      value: "education",
                    },
                  ]}
                  value={"education"}
                  name={"education"}
                />
                <TailwindDropdown
                  zIndex={"z-30"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Religion",
                      value: "religion",
                    },
                  ]}
                  value={"religion"}
                  name={"religion"}
                />
                <TailwindDropdown
                  zIndex={"z-20"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Eye Color",
                      value: "eye-color",
                    },
                  ]}
                  value={"eye-color"}
                  name={"eye-color"}
                />
                <TailwindDropdown
                  zIndex={"z-20"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Glasses",
                      value: "glasses",
                    },
                  ]}
                  value={"glasses"}
                  name={"glasses"}
                />
                <TailwindDropdown
                  zIndex={"z-20"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Hair Color",
                      value: "hair-color",
                    },
                  ]}
                  value={"hair-color"}
                  name={"hair-color"}
                />
                <TailwindDropdown
                  zIndex={"z-20"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Endowment Length",
                      value: "endowment-length",
                    },
                  ]}
                  value={"endowment-length"}
                  name={"endowment-length"}
                />
                <TailwindDropdown
                  zIndex={"z-10"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Endowment Width",
                      value: "endowment-width",
                    },
                  ]}
                  value={"endowment-width"}
                  name={"endowment-width"}
                />
                <TailwindDropdown
                  zIndex={"z-10"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Circumcised",
                      value: "circumcised",
                    },
                  ]}
                  value={"circumcised"}
                  name={"circumcised"}
                />
                <TailwindDropdown
                  zIndex={"z-10"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Female breast size",
                      value: "female-breast-size",
                    },
                  ]}
                  value={"female-breast-size"}
                  name={"female-breast-size"}
                />
                <TailwindDropdown
                  zIndex={"z-10"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Have children",
                      value: "have-children",
                    },
                  ]}
                  value={"have-children"}
                  name={"have-children"}
                />
                <TailwindDropdown
                  zIndex={"z-0"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Wants children",
                      value: "wants-children",
                    },
                  ]}
                  value={"wants-children"}
                  name={"wants-children"}
                />
                <TailwindDropdown
                  zIndex={"z-0"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Willing to relocate",
                      value: "willing-to-relocate",
                    },
                  ]}
                  value={"willing-to-relocate"}
                  name={"willing-to-relocate"}
                />
                <TailwindDropdown
                  zIndex={"z-0"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Astrological sign",
                      value: "astrological-sign",
                    },
                  ]}
                  value={"astrological-sign"}
                  name={"astrological-sign"}
                />
                <TailwindDropdown
                  zIndex={"z-0"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}

                  items={[
                    {
                      label: "Chinese zodiac sign",
                      value: "chinese-zodiac-sign",
                    },
                  ]}
                  value={"chinese-zodiac-sign"}
                  name={"chinese-zodiac-sign"}
                />
              </TailwindBox>
              <TailwindFlex
                width={"w-auto"}
                justifyContent={"justify-end"}
                margin={["m-4"]}
              >
                <TailwindButton
                  borderRadius={"rounded-full"}
                  backgroundColor={"bg-gray-800"}
                  fullWidth={false}
                  width={"w-auto"}
                >
                  Cancel
                </TailwindButton>
                <TailwindButton
                  margin={["ml-2"]}
                  borderRadius={"rounded-full"}
                  backgroundColor={"bg-pink-400"}
                  fullWidth={false}
                  width={"w-auto"}
                >
                  Apply
                </TailwindButton>
              </TailwindFlex>
            </React.Fragment>
          )}
        </TailwindCard>`}
        backgroundColor={"bg-white"}>
        <TailwindCard
          backgroundColor={"primary"}
          width={"w-full"}
          padding={["px-3", "py-3"]}
          borderRadius={"rounded-2xl"}
          boxShadow={"shadow-md"}>
          <TailwindFlex>
            <TailwindFlex flexGrow={"flex-grow"} margin={["mx-4"]}>
              <TailwindBox margin={["mr-2"]}>
                <TailwindDropdown
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Man",
                      value: "man",
                    },
                    {
                      label: "Woman",
                      value: "woman",
                    },
                    {
                      label: "Group",
                      value: "group",
                    },
                    {
                      label: "Couple",
                      value: "couple(man/woman)",
                    },
                    {
                      label: "Couple(man/woman)",
                      value: "couple-both",
                    },
                    {
                      label: "Couple(2 women)",
                      value: "couple-2-women",
                    },
                    {
                      label: "Couple(2 men)",
                      value: "couple-2-men",
                    },
                    {
                      label: "TS/TV/TG",
                      value: "ts-tv-tg",
                    },
                  ]}
                  value={[]}
                  name={"search-type"}
                  multipleProps={{
                    placeholder: "I am looking for",
                  }}
                />
              </TailwindBox>
              <TailwindBox margin={["mx-2"]}>
                <TailwindDropdown
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "20-30",
                      value: "20-30",
                    },
                    {
                      label: "30-40",
                      value: "30-40",
                    },
                    {
                      label: "40-50",
                      value: "40-50",
                    },
                  ]}
                  value={"20-30"}
                  name={"between-age"}
                />
              </TailwindBox>
              <TailwindBox margin={["mx-2"]}>
                <TailwindDropdown
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Living Nearby",
                      value: "near",
                    },
                    {
                      label: "California, USA",
                      value: "california",
                    },
                    {
                      label: "Colorado, USA",
                      value: "colorado",
                    },
                    {
                      label: "Florida, USA",
                      value: "florida",
                    },
                  ]}
                  value={"near"}
                  name={"living"}
                />
              </TailwindBox>
              <TailwindButton
                margin={["mx-2"]}
                display={"flex"}
                alignItems={"items-center"}
                justifyContent={"justify-between"}
                width={"w-52"}
                fullWidth={false}
                rightIconProps={{ name: CustomIconName["filter-narrow-down"] }}
                backgroundColor={"bg-gray-800"}
                textProps={{
                  fontSize: "text-sm",
                }}
                borderWidth={isMoreFilterCardOpen ? ["border-2"] : undefined}
                onClick={() => setIsMoreFilterCardOpen(!isMoreFilterCardOpen)}>
                More Filters
              </TailwindButton>
              <TailwindFlex
                margin={["mx-2"]}
                justifyContent={"justify-between"}
                alignItems={"items-center"}
                backgroundColor={"bg-gray-800"}
                width={"w-40"}
                borderRadius={"rounded-full"}
                padding={["pl-4", "pr-2"]}>
                <TailwindText fontSize={"text-sm"}>Kink Search</TailwindText>
                <TailwindSwitch
                  offBackgroundColor={"bg-gray-700"}
                  size={"sm"}
                  isChecked={false}
                  onChange={() => {}}
                />
              </TailwindFlex>
            </TailwindFlex>
            <TailwindFlex
              width={"w-10"}
              height={"h-10"}
              alignItems={"items-center"}
              justifyContent={"justify-center"}
              cursor={"cursor-pointer"}>
              <TailwindIcon name={"close"} fontSize={"text-sm"} />
            </TailwindFlex>
          </TailwindFlex>
          <TailwindFlex margin={["mx-4", "mt-2"]}>
            <TailwindPill
              margin={["mr-2"]}
              backgroundColor={"bg-black"}
              rightIconProps={{ name: "close" }}
              cursor={"cursor-pointer"}>
              Text
            </TailwindPill>
            <TailwindPill
              margin={["mr-2"]}
              backgroundColor={"bg-black"}
              rightIconProps={{ name: "close" }}
              cursor={"cursor-pointer"}>
              Couple(2 women)
            </TailwindPill>
            <TailwindPill
              margin={["mr-2"]}
              textColor={"text-pink-400"}
              cursor={"cursor-pointer"}>
              Clear All
            </TailwindPill>
          </TailwindFlex>
          {isMoreFilterCardOpen && (
            <React.Fragment>
              <TailwindBox
                margin={["m-4", "mt-6"]}
                display={"grid"}
                gridCols={"grid-cols-4"}
                gap={"gap-4"}
                position={"relative"}
                zIndex={"z-10"}>
                <TailwindDropdown
                  zIndex={"z-50"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Status",
                      value: "status",
                    },
                  ]}
                  value={"status"}
                  name={"status"}
                />
                <TailwindDropdown
                  zIndex={"z-50"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Race",
                      value: "race",
                    },
                  ]}
                  value={"race"}
                  name={"race"}
                />
                <TailwindDropdown
                  zIndex={"z-50"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Sexual Orientation",
                      value: "s-orientation",
                    },
                  ]}
                  value={"s-orientation"}
                  name={"s-orientation"}
                />
                <TailwindDropdown
                  zIndex={"z-50"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Body Type",
                      value: "body-type",
                    },
                  ]}
                  value={"body-type"}
                  name={"body-type"}
                />
                <TailwindDropdown
                  zIndex={"z-40"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Marital Status",
                      value: "marital-status",
                    },
                  ]}
                  value={"marital-status"}
                  name={"marital-status"}
                />
                <TailwindDropdown
                  zIndex={"z-40"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Swing Style",
                      value: "swing-style",
                    },
                  ]}
                  value={"swing-style"}
                  name={"swing-style"}
                />
                <TailwindDropdown
                  zIndex={"z-40"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Smoking",
                      value: "smoking",
                    },
                  ]}
                  value={"smoking"}
                  name={"smoking"}
                />
                <TailwindDropdown
                  zIndex={"z-40"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Alcohol",
                      value: "alcohol",
                    },
                  ]}
                  value={"alcohol"}
                  name={"alcohol"}
                />
                <TailwindDropdown
                  zIndex={"z-30"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Drugs",
                      value: "drugs",
                    },
                  ]}
                  value={"drugs"}
                  name={"drugs"}
                />
                <TailwindDropdown
                  zIndex={"z-30"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Language(s)",
                      value: "languages",
                    },
                  ]}
                  value={"languages"}
                  name={"languages"}
                />
                <TailwindDropdown
                  zIndex={"z-30"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Education",
                      value: "education",
                    },
                  ]}
                  value={"education"}
                  name={"education"}
                />
                <TailwindDropdown
                  zIndex={"z-30"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Religion",
                      value: "religion",
                    },
                  ]}
                  value={"religion"}
                  name={"religion"}
                />
                <TailwindDropdown
                  zIndex={"z-20"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Eye Color",
                      value: "eye-color",
                    },
                  ]}
                  value={"eye-color"}
                  name={"eye-color"}
                />
                <TailwindDropdown
                  zIndex={"z-20"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Glasses",
                      value: "glasses",
                    },
                  ]}
                  value={"glasses"}
                  name={"glasses"}
                />
                <TailwindDropdown
                  zIndex={"z-20"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Hair Color",
                      value: "hair-color",
                    },
                  ]}
                  value={"hair-color"}
                  name={"hair-color"}
                />
                <TailwindDropdown
                  zIndex={"z-20"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Endowment Length",
                      value: "endowment-length",
                    },
                  ]}
                  value={"endowment-length"}
                  name={"endowment-length"}
                />
                <TailwindDropdown
                  zIndex={"z-10"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Endowment Width",
                      value: "endowment-width",
                    },
                  ]}
                  value={"endowment-width"}
                  name={"endowment-width"}
                />
                <TailwindDropdown
                  zIndex={"z-10"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Circumcised",
                      value: "circumcised",
                    },
                  ]}
                  value={"circumcised"}
                  name={"circumcised"}
                />
                <TailwindDropdown
                  zIndex={"z-10"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Female breast size",
                      value: "female-breast-size",
                    },
                  ]}
                  value={"female-breast-size"}
                  name={"female-breast-size"}
                />
                <TailwindDropdown
                  zIndex={"z-10"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Have children",
                      value: "have-children",
                    },
                  ]}
                  value={"have-children"}
                  name={"have-children"}
                />
                <TailwindDropdown
                  zIndex={"z-0"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Wants children",
                      value: "wants-children",
                    },
                  ]}
                  value={"wants-children"}
                  name={"wants-children"}
                />
                <TailwindDropdown
                  zIndex={"z-0"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Willing to relocate",
                      value: "willing-to-relocate",
                    },
                  ]}
                  value={"willing-to-relocate"}
                  name={"willing-to-relocate"}
                />
                <TailwindDropdown
                  zIndex={"z-0"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Astrological sign",
                      value: "astrological-sign",
                    },
                  ]}
                  value={"astrological-sign"}
                  name={"astrological-sign"}
                />
                <TailwindDropdown
                  zIndex={"z-0"}
                  fullWidth
                  focusBorderColor={"2xl:border-white"}
                  backgroundColor={"bg-gray-800"}
                  items={[
                    {
                      label: "Chinese zodiac sign",
                      value: "chinese-zodiac-sign",
                    },
                  ]}
                  value={"chinese-zodiac-sign"}
                  name={"chinese-zodiac-sign"}
                />
              </TailwindBox>
              <TailwindFlex
                width={"w-auto"}
                justifyContent={"justify-end"}
                margin={["m-4"]}>
                <TailwindButton
                  borderRadius={"rounded-full"}
                  backgroundColor={"bg-gray-800"}
                  fullWidth={false}
                  width={"w-auto"}>
                  Cancel
                </TailwindButton>
                <TailwindButton
                  margin={["ml-2"]}
                  borderRadius={"rounded-full"}
                  backgroundColor={"bg-pink-400"}
                  fullWidth={false}
                  width={"w-auto"}>
                  Apply
                </TailwindButton>
              </TailwindFlex>
            </React.Fragment>
          )}
        </TailwindCard>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsKinksSearchBar;
