import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";

interface Props {}

const OBSModalTabStep2: React.FunctionComponent<Props & WrappedComponentProps> = () => {
  return (
    <TailwindTranslatedText
        paragraphProps={{
          margin: ["mb-2"],
        }}
        descriptor={{
          id: "broadcast-obs-modal.step-2-body",
          defaultMessage:
          "Next, download OBS and install it on your computer. Click {here} to learn how to install OBS software.",
        }}
        values= {{here: "<a class='text-indigo-700' href='https://help.streamray.com/models/knowledgebase/how-to-use-obs-for-broadcasting-in-hd-high-definition/' target='new'> here </a>",}}
      />
  );
};

export default injectIntl(OBSModalTabStep2);
