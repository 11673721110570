import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import MediaManagerSidebarHeader from "containers/_components/media-manager/media-manager-sidebar-header";
import MediaManagerSidebarBody from "containers/_components/media-manager/media-manager-sidebar-body";

type Props = {};

const MediaManagerSidebar: React.ComponentType<Props> = ({}) => {
  return (
    <TailwindFlex
      margin={["mb-8"]}
      justifyContent={"justify-start"}
      alignItems={"items-center"}
      maxWidth={"max-w-sm"}
      pseudoClasses={["md:max-w-full"]}>
      <TailwindFlex
        padding={["pt-3", "px-4"]}
        flexDirection={"flex-col"}
        width={"w-full"}>
        <MediaManagerSidebarHeader />
        <MediaManagerSidebarBody />
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default MediaManagerSidebar;
