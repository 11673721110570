import React from "react";
import TailwindButton from "library/components/_tailwind/button";

type Props = {
  dataTestId?: string;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  label: string;
  fullWidth?: boolean;
  fullFillButton?: boolean;
};

const BroadcastTippingControlActionButton: React.ComponentType<Props> = ({
  dataTestId,
  disabled,
  onClick,
  fullWidth = true,
  label,
  fullFillButton = false,
}) => {
  return (
    <TailwindButton
      data-testid={dataTestId}
      data-is-enabled={!disabled}
      disabled={disabled}
      disabledProps={
        disabled
          ? {
              textColor: "text-pink-400",
              backgroundColor: "bg-transparent",
            }
          : undefined
      }
      rounded={false}
      fullWidth={fullWidth}
      backgroundColor={fullFillButton ? "bg-pink-400" : "bg-transparent"}
      borderColor={disabled ? "border-pink-300" : "border-pink-400"}
      borderWidth={["border-2"]}
      textAlign={'text-center'}
      textColor={fullFillButton ? "text-white" : "text-pink-400"}
      alignItems={"items-center"}
      justifyContent={"justify-center"}
      textProps={{
        textTransform: "capitalize",
      }}
      lineHeight={"leading-none"}
      onClick={onClick}>
      {label}
    </TailwindButton>
  );
};

export default BroadcastTippingControlActionButton;
