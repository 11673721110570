import React, { useCallback, useMemo } from "react";
import Emoji from "react-emoji-render";
import { MessageCenterConversationDetails } from "./stores/messages/types";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import { inject, observer } from "mobx-react";
// import TailwindIcon from "library/components/_tailwind/icon";
import classnames from "classnames";
import MessageStore from "./stores/messages/MessageStore";
import { TPseudoClasses } from "library/styles/tailwind-classnames";
import Avatar from "library/components/avatar";

type Props = {
  notice: MessageCenterConversationDetails;
  readNotice: (
    article: MessageCenterConversationDetails,
    multiple?: boolean
  ) => void;
  selectNotice?: (
    article: MessageCenterConversationDetails,
    multiple?: boolean
  ) => void;
  isActive?: boolean;
  messageStore?: MessageStore;
};

const MessageCenterNewsAndPolicy: React.ComponentType<Props> = ({
  notice,
  readNotice,
  isActive,
}) => {
  const onClickConversation = useCallback(() => {
    readNotice(notice);
  }, [readNotice]);

  const classNameValues = useMemo(() => {
    if (!notice.unread_count && !isActive)
      return [
        "first:rounded-t-xl",
        "bg-primary-bg-color",
        "hover:bg-highlight-color",
      ] as TPseudoClasses[];

    if (isActive) ["hover:bg-highlight-color"] as TPseudoClasses[];

    return ["hover:bg-highlight-color"] as TPseudoClasses[];
  }, [isActive, notice]);

  return (
    <TailwindFlex
      padding={["px-4"]}
      backgroundColor={
        isActive
          ? "bg-card-secondary-bg-color"
          : notice.unread_count
          ? "bg-highlight-color"
          : "bg-quaternary-bg-color"
      }
      pseudoClasses={classNameValues}
      onClick={onClickConversation}>
      <TailwindFlex
        borderWidth={["border-b-2"]}
        borderColor={"border-primary-bg-color"}
        padding={["py-3"]}>
        <TailwindFlex width={"w-auto"} padding={["pr-1"]}>
          {/* <TailwindIcon
          fontSize={"text-lg"}
          textColor={"text-yellow-300"}
          name={"star"}></TailwindIcon> */}
          <Avatar
            username={notice?.participants?.[0].username}
            size={"small"}
            backgroundColor={"bg-modal-button-color"}
          />
        </TailwindFlex>
        <TailwindFlex
          width={"w-auto"}
          margin={["ml-4"]}
          flexGrow={"flex-grow"}
          flexShrink={"flex-shrink"}
          alignItems={"items-center"}
          justifyContent={"justify-start"}>
          <TailwindFlex width={"w-9/12"} flexDirection={"flex-col"}>
            <TailwindFlex
              fontWeight={
                isActive || notice.unread_count ? "font-bold" : "font-normal"
              }
              maxWidth={"max-w-sm"}
              pseudoClasses={["md:max-w-lg"]}>
              <Emoji className={classnames("truncate")} text={notice.title} />
            </TailwindFlex>
          </TailwindFlex>

          <TailwindFlex
            width={"w-3/12"}
            alignItems={"items-center"}
            justifyContent={"justify-end"}>
            <TailwindText
              fontSize={"text-xs"}
              textColor={"text-gray-500"}
              textAlign={"text-right"}>
              {notice.start_date}
            </TailwindText>
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};
export default inject("messageStore")(observer(MessageCenterNewsAndPolicy));
