import React from "react";
import "./styles.scss";
import classnames from "classnames";

type HeartOutlineIconProps = {
  className?: string;
};

const HeartOutlineIcon: React.ComponentType<HeartOutlineIconProps> = ({
  className,
}) => (
  <span className={classnames("HeartOutlineIcon", className && className)}>
    <svg 
      version="1.2" 
      overflow="visible" 
      preserveAspectRatio="none" 
      viewBox="0 -3.496527777777775 53 53" 
      width="14" 
      height="14">
        <path 
          d="M26.8,46l-1.9-1.5c-11.3-8.7-18.1-13.9-22-20.7c-2.9-5-3.6-10-2.2-14.3C1.9,5.9,4.5,3,8.2,1.3C10,0.4,12.1,0,14.2,0  c5.1,0,9.9,2.5,12.5,6.5C29.4,2.5,34.2,0,39.3,0c2.1,0,4.2,0.4,6.1,1.3C49,3,51.7,5.9,52.9,9.5c1.4,4.3,0.6,9.3-2.2,14.3  c-3.9,6.8-10.7,12.1-22,20.7L26.8,46z M14.2,2C12.4,2,10.6,2.4,9,3.1c-3.1,1.4-5.4,3.9-6.4,7c-1.2,3.8-0.5,8.2,2.1,12.7  C8.3,29.3,15,34.5,26.1,43l0.7,0.5l0.6-0.5c11.1-8.5,17.8-13.7,21.5-20.2c2.6-4.5,3.3-8.9,2.1-12.7c-1-3.1-3.3-5.6-6.4-7  C42.9,2.4,41.2,2,39.3,2c-5,0-9.7,2.8-11.6,6.9l-0.9,2l-0.9-2C23.9,4.8,19.2,2,14.2,2z"/>
        <g/>
    </svg>
  </span>
);

export default HeartOutlineIcon;
