import React, { useCallback, useState } from "react";
import Joyride, {
  CallBackProps,
  STATUS,
  EVENTS,
  Step,
  TooltipRenderProps,
} from "react-joyride";
import ShowcaseTooltip from "./tooltip";
import { Props as FloaterType } from "react-floater";
import {
  TMaxWidth,
  TMinWidth,
  TPseudoClasses,
  TWidth,
} from "../../../styles/tailwind-classnames";

export type ShowcaseStep = Step & {
  maxWidth?: TMaxWidth;
  minWidth?: TMinWidth;
  width?: TWidth;
  pseudoClasses?: TPseudoClasses[];
  isLastStep?: boolean;
};

type ShowcaseProps = {
  onFinish?: () => void;
  onSkipped?: (step: number) => void;
  onBeforeStep?: (step: number) => void;
  steps: ShowcaseStep[];
  run?: boolean;
  enableDoneButton?: boolean;
  scrollOffset?: number;
  disableScrolling?: boolean;
  floaterProps?: FloaterType;
  disableOverlay?: boolean;
  showCloseButton?: boolean;
  showSkipButton?: boolean;
  hideBackButton?: boolean;
  hideLastStepNextButton?: boolean;
  showStep?: boolean;
};

const Showcase: React.ComponentType<ShowcaseProps> = ({
  onFinish,
  onSkipped,
  onBeforeStep,
  steps,
  run,
  scrollOffset,
  enableDoneButton = false,
  disableScrolling = false,
  disableOverlay = false,
  floaterProps,
  showCloseButton = false,
  showSkipButton = false,
  hideBackButton = true,
  hideLastStepNextButton = false,
  showStep=false
}) => {
  const [showcaseOn, setShowcaseOn] = useState<boolean>(true);
  const handleJoyrideCallback = useCallback((data: CallBackProps) => {
    const { status, type, index } = data;

    if (type === EVENTS.STEP_BEFORE) {
      onBeforeStep?.(index);
    }

    if (STATUS.FINISHED === status && type === "tour:end") {
      setShowcaseOn(false);
      onFinish?.();
    } else if (STATUS.SKIPPED === status) {
      setShowcaseOn(false);
      onSkipped?.(index);
    }
  }, []);

  const joyRideTooltip = ({
    index,
    isLastStep,
    size,
    step,
    backProps,
    primaryProps,
    skipProps,
    tooltipProps,
  }: TooltipRenderProps) => {
    return (
      <ShowcaseTooltip
        index={index}
        isLastStep={isLastStep}
        size={size}
        step={step}
        backProps={backProps}
        primaryProps={primaryProps}
        skipProps={skipProps}
        tooltipProps={tooltipProps}
        showSkipButton={showSkipButton}
        hideBackButton={hideBackButton}
        showCloseButton={showCloseButton}
        enableDoneButton={enableDoneButton}
        hideLastStepNextButton={hideLastStepNextButton}
        showStep={showStep}
      />
    );
  };

  return (
    <Joyride
      tooltipComponent={joyRideTooltip}
      callback={handleJoyrideCallback}
      run={run || showcaseOn}
      disableScrollParentFix={true}
      disableScrolling={disableScrolling}
      disableOverlay={disableOverlay}
      scrollOffset={scrollOffset || 20}
      floaterProps={floaterProps}
      steps={steps}
      styles={{
        options: {
          zIndex: 9999,
          arrowColor: "#fff",
          beaconSize: 30,
        },
      }}
    />
  );
};

export default Showcase;
