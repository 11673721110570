import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindTabs from "../../components/_tailwind/tabs";
import TailwindParagraph from "../../components/_tailwind/paragraph";

type Props = {};

const StorybookComponentsTabs: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Tabs</StorybookHeading>
      <StorybookParagraph>
        Tabs component is a collection of several components, such as tab-body
        and tab-header & tab-button which can be used alone or as altogether
        under the name of TailwindTabs component. The main tab component manages
        state of the tabs. It is not finished yet but Here are the props it
        takes:
      </StorybookParagraph>
      <Code>{`tabs: Tab[];
activeTabIndex?: number;
onChange?: (activeTabIndex: number) => void;
headerProps?: TailwindTabHeaderProps;
bodyProps?: TailwindTabBodyProps;

export type Tab = {
    id: string
    label: string
    component: React.ReactElement
}
`}</Code>
      <StorybookParagraph>Here are some examples:</StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindTabs
          tabs={[
            {
              id: "1",
              label: "Tab 1",
              component: <TailwindParagraph>Tab 1</TailwindParagraph>,
            },
            {
              id: "2",
              label: "Tab 2",
              component: <TailwindParagraph>Tab 2</TailwindParagraph>,
            },
          ]}
        />`}
      >
        <TailwindTabs
          tabs={[
            {
              id: "1",
              label: "Tab 1",
              component: <TailwindParagraph>Tab 1</TailwindParagraph>,
            },
            {
              id: "2",
              label: "Tab 2",
              component: <TailwindParagraph>Tab 2</TailwindParagraph>,
            },
          ]}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindTabs
          headerProps={{
            buttonProps: {
              backgroundColor: "bg-yellow-200",
              textColor: "text-red-500",
            },
            activeButtonProps: {
              backgroundColor: "bg-yellow-900",
              textColor: "text-red-500",
            },
          }}
          tabs={[
            {
              id: "1",
              label: "Tab 1",
              component: <TailwindParagraph>Tab 1</TailwindParagraph>,
            },
            {
              id: "2",
              label: "Tab 2",
              component: <TailwindParagraph>Tab 2</TailwindParagraph>,
            },
          ]}
        />`}
      >
        <TailwindTabs
          headerProps={{
            buttonProps: {
              backgroundColor: "bg-yellow-200",
              textColor: "text-red-500",
            },
            activeButtonProps: {
              backgroundColor: "bg-yellow-900",
              textColor: "text-red-500",
            },
          }}
          tabs={[
            {
              id: "1",
              label: "Tab 1",
              component: <TailwindParagraph>Tab 1</TailwindParagraph>,
            },
            {
              id: "2",
              label: "Tab 2",
              component: <TailwindParagraph>Tab 2</TailwindParagraph>,
            },
          ]}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindTabs
          headerProps={{
            buttonProps: {
              backgroundColor: "bg-yellow-200",
              textColor: "text-red-500",
            },
            activeButtonProps: {
              backgroundColor: "bg-yellow-900",
              textColor: "text-red-500",
            },
          }}
          bodyProps={{
            backgroundColor: "bg-yellow-500",
            padding: ["p-4"],
          }}
          tabs={[
            {
              id: "1",
              label: "Tab 1",
              component: <TailwindParagraph>Tab 1</TailwindParagraph>,
            },
            {
              id: "2",
              label: "Tab 2",
              component: <TailwindParagraph>Tab 2</TailwindParagraph>,
            },
          ]}
        />`}
      >
        <TailwindTabs
          headerProps={{
            buttonProps: {
              backgroundColor: "bg-yellow-200",
              textColor: "text-red-500",
            },
            activeButtonProps: {
              backgroundColor: "bg-yellow-900",
              textColor: "text-red-500",
            },
          }}
          bodyProps={{
            backgroundColor: "bg-yellow-500",
            padding: ["p-4"],
          }}
          tabs={[
            {
              id: "1",
              label: "Tab 1",
              component: <TailwindParagraph>Tab 1</TailwindParagraph>,
            },
            {
              id: "2",
              label: "Tab 2",
              component: <TailwindParagraph>Tab 2</TailwindParagraph>,
            },
          ]}
        />
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsTabs;
