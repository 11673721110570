import { IChatToken } from "./interfaces";
import { logger } from "library/core/utility";
import { authStore, profileStore } from "core/stores";
import axios from "core/config/axios";
const CHAT_TOKEN_REFETCH_TIMEOUT = 2 * 60 * 1000;

const logPrefix = "[NodeChatSocket]:";

export default class NodeChatToken {
  isLoadingChatToken: boolean = false;
  chatToken: IChatToken | null = null;
  currentChatTokenIdentity: string | null = null;
  lastRefreshTimestamp: number | null = null;

  constructor() {
   
  }

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  timePassed = (value: number) => {
    return (
      this.lastRefreshTimestamp &&
      new Date().getTime() - this.lastRefreshTimestamp > value
    );
  };

  get = async (msg?: string, forceRefresh: boolean = false) => {
    this.log("get received message", msg);
    const shouldRefresh =
      forceRefresh || this.timePassed(CHAT_TOKEN_REFETCH_TIMEOUT);
    try {
      this.log(
        "get found user logged in?",
        authStore?.isLoggedIn,
        this.currentChatTokenIdentity,
        shouldRefresh
      );
      if (!authStore?.isLoggedIn) {
        if (this.currentChatTokenIdentity !== "guest" || shouldRefresh) {
          this.currentChatTokenIdentity = "guest";
          const { data } = await axios.get(
            "/models/me/chat-token/",
            {}
          );
          this.lastRefreshTimestamp = new Date().getTime();
          this.chatToken = data;
        } else {
          if(this.currentChatTokenIdentity !== "guest") {
            this.chatToken = null;
          }
        }
      } else {
        if (
          shouldRefresh || (this.currentChatTokenIdentity !== profileStore.modelProfile.id)
        ) {
          this.currentChatTokenIdentity = profileStore.modelProfile.id;

          const { data } = await axios.get(
            "/models/me/chat-token/",
            {}
          );
          this.lastRefreshTimestamp = new Date().getTime();
          this.chatToken = data;
        }
      }
    } catch {
      this.chatToken = null;
    } 
    return this.chatToken;
    
  };
}
