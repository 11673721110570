import React, { useCallback } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import {
  createPeriodDateObject,
  getSelectedPeriodValue,
} from "../../_stores/earnings/utils";
import { isWithinInterval } from "date-fns";
import { dateProcess } from "library/core/utility";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindDropdown, {
  TailwindDropdownValue,
} from "library/components/_tailwind/dropdown";
import TailwindButton from "library/components/_tailwind/button";
import EarningsStore from "../../_stores/earnings/EarningsStore";
import { PAY_PERIOD_YEARS } from "../../_stores/earnings/consts";
import { PayPeriod } from "../../_stores/earnings/types";
import ModalStore from "library/core/stores/modal";
import PeriodEarningsModal from "../../period-earning-summary-modal";

type FiltersProps = {
  earningsStore?: EarningsStore;
  modalStore?: ModalStore;
};

const StudioPayoutHeaderFilters: React.FunctionComponent<
  FiltersProps & WrappedComponentProps
> = ({ intl, earningsStore, modalStore }) => {
  const {
    selectedPeriod,
    setStudioSelectedPeriodYear,
    periods,
    setStudioSelectedPeriodDay,
  } = earningsStore!;
  const { openPrimaryModal } = modalStore!;
  const getPeriodLabel = (period: PayPeriod) =>
    isWithinInterval(dateProcess.today, {
      start: createPeriodDateObject(period, "start"),
      end: createPeriodDateObject(period, "end"),
    })
      ? intl.formatMessage({
          id: "common.currentPeriod",
          defaultMessage: "Current Period",
        })
      : getSelectedPeriodValue(period);

  const onChangePeriodYear = useCallback((value: TailwindDropdownValue) => {
    setStudioSelectedPeriodYear(value as number);
  }, []);

  const onOpenPeriodEarningsModalClicked = useCallback(() => {
    openPrimaryModal(<PeriodEarningsModal />);
  }, []);

  const onChangePayPeriod = useCallback((value: TailwindDropdownValue) => {
    if (value) {
      setStudioSelectedPeriodDay(value as string);
    }
  }, []);

  return (
    <TailwindFlex pseudoClasses={["md:flex-col"]} margin={["mt-4"]}>
      <TailwindFlex>
        <TailwindFlex width={"w-auto"} md={"w-full"}>
          <TailwindDropdown
            pseudoClasses={["md:w-full"]}
            backgroundColor={"primary"}
            value={selectedPeriod?.startYear}
            label={intl.formatMessage({
              id: "common.year",
              defaultMessage: "Year",
            })}
            labelProps={{
              fontSize: "text-sm",
              margin: ["m-0", "mb-1"],
            }}
            name={"studio-payout-year"}
            onChange={onChangePeriodYear}
            items={PAY_PERIOD_YEARS.map(item => ({
              value: item,
              label: item.toString(),
            }))}
          />
        </TailwindFlex>
        <TailwindFlex
          width={"w-auto"}
          md={"w-full"}
          margin={["ml-4"]}
          pseudoClasses={["md:ml-0"]}>
          <TailwindDropdown
            pseudoClasses={["md:w-full"]}
            backgroundColor={"primary"}
            value={getSelectedPeriodValue(selectedPeriod)}
            label={intl.formatMessage({
              id: "common.payPeriod",
              defaultMessage: "Pay Period",
            })}
            labelProps={{
              fontSize: "text-sm",
              margin: ["m-0", "mb-1"],
            }}
            name={"studio-payout-pay-period"}
            onChange={onChangePayPeriod}
            items={periods.map(period => ({
              value: getSelectedPeriodValue(period as PayPeriod),
              label: getPeriodLabel(period as PayPeriod),
            }))}
          />
        </TailwindFlex>
      </TailwindFlex>
      <TailwindButton
        alignSelf={"self-end"}
        backgroundColor={"bg-blue-400"}
        fullWidth={false}
        height={"h-12"}
        rounded={false}
        textProps={{
          textAlign: "text-center",
          textColor: "text-white",
          fontWeight: "font-normal",
          lineHeight: "leading-4",
        }}
        justifyContent={"justify-center"}
        onClick={onOpenPeriodEarningsModalClicked}>
        {intl.formatMessage({
          id: "studioPayout.earning.periodEarningSummary",
          defaultMessage: "PERIOD EARNING SUMMARY",
        })}
      </TailwindButton>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject("earningsStore", "modalStore")(observer(StudioPayoutHeaderFilters))
);
