import React, { FunctionComponent, useMemo } from "react";
import { observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import "../styles.scss";
import LandingCover from "./landing-cover";
import LandingSlider1 from "./sliders/slider1";
import LandingSlider2 from "./sliders/slider2";
import LandingSlider3 from "./sliders/slider3";
import TailwindFlex from "library/components/_tailwind/flex";
import Carousel from "library/components/carousel";
import { openRegisterModal } from "core/stores/auth/utils";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindParagraph from "library/components/_tailwind/paragraph";
import TailwindBox from "library/components/_tailwind/box";

type CamsModelLandingContainerProps = {};

const CamsModelLandingContainer: FunctionComponent<
  CamsModelLandingContainerProps & WrappedComponentProps
> = ({ intl }) => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <TailwindFlex
      className={["Landing"]}
      overflow={["overflow-y-auto"]}
      flexDirection={"flex-col"}
      height={"h-full"}
      width={"w-full"}>
      <div className='Landing__highlight'>
        <div className={"Landing__img-slider"}>
          <Carousel showProgress={false} autoSlide fullHeight showNav>
            {[
              {
                photoUrl:
                  "https://secureimage.securedataimages.com/images/streamray/cover/slide1.jpg",
                content: (
                  <LandingSlider1 openRegistrationModal={openRegisterModal} />
                ),
              },
              {
                photoUrl:
                  "https://secureimage.securedataimages.com/images/streamray/cover/slide2-4.jpg",
                content: (
                  <LandingSlider2 openRegistrationModal={openRegisterModal} />
                ),
              },
              {
                photoUrl:
                  "https://secureimage.securedataimages.com/images/streamray/cover/slide3-1.png",
                content: (
                  <LandingSlider3 openRegistrationModal={openRegisterModal} />
                ),
              },
            ].map((slider, index) => (
              <div
                key={index}
                style={{
                  background: `url(${slider.photoUrl}) no-repeat top center`,
                  height: "381px",
                }}>
                {slider.content}
              </div>
            ))}
          </Carousel>
        </div>
        <TailwindBox
          minHeight={"min-h-0"}
          width={"w-full"}
          textAlign={"text-center"}
          padding={["p-10"]}
          backgroundColor={"bg-primary-bg-color"}>
          <TailwindFlex
            className={["LandingJoinBanner__content"]}
            flexDirection={"flex-col"}
            alignItems={"items-center"}
            margin={["mx-auto", "my-5"]}>
            <TailwindBox>
              <TailwindTranslatedText
                paragraphProps={{
                  padding: ["pb-5"],
                  fontSize: "text-lg",
                }}
                descriptor={{
                  id: "landing.textBlock1",
                  defaultMessage:
                    "Broadcast on the award winning website that combines new cutting edge technology, a world-class support team and millions of visitors each month! It’s fun. It’s easy. It’s safe. It’s Streamray.com.",
                }}
              />
              <TailwindParagraph>
                <TailwindTranslatedText
                  fontSize={"text-lg"}
                  descriptor={{
                    id: "landing.textBlock2.1",
                    defaultMessage:
                      "When you perform as a Streamray model, you have complete control over who sees you as you can restrict viewers by member name, country and even US state.  Also, model usernames are 100% anonymous so what are you waiting for? ",
                  }}
                />
                <TailwindTranslatedText
                  fontSize={"text-lg"}
                  textColor={"text-yellow-500"}
                  cursor={"cursor-pointer"}
                  onClick={openRegisterModal}
                  descriptor={{
                    id: "landing.textBlock2.2",
                    defaultMessage: "Sign up",
                  }}
                />
                <TailwindTranslatedText
                  fontSize={"text-lg"}
                  descriptor={{
                    id: "landing.textBlock2.3",
                    defaultMessage: " now and start making money right now.",
                  }}
                />
              </TailwindParagraph>
            </TailwindBox>
          </TailwindFlex>
        </TailwindBox>
        <LandingCover />
      </div>
      <footer className='Landing__footer'>
        <TailwindBox
          padding={["px-6"]}
          pseudoClasses={["sm:px-2", "md:px-4"]}
          className={["Landing__footer--copyright textCenter"]}>
          <p>
            18 U.S.C. 2257{" "}
            {intl.formatMessage({
              id: "landing.recordKeeping",
              defaultMessage:
                "Record Keeping Requirements Compliance Statement",
            })}
          </p>
          <p>
            © 1999-{currentYear}{" "}
            {intl.formatMessage({
              id: "landing.streamrayInc",
              defaultMessage: "Streamray Inc. All Rights Reserved",
            })}
          </p>
        </TailwindBox>
      </footer>
    </TailwindFlex>
  );
};

export default injectIntl(observer(CamsModelLandingContainer));
