import { inject, observer } from "mobx-react";
import React, { useCallback } from "react";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import { BroadcastStreamType } from "common/broadcast/_stores/broadcast-stream/enums";
import TailwindButton from "library/components/_tailwind/button";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import LanguageStore from "library/core/stores/language/LanguageStore";

type Props = {
  broadcastStreamStore?: BroadcastStreamStore;
  broadcastStore?: BroadcastStore;
  languageStore?: LanguageStore;
};

const BroadcastStreamTypeSwitcher: React.FunctionComponent<
  Props
> = ({
  broadcastStreamStore,
  broadcastStore,
  languageStore
  //intl,
}) => {
  const { streamType, setStreamType, mediaStream, stopWebRTCBroadcast } =
    broadcastStreamStore!;

  const { intl } = languageStore!;

  const { isShowStarted } = broadcastStore!;
  const onClickChangeStreamType = useCallback(async () => {
    if (mediaStream) {
      await stopWebRTCBroadcast();
    }

    await setStreamType(
      streamType === BroadcastStreamType.WEBRTC
        ? BroadcastStreamType.OBS
        : BroadcastStreamType.WEBRTC
    );
  }, [streamType, stopWebRTCBroadcast, mediaStream]);

  if (!streamType || isShowStarted) {
    return null;
  }

  return (
    <TailwindButton
      data-testid={"stream-type-switcher"}
      data-current-stream-type={streamType}
      className={[`BroadcastPreview__streamTypeSwitcher`]}
      onClick={onClickChangeStreamType}
      size={"sm"}
      fullWidth={false}
      position={"absolute"}
      inset={["bottom-2", "left-2"]}
      backgroundColor={"primary"}>
      {streamType === BroadcastStreamType.WEBRTC
        ? intl.formatMessage({
          id: 'broadcast.obs-instead',
          defaultMessage: "Use OBS instead"
        })
        : intl.formatMessage({
          id: 'broadcast.webcam-instead'
        })}
    </TailwindButton>
  );
};

export default 
  inject("broadcastStreamStore", "broadcastStore", "languageStore")(observer(BroadcastStreamTypeSwitcher));
