import React from "react";
import "./styles.scss";
import classnames from "classnames";

type PauseIconProps = {
  className?: string;
};

const PauseIcon: React.ComponentType<PauseIconProps> = ({ className }) => (
  <span className={classnames("PauseIcon", className && className)}>
    <svg
      width='100%'
      height='100%'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      version='1.2'
      overflow='visible'
      preserveAspectRatio='none'
      x='0px'
      y='0px'
      viewBox='0 0 32 32'>
      <g>
        <path
          d='M16,0c-8.832,0 -16,7.168 -16,16c0,8.832 7.168,16 16,16c8.832,0 16,-7.168 16,-16c0,-8.832 -7.168,-16 -16,-16zM14.4,22.4h-3.2v-12.8h3.2zM20.8,22.4h-3.2v-12.8h3.2z'
          vectorEffect='non-scaling-stroke'
        />
      </g>
    </svg>
  </span>
);

export default PauseIcon;
