import classNames from "classnames";
import TailwindIcon from "library/components/_tailwind/icon";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import { inject, observer } from "mobx-react";
import React, { useCallback } from "react";
import BroadcastTapeButton from "../broadcast-tape-button";
import BroadcastStreamStore from "../_stores/broadcast-stream/BroadcastStreamStore";
import { BroadcastStreamType } from "../_stores/broadcast-stream/enums";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";

type Props = {
  broadcastStreamStore?: BroadcastStreamStore;
  broadcastStore?: BroadcastStore;
  layoutStore?: LayoutStore;
};

const BroadcastSettingButtonsComponent = ({
  broadcastStore,
  broadcastStreamStore,
  layoutStore
}: Props) => {
  const { streamType, setIsMicrophoneMuted, isMicrophoneMuted } =
    broadcastStreamStore!;
  const { showVideoSettingsOverlay } = broadcastStore!;
  const { isLiteMode } = layoutStore!;

  const onClickMuteMicrophone = useCallback(() => {
    setIsMicrophoneMuted(!isMicrophoneMuted);
  }, [isMicrophoneMuted]);
  


  return (
    <span
      className={`BroadcastSettingButtons ${isLiteMode ? "BroadcastSettingButtons--litemode" : ""} absolute z-50 flex justify-end`}
      style={{ right: isLiteMode ? "10px" : "5px", top: isLiteMode ? "10px" : "0.25rem" }}>
      {streamType == BroadcastStreamType.WEBRTC && (
        <BroadcastTapeButton
          dataTestId={
            isMicrophoneMuted 
              ? "broadcast-unmute-stream" 
              : "broadcast-mute-stream"
          }
          className={["mr-1"]}
          fullWidth={false}
          width={"w-8"}
          onClick={onClickMuteMicrophone}>
          <TailwindIcon
            fontSize={"text-xl"}
            className={classNames(
              isMicrophoneMuted
                ? "text-red-500 CustomIcon__muted"
                : "text-green-500",
              "CustomIcon__shadow"
            )}
            name={"mic"}
          />
        </BroadcastTapeButton>
      )}
      {streamType == BroadcastStreamType.WEBRTC && (
        <BroadcastTapeButton
          dataTestId={"broadcast-video-settings"}
          fullWidth={false}
          disabled={!streamType}
          width={"w-8"}
          onClick={showVideoSettingsOverlay}>
          <TailwindIcon
            fontSize={"text-base"}
            className={classNames(
              "CustomIcon__shadow",
              !streamType ? "text-gray-500" : "text-black"
            )}
            name={"settings_extra"}
          />
        </BroadcastTapeButton>
      )}
    </span>
  );
};

export const BroadcastSettingButtons = inject(
  "broadcastStreamStore",
  "broadcastStore",
  "layoutStore"
)(observer(BroadcastSettingButtonsComponent));
