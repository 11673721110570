import React from "react";
import TailwindText, { TailwindTextProps } from "../text";
import { CustomFormatConfig, FormattedNumber } from "react-intl";
import { UnifiedNumberFormatOptions } from "@formatjs/intl-unified-numberformat";
export type TailwindTranslatedNumberProps = UnifiedNumberFormatOptions &
  CustomFormatConfig & {
    value: number;
  } & {
    textProps?: TailwindTextProps;
  };
const TailwindTranslatedNumber: React.ComponentType<TailwindTranslatedNumberProps> = ({
  textProps,
  ...props
}) => {
  return (
    <TailwindText {...textProps}>
      <FormattedNumber {...props} />
    </TailwindText>
  );
};
export default TailwindTranslatedNumber;
