import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import "./styles.scss";
import { STATIC_PATHS } from "library/core/config/urls";
import TailwindPill, {
  TailwindPillProps,
} from "library/components/_tailwind/pill";
import TailwindBox from "library/components/_tailwind/box";
import TailwindCard from "library/components/_tailwind/card";

type ContestsContainerProps = {
  hideCover?: boolean;
  pill?: TailwindPillProps;
  banner?: string;
  copyright?: boolean;
  summary: string;
  title: string;
};

const ContestCard: React.ComponentType<
  ContestsContainerProps & WrappedComponentProps
> = ({ children, banner, copyright, title, summary, pill, intl }) => {
  return (
    <TailwindCard className={["ContestCard"]}>
      {pill && (
        <TailwindBox
          position={"absolute"}
          zIndex={"z-10"}
          style={{ top: "20px", left: "20px" }}>
          <TailwindPill {...pill} />
        </TailwindBox>
      )}
      {banner && (
        <div className={"ContestCard__cover"}>
          <img src={STATIC_PATHS.BANNERS + banner} alt={title} />
        </div>
      )}
      {!!copyright && (
        <div className='ContestCard__copyright'>
          {intl.formatMessage({
            id: "landing.createAccount.individualsPictured",
            defaultMessage:
              "Individuals pictured are models and are used for illustrative purposes",
          })}
        </div>
      )}

      <div className={"ContestCard__head"}>
        <h5 className={"half uppercase colorStrong"}>{title}</h5>
        <p className={"body-1 colorTertiary"}>{summary}</p>
      </div>
      <div className={"ContestCard__body"}>{children}</div>
    </TailwindCard>
  );
};

export default injectIntl(ContestCard);
