import React from "react";
import "./styles.scss";
import classnames from "classnames";

type SearchIconAltProps = {
  className?: string;
};

const SearchIconAlt: React.ComponentType<SearchIconAltProps> = ({
  className,
}) => (
  <span className={classnames("SearchIconAlt", className && className)}>
    <svg version='1.2' preserveAspectRatio='none' viewBox='0 0 19 18'>
      <g transform='translate(0, 0)'>
        <path
          d='M8.00012,0c-3.2358,0 -6.15295,1.94929 -7.39108,4.93884c-1.23813,2.98955 -0.5534,6.43057 1.73489,8.71839c2.28829,2.28782 5.72945,2.97186 8.71875,1.73311c2.9893,-1.23874 4.93799,-4.15629 4.93733,-7.39209c-0.00539,-4.41571 -3.58417,-7.99376 -7.99988,-7.99825zM8,14.85713c-3.7871,0 -6.85714,-3.07005 -6.85714,-6.85714c0,-3.7871 3.07005,-6.85714 6.85714,-6.85714c3.7871,0 6.85714,3.07005 6.85714,6.85714c-0.00514,3.78496 -3.07218,6.852 -6.85714,6.85714z'
          strokeWidth='0'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          fill='rgb(92, 92, 92)'
          vectorEffect='non-scaling-stroke'
        />
        <path
          d='M8.00012,0c-3.2358,0 -6.15295,1.94929 -7.39108,4.93884c-1.23813,2.98955 -0.5534,6.43057 1.73489,8.71839c2.28829,2.28782 5.72945,2.97186 8.71875,1.73311c2.9893,-1.23874 4.93799,-4.15629 4.93733,-7.39209c-0.00539,-4.41571 -3.58417,-7.99376 -7.99988,-7.99825zM8,14.85713c-3.7871,0 -6.85714,-3.07005 -6.85714,-6.85714c0,-3.7871 3.07005,-6.85714 6.85714,-6.85714c3.7871,0 6.85714,3.07005 6.85714,6.85714c-0.00514,3.78496 -3.07218,6.852 -6.85714,6.85714z'
          vectorEffect='non-scaling-stroke'
        />
      </g>
      <g transform='translate(-1, -1)'>
        <path
          d='M14.619852036785524 14.063339626702275 C14.619852036785524 14.063339626702275 13.850386140112603 14.823520147064562 13.850386140112603 14.823520147064562 C13.850386140112603 14.823520147064562 18.467181520150135 19.38460326923827 18.467181520150135 19.38460326923827 C18.467181520150135 19.38460326923827 19.236647416823057 18.624422748875986 19.236647416823057 18.624422748875986 C19.236647416823057 18.624422748875986 14.619852036785524 14.063339626702275 14.619852036785524 14.063339626702275 Z'
          vectorEffect='non-scaling-stroke'
        />
      </g>
    </svg>
  </span>
);

export default SearchIconAlt;
