import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import EarningsTableRow from "./earnings-table-row";
import { inject, observer } from "mobx-react";
import EarningsStore from "common/earnings/_stores/earnings/EarningsStore";
import TailwindSpinner from "library/components/_tailwind/spinner";

type Props = {
  earningsStore?: EarningsStore;
};

const EarningsTableBody: React.ComponentType<Props> = ({ earningsStore }) => {
  const { currentPeriodTotals, loadingEarnings } = earningsStore!;

  if (loadingEarnings) {
    return (
      <TailwindFlex
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        width={"w-full"}
        height={"h-full"}>
        <TailwindSpinner />
      </TailwindFlex>
    );
  }

  if (!currentPeriodTotals) {
    return (
      <TailwindFlex
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        width={"w-full"}
        height={"h-full"}
        padding={["p-5"]}>
        There are no earnings for the selected period.
      </TailwindFlex>
    );
  }

  return (
    <TailwindFlex
      flexDirection={"flex-col"}
      overflow={["overflow-y-auto", "overflow-x-hidden"]}>
      {currentPeriodTotals.map((totalPeriod, index) => (
        <EarningsTableRow
          key={`${totalPeriod.group}-${index}`}
          periodTotal={totalPeriod}
        />
      ))}
    </TailwindFlex>
  );
};

export default inject("earningsStore")(observer(EarningsTableBody));
