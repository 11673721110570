import { MODEL_TRANSACTION_EARNING_TYPE, TransactionProductSubType } from "./enums";

export type StudioEarnings = {
  model_username: string;
  broadcast_hours: number;
  earned_tokens?: any;
  earnings?: {
    bonus_tokens: number;
    chargeback_tokens: number;
    fan_club_earned: number;
    fan_club_received: number;
    media_tokens_earned: number;
    media_tokens_received: number;
    member_bounty_referrals_earned: number;
    member_bounty_referrals_received: number;
    member_referral_to_other_models_earned: number;
    member_referral_to_other_models_received: number;
    member_referrals_earned: number;
    member_referrals_received: number;
    model_referral_earned: number;
    model_referral_received: number;
    tiered_tokens_earned: number;
    tiered_tokens_received: number;
  };
  amounts: {
    tiered_tokens_earned: number;
    bonus_tokens: number;
    chargeback_tokens: number;
    fan_club_earned: number;
  };
  tier?: number;
  member_spent_tokens?: number;
  period_total_earnings: number;
};

export type StudioPeriodEarning = {
  start_time: string;
  end_time: string;
  period_total_earnings: number;
};

export type StudioEarningsPeriodSum = {
  total_bonus_tokens: number;
  total_broadcast_hours: number;
  total_chargeback_tokens: number;
  total_fan_club_earned: number;
  total_media_tokens_earned: number;
  total_member_bounty_referrals_earned: number;
  total_member_referrals_earned: number;
  total_model_referral_earned: number;
  total_period_earnings: number;
  total_tiered_tokens_earned: number;
};

export type StudioPayment = {
  date: string;
  amount: number;
};

export type PayPeriod = {
  startDay: number;
  startMonth: number;
  startYear: number;
  endDay: number;
  endMonth: number;
  endYear: number;
  period: string;
};

export type CurrentPeriodSums = {
  memberSpentTotal: string;
  tieredPayoutTotal: string;
  payoutAdjustment: string;
  bonusMemberSpendTotal: string;
  bonusPayoutTotal: string;
  grandTotal: string;
  tier?: string;
  tieredRefundTotal: string;
};

export type CurrentPeriodTotals = {
  group: string;
  header?: boolean;
  youMake?: MODEL_TRANSACTION_EARNING_TYPE;
  category?: string;
  tokens?: number;
  cost?: number;
  yourCut?: number | string;
  earnings: number;
};


export type Transaction = {
  id?: number;
  amount?: number;
  amount_tokens?: number;
  member_spent_usd: number;
  member_spent_tokens: number;
  period_id?: number;
  broadcast?: string | null;
  broadcast_end?: string | null;
  broadcast_start?: string | null;
  created_date: string;
  website: string;
  member_id?: string;
  member_username: string;
  product: string;
  location?: string;
  note?: string;
  transaction_type: string;
  percentage?: string;
  earning?: number;
  earning_usd: number;
  refunded?: boolean;
  product_type?: string;
  product_subtype?: TransactionProductSubType;
  product_info?: {
    image: string;
  };
};

export type IModelEarningsForCurrentPeriod = {
  private_show_earnings: number;
  tipping_show_earnings: number;
  fan_club_earnings: number;
  photos_and_videos: number;
  referrals_and_bounty: number;
  total_earnings: number;
  amount_until_next_tier: number;
};

export type StudioModelEarningsLight = {
  source: string;
  youMake?: MODEL_TRANSACTION_EARNING_TYPE;
  tokens: number;
};

export type IMemberTransactions30Days = {
  id: string;
  product: string;
  website: string;
  member_spent_tokens: number;
  member_spent_usd: number;
  created_date: string;
  percentage: number;
  earning: number;
  earning_usd: number;
};
export type PeriodAmount = {
  bonus_tokens: number;
  chargeback_tokens: number;
  media_tokens_earned: number;
  media_tokens_received: number;
  member_referrals_earned: number;
  member_referrals_received: number;
  member_bounty_referrals_received: number;
  tiered_tokens_earned: number;
  tiered_tokens_received: number;
};
export type Period = {
  amounts?: PeriodAmount;
  id: number;
  locked: boolean;
  period: string;
  tier: number;
  year: string;
  title?: string;
  period_total_earnings: number;
  amount_to_next_tier?: number;
  earnings?: any;
  member_spent_tokens?: any;
  amount_to_next_tier_tokens?: number;
  model_token_earnings?: any;
};

export enum PayoutFilterMainType {
  ALL = "all",
  BONUS_PAYOUT = "bonus_payout",
  TIER_PAYOUT = "tier_payout",
  FLAT_PAYOUT = "flat_payout",
  CREDIT_REFUND = "credit_refund"
}
export interface PayoutFilter {
  category: PayoutFilterMainType;
  productTypes: string[];
}