import React, { useCallback, useState } from "react";
import "./styles.scss";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Link } from "react-router-dom";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import { NullableString } from "library/core/types";
import RecaptchaForm from "library/components/_tailwind/recaptcha";
import config from "core/config";
import TailwindButton from "library/components/_tailwind/button";
import TailwindInput from "library/components/_tailwind/input";
import { TBorderRadius } from "library/styles/tailwind-classnames";
import AuthStore from "core/stores/auth/AuthStore";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import TailwindCard from "library/components/_tailwind/card";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import { authStore } from "core/stores";

type ResetPasswordFormProps = {
  authStore?: AuthStore;
  validationStore?: ValidationStore;
  btnShapeVariant?: TBorderRadius[];
  showForgotUsername?: boolean;
};

const ResetPasswordForm: React.FunctionComponent<
  ResetPasswordFormProps & WrappedComponentProps
> = ({
  validationStore,
  btnShapeVariant = ["rounded"],
  showForgotUsername = true,
  intl,
}) => {
  const [emailOrUsername, setEmailOrUsername] = useState<string>("");
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const { resetPassword, isActionInProgress } = authStore!;
  const { errors, validate } = validationStore!;
  const { googleCaptchaSiteKey } = config!;

  const onResetPasswordClick = useCallback(
    async (recaptchaToken: NullableString) => {
      try {
        await resetPassword(emailOrUsername, recaptchaToken);
        setShowSuccess(true);
      } catch (error) {
        // do not remove as this is used to fallback to v2
        throw new Error(error);
      }
    },
    [emailOrUsername]
  );

  const onCaptchaChange = async (recaptchaKey: NullableString) => {
    const isValid = validate("emailOrUsername", emailOrUsername);
    if (isValid && recaptchaKey) {
      onResetPasswordClick(recaptchaKey);
    }
  };

  return (
    <TailwindCard
      backgroundColor={"bg-card-secondary-bg-color"}
      padding={["p-8"]}>
      <RecaptchaForm
        className={"w-full"}
        margin={["mt-6"]}
        captchaSiteKey={googleCaptchaSiteKey}
        callback={onCaptchaChange}>
        {!showSuccess ? (
          <React.Fragment>
            <TailwindTranslatedText
              textColor='text-main-color'
              headingProps={{
                level: 5,
                margin: ["mb-1"],
              }}
              descriptor={{
                id: "landing.resetPasswordAuth.forgotYourPassword",
                defaultMessage: "Forgot Your Password?",
              }}
            />
            <TailwindTranslatedText
              margin={["mb-2"]}
              textColor='text-main-color'
              display={"inline-block"}
              descriptor={{
                id: "landing.resetPasswordAuth.enterYourEmailOrUsername",
                defaultMessage:
                  "Enter your e-mail or username below to reset new password.",
              }}
            />
            <TailwindInput
              required
              className={["mb-2"]}
              rounded={false}
              width={"w-full"}
              backgroundColor={"transparent"}
              borderColor={"border-black"}
              borderWidth={["border"]}
              label={intl.formatMessage({
                id: "common.email-or-username",
                defaultMessage: "E-mail or Username",
              })}
              errorProps={{
                textAlign: "text-right",
              }}
              value={emailOrUsername || ""}
              type={TailwindInputType.text}
              onChange={e => setEmailOrUsername(e.target.value)}
              error={errors.emailOrUsername || undefined}
            />
            {showForgotUsername && (
              <div className='w-full text-right ResetPasswordForm__forgot-password'>
                <p>
                  <Link
                    to={"/reset-username"}
                    className={
                      "ResetPasswordForm__forgot-password-text textCapitalize coral large"
                    }>
                    {intl.formatMessage({
                      id: "landing.welcomeBack.forgotUsername",
                      defaultMessage: "Forgot Username?",
                    })}
                  </Link>
                </p>
              </div>
            )}
            {errors?.resetPassword && (
              <div className={"mb-3 ResetPasswordForm__error"}>
                {errors?.resetPassword}
              </div>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h5 className={"text-base mb-4 colorTitle"}>
              {intl.formatMessage({
                id: "accountSettings.passwordSettings.yourPasswordResetEmailSent",
                defaultMessage:
                  "Your password reset email sent, if you are missing the email, check your spam filter or click to resend",
              })}
            </h5>
          </React.Fragment>
        )}
        <div className={"mt-4"}>
          <TailwindButton
            type={"submit"}
            backgroundColor={"bg-yellow-500"}
            textColor={"text-white"}
            fullWidth
            justifyContent={"justify-center"}
            borderRadius={btnShapeVariant}
            disabled={isActionInProgress}
            showSpinner={isActionInProgress}>
            {intl.formatMessage({
              id: "common.sendResetLink",
              defaultMessage: "Send Reset Link",
            })}
          </TailwindButton>
        </div>
      </RecaptchaForm>
    </TailwindCard>
  );
};

export default injectIntl(
  inject(
    "authStore",
    "profileStore",
    "validationStore"
  )(observer(ResetPasswordForm))
);
