import React from "react";
import TailwindTranslatedText, {
  TailwindTranslatedTextValues,
} from "library/components/_tailwind/translated-text";
import TailwindFlex from "library/components/_tailwind/flex";
import { MessageDescriptor } from "react-intl";
import TailwindPill from "library/components/_tailwind/pill";

type Props = {
  descriptor: MessageDescriptor;
  values?: TailwindTranslatedTextValues;
  pillContent?: JSX.Element;
  isActive: boolean;
};

const BroadcastChatTabLabel: React.ComponentType<Props> = ({
  descriptor,
  values,
  pillContent,
}) => {
  return (
    <TailwindFlex
      width={"w-full"}
      justifyContent={"justify-center"}
      alignItems={"items-center"}>
      <TailwindTranslatedText
        fontWeight={"font-bold"}
        descriptor={descriptor}
        values={values}
      />
      {pillContent && (
        <TailwindPill
          margin={["ml-1"]}
          padding={["px-3", "py-1"]}
          backgroundColor={"bg-pink-700"}
          textColor={"text-white"}
          textProps={{
            fontWeight: "font-bold",
          }}>
          {pillContent}
        </TailwindPill>
      )}
    </TailwindFlex>
  );
};

export default BroadcastChatTabLabel;
