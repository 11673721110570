import React, { useRef } from "react";
import { DropTargetMonitor, useDrag, useDrop } from "react-dnd";

type DraggableItemProps = {
  id: string;
  index: number;
  reorder: (dragIndex: number, hoverIndex: number) => void;
  itemType: string;
};

const DraggableItem: React.ComponentType<DraggableItemProps> = ({
  id,
  index,
  reorder,
  children,
  itemType,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: itemType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item: DraggableItemProps, _monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      //const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      /*const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;*/

      // Determine mouse position
      //const clientOffset = monitor.getClientOffset();

      /*// Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;*/

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      /*if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }*/

      // Time to actually perform the action
      reorder(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    type: itemType,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div ref={ref} data-handler-id={handlerId}>
      {children}
    </div>
  );
};

export default DraggableItem;
