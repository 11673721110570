import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import TailwindButton from "library/components/_tailwind/button";
import TailwindFlex from "library/components/_tailwind/flex";
import { injectIntl, WrappedComponentProps } from "react-intl";

type Props = {
  openRegistrationModal: () => void;
};

const LandingSlider2: React.FunctionComponent<
  Props & RouteComponentProps & WrappedComponentProps
> = ({ openRegistrationModal, history, intl }) => {
  const handleClick = () => {
    history.push("/learn-more");
  };

  return (
    <div className={"LandingSlider__text LandingSlider__text--2"}>
      <div className={"LandingSlider__title"}>
        {intl.formatMessage({
          id: "landing.slider2.title",
          defaultMessage: "Payouts of up to 100%*",
        })}
      </div>
      <div className={"LandingSlider__text-body"}>
        {intl.formatMessage({
          id: "landing.slider2.body",
          defaultMessage:
            "Our models can make $1,000s per week with some of the highest payouts available.",
        })}
      </div>
      <TailwindFlex justifyContent={"justify-start"} margin={["mb-4"]}>
        <TailwindButton
          position={"relative"}
          zIndex={"z-100"}
          justifyContent={"justify-center"}
          fullWidth={false}
          margin={["mt-8", "mr-2"]}
          borderRadius={"rounded-none"}
          width={"w-5/12"}
          size={"lg"}
          backgroundColor={"primary"}
          rounded={false}
          backgroundOpacity={"bg-opacity-70"}
          onClick={openRegistrationModal}>
          {intl.formatMessage({
            id: "landing.getStarted",
            defaultMessage: "Get Started",
          })}
        </TailwindButton>
        <TailwindButton
          borderWidth={["border-2"]}
          borderColor={"border-white"}
          position={"relative"}
          zIndex={"z-100"}
          justifyContent={"justify-center"}
          fullWidth={false}
          margin={["mt-8"]}
          borderRadius={"rounded-none"}
          textProps={{ textColor: "text-white" }}
          width={"w-5/12"}
          size={"lg"}
          onClick={handleClick}>
          {intl.formatMessage({
            id: "landing.learnMore",
            defaultMessage: "Learn More",
          })}
        </TailwindButton>
      </TailwindFlex>
      <p className={"LandingSlider__text--white"}>
        {intl.formatMessage({
          id: "landing.slider2.disclaimer",
          defaultMessage:
            "*Subject to certain terms and conditions. Individual model results may vary.",
        })}
      </p>
    </div>
  );
};

export default withRouter(injectIntl(LandingSlider2));
