import React from "react";
import "./styles.scss";
import classnames from "classnames";

type PinIconProps = {
  className?: string;
};

const PinIcon: React.ComponentType<PinIconProps> = ({ className }) => (
  <span className={classnames("PinIcon", className && className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 26 26"
    >
      <g>
        <defs> </defs>
        <rect display="none" vectorEffect="non-scaling-stroke" />
        <rect
          id="_x3C_Slice_x3E__81_"
          display="none"
          vectorEffect="non-scaling-stroke"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3,12h20c0,0-1-5-5-5V3l3-3H5l3,3v4C4,7,3,12,3,12z M13,26l2-13h-4L13,26z"
          vectorEffect="non-scaling-stroke"
        />{" "}
      </g>
    </svg>
  </span>
);

export default PinIcon;
