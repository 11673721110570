import React, { useCallback, useEffect } from "react";
import { inject, observer } from "mobx-react";
import LanguageStore from "library/core/stores/language/LanguageStore";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import TailwindDivider from "library/components/_tailwind/divider";
import ModalStore from "library/core/stores/modal";
import PricingStore from "../../../../src/common/broadcast/_stores/pricing/PricingStore";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindCard from "library/components/_tailwind/card";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindDropdown, {
  TailwindDropdownValue,
} from "library/components/_tailwind/dropdown";
import { MESSAGE_PRICE_DROPDOWN_ITEMS } from "./const";
import StudioModelAccessRights from "../../studio-models-access-rights";

interface PricingModalProps {
  modalStore?: ModalStore;
  pricingStore?: PricingStore;
  languageStore?: LanguageStore;
  profileStore?: ProfileStore;
}

const PricingModal: React.ComponentType<PricingModalProps> = ({
  pricingStore,
  profileStore,
}) => {
  const {} = "";
  const { submitPrice, getPriceData, modelProducts } = pricingStore!;
  const { isStudioModel, modelProfile } = profileStore!;

  useEffect(() => {
    getPriceData();
  }, []);

  const onMessagePriceChange = useCallback((value: TailwindDropdownValue) => {
    submitPrice("conversation_message_price", value);
  }, []);

  return (
    <TailwindFlex width={"w-112"}>
      <TailwindCard
        backgroundColor={"bg-white"}
        height={"h-full"}
        width={"w-full"}
        padding={["p-6"]}
        borderRadius={"rounded-lg"}>
        <TailwindTranslatedText
          descriptor={{
            id: "messageCenter.pricingModal.title",
            defaultMessage: "Message Price",
          }}
          paragraphProps={{
            fontSize: "text-base",
            fontWeight: "font-bold",
            margin: ["my-2"],
          }}
        />
        <TailwindDivider
          color={"bg-header-bg-color"}
          margin={["my-4"]}
          opacity='opacity-50'
          style={{ height: "2px" }}
        />
        <TailwindTranslatedText
          descriptor={{
            id: "messageCenter.pricingModal.pricingMessage",
            defaultMessage:
              "Set the token price members pay for each message they send you.",
          }}
          paragraphProps={{
            fontSize: "text-sm",
            margin: ["mr-auto", "mb-5"],
          }}
        />
        <TailwindFlex
          fontSize={"text-sm"}
          flexDirection='flex-row'
          width={"w-full"}>
            <StudioModelAccessRights
              isIndividualModel={!isStudioModel}
              shouldShow={!!modelProfile.access_rights?.is_pricing_settings_change_allowed}
              hideContent={true}
              noAccessChildren={(
                <TailwindDropdown
                  borderColor={"border-gray-100"}
                  labelPosition='left'
                  disabled={true}
                  items={MESSAGE_PRICE_DROPDOWN_ITEMS}
                  value={modelProducts.conversation_message_price as number}
                  onChange={onMessagePriceChange}
                  labelProps={{
                    width: "w-full",
                    fontSize: "text-sm",
                    fontWeight: "font-normal",
                  }}
                  name={"message-price"}
                  width={"w-full"}
                  itemLabelProps={{
                    fontSize: "text-xs",
                  }}
                  direction={"bottom"}
                  fullWidth={false}
                  backgroundColor={"bg-primary-bg-color"}
                  margin={["ml-auto"]}
                />
              )}
            >
            <TailwindDropdown
              borderColor={"border-gray-100"}
              labelPosition='left'
              items={MESSAGE_PRICE_DROPDOWN_ITEMS}
              value={modelProducts.conversation_message_price as number}
              onChange={onMessagePriceChange}
              labelProps={{
                width: "w-full",
                fontSize: "text-sm",
                fontWeight: "font-normal",
              }}
              name={"message-price"}
              width={"w-full"}
              itemLabelProps={{
                fontSize: "text-xs",
              }}
              direction={"bottom"}
              fullWidth={false}
              backgroundColor={"bg-primary-bg-color"}
              margin={["ml-auto"]}
            />
          </StudioModelAccessRights>
        </TailwindFlex>
      </TailwindCard>
    </TailwindFlex>
  );
};

export default inject(
  "modalStore",
  "pricingStore",
  "languageStore",
  "profileStore"
)(observer(PricingModal));
