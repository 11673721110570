import React from "react";
import TailwindText, {
  TailwindTextProps,
} from "library/components/_tailwind/text";
import TailwindPill from "library/components/_tailwind/pill";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";

export type MediaManagerMediaCardPurchasesProps = TailwindTextProps & {
  num_purchases: number;
};

const MediaManagerMediaCardPurchases: React.ComponentType<MediaManagerMediaCardPurchasesProps> =
  ({ num_purchases = 0, ...props }) => {
    return num_purchases > 0 ? (
      <TailwindPill
        backgroundColor={"bg-gray-600"}
        size='xs'
        fontSize={"text-xs"}
        textColor={"text-white"}>
        <TailwindText 
          fontSize={"text-xs"}
          style={{
            position: "relative",
          }}
          {...props}>
          <TailwindIcon 
            height={"h-4"}
            width={"w-4"}
            fontSize={"text-xs"}
            style={{
              position: "relative",
              top: "4px"
            }}
            name={CustomIconName.earnings}/> {num_purchases > 99 ? "99+" : num_purchases}
        </TailwindText>
      </TailwindPill>
    ) : (
      <TailwindText></TailwindText>
    );
  };

export default MediaManagerMediaCardPurchases;
