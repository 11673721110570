import config from "../../config";
import cookie from "js-cookie";

export default class Logger {
  log(...args: any[]) {
    if (
      config.enableLogger ||
      (typeof window !== undefined && cookie.get("logs"))
    ) {
      console.log(...args);
    }
  }
  error(...args: any[]) {
    console.error(...args);
  }
}
