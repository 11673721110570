import { inject, observer } from "mobx-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable";
import "./styles.scss";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import RouteStore from "core/stores/route/RouteStore";
import { NANOPLAYER_MINI_ELEMENT_ID } from "common/broadcast/_stores/broadcast-stream/consts";
import { BroadcastStreamService } from "common/broadcast/_stores/broadcast-stream/BroadcastStreamService";
import CamsNanoPlayer from "core/utility/nano/nano";
import { usePrevious } from "library/core/utility/hooks";
import BroadcastStore from "common/broadcast/_stores/broadcast/BroadcastStore";
import { BroadcastStreamState } from "common/broadcast/_stores/broadcast/enums";

interface Props {
  broadcastStreamStore?: BroadcastStreamStore;
  broadcastStreamService?: BroadcastStreamService;
  routeStore?: RouteStore;
  broadcastStore?: BroadcastStore;
}

const MiniStreamViewer: React.FunctionComponent<Props> = ({
  broadcastStreamStore,
  broadcastStreamService,
  routeStore,
  broadcastStore,
}) => {
  const { streamState } = broadcastStore!;
  const { initializeNanoAndPlayStream } = broadcastStreamService!;
  const { currentRoutePathname } = routeStore!;
  const { mediaStream, isStreamingWebRTC, isStreamingOBS, nanoPlayerSettings } =
    broadcastStreamStore!;
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [obsNanoPlayer, setObsNanoPlayer] = useState<CamsNanoPlayer | null>(
    null
  );
  const previousIsStreamingObs = usePrevious(isStreamingOBS);
  useEffect(() => {
    if (videoRef?.current && mediaStream) {
      videoRef.current.srcObject = mediaStream;
    }
  }, [videoRef, mediaStream]);

  const showMiniStreamViewer = useMemo(() => {
    return (
      (isStreamingWebRTC || isStreamingOBS) &&
      streamState === BroadcastStreamState.started &&
      currentRoutePathname !== "/broadcast" &&
      currentRoutePathname !== "/broadcast-member-verification" &&
      ((isStreamingWebRTC && mediaStream) || isStreamingOBS)
    );
  }, [
    currentRoutePathname,
    mediaStream,
    isStreamingWebRTC,
    isStreamingOBS,
    streamState,
  ]);

  useEffect(() => {
    async function initObsNano() {
      if (
        showMiniStreamViewer &&
        isStreamingOBS &&
        nanoPlayerSettings &&
        !obsNanoPlayer
      ) {
        const player = await initializeNanoAndPlayStream(
          NANOPLAYER_MINI_ELEMENT_ID,
          nanoPlayerSettings
        );
        setObsNanoPlayer(player);
      }
    }

    initObsNano();
  }, [showMiniStreamViewer, isStreamingOBS, nanoPlayerSettings, obsNanoPlayer]);

  useEffect(() => {
    if (previousIsStreamingObs && !isStreamingOBS) {
      obsNanoPlayer?.stop();
      setObsNanoPlayer(null);
    }
  }, [isStreamingOBS, previousIsStreamingObs, obsNanoPlayer]);

  return (
    <Draggable>
      <div
        className={`MiniStreamViewer ${
          !showMiniStreamViewer && "MiniStreamViewer--hide"
        }`}>
        {isStreamingOBS ? (
          <div
            id={NANOPLAYER_MINI_ELEMENT_ID}
            className={`MiniStreamViewer__video`}></div>
        ) : null}
        {isStreamingWebRTC && (
          <video
            ref={videoRef}
            className={`MiniStreamViewer__video`}
            autoPlay={true}
            muted={true}
            id='streamPreview'
          />
        )}
      </div>
    </Draggable>
  );
};

export default inject(
  "broadcastStreamStore",
  "broadcastStreamService",
  "routeStore",
  "broadcastStore"
)(observer(MiniStreamViewer));
