import TailwindText from "library/components/_tailwind/text";
import React, { FunctionComponent } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";

type Props = {
  isApplicationPending: boolean;
  isApplicationDenied: boolean;
  isApplicationIncomplete: boolean;
};

const AccountApprovalStep: FunctionComponent<Props & WrappedComponentProps> = ({
  isApplicationPending,
  isApplicationDenied,
  isApplicationIncomplete,
  intl,
}) => {
  return (
    <React.Fragment>
      <div className='w-6/12 mr-1'>
        <TailwindText textColor='text-main-color'>
          {intl.formatMessage({
            id: "landing.summaryStep.step4",
            defaultMessage: "Application Approval",
          })}
        </TailwindText>
      </div>
      <div className='w-6/12 mr-1'>
        {isApplicationIncomplete && (
          <React.Fragment>
            <TailwindText textColor={"text-red-500"} fontWeight={"font-bold"}>
              {intl.formatMessage({
                id: "landing.summaryStep.incomplete",
                defaultMessage: "Incomplete",
              })}
            </TailwindText>
          </React.Fragment>
        )}
        {isApplicationPending && (
          <React.Fragment>
            <TailwindText
              textColor={"text-yellow-500"}
              fontWeight={"font-bold"}>
              {intl.formatMessage({
                id: "landing.summaryStep.pending",
                defaultMessage: "Pending",
              })}
            </TailwindText>
            {/* <br /> */}
            {/* {!isVerificationPending && !isSignAgreementPending && (
              <TailwindText fontSize={"text-sm"}>
                (
                {intl.formatMessage({
                  id: "landing.summaryStep.applicationBeingReviewed",
                  defaultMessage: "Your application is being reviewed.",
                })}
                )
              </TailwindText>
            )} */}
          </React.Fragment>
        )}
        {isApplicationDenied && (
          <TailwindText fontWeight={"font-bold"} textColor={"text-red-500"}>
            {intl.formatMessage({
              id: "landing.summaryStep.denied",
              defaultMessage: "Denied",
            })}
          </TailwindText>
        )}
      </div>
    </React.Fragment>
  );
};
export default injectIntl(AccountApprovalStep);
