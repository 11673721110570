import React, { useCallback, useEffect } from "react";
import "./styles.scss";
import { inject, observer } from "mobx-react";
import GiftsAndAwardsStore from "containers/model/gifts-and-awards/stores/gifts-and-awards/GiftsAndAwards";
import { injectIntl, WrappedComponentProps } from "react-intl";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindDropdown, {
  TailwindDropdownValue,
} from "library/components/_tailwind/dropdown";
import TailwindGiftWithPill from "library/components/_tailwind/gift";
import { TailwindPillProps } from "library/components/_tailwind/pill";
import TailwindSpinner from "library/components/_tailwind/spinner";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";

type VirtualGiftsProps = {
  giftsAndAwardsStore?: GiftsAndAwardsStore;
};

const CamsModelMyVirtualGifts: React.ComponentType<
  VirtualGiftsProps & WrappedComponentProps
> = ({ giftsAndAwardsStore, intl }) => {
  const {
    gifts,
    loadingGifts,
    giftSortKey,
    getGifts,
    sortedGifts,
    setSortKey,
  } = giftsAndAwardsStore!;
  const hasGifts = gifts && gifts.length > 0;

  useEffect(() => {
    getGifts();
  }, []);

  const onChangeSortGift = useCallback((value: TailwindDropdownValue) => {
    setSortKey(value as "price" | "count");
  }, []);

  return (
    <TailwindFlex
      className={["CamsModelMyVirtualGifts"]}
      height={"h-full"}
      flexDirection={"flex-col"}>
      {hasGifts && (
        <TailwindFlex justifyContent={"justify-end"}>
          <TailwindFlex
            width={"w-auto"}
            md={"w-full"}
            justifyContent={"justify-end"}>
            <TailwindDropdown
              name={"gift-sort-key"}
              backgroundColor={"bg-primary-bg-color"}
              value={giftSortKey}
              onChange={onChangeSortGift}
              items={[
                {
                  value: "price",
                  label: intl.formatMessage({
                    id: "giftsAndAwards.myVirtualGifts.mostExpensive",
                    defaultMessage: "Most Expensive",
                  }),
                },
                {
                  value: "count",
                  label: intl.formatMessage({
                    id: "giftsAndAwards.myVirtualGifts.mostReceived",
                    defaultMessage: "Most Received",
                  }),
                },
              ]}
            />
          </TailwindFlex>
        </TailwindFlex>
      )}
      <TailwindFlex flexDirection={"flex-col"} flexGrow={"flex-grow"}>
        {loadingGifts ? (
          <TailwindSpinner />
        ) : (
          <TailwindFlex flexWrap={"flex-wrap"}>
            {hasGifts ? (
              sortedGifts.map((gift, index) => {
                const pills: TailwindPillProps[] = [];
                if (gift.price) {
                  pills.push({
                    backgroundColor: "bg-blue-400",
                    textColor: "text-white",
                    children: gift.price,
                    leftIconProps: {
                      name: CustomIconName.token,
                    },
                  });
                }
                if (gift.count) {
                  pills.push({
                    backgroundColor: "bg-purple-400",
                    textColor: "text-white",
                    children: gift.count,
                  });
                }
                return (
                  <TailwindGiftWithPill
                    key={index}
                    pills={pills}
                    image={gift.image}
                  />
                );
              })
            ) : (
              <TailwindFlex
                height={"h-full"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}>
                <TailwindTranslatedText
                  descriptor={{
                    id: "giftsAndAwards.myVirtualGifts.youDontHaveGifts",
                    defaultMessage: "You don't have any virtual gifts yet.",
                  }}
                  headingProps={{
                    level: 6,
                    textAlign: "text-center",
                  }}
                />
              </TailwindFlex>
            )}
          </TailwindFlex>
        )}
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject("giftsAndAwardsStore")(observer(CamsModelMyVirtualGifts))
);
