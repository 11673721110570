import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";

type Props = {
  amount: number;
};

const MessageTip: React.ComponentType<Props & WrappedComponentProps> = ({
  amount,
  intl,
}) => {
  return (
    <div className='MessageTip'>
      <div className='MessageTip__amount text-white'>
        <TailwindIcon
          textColor={"text-white"}
          name={CustomIconName.token}
          fontSize={"text-lg"}
        />
        {amount}
      </div>
      <div>
        {intl.formatMessage({
          id: "message-center.tip-received",
          defaultMessage: "Tip Received",
        })}
      </div>
    </div>
  );
};

export default injectIntl(
  inject("messageStore", "authStore")(observer(MessageTip))
);
