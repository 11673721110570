import React, { useCallback, useMemo } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import { TTextColor } from "library/styles/tailwind-classnames";
import TailwindButton from "library/components/_tailwind/button";
import { NotificationType } from "common/my-page/stores/profile/types";

type Props = {
  id: string;
  type: NotificationType;
  content: JSX.Element;
  onClickReadMore: (id: string) => void;
  onClickDismiss: (id: string) => void;
  isDismissable: boolean;
};

const DashboardNotification: React.ComponentType<Props> = ({
  id,
  content,
  type = "info",
  isDismissable,
  onClickDismiss,
  onClickReadMore,
}) => {
  const backgroundColor = useMemo(() => {
    switch (type) {
      case "error":
        return "bg-red-500";
      case "success":
        return "bg-green-500";
      case "info":
        return "bg-pink-600";
      case "warning":
        return "bg-yellow-500";
    }
  }, [type]);

  const textColor = useMemo(() => {
    let color: TTextColor = "text-white";
    switch (type) {
      case "error":
        color = "text-white";
        break;
      case "success":
        color = "text-white";
        break;
      case "info":
        color = "text-white";
        break;
      case "warning":
        color = "text-white";
        break;
    }
    return color;
  }, [type]);

  const _onClickReadMore = useCallback(() => {
    onClickReadMore(id);
  }, [id, onClickReadMore]);

  const _onClickDismiss = useCallback(() => {
    onClickDismiss(id);
  }, [id, onClickDismiss]);

  return (
    <TailwindFlex
      flexDirection={"flex-row"}
      padding={["px-4", "py-2"]}
      minHeight={"min-h-16"}
      backgroundColor={backgroundColor}
      textColor={textColor}>
      <TailwindFlex
        inline
        width={"w-auto"}
        flexGrow={"flex-grow"}
        alignItems={"items-center"}>
        {content}
      </TailwindFlex>
      <TailwindFlex
        width={"w-auto"}
        inline
        alignItems={"items-center"}
        justifyContent={"justify-end"}>
        <TailwindButton
          onClick={_onClickReadMore}
          fullWidth={false}
          backgroundColor={"bg-transparent"}
          underline
          textProps={{
            textColor,
          }}>
          Details
        </TailwindButton>
        {isDismissable && (
          <TailwindButton
            onClick={_onClickDismiss}
            fullWidth={false}
            backgroundColor={"bg-transparent"}
            leftIconProps={{
              name: "close",
              textColor: textColor,
            }}></TailwindButton>
        )}
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default DashboardNotification;
