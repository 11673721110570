import React from "react";
import classnames from "classnames";

type ClockIconProps = {
  className?: string;
};

const ClockIcon: React.FunctionComponent<ClockIconProps> = ({ className }) => {
  return (
    <span className={classnames("ClockIcon", className && className)}>
      <svg
        version="1.2"
        preserveAspectRatio="none"
        viewBox="0 0 24 24"
        className={className || ""}
      >
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12,4C6.478,4,2,8.478,2,14c0,5.523,4.478,10,10,10s10-4.477,10-10 C22,8.478,17.522,4,12,4z M17,15h-6V7h2v6h4V15z M21.69,6.942C22.49,6.211,23,5.169,23,4c0-2.209-1.791-4-4-4 c-1.667,0-3.094,1.021-3.694,2.472C17.904,3.216,20.137,4.812,21.69,6.942z M8.694,2.472C8.094,1.021,6.668,0,5,0 C2.791,0,1,1.791,1,4c0,1.169,0.51,2.211,1.31,2.942C3.863,4.812,6.096,3.216,8.694,2.472z"
            style={{ fill: "#ffd000" }}
          ></path>
        </g>
      </svg>
    </span>
  );
};
export default ClockIcon;
