import React, { useCallback } from "react";
import TailwindDropdown, {
  TailwindDropdownValue,
} from "library/components/_tailwind/dropdown";
import TailwindBox, {
  TailwindBoxClassName,
} from "library/components/_tailwind/box";

const ProductDropdown: React.FunctionComponent<{
  options: (string | number)[];
  onChange: (value: TailwindDropdownValue) => void;
  price?: number;
  unit: string;
  label?: string;
  currency?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  className?: TailwindBoxClassName;
  showSearch?: boolean;
  direction?: "top" | "bottom";
  dataTestId?: string;
}> = ({
  options,
  onChange,
  price,
  unit,
  label,
  currency,
  disabled,
  fullHeight = false,
  fullWidth = false,
  className,
  showSearch = false,
  direction,
  dataTestId
}) => {
  const renderValue = useCallback(
    value =>
      isNaN(value)
        ? value
        : `${value} ${currency ? currency : ""} ${!unit ? "" : "/" + unit!}`,
    [unit, currency, price]
  );

  return (
    <TailwindBox>
      <TailwindDropdown
        dataTestId={dataTestId}
        showSearch={showSearch}
        className={className}
        name='pricing'
        fullWidth={fullWidth}
        fullHeight={fullHeight}
        borderColor={"border-gray-500"}
        backgroundColor={"primary"}
        borderWidth={["border"]}
        disabled={disabled}
        value={isNaN(price!) ? "Not allowed" : price}
        onChange={onChange}
        items={(options as number[]).map(priceOption => ({
          label: renderValue(priceOption),
          value: isNaN(+priceOption) ? priceOption : +priceOption,
        }))}
        menuProps={{
          maxHeight: "max-h-32",
        }}
        label={label}
        direction={direction}
      />
    </TailwindBox>
  );
};
export default ProductDropdown;
