import React from "react";
import TailwindText from "library/components/_tailwind/text";
import "./styles.scss";
import BroadcastOBSModal from "common/broadcast/broadcast-obs-modal";
import TailwindBox from "library/components/_tailwind/box";

type Props = {};
const OBSSupport: React.FC<Props> = ({}) => {
  return (
    <div className={"OBSSupport"}>
      <TailwindText
        display={"block"}
        fontSize={"text-3xl"}
        fontWeight={"font-bold"}
        padding={["py-2"]}
        margin={["mb-4"]}>
        OBS Support
      </TailwindText>
      <TailwindBox>
        <BroadcastOBSModal
          showDifferentStepStreamUrlForStandaloneObsHelpPage={true}
        />
      </TailwindBox>
    </div>
  );
};

export default OBSSupport;
