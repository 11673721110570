import React, { useState } from "react";
import MediaProfileOverlay, {
  MediaProfileOverlayProps,
} from "./media-profile-overlay";
import MediaBlurredOverlay, {
  MediaBlurredOverlayProps,
} from "./media-blurred-overlay";
import Media, { MediaProps } from "./index";
import MediaProfileOnlineStatusOverlay, {
  MediaProfileOnlineStatusOverlayProps,
} from "./media-online-status-overlay";
import AffProfileActions from "../aff-profile-actions";
import TailwindButton from "../button";
import TailwindFlex from "../flex";
import TailwindBox from "../box";
import MediaUnlockOverlay, {
  MediaUnlockOverlayProps,
} from "./media-unlock-overlay";

export type MediaProfileThumbnailProps = {
  mediaProps: MediaProps;
  mediaProfileOverlayProps?: MediaProfileOverlayProps;
  mediaBlurredOverlayProps?: MediaBlurredOverlayProps;
  mediaProfileOnlineStatusOverlayProps?: MediaProfileOnlineStatusOverlayProps;
  showProfileActionsOnHover?: boolean;
  showUnlockOverlay?: MediaUnlockOverlayProps;
};

const MediaProfileThumbnail: React.ComponentType<MediaProfileThumbnailProps> = ({
  mediaProps,
  mediaProfileOverlayProps,
  mediaBlurredOverlayProps,
  mediaProfileOnlineStatusOverlayProps,
  showProfileActionsOnHover = false,
  showUnlockOverlay,
}) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <Media
      {...mediaProps}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}>
      {isHover && showProfileActionsOnHover && (
        <TailwindBox
          position={"absolute"}
          objectPosition={"object-left-top"}
          height={"h-full"}
          width={"w-full"}>
          <MediaBlurredOverlay>
            <TailwindFlex
              flexDirection={"flex-col"}
              alignItems={"items-center"}
              padding={["p-4"]}
              width={"w-auto"}>
              <AffProfileActions variant={"contained"} />
              <TailwindButton
                backgroundColor={"bg-white"}
                backgroundOpacity={"bg-opacity-20"}
                borderWidth={["border-2"]}
                margin={["mt-4"]}>
                View Profile
              </TailwindButton>
            </TailwindFlex>
          </MediaBlurredOverlay>
        </TailwindBox>
      )}
      {showUnlockOverlay && <MediaUnlockOverlay {...showUnlockOverlay} />}
      {mediaProfileOverlayProps && (
        <MediaProfileOverlay {...mediaProfileOverlayProps} />
      )}
      {mediaBlurredOverlayProps && (
        <MediaBlurredOverlay {...mediaBlurredOverlayProps} />
      )}
      {mediaProfileOnlineStatusOverlayProps && (
        <MediaProfileOnlineStatusOverlay
          {...mediaProfileOnlineStatusOverlayProps}
        />
      )}
    </Media>
  );
};

export default MediaProfileThumbnail;
