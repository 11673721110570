import React, { useCallback } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import MediaManagerMediaCardFileUpload from "./media-manager-media-card-file-upload";
import { MediaManagerMediaCardHeaderActionsProps } from "./media-manager-media-card-header-actions";

const MediaManagerMediaCardFooterActions: React.ComponentType<MediaManagerMediaCardHeaderActionsProps> =
  ({ onDeleteClicked, fileUploadProps }) => {
    const onDeleteClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (onDeleteClicked) {
          onDeleteClicked(event);
        }
      },
      [onDeleteClicked]
    );

    return (
      <TailwindFlex
        // position={"absolute"}
        height={"h-full"}
        width={"w-full"}
        // margin={["mt-1"]}
        flexDirection={"flex-row"}
        justifyContent={"justify-end"}
        alignItems={"items-center"}>
        {fileUploadProps && (
          <MediaManagerMediaCardFileUpload
            fileUploadProps={{ ...fileUploadProps?.fileUploadProps }}
            margin={["mt-2"]}
          />
        )}

        {onDeleteClicked && (
          <TailwindButton
            rounded
            display={"flex"}
            height={"h-8"}
            width={"w-8"}
            margin={["my-0"]}
            fullWidth={false}
            alignItems={"items-center"}
            justifyContent={"justify-center"}
            backgroundColor={"bg-gray-600"}
            leftIconProps={{
              name: "delete",
              fontSize: "text-lg",
              textColor: "text-white",
            }}
            onClick={onDeleteClick}
            textProps={{
              textTransform: "capitalize",
            }}
            pseudoClasses={["md:h-6", "md:w-6", "md:text-xs"]}
          />
        )}
      </TailwindFlex>
    );
  };

export default MediaManagerMediaCardFooterActions;
