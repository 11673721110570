import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import StorybookConventionsSCSS from "./conventions-css-and-scss";
import StorybookConventionsNaming from "./conventions-naming";
import StorybookConventionsOOPVsFunctional from "./conventions-oop-vs-functional";
import StorybookConventionsEslintAndPrettier from "./conventions-eslint-and-prettier";
import StorybookConventionsFileAndFolderStructure from "./conventions-file-and-folder-structure";
import StorybookConventionsJavascriptAndTypescript from "./conventions-javascript-and-typescript-and-react";
import StorybookConventionsInstallingPackages from "./conventions-installing-packages";

type Props = {};

const StorybookConventions: React.ComponentType<Props> = ({}) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/css-and-scss`}>
        <StorybookConventionsSCSS />
      </Route>
      <Route exact path={`${path}/naming`}>
        <StorybookConventionsNaming />
      </Route>
      <Route exact path={`${path}/oop-vs-functional`}>
        <StorybookConventionsOOPVsFunctional />
      </Route>
      <Route exact path={`${path}/eslint-and-prettier`}>
        <StorybookConventionsEslintAndPrettier />
      </Route>
      <Route path={`${path}/installing-packages`}>
        <StorybookConventionsInstallingPackages />
      </Route>
      <Route exact path={`${path}/file-and-folder-structure`}>
        <StorybookConventionsFileAndFolderStructure />
      </Route>
      <Route exact path={`${path}/javascript-and-typescript-and-react`}>
        <StorybookConventionsJavascriptAndTypescript />
      </Route>
    </Switch>
  );
};

export default StorybookConventions;
