import React, { useEffect } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import ProfileStore from "../../my-page/stores/profile/ProfileStore";
import StudioModelsAccessRightsStore from "containers/studio/studio-models/stores/studio-models-access-rights/StudioModelsAccessRightsStore";
import PayoutDetails from "./payout-details";
import PayoutTable from "./payout-table";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindSpinner from "library/components/_tailwind/spinner";
import StudioModelAccessRights from "../../../common/studio-models-access-rights";
import EarningsStore from "../_stores/earnings/EarningsStore";
import PayoutOverview from "./payout-overview";
import PayoutGraph from "./payout-graph";
import PayoutPeriodInfo from "./payout-period-info";

type AdmirersContainerProps = {
  profileStore?: ProfileStore;
  earningsStore?: EarningsStore;
  studioModelsAccessRightsStore?: StudioModelsAccessRightsStore;
};

const EarningsContainer: React.ComponentType<
  AdmirersContainerProps & WrappedComponentProps
> = ({ earningsStore, studioModelsAccessRightsStore }) => {
  const { initPayoutPageData, loadingPeriods } = earningsStore!;
  const { isEarningsAndTransactionsVisible } = studioModelsAccessRightsStore!;

  useEffect(() => {
    initPayoutPageData();
  }, []);

  return (
    <StudioModelAccessRights shouldShow={isEarningsAndTransactionsVisible}>
      <TailwindFlex flexDirection={"flex-col"} overflow={["overflow-x-hidden"]}>
        {loadingPeriods ? (
          <TailwindSpinner size={6} />
        ) : (
          <TailwindFlex flexDirection={"flex-col"}>
            <TailwindFlex margin={["mb-4"]}>
              <PayoutDetails />
            </TailwindFlex>
            <TailwindFlex flexWrap='flex-wrap' margin={["mb-4"]} width='w-full'>
              <TailwindFlex padding={["pr-2"]} md={"w-full"} width={"w-1/3"}>
                <PayoutPeriodInfo />
              </TailwindFlex>
              <TailwindFlex padding={["px-2"]} md={"w-full"} width={"w-1/3"}>
                <PayoutGraph />
              </TailwindFlex>
              <TailwindFlex padding={["pl-2"]} md={"w-full"} width={"w-1/3"}>
                <PayoutOverview />
              </TailwindFlex>
            </TailwindFlex>
            <TailwindFlex>
              <PayoutTable />
            </TailwindFlex>
          </TailwindFlex>
        )}
      </TailwindFlex>
    </StudioModelAccessRights>
  );
};

export default injectIntl(
  inject(
    "profileStore",
    "earningsStore",
    "studioModelsAccessRightsStore"
  )(observer(EarningsContainer))
);
