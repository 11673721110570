import React from "react";
import "./styles.scss";

type GalleryIconProps = {
  className?: string;
};

const GalleryIcon: React.ComponentType<GalleryIconProps> = ({ className }) => (
  <span className={`${className} camsIcon`}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
      <path d='M97.33,38.3c-.48-9.25-7.64-20.66-18.42-23a154.45,154.45,0,0,0-57.94,0c-10.79,2.33-17.81,13.5-18.36,23a215.65,215.65,0,0,0,0,26.18c.6,9.46,7.63,20.64,18.42,23A154.1,154.1,0,0,0,50,90.16a155.49,155.49,0,0,0,29-2.75C89.61,85.11,96.66,74,97.33,64.48A218.65,218.65,0,0,0,97.33,38.3Zm-5,25.83C91.82,71.59,86.15,80.75,78,82.51a149.33,149.33,0,0,1-55.95,0C13.72,80.73,8.07,71.59,7.6,64.14a209.51,209.51,0,0,1,0-25.56C8,31.15,13.67,22,22,20.22a149.26,149.26,0,0,1,56,0C86.06,22,92,31.12,92.34,38.56A212.61,212.61,0,0,1,92.34,64.13Z' />
      <path d='M69.92,46.19,44.86,31.71a6.16,6.16,0,0,0-9.24,5.34V66a6.17,6.17,0,0,0,6.16,6.17,6.19,6.19,0,0,0,3.08-.83L69.92,56.87a6.17,6.17,0,0,0,0-10.68Zm-2.5,6.35L42.36,67a1.16,1.16,0,0,1-1.74-1v-29a1.12,1.12,0,0,1,.58-1,1.22,1.22,0,0,1,.58-.16,1.13,1.13,0,0,1,.58.16L67.42,50.52a1.17,1.17,0,0,1,0,2Z' />
    </svg>
  </span>
);

export default GalleryIcon;
