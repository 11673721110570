import React from "react";
import "./styles.scss";

type SettingsSideProps = {
  className?: string;
};

const SettingsSide: React.ComponentType<SettingsSideProps> = ({
  className,
}) => (
  <span className={`${className} camsIcon`}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
      <path d='M94.22,59.77l-8.32-6.5a31.53,31.53,0,0,0,0-6.54l8.33-6.5a4.77,4.77,0,0,0,1.15-6.07l-8.9-15.39a4.78,4.78,0,0,0-5.85-2.11l-9.81,3.95a35.49,35.49,0,0,0-5.63-3.27L63.7,6.94A4.69,4.69,0,0,0,59,2.87H41.18a4.67,4.67,0,0,0-4.66,4L35,17.34a37.46,37.46,0,0,0-5.62,3.27l-9.85-3.95a4.67,4.67,0,0,0-5.79,2.06L4.84,34.14A4.72,4.72,0,0,0,6,40.23l8.32,6.51a29.61,29.61,0,0,0,0,6.52L6,59.77a4.76,4.76,0,0,0-1.15,6.07l8.89,15.39a4.79,4.79,0,0,0,5.85,2.11l9.82-4A35.49,35.49,0,0,0,35,82.66l1.49,10.4a4.69,4.69,0,0,0,4.67,4.07H59a4.67,4.67,0,0,0,4.66-4l1.5-10.45a36.89,36.89,0,0,0,5.61-3.27l9.86,4a4.66,4.66,0,0,0,5.79-2.06l9-15.49A4.75,4.75,0,0,0,94.22,59.77Zm-12,18.82L70.08,73.71,69,74.55a31.63,31.63,0,0,1-7,4.06l-1.32.55-1.86,13H41.43l-1.86-13-1.33-.55a29.87,29.87,0,0,1-7-4l-1.13-.87L18,78.59l-8.69-15L19.6,55.47l-.17-1.41a32.61,32.61,0,0,1-.3-4.06,32.61,32.61,0,0,1,.3-4.06l.17-1.41L9.28,36.46l8.69-15,12.16,4.88,1.12-.84a31.69,31.69,0,0,1,7-4.06l1.33-.55,1.86-13H58.78l1.86,13,1.32.55a29.93,29.93,0,0,1,7,4l1.13.87,12.17-4.89,8.7,15L80.6,44.53l.18,1.41A30.71,30.71,0,0,1,81.07,50a32.13,32.13,0,0,1-.29,4.06l-.18,1.41,10.33,8.07Z' />
      <path d='M50.1,31.88A18.12,18.12,0,1,0,68.22,50,18.14,18.14,0,0,0,50.1,31.88Zm0,31.24A13.12,13.12,0,1,1,63.22,50,13.14,13.14,0,0,1,50.1,63.12Z' />
    </svg>
  </span>
);

export default SettingsSide;
