import React, { useMemo } from "react";
import TailwindText, { TailwindTextProps } from "../text";
import { TFontSize } from "../../../styles/tailwind-classnames";

export type TailwindHeadingProps = TailwindTextProps & {
  level: 1 | 2 | 3 | 4 | 5 | 6;
};

const TailwindHeading: React.ComponentType<TailwindHeadingProps> = ({ level, children, ...props }) => {
  const fontSize = useMemo(() => {
    switch (level) {
      case 1:
        return "font-5xl";
      case 2:
        return "font-4xl";
      case 3:
        return "font-3xl";
      case 4:
        return "font-2xl";
      case 5:
        return "font-xl";
      case 6:
        return "font-lg";
      default:
        return "font-2xl";
    }
  }, [level]);

  return (
    <TailwindText {...props} as={`h${level}`} fontWeight={"font-bold"} fontSize={fontSize as TFontSize}>
      {children}
    </TailwindText>
  );
};

export default TailwindHeading;
