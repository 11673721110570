import React from "react";
import classnames from "classnames";

type Props = {
  className?: string;
};

const MuteIcon: React.ComponentType<Props> = ({ className }) => {
  return (
    <span className={classnames("MuteIcon", className && className)}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
        <path d='M49.92,5.59A44.41,44.41,0,1,0,94.33,50,44.46,44.46,0,0,0,49.92,5.59Zm0,83.82A39.41,39.41,0,1,1,89.33,50,39.46,39.46,0,0,1,49.92,89.41Z' />
        <path d='M42.91,42.81a4.57,4.57,0,1,0-4.56,4.56A4.56,4.56,0,0,0,42.91,42.81Z' />
        <path d='M61.49,38.25a4.56,4.56,0,1,0,4.56,4.56A4.56,4.56,0,0,0,61.49,38.25Z' />
        <path d='M64.9,71.29l-10.17-6.4,10.17-6.4a2.5,2.5,0,1,0-2.66-4.23L50,61.94,37.82,54.26a2.5,2.5,0,0,0-2.66,4.23l10.18,6.4-10.18,6.4a2.5,2.5,0,1,0,2.66,4.23L50,67.84l12.21,7.68a2.48,2.48,0,0,0,1.33.39,2.5,2.5,0,0,0,1.33-4.62Z' />
      </svg>
    </span>
  );
};

export default MuteIcon;
