import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import AuthStore from "core/stores/auth/AuthStore";
import { inject, observer } from "mobx-react";
import { userRole } from "library/core/stores/consts";

type Props = {
  authStore?: AuthStore;
};

const ReferralRedirectContainer: React.ComponentType<Props> = ({ authStore }) => {
  const { referringModelOrStudio } = useParams();
  const { setStudioReferral, setModelReferral } = authStore!;

  useEffect(() => {
    if (referringModelOrStudio?.trim !== "") {
      if (userRole === "model") {
        setModelReferral(referringModelOrStudio);
      } else if (userRole === "studio") {
        setStudioReferral(referringModelOrStudio);
      }
    }
  }, []);

  return <Redirect to={"/"} />;
};

export default inject("authStore")(observer(ReferralRedirectContainer));
