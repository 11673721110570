import React, { useEffect } from "react";
import { Switch, useLocation } from "react-router-dom";
import { inject, observer } from "mobx-react";
import CommonRoutes from "common/routes";
import PrivateRoute from "./private-route";
import AuthStore from "../auth/AuthStore";
import { ROUTE_PRODUCT_PREFIXES } from "./index";
import CamsStudioRoutes from "containers/studio/routes";
import CamsModelRoutes from "containers/model/routes";
import PageTitle from "library/components/_tailwind/page-title";
import qs from "querystring";
import { handleDebugCookie } from "library/core/utility/misc";
import RouteStore from "core/stores/route/RouteStore";

type Props = {
  authStore?: AuthStore;
  routeStore?: RouteStore;
};

const AppRoutes: React.ComponentType<Props> = ({ authStore, routeStore }) => {
  const { userRole } = authStore!;
  const { getRouteTitle } = routeStore!;

  const location = useLocation();

  useEffect(() => {
    const { debugCookie } = qs.parse(location.search.replace("?", ""));
    handleDebugCookie(debugCookie as string | undefined);
  }, []);

  return (
    <>
      <PageTitle title={getRouteTitle(location.pathname)} userRole={userRole} />
      <Switch>
        <PrivateRoute isNested path={`/${ROUTE_PRODUCT_PREFIXES.model}`}>
          <CamsModelRoutes />
        </PrivateRoute>
        <PrivateRoute isNested path={`/${ROUTE_PRODUCT_PREFIXES.studio}`}>
          <CamsStudioRoutes />
        </PrivateRoute>
        <CommonRoutes />
      </Switch>
    </>
  );
};

export default inject("authStore", "routeStore")(observer(AppRoutes));
