import { makeAutoObservable } from "mobx";
import { api } from "core/utility";
import validator from "validator";
import axios from "core/config/axios";
import config from "core/config";
import { format } from "date-fns";
import _ from "lodash";
import {
  BLOCK_ERROR_MESSAGES,
  BLOCK_FORM_ERROR_DEFAULTS,
  EMPTY_DOCUMENT,
  PASSWORD_RESETTINGS_ERROR_MESSAGES,
  PASSWORD_RESETTINGS_FORM_ERROR_DEFAULTS,
} from "./consts";
import { PasswordResettingsForm } from "./interfaces";
import { AGREEMENT_TYPE_TO_NAME } from "core/stores/auth/consts";
import { modalStore } from "library/core/stores/modal";
import { validationStore } from "library/core/stores/validation/ValidationStore";
import { snackbarStore } from "library/core/stores/snackbar/SnackbarStore";
import { logger } from "library/core/utility";
import { ProfileDocumentType } from "../../../my-page/stores/profile/enums";
import { ACCEPTED_FILE_TYPES_FOR_TAX_FORM } from "../../upload-tax-form-modal/const";
import { LVS_SITES, US_STATES } from "library/core/constants";
import {
  BlockedCountry,
  BlockedMember,
  IDocument,
} from "common/account-settings/stores/account-settings/types";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";
import { authStore, profileStore } from "core/stores";

export default class AccountSettingsStore {
  documents: IDocument[] = [];
  signingDocument: IDocument = EMPTY_DOCUMENT;
  isLoadingDocuments: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  isBlockingOrUnblocking: boolean = false;
  blockFormErrors = BLOCK_FORM_ERROR_DEFAULTS;
  isblockFormAllValid = false;
  blockedMembers: BlockedMember[] = [];
  modelMe: { [name: string]: string } = {};
  isBlockMembersLoading = false;
  blockedCountries: BlockedCountry[] = [];
  isBlockedCountriesLoading: boolean = false;
  resetPasswordFormErrors: PasswordResettingsForm =
    PASSWORD_RESETTINGS_FORM_ERROR_DEFAULTS;
  passwordResettingsFormAllValid = false;
  isPasswordResetInProgress: boolean = false;
  resetPasswordToken: string | undefined = undefined;

  public init = async () => {
    if (authStore.userRole === "model") {
      this.getBlockedMembers();
      this.getBlockedCountries();
    }
  };

  public getDocuments = async () => {
    this.isLoadingDocuments = true;
    try {
      const { profile } = profileStore;

      const documents = await Promise.all(
        profile.signed_agreements?.map(async (signedAgreement: any) => {
          const res = await axios.get(
            `/accounts/signup-agreement/${signedAgreement.agreement}`
          );
          return {
            id: res.data.id,
            name: AGREEMENT_TYPE_TO_NAME[res.data.agreement_type],
            created_at: format(
              new Date(signedAgreement.created_at),
              "MM/dd/yyyy"
            ),
            lastEdit: "",
            type: "",
            title: "",
            content: res.data.text,
            requires_signature: false,
            version: res.data.version,
            signature: signedAgreement.signature,
            date: "",
          };
        })
      );

      this.documents = documents;
    } catch (error) {
      logger.log("Get Documents error", config.showAjaxErrors ? error : "");
    } finally {
      this.isLoadingDocuments = false;
    }
  };

  sign = async () => {
    await api.contractDocuments.get(`${this.signingDocument.id}/sign/`);
    modalStore.closePrimaryModal();
    snackbarStore.enqueueSnackbar({
      message: {
        id: "signDocumentModal.updated",
        default: "{name} updated",
        parameters: { name },
      },
      closeButton: true,
      variant: SnackbarVariants.SUCCESS,
    });
  };

  upload = async () => {
    // TODO: Endpoint is not ready
    modalStore.closePrimaryModal();
    snackbarStore.enqueueSnackbar({
      message: {
        id: "uploadDocumentModal.upload",
        default: "Upload successful! it may take 1-3 days to process.",
      },
      closeButton: true,
      variant: SnackbarVariants.SUCCESS,
    });
  };

  setResetPasswordToken = (token?: string) => {
    this.resetPasswordToken = token;
  };

  public getBlockedMembers = async () => {
    try {
      if (profileStore.modelProfile) {
        this.isBlockMembersLoading = true;
        const results = await api.makeRequestsForAllPages(
          "modelsBase",
          "me/blocked-members/"
        );
        this.blockedMembers = (results as BlockedMember[]) || [];
      }
    } catch (e) {
      logger.log("getBlockedMembers error", config.showAjaxErrors ? e : "");
      this.blockedMembers = [];
    } finally {
      this.isBlockMembersLoading = false;
    }
  };

  public getBlockedCountries = async () => {
    try {
      if (profileStore.modelProfile) {
        this.isBlockedCountriesLoading = true;
        const { data } = await api.modelsBase.get({}, "me/blocked-countries/");

        this.blockedCountries = data.results.map(countryData => {
          let countryISO = countryData.country;
          if (countryData.subdivision) {
            countryISO += `-${countryData.subdivision}`;
          }

          return {
            ...countryData,
            countryLabel:
              authStore?.countries[countryISO] || US_STATES?.[countryISO],
          };
        });
      }
    } catch (e) {
      logger.log("getBlockedCountries error", config.showAjaxErrors ? e : "");
      this.blockedCountries = [];
    } finally {
      this.isBlockedCountriesLoading = false;
    }
  };

  /*
    UI Related Actions
  */

  setSigningDocument = (document: IDocument) => {
    this.signingDocument = document;
  };

  public unblockMember = async memberTobeUnBlock => {
    try {
      this.isBlockingOrUnblocking = true;
      await axios.delete(`models/me/blocked-members/${memberTobeUnBlock.id}/`);

      const updatedBlockMembers = this.blockedMembers.filter(
        member => memberTobeUnBlock.id !== member.id
      );
      this.blockedMembers = updatedBlockMembers;
      modalStore.closePrimaryModal();
      snackbarStore.enqueueSnackbar({
        message: {
          id: "accountSettings.blockUnblock.youUnblock",
          parameters: {
            name: memberTobeUnBlock.username,
          },
        },
        closeButton: true,
        variant: SnackbarVariants.SUCCESS,
      });
    } catch (error) {
      snackbarStore.enqueueSnackbar({
        message: {
          id: "fallback-to-default",
          default: error?.response?.data?.non_field_errors[0],
        },
        closeButton: true,
        variant: SnackbarVariants.ERROR,
      });
      logger.log("unblockMember error", config.showAjaxErrors ? error : "");
    } finally {
      this.isBlockingOrUnblocking = false;
    }
  };

  public blockMember = async memberTobeBlock => {
    const validKeys = Object.keys(memberTobeBlock);
    const isAllValid = validKeys
      .map(inputField =>
        this.validateBlockFormInput(inputField, memberTobeBlock[inputField])
      )
      .every(isValid => isValid);
    this.isblockFormAllValid = isAllValid;
    if (isAllValid) {
      try {
        this.isBlockingOrUnblocking = true;
        const { data } = await axios.post(`models/me/blocked-members/`, {
          username: memberTobeBlock.name,
          reason: memberTobeBlock.reason,
          site: memberTobeBlock.site.toLowerCase(),
        });
        this.blockedMembers = [...this.blockedMembers, data];
        modalStore.closePrimaryModal();
        snackbarStore.enqueueSnackbar({
          message: {
            id: "accountSettings.blockUnblock.youBlocked",
            parameters: {
              name: memberTobeBlock.name,
            },
          },
          closeButton: true,
          variant: SnackbarVariants.SUCCESS,
        });
      } catch (error) {
        snackbarStore.enqueueSnackbar({
          message: {
            id: "fallback-to-default",
            default: error?.response?.data?.non_field_errors[0],
          },
          closeButton: true,
          variant: SnackbarVariants.ERROR,
        });
        logger.log("blockMember error", config.showAjaxErrors ? error : "");
      } finally {
        this.isBlockingOrUnblocking = false;
      }
    }
  };

  validateBlockFormInput = (inputField: string, value: any) => {
    const isEmpty = this.isEmpty(value);
    if (inputField in this.validationFunctions) {
      const isValid = this.validationFunctions[inputField](value);
      this.blockFormErrors = {
        ...this.blockFormErrors,
        [inputField]:
          isEmpty || !isValid
            ? this.getErrorMessage(
                isEmpty,
                isValid,
                inputField,
                BLOCK_ERROR_MESSAGES,
                value
              )
            : null,
      };
      return !isEmpty && isValid;
    } else {
      this.blockFormErrors = {
        ...this.blockFormErrors,
        [inputField]: isEmpty
          ? BLOCK_ERROR_MESSAGES[inputField + "_empty"]
          : null,
      };
      return !isEmpty;
    }
  };

  private validationFunctions = {
    email: (value: string) => validator.isEmail(value),
    username: (value: string) =>
      new RegExp(/^[A-Za-z0-9]+(?:[_][A-Za-z0-9]+)*$/).test(value),
    emailOrUsername: (value: any) =>
      validator.isEmail(value) ||
      new RegExp(/^[A-Za-z0-9]+(?:[_][A-Za-z0-9]+)*$/).test(value),
    originalPassword: (value: string) =>
      new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/).test(value),
    newPassword: (value: string) =>
      new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/).test(value),
    reEnterNewPassword: (value: string) =>
      new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/).test(value),
    site: (site: string) =>
      LVS_SITES.map(s => s.toLowerCase()).includes(site.toLowerCase()),
  };

  private isEmpty(value: any) {
    return value === undefined || value === null || validator.isEmpty(value);
  }

  validateResetPwdFormInput = (inputField, value: any) => {
    const isEmpty = this.isEmpty(value);
    if (inputField in this.validationFunctions) {
      const isValid = this.validationFunctions[inputField](value);
      this.resetPasswordFormErrors = {
        ...this.resetPasswordFormErrors,
        [inputField]:
          isEmpty || !isValid
            ? this.getErrorMessage(
                isEmpty,
                isValid,
                inputField,
                PASSWORD_RESETTINGS_ERROR_MESSAGES
              )
            : null,
      };
      return !isEmpty && isValid;
    } else {
      this.resetPasswordFormErrors = {
        ...this.resetPasswordFormErrors,
        [inputField]: isEmpty
          ? PASSWORD_RESETTINGS_ERROR_MESSAGES[inputField + "_empty"]
          : null,
      };

      return !isEmpty;
    }
  };

  private getErrorMessage(isEmpty, isValid, field, errorMessages, value?: any) {
    let emptyErrorMessage = errorMessages[field + "_empty"];
    let invalidErrorMessage = errorMessages[field + "_invalid"];
    let fallbackErrorMessage = errorMessages[field];
    const isEmptyErrorMessageFunction = typeof emptyErrorMessage === "function";
    const isInvalidErrorMessageFunction =
      typeof invalidErrorMessage === "function";
    const isFallbackErrorMessageFunction =
      typeof fallbackErrorMessage === "function";

    if (isEmptyErrorMessageFunction) {
      emptyErrorMessage = emptyErrorMessage(value);
    }

    if (isInvalidErrorMessageFunction) {
      invalidErrorMessage = invalidErrorMessage(value);
    }

    if (isFallbackErrorMessageFunction) {
      fallbackErrorMessage = fallbackErrorMessage(value);
    }

    const errorMessage = isEmpty
      ? emptyErrorMessage
      : !isValid
      ? invalidErrorMessage
      : fallbackErrorMessage;

    return errorMessage;
  }

  clearBlockErrors = () => {
    this.blockFormErrors = { ...BLOCK_FORM_ERROR_DEFAULTS };
  };

  clearResetErrors = () => {
    this.resetPasswordFormErrors = {
      ...PASSWORD_RESETTINGS_FORM_ERROR_DEFAULTS,
    };
    validationStore.clearErrors();
  };

  public blockCountry = async (countryCodes: string[]) => {
    try {
      this.isBlockingOrUnblocking = true;
      await Promise.all(
        countryCodes.map(async country => {
          const isCountryBlockedAlready = _.find(
            this.blockedCountries,
            (o: any) => {
              const state = country.split("-")[1] || "";
              return o.country == country || o.subdivision == state;
            }
          );
          if (!isCountryBlockedAlready) {
            const params: { country?: string; subdivision?: string } = {};

            if (country?.indexOf("-") > -1) {
              // for US only
              const isoSplit = country.split("-");
              params.country = isoSplit[0];
              params.subdivision = isoSplit[1];
            } else {
              params.country = country;
            }
            const { data } = await axios.post(
              `models/me/blocked-countries/`,
              params
            );
            data.countryLabel =
              authStore?.countries[country] || US_STATES[country];
            this.blockedCountries = [...this.blockedCountries, data];
          }
        })
      );
      modalStore.closePrimaryModal();
      snackbarStore.enqueueSnackbar({
        message: {
          id: "accountSettings.blockUnblock.youBlocked",
          parameters: {
            name: countryCodes
              .map(code => authStore?.countries[code] || US_STATES[code])
              ?.join(", "),
          },
        },
        closeButton: true,
        variant: SnackbarVariants.SUCCESS,
      });
    } catch (error) {
      snackbarStore.enqueueSnackbar({
        message: {
          id: "fallback-to-default",
          default: error?.response?.data?.non_field_errors[0],
        },
        closeButton: true,
        variant: SnackbarVariants.ERROR,
      });
      logger.log("blockCountry error", config.showAjaxErrors ? error : "");
    } finally {
      this.isBlockingOrUnblocking = false;
    }
  };

  public unblockCountry = async countryToUnblock => {
    try {
      this.isBlockingOrUnblocking = true;
      await axios.delete(`models/me/blocked-countries/${countryToUnblock.id}/`);

      const updatedBlockedCountries = this.blockedCountries.filter(
        (country: any) => countryToUnblock.id !== country.id
      );
      const country_label =
        authStore?.countries[countryToUnblock.countryLabel] ||
        US_STATES[countryToUnblock.countryLabel] ||
        countryToUnblock.countryLabel;
      this.blockedCountries = updatedBlockedCountries;
      modalStore.closePrimaryModal();
      snackbarStore.enqueueSnackbar({
        message: {
          id: "accountSettings.blockUnblock.youUnblock",
          parameters: {
            name: country_label,
          },
        },
        closeButton: true,
        variant: SnackbarVariants.SUCCESS,
      });
    } catch (error) {
      snackbarStore.enqueueSnackbar({
        message: {
          id: "fallback-to-default",
          default: error?.response?.data?.non_field_errors[0],
        },
        closeButton: true,
        variant: SnackbarVariants.ERROR,
      });
      logger.log("unblockCountry error", config.showAjaxErrors ? error : "");
    } finally {
      this.isBlockingOrUnblocking = false;
    }
  };

  public uploadTaxForm = async (files: File[]) => {
    try {
      if (!files.length) {
        throw new Error("no files to upload");
      }

      const { uploadDocument, updateTaxFileKey } = profileStore!;
      const { key } = await uploadDocument(files, ProfileDocumentType.TAX_FORM);
      const tax_file = await updateTaxFileKey(key);
      modalStore.closePrimaryModal();
      snackbarStore.enqueueSnackbar({
        message: {
          id: "uploadTaxFormModal.uploadTaxFormSuccess",
          default: "Upload successful! it may take 1-3 days to process.",
        },
        closeButton: true,
        variant: SnackbarVariants.SUCCESS,
      });
      return tax_file;
    } catch (error) {
      const extensions: String[] = [];
      ACCEPTED_FILE_TYPES_FOR_TAX_FORM.forEach(type => {
        extensions.push(type.split("/")[1]);
      });
      snackbarStore.enqueueSnackbar({
        message: {
          id: "uploadTaxFormModal.uploadTaxFormFailure",
          default:
            "Try again using one of the supported file extensions: {fileExtensions}",
          parameters: { fileExtensions: extensions.join(", ") },
        },
        closeButton: true,
        variant: SnackbarVariants.ERROR,
      });
      logger.log("AccountSettingsStore: Error uploading media", error);
    }
  };
}
