import {
  PERIOD_TYPES,
  TransactionLocation,
  TransactionProduct,
  TransactionProductSubType,
} from "./enums";
import { PayPeriod, Transaction } from "./types";
import {
  add,
  endOfMonth,
  format,
  getDate,
  getMonth,
  getYear,
  isPast,
  isWithinInterval,
  startOfMonth,
  sub,
} from "date-fns";
import { dateProcess } from "library/core/utility";

export const calculatePeriod = (
  date: Date,
  periodType: PERIOD_TYPES
): PayPeriod => {
  const day = date.getDate();
  let beginningOfPeriod;
  let endOfPeriod;

  if (periodType === PERIOD_TYPES.payPeriod_8_22) {
    if (day >= 8 && day < 21) {
      beginningOfPeriod = add(startOfMonth(date), { days: 7 });
      endOfPeriod = add(startOfMonth(date), { days: 20 });
    } else if (day < 8) {
      beginningOfPeriod = add(sub(startOfMonth(date), { months: 1 }), {
        days: 21,
      });
      endOfPeriod = add(startOfMonth(date), { days: 6 });
    } else {
      beginningOfPeriod = add(startOfMonth(date), { days: 21 });
      endOfPeriod = add(startOfMonth(add(beginningOfPeriod, { days: 10 })), {
        days: 10,
      });
    }
  } else {
    if (day < 16) {
      beginningOfPeriod = startOfMonth(date);
      endOfPeriod = add(beginningOfPeriod, { days: 14 });
    } else {
      beginningOfPeriod = add(startOfMonth(date), { days: 15 });
      endOfPeriod = endOfMonth(date);
    }
  }

  const startDay = getDate(beginningOfPeriod);
  const startMonth = getMonth(beginningOfPeriod);
  const startYear = getYear(beginningOfPeriod);
  const endDay = getDate(endOfPeriod);
  const endMonth = getMonth(endOfPeriod);
  const endYear = getYear(endOfPeriod);
  const period = `${format(beginningOfPeriod, "MM/dd")} - ${format(
    endOfPeriod,
    "MM/dd"
  )}`;

  return {
    startDay,
    startMonth,
    startYear,
    endDay,
    endMonth,
    endYear,
    period,
  };
};

export const calculateStudioPayPeriods = (
  startYear: string | number,
  periodType: PERIOD_TYPES
) => {
  const periods: PayPeriod[] = [];
  const dateString = `${startYear}-01-10`;
  let date = new Date(dateString);
  for (let i = 0; i < 24; i++) {
    const period = calculatePeriod(date, periodType);
    const startDate = createPeriodDateObject(period, "start");
    const endDate = createPeriodDateObject(period, "end");
    if (
      isPast(endDate) ||
      isWithinInterval(dateProcess.today, { start: startDate, end: endDate })
    ) {
      periods.push(period);
    }

    if (periodType === PERIOD_TYPES.payPeriod_8_22) {
      if (i % 2 === 0) {
        date = add(startOfMonth(date), { days: 25 });
      } else {
        date = add(date, { days: 15 });
      }
    } else {
      if (i % 2 === 0) {
        date = endOfMonth(date);
      } else {
        date = startOfMonth(add(date, { days: 2 }));
      }
    }
  }

  // sort from recent to old
  return periods.reverse();
};

export const generatePayPeriodYears = (startYear: number = 2019) => {
  const years: number[] = [];
  const current_year = Number(new Date().getFullYear().toString());
  for (let i = current_year; i >= startYear; i--) {
    years.push(i);
  }
  return years;
};

export const createPeriodDateObject = (
  period: PayPeriod,
  startOrEnd: "start" | "end"
) => {
  let date = dateProcess.createDate(
    period.startYear,
    period.startMonth,
    period.startDay
  );
  if (startOrEnd === "end") {
    date = dateProcess.createDate(
      period.endYear,
      period.endMonth,
      period.endDay
    );
  }

  return date;
};

export const getFormattedPeriodFullDateYYYYMMDD = (
  period: PayPeriod,
  startOrEnd: "start" | "end"
): string => {
  if (
    period.startDay != 1 &&
    period.startDay != 8 &&
    period.startDay != 16 &&
    period.startDay != 22
  ) {
    period.startDay = 1;
  }
  const date = createPeriodDateObject(period, startOrEnd);
  return format(date, "yyyy-MM-dd");
};

export const getFormattedPeriodFullDateMMdd = (
  period: PayPeriod,
  startOrEnd: "start" | "end"
): string => {
  const date = createPeriodDateObject(period, startOrEnd);
  return format(date, "MM/dd");
};

export const getSelectedPeriodValue = (period: PayPeriod) => {
  const startDateFormatted = getFormattedPeriodFullDateMMdd(period, "start");
  const endDateFormatted = getFormattedPeriodFullDateMMdd(period, "end");
  const label = `${startDateFormatted} - ${endDateFormatted}`;
  return label;
};

export const adjustedActivityProduct = (activity: Transaction): string => {
  if (
    activity.product == TransactionProduct.TIP &&
    activity.location == TransactionLocation.OFFLINE
  ) {
    return TransactionProduct.TIP_OFFLINE;
  } else if (
    activity.product == TransactionProduct.VIRTUAL_GIFT &&
    activity.location == TransactionLocation.OFFLINE
  ) {
    return TransactionProduct.VIRTUAL_GIFT_OFFLINE;
  }
  return activity.product;
};

/**
 * Get the model's percentage earned
 * @param cost  - USD amount in total received
 * @param earnings - USD amount earned after tier cut
 * @returns percentage of cost that was earned by the model as a decimal
 */
export const calculateModelCutPercentage = (cost, earnings): number => {
  if (!cost || !earnings) {
    return 0;
  }

  return Math.round((earnings / cost) * 100) / 100;
};

export const TransactionProductSubTypeDetailMapping: Record<
  TransactionProductSubType,
  string
> = {
  [TransactionProductSubType.VIRTUAL_GIFT]: "Virtual Gift",
  [TransactionProductSubType.TIP_ONLINE]: "Tip Online",
  [TransactionProductSubType.TIP_OFFLINE]: "Tip Offline",
  [TransactionProductSubType.CHAT_SHOW]: "Chat",
  [TransactionProductSubType.CONNEXION]: "Connexion",
  [TransactionProductSubType.FANCLUB]: "FanClub",
  [TransactionProductSubType.MAINTENANCE]: "Maintenance",
  [TransactionProductSubType.BOUNTY]: "Bounty",
  [TransactionProductSubType.BONUS]: "Bonus",
  [TransactionProductSubType.TEXT]: "Text",
  [TransactionProductSubType.MODEL_MEDIA]: "Model Media",
  [TransactionProductSubType.TIERED_PRODUCT]: "Tiered Product",
  [TransactionProductSubType.BUZZ]: "Buzz",
  [TransactionProductSubType.SUPER_BUZZ]: "Super Buzz",
};

export const getTransactionDetails = (
  transaction: Transaction
): string | undefined => {
  if (transaction.product_subtype) {
    return (
      TransactionProductSubTypeDetailMapping[transaction.product_subtype] ??
      transaction.note ??
      ""
    );
  }
  return transaction.note;
};

export const getTransactionInProducts = (
  transactions: Transaction[],
  types: string[]
) => {
  return transactions
  .filter(transaction => types.includes(transaction.product))
  .reduce((acc: number, transaction) => acc += transaction.earning_usd, 0);
};
