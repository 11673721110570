import React, { useMemo } from "react";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import { injectIntl, WrappedComponentProps } from "react-intl";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import EarningsStore from "../../_stores/earnings/EarningsStore";
import MemberTokenActivityTableRow from "./member-token-activity-table-row";
import MemberTokenActivityTableHead from "./member-token-activity-table-head";
import TailwindSpinner from "library/components/_tailwind/spinner";
import { PayoutFilterMainType } from "common/earnings/_stores/earnings/types";
import { PAYOUT_FILTER_PRODUCT_MAPPINGS } from "common/earnings/_stores/earnings/consts";

type PayoutTableProps = {
  earningsStore?: EarningsStore;
};

const PayoutTable: React.FunctionComponent<
  PayoutTableProps & WrappedComponentProps
> = ({ earningsStore }) => {
  const {
    allTransactions,
    currentProduct,
    loadingEarnings,
    loadingModelEarnings,
    payoutFilters,
  } = earningsStore!;
  const filteredTransactions = useMemo(() => {
    let transactions = allTransactions.map(transaction => {
      // render upforit as streamray
      if (transaction.website.includes("upforit")) {
        transaction.website = "streamray.com";
      }

      return transaction;
    });

    const activeFilters = payoutFilters.productTypes.reduce(
      (acc: string[], product) => {
        return [...acc, ...PAYOUT_FILTER_PRODUCT_MAPPINGS[product]];
      },
      []
    );

    if (
      payoutFilters.category === PayoutFilterMainType.TIER_PAYOUT ||
      payoutFilters.category === PayoutFilterMainType.FLAT_PAYOUT
    ) {
      transactions = transactions.filter(transaction =>
        activeFilters.includes(transaction.product)
      );
    }

    if (payoutFilters.category === PayoutFilterMainType.BONUS_PAYOUT) {
      transactions = transactions.filter(transaction =>
        activeFilters.includes(transaction.transaction_type)
      );
    }

    if (payoutFilters.category === PayoutFilterMainType.CREDIT_REFUND) {
      transactions = transactions.filter(transaction =>
        activeFilters.includes(transaction.transaction_type)
      );
    }

    if (
      currentProduct &&
      currentProduct.filterKey &&
      currentProduct.filterKey !== "all"
    ) {
      transactions = _.filter(transactions, {
        product: currentProduct.filterKey,
      });
    }
    return transactions;
  }, [allTransactions, currentProduct, payoutFilters]);

  if (loadingEarnings || loadingModelEarnings) {
    return (
      <TailwindFlex
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        width={"w-full"}
        height={"h-full"}>
        <TailwindSpinner />
      </TailwindFlex>
    );
  }

  if (!filteredTransactions?.length) {
    return (
      <TailwindFlex
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        width={"w-full"}
        height={"h-full"}>
        <TailwindText textColor='text-main-color'>
          There are no activity yet.
        </TailwindText>
      </TailwindFlex>
    );
  }

  return (
    <TailwindFlex flexDirection={"flex-row"} height={"h-auto"} width={"w-full"}>
      <TailwindFlex
        width={"w-full"}
        overflow={["overflow-x-auto", "overflow-y-hidden"]}
        className={["bg-primary-bg-color"]}
        borderRadius={"rounded-xl"}
        flexDirection={"flex-col"}>
        <TailwindFlex
          flexDirection={"flex-col"}
          height={"h-full"}
          width={"w-full"}
          textColor='text-main-color'
          minWidth={"min-w-160"}>
          <MemberTokenActivityTableHead />
          <TailwindFlex
            flexDirection={"flex-col"}
            overflow={["overflow-y-auto", "overflow-x-hidden"]}
            textColor='text-main-color'
            maxHeight={"max-h-96"}>
            {filteredTransactions.map(activity => (
              <MemberTokenActivityTableRow
                key={`${activity.id}-t`}
                activity={activity}
              />
            ))}
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(inject("earningsStore")(observer(PayoutTable)));
