import React, { useEffect } from "react";
import { Router } from "react-router";
import "./App.scss";
import "react-virtualized/styles.css";
import { Provider } from "mobx-react";
import { history } from "library/core/utility";
import { IntlProvider } from "react-intl";
import { CssBaseline } from "@material-ui/core";
import AppRoutes from "core/stores/route/AppRoutes";
import QAWidget from "./containers/_layout/_components/qa-widget";
import { services, stores } from "./core/stores";
import Snackbar from "library/components/snackbar";
import { languageStore } from "library/core/stores/language/LanguageStore";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import config from "core/config";
import StorybookApp from "./library/src/App";
import CookieStorage from "library/core/utility/cookie-storage";
import { broadcastStrategy } from "common/broadcast/_stores/BroadcastStrategy";
import LocalStorage from "library/core/utility/local-storage";

type AppProps = {};

const App: React.FunctionComponent<AppProps> = () => {
  const { googleCaptchaSiteKeyV3, env, modelsUrl, studiosUrl } = config!;

  if (window.location.href.includes("?storybookEnabled=true")) {
    CookieStorage.set("storybookEnabled", "true");
  } else {
    CookieStorage.remove("storybookEnabled");
  }

  if (CookieStorage.get("storybookEnabled")) {
    return <StorybookApp />;
  }
  useEffect(() => {
    LocalStorage.clear();
  }, []);

  return (
    <Provider {...services} {...stores} broadcastStrategy={broadcastStrategy}>
      <Router history={history}>
        <GoogleReCaptchaProvider reCaptchaKey={googleCaptchaSiteKeyV3}>
          <IntlProvider
            locale={languageStore.language}
            messages={languageStore.getMessages(languageStore.language)}>
            <CssBaseline />
            <Snackbar />
            <AppRoutes />
            {(env !== "production" ||
              (modelsUrl.includes("models.qa") &&
                studiosUrl.includes("studios.qa"))) && <QAWidget />}
          </IntlProvider>
        </GoogleReCaptchaProvider>
      </Router>
    </Provider>
  );
};

export default App;
