import React, { useCallback, useMemo } from "react";
import { inject, observer } from "mobx-react";
import TailwindCard from "library/components/_tailwind/card";
import TailwindFlex from "library/components/_tailwind/flex";
import BroadcastChatTabs from "./chat-tabs";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindButton from "library/components/_tailwind/button";
import BroadcastChatTopic from "./chat-topic";
import TailwindSpinner from "library/components/_tailwind/spinner";
import { ChatState } from "common/broadcast/_stores/chat/enums";
import { broadcastStore, nodeChatStore } from "core/stores";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import { BroadcastStreamType } from "../_stores/broadcast-stream/enums";
import BroadcastStreamStore from "../_stores/broadcast-stream/BroadcastStreamStore";
import BroadcastStartStreamingButton from "../broadcast-control/broadcast-start-streaming-button";
import { BroadcastStreamState } from "../_stores/broadcast/enums";
import TailwindBox from "library/components/_tailwind/box";
import { languageStore } from "library/core/stores/language/LanguageStore";
import BroadcastSettingsOverlay from "../broadcast-settings-overlay";

type IChatBoxProps = {
  broadcastStore?: BroadcastStore;
  broadcastStreamStore?: BroadcastStreamStore;
};

const ChatBox: React.FunctionComponent<IChatBoxProps> = ({
  broadcastStreamStore,
}) => {
  const { reloadChat, chatState } = nodeChatStore!;
  const { isShowStarted, streamState } = broadcastStore!;
  const { setStreamType, obsStreamConnected, streamType, mediaStream } =
    broadcastStreamStore!;

  const reload = useCallback(() => {
    reloadChat("reload");
  }, []);

  const onClickStreamTypeButton = useCallback(
    (streamType: BroadcastStreamType) => {
      setStreamType(streamType);
    },
    []
  );

  const shouldShowBCButton = useMemo(() => {
    return (
      streamState !== BroadcastStreamState.started &&
      ((obsStreamConnected && streamType == BroadcastStreamType.OBS) ||
        (mediaStream && streamType === BroadcastStreamType.WEBRTC))
    );
  }, [obsStreamConnected, streamType, streamState, mediaStream]);

  return (
    <TailwindCard
      className={["BroadcastChatBox", "filter"]}
      width={"w-full"}
      height={"h-full"}
      blur={"blur-none"}
      flexDirection={"flex-col"}
      rounded={true}
      backgroundColor={"bg-tertiary-bg-color"}>
      {chatState === ChatState.started && (
        <TailwindFlex
          flexDirection={"flex-col"}
          height={"h-full"}
          padding={["pb-4"]}
          position={"relative"}>
          <TailwindFlex
            position={"relative"}
            justifyContent='justify-center'
            borderColor={"border-white"}
            borderStyle={"border-solid"}
            display={"hidden"}
            borderWidth={["border-b"]}
            width={"w-full"}>
            <BroadcastChatTopic />
          </TailwindFlex>
          <BroadcastChatTabs />
        </TailwindFlex>
      )}
      {chatState === ChatState.errored && (
        <TailwindFlex
          height={"h-full"}
          flexDirection={"flex-col"}
          justifyContent={"justify-center"}
          alignItems={"items-center"}>
          <TailwindTranslatedText
            descriptor={{
              id: "broadcast.chatError",
              defaultMessage: "Chat Initialize Failed",
            }}
            margin={["mb-4"]}
          />
          <TailwindButton
            fullWidth={false}
            backgroundColor={"bg-gray-300"}
            onClick={reload}>
            <TailwindTranslatedText
              fontWeight={"font-bold"}
              descriptor={{
                id: "broadcast.chatReload",
                defaultMessage: "Reload",
              }}
            />
          </TailwindButton>
        </TailwindFlex>
      )}

      {(chatState === ChatState.startingChat ||
        !isShowStarted ||
        chatState === ChatState.stoppingChat) && (
        <TailwindFlex
          height={"h-full"}
          alignItems={"items-center"}
          justifyContent={"justify-center"}
          flexDirection={"flex-col"}>
          {!isShowStarted ? (
            <>
              <TailwindTranslatedText
                margin={["mb-12", "mt-8"]}
                fontSize={"text-3xl"}
                pseudoClasses={["md:text-xl"]}
                fontWeight={"font-bold"}
                textColor='text-main-color'
                className='whitespace-nowrap'
                descriptor={{
                  id: "broadcast.chatInstruct1",
                  defaultMessage: "Welcome to the Streamray Broadcaster.",
                }}
              />
              {!shouldShowBCButton ? (
                <TailwindFlex
                  justifyContent={"justify-center"}
                  padding={["px-6"]}>
                  <TailwindTranslatedText
                    margin={["mb-4"]}
                    textColor='text-main-color'
                    fontSize={"text-2xl"}
                    pseudoClasses={["md:text-lg"]}
                    className='whitespace-nowrap'
                    descriptor={{
                      id: "broadcast.chatInstructSelect1",
                      defaultMessage: "To begin, choose",
                    }}
                  />
                  <TailwindButton
                    fullWidth={false}
                    margin={["mx-2"]}
                    backgroundColor={"secondary"}
                    onClick={() =>
                      onClickStreamTypeButton(BroadcastStreamType.WEBRTC)
                    }>
                    {languageStore.intl.formatMessage({
                      id: "broadcast.broadcastPreview.use-webcam",
                      defaultMessage: "Use Ny Webcam",
                    })}
                  </TailwindButton>
                  <TailwindTranslatedText
                    margin={["mb-4"]}
                    textColor='text-main-color'
                    fontSize={"text-2xl"}
                    pseudoClasses={["md:text-lg"]}
                    className='whitespace-nowrap'
                    descriptor={{
                      id: "broadcast.chatInstructSelect2",
                      defaultMessage: "or",
                    }}
                  />
                  <TailwindButton
                    fullWidth={false}
                    margin={["mx-2"]}
                    backgroundColor={"primary"}
                    onClick={() =>
                      onClickStreamTypeButton(BroadcastStreamType.OBS)
                    }>
                    {languageStore.intl.formatMessage({
                      id: "broadcast.broadcastPreview.use-obs",
                      defaultMessage: "Use OBS",
                    })}
                  </TailwindButton>
                </TailwindFlex>
              ) : (
                <>
                  <TailwindFlex
                    flexDirection='flex-col'
                    justifyContent={"justify-center"}
                    alignItems={"items-center"}
                    padding={["px-6"]}>
                    <TailwindTranslatedText
                      margin={["mb-4"]}
                      fontSize={"text-2xl"}
                      pseudoClasses={["md:text-lg"]}
                      className='whitespace-nowrap'
                      textColor='text-main-color'
                      descriptor={{
                        id: "broadcast.chatInstruct3-1",
                        defaultMessage: "When you are ready to go live, click",
                      }}
                    />
                    <TailwindBox margin={["-mt-2", "mx-2"]}>
                      <BroadcastStartStreamingButton />
                    </TailwindBox>
                    <TailwindTranslatedText
                      margin={["mb-4"]}
                      fontSize={"text-2xl"}
                      pseudoClasses={["md:text-lg"]}
                      textColor='text-main-color'
                      className='whitespace-nowrap'
                      descriptor={{
                        id: "broadcast.chatInstruct3-2",
                        defaultMessage: "to begin your show.",
                      }}
                    />
                  </TailwindFlex>
                  <TailwindFlex
                    width='w-full'
                    flexDirection='flex-col'
                    maxWidth='max-w-7xl'>
                    <BroadcastSettingsOverlay mini={true} />
                  </TailwindFlex>
                </>
              )}
            </>
          ) : (
            <>
              <TailwindSpinner margin={["mt-4", "mx-auto"]} />
              <TailwindTranslatedText
                margin={["my-6"]}
                textColor='text-main-color'
                fontSize='text-xl'
                descriptor={{
                  id: "broadcast.chatLoading",
                  defaultMessage: "Chat Is Loading",
                }}
              />
            </>
          )}
        </TailwindFlex>
      )}
    </TailwindCard>
  );
};

export default inject(
  "chatStore",
  "broadcastStore",
  "broadcastStreamStore"
)(observer(ChatBox));
