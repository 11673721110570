import React, { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import TailwindFlex from "library/components/_tailwind/flex";
import MCSharedMediaModalGrid from "./media-grid";
import { useInView } from "react-intersection-observer";
import { SharedMedia } from "./types";

type MCSharedMediaModalListProps = {
  media: SharedMedia[];
  isLoading?: boolean;
  onBottomInView?: () => void;
  isMCPhase2Active?: boolean;
};

const MCSharedMediaModalList: React.FunctionComponent<MCSharedMediaModalListProps> =
  ({ media, isLoading: isMediaLoading, onBottomInView, isMCPhase2Active }) => {
    const { ref: scrollingPixelRef, inView } = useInView({
      delay: 500,
    });

    useEffect(() => {
      if (inView) {
        onBottomInView?.();
      }
    }, [inView]);

    return (
      <TailwindFlex className={["SharedMediaModalList"]} position={"relative"}>
        <TailwindFlex
          position={"relative"}
          flexGrow={"flex-grow"}
          flexDirection={"flex-col"}>
          <MCSharedMediaModalGrid
            media={media}
            isMCPhase2Active={isMCPhase2Active}
          />

          <TailwindFlex
            justifyContent={"justify-center"}
            alignItems={"items-center"}
            padding={["p-5", "pb-8"]}
            position={"relative"}>
            <div
              ref={scrollingPixelRef}
              style={{
                position: "absolute",
                bottom: "100px",
                left: 0,
              }}></div>
            {isMediaLoading ? <CircularProgress color='primary' /> : null}
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
    );
  };

export default MCSharedMediaModalList;
