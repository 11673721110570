import EarningsStore from "common/earnings/_stores/earnings/EarningsStore";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindTranslatedNumber from "library/components/_tailwind/translated-number";
import { inject, observer } from "mobx-react";
import React from "react";
import { useIntl } from "react-intl";

type Props = {
  earningsStore?: EarningsStore;
  profileStore?: ProfileStore;
};

const periodsList = [32, 50, 60, 70];

const PayoutPeriodInfo = ({ earningsStore, profileStore }: Props) => {
  const intl = useIntl();
  const { isStudioModel } = profileStore!;
  const { currentTier, amountNeededToNextTier, currentPeriodSums } =
    earningsStore!;

  const nextTier = periodsList[periodsList.indexOf(+currentTier) + 1];

  return (
    <TailwindFlex
      flexDirection={"flex-col"}
      height={"h-full"}
      width={"w-full"}
      textColor='text-main-color'
      borderRadius={"rounded-xl"}>
      <TailwindFlex
        flexGrow='flex-grow'
        borderRadius={"rounded-xl"}
        backgroundColor={"bg-primary-bg-color"}
        padding={["p-2"]}
        alignItems='items-center'
        justifyContent='justify-around'>
        <div className='TierPie'>
          <svg viewBox='0 0 32 32'>
            <circle className='base' strokeDasharray={`100 100`}></circle>
            <circle
              className='progress'
              strokeDasharray={`${currentTier || 0} 100`}></circle>
          </svg>

          <div className='TierPie__Inner'>
            <TailwindText textTransform='uppercase'>
              {intl.formatMessage({
                id: "payout.earnings.current-tier",
                defaultMessage: "Current Tier",
              })}
            </TailwindText>

            <TailwindText fontSize='text-2xl'>{currentTier}%</TailwindText>
          </div>
        </div>

        <TailwindFlex width='w-auto' margin={["ml-4"]} flexDirection='flex-col'>
          <TailwindTranslatedNumber
            textProps={{
              fontSize: "text-2xl",
            }}
            value={+(amountNeededToNextTier || 0)}
            style='currency'
            currency='USD'
          />
          <TailwindText textTransform='uppercase' fontSize='text-sm'>
            {intl.formatMessage(
              {
                id: "payout.earnings.more-needed",
                defaultMessage: "more needed to reach {nextTier}% tier",
              },
              {
                nextTier,
              }
            )}
          </TailwindText>

          {!isStudioModel && (
            <>
              <TailwindTranslatedNumber
                textProps={{
                  fontSize: "text-2xl",
                  margin: ["mt-2"],
                }}
                value={+(currentPeriodSums?.grandTotal || 0)}
                style='currency'
                currency='USD'
              />
              <TailwindText textTransform='uppercase' fontSize='text-sm'>
                {intl.formatMessage({
                  id: "payout.earnings.current-member-spend",
                  defaultMessage: "Current Member Spend",
                })}
              </TailwindText>
            </>
          )}
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default inject(
  "earningsStore",
  "profileStore"
)(observer(PayoutPeriodInfo));
