import React from "react";
import "./styles.scss";
import classnames from "classnames";

type VerifiedIconProps = {
  className?: string;
  color?: "blue" | "purple";
};

const VerifiedIcon: React.ComponentType<VerifiedIconProps> = ({
  className,
  color = "blue",
}) => (
  <span
    className={classnames(
      "VerifiedIcon",
      `VerifiedIcon--color-${color}`,
      className && className
    )}
  >
    <svg
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 34 34"
    >
      <g transform="translate(1, 1)">
        <g stroke="none">
          <path
            stroke="none"
            d="M28.1,7.2c-1.3-1.3-3.2-1.3-4.4,0l-11,11.1l-4.4-4.4c-1.3-1.3-3.2-1.3-4.4,0s-1.3,3.2,0,4.4l6.6,6.6c1.3,1.3,3.2,1.3,4.4,0l13.3-13.3C29.4,10.3,29.4,8.4,28.1,7.2z"
          />
        </g>
      </g>
    </svg>
  </span>
);

export default VerifiedIcon;
