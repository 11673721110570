import React, { FunctionComponent } from "react";
import { inject, observer } from "mobx-react";
import "./styles.scss";
import ProfileStore from "../my-page/stores/profile/ProfileStore";
import AuthStore from "core/stores/auth/AuthStore";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindList, {
  TailwindListType,
} from "library/components/_tailwind/list";
import TailwindListItem from "library/components/_tailwind/list/list-item";

type AccountSuspendedDisclaimerProps = {
  profileStore?: ProfileStore;
  authStore?: AuthStore;
  user_name?: string;
};

const AccountSuspendedDisclaimer: FunctionComponent<AccountSuspendedDisclaimerProps> = ({
  profileStore,
  authStore,
}) => {
  const { profile } = profileStore!;
  const { userRole } = authStore!;
  const username = profile?.username || "";
  const signatures = {
    model: {
      id: "blocked-login-error.the-cams-team",
      defaultMessage: "The Cams Team",
    },
    studio: {
      id: "blocked-login-error.the-cams-team",
      defaultMessage: "The Cams Team",
    },
  };

  return (
    <TailwindFlex
      flexDirection={"flex-col"}
      width={"w-full"}
      className={["AccountSuspendedDisclaimer"]}>
      <TailwindTranslatedText
        headingProps={{ level: 5, textAlign: "text-center" }}
        descriptor={{
          id: "blocked-login-error.actionRequired",
          defaultMessage: "Action Required",
        }}
      />
      {username && (
        <TailwindFlex margin={["mt-6", "mb-4"]}>
          <TailwindTranslatedText
            descriptor={{
              id: "blocked-login-error.dearUser",
              defaultMessage: "Dear {username},",
            }}
            values={{
              username,
            }}
          />
        </TailwindFlex>
      )}
      <TailwindFlex margin={["mb-4"]}>
        <TailwindTranslatedText
          descriptor={{
            id: "blocked-login-error.accountSelectedForReview",
            defaultMessage:
              "Based on recent activity, your account was selected for manual review.",
          }}
        />
      </TailwindFlex>
      <TailwindFlex margin={["mb-2"]}>
        <TailwindTranslatedText
          descriptor={{
            id: "blocked-login-error.contactSupport",
            defaultMessage: "Please Contact support for assistance:",
          }}
        />
      </TailwindFlex>
      <TailwindFlex margin={["ml-8", "mb-4"]}>
        <TailwindList type={TailwindListType.ul}>
          <TailwindListItem>
            1 (699) 208-0374 (
            <TailwindTranslatedText
              descriptor={{
                id: `blocked-login-error.local`,
                defaultMessage: "Local",
              }}
            />
            )
          </TailwindListItem>
          <TailwindListItem>
            1 (888) 546-0188 (
            <TailwindTranslatedText
              descriptor={{
                id: `blocked-login-error.toll-free-domestic`,
                defaultMessage: "Toll free domestic",
              }}
            />
            )
          </TailwindListItem>
        </TailwindList>
      </TailwindFlex>
      <TailwindFlex>
        <TailwindTranslatedText
          descriptor={{
            id: `blocked-login-error.sincerely`,
            defaultMessage: "Sincerely",
          }}
        />
      </TailwindFlex>
      {userRole && (
        <TailwindFlex>
          <TailwindTranslatedText
            descriptor={{
              id: signatures[userRole].id,
              defaultMessage: signatures[userRole].defaultMessage,
            }}
          />
        </TailwindFlex>
      )}
    </TailwindFlex>
  );
};

export default inject(
  "profileStore",
  "authStore"
)(observer(AccountSuspendedDisclaimer));
