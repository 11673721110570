export default class CopyToClipboard {
  copy(inputId: string): Promise<any> {
    const input: any = document.getElementById(inputId);
    input.select();
    input.setSelectionRange(0, 99999); /*For mobile devices*/
    return new Promise((resolve, reject) => {
      if (document.execCommand("copy")) resolve(input.value);
      else reject();
    });
  }
}
