import React, { useState, useRef, useMemo } from "react";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import PricingStore from "../_stores/pricing/PricingStore";
import useOutsideClick from "library/core/utility/hooks/useOutsideClick";
// import TailwindInput from "library/components/_tailwind/input";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";

type IChatTopicProps = {
  pricingStore?: PricingStore;
};

const BroadcastChatTopic: React.FunctionComponent<
  IChatTopicProps & WrappedComponentProps
> = ({ pricingStore }) => {
  const chatTopicRef = useRef<HTMLDivElement>(null);
  const [clickedOutside, setClickedOutside] = useState(true);
  const [newChatTopic, setNewChatTopic] = useState<string | null>(null);
  const { modelProducts, submitChatTopic, removeChatTopic } = pricingStore!;
  const { chat_settings } = modelProducts;
  const { chatTopic, pendingChatTopic } = chat_settings || {};

  useOutsideClick(chatTopicRef, async () => {
    if (!clickedOutside) {
      setClickedOutside(true);
      if (newChatTopic != chatTopic) {
        if (newChatTopic === "") {
          await removeChatTopic();
          setNewChatTopic(null);
        } else if (newChatTopic !== null) {
          await submitChatTopic(newChatTopic);
        }
      }
    }
  });

  // const handleFocus = useCallback(() => {
  //   setClickedOutside(false);
  // }, []);

  // const chatTopicToDisplay = useMemo(() => {
  //   if (newChatTopic === null) {
  //     if (!pendingChatTopic || chatTopic === pendingChatTopic) {
  //       return chatTopic || undefined;
  //     } else {
  //       return pendingChatTopic || undefined;
  //     }
  //   } else {
  //     return newChatTopic;
  //   }
  // }, [newChatTopic, chatTopic, pendingChatTopic]);

  const isPending = useMemo(() => {
    if (pendingChatTopic && chatTopic !== pendingChatTopic) {
      return true;
    } else {
      return false;
    }
  }, [chatTopic, pendingChatTopic]);

  // const handleTopicInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setNewChatTopic(e.target.value);
  // };

  const expand = useMemo(() => {
    return clickedOutside === false || chatTopic || pendingChatTopic;
  }, [clickedOutside, chatTopic, pendingChatTopic]);

  return (
    <TailwindFlex
      ref={chatTopicRef}
      width={expand ? "w-full" : "w-48"}
      alignItems={"items-center"}>
      {isPending && (
        <TailwindTranslatedText
          verticalAlign={"align-middle"}
          borderRadius={"rounded-full"}
          margin={["ml-1"]}
          whiteSpace={"whitespace-nowrap"}
          padding={["p-1", "px-2"]}
          height={"h-6"}
          backgroundColor={"bg-yellow-200"}
          fontSize={"text-xs"}
          descriptor={{
            id: "verbiage.pending-review",
            defaultMessage: "Pending Review",
          }}
        />
      )}
      {/* <TailwindInput
        boxProps={{ height: "h-10" }}
        className={["overflow-hidden", "text-center"]}
        backgroundColor={"bg-primary-bg-color"}
        value={chatTopicToDisplay}
        onChange={handleTopicInputChange}
        onFocus={handleFocus}
        fontSize={"text-sm"}
        placeholder={intl.formatMessage({
          id: "broadcast.chatTopic.inputPlaceholder",
          defaultMessage: "Enter a topic for your room",
        })}
      /> */}
    </TailwindFlex>
  );
};

export default injectIntl(inject("pricingStore")(observer(BroadcastChatTopic)));
