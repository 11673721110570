import React from "react";

import TailwindInput, { TailwindInputProps } from "../input";
import TailwindButton, { TailwindButtonProps } from "../button";
import TailwindFlex, { TailwindFlexProps } from "../flex";

type SearchBoxProps = {
  inputProps?: TailwindInputProps;
  buttonProps?: TailwindButtonProps;
  containerProps?: TailwindFlexProps;
};

const SearchBox: React.ComponentType<SearchBoxProps> = ({
  inputProps,
  buttonProps = {},
  containerProps,
}) => {
  return (
    <TailwindFlex {...containerProps}>
      <TailwindInput
        flexProps={{
          width: "w-auto",
          flexGrow: "flex-grow",
          textColor: "text-black",
          ...inputProps?.flexProps,
        }}
        boxProps={{
          backgroundColor: "bg-white",
          textColor: "text-black",
          ...inputProps?.boxProps,
        }}
        height={inputProps?.height || "h-full"}
        rounded={inputProps?.rounded !== undefined ? inputProps.rounded : false}
        borderWidth={inputProps?.borderWidth || []}
        fullHeight={
          inputProps?.fullHeight !== undefined ? inputProps.fullHeight : true
        }
        {...inputProps}
      />
      <TailwindButton
        rounded={false}
        backgroundColor={"primary"}
        fullWidth={false}
        className={["h-full"]}
        padding={["px-8"]}
        {...buttonProps}
      />
    </TailwindFlex>
  );
};

export default SearchBox;
