import React, { useState, useRef, useEffect } from "react";
import TailwindBox, { TailwindBoxProps } from "../box";
import "./style.scss";
import TailwindFlex, { TailwindFlexProps } from "../flex";
import TailwindIcon from "../icon";

import { inject, observer } from "mobx-react";

type KebabProps = {
  kebabProps?: TailwindBoxProps;
  otherOptions?: boolean;
};

function useCheckClickOutside(ref, handleClose, kebab) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        ref.current &&
        kebab.current &&
        !ref.current.contains(event.target) &&
        !kebab.current.contains(event.target)
      ) {
        handleClose();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, kebab]);
}

const TailwindKebabMenu: React.ComponentType<TailwindFlexProps & KebabProps> =
  ({ children, kebabProps, otherOptions = false, ...props }) => {
    const [kebabOpen, setKebabOpen] = useState(false);
    const drawer = useRef(null);
    const kebab = useRef(null);
    const handleKebab = () => setKebabOpen(!kebabOpen);
    const handleCloseDrawer = () => setKebabOpen(false);
    useCheckClickOutside(drawer, handleCloseDrawer, kebab);
    return (
      <TailwindFlex
        flexDirection={"flex-col"}
        justifyContent={"justify-center"}
        position='relative'
        zIndex='z-800'
        alignItems='items-center'
        gap='gap-1'
        {...kebabProps}
        {...props}>
        <TailwindBox
          flexDirection={"flex-col"}
          justifyContent={"justify-center"}
          display='flex'
          ref={kebab}
          cursor='cursor-pointer'
          alignItems='items-center'
          gap='gap-1'
          onClick={handleKebab}>
          <TailwindIcon
            fontSize={"text-3xl"}
            name={"kebab-icon"}
            position={"relative"}
            textProps={{
              backgroundColor: "bg-tertiary-bg-color",
              borderRadius: "rounded-full",
              height: "h-10",
              width: "w-10",
            }}
            iconStyleProps={{
              className: ["m-auto", "text-3xl"],
            }}
          />
        </TailwindBox>
        {kebabOpen && (
          <TailwindBox
            ref={drawer}
            className={[!otherOptions ? "Kebab-menu2" : "Kebab-menu"]}
            backgroundColor='bg-primary-bg-color'
            position='absolute'
            width={"w-40"}
            textAlign={"text-right"}
            zIndex={"z-900"}
            borderRadius={"rounded-lg"}
            padding={["px-4"]}>
            {!otherOptions ? (
              <TailwindIcon
                name={"close"}
                height={"h-1"}
                position={"relative"}
                inset={["right-2", "top-2"]}
                onClick={handleCloseDrawer}
              />
            ) : null}
            <TailwindFlex
              flexDirection={"flex-col"}
              justifyContent={"justify-center"}
              alignItems='items-center'>
              {children}
            </TailwindFlex>
          </TailwindBox>
        )}
      </TailwindFlex>
    );
  };

export default inject("messageStore")(observer(TailwindKebabMenu));
