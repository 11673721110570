import React from "react";
import "./styles.scss";

type ChatIconProps = {
  className?: string;
};

const ChatIcon: React.ComponentType<ChatIconProps> = ({ className }) => (
  <span className={`${className} camsIcon`}>
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          id="comment"
          d="M21.34,10.68c-0.01-1.23-0.45-2.42-1.25-3.35c-0.91-1.08-2.07-1.92-3.39-2.43c-1.49-0.61-3.08-0.91-4.69-0.89  C10.79,4,9.57,4.18,8.4,4.54c-1.07,0.31-2.08,0.78-3,1.42c-0.81,0.56-1.49,1.29-2,2.13c-0.48,0.78-0.73,1.68-0.74,2.59  c0,1.05,0.33,2.07,0.94,2.93c0.67,0.95,1.56,1.73,2.58,2.29c-0.07,0.27-0.16,0.53-0.27,0.79c-0.11,0.25-0.2,0.45-0.28,0.61  c-0.1,0.18-0.21,0.36-0.34,0.53c-0.14,0.19-0.25,0.33-0.32,0.41l-0.36,0.4l-0.29,0.37L4.24,19.1l-0.07,0.09l-0.06,0.09  c-0.01,0.03-0.01,0.07,0,0.1c0.01,0.03,0.01,0.07,0,0.1c-0.02,0.04-0.02,0.08,0,0.12H4.01c0.02,0.12,0.09,0.22,0.18,0.3  C4.28,19.97,4.4,20,4.51,19.99c0.4-0.05,0.8-0.12,1.19-0.23c1.78-0.44,3.42-1.31,4.79-2.52c0.5,0.05,1.01,0.08,1.51,0.08  c1.61,0.02,3.2-0.28,4.69-0.89c1.32-0.51,2.49-1.34,3.4-2.42c0.8-0.93,1.24-2.12,1.25-3.35l0,0V10.68z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  </span>
);

export default ChatIcon;
