import React from "react";
import { Toaster } from "react-hot-toast";
import { layoutStore } from "../../core/stores/layout/LayoutStore"

type Props = {};

const Snackbar: React.FC<Props> = ({}) => {
  const {isMobileDevice} = layoutStore!
  return (
    <Toaster
      toastOptions={{
        position: isMobileDevice ? "bottom-center" : "bottom-right",
        style: {
          maxWidth: "400px",
        },
      }}
    />
  );
};

export default Snackbar;
