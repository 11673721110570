import React, { useMemo } from "react";
import TailwindList from "library/components/_tailwind/list";
import TailwindListItem from "library/components/_tailwind/list/list-item";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import { inject, observer } from "mobx-react";

type Props = {
  profileStore?: ProfileStore;
};

const AccountDenyReasons: React.ComponentType<Props> = ({ profileStore }) => {
  const { profile, isStudioModel } = profileStore!;
  const denyReasons = useMemo(() => profile.deny_reasons, [profile]);

  if (!denyReasons?.length) {
    return null;
  }

  return (
    <TailwindList
      padding={["p-5"]}
      backgroundColor={"bg-yellow-100"}
      backgroundOpacity={"bg-opacity-70"}
      margin={["mb-2"]}>
      {denyReasons
        .filter(item => item.category == "document")
        .map((denyReason, index) => (
          <TailwindListItem
            key={denyReason.property}
            textColor={"text-red-600"}>
            <p key={`access-restriction-reason-denial-${index}`}>
              {index + 1}. {denyReason.text}
            </p>
          </TailwindListItem>
        ))}
      {isStudioModel && (
        <p>
          <TailwindTranslatedText
            textColor='text-main-color'
            descriptor={{
              id: "message.error.contact-studio-owner",
              defaultMessage:
                "Please contact your studio owner to get your application updated",
            }}
          />
        </p>
      )}
    </TailwindList>
  );
};

export default inject("profileStore")(observer(AccountDenyReasons));
