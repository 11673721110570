import React from "react";
import TailwindAccordion from "library/components/_tailwind/accordion";
import TailwindBox from "library/components/_tailwind/box";

type Props = {
  label: string;
  text: string;
};

const BroadcastInfoAccordion: React.ComponentType<Props> = ({
  text,
  label,
}) => {
  return (
    <TailwindBox
      width={"w-full"}
      borderWidth={["border-b", "border-l-0", "border-t-0", "border-r-0"]}
      borderColor={"border-main-color"}>
      <TailwindAccordion
        label={label}
        cardProps={{ padding: ["px-4", "pb-4"], textColor: "text-main-color" }}
        buttonProps={{
          showArrowOnRight: true,
          textProps: { width: "w-full" },
        }}
        buttonFlexProps={{
          justifyContent: "justify-between",
        }}
        iconProps={{
          collapsedIconName: "keyboard_arrow_down",
          expandedIconName: "keyboard_arrow_up",
        }}>
        {text}
      </TailwindAccordion>
    </TailwindBox>
  );
};

export default BroadcastInfoAccordion;
