import React, { FunctionComponent, useMemo } from "react";
import { observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import "../styles.scss";

import TailwindFlex from "library/components/_tailwind/flex";
import { openRegisterModal } from "core/stores/auth/utils";
import TailwindButton from "library/components/_tailwind/button";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindBox from "library/components/_tailwind/box";
import {
  CAMS_STUDIO_APPLICATIONS_EMAIL,
  CAMS_SUPPORT_PHONE_NUMBER,
} from "library/core/config/urls";

type CamsStudioLandingContainerProps = {};

const CamsStudioLandingContainer: FunctionComponent<
  CamsStudioLandingContainerProps & WrappedComponentProps
> = ({ intl }) => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <TailwindFlex
      className={["Landing", "StudioLanding"]}
      overflow={["overflow-y-auto"]}
      flexDirection={"flex-col"}
      height={"h-full"}
      width={"w-full"}
      flexGrow='flex-grow'>
      <TailwindFlex
        className={["StudioLanding__wrapper"]}
        backgroundColor='bg-white'
        flexGrow='flex-grow'>
        <TailwindFlex
          className={["StudioLanding__info"]}
          padding={["py-20", "px-20"]}
          flexDirection='flex-col'
          alignItems='items-center'>
          <TailwindTranslatedText
            fontSize={"text-3xl"}
            textColor='text-gray-400'
            descriptor={{
              id: "landing.studio.heading",
              defaultMessage: "Run Your Own Studio",
            }}
          />

          <TailwindTranslatedText
            margin={["mb-5"]}
            fontSize='text-5xl'
            textColor='text-gray-600'
            descriptor={{
              id: "landing.studio.subheading",
              defaultMessage: "Earn $1000s a week",
            }}
          />

          <TailwindTranslatedText
            className={"StudioLanding__content"}
            fontSize={"text-lg"}
            textColor='text-gray-500'
            descriptor={{
              id: "landing.studio.content",
              defaultMessage:
                "As head of your own Streamray Studio, you'll be recruiting and managing your own talent. Train your own models and earn a percentage of their income while helping them make money in a safe environment. You set your own hours and work on your own time, as do your models. The more models you add to your studio, the more money you make. Our top studios make up to $50,000 a month!",
            }}
          />

          <TailwindBox>
            <TailwindButton
              margin={["mt-5", "mb-20"]}
              backgroundColor={"primary"}
              rounded={false}
              fullWidth={false}
              borderRadius={"rounded-none"}
              justifyContent={"justify-center"}
              textColor={"text-white"}
              size={"lg"}
              width='w-80'
              onClick={openRegisterModal}>
              Get Started
            </TailwindButton>
          </TailwindBox>
          <TailwindFlex
            justifyContent='justify-center'
            flexDirection='flex-row'>
            <h1 className={"LandingCover__title"}>
              {intl.formatMessage({
                id: "landing.questions.title",
                defaultMessage: "Questions?",
              })}
            </h1>
            <div className={"LandingCover__text"}>
              <ul className={"LandingCover__list"}>
                <li className={"LandingCover__list-item"}>
                  <span className={"LandingCover__list-item--phone"}></span>
                  <span className={"text-lg"}>{CAMS_SUPPORT_PHONE_NUMBER}</span>
                </li>
                <li className={"LandingCover__link LandingCover__list-item"}>
                  <a href={`mailto:${CAMS_STUDIO_APPLICATIONS_EMAIL}`}>
                    <span className={"LandingCover__list-item--mail"}></span>
                    <TailwindTranslatedText
                      fontSize={"text-lg"}
                      descriptor={{
                        id: "landing.questions.email-us",
                        defaultMessage: "Email Us",
                      }}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </TailwindFlex>
        </TailwindFlex>
        <TailwindFlex
          className={["StudioLanding__image"]}
          justifyContent='justify-center'
          alignItems='items-end'
          flexDirection='flex-col'>
          <img
            className='StudioLanding__cover'
            src='https://secureimage.securedataimages.com/images/cams/studios/cover_pad_black.png'
          />
        </TailwindFlex>
      </TailwindFlex>
      <footer className='Landing__studio-footer'>
        <TailwindBox
          padding={["px-6"]}
          pseudoClasses={["sm:px-2", "md:px-4"]}
          className={["Landing__studio-footer--copyright textCenter"]}>
          <p>
            18 U.S.C. 2257{" "}
            {intl.formatMessage({
              id: "landing.recordKeeping",
              defaultMessage:
                "Record Keeping Requirements Compliance Statement",
            })}
          </p>
          <p>
            © 1999-{currentYear}{" "}
            {intl.formatMessage({
              id: "landing.streamrayInc",
              defaultMessage: "Streamray Inc. All Rights Reserved",
            })}
          </p>
        </TailwindBox>
      </footer>
    </TailwindFlex>
  );
};

export default injectIntl(observer(CamsStudioLandingContainer));
