import React, { FunctionComponent } from "react";
import { inject, observer } from "mobx-react";
import BroadcastStore from "common/broadcast/_stores/broadcast/BroadcastStore";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import classNames from "classnames";

interface BroadcastShowTypeIndicatorProps {
  broadcastStore?: BroadcastStore;
  layoutStore?: LayoutStore;
}

const BroadcastRecordingIndicator: FunctionComponent<BroadcastShowTypeIndicatorProps> =
  ({ broadcastStore, layoutStore }) => {
    const { isRecording } = broadcastStore!;
    const { isLiteMode } = layoutStore!;

    if (isRecording) {
      return (
        <div className={classNames({
          'BroadcastRecordingIndicator': true,
          'BroadcastRecordingIndicator--litemode': isLiteMode,
        })}>
          <span
            className={`BroadcastShowTypeIndicator__circle BroadcastShowTypeIndicator__circle--red`}></span>
          REC
        </div>
      );
    }

    return null;
  };

export default inject("broadcastStore", "layoutStore")(observer(BroadcastRecordingIndicator));
