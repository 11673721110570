import React, { useCallback, useMemo, useState } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindDropdown, {
  TailwindDropdownValue,
} from "library/components/_tailwind/dropdown";
import { US_STATES } from "library/core/constants";
import TailwindButton from "library/components/_tailwind/button";
import { isArray } from "lodash";
import AccountSettingsStore from "common/account-settings/stores/account-settings/AccountSettingsStore";
import AuthStore from "core/stores/auth/AuthStore";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";

type Props = {
  accountSettingsStore?: AccountSettingsStore;
  authStore?: AuthStore;
  onBlockCountrySuccess?: () => void;
};

const BlockAddCountry: React.ComponentType<Props & WrappedComponentProps> = ({
  authStore,
  accountSettingsStore,
  onBlockCountrySuccess,
  intl,
}) => {
  const { countries } = authStore!;
  const {
    blockedCountries,
    blockCountry,
    unblockCountry,
    isBlockingOrUnblocking,
  } = accountSettingsStore!;
  const blockedCountryKeys = useMemo(() => {
    const countries = blockedCountries
      .filter((country: any) => !country.subdivision)
      .map((country: any) => country.country);
    if (countries.length !== blockedCountries.length) {
      countries.push("US");
    }
    return countries;
  }, [blockedCountries]);

  const [selectedCountries, setSelectedCountries] =
    useState<string[]>(blockedCountryKeys);
  const selectedUSStatesAsDefault = useMemo(
    () =>
      blockedCountries
        .filter((country: any) => country.subdivision)
        .map((country: any) => `${country.country}-${country.subdivision}`),
    [blockedCountries]
  );
  const [selectedUSStates, setSelectedUSStates] = useState<string[]>(
    selectedUSStatesAsDefault
  );
  const showUSStatesDropdown = useMemo(
    () => selectedCountries.includes("US"),
    [selectedCountries]
  );

  const onBlockCountryClicked = useCallback(() => {
    const willUnBlockCountries = blockedCountryKeys.filter(
      key => !selectedCountries.includes(key)
    );
    let willUnBlockStates;
    if (showUSStatesDropdown) {
      willUnBlockStates = selectedUSStatesAsDefault.filter(
        label => !selectedUSStates.includes(label)
      );
    } else {
      willUnBlockStates = Object.keys(US_STATES);
    }
    blockedCountries.forEach(async (country: any) => {
      if (
        (!country.subdivision &&
          willUnBlockCountries.includes(country.country)) ||
        willUnBlockStates.includes(`${country.country}-${country.subdivision}`)
      ) {
        await unblockCountry({
          id: country.id,
          countryLabel:
            country.countryLabel ||
            US_STATES[`${country.country}-${country.subdivision}`],
        });
      }
    });
    const merged = [...selectedCountries.filter(label => label != "US")];
    if (showUSStatesDropdown) {
      merged.push(...selectedUSStates);
    }
    if (merged.length) {
      blockCountry(merged);
      if (onBlockCountrySuccess) {
        onBlockCountrySuccess();
      }
    }
  }, [
    selectedCountries,
    blockedCountries,
    selectedUSStates,
    showUSStatesDropdown,
    blockedCountryKeys,
    selectedUSStatesAsDefault,
  ]);

  const onChangeCountry = useCallback(
    (value: TailwindDropdownValue) => {
      const countriesToBlock: string[] = [];
      if (isArray(value)) {
        value?.forEach(country => {
          countriesToBlock.push(country as string);
        });
      } else {
        countriesToBlock.push(value as string);
      }
      setSelectedCountries(countriesToBlock);
    },
    [selectedCountries]
  );

  const onChangeUSState = useCallback(
    (value: TailwindDropdownValue) => {
      const USStatesToBlock: string[] = [];
      if (isArray(value)) {
        value?.forEach(state => {
          USStatesToBlock.push(state as string);
        });
      } else {
        USStatesToBlock.push(value as string);
      }
      setSelectedUSStates(USStatesToBlock);
    },
    [selectedUSStates]
  );

  const countriesThatAreNotYetBlocked = useMemo(
    () =>
      Object.keys(countries)
        .filter(
          key => !blockedCountries.find(country => country.country === key)
        )
        .map(key => ({ label: countries[key], value: key })),
    [countries, blockedCountries]
  );

  return (
    <TailwindFlex flexDirection={"flex-col"}>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindTranslatedText
          descriptor={{
            id: "accountSettings.blockUnblock.blockCountry",
            defaultMessage: "Block Country",
          }}
          headingProps={{ level: 5 }}
        />
      </TailwindFlex>
      <TailwindFlex flexDirection={"flex-col"}>
        <TailwindFlex>
          <TailwindDropdown
            backgroundColor={"secondary"}
            fullWidth={true}
            label={"Country"}
            value={selectedCountries}
            multipleProps={{ placeholder: "Select a country" }}
            onChange={onChangeCountry}
            name={"block-country"}
            items={countriesThatAreNotYetBlocked}
            showSearch
          />
        </TailwindFlex>
        <TailwindFlex margin={["mt-4"]}>
          <TailwindDropdown
            label={"US States"}
            backgroundColor={"secondary"}
            fullWidth={true}
            disabled={!showUSStatesDropdown}
            value={selectedUSStates}
            multipleProps={{
              placeholder: showUSStatesDropdown
                ? "Select a US State"
                : "States can only be blocked when United States is selected above",
            }}
            onChange={onChangeUSState}
            name={"block-us-state"}
            items={Object.keys(US_STATES).map(key => ({
              label: US_STATES[key],
              value: key,
            }))}
            showSearch
          />
        </TailwindFlex>
        <TailwindFlex margin={["mt-4"]}>
          <TailwindButton
            backgroundColor={"primary"}
            rounded={false}
            fullWidth={true}
            justifyContent={"justify-center"}
            onClick={onBlockCountryClicked}
            showSpinner={isBlockingOrUnblocking}
            disabled={isBlockingOrUnblocking}>
            {intl.formatMessage({
              id: "accountSettings.blockUnblock.block",
              defaultMessage: "Block",
            })}
          </TailwindButton>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject("accountSettingsStore", "authStore")(observer(BlockAddCountry))
);
