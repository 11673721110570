import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import VideoPlayer from "../../components/_tailwind/video-player";

type Props = {};

const StorybookComponentsVideoPlayer: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; VideoPlayer</StorybookHeading>
      <StorybookParagraph>
        VideoPlayer is a component that basically uses a video HTML element
        however has also an HLS fallback for playing videos being served from{" "}
        <Code inline>minio</Code>. It has only one opinion: if file src has
        minio in it or is a file blob, it uses video element as well as it has a
        close button that you can use to hide the video player via{" "}
        <Code inline>onClickCloseVideo prop</Code>. Here are the props it takes:
      </StorybookParagraph>
      <Code>{`src: string;
className?: string;
style?: React.CSSProperties;
onClickCloseVideo?: () => void;
fullHeight?: boolean;
useHls?: boolean;
showControls?: boolean
`}</Code>
      <StorybookComponentShowcase sourceCode={``}>
        Example video may not work because of crossOrigin
        <VideoPlayer
          src={
            "https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4"
          }
        />
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsVideoPlayer;
