import React, { useCallback } from "react";
import TailwindInput from "library/components/_tailwind/input";
import TailwindBox from "library/components/_tailwind/box";
import MediaManagerStore from "containers/_components/media-manager/store/MediaManagerStore";
import { inject, observer } from "mobx-react";
import TailwindPill from "library/components/_tailwind/pill";
import TailwindText from "library/components/_tailwind/text";

type Props = {
  mediaManagerStore?: MediaManagerStore;
};

const MediaManagerSidebarBodyAlbumName: React.ComponentType<Props> = ({
  mediaManagerStore,
}) => {
  const { selectedAlbum, setAlbumName, getAlbumTitle } = mediaManagerStore!;

  const onChangeAlbumName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (selectedAlbum) {
        setAlbumName(event.target.value, selectedAlbum);
      }
    },
    [selectedAlbum]
  );

  if (!selectedAlbum) {
    return null;
  }

  return (
    <TailwindBox margin={["mb-5"]}>
      <TailwindInput
        value={getAlbumTitle(selectedAlbum)}
        onChange={onChangeAlbumName}
        fontWeight={"font-bold"}
        wrapInput
        autoFocus
        maxLength={125}
        boxProps={{
          padding: ["px-4"],
          backgroundColor: "bg-primary-bg-color",
          margin: ["mb-3"],
          width: "w-full",
        }}
        placeholder={"Add title"}
        psuedoClasses={["focus:outline-none"]}
        style={{ margin: "20px auto" }}
      />
      {
        (selectedAlbum?.pending_title &&
        selectedAlbum?.pending_title_status === "PENDING") && 
        <TailwindPill
          borderRadius={"rounded-full"}
          backgroundColor={"bg-gray-600"}
          size='sm'
          padding={["px-4", "py-2"]}
          textColor={"text-white"}>
          <TailwindText fontSize={"text-sm"}>
            {"Pending"}
          </TailwindText>
        </TailwindPill>
      }
      
    </TailwindBox>
  );
};

export default inject("mediaManagerStore")(
  observer(MediaManagerSidebarBodyAlbumName)
);
