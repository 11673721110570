import React, { useMemo } from "react";
import classnames from "classnames";
import {
  TAlignContent,
  TAlignItems,
  TAlignSelf,
  TAppearance,
  TBackdropBlur,
  TBackdropFilter,
  TBackgroundColor,
  TBackgroundImage,
  TBackgroundOpacity,
  TBackgroundPosition,
  TBackgroundRepeat,
  TBackgroundSize,
  TBlur,
  TBorderColor,
  TBorderOpacity,
  TBorderRadius,
  TBorderStyle,
  TBorderWidth,
  TBoxShadow,
  TBoxSizing,
  TClear,
  TCursor,
  TDisplay,
  TDivideColor,
  TDivideOpacity,
  TDivideStyle,
  TDivideWidth,
  TFlex,
  TFlexDirection,
  TFlexGrow,
  TFlexShrink,
  TFlexWrap,
  TFloat,
  TFontSize,
  TFontWeight,
  TGap,
  TGradientColorStops,
  TGridTemplateColumns,
  TGridTemplateRows,
  THeight,
  TInset,
  TJustifyContent,
  TJustifyItems,
  TJustifySelf,
  TListStylePosition,
  TListStyleType,
  TMargin,
  TMaxHeight,
  TMaxWidth,
  TMinHeight,
  TMinWidth,
  TObjectFit,
  TObjectPosition,
  TOpacity,
  TOrder,
  TOutline,
  TOverflow,
  TPadding,
  TPlaceContent,
  TPlaceItems,
  TPlaceSelf,
  TPointerEvents,
  TPosition,
  TPseudoClasses,
  TRingColor,
  TRingOffsetColor,
  TRingOffsetWidth,
  TRingOpacity,
  TRingWidth,
  TSpace,
  TTextAlign,
  TTextColor,
  TTransforms,
  TTransitionDelay,
  TTransitionDuration,
  TTransitionProperty,
  TTransitionTimingFunction,
  TUserSelect,
  TVisibility,
  tw,
  TWhitespace,
  TWidth,
  TWordBreak,
  TZIndex,
} from "../../../styles/tailwind-classnames";

export type TailwindBoxClassName = (string | undefined | boolean)[];

export type TailwindBoxProps = {
  width?: TWidth;
  minWidth?: TMinWidth;
  maxWidth?: TMaxWidth;
  height?: THeight;
  minHeight?: TMinHeight;
  maxHeight?: TMaxHeight;
  display?: TDisplay;
  float?: TFloat;
  flexDirection?: TFlexDirection;
  flexWrap?: TFlexWrap;
  flex?: TFlex;
  flexGrow?: TFlexGrow;
  flexShrink?: TFlexShrink;
  order?: TOrder;
  backdropBlur?: TBackdropBlur;
  gap?: TGap;
  gridCols?: TGridTemplateColumns;
  gridRows?: TGridTemplateRows;
  justifyContent?: TJustifyContent;
  justifyItems?: TJustifyItems;
  justifySelf?: TJustifySelf;
  alignContent?: TAlignContent;
  alignItems?: TAlignItems;
  blur?: TBlur;
  alignSelf?: TAlignSelf;
  placeContent?: TPlaceContent;
  placeItems?: TPlaceItems;
  placeSelf?: TPlaceSelf;
  margin?: (TMargin | undefined)[];
  padding?: (TPadding | undefined)[];
  space?: TSpace;
  overflow?: TOverflow | (TOverflow | undefined)[];
  zIndex?: TZIndex;
  visibility?: TVisibility;
  clear?: TClear;
  boxSizing?: TBoxSizing;
  position?: TPosition;
  inset?: TInset[];
  borderRadius?: TBorderRadius | TBorderRadius[];
  borderWidth?: TBorderWidth[];
  borderColor?: TBorderColor;
  borderOpacity?: TBorderOpacity;
  borderStyle?: TBorderStyle;
  divideWidth?: TDivideWidth;
  divideColor?: TDivideColor;
  divideOpacity?: TDivideOpacity;
  divideStyle?: TDivideStyle;
  ringWidth?: TRingWidth;
  ringColor?: TRingColor;
  ringOpacity?: TRingOpacity;
  ringOffsetWidth?: TRingOffsetWidth;
  ringOffsetColor?: TRingOffsetColor;
  appearance?: TAppearance;
  cursor?: TCursor;
  outline?: TOutline;
  pointerEvents?: TPointerEvents;
  userSelect?: TUserSelect;
  transitionProperty?: TTransitionProperty;
  transitionDuration?: TTransitionDuration;
  transitionTimingFunction?: TTransitionTimingFunction;
  transitionDelay?: TTransitionDelay;
  backgroundColor?: TBackgroundColor;
  backgroundOpacity?: TBackgroundOpacity;
  backgroundImage?: TBackgroundImage;
  backgroundSize?: TBackgroundSize;
  backgroundPosition?: TBackgroundPosition;
  backgroundRepeat?: TBackgroundRepeat;
  gradientColorStops?: TGradientColorStops[];
  objectPosition?: TObjectPosition;
  objectFit?: TObjectFit;
  transform?: TTransforms[];
  textColor?: TTextColor;
  textAlign?: TTextAlign;
  backdropFilter?: TBackdropFilter;
  fontSize?: TFontSize;
  fontWeight?: TFontWeight;
  boxShadow?: TBoxShadow;
  whiteSpace?: TWhitespace;
  opacity?: TOpacity;
  listStyleType?: TListStyleType;
  listStylePosition?: TListStylePosition;
  wordBreak?: TWordBreak;
  className?: TailwindBoxClassName;
  prettyScroll?: boolean;
  id?: string;
  style?: React.CSSProperties;
  pseudoClasses?: (TPseudoClasses | undefined)[];
  as?: string;
  onClick?: (event: React.MouseEvent<any>) => void;
  onMouseEnter?: (event: React.MouseEvent<any>) => void;
  onMouseLeave?: (event: React.MouseEvent<any>) => void;
  dangerouslySetInnerHTML?: {
    __html: string;
  };
  htmlElementProps?: any; //this is used for things like disabled
  children?: any;
};

const TailwindBox = React.forwardRef<HTMLElement, TailwindBoxProps>(
  (
    {
      width,
      minWidth,
      maxWidth,
      height,
      minHeight,
      maxHeight,
      display,
      float,
      justifyContent,
      justifyItems,
      justifySelf,
      alignContent,
      alignItems,
      alignSelf,
      placeContent,
      placeItems,
      placeSelf,
      margin = [],
      padding = [],
      space,
      flex,
      flexDirection,
      flexGrow,
      flexShrink,
      flexWrap,
      order,
      backdropBlur,
      objectFit,
      backdropFilter,
      blur,
      gap,
      gridCols,
      gridRows,
      overflow,
      zIndex,
      visibility,
      clear,
      boxSizing,
      position,
      inset = [],
      borderRadius,
      borderWidth,
      borderColor,
      borderOpacity,
      borderStyle,
      divideWidth,
      divideColor,
      divideOpacity,
      divideStyle,
      ringWidth,
      ringColor,
      ringOpacity,
      ringOffsetWidth,
      ringOffsetColor,
      appearance,
      cursor,
      outline,
      pointerEvents,
      userSelect,
      transitionProperty,
      transitionDuration,
      transitionTimingFunction,
      transitionDelay,
      backgroundColor,
      backgroundOpacity,
      backgroundImage,
      backgroundSize,
      backgroundPosition,
      backgroundRepeat,
      gradientColorStops = [],
      objectPosition,
      transform = [],
      textColor,
      textAlign,
      fontSize,
      fontWeight,
      boxShadow,
      whiteSpace,
      opacity,
      listStyleType,
      listStylePosition,
      wordBreak,
      className = [],
      prettyScroll = true,
      id,
      style,
      as = "div",
      pseudoClasses = [],
      htmlElementProps,
      children,
      onClick,
      ...rest
    },
    ref
  ) => {
    const _borderRadius = useMemo(() => {
      if (Array.isArray(borderRadius)) {
        return borderRadius.join(" ") as TBorderRadius;
      }

      return borderRadius;
    }, [borderRadius]);
    const _overflow = useMemo(() => {
      if (Array.isArray(overflow)) {
        return overflow.join(" ") as TOverflow;
      }

      return overflow;
    }, [overflow]);

    return React.createElement(
      as,
      {
        ref,
        className: classnames(
          tw(
            width,
            minWidth,
            maxWidth,
            height,
            minHeight,
            maxHeight,
            display,
            float,
            justifyContent,
            objectFit,
            justifyItems,
            justifySelf,
            alignContent,
            alignItems,
            alignSelf,
            placeContent,
            placeItems,
            placeSelf,
            ...(Array.isArray(margin) ? margin : [margin]),
            ...padding,
            space,
            flex,
            flexDirection,
            flexGrow,
            flexShrink,
            flexWrap,
            order,
            backdropBlur,
            backdropFilter,
            blur,
            gap,
            gridCols,
            gridRows,
            _overflow,
            zIndex,
            visibility,
            clear,
            boxSizing,
            position,
            ...inset,
            _borderRadius,
            ...(borderWidth || []),
            borderColor,
            borderOpacity,
            borderStyle,
            divideWidth,
            divideColor,
            divideOpacity,
            divideStyle,
            ringWidth,
            ringColor,
            ringOpacity,
            ringOffsetWidth,
            ringOffsetColor,
            appearance,
            cursor,
            outline,
            pointerEvents,
            userSelect,
            transitionProperty,
            transitionDuration,
            transitionTimingFunction,
            transitionDelay,
            backgroundColor,
            backgroundOpacity,
            backgroundImage,
            backgroundSize,
            backgroundPosition,
            backgroundRepeat,
            objectPosition,
            textColor,
            textAlign,
            fontSize,
            fontWeight,
            boxShadow,
            whiteSpace,
            opacity,
            listStyleType,
            listStylePosition,
            wordBreak,
            onClick ? "cursor-pointer" : undefined,
            ...([
              ...(transform.length > 0 ? ["transform", ...transform] : []),
            ] as any),
            ...gradientColorStops,
            ...pseudoClasses
          ),
          ...className,
          prettyScroll && "pretty-scroll"
        ),
        ...rest,
        id,
        style,
        ...htmlElementProps,
        onClick,
      },
      children
    );
  }
);

export default TailwindBox;
