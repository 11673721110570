import React from "react";
import "./styles.scss";

type EarningsIconProps = {
  className?: string;
};

const EarningsIcon: React.ComponentType<EarningsIconProps> = ({
  className,
}) => (
  <span className={`${className} camsIcon`}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
      <path d='M50,.94A49.06,49.06,0,1,0,99.06,50,49.12,49.12,0,0,0,50,.94Zm0,93.12A44.06,44.06,0,1,1,94.06,50,44.12,44.12,0,0,1,50,94.06Z' />
      <path d='M53.92,27.94a9.75,9.75,0,0,1,9.73,9.73,2.5,2.5,0,1,0,5,0A14.75,14.75,0,0,0,53.92,22.94H52.5V16a2.5,2.5,0,0,0-5,0v6.91h-.91A15.25,15.25,0,0,0,31.35,37.85v.35c0,10.46,9.35,13.92,14.35,14.3l1.8.09V72.42a12.67,12.67,0,0,1-6.71-1.87,8.88,8.88,0,0,1-4.44-7.88,2.5,2.5,0,0,0-5,0,13.78,13.78,0,0,0,6.76,12.1,17.67,17.67,0,0,0,9.39,2.65V84a2.5,2.5,0,1,0,5,0V77.42h1.33A14.65,14.65,0,0,0,68.62,63.69q0-.49,0-1A14.17,14.17,0,0,0,64,52a16.05,16.05,0,0,0-10-4.08l-1.52-.07V27.94Zm-.16,25a10.93,10.93,0,0,1,6.89,2.76,9.14,9.14,0,0,1,3,7c0,.26,0,.51,0,.76a9.66,9.66,0,0,1-9.8,9H52.5V52.84ZM47.5,47.58,46,47.51c-.4,0-9.67-.83-9.67-9.31v-.29a10.24,10.24,0,0,1,10.24-10h.91Z' />
    </svg>
  </span>
);

export default EarningsIcon;
