import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import AuthStore from "core/stores/auth/AuthStore";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import { injectIntl, WrappedComponentProps } from "react-intl";
import ProfileStore from "../../my-page/stores/profile/ProfileStore";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { ProfileDocumentType } from "../../my-page/stores/profile/enums";
import TailwindFlex from "library/components/_tailwind/flex";
import FileUpload, {
  FileUploadProcessStatus,
} from "library/components/file-upload";
import { DOCUMENT_TYPES, US_DOCUMENT_TYPES } from "library/core/constants";
import { IMAGES_PATH } from "library/core/config/urls";
import TailwindInput from "library/components/_tailwind/input";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import { inject, observer } from "mobx-react";
import moment from "moment";
import TailwindButton from "library/components/_tailwind/button";
import TailwindDropdown, {
  TailwindDropdownValue,
} from "library/components/_tailwind/dropdown";
import AccountDenyReasons from "../account-deny-reasons";
import { DenyReasonKeys } from "common/my-page/stores/profile/types";
import { formatter } from "library/core/utility";

type RegisterUploadStepProps = {
  authStore?: AuthStore;
  validationStore?: ValidationStore;
  profileStore?: ProfileStore;
};

const RegisterUploadStep: FunctionComponent<
  RegisterUploadStepProps & WrappedComponentProps
> = ({ authStore, validationStore, profileStore, intl }) => {
  const { clearErrors, validate, errors } = validationStore!;

  const { splitURLToUploadImageKey } = formatter;

  const [frontOfIdImageErrorState, setFrontOfIdImageErrorState] =
    useState<FileUploadProcessStatus>(undefined);
  const [backOfIdImageErrorState, setBackOfIdImageErrorState] =
    useState<FileUploadProcessStatus>(undefined);
  const [verificationOfIdImageErrorState, setVerificationOfIdImageErrorState] =
    useState<FileUploadProcessStatus>(undefined);

  const [frontOfIdImage, setFrontOfIdImage] = useState<string | undefined>(
    undefined
  );
  const [backOfIdImage, setBackOfIdImage] = useState<string | undefined>(
    undefined
  );
  const [verificationOfIdImage, setVerificationOfIdImage] = useState<
    string | undefined
  >(undefined);

  const {
    uploadDocument,
    modelProfile,
    //isCams
  } = profileStore!;

  const {
    formRegistrationData,
    updateRegistrationFormField,
    completeDocumentUploadStep,
    countries,
  } = authStore!;
  const {
    document_type,
    issue_country,
    expire_date,
    document_verification_key,
    document_front_key,
    document_back_key,
  } = formRegistrationData!.model_document;

  const changeDocumentType = (value: TailwindDropdownValue) => {
    updateRegistrationFormField("model_document", {
      name: "document_type",
      value: value,
    });
    setFrontOfIdImageErrorState(undefined);
    setBackOfIdImageErrorState(undefined);
    setVerificationOfIdImageErrorState(undefined);
    setFrontOfIdImage(undefined);
    setBackOfIdImage(undefined);
    setVerificationOfIdImage(undefined);
  };

  const uploadFrontImageOfId = useCallback(async (files: File[]) => {
    try {
      setFrontOfIdImageErrorState("processing");
      const media = await uploadDocument(files, ProfileDocumentType.ID_FRONT);
      updateRegistrationFormField("model_document", {
        name: "document_front_key",
        value: media.key,
      });
      setFrontOfIdImageErrorState("accepted");
      validate("document_front_key", media.key);
      setFrontOfIdImage(media?.view_url);
    } catch (error) {
      setFrontOfIdImageErrorState("rejected");
    }
  }, []);

  const uploadBackImageOfId = useCallback(async (files: File[]) => {
    try {
      setBackOfIdImageErrorState("processing");
      const media = await uploadDocument(files, ProfileDocumentType.ID_BACK);
      updateRegistrationFormField("model_document", {
        name: "document_back_key",
        value: media.key,
      });
      setBackOfIdImageErrorState("accepted");
      validate("document_back_key", media.key);
      setBackOfIdImage(media?.view_url);
    } catch (error) {
      setBackOfIdImageErrorState("rejected");
    }
  }, []);

  const uploadVerificationImageOfId = useCallback(async (files: File[]) => {
    try {
      setVerificationOfIdImageErrorState("processing");
      const media = await uploadDocument(
        files,
        ProfileDocumentType.ID_VERIFICATION
      );
      updateRegistrationFormField("model_document", {
        name: "document_verification_key",
        value: media.key,
      });
      setVerificationOfIdImage(media?.view_url);
      validate("document_verification_key", media.key);
      setVerificationOfIdImageErrorState("accepted");
    } catch (error) {
      setVerificationOfIdImageErrorState("rejected");
    }
  }, []);

  const shouldDisableUploadFields = useMemo(
    () => !document_type || document_type === "",
    [document_type]
  );
  const shouldUploadBackImage = useMemo(
    () =>
      document_type &&
      (document_type === "us_military_id" || document_type === "national_id"),
    [document_type]
  );

  const hasDenyReasonYourKey = useCallback(
    (reason: DenyReasonKeys) => {
      const res = modelProfile.deny_reasons.filter(
        item => item.property == reason
      );
      if (!!res.length) {
        return res[0].text;
      } else {
        return "";
      }
    },
    [modelProfile]
  );

  useEffect(() => {
    updateRegistrationFormField("model_document", {
      name: "document_front_key",
      value: splitURLToUploadImageKey(document_front_key || ""),
    });
    updateRegistrationFormField("model_document", {
      name: "document_back_key",
      value: splitURLToUploadImageKey(document_back_key || ""),
    });
    updateRegistrationFormField("model_document", {
      name: "document_verification_key",
      value: splitURLToUploadImageKey(document_verification_key || ""),
    });
    setVerificationOfIdImage(document_verification_key || "");
    setFrontOfIdImage(document_front_key || "");
    setBackOfIdImage(document_back_key || "");
  }, []);
  useEffect(() => clearErrors(), [validationStore]);

  return (
    <TailwindFlex width={"w-192"} md={"w-11/12"} height={"h-full"}>
      <SimpleBar className='RegisterModal__form-scrollarea'>
        <div className='RegisterModal__form'>
          <AccountDenyReasons />
          <form noValidate autoComplete='false' className={"AuthModal__form"}>
            <div className='RegisterModal__cardTitle text-main-color'>
              {intl.formatMessage({
                id: "landing.documents.cardTitle",
                defaultMessage: "Upload identification documents",
              })}
            </div>
            <div className='RegisterModal__cardSubtitle text-main-color'>
              {intl.formatMessage({
                id: "landing.documents.cardSubtitle",
                defaultMessage:
                  "To safeguard minors, we require all models to show proof that they are over the age of 18. We do this by asking for a government-issued photo ID and a photo of you holding the uploaded ID.",
              })}
            </div>
            <div className='flex w-full mt-2 mb-4'>
              <TailwindDropdown
                backgroundColor={"primary"}
                fullWidth={true}
                name={"issue_country"}
                error={!!errors?.issue_country ? errors?.issue_country : ""}
                label={intl.formatMessage({
                  id: "common.issuedCountry",
                  defaultMessage: "ID Issued Country",
                })}
                value={issue_country}
                onChange={(value: TailwindDropdownValue) => {
                  updateRegistrationFormField("model_document", {
                    name: "issue_country",
                    value: value,
                  });
                  validate("issue_country", value);
                }}
                onClick={e => e.preventDefault()}
                items={Object.entries(countries).map(
                  ([isoCode, countryName]) => ({
                    value: isoCode,
                    label: countryName,
                  })
                )}
              />
            </div>
            <div className='flex w-full mt-2 mb-4'>
              <TailwindFlex width={"w-1/2"} margin={["mr-1"]}>
                <TailwindDropdown
                  backgroundColor={"primary"}
                  fullWidth={true}
                  name={"document_type"}
                  error={!!errors?.document_type ? errors?.document_type : ""}
                  label={intl.formatMessage({
                    id: "common.documentType",
                    defaultMessage: "Document Type",
                  })}
                  value={document_type}
                  onChange={(value: TailwindDropdownValue) => {
                    changeDocumentType(value);
                    validate("document_type", value);
                  }}
                  onClick={e => e.preventDefault()}
                  items={Object.entries(
                    issue_country === "US" ? US_DOCUMENT_TYPES : DOCUMENT_TYPES
                  ).map(([iso_code, country_name]) => ({
                    value: iso_code,
                    label: country_name,
                  }))}
                />
              </TailwindFlex>
              <TailwindFlex width={"w-1/2"} pseudoClasses={["md:mt-1"]}>
                <TailwindInput
                  required
                  rounded={false}
                  backgroundColor={"primary"}
                  borderColor={"border-transparent"}
                  label={intl.formatMessage({
                    id: "common.expireDate",
                    defaultMessage: "Expiration Date",
                  })}
                  placeholder={"YYYY/MM/DD"}
                  placeholderColor={"placeholder-gray-500"}
                  errorProps={{
                    textAlign: "text-right",
                  }}
                  type={TailwindInputType.date}
                  name={"expire_date"}
                  value={
                    moment(
                      expire_date ? new Date(expire_date as string) : new Date()
                    )
                      .utc()
                      .format("YYYY-MM-DD") || ""
                  }
                  error={
                    errors?.expire_date || hasDenyReasonYourKey("expire_date")
                  }
                  onChange={e =>
                    updateRegistrationFormField("model_document", {
                      name: "expire_date",
                      value: e.target.value,
                    })
                  }
                  onBlur={() => validate("expire_date", expire_date)}
                />
              </TailwindFlex>
            </div>
            <div className={"RegisterModal__uploadRow"}>
              <div
                className={
                  "RegisterModal__uploadDocument mt-2 mb-4 text-main-color"
                }>
                <div className={"mb-4"}>
                  {intl.formatMessage({
                    id: "landing.documents.idDocument",
                    defaultMessage:
                      "Upload a clear photo of your proof of identity",
                  })}
                  .
                </div>
              </div>
              <div className={"RegisterModal__uploadExample mt-2 mb-4 "}>
                <span className={"RegisterModal__view-example text-main-color"}>
                  {intl.formatMessage({
                    id: "common.example",
                    defaultMessage: "Example",
                  })}
                </span>
              </div>
            </div>
            <div className={"RegisterModal__uploadRow"}>
              <div className={"RegisterModal__uploadDocument mt-2 mb-4 "}>
                <div>
                  <FileUpload
                    dropzoneProps={{
                      disabled: shouldDisableUploadFields,
                      onDrop: uploadFrontImageOfId,
                      accept: ["image/jpeg", "image/jpg", "image/png"],
                      maxFiles: 1,
                    }}
                    error={
                      !!errors?.document_front_key ||
                      !!hasDenyReasonYourKey("document_front")
                    }
                    processStatus={frontOfIdImageErrorState}
                    acceptedText={"Photo Uploaded"}
                    previewUrl={frontOfIdImage}
                  />
                </div>
              </div>
              <div className={"RegisterModal__uploadExample mt-2 mb-4"}>
                <img
                  src={`${IMAGES_PATH}/registration/front_id_sample.png`}
                  alt='front of id'
                />
              </div>
            </div>
            {shouldUploadBackImage && (
              <div className={"w-full mt-2 mb-4"}>
                <div className={"mb-4 text-main-color"}>
                  {intl.formatMessage({
                    id: "landing.documents.idDocumentBack",
                    defaultMessage:
                      "Upload a clear photo of the back of your proof of identity",
                  })}
                  .
                </div>
                <div>
                  <FileUpload
                    dropzoneProps={{
                      disabled: shouldDisableUploadFields,
                      onDrop: uploadBackImageOfId,
                      accept: ["image/jpeg", "image/jpg", "image/png"],
                      maxFiles: 1,
                    }}
                    error={
                      !!errors?.document_back_key ||
                      !!hasDenyReasonYourKey("document_back")
                    }
                    processStatus={backOfIdImageErrorState}
                    acceptedText={"Photo Uploaded"}
                    previewUrl={backOfIdImage}
                  />
                </div>
              </div>
            )}
            <div className={"RegisterModal__uploadRow"}>
              <div
                className={
                  "RegisterModal__uploadDocument mt-2 mb-4 text-main-color"
                }>
                <div className={"mb-4 text-main-color"}>
                  {intl.formatMessage({
                    id: "landing.documents.idProof",
                    defaultMessage:
                      "This time of you holding your proof of identity next to your face so we can verify who you are",
                  })}
                  .
                </div>
              </div>
              <div className={"RegisterModal__uploadExample mt-2 mb-4"}>
                <span className={"RegisterModal__view-example text-main-color"}>
                  {intl.formatMessage({
                    id: "common.example",
                    defaultMessage: "Example",
                  })}
                </span>
              </div>
            </div>
            <div className={"RegisterModal__uploadRow"}>
              <div className={"RegisterModal__uploadDocument mt-2 mb-4"}>
                <div>
                  <FileUpload
                    dropzoneProps={{
                      disabled: shouldDisableUploadFields,
                      onDrop: uploadVerificationImageOfId,
                      accept: ["image/jpeg", "image/jpg", "image/png"],
                      maxFiles: 1,
                    }}
                    error={
                      !!errors?.document_verification_key ||
                      !!hasDenyReasonYourKey("document_verification")
                    }
                    processStatus={verificationOfIdImageErrorState}
                    acceptedText={"Photo Uploaded"}
                    previewUrl={verificationOfIdImage}
                  />
                </div>
              </div>
              <div className={"RegisterModal__uploadExample mt-2 mb-4"}>
                <img
                  src={`${IMAGES_PATH}/registration/selfie_id_sample.png`}
                  alt='front of id'
                />
              </div>
            </div>
            <div className={"RegisterModal__submit"}>
              <TailwindButton
                justifyContent={"justify-center"}
                backgroundColor={"primary"}
                fullWidth={false}
                rounded={false}
                disabled={authStore!.isActionInProgress}
                showSpinner={authStore!.isActionInProgress}
                onClick={e => {
                  e.preventDefault();
                  completeDocumentUploadStep();
                }}>
                {intl.formatMessage({
                  id: "common.next",
                  defaultMessage: "Next",
                })}
              </TailwindButton>
            </div>
          </form>
        </div>
      </SimpleBar>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject(
    "authStore",
    "validationStore",
    "profileStore"
  )(observer(RegisterUploadStep))
);
