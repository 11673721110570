import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookSubHeading from "../_internal/sub-heading";
import Code from "../../components/_tailwind/code";

type Props = {};

const StorybookConventionsEslintAndPrettier: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Conventions &gt; Eslint & Prettier</StorybookHeading>
      <StorybookParagraph>
        We use Eslint & Prettier for linting & formatting. Some of the crucial
        Eslint rules have been disabled due to the fact that eslint & Prettier
        was just added recently and app had tons of errors. The idea is to
        eventually get the app in a state where we enable back the following
        rules:
      </StorybookParagraph>
      <StorybookParagraph>
        - @typescript-eslint/no-explicit-any
      </StorybookParagraph>
      <StorybookParagraph>
        - @typescript-eslint/no-non-null-asserted-optional-chain
      </StorybookParagraph>
      <StorybookParagraph>- no-extra-boolean-cast</StorybookParagraph>
      <StorybookParagraph>
        There is no .prettierrc file therefore the default opinions of Prettier
        is used for formatting. You can setup your editors to lint on save.
        Currently the app does not run during development or build if there are
        linting errors but we will make a pre-commit hook that does the linting
        automatically to prevent any badly formatted code getting into the main
        branch
      </StorybookParagraph>
      <StorybookSubHeading>Editor Setup</StorybookSubHeading>
      <StorybookParagraph>VSCode:</StorybookParagraph>
      <Code>
        {`yarn global add prettier
install VSCode extension https://marketplace.visualstudio.com/items?itemName=esbenp.prettier-vscode
change User's settings/prefernces JSON to include the following:
"editor.formatOnSave": false,
`}
      </Code>
      <StorybookParagraph>Webstorm:</StorybookParagraph>
      <Code>
        {`yarn global add prettier
To run Prettier on save in WebStorm 2020.1 or above, open Preferences | Languages & Frameworks | JavaScript | Prettier and enable the option Run on save for files
`}
      </Code>
    </StorybookWrapper>
  );
};

export default StorybookConventionsEslintAndPrettier;
