import React from "react";
import "./styles.scss";
import classnames from "classnames";

type PlayIconProps = {
  className?: string;
};

const PlayIcon: React.ComponentType<PlayIconProps> = ({ className }) => (
  <span className={classnames("PlayIcon", className && className)}>
    <svg
      width='100%'
      height='100%'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      version='1.2'
      overflow='visible'
      preserveAspectRatio='none'
      x='0px'
      y='0px'
      viewBox='0 0 512 512'>
      <g>
        <path
          d='M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9V344c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z'
          vectorEffect='non-scaling-stroke'
        />
      </g>
    </svg>
  </span>
);

export default PlayIcon;
