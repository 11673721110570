export enum ProfileDocumentType {
  ID_FRONT = "id_front",
  ID_BACK = "id_back",
  ID_VERIFICATION = "id_verification",
  TAX_FORM = "tax_form",
  CERTIFICATION_FORM = "document_id_cert",
}

export enum UserAccountStatus {
  INCOMPLETE = "INCOMPLETE",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  PENDING = "PENDING",
  SUSPENDED = "SUSPENDED",
  SUSPENDED_TWICE = "SUSPENDED_TWICE",
  CANCELLED = "CANCELLED",
  CLOSED = "CLOSED",
}

export enum StudioModelsPermissions {
  IS_PRICING_SETTING_CHANGE_ALLOWED = "is_pricing_settings_change_allowed",
  IS_EARNINGS_AND_TRANSACTIONS_VISIBLE = "is_earnings_and_transactions_visible",
  IS_QUICK_TEXT_CHANGES_ALLOWED = "is_quick_text_changes_allowed",
  IS_TRANSACTION_DETAIL_VISIBLE = "is_transaction_detail_visible",
}
