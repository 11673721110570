import React from "react";
import classnames from "classnames";

type StopRecordingIconProps = {
  className?: string;
};

const StopRecordingIcon: React.FunctionComponent<StopRecordingIconProps> = ({
  className = "",
}) => {
  return (
    <span className={classnames("StopRecordingIcon", className)}>
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 512 512'
        xmlSpace='preserve'>
        <path
          d='M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm192-96H320c17.7 0 32 14.3 32 32V320c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V192c0-17.7 14.3-32 32-32z'
          vectorEffect='non-scaling-stroke'
        />
      </svg>
    </span>
  );
};

export default StopRecordingIcon;
