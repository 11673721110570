import React from "react";
import ProfileInfo, { ProfileInfoProps } from "../../profile/info";
import TailwindFlex from "../flex";

export type MediaProfileOverlayProps = {} & ProfileInfoProps;

const MediaProfileOverlay: React.ComponentType<MediaProfileOverlayProps> = ({
  ...profileInfoProps
}) => {
  return (
    <TailwindFlex
      height={"h-full"}
      alignItems={"items-end"}
      padding={["pl-4", "pb-2"]}>
      <ProfileInfo {...profileInfoProps} />
    </TailwindFlex>
  );
};

export default MediaProfileOverlay;
