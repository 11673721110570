import React, { useMemo, useState } from "react";
import StorybookSidebarItem from "./sidebar-item";
import StorybookSidebarSubmenu from "./sidebar-submenu";
import TailwindFlex from "../../../components/_tailwind/flex";
import StorybookSidebarHeading from "./sidebar-heading";
import TailwindAnchor from "../../../components/_tailwind/anchor";
import sortBy from "lodash/sortBy";

type Props = {};

const componentRoutes = [
  {
    label: "Text (Primitive)",
    link: "text",
  },
  {
    label: "Translated Text",
    link: "translated-text",
  },
  {
    label: "Translated Number",
    link: "translated-number",
  },
  {
    label: "Translated Date",
    link: "translated-date",
  },
  {
    label: "Translated Time",
    link: "translated-time",
  },
  {
    label: "Heading",
    link: "heading",
  },
  {
    label: "Paragraph",
    link: "paragraph",
  },
  {
    label: "Icon",
    link: "icon",
  },
  {
    label: "Box (Primitive)",
    link: "box",
  },
  {
    label: "Card",
    link: "card",
  },
  {
    label: "Flex",
    link: "flex",
  },
  {
    label: "Grid",
    link: "grid",
  },
  {
    label: "Anchor",
    link: "anchor",
  },
  {
    label: "Button",
    link: "button",
  },
  {
    label: "Button Group",
    link: "button-group",
  },
  {
    label: "Toggleable Button",
    link: "toggleable-button",
  },
  {
    label: "Input Base (Primitive)",
    link: "input-base",
  },
  {
    label: "Input",
    link: "input",
  },
  {
    label: "Dropdown",
    link: "Dropdown",
  },
  {
    label: "Checkbox/Radio",
    link: "checkbox-radio",
  },
  {
    label: "Switch",
    link: "switch",
  },
  {
    label: "Pill",
    link: "pill",
  },
  {
    label: "Badge",
    link: "Badge",
  },
  {
    label: "Drag and Drop",
    link: "drag-and-drop",
  },
  {
    label: "Divider",
    link: "divider",
  },
  {
    label: "Feed Components",
    link: "feed",
  },
  {
    label: "List Components",
    link: "list",
  },
  {
    label: "Media Components",
    link: "media",
  },
  {
    label: "Profile Components",
    link: "profile",
  },
  {
    label: "Video Player",
    link: "video-player",
  },
  {
    label: "Tabs",
    link: "tabs",
  },
  {
    label: "Snackbar",
    link: "snackbar",
  },
  {
    label: "Image",
    link: "image",
  },
  {
    label: "Swipeable Slider",
    link: "swipeable-slider",
  },
  {
    label: "Avatar",
    link: "avatar",
  },
  {
    label: "File Upload",
    link: "file-upload",
  },
  {
    label: "Live Models",
    link: "live-models",
  },
  {
    label: "Kink Search Bar",
    link: "kink-search-bar",
  },
  {
    label: "Interactions Page",
    link: "interactions-page",
  },
  {
    label: "Profile Page",
    link: "profile-page",
  },
  {
    label: "Notifications Menu",
    link: "notifications-menu",
  },
];

const StorybookSidebar: React.ComponentType<Props> = ({}) => {
  const [isSetupCollapsed, setIsSetupCollapsed] = useState<boolean>(true);
  const [isFlowCollapsed, setIsFlowCollapsed] = useState<boolean>(true);
  const [isConventionsCollapsed, setIsConventionsCollapsed] = useState<boolean>(
    true
  );
  const [
    isArchitectureCollapsed,
    setIsArchitectureCollapsed,
  ] = useState<boolean>(true);
  const [isComponentsCollapsed, setIsComponentsCollapsed] = useState<boolean>(
    true
  );

  const sortedComponentRoutes = useMemo(() => {
    return sortBy(componentRoutes, (o) => o.label);
  }, []);

  return (
    <TailwindFlex
      width={"w-2/12"}
      flexDirection={"flex-col"}
      maxHeight={"max-h-screen"}
      overflow={["overflow-y-auto"]}
      padding={["py-4"]}
    >
      <TailwindAnchor to={"/"}>
        <StorybookSidebarHeading>Introduction</StorybookSidebarHeading>
      </TailwindAnchor>
      <StorybookSidebarHeading
        isCollapsed={isSetupCollapsed}
        onClick={() => setIsSetupCollapsed(!isSetupCollapsed)}
      >
        Setup
      </StorybookSidebarHeading>
      <StorybookSidebarSubmenu isCollapsed={isSetupCollapsed}>
        <StorybookSidebarItem to={"/setup/code"}>Code</StorybookSidebarItem>
        <StorybookSidebarItem to={"/setup/designs"}>
          Designs
        </StorybookSidebarItem>
      </StorybookSidebarSubmenu>
      <StorybookSidebarHeading
        isCollapsed={isFlowCollapsed}
        onClick={() => setIsFlowCollapsed(!isFlowCollapsed)}
      >
        Flow
      </StorybookSidebarHeading>
      <StorybookSidebarSubmenu isCollapsed={isFlowCollapsed}>
        <StorybookSidebarItem to={"/flow/git"}>Git</StorybookSidebarItem>
        <StorybookSidebarItem to={"/flow/clickup"}>
          ClickUp
        </StorybookSidebarItem>
        <StorybookSidebarItem to={"/flow/deployments"}>
          Deployments
        </StorybookSidebarItem>
      </StorybookSidebarSubmenu>
      <StorybookSidebarHeading
        isCollapsed={isConventionsCollapsed}
        onClick={() => setIsConventionsCollapsed(!isConventionsCollapsed)}
      >
        Conventions
      </StorybookSidebarHeading>
      <StorybookSidebarSubmenu isCollapsed={isConventionsCollapsed}>
        <StorybookSidebarItem to={"/conventions/file-and-folder-structure"}>
          File and Folder Structure
        </StorybookSidebarItem>
        <StorybookSidebarItem to={"/conventions/naming"}>
          Naming
        </StorybookSidebarItem>
        <StorybookSidebarItem to={"/conventions/css-and-scss"}>
          CSS & SCSS
        </StorybookSidebarItem>
        <StorybookSidebarItem
          to={"/conventions/javascript-and-typescript-and-react"}
        >
          Javascript, Typescript & React
        </StorybookSidebarItem>
        <StorybookSidebarItem to={"/conventions/eslint-and-prettier"}>
          Eslint & Prettier
        </StorybookSidebarItem>
        <StorybookSidebarItem to={"/conventions/oop-vs-functional"}>
          OOP vs Functional
        </StorybookSidebarItem>
        <StorybookSidebarItem to={"/conventions/installing-packages"}>
          Installing Packages
        </StorybookSidebarItem>
      </StorybookSidebarSubmenu>
      <StorybookSidebarHeading
        isCollapsed={isArchitectureCollapsed}
        onClick={() => setIsArchitectureCollapsed(!isArchitectureCollapsed)}
      >
        Architecture
      </StorybookSidebarHeading>
      <StorybookSidebarSubmenu isCollapsed={isArchitectureCollapsed}>
        <StorybookSidebarItem to={"/architecture/monorepo"}>
          Monorepo
        </StorybookSidebarItem>
        <StorybookSidebarItem to={"/architecture/file-and-folder-structure"}>
          File and Folder Structure
        </StorybookSidebarItem>
        <StorybookSidebarItem
          to={"/architecture/adaptive-and-responsive-design"}
        >
          Adaptive & Responsive Design
        </StorybookSidebarItem>
        <StorybookSidebarItem to={"/architecture/layout"}>
          Layout
        </StorybookSidebarItem>
        <StorybookSidebarItem to={"/architecture/tailwind-css"}>
          TailwindCSS
        </StorybookSidebarItem>
        <StorybookSidebarItem to={"/architecture/mobx-and-stores"}>
          Mobx & Utility Stores
        </StorybookSidebarItem>
        <StorybookSidebarItem to={"/architecture/utilities"}>
          Utilities/Services
        </StorybookSidebarItem>
        <StorybookSidebarItem to={"/architecture/theming"}>
          Theming
        </StorybookSidebarItem>
        <StorybookSidebarItem to={"/architecture/i18n"}>
          Internalization & Localization
        </StorybookSidebarItem>
        <StorybookSidebarItem to={"/architecture/tests"}>
          Tests
        </StorybookSidebarItem>
      </StorybookSidebarSubmenu>
      <StorybookSidebarHeading
        isCollapsed={isComponentsCollapsed}
        onClick={() => setIsComponentsCollapsed(!isComponentsCollapsed)}
      >
        Components
      </StorybookSidebarHeading>
      <StorybookSidebarSubmenu isCollapsed={isComponentsCollapsed}>
        <StorybookSidebarItem to={`/components/introduction`}>
          Introduction to Components
        </StorybookSidebarItem>
        {sortedComponentRoutes.map((route) => (
          <StorybookSidebarItem
            key={route.link}
            to={`/components/${route.link}`}
          >
            {route.label}
          </StorybookSidebarItem>
        ))}
      </StorybookSidebarSubmenu>
      <TailwindAnchor to={"/types-and-constants"}>
        <StorybookSidebarHeading>Types & Constants</StorybookSidebarHeading>
      </TailwindAnchor>
    </TailwindFlex>
  );
};

export default StorybookSidebar;
