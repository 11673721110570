import React, { useCallback } from "react";
import TailwindHeading, {
  TailwindHeadingProps,
} from "../../../components/_tailwind/heading";
import TailwindButton from "../../../components/_tailwind/button";
import TailwindFlex from "../../../components/_tailwind/flex";

type Props = Omit<TailwindHeadingProps, "level"> & {
  isCollapsed?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const StorybookSidebarHeading: React.ComponentType<Props> = ({
  children,
  isCollapsed,
  onClick,
  ...props
}) => {
  const onClickHeading = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(event);
      }
    },
    [onClick]
  );

  return (
    <TailwindFlex>
      <TailwindButton
        rightIconProps={
          onClick
            ? {
                name: isCollapsed ? "keyboard_arrow_down" : "keyboard_arrow_up",
                textColor: "text-red-500",
              }
            : undefined
        }
        onClick={onClickHeading}
      >
        <TailwindHeading
          {...props}
          level={6}
          textTransform={"uppercase"}
          margin={["my-2"]}
          textColor={"text-red-500"}
        >
          {children}
        </TailwindHeading>
      </TailwindButton>
    </TailwindFlex>
  );
};

export default StorybookSidebarHeading;
