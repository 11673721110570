import React from "react";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import TailwindList, {
  TailwindListType,
} from "library/components/_tailwind/list";
import TailwindListItem from "library/components/_tailwind/list/list-item";
import TailwindParagraph from "library/components/_tailwind/paragraph";
import LanguageStore from "library/core/stores/language/LanguageStore";
import { inject, observer } from "mobx-react";

type Props = {
  onTerminateSession?: () => {};
  languageStore?: LanguageStore;
};

const BroadcastIsAlreadyBroadcastingError: React.ComponentType<Props> = ({
  onTerminateSession,
  languageStore,
}) => {
  const { intl } = languageStore!;

  return (
    <TailwindFlex
      height={"h-full"}
      alignItems={"items-center"}
      justifyContent={"justify-center"}
      flexDirection='flex-col'>
      <TailwindFlex
        alignItems={"items-center"}
        justifyContent={"justify-center"}>
        <TailwindTranslatedText
          headingProps={{
            level: 5,
            textAlign: "text-center",
          }}
          descriptor={{
            id: "broadcast-error.broadcasting-session-alert",
            defaultMessage: "Broadcasting Session Alert",
          }}
        />
      </TailwindFlex>

      <TailwindFlex
        alignItems={"items-center"}
        justifyContent={"justify-center"}
        margin={["mt-2"]}
        flexDirection={"flex-col"}>
        <TailwindFlex
          width={"w-2/3"}
          margin={["mb-2"]}
          flexDirection={"flex-col"}>
          <TailwindParagraph>
            <TailwindTranslatedText
              descriptor={{
                id: "broadcast-error.session-already-active",
                defaultMessage:
                  "It appears you have another broadcasting session already active. This may occur due to:",
              }}
            />
          </TailwindParagraph>
          <TailwindList
            type={TailwindListType.ul}
            padding={["pl-4"]}
            margin={["mb-2"]}>
            <TailwindListItem margin={["ml-4"]}>
              <TailwindTranslatedText
                descriptor={{
                  id: "broadcast-error.already-active-item1",
                  defaultMessage:
                    "An ongoing broadcast in another window or tab,",
                }}
              />
            </TailwindListItem>
            <TailwindListItem margin={["ml-4"]}>
              <TailwindTranslatedText
                descriptor={{
                  id: "broadcast-error.already-active-item2",
                  defaultMessage:
                    "An active OBS (Open Broadcasting Software) session that hasn't been properly closed, or",
                }}
              />
            </TailwindListItem>
            <TailwindListItem margin={["ml-4"]}>
              <TailwindTranslatedText
                descriptor={{
                  id: "broadcast-error.already-active-item3",
                  defaultMessage:
                    "Your account is being used to broadcast from a different location.",
                }}
              />
            </TailwindListItem>
          </TailwindList>
          <TailwindParagraph margin={["mb-2"]}>
            <TailwindTranslatedText
              descriptor={{
                id: "broadcast-error.ensure-previous-sessions-closed",
                defaultMessage:
                  "Please ensure that any previous sessions are fully terminated and closed before starting a new broadcast. Check for any hidden or minimized windows and ending or restarting any active OBS broadcasts.",
              }}
            />
          </TailwindParagraph>
          <TailwindParagraph margin={["mb-2"]}>
            <TailwindTranslatedText
              descriptor={{
                id: "broadcast-error.click-to-terminate",
                defaultMessage:
                  "{quick_fix} If you're unable to locate or close the other session, simply click the {terminate_all_sessions} button below to automatically close any other broadcasting sessions and continue with your current broadcast.",
              }}
              values={{
                quick_fix: `<strong>${intl.formatMessage({
                  id: "broadcast-error.quick_fix",
                  defaultMessage: "Quick Fix",
                })}:</strong>`,
                terminate_all_sessions: `<strong>&quot;${intl.formatMessage({
                  id: "broadcast-error.terminate-session",
                  defaultMessage: "Terminate All Other Sessions",
                })}&quot;</strong>`,
              }}
            />
          </TailwindParagraph>
          <TailwindParagraph margin={["mb-2"]}>
            <TailwindTranslatedText
              descriptor={{
                id: "broadcast-error.reach-out-for-support",
                defaultMessage:
                  "Should you need further assistance or have any questions, please do not hesitate to reach out to our support team. We're here to help!",
              }}
            />
          </TailwindParagraph>
        </TailwindFlex>

        <TailwindFlex
          alignItems={"items-center"}
          justifyContent={"justify-center"}>
          <TailwindButton
            fullWidth={false}
            backgroundColor={"bg-avatar-secondary-bg-color"}
            justifyContent={"justify-center"}
            onClick={onTerminateSession}>
            <TailwindTranslatedText
              className={"BroadcastPreview__StartStreamingButtonText"}
              fontSize={"text-sm"}
              textTransform={"capitalize"}
              fontWeight={"font-bold"}
              textColor={"text-go-live-text"}
              descriptor={{
                id: "broadcast-error.terminate-session",
                defaultMessage: "Terminate All Other Sessions",
              }}
            />
          </TailwindButton>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default inject("languageStore")(
  observer(BroadcastIsAlreadyBroadcastingError)
);
