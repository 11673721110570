import React from "react";
import TailwindBox from "library/components/_tailwind/box";
import TailwindText from "library/components/_tailwind/text";
import "./styles.scss";
import { FALLBACK_PERSONS_APPEARING_ON_STREAMRAY } from "core/constants";

type Props = {};
const PersonsAppearingOnStreamray = ({}: Props) => {
  return (
    <div className={"PersonsAppearingOnStreamray"}>
      <TailwindText
        display={"block"}
        fontSize={"text-3xl"}
        fontWeight={"font-bold"}
        padding={["py-2"]}
        margin={["mb-4"]}>
        A note about persons appearing on Streamray Inc. websites.
      </TailwindText>
      <TailwindBox
        dangerouslySetInnerHTML={{
          __html: FALLBACK_PERSONS_APPEARING_ON_STREAMRAY,
        }}></TailwindBox>
    </div>
  );
};

export default PersonsAppearingOnStreamray;
