import {
  CamsPayoutSettingCheck,
  CamsPayoutSettingDirectDeposit,
  CamsPayoutSettingPaxum,
  CamsPayoutSettingWireTransfer,
} from "./types";
import { CAMS_MODEL_PAYOUT_METHOD_KEY, PAYOUT_METHOD_WIRE_TRANSFER_TYPES_ENUM } from "./enums";
import { getDefaultPayoutSetting } from "./utils";

export const PAYOUT_CHECKING_DEFAULT: CamsPayoutSettingCheck = {
  name: "",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  phone_number: "",
  country: "",
};

export const PAYOUT_DIRECT_DEFAULT: CamsPayoutSettingDirectDeposit = {
  account_name: "",
  routing_number: "",
  account_number: "",
  account_type: "checking",
  country: "",
};

export const PAYOUT_WIRE_DEFAULT: CamsPayoutSettingWireTransfer = {
  account_name: "",
  account_number: "",
  bank_name: "",
  bank_address: "",
  routing_number: "",
  name_on_account: "",
  address_on_account: "",
  inter_correspondent_bank_name: "",
  inter_correspondent_bank_swift: "",
  iban: "",
  swift: "",
  country: "",
};

export const PAYOUT_PAXUM_DEFAULT: CamsPayoutSettingPaxum = {
  email: "",
  country: "",
};

export const PayoutInitialData: any = {
  loadingPayoutMethods: false,
  submittingPaymentDetails: false,
  submittingConfirmPaymentDetails: false,
  wireTransferType: "Domestic",

  currentPeriodTitle: "",
  showPayoutConfirmation: false,

  payoutSettings: [
    getDefaultPayoutSetting(CAMS_MODEL_PAYOUT_METHOD_KEY.check),
    getDefaultPayoutSetting(CAMS_MODEL_PAYOUT_METHOD_KEY.wired_transfer),
    getDefaultPayoutSetting(CAMS_MODEL_PAYOUT_METHOD_KEY.paxum),
    getDefaultPayoutSetting(CAMS_MODEL_PAYOUT_METHOD_KEY.direct_deposit),
  ],
};

export const CAMS_PAYOUT_WIRE_TRANSFER_TYPES = [
  PAYOUT_METHOD_WIRE_TRANSFER_TYPES_ENUM.DOMESTIC,
  PAYOUT_METHOD_WIRE_TRANSFER_TYPES_ENUM.INTERNATIONAL,
];
