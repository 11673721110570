import React from "react";
import TailwindBox from "../box";
import { CustomIconName } from "../icon/icons/enums";
import { TGridTemplateColumns } from "../../../styles/tailwind-classnames";
import AffProfileActionButton, {
  AFFProfileActionButtonVariant,
} from "./action-button";

type Props = {
  gridCols?: TGridTemplateColumns;
  variant?: AFFProfileActionButtonVariant;
};

const AffProfileActions = ({ gridCols = "grid-cols-3", variant }: Props) => {
  return (
    <TailwindBox display={"inline-grid"} gridCols={gridCols} gap={"gap-4"}>
      <AffProfileActionButton
        variant={variant}
        icon={CustomIconName.flirt}
        iconHoverColor={"text-purple-400"}
        borderColor={"border-purple-400"}
      />
      <AffProfileActionButton
        variant={variant}
        icon={CustomIconName.fire}
        iconHoverColor={"text-pink-400"}
        borderColor={"border-pink-400"}
      />
      <AffProfileActionButton
        variant={variant}
        icon={"person_add"}
        iconHoverColor={"text-blue-400"}
        borderColor={"border-blue-400"}
      />
      <AffProfileActionButton
        variant={variant}
        icon={CustomIconName["chat-bubble"]}
        iconHoverColor={"text-green-400"}
        borderColor={"border-green-400"}
      />
      <AffProfileActionButton
        variant={variant}
        icon={"visibility_off"}
        iconHoverColor={"text-gray-600"}
        borderColor={"border-gray-600"}
      />
      <AffProfileActionButton
        variant={variant}
        icon={"redeem"}
        iconHoverColor={"text-yellow-600"}
        borderColor={"border-yellow-600"}
      />
    </TailwindBox>
  );
};

export default AffProfileActions;
