import React from "react";
import TailwindButton, {
  TailwindButtonProps,
} from "library/components/_tailwind/button";

interface Props extends TailwindButtonProps {}

const BroadcastTapeButton: React.FunctionComponent<Props> = props => {
  return (
    <TailwindButton
      padding={["p-1"]}
      borderColor='border-black'
      borderRadius={["rounded"]}
      borderWidth={["border-0"]}
      justifyContent='justify-center'
      alignItems={"items-center"}
      size={"sm"}
      {...props}></TailwindButton>
  );
};

export default BroadcastTapeButton;
