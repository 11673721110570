import React, { useMemo, useState } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import TailwindFlex from "library/components/_tailwind/flex";
import MessageCenterSearchBox from "./messages-navigator-contact-conversation-search-box";
import MessageStore from "./stores/messages/MessageStore";
import { inject, observer } from "mobx-react";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import TailwindButton from "library/components/_tailwind/button";
import MessageCenterConversationList from "./messages-navigator-conversation-list";
import TailwindText from "library/components/_tailwind/text";

type Props = {
  messageStore?: MessageStore;
  layoutStore?: LayoutStore;
  goBack: () => void;
};

const SeatchResult: React.ComponentType<Props & WrappedComponentProps> = ({
  messageStore,
  goBack,
  layoutStore,
}) => {
  const {
    isSearchingConversations,
    isConversationsLoadingMore,
    setConversationsSearchTerm,
    isSearchingContacts,
    isContactsLoadingMore,
    conversationsSearchTerm,
    conversations,
    isConversationsLoading,
    isContactsLoading,
  } = messageStore!;
  const [searchResultMatchesUsername, setSearchResultMatchesUsername] =
    useState<string>("");
  const [searchResultMatchesCount, setSearchResultMatchesCount] =
    useState<number>(0);
  const [searchedMessagesView, setSearchedMessagesView] =
    useState<boolean>(false);
  const { isMobileScreenSize, isMobileDevice } = layoutStore!;

  const isSearchBoxDisabled = useMemo(
    () =>
      isSearchingConversations || isContactsLoadingMore || isSearchingContacts,
    [isConversationsLoadingMore]
  );

  const isMobile = useMemo(
    () => isMobileDevice || isMobileScreenSize,
    [isMobileScreenSize, isMobileDevice]
  );

  return (
    <TailwindFlex flexDirection='flex-col'>
      <TailwindFlex
        width={"w-full"}
        justifyContent={"justify-start"}
        padding={["py-3", "px-2"]}
        backgroundColor='bg-lightBlue'
        borderWidth={
          isConversationsLoading || isContactsLoading || !conversations?.length
            ? ["border-b-2"]
            : []
        }
        borderColor={"border-primary-bg-color"}>
        <TailwindButton
          width='w-8'
          height='h-8'
          maxWidth='max-w-min'
          padding={["p-0"]}
          onClick={() => {
            if (searchResultMatchesCount) {
              setSearchedMessagesView(false);
            } else {
              goBack();
            }
          }}
          alignSelf='self-center'
          rightIconProps={{
            name: "keyboard_arrow_left",
            fontSize: "text-4xl",
          }}
        />
        {searchResultMatchesCount > 0 ? (
          <TailwindFlex alignItems={"items-center"}>
            <TailwindText fontWeight='font-black'>
              {searchResultMatchesUsername}
            </TailwindText>
          </TailwindFlex>
        ) : (
          <MessageCenterSearchBox
            placeholder={"Messages"}
            setSearchTerm={setConversationsSearchTerm}
            searchTerm={conversationsSearchTerm}
            disabled={isSearchBoxDisabled}
            isMobile={isMobile}
          />
        )}
      </TailwindFlex>

      <TailwindFlex
        margin={["m-0"]}
        height='h-vh-full'
        backgroundColor='bg-white'>
        <MessageCenterConversationList
          isMobile={isMobile}
          searchedMessagesView={searchedMessagesView}
          setSearchedMessagesView={setSearchedMessagesView}
          onMultipleMatchesViewShown={messages => {
            setSearchResultMatchesCount(messages?.length);
            setSearchResultMatchesUsername(
              !messages?.length ? "" : messages?.[0].participants?.[0].username
            );
          }}
        />
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject("messageStore", "layoutStore")(observer(SeatchResult))
);
