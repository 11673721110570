import { makeAutoObservable } from "mobx";
import { ISnackbarProps } from "./interfaces";
import { SnackbarVariants } from "./enums";
import toast, { ToastOptions } from "react-hot-toast";
import React from "react";
import { languageStore } from "../language/LanguageStore";
import SnackbarActionButton from "../../../components/snackbar/action-button";
import { SnackbarCloseButton } from "../../../components/snackbar/snackbar-close-button";

export default class SnackbarStore {
  snackbars: string[] = [];

  defaultSnackbarOptions: ToastOptions = {
    duration: 5000,
    style: {
      color: "#fff",
    },
  };

  snackbarOptions = {
    success: {
      ...this.defaultSnackbarOptions,
      className: "snackbar--success",
      style: {
        ...this.defaultSnackbarOptions.style,
        background: "#43a047",
      },
    },
    error: {
      ...this.defaultSnackbarOptions,
      className: "snackbar--error",
      style: {
        ...this.defaultSnackbarOptions.style,
        background: "#d32f2f",
      },
    },
    warning: {
      ...this.defaultSnackbarOptions,
      className: "snackbar--warning",
      style: {
        ...this.defaultSnackbarOptions.style,
        background: "#ff9800",
      },
    },
    info: {
      ...this.defaultSnackbarOptions,
      className: "snackbar--info",
      style: {
        ...this.defaultSnackbarOptions.style,
        background: "#2196f3",
      },
    },
    loading: {
      ...this.defaultSnackbarOptions,
      duration: Infinity,
      className: "snackbar--info",
      style: {
        ...this.defaultSnackbarOptions.style,
        background: "#2196f3",
      },
    },
  };

  constructor() {
    makeAutoObservable(this);    
  }

  public enqueueSimpleSnackbar(id: string, variant: SnackbarVariants) {
    this.enqueueSnackbar({
      message: { id },
      variant,
    });
  }

  enqueueSnackbar = ({
    message,
    variant,
    options,
    actions = [],
    closeButton,
  }: ISnackbarProps) => {
    if (!this.snackbars.includes(message.id)) {
      const defaultOptions = this.snackbarOptions[variant];
      const _options: ToastOptions = {
        ...defaultOptions,
        ...options,
        id: message.id,
      };
      const toastId = toast(
        t => (
          <div>
            {languageStore.intl.formatMessage(
              { id: message.id, defaultMessage: message.default },
              message.parameters
            )}
            {actions.map(action =>
              React.createElement(SnackbarActionButton, {
                ...action,
                id: t.id,
                onClick: action.callback,
                callbackParams: action.parameters,
                label: languageStore.intl.formatMessage({
                  id: action.labelId,
                  defaultMessage: action.defaultLabel,
                }),
              })
            )}
            {closeButton && (
              <span className={"ml-1"}>
                {React.createElement(SnackbarCloseButton, { id: t.id })}
              </span>
            )}
          </div>
        ),
        _options
      );
      this.snackbars.push(toastId);

      if (_options.duration !== Infinity) {
        setTimeout(() => {
          this.dismissSnackbar(toastId);
        }, _options.duration);
      }
    }
  };

  dismissSnackbar = (toastId: string) => {
    this.snackbars = this.snackbars.filter(_toastId => _toastId !== toastId);
    toast.dismiss(toastId);
  };
}

export const snackbarStore = new SnackbarStore();
