import React from "react";
import "./styles.scss";

type ProfileIconProps = {
  className?: string;
};

const ProfileIcon: React.ComponentType<ProfileIconProps> = ({ className }) => (
  <span className={`${className} camsIcon`}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
      <path d='M50,49.82A18.65,18.65,0,1,0,31.35,31.17,18.68,18.68,0,0,0,50,49.82Zm0-32.3A13.65,13.65,0,1,1,36.35,31.17,13.66,13.66,0,0,1,50,17.52Z' />
      <path d='M99.06,50A49.06,49.06,0,1,0,14.7,84v0l.93.92A48.76,48.76,0,0,0,40.06,98l.08,0c.71.14,1.42.27,2.13.38l.32,0c.64.1,1.3.18,1.95.25l.51.07c.63.06,1.26.1,1.89.14l.58,0c.82,0,1.65.07,2.48.07s1.66,0,2.48-.07l.58,0c.63,0,1.26-.08,1.89-.14l.51-.07c.65-.07,1.31-.15,1.95-.25l.32,0c.71-.11,1.42-.24,2.13-.38l.08,0A48.76,48.76,0,0,0,84.37,85l.93-.92v0A48.9,48.9,0,0,0,99.06,50ZM50,5.94A44,44,0,0,1,83.93,78.07a35.63,35.63,0,0,0-67.86,0A44,44,0,0,1,50,5.94ZM63.1,92.07h0c-.65.21-1.3.39-2,.56l-.21.06c-.59.15-1.19.28-1.8.41l-.38.08c-.55.11-1.1.21-1.66.3l-.52.09-1.57.2-.61.07c-.52,0-1,.08-1.58.12l-.6,0q-1.09.06-2.19.06T47.81,94l-.6,0c-.53,0-1.06-.07-1.58-.12L45,93.77l-1.57-.2-.52-.09c-.56-.09-1.11-.19-1.66-.3l-.38-.08c-.61-.13-1.21-.26-1.8-.41l-.21-.06c-.66-.17-1.31-.35-2-.56h0a44.08,44.08,0,0,1-16.79-9.75,30.61,30.61,0,0,1,59.78,0A44.08,44.08,0,0,1,63.1,92.07Z' />
    </svg>
  </span>
);

export default ProfileIcon;
