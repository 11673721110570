import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import config from "core/config";
import { SHOWCASE_TARGET_CLASSNAMES } from "core/stores/showcase/const";

type Props = {};

const EarningsTableSimpleHead: React.ComponentType<Props> = ({}) => {
  const { showYouMakeColumnOnEarningsReport } = config!;

  return (
    <TailwindFlex padding={["p-3", "pl-5", "pr-5"]} backgroundColor={"bg-sidebar-bg-color"}>
      <TailwindFlex width={"w-3/12"} justifyContent={"justify-start"}>
        <TailwindTranslatedText
          descriptor={{
            id: "modelPayoutTable.source",
            defaultMessage: "Source",
          }}
        />
      </TailwindFlex>
      {showYouMakeColumnOnEarningsReport && (
        <TailwindFlex
          width={"w-6/12"}
          justifyContent={"justify-start"}
          className={[SHOWCASE_TARGET_CLASSNAMES.earningsYouMake]}>
          <TailwindTranslatedText
            descriptor={{
              id: "modelPayoutTable.you_make",
              defaultMessage: "You Make",
            }}
          />
        </TailwindFlex>
      )}
      <TailwindFlex width={"w-3/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedText
          descriptor={{
            id: "modelPayoutTable.tokens",
            defaultMessage: "Tokens",
          }}
        />
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default EarningsTableSimpleHead;
