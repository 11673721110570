import React from "react";
import TailwindFlex, { TailwindFlexProps } from "library/components/_tailwind/flex";

export type MediaManagerMediaCardHeaderSize = "sm" | "normal";

export type MediaManagerMediaCardHeaderProps = TailwindFlexProps & {
  isMyProfile?: boolean
};

const MediaManagerMediaCardHeader: React.ComponentType<MediaManagerMediaCardHeaderProps> = ({
  padding,
  children,
  isMyProfile,
  ...props
}) => {
  return (
    <TailwindFlex
      {...props}
      justifyContent={"justify-between"}
      alignItems={"items-end"}
      position={!isMyProfile ? "absolute" : "static"}
      zIndex={"z-10"}
      padding={!isMyProfile ? [...(padding || []), "px-2", "pt-2"] : ["p-0"]}
      className={["Album__title"]}>
      {children}
    </TailwindFlex>
  );
};

export default MediaManagerMediaCardHeader;
