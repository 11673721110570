import React, { FunctionComponent, useCallback, useMemo } from "react";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import AuthStore from "core/stores/auth/AuthStore";
import ProfileStore from "../my-page/stores/profile/ProfileStore";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import "./styles.scss";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import TailwindText from "library/components/_tailwind/text";
import { RegistrationStep } from "core/stores/auth/enums";
import { openRegisterModal } from "core/stores/auth/utils";
import TailwindBox from "library/components/_tailwind/box";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import { DenyReason } from "common/my-page/stores/profile/types";

import ApplicationDenied from "./summary-containers/application-denied";
import ApplicationIncomplete from "./summary-containers/application-incomplete";
import ApplicationPending from "./summary-containers/application-pending";
import VerifyIdStep from "./summary-containers/verify-id-step";
import SignAgreementStep from "./summary-containers/sign-agreement-step";
import AccountApprovalStep from "./summary-containers/account-approval-step";

type SummaryStepProps = {
  authStore?: AuthStore;
  profileStore?: ProfileStore;
  classes?: string;
};

const SummaryStep: FunctionComponent<SummaryStepProps & WrappedComponentProps> =
  ({ authStore, profileStore, intl }) => {
    const { completeSummaryStep, setRegistrationStep, userRole } = authStore!;
    const {
      profile,
      isModel,
      isStudio,
      //isCams
    } = profileStore!;
    const { missing_signup_fields, deny_reasons } = profile;

    const isSignAgreementIncomplete = useMemo(
      () => missing_signup_fields.includes("signed_agreement"),
      [missing_signup_fields]
    );

    const isSignAgreementPending = useMemo(
      () => !missing_signup_fields.includes("signed_agreement"),
      [missing_signup_fields]
    );

    const isVerificationIncomplete = useMemo(() => {
      return !!missing_signup_fields.find(msf => msf.startsWith("document"));
    }, [missing_signup_fields]);

    const isVerificationPending = useMemo(
      () =>
        isSignAgreementPending
          ? missing_signup_fields.length > 1
          : !!missing_signup_fields.length,
      [missing_signup_fields]
    );
    const verifyIDStatusDone = useMemo(
      () =>
        !deny_reasons.find(
          item =>
            item.property == "document_front" ||
            item.property == "document_back" ||
            item.property == "document_verification" ||
            item.property == "document"
        ),
      [deny_reasons]
    );

    const signedAgreementStatusDone = useMemo(
      () =>
        !deny_reasons.find(
          item =>
            item.property == "Broadcasting Agreement" ||
            item.property == "Broadcast Release" ||
            item.property == "Performer's Acknowledgement and Agreement"
        ),
      [deny_reasons]
    );

    const isApplicationIncomplete = useMemo(() => {
      return (
        profile.status === "INCOMPLETE" ||
        isVerificationIncomplete ||
        isSignAgreementIncomplete
      );
    }, [profile, isVerificationIncomplete, isSignAgreementIncomplete]);

    const isApplicationPending = useMemo(() => {
      return (
        profile.status === "PENDING" &&
        !isVerificationIncomplete &&
        !isSignAgreementIncomplete
      );
    }, [profile, isVerificationIncomplete, isSignAgreementIncomplete]);

    const isApplicationDenied = useMemo(
      () => profile.status === "DENIED",
      [profile]
    );

    const openRegisterUploadModal = useCallback(() => {
      setRegistrationStep(
        isModel
          ? RegistrationStep.MODEL_DOCUMENT_SELECTION_AND_UPLOAD
          : RegistrationStep.STUDIO_DOCUMENT_SELECTION_AND_UPLOAD
      );
      openRegisterModal();
    }, [isModel]);

    const openRegisterAgreementModal = useCallback(() => {
      setRegistrationStep(
        isModel
          ? RegistrationStep.MODEL_AGREEMENT
          : RegistrationStep.STUDIO_AGREEMENT
      );
      openRegisterModal();
    }, [isModel]);

    const hasAdditionalNote: DenyReason | undefined = useMemo(
      () => deny_reasons.find(item => item.property == "additional_notes"),
      [deny_reasons]
    );
    const hasMiscellaneous: DenyReason | undefined = useMemo(
      () => deny_reasons.find(item => item.property == "miscellaneous"),
      [deny_reasons]
    );

    return (
      <TailwindFlex width={"w-112"} sm={"w-full"} height={"h-full"}>
        <SimpleBar className='RegisterModal__form-scrollarea'>
          <div className='RegisterModal__form'>
            <form noValidate autoComplete='false' className={"AuthModal__form"}>
              <div className={"w-full mt-0"}>
                <TailwindTranslatedText
                  textColor='text-main-color'
                  descriptor={{
                    id: "landing.summaryStep.modelApplication",
                    defaultMessage: "STREAMRAY'S {applicationType} APPLICATION",
                  }}
                  values={{
                    applicationType: isStudio ? "STUDIO" : "MODEL",
                  }}
                  headingProps={{ level: 6 }}
                />
                <TailwindTranslatedText
                  textColor='text-main-color'
                  descriptor={{
                    id: "landing.summaryStep.stepsAway",
                    defaultMessage:
                      "You're steps away from becoming a {applicationType}!",
                  }}
                  values={{
                    applicationType: isStudio ? "studio" : "model",
                  }}
                  headingProps={{ level: 6, margin: ["mt-1", "mb-3"] }}
                />
              </div>
              <div className={"w-full mt-2 mb-8"}>
                {isApplicationDenied && (
                  <ApplicationDenied isStudio={isStudio} />
                )}

                {isApplicationIncomplete && (
                  <ApplicationIncomplete isStudio={isStudio} />
                )}

                {isApplicationPending && (
                  <ApplicationPending isStudio={isStudio} />
                )}
              </div>
              <div>
                <div className='w-full flex mt-2 mb-2 h-10'>
                  <div className='w-6/12 mr-1'>
                    <TailwindText textColor='text-main-color'>
                      {intl.formatMessage({
                        id: "landing.summaryStep.step1",
                        defaultMessage: "Create Your Account",
                      })}
                    </TailwindText>
                  </div>
                  <div className='w-6/12 mr-1'>
                    <TailwindText
                      textColor={"text-green-500"}
                      fontWeight={"font-bold"}>
                      {intl.formatMessage({
                        id: "landing.summaryStep.done",
                        defaultMessage: "Done",
                      })}
                    </TailwindText>
                  </div>
                </div>
                <div className='w-full flex mt-2 mb-2 h-10'>
                  <VerifyIdStep
                    isVerificationIncomplete={isVerificationIncomplete}
                    openRegisterUploadModal={openRegisterUploadModal}
                    isApplicationPending={isApplicationPending}
                    isVerificationPending={isVerificationPending}
                    verifyIDStatusDone={verifyIDStatusDone}
                  />
                </div>
                <div className='w-full flex mt-2 mb-2 h-10'>
                  <SignAgreementStep
                    isSignAgreementIncomplete={isSignAgreementIncomplete}
                    openRegisterAgreementModal={openRegisterAgreementModal}
                    isApplicationPending={isApplicationPending}
                    isSignAgreementPending={isSignAgreementPending}
                    signedAgreementStatusDone={signedAgreementStatusDone}
                    isModel={userRole === "model"}
                  />
                </div>
                <div className='w-full flex mt-2 mb-2 h-10'>
                  <AccountApprovalStep
                    isApplicationPending={isApplicationPending}
                    isApplicationDenied={isApplicationDenied}
                    isApplicationIncomplete={isApplicationIncomplete}
                  />
                </div>
              </div>
              {(hasMiscellaneous || hasAdditionalNote) && (
                <TailwindBox
                  backgroundColor={"bg-yellow-100"}
                  padding={["p-5"]}
                  margin={["mb-5"]}>
                  {hasMiscellaneous && (
                    <TailwindText fontWeight={"font-bold"}>
                      {hasMiscellaneous.text}
                    </TailwindText>
                  )}
                  {hasAdditionalNote && (
                    <TailwindBox margin={["mt-2"]}>
                      {hasAdditionalNote.text}
                    </TailwindBox>
                  )}
                </TailwindBox>
              )}
              <div className={"RegisterModal__submit"}>
                <TailwindButton
                  maxWidth={"max-w-70"}
                  margin={["mt-4"]}
                  justifyContent={"justify-center"}
                  backgroundColor={"primary"}
                  rounded={false}
                  disabled={authStore!.isActionInProgress}
                  onClick={completeSummaryStep}>
                  {intl.formatMessage({
                    id: "common.ok",
                    defaultMessage: "OK",
                  })}
                </TailwindButton>
              </div>
            </form>
          </div>
        </SimpleBar>
      </TailwindFlex>
    );
  };
export default injectIntl(
  inject("authStore", "routeStore", "profileStore")(observer(SummaryStep))
);
