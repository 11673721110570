import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MessageCenterMessage from "./messages-reader-message";
import LoadingResourceSpinner from "./messages-loading-spinner";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { useScroll, usePrevious } from "library/core/utility/hooks";
import {
  MessageCenterConversationDetails,
  MessageCenterMessageAttachmentDetails,
  MessageCenterMessageDetails,
  MessageQueueChunk,
  PendingAttachmentData,
} from "./stores/messages/types";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindFlex from "library/components/_tailwind/flex";
import Avatar from "library/components/avatar";
import TailwindBox from "library/components/_tailwind/box";
import MessageStore from "./stores/messages/MessageStore";
import { inject, observer } from "mobx-react";
import TailwindTooltip from "library/components/_tailwind/tooltip";
import TailwindText from "library/components/_tailwind/text";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";

type Props = {
  conversation: MessageCenterConversationDetails | null;
  messages?: MessageCenterMessageDetails[];
  getMessages: (loadMore?: boolean) => void;
  getFutureMessages: () => void;
  isLoading: boolean;
  isLoadingMore: boolean;
  message: string;
  hasMore: boolean;
  hasMoreFuture: boolean;
  userName: string;
  highlightedWord?: string;
  messageStore?: MessageStore;
  layoutStore?: LayoutStore;
  hasConversations: boolean;
  initAtMessageStartDate?: string;
  profileStore?: ProfileStore;
};

const MessageCenterMessageList: React.ComponentType<
  Props & WrappedComponentProps
> = ({
  conversation,
  messages,
  getMessages,
  getFutureMessages,
  isLoading,
  isLoadingMore,
  message,
  hasMore,
  hasMoreFuture,
  userName,
  highlightedWord,
  hasConversations,
  initAtMessageStartDate,
  messageStore,
  layoutStore,
  profileStore,
  intl,
}) => {
  const [isRefreshingMessages, setIsRefreshingMessages] =
    useState<boolean>(false);
  const {
    isBulkMessageView,
    selectedContacts,
    contacts,
    isActiveConversationMessagesRefreshing,
    activeConversationId,
    sendMessageQueue,
    attachmentsPendingUpload,
    cancelSendMessage,
  } = messageStore!;
  const { isMobileDevice } = layoutStore!;
  const { profile } = profileStore!;
  const previousMessage = usePrevious<string>(message);
  const previousPendingAttachments = usePrevious<PendingAttachmentData[]>(
    attachmentsPendingUpload
  );
  const previousConversation =
    usePrevious<MessageCenterConversationDetails>(conversation);
  const loadMoreMessages = useCallback(() => {
    if (hasMore && !isLoading && !isLoadingMore) {
      getMessages(true);
    }
  }, [isLoading, isLoadingMore, hasMore, getMessages]);

  const loadFutureMessages = useCallback(() => {
    if (hasMoreFuture && !isLoading && !isLoadingMore) {
      getFutureMessages();
    }
  }, [isLoading, isLoadingMore, hasMoreFuture, getFutureMessages]);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  useScroll({
    element: scrollContainerRef.current,
    reversedY: true,
    onTopReached: () => {
      loadMoreMessages();
    },
    onBottomReached: () => {
      loadFutureMessages();
    },
  });

  const hasMessages = useMemo(
    () => Array.isArray(messages) && messages.length > 0,
    [messages]
  );

  const isLastMsgFromModel = useMemo(() => {
    return messages?.length
      ? messages[0]?.sender?.profile_type === "model"
      : false;
  }, [messages]);

  const scrollToBottom = useCallback(async () => {
    const scrollContainerEl = scrollContainerRef.current;
    const unreadMessages = messages?.filter(message => message.scroll_to);
    const hasUnread = unreadMessages?.length || 0;

    if (
      unreadMessages &&
      hasUnread &&
      scrollContainerRef &&
      scrollContainerEl
    ) {
      const firstUnreadMessage =
        scrollContainerRef?.current?.getElementsByClassName(
          `message-${
            unreadMessages[isLastMsgFromModel ? 0 : unreadMessages?.length - 1]
              ?.id
          }`
        )?.[0] as HTMLElement;

      scrollContainerEl.scrollTop = firstUnreadMessage?.offsetTop - 20;
    } else if (scrollContainerRef && scrollContainerEl) {
      scrollContainerEl.scrollTop = scrollContainerEl.scrollHeight;
    }
  }, [scrollContainerRef, messages, isLastMsgFromModel]);

  const scrollToStartDate = useCallback(
    async startAtDate => {
      const scrollContainerEl = scrollContainerRef.current;
      const target = messages?.find(
        message => message.created_at === startAtDate
      );

      if (scrollContainerRef && scrollContainerEl && target) {
        const firstUnreadMessage =
          scrollContainerRef?.current?.getElementsByClassName(
            `message-${target.id}`
          )?.[0] as HTMLElement;

        scrollContainerEl.scrollTop = firstUnreadMessage?.offsetTop - 20;
      } else if (scrollContainerRef && scrollContainerEl) {
        scrollContainerEl.scrollTop = scrollContainerEl.scrollHeight;
      }
    },
    [scrollContainerRef, messages, isLastMsgFromModel]
  );

  useEffect(() => {
    if (previousConversation?.id === conversation?.id && !isLoading) {
      setTimeout(() => {
        scrollToBottom();
      }, 500);
    }
  }, [previousConversation, conversation, isLoading]);

  useEffect(() => {
    if (
      !previousMessage ||
      (previousMessage?.trim() !== "" && message.trim() === "")
    ) {
      scrollToBottom();
    }
  }, [previousMessage, message]);

  useEffect(() => {
    if (
      previousPendingAttachments &&
      previousPendingAttachments.length > attachmentsPendingUpload.length
    ) {
      setTimeout(() => {
        scrollToBottom();
      }, 1000);
    }
  }, [previousPendingAttachments, attachmentsPendingUpload]);

  useEffect(() => {
    if (initAtMessageStartDate) {
      scrollToStartDate(initAtMessageStartDate);
    }
  }, [messages?.length, initAtMessageStartDate]);

  useEffect(() => {
    setIsRefreshingMessages(isActiveConversationMessagesRefreshing);
  }, [isActiveConversationMessagesRefreshing]);

  const isLoadingMessages = useMemo(
    () => (isLoading || isLoadingMore) && !isRefreshingMessages,
    [isLoading, isLoadingMore, isRefreshingMessages]
  );

  const messageParticipantNames = useMemo(() => {
    return conversation?.participants
      .slice(1)
      ?.map(participant => {
        return participant.username;
      })
      .join(", ");
  }, [conversation, selectedContacts, isBulkMessageView, contacts]);

  const messageParticipantUserName = useMemo(
    () =>
      conversation?.participants
        ?.map(participant => participant.username)
        .join(", "),
    [conversation]
  );

  const messageList = useMemo(
    () =>
      messages?.map(message => (
        <TailwindBox
          key={message.id}
          className={[`message-${message.id}`]}
          padding={["pl-4", "pr-4"]}>
          <MessageCenterMessage
            key={message.id}
            message={message}
            userName={userName}
            highlightedWord={highlightedWord}
          />
        </TailwindBox>
      )),
    [messages, message, userName, highlightedWord]
  );

  const pendingMessages = useMemo(() => {
    return sendMessageQueue.filter(
      message => message.conversation_id === activeConversationId
    );
  }, [sendMessageQueue, activeConversationId]);

  const getPendingAttachments = messageId => {
    const results: Array<MessageCenterMessageAttachmentDetails> = [];
    attachmentsPendingUpload.filter(attachment => {
      if (attachment.message_id === messageId) {
        results.push(attachment.attachment_data);
      }
    });
    return results;
  };

  const pendingMessageList = useMemo(() => {
    const messagesReversed = pendingMessages.reverse();
    return messagesReversed?.map(message => (
      <TailwindBox
        key={message.message_id}
        className={[`message-${message.message_id}`]}
        padding={["pl-4", "pr-4"]}>
        <MessageCenterMessage
          key={message.message_id}
          message={
            {
              id: message.message_id,
              sender: {
                id: profile.id,
                top_admirer_rank: 0,
                profile_picture: profile.profile_image,
                username: profile.username,
                email: profile.email,
                first_name: profile.first_name,
                last_name: profile.last_name,
                is_bounty_member: false,
                is_fanclub_member: false,
                is_recent_visitor: false,
                is_top_admirer: false,
                is_top_spender: false,
                is_referral_member: false,
                can_receive_bulk_message: false,
                can_receive_message: false,
                profile_type: "model",
              },
              data: {
                message: (message.request_data as MessageQueueChunk).data
                  ?.message,
              },
              created_at: new Date().toISOString(),
              status: "PENDING",
              attachments_data: getPendingAttachments(message.message_id),
            } as MessageCenterMessageDetails
          }
          userName={userName}
          onCancelProgressClicked={() => {
            cancelSendMessage(message.message_id);
          }}
        />
      </TailwindBox>
    ));
  }, [pendingMessages, attachmentsPendingUpload]);

  return (
    <TailwindFlex
      overflow={["overflow-hidden"]}
      flexWrap={"flex-wrap"}
      padding={["pt-4", "pb-4"]}
      alignItems={"items-center"}
      flexGrow={"flex-grow"}
      justifyContent={"justify-center"}>
      {isLoadingMessages && (
        <TailwindFlex
          height={"h-full"}
          alignItems={"items-center"}
          justifyContent={"justify-center"}>
          <LoadingResourceSpinner
            name={intl.formatMessage({
              id: "verbiage.messages",
              defaultMessage: "Messages",
            })}
          />
        </TailwindFlex>
      )}
      <TailwindFlex
        flexDirection={"flex-col-reverse"}
        height={"h-full"}
        overflow={[
          isLoadingMessages ? "overflow-y-hidden" : "overflow-y-auto",
          "overflow-x-hidden",
        ]}
        ref={scrollContainerRef as React.RefObject<HTMLDivElement>}
        alignItems={
          isLoading || !hasMessages || !conversation
            ? "items-center"
            : undefined
        }
        justifyContent={
          isLoading || !conversation
            ? "justify-center"
            : !hasMessages
            ? "justify-end"
            : undefined
        }
        padding={hasMore && !conversation ? ["p-12"] : ["pr-1.5"]}>
        {(!isLoading || isRefreshingMessages) && (
          <>
            {!hasMessages && !conversation && (
              <TailwindTranslatedText
                textColor='text-main-color'
                descriptor={
                  hasConversations
                    ? {
                        id: "message-center.select-a-conversation",
                        defaultMessage: "Select a conversation",
                      }
                    : {
                        id: "message-center.start-a-conversation",
                        defaultMessage: "Start a conversation",
                      }
                }
              />
            )}
            {pendingMessages.length ? pendingMessageList : null}
            {hasMessages ? messageList : null}
          </>
        )}
        {!hasMore && conversation && !isLoading && !isLoadingMore && (
          <TailwindFlex
            flexDirection='flex-col'
            flexGrow='flex-grow'
            textAlign={"text-center"}
            margin={["mx-auto", "mb-4", "mt-1"]}>
            {conversation.participants.length > 1 ? (
              <>
                <TailwindFlex justifyContent='justify-center'>
                  <Avatar size={"small"} icon={"group"} />
                </TailwindFlex>
                <TailwindFlex
                  alignItems='items-center'
                  justifyContent='justify-center'
                  textColor='text-toggle-disabled'
                  fontWeight='font-bold'
                  margin={["mt-2"]}>
                  {intl.formatMessage({
                    id: "message-center.bulk-message",
                    defaultMessage: "Bulk Message",
                  })}
                </TailwindFlex>
                <TailwindFlex
                  textColor='text-main-color'
                  fontWeight='font-bold'
                  display='inline'
                  alignItems='items-center'
                  justifyContent='justify-center'>
                  {conversation?.participants?.[0]?.username + " + "}
                  <TailwindTooltip
                    className='MessagesReader__tooltip'
                    place={isMobileDevice ? "top" : "right"}
                    content={
                      <TailwindText fontWeight='font-bold'>
                        {messageParticipantNames}
                      </TailwindText>
                    }>
                    <TailwindBox display='inline' cursor='cursor-pointer'>
                      <TailwindTranslatedText
                        fontWeight='font-bold'
                        textColor='text-modal-button-color'
                        descriptor={{
                          id: "message-center.bulk-count",
                          defaultMessage: "{count} others",
                        }}
                        values={{
                          count: conversation?.participants?.length - 1,
                        }}
                      />
                    </TailwindBox>
                  </TailwindTooltip>
                </TailwindFlex>
              </>
            ) : (
              <>
                <TailwindBox className={["MessagesReader__startConversation"]}>
                  {!hasMore && conversation && !isLoadingMessages && (
                    <>
                      {messageParticipantUserName && (
                        <TailwindFlex justifyContent='justify-center'>
                          <Avatar
                            size={"large"}
                            username={messageParticipantUserName}
                            photoURL={
                              conversation?.participants[0]?.profile_picture
                            }
                            randomBackgroundColor={true}
                          />
                        </TailwindFlex>
                      )}
                      <TailwindFlex
                        textAlign={"text-center"}
                        margin={["mt-2"]}
                        fontWeight={"font-light"}
                        justifyContent={"justify-center"}
                        alignItems={"items-center"}
                        className={["MessagesReader__model-details"]}>
                        {messageParticipantUserName}
                      </TailwindFlex>
                      <TailwindBox
                        textAlign='text-center'
                        margin={["mt-2"]}
                        className={["MessagesReader__start-conversation"]}
                        borderColor={"border-primary-bg-color"}
                        borderStyle={"border-solid"}
                        style={{
                          borderWidth: "2px 0",
                          padding: "10px 0",
                          fontSize: "14px",
                        }}>
                        {intl.formatMessage({
                          id: "message-center.start-of-conversation-with",
                          defaultMessage: "Start of conversation with",
                        })}{" "}
                        <strong>{messageParticipantUserName}</strong>
                      </TailwindBox>
                    </>
                  )}
                </TailwindBox>
              </>
            )}
          </TailwindFlex>
        )}
      </TailwindFlex>
    </TailwindFlex>
  );
};
export default injectIntl(
  inject(
    "messageStore",
    "layoutStore",
    "profileStore"
  )(observer(MessageCenterMessageList))
);
