import React, { useMemo } from "react";
import "./styles.scss";
import TailwindFlex from "../flex";
import TailwindCard, { TailwindCardProps } from "../card";

export type MediaProps = TailwindCardProps & {
  src: string;
  bgPosition?: string,
  className?: string;
  onMouseEnter?: (event: React.MouseEvent<any>) => void;
  onMouseLeave?: (event: React.MouseEvent<any>) => void;
};

const Media: React.ComponentType<MediaProps> = ({
   src,
   className,
   children,
   bgPosition,
   ...props
  }) => {
  const contentStyle = useMemo(() => {
    const style: React.CSSProperties = {
      backgroundImage: `url('${src}'), url('images/broken-image-placeholder.jpg')`,
    };

    return style;
  }, [src]);

  const setBgPosition = useMemo(() => {
    return bgPosition ? bgPosition : "bg-center";
  }, [bgPosition]);

  return (
    <TailwindCard {...props} className={className} width={"w-full"}>
      <TailwindFlex
        style={contentStyle}
        backgroundRepeat={"bg-no-repeat"}
        backgroundSize={"bg-cover"}
        position={"relative"}
        height={"h-full"}
        className={[setBgPosition]}
      >
        {React.Children.count(children) > 0 && (
          <TailwindFlex
            position={"absolute"}
            inset={["inset-0"]}
            flexDirection={"flex-col"}>
            {children}
          </TailwindFlex>
        )}
      </TailwindFlex>
    </TailwindCard>
  );
};

export default Media;