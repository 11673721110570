import React from "react";
import tw, {
  TAlignItems,
  TBackgroundColor,
  TBackgroundOpacity,
  TBorderRadius,
  TClasses,
  TCursor,
  TDisplay,
  TFlexDirection,
  TFontFamily,
  TFontSize,
  TFontSmoothing,
  TFontStyle,
  TFontVariantNumeric,
  TFontWeight,
  THeight,
  TInset,
  TJustifyContent,
  TLetterSpacing,
  TLineHeight,
  TMargin,
  TOverflow,
  TPadding,
  TPosition,
  TPseudoClasses,
  TTextAlign,
  TTextColor,
  TTextDecoration,
  TTextOpacity,
  TTextOverflow,
  TTextTransform,
  TUserSelect,
  TVerticalAlign,
  TWhitespace,
  TWidth,
  TWordBreak,
  TZIndex,
} from "../../../styles/tailwind-classnames";
import classnames from "classnames";

export type TailwindTextProps = {
  textColor?: TTextColor;
  textOpacity?: TTextOpacity;
  fontFamily?: TFontFamily;
  fontSize?: TFontSize;
  fontSmoothing?: TFontSmoothing;
  fontStyle?: TFontStyle;
  fontWeight?: TFontWeight;
  fontVariantNumeric?: TFontVariantNumeric;
  letterSpacing?: TLetterSpacing;
  lineHeight?: TLineHeight;
  textAlign?: TTextAlign;
  textDecoration?: TTextDecoration;
  textTransform?: TTextTransform;
  textOverflow?: TTextOverflow;
  verticalAlign?: TVerticalAlign;
  whiteSpace?: TWhitespace;
  wordBreak?: TWordBreak;
  position?: TPosition;
  inset?: TInset[];
  width?: TWidth;
  height?: THeight;
  margin?: (TMargin | undefined)[];
  padding?: (TPadding | undefined)[];
  className?: TClasses | string;
  zIndex?: TZIndex;
  display?: TDisplay;
  flexDirection?: TFlexDirection;
  alignItems?: TAlignItems;
  justifyContent?: TJustifyContent;
  userSelect?: TUserSelect;
  cursor?: TCursor;
  overflow?: TOverflow;
  backgroundColor?: TBackgroundColor;
  backgroundOpacity?: TBackgroundOpacity;
  borderRadius?: TBorderRadius;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  as?: string;
  htmlFor?: string;
  pseudoClasses?: (TPseudoClasses | undefined)[];
  style?: React.CSSProperties;
  dangerouslySetInnerHTML?: {
    __html: string;
  };
  children?: any;
};

const TailwindText = React.forwardRef<HTMLElement, TailwindTextProps>(
  (
    {
      textColor,
      textOpacity = "text-opacity-100",
      fontFamily,
      fontSize = "text-base",
      fontSmoothing = "antialiased",
      fontStyle = "non-italic",
      fontWeight = "font-normal",
      fontVariantNumeric,
      letterSpacing = "tracking-normal",
      lineHeight,
      textAlign = "text-left",
      textDecoration = "no-underline",
      textTransform = "normal-case",
      textOverflow,
      verticalAlign = "align-baseline",
      whiteSpace,
      backgroundOpacity,
      wordBreak = "break-normal",
      position,
      inset = [],
      margin = [],
      padding = [],
      className,
      zIndex,
      display,
      flexDirection,
      alignItems,
      justifyContent,
      userSelect,
      cursor,
      overflow,
      backgroundColor,
      borderRadius,
      onClick = () => {},
      as = "span",
      width,
      height,
      htmlFor,
      pseudoClasses = [],
      style,
      dangerouslySetInnerHTML,
      children,
    },
    ref
  ) => {
    const props: any = {
      ref,
      className: classnames(
        className && className,
        tw(
          textColor,
          textOpacity,
          fontFamily,
          fontSize,
          fontSmoothing,
          fontStyle,
          fontWeight,
          fontVariantNumeric,
          letterSpacing,
          lineHeight,
          textAlign,
          textDecoration,
          textTransform,
          textOverflow,
          verticalAlign,
          whiteSpace,
          wordBreak,
          position,
          backgroundOpacity,
          zIndex,
          display,
          flexDirection,
          alignItems,
          justifyContent,
          userSelect,
          cursor,
          overflow,
          backgroundColor,
          borderRadius,
          width,
          height,
          ...inset,
          ...margin,
          ...padding,
          ...pseudoClasses
        )
      ),
      htmlFor,
      onClick,
      style,
    };

    if (dangerouslySetInnerHTML) {
      if (props) {
        props.dangerouslySetInnerHTML = dangerouslySetInnerHTML;
      }
      return React.createElement(as, props);
    }

    return React.createElement(as, props, children);
  }
);

export default TailwindText;
