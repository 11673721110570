import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import StorybookFlowClickUp from "./flow-clickup";
import StorybookFlowGit from "./flow-git";
import StorybookFlowDeployments from "./flow-deployments";

type Props = {};

const StorybookArchitecture: React.ComponentType<Props> = ({}) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/git`}>
        <StorybookFlowGit />
      </Route>
      <Route exact path={`${path}/jira`}>
        <StorybookFlowClickUp />
      </Route>
      <Route exact path={`${path}/deployments`}>
        <StorybookFlowDeployments />
      </Route>
    </Switch>
  );
};

export default StorybookArchitecture;
