import React, { useMemo } from "react";
import "./styles.scss";
import TailwindBox from "library/components/_tailwind/box";
import TailwindFlex from "library/components/_tailwind/flex";

export type MemberGalleryTooltipProps = {
  images: string[];
  desc: string;
  imageCountToShow?: number;
};

const MemberGalleryTooltip: React.FunctionComponent<MemberGalleryTooltipProps> =
  ({ images, desc, imageCountToShow = 5 }) => {
    const filteredImages = useMemo(
      () => images.filter((_image, index) => index < imageCountToShow),
      [images, imageCountToShow]
    );

    return (
      <TailwindBox width={"w-auto"} className={["MemberGallery"]}>
        <TailwindFlex width={"w-auto"}>
          {filteredImages.map((image, index) => (
            <img
              key={`member-image-${index}`}
              className={"m-1"}
              src={image}
              alt=''
            />
          ))}
        </TailwindFlex>
        <div className={"MemberGallery__about"}>
          <span>{desc}</span>
        </div>
      </TailwindBox>
    );
  };

export default MemberGalleryTooltip;
