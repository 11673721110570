import React from "react";
import "./styles.scss";
import classnames from "classnames";

type SharedMediaIconAltProps = {
  className?: string;
};

const SharedMediaIconAlt: React.ComponentType<SharedMediaIconAltProps> = ({
  className,
}) => (
  <span className={classnames("SharedMediaIconAlt", className && className)}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      version='1.2'
      overflow='visible'
      preserveAspectRatio='none'
      viewBox='0 0 18.000005496458925 17'>
      <g>
        <path
          d='M13.2173,0h-10.99932c-1.25496,0 -2.21463,0.93925 -2.21463,2.1675v10.115c0,1.22825 0.95967,2.1675 2.21463,2.1675h10.99932c1.25496,0 2.21463,-0.93925 2.21463,-2.1675v-10.115c0,-1.22825 -1.03349,-2.1675 -2.21463,-2.1675zM5.09364,10.18725v-5.9245l5.16746,2.96225z'
          vectorEffect='non-scaling-stroke'
        />
        <path
          d='M13.2173,0h-10.99932c-1.25496,0 -2.21463,0.93925 -2.21463,2.1675v10.115c0,1.22825 0.95967,2.1675 2.21463,2.1675h10.99932c1.25496,0 2.21463,-0.93925 2.21463,-2.1675v-10.115c0,-1.22825 -1.03349,-2.1675 -2.21463,-2.1675zM5.09364,10.18725v-5.9245l5.16746,2.96225z'
          transform='translate(-0.003349999999954889, 0) rotate(0)'
          vectorEffect='non-scaling-stroke'
        />
        <path
          d='M18.075315317645213 4.878260869565269 C18.075315317645213 4.878260869565269 18.075315317645213 14.782608695652332 18.075315317645213 14.782608695652332 C18.075315317645213 16.039130434782777 17.09623573793943 17.00000000000018 15.815900902939562 17.00000000000018 C15.815900902939562 17.00000000000018 5.422594595293565 17.00000000000018 5.422594595293565 17.00000000000018 C4.36820120176426 17.00000000000018 3.389121622058478 16.260869565217565 3.2384939944114346 15.2260869565219 C3.389121622058478 15.300000000000164 3.6150630635290435 15.300000000000164 3.7656906911760877 15.300000000000164 C3.7656906911760877 15.300000000000164 14.158996998822087 15.300000000000164 14.158996998822087 15.300000000000164 C15.439331833821955 15.300000000000164 16.418411413527735 14.339130434782764 16.418411413527735 13.082608695652315 C16.418411413527735 13.082608695652315 16.418411413527735 3.4739130434782997 16.418411413527735 3.4739130434782997 C16.418411413527735 3.178260869565253 16.343097599704212 2.882608695652207 16.26778378588069 2.6608695652174217 C17.322177179409998 2.8826086956522046 18.075315317645213 3.7695652173913436 18.075315317645213 4.878260869565269 Z'
          vectorEffect='non-scaling-stroke'
        />
        <path
          d='M18.00000549645881 4.878260869565111 C18.00000549645881 4.878260869565111 18.00000549645881 14.782608695652158 18.00000549645881 14.782608695652158 C18.00000549645881 16.039130434782606 17.02092591675307 17 15.740591081753223 17 C15.740591081753223 17 5.3472847741072655 17 5.3472847741072655 17 C4.292891380577885 17 3.313811800872145 16.260869565217405 3.1631841732250905 15.226086956521726 C3.313811800872145 15.299999999999983 3.5397532423427265 15.299999999999983 3.690380869989781 15.299999999999983 C3.690380869989781 15.299999999999983 14.083687177635738 15.299999999999983 14.083687177635738 15.299999999999983 C15.364022012635587 15.299999999999983 16.343101592341327 14.33913043478259 16.343101592341327 13.08260869565214 C16.343101592341327 13.08260869565214 16.343101592341327 3.4739130434781202 16.343101592341327 3.4739130434781202 C16.343101592341327 3.1782608695650936 16.267787778517913 2.8826086956520385 16.192473964694386 2.66086956521724 C17.246867358223653 2.8826086956520385 18.00000549645881 3.7695652173911753 18.00000549645881 4.878260869565111 Z'
          vectorEffect='non-scaling-stroke'
        />
      </g>
    </svg>
  </span>
);

export default SharedMediaIconAlt;
