import React, { useCallback } from "react";
import TailwindFlex, {
  TailwindFlexProps,
} from "library/components/_tailwind/flex";
import TextareaAutosize from "react-textarea-autosize";
import classnames from "classnames";
import tw, { TFontSize } from "library/styles/tailwind-classnames";
import { inject, observer } from "mobx-react";
import MessageStore from "./stores/messages/MessageStore";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";

type Props = {
  messageStore?: MessageStore;
  onChange: (value: string) => void;
  placeholder: string;
  value: string;
  disabled?: boolean;
  enterPressCallback?: (...args: any[]) => void;
  inputRef: React.RefObject<HTMLTextAreaElement>;
  loading?: boolean;
  boxProps?: TailwindFlexProps;
  maxRows?: number;
  maxLength?: number;
};

const MessageCenterInput: React.FunctionComponent<Props> = ({
  messageStore,
  onChange,
  placeholder,
  value,
  disabled = false,
  enterPressCallback = undefined,
  inputRef: ref,
  loading = false,
  boxProps,
  maxRows = 4,
  maxLength = 2000,
}) => {
  const { messageFontSize, setMessageInputFocused } = messageStore!;

  const onChange_ = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const sanitizedHtml = event.target.value;
      if (!disabled) {
        onChange(sanitizedHtml);
      }
    },
    [onChange, disabled]
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.keyCode === 13) {
        if (!event.shiftKey) {
          event.preventDefault();
          event.stopPropagation();

          if (enterPressCallback) {
            enterPressCallback();
          }
        } else {
          event.preventDefault();
          const start = event.currentTarget.selectionStart;
          const end = event.currentTarget.selectionEnd;
          onChange(`${value.substring(0, start)}\n${value.substring(end)}`);
          event.currentTarget.selectionStart =
            event.currentTarget.selectionEnd = start + 1;
          event.currentTarget.scrollTop = event.currentTarget.scrollHeight;
        }

        return false;
      }
      return true;
    },
    [enterPressCallback, value]
  );

  return (
    <TailwindFlex
      flexDirection={"flex-col"}
      position={"relative"}
      padding={["p-4", "pb-0"]}
      {...boxProps}>
      <TailwindFlex
        alignItems={"items-center"}
        space={"space-x-2"}
        textColor='text-main-color'
        padding={["pl-2", "pr-3", "py-4"]}
        backgroundColor='bg-primary-bg-color'
        className={["rounded-md"]}>
        {loading ? (
          <TailwindIcon
            width='w-0.5'
            height='h-0.5'
            fontSize='text-xs'
            name={CustomIconName.spinner}
          />
        ) : (
          <TextareaAutosize
            // @ts-ignore
            ref={ref as React.RefObject<HTMLTextAreaElement>}
            className={classnames(
              "focus:outline-none",
              "pretty-scroll",
              tw(
                "w-full",
                "min-h-8",
                "text-lg",
                "bg-transparent",
                "resize-none",
                messageFontSize as TFontSize
              )
            )}
            value={value}
            onChange={onChange_}
            onKeyDown={onKeyDown}
            onFocus={() => {
              setMessageInputFocused(true);
            }}
            onBlur={() => {
              setMessageInputFocused(false);
            }}
            disabled={disabled}
            placeholder={placeholder}
            rows={2}
            maxRows={maxRows}
            maxLength={maxLength}
          />
        )}
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default inject("messageStore")(observer(MessageCenterInput));
