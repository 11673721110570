import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import EarningsTableHead from "./earnings-table-head";
import EarningsTableBody from "./earnings-table-body";
import TailwindButton from "library/components/_tailwind/button";

type Props = {
  detailsVisible: boolean;
  showDetails?: any;
};

const EarningsTableContainer: React.ComponentType<Props> = ({
  showDetails,
  detailsVisible,
}) => {
  return (
    <TailwindFlex flexDirection={"flex-row"} height={"h-auto"} width={"w-full"}>
      <TailwindFlex
        width={"w-full"}
        overflow={["overflow-x-auto", "overflow-y-hidden"]}
        flexDirection={"flex-col"}>
        <TailwindFlex
          flexDirection={"flex-col"}
          height={"h-full"}
          width={"w-full"}
          textColor='text-main-color'
          minWidth={"min-w-160"}
          borderRadius={"rounded-xl"}
          backgroundColor={"bg-primary-bg-color"}
          padding={["p-0"]}>
          <EarningsTableHead />
          <EarningsTableBody />
        </TailwindFlex>
        <TailwindFlex padding={["pt-2", "pr-5"]} justifyContent={"justify-end"}>
          <TailwindButton
            rounded={false}
            justifyContent={"justify-center"}
            fullWidth={false}
            onClick={showDetails}
            padding={["p-0"]}
            textProps={{ textAlign: "text-right" }}>
            {detailsVisible ? "Hide" : "Show"} details
          </TailwindButton>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default EarningsTableContainer;
