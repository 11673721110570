import React from "react";
import { inject, observer } from "mobx-react";
import BroadcastStore from "./_stores/broadcast/BroadcastStore";
import TailwindBox from "library/components/_tailwind/box";
import TailwindText from "library/components/_tailwind/text";

type Props = {
  broadcastStore?: BroadcastStore;
};

const BroadcastingWithGuestsIndicatorComponent = ({
  broadcastStore,
}: Props) => {
  const { isBroadcastingAlone, guestProfilesInCurrentSession } =
    broadcastStore!;

  if (isBroadcastingAlone || guestProfilesInCurrentSession.length === 0) {
    return null;
  }

  return (
    <TailwindBox
      margin={["mb-2"]}
      borderRadius={"rounded-xl"}
      padding={["p-4"]}
      backgroundColor='bg-primary-bg-color'>
      <TailwindText display="block" overflow="overflow-hidden" whiteSpace="whitespace-nowrap" textOverflow='overflow-ellipsis'>
        {`Broadcasting with: `}
        <b>{guestProfilesInCurrentSession.map(item => item.name).join(",")}</b>
      </TailwindText>
    </TailwindBox>
  );
};

export const BroadcastingWithGuestsIndicator = inject("broadcastStore")(
  observer(BroadcastingWithGuestsIndicatorComponent)
);
