import Media from "library/components/_tailwind/media";
import TailwindBox from "library/components/_tailwind/box";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindText from "library/components/_tailwind/text";
import TailwindButton from "library/components/_tailwind/button";
import React, { useMemo } from "react";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import { LeaderBoardModel } from "./store/types";
import moment from "moment";
import { LeaderBoardContests } from "./store/enums";

type Props = {
  contestant: LeaderBoardModel;
  contestType: LeaderBoardContests;
};

const ModelMedia: React.ComponentType<Props> = ({
  contestant,
  contestType,
}) => {
  const gotoModelPage = () => {
    window.open(contestant.profileUrl, "_blank");
  };

  const displayDate = useMemo(() => {
    switch (contestType) {
      case LeaderBoardContests.day: {
        return moment(contestant.displayDate).format("MMM DD YYYY");
      }
      case LeaderBoardContests.month: {
        return moment(contestant.displayDate).format("MMMM YYYY");
      }
      case LeaderBoardContests.year: {
        return moment(contestant.displayDate).format("YYYY");
      }
    }
  }, [contestType]);
  return (
    <Media
      width={"w-full"}
      height={"h-40"}
      display={"flex"}
      position={"relative"}
      src={contestant.image}
      borderRadius={"rounded"}
      bgPosition={"bg-top"}
      overflow={"overflow-hidden"}>
      <TailwindFlex
        flexDirection={"flex-col"}
        height={"h-full"}
        cursor={"cursor-pointer"}
        onClick={gotoModelPage}>
        <TailwindFlex
          justifyContent={"justify-end"}
          alignItems={"items-center"}
          padding={["px-2"]}
          height={"h-1/3"}
          width={"w-full"}>
          {contestant.displayDate && (
            <TailwindButton
              size={"sm"}
              padding={["px-4"]}
              fullWidth={false}
              textColor={"text-white"}
              backgroundColor={"bg-black"}
              backgroundOpacity={"bg-opacity-50"}
              rounded={true}>
              {displayDate}
            </TailwindButton>
          )}
          {contestant.rank && contestant.tokensToNext && (
            <TailwindButton
              size={"sm"}
              textColor={"text-white"}
              backgroundColor={"bg-black"}
              backgroundOpacity={"bg-opacity-50"}
              rounded={true}
              fullWidth={false}>
              <TailwindText display={"inline-flex"}>
                {contestant.tokensToNext}
                <TailwindIcon
                  margin={["mt-0.5", "mr-1"]}
                  fontSize={"text-xs"}
                  name={CustomIconName.token}
                />{" "}
                more to be #{contestant.rank - 1}
              </TailwindText>
            </TailwindButton>
          )}
        </TailwindFlex>
        <TailwindBox
          display={"flex"}
          height={"h-1/3"}
          width={"w-full"}></TailwindBox>
        <TailwindFlex
          justifyContent={contestant.rank ? "justify-between" : "justify-start"}
          alignItems={"items-center"}
          padding={["px-2", "pt-4"]}
          margin={["mb-1.5"]}
          height={"h-1/3"}
          width={"w-full"}>
          <TailwindBox
            backgroundColor={"bg-black"}
            backgroundOpacity={"bg-opacity-50"}
            padding={["px-3", "py-1"]}
            borderRadius={"rounded-3xl"}>
            <TailwindText margin={["ml-1", "mb-1"]} textColor={"text-white"}>
              {contestant.username}
            </TailwindText>
          </TailwindBox>

          {contestant && contestant.rank && (
            <TailwindButton
              size={"sm"}
              padding={["px-4"]}
              fullWidth={false}
              textColor={"text-white"}
              backgroundColor={"bg-black"}
              backgroundOpacity={"bg-opacity-50"}
              rounded={true}>
              <TailwindFlex>
                <TailwindIcon
                  textColor={"text-white"}
                  name={CustomIconName.crown}></TailwindIcon>
                <TailwindText margin={["ml-1"]} textColor={"text-white"}>
                  {contestant.rank}
                </TailwindText>
              </TailwindFlex>
            </TailwindButton>
          )}
        </TailwindFlex>
      </TailwindFlex>
    </Media>
  );
};

export default ModelMedia;
