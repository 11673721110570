import React from "react";
import "./styles.scss";
import classnames from "classnames";

type DashboardIconProps = {
  className?: string;
};

const DashboardIcon: React.ComponentType<DashboardIconProps> = ({
  className,
}) => (
  <span className={classnames("DashboardIcon", className && className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 602 602"
      xmlSpace="preserve"
      y="0px"
      x="0px"
      id="Layer_1_1606361739359"
    >
      <g transform="translate(1, 1)">
        <path
          d="M275.8,525.3H81.6c-5.5,0-10-4.5-10-10V99.5c0-5.5,4.5-10,10-10h194.2c5.5,0,10,4.5,10,10v415.8  C285.8,520.8,281.3,525.3,275.8,525.3z"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M514.4,287.1H336.8c-5.5,0-10-4.5-10-10V99.5c0-5.5,4.5-10,10-10h177.6c5.5,0,10,4.5,10,10v177.6  C524.4,282.6,519.9,287.1,514.4,287.1z"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M514.4,405.9H336.8c-5.5,0-10-4.5-10-10v-58c0-5.5,4.5-10,10-10h177.6c5.5,0,10,4.5,10,10v58  C524.4,401.4,519.9,405.9,514.4,405.9z"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M514.4,525.3H336.8c-5.5,0-10-4.5-10-10v-58c0-5.5,4.5-10,10-10h177.6c5.5,0,10,4.5,10,10v58  C524.4,520.8,519.9,525.3,514.4,525.3z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  </span>
);

export default DashboardIcon;
