import React from "react";
import "./styles.scss";

type MessagesIconProps = {
  className?: string;
};

const MessagesIcon: React.ComponentType<MessagesIconProps> = ({
  className,
}) => (
  <span className={`${className} camsIcon`}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
      <path d='M50,2.15A47.45,47.45,0,0,0,2.61,49.55a46.87,46.87,0,0,0,9.14,28l-6.27,13a3.88,3.88,0,0,0,4.65,5.4L26.6,90.77A47.4,47.4,0,1,0,50,2.15Zm0,89.79a42.4,42.4,0,0,1-21.8-6l-1-.58-16.14,5,6.5-13.43-1-1.23a41.92,41.92,0,0,1-9-26.13A42.39,42.39,0,1,1,50,91.94Z' />
      <path d='M27.53,42.64a6.91,6.91,0,1,0,6.91,6.91A6.9,6.9,0,0,0,27.53,42.64Z' />
      <path d='M50.7,42.64a6.91,6.91,0,1,0,6.91,6.91A6.91,6.91,0,0,0,50.7,42.64Z' />
      <path d='M73.87,42.64a6.91,6.91,0,1,0,6.91,6.91A6.91,6.91,0,0,0,73.87,42.64Z' />
    </svg>
  </span>
);

export default MessagesIcon;
