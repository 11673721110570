import { inject, observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import { NANOPLAYER_ELEMENT_ID } from "common/broadcast/_stores/broadcast-stream/consts";
import BroadcastStreamTypeSwitcher from "./broadcast-stream-type-switcher";

interface Props {
  broadcastStore?: BroadcastStore;
  broadcastStreamStore?: BroadcastStreamStore;
}

const BroadcastWebRTCPreview: React.FunctionComponent<Props> = ({
  broadcastStreamStore,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { mediaStream } = broadcastStreamStore!;

  useEffect(() => {
    if (videoRef?.current) {
      videoRef.current.setAttribute("webkit-playsinline", "");
      videoRef.current.setAttribute("playsinline", "");
      videoRef.current.setAttribute("playsInline", "");
    }
  }, [videoRef]);

  useEffect(() => {
    if (videoRef?.current && !!mediaStream) {
      videoRef.current.srcObject = mediaStream;
    }
  }, [mediaStream, videoRef]);

  return (
    <div className='BroadcastPreview__videoContainer' data-testid="broadcast-preview">
      <BroadcastStreamTypeSwitcher />
      <video
        ref={videoRef}
        className={`BroadcastPreview__video BroadcastPreview__video--started`}
        autoPlay={true}
        muted={true}
        id={NANOPLAYER_ELEMENT_ID}
      />
    </div>
  );
};

export default inject(
  "broadcastStore",
  "broadcastStreamStore",
  "broadcastStreamService"
)(observer(BroadcastWebRTCPreview));
