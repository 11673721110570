import { IChatNotification } from "common/broadcast/_stores/chat/interfaces";
import { ChatState, IChatTab } from "common/broadcast/_stores/chat/enums";
import { IShowType } from "../broadcast/enums";

export const CHAT_MEMBER_COLOR_MAPPING = {
  BOUNTY_COLOR_DEFAULT: "#FFB1C6",
  FAN_COLOR_DEFAULT: "#009900",
  FAN_COLOR_ICON: "#d8d13b",
  PREM_COLOR_DEFAULT: "#FF007F",
  PAID_COLOR_DEFAULT: "#00CCCC",
  DEFAULT_COLOR_DEFAULT: "#666666",
  SPENT_TIER_ONE: "#0000CC",
  SPENT_TIER_TWO: "#AD33FF",
  SPENT_TIER_THREE: "#5C0099",
  TOP_ADMIRER: "#EA3323",
  HIGH_VALUE: "#FFA500",
};

export const CHAT_DEFAULT_NOTIFICATIONS: IChatNotification[] = [
  {
    id: "1",
    created: new Date(),
    fixedContentKey: "notificationMessage.rememberStartBroadcast",
    isMessageOrNotification: "notification",
  },
];

export const InitialData = {
  chatSocket: undefined,
  ChatState: undefined,
  publicMemberCount: 0,
  isNotesDrawerOpen: "",
  isEditingNotes: "",
  privateMemberCount: 0,
  notifications: CHAT_DEFAULT_NOTIFICATIONS,
  numberOfOnlineGuests: 0,
  numberOfOnlinePublicMembers: 0,
  numberOfOnlinePrivateMembers: 0,
  supportChatMessagesUnread: 0,
  publicChatMessages: [],
  privateChatMessages: [],
  supportChatMessages: [],
  bufferedMessages: [],
  MemberNotes: [],
  publicMemberHash: {},
  privateMemberHash: {},
  voyeurMemberHash: {},
  chatState: ChatState.startingChat,
  publicWhisperHash: {},
  privateWhisperHash: {},
  currentChatLanguage: "en",
  activeChatTab: IChatTab.PUBLIC_OR_PRIVATE,
  gift: undefined,
  isPrivateChatReady: false,
  privateShowRequesterName: "",
  memberJoinedShowInfo: undefined,
  membersBalance: 0,
  membersBalanceHash: {},
  topAdmirers: [],
  topAdmirersHash: {},
  chatFontSize: 16,
  chatGiftImageSize: 64,
  message: "",
  wheelOfFunSpinnerUser: undefined,
  wheelOfFunSpinnerReward: undefined,
  wheelOfFunSpinnerTimestamp: undefined,
  memberSentGiftReactionDisposer: undefined,
  memberJoinedShowReactionDisposer: undefined,
  getRoomConnectionStatusInterval: undefined,
  modelRoomDisconnectedReaction: undefined,
  roomConnectionStatus: {
    public: false,
    private: false,
    support: false,
  },
  shouldShowPrivatememberLeftConfirmation: false,
};

export const CHAT_MEMBER_OUT_OF_FUNDS_MESSAGE = 'ran out of funds'
export const MEMBER_SILENCE_STRING = ` but he is silenced. You should consider unsilencing this member.`
export const SHOW_TYPE_CHAT_TYPE_MAP = {
  [IShowType.FREE]: 'public',
  [IShowType.GROUP]: 'party',
  [IShowType.PRIVATE]: 'private',
  [IShowType.NUDE]: 'nude',
  [IShowType.CURTAIN_DROPPED]: 'tipping',
  [IShowType.TIPPING]: 'tipping'
}
export const MIN_HOURS_TO_SHOW_TIME_REMAINING = 4;

export const SHOW_TYPES_WITHOUT_MEMBER_TIME_LEFT_NOTIFICATION = [IShowType.GROUP];