import cookie from "js-cookie";

export const createFormData = (file: any, additionalFormData: any) => {
  const form = new FormData();
  Object.keys(additionalFormData).forEach(key => {
    const value = additionalFormData[key];
    form.append(key, value);
  });
  form.append("file", file);
  return form;
};

export const getExtension = (filename: string): string => {
  var parts = filename.split(".");
  return parts[parts.length - 1];
};

export const isImage = (filename: string): boolean => {
  const ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "jpg":
    case "jpeg":
    case "gif":
    case "png":
      return true;
  }
  return false;
};

export const isVideo = (filename: string): boolean => {
  const ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "m4v":
    case "avi":
    case "mpg":
    case "mp4":
    case "mov":
      return true;
  }
  return false;
};

export const dataURItoBlob = (dataURI: string) => {
  let byteString;

  if (dataURI.split(",")[0].indexOf("base64") >= 0) {
    byteString = atob(dataURI.split(",")[1]);
  } else {
    byteString = encodeURI(dataURI.split(",")[1]);
  }
  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
};

export const propertySortMethod = (
  a: any,
  b: any,
  property: string,
  descending: boolean = true
) => {
  if (a[property] > b[property]) {
    return descending ? -1 : 1;
  }
  if (a[property] < b[property]) {
    return descending ? 1 : -1;
  }
  return 0;
};

export const handleDebugCookie = (debugCookie?: string) => {
  if (debugCookie) {
    const parsedCookie = debugCookie.split(":");
    if (parsedCookie.length > 1) {
      if (parsedCookie[1] === "delete") {
        cookie.remove(parsedCookie[0]);
      } else {
        console.log("cookie set", parsedCookie[0], parsedCookie[1]);
        cookie.set(parsedCookie[0], parsedCookie[1], {
          sameSite: "none",
          secure: true,
        });
      }
    }
  }
};

export const exponentialBackoffWithJitter = async (
  fn: Function,
  maxAttempts = 5,
  delayMS = 1000
) => {
  let attempt = 1;

  const execute = async () => {
    try {
      return await fn();
    } catch (error) {
      if (attempt >= maxAttempts) {
        throw new Error("Maximum attempts exceeded");
      }

      const delay = Math.floor(Math.random() * delayMS * 2 ** attempt);
      await new Promise(resolve => setTimeout(resolve, delay));

      attempt++;
      return execute();
    }
  };

  return execute();
};
