import React, { useCallback, useMemo } from "react";
import { inject, observer } from "mobx-react";
import { FileRejection } from "react-dropzone";
import MediaManagerPrompt from "./media-manager-prompt";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import {
  MY_PAGE_ACCEPTED_FILE_EXTENSIONS,
  MY_PAGE_ACCEPTED_MAXIMUM_FILES_FOR_CUSTOM_ALBUM,
} from "./store/consts";
import { MyPagePromptMode } from "./store/enums";
import MediaManagerStore from "./store/MediaManagerStore";
import { history } from "library/core/utility";
import { AppCamsModelRouteKey } from "core/stores/route/enums";
import { ModelAlbum, ModelImage, ModelVideo } from "./store/types";
import { NonManagerProfile } from "../../../common/my-page/stores/profile/types";
import MediaManagerAlbum from "./media-manager-album";
import TailwindFlex from "library/components/_tailwind/flex";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import TailwindText from "library/components/_tailwind/text";
import RouteStore from "core/stores/route/RouteStore";

type Props = {
  album: ModelAlbum;
  routeStore?: RouteStore;
  mediaManagerStore?: MediaManagerStore;
  validationStore?: ValidationStore;
  profileStore?: ProfileStore;
};

const MediaManagerMasonryAlbum: React.ComponentType<Props> = ({
  album,
  routeStore,
  mediaManagerStore,
  validationStore,
  profileStore,
}) => {
  const { getSiteRouteURL } = routeStore!;
  const { showNotificationOnRejectedFiles } = validationStore!;
  const { profile } = profileStore!;
  const {
    albums,
    getAlbumTitle,
    getEmptyAlbumMessage,
    setIsPromptOpen,
    uploadModelMediaFile,
    deleteMedia,
  } = mediaManagerStore!;

  const mediaApprovedCount = useMemo(()=>{
    return ((album?.images?.filter((image)=>image['status'] === "APPROVED"))?.length || 0)
     + ((album?.videos?.filter((video)=>video['status'] === "APPROVED"))?.length || 0)
  }, [album])

  const getVideoAlbumCover = useCallback(
    (album: ModelAlbum) => {
      if (album.videos && album.videos.length) {
        const cover =
          album.videos[0].media_status === "active"
            ? album.videos[0]
            : undefined;
        return cover ? (cover as ModelVideo).poster_image : null;
      }
      return null;
    },
    [albums]
  );

  const onClickEditAlbum = useCallback(
    (albumOrMedia: ModelAlbum | ModelImage | ModelVideo) => {
      history.push(
        `${getSiteRouteURL(AppCamsModelRouteKey.myPhotosEdit)}/${
          albumOrMedia.id
        }`
      );
    },
    []
  );

  const onClickDeleteMedia = useCallback(
    async (albumOrMedia: ModelAlbum | ModelImage | ModelVideo) => {
      const isPremium =
        albumOrMedia.price_in_token !== undefined &&
        albumOrMedia.price_in_token !== null &&
        albumOrMedia.price_in_token > 0;
      setIsPromptOpen(
        <MediaManagerPrompt />,
        isPremium ? MyPagePromptMode.DELETE_PREMIUM : MyPagePromptMode.DELETE,
        async () => {
          await deleteMedia(albumOrMedia);
          setIsPromptOpen(null);
        },
        () => {
          setIsPromptOpen(null);
        }
      );
    },
    []
  );

  const onDrop = useCallback(async (files: File[], album: ModelAlbum) => {
    if (files && files.length && album) {
      let uplCount = 0;
      album.uploadProgress = 1;
      files.forEach(async file => {
        await uploadModelMediaFile(file, file.name, album.id);
        uplCount++;
        if (files.length === uplCount) {
          album.uploadProgress = 100;
        } else {
          album.uploadProgress = Math.round((uplCount / files.length) * 100);
        }
      });
    }
  }, []);

  const onDropRejected = useCallback((rejections: FileRejection[]) => {
    showNotificationOnRejectedFiles(
      rejections,
      MY_PAGE_ACCEPTED_MAXIMUM_FILES_FOR_CUSTOM_ALBUM,
      MY_PAGE_ACCEPTED_FILE_EXTENSIONS.join(", ")
    );
  }, []);

  return (
    <TailwindFlex
      minWidth={"min-w-48"}
      key={album.id || album.type}
      height={"h-auto"}>
      <MediaManagerAlbum
        onClick={() => onClickEditAlbum(album)}
        purchasesProps={{
          num_purchases: album?.num_purchases,
        }}
        fileUploadProps={
          album.album_type === "custom" || album.album_type === "default"
            ? {
                fileUploadProps: {
                  showHelperText: false,
                  borderOpacity: "border-opacity-0",
                  altBaseClassName: "MediaManagerActions",
                  height: "h-10",
                  display: "flex",
                  alignItems: "items-end",
                  customIcon: <></>,
                  dropzoneProps: {
                    onDrop: files => onDrop(files, album),
                    onDropRejected,
                  },
                },
              }
            : undefined
        }
        actionsProps={{
          onEditClicked:
            album.images?.length || album.videos?.length
              ? () => onClickEditAlbum(album)
              : undefined,
          onDeleteClicked:
            album.album_type === "custom"
              ? () => onClickDeleteMedia(album)
              : undefined,
        }}
        statusProps={{
          status: album.media_status,
        }}
        titleProps={{
          name: getAlbumTitle(album),
          status: album?.pending_title_status as string,
        }}
        priceProps={{
          priceInToken: album.price_in_token,
        }}
        bodyProps={{
          backgroundImage:
            album.album_type === "recorded" ||
            (album.album_type === "videos" && album?.num_media)
              ? getVideoAlbumCover(album)
              : album?.cover_image?.image || album?.images?.[0]?.image,
          fallbackBackgroundImage: (profile as NonManagerProfile)
            .public_profile_image,
          brokenPlaceholderChildren: (
            <TailwindFlex
              width='w-full'
              padding={[album?.album_type === "custom" ? "p-9" : "p-0"]}>
              {!album.num_media && (
                <TailwindText width='w-full' textAlign={"text-center"}>
                  {getEmptyAlbumMessage()}
                </TailwindText>
              )}
            </TailwindFlex>
          ),
        }}
        mediaCountProps={{
          count: mediaApprovedCount,
        }}
        mediaUploadProgressProps={{
          uploadProgress: album.uploadProgress || 100,
        }}
      />
    </TailwindFlex>
  );
};

export default inject(
  "mediaManagerStore",
  "routeStore",
  "validationStore",
  "profileStore"
)(observer(MediaManagerMasonryAlbum));
