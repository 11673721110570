import { ThemeColorPalette } from "./types";
import Color from "color";
import { ThemeColorContrast } from "./enums";
import { getUpdatedColorPalette } from "./utils";

export const DEFAULT_AFF_THEME_COLOR_CONTRAST = ThemeColorContrast.dark;

/* DARK */
const affDarkTitleColor1 = "#FFFFFF";
const affDarkTitleColor2 = "#9092A6";
const affDarkSkyBlueColor = "#3B70EB";
const affDarkPinkColor = "#ff5497";
const affDarkStrongColor = "#FFFFFF";
const affDarkBorderColor1 = "#000";
const affDarkPrimaryBgColor = "#000";
const affDarkSecondaryBgColor = "#fff";
const affDarkSidebarBgColor = "#000000";
const affDarkHeaderBgColor = "#000000";
const affDarkSidebarTextColor = "#fff";
const affDarkSidebarActiveHoverColor = "#df0000";
const affDarkSidebarBorderRadius = "0";
const affDarkHeaderTextColor = "#cccccc";
const affDarkBodyPadding = "0";
const affDarkBodyBgColor = "#1b1d24";
const affDarkHeaderHeight = "100px";
const affDarkCardBorderRadius = "0";
const affDarkCardPrimaryBgColor = "#000";
const affDarkCardSecondaryBgColor = "#000";
const affDarkTabActiveTextColor = "#fff";
const affDarkTabActiveTabLineColor = "#fff";
const affDarkTabsBottomBorderColor = "#606166";
const affDarkFeedCardBgColor = "#2a2d36";
const affDarkFeedCardCommentBgColor = "#424852";
const affDarkFeedCardCommentBorderRadius = "10px";

export const DEFAULT_AFF_DARK_THEME_COLOR_PALETTE: ThemeColorPalette = {
  "primary-bg-color": affDarkPrimaryBgColor,
  "primary-bg-color-darker": Color(affDarkPrimaryBgColor).darken(0.5).hex(),
  "primary-bg-color-rgb": Color(affDarkPrimaryBgColor).rgb().array(),
  "secondary-bg-color": affDarkSecondaryBgColor,
  "secondary-bg-color-darker": Color(affDarkSecondaryBgColor).darken(0.3).hex(),
  "secondary-bg-color-rgb": Color(affDarkSecondaryBgColor).rgb().array(),
  "table-header-primary-bg-color": "#F5F6FE",
  "table-secondary-color": affDarkCardSecondaryBgColor,
  "tertiary-bg-color": affDarkPrimaryBgColor,
  "quaternary-bg-color": "#e9eef2",
  "avatar-primary-bg-color": affDarkPrimaryBgColor,
  "avatar-secondary-bg-color": affDarkCardSecondaryBgColor,
  "card-primary-bg-color": affDarkCardPrimaryBgColor,
  "card-secondary-bg-color": affDarkCardSecondaryBgColor,
  "card-primary-bg-color-tw-class": "bg-gray-700",
  "card-secondary-bg-color-tw-class": "bg-gray-900",
  "dropdown-primary-bg-color-tw-class": "bg-gray-100",
  "dropdown-secondary-bg-color-tw-class": "bg-blue-900",
  "dropdown-primary-text-color-tw-class": "text-white",
  "dropdown-secondary-text-color-tw-class": "text-white",
  "dropdown-primary-focus-button-border-color-tw-class": "border-white",
  "dropdown-secondary-focus-button-border-color-tw-class": "border-white",
  "dropdown-primary-multiple-counter-background-color-tw-class": "bg-white",
  "dropdown-secondary-multiple-counter-background-color-tw-class": "bg-white",
  "dropdown-primary-multiple-counter-text-color-tw-class": "text-black",
  "dropdown-secondary-multiple-counter-text-color-tw-class": "text-black",
  "dropdown-primary-multiple-checkbox-border-color-tw-class": "border-gray-500",
  "dropdown-secondary-multiple-checkbox-border-color-tw-class":
    "border-gray-500",
  "input-primary-bg-color-tw-class": "bg-gray-100",
  "input-secondary-bg-color-tw-class": "bg-blue-900",
  "input-primary-text-color-tw-class": "text-white",
  "input-secondary-text-color-tw-class": "text-white",
  "input-primary-placeholder-color-tw-class": "placeholder-gray-700",
  "input-secondary-placeholder-color-tw-class": "placeholder-gray-700",
  "input-error-text-color-tw-class": "text-red-500",
  "input-error-border-color-tw-class": "border-red-500",
  "input-warning-text-color-tw-class": "text-yellow-500",
  "input-warning-border-color-tw-class": "border-yellow-500",
  "input-disabled-background-color-tw-class": "bg-gray-500",
  "input-disabled-text-color-tw-class": "text-white",
  borderColor: "#2e2b66",
  "border-color-contrast": "#9092A6",
  "contrasting-bg-color": "#FFFFFF",
  "link-color": "#9092A6",
  "link-hover-active-color": "#df0000",
  "strong-color": affDarkStrongColor,
  "strong-color-rgb": Color(affDarkStrongColor).rgb().array(),
  "title-color-1": affDarkTitleColor1,
  "title-color-1-rgb": Color(affDarkTitleColor1).rgb().array(),
  "title-color-1-inversed": Color(affDarkTitleColor1).negate().hex(),
  "title-color-1-darken-10": Color(affDarkTitleColor1).darken(0.1).hex(),
  "title-color-2": affDarkTitleColor2,
  "title-color-2-rgb": Color(affDarkTitleColor2).rgb().array(),
  "title-color-3": "#8c94c1",
  "title-color-4": "#7e8bd9",
  "title-color-5": "#d5e8ff",
  "title-color-6": "#999",
  "body-color-1": "#FFFFFF",
  "body-color-2": "#999",
  "faded-color": "#a5a5a5",
  "scroll-bg-color": "#000",
  "scroll-bar-color": "#df0000",
  "scroll-bar-color-2": "#df0000",
  "scroll-bg-color-3": "#000",
  "orange-color": "#ef951b",
  "red-color": "#df0000",
  "green-color": "#28a745",
  "error-red": "#df0000",
  "border-color-1": affDarkBorderColor1,
  "text-main-color": "#fff",
  "text-secondary-color": "#5c5c5c",
  "border-color-1-rgb": Color(affDarkBorderColor1).rgb().array(),
  "sky-blue-color": affDarkSkyBlueColor,
  "sky-blue-color-rgb": Color(affDarkSkyBlueColor).rgb().array(),
  "pink-color": affDarkPinkColor,
  "pink-color-rgb": Color(affDarkPinkColor).rgb().array(),
  "darker-card-color": "#262a4f",
  "transparent-bg-color": "rgba(#262a4f,.3)",
  "image-overlay-bg-color": "rgba(#161833,.7)",
  "upload-overlay-bg-color": "#4D516E",
  "transparent-overlay-color": "rgba(#000000,.7)",
  "snackbar-bg": "#42476D",
  "snackbar-border": "#666",
  "image-editor-sidebar-bg": "#21244d",
  "image-editor-sidebar-header-bg": "#1e1f47",
  "yellow-color": "#eddc1f",
  "input-border-radius": "25px",
  "media-border-radius": "20px",
  "sidebar-width": "250px",
  "sidebar-border-right-color": "transparent",
  "sidebar-bg-color": affDarkSidebarBgColor,
  "sidebar-text-color": affDarkSidebarTextColor,
  "sidebar-text-active-hover-color": affDarkSidebarActiveHoverColor,
  "sidebar-border-radius": affDarkSidebarBorderRadius,
  "header-bg-color": affDarkHeaderBgColor,
  "header-text-color": affDarkHeaderTextColor,
  "header-height": affDarkHeaderHeight,
  "logo-color": affDarkTitleColor1,
  "body-padding": affDarkBodyPadding,
  "body-bg-color": affDarkBodyBgColor,
  "card-border-radius": affDarkCardBorderRadius,
  "tab-active-tab-line-color": affDarkTabActiveTabLineColor,
  "tab-active-text-color": affDarkTabActiveTextColor,
  "tabs-bottom-border-color": affDarkTabsBottomBorderColor,
  "feed-card-bg-color": affDarkFeedCardBgColor,
  "feed-card-comment-bg-color": affDarkFeedCardCommentBgColor,
  "feed-card-comment-border-radius": affDarkFeedCardCommentBorderRadius,
  "form-elements-font-size": "1rem",
  "sidebar-text-font-size": "1rem",
  "dropdown-border-radius-tw-class": "rounded",
  "input-border-radius-tw-class": "rounded",
  "media-border-radius-tw-class": "rounded",
  "media-background-color-tw-class": "bg-gray-500",
  "button-primary-bg-color-tw-class": "bg-pink-400",
  "button-secondary-bg-color-tw-class": "bg-blue-400",
  "button-tertiary-bg-color-tw-class": "bg-gray-100",
  "button-primary-text-color-tw-class": "text-white",
  "button-secondary-text-color-tw-class": "text-white",
  "button-tertiary-text-color-tw-class": "text-black",
  "button-non-rounded-border-radius-tw-class": "rounded",
  "button-rounded-border-radius-tw-class": "rounded-full",
  "button-disabled-background-color-tw-class": "bg-gray-500",
  "button-disabled-text-color-tw-class": "text-gray-800",
  "loading-indicator-accent-color": "#3B70EB",
  "loading-indicator-bg-color": "#fff",
  "highlight-color": affDarkPrimaryBgColor,
  "lightGreen-bg-color": "#00b326",
  "lightPink-bg-color": "#f472b7",
  "lightRed-bg-color": "#ff0000",
  "lightGray-bg-color": "#9e9e9e",
  "lightPurple-bg-color": "#5c0099",
};

/* LIGHT */
const affLightTitleColor1 = "#000";
const affLightTitleColor2 = "#8c94c1";
const affLightSkyBlueColor = "#3B70EB";
const affLightPinkColor = "#ff5497";
const affLightStrongColor = "#FFFFFF";
const affLightBorderColor1 = "#465bca";
const affLightPrimaryBgColor = "#fafafa";
const affLightSecondaryBgColor = "#f4f4f4";
const affLightSidebarBgColor = "#F4F4F4";
const affLightHeaderBgColor = "#F4F4F4";
const affLightSidebarTextColor = "#000";
const affLightSidebarActiveHoverColor = "#ef951b";
const affLightSidebarBorderRadius = "0";
const affLightHeaderTextColor = "#000";
const affLightBodyPadding = "0";
const affLightBodyBgColor = "#FAFAFA";
const affLightHeaderHeight = "100px";
const affLightCardBorderRadius = "0";
const affLightCardPrimaryBgColor = "#fff";
const affLightCardSecondaryBgColor = "#fff";
const affLightTabActiveTextColor = "#ef951b";
const affLightTabActiveTabLineColor = "#ef951b";
const affLightTabsBottomBorderColor = "#ef951b";
const affLightFeedCardBgColor = "#ef951b";
const affLightFeedCardCommentBgColor = "#ef951b";
const affLightFeedCardCommentBorderRadius = "10px";

export const DEFAULT_AFF_LIGHT_THEME_COLOR_PALETTE: ThemeColorPalette = {
  "primary-bg-color": affLightPrimaryBgColor,
  "primary-bg-color-darker": Color(affLightPrimaryBgColor).darken(0.5).hex(),
  "primary-bg-color-rgb": Color(affLightPrimaryBgColor).rgb().array(),
  "secondary-bg-color": affLightSecondaryBgColor,
  "secondary-bg-color-darker": Color(affLightSecondaryBgColor)
    .darken(0.3)
    .hex(),
  "secondary-bg-color-rgb": Color(affLightSecondaryBgColor).rgb().array(),
  "tertiary-bg-color": affLightSecondaryBgColor,
  "quaternary-bg-color": "#e9eef2",
  "table-header-primary-bg-color": "#F5F6FE",
  "table-secondary-color": affLightCardSecondaryBgColor,
  "avatar-primary-bg-color": affLightCardPrimaryBgColor,
  "avatar-secondary-bg-color": affLightCardSecondaryBgColor,
  "card-primary-bg-color": affLightCardPrimaryBgColor,
  "card-secondary-bg-color": affLightCardSecondaryBgColor,
  "card-primary-bg-color-tw-class": "bg-gray-300",
  "card-secondary-bg-color-tw-class": "bg-pink-400",
  "dropdown-primary-bg-color-tw-class": "bg-gray-100",
  "dropdown-secondary-bg-color-tw-class": "bg-gray-300",
  "dropdown-primary-text-color-tw-class": "text-black",
  "dropdown-secondary-text-color-tw-class": "text-black",
  "dropdown-primary-focus-button-border-color-tw-class": "border-black",
  "dropdown-secondary-focus-button-border-color-tw-class": "border-black",
  "dropdown-primary-multiple-counter-background-color-tw-class": "bg-black",
  "dropdown-secondary-multiple-counter-background-color-tw-class": "bg-black",
  "dropdown-primary-multiple-counter-text-color-tw-class": "text-white",
  "dropdown-secondary-multiple-counter-text-color-tw-class": "text-white",
  "dropdown-primary-multiple-checkbox-border-color-tw-class": "border-black",
  "dropdown-secondary-multiple-checkbox-border-color-tw-class": "border-black",
  "input-primary-bg-color-tw-class": "bg-gray-100",
  "text-main-color": "#000",
  "text-secondary-color": "#5c5c5c",
  "input-secondary-bg-color-tw-class": "bg-gray-300",
  "input-primary-text-color-tw-class": "text-black",
  "input-secondary-text-color-tw-class": "text-black",
  "input-primary-placeholder-color-tw-class": "placeholder-gray-700",
  "input-secondary-placeholder-color-tw-class": "placeholder-gray-700",
  "input-error-text-color-tw-class": "text-red-500",
  "input-error-border-color-tw-class": "border-red-500",
  "input-warning-text-color-tw-class": "text-yellow-500",
  "input-warning-border-color-tw-class": "border-yellow-500",
  "input-disabled-background-color-tw-class": "bg-gray-500",
  "input-disabled-text-color-tw-class": "text-white",
  borderColor: "#2e2b66",
  "border-color-contrast": "#9092A6",
  "contrasting-bg-color": "#FFFFFF",
  "link-color": "#9092A6",
  "link-hover-active-color": "#ef951b",
  "strong-color": affLightStrongColor,
  "strong-color-rgb": Color(affLightStrongColor).rgb().array(),
  "title-color-1": affLightTitleColor1,
  "title-color-1-rgb": Color(affLightTitleColor1).rgb().array(),
  "title-color-1-inversed": Color(affLightTitleColor1).negate().hex(),
  "title-color-1-darken-10": Color(affLightTitleColor1).darken(0.1).hex(),
  "title-color-2": affLightTitleColor2,
  "title-color-2-rgb": Color(affLightTitleColor2).rgb().array(),
  "title-color-3": "#8c94c1",
  "title-color-4": "#7e8bd9",
  "title-color-5": "#d5e8ff",
  "title-color-6": "#999",
  "body-color-1": "#FFFFFF",
  "body-color-2": "#999",
  "faded-color": "#ccc",
  "scroll-bg-color": "#101233",
  "scroll-bar-color": "#21244d",
  "scroll-bar-color-2": "#6C708D",
  "scroll-bg-color-3": "#383e75",
  "orange-color": "#ef951b",
  "red-color": "#cd264f",
  "green-color": "#28a745",
  "error-red": "#ff1744",
  "border-color-1": affLightBorderColor1,
  "border-color-1-rgb": Color(affLightBorderColor1).rgb().array(),
  "sky-blue-color": affLightSkyBlueColor,
  "sky-blue-color-rgb": Color(affLightSkyBlueColor).rgb().array(),
  "pink-color": affLightPinkColor,
  "pink-color-rgb": Color(affLightPinkColor).rgb().array(),
  "darker-card-color": "#262a4f",
  "transparent-bg-color": "rgba(#262a4f,.3)",
  "image-overlay-bg-color": "rgba(#161833,.7)",
  "upload-overlay-bg-color": "#4D516E",
  "transparent-overlay-color": "rgba(#000000,.7)",
  "snackbar-bg": "#42476D",
  "snackbar-border": "#666",
  "image-editor-sidebar-bg": "#21244d",
  "image-editor-sidebar-header-bg": "#1e1f47",
  "yellow-color": "#eddc1f",
  "input-border-radius": "25px",
  "media-border-radius": "20px",
  "sidebar-width": "250px",
  "sidebar-border-right-color": "transparent",
  "sidebar-bg-color": affLightSidebarBgColor,
  "sidebar-text-color": affLightSidebarTextColor,
  "sidebar-text-active-hover-color": affLightSidebarActiveHoverColor,
  "sidebar-border-radius": affLightSidebarBorderRadius,
  "header-bg-color": affLightHeaderBgColor,
  "header-text-color": affLightHeaderTextColor,
  "header-height": affLightHeaderHeight,
  "logo-color": affLightTitleColor1,
  "body-padding": affLightBodyPadding,
  "body-bg-color": affLightBodyBgColor,
  "card-border-radius": affLightCardBorderRadius,
  "tab-active-tab-line-color": affLightTabActiveTabLineColor,
  "tab-active-text-color": affLightTabActiveTextColor,
  "tabs-bottom-border-color": affLightTabsBottomBorderColor,
  "feed-card-bg-color": affLightFeedCardBgColor,
  "feed-card-comment-bg-color": affLightFeedCardCommentBgColor,
  "feed-card-comment-border-radius": affLightFeedCardCommentBorderRadius,
  "form-elements-font-size": "1rem",
  "sidebar-text-font-size": "1rem",
  "dropdown-border-radius-tw-class": "rounded",
  "input-border-radius-tw-class": "rounded",
  "media-border-radius-tw-class": "rounded",
  "media-background-color-tw-class": "bg-gray-500",
  "button-primary-bg-color-tw-class": "bg-pink-400",
  "button-secondary-bg-color-tw-class": "bg-blue-400",
  "button-tertiary-bg-color-tw-class": "bg-gray-100",
  "button-primary-text-color-tw-class": "text-white",
  "button-secondary-text-color-tw-class": "text-white",
  "button-tertiary-text-color-tw-class": "text-black",
  "button-non-rounded-border-radius-tw-class": "rounded",
  "button-rounded-border-radius-tw-class": "rounded-full",
  "button-disabled-background-color-tw-class": "bg-gray-500",
  "button-disabled-text-color-tw-class": "text-gray-800",
  "loading-indicator-accent-color": "#3B70EB",
  "loading-indicator-bg-color": "#fff",
  "highlight-color": affLightPrimaryBgColor,
  "lightGreen-bg-color": "#00b326",
  "lightPink-bg-color": "#f472b7",
  "lightRed-bg-color": "#ff0000",
  "lightGray-bg-color": "#9e9e9e",
  "lightPurple-bg-color": "#5c0099",
};

export const AffDarkThemePalette = (
  colors: ThemeColorPalette
): ThemeColorPalette =>
  getUpdatedColorPalette(DEFAULT_AFF_DARK_THEME_COLOR_PALETTE, colors);

export const AffLightThemePalette = (
  colors: ThemeColorPalette
): ThemeColorPalette =>
  getUpdatedColorPalette(DEFAULT_AFF_LIGHT_THEME_COLOR_PALETTE, colors);
