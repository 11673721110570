import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";
import Code from "../../components/_tailwind/code";
import TailwindHeading from "../../components/_tailwind/heading";

type Props = {};

const StorybookComponentsHeading: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Heading</StorybookHeading>
      <StorybookParagraph>
        Heading component is an extended version of TailwindText that adds some
        opinions via some props like <Code inline>level</Code> to easily change
        the font sizes.
      </StorybookParagraph>
      <StorybookParagraph>
        Currently it takes all the props from TailwindText.
      </StorybookParagraph>
      <StorybookParagraph>
        Here are some examples with the TailwindText component. As it is very
        flexible, you can create variants easily so not all combinations are
        showcased here.
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindHeading level={1}>Heading1</TailwindHeading>`}
      >
        <TailwindHeading level={1}>Heading1</TailwindHeading>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindHeading level={2}>Heading2</TailwindHeading>`}
      >
        <TailwindHeading level={2}>Heading2</TailwindHeading>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindHeading level={3}>Heading3</TailwindHeading>`}
      >
        <TailwindHeading level={3}>Heading3</TailwindHeading>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindHeading level={4}>Heading4</TailwindHeading>`}
      >
        <TailwindHeading level={4}>Heading4</TailwindHeading>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindHeading level={5}>Heading5</TailwindHeading>`}
      >
        <TailwindHeading level={5}>Heading5</TailwindHeading>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindHeading level={6}>Heading6</TailwindHeading>`}
      >
        <TailwindHeading level={6}>Heading6</TailwindHeading>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindHeading level={6} textColor={"text-red-500"}>Heading6</TailwindHeading>`}
      >
        <TailwindHeading level={6} textColor={"text-red-500"}>
          Heading6
        </TailwindHeading>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsHeading;
