import React from "react";
import classnames from "classnames";

type LockIconProps = {
  className?: string;
};

const LockIcon: React.FunctionComponent<LockIconProps> = ({
  className = "",
}) => {
  return (
    <span className={classnames("LockIcon", className && className)}>
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        xmlSpace='preserve'
        x='0px'
        y='0px'
        viewBox='0 0 14 15'>
        <g transform='translate(0, 0) rotate(0)'>
          <path
            d='M12.67,5.23923h-2.73v-1.86603c0,-1.86603 -1.47,-3.37321 -3.29,-3.37321c-1.82,0 -3.29,1.57895 -3.29,3.37321v1.79426h-2.03c-0.7,0 -1.33,0.57416 -1.33,1.29187v7.2488c0,0.7177 0.63,1.29187 1.33,1.29187h11.34c0.7,0 1.33,-0.57416 1.33,-1.2201v-7.2488c0,-0.7177 -0.63,-1.29187 -1.33,-1.29187zM4.41,3.37321c0,-1.2201 0.98,-2.29665 2.24,-2.29665c1.26,0 2.24,1.00478 2.24,2.29665v1.79426h-4.48zM12.88,13.85167c0,0.07177 -0.14,0.14354 -0.21,0.14354h-11.34c-0.14,0 -0.21,-0.07177 -0.21,-0.14354v-7.32057c0,-0.14354 0.07,-0.14354 0.21,-0.14354h11.34c0.14,0 0.21,0.07177 0.21,0.14354zM8.33,9.54545c0,-0.78947 -0.63,-1.36364 -1.33,-1.36364c-0.7,0 -1.33,0.64593 -1.33,1.36364c0,0.50239 0.28,1.00478 0.7,1.2201v1.00478c0,0.28708 0.21,0.57416 0.56,0.57416h0.14c0.28,0 0.56,-0.21531 0.56,-0.57416v-1.00478c0.42,-0.21531 0.7,-0.64593 0.7,-1.2201z'
            vectorEffect='non-scaling-stroke'
          />
        </g>
      </svg>
    </span>
  );
};

export default LockIcon;
