import React, { useMemo, useState, useCallback } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import ModalStore from "library/core/stores/modal";
import MessageStore from "./stores/messages/MessageStore";
import LanguageStore from "library/core/stores/language/LanguageStore";
import { Box } from "@material-ui/core";
import MessageCenterImage from "./message-reader-image";
import {
  MessageCenterMessageDetails,
  MessageCenterMessageAttachmentDetails,
} from "common/messages/stores/messages/types";
import MessageCenterModelNameBar from "./messages-model-name-bar";
import VideoPlayer from "library/components/_tailwind/video-player";
import { SharedMediaTypeEnum } from "./stores/messages/enum";
import SwipeableSlider from "library/components/swipeable-slider";
import CarouselPaginator from "library/components/carousel-paginator";
import MediaCarouselImage from "./message-media-carousel-image";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import MessagesAudioPlayer from "./messages-audio-player";
import Avatar from "library/components/avatar";

type Props = {
  modalStore?: ModalStore;
  message: MessageCenterMessageDetails;
  messageStore?: MessageStore;
  languageStore?: LanguageStore;
  profileStore?: ProfileStore;
  attachment: MessageCenterMessageAttachmentDetails;
};

const MediaCarouselModal: React.ComponentType<Props & WrappedComponentProps> =
  ({ modalStore, messageStore, languageStore, profileStore, attachment }) => {
    const [showAudioPing, setShowAudioPing] = useState<boolean>(false);
    const { activeConversationAttachments, activeConversation } = messageStore!;
    const { profile } = profileStore!;

    const { closeSecondaryModal } = modalStore!;
    const { intl } = languageStore!;

    const firstConversationParticipant = useMemo(
      () => activeConversation?.participants?.[0],
      [activeConversation]
    );

    const isSenderCurrentUser = useCallback(
      id => id === profile?.user_id,
      [profile]
    );

    const modelProfileImage = useMemo(
      () =>
        activeConversation && firstConversationParticipant
          ? firstConversationParticipant?.profile_picture
          : "",
      [activeConversation, firstConversationParticipant]
    );

    function getIndex(details) {
      return activeConversationAttachments.findIndex(attachment =>
        attachment.attachment_type === SharedMediaTypeEnum.Video
          ? attachment?.thumbnail_url == details.signed_url
          : attachment?.signed_url == details.signed_url
      );
    }

    const [activeIndex, setActiveIndex] = useState<number>(
      getIndex(attachment)
    );

    const onSlideChange = index => {
      setActiveIndex(index);
    };

    const onImageClick = useCallback(index => {
      onSlideChange(index);
    }, []);

    return (
      <Box className={"MediaCarouselModal"}>
        <Box
          className={"flex-col MediaCarouselModal__container"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"flex-col"}
          justifyContent={"center"}
          width={"vw-full"}>
          <Box className='MediaCarouselModal__header pb-1' display={"flex"}>
            <Box
              display={"flex"}
              alignItems='center'
              flexDirection='row'
              width={"auto"}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                width={"auto"}></Box>
              <TailwindButton
                className={["SharedMediaModal__back-button"]}
                padding={["p-0", "pr-2"]}
                onClick={closeSecondaryModal}
                alignSelf='self-center'
                rightIconProps={{
                  name: "keyboard_arrow_left",
                  fontSize: "text-2xl",
                  textColor: "text-gray-400",
                  style: {
                    width: "12px",
                  },
                }}
              />
              <Box
                className={"SharedMediaModal__model-name"}
                display={"flex"}
                flexGrow={1}>
                {activeConversation ? (
                  <TailwindFlex
                    className={["SharedMediaModal__model-name"]}
                    flexGrow={"flex-grow"}>
                    <MessageCenterModelNameBar
                      conversation={activeConversation}
                      modelProfileImage={modelProfileImage}
                      text={intl.formatMessage({
                        id: "message-center.shared-conversation",
                        defaultMessage: "Shared Media",
                      })}
                      boxProps={{
                        padding: ["p-1"],
                      }}
                    />
                  </TailwindFlex>
                ) : null}
              </Box>
            </Box>
          </Box>
          <Box
            flexDirection={"flex-col"}
            className={`MediaCarouselModal__media`}
            display={"flex"}
            flexGrow={1}>
            <Box
              className={"MediaCarouselModal__media-selected-image"}
              onContextMenu={e => {
                e.preventDefault();
                return false;
              }}>
              <SwipeableSlider
                swipeableViewsProps={{
                  index: activeIndex || 0,
                  onChangeIndex: onSlideChange,
                }}
                disableArrows={true}
                enableKeyboardArrowPress={true}>
                {activeConversationAttachments.length
                  ? activeConversationAttachments.map((m, index) => {
                      return m.attachment_type === SharedMediaTypeEnum.Image ? (
                        <MessageCenterImage
                          key={index}
                          imageURL={m.signed_url}
                        />
                      ) : m.attachment_type === SharedMediaTypeEnum.Video ? (
                        <VideoPlayer
                          src={index === activeIndex ? m.signed_url : ""}
                          useHls={false}
                          autoPlay={true}
                          fullHeight={false}
                          fullWidth={false}
                          wrapperProps={{
                            display: "flex",
                            height: "h-full",
                            justifyContent: "justify-center",
                          }}></VideoPlayer>
                      ) : m.attachment_type === SharedMediaTypeEnum.Audio ? (
                        <TailwindFlex
                          height='h-full'
                          justifyContent='justify-center'
                          alignItems='items-center'>
                          <Avatar
                            username={
                              isSenderCurrentUser(m?.sender)
                                ? profile?.username
                                : firstConversationParticipant?.username
                            }
                            randomBackgroundColor={
                              isSenderCurrentUser(m?.sender) ? false : true
                            }
                            showPing={showAudioPing}
                          />
                          <TailwindFlex className={["AudioPlayer__container"]}>
                            <MessagesAudioPlayer
                              src={index === activeIndex ? m.signed_url : ""}
                              duration={m.length ? parseFloat(m.length) : 0}
                              onPlay={playing => {
                                setShowAudioPing(playing);
                              }}
                            />
                          </TailwindFlex>
                        </TailwindFlex>
                      ) : null;
                    })
                  : null}
              </SwipeableSlider>
            </Box>
          </Box>

          <Box
            className='MediaCarouselModal__footer justify-center flex-row'
            display={"flex"}
            justifyContent={"justify-center"}
            flexDirection={"flex-row"}
            alignItems={"center"}>
            <CarouselPaginator startAt={activeIndex} max={5}>
              {activeConversationAttachments.length
                ? activeConversationAttachments.map((m, index) => {
                    return (
                      <Box className={"carouselIconBox"} key={m.id}>
                        <MediaCarouselImage
                          onClick={() => onImageClick(index)}
                          className={`carouselIcon ${
                            activeIndex === index ? "selectedIcon" : ""
                          }`}
                          src={
                            m.thumbnail_url || m.signed_url || modelProfileImage
                          }
                          alt=''
                          onContextMenu={e => {
                            e.preventDefault();
                            return false;
                          }}
                          isVideo={
                            m.attachment_type === SharedMediaTypeEnum.Video
                          }
                          isAudio={
                            m.attachment_type === SharedMediaTypeEnum.Audio
                          }
                          username={
                            isSenderCurrentUser(m?.sender)
                              ? profile.username
                              : firstConversationParticipant?.username
                          }
                          isSenderCurrentUser={isSenderCurrentUser(m?.sender)}
                        />
                      </Box>
                    );
                  })
                : null}
            </CarouselPaginator>
          </Box>
        </Box>
      </Box>
    );
  };

export default injectIntl(
  inject(
    "modalStore",
    "validationStore",
    "messageStore",
    "languageStore",
    "profileStore"
  )(observer(MediaCarouselModal))
);
