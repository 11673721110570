import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindButton from "library/components/_tailwind/button";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import AuthStore from "core/stores/auth/AuthStore";
import { inject, observer } from "mobx-react";
import TailwindInputBase, {
  TailwindInputType,
} from "library/components/_tailwind/input-base";
import BroadcastMemberVerifyViewer from "../broadcast-preview/broadcast-member-verify-viewer";
import NodeChatStore from "../_stores/nodechat/NodeChatStore";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import { ShowTab } from "../_stores/broadcast/enums";
import { ChatMemberDetails } from "../_stores/nodechat/interfaces";
import { Popover } from "common/popover";
import "./styles.scss";

type Props = {
  authStore?: AuthStore;
  nodeChatStore?: NodeChatStore;
  broadcastStore?: BroadcastStore;
};

const GENDER_OPTIONS = [
  { key: "0", value: "Unknown" },
  { key: "1", value: "Male" },
  { key: "2", value: "Female" },
  { key: "3", value: "Couple" },
  { key: "5", value: "Couple" },
  { key: "6", value: "Couple" },
  { key: "7", value: "Trans" },
];
type Gender = typeof GENDER_OPTIONS[number]["key"];

const AGE_GROUP_OPTIONS = [
  { key: "under_25", value: "Above 18" },
  { key: "over_25", value: "Over 25" },
];
type AgeGroup = typeof AGE_GROUP_OPTIONS[number]["key"];

const BroadcastMemberVerifyPanel: React.ComponentType<Props> = ({
  nodeChatStore,
  broadcastStore,
}) => {
  const {
    c2cHash,
    verifyMemberAge,
    suspendMemberAgeVerification,
    denyMemberAgeVerification,
    switchToShow,
  } = broadcastStore!;
  const { members, getMemberDetails, getMemberVerificationDetails } =
    nodeChatStore!;
  const [isSnoozeCollapsed, setIsSnoozeCollapsed] = useState<boolean>(true);
  const [gender, setGender] = useState<Gender>("0");
  const [ageGroup, setAgeGroup] = useState<AgeGroup>("under_25");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [isGenderChecked, setIsGenderChecked] = useState(false);
  const [isBirthdateChecked, setIsBirthdateChecked] = useState(false);
  const [isCountryChecked, setIsCountryChecked] = useState(false);
  const [isPhotoChecked, setIsPhotoChecked] = useState(false);
  const member = members[0]; // VERIFIER show is 1 on 1 show so there should only be one member in the member list
  const [photoUrl, setPhotoUrl] = useState("");
  const [memberDetails, setMemberDetails] = useState<ChatMemberDetails | null>(
    null
  );
  const [isVerified, setIsVerified] = useState(false);

  const getHoursToDayLabel = hours => {
    if (hours < 24) {
      return `${hours} hours`;
    } else if (hours % 24 === 0) {
      return `${hours / 24} days`;
    }

    const remainingHours = hours % 24;
    return `${Math.floor(hours / 24)} days ${remainingHours} hours`;
  };

  const snoozeValues = useMemo(() => {
    const now = new Date();
    const intervals = [12, 24, 72, 168, 336]; // hours for each interval

    return intervals.map(hours => {
      const futureDate = new Date(now.getTime() + hours * 60 * 60 * 1000);
      return {
        label: getHoursToDayLabel(hours),
        value: futureDate.toISOString(),
      };
    });
  }, []);

  const isC2CActive = useMemo(() => {
    return !!Object.values(broadcastStore?.c2cHash || {}).length;
  }, [c2cHash]);

  const deny = async () => {
    if (member?.user_id) {
      denyMemberAgeVerification(member.user_id);
    } else {
      endShow();
    }
  };

  const snooze = async (iso_string: string) => {
    if (member?.user_id) {
      suspendMemberAgeVerification(member.user_id, iso_string);
    } else {
      endShow();
    }
  };

  const submit = async () => {
    setIsVerified(true);
    const success = await verifyMemberAge({
      user_id: member?.user_id || "",
      sex: Number(gender),
      age_range: ageGroup,
      is_photo_verified: isPhotoChecked,
      has_gender: isGenderChecked,
      has_birth_date: isBirthdateChecked,
      has_country: isCountryChecked,
      country,
      bday_day: `${memberDetails?.birthdate[2] || 0}`,
      bday_month: `${memberDetails?.birthdate[1] || 0}`,
      bday_year: `${memberDetails?.birthdate[0] || 0}`,
      ...(memberDetails?.birthdate2?.length && {
        bday_day2: `${memberDetails?.birthdate2?.[2] || 0}`,
        bday_month2: `${memberDetails?.birthdate2?.[1] || 0}`,
        bday_year2: `${memberDetails?.birthdate2?.[0] || 0}`,
      }),
      state: state || "N/A",
    });

    if (!success) {
      setIsVerified(false);
    }
  };

  const endShow = () => {
    switchToShow(ShowTab.FREE);
  };

  const initMember = useCallback(async () => {
    if (member) {
      const data = await getMemberDetails(member);
      const verifiedData = await getMemberVerificationDetails(member);

      setMemberDetails(data);

      setIsPhotoChecked(!!verifiedData?.photo_verified);
      setPhotoUrl(data?.profile_photo || "");
      setIsBirthdateChecked(!!verifiedData?.has_birth_date);
      setGender(`${verifiedData?.sex || data?.sex || 0}`);
      setIsGenderChecked(!!verifiedData?.has_gender);
      setCountry(
        verifiedData?.country || data?.country || data?.country_code || ""
      );
      setIsCountryChecked(!!verifiedData?.has_country);

      if (verifiedData?.age_range) {
        setAgeGroup(verifiedData.age_range);
      } else if (data?.age && data?.age > 25) {
        setAgeGroup(AGE_GROUP_OPTIONS[1].key); // Over 25
      } else {
        setAgeGroup(AGE_GROUP_OPTIONS[0].key); // Over 18
      }

      if (
        data?.state ||
        data?.state_code ||
        (!!verifiedData?.state && verifiedData?.state !== "N/A")
      ) {
        setState(`${verifiedData?.state || data?.state || data?.state_code}`);
      }
    }
  }, [member]);

  useEffect(() => {
    initMember();
  }, [member]);

  return (
    <TailwindFlex
      flexDirection='flex-row'
      className={["BroadcastMemberVerifyPanel"]}>
      {isC2CActive && (
        <TailwindFlex width='w-1/2'>
          <TailwindFlex
            backgroundColor='bg-gray-200'
            margin={["m-4"]}
            borderRadius={"rounded-lg"}>
            <BroadcastMemberVerifyViewer />
          </TailwindFlex>
        </TailwindFlex>
      )}
      <TailwindFlex
        flexDirection='flex-col'
        gap='gap-4'
        width={isC2CActive ? "w-1/2" : "w-full"}>
        <TailwindFlex
          alignItems='items-center'
          justifyContent='justify-start'
          gap='gap-5'>
          <TailwindFlex width={"w-8"} height={"h-8"}></TailwindFlex>
          <TailwindFlex flexDirection='flex-col'>
            <TailwindText fontWeight='font-semibold'>
              {"Age Range (Visual Appearance)"}
            </TailwindText>
            <TailwindFlex gap='gap-2' flexWrap='flex-wrap'>
              {AGE_GROUP_OPTIONS.map(({ key, value }) => (
                <button
                  key={key}
                  className={`px-3 py-1 rounded ${
                    ageGroup === key
                      ? "bg-black text-white"
                      : "bg-white text-black"
                  }`}
                  onClick={() => setAgeGroup(key)}>
                  {value}
                </button>
              ))}
            </TailwindFlex>
            {ageGroup === "under_25" && (
              <TailwindText textColor='text-red-600'>
                Members under 25 must be verified by ID
              </TailwindText>
            )}
          </TailwindFlex>
        </TailwindFlex>

        <TailwindFlex
          alignItems='items-center'
          justifyContent='justify-start'
          gap='gap-5'>
          <TailwindInputBase
            name={"checkbox"}
            type={TailwindInputType.checkbox}
            cursor={"cursor-pointer"}
            width={"w-8"}
            height={"h-8"}
            psuedoClasses={["focus:outline-none"]}
            onChange={() => setIsGenderChecked(!isGenderChecked)}
            checked={isGenderChecked}
          />
          <TailwindFlex flexDirection='flex-col'>
            <TailwindText fontWeight='font-semibold'>Gender</TailwindText>
            <TailwindFlex gap='gap-2' flexWrap='flex-wrap'>
              <button
                className={`px-3 py-1 rounded ${
                  gender === "0" ? "bg-black text-white" : "bg-white text-black"
                }`}
                disabled>
                Unknown
              </button>
              <button
                className={`px-3 py-1 rounded ${
                  gender === "1" ? "bg-black text-white" : "bg-white text-black"
                }`}
                disabled>
                Male
              </button>
              <button
                className={`px-3 py-1 rounded ${
                  gender === "2" ? "bg-black text-white" : "bg-white text-black"
                }`}
                disabled>
                Female
              </button>
              <button
                className={`px-3 py-1 rounded ${
                  gender === "3" || gender === "5" || gender === "6"
                    ? "bg-black text-white"
                    : "bg-white text-black"
                }`}
                disabled>
                Couple
              </button>
              <button
                className={`px-3 py-1 rounded ${
                  gender === "7" ? "bg-black text-white" : "bg-white text-black"
                }`}
                disabled>
                Trans
              </button>
            </TailwindFlex>
          </TailwindFlex>
        </TailwindFlex>

        <TailwindFlex
          alignItems='items-center'
          justifyContent='justify-start'
          gap='gap-5'>
          <TailwindInputBase
            name={"checkbox"}
            type={TailwindInputType.checkbox}
            cursor={"cursor-pointer"}
            width={"w-8"}
            height={"h-8"}
            psuedoClasses={["focus:outline-none"]}
            onChange={() => setIsBirthdateChecked(!isBirthdateChecked)}
            checked={isBirthdateChecked}
          />
          <TailwindFlex flexDirection='flex-col'>
            <TailwindText fontWeight='font-semibold'>Birth Date</TailwindText>
            <TailwindFlex flexDirection='flex-col' gap='gap-1'>
              <TailwindFlex space='space-x-2'>
                <input
                  type='text'
                  placeholder='MM'
                  className='w-16 p-2 border rounded'
                  value={memberDetails?.birthdate[1]}
                  disabled
                />
                <input
                  type='text'
                  placeholder='DD'
                  className='w-16 p-2 border rounded'
                  value={memberDetails?.birthdate[2]}
                  disabled
                />
                <input
                  type='text'
                  placeholder='YYYY'
                  className='w-20 p-2 border rounded'
                  value={memberDetails?.birthdate[0]}
                  disabled
                />
              </TailwindFlex>
              {memberDetails?.birthdate2?.length && (
                <TailwindFlex space='space-x-2'>
                  <input
                    type='text'
                    placeholder='MM'
                    className='w-16 p-2 border rounded'
                    value={memberDetails?.birthdate2[1]}
                    disabled
                  />
                  <input
                    type='text'
                    placeholder='DD'
                    className='w-16 p-2 border rounded'
                    value={memberDetails?.birthdate2[2]}
                    disabled
                  />
                  <input
                    type='text'
                    placeholder='YYYY'
                    className='w-20 p-2 border rounded'
                    value={memberDetails?.birthdate2[0]}
                    disabled
                  />
                </TailwindFlex>
              )}
            </TailwindFlex>
          </TailwindFlex>
        </TailwindFlex>

        <TailwindFlex
          alignItems='items-center'
          justifyContent='justify-start'
          gap='gap-5'>
          <TailwindInputBase
            name={"checkbox"}
            type={TailwindInputType.checkbox}
            cursor={"cursor-pointer"}
            width={"w-8"}
            height={"h-8"}
            psuedoClasses={["focus:outline-none"]}
            onChange={() => setIsCountryChecked(!isCountryChecked)}
            checked={isCountryChecked}
          />
          <TailwindFlex gap='gap-4'>
            <input
              type='text'
              placeholder='Country'
              className='w-20 p-2 border rounded'
              value={country}
              style={{
                width: "max-content",
              }}
              disabled
            />
            <input
              type='text'
              placeholder='State'
              className='w-20 p-2 border rounded'
              value={state}
              style={{
                width: "max-content",
              }}
              disabled
            />
          </TailwindFlex>
        </TailwindFlex>

        <TailwindFlex
          alignItems='items-center'
          justifyContent='justify-start'
          gap='gap-5'>
          <TailwindInputBase
            name={"checkbox"}
            type={TailwindInputType.checkbox}
            cursor={"cursor-pointer"}
            width={"w-8"}
            height={"h-8"}
            psuedoClasses={["focus:outline-none"]}
            onChange={() => setIsPhotoChecked(!isPhotoChecked)}
            checked={isPhotoChecked}
          />
          <TailwindFlex
            width='w-32'
            height='h-32'
            borderWidth={["border-2"]}
            borderColor='border-gray-300'
            borderRadius={"rounded"}>
            {photoUrl && (
              <img
                src={photoUrl}
                alt='User'
                className='w-full h-full object-cover'
                onClick={() => {
                  window.open(photoUrl);
                }}
              />
            )}
          </TailwindFlex>
        </TailwindFlex>

        <TailwindFlex gap='gap-4' flexWrap='flex-wrap'>
          <TailwindButton
            fullWidth={false}
            cursor='cursor-pointer'
            padding={["px-4", "py-3"]}
            backgroundColor={"bg-black"}
            borderRadius={"rounded-lg"}
            textColor='text-white'
            display={"flex"}
            alignItems={"items-center"}
            justifyContent={"justify-center"}
            onClick={deny}>
            Ban
          </TailwindButton>

          <Popover
            placement='bottom'
            onHide={() => {
              setIsSnoozeCollapsed(true);
            }}
            onShow={() => {
              setIsSnoozeCollapsed(false);
            }}
            show={!isSnoozeCollapsed}
            content={
              <TailwindFlex
                padding={["px-4", "py-3"]}
                margin={["mt-0.5"]}
                flexDirection='flex-col'
                backgroundColor={"bg-black"}
                borderRadius={"rounded-lg"}
                cursor='cursor-pointer'
                textColor='text-white'
                display={"flex"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                gap='gap-2'
                style={{
                  minWidth: "170px",
                }}>
                {snoozeValues.map(item => (
                  <TailwindFlex
                    key={item.label}
                    onClick={() => {
                      snooze(item.value);
                      setIsSnoozeCollapsed(true);
                    }}
                    fontWeight='font-bold'>
                    {item.label}
                  </TailwindFlex>
                ))}
              </TailwindFlex>
            }>
            <TailwindButton
              cursor='cursor-pointer'
              padding={["px-4", "py-3"]}
              backgroundColor={"bg-black"}
              borderRadius={"rounded-lg"}
              textColor='text-white'
              display={"flex"}
              alignItems={"items-center"}
              justifyContent={"justify-start"}
              textProps={{
                width: "w-full",
              }}
              rightIconProps={{
                name: isSnoozeCollapsed
                  ? "keyboard_arrow_down"
                  : "keyboard_arrow_up",
                fontSize: "text-lg",
              }}
              style={{
                minWidth: "170px",
              }}>
              <TailwindFlex>Snooze for</TailwindFlex>
            </TailwindButton>
          </Popover>

          <TailwindButton
            padding={["px-4", "py-3"]}
            fullWidth={false}
            flexGrow='flex-grow'
            backgroundColor='bg-pink-500'
            textColor='text-white'
            cursor='cursor-pointer'
            borderRadius={"rounded-lg"}
            justifyContent='justify-center'
            onClick={() => submit()}
            disabled={isVerified}>
            {isVerified ? "Completed" : "Complete"}
          </TailwindButton>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default inject(
  "authStore",
  "nodeChatStore",
  "broadcastStore"
)(observer(BroadcastMemberVerifyPanel));
