import React, { useEffect } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import { SharedMediaTabArray } from "./consts";
import TailwindFlex from "library/components/_tailwind/flex";
import { SharedMediaTab } from "../stores/messages/types";
import MessageStore from "../stores/messages/MessageStore";
import TailwindPill from "library/components/_tailwind/pill";

type MCSharedMediaModalTabBarProps = {
  messageStore?: MessageStore;
  isMobile?: boolean;
};

const MCSharedMediaModalTabBar: React.FunctionComponent<
  MCSharedMediaModalTabBarProps & WrappedComponentProps
> = ({ messageStore, isMobile, intl }) => {
  const { activeMCSharedMediaModalTab, setActiveMCSharedMediaModalTab } =
    messageStore!;

  useEffect(() => {
    return () => {
      setActiveMCSharedMediaModalTab("all" as SharedMediaTab);
    };
  }, []);

  return (
    <TailwindFlex
      className={["SharedMediaModal__tab-bar"]}
      alignItems={"items-center"}
      justifyContent={isMobile ? "justify-center" : "justify-start"}
      padding={["pt-3", "pb-3", "pl-6", "pr-6"]}
      borderStyle={"border-solid"}
      borderColor={"border-highlight-color"}
      borderWidth={["border-b-2"]}>
      {SharedMediaTabArray.map(tab => {
        return (
          <TailwindFlex
            key={tab.name}
            justifyContent={"justify-center"}
            alignItems={"items-center"}
            width={isMobile ? "w-full" : "w-auto"}>
            <TailwindFlex
              width={"w-auto"}
              justifyContent={"justify-center"}
              alignItems={"items-center"}
              onClick={() => {
                setActiveMCSharedMediaModalTab(tab.name);
              }}>
              <TailwindPill
                backgroundColor={
                  activeMCSharedMediaModalTab === tab.name
                    ? "bg-highlight-color"
                    : "bg-quaternary-bg-color"
                }
                size={"sm"}
                style={{
                  padding: "13px 15px",
                  margin: "0 10px 0 0"
                }}
                leftIconProps={{
                  name: activeMCSharedMediaModalTab === tab.name ? tab.icon2 : tab.icon,
                }}
                textProps={{
                  textTransform: "capitalize",
                  fontWeight:
                    activeMCSharedMediaModalTab === tab.name
                      ? "font-bold"
                      : "font-normal",
                }}>
                {intl.formatMessage({
                  id: `message-center.${tab.name}`,
                  defaultMessage: tab.name.toString(),
                })}
              </TailwindPill>
            </TailwindFlex>
          </TailwindFlex>
        );
      })}
    </TailwindFlex>
  );
};

export default injectIntl(
  inject("messageStore")(observer(MCSharedMediaModalTabBar))
);
