import React from "react";
import "./styles.scss";
import classnames from "classnames";

type VerticalDotsIconProps = {
  className?: string;
};

const VerticalDotsIcon: React.ComponentType<VerticalDotsIconProps> = ({
  className,
}) => (
  <span className={classnames("VerticalDotsIcon", className && className)}>
    <svg focusable="false" viewBox="0 0 24 24">
      <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </svg>
  </span>
);

export default VerticalDotsIcon;
