import React, { useMemo } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import SearchBox from "library/components/_tailwind/search-box";
import config from "core/config";
// import TailwindBox from "library/components/_tailwind/box";
import TailwindParagraph from "library/components/_tailwind/paragraph";
import TailwindCard from "library/components/_tailwind/card";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import { snackbarStore } from "library/core/stores/snackbar/SnackbarStore";
import CopyToClipboard from "library/core/utility/copy-to-clipboard";
import { dateProcess } from "library/core/utility";
import { MessageDescriptor } from "react-intl";
import { ReferredUser } from "containers/model/referrals/stores/referrals/types";
import { TPadding, TPseudoClasses } from "library/styles/tailwind-classnames";
import TailwindBox from "library/components/_tailwind/box";
import TailwindSpinner from "library/components/_tailwind/spinner";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";

type Props = {
  type: "model" | "member";
  modelUsername: string;
  referrals: ReferredUser[];
  heading: MessageDescriptor;
  subHeading: React.ReactNode;
  emptyText: MessageDescriptor;
  tableHeadColumn1Title: MessageDescriptor;
  tableHeadColumn2Title: MessageDescriptor;
  tableHeadColumn3Title: MessageDescriptor;
  referralLinkTitleClass?: string;
  isLoading: boolean;
  padding?: TPadding[];
  psuedoClasses?: TPseudoClasses[];
};

const copyToClipboard = new CopyToClipboard();

const CamsModelReferralsColumn: React.ComponentType<Props> = ({
  type,
  modelUsername,
  referrals,
  heading,
  subHeading,
  emptyText,
  tableHeadColumn1Title,
  tableHeadColumn2Title,
  tableHeadColumn3Title,
  referralLinkTitleClass,
  isLoading,
  padding,
  psuedoClasses,
}) => {
  const { formatDate } = dateProcess;

  const copyElId = `referral-url-copy-box-${type}`;

  const referralURL = useMemo(() => {
    if (type === "member") {
      return `${config.membersUrl}/reg/${modelUsername}`;
      //return `${config.membersUrl}/${modelUsername}`; // the new reg URL that is not decided yet
    } else {
      return `${window.location.origin}/friend/${modelUsername}`;
    }
  }, [type, modelUsername]);

  return (
    <TailwindFlex
      height={"h-full"}
      flexDirection={"flex-col"}
      alignItems={"items-start"}
      padding={padding}
      pseudoClasses={psuedoClasses}>
      <TailwindFlex
        flexWrap={"flex-wrap"}
        height={"h-28"}
        pseudoClasses={["2xl:h-36", "xl:h-40", "md:h-auto"]}>
        <TailwindFlex width={"w-auto"} md={"w-full"}>
          <TailwindParagraph>{subHeading}</TailwindParagraph>
        </TailwindFlex>
      </TailwindFlex>
      <TailwindFlex flexDirection={"flex-col"} flexGrow={"flex-grow"}>
        <TailwindFlex
          padding={["py-4"]}
          flexDirection={"flex-row"}
          pseudoClasses={["xl:flex-col"]}>
          <TailwindFlex
            width={"w-auto"}
            pseudoClasses={["xl:w-full", "md:mb-2"]}
            className={[referralLinkTitleClass]}>
            <TailwindTranslatedText
              headingProps={{
                level: 6,
                margin: ["my-auto", "mr-4"],
                whiteSpace: "whitespace-nowrap",
              }}
              descriptor={heading}
            />
          </TailwindFlex>
          <SearchBox
            inputProps={{
              readOnly: true,
              value: referralURL,
              id: copyElId,
              backgroundColor: "bg-transparent",
            }}
            buttonProps={{
              children: "Copy Link",
              onClick: () => {
                copyToClipboard.copy(copyElId);
                snackbarStore!.enqueueSnackbar({
                  message: {
                    id: "admirers.referrals.myReferralLink.memberLinkCopy",
                    default: "LINK COPIED!",
                  },
                  closeButton: true,
                  variant: SnackbarVariants.SUCCESS,
                });
              },
            }}
          />
        </TailwindFlex>
        <TailwindCard
          width={"w-full"}
          display={"flex"}
          flexDirection={"flex-col"}
          alignItems={"items-center"}
          height={"h-full"}>
          {isLoading && (
            <TailwindFlex
              height={"h-full"}
              alignItems={"items-center"}
              justifyContent={"justify-center"}>
              <TailwindSpinner />
            </TailwindFlex>
          )}
          {!isLoading && referrals && referrals.length === 0 && (
            <TailwindFlex
              height={"h-2/3"}
              alignItems={"items-center"}
              justifyContent={"justify-center"}>
              <TailwindTranslatedText
                fontSize={"text-lg"}
                descriptor={{
                  id: emptyText.id,
                  defaultMessage: emptyText.defaultMessage,
                }}
              />
            </TailwindFlex>
          )}
          {!isLoading && referrals?.length > 0 && (
            <TailwindBox
              backgroundColor={"bg-primary-bg-color"}
              width={"w-full"}
              borderRadius={"rounded-xl"}>
              <TailwindFlex
                backgroundColor={"bg-table-primary-bg-color"}
                padding={["p-3"]}
                height={"h-12"}
                borderRadius={"rounded-t-xl"}
                justifyContent={"justify-center"}
                alignItems={"items-center"}>
                <TailwindBox width={"w-4/12"} padding={["p-2"]}>
                  <TailwindTranslatedText
                    fontWeight='font-bold'
                    descriptor={tableHeadColumn1Title}
                  />
                </TailwindBox>
                <TailwindBox width={"w-4/12"} padding={["p-2"]}>
                  <TailwindTranslatedText
                    fontWeight='font-bold'
                    descriptor={tableHeadColumn2Title}
                  />
                </TailwindBox>
                <TailwindBox
                  justifyContent={"justify-end"}
                  alignItems={"items-center"}
                  width={"w-4/12"}
                  padding={["p-2"]}
                  textAlign={"text-right"}>
                  <TailwindTranslatedText
                    fontWeight='font-bold'
                    descriptor={tableHeadColumn3Title}
                  />
                </TailwindBox>
              </TailwindFlex>
              {referrals
                .sort(
                  (a, b) =>
                    (b.earnings?.earning_tokens || 0) -
                    (a.earnings?.earning_tokens || 0)
                )
                .map((referral, index) => (
                  <TailwindFlex
                    key={`model-referral-${type}-joined-${index}`}
                    padding={["p-3"]}
                    height={"h-16"}
                    justifyContent={"justify-center"}
                    alignItems={"items-center"}
                    borderColor={"border-table-secondary-color"}
                    borderStyle={"border-solid"}
                    borderWidth={[
                      index < referrals.length - 1 ? "border-b" : "border-0",
                    ]}>
                    <TailwindBox width={"w-4/12"} padding={["p-2"]}>
                      {referral.username}
                    </TailwindBox>
                    <TailwindBox width={"w-4/12"} padding={["p-2"]}>
                      {referral.join_date ? (
                        formatDate(referral.join_date)
                      ) : type === "model" ? (
                        <TailwindTranslatedText
                          descriptor={{
                            id: "verbiage.pending-review",
                            defaultMessage: "Pending Review",
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </TailwindBox>
                    <TailwindBox
                      width={"w-4/12"}
                      padding={["p-2"]}
                      textAlign={"text-right"}>
                      {referral.earnings?.earning_tokens || 0}
                    </TailwindBox>
                  </TailwindFlex>
                ))}
            </TailwindBox>
          )}
        </TailwindCard>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default CamsModelReferralsColumn;
