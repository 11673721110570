import React from "react";
import classnames from "classnames";

type Props = {
  className?: string;
};

const ProfileSilhouetteIcon: React.ComponentType<Props> = ({ className }) => {
  return (
    <span
      className={classnames("ProfileSilhouetteIcon", className && className)}
    >
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 71.91 70.38"
      >
        <path
          className="cls-1"
          d="M.05,65.78c8.44-9.25,19.07-13.6,31.47-14,9.11-.26,18.14-.2,26.64,3.94,5.23,2.54,10.15,5.37,13.79,10v6c-1.76,1.63-4,1.49-6.11,1.49q-29.83,0-59.68,0c-2.16,0-4.35.14-6.11-1.49Z"
          transform="translate(-0.05 -2.88)"
        />
        <path
          className="cls-2"
          d="M51.9,23.29c0,12.15-3.34,17.71-12,20C31.48,45.51,22.25,39.54,21.08,31a61.08,61.08,0,0,1-.15-13.85C21.71,8.63,28.07,2.8,36.4,2.88a15.57,15.57,0,0,1,15.48,15C52,19.65,51.9,21.47,51.9,23.29Z"
          transform="translate(-0.05 -2.88)"
        />
      </svg>
    </span>
  );
};

export default ProfileSilhouetteIcon;
