import React, { useMemo } from "react";
import TailwindInputBase, { TailwindInputBaseProps } from "../input-base";
import {
  TBackgroundColor,
  TBackgroundOpacity,
  TBorderColor,
  TBorderRadius,
  TBorderWidth,
  TPlaceholderColor,
  TTextColor,
} from "../../../styles/tailwind-classnames";
import TailwindText, { TailwindTextProps } from "../text";
import TailwindFlex, { TailwindFlexProps } from "../flex";
import { inject, observer } from "mobx-react";
import ThemeStore from "../../../core/stores/theme/ThemeStore";
import { TailwindCardBackgroundColor } from "../card";

type TailwindInputVariant = "underlined" | "contained";

export type TailwindInputBackgroundColor =
  | "primary"
  | "secondary"
  | "transparent"
  | TBackgroundColor;

export type TailwindInputProps = Omit<
  TailwindInputBaseProps,
  "backgroundColor" | "ref"
> & {
  "data-testid"?: string;
  className?: (string | undefined | boolean)[];
  backgroundOpacity?: TBackgroundOpacity;
  backgroundColor?: TailwindInputBackgroundColor;
  borderColor?: TBorderColor;
  error?: string;
  errorProps?: TailwindTextProps;
  warning?: string;
  warningProps?: TailwindTextProps;
  sublabel?: string;
  sublabelProps?: TailwindTextProps;
  flexProps?: TailwindFlexProps;
  variant?: TailwindInputVariant;
  rounded?: boolean;
  label?: string;
  labelProps?: TailwindTextProps;
  themeStore?: ThemeStore;
  borderWidth?: TBorderWidth[];
  fullHeight?: boolean;
  disabled?: boolean;
  disabledProps?: {
    textColor?: TTextColor;
    backgroundColor?: TBackgroundColor;
  };
};
const TailwindInput: React.ComponentType<TailwindInputProps> = ({
  value,
  onChange,
  leftIconProps,
  rightIconProps,
  placeholder,
  className = [],
  backgroundColor,
  backgroundOpacity,
  error,
  errorProps,
  warning,
  warningProps,
  sublabel,
  sublabelProps,
  boxProps,
  flexProps,
  variant = "contained",
  rounded = false,
  label,
  labelProps,
  themeStore,
  borderRadius,
  borderColor,
  borderWidth,
  placeholderColor,
  fullHeight,
  wrapperProps,
  name,
  disabled,
  disabledProps,
  ...props
}) => {
  const { currentThemePalette } = themeStore!;

  const variantProps = useMemo(() => {
    const _props: {
      borderRadius: TBorderRadius | undefined;
      borderWidth: TBorderWidth[];
    } = {
      borderRadius: rounded
        ? borderRadius || "rounded-full"
        : currentThemePalette?.["input-border-radius-tw-class"],
      borderWidth: borderWidth || [],
    };

    if (variant === "underlined") {
      _props.borderRadius = "rounded-none";
      _props.borderWidth = ["border-b"];
    }

    return _props;
  }, [variant, error, warning, rounded, borderWidth]);

  const themeColors = useMemo(() => {
    const colors: {
      backgroundColor: TBackgroundColor;
      textColor: TTextColor;
      errorTextColor?: TTextColor;
      warningTextColor?: TTextColor;
      borderColor?: TBorderColor;
      placeholderColor?: TPlaceholderColor;
    } = {
      backgroundColor: "bg-white",
      textColor: labelProps?.textColor || "text-main-color",
      errorTextColor: error
        ? currentThemePalette?.["input-error-text-color-tw-class"]
        : undefined,
      warningTextColor: warning
        ? currentThemePalette?.["input-warning-text-color-tw-class"]
        : undefined,
      placeholderColor: "placeholder-gray-400",
    };
    if (error) {
      colors.borderColor =
        currentThemePalette?.["input-error-border-color-tw-class"];
    } else if (warning) {
      colors.borderColor =
        currentThemePalette?.["input-warning-border-color-tw-class"];
    } else {
      colors.borderColor = borderColor || undefined;
    }

    if (disabled) {
      const backgroundClass =
        currentThemePalette?.["input-disabled-background-color-tw-class"];

      const textColorClass =
        currentThemePalette?.["input-disabled-text-color-tw-class"];

      if (backgroundClass) {
        colors.backgroundColor =
          disabledProps?.backgroundColor || backgroundClass;
      }
      if (textColorClass) {
        colors.textColor = disabledProps?.textColor || textColorClass;
      }
    } else {
      switch (backgroundColor as TailwindCardBackgroundColor) {
        case "primary": {
          const backgroundClass =
            currentThemePalette?.["input-primary-bg-color-tw-class"];
          if (backgroundClass) {
            colors.backgroundColor = backgroundClass;
          }
          const textColorClass =
            currentThemePalette?.["input-primary-text-color-tw-class"];
          if (textColorClass) {
            colors.textColor = textColorClass;
          }
          const placeholderColorClass =
            currentThemePalette?.["input-primary-placeholder-color-tw-class"];
          if (placeholderColorClass) {
            colors.placeholderColor = placeholderColorClass;
          }
          break;
        }
        case "secondary": {
          const backgroundClass =
            currentThemePalette?.["input-secondary-bg-color-tw-class"];
          if (backgroundClass) {
            colors.backgroundColor = backgroundClass;
          }
          const textColorClass =
            currentThemePalette?.["input-secondary-text-color-tw-class"];
          if (textColorClass) {
            colors.textColor = textColorClass;
          }
          const placeholderColorClass =
            currentThemePalette?.["input-secondary-placeholder-color-tw-class"];
          if (placeholderColorClass) {
            colors.placeholderColor = placeholderColorClass;
          }
          break;
        }
        case "transparent": {
          colors.backgroundColor = "bg-transparent";
          break;
        }
        default: {
          colors.backgroundColor = backgroundColor as TBackgroundColor;
          break;
        }
      }
    }

    if (variant === "underlined") {
      colors.backgroundColor = "bg-transparent";
    }

    /*
    const borderBackgroundColorClass = currentThemePalette?.["input-error-border-color-tw-class"];
    if (borderBackgroundColorClass && error) {
      colors.borderColor = borderBackgroundColorClass;
    }
    */

    return colors;
  }, [
    backgroundColor,
    labelProps,
    variant,
    error,
    warning,
    currentThemePalette,
    borderColor,
    disabled,
  ]);

  return (
    <TailwindFlex
      flexDirection={"flex-col"}
      className={className}
      {...flexProps}>
      {label && (
        <TailwindFlex>
          <TailwindText
            {...labelProps}
            as={"label"}
            htmlFor={name}
            margin={[...(labelProps?.margin || ["mb-1"])]}
            fontSize={labelProps?.fontSize || "text-base"}
            display={"flex"}
            textColor={
              themeColors.errorTextColor ||
              themeColors.warningTextColor ||
              labelProps?.textColor ||
              "text-main-color"
            }
            fontWeight={"font-bold"}>
            {label}
          </TailwindText>
        </TailwindFlex>
      )}
      <TailwindInputBase
        {...props}
        name={name}
        wrapInput
        boxProps={{
          display: "flex",
          alignItems: "items-center",
          justifyContent: "justify-start",
          backgroundColor:
          boxProps?.backgroundColor || themeColors.backgroundColor,
          backgroundOpacity,
          borderRadius: boxProps?.borderRadius || variantProps.borderRadius,
          borderWidth: boxProps?.borderWidth || variantProps.borderWidth,
          borderColor: boxProps?.borderColor || themeColors.borderColor,
          textColor:
          themeColors.errorTextColor ||
          themeColors.textColor ||
          themeColors.textColor,
          overflow: "overflow-hidden",
          height: boxProps?.height || (fullHeight && "h-full") || "h-10",
          padding: ["px-3", "py-0"],
          as: "div",
          ...boxProps,
        }}
        flexGrow={"flex-grow"}
        placeholderColor={placeholderColor || themeColors.placeholderColor}
        psuedoClasses={["focus:outline-none"]}
        placeholder={placeholder}
        leftIconProps={leftIconProps}
        rightIconProps={rightIconProps}
        onChange={onChange}
        value={value}
        wrapperProps={{
          ...wrapperProps,
          height: (fullHeight && "h-full") || wrapperProps?.height,
        }}
        disabled={disabled}
        data-is-enabled={!disabled}
      />
      {(error || warning || sublabel) && (
        <TailwindText
          {...errorProps}
          textColor={
            error
              ? errorProps?.textColor || themeColors.errorTextColor
              : warning
              ? warningProps?.textColor || themeColors.textColor
              : sublabelProps?.textColor || themeColors.textColor
          }
          fontSize={"text-sm"}
          margin={["ml-2"]}>
          {error ? error : warning ? warning : sublabel}
        </TailwindText>
      )}
    </TailwindFlex>
  );
};

export default inject("themeStore")(observer(TailwindInput));
