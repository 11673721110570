import TailwindFlex from "library/components/_tailwind/flex";
import BroadcastControlButtons from "common/broadcast/broadcast-control/broadcast-control-buttons";
import TailwindCard from "library/components/_tailwind/card";
import React from "react";
import BroadcastShowInfoAccordions from "common/broadcast/broadcast-control/broadcast-show-info-accordions";
import { ShowTab } from "../_stores/broadcast/enums";

type Props = {
  value?: number;
  index?: number;
  allowScroll: boolean;
  showTab: ShowTab;
} & React.HTMLAttributes<HTMLDivElement>;

const BroadcastControlTab: React.FC<Props> = ({
  children,
  value,
  index,
  allowScroll,
  showTab,
  ...other
}) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}>
      {value === index && (
        <TailwindFlex flexDirection='flex-col' width={"w-auto"}>
          <BroadcastShowInfoAccordions showTab={showTab} />
          <TailwindFlex flexDirection='flex-col' alignItems='items-center'>
            <BroadcastControlButtons />
          </TailwindFlex>
          <TailwindCard
            width={"w-full"}
            maxWidth={"max-w-full"}
            flexGrow='flex-grow'
            padding={["p-4"]}
            overflow={allowScroll ? "overflow-y-auto" : "overflow-visible"}>
            {children}
          </TailwindCard>
        </TailwindFlex>
      )}
    </div>
  );
};

export default BroadcastControlTab;
