import React from "react";
import TailwindFlex from "../flex";
import FeedHeaderProfileDetails, {
  FeedHeaderProfileDetailsProps,
} from "./feed-header-profile-details";
import FeedHeaderActivityDetails, {
  FeedHeaderActivityDetailsProps,
} from "./feed-header-activity-details";

export type FeedHeaderProps = {
  profileDetails?: FeedHeaderProfileDetailsProps;
  activityDetails?: FeedHeaderActivityDetailsProps;
};

const FeedHeader: React.ComponentType<FeedHeaderProps> = ({
  profileDetails,
  activityDetails,
}) => {
  return (
    <TailwindFlex flexDirection={"flex-col"}>
      {activityDetails && <FeedHeaderActivityDetails {...activityDetails} />}
      {profileDetails && <FeedHeaderProfileDetails {...profileDetails} />}
    </TailwindFlex>
  );
};

export default FeedHeader;
