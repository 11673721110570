import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import DashboardNotification from "containers/_layout/dashboard/dashboard-notification";
import { inject, observer } from "mobx-react";
import MessageStore from "common/messages/stores/messages/MessageStore";

type Props = {
  messageStore?: MessageStore;
};

const DashboardNotificationsWrapper: React.ComponentType<Props> = ({
  messageStore,
}) => {
  const { notificationsThatAreNotDismissed } = messageStore!;

  if (!notificationsThatAreNotDismissed.length) {
    return null;
  }
  return (
    <TailwindFlex flexDirection={"flex-col"}>
      {notificationsThatAreNotDismissed.map(notification => (
        <DashboardNotification
          id={notification.id}
          key={notification.id}
          type={notification.type}
          content={notification.content}
          onClickReadMore={notification.onClickReadMore}
          onClickDismiss={notification.onClickDismiss}
          isDismissable={notification.isDismissable}
        />
      ))}
    </TailwindFlex>
  );
};

export default inject("messageStore")(observer(DashboardNotificationsWrapper));
