import React, { FunctionComponent } from "react";
import TailwindParagraph from "library/components/_tailwind/paragraph";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindAnchor from "library/components/_tailwind/anchor";

const InternationalDirections: FunctionComponent = ({}) => {
  return (
    <>
      <TailwindParagraph margin={["mt-2"]} textColor={"text-red-600"} fontWeight={"font-bold"}>
        <TailwindTranslatedText
          descriptor={{
            id: "uploadTaxFormModal.internationalDelayedIfWeDontReceiveW8",
            defaultMessage: "International Payment may be withheld or delayed if we do not receive a W-8.",
          }}
        />
      </TailwindParagraph>
      <TailwindParagraph>
        <TailwindTranslatedText
          descriptor={{
            id: "uploadTaxFormModal.w8MustBeCompleted",
            defaultMessage: "The W-8 form must be completed even if you do not have models working in the U.S.",
          }}
        />
      </TailwindParagraph>
      <TailwindParagraph>
        <TailwindTranslatedText
          descriptor={{
            id: "uploadTaxFormModal.pleasePrintW8",
            defaultMessage: "Please print, complete, scan, and upload a W-8 form.",
          }}
        />
        <TailwindAnchor
          display={"inline-block"}
          to={"https://apps.irs.gov/app/picklist/list/formsInstructions.html?value=w-8&criteria=formNumber"}>
          <TailwindTranslatedText
            fontWeight={"font-bold"}
            descriptor={{
              id: "uploadTaxFormModal.clickForForm",
              defaultMessage: "Click here for the form.",
            }}
          />
        </TailwindAnchor>
      </TailwindParagraph>
    </>
  );
};

export default InternationalDirections;
