import React from "react";
import { inject, observer } from "mobx-react";
import TailwindFlex from "library/components/_tailwind/flex";
import ModelsTableHead from "./model-table-head";
import ModelsTableBody from "./model-table-body";
import ModelStore from "containers/studio/studio-models/stores/models/ModelStore";

type Props = {
  modelStore?: ModelStore;
};

const ModelTable: React.ComponentType<Props> = ({ modelStore }) => {
  const {
    //models,
    //isLoadingModels
  } = modelStore!;
  return (
    <React.Fragment>
      <TailwindFlex
        width={"w-full"}
        height={"h-full"}
        overflow={["overflow-y-hidden", "overflow-x-auto"]}
        className={["bg-gray-100"]}
        flexDirection={"flex-col"}
        borderRadius={"rounded-xl"}>
        <TailwindFlex
          minWidth={"min-w-280"}
          width={"w-full"}
          flexDirection={"flex-col"}
          height={"h-full"}>
          <ModelsTableHead />
          <ModelsTableBody />
          {/*
          in order to use pagination we need to make changes on API regarding response structure
          {!isLoadingModels && (
            <TailwindPaginationBarWithDropdown
              resultCount={models.length}
              label={"Models"}
            />
          )}*/}
        </TailwindFlex>
      </TailwindFlex>
    </React.Fragment>
  );
};

export default inject("layoutStore", "modelStore")(observer(ModelTable));
