import React from "react";
import "./styles.scss";
import classnames from "classnames";

type SharedMediaIconAltProps = {
  className?: string;
};

const SharedMediaIconAlt: React.ComponentType<SharedMediaIconAltProps> = ({
  className,
}) => (
  <span className={classnames("SharedMediaIconAlt", className && className)}>
    <svg 
      version="1.2" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlnsXlink="http://www.w3.org/1999/xlink" 
      overflow="visible" 
      preserveAspectRatio="none" 
      viewBox="0 0 16 17.000000000000004" 
      width="16" 
      height="17.000000000000004">
        <g transform="translate(0, 0)">
          <g transform="translate(0, -0.000010000000000065512) rotate(0)">
            <path 
              d="M14.48,6.06573h-2.72v-2.31455c0,-2.07512 -1.68,-3.75117 -3.76,-3.75117c-2.08,0 -3.76,1.67606 -3.76,3.75117v2.31455h-2.72c-0.8,0 -1.52,0.6385 -1.52,1.35681v8.14085c0,0.79812 0.72,1.43662 1.52,1.43662h12.96c0.8,0 1.52,-0.6385 1.52,-1.43662v-8.14085c0,-0.71831 -0.64,-1.35681 -1.52,-1.35681zM5.44,3.75117c0,-1.43662 1.12,-2.55399 2.56,-2.55399c1.44,0 2.56,1.11737 2.56,2.55399v2.31455h-5.12zM8.72,12.21127v1.11737c0,0.31925 -0.24,0.6385 -0.64,0.6385h-0.16c-0.32,0 -0.64,-0.23944 -0.64,-0.6385v-1.11737c-0.48,-0.23944 -0.8,-0.71831 -0.8,-1.35681c0,-0.87793 0.72,-1.51643 1.52,-1.51643c0.8,0 1.52,0.71831 1.52,1.51643c0,0.55869 -0.32,1.11737 -0.8,1.35681z" 
              vectorEffect="non-scaling-stroke"/>
          </g>
          <defs>
            <path 
              id="path-171503115829834441" 
              d="M14.48,6.06573h-2.72v-2.31455c0,-2.07512 -1.68,-3.75117 -3.76,-3.75117c-2.08,0 -3.76,1.67606 -3.76,3.75117v2.31455h-2.72c-0.8,0 -1.52,0.6385 -1.52,1.35681v8.14085c0,0.79812 0.72,1.43662 1.52,1.43662h12.96c0.8,0 1.52,-0.6385 1.52,-1.43662v-8.14085c0,-0.71831 -0.64,-1.35681 -1.52,-1.35681zM5.44,3.75117c0,-1.43662 1.12,-2.55399 2.56,-2.55399c1.44,0 2.56,1.11737 2.56,2.55399v2.31455h-5.12zM8.72,12.21127v1.11737c0,0.31925 -0.24,0.6385 -0.64,0.6385h-0.16c-0.32,0 -0.64,-0.23944 -0.64,-0.6385v-1.11737c-0.48,-0.23944 -0.8,-0.71831 -0.8,-1.35681c0,-0.87793 0.72,-1.51643 1.52,-1.51643c0.8,0 1.52,0.71831 1.52,1.51643c0,0.55869 -0.32,1.11737 -0.8,1.35681z" 
              vectorEffect="non-scaling-stroke"/>
          </defs>
        </g>
    </svg>
  </span>
);

export default SharedMediaIconAlt;
