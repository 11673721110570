import React, { FunctionComponent } from "react";
import TailwindParagraph from "library/components/_tailwind/paragraph";
import TailwindAnchor from "library/components/_tailwind/anchor";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import { injectIntl, WrappedComponentProps } from "react-intl";
import TailwindText from "library/components/_tailwind/text";

const DomesticDirections: FunctionComponent<WrappedComponentProps> = ({ intl }) => {
  return (
    <>
      <TailwindParagraph margin={["mt-2"]} textColor={"text-red-600"} fontWeight={"font-bold"}>
        <TailwindTranslatedText
          descriptor={{
            id: "uploadTaxFormModal.wontReceivePaymentUntilW9Below",
            defaultMessage: "You won't receive payment until you complete the W-9 form below.",
          }}
        />
      </TailwindParagraph>
      <TailwindParagraph margin={["mt-2"]}>
        <TailwindTranslatedText
          descriptor={{
            id: "uploadTaxFormModal.inividualsMustCompleteW9",
            defaultMessage: "U.S. individuals must complete and return a W-9 Form.",
          }}
        />
      </TailwindParagraph>
      <TailwindParagraph>
        <TailwindText
          margin={["mr-1"]}
          display={"inline-block"}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              {
                id: "uploadTaxFormModal.printCompleteScanW9",
                defaultMessage:
                  'Please print, complete, scan, and upload the <a class="{class_names}" href="{href}">W-9 Form.</a>',
              },
              {
                href:
                  "https://apps.irs.gov/app/picklist/list/formsInstructions.html;jsessionid=amdjbsl9YkkmN-S9F4f49-Qf.20?value=w-9&criteria=formNumber&submitSearch=Find",
                class_names: "font-bold underline",
              }
            ),
          }}
        />
        <TailwindAnchor
          display={"inline-block"}
          to={`https://secureimage.securedataimages.com/images/cams/models/W-9_example.jpg`}>
          <TailwindTranslatedText
            fontWeight={"font-bold"}
            descriptor={{
              id: "common.example",
              defaultMessage: "Example",
            }}
          />
        </TailwindAnchor>
      </TailwindParagraph>
    </>
  );
};

export default injectIntl(DomesticDirections);
