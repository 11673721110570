import React, { useCallback } from "react";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import PricingStore from "common/broadcast/_stores/pricing/PricingStore";
import NodeChatStore from "../_stores/nodechat/NodeChatStore";
import { inject, observer } from "mobx-react";
import {
  BROADCAST_CHAT_INPUT_MAX_CHARS,
} from "./chat-tab";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import ChatStore from "common/broadcast/_stores/chat/ChatStore";
import BroadcastChatInputOptions from "./chat-input-options";
import classnames from "classnames";
import tw from "library/styles/tailwind-classnames";
import TextareaAutosize from "react-textarea-autosize";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import { ChatModeEnum } from "../_stores/chat/enums";
import LanguageStore from "library/core/stores/language/LanguageStore";

type Props = {
  broadcastStore?: BroadcastStore;
  pricingStore?: PricingStore;
  nodeChatStore?: NodeChatStore;
  chatStore?: ChatStore;
  sendMessage: (message: string) => void;
  setShowLimitMessage: (isOverLimit: boolean) => void;
  languageStore?: LanguageStore;
  disableInput: boolean;
  inputRef: React.RefObject<HTMLTextAreaElement>;
};

const BroadcastChatInput: React.ComponentType<Props> =
  ({
    chatStore,
    languageStore,
    sendMessage,
    disableInput,
    inputRef,
    pricingStore,
    nodeChatStore,
  }) => {
    const { message, setMessage } = chatStore!;
    const {
      activeMemberToWhisper,
      toggleOffWhisperTo,
      sendMessage: whipserSendMessage,
      whisperHash,
      activeChatTab,
      setSaveNextMessageToQT,
      saveNextMessageToQT,
      quickTexts
    } = nodeChatStore!;
    const { modelProducts } = pricingStore!;
    const { intl } = languageStore!;

    const handleChatInputChange = event => {
      const value = event.target.value;
      setMessage(value);
    };

    const handleChatMessageSend = async (event?) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      if (message === "") {
        return;
      }
      const messageToSend = message.substring(
        0,
        BROADCAST_CHAT_INPUT_MAX_CHARS - 1
      );
      
      sendMessage(messageToSend);
      setMessage("");
    };

    const whipserSendMsg = useCallback(
      (event?) => {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }
        if (message === "") {
          return;
        }
        whipserSendMessage(
          message,
          ChatModeEnum.WHISPER,
          activeMemberToWhisper?.username,
          activeMemberToWhisper?.id
        );
        setMessage("");
      },
      [activeMemberToWhisper, message]
    );

    const onKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.keyCode === 13) {
          if (!event.shiftKey) {
            event.preventDefault();
            event.stopPropagation();

            !modelProducts.chat_settings.system_settings
              .whispers_in_separate_tabs && activeMemberToWhisper?.id
              ? whipserSendMsg()
              : handleChatMessageSend();
          } else {
            event.preventDefault();
            const start = event.currentTarget.selectionStart;
            const end = event.currentTarget.selectionEnd;
            setMessage(
              `${message.substring(0, start)}\n${message.substring(end)}`
            );
            event.currentTarget.selectionStart =
              event.currentTarget.selectionEnd = start + 1;
            event.currentTarget.scrollTop = event.currentTarget.scrollHeight;
          }

          return false;
        }
        return true;
      },
      [handleChatMessageSend, message, setMessage]
    );

    return (
      <TailwindFlex
        htmlElementProps={{
          onSubmit:
            !modelProducts.chat_settings.system_settings
              .whispers_in_separate_tabs && activeMemberToWhisper?.id
              ? whipserSendMsg
              : handleChatMessageSend,
        }}
        as={"form"}
        flexDirection={"flex-col"}
        width={"w-full"}
        alignItems={"items-end"}>
        <TailwindFlex
          flexDirection='flex-col'
          backgroundColor='bg-white'
          alignItems='items-end'
          padding={["pr-2"]}
          overflow='overflow-hidden'
          borderRadius={"rounded-xl"}
          borderColor={
            !modelProducts.chat_settings.system_settings
              .whispers_in_separate_tabs && !!activeMemberToWhisper
              ? "border-pink-600"
              : "border-transparent"
          }
          borderWidth={["border"]}>
          <TailwindFlex
            flexDirection="flex-row"
            alignItems="items-center"
            justifyContent="justify-between"
            width="w-full"
            padding={["px-4", "py-2"]}
          >
            <label className="flex items-center text-sm text-gray-600">
              <input
                type="checkbox"
                checked={saveNextMessageToQT}
                onChange={(e) => setSaveNextMessageToQT(e.target.checked)}
                className="mr-2"
                disabled={quickTexts.length >= 10}
              />
              {intl.formatMessage({
                id: "broadcast.chatInput.saveAsQuickText",
                defaultMessage: "Save as Quick Text",
              })}
            </label>
          </TailwindFlex>
          {!modelProducts.chat_settings.system_settings
            .whispers_in_separate_tabs && activeMemberToWhisper?.id ? (
            <div className='flex w-full'>
              <TailwindFlex
                backgroundColor='bg-pink-600'
                flex='flex-none'
                width='w-auto'
                padding={["px-3", "py-1"]}
                justifyContent='justify-center'
                alignItems='items-center'
                onClick={() => toggleOffWhisperTo(activeMemberToWhisper.id)}
                cursor={"cursor-pointer"}>
                <TailwindIcon
                  className='mr-1'
                  name={CustomIconName["private-message"]}
                  cursor={"cursor-pointer"}
                  textColor={"text-white"}
                  fontSize={"text-base"}
                />
                <span className='text-sm text-white'>
                  {activeMemberToWhisper.username}
                </span>
                <button className='ml-1'>
                  <TailwindIcon
                    name={"close"}
                    cursor={"cursor-pointer"}
                    textColor={"text-white"}
                    fontSize={"text-base"}
                  />
                </button>
              </TailwindFlex>
              <TextareaAutosize
                data-testid={"chat-input-field"}
                ref={inputRef}
                className={classnames(
                  "focus:outline-none",
                  "pretty-scroll",
                  tw(
                    "w-full",
                    "px-4",
                    "py-2",
                    "min-h-4",
                    "resize-none",
                    "text-black",
                    "bg-white",
                    "placeholder-gray-400"
                  )
                )}
                value={message}
                onChange={handleChatInputChange}
                onKeyDown={onKeyDown}
                data-is-enabled={!disableInput}
                disabled={disableInput}
                placeholder={
                  !disableInput
                    ? intl.formatMessage({
                        id: "broadcast.chatInputWhisperPlaceholder",
                        defaultMessage: "Type your whisper",
                      })
                    : intl.formatMessage({
                        id: "broadcast.chatInputOfflinePlaceholder",
                        defaultMessage: "Start broadcasting to chat",
                      })
                }
                rows={1}
                maxRows={3}
                maxLength={BROADCAST_CHAT_INPUT_MAX_CHARS}
              />
            </div>
          ) : (
            <TextareaAutosize
              data-testid={"chat-input-field"}
              ref={inputRef}
              className={classnames(
                "focus:outline-none",
                "pretty-scroll",
                tw(
                  "w-full",
                  "px-4",
                  "py-2",
                  "min-h-4",
                  "resize-none",
                  "text-black",
                  "bg-white",
                  "placeholder-gray-400"
                )
              )}
              value={message}
              onChange={handleChatInputChange}
              onKeyDown={onKeyDown}
              data-is-enabled={!disableInput}
              disabled={disableInput}
              placeholder={
                !disableInput &&
                !!whisperHash[activeChatTab] &&
                modelProducts.chat_settings.system_settings
                  .whispers_in_separate_tabs
                  ? intl.formatMessage({
                      id: "broadcast.chatInputWhisperPlaceholder",
                      defaultMessage: "Type your whisper",
                    })
                  : !disableInput
                  ? intl.formatMessage({
                      id: "broadcast.chatInputPlaceholder",
                      defaultMessage: "Type your message",
                    })
                  : intl.formatMessage({
                      id: "broadcast.chatInputOfflinePlaceholder",
                      defaultMessage: "Start broadcasting to chat",
                    })
              }
              rows={1}
              maxRows={3}
              maxLength={BROADCAST_CHAT_INPUT_MAX_CHARS}
            />
          )}
        </TailwindFlex>
        <TailwindFlex
          width='w-full'
          justifyContent='justify-between'
          margin={["mt-2"]}>
          <BroadcastChatInputOptions inputRef={inputRef} />
          <TailwindButton
            dataTestId={"chat-send-button"}
            fullWidth={false}
            rounded={false}
            padding={["px-8"]}
            height={"h-12"}
            backgroundColor={"primary"}
            type={"submit"}>
              {intl.formatMessage({
                id: "common.send",
                defaultMessage: "Send",
              })}
          </TailwindButton>
        </TailwindFlex>
      </TailwindFlex>
    );
  };

  export default inject(
    "broadcastStore",
    "chatStore",
    "pricingStore",
    "nodeChatStore",
    "languageStore"
  )(observer(BroadcastChatInput));
