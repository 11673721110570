import React, { useMemo } from "react";
import TailwindBox, { TailwindBoxProps } from "../box";
import { TBackgroundColor, THeight, TWidth } from "../../../styles/tailwind-classnames";

export type TailwindDividerProps = TailwindBoxProps & {
  thickness?: "0.5" | 1 | 2 | 3;
  color?: TBackgroundColor;
  orientation?: "vertical" | "horizontal";
};

const TailwindDivider: React.ComponentType<TailwindDividerProps> = ({
  thickness = 1,
  color = "bg-gray-500",
  opacity = "opacity-20",
  orientation = "horizontal",
  ...props
}) => {
  const orientationClasses = useMemo(() => {
    const classes: {
      width: TWidth;
      height: THeight;
    } = {
      width: "w-full",
      height: `h-${thickness}` as THeight,
    };

    switch (orientation) {
      case "horizontal": {
        classes.width = "w-full";
        classes.height = `h-${thickness}` as THeight;
        break;
      }
      case "vertical": {
        classes.width = `w-${thickness}` as TWidth;
        classes.height = "h-full";
      }
    }
    return classes;
  }, [thickness, orientation]);

  return (
    <TailwindBox
      {...props}
      display={"block"}
      width={orientationClasses?.width}
      height={orientationClasses?.height}
      opacity={opacity}
      backgroundColor={color}
    />
  );
};

export default TailwindDivider;
