import React from "react";
import TailwindAnchor, {
  TailwindAnchorProps,
} from "../../../components/_tailwind/anchor";

type Props = Omit<TailwindAnchorProps, "className"> & {};

const StorybookSidebarItem: React.ComponentType<Props> = ({
  children,
  ...props
}) => {
  return (
    <TailwindAnchor
      {...props}
      backgroundColor={"bg-gray-300"}
      margin={["mx-4", "my-1"]}
      padding={["px-4"]}
    >
      {children}
    </TailwindAnchor>
  );
};

export default StorybookSidebarItem;
