import {
  FILTER_MODELS_LOGIN_TIME,
  IStepsCompletionStatuses,
  IStudioModel,
  ModelCreateStep,
} from "./interfaces";

export const MODEL_APPROVAL_STATUSES = {
  INCOMPLETE: "Incomplete",
  PENDING: "Pending",
  APPROVED: "Approved",
  DENIED: "Denied",
  SUSPENDED: "Suspended",
  CANCELLED: "Cancelled",
  CLOSED: "Closed (pays out)",
};

export const lastUpdatePeriod = [
  {
    label: "All",
    value: FILTER_MODELS_LOGIN_TIME.ALL,
  },
  {
    label: "Online Now",
    value: FILTER_MODELS_LOGIN_TIME.ONLINE_NOW,
  },
  {
    label: "Last 24 hours",
    value: FILTER_MODELS_LOGIN_TIME.LAST_24_HOURS,
  },
  {
    label: "Last 7 days",
    value: FILTER_MODELS_LOGIN_TIME.LAST_7_DAYS,
  },
  {
    label: "Last 30 days",
    value: FILTER_MODELS_LOGIN_TIME.LAST_30_DAYS,
  },
  {
    label: "Last Year",
    value: FILTER_MODELS_LOGIN_TIME.LAST_YEAR,
  },
];

export const STUDIO_REGISTERS_MODEL_FORM_DATA_DEFAULTS: IStudioModel = {
  modelId: "",
  modified_at: "",
  first_name: "",
  last_name: "",
  other_name: "",
  username: "",
  gender: "",
  birthdate: new Date().toISOString(),
  phone_number: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  country: "",
  iso_country: "",
  email: "",
  password: "",
  password_confirm: "",
  document: {
    document_type: "",
    issue_country: "",
    expire_date: new Date().toISOString(),
    document_front_key: "",
    document_back_key: "",
    document_verification_key: "",
    document_front_key_view_url: "",
    document_back_key_view_url: "",
    document_verification_key_view_url: "",
  },
  missing_signup_fields: [],
  status: "",
  access_rights: undefined,
  is_online: false,
  last_login_datetime: "",
};

export const STEPS_COMPLETION_STATUS_DEFAULTS: IStepsCompletionStatuses = {
  [ModelCreateStep.SUBMIT_MODEL_INFO]: "initial",
  [ModelCreateStep.SUBMIT_MODEL_ID]: "initial",
  [ModelCreateStep.MODEL_AGREEMENTS]: "initial",
  [ModelCreateStep.SUMMARY]: "initial",
};

export const MODEL_TIMER_INTERVAL = 10000;
