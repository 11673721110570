import React from "react";
import "./styles.scss";
import classnames from "classnames";

type PineappleIconProps = {
  className?: string;
};

const PineappleIcon: React.ComponentType<PineappleIconProps> = ({
  className,
}) => (
  <span className={classnames("pineapple-icon", className && className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 54.4 98'>
      <g>
        <linearGradient
          id='SVGID_1_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3045'
          y1='49.182'
          x2='99.1784'
          y2='49.182'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='M41.1,49.4c-0.1,0.4-0.2,0.7-0.5,0.9c-0.5,0.1-0.5-0.3-0.7-0.5c-0.8-0.4-2.3-0.2-3.3-0.5
		c0.4-1,2.1-1.8,3.4-1.3C40.6,48.4,40.8,49,41.1,49.4z'
        />

        <linearGradient
          id='SVGID_00000024000417347422475600000017629761445576664487_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3244'
          y1='50.4157'
          x2='99.1946'
          y2='50.4157'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M35,48.3c0.4,1,0.6,1.6,1.7,1.6c0.1,0,0.4,0,0.5,0.1c0.3,0.1,0.6,0.4,1.2,0.4c0.2,0.8-1.1,1.1-1.6,1.4c-0.5,0.3-1,0.7-2,0.6
		c-0.8-0.1-1.8-0.2-2.4-0.9c0.1-0.4-0.1-0.4-0.3-0.6c0.2-0.4,0.6-0.5,0.9-0.7C33.4,49.3,33.8,48.5,35,48.3z'
        />

        <linearGradient
          id='SVGID_00000039103528801344461420000017769026972616198829_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3154'
          y1='51.808'
          x2='99.1859'
          y2='51.808'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M14.2,49.6c0.5-0.7,1.2-0.6,2.2-0.3c0.5,0.1,0.9,0.5,1.2,0.5c0.4,0,1-0.3,1.1,0.3c0.6-0.2,1.1,0,1.8,0.2c0.9,0.8,0.4,2.7-0.9,2.8
		c-1.3,1.5-3.6,1.8-4.9,0.3c-0.5-0.6-1.1-1.3-0.6-2.2C13.7,50.6,14.3,50.2,14.2,49.6z'
        />

        <linearGradient
          id='SVGID_00000000181726860152999020000018011094720636626076_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3244'
          y1='52.4319'
          x2='99.2018'
          y2='52.4319'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M8.3,53.5c-0.1,0.5-0.5,0.8-0.7,1.2c-0.8,0.1-1.3-0.2-2-0.5c0.2-1.7,1.4-2.4,2.8-2.9c0.1-0.2-0.5-0.4-0.4-0.7
		c0.1-0.4,0.8-0.4,1.2-0.3c0.4,0.1,0.9,0.5,1.4,0.6c0.4,0.1,0.8-0.2,0.9,0.2c0.2-0.1,0.2-0.3,0.5-0.2c0.5,0.2,0.7,0.6,0.9,1.2
		c-0.2,0.5-0.7,0.3-1.1,0.4C10.6,52.6,9.3,53.1,8.3,53.5z'
        />

        <linearGradient
          id='SVGID_00000029723863690754155030000009506452827863650700_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3121'
          y1='53.0077'
          x2='99.1844'
          y2='53.0077'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M30.6,53.5c0,0.3,0.4,0.2,0.3,0.5c-0.6,0.6-1.9,0.4-2.6,0.9c-0.2-0.1-0.5-0.3-0.7-0.5c0.5-0.9,1.1-1.8,2.1-2.3
		c-0.1-0.7,0.8-1.1,1.2-0.9C31.9,51.4,31,53.2,30.6,53.5z'
        />

        <linearGradient
          id='SVGID_00000173841060077839795530000004195786405925033151_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3237'
          y1='53.935'
          x2='99.1935'
          y2='53.935'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M38.7,51.9c0,0.8,0.8,1.3,1.3,1.7c0.1,0.1,0.1,0.3,0.1,0.3c0.3,0.2,0.6,0.2,0.7,0.5c0,0.4-0.6,0.5-0.9,0.8
		c-0.4,0.4-1.2,1.3-2.2,0.7c-0.9,0.4-1.7-0.3-2.2-0.8c0-0.5-0.1-1.1,0.1-1.7c1,0,1.8-0.8,2.1-1.7C38.1,51.7,38.4,51.8,38.7,51.9z'
        />

        <linearGradient
          id='SVGID_00000032628891465084032380000000175451166457723828_'
          gradientUnits='userSpaceOnUse'
          x1='-41.327'
          y1='55.3386'
          x2='99.2005'
          y2='55.3386'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M22.7,52c0.2,0.3-0.1,0.5-0.1,0.7c0.1,1.1,1.8,0.9,2.2,2c-0.1,0.6,0.1,1,0.3,1.4c-0.3,0.3-0.5,0.6-0.9,0.8c0,0.2-0.1,0.5-0.2,0.7
		c-1.2,1.8-4,1.5-5-0.3c0.2-0.6-0.4-0.8-0.3-1.4c0.2-0.3,0.7-0.3,0.6-0.8c1.3-0.5,2.2-1.6,2.2-3C21.8,51.8,22.2,51.9,22.7,52z'
        />

        <linearGradient
          id='SVGID_00000083054554568736696250000004665074044164671401_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3125'
          y1='56.7188'
          x2='99.1839'
          y2='56.7188'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M34.4,53.1c0.3,0,0.2,0.5,0.1,0.7c0.3,1.1,0.8,2,1.4,2.7c0.5-0.2,0.6,0.4,0.9,0.7c-0.1,0.3-0.2,0.5-0.5,0.7
		c0.1,0.6-0.3,0.8-0.5,1.2c-0.3,0.1-0.7,0.5-1.1,0.7c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0.1-1.1,0.6-1.6,0.7c-1.7,0.3-2.7-0.8-3.7-1.5
		C29,58.6,29,58.3,29,58c-0.6-0.4-0.4-1.2-0.3-2c0.9-0.1,2.2,0,2.9-0.7c-0.2-0.8-0.1-1.4,0.3-1.8C32.8,53.4,33.6,53.2,34.4,53.1z'
        />

        <linearGradient
          id='SVGID_00000053545881556500131380000016328625152702940344_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3249'
          y1='56.151'
          x2='99.2025'
          y2='56.151'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M11.5,53.3c0.2,0.3-0.2,0.3-0.1,0.7c0.3,0.8,1.2,1.2,1.2,2c0.1,0.1,0.3-0.1,0.5-0.1c0.5,0.3-0.1,1,0.2,1.3c-0.2,0.3-0.7,0.5-1,0.7
		c-0.1,1.6-2.4,1.4-3.2,0.6c-0.1-0.8-0.7-1.1-0.9-2c0.9-0.5,2.4-1.1,1.8-2.6C10.1,53.4,10.8,52.8,11.5,53.3z'
        />

        <linearGradient
          id='SVGID_00000113336543539211524740000011261420833597106576_'
          gradientUnits='userSpaceOnUse'
          x1='-41.308'
          y1='60.6948'
          x2='99.1789'
          y2='60.6948'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M27.7,56.3c0,0.1,0,0.1,0.1,0.1c-0.2,0.6-0.2,1.2,0.3,1.4c-0.3,1.1,0.6,1.3,0.9,2c0,0.2,0.1-0.1,0.3,0c0.1,0.2-0.2,0.2-0.1,0.4
		c0.3,0.2,0.5,0.5,0.9,0.6c0.5,0.6,0.2,1.6,0,2.3c-0.3,0.3-0.6,0.5-0.7,1c-1.3,1.7-4.4,1-5.2-0.7c0.3-0.8-0.6-0.9-0.4-1.6
		c-0.7,0-0.6-1-0.5-1.6c0.6,0,1.3-0.4,1.7-0.9c0.4-0.4,0.9-1.1,1-1.4c0-0.1-0.1-0.3-0.1-0.5c0.1-0.3,0.5-0.6,0.3-1
		C26.5,56.3,27.3,56.1,27.7,56.3z'
        />

        <linearGradient
          id='SVGID_00000072983462566518992160000006855475217459986316_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3188'
          y1='61.5201'
          x2='99.1909'
          y2='61.5201'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M38.9,58.2c0.2,1.6,1.4,2.3,2.4,3.1c0.1,0.2-0.1,0.2-0.1,0.3c0.3,0.1,1.1,0.4,1.2,0.8c0.1,0.7-1.2,0.9-1.7,1.3
		c-0.3,0.9-1.7,1.7-3,1.4c-0.2,0-0.3-0.2-0.5-0.3c-1.8,0.5-3-0.6-3.8-1.6c0.2-0.7-0.1-1.6,0.3-2.6c0.2-0.2,0.3,0.1,0.6,0
		c1.1-0.6,2.5-1.2,2.6-2.6C37.2,57.5,38.5,58.1,38.9,58.2z'
        />

        <linearGradient
          id='SVGID_00000108987965029269164070000017838244053572087680_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3095'
          y1='65.6998'
          x2='99.1802'
          y2='65.6998'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M21.3,61.5c0.3,0.3,0.6,0.1,0.9,0.3c-0.5,1.3,1,1.9,1.7,2.6c0.1,0.1,0.1,0.3,0.1,0.5c0.5,0.1,0.4,0.9,0.9,1c0,0.4-0.4,0.6-0.1,1
		c-0.3,0.5-0.6,1-1.1,1.2c0.2,1.2-2.1,2.7-2.5,1.4c-0.9-0.2-1.9-0.2-2.5-1c-0.2-0.3-0.6-0.9-0.6-1.1c0-0.5,0.4-0.6,0.3-1.2
		c0-0.4-0.2-1,0.1-1.4c0.2-0.1,0.2,0.1,0.3,0.1c1-0.6,1.4-1.6,1.8-2.8C20.8,61.8,21.3,61.9,21.3,61.5z'
        />

        <linearGradient
          id='SVGID_00000101823757311411509370000016720523280167601592_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3154'
          y1='66.1899'
          x2='99.1863'
          y2='66.1899'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M32.1,62.5c0,0.4-0.2,0.7,0.1,0.9c0.3,0,0.5-0.1,0.9-0.1c-0.1,1.8,1.4,2.5,2.3,3.6c-0.6,1-1.7,1.4-2,2.6C32.7,70,31.9,70,31,69.8
		c-0.1-0.1-0.1-0.3-0.2-0.5c-1.1-0.1-1.6-0.7-2.2-1.3c0.3-1-0.3-1.4-0.6-2.2c0.1,0,0.1-0.1,0.1-0.1c1.3-0.3,1.9-1.3,2.5-2.3
		c0.1-0.1-0.2-0.3,0-0.6C30.9,62.5,31.6,62.3,32.1,62.5z'
        />

        <linearGradient
          id='SVGID_00000036933543316272191980000017135700839173009572_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3139'
          y1='65.7894'
          x2='99.1823'
          y2='65.7894'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M2.4,64.9c0.8,0.8,2,1.1,3,1.7c-0.9,1.2-3,0.6-4-0.2c0.1-0.5-0.1-0.7-0.3-0.9C0.9,64.7,2.2,63.7,2.4,64.9z'
        />

        <linearGradient
          id='SVGID_00000051355318074961358610000006352009447170557867_'
          gradientUnits='userSpaceOnUse'
          x1='-41.316'
          y1='71.1301'
          x2='99.1881'
          y2='71.1301'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M29.2,71.2c0.4,0,0.6,0.1,0.9,0.2c-0.1,0.9-0.7,1.4-1.2,1.9c-0.1,0.7-0.5,1.2-1.1,1.4c-0.2,0.2,0,0.4-0.2,0.5c-0.3,0,0-0.2,0-0.3
		c-0.7,0.2-1.1,0-1.8,0.3c-0.8-0.2-1.9-0.5-2.4-1.3c-0.3-0.4-0.7-1.3-0.7-1.4c0-0.3,0.2-0.5,0.3-0.7c-0.2-0.5,0.3-1.4,0.4-2
		c1.7-0.2,2.1-1.8,2.9-3C28,67.7,28.4,69.6,29.2,71.2z'
        />

        <linearGradient
          id='SVGID_00000036955683690292095410000010451382602497049003_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3085'
          y1='69.95'
          x2='99.172'
          y2='69.95'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M6.8,67.4c0,0.5,0.1,0.8,0.3,1.3c0.3,0,0.3-0.3,0.6-0.3C7.9,69.1,7.9,70,8.9,70c0.1,0.3,0.6,0.4,0.6,0.7c0,0.2-0.7,1-1,1.2
		c-0.7,0.6-1.7,1-2.8,0.7c-1.3,0.5-2.4-0.5-3-1.3c0.2-0.6-0.1-1.3-0.2-2c0.5-0.1,1,0,1.6-0.3C4.2,67.5,5.4,66.7,6.8,67.4z'
        />

        <linearGradient
          id='SVGID_00000019639025244621321850000004818204126762537093_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3184'
          y1='71.1109'
          x2='99.1907'
          y2='71.1109'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M16.1,68.2c0.3,1.1,0.7,2.1,1.3,2.8c0.1,0,0.2,0,0.2-0.1c0.2,0.3,0.6,0.4,0.7,0.9c-0.2,0.2-0.3,0.5-0.6,0.5c0,1.2-1,1.4-1.7,1.8
		c-0.2,0-0.3,0-0.3-0.1c-0.4,0.3-1,0.6-1.5,0.7c-1.7,0.2-3-0.9-3.8-1.7c-0.1-0.3,0-0.4,0-0.7c0-0.3-0.4-0.2-0.4-0.5
		c0.1-0.6,0-1.4,0.5-1.6c0.8,0.4,2,0,2.7-0.4c0-0.7-0.2-1.4,0.4-1.8c0.3-0.2,0.8-0.1,1.2-0.1C15.6,67.7,16.6,67.2,16.1,68.2z'
        />

        <linearGradient
          id='SVGID_00000101104947968423195540000016574593114084122272_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3257'
          y1='75.5911'
          x2='99.1999'
          y2='75.5911'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M20.7,71.2c0,0.3,0.2,0.5,0.5,0.6c-0.1,1.2,0.7,2,1.2,2.8c0.3,0.1,0.2-0.3,0.5-0.2c-0.1,0.5,0.2,1,0.5,1.3c0.6,0,1,0.3,1.2,0.7
		c-0.3,0.9-1.2,1.2-1.8,1.9c-0.2,1.1-2.9,3-3.3,1.3c-0.4,0.1-1.4,0.1-2-0.3c-0.3-0.2-0.5-0.6-0.8-0.9c-0.4-0.4-0.9-0.7-0.3-1.4
		c-0.1-0.7-0.2-1.1-0.4-1.8c0.6-0.4,1.3-0.5,1.9-1c0.6-0.5,1-1.1,0.9-1.9c0.1-0.3,0.6-0.4,0.3-0.9C19.4,70.6,20,70.9,20.7,71.2z'
        />

        <linearGradient
          id='SVGID_00000009555575968326695760000011534959328034447526_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3103'
          y1='75.6973'
          x2='99.1764'
          y2='75.6973'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M9.8,72.1c0.3,0.3,0.1,0.7,0,1c0.1,0.4,0.7,0.5,0.3,1c1.1,0.9,0.9,3.2,0.1,4.3c-0.9,0-1.2,0.8-2.1,0.9c-1.2,0-1.8-1.1-2.4-1.9
		C6.1,76.9,5.5,76.5,6,76c0-0.4-0.3-0.8-0.1-1.2c0.2-0.1,0.4,0,0.6,0C8.2,74.6,7.6,71.7,9.8,72.1z'
        />

        <linearGradient
          id='SVGID_00000088104025611729848240000004019316258152903869_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3126'
          y1='77.2209'
          x2='99.1852'
          y2='77.2209'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M33.5,72.9c-0.2,1.1,0.2,2.7,1.3,2.7c-0.2,0.4,0.3,0.6,0.2,1.1c0.4-0.4,1.3-0.1,1.6,0.2c0.1,0.9-0.7,1-0.9,1.6
		c0.4,1.2-0.4,2.2-1,2.9c-0.2,0-0.3-0.1-0.5-0.1c-0.6,0.2-1.3,0.8-2.2,0.9c-1.5,0-2.6-1.5-3.5-2.3c0.3-0.9-0.5-1.2-0.1-1.8
		c-0.3-0.3-0.4-0.8-0.3-1.5c1.5-0.4,2.3-1.5,3-2.7c0.1-0.2-0.1-0.3-0.1-0.5c0-0.3,0.3-0.3,0.5-0.5c-0.1-0.2,0-0.4,0.1-0.5
		C32.5,72.3,33.1,72.5,33.5,72.9z'
        />

        <linearGradient
          id='SVGID_00000076584252037109334720000001441205381763953581_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3264'
          y1='74.1438'
          x2='99.2074'
          y2='74.1438'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M6.5,73.5c-0.5,0.8-1,1.5-2.2,1.4c-0.1-0.3-0.2-0.5-0.2-0.9C4.7,74,5.7,73.1,6.5,73.5z'
        />

        <linearGradient
          id='SVGID_00000103950389661746598400000014525620155706584706_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3126'
          y1='80.0832'
          x2='99.1821'
          y2='80.0832'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M17.3,80c-0.2,1.2-1,1.8-1.8,2.3c0.3,0.5-0.1,0.8-0.4,1c-0.7-0.2-1.3,0.4-2,0.4c-1.4,0-2.4-1-3.2-1.7c0.4-1.3-1.1-1.7-0.5-2.9
		c1.8,0.1,2.9-1.3,3.3-2.6c0.7-0.3,1.5,0,1.9,0.5c0.1,1.2,1,1.6,1.6,2.4C16.7,79.3,16.9,79.7,17.3,80z'
        />

        <linearGradient
          id='SVGID_00000010290366905052867250000000672504884168990102_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3181'
          y1='83.2124'
          x2='99.1896'
          y2='83.2124'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M26.4,77.5c0.1,0.4,0.2,0.5,0.5,0.6c-0.1,1.3,0.8,2,1.2,2.9c0.3,0,0.2-0.3,0.5-0.3c-0.2,1.1,0.8,1.4,1.6,1.8
		c-0.1,1.2-1.2,1.3-1.7,2c0,1.1-2.8,3.1-3.3,1.4c-0.7,0.3-1.7-0.1-2.2-0.5c-0.7,0.4-1.3,1.1-2,1.5c0.2,0.3-0.2,0.5,0,0.8
		c-0.3,0.7-1,1.1-1.7,1.5c-1.9,0.6-3.6-0.6-4.5-1.8c0-0.2,0.1-0.4,0.1-0.7c-0.4-0.4-0.8-1.5-0.5-2.2c0.6,0.1,1.1,0,1.8-0.2
		c0.2-1,1-1.5,1.4-2.4c0.2,0,0.2-0.1,0.5-0.1c0.1-0.5,0.8-0.5,1.5-0.5c0.2,0.1,0.2,0.3,0.3,0.5c0.1-0.1,0.3-0.2,0.6-0.1
		c0.2,1,0.7,1.7,1.4,2.2c0.6-0.7,0.2-1.5-0.1-2.3c0.2-0.4,0.6-0.2,0.9-0.3c0.9-0.4,1.5-1.3,2-2c0-0.3-0.1-0.3-0.1-0.6
		c0.5-0.5,0.2-1,0.9-1.5C25.6,77.3,26,77.3,26.4,77.5z'
        />

        <linearGradient
          id='SVGID_00000097484739437919127340000003862009590851449729_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3107'
          y1='82.9051'
          x2='99.1835'
          y2='82.9051'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M41.5,80.9c0.1,0,0.2-0.2,0.4-0.1c0.1,0.2,0.4,0.3,0.5,0.5c-0.2,1.3,1,1.2,1,2.2c-0.5,0.2-1.1,0.2-1.5,0.7c0.4,0.7,0,1.5-0.5,1.9
		c-0.2,0-0.2-0.2-0.5-0.2c-0.2,0.1,0,0.3-0.1,0.5c-0.6,0.2-0.7,0.7-1.1,0.9c-1.6,0.7-3.2-0.6-4.1-1.4c0.2-1.1-1-1.5-0.6-2.9
		c0.7-0.2,1.5-0.1,2.1-0.7c-0.1-0.4,0-0.6,0.3-0.6c-0.2-1,0.4-1.5,0.5-2.4c0.3-0.1,0.6-0.2,0.7-0.6c0.7-0.2,1.3-0.6,1.8-0.1
		C40.6,79.5,41,80.2,41.5,80.9z'
        />

        <linearGradient
          id='SVGID_00000163793090098546797480000018207741527728043685_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3225'
          y1='87.2553'
          x2='99.194'
          y2='87.2553'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M33,82.8c-0.1,0.4,0.2,0.8-0.1,1c0.3,0.1,0.5,0.4,0.5,0.9c0.5,1,1.3,1.7,2.1,2.4c0.3,0,0.5-0.4,0.7-0.2c0.2,0.2-0.2,0.4-0.1,0.7
		c0.2,0.2,0.5,0.2,0.7,0.5c-0.2,1.1-1.4,1.4-1.9,2.2c-0.1,0-0.1-0.1-0.3-0.1c-0.4,0.6-0.6,0.9-1.3,1.2c-0.3,0.1-0.5,0.4-0.7,0.4
		c-1,0.2-2.4-0.5-3.1-1.2c-0.3-0.3-1-1-1-1.4c0-0.3,0.2-0.5,0.2-0.7c0-0.2-0.2-0.3-0.2-0.5c-0.1-0.4-0.1-1-0.3-1.6
		c0.8-0.2,1.4-0.6,2-0.9c-0.1-0.8,0.4-1.5,0.6-2.3c0.4-0.1,0.6-0.3,0.7-0.6C32,82.6,32.7,82.5,33,82.8z'
        />

        <linearGradient
          id='SVGID_00000003096175387553816030000014974299904911625861_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3206'
          y1='89.3465'
          x2='99.1932'
          y2='89.3465'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M25.1,86.8c-0.1,1.3,1.4,1.6,1.8,2.6c0.3,0.1,0.9,0.2,0.9,0.8c-0.1,0.4-0.8,0.4-1.3,0.9c-0.4,0.4-0.6,1-1.2,1.1
		c-0.7,0.1-1.2,0-1.5-0.1c-1.3,0.4-2.3-0.5-3-1.2c0.1-0.7-0.1-1.3,0.2-2.2c1.4,0,2.3-1.1,2.7-2.2C24,86.4,24.7,86.5,25.1,86.8z'
        />

        <linearGradient
          id='SVGID_00000065785624901891094770000004544264548259697586_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3163'
          y1='93.9502'
          x2='99.1872'
          y2='93.9502'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M27.7,92.7c-0.3,0.4,0.1,0.6,0.1,1.1c0.1,0.1,0.3-0.3,0.4-0.5c0.1,0,0.2,0,0.3,0.1c0.2-0.2,0.4-0.5,0.5-0.8
		c0.5,0.1,0.7,0.1,0.9-0.2c0.7,0.1,1.6,0.8,0.9,1.4c-0.8,0-0.7,0.9-1.6,0.8c-0.6,0.4-1.1,0.8-2,0.9c-0.5-0.4-1.1-0.7-1.5-1.2
		c0.2-0.3,0.2-0.5,0.1-0.9c0-0.3,0.3-0.3,0.4-0.4C26,92,27.2,92.5,27.7,92.7z'
        />

        <linearGradient
          id='SVGID_00000044139184815306543390000012201694792082902923_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3179'
          y1='51.4676'
          x2='99.1902'
          y2='51.4676'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M46.1,51.5c-0.2,0.9-0.8,1.4-1.4,1.8c0.1,0.4-0.1,0.5-0.2,0.8c-0.4,0-0.8,0.1-1,0.3c-1.6,0.3-2.5-0.6-3.3-1.3
		c0.3-1-0.9-1.4-0.4-2.4c0.2,0,0.4,0,0.5,0.1c0.7-0.2,1.2-0.7,1.8-1.2c0.2-0.7,0.3-1.2,1.3-1c0.2,0.2,0.3,0.4,0.5,0.5
		C44,50.4,45.2,50.7,46.1,51.5z'
        />

        <linearGradient
          id='SVGID_00000034789161810258624120000000773938345566151863_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3169'
          y1='65.4632'
          x2='99.188'
          y2='65.4632'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M45.8,59.8c1,0,1.5-1.3,2.4-2c0.2,0.2,0.5,0.5,0.5,0.9c0.2,0.1,1,0.6,0.6,0.6c0.3,0.1,0.6,0.6,0.9,1c0,0.2,0,0.1-0.1,0.1
		c0.1,0.2,0.3,0,0.4,0.2c-0.2,0.8,0.4,0.9,0.3,1.5c0.1-0.1,0.1,0,0.2,0c0.2-0.1-0.2,0-0.1-0.2c0.3,0,0.4,0.1,0.5,0.1
		c0.2,0.6-0.5,1-0.7,0.8c0,0.7-0.8,0.8-0.9,1.6c-0.4,0.1-0.8,0.3-1,0.5c-1.7,0.2-2.4-0.7-3.2-1.4c-0.2,0-0.1,0.3-0.3,0.3
		c0,0.5,1.1,0.9,0.9,1.8c0.5-0.1,0.9-0.2,1.2,0.1c0,0.2,0,0.4,0,0.6c-0.2,0.1-0.3,0.4-0.5,0.3c0,1-1.2,1.2-1,2.3
		c-0.2,0.3-0.6,0.4-0.6,0.9c-0.8,1-3.1,1-3.7-0.1c-0.2,0-0.1,0.2-0.2,0.4c0.2,0.1,0.4,0.3,0.3,0.6c1.5,0.4-0.3,1.3-0.1,2.4
		c-0.2,0.2-0.1-0.1-0.3-0.1c0,0.7-0.4,0.9-0.3,1.4c-0.8,1-1.6,2-3.1,1.8c-1-0.1-2.1-1.2-2.7-1.9c-0.4-0.5-0.2-0.7-0.3-1.4
		c-0.1-0.4-0.5-0.7-0.3-1.2c-0.1-0.2-0.4-0.2-0.6-0.3c0-0.1,0-0.3,0-0.4c0.5-0.2,0.8-0.5,1.3-0.7c0.1-1.2,1.1-1.9,1.6-2.9
		c0.2,0,0.3-0.1,0.5,0c0.1-0.7,1.3-0.1,1.2-0.8c0.3,0,0.6,0.3,0.5,0.6c0.6,0.4,1.2,0.8,1.6,1.3c0.2-0.3-0.1-0.6-0.3-0.7
		c0.3-0.9-0.4-0.9-0.6-1.5c0.6-0.7,1.6-0.9,2.2-1.4c0.5-0.5,0.9-1.1,1.2-1.8c0.2,0,0.1-0.3,0.4-0.2c0.1-0.2-0.1-0.3,0-0.4
		c0.4,0,0.4,0.4,0.5,0.7c0.3,0.1,0.7,0.6,1.2,0.3c0.3-1.1-0.3-1.7-0.7-2.4c-0.2,0-0.2,0.1-0.4,0.1c-1.4,0.1-2.8-1-3.7-1.6
		c-0.2-0.2-0.3-0.4-0.5-0.6c0.1-0.9-0.6-1.1-0.6-1.8c0-0.3,0.4-0.5,0.3-1.1c0.6-0.6,1.7-0.2,2.6-0.5c0.2-0.1,0.3-0.3,0.5-0.3
		c0.9-0.3,2-0.1,3-0.1c0.1,0.1,0.2,0.4,0.3,0.5c0.7-0.2,0.9,0.6,1.2,1.1c0.2,0,0.1-0.5,0.3-0.5c0.4,0.9-0.5,1.6-0.7,2.3
		c0,0-0.1,0-0.1-0.1C46.5,58.7,46.1,59.3,45.8,59.8z'
        />

        <linearGradient
          id='SVGID_00000020365856708969882430000015123966365779568281_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3061'
          y1='59.0007'
          x2='99.1835'
          y2='59.0007'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M52.9,61.1c-0.3,0.7-1.7,0.1-1.8,0.1c-0.3-1.3-0.8-2.5-0.9-4.1c0.1-0.1,0.4-0.1,0.3-0.4c1.2,0.6,2,1.5,2.6,2.6c0,0.2,0,0.3-0.1,0.4
		C53.4,60.2,53,60.5,52.9,61.1z'
        />

        <linearGradient
          id='SVGID_00000145741338018388475670000003370323673303858599_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3185'
          y1='65.9841'
          x2='99.1892'
          y2='65.9841'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M52.2,63.4c0,0.3-0.2,0.3-0.1,0.7c0,0.3,0.3,0.3,0.3,0.5c0,0.1,0,0.2-0.1,0.3c0.3,0.3,0.3,0.8,0.6,1c0,0.5,0.1,1.6-0.3,1.6
		c0.1,0.4,0,1.1-0.7,1c-0.7,1.1-2.8,0.6-3-0.6c-0.4,0.3-0.2-0.8-0.3-1.2c0.5,0.1-0.1-0.9,0.2-1.3c1,0.2,1.4-0.6,1.7-1.2
		c0.1,0.2,0,0.2,0.1,0.2C50.2,63.5,51.6,62.5,52.2,63.4z'
        />

        <linearGradient
          id='SVGID_00000031204728136436728040000008929446464063812529_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3183'
          y1='72.1503'
          x2='99.1901'
          y2='72.1503'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M49.4,74.8c-0.1,0-0.1-0.1-0.1-0.1c0,0.3-0.1,0.1-0.3,0.3c-0.2-0.1-0.1,0-0.3-0.1c0,0.1-0.4,0.7-0.5,0.4c-1,1.2-2.9-0.2-3.4-1.2
		c0.1-0.5,0.2-0.6,0.1-1.2c0.1-0.1,0.2-0.1,0.3-0.3c-0.1-0.3-0.3-0.9-0.1-1.2c0.2-0.1,0.2,0.1,0.5,0.1c1.1-0.2,1.4-1.4,1.8-2.3
		c0.2-0.3,0.9-0.5,1.5-0.5c0.4,0.4,0,0.7,0,1.2c0.1,0.2,0.3,0.4,0.5,0.5c-0.4,0.4,0.2,0.7,0.2,0.9C50.2,71.9,49.9,74,49.4,74.8z'
        />

        <linearGradient
          id='SVGID_00000145050149640918170100000014312147620988236714_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3161'
          y1='24.6617'
          x2='99.1886'
          y2='24.6617'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M32.8,47.3c-1.1-0.6-1.5-1.6-0.7-2.9c0.3-0.5,1.1-0.9,1.7-1.4c1.3-1.1,2-1.6,3.4-2.8c0.4-0.4,1.4-1.3,1.3-1.8
		c-0.2-0.8-2.3-0.1-3-0.3c-0.4-0.1-0.7-0.6-0.7-1c0.1-0.6,0.6-0.9,0.9-1.4c1.1-1.8,1.9-4.2,3.2-5.9c-0.7,0.3-1,1-1.6,1.6
		c-0.5,0.5-1.1,0.8-1.5,1.4c-0.4,0.6-0.5,1.4-1.2,1.7c-1.9,0-3.7-0.1-3.4-2.4c0.1-1.1,1-2.4,1.5-3.5c1.6-3.3,3.9-6.8,5.3-9.8
		c-1,0.3-1.9,1-2.6,1.8c-2.8,3.4-6.2,7-5.8,13.1c-0.6,0.6-0.5,1.6-0.7,2.6c-0.7,0.5-2.1,0.3-2.5-0.3c-0.3-0.6-0.2-2.1-0.3-3
		c-0.1-0.9-0.3-1.8-0.4-2.8c-0.6-6.5,0.2-13.2,1.2-18.2c-0.7,0.9-1.5,1.9-2,3C25,15.5,25,16.2,24.7,17c-0.2,0.6-0.6,1.2-0.9,1.8
		c-0.7,1.4-0.9,3.3-1.2,5c-0.3,2-0.9,4.4-0.5,6.1c0.2,1,1,2,1,2.8c0,0.5-0.3,1.1-0.8,1.2c-1.1,0.2-1.7-1.8-2-2.6
		c-0.5-1.3-0.9-2.3-1.4-3.2c-1.1-2.2-2-4.1-3.3-5.8c0.5,3.1,1.7,6.9,2.6,10.1c0.4,1.5,0.7,2.6,0.9,4.3c0.1,0.8,0.4,1.8,0.6,2.5
		c0.2,0.7,1,1.4,0.9,2.1c-0.1,1.5-1.3,0.6-2,0.1c-1.2-1-2.4-1.9-3.7-2.6c0.8,1.7,2.3,3.2,3.6,4.7c0.4,0.4,1,1,1,1.7
		c0.1,0.9-0.6,2.2-1.1,2.3c-0.5,0.1-1.3-0.6-1.8-1C16,45.7,15.4,45.4,15,45c-2.4-2.2-4-5.1-5.6-8.1c-0.3-0.6-0.9-1.4-0.9-1.9
		c0-0.5,0.5-1.3,0.8-1.8c0.6-1.1,1.5-1.6,2.4-0.6c0.4,0.4,0.8,0.7,0.8,1.4c1.3,1.2,2.3,2.8,4,3.6c-2.2-6-5.3-14.5-4.7-23.1
		c0.1-0.8,0.2-1.7,0.5-2.3c0.4-0.8,1.5-2.1,2.8-1.7c0.9,0.3,1.2,2,1.5,3.1c1.1,3.5,2.4,6.8,4.2,9.6c0.4-1.9,0.9-3.8,1.5-5.6
		c0.6-1.7,1.1-3.5,2-5.1c1.4-2.4,2.4-4.6,3.9-6.9c0.2-0.4,0.5-0.7,0.7-1c0.4-0.7,0.5-1.5,1.2-1.9c0.9-0.5,3.3-0.8,3.5,0.4
		c0.1,0.5-0.4,1.7-0.5,2.4c-1,3.9-1.5,8.1-2,12.5c-0.2,2.4-0.6,4.1-0.7,6.5c0.6-1,1.5-1.7,2.3-2.7c0.6-0.8,1.1-1.6,1.8-2.3
		c1.7-1.6,3.8-3.2,5.6-4.7c0.4-0.3,0.7-0.7,1-1c1-0.7,2.5-0.8,3.5-0.2c-0.1,1.5-0.8,2.7-1.4,3.8c-1.2,2.3-2.2,4.6-3.3,7
		c-0.6,1.1-1.3,2.3-1.6,3.6c1.8-0.7,3.7-3.7,6.2-2.3c-0.4,1.9-1.6,3.3-2.6,4.8c-0.5,0.7-1,1.5-1.4,2.2c-0.8,1.2-1.5,2.2-2.1,3.7
		c0.9,0,1.3-1.1,2.5-1.6c0.3-0.1,0.9-0.1,1.4-0.2c1-0.2,2.4-0.9,2.7,0.3c0.1,0.5-0.3,1-0.6,1.2c-1.5,1.9-3,3.9-4.7,5.8
		c-0.5,0.6-1.1,1.3-1.6,1.8C36.7,45.2,34.6,46,32.8,47.3z'
        />

        <linearGradient
          id='SVGID_00000073695175640278325120000013201280367251251878_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3278'
          y1='54.6641'
          x2='99.1952'
          y2='54.6641'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M48.9,53.5c0.3,0,0.2-0.5,0.5-0.5c1.2,0.5,0.4,2.5,1.2,3.2c-0.1,0.5-0.3,0.8-0.7,1c-0.9-0.5-1.2-1.6-1.8-2.2
		c0.3,0.1-0.1-0.1,0.1-0.2c-0.2,0.6-0.5-0.4-0.6-0.5c-0.4-0.2-0.9,0.1-1.3-0.1c-0.3-0.5-0.3-1-0.4-1.7
		C46.9,51.7,48.7,53.1,48.9,53.5z'
        />

        <linearGradient
          id='SVGID_00000093894902364371151570000009984055782141545352_'
          gradientUnits='userSpaceOnUse'
          x1='-41.3159'
          y1='60.4708'
          x2='99.1865'
          y2='60.4708'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M15.2,54.3c-0.2,0.7,0.6,1,0.1,1.5c0.5-0.1,0.8-0.9,1.8-0.7c0,0.1,0.1,0.2,0.2,0.2c0.1,0.5-0.1,0.8-0.2,1c0.1,0.2,0.5-0.2,0.7,0.1
		c0,1.1,0.6,2.1,1.4,2.6c0.7-0.3,1.5,0.1,1.6,0.5c0.1,0.3-0.2,0.5-0.5,1c-0.4,0.6-0.5,1-0.9,1.4c-0.7,0.7-1.5,1.1-2.4,1.3
		c-0.2,0.1-0.2,0.2-0.3,0.3c-0.5-0.1-0.6,0-0.9,0.2c-1.2,0-2-0.4-2.7-0.9c0.5,1.3,2.1,1.5,2.4,3c-0.6,0.6-2.1,0.4-2.2,1.5
		c-0.4,0.2-0.7,0.4-0.9,0.7c-1.7,0.6-3.7,0.1-4.5-0.9c0-0.2-0.1-0.3-0.1-0.5c-0.4-0.3-0.4-1.1,0.2-1.1c0-0.3-0.2-0.4-0.2-0.7
		c-0.8,0.3-1.4,0.8-2.2,0.8c-1.5,0-2.6-1.5-3.4-2.2c0.2-1-0.6-0.9-0.5-1.7c-0.1-0.2-0.5-0.2-0.6-0.5c0-0.5,0.2-0.8,0.3-1.2
		c1-0.3,1.6-1.1,2-1.9C3,57.9,2.1,58,1.9,57.4c0.1-0.1,0.2-0.3,0.5-0.3c-0.1-1.3,0.9-2.3,2.4-2c0,0.8,1,0.3,1.5,0.6
		c0.1,0.1,0.1,0.2,0.1,0.4c0.5,0.2,0.9,0.6,0.9,1.3c0.6,1.1,1.4,2,2.3,2.8c0.3,0,0.5,0,0.7,0.1c0.2,1.4-1.4,1.7-1.3,3.2
		c-0.2,0.3-0.5,0.4-0.6,0.7c0.3,0.3,0.7-0.1,1-0.2c0.1-0.8,0.6-1.2,0.9-1.8c0.4,0.1,0.5-0.2,0.7-0.4c0.7,0,1.2-0.1,1.6-0.5
		c0-0.5-0.5-0.6-0.7-0.9c0.2-0.3,0.1-0.6,0.1-1c1.1,0,1.8-0.4,2.4-1.1c-0.1-0.9,0.7-1.5,1-2.2c-0.3-0.1-0.6,0.2-1,0.2
		C14,56.1,14,55.9,14,55.7c-0.8-0.9-1.5-1.9-1.8-3.2C13.4,52.4,14.2,53.7,15.2,54.3z'
        />

        <linearGradient
          id='SVGID_00000135685057894971036580000003892189539578048931_'
          gradientUnits='userSpaceOnUse'
          x1='-41.314'
          y1='77.5517'
          x2='99.1874'
          y2='77.5517'>
          <stop offset='0' />
          <stop offset='1' />
        </linearGradient>
        <path
          d='
		M44.2,74.1c0,0.5,0.2,0.6,0.1,1.2c0.6,0.3,1.6,0.6,1.4,1.4c0.7,0.3,1.2,0.9,0.8,1.8c-0.1,0-0.2,0-0.3,0c0.3,0.8-0.1,1.5-0.2,2
		c-0.4,0-0.6,0.2-0.8,0.4c-1.8-0.3-3-1.3-4-2.6c0.2-0.6,0.1-0.7,0.1-1.4c0.2-0.2,0.4-0.1,0.5-0.2c0.3-0.3,0-0.7,0-1.2
		c0-0.4,0.4-0.5,0.4-0.9c0.2,0,0.2-0.1,0.5-0.1C42.6,74.1,43.4,74.1,44.2,74.1z'
        />
      </g>
    </svg>
  </span>
);

export default PineappleIcon;
