import React from "react";
import "./styles.scss";
import classnames from "classnames";

type ContestsIconProps = {
  className?: string;
};

const ContestsIcon: React.ComponentType<ContestsIconProps> = ({
  className,
}) => (
  <span className={classnames("ContestsIcon", className && className)}>
    <svg
      version='1.2'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      overflow='visible'
      preserveAspectRatio='none'
      viewBox='0 0 26 26'>
      <g>
        <defs> </defs>{" "}
        <rect display='none' fill='#FFA400' width='22' height='22'></rect>{" "}
        <rect
          id='_x3C_Slice_x3E__81_'
          display='none'
          fill='none'
          width='22'
          height='22'></rect>{" "}
        <path d='M6,12H0v6h6V12z M14,6H8v12h6V6z M16,0v18h6V0H16z M0,22h22v-2H0V22z'></path>{" "}
      </g>
    </svg>
  </span>
);

export default ContestsIcon;
