import React, { useCallback } from "react";
import PricingStore from "../_stores/pricing/PricingStore";
import { inject, observer } from "mobx-react";
import ProductDropdown from "../broadcast-pricing-product-dropdown";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import LanguageStore from "library/core/stores/language/LanguageStore";
import StudioModelAccessRights from "../../studio-models-access-rights";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";

interface Props {
  pricingStore?: PricingStore;
  broadcastStore?: BroadcastStore;
  layoutStore?: LayoutStore;
  languageStore?: LanguageStore;
  profileStore?: ProfileStore;
}

const BroadcastNudeSettings: React.FunctionComponent<Props> = ({
  pricingStore,
  broadcastStore,
  layoutStore,
  languageStore,
  profileStore,
}) => {
  const { isMobileScreenSize } = layoutStore!;
  const { isShowStarted } = broadcastStore!;
  const { submitPrice, modelProducts, nudeChatPriceOptions } = pricingStore!;
  const { intl } = languageStore!;
  const {
    isStudioModel,
    modelProfile: { access_rights },
  } = profileStore!;

  const onChangeNudeShowPrice = useCallback((value: any) => {
    submitPrice("nude_chat", value);
  }, []);

  return (
    <>
      <TailwindFlex
        flexDirection='flex-col'
        minHeight={isMobileScreenSize ? "min-h-0" : "min-h-40"}>
        <TailwindFlex flexDirection='flex-row' justifyContent='justify-between'>
          <StudioModelAccessRights
            isIndividualModel={!isStudioModel}
            shouldShow={!!access_rights?.is_pricing_settings_change_allowed}
            type={"pricing"}>
            <TailwindTranslatedText
              padding={["pt-2"]}
              textColor='text-main-color'
              descriptor={{
                id: "pay-to-enter.nude-show",
                defaultMessage: "Nude show",
              }}
            />
            <ProductDropdown
              dataTestId={"nude-show-price-dropdown"}
              className={["h-10"]}
              fullHeight={true}
              disabled={isShowStarted}
              direction={"bottom"}
              options={nudeChatPriceOptions}
              onChange={onChangeNudeShowPrice}
              price={modelProducts?.nude_chat}
              currency={intl.formatMessage({
                id: `common.units.tokens-long`,
                defaultMessage: "tokens",
              })}
              unit={intl.formatMessage({
                id: `common.units.minute`,
                defaultMessage: "minute",
              })}
            />
          </StudioModelAccessRights>
        </TailwindFlex>
      </TailwindFlex>
    </>
  );
};

export default inject(
  "pricingStore",
  "broadcastStore",
  "layoutStore",
  "languageStore",
  "profileStore"
)(observer(BroadcastNudeSettings));
