import React from "react";
import TailwindFlex, { TailwindFlexProps } from "../flex";
import { Tab } from "./types";

export type TailwindTabBodyProps = TailwindFlexProps & {
  tabs: Tab[];
  activeTabIndex: number;
};

const TailwindTabBody: React.ComponentType<TailwindTabBodyProps> = ({ tabs, activeTabIndex, ...props }) => {
  return (
    <TailwindFlex {...props}>
      {tabs.map((tab, index) => (activeTabIndex === index ? React.cloneElement(tab.component, { key: tab.id }) : null))}
    </TailwindFlex>
  );
};

export default TailwindTabBody;
