import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import TailwindFlex from "../../components/_tailwind/flex";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookSubHeading from "../_internal/sub-heading";
import TailwindAnchor from "../../components/_tailwind/anchor";

type Props = {};

const StorybookArchitectureMobXAndStores: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>
        Architecture &gt; Mobx & Utility Stores
      </StorybookHeading>
      <StorybookParagraph>
        We just recently updated MobX to version 6, that removes decorators and
        makes the class automatically decorated with action, computed,
        observable via{" "}
        <Code
          inline
        >{`makeAutoObservable(this) as well as makeObservable(this, {methodOne: action, variableOne: observable})`}</Code>
      </StorybookParagraph>
      <StorybookParagraph>
        The principal idea with MobX is that we create stores that are as
        generic as possible. Like PayoutStore that should be handling
        ModelPayout and StudioPayout functionality of Cams. Currently some of
        the parts are not DRY, meaning there are more than one store that has
        common but duplicated functions/variables as well as components, this
        leads to bugs therefore we need to reduce the redundancy.
      </StorybookParagraph>
      <StorybookParagraph>
        There are some core stores that we are using:
      </StorybookParagraph>
      <StorybookSubHeading>ValidationStore</StorybookSubHeading>
      <TailwindFlex id={"validation-store"}>
        ValidationStore has the error messages, validation conditions,
        validation functions as well as capability to store backend errors and
        display them whereever necessary. It first scans BE response for any
        error messages then falls back to error messages inside ValidationStore.
        All FE code is validated before it hits BE meaning on form "onBlur" we
        validate so that user does not need to click, make request, see an error
        message.
      </TailwindFlex>
      <StorybookParagraph>
        Some of the prominent functions/variables:
      </StorybookParagraph>
      <StorybookParagraph>
        <Code inline>private validators</Code> stores validator functions that
        uses validator.js for some of them like age, username, body_Type,
        breast_size, password etc.
      </StorybookParagraph>
      <StorybookParagraph>
        <Code inline>private ERROR_MESSAGES</Code> stores error messages that
        are not yet connected to i18n, it will be i18n later.
      </StorybookParagraph>
      <StorybookParagraph>
        <Code inline>public showNotificationOnRejectedFiles</Code> shows
        toasts/snacks when a file is rejected because of its max file limit, max
        size limit, or accepted file types
      </StorybookParagraph>
      <StorybookParagraph>
        <Code inline>public storeBackendErrors</Code> is the code we use to
        catch errors from BE like:
      </StorybookParagraph>
      <Code>
        {`try {
        //...some code
} catch (error) {
  validationStore.storeBackendErrors(error);
}
`}
      </Code>
      <StorybookParagraph>
        <Code inline>public clearErrors</Code> clears the error in-memory cache
        from the store, this is useful when you have a form inside a modal that
        when you close and reopen it, the validations are reset
      </StorybookParagraph>
      <StorybookParagraph>
        <Code inline>
          public validate(keyOfTheValidatorFunction: string, value: string,
          options: ValidateOptions)
        </Code>{" "}
        this simply runs a validator function against an input, t hen writes
        into <Code inline>public errors</Code> object the error message as{" "}
        <Code inline>errors.email</Code>
      </StorybookParagraph>
      <StorybookParagraph>
        <Code inline>
          public validateMultipleObj(objectWithKeysAndValues: Object,
          optionsWithKeysAndValues: Object, validateOneByOne: boolean = true
        </Code>{" "}
        This is a proxy function that validates an object instead of a key and
        value strings into <Code inline>public errors</Code> object the error
        message as <Code inline>errors.email</Code>
      </StorybookParagraph>
      <StorybookParagraph>Usage:</StorybookParagraph>
      <Code>
        {`const fieldsToValidate = {
  first_name: "some first name",
  last_name: "some last name",
  username: "some username",
  gender: "female",
  birthdate: "some birthday",
  address: "some address",
  city: "some city",
  state: "some state",
  zip: "some zip",
  country: "some country",
  email: "some@email.com,
  password: "somePassword",
};

const allInputsValid = validationStore.validateMultipleObj(
  fieldsToValidate
);
  if (allInputsValid) {
    //...proceed
  }
}
        
`}
      </Code>
      <StorybookParagraph>
        <Code inline>
          public validateMultiple(inputKeys: string[], formValues: Object,
          options: ValidateOptions, validateOneByOne: boolean = true
        </Code>{" "}
        This is a function that makes it easy to store the validation fields in
        multiple files via a constant that you can run against several times and
        stores <Code inline>public errors</Code> object the error message as{" "}
        <Code inline>errors.email</Code>
      </StorybookParagraph>
      <StorybookParagraph>Usage:</StorybookParagraph>
      <Code>
        {`const fieldOptions: HashMap<ValidateOptions> = {};
const fieldValues: HashMap<any> = {};
const validationFields = [
  "first_name",
  "last_name",
  "country",
  "city",
  "phone_number",
  "zip_code",
  "username",
  "address",
];

validationFields.forEach((field) => {
  fieldOptions[field] = {
    checkForEmptyString: true,
  };
  fieldValues[field] = this.profile[field];
});

const isAllFieldsValid = validationStore.validateMultiple(
  validationFields,
  fieldValues,
  fieldOptions,
  false
);

if (isAllFieldsValid) {
  //proceed
}
`}
      </Code>
      <StorybookSubHeading>ModalStore</StorybookSubHeading>
      <StorybookParagraph>
        ModalStore is basically a modal manager that stores components that you
        want to show in its memory and manages opening and closing of the
        modals. There are three modal portals:{" "}
        <Code inline>auth, primary, secondary</Code>. As the name can tell auth
        is Authentication related, meaning we only use it on landing, it had
        different settings for backgrounds etc but now it is streamlined into
        look like primary & secondary and is not actually needed but it is good
        to have that seperation; we can get rid of it later if we want so that
        maybe prevent closing of the modal via an X button on auth modals if we
        chose to.
      </StorybookParagraph>
      <StorybookParagraph>
        Primary & secondary modals exist so that if you want a modal on top of a
        modal you can use the secondary one. It is useful for prompts that
        accept user input.
      </StorybookParagraph>
      <StorybookParagraph>
        It has functions like:{" "}
        <Code inline>
          openPrimaryModal(component), openSecondaryModal(component),
          openAuthModal(component) as well as closePrimaryModal(),
          closeSecondaryModal(), closeAuthModal()
        </Code>
      </StorybookParagraph>
      <StorybookSubHeading>LayoutStore</StorybookSubHeading>
      <StorybookParagraph>
        LayoutStore is the manager for layout, useragent, browser dimensions &
        orientation, mobile keyboard handler. The most prominent variables and
        functions in it are:
      </StorybookParagraph>
      <Code>
        {`public isMobile: boolean
public isPortrait: boolean
public static isWebkit: boolean
public static isChrome: boolean
public static isAppleMobile: boolean
public static isAppleTablet: boolean
public static isAppleMac: boolean
public static isAndroid: boolean
public static isWindows: boolean
public isKeyboardOpen: boolean
public setIsKeyboardOpen(open: boolean, isInputFocused: boolean)
public hasFocusedInput: boolean
public setHasFocusedInput(has: boolean)
public screenSize: "xs" | "sm" | "md" | "lg" | "xl" | "4k" | "widescreen"
public screenOrientation: "landscape" | "portrait"
public userAgent: "AppleMobile" | "Android" | "AppleMac" | "Windows"
public windowWidth: number
public windowHeight: number
`}
      </Code>
      <StorybookSubHeading>LanguageStore</StorybookSubHeading>
      <StorybookParagraph>
        This creates the i18n instance and also exposes a function for
        translating code inside stores that is{" "}
        <Code inline>getText(id: string, defaultText: string = "")</Code>. It
        also has the ability to change the language via{" "}
        <Code inline>set(locale: string)</Code>
      </StorybookParagraph>
      <StorybookSubHeading>SnackbarStore</StorybookSubHeading>
      <StorybookParagraph>
        This manages toasts/snacks/notifications and has a wrapper inside
        App.tsx that mounts the snacks at bottom right corner. The toasts are
        fired via the following functions:
      </StorybookParagraph>
      <Code>
        {`public enqueueSimpleSnackbar(idOfIntl: string, variant: "success" | "error" | "warning" | "info")
public enqueueSnackbar({
  ...params here
}: {
  message: {
    id: string;
    default?: string;
    parameters?: any;
  };
  closeButton?: boolean;
  actions?: {
    callback: Function;
    parameters: any;
    labelId: string;
    defaultLabel: string;
  }[];
  options?: OptionsObject;
})
`}
      </Code>
      <StorybookParagraph>
        You can have actions inside snacks like retry or undo, via actions
        object as well as control duration etc. via options object.
      </StorybookParagraph>
      <StorybookSubHeading>ThemeStore</StorybookSubHeading>
      <StorybookParagraph>
        ThemeStore is explained in the{" "}
        <TailwindAnchor to={"/architecture/theming"}>
          Theming page.
        </TailwindAnchor>
      </StorybookParagraph>
    </StorybookWrapper>
  );
};

export default StorybookArchitectureMobXAndStores;
