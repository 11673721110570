import React from "react";
import TailwindFlex from "../flex";
import TailwindPill, { TailwindPillProps } from "../pill";
import "./styles.scss";

export type TailwindGiftWithPillProps = {
  pills?: TailwindPillProps[];
  image?: string;
};

const TailwindGiftWithPill: React.FunctionComponent<TailwindGiftWithPillProps> = ({
  pills,
  image,
}: TailwindGiftWithPillProps) => (
  <TailwindFlex
    width={"w-auto"}
    display={"inline-flex"}
    flexDirection={"flex-col"}
    alignItems={"items-center"}
    padding={["p-2.5"]}>
    <img src={image} alt='' className={"mb-2.5"} />
    {pills && (
      <div className={`${pills.length > 1 ? "pillGroup" : ""} inline-flex`}>
        {pills.map((pill, index) => (
          <TailwindPill key={`gift-badge-${index}`} {...pill} />
        ))}
      </div>
    )}
  </TailwindFlex>
);

export default TailwindGiftWithPill;
