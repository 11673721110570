import React from "react";
import TailwindText from "../text";
import TailwindBox from "../box";
import { indent } from "indent.js";

type Props = {
  inline?: boolean;
  disableMarginsAndPaddings?: boolean;
};

const Code: React.ComponentType<Props> = ({
  children,
  inline,
  disableMarginsAndPaddings,
}) => {
  return (
    <TailwindBox
      as={"code"}
      width={inline ? "w-auto" : "w-full"}
      margin={
        disableMarginsAndPaddings
          ? []
          : ["mt-2", "mb-4", !inline ? "mr-2" : undefined]
      }
      padding={disableMarginsAndPaddings ? [] : [inline ? "px-2" : "p-2"]}
      display={inline ? "inline-block" : "block"}
      backgroundColor={"bg-gray-700"}
      textColor={"text-white"}
    >
      <TailwindText
        whiteSpace={"whitespace-pre-wrap"}
        lineHeight={"leading-6"}
        letterSpacing={"tracking-wide"}
      >
        {indent.ts(`${children}`, { tabString: "\t\t" })}
      </TailwindText>
    </TailwindBox>
  );
};

export default Code;
