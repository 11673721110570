import React, { useCallback, useEffect, useMemo, useState } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import MessageStore from "./stores/messages/MessageStore";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindHeading from "library/components/_tailwind/heading";
import TailwindButton from "library/components/_tailwind/button";
import ModalStore from "library/core/stores/modal";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import ModalCloseButton from "library/components/modal/modal-close-button";
import {
  DELETE_CONVERSATION_CONFIRMATION_WORD,
  DELETE_CONVERSATION_INPUT_IDENTIFIER,
} from "./stores/messages/consts";
import TailwindInput from "library/components/_tailwind/input";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import TailwindList, {
  TailwindListType,
} from "library/components/_tailwind/list";
import TailwindListItem from "library/components/_tailwind/list/list-item";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";

type Props = {
  messageStore?: MessageStore;
  modalStore?: ModalStore;
  validationStore?: ValidationStore;
};

const MessageCenterDeleteModal: React.ComponentType<
  Props & WrappedComponentProps
> = ({ messageStore, modalStore, validationStore, intl }) => {
  const [removeInput, setRemoveInput] = useState<string>("");
  const {
    selectedConversations,
    deleteConversations,
    activeConversationId,
    activeNewsAndPolicy,
    deleteNewsAndPolicy,
    isMCPhase2Active,
  } = messageStore!;
  const { closePrimaryModal } = modalStore!;
  const { errors, clearErrors, validate } = validationStore!;

  const selectedConversationLength = useMemo(
    () =>
      selectedConversations && Array.isArray(selectedConversations)
        ? selectedConversations.length
        : 1,
    [selectedConversations]
  );

  const close = () => {
    clearErrors();
    closePrimaryModal();
  };

  const handleDelete = useCallback(() => {
    if (
      activeNewsAndPolicy &&
      activeNewsAndPolicy.id === activeConversationId
    ) {
      deleteNewsAndPolicy(activeNewsAndPolicy);
    } else {
      deleteConversations();
    }
    close();
  }, [activeNewsAndPolicy, activeConversationId]);

  const title = useMemo(() => {
    if (isMCPhase2Active && selectedConversationLength <= 1) {
      return intl.formatMessage({
        id: "message-center.remove-conversation",
        defaultMessage: "Remove conversation",
      });
    } else if (!isMCPhase2Active && selectedConversationLength <= 1) {
      return intl
        .formatMessage({
          id: "verbiage.conversation",
          defaultMessage: "conversation",
        })
        .toLocaleLowerCase();
    } else if (isMCPhase2Active) {
      return intl.formatMessage(
        {
          id: "message-center.remove-conversations",
          defaultMessage: "Remove {count} conversations",
        },
        {
          count: selectedConversationLength,
        }
      );
    } else {
      return `${selectedConversationLength} ${intl
        .formatMessage({
          id: "verbiage.conversations",
          defaultMessage: "conversations",
        })
        .toLocaleLowerCase()}`;
    }
  }, [selectedConversationLength]);

  const onChangeInput = event => {
    const target = event?.currentTarget;
    clearErrors();
    if (target) {
      setRemoveInput(target?.value || "");
    }
  };

  const validateText = useCallback(() => {
    return validate(
      DELETE_CONVERSATION_INPUT_IDENTIFIER,
      {
        new: removeInput.toLowerCase(),
        newConfirm: DELETE_CONVERSATION_CONFIRMATION_WORD.toLowerCase(),
      },
      {
        checkForEmptyString: true,
      }
    );
  }, [removeInput]);

  const onBlurInput = useCallback(() => {
    if (removeInput) {
      validateText();
    } else {
      clearErrors();
    }
  }, [removeInput]);

  const onSubmit = useCallback(() => {
    const success = validateText();
    if (success || !isMCPhase2Active) {
      handleDelete();
    }
  }, [removeInput, isMCPhase2Active]);

  const onInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        setTimeout(() => {
          onSubmit();
        });
      }
    },
    [removeInput, errors]
  );

  useEffect(() => {
    return () => {
      close();
    };
  }, []);

  return (
    <TailwindFlex
      className={["MessageConversationDeleteModal"]}
      backgroundColor={"bg-primary-bg-color"}
      flexDirection={"flex-col"}
      padding={["p-8"]}
      borderRadius={"rounded-xl"}
      style={{
        maxWidth: "450px",
      }}>
      {isMCPhase2Active ? <ModalCloseButton closeModal={close} /> : null}
      <TailwindFlex
        justifyContent='justify-center'
        alignItems='items-center'
        flexDirection='flex-col'>
        {isMCPhase2Active ? (
          <>
            <TailwindHeading
              level={5}
              margin={["mb-2"]}
              textAlign='text-center'>
              {title}
            </TailwindHeading>
            <TailwindFlex textAlign='text-center'>
              <TailwindTranslatedText
                descriptor={{
                  id: "message-center.permanently-lose",
                  defaultMessage:
                    "This will permanently remove the conversation history on your side and you will lose access to any paid messages you sent as well as any purchased media content, including:",
                }}
              />
            </TailwindFlex>
          </>
        ) : (
          <>
            <TailwindHeading level={6} width='w-full'>
              {selectedConversationLength <= 1
                ? intl.formatMessage({
                    id: "verbiage.remove_these_conversations",
                    defaultMessage: "Remove this conversation",
                  })
                : intl.formatMessage(
                    {
                      id: "verbiage.remove_these_conversations",
                      defaultMessage: "Remove these {count} conversations",
                    },
                    {
                      count: selectedConversationLength,
                    }
                  )}
              ?
            </TailwindHeading>
            <TailwindFlex justifyContent='justify-start'>
              {intl.formatMessage(
                {
                  id: "verbiage.removeConVersationSubHeader",
                  defaultMessage: "This will remove {title} for you.",
                },
                { title }
              )}
            </TailwindFlex>
          </>
        )}

        {isMCPhase2Active ? (
          <>
            <TailwindFlex
              justifyContent='justify-center'
              alignItems='items-center'
              margin={["my-2"]}>
              <TailwindList type={TailwindListType.ul} margin={["m-0"]}>
                <TailwindListItem margin={["my-1"]}>
                  {intl
                    .formatMessage({
                      id: "message-center.photos",
                      defaultMessage: "photos",
                    })
                    .toLocaleLowerCase()}
                </TailwindListItem>
                <TailwindListItem margin={["my-1"]}>
                  {intl
                    .formatMessage({
                      id: "message-center.videos",
                      defaultMessage: "videos",
                    })
                    .toLocaleLowerCase()}
                </TailwindListItem>
                <TailwindListItem margin={["my-1"]}>
                  {intl
                    .formatMessage({
                      id: "message-center.audio",
                      defaultMessage: "audio voice recordings",
                    })
                    .toLocaleLowerCase()}
                </TailwindListItem>
              </TailwindList>
            </TailwindFlex>
            <TailwindFlex margin={["mb-4"]}>
              {intl.formatMessage(
                {
                  id: "message-center.cannot-be-undone",
                  defaultMessage:
                    'This action cannot be undone. Type "{word}" to confirm',
                },
                {
                  word: DELETE_CONVERSATION_CONFIRMATION_WORD,
                }
              )}
            </TailwindFlex>
            <TailwindFlex flexDirection='flex-col'>
              <TailwindInput
                name={DELETE_CONVERSATION_INPUT_IDENTIFIER}
                type={TailwindInputType.text}
                backgroundColor={"secondary"}
                onChange={onChangeInput}
                onBlur={onBlurInput}
                onKeyDown={onInputKeyDown}
                value={removeInput}
                error={
                  errors[DELETE_CONVERSATION_INPUT_IDENTIFIER] || undefined
                }
              />
            </TailwindFlex>
          </>
        ) : null}
        <TailwindFlex
          margin={["mt-8"]}
          justifyContent={"justify-end"}
          alignItems={"items-end"}>
          <TailwindFlex width={"w-auto"} margin={["mr-4"]}>
            <TailwindButton
              fullWidth
              onClick={close}
              backgroundColor={"bg-transparent"}
              textProps={{
                textColor: "text-modal-button-color",
              }}
              borderStyle={"border-solid"}
              justifyContent={"justify-center"}>
              {intl.formatMessage({
                id: "verbiage.cancel",
                defaultMessage: "Cancel",
              })}
            </TailwindButton>
          </TailwindFlex>
          <TailwindFlex
            width={"w-auto"}
            opacity={
              !!errors[DELETE_CONVERSATION_INPUT_IDENTIFIER] || !removeInput
                ? "opacity-70"
                : "opacity-100"
            }>
            <TailwindButton
              padding={["px-6"]}
              backgroundColor={"bg-modal-button-color"}
              textProps={{ textColor: "text-white" }}
              justifyContent={"justify-center"}
              onClick={onSubmit}
              disabled={
                isMCPhase2Active &&
                (!!errors[DELETE_CONVERSATION_INPUT_IDENTIFIER] || !removeInput)
              }
              settings={true}
              disabledProps={{
                backgroundColor: "bg-modal-button-color",
                textColor: "text-white",
              }}>
              {intl.formatMessage({
                id: "verbiage.remove",
                defaultMessage: "Remove",
              })}
            </TailwindButton>
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject(
    "messageStore",
    "modalStore",
    "validationStore"
  )(observer(MessageCenterDeleteModal))
);
