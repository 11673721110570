import React, { useMemo } from "react";
import "./styles.scss";
import TailwindBox, { TailwindBoxProps } from "../box";

export type Props = TailwindBoxProps & {
  type?: TailwindSpinnerType;
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  color?: {
    bg: string; //hex
    accent: string; //hex
  };
};

export enum TailwindSpinnerType {
  "spinning-circle" = "spinning-circle",
  "spinning-circle-double" = "spinning-circle-double",
  "spinning-snake" = "spinning-snake",
  "flipping-rectangle" = "flipping-rectangle",
  "flipping-rectangle-walking" = "flipping-rectangle-walking",
  "moving-bars" = "moving-bars",
  "blinking-circle" = "blinking-circle",
  "pulsating-circle" = "pulsating-circle",
}

const TailwindSpinner: React.ComponentType<Props> = ({
  type = TailwindSpinnerType["spinning-snake"],
  size = 3,
  color,
  ...props
}) => {
  const style = useMemo(
    () => ({
      width: `${size}rem`,
      height: `${size}rem`,
    }),
    []
  );

  const cssVars = useMemo(() => {
    const bg = color?.bg || "var(--loading-indicator-accent-color)";
    const accent = color?.accent || "var(--loading-indicator-bg-color)";
    return `
      .TailwindSpinner {
        --loading-indicator-custom-accent-color: ${bg};
        --loading-indicator-custom-bg-color: ${accent};
      }
    `;
  }, [color]);

  return (
    <TailwindBox
      {...props}
      className={["TailwindSpinner"]}
      margin={props.margin || ["mx-auto", "my-auto"]}>
      <style dangerouslySetInnerHTML={{ __html: cssVars }}></style>
      {type === TailwindSpinnerType["spinning-circle"] ? (
        <div
          className='TailwindSpinner__spinner TailwindSpinner__spinning-circle'
          style={style}
        />
      ) : type === TailwindSpinnerType["spinning-circle-double"] ? (
        <div
          className='TailwindSpinner__spinner TailwindSpinner__spinning-circle-double'
          style={style}
        />
      ) : type === TailwindSpinnerType["spinning-snake"] ? (
        <div
          className='TailwindSpinner__spinner TailwindSpinner__spinning-snake'
          style={style}
        />
      ) : type === TailwindSpinnerType["flipping-rectangle"] ? (
        <div
          className={
            "TailwindSpinner__spinner TailwindSpinner__flipping-rectangle"
          }
          style={style}
        />
      ) : type === TailwindSpinnerType["flipping-rectangle-walking"] ? (
        <div
          className={
            "TailwindSpinner__spinner TailwindSpinner__flipping-rectangle-walking"
          }
          style={style}
        />
      ) : type === TailwindSpinnerType["moving-bars"] ? (
        <div
          className={"TailwindSpinner__spinner TailwindSpinner__moving-bars"}
          style={style}
        />
      ) : type === TailwindSpinnerType["blinking-circle"] ? (
        <div
          className={
            "TailwindSpinner__spinner TailwindSpinner__blinking-circle"
          }
          style={style}
        />
      ) : type === TailwindSpinnerType["pulsating-circle"] ? (
        <div
          className={
            "TailwindSpinner__spinner TailwindSpinner__pulsating-circle"
          }
          style={style}
        />
      ) : null}
    </TailwindBox>
  );
};

export default TailwindSpinner;
