import React, { useCallback, useMemo } from "react";
import TailwindBox, { TailwindBoxProps } from "../box";
import { THeight, TWidth } from "../../../styles/tailwind-classnames";
import TailwindIcon from "../icon";

export const BUTTON_DEFAULT_ROUNDED = false;

export type TailwindFabProps = TailwindBoxProps & {
  fillColor?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  size?: "sm" | "normal" | "lg";
};

const TailwindFab: React.ComponentType<TailwindFabProps> = ({
  fillColor = "#ffffff",
  onClick,
  size = "normal",
  ...props
}) => {
  const onClickButton = useCallback(
    (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      if (onClick) {
        onClick(event);
      }
    },
    [onClick]
  );

  const width: TWidth = useMemo(() => {
    let width;

    switch (size) {
      case "sm":
        width = "w-10";

        break;
      case "normal":
        width = "w-12";

        break;
      case "lg":
        width = "w-16";

        break;
      default:
        width = "w-16";
    }
    return width;
  }, [size]);

  const height: THeight = useMemo(() => {
    let height;

    switch (size) {
      case "sm":
        height = "h-10";
        break;
      case "normal":
        height = "h-12";
        break;
      case "lg":
        height = "h-16";
        break;
      default:
        height = "h-16";
    }
    return height;
  }, [size]);

  return (
    <>
      <TailwindBox
        padding={["p-0"]}
        borderRadius={"rounded-full"}
        width={width}
        height={height}
        onClick={onClickButton}
        display={"inline-flex"}
        alignItems={"items-center"}
        justifyContent={"justify-center"}
        backgroundColor={"bg-primary-bg-color"}
        className={["active:shadow-lg", "shadow", "focus:outline-none"]}
        position={"fixed"}
        style={{
          right: "10px",
          bottom: "50px",
        }}
        {...props}>
        <TailwindIcon
          name='create'
          fontSize='text-4xl'
          display={"inline-flex"}
          alignItems={"items-center"}
          textColor={"text-white"}
        />
      </TailwindBox>
    </>
  );
};

export default TailwindFab;
