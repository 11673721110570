import React from "react";
import "./styles.scss";
import clsx from "clsx";

type OptimizationIconProps = {
  className?: string;
};

const OptimizationIcon: React.ComponentType<OptimizationIconProps> = ({
  className,
}) => (
  <span className={clsx("OptimizationIcon", className && className)}>
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 202 202"
      xmlSpace="preserve"
      y="0px"
      x="0px"
      id="Layer_1_1568709945470"
    >
      <g transform="translate(1, 1)">
        <path
          d="M100.22,8.56c-50.41,0-91.28,40.87-91.28,91.28c0,22.66,8.26,43.4,21.94,59.36l16.99-16.99  c-9.39-11.58-15.03-26.33-15.03-42.37c0-37.16,30.23-67.39,67.39-67.39c32.91,0,60.37,23.72,66.22,54.96h-19.7  c-5.48-20.57-24.23-35.72-46.52-35.72c-26.59,0-48.15,21.56-48.15,48.15c0,26.59,21.56,48.15,48.15,48.15  c22.29,0,41.04-15.16,46.52-35.72h19.7c-5.85,31.24-33.31,54.96-66.22,54.96c-12.13,0-23.51-3.24-33.35-8.87l-17.38,17.38  c14.51,9.72,31.96,15.39,50.73,15.39c50.41,0,91.28-40.87,91.28-91.28S150.63,8.56,100.22,8.56z M100.22,124.1  c-13.37,0-24.25-10.88-24.25-24.25c0-13.37,10.88-24.25,24.25-24.25c13.37,0,24.25,10.88,24.25,24.25  C124.47,113.22,113.59,124.1,100.22,124.1z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  </span>
);

export default OptimizationIcon;
