import { useInterval } from "library/core/utility/hooks/interval";
import { inject, observer } from "mobx-react";
import React, { FunctionComponent, useCallback, useState } from "react";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";

interface Props {
  broadcastStore?: BroadcastStore;
}

const BroadcastRecordCountdownTimer: FunctionComponent<Props> = ({
  broadcastStore,
}) => {
  const {
    isRecording,
    stopRecording,
    startRecording,
    isStartingRecording,
    setCountdownStarted,
  } = broadcastStore!;
  const [remainingSeconds, setRemainingSeconds] = useState(5);

  const onRecordingStarted = useCallback(() => {
    setCountdownStarted(false);
  }, []);

  const onRecordingFailed = useCallback(() => {
    setCountdownStarted(false);

    stopRecording();
  }, []);

  useInterval(() => {
    if (remainingSeconds > 1) {
      setRemainingSeconds(remainingSeconds - 1);
    } else if (!isRecording && !isStartingRecording) {
      startRecording().then(onRecordingStarted).catch(onRecordingFailed);
    }
  }, 1000);

  return (
    <div className='BroadcastPreview__RecordStreamingCountdown'>
      {remainingSeconds}
    </div>
  );
};

export default inject("broadcastStore")(
  observer(BroadcastRecordCountdownTimer)
);
