import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindTranslatedNumber from "library/components/_tailwind/translated-number";
import { CurrentPeriodTotals } from "common/earnings/_stores/earnings/types";
import config from "core/config";
import _ from "lodash";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindIcon from "library/components/_tailwind/icon";

type Props = {
  periodTotal: CurrentPeriodTotals;
};

const EarningsTableRow: React.ComponentType<Props> = ({ periodTotal }) => {
  const { showYouMakeColumnOnEarningsReport } = config!;
  const isMainCategory = !periodTotal.category;

  if (periodTotal.header) {
    return (
      <TailwindFlex padding={["p-2", "pl-5", "pr-5"]} width={"w-full"}>
        <TailwindFlex
          width={"w-full"}
          justifyContent={"justify-start"}
          overflow={"overflow-hidden"}>
          <TailwindText
            width={"w-full"}
            fontWeight={"font-bold"}
            padding={["pl-1", "pr-1"]}
            className={"capitalize-first"}
            textOverflow={"overflow-ellipsis"}
            overflow={"overflow-hidden"}>
            {periodTotal.group}
          </TailwindText>
        </TailwindFlex>
      </TailwindFlex>
    );
  }

  if (periodTotal.group === "TIERED-TOTALS") {
    return (
      <>
        <TailwindFlex
          padding={
            isMainCategory ? ["p-2", "pl-5", "pr-5"] : ["p-2", "pl-10", "pr-5"]
          }
          width={"w-full"}
          borderColor={"border-table-primary-bg-color"}
          borderStyle={"border-solid"}
          borderWidth={["border-b"]}>
          <TailwindFlex
            width={
              config.showYouMakeColumnOnEarningsReport ? "w-8/12" : "w-6/12"
            }
            justifyContent={"justify-end"}
            overflow={"overflow-hidden"}>
            <TailwindText
              width={"w-full"}
              fontWeight={"font-bold"}
              textAlign={"text-right"}
              padding={["pl-1", "pr-2"]}
              className={"capitalize-first"}
              textOverflow={"overflow-ellipsis"}
              overflow={"overflow-hidden"}>
              Tiered Tokens
            </TailwindText>

            <TailwindTranslatedNumber
              textProps={{
                fontWeight: "font-bold",
              }}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              value={periodTotal?.tokens || 0}
              style='decimal'
            />
          </TailwindFlex>
        </TailwindFlex>
      </>
    );
  }

  if (periodTotal.group === "TOTALS") {
    return (
      <TailwindFlex
        padding={
          isMainCategory ? ["p-3", "pl-5", "pr-5"] : ["p-3", "pl-10", "pr-5"]
        }
        justifyContent={"justify-end"}
        borderColor={"border-table-secondary-color"}
        borderStyle={"border-solid"}
        borderWidth={["border-t"]}
        width={"w-full"}>
        <TailwindFlex width={"w-6/12"} justifyContent={"justify-end"}>
          <TailwindTranslatedText
            paragraphProps={{
              textTransform: "uppercase",
              fontWeight: "font-bold",
              margin: ["mr-1"],
            }}
            whiteSpace={"whitespace-nowrap"}
            textOverflow={"overflow-ellipsis"}
            overflow={"overflow-hidden"}
            textAlign={"text-right"}
            descriptor={{
              id: "modelPayoutTable.total",
              defaultMessage: "TOTAL",
            }}
          />

          <TailwindTranslatedNumber
            value={periodTotal?.earnings || 0}
            style='currency'
            currency='USD'
          />
        </TailwindFlex>
      </TailwindFlex>
    );
  }

  return (
    <TailwindFlex padding={["p-2", "pl-5", "pr-5"]} width={"w-full"}>
      <TailwindFlex
        width={"w-4/12"}
        justifyContent={"justify-start"}
        alignItems={"items-start"}
        overflow={"overflow-hidden"}>
        <TailwindText
          width={"w-auto"}
          padding={isMainCategory ? ["pl-1", "pr-1"] : ["pl-5", "pr-1"]}
          fontWeight={isMainCategory ? "font-bold" : "font-normal"}
          className={"capitalize-first"}
          textOverflow={"overflow-ellipsis"}
          overflow={"overflow-hidden"}>
          {periodTotal.category || periodTotal.group}
          {periodTotal.category && periodTotal.group === "Bonuses" && (
            <TailwindIcon
              style={{
                fill: "blue",
                verticalAlign: "bottom",
              }}
              name='question-mark'
            />
          )}
        </TailwindText>
      </TailwindFlex>
      {showYouMakeColumnOnEarningsReport && (
        <TailwindFlex
          width={"w-2/12"}
          justifyContent={"justify-start"}
          overflow={"overflow-hidden"}>
          <TailwindText
            width={"w-full"}
            padding={["pl-1", "pr-1"]}
            className={"capitalize-first"}
            textOverflow={"overflow-ellipsis"}
            overflow={"overflow-hidden"}>
            {periodTotal.youMake || ""}
          </TailwindText>
        </TailwindFlex>
      )}
      <TailwindFlex
        width={"w-2/12"}
        justifyContent={"justify-end"}
        overflow={"overflow-hidden"}>
        {!_.isUndefined(periodTotal?.tokens) ? (
          <TailwindTranslatedNumber
            minimumFractionDigits={0}
            maximumFractionDigits={0}
            value={periodTotal?.tokens}
            style='decimal'
          />
        ) : (
          ""
        )}
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
        {!_.isUndefined(periodTotal?.cost) ? (
          <TailwindTranslatedNumber
            value={periodTotal?.cost || 0}
            style='currency'
            currency='USD'
          />
        ) : (
          ""
        )}
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
        {!_.isUndefined(periodTotal?.yourCut) &&
          typeof periodTotal?.yourCut === "number" && (
            <TailwindTranslatedNumber
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              value={periodTotal?.yourCut}
              style='percent'
            />
          )}
        {!_.isUndefined(periodTotal?.yourCut) &&
          typeof periodTotal?.yourCut === "string" && (
            <TailwindText className='font-bold'>
              {periodTotal?.yourCut}
            </TailwindText>
          )}
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedNumber
          value={periodTotal?.earnings}
          style='currency'
          currency='USD'
        />
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default EarningsTableRow;
