import { PasswordResettingsForm } from "./interfaces";
import { IDocument } from "common/account-settings/stores/account-settings/types";
import stringSimilarity from "string-similarity";
import { LVS_SITES } from "library/core/constants";

export const PASSWORD_RESETTINGS_FORM_ERROR_DEFAULTS: PasswordResettingsForm = {
  email: null,
  isForgotPWD: null,
  newPassword: null,
  oldPassword: null,
  newPasswordCheck: null,
  emailOrUsername: null,
};

export const BLOCK_FORM_ERROR_DEFAULTS = {
  name: null,
  site: null,
  reason: null,
};
export const BLOCK_ERROR_MESSAGES = {
  site_empty: "Site can not be left blank",
  reason_empty: "Reason can not be left blank",
  name_empty: "Member username can not be left blank",
  site_invalid: (value: string) => {
    const {
      bestMatch: { target },
    } = stringSimilarity.findBestMatch(value, LVS_SITES);
    return `Site is not a valid site. Did you mean ${target}?`;
  },
};

export const PASSWORD_RESETTINGS_ERROR_MESSAGES = {
  email_empty: "Email can not be left blank",
  newPassword_empty: "New Password can not be left blank",
  originalPassword_empty: "The original Password can not be left blank",
  reEnterNewPassword_empty: "Please enter new password again",
  username_empty: "Username can not be left blank",
  email_invalid: "Please enter a valid email",
  originalPassword_invalid:
    "Password should contain 8 characters with at least one number, one uppercase and one lowercase letter",
  newPassword_invalid:
    "Password should contain 8 characters with at least one number, one uppercase and one lowercase letter",
  reEnterNewPassword_invalid:
    "Password should contain 8 characters with at least one number, one uppercase and one lowercase letter",
};
export const EMPTY_DOCUMENT: IDocument = {
  id: "",
  type: "",
  name: "",
  content: "",
  signature: "",
  version: "",
  created_at: "",
  lastEdit: "",
  title: "",
  requires_signature: false,
};
