import React, { useCallback, useMemo } from "react";
import TailwindText, { TailwindTextProps } from "../text";
import TailwindInputBase, { TailwindInputType } from "../input-base";
import TailwindBox, { TailwindBoxProps } from "../box";
import {
  TBackgroundColor,
  TBackgroundImage,
  TBorderColor,
  TGradientColorStops,
} from "../../../styles/tailwind-classnames";

export type TailwindCheckboxRadioProps = {
  label?: React.ReactNode;
  labelProps?: TailwindTextProps;
  boxProps?: TailwindBoxProps;
  isChecked?: boolean;
  onChange?: (isChecked: boolean) => void;
  type: TailwindInputType.checkbox | TailwindInputType.radio;
  name?: string;
  gradientDirection?: TBackgroundImage;
  gradientColorStops?: TGradientColorStops[];
  disabled?: boolean;
  borderColor?: TBorderColor;
  backgroundColor?: TBackgroundColor;
};

const TailwindCheckboxRadio: React.ComponentType<TailwindCheckboxRadioProps> =
  ({
    label,
    labelProps,
    boxProps,
    isChecked,
    name,
    onChange = () => {},
    gradientDirection = "bg-gradient-to-br",
    gradientColorStops = ["from-pink-500", "to-purple-500"],
    disabled = false,
    borderColor,
    backgroundColor,
    type,
  }) => {
    const onClickBox = useCallback(
      (event: React.MouseEvent<HTMLSpanElement>) => {
        if (boxProps?.onClick) {
          boxProps?.onClick(event);
        }

        if (!disabled) {
          onChange(!isChecked);
        }
      },
      [isChecked, disabled, boxProps]
    );

    const cursor = useMemo(
      () => (disabled ? "cursor-not-allowed" : "cursor-pointer"),
      [disabled]
    );

    return (
      <TailwindBox
        {...boxProps}
        display={"inline-flex"}
        justifyContent={"justify-start"}
        alignItems={"items-center"}
        onClick={onClickBox}
        as={"span"}
        position={"relative"}
        cursor={cursor}>
        <TailwindInputBase
          wrapperProps={{
            position: "absolute",
            inset: ["inset-0"],
            cursor: cursor,
            opacity: "opacity-0",
          }}
          type={type}
          checked={isChecked}
          name={name}
          disabled={disabled}
          width={"w-full"}
          height={"h-full"}
          cursor={cursor}
        />
        <TailwindBox
          borderWidth={
            type === TailwindInputType.checkbox ? ["border-2"] : ["border"]
          }
          borderRadius={
            type === TailwindInputType.checkbox ? "rounded" : "rounded-full"
          }
          borderColor={borderColor || "border-gray-300"}
          backgroundColor={backgroundColor || "bg-transparent"}
          width={"w-5"}
          height={"h-5"}
          display={"flex"}
          flexShrink={"flex-shrink-0"}
          justifyContent={"justify-center"}
          alignItems={"items-center"}
          style={{
            padding: "1px",
          }}
          cursor={cursor}>
          <TailwindBox
            display={isChecked ? "inline-block" : "hidden"}
            backgroundImage={gradientDirection}
            gradientColorStops={
              !disabled ? gradientColorStops : ["from-gray-500", "to-gray-700"]
            }
            width={"w-full"}
            height={"h-full"}
            borderRadius={
              type === TailwindInputType.checkbox
                ? "rounded-sm"
                : "rounded-full"
            }
            cursor={cursor}
          />
        </TailwindBox>
        {label && (
          <TailwindText
            margin={["ml-2"]}
            {...labelProps}
            userSelect={"select-none"}
            htmlFor={name}
            onClick={onClickBox}
            cursor={cursor}>
            {label}
          </TailwindText>
        )}
      </TailwindBox>
    );
  };

export default TailwindCheckboxRadio;
