import React from "react";
import "./styles.scss";

type TopsModelsIconProps = {
  className?: string;
};

const TopsModelsIcon: React.ComponentType<TopsModelsIconProps> = ({
  className,
}) => (
  <span className={`${className} camsIcon`}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
      <path d='M65.06,35.24a.44.44,0,0,1,.11-.36l7.18-7a5.4,5.4,0,0,0-3-9.21l-9.92-1.44a.4.4,0,0,1-.3-.22L54.7,8A5.4,5.4,0,0,0,45,8l-4.44,9a.38.38,0,0,1-.3.22l-9.91,1.44a5.4,5.4,0,0,0-3,9.21l7.18,7a.38.38,0,0,1,.11.35L33,45.12a5.36,5.36,0,0,0,2.15,5.28,5.43,5.43,0,0,0,3.17,1,5.36,5.36,0,0,0,2.51-.62l8.87-4.67a.42.42,0,0,1,.38,0l8.87,4.67a5.4,5.4,0,0,0,7.83-5.69Zm-4.93.84L61.82,46a.39.39,0,0,1-.57.42l-8.88-4.66a5.37,5.37,0,0,0-5,0l-8.87,4.66a.36.36,0,0,1-.42,0A.37.37,0,0,1,37.9,46l1.69-9.88A5.41,5.41,0,0,0,38,31.3l-7.18-7a.39.39,0,0,1-.1-.41.36.36,0,0,1,.32-.27L41,22.19a5.41,5.41,0,0,0,4.07-3l4.43-9a.4.4,0,0,1,.72,0l4.44,9a5.38,5.38,0,0,0,4.06,3l9.92,1.44a.36.36,0,0,1,.32.27.37.37,0,0,1-.1.41l-7.18,7A5.41,5.41,0,0,0,60.13,36.08Z' />
      <path d='M83.59,71.44H74.52a7.26,7.26,0,0,0-7.26,7.25V92.25a4.55,4.55,0,0,0,4.55,4.55H86.3a4.55,4.55,0,0,0,4.55-4.55V78.69A7.26,7.26,0,0,0,83.59,71.44ZM85.85,91.8H72.26V78.69a2.26,2.26,0,0,1,2.26-2.25h9.07a2.26,2.26,0,0,1,2.26,2.25Z' />
      <path d='M54.4,54H45.32a7.27,7.27,0,0,0-7.26,7.26v31a4.56,4.56,0,0,0,4.56,4.55H57.11a4.55,4.55,0,0,0,4.55-4.55v-31A7.27,7.27,0,0,0,54.4,54ZM56.66,91.8H43.06V61.21A2.27,2.27,0,0,1,45.32,59H54.4a2.26,2.26,0,0,1,2.26,2.26Z' />
      <path d='M25.2,62.83H16.13a7.27,7.27,0,0,0-7.26,7.26V92.25a4.55,4.55,0,0,0,4.55,4.55H27.91a4.55,4.55,0,0,0,4.55-4.55V70.09A7.26,7.26,0,0,0,25.2,62.83Zm2.26,29H13.87V70.09a2.26,2.26,0,0,1,2.26-2.26H25.2a2.26,2.26,0,0,1,2.26,2.26Z' />
    </svg>
  </span>
);

export default TopsModelsIcon;
