import React, { useCallback, useMemo, useState } from "react";
import MessageCenterConversationList from "./messages-navigator-conversation-list";
import MessageCenterReader from "./messages-reader";
import MessageCenterNavigatorHeader from "./messages-navigator-header";
import MessageCenterContactList from "./messages-navigator-contact-list";
import SeatchResult from "./search-result";
import { inject, observer } from "mobx-react";
import MessageStore from "./stores/messages/MessageStore";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindFab from "library/components/_tailwind/fab";
import { MessageTabEnum } from "./stores/messages/consts";
import { useHistory } from "react-router-dom";
import { AppCommonRouteKey } from "core/stores/route/enums";
import RouteStore from "core/stores/route/RouteStore";
import { WrappedComponentProps, injectIntl } from "react-intl";

type Props = {
  goBack: () => void;
  messageType: MessageTabEnum.POLICY | MessageTabEnum.MESSAGE;
  messageStore?: MessageStore;
  layoutStore?: LayoutStore;
  routeStore?: RouteStore;
};

const MessageCenterNavigator: React.ComponentType<
  Props & WrappedComponentProps
> = ({ messageStore, layoutStore, routeStore, goBack, messageType, intl }) => {
  const history = useHistory();
  const { getSiteRouteURL } = routeStore!;
  const { isMobileScreenSize, isMobileDevice } = layoutStore!;
  const {
    conversations,
    newsAndPolicies,
    activeConversation,
    selectedConversations,
    setSelectedConversations,
    isComposing,
    setIsComposing,
    setIsBulkMessageView,
    isSearchingView,
    isConversationsLoading,
    isSearchingConversations,
    contacts,
  } = messageStore!;
  const [hideComposingFab, setHideComposingFab] = useState<boolean>(false);
  // const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [searchResultMatchesUsername, setSearchResultMatchesUsername] =
    useState<string>("");
  const [searchResultMatchesCount, setSearchResultMatchesCount] =
    useState<number>(0);
  const [searchedMessagesView, setSearchedMessagesView] =
    useState<boolean>(false);

  const hasSelectedConversation = useMemo(
    () => !!activeConversation,
    [activeConversation, selectedConversations]
  );

  const showBackButton = useMemo(() => {
    return (
      (isMobileScreenSize && hasSelectedConversation) ||
      isComposing ||
      searchResultMatchesCount > 0
    );
  }, [
    isMobileScreenSize,
    hasSelectedConversation,
    isComposing,
    isSearchingView,
    searchResultMatchesCount,
  ]);

  const toggleIsComposing = useCallback(() => {
    if (isMobileDevice) {
      history.push(
        `${getSiteRouteURL(AppCommonRouteKey.messages)}${getSiteRouteURL(
          AppCommonRouteKey.contacts
        )}`
      );
    } else {
      setIsComposing(!isComposing);
      setIsBulkMessageView(false);
    }
  }, [isComposing]);

  const hasSelectedAllConversations = useMemo(() => {
    return (
      selectedConversations?.length > 0 &&
      conversations?.length === selectedConversations?.length
    );
  }, [selectedConversations, conversations, newsAndPolicies]);

  const selectAllOrNoneConversations = useCallback(() => {
    if (hasSelectedAllConversations) {
      setSelectedConversations([]);
    } else {
      const allConversationIds = conversations.map(
        conversation => conversation.id
      );
      setSelectedConversations(allConversationIds);
    }
  }, [hasSelectedAllConversations, conversations, newsAndPolicies]);

  const onConversationListScrollUp = () => {
    setHideComposingFab(false);
  };

  const onConversationListScrollDown = () => {
    setHideComposingFab(true);
  };

  return (
    <TailwindFlex
      flexDirection={"flex-col"}
      flexGrow={"flex-grow"}
      textColor='text-main-color'
      margin={isMobileDevice ? ["m-0"] : []}
      padding={isMobileDevice ? ["pr-0"] : ["pr-4"]}
      overflow={["overflow-hidden"]}>
      {messageType === MessageTabEnum.MESSAGE && (
        <>
          <TailwindFlex
            borderWidth={
              !isMobileDevice &&
              ((isComposing && !contacts?.length) ||
                isSearchingConversations ||
                (!isComposing && isConversationsLoading) ||
                (!isComposing && !conversations?.length))
                ? ["border-b-2"]
                : []
            }
            borderColor={"border-primary-bg-color"}
            padding={["p-0"]}
            margin={["m-0"]}>
            <MessageCenterNavigatorHeader
              conversation={activeConversation}
              goBack={() => {
                if (searchResultMatchesCount) {
                  setSearchedMessagesView(false);
                } else {
                  goBack();
                }
              }}
              showBackButton={showBackButton}
              selectedConversations={selectedConversations}
              toggleIsComposing={toggleIsComposing}
              selectAllOrNoneConversations={selectAllOrNoneConversations}
              hasSelectedAllConversations={hasSelectedAllConversations}
              showSearchResultHeader={searchResultMatchesCount > 0}
              searchResultMatchesUsername={searchResultMatchesUsername}
            />
          </TailwindFlex>
          {searchResultMatchesCount ? (
            <TailwindFlex
              backgroundColor={"bg-lightBlue"}
              padding={["px-4", "pt-3", "pb-2"]}
              borderWidth={["border-t-2"]}
              borderColor={"border-primary-bg-color"}
              flexDirection={"flex-row"}>
              {intl.formatMessage(
                {
                  id: "message-center.number-of-matched-messages",
                  defaultMessage: "{count} matched messages",
                },
                {
                  count: searchResultMatchesCount,
                }
              )}
            </TailwindFlex>
          ) : null}
        </>
      )}

      <TailwindFlex
        borderRadius={isMobileDevice ? "rounded-none" : "rounded-b-lg"}
        flexGrow={"flex-grow"}
        overflow={"overflow-hidden"}
        style={{ height: "calc(100% - 3.5rem)" }}>
        {isComposing && <MessageCenterContactList isMobile={isMobileDevice} />}
        {isSearchingView && <SeatchResult goBack={goBack} />}
        {!isComposing && !isSearchingView && (
          <React.Fragment>
            {(!isMobileDevice || !activeConversation) && (
              <MessageCenterConversationList
                isMobile={isMobileScreenSize}
                onScrollUp={onConversationListScrollUp}
                onScrollDown={onConversationListScrollDown}
                onScrollReset={onConversationListScrollUp}
                type={messageType}
                onMultipleMatchesViewShown={messages => {
                  setSearchResultMatchesCount(messages?.length);
                  setSearchResultMatchesUsername(
                    !messages?.length
                      ? ""
                      : messages?.[0].participants?.[0].username
                  );
                }}
                searchedMessagesView={searchedMessagesView}
                setSearchedMessagesView={setSearchedMessagesView}
              />
            )}
            {isMobileDevice && activeConversation && (
              <MessageCenterReader goBack={goBack} />
            )}
          </React.Fragment>
        )}
      </TailwindFlex>
      {!isComposing &&
        !isSearchingView &&
        isMobileDevice &&
        !hideComposingFab &&
        !activeConversation &&
        messageType === MessageTabEnum.MESSAGE && (
          <TailwindFab
            backgroundColor={"bg-modal-button-color"}
            size={"lg"}
            zIndex={"z-100"}
            onClick={toggleIsComposing}
            style={{
              right: "15px",
              bottom: "15px",
            }}
          />
        )}
    </TailwindFlex>
  );
};
export default injectIntl(
  inject(
    "layoutStore",
    "messageStore",
    "routeStore"
  )(observer(MessageCenterNavigator))
);
