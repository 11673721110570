import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindDropdown from "../../components/_tailwind/dropdown";

type Props = {};

const StorybookComponentsDropdown: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Dropdown</StorybookHeading>
      <StorybookParagraph>
        Dropdown is a component composed of multiple Tailwind components. It
        requires a portal at the root of the app component tree so that the menu
        appears above everything otherwise it leaks other dropdown placeholders
        for example since they are on the same zIndex. It manages the creation
        and removal of the container itself.
      </StorybookParagraph>
      <StorybookParagraph>
        <strong>
          One thing very important to note that the name is required and it
          needs to be a unique identifier otherwise it will not work
          consistently.
        </strong>
      </StorybookParagraph>
      <StorybookParagraph>
        It also calculates windowHeight and makes a decision whether or not to
        show menu to the top of the button if there is not enough space below.
      </StorybookParagraph>
      <StorybookParagraph>
        Currently it takes the following props:
      </StorybookParagraph>
      <Code>{`export type TailwindDropdownProps = TailwindBoxProps & {
  items: TailwindDropdownItem[];
  value: TailwindDropdownValue;
  labelProps?: TailwindTextProps;
  focusBorderColor?: TPseudoClasses;
  backgroundColor?: TBackgroundColor;
  itemHoverBackgroundColor?: TPseudoClasses;
  shape?: "rounded" | "square";
  onChange?: (item: TailwindDropdownValue) => void;
  name: string;
  fullHeight?: boolean;
  fullWidth?: boolean;
  multipleProps?: {
    placeholder: string;
    labelBadgeProps?: TailwindPillProps;
  };
};

export type TailwindDropdownItem = {
  label: string;
  value: any;
};
      `}</Code>
      <StorybookParagraph>Here are some examples:</StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindDropdown
          focusBorderColor={"2xl:border-white"}
          backgroundColor={"bg-gray-800"}

          items={[
            {
              label: "Man",
              value: "man",
            },
            {
              label: "Woman",
              value: "woman",
            },
            {
              label: "Group",
              value: "group",
            },
            {
              label: "Couple",
              value: "couple(man/woman)",
            },
            {
              label: "Couple(man/woman)",
              value: "couple-both",
            },
            {
              label: "Couple(2 women)",
              value: "couple-2-women",
            },
            {
              label: "Couple(2 men)",
              value: "couple-2-men",
            },
            {
              label: "TS/TV/TG",
              value: "ts-tv-tg",
            },
          ]}
          value={[]}
          name={"search-type"}
          multipleProps={{
            placeholder: "I am looking for",
          }}
          onChange={(value) => alert(value)}
        />`}
      >
        <StorybookParagraph>Multiple</StorybookParagraph>
        <TailwindDropdown
          focusBorderColor={"2xl:border-white"}
          backgroundColor={"bg-gray-800"}

          items={[
            {
              label: "Man",
              value: "man",
            },
            {
              label: "Woman",
              value: "woman",
            },
            {
              label: "Group",
              value: "group",
            },
            {
              label: "Couple",
              value: "couple(man/woman)",
            },
            {
              label: "Couple(man/woman)",
              value: "couple-both",
            },
            {
              label: "Couple(2 women)",
              value: "couple-2-women",
            },
            {
              label: "Couple(2 men)",
              value: "couple-2-men",
            },
            {
              label: "TS/TV/TG",
              value: "ts-tv-tg",
            },
          ]}
          value={[]}
          name={"search-type"}
          multipleProps={{
            placeholder: "I am looking for",
          }}
          onChange={(value) => alert(value)}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindDropdown
          focusBorderColor={"2xl:border-white"}
          backgroundColor={"bg-gray-800"}

          items={[
            {
              label: "20-30",
              value: "20-30",
            },
            {
              label: "30-40",
              value: "30-40",
            },
            {
              label: "40-50",
              value: "40-50",
            },
          ]}
          value={"20-30"}
          name={"between-age"}
          onChange={(value) => alert(value)}
        />
      `}
      >
        <StorybookParagraph>Single</StorybookParagraph>
        <TailwindDropdown
          focusBorderColor={"2xl:border-white"}
          backgroundColor={"bg-gray-800"}

          items={[
            {
              label: "20-30",
              value: "20-30",
            },
            {
              label: "30-40",
              value: "30-40",
            },
            {
              label: "40-50",
              value: "40-50",
            },
          ]}
          value={"20-30"}
          name={"between-age"}
          onChange={(value) => alert(value)}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindDropdown
          focusBorderColor={"2xl:border-white"}
          backgroundColor={"bg-red-500"}

          items={[
            {
              label: "20-30",
              value: "20-30",
            },
            {
              label: "30-40",
              value: "30-40",
            },
            {
              label: "40-50",
              value: "40-50",
            },
          ]}
          value={"20-30"}
          name={"between-age-2"}
          onChange={(value) => alert(value)}
        />
      `}
      >
        <StorybookParagraph>bg-red-500</StorybookParagraph>
        <TailwindDropdown
          focusBorderColor={"2xl:border-white"}
          backgroundColor={"bg-red-500"}

          items={[
            {
              label: "20-30",
              value: "20-30",
            },
            {
              label: "30-40",
              value: "30-40",
            },
            {
              label: "40-50",
              value: "40-50",
            },
          ]}
          value={"20-30"}
          name={"between-age-2"}
          onChange={(value) => alert(value)}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindDropdown
          focusBorderColor={"2xl:border-white"}
          backgroundColor={"bg-yellow-500"}
          items={[
            {
              label: "20-30",
              value: "20-30",
            },
            {
              label: "30-40",
              value: "30-40",
            },
            {
              label: "40-50",
              value: "40-50",
            },
          ]}
          value={"20-30"}
          name={"between-age-3"}
          onChange={(value) => alert(value)}
        />
      `}
      >
        <StorybookParagraph>sharp</StorybookParagraph>
        <TailwindDropdown
          focusBorderColor={"2xl:border-white"}
          backgroundColor={"bg-yellow-500"}
          items={[
            {
              label: "20-30",
              value: "20-30",
            },
            {
              label: "30-40",
              value: "30-40",
            },
            {
              label: "40-50",
              value: "40-50",
            },
          ]}
          value={"20-30"}
          name={"between-age-3"}
          onChange={(value) => alert(value)}
        />
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsDropdown;
