import TailwindBox from "library/components/_tailwind/box";
import TailwindText from "library/components/_tailwind/text";
import { inject, observer } from "mobx-react";
import React from "react";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import LanguageStore from "library/core/stores/language/LanguageStore";

interface Props {
  broadcastStore?: BroadcastStore;
  languageStore?: LanguageStore;
}

const BroadcastObsPreview: React.FunctionComponent<Props> = ({languageStore}) => {

  const { intl } = languageStore!;

  return (
    <TailwindBox padding={["py-36"]}>
      <TailwindText textColor='text-main-color'>
        {intl.formatMessage({
          id: "broadcast.broadcastPreview.streamConnected",
          defaultMessage: "Your obs stream is connected",
        })}
      </TailwindText>
    </TailwindBox>  
  );
};

export default inject(
  "broadcastStore",
  "languageStore"
)(observer(BroadcastObsPreview));
