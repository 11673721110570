import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import "./styles.scss";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import TailwindHeading from "library/components/_tailwind/heading";
import TailwindFlex from "library/components/_tailwind/flex";
// import TailwindGrid from "library/components/_tailwind/grid";
import CamsModelReferralsColumn from "containers/model/referrals/referrals-column";
import ReferralsStore from "containers/model/referrals/stores/referrals/ReferralsStore";
import Showcase from "library/components/_tailwind/showcase";
import { AppCamsModelRouteKey } from "core/stores/route/enums";
import ShowcaseStore from "core/stores/showcase/ShowcaseStore";
import ReferralsEarningsTier from "./earnings-tier";
import { SHOWCASE_TARGET_CLASSNAMES } from "core/stores/showcase/const";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import ModalStore from "library/core/stores/modal";
import TailwindBox from "library/components/_tailwind/box";

type ReferralsProps = {
  referralsStore?: ReferralsStore;
  profileStore?: ProfileStore;
  showcaseStore?: ShowcaseStore;
  modalStore?: ModalStore;
};

const AdmirersPageReferrals: React.ComponentType<
  ReferralsProps & WrappedComponentProps
> = ({ referralsStore, profileStore, showcaseStore, modalStore, intl }) => {
  const { memberReferrals, modelReferrals, getReferrals, isLoadingReferrals } =
    referralsStore!;
  const { modelProfile } = profileStore!;
  const {
    getWelcomeShowcaseSteps,
    nextWelcomeShowcasePage,
    playingShowcase,
    disableShowcase,
  } = showcaseStore!;
  const { openPrimaryModal } = modalStore!;

  useEffect(() => {
    getReferrals();
  }, []);

  const onClickShowMoreInfo = () => {
    openPrimaryModal(<ReferralsEarningsTier />);
  };

  return (
    <>
      {playingShowcase && (
        <Showcase
          steps={getWelcomeShowcaseSteps(AppCamsModelRouteKey.referrals)}
          onFinish={nextWelcomeShowcasePage}
          disableOverlay={true}
          showSkipButton={true}
          onSkipped={() => {
            disableShowcase();
          }}
        />
      )}
      <TailwindFlex
        height={"h-full"}
        pseudoClasses={["md:h-auto"]}
        flexDirection={"flex-col"}
        className={["Referrals"]}>
        <TailwindHeading level={5} margin={["mb-4"]}>
          {intl.formatMessage({
            id: "admirers.referrals.myReferralLink.title",
            defaultMessage: "My Referrals",
          })}
        </TailwindHeading>
        <TailwindFlex
          flexDirection={"flex-row"}
          pseudoClasses={["md:flex-col", "md:h-auto"]}
          height={"h-full"}>
          <TailwindFlex pseudoClasses={["md:w-full", "md:mb-4"]}>
            <TailwindFlex
              height={"h-full"}
              flexGrow={"flex-grow"}
              textColor='text-main-color'
              flexDirection={"flex-row"}
              pseudoClasses={["md:flex-col"]}>
              <CamsModelReferralsColumn
                referralLinkTitleClass={
                  SHOWCASE_TARGET_CLASSNAMES.referralsMemberLink
                }
                type={"member"}
                modelUsername={modelProfile?.username}
                referrals={memberReferrals}
                heading={{
                  id: "admirers.referrals.promoteThisLink",
                  defaultMessage: "Promote this link",
                }}
                subHeading={
                  <React.Fragment>
                    <TailwindFlex flexDirection={"flex-col"}>
                      <TailwindTranslatedText
                        margin={["mb-4"]}
                        fontWeight='font-bold'
                        fontSize='text-xl'
                        descriptor={{
                          id: "admirers.referrals.promoteYourChannel",
                          defaultMessage: "Promote your channel to new members",
                        }}
                      />
                      <TailwindBox>
                        <TailwindTranslatedText
                          descriptor={{
                            id: "admirers.referrals.memberSubtitleLine",
                            defaultMessage:
                              "Promote your Cams.com profile page on social media and get a 100% cut of every tip, paid chat, virtual gift, fan club, and paid content that <b>new</b> members spend on you for their first 6 months, then 80% thereafter.",
                          }}
                        />
                        {/*
                        // commented out as per https://jira.friendfinderinc.com/browse/CAMSRBT-3776
                        &nbsp;
                        <TailwindTooltip
                          content={intl.formatMessage({
                            id: "admirers.referrals.memberMoreInfo",
                            defaultMessage:
                              "This offer only applies to new member referrals and is not eligible to be used in conjunction with the Model Referral Program.  If you try to entice current members to sign up again using your referral link, you will lose all your referrals.",
                          })}>
                          <u>
                            <TailwindTranslatedText
                              margin={["ml-2"]}
                              descriptor={{
                                id: "common.terms-and-conditions",
                                defaultMessage: "Terms and Conditions",
                              }}
                            />
                            .
                          </u>
                        </TailwindTooltip>*/}
                      </TailwindBox>
                    </TailwindFlex>
                  </React.Fragment>
                }
                emptyText={{
                  id: "admirers.referrals.memberEmpty",
                  defaultMessage: "Refer some members to earn more!",
                }}
                tableHeadColumn1Title={{
                  id: "common.referred-member",
                  defaultMessage: "Referred Member",
                }}
                tableHeadColumn2Title={{
                  id: "common.join-date",
                  defaultMessage: "Join Date",
                }}
                tableHeadColumn3Title={{
                  id: "common.tokens_earned",
                  defaultMessage: "Tokens Earned",
                }}
                isLoading={isLoadingReferrals}
                padding={["pr-4"]}
                psuedoClasses={["md:pb-4", "md:pr-0"]}
              />
              <CamsModelReferralsColumn
                referralLinkTitleClass={
                  SHOWCASE_TARGET_CLASSNAMES.referralsModelLink
                }
                type={"model"}
                modelUsername={modelProfile?.username}
                referrals={modelReferrals}
                heading={{
                  id: "admirers.referrals.promoteThisLink",
                  defaultMessage: "Promote this link",
                }}
                subHeading={
                  <TailwindFlex flexDirection={"flex-col"}>
                    <TailwindTranslatedText
                      margin={["mb-4"]}
                      fontWeight='font-bold'
                      fontSize='text-xl'
                      descriptor={{
                        id: "admirers.referrals.referUs",
                        defaultMessage: "Refer us to your model friends",
                      }}
                    />
                    <TailwindBox>
                      <TailwindTranslatedText
                        descriptor={{
                          id: "admirers.referrals.modelSubtitle",
                          defaultMessage:
                            "You can also send models to Cams.com and increase your earnings!  Model receives a percent cut of the referred model’s earnings from Cams.com Network for a year.",
                        }}
                      />
                      <u>
                        <TailwindTranslatedText
                          margin={["ml-2"]}
                          onClick={onClickShowMoreInfo}
                          cursor={"cursor-pointer"}
                          descriptor={{
                            id: "common.get-more-info",
                            defaultMessage: "Get more info",
                          }}
                        />
                      </u>
                    </TailwindBox>
                  </TailwindFlex>
                }
                emptyText={{
                  id: "admirers.referrals.modelEmpty",
                  defaultMessage: "Refer a model for extra income!",
                }}
                tableHeadColumn1Title={{
                  id: "common.referred-model",
                  defaultMessage: "Referred Model",
                }}
                tableHeadColumn2Title={{
                  id: "common.join-date",
                  defaultMessage: "Join Date",
                }}
                tableHeadColumn3Title={{
                  id: "common.tokens_earned",
                  defaultMessage: "Tokens Earned",
                }}
                isLoading={isLoadingReferrals}
                padding={["pr-4"]}
                psuedoClasses={["md:pr-0"]}
              />
            </TailwindFlex>
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
    </>
  );
};

export default injectIntl(
  inject(
    "profileStore",
    "referralsStore",
    "showcaseStore",
    "modalStore"
  )(observer(AdmirersPageReferrals))
);
