import React from "react";

type LockIconProps = {
  className?: string;
};

const LockIcon2: React.FunctionComponent<LockIconProps> = ({}) => {
  return (
    <svg 
      version="1.2" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlnsXlink="http://www.w3.org/1999/xlink" 
      overflow="visible" 
      preserveAspectRatio="none" 
      viewBox="0 0 16 16.99999">
        <g transform="translate(0, 0)">
          <g transform="translate(0, 0) rotate(0)">
            <path 
              d="M14.48,5.9378h-3.12v-2.11483c0,-2.11483 -1.68,-3.82297 -3.76,-3.82297c-2.08,0 -3.76,1.78947 -3.76,3.82297v2.03349h-2.32c-0.8,0 -1.52,0.65072 -1.52,1.46411v8.21531c0,0.8134 0.72,1.46411 1.52,1.46411h12.96c0.8,0 1.52,-0.65072 1.52,-1.38278v-8.21531c0,-0.8134 -0.72,-1.46411 -1.52,-1.46411zM5.04,3.82297c0,-1.38278 1.12,-2.60287 2.56,-2.60287c1.44,0 2.56,1.13876 2.56,2.60287v2.03349h-5.12zM14.72,15.69856c0,0.08134 -0.16,0.16268 -0.24,0.16268h-12.96c-0.16,0 -0.24,-0.08134 -0.24,-0.16268v-8.29665c0,-0.16268 0.08,-0.16268 0.24,-0.16268h12.96c0.16,0 0.24,0.08134 0.24,0.16268zM9.52,10.81818c0,-0.89474 -0.72,-1.54545 -1.52,-1.54545c-0.8,0 -1.52,0.73206 -1.52,1.54545c0,0.56938 0.32,1.13876 0.8,1.38278v1.13876c0,0.32536 0.24,0.65072 0.64,0.65072h0.16c0.32,0 0.64,-0.24402 0.64,-0.65072v-1.13876c0.48,-0.24402 0.8,-0.73206 0.8,-1.38278z" 
              vectorEffect="non-scaling-stroke"/>
          </g>
          <defs>
            <path 
            id="path-171521635072874052" 
            d="M14.48,5.9378h-3.12v-2.11483c0,-2.11483 -1.68,-3.82297 -3.76,-3.82297c-2.08,0 -3.76,1.78947 -3.76,3.82297v2.03349h-2.32c-0.8,0 -1.52,0.65072 -1.52,1.46411v8.21531c0,0.8134 0.72,1.46411 1.52,1.46411h12.96c0.8,0 1.52,-0.65072 1.52,-1.38278v-8.21531c0,-0.8134 -0.72,-1.46411 -1.52,-1.46411zM5.04,3.82297c0,-1.38278 1.12,-2.60287 2.56,-2.60287c1.44,0 2.56,1.13876 2.56,2.60287v2.03349h-5.12zM14.72,15.69856c0,0.08134 -0.16,0.16268 -0.24,0.16268h-12.96c-0.16,0 -0.24,-0.08134 -0.24,-0.16268v-8.29665c0,-0.16268 0.08,-0.16268 0.24,-0.16268h12.96c0.16,0 0.24,0.08134 0.24,0.16268zM9.52,10.81818c0,-0.89474 -0.72,-1.54545 -1.52,-1.54545c-0.8,0 -1.52,0.73206 -1.52,1.54545c0,0.56938 0.32,1.13876 0.8,1.38278v1.13876c0,0.32536 0.24,0.65072 0.64,0.65072h0.16c0.32,0 0.64,-0.24402 0.64,-0.65072v-1.13876c0.48,-0.24402 0.8,-0.73206 0.8,-1.38278z" 
            vectorEffect="non-scaling-stroke"/>
          </defs>
        </g>
    </svg>
  );
};

export default LockIcon2;
