import React from "react";
import classnames from "classnames";

type TokenIconProps = {
  className?: string;
};

const TokenIcon: React.FunctionComponent<TokenIconProps> = ({
  className = "",
}) => {
  return (
    <span className={classnames("TokenIcon", className && className)}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
      >
        <g>
          <polygon points="30.9,32.4 36.5,32.4 36.5,48.2 38.4,48.2 38.4,32.4 44,32.4 44,30.5 30.9,30.5 	" />
          <polygon points="17.9,16.8 26,10.4 28.7,12.3 29.5,11.5 22.8,7.2 22,8 24.7,9.8 16.9,16 	" />
          <path
            d="M49.8,14.6c0-1.3-0.3-2.7-0.8-3.7c-0.8-1.9-2.7-3.7-4.8-5.4c-4.6-3.2-11.5-5.4-19.3-5.4S10.2,2.2,5.6,5.5
		C3.5,7.1,1.9,9,0.8,10.9C0.3,12.3,0,13.3,0,14.7c0,0.5,0,1.1,0.3,1.6C0,16.8,0,17.4,0,17.9c0,0.4,0,0.9,0.2,1.4c0,0,0,0,0,0
		C0,20,0,20.6,0,21.1c0,0.4,0,0.9,0.2,1.6c0,0.1,0,0.2,0,0.3C0,23.5,0,23.8,0,24.3s0,1.3,0.3,1.9v-0.3c0,0,0.1,0,0.1,0v0.3
		C0,26.7,0,27.3,0,27.8c0,1.3,0.3,2.7,0.8,3.7c0.8,1.9,2.7,3.7,4.8,5.4c1.8,1.4,4,2.4,6.5,3.2C13.4,53.5,24.6,63.7,38,63.7
		c14.5,0,26-11.8,26-26C64,27.7,58.2,19,49.8,14.6z M12,38c-2.6-1.1-4.5-2.4-5.9-3.9c1.7,1.2,3.7,2.1,5.9,2.9c0,0.3,0,0.5,0,0.8
		C12,37.8,12,37.9,12,38z M12.2,34.6C9.5,33.4,7,32,5.6,30.1c1.8,1.4,4.1,2.5,6.8,3.2C12.3,33.8,12.2,34.2,12.2,34.6z M12.8,31.4
		c-2.2-0.8-4.1-1.8-5.6-3.1c1.7,1,3.6,1.8,5.7,2.4C12.9,30.9,12.8,31.1,12.8,31.4z M13.6,28.8c-2.3-0.9-4.5-2.1-6.2-3.6
		c1.9,1,4.1,1.9,6.6,2.7C13.8,28.2,13.7,28.5,13.6,28.8z M3.2,14.7c0-7,9.6-12.6,21.7-12.6c11.2,0,20.4,4.9,21.6,11.1
		c-2.7-0.9-5.5-1.5-8.5-1.5c-10.2,0-19,5.9-23.2,14.3C8,23.8,3.2,19.7,3.2,14.7z M38,60.5c-12.6,0-22.8-10.2-22.8-22.8
		c0-12.6,10.2-22.8,22.8-22.8s22.8,10.2,22.8,22.8C60.8,50.3,50.6,60.5,38,60.5z"
          />
        </g>
      </svg>
    </span>
  );
};

export default TokenIcon;
