import React from "react";
import "./styles.scss";
import clsx from "clsx";

type CancelCircleIconProps = {
  className?: string;
};

const CancelCircleIcon: React.ComponentType<CancelCircleIconProps> = ({
  className,
}) => (
  <span className={clsx("CancelCircleIcon", className && className)}>
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  </span>
);

export default CancelCircleIcon;
