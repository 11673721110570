import React from "react";
import "./styles.scss";
import { IChatMessage } from "common/broadcast/_stores/chat/interfaces";
import ChatMessageText from "common/broadcast/broadcast-chat/chat-message-text";
import ChatMessageTip from "common/broadcast/broadcast-chat/chat-message-tip";
import ChatMessageGift from "common/broadcast/broadcast-chat/chat-message-gift";
import ChatMessageWhisper from "./chat-message-whisper";
import { ModelPricingResponseChatSystemSettings } from "../_stores/pricing/types";
import { ChatModeEnum } from "../_stores/chat/enums";

type Props = {
  key?: any;
  message: IChatMessage;
  system_settings: ModelPricingResponseChatSystemSettings;
  chatMode?: ChatModeEnum;
  onWhisperBack?: (username: string) => void;
};

const ChatMessage: React.FC<Props> = ({
  message,
  system_settings,
  chatMode,
  onWhisperBack,
}) => {
  const { whispers_in_separate_tabs } = system_settings!;

  return (
    <>
      {message.type === "text" ||
      (message.type === "whisper" && chatMode === ChatModeEnum.WHISPER) ? (
        <ChatMessageText message={message} />
      ) : null}
      {message.type === "tip" && <ChatMessageTip message={message} />}
      {message.type === "gift" && <ChatMessageGift message={message} />}
      {message.type === "whisper" &&
      chatMode !== ChatModeEnum.WHISPER &&
      !whispers_in_separate_tabs ? (
        <ChatMessageWhisper
          message={message}
          isTabModeActive={whispers_in_separate_tabs}
          onWhisperBack={onWhisperBack}
        />
      ) : null}
    </>
  );
};

export default ChatMessage;
