export enum MyPagePromptMode {
  SWITCH,
  DELETE,
  DELETE_PREMIUM,
  EXIT,
  FILTER,
  SORT,
  VIDEOALBUM,
}
export enum ModelMediaVisibility {
  PRIVATE = "private",
  GUESTS = "guests",
  MEMBERS = "members",
  FAN_CLUB = "fanclub",
}

export enum VideoRejectReasonEnum {
  DENIED_VIOLATION_OF_TOS = "denied_violation_of_tos",
  DENIED_VIDEO_UNPLAYABLE = "denied_video_unplayable",
  DENIED_PERSONAL_CONTACT_INFO = "denied_personal_contact_info",
  DENIED_COMMERCIAL_SOLICITATION = "denied_commercial_solicitation",
  DENIED_COMMERCIAL_VIDEO = "denied_commercial_video",
  DENIED_ILLEGAL_ACTIVITY = "denied_illegal_activity",
  DENIED_STOLEN_CONTENT = "denied_stolen_content",
  DENIED_STOLEN_FROM_PORN_SITE = "denied_stolen_from_porn_site",
  DENIED_INAPPROPRIATE = "denied_inappropriate",
  DENIED_INAPPROPRIATE_DESCRIPTION = "denied_inappropriate_description",
  DENIED_OTHER = "denied_other",
  DENIED_VIDEO_COULD_NOT_PROCESS = "denied_video_processing",
  DENIED_UNAUTHORIZED_PERFORMER = "denied_unauthorized_performer",
}

export enum ModelMediaRouteMode {
  PHOTOS = "photos",
  UPLOADED_VIDEOS = "uploadedVideos",
  RECORDED_VIDEOS = "recordedVideos",
}

export enum ModelMediaManagerMode {
  "EDIT" = "edit",
  "LIST" = "list",
  "CREATE" = "create",
}

export enum MediaRating {
  PG = "PG",
  PG13 = "PG13",
  R = "R",
  X = "X"
}