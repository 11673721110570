import React from "react";
import TailwindBox, { TailwindBoxProps } from "../box";

export type FeedFooterProps = {
  wrapperProps?: TailwindBoxProps;
};

const FeedFooter: React.ComponentType<FeedFooterProps> = ({ wrapperProps, children }) => {
  return (
    <TailwindBox
      {...wrapperProps}
      borderColor={"border-gray-600"}
      borderWidth={["border-t"]}
      borderStyle={"border-solid"}
      padding={["p-3"]}>
      {children}
    </TailwindBox>
  );
};

export default FeedFooter;
