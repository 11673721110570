import React, { useCallback, useMemo } from "react";
import { inject, observer } from "mobx-react";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import TailwindSwitch from "library/components/_tailwind/switch";
import TailwindDropdown, {
  TailwindDropdownValue,
} from "library/components/_tailwind/dropdown";
import {
  MODEL_2257_FLAG_ACTION_NAMES,
  MODEL_2257_FLAG_CODES,
} from "common/my-page/stores/profile/consts";
import { injectIntl, WrappedComponentProps } from "react-intl";
import TailwindButton from "library/components/_tailwind/button";
import { openAccountAuditedDisclaimerModal } from "core/stores/auth/utils";
import MessageStore from "common/messages/stores/messages/MessageStore";

type Props = {
  profileStore?: ProfileStore;
  messageStore?: MessageStore;
};

const QAWidgetProfile: React.ComponentType<Props & WrappedComponentProps> = ({
  profileStore,
  messageStore,
  intl,
}) => {
  const { audits, hasAcknowledgedAllAudits, notifications, initNotifications } =
    messageStore!;
  const {
    profileInfoCompleted,
    setProfileInfoCompleted,
    shouldShowTutorialToModel,
    setShouldShowTutorialToModel,
    modelProfile,
    mock2257WarningReason,
    setMock2257WarningReason,
    mock2257WarningResult,
    setMock2257WarningResult,
    mock2257WarningShouldShowMockImage,
    setMock2257WarningShouldShowMockImage,
    setMock2257WarningHasAcknowledgedAllAudits,
  } = profileStore!;

  const toggleProfileCompleted = useCallback(() => {
    setProfileInfoCompleted(!profileInfoCompleted);
  }, [profileInfoCompleted]);

  const onChange2257Reason = useCallback((value: TailwindDropdownValue) => {
    if (value) {
      setMock2257WarningReason(value as string | undefined);
    }
  }, []);

  const onChange2257Result = useCallback((value: TailwindDropdownValue) => {
    if (value) {
      setMock2257WarningResult(value as string | undefined);
    }
  }, []);

  const reasonsFor2257 = useMemo(
    () => [
      ...MODEL_2257_FLAG_CODES.map(key => ({
        label: `${key} - ${intl.formatMessage(
          {
            id: `model2257.${key}.subject`,
          },
          {
            modelStreamName: modelProfile?.screen_name,
          }
        )}`,
        value: key,
      })),
      {
        value: undefined,
        label: "The actual real value that backend sends",
      },
    ],
    [modelProfile]
  );

  const resultsFor2257 = useMemo(
    () => [
      ...MODEL_2257_FLAG_ACTION_NAMES.map(name => ({
        label: name,
        value: name,
      })),
      {
        value: undefined,
        label: "The actual real value that backend sends",
      },
    ],
    [modelProfile]
  );

  const onClickFakeAuditAcknowledgement = useCallback(async () => {
    setMock2257WarningHasAcknowledgedAllAudits(false);
    await initNotifications();
  }, [notifications, audits]);

  return (
    <React.Fragment>
      <div className='FeatureSwitch__Cobrand-item'>
        <TailwindSwitch
          size={"xs"}
          label={"Profile Completed?"}
          onLabel={"Yes"}
          offLabel={"No"}
          onChange={toggleProfileCompleted}
          isChecked={profileInfoCompleted}
        />
      </div>
      <div className='FeatureSwitch__Cobrand-item'>
        <TailwindSwitch
          size={"xs"}
          label={"Show tutorial?"}
          onLabel={"Yes"}
          offLabel={"No"}
          onChange={setShouldShowTutorialToModel}
          isChecked={shouldShowTutorialToModel}
        />
      </div>
      {audits.length > 0 && (
        <React.Fragment>
          <div className='FeatureSwitch__Cobrand-item'>
            <TailwindSwitch
              size={"xs"}
              label={"Show mock image for audit?"}
              onLabel={"Yes"}
              offLabel={"No"}
              onChange={setMock2257WarningShouldShowMockImage}
              isChecked={mock2257WarningShouldShowMockImage}
            />
          </div>
          <div className='FeatureSwitch__Cobrand-item'>
            <TailwindButton
              backgroundColor={"primary"}
              onClick={onClickFakeAuditAcknowledgement}>
              Make audits and notifications 'UNREAD' & refresh the notifs/audits
            </TailwindButton>
          </div>
          <div className='FeatureSwitch__Cobrand-item'>
            <TailwindButton
              backgroundColor={"primary"}
              fullWidth
              onClick={() =>
                openAccountAuditedDisclaimerModal(hasAcknowledgedAllAudits)
              }>
              Open Audit Disclaimer Popup
            </TailwindButton>
          </div>
          <div className='FeatureSwitch__Cobrand-item'>
            <TailwindDropdown
              menuProps={{
                maxHeight: "max-h-48",
              }}
              backgroundColor={"secondary"}
              fullWidth={true}
              label={"2257 Warning/Penalty Reason (Flag)"}
              value={mock2257WarningReason}
              onChange={onChange2257Reason}
              name={"qa-widget-2257-reason"}
              items={reasonsFor2257}
            />
          </div>
          <div className='FeatureSwitch__Cobrand-item'>
            <TailwindDropdown
              menuProps={{
                maxHeight: "max-h-48",
              }}
              backgroundColor={"secondary"}
              fullWidth={true}
              label={"2257 Warning/Penalty Result (Flag Action)"}
              value={mock2257WarningResult}
              onChange={onChange2257Result}
              name={"qa-widget-2257-result"}
              items={resultsFor2257}
            />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default injectIntl(
  inject("profileStore", "messageStore")(observer(QAWidgetProfile))
);
