import React from "react";
import "./styles.scss";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import TailwindTooltip from "../tooltip";

export type HelpTooltipProps = {
  tooltip: string;
  size?: "small" | "medium";
  className?: string;
  tooltipClassName?: string;
};

const HelpTooltip: React.FunctionComponent<HelpTooltipProps> = ({
  tooltip,
  size = "medium",
  className = "",
  tooltipClassName = "",
}) => (
  <TailwindTooltip content={tooltip} className={tooltipClassName}>
    <HelpOutlineIcon
      className={`HelpTooltip__icon ${className} ${
        size === "small" ? "HelpTooltip__icon--small" : ""
      }`}>
      help_outline
    </HelpOutlineIcon>
  </TailwindTooltip>
);

export default HelpTooltip;
