import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import Filters from "./filters";
import { PERIOD_TYPES } from "../../_stores/earnings/enums";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindFlex from "library/components/_tailwind/flex";
import EarningsStore from "../../_stores/earnings/EarningsStore";
import HistoricalEarnings from "../../historical-earnings";

type StudioPayoutHeaderProps = {
  earningsStore?: EarningsStore;
};

const StudioPayoutHeader: React.FunctionComponent<
  StudioPayoutHeaderProps & WrappedComponentProps
> = ({ earningsStore }) => {
  const { periodType } = earningsStore!;

  return (
    <TailwindFlex flexDirection={"flex-col"} width={"w-full"} md={"w-full"}>
      <TailwindFlex
        flexDirection={"flex-row"}
        justifyContent={"justify-between"}
        alignItems={"items-center"}
        width={"w-full"}
        md={"w-full"}>
        <TailwindTranslatedText
          headingProps={{
            level: 5,
            margin: ["mb-3"],
          }}
          descriptor={{
            id: "studioPayout.earning.title",
            defaultMessage: "Earnings Report",
          }}
        />
        <HistoricalEarnings />
      </TailwindFlex>
      <TailwindTranslatedText
        descriptor={{
          id:
            periodType === PERIOD_TYPES.payPeriod_8_22
              ? "studioPayout.earning.description_2"
              : "studioPayout.earning.description_1",
          defaultMessage: "",
        }}
      />
      <Filters />
    </TailwindFlex>
  );
};

export default injectIntl(
  inject("earningsStore")(observer(StudioPayoutHeader))
);
