import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";
import ProfileInfo from "../../components/profile/info";
import ProfileBadges from "../../components/profile/badges";
import ProfileOnlineStatus from "../../components/profile/online-status";
import TailwindFlex from "../../components/_tailwind/flex";

type Props = {};

const StorybookComponentsProfilePage: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Profile Page</StorybookHeading>
      <StorybookParagraph>
        The profile components are pure components that are only used to compose
        other components such as Media & Feed components.
      </StorybookParagraph>
      <StorybookParagraph>
        These components are so far only used on AFF but will be used in other
        projects later.
      </StorybookParagraph>
      <StorybookParagraph>Here are some examples:</StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<ProfileInfo
          username={"someusername"}
          age={25}
          gender={"male"}
          genderShort={"M"}
        />`}
      >
        <ProfileInfo
          username={"someusername"}
          age={25}
          gender={"male"}
          genderShort={"M"}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<ProfileBadges isVerified={true} isPremiumVIP={true} />`}
      >
        <ProfileBadges isVerified={true} isPremiumVIP={true} />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<ProfileOnlineStatus status={"broadcasting"} />`}
      >
        <TailwindFlex width={"w-auto"}>
          <ProfileOnlineStatus status={"broadcasting"} />
        </TailwindFlex>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsProfilePage;
