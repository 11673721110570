import React, { useMemo, useState } from "react";
import { format } from "date-fns";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindInput from "library/components/_tailwind/input";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import TailwindBox from "library/components/_tailwind/box";
const NotesCard: React.FunctionComponent<{
  note: any;
  onDelete: Function;
  onEdit: Function;
  memberId: string;
  isEditingNotes: string;
  setisEditingNotes: Function;
}> = ({
  note,
  onDelete,
  memberId,
  onEdit,
  isEditingNotes,
  setisEditingNotes,
}) => {
  const noteCreatedDate = note?.modified_at
    ? format(new Date(note?.modified_at), "MM/dd/yyyy HH:mm")
    : format(new Date(note?.created_at), "MM/dd/yyyy HH:mm");
  const [newValue, setNewValue] = useState<any>(note?.note);
  const handEditNote = () => {
    if (!textLength || !newValue.replaceAll(" ", "").length) {
      setisEditingNotes("");
      onDelete(note?.id);
    } else {
      onEdit(newValue, memberId, note.id);
    }
  };

  const textLength = useMemo(() => {
    if (newValue) {
      return newValue.replaceAll("\n", "").toString().length;
    } else {
      return 0;
    }
  }, [newValue]);
  return (
    <>
      <TailwindFlex
        flexDirection='flex-col'
        display='flex'
        justifyContent='justify-start'
        textColor='text-main-color'
        flexWrap='flex-wrap'
        overflow='overflow-hidden'
        height='h-auto'
        borderColor='border-main-color'
        borderWidth={isEditingNotes === note.id ? ["border"] : ["border-0"]}
        borderRadius={"rounded-lg"}
        margin={["mb-1"]}
        padding={["pt-1"]}
        alignItems='items-start'
        backgroundColor={"bg-primary-bg-color"}>
        <TailwindFlex
          margin={["mt-1"]}
          textColor={"text-main-color"}
          width='w-full'
          alignItems='items-center'
          padding={["p-0", "px-4"]}
          justifyContent='justify-between'>
          <TailwindText
            fontSize='text-xs'
            fontStyle='italic'
            margin={["mt-2"]}
            width='w-full'
            textColor='text-gray-400'
            fontWeight='font-normal'>
            {noteCreatedDate + " PST"}
          </TailwindText>
          <TailwindFlex
            flexDirection='flex-row'
            width='w-8'
            justifyContent='justify-end'>
            {isEditingNotes !== note.id && (
              <TailwindIcon
                cursor='cursor-pointer'
                onClick={() => setisEditingNotes(note.id)}
                name='notes-pencil'
                fontSize='text-xs'
              />
            )}
            <TailwindIcon
              cursor='cursor-pointer'
              onClick={() => onDelete(note.id)}
              name='delete'
            />
          </TailwindFlex>
        </TailwindFlex>
        {isEditingNotes !== note.id && (
          <TailwindText
            width='w-full'
            wordBreak='break-words'
            padding={["px-4"]}
            margin={["mt-2", "mb-4"]}>
            {note?.note}
          </TailwindText>
        )}
        {isEditingNotes === note.id && (
          <TailwindBox width='w-full' height='h-full'>
            <TailwindInput
              backgroundColor='bg-primary-bg-color'
              onChange={e => setNewValue(e.target.value)}
              type={TailwindInputType.textarea}
              value={newValue}
              boxProps={{
                height: "h-auto",
                width: "w-full",
                margin: ["mt-2"],
                padding: ["p-0"],
                overflow: "overflow-hidden",
              }}
              maxLength={500}
              padding={["px-4"]}
              rows={5}
              minRows={5}
              maxRows={5}
            />
          </TailwindBox>
        )}
      </TailwindFlex>
      {isEditingNotes === note.id && (
        <TailwindFlex
          margin={["mb-4"]}
          textColor={"text-main-color"}
          width='w-full'
          padding={["p-0"]}
          justifyContent='justify-between'>
          {textLength}/500
          <TailwindText
            padding={["p-0"]}
            width='w-8'
            height='h-full'
            display={"block"}
            alignItems='items-end'
            textColor='text-green-500'
            onClick={handEditNote}
            cursor='cursor-pointer'>
            Save
          </TailwindText>
        </TailwindFlex>
      )}
    </>
  );
};
export default NotesCard;
