import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindPill from "library/components/_tailwind/pill";
import { MyFansPageMemberStats } from "containers/model/my-fans/stores/my-fans/types";

type MemberBadgesProps = {
  fan: MyFansPageMemberStats;
};

const MemberBadges: React.ComponentType<MemberBadgesProps> = ({ fan }) => {
  return (
    <TailwindFlex
      alignContent={"content-start"}
      width={"w-auto"}
      flexDirection={"flex-row"}
      flexShrink={"flex-shrink"}
      height={"h-8"}
      alignItems={"items-center"}>
      {fan.is_fanclub && (
        <TailwindPill
          float={"float-left"}
          margin={["mr-1"]}
          className={["whitespace-nowrap"]}
          borderRadius={"rounded-xl"}
          backgroundColor={"bg-blue-300"}
          textColor={"text-white"}
          fontSize={"text-xs"}>
          FC
        </TailwindPill>
      )}
      {fan.top_admirer_rank !== undefined &&
        fan.top_admirer_rank !== null &&
        fan.top_admirer_rank <= 5 && (
          <TailwindPill
            margin={["mr-1"]}
            float={"float-left"}
            className={["whitespace-nowrap"]}
            borderRadius={"rounded-xl"}
            backgroundColor={"bg-pink-500"}
            textColor={"text-white"}
            fontSize={"text-xs"}>{`#${fan.top_admirer_rank}`}</TailwindPill>
        )}
      {fan.is_bounty && (
        <TailwindPill
          float={"float-left"}
          borderRadius={"rounded-xl"}
          backgroundColor={"bg-yellow-500"}
          textColor={"text-white"}
          fontSize={"text-xs"}>
          B
        </TailwindPill>
      )}
    </TailwindFlex>
  );
};

export default MemberBadges;
