import React from "react";
import "./styles.scss";
import classnames from "classnames";

type FilterNarrowDownIconProps = {
  className?: string;
};

const FilterNarrowDownIcon: React.ComponentType<FilterNarrowDownIconProps> = ({
  className,
}) => (
  <span className={classnames("FilterNarrowDownIcon", className && className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M10,18h4v-2h-4V18z M3,6v2h18V6H3z M6,13h12v-2H6V13z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  </span>
);

export default FilterNarrowDownIcon;
