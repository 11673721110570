export enum PERIOD_TYPES {
  "payPeriod_1_16" = 1,
  "payPeriod_8_22" = 2,
}

export enum MODEL_TRANSACTION_EARNING_TYPE {
  BASED_ON_TIER = "% based on tiers",
  PERC_ON_TIPS = "15% on their tips for a year!",
  PERC_FOR_YEAR = "15% for a year!",
  REFERED_BY_YOU = "100% first 6 months, 80% rest of year",
  FIRST_PAID_SHOW = "By Being a members first paid show",
}

export enum TransactionProduct {
  TIP = "tip",
  TIP_OFFLINE = "tip_offline",
  VIRTUAL_GIFT = "virtual_gift",
  VIRTUAL_GIFT_OFFLINE = "virtual_gift_offline",
}

export enum TransactionLocation {
  ONLINE = "online",
  OFFLINE = "offline",
}

export enum TransactionProductType {
  TIERED_TRANSACTION = "tiered_transaction",
  MODEL_BONUS = "model_bonus",
  MEMBER_REFERRAL_FIRST_SIX_MONTHS = "member_referral",
  MEMBER_REFERRAL_AFTER_SIX_MONTHS = "member_referral",
  PAYOUT_ADJUSTMENT = "custom_refund",
  CURRENT_PERIOD_REFUND = "refund",
  MEMBER_REGULAR_CREDIT = "member_credit",
  MEMBER_BONUS = "member_bonus",
  MEMBER_REFUND = "member_refund",
  BALANCE_SYNC = "balance_sync",
  MEDIA_TRANSACTION = "media",
  MEMBER_BOUNTY_REFERRAL = "member_bounty_referral",
  FAN_CLUB_MEMBERSHIP = "fan_club",
  FAN_CLUB_MEMBERSHIP_FIRST_SIX_MONTHS = "fan_club_referral",
  FAN_CLUB_MEMBERSHIP_AFTER_SIX_MONTHS = "fan_club_referral",
  FAN_CLUB_MEMBERSHIP_REFUND = "fun_club_refund",
  FAN_CLUB_MEMBERSHIP_FIRST_SIX_MONTHS_REFUND = "fun_club_refund_referral",
  FAN_CLUB_MEMBERSHIP_AFTER_SIX_MONTHS_REFUND = "fun_club_refund_referral",
  MODEL_REFERRAL_1_2_MONTH = "model_referral",
  MODEL_REFERRAL_3_4_MONTH = "model_referral",
  MODEL_REFERRAL_5_6_MONTH = "model_referral",
  MODEL_REFERRAL_7_8_MONTH = "model_referral",
  MODEL_REFERRAL_9_10_MONTH = "model_referral",
  MODEL_REFERRAL_11_12_MONTH = "model_referral",
  MEMBER_REFERRAL_TO_ANOTHER_MODEL = "member_referral_to_another_model",
  MODEL_REFERRAL = "model_referral",
  MEMBER_DEDUCT = "member_deduct",
  NOT_PROCESSED = "not_processed",
}

export enum TransactionProductSubType {
  VIRTUAL_GIFT = "virtual_gift",
  TIP_ONLINE = "tip_online",
  TIP_OFFLINE = "tip_offline",
  CHAT_SHOW = "chat_show",
  CONNEXION = "connexion",
  FANCLUB = "fanclub",
  MAINTENANCE = "maintenance",
  BOUNTY = "bounty",
  BONUS = "bonus",
  TEXT = "text",
  MODEL_MEDIA = "model_media",
  TIERED_PRODUCT = "tiered_product",
  BUZZ = "buzz",
  SUPER_BUZZ = "super_buzz",
}
