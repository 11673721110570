import React from "react";
import TailwindButton from "library/components/_tailwind/button";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import { WrappedComponentProps, injectIntl } from "react-intl";
import { inject, observer } from "mobx-react";
import LayoutStore from "library/core/stores/layout/LayoutStore";

type SendMessageButtonProps = {
  onSubmit: () => void;
  disabled?: boolean;
  layoutStore?: LayoutStore;
};

const SendMessageButton: React.ComponentType<
  SendMessageButtonProps & WrappedComponentProps
> = ({ onSubmit, disabled, layoutStore, intl }) => {
  const { isMobileDevice } = layoutStore!;

  return (
    <TailwindButton
      onClick={() => onSubmit()}
      backgroundColor={"bg-modal-button-color"}
      borderRadius={"rounded-full"}
      textColor={"text-white"}
      width={isMobileDevice ? "w-10" : "w-auto"}
      fullWidth={false}
      alignItems={"items-center"}
      justifyContent={"justify-center"}
      padding={isMobileDevice ? ["p-0"] : ["px-4"]}
      disabled={disabled}
      settings={true}
      disabledProps={{
        backgroundColor: "bg-gray-300",
        textColor: "text-white",
      }}
      rightIconProps={{
        name: CustomIconName.sendAirplane,
        fontSize: "text-lg",
      }}>
      {isMobileDevice
        ? null
        : intl.formatMessage({
            id: "common.send",
            defaultMessage: "Send",
          })}
    </TailwindButton>
  );
};

export default injectIntl(inject("layoutStore")(observer(SendMessageButton)));
