import React from "react";
import "./styles.scss";

type NewsPaperIconProps = {
  className?: string;
};

const NewsPaperIcon: React.ComponentType<NewsPaperIconProps> = ({
  className,
}) => (
  <span className={`${className} camsIcon`}>
    <svg
      viewBox='0 0 68 59'
      xmlns='http://www.w3.org/2000/svg'
      style={{
        fill: "transparent",
      }}>
      <path
        d='M66.3846 4.66672V56.4616H6.64104'
        stroke='currentColor'
        strokeWidth='3.20513'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M63.564 1.84619H2.53839V55.1795H63.564V1.84619Z'
        stroke='currentColor'
        strokeWidth='3.20513'
        strokeLinejoin='round'
      />
      <path
        d='M56.1282 11.077H33.4359V34.2821H56.1282V11.077Z'
        stroke='currentColor'
        strokeWidth='3.20513'
        strokeLinejoin='round'
      />
      <path
        d='M10.2308 11.077H27.4103'
        stroke='currentColor'
        strokeWidth='3.20513'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.2308 22.7437H27.4103'
        stroke='currentColor'
        strokeWidth='3.20513'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.2308 34.2821H27.4103'
        stroke='currentColor'
        strokeWidth='3.20513'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.2308 45.8205H56.1282'
        stroke='currentColor'
        strokeWidth='3.20513'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </span>
);

export default NewsPaperIcon;
