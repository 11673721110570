import React, { useEffect, useMemo } from "react";
import MessageCenterMessageList from "./messages-reader-message-list";
import MessageCenterReaderFooter from "./messages-reader-footer";
import MessageCenterNewsAndPolicies from "./messages-reader-news-and-policies";
import { inject, observer } from "mobx-react";
import MessageStore from "./stores/messages/MessageStore";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import ProfileStore from "../my-page/stores/profile/ProfileStore";
import TailwindFlex from "library/components/_tailwind/flex";
import MessageCenterReaderSubHeader from "./messages-reader-textarea-subHeader";
import MessageCenterReaderFooterBlocked from "./messages-reader-footer-blocked";
import AccountSettingsStore from "common/account-settings/stores/account-settings/AccountSettingsStore";
import { ConversationBlockedErrors } from "./stores/messages/consts";

type Props = {
  floatingBarChildren?: React.ReactNode;
  messageStore?: MessageStore;
  layoutStore?: LayoutStore;
  profileStore?: ProfileStore;
  accountSettingsStore?: AccountSettingsStore;
  goBack?: () => void;
};

const MessageCenterReader: React.ComponentType<Props> = ({
  floatingBarChildren,
  messageStore,
  layoutStore,
  profileStore,
  accountSettingsStore,
  goBack,
}) => {
  const { isMobileScreenSize } = layoutStore!;
  const { profile } = profileStore!;
  const {
    activeConversation,
    activeConversationMessages,
    setMessage,
    sendMessage,
    message,
    isMessagesLoading,
    isMessagesLoadingMore,
    getActiveConversationMessages,
    getSearchedConversationFutureMessages,
    hasMoreMessages,
    hasMoreFutureMessages,
    activeNewsAndPolicy,
    sendMessageToMember,
    systemMessageUser,
    conversations,
    sendMessageError,
    activeSearchedWord,
    searchingMessagesStartDate,
    unBlockActiveConversation,
    isActiveConversationBlocked,
    activeConversationBlockedType,
    activeConversationBlockedCountryId,
    activeConversationBlockedCountryLabel,
  } = messageStore!;
  const { unblockCountry, unblockMember, blockedMembers, getBlockedMembers } =
    accountSettingsStore!;

  const sendSystemMessage = async () => {
    if (systemMessageUser) {
      const pendingMessage = message;
      await sendMessageToMember(systemMessageUser.id);
      setMessage(pendingMessage);
      sendMessage();
    }
  };

  const hasConversations = useMemo(
    () => Array.isArray(conversations) && conversations.length > 0,
    [conversations]
  );

  const firstConversationParticipant = useMemo(
    () =>
      activeConversation?.participants?.find(
        member => member.profile_type !== "model"
      )?.username || "Member",
    [activeConversation]
  );

  const isMemberBlocked = useMemo(
    () =>
      (isActiveConversationBlocked &&
        activeConversationBlockedType === "member") ||
      sendMessageError === ConversationBlockedErrors.member,
    [
      isActiveConversationBlocked,
      activeConversationBlockedType,
      sendMessageError,
    ]
  );

  const isBlockedCountry = useMemo(
    () =>
      (isActiveConversationBlocked &&
        activeConversationBlockedType === "country") ||
      sendMessageError === ConversationBlockedErrors.country,
    [
      isActiveConversationBlocked,
      activeConversationBlockedType,
      sendMessageError,
    ]
  );

  const isBlockedState = useMemo(
    () =>
      (isActiveConversationBlocked &&
        activeConversationBlockedType === "state") ||
      sendMessageError === ConversationBlockedErrors.state,
    [
      isActiveConversationBlocked,
      activeConversationBlockedType,
      sendMessageError,
    ]
  );

  const countryISO = useMemo(() => {
    let country = activeConversationBlockedCountryLabel || "";
    if (isBlockedState) {
      country = `US-${country}`;
    }

    return country;
  }, [activeConversationBlockedCountryLabel]);

  const blockConversation = useMemo(
    () =>
      (isMemberBlocked ||
        isBlockedCountry ||
        isBlockedState ||
        sendMessageError === ConversationBlockedErrors.member ||
        sendMessageError === ConversationBlockedErrors.country ||
        sendMessageError === ConversationBlockedErrors.state) &&
      firstConversationParticipant &&
      !isMessagesLoading,
    [
      isMemberBlocked,
      isBlockedCountry,
      isBlockedState,
      firstConversationParticipant,
      isMessagesLoading,
      sendMessageError,
    ]
  );

  const unBlockConvoByCountry = async () => {
    if (activeConversationBlockedCountryId) {
      await unblockCountry({
        id: activeConversationBlockedCountryId,
        countryLabel: countryISO,
      });
      unBlockActiveConversation();
    }
  };

  const unblockConvoByMember = async () => {
    if (blockedMembers?.length) {
      const target = blockedMembers.find(
        blocked_member =>
          blocked_member.username === firstConversationParticipant
      );
      await unblockMember(target);
      unBlockActiveConversation();
    }
  };

  useEffect(() => {
    if (activeConversation) {
      getBlockedMembers();
    }
  }, [activeConversation]);

  return (
    <>
      <TailwindFlex
        height={isMobileScreenSize ? "h-screen" : "h-full"}
        maxHeight={isMobileScreenSize ? "max-h-full-dynamic" : "max-h-full"}
        flexDirection={"flex-col"}
        flexGrow={"flex-grow"}
        // margin={!activeConversation ? ["mt-14"] : []}
        backgroundColor={"bg-quaternary-bg-color"}
        borderRadius={"rounded-xl"}
        overflow={"overflow-hidden"}
        className={["MessagesReader"]}>
        <>
          {activeConversation && goBack && (
            <MessageCenterReaderSubHeader
              goBack={goBack}
              activeConversation={activeConversation}
            />
          )}
          {floatingBarChildren ? (
            <TailwindFlex
              className={["MessageCenterReader__floating-bar"]}
              alignItems={"items-center"}>
              {floatingBarChildren}
            </TailwindFlex>
          ) : null}
          {activeNewsAndPolicy && (
            <MessageCenterNewsAndPolicies notice={activeNewsAndPolicy} />
          )}
          {!activeNewsAndPolicy && (
            <MessageCenterMessageList
              hasConversations={hasConversations}
              messages={activeConversationMessages}
              getMessages={getActiveConversationMessages}
              getFutureMessages={getSearchedConversationFutureMessages}
              conversation={activeConversation}
              message={message}
              isLoading={isMessagesLoading}
              isLoadingMore={isMessagesLoadingMore}
              hasMore={hasMoreMessages}
              hasMoreFuture={hasMoreFutureMessages}
              userName={profile.username}
              highlightedWord={activeSearchedWord}
              initAtMessageStartDate={searchingMessagesStartDate}
            />
          )}
          {blockConversation && !activeNewsAndPolicy ? (
            <MessageCenterReaderFooterBlocked
              memberName={firstConversationParticipant}
              blockType={
                isBlockedCountry
                  ? "country"
                  : isBlockedState
                  ? "state"
                  : isMemberBlocked
                  ? "member"
                  : null
              }
              countryISO={countryISO}
              onUnblock={
                isMemberBlocked ? unblockConvoByMember : unBlockConvoByCountry
              }
            />
          ) : !activeNewsAndPolicy ? (
            <MessageCenterReaderFooter
              hasActiveConversation={activeConversation !== null}
              setMessage={setMessage}
              message={message}
              sendMessage={
                activeNewsAndPolicy ? sendSystemMessage : sendMessage
              }
              conversation={activeConversation}
            />
          ) : null}
        </>
      </TailwindFlex>
    </>
  );
};

export default inject(
  "profileStore",
  "messageStore",
  "layoutStore",
  "accountSettingsStore"
)(observer(MessageCenterReader));
