import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindHeading from "library/components/_tailwind/heading";
import TailwindButton from "library/components/_tailwind/button";

type Props = {
  onAccept: () => void;
  onDecline: () => void;
};

const MessageCenterUnblockModal: React.ComponentType<
  Props & WrappedComponentProps
> = ({ onAccept, onDecline, intl }) => {
  return (
    <TailwindFlex
      backgroundColor={"bg-primary-bg-color"}
      flexDirection={"flex-col"}
      padding={["p-8"]}
      borderRadius={"rounded-xl"}>
      <TailwindHeading level={6}>
        {intl.formatMessage({
          id: "message-center.unblock_these_conversations",
          defaultMessage: "Are you sure you want to unblock this conversation?",
        })}
      </TailwindHeading>
      <TailwindFlex
        margin={["mt-8"]}
        justifyContent={"justify-end"}
        alignItems={"items-end"}>
        <TailwindFlex width={"w-auto"} margin={["mr-4"]}>
          <TailwindButton
            fullWidth
            onClick={onDecline}
            backgroundColor={"bg-transparent"}
            textProps={{
              textColor: "text-modal-button-color",
            }}
            borderStyle={"border-solid"}
            justifyContent={"justify-center"}>
            {intl.formatMessage({
              id: "verbiage.cancel",
              defaultMessage: "Cancel",
            })}
          </TailwindButton>
        </TailwindFlex>
        <TailwindFlex width={"w-auto"}>
          <TailwindButton
            padding={["px-6"]}
            backgroundColor={"bg-modal-button-color"}
            textProps={{ textColor: "text-white" }}
            justifyContent={"justify-center"}
            onClick={onAccept}>
            {intl.formatMessage({
              id: "accountSettings.blockUnblock.unblock",
              defaultMessage: "Unblock",
            })}
          </TailwindButton>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(inject()(observer(MessageCenterUnblockModal)));
