import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import ContestsStore from "containers/model/contests/stores/contests/ContestsStore";
import ContestCard from "containers/model/contests/contest-card";
import { injectIntl, WrappedComponentProps } from "react-intl";
import "./styles.scss";
import { IContest } from "./stores/contests/interfaces";
import { dateProcess } from "library/core/utility";
import TailwindSpinner from "library/components/_tailwind/spinner";
import { TailwindPillProps } from "library/components/_tailwind/pill";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindBox from "library/components/_tailwind/box";

type ContestContainerProps = {
  contestsStore?: ContestsStore;
};

const ContestContainer: React.ComponentType<
  ContestContainerProps & WrappedComponentProps
> = ({ contestsStore, intl }) => {
  const { contests, loading, getContests } = contestsStore!;

  useEffect(() => {
    getContests();
  }, []);

  const getRibbon = (contest: IContest) => {
    let ribbon: TailwindPillProps;
    switch (contest.status) {
      case "current":
        {
          ribbon = {
            backgroundColor: "bg-blue-500",
            textColor: "text-white",
            children: intl.formatMessage({
              id: "contests.currentContests",
              defaultMessage: "CURRENT CONTEST",
            }),
          };
        }
        break;
      case "upcoming":
        {
          ribbon = {
            backgroundColor: "bg-red-500",
            textColor: "text-white",
            children: intl.formatMessage({
              id: "contests.upcomingContests",
              defaultMessage: "UPCOMING CONTEST",
            }),
          };
        }
        break;
      default:
        {
          ribbon = {
            backgroundColor: "bg-blue-300",
            textColor: "text-white",
            children: intl.formatMessage({
              id: "contests.finishedContests",
              defaultMessage: "FINISHED CONTEST",
            }),
          };
        }
        break;
    }

    return ribbon;
  };

  if (loading) {
    return <TailwindSpinner />;
  } else {
    return (
      <TailwindFlex width={"w-auto"}>
        {contests.map((contest, index) => {
          return (
            <TailwindBox key={index} margin={["mb-3"]}>
              {/*//@ts-ignore*/}
              <ContestCard
                title={contest.title}
                summary={contest.description}
                banner={contest.banner}
                pill={getRibbon(contest)}
                copyright={index === 1}>
                <TailwindFlex pseudoClasses={["xs:flex-col"]}>
                  <TailwindBox
                    width={"w-full"}
                    margin={["mr-4"]}
                    pseudoClasses={[
                      "md:w-1/2",
                      "lg:w-4/12",
                      "xs:mr-0",
                      "sm:mr-0",
                    ]}>
                    <h6 className={"uppercase subTitle-1 colorPrimary"}>
                      {intl.formatMessage({
                        id: "contests.endsOn",
                        defaultMessage: "ends on",
                      })}
                    </h6>
                    <p className={"body-1 colorTertiary"}>
                      {dateProcess.dateFormatChange(
                        contest.submission_deadline
                      )}
                    </p>
                    <h6 className={"uppercase subTitle-1 colorPrimary"}>
                      {intl.formatMessage({
                        id: "contests.prizes",
                        defaultMessage: "prizes",
                      })}
                    </h6>
                    <ul className={"simpleList colorTertiary PrizesList"}>
                      {Object.keys(contest.prizes).map((key, index) => {
                        return (
                          <li
                            key={
                              index
                            }>{`${key} Place: $${contest.prizes[key]}`}</li>
                        );
                      })}
                    </ul>
                  </TailwindBox>
                  <TailwindBox
                    width={"w-full"}
                    margin={["ml-4"]}
                    pseudoClasses={[
                      "md:w-1/2",
                      "lg:w-8/12",
                      "xs:ml-0",
                      "sm:ml-0",
                    ]}>
                    <h6 className={"uppercase subTitle-1 colorPrimary"}>
                      {intl.formatMessage({
                        id: "contests.rules",
                        defaultMessage: "rules",
                      })}
                    </h6>
                    <p className={"body-1 colorTertiary"}>{contest.rules}</p>
                  </TailwindBox>
                </TailwindFlex>
              </ContestCard>
            </TailwindBox>
          );
        })}
      </TailwindFlex>
    );
  }
};

export default injectIntl(inject("contestsStore")(observer(ContestContainer)));
