import React, { useMemo, useState } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindCard from "library/components/_tailwind/card";
import TailwindText from "library/components/_tailwind/text";
import TailwindButton from "library/components/_tailwind/button";
import TailwindTranslatedNumber from "library/components/_tailwind/translated-number";
import EarningsStore from "common/earnings/_stores/earnings/EarningsStore";
import { inject, observer } from "mobx-react";
import TailwindHeading from "library/components/_tailwind/heading";
import PayoutBasicTable from "../../model/payout-basic-table";
import PayoutTable from "../../model/payout-table";
import { StudioEarnings } from "common/earnings/_stores/earnings/types";

type Props = {
  earning: StudioEarnings;
  earningsStore?: EarningsStore;
};

const StudioPayoutTableRow: React.ComponentType<Props> = ({
  earning,
  earningsStore,
}) => {
  const {
    setStudioActiveModel,
    selectedModel,
    modelIsSelected,
    currentPeriodTotals,
  } = earningsStore!;

  const earningMap = {
    broadcast_hours: earning?.broadcast_hours || 0,
    tiered_tokens_earned: earning?.amounts?.tiered_tokens_earned * 0.1 || 0,
    bonus_tokens: earning?.amounts?.bonus_tokens * 0.1 || 0,
    chargeback_tokens: earning?.amounts?.chargeback_tokens * 0.1 || 0,
    period_total_earnings: earning?.period_total_earnings * 0.1 || 0,
  };

  const [detailsVisible, showDetails] = useState<boolean>(false);
  const [earningsTableVisible, showEarningsTable] = useState<boolean>(false);

  const showingEarningDetails = useMemo(() => {
    return (
      modelIsSelected &&
      earning?.model_username === selectedModel?.screen_name &&
      currentPeriodTotals &&
      earningsTableVisible
    );
  }, [
    modelIsSelected,
    earning,
    selectedModel,
    currentPeriodTotals,
    earningsTableVisible,
  ]);

  const onShowDetails = () => {
    if (showingEarningDetails) {
      showEarningsTable(!earningsTableVisible);
    } else {
      setStudioActiveModel(earning?.model_username);
      showEarningsTable(true);
    }
  };

  return (
    <TailwindCard
      flexDirection={"flex-col"}
      width={"w-full"}
      padding={["p-4", "pb-4"]}
      pseudoClasses={["hover:bg-secondary-bg-color"]}>
      <TailwindFlex width={"w-full"} flexDirection={"flex-col"}>
        <TailwindFlex>
          <TailwindFlex
            width={"w-2/12"}
            justifyContent={"justify-start"}
            overflow={"overflow-hidden"}>
            <TailwindText
              width={"w-full"}
              padding={["pr-1"]}
              className={"capitalize-first"}
              textOverflow={"overflow-ellipsis"}
              overflow={"overflow-hidden"}>
              {earning.model_username || "unknown"}
            </TailwindText>
          </TailwindFlex>

          <TailwindFlex
            width={"w-2/12"}
            justifyContent={"justify-start"}
            overflow={"overflow-hidden"}>
            <TailwindTranslatedNumber
              value={earningMap.broadcast_hours || 0}
              style={"decimal"}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          </TailwindFlex>

          {[
            "tiered_tokens_earned",
            "bonus_tokens",
            "chargeback_tokens",
            "period_total_earnings",
          ].map((earningField, index) => {
            return (
              <TailwindFlex
                key={index}
                width={"w-2/12"}
                justifyContent={"justify-end"}
                overflow={"overflow-hidden"}>
                {earningField === "chargeback_tokens" ? (
                  <TailwindTranslatedNumber
                    value={-earningMap[earningField] || 0}
                    style={"currency"}
                    currency='USD'
                  />
                ) : (
                  <TailwindTranslatedNumber
                    value={earningMap[earningField] || 0}
                    style={"currency"}
                    currency='USD'
                  />
                )}
              </TailwindFlex>
            );
          })}
          <TailwindFlex
            width={"w-2/12"}
            justifyContent={"justify-end"}
            overflow={"overflow-hidden"}>
            <TailwindButton
              height={"h-full"}
              width={"w-auto"}
              className={[
                "whitespace-nowrap",
                "overflow-hidden",
                "justify-end",
              ]}
              alignSelf={"self-end"}
              padding={["p-0"]}
              onClick={onShowDetails}>
              <TailwindText
                width={"w-full"}
                textAlign={"text-center"}
                textOverflow={"overflow-ellipsis"}
                fontWeight={"font-normal"}
                textColor={"text-blue-500"}
                fontSize={"text-base"}>
                {showingEarningDetails ? "Hide" : "Show"}
              </TailwindText>
            </TailwindButton>
          </TailwindFlex>
        </TailwindFlex>
        {showingEarningDetails && (
          <TailwindFlex
            width={"w-full"}
            md={"w-full"}
            flexDirection={"flex-col"}
            padding={["p-4", "pl-10", "pr-10"]}
            pseudoClasses={["md:pr-0"]}>
            <TailwindFlex>
              <PayoutBasicTable
                isSimple={false}
                detailsVisible={detailsVisible}
                showDetails={() => showDetails(!detailsVisible)}
              />
            </TailwindFlex>
            {detailsVisible && (
              <React.Fragment>
                <TailwindFlex margin={["mb-4", "mt-4"]}>
                  <TailwindHeading level={6}>
                    Member Token Activity
                  </TailwindHeading>
                </TailwindFlex>
                <TailwindFlex>
                  <PayoutTable />
                </TailwindFlex>
              </React.Fragment>
            )}
          </TailwindFlex>
        )}
      </TailwindFlex>
    </TailwindCard>
  );
};

export default inject("earningsStore")(observer(StudioPayoutTableRow));
