import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindBadge from "../../components/_tailwind/badge";
import { CustomIconName } from "../../components/_tailwind/icon/icons/enums";

type Props = {};

const StorybookComponentsBadge: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Badge</StorybookHeading>
      <StorybookParagraph>
        Badge is an opinionated component towards getting almost the same look
        as Material-UI badge. It can either accept{" "}
        <Code inline>string, number or an CustomIconName</Code>. It has the
        following props:
      </StorybookParagraph>
      <Code>
        {`badgeContent: string | number | CustomIconName;
  badgeBackgroundColor?: TBackgroundColor;
  badgeTextColor?: TTextColor;`}
      </Code>
      <StorybookParagraph>Here are some examples</StorybookParagraph>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindBadge badgeContent={21}>Some Text</TailwindBadge>`}>
        <TailwindBadge badgeContent={21}>Some Text</TailwindBadge>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindBadge badgeContent={CustomIconName.star}>Some Text</TailwindBadge>`}>
        <TailwindBadge badgeContent={CustomIconName.star}>
          Some Text
        </TailwindBadge>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindBadge badgeContent={"Something"}>Some Text</TailwindBadge>`}>
        <TailwindBadge badgeContent={"Something"}>Some Text</TailwindBadge>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindBadge badgeContent={"bg-red-500"} badgeBackgroundColor={"bg-red-500"}>bg-red-500</TailwindBadge>`}>
        <TailwindBadge
          badgeContent={"bg-red-500"}
          badgeBackgroundColor={"bg-red-500"}>
          bg-red-500
        </TailwindBadge>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindBadge
          badgeContent={"bg-red-500"}
          badgeBackgroundColor={"bg-yellow-500"}
          badgeTextColor={"text-black"}
        >
          bg-yellow-500 text-black
        </TailwindBadge>`}>
        <TailwindBadge
          badgeContent={"bg-red-500"}
          badgeBackgroundColor={"bg-yellow-500"}
          badgeTextColor={"text-black"}>
          bg-yellow-500 text-black
        </TailwindBadge>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsBadge;
