import React, { useCallback, useState, useEffect } from "react";
import { Tab } from "./types";
import TailwindFlex, { TailwindFlexProps } from "../flex";
import TailwindTabHeader, { TailwindTabHeaderProps } from "./tab-header";
import TailwindTabBody, { TailwindTabBodyProps } from "./tab-body";
import "./styles.scss";

export type TailwindTabsProps = TailwindFlexProps & {
  tabs: Tab[];
  activeTabIndex?: number;
  onChange?: (activeTabIndex: number, activeTabId: string) => void;
  headerProps?: Omit<
    TailwindTabHeaderProps,
    "activeTabIndex" | "tabs" | "setActiveTab"
  >;
  bodyProps?: Omit<TailwindTabBodyProps, "tabs" | "activeTabIndex">;
};

const TailwindTabs: React.ComponentType<TailwindTabsProps> = ({
  tabs,
  activeTabIndex,
  onChange,
  headerProps,
  bodyProps,
  ...props
}) => {
  const [_activeTabIndex, setActiveTabIndex] = useState<number>(
    activeTabIndex || 0
  );

  const setActiveTab = useCallback(
    (tabIndex: number, tabId: string) => {
      setActiveTabIndex(tabIndex);
      if (onChange) {
        onChange(tabIndex, tabId);
      }
    },
    [onChange]
  );

  useEffect(() => {
    if (activeTabIndex !== undefined && activeTabIndex !== _activeTabIndex) {
      setActiveTabIndex(activeTabIndex);
    }
  }, [activeTabIndex]);

  return (
    <TailwindFlex flexDirection={"flex-col"} {...props}>
      <TailwindTabHeader
        tabs={tabs}
        activeTabIndex={_activeTabIndex}
        setActiveTab={setActiveTab}
        {...headerProps}
      />
      <TailwindTabBody
        tabs={tabs}
        activeTabIndex={_activeTabIndex}
        {...bodyProps}
      />
    </TailwindFlex>
  );
};

export default TailwindTabs;
