import React from "react";
import MediaProfileThumbnail, {
  MediaProfileThumbnailProps,
} from "../media/media-profile-thumbnail";
import classnames from "classnames";

export type FeedCover =
  | string
  | {
      type: "friend" | "upgrade";
      data: any;
    };

export type FeedCoverProps = {
  mediaProfileThumbnailProps?: MediaProfileThumbnailProps;
};

const FeedCover: React.ComponentType<FeedCoverProps> = ({
  mediaProfileThumbnailProps,
}) => {
  return (
    <div className={classnames("FeedCover")}>
      {mediaProfileThumbnailProps && (
        <MediaProfileThumbnail {...mediaProfileThumbnailProps} />
      )}
    </div>
  );
};

export default FeedCover;
