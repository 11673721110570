import { makeAutoObservable } from "mobx";
import React from "react";
import { BackdropVariant, ModalProps, Modals } from "./types";
import { InitialData } from "./consts";
import { validationStore } from "../validation/ValidationStore";

export default class ModalStore {
  modals: Modals = InitialData.modals;

  constructor() {
    makeAutoObservable(this);
  }

  closeAllModals = () => {
    this.closeAuthModal();
    this.closePrimaryModal();
    this.closeSecondaryModal();
  };

  closeAuthModal = () => {
    this.closeModal("auth");
  };

  closePrimaryModal = () => {
    this.closeModal("primary");
  };

  closeSecondaryModal = () => {
    this.closeModal("secondary");
  };

  closeModal = (key: string) => {
    this.toggleModal(null, false, key);
    validationStore.clearErrors();
  };

  toggleModal = (
    content: React.ReactNode | null,
    isOpen?: boolean,
    key = "primary",
    modalProps?: ModalProps
  ): void => {
    const defaultModalProps = {
      scrollTop: false,
      backdropVariant: "blur" as BackdropVariant,
      showNativeCloseButton: true,
    };
    const extendedModalProps: ModalProps = {
      ...defaultModalProps,
      ...modalProps,
    };

    const active = isOpen !== undefined ? isOpen : !this.modals[key].active;
    if (extendedModalProps.scrollTop) window.scrollTo({ top: 0 });
    this.modals[key] = { content, active, modalProps: extendedModalProps };
  };

  openPrimaryModal = (content: React.ReactNode, options?: ModalProps) => {
    this.toggleModal(content, true, "primary", options);
  };

  openSecondaryModal = (content: React.ReactNode, options?: ModalProps) => {
    this.toggleModal(content, true, "secondary", options);
  };

  openAuthModal = (content: React.ReactNode) => {
    this.toggleModal(content, true, "auth");
  };

  hasModalActiveWithName = (name: string) => {
    return (
      Object.values(this.modals).find(
        modal => modal.modalProps?.name === name
      ) !== undefined
    );
  };
}

export const modalStore = new ModalStore();
