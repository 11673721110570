import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindDropdown from "library/components/_tailwind/dropdown";
import { PAYOUT_METHOD_WIRE_TRANSFER_TYPES_ENUM } from "./stores/payout/enums";
import TailwindText from "library/components/_tailwind/text";
import TailwindAnchor from "library/components/_tailwind/anchor";
import { CAMS_WIRE_PAYMENTS_EMAIL } from "library/core/config/urls";
import PayoutStore from "./stores/payout/PayoutStore";
import { inject, observer } from "mobx-react";
import { CAMS_PAYOUT_WIRE_TRANSFER_TYPES } from "./stores/payout/consts";
import { injectIntl, WrappedComponentProps } from "react-intl";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import { CamsPayoutSettingWireTransfer } from "./stores/payout/types";
import TailwindInput from "library/components/_tailwind/input";

type Props = {
  payoutStore?: PayoutStore;
  validationStore?: ValidationStore;
};

const CamsPayoutWireTransferForm: React.ComponentType<
  Props & WrappedComponentProps
> = ({ payoutStore, validationStore, intl }) => {
  const { errors, validate } = validationStore!;

  const {
    wireTransferType,
    setWireTransferType,
    activePayoutSetting,
    updatePaymentDetails,
    shouldDisableWireTransferFormFields,
  } = payoutStore!;

  const isInternationalTransfer =
    wireTransferType === PAYOUT_METHOD_WIRE_TRANSFER_TYPES_ENUM.INTERNATIONAL;
  return (
    <React.Fragment>
      <TailwindFlex margin={["mb-2"]}>
        Receive money directly to your bank account outside United States.
      </TailwindFlex>
      <TailwindFlex margin={["mb-2"]}>
        Minimum payment by wire transfer is ${120}.
      </TailwindFlex>
      <TailwindFlex margin={["mb-2"]}>
        Earning less than $120 will roll over to next period.
      </TailwindFlex>
      <TailwindFlex margin={["mb-2"]}>
        Payment arrives 2-3 days sooner than paper check.
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        Wire transfer fee is $10 for domestic (U.S.), $20 for international.
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindDropdown
          backgroundColor={"secondary"}
          fullWidth
          name={"transferType"}
          label={intl.formatMessage({
            id: `payout.payoutSettings.input.transferType`,
            defaultMessage: "Transfer Type",
          })}
          value={wireTransferType}
          disabled={shouldDisableWireTransferFormFields}
          onChange={val =>
            setWireTransferType(val as PAYOUT_METHOD_WIRE_TRANSFER_TYPES_ENUM)
          }
          items={CAMS_PAYOUT_WIRE_TRANSFER_TYPES?.map(item => ({
            label: item,
            value: item,
          }))}
        />
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindInput
          backgroundColor={"secondary"}
          required={true}
          variant={"contained"}
          label={"Account Name"}
          error={errors["account_name"] || ""}
          disabled={shouldDisableWireTransferFormFields}
          onBlur={_ =>
            validate(
              "account_name",
              (
                activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
              )?.account_name
            )
          }
          value={
            (
              activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
            )?.account_name
          }
          onChange={e =>
            updatePaymentDetails(
              activePayoutSetting,
              "account_name",
              e.target.value
            )
          }
          name={"account_name"}
        />
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindInput
          backgroundColor={"secondary"}
          required={true}
          variant={"contained"}
          label={"Account Number"}
          error={errors["account_number"] || ""}
          disabled={shouldDisableWireTransferFormFields}
          onBlur={_ =>
            validate(
              "account_number",
              (
                activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
              )?.account_number
            )
          }
          value={
            (
              activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
            )?.account_number
          }
          onChange={e =>
            updatePaymentDetails(
              activePayoutSetting,
              "account_number",
              e.target.value
            )
          }
          name={"account_number"}
        />
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindInput
          backgroundColor={"secondary"}
          required={true}
          variant={"contained"}
          label={"Bank Name"}
          error={errors["bank_name"] || ""}
          disabled={shouldDisableWireTransferFormFields}
          onBlur={_ =>
            validate(
              "bank_name",
              (
                activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
              )?.bank_name
            )
          }
          value={
            (
              activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
            )?.bank_name
          }
          onChange={e =>
            updatePaymentDetails(
              activePayoutSetting,
              "bank_name",
              e.target.value
            )
          }
          name={"bank_name"}
        />
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindInput
          backgroundColor={"secondary"}
          required={true}
          variant={"contained"}
          label={"Bank Address"}
          error={errors["bank_address"] || ""}
          disabled={shouldDisableWireTransferFormFields}
          onBlur={_ =>
            validate(
              "bank_address",
              (
                activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
              )?.bank_address
            )
          }
          value={
            (
              activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
            )?.bank_address
          }
          onChange={e =>
            updatePaymentDetails(
              activePayoutSetting,
              "bank_address",
              e.target.value
            )
          }
          name={"bank_address"}
        />
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindInput
          backgroundColor={"secondary"}
          required={true}
          variant={"contained"}
          label={"Name on Account"}
          error={errors["name_on_account"] || ""}
          disabled={shouldDisableWireTransferFormFields}
          onBlur={_ =>
            validate(
              "name_on_account",
              (
                activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
              )?.name_on_account
            )
          }
          value={
            (
              activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
            )?.name_on_account
          }
          onChange={e =>
            updatePaymentDetails(
              activePayoutSetting,
              "name_on_account",
              e.target.value
            )
          }
          name={"name_on_account"}
        />
      </TailwindFlex>
      {isInternationalTransfer && (
        <React.Fragment>
          <TailwindFlex margin={["mb-4"]}>
            <TailwindInput
              backgroundColor={"secondary"}
              required={true}
              variant={"contained"}
              label={"Address on Account"}
              error={errors["address_on_account"] || ""}
              disabled={shouldDisableWireTransferFormFields}
              onBlur={_ =>
                validate(
                  "address_on_account",
                  (
                    activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
                  )?.address_on_account
                )
              }
              value={
                (
                  activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
                )?.address_on_account
              }
              onChange={e =>
                updatePaymentDetails(
                  activePayoutSetting,
                  "address_on_account",
                  e.target.value
                )
              }
              name={"address_on_account"}
            />
          </TailwindFlex>
          <TailwindFlex margin={["mb-4"]}>
            <TailwindInput
              backgroundColor={"secondary"}
              required={true}
              variant={"contained"}
              label={"Inter Correspondent Bank Name"}
              error={errors["inter_correspondent_bank_name"] || ""}
              disabled={shouldDisableWireTransferFormFields}
              onBlur={_ =>
                validate(
                  "inter_correspondent_bank_name",
                  (
                    activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
                  )?.inter_correspondent_bank_name
                )
              }
              value={
                (
                  activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
                )?.inter_correspondent_bank_name
              }
              onChange={e =>
                updatePaymentDetails(
                  activePayoutSetting,
                  "inter_correspondent_bank_name",
                  e.target.value
                )
              }
              name={"inter_correspondent_bank_name"}
            />
          </TailwindFlex>
          <TailwindFlex margin={["mb-4"]}>
            <TailwindInput
              backgroundColor={"secondary"}
              required={true}
              variant={"contained"}
              label={"Inter Correspondent Bank Swift#"}
              error={errors["inter_correspondent_bank_swift"] || ""}
              disabled={shouldDisableWireTransferFormFields}
              onBlur={_ =>
                validate(
                  "inter_correspondent_bank_swift",
                  (
                    activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
                  )?.inter_correspondent_bank_swift
                )
              }
              value={
                (
                  activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
                )?.inter_correspondent_bank_swift
              }
              onChange={e =>
                updatePaymentDetails(
                  activePayoutSetting,
                  "inter_correspondent_bank_swift",
                  e.target.value
                )
              }
              name={"inter_correspondent_bank_swift"}
            />
          </TailwindFlex>
          <TailwindFlex margin={["mb-4"]}>
            <TailwindInput
              backgroundColor={"secondary"}
              required={true}
              variant={"contained"}
              label={"IBAN #"}
              error={errors["iban"] || ""}
              disabled={shouldDisableWireTransferFormFields}
              onBlur={_ =>
                validate(
                  "iban",
                  (
                    activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
                  )?.iban
                )
              }
              value={
                (
                  activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
                )?.iban
              }
              onChange={e =>
                updatePaymentDetails(
                  activePayoutSetting,
                  "iban",
                  e.target.value
                )
              }
              name={"iban"}
            />
          </TailwindFlex>
          <TailwindFlex margin={["mb-4"]}>
            <TailwindInput
              backgroundColor={"secondary"}
              required={true}
              variant={"contained"}
              disabled={shouldDisableWireTransferFormFields}
              label={"Swift #"}
              error={errors["swift"] || ""}
              onBlur={_ =>
                validate(
                  "swift",
                  (
                    activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
                  )?.swift
                )
              }
              value={
                (
                  activePayoutSetting?.payout_settings as CamsPayoutSettingWireTransfer
                )?.swift
              }
              onChange={e =>
                updatePaymentDetails(
                  activePayoutSetting,
                  "swift",
                  e.target.value
                )
              }
              name={"swift"}
            />
          </TailwindFlex>
        </React.Fragment>
      )}
      {shouldDisableWireTransferFormFields && (
        <TailwindFlex
          fontWeight={"font-bold"}
          margin={["mb-4"]}
          pseudoClasses={["md:flex-col"]}>
          <TailwindText>
            If you need to make changes to payment information, please contact
            Customer Support for assistance by contacting&nbsp;
            <TailwindAnchor
              display={"inline-block"}
              to={`mailto:${CAMS_WIRE_PAYMENTS_EMAIL}`}>
              {CAMS_WIRE_PAYMENTS_EMAIL}
            </TailwindAnchor>
          </TailwindText>
        </TailwindFlex>
      )}
      <TailwindFlex fontWeight={"font-bold"} margin={["mb-4"]}>
        Please allow 2 business days for the processing of all wire transfer
        applications and edits. The previous payment type will remain in effect
        until an email confirmation is received.
      </TailwindFlex>
    </React.Fragment>
  );
};

export default injectIntl(
  inject("payoutStore", "validationStore")(observer(CamsPayoutWireTransferForm))
);
