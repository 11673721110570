import React from "react";
import TailwindText from "library/components/_tailwind/text";
import MyFansStore from "containers/model/my-fans/stores/my-fans/MyFansStore";
import { inject, observer } from "mobx-react";
import MyFansMemberTableRow from "containers/model/my-fans/member-table-row";

type Props = {
  myFansStore?: MyFansStore;
};

const MyFansMemberTableBody: React.ComponentType<Props> = ({ myFansStore }) => {
  const { activeTabMembers, selectedMemberId } = myFansStore!;

  if (!activeTabMembers.length) {
    return (
      <TailwindText
        textAlign={"text-center"}
        fontSize={"text-base"}
        textColor='text-main-color'
        padding={["p-10", "pt-20"]}>
        Your fans will appear here when they give tips, join your fan club, or
        enter your paid shows.
      </TailwindText>
    );
  }

  return (
    <React.Fragment>
      {activeTabMembers.length &&
        activeTabMembers.map((fan, index) => (
          <MyFansMemberTableRow
            showDesktopDivider={
              activeTabMembers[index + 1]?.member?.id != selectedMemberId &&
              fan?.member?.id != selectedMemberId
            }
            key={`${fan?.member?.id}-m`}
            fan={fan}
          />
        ))}
    </React.Fragment>
  );
};

export default inject("myFansStore")(observer(MyFansMemberTableBody));
