import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindCard from "../../components/_tailwind/card";
import TailwindIcon from "../../components/_tailwind/icon";
import TailwindFlex from "../../components/_tailwind/flex";
import TailwindButton from "../../components/_tailwind/button";
import TailwindText from "../../components/_tailwind/text";
import TailwindBox from "../../components/_tailwind/box";
import MediaProfileThumbnail from "../../components/_tailwind/media/media-profile-thumbnail";
import TailwindGrid from "../../components/_tailwind/grid";

type Props = {};

const StorybookComponentsLiveModels: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Live Models</StorybookHeading>
      <StorybookParagraph>
        These components are so far only used on AFF but will be used in other
        projects later.
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindCard
          backgroundColor={"primary"}
          borderRadius={"rounded-3xl"}
          display={"flex"}
          overflow={"overflow-hidden"}
          rounded
        >
          <TailwindBox width={"w-4/12"}>
            <MediaProfileThumbnail
              mediaProps={{
                src:
                  "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/model_show-fd7f93.jpg",
                height: "h-full"
              }}
              mediaProfileOnlineStatusOverlayProps={{ status: "online" }}
            />
          </TailwindBox>
          <TailwindBox width={"w-8/12"}>
            <TailwindFlex margin={["m-8"]} flexDirection={"flex-col"}>
              <TailwindText
                fontSize={"text-lg"}
                textAlign={"text-center"}
                width={"w-full"}
                display={"block"}
                fontWeight={"font-bold"}
                margin={["mb-4"]}
              >
                Live Models
              </TailwindText>
              <TailwindBox
                display={"grid"}
                className={["grid-cols-4", "gap-4"]}
              >
                <TailwindBox>
                  <TailwindBox
                    borderRadius={"rounded-md"}
                    overflow={"overflow-hidden"}
                  >
                    <MediaProfileThumbnail
                      mediaProps={{
                        src:
                          "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/2-891ef7.png",
                        height: "h-48"
                      }}
                    />
                  </TailwindBox>
                  <TailwindFlex margin={["mt-2"]}>
                    <TailwindFlex flexDirection={"flex-col"} width={"w-auto"}>
                      <TailwindText fontSize={"text-xs"}>
                        Memberusername
                      </TailwindText>
                      <TailwindBox display={"flex"} alignItems={"items-center"}>
                        <TailwindIcon
                          name={"video-camera"}
                          display={"inline"}
                          textColor={"text-pink-400"}
                          margin={["mr-1"]}
                        />
                        <TailwindText
                          textColor={"text-pink-400"}
                          fontSize={"text-xs"}
                          fontWeight={"font-bold"}
                        >
                          Live free chat
                        </TailwindText>
                      </TailwindBox>
                    </TailwindFlex>
                  </TailwindFlex>
                </TailwindBox>
                <TailwindBox>
                  <TailwindBox
                    borderRadius={"rounded-md"}
                    overflow={"overflow-hidden"}
                  >
                    <MediaProfileThumbnail
                      mediaProps={{
                        src:
                          "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/3-80bc45.png",
                        height: "h-48"
                      }}
                    />
                  </TailwindBox>
                  <TailwindFlex margin={["mt-2"]}>
                    <TailwindFlex flexDirection={"flex-col"} width={"w-auto"}>
                      <TailwindText fontSize={"text-xs"}>
                        Memberusername
                      </TailwindText>
                      <TailwindBox display={"flex"} alignItems={"items-center"}>
                        <TailwindIcon
                          name={"video-camera"}
                          display={"inline"}
                          textColor={"text-pink-400"}
                          margin={["mr-1"]}
                        />
                        <TailwindText
                          textColor={"text-pink-400"}
                          fontSize={"text-xs"}
                          fontWeight={"font-bold"}
                        >
                          Live free chat
                        </TailwindText>
                      </TailwindBox>
                    </TailwindFlex>
                  </TailwindFlex>
                </TailwindBox>
                <TailwindBox>
                  <TailwindBox
                    borderRadius={"rounded-md"}
                    overflow={"overflow-hidden"}
                  >
                    <MediaProfileThumbnail
                      mediaProps={{
                        src:
                          "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/4-3bcebd.png",
                        height: "h-48"
                      }}
                    />
                  </TailwindBox>
                  <TailwindFlex margin={["mt-2"]}>
                    <TailwindFlex flexDirection={"flex-col"} width={"w-auto"}>
                      <TailwindText fontSize={"text-xs"}>
                        Memberusername
                      </TailwindText>
                      <TailwindBox display={"flex"} alignItems={"items-center"}>
                        <TailwindIcon
                          name={"video-camera"}
                          display={"inline"}
                          textColor={"text-pink-400"}
                          margin={["mr-1"]}
                        />
                        <TailwindText
                          textColor={"text-pink-400"}
                          fontSize={"text-xs"}
                          fontWeight={"font-bold"}
                        >
                          Live free chat
                        </TailwindText>
                      </TailwindBox>
                    </TailwindFlex>
                  </TailwindFlex>
                </TailwindBox>
                <TailwindBox>
                  <TailwindBox
                    borderRadius={"rounded-md"}
                    overflow={"overflow-hidden"}
                  >
                    <MediaProfileThumbnail
                      mediaProps={{
                        src:
                          "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/7-edb2cc.png",
                        height: "h-48"
                      }}
                    />
                  </TailwindBox>
                  <TailwindFlex margin={["mt-2"]}>
                    <TailwindFlex flexDirection={"flex-col"} width={"w-auto"}>
                      <TailwindText fontSize={"text-xs"}>
                        Memberusername
                      </TailwindText>
                      <TailwindBox display={"flex"} alignItems={"items-center"}>
                        <TailwindIcon
                          name={"video-camera"}
                          display={"inline"}
                          textColor={"text-pink-400"}
                          margin={["mr-1"]}
                        />
                        <TailwindText
                          textColor={"text-pink-400"}
                          fontSize={"text-xs"}
                          fontWeight={"font-bold"}
                        >
                          Live free chat
                        </TailwindText>
                      </TailwindBox>
                    </TailwindFlex>
                  </TailwindFlex>
                </TailwindBox>
              </TailwindBox>
              <TailwindButton
                margin={["mt-8", "mx-auto"]}
                backgroundColor={"bg-pink-400"}
                width={"w-6/12"}
                fullWidth={false}
                justifyContent={"justify-center"}
              >
                View more live models
              </TailwindButton>
            </TailwindFlex>
          </TailwindBox>
        </TailwindCard>`}>
        <TailwindCard
          backgroundColor={"primary"}
          borderRadius={"rounded-3xl"}
          display={"flex"}
          overflow={"overflow-hidden"}
          rounded>
          <TailwindBox width={"w-4/12"}>
            <MediaProfileThumbnail
              mediaProps={{
                src:
                  "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/model_show-fd7f93.jpg",
                height: "h-full",
              }}
              mediaProfileOnlineStatusOverlayProps={{ status: "online" }}
            />
          </TailwindBox>
          <TailwindBox width={"w-8/12"}>
            <TailwindFlex padding={["p-8"]} flexDirection={"flex-col"}>
              <TailwindText
                fontSize={"text-lg"}
                textAlign={"text-center"}
                width={"w-full"}
                display={"block"}
                fontWeight={"font-bold"}
                margin={["mb-4"]}>
                Live Models
              </TailwindText>
              <TailwindGrid cols={"grid-cols-4"} gap={"gap-4"}>
                <TailwindBox>
                  <TailwindBox
                    borderRadius={"rounded-md"}
                    overflow={"overflow-hidden"}>
                    <MediaProfileThumbnail
                      mediaProps={{
                        src:
                          "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/2-891ef7.png",
                        height: "h-48",
                      }}
                    />
                  </TailwindBox>
                  <TailwindFlex margin={["mt-2"]}>
                    <TailwindFlex flexDirection={"flex-col"} width={"w-auto"}>
                      <TailwindText fontSize={"text-xs"}>
                        Memberusername
                      </TailwindText>
                      <TailwindBox display={"flex"} alignItems={"items-center"}>
                        <TailwindIcon
                          name={"video-camera"}
                          display={"inline"}
                          textColor={"text-pink-400"}
                          margin={["mr-1"]}
                        />
                        <TailwindText
                          textColor={"text-pink-400"}
                          fontSize={"text-xs"}
                          fontWeight={"font-bold"}>
                          Live free chat
                        </TailwindText>
                      </TailwindBox>
                    </TailwindFlex>
                  </TailwindFlex>
                </TailwindBox>
                <TailwindBox>
                  <TailwindBox
                    borderRadius={"rounded-md"}
                    overflow={"overflow-hidden"}>
                    <MediaProfileThumbnail
                      mediaProps={{
                        src:
                          "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/3-80bc45.png",
                        height: "h-48",
                      }}
                    />
                  </TailwindBox>
                  <TailwindFlex margin={["mt-2"]}>
                    <TailwindFlex flexDirection={"flex-col"} width={"w-auto"}>
                      <TailwindText fontSize={"text-xs"}>
                        Memberusername
                      </TailwindText>
                      <TailwindBox display={"flex"} alignItems={"items-center"}>
                        <TailwindIcon
                          name={"video-camera"}
                          display={"inline"}
                          textColor={"text-pink-400"}
                          margin={["mr-1"]}
                        />
                        <TailwindText
                          textColor={"text-pink-400"}
                          fontSize={"text-xs"}
                          fontWeight={"font-bold"}>
                          Live free chat
                        </TailwindText>
                      </TailwindBox>
                    </TailwindFlex>
                  </TailwindFlex>
                </TailwindBox>
                <TailwindBox>
                  <TailwindBox
                    borderRadius={"rounded-md"}
                    overflow={"overflow-hidden"}>
                    <MediaProfileThumbnail
                      mediaProps={{
                        src:
                          "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/4-3bcebd.png",
                        height: "h-48",
                      }}
                    />
                  </TailwindBox>
                  <TailwindFlex margin={["mt-2"]}>
                    <TailwindFlex flexDirection={"flex-col"} width={"w-auto"}>
                      <TailwindText fontSize={"text-xs"}>
                        Memberusername
                      </TailwindText>
                      <TailwindBox display={"flex"} alignItems={"items-center"}>
                        <TailwindIcon
                          name={"video-camera"}
                          display={"inline"}
                          textColor={"text-pink-400"}
                          margin={["mr-1"]}
                        />
                        <TailwindText
                          textColor={"text-pink-400"}
                          fontSize={"text-xs"}
                          fontWeight={"font-bold"}>
                          Live free chat
                        </TailwindText>
                      </TailwindBox>
                    </TailwindFlex>
                  </TailwindFlex>
                </TailwindBox>
                <TailwindBox>
                  <TailwindBox
                    borderRadius={"rounded-md"}
                    overflow={"overflow-hidden"}>
                    <MediaProfileThumbnail
                      mediaProps={{
                        src:
                          "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/7-edb2cc.png",
                        height: "h-48",
                      }}
                    />
                  </TailwindBox>
                  <TailwindFlex margin={["mt-2"]}>
                    <TailwindFlex flexDirection={"flex-col"} width={"w-auto"}>
                      <TailwindText fontSize={"text-xs"}>
                        Memberusername
                      </TailwindText>
                      <TailwindBox display={"flex"} alignItems={"items-center"}>
                        <TailwindIcon
                          name={"video-camera"}
                          display={"inline"}
                          textColor={"text-pink-400"}
                          margin={["mr-1"]}
                        />
                        <TailwindText
                          textColor={"text-pink-400"}
                          fontSize={"text-xs"}
                          fontWeight={"font-bold"}>
                          Live free chat
                        </TailwindText>
                      </TailwindBox>
                    </TailwindFlex>
                  </TailwindFlex>
                </TailwindBox>
              </TailwindGrid>
              <TailwindButton
                margin={["mt-8", "mx-auto"]}
                backgroundColor={"bg-pink-400"}
                width={"w-6/12"}
                fullWidth={false}
                justifyContent={"justify-center"}>
                View more live models
              </TailwindButton>
            </TailwindFlex>
          </TailwindBox>
        </TailwindCard>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsLiveModels;
