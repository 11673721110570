export const InitialData = {
  initialWindowHeight: null,
  screenSize: null,
  screenOrientation: null,
  isKeyboardOpen: false,
  windowHeight: null,
  windowWidth: null,
  userAgent: null,
  isAndroidOrIOS: false,
  hasFocusedInput: false,
  hasResizeEventBeenConsumed: true,
  shouldBlurInput: false,
  navigatorAgent: null,
  openRegistrationModal: false,
  isMenuOpen: true,
  isLiveChatOpen: false,
  isOpenMenuOnMouseOver: false,
  activeSidebarDropdownKey: null,
  isLiteMode: false,
  isOffline: false,
  isBroadcastPage: false,
};
