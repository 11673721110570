import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookComponentShowcase from "../_internal/component-showcase";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import TailwindFlex from "../../components/_tailwind/flex";
import TailwindButton from "../../components/_tailwind/button";
import { inject, observer } from "mobx-react";
import SnackbarStore from "../../core/stores/snackbar/SnackbarStore";
import { CustomIconName } from "../../components/_tailwind/icon/icons/enums";
import { SnackbarVariants } from "../../core/stores/snackbar/enums";

type Props = {
  snackbarStore?: SnackbarStore;
};

const StorybookComponentsSnackbar: React.ComponentType<Props> = ({
  snackbarStore,
}) => {
  const { enqueueSnackbar } = snackbarStore!;

  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Snackbar</StorybookHeading>
      <StorybookParagraph>
        Snackbar components uses <Code inline>react-hot-toast</Code> package
        under the hood but there are some extensions we added over time such as
        actions as well as translation ability for the snackbar. SnackbarStore
        needs to be injected for snacks to be shown, inside stores,
        <Code inline>snackbarStore.enqueueSnackbar</Code> or{" "}
        <Code inline>snackbarStore.enqueueSimpleSnackbar</Code> can be used
        without injecting into the store by importing it like this{" "}
        <Code inline>
          {`import {snackbarStore} from "../snackbar/SnackbarStore";`}
        </Code>
      </StorybookParagraph>
      <TailwindFlex>
        <TailwindFlex width={"w-3/12"}>
          <StorybookComponentShowcase
            sourceCode={`<TailwindButton
              backgroundColor={"bg-red-500"}
              onClick={() =>
                enqueueSnackbar({
                  message: {
                    id: "fallback-to-default",
                    default: "Some Success",
                  },
                  variant: SnackbarVariants.SUCCESS
                  
                })
              }
            >
              Click for Success
            </TailwindButton>`}>
            <TailwindButton
              backgroundColor={"bg-red-500"}
              onClick={() =>
                enqueueSnackbar({
                  message: {
                    id: "fallback-to-default",
                    default: "Some Success",
                  },
                  variant: SnackbarVariants.SUCCESS,
                })
              }>
              Click for Success
            </TailwindButton>
          </StorybookComponentShowcase>
        </TailwindFlex>
        <TailwindFlex width={"w-3/12"}>
          <StorybookComponentShowcase
            sourceCode={`<TailwindButton
              backgroundColor={"bg-red-500"}
              onClick={() =>
                enqueueSnackbar({
                  message: {
                    id: "fallback-to-default",
                    default: "Some Warning",
                  },
                  variant: SnackbarVariants.WARNING,
                })
              }
            >
              Click for Warning
            </TailwindButton>`}>
            <TailwindButton
              backgroundColor={"bg-red-500"}
              onClick={() =>
                enqueueSnackbar({
                  message: {
                    id: "fallback-to-default",
                    default: "Some Warning",
                  },
                  variant: SnackbarVariants.WARNING,
                })
              }>
              Click for Warning
            </TailwindButton>
          </StorybookComponentShowcase>
        </TailwindFlex>
        <TailwindFlex width={"w-3/12"}>
          <StorybookComponentShowcase
            sourceCode={`<TailwindButton
              backgroundColor={"bg-red-500"}
              onClick={() =>
                enqueueSnackbar({
                  message: {
                    id: "fallback-to-default",
                    default: "Some Error",
                  },
                  variant: SnackbarVariants.ERROR,
                })
              }
            >
              Click for Error
            </TailwindButton>`}>
            <TailwindButton
              backgroundColor={"bg-red-500"}
              onClick={() =>
                enqueueSnackbar({
                  message: {
                    id: "fallback-to-default",
                    default: "Some Error",
                  },
                  variant: SnackbarVariants.ERROR,
                })
              }>
              Click for Error
            </TailwindButton>
          </StorybookComponentShowcase>
        </TailwindFlex>
        <TailwindFlex width={"w-3/12"}>
          <StorybookComponentShowcase
            sourceCode={`<TailwindButton
              backgroundColor={"bg-red-500"}
              onClick={() =>
                enqueueSnackbar({
                  message: {
                    id: "fallback-to-default",
                    default: "Some Info",
                  },
                  variant: SnackbarVariants.INFO,
                })
              }
            >
              Click for Info
            </TailwindButton>`}>
            <TailwindButton
              backgroundColor={"bg-red-500"}
              onClick={() =>
                enqueueSnackbar({
                  message: {
                    id: "fallback-to-default",
                    default: "Some Info",
                  },
                  variant: SnackbarVariants.INFO,
                })
              }>
              Click for Info
            </TailwindButton>
          </StorybookComponentShowcase>
        </TailwindFlex>
        <TailwindFlex width={"w-3/12"}>
          <StorybookComponentShowcase
            sourceCode={`<TailwindButton
              backgroundColor={"bg-red-500"}
              onClick={() =>
                enqueueSnackbar({
                  message: {
                    id: "fallback-to-default",
                    default: "Favorited {model}",
                    parameters: {
                      model: "somemodel",
                    },
                  },
                  actions: [
                    {
                      callback: ({ model }) => alert(\`Unfavorite \${model}\`),
                      parameters: {
                        model: "somemodel",
                      },
                      labelId: "fallback-to-default",
                      defaultLabel: "Unfavorite",
                      textProps: {
                        textColor: "text-yellow-500",
                      },
                    },
                    {
                      callback: ({ tips }) => alert(\`Sent \${tips} tips\`),
                      parameters: {
                        tips: 10,
                      },
                      labelId: "fallback-to-default",
                      defaultLabel: "Send 10 Tips",
                      backgroundColor: "bg-yellow-500",
                      leftIconProps: {
                        name: CustomIconName.tips,
                      },
                    },
                  ],
                  variant: SnackbarVariant.info,
                  options: {
                    duration: 10000,
                  },
                })
              }
            >
              Click for Info With Actions
            </TailwindButton>`}>
            <TailwindButton
              backgroundColor={"bg-red-500"}
              onClick={() =>
                enqueueSnackbar({
                  message: {
                    id: "fallback-to-default",
                    default: "Favorited {model}",
                    parameters: {
                      model: "somemodel",
                    },
                  },
                  actions: [
                    {
                      callback: ({ model }) => alert(`Unfavorite ${model}`),
                      parameters: {
                        model: "somemodel",
                      },
                      labelId: "fallback-to-default",
                      defaultLabel: "Unfavorite",
                      textProps: {
                        textColor: "text-yellow-500",
                      },
                    },
                    {
                      callback: ({ tips }) => alert(`Sent ${tips} tips`),
                      parameters: {
                        tips: 10,
                      },
                      labelId: "fallback-to-default",
                      defaultLabel: "Send 10 Tips",
                      backgroundColor: "bg-yellow-500",
                      leftIconProps: {
                        name: CustomIconName.tips,
                      },
                    },
                  ],
                  variant: SnackbarVariants.INFO,
                  options: {
                    duration: 10000,
                  },
                })
              }>
              Click for Info With Actions
            </TailwindButton>
          </StorybookComponentShowcase>
        </TailwindFlex>
      </TailwindFlex>
    </StorybookWrapper>
  );
};

export default inject("snackbarStore")(observer(StorybookComponentsSnackbar));
