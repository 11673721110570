import React from "react";
import MediaManagerSidebarHeaderTitle from "containers/_components/media-manager/media-manager-sidebar-header-title";
import MediaManagerSidebarHeaderDescription from "containers/_components/media-manager/media-manager-sidebar-header-description";

type Props = {};

const MediaManagerSidebarHeader: React.ComponentType<Props> = ({}) => {
  return (
    <React.Fragment>
      <MediaManagerSidebarHeaderTitle />
      <MediaManagerSidebarHeaderDescription />
    </React.Fragment>
  );
};

export default MediaManagerSidebarHeader;
