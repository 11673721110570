import React from "react";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindText from "library/components/_tailwind/text";
import { MessageDescriptor } from "react-intl";

interface Props {
  stepNumber: number;
  title: MessageDescriptor;
}

const OBSModalTab: React.FunctionComponent<Props> = ({ stepNumber, title }) => {
  return (
    <>
      <TailwindText
        backgroundColor={"bg-gray-400"}
        borderRadius={"rounded-full"}
        padding={["px-2", "py-1"]}
        fontSize={"text-xs"}
        textColor={"text-white"}
        pseudoClasses={["sm:text-base"]}>
        <TailwindTranslatedText
          descriptor={{ id: "broadcast-obs-modal.step", defaultMessage: "STEP" }}
          pseudoClasses={["sm:hidden"]}
          fontSize={"text-xs"}
        />
        &nbsp;{stepNumber}
      </TailwindText>
      <TailwindTranslatedText
        descriptor={title}
        paragraphProps={{
          fontSize: "text-sm",
          whiteSpace: "whitespace-nowrap",
          pseudoClasses: ["md:hidden"],
          fontWeight: "font-semibold",
        }}
      />
    </>
  );
};

export default OBSModalTab;
