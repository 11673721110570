import _ from "lodash";

interface BlobEvent {
  data: Blob;
}

interface recorderConfig {
  onMediaReady: (audioURL: string, type: string, file: Blob) => void; // returns audio source when completed
  onDataAvailable?: (blob: BlobEvent) => void; // returns audio blob when available
  onPermissionError?: () => void;
  onRecording?: (recording: boolean) => void; // status change when recording starts and stops
}

export default class Recorder {
  private mediaRecorder: MediaRecorder | null = null;
  private activeStream: MediaStream | null = null;
  private chunks: Array<Blob> = [];
  private onMediaReadyCallback:
    | ((audioURL: string, type: string, file: Blob) => void)
    | undefined;
  private onDataAvailableCallback: ((blob: BlobEvent) => void) | undefined;
  private onPermissionErrorCallback: (() => void) | undefined;
  private onRecordingCallback: ((recording: boolean) => void) | undefined;
  private mediaConstraints: MediaStreamConstraints = { audio: true };
  private mediaType;
  private mimeTypesToCheck = [
    "audio/mpeg",
    "audio/wav",
    "audio/mp4",
    "audio/ogg",
    "audio/webm",
  ];

  constructor(config: recorderConfig) {
    if (navigator.mediaDevices) {
      this.onMediaReadyCallback = config.onMediaReady;
      this.onDataAvailableCallback = config.onDataAvailable;
      this.onPermissionErrorCallback = config.onPermissionError;
      this.onRecordingCallback = config.onRecording;
      this.mediaType = this.mimeTypesToCheck.find(mimeType =>
        MediaRecorder.isTypeSupported(mimeType)
      );
    }
  }

  private onStop = () => {
    if (this.chunks.length) {
      const blob = new Blob(this.chunks, { type: this.mediaType });
      this.chunks = [];
      const audioURL = (URL || webkitURL).createObjectURL(blob);

      this.onMediaReadyCallback?.(audioURL, this.mediaType, blob);
    }
  };

  private onRecorderDataAvailable = (blob: BlobEvent) => {
    this.chunks.push(blob.data);
    this.onDataAvailableCallback?.(blob);
  };

  public start = () => {
    try {
      navigator.mediaDevices
        .getUserMedia(this.mediaConstraints)
        .then(async stream => {
          this.activeStream = stream;
          this.mediaRecorder = new MediaRecorder(stream, {
            mimeType: this.mediaType,
          });
          this.mediaRecorder.onstop = _.debounce(this.onStop, 1000);
          this.mediaRecorder.ondataavailable = this.onRecorderDataAvailable;
          this.mediaRecorder?.start(500);
          this.onRecordingCallback?.(true);
        })

        .catch(err => {
          console.error(`Recorder: error encountered: ${err}`);
          this.onPermissionErrorCallback?.();
        });
    } catch {
      this.onPermissionErrorCallback?.();
    }
  };

  public stop = () => {
    this.mediaRecorder?.stop();
    this.activeStream?.getAudioTracks().forEach((track: MediaStreamTrack) => {
      track.stop();
      this.activeStream?.removeTrack(track);
    });
    this.onRecordingCallback?.(false);
  };
}
