import React from "react";
import TailwindBox from "library/components/_tailwind/box";
import TailwindText from "library/components/_tailwind/text";
import "./styles.scss";
import TailwindList, {
  TailwindListType,
} from "library/components/_tailwind/list";
import TailwindListItem from "library/components/_tailwind/list/list-item";

type Props = {};
const StudiosAcceptableUsePolicy = ({}: Props) => {
  return (
    <div className={"StudiosAcceptableUsePolicy"}>
      <TailwindText
        fontSize={"text-3xl"}
        fontWeight={"font-bold"}
        padding={["py-2"]}>
        STREAMRAY ACCEPTABLE USE POLICY AND RULES FOR BROADCASTING (THE
        "POLICY")
      </TailwindText>
      <TailwindBox>
        <img
          src='https://secureimage.securedataimages.com/images/streamray/model_center/streamray_logo_041719.png'
          width='261'
          height='88'
          alt={""}
        />
        <p>
          This Policy is incorporated into and forms a part of the Streamray
          Broadcast Agreement (the "Agreement") or Performer's Acknowledgement
          and Agreement (the "Acknowledgement") one of which you executed. This
          Policy is subject to change, so please check back regularly to ensure
          that you are familiar with the latest version.
        </p>
        <p className={"mt-4 mb-3"}>
          Capitalized terms used and not otherwise defined in this Policy shall
          have the meanings set forth in the Agreement or Acknowledgement.
        </p>
        <TailwindList type={TailwindListType.ol}>
          <TailwindListItem padding={["py-2"]}>
            <strong>Your Content.</strong> You are solely responsible for the
            content that you produce while appearing on the Streamray Network.
            Streamray does not require you to participate in any sexually
            explicit or nude conduct. We suggest that for the benefit of its
            Users and subscribers and to avoid misunderstandings, you clearly
            list your "dos" and "don'ts" in your Streamray "Bio".
          </TailwindListItem>
          <TailwindListItem padding={["py-2"]}>
            <strong>Obscenity.</strong> You are prohibited from engaging in any
            behavior on the Streamray Network that could reasonably be
            considered obscene or a violation of any United States laws, rules
            or regulations or any laws, rules or regulations otherwise
            applicable to you or the location from which you broadcast.
          </TailwindListItem>
          <TailwindListItem padding={["py-2"]}>
            <strong>No Nudity in Free Chat.</strong> A Performer may not be nude
            in "Free Chat" or other areas in which a User does not pay for
            services. In addition to its common meaning, the definition of nude
            includes, but is not limited to, exposure of areola of the female
            breast and/or lascivious display of your genitals and/or buttocks.
          </TailwindListItem>
          <TailwindListItem padding={["py-2"]}>
            <strong>Nudity in Nude Chat</strong> A Performer must be nude in
            "Nude Chat".
          </TailwindListItem>
          <TailwindListItem padding={["py-2"]}>
            <strong>Live Performances in Any Chat Room</strong> All chat room
            appearances must be live.
          </TailwindListItem>
          <TailwindListItem padding={["py-2"]}>
            <strong>Illegal Drug Use and Other Illegal Activity.</strong> The
            use of illegal drugs or any other illegal activity during a
            broadcast is strictly prohibited.
          </TailwindListItem>
          <TailwindListItem padding={["py-2"]}>
            <strong>
              Identification; Availability to Streamray During Broadcast.
            </strong>{" "}
            During any broadcast, you must show your face for a reasonable
            duration so as to enable a Streamray representative to verify your
            identity. If contacted by a Streamray representative during any
            broadcast, you must cooperate with that person to verify your
            identity or discuss this Policy. You will not be requested to
            "perform" by or for the representative.
          </TailwindListItem>
        </TailwindList>
      </TailwindBox>
    </div>
  );
};

export default StudiosAcceptableUsePolicy;
