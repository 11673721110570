enum DEFAULT_STORAGE_PREFIXES {
  FFN = "FFN",
  CAMS = "CAMS",
  AFF = "AFF",
  PASSION = "PASSION",
  MODELS = "MODELS",
  STUDIOS = "STUDIOS",
  DATING_MEMBERS = "DATING_MEMBERS",
}

const CAMS_MODELS_STORAGE_PREFIX = `${DEFAULT_STORAGE_PREFIXES.FFN}_${DEFAULT_STORAGE_PREFIXES.CAMS}_${DEFAULT_STORAGE_PREFIXES.MODELS}`;
const CAMS_STUDIOS_STORAGE_PREFIX = `${DEFAULT_STORAGE_PREFIXES.FFN}_${DEFAULT_STORAGE_PREFIXES.CAMS}_${DEFAULT_STORAGE_PREFIXES.STUDIOS}`;
const AFF_DATING_MEMBERS_STORAGE_PREFIX = `${DEFAULT_STORAGE_PREFIXES.FFN}_${DEFAULT_STORAGE_PREFIXES.AFF}_${DEFAULT_STORAGE_PREFIXES.DATING_MEMBERS}`;
const PASSION_DATING_MEMBERS_STORAGE_PREFIX = `${DEFAULT_STORAGE_PREFIXES.FFN}_${DEFAULT_STORAGE_PREFIXES.PASSION}_${DEFAULT_STORAGE_PREFIXES.DATING_MEMBERS}`;

export const CAMS_MODEL_SESSION_STORAGE_KEYS = {
  SUMMARY_MODAL: `${CAMS_MODELS_STORAGE_PREFIX}_IS_SHOWED_SUMMARY_MODAL`,
};
export const CAMS_MODELS_STORAGE_KEYS = {
  ACCESS_TOKEN: `${CAMS_MODELS_STORAGE_PREFIX}_ACCESS_TOKEN`,
  REFRESH_TOKEN: `${CAMS_MODELS_STORAGE_PREFIX}_REFRESH_TOKEN`,
  MODEL_REFERRAL: `${CAMS_MODELS_STORAGE_PREFIX}_MODEL_REFERRAL`,
  STUDIO_REFERRAL: `${CAMS_MODELS_STORAGE_PREFIX}_STUDIO_REFERRAL`,
  GLOBAL_LOGOUT_KEY: `${CAMS_MODELS_STORAGE_PREFIX}_logout`,
  GLOBAL_LOGIN_KEY: `${CAMS_MODELS_STORAGE_PREFIX}_login`,
  GLOBAL_STORAGE_KEY: `${CAMS_MODELS_STORAGE_PREFIX}_storage`,
  GIFTS: `${CAMS_MODELS_STORAGE_PREFIX}_GIFTS`,
  AWARDS: `${CAMS_MODELS_STORAGE_PREFIX}_AWARDS`,
  ACCOUNT_SETTINGS: `${CAMS_MODELS_STORAGE_PREFIX}_ACCOUNT_SETTINGS`,
  STORE: `${CAMS_MODELS_STORAGE_PREFIX}_STORE`,
  TUTORIAL_MODAL_SHOWN_KEY: `${CAMS_MODELS_STORAGE_PREFIX}_STORE`,
  MODEL: `${CAMS_MODELS_STORAGE_PREFIX}_MODEL`,
  HIDE_WELCOME_PAGE: `${CAMS_MODELS_STORAGE_PREFIX}_HIDE_WELCOME_PAGE`,
  SHOWCASE_STEP: `${CAMS_MODELS_STORAGE_PREFIX}_SHOWCASE_STEP`,
};

export const CAMS_STUDIOS_STORAGE_KEYS = {
  ACCESS_TOKEN: `${CAMS_STUDIOS_STORAGE_PREFIX}_ACCESS_TOKEN`,
  REFRESH_TOKEN: `${CAMS_STUDIOS_STORAGE_PREFIX}_REFRESH_TOKEN`,
  MODEL_REFERRAL: `${CAMS_STUDIOS_STORAGE_PREFIX}_MODEL_REFERRAL`,
  STUDIO_REFERRAL: `${CAMS_STUDIOS_STORAGE_PREFIX}_STUDIO_REFERRAL`,
  GLOBAL_LOGOUT_KEY: `${CAMS_STUDIOS_STORAGE_PREFIX}_logout`,
  GLOBAL_LOGIN_KEY: `${CAMS_STUDIOS_STORAGE_PREFIX}_login`,
  GLOBAL_STORAGE_KEY: `${CAMS_STUDIOS_STORAGE_PREFIX}_storage`,
  GIFTS: `${CAMS_STUDIOS_STORAGE_PREFIX}_GIFTS`,
  AWARDS: `${CAMS_STUDIOS_STORAGE_PREFIX}_AWARDS`,
  ACCOUNT_SETTINGS: `${CAMS_STUDIOS_STORAGE_PREFIX}_ACCOUNT_SETTINGS`,
  STORE: `${CAMS_STUDIOS_STORAGE_PREFIX}_STORE`,
  TUTORIAL_MODAL_SHOWN_KEY: `${CAMS_STUDIOS_STORAGE_PREFIX}_STORE`,
  MODEL: `${CAMS_STUDIOS_STORAGE_PREFIX}_MODEL`,
};

export const AFF_DATING_MEMBERS_STORAGE_KEYS = {
  ACCESS_TOKEN: `${AFF_DATING_MEMBERS_STORAGE_PREFIX}_ACCESS_TOKEN`,
  REFRESH_TOKEN: `${AFF_DATING_MEMBERS_STORAGE_PREFIX}_REFRESH_TOKEN`,
  GLOBAL_LOGOUT_KEY: `${AFF_DATING_MEMBERS_STORAGE_PREFIX}_logout`,
  GLOBAL_LOGIN_KEY: `${AFF_DATING_MEMBERS_STORAGE_PREFIX}_login`,
  GLOBAL_STORAGE_KEY: `${AFF_DATING_MEMBERS_STORAGE_PREFIX}_storage`,
  ACCOUNT_SETTINGS: `${AFF_DATING_MEMBERS_STORAGE_PREFIX}_ACCOUNT_SETTINGS`,
  STORE: `${AFF_DATING_MEMBERS_STORAGE_PREFIX}_STORE`,
};

export const PASSION_DATING_MEMBERS_STORAGE_KEYS = {
  ACCESS_TOKEN: `${PASSION_DATING_MEMBERS_STORAGE_PREFIX}_ACCESS_TOKEN`,
  REFRESH_TOKEN: `${PASSION_DATING_MEMBERS_STORAGE_PREFIX}_REFRESH_TOKEN`,
  GLOBAL_LOGOUT_KEY: `${PASSION_DATING_MEMBERS_STORAGE_PREFIX}_logout`,
  GLOBAL_LOGIN_KEY: `${PASSION_DATING_MEMBERS_STORAGE_PREFIX}_login`,
  GLOBAL_STORAGE_KEY: `${PASSION_DATING_MEMBERS_STORAGE_PREFIX}_storage`,
  ACCOUNT_SETTINGS: `${PASSION_DATING_MEMBERS_STORAGE_PREFIX}_ACCOUNT_SETTINGS`,
  STORE: `${PASSION_DATING_MEMBERS_STORAGE_PREFIX}_STORE`,
};
