import React from "react";
import classnames from "classnames";

type TokenIconProps = {
  className?: string;
};

const GiftIcon2: React.FunctionComponent<TokenIconProps> = ({
  className = "",
}) => {
  return (
    <span className={classnames("GiftIcon2", className && className)}>
      <svg
        version='1.2'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        xmlSpace='preserve'
        y='0px'
        x='0px'
        viewBox='0 0 26 26'>
        <g transform='translate(1, 1)'>
          <path
            d='M21.374,11.576h1.52L22.89,4.689H19.07c0.498-1.474,0.211-2.979-0.784-3.888  c-0.583-0.533-1.343-0.815-2.198-0.815c-1.241,0-2.545,0.617-3.487,1.649c-0.348,0.378-0.634,0.795-0.853,1.237  c-0.218-0.441-0.504-0.858-0.851-1.237c-0.944-1.033-2.248-1.65-3.489-1.65c-0.856,0-1.616,0.282-2.198,0.815  C4.214,1.709,3.928,3.214,4.425,4.689H0.607l0.005,1.47v3.948H0.607l0.005,1.469h1.479v12.423l19.283-0.003v-6.498h0.008v-1.062  l-0.008-0.421V11.576z M12.487,16.015v-4.438h7.417v4.438H12.487z M12.487,22.517v-5.019h7.417v5.019H12.487z M3.562,16.015v-4.438  h7.458v4.438H3.562z M3.562,22.517v-5.019h7.458v5.019H3.562z M9.891,2.555c0.571,0.625,0.903,1.398,0.927,2.135H5.91  c-0.486-1.045-0.37-2.164,0.308-2.784C6.563,1.589,7.021,1.423,7.54,1.423C8.364,1.423,9.244,1.846,9.891,2.555z M15.956,1.423  c0.521,0,0.979,0.167,1.324,0.482c0.676,0.621,0.79,1.739,0.304,2.784h-4.905c0.023-0.736,0.354-1.51,0.926-2.135  C14.252,1.846,15.132,1.423,15.956,1.423z M21.425,6.159v3.948H2.082V6.159H21.425z'
            vectorEffect='non-scaling-stroke'
          />
        </g>
      </svg>
    </span>
  );
};

export default GiftIcon2;
