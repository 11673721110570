export type ScreenBreakpoint =
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "4k"
  | "widescreen";
export type ScreenOrientation = "landscape" | "portrait";
export type UserAgent = "AppleMobile" | "Android" | "AppleMac" | "Windows";
export const BreakPoints = {
  widescreen: 3840,
  "4k": 2560,
  xl: 1279,
  lg: 1023,
  md: 767,
  sm: 639,
  xs: 475
};
