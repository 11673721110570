import { makeAutoObservable } from "mobx";
import { ContestSubType, LeaderBoardContests } from "./enums";
import { ContestData, LeaderBoardModel } from "./types";
import { api } from "core/utility";
import { profileStore } from "core/stores";

export default class LeaderBoardStore {
  contestData: ContestData = {
    day: {
      contest: [],
      winners: [],
    },
    month: {
      contest: [],
      winners: [],
    },
    year: {
      contest: [],
      winners: [],
    },
  };
  isLoadingData = false;
  loggedInModel: LeaderBoardModel = {
    username: profileStore?.profile?.username,
    displayDate: "",
    image:
      "https://minio.logicbeta.com/cams-d-com-development-model-image/0a/d1/0ad1b1fd-6ae1-480b-8955-c7202ad159ff/profile_e5e9e2b7-cbed-4315-b40b-9e97ba7d9e74/01ae3ef6-95c9-413a-8b0a-5b71936745f0/original.jpg?AWSAccessKeyId=AKIAIOSFODNN7EXAMPLE&Expires=1619560800&Signature=aD9xjzXuNhX4Ce9hACo8602zr4c%3D",
    profileUrl: "https://cams.com/modelname",
    rank: 15,
    tokensToNext: 231,
  };

  constructor() {
    makeAutoObservable(this);
  }

  initContestData = async () => {
    this.isLoadingData = true;
    this.contestData.day.winners = await this.getPastContestWinners(
      LeaderBoardContests.day
    );
    this.contestData.month.winners = await this.getPastContestWinners(
      LeaderBoardContests.month
    );
    this.isLoadingData = false;
  };

  changePage = async (
    contest: LeaderBoardContests,
    subType: ContestSubType,
    page: number
  ) => {
    if (subType === ContestSubType.winners) {
      this.isLoadingData = true;
      this.contestData[contest][subType] = await this.getPastContestWinners(
        contest,
        page
      );
      this.isLoadingData = false;
    }
  };

  getLoggedInModelRank = () => {
    return this.loggedInModel;
  };

  getPastContestWinners = (
    contestType: LeaderBoardContests,
    page: number = 1
  ): LeaderBoardModel[] => {
    return api.modelsTopModels
      .get(`?award_type=${contestType}&page=${page}`)
      .then(resp => {
        if (resp?.data?.results) {
          const fmtd: LeaderBoardModel[] = resp?.data?.results?.map(
            (mdl: any) => {
              return {
                username: mdl.model.screen_name,
                displayDate: mdl.date,
                image: mdl.model.profile_image,
                profileUrl: `https://cams.com/${mdl.model.screen_name}`,
              } as LeaderBoardModel;
            }
          );
          return fmtd;
        } else {
          return [];
        }
      });
  };
}
