export const CDN_PATH = "https://secureimage.securedataimages.com/images/cams/";
export const STATIC_SRC =
  process.env.REACT_APP_STATIC_PATH || process.env.PUBLIC_URL || "/";
export const IMAGES_PATH = `${STATIC_SRC}images/`;
export const STATIC_PATHS = {
  STATIC_SRC: STATIC_SRC,
  // TODO Move to CDN
  IMAGES_PATH: IMAGES_PATH,
  EMOJIS: IMAGES_PATH + "emojis/",
  GIFTS: IMAGES_PATH + "gifts/",
  ICONS: IMAGES_PATH + "icons/",
  BANNERS: IMAGES_PATH + "banners/",
  MODELS: IMAGES_PATH + "models/",
  GALLERY: IMAGES_PATH + "gallery/",
  TUTORIAL: IMAGES_PATH + "tutorial/",
  LANDING: IMAGES_PATH + "landing/",
};

export const CDN_SRC = {
  GIFTS: CDN_PATH + "vgifts/",
};

export const CAMS_WIRE_PAYMENTS_EMAIL = "wire_payments@cams.com";
export const CAMS_SUPPORT_PHONE_NUMBER = "1 (669) 208-0374";
export const CAMS_SUPPORT_TALL_PHONE_NUMBER = "(888)546-0188";
export const CAMS_MODEL_APPLICATIONS_EMAIL = "model_applications@cams.com";
export const CAMS_STUDIO_APPLICATIONS_EMAIL = "studio_applications@cams.com";
export const CAMS_TAX_FORMS_EMAIL = "tax_forms@cams.com";
export const CAMS_STREAMS_EMAIL = "streams@cams.com";
