import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "core/stores/route/private-route";
import MyFansContainer from "./my-fans";
import ContestContainer from "./contests";
import GiftsAndAwardsContainer from "./gifts-and-awards";
import ReferralsContainer from "./referrals";
import {
  AppCamsModelRouteKey,
  AppVariantRouteKey,
} from "core/stores/route/enums";
import NotFoundRoute from "core/stores/route/not-found-redirect";
import BlockUnblockContainer from "../../common/account-settings/model-block-unblock";
import { inject, observer } from "mobx-react";
import RouteStore from "core/stores/route/RouteStore";
import MyPageContainer from "common/my-page";
import LeaderBoardContainer from "common/leaderboard";
import TutorialContainer from "containers/model/tutorial";
import MediaManager from "containers/_components/media-manager";
import { ModelMediaRouteMode } from "containers/_components/media-manager/store/enums";

type Props = {
  routeStore?: RouteStore;
};

const CamsModelRoutes: React.ComponentType<Props> = ({ routeStore }) => {
  const { getSiteRouteURL } = routeStore!;
  const myPhotosRootURL = `${getSiteRouteURL(
    AppCamsModelRouteKey.myPhotosRoot
  )}`;

  return (
    <Switch>
      <PrivateRoute exact path={getSiteRouteURL(AppVariantRouteKey.tutorial)}>
        <TutorialContainer />
      </PrivateRoute>
      <PrivateRoute exact path={getSiteRouteURL(AppCamsModelRouteKey.myPage)}>
        <MyPageContainer />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={getSiteRouteURL(AppCamsModelRouteKey.leaderboards)}>
        <LeaderBoardContainer />
      </PrivateRoute>
      <PrivateRoute exact path={getSiteRouteURL(AppCamsModelRouteKey.myFans)}>
        <MyFansContainer />
      </PrivateRoute>
      <PrivateRoute exact path={getSiteRouteURL(AppCamsModelRouteKey.contests)}>
        <ContestContainer />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={getSiteRouteURL(AppCamsModelRouteKey.giftsAndAwards)}>
        <GiftsAndAwardsContainer />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={getSiteRouteURL(AppCamsModelRouteKey.referrals)}>
        <ReferralsContainer />
      </PrivateRoute>
      <PrivateRoute exact path={getSiteRouteURL(AppCamsModelRouteKey.blocks)}>
        <BlockUnblockContainer />
      </PrivateRoute>
      <PrivateRoute path={myPhotosRootURL}>
        <Switch>
          <PrivateRoute
            isNested={true}
            path={[
              `${myPhotosRootURL}/list`,
              `${myPhotosRootURL}/create`,
              `${myPhotosRootURL}/edit/:albumId`,
            ]}>
            <MediaManager mediaType={ModelMediaRouteMode.PHOTOS} />
          </PrivateRoute>
        </Switch>
      </PrivateRoute>
      <PrivateRoute
        exact
        path={`${getSiteRouteURL(AppCamsModelRouteKey.recordedVideos)}`}>
        <MediaManager mediaType={ModelMediaRouteMode.RECORDED_VIDEOS} />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={`${getSiteRouteURL(AppCamsModelRouteKey.uploadedVideos)}`}>
        <MediaManager mediaType={ModelMediaRouteMode.UPLOADED_VIDEOS} />
      </PrivateRoute>
      <NotFoundRoute />
    </Switch>
  );
};

export default inject("routeStore")(observer(CamsModelRoutes));
