import React from "react";
import "./styles.scss";
import TailwindBox from "library/components/_tailwind/box";
import { inject, observer } from "mobx-react";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import DashboardNotificationsWrapper from "containers/_layout/dashboard/dashboard-notifications-wrapper";

type Props = {
  layoutStore?: LayoutStore;
};

const DashboardBody: React.ComponentType<Props> = ({
  children,
  layoutStore,
}) => {
  const { bodyPadding, bodyOverflow, bodyFlexGrow } = layoutStore!;

  return (
    <TailwindBox
      width={"w-full"}
      display={"flex"}
      flexDirection={"flex-col"}
      flexGrow={bodyFlexGrow}>
      <DashboardNotificationsWrapper />
      <TailwindBox
        width={"w-auto"}
        flexGrow={bodyFlexGrow}
        backgroundColor={"bg-body-bg-color"}
        style={{
          padding: `${
            bodyPadding * 0.7
          }px ${bodyPadding}px ${bodyPadding}px ${bodyPadding}px`,
        }}
        overflow={bodyOverflow}>
        {children}
      </TailwindBox>
    </TailwindBox>
  );
};

export default inject("layoutStore")(observer(DashboardBody));
