import React from "react";
import "./styles.scss";
import PrivacyPolicy from "common/privacy-policy";

type Props = {};
const Cookies = ({}: Props) => {
  return <PrivacyPolicy jumpToSectionId={"sec2"} />;
};

export default Cookies;
