import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";

export type MediaManagerMediaCardMediaCountProps = {
  count: number;
};

const MediaManagerMediaCardMediaCount: React.ComponentType<MediaManagerMediaCardMediaCountProps> = ({ count }) => {
  if (!count) {
    return null;
  }

  return (
    <TailwindFlex
      width={"w-8"}
      height={"h-8"}
      alignItems={"items-center"}
      justifyContent={"justify-center"}
      backgroundColor={"bg-green-400"}
      backgroundOpacity={"bg-opacity-90"}
      borderRadius={"rounded-3xl"}
      margin={["mx-2"]}
      zIndex={"z-10"}>
      <TailwindText textColor={"text-white"} fontWeight={"font-bold"} >
        {count}
      </TailwindText>
    </TailwindFlex>
  );
};

export default MediaManagerMediaCardMediaCount;
