import { action, observable, makeObservable } from "mobx";
import { createIntl, createIntlCache } from "react-intl";
import { LOCALES_DATA } from "../../../i18n/locales";
import CookieStorage from "../../utility/cookie-storage";


export default class LanguageStore {
  language = "en-US";
  messages = LOCALES_DATA[this.language];
  intl;
  constructor() {
    makeObservable(this, {
      language: observable,
      messages: observable,
      intl: observable,
      set: action,
      getText: action
    });

    this.language = this.getLangSetting();

    this.set(this.language);
  }
  
  getMessages(language) {
    return LOCALES_DATA[language];
  }

  getLangSetting = () => {
    const existing = CookieStorage.get('BROADCASTER_LANG');
    if(existing) {
      return existing;
    } else {
      if(window.navigator.language.includes('en')) {
        return 'en-US';
      } else if(window.navigator.language.includes('es')) {
        return 'es';
      } else {
        return 'en-US';
      }
    }
  }

  updateCookieAndSetLang = (locale: string) => {
    if(LOCALES_DATA[locale]) {
      CookieStorage.set('BROADCASTER_LANG', locale);
      this.set(locale);
    }
  }

  set(locale) {
    this.language = locale;
    const cache = createIntlCache();
    this.intl = createIntl(
      {
        locale: locale,
        messages: LOCALES_DATA[locale],
      },
      cache
    );
  }

  getText(id, defaultText = "") {
    return this.intl.formatMessage({
      id: id,
      defaultMessage: defaultText,
    });
  }
}

export const languageStore = new LanguageStore();
