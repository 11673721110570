import { TailwindCardProps } from "library/components/_tailwind/card";
import React, { useCallback, useState } from "react";
import TailwindImageCrop, {
  Crop,
} from "library/components/_tailwind/image-crop";
import TailwindCard from "library/components/_tailwind/card";
import TailwindHeading from "library/components/_tailwind/heading";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import Modal from "library/components/modal";
import TailwindText from "library/components/_tailwind/text";
import TailwindList from "library/components/_tailwind/list";
import TailwindListItem from "library/components/_tailwind/list/list-item";

type ProfilePhotoCropCardProps = {
  cropSrc: File | null;
  cropImageType: "profile_image" | "non_nude_profile_image" | null;
  cropFinished: Function;
};

const ProfilePhotoCropCard: React.FunctionComponent<
  ProfilePhotoCropCardProps & TailwindCardProps
> = ({ cropSrc, cropImageType, cropFinished }) => {
  const [cropPreview, setCropPreview] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  const [crop, setCrop] = useState<Crop>({
    aspect: 16 / 9,
    width: 100,
    unit: "%",
  });

  const updateCropPreview = useCallback((previewUrl: string) => {
    window.URL.revokeObjectURL(cropPreview);
    setCropPreview(previewUrl);
  }, []);

  const handleCancel = () => {
    if (showMoreInfo) {
      setShowMoreInfo(false);
    } else {
      setModalOpen(false);
      cropFinished();
    }
  };

  const handleSubmit = async () => {
    const _blob = await fetch(cropPreview).then(r => {
      return r.blob();
    });
    const blob = new Blob([_blob], { type: "image/png" });

    cropFinished(blob, cropImageType, cropPreview);
  };

  const onCropChange = useCallback((crop: Crop) => {
    setCrop(crop);
  }, []);

  return (
    <Modal open={modalOpen} closeModal={handleCancel}>
      <TailwindCard
        maxHeight={"max-h-screen"}
        backgroundColor={"bg-primary-bg-color"}
        minWidth={"min-w-72"}
        padding={["p-6"]}
        pseudoClasses={["md:p-2"]}
        borderRadius={"rounded"}>
        {showMoreInfo ? (
          <TailwindFlex
            maxWidth={"max-w-3xl"}
            flexDirection={"flex-col"}
            alignItems={"items-start"}
            justifyContent={"justify-center"}>
            <TailwindHeading margin={["mb-4"]} level={6}>
              Your Profile Photo is shown to guests on the Who's Online Now page
              and appears as the default image when you're in a paid chat
              session.
            </TailwindHeading>
            <TailwindText margin={["mb-2"]} fontSize={"text-sm"}>
              The following rules apply to your Profile Photo. Photos that
              violate these rules will be rejected.
            </TailwindText>
            <TailwindList
              listStyleType={"list-disc"}
              fontSize={"text-sm"}
              margin={["mb-4"]}>
              <TailwindListItem listStyleType={"list-disc"} margin={["mb-1"]}>
                Photo dimensions should be landscape orientation in 4:3 or 16:9
                aspect ratio, minimum 200 x 150px, maximum 1000 x 562px with at
                least 72dpi resolution.
              </TailwindListItem>
              <TailwindListItem listStyleType={"list-disc"} margin={["mb-1"]}>
                Your face must be identifiable and match the primary model of
                the stream.
              </TailwindListItem>
              <TailwindListItem listStyleType={"list-disc"} margin={["mb-1"]}>
                Photos must not have copyright protection, poor resolution,
                pixelation, custom frames, text or graphic overlays,
                unauthorized endorsements or badges, contact information, or
                subject material that otherwise violates the Terms of Use.
              </TailwindListItem>
            </TailwindList>
            <TailwindText margin={["mb-2"]} fontSize={"text-sm"}>
              All images are reviewed by Streamray staff. When approved, a
              rating is assigned based on the photo composition.
            </TailwindText>
            <TailwindText margin={["mb-2"]} fontSize={"text-sm"}>
              Members cannot see your Promotional Images album. You may only
              select one photo in the Promotional Images gallery to be your
              Profile Photo.
            </TailwindText>
            <TailwindText margin={["mb-2"]} fontSize={"text-sm"}>
              If your Profile Photo has Rating PG or PG-13, it will be shown on
              the WON page to guests and members across all Streamray sites.
            </TailwindText>
            <TailwindText margin={["mb-2"]} fontSize={"text-sm"}>
              If your Profile Photo has Rating R or X, it will be restricted
              from being shown on the WON page for some Streamray sites but will
              be shown to all logged in members.
            </TailwindText>
            <TailwindText margin={["mb-2"]} fontSize={"text-sm"}>
              If you would like to create additional photo albums, click the
              Create Album button. You can create free or paid albums, control
              who can see the album, and set how much each album costs to view.
            </TailwindText>
            <TailwindButton
              onClick={() => {
                setShowMoreInfo(false);
              }}
              width={"w-full"}
              height={"h-14"}
              borderStyle={"border-solid"}
              borderWidth={["border"]}
              borderRadius={"rounded"}
              borderColor={"border-black"}
              justifyContent={"justify-center"}
              margin={["mt-4"]}
              fullWidth={true}>
              OK
            </TailwindButton>
          </TailwindFlex>
        ) : (
          <TailwindFlex
            flexDirection={"flex-col"}
            alignItems={"items-center"}
            justifyContent={"justify-center"}>
            <TailwindFlex
              justifyContent={"justify-center"}
              alignItems={"items-center"}
              margin={["mb-4"]}
              pseudoClasses={["md:flex-col"]}>
              <TailwindHeading level={5}>
                Crop your profile image
              </TailwindHeading>
              <TailwindButton
                fullWidth={false}
                onClick={() => {
                  setShowMoreInfo(true);
                }}>
                More Info
              </TailwindButton>
            </TailwindFlex>
            <TailwindImageCrop
              maxWidth={"max-w-3xl"}
              crop={crop}
              src={cropSrc}
              cropPreview={cropPreview}
              imageType={cropImageType}
              setCropPreview={updateCropPreview}
              onCropChange={onCropChange}
            />

            <TailwindButton
              onClick={handleSubmit}
              disabled={!cropPreview}
              width={"w-full"}
              height={"h-14"}
              borderStyle={"border-solid"}
              borderWidth={["border"]}
              borderRadius={"rounded"}
              borderColor={"border-black"}
              justifyContent={"justify-center"}
              margin={["mt-4"]}
              fullWidth={true}>
              Upload
            </TailwindButton>
          </TailwindFlex>
        )}
      </TailwindCard>
    </Modal>
  );
};

export default ProfilePhotoCropCard;

//move astro to bottom, gender
// check if age is display age,  or if used on 2257
//change age / bday to display age m/d
// profile album should be next to profile photos
// ability to set
