import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import BroadcastOBSPlayback from "./broadcast-obs-playback";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";

interface Props {
  broadcastStreamStore?: BroadcastStreamStore;
  broadcastStore?: BroadcastStore;
}

const BroadcastOBSTimer: React.FunctionComponent<
  Props & WrappedComponentProps
> = () => {
  return (
    <div className='h-64 BroadcastOBSTimer'>
      <BroadcastOBSPlayback />
    </div>
  );
};

export default injectIntl(
  inject("broadcastStreamStore", "broadcastStore")(observer(BroadcastOBSTimer))
);
