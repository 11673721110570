import React, { FunctionComponent } from "react";
import PleaseContactUs from "./please-contact-us";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";

const ApplicationDenied: FunctionComponent<{ isStudio: boolean }> = ({
  isStudio,
}) => {
  return (
    <React.Fragment>
      <TailwindTranslatedText
        textColor='text-main-color'
        fontSize={"text-sm"}
        descriptor={{
          id: "landing.summaryStep.deniedText",
          defaultMessage:
            "There is a problem with your application. Please click on the links below to learn what you need to do to get your application back on track.",
        }}
      />
      <br />
      <br />
      <PleaseContactUs isStudio={isStudio} />
    </React.Fragment>
  );
};
export default ApplicationDenied;
