import { AppCommonRouteKey, RouteSidebarLocations } from "./enums";
import { AppRoute } from "./types";
import { CAMS_LANDING_TITLE } from "./cams";
import { userRole } from "library/core/stores/consts";
import { UserRole } from "library/core/types";

export const COMMON_ROUTES: Record<AppCommonRouteKey, AppRoute> = {
  [AppCommonRouteKey.completeRegistration]: {
    key: AppCommonRouteKey.completeRegistration,
    hasAccess: (
      _userRole: UserRole,
      _isIndividualModel: boolean,
      profileLoaded: boolean,
      profileRegistrationCompleted: boolean
    ) => {
      if (profileLoaded && !profileRegistrationCompleted) {
        return true;
      }
      return false;
    },
    sidebarOptions: {
      label: "Complete Registration",
      icon: "person",
      location: RouteSidebarLocations.top,
      index: 1,
    },
  },
  [AppCommonRouteKey.broadcast]: {
    key: AppCommonRouteKey.broadcast,
    link: "broadcast",
    pageTitle: "Broadcast",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.contact]: {
    key: AppCommonRouteKey.contact,
    link: "contact-us",
    pageTitle: "Contact Us",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.logout]: {
    key: AppCommonRouteKey.logout,
    link: "logout",
    pageTitle: "Logout",
    hasAccess: ["model", "studio"],
    sidebarOptions: {
      location: RouteSidebarLocations.bottom,
      index: 3,
      icon: "logout",
      label: "Logout",
    },
  },
  [AppCommonRouteKey.login]: {
    key: AppCommonRouteKey.login,
    link: "login",
    pageTitle: "Login",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.register]: {
    key: AppCommonRouteKey.register,
    link: "register",
    pageTitle: "Sign Up",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.accountClosed]: {
    key: AppCommonRouteKey.accountClosed,
    link: "account-closed",
    pageTitle: "Account Closed",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.landing]: {
    key: AppCommonRouteKey.landing,
    link: "",
    pageTitle: CAMS_LANDING_TITLE,
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.notifications]: {
    key: AppCommonRouteKey.notifications,
    link: "notifications",
    pageTitle: "Notifications",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.learnMore]: {
    key: AppCommonRouteKey.learnMore,
    link: "learn-more",
    pageTitle: "Learn More",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.earnings]: {
    key: AppCommonRouteKey.earnings,
    link: "earnings",
    pageTitle: "Earnings",
    hasAccess: ["model", "studio"],
    sidebarOptions: {
      location: RouteSidebarLocations.top,
      index: userRole === "studio" ? 2 : 6,
      icon: "earnings",
      label: "Earnings",
    },
  },
  [AppCommonRouteKey.messages]: {
    key: AppCommonRouteKey.messages,
    link: "messages",
    pageTitle: "Messages",
    hasAccess: ["model"],
    sidebarOptions: {
      location: RouteSidebarLocations.top,
      index: 7,
      label: "Messages",
      icon: "messages",
    },
  },
  [AppCommonRouteKey.compose]: {
    key: AppCommonRouteKey.compose,
    link: "compose",
    pageTitle: "Compose Message",
    hasAccess: ["model"],
  },
  [AppCommonRouteKey.searchMessages]: {
    key: AppCommonRouteKey.searchMessages,
    link: "search",
    pageTitle: "Search Messages",
    hasAccess: ["model"],
  },
  [AppCommonRouteKey.contacts]: {
    key: AppCommonRouteKey.contacts,
    link: "contacts",
    pageTitle: "Contacts",
    hasAccess: ["model"],
  },
  [AppCommonRouteKey.notices]: {
    key: AppCommonRouteKey.notices,
    link: "notices",
    pageTitle: "Latest News & Policies",
    hasAccess: ["model", "studio"],
    sidebarOptions: {
      location: RouteSidebarLocations.top,
      index: 8,
      label: "Latest News & Policies",
      icon: "newspaper",
    },
  },
  [AppCommonRouteKey.resetPassword]: {
    key: AppCommonRouteKey.resetPassword,
    link: "reset-password",
    pageTitle: "Reset Password",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.resetUsername]: {
    key: AppCommonRouteKey.resetUsername,
    link: "reset-username",
    pageTitle: "Reset Username",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.studiosAcceptableUsePolicy]: {
    key: AppCommonRouteKey.studiosAcceptableUsePolicy,
    link: "studios-acceptable-use-policy",
    pageTitle: "Studios Acceptable Use Policy",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.acceptableUsePolicy]: {
    key: AppCommonRouteKey.acceptableUsePolicy,
    link: "acceptable-use-policy",
    pageTitle: "Acceptable Use Policy",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.recordKeepingRequirementsCompliance]: {
    key: AppCommonRouteKey.recordKeepingRequirementsCompliance,
    link: "record-keeping-requirements-compliance",
    pageTitle:
      "18 U.S.C. 2257 Record Keeping Requirements Compliance Statement",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.protectKids]: {
    key: AppCommonRouteKey.protectKids,
    link: "protect-kids",
    pageTitle: "Protect Your Kids: Keeping Children Away from Adult Sites",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.privacyPolicy]: {
    key: AppCommonRouteKey.privacyPolicy,
    link: "privacy-policy",
    pageTitle: "FriendFinder Networks Inc. Privacy Policy",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.paymentRenewalTermsOfUse]: {
    key: AppCommonRouteKey.paymentRenewalTermsOfUse,
    link: "payment-renewal-terms-of-use",
    pageTitle: "Terms of Use",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.personsAppearingOnStreamray]: {
    key: AppCommonRouteKey.personsAppearingOnStreamray,
    link: "persons-appearing-on-streamray",
    pageTitle: "A note about persons appearing on Streamray Inc. websites.",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.dmca]: {
    key: AppCommonRouteKey.dmca,
    link: "dmca",
    pageTitle:
      "Important Legal Information and Information Regarding the Digital Millenium Copyright Act",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.cookies]: {
    key: AppCommonRouteKey.cookies,
    link: "cookies",
    pageTitle: "Cookies",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.californiaAct]: {
    key: AppCommonRouteKey.californiaAct,
    link: "california-act",
    pageTitle: "California Consumer Privacy Act Notice",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.percentagePayout]: {
    key: AppCommonRouteKey.percentagePayout,
    link: "percentage-payout",
    pageTitle: "Payout Schedule",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.bountyLegalInfo]: {
    key: AppCommonRouteKey.bountyLegalInfo,
    link: "bounty-legal-info",
    pageTitle: "Bounty Legal Information",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.performersAcknowledgementAndAgreement]: {
    key: AppCommonRouteKey.performersAcknowledgementAndAgreement,
    link: "performers-acknowledgement-and-agreement",
    pageTitle: "Performers Acknowledgement and Agreement",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.broadcastAgreement]: {
    key: AppCommonRouteKey.broadcastAgreement,
    link: "broadcast-agreement",
    pageTitle: "Broadcast Agreement",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.broadcastRelease]: {
    key: AppCommonRouteKey.broadcastRelease,
    link: "broadcast-release",
    pageTitle: "Broadcast Release",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.obsSupport]: {
    key: AppCommonRouteKey.obsSupport,
    link: "obs-support",
    pageTitle: "OBS Support",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.legalDocuments]: {
    key: AppCommonRouteKey.legalDocuments,
    link: "legal-documents",
    pageTitle: "Legal Documents",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.updatePassword]: {
    key: AppCommonRouteKey.updatePassword,
    link: "update-password",
    pageTitle: "Update Password",
    hasAccess: ["model", "studio"],
  },
  [AppCommonRouteKey.payout]: {
    key: AppCommonRouteKey.payout,
    link: "payout",
    pageTitle: "Payout",
    hasAccess: (userRole: UserRole, isIndividualModel: boolean) => {
      if (userRole === "studio" || isIndividualModel) {
        return true;
      }

      return false;
    },
  },
  [AppCommonRouteKey.broadcastMemberVerification]: {
    key: AppCommonRouteKey.broadcastMemberVerification,
    link: "broadcast-member-verification",
    pageTitle: "Member Verification",
    hasAccess: ["model", "studio"],
  },
};
