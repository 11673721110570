import {
  BroadcastStreamState,
  IGameSettings,
  IShowType,
  MainTab,
  PrivateShowSelection,
  ShowTab,
  WebRTCStatus,
} from "./enums";
import { BroadcastShowType } from "./types";
import { BroadcastVideoResolutionOption } from "common/broadcast/_stores/broadcast-stream/types";
import { IMember } from "common/broadcast/_stores/chat/interfaces";

export const MOCKED_USER = {
  id: "mock",
  username: "Mock_User",
  gender: "Male",
  age: 18,
  about: "About me test",
  profile_picture: "",
  gallery_picture_1: "",
  gallery_picture_2: "",
  gallery_picture_3: "",
  gallery_picture_4: "",
  gallery_picture_5: "",
  pictures: [],
};
export const BROADCAST_CAMERA_RESOLUTIONS: BroadcastVideoResolutionOption[] = [
  /*{
    value: "320x240",
    label: "320x240",
  },
  {
    value: "640x480",
    label: "640x480",
  },
  {
    value: "854x480",
    label: "854x480 widescreen",
  },
  {
    value: "800x600",
    label: "800x600",
  },
  {
    value: "1024x576",
    label: "1024x576 widescreen",
  },*/
  {
    value: "1280x720",
    label: "1280x720 widescreen",
  },
  /*{
    value: "1112x846",
    label: "1112x846",
  },
  {
    value: "1360x768",
    label: "1360x768 widescreen",
  },*/
  /*{
    value: "1366x768",
    label: "1366x768 widescreen",
  },
  {
    value: "1600x900",
    label: "1600x900 widescreen",
  },*/ //any widescreen above 720p is not actually needed
  /*{
    value: "1400x1050",
    label: "1400x1050",
  },*/
  {
    value: "1920x1080",
    label: "1920x1080 widescreen",
  },
  /*{
    value: "2560x1440",
    label: "2560x1440 widescreen",
  },
  {
    value: "3840x2160",
    label: "3840x2160 widescreen",
  },*/ //any widescreen above 720p is not actually needed
];
export const BROADCAST_DEFAULT_CAMERA_RESOLUTION_INDEX =
  BROADCAST_CAMERA_RESOLUTIONS.findIndex(
    resolution => resolution.value === "1280x720"
  );

export const BROADCAST_SHOW_TYPES: BroadcastShowType[] = [
  {
    type: IShowType.FREE,
    tabText: "FREE",
    titleIntlKey: "free",
    tooltip: {
      intlId: "broadcast-show-type-control.free.tooltip",
      intlDefaultMessage: "Free Show",
    },
    isActive: false,
  },
  {
    type: IShowType.TIPPING,
    subType: IShowType.CURTAIN_DROPPED,
    tabText: "TIPPING",
    titleIntlKey: "tipping",
    tooltip: {
      intlId: "broadcast-show-type-control.tipping.tooltip",
      intlDefaultMessage:
        "Tipping Chat is like Free Chat, except viewers can leave tips and make requests. Nudity is only allowed once you have started your show.",
    },
    isActive: false,
  },
  {
    type: IShowType.NUDE,
    tabText: "NUDE",
    titleIntlKey: "nude",
    tooltip: {
      intlId: "broadcast-show-type-control.nude.tooltip",
      intlDefaultMessage:
        "Nude Chats are live, pay-per-minute nude shows that are seen by multiple members. You must be nude during a Nude Chat, and can be as sexually explicit as you wish. This chat type is a great way to earn money fast.",
    },
    isActive: false,
  },
];

export const BROADCASTER_IS_PARTY_CHAT_ENABLED = false;

export const BROADCASTER_IS_CONNEXION_ONE_WAY_ENABLED = false;

export const BROADCASTER_IS_CONNEXION_TWO_WAY_ENABLED = false;

export const BROADCASTER_MEMBERS: IMember[] = [];

export const BROADCASTER_GUESTS = [];

export const BROADCASTER_QUICK_MESSAGES = [];

export const BROADCASTER_OFFLINE_ERROR_MESSAGE_ID =
  "broadcast-error.stopping-stream-from-offline";
export const BROADCASTER_ONLINE_ERROR_MESSAGE_ID =
  "broadcast-error.starting-stream-from-online";

export const InitialData = {
  broadcastSocket: undefined,
  onGiftReceived: undefined,
  interval: undefined,
  broadcastListeners: undefined,
  memberBalanceDisposer: undefined,
  selectedMemberId: undefined,
  selectedMember: undefined,
  members: BROADCASTER_MEMBERS,
  guests: BROADCASTER_GUESTS,
  quickMessages: BROADCASTER_QUICK_MESSAGES,
  currentShowType: IShowType.NONE,
  previousShowType: IShowType.NONE,
  currentGameSettings: IGameSettings.NONE,
  isVideoSettingsPopoverShown: false,
  isAudioSettingsPopoverShown: false,
  isVideoSettingsOverlayShown: false,
  isWheelOfFunSettingsPopoverShown: false,
  showEndTippingShowConfirmation: false,
  shouldShowStartTippingShowConfirmation: false,
  cancelTippingShowAndStart: undefined,
  showTippingSum: 0,
  c2cHash: {},
  currentShowTab: ShowTab.FREE,
  previousShowTab: ShowTab.FREE,
  currentMainTab: MainTab.SHOW_TYPES,
  previousMainTab: MainTab.SHOW_TYPES,
  privateShowChoice: PrivateShowSelection.NONE,
  isPrivateConsentOpen: false,
  currentPrivateRequest: null,
  currentPrivateRequestRemainingSeconds: 0,
  currentPrivateRequestCountdownInterval: undefined,
  currentPrivateRequestChatPrice: 0,
  startShowRetry: 0,
  startShowMaxRetry: 1,
  isBuzzPopUpOpen: false,
  isWofPopUpOpen: false,
  isRecording: false,
  isStartingRecording: false,
  recordingReason: null,
  countdownStarted: false,
  showTypeChangeReaction: undefined,
  onlineOfflineChangeReaction: undefined,
  storeInited: false,
  isAlreadyBroadcastingInAnotherWindow: false,
  currentShowSessionId: undefined,
  currentShowSessionStartTime: undefined,
  notificationAccordionExpanded: true,
  streamState: BroadcastStreamState.stopped,
  isMakingStartShowTypeRequest: false,
  isBroadcastingAlone: true,
  minimumRecordingDurationInSeconds: 120,
  broadcastSessionId: undefined,
  webRTCStatus: WebRTCStatus.Stopped,
  isCountUpTimerOn: false,
  bountyOrderMember: undefined,
  fanClubOrderMember: undefined,
};

export const BROADCAST_END_TIPPING_SHOW_EARLY_SHOW_STOP_WARNING_TIME_IN_MINUTES = 5;

export const BROADCAST_RECORDING_STOP_AFTER_N_MINUTES = 59; // on 60th minute it will give an error so stop at 59
export const BROADCAST_RECORDING_STOP_AFTER_N_SECONDS =
  BROADCAST_RECORDING_STOP_AFTER_N_MINUTES * 60 + 59;

export const BROADCAST_SHOW_TYPES_TO_RECORD_WHEN_AUTOMATIC_ENABLED: IShowType[] =
  [IShowType.PRIVATE, IShowType.GROUP];

export const SHOW_TYPE_ACTIVE_SHOW_TAB_MAP: {
  [key in IShowType]?: ShowTab;
} = {
  [IShowType.FREE]: ShowTab.FREE,
  [IShowType.PRIVATE]: ShowTab.FREE,
  [IShowType.GROUP]: ShowTab.FREE,
  [IShowType.CURTAIN_DROPPED]: ShowTab.TIPPING,
  [IShowType.TIPPING]: ShowTab.TIPPING,
  [IShowType.NUDE]: ShowTab.NUDE,
};

export const TwitterMessage = "I'm online now. Let's play!";
