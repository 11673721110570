import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import TailwindAnchor from "../../components/_tailwind/anchor";
import Code from "../../components/_tailwind/code";

type Props = {};

const StorybookArchitectureAdaptiveAndResponsiveDesign: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>
        Architecture &gt; Adaptive & Responsive Design
      </StorybookHeading>
      <StorybookParagraph>
        <TailwindAnchor
          to={"https://en.wikipedia.org/wiki/Adaptive_web_design"}
        >
          Adaptive Design
        </TailwindAnchor>{" "}
        refers to showing different set of components per device size however in
        our case it also means we show different set of components according to
        a set of conditions.
      </StorybookParagraph>
      <StorybookParagraph>
        There is a LayoutStore utility that has some observable variables that
        can tell you whether you are on a Mobile device or not along with the
        size of the device. This allows us to show ComponentA for desktop and
        ComponentB for tablet/mobile or even better via the screenSize
        ComponentB for tablet, ComponentC for mobile.
      </StorybookParagraph>
      <StorybookParagraph>
        The same "adaptivity" also applies to showing different containers based
        on user role. This is most of the time called Multi-Tenant application
        however we name it Adaptive Design as the code "adapts" itself to
        different set of conditions.
      </StorybookParagraph>
      <StorybookParagraph>
        Given the below folder structure referenced from{" "}
        <TailwindAnchor to={"/architecture/file-and-folder-structure"}>
          Architecture, File and Folder Structure page
        </TailwindAnchor>
        , we would have the following code:
      </StorybookParagraph>
      <Code>
        {`common/my-page/index.tsx        
common/my-page/model.tsx        
common/my-page/studio.tsx        
common/my-page/dating-member.tsx        
common/my-page/passion-member.tsx

inside index.tsx:

switch (userRole): {
  case "aff": {
    return <AffMyPage />
  }
  case "model": {
    return <CamsModelMyPage />
  }
  case "studio": {
    return <CamsStudioMyPage />
  }
  case "passion": {
    return <PassionMyPage />
  }
}
`}
      </Code>
      <StorybookParagraph>
        The same set of if/elses and switch/case statements allow us to reuse
        some of the containers/components in a dynamic way such as Header
        component having different set of buttons for AFF while not having for
        Cams.
      </StorybookParagraph>
      <StorybookParagraph>
        Another example can be the Sidebar showing different links/menus based
        on userRole. All over the app, there are areas like this (for example
        also in API Facade) that differentiates certain code from one another to
        correctly fulfill a task.
      </StorybookParagraph>
      <StorybookParagraph>
        In other words, it is not only showing a component based on some
        conditions but also in areas like store logic, types, enums, will also
        follow the same "adaptive" design.
      </StorybookParagraph>
      <StorybookParagraph>
        ThemeStore for example has JS variables relating to the theming for each
        products light/dark themes. We will at later stages, inject ThemeStore
        into the shared components so that they have a bit of a logic inside
        themselves to correctly set colors, radiuses, widths etc. according to
        the product/userRole combinations
      </StorybookParagraph>
    </StorybookWrapper>
  );
};

export default StorybookArchitectureAdaptiveAndResponsiveDesign;
