import React from "react";
import "./styles.scss";
import clsx from "clsx";

type ArchivedIconProps = {
  className?: string;
};

const ArchivedIcon: React.ComponentType<ArchivedIconProps> = ({ className }) => (
  <span className={clsx("ArchivedIcon", className && className)}>
    <svg 
      version="1.2" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlnsXlink="http://www.w3.org/1999/xlink" 
      overflow="visible" 
      preserveAspectRatio="none" 
      viewBox="0 0 24 24" 
      width="24" 
      height="24"
    >
      <g transform="translate(0, 0)">
        <g transform="translate(-1.3322676295501878e-15, 0) rotate(0)">
          <path 
            //style="stroke-width: 0; stroke-linecap: butt; stroke-linejoin: miter; fill: rgb(92, 92, 92);" 
            d="M14.6,10h-5.3c-0.9,0.1 -1.6,0.8 -1.7,1.7c-0.1,1.1 0.6,2.1 1.8,2.2h5.2c0.5,0 1,-0.2 1.4,-0.6c0.4,-0.4 0.6,-0.9 0.6,-1.4c-0.1,-1 -1,-1.9 -2,-1.9zM15,12.5v0c-0.1,0.1 -0.3,0.2 -0.5,0.2h-5.1c-0.4,-0.1 -0.6,-0.4 -0.6,-0.8c0,-0.3 0.3,-0.6 0.5,-0.6h5.3c0.4,0 0.6,0.3 0.6,0.7c0,0.2 -0.1,0.3 -0.2,0.5zM23.5,0.4c-0.2,-0.2 -0.6,-0.4 -1,-0.4h-20.9c-0.9,0 -1.5,0.7 -1.6,1.5v3.5c0,0.6 0.4,1.1 0.9,1.4c0,0.2 -0.1,0.3 -0.1,0.5v15.4c0,0.9 0.7,1.6 1.5,1.7h19.2c0.8,0 1.5,-0.7 1.5,-1.7v-15.4c0,-0.2 0,-0.3 -0.1,-0.5c0.7,-0.2 1.1,-0.7 1.1,-1.4v-3.5c0,-0.4 -0.2,-0.8 -0.5,-1.1zM21.6,22.7h-19.2c-0.1,0 -0.2,-0.2 -0.2,-0.4v-15.4c0,-0.2 0.1,-0.4 0.2,-0.4h19.2c0,0 0.1,0 0.1,0.1v0c0.1,0.1 0.1,0.2 0.1,0.3v15.4c0,0.3 -0.1,0.4 -0.2,0.4zM22.5,5.2h-0.9h-19.2h-0.8c-0.1,0 -0.2,-0.1 -0.3,-0.2v-3.5c0,-0.1 0.1,-0.2 0.2,-0.2h20.9c0,0 0.1,0 0.1,0.1v0l0.1,0.1v3.5c0.1,0.1 0,0.2 -0.1,0.2z" 
            vectorEffect="non-scaling-stroke"/>
        </g>
        <defs>
          <path 
            id="path-1711135279750539" 
            d="M14.6,10h-5.3c-0.9,0.1 -1.6,0.8 -1.7,1.7c-0.1,1.1 0.6,2.1 1.8,2.2h5.2c0.5,0 1,-0.2 1.4,-0.6c0.4,-0.4 0.6,-0.9 0.6,-1.4c-0.1,-1 -1,-1.9 -2,-1.9zM15,12.5v0c-0.1,0.1 -0.3,0.2 -0.5,0.2h-5.1c-0.4,-0.1 -0.6,-0.4 -0.6,-0.8c0,-0.3 0.3,-0.6 0.5,-0.6h5.3c0.4,0 0.6,0.3 0.6,0.7c0,0.2 -0.1,0.3 -0.2,0.5zM23.5,0.4c-0.2,-0.2 -0.6,-0.4 -1,-0.4h-20.9c-0.9,0 -1.5,0.7 -1.6,1.5v3.5c0,0.6 0.4,1.1 0.9,1.4c0,0.2 -0.1,0.3 -0.1,0.5v15.4c0,0.9 0.7,1.6 1.5,1.7h19.2c0.8,0 1.5,-0.7 1.5,-1.7v-15.4c0,-0.2 0,-0.3 -0.1,-0.5c0.7,-0.2 1.1,-0.7 1.1,-1.4v-3.5c0,-0.4 -0.2,-0.8 -0.5,-1.1zM21.6,22.7h-19.2c-0.1,0 -0.2,-0.2 -0.2,-0.4v-15.4c0,-0.2 0.1,-0.4 0.2,-0.4h19.2c0,0 0.1,0 0.1,0.1v0c0.1,0.1 0.1,0.2 0.1,0.3v15.4c0,0.3 -0.1,0.4 -0.2,0.4zM22.5,5.2h-0.9h-19.2h-0.8c-0.1,0 -0.2,-0.1 -0.3,-0.2v-3.5c0,-0.1 0.1,-0.2 0.2,-0.2h20.9c0,0 0.1,0 0.1,0.1v0l0.1,0.1v3.5c0.1,0.1 0,0.2 -0.1,0.2z" 
            vectorEffect="non-scaling-stroke"/>
        </defs>
      </g>
    </svg>
  </span>
);

export default ArchivedIcon;
