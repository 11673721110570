import React from "react";
import "./styles.scss";
import MyVirtualGifts from "./my-virtual-gifts";
import TailwindBox from "library/components/_tailwind/box";
//import MyAwards from './my-awards';

type GiftsAndAwardsProps = {};

const GiftsAndAwardsContainer: React.FunctionComponent<GiftsAndAwardsProps> =
  () => (
    <TailwindBox width={"w-full"} height={"h-full"}>
      <MyVirtualGifts />
    </TailwindBox>
  );

export default GiftsAndAwardsContainer;
