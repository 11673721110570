import { WowzaWebRTCStreamSettings } from "common/broadcast/_stores/broadcast-stream/types";
import {
  BROADCAST_CAMERA_RESOLUTIONS,
  BROADCAST_DEFAULT_CAMERA_RESOLUTION_INDEX,
} from "common/broadcast/_stores/broadcast/consts";
import { BroadcastDefaultDeviceSelectionAlgorithm } from "common/broadcast/_stores/broadcast-stream/enums";
import {
  convertKbpsToBps,
  convertMbpsToKbps,
} from "common/broadcast/_stores/broadcast-stream/utils";

export const WOWZA_MAX_VIDEO_BITRATE = 2000;

export const WOWZA_DEFAULT_WEBRTC_SETTINGS: WowzaWebRTCStreamSettings = {
  sdpURL: `${process.env.REACT_APP_API_BROADCAST_WS}`,
  streamInfo: {
    applicationName: "",
    streamName: "",
    sessionId: "[empty]",
  },
  mediaInfo: {
    audioBitrate: "64",
    audioCodec: "opus",
    videoBitrate: WOWZA_MAX_VIDEO_BITRATE.toString(),
    videoCodec: "42e01f",
    videoFrameRate: "30",
  },
  userData: {
    param1: "value1",
  },
  frameSize: "default",
};

export const INITIAL_DATA = {
  controlledMediaStream: null,
  originalMediaStream: null,
  isNanoReady: false,
  nanoPlayer: null,
  nanoPlayerSettings: null,
  miniNanoPlayer: null,
  startedWebRTCBroadcasting: false,
  streamType: null,
  obsWebrtcPlaybackStream: null,
  excludedDevices: [],
  cameras: [],
  microphones: [],
  isChangingCameraDevice: false,
  selectedCameraDeviceId: null,
  selectedMicrophoneDeviceId: null,
  selectedResolution:
    BROADCAST_CAMERA_RESOLUTIONS[BROADCAST_DEFAULT_CAMERA_RESOLUTION_INDEX]
      .value,
  videoQuality: 100,
  videoBitrate: WOWZA_MAX_VIDEO_BITRATE,
  isMicrophoneMuted: false,
  volumeGain: null,
  availableResolutions: [],
  isWaitingForWebRTCConnection: false,
  obsStreamConnected: false,
  obsTimer: 0,
  videoQualityMarker: 0,
  obsTimerInterval: undefined,
  isStreamInitialized: false,
  isGatheringMediaDeviceData: true,
  isConnectingToWebrtcStream: true,
  hasUserDeniedPermissionsToDevices: false,
  broadcastError: null,
  broadcastErrorAction: null,
  publishToken: "",
  publishTokenFetchError: false,
  webrtcStreamSettings: WOWZA_DEFAULT_WEBRTC_SETTINGS,
  peerConnection: null,
  peerConnectionConfig: {
    iceServers: [],
  },
  audioSender: null,
  videoSender: null,
  websocketConnection: null,
  receivedSDPResponse: false,
  peerConnectionStateConnected: false,
  peerConnectionStateConnectedReaction: undefined,
  obsVideoSettings: null,
  lastStoppedAt: undefined,
  isFetchingPublishToken: false,
};

export const NANOPLAYER_ELEMENT_ID = "stream-preview";
export const NANOPLAYER_MINI_ELEMENT_ID = "mini-stream-preview";
export const BROADCAST_NO_WEBCAMS_AVAILABLE_ERROR =
  "No webcams with 720p and higher";
export const BROADCAST_NO_MICROPHONES_AVAILABLE_ERROR = "No audio source";
export const BROADCAST_LAST_USED_CAMERA_DEVICE_ID_COOKIE_NAME =
  "lastUsedCamera";
export const BROADCAST_LAST_USED_MICROPHONE_DEVICE_ID_COOKIE_NAME =
  "lastUsedMicrophone";
export const BROADCAST_CAMERA_DEVICE_SELECTION_ALGORITHM_TYPE: BroadcastDefaultDeviceSelectionAlgorithm =
  BroadcastDefaultDeviceSelectionAlgorithm.LAST_USED;
export const BROADCAST_MICROPHONE_DEVICE_SELECTION_ALGORITHM_TYPE: BroadcastDefaultDeviceSelectionAlgorithm =
  BroadcastDefaultDeviceSelectionAlgorithm.LAST_USED;
export const BROADCAST_MAX_ALLOWED_FRAME_RATE = 30;
export const BROADCAST_MAX_ALLOWED_RESOLUTION = "1920x1088";
export const BROADCAST_RECOMMENDED_MINIMUM_RESOLUTION = "1280x720";

export const BROADCAST_MAX_ALLOWED_BITRATE_IN_MBPS = 8;
export const BROADCAST_MAX_ALLOWED_BITRATE_IN_KBPS = convertMbpsToKbps(
  BROADCAST_MAX_ALLOWED_BITRATE_IN_MBPS
);
export const BROADCAST_MAX_ALLOWED_BITRATE_IN_BPS = convertKbpsToBps(
  BROADCAST_MAX_ALLOWED_BITRATE_IN_KBPS
);
export const BROADCAST_RECOMMENDED_MINIMUM_BITRATE_IN_MBPS = 2.5;
export const BROADCAST_RECOMMENDED_MINIMUM_BITRATE_IN_KBPS = convertMbpsToKbps(
  BROADCAST_RECOMMENDED_MINIMUM_BITRATE_IN_MBPS
);
export const BROADCAST_RECOMMENDED_MINIMUM_BITRATE_IN_BPS = convertKbpsToBps(
  BROADCAST_RECOMMENDED_MINIMUM_BITRATE_IN_KBPS
);
export const BROADCAST_RECOMMENDED_MAXIMUM_BITRATE_IN_MBPS = 5;
export const BROADCAST_RECOMMENDED_MAXIMUM_BITRATE_IN_KBPS = convertMbpsToKbps(
  BROADCAST_RECOMMENDED_MAXIMUM_BITRATE_IN_MBPS
);
export const BROADCAST_RECOMMENDED_MAXIMUM_BITRATE_IN_BPS = convertKbpsToBps(
  BROADCAST_RECOMMENDED_MAXIMUM_BITRATE_IN_KBPS
);
export const BROADCAST_MODAL_OBS_INVALID_SETTINGS_NAME = "obs-invalid-settings";

export const JUST_STOPPED_INTERVAL = 10 * 1000;
