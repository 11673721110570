import React from "react";
import { IChatNotification } from "common/broadcast/_stores/chat/interfaces";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { BroadcastType } from "common/broadcast/_stores/broadcast/enums";
import { IBroadcastData } from "common/broadcast/_stores/broadcast/interfaces";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import LocalBar from "@material-ui/icons/LocalBar";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import { inject, observer } from "mobx-react";
import ChatStore from "common/broadcast/_stores/chat/ChatStore";

const broadcastTypes = new Map<BroadcastType, IBroadcastData>([
  [
    BroadcastType.Tipped,
    {
      className: "notificationTipped",
      icon: (fontSize: number) => (
        <MonetizationOnOutlinedIcon
          style={{ width: fontSize, height: fontSize }}
        />
      ),
    } as IBroadcastData,
  ],
  [
    BroadcastType.Gift,
    {
      className: "notificationGift",
      icon: (fontSize: number) => (
        <LocalBar style={{ width: fontSize, height: fontSize }} />
      ),
    } as IBroadcastData,
  ],
  [
    BroadcastType.Buzz,
    {
      className: "notificationBuzz",
      icon: (fontSize: number) => (
        <TailwindIcon
          name={CustomIconName.notificationBuzz}
          style={{ fontSize: `${fontSize}px` }}
        />
      ),
    } as IBroadcastData,
  ],
  [
    BroadcastType.SuperBuzz,
    {
      className: "notificationSuperBuzz",
      icon: (fontSize: number) => (
        <TailwindIcon
          name={CustomIconName.notificationSuperBuzz}
          style={{ fontSize: `${fontSize}px` }}
        />
      ),
    } as IBroadcastData,
  ],
  [
    BroadcastType.BroadcastStart,
    {
      className: "notificationBroadcastingInHD",
    } as IBroadcastData,
  ],
  [
    BroadcastType.BroadcastStop,
    {
      className: "notificationBroadcastingStop",
    } as IBroadcastData,
  ],
  [
    BroadcastType.ShowStart,
    {
      className: "notificationShowStart",
    } as IBroadcastData,
  ],
  [
    BroadcastType.ShowStop,
    {
      className: "notificationShowStop",
    } as IBroadcastData,
  ],
  [
    BroadcastType.WheelOfFun,
    {
      className: "notificationWheel",
      icon: (fontSize: number) => (
        <TailwindIcon
          name={CustomIconName.wheelOfFunIcon}
          style={{ width: `${fontSize}px`, height: `${fontSize}px` }}
        />
      ),
    } as IBroadcastData,
  ],
  [
    BroadcastType.TopAdmirer,
    {
      className: "notificationAdmirer",
      icon: (fontSize: number) => (
        <TailwindIcon
          name={CustomIconName.medal}
          iconProps={{ iconrange: 1 }}
          style={{ fontSize: `${fontSize}px` }}
        />
      ),
    } as IBroadcastData,
  ],
]);

const omittedNotifications = ["notificationMessage.broadcastStarted"];

type Props = {
  notification: IChatNotification;
  chatStore?: ChatStore;
  isMemberTabNotification?: boolean;
  isMainChatTab?: boolean;
};

const ChatNotification: React.ComponentType<Props & WrappedComponentProps> = ({
  notification,
  chatStore,
  intl,
  isMemberTabNotification,
  isMainChatTab,
}) => {
  const { chatFontSize: fontSize, chatGiftImageSize } = chatStore!;

  const getText = (notification: IChatNotification): string => {
    const result = notification.fixedContentKey
      ? intl.formatMessage(
          {
            id: notification.fixedContentKey,
            defaultMessage: notification.content,
          },
          notification.fixedContentParams || {}
        )
      : notification.content;

    return result || "";
  };

  const getImage = (notification: IChatNotification, fontSize: number) => {
    if (notification.image && (isMemberTabNotification || isMainChatTab)) {
      return (
        <img
          src={notification.image}
          alt={notification.content}
          style={{
            width: chatGiftImageSize,
            height: chatGiftImageSize,
            margin: "5px 10px",
          }}
        />
      );
    } else if (
      notification.image &&
      !isMemberTabNotification &&
      !isMainChatTab
    ) {
      return (
        <img
          src={notification.image}
          alt={notification.content}
          style={{
            width: chatGiftImageSize,
            height: chatGiftImageSize,
            margin: "5px 10px",
          }}
        />
      );
    } else if (broadcastTypes.get(notification.broadcastType!)?.icon) {
      return broadcastTypes.get(notification.broadcastType!)?.icon(fontSize);
    } else {
      return null;
    }
  };

  return omittedNotifications.includes(notification.fixedContentKey!) ? null : (
    <div
      className={`ChatNotification ${
        broadcastTypes.get(notification.broadcastType!)?.className || ""
      } ${isMemberTabNotification || isMainChatTab ? "" : "background-muted"}`}
      data-testid={"chat-message-notification"}
      style={{ fontSize }}>
      [System]:&nbsp;
      {getImage(notification, fontSize)}&nbsp;
      <div
        style={{ fontSize }}
        dangerouslySetInnerHTML={{
          __html: getText(notification),
        }}
      />
    </div>
  );
};

export default injectIntl(inject("chatStore")(observer(ChatNotification)));
