    import React from "react";
    import TailwindFlex from "library/components/_tailwind/flex";
    import TailwindTranslatedText from "library/components/_tailwind/translated-text";
    import { injectIntl, WrappedComponentProps } from "react-intl";
    import { Icon } from "@material-ui/core";
    import TailwindBox from "library/components/_tailwind/box";

    type Props = {
        setOpenLoyalFansModal?: (status: boolean) => void;
        isMobileDevice?: boolean;
    };

    const LoyalFansModal: React.ComponentType<
    Props & WrappedComponentProps
    > = ({setOpenLoyalFansModal, isMobileDevice = false}) => {
    return (
        <TailwindFlex
            width={"w-full"}
            flexDirection={"flex-col"}
            backgroundColor={"bg-primary-bg-color"}
            position={isMobileDevice ? "static" : "absolute"}
            inset={["top-0", "left-0"]}
            zIndex={"z-100"}
            boxShadow={"shadow"}
            borderRadius="rounded"
            padding={isMobileDevice ? ["py-5", "px-3"] : ["px-2", "py-5"]}
        >
        <TailwindFlex flexWrap={"flex-wrap"} padding={["px-4"]}>
            {setOpenLoyalFansModal &&
                <TailwindFlex width={"w-full"} justifyContent="justify-end" position="absolute" inset={["top-1", "right-1"]}>
                    <TailwindBox cursor="cursor-pointer">
                        <Icon onClick={() => setOpenLoyalFansModal(false)}>close</Icon>
                    </TailwindBox>
                </TailwindFlex>
            }
            <TailwindFlex width={"w-full"}>
                <TailwindTranslatedText
                    fontSize="text-lg"
                    fontWeight="font-bold"
                    margin={["mb-3"]}
                    descriptor={{
                    id: "send.bulk.message.modal.title",
                    defaultMessage: "Send bulk message to your most loyal fans",
                    }}
                />
            </TailwindFlex>
            <TailwindFlex flexDirection={"flex-col"} margin={["mb-4"]}>
                <TailwindTranslatedText
                    fontSize="text-sm"
                    textColor='text-main-color'
                    descriptor={{
                    id: "send.bulk.message.modal.titleDescription",
                    defaultMessage: "Be sure to send them messages regularly to let them know about your schedule and fan club content.",
                    }}
                />
            </TailwindFlex>
            <TailwindTranslatedText
                textColor='text-main-color'
                fontSize="text-sm"
                descriptor={{
                    id: "send.bulk.message.modal.body",
                    defaultMessage:
                    `<strong>Note:</strong> To prevent spamming, you can <strong>only send a bulk message to the same member once every 24 hours.</strong> 
                    Once a bulk message has been sent to a Cams member (or if they are not a Cams member),
                    their name will be grayed out indicating you are not able to send bulk messages to that particular member.`,
                }}
            />
        </TailwindFlex>
        </TailwindFlex>
    );
    };

    export default injectIntl(LoyalFansModal);
