import React from "react";
import classnames from "classnames";

type Props = {
  className?: string;
};

const UnmuteIcon: React.ComponentType<Props> = ({ className }) => {
  return (
    <span className={classnames("UnmuteIcon", className && className)}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
        <path d='M49.92,5.59A44.41,44.41,0,1,0,94.33,50,44.46,44.46,0,0,0,49.92,5.59Zm0,83.82A39.41,39.41,0,1,1,89.33,50,39.46,39.46,0,0,1,49.92,89.41Z' />
        <path d='M42.91,42.81a4.57,4.57,0,1,0-4.56,4.56A4.56,4.56,0,0,0,42.91,42.81Z' />
        <path d='M61.49,38.25a4.56,4.56,0,1,0,4.56,4.56A4.56,4.56,0,0,0,61.49,38.25Z' />
        <path d='M55.88,56.26H44a8.66,8.66,0,0,0,0,17.32H55.88a8.66,8.66,0,1,0,0-17.32Zm0,12.32H44a3.66,3.66,0,0,1,0-7.32H55.88a3.66,3.66,0,0,1,0,7.32Z' />
      </svg>
    </span>
  );
};

export default UnmuteIcon;
