import React, { FunctionComponent } from "react";
import ResetUsernameForm from "containers/_components/reset-username-form";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindBox from "library/components/_tailwind/box";
import TailwindCard from "library/components/_tailwind/card";

type Props = {};

const ResetUsername: FunctionComponent<Props> = ({}) => {
  return (
    <TailwindCard backgroundColor={"bg-white"} padding={["p-8"]}>
      <TailwindFlex
        width={"w-full"}
        height={"h-full"}
        alignItems={"items-center"}
        justifyContent={"justify-center"}>
        <TailwindBox>
          <TailwindFlex width={"w-full"}>
            <TailwindTranslatedText
              headingProps={{
                level: 5,
              }}
              descriptor={{
                id: "landing.resetPasswordAuth.forgotYourUsername",
                defaultMessage: "Forgot Your Username?",
              }}
            />
          </TailwindFlex>
          <TailwindFlex width={"w-full"}>
            <TailwindTranslatedText
              descriptor={{
                id: "landing.resetPasswordAuth.enterYourEmail",
                defaultMessage:
                  "Enter your email below to learn your username.",
              }}
            />
          </TailwindFlex>
          <TailwindFlex width={"w-full"} margin={["mt-4"]}>
            <ResetUsernameForm />
          </TailwindFlex>
        </TailwindBox>
      </TailwindFlex>
    </TailwindCard>
  );
};

export default ResetUsername;
