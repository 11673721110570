import React from "react";
import "./styles.scss";
import classnames from "classnames";

type FireIconProps = {
  className?: string;
};

const FireIcon: React.ComponentType<FireIconProps> = ({ className }) => (
  <span className={classnames("FireIcon", className && className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 302 302"
      xmlSpace="preserve"
      y="0px"
      x="0px"
    >
      <g transform="translate(1, 1)">
        <path
          d="M240.2,145.2c-11.2-25.7-32.8-45-46.9-59.1c-6.9-6.9-8.4,0-6.7,3.5c1.7,3.5,6.7,34.7-24.6,27.8s5.5-74.7,5.5-78.2  s-1.7-5.2-5.2-5.2s-45.2,17.4-79.9,66s-35,104.2-15.9,132s53.9,40,83.4,40s57.3-8.7,81.6-36.5S252.3,173,240.2,145.2z M180.9,232.2  c-4.7,12.6-13,16.3-22.2,18.6c-9.3,2.2-21.3-1.3-29.8-10.4c-8.5-9.1-12.1-28.8-5.7-50c6.4-21.1,17.9-30.9,19-31.2  c1.1-0.3,1.8,0.3,2.1,1.6s-0.2,21.2,7.3,28.6c12.1,12.1,13,6,23.5,15.9C183.5,213.3,184.3,223.2,180.9,232.2z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  </span>
);

export default FireIcon;
