import React, { Fragment, useEffect } from "react";
import "./styles.scss";
import BroadcastStore from "../../_stores/broadcast/BroadcastStore";
// import moment from 'moment';
import { injectIntl, WrappedComponentProps } from "react-intl";
import MemberGalleryTooltip from "../member-gallery-tooltip";
import { Icon } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import Avatar from "library/components/avatar";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import CustomIcon from "library/components/_tailwind/icon/icons";
import TailwindTooltip from "library/components/_tailwind/tooltip";

// ALL COMMENTED CODE IS COMMENTED UNTIL SWITCH

// interface IComment {
//   content: string;
//   date: Date;
// }

interface IMemberBoxProps {
  broadcastStore?: BroadcastStore;
  memberId: string;
  onClose: (event: React.MouseEvent) => void;
}

const MemberBox = ({
  onClose,
  broadcastStore,
  memberId,
  intl,
}: IMemberBoxProps & WrappedComponentProps) => {
  // const [comments] = useState<IComment[]>([]);
  // const [commentInput, setCommentInput] = useState<string>('');
  const memberImages = broadcastStore?.selectedMember?.pictures || [];
  useEffect(() => {
    broadcastStore!.getMember(memberId);
  }, [broadcastStore, memberId]);

  // const handleChangeCommentInput = (e: any) => {
  //   setCommentInput(e.target.value);
  // };

  // const handleAddComment = useCallback(
  //   (comment: IComment) => {
  //     setComments([...comments, comment]);
  //   },
  //   [comments]
  // );

  // const handleSubmitComment = useCallback(
  //   (e: any) => {
  //     e.preventDefault();
  //     handleAddComment({
  //       content: commentInput,
  //       date: new Date(),
  //     });
  //     setCommentInput('');
  //   },
  //   [commentInput, handleAddComment]
  // );

  return (
    <div className={"BroadcastMemberBox"}>
      <div className={"BroadcastMemberBox__info_container"}>
        <div className={"BroadcastMemberBox__info_image"}>
          <Avatar
            className={"position-static"}
            size={"larger"}
            borderColor={"purple"}
            photoURL={broadcastStore!.selectedMember?.profile_picture}
          />
        </div>
        <div className={"BroadcastMemberBox__info_text"}>
          <div>
            <CustomIcon
              className={`BroadcastMemberBox__info_rank BroadcastMemberBox__info_rank--${
                broadcastStore!.selectedMember?.score
              }`}
              name={CustomIconName.medal}
              props={{ iconrange: broadcastStore!.selectedMember?.score }}
            />
            <p className={"BroadcastMemberBox__info_name"}>
              {broadcastStore!.selectedMember?.username} |{" "}
              {broadcastStore!.selectedMember?.age}{" "}
              {broadcastStore!.selectedMember?.gender}
            </p>
          </div>
          <p className={"BroadcastMemberBox__info_balance"}>
            {/* TODO tkns */}
            {!!memberImages.length && (
              <Fragment>
                {` | `}
                <TailwindTooltip
                  content={
                    (
                      <MemberGalleryTooltip
                        images={memberImages}
                        desc={broadcastStore!.selectedMember?.about || ""}
                      />
                    ) as any
                  }>
                  <span>
                    {`${memberImages.length} `}
                    {memberImages.length > 1
                      ? intl.formatMessage({
                          id: "admirers.admirerMemberDetails.photos",
                          defaultMessage: "Photos",
                        })
                      : intl.formatMessage({
                          id: "admirers.admirerMemberDetails.photo",
                          defaultMessage: "Photo",
                        })}
                  </span>
                </TailwindTooltip>
              </Fragment>
            )}
          </p>
          <p className={"BroadcastMemberBox__info_intro"}>
            {broadcastStore!.selectedMember?.aboutShort}
          </p>
        </div>
        <div onClick={onClose} className={"BroadcastMemberBox__info_close"}>
          <Icon>close</Icon>
        </div>
      </div>
      {/* <div className={'BroadcastMemberBox__comments'}>
        {comments.map((comment) => (
          <div className={'BroadcastMemberBox__comment'}>
            <div className={'BroadcastMemberBox__comment-content'}>{comment.content}</div>
            <div className={'BroadcastMemberBox__comment-date'}>{moment(comment.date).format('YYYY/MM/DD')}</div>
          </div>
        ))}
      </div> */}

      {/* <form className={'BroadcastMemberBox__input-container'} onSubmit={handleSubmitComment}>
        <input
          placeholder={intl.formatMessage({
            id: 'broadcast.memberBox.typeYourNotes',
            defaultMessage: 'Type Your Notes',
          })}
          value={commentInput}
          onChange={handleChangeCommentInput}
          className={'BroadcastMemberBox__input'}
        />
      </form> */}
    </div>
  );
};

export default injectIntl(inject("broadcastStore")(observer(MemberBox)));
