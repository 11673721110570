import React from "react";
import { MessageCenterConversationDetails } from "./stores/messages/types";
import MessageCenterModelNameBar from "./messages-model-name-bar";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import TailwindTooltip from "library/components/_tailwind/tooltip";
import LanguageStore from "library/core/stores/language/LanguageStore";
import { inject, observer } from "mobx-react";

type Props = {
  conversation: MessageCenterConversationDetails;
  modelProfileImage: string;
  onSharedMediaButtonClicked: () => void;
  languageStore?: LanguageStore;
};

const MessageCenterReaderModelBar: React.ComponentType<Props> = ({
  conversation,
  modelProfileImage,
  onSharedMediaButtonClicked,
  languageStore,
}) => {
  const { intl } = languageStore!;
  return (
    <MessageCenterModelNameBar
      conversation={conversation}
      modelProfileImage={modelProfileImage}
      buttonChildren={
        <TailwindFlex width='w-auto'>
          <TailwindTooltip
            content={intl.formatMessage({
              id: "message-center.shared-conversation",
              defaultMessage: "Shared Media",
            })}>
            <TailwindButton
              onClick={onSharedMediaButtonClicked}
              fullWidth={false}
              padding={["p-0"]}
              margin={["ml-3"]}
              justifyContent={"justify-center"}
              alignItems={"items-center"}
              backgroundColor={"bg-tertiary-bg-color"}
              height={"h-10"}
              width={"w-10"}
              borderRadius={"rounded-full"}
              rightIconProps={{
                name: "shared-media-icon",
                fontSize: "text-lg",
                textColor: "text-secondary-color",
                borderRadius: "rounded-full",
                style: {
                  position: "relative",
                },
              }}
            />
          </TailwindTooltip>
        </TailwindFlex>
      }
    />
  );
};

export default inject("languageStore")(observer(MessageCenterReaderModelBar));
