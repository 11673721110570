import React from "react";
import "./styles.scss";
import clsx from "clsx";

type PrivateMessageIconProps = {
  className?: string;
};

const PrivateMessageIcon: React.ComponentType<PrivateMessageIconProps> = ({
  className,
}) => (
  <span className={clsx("PrivateMessageIcon", className && className)}>
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 362 362"
      xmlSpace="preserve"
      y="0px"
      x="0px"
      id="Layer_1_1568879681421"
    >
      <g transform="translate(1, 1)">
        <path
          d="M322.3,276.2c-36.3,52.6-80.9,78.8-133.9,78.8h-9.8h-10.5c-53,0-97.7-26.3-133.9-78.8c-2.3-3.7-2.3-7.2,0-10.5  c1.4-3.2,3.7-4.9,7-4.9l4.2-1.4c2.3-0.9,7-3.9,14-9.1c7-5.1,14.9-11.9,23.7-20.2c25.6-25.1,47.4-37.7,65.6-37.7h1.4  c10.2,0,19.1,3.5,26.5,10.5c9.8-7,18.8-10.5,27.2-10.5h1.4c17.2,0,39.1,12.6,65.6,37.7c19.5,19.5,33.5,29.8,41.9,30.7  c3.2,0,5.6,1.6,7,4.9C321.4,267.6,322.3,271.1,322.3,276.2z M62.8,140.2c-7.4-9.3-11.6-19.5-12.6-30.7V54.3  c0-14.4,4.9-26.5,14.6-36.3c9.8-9.8,21.9-14.6,36.3-14.6h133.9c14,0,25.8,4.9,35.6,14.6c9.8,9.8,14.6,21.9,14.6,36.3v53.7  c0,14-4.9,25.8-14.6,35.6c-9.8,9.8-21.6,14.6-35.6,14.6H150l-60.7,50.9v-52.3C79.1,155,70.3,149.5,62.8,140.2z M291.6,284.5H62.1  c29.3,34.9,63.9,52.3,103.9,52.3h9.1h3.5h8.4C225.6,336.9,260.5,319.4,291.6,284.5z M77.8,29.9c-6.3,6.5-9.4,14.6-9.4,24.4v55.1  c0,9.3,3.2,17.3,9.8,24.1c6.5,6.7,14.2,10.1,23,10.1h4.2V175l38.4-31.4h91.4c9.3,0,17.3-3.4,24.1-10.1c6.7-6.7,10.1-14.8,10.1-24.1  V54.3c0-9.8-3.4-17.9-10.1-24.4c-6.7-6.5-14.8-9.8-24.1-9.8H101.2C91.9,20.2,84.1,23.4,77.8,29.9z M68.4,265.7h216.9  c-1.9-1.4-10.7-8.8-26.5-22.3c-21.9-21.9-39.8-32.8-53.7-32.8c-6.5,0-12.6,1.9-18.1,5.6c-7,5.1-14,5.1-20.9,0  c-5.6-3.7-10.9-5.6-16-5.6h-1.4c-12.6,0-30.5,10.9-53.7,32.8C86.6,251.8,77.7,259.2,68.4,265.7z M135.4,87.1V71.8H97.7v15.3h39.1  H135.4z M187,71.8h-37v15.3h37V71.8z M239.3,71.8h-37v15.3h37V71.8z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  </span>
);

export default PrivateMessageIcon;
