import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";
import Code from "../../components/_tailwind/code";
import TailwindTranslatedText from "../../components/_tailwind/translated-text";

type Props = {};

const StorybookComponentsTranslatedText: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; TranslatedText</StorybookHeading>
      <StorybookParagraph>
        TranslatedText component is a combination of primitive Text component as
        well as the react-intl. It also encompasses TailwindHeading and
        TailwindParagraph if we want to use in such a way.
      </StorybookParagraph>
      <StorybookParagraph>
        Currently it takes all the props from TailwindText as well as the
        following for translation:
      </StorybookParagraph>
      <Code>
        {`descriptor: {
  id?: string | number;
  description?: string | object;
  defaultMessage?: string;
};
values?: Record<string, PrimitiveType>;
headingProps?: TailwindHeadingProps;
paragraphProps?: TailwindParagraphProps;       
`}
      </Code>
      <StorybookParagraph>
        Here are some examples with the TailwindText component. As it is very
        flexible, you can create variants easily so not all combinations are
        showcased here.
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={` <TailwindTranslatedText
  textTransform={"uppercase"}
  descriptor={{
    id: "common.yes",
    defaultMessage: "Yes",
  }}
/>
`}
      >
        <TailwindTranslatedText
          textTransform={"uppercase"}
          descriptor={{
            id: "common.yes",
            defaultMessage: "Yes",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindTranslatedText
  textColor={"text-red-500"}
  descriptor={{
    id: "common.yes",
    defaultMessage: "Yes",
  }}
/>
`}
      >
        <TailwindTranslatedText
          textColor={"text-red-500"}
          descriptor={{
            id: "common.yes",
            defaultMessage: "Yes",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindTranslatedText
  textColor={"text-red-500"}
  descriptor={{
    id: "dashboard.myEarnings.totalIncomeOfMonth",
    defaultMessage: "In {month}",
  }}
  values={{
    month: "some value that gets interpolated"
  }}
/>
`}
      >
        <TailwindTranslatedText
          textColor={"text-red-500"}
          descriptor={{
            id: "dashboard.myEarnings.totalIncomeOfMonth",
            defaultMessage: "In {month}",
          }}
          values={{
            month: "some value that gets interpolated",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindTranslatedText
  textColor={"text-red-500"}
  headingProps={{
    level: 5
  }}
  descriptor={{
    id: "common.yes",
    defaultMessage: "Yes",
  }}
/>
`}
      >
        <TailwindTranslatedText
          textColor={"text-red-500"}
          headingProps={{
            level: 5,
          }}
          descriptor={{
            id: "common.yes",
            defaultMessage: "Yes",
          }}
        />
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsTranslatedText;
