import React, { useEffect, useMemo, useRef, useState } from "react";
import TailwindBox, { TailwindBoxProps } from "../box";
import {
  TBackgroundColor,
  TBorderColor,
  TBorderRadius,
  TTextColor,
} from "../../../styles/tailwind-classnames";
import { TailwindCardBackgroundColor } from "../card";
import { inject, observer } from "mobx-react";
import useOutsideClick from "../../../core/utility/hooks/useOutsideClick";
import ThemeStore from "../../../core/stores/theme/ThemeStore";
import { TailwindDropdownBackgroundColor } from "../dropdown";
import { MODAL_WRAPPER_CLASSNAME } from "../../../core/stores/modal/consts";

export type TailwindMenuProps = Omit<TailwindBoxProps, "backgroundColor"> & {
  backgroundColor?: TailwindDropdownBackgroundColor;
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
  shape?: "rounded" | "square";
  buttonRef: React.RefObject<HTMLElement>;
  themeStore?: ThemeStore;
  fullWidth?: boolean;
  isShowingMenuOnTop: boolean;
  direction?: "top" | "bottom";
  setIsShowingMenuOnTop: (isOnTop: boolean) => void;
};

const TailwindMenu: React.ComponentType<TailwindMenuProps> = ({
  isCollapsed,
  backgroundColor,
  setIsCollapsed,
  shape,
  buttonRef,
  themeStore,
  fullWidth = true,
  isShowingMenuOnTop,
  setIsShowingMenuOnTop,
  maxHeight,
  width,
  borderColor,
  borderWidth,
  direction,
  children,
}) => {
  /*
   ###### Component Flows
   Component needs to work in following cases:
   - If inside a modal and modal is small, it should decide the position and the height of the menu
   - if inside a non-modal container and container is small, it should decide the position and the height of the menu
   ###: Note that on both cases, there can be a scroll in the modal/container. the visible viewport should be taken into account
   so that user does not need to scroll down to see the rest of the options. the height/position of the menu should adjust accordingly
   ###: Another note is that if height of the menu is small (like three items) and there is more available space than needed, height of the menu should be the auto-height so that the menu does not occupy space more than needed
   */

  const { currentThemePalette } = themeStore!;

  const menuRef = useRef<HTMLDivElement>(null);
  const [menuHeight, setMenuHeight] = useState<number | "auto">("auto");
  const [menuBorderRadius, setMenuBorderRadius] = useState<TBorderRadius[]>(
    shape === "rounded"
      ? ["rounded-bl-3xl", "rounded-br-3xl"]
      : ["rounded-bl", "rounded-br"]
  );
  const [menuTop, setMenuTop] = useState<number | null>(null);
  const [menuBottom, setMenuBottom] = useState<number | null>(null);

  const menuEl = menuRef.current;
  const buttonEl = buttonRef.current;
  const naturalMenuHeight = (React.Children.count(children) - 1) * 40 + 22;
  const maxMenuHeight = 384;

  const getModalBottom = () => {
    const isAnyModalOpen: boolean =
      document.querySelector(`.${MODAL_WRAPPER_CLASSNAME}`) !== null;
    const buttonEl = buttonRef?.current;
    let modalBottom: number | null = null;
    if (isAnyModalOpen) {
      const openModals = document.querySelectorAll(
        `.${MODAL_WRAPPER_CLASSNAME}`
      );

      openModals.forEach(modal => {
        if (modal.contains(buttonEl)) {
          const { bottom: _modalBottom } = modal.getBoundingClientRect();
          modalBottom = _modalBottom;
        }
      });
    }

    return modalBottom;
  };

  const getModalTop = () => {
    const isAnyModalOpen: boolean =
      document.querySelector(`.${MODAL_WRAPPER_CLASSNAME}`) !== null;
    const buttonEl = buttonRef?.current;
    let modalTop: number | null = null;
    if (isAnyModalOpen) {
      const openModals = document.querySelectorAll(
        `.${MODAL_WRAPPER_CLASSNAME}`
      );

      openModals.forEach(modal => {
        if (modal.contains(buttonEl)) {
          const { top: _modalTop } = modal.getBoundingClientRect();
          modalTop = _modalTop;
        }
      });
    }

    return modalTop;
  };

  const isInsideModal = getModalTop() || getModalBottom();

  const getMenuHeight = (_isShowingMenuOnTop: boolean) => {
    let menuHeightWhenOnTop = 0;
    let menuHeightWhenOnBottom = 0;
    if (menuEl && buttonEl) {
      const {
        top: buttonTop,
        bottom: buttonBottom,
        //height: buttonHeight,
      } = buttonEl.getBoundingClientRect();
      if (isInsideModal) {
        const modalTop = getModalTop()!;
        const modalBottom = getModalBottom()!;
        menuHeightWhenOnTop = Math.abs(modalTop - buttonTop);
        menuHeightWhenOnBottom = Math.abs(modalBottom - buttonBottom);
      } else {
        const windowInnerHeight = window.innerHeight;
        menuHeightWhenOnTop = Math.abs(buttonTop);
        menuHeightWhenOnBottom = Math.abs(windowInnerHeight - buttonBottom);
      }
    }

    const availableSpace =
      menuHeightWhenOnTop > menuHeightWhenOnBottom
        ? menuHeightWhenOnTop
        : menuHeightWhenOnBottom;

    return {
      height:
        naturalMenuHeight > availableSpace
          ? availableSpace
          : naturalMenuHeight > maxMenuHeight
          ? maxMenuHeight
          : naturalMenuHeight,
      heightWhenOnTop: menuHeightWhenOnTop,
      heightWhenOnBottom: menuHeightWhenOnBottom,
    };
  };

  const _setIsCollapsed = (_isCollapsed: boolean) => {
    setIsCollapsed(_isCollapsed);
  };

  useOutsideClick(
    menuRef,
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      _setIsCollapsed(true);
    },
    buttonRef
  );

  // do not add any react hooks
  const getMenuPositionProps = (_isShowingMenuOnTop: boolean) => {
    let borderRadius: TBorderRadius[] = menuBorderRadius;
    let top: number | null = null;
    let bottom: number | null = null;

    if (buttonEl) {
      const { height: buttonHeight } = buttonEl.getBoundingClientRect();
      if (_isShowingMenuOnTop) {
        borderRadius =
          shape === "rounded"
            ? ["rounded-tl-3xl", "rounded-tr-3xl"]
            : ["rounded-tl", "rounded-tr"];
        bottom = buttonHeight;
      } else {
        top = buttonHeight;
      }
    }

    return {
      borderRadius,
      top,
      bottom,
    };
  };

  const themeColors = useMemo(() => {
    const colors: {
      backgroundColor: TBackgroundColor;
      focusButtonBorderColor: TBorderColor;
      multipleCounterBackgroundColor: TBackgroundColor;
      multipleCounterTextColor: TTextColor;
    } = {
      backgroundColor: "bg-white",
      focusButtonBorderColor: "border-black",
      multipleCounterBackgroundColor: "bg-black",
      multipleCounterTextColor: "text-white",
    };

    switch (backgroundColor as TailwindCardBackgroundColor) {
      case "primary": {
        const backgroundClass =
          currentThemePalette?.["dropdown-primary-bg-color-tw-class"];
        if (backgroundClass) {
          colors.backgroundColor = backgroundClass;
        }
        break;
      }
      case "secondary": {
        const backgroundClass =
          currentThemePalette?.["dropdown-secondary-bg-color-tw-class"];
        if (backgroundClass) {
          colors.backgroundColor = backgroundClass;
        }
        break;
      }
      case "transparent": {
        colors.backgroundColor = "bg-transparent";
        break;
      }
      default: {
        colors.backgroundColor = backgroundColor as TBackgroundColor;
        break;
      }
    }

    return colors;
  }, [backgroundColor]);

  useEffect(() => {
    const { height, heightWhenOnTop, heightWhenOnBottom } =
      getMenuHeight(isShowingMenuOnTop);
    setMenuHeight(height);
    let hasMoreSpaceAvailableAtTop = heightWhenOnTop > heightWhenOnBottom;
    if (direction === "top") {
      setIsShowingMenuOnTop(true);
      hasMoreSpaceAvailableAtTop = true;
    } else if (direction === "bottom") {
      setIsShowingMenuOnTop(false);
      hasMoreSpaceAvailableAtTop = false;
    } else {
      setIsShowingMenuOnTop(hasMoreSpaceAvailableAtTop);
    }

    const { borderRadius, top, bottom } = getMenuPositionProps(
      hasMoreSpaceAvailableAtTop
    );
    setMenuBorderRadius(borderRadius);
    setMenuTop(top);
    setMenuBottom(bottom);
  }, [isCollapsed, direction]);

  return (
    <TailwindBox
      ref={menuRef}
      width={fullWidth ? "w-full" : width || "w-52"}
      display={isCollapsed ? "hidden" : "flex"}
      visibility={isCollapsed ? "invisible" : "visible"}
      padding={["p-3"]}
      borderRadius={menuBorderRadius}
      backgroundColor={themeColors.backgroundColor}
      borderColor={borderColor}
      borderWidth={borderWidth}
      position={"absolute"}
      flexDirection={"flex-col"}
      maxHeight={maxHeight || "max-h-96"}
      overflow={"overflow-hidden"}
      zIndex={"z-900"}
      style={{
        top: menuTop === null ? undefined : menuTop,
        bottom: menuBottom === null ? undefined : menuBottom,
        height: menuHeight,
      }}>
      <TailwindBox
        overflow={["overflow-y-auto"]}
        height={"h-full"}
        className={["pretty-scroll"]}>
        {children}
      </TailwindBox>
    </TailwindBox>
  );
};

export default inject("themeStore")(observer(TailwindMenu));
