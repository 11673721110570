import { RegistrationStep } from "./enums";
import { ILoginRegisterForm, ILoginRegisterFormErrors } from "./interfaces";
import { LoginFormData } from "core/stores/auth/types";

export const LOGIN_REGISTER_FORM_DEFAULTS: ILoginRegisterForm = {
  email: null,
  password: null,
  user_type: null,
  username: null,
  gender: null,
  promo_code: null,
  agreement: false,
  first_name: null,
  last_name: null,
  phone_number: null,
  mailing_address: null,
  address: null,
  city: null,
  state: null,
  zip_code: null,
  country: null,
  emailOrUsername: null,
  issue_country: null,
  document_type: null,
  expire_date: null,
};

export const LOGIN_REGISTER_FORM_ERRORS_DEFAULTS: ILoginRegisterFormErrors = {
  email: null,
  password: null,
  user_type: null,
  username: null,
  gender: null,
  promo_code: null,
  agreement: null,
  first_name: null,
  last_name: null,
  phone_number: null,
  birthdate: null,
  address: null,
  city: null,
  state: null,
  zip_code: null,
  country: null,
  emailOrUsername: null,
  issue_country: null,
  document_type: null,
  expire_date: null,
  document_front_key: null,
  document_back_key: null,
  document_verification_key: null,
  signature: null,
};

export const REGISTER_FORM_DATA_DEFAULTS = {
  email: "",
  password: "",
  model: {
    first_name: "",
    last_name: "",
    other_name: "",
    birthdate: "",
    username: "",
    gender: "",
    promo_code: "",
  },
  studio: {
    username: "",
    first_name: "",
    other_name: "",
    last_name: "",
    phone_number: "",
    phone_number_country_code: "US",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
  },
  model_document: {
    issue_country: "",
    document_type: "",
    document_front_key: "",
    document_back_key: "",
    document_verification_key: "",
    expire_date: new Date().toISOString(),
  },
  studio_document: {
    issue_country: "",
    document_type: "studio",
    document_front_key: "",
    expire_date: new Date().toISOString(),
  },
  signed_agreement: {
    signature: "",
  },
  site: "cams",
};

export const LOGIN_FORM_DATA_DEFAULTS: LoginFormData = {
  emailOrUsername: "",
  password: "",
  site: "cams",
};

export const AUTH_ERROR_MESSAGES = {
  email_taken: "E-mail is already taken",
  email_empty: "E-mail can not be left blank",
  email_invalid: "E-mail must be valid",
  username_taken: "Username is already taken",
  username_invalid:
    "Username can only contain alphanumeric characters and underscores",
  username_empty: "Username can not be left blank",
  password_empty: "Password can not be left blank",
  password_invalid:
    "Password should contain 8 characters with at least one number, one uppercase and one lowercase letter",
  gender_empty: "Gender can not be left blank",
  gender_invalid: "Gender must be valid",
  email_or_password_invalid: "E-mail or password is invalid",
};

export const AGREEMENT_TYPE_TO_NAME = {
  broadcasting_agreement: "Broadcast Agreement",
  broadcast_release: "Broadcast Release",
  performers_agreement: "Performers Agreement",
};
export const LOGOUT_LOCALSTORAGE_KEY = "LOGOUT_LOCALSTORAGE_KEY";

export const AuthStoreInitialData = {
  isReady: false,
  loginRegisterErrors: LOGIN_REGISTER_FORM_ERRORS_DEFAULTS,
  loginRegisterForm: LOGIN_REGISTER_FORM_DEFAULTS,
  registerActiveStep: RegistrationStep.AUTH_CREDENTIALS,
  isActionInProgress: false,
  loginInProgress: false,
  logoutInProgress: false,
  isLoggedIn: false,
  rememberMe: false,
  userRole: null,
  isRefreshingToken: false,
  isRetryingRefreshToken: false,
  loadingAgreements: false,
  agreements: [],
  formRegistrationData: REGISTER_FORM_DATA_DEFAULTS,
  loginFormData: LOGIN_FORM_DATA_DEFAULTS,
  failedRequestQueue: [],
  accountSuspensionReason: null,
  countries: {},
  refreshTokenRetryExponent: -1,
};
