import React, { useCallback, useEffect } from "react";
import TailwindBox from "library/components/_tailwind/box";
import TailwindText from "library/components/_tailwind/text";
import "./styles.scss";
import { history } from "library/core/utility";
import { COMMON_ROUTES } from "core/stores/route/common";
import { FALLBACK_PRIVACY_POLICY } from "core/constants";

type Props = {
  jumpToSectionId?: string;
};
const PrivacyPolicy: React.FC<Props> = ({ jumpToSectionId }) => {
  let scrollInterval;
  const jumpToSection = useCallback(() => {
    if (jumpToSectionId) {
      const element = document.getElementById(jumpToSectionId);
      const container = document.getElementById("PrivacyPolicy");
      const yOffset = -100;
      if (element && container) {
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        container.scrollTo({ top: y, behavior: "smooth" });
        clearInterval(scrollInterval);
      } else {
        scrollInterval = setInterval(() => jumpToSection, 500);
      }
    }
  }, [jumpToSectionId]);

  useEffect(() => {
    jumpToSection();
  }, [jumpToSection]);

  useEffect(() => {
    (window as any).onClickTermsOfUse = () => {
      history.push(`/${COMMON_ROUTES.paymentRenewalTermsOfUse.link}`);
    };
  }, []);

  return (
    <div id={"PrivacyPolicy"} className={"PrivacyPolicy"}>
      <TailwindText
        display={"block"}
        fontSize={"text-3xl"}
        fontWeight={"font-bold"}
        padding={["py-2"]}
        margin={["mb-4"]}>
        FriendFinder Networks Inc. Privacy Policy
      </TailwindText>
      <TailwindBox
        dangerouslySetInnerHTML={{
          __html: FALLBACK_PRIVACY_POLICY,
        }}></TailwindBox>
    </div>
  );
};

export default PrivacyPolicy;
