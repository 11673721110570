import React, { useEffect, useMemo, useState } from "react";
import { inject, observer } from "mobx-react";
import TailwindFlex from "library/components/_tailwind/flex";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import TailwindButton from "library/components/_tailwind/button";
import LeaderBoardStore from "./store/LeaderBoardStore";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindBox from "library/components/_tailwind/box";
import TailwindMasonry from "library/components/_tailwind/masonry";
import ModelMedia from "./model-media";
import { ContestSubType, LeaderBoardContests } from "./store/enums";
import { LeaderBoardModel } from "./store/types";
import Showcase from "library/components/_tailwind/showcase";
import { AppCamsModelRouteKey } from "core/stores/route/enums";
import ShowcaseStore from "core/stores/showcase/ShowcaseStore";
import ProfileStore from "../my-page/stores/profile/ProfileStore";
import { UserAccountStatus } from "../my-page/stores/profile/enums";
import { SHOWCASE_TARGET_CLASSNAMES } from "core/stores/showcase/const";
import TailwindSpinner from "library/components/_tailwind/spinner";
import TailwindText from "library/components/_tailwind/text";
import TailwindImage from "library/components/_tailwind/image";
import TailwindGrid from "library/components/_tailwind/grid";

type Props = {
  leaderBoardStore?: LeaderBoardStore;
  layoutStore?: LayoutStore;
  showcaseStore?: ShowcaseStore;
  profileStore?: ProfileStore;
};

const LeaderBoardContainer: React.ComponentType<Props> = ({
  layoutStore,
  leaderBoardStore,
  showcaseStore,
  profileStore,
}) => {
  const [contestSelected, setContestSelected] = useState<LeaderBoardContests>(
    LeaderBoardContests.day
  );
  const [contestSubType, setContestSubType] = useState<ContestSubType>(
    ContestSubType.winners
  );
  const { getLoggedInModelRank, initContestData, contestData, isLoadingData } =
    leaderBoardStore!;
  const { isMobileScreenSize } = layoutStore!;
  const {
    getWelcomeShowcaseSteps,
    nextWelcomeShowcasePage,
    playingShowcase,
    disableShowcase,
  } = showcaseStore!;
  const { isCurrentAccountStatus } = profileStore!;

  useEffect(() => {
    initContestData();
  }, []);

  const contestantData = useMemo(() => {
    const maxDayResultsToShow = 90;
    const maxMonthResultsToShow = 36;
    if (
      contestSelected === LeaderBoardContests.day &&
      contestSubType === ContestSubType.winners
    ) {
      return contestData[contestSelected][contestSubType].slice(
        0,
        maxDayResultsToShow
      );
    } else if (
      contestSelected === LeaderBoardContests.month &&
      contestSubType === ContestSubType.winners
    ) {
      return contestData[contestSelected][contestSubType].slice(
        0,
        maxMonthResultsToShow
      );
    } else {
      return contestData[contestSelected][contestSubType];
    }
  }, [
    contestData.day.winners,
    contestData.month.winners,
    contestData.year.winners,
    contestData.day.contest,
    contestData.month.contest,
    contestData.year.contest,
    contestSelected,
    contestSubType,
  ]);

  const loggedInModelRank = useMemo(() => {
    return getLoggedInModelRank();
  }, []);

  const enableShowcaseDoneButton = useMemo(() => {
    // enable done button if account is not approved since broadcast and payout require approval
    return !isCurrentAccountStatus(UserAccountStatus.APPROVED);
  }, []);

  const yearWinners = [
    { year: 2019, img: "/images/models-of-the-year/2019.jpg" },
    { year: 2020, img: "/images/models-of-the-year/2020.jpg" },
    { year: 2021, img: "/images/models-of-the-year/2021.jpg" },
  ];

  return (
    <>
      {playingShowcase && (
        <Showcase
          steps={getWelcomeShowcaseSteps(AppCamsModelRouteKey.leaderboards)}
          onFinish={nextWelcomeShowcasePage}
          disableOverlay={true}
          showSkipButton={true}
          onSkipped={() => {
            disableShowcase();
          }}
          enableDoneButton={enableShowcaseDoneButton}
        />
      )}
      <TailwindFlex
        flexDirection={"flex-col"}
        width={"w-full"}
        className={["LeaderBoard"]}>
        <TailwindFlex
          flexDirection={"flex-col"}
          justifyContent={"justify-start"}
          margin={["mb-4"]}>
          <TailwindTranslatedText
            headingProps={{
              className: "MyFans__title",
              level: 5,
              margin: ["mb-3"],
            }}
            descriptor={{
              id: "contests.topModels",
              defaultMessage: "Top Models",
            }}
          />
          <TailwindTranslatedText
            paragraphProps={{ textColor: "text-main-color" }}
            descriptor={{
              id: "contests.topModelsDesc",
              defaultMessage:
                "Become the model of the day, based on tokens earned in any show type and get a $100 bonus (Limit once per month). Become the model of the month and get a $1000 bonus!",
            }}
          />
        </TailwindFlex>
        <TailwindFlex flexDirection={"flex-col"}>
          <TailwindFlex flexDirection={"flex-col"} width={"w-full"}>
            <TailwindFlex>
              <TailwindButton
                className={[SHOWCASE_TARGET_CLASSNAMES.leaderboardModelOfDay]}
                width={"w-60"}
                justifyContent={"justify-center"}
                pseudoClasses={["md:w-1/3"]}
                height={"h-14"}
                rounded={false}
                backgroundColor={
                  contestSelected === LeaderBoardContests.day
                    ? "bg-primary-bg-color"
                    : "bg-secondary-bg-color"
                }
                fullWidth={false}
                onClick={() => {
                  setContestSelected(LeaderBoardContests.day);
                }}>
                <TailwindTranslatedText
                  fontWeight={"font-semibold"}
                  descriptor={
                    isMobileScreenSize
                      ? {
                          id: "contests.day",
                          defaultMessage: "Day",
                        }
                      : {
                          id: "contests.modelOfDay",
                          defaultMessage: "Model of the Day",
                        }
                  }
                />
              </TailwindButton>
              <TailwindButton
                width={"w-60"}
                justifyContent={"justify-center"}
                pseudoClasses={["md:w-1/3"]}
                margin={["mx-4"]}
                height={"h-14"}
                rounded={false}
                backgroundColor={
                  contestSelected === LeaderBoardContests.month
                    ? "bg-primary-bg-color"
                    : "bg-secondary-bg-color"
                }
                fullWidth={false}
                onClick={() => {
                  setContestSelected(LeaderBoardContests.month);
                }}>
                <TailwindTranslatedText
                  fontWeight={"font-semibold"}
                  descriptor={
                    isMobileScreenSize
                      ? {
                          id: "contests.month",
                          defaultMessage: "Month",
                        }
                      : {
                          id: "contests.modelOfMonth",
                          defaultMessage: "Model of the Month",
                        }
                  }
                />
              </TailwindButton>
              <TailwindButton
                width={"w-60"}
                justifyContent={"justify-center"}
                pseudoClasses={["md:w-1/3"]}
                height={"h-14"}
                rounded={false}
                backgroundColor={
                  contestSelected === LeaderBoardContests.year
                    ? "bg-primary-bg-color"
                    : "bg-secondary-bg-color"
                }
                fullWidth={false}
                onClick={() => {
                  setContestSelected(LeaderBoardContests.year);
                  setContestSubType(ContestSubType.winners);
                }}>
                <TailwindTranslatedText
                  fontWeight={"font-semibold"}
                  descriptor={
                    isMobileScreenSize
                      ? {
                          id: "contests.year",
                          defaultMessage: "Year",
                        }
                      : {
                          id: "contests.modelOfYear",
                          defaultMessage: "Model of the Year",
                        }
                  }
                />
              </TailwindButton>
            </TailwindFlex>
            {
              <TailwindFlex margin={["mt-4"]}>
                <TailwindButton
                  width={"w-1/2"}
                  height={"h-10"}
                  justifyContent={"justify-center"}
                  margin={["mr-2"]}
                  backgroundColor={
                    contestSubType === ContestSubType.contest
                      ? "bg-primary-bg-color"
                      : "bg-secondary-bg-color"
                  }
                  rounded={false}
                  fullWidth={false}
                  onClick={() => {
                    setContestSubType(ContestSubType.contest);
                  }}>
                  <TailwindTranslatedText
                    fontWeight={"font-semibold"}
                    descriptor={{
                      id: "contests.currentContest",
                      defaultMessage: "Current Contest",
                    }}
                  />
                </TailwindButton>
                <TailwindButton
                  width={"w-1/2"}
                  height={"h-10"}
                  justifyContent={"justify-center"}
                  margin={["ml-2"]}
                  backgroundColor={
                    contestSubType === ContestSubType.winners
                      ? "bg-primary-bg-color"
                      : "bg-secondary-bg-color"
                  }
                  rounded={false}
                  fullWidth={false}
                  onClick={() => {
                    setContestSubType(ContestSubType.winners);
                  }}>
                  <TailwindTranslatedText
                    fontWeight={"font-semibold"}
                    descriptor={{
                      id: "contests.winners",
                      defaultMessage: "Winners",
                    }}
                  />
                </TailwindButton>
              </TailwindFlex>
            }
          </TailwindFlex>
          {isLoadingData ? (
            <TailwindFlex
              height={"h-64"}
              width={"w-full"}
              justifyContent={"justify-center"}
              alignItems={"items-center"}>
              <TailwindSpinner />
            </TailwindFlex>
          ) : (
            <>
              {contestSelected === LeaderBoardContests.year &&
                contestSubType === ContestSubType.winners && (
                  <TailwindGrid
                    sm={{
                      cols: "grid-cols-1",
                    }}
                    margin={["mt-1"]}
                    cols={"grid-cols-2"}
                    gap='gap-1'>
                    {yearWinners.reverse().map((winner, index) => (
                      <TailwindBox
                        position={"relative"}
                        width='w-full'
                        borderRadius={"rounded"}
                        height='h-full'
                        key={index}>
                        <TailwindText
                          position={"absolute"}
                          borderRadius={"rounded-full"}
                          backgroundOpacity={"bg-opacity-50"}
                          backgroundColor='bg-black'
                          textColor='text-white'
                          fontSize='text-sm'
                          className={"right-2 top-2"}
                          padding={["px-4", "py-1"]}>
                          {winner.year}
                        </TailwindText>
                        <TailwindImage
                          boxProps={{
                            width: "w-full",
                            height: "h-full",
                          }}
                          className={"object-cover h-full w-full"}
                          src={winner.img}
                        />
                      </TailwindBox>
                    ))}
                  </TailwindGrid>
                )}
              {contestSelected === LeaderBoardContests.year &&
                contestSubType === ContestSubType.contest && (
                  <TailwindBox>
                    <TailwindTranslatedText
                      headingProps={{
                        className: "MyFans__title",
                        level: 5,
                        margin: ["my-4"],
                      }}
                      descriptor={{
                        id: "contests.modeloftheyear",
                        defaultMessage: "2021 Model of the Year",
                      }}
                    />
                    <TailwindTranslatedText
                      descriptor={{
                        id: "contests.modeloftheyear",
                        defaultMessage:
                          "Every year we present one model with the prestigious title of Cams.com Model of the Year. We base this award on a variety of criteria, including earnings, time spent broadcasting, member engagement, use of additional revenue streams, and attracting new members. This year, the title of Model of the Year goes to the one and only…",
                      }}
                    />
                    <TailwindTranslatedText
                      headingProps={{
                        className: "MyFans__title",
                        level: 5,
                        margin: ["my-4"],
                      }}
                      descriptor={{
                        id: "contests.modeloftheyear",
                        defaultMessage: "AARIS",
                      }}
                    />
                    <TailwindFlex
                      flexDirection={"flex-col"}
                      sm={"w-full"}
                      width='w-64'
                      height='h-full'>
                      <TailwindText
                        textAlign='text-center'
                        fontSize='text-xs'
                        padding={["py-1"]}
                        backgroundColor='bg-secondary-bg-color'>
                        AARIS
                      </TailwindText>
                      <TailwindImage
                        boxProps={{ width: "w-full", height: "h-full" }}
                        className='object-cover w-full h-full'
                        src={yearWinners[2].img}
                      />
                    </TailwindFlex>
                    <TailwindTranslatedText
                      paragraphProps={{
                        margin: ["mt-4"],
                      }}
                      descriptor={{
                        id: "contests.modeloftheyear",
                        defaultMessage:
                          "AARIS is a raven-haired goddess who has graced the pages of Cams.com for over three years. She’s adored by fans and fellow models alike, thanks in no small part to her sparkling personality and fierce work ethic.",
                      }}
                    />
                    <TailwindTranslatedText
                      paragraphProps={{
                        margin: ["mt-4"],
                      }}
                      descriptor={{
                        id: "contests.modeloftheyear",
                        defaultMessage:
                          "“The pandemic made 2020 a difficult year for many people around the world. We are so thankful for the loyalty and commitment of all our models who helped bring some joy into the lives of those who were stuck at home for so many months,” says Gunner, Director of Strategic Development for Cams.com. “AARIS took it to the next level and turned 2020 into a record-breaking year. I speak for the entire Cams team when I say we are absolutely thrilled to see her win Cams.com Model of the Year!”",
                      }}
                    />
                    <TailwindTranslatedText
                      paragraphProps={{
                        margin: ["mt-4"],
                      }}
                      descriptor={{
                        id: "contests.modeloftheyear",
                        defaultMessage: "Congratulations, AARIS!",
                      }}
                    />
                  </TailwindBox>
                )}
              <TailwindMasonry
                margin={["mt-3"]}
                colGapMargin={["ml-0"]}
                rowGapMargin={["mb-0"]}
                breakPoints={[
                  { size: 400, cols: 1 },
                  { size: 600, cols: 2 },
                  { size: 1000, cols: 3 },
                  { size: 1600, cols: 4 },
                  { size: 2000, cols: 5 },
                  { size: "default", cols: 6 },
                ]}>
                {contestSelected === LeaderBoardContests.day &&
                  contestSubType === ContestSubType.contest && (
                    <TailwindBox
                      borderRadius={"rounded"}
                      boxSizing={"box-border"}
                      borderColor={"border-white"}
                      borderWidth={["border-8"]}
                      key={loggedInModelRank.username + "my-rank"}>
                      <ModelMedia
                        contestType={contestSelected}
                        contestant={loggedInModelRank}
                      />
                    </TailwindBox>
                  )}

                {contestantData?.map((cntst: LeaderBoardModel, i: number) => {
                  return (
                    <TailwindBox
                      borderRadius={"rounded"}
                      boxSizing={"box-border"}
                      borderColor={
                        cntst.username === loggedInModelRank.username
                          ? "border-yellow-400"
                          : "border-transparent"
                      }
                      borderWidth={["border-8"]}
                      key={cntst.username + i}>
                      <ModelMedia
                        contestType={contestSelected}
                        contestant={cntst}
                      />
                    </TailwindBox>
                  );
                })}
              </TailwindMasonry>
            </>
          )}
        </TailwindFlex>
      </TailwindFlex>
    </>
  );
};

export default inject(
  "layoutStore",
  "leaderBoardStore",
  "showcaseStore",
  "profileStore"
)(observer(LeaderBoardContainer));
