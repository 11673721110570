import React, { useMemo } from "react";
import ProfileBadges from "./badges";
import TailwindFlex from "../_tailwind/flex";
import TailwindText from "../_tailwind/text";

export type ProfileInfoProps = {
  username: string;
  age: number | string;
  gender: string;
  locationDistance?: number | string;
  isVerified?: boolean;
  isPremium?: boolean;
  isPremiumVIP?: boolean;
  genderShort: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  showLocationDistance?: boolean;
  showBadgesAtEnd?: boolean;
  showDetailsNextToUsername?: boolean;
};

const ProfileInfo: React.ComponentType<ProfileInfoProps> = ({
  username,
  age,
  genderShort,
  locationDistance,
  isVerified,
  isPremium,
  isPremiumVIP,
  showLocationDistance = false,
  showBadgesAtEnd = true,
  showDetailsNextToUsername = true,
  onClick,
}) => {
  const hasBadge = useMemo(
    () => !!isVerified || !!isPremium || !!isPremiumVIP,
    [isVerified, isPremium, isPremiumVIP]
  );

  return (
    <TailwindFlex
      cursor={onClick ? "cursor-pointer" : undefined}
      onClick={onClick}
      alignItems={"items-center"}
    >
      <TailwindFlex width={"w-auto"} inline={showDetailsNextToUsername}>
        <TailwindText
          textColor={"text-white"}
          fontWeight={"font-bold"}
          fontSize={"text-base"}
        >
          {username}
        </TailwindText>
        {!showBadgesAtEnd && hasBadge && (
          <ProfileBadges
            margin={["ml-2"]}
            isVerified={isVerified}
            isPremium={isPremium}
            isPremiumVIP={isPremiumVIP}
          />
        )}
      </TailwindFlex>
      <TailwindFlex width={"w-auto"} inline={showDetailsNextToUsername}>
        {showLocationDistance ? (
          <React.Fragment>
            {age}
            {genderShort} • {locationDistance}km away
          </React.Fragment>
        ) : (
          <React.Fragment>
            &nbsp;•&nbsp;{age}
            {genderShort}
            {showBadgesAtEnd && hasBadge && (
              <ProfileBadges
                margin={["ml-2"]}
                isVerified={isVerified}
                isPremium={isPremium}
                isPremiumVIP={isPremiumVIP}
              />
            )}
          </React.Fragment>
        )}
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default ProfileInfo;
