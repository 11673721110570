import React, { useMemo } from "react";
import BroadcastInfoAccordion from "common/broadcast/broadcast-info-accordion";
import { ShowTab } from "common/broadcast/_stores/broadcast/enums";
import TailwindFlex from "library/components/_tailwind/flex";
import { languageStore } from "library/core/stores/language/LanguageStore";

type Props = {
  showTab: ShowTab;
};

const BroadcastShowInfoAccordions: React.ComponentType<Props> = ({
  showTab,
}) => {
  const { language, intl } = languageStore!;

  const label = useMemo(() => {
    switch (showTab) {
      case ShowTab.FREE: {
        return intl.formatMessage({
          id: "common.what-is-free-show",
          defaultMessage: "What is a FREE show?",
        });
      }
      case ShowTab.NUDE: {
        return intl.formatMessage({
          id: "common.what-is-nude-show",
          defaultMessage: "What is a NUDE show?",
        });
      }
      case ShowTab.TIPPING: {
        return intl.formatMessage({
          id: "common.what-is-goal-show",
          defaultMessage: "What is a GOAL show?",
        });
      }
    }
  }, [showTab, language, intl]);

  const text = useMemo(() => {
    switch (showTab) {
      case ShowTab.FREE: {
        return languageStore.intl.formatMessage({
          id: "private.title",
          defaultMessage:
            "Limited nudity and limited play are allowed unless in a Private or Party show where viewers are charged per minute. In Voyeur mode they can not hear you or chat. You can also stay in Free show to enjoy collecting buzzes, tips and gifts while entertaining a group!",
        });
      }
      case ShowTab.NUDE: {
        return languageStore.intl.formatMessage({
          id: "pay-to-enter.title",
          defaultMessage:
            "Explicit nudity and play allowed. All members are charged a per-minute fee to view and chat.",
        });
      }
      case ShowTab.TIPPING: {
        return languageStore.intl.formatMessage({
          id: "broadcast-show-type-control.tipping.detail.title",
          defaultMessage:
            'Topless nudity only until your chosen goal is reached. Once you reach your goal and enter the "hidden" mode, explicit nudity and play are allowed. All members who enter after you are in the "hidden" mode are charged the late entry fee. There is no per-minute fee.',
        });
      }
    }
  }, [showTab, language, intl]);

  return (
    <TailwindFlex padding={["p-4"]}>
      <BroadcastInfoAccordion label={label} text={text} />
    </TailwindFlex>
  );
};

export default BroadcastShowInfoAccordions;
