import React, { useCallback, useMemo, useState } from "react";
import "./styles.scss";
import { inject, observer } from "mobx-react";
import { Popover } from "../../popover";
import NodeChatStore from "../_stores/nodechat/NodeChatStore";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import { QuickText } from "../_stores/nodechat/types";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import { ChatModeEnum } from "../_stores/chat/enums";

interface IQuickTextPopover {
  nodeChatStore?: NodeChatStore;
  broadcastStore?: BroadcastStore;
  children: any;
}

const QuickTextPopover = ({
  nodeChatStore,
  broadcastStore,
  children,
}: IQuickTextPopover) => {
  const { quickTexts, sendMessage, activeMemberToWhisper, updateQuickText, deleteQuickText } = nodeChatStore!;
  const [deleteConfirmIndex, setDeleteConfirmIndex] = useState<number | null>(null);
  const { isInPaidShow } = broadcastStore!;

  const [show, setShow] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editedText, setEditedText] = useState("");

  const onHide = useCallback(() => {
    setShow(false);
  }, []);

  const onShow = useCallback(() => {
    setShow(true);
  }, []);

  const sendQt = (qt: QuickText) => {
    const chatType = !!activeMemberToWhisper ? ChatModeEnum.WHISPER : isInPaidShow ? ChatModeEnum.PRIVATE : ChatModeEnum.PUBLIC
    sendMessage(qt.text, chatType, activeMemberToWhisper?.username, activeMemberToWhisper?.id);
    onHide();
  }

  const startEditing = (index: number, text: string) => {
    setEditingIndex(index);
    setEditedText(text);
  }

  const saveEdit = (index: number) => {
    updateQuickText(quickTexts[index].id, editedText);
    setEditingIndex(null);
    setEditedText("");
  }

  const qts = useMemo(() => {
    return quickTexts.map((qt, index) => (
      <li 
        key={index} 
        className="w-full flex flex-row justify-start items-center gap-1 hover:bg-gray-100 rounded-lg"
      >
        {editingIndex === index ? (
          <input
            type="text"
            value={editedText}
            onChange={(e) => setEditedText(e.target.value)}
            className="w-96 p-1 border border-gray-300 rounded"
          />
        ) : (
          <span 
            onClick={() => sendQt(qt)} 
            className="w-96 whitespace-nowrap overflow-ellipsis overflow-hidden cursor-pointer p-1"
          >
            {qt.text}
          </span>
        )}
        <TailwindButton
          dataTestId={"chat-quick-text-edit-button"}
          type={"button"}
          fullWidth={false}
          size={"xs"}
          backgroundColor={"bg-secondary-bg-color"}
          borderRadius={"rounded-full"}
          leftIconProps={{
            name: editingIndex === index ? "check" : "edit",
          }}
          onClick={() => editingIndex === index ? saveEdit(index) : startEditing(index, qt.text)}
        />
        <TailwindButton
          dataTestId={"chat-quick-text-delete-button"}
          type={"button"}
          fullWidth={false}
          size={"xs"}
          textColor={deleteConfirmIndex === index ? "text-white" : "text-black"}
          backgroundColor={deleteConfirmIndex === index ? "bg-red-500" : "bg-secondary-bg-color"}
          borderRadius={"rounded-full"}
          leftIconProps={{
            name: deleteConfirmIndex === index ? "question-mark" : "trash",
          }}
          onClick={() => {
            if (deleteConfirmIndex === index) {
              deleteQuickText(qt.id);
              setDeleteConfirmIndex(null);
            } else {
              setDeleteConfirmIndex(index);
            }
          }}
        />
      </li>
    ));
  }, [quickTexts, editingIndex, editedText, deleteConfirmIndex])

  return (
    <Popover
      placement='top-start'
      onHide={onHide}
      onShow={onShow}
      show={show}
      content={
        <TailwindFlex 
          gap={'gap-1.5'} 
          flexDirection={"flex-col"} 
          justifyContent={"justify-end"} 
          alignItems="items-start" 
          width={"w-auto"} 
          borderWidth={["border-2"]} 
          borderColor={"border-blue-500"} 
          padding={['p-2']} 
          backgroundColor={"bg-primary-bg-color"}
        >
          <TailwindTranslatedText className="font-bold" descriptor={{
            id: 'broadcast.chatItem.qt_label',
            defaultMessage: 'Quick Text'
          }}></TailwindTranslatedText>
          <ul>
            {qts && qts.length ? (
              <>
              {qts}
              </>
            ) : (
              <li>No quick texts available</li>
            )}
          </ul>
          
        </TailwindFlex>
      }>
      {children}
    </Popover>
  );
};

export default inject("nodeChatStore", "broadcastStore")(observer(QuickTextPopover));
