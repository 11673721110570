export enum IChatTab {
  PUBLIC_OR_PRIVATE = "publicOrPrivate",
  WHISPER = "whisper",
  ADMIN = "admin",
}
export enum MemberActionEnum {
  JOIN = "Join",
  LEFT = "Left",
  DISCONNECT = "Disconnect",
}
export enum ChatModeEnum {
  PUBLIC = "public",
  PRIVATE = "private",
  WHISPER = "whisper",
  ADMIN = "admin",
}

export enum ChatState {
  "stopped" = "stopped",
  "started" = "started",
  "stoppingChat" = "stoppingChat",
  "startingChat" = "startingChat",
  "errored" = "errored",
}
