import {
  IconButton,
  withStyles,
  StyledComponentProps,
  Icon,
} from "@material-ui/core";
import toast from "react-hot-toast";
import * as React from "react";

const snackbarCloseButtonStyles = () => ({
  root: {
    color: "white",
  },
});

interface ISnackbarCloseButtonProps extends StyledComponentProps {
  id: string | undefined;
}

const SnackbarCloseButtonComponent = ({
  id,
  classes = {},
}: ISnackbarCloseButtonProps) => {
  return (
    <IconButton
      size='small'
      classes={{ root: classes.root }}
      onClick={() => toast.dismiss(id)}>
      <Icon>close</Icon>
    </IconButton>
  );
};

const SnackbarCloseButton = withStyles(snackbarCloseButtonStyles)(
  SnackbarCloseButtonComponent
);

export { SnackbarCloseButton };
