import React, { useCallback, useEffect, useRef, useMemo } from "react";
import ChatMessage from "./chat-message";
import { IChatMessageOrNotification } from "../_stores/chat/interfaces";
import TailwindFlex from "library/components/_tailwind/flex";
import ChatNotification from "common/broadcast/broadcast-chat/chat-notification";
import TailwindText from "library/components/_tailwind/text";
import { ChatModeEnum } from "../_stores/chat/enums";
import PricingStore from "../_stores/pricing/PricingStore";
import NodeChatStore from "../_stores/nodechat/NodeChatStore";
import { inject, observer } from "mobx-react";

type Props = {
  messages: IChatMessageOrNotification[];
  chatMode?: ChatModeEnum;
  isStreamingAndShowStarted: boolean;
  pricingStore?: PricingStore;
  nodeChatStore?: NodeChatStore;
  whisperMemberUsername?: string;
};

const BroadcastChatMessages: React.ComponentType<Props> = ({
  messages,
  chatMode = ChatModeEnum.PUBLIC,
  // isStreamingAndShowStarted,
  pricingStore,
  nodeChatStore,
  whisperMemberUsername
}) => {
  const chatScrollBox = useRef() as React.MutableRefObject<any>;
  const { modelProducts } = pricingStore!;
  const { startWhisperTo, allMemberHash } = nodeChatStore!;

  const system_settings = useMemo(
    () => modelProducts?.chat_settings?.system_settings,
    [modelProducts]
  );

  const isWhisperActiveAndEmpty = useMemo(
    () =>
      chatMode == ChatModeEnum.WHISPER && (!messages || messages.length === 0),
    [chatMode, messages]
  );

  const handleWhisperBack = useCallback(
    username => {
      const member = Object.values(allMemberHash)?.find(
        user => user?.username === username
      );

      if (member) {
        startWhisperTo(member);
      }
    },
    [allMemberHash]
  );

  useEffect(() => {
    if (messages && messages.length > 0 && chatScrollBox!.current) {
      chatScrollBox!.current!.scrollTop = chatScrollBox!.current!.scrollHeight;
    }
  }, [chatScrollBox, messages]);

  // Avoid showing notification on broadcast page when model is offline
  //if (!isStreamingAndShowStarted) return null;

  return (
    <TailwindFlex
      ref={chatScrollBox}
      flexDirection={
        !messages || messages.length === 0 ? "flex-col" : "flex-col-reverse"
      }
      overflow={"overflow-y-auto"}
      flexGrow={"flex-grow"}
      padding={["pb-2"]}
      justifyContent={isWhisperActiveAndEmpty ? "justify-center" : undefined}
      alignItems={isWhisperActiveAndEmpty ? "items-center" : undefined}>
      {messages &&
        messages.length > 0 &&
        messages
          .map((message, index) => {
            if (message.isMessageOrNotification === "message") {
              return (
                <ChatMessage
                  key={"msg" + index}
                  message={message}
                  chatMode={chatMode}
                  system_settings={system_settings}
                  onWhisperBack={handleWhisperBack}
                />
              );
            } else {
              const isMemberTabNotification = whisperMemberUsername === message?.fixedContentParams?.chatfrom;
              const isMainChatTab = chatMode === 'public' || chatMode === 'private';

              return <ChatNotification key={index} notification={message} isMemberTabNotification={isMemberTabNotification} isMainChatTab={isMainChatTab} />;
            }
          })
          .reverse()}
      {isWhisperActiveAndEmpty && (
        <TailwindText
          margin={["mt-6"]}
          width='w-full'
          textColor='text-main-color'
          textAlign='text-center'>
          You have not received any whispers from members yet. Start whispering
          with members.
        </TailwindText>
      )}
    </TailwindFlex>
  );
};

export default inject(
  "nodeChatStore",
  "pricingStore"
)(observer(BroadcastChatMessages));
