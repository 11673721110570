import { snackbarStore } from "./snackbar/SnackbarStore";
import { languageStore } from "./language/LanguageStore";
import { validationStore } from "./validation/ValidationStore";
import { layoutStore } from "./layout/LayoutStore";
import { modalStore } from "./modal";
import { themeStore } from "./theme/ThemeStore";

export const stores = {
  snackbarStore,
  languageStore,
  validationStore,
  layoutStore,
  themeStore,
  modalStore,
};

if (!(window as any).cams) {
  (window as any).cams = {};
}

((window as any) || {}).cams.library = {
  stores,
};
