import React from "react";
import TailwindParagraph, {
  TailwindParagraphProps,
} from "../../components/_tailwind/paragraph";

type Props = TailwindParagraphProps & {};

const StorybookParagraph: React.ComponentType<Props> = ({
  children,
  ...props
}) => {
  return (
    <TailwindParagraph {...props} margin={["mb-4"]}>
      {children}
    </TailwindParagraph>
  );
};

export default StorybookParagraph;
