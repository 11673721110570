import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import StorybookComponentsText from "./components-text";
import StorybookComponentsTranslatedText from "./components-translated-text";
import StorybookComponentsTranslatedNumber from "./components-translated-number";
import StorybookComponentsTranslatedDate from "./components-translated-date";
import StorybookComponentsTranslatedTime from "./components-translated-time";
import StorybookComponentsHeading from "./components-heading";
import StorybookComponentsParagraph from "./components-paragraph";
import StorybookComponentsIcon from "./components-icon";
import StorybookComponentsBox from "./components-box";
import StorybookComponentsCard from "./components-card";
import StorybookComponentsFlex from "./components-flex";
import StorybookComponentsGrid from "./components-grid";
import StorybookComponentsAnchor from "./components-anchor";
import StorybookComponentsButton from "./components-button";
import StorybookComponentsButtonGroup from "./components-button-group";
import StorybookComponentsDivider from "./components-divider";
import StorybookComponentsSwitch from "./components-switch";
import StorybookComponentsCheckboxRadio from "./components-checkbox-radio";
import StorybookComponentsSwipeableSlider from "./components-swipeable-slider";
import StorybookComponentsToggleableButton from "./components-toggle";
import StorybookComponentsInputBase from "./components-input-base";
import StorybookComponentsInput from "./components-input";
import StorybookComponentsImage from "./components-image";
import StorybookComponentsAvatar from "./components-avatar";
import StorybookComponentsFileUpload from "./components-file-upload";
import StorybookComponentsDragAndDrop from "./components-drag-and-drop";
import StorybookComponentsSnackbar from "./components-snackbar";
import StorybookComponentsVideoPlayer from "./components-video-player";
import StorybookComponentsTabs from "./components-tabs";
import StorybookComponentsFeed from "./components-feed";
import StorybookComponentsMedia from "./components-media";
import StorybookComponentsProfile from "./components-profile";
import StorybookComponentsLiveModels from "./components-live-models";
import StorybookComponentsInteractionsPage from "./components-interactions-page";
import StorybookComponentsNotificationsMenu from "./components-notifications-menu";
import StorybookComponentsProfilePage from "./components-profile";
import StorybookComponentsKinksSearchBar from "./components-kinks-search-bar";
import StorybookComponentsBadge from "./components-badge";
import StorybookComponentsDropdown from "./components-dropdown";
import StorybookComponentsPill from "./components-pill";
import StorybookComponentsList from "./components-list";

type Props = {};

const StorybookComponents: React.ComponentType<Props> = ({}) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/list`}>
        <StorybookComponentsList />
      </Route>
      <Route exact path={`${path}/text`}>
        <StorybookComponentsText />
      </Route>
      <Route exact path={`${path}/translated-text`}>
        <StorybookComponentsTranslatedText />
      </Route>
      <Route exact path={`${path}/translated-number`}>
        <StorybookComponentsTranslatedNumber />
      </Route>
      <Route exact path={`${path}/translated-date`}>
        <StorybookComponentsTranslatedDate />
      </Route>
      <Route exact path={`${path}/translated-time`}>
        <StorybookComponentsTranslatedTime />
      </Route>
      <Route exact path={`${path}/heading`}>
        <StorybookComponentsHeading />
      </Route>
      <Route exact path={`${path}/paragraph`}>
        <StorybookComponentsParagraph />
      </Route>
      <Route exact path={`${path}/icon`}>
        <StorybookComponentsIcon />
      </Route>
      <Route exact path={`${path}/box`}>
        <StorybookComponentsBox />
      </Route>
      <Route exact path={`${path}/card`}>
        <StorybookComponentsCard />
      </Route>
      <Route exact path={`${path}/flex`}>
        <StorybookComponentsFlex />
      </Route>
      <Route exact path={`${path}/grid`}>
        <StorybookComponentsGrid />
      </Route>
      <Route exact path={`${path}/anchor`}>
        <StorybookComponentsAnchor />
      </Route>
      <Route exact path={`${path}/button`}>
        <StorybookComponentsButton />
      </Route>
      <Route exact path={`${path}/badge`}>
        <StorybookComponentsBadge />
      </Route>
      <Route exact path={`${path}/dropdown`}>
        <StorybookComponentsDropdown />
      </Route>
      <Route exact path={`${path}/button-group`}>
        <StorybookComponentsButtonGroup />
      </Route>
      <Route exact path={`${path}/divider`}>
        <StorybookComponentsDivider />
      </Route>
      <Route exact path={`${path}/pill`}>
        <StorybookComponentsPill />
      </Route>
      <Route exact path={`${path}/switch`}>
        <StorybookComponentsSwitch />
      </Route>
      <Route exact path={`${path}/checkbox-radio`}>
        <StorybookComponentsCheckboxRadio />
      </Route>
      <Route exact path={`${path}/swipeable-slider`}>
        <StorybookComponentsSwipeableSlider />
      </Route>
      <Route exact path={`${path}/toggleable-button`}>
        <StorybookComponentsToggleableButton />
      </Route>
      <Route exact path={`${path}/input-base`}>
        <StorybookComponentsInputBase />
      </Route>
      <Route exact path={`${path}/input`}>
        <StorybookComponentsInput />
      </Route>
      <Route exact path={`${path}/image`}>
        <StorybookComponentsImage />
      </Route>
      <Route exact path={`${path}/avatar`}>
        <StorybookComponentsAvatar />
      </Route>
      <Route exact path={`${path}/file-upload`}>
        <StorybookComponentsFileUpload />
      </Route>
      <Route exact path={`${path}/drag-and-drop`}>
        <StorybookComponentsDragAndDrop />
      </Route>
      <Route exact path={`${path}/snackbar`}>
        <StorybookComponentsSnackbar />
      </Route>
      <Route exact path={`${path}/video-player`}>
        <StorybookComponentsVideoPlayer />
      </Route>
      <Route exact path={`${path}/tabs`}>
        <StorybookComponentsTabs />
      </Route>
      <Route exact path={`${path}/feed`}>
        <StorybookComponentsFeed />
      </Route>
      <Route exact path={`${path}/media`}>
        <StorybookComponentsMedia />
      </Route>
      <Route exact path={`${path}/profile`}>
        <StorybookComponentsProfile />
      </Route>
      <Route exact path={`${path}/live-models`}>
        <StorybookComponentsLiveModels />
      </Route>
      <Route exact path={`${path}/interactions-page`}>
        <StorybookComponentsInteractionsPage />
      </Route>
      <Route exact path={`${path}/notifications-menu`}>
        <StorybookComponentsNotificationsMenu />
      </Route>
      <Route exact path={`${path}/profile-page`}>
        <StorybookComponentsProfilePage />
      </Route>
      <Route exact path={`${path}/kinks-search-bar`}>
        <StorybookComponentsKinksSearchBar />
      </Route>
      <Route exact path={`${path}/intro`}>
        <StorybookComponentsKinksSearchBar />
      </Route>
    </Switch>
  );
};

export default StorybookComponents;
