import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import FeedStory from "../../components/_tailwind/feed/feed-story";
import TailwindFlex from "../../components/_tailwind/flex";
import FeedActivity from "../../components/_tailwind/feed/feed-activity";
import { CustomIconName } from "../../components/_tailwind/icon/icons/enums";

export const PROFILE_MOCK: any = {
  isPremium: true,
  isVerified: true,
  gender: "male",
  age: 21,
  isOnline: true,
  username: "Memberusername",
  location: "Long Beach, CA",
  lookingFor: "Monogamous",
  bodyType: "Hispanic, Averang, 5'10\"",
  drugs: "Non-smoker, Light/social drinker",
  friends: 736,
  hotlist: 52461,
  gifts: 1847,
  fans: 6308,
  flirts: 52721,
  sexualOrientation: "Straight",
  genderShort: "M",
  genderLong: "Man",
  points: 99,
  profilePhoto:
    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb01-1b1f87ed74cf90fd190f20abb17c5414-9211b9.jpg",
};

type Props = {};

const StorybookComponentsFeed: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Feed</StorybookHeading>
      <StorybookParagraph>
        Feed components are a collection of components that are used to compose
        an "activity" or a "story" according to the design. These components are
        so far only used on AFF but will be used in other projects later. The
        list of components are:
      </StorybookParagraph>
      <Code>{`
        feed-activity (container)
        feed-story (container)
        feed-comment (sub component)
        feed-cover (sub component)
        feed-header (sub component)
        feed-footer (sub component)
      `}</Code>
      <StorybookParagraph>Here are some examples:</StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<FeedStory
            feedCoverProps={{
              mediaProfileThumbnailProps: {
                mediaProps: {
                  src: "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/iStock_471161432-a397ba678a620d002fc5c6a89bfd140f.jpg",
                  height: "h-96"
                },
              },
            }}
            feedHeaderProps={{
              profile: {
              isPremium: true,
              isVerified: true,
              gender: GenderEnum.M,
              age: 21,
              isOnline: true,
              username: "Memberusername",
              location: "Long Beach, CA",
              lookingFor: "Monogamous",
              bodyType: "Hispanic, Averang, 5'10"",
              drugs: "Non-smoker, Light/social drinker",
              friends: 736,
              hotlist: 52461,
              gifts: 1847,
              fans: 6308,
              flirts: 52721,
              sexualOrientation: "Straight",
              genderShort: "M",
              genderLong: "Man",
              points: 99,
              profilePhoto:
                "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb01-1b1f87ed74cf90fd190f20abb17c5414-9211b9.jpg",
                  },
              date: new Date(),
              description: "He's the reason for the teardrops on my.",
            }}
            commentCount={2}
            viewCount={13234}
          />`}
      >
        <TailwindFlex width={"w-4/12"}>
          <FeedStory
            feedCoverProps={{
              mediaProfileThumbnailProps: {
                mediaProps: {
                  src:
                    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/iStock_471161432-a397ba678a620d002fc5c6a89bfd140f.jpg",
                  height: "h-96",
                },
              },
            }}
            feedHeaderProps={{
              profileDetails: {
                profile: PROFILE_MOCK,
                date: new Date(),
                description: "He's the reason for the teardrops on my.",
              },
            }}
            commentCount={2}
            viewCount={13234}
          />
        </TailwindFlex>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<FeedActivity
            feedCoverProps={{
              mediaProfileThumbnailProps: {
                mediaProps: {
                  src:
                    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb01-1b1f87ed74cf90fd190f20abb17c5414-9211b9.jpg",
                  hasBorderRadius: false,
                },
              },
            }}
            feedHeaderProps={{
              profileDetails: {
                profile: PROFILE_MOCK,
                date: new Date(),
                description: "He's the reason for the teardrops on my.",
              }
            }}
            comments={[
              {
                profile: {
                  ...PROFILE_MOCK,
                  profilePhoto:
                    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb04-8f5ca3e78679f6f40d9708881c0dcedb.jpg",
                },
                date: new Date(),
                comment: "Sounds good!",
                id: "1",
              },
              {
                profile: {
                  ...PROFILE_MOCK,
                  profilePhoto:
                    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb05-a9b1bf870714bd63ea94bd67c857c354.jpg",
                },
                date: new Date(),
                comment: "Nice photo. Can't wait to see you.",
                id: "2",
              },
              {
                profile: {
                  ...PROFILE_MOCK,
                  profilePhoto:
                    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Female_thumb09-c365c0059c7a9feac1280d1354579b88.jpg",
                },
                date: new Date(),
                comment: "Wow",
                id: "3",
              },
            ]}
          />`}
      >
        <TailwindFlex width={"w-4/12"}>
          <FeedActivity
            feedCoverProps={{
              mediaProfileThumbnailProps: {
                mediaProps: {
                  src:
                    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb01-1b1f87ed74cf90fd190f20abb17c5414-9211b9.jpg",
                  height: "h-96",
                },
              },
            }}
            feedHeaderProps={{
              profileDetails: {
                profile: PROFILE_MOCK,
                date: new Date(),
                description: "He's the reason for the teardrops on my.",
              },
            }}
            comments={[
              {
                profile: {
                  ...PROFILE_MOCK,
                  profilePhoto:
                    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb04-8f5ca3e78679f6f40d9708881c0dcedb.jpg",
                },
                date: new Date(),
                comment: "Sounds good!",
                id: "1",
              },
              {
                profile: {
                  ...PROFILE_MOCK,
                  profilePhoto:
                    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb05-a9b1bf870714bd63ea94bd67c857c354.jpg",
                },
                date: new Date(),
                comment: "Nice photo. Can't wait to see you.",
                id: "2",
              },
              {
                profile: {
                  ...PROFILE_MOCK,
                  profilePhoto:
                    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Female_thumb09-c365c0059c7a9feac1280d1354579b88.jpg",
                },
                date: new Date(),
                comment: "Wow",
                id: "3",
              },
            ]}
          />
        </TailwindFlex>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<FeedActivity
            feedCoverProps={{
              mediaProfileThumbnailProps: {
                mediaProps: {
                  src:
                    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/iStock_926640544-8e08f719174ff9d616d5a2ac46973612.png",
                  hasBorderRadius: false,
                },
              },
            }}
            feedHeaderProps={{
              profileDetails: {
                profile: {
                  ...PROFILE_MOCK,
                  profilePhoto:
                    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r_20_s_2_478520850_73580_1288270546_0_main_ba_0_e_9949_cbf_28_c_5426062_a_699_c_7_d_9344_jpg-9a04047d0a948ebb4f473e1f8e94649b.jpg",
                },
                date: new Date(),
              }
            }}
          />`}
      >
        <TailwindFlex>
          <FeedActivity
            feedCoverProps={{
              mediaProfileThumbnailProps: {
                mediaProps: {
                  src:
                    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/iStock_926640544-8e08f719174ff9d616d5a2ac46973612.png",
                  height: "h-96",
                },
              },
            }}
            feedHeaderProps={{
              profileDetails: {
                profile: {
                  ...PROFILE_MOCK,
                  profilePhoto:
                    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r_20_s_2_478520850_73580_1288270546_0_main_ba_0_e_9949_cbf_28_c_5426062_a_699_c_7_d_9344_jpg-9a04047d0a948ebb4f473e1f8e94649b.jpg",
                },
                date: new Date(),
              },
            }}
          />
        </TailwindFlex>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<FeedActivity
            feedHeaderProps={{
              activityDetails: {
                iconName: CustomIconName["gold-crown"],
                text: "Upgraded to a Gold Member",
              },
              profileDetails: {
                profile: {
                  ...PROFILE_MOCK,
                  profilePhoto:
                    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r_20_s_2_478520850_73580_1288270546_0_main_ba_0_e_9949_cbf_28_c_5426062_a_699_c_7_d_9344_jpg-9a04047d0a948ebb4f473e1f8e94649b.jpg",
                },
                date: new Date(),
              },
            }}
          />`}
      >
        <TailwindFlex>
          <FeedActivity
            feedHeaderProps={{
              activityDetails: {
                iconName: CustomIconName["gold-crown"],
                text: "Upgraded to a Gold Member",
              },
              profileDetails: {
                profile: {
                  ...PROFILE_MOCK,
                  profilePhoto:
                    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r_20_s_2_478520850_73580_1288270546_0_main_ba_0_e_9949_cbf_28_c_5426062_a_699_c_7_d_9344_jpg-9a04047d0a948ebb4f473e1f8e94649b.jpg",
                },
                date: new Date(),
              },
            }}
          />
        </TailwindFlex>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsFeed;
