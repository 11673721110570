import React from "react";
import TailwindText from "../text";
import { CustomIconName } from "../icon/icons/enums";
import TailwindIcon from "../icon";
import {
  TBackgroundColor,
  TBorderRadius,
  TTextColor,
} from "../../../styles/tailwind-classnames";
import TailwindFlex, { TailwindFlexProps } from "../flex";

export type TailwindBadgeProps = TailwindFlexProps & {
  badgeContent: string | number | CustomIconName;
  badgeBackgroundColor?: TBackgroundColor;
  badgeTextColor?: TTextColor;
  borderRadius?: TBorderRadius;
};

const TailwindBadge: React.ComponentType<TailwindBadgeProps> = ({
  badgeContent,
  badgeBackgroundColor = "bg-pink-600",
  badgeTextColor = "text-white",
  borderRadius,
  children,
  ...props
}) => {
  return (
    <TailwindFlex {...props} inline position={"relative"} width={"w-auto"}>
      {children}
      {Object.values(CustomIconName).includes(
        badgeContent as CustomIconName
      ) ? (
        <TailwindIcon
          name={badgeContent as CustomIconName}
          backgroundColor={badgeBackgroundColor}
          textColor={badgeTextColor}
          borderRadius={borderRadius || "rounded-full"}
          padding={["p-1"]}
          fontSize={"text-xs"}
          display={"inline-flex"}
          alignItems={"items-center"}
          justifyContent={"justify-center"}
          position={"absolute"}
          inset={["-top-3", "left-full"]}
        />
      ) : (typeof badgeContent === "number" && badgeContent > 0) ||
        typeof badgeContent === "string" ? (
        <TailwindText
          backgroundColor={badgeBackgroundColor}
          borderRadius={borderRadius || "rounded-full"}
          padding={["p-1"]}
          textColor={badgeTextColor}
          fontSize={"text-xs"}
          display={"inline-flex"}
          alignItems={"items-center"}
          justifyContent={"justify-center"}
          position={"absolute"}
          inset={["-top-3", "left-full"]}
          whiteSpace={"whitespace-nowrap"}>
          {badgeContent}
        </TailwindText>
      ) : null}
    </TailwindFlex>
  );
};

export default TailwindBadge;
