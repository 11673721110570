import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { inject, observer } from "mobx-react";
import AuthStore from "core/stores/auth/AuthStore";
import RouteStore from "core/stores/route/RouteStore";

type Props = RouteProps & {
  authStore?: AuthStore;
  routeStore?: RouteStore;
};

const NotFoundRoute: React.ComponentType<Props> = ({
  authStore,
  routeStore,
}) => {
  const { isLoggedIn } = authStore!;
  const { getMainRouteForSite } = routeStore!;

  const redirectPath = isLoggedIn ? getMainRouteForSite() : "/";
  return (
    <Route path={"*"}>
      <Redirect
        to={{
          pathname: redirectPath,
        }}
      />
    </Route>
  );
};

export default inject("authStore", "routeStore")(observer(NotFoundRoute));
