import React from "react";
import TailwindBox from "library/components/_tailwind/box";
import TailwindText from "library/components/_tailwind/text";
import "./styles.scss";
import { FALLBACK_BOUNTY_LEGAL_INFO } from "core/constants";

type Props = {};
const BountyLegalInfo = ({}: Props) => {
  return (
    <div className={"BountyLegalInfo"}>
      <TailwindText
        display={"block"}
        fontSize={"text-3xl"}
        fontWeight={"font-bold"}
        padding={["py-2"]}
        margin={["mb-4"]}>
        Bounty Legal Information
      </TailwindText>
      <TailwindBox
        dangerouslySetInnerHTML={{
          __html: FALLBACK_BOUNTY_LEGAL_INFO,
        }}></TailwindBox>
    </div>
  );
};

export default BountyLegalInfo;
