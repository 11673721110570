import React from "react";
import "./styles.scss";
import classnames from "classnames";

type ConfirmCircleIconProps = {
  className?: string;
};

const ConfirmCircleIcon: React.ComponentType<ConfirmCircleIconProps> = ({
  className,
}) => (
  <span className={classnames("ConfirmCircleIcon", className && className)}>
    <svg
      version='1.2'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      overflow='visible'
      preserveAspectRatio='none'
      viewBox='0 0 20 20'>
      <style
        type='text/css'
        dangerouslySetInnerHTML={{
          __html: "\n\t.st0{fill:#FFFFFF;}\n\t.st1{fill:#00B326;}\n",
        }}
      />
      <g>
        <path
          className='st0'
          d='M16.7,9.9c0,3.7-3,6.7-6.7,6.7s-6.7-3-6.7-6.7s3-6.7,6.7-6.7S16.7,6.2,16.7,9.9z'
        />
        <path
          className='st0'
          d='M10,1.9c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S14.4,1.9,10,1.9z M10,16.6c-3.7,0-6.7-3-6.7-6.7s3-6.7,6.7-6.7
		s6.7,3,6.7,6.7S13.7,16.6,10,16.6z'
        />
        <path
          vectorEffect='non-scaling-stroke'
          className='st1'
          d='M10,3.3c-3.7,0-6.7,3-6.7,6.7
		s3,6.7,6.7,6.7s6.7-3,6.7-6.7S13.7,3.3,10,3.3z M8.7,13.2L5.3,9.9L6.2,9l2.4,2.4l5.1-5.1l0.9,0.9L8.7,13.2z'
        />
      </g>
    </svg>
  </span>
);

export default ConfirmCircleIcon;
