import React, { FunctionComponent, useCallback, useEffect } from "react";
import { inject, observer } from "mobx-react";
import AuthStore from "core/stores/auth/AuthStore";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import { injectIntl, WrappedComponentProps } from "react-intl";
import config from "core/config";
import ProfileStore from "../../my-page/stores/profile/ProfileStore";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindInput from "library/components/_tailwind/input";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import TailwindButton from "library/components/_tailwind/button";
import TailwindDropdown from "library/components/_tailwind/dropdown";
import DateProcess from "library/core/utility/date-process";
import RecaptchaForm from "library/components/_tailwind/recaptcha";
import { NullableString } from "library/core/types";
import { RegistrationStep } from "core/stores/auth/enums";

type ModelAccountInfoStepProps = {
  authStore?: AuthStore;
  validationStore?: ValidationStore;
  profileStore?: ProfileStore;
};

const ModelAccountInfoStep: FunctionComponent<
  ModelAccountInfoStepProps & WrappedComponentProps
> = ({ profileStore, authStore, validationStore, intl }) => {
  const { validateMultipleObj, clearErrors, clearError, validate, errors } =
    validationStore!;
  const {
    submitAccountRegistration,
    formRegistrationData,
    updateRegistrationFormField,
    userRole,
  } = authStore!;

  const {
    model: {
      username,
      promo_code,
      first_name,
      other_name,
      last_name,
      gender,
      birthdate,
    },
  } = formRegistrationData!;

  const {
    //isCams
  } = profileStore!;

  const { googleCaptchaSiteKey } = config!;

  const validateRegistrationFields = useCallback(() => {
    return validateMultipleObj(
      {
        username,
        gender,
        first_name,
        last_name,
        birthdate,
        promo_code,
      },
      {
        promo_code: {
          canBeEmpty: true,
        },
      },
      false
    );
  }, [
    username,
    gender,
    first_name,
    last_name,
    birthdate,
    promo_code,
  ]);

  const onCaptchaChange = async (recaptchaKey: NullableString) => {
    if (recaptchaKey && userRole && validateRegistrationFields()) {
      try {
        await submitAccountRegistration(recaptchaKey);
      } catch (error) {
        // do not remove as this is used to fallback to v2
        throw new Error(error);
      }
    }
  };

  useEffect(() => {
    // initialize default birthdate
    if (!birthdate) {
      updateRegistrationFormField("model", {
        name: "birthdate",
        value: DateProcess.eighteenYearsBeforeToday,
      });
    }
  }, []);

  useEffect(() => {
    clearErrors();
  }, [validationStore]);

  useEffect(() => {
    if (errors.email) {
      authStore?.setRegistrationStep(RegistrationStep.AUTH_CREDENTIALS);
    }
  }, [errors]);

  return (
    <TailwindFlex
      sm={"w-full"}
      width={"w-96"}
      className={["RegisterModal__form"]}
      height={"h-full"}>
      <RecaptchaForm
        margin={["mt-6"]}
        captchaSiteKey={googleCaptchaSiteKey}
        beforeExecuteRecaptcha={clearErrors}
        callback={onCaptchaChange}>
        <TailwindFlex
          margin={["mb-4"]}
          pseudoClasses={["sm:flex-col", "md:flex-col"]}>
          <TailwindFlex
            width={"w-6/12"}
            margin={["mr-1"]}
            pseudoClasses={["sm:mr-0", "md:mb-4", "md:mr-0", "md:mb-4"]}
            sm={"w-full"}
            md={"w-full"}
            className={["RegisterModal__form-firstname"]}>
            <TailwindInput
              required
              rounded={false}
              backgroundColor={"transparent"}
              borderColor={"border-black"}
              borderWidth={["border"]}
              label={intl.formatMessage({
                id: "landing.modelSetup.firstName",
                defaultMessage: "First Name",
              })}
              errorProps={{
                textAlign: "text-right",
              }}
              value={first_name || ""}
              type={TailwindInputType.text}
              name={"first_name"}
              error={errors?.first_name || undefined}
              onChange={e => {
                clearError("first_name");
                updateRegistrationFormField("model", e.target);
              }}
              onBlur={e => validate("first_name", e.target.value)}
            />
          </TailwindFlex>
          <TailwindFlex
            width={"w-6/12"}
            margin={["ml-1"]}
            pseudoClasses={["sm:ml-0", "md:ml-0"]}
            sm={"w-full"}
            md={"w-full"}
            className={["RegisterModal__form-lastname"]}>
            <TailwindInput
              required
              rounded={false}
              backgroundColor={"transparent"}
              borderColor={"border-black"}
              borderWidth={["border"]}
              label={intl.formatMessage({
                id: "landing.modelSetup.lastName",
                defaultMessage: "Last Name",
              })}
              errorProps={{
                textAlign: "text-right",
              }}
              value={last_name || ""}
              type={TailwindInputType.text}
              name={"last_name"}
              error={errors?.last_name || undefined}
              onChange={e => {
                clearError("last_name");
                updateRegistrationFormField("model", e.target);
              }}
              onBlur={e => validate("last_name", e.target.value)}
            />
          </TailwindFlex>
        </TailwindFlex>
        <TailwindFlex
          margin={["mb-4"]}
          pseudoClasses={["sm:flex-col", "md:flex-col"]}>
          <TailwindFlex
            width={"w-full"}
            margin={["mr-1"]}
            pseudoClasses={["sm:mr-0", "md:mb-4", "md:mr-0", "md:mb-4"]}
            sm={"w-full"}
            md={"w-full"}
            className={["RegisterModal__form-othername"]}>
            <TailwindInput
              rounded={false}
              backgroundColor={"transparent"}
              borderColor={"border-black"}
              borderWidth={["border"]}
              label={intl.formatMessage({
                id: "landing.modelSetup.otherName",
                defaultMessage: "Other Name",
              })}
              errorProps={{
                textAlign: "text-right",
              }}
              value={other_name || ""}
              type={TailwindInputType.text}
              name={"other_name"}
              error={errors?.other_name || undefined}
              onChange={e => {
                clearError("other_name");
                updateRegistrationFormField("model", e.target);
              }}
              onBlur={_e =>
                validate("other_name", other_name, {
                  canBeEmpty: true,
                })
              }
            />
          </TailwindFlex>
        </TailwindFlex>
        <TailwindFlex margin={["mb-4"]} width={"w-full"}>
          <TailwindInput
            required
            maxLength={16}
            rounded={false}
            backgroundColor={"transparent"}
            borderColor={"border-black"}
            borderWidth={["border"]}
            label={intl.formatMessage({
              id: "landing.createAccount.username",
              defaultMessage: "username",
            })}
            errorProps={{
              textAlign: "text-right",
            }}
            value={username || ""}
            type={TailwindInputType.text}
            name={"username"}
            error={errors?.username || undefined}
            onChange={e => {
              clearError("username");
              updateRegistrationFormField("model", e.target);
            }}
            onBlur={e => validate("username", e.target.value)}
          />
        </TailwindFlex>

        <TailwindFlex margin={["mb-4"]} width={"w-full"}>
          <TailwindInput
            required
            rounded={false}
            backgroundColor={"transparent"}
            borderColor={"border-black"}
            borderWidth={["border"]}
            label={intl.formatMessage({
              id: "common.birthdate",
              defaultMessage: "Birthdate",
            })}
            placeholder={"YYYY/MM/DD"}
            placeholderColor={"placeholder-gray-500"}
            errorProps={{
              textAlign: "text-right",
            }}
            max={DateProcess.eighteenYearsBeforeToday}
            value={birthdate || DateProcess.eighteenYearsBeforeToday}
            type={TailwindInputType.date}
            name={"birthdate"}
            error={errors?.birthdate || undefined}
            onChange={e => updateRegistrationFormField("model", e.target)}
            onBlur={e => validate("birthdate", e.target.value)}
          />
        </TailwindFlex>
        <TailwindFlex margin={["mb-6"]} width={"w-full"}>
          <TailwindDropdown
            focusBorderColor={"md:border-black"}
            backgroundColor={"bg-primary-bg-color"}
            borderColor={"border-black"}
            borderWidth={["border"]}
            fullWidth
            items={[
              {
                label: intl.formatMessage({
                  id: "landing.accountInfo.gender.male",
                  defaultMessage: "Male",
                }),
                value: "male",
              },
              {
                label: intl.formatMessage({
                  id: "landing.accountInfo.gender.female",
                  defaultMessage: "Female",
                }),
                value: "female",
              },
              {
                label: intl.formatMessage({
                  id: "landing.accountInfo.gender.transFluis",
                  defaultMessage: "Trans/fluid",
                }),
                value: "trans",
              },
            ]}
            label={intl.formatMessage({
              id: "landing.accountInfo.gender.title",
              defaultMessage: "Gender",
            })}
            value={gender || ""}
            name={"gender"}
            error={errors?.gender || undefined}
            onChange={value =>
              updateRegistrationFormField("model", {
                name: "gender",
                value,
              })
            }
            onClick={e => e.preventDefault()}
          />
        </TailwindFlex>
        <TailwindFlex width={"w-full"} margin={["mb-2"]}>
          <TailwindTranslatedText
            textColor='text-main-color'
            descriptor={{
              id: "landing.accountInfo.ifYouHAReferral",
              defaultMessage: "If you have a referral code, enter it here.",
            }}
          />
        </TailwindFlex>
        <TailwindFlex margin={["mb-10"]} width={"w-full"}>
          <TailwindInput
            rounded={false}
            backgroundColor={"transparent"}
            borderColor={"border-black"}
            borderWidth={["border"]}
            label={intl.formatMessage({
              id: "landing.accountInfo.referralCode",
              defaultMessage: "Referral Code (Optional)",
            })}
            errorProps={{
              textAlign: "text-right",
            }}
            value={promo_code || ""}
            type={TailwindInputType.text}
            name={"promo_code"}
            error={errors?.promo_code || undefined}
            onChange={e => {
              clearError("promo_code");
              updateRegistrationFormField("model", e.target);
            }}
            onBlur={_e =>
              validate("promo_code", promo_code, {
                canBeEmpty: true,
              })
            }
          />
        </TailwindFlex>
        <TailwindFlex
          alignItems={"items-center"}
          justifyContent={"justify-between"}>
          <TailwindButton
            showSpinner={authStore!.isActionInProgress}
            disabled={authStore!.isActionInProgress}
            fullWidth
            size={"lg"}
            backgroundColor={"primary"}
            type={"submit"}
            justifyContent={"justify-center"}
            textColor={"text-white"}
            rounded={false}>
            <TailwindTranslatedText
              descriptor={{
                id: "common.next",
                defaultMessage: "Next",
              }}
              textTransform={"uppercase"}
              fontWeight={"font-bold"}
            />
          </TailwindButton>
        </TailwindFlex>
      </RecaptchaForm>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject(
    "authStore",
    "validationStore",
    "profileStore"
  )(observer(ModelAccountInfoStep))
);
