/* eslint-disable */
/* tslint:disable */

//////////////////////////////////////////////////////////////////////////////
/// Autogenerated by tailwindcss-classnames CLI. https://git.io/JtsPU
/// DO NOT EDIT THIS FILE DIRECTLY!
//////////////////////////////////////////////////////////////////////////////

import classnamesLib from "clsx";

export type TScreenReaders = "sr-only" | "not-sr-only";

export type TAccessibility = TScreenReaders;

export type TBackgroundAttachment = "bg-fixed" | "bg-local" | "bg-scroll";

export type TBackgroundClip =
  | "bg-clip-border"
  | "bg-clip-padding"
  | "bg-clip-content"
  | "bg-clip-text";

export type TBackgroundOrigin =
  | "bg-origin-border"
  | "bg-origin-padding"
  | "bg-origin-content";

export type TBackgroundRepeat =
  | "bg-repeat"
  | "bg-no-repeat"
  | "bg-repeat-x"
  | "bg-repeat-y"
  | "bg-repeat-round"
  | "bg-repeat-space";

export type TBackgroundOpacity =
  | "bg-opacity-0"
  | "bg-opacity-5"
  | "bg-opacity-10"
  | "bg-opacity-20"
  | "bg-opacity-25"
  | "bg-opacity-30"
  | "bg-opacity-40"
  | "bg-opacity-50"
  | "bg-opacity-60"
  | "bg-opacity-70"
  | "bg-opacity-75"
  | "bg-opacity-80"
  | "bg-opacity-90"
  | "bg-opacity-95"
  | "bg-opacity-100";

export type TBackgroundColor =
  | "bg-transparent"
  | "bg-current"
  | "bg-black"
  | "bg-white"
  | "bg-gray-50"
  | "bg-gray-100"
  | "bg-gray-200"
  | "bg-gray-300"
  | "bg-gray-400"
  | "bg-gray-500"
  | "bg-gray-600"
  | "bg-gray-700"
  | "bg-gray-800"
  | "bg-gray-900"
  | "bg-red-50"
  | "bg-red-100"
  | "bg-red-200"
  | "bg-red-300"
  | "bg-red-400"
  | "bg-red-500"
  | "bg-red-600"
  | "bg-red-700"
  | "bg-red-800"
  | "bg-red-900"
  | "bg-yellow-50"
  | "bg-yellow-100"
  | "bg-yellow-200"
  | "bg-yellow-300"
  | "bg-yellow-400"
  | "bg-yellow-500"
  | "bg-yellow-600"
  | "bg-yellow-700"
  | "bg-yellow-800"
  | "bg-yellow-900"
  | "bg-green-50"
  | "bg-green-100"
  | "bg-green-200"
  | "bg-green-300"
  | "bg-green-400"
  | "bg-green-500"
  | "bg-green-600"
  | "bg-green-700"
  | "bg-green-800"
  | "bg-green-900"
  | "bg-blue-50"
  | "bg-blue-100"
  | "bg-blue-200"
  | "bg-blue-300"
  | "bg-blue-400"
  | "bg-blue-500"
  | "bg-blue-600"
  | "bg-blue-700"
  | "bg-blue-800"
  | "bg-blue-900"
  | "bg-indigo-50"
  | "bg-indigo-100"
  | "bg-indigo-200"
  | "bg-indigo-300"
  | "bg-indigo-400"
  | "bg-indigo-500"
  | "bg-indigo-600"
  | "bg-indigo-700"
  | "bg-indigo-800"
  | "bg-indigo-900"
  | "bg-purple-50"
  | "bg-purple-100"
  | "bg-purple-200"
  | "bg-purple-300"
  | "bg-purple-400"
  | "bg-purple-500"
  | "bg-purple-600"
  | "bg-purple-700"
  | "bg-purple-800"
  | "bg-purple-900"
  | "bg-pink-50"
  | "bg-pink-100"
  | "bg-pink-200"
  | "bg-pink-300"
  | "bg-pink-400"
  | "bg-pink-500"
  | "bg-pink-600"
  | "bg-pink-700"
  | "bg-pink-800"
  | "bg-pink-900"
  | "bg-title-color-1"
  | "bg-primary-bg-color"
  | "bg-secondary-bg-color"
  | "bg-sidebar-bg-color"
  | "bg-header-bg-color"
  | "bg-body-bg-color"
  | "bg-main-color"
  | "bg-secondary-color"
  | "bg-tertiary-bg-color"
  | "bg-quaternary-bg-color"
  | "bg-highlight-color"
  | "bg-avatar-primary-bg-color"
  | "bg-avatar-secondary-bg-color"
  | "bg-earnings-bg-color"
  | "bg-card-secondary-bg-color"
  | "bg-pink-color"
  | "bg-go-live-text"
  | "bg-go-live-bg-color"
  | "bg-gray-button"
  | "bg-toggle-disabled"
  | "bg-tab-not-selected-color"
  | "bg-text-area-bg-color"
  | "bg-table-primary-bg-color"
  | "bg-table-secondary-color"
  | "bg-modal-button-color"
  | "bg-lightGreen-bg-color"
  | "bg-lightPink-bg-color"
  | "bg-lightRed-bg-color"
  | "bg-lightGray-bg-color"
  | "bg-lightBlue"
  | "bg-lightPurple-bg-color";

export type TBackgroundPosition =
  | "bg-bottom"
  | "bg-center"
  | "bg-left"
  | "bg-left-bottom"
  | "bg-left-top"
  | "bg-right"
  | "bg-right-bottom"
  | "bg-right-top"
  | "bg-top";

export type TBackgroundSize = "bg-auto" | "bg-cover" | "bg-contain";

export type TBackgroundImage =
  | "bg-none"
  | "bg-gradient-to-t"
  | "bg-gradient-to-tr"
  | "bg-gradient-to-r"
  | "bg-gradient-to-br"
  | "bg-gradient-to-b"
  | "bg-gradient-to-bl"
  | "bg-gradient-to-l"
  | "bg-gradient-to-tl";

export type TGradientColorStops =
  | "from-transparent"
  | "via-transparent"
  | "to-transparent"
  | "from-current"
  | "via-current"
  | "to-current"
  | "from-black"
  | "via-black"
  | "to-black"
  | "from-white"
  | "via-white"
  | "to-white"
  | "from-gray-50"
  | "via-gray-50"
  | "to-gray-50"
  | "from-gray-100"
  | "via-gray-100"
  | "to-gray-100"
  | "from-gray-200"
  | "via-gray-200"
  | "to-gray-200"
  | "from-gray-300"
  | "via-gray-300"
  | "to-gray-300"
  | "from-gray-400"
  | "via-gray-400"
  | "to-gray-400"
  | "from-gray-500"
  | "via-gray-500"
  | "to-gray-500"
  | "from-gray-600"
  | "via-gray-600"
  | "to-gray-600"
  | "from-gray-700"
  | "via-gray-700"
  | "to-gray-700"
  | "from-gray-800"
  | "via-gray-800"
  | "to-gray-800"
  | "from-gray-900"
  | "via-gray-900"
  | "to-gray-900"
  | "from-red-50"
  | "via-red-50"
  | "to-red-50"
  | "from-red-100"
  | "via-red-100"
  | "to-red-100"
  | "from-red-200"
  | "via-red-200"
  | "to-red-200"
  | "from-red-300"
  | "via-red-300"
  | "to-red-300"
  | "from-red-400"
  | "via-red-400"
  | "to-red-400"
  | "from-red-500"
  | "via-red-500"
  | "to-red-500"
  | "from-red-600"
  | "via-red-600"
  | "to-red-600"
  | "from-red-700"
  | "via-red-700"
  | "to-red-700"
  | "from-red-800"
  | "via-red-800"
  | "to-red-800"
  | "from-red-900"
  | "via-red-900"
  | "to-red-900"
  | "from-yellow-50"
  | "via-yellow-50"
  | "to-yellow-50"
  | "from-yellow-100"
  | "via-yellow-100"
  | "to-yellow-100"
  | "from-yellow-200"
  | "via-yellow-200"
  | "to-yellow-200"
  | "from-yellow-300"
  | "via-yellow-300"
  | "to-yellow-300"
  | "from-yellow-400"
  | "via-yellow-400"
  | "to-yellow-400"
  | "from-yellow-500"
  | "via-yellow-500"
  | "to-yellow-500"
  | "from-yellow-600"
  | "via-yellow-600"
  | "to-yellow-600"
  | "from-yellow-700"
  | "via-yellow-700"
  | "to-yellow-700"
  | "from-yellow-800"
  | "via-yellow-800"
  | "to-yellow-800"
  | "from-yellow-900"
  | "via-yellow-900"
  | "to-yellow-900"
  | "from-green-50"
  | "via-green-50"
  | "to-green-50"
  | "from-green-100"
  | "via-green-100"
  | "to-green-100"
  | "from-green-200"
  | "via-green-200"
  | "to-green-200"
  | "from-green-300"
  | "via-green-300"
  | "to-green-300"
  | "from-green-400"
  | "via-green-400"
  | "to-green-400"
  | "from-green-500"
  | "via-green-500"
  | "to-green-500"
  | "from-green-600"
  | "via-green-600"
  | "to-green-600"
  | "from-green-700"
  | "via-green-700"
  | "to-green-700"
  | "from-green-800"
  | "via-green-800"
  | "to-green-800"
  | "from-green-900"
  | "via-green-900"
  | "to-green-900"
  | "from-blue-50"
  | "via-blue-50"
  | "to-blue-50"
  | "from-blue-100"
  | "via-blue-100"
  | "to-blue-100"
  | "from-blue-200"
  | "via-blue-200"
  | "to-blue-200"
  | "from-blue-300"
  | "via-blue-300"
  | "to-blue-300"
  | "from-blue-400"
  | "via-blue-400"
  | "to-blue-400"
  | "from-blue-500"
  | "via-blue-500"
  | "to-blue-500"
  | "from-blue-600"
  | "via-blue-600"
  | "to-blue-600"
  | "from-blue-700"
  | "via-blue-700"
  | "to-blue-700"
  | "from-blue-800"
  | "via-blue-800"
  | "to-blue-800"
  | "from-blue-900"
  | "via-blue-900"
  | "to-blue-900"
  | "from-indigo-50"
  | "via-indigo-50"
  | "to-indigo-50"
  | "from-indigo-100"
  | "via-indigo-100"
  | "to-indigo-100"
  | "from-indigo-200"
  | "via-indigo-200"
  | "to-indigo-200"
  | "from-indigo-300"
  | "via-indigo-300"
  | "to-indigo-300"
  | "from-indigo-400"
  | "via-indigo-400"
  | "to-indigo-400"
  | "from-indigo-500"
  | "via-indigo-500"
  | "to-indigo-500"
  | "from-indigo-600"
  | "via-indigo-600"
  | "to-indigo-600"
  | "from-indigo-700"
  | "via-indigo-700"
  | "to-indigo-700"
  | "from-indigo-800"
  | "via-indigo-800"
  | "to-indigo-800"
  | "from-indigo-900"
  | "via-indigo-900"
  | "to-indigo-900"
  | "from-purple-50"
  | "via-purple-50"
  | "to-purple-50"
  | "from-purple-100"
  | "via-purple-100"
  | "to-purple-100"
  | "from-purple-200"
  | "via-purple-200"
  | "to-purple-200"
  | "from-purple-300"
  | "via-purple-300"
  | "to-purple-300"
  | "from-purple-400"
  | "via-purple-400"
  | "to-purple-400"
  | "from-purple-500"
  | "via-purple-500"
  | "to-purple-500"
  | "from-purple-600"
  | "via-purple-600"
  | "to-purple-600"
  | "from-purple-700"
  | "via-purple-700"
  | "to-purple-700"
  | "from-purple-800"
  | "via-purple-800"
  | "to-purple-800"
  | "from-purple-900"
  | "via-purple-900"
  | "to-purple-900"
  | "from-pink-50"
  | "via-pink-50"
  | "to-pink-50"
  | "from-pink-100"
  | "via-pink-100"
  | "to-pink-100"
  | "from-pink-200"
  | "via-pink-200"
  | "to-pink-200"
  | "from-pink-300"
  | "via-pink-300"
  | "to-pink-300"
  | "from-pink-400"
  | "via-pink-400"
  | "to-pink-400"
  | "from-pink-500"
  | "via-pink-500"
  | "to-pink-500"
  | "from-pink-600"
  | "via-pink-600"
  | "to-pink-600"
  | "from-pink-700"
  | "via-pink-700"
  | "to-pink-700"
  | "from-pink-800"
  | "via-pink-800"
  | "to-pink-800"
  | "from-pink-900"
  | "via-pink-900"
  | "to-pink-900"
  | "from-title-color-1"
  | "via-title-color-1"
  | "to-title-color-1"
  | "from-primary-bg-color"
  | "via-primary-bg-color"
  | "to-primary-bg-color"
  | "from-secondary-bg-color"
  | "via-secondary-bg-color"
  | "to-secondary-bg-color"
  | "from-sidebar-bg-color"
  | "via-sidebar-bg-color"
  | "to-sidebar-bg-color"
  | "from-header-bg-color"
  | "via-header-bg-color"
  | "to-header-bg-color"
  | "from-body-bg-color"
  | "via-body-bg-color"
  | "to-body-bg-color"
  | "from-main-color"
  | "via-main-color"
  | "to-main-color"
  | "from-tertiary-bg-color"
  | "via-tertiary-bg-color"
  | "to-tertiary-bg-color"
  | "from-quaternary-bg-color"
  | "via-quaternary-bg-color"
  | "to-quaternary-bg-color"
  | "from-earnings-bg-color"
  | "via-earnings-bg-color"
  | "to-earnings-bg-color"
  | "from-card-secondary-bg-color"
  | "via-card-secondary-bg-color"
  | "to-card-secondary-bg-color"
  | "from-pink-color"
  | "via-pink-color"
  | "to-pink-color"
  | "from-go-live-text"
  | "via-go-live-text"
  | "to-go-live-text"
  | "from-go-live-bg-color"
  | "via-go-live-bg-color"
  | "to-go-live-bg-color"
  | "from-gray-button"
  | "via-gray-button"
  | "to-gray-button"
  | "from-toggle-disabled"
  | "via-toggle-disabled"
  | "to-toggle-disabled"
  | "from-tab-not-selected-color"
  | "via-tab-not-selected-color"
  | "to-tab-not-selected-color"
  | "from-text-area-bg-color"
  | "via-text-area-bg-color"
  | "to-text-area-bg-color"
  | "from-table-primary-bg-color"
  | "via-table-primary-bg-color"
  | "to-table-primary-bg-color"
  | "from-table-secondary-color"
  | "via-table-secondary-color"
  | "to-table-secondary-color"
  | "from-modal-button-color"
  | "via-modal-button-color"
  | "to-modal-button-color";

export type TBackgrounds =
  | TBackgroundAttachment
  | TBackgroundClip
  | TBackgroundOrigin
  | TBackgroundRepeat
  | TBackgroundOpacity
  | TBackgroundColor
  | TBackgroundPosition
  | TBackgroundSize
  | TBackgroundImage
  | TGradientColorStops;

export type TBorderStyle =
  | "border-solid"
  | "border-dashed"
  | "border-dotted"
  | "border-double"
  | "border-none";

export type TDivideStyle =
  | "divide-solid"
  | "divide-dashed"
  | "divide-dotted"
  | "divide-double"
  | "divide-none";

export type TBorderColor =
  | "border-transparent"
  | "border-current"
  | "border-black"
  | "border-white"
  | "border-gray-50"
  | "border-gray-100"
  | "border-gray-200"
  | "border-gray-300"
  | "border-gray-400"
  | "border-gray-500"
  | "border-gray-600"
  | "border-gray-700"
  | "border-gray-800"
  | "border-gray-900"
  | "border-red-50"
  | "border-red-100"
  | "border-red-200"
  | "border-red-300"
  | "border-red-400"
  | "border-red-500"
  | "border-red-600"
  | "border-red-700"
  | "border-red-800"
  | "border-red-900"
  | "border-yellow-50"
  | "border-yellow-100"
  | "border-yellow-200"
  | "border-yellow-300"
  | "border-yellow-400"
  | "border-yellow-500"
  | "border-yellow-600"
  | "border-yellow-700"
  | "border-yellow-800"
  | "border-yellow-900"
  | "border-green-50"
  | "border-green-100"
  | "border-green-200"
  | "border-green-300"
  | "border-green-400"
  | "border-green-500"
  | "border-green-600"
  | "border-green-700"
  | "border-green-800"
  | "border-green-900"
  | "border-blue-50"
  | "border-blue-100"
  | "border-blue-200"
  | "border-blue-300"
  | "border-blue-400"
  | "border-blue-500"
  | "border-blue-600"
  | "border-blue-700"
  | "border-blue-800"
  | "border-blue-900"
  | "border-indigo-50"
  | "border-indigo-100"
  | "border-indigo-200"
  | "border-indigo-300"
  | "border-indigo-400"
  | "border-indigo-500"
  | "border-indigo-600"
  | "border-indigo-700"
  | "border-indigo-800"
  | "border-indigo-900"
  | "border-purple-50"
  | "border-purple-100"
  | "border-purple-200"
  | "border-purple-300"
  | "border-purple-400"
  | "border-purple-500"
  | "border-purple-600"
  | "border-purple-700"
  | "border-purple-800"
  | "border-purple-900"
  | "border-pink-50"
  | "border-pink-100"
  | "border-pink-200"
  | "border-pink-300"
  | "border-pink-400"
  | "border-pink-500"
  | "border-pink-600"
  | "border-pink-700"
  | "border-pink-800"
  | "border-pink-900"
  | "border-title-color-1"
  | "border-primary-bg-color"
  | "border-secondary-bg-color"
  | "border-sidebar-bg-color"
  | "border-header-bg-color"
  | "border-body-bg-color"
  | "border-main-color"
  | "border-tertiary-bg-color"
  | "border-quaternary-bg-color"
  | "border-highlight-color"
  | "border-earnings-bg-color"
  | "border-card-secondary-bg-color"
  | "border-pink-color"
  | "border-go-live-text"
  | "border-go-live-bg-color"
  | "border-gray-button"
  | "border-toggle-disabled"
  | "border-tab-not-selected-color"
  | "border-text-area-bg-color"
  | "border-table-primary-bg-color"
  | "border-table-secondary-color"
  | "border-modal-button-color";

export type TBorderOpacity =
  | "border-opacity-0"
  | "border-opacity-5"
  | "border-opacity-10"
  | "border-opacity-20"
  | "border-opacity-25"
  | "border-opacity-30"
  | "border-opacity-40"
  | "border-opacity-50"
  | "border-opacity-60"
  | "border-opacity-70"
  | "border-opacity-75"
  | "border-opacity-80"
  | "border-opacity-90"
  | "border-opacity-95"
  | "border-opacity-100";

export type TBorderRadius =
  | "rounded-t-none"
  | "rounded-r-none"
  | "rounded-b-none"
  | "rounded-l-none"
  | "rounded-tr-none"
  | "rounded-tl-none"
  | "rounded-br-none"
  | "rounded-bl-none"
  | "rounded-none"
  | "rounded-t-sm"
  | "rounded-r-sm"
  | "rounded-b-sm"
  | "rounded-l-sm"
  | "rounded-tr-sm"
  | "rounded-tl-sm"
  | "rounded-br-sm"
  | "rounded-bl-sm"
  | "rounded-sm"
  | "rounded-t"
  | "rounded-r"
  | "rounded-b"
  | "rounded-l"
  | "rounded-tr"
  | "rounded-tl"
  | "rounded-br"
  | "rounded-bl"
  | "rounded"
  | "rounded-t-md"
  | "rounded-r-md"
  | "rounded-b-md"
  | "rounded-l-md"
  | "rounded-tr-md"
  | "rounded-tl-md"
  | "rounded-br-md"
  | "rounded-bl-md"
  | "rounded-md"
  | "rounded-t-lg"
  | "rounded-r-lg"
  | "rounded-b-lg"
  | "rounded-l-lg"
  | "rounded-tr-lg"
  | "rounded-tl-lg"
  | "rounded-br-lg"
  | "rounded-bl-lg"
  | "rounded-lg"
  | "rounded-t-xl"
  | "rounded-r-xl"
  | "rounded-b-xl"
  | "rounded-l-xl"
  | "rounded-tr-xl"
  | "rounded-tl-xl"
  | "rounded-br-xl"
  | "rounded-bl-xl"
  | "rounded-xl"
  | "rounded-t-2xl"
  | "rounded-r-2xl"
  | "rounded-b-2xl"
  | "rounded-l-2xl"
  | "rounded-tr-2xl"
  | "rounded-tl-2xl"
  | "rounded-br-2xl"
  | "rounded-bl-2xl"
  | "rounded-2xl"
  | "rounded-t-3xl"
  | "rounded-r-3xl"
  | "rounded-b-3xl"
  | "rounded-l-3xl"
  | "rounded-tr-3xl"
  | "rounded-tl-3xl"
  | "rounded-br-3xl"
  | "rounded-bl-3xl"
  | "rounded-3xl"
  | "rounded-t-full"
  | "rounded-r-full"
  | "rounded-b-full"
  | "rounded-l-full"
  | "rounded-tr-full"
  | "rounded-tl-full"
  | "rounded-br-full"
  | "rounded-bl-full"
  | "rounded-full";

export type TBorderWidth =
  | "border-t-0"
  | "border-r-0"
  | "border-b-0"
  | "border-l-0"
  | "border-0"
  | "border-t-2"
  | "border-r-2"
  | "border-b-2"
  | "border-l-2"
  | "border-2"
  | "border-t-4"
  | "border-r-4"
  | "border-b-4"
  | "border-l-4"
  | "border-4"
  | "border-t-8"
  | "border-r-8"
  | "border-b-8"
  | "border-l-8"
  | "border-8"
  | "border-t"
  | "border-r"
  | "border-b"
  | "border-l"
  | "border";

export type TDivideColor =
  | "divide-transparent"
  | "divide-current"
  | "divide-black"
  | "divide-white"
  | "divide-gray-50"
  | "divide-gray-100"
  | "divide-gray-200"
  | "divide-gray-300"
  | "divide-gray-400"
  | "divide-gray-500"
  | "divide-gray-600"
  | "divide-gray-700"
  | "divide-gray-800"
  | "divide-gray-900"
  | "divide-red-50"
  | "divide-red-100"
  | "divide-red-200"
  | "divide-red-300"
  | "divide-red-400"
  | "divide-red-500"
  | "divide-red-600"
  | "divide-red-700"
  | "divide-red-800"
  | "divide-red-900"
  | "divide-yellow-50"
  | "divide-yellow-100"
  | "divide-yellow-200"
  | "divide-yellow-300"
  | "divide-yellow-400"
  | "divide-yellow-500"
  | "divide-yellow-600"
  | "divide-yellow-700"
  | "divide-yellow-800"
  | "divide-yellow-900"
  | "divide-green-50"
  | "divide-green-100"
  | "divide-green-200"
  | "divide-green-300"
  | "divide-green-400"
  | "divide-green-500"
  | "divide-green-600"
  | "divide-green-700"
  | "divide-green-800"
  | "divide-green-900"
  | "divide-blue-50"
  | "divide-blue-100"
  | "divide-blue-200"
  | "divide-blue-300"
  | "divide-blue-400"
  | "divide-blue-500"
  | "divide-blue-600"
  | "divide-blue-700"
  | "divide-blue-800"
  | "divide-blue-900"
  | "divide-indigo-50"
  | "divide-indigo-100"
  | "divide-indigo-200"
  | "divide-indigo-300"
  | "divide-indigo-400"
  | "divide-indigo-500"
  | "divide-indigo-600"
  | "divide-indigo-700"
  | "divide-indigo-800"
  | "divide-indigo-900"
  | "divide-purple-50"
  | "divide-purple-100"
  | "divide-purple-200"
  | "divide-purple-300"
  | "divide-purple-400"
  | "divide-purple-500"
  | "divide-purple-600"
  | "divide-purple-700"
  | "divide-purple-800"
  | "divide-purple-900"
  | "divide-pink-50"
  | "divide-pink-100"
  | "divide-pink-200"
  | "divide-pink-300"
  | "divide-pink-400"
  | "divide-pink-500"
  | "divide-pink-600"
  | "divide-pink-700"
  | "divide-pink-800"
  | "divide-pink-900";

export type TDivideOpacity =
  | "divide-opacity-0"
  | "divide-opacity-5"
  | "divide-opacity-10"
  | "divide-opacity-20"
  | "divide-opacity-25"
  | "divide-opacity-30"
  | "divide-opacity-40"
  | "divide-opacity-50"
  | "divide-opacity-60"
  | "divide-opacity-70"
  | "divide-opacity-75"
  | "divide-opacity-80"
  | "divide-opacity-90"
  | "divide-opacity-95"
  | "divide-opacity-100";

export type TDivideWidth =
  | "divide-x-0"
  | "divide-y-0"
  | "divide-x-2"
  | "divide-y-2"
  | "divide-x-4"
  | "divide-y-4"
  | "divide-x-8"
  | "divide-y-8"
  | "divide-x"
  | "divide-y"
  | "divide-x-reverse"
  | "divide-y-reverse";

export type TRingColor =
  | "ring-transparent"
  | "ring-current"
  | "ring-black"
  | "ring-white"
  | "ring-gray"
  | "ring-red-50"
  | "ring-red-100"
  | "ring-red-200"
  | "ring-red-300"
  | "ring-red-400"
  | "ring-red-500"
  | "ring-red-600"
  | "ring-red-700"
  | "ring-red-800"
  | "ring-red-900"
  | "ring-yellow-50"
  | "ring-yellow-100"
  | "ring-yellow-200"
  | "ring-yellow-300"
  | "ring-yellow-400"
  | "ring-yellow-500"
  | "ring-yellow-600"
  | "ring-yellow-700"
  | "ring-yellow-800"
  | "ring-yellow-900"
  | "ring-green-50"
  | "ring-green-100"
  | "ring-green-200"
  | "ring-green-300"
  | "ring-green-400"
  | "ring-green-500"
  | "ring-green-600"
  | "ring-green-700"
  | "ring-green-800"
  | "ring-green-900"
  | "ring-blue-50"
  | "ring-blue-100"
  | "ring-blue-200"
  | "ring-blue-300"
  | "ring-blue-400"
  | "ring-blue-500"
  | "ring-blue-600"
  | "ring-blue-700"
  | "ring-blue-800"
  | "ring-blue-900"
  | "ring-indigo-50"
  | "ring-indigo-100"
  | "ring-indigo-200"
  | "ring-indigo-300"
  | "ring-indigo-400"
  | "ring-indigo-500"
  | "ring-indigo-600"
  | "ring-indigo-700"
  | "ring-indigo-800"
  | "ring-indigo-900"
  | "ring-purple-50"
  | "ring-purple-100"
  | "ring-purple-200"
  | "ring-purple-300"
  | "ring-purple-400"
  | "ring-purple-500"
  | "ring-purple-600"
  | "ring-purple-700"
  | "ring-purple-800"
  | "ring-purple-900"
  | "ring-pink-50"
  | "ring-pink-100"
  | "ring-pink-200"
  | "ring-pink-300"
  | "ring-pink-400"
  | "ring-pink-500"
  | "ring-pink-600"
  | "ring-pink-700"
  | "ring-pink-800"
  | "ring-pink-900"
  | "ring-title-color-1-50"
  | "ring-title-color-1-100"
  | "ring-title-color-1-200"
  | "ring-title-color-1-300"
  | "ring-title-color-1-400"
  | "ring-title-color-1-500"
  | "ring-title-color-1-600"
  | "ring-title-color-1-700"
  | "ring-title-color-1-800"
  | "ring-title-color-1-900"
  | "ring-primary-bg-color"
  | "ring-secondary-bg-color"
  | "ring-sidebar-bg-color"
  | "ring-header-bg-color"
  | "ring-body-bg-color"
  | "ring-main-color"
  | "ring-tertiary-bg-color"
  | "ring-quaternary-bg-color"
  | "ring-earnings-bg-color"
  | "ring-card-secondary-bg-color"
  | "ring-pink-color"
  | "ring-go-live-text"
  | "ring-go-live-bg-color"
  | "ring-gray-button"
  | "ring-toggle-disabled"
  | "ring-tab-not-selected-color"
  | "ring-text-area-bg-color"
  | "ring-table-primary-bg-color"
  | "ring-table-secondary-color"
  | "ring-modal-button-color";

export type TRingWidth =
  | "ring-0"
  | "ring-1"
  | "ring-2"
  | "ring-4"
  | "ring-8"
  | "ring"
  | "inset";

export type TRingOpacity =
  | "ring-opacity-0"
  | "ring-opacity-5"
  | "ring-opacity-10"
  | "ring-opacity-20"
  | "ring-opacity-25"
  | "ring-opacity-30"
  | "ring-opacity-40"
  | "ring-opacity-50"
  | "ring-opacity-60"
  | "ring-opacity-70"
  | "ring-opacity-75"
  | "ring-opacity-80"
  | "ring-opacity-90"
  | "ring-opacity-95"
  | "ring-opacity-100"
  | "ring-opacity";

export type TRingOffsetColor =
  | "ring-offset-transparent"
  | "ring-offset-current"
  | "ring-offset-black"
  | "ring-offset-white"
  | "ring-offset-gray-50"
  | "ring-offset-gray-100"
  | "ring-offset-gray-200"
  | "ring-offset-gray-300"
  | "ring-offset-gray-400"
  | "ring-offset-gray-500"
  | "ring-offset-gray-600"
  | "ring-offset-gray-700"
  | "ring-offset-gray-800"
  | "ring-offset-gray-900"
  | "ring-offset-red-50"
  | "ring-offset-red-100"
  | "ring-offset-red-200"
  | "ring-offset-red-300"
  | "ring-offset-red-400"
  | "ring-offset-red-500"
  | "ring-offset-red-600"
  | "ring-offset-red-700"
  | "ring-offset-red-800"
  | "ring-offset-red-900"
  | "ring-offset-yellow-50"
  | "ring-offset-yellow-100"
  | "ring-offset-yellow-200"
  | "ring-offset-yellow-300"
  | "ring-offset-yellow-400"
  | "ring-offset-yellow-500"
  | "ring-offset-yellow-600"
  | "ring-offset-yellow-700"
  | "ring-offset-yellow-800"
  | "ring-offset-yellow-900"
  | "ring-offset-green-50"
  | "ring-offset-green-100"
  | "ring-offset-green-200"
  | "ring-offset-green-300"
  | "ring-offset-green-400"
  | "ring-offset-green-500"
  | "ring-offset-green-600"
  | "ring-offset-green-700"
  | "ring-offset-green-800"
  | "ring-offset-green-900"
  | "ring-offset-blue-50"
  | "ring-offset-blue-100"
  | "ring-offset-blue-200"
  | "ring-offset-blue-300"
  | "ring-offset-blue-400"
  | "ring-offset-blue-500"
  | "ring-offset-blue-600"
  | "ring-offset-blue-700"
  | "ring-offset-blue-800"
  | "ring-offset-blue-900"
  | "ring-offset-indigo-50"
  | "ring-offset-indigo-100"
  | "ring-offset-indigo-200"
  | "ring-offset-indigo-300"
  | "ring-offset-indigo-400"
  | "ring-offset-indigo-500"
  | "ring-offset-indigo-600"
  | "ring-offset-indigo-700"
  | "ring-offset-indigo-800"
  | "ring-offset-indigo-900"
  | "ring-offset-purple-50"
  | "ring-offset-purple-100"
  | "ring-offset-purple-200"
  | "ring-offset-purple-300"
  | "ring-offset-purple-400"
  | "ring-offset-purple-500"
  | "ring-offset-purple-600"
  | "ring-offset-purple-700"
  | "ring-offset-purple-800"
  | "ring-offset-purple-900"
  | "ring-offset-pink-50"
  | "ring-offset-pink-100"
  | "ring-offset-pink-200"
  | "ring-offset-pink-300"
  | "ring-offset-pink-400"
  | "ring-offset-pink-500"
  | "ring-offset-pink-600"
  | "ring-offset-pink-700"
  | "ring-offset-pink-800"
  | "ring-offset-pink-900"
  | "ring-offset-title-color-1"
  | "ring-offset-primary-bg-color"
  | "ring-offset-secondary-bg-color"
  | "ring-offset-sidebar-bg-color"
  | "ring-offset-header-bg-color"
  | "ring-offset-body-bg-color"
  | "ring-offset-main-color"
  | "ring-offset-tertiary-bg-color"
  | "ring-offset-quaternary-bg-color"
  | "ring-offset-earnings-bg-color"
  | "ring-offset-card-secondary-bg-color"
  | "ring-offset-pink-color"
  | "ring-offset-go-live-text"
  | "ring-offset-go-live-bg-color"
  | "ring-offset-gray-button"
  | "ring-offset-toggle-disabled"
  | "ring-offset-tab-not-selected-color"
  | "ring-offset-text-area-bg-color"
  | "ring-offset-table-primary-bg-color"
  | "ring-offset-table-secondary-color"
  | "ring-offset-modal-button-color";

export type TRingOffsetWidth =
  | "ring-offset-0"
  | "ring-offset-1"
  | "ring-offset-2"
  | "ring-offset-4"
  | "ring-offset-8";

export type TBorders =
  | TBorderStyle
  | TDivideStyle
  | TBorderColor
  | TBorderOpacity
  | TBorderRadius
  | TBorderWidth
  | TDivideColor
  | TDivideOpacity
  | TDivideWidth
  | TRingColor
  | TRingWidth
  | TRingOpacity
  | TRingOffsetColor
  | TRingOffsetWidth;

export type TBorderCollapse = "border-collapse" | "border-separate";

export type TTableLayout = "table-auto" | "table-fixed";

export type TTables = TBorderCollapse | TTableLayout;

export type TMixBlendMode =
  | "mix-blend-normal"
  | "mix-blend-multiply"
  | "mix-blend-screen"
  | "mix-blend-overlay"
  | "mix-blend-darken"
  | "mix-blend-lighten"
  | "mix-blend-color-dodge"
  | "mix-blend-color-burn"
  | "mix-blend-hard-light"
  | "mix-blend-soft-light"
  | "mix-blend-difference"
  | "mix-blend-exclusion"
  | "mix-blend-hue"
  | "mix-blend-saturation"
  | "mix-blend-color"
  | "mix-blend-luminosity";

export type TBackgroundBlendMode =
  | "bg-blend-normal"
  | "bg-blend-multiply"
  | "bg-blend-screen"
  | "bg-blend-overlay"
  | "bg-blend-darken"
  | "bg-blend-lighten"
  | "bg-blend-color-dodge"
  | "bg-blend-color-burn"
  | "bg-blend-hard-light"
  | "bg-blend-soft-light"
  | "bg-blend-difference"
  | "bg-blend-exclusion"
  | "bg-blend-hue"
  | "bg-blend-saturation"
  | "bg-blend-color"
  | "bg-blend-luminosity";

export type TBoxShadow =
  | "shadow-sm"
  | "shadow"
  | "shadow-md"
  | "shadow-lg"
  | "shadow-xl"
  | "shadow-2xl"
  | "shadow-inner"
  | "shadow-none";

export type TOpacity =
  | "opacity-0"
  | "opacity-5"
  | "opacity-10"
  | "opacity-20"
  | "opacity-25"
  | "opacity-30"
  | "opacity-40"
  | "opacity-50"
  | "opacity-60"
  | "opacity-70"
  | "opacity-75"
  | "opacity-80"
  | "opacity-90"
  | "opacity-95"
  | "opacity-100";

export type TEffects =
  | TMixBlendMode
  | TBackgroundBlendMode
  | TBoxShadow
  | TOpacity;

export type TTransitionProperty =
  | "transition-none"
  | "transition-all"
  | "transition"
  | "transition-colors"
  | "transition-opacity"
  | "transition-shadow"
  | "transition-transform";

export type TTransitionDuration =
  | "duration-75"
  | "duration-100"
  | "duration-150"
  | "duration-200"
  | "duration-300"
  | "duration-500"
  | "duration-700"
  | "duration-1000"
  | "duration";

export type TTransitionTimingFunction =
  | "ease-linear"
  | "ease-in"
  | "ease-out"
  | "ease-in-out";

export type TTransitionDelay =
  | "delay-75"
  | "delay-100"
  | "delay-150"
  | "delay-200"
  | "delay-300"
  | "delay-500"
  | "delay-700"
  | "delay-1000";

export type TAnimation =
  | "animate-none"
  | "animate-spin"
  | "animate-ping"
  | "animate-pulse"
  | "animate-bounce";

export type TTransitionsAndAnimations =
  | TTransitionProperty
  | TTransitionDuration
  | TTransitionTimingFunction
  | TTransitionDelay
  | TAnimation;

export type TFilter = "filter" | "filter-none";

export type TBackdropFilter = "backdrop-filter" | "backdrop-filter-none";

export type TBlur =
  | "blur-0"
  | "blur-none"
  | "blur-sm"
  | "blur"
  | "blur-md"
  | "blur-lg"
  | "blur-xl"
  | "blur-2xl"
  | "blur-3xl";

export type TBrightness =
  | "brightness-0"
  | "brightness-50"
  | "brightness-75"
  | "brightness-90"
  | "brightness-95"
  | "brightness-100"
  | "brightness-105"
  | "brightness-110"
  | "brightness-125"
  | "brightness-150"
  | "brightness-200";

export type TContrast =
  | "contrast-0"
  | "contrast-50"
  | "contrast-75"
  | "contrast-100"
  | "contrast-125"
  | "contrast-150"
  | "contrast-200";

export type TDropShadow =
  | "drop-shadow-sm"
  | "drop-shadow"
  | "drop-shadow-md"
  | "drop-shadow-lg"
  | "drop-shadow-xl"
  | "drop-shadow-2xl"
  | "drop-shadow-none";

export type TGrayscale = "grayscale-0" | "grayscale";

export type THueRotate =
  | "hue-rotate-0"
  | "hue-rotate-15"
  | "hue-rotate-30"
  | "hue-rotate-60"
  | "hue-rotate-90"
  | "hue-rotate-180"
  | "hue-rotate--180"
  | "hue-rotate--90"
  | "hue-rotate--60"
  | "hue-rotate--30"
  | "hue-rotate--15";

export type TInvert = "invert-0" | "invert";

export type TSaturate =
  | "saturate-0"
  | "saturate-50"
  | "saturate-100"
  | "saturate-150"
  | "saturate-200";

export type TSepia = "sepia-0" | "sepia";

export type TBackdropBlur =
  | "backdrop-blur-0"
  | "backdrop-blur-none"
  | "backdrop-blur-sm"
  | "backdrop-blur"
  | "backdrop-blur-md"
  | "backdrop-blur-lg"
  | "backdrop-blur-xl"
  | "backdrop-blur-2xl"
  | "backdrop-blur-3xl";

export type TBackdropBrightness =
  | "backdrop-brightness-0"
  | "backdrop-brightness-50"
  | "backdrop-brightness-75"
  | "backdrop-brightness-90"
  | "backdrop-brightness-95"
  | "backdrop-brightness-100"
  | "backdrop-brightness-105"
  | "backdrop-brightness-110"
  | "backdrop-brightness-125"
  | "backdrop-brightness-150"
  | "backdrop-brightness-200";

export type TBackdropContrast =
  | "backdrop-contrast-0"
  | "backdrop-contrast-50"
  | "backdrop-contrast-75"
  | "backdrop-contrast-100"
  | "backdrop-contrast-125"
  | "backdrop-contrast-150"
  | "backdrop-contrast-200";

export type TBackdropGrayscale = "backdrop-grayscale-0" | "backdrop-grayscale";

export type TBackdropHueRotate =
  | "backdrop-hue-rotate-0"
  | "backdrop-hue-rotate-15"
  | "backdrop-hue-rotate-30"
  | "backdrop-hue-rotate-60"
  | "backdrop-hue-rotate-90"
  | "backdrop-hue-rotate-180"
  | "backdrop-hue-rotate--180"
  | "backdrop-hue-rotate--90"
  | "backdrop-hue-rotate--60"
  | "backdrop-hue-rotate--30"
  | "backdrop-hue-rotate--15";

export type TBackdropInvert = "backdrop-invert-0" | "backdrop-invert";

export type TBackdropOpacity =
  | "backdrop-opacity-0"
  | "backdrop-opacity-5"
  | "backdrop-opacity-10"
  | "backdrop-opacity-20"
  | "backdrop-opacity-25"
  | "backdrop-opacity-30"
  | "backdrop-opacity-40"
  | "backdrop-opacity-50"
  | "backdrop-opacity-60"
  | "backdrop-opacity-70"
  | "backdrop-opacity-75"
  | "backdrop-opacity-80"
  | "backdrop-opacity-90"
  | "backdrop-opacity-95"
  | "backdrop-opacity-100";

export type TBackdropSaturate =
  | "backdrop-saturate-0"
  | "backdrop-saturate-50"
  | "backdrop-saturate-100"
  | "backdrop-saturate-150"
  | "backdrop-saturate-200";

export type TBackdropSepia = "backdrop-sepia-0" | "backdrop-sepia";

export type TFilters =
  | TFilter
  | TBackdropFilter
  | TBlur
  | TBrightness
  | TContrast
  | TDropShadow
  | TGrayscale
  | THueRotate
  | TInvert
  | TSaturate
  | TSepia
  | TBackdropBlur
  | TBackdropBrightness
  | TBackdropContrast
  | TBackdropGrayscale
  | TBackdropHueRotate
  | TBackdropInvert
  | TBackdropOpacity
  | TBackdropSaturate
  | TBackdropSepia;

export type TFlexDirection =
  | "flex-row"
  | "flex-row-reverse"
  | "flex-col"
  | "flex-col-reverse";

export type TFlexWrap = "flex-nowrap" | "flex-wrap" | "flex-wrap-reverse";

export type TAlignItems =
  | "items-stretch"
  | "items-start"
  | "items-center"
  | "items-end"
  | "items-baseline";

export type TAlignContent =
  | "content-start"
  | "content-center"
  | "content-end"
  | "content-between"
  | "content-around";

export type TAlignSelf =
  | "self-auto"
  | "self-start"
  | "self-center"
  | "self-end"
  | "self-stretch"
  | "self-baseline";

export type TPlaceContent =
  | "place-content-start"
  | "place-content-center"
  | "place-content-end"
  | "place-content-between"
  | "place-content-around"
  | "place-content-evenly"
  | "place-content-stretch";

export type TPlaceItems =
  | "place-items-auto"
  | "place-items-start"
  | "place-items-center"
  | "place-items-end"
  | "place-items-stretch";

export type TPlaceSelf =
  | "place-self-auto"
  | "place-self-start"
  | "place-self-center"
  | "place-self-end"
  | "place-self-stretch";

export type TJustifyContent =
  | "justify-start"
  | "justify-center"
  | "justify-end"
  | "justify-between"
  | "justify-around";

export type TJustifyItems =
  | "justify-items-auto"
  | "justify-items-start"
  | "justify-items-center"
  | "justify-items-end"
  | "justify-items-stretch";

export type TJustifySelf =
  | "justify-self-auto"
  | "justify-self-start"
  | "justify-self-center"
  | "justify-self-end"
  | "justify-self-stretch";

export type TFlex = "flex-initial" | "flex-1" | "flex-auto" | "flex-none";

export type TFlexGrow = "flex-grow-0" | "flex-grow";

export type TFlexShrink = "flex-shrink-0" | "flex-shrink";

export type TOrder =
  | "order-1"
  | "order-2"
  | "order-3"
  | "order-4"
  | "order-5"
  | "order-6"
  | "order-7"
  | "order-8"
  | "order-9"
  | "order-10"
  | "order-11"
  | "order-12"
  | "order-first"
  | "order-last"
  | "order-none";

export type TFlexBox =
  | TFlexDirection
  | TFlexWrap
  | TAlignItems
  | TAlignContent
  | TAlignSelf
  | TPlaceContent
  | TPlaceItems
  | TPlaceSelf
  | TJustifyContent
  | TJustifyItems
  | TJustifySelf
  | TFlex
  | TFlexGrow
  | TFlexShrink
  | TOrder;

export type TGridAutoFlow =
  | "grid-flow-row"
  | "grid-flow-col"
  | "grid-flow-row-dense"
  | "grid-flow-col-dense";

export type TGridTemplateColumns =
  | "grid-cols-1"
  | "grid-cols-2"
  | "grid-cols-3"
  | "grid-cols-4"
  | "grid-cols-5"
  | "grid-cols-6"
  | "grid-cols-7"
  | "grid-cols-8"
  | "grid-cols-9"
  | "grid-cols-10"
  | "grid-cols-11"
  | "grid-cols-12"
  | "grid-cols-none";

export type TGridAutoColumns =
  | "auto-cols-auto"
  | "auto-cols-min"
  | "auto-cols-max"
  | "auto-cols-fr";

export type TGridColumn =
  | "col-auto"
  | "col-span-1"
  | "col-span-2"
  | "col-span-3"
  | "col-span-4"
  | "col-span-5"
  | "col-span-6"
  | "col-span-7"
  | "col-span-8"
  | "col-span-9"
  | "col-span-10"
  | "col-span-11"
  | "col-span-12"
  | "col-span-full";

export type TGridColumnStart =
  | "col-start-1"
  | "col-start-2"
  | "col-start-3"
  | "col-start-4"
  | "col-start-5"
  | "col-start-6"
  | "col-start-7"
  | "col-start-8"
  | "col-start-9"
  | "col-start-10"
  | "col-start-11"
  | "col-start-12"
  | "col-start-13"
  | "col-start-auto";

export type TGridColumnEnd =
  | "col-end-1"
  | "col-end-2"
  | "col-end-3"
  | "col-end-4"
  | "col-end-5"
  | "col-end-6"
  | "col-end-7"
  | "col-end-8"
  | "col-end-9"
  | "col-end-10"
  | "col-end-11"
  | "col-end-12"
  | "col-end-13"
  | "col-end-auto";

export type TGridTemplateRows =
  | "grid-rows-1"
  | "grid-rows-2"
  | "grid-rows-3"
  | "grid-rows-4"
  | "grid-rows-5"
  | "grid-rows-6"
  | "grid-rows-none"
  | "grid-rows-fit";

export type TGridAutoRows =
  | "auto-rows-auto"
  | "auto-rows-min"
  | "auto-rows-max"
  | "auto-rows-fr";

export type TGridRow =
  | "row-auto"
  | "row-span-1"
  | "row-span-2"
  | "row-span-3"
  | "row-span-4"
  | "row-span-5"
  | "row-span-6"
  | "row-span-full";

export type TGridRowStart =
  | "row-start-1"
  | "row-start-2"
  | "row-start-3"
  | "row-start-4"
  | "row-start-5"
  | "row-start-6"
  | "row-start-7"
  | "row-start-auto";

export type TGridRowEnd =
  | "row-end-1"
  | "row-end-2"
  | "row-end-3"
  | "row-end-4"
  | "row-end-5"
  | "row-end-6"
  | "row-end-7"
  | "row-end-auto";

export type TGap =
  | "gap-0"
  | "gap-1"
  | "gap-2"
  | "gap-3"
  | "gap-4"
  | "gap-5"
  | "gap-6"
  | "gap-7"
  | "gap-8"
  | "gap-9"
  | "gap-10"
  | "gap-11"
  | "gap-12"
  | "gap-14"
  | "gap-16"
  | "gap-20"
  | "gap-24"
  | "gap-28"
  | "gap-32"
  | "gap-36"
  | "gap-40"
  | "gap-44"
  | "gap-48"
  | "gap-52"
  | "gap-56"
  | "gap-60"
  | "gap-64"
  | "gap-72"
  | "gap-80"
  | "gap-96"
  | "gap-px"
  | "gap-0.5"
  | "gap-1.5"
  | "gap-2.5"
  | "gap-3.5"
  | "gap-y-0"
  | "gap-y-1"
  | "gap-y-2"
  | "gap-y-3"
  | "gap-y-4"
  | "gap-y-5"
  | "gap-y-6"
  | "gap-y-7"
  | "gap-y-8"
  | "gap-y-9"
  | "gap-y-10"
  | "gap-y-11"
  | "gap-y-12"
  | "gap-y-14"
  | "gap-y-16"
  | "gap-y-20"
  | "gap-y-24"
  | "gap-y-28"
  | "gap-y-32"
  | "gap-y-36"
  | "gap-y-40"
  | "gap-y-44"
  | "gap-y-48"
  | "gap-y-52"
  | "gap-y-56"
  | "gap-y-60"
  | "gap-y-64"
  | "gap-y-72"
  | "gap-y-80"
  | "gap-y-96"
  | "gap-y-px"
  | "gap-y-0.5"
  | "gap-y-1.5"
  | "gap-y-2.5"
  | "gap-y-3.5"
  | "gap-x-0"
  | "gap-x-1"
  | "gap-x-2"
  | "gap-x-3"
  | "gap-x-4"
  | "gap-x-5"
  | "gap-x-6"
  | "gap-x-7"
  | "gap-x-8"
  | "gap-x-9"
  | "gap-x-10"
  | "gap-x-11"
  | "gap-x-12"
  | "gap-x-14"
  | "gap-x-16"
  | "gap-x-20"
  | "gap-x-24"
  | "gap-x-28"
  | "gap-x-32"
  | "gap-x-36"
  | "gap-x-40"
  | "gap-x-44"
  | "gap-x-48"
  | "gap-x-52"
  | "gap-x-56"
  | "gap-x-60"
  | "gap-x-64"
  | "gap-x-72"
  | "gap-x-80"
  | "gap-x-96"
  | "gap-x-px"
  | "gap-x-0.5"
  | "gap-x-1.5"
  | "gap-x-2.5"
  | "gap-x-3.5";

export type TGrid =
  | TGridAutoFlow
  | TGridTemplateColumns
  | TGridAutoColumns
  | TGridColumn
  | TGridColumnStart
  | TGridColumnEnd
  | TGridTemplateRows
  | TGridAutoRows
  | TGridRow
  | TGridRowStart
  | TGridRowEnd
  | TGap;

export type TPadding =
  | "p-0"
  | "p-1"
  | "p-2"
  | "p-3"
  | "p-4"
  | "p-5"
  | "p-6"
  | "p-7"
  | "p-8"
  | "p-9"
  | "p-10"
  | "p-11"
  | "p-12"
  | "p-14"
  | "p-16"
  | "p-20"
  | "p-24"
  | "p-28"
  | "p-32"
  | "p-36"
  | "p-40"
  | "p-44"
  | "p-48"
  | "p-52"
  | "p-56"
  | "p-60"
  | "p-64"
  | "p-72"
  | "p-80"
  | "p-96"
  | "p-px"
  | "p-0.5"
  | "p-1.5"
  | "p-2.5"
  | "p-3.5"
  | "py-0"
  | "py-1"
  | "py-2"
  | "py-3"
  | "py-4"
  | "py-5"
  | "py-6"
  | "py-7"
  | "py-8"
  | "py-9"
  | "py-10"
  | "py-11"
  | "py-12"
  | "py-14"
  | "py-16"
  | "py-20"
  | "py-24"
  | "py-28"
  | "py-32"
  | "py-36"
  | "py-40"
  | "py-44"
  | "py-48"
  | "py-52"
  | "py-56"
  | "py-60"
  | "py-64"
  | "py-72"
  | "py-80"
  | "py-96"
  | "py-px"
  | "py-0.5"
  | "py-1.5"
  | "py-2.5"
  | "py-3.5"
  | "px-0"
  | "px-1"
  | "px-2"
  | "px-3"
  | "px-4"
  | "px-5"
  | "px-6"
  | "px-7"
  | "px-8"
  | "px-9"
  | "px-10"
  | "px-11"
  | "px-12"
  | "px-14"
  | "px-16"
  | "px-20"
  | "px-24"
  | "px-28"
  | "px-32"
  | "px-36"
  | "px-40"
  | "px-44"
  | "px-48"
  | "px-52"
  | "px-56"
  | "px-60"
  | "px-64"
  | "px-72"
  | "px-80"
  | "px-96"
  | "px-px"
  | "px-0.5"
  | "px-1.5"
  | "px-2.5"
  | "px-3.5"
  | "pt-0"
  | "pt-1"
  | "pt-2"
  | "pt-3"
  | "pt-4"
  | "pt-5"
  | "pt-6"
  | "pt-7"
  | "pt-8"
  | "pt-9"
  | "pt-10"
  | "pt-11"
  | "pt-12"
  | "pt-14"
  | "pt-16"
  | "pt-20"
  | "pt-24"
  | "pt-28"
  | "pt-32"
  | "pt-36"
  | "pt-40"
  | "pt-44"
  | "pt-48"
  | "pt-52"
  | "pt-56"
  | "pt-60"
  | "pt-64"
  | "pt-72"
  | "pt-80"
  | "pt-96"
  | "pt-px"
  | "pt-0.5"
  | "pt-1.5"
  | "pt-2.5"
  | "pt-3.5"
  | "pr-0"
  | "pr-1"
  | "pr-2"
  | "pr-3"
  | "pr-4"
  | "pr-5"
  | "pr-6"
  | "pr-7"
  | "pr-8"
  | "pr-9"
  | "pr-10"
  | "pr-11"
  | "pr-12"
  | "pr-14"
  | "pr-16"
  | "pr-20"
  | "pr-24"
  | "pr-28"
  | "pr-32"
  | "pr-36"
  | "pr-40"
  | "pr-44"
  | "pr-48"
  | "pr-52"
  | "pr-56"
  | "pr-60"
  | "pr-64"
  | "pr-72"
  | "pr-80"
  | "pr-96"
  | "pr-px"
  | "pr-0.5"
  | "pr-1.5"
  | "pr-2.5"
  | "pr-3.5"
  | "pb-0"
  | "pb-1"
  | "pb-2"
  | "pb-3"
  | "pb-4"
  | "pb-5"
  | "pb-6"
  | "pb-7"
  | "pb-8"
  | "pb-9"
  | "pb-10"
  | "pb-11"
  | "pb-12"
  | "pb-14"
  | "pb-16"
  | "pb-20"
  | "pb-24"
  | "pb-28"
  | "pb-32"
  | "pb-36"
  | "pb-40"
  | "pb-44"
  | "pb-48"
  | "pb-52"
  | "pb-56"
  | "pb-60"
  | "pb-64"
  | "pb-72"
  | "pb-80"
  | "pb-96"
  | "pb-px"
  | "pb-0.5"
  | "pb-1.5"
  | "pb-2.5"
  | "pb-3.5"
  | "pl-0"
  | "pl-1"
  | "pl-2"
  | "pl-3"
  | "pl-4"
  | "pl-5"
  | "pl-6"
  | "pl-7"
  | "pl-8"
  | "pl-9"
  | "pl-10"
  | "pl-11"
  | "pl-12"
  | "pl-14"
  | "pl-16"
  | "pl-20"
  | "pl-24"
  | "pl-28"
  | "pl-32"
  | "pl-36"
  | "pl-40"
  | "pl-44"
  | "pl-48"
  | "pl-52"
  | "pl-56"
  | "pl-60"
  | "pl-64"
  | "pl-72"
  | "pl-80"
  | "pl-96"
  | "pl-px"
  | "pl-0.5"
  | "pl-1.5"
  | "pl-2.5"
  | "pl-3.5";

export type TMargin =
  | "m-0"
  | "m-1"
  | "m-2"
  | "m-3"
  | "m-4"
  | "m-5"
  | "m-6"
  | "m-7"
  | "m-8"
  | "m-9"
  | "m-10"
  | "m-11"
  | "m-12"
  | "m-14"
  | "m-16"
  | "m-20"
  | "m-24"
  | "m-28"
  | "m-32"
  | "m-36"
  | "m-40"
  | "m-44"
  | "m-48"
  | "m-52"
  | "m-56"
  | "m-60"
  | "m-64"
  | "m-72"
  | "m-80"
  | "m-96"
  | "m-auto"
  | "m-px"
  | "m-0.5"
  | "m-1.5"
  | "m-2.5"
  | "m-3.5"
  | "-m-0"
  | "-m-1"
  | "-m-2"
  | "-m-3"
  | "-m-4"
  | "-m-5"
  | "-m-6"
  | "-m-7"
  | "-m-8"
  | "-m-9"
  | "-m-10"
  | "-m-11"
  | "-m-12"
  | "-m-14"
  | "-m-16"
  | "-m-20"
  | "-m-24"
  | "-m-28"
  | "-m-32"
  | "-m-36"
  | "-m-40"
  | "-m-44"
  | "-m-48"
  | "-m-52"
  | "-m-56"
  | "-m-60"
  | "-m-64"
  | "-m-72"
  | "-m-80"
  | "-m-96"
  | "-m-px"
  | "-m-0.5"
  | "-m-1.5"
  | "-m-2.5"
  | "-m-3.5"
  | "my-0"
  | "my-1"
  | "my-2"
  | "my-3"
  | "my-4"
  | "my-5"
  | "my-6"
  | "my-7"
  | "my-8"
  | "my-9"
  | "my-10"
  | "my-11"
  | "my-12"
  | "my-14"
  | "my-16"
  | "my-20"
  | "my-24"
  | "my-28"
  | "my-32"
  | "my-36"
  | "my-40"
  | "my-44"
  | "my-48"
  | "my-52"
  | "my-56"
  | "my-60"
  | "my-64"
  | "my-72"
  | "my-80"
  | "my-96"
  | "my-auto"
  | "my-px"
  | "my-0.5"
  | "my-1.5"
  | "my-2.5"
  | "my-3.5"
  | "-my-0"
  | "-my-1"
  | "-my-2"
  | "-my-3"
  | "-my-4"
  | "-my-5"
  | "-my-6"
  | "-my-7"
  | "-my-8"
  | "-my-9"
  | "-my-10"
  | "-my-11"
  | "-my-12"
  | "-my-14"
  | "-my-16"
  | "-my-20"
  | "-my-24"
  | "-my-28"
  | "-my-32"
  | "-my-36"
  | "-my-40"
  | "-my-44"
  | "-my-48"
  | "-my-52"
  | "-my-56"
  | "-my-60"
  | "-my-64"
  | "-my-72"
  | "-my-80"
  | "-my-96"
  | "-my-px"
  | "-my-0.5"
  | "-my-1.5"
  | "-my-2.5"
  | "-my-3.5"
  | "mx-0"
  | "mx-1"
  | "mx-2"
  | "mx-3"
  | "mx-4"
  | "mx-5"
  | "mx-6"
  | "mx-7"
  | "mx-8"
  | "mx-9"
  | "mx-10"
  | "mx-11"
  | "mx-12"
  | "mx-14"
  | "mx-16"
  | "mx-20"
  | "mx-24"
  | "mx-28"
  | "mx-32"
  | "mx-36"
  | "mx-40"
  | "mx-44"
  | "mx-48"
  | "mx-52"
  | "mx-56"
  | "mx-60"
  | "mx-64"
  | "mx-72"
  | "mx-80"
  | "mx-96"
  | "mx-auto"
  | "mx-px"
  | "mx-0.5"
  | "mx-1.5"
  | "mx-2.5"
  | "mx-3.5"
  | "-mx-0"
  | "-mx-1"
  | "-mx-2"
  | "-mx-3"
  | "-mx-4"
  | "-mx-5"
  | "-mx-6"
  | "-mx-7"
  | "-mx-8"
  | "-mx-9"
  | "-mx-10"
  | "-mx-11"
  | "-mx-12"
  | "-mx-14"
  | "-mx-16"
  | "-mx-20"
  | "-mx-24"
  | "-mx-28"
  | "-mx-32"
  | "-mx-36"
  | "-mx-40"
  | "-mx-44"
  | "-mx-48"
  | "-mx-52"
  | "-mx-56"
  | "-mx-60"
  | "-mx-64"
  | "-mx-72"
  | "-mx-80"
  | "-mx-96"
  | "-mx-px"
  | "-mx-0.5"
  | "-mx-1.5"
  | "-mx-2.5"
  | "-mx-3.5"
  | "mt-0"
  | "mt-1"
  | "mt-2"
  | "mt-3"
  | "mt-4"
  | "mt-5"
  | "mt-6"
  | "mt-7"
  | "mt-8"
  | "mt-9"
  | "mt-10"
  | "mt-11"
  | "mt-12"
  | "mt-14"
  | "mt-16"
  | "mt-20"
  | "mt-24"
  | "mt-28"
  | "mt-32"
  | "mt-36"
  | "mt-40"
  | "mt-44"
  | "mt-48"
  | "mt-52"
  | "mt-56"
  | "mt-60"
  | "mt-64"
  | "mt-72"
  | "mt-80"
  | "mt-96"
  | "mt-auto"
  | "mt-px"
  | "mt-0.5"
  | "mt-1.5"
  | "mt-2.5"
  | "mt-3.5"
  | "-mt-0"
  | "-mt-1"
  | "-mt-2"
  | "-mt-3"
  | "-mt-4"
  | "-mt-5"
  | "-mt-6"
  | "-mt-7"
  | "-mt-8"
  | "-mt-9"
  | "-mt-10"
  | "-mt-11"
  | "-mt-12"
  | "-mt-14"
  | "-mt-16"
  | "-mt-20"
  | "-mt-24"
  | "-mt-28"
  | "-mt-32"
  | "-mt-36"
  | "-mt-40"
  | "-mt-44"
  | "-mt-48"
  | "-mt-52"
  | "-mt-56"
  | "-mt-60"
  | "-mt-64"
  | "-mt-72"
  | "-mt-80"
  | "-mt-96"
  | "-mt-px"
  | "-mt-0.5"
  | "-mt-1.5"
  | "-mt-2.5"
  | "-mt-3.5"
  | "mr-0"
  | "mr-1"
  | "mr-2"
  | "mr-3"
  | "mr-4"
  | "mr-5"
  | "mr-6"
  | "mr-7"
  | "mr-8"
  | "mr-9"
  | "mr-10"
  | "mr-11"
  | "mr-12"
  | "mr-14"
  | "mr-16"
  | "mr-20"
  | "mr-24"
  | "mr-28"
  | "mr-32"
  | "mr-36"
  | "mr-40"
  | "mr-44"
  | "mr-48"
  | "mr-52"
  | "mr-56"
  | "mr-60"
  | "mr-64"
  | "mr-72"
  | "mr-80"
  | "mr-96"
  | "mr-auto"
  | "mr-px"
  | "mr-0.5"
  | "mr-1.5"
  | "mr-2.5"
  | "mr-3.5"
  | "-mr-0"
  | "-mr-1"
  | "-mr-2"
  | "-mr-3"
  | "-mr-4"
  | "-mr-5"
  | "-mr-6"
  | "-mr-7"
  | "-mr-8"
  | "-mr-9"
  | "-mr-10"
  | "-mr-11"
  | "-mr-12"
  | "-mr-14"
  | "-mr-16"
  | "-mr-20"
  | "-mr-24"
  | "-mr-28"
  | "-mr-32"
  | "-mr-36"
  | "-mr-40"
  | "-mr-44"
  | "-mr-48"
  | "-mr-52"
  | "-mr-56"
  | "-mr-60"
  | "-mr-64"
  | "-mr-72"
  | "-mr-80"
  | "-mr-96"
  | "-mr-px"
  | "-mr-0.5"
  | "-mr-1.5"
  | "-mr-2.5"
  | "-mr-3.5"
  | "mb-0"
  | "mb-1"
  | "mb-2"
  | "mb-3"
  | "mb-4"
  | "mb-5"
  | "mb-6"
  | "mb-7"
  | "mb-8"
  | "mb-9"
  | "mb-10"
  | "mb-11"
  | "mb-12"
  | "mb-14"
  | "mb-16"
  | "mb-20"
  | "mb-24"
  | "mb-28"
  | "mb-32"
  | "mb-36"
  | "mb-40"
  | "mb-44"
  | "mb-48"
  | "mb-52"
  | "mb-56"
  | "mb-60"
  | "mb-64"
  | "mb-72"
  | "mb-80"
  | "mb-96"
  | "mb-auto"
  | "mb-px"
  | "mb-0.5"
  | "mb-1.5"
  | "mb-2.5"
  | "mb-3.5"
  | "-mb-0"
  | "-mb-1"
  | "-mb-2"
  | "-mb-3"
  | "-mb-4"
  | "-mb-5"
  | "-mb-6"
  | "-mb-7"
  | "-mb-8"
  | "-mb-9"
  | "-mb-10"
  | "-mb-11"
  | "-mb-12"
  | "-mb-14"
  | "-mb-16"
  | "-mb-20"
  | "-mb-24"
  | "-mb-28"
  | "-mb-32"
  | "-mb-36"
  | "-mb-40"
  | "-mb-44"
  | "-mb-48"
  | "-mb-52"
  | "-mb-56"
  | "-mb-60"
  | "-mb-64"
  | "-mb-72"
  | "-mb-80"
  | "-mb-96"
  | "-mb-px"
  | "-mb-0.5"
  | "-mb-1.5"
  | "-mb-2.5"
  | "-mb-3.5"
  | "ml-0"
  | "ml-1"
  | "ml-2"
  | "ml-3"
  | "ml-4"
  | "ml-5"
  | "ml-6"
  | "ml-7"
  | "ml-8"
  | "ml-9"
  | "ml-10"
  | "ml-11"
  | "ml-12"
  | "ml-14"
  | "ml-16"
  | "ml-20"
  | "ml-24"
  | "ml-28"
  | "ml-32"
  | "ml-36"
  | "ml-40"
  | "ml-44"
  | "ml-48"
  | "ml-52"
  | "ml-56"
  | "ml-60"
  | "ml-64"
  | "ml-72"
  | "ml-80"
  | "ml-96"
  | "ml-auto"
  | "ml-px"
  | "ml-0.5"
  | "ml-1.5"
  | "ml-2.5"
  | "ml-3.5"
  | "-ml-0"
  | "-ml-1"
  | "-ml-2"
  | "-ml-3"
  | "-ml-4"
  | "-ml-5"
  | "-ml-6"
  | "-ml-7"
  | "-ml-8"
  | "-ml-9"
  | "-ml-10"
  | "-ml-11"
  | "-ml-12"
  | "-ml-14"
  | "-ml-16"
  | "-ml-20"
  | "-ml-24"
  | "-ml-28"
  | "-ml-32"
  | "-ml-36"
  | "-ml-40"
  | "-ml-44"
  | "-ml-48"
  | "-ml-52"
  | "-ml-56"
  | "-ml-60"
  | "-ml-64"
  | "-ml-72"
  | "-ml-80"
  | "-ml-96"
  | "-ml-px"
  | "-ml-0.5"
  | "-ml-1.5"
  | "-ml-2.5"
  | "-ml-3.5";

export type TSpace =
  | "space-x-0"
  | "space-x-1"
  | "space-x-2"
  | "space-x-3"
  | "space-x-4"
  | "space-x-5"
  | "space-x-6"
  | "space-x-7"
  | "space-x-8"
  | "space-x-9"
  | "space-x-10"
  | "space-x-11"
  | "space-x-12"
  | "space-x-14"
  | "space-x-16"
  | "space-x-20"
  | "space-x-24"
  | "space-x-28"
  | "space-x-32"
  | "space-x-36"
  | "space-x-40"
  | "space-x-44"
  | "space-x-48"
  | "space-x-52"
  | "space-x-56"
  | "space-x-60"
  | "space-x-64"
  | "space-x-72"
  | "space-x-80"
  | "space-x-96"
  | "space-x-px"
  | "space-x-0.5"
  | "space-x-1.5"
  | "space-x-2.5"
  | "space-x-3.5"
  | "-space-x-0"
  | "-space-x-1"
  | "-space-x-2"
  | "-space-x-3"
  | "-space-x-4"
  | "-space-x-5"
  | "-space-x-6"
  | "-space-x-7"
  | "-space-x-8"
  | "-space-x-9"
  | "-space-x-10"
  | "-space-x-11"
  | "-space-x-12"
  | "-space-x-14"
  | "-space-x-16"
  | "-space-x-20"
  | "-space-x-24"
  | "-space-x-28"
  | "-space-x-32"
  | "-space-x-36"
  | "-space-x-40"
  | "-space-x-44"
  | "-space-x-48"
  | "-space-x-52"
  | "-space-x-56"
  | "-space-x-60"
  | "-space-x-64"
  | "-space-x-72"
  | "-space-x-80"
  | "-space-x-96"
  | "-space-x-px"
  | "-space-x-0.5"
  | "-space-x-1.5"
  | "-space-x-2.5"
  | "-space-x-3.5"
  | "space-x-reverse"
  | "space-y-0"
  | "space-y-1"
  | "space-y-2"
  | "space-y-3"
  | "space-y-4"
  | "space-y-5"
  | "space-y-6"
  | "space-y-7"
  | "space-y-8"
  | "space-y-9"
  | "space-y-10"
  | "space-y-11"
  | "space-y-12"
  | "space-y-14"
  | "space-y-16"
  | "space-y-20"
  | "space-y-24"
  | "space-y-28"
  | "space-y-32"
  | "space-y-36"
  | "space-y-40"
  | "space-y-44"
  | "space-y-48"
  | "space-y-52"
  | "space-y-56"
  | "space-y-60"
  | "space-y-64"
  | "space-y-72"
  | "space-y-80"
  | "space-y-96"
  | "space-y-px"
  | "space-y-0.5"
  | "space-y-1.5"
  | "space-y-2.5"
  | "space-y-3.5"
  | "-space-y-0"
  | "-space-y-1"
  | "-space-y-2"
  | "-space-y-3"
  | "-space-y-4"
  | "-space-y-5"
  | "-space-y-6"
  | "-space-y-7"
  | "-space-y-8"
  | "-space-y-9"
  | "-space-y-10"
  | "-space-y-11"
  | "-space-y-12"
  | "-space-y-14"
  | "-space-y-16"
  | "-space-y-20"
  | "-space-y-24"
  | "-space-y-28"
  | "-space-y-32"
  | "-space-y-36"
  | "-space-y-40"
  | "-space-y-44"
  | "-space-y-48"
  | "-space-y-52"
  | "-space-y-56"
  | "-space-y-60"
  | "-space-y-64"
  | "-space-y-72"
  | "-space-y-80"
  | "-space-y-96"
  | "-space-y-px"
  | "-space-y-0.5"
  | "-space-y-1.5"
  | "-space-y-2.5"
  | "-space-y-3.5"
  | "space-y-reverse";

export type TSpacing = TPadding | TMargin | TSpace;

export type TAppearance = "appearance-none";

export type TPointerEvents = "pointer-events-none" | "pointer-events-auto";

export type TResize = "resize-none" | "resize" | "resize-y" | "resize-x";

export type TUserSelect =
  | "select-none"
  | "select-text"
  | "select-all"
  | "select-auto";

export type TCursor =
  | "cursor-auto"
  | "cursor-default"
  | "cursor-pointer"
  | "cursor-wait"
  | "cursor-text"
  | "cursor-move"
  | "cursor-help"
  | "cursor-not-allowed";

export type TOutline = "outline-none" | "outline-white" | "outline-black";

export type TInteractivity =
  | TAppearance
  | TPointerEvents
  | TResize
  | TUserSelect
  | TCursor
  | TOutline;

export type TDisplay =
  | "block"
  | "inline-block"
  | "inline"
  | "flex"
  | "inline-flex"
  | "grid"
  | "inline-grid"
  | "table"
  | "inline-table"
  | "table-row"
  | "table-cell"
  | "contents"
  | "list-item"
  | "hidden";

export type TBoxDecorationBreak = "decoration-slice" | "decoration-clone";

export type TBoxSizing = "box-border" | "box-content";

export type TContainer = "container";

export type TFloat = "float-right" | "float-left" | "float-none";

export type TClear = "clear-left" | "clear-right" | "clear-both" | "clear-none";

export type TIsolation = "isolate" | "isolation-auto";

export type TObjectFit =
  | "object-contain"
  | "object-cover"
  | "object-fill"
  | "object-none"
  | "object-scale-down";

export type TOverflow =
  | "overflow-auto"
  | "overflow-hidden"
  | "overflow-visible"
  | "overflow-scroll"
  | "overflow-x-auto"
  | "overflow-y-auto"
  | "overflow-x-hidden"
  | "overflow-y-hidden"
  | "overflow-x-visible"
  | "overflow-y-visible"
  | "overflow-x-scroll"
  | "overflow-y-scroll";

export type TOverscrollBehavior =
  | "overscroll-auto"
  | "overscroll-contain"
  | "overscroll-none"
  | "overscroll-y-auto"
  | "overscroll-y-contain"
  | "overscroll-y-none"
  | "overscroll-x-auto"
  | "overscroll-x-contain"
  | "overscroll-x-none";

export type TPosition = "static" | "fixed" | "absolute" | "relative" | "sticky";

export type TVisibility = "visible" | "invisible";

export type TObjectPosition =
  | "object-bottom"
  | "object-center"
  | "object-left"
  | "object-left-bottom"
  | "object-left-top"
  | "object-right"
  | "object-right-bottom"
  | "object-right-top"
  | "object-top";

export type TInset =
  | "inset-0"
  | "inset-x-0"
  | "inset-y-0"
  | "top-0"
  | "right-0"
  | "bottom-0"
  | "left-0"
  | "inset-1"
  | "inset-x-1"
  | "inset-y-1"
  | "top-1"
  | "right-1"
  | "bottom-1"
  | "left-1"
  | "inset-2"
  | "inset-x-2"
  | "inset-y-2"
  | "top-2"
  | "right-2"
  | "bottom-2"
  | "left-2"
  | "inset-3"
  | "inset-x-3"
  | "inset-y-3"
  | "top-3"
  | "right-3"
  | "bottom-3"
  | "left-3"
  | "inset-4"
  | "inset-x-4"
  | "inset-y-4"
  | "top-4"
  | "right-4"
  | "bottom-4"
  | "left-4"
  | "inset-5"
  | "inset-x-5"
  | "inset-y-5"
  | "top-5"
  | "right-5"
  | "bottom-5"
  | "left-5"
  | "inset-6"
  | "inset-x-6"
  | "inset-y-6"
  | "top-6"
  | "right-6"
  | "bottom-6"
  | "left-6"
  | "inset-7"
  | "inset-x-7"
  | "inset-y-7"
  | "top-7"
  | "right-7"
  | "bottom-7"
  | "left-7"
  | "inset-8"
  | "inset-x-8"
  | "inset-y-8"
  | "top-8"
  | "right-8"
  | "bottom-8"
  | "left-8"
  | "inset-9"
  | "inset-x-9"
  | "inset-y-9"
  | "top-9"
  | "right-9"
  | "bottom-9"
  | "left-9"
  | "inset-10"
  | "inset-x-10"
  | "inset-y-10"
  | "top-10"
  | "right-10"
  | "bottom-10"
  | "left-10"
  | "inset-11"
  | "inset-x-11"
  | "inset-y-11"
  | "top-11"
  | "right-11"
  | "bottom-11"
  | "left-11"
  | "inset-12"
  | "inset-x-12"
  | "inset-y-12"
  | "top-12"
  | "right-12"
  | "bottom-12"
  | "left-12"
  | "inset-14"
  | "inset-x-14"
  | "inset-y-14"
  | "top-14"
  | "right-14"
  | "bottom-14"
  | "left-14"
  | "inset-16"
  | "inset-x-16"
  | "inset-y-16"
  | "top-16"
  | "right-16"
  | "bottom-16"
  | "left-16"
  | "inset-20"
  | "inset-x-20"
  | "inset-y-20"
  | "top-20"
  | "right-20"
  | "bottom-20"
  | "left-20"
  | "inset-24"
  | "inset-x-24"
  | "inset-y-24"
  | "top-24"
  | "right-24"
  | "bottom-24"
  | "left-24"
  | "inset-28"
  | "inset-x-28"
  | "inset-y-28"
  | "top-28"
  | "right-28"
  | "bottom-28"
  | "left-28"
  | "inset-32"
  | "inset-x-32"
  | "inset-y-32"
  | "top-32"
  | "right-32"
  | "bottom-32"
  | "left-32"
  | "inset-36"
  | "inset-x-36"
  | "inset-y-36"
  | "top-36"
  | "right-36"
  | "bottom-36"
  | "left-36"
  | "inset-40"
  | "inset-x-40"
  | "inset-y-40"
  | "top-40"
  | "right-40"
  | "bottom-40"
  | "left-40"
  | "inset-44"
  | "inset-x-44"
  | "inset-y-44"
  | "top-44"
  | "right-44"
  | "bottom-44"
  | "left-44"
  | "inset-48"
  | "inset-x-48"
  | "inset-y-48"
  | "top-48"
  | "right-48"
  | "bottom-48"
  | "left-48"
  | "inset-52"
  | "inset-x-52"
  | "inset-y-52"
  | "top-52"
  | "right-52"
  | "bottom-52"
  | "left-52"
  | "inset-56"
  | "inset-x-56"
  | "inset-y-56"
  | "top-56"
  | "right-56"
  | "bottom-56"
  | "left-56"
  | "inset-60"
  | "inset-x-60"
  | "inset-y-60"
  | "top-60"
  | "right-60"
  | "bottom-60"
  | "left-60"
  | "inset-64"
  | "inset-x-64"
  | "inset-y-64"
  | "top-64"
  | "right-64"
  | "bottom-64"
  | "left-64"
  | "inset-72"
  | "inset-x-72"
  | "inset-y-72"
  | "top-72"
  | "right-72"
  | "bottom-72"
  | "left-72"
  | "inset-80"
  | "inset-x-80"
  | "inset-y-80"
  | "top-80"
  | "right-80"
  | "bottom-80"
  | "left-80"
  | "inset-96"
  | "inset-x-96"
  | "inset-y-96"
  | "top-96"
  | "right-96"
  | "bottom-96"
  | "left-96"
  | "inset-auto"
  | "inset-x-auto"
  | "inset-y-auto"
  | "top-auto"
  | "right-auto"
  | "bottom-auto"
  | "left-auto"
  | "inset-px"
  | "inset-x-px"
  | "inset-y-px"
  | "top-px"
  | "right-px"
  | "bottom-px"
  | "left-px"
  | "inset-0.5"
  | "inset-x-0.5"
  | "inset-y-0.5"
  | "top-0.5"
  | "right-0.5"
  | "bottom-0.5"
  | "left-0.5"
  | "inset-1.5"
  | "inset-x-1.5"
  | "inset-y-1.5"
  | "top-1.5"
  | "right-1.5"
  | "bottom-1.5"
  | "left-1.5"
  | "inset-2.5"
  | "inset-x-2.5"
  | "inset-y-2.5"
  | "top-2.5"
  | "right-2.5"
  | "bottom-2.5"
  | "left-2.5"
  | "inset-3.5"
  | "inset-x-3.5"
  | "inset-y-3.5"
  | "top-3.5"
  | "right-3.5"
  | "bottom-3.5"
  | "left-3.5"
  | "-inset-0"
  | "-inset-x-0"
  | "-inset-y-0"
  | "-top-0"
  | "-right-0"
  | "-bottom-0"
  | "-left-0"
  | "-inset-1"
  | "-inset-x-1"
  | "-inset-y-1"
  | "-top-1"
  | "-right-1"
  | "-bottom-1"
  | "-left-1"
  | "-inset-2"
  | "-inset-x-2"
  | "-inset-y-2"
  | "-top-2"
  | "-right-2"
  | "-bottom-2"
  | "-left-2"
  | "-inset-3"
  | "-inset-x-3"
  | "-inset-y-3"
  | "-top-3"
  | "-right-3"
  | "-bottom-3"
  | "-left-3"
  | "-inset-4"
  | "-inset-x-4"
  | "-inset-y-4"
  | "-top-4"
  | "-right-4"
  | "-bottom-4"
  | "-left-4"
  | "-inset-5"
  | "-inset-x-5"
  | "-inset-y-5"
  | "-top-5"
  | "-right-5"
  | "-bottom-5"
  | "-left-5"
  | "-inset-6"
  | "-inset-x-6"
  | "-inset-y-6"
  | "-top-6"
  | "-right-6"
  | "-bottom-6"
  | "-left-6"
  | "-inset-7"
  | "-inset-x-7"
  | "-inset-y-7"
  | "-top-7"
  | "-right-7"
  | "-bottom-7"
  | "-left-7"
  | "-inset-8"
  | "-inset-x-8"
  | "-inset-y-8"
  | "-top-8"
  | "-right-8"
  | "-bottom-8"
  | "-left-8"
  | "-inset-9"
  | "-inset-x-9"
  | "-inset-y-9"
  | "-top-9"
  | "-right-9"
  | "-bottom-9"
  | "-left-9"
  | "-inset-10"
  | "-inset-x-10"
  | "-inset-y-10"
  | "-top-10"
  | "-right-10"
  | "-bottom-10"
  | "-left-10"
  | "-inset-11"
  | "-inset-x-11"
  | "-inset-y-11"
  | "-top-11"
  | "-right-11"
  | "-bottom-11"
  | "-left-11"
  | "-inset-12"
  | "-inset-x-12"
  | "-inset-y-12"
  | "-top-12"
  | "-right-12"
  | "-bottom-12"
  | "-left-12"
  | "-inset-14"
  | "-inset-x-14"
  | "-inset-y-14"
  | "-top-14"
  | "-right-14"
  | "-bottom-14"
  | "-left-14"
  | "-inset-16"
  | "-inset-x-16"
  | "-inset-y-16"
  | "-top-16"
  | "-right-16"
  | "-bottom-16"
  | "-left-16"
  | "-inset-20"
  | "-inset-x-20"
  | "-inset-y-20"
  | "-top-20"
  | "-right-20"
  | "-bottom-20"
  | "-left-20"
  | "-inset-24"
  | "-inset-x-24"
  | "-inset-y-24"
  | "-top-24"
  | "-right-24"
  | "-bottom-24"
  | "-left-24"
  | "-inset-28"
  | "-inset-x-28"
  | "-inset-y-28"
  | "-top-28"
  | "-right-28"
  | "-bottom-28"
  | "-left-28"
  | "-inset-32"
  | "-inset-x-32"
  | "-inset-y-32"
  | "-top-32"
  | "-right-32"
  | "-bottom-32"
  | "-left-32"
  | "-inset-36"
  | "-inset-x-36"
  | "-inset-y-36"
  | "-top-36"
  | "-right-36"
  | "-bottom-36"
  | "-left-36"
  | "-inset-40"
  | "-inset-x-40"
  | "-inset-y-40"
  | "-top-40"
  | "-right-40"
  | "-bottom-40"
  | "-left-40"
  | "-inset-44"
  | "-inset-x-44"
  | "-inset-y-44"
  | "-top-44"
  | "-right-44"
  | "-bottom-44"
  | "-left-44"
  | "-inset-48"
  | "-inset-x-48"
  | "-inset-y-48"
  | "-top-48"
  | "-right-48"
  | "-bottom-48"
  | "-left-48"
  | "-inset-52"
  | "-inset-x-52"
  | "-inset-y-52"
  | "-top-52"
  | "-right-52"
  | "-bottom-52"
  | "-left-52"
  | "-inset-56"
  | "-inset-x-56"
  | "-inset-y-56"
  | "-top-56"
  | "-right-56"
  | "-bottom-56"
  | "-left-56"
  | "-inset-60"
  | "-inset-x-60"
  | "-inset-y-60"
  | "-top-60"
  | "-right-60"
  | "-bottom-60"
  | "-left-60"
  | "-inset-64"
  | "-inset-x-64"
  | "-inset-y-64"
  | "-top-64"
  | "-right-64"
  | "-bottom-64"
  | "-left-64"
  | "-inset-72"
  | "-inset-x-72"
  | "-inset-y-72"
  | "-top-72"
  | "-right-72"
  | "-bottom-72"
  | "-left-72"
  | "-inset-80"
  | "-inset-x-80"
  | "-inset-y-80"
  | "-top-80"
  | "-right-80"
  | "-bottom-80"
  | "-left-80"
  | "-inset-96"
  | "-inset-x-96"
  | "-inset-y-96"
  | "-top-96"
  | "-right-96"
  | "-bottom-96"
  | "-left-96"
  | "-inset-px"
  | "-inset-x-px"
  | "-inset-y-px"
  | "-top-px"
  | "-right-px"
  | "-bottom-px"
  | "-left-px"
  | "-inset-0.5"
  | "-inset-x-0.5"
  | "-inset-y-0.5"
  | "-top-0.5"
  | "-right-0.5"
  | "-bottom-0.5"
  | "-left-0.5"
  | "-inset-1.5"
  | "-inset-x-1.5"
  | "-inset-y-1.5"
  | "-top-1.5"
  | "-right-1.5"
  | "-bottom-1.5"
  | "-left-1.5"
  | "-inset-2.5"
  | "-inset-x-2.5"
  | "-inset-y-2.5"
  | "-top-2.5"
  | "-right-2.5"
  | "-bottom-2.5"
  | "-left-2.5"
  | "-inset-3.5"
  | "-inset-x-3.5"
  | "-inset-y-3.5"
  | "-top-3.5"
  | "-right-3.5"
  | "-bottom-3.5"
  | "-left-3.5"
  | "inset-1/2"
  | "inset-x-1/2"
  | "inset-y-1/2"
  | "top-1/2"
  | "right-1/2"
  | "bottom-1/2"
  | "left-1/2"
  | "inset-1/3"
  | "inset-x-1/3"
  | "inset-y-1/3"
  | "top-1/3"
  | "right-1/3"
  | "bottom-1/3"
  | "left-1/3"
  | "inset-2/3"
  | "inset-x-2/3"
  | "inset-y-2/3"
  | "top-2/3"
  | "right-2/3"
  | "bottom-2/3"
  | "left-2/3"
  | "inset-1/4"
  | "inset-x-1/4"
  | "inset-y-1/4"
  | "top-1/4"
  | "right-1/4"
  | "bottom-1/4"
  | "left-1/4"
  | "inset-2/4"
  | "inset-x-2/4"
  | "inset-y-2/4"
  | "top-2/4"
  | "right-2/4"
  | "bottom-2/4"
  | "left-2/4"
  | "inset-3/4"
  | "inset-x-3/4"
  | "inset-y-3/4"
  | "top-3/4"
  | "right-3/4"
  | "bottom-3/4"
  | "left-3/4"
  | "inset-full"
  | "inset-x-full"
  | "inset-y-full"
  | "top-full"
  | "right-full"
  | "bottom-full"
  | "left-full"
  | "-inset-1/2"
  | "-inset-x-1/2"
  | "-inset-y-1/2"
  | "-top-1/2"
  | "-right-1/2"
  | "-bottom-1/2"
  | "-left-1/2"
  | "-inset-1/3"
  | "-inset-x-1/3"
  | "-inset-y-1/3"
  | "-top-1/3"
  | "-right-1/3"
  | "-bottom-1/3"
  | "-left-1/3"
  | "-inset-2/3"
  | "-inset-x-2/3"
  | "-inset-y-2/3"
  | "-top-2/3"
  | "-right-2/3"
  | "-bottom-2/3"
  | "-left-2/3"
  | "-inset-1/4"
  | "-inset-x-1/4"
  | "-inset-y-1/4"
  | "-top-1/4"
  | "-right-1/4"
  | "-bottom-1/4"
  | "-left-1/4"
  | "-inset-2/4"
  | "-inset-x-2/4"
  | "-inset-y-2/4"
  | "-top-2/4"
  | "-right-2/4"
  | "-bottom-2/4"
  | "-left-2/4"
  | "-inset-3/4"
  | "-inset-x-3/4"
  | "-inset-y-3/4"
  | "-top-3/4"
  | "-right-3/4"
  | "-bottom-3/4"
  | "-left-3/4"
  | "-inset-full"
  | "-inset-x-full"
  | "-inset-y-full"
  | "-top-full"
  | "-right-full"
  | "-bottom-full"
  | "-left-full"
  | "inset-top-full"
  | "inset-x-top-full"
  | "inset-y-top-full"
  | "top-top-full"
  | "right-top-full"
  | "bottom-top-full"
  | "left-top-full"
  | "inset-right-full"
  | "inset-x-right-full"
  | "inset-y-right-full"
  | "top-right-full"
  | "right-right-full"
  | "bottom-right-full"
  | "left-right-full"
  | "inset-bottom-full"
  | "inset-x-bottom-full"
  | "inset-y-bottom-full"
  | "top-bottom-full"
  | "right-bottom-full"
  | "bottom-bottom-full"
  | "left-bottom-full"
  | "inset-left-full"
  | "inset-x-left-full"
  | "inset-y-left-full"
  | "top-left-full"
  | "right-left-full"
  | "bottom-left-full"
  | "left-left-full";

export type TZIndex =
  | "z-0"
  | "z-10"
  | "z-20"
  | "z-30"
  | "z-40"
  | "z-50"
  | "z-100"
  | "z-200"
  | "z-300"
  | "z-400"
  | "z-500"
  | "z-600"
  | "z-700"
  | "z-800"
  | "z-900"
  | "z-auto";

export type TLayout =
  | TDisplay
  | TBoxDecorationBreak
  | TBoxSizing
  | TContainer
  | TFloat
  | TClear
  | TIsolation
  | TObjectFit
  | TOverflow
  | TOverscrollBehavior
  | TPosition
  | TVisibility
  | TObjectPosition
  | TInset
  | TZIndex;

export type TWidth =
  | "w-0"
  | "w-1"
  | "w-2"
  | "w-3"
  | "w-4"
  | "w-5"
  | "w-6"
  | "w-7"
  | "w-8"
  | "w-9"
  | "w-10"
  | "w-11"
  | "w-12"
  | "w-14"
  | "w-16"
  | "w-20"
  | "w-24"
  | "w-28"
  | "w-32"
  | "w-36"
  | "w-40"
  | "w-44"
  | "w-48"
  | "w-52"
  | "w-56"
  | "w-60"
  | "w-64"
  | "w-72"
  | "w-80"
  | "w-96"
  | "w-112"
  | "w-192"
  | "w-auto"
  | "w-px"
  | "w-0.5"
  | "w-1.5"
  | "w-2.5"
  | "w-3.5"
  | "w-1/2"
  | "w-1/3"
  | "w-2/3"
  | "w-1/4"
  | "w-2/4"
  | "w-3/4"
  | "w-1/5"
  | "w-2/5"
  | "w-3/5"
  | "w-4/5"
  | "w-1/6"
  | "w-2/6"
  | "w-3/6"
  | "w-4/6"
  | "w-5/6"
  | "w-1/12"
  | "w-2/12"
  | "w-3/12"
  | "w-4/12"
  | "w-5/12"
  | "w-6/12"
  | "w-7/12"
  | "w-8/12"
  | "w-9/12"
  | "w-10/12"
  | "w-11/12"
  | "w-full"
  | "w-screen"
  | "w-min"
  | "w-max"
  | "w-4.5"
  | "w-vw-full";

export type TMinWidth =
  | "min-w-0"
  | "min-w-40"
  | "min-w-44"
  | "min-w-48"
  | "min-w-52"
  | "min-w-56"
  | "min-w-60"
  | "min-w-64"
  | "min-w-72"
  | "min-w-80"
  | "min-w-96"
  | "min-w-112"
  | "min-w-128"
  | "min-w-144"
  | "min-w-160"
  | "min-w-280"
  | "min-w-full"
  | "min-w-min"
  | "min-w-max"
  | "min-w-vw-1/2"
  | "min-w-vw-1/4"
  | "min-w-vw-3/4";

export type TMaxWidth =
  | "max-w-0"
  | "max-w-10"
  | "max-w-20"
  | "max-w-30"
  | "max-w-40"
  | "max-w-50"
  | "max-w-60"
  | "max-w-70"
  | "max-w-80"
  | "max-w-90"
  | "max-w-none"
  | "max-w-xs"
  | "max-w-sm"
  | "max-w-md"
  | "max-w-lg"
  | "max-w-xl"
  | "max-w-2xl"
  | "max-w-3xl"
  | "max-w-4xl"
  | "max-w-5xl"
  | "max-w-6xl"
  | "max-w-7xl"
  | "max-w-full"
  | "max-w-min"
  | "max-w-max"
  | "max-w-prose"
  | "max-w-screen-2xl"
  | "max-w-screen-1xl"
  | "max-w-screen-xl"
  | "max-w-screen-lg"
  | "max-w-screen-md"
  | "max-w-screen-sm"
  | "max-w-screen-xs";

export type THeight =
  | "h-0"
  | "h-1"
  | "h-2"
  | "h-3"
  | "h-4"
  | "h-5"
  | "h-6"
  | "h-7"
  | "h-8"
  | "h-9"
  | "h-10"
  | "h-11"
  | "h-12"
  | "h-14"
  | "h-16"
  | "h-20"
  | "h-24"
  | "h-28"
  | "h-32"
  | "h-36"
  | "h-40"
  | "h-44"
  | "h-48"
  | "h-52"
  | "h-56"
  | "h-60"
  | "h-64"
  | "h-72"
  | "h-80"
  | "h-96"
  | "h-192"
  | "h-auto"
  | "h-px"
  | "h-0.5"
  | "h-1.5"
  | "h-2.5"
  | "h-3.5"
  | "h-1/2"
  | "h-1/3"
  | "h-2/3"
  | "h-1/4"
  | "h-2/4"
  | "h-3/4"
  | "h-1/5"
  | "h-2/5"
  | "h-3/5"
  | "h-4/5"
  | "h-1/6"
  | "h-2/6"
  | "h-3/6"
  | "h-4/6"
  | "h-5/6"
  | "h-full"
  | "h-screen"
  | "h-4.5"
  | "h-vh-full"
  | "h-full-dynamic";

export type TMinHeight =
  | "min-h-0"
  | "min-h-4"
  | "min-h-8"
  | "min-h-12"
  | "min-h-16"
  | "min-h-20"
  | "min-h-24"
  | "min-h-36"
  | "min-h-40"
  | "min-h-44"
  | "min-h-48"
  | "min-h-52"
  | "min-h-56"
  | "min-h-60"
  | "min-h-64"
  | "min-h-72"
  | "min-h-80"
  | "min-h-96"
  | "min-h-full"
  | "min-h-screen"
  | "min-h-1/2"
  | "min-h-1/4"
  | "min-h-3/4"
  | "min-h-vh-1/2"
  | "min-h-vh-1/4"
  | "min-h-vh-3/4";

export type TMaxHeight =
  | "max-h-0"
  | "max-h-1"
  | "max-h-2"
  | "max-h-3"
  | "max-h-4"
  | "max-h-5"
  | "max-h-6"
  | "max-h-7"
  | "max-h-8"
  | "max-h-9"
  | "max-h-10"
  | "max-h-11"
  | "max-h-12"
  | "max-h-14"
  | "max-h-16"
  | "max-h-20"
  | "max-h-24"
  | "max-h-28"
  | "max-h-32"
  | "max-h-36"
  | "max-h-40"
  | "max-h-44"
  | "max-h-48"
  | "max-h-52"
  | "max-h-56"
  | "max-h-60"
  | "max-h-64"
  | "max-h-72"
  | "max-h-80"
  | "max-h-96"
  | "max-h-px"
  | "max-h-0.5"
  | "max-h-1.5"
  | "max-h-2.5"
  | "max-h-3.5"
  | "max-h-full"
  | "max-h-screen"
  | "max-h-full-dynamic"
  | "max-h-full-without-header";

export type TSizing =
  | TWidth
  | TMinWidth
  | TMaxWidth
  | THeight
  | TMinHeight
  | TMaxHeight;

export type TFill = "fill-current";

export type TStroke = "stroke-current";

export type TStrokeWidth = "stroke-0" | "stroke-1" | "stroke-2";

export type TSVG = TFill | TStroke | TStrokeWidth;

export type THardwareAcceleration = "transform-gpu";

export type TScale =
  | "scale-0"
  | "scale-50"
  | "scale-75"
  | "scale-90"
  | "scale-95"
  | "scale-100"
  | "scale-105"
  | "scale-110"
  | "scale-125"
  | "scale-150"
  | "scale-x-0"
  | "scale-x-50"
  | "scale-x-75"
  | "scale-x-90"
  | "scale-x-95"
  | "scale-x-100"
  | "scale-x-105"
  | "scale-x-110"
  | "scale-x-125"
  | "scale-x-150"
  | "scale-y-0"
  | "scale-y-50"
  | "scale-y-75"
  | "scale-y-90"
  | "scale-y-95"
  | "scale-y-100"
  | "scale-y-105"
  | "scale-y-110"
  | "scale-y-125"
  | "scale-y-150";

export type TRotate =
  | "rotate-0"
  | "rotate-1"
  | "rotate-2"
  | "rotate-3"
  | "rotate-6"
  | "rotate-12"
  | "rotate-45"
  | "rotate-90"
  | "rotate-180"
  | "-rotate-180"
  | "-rotate-90"
  | "-rotate-45"
  | "-rotate-12"
  | "-rotate-6"
  | "-rotate-3"
  | "-rotate-2"
  | "-rotate-1";

export type TTranslate =
  | "translate-x-0"
  | "translate-x-1"
  | "translate-x-2"
  | "translate-x-3"
  | "translate-x-4"
  | "translate-x-5"
  | "translate-x-6"
  | "translate-x-7"
  | "translate-x-8"
  | "translate-x-9"
  | "translate-x-10"
  | "translate-x-11"
  | "translate-x-12"
  | "translate-x-14"
  | "translate-x-16"
  | "translate-x-20"
  | "translate-x-24"
  | "translate-x-28"
  | "translate-x-32"
  | "translate-x-36"
  | "translate-x-40"
  | "translate-x-44"
  | "translate-x-48"
  | "translate-x-52"
  | "translate-x-56"
  | "translate-x-60"
  | "translate-x-64"
  | "translate-x-72"
  | "translate-x-80"
  | "translate-x-96"
  | "translate-x-px"
  | "translate-x-0.5"
  | "translate-x-1.5"
  | "translate-x-2.5"
  | "translate-x-3.5"
  | "-translate-x-0"
  | "-translate-x-1"
  | "-translate-x-2"
  | "-translate-x-3"
  | "-translate-x-4"
  | "-translate-x-5"
  | "-translate-x-6"
  | "-translate-x-7"
  | "-translate-x-8"
  | "-translate-x-9"
  | "-translate-x-10"
  | "-translate-x-11"
  | "-translate-x-12"
  | "-translate-x-14"
  | "-translate-x-16"
  | "-translate-x-20"
  | "-translate-x-24"
  | "-translate-x-28"
  | "-translate-x-32"
  | "-translate-x-36"
  | "-translate-x-40"
  | "-translate-x-44"
  | "-translate-x-48"
  | "-translate-x-52"
  | "-translate-x-56"
  | "-translate-x-60"
  | "-translate-x-64"
  | "-translate-x-72"
  | "-translate-x-80"
  | "-translate-x-96"
  | "-translate-x-px"
  | "-translate-x-0.5"
  | "-translate-x-1.5"
  | "-translate-x-2.5"
  | "-translate-x-3.5"
  | "translate-x-1/2"
  | "translate-x-1/3"
  | "translate-x-2/3"
  | "translate-x-1/4"
  | "translate-x-2/4"
  | "translate-x-3/4"
  | "translate-x-full"
  | "-translate-x-1/2"
  | "-translate-x-1/3"
  | "-translate-x-2/3"
  | "-translate-x-1/4"
  | "-translate-x-2/4"
  | "-translate-x-3/4"
  | "-translate-x-full"
  | "translate-y-0"
  | "translate-y-1"
  | "translate-y-2"
  | "translate-y-3"
  | "translate-y-4"
  | "translate-y-5"
  | "translate-y-6"
  | "translate-y-7"
  | "translate-y-8"
  | "translate-y-9"
  | "translate-y-10"
  | "translate-y-11"
  | "translate-y-12"
  | "translate-y-14"
  | "translate-y-16"
  | "translate-y-20"
  | "translate-y-24"
  | "translate-y-28"
  | "translate-y-32"
  | "translate-y-36"
  | "translate-y-40"
  | "translate-y-44"
  | "translate-y-48"
  | "translate-y-52"
  | "translate-y-56"
  | "translate-y-60"
  | "translate-y-64"
  | "translate-y-72"
  | "translate-y-80"
  | "translate-y-96"
  | "translate-y-px"
  | "translate-y-0.5"
  | "translate-y-1.5"
  | "translate-y-2.5"
  | "translate-y-3.5"
  | "-translate-y-0"
  | "-translate-y-1"
  | "-translate-y-2"
  | "-translate-y-3"
  | "-translate-y-4"
  | "-translate-y-5"
  | "-translate-y-6"
  | "-translate-y-7"
  | "-translate-y-8"
  | "-translate-y-9"
  | "-translate-y-10"
  | "-translate-y-11"
  | "-translate-y-12"
  | "-translate-y-14"
  | "-translate-y-16"
  | "-translate-y-20"
  | "-translate-y-24"
  | "-translate-y-28"
  | "-translate-y-32"
  | "-translate-y-36"
  | "-translate-y-40"
  | "-translate-y-44"
  | "-translate-y-48"
  | "-translate-y-52"
  | "-translate-y-56"
  | "-translate-y-60"
  | "-translate-y-64"
  | "-translate-y-72"
  | "-translate-y-80"
  | "-translate-y-96"
  | "-translate-y-px"
  | "-translate-y-0.5"
  | "-translate-y-1.5"
  | "-translate-y-2.5"
  | "-translate-y-3.5"
  | "translate-y-1/2"
  | "translate-y-1/3"
  | "translate-y-2/3"
  | "translate-y-1/4"
  | "translate-y-2/4"
  | "translate-y-3/4"
  | "translate-y-full"
  | "-translate-y-1/2"
  | "-translate-y-1/3"
  | "-translate-y-2/3"
  | "-translate-y-1/4"
  | "-translate-y-2/4"
  | "-translate-y-3/4"
  | "-translate-y-full";

export type TSkew =
  | "skew-x-0"
  | "skew-x-1"
  | "skew-x-2"
  | "skew-x-3"
  | "skew-x-6"
  | "skew-x-12"
  | "-skew-x-12"
  | "-skew-x-6"
  | "-skew-x-3"
  | "-skew-x-2"
  | "-skew-x-1"
  | "skew-y-0"
  | "skew-y-1"
  | "skew-y-2"
  | "skew-y-3"
  | "skew-y-6"
  | "skew-y-12"
  | "-skew-y-12"
  | "-skew-y-6"
  | "-skew-y-3"
  | "-skew-y-2"
  | "-skew-y-1";

export type TTransformOrigin =
  | "origin-center"
  | "origin-top"
  | "origin-top-right"
  | "origin-right"
  | "origin-bottom-right"
  | "origin-bottom"
  | "origin-bottom-left"
  | "origin-left"
  | "origin-top-left";

export type TTransforms =
  | THardwareAcceleration
  | TScale
  | TRotate
  | TTranslate
  | TSkew
  | TTransformOrigin;

export type TFontSmoothing = "antialiased" | "subpixel-antialiased";

export type TFontStyle = "italic" | "non-italic";

export type TFontVariantNumeric =
  | "normal-nums"
  | "ordinal"
  | "slashed-zero"
  | "lining-nums"
  | "oldstyle-nums"
  | "proportional-nums"
  | "tabular-nums"
  | "diagonal-fractions"
  | "stacked-fractions";

export type TListStylePosition = "list-inside" | "list-outside";

export type TTextAlign =
  | "text-left"
  | "text-center"
  | "text-right"
  | "text-justify";

export type TTextDecoration = "underline" | "line-through" | "no-underline";

export type TTextTransform =
  | "uppercase"
  | "lowercase"
  | "capitalize"
  | "normal-case";

export type TTextOverflow = "truncate" | "overflow-ellipsis" | "overflow-clip";

export type TVerticalAlign =
  | "align-baseline"
  | "align-top"
  | "align-middle"
  | "align-bottom"
  | "align-text-top"
  | "align-text-bottom";

export type TWhitespace =
  | "whitespace-normal"
  | "whitespace-nowrap"
  | "whitespace-pre"
  | "whitespace-pre-line"
  | "whitespace-pre-wrap";

export type TWordBreak = "break-normal" | "break-words" | "break-all";

export type TFontFamily = "font-sans" | "font-serif" | "font-mono";

export type TFontSize =
  | "text-xs"
  | "text-sm"
  | "text-base"
  | "text-lg"
  | "text-xl"
  | "text-2xl"
  | "text-3xl"
  | "text-4xl"
  | "text-5xl"
  | "text-6xl"
  | "text-7xl"
  | "text-8xl"
  | "text-9xl";

export type TFontWeight =
  | "font-thin"
  | "font-extralight"
  | "font-light"
  | "font-normal"
  | "font-medium"
  | "font-semibold"
  | "font-bold"
  | "font-extrabold"
  | "font-black";

export type TLetterSpacing =
  | "tracking-tighter"
  | "tracking-tight"
  | "tracking-normal"
  | "tracking-wide"
  | "tracking-wider"
  | "tracking-widest";

export type TLineHeight =
  | "leading-3"
  | "leading-4"
  | "leading-5"
  | "leading-6"
  | "leading-7"
  | "leading-8"
  | "leading-9"
  | "leading-10"
  | "leading-none"
  | "leading-tight"
  | "leading-snug"
  | "leading-normal"
  | "leading-relaxed"
  | "leading-loose";

export type TListStyleType = "list-none" | "list-disc" | "list-decimal";

export type TPlaceholderColor =
  | "placeholder-transparent"
  | "placeholder-current"
  | "placeholder-black"
  | "placeholder-white"
  | "placeholder-gray-50"
  | "placeholder-gray-100"
  | "placeholder-gray-200"
  | "placeholder-gray-300"
  | "placeholder-gray-400"
  | "placeholder-gray-500"
  | "placeholder-gray-600"
  | "placeholder-gray-700"
  | "placeholder-gray-800"
  | "placeholder-gray-900"
  | "placeholder-red-50"
  | "placeholder-red-100"
  | "placeholder-red-200"
  | "placeholder-red-300"
  | "placeholder-red-400"
  | "placeholder-red-500"
  | "placeholder-red-600"
  | "placeholder-red-700"
  | "placeholder-red-800"
  | "placeholder-red-900"
  | "placeholder-yellow-50"
  | "placeholder-yellow-100"
  | "placeholder-yellow-200"
  | "placeholder-yellow-300"
  | "placeholder-yellow-400"
  | "placeholder-yellow-500"
  | "placeholder-yellow-600"
  | "placeholder-yellow-700"
  | "placeholder-yellow-800"
  | "placeholder-yellow-900"
  | "placeholder-green-50"
  | "placeholder-green-100"
  | "placeholder-green-200"
  | "placeholder-green-300"
  | "placeholder-green-400"
  | "placeholder-green-500"
  | "placeholder-green-600"
  | "placeholder-green-700"
  | "placeholder-green-800"
  | "placeholder-green-900"
  | "placeholder-blue-50"
  | "placeholder-blue-100"
  | "placeholder-blue-200"
  | "placeholder-blue-300"
  | "placeholder-blue-400"
  | "placeholder-blue-500"
  | "placeholder-blue-600"
  | "placeholder-blue-700"
  | "placeholder-blue-800"
  | "placeholder-blue-900"
  | "placeholder-indigo-50"
  | "placeholder-indigo-100"
  | "placeholder-indigo-200"
  | "placeholder-indigo-300"
  | "placeholder-indigo-400"
  | "placeholder-indigo-500"
  | "placeholder-indigo-600"
  | "placeholder-indigo-700"
  | "placeholder-indigo-800"
  | "placeholder-indigo-900"
  | "placeholder-purple-50"
  | "placeholder-purple-100"
  | "placeholder-purple-200"
  | "placeholder-purple-300"
  | "placeholder-purple-400"
  | "placeholder-purple-500"
  | "placeholder-purple-600"
  | "placeholder-purple-700"
  | "placeholder-purple-800"
  | "placeholder-purple-900"
  | "placeholder-pink-50"
  | "placeholder-pink-100"
  | "placeholder-pink-200"
  | "placeholder-pink-300"
  | "placeholder-pink-400"
  | "placeholder-pink-500"
  | "placeholder-pink-600"
  | "placeholder-pink-700"
  | "placeholder-pink-800"
  | "placeholder-pink-900"
  | "placeholder-title-color-1"
  | "placeholder-primary-bg-color"
  | "placeholder-secondary-bg-color"
  | "placeholder-sidebar-bg-color"
  | "placeholder-header-bg-color"
  | "placeholder-body-bg-color"
  | "placeholder-main-color"
  | "placeholder-tertiary-bg-color"
  | "placeholder-quaternary-bg-color"
  | "placeholder-earnings-bg-color"
  | "placeholder-card-secondary-bg-color"
  | "placeholder-pink-color"
  | "placeholder-go-live-text"
  | "placeholder-go-live-bg-color"
  | "placeholder-gray-button"
  | "placeholder-toggle-disabled"
  | "placeholder-tab-not-selected-color"
  | "placeholder-text-area-bg-color"
  | "placeholder-table-primary-bg-color"
  | "placeholder-table-secondary-color"
  | "placeholder-modal-button-color";

export type TPlaceholderOpacity =
  | "placeholder-opacity-0"
  | "placeholder-opacity-5"
  | "placeholder-opacity-10"
  | "placeholder-opacity-20"
  | "placeholder-opacity-25"
  | "placeholder-opacity-30"
  | "placeholder-opacity-40"
  | "placeholder-opacity-50"
  | "placeholder-opacity-60"
  | "placeholder-opacity-70"
  | "placeholder-opacity-75"
  | "placeholder-opacity-80"
  | "placeholder-opacity-90"
  | "placeholder-opacity-95"
  | "placeholder-opacity-100";

export type TTextColor =
  | "text-transparent"
  | "text-current"
  | "text-black"
  | "text-white"
  | "text-gray-50"
  | "text-gray-100"
  | "text-gray-200"
  | "text-gray-300"
  | "text-gray-400"
  | "text-gray-500"
  | "text-gray-600"
  | "text-gray-700"
  | "text-gray-800"
  | "text-gray-900"
  | "text-red-50"
  | "text-red-100"
  | "text-red-200"
  | "text-red-300"
  | "text-red-400"
  | "text-red-500"
  | "text-red-600"
  | "text-red-700"
  | "text-red-800"
  | "text-red-900"
  | "text-yellow-50"
  | "text-yellow-100"
  | "text-yellow-200"
  | "text-yellow-300"
  | "text-yellow-400"
  | "text-yellow-500"
  | "text-yellow-600"
  | "text-yellow-700"
  | "text-yellow-800"
  | "text-yellow-900"
  | "text-green-50"
  | "text-green-100"
  | "text-green-200"
  | "text-green-300"
  | "text-green-400"
  | "text-green-500"
  | "text-green-600"
  | "text-green-700"
  | "text-green-800"
  | "text-green-900"
  | "text-blue-50"
  | "text-blue-100"
  | "text-blue-200"
  | "text-blue-300"
  | "text-blue-400"
  | "text-blue-500"
  | "text-blue-600"
  | "text-blue-700"
  | "text-blue-800"
  | "text-blue-900"
  | "text-indigo-50"
  | "text-indigo-100"
  | "text-indigo-200"
  | "text-indigo-300"
  | "text-indigo-400"
  | "text-indigo-500"
  | "text-indigo-600"
  | "text-indigo-700"
  | "text-indigo-800"
  | "text-indigo-900"
  | "text-purple-50"
  | "text-purple-100"
  | "text-purple-200"
  | "text-purple-300"
  | "text-purple-400"
  | "text-purple-500"
  | "text-purple-600"
  | "text-purple-700"
  | "text-purple-800"
  | "text-purple-900"
  | "text-pink-50"
  | "text-pink-100"
  | "text-pink-200"
  | "text-pink-300"
  | "text-pink-400"
  | "text-pink-500"
  | "text-pink-600"
  | "text-pink-700"
  | "text-pink-800"
  | "text-pink-900"
  | "text-title-color-1"
  | "text-primary-bg-color"
  | "text-secondary-bg-color"
  | "text-sidebar-bg-color"
  | "text-header-bg-color"
  | "text-body-bg-color"
  | "text-main-color"
  | "text-secondary-color"
  | "text-tertiary-bg-color"
  | "text-quaternary-bg-color"
  | "text-earnings-bg-color"
  | "text-card-secondary-bg-color"
  | "text-pink-color"
  | "text-go-live-text"
  | "text-go-live-bg-color"
  | "text-gray-button"
  | "text-toggle-disabled"
  | "text-tab-not-selected-color"
  | "text-text-area-bg-color"
  | "text-table-primary-bg-color"
  | "text-table-secondary-color"
  | "text-modal-button-color";

export type TTextOpacity =
  | "text-opacity-0"
  | "text-opacity-5"
  | "text-opacity-10"
  | "text-opacity-20"
  | "text-opacity-25"
  | "text-opacity-30"
  | "text-opacity-40"
  | "text-opacity-50"
  | "text-opacity-60"
  | "text-opacity-70"
  | "text-opacity-75"
  | "text-opacity-80"
  | "text-opacity-90"
  | "text-opacity-95"
  | "text-opacity-100";

export type TTypography =
  | TFontSmoothing
  | TFontStyle
  | TFontVariantNumeric
  | TListStylePosition
  | TTextAlign
  | TTextDecoration
  | TTextTransform
  | TTextOverflow
  | TVerticalAlign
  | TWhitespace
  | TWordBreak
  | TFontFamily
  | TFontSize
  | TFontWeight
  | TLetterSpacing
  | TLineHeight
  | TListStyleType
  | TPlaceholderColor
  | TPlaceholderOpacity
  | TTextColor
  | TTextOpacity;

export type TPseudoClasses =
  | "2xl:content-start"
  | "1xl:content-start"
  | "xl:content-start"
  | "lg:content-start"
  | "md:content-start"
  | "sm:content-start"
  | "xs:content-start"
  | "2xl:content-center"
  | "1xl:content-center"
  | "xl:content-center"
  | "lg:content-center"
  | "md:content-center"
  | "sm:content-center"
  | "xs:content-center"
  | "2xl:content-end"
  | "1xl:content-end"
  | "xl:content-end"
  | "lg:content-end"
  | "md:content-end"
  | "sm:content-end"
  | "xs:content-end"
  | "2xl:content-between"
  | "1xl:content-between"
  | "xl:content-between"
  | "lg:content-between"
  | "md:content-between"
  | "sm:content-between"
  | "xs:content-between"
  | "2xl:content-around"
  | "1xl:content-around"
  | "xl:content-around"
  | "lg:content-around"
  | "md:content-around"
  | "sm:content-around"
  | "xs:content-around"
  | "2xl:items-stretch"
  | "1xl:items-stretch"
  | "xl:items-stretch"
  | "lg:items-stretch"
  | "md:items-stretch"
  | "sm:items-stretch"
  | "xs:items-stretch"
  | "2xl:items-start"
  | "1xl:items-start"
  | "xl:items-start"
  | "lg:items-start"
  | "md:items-start"
  | "sm:items-start"
  | "xs:items-start"
  | "2xl:items-center"
  | "1xl:items-center"
  | "xl:items-center"
  | "lg:items-center"
  | "md:items-center"
  | "sm:items-center"
  | "xs:items-center"
  | "2xl:items-end"
  | "1xl:items-end"
  | "xl:items-end"
  | "lg:items-end"
  | "md:items-end"
  | "sm:items-end"
  | "xs:items-end"
  | "2xl:items-baseline"
  | "1xl:items-baseline"
  | "xl:items-baseline"
  | "lg:items-baseline"
  | "md:items-baseline"
  | "sm:items-baseline"
  | "xs:items-baseline"
  | "2xl:self-auto"
  | "1xl:self-auto"
  | "xl:self-auto"
  | "lg:self-auto"
  | "md:self-auto"
  | "sm:self-auto"
  | "xs:self-auto"
  | "2xl:self-start"
  | "1xl:self-start"
  | "xl:self-start"
  | "lg:self-start"
  | "md:self-start"
  | "sm:self-start"
  | "xs:self-start"
  | "2xl:self-center"
  | "1xl:self-center"
  | "xl:self-center"
  | "lg:self-center"
  | "md:self-center"
  | "sm:self-center"
  | "xs:self-center"
  | "2xl:self-end"
  | "1xl:self-end"
  | "xl:self-end"
  | "lg:self-end"
  | "md:self-end"
  | "sm:self-end"
  | "xs:self-end"
  | "2xl:self-stretch"
  | "1xl:self-stretch"
  | "xl:self-stretch"
  | "lg:self-stretch"
  | "md:self-stretch"
  | "sm:self-stretch"
  | "xs:self-stretch"
  | "2xl:self-baseline"
  | "1xl:self-baseline"
  | "xl:self-baseline"
  | "lg:self-baseline"
  | "md:self-baseline"
  | "sm:self-baseline"
  | "xs:self-baseline"
  | "2xl:animate-none"
  | "1xl:animate-none"
  | "xl:animate-none"
  | "lg:animate-none"
  | "md:animate-none"
  | "sm:animate-none"
  | "xs:animate-none"
  | "2xl:animate-spin"
  | "1xl:animate-spin"
  | "xl:animate-spin"
  | "lg:animate-spin"
  | "md:animate-spin"
  | "sm:animate-spin"
  | "xs:animate-spin"
  | "2xl:animate-ping"
  | "1xl:animate-ping"
  | "xl:animate-ping"
  | "lg:animate-ping"
  | "md:animate-ping"
  | "sm:animate-ping"
  | "xs:animate-ping"
  | "2xl:animate-pulse"
  | "1xl:animate-pulse"
  | "xl:animate-pulse"
  | "lg:animate-pulse"
  | "md:animate-pulse"
  | "sm:animate-pulse"
  | "xs:animate-pulse"
  | "2xl:animate-bounce"
  | "1xl:animate-bounce"
  | "xl:animate-bounce"
  | "lg:animate-bounce"
  | "md:animate-bounce"
  | "sm:animate-bounce"
  | "xs:animate-bounce"
  | "2xl:appearance-none"
  | "1xl:appearance-none"
  | "xl:appearance-none"
  | "lg:appearance-none"
  | "md:appearance-none"
  | "sm:appearance-none"
  | "xs:appearance-none"
  | "2xl:backdrop-blur-0"
  | "1xl:backdrop-blur-0"
  | "xl:backdrop-blur-0"
  | "lg:backdrop-blur-0"
  | "md:backdrop-blur-0"
  | "sm:backdrop-blur-0"
  | "xs:backdrop-blur-0"
  | "2xl:backdrop-blur-none"
  | "1xl:backdrop-blur-none"
  | "xl:backdrop-blur-none"
  | "lg:backdrop-blur-none"
  | "md:backdrop-blur-none"
  | "sm:backdrop-blur-none"
  | "xs:backdrop-blur-none"
  | "2xl:backdrop-blur-sm"
  | "1xl:backdrop-blur-sm"
  | "xl:backdrop-blur-sm"
  | "lg:backdrop-blur-sm"
  | "md:backdrop-blur-sm"
  | "sm:backdrop-blur-sm"
  | "xs:backdrop-blur-sm"
  | "2xl:backdrop-blur"
  | "1xl:backdrop-blur"
  | "xl:backdrop-blur"
  | "lg:backdrop-blur"
  | "md:backdrop-blur"
  | "sm:backdrop-blur"
  | "xs:backdrop-blur"
  | "2xl:backdrop-blur-md"
  | "1xl:backdrop-blur-md"
  | "xl:backdrop-blur-md"
  | "lg:backdrop-blur-md"
  | "md:backdrop-blur-md"
  | "sm:backdrop-blur-md"
  | "xs:backdrop-blur-md"
  | "2xl:backdrop-blur-lg"
  | "1xl:backdrop-blur-lg"
  | "xl:backdrop-blur-lg"
  | "lg:backdrop-blur-lg"
  | "md:backdrop-blur-lg"
  | "sm:backdrop-blur-lg"
  | "xs:backdrop-blur-lg"
  | "2xl:backdrop-blur-xl"
  | "1xl:backdrop-blur-xl"
  | "xl:backdrop-blur-xl"
  | "lg:backdrop-blur-xl"
  | "md:backdrop-blur-xl"
  | "sm:backdrop-blur-xl"
  | "xs:backdrop-blur-xl"
  | "2xl:backdrop-blur-2xl"
  | "1xl:backdrop-blur-2xl"
  | "xl:backdrop-blur-2xl"
  | "lg:backdrop-blur-2xl"
  | "md:backdrop-blur-2xl"
  | "sm:backdrop-blur-2xl"
  | "xs:backdrop-blur-2xl"
  | "2xl:backdrop-blur-3xl"
  | "1xl:backdrop-blur-3xl"
  | "xl:backdrop-blur-3xl"
  | "lg:backdrop-blur-3xl"
  | "md:backdrop-blur-3xl"
  | "sm:backdrop-blur-3xl"
  | "xs:backdrop-blur-3xl"
  | "2xl:backdrop-brightness-0"
  | "1xl:backdrop-brightness-0"
  | "xl:backdrop-brightness-0"
  | "lg:backdrop-brightness-0"
  | "md:backdrop-brightness-0"
  | "sm:backdrop-brightness-0"
  | "xs:backdrop-brightness-0"
  | "2xl:backdrop-brightness-50"
  | "1xl:backdrop-brightness-50"
  | "xl:backdrop-brightness-50"
  | "lg:backdrop-brightness-50"
  | "md:backdrop-brightness-50"
  | "sm:backdrop-brightness-50"
  | "xs:backdrop-brightness-50"
  | "2xl:backdrop-brightness-75"
  | "1xl:backdrop-brightness-75"
  | "xl:backdrop-brightness-75"
  | "lg:backdrop-brightness-75"
  | "md:backdrop-brightness-75"
  | "sm:backdrop-brightness-75"
  | "xs:backdrop-brightness-75"
  | "2xl:backdrop-brightness-90"
  | "1xl:backdrop-brightness-90"
  | "xl:backdrop-brightness-90"
  | "lg:backdrop-brightness-90"
  | "md:backdrop-brightness-90"
  | "sm:backdrop-brightness-90"
  | "xs:backdrop-brightness-90"
  | "2xl:backdrop-brightness-95"
  | "1xl:backdrop-brightness-95"
  | "xl:backdrop-brightness-95"
  | "lg:backdrop-brightness-95"
  | "md:backdrop-brightness-95"
  | "sm:backdrop-brightness-95"
  | "xs:backdrop-brightness-95"
  | "2xl:backdrop-brightness-100"
  | "1xl:backdrop-brightness-100"
  | "xl:backdrop-brightness-100"
  | "lg:backdrop-brightness-100"
  | "md:backdrop-brightness-100"
  | "sm:backdrop-brightness-100"
  | "xs:backdrop-brightness-100"
  | "2xl:backdrop-brightness-105"
  | "1xl:backdrop-brightness-105"
  | "xl:backdrop-brightness-105"
  | "lg:backdrop-brightness-105"
  | "md:backdrop-brightness-105"
  | "sm:backdrop-brightness-105"
  | "xs:backdrop-brightness-105"
  | "2xl:backdrop-brightness-110"
  | "1xl:backdrop-brightness-110"
  | "xl:backdrop-brightness-110"
  | "lg:backdrop-brightness-110"
  | "md:backdrop-brightness-110"
  | "sm:backdrop-brightness-110"
  | "xs:backdrop-brightness-110"
  | "2xl:backdrop-brightness-125"
  | "1xl:backdrop-brightness-125"
  | "xl:backdrop-brightness-125"
  | "lg:backdrop-brightness-125"
  | "md:backdrop-brightness-125"
  | "sm:backdrop-brightness-125"
  | "xs:backdrop-brightness-125"
  | "2xl:backdrop-brightness-150"
  | "1xl:backdrop-brightness-150"
  | "xl:backdrop-brightness-150"
  | "lg:backdrop-brightness-150"
  | "md:backdrop-brightness-150"
  | "sm:backdrop-brightness-150"
  | "xs:backdrop-brightness-150"
  | "2xl:backdrop-brightness-200"
  | "1xl:backdrop-brightness-200"
  | "xl:backdrop-brightness-200"
  | "lg:backdrop-brightness-200"
  | "md:backdrop-brightness-200"
  | "sm:backdrop-brightness-200"
  | "xs:backdrop-brightness-200"
  | "2xl:backdrop-contrast-0"
  | "1xl:backdrop-contrast-0"
  | "xl:backdrop-contrast-0"
  | "lg:backdrop-contrast-0"
  | "md:backdrop-contrast-0"
  | "sm:backdrop-contrast-0"
  | "xs:backdrop-contrast-0"
  | "2xl:backdrop-contrast-50"
  | "1xl:backdrop-contrast-50"
  | "xl:backdrop-contrast-50"
  | "lg:backdrop-contrast-50"
  | "md:backdrop-contrast-50"
  | "sm:backdrop-contrast-50"
  | "xs:backdrop-contrast-50"
  | "2xl:backdrop-contrast-75"
  | "1xl:backdrop-contrast-75"
  | "xl:backdrop-contrast-75"
  | "lg:backdrop-contrast-75"
  | "md:backdrop-contrast-75"
  | "sm:backdrop-contrast-75"
  | "xs:backdrop-contrast-75"
  | "2xl:backdrop-contrast-100"
  | "1xl:backdrop-contrast-100"
  | "xl:backdrop-contrast-100"
  | "lg:backdrop-contrast-100"
  | "md:backdrop-contrast-100"
  | "sm:backdrop-contrast-100"
  | "xs:backdrop-contrast-100"
  | "2xl:backdrop-contrast-125"
  | "1xl:backdrop-contrast-125"
  | "xl:backdrop-contrast-125"
  | "lg:backdrop-contrast-125"
  | "md:backdrop-contrast-125"
  | "sm:backdrop-contrast-125"
  | "xs:backdrop-contrast-125"
  | "2xl:backdrop-contrast-150"
  | "1xl:backdrop-contrast-150"
  | "xl:backdrop-contrast-150"
  | "lg:backdrop-contrast-150"
  | "md:backdrop-contrast-150"
  | "sm:backdrop-contrast-150"
  | "xs:backdrop-contrast-150"
  | "2xl:backdrop-contrast-200"
  | "1xl:backdrop-contrast-200"
  | "xl:backdrop-contrast-200"
  | "lg:backdrop-contrast-200"
  | "md:backdrop-contrast-200"
  | "sm:backdrop-contrast-200"
  | "xs:backdrop-contrast-200"
  | "2xl:backdrop-filter"
  | "1xl:backdrop-filter"
  | "xl:backdrop-filter"
  | "lg:backdrop-filter"
  | "md:backdrop-filter"
  | "sm:backdrop-filter"
  | "xs:backdrop-filter"
  | "2xl:backdrop-filter-none"
  | "1xl:backdrop-filter-none"
  | "xl:backdrop-filter-none"
  | "lg:backdrop-filter-none"
  | "md:backdrop-filter-none"
  | "sm:backdrop-filter-none"
  | "xs:backdrop-filter-none"
  | "2xl:backdrop-grayscale-0"
  | "1xl:backdrop-grayscale-0"
  | "xl:backdrop-grayscale-0"
  | "lg:backdrop-grayscale-0"
  | "md:backdrop-grayscale-0"
  | "sm:backdrop-grayscale-0"
  | "xs:backdrop-grayscale-0"
  | "2xl:backdrop-grayscale"
  | "1xl:backdrop-grayscale"
  | "xl:backdrop-grayscale"
  | "lg:backdrop-grayscale"
  | "md:backdrop-grayscale"
  | "sm:backdrop-grayscale"
  | "xs:backdrop-grayscale"
  | "2xl:backdrop-hue-rotate-0"
  | "1xl:backdrop-hue-rotate-0"
  | "xl:backdrop-hue-rotate-0"
  | "lg:backdrop-hue-rotate-0"
  | "md:backdrop-hue-rotate-0"
  | "sm:backdrop-hue-rotate-0"
  | "xs:backdrop-hue-rotate-0"
  | "2xl:backdrop-hue-rotate-15"
  | "1xl:backdrop-hue-rotate-15"
  | "xl:backdrop-hue-rotate-15"
  | "lg:backdrop-hue-rotate-15"
  | "md:backdrop-hue-rotate-15"
  | "sm:backdrop-hue-rotate-15"
  | "xs:backdrop-hue-rotate-15"
  | "2xl:backdrop-hue-rotate-30"
  | "1xl:backdrop-hue-rotate-30"
  | "xl:backdrop-hue-rotate-30"
  | "lg:backdrop-hue-rotate-30"
  | "md:backdrop-hue-rotate-30"
  | "sm:backdrop-hue-rotate-30"
  | "xs:backdrop-hue-rotate-30"
  | "2xl:backdrop-hue-rotate-60"
  | "1xl:backdrop-hue-rotate-60"
  | "xl:backdrop-hue-rotate-60"
  | "lg:backdrop-hue-rotate-60"
  | "md:backdrop-hue-rotate-60"
  | "sm:backdrop-hue-rotate-60"
  | "xs:backdrop-hue-rotate-60"
  | "2xl:backdrop-hue-rotate-90"
  | "1xl:backdrop-hue-rotate-90"
  | "xl:backdrop-hue-rotate-90"
  | "lg:backdrop-hue-rotate-90"
  | "md:backdrop-hue-rotate-90"
  | "sm:backdrop-hue-rotate-90"
  | "xs:backdrop-hue-rotate-90"
  | "2xl:backdrop-hue-rotate-180"
  | "1xl:backdrop-hue-rotate-180"
  | "xl:backdrop-hue-rotate-180"
  | "lg:backdrop-hue-rotate-180"
  | "md:backdrop-hue-rotate-180"
  | "sm:backdrop-hue-rotate-180"
  | "xs:backdrop-hue-rotate-180"
  | "2xl:backdrop-hue-rotate--180"
  | "1xl:backdrop-hue-rotate--180"
  | "xl:backdrop-hue-rotate--180"
  | "lg:backdrop-hue-rotate--180"
  | "md:backdrop-hue-rotate--180"
  | "sm:backdrop-hue-rotate--180"
  | "xs:backdrop-hue-rotate--180"
  | "2xl:backdrop-hue-rotate--90"
  | "1xl:backdrop-hue-rotate--90"
  | "xl:backdrop-hue-rotate--90"
  | "lg:backdrop-hue-rotate--90"
  | "md:backdrop-hue-rotate--90"
  | "sm:backdrop-hue-rotate--90"
  | "xs:backdrop-hue-rotate--90"
  | "2xl:backdrop-hue-rotate--60"
  | "1xl:backdrop-hue-rotate--60"
  | "xl:backdrop-hue-rotate--60"
  | "lg:backdrop-hue-rotate--60"
  | "md:backdrop-hue-rotate--60"
  | "sm:backdrop-hue-rotate--60"
  | "xs:backdrop-hue-rotate--60"
  | "2xl:backdrop-hue-rotate--30"
  | "1xl:backdrop-hue-rotate--30"
  | "xl:backdrop-hue-rotate--30"
  | "lg:backdrop-hue-rotate--30"
  | "md:backdrop-hue-rotate--30"
  | "sm:backdrop-hue-rotate--30"
  | "xs:backdrop-hue-rotate--30"
  | "2xl:backdrop-hue-rotate--15"
  | "1xl:backdrop-hue-rotate--15"
  | "xl:backdrop-hue-rotate--15"
  | "lg:backdrop-hue-rotate--15"
  | "md:backdrop-hue-rotate--15"
  | "sm:backdrop-hue-rotate--15"
  | "xs:backdrop-hue-rotate--15"
  | "2xl:backdrop-invert-0"
  | "1xl:backdrop-invert-0"
  | "xl:backdrop-invert-0"
  | "lg:backdrop-invert-0"
  | "md:backdrop-invert-0"
  | "sm:backdrop-invert-0"
  | "xs:backdrop-invert-0"
  | "2xl:backdrop-invert"
  | "1xl:backdrop-invert"
  | "xl:backdrop-invert"
  | "lg:backdrop-invert"
  | "md:backdrop-invert"
  | "sm:backdrop-invert"
  | "xs:backdrop-invert"
  | "2xl:backdrop-saturate-0"
  | "1xl:backdrop-saturate-0"
  | "xl:backdrop-saturate-0"
  | "lg:backdrop-saturate-0"
  | "md:backdrop-saturate-0"
  | "sm:backdrop-saturate-0"
  | "xs:backdrop-saturate-0"
  | "2xl:backdrop-saturate-50"
  | "1xl:backdrop-saturate-50"
  | "xl:backdrop-saturate-50"
  | "lg:backdrop-saturate-50"
  | "md:backdrop-saturate-50"
  | "sm:backdrop-saturate-50"
  | "xs:backdrop-saturate-50"
  | "2xl:backdrop-saturate-100"
  | "1xl:backdrop-saturate-100"
  | "xl:backdrop-saturate-100"
  | "lg:backdrop-saturate-100"
  | "md:backdrop-saturate-100"
  | "sm:backdrop-saturate-100"
  | "xs:backdrop-saturate-100"
  | "2xl:backdrop-saturate-150"
  | "1xl:backdrop-saturate-150"
  | "xl:backdrop-saturate-150"
  | "lg:backdrop-saturate-150"
  | "md:backdrop-saturate-150"
  | "sm:backdrop-saturate-150"
  | "xs:backdrop-saturate-150"
  | "2xl:backdrop-saturate-200"
  | "1xl:backdrop-saturate-200"
  | "xl:backdrop-saturate-200"
  | "lg:backdrop-saturate-200"
  | "md:backdrop-saturate-200"
  | "sm:backdrop-saturate-200"
  | "xs:backdrop-saturate-200"
  | "2xl:backdrop-sepia-0"
  | "1xl:backdrop-sepia-0"
  | "xl:backdrop-sepia-0"
  | "lg:backdrop-sepia-0"
  | "md:backdrop-sepia-0"
  | "sm:backdrop-sepia-0"
  | "xs:backdrop-sepia-0"
  | "2xl:backdrop-sepia"
  | "1xl:backdrop-sepia"
  | "xl:backdrop-sepia"
  | "lg:backdrop-sepia"
  | "md:backdrop-sepia"
  | "sm:backdrop-sepia"
  | "xs:backdrop-sepia"
  | "2xl:bg-fixed"
  | "1xl:bg-fixed"
  | "xl:bg-fixed"
  | "lg:bg-fixed"
  | "md:bg-fixed"
  | "sm:bg-fixed"
  | "xs:bg-fixed"
  | "2xl:bg-local"
  | "1xl:bg-local"
  | "xl:bg-local"
  | "lg:bg-local"
  | "md:bg-local"
  | "sm:bg-local"
  | "xs:bg-local"
  | "2xl:bg-scroll"
  | "1xl:bg-scroll"
  | "xl:bg-scroll"
  | "lg:bg-scroll"
  | "md:bg-scroll"
  | "sm:bg-scroll"
  | "xs:bg-scroll"
  | "2xl:bg-blend-normal"
  | "1xl:bg-blend-normal"
  | "xl:bg-blend-normal"
  | "lg:bg-blend-normal"
  | "md:bg-blend-normal"
  | "sm:bg-blend-normal"
  | "xs:bg-blend-normal"
  | "2xl:bg-blend-multiply"
  | "1xl:bg-blend-multiply"
  | "xl:bg-blend-multiply"
  | "lg:bg-blend-multiply"
  | "md:bg-blend-multiply"
  | "sm:bg-blend-multiply"
  | "xs:bg-blend-multiply"
  | "2xl:bg-blend-screen"
  | "1xl:bg-blend-screen"
  | "xl:bg-blend-screen"
  | "lg:bg-blend-screen"
  | "md:bg-blend-screen"
  | "sm:bg-blend-screen"
  | "xs:bg-blend-screen"
  | "2xl:bg-blend-overlay"
  | "1xl:bg-blend-overlay"
  | "xl:bg-blend-overlay"
  | "lg:bg-blend-overlay"
  | "md:bg-blend-overlay"
  | "sm:bg-blend-overlay"
  | "xs:bg-blend-overlay"
  | "2xl:bg-blend-darken"
  | "1xl:bg-blend-darken"
  | "xl:bg-blend-darken"
  | "lg:bg-blend-darken"
  | "md:bg-blend-darken"
  | "sm:bg-blend-darken"
  | "xs:bg-blend-darken"
  | "2xl:bg-blend-lighten"
  | "1xl:bg-blend-lighten"
  | "xl:bg-blend-lighten"
  | "lg:bg-blend-lighten"
  | "md:bg-blend-lighten"
  | "sm:bg-blend-lighten"
  | "xs:bg-blend-lighten"
  | "2xl:bg-blend-color-dodge"
  | "1xl:bg-blend-color-dodge"
  | "xl:bg-blend-color-dodge"
  | "lg:bg-blend-color-dodge"
  | "md:bg-blend-color-dodge"
  | "sm:bg-blend-color-dodge"
  | "xs:bg-blend-color-dodge"
  | "2xl:bg-blend-color-burn"
  | "1xl:bg-blend-color-burn"
  | "xl:bg-blend-color-burn"
  | "lg:bg-blend-color-burn"
  | "md:bg-blend-color-burn"
  | "sm:bg-blend-color-burn"
  | "xs:bg-blend-color-burn"
  | "2xl:bg-blend-hard-light"
  | "1xl:bg-blend-hard-light"
  | "xl:bg-blend-hard-light"
  | "lg:bg-blend-hard-light"
  | "md:bg-blend-hard-light"
  | "sm:bg-blend-hard-light"
  | "xs:bg-blend-hard-light"
  | "2xl:bg-blend-soft-light"
  | "1xl:bg-blend-soft-light"
  | "xl:bg-blend-soft-light"
  | "lg:bg-blend-soft-light"
  | "md:bg-blend-soft-light"
  | "sm:bg-blend-soft-light"
  | "xs:bg-blend-soft-light"
  | "2xl:bg-blend-difference"
  | "1xl:bg-blend-difference"
  | "xl:bg-blend-difference"
  | "lg:bg-blend-difference"
  | "md:bg-blend-difference"
  | "sm:bg-blend-difference"
  | "xs:bg-blend-difference"
  | "2xl:bg-blend-exclusion"
  | "1xl:bg-blend-exclusion"
  | "xl:bg-blend-exclusion"
  | "lg:bg-blend-exclusion"
  | "md:bg-blend-exclusion"
  | "sm:bg-blend-exclusion"
  | "xs:bg-blend-exclusion"
  | "2xl:bg-blend-hue"
  | "1xl:bg-blend-hue"
  | "xl:bg-blend-hue"
  | "lg:bg-blend-hue"
  | "md:bg-blend-hue"
  | "sm:bg-blend-hue"
  | "xs:bg-blend-hue"
  | "2xl:bg-blend-saturation"
  | "1xl:bg-blend-saturation"
  | "xl:bg-blend-saturation"
  | "lg:bg-blend-saturation"
  | "md:bg-blend-saturation"
  | "sm:bg-blend-saturation"
  | "xs:bg-blend-saturation"
  | "2xl:bg-blend-color"
  | "1xl:bg-blend-color"
  | "xl:bg-blend-color"
  | "lg:bg-blend-color"
  | "md:bg-blend-color"
  | "sm:bg-blend-color"
  | "xs:bg-blend-color"
  | "2xl:bg-blend-luminosity"
  | "1xl:bg-blend-luminosity"
  | "xl:bg-blend-luminosity"
  | "lg:bg-blend-luminosity"
  | "md:bg-blend-luminosity"
  | "sm:bg-blend-luminosity"
  | "xs:bg-blend-luminosity"
  | "2xl:bg-clip-border"
  | "1xl:bg-clip-border"
  | "xl:bg-clip-border"
  | "lg:bg-clip-border"
  | "md:bg-clip-border"
  | "sm:bg-clip-border"
  | "xs:bg-clip-border"
  | "2xl:bg-clip-padding"
  | "1xl:bg-clip-padding"
  | "xl:bg-clip-padding"
  | "lg:bg-clip-padding"
  | "md:bg-clip-padding"
  | "sm:bg-clip-padding"
  | "xs:bg-clip-padding"
  | "2xl:bg-clip-content"
  | "1xl:bg-clip-content"
  | "xl:bg-clip-content"
  | "lg:bg-clip-content"
  | "md:bg-clip-content"
  | "sm:bg-clip-content"
  | "xs:bg-clip-content"
  | "2xl:bg-clip-text"
  | "1xl:bg-clip-text"
  | "xl:bg-clip-text"
  | "lg:bg-clip-text"
  | "md:bg-clip-text"
  | "sm:bg-clip-text"
  | "xs:bg-clip-text"
  | "2xl:bg-transparent"
  | "1xl:bg-transparent"
  | "xl:bg-transparent"
  | "lg:bg-transparent"
  | "md:bg-transparent"
  | "sm:bg-transparent"
  | "xs:bg-transparent"
  | "group-hover:bg-transparent"
  | "group"
  | "focus-within:bg-transparent"
  | "hover:bg-transparent"
  | "focus:bg-transparent"
  | "checked:bg-transparent"
  | "odd:bg-transparent"
  | "even:bg-transparent"
  | "checked:bg-transparent"
  | "odd:bg-transparent"
  | "even:bg-transparent"
  | "2xl:bg-current"
  | "1xl:bg-current"
  | "xl:bg-current"
  | "lg:bg-current"
  | "md:bg-current"
  | "sm:bg-current"
  | "xs:bg-current"
  | "group-hover:bg-current"
  | "focus-within:bg-current"
  | "hover:bg-current"
  | "focus:bg-current"
  | "checked:bg-current"
  | "odd:bg-current"
  | "even:bg-current"
  | "checked:bg-current"
  | "odd:bg-current"
  | "even:bg-current"
  | "2xl:bg-black"
  | "1xl:bg-black"
  | "xl:bg-black"
  | "lg:bg-black"
  | "md:bg-black"
  | "sm:bg-black"
  | "xs:bg-black"
  | "group-hover:bg-black"
  | "focus-within:bg-black"
  | "hover:bg-black"
  | "focus:bg-black"
  | "checked:bg-black"
  | "odd:bg-black"
  | "even:bg-black"
  | "checked:bg-black"
  | "odd:bg-black"
  | "even:bg-black"
  | "2xl:bg-white"
  | "1xl:bg-white"
  | "xl:bg-white"
  | "lg:bg-white"
  | "md:bg-white"
  | "sm:bg-white"
  | "xs:bg-white"
  | "group-hover:bg-white"
  | "focus-within:bg-white"
  | "hover:bg-white"
  | "focus:bg-white"
  | "checked:bg-white"
  | "odd:bg-white"
  | "even:bg-white"
  | "checked:bg-white"
  | "odd:bg-white"
  | "even:bg-white"
  | "2xl:bg-gray-50"
  | "1xl:bg-gray-50"
  | "xl:bg-gray-50"
  | "lg:bg-gray-50"
  | "md:bg-gray-50"
  | "sm:bg-gray-50"
  | "xs:bg-gray-50"
  | "group-hover:bg-gray-50"
  | "focus-within:bg-gray-50"
  | "hover:bg-gray-50"
  | "focus:bg-gray-50"
  | "checked:bg-gray-50"
  | "odd:bg-gray-50"
  | "even:bg-gray-50"
  | "checked:bg-gray-50"
  | "odd:bg-gray-50"
  | "even:bg-gray-50"
  | "2xl:bg-gray-100"
  | "1xl:bg-gray-100"
  | "xl:bg-gray-100"
  | "lg:bg-gray-100"
  | "md:bg-gray-100"
  | "sm:bg-gray-100"
  | "xs:bg-gray-100"
  | "group-hover:bg-gray-100"
  | "focus-within:bg-gray-100"
  | "hover:bg-gray-100"
  | "focus:bg-gray-100"
  | "checked:bg-gray-100"
  | "odd:bg-gray-100"
  | "even:bg-gray-100"
  | "checked:bg-gray-100"
  | "odd:bg-gray-100"
  | "even:bg-gray-100"
  | "2xl:bg-gray-200"
  | "1xl:bg-gray-200"
  | "xl:bg-gray-200"
  | "lg:bg-gray-200"
  | "md:bg-gray-200"
  | "sm:bg-gray-200"
  | "xs:bg-gray-200"
  | "group-hover:bg-gray-200"
  | "focus-within:bg-gray-200"
  | "hover:bg-gray-200"
  | "focus:bg-gray-200"
  | "checked:bg-gray-200"
  | "odd:bg-gray-200"
  | "even:bg-gray-200"
  | "checked:bg-gray-200"
  | "odd:bg-gray-200"
  | "even:bg-gray-200"
  | "2xl:bg-gray-300"
  | "1xl:bg-gray-300"
  | "xl:bg-gray-300"
  | "lg:bg-gray-300"
  | "md:bg-gray-300"
  | "sm:bg-gray-300"
  | "xs:bg-gray-300"
  | "group-hover:bg-gray-300"
  | "focus-within:bg-gray-300"
  | "hover:bg-gray-300"
  | "focus:bg-gray-300"
  | "checked:bg-gray-300"
  | "odd:bg-gray-300"
  | "even:bg-gray-300"
  | "checked:bg-gray-300"
  | "odd:bg-gray-300"
  | "even:bg-gray-300"
  | "2xl:bg-gray-400"
  | "1xl:bg-gray-400"
  | "xl:bg-gray-400"
  | "lg:bg-gray-400"
  | "md:bg-gray-400"
  | "sm:bg-gray-400"
  | "xs:bg-gray-400"
  | "group-hover:bg-gray-400"
  | "focus-within:bg-gray-400"
  | "hover:bg-gray-400"
  | "focus:bg-gray-400"
  | "checked:bg-gray-400"
  | "odd:bg-gray-400"
  | "even:bg-gray-400"
  | "checked:bg-gray-400"
  | "odd:bg-gray-400"
  | "even:bg-gray-400"
  | "2xl:bg-gray-500"
  | "1xl:bg-gray-500"
  | "xl:bg-gray-500"
  | "lg:bg-gray-500"
  | "md:bg-gray-500"
  | "sm:bg-gray-500"
  | "xs:bg-gray-500"
  | "group-hover:bg-gray-500"
  | "focus-within:bg-gray-500"
  | "hover:bg-gray-500"
  | "focus:bg-gray-500"
  | "checked:bg-gray-500"
  | "odd:bg-gray-500"
  | "even:bg-gray-500"
  | "checked:bg-gray-500"
  | "odd:bg-gray-500"
  | "even:bg-gray-500"
  | "2xl:bg-gray-600"
  | "1xl:bg-gray-600"
  | "xl:bg-gray-600"
  | "lg:bg-gray-600"
  | "md:bg-gray-600"
  | "sm:bg-gray-600"
  | "xs:bg-gray-600"
  | "group-hover:bg-gray-600"
  | "focus-within:bg-gray-600"
  | "hover:bg-gray-600"
  | "focus:bg-gray-600"
  | "checked:bg-gray-600"
  | "odd:bg-gray-600"
  | "even:bg-gray-600"
  | "checked:bg-gray-600"
  | "odd:bg-gray-600"
  | "even:bg-gray-600"
  | "2xl:bg-gray-700"
  | "1xl:bg-gray-700"
  | "xl:bg-gray-700"
  | "lg:bg-gray-700"
  | "md:bg-gray-700"
  | "sm:bg-gray-700"
  | "xs:bg-gray-700"
  | "group-hover:bg-gray-700"
  | "focus-within:bg-gray-700"
  | "hover:bg-gray-700"
  | "focus:bg-gray-700"
  | "checked:bg-gray-700"
  | "odd:bg-gray-700"
  | "even:bg-gray-700"
  | "checked:bg-gray-700"
  | "odd:bg-gray-700"
  | "even:bg-gray-700"
  | "2xl:bg-gray-800"
  | "1xl:bg-gray-800"
  | "xl:bg-gray-800"
  | "lg:bg-gray-800"
  | "md:bg-gray-800"
  | "sm:bg-gray-800"
  | "xs:bg-gray-800"
  | "group-hover:bg-gray-800"
  | "focus-within:bg-gray-800"
  | "hover:bg-gray-800"
  | "focus:bg-gray-800"
  | "checked:bg-gray-800"
  | "odd:bg-gray-800"
  | "even:bg-gray-800"
  | "checked:bg-gray-800"
  | "odd:bg-gray-800"
  | "even:bg-gray-800"
  | "2xl:bg-gray-900"
  | "1xl:bg-gray-900"
  | "xl:bg-gray-900"
  | "lg:bg-gray-900"
  | "md:bg-gray-900"
  | "sm:bg-gray-900"
  | "xs:bg-gray-900"
  | "group-hover:bg-gray-900"
  | "focus-within:bg-gray-900"
  | "hover:bg-gray-900"
  | "focus:bg-gray-900"
  | "checked:bg-gray-900"
  | "odd:bg-gray-900"
  | "even:bg-gray-900"
  | "checked:bg-gray-900"
  | "odd:bg-gray-900"
  | "even:bg-gray-900"
  | "2xl:bg-red-50"
  | "1xl:bg-red-50"
  | "xl:bg-red-50"
  | "lg:bg-red-50"
  | "md:bg-red-50"
  | "sm:bg-red-50"
  | "xs:bg-red-50"
  | "group-hover:bg-red-50"
  | "focus-within:bg-red-50"
  | "hover:bg-red-50"
  | "focus:bg-red-50"
  | "checked:bg-red-50"
  | "odd:bg-red-50"
  | "even:bg-red-50"
  | "checked:bg-red-50"
  | "odd:bg-red-50"
  | "even:bg-red-50"
  | "2xl:bg-red-100"
  | "1xl:bg-red-100"
  | "xl:bg-red-100"
  | "lg:bg-red-100"
  | "md:bg-red-100"
  | "sm:bg-red-100"
  | "xs:bg-red-100"
  | "group-hover:bg-red-100"
  | "focus-within:bg-red-100"
  | "hover:bg-red-100"
  | "focus:bg-red-100"
  | "checked:bg-red-100"
  | "odd:bg-red-100"
  | "even:bg-red-100"
  | "checked:bg-red-100"
  | "odd:bg-red-100"
  | "even:bg-red-100"
  | "2xl:bg-red-200"
  | "1xl:bg-red-200"
  | "xl:bg-red-200"
  | "lg:bg-red-200"
  | "md:bg-red-200"
  | "sm:bg-red-200"
  | "xs:bg-red-200"
  | "group-hover:bg-red-200"
  | "focus-within:bg-red-200"
  | "hover:bg-red-200"
  | "focus:bg-red-200"
  | "checked:bg-red-200"
  | "odd:bg-red-200"
  | "even:bg-red-200"
  | "checked:bg-red-200"
  | "odd:bg-red-200"
  | "even:bg-red-200"
  | "2xl:bg-red-300"
  | "1xl:bg-red-300"
  | "xl:bg-red-300"
  | "lg:bg-red-300"
  | "md:bg-red-300"
  | "sm:bg-red-300"
  | "xs:bg-red-300"
  | "group-hover:bg-red-300"
  | "focus-within:bg-red-300"
  | "hover:bg-red-300"
  | "focus:bg-red-300"
  | "checked:bg-red-300"
  | "odd:bg-red-300"
  | "even:bg-red-300"
  | "checked:bg-red-300"
  | "odd:bg-red-300"
  | "even:bg-red-300"
  | "2xl:bg-red-400"
  | "1xl:bg-red-400"
  | "xl:bg-red-400"
  | "lg:bg-red-400"
  | "md:bg-red-400"
  | "sm:bg-red-400"
  | "xs:bg-red-400"
  | "group-hover:bg-red-400"
  | "focus-within:bg-red-400"
  | "hover:bg-red-400"
  | "focus:bg-red-400"
  | "checked:bg-red-400"
  | "odd:bg-red-400"
  | "even:bg-red-400"
  | "checked:bg-red-400"
  | "odd:bg-red-400"
  | "even:bg-red-400"
  | "2xl:bg-red-500"
  | "1xl:bg-red-500"
  | "xl:bg-red-500"
  | "lg:bg-red-500"
  | "md:bg-red-500"
  | "sm:bg-red-500"
  | "xs:bg-red-500"
  | "group-hover:bg-red-500"
  | "focus-within:bg-red-500"
  | "hover:bg-red-500"
  | "focus:bg-red-500"
  | "checked:bg-red-500"
  | "odd:bg-red-500"
  | "even:bg-red-500"
  | "checked:bg-red-500"
  | "odd:bg-red-500"
  | "even:bg-red-500"
  | "2xl:bg-red-600"
  | "1xl:bg-red-600"
  | "xl:bg-red-600"
  | "lg:bg-red-600"
  | "md:bg-red-600"
  | "sm:bg-red-600"
  | "xs:bg-red-600"
  | "group-hover:bg-red-600"
  | "focus-within:bg-red-600"
  | "hover:bg-red-600"
  | "focus:bg-red-600"
  | "checked:bg-red-600"
  | "odd:bg-red-600"
  | "even:bg-red-600"
  | "checked:bg-red-600"
  | "odd:bg-red-600"
  | "even:bg-red-600"
  | "2xl:bg-red-700"
  | "1xl:bg-red-700"
  | "xl:bg-red-700"
  | "lg:bg-red-700"
  | "md:bg-red-700"
  | "sm:bg-red-700"
  | "xs:bg-red-700"
  | "group-hover:bg-red-700"
  | "focus-within:bg-red-700"
  | "hover:bg-red-700"
  | "focus:bg-red-700"
  | "checked:bg-red-700"
  | "odd:bg-red-700"
  | "even:bg-red-700"
  | "checked:bg-red-700"
  | "odd:bg-red-700"
  | "even:bg-red-700"
  | "2xl:bg-red-800"
  | "1xl:bg-red-800"
  | "xl:bg-red-800"
  | "lg:bg-red-800"
  | "md:bg-red-800"
  | "sm:bg-red-800"
  | "xs:bg-red-800"
  | "group-hover:bg-red-800"
  | "focus-within:bg-red-800"
  | "hover:bg-red-800"
  | "focus:bg-red-800"
  | "checked:bg-red-800"
  | "odd:bg-red-800"
  | "even:bg-red-800"
  | "checked:bg-red-800"
  | "odd:bg-red-800"
  | "even:bg-red-800"
  | "2xl:bg-red-900"
  | "1xl:bg-red-900"
  | "xl:bg-red-900"
  | "lg:bg-red-900"
  | "md:bg-red-900"
  | "sm:bg-red-900"
  | "xs:bg-red-900"
  | "group-hover:bg-red-900"
  | "focus-within:bg-red-900"
  | "hover:bg-red-900"
  | "focus:bg-red-900"
  | "checked:bg-red-900"
  | "odd:bg-red-900"
  | "even:bg-red-900"
  | "checked:bg-red-900"
  | "odd:bg-red-900"
  | "even:bg-red-900"
  | "2xl:bg-yellow-50"
  | "1xl:bg-yellow-50"
  | "xl:bg-yellow-50"
  | "lg:bg-yellow-50"
  | "md:bg-yellow-50"
  | "sm:bg-yellow-50"
  | "xs:bg-yellow-50"
  | "group-hover:bg-yellow-50"
  | "focus-within:bg-yellow-50"
  | "hover:bg-yellow-50"
  | "focus:bg-yellow-50"
  | "checked:bg-yellow-50"
  | "odd:bg-yellow-50"
  | "even:bg-yellow-50"
  | "checked:bg-yellow-50"
  | "odd:bg-yellow-50"
  | "even:bg-yellow-50"
  | "2xl:bg-yellow-100"
  | "1xl:bg-yellow-100"
  | "xl:bg-yellow-100"
  | "lg:bg-yellow-100"
  | "md:bg-yellow-100"
  | "sm:bg-yellow-100"
  | "xs:bg-yellow-100"
  | "group-hover:bg-yellow-100"
  | "focus-within:bg-yellow-100"
  | "hover:bg-yellow-100"
  | "focus:bg-yellow-100"
  | "checked:bg-yellow-100"
  | "odd:bg-yellow-100"
  | "even:bg-yellow-100"
  | "checked:bg-yellow-100"
  | "odd:bg-yellow-100"
  | "even:bg-yellow-100"
  | "2xl:bg-yellow-200"
  | "1xl:bg-yellow-200"
  | "xl:bg-yellow-200"
  | "lg:bg-yellow-200"
  | "md:bg-yellow-200"
  | "sm:bg-yellow-200"
  | "xs:bg-yellow-200"
  | "group-hover:bg-yellow-200"
  | "focus-within:bg-yellow-200"
  | "hover:bg-yellow-200"
  | "focus:bg-yellow-200"
  | "checked:bg-yellow-200"
  | "odd:bg-yellow-200"
  | "even:bg-yellow-200"
  | "checked:bg-yellow-200"
  | "odd:bg-yellow-200"
  | "even:bg-yellow-200"
  | "2xl:bg-yellow-300"
  | "1xl:bg-yellow-300"
  | "xl:bg-yellow-300"
  | "lg:bg-yellow-300"
  | "md:bg-yellow-300"
  | "sm:bg-yellow-300"
  | "xs:bg-yellow-300"
  | "group-hover:bg-yellow-300"
  | "focus-within:bg-yellow-300"
  | "hover:bg-yellow-300"
  | "focus:bg-yellow-300"
  | "checked:bg-yellow-300"
  | "odd:bg-yellow-300"
  | "even:bg-yellow-300"
  | "checked:bg-yellow-300"
  | "odd:bg-yellow-300"
  | "even:bg-yellow-300"
  | "2xl:bg-yellow-400"
  | "1xl:bg-yellow-400"
  | "xl:bg-yellow-400"
  | "lg:bg-yellow-400"
  | "md:bg-yellow-400"
  | "sm:bg-yellow-400"
  | "xs:bg-yellow-400"
  | "group-hover:bg-yellow-400"
  | "focus-within:bg-yellow-400"
  | "hover:bg-yellow-400"
  | "focus:bg-yellow-400"
  | "checked:bg-yellow-400"
  | "odd:bg-yellow-400"
  | "even:bg-yellow-400"
  | "checked:bg-yellow-400"
  | "odd:bg-yellow-400"
  | "even:bg-yellow-400"
  | "2xl:bg-yellow-500"
  | "1xl:bg-yellow-500"
  | "xl:bg-yellow-500"
  | "lg:bg-yellow-500"
  | "md:bg-yellow-500"
  | "sm:bg-yellow-500"
  | "xs:bg-yellow-500"
  | "group-hover:bg-yellow-500"
  | "focus-within:bg-yellow-500"
  | "hover:bg-yellow-500"
  | "focus:bg-yellow-500"
  | "checked:bg-yellow-500"
  | "odd:bg-yellow-500"
  | "even:bg-yellow-500"
  | "checked:bg-yellow-500"
  | "odd:bg-yellow-500"
  | "even:bg-yellow-500"
  | "2xl:bg-yellow-600"
  | "1xl:bg-yellow-600"
  | "xl:bg-yellow-600"
  | "lg:bg-yellow-600"
  | "md:bg-yellow-600"
  | "sm:bg-yellow-600"
  | "xs:bg-yellow-600"
  | "group-hover:bg-yellow-600"
  | "focus-within:bg-yellow-600"
  | "hover:bg-yellow-600"
  | "focus:bg-yellow-600"
  | "checked:bg-yellow-600"
  | "odd:bg-yellow-600"
  | "even:bg-yellow-600"
  | "checked:bg-yellow-600"
  | "odd:bg-yellow-600"
  | "even:bg-yellow-600"
  | "2xl:bg-yellow-700"
  | "1xl:bg-yellow-700"
  | "xl:bg-yellow-700"
  | "lg:bg-yellow-700"
  | "md:bg-yellow-700"
  | "sm:bg-yellow-700"
  | "xs:bg-yellow-700"
  | "group-hover:bg-yellow-700"
  | "focus-within:bg-yellow-700"
  | "hover:bg-yellow-700"
  | "focus:bg-yellow-700"
  | "checked:bg-yellow-700"
  | "odd:bg-yellow-700"
  | "even:bg-yellow-700"
  | "checked:bg-yellow-700"
  | "odd:bg-yellow-700"
  | "even:bg-yellow-700"
  | "2xl:bg-yellow-800"
  | "1xl:bg-yellow-800"
  | "xl:bg-yellow-800"
  | "lg:bg-yellow-800"
  | "md:bg-yellow-800"
  | "sm:bg-yellow-800"
  | "xs:bg-yellow-800"
  | "group-hover:bg-yellow-800"
  | "focus-within:bg-yellow-800"
  | "hover:bg-yellow-800"
  | "focus:bg-yellow-800"
  | "checked:bg-yellow-800"
  | "odd:bg-yellow-800"
  | "even:bg-yellow-800"
  | "checked:bg-yellow-800"
  | "odd:bg-yellow-800"
  | "even:bg-yellow-800"
  | "2xl:bg-yellow-900"
  | "1xl:bg-yellow-900"
  | "xl:bg-yellow-900"
  | "lg:bg-yellow-900"
  | "md:bg-yellow-900"
  | "sm:bg-yellow-900"
  | "xs:bg-yellow-900"
  | "group-hover:bg-yellow-900"
  | "focus-within:bg-yellow-900"
  | "hover:bg-yellow-900"
  | "focus:bg-yellow-900"
  | "checked:bg-yellow-900"
  | "odd:bg-yellow-900"
  | "even:bg-yellow-900"
  | "checked:bg-yellow-900"
  | "odd:bg-yellow-900"
  | "even:bg-yellow-900"
  | "2xl:bg-green-50"
  | "1xl:bg-green-50"
  | "xl:bg-green-50"
  | "lg:bg-green-50"
  | "md:bg-green-50"
  | "sm:bg-green-50"
  | "xs:bg-green-50"
  | "group-hover:bg-green-50"
  | "focus-within:bg-green-50"
  | "hover:bg-green-50"
  | "focus:bg-green-50"
  | "checked:bg-green-50"
  | "odd:bg-green-50"
  | "even:bg-green-50"
  | "checked:bg-green-50"
  | "odd:bg-green-50"
  | "even:bg-green-50"
  | "2xl:bg-green-100"
  | "1xl:bg-green-100"
  | "xl:bg-green-100"
  | "lg:bg-green-100"
  | "md:bg-green-100"
  | "sm:bg-green-100"
  | "xs:bg-green-100"
  | "group-hover:bg-green-100"
  | "focus-within:bg-green-100"
  | "hover:bg-green-100"
  | "focus:bg-green-100"
  | "checked:bg-green-100"
  | "odd:bg-green-100"
  | "even:bg-green-100"
  | "checked:bg-green-100"
  | "odd:bg-green-100"
  | "even:bg-green-100"
  | "2xl:bg-green-200"
  | "1xl:bg-green-200"
  | "xl:bg-green-200"
  | "lg:bg-green-200"
  | "md:bg-green-200"
  | "sm:bg-green-200"
  | "xs:bg-green-200"
  | "group-hover:bg-green-200"
  | "focus-within:bg-green-200"
  | "hover:bg-green-200"
  | "focus:bg-green-200"
  | "checked:bg-green-200"
  | "odd:bg-green-200"
  | "even:bg-green-200"
  | "checked:bg-green-200"
  | "odd:bg-green-200"
  | "even:bg-green-200"
  | "2xl:bg-green-300"
  | "1xl:bg-green-300"
  | "xl:bg-green-300"
  | "lg:bg-green-300"
  | "md:bg-green-300"
  | "sm:bg-green-300"
  | "xs:bg-green-300"
  | "group-hover:bg-green-300"
  | "focus-within:bg-green-300"
  | "hover:bg-green-300"
  | "focus:bg-green-300"
  | "checked:bg-green-300"
  | "odd:bg-green-300"
  | "even:bg-green-300"
  | "checked:bg-green-300"
  | "odd:bg-green-300"
  | "even:bg-green-300"
  | "2xl:bg-green-400"
  | "1xl:bg-green-400"
  | "xl:bg-green-400"
  | "lg:bg-green-400"
  | "md:bg-green-400"
  | "sm:bg-green-400"
  | "xs:bg-green-400"
  | "group-hover:bg-green-400"
  | "focus-within:bg-green-400"
  | "hover:bg-green-400"
  | "focus:bg-green-400"
  | "checked:bg-green-400"
  | "odd:bg-green-400"
  | "even:bg-green-400"
  | "checked:bg-green-400"
  | "odd:bg-green-400"
  | "even:bg-green-400"
  | "2xl:bg-green-500"
  | "1xl:bg-green-500"
  | "xl:bg-green-500"
  | "lg:bg-green-500"
  | "md:bg-green-500"
  | "sm:bg-green-500"
  | "xs:bg-green-500"
  | "group-hover:bg-green-500"
  | "focus-within:bg-green-500"
  | "hover:bg-green-500"
  | "focus:bg-green-500"
  | "checked:bg-green-500"
  | "odd:bg-green-500"
  | "even:bg-green-500"
  | "checked:bg-green-500"
  | "odd:bg-green-500"
  | "even:bg-green-500"
  | "2xl:bg-green-600"
  | "1xl:bg-green-600"
  | "xl:bg-green-600"
  | "lg:bg-green-600"
  | "md:bg-green-600"
  | "sm:bg-green-600"
  | "xs:bg-green-600"
  | "group-hover:bg-green-600"
  | "focus-within:bg-green-600"
  | "hover:bg-green-600"
  | "focus:bg-green-600"
  | "checked:bg-green-600"
  | "odd:bg-green-600"
  | "even:bg-green-600"
  | "checked:bg-green-600"
  | "odd:bg-green-600"
  | "even:bg-green-600"
  | "2xl:bg-green-700"
  | "1xl:bg-green-700"
  | "xl:bg-green-700"
  | "lg:bg-green-700"
  | "md:bg-green-700"
  | "sm:bg-green-700"
  | "xs:bg-green-700"
  | "group-hover:bg-green-700"
  | "focus-within:bg-green-700"
  | "hover:bg-green-700"
  | "focus:bg-green-700"
  | "checked:bg-green-700"
  | "odd:bg-green-700"
  | "even:bg-green-700"
  | "checked:bg-green-700"
  | "odd:bg-green-700"
  | "even:bg-green-700"
  | "2xl:bg-green-800"
  | "1xl:bg-green-800"
  | "xl:bg-green-800"
  | "lg:bg-green-800"
  | "md:bg-green-800"
  | "sm:bg-green-800"
  | "xs:bg-green-800"
  | "group-hover:bg-green-800"
  | "focus-within:bg-green-800"
  | "hover:bg-green-800"
  | "focus:bg-green-800"
  | "checked:bg-green-800"
  | "odd:bg-green-800"
  | "even:bg-green-800"
  | "checked:bg-green-800"
  | "odd:bg-green-800"
  | "even:bg-green-800"
  | "2xl:bg-green-900"
  | "1xl:bg-green-900"
  | "xl:bg-green-900"
  | "lg:bg-green-900"
  | "md:bg-green-900"
  | "sm:bg-green-900"
  | "xs:bg-green-900"
  | "group-hover:bg-green-900"
  | "focus-within:bg-green-900"
  | "hover:bg-green-900"
  | "focus:bg-green-900"
  | "checked:bg-green-900"
  | "odd:bg-green-900"
  | "even:bg-green-900"
  | "checked:bg-green-900"
  | "odd:bg-green-900"
  | "even:bg-green-900"
  | "2xl:bg-blue-50"
  | "1xl:bg-blue-50"
  | "xl:bg-blue-50"
  | "lg:bg-blue-50"
  | "md:bg-blue-50"
  | "sm:bg-blue-50"
  | "xs:bg-blue-50"
  | "group-hover:bg-blue-50"
  | "focus-within:bg-blue-50"
  | "hover:bg-blue-50"
  | "focus:bg-blue-50"
  | "checked:bg-blue-50"
  | "odd:bg-blue-50"
  | "even:bg-blue-50"
  | "checked:bg-blue-50"
  | "odd:bg-blue-50"
  | "even:bg-blue-50"
  | "2xl:bg-blue-100"
  | "1xl:bg-blue-100"
  | "xl:bg-blue-100"
  | "lg:bg-blue-100"
  | "md:bg-blue-100"
  | "sm:bg-blue-100"
  | "xs:bg-blue-100"
  | "group-hover:bg-blue-100"
  | "focus-within:bg-blue-100"
  | "hover:bg-blue-100"
  | "focus:bg-blue-100"
  | "checked:bg-blue-100"
  | "odd:bg-blue-100"
  | "even:bg-blue-100"
  | "checked:bg-blue-100"
  | "odd:bg-blue-100"
  | "even:bg-blue-100"
  | "2xl:bg-blue-200"
  | "1xl:bg-blue-200"
  | "xl:bg-blue-200"
  | "lg:bg-blue-200"
  | "md:bg-blue-200"
  | "sm:bg-blue-200"
  | "xs:bg-blue-200"
  | "group-hover:bg-blue-200"
  | "focus-within:bg-blue-200"
  | "hover:bg-blue-200"
  | "focus:bg-blue-200"
  | "checked:bg-blue-200"
  | "odd:bg-blue-200"
  | "even:bg-blue-200"
  | "checked:bg-blue-200"
  | "odd:bg-blue-200"
  | "even:bg-blue-200"
  | "2xl:bg-blue-300"
  | "1xl:bg-blue-300"
  | "xl:bg-blue-300"
  | "lg:bg-blue-300"
  | "md:bg-blue-300"
  | "sm:bg-blue-300"
  | "xs:bg-blue-300"
  | "group-hover:bg-blue-300"
  | "focus-within:bg-blue-300"
  | "hover:bg-blue-300"
  | "focus:bg-blue-300"
  | "checked:bg-blue-300"
  | "odd:bg-blue-300"
  | "even:bg-blue-300"
  | "checked:bg-blue-300"
  | "odd:bg-blue-300"
  | "even:bg-blue-300"
  | "2xl:bg-blue-400"
  | "1xl:bg-blue-400"
  | "xl:bg-blue-400"
  | "lg:bg-blue-400"
  | "md:bg-blue-400"
  | "sm:bg-blue-400"
  | "xs:bg-blue-400"
  | "group-hover:bg-blue-400"
  | "focus-within:bg-blue-400"
  | "hover:bg-blue-400"
  | "focus:bg-blue-400"
  | "checked:bg-blue-400"
  | "odd:bg-blue-400"
  | "even:bg-blue-400"
  | "checked:bg-blue-400"
  | "odd:bg-blue-400"
  | "even:bg-blue-400"
  | "2xl:bg-blue-500"
  | "1xl:bg-blue-500"
  | "xl:bg-blue-500"
  | "lg:bg-blue-500"
  | "md:bg-blue-500"
  | "sm:bg-blue-500"
  | "xs:bg-blue-500"
  | "group-hover:bg-blue-500"
  | "focus-within:bg-blue-500"
  | "hover:bg-blue-500"
  | "focus:bg-blue-500"
  | "checked:bg-blue-500"
  | "odd:bg-blue-500"
  | "even:bg-blue-500"
  | "checked:bg-blue-500"
  | "odd:bg-blue-500"
  | "even:bg-blue-500"
  | "2xl:bg-blue-600"
  | "1xl:bg-blue-600"
  | "xl:bg-blue-600"
  | "lg:bg-blue-600"
  | "md:bg-blue-600"
  | "sm:bg-blue-600"
  | "xs:bg-blue-600"
  | "group-hover:bg-blue-600"
  | "focus-within:bg-blue-600"
  | "hover:bg-blue-600"
  | "focus:bg-blue-600"
  | "checked:bg-blue-600"
  | "odd:bg-blue-600"
  | "even:bg-blue-600"
  | "checked:bg-blue-600"
  | "odd:bg-blue-600"
  | "even:bg-blue-600"
  | "2xl:bg-blue-700"
  | "1xl:bg-blue-700"
  | "xl:bg-blue-700"
  | "lg:bg-blue-700"
  | "md:bg-blue-700"
  | "sm:bg-blue-700"
  | "xs:bg-blue-700"
  | "group-hover:bg-blue-700"
  | "focus-within:bg-blue-700"
  | "hover:bg-blue-700"
  | "focus:bg-blue-700"
  | "checked:bg-blue-700"
  | "odd:bg-blue-700"
  | "even:bg-blue-700"
  | "checked:bg-blue-700"
  | "odd:bg-blue-700"
  | "even:bg-blue-700"
  | "2xl:bg-blue-800"
  | "1xl:bg-blue-800"
  | "xl:bg-blue-800"
  | "lg:bg-blue-800"
  | "md:bg-blue-800"
  | "sm:bg-blue-800"
  | "xs:bg-blue-800"
  | "group-hover:bg-blue-800"
  | "focus-within:bg-blue-800"
  | "hover:bg-blue-800"
  | "focus:bg-blue-800"
  | "checked:bg-blue-800"
  | "odd:bg-blue-800"
  | "even:bg-blue-800"
  | "checked:bg-blue-800"
  | "odd:bg-blue-800"
  | "even:bg-blue-800"
  | "2xl:bg-blue-900"
  | "1xl:bg-blue-900"
  | "xl:bg-blue-900"
  | "lg:bg-blue-900"
  | "md:bg-blue-900"
  | "sm:bg-blue-900"
  | "xs:bg-blue-900"
  | "group-hover:bg-blue-900"
  | "focus-within:bg-blue-900"
  | "hover:bg-blue-900"
  | "focus:bg-blue-900"
  | "checked:bg-blue-900"
  | "odd:bg-blue-900"
  | "even:bg-blue-900"
  | "checked:bg-blue-900"
  | "odd:bg-blue-900"
  | "even:bg-blue-900"
  | "2xl:bg-indigo-50"
  | "1xl:bg-indigo-50"
  | "xl:bg-indigo-50"
  | "lg:bg-indigo-50"
  | "md:bg-indigo-50"
  | "sm:bg-indigo-50"
  | "xs:bg-indigo-50"
  | "group-hover:bg-indigo-50"
  | "focus-within:bg-indigo-50"
  | "hover:bg-indigo-50"
  | "focus:bg-indigo-50"
  | "checked:bg-indigo-50"
  | "odd:bg-indigo-50"
  | "even:bg-indigo-50"
  | "checked:bg-indigo-50"
  | "odd:bg-indigo-50"
  | "even:bg-indigo-50"
  | "2xl:bg-indigo-100"
  | "1xl:bg-indigo-100"
  | "xl:bg-indigo-100"
  | "lg:bg-indigo-100"
  | "md:bg-indigo-100"
  | "sm:bg-indigo-100"
  | "xs:bg-indigo-100"
  | "group-hover:bg-indigo-100"
  | "focus-within:bg-indigo-100"
  | "hover:bg-indigo-100"
  | "focus:bg-indigo-100"
  | "checked:bg-indigo-100"
  | "odd:bg-indigo-100"
  | "even:bg-indigo-100"
  | "checked:bg-indigo-100"
  | "odd:bg-indigo-100"
  | "even:bg-indigo-100"
  | "2xl:bg-indigo-200"
  | "1xl:bg-indigo-200"
  | "xl:bg-indigo-200"
  | "lg:bg-indigo-200"
  | "md:bg-indigo-200"
  | "sm:bg-indigo-200"
  | "xs:bg-indigo-200"
  | "group-hover:bg-indigo-200"
  | "focus-within:bg-indigo-200"
  | "hover:bg-indigo-200"
  | "focus:bg-indigo-200"
  | "checked:bg-indigo-200"
  | "odd:bg-indigo-200"
  | "even:bg-indigo-200"
  | "checked:bg-indigo-200"
  | "odd:bg-indigo-200"
  | "even:bg-indigo-200"
  | "2xl:bg-indigo-300"
  | "1xl:bg-indigo-300"
  | "xl:bg-indigo-300"
  | "lg:bg-indigo-300"
  | "md:bg-indigo-300"
  | "sm:bg-indigo-300"
  | "xs:bg-indigo-300"
  | "group-hover:bg-indigo-300"
  | "focus-within:bg-indigo-300"
  | "hover:bg-indigo-300"
  | "focus:bg-indigo-300"
  | "checked:bg-indigo-300"
  | "odd:bg-indigo-300"
  | "even:bg-indigo-300"
  | "checked:bg-indigo-300"
  | "odd:bg-indigo-300"
  | "even:bg-indigo-300"
  | "2xl:bg-indigo-400"
  | "1xl:bg-indigo-400"
  | "xl:bg-indigo-400"
  | "lg:bg-indigo-400"
  | "md:bg-indigo-400"
  | "sm:bg-indigo-400"
  | "xs:bg-indigo-400"
  | "group-hover:bg-indigo-400"
  | "focus-within:bg-indigo-400"
  | "hover:bg-indigo-400"
  | "focus:bg-indigo-400"
  | "checked:bg-indigo-400"
  | "odd:bg-indigo-400"
  | "even:bg-indigo-400"
  | "checked:bg-indigo-400"
  | "odd:bg-indigo-400"
  | "even:bg-indigo-400"
  | "2xl:bg-indigo-500"
  | "1xl:bg-indigo-500"
  | "xl:bg-indigo-500"
  | "lg:bg-indigo-500"
  | "md:bg-indigo-500"
  | "sm:bg-indigo-500"
  | "xs:bg-indigo-500"
  | "group-hover:bg-indigo-500"
  | "focus-within:bg-indigo-500"
  | "hover:bg-indigo-500"
  | "focus:bg-indigo-500"
  | "checked:bg-indigo-500"
  | "odd:bg-indigo-500"
  | "even:bg-indigo-500"
  | "checked:bg-indigo-500"
  | "odd:bg-indigo-500"
  | "even:bg-indigo-500"
  | "2xl:bg-indigo-600"
  | "1xl:bg-indigo-600"
  | "xl:bg-indigo-600"
  | "lg:bg-indigo-600"
  | "md:bg-indigo-600"
  | "sm:bg-indigo-600"
  | "xs:bg-indigo-600"
  | "group-hover:bg-indigo-600"
  | "focus-within:bg-indigo-600"
  | "hover:bg-indigo-600"
  | "focus:bg-indigo-600"
  | "checked:bg-indigo-600"
  | "odd:bg-indigo-600"
  | "even:bg-indigo-600"
  | "checked:bg-indigo-600"
  | "odd:bg-indigo-600"
  | "even:bg-indigo-600"
  | "2xl:bg-indigo-700"
  | "1xl:bg-indigo-700"
  | "xl:bg-indigo-700"
  | "lg:bg-indigo-700"
  | "md:bg-indigo-700"
  | "sm:bg-indigo-700"
  | "xs:bg-indigo-700"
  | "group-hover:bg-indigo-700"
  | "focus-within:bg-indigo-700"
  | "hover:bg-indigo-700"
  | "focus:bg-indigo-700"
  | "checked:bg-indigo-700"
  | "odd:bg-indigo-700"
  | "even:bg-indigo-700"
  | "checked:bg-indigo-700"
  | "odd:bg-indigo-700"
  | "even:bg-indigo-700"
  | "2xl:bg-indigo-800"
  | "1xl:bg-indigo-800"
  | "xl:bg-indigo-800"
  | "lg:bg-indigo-800"
  | "md:bg-indigo-800"
  | "sm:bg-indigo-800"
  | "xs:bg-indigo-800"
  | "group-hover:bg-indigo-800"
  | "focus-within:bg-indigo-800"
  | "hover:bg-indigo-800"
  | "focus:bg-indigo-800"
  | "checked:bg-indigo-800"
  | "odd:bg-indigo-800"
  | "even:bg-indigo-800"
  | "checked:bg-indigo-800"
  | "odd:bg-indigo-800"
  | "even:bg-indigo-800"
  | "2xl:bg-indigo-900"
  | "1xl:bg-indigo-900"
  | "xl:bg-indigo-900"
  | "lg:bg-indigo-900"
  | "md:bg-indigo-900"
  | "sm:bg-indigo-900"
  | "xs:bg-indigo-900"
  | "group-hover:bg-indigo-900"
  | "focus-within:bg-indigo-900"
  | "hover:bg-indigo-900"
  | "focus:bg-indigo-900"
  | "checked:bg-indigo-900"
  | "odd:bg-indigo-900"
  | "even:bg-indigo-900"
  | "checked:bg-indigo-900"
  | "odd:bg-indigo-900"
  | "even:bg-indigo-900"
  | "2xl:bg-purple-50"
  | "1xl:bg-purple-50"
  | "xl:bg-purple-50"
  | "lg:bg-purple-50"
  | "md:bg-purple-50"
  | "sm:bg-purple-50"
  | "xs:bg-purple-50"
  | "group-hover:bg-purple-50"
  | "focus-within:bg-purple-50"
  | "hover:bg-purple-50"
  | "focus:bg-purple-50"
  | "checked:bg-purple-50"
  | "odd:bg-purple-50"
  | "even:bg-purple-50"
  | "checked:bg-purple-50"
  | "odd:bg-purple-50"
  | "even:bg-purple-50"
  | "2xl:bg-purple-100"
  | "1xl:bg-purple-100"
  | "xl:bg-purple-100"
  | "lg:bg-purple-100"
  | "md:bg-purple-100"
  | "sm:bg-purple-100"
  | "xs:bg-purple-100"
  | "group-hover:bg-purple-100"
  | "focus-within:bg-purple-100"
  | "hover:bg-purple-100"
  | "focus:bg-purple-100"
  | "checked:bg-purple-100"
  | "odd:bg-purple-100"
  | "even:bg-purple-100"
  | "checked:bg-purple-100"
  | "odd:bg-purple-100"
  | "even:bg-purple-100"
  | "2xl:bg-purple-200"
  | "1xl:bg-purple-200"
  | "xl:bg-purple-200"
  | "lg:bg-purple-200"
  | "md:bg-purple-200"
  | "sm:bg-purple-200"
  | "xs:bg-purple-200"
  | "group-hover:bg-purple-200"
  | "focus-within:bg-purple-200"
  | "hover:bg-purple-200"
  | "focus:bg-purple-200"
  | "checked:bg-purple-200"
  | "odd:bg-purple-200"
  | "even:bg-purple-200"
  | "checked:bg-purple-200"
  | "odd:bg-purple-200"
  | "even:bg-purple-200"
  | "2xl:bg-purple-300"
  | "1xl:bg-purple-300"
  | "xl:bg-purple-300"
  | "lg:bg-purple-300"
  | "md:bg-purple-300"
  | "sm:bg-purple-300"
  | "xs:bg-purple-300"
  | "group-hover:bg-purple-300"
  | "focus-within:bg-purple-300"
  | "hover:bg-purple-300"
  | "focus:bg-purple-300"
  | "checked:bg-purple-300"
  | "odd:bg-purple-300"
  | "even:bg-purple-300"
  | "checked:bg-purple-300"
  | "odd:bg-purple-300"
  | "even:bg-purple-300"
  | "2xl:bg-purple-400"
  | "1xl:bg-purple-400"
  | "xl:bg-purple-400"
  | "lg:bg-purple-400"
  | "md:bg-purple-400"
  | "sm:bg-purple-400"
  | "xs:bg-purple-400"
  | "group-hover:bg-purple-400"
  | "focus-within:bg-purple-400"
  | "hover:bg-purple-400"
  | "focus:bg-purple-400"
  | "checked:bg-purple-400"
  | "odd:bg-purple-400"
  | "even:bg-purple-400"
  | "checked:bg-purple-400"
  | "odd:bg-purple-400"
  | "even:bg-purple-400"
  | "2xl:bg-purple-500"
  | "1xl:bg-purple-500"
  | "xl:bg-purple-500"
  | "lg:bg-purple-500"
  | "md:bg-purple-500"
  | "sm:bg-purple-500"
  | "xs:bg-purple-500"
  | "group-hover:bg-purple-500"
  | "focus-within:bg-purple-500"
  | "hover:bg-purple-500"
  | "focus:bg-purple-500"
  | "checked:bg-purple-500"
  | "odd:bg-purple-500"
  | "even:bg-purple-500"
  | "checked:bg-purple-500"
  | "odd:bg-purple-500"
  | "even:bg-purple-500"
  | "2xl:bg-purple-600"
  | "1xl:bg-purple-600"
  | "xl:bg-purple-600"
  | "lg:bg-purple-600"
  | "md:bg-purple-600"
  | "sm:bg-purple-600"
  | "xs:bg-purple-600"
  | "group-hover:bg-purple-600"
  | "focus-within:bg-purple-600"
  | "hover:bg-purple-600"
  | "focus:bg-purple-600"
  | "checked:bg-purple-600"
  | "odd:bg-purple-600"
  | "even:bg-purple-600"
  | "checked:bg-purple-600"
  | "odd:bg-purple-600"
  | "even:bg-purple-600"
  | "2xl:bg-purple-700"
  | "1xl:bg-purple-700"
  | "xl:bg-purple-700"
  | "lg:bg-purple-700"
  | "md:bg-purple-700"
  | "sm:bg-purple-700"
  | "xs:bg-purple-700"
  | "group-hover:bg-purple-700"
  | "focus-within:bg-purple-700"
  | "hover:bg-purple-700"
  | "focus:bg-purple-700"
  | "checked:bg-purple-700"
  | "odd:bg-purple-700"
  | "even:bg-purple-700"
  | "checked:bg-purple-700"
  | "odd:bg-purple-700"
  | "even:bg-purple-700"
  | "2xl:bg-purple-800"
  | "1xl:bg-purple-800"
  | "xl:bg-purple-800"
  | "lg:bg-purple-800"
  | "md:bg-purple-800"
  | "sm:bg-purple-800"
  | "xs:bg-purple-800"
  | "group-hover:bg-purple-800"
  | "focus-within:bg-purple-800"
  | "hover:bg-purple-800"
  | "focus:bg-purple-800"
  | "checked:bg-purple-800"
  | "odd:bg-purple-800"
  | "even:bg-purple-800"
  | "checked:bg-purple-800"
  | "odd:bg-purple-800"
  | "even:bg-purple-800"
  | "2xl:bg-purple-900"
  | "1xl:bg-purple-900"
  | "xl:bg-purple-900"
  | "lg:bg-purple-900"
  | "md:bg-purple-900"
  | "sm:bg-purple-900"
  | "xs:bg-purple-900"
  | "group-hover:bg-purple-900"
  | "focus-within:bg-purple-900"
  | "hover:bg-purple-900"
  | "focus:bg-purple-900"
  | "checked:bg-purple-900"
  | "odd:bg-purple-900"
  | "even:bg-purple-900"
  | "checked:bg-purple-900"
  | "odd:bg-purple-900"
  | "even:bg-purple-900"
  | "2xl:bg-pink-50"
  | "1xl:bg-pink-50"
  | "xl:bg-pink-50"
  | "lg:bg-pink-50"
  | "md:bg-pink-50"
  | "sm:bg-pink-50"
  | "xs:bg-pink-50"
  | "group-hover:bg-pink-50"
  | "focus-within:bg-pink-50"
  | "hover:bg-pink-50"
  | "focus:bg-pink-50"
  | "checked:bg-pink-50"
  | "odd:bg-pink-50"
  | "even:bg-pink-50"
  | "checked:bg-pink-50"
  | "odd:bg-pink-50"
  | "even:bg-pink-50"
  | "2xl:bg-pink-100"
  | "1xl:bg-pink-100"
  | "xl:bg-pink-100"
  | "lg:bg-pink-100"
  | "md:bg-pink-100"
  | "sm:bg-pink-100"
  | "xs:bg-pink-100"
  | "group-hover:bg-pink-100"
  | "focus-within:bg-pink-100"
  | "hover:bg-pink-100"
  | "focus:bg-pink-100"
  | "checked:bg-pink-100"
  | "odd:bg-pink-100"
  | "even:bg-pink-100"
  | "checked:bg-pink-100"
  | "odd:bg-pink-100"
  | "even:bg-pink-100"
  | "2xl:bg-pink-200"
  | "1xl:bg-pink-200"
  | "xl:bg-pink-200"
  | "lg:bg-pink-200"
  | "md:bg-pink-200"
  | "sm:bg-pink-200"
  | "xs:bg-pink-200"
  | "group-hover:bg-pink-200"
  | "focus-within:bg-pink-200"
  | "hover:bg-pink-200"
  | "focus:bg-pink-200"
  | "checked:bg-pink-200"
  | "odd:bg-pink-200"
  | "even:bg-pink-200"
  | "checked:bg-pink-200"
  | "odd:bg-pink-200"
  | "even:bg-pink-200"
  | "2xl:bg-pink-300"
  | "1xl:bg-pink-300"
  | "xl:bg-pink-300"
  | "lg:bg-pink-300"
  | "md:bg-pink-300"
  | "sm:bg-pink-300"
  | "xs:bg-pink-300"
  | "group-hover:bg-pink-300"
  | "focus-within:bg-pink-300"
  | "hover:bg-pink-300"
  | "focus:bg-pink-300"
  | "checked:bg-pink-300"
  | "odd:bg-pink-300"
  | "even:bg-pink-300"
  | "checked:bg-pink-300"
  | "odd:bg-pink-300"
  | "even:bg-pink-300"
  | "2xl:bg-pink-400"
  | "1xl:bg-pink-400"
  | "xl:bg-pink-400"
  | "lg:bg-pink-400"
  | "md:bg-pink-400"
  | "sm:bg-pink-400"
  | "xs:bg-pink-400"
  | "group-hover:bg-pink-400"
  | "focus-within:bg-pink-400"
  | "hover:bg-pink-400"
  | "focus:bg-pink-400"
  | "checked:bg-pink-400"
  | "odd:bg-pink-400"
  | "even:bg-pink-400"
  | "checked:bg-pink-400"
  | "odd:bg-pink-400"
  | "even:bg-pink-400"
  | "2xl:bg-pink-500"
  | "1xl:bg-pink-500"
  | "xl:bg-pink-500"
  | "lg:bg-pink-500"
  | "md:bg-pink-500"
  | "sm:bg-pink-500"
  | "xs:bg-pink-500"
  | "group-hover:bg-pink-500"
  | "focus-within:bg-pink-500"
  | "hover:bg-pink-500"
  | "focus:bg-pink-500"
  | "checked:bg-pink-500"
  | "odd:bg-pink-500"
  | "even:bg-pink-500"
  | "checked:bg-pink-500"
  | "odd:bg-pink-500"
  | "even:bg-pink-500"
  | "2xl:bg-pink-600"
  | "1xl:bg-pink-600"
  | "xl:bg-pink-600"
  | "lg:bg-pink-600"
  | "md:bg-pink-600"
  | "sm:bg-pink-600"
  | "xs:bg-pink-600"
  | "group-hover:bg-pink-600"
  | "focus-within:bg-pink-600"
  | "hover:bg-pink-600"
  | "focus:bg-pink-600"
  | "checked:bg-pink-600"
  | "odd:bg-pink-600"
  | "even:bg-pink-600"
  | "checked:bg-pink-600"
  | "odd:bg-pink-600"
  | "even:bg-pink-600"
  | "2xl:bg-pink-700"
  | "1xl:bg-pink-700"
  | "xl:bg-pink-700"
  | "lg:bg-pink-700"
  | "md:bg-pink-700"
  | "sm:bg-pink-700"
  | "xs:bg-pink-700"
  | "group-hover:bg-pink-700"
  | "focus-within:bg-pink-700"
  | "hover:bg-pink-700"
  | "focus:bg-pink-700"
  | "checked:bg-pink-700"
  | "odd:bg-pink-700"
  | "even:bg-pink-700"
  | "checked:bg-pink-700"
  | "odd:bg-pink-700"
  | "even:bg-pink-700"
  | "2xl:bg-pink-800"
  | "1xl:bg-pink-800"
  | "xl:bg-pink-800"
  | "lg:bg-pink-800"
  | "md:bg-pink-800"
  | "sm:bg-pink-800"
  | "xs:bg-pink-800"
  | "group-hover:bg-pink-800"
  | "focus-within:bg-pink-800"
  | "hover:bg-pink-800"
  | "focus:bg-pink-800"
  | "checked:bg-pink-800"
  | "odd:bg-pink-800"
  | "even:bg-pink-800"
  | "checked:bg-pink-800"
  | "odd:bg-pink-800"
  | "even:bg-pink-800"
  | "2xl:bg-pink-900"
  | "1xl:bg-pink-900"
  | "xl:bg-pink-900"
  | "lg:bg-pink-900"
  | "md:bg-pink-900"
  | "sm:bg-pink-900"
  | "xs:bg-pink-900"
  | "group-hover:bg-pink-900"
  | "focus-within:bg-pink-900"
  | "hover:bg-pink-900"
  | "focus:bg-pink-900"
  | "checked:bg-pink-900"
  | "odd:bg-pink-900"
  | "even:bg-pink-900"
  | "checked:bg-pink-900"
  | "odd:bg-pink-900"
  | "even:bg-pink-900"
  | "2xl:bg-title-color-1"
  | "1xl:bg-title-color-1"
  | "xl:bg-title-color-1"
  | "lg:bg-title-color-1"
  | "md:bg-title-color-1"
  | "sm:bg-title-color-1"
  | "xs:bg-title-color-1"
  | "group-hover:bg-title-color-1"
  | "focus-within:bg-title-color-1"
  | "hover:bg-title-color-1"
  | "focus:bg-title-color-1"
  | "checked:bg-title-color-1"
  | "odd:bg-title-color-1"
  | "even:bg-title-color-1"
  | "checked:bg-title-color-1"
  | "odd:bg-title-color-1"
  | "even:bg-title-color-1"
  | "2xl:bg-primary-bg-color"
  | "1xl:bg-primary-bg-color"
  | "xl:bg-primary-bg-color"
  | "lg:bg-primary-bg-color"
  | "md:bg-primary-bg-color"
  | "sm:bg-primary-bg-color"
  | "xs:bg-primary-bg-color"
  | "group-hover:bg-primary-bg-color"
  | "focus-within:bg-primary-bg-color"
  | "hover:bg-primary-bg-color"
  | "focus:bg-primary-bg-color"
  | "checked:bg-primary-bg-color"
  | "odd:bg-primary-bg-color"
  | "even:bg-primary-bg-color"
  | "checked:bg-primary-bg-color"
  | "odd:bg-primary-bg-color"
  | "even:bg-primary-bg-color"
  | "2xl:bg-secondary-bg-color"
  | "1xl:bg-secondary-bg-color"
  | "xl:bg-secondary-bg-color"
  | "lg:bg-secondary-bg-color"
  | "md:bg-secondary-bg-color"
  | "sm:bg-secondary-bg-color"
  | "xs:bg-secondary-bg-color"
  | "group-hover:bg-secondary-bg-color"
  | "focus-within:bg-secondary-bg-color"
  | "hover:bg-secondary-bg-color"
  | "focus:bg-secondary-bg-color"
  | "checked:bg-secondary-bg-color"
  | "odd:bg-secondary-bg-color"
  | "even:bg-secondary-bg-color"
  | "checked:bg-secondary-bg-color"
  | "odd:bg-secondary-bg-color"
  | "even:bg-secondary-bg-color"
  | "2xl:bg-sidebar-bg-color"
  | "1xl:bg-sidebar-bg-color"
  | "xl:bg-sidebar-bg-color"
  | "lg:bg-sidebar-bg-color"
  | "md:bg-sidebar-bg-color"
  | "sm:bg-sidebar-bg-color"
  | "xs:bg-sidebar-bg-color"
  | "group-hover:bg-sidebar-bg-color"
  | "focus-within:bg-sidebar-bg-color"
  | "hover:bg-sidebar-bg-color"
  | "focus:bg-sidebar-bg-color"
  | "checked:bg-sidebar-bg-color"
  | "odd:bg-sidebar-bg-color"
  | "even:bg-sidebar-bg-color"
  | "checked:bg-sidebar-bg-color"
  | "odd:bg-sidebar-bg-color"
  | "even:bg-sidebar-bg-color"
  | "2xl:bg-header-bg-color"
  | "1xl:bg-header-bg-color"
  | "xl:bg-header-bg-color"
  | "lg:bg-header-bg-color"
  | "md:bg-header-bg-color"
  | "sm:bg-header-bg-color"
  | "xs:bg-header-bg-color"
  | "group-hover:bg-header-bg-color"
  | "focus-within:bg-header-bg-color"
  | "hover:bg-header-bg-color"
  | "focus:bg-header-bg-color"
  | "checked:bg-header-bg-color"
  | "odd:bg-header-bg-color"
  | "even:bg-header-bg-color"
  | "checked:bg-header-bg-color"
  | "odd:bg-header-bg-color"
  | "even:bg-header-bg-color"
  | "2xl:bg-body-bg-color"
  | "1xl:bg-body-bg-color"
  | "xl:bg-body-bg-color"
  | "lg:bg-body-bg-color"
  | "md:bg-body-bg-color"
  | "sm:bg-body-bg-color"
  | "xs:bg-body-bg-color"
  | "group-hover:bg-body-bg-color"
  | "focus-within:bg-body-bg-color"
  | "hover:bg-body-bg-color"
  | "focus:bg-body-bg-color"
  | "checked:bg-body-bg-color"
  | "odd:bg-body-bg-color"
  | "even:bg-body-bg-color"
  | "checked:bg-body-bg-color"
  | "odd:bg-body-bg-color"
  | "even:bg-body-bg-color"
  | "2xl:bg-main-color"
  | "1xl:bg-main-color"
  | "xl:bg-main-color"
  | "lg:bg-main-color"
  | "md:bg-main-color"
  | "sm:bg-main-color"
  | "xs:bg-main-color"
  | "group-hover:bg-main-color"
  | "focus-within:bg-main-color"
  | "hover:bg-main-color"
  | "focus:bg-main-color"
  | "checked:bg-main-color"
  | "odd:bg-main-color"
  | "even:bg-main-color"
  | "checked:bg-main-color"
  | "odd:bg-main-color"
  | "even:bg-main-color"
  | "2xl:bg-secondary-color"
  | "1xl:bg-secondary-color"
  | "xl:bg-secondary-color"
  | "lg:bg-secondary-color"
  | "md:bg-secondary-color"
  | "sm:bg-secondary-color"
  | "xs:bg-secondary-color"
  | "group-hover:bg-secondary-color"
  | "focus-within:bg-secondary-color"
  | "hover:bg-secondary-color"
  | "focus:bg-secondary-color"
  | "checked:bg-secondary-color"
  | "odd:bg-secondary-color"
  | "even:bg-secondary-color"
  | "checked:bg-secondary-color"
  | "odd:bg-secondary-color"
  | "even:bg-secondary-color"
  | "2xl:bg-highlight-color"
  | "1xl:bg-highlight-color"
  | "xl:bg-highlight-color"
  | "lg:bg-highlight-color"
  | "md:bg-highlight-color"
  | "sm:bg-highlight-color"
  | "xs:bg-highlight-color"
  | "group-hover:bg-highlight-color"
  | "focus-within:bg-highlight-color"
  | "hover:bg-highlight-color"
  | "focus:bg-highlight-color"
  | "checked:bg-highlight-color"
  | "odd:bg-highlight-color"
  | "even:bg-highlight-color"
  | "checked:bg-highlight-color"
  | "odd:bg-highlight-color"
  | "even:bg-highlight-color"
  | "2xl:bg-tertiary-bg-color"
  | "1xl:bg-tertiary-bg-color"
  | "xl:bg-tertiary-bg-color"
  | "lg:bg-tertiary-bg-color"
  | "md:bg-tertiary-bg-color"
  | "sm:bg-tertiary-bg-color"
  | "xs:bg-tertiary-bg-color"
  | "group-hover:bg-tertiary-bg-color"
  | "focus-within:bg-tertiary-bg-color"
  | "hover:bg-tertiary-bg-color"
  | "focus:bg-tertiary-bg-color"
  | "checked:bg-tertiary-bg-color"
  | "odd:bg-tertiary-bg-color"
  | "even:bg-tertiary-bg-color"
  | "checked:bg-tertiary-bg-color"
  | "odd:bg-tertiary-bg-color"
  | "even:bg-tertiary-bg-color"
  | "2xl:quaternary-bg-color"
  | "1xl:bg-quaternary-bg-color"
  | "xl:bg-quaternary-bg-color"
  | "lg:bg-quaternary-bg-color"
  | "md:bg-quaternary-bg-color"
  | "sm:bg-quaternary-bg-color"
  | "xs:bg-quaternary-bg-color"
  | "group-hover:bg-quaternary-bg-color"
  | "focus-within:bg-quaternary-bg-color"
  | "hover:bg-quaternary-bg-color"
  | "focus:bg-quaternary-bg-color"
  | "checked:bg-quaternary-bg-color"
  | "odd:bg-quaternary-bg-color"
  | "even:bg-quaternary-bg-color"
  | "checked:bg-quaternary-bg-color"
  | "odd:bg-quaternary-bg-color"
  | "even:bg-quaternary-bg-color"
  | "2xl:bg-earnings-bg-color"
  | "1xl:bg-earnings-bg-color"
  | "xl:bg-earnings-bg-color"
  | "lg:bg-earnings-bg-color"
  | "md:bg-earnings-bg-color"
  | "sm:bg-earnings-bg-color"
  | "xs:bg-earnings-bg-color"
  | "group-hover:bg-earnings-bg-color"
  | "focus-within:bg-earnings-bg-color"
  | "hover:bg-earnings-bg-color"
  | "focus:bg-earnings-bg-color"
  | "checked:bg-earnings-bg-color"
  | "odd:bg-earnings-bg-color"
  | "even:bg-earnings-bg-color"
  | "checked:bg-earnings-bg-color"
  | "odd:bg-earnings-bg-color"
  | "even:bg-earnings-bg-color"
  | "2xl:bg-card-secondary-bg-color"
  | "1xl:bg-card-secondary-bg-color"
  | "xl:bg-card-secondary-bg-color"
  | "lg:bg-card-secondary-bg-color"
  | "md:bg-card-secondary-bg-color"
  | "sm:bg-card-secondary-bg-color"
  | "xs:bg-card-secondary-bg-color"
  | "group-hover:bg-card-secondary-bg-color"
  | "focus-within:bg-card-secondary-bg-color"
  | "hover:bg-card-secondary-bg-color"
  | "focus:bg-card-secondary-bg-color"
  | "checked:bg-card-secondary-bg-color"
  | "odd:bg-card-secondary-bg-color"
  | "even:bg-card-secondary-bg-color"
  | "checked:bg-card-secondary-bg-color"
  | "odd:bg-card-secondary-bg-color"
  | "even:bg-card-secondary-bg-color"
  | "2xl:bg-pink-color"
  | "1xl:bg-pink-color"
  | "xl:bg-pink-color"
  | "lg:bg-pink-color"
  | "md:bg-pink-color"
  | "sm:bg-pink-color"
  | "xs:bg-pink-color"
  | "group-hover:bg-pink-color"
  | "focus-within:bg-pink-color"
  | "hover:bg-pink-color"
  | "focus:bg-pink-color"
  | "checked:bg-pink-color"
  | "odd:bg-pink-color"
  | "even:bg-pink-color"
  | "checked:bg-pink-color"
  | "odd:bg-pink-color"
  | "even:bg-pink-color"
  | "2xl:bg-go-live-text"
  | "1xl:bg-go-live-text"
  | "xl:bg-go-live-text"
  | "lg:bg-go-live-text"
  | "md:bg-go-live-text"
  | "sm:bg-go-live-text"
  | "xs:bg-go-live-text"
  | "group-hover:bg-go-live-text"
  | "focus-within:bg-go-live-text"
  | "hover:bg-go-live-text"
  | "focus:bg-go-live-text"
  | "checked:bg-go-live-text"
  | "odd:bg-go-live-text"
  | "even:bg-go-live-text"
  | "checked:bg-go-live-text"
  | "odd:bg-go-live-text"
  | "even:bg-go-live-text"
  | "2xl:bg-go-live-bg-color"
  | "1xl:bg-go-live-bg-color"
  | "xl:bg-go-live-bg-color"
  | "lg:bg-go-live-bg-color"
  | "md:bg-go-live-bg-color"
  | "sm:bg-go-live-bg-color"
  | "xs:bg-go-live-bg-color"
  | "group-hover:bg-go-live-bg-color"
  | "focus-within:bg-go-live-bg-color"
  | "hover:bg-go-live-bg-color"
  | "focus:bg-go-live-bg-color"
  | "checked:bg-go-live-bg-color"
  | "odd:bg-go-live-bg-color"
  | "even:bg-go-live-bg-color"
  | "checked:bg-go-live-bg-color"
  | "odd:bg-go-live-bg-color"
  | "even:bg-go-live-bg-color"
  | "2xl:bg-gray-button"
  | "1xl:bg-gray-button"
  | "xl:bg-gray-button"
  | "lg:bg-gray-button"
  | "md:bg-gray-button"
  | "sm:bg-gray-button"
  | "xs:bg-gray-button"
  | "group-hover:bg-gray-button"
  | "focus-within:bg-gray-button"
  | "hover:bg-gray-button"
  | "focus:bg-gray-button"
  | "checked:bg-gray-button"
  | "odd:bg-gray-button"
  | "even:bg-gray-button"
  | "checked:bg-gray-button"
  | "odd:bg-gray-button"
  | "even:bg-gray-button"
  | "2xl:bg-toggle-disabled"
  | "1xl:bg-toggle-disabled"
  | "xl:bg-toggle-disabled"
  | "lg:bg-toggle-disabled"
  | "md:bg-toggle-disabled"
  | "sm:bg-toggle-disabled"
  | "xs:bg-toggle-disabled"
  | "group-hover:bg-toggle-disabled"
  | "focus-within:bg-toggle-disabled"
  | "hover:bg-toggle-disabled"
  | "focus:bg-toggle-disabled"
  | "checked:bg-toggle-disabled"
  | "odd:bg-toggle-disabled"
  | "even:bg-toggle-disabled"
  | "checked:bg-toggle-disabled"
  | "odd:bg-toggle-disabled"
  | "even:bg-toggle-disabled"
  | "2xl:bg-tab-not-selected-color"
  | "1xl:bg-tab-not-selected-color"
  | "xl:bg-tab-not-selected-color"
  | "lg:bg-tab-not-selected-color"
  | "md:bg-tab-not-selected-color"
  | "sm:bg-tab-not-selected-color"
  | "xs:bg-tab-not-selected-color"
  | "group-hover:bg-tab-not-selected-color"
  | "focus-within:bg-tab-not-selected-color"
  | "hover:bg-tab-not-selected-color"
  | "focus:bg-tab-not-selected-color"
  | "checked:bg-tab-not-selected-color"
  | "odd:bg-tab-not-selected-color"
  | "even:bg-tab-not-selected-color"
  | "checked:bg-tab-not-selected-color"
  | "odd:bg-tab-not-selected-color"
  | "even:bg-tab-not-selected-color"
  | "2xl:bg-text-area-bg-color"
  | "1xl:bg-text-area-bg-color"
  | "xl:bg-text-area-bg-color"
  | "lg:bg-text-area-bg-color"
  | "md:bg-text-area-bg-color"
  | "sm:bg-text-area-bg-color"
  | "xs:bg-text-area-bg-color"
  | "group-hover:bg-text-area-bg-color"
  | "focus-within:bg-text-area-bg-color"
  | "hover:bg-text-area-bg-color"
  | "focus:bg-text-area-bg-color"
  | "checked:bg-text-area-bg-color"
  | "odd:bg-text-area-bg-color"
  | "even:bg-text-area-bg-color"
  | "checked:bg-text-area-bg-color"
  | "odd:bg-text-area-bg-color"
  | "even:bg-text-area-bg-color"
  | "2xl:bg-table-primary-bg-color"
  | "1xl:bg-table-primary-bg-color"
  | "xl:bg-table-primary-bg-color"
  | "lg:bg-table-primary-bg-color"
  | "md:bg-table-primary-bg-color"
  | "sm:bg-table-primary-bg-color"
  | "xs:bg-table-primary-bg-color"
  | "group-hover:bg-table-primary-bg-color"
  | "focus-within:bg-table-primary-bg-color"
  | "hover:bg-table-primary-bg-color"
  | "focus:bg-table-primary-bg-color"
  | "checked:bg-table-primary-bg-color"
  | "odd:bg-table-primary-bg-color"
  | "even:bg-table-primary-bg-color"
  | "checked:bg-table-primary-bg-color"
  | "odd:bg-table-primary-bg-color"
  | "even:bg-table-primary-bg-color"
  | "2xl:bg-table-secondary-color"
  | "1xl:bg-table-secondary-color"
  | "xl:bg-table-secondary-color"
  | "lg:bg-table-secondary-color"
  | "md:bg-table-secondary-color"
  | "sm:bg-table-secondary-color"
  | "xs:bg-table-secondary-color"
  | "group-hover:bg-table-secondary-color"
  | "focus-within:bg-table-secondary-color"
  | "hover:bg-table-secondary-color"
  | "focus:bg-table-secondary-color"
  | "checked:bg-table-secondary-color"
  | "odd:bg-table-secondary-color"
  | "even:bg-table-secondary-color"
  | "checked:bg-table-secondary-color"
  | "odd:bg-table-secondary-color"
  | "even:bg-table-secondary-color"
  | "2xl:bg-modal-button-color"
  | "1xl:bg-modal-button-color"
  | "xl:bg-modal-button-color"
  | "lg:bg-modal-button-color"
  | "md:bg-modal-button-color"
  | "sm:bg-modal-button-color"
  | "xs:bg-modal-button-color"
  | "group-hover:bg-modal-button-color"
  | "focus-within:bg-modal-button-color"
  | "hover:bg-modal-button-color"
  | "focus:bg-modal-button-color"
  | "checked:bg-modal-button-color"
  | "odd:bg-modal-button-color"
  | "even:bg-modal-button-color"
  | "checked:bg-modal-button-color"
  | "odd:bg-modal-button-color"
  | "even:bg-modal-button-color"
  | "2xl:bg-none"
  | "1xl:bg-none"
  | "xl:bg-none"
  | "lg:bg-none"
  | "md:bg-none"
  | "sm:bg-none"
  | "xs:bg-none"
  | "2xl:bg-gradient-to-t"
  | "1xl:bg-gradient-to-t"
  | "xl:bg-gradient-to-t"
  | "lg:bg-gradient-to-t"
  | "md:bg-gradient-to-t"
  | "sm:bg-gradient-to-t"
  | "xs:bg-gradient-to-t"
  | "2xl:bg-gradient-to-tr"
  | "1xl:bg-gradient-to-tr"
  | "xl:bg-gradient-to-tr"
  | "lg:bg-gradient-to-tr"
  | "md:bg-gradient-to-tr"
  | "sm:bg-gradient-to-tr"
  | "xs:bg-gradient-to-tr"
  | "2xl:bg-gradient-to-r"
  | "1xl:bg-gradient-to-r"
  | "xl:bg-gradient-to-r"
  | "lg:bg-gradient-to-r"
  | "md:bg-gradient-to-r"
  | "sm:bg-gradient-to-r"
  | "xs:bg-gradient-to-r"
  | "2xl:bg-gradient-to-br"
  | "1xl:bg-gradient-to-br"
  | "xl:bg-gradient-to-br"
  | "lg:bg-gradient-to-br"
  | "md:bg-gradient-to-br"
  | "sm:bg-gradient-to-br"
  | "xs:bg-gradient-to-br"
  | "2xl:bg-gradient-to-b"
  | "1xl:bg-gradient-to-b"
  | "xl:bg-gradient-to-b"
  | "lg:bg-gradient-to-b"
  | "md:bg-gradient-to-b"
  | "sm:bg-gradient-to-b"
  | "xs:bg-gradient-to-b"
  | "2xl:bg-gradient-to-bl"
  | "1xl:bg-gradient-to-bl"
  | "xl:bg-gradient-to-bl"
  | "lg:bg-gradient-to-bl"
  | "md:bg-gradient-to-bl"
  | "sm:bg-gradient-to-bl"
  | "xs:bg-gradient-to-bl"
  | "2xl:bg-gradient-to-l"
  | "1xl:bg-gradient-to-l"
  | "xl:bg-gradient-to-l"
  | "lg:bg-gradient-to-l"
  | "md:bg-gradient-to-l"
  | "sm:bg-gradient-to-l"
  | "xs:bg-gradient-to-l"
  | "2xl:bg-gradient-to-tl"
  | "1xl:bg-gradient-to-tl"
  | "xl:bg-gradient-to-tl"
  | "lg:bg-gradient-to-tl"
  | "md:bg-gradient-to-tl"
  | "sm:bg-gradient-to-tl"
  | "xs:bg-gradient-to-tl"
  | "2xl:bg-opacity-0"
  | "1xl:bg-opacity-0"
  | "xl:bg-opacity-0"
  | "lg:bg-opacity-0"
  | "md:bg-opacity-0"
  | "sm:bg-opacity-0"
  | "xs:bg-opacity-0"
  | "group-hover:bg-opacity-0"
  | "focus-within:bg-opacity-0"
  | "hover:bg-opacity-0"
  | "focus:bg-opacity-0"
  | "2xl:bg-opacity-5"
  | "1xl:bg-opacity-5"
  | "xl:bg-opacity-5"
  | "lg:bg-opacity-5"
  | "md:bg-opacity-5"
  | "sm:bg-opacity-5"
  | "xs:bg-opacity-5"
  | "group-hover:bg-opacity-5"
  | "focus-within:bg-opacity-5"
  | "hover:bg-opacity-5"
  | "focus:bg-opacity-5"
  | "2xl:bg-opacity-10"
  | "1xl:bg-opacity-10"
  | "xl:bg-opacity-10"
  | "lg:bg-opacity-10"
  | "md:bg-opacity-10"
  | "sm:bg-opacity-10"
  | "xs:bg-opacity-10"
  | "group-hover:bg-opacity-10"
  | "focus-within:bg-opacity-10"
  | "hover:bg-opacity-10"
  | "focus:bg-opacity-10"
  | "2xl:bg-opacity-20"
  | "1xl:bg-opacity-20"
  | "xl:bg-opacity-20"
  | "lg:bg-opacity-20"
  | "md:bg-opacity-20"
  | "sm:bg-opacity-20"
  | "xs:bg-opacity-20"
  | "group-hover:bg-opacity-20"
  | "focus-within:bg-opacity-20"
  | "hover:bg-opacity-20"
  | "focus:bg-opacity-20"
  | "2xl:bg-opacity-25"
  | "1xl:bg-opacity-25"
  | "xl:bg-opacity-25"
  | "lg:bg-opacity-25"
  | "md:bg-opacity-25"
  | "sm:bg-opacity-25"
  | "xs:bg-opacity-25"
  | "group-hover:bg-opacity-25"
  | "focus-within:bg-opacity-25"
  | "hover:bg-opacity-25"
  | "focus:bg-opacity-25"
  | "2xl:bg-opacity-30"
  | "1xl:bg-opacity-30"
  | "xl:bg-opacity-30"
  | "lg:bg-opacity-30"
  | "md:bg-opacity-30"
  | "sm:bg-opacity-30"
  | "xs:bg-opacity-30"
  | "group-hover:bg-opacity-30"
  | "focus-within:bg-opacity-30"
  | "hover:bg-opacity-30"
  | "focus:bg-opacity-30"
  | "2xl:bg-opacity-40"
  | "1xl:bg-opacity-40"
  | "xl:bg-opacity-40"
  | "lg:bg-opacity-40"
  | "md:bg-opacity-40"
  | "sm:bg-opacity-40"
  | "xs:bg-opacity-40"
  | "group-hover:bg-opacity-40"
  | "focus-within:bg-opacity-40"
  | "hover:bg-opacity-40"
  | "focus:bg-opacity-40"
  | "2xl:bg-opacity-50"
  | "1xl:bg-opacity-50"
  | "xl:bg-opacity-50"
  | "lg:bg-opacity-50"
  | "md:bg-opacity-50"
  | "sm:bg-opacity-50"
  | "xs:bg-opacity-50"
  | "group-hover:bg-opacity-50"
  | "focus-within:bg-opacity-50"
  | "hover:bg-opacity-50"
  | "focus:bg-opacity-50"
  | "2xl:bg-opacity-60"
  | "1xl:bg-opacity-60"
  | "xl:bg-opacity-60"
  | "lg:bg-opacity-60"
  | "md:bg-opacity-60"
  | "sm:bg-opacity-60"
  | "xs:bg-opacity-60"
  | "group-hover:bg-opacity-60"
  | "focus-within:bg-opacity-60"
  | "hover:bg-opacity-60"
  | "focus:bg-opacity-60"
  | "2xl:bg-opacity-70"
  | "1xl:bg-opacity-70"
  | "xl:bg-opacity-70"
  | "lg:bg-opacity-70"
  | "md:bg-opacity-70"
  | "sm:bg-opacity-70"
  | "xs:bg-opacity-70"
  | "group-hover:bg-opacity-70"
  | "focus-within:bg-opacity-70"
  | "hover:bg-opacity-70"
  | "focus:bg-opacity-70"
  | "2xl:bg-opacity-75"
  | "1xl:bg-opacity-75"
  | "xl:bg-opacity-75"
  | "lg:bg-opacity-75"
  | "md:bg-opacity-75"
  | "sm:bg-opacity-75"
  | "xs:bg-opacity-75"
  | "group-hover:bg-opacity-75"
  | "focus-within:bg-opacity-75"
  | "hover:bg-opacity-75"
  | "focus:bg-opacity-75"
  | "2xl:bg-opacity-80"
  | "1xl:bg-opacity-80"
  | "xl:bg-opacity-80"
  | "lg:bg-opacity-80"
  | "md:bg-opacity-80"
  | "sm:bg-opacity-80"
  | "xs:bg-opacity-80"
  | "group-hover:bg-opacity-80"
  | "focus-within:bg-opacity-80"
  | "hover:bg-opacity-80"
  | "focus:bg-opacity-80"
  | "2xl:bg-opacity-90"
  | "1xl:bg-opacity-90"
  | "xl:bg-opacity-90"
  | "lg:bg-opacity-90"
  | "md:bg-opacity-90"
  | "sm:bg-opacity-90"
  | "xs:bg-opacity-90"
  | "group-hover:bg-opacity-90"
  | "focus-within:bg-opacity-90"
  | "hover:bg-opacity-90"
  | "focus:bg-opacity-90"
  | "2xl:bg-opacity-95"
  | "1xl:bg-opacity-95"
  | "xl:bg-opacity-95"
  | "lg:bg-opacity-95"
  | "md:bg-opacity-95"
  | "sm:bg-opacity-95"
  | "xs:bg-opacity-95"
  | "group-hover:bg-opacity-95"
  | "focus-within:bg-opacity-95"
  | "hover:bg-opacity-95"
  | "focus:bg-opacity-95"
  | "2xl:bg-opacity-100"
  | "1xl:bg-opacity-100"
  | "xl:bg-opacity-100"
  | "lg:bg-opacity-100"
  | "md:bg-opacity-100"
  | "sm:bg-opacity-100"
  | "xs:bg-opacity-100"
  | "group-hover:bg-opacity-100"
  | "focus-within:bg-opacity-100"
  | "hover:bg-opacity-100"
  | "focus:bg-opacity-100"
  | "2xl:bg-bottom"
  | "1xl:bg-bottom"
  | "xl:bg-bottom"
  | "lg:bg-bottom"
  | "md:bg-bottom"
  | "sm:bg-bottom"
  | "xs:bg-bottom"
  | "2xl:bg-center"
  | "1xl:bg-center"
  | "xl:bg-center"
  | "lg:bg-center"
  | "md:bg-center"
  | "sm:bg-center"
  | "xs:bg-center"
  | "2xl:bg-left"
  | "1xl:bg-left"
  | "xl:bg-left"
  | "lg:bg-left"
  | "md:bg-left"
  | "sm:bg-left"
  | "xs:bg-left"
  | "2xl:bg-left-bottom"
  | "1xl:bg-left-bottom"
  | "xl:bg-left-bottom"
  | "lg:bg-left-bottom"
  | "md:bg-left-bottom"
  | "sm:bg-left-bottom"
  | "xs:bg-left-bottom"
  | "2xl:bg-left-top"
  | "1xl:bg-left-top"
  | "xl:bg-left-top"
  | "lg:bg-left-top"
  | "md:bg-left-top"
  | "sm:bg-left-top"
  | "xs:bg-left-top"
  | "2xl:bg-right"
  | "1xl:bg-right"
  | "xl:bg-right"
  | "lg:bg-right"
  | "md:bg-right"
  | "sm:bg-right"
  | "xs:bg-right"
  | "2xl:bg-right-bottom"
  | "1xl:bg-right-bottom"
  | "xl:bg-right-bottom"
  | "lg:bg-right-bottom"
  | "md:bg-right-bottom"
  | "sm:bg-right-bottom"
  | "xs:bg-right-bottom"
  | "2xl:bg-right-top"
  | "1xl:bg-right-top"
  | "xl:bg-right-top"
  | "lg:bg-right-top"
  | "md:bg-right-top"
  | "sm:bg-right-top"
  | "xs:bg-right-top"
  | "2xl:bg-top"
  | "1xl:bg-top"
  | "xl:bg-top"
  | "lg:bg-top"
  | "md:bg-top"
  | "sm:bg-top"
  | "xs:bg-top"
  | "2xl:bg-repeat"
  | "1xl:bg-repeat"
  | "xl:bg-repeat"
  | "lg:bg-repeat"
  | "md:bg-repeat"
  | "sm:bg-repeat"
  | "xs:bg-repeat"
  | "2xl:bg-no-repeat"
  | "1xl:bg-no-repeat"
  | "xl:bg-no-repeat"
  | "lg:bg-no-repeat"
  | "md:bg-no-repeat"
  | "sm:bg-no-repeat"
  | "xs:bg-no-repeat"
  | "2xl:bg-repeat-x"
  | "1xl:bg-repeat-x"
  | "xl:bg-repeat-x"
  | "lg:bg-repeat-x"
  | "md:bg-repeat-x"
  | "sm:bg-repeat-x"
  | "xs:bg-repeat-x"
  | "2xl:bg-repeat-y"
  | "1xl:bg-repeat-y"
  | "xl:bg-repeat-y"
  | "lg:bg-repeat-y"
  | "md:bg-repeat-y"
  | "sm:bg-repeat-y"
  | "xs:bg-repeat-y"
  | "2xl:bg-repeat-round"
  | "1xl:bg-repeat-round"
  | "xl:bg-repeat-round"
  | "lg:bg-repeat-round"
  | "md:bg-repeat-round"
  | "sm:bg-repeat-round"
  | "xs:bg-repeat-round"
  | "2xl:bg-repeat-space"
  | "1xl:bg-repeat-space"
  | "xl:bg-repeat-space"
  | "lg:bg-repeat-space"
  | "md:bg-repeat-space"
  | "sm:bg-repeat-space"
  | "xs:bg-repeat-space"
  | "2xl:bg-auto"
  | "1xl:bg-auto"
  | "xl:bg-auto"
  | "lg:bg-auto"
  | "md:bg-auto"
  | "sm:bg-auto"
  | "xs:bg-auto"
  | "2xl:bg-cover"
  | "1xl:bg-cover"
  | "xl:bg-cover"
  | "lg:bg-cover"
  | "md:bg-cover"
  | "sm:bg-cover"
  | "xs:bg-cover"
  | "2xl:bg-contain"
  | "1xl:bg-contain"
  | "xl:bg-contain"
  | "lg:bg-contain"
  | "md:bg-contain"
  | "sm:bg-contain"
  | "xs:bg-contain"
  | "2xl:bg-origin-border"
  | "1xl:bg-origin-border"
  | "xl:bg-origin-border"
  | "lg:bg-origin-border"
  | "md:bg-origin-border"
  | "sm:bg-origin-border"
  | "xs:bg-origin-border"
  | "2xl:bg-origin-padding"
  | "1xl:bg-origin-padding"
  | "xl:bg-origin-padding"
  | "lg:bg-origin-padding"
  | "md:bg-origin-padding"
  | "sm:bg-origin-padding"
  | "xs:bg-origin-padding"
  | "2xl:bg-origin-content"
  | "1xl:bg-origin-content"
  | "xl:bg-origin-content"
  | "lg:bg-origin-content"
  | "md:bg-origin-content"
  | "sm:bg-origin-content"
  | "xs:bg-origin-content"
  | "2xl:blur-0"
  | "1xl:blur-0"
  | "xl:blur-0"
  | "lg:blur-0"
  | "md:blur-0"
  | "sm:blur-0"
  | "xs:blur-0"
  | "2xl:blur-none"
  | "1xl:blur-none"
  | "xl:blur-none"
  | "lg:blur-none"
  | "md:blur-none"
  | "sm:blur-none"
  | "xs:blur-none"
  | "2xl:blur-sm"
  | "1xl:blur-sm"
  | "xl:blur-sm"
  | "lg:blur-sm"
  | "md:blur-sm"
  | "sm:blur-sm"
  | "xs:blur-sm"
  | "2xl:blur"
  | "1xl:blur"
  | "xl:blur"
  | "lg:blur"
  | "md:blur"
  | "sm:blur"
  | "xs:blur"
  | "2xl:blur-md"
  | "1xl:blur-md"
  | "xl:blur-md"
  | "lg:blur-md"
  | "md:blur-md"
  | "sm:blur-md"
  | "xs:blur-md"
  | "2xl:blur-lg"
  | "1xl:blur-lg"
  | "xl:blur-lg"
  | "lg:blur-lg"
  | "md:blur-lg"
  | "sm:blur-lg"
  | "xs:blur-lg"
  | "2xl:blur-xl"
  | "1xl:blur-xl"
  | "xl:blur-xl"
  | "lg:blur-xl"
  | "md:blur-xl"
  | "sm:blur-xl"
  | "xs:blur-xl"
  | "2xl:blur-2xl"
  | "1xl:blur-2xl"
  | "xl:blur-2xl"
  | "lg:blur-2xl"
  | "md:blur-2xl"
  | "sm:blur-2xl"
  | "xs:blur-2xl"
  | "2xl:blur-3xl"
  | "1xl:blur-3xl"
  | "xl:blur-3xl"
  | "lg:blur-3xl"
  | "md:blur-3xl"
  | "sm:blur-3xl"
  | "xs:blur-3xl"
  | "2xl:border-collapse"
  | "1xl:border-collapse"
  | "xl:border-collapse"
  | "lg:border-collapse"
  | "md:border-collapse"
  | "sm:border-collapse"
  | "xs:border-collapse"
  | "2xl:border-separate"
  | "1xl:border-separate"
  | "xl:border-separate"
  | "lg:border-separate"
  | "md:border-separate"
  | "sm:border-separate"
  | "xs:border-separate"
  | "2xl:border-transparent"
  | "1xl:border-transparent"
  | "xl:border-transparent"
  | "lg:border-transparent"
  | "md:border-transparent"
  | "sm:border-transparent"
  | "xs:border-transparent"
  | "group-hover:border-transparent"
  | "focus-within:border-transparent"
  | "hover:border-transparent"
  | "focus:border-transparent"
  | "checked:border-transparent"
  | "checked:border-transparent"
  | "2xl:border-current"
  | "1xl:border-current"
  | "xl:border-current"
  | "lg:border-current"
  | "md:border-current"
  | "sm:border-current"
  | "xs:border-current"
  | "group-hover:border-current"
  | "focus-within:border-current"
  | "hover:border-current"
  | "focus:border-current"
  | "checked:border-current"
  | "checked:border-current"
  | "2xl:border-black"
  | "1xl:border-black"
  | "xl:border-black"
  | "lg:border-black"
  | "md:border-black"
  | "sm:border-black"
  | "xs:border-black"
  | "group-hover:border-black"
  | "focus-within:border-black"
  | "hover:border-black"
  | "focus:border-black"
  | "checked:border-black"
  | "checked:border-black"
  | "2xl:border-white"
  | "1xl:border-white"
  | "xl:border-white"
  | "lg:border-white"
  | "md:border-white"
  | "sm:border-white"
  | "xs:border-white"
  | "group-hover:border-white"
  | "focus-within:border-white"
  | "hover:border-white"
  | "focus:border-white"
  | "checked:border-white"
  | "checked:border-white"
  | "2xl:border-gray-50"
  | "1xl:border-gray-50"
  | "xl:border-gray-50"
  | "lg:border-gray-50"
  | "md:border-gray-50"
  | "sm:border-gray-50"
  | "xs:border-gray-50"
  | "group-hover:border-gray-50"
  | "focus-within:border-gray-50"
  | "hover:border-gray-50"
  | "focus:border-gray-50"
  | "checked:border-gray-50"
  | "checked:border-gray-50"
  | "2xl:border-gray-100"
  | "1xl:border-gray-100"
  | "xl:border-gray-100"
  | "lg:border-gray-100"
  | "md:border-gray-100"
  | "sm:border-gray-100"
  | "xs:border-gray-100"
  | "group-hover:border-gray-100"
  | "focus-within:border-gray-100"
  | "hover:border-gray-100"
  | "focus:border-gray-100"
  | "checked:border-gray-100"
  | "checked:border-gray-100"
  | "2xl:border-gray-200"
  | "1xl:border-gray-200"
  | "xl:border-gray-200"
  | "lg:border-gray-200"
  | "md:border-gray-200"
  | "sm:border-gray-200"
  | "xs:border-gray-200"
  | "group-hover:border-gray-200"
  | "focus-within:border-gray-200"
  | "hover:border-gray-200"
  | "focus:border-gray-200"
  | "checked:border-gray-200"
  | "checked:border-gray-200"
  | "2xl:border-gray-300"
  | "1xl:border-gray-300"
  | "xl:border-gray-300"
  | "lg:border-gray-300"
  | "md:border-gray-300"
  | "sm:border-gray-300"
  | "xs:border-gray-300"
  | "group-hover:border-gray-300"
  | "focus-within:border-gray-300"
  | "hover:border-gray-300"
  | "focus:border-gray-300"
  | "checked:border-gray-300"
  | "checked:border-gray-300"
  | "2xl:border-gray-400"
  | "1xl:border-gray-400"
  | "xl:border-gray-400"
  | "lg:border-gray-400"
  | "md:border-gray-400"
  | "sm:border-gray-400"
  | "xs:border-gray-400"
  | "group-hover:border-gray-400"
  | "focus-within:border-gray-400"
  | "hover:border-gray-400"
  | "focus:border-gray-400"
  | "checked:border-gray-400"
  | "checked:border-gray-400"
  | "2xl:border-gray-500"
  | "1xl:border-gray-500"
  | "xl:border-gray-500"
  | "lg:border-gray-500"
  | "md:border-gray-500"
  | "sm:border-gray-500"
  | "xs:border-gray-500"
  | "group-hover:border-gray-500"
  | "focus-within:border-gray-500"
  | "hover:border-gray-500"
  | "focus:border-gray-500"
  | "checked:border-gray-500"
  | "checked:border-gray-500"
  | "2xl:border-gray-600"
  | "1xl:border-gray-600"
  | "xl:border-gray-600"
  | "lg:border-gray-600"
  | "md:border-gray-600"
  | "sm:border-gray-600"
  | "xs:border-gray-600"
  | "group-hover:border-gray-600"
  | "focus-within:border-gray-600"
  | "hover:border-gray-600"
  | "focus:border-gray-600"
  | "checked:border-gray-600"
  | "checked:border-gray-600"
  | "2xl:border-gray-700"
  | "1xl:border-gray-700"
  | "xl:border-gray-700"
  | "lg:border-gray-700"
  | "md:border-gray-700"
  | "sm:border-gray-700"
  | "xs:border-gray-700"
  | "group-hover:border-gray-700"
  | "focus-within:border-gray-700"
  | "hover:border-gray-700"
  | "focus:border-gray-700"
  | "checked:border-gray-700"
  | "checked:border-gray-700"
  | "2xl:border-gray-800"
  | "1xl:border-gray-800"
  | "xl:border-gray-800"
  | "lg:border-gray-800"
  | "md:border-gray-800"
  | "sm:border-gray-800"
  | "xs:border-gray-800"
  | "group-hover:border-gray-800"
  | "focus-within:border-gray-800"
  | "hover:border-gray-800"
  | "focus:border-gray-800"
  | "checked:border-gray-800"
  | "checked:border-gray-800"
  | "2xl:border-gray-900"
  | "1xl:border-gray-900"
  | "xl:border-gray-900"
  | "lg:border-gray-900"
  | "md:border-gray-900"
  | "sm:border-gray-900"
  | "xs:border-gray-900"
  | "group-hover:border-gray-900"
  | "focus-within:border-gray-900"
  | "hover:border-gray-900"
  | "focus:border-gray-900"
  | "checked:border-gray-900"
  | "checked:border-gray-900"
  | "2xl:border-red-50"
  | "1xl:border-red-50"
  | "xl:border-red-50"
  | "lg:border-red-50"
  | "md:border-red-50"
  | "sm:border-red-50"
  | "xs:border-red-50"
  | "group-hover:border-red-50"
  | "focus-within:border-red-50"
  | "hover:border-red-50"
  | "focus:border-red-50"
  | "checked:border-red-50"
  | "checked:border-red-50"
  | "2xl:border-red-100"
  | "1xl:border-red-100"
  | "xl:border-red-100"
  | "lg:border-red-100"
  | "md:border-red-100"
  | "sm:border-red-100"
  | "xs:border-red-100"
  | "group-hover:border-red-100"
  | "focus-within:border-red-100"
  | "hover:border-red-100"
  | "focus:border-red-100"
  | "checked:border-red-100"
  | "checked:border-red-100"
  | "2xl:border-red-200"
  | "1xl:border-red-200"
  | "xl:border-red-200"
  | "lg:border-red-200"
  | "md:border-red-200"
  | "sm:border-red-200"
  | "xs:border-red-200"
  | "group-hover:border-red-200"
  | "focus-within:border-red-200"
  | "hover:border-red-200"
  | "focus:border-red-200"
  | "checked:border-red-200"
  | "checked:border-red-200"
  | "2xl:border-red-300"
  | "1xl:border-red-300"
  | "xl:border-red-300"
  | "lg:border-red-300"
  | "md:border-red-300"
  | "sm:border-red-300"
  | "xs:border-red-300"
  | "group-hover:border-red-300"
  | "focus-within:border-red-300"
  | "hover:border-red-300"
  | "focus:border-red-300"
  | "checked:border-red-300"
  | "checked:border-red-300"
  | "2xl:border-red-400"
  | "1xl:border-red-400"
  | "xl:border-red-400"
  | "lg:border-red-400"
  | "md:border-red-400"
  | "sm:border-red-400"
  | "xs:border-red-400"
  | "group-hover:border-red-400"
  | "focus-within:border-red-400"
  | "hover:border-red-400"
  | "focus:border-red-400"
  | "checked:border-red-400"
  | "checked:border-red-400"
  | "2xl:border-red-500"
  | "1xl:border-red-500"
  | "xl:border-red-500"
  | "lg:border-red-500"
  | "md:border-red-500"
  | "sm:border-red-500"
  | "xs:border-red-500"
  | "group-hover:border-red-500"
  | "focus-within:border-red-500"
  | "hover:border-red-500"
  | "focus:border-red-500"
  | "checked:border-red-500"
  | "checked:border-red-500"
  | "2xl:border-red-600"
  | "1xl:border-red-600"
  | "xl:border-red-600"
  | "lg:border-red-600"
  | "md:border-red-600"
  | "sm:border-red-600"
  | "xs:border-red-600"
  | "group-hover:border-red-600"
  | "focus-within:border-red-600"
  | "hover:border-red-600"
  | "focus:border-red-600"
  | "checked:border-red-600"
  | "checked:border-red-600"
  | "2xl:border-red-700"
  | "1xl:border-red-700"
  | "xl:border-red-700"
  | "lg:border-red-700"
  | "md:border-red-700"
  | "sm:border-red-700"
  | "xs:border-red-700"
  | "group-hover:border-red-700"
  | "focus-within:border-red-700"
  | "hover:border-red-700"
  | "focus:border-red-700"
  | "checked:border-red-700"
  | "checked:border-red-700"
  | "2xl:border-red-800"
  | "1xl:border-red-800"
  | "xl:border-red-800"
  | "lg:border-red-800"
  | "md:border-red-800"
  | "sm:border-red-800"
  | "xs:border-red-800"
  | "group-hover:border-red-800"
  | "focus-within:border-red-800"
  | "hover:border-red-800"
  | "focus:border-red-800"
  | "checked:border-red-800"
  | "checked:border-red-800"
  | "2xl:border-red-900"
  | "1xl:border-red-900"
  | "xl:border-red-900"
  | "lg:border-red-900"
  | "md:border-red-900"
  | "sm:border-red-900"
  | "xs:border-red-900"
  | "group-hover:border-red-900"
  | "focus-within:border-red-900"
  | "hover:border-red-900"
  | "focus:border-red-900"
  | "checked:border-red-900"
  | "checked:border-red-900"
  | "2xl:border-yellow-50"
  | "1xl:border-yellow-50"
  | "xl:border-yellow-50"
  | "lg:border-yellow-50"
  | "md:border-yellow-50"
  | "sm:border-yellow-50"
  | "xs:border-yellow-50"
  | "group-hover:border-yellow-50"
  | "focus-within:border-yellow-50"
  | "hover:border-yellow-50"
  | "focus:border-yellow-50"
  | "checked:border-yellow-50"
  | "checked:border-yellow-50"
  | "2xl:border-yellow-100"
  | "1xl:border-yellow-100"
  | "xl:border-yellow-100"
  | "lg:border-yellow-100"
  | "md:border-yellow-100"
  | "sm:border-yellow-100"
  | "xs:border-yellow-100"
  | "group-hover:border-yellow-100"
  | "focus-within:border-yellow-100"
  | "hover:border-yellow-100"
  | "focus:border-yellow-100"
  | "checked:border-yellow-100"
  | "checked:border-yellow-100"
  | "2xl:border-yellow-200"
  | "1xl:border-yellow-200"
  | "xl:border-yellow-200"
  | "lg:border-yellow-200"
  | "md:border-yellow-200"
  | "sm:border-yellow-200"
  | "xs:border-yellow-200"
  | "group-hover:border-yellow-200"
  | "focus-within:border-yellow-200"
  | "hover:border-yellow-200"
  | "focus:border-yellow-200"
  | "checked:border-yellow-200"
  | "checked:border-yellow-200"
  | "2xl:border-yellow-300"
  | "1xl:border-yellow-300"
  | "xl:border-yellow-300"
  | "lg:border-yellow-300"
  | "md:border-yellow-300"
  | "sm:border-yellow-300"
  | "xs:border-yellow-300"
  | "group-hover:border-yellow-300"
  | "focus-within:border-yellow-300"
  | "hover:border-yellow-300"
  | "focus:border-yellow-300"
  | "checked:border-yellow-300"
  | "checked:border-yellow-300"
  | "2xl:border-yellow-400"
  | "1xl:border-yellow-400"
  | "xl:border-yellow-400"
  | "lg:border-yellow-400"
  | "md:border-yellow-400"
  | "sm:border-yellow-400"
  | "xs:border-yellow-400"
  | "group-hover:border-yellow-400"
  | "focus-within:border-yellow-400"
  | "hover:border-yellow-400"
  | "focus:border-yellow-400"
  | "checked:border-yellow-400"
  | "checked:border-yellow-400"
  | "2xl:border-yellow-500"
  | "1xl:border-yellow-500"
  | "xl:border-yellow-500"
  | "lg:border-yellow-500"
  | "md:border-yellow-500"
  | "sm:border-yellow-500"
  | "xs:border-yellow-500"
  | "group-hover:border-yellow-500"
  | "focus-within:border-yellow-500"
  | "hover:border-yellow-500"
  | "focus:border-yellow-500"
  | "checked:border-yellow-500"
  | "checked:border-yellow-500"
  | "2xl:border-yellow-600"
  | "1xl:border-yellow-600"
  | "xl:border-yellow-600"
  | "lg:border-yellow-600"
  | "md:border-yellow-600"
  | "sm:border-yellow-600"
  | "xs:border-yellow-600"
  | "group-hover:border-yellow-600"
  | "focus-within:border-yellow-600"
  | "hover:border-yellow-600"
  | "focus:border-yellow-600"
  | "checked:border-yellow-600"
  | "checked:border-yellow-600"
  | "2xl:border-yellow-700"
  | "1xl:border-yellow-700"
  | "xl:border-yellow-700"
  | "lg:border-yellow-700"
  | "md:border-yellow-700"
  | "sm:border-yellow-700"
  | "xs:border-yellow-700"
  | "group-hover:border-yellow-700"
  | "focus-within:border-yellow-700"
  | "hover:border-yellow-700"
  | "focus:border-yellow-700"
  | "checked:border-yellow-700"
  | "checked:border-yellow-700"
  | "2xl:border-yellow-800"
  | "1xl:border-yellow-800"
  | "xl:border-yellow-800"
  | "lg:border-yellow-800"
  | "md:border-yellow-800"
  | "sm:border-yellow-800"
  | "xs:border-yellow-800"
  | "group-hover:border-yellow-800"
  | "focus-within:border-yellow-800"
  | "hover:border-yellow-800"
  | "focus:border-yellow-800"
  | "checked:border-yellow-800"
  | "checked:border-yellow-800"
  | "2xl:border-yellow-900"
  | "1xl:border-yellow-900"
  | "xl:border-yellow-900"
  | "lg:border-yellow-900"
  | "md:border-yellow-900"
  | "sm:border-yellow-900"
  | "xs:border-yellow-900"
  | "group-hover:border-yellow-900"
  | "focus-within:border-yellow-900"
  | "hover:border-yellow-900"
  | "focus:border-yellow-900"
  | "checked:border-yellow-900"
  | "checked:border-yellow-900"
  | "2xl:border-green-50"
  | "1xl:border-green-50"
  | "xl:border-green-50"
  | "lg:border-green-50"
  | "md:border-green-50"
  | "sm:border-green-50"
  | "xs:border-green-50"
  | "group-hover:border-green-50"
  | "focus-within:border-green-50"
  | "hover:border-green-50"
  | "focus:border-green-50"
  | "checked:border-green-50"
  | "checked:border-green-50"
  | "2xl:border-green-100"
  | "1xl:border-green-100"
  | "xl:border-green-100"
  | "lg:border-green-100"
  | "md:border-green-100"
  | "sm:border-green-100"
  | "xs:border-green-100"
  | "group-hover:border-green-100"
  | "focus-within:border-green-100"
  | "hover:border-green-100"
  | "focus:border-green-100"
  | "checked:border-green-100"
  | "checked:border-green-100"
  | "2xl:border-green-200"
  | "1xl:border-green-200"
  | "xl:border-green-200"
  | "lg:border-green-200"
  | "md:border-green-200"
  | "sm:border-green-200"
  | "xs:border-green-200"
  | "group-hover:border-green-200"
  | "focus-within:border-green-200"
  | "hover:border-green-200"
  | "focus:border-green-200"
  | "checked:border-green-200"
  | "checked:border-green-200"
  | "2xl:border-green-300"
  | "1xl:border-green-300"
  | "xl:border-green-300"
  | "lg:border-green-300"
  | "md:border-green-300"
  | "sm:border-green-300"
  | "xs:border-green-300"
  | "group-hover:border-green-300"
  | "focus-within:border-green-300"
  | "hover:border-green-300"
  | "focus:border-green-300"
  | "checked:border-green-300"
  | "checked:border-green-300"
  | "2xl:border-green-400"
  | "1xl:border-green-400"
  | "xl:border-green-400"
  | "lg:border-green-400"
  | "md:border-green-400"
  | "sm:border-green-400"
  | "xs:border-green-400"
  | "group-hover:border-green-400"
  | "focus-within:border-green-400"
  | "hover:border-green-400"
  | "focus:border-green-400"
  | "checked:border-green-400"
  | "checked:border-green-400"
  | "2xl:border-green-500"
  | "1xl:border-green-500"
  | "xl:border-green-500"
  | "lg:border-green-500"
  | "md:border-green-500"
  | "sm:border-green-500"
  | "xs:border-green-500"
  | "group-hover:border-green-500"
  | "focus-within:border-green-500"
  | "hover:border-green-500"
  | "focus:border-green-500"
  | "checked:border-green-500"
  | "checked:border-green-500"
  | "2xl:border-green-600"
  | "1xl:border-green-600"
  | "xl:border-green-600"
  | "lg:border-green-600"
  | "md:border-green-600"
  | "sm:border-green-600"
  | "xs:border-green-600"
  | "group-hover:border-green-600"
  | "focus-within:border-green-600"
  | "hover:border-green-600"
  | "focus:border-green-600"
  | "checked:border-green-600"
  | "checked:border-green-600"
  | "2xl:border-green-700"
  | "1xl:border-green-700"
  | "xl:border-green-700"
  | "lg:border-green-700"
  | "md:border-green-700"
  | "sm:border-green-700"
  | "xs:border-green-700"
  | "group-hover:border-green-700"
  | "focus-within:border-green-700"
  | "hover:border-green-700"
  | "focus:border-green-700"
  | "checked:border-green-700"
  | "checked:border-green-700"
  | "2xl:border-green-800"
  | "1xl:border-green-800"
  | "xl:border-green-800"
  | "lg:border-green-800"
  | "md:border-green-800"
  | "sm:border-green-800"
  | "xs:border-green-800"
  | "group-hover:border-green-800"
  | "focus-within:border-green-800"
  | "hover:border-green-800"
  | "focus:border-green-800"
  | "checked:border-green-800"
  | "checked:border-green-800"
  | "2xl:border-green-900"
  | "1xl:border-green-900"
  | "xl:border-green-900"
  | "lg:border-green-900"
  | "md:border-green-900"
  | "sm:border-green-900"
  | "xs:border-green-900"
  | "group-hover:border-green-900"
  | "focus-within:border-green-900"
  | "hover:border-green-900"
  | "focus:border-green-900"
  | "checked:border-green-900"
  | "checked:border-green-900"
  | "2xl:border-blue-50"
  | "1xl:border-blue-50"
  | "xl:border-blue-50"
  | "lg:border-blue-50"
  | "md:border-blue-50"
  | "sm:border-blue-50"
  | "xs:border-blue-50"
  | "group-hover:border-blue-50"
  | "focus-within:border-blue-50"
  | "hover:border-blue-50"
  | "focus:border-blue-50"
  | "checked:border-blue-50"
  | "checked:border-blue-50"
  | "2xl:border-blue-100"
  | "1xl:border-blue-100"
  | "xl:border-blue-100"
  | "lg:border-blue-100"
  | "md:border-blue-100"
  | "sm:border-blue-100"
  | "xs:border-blue-100"
  | "group-hover:border-blue-100"
  | "focus-within:border-blue-100"
  | "hover:border-blue-100"
  | "focus:border-blue-100"
  | "checked:border-blue-100"
  | "checked:border-blue-100"
  | "2xl:border-blue-200"
  | "1xl:border-blue-200"
  | "xl:border-blue-200"
  | "lg:border-blue-200"
  | "md:border-blue-200"
  | "sm:border-blue-200"
  | "xs:border-blue-200"
  | "group-hover:border-blue-200"
  | "focus-within:border-blue-200"
  | "hover:border-blue-200"
  | "focus:border-blue-200"
  | "checked:border-blue-200"
  | "checked:border-blue-200"
  | "2xl:border-blue-300"
  | "1xl:border-blue-300"
  | "xl:border-blue-300"
  | "lg:border-blue-300"
  | "md:border-blue-300"
  | "sm:border-blue-300"
  | "xs:border-blue-300"
  | "group-hover:border-blue-300"
  | "focus-within:border-blue-300"
  | "hover:border-blue-300"
  | "focus:border-blue-300"
  | "checked:border-blue-300"
  | "checked:border-blue-300"
  | "2xl:border-blue-400"
  | "1xl:border-blue-400"
  | "xl:border-blue-400"
  | "lg:border-blue-400"
  | "md:border-blue-400"
  | "sm:border-blue-400"
  | "xs:border-blue-400"
  | "group-hover:border-blue-400"
  | "focus-within:border-blue-400"
  | "hover:border-blue-400"
  | "focus:border-blue-400"
  | "checked:border-blue-400"
  | "checked:border-blue-400"
  | "2xl:border-blue-500"
  | "1xl:border-blue-500"
  | "xl:border-blue-500"
  | "lg:border-blue-500"
  | "md:border-blue-500"
  | "sm:border-blue-500"
  | "xs:border-blue-500"
  | "group-hover:border-blue-500"
  | "focus-within:border-blue-500"
  | "hover:border-blue-500"
  | "focus:border-blue-500"
  | "checked:border-blue-500"
  | "checked:border-blue-500"
  | "2xl:border-blue-600"
  | "1xl:border-blue-600"
  | "xl:border-blue-600"
  | "lg:border-blue-600"
  | "md:border-blue-600"
  | "sm:border-blue-600"
  | "xs:border-blue-600"
  | "group-hover:border-blue-600"
  | "focus-within:border-blue-600"
  | "hover:border-blue-600"
  | "focus:border-blue-600"
  | "checked:border-blue-600"
  | "checked:border-blue-600"
  | "2xl:border-blue-700"
  | "1xl:border-blue-700"
  | "xl:border-blue-700"
  | "lg:border-blue-700"
  | "md:border-blue-700"
  | "sm:border-blue-700"
  | "xs:border-blue-700"
  | "group-hover:border-blue-700"
  | "focus-within:border-blue-700"
  | "hover:border-blue-700"
  | "focus:border-blue-700"
  | "checked:border-blue-700"
  | "checked:border-blue-700"
  | "2xl:border-blue-800"
  | "1xl:border-blue-800"
  | "xl:border-blue-800"
  | "lg:border-blue-800"
  | "md:border-blue-800"
  | "sm:border-blue-800"
  | "xs:border-blue-800"
  | "group-hover:border-blue-800"
  | "focus-within:border-blue-800"
  | "hover:border-blue-800"
  | "focus:border-blue-800"
  | "checked:border-blue-800"
  | "checked:border-blue-800"
  | "2xl:border-blue-900"
  | "1xl:border-blue-900"
  | "xl:border-blue-900"
  | "lg:border-blue-900"
  | "md:border-blue-900"
  | "sm:border-blue-900"
  | "xs:border-blue-900"
  | "group-hover:border-blue-900"
  | "focus-within:border-blue-900"
  | "hover:border-blue-900"
  | "focus:border-blue-900"
  | "checked:border-blue-900"
  | "checked:border-blue-900"
  | "2xl:border-indigo-50"
  | "1xl:border-indigo-50"
  | "xl:border-indigo-50"
  | "lg:border-indigo-50"
  | "md:border-indigo-50"
  | "sm:border-indigo-50"
  | "xs:border-indigo-50"
  | "group-hover:border-indigo-50"
  | "focus-within:border-indigo-50"
  | "hover:border-indigo-50"
  | "focus:border-indigo-50"
  | "checked:border-indigo-50"
  | "checked:border-indigo-50"
  | "2xl:border-indigo-100"
  | "1xl:border-indigo-100"
  | "xl:border-indigo-100"
  | "lg:border-indigo-100"
  | "md:border-indigo-100"
  | "sm:border-indigo-100"
  | "xs:border-indigo-100"
  | "group-hover:border-indigo-100"
  | "focus-within:border-indigo-100"
  | "hover:border-indigo-100"
  | "focus:border-indigo-100"
  | "checked:border-indigo-100"
  | "checked:border-indigo-100"
  | "2xl:border-indigo-200"
  | "1xl:border-indigo-200"
  | "xl:border-indigo-200"
  | "lg:border-indigo-200"
  | "md:border-indigo-200"
  | "sm:border-indigo-200"
  | "xs:border-indigo-200"
  | "group-hover:border-indigo-200"
  | "focus-within:border-indigo-200"
  | "hover:border-indigo-200"
  | "focus:border-indigo-200"
  | "checked:border-indigo-200"
  | "checked:border-indigo-200"
  | "2xl:border-indigo-300"
  | "1xl:border-indigo-300"
  | "xl:border-indigo-300"
  | "lg:border-indigo-300"
  | "md:border-indigo-300"
  | "sm:border-indigo-300"
  | "xs:border-indigo-300"
  | "group-hover:border-indigo-300"
  | "focus-within:border-indigo-300"
  | "hover:border-indigo-300"
  | "focus:border-indigo-300"
  | "checked:border-indigo-300"
  | "checked:border-indigo-300"
  | "2xl:border-indigo-400"
  | "1xl:border-indigo-400"
  | "xl:border-indigo-400"
  | "lg:border-indigo-400"
  | "md:border-indigo-400"
  | "sm:border-indigo-400"
  | "xs:border-indigo-400"
  | "group-hover:border-indigo-400"
  | "focus-within:border-indigo-400"
  | "hover:border-indigo-400"
  | "focus:border-indigo-400"
  | "checked:border-indigo-400"
  | "checked:border-indigo-400"
  | "2xl:border-indigo-500"
  | "1xl:border-indigo-500"
  | "xl:border-indigo-500"
  | "lg:border-indigo-500"
  | "md:border-indigo-500"
  | "sm:border-indigo-500"
  | "xs:border-indigo-500"
  | "group-hover:border-indigo-500"
  | "focus-within:border-indigo-500"
  | "hover:border-indigo-500"
  | "focus:border-indigo-500"
  | "checked:border-indigo-500"
  | "checked:border-indigo-500"
  | "2xl:border-indigo-600"
  | "1xl:border-indigo-600"
  | "xl:border-indigo-600"
  | "lg:border-indigo-600"
  | "md:border-indigo-600"
  | "sm:border-indigo-600"
  | "xs:border-indigo-600"
  | "group-hover:border-indigo-600"
  | "focus-within:border-indigo-600"
  | "hover:border-indigo-600"
  | "focus:border-indigo-600"
  | "checked:border-indigo-600"
  | "checked:border-indigo-600"
  | "2xl:border-indigo-700"
  | "1xl:border-indigo-700"
  | "xl:border-indigo-700"
  | "lg:border-indigo-700"
  | "md:border-indigo-700"
  | "sm:border-indigo-700"
  | "xs:border-indigo-700"
  | "group-hover:border-indigo-700"
  | "focus-within:border-indigo-700"
  | "hover:border-indigo-700"
  | "focus:border-indigo-700"
  | "checked:border-indigo-700"
  | "checked:border-indigo-700"
  | "2xl:border-indigo-800"
  | "1xl:border-indigo-800"
  | "xl:border-indigo-800"
  | "lg:border-indigo-800"
  | "md:border-indigo-800"
  | "sm:border-indigo-800"
  | "xs:border-indigo-800"
  | "group-hover:border-indigo-800"
  | "focus-within:border-indigo-800"
  | "hover:border-indigo-800"
  | "focus:border-indigo-800"
  | "checked:border-indigo-800"
  | "checked:border-indigo-800"
  | "2xl:border-indigo-900"
  | "1xl:border-indigo-900"
  | "xl:border-indigo-900"
  | "lg:border-indigo-900"
  | "md:border-indigo-900"
  | "sm:border-indigo-900"
  | "xs:border-indigo-900"
  | "group-hover:border-indigo-900"
  | "focus-within:border-indigo-900"
  | "hover:border-indigo-900"
  | "focus:border-indigo-900"
  | "checked:border-indigo-900"
  | "checked:border-indigo-900"
  | "2xl:border-purple-50"
  | "1xl:border-purple-50"
  | "xl:border-purple-50"
  | "lg:border-purple-50"
  | "md:border-purple-50"
  | "sm:border-purple-50"
  | "xs:border-purple-50"
  | "group-hover:border-purple-50"
  | "focus-within:border-purple-50"
  | "hover:border-purple-50"
  | "focus:border-purple-50"
  | "checked:border-purple-50"
  | "checked:border-purple-50"
  | "2xl:border-purple-100"
  | "1xl:border-purple-100"
  | "xl:border-purple-100"
  | "lg:border-purple-100"
  | "md:border-purple-100"
  | "sm:border-purple-100"
  | "xs:border-purple-100"
  | "group-hover:border-purple-100"
  | "focus-within:border-purple-100"
  | "hover:border-purple-100"
  | "focus:border-purple-100"
  | "checked:border-purple-100"
  | "checked:border-purple-100"
  | "2xl:border-purple-200"
  | "1xl:border-purple-200"
  | "xl:border-purple-200"
  | "lg:border-purple-200"
  | "md:border-purple-200"
  | "sm:border-purple-200"
  | "xs:border-purple-200"
  | "group-hover:border-purple-200"
  | "focus-within:border-purple-200"
  | "hover:border-purple-200"
  | "focus:border-purple-200"
  | "checked:border-purple-200"
  | "checked:border-purple-200"
  | "2xl:border-purple-300"
  | "1xl:border-purple-300"
  | "xl:border-purple-300"
  | "lg:border-purple-300"
  | "md:border-purple-300"
  | "sm:border-purple-300"
  | "xs:border-purple-300"
  | "group-hover:border-purple-300"
  | "focus-within:border-purple-300"
  | "hover:border-purple-300"
  | "focus:border-purple-300"
  | "checked:border-purple-300"
  | "checked:border-purple-300"
  | "2xl:border-purple-400"
  | "1xl:border-purple-400"
  | "xl:border-purple-400"
  | "lg:border-purple-400"
  | "md:border-purple-400"
  | "sm:border-purple-400"
  | "xs:border-purple-400"
  | "group-hover:border-purple-400"
  | "focus-within:border-purple-400"
  | "hover:border-purple-400"
  | "focus:border-purple-400"
  | "checked:border-purple-400"
  | "checked:border-purple-400"
  | "2xl:border-purple-500"
  | "1xl:border-purple-500"
  | "xl:border-purple-500"
  | "lg:border-purple-500"
  | "md:border-purple-500"
  | "sm:border-purple-500"
  | "xs:border-purple-500"
  | "group-hover:border-purple-500"
  | "focus-within:border-purple-500"
  | "hover:border-purple-500"
  | "focus:border-purple-500"
  | "checked:border-purple-500"
  | "checked:border-purple-500"
  | "2xl:border-purple-600"
  | "1xl:border-purple-600"
  | "xl:border-purple-600"
  | "lg:border-purple-600"
  | "md:border-purple-600"
  | "sm:border-purple-600"
  | "xs:border-purple-600"
  | "group-hover:border-purple-600"
  | "focus-within:border-purple-600"
  | "hover:border-purple-600"
  | "focus:border-purple-600"
  | "checked:border-purple-600"
  | "checked:border-purple-600"
  | "2xl:border-purple-700"
  | "1xl:border-purple-700"
  | "xl:border-purple-700"
  | "lg:border-purple-700"
  | "md:border-purple-700"
  | "sm:border-purple-700"
  | "xs:border-purple-700"
  | "group-hover:border-purple-700"
  | "focus-within:border-purple-700"
  | "hover:border-purple-700"
  | "focus:border-purple-700"
  | "checked:border-purple-700"
  | "checked:border-purple-700"
  | "2xl:border-purple-800"
  | "1xl:border-purple-800"
  | "xl:border-purple-800"
  | "lg:border-purple-800"
  | "md:border-purple-800"
  | "sm:border-purple-800"
  | "xs:border-purple-800"
  | "group-hover:border-purple-800"
  | "focus-within:border-purple-800"
  | "hover:border-purple-800"
  | "focus:border-purple-800"
  | "checked:border-purple-800"
  | "checked:border-purple-800"
  | "2xl:border-purple-900"
  | "1xl:border-purple-900"
  | "xl:border-purple-900"
  | "lg:border-purple-900"
  | "md:border-purple-900"
  | "sm:border-purple-900"
  | "xs:border-purple-900"
  | "group-hover:border-purple-900"
  | "focus-within:border-purple-900"
  | "hover:border-purple-900"
  | "focus:border-purple-900"
  | "checked:border-purple-900"
  | "checked:border-purple-900"
  | "2xl:border-pink-50"
  | "1xl:border-pink-50"
  | "xl:border-pink-50"
  | "lg:border-pink-50"
  | "md:border-pink-50"
  | "sm:border-pink-50"
  | "xs:border-pink-50"
  | "group-hover:border-pink-50"
  | "focus-within:border-pink-50"
  | "hover:border-pink-50"
  | "focus:border-pink-50"
  | "checked:border-pink-50"
  | "checked:border-pink-50"
  | "2xl:border-pink-100"
  | "1xl:border-pink-100"
  | "xl:border-pink-100"
  | "lg:border-pink-100"
  | "md:border-pink-100"
  | "sm:border-pink-100"
  | "xs:border-pink-100"
  | "group-hover:border-pink-100"
  | "focus-within:border-pink-100"
  | "hover:border-pink-100"
  | "focus:border-pink-100"
  | "checked:border-pink-100"
  | "checked:border-pink-100"
  | "2xl:border-pink-200"
  | "1xl:border-pink-200"
  | "xl:border-pink-200"
  | "lg:border-pink-200"
  | "md:border-pink-200"
  | "sm:border-pink-200"
  | "xs:border-pink-200"
  | "group-hover:border-pink-200"
  | "focus-within:border-pink-200"
  | "hover:border-pink-200"
  | "focus:border-pink-200"
  | "checked:border-pink-200"
  | "checked:border-pink-200"
  | "2xl:border-pink-300"
  | "1xl:border-pink-300"
  | "xl:border-pink-300"
  | "lg:border-pink-300"
  | "md:border-pink-300"
  | "sm:border-pink-300"
  | "xs:border-pink-300"
  | "group-hover:border-pink-300"
  | "focus-within:border-pink-300"
  | "hover:border-pink-300"
  | "focus:border-pink-300"
  | "checked:border-pink-300"
  | "checked:border-pink-300"
  | "2xl:border-pink-400"
  | "1xl:border-pink-400"
  | "xl:border-pink-400"
  | "lg:border-pink-400"
  | "md:border-pink-400"
  | "sm:border-pink-400"
  | "xs:border-pink-400"
  | "group-hover:border-pink-400"
  | "focus-within:border-pink-400"
  | "hover:border-pink-400"
  | "focus:border-pink-400"
  | "checked:border-pink-400"
  | "checked:border-pink-400"
  | "2xl:border-pink-500"
  | "1xl:border-pink-500"
  | "xl:border-pink-500"
  | "lg:border-pink-500"
  | "md:border-pink-500"
  | "sm:border-pink-500"
  | "xs:border-pink-500"
  | "group-hover:border-pink-500"
  | "focus-within:border-pink-500"
  | "hover:border-pink-500"
  | "focus:border-pink-500"
  | "checked:border-pink-500"
  | "checked:border-pink-500"
  | "2xl:border-pink-600"
  | "1xl:border-pink-600"
  | "xl:border-pink-600"
  | "lg:border-pink-600"
  | "md:border-pink-600"
  | "sm:border-pink-600"
  | "xs:border-pink-600"
  | "group-hover:border-pink-600"
  | "focus-within:border-pink-600"
  | "hover:border-pink-600"
  | "focus:border-pink-600"
  | "checked:border-pink-600"
  | "checked:border-pink-600"
  | "2xl:border-pink-700"
  | "1xl:border-pink-700"
  | "xl:border-pink-700"
  | "lg:border-pink-700"
  | "md:border-pink-700"
  | "sm:border-pink-700"
  | "xs:border-pink-700"
  | "group-hover:border-pink-700"
  | "focus-within:border-pink-700"
  | "hover:border-pink-700"
  | "focus:border-pink-700"
  | "checked:border-pink-700"
  | "checked:border-pink-700"
  | "2xl:border-pink-800"
  | "1xl:border-pink-800"
  | "xl:border-pink-800"
  | "lg:border-pink-800"
  | "md:border-pink-800"
  | "sm:border-pink-800"
  | "xs:border-pink-800"
  | "group-hover:border-pink-800"
  | "focus-within:border-pink-800"
  | "hover:border-pink-800"
  | "focus:border-pink-800"
  | "checked:border-pink-800"
  | "checked:border-pink-800"
  | "2xl:border-pink-900"
  | "1xl:border-pink-900"
  | "xl:border-pink-900"
  | "lg:border-pink-900"
  | "md:border-pink-900"
  | "sm:border-pink-900"
  | "xs:border-pink-900"
  | "group-hover:border-pink-900"
  | "focus-within:border-pink-900"
  | "hover:border-pink-900"
  | "focus:border-pink-900"
  | "checked:border-pink-900"
  | "checked:border-pink-900"
  | "2xl:border-title-color-1"
  | "1xl:border-title-color-1"
  | "xl:border-title-color-1"
  | "lg:border-title-color-1"
  | "md:border-title-color-1"
  | "sm:border-title-color-1"
  | "xs:border-title-color-1"
  | "group-hover:border-title-color-1"
  | "focus-within:border-title-color-1"
  | "hover:border-title-color-1"
  | "focus:border-title-color-1"
  | "checked:border-title-color-1"
  | "checked:border-title-color-1"
  | "2xl:border-primary-bg-color"
  | "1xl:border-primary-bg-color"
  | "xl:border-primary-bg-color"
  | "lg:border-primary-bg-color"
  | "md:border-primary-bg-color"
  | "sm:border-primary-bg-color"
  | "xs:border-primary-bg-color"
  | "group-hover:border-primary-bg-color"
  | "focus-within:border-primary-bg-color"
  | "hover:border-primary-bg-color"
  | "focus:border-primary-bg-color"
  | "checked:border-primary-bg-color"
  | "checked:border-primary-bg-color"
  | "2xl:border-secondary-bg-color"
  | "1xl:border-secondary-bg-color"
  | "xl:border-secondary-bg-color"
  | "lg:border-secondary-bg-color"
  | "md:border-secondary-bg-color"
  | "sm:border-secondary-bg-color"
  | "xs:border-secondary-bg-color"
  | "group-hover:border-secondary-bg-color"
  | "focus-within:border-secondary-bg-color"
  | "hover:border-secondary-bg-color"
  | "focus:border-secondary-bg-color"
  | "checked:border-secondary-bg-color"
  | "checked:border-secondary-bg-color"
  | "2xl:border-sidebar-bg-color"
  | "1xl:border-sidebar-bg-color"
  | "xl:border-sidebar-bg-color"
  | "lg:border-sidebar-bg-color"
  | "md:border-sidebar-bg-color"
  | "sm:border-sidebar-bg-color"
  | "xs:border-sidebar-bg-color"
  | "group-hover:border-sidebar-bg-color"
  | "focus-within:border-sidebar-bg-color"
  | "hover:border-sidebar-bg-color"
  | "focus:border-sidebar-bg-color"
  | "checked:border-sidebar-bg-color"
  | "checked:border-sidebar-bg-color"
  | "2xl:border-header-bg-color"
  | "1xl:border-header-bg-color"
  | "xl:border-header-bg-color"
  | "lg:border-header-bg-color"
  | "md:border-header-bg-color"
  | "sm:border-header-bg-color"
  | "xs:border-header-bg-color"
  | "group-hover:border-header-bg-color"
  | "focus-within:border-header-bg-color"
  | "hover:border-header-bg-color"
  | "focus:border-header-bg-color"
  | "checked:border-header-bg-color"
  | "checked:border-header-bg-color"
  | "2xl:border-body-bg-color"
  | "1xl:border-body-bg-color"
  | "xl:border-body-bg-color"
  | "lg:border-body-bg-color"
  | "md:border-body-bg-color"
  | "sm:border-body-bg-color"
  | "xs:border-body-bg-color"
  | "group-hover:border-body-bg-color"
  | "focus-within:border-body-bg-color"
  | "hover:border-body-bg-color"
  | "focus:border-body-bg-color"
  | "checked:border-body-bg-color"
  | "checked:border-body-bg-color"
  | "2xl:border-main-color"
  | "1xl:border-main-color"
  | "xl:border-main-color"
  | "lg:border-main-color"
  | "md:border-main-color"
  | "sm:border-main-color"
  | "xs:border-main-color"
  | "group-hover:border-main-color"
  | "focus-within:border-main-color"
  | "hover:border-main-color"
  | "focus:border-main-color"
  | "checked:border-main-color"
  | "checked:border-main-color"
  | "2xl:border-tertiary-bg-color"
  | "1xl:border-tertiary-bg-color"
  | "xl:border-tertiary-bg-color"
  | "lg:border-tertiary-bg-color"
  | "md:border-tertiary-bg-color"
  | "sm:border-tertiary-bg-color"
  | "xs:border-tertiary-bg-color"
  | "group-hover:border-tertiary-bg-color"
  | "focus-within:border-tertiary-bg-color"
  | "hover:border-tertiary-bg-color"
  | "focus:border-tertiary-bg-color"
  | "checked:border-tertiary-bg-color"
  | "checked:border-tertiary-bg-color"
  | "2xl:border-quaternary-bg-color"
  | "1xl:border-quaternary-bg-color"
  | "xl:border-quaternary-bg-color"
  | "lg:border-quaternary-bg-color"
  | "md:border-quaternary-bg-color"
  | "sm:border-quaternary-bg-color"
  | "xs:border-quaternary-bg-color"
  | "group-hover:border-quaternary-bg-color"
  | "focus-within:border-quaternary-bg-color"
  | "hover:border-quaternary-bg-color"
  | "focus:border-quaternary-bg-color"
  | "checked:border-quaternary-bg-color"
  | "checked:border-quaternary-bg-color"
  | "2xl:border-highlight-color"
  | "1xl:border-highlight-color"
  | "xl:border-highlight-color"
  | "lg:border-highlight-color"
  | "md:border-highlight-color"
  | "sm:border-highlight-color"
  | "xs:border-highlight-color"
  | "group-hover:border-highlight-color"
  | "focus-within:border-highlight-color"
  | "hover:border-highlight-color"
  | "focus:border-highlight-color"
  | "checked:border-highlight-color"
  | "checked:border-highlight-color"
  | "2xl:border-earnings-bg-color"
  | "1xl:border-earnings-bg-color"
  | "xl:border-earnings-bg-color"
  | "lg:border-earnings-bg-color"
  | "md:border-earnings-bg-color"
  | "sm:border-earnings-bg-color"
  | "xs:border-earnings-bg-color"
  | "group-hover:border-earnings-bg-color"
  | "focus-within:border-earnings-bg-color"
  | "hover:border-earnings-bg-color"
  | "focus:border-earnings-bg-color"
  | "checked:border-earnings-bg-color"
  | "checked:border-earnings-bg-color"
  | "2xl:border-card-secondary-bg-color"
  | "1xl:border-card-secondary-bg-color"
  | "xl:border-card-secondary-bg-color"
  | "lg:border-card-secondary-bg-color"
  | "md:border-card-secondary-bg-color"
  | "sm:border-card-secondary-bg-color"
  | "xs:border-card-secondary-bg-color"
  | "group-hover:border-card-secondary-bg-color"
  | "focus-within:border-card-secondary-bg-color"
  | "hover:border-card-secondary-bg-color"
  | "focus:border-card-secondary-bg-color"
  | "checked:border-card-secondary-bg-color"
  | "checked:border-card-secondary-bg-color"
  | "2xl:border-pink-color"
  | "1xl:border-pink-color"
  | "xl:border-pink-color"
  | "lg:border-pink-color"
  | "md:border-pink-color"
  | "sm:border-pink-color"
  | "xs:border-pink-color"
  | "group-hover:border-pink-color"
  | "focus-within:border-pink-color"
  | "hover:border-pink-color"
  | "focus:border-pink-color"
  | "checked:border-pink-color"
  | "checked:border-pink-color"
  | "2xl:border-go-live-text"
  | "1xl:border-go-live-text"
  | "xl:border-go-live-text"
  | "lg:border-go-live-text"
  | "md:border-go-live-text"
  | "sm:border-go-live-text"
  | "xs:border-go-live-text"
  | "group-hover:border-go-live-text"
  | "focus-within:border-go-live-text"
  | "hover:border-go-live-text"
  | "focus:border-go-live-text"
  | "checked:border-go-live-text"
  | "checked:border-go-live-text"
  | "2xl:border-go-live-bg-color"
  | "1xl:border-go-live-bg-color"
  | "xl:border-go-live-bg-color"
  | "lg:border-go-live-bg-color"
  | "md:border-go-live-bg-color"
  | "sm:border-go-live-bg-color"
  | "xs:border-go-live-bg-color"
  | "group-hover:border-go-live-bg-color"
  | "focus-within:border-go-live-bg-color"
  | "hover:border-go-live-bg-color"
  | "focus:border-go-live-bg-color"
  | "checked:border-go-live-bg-color"
  | "checked:border-go-live-bg-color"
  | "2xl:border-gray-button"
  | "1xl:border-gray-button"
  | "xl:border-gray-button"
  | "lg:border-gray-button"
  | "md:border-gray-button"
  | "sm:border-gray-button"
  | "xs:border-gray-button"
  | "group-hover:border-gray-button"
  | "focus-within:border-gray-button"
  | "hover:border-gray-button"
  | "focus:border-gray-button"
  | "checked:border-gray-button"
  | "checked:border-gray-button"
  | "2xl:border-toggle-disabled"
  | "1xl:border-toggle-disabled"
  | "xl:border-toggle-disabled"
  | "lg:border-toggle-disabled"
  | "md:border-toggle-disabled"
  | "sm:border-toggle-disabled"
  | "xs:border-toggle-disabled"
  | "group-hover:border-toggle-disabled"
  | "focus-within:border-toggle-disabled"
  | "hover:border-toggle-disabled"
  | "focus:border-toggle-disabled"
  | "checked:border-toggle-disabled"
  | "checked:border-toggle-disabled"
  | "2xl:border-tab-not-selected-color"
  | "1xl:border-tab-not-selected-color"
  | "xl:border-tab-not-selected-color"
  | "lg:border-tab-not-selected-color"
  | "md:border-tab-not-selected-color"
  | "sm:border-tab-not-selected-color"
  | "xs:border-tab-not-selected-color"
  | "group-hover:border-tab-not-selected-color"
  | "focus-within:border-tab-not-selected-color"
  | "hover:border-tab-not-selected-color"
  | "focus:border-tab-not-selected-color"
  | "checked:border-tab-not-selected-color"
  | "checked:border-tab-not-selected-color"
  | "2xl:border-text-area-bg-color"
  | "1xl:border-text-area-bg-color"
  | "xl:border-text-area-bg-color"
  | "lg:border-text-area-bg-color"
  | "md:border-text-area-bg-color"
  | "sm:border-text-area-bg-color"
  | "xs:border-text-area-bg-color"
  | "group-hover:border-text-area-bg-color"
  | "focus-within:border-text-area-bg-color"
  | "hover:border-text-area-bg-color"
  | "focus:border-text-area-bg-color"
  | "checked:border-text-area-bg-color"
  | "checked:border-text-area-bg-color"
  | "2xl:border-table-primary-bg-color"
  | "1xl:border-table-primary-bg-color"
  | "xl:border-table-primary-bg-color"
  | "lg:border-table-primary-bg-color"
  | "md:border-table-primary-bg-color"
  | "sm:border-table-primary-bg-color"
  | "xs:border-table-primary-bg-color"
  | "group-hover:border-table-primary-bg-color"
  | "focus-within:border-table-primary-bg-color"
  | "hover:border-table-primary-bg-color"
  | "focus:border-table-primary-bg-color"
  | "checked:border-table-primary-bg-color"
  | "checked:border-table-primary-bg-color"
  | "2xl:border-table-secondary-color"
  | "1xl:border-table-secondary-color"
  | "xl:border-table-secondary-color"
  | "lg:border-table-secondary-color"
  | "md:border-table-secondary-color"
  | "sm:border-table-secondary-color"
  | "xs:border-table-secondary-color"
  | "group-hover:border-table-secondary-color"
  | "focus-within:border-table-secondary-color"
  | "hover:border-table-secondary-color"
  | "focus:border-table-secondary-color"
  | "checked:border-table-secondary-color"
  | "checked:border-table-secondary-color"
  | "2xl:border-modal-button-color"
  | "1xl:border-modal-button-color"
  | "xl:border-modal-button-color"
  | "lg:border-modal-button-color"
  | "md:border-modal-button-color"
  | "sm:border-modal-button-color"
  | "xs:border-modal-button-color"
  | "group-hover:border-modal-button-color"
  | "focus-within:border-modal-button-color"
  | "hover:border-modal-button-color"
  | "focus:border-modal-button-color"
  | "checked:border-modal-button-color"
  | "checked:border-modal-button-color"
  | "2xl:border-opacity-0"
  | "1xl:border-opacity-0"
  | "xl:border-opacity-0"
  | "lg:border-opacity-0"
  | "md:border-opacity-0"
  | "sm:border-opacity-0"
  | "xs:border-opacity-0"
  | "group-hover:border-opacity-0"
  | "focus-within:border-opacity-0"
  | "hover:border-opacity-0"
  | "focus:border-opacity-0"
  | "2xl:border-opacity-5"
  | "1xl:border-opacity-5"
  | "xl:border-opacity-5"
  | "lg:border-opacity-5"
  | "md:border-opacity-5"
  | "sm:border-opacity-5"
  | "xs:border-opacity-5"
  | "group-hover:border-opacity-5"
  | "focus-within:border-opacity-5"
  | "hover:border-opacity-5"
  | "focus:border-opacity-5"
  | "2xl:border-opacity-10"
  | "1xl:border-opacity-10"
  | "xl:border-opacity-10"
  | "lg:border-opacity-10"
  | "md:border-opacity-10"
  | "sm:border-opacity-10"
  | "xs:border-opacity-10"
  | "group-hover:border-opacity-10"
  | "focus-within:border-opacity-10"
  | "hover:border-opacity-10"
  | "focus:border-opacity-10"
  | "2xl:border-opacity-20"
  | "1xl:border-opacity-20"
  | "xl:border-opacity-20"
  | "lg:border-opacity-20"
  | "md:border-opacity-20"
  | "sm:border-opacity-20"
  | "xs:border-opacity-20"
  | "group-hover:border-opacity-20"
  | "focus-within:border-opacity-20"
  | "hover:border-opacity-20"
  | "focus:border-opacity-20"
  | "2xl:border-opacity-25"
  | "1xl:border-opacity-25"
  | "xl:border-opacity-25"
  | "lg:border-opacity-25"
  | "md:border-opacity-25"
  | "sm:border-opacity-25"
  | "xs:border-opacity-25"
  | "group-hover:border-opacity-25"
  | "focus-within:border-opacity-25"
  | "hover:border-opacity-25"
  | "focus:border-opacity-25"
  | "2xl:border-opacity-30"
  | "1xl:border-opacity-30"
  | "xl:border-opacity-30"
  | "lg:border-opacity-30"
  | "md:border-opacity-30"
  | "sm:border-opacity-30"
  | "xs:border-opacity-30"
  | "group-hover:border-opacity-30"
  | "focus-within:border-opacity-30"
  | "hover:border-opacity-30"
  | "focus:border-opacity-30"
  | "2xl:border-opacity-40"
  | "1xl:border-opacity-40"
  | "xl:border-opacity-40"
  | "lg:border-opacity-40"
  | "md:border-opacity-40"
  | "sm:border-opacity-40"
  | "xs:border-opacity-40"
  | "group-hover:border-opacity-40"
  | "focus-within:border-opacity-40"
  | "hover:border-opacity-40"
  | "focus:border-opacity-40"
  | "2xl:border-opacity-50"
  | "1xl:border-opacity-50"
  | "xl:border-opacity-50"
  | "lg:border-opacity-50"
  | "md:border-opacity-50"
  | "sm:border-opacity-50"
  | "xs:border-opacity-50"
  | "group-hover:border-opacity-50"
  | "focus-within:border-opacity-50"
  | "hover:border-opacity-50"
  | "focus:border-opacity-50"
  | "2xl:border-opacity-60"
  | "1xl:border-opacity-60"
  | "xl:border-opacity-60"
  | "lg:border-opacity-60"
  | "md:border-opacity-60"
  | "sm:border-opacity-60"
  | "xs:border-opacity-60"
  | "group-hover:border-opacity-60"
  | "focus-within:border-opacity-60"
  | "hover:border-opacity-60"
  | "focus:border-opacity-60"
  | "2xl:border-opacity-70"
  | "1xl:border-opacity-70"
  | "xl:border-opacity-70"
  | "lg:border-opacity-70"
  | "md:border-opacity-70"
  | "sm:border-opacity-70"
  | "xs:border-opacity-70"
  | "group-hover:border-opacity-70"
  | "focus-within:border-opacity-70"
  | "hover:border-opacity-70"
  | "focus:border-opacity-70"
  | "2xl:border-opacity-75"
  | "1xl:border-opacity-75"
  | "xl:border-opacity-75"
  | "lg:border-opacity-75"
  | "md:border-opacity-75"
  | "sm:border-opacity-75"
  | "xs:border-opacity-75"
  | "group-hover:border-opacity-75"
  | "focus-within:border-opacity-75"
  | "hover:border-opacity-75"
  | "focus:border-opacity-75"
  | "2xl:border-opacity-80"
  | "1xl:border-opacity-80"
  | "xl:border-opacity-80"
  | "lg:border-opacity-80"
  | "md:border-opacity-80"
  | "sm:border-opacity-80"
  | "xs:border-opacity-80"
  | "group-hover:border-opacity-80"
  | "focus-within:border-opacity-80"
  | "hover:border-opacity-80"
  | "focus:border-opacity-80"
  | "2xl:border-opacity-90"
  | "1xl:border-opacity-90"
  | "xl:border-opacity-90"
  | "lg:border-opacity-90"
  | "md:border-opacity-90"
  | "sm:border-opacity-90"
  | "xs:border-opacity-90"
  | "group-hover:border-opacity-90"
  | "focus-within:border-opacity-90"
  | "hover:border-opacity-90"
  | "focus:border-opacity-90"
  | "2xl:border-opacity-95"
  | "1xl:border-opacity-95"
  | "xl:border-opacity-95"
  | "lg:border-opacity-95"
  | "md:border-opacity-95"
  | "sm:border-opacity-95"
  | "xs:border-opacity-95"
  | "group-hover:border-opacity-95"
  | "focus-within:border-opacity-95"
  | "hover:border-opacity-95"
  | "focus:border-opacity-95"
  | "2xl:border-opacity-100"
  | "1xl:border-opacity-100"
  | "xl:border-opacity-100"
  | "lg:border-opacity-100"
  | "md:border-opacity-100"
  | "sm:border-opacity-100"
  | "xs:border-opacity-100"
  | "group-hover:border-opacity-100"
  | "focus-within:border-opacity-100"
  | "hover:border-opacity-100"
  | "focus:border-opacity-100"
  | "2xl:rounded-t-none"
  | "1xl:rounded-t-none"
  | "xl:rounded-t-none"
  | "lg:rounded-t-none"
  | "md:rounded-t-none"
  | "sm:rounded-t-none"
  | "xs:rounded-t-none"
  | "2xl:rounded-r-none"
  | "1xl:rounded-r-none"
  | "xl:rounded-r-none"
  | "lg:rounded-r-none"
  | "md:rounded-r-none"
  | "sm:rounded-r-none"
  | "xs:rounded-r-none"
  | "2xl:rounded-b-none"
  | "1xl:rounded-b-none"
  | "xl:rounded-b-none"
  | "lg:rounded-b-none"
  | "md:rounded-b-none"
  | "sm:rounded-b-none"
  | "xs:rounded-b-none"
  | "2xl:rounded-l-none"
  | "1xl:rounded-l-none"
  | "xl:rounded-l-none"
  | "lg:rounded-l-none"
  | "md:rounded-l-none"
  | "sm:rounded-l-none"
  | "xs:rounded-l-none"
  | "2xl:rounded-tr-none"
  | "1xl:rounded-tr-none"
  | "xl:rounded-tr-none"
  | "lg:rounded-tr-none"
  | "md:rounded-tr-none"
  | "sm:rounded-tr-none"
  | "xs:rounded-tr-none"
  | "2xl:rounded-tl-none"
  | "1xl:rounded-tl-none"
  | "xl:rounded-tl-none"
  | "lg:rounded-tl-none"
  | "md:rounded-tl-none"
  | "sm:rounded-tl-none"
  | "xs:rounded-tl-none"
  | "2xl:rounded-br-none"
  | "1xl:rounded-br-none"
  | "xl:rounded-br-none"
  | "lg:rounded-br-none"
  | "md:rounded-br-none"
  | "sm:rounded-br-none"
  | "xs:rounded-br-none"
  | "2xl:rounded-bl-none"
  | "1xl:rounded-bl-none"
  | "xl:rounded-bl-none"
  | "lg:rounded-bl-none"
  | "md:rounded-bl-none"
  | "sm:rounded-bl-none"
  | "xs:rounded-bl-none"
  | "2xl:rounded-none"
  | "1xl:rounded-none"
  | "xl:rounded-none"
  | "lg:rounded-none"
  | "md:rounded-none"
  | "sm:rounded-none"
  | "xs:rounded-none"
  | "2xl:rounded-t-sm"
  | "1xl:rounded-t-sm"
  | "xl:rounded-t-sm"
  | "lg:rounded-t-sm"
  | "md:rounded-t-sm"
  | "sm:rounded-t-sm"
  | "xs:rounded-t-sm"
  | "2xl:rounded-r-sm"
  | "1xl:rounded-r-sm"
  | "xl:rounded-r-sm"
  | "lg:rounded-r-sm"
  | "md:rounded-r-sm"
  | "sm:rounded-r-sm"
  | "xs:rounded-r-sm"
  | "2xl:rounded-b-sm"
  | "1xl:rounded-b-sm"
  | "xl:rounded-b-sm"
  | "lg:rounded-b-sm"
  | "md:rounded-b-sm"
  | "sm:rounded-b-sm"
  | "xs:rounded-b-sm"
  | "2xl:rounded-l-sm"
  | "1xl:rounded-l-sm"
  | "xl:rounded-l-sm"
  | "lg:rounded-l-sm"
  | "md:rounded-l-sm"
  | "sm:rounded-l-sm"
  | "xs:rounded-l-sm"
  | "2xl:rounded-tr-sm"
  | "1xl:rounded-tr-sm"
  | "xl:rounded-tr-sm"
  | "lg:rounded-tr-sm"
  | "md:rounded-tr-sm"
  | "sm:rounded-tr-sm"
  | "xs:rounded-tr-sm"
  | "2xl:rounded-tl-sm"
  | "1xl:rounded-tl-sm"
  | "xl:rounded-tl-sm"
  | "lg:rounded-tl-sm"
  | "md:rounded-tl-sm"
  | "sm:rounded-tl-sm"
  | "xs:rounded-tl-sm"
  | "2xl:rounded-br-sm"
  | "1xl:rounded-br-sm"
  | "xl:rounded-br-sm"
  | "lg:rounded-br-sm"
  | "md:rounded-br-sm"
  | "sm:rounded-br-sm"
  | "xs:rounded-br-sm"
  | "2xl:rounded-bl-sm"
  | "1xl:rounded-bl-sm"
  | "xl:rounded-bl-sm"
  | "lg:rounded-bl-sm"
  | "md:rounded-bl-sm"
  | "sm:rounded-bl-sm"
  | "xs:rounded-bl-sm"
  | "2xl:rounded-sm"
  | "1xl:rounded-sm"
  | "xl:rounded-sm"
  | "lg:rounded-sm"
  | "md:rounded-sm"
  | "sm:rounded-sm"
  | "xs:rounded-sm"
  | "2xl:rounded-t"
  | "1xl:rounded-t"
  | "xl:rounded-t"
  | "lg:rounded-t"
  | "md:rounded-t"
  | "sm:rounded-t"
  | "xs:rounded-t"
  | "2xl:rounded-r"
  | "1xl:rounded-r"
  | "xl:rounded-r"
  | "lg:rounded-r"
  | "md:rounded-r"
  | "sm:rounded-r"
  | "xs:rounded-r"
  | "2xl:rounded-b"
  | "1xl:rounded-b"
  | "xl:rounded-b"
  | "lg:rounded-b"
  | "md:rounded-b"
  | "sm:rounded-b"
  | "xs:rounded-b"
  | "2xl:rounded-l"
  | "1xl:rounded-l"
  | "xl:rounded-l"
  | "lg:rounded-l"
  | "md:rounded-l"
  | "sm:rounded-l"
  | "xs:rounded-l"
  | "2xl:rounded-tr"
  | "1xl:rounded-tr"
  | "xl:rounded-tr"
  | "lg:rounded-tr"
  | "md:rounded-tr"
  | "sm:rounded-tr"
  | "xs:rounded-tr"
  | "2xl:rounded-tl"
  | "1xl:rounded-tl"
  | "xl:rounded-tl"
  | "lg:rounded-tl"
  | "md:rounded-tl"
  | "sm:rounded-tl"
  | "xs:rounded-tl"
  | "2xl:rounded-br"
  | "1xl:rounded-br"
  | "xl:rounded-br"
  | "lg:rounded-br"
  | "md:rounded-br"
  | "sm:rounded-br"
  | "xs:rounded-br"
  | "2xl:rounded-bl"
  | "1xl:rounded-bl"
  | "xl:rounded-bl"
  | "lg:rounded-bl"
  | "md:rounded-bl"
  | "sm:rounded-bl"
  | "xs:rounded-bl"
  | "2xl:rounded"
  | "1xl:rounded"
  | "xl:rounded"
  | "lg:rounded"
  | "md:rounded"
  | "sm:rounded"
  | "xs:rounded"
  | "2xl:rounded-t-md"
  | "1xl:rounded-t-md"
  | "xl:rounded-t-md"
  | "lg:rounded-t-md"
  | "md:rounded-t-md"
  | "sm:rounded-t-md"
  | "xs:rounded-t-md"
  | "2xl:rounded-r-md"
  | "1xl:rounded-r-md"
  | "xl:rounded-r-md"
  | "lg:rounded-r-md"
  | "md:rounded-r-md"
  | "sm:rounded-r-md"
  | "xs:rounded-r-md"
  | "2xl:rounded-b-md"
  | "1xl:rounded-b-md"
  | "xl:rounded-b-md"
  | "lg:rounded-b-md"
  | "md:rounded-b-md"
  | "sm:rounded-b-md"
  | "xs:rounded-b-md"
  | "2xl:rounded-l-md"
  | "1xl:rounded-l-md"
  | "xl:rounded-l-md"
  | "lg:rounded-l-md"
  | "md:rounded-l-md"
  | "sm:rounded-l-md"
  | "xs:rounded-l-md"
  | "2xl:rounded-tr-md"
  | "1xl:rounded-tr-md"
  | "xl:rounded-tr-md"
  | "lg:rounded-tr-md"
  | "md:rounded-tr-md"
  | "sm:rounded-tr-md"
  | "xs:rounded-tr-md"
  | "2xl:rounded-tl-md"
  | "1xl:rounded-tl-md"
  | "xl:rounded-tl-md"
  | "lg:rounded-tl-md"
  | "md:rounded-tl-md"
  | "sm:rounded-tl-md"
  | "xs:rounded-tl-md"
  | "2xl:rounded-br-md"
  | "1xl:rounded-br-md"
  | "xl:rounded-br-md"
  | "lg:rounded-br-md"
  | "md:rounded-br-md"
  | "sm:rounded-br-md"
  | "xs:rounded-br-md"
  | "2xl:rounded-bl-md"
  | "1xl:rounded-bl-md"
  | "xl:rounded-bl-md"
  | "lg:rounded-bl-md"
  | "md:rounded-bl-md"
  | "sm:rounded-bl-md"
  | "xs:rounded-bl-md"
  | "2xl:rounded-md"
  | "1xl:rounded-md"
  | "xl:rounded-md"
  | "lg:rounded-md"
  | "md:rounded-md"
  | "sm:rounded-md"
  | "xs:rounded-md"
  | "2xl:rounded-t-lg"
  | "1xl:rounded-t-lg"
  | "xl:rounded-t-lg"
  | "lg:rounded-t-lg"
  | "md:rounded-t-lg"
  | "sm:rounded-t-lg"
  | "xs:rounded-t-lg"
  | "2xl:rounded-r-lg"
  | "1xl:rounded-r-lg"
  | "xl:rounded-r-lg"
  | "lg:rounded-r-lg"
  | "md:rounded-r-lg"
  | "sm:rounded-r-lg"
  | "xs:rounded-r-lg"
  | "2xl:rounded-b-lg"
  | "1xl:rounded-b-lg"
  | "xl:rounded-b-lg"
  | "lg:rounded-b-lg"
  | "md:rounded-b-lg"
  | "sm:rounded-b-lg"
  | "xs:rounded-b-lg"
  | "2xl:rounded-l-lg"
  | "1xl:rounded-l-lg"
  | "xl:rounded-l-lg"
  | "lg:rounded-l-lg"
  | "md:rounded-l-lg"
  | "sm:rounded-l-lg"
  | "xs:rounded-l-lg"
  | "2xl:rounded-tr-lg"
  | "1xl:rounded-tr-lg"
  | "xl:rounded-tr-lg"
  | "lg:rounded-tr-lg"
  | "md:rounded-tr-lg"
  | "sm:rounded-tr-lg"
  | "xs:rounded-tr-lg"
  | "2xl:rounded-tl-lg"
  | "1xl:rounded-tl-lg"
  | "xl:rounded-tl-lg"
  | "lg:rounded-tl-lg"
  | "md:rounded-tl-lg"
  | "sm:rounded-tl-lg"
  | "xs:rounded-tl-lg"
  | "2xl:rounded-br-lg"
  | "1xl:rounded-br-lg"
  | "xl:rounded-br-lg"
  | "lg:rounded-br-lg"
  | "md:rounded-br-lg"
  | "sm:rounded-br-lg"
  | "xs:rounded-br-lg"
  | "2xl:rounded-bl-lg"
  | "1xl:rounded-bl-lg"
  | "xl:rounded-bl-lg"
  | "lg:rounded-bl-lg"
  | "md:rounded-bl-lg"
  | "sm:rounded-bl-lg"
  | "xs:rounded-bl-lg"
  | "2xl:rounded-lg"
  | "1xl:rounded-lg"
  | "xl:rounded-lg"
  | "lg:rounded-lg"
  | "md:rounded-lg"
  | "sm:rounded-lg"
  | "xs:rounded-lg"
  | "2xl:rounded-t-xl"
  | "1xl:rounded-t-xl"
  | "xl:rounded-t-xl"
  | "lg:rounded-t-xl"
  | "md:rounded-t-xl"
  | "sm:rounded-t-xl"
  | "xs:rounded-t-xl"
  | "2xl:rounded-r-xl"
  | "1xl:rounded-r-xl"
  | "xl:rounded-r-xl"
  | "lg:rounded-r-xl"
  | "md:rounded-r-xl"
  | "sm:rounded-r-xl"
  | "xs:rounded-r-xl"
  | "2xl:rounded-b-xl"
  | "1xl:rounded-b-xl"
  | "xl:rounded-b-xl"
  | "lg:rounded-b-xl"
  | "md:rounded-b-xl"
  | "sm:rounded-b-xl"
  | "xs:rounded-b-xl"
  | "2xl:rounded-l-xl"
  | "1xl:rounded-l-xl"
  | "xl:rounded-l-xl"
  | "lg:rounded-l-xl"
  | "md:rounded-l-xl"
  | "sm:rounded-l-xl"
  | "xs:rounded-l-xl"
  | "2xl:rounded-tr-xl"
  | "1xl:rounded-tr-xl"
  | "xl:rounded-tr-xl"
  | "lg:rounded-tr-xl"
  | "md:rounded-tr-xl"
  | "sm:rounded-tr-xl"
  | "xs:rounded-tr-xl"
  | "2xl:rounded-tl-xl"
  | "1xl:rounded-tl-xl"
  | "xl:rounded-tl-xl"
  | "lg:rounded-tl-xl"
  | "md:rounded-tl-xl"
  | "sm:rounded-tl-xl"
  | "xs:rounded-tl-xl"
  | "2xl:rounded-br-xl"
  | "1xl:rounded-br-xl"
  | "xl:rounded-br-xl"
  | "lg:rounded-br-xl"
  | "md:rounded-br-xl"
  | "sm:rounded-br-xl"
  | "xs:rounded-br-xl"
  | "2xl:rounded-bl-xl"
  | "1xl:rounded-bl-xl"
  | "xl:rounded-bl-xl"
  | "lg:rounded-bl-xl"
  | "md:rounded-bl-xl"
  | "sm:rounded-bl-xl"
  | "xs:rounded-bl-xl"
  | "2xl:rounded-xl"
  | "1xl:rounded-xl"
  | "xl:rounded-xl"
  | "lg:rounded-xl"
  | "md:rounded-xl"
  | "sm:rounded-xl"
  | "xs:rounded-xl"
  | "2xl:rounded-t-2xl"
  | "1xl:rounded-t-2xl"
  | "xl:rounded-t-2xl"
  | "lg:rounded-t-2xl"
  | "md:rounded-t-2xl"
  | "sm:rounded-t-2xl"
  | "xs:rounded-t-2xl"
  | "2xl:rounded-r-2xl"
  | "1xl:rounded-r-2xl"
  | "xl:rounded-r-2xl"
  | "lg:rounded-r-2xl"
  | "md:rounded-r-2xl"
  | "sm:rounded-r-2xl"
  | "xs:rounded-r-2xl"
  | "2xl:rounded-b-2xl"
  | "1xl:rounded-b-2xl"
  | "xl:rounded-b-2xl"
  | "lg:rounded-b-2xl"
  | "md:rounded-b-2xl"
  | "sm:rounded-b-2xl"
  | "xs:rounded-b-2xl"
  | "2xl:rounded-l-2xl"
  | "1xl:rounded-l-2xl"
  | "xl:rounded-l-2xl"
  | "lg:rounded-l-2xl"
  | "md:rounded-l-2xl"
  | "sm:rounded-l-2xl"
  | "xs:rounded-l-2xl"
  | "2xl:rounded-tr-2xl"
  | "1xl:rounded-tr-2xl"
  | "xl:rounded-tr-2xl"
  | "lg:rounded-tr-2xl"
  | "md:rounded-tr-2xl"
  | "sm:rounded-tr-2xl"
  | "xs:rounded-tr-2xl"
  | "2xl:rounded-tl-2xl"
  | "1xl:rounded-tl-2xl"
  | "xl:rounded-tl-2xl"
  | "lg:rounded-tl-2xl"
  | "md:rounded-tl-2xl"
  | "sm:rounded-tl-2xl"
  | "xs:rounded-tl-2xl"
  | "2xl:rounded-br-2xl"
  | "1xl:rounded-br-2xl"
  | "xl:rounded-br-2xl"
  | "lg:rounded-br-2xl"
  | "md:rounded-br-2xl"
  | "sm:rounded-br-2xl"
  | "xs:rounded-br-2xl"
  | "2xl:rounded-bl-2xl"
  | "1xl:rounded-bl-2xl"
  | "xl:rounded-bl-2xl"
  | "lg:rounded-bl-2xl"
  | "md:rounded-bl-2xl"
  | "sm:rounded-bl-2xl"
  | "xs:rounded-bl-2xl"
  | "2xl:rounded-2xl"
  | "1xl:rounded-2xl"
  | "xl:rounded-2xl"
  | "lg:rounded-2xl"
  | "md:rounded-2xl"
  | "sm:rounded-2xl"
  | "xs:rounded-2xl"
  | "2xl:rounded-t-3xl"
  | "1xl:rounded-t-3xl"
  | "xl:rounded-t-3xl"
  | "lg:rounded-t-3xl"
  | "md:rounded-t-3xl"
  | "sm:rounded-t-3xl"
  | "xs:rounded-t-3xl"
  | "2xl:rounded-r-3xl"
  | "1xl:rounded-r-3xl"
  | "xl:rounded-r-3xl"
  | "lg:rounded-r-3xl"
  | "md:rounded-r-3xl"
  | "sm:rounded-r-3xl"
  | "xs:rounded-r-3xl"
  | "2xl:rounded-b-3xl"
  | "1xl:rounded-b-3xl"
  | "xl:rounded-b-3xl"
  | "lg:rounded-b-3xl"
  | "md:rounded-b-3xl"
  | "sm:rounded-b-3xl"
  | "xs:rounded-b-3xl"
  | "2xl:rounded-l-3xl"
  | "1xl:rounded-l-3xl"
  | "xl:rounded-l-3xl"
  | "lg:rounded-l-3xl"
  | "md:rounded-l-3xl"
  | "sm:rounded-l-3xl"
  | "xs:rounded-l-3xl"
  | "2xl:rounded-tr-3xl"
  | "1xl:rounded-tr-3xl"
  | "xl:rounded-tr-3xl"
  | "lg:rounded-tr-3xl"
  | "md:rounded-tr-3xl"
  | "sm:rounded-tr-3xl"
  | "xs:rounded-tr-3xl"
  | "2xl:rounded-tl-3xl"
  | "1xl:rounded-tl-3xl"
  | "xl:rounded-tl-3xl"
  | "lg:rounded-tl-3xl"
  | "md:rounded-tl-3xl"
  | "sm:rounded-tl-3xl"
  | "xs:rounded-tl-3xl"
  | "2xl:rounded-br-3xl"
  | "1xl:rounded-br-3xl"
  | "xl:rounded-br-3xl"
  | "lg:rounded-br-3xl"
  | "md:rounded-br-3xl"
  | "sm:rounded-br-3xl"
  | "xs:rounded-br-3xl"
  | "2xl:rounded-bl-3xl"
  | "1xl:rounded-bl-3xl"
  | "xl:rounded-bl-3xl"
  | "lg:rounded-bl-3xl"
  | "md:rounded-bl-3xl"
  | "sm:rounded-bl-3xl"
  | "xs:rounded-bl-3xl"
  | "2xl:rounded-3xl"
  | "1xl:rounded-3xl"
  | "xl:rounded-3xl"
  | "lg:rounded-3xl"
  | "md:rounded-3xl"
  | "sm:rounded-3xl"
  | "xs:rounded-3xl"
  | "2xl:rounded-t-full"
  | "1xl:rounded-t-full"
  | "xl:rounded-t-full"
  | "lg:rounded-t-full"
  | "md:rounded-t-full"
  | "sm:rounded-t-full"
  | "xs:rounded-t-full"
  | "2xl:rounded-r-full"
  | "1xl:rounded-r-full"
  | "xl:rounded-r-full"
  | "lg:rounded-r-full"
  | "md:rounded-r-full"
  | "sm:rounded-r-full"
  | "xs:rounded-r-full"
  | "2xl:rounded-b-full"
  | "1xl:rounded-b-full"
  | "xl:rounded-b-full"
  | "lg:rounded-b-full"
  | "md:rounded-b-full"
  | "sm:rounded-b-full"
  | "xs:rounded-b-full"
  | "2xl:rounded-l-full"
  | "1xl:rounded-l-full"
  | "xl:rounded-l-full"
  | "lg:rounded-l-full"
  | "md:rounded-l-full"
  | "sm:rounded-l-full"
  | "xs:rounded-l-full"
  | "2xl:rounded-tr-full"
  | "1xl:rounded-tr-full"
  | "xl:rounded-tr-full"
  | "lg:rounded-tr-full"
  | "md:rounded-tr-full"
  | "sm:rounded-tr-full"
  | "xs:rounded-tr-full"
  | "2xl:rounded-tl-full"
  | "1xl:rounded-tl-full"
  | "xl:rounded-tl-full"
  | "lg:rounded-tl-full"
  | "md:rounded-tl-full"
  | "sm:rounded-tl-full"
  | "xs:rounded-tl-full"
  | "2xl:rounded-br-full"
  | "1xl:rounded-br-full"
  | "xl:rounded-br-full"
  | "lg:rounded-br-full"
  | "md:rounded-br-full"
  | "sm:rounded-br-full"
  | "xs:rounded-br-full"
  | "2xl:rounded-bl-full"
  | "1xl:rounded-bl-full"
  | "xl:rounded-bl-full"
  | "lg:rounded-bl-full"
  | "md:rounded-bl-full"
  | "sm:rounded-bl-full"
  | "xs:rounded-bl-full"
  | "2xl:rounded-full"
  | "1xl:rounded-full"
  | "xl:rounded-full"
  | "lg:rounded-full"
  | "md:rounded-full"
  | "sm:rounded-full"
  | "xs:rounded-full"
  | "2xl:border-solid"
  | "1xl:border-solid"
  | "xl:border-solid"
  | "lg:border-solid"
  | "md:border-solid"
  | "sm:border-solid"
  | "xs:border-solid"
  | "2xl:border-dashed"
  | "1xl:border-dashed"
  | "xl:border-dashed"
  | "lg:border-dashed"
  | "md:border-dashed"
  | "sm:border-dashed"
  | "xs:border-dashed"
  | "2xl:border-dotted"
  | "1xl:border-dotted"
  | "xl:border-dotted"
  | "lg:border-dotted"
  | "md:border-dotted"
  | "sm:border-dotted"
  | "xs:border-dotted"
  | "2xl:border-double"
  | "1xl:border-double"
  | "xl:border-double"
  | "lg:border-double"
  | "md:border-double"
  | "sm:border-double"
  | "xs:border-double"
  | "2xl:border-none"
  | "1xl:border-none"
  | "xl:border-none"
  | "lg:border-none"
  | "md:border-none"
  | "sm:border-none"
  | "xs:border-none"
  | "2xl:border-t-0"
  | "1xl:border-t-0"
  | "xl:border-t-0"
  | "lg:border-t-0"
  | "md:border-t-0"
  | "sm:border-t-0"
  | "xs:border-t-0"
  | "2xl:border-r-0"
  | "1xl:border-r-0"
  | "xl:border-r-0"
  | "lg:border-r-0"
  | "md:border-r-0"
  | "sm:border-r-0"
  | "xs:border-r-0"
  | "2xl:border-b-0"
  | "1xl:border-b-0"
  | "xl:border-b-0"
  | "lg:border-b-0"
  | "md:border-b-0"
  | "sm:border-b-0"
  | "xs:border-b-0"
  | "2xl:border-l-0"
  | "1xl:border-l-0"
  | "xl:border-l-0"
  | "lg:border-l-0"
  | "md:border-l-0"
  | "sm:border-l-0"
  | "xs:border-l-0"
  | "2xl:border-0"
  | "1xl:border-0"
  | "xl:border-0"
  | "lg:border-0"
  | "md:border-0"
  | "sm:border-0"
  | "xs:border-0"
  | "2xl:border-t-2"
  | "1xl:border-t-2"
  | "xl:border-t-2"
  | "lg:border-t-2"
  | "md:border-t-2"
  | "sm:border-t-2"
  | "xs:border-t-2"
  | "2xl:border-r-2"
  | "1xl:border-r-2"
  | "xl:border-r-2"
  | "lg:border-r-2"
  | "md:border-r-2"
  | "sm:border-r-2"
  | "xs:border-r-2"
  | "2xl:border-b-2"
  | "1xl:border-b-2"
  | "xl:border-b-2"
  | "lg:border-b-2"
  | "md:border-b-2"
  | "sm:border-b-2"
  | "xs:border-b-2"
  | "2xl:border-l-2"
  | "1xl:border-l-2"
  | "xl:border-l-2"
  | "lg:border-l-2"
  | "md:border-l-2"
  | "sm:border-l-2"
  | "xs:border-l-2"
  | "2xl:border-2"
  | "1xl:border-2"
  | "xl:border-2"
  | "lg:border-2"
  | "md:border-2"
  | "sm:border-2"
  | "xs:border-2"
  | "2xl:border-t-4"
  | "1xl:border-t-4"
  | "xl:border-t-4"
  | "lg:border-t-4"
  | "md:border-t-4"
  | "sm:border-t-4"
  | "xs:border-t-4"
  | "2xl:border-r-4"
  | "1xl:border-r-4"
  | "xl:border-r-4"
  | "lg:border-r-4"
  | "md:border-r-4"
  | "sm:border-r-4"
  | "xs:border-r-4"
  | "2xl:border-b-4"
  | "1xl:border-b-4"
  | "xl:border-b-4"
  | "lg:border-b-4"
  | "md:border-b-4"
  | "sm:border-b-4"
  | "xs:border-b-4"
  | "2xl:border-l-4"
  | "1xl:border-l-4"
  | "xl:border-l-4"
  | "lg:border-l-4"
  | "md:border-l-4"
  | "sm:border-l-4"
  | "xs:border-l-4"
  | "2xl:border-4"
  | "1xl:border-4"
  | "xl:border-4"
  | "lg:border-4"
  | "md:border-4"
  | "sm:border-4"
  | "xs:border-4"
  | "2xl:border-t-8"
  | "1xl:border-t-8"
  | "xl:border-t-8"
  | "lg:border-t-8"
  | "md:border-t-8"
  | "sm:border-t-8"
  | "xs:border-t-8"
  | "2xl:border-r-8"
  | "1xl:border-r-8"
  | "xl:border-r-8"
  | "lg:border-r-8"
  | "md:border-r-8"
  | "sm:border-r-8"
  | "xs:border-r-8"
  | "2xl:border-b-8"
  | "1xl:border-b-8"
  | "xl:border-b-8"
  | "lg:border-b-8"
  | "md:border-b-8"
  | "sm:border-b-8"
  | "xs:border-b-8"
  | "2xl:border-l-8"
  | "1xl:border-l-8"
  | "xl:border-l-8"
  | "lg:border-l-8"
  | "md:border-l-8"
  | "sm:border-l-8"
  | "xs:border-l-8"
  | "2xl:border-8"
  | "1xl:border-8"
  | "xl:border-8"
  | "lg:border-8"
  | "md:border-8"
  | "sm:border-8"
  | "xs:border-8"
  | "2xl:border-t"
  | "1xl:border-t"
  | "xl:border-t"
  | "lg:border-t"
  | "md:border-t"
  | "sm:border-t"
  | "xs:border-t"
  | "2xl:border-r"
  | "1xl:border-r"
  | "xl:border-r"
  | "lg:border-r"
  | "md:border-r"
  | "sm:border-r"
  | "xs:border-r"
  | "2xl:border-b"
  | "1xl:border-b"
  | "xl:border-b"
  | "lg:border-b"
  | "md:border-b"
  | "sm:border-b"
  | "xs:border-b"
  | "2xl:border-l"
  | "1xl:border-l"
  | "xl:border-l"
  | "lg:border-l"
  | "md:border-l"
  | "sm:border-l"
  | "xs:border-l"
  | "2xl:border"
  | "1xl:border"
  | "xl:border"
  | "lg:border"
  | "md:border"
  | "sm:border"
  | "xs:border"
  | "2xl:decoration-slice"
  | "1xl:decoration-slice"
  | "xl:decoration-slice"
  | "lg:decoration-slice"
  | "md:decoration-slice"
  | "sm:decoration-slice"
  | "xs:decoration-slice"
  | "2xl:decoration-clone"
  | "1xl:decoration-clone"
  | "xl:decoration-clone"
  | "lg:decoration-clone"
  | "md:decoration-clone"
  | "sm:decoration-clone"
  | "xs:decoration-clone"
  | "2xl:shadow-sm"
  | "1xl:shadow-sm"
  | "xl:shadow-sm"
  | "lg:shadow-sm"
  | "md:shadow-sm"
  | "sm:shadow-sm"
  | "xs:shadow-sm"
  | "group-hover:shadow-sm"
  | "focus-within:shadow-sm"
  | "hover:shadow-sm"
  | "focus:shadow-sm"
  | "2xl:shadow"
  | "1xl:shadow"
  | "xl:shadow"
  | "lg:shadow"
  | "md:shadow"
  | "sm:shadow"
  | "xs:shadow"
  | "group-hover:shadow"
  | "focus-within:shadow"
  | "hover:shadow"
  | "focus:shadow"
  | "2xl:shadow-md"
  | "1xl:shadow-md"
  | "xl:shadow-md"
  | "lg:shadow-md"
  | "md:shadow-md"
  | "sm:shadow-md"
  | "xs:shadow-md"
  | "group-hover:shadow-md"
  | "focus-within:shadow-md"
  | "hover:shadow-md"
  | "focus:shadow-md"
  | "2xl:shadow-lg"
  | "1xl:shadow-lg"
  | "xl:shadow-lg"
  | "lg:shadow-lg"
  | "md:shadow-lg"
  | "sm:shadow-lg"
  | "xs:shadow-lg"
  | "group-hover:shadow-lg"
  | "focus-within:shadow-lg"
  | "hover:shadow-lg"
  | "focus:shadow-lg"
  | "2xl:shadow-xl"
  | "1xl:shadow-xl"
  | "xl:shadow-xl"
  | "lg:shadow-xl"
  | "md:shadow-xl"
  | "sm:shadow-xl"
  | "xs:shadow-xl"
  | "group-hover:shadow-xl"
  | "focus-within:shadow-xl"
  | "hover:shadow-xl"
  | "focus:shadow-xl"
  | "2xl:shadow-2xl"
  | "1xl:shadow-2xl"
  | "xl:shadow-2xl"
  | "lg:shadow-2xl"
  | "md:shadow-2xl"
  | "sm:shadow-2xl"
  | "xs:shadow-2xl"
  | "group-hover:shadow-2xl"
  | "focus-within:shadow-2xl"
  | "hover:shadow-2xl"
  | "focus:shadow-2xl"
  | "2xl:shadow-inner"
  | "1xl:shadow-inner"
  | "xl:shadow-inner"
  | "lg:shadow-inner"
  | "md:shadow-inner"
  | "sm:shadow-inner"
  | "xs:shadow-inner"
  | "group-hover:shadow-inner"
  | "focus-within:shadow-inner"
  | "hover:shadow-inner"
  | "focus:shadow-inner"
  | "2xl:shadow-none"
  | "1xl:shadow-none"
  | "xl:shadow-none"
  | "lg:shadow-none"
  | "md:shadow-none"
  | "sm:shadow-none"
  | "xs:shadow-none"
  | "group-hover:shadow-none"
  | "focus-within:shadow-none"
  | "hover:shadow-none"
  | "focus:shadow-none"
  | "2xl:box-border"
  | "1xl:box-border"
  | "xl:box-border"
  | "lg:box-border"
  | "md:box-border"
  | "sm:box-border"
  | "xs:box-border"
  | "2xl:box-content"
  | "1xl:box-content"
  | "xl:box-content"
  | "lg:box-content"
  | "md:box-content"
  | "sm:box-content"
  | "xs:box-content"
  | "2xl:brightness-0"
  | "1xl:brightness-0"
  | "xl:brightness-0"
  | "lg:brightness-0"
  | "md:brightness-0"
  | "sm:brightness-0"
  | "xs:brightness-0"
  | "2xl:brightness-50"
  | "1xl:brightness-50"
  | "xl:brightness-50"
  | "lg:brightness-50"
  | "md:brightness-50"
  | "sm:brightness-50"
  | "xs:brightness-50"
  | "2xl:brightness-75"
  | "1xl:brightness-75"
  | "xl:brightness-75"
  | "lg:brightness-75"
  | "md:brightness-75"
  | "sm:brightness-75"
  | "xs:brightness-75"
  | "2xl:brightness-90"
  | "1xl:brightness-90"
  | "xl:brightness-90"
  | "lg:brightness-90"
  | "md:brightness-90"
  | "sm:brightness-90"
  | "xs:brightness-90"
  | "2xl:brightness-95"
  | "1xl:brightness-95"
  | "xl:brightness-95"
  | "lg:brightness-95"
  | "md:brightness-95"
  | "sm:brightness-95"
  | "xs:brightness-95"
  | "2xl:brightness-100"
  | "1xl:brightness-100"
  | "xl:brightness-100"
  | "lg:brightness-100"
  | "md:brightness-100"
  | "sm:brightness-100"
  | "xs:brightness-100"
  | "2xl:brightness-105"
  | "1xl:brightness-105"
  | "xl:brightness-105"
  | "lg:brightness-105"
  | "md:brightness-105"
  | "sm:brightness-105"
  | "xs:brightness-105"
  | "2xl:brightness-110"
  | "1xl:brightness-110"
  | "xl:brightness-110"
  | "lg:brightness-110"
  | "md:brightness-110"
  | "sm:brightness-110"
  | "xs:brightness-110"
  | "2xl:brightness-125"
  | "1xl:brightness-125"
  | "xl:brightness-125"
  | "lg:brightness-125"
  | "md:brightness-125"
  | "sm:brightness-125"
  | "xs:brightness-125"
  | "2xl:brightness-150"
  | "1xl:brightness-150"
  | "xl:brightness-150"
  | "lg:brightness-150"
  | "md:brightness-150"
  | "sm:brightness-150"
  | "xs:brightness-150"
  | "2xl:brightness-200"
  | "1xl:brightness-200"
  | "xl:brightness-200"
  | "lg:brightness-200"
  | "md:brightness-200"
  | "sm:brightness-200"
  | "xs:brightness-200"
  | "2xl:clear-left"
  | "1xl:clear-left"
  | "xl:clear-left"
  | "lg:clear-left"
  | "md:clear-left"
  | "sm:clear-left"
  | "xs:clear-left"
  | "2xl:clear-right"
  | "1xl:clear-right"
  | "xl:clear-right"
  | "lg:clear-right"
  | "md:clear-right"
  | "sm:clear-right"
  | "xs:clear-right"
  | "2xl:clear-both"
  | "1xl:clear-both"
  | "xl:clear-both"
  | "lg:clear-both"
  | "md:clear-both"
  | "sm:clear-both"
  | "xs:clear-both"
  | "2xl:clear-none"
  | "1xl:clear-none"
  | "xl:clear-none"
  | "lg:clear-none"
  | "md:clear-none"
  | "sm:clear-none"
  | "xs:clear-none"
  | "2xl:container"
  | "1xl:container"
  | "xl:container"
  | "lg:container"
  | "md:container"
  | "sm:container"
  | "xs:container"
  | "2xl:contrast-0"
  | "1xl:contrast-0"
  | "xl:contrast-0"
  | "lg:contrast-0"
  | "md:contrast-0"
  | "sm:contrast-0"
  | "xs:contrast-0"
  | "2xl:contrast-50"
  | "1xl:contrast-50"
  | "xl:contrast-50"
  | "lg:contrast-50"
  | "md:contrast-50"
  | "sm:contrast-50"
  | "xs:contrast-50"
  | "2xl:contrast-75"
  | "1xl:contrast-75"
  | "xl:contrast-75"
  | "lg:contrast-75"
  | "md:contrast-75"
  | "sm:contrast-75"
  | "xs:contrast-75"
  | "2xl:contrast-100"
  | "1xl:contrast-100"
  | "xl:contrast-100"
  | "lg:contrast-100"
  | "md:contrast-100"
  | "sm:contrast-100"
  | "xs:contrast-100"
  | "2xl:contrast-125"
  | "1xl:contrast-125"
  | "xl:contrast-125"
  | "lg:contrast-125"
  | "md:contrast-125"
  | "sm:contrast-125"
  | "xs:contrast-125"
  | "2xl:contrast-150"
  | "1xl:contrast-150"
  | "xl:contrast-150"
  | "lg:contrast-150"
  | "md:contrast-150"
  | "sm:contrast-150"
  | "xs:contrast-150"
  | "2xl:contrast-200"
  | "1xl:contrast-200"
  | "xl:contrast-200"
  | "lg:contrast-200"
  | "md:contrast-200"
  | "sm:contrast-200"
  | "xs:contrast-200"
  | "2xl:cursor-auto"
  | "1xl:cursor-auto"
  | "xl:cursor-auto"
  | "lg:cursor-auto"
  | "md:cursor-auto"
  | "sm:cursor-auto"
  | "xs:cursor-auto"
  | "2xl:cursor-default"
  | "1xl:cursor-default"
  | "xl:cursor-default"
  | "lg:cursor-default"
  | "md:cursor-default"
  | "sm:cursor-default"
  | "xs:cursor-default"
  | "2xl:cursor-pointer"
  | "1xl:cursor-pointer"
  | "xl:cursor-pointer"
  | "lg:cursor-pointer"
  | "md:cursor-pointer"
  | "sm:cursor-pointer"
  | "xs:cursor-pointer"
  | "2xl:cursor-wait"
  | "1xl:cursor-wait"
  | "xl:cursor-wait"
  | "lg:cursor-wait"
  | "md:cursor-wait"
  | "sm:cursor-wait"
  | "xs:cursor-wait"
  | "2xl:cursor-text"
  | "1xl:cursor-text"
  | "xl:cursor-text"
  | "lg:cursor-text"
  | "md:cursor-text"
  | "sm:cursor-text"
  | "xs:cursor-text"
  | "2xl:cursor-move"
  | "1xl:cursor-move"
  | "xl:cursor-move"
  | "lg:cursor-move"
  | "md:cursor-move"
  | "sm:cursor-move"
  | "xs:cursor-move"
  | "2xl:cursor-help"
  | "1xl:cursor-help"
  | "xl:cursor-help"
  | "lg:cursor-help"
  | "md:cursor-help"
  | "sm:cursor-help"
  | "xs:cursor-help"
  | "2xl:cursor-not-allowed"
  | "1xl:cursor-not-allowed"
  | "xl:cursor-not-allowed"
  | "lg:cursor-not-allowed"
  | "md:cursor-not-allowed"
  | "sm:cursor-not-allowed"
  | "xs:cursor-not-allowed"
  | "2xl:block"
  | "1xl:block"
  | "xl:block"
  | "lg:block"
  | "md:block"
  | "sm:block"
  | "xs:block"
  | "2xl:inline-block"
  | "1xl:inline-block"
  | "xl:inline-block"
  | "lg:inline-block"
  | "md:inline-block"
  | "sm:inline-block"
  | "xs:inline-block"
  | "2xl:inline"
  | "1xl:inline"
  | "xl:inline"
  | "lg:inline"
  | "md:inline"
  | "sm:inline"
  | "xs:inline"
  | "2xl:flex"
  | "1xl:flex"
  | "xl:flex"
  | "lg:flex"
  | "md:flex"
  | "sm:flex"
  | "xs:flex"
  | "2xl:inline-flex"
  | "1xl:inline-flex"
  | "xl:inline-flex"
  | "lg:inline-flex"
  | "md:inline-flex"
  | "sm:inline-flex"
  | "xs:inline-flex"
  | "2xl:grid"
  | "1xl:grid"
  | "xl:grid"
  | "lg:grid"
  | "md:grid"
  | "sm:grid"
  | "xs:grid"
  | "2xl:inline-grid"
  | "1xl:inline-grid"
  | "xl:inline-grid"
  | "lg:inline-grid"
  | "md:inline-grid"
  | "sm:inline-grid"
  | "xs:inline-grid"
  | "2xl:table"
  | "1xl:table"
  | "xl:table"
  | "lg:table"
  | "md:table"
  | "sm:table"
  | "xs:table"
  | "2xl:inline-table"
  | "1xl:inline-table"
  | "xl:inline-table"
  | "lg:inline-table"
  | "md:inline-table"
  | "sm:inline-table"
  | "xs:inline-table"
  | "2xl:table-row"
  | "1xl:table-row"
  | "xl:table-row"
  | "lg:table-row"
  | "md:table-row"
  | "sm:table-row"
  | "xs:table-row"
  | "2xl:table-cell"
  | "1xl:table-cell"
  | "xl:table-cell"
  | "lg:table-cell"
  | "md:table-cell"
  | "sm:table-cell"
  | "xs:table-cell"
  | "2xl:contents"
  | "1xl:contents"
  | "xl:contents"
  | "lg:contents"
  | "md:contents"
  | "sm:contents"
  | "xs:contents"
  | "2xl:list-item"
  | "1xl:list-item"
  | "xl:list-item"
  | "lg:list-item"
  | "md:list-item"
  | "sm:list-item"
  | "xs:list-item"
  | "2xl:hidden"
  | "1xl:hidden"
  | "xl:hidden"
  | "lg:hidden"
  | "md:hidden"
  | "sm:hidden"
  | "xs:hidden"
  | "2xl:divide-transparent"
  | "1xl:divide-transparent"
  | "xl:divide-transparent"
  | "lg:divide-transparent"
  | "md:divide-transparent"
  | "sm:divide-transparent"
  | "xs:divide-transparent"
  | "2xl:divide-current"
  | "1xl:divide-current"
  | "xl:divide-current"
  | "lg:divide-current"
  | "md:divide-current"
  | "sm:divide-current"
  | "xs:divide-current"
  | "2xl:divide-black"
  | "1xl:divide-black"
  | "xl:divide-black"
  | "lg:divide-black"
  | "md:divide-black"
  | "sm:divide-black"
  | "xs:divide-black"
  | "2xl:divide-white"
  | "1xl:divide-white"
  | "xl:divide-white"
  | "lg:divide-white"
  | "md:divide-white"
  | "sm:divide-white"
  | "xs:divide-white"
  | "2xl:divide-gray-50"
  | "1xl:divide-gray-50"
  | "xl:divide-gray-50"
  | "lg:divide-gray-50"
  | "md:divide-gray-50"
  | "sm:divide-gray-50"
  | "xs:divide-gray-50"
  | "2xl:divide-gray-100"
  | "1xl:divide-gray-100"
  | "xl:divide-gray-100"
  | "lg:divide-gray-100"
  | "md:divide-gray-100"
  | "sm:divide-gray-100"
  | "xs:divide-gray-100"
  | "2xl:divide-gray-200"
  | "1xl:divide-gray-200"
  | "xl:divide-gray-200"
  | "lg:divide-gray-200"
  | "md:divide-gray-200"
  | "sm:divide-gray-200"
  | "xs:divide-gray-200"
  | "2xl:divide-gray-300"
  | "1xl:divide-gray-300"
  | "xl:divide-gray-300"
  | "lg:divide-gray-300"
  | "md:divide-gray-300"
  | "sm:divide-gray-300"
  | "xs:divide-gray-300"
  | "2xl:divide-gray-400"
  | "1xl:divide-gray-400"
  | "xl:divide-gray-400"
  | "lg:divide-gray-400"
  | "md:divide-gray-400"
  | "sm:divide-gray-400"
  | "xs:divide-gray-400"
  | "2xl:divide-gray-500"
  | "1xl:divide-gray-500"
  | "xl:divide-gray-500"
  | "lg:divide-gray-500"
  | "md:divide-gray-500"
  | "sm:divide-gray-500"
  | "xs:divide-gray-500"
  | "2xl:divide-gray-600"
  | "1xl:divide-gray-600"
  | "xl:divide-gray-600"
  | "lg:divide-gray-600"
  | "md:divide-gray-600"
  | "sm:divide-gray-600"
  | "xs:divide-gray-600"
  | "2xl:divide-gray-700"
  | "1xl:divide-gray-700"
  | "xl:divide-gray-700"
  | "lg:divide-gray-700"
  | "md:divide-gray-700"
  | "sm:divide-gray-700"
  | "xs:divide-gray-700"
  | "2xl:divide-gray-800"
  | "1xl:divide-gray-800"
  | "xl:divide-gray-800"
  | "lg:divide-gray-800"
  | "md:divide-gray-800"
  | "sm:divide-gray-800"
  | "xs:divide-gray-800"
  | "2xl:divide-gray-900"
  | "1xl:divide-gray-900"
  | "xl:divide-gray-900"
  | "lg:divide-gray-900"
  | "md:divide-gray-900"
  | "sm:divide-gray-900"
  | "xs:divide-gray-900"
  | "2xl:divide-red-50"
  | "1xl:divide-red-50"
  | "xl:divide-red-50"
  | "lg:divide-red-50"
  | "md:divide-red-50"
  | "sm:divide-red-50"
  | "xs:divide-red-50"
  | "2xl:divide-red-100"
  | "1xl:divide-red-100"
  | "xl:divide-red-100"
  | "lg:divide-red-100"
  | "md:divide-red-100"
  | "sm:divide-red-100"
  | "xs:divide-red-100"
  | "2xl:divide-red-200"
  | "1xl:divide-red-200"
  | "xl:divide-red-200"
  | "lg:divide-red-200"
  | "md:divide-red-200"
  | "sm:divide-red-200"
  | "xs:divide-red-200"
  | "2xl:divide-red-300"
  | "1xl:divide-red-300"
  | "xl:divide-red-300"
  | "lg:divide-red-300"
  | "md:divide-red-300"
  | "sm:divide-red-300"
  | "xs:divide-red-300"
  | "2xl:divide-red-400"
  | "1xl:divide-red-400"
  | "xl:divide-red-400"
  | "lg:divide-red-400"
  | "md:divide-red-400"
  | "sm:divide-red-400"
  | "xs:divide-red-400"
  | "2xl:divide-red-500"
  | "1xl:divide-red-500"
  | "xl:divide-red-500"
  | "lg:divide-red-500"
  | "md:divide-red-500"
  | "sm:divide-red-500"
  | "xs:divide-red-500"
  | "2xl:divide-red-600"
  | "1xl:divide-red-600"
  | "xl:divide-red-600"
  | "lg:divide-red-600"
  | "md:divide-red-600"
  | "sm:divide-red-600"
  | "xs:divide-red-600"
  | "2xl:divide-red-700"
  | "1xl:divide-red-700"
  | "xl:divide-red-700"
  | "lg:divide-red-700"
  | "md:divide-red-700"
  | "sm:divide-red-700"
  | "xs:divide-red-700"
  | "2xl:divide-red-800"
  | "1xl:divide-red-800"
  | "xl:divide-red-800"
  | "lg:divide-red-800"
  | "md:divide-red-800"
  | "sm:divide-red-800"
  | "xs:divide-red-800"
  | "2xl:divide-red-900"
  | "1xl:divide-red-900"
  | "xl:divide-red-900"
  | "lg:divide-red-900"
  | "md:divide-red-900"
  | "sm:divide-red-900"
  | "xs:divide-red-900"
  | "2xl:divide-yellow-50"
  | "1xl:divide-yellow-50"
  | "xl:divide-yellow-50"
  | "lg:divide-yellow-50"
  | "md:divide-yellow-50"
  | "sm:divide-yellow-50"
  | "xs:divide-yellow-50"
  | "2xl:divide-yellow-100"
  | "1xl:divide-yellow-100"
  | "xl:divide-yellow-100"
  | "lg:divide-yellow-100"
  | "md:divide-yellow-100"
  | "sm:divide-yellow-100"
  | "xs:divide-yellow-100"
  | "2xl:divide-yellow-200"
  | "1xl:divide-yellow-200"
  | "xl:divide-yellow-200"
  | "lg:divide-yellow-200"
  | "md:divide-yellow-200"
  | "sm:divide-yellow-200"
  | "xs:divide-yellow-200"
  | "2xl:divide-yellow-300"
  | "1xl:divide-yellow-300"
  | "xl:divide-yellow-300"
  | "lg:divide-yellow-300"
  | "md:divide-yellow-300"
  | "sm:divide-yellow-300"
  | "xs:divide-yellow-300"
  | "2xl:divide-yellow-400"
  | "1xl:divide-yellow-400"
  | "xl:divide-yellow-400"
  | "lg:divide-yellow-400"
  | "md:divide-yellow-400"
  | "sm:divide-yellow-400"
  | "xs:divide-yellow-400"
  | "2xl:divide-yellow-500"
  | "1xl:divide-yellow-500"
  | "xl:divide-yellow-500"
  | "lg:divide-yellow-500"
  | "md:divide-yellow-500"
  | "sm:divide-yellow-500"
  | "xs:divide-yellow-500"
  | "2xl:divide-yellow-600"
  | "1xl:divide-yellow-600"
  | "xl:divide-yellow-600"
  | "lg:divide-yellow-600"
  | "md:divide-yellow-600"
  | "sm:divide-yellow-600"
  | "xs:divide-yellow-600"
  | "2xl:divide-yellow-700"
  | "1xl:divide-yellow-700"
  | "xl:divide-yellow-700"
  | "lg:divide-yellow-700"
  | "md:divide-yellow-700"
  | "sm:divide-yellow-700"
  | "xs:divide-yellow-700"
  | "2xl:divide-yellow-800"
  | "1xl:divide-yellow-800"
  | "xl:divide-yellow-800"
  | "lg:divide-yellow-800"
  | "md:divide-yellow-800"
  | "sm:divide-yellow-800"
  | "xs:divide-yellow-800"
  | "2xl:divide-yellow-900"
  | "1xl:divide-yellow-900"
  | "xl:divide-yellow-900"
  | "lg:divide-yellow-900"
  | "md:divide-yellow-900"
  | "sm:divide-yellow-900"
  | "xs:divide-yellow-900"
  | "2xl:divide-green-50"
  | "1xl:divide-green-50"
  | "xl:divide-green-50"
  | "lg:divide-green-50"
  | "md:divide-green-50"
  | "sm:divide-green-50"
  | "xs:divide-green-50"
  | "2xl:divide-green-100"
  | "1xl:divide-green-100"
  | "xl:divide-green-100"
  | "lg:divide-green-100"
  | "md:divide-green-100"
  | "sm:divide-green-100"
  | "xs:divide-green-100"
  | "2xl:divide-green-200"
  | "1xl:divide-green-200"
  | "xl:divide-green-200"
  | "lg:divide-green-200"
  | "md:divide-green-200"
  | "sm:divide-green-200"
  | "xs:divide-green-200"
  | "2xl:divide-green-300"
  | "1xl:divide-green-300"
  | "xl:divide-green-300"
  | "lg:divide-green-300"
  | "md:divide-green-300"
  | "sm:divide-green-300"
  | "xs:divide-green-300"
  | "2xl:divide-green-400"
  | "1xl:divide-green-400"
  | "xl:divide-green-400"
  | "lg:divide-green-400"
  | "md:divide-green-400"
  | "sm:divide-green-400"
  | "xs:divide-green-400"
  | "2xl:divide-green-500"
  | "1xl:divide-green-500"
  | "xl:divide-green-500"
  | "lg:divide-green-500"
  | "md:divide-green-500"
  | "sm:divide-green-500"
  | "xs:divide-green-500"
  | "2xl:divide-green-600"
  | "1xl:divide-green-600"
  | "xl:divide-green-600"
  | "lg:divide-green-600"
  | "md:divide-green-600"
  | "sm:divide-green-600"
  | "xs:divide-green-600"
  | "2xl:divide-green-700"
  | "1xl:divide-green-700"
  | "xl:divide-green-700"
  | "lg:divide-green-700"
  | "md:divide-green-700"
  | "sm:divide-green-700"
  | "xs:divide-green-700"
  | "2xl:divide-green-800"
  | "1xl:divide-green-800"
  | "xl:divide-green-800"
  | "lg:divide-green-800"
  | "md:divide-green-800"
  | "sm:divide-green-800"
  | "xs:divide-green-800"
  | "2xl:divide-green-900"
  | "1xl:divide-green-900"
  | "xl:divide-green-900"
  | "lg:divide-green-900"
  | "md:divide-green-900"
  | "sm:divide-green-900"
  | "xs:divide-green-900"
  | "2xl:divide-blue-50"
  | "1xl:divide-blue-50"
  | "xl:divide-blue-50"
  | "lg:divide-blue-50"
  | "md:divide-blue-50"
  | "sm:divide-blue-50"
  | "xs:divide-blue-50"
  | "2xl:divide-blue-100"
  | "1xl:divide-blue-100"
  | "xl:divide-blue-100"
  | "lg:divide-blue-100"
  | "md:divide-blue-100"
  | "sm:divide-blue-100"
  | "xs:divide-blue-100"
  | "2xl:divide-blue-200"
  | "1xl:divide-blue-200"
  | "xl:divide-blue-200"
  | "lg:divide-blue-200"
  | "md:divide-blue-200"
  | "sm:divide-blue-200"
  | "xs:divide-blue-200"
  | "2xl:divide-blue-300"
  | "1xl:divide-blue-300"
  | "xl:divide-blue-300"
  | "lg:divide-blue-300"
  | "md:divide-blue-300"
  | "sm:divide-blue-300"
  | "xs:divide-blue-300"
  | "2xl:divide-blue-400"
  | "1xl:divide-blue-400"
  | "xl:divide-blue-400"
  | "lg:divide-blue-400"
  | "md:divide-blue-400"
  | "sm:divide-blue-400"
  | "xs:divide-blue-400"
  | "2xl:divide-blue-500"
  | "1xl:divide-blue-500"
  | "xl:divide-blue-500"
  | "lg:divide-blue-500"
  | "md:divide-blue-500"
  | "sm:divide-blue-500"
  | "xs:divide-blue-500"
  | "2xl:divide-blue-600"
  | "1xl:divide-blue-600"
  | "xl:divide-blue-600"
  | "lg:divide-blue-600"
  | "md:divide-blue-600"
  | "sm:divide-blue-600"
  | "xs:divide-blue-600"
  | "2xl:divide-blue-700"
  | "1xl:divide-blue-700"
  | "xl:divide-blue-700"
  | "lg:divide-blue-700"
  | "md:divide-blue-700"
  | "sm:divide-blue-700"
  | "xs:divide-blue-700"
  | "2xl:divide-blue-800"
  | "1xl:divide-blue-800"
  | "xl:divide-blue-800"
  | "lg:divide-blue-800"
  | "md:divide-blue-800"
  | "sm:divide-blue-800"
  | "xs:divide-blue-800"
  | "2xl:divide-blue-900"
  | "1xl:divide-blue-900"
  | "xl:divide-blue-900"
  | "lg:divide-blue-900"
  | "md:divide-blue-900"
  | "sm:divide-blue-900"
  | "xs:divide-blue-900"
  | "2xl:divide-indigo-50"
  | "1xl:divide-indigo-50"
  | "xl:divide-indigo-50"
  | "lg:divide-indigo-50"
  | "md:divide-indigo-50"
  | "sm:divide-indigo-50"
  | "xs:divide-indigo-50"
  | "2xl:divide-indigo-100"
  | "1xl:divide-indigo-100"
  | "xl:divide-indigo-100"
  | "lg:divide-indigo-100"
  | "md:divide-indigo-100"
  | "sm:divide-indigo-100"
  | "xs:divide-indigo-100"
  | "2xl:divide-indigo-200"
  | "1xl:divide-indigo-200"
  | "xl:divide-indigo-200"
  | "lg:divide-indigo-200"
  | "md:divide-indigo-200"
  | "sm:divide-indigo-200"
  | "xs:divide-indigo-200"
  | "2xl:divide-indigo-300"
  | "1xl:divide-indigo-300"
  | "xl:divide-indigo-300"
  | "lg:divide-indigo-300"
  | "md:divide-indigo-300"
  | "sm:divide-indigo-300"
  | "xs:divide-indigo-300"
  | "2xl:divide-indigo-400"
  | "1xl:divide-indigo-400"
  | "xl:divide-indigo-400"
  | "lg:divide-indigo-400"
  | "md:divide-indigo-400"
  | "sm:divide-indigo-400"
  | "xs:divide-indigo-400"
  | "2xl:divide-indigo-500"
  | "1xl:divide-indigo-500"
  | "xl:divide-indigo-500"
  | "lg:divide-indigo-500"
  | "md:divide-indigo-500"
  | "sm:divide-indigo-500"
  | "xs:divide-indigo-500"
  | "2xl:divide-indigo-600"
  | "1xl:divide-indigo-600"
  | "xl:divide-indigo-600"
  | "lg:divide-indigo-600"
  | "md:divide-indigo-600"
  | "sm:divide-indigo-600"
  | "xs:divide-indigo-600"
  | "2xl:divide-indigo-700"
  | "1xl:divide-indigo-700"
  | "xl:divide-indigo-700"
  | "lg:divide-indigo-700"
  | "md:divide-indigo-700"
  | "sm:divide-indigo-700"
  | "xs:divide-indigo-700"
  | "2xl:divide-indigo-800"
  | "1xl:divide-indigo-800"
  | "xl:divide-indigo-800"
  | "lg:divide-indigo-800"
  | "md:divide-indigo-800"
  | "sm:divide-indigo-800"
  | "xs:divide-indigo-800"
  | "2xl:divide-indigo-900"
  | "1xl:divide-indigo-900"
  | "xl:divide-indigo-900"
  | "lg:divide-indigo-900"
  | "md:divide-indigo-900"
  | "sm:divide-indigo-900"
  | "xs:divide-indigo-900"
  | "2xl:divide-purple-50"
  | "1xl:divide-purple-50"
  | "xl:divide-purple-50"
  | "lg:divide-purple-50"
  | "md:divide-purple-50"
  | "sm:divide-purple-50"
  | "xs:divide-purple-50"
  | "2xl:divide-purple-100"
  | "1xl:divide-purple-100"
  | "xl:divide-purple-100"
  | "lg:divide-purple-100"
  | "md:divide-purple-100"
  | "sm:divide-purple-100"
  | "xs:divide-purple-100"
  | "2xl:divide-purple-200"
  | "1xl:divide-purple-200"
  | "xl:divide-purple-200"
  | "lg:divide-purple-200"
  | "md:divide-purple-200"
  | "sm:divide-purple-200"
  | "xs:divide-purple-200"
  | "2xl:divide-purple-300"
  | "1xl:divide-purple-300"
  | "xl:divide-purple-300"
  | "lg:divide-purple-300"
  | "md:divide-purple-300"
  | "sm:divide-purple-300"
  | "xs:divide-purple-300"
  | "2xl:divide-purple-400"
  | "1xl:divide-purple-400"
  | "xl:divide-purple-400"
  | "lg:divide-purple-400"
  | "md:divide-purple-400"
  | "sm:divide-purple-400"
  | "xs:divide-purple-400"
  | "2xl:divide-purple-500"
  | "1xl:divide-purple-500"
  | "xl:divide-purple-500"
  | "lg:divide-purple-500"
  | "md:divide-purple-500"
  | "sm:divide-purple-500"
  | "xs:divide-purple-500"
  | "2xl:divide-purple-600"
  | "1xl:divide-purple-600"
  | "xl:divide-purple-600"
  | "lg:divide-purple-600"
  | "md:divide-purple-600"
  | "sm:divide-purple-600"
  | "xs:divide-purple-600"
  | "2xl:divide-purple-700"
  | "1xl:divide-purple-700"
  | "xl:divide-purple-700"
  | "lg:divide-purple-700"
  | "md:divide-purple-700"
  | "sm:divide-purple-700"
  | "xs:divide-purple-700"
  | "2xl:divide-purple-800"
  | "1xl:divide-purple-800"
  | "xl:divide-purple-800"
  | "lg:divide-purple-800"
  | "md:divide-purple-800"
  | "sm:divide-purple-800"
  | "xs:divide-purple-800"
  | "2xl:divide-purple-900"
  | "1xl:divide-purple-900"
  | "xl:divide-purple-900"
  | "lg:divide-purple-900"
  | "md:divide-purple-900"
  | "sm:divide-purple-900"
  | "xs:divide-purple-900"
  | "2xl:divide-pink-50"
  | "1xl:divide-pink-50"
  | "xl:divide-pink-50"
  | "lg:divide-pink-50"
  | "md:divide-pink-50"
  | "sm:divide-pink-50"
  | "xs:divide-pink-50"
  | "2xl:divide-pink-100"
  | "1xl:divide-pink-100"
  | "xl:divide-pink-100"
  | "lg:divide-pink-100"
  | "md:divide-pink-100"
  | "sm:divide-pink-100"
  | "xs:divide-pink-100"
  | "2xl:divide-pink-200"
  | "1xl:divide-pink-200"
  | "xl:divide-pink-200"
  | "lg:divide-pink-200"
  | "md:divide-pink-200"
  | "sm:divide-pink-200"
  | "xs:divide-pink-200"
  | "2xl:divide-pink-300"
  | "1xl:divide-pink-300"
  | "xl:divide-pink-300"
  | "lg:divide-pink-300"
  | "md:divide-pink-300"
  | "sm:divide-pink-300"
  | "xs:divide-pink-300"
  | "2xl:divide-pink-400"
  | "1xl:divide-pink-400"
  | "xl:divide-pink-400"
  | "lg:divide-pink-400"
  | "md:divide-pink-400"
  | "sm:divide-pink-400"
  | "xs:divide-pink-400"
  | "2xl:divide-pink-500"
  | "1xl:divide-pink-500"
  | "xl:divide-pink-500"
  | "lg:divide-pink-500"
  | "md:divide-pink-500"
  | "sm:divide-pink-500"
  | "xs:divide-pink-500"
  | "2xl:divide-pink-600"
  | "1xl:divide-pink-600"
  | "xl:divide-pink-600"
  | "lg:divide-pink-600"
  | "md:divide-pink-600"
  | "sm:divide-pink-600"
  | "xs:divide-pink-600"
  | "2xl:divide-pink-700"
  | "1xl:divide-pink-700"
  | "xl:divide-pink-700"
  | "lg:divide-pink-700"
  | "md:divide-pink-700"
  | "sm:divide-pink-700"
  | "xs:divide-pink-700"
  | "2xl:divide-pink-800"
  | "1xl:divide-pink-800"
  | "xl:divide-pink-800"
  | "lg:divide-pink-800"
  | "md:divide-pink-800"
  | "sm:divide-pink-800"
  | "xs:divide-pink-800"
  | "2xl:divide-pink-900"
  | "1xl:divide-pink-900"
  | "xl:divide-pink-900"
  | "lg:divide-pink-900"
  | "md:divide-pink-900"
  | "sm:divide-pink-900"
  | "xs:divide-pink-900"
  | "2xl:divide-opacity-0"
  | "1xl:divide-opacity-0"
  | "xl:divide-opacity-0"
  | "lg:divide-opacity-0"
  | "md:divide-opacity-0"
  | "sm:divide-opacity-0"
  | "xs:divide-opacity-0"
  | "2xl:divide-opacity-5"
  | "1xl:divide-opacity-5"
  | "xl:divide-opacity-5"
  | "lg:divide-opacity-5"
  | "md:divide-opacity-5"
  | "sm:divide-opacity-5"
  | "xs:divide-opacity-5"
  | "2xl:divide-opacity-10"
  | "1xl:divide-opacity-10"
  | "xl:divide-opacity-10"
  | "lg:divide-opacity-10"
  | "md:divide-opacity-10"
  | "sm:divide-opacity-10"
  | "xs:divide-opacity-10"
  | "2xl:divide-opacity-20"
  | "1xl:divide-opacity-20"
  | "xl:divide-opacity-20"
  | "lg:divide-opacity-20"
  | "md:divide-opacity-20"
  | "sm:divide-opacity-20"
  | "xs:divide-opacity-20"
  | "2xl:divide-opacity-25"
  | "1xl:divide-opacity-25"
  | "xl:divide-opacity-25"
  | "lg:divide-opacity-25"
  | "md:divide-opacity-25"
  | "sm:divide-opacity-25"
  | "xs:divide-opacity-25"
  | "2xl:divide-opacity-30"
  | "1xl:divide-opacity-30"
  | "xl:divide-opacity-30"
  | "lg:divide-opacity-30"
  | "md:divide-opacity-30"
  | "sm:divide-opacity-30"
  | "xs:divide-opacity-30"
  | "2xl:divide-opacity-40"
  | "1xl:divide-opacity-40"
  | "xl:divide-opacity-40"
  | "lg:divide-opacity-40"
  | "md:divide-opacity-40"
  | "sm:divide-opacity-40"
  | "xs:divide-opacity-40"
  | "2xl:divide-opacity-50"
  | "1xl:divide-opacity-50"
  | "xl:divide-opacity-50"
  | "lg:divide-opacity-50"
  | "md:divide-opacity-50"
  | "sm:divide-opacity-50"
  | "xs:divide-opacity-50"
  | "2xl:divide-opacity-60"
  | "1xl:divide-opacity-60"
  | "xl:divide-opacity-60"
  | "lg:divide-opacity-60"
  | "md:divide-opacity-60"
  | "sm:divide-opacity-60"
  | "xs:divide-opacity-60"
  | "2xl:divide-opacity-70"
  | "1xl:divide-opacity-70"
  | "xl:divide-opacity-70"
  | "lg:divide-opacity-70"
  | "md:divide-opacity-70"
  | "sm:divide-opacity-70"
  | "xs:divide-opacity-70"
  | "2xl:divide-opacity-75"
  | "1xl:divide-opacity-75"
  | "xl:divide-opacity-75"
  | "lg:divide-opacity-75"
  | "md:divide-opacity-75"
  | "sm:divide-opacity-75"
  | "xs:divide-opacity-75"
  | "2xl:divide-opacity-80"
  | "1xl:divide-opacity-80"
  | "xl:divide-opacity-80"
  | "lg:divide-opacity-80"
  | "md:divide-opacity-80"
  | "sm:divide-opacity-80"
  | "xs:divide-opacity-80"
  | "2xl:divide-opacity-90"
  | "1xl:divide-opacity-90"
  | "xl:divide-opacity-90"
  | "lg:divide-opacity-90"
  | "md:divide-opacity-90"
  | "sm:divide-opacity-90"
  | "xs:divide-opacity-90"
  | "2xl:divide-opacity-95"
  | "1xl:divide-opacity-95"
  | "xl:divide-opacity-95"
  | "lg:divide-opacity-95"
  | "md:divide-opacity-95"
  | "sm:divide-opacity-95"
  | "xs:divide-opacity-95"
  | "2xl:divide-opacity-100"
  | "1xl:divide-opacity-100"
  | "xl:divide-opacity-100"
  | "lg:divide-opacity-100"
  | "md:divide-opacity-100"
  | "sm:divide-opacity-100"
  | "xs:divide-opacity-100"
  | "2xl:divide-solid"
  | "1xl:divide-solid"
  | "xl:divide-solid"
  | "lg:divide-solid"
  | "md:divide-solid"
  | "sm:divide-solid"
  | "xs:divide-solid"
  | "2xl:divide-dashed"
  | "1xl:divide-dashed"
  | "xl:divide-dashed"
  | "lg:divide-dashed"
  | "md:divide-dashed"
  | "sm:divide-dashed"
  | "xs:divide-dashed"
  | "2xl:divide-dotted"
  | "1xl:divide-dotted"
  | "xl:divide-dotted"
  | "lg:divide-dotted"
  | "md:divide-dotted"
  | "sm:divide-dotted"
  | "xs:divide-dotted"
  | "2xl:divide-double"
  | "1xl:divide-double"
  | "xl:divide-double"
  | "lg:divide-double"
  | "md:divide-double"
  | "sm:divide-double"
  | "xs:divide-double"
  | "2xl:divide-none"
  | "1xl:divide-none"
  | "xl:divide-none"
  | "lg:divide-none"
  | "md:divide-none"
  | "sm:divide-none"
  | "xs:divide-none"
  | "2xl:divide-x-0"
  | "1xl:divide-x-0"
  | "xl:divide-x-0"
  | "lg:divide-x-0"
  | "md:divide-x-0"
  | "sm:divide-x-0"
  | "xs:divide-x-0"
  | "2xl:divide-y-0"
  | "1xl:divide-y-0"
  | "xl:divide-y-0"
  | "lg:divide-y-0"
  | "md:divide-y-0"
  | "sm:divide-y-0"
  | "xs:divide-y-0"
  | "2xl:divide-x-2"
  | "1xl:divide-x-2"
  | "xl:divide-x-2"
  | "lg:divide-x-2"
  | "md:divide-x-2"
  | "sm:divide-x-2"
  | "xs:divide-x-2"
  | "2xl:divide-y-2"
  | "1xl:divide-y-2"
  | "xl:divide-y-2"
  | "lg:divide-y-2"
  | "md:divide-y-2"
  | "sm:divide-y-2"
  | "xs:divide-y-2"
  | "2xl:divide-x-4"
  | "1xl:divide-x-4"
  | "xl:divide-x-4"
  | "lg:divide-x-4"
  | "md:divide-x-4"
  | "sm:divide-x-4"
  | "xs:divide-x-4"
  | "2xl:divide-y-4"
  | "1xl:divide-y-4"
  | "xl:divide-y-4"
  | "lg:divide-y-4"
  | "md:divide-y-4"
  | "sm:divide-y-4"
  | "xs:divide-y-4"
  | "2xl:divide-x-8"
  | "1xl:divide-x-8"
  | "xl:divide-x-8"
  | "lg:divide-x-8"
  | "md:divide-x-8"
  | "sm:divide-x-8"
  | "xs:divide-x-8"
  | "2xl:divide-y-8"
  | "1xl:divide-y-8"
  | "xl:divide-y-8"
  | "lg:divide-y-8"
  | "md:divide-y-8"
  | "sm:divide-y-8"
  | "xs:divide-y-8"
  | "2xl:divide-x"
  | "1xl:divide-x"
  | "xl:divide-x"
  | "lg:divide-x"
  | "md:divide-x"
  | "sm:divide-x"
  | "xs:divide-x"
  | "2xl:divide-y"
  | "1xl:divide-y"
  | "xl:divide-y"
  | "lg:divide-y"
  | "md:divide-y"
  | "sm:divide-y"
  | "xs:divide-y"
  | "2xl:divide-x-reverse"
  | "1xl:divide-x-reverse"
  | "xl:divide-x-reverse"
  | "lg:divide-x-reverse"
  | "md:divide-x-reverse"
  | "sm:divide-x-reverse"
  | "xs:divide-x-reverse"
  | "2xl:divide-y-reverse"
  | "1xl:divide-y-reverse"
  | "xl:divide-y-reverse"
  | "lg:divide-y-reverse"
  | "md:divide-y-reverse"
  | "sm:divide-y-reverse"
  | "xs:divide-y-reverse"
  | "2xl:drop-shadow-sm"
  | "1xl:drop-shadow-sm"
  | "xl:drop-shadow-sm"
  | "lg:drop-shadow-sm"
  | "md:drop-shadow-sm"
  | "sm:drop-shadow-sm"
  | "xs:drop-shadow-sm"
  | "2xl:drop-shadow"
  | "1xl:drop-shadow"
  | "xl:drop-shadow"
  | "lg:drop-shadow"
  | "md:drop-shadow"
  | "sm:drop-shadow"
  | "xs:drop-shadow"
  | "2xl:drop-shadow-md"
  | "1xl:drop-shadow-md"
  | "xl:drop-shadow-md"
  | "lg:drop-shadow-md"
  | "md:drop-shadow-md"
  | "sm:drop-shadow-md"
  | "xs:drop-shadow-md"
  | "2xl:drop-shadow-lg"
  | "1xl:drop-shadow-lg"
  | "xl:drop-shadow-lg"
  | "lg:drop-shadow-lg"
  | "md:drop-shadow-lg"
  | "sm:drop-shadow-lg"
  | "xs:drop-shadow-lg"
  | "2xl:drop-shadow-xl"
  | "1xl:drop-shadow-xl"
  | "xl:drop-shadow-xl"
  | "lg:drop-shadow-xl"
  | "md:drop-shadow-xl"
  | "sm:drop-shadow-xl"
  | "xs:drop-shadow-xl"
  | "2xl:drop-shadow-2xl"
  | "1xl:drop-shadow-2xl"
  | "xl:drop-shadow-2xl"
  | "lg:drop-shadow-2xl"
  | "md:drop-shadow-2xl"
  | "sm:drop-shadow-2xl"
  | "xs:drop-shadow-2xl"
  | "2xl:drop-shadow-none"
  | "1xl:drop-shadow-none"
  | "xl:drop-shadow-none"
  | "lg:drop-shadow-none"
  | "md:drop-shadow-none"
  | "sm:drop-shadow-none"
  | "xs:drop-shadow-none"
  | "2xl:fill-current"
  | "1xl:fill-current"
  | "xl:fill-current"
  | "lg:fill-current"
  | "md:fill-current"
  | "sm:fill-current"
  | "xs:fill-current"
  | "2xl:filter"
  | "1xl:filter"
  | "xl:filter"
  | "lg:filter"
  | "md:filter"
  | "sm:filter"
  | "xs:filter"
  | "2xl:filter-none"
  | "1xl:filter-none"
  | "xl:filter-none"
  | "lg:filter-none"
  | "md:filter-none"
  | "sm:filter-none"
  | "xs:filter-none"
  | "2xl:flex-initial"
  | "1xl:flex-initial"
  | "xl:flex-initial"
  | "lg:flex-initial"
  | "md:flex-initial"
  | "sm:flex-initial"
  | "xs:flex-initial"
  | "2xl:flex-1"
  | "1xl:flex-1"
  | "xl:flex-1"
  | "lg:flex-1"
  | "md:flex-1"
  | "sm:flex-1"
  | "xs:flex-1"
  | "2xl:flex-auto"
  | "1xl:flex-auto"
  | "xl:flex-auto"
  | "lg:flex-auto"
  | "md:flex-auto"
  | "sm:flex-auto"
  | "xs:flex-auto"
  | "2xl:flex-none"
  | "1xl:flex-none"
  | "xl:flex-none"
  | "lg:flex-none"
  | "md:flex-none"
  | "sm:flex-none"
  | "xs:flex-none"
  | "2xl:flex-row"
  | "1xl:flex-row"
  | "xl:flex-row"
  | "lg:flex-row"
  | "md:flex-row"
  | "sm:flex-row"
  | "xs:flex-row"
  | "2xl:flex-row-reverse"
  | "1xl:flex-row-reverse"
  | "xl:flex-row-reverse"
  | "lg:flex-row-reverse"
  | "md:flex-row-reverse"
  | "sm:flex-row-reverse"
  | "xs:flex-row-reverse"
  | "2xl:flex-col"
  | "1xl:flex-col"
  | "xl:flex-col"
  | "lg:flex-col"
  | "md:flex-col"
  | "sm:flex-col"
  | "xs:flex-col"
  | "2xl:flex-col-reverse"
  | "1xl:flex-col-reverse"
  | "xl:flex-col-reverse"
  | "lg:flex-col-reverse"
  | "md:flex-col-reverse"
  | "sm:flex-col-reverse"
  | "xs:flex-col-reverse"
  | "2xl:flex-grow-0"
  | "1xl:flex-grow-0"
  | "xl:flex-grow-0"
  | "lg:flex-grow-0"
  | "md:flex-grow-0"
  | "sm:flex-grow-0"
  | "xs:flex-grow-0"
  | "2xl:flex-grow"
  | "1xl:flex-grow"
  | "xl:flex-grow"
  | "lg:flex-grow"
  | "md:flex-grow"
  | "sm:flex-grow"
  | "xs:flex-grow"
  | "2xl:flex-shrink-0"
  | "1xl:flex-shrink-0"
  | "xl:flex-shrink-0"
  | "lg:flex-shrink-0"
  | "md:flex-shrink-0"
  | "sm:flex-shrink-0"
  | "xs:flex-shrink-0"
  | "2xl:flex-shrink"
  | "1xl:flex-shrink"
  | "xl:flex-shrink"
  | "lg:flex-shrink"
  | "md:flex-shrink"
  | "sm:flex-shrink"
  | "xs:flex-shrink"
  | "2xl:flex-nowrap"
  | "1xl:flex-nowrap"
  | "xl:flex-nowrap"
  | "lg:flex-nowrap"
  | "md:flex-nowrap"
  | "sm:flex-nowrap"
  | "xs:flex-nowrap"
  | "2xl:flex-wrap"
  | "1xl:flex-wrap"
  | "xl:flex-wrap"
  | "lg:flex-wrap"
  | "md:flex-wrap"
  | "sm:flex-wrap"
  | "xs:flex-wrap"
  | "2xl:flex-wrap-reverse"
  | "1xl:flex-wrap-reverse"
  | "xl:flex-wrap-reverse"
  | "lg:flex-wrap-reverse"
  | "md:flex-wrap-reverse"
  | "sm:flex-wrap-reverse"
  | "xs:flex-wrap-reverse"
  | "2xl:float-right"
  | "1xl:float-right"
  | "xl:float-right"
  | "lg:float-right"
  | "md:float-right"
  | "sm:float-right"
  | "xs:float-right"
  | "2xl:float-left"
  | "1xl:float-left"
  | "xl:float-left"
  | "lg:float-left"
  | "md:float-left"
  | "sm:float-left"
  | "xs:float-left"
  | "2xl:float-none"
  | "1xl:float-none"
  | "xl:float-none"
  | "lg:float-none"
  | "md:float-none"
  | "sm:float-none"
  | "xs:float-none"
  | "2xl:font-sans"
  | "1xl:font-sans"
  | "xl:font-sans"
  | "lg:font-sans"
  | "md:font-sans"
  | "sm:font-sans"
  | "xs:font-sans"
  | "2xl:font-serif"
  | "1xl:font-serif"
  | "xl:font-serif"
  | "lg:font-serif"
  | "md:font-serif"
  | "sm:font-serif"
  | "xs:font-serif"
  | "2xl:font-mono"
  | "1xl:font-mono"
  | "xl:font-mono"
  | "lg:font-mono"
  | "md:font-mono"
  | "sm:font-mono"
  | "xs:font-mono"
  | "2xl:text-xs"
  | "1xl:text-xs"
  | "xl:text-xs"
  | "lg:text-xs"
  | "md:text-xs"
  | "sm:text-xs"
  | "xs:text-xs"
  | "2xl:text-sm"
  | "1xl:text-sm"
  | "xl:text-sm"
  | "lg:text-sm"
  | "md:text-sm"
  | "sm:text-sm"
  | "xs:text-sm"
  | "2xl:text-base"
  | "1xl:text-base"
  | "xl:text-base"
  | "lg:text-base"
  | "md:text-base"
  | "sm:text-base"
  | "xs:text-base"
  | "2xl:text-lg"
  | "1xl:text-lg"
  | "xl:text-lg"
  | "lg:text-lg"
  | "md:text-lg"
  | "sm:text-lg"
  | "xs:text-lg"
  | "2xl:text-xl"
  | "1xl:text-xl"
  | "xl:text-xl"
  | "lg:text-xl"
  | "md:text-xl"
  | "sm:text-xl"
  | "xs:text-xl"
  | "2xl:text-2xl"
  | "1xl:text-2xl"
  | "xl:text-2xl"
  | "lg:text-2xl"
  | "md:text-2xl"
  | "sm:text-2xl"
  | "xs:text-2xl"
  | "2xl:text-3xl"
  | "1xl:text-3xl"
  | "xl:text-3xl"
  | "lg:text-3xl"
  | "md:text-3xl"
  | "sm:text-3xl"
  | "xs:text-3xl"
  | "2xl:text-4xl"
  | "1xl:text-4xl"
  | "xl:text-4xl"
  | "lg:text-4xl"
  | "md:text-4xl"
  | "sm:text-4xl"
  | "xs:text-4xl"
  | "2xl:text-5xl"
  | "1xl:text-5xl"
  | "xl:text-5xl"
  | "lg:text-5xl"
  | "md:text-5xl"
  | "sm:text-5xl"
  | "xs:text-5xl"
  | "2xl:text-6xl"
  | "1xl:text-6xl"
  | "xl:text-6xl"
  | "lg:text-6xl"
  | "md:text-6xl"
  | "sm:text-6xl"
  | "xs:text-6xl"
  | "2xl:text-7xl"
  | "1xl:text-7xl"
  | "xl:text-7xl"
  | "lg:text-7xl"
  | "md:text-7xl"
  | "sm:text-7xl"
  | "xs:text-7xl"
  | "2xl:text-8xl"
  | "1xl:text-8xl"
  | "xl:text-8xl"
  | "lg:text-8xl"
  | "md:text-8xl"
  | "sm:text-8xl"
  | "xs:text-8xl"
  | "2xl:text-9xl"
  | "1xl:text-9xl"
  | "xl:text-9xl"
  | "lg:text-9xl"
  | "md:text-9xl"
  | "sm:text-9xl"
  | "xs:text-9xl"
  | "2xl:antialiased"
  | "1xl:antialiased"
  | "xl:antialiased"
  | "lg:antialiased"
  | "md:antialiased"
  | "sm:antialiased"
  | "xs:antialiased"
  | "2xl:subpixel-antialiased"
  | "1xl:subpixel-antialiased"
  | "xl:subpixel-antialiased"
  | "lg:subpixel-antialiased"
  | "md:subpixel-antialiased"
  | "sm:subpixel-antialiased"
  | "xs:subpixel-antialiased"
  | "2xl:italic"
  | "1xl:italic"
  | "xl:italic"
  | "lg:italic"
  | "md:italic"
  | "sm:italic"
  | "xs:italic"
  | "2xl:non-italic"
  | "1xl:non-italic"
  | "xl:non-italic"
  | "lg:non-italic"
  | "md:non-italic"
  | "sm:non-italic"
  | "xs:non-italic"
  | "2xl:normal-nums"
  | "1xl:normal-nums"
  | "xl:normal-nums"
  | "lg:normal-nums"
  | "md:normal-nums"
  | "sm:normal-nums"
  | "xs:normal-nums"
  | "2xl:ordinal"
  | "1xl:ordinal"
  | "xl:ordinal"
  | "lg:ordinal"
  | "md:ordinal"
  | "sm:ordinal"
  | "xs:ordinal"
  | "2xl:slashed-zero"
  | "1xl:slashed-zero"
  | "xl:slashed-zero"
  | "lg:slashed-zero"
  | "md:slashed-zero"
  | "sm:slashed-zero"
  | "xs:slashed-zero"
  | "2xl:lining-nums"
  | "1xl:lining-nums"
  | "xl:lining-nums"
  | "lg:lining-nums"
  | "md:lining-nums"
  | "sm:lining-nums"
  | "xs:lining-nums"
  | "2xl:oldstyle-nums"
  | "1xl:oldstyle-nums"
  | "xl:oldstyle-nums"
  | "lg:oldstyle-nums"
  | "md:oldstyle-nums"
  | "sm:oldstyle-nums"
  | "xs:oldstyle-nums"
  | "2xl:proportional-nums"
  | "1xl:proportional-nums"
  | "xl:proportional-nums"
  | "lg:proportional-nums"
  | "md:proportional-nums"
  | "sm:proportional-nums"
  | "xs:proportional-nums"
  | "2xl:tabular-nums"
  | "1xl:tabular-nums"
  | "xl:tabular-nums"
  | "lg:tabular-nums"
  | "md:tabular-nums"
  | "sm:tabular-nums"
  | "xs:tabular-nums"
  | "2xl:diagonal-fractions"
  | "1xl:diagonal-fractions"
  | "xl:diagonal-fractions"
  | "lg:diagonal-fractions"
  | "md:diagonal-fractions"
  | "sm:diagonal-fractions"
  | "xs:diagonal-fractions"
  | "2xl:stacked-fractions"
  | "1xl:stacked-fractions"
  | "xl:stacked-fractions"
  | "lg:stacked-fractions"
  | "md:stacked-fractions"
  | "sm:stacked-fractions"
  | "xs:stacked-fractions"
  | "2xl:font-thin"
  | "1xl:font-thin"
  | "xl:font-thin"
  | "lg:font-thin"
  | "md:font-thin"
  | "sm:font-thin"
  | "xs:font-thin"
  | "2xl:font-extralight"
  | "1xl:font-extralight"
  | "xl:font-extralight"
  | "lg:font-extralight"
  | "md:font-extralight"
  | "sm:font-extralight"
  | "xs:font-extralight"
  | "2xl:font-light"
  | "1xl:font-light"
  | "xl:font-light"
  | "lg:font-light"
  | "md:font-light"
  | "sm:font-light"
  | "xs:font-light"
  | "2xl:font-normal"
  | "1xl:font-normal"
  | "xl:font-normal"
  | "lg:font-normal"
  | "md:font-normal"
  | "sm:font-normal"
  | "xs:font-normal"
  | "2xl:font-medium"
  | "1xl:font-medium"
  | "xl:font-medium"
  | "lg:font-medium"
  | "md:font-medium"
  | "sm:font-medium"
  | "xs:font-medium"
  | "2xl:font-semibold"
  | "1xl:font-semibold"
  | "xl:font-semibold"
  | "lg:font-semibold"
  | "md:font-semibold"
  | "sm:font-semibold"
  | "xs:font-semibold"
  | "2xl:font-bold"
  | "1xl:font-bold"
  | "xl:font-bold"
  | "lg:font-bold"
  | "md:font-bold"
  | "sm:font-bold"
  | "xs:font-bold"
  | "2xl:font-extrabold"
  | "1xl:font-extrabold"
  | "xl:font-extrabold"
  | "lg:font-extrabold"
  | "md:font-extrabold"
  | "sm:font-extrabold"
  | "xs:font-extrabold"
  | "2xl:font-black"
  | "1xl:font-black"
  | "xl:font-black"
  | "lg:font-black"
  | "md:font-black"
  | "sm:font-black"
  | "xs:font-black"
  | "2xl:gap-0"
  | "1xl:gap-0"
  | "xl:gap-0"
  | "lg:gap-0"
  | "md:gap-0"
  | "sm:gap-0"
  | "xs:gap-0"
  | "2xl:gap-1"
  | "1xl:gap-1"
  | "xl:gap-1"
  | "lg:gap-1"
  | "md:gap-1"
  | "sm:gap-1"
  | "xs:gap-1"
  | "2xl:gap-2"
  | "1xl:gap-2"
  | "xl:gap-2"
  | "lg:gap-2"
  | "md:gap-2"
  | "sm:gap-2"
  | "xs:gap-2"
  | "2xl:gap-3"
  | "1xl:gap-3"
  | "xl:gap-3"
  | "lg:gap-3"
  | "md:gap-3"
  | "sm:gap-3"
  | "xs:gap-3"
  | "2xl:gap-4"
  | "1xl:gap-4"
  | "xl:gap-4"
  | "lg:gap-4"
  | "md:gap-4"
  | "sm:gap-4"
  | "xs:gap-4"
  | "2xl:gap-5"
  | "1xl:gap-5"
  | "xl:gap-5"
  | "lg:gap-5"
  | "md:gap-5"
  | "sm:gap-5"
  | "xs:gap-5"
  | "2xl:gap-6"
  | "1xl:gap-6"
  | "xl:gap-6"
  | "lg:gap-6"
  | "md:gap-6"
  | "sm:gap-6"
  | "xs:gap-6"
  | "2xl:gap-7"
  | "1xl:gap-7"
  | "xl:gap-7"
  | "lg:gap-7"
  | "md:gap-7"
  | "sm:gap-7"
  | "xs:gap-7"
  | "2xl:gap-8"
  | "1xl:gap-8"
  | "xl:gap-8"
  | "lg:gap-8"
  | "md:gap-8"
  | "sm:gap-8"
  | "xs:gap-8"
  | "2xl:gap-9"
  | "1xl:gap-9"
  | "xl:gap-9"
  | "lg:gap-9"
  | "md:gap-9"
  | "sm:gap-9"
  | "xs:gap-9"
  | "2xl:gap-10"
  | "1xl:gap-10"
  | "xl:gap-10"
  | "lg:gap-10"
  | "md:gap-10"
  | "sm:gap-10"
  | "xs:gap-10"
  | "2xl:gap-11"
  | "1xl:gap-11"
  | "xl:gap-11"
  | "lg:gap-11"
  | "md:gap-11"
  | "sm:gap-11"
  | "xs:gap-11"
  | "2xl:gap-12"
  | "1xl:gap-12"
  | "xl:gap-12"
  | "lg:gap-12"
  | "md:gap-12"
  | "sm:gap-12"
  | "xs:gap-12"
  | "2xl:gap-14"
  | "1xl:gap-14"
  | "xl:gap-14"
  | "lg:gap-14"
  | "md:gap-14"
  | "sm:gap-14"
  | "xs:gap-14"
  | "2xl:gap-16"
  | "1xl:gap-16"
  | "xl:gap-16"
  | "lg:gap-16"
  | "md:gap-16"
  | "sm:gap-16"
  | "xs:gap-16"
  | "2xl:gap-20"
  | "1xl:gap-20"
  | "xl:gap-20"
  | "lg:gap-20"
  | "md:gap-20"
  | "sm:gap-20"
  | "xs:gap-20"
  | "2xl:gap-24"
  | "1xl:gap-24"
  | "xl:gap-24"
  | "lg:gap-24"
  | "md:gap-24"
  | "sm:gap-24"
  | "xs:gap-24"
  | "2xl:gap-28"
  | "1xl:gap-28"
  | "xl:gap-28"
  | "lg:gap-28"
  | "md:gap-28"
  | "sm:gap-28"
  | "xs:gap-28"
  | "2xl:gap-32"
  | "1xl:gap-32"
  | "xl:gap-32"
  | "lg:gap-32"
  | "md:gap-32"
  | "sm:gap-32"
  | "xs:gap-32"
  | "2xl:gap-36"
  | "1xl:gap-36"
  | "xl:gap-36"
  | "lg:gap-36"
  | "md:gap-36"
  | "sm:gap-36"
  | "xs:gap-36"
  | "2xl:gap-40"
  | "1xl:gap-40"
  | "xl:gap-40"
  | "lg:gap-40"
  | "md:gap-40"
  | "sm:gap-40"
  | "xs:gap-40"
  | "2xl:gap-44"
  | "1xl:gap-44"
  | "xl:gap-44"
  | "lg:gap-44"
  | "md:gap-44"
  | "sm:gap-44"
  | "xs:gap-44"
  | "2xl:gap-48"
  | "1xl:gap-48"
  | "xl:gap-48"
  | "lg:gap-48"
  | "md:gap-48"
  | "sm:gap-48"
  | "xs:gap-48"
  | "2xl:gap-52"
  | "1xl:gap-52"
  | "xl:gap-52"
  | "lg:gap-52"
  | "md:gap-52"
  | "sm:gap-52"
  | "xs:gap-52"
  | "2xl:gap-56"
  | "1xl:gap-56"
  | "xl:gap-56"
  | "lg:gap-56"
  | "md:gap-56"
  | "sm:gap-56"
  | "xs:gap-56"
  | "2xl:gap-60"
  | "1xl:gap-60"
  | "xl:gap-60"
  | "lg:gap-60"
  | "md:gap-60"
  | "sm:gap-60"
  | "xs:gap-60"
  | "2xl:gap-64"
  | "1xl:gap-64"
  | "xl:gap-64"
  | "lg:gap-64"
  | "md:gap-64"
  | "sm:gap-64"
  | "xs:gap-64"
  | "2xl:gap-72"
  | "1xl:gap-72"
  | "xl:gap-72"
  | "lg:gap-72"
  | "md:gap-72"
  | "sm:gap-72"
  | "xs:gap-72"
  | "2xl:gap-80"
  | "1xl:gap-80"
  | "xl:gap-80"
  | "lg:gap-80"
  | "md:gap-80"
  | "sm:gap-80"
  | "xs:gap-80"
  | "2xl:gap-96"
  | "1xl:gap-96"
  | "xl:gap-96"
  | "lg:gap-96"
  | "md:gap-96"
  | "sm:gap-96"
  | "xs:gap-96"
  | "2xl:gap-px"
  | "1xl:gap-px"
  | "xl:gap-px"
  | "lg:gap-px"
  | "md:gap-px"
  | "sm:gap-px"
  | "xs:gap-px"
  | "2xl:gap-0.5"
  | "1xl:gap-0.5"
  | "xl:gap-0.5"
  | "lg:gap-0.5"
  | "md:gap-0.5"
  | "sm:gap-0.5"
  | "xs:gap-0.5"
  | "2xl:gap-1.5"
  | "1xl:gap-1.5"
  | "xl:gap-1.5"
  | "lg:gap-1.5"
  | "md:gap-1.5"
  | "sm:gap-1.5"
  | "xs:gap-1.5"
  | "2xl:gap-2.5"
  | "1xl:gap-2.5"
  | "xl:gap-2.5"
  | "lg:gap-2.5"
  | "md:gap-2.5"
  | "sm:gap-2.5"
  | "xs:gap-2.5"
  | "2xl:gap-3.5"
  | "1xl:gap-3.5"
  | "xl:gap-3.5"
  | "lg:gap-3.5"
  | "md:gap-3.5"
  | "sm:gap-3.5"
  | "xs:gap-3.5"
  | "2xl:gap-y-0"
  | "1xl:gap-y-0"
  | "xl:gap-y-0"
  | "lg:gap-y-0"
  | "md:gap-y-0"
  | "sm:gap-y-0"
  | "xs:gap-y-0"
  | "2xl:gap-y-1"
  | "1xl:gap-y-1"
  | "xl:gap-y-1"
  | "lg:gap-y-1"
  | "md:gap-y-1"
  | "sm:gap-y-1"
  | "xs:gap-y-1"
  | "2xl:gap-y-2"
  | "1xl:gap-y-2"
  | "xl:gap-y-2"
  | "lg:gap-y-2"
  | "md:gap-y-2"
  | "sm:gap-y-2"
  | "xs:gap-y-2"
  | "2xl:gap-y-3"
  | "1xl:gap-y-3"
  | "xl:gap-y-3"
  | "lg:gap-y-3"
  | "md:gap-y-3"
  | "sm:gap-y-3"
  | "xs:gap-y-3"
  | "2xl:gap-y-4"
  | "1xl:gap-y-4"
  | "xl:gap-y-4"
  | "lg:gap-y-4"
  | "md:gap-y-4"
  | "sm:gap-y-4"
  | "xs:gap-y-4"
  | "2xl:gap-y-5"
  | "1xl:gap-y-5"
  | "xl:gap-y-5"
  | "lg:gap-y-5"
  | "md:gap-y-5"
  | "sm:gap-y-5"
  | "xs:gap-y-5"
  | "2xl:gap-y-6"
  | "1xl:gap-y-6"
  | "xl:gap-y-6"
  | "lg:gap-y-6"
  | "md:gap-y-6"
  | "sm:gap-y-6"
  | "xs:gap-y-6"
  | "2xl:gap-y-7"
  | "1xl:gap-y-7"
  | "xl:gap-y-7"
  | "lg:gap-y-7"
  | "md:gap-y-7"
  | "sm:gap-y-7"
  | "xs:gap-y-7"
  | "2xl:gap-y-8"
  | "1xl:gap-y-8"
  | "xl:gap-y-8"
  | "lg:gap-y-8"
  | "md:gap-y-8"
  | "sm:gap-y-8"
  | "xs:gap-y-8"
  | "2xl:gap-y-9"
  | "1xl:gap-y-9"
  | "xl:gap-y-9"
  | "lg:gap-y-9"
  | "md:gap-y-9"
  | "sm:gap-y-9"
  | "xs:gap-y-9"
  | "2xl:gap-y-10"
  | "1xl:gap-y-10"
  | "xl:gap-y-10"
  | "lg:gap-y-10"
  | "md:gap-y-10"
  | "sm:gap-y-10"
  | "xs:gap-y-10"
  | "2xl:gap-y-11"
  | "1xl:gap-y-11"
  | "xl:gap-y-11"
  | "lg:gap-y-11"
  | "md:gap-y-11"
  | "sm:gap-y-11"
  | "xs:gap-y-11"
  | "2xl:gap-y-12"
  | "1xl:gap-y-12"
  | "xl:gap-y-12"
  | "lg:gap-y-12"
  | "md:gap-y-12"
  | "sm:gap-y-12"
  | "xs:gap-y-12"
  | "2xl:gap-y-14"
  | "1xl:gap-y-14"
  | "xl:gap-y-14"
  | "lg:gap-y-14"
  | "md:gap-y-14"
  | "sm:gap-y-14"
  | "xs:gap-y-14"
  | "2xl:gap-y-16"
  | "1xl:gap-y-16"
  | "xl:gap-y-16"
  | "lg:gap-y-16"
  | "md:gap-y-16"
  | "sm:gap-y-16"
  | "xs:gap-y-16"
  | "2xl:gap-y-20"
  | "1xl:gap-y-20"
  | "xl:gap-y-20"
  | "lg:gap-y-20"
  | "md:gap-y-20"
  | "sm:gap-y-20"
  | "xs:gap-y-20"
  | "2xl:gap-y-24"
  | "1xl:gap-y-24"
  | "xl:gap-y-24"
  | "lg:gap-y-24"
  | "md:gap-y-24"
  | "sm:gap-y-24"
  | "xs:gap-y-24"
  | "2xl:gap-y-28"
  | "1xl:gap-y-28"
  | "xl:gap-y-28"
  | "lg:gap-y-28"
  | "md:gap-y-28"
  | "sm:gap-y-28"
  | "xs:gap-y-28"
  | "2xl:gap-y-32"
  | "1xl:gap-y-32"
  | "xl:gap-y-32"
  | "lg:gap-y-32"
  | "md:gap-y-32"
  | "sm:gap-y-32"
  | "xs:gap-y-32"
  | "2xl:gap-y-36"
  | "1xl:gap-y-36"
  | "xl:gap-y-36"
  | "lg:gap-y-36"
  | "md:gap-y-36"
  | "sm:gap-y-36"
  | "xs:gap-y-36"
  | "2xl:gap-y-40"
  | "1xl:gap-y-40"
  | "xl:gap-y-40"
  | "lg:gap-y-40"
  | "md:gap-y-40"
  | "sm:gap-y-40"
  | "xs:gap-y-40"
  | "2xl:gap-y-44"
  | "1xl:gap-y-44"
  | "xl:gap-y-44"
  | "lg:gap-y-44"
  | "md:gap-y-44"
  | "sm:gap-y-44"
  | "xs:gap-y-44"
  | "2xl:gap-y-48"
  | "1xl:gap-y-48"
  | "xl:gap-y-48"
  | "lg:gap-y-48"
  | "md:gap-y-48"
  | "sm:gap-y-48"
  | "xs:gap-y-48"
  | "2xl:gap-y-52"
  | "1xl:gap-y-52"
  | "xl:gap-y-52"
  | "lg:gap-y-52"
  | "md:gap-y-52"
  | "sm:gap-y-52"
  | "xs:gap-y-52"
  | "2xl:gap-y-56"
  | "1xl:gap-y-56"
  | "xl:gap-y-56"
  | "lg:gap-y-56"
  | "md:gap-y-56"
  | "sm:gap-y-56"
  | "xs:gap-y-56"
  | "2xl:gap-y-60"
  | "1xl:gap-y-60"
  | "xl:gap-y-60"
  | "lg:gap-y-60"
  | "md:gap-y-60"
  | "sm:gap-y-60"
  | "xs:gap-y-60"
  | "2xl:gap-y-64"
  | "1xl:gap-y-64"
  | "xl:gap-y-64"
  | "lg:gap-y-64"
  | "md:gap-y-64"
  | "sm:gap-y-64"
  | "xs:gap-y-64"
  | "2xl:gap-y-72"
  | "1xl:gap-y-72"
  | "xl:gap-y-72"
  | "lg:gap-y-72"
  | "md:gap-y-72"
  | "sm:gap-y-72"
  | "xs:gap-y-72"
  | "2xl:gap-y-80"
  | "1xl:gap-y-80"
  | "xl:gap-y-80"
  | "lg:gap-y-80"
  | "md:gap-y-80"
  | "sm:gap-y-80"
  | "xs:gap-y-80"
  | "2xl:gap-y-96"
  | "1xl:gap-y-96"
  | "xl:gap-y-96"
  | "lg:gap-y-96"
  | "md:gap-y-96"
  | "sm:gap-y-96"
  | "xs:gap-y-96"
  | "2xl:gap-y-px"
  | "1xl:gap-y-px"
  | "xl:gap-y-px"
  | "lg:gap-y-px"
  | "md:gap-y-px"
  | "sm:gap-y-px"
  | "xs:gap-y-px"
  | "2xl:gap-y-0.5"
  | "1xl:gap-y-0.5"
  | "xl:gap-y-0.5"
  | "lg:gap-y-0.5"
  | "md:gap-y-0.5"
  | "sm:gap-y-0.5"
  | "xs:gap-y-0.5"
  | "2xl:gap-y-1.5"
  | "1xl:gap-y-1.5"
  | "xl:gap-y-1.5"
  | "lg:gap-y-1.5"
  | "md:gap-y-1.5"
  | "sm:gap-y-1.5"
  | "xs:gap-y-1.5"
  | "2xl:gap-y-2.5"
  | "1xl:gap-y-2.5"
  | "xl:gap-y-2.5"
  | "lg:gap-y-2.5"
  | "md:gap-y-2.5"
  | "sm:gap-y-2.5"
  | "xs:gap-y-2.5"
  | "2xl:gap-y-3.5"
  | "1xl:gap-y-3.5"
  | "xl:gap-y-3.5"
  | "lg:gap-y-3.5"
  | "md:gap-y-3.5"
  | "sm:gap-y-3.5"
  | "xs:gap-y-3.5"
  | "2xl:gap-x-0"
  | "1xl:gap-x-0"
  | "xl:gap-x-0"
  | "lg:gap-x-0"
  | "md:gap-x-0"
  | "sm:gap-x-0"
  | "xs:gap-x-0"
  | "2xl:gap-x-1"
  | "1xl:gap-x-1"
  | "xl:gap-x-1"
  | "lg:gap-x-1"
  | "md:gap-x-1"
  | "sm:gap-x-1"
  | "xs:gap-x-1"
  | "2xl:gap-x-2"
  | "1xl:gap-x-2"
  | "xl:gap-x-2"
  | "lg:gap-x-2"
  | "md:gap-x-2"
  | "sm:gap-x-2"
  | "xs:gap-x-2"
  | "2xl:gap-x-3"
  | "1xl:gap-x-3"
  | "xl:gap-x-3"
  | "lg:gap-x-3"
  | "md:gap-x-3"
  | "sm:gap-x-3"
  | "xs:gap-x-3"
  | "2xl:gap-x-4"
  | "1xl:gap-x-4"
  | "xl:gap-x-4"
  | "lg:gap-x-4"
  | "md:gap-x-4"
  | "sm:gap-x-4"
  | "xs:gap-x-4"
  | "2xl:gap-x-5"
  | "1xl:gap-x-5"
  | "xl:gap-x-5"
  | "lg:gap-x-5"
  | "md:gap-x-5"
  | "sm:gap-x-5"
  | "xs:gap-x-5"
  | "2xl:gap-x-6"
  | "1xl:gap-x-6"
  | "xl:gap-x-6"
  | "lg:gap-x-6"
  | "md:gap-x-6"
  | "sm:gap-x-6"
  | "xs:gap-x-6"
  | "2xl:gap-x-7"
  | "1xl:gap-x-7"
  | "xl:gap-x-7"
  | "lg:gap-x-7"
  | "md:gap-x-7"
  | "sm:gap-x-7"
  | "xs:gap-x-7"
  | "2xl:gap-x-8"
  | "1xl:gap-x-8"
  | "xl:gap-x-8"
  | "lg:gap-x-8"
  | "md:gap-x-8"
  | "sm:gap-x-8"
  | "xs:gap-x-8"
  | "2xl:gap-x-9"
  | "1xl:gap-x-9"
  | "xl:gap-x-9"
  | "lg:gap-x-9"
  | "md:gap-x-9"
  | "sm:gap-x-9"
  | "xs:gap-x-9"
  | "2xl:gap-x-10"
  | "1xl:gap-x-10"
  | "xl:gap-x-10"
  | "lg:gap-x-10"
  | "md:gap-x-10"
  | "sm:gap-x-10"
  | "xs:gap-x-10"
  | "2xl:gap-x-11"
  | "1xl:gap-x-11"
  | "xl:gap-x-11"
  | "lg:gap-x-11"
  | "md:gap-x-11"
  | "sm:gap-x-11"
  | "xs:gap-x-11"
  | "2xl:gap-x-12"
  | "1xl:gap-x-12"
  | "xl:gap-x-12"
  | "lg:gap-x-12"
  | "md:gap-x-12"
  | "sm:gap-x-12"
  | "xs:gap-x-12"
  | "2xl:gap-x-14"
  | "1xl:gap-x-14"
  | "xl:gap-x-14"
  | "lg:gap-x-14"
  | "md:gap-x-14"
  | "sm:gap-x-14"
  | "xs:gap-x-14"
  | "2xl:gap-x-16"
  | "1xl:gap-x-16"
  | "xl:gap-x-16"
  | "lg:gap-x-16"
  | "md:gap-x-16"
  | "sm:gap-x-16"
  | "xs:gap-x-16"
  | "2xl:gap-x-20"
  | "1xl:gap-x-20"
  | "xl:gap-x-20"
  | "lg:gap-x-20"
  | "md:gap-x-20"
  | "sm:gap-x-20"
  | "xs:gap-x-20"
  | "2xl:gap-x-24"
  | "1xl:gap-x-24"
  | "xl:gap-x-24"
  | "lg:gap-x-24"
  | "md:gap-x-24"
  | "sm:gap-x-24"
  | "xs:gap-x-24"
  | "2xl:gap-x-28"
  | "1xl:gap-x-28"
  | "xl:gap-x-28"
  | "lg:gap-x-28"
  | "md:gap-x-28"
  | "sm:gap-x-28"
  | "xs:gap-x-28"
  | "2xl:gap-x-32"
  | "1xl:gap-x-32"
  | "xl:gap-x-32"
  | "lg:gap-x-32"
  | "md:gap-x-32"
  | "sm:gap-x-32"
  | "xs:gap-x-32"
  | "2xl:gap-x-36"
  | "1xl:gap-x-36"
  | "xl:gap-x-36"
  | "lg:gap-x-36"
  | "md:gap-x-36"
  | "sm:gap-x-36"
  | "xs:gap-x-36"
  | "2xl:gap-x-40"
  | "1xl:gap-x-40"
  | "xl:gap-x-40"
  | "lg:gap-x-40"
  | "md:gap-x-40"
  | "sm:gap-x-40"
  | "xs:gap-x-40"
  | "2xl:gap-x-44"
  | "1xl:gap-x-44"
  | "xl:gap-x-44"
  | "lg:gap-x-44"
  | "md:gap-x-44"
  | "sm:gap-x-44"
  | "xs:gap-x-44"
  | "2xl:gap-x-48"
  | "1xl:gap-x-48"
  | "xl:gap-x-48"
  | "lg:gap-x-48"
  | "md:gap-x-48"
  | "sm:gap-x-48"
  | "xs:gap-x-48"
  | "2xl:gap-x-52"
  | "1xl:gap-x-52"
  | "xl:gap-x-52"
  | "lg:gap-x-52"
  | "md:gap-x-52"
  | "sm:gap-x-52"
  | "xs:gap-x-52"
  | "2xl:gap-x-56"
  | "1xl:gap-x-56"
  | "xl:gap-x-56"
  | "lg:gap-x-56"
  | "md:gap-x-56"
  | "sm:gap-x-56"
  | "xs:gap-x-56"
  | "2xl:gap-x-60"
  | "1xl:gap-x-60"
  | "xl:gap-x-60"
  | "lg:gap-x-60"
  | "md:gap-x-60"
  | "sm:gap-x-60"
  | "xs:gap-x-60"
  | "2xl:gap-x-64"
  | "1xl:gap-x-64"
  | "xl:gap-x-64"
  | "lg:gap-x-64"
  | "md:gap-x-64"
  | "sm:gap-x-64"
  | "xs:gap-x-64"
  | "2xl:gap-x-72"
  | "1xl:gap-x-72"
  | "xl:gap-x-72"
  | "lg:gap-x-72"
  | "md:gap-x-72"
  | "sm:gap-x-72"
  | "xs:gap-x-72"
  | "2xl:gap-x-80"
  | "1xl:gap-x-80"
  | "xl:gap-x-80"
  | "lg:gap-x-80"
  | "md:gap-x-80"
  | "sm:gap-x-80"
  | "xs:gap-x-80"
  | "2xl:gap-x-96"
  | "1xl:gap-x-96"
  | "xl:gap-x-96"
  | "lg:gap-x-96"
  | "md:gap-x-96"
  | "sm:gap-x-96"
  | "xs:gap-x-96"
  | "2xl:gap-x-px"
  | "1xl:gap-x-px"
  | "xl:gap-x-px"
  | "lg:gap-x-px"
  | "md:gap-x-px"
  | "sm:gap-x-px"
  | "xs:gap-x-px"
  | "2xl:gap-x-0.5"
  | "1xl:gap-x-0.5"
  | "xl:gap-x-0.5"
  | "lg:gap-x-0.5"
  | "md:gap-x-0.5"
  | "sm:gap-x-0.5"
  | "xs:gap-x-0.5"
  | "2xl:gap-x-1.5"
  | "1xl:gap-x-1.5"
  | "xl:gap-x-1.5"
  | "lg:gap-x-1.5"
  | "md:gap-x-1.5"
  | "sm:gap-x-1.5"
  | "xs:gap-x-1.5"
  | "2xl:gap-x-2.5"
  | "1xl:gap-x-2.5"
  | "xl:gap-x-2.5"
  | "lg:gap-x-2.5"
  | "md:gap-x-2.5"
  | "sm:gap-x-2.5"
  | "xs:gap-x-2.5"
  | "2xl:gap-x-3.5"
  | "1xl:gap-x-3.5"
  | "xl:gap-x-3.5"
  | "lg:gap-x-3.5"
  | "md:gap-x-3.5"
  | "sm:gap-x-3.5"
  | "xs:gap-x-3.5"
  | "2xl:from-transparent"
  | "1xl:from-transparent"
  | "xl:from-transparent"
  | "lg:from-transparent"
  | "md:from-transparent"
  | "sm:from-transparent"
  | "xs:from-transparent"
  | "hover:from-transparent"
  | "focus:from-transparent"
  | "2xl:via-transparent"
  | "1xl:via-transparent"
  | "xl:via-transparent"
  | "lg:via-transparent"
  | "md:via-transparent"
  | "sm:via-transparent"
  | "xs:via-transparent"
  | "hover:via-transparent"
  | "focus:via-transparent"
  | "2xl:to-transparent"
  | "1xl:to-transparent"
  | "xl:to-transparent"
  | "lg:to-transparent"
  | "md:to-transparent"
  | "sm:to-transparent"
  | "xs:to-transparent"
  | "hover:to-transparent"
  | "focus:to-transparent"
  | "2xl:from-current"
  | "1xl:from-current"
  | "xl:from-current"
  | "lg:from-current"
  | "md:from-current"
  | "sm:from-current"
  | "xs:from-current"
  | "hover:from-current"
  | "focus:from-current"
  | "2xl:via-current"
  | "1xl:via-current"
  | "xl:via-current"
  | "lg:via-current"
  | "md:via-current"
  | "sm:via-current"
  | "xs:via-current"
  | "hover:via-current"
  | "focus:via-current"
  | "2xl:to-current"
  | "1xl:to-current"
  | "xl:to-current"
  | "lg:to-current"
  | "md:to-current"
  | "sm:to-current"
  | "xs:to-current"
  | "hover:to-current"
  | "focus:to-current"
  | "2xl:from-black"
  | "1xl:from-black"
  | "xl:from-black"
  | "lg:from-black"
  | "md:from-black"
  | "sm:from-black"
  | "xs:from-black"
  | "hover:from-black"
  | "focus:from-black"
  | "2xl:via-black"
  | "1xl:via-black"
  | "xl:via-black"
  | "lg:via-black"
  | "md:via-black"
  | "sm:via-black"
  | "xs:via-black"
  | "hover:via-black"
  | "focus:via-black"
  | "2xl:to-black"
  | "1xl:to-black"
  | "xl:to-black"
  | "lg:to-black"
  | "md:to-black"
  | "sm:to-black"
  | "xs:to-black"
  | "hover:to-black"
  | "focus:to-black"
  | "2xl:from-white"
  | "1xl:from-white"
  | "xl:from-white"
  | "lg:from-white"
  | "md:from-white"
  | "sm:from-white"
  | "xs:from-white"
  | "hover:from-white"
  | "focus:from-white"
  | "2xl:via-white"
  | "1xl:via-white"
  | "xl:via-white"
  | "lg:via-white"
  | "md:via-white"
  | "sm:via-white"
  | "xs:via-white"
  | "hover:via-white"
  | "focus:via-white"
  | "2xl:to-white"
  | "1xl:to-white"
  | "xl:to-white"
  | "lg:to-white"
  | "md:to-white"
  | "sm:to-white"
  | "xs:to-white"
  | "hover:to-white"
  | "focus:to-white"
  | "2xl:from-gray-50"
  | "1xl:from-gray-50"
  | "xl:from-gray-50"
  | "lg:from-gray-50"
  | "md:from-gray-50"
  | "sm:from-gray-50"
  | "xs:from-gray-50"
  | "hover:from-gray-50"
  | "focus:from-gray-50"
  | "2xl:via-gray-50"
  | "1xl:via-gray-50"
  | "xl:via-gray-50"
  | "lg:via-gray-50"
  | "md:via-gray-50"
  | "sm:via-gray-50"
  | "xs:via-gray-50"
  | "hover:via-gray-50"
  | "focus:via-gray-50"
  | "2xl:to-gray-50"
  | "1xl:to-gray-50"
  | "xl:to-gray-50"
  | "lg:to-gray-50"
  | "md:to-gray-50"
  | "sm:to-gray-50"
  | "xs:to-gray-50"
  | "hover:to-gray-50"
  | "focus:to-gray-50"
  | "2xl:from-gray-100"
  | "1xl:from-gray-100"
  | "xl:from-gray-100"
  | "lg:from-gray-100"
  | "md:from-gray-100"
  | "sm:from-gray-100"
  | "xs:from-gray-100"
  | "hover:from-gray-100"
  | "focus:from-gray-100"
  | "2xl:via-gray-100"
  | "1xl:via-gray-100"
  | "xl:via-gray-100"
  | "lg:via-gray-100"
  | "md:via-gray-100"
  | "sm:via-gray-100"
  | "xs:via-gray-100"
  | "hover:via-gray-100"
  | "focus:via-gray-100"
  | "2xl:to-gray-100"
  | "1xl:to-gray-100"
  | "xl:to-gray-100"
  | "lg:to-gray-100"
  | "md:to-gray-100"
  | "sm:to-gray-100"
  | "xs:to-gray-100"
  | "hover:to-gray-100"
  | "focus:to-gray-100"
  | "2xl:from-gray-200"
  | "1xl:from-gray-200"
  | "xl:from-gray-200"
  | "lg:from-gray-200"
  | "md:from-gray-200"
  | "sm:from-gray-200"
  | "xs:from-gray-200"
  | "hover:from-gray-200"
  | "focus:from-gray-200"
  | "2xl:via-gray-200"
  | "1xl:via-gray-200"
  | "xl:via-gray-200"
  | "lg:via-gray-200"
  | "md:via-gray-200"
  | "sm:via-gray-200"
  | "xs:via-gray-200"
  | "hover:via-gray-200"
  | "focus:via-gray-200"
  | "2xl:to-gray-200"
  | "1xl:to-gray-200"
  | "xl:to-gray-200"
  | "lg:to-gray-200"
  | "md:to-gray-200"
  | "sm:to-gray-200"
  | "xs:to-gray-200"
  | "hover:to-gray-200"
  | "focus:to-gray-200"
  | "2xl:from-gray-300"
  | "1xl:from-gray-300"
  | "xl:from-gray-300"
  | "lg:from-gray-300"
  | "md:from-gray-300"
  | "sm:from-gray-300"
  | "xs:from-gray-300"
  | "hover:from-gray-300"
  | "focus:from-gray-300"
  | "2xl:via-gray-300"
  | "1xl:via-gray-300"
  | "xl:via-gray-300"
  | "lg:via-gray-300"
  | "md:via-gray-300"
  | "sm:via-gray-300"
  | "xs:via-gray-300"
  | "hover:via-gray-300"
  | "focus:via-gray-300"
  | "2xl:to-gray-300"
  | "1xl:to-gray-300"
  | "xl:to-gray-300"
  | "lg:to-gray-300"
  | "md:to-gray-300"
  | "sm:to-gray-300"
  | "xs:to-gray-300"
  | "hover:to-gray-300"
  | "focus:to-gray-300"
  | "2xl:from-gray-400"
  | "1xl:from-gray-400"
  | "xl:from-gray-400"
  | "lg:from-gray-400"
  | "md:from-gray-400"
  | "sm:from-gray-400"
  | "xs:from-gray-400"
  | "hover:from-gray-400"
  | "focus:from-gray-400"
  | "2xl:via-gray-400"
  | "1xl:via-gray-400"
  | "xl:via-gray-400"
  | "lg:via-gray-400"
  | "md:via-gray-400"
  | "sm:via-gray-400"
  | "xs:via-gray-400"
  | "hover:via-gray-400"
  | "focus:via-gray-400"
  | "2xl:to-gray-400"
  | "1xl:to-gray-400"
  | "xl:to-gray-400"
  | "lg:to-gray-400"
  | "md:to-gray-400"
  | "sm:to-gray-400"
  | "xs:to-gray-400"
  | "hover:to-gray-400"
  | "focus:to-gray-400"
  | "2xl:from-gray-500"
  | "1xl:from-gray-500"
  | "xl:from-gray-500"
  | "lg:from-gray-500"
  | "md:from-gray-500"
  | "sm:from-gray-500"
  | "xs:from-gray-500"
  | "hover:from-gray-500"
  | "focus:from-gray-500"
  | "2xl:via-gray-500"
  | "1xl:via-gray-500"
  | "xl:via-gray-500"
  | "lg:via-gray-500"
  | "md:via-gray-500"
  | "sm:via-gray-500"
  | "xs:via-gray-500"
  | "hover:via-gray-500"
  | "focus:via-gray-500"
  | "2xl:to-gray-500"
  | "1xl:to-gray-500"
  | "xl:to-gray-500"
  | "lg:to-gray-500"
  | "md:to-gray-500"
  | "sm:to-gray-500"
  | "xs:to-gray-500"
  | "hover:to-gray-500"
  | "focus:to-gray-500"
  | "2xl:from-gray-600"
  | "1xl:from-gray-600"
  | "xl:from-gray-600"
  | "lg:from-gray-600"
  | "md:from-gray-600"
  | "sm:from-gray-600"
  | "xs:from-gray-600"
  | "hover:from-gray-600"
  | "focus:from-gray-600"
  | "2xl:via-gray-600"
  | "1xl:via-gray-600"
  | "xl:via-gray-600"
  | "lg:via-gray-600"
  | "md:via-gray-600"
  | "sm:via-gray-600"
  | "xs:via-gray-600"
  | "hover:via-gray-600"
  | "focus:via-gray-600"
  | "2xl:to-gray-600"
  | "1xl:to-gray-600"
  | "xl:to-gray-600"
  | "lg:to-gray-600"
  | "md:to-gray-600"
  | "sm:to-gray-600"
  | "xs:to-gray-600"
  | "hover:to-gray-600"
  | "focus:to-gray-600"
  | "2xl:from-gray-700"
  | "1xl:from-gray-700"
  | "xl:from-gray-700"
  | "lg:from-gray-700"
  | "md:from-gray-700"
  | "sm:from-gray-700"
  | "xs:from-gray-700"
  | "hover:from-gray-700"
  | "focus:from-gray-700"
  | "2xl:via-gray-700"
  | "1xl:via-gray-700"
  | "xl:via-gray-700"
  | "lg:via-gray-700"
  | "md:via-gray-700"
  | "sm:via-gray-700"
  | "xs:via-gray-700"
  | "hover:via-gray-700"
  | "focus:via-gray-700"
  | "2xl:to-gray-700"
  | "1xl:to-gray-700"
  | "xl:to-gray-700"
  | "lg:to-gray-700"
  | "md:to-gray-700"
  | "sm:to-gray-700"
  | "xs:to-gray-700"
  | "hover:to-gray-700"
  | "focus:to-gray-700"
  | "2xl:from-gray-800"
  | "1xl:from-gray-800"
  | "xl:from-gray-800"
  | "lg:from-gray-800"
  | "md:from-gray-800"
  | "sm:from-gray-800"
  | "xs:from-gray-800"
  | "hover:from-gray-800"
  | "focus:from-gray-800"
  | "2xl:via-gray-800"
  | "1xl:via-gray-800"
  | "xl:via-gray-800"
  | "lg:via-gray-800"
  | "md:via-gray-800"
  | "sm:via-gray-800"
  | "xs:via-gray-800"
  | "hover:via-gray-800"
  | "focus:via-gray-800"
  | "2xl:to-gray-800"
  | "1xl:to-gray-800"
  | "xl:to-gray-800"
  | "lg:to-gray-800"
  | "md:to-gray-800"
  | "sm:to-gray-800"
  | "xs:to-gray-800"
  | "hover:to-gray-800"
  | "focus:to-gray-800"
  | "2xl:from-gray-900"
  | "1xl:from-gray-900"
  | "xl:from-gray-900"
  | "lg:from-gray-900"
  | "md:from-gray-900"
  | "sm:from-gray-900"
  | "xs:from-gray-900"
  | "hover:from-gray-900"
  | "focus:from-gray-900"
  | "2xl:via-gray-900"
  | "1xl:via-gray-900"
  | "xl:via-gray-900"
  | "lg:via-gray-900"
  | "md:via-gray-900"
  | "sm:via-gray-900"
  | "xs:via-gray-900"
  | "hover:via-gray-900"
  | "focus:via-gray-900"
  | "2xl:to-gray-900"
  | "1xl:to-gray-900"
  | "xl:to-gray-900"
  | "lg:to-gray-900"
  | "md:to-gray-900"
  | "sm:to-gray-900"
  | "xs:to-gray-900"
  | "hover:to-gray-900"
  | "focus:to-gray-900"
  | "2xl:from-red-50"
  | "1xl:from-red-50"
  | "xl:from-red-50"
  | "lg:from-red-50"
  | "md:from-red-50"
  | "sm:from-red-50"
  | "xs:from-red-50"
  | "hover:from-red-50"
  | "focus:from-red-50"
  | "2xl:via-red-50"
  | "1xl:via-red-50"
  | "xl:via-red-50"
  | "lg:via-red-50"
  | "md:via-red-50"
  | "sm:via-red-50"
  | "xs:via-red-50"
  | "hover:via-red-50"
  | "focus:via-red-50"
  | "2xl:to-red-50"
  | "1xl:to-red-50"
  | "xl:to-red-50"
  | "lg:to-red-50"
  | "md:to-red-50"
  | "sm:to-red-50"
  | "xs:to-red-50"
  | "hover:to-red-50"
  | "focus:to-red-50"
  | "2xl:from-red-100"
  | "1xl:from-red-100"
  | "xl:from-red-100"
  | "lg:from-red-100"
  | "md:from-red-100"
  | "sm:from-red-100"
  | "xs:from-red-100"
  | "hover:from-red-100"
  | "focus:from-red-100"
  | "2xl:via-red-100"
  | "1xl:via-red-100"
  | "xl:via-red-100"
  | "lg:via-red-100"
  | "md:via-red-100"
  | "sm:via-red-100"
  | "xs:via-red-100"
  | "hover:via-red-100"
  | "focus:via-red-100"
  | "2xl:to-red-100"
  | "1xl:to-red-100"
  | "xl:to-red-100"
  | "lg:to-red-100"
  | "md:to-red-100"
  | "sm:to-red-100"
  | "xs:to-red-100"
  | "hover:to-red-100"
  | "focus:to-red-100"
  | "2xl:from-red-200"
  | "1xl:from-red-200"
  | "xl:from-red-200"
  | "lg:from-red-200"
  | "md:from-red-200"
  | "sm:from-red-200"
  | "xs:from-red-200"
  | "hover:from-red-200"
  | "focus:from-red-200"
  | "2xl:via-red-200"
  | "1xl:via-red-200"
  | "xl:via-red-200"
  | "lg:via-red-200"
  | "md:via-red-200"
  | "sm:via-red-200"
  | "xs:via-red-200"
  | "hover:via-red-200"
  | "focus:via-red-200"
  | "2xl:to-red-200"
  | "1xl:to-red-200"
  | "xl:to-red-200"
  | "lg:to-red-200"
  | "md:to-red-200"
  | "sm:to-red-200"
  | "xs:to-red-200"
  | "hover:to-red-200"
  | "focus:to-red-200"
  | "2xl:from-red-300"
  | "1xl:from-red-300"
  | "xl:from-red-300"
  | "lg:from-red-300"
  | "md:from-red-300"
  | "sm:from-red-300"
  | "xs:from-red-300"
  | "hover:from-red-300"
  | "focus:from-red-300"
  | "2xl:via-red-300"
  | "1xl:via-red-300"
  | "xl:via-red-300"
  | "lg:via-red-300"
  | "md:via-red-300"
  | "sm:via-red-300"
  | "xs:via-red-300"
  | "hover:via-red-300"
  | "focus:via-red-300"
  | "2xl:to-red-300"
  | "1xl:to-red-300"
  | "xl:to-red-300"
  | "lg:to-red-300"
  | "md:to-red-300"
  | "sm:to-red-300"
  | "xs:to-red-300"
  | "hover:to-red-300"
  | "focus:to-red-300"
  | "2xl:from-red-400"
  | "1xl:from-red-400"
  | "xl:from-red-400"
  | "lg:from-red-400"
  | "md:from-red-400"
  | "sm:from-red-400"
  | "xs:from-red-400"
  | "hover:from-red-400"
  | "focus:from-red-400"
  | "2xl:via-red-400"
  | "1xl:via-red-400"
  | "xl:via-red-400"
  | "lg:via-red-400"
  | "md:via-red-400"
  | "sm:via-red-400"
  | "xs:via-red-400"
  | "hover:via-red-400"
  | "focus:via-red-400"
  | "2xl:to-red-400"
  | "1xl:to-red-400"
  | "xl:to-red-400"
  | "lg:to-red-400"
  | "md:to-red-400"
  | "sm:to-red-400"
  | "xs:to-red-400"
  | "hover:to-red-400"
  | "focus:to-red-400"
  | "2xl:from-red-500"
  | "1xl:from-red-500"
  | "xl:from-red-500"
  | "lg:from-red-500"
  | "md:from-red-500"
  | "sm:from-red-500"
  | "xs:from-red-500"
  | "hover:from-red-500"
  | "focus:from-red-500"
  | "2xl:via-red-500"
  | "1xl:via-red-500"
  | "xl:via-red-500"
  | "lg:via-red-500"
  | "md:via-red-500"
  | "sm:via-red-500"
  | "xs:via-red-500"
  | "hover:via-red-500"
  | "focus:via-red-500"
  | "2xl:to-red-500"
  | "1xl:to-red-500"
  | "xl:to-red-500"
  | "lg:to-red-500"
  | "md:to-red-500"
  | "sm:to-red-500"
  | "xs:to-red-500"
  | "hover:to-red-500"
  | "focus:to-red-500"
  | "2xl:from-red-600"
  | "1xl:from-red-600"
  | "xl:from-red-600"
  | "lg:from-red-600"
  | "md:from-red-600"
  | "sm:from-red-600"
  | "xs:from-red-600"
  | "hover:from-red-600"
  | "focus:from-red-600"
  | "2xl:via-red-600"
  | "1xl:via-red-600"
  | "xl:via-red-600"
  | "lg:via-red-600"
  | "md:via-red-600"
  | "sm:via-red-600"
  | "xs:via-red-600"
  | "hover:via-red-600"
  | "focus:via-red-600"
  | "2xl:to-red-600"
  | "1xl:to-red-600"
  | "xl:to-red-600"
  | "lg:to-red-600"
  | "md:to-red-600"
  | "sm:to-red-600"
  | "xs:to-red-600"
  | "hover:to-red-600"
  | "focus:to-red-600"
  | "2xl:from-red-700"
  | "1xl:from-red-700"
  | "xl:from-red-700"
  | "lg:from-red-700"
  | "md:from-red-700"
  | "sm:from-red-700"
  | "xs:from-red-700"
  | "hover:from-red-700"
  | "focus:from-red-700"
  | "2xl:via-red-700"
  | "1xl:via-red-700"
  | "xl:via-red-700"
  | "lg:via-red-700"
  | "md:via-red-700"
  | "sm:via-red-700"
  | "xs:via-red-700"
  | "hover:via-red-700"
  | "focus:via-red-700"
  | "2xl:to-red-700"
  | "1xl:to-red-700"
  | "xl:to-red-700"
  | "lg:to-red-700"
  | "md:to-red-700"
  | "sm:to-red-700"
  | "xs:to-red-700"
  | "hover:to-red-700"
  | "focus:to-red-700"
  | "2xl:from-red-800"
  | "1xl:from-red-800"
  | "xl:from-red-800"
  | "lg:from-red-800"
  | "md:from-red-800"
  | "sm:from-red-800"
  | "xs:from-red-800"
  | "hover:from-red-800"
  | "focus:from-red-800"
  | "2xl:via-red-800"
  | "1xl:via-red-800"
  | "xl:via-red-800"
  | "lg:via-red-800"
  | "md:via-red-800"
  | "sm:via-red-800"
  | "xs:via-red-800"
  | "hover:via-red-800"
  | "focus:via-red-800"
  | "2xl:to-red-800"
  | "1xl:to-red-800"
  | "xl:to-red-800"
  | "lg:to-red-800"
  | "md:to-red-800"
  | "sm:to-red-800"
  | "xs:to-red-800"
  | "hover:to-red-800"
  | "focus:to-red-800"
  | "2xl:from-red-900"
  | "1xl:from-red-900"
  | "xl:from-red-900"
  | "lg:from-red-900"
  | "md:from-red-900"
  | "sm:from-red-900"
  | "xs:from-red-900"
  | "hover:from-red-900"
  | "focus:from-red-900"
  | "2xl:via-red-900"
  | "1xl:via-red-900"
  | "xl:via-red-900"
  | "lg:via-red-900"
  | "md:via-red-900"
  | "sm:via-red-900"
  | "xs:via-red-900"
  | "hover:via-red-900"
  | "focus:via-red-900"
  | "2xl:to-red-900"
  | "1xl:to-red-900"
  | "xl:to-red-900"
  | "lg:to-red-900"
  | "md:to-red-900"
  | "sm:to-red-900"
  | "xs:to-red-900"
  | "hover:to-red-900"
  | "focus:to-red-900"
  | "2xl:from-yellow-50"
  | "1xl:from-yellow-50"
  | "xl:from-yellow-50"
  | "lg:from-yellow-50"
  | "md:from-yellow-50"
  | "sm:from-yellow-50"
  | "xs:from-yellow-50"
  | "hover:from-yellow-50"
  | "focus:from-yellow-50"
  | "2xl:via-yellow-50"
  | "1xl:via-yellow-50"
  | "xl:via-yellow-50"
  | "lg:via-yellow-50"
  | "md:via-yellow-50"
  | "sm:via-yellow-50"
  | "xs:via-yellow-50"
  | "hover:via-yellow-50"
  | "focus:via-yellow-50"
  | "2xl:to-yellow-50"
  | "1xl:to-yellow-50"
  | "xl:to-yellow-50"
  | "lg:to-yellow-50"
  | "md:to-yellow-50"
  | "sm:to-yellow-50"
  | "xs:to-yellow-50"
  | "hover:to-yellow-50"
  | "focus:to-yellow-50"
  | "2xl:from-yellow-100"
  | "1xl:from-yellow-100"
  | "xl:from-yellow-100"
  | "lg:from-yellow-100"
  | "md:from-yellow-100"
  | "sm:from-yellow-100"
  | "xs:from-yellow-100"
  | "hover:from-yellow-100"
  | "focus:from-yellow-100"
  | "2xl:via-yellow-100"
  | "1xl:via-yellow-100"
  | "xl:via-yellow-100"
  | "lg:via-yellow-100"
  | "md:via-yellow-100"
  | "sm:via-yellow-100"
  | "xs:via-yellow-100"
  | "hover:via-yellow-100"
  | "focus:via-yellow-100"
  | "2xl:to-yellow-100"
  | "1xl:to-yellow-100"
  | "xl:to-yellow-100"
  | "lg:to-yellow-100"
  | "md:to-yellow-100"
  | "sm:to-yellow-100"
  | "xs:to-yellow-100"
  | "hover:to-yellow-100"
  | "focus:to-yellow-100"
  | "2xl:from-yellow-200"
  | "1xl:from-yellow-200"
  | "xl:from-yellow-200"
  | "lg:from-yellow-200"
  | "md:from-yellow-200"
  | "sm:from-yellow-200"
  | "xs:from-yellow-200"
  | "hover:from-yellow-200"
  | "focus:from-yellow-200"
  | "2xl:via-yellow-200"
  | "1xl:via-yellow-200"
  | "xl:via-yellow-200"
  | "lg:via-yellow-200"
  | "md:via-yellow-200"
  | "sm:via-yellow-200"
  | "xs:via-yellow-200"
  | "hover:via-yellow-200"
  | "focus:via-yellow-200"
  | "2xl:to-yellow-200"
  | "1xl:to-yellow-200"
  | "xl:to-yellow-200"
  | "lg:to-yellow-200"
  | "md:to-yellow-200"
  | "sm:to-yellow-200"
  | "xs:to-yellow-200"
  | "hover:to-yellow-200"
  | "focus:to-yellow-200"
  | "2xl:from-yellow-300"
  | "1xl:from-yellow-300"
  | "xl:from-yellow-300"
  | "lg:from-yellow-300"
  | "md:from-yellow-300"
  | "sm:from-yellow-300"
  | "xs:from-yellow-300"
  | "hover:from-yellow-300"
  | "focus:from-yellow-300"
  | "2xl:via-yellow-300"
  | "1xl:via-yellow-300"
  | "xl:via-yellow-300"
  | "lg:via-yellow-300"
  | "md:via-yellow-300"
  | "sm:via-yellow-300"
  | "xs:via-yellow-300"
  | "hover:via-yellow-300"
  | "focus:via-yellow-300"
  | "2xl:to-yellow-300"
  | "1xl:to-yellow-300"
  | "xl:to-yellow-300"
  | "lg:to-yellow-300"
  | "md:to-yellow-300"
  | "sm:to-yellow-300"
  | "xs:to-yellow-300"
  | "hover:to-yellow-300"
  | "focus:to-yellow-300"
  | "2xl:from-yellow-400"
  | "1xl:from-yellow-400"
  | "xl:from-yellow-400"
  | "lg:from-yellow-400"
  | "md:from-yellow-400"
  | "sm:from-yellow-400"
  | "xs:from-yellow-400"
  | "hover:from-yellow-400"
  | "focus:from-yellow-400"
  | "2xl:via-yellow-400"
  | "1xl:via-yellow-400"
  | "xl:via-yellow-400"
  | "lg:via-yellow-400"
  | "md:via-yellow-400"
  | "sm:via-yellow-400"
  | "xs:via-yellow-400"
  | "hover:via-yellow-400"
  | "focus:via-yellow-400"
  | "2xl:to-yellow-400"
  | "1xl:to-yellow-400"
  | "xl:to-yellow-400"
  | "lg:to-yellow-400"
  | "md:to-yellow-400"
  | "sm:to-yellow-400"
  | "xs:to-yellow-400"
  | "hover:to-yellow-400"
  | "focus:to-yellow-400"
  | "2xl:from-yellow-500"
  | "1xl:from-yellow-500"
  | "xl:from-yellow-500"
  | "lg:from-yellow-500"
  | "md:from-yellow-500"
  | "sm:from-yellow-500"
  | "xs:from-yellow-500"
  | "hover:from-yellow-500"
  | "focus:from-yellow-500"
  | "2xl:via-yellow-500"
  | "1xl:via-yellow-500"
  | "xl:via-yellow-500"
  | "lg:via-yellow-500"
  | "md:via-yellow-500"
  | "sm:via-yellow-500"
  | "xs:via-yellow-500"
  | "hover:via-yellow-500"
  | "focus:via-yellow-500"
  | "2xl:to-yellow-500"
  | "1xl:to-yellow-500"
  | "xl:to-yellow-500"
  | "lg:to-yellow-500"
  | "md:to-yellow-500"
  | "sm:to-yellow-500"
  | "xs:to-yellow-500"
  | "hover:to-yellow-500"
  | "focus:to-yellow-500"
  | "2xl:from-yellow-600"
  | "1xl:from-yellow-600"
  | "xl:from-yellow-600"
  | "lg:from-yellow-600"
  | "md:from-yellow-600"
  | "sm:from-yellow-600"
  | "xs:from-yellow-600"
  | "hover:from-yellow-600"
  | "focus:from-yellow-600"
  | "2xl:via-yellow-600"
  | "1xl:via-yellow-600"
  | "xl:via-yellow-600"
  | "lg:via-yellow-600"
  | "md:via-yellow-600"
  | "sm:via-yellow-600"
  | "xs:via-yellow-600"
  | "hover:via-yellow-600"
  | "focus:via-yellow-600"
  | "2xl:to-yellow-600"
  | "1xl:to-yellow-600"
  | "xl:to-yellow-600"
  | "lg:to-yellow-600"
  | "md:to-yellow-600"
  | "sm:to-yellow-600"
  | "xs:to-yellow-600"
  | "hover:to-yellow-600"
  | "focus:to-yellow-600"
  | "2xl:from-yellow-700"
  | "1xl:from-yellow-700"
  | "xl:from-yellow-700"
  | "lg:from-yellow-700"
  | "md:from-yellow-700"
  | "sm:from-yellow-700"
  | "xs:from-yellow-700"
  | "hover:from-yellow-700"
  | "focus:from-yellow-700"
  | "2xl:via-yellow-700"
  | "1xl:via-yellow-700"
  | "xl:via-yellow-700"
  | "lg:via-yellow-700"
  | "md:via-yellow-700"
  | "sm:via-yellow-700"
  | "xs:via-yellow-700"
  | "hover:via-yellow-700"
  | "focus:via-yellow-700"
  | "2xl:to-yellow-700"
  | "1xl:to-yellow-700"
  | "xl:to-yellow-700"
  | "lg:to-yellow-700"
  | "md:to-yellow-700"
  | "sm:to-yellow-700"
  | "xs:to-yellow-700"
  | "hover:to-yellow-700"
  | "focus:to-yellow-700"
  | "2xl:from-yellow-800"
  | "1xl:from-yellow-800"
  | "xl:from-yellow-800"
  | "lg:from-yellow-800"
  | "md:from-yellow-800"
  | "sm:from-yellow-800"
  | "xs:from-yellow-800"
  | "hover:from-yellow-800"
  | "focus:from-yellow-800"
  | "2xl:via-yellow-800"
  | "1xl:via-yellow-800"
  | "xl:via-yellow-800"
  | "lg:via-yellow-800"
  | "md:via-yellow-800"
  | "sm:via-yellow-800"
  | "xs:via-yellow-800"
  | "hover:via-yellow-800"
  | "focus:via-yellow-800"
  | "2xl:to-yellow-800"
  | "1xl:to-yellow-800"
  | "xl:to-yellow-800"
  | "lg:to-yellow-800"
  | "md:to-yellow-800"
  | "sm:to-yellow-800"
  | "xs:to-yellow-800"
  | "hover:to-yellow-800"
  | "focus:to-yellow-800"
  | "2xl:from-yellow-900"
  | "1xl:from-yellow-900"
  | "xl:from-yellow-900"
  | "lg:from-yellow-900"
  | "md:from-yellow-900"
  | "sm:from-yellow-900"
  | "xs:from-yellow-900"
  | "hover:from-yellow-900"
  | "focus:from-yellow-900"
  | "2xl:via-yellow-900"
  | "1xl:via-yellow-900"
  | "xl:via-yellow-900"
  | "lg:via-yellow-900"
  | "md:via-yellow-900"
  | "sm:via-yellow-900"
  | "xs:via-yellow-900"
  | "hover:via-yellow-900"
  | "focus:via-yellow-900"
  | "2xl:to-yellow-900"
  | "1xl:to-yellow-900"
  | "xl:to-yellow-900"
  | "lg:to-yellow-900"
  | "md:to-yellow-900"
  | "sm:to-yellow-900"
  | "xs:to-yellow-900"
  | "hover:to-yellow-900"
  | "focus:to-yellow-900"
  | "2xl:from-green-50"
  | "1xl:from-green-50"
  | "xl:from-green-50"
  | "lg:from-green-50"
  | "md:from-green-50"
  | "sm:from-green-50"
  | "xs:from-green-50"
  | "hover:from-green-50"
  | "focus:from-green-50"
  | "2xl:via-green-50"
  | "1xl:via-green-50"
  | "xl:via-green-50"
  | "lg:via-green-50"
  | "md:via-green-50"
  | "sm:via-green-50"
  | "xs:via-green-50"
  | "hover:via-green-50"
  | "focus:via-green-50"
  | "2xl:to-green-50"
  | "1xl:to-green-50"
  | "xl:to-green-50"
  | "lg:to-green-50"
  | "md:to-green-50"
  | "sm:to-green-50"
  | "xs:to-green-50"
  | "hover:to-green-50"
  | "focus:to-green-50"
  | "2xl:from-green-100"
  | "1xl:from-green-100"
  | "xl:from-green-100"
  | "lg:from-green-100"
  | "md:from-green-100"
  | "sm:from-green-100"
  | "xs:from-green-100"
  | "hover:from-green-100"
  | "focus:from-green-100"
  | "2xl:via-green-100"
  | "1xl:via-green-100"
  | "xl:via-green-100"
  | "lg:via-green-100"
  | "md:via-green-100"
  | "sm:via-green-100"
  | "xs:via-green-100"
  | "hover:via-green-100"
  | "focus:via-green-100"
  | "2xl:to-green-100"
  | "1xl:to-green-100"
  | "xl:to-green-100"
  | "lg:to-green-100"
  | "md:to-green-100"
  | "sm:to-green-100"
  | "xs:to-green-100"
  | "hover:to-green-100"
  | "focus:to-green-100"
  | "2xl:from-green-200"
  | "1xl:from-green-200"
  | "xl:from-green-200"
  | "lg:from-green-200"
  | "md:from-green-200"
  | "sm:from-green-200"
  | "xs:from-green-200"
  | "hover:from-green-200"
  | "focus:from-green-200"
  | "2xl:via-green-200"
  | "1xl:via-green-200"
  | "xl:via-green-200"
  | "lg:via-green-200"
  | "md:via-green-200"
  | "sm:via-green-200"
  | "xs:via-green-200"
  | "hover:via-green-200"
  | "focus:via-green-200"
  | "2xl:to-green-200"
  | "1xl:to-green-200"
  | "xl:to-green-200"
  | "lg:to-green-200"
  | "md:to-green-200"
  | "sm:to-green-200"
  | "xs:to-green-200"
  | "hover:to-green-200"
  | "focus:to-green-200"
  | "2xl:from-green-300"
  | "1xl:from-green-300"
  | "xl:from-green-300"
  | "lg:from-green-300"
  | "md:from-green-300"
  | "sm:from-green-300"
  | "xs:from-green-300"
  | "hover:from-green-300"
  | "focus:from-green-300"
  | "2xl:via-green-300"
  | "1xl:via-green-300"
  | "xl:via-green-300"
  | "lg:via-green-300"
  | "md:via-green-300"
  | "sm:via-green-300"
  | "xs:via-green-300"
  | "hover:via-green-300"
  | "focus:via-green-300"
  | "2xl:to-green-300"
  | "1xl:to-green-300"
  | "xl:to-green-300"
  | "lg:to-green-300"
  | "md:to-green-300"
  | "sm:to-green-300"
  | "xs:to-green-300"
  | "hover:to-green-300"
  | "focus:to-green-300"
  | "2xl:from-green-400"
  | "1xl:from-green-400"
  | "xl:from-green-400"
  | "lg:from-green-400"
  | "md:from-green-400"
  | "sm:from-green-400"
  | "xs:from-green-400"
  | "hover:from-green-400"
  | "focus:from-green-400"
  | "2xl:via-green-400"
  | "1xl:via-green-400"
  | "xl:via-green-400"
  | "lg:via-green-400"
  | "md:via-green-400"
  | "sm:via-green-400"
  | "xs:via-green-400"
  | "hover:via-green-400"
  | "focus:via-green-400"
  | "2xl:to-green-400"
  | "1xl:to-green-400"
  | "xl:to-green-400"
  | "lg:to-green-400"
  | "md:to-green-400"
  | "sm:to-green-400"
  | "xs:to-green-400"
  | "hover:to-green-400"
  | "focus:to-green-400"
  | "2xl:from-green-500"
  | "1xl:from-green-500"
  | "xl:from-green-500"
  | "lg:from-green-500"
  | "md:from-green-500"
  | "sm:from-green-500"
  | "xs:from-green-500"
  | "hover:from-green-500"
  | "focus:from-green-500"
  | "2xl:via-green-500"
  | "1xl:via-green-500"
  | "xl:via-green-500"
  | "lg:via-green-500"
  | "md:via-green-500"
  | "sm:via-green-500"
  | "xs:via-green-500"
  | "hover:via-green-500"
  | "focus:via-green-500"
  | "2xl:to-green-500"
  | "1xl:to-green-500"
  | "xl:to-green-500"
  | "lg:to-green-500"
  | "md:to-green-500"
  | "sm:to-green-500"
  | "xs:to-green-500"
  | "hover:to-green-500"
  | "focus:to-green-500"
  | "2xl:from-green-600"
  | "1xl:from-green-600"
  | "xl:from-green-600"
  | "lg:from-green-600"
  | "md:from-green-600"
  | "sm:from-green-600"
  | "xs:from-green-600"
  | "hover:from-green-600"
  | "focus:from-green-600"
  | "2xl:via-green-600"
  | "1xl:via-green-600"
  | "xl:via-green-600"
  | "lg:via-green-600"
  | "md:via-green-600"
  | "sm:via-green-600"
  | "xs:via-green-600"
  | "hover:via-green-600"
  | "focus:via-green-600"
  | "2xl:to-green-600"
  | "1xl:to-green-600"
  | "xl:to-green-600"
  | "lg:to-green-600"
  | "md:to-green-600"
  | "sm:to-green-600"
  | "xs:to-green-600"
  | "hover:to-green-600"
  | "focus:to-green-600"
  | "2xl:from-green-700"
  | "1xl:from-green-700"
  | "xl:from-green-700"
  | "lg:from-green-700"
  | "md:from-green-700"
  | "sm:from-green-700"
  | "xs:from-green-700"
  | "hover:from-green-700"
  | "focus:from-green-700"
  | "2xl:via-green-700"
  | "1xl:via-green-700"
  | "xl:via-green-700"
  | "lg:via-green-700"
  | "md:via-green-700"
  | "sm:via-green-700"
  | "xs:via-green-700"
  | "hover:via-green-700"
  | "focus:via-green-700"
  | "2xl:to-green-700"
  | "1xl:to-green-700"
  | "xl:to-green-700"
  | "lg:to-green-700"
  | "md:to-green-700"
  | "sm:to-green-700"
  | "xs:to-green-700"
  | "hover:to-green-700"
  | "focus:to-green-700"
  | "2xl:from-green-800"
  | "1xl:from-green-800"
  | "xl:from-green-800"
  | "lg:from-green-800"
  | "md:from-green-800"
  | "sm:from-green-800"
  | "xs:from-green-800"
  | "hover:from-green-800"
  | "focus:from-green-800"
  | "2xl:via-green-800"
  | "1xl:via-green-800"
  | "xl:via-green-800"
  | "lg:via-green-800"
  | "md:via-green-800"
  | "sm:via-green-800"
  | "xs:via-green-800"
  | "hover:via-green-800"
  | "focus:via-green-800"
  | "2xl:to-green-800"
  | "1xl:to-green-800"
  | "xl:to-green-800"
  | "lg:to-green-800"
  | "md:to-green-800"
  | "sm:to-green-800"
  | "xs:to-green-800"
  | "hover:to-green-800"
  | "focus:to-green-800"
  | "2xl:from-green-900"
  | "1xl:from-green-900"
  | "xl:from-green-900"
  | "lg:from-green-900"
  | "md:from-green-900"
  | "sm:from-green-900"
  | "xs:from-green-900"
  | "hover:from-green-900"
  | "focus:from-green-900"
  | "2xl:via-green-900"
  | "1xl:via-green-900"
  | "xl:via-green-900"
  | "lg:via-green-900"
  | "md:via-green-900"
  | "sm:via-green-900"
  | "xs:via-green-900"
  | "hover:via-green-900"
  | "focus:via-green-900"
  | "2xl:to-green-900"
  | "1xl:to-green-900"
  | "xl:to-green-900"
  | "lg:to-green-900"
  | "md:to-green-900"
  | "sm:to-green-900"
  | "xs:to-green-900"
  | "hover:to-green-900"
  | "focus:to-green-900"
  | "2xl:from-blue-50"
  | "1xl:from-blue-50"
  | "xl:from-blue-50"
  | "lg:from-blue-50"
  | "md:from-blue-50"
  | "sm:from-blue-50"
  | "xs:from-blue-50"
  | "hover:from-blue-50"
  | "focus:from-blue-50"
  | "2xl:via-blue-50"
  | "1xl:via-blue-50"
  | "xl:via-blue-50"
  | "lg:via-blue-50"
  | "md:via-blue-50"
  | "sm:via-blue-50"
  | "xs:via-blue-50"
  | "hover:via-blue-50"
  | "focus:via-blue-50"
  | "2xl:to-blue-50"
  | "1xl:to-blue-50"
  | "xl:to-blue-50"
  | "lg:to-blue-50"
  | "md:to-blue-50"
  | "sm:to-blue-50"
  | "xs:to-blue-50"
  | "hover:to-blue-50"
  | "focus:to-blue-50"
  | "2xl:from-blue-100"
  | "1xl:from-blue-100"
  | "xl:from-blue-100"
  | "lg:from-blue-100"
  | "md:from-blue-100"
  | "sm:from-blue-100"
  | "xs:from-blue-100"
  | "hover:from-blue-100"
  | "focus:from-blue-100"
  | "2xl:via-blue-100"
  | "1xl:via-blue-100"
  | "xl:via-blue-100"
  | "lg:via-blue-100"
  | "md:via-blue-100"
  | "sm:via-blue-100"
  | "xs:via-blue-100"
  | "hover:via-blue-100"
  | "focus:via-blue-100"
  | "2xl:to-blue-100"
  | "1xl:to-blue-100"
  | "xl:to-blue-100"
  | "lg:to-blue-100"
  | "md:to-blue-100"
  | "sm:to-blue-100"
  | "xs:to-blue-100"
  | "hover:to-blue-100"
  | "focus:to-blue-100"
  | "2xl:from-blue-200"
  | "1xl:from-blue-200"
  | "xl:from-blue-200"
  | "lg:from-blue-200"
  | "md:from-blue-200"
  | "sm:from-blue-200"
  | "xs:from-blue-200"
  | "hover:from-blue-200"
  | "focus:from-blue-200"
  | "2xl:via-blue-200"
  | "1xl:via-blue-200"
  | "xl:via-blue-200"
  | "lg:via-blue-200"
  | "md:via-blue-200"
  | "sm:via-blue-200"
  | "xs:via-blue-200"
  | "hover:via-blue-200"
  | "focus:via-blue-200"
  | "2xl:to-blue-200"
  | "1xl:to-blue-200"
  | "xl:to-blue-200"
  | "lg:to-blue-200"
  | "md:to-blue-200"
  | "sm:to-blue-200"
  | "xs:to-blue-200"
  | "hover:to-blue-200"
  | "focus:to-blue-200"
  | "2xl:from-blue-300"
  | "1xl:from-blue-300"
  | "xl:from-blue-300"
  | "lg:from-blue-300"
  | "md:from-blue-300"
  | "sm:from-blue-300"
  | "xs:from-blue-300"
  | "hover:from-blue-300"
  | "focus:from-blue-300"
  | "2xl:via-blue-300"
  | "1xl:via-blue-300"
  | "xl:via-blue-300"
  | "lg:via-blue-300"
  | "md:via-blue-300"
  | "sm:via-blue-300"
  | "xs:via-blue-300"
  | "hover:via-blue-300"
  | "focus:via-blue-300"
  | "2xl:to-blue-300"
  | "1xl:to-blue-300"
  | "xl:to-blue-300"
  | "lg:to-blue-300"
  | "md:to-blue-300"
  | "sm:to-blue-300"
  | "xs:to-blue-300"
  | "hover:to-blue-300"
  | "focus:to-blue-300"
  | "2xl:from-blue-400"
  | "1xl:from-blue-400"
  | "xl:from-blue-400"
  | "lg:from-blue-400"
  | "md:from-blue-400"
  | "sm:from-blue-400"
  | "xs:from-blue-400"
  | "hover:from-blue-400"
  | "focus:from-blue-400"
  | "2xl:via-blue-400"
  | "1xl:via-blue-400"
  | "xl:via-blue-400"
  | "lg:via-blue-400"
  | "md:via-blue-400"
  | "sm:via-blue-400"
  | "xs:via-blue-400"
  | "hover:via-blue-400"
  | "focus:via-blue-400"
  | "2xl:to-blue-400"
  | "1xl:to-blue-400"
  | "xl:to-blue-400"
  | "lg:to-blue-400"
  | "md:to-blue-400"
  | "sm:to-blue-400"
  | "xs:to-blue-400"
  | "hover:to-blue-400"
  | "focus:to-blue-400"
  | "2xl:from-blue-500"
  | "1xl:from-blue-500"
  | "xl:from-blue-500"
  | "lg:from-blue-500"
  | "md:from-blue-500"
  | "sm:from-blue-500"
  | "xs:from-blue-500"
  | "hover:from-blue-500"
  | "focus:from-blue-500"
  | "2xl:via-blue-500"
  | "1xl:via-blue-500"
  | "xl:via-blue-500"
  | "lg:via-blue-500"
  | "md:via-blue-500"
  | "sm:via-blue-500"
  | "xs:via-blue-500"
  | "hover:via-blue-500"
  | "focus:via-blue-500"
  | "2xl:to-blue-500"
  | "1xl:to-blue-500"
  | "xl:to-blue-500"
  | "lg:to-blue-500"
  | "md:to-blue-500"
  | "sm:to-blue-500"
  | "xs:to-blue-500"
  | "hover:to-blue-500"
  | "focus:to-blue-500"
  | "2xl:from-blue-600"
  | "1xl:from-blue-600"
  | "xl:from-blue-600"
  | "lg:from-blue-600"
  | "md:from-blue-600"
  | "sm:from-blue-600"
  | "xs:from-blue-600"
  | "hover:from-blue-600"
  | "focus:from-blue-600"
  | "2xl:via-blue-600"
  | "1xl:via-blue-600"
  | "xl:via-blue-600"
  | "lg:via-blue-600"
  | "md:via-blue-600"
  | "sm:via-blue-600"
  | "xs:via-blue-600"
  | "hover:via-blue-600"
  | "focus:via-blue-600"
  | "2xl:to-blue-600"
  | "1xl:to-blue-600"
  | "xl:to-blue-600"
  | "lg:to-blue-600"
  | "md:to-blue-600"
  | "sm:to-blue-600"
  | "xs:to-blue-600"
  | "hover:to-blue-600"
  | "focus:to-blue-600"
  | "2xl:from-blue-700"
  | "1xl:from-blue-700"
  | "xl:from-blue-700"
  | "lg:from-blue-700"
  | "md:from-blue-700"
  | "sm:from-blue-700"
  | "xs:from-blue-700"
  | "hover:from-blue-700"
  | "focus:from-blue-700"
  | "2xl:via-blue-700"
  | "1xl:via-blue-700"
  | "xl:via-blue-700"
  | "lg:via-blue-700"
  | "md:via-blue-700"
  | "sm:via-blue-700"
  | "xs:via-blue-700"
  | "hover:via-blue-700"
  | "focus:via-blue-700"
  | "2xl:to-blue-700"
  | "1xl:to-blue-700"
  | "xl:to-blue-700"
  | "lg:to-blue-700"
  | "md:to-blue-700"
  | "sm:to-blue-700"
  | "xs:to-blue-700"
  | "hover:to-blue-700"
  | "focus:to-blue-700"
  | "2xl:from-blue-800"
  | "1xl:from-blue-800"
  | "xl:from-blue-800"
  | "lg:from-blue-800"
  | "md:from-blue-800"
  | "sm:from-blue-800"
  | "xs:from-blue-800"
  | "hover:from-blue-800"
  | "focus:from-blue-800"
  | "2xl:via-blue-800"
  | "1xl:via-blue-800"
  | "xl:via-blue-800"
  | "lg:via-blue-800"
  | "md:via-blue-800"
  | "sm:via-blue-800"
  | "xs:via-blue-800"
  | "hover:via-blue-800"
  | "focus:via-blue-800"
  | "2xl:to-blue-800"
  | "1xl:to-blue-800"
  | "xl:to-blue-800"
  | "lg:to-blue-800"
  | "md:to-blue-800"
  | "sm:to-blue-800"
  | "xs:to-blue-800"
  | "hover:to-blue-800"
  | "focus:to-blue-800"
  | "2xl:from-blue-900"
  | "1xl:from-blue-900"
  | "xl:from-blue-900"
  | "lg:from-blue-900"
  | "md:from-blue-900"
  | "sm:from-blue-900"
  | "xs:from-blue-900"
  | "hover:from-blue-900"
  | "focus:from-blue-900"
  | "2xl:via-blue-900"
  | "1xl:via-blue-900"
  | "xl:via-blue-900"
  | "lg:via-blue-900"
  | "md:via-blue-900"
  | "sm:via-blue-900"
  | "xs:via-blue-900"
  | "hover:via-blue-900"
  | "focus:via-blue-900"
  | "2xl:to-blue-900"
  | "1xl:to-blue-900"
  | "xl:to-blue-900"
  | "lg:to-blue-900"
  | "md:to-blue-900"
  | "sm:to-blue-900"
  | "xs:to-blue-900"
  | "hover:to-blue-900"
  | "focus:to-blue-900"
  | "2xl:from-indigo-50"
  | "1xl:from-indigo-50"
  | "xl:from-indigo-50"
  | "lg:from-indigo-50"
  | "md:from-indigo-50"
  | "sm:from-indigo-50"
  | "xs:from-indigo-50"
  | "hover:from-indigo-50"
  | "focus:from-indigo-50"
  | "2xl:via-indigo-50"
  | "1xl:via-indigo-50"
  | "xl:via-indigo-50"
  | "lg:via-indigo-50"
  | "md:via-indigo-50"
  | "sm:via-indigo-50"
  | "xs:via-indigo-50"
  | "hover:via-indigo-50"
  | "focus:via-indigo-50"
  | "2xl:to-indigo-50"
  | "1xl:to-indigo-50"
  | "xl:to-indigo-50"
  | "lg:to-indigo-50"
  | "md:to-indigo-50"
  | "sm:to-indigo-50"
  | "xs:to-indigo-50"
  | "hover:to-indigo-50"
  | "focus:to-indigo-50"
  | "2xl:from-indigo-100"
  | "1xl:from-indigo-100"
  | "xl:from-indigo-100"
  | "lg:from-indigo-100"
  | "md:from-indigo-100"
  | "sm:from-indigo-100"
  | "xs:from-indigo-100"
  | "hover:from-indigo-100"
  | "focus:from-indigo-100"
  | "2xl:via-indigo-100"
  | "1xl:via-indigo-100"
  | "xl:via-indigo-100"
  | "lg:via-indigo-100"
  | "md:via-indigo-100"
  | "sm:via-indigo-100"
  | "xs:via-indigo-100"
  | "hover:via-indigo-100"
  | "focus:via-indigo-100"
  | "2xl:to-indigo-100"
  | "1xl:to-indigo-100"
  | "xl:to-indigo-100"
  | "lg:to-indigo-100"
  | "md:to-indigo-100"
  | "sm:to-indigo-100"
  | "xs:to-indigo-100"
  | "hover:to-indigo-100"
  | "focus:to-indigo-100"
  | "2xl:from-indigo-200"
  | "1xl:from-indigo-200"
  | "xl:from-indigo-200"
  | "lg:from-indigo-200"
  | "md:from-indigo-200"
  | "sm:from-indigo-200"
  | "xs:from-indigo-200"
  | "hover:from-indigo-200"
  | "focus:from-indigo-200"
  | "2xl:via-indigo-200"
  | "1xl:via-indigo-200"
  | "xl:via-indigo-200"
  | "lg:via-indigo-200"
  | "md:via-indigo-200"
  | "sm:via-indigo-200"
  | "xs:via-indigo-200"
  | "hover:via-indigo-200"
  | "focus:via-indigo-200"
  | "2xl:to-indigo-200"
  | "1xl:to-indigo-200"
  | "xl:to-indigo-200"
  | "lg:to-indigo-200"
  | "md:to-indigo-200"
  | "sm:to-indigo-200"
  | "xs:to-indigo-200"
  | "hover:to-indigo-200"
  | "focus:to-indigo-200"
  | "2xl:from-indigo-300"
  | "1xl:from-indigo-300"
  | "xl:from-indigo-300"
  | "lg:from-indigo-300"
  | "md:from-indigo-300"
  | "sm:from-indigo-300"
  | "xs:from-indigo-300"
  | "hover:from-indigo-300"
  | "focus:from-indigo-300"
  | "2xl:via-indigo-300"
  | "1xl:via-indigo-300"
  | "xl:via-indigo-300"
  | "lg:via-indigo-300"
  | "md:via-indigo-300"
  | "sm:via-indigo-300"
  | "xs:via-indigo-300"
  | "hover:via-indigo-300"
  | "focus:via-indigo-300"
  | "2xl:to-indigo-300"
  | "1xl:to-indigo-300"
  | "xl:to-indigo-300"
  | "lg:to-indigo-300"
  | "md:to-indigo-300"
  | "sm:to-indigo-300"
  | "xs:to-indigo-300"
  | "hover:to-indigo-300"
  | "focus:to-indigo-300"
  | "2xl:from-indigo-400"
  | "1xl:from-indigo-400"
  | "xl:from-indigo-400"
  | "lg:from-indigo-400"
  | "md:from-indigo-400"
  | "sm:from-indigo-400"
  | "xs:from-indigo-400"
  | "hover:from-indigo-400"
  | "focus:from-indigo-400"
  | "2xl:via-indigo-400"
  | "1xl:via-indigo-400"
  | "xl:via-indigo-400"
  | "lg:via-indigo-400"
  | "md:via-indigo-400"
  | "sm:via-indigo-400"
  | "xs:via-indigo-400"
  | "hover:via-indigo-400"
  | "focus:via-indigo-400"
  | "2xl:to-indigo-400"
  | "1xl:to-indigo-400"
  | "xl:to-indigo-400"
  | "lg:to-indigo-400"
  | "md:to-indigo-400"
  | "sm:to-indigo-400"
  | "xs:to-indigo-400"
  | "hover:to-indigo-400"
  | "focus:to-indigo-400"
  | "2xl:from-indigo-500"
  | "1xl:from-indigo-500"
  | "xl:from-indigo-500"
  | "lg:from-indigo-500"
  | "md:from-indigo-500"
  | "sm:from-indigo-500"
  | "xs:from-indigo-500"
  | "hover:from-indigo-500"
  | "focus:from-indigo-500"
  | "2xl:via-indigo-500"
  | "1xl:via-indigo-500"
  | "xl:via-indigo-500"
  | "lg:via-indigo-500"
  | "md:via-indigo-500"
  | "sm:via-indigo-500"
  | "xs:via-indigo-500"
  | "hover:via-indigo-500"
  | "focus:via-indigo-500"
  | "2xl:to-indigo-500"
  | "1xl:to-indigo-500"
  | "xl:to-indigo-500"
  | "lg:to-indigo-500"
  | "md:to-indigo-500"
  | "sm:to-indigo-500"
  | "xs:to-indigo-500"
  | "hover:to-indigo-500"
  | "focus:to-indigo-500"
  | "2xl:from-indigo-600"
  | "1xl:from-indigo-600"
  | "xl:from-indigo-600"
  | "lg:from-indigo-600"
  | "md:from-indigo-600"
  | "sm:from-indigo-600"
  | "xs:from-indigo-600"
  | "hover:from-indigo-600"
  | "focus:from-indigo-600"
  | "2xl:via-indigo-600"
  | "1xl:via-indigo-600"
  | "xl:via-indigo-600"
  | "lg:via-indigo-600"
  | "md:via-indigo-600"
  | "sm:via-indigo-600"
  | "xs:via-indigo-600"
  | "hover:via-indigo-600"
  | "focus:via-indigo-600"
  | "2xl:to-indigo-600"
  | "1xl:to-indigo-600"
  | "xl:to-indigo-600"
  | "lg:to-indigo-600"
  | "md:to-indigo-600"
  | "sm:to-indigo-600"
  | "xs:to-indigo-600"
  | "hover:to-indigo-600"
  | "focus:to-indigo-600"
  | "2xl:from-indigo-700"
  | "1xl:from-indigo-700"
  | "xl:from-indigo-700"
  | "lg:from-indigo-700"
  | "md:from-indigo-700"
  | "sm:from-indigo-700"
  | "xs:from-indigo-700"
  | "hover:from-indigo-700"
  | "focus:from-indigo-700"
  | "2xl:via-indigo-700"
  | "1xl:via-indigo-700"
  | "xl:via-indigo-700"
  | "lg:via-indigo-700"
  | "md:via-indigo-700"
  | "sm:via-indigo-700"
  | "xs:via-indigo-700"
  | "hover:via-indigo-700"
  | "focus:via-indigo-700"
  | "2xl:to-indigo-700"
  | "1xl:to-indigo-700"
  | "xl:to-indigo-700"
  | "lg:to-indigo-700"
  | "md:to-indigo-700"
  | "sm:to-indigo-700"
  | "xs:to-indigo-700"
  | "hover:to-indigo-700"
  | "focus:to-indigo-700"
  | "2xl:from-indigo-800"
  | "1xl:from-indigo-800"
  | "xl:from-indigo-800"
  | "lg:from-indigo-800"
  | "md:from-indigo-800"
  | "sm:from-indigo-800"
  | "xs:from-indigo-800"
  | "hover:from-indigo-800"
  | "focus:from-indigo-800"
  | "2xl:via-indigo-800"
  | "1xl:via-indigo-800"
  | "xl:via-indigo-800"
  | "lg:via-indigo-800"
  | "md:via-indigo-800"
  | "sm:via-indigo-800"
  | "xs:via-indigo-800"
  | "hover:via-indigo-800"
  | "focus:via-indigo-800"
  | "2xl:to-indigo-800"
  | "1xl:to-indigo-800"
  | "xl:to-indigo-800"
  | "lg:to-indigo-800"
  | "md:to-indigo-800"
  | "sm:to-indigo-800"
  | "xs:to-indigo-800"
  | "hover:to-indigo-800"
  | "focus:to-indigo-800"
  | "2xl:from-indigo-900"
  | "1xl:from-indigo-900"
  | "xl:from-indigo-900"
  | "lg:from-indigo-900"
  | "md:from-indigo-900"
  | "sm:from-indigo-900"
  | "xs:from-indigo-900"
  | "hover:from-indigo-900"
  | "focus:from-indigo-900"
  | "2xl:via-indigo-900"
  | "1xl:via-indigo-900"
  | "xl:via-indigo-900"
  | "lg:via-indigo-900"
  | "md:via-indigo-900"
  | "sm:via-indigo-900"
  | "xs:via-indigo-900"
  | "hover:via-indigo-900"
  | "focus:via-indigo-900"
  | "2xl:to-indigo-900"
  | "1xl:to-indigo-900"
  | "xl:to-indigo-900"
  | "lg:to-indigo-900"
  | "md:to-indigo-900"
  | "sm:to-indigo-900"
  | "xs:to-indigo-900"
  | "hover:to-indigo-900"
  | "focus:to-indigo-900"
  | "2xl:from-purple-50"
  | "1xl:from-purple-50"
  | "xl:from-purple-50"
  | "lg:from-purple-50"
  | "md:from-purple-50"
  | "sm:from-purple-50"
  | "xs:from-purple-50"
  | "hover:from-purple-50"
  | "focus:from-purple-50"
  | "2xl:via-purple-50"
  | "1xl:via-purple-50"
  | "xl:via-purple-50"
  | "lg:via-purple-50"
  | "md:via-purple-50"
  | "sm:via-purple-50"
  | "xs:via-purple-50"
  | "hover:via-purple-50"
  | "focus:via-purple-50"
  | "2xl:to-purple-50"
  | "1xl:to-purple-50"
  | "xl:to-purple-50"
  | "lg:to-purple-50"
  | "md:to-purple-50"
  | "sm:to-purple-50"
  | "xs:to-purple-50"
  | "hover:to-purple-50"
  | "focus:to-purple-50"
  | "2xl:from-purple-100"
  | "1xl:from-purple-100"
  | "xl:from-purple-100"
  | "lg:from-purple-100"
  | "md:from-purple-100"
  | "sm:from-purple-100"
  | "xs:from-purple-100"
  | "hover:from-purple-100"
  | "focus:from-purple-100"
  | "2xl:via-purple-100"
  | "1xl:via-purple-100"
  | "xl:via-purple-100"
  | "lg:via-purple-100"
  | "md:via-purple-100"
  | "sm:via-purple-100"
  | "xs:via-purple-100"
  | "hover:via-purple-100"
  | "focus:via-purple-100"
  | "2xl:to-purple-100"
  | "1xl:to-purple-100"
  | "xl:to-purple-100"
  | "lg:to-purple-100"
  | "md:to-purple-100"
  | "sm:to-purple-100"
  | "xs:to-purple-100"
  | "hover:to-purple-100"
  | "focus:to-purple-100"
  | "2xl:from-purple-200"
  | "1xl:from-purple-200"
  | "xl:from-purple-200"
  | "lg:from-purple-200"
  | "md:from-purple-200"
  | "sm:from-purple-200"
  | "xs:from-purple-200"
  | "hover:from-purple-200"
  | "focus:from-purple-200"
  | "2xl:via-purple-200"
  | "1xl:via-purple-200"
  | "xl:via-purple-200"
  | "lg:via-purple-200"
  | "md:via-purple-200"
  | "sm:via-purple-200"
  | "xs:via-purple-200"
  | "hover:via-purple-200"
  | "focus:via-purple-200"
  | "2xl:to-purple-200"
  | "1xl:to-purple-200"
  | "xl:to-purple-200"
  | "lg:to-purple-200"
  | "md:to-purple-200"
  | "sm:to-purple-200"
  | "xs:to-purple-200"
  | "hover:to-purple-200"
  | "focus:to-purple-200"
  | "2xl:from-purple-300"
  | "1xl:from-purple-300"
  | "xl:from-purple-300"
  | "lg:from-purple-300"
  | "md:from-purple-300"
  | "sm:from-purple-300"
  | "xs:from-purple-300"
  | "hover:from-purple-300"
  | "focus:from-purple-300"
  | "2xl:via-purple-300"
  | "1xl:via-purple-300"
  | "xl:via-purple-300"
  | "lg:via-purple-300"
  | "md:via-purple-300"
  | "sm:via-purple-300"
  | "xs:via-purple-300"
  | "hover:via-purple-300"
  | "focus:via-purple-300"
  | "2xl:to-purple-300"
  | "1xl:to-purple-300"
  | "xl:to-purple-300"
  | "lg:to-purple-300"
  | "md:to-purple-300"
  | "sm:to-purple-300"
  | "xs:to-purple-300"
  | "hover:to-purple-300"
  | "focus:to-purple-300"
  | "2xl:from-purple-400"
  | "1xl:from-purple-400"
  | "xl:from-purple-400"
  | "lg:from-purple-400"
  | "md:from-purple-400"
  | "sm:from-purple-400"
  | "xs:from-purple-400"
  | "hover:from-purple-400"
  | "focus:from-purple-400"
  | "2xl:via-purple-400"
  | "1xl:via-purple-400"
  | "xl:via-purple-400"
  | "lg:via-purple-400"
  | "md:via-purple-400"
  | "sm:via-purple-400"
  | "xs:via-purple-400"
  | "hover:via-purple-400"
  | "focus:via-purple-400"
  | "2xl:to-purple-400"
  | "1xl:to-purple-400"
  | "xl:to-purple-400"
  | "lg:to-purple-400"
  | "md:to-purple-400"
  | "sm:to-purple-400"
  | "xs:to-purple-400"
  | "hover:to-purple-400"
  | "focus:to-purple-400"
  | "2xl:from-purple-500"
  | "1xl:from-purple-500"
  | "xl:from-purple-500"
  | "lg:from-purple-500"
  | "md:from-purple-500"
  | "sm:from-purple-500"
  | "xs:from-purple-500"
  | "hover:from-purple-500"
  | "focus:from-purple-500"
  | "2xl:via-purple-500"
  | "1xl:via-purple-500"
  | "xl:via-purple-500"
  | "lg:via-purple-500"
  | "md:via-purple-500"
  | "sm:via-purple-500"
  | "xs:via-purple-500"
  | "hover:via-purple-500"
  | "focus:via-purple-500"
  | "2xl:to-purple-500"
  | "1xl:to-purple-500"
  | "xl:to-purple-500"
  | "lg:to-purple-500"
  | "md:to-purple-500"
  | "sm:to-purple-500"
  | "xs:to-purple-500"
  | "hover:to-purple-500"
  | "focus:to-purple-500"
  | "2xl:from-purple-600"
  | "1xl:from-purple-600"
  | "xl:from-purple-600"
  | "lg:from-purple-600"
  | "md:from-purple-600"
  | "sm:from-purple-600"
  | "xs:from-purple-600"
  | "hover:from-purple-600"
  | "focus:from-purple-600"
  | "2xl:via-purple-600"
  | "1xl:via-purple-600"
  | "xl:via-purple-600"
  | "lg:via-purple-600"
  | "md:via-purple-600"
  | "sm:via-purple-600"
  | "xs:via-purple-600"
  | "hover:via-purple-600"
  | "focus:via-purple-600"
  | "2xl:to-purple-600"
  | "1xl:to-purple-600"
  | "xl:to-purple-600"
  | "lg:to-purple-600"
  | "md:to-purple-600"
  | "sm:to-purple-600"
  | "xs:to-purple-600"
  | "hover:to-purple-600"
  | "focus:to-purple-600"
  | "2xl:from-purple-700"
  | "1xl:from-purple-700"
  | "xl:from-purple-700"
  | "lg:from-purple-700"
  | "md:from-purple-700"
  | "sm:from-purple-700"
  | "xs:from-purple-700"
  | "hover:from-purple-700"
  | "focus:from-purple-700"
  | "2xl:via-purple-700"
  | "1xl:via-purple-700"
  | "xl:via-purple-700"
  | "lg:via-purple-700"
  | "md:via-purple-700"
  | "sm:via-purple-700"
  | "xs:via-purple-700"
  | "hover:via-purple-700"
  | "focus:via-purple-700"
  | "2xl:to-purple-700"
  | "1xl:to-purple-700"
  | "xl:to-purple-700"
  | "lg:to-purple-700"
  | "md:to-purple-700"
  | "sm:to-purple-700"
  | "xs:to-purple-700"
  | "hover:to-purple-700"
  | "focus:to-purple-700"
  | "2xl:from-purple-800"
  | "1xl:from-purple-800"
  | "xl:from-purple-800"
  | "lg:from-purple-800"
  | "md:from-purple-800"
  | "sm:from-purple-800"
  | "xs:from-purple-800"
  | "hover:from-purple-800"
  | "focus:from-purple-800"
  | "2xl:via-purple-800"
  | "1xl:via-purple-800"
  | "xl:via-purple-800"
  | "lg:via-purple-800"
  | "md:via-purple-800"
  | "sm:via-purple-800"
  | "xs:via-purple-800"
  | "hover:via-purple-800"
  | "focus:via-purple-800"
  | "2xl:to-purple-800"
  | "1xl:to-purple-800"
  | "xl:to-purple-800"
  | "lg:to-purple-800"
  | "md:to-purple-800"
  | "sm:to-purple-800"
  | "xs:to-purple-800"
  | "hover:to-purple-800"
  | "focus:to-purple-800"
  | "2xl:from-purple-900"
  | "1xl:from-purple-900"
  | "xl:from-purple-900"
  | "lg:from-purple-900"
  | "md:from-purple-900"
  | "sm:from-purple-900"
  | "xs:from-purple-900"
  | "hover:from-purple-900"
  | "focus:from-purple-900"
  | "2xl:via-purple-900"
  | "1xl:via-purple-900"
  | "xl:via-purple-900"
  | "lg:via-purple-900"
  | "md:via-purple-900"
  | "sm:via-purple-900"
  | "xs:via-purple-900"
  | "hover:via-purple-900"
  | "focus:via-purple-900"
  | "2xl:to-purple-900"
  | "1xl:to-purple-900"
  | "xl:to-purple-900"
  | "lg:to-purple-900"
  | "md:to-purple-900"
  | "sm:to-purple-900"
  | "xs:to-purple-900"
  | "hover:to-purple-900"
  | "focus:to-purple-900"
  | "2xl:from-pink-50"
  | "1xl:from-pink-50"
  | "xl:from-pink-50"
  | "lg:from-pink-50"
  | "md:from-pink-50"
  | "sm:from-pink-50"
  | "xs:from-pink-50"
  | "hover:from-pink-50"
  | "focus:from-pink-50"
  | "2xl:via-pink-50"
  | "1xl:via-pink-50"
  | "xl:via-pink-50"
  | "lg:via-pink-50"
  | "md:via-pink-50"
  | "sm:via-pink-50"
  | "xs:via-pink-50"
  | "hover:via-pink-50"
  | "focus:via-pink-50"
  | "2xl:to-pink-50"
  | "1xl:to-pink-50"
  | "xl:to-pink-50"
  | "lg:to-pink-50"
  | "md:to-pink-50"
  | "sm:to-pink-50"
  | "xs:to-pink-50"
  | "hover:to-pink-50"
  | "focus:to-pink-50"
  | "2xl:from-pink-100"
  | "1xl:from-pink-100"
  | "xl:from-pink-100"
  | "lg:from-pink-100"
  | "md:from-pink-100"
  | "sm:from-pink-100"
  | "xs:from-pink-100"
  | "hover:from-pink-100"
  | "focus:from-pink-100"
  | "2xl:via-pink-100"
  | "1xl:via-pink-100"
  | "xl:via-pink-100"
  | "lg:via-pink-100"
  | "md:via-pink-100"
  | "sm:via-pink-100"
  | "xs:via-pink-100"
  | "hover:via-pink-100"
  | "focus:via-pink-100"
  | "2xl:to-pink-100"
  | "1xl:to-pink-100"
  | "xl:to-pink-100"
  | "lg:to-pink-100"
  | "md:to-pink-100"
  | "sm:to-pink-100"
  | "xs:to-pink-100"
  | "hover:to-pink-100"
  | "focus:to-pink-100"
  | "2xl:from-pink-200"
  | "1xl:from-pink-200"
  | "xl:from-pink-200"
  | "lg:from-pink-200"
  | "md:from-pink-200"
  | "sm:from-pink-200"
  | "xs:from-pink-200"
  | "hover:from-pink-200"
  | "focus:from-pink-200"
  | "2xl:via-pink-200"
  | "1xl:via-pink-200"
  | "xl:via-pink-200"
  | "lg:via-pink-200"
  | "md:via-pink-200"
  | "sm:via-pink-200"
  | "xs:via-pink-200"
  | "hover:via-pink-200"
  | "focus:via-pink-200"
  | "2xl:to-pink-200"
  | "1xl:to-pink-200"
  | "xl:to-pink-200"
  | "lg:to-pink-200"
  | "md:to-pink-200"
  | "sm:to-pink-200"
  | "xs:to-pink-200"
  | "hover:to-pink-200"
  | "focus:to-pink-200"
  | "2xl:from-pink-300"
  | "1xl:from-pink-300"
  | "xl:from-pink-300"
  | "lg:from-pink-300"
  | "md:from-pink-300"
  | "sm:from-pink-300"
  | "xs:from-pink-300"
  | "hover:from-pink-300"
  | "focus:from-pink-300"
  | "2xl:via-pink-300"
  | "1xl:via-pink-300"
  | "xl:via-pink-300"
  | "lg:via-pink-300"
  | "md:via-pink-300"
  | "sm:via-pink-300"
  | "xs:via-pink-300"
  | "hover:via-pink-300"
  | "focus:via-pink-300"
  | "2xl:to-pink-300"
  | "1xl:to-pink-300"
  | "xl:to-pink-300"
  | "lg:to-pink-300"
  | "md:to-pink-300"
  | "sm:to-pink-300"
  | "xs:to-pink-300"
  | "hover:to-pink-300"
  | "focus:to-pink-300"
  | "2xl:from-pink-400"
  | "1xl:from-pink-400"
  | "xl:from-pink-400"
  | "lg:from-pink-400"
  | "md:from-pink-400"
  | "sm:from-pink-400"
  | "xs:from-pink-400"
  | "hover:from-pink-400"
  | "focus:from-pink-400"
  | "2xl:via-pink-400"
  | "1xl:via-pink-400"
  | "xl:via-pink-400"
  | "lg:via-pink-400"
  | "md:via-pink-400"
  | "sm:via-pink-400"
  | "xs:via-pink-400"
  | "hover:via-pink-400"
  | "focus:via-pink-400"
  | "2xl:to-pink-400"
  | "1xl:to-pink-400"
  | "xl:to-pink-400"
  | "lg:to-pink-400"
  | "md:to-pink-400"
  | "sm:to-pink-400"
  | "xs:to-pink-400"
  | "hover:to-pink-400"
  | "focus:to-pink-400"
  | "2xl:from-pink-500"
  | "1xl:from-pink-500"
  | "xl:from-pink-500"
  | "lg:from-pink-500"
  | "md:from-pink-500"
  | "sm:from-pink-500"
  | "xs:from-pink-500"
  | "hover:from-pink-500"
  | "focus:from-pink-500"
  | "2xl:via-pink-500"
  | "1xl:via-pink-500"
  | "xl:via-pink-500"
  | "lg:via-pink-500"
  | "md:via-pink-500"
  | "sm:via-pink-500"
  | "xs:via-pink-500"
  | "hover:via-pink-500"
  | "focus:via-pink-500"
  | "2xl:to-pink-500"
  | "1xl:to-pink-500"
  | "xl:to-pink-500"
  | "lg:to-pink-500"
  | "md:to-pink-500"
  | "sm:to-pink-500"
  | "xs:to-pink-500"
  | "hover:to-pink-500"
  | "focus:to-pink-500"
  | "2xl:from-pink-600"
  | "1xl:from-pink-600"
  | "xl:from-pink-600"
  | "lg:from-pink-600"
  | "md:from-pink-600"
  | "sm:from-pink-600"
  | "xs:from-pink-600"
  | "hover:from-pink-600"
  | "focus:from-pink-600"
  | "2xl:via-pink-600"
  | "1xl:via-pink-600"
  | "xl:via-pink-600"
  | "lg:via-pink-600"
  | "md:via-pink-600"
  | "sm:via-pink-600"
  | "xs:via-pink-600"
  | "hover:via-pink-600"
  | "focus:via-pink-600"
  | "2xl:to-pink-600"
  | "1xl:to-pink-600"
  | "xl:to-pink-600"
  | "lg:to-pink-600"
  | "md:to-pink-600"
  | "sm:to-pink-600"
  | "xs:to-pink-600"
  | "hover:to-pink-600"
  | "focus:to-pink-600"
  | "2xl:from-pink-700"
  | "1xl:from-pink-700"
  | "xl:from-pink-700"
  | "lg:from-pink-700"
  | "md:from-pink-700"
  | "sm:from-pink-700"
  | "xs:from-pink-700"
  | "hover:from-pink-700"
  | "focus:from-pink-700"
  | "2xl:via-pink-700"
  | "1xl:via-pink-700"
  | "xl:via-pink-700"
  | "lg:via-pink-700"
  | "md:via-pink-700"
  | "sm:via-pink-700"
  | "xs:via-pink-700"
  | "hover:via-pink-700"
  | "focus:via-pink-700"
  | "2xl:to-pink-700"
  | "1xl:to-pink-700"
  | "xl:to-pink-700"
  | "lg:to-pink-700"
  | "md:to-pink-700"
  | "sm:to-pink-700"
  | "xs:to-pink-700"
  | "hover:to-pink-700"
  | "focus:to-pink-700"
  | "2xl:from-pink-800"
  | "1xl:from-pink-800"
  | "xl:from-pink-800"
  | "lg:from-pink-800"
  | "md:from-pink-800"
  | "sm:from-pink-800"
  | "xs:from-pink-800"
  | "hover:from-pink-800"
  | "focus:from-pink-800"
  | "2xl:via-pink-800"
  | "1xl:via-pink-800"
  | "xl:via-pink-800"
  | "lg:via-pink-800"
  | "md:via-pink-800"
  | "sm:via-pink-800"
  | "xs:via-pink-800"
  | "hover:via-pink-800"
  | "focus:via-pink-800"
  | "2xl:to-pink-800"
  | "1xl:to-pink-800"
  | "xl:to-pink-800"
  | "lg:to-pink-800"
  | "md:to-pink-800"
  | "sm:to-pink-800"
  | "xs:to-pink-800"
  | "hover:to-pink-800"
  | "focus:to-pink-800"
  | "2xl:from-pink-900"
  | "1xl:from-pink-900"
  | "xl:from-pink-900"
  | "lg:from-pink-900"
  | "md:from-pink-900"
  | "sm:from-pink-900"
  | "xs:from-pink-900"
  | "hover:from-pink-900"
  | "focus:from-pink-900"
  | "2xl:via-pink-900"
  | "1xl:via-pink-900"
  | "xl:via-pink-900"
  | "lg:via-pink-900"
  | "md:via-pink-900"
  | "sm:via-pink-900"
  | "xs:via-pink-900"
  | "hover:via-pink-900"
  | "focus:via-pink-900"
  | "2xl:to-pink-900"
  | "1xl:to-pink-900"
  | "xl:to-pink-900"
  | "lg:to-pink-900"
  | "md:to-pink-900"
  | "sm:to-pink-900"
  | "xs:to-pink-900"
  | "hover:to-pink-900"
  | "focus:to-pink-900"
  | "2xl:from-title-color-1"
  | "1xl:from-title-color-1"
  | "xl:from-title-color-1"
  | "lg:from-title-color-1"
  | "md:from-title-color-1"
  | "sm:from-title-color-1"
  | "xs:from-title-color-1"
  | "hover:from-title-color-1"
  | "focus:from-title-color-1"
  | "2xl:via-title-color-1"
  | "1xl:via-title-color-1"
  | "xl:via-title-color-1"
  | "lg:via-title-color-1"
  | "md:via-title-color-1"
  | "sm:via-title-color-1"
  | "xs:via-title-color-1"
  | "hover:via-title-color-1"
  | "focus:via-title-color-1"
  | "2xl:to-title-color-1"
  | "1xl:to-title-color-1"
  | "xl:to-title-color-1"
  | "lg:to-title-color-1"
  | "md:to-title-color-1"
  | "sm:to-title-color-1"
  | "xs:to-title-color-1"
  | "hover:to-title-color-1"
  | "focus:to-title-color-1"
  | "2xl:from-primary-bg-color"
  | "1xl:from-primary-bg-color"
  | "xl:from-primary-bg-color"
  | "lg:from-primary-bg-color"
  | "md:from-primary-bg-color"
  | "sm:from-primary-bg-color"
  | "xs:from-primary-bg-color"
  | "hover:from-primary-bg-color"
  | "focus:from-primary-bg-color"
  | "2xl:via-primary-bg-color"
  | "1xl:via-primary-bg-color"
  | "xl:via-primary-bg-color"
  | "lg:via-primary-bg-color"
  | "md:via-primary-bg-color"
  | "sm:via-primary-bg-color"
  | "xs:via-primary-bg-color"
  | "hover:via-primary-bg-color"
  | "focus:via-primary-bg-color"
  | "2xl:to-primary-bg-color"
  | "1xl:to-primary-bg-color"
  | "xl:to-primary-bg-color"
  | "lg:to-primary-bg-color"
  | "md:to-primary-bg-color"
  | "sm:to-primary-bg-color"
  | "xs:to-primary-bg-color"
  | "hover:to-primary-bg-color"
  | "focus:to-primary-bg-color"
  | "2xl:from-secondary-bg-color"
  | "1xl:from-secondary-bg-color"
  | "xl:from-secondary-bg-color"
  | "lg:from-secondary-bg-color"
  | "md:from-secondary-bg-color"
  | "sm:from-secondary-bg-color"
  | "xs:from-secondary-bg-color"
  | "hover:from-secondary-bg-color"
  | "focus:from-secondary-bg-color"
  | "2xl:via-secondary-bg-color"
  | "1xl:via-secondary-bg-color"
  | "xl:via-secondary-bg-color"
  | "lg:via-secondary-bg-color"
  | "md:via-secondary-bg-color"
  | "sm:via-secondary-bg-color"
  | "xs:via-secondary-bg-color"
  | "hover:via-secondary-bg-color"
  | "focus:via-secondary-bg-color"
  | "2xl:to-secondary-bg-color"
  | "1xl:to-secondary-bg-color"
  | "xl:to-secondary-bg-color"
  | "lg:to-secondary-bg-color"
  | "md:to-secondary-bg-color"
  | "sm:to-secondary-bg-color"
  | "xs:to-secondary-bg-color"
  | "hover:to-secondary-bg-color"
  | "focus:to-secondary-bg-color"
  | "2xl:from-sidebar-bg-color"
  | "1xl:from-sidebar-bg-color"
  | "xl:from-sidebar-bg-color"
  | "lg:from-sidebar-bg-color"
  | "md:from-sidebar-bg-color"
  | "sm:from-sidebar-bg-color"
  | "xs:from-sidebar-bg-color"
  | "hover:from-sidebar-bg-color"
  | "focus:from-sidebar-bg-color"
  | "2xl:via-sidebar-bg-color"
  | "1xl:via-sidebar-bg-color"
  | "xl:via-sidebar-bg-color"
  | "lg:via-sidebar-bg-color"
  | "md:via-sidebar-bg-color"
  | "sm:via-sidebar-bg-color"
  | "xs:via-sidebar-bg-color"
  | "hover:via-sidebar-bg-color"
  | "focus:via-sidebar-bg-color"
  | "2xl:to-sidebar-bg-color"
  | "1xl:to-sidebar-bg-color"
  | "xl:to-sidebar-bg-color"
  | "lg:to-sidebar-bg-color"
  | "md:to-sidebar-bg-color"
  | "sm:to-sidebar-bg-color"
  | "xs:to-sidebar-bg-color"
  | "hover:to-sidebar-bg-color"
  | "focus:to-sidebar-bg-color"
  | "2xl:from-header-bg-color"
  | "1xl:from-header-bg-color"
  | "xl:from-header-bg-color"
  | "lg:from-header-bg-color"
  | "md:from-header-bg-color"
  | "sm:from-header-bg-color"
  | "xs:from-header-bg-color"
  | "hover:from-header-bg-color"
  | "focus:from-header-bg-color"
  | "2xl:via-header-bg-color"
  | "1xl:via-header-bg-color"
  | "xl:via-header-bg-color"
  | "lg:via-header-bg-color"
  | "md:via-header-bg-color"
  | "sm:via-header-bg-color"
  | "xs:via-header-bg-color"
  | "hover:via-header-bg-color"
  | "focus:via-header-bg-color"
  | "2xl:to-header-bg-color"
  | "1xl:to-header-bg-color"
  | "xl:to-header-bg-color"
  | "lg:to-header-bg-color"
  | "md:to-header-bg-color"
  | "sm:to-header-bg-color"
  | "xs:to-header-bg-color"
  | "hover:to-header-bg-color"
  | "focus:to-header-bg-color"
  | "2xl:from-body-bg-color"
  | "1xl:from-body-bg-color"
  | "xl:from-body-bg-color"
  | "lg:from-body-bg-color"
  | "md:from-body-bg-color"
  | "sm:from-body-bg-color"
  | "xs:from-body-bg-color"
  | "hover:from-body-bg-color"
  | "focus:from-body-bg-color"
  | "2xl:via-body-bg-color"
  | "1xl:via-body-bg-color"
  | "xl:via-body-bg-color"
  | "lg:via-body-bg-color"
  | "md:via-body-bg-color"
  | "sm:via-body-bg-color"
  | "xs:via-body-bg-color"
  | "hover:via-body-bg-color"
  | "focus:via-body-bg-color"
  | "2xl:to-body-bg-color"
  | "1xl:to-body-bg-color"
  | "xl:to-body-bg-color"
  | "lg:to-body-bg-color"
  | "md:to-body-bg-color"
  | "sm:to-body-bg-color"
  | "xs:to-body-bg-color"
  | "hover:to-body-bg-color"
  | "focus:to-body-bg-color"
  | "2xl:from-main-color"
  | "1xl:from-main-color"
  | "xl:from-main-color"
  | "lg:from-main-color"
  | "md:from-main-color"
  | "sm:from-main-color"
  | "xs:from-main-color"
  | "hover:from-main-color"
  | "focus:from-main-color"
  | "2xl:via-main-color"
  | "1xl:via-main-color"
  | "xl:via-main-color"
  | "lg:via-main-color"
  | "md:via-main-color"
  | "sm:via-main-color"
  | "xs:via-main-color"
  | "hover:via-main-color"
  | "focus:via-main-color"
  | "2xl:to-main-color"
  | "1xl:to-main-color"
  | "xl:to-main-color"
  | "lg:to-main-color"
  | "md:to-main-color"
  | "sm:to-main-color"
  | "xs:to-main-color"
  | "hover:to-main-color"
  | "focus:to-main-color"
  | "2xl:from-tertiary-bg-color"
  | "1xl:from-tertiary-bg-color"
  | "xl:from-tertiary-bg-color"
  | "lg:from-tertiary-bg-color"
  | "md:from-tertiary-bg-color"
  | "sm:from-tertiary-bg-color"
  | "xs:from-tertiary-bg-color"
  | "hover:from-tertiary-bg-color"
  | "focus:from-tertiary-bg-color"
  | "2xl:via-tertiary-bg-color"
  | "1xl:via-tertiary-bg-color"
  | "xl:via-tertiary-bg-color"
  | "lg:via-tertiary-bg-color"
  | "md:via-tertiary-bg-color"
  | "sm:via-tertiary-bg-color"
  | "xs:via-tertiary-bg-color"
  | "hover:via-tertiary-bg-color"
  | "focus:via-tertiary-bg-color"
  | "2xl:to-tertiary-bg-color"
  | "1xl:to-tertiary-bg-color"
  | "xl:to-tertiary-bg-color"
  | "lg:to-tertiary-bg-color"
  | "md:to-tertiary-bg-color"
  | "sm:to-tertiary-bg-color"
  | "xs:to-tertiary-bg-color"
  | "hover:to-tertiary-bg-color"
  | "focus:to-tertiary-bg-color"
  | "2xl:from-quaternary-bg-color"
  | "1xl:from-quaternary-bg-color"
  | "xl:from-quaternary-bg-color"
  | "lg:from-quaternary-bg-color"
  | "md:from-quaternary-bg-color"
  | "sm:from-quaternary-bg-color"
  | "xs:from-quaternary-bg-color"
  | "hover:from-quaternary-bg-color"
  | "focus:from-quaternary-bg-color"
  | "2xl:via-quaternary-bg-color"
  | "1xl:via-quaternary-bg-color"
  | "xl:via-quaternary-bg-color"
  | "lg:via-quaternary-bg-color"
  | "md:via-quaternary-bg-color"
  | "sm:via-quaternary-bg-color"
  | "xs:via-quaternary-bg-color"
  | "hover:via-quaternary-bg-color"
  | "focus:via-quaternary-bg-color"
  | "2xl:to-quaternary-bg-color"
  | "1xl:to-quaternary-bg-color"
  | "xl:to-quaternary-bg-color"
  | "lg:to-quaternary-bg-color"
  | "md:to-quaternary-bg-color"
  | "sm:to-quaternary-bg-color"
  | "xs:to-quaternary-bg-color"
  | "hover:to-quaternary-bg-color"
  | "focus:to-quaternary-bg-color"
  | "2xl:from-earnings-bg-color"
  | "1xl:from-earnings-bg-color"
  | "xl:from-earnings-bg-color"
  | "lg:from-earnings-bg-color"
  | "md:from-earnings-bg-color"
  | "sm:from-earnings-bg-color"
  | "xs:from-earnings-bg-color"
  | "hover:from-earnings-bg-color"
  | "focus:from-earnings-bg-color"
  | "2xl:via-earnings-bg-color"
  | "1xl:via-earnings-bg-color"
  | "xl:via-earnings-bg-color"
  | "lg:via-earnings-bg-color"
  | "md:via-earnings-bg-color"
  | "sm:via-earnings-bg-color"
  | "xs:via-earnings-bg-color"
  | "hover:via-earnings-bg-color"
  | "focus:via-earnings-bg-color"
  | "2xl:to-earnings-bg-color"
  | "1xl:to-earnings-bg-color"
  | "xl:to-earnings-bg-color"
  | "lg:to-earnings-bg-color"
  | "md:to-earnings-bg-color"
  | "sm:to-earnings-bg-color"
  | "xs:to-earnings-bg-color"
  | "hover:to-earnings-bg-color"
  | "focus:to-earnings-bg-color"
  | "2xl:from-card-secondary-bg-color"
  | "1xl:from-card-secondary-bg-color"
  | "xl:from-card-secondary-bg-color"
  | "lg:from-card-secondary-bg-color"
  | "md:from-card-secondary-bg-color"
  | "sm:from-card-secondary-bg-color"
  | "xs:from-card-secondary-bg-color"
  | "hover:from-card-secondary-bg-color"
  | "focus:from-card-secondary-bg-color"
  | "2xl:via-card-secondary-bg-color"
  | "1xl:via-card-secondary-bg-color"
  | "xl:via-card-secondary-bg-color"
  | "lg:via-card-secondary-bg-color"
  | "md:via-card-secondary-bg-color"
  | "sm:via-card-secondary-bg-color"
  | "xs:via-card-secondary-bg-color"
  | "hover:via-card-secondary-bg-color"
  | "focus:via-card-secondary-bg-color"
  | "2xl:to-card-secondary-bg-color"
  | "1xl:to-card-secondary-bg-color"
  | "xl:to-card-secondary-bg-color"
  | "lg:to-card-secondary-bg-color"
  | "md:to-card-secondary-bg-color"
  | "sm:to-card-secondary-bg-color"
  | "xs:to-card-secondary-bg-color"
  | "hover:to-card-secondary-bg-color"
  | "focus:to-card-secondary-bg-color"
  | "2xl:from-pink-color"
  | "1xl:from-pink-color"
  | "xl:from-pink-color"
  | "lg:from-pink-color"
  | "md:from-pink-color"
  | "sm:from-pink-color"
  | "xs:from-pink-color"
  | "hover:from-pink-color"
  | "focus:from-pink-color"
  | "2xl:via-pink-color"
  | "1xl:via-pink-color"
  | "xl:via-pink-color"
  | "lg:via-pink-color"
  | "md:via-pink-color"
  | "sm:via-pink-color"
  | "xs:via-pink-color"
  | "hover:via-pink-color"
  | "focus:via-pink-color"
  | "2xl:to-pink-color"
  | "1xl:to-pink-color"
  | "xl:to-pink-color"
  | "lg:to-pink-color"
  | "md:to-pink-color"
  | "sm:to-pink-color"
  | "xs:to-pink-color"
  | "hover:to-pink-color"
  | "focus:to-pink-color"
  | "2xl:from-go-live-text"
  | "1xl:from-go-live-text"
  | "xl:from-go-live-text"
  | "lg:from-go-live-text"
  | "md:from-go-live-text"
  | "sm:from-go-live-text"
  | "xs:from-go-live-text"
  | "hover:from-go-live-text"
  | "focus:from-go-live-text"
  | "2xl:via-go-live-text"
  | "1xl:via-go-live-text"
  | "xl:via-go-live-text"
  | "lg:via-go-live-text"
  | "md:via-go-live-text"
  | "sm:via-go-live-text"
  | "xs:via-go-live-text"
  | "hover:via-go-live-text"
  | "focus:via-go-live-text"
  | "2xl:to-go-live-text"
  | "1xl:to-go-live-text"
  | "xl:to-go-live-text"
  | "lg:to-go-live-text"
  | "md:to-go-live-text"
  | "sm:to-go-live-text"
  | "xs:to-go-live-text"
  | "hover:to-go-live-text"
  | "focus:to-go-live-text"
  | "2xl:from-go-live-bg-color"
  | "1xl:from-go-live-bg-color"
  | "xl:from-go-live-bg-color"
  | "lg:from-go-live-bg-color"
  | "md:from-go-live-bg-color"
  | "sm:from-go-live-bg-color"
  | "xs:from-go-live-bg-color"
  | "hover:from-go-live-bg-color"
  | "focus:from-go-live-bg-color"
  | "2xl:via-go-live-bg-color"
  | "1xl:via-go-live-bg-color"
  | "xl:via-go-live-bg-color"
  | "lg:via-go-live-bg-color"
  | "md:via-go-live-bg-color"
  | "sm:via-go-live-bg-color"
  | "xs:via-go-live-bg-color"
  | "hover:via-go-live-bg-color"
  | "focus:via-go-live-bg-color"
  | "2xl:to-go-live-bg-color"
  | "1xl:to-go-live-bg-color"
  | "xl:to-go-live-bg-color"
  | "lg:to-go-live-bg-color"
  | "md:to-go-live-bg-color"
  | "sm:to-go-live-bg-color"
  | "xs:to-go-live-bg-color"
  | "hover:to-go-live-bg-color"
  | "focus:to-go-live-bg-color"
  | "2xl:from-gray-button"
  | "1xl:from-gray-button"
  | "xl:from-gray-button"
  | "lg:from-gray-button"
  | "md:from-gray-button"
  | "sm:from-gray-button"
  | "xs:from-gray-button"
  | "hover:from-gray-button"
  | "focus:from-gray-button"
  | "2xl:via-gray-button"
  | "1xl:via-gray-button"
  | "xl:via-gray-button"
  | "lg:via-gray-button"
  | "md:via-gray-button"
  | "sm:via-gray-button"
  | "xs:via-gray-button"
  | "hover:via-gray-button"
  | "focus:via-gray-button"
  | "2xl:to-gray-button"
  | "1xl:to-gray-button"
  | "xl:to-gray-button"
  | "lg:to-gray-button"
  | "md:to-gray-button"
  | "sm:to-gray-button"
  | "xs:to-gray-button"
  | "hover:to-gray-button"
  | "focus:to-gray-button"
  | "2xl:from-toggle-disabled"
  | "1xl:from-toggle-disabled"
  | "xl:from-toggle-disabled"
  | "lg:from-toggle-disabled"
  | "md:from-toggle-disabled"
  | "sm:from-toggle-disabled"
  | "xs:from-toggle-disabled"
  | "hover:from-toggle-disabled"
  | "focus:from-toggle-disabled"
  | "2xl:via-toggle-disabled"
  | "1xl:via-toggle-disabled"
  | "xl:via-toggle-disabled"
  | "lg:via-toggle-disabled"
  | "md:via-toggle-disabled"
  | "sm:via-toggle-disabled"
  | "xs:via-toggle-disabled"
  | "hover:via-toggle-disabled"
  | "focus:via-toggle-disabled"
  | "2xl:to-toggle-disabled"
  | "1xl:to-toggle-disabled"
  | "xl:to-toggle-disabled"
  | "lg:to-toggle-disabled"
  | "md:to-toggle-disabled"
  | "sm:to-toggle-disabled"
  | "xs:to-toggle-disabled"
  | "hover:to-toggle-disabled"
  | "focus:to-toggle-disabled"
  | "2xl:from-tab-not-selected-color"
  | "1xl:from-tab-not-selected-color"
  | "xl:from-tab-not-selected-color"
  | "lg:from-tab-not-selected-color"
  | "md:from-tab-not-selected-color"
  | "sm:from-tab-not-selected-color"
  | "xs:from-tab-not-selected-color"
  | "hover:from-tab-not-selected-color"
  | "focus:from-tab-not-selected-color"
  | "2xl:via-tab-not-selected-color"
  | "1xl:via-tab-not-selected-color"
  | "xl:via-tab-not-selected-color"
  | "lg:via-tab-not-selected-color"
  | "md:via-tab-not-selected-color"
  | "sm:via-tab-not-selected-color"
  | "xs:via-tab-not-selected-color"
  | "hover:via-tab-not-selected-color"
  | "focus:via-tab-not-selected-color"
  | "2xl:to-tab-not-selected-color"
  | "1xl:to-tab-not-selected-color"
  | "xl:to-tab-not-selected-color"
  | "lg:to-tab-not-selected-color"
  | "md:to-tab-not-selected-color"
  | "sm:to-tab-not-selected-color"
  | "xs:to-tab-not-selected-color"
  | "hover:to-tab-not-selected-color"
  | "focus:to-tab-not-selected-color"
  | "2xl:from-text-area-bg-color"
  | "1xl:from-text-area-bg-color"
  | "xl:from-text-area-bg-color"
  | "lg:from-text-area-bg-color"
  | "md:from-text-area-bg-color"
  | "sm:from-text-area-bg-color"
  | "xs:from-text-area-bg-color"
  | "hover:from-text-area-bg-color"
  | "focus:from-text-area-bg-color"
  | "2xl:via-text-area-bg-color"
  | "1xl:via-text-area-bg-color"
  | "xl:via-text-area-bg-color"
  | "lg:via-text-area-bg-color"
  | "md:via-text-area-bg-color"
  | "sm:via-text-area-bg-color"
  | "xs:via-text-area-bg-color"
  | "hover:via-text-area-bg-color"
  | "focus:via-text-area-bg-color"
  | "2xl:to-text-area-bg-color"
  | "1xl:to-text-area-bg-color"
  | "xl:to-text-area-bg-color"
  | "lg:to-text-area-bg-color"
  | "md:to-text-area-bg-color"
  | "sm:to-text-area-bg-color"
  | "xs:to-text-area-bg-color"
  | "hover:to-text-area-bg-color"
  | "focus:to-text-area-bg-color"
  | "2xl:from-table-primary-bg-color"
  | "1xl:from-table-primary-bg-color"
  | "xl:from-table-primary-bg-color"
  | "lg:from-table-primary-bg-color"
  | "md:from-table-primary-bg-color"
  | "sm:from-table-primary-bg-color"
  | "xs:from-table-primary-bg-color"
  | "hover:from-table-primary-bg-color"
  | "focus:from-table-primary-bg-color"
  | "2xl:via-table-primary-bg-color"
  | "1xl:via-table-primary-bg-color"
  | "xl:via-table-primary-bg-color"
  | "lg:via-table-primary-bg-color"
  | "md:via-table-primary-bg-color"
  | "sm:via-table-primary-bg-color"
  | "xs:via-table-primary-bg-color"
  | "hover:via-table-primary-bg-color"
  | "focus:via-table-primary-bg-color"
  | "2xl:to-table-primary-bg-color"
  | "1xl:to-table-primary-bg-color"
  | "xl:to-table-primary-bg-color"
  | "lg:to-table-primary-bg-color"
  | "md:to-table-primary-bg-color"
  | "sm:to-table-primary-bg-color"
  | "xs:to-table-primary-bg-color"
  | "hover:to-table-primary-bg-color"
  | "focus:to-table-primary-bg-color"
  | "2xl:from-table-secondary-color"
  | "1xl:from-table-secondary-color"
  | "xl:from-table-secondary-color"
  | "lg:from-table-secondary-color"
  | "md:from-table-secondary-color"
  | "sm:from-table-secondary-color"
  | "xs:from-table-secondary-color"
  | "hover:from-table-secondary-color"
  | "focus:from-table-secondary-color"
  | "2xl:via-table-secondary-color"
  | "1xl:via-table-secondary-color"
  | "xl:via-table-secondary-color"
  | "lg:via-table-secondary-color"
  | "md:via-table-secondary-color"
  | "sm:via-table-secondary-color"
  | "xs:via-table-secondary-color"
  | "hover:via-table-secondary-color"
  | "focus:via-table-secondary-color"
  | "2xl:to-table-secondary-color"
  | "1xl:to-table-secondary-color"
  | "xl:to-table-secondary-color"
  | "lg:to-table-secondary-color"
  | "md:to-table-secondary-color"
  | "sm:to-table-secondary-color"
  | "xs:to-table-secondary-color"
  | "hover:to-table-secondary-color"
  | "focus:to-table-secondary-color"
  | "2xl:from-modal-button-color"
  | "1xl:from-modal-button-color"
  | "xl:from-modal-button-color"
  | "lg:from-modal-button-color"
  | "md:from-modal-button-color"
  | "sm:from-modal-button-color"
  | "xs:from-modal-button-color"
  | "hover:from-modal-button-color"
  | "focus:from-modal-button-color"
  | "2xl:via-modal-button-color"
  | "1xl:via-modal-button-color"
  | "xl:via-modal-button-color"
  | "lg:via-modal-button-color"
  | "md:via-modal-button-color"
  | "sm:via-modal-button-color"
  | "xs:via-modal-button-color"
  | "hover:via-modal-button-color"
  | "focus:via-modal-button-color"
  | "2xl:to-modal-button-color"
  | "1xl:to-modal-button-color"
  | "xl:to-modal-button-color"
  | "lg:to-modal-button-color"
  | "md:to-modal-button-color"
  | "sm:to-modal-button-color"
  | "xs:to-modal-button-color"
  | "hover:to-modal-button-color"
  | "focus:to-modal-button-color"
  | "2xl:grayscale-0"
  | "1xl:grayscale-0"
  | "xl:grayscale-0"
  | "lg:grayscale-0"
  | "md:grayscale-0"
  | "sm:grayscale-0"
  | "xs:grayscale-0"
  | "2xl:grayscale"
  | "1xl:grayscale"
  | "xl:grayscale"
  | "lg:grayscale"
  | "md:grayscale"
  | "sm:grayscale"
  | "xs:grayscale"
  | "2xl:auto-cols-auto"
  | "1xl:auto-cols-auto"
  | "xl:auto-cols-auto"
  | "lg:auto-cols-auto"
  | "md:auto-cols-auto"
  | "sm:auto-cols-auto"
  | "xs:auto-cols-auto"
  | "2xl:auto-cols-min"
  | "1xl:auto-cols-min"
  | "xl:auto-cols-min"
  | "lg:auto-cols-min"
  | "md:auto-cols-min"
  | "sm:auto-cols-min"
  | "xs:auto-cols-min"
  | "2xl:auto-cols-max"
  | "1xl:auto-cols-max"
  | "xl:auto-cols-max"
  | "lg:auto-cols-max"
  | "md:auto-cols-max"
  | "sm:auto-cols-max"
  | "xs:auto-cols-max"
  | "2xl:auto-cols-fr"
  | "1xl:auto-cols-fr"
  | "xl:auto-cols-fr"
  | "lg:auto-cols-fr"
  | "md:auto-cols-fr"
  | "sm:auto-cols-fr"
  | "xs:auto-cols-fr"
  | "2xl:grid-flow-row"
  | "1xl:grid-flow-row"
  | "xl:grid-flow-row"
  | "lg:grid-flow-row"
  | "md:grid-flow-row"
  | "sm:grid-flow-row"
  | "xs:grid-flow-row"
  | "2xl:grid-flow-col"
  | "1xl:grid-flow-col"
  | "xl:grid-flow-col"
  | "lg:grid-flow-col"
  | "md:grid-flow-col"
  | "sm:grid-flow-col"
  | "xs:grid-flow-col"
  | "2xl:grid-flow-row-dense"
  | "1xl:grid-flow-row-dense"
  | "xl:grid-flow-row-dense"
  | "lg:grid-flow-row-dense"
  | "md:grid-flow-row-dense"
  | "sm:grid-flow-row-dense"
  | "xs:grid-flow-row-dense"
  | "2xl:grid-flow-col-dense"
  | "1xl:grid-flow-col-dense"
  | "xl:grid-flow-col-dense"
  | "lg:grid-flow-col-dense"
  | "md:grid-flow-col-dense"
  | "sm:grid-flow-col-dense"
  | "xs:grid-flow-col-dense"
  | "2xl:auto-rows-auto"
  | "1xl:auto-rows-auto"
  | "xl:auto-rows-auto"
  | "lg:auto-rows-auto"
  | "md:auto-rows-auto"
  | "sm:auto-rows-auto"
  | "xs:auto-rows-auto"
  | "2xl:auto-rows-min"
  | "1xl:auto-rows-min"
  | "xl:auto-rows-min"
  | "lg:auto-rows-min"
  | "md:auto-rows-min"
  | "sm:auto-rows-min"
  | "xs:auto-rows-min"
  | "2xl:auto-rows-max"
  | "1xl:auto-rows-max"
  | "xl:auto-rows-max"
  | "lg:auto-rows-max"
  | "md:auto-rows-max"
  | "sm:auto-rows-max"
  | "xs:auto-rows-max"
  | "2xl:auto-rows-fr"
  | "1xl:auto-rows-fr"
  | "xl:auto-rows-fr"
  | "lg:auto-rows-fr"
  | "md:auto-rows-fr"
  | "sm:auto-rows-fr"
  | "xs:auto-rows-fr"
  | "2xl:col-auto"
  | "1xl:col-auto"
  | "xl:col-auto"
  | "lg:col-auto"
  | "md:col-auto"
  | "sm:col-auto"
  | "xs:col-auto"
  | "2xl:col-span-1"
  | "1xl:col-span-1"
  | "xl:col-span-1"
  | "lg:col-span-1"
  | "md:col-span-1"
  | "sm:col-span-1"
  | "xs:col-span-1"
  | "2xl:col-span-2"
  | "1xl:col-span-2"
  | "xl:col-span-2"
  | "lg:col-span-2"
  | "md:col-span-2"
  | "sm:col-span-2"
  | "xs:col-span-2"
  | "2xl:col-span-3"
  | "1xl:col-span-3"
  | "xl:col-span-3"
  | "lg:col-span-3"
  | "md:col-span-3"
  | "sm:col-span-3"
  | "xs:col-span-3"
  | "2xl:col-span-4"
  | "1xl:col-span-4"
  | "xl:col-span-4"
  | "lg:col-span-4"
  | "md:col-span-4"
  | "sm:col-span-4"
  | "xs:col-span-4"
  | "2xl:col-span-5"
  | "1xl:col-span-5"
  | "xl:col-span-5"
  | "lg:col-span-5"
  | "md:col-span-5"
  | "sm:col-span-5"
  | "xs:col-span-5"
  | "2xl:col-span-6"
  | "1xl:col-span-6"
  | "xl:col-span-6"
  | "lg:col-span-6"
  | "md:col-span-6"
  | "sm:col-span-6"
  | "xs:col-span-6"
  | "2xl:col-span-7"
  | "1xl:col-span-7"
  | "xl:col-span-7"
  | "lg:col-span-7"
  | "md:col-span-7"
  | "sm:col-span-7"
  | "xs:col-span-7"
  | "2xl:col-span-8"
  | "1xl:col-span-8"
  | "xl:col-span-8"
  | "lg:col-span-8"
  | "md:col-span-8"
  | "sm:col-span-8"
  | "xs:col-span-8"
  | "2xl:col-span-9"
  | "1xl:col-span-9"
  | "xl:col-span-9"
  | "lg:col-span-9"
  | "md:col-span-9"
  | "sm:col-span-9"
  | "xs:col-span-9"
  | "2xl:col-span-10"
  | "1xl:col-span-10"
  | "xl:col-span-10"
  | "lg:col-span-10"
  | "md:col-span-10"
  | "sm:col-span-10"
  | "xs:col-span-10"
  | "2xl:col-span-11"
  | "1xl:col-span-11"
  | "xl:col-span-11"
  | "lg:col-span-11"
  | "md:col-span-11"
  | "sm:col-span-11"
  | "xs:col-span-11"
  | "2xl:col-span-12"
  | "1xl:col-span-12"
  | "xl:col-span-12"
  | "lg:col-span-12"
  | "md:col-span-12"
  | "sm:col-span-12"
  | "xs:col-span-12"
  | "2xl:col-span-full"
  | "1xl:col-span-full"
  | "xl:col-span-full"
  | "lg:col-span-full"
  | "md:col-span-full"
  | "sm:col-span-full"
  | "xs:col-span-full"
  | "2xl:col-end-1"
  | "1xl:col-end-1"
  | "xl:col-end-1"
  | "lg:col-end-1"
  | "md:col-end-1"
  | "sm:col-end-1"
  | "xs:col-end-1"
  | "2xl:col-end-2"
  | "1xl:col-end-2"
  | "xl:col-end-2"
  | "lg:col-end-2"
  | "md:col-end-2"
  | "sm:col-end-2"
  | "xs:col-end-2"
  | "2xl:col-end-3"
  | "1xl:col-end-3"
  | "xl:col-end-3"
  | "lg:col-end-3"
  | "md:col-end-3"
  | "sm:col-end-3"
  | "xs:col-end-3"
  | "2xl:col-end-4"
  | "1xl:col-end-4"
  | "xl:col-end-4"
  | "lg:col-end-4"
  | "md:col-end-4"
  | "sm:col-end-4"
  | "xs:col-end-4"
  | "2xl:col-end-5"
  | "1xl:col-end-5"
  | "xl:col-end-5"
  | "lg:col-end-5"
  | "md:col-end-5"
  | "sm:col-end-5"
  | "xs:col-end-5"
  | "2xl:col-end-6"
  | "1xl:col-end-6"
  | "xl:col-end-6"
  | "lg:col-end-6"
  | "md:col-end-6"
  | "sm:col-end-6"
  | "xs:col-end-6"
  | "2xl:col-end-7"
  | "1xl:col-end-7"
  | "xl:col-end-7"
  | "lg:col-end-7"
  | "md:col-end-7"
  | "sm:col-end-7"
  | "xs:col-end-7"
  | "2xl:col-end-8"
  | "1xl:col-end-8"
  | "xl:col-end-8"
  | "lg:col-end-8"
  | "md:col-end-8"
  | "sm:col-end-8"
  | "xs:col-end-8"
  | "2xl:col-end-9"
  | "1xl:col-end-9"
  | "xl:col-end-9"
  | "lg:col-end-9"
  | "md:col-end-9"
  | "sm:col-end-9"
  | "xs:col-end-9"
  | "2xl:col-end-10"
  | "1xl:col-end-10"
  | "xl:col-end-10"
  | "lg:col-end-10"
  | "md:col-end-10"
  | "sm:col-end-10"
  | "xs:col-end-10"
  | "2xl:col-end-11"
  | "1xl:col-end-11"
  | "xl:col-end-11"
  | "lg:col-end-11"
  | "md:col-end-11"
  | "sm:col-end-11"
  | "xs:col-end-11"
  | "2xl:col-end-12"
  | "1xl:col-end-12"
  | "xl:col-end-12"
  | "lg:col-end-12"
  | "md:col-end-12"
  | "sm:col-end-12"
  | "xs:col-end-12"
  | "2xl:col-end-13"
  | "1xl:col-end-13"
  | "xl:col-end-13"
  | "lg:col-end-13"
  | "md:col-end-13"
  | "sm:col-end-13"
  | "xs:col-end-13"
  | "2xl:col-end-auto"
  | "1xl:col-end-auto"
  | "xl:col-end-auto"
  | "lg:col-end-auto"
  | "md:col-end-auto"
  | "sm:col-end-auto"
  | "xs:col-end-auto"
  | "2xl:col-start-1"
  | "1xl:col-start-1"
  | "xl:col-start-1"
  | "lg:col-start-1"
  | "md:col-start-1"
  | "sm:col-start-1"
  | "xs:col-start-1"
  | "2xl:col-start-2"
  | "1xl:col-start-2"
  | "xl:col-start-2"
  | "lg:col-start-2"
  | "md:col-start-2"
  | "sm:col-start-2"
  | "xs:col-start-2"
  | "2xl:col-start-3"
  | "1xl:col-start-3"
  | "xl:col-start-3"
  | "lg:col-start-3"
  | "md:col-start-3"
  | "sm:col-start-3"
  | "xs:col-start-3"
  | "2xl:col-start-4"
  | "1xl:col-start-4"
  | "xl:col-start-4"
  | "lg:col-start-4"
  | "md:col-start-4"
  | "sm:col-start-4"
  | "xs:col-start-4"
  | "2xl:col-start-5"
  | "1xl:col-start-5"
  | "xl:col-start-5"
  | "lg:col-start-5"
  | "md:col-start-5"
  | "sm:col-start-5"
  | "xs:col-start-5"
  | "2xl:col-start-6"
  | "1xl:col-start-6"
  | "xl:col-start-6"
  | "lg:col-start-6"
  | "md:col-start-6"
  | "sm:col-start-6"
  | "xs:col-start-6"
  | "2xl:col-start-7"
  | "1xl:col-start-7"
  | "xl:col-start-7"
  | "lg:col-start-7"
  | "md:col-start-7"
  | "sm:col-start-7"
  | "xs:col-start-7"
  | "2xl:col-start-8"
  | "1xl:col-start-8"
  | "xl:col-start-8"
  | "lg:col-start-8"
  | "md:col-start-8"
  | "sm:col-start-8"
  | "xs:col-start-8"
  | "2xl:col-start-9"
  | "1xl:col-start-9"
  | "xl:col-start-9"
  | "lg:col-start-9"
  | "md:col-start-9"
  | "sm:col-start-9"
  | "xs:col-start-9"
  | "2xl:col-start-10"
  | "1xl:col-start-10"
  | "xl:col-start-10"
  | "lg:col-start-10"
  | "md:col-start-10"
  | "sm:col-start-10"
  | "xs:col-start-10"
  | "2xl:col-start-11"
  | "1xl:col-start-11"
  | "xl:col-start-11"
  | "lg:col-start-11"
  | "md:col-start-11"
  | "sm:col-start-11"
  | "xs:col-start-11"
  | "2xl:col-start-12"
  | "1xl:col-start-12"
  | "xl:col-start-12"
  | "lg:col-start-12"
  | "md:col-start-12"
  | "sm:col-start-12"
  | "xs:col-start-12"
  | "2xl:col-start-13"
  | "1xl:col-start-13"
  | "xl:col-start-13"
  | "lg:col-start-13"
  | "md:col-start-13"
  | "sm:col-start-13"
  | "xs:col-start-13"
  | "2xl:col-start-auto"
  | "1xl:col-start-auto"
  | "xl:col-start-auto"
  | "lg:col-start-auto"
  | "md:col-start-auto"
  | "sm:col-start-auto"
  | "xs:col-start-auto"
  | "2xl:row-auto"
  | "1xl:row-auto"
  | "xl:row-auto"
  | "lg:row-auto"
  | "md:row-auto"
  | "sm:row-auto"
  | "xs:row-auto"
  | "2xl:row-span-1"
  | "1xl:row-span-1"
  | "xl:row-span-1"
  | "lg:row-span-1"
  | "md:row-span-1"
  | "sm:row-span-1"
  | "xs:row-span-1"
  | "2xl:row-span-2"
  | "1xl:row-span-2"
  | "xl:row-span-2"
  | "lg:row-span-2"
  | "md:row-span-2"
  | "sm:row-span-2"
  | "xs:row-span-2"
  | "2xl:row-span-3"
  | "1xl:row-span-3"
  | "xl:row-span-3"
  | "lg:row-span-3"
  | "md:row-span-3"
  | "sm:row-span-3"
  | "xs:row-span-3"
  | "2xl:row-span-4"
  | "1xl:row-span-4"
  | "xl:row-span-4"
  | "lg:row-span-4"
  | "md:row-span-4"
  | "sm:row-span-4"
  | "xs:row-span-4"
  | "2xl:row-span-5"
  | "1xl:row-span-5"
  | "xl:row-span-5"
  | "lg:row-span-5"
  | "md:row-span-5"
  | "sm:row-span-5"
  | "xs:row-span-5"
  | "2xl:row-span-6"
  | "1xl:row-span-6"
  | "xl:row-span-6"
  | "lg:row-span-6"
  | "md:row-span-6"
  | "sm:row-span-6"
  | "xs:row-span-6"
  | "2xl:row-span-full"
  | "1xl:row-span-full"
  | "xl:row-span-full"
  | "lg:row-span-full"
  | "md:row-span-full"
  | "sm:row-span-full"
  | "xs:row-span-full"
  | "2xl:row-end-1"
  | "1xl:row-end-1"
  | "xl:row-end-1"
  | "lg:row-end-1"
  | "md:row-end-1"
  | "sm:row-end-1"
  | "xs:row-end-1"
  | "2xl:row-end-2"
  | "1xl:row-end-2"
  | "xl:row-end-2"
  | "lg:row-end-2"
  | "md:row-end-2"
  | "sm:row-end-2"
  | "xs:row-end-2"
  | "2xl:row-end-3"
  | "1xl:row-end-3"
  | "xl:row-end-3"
  | "lg:row-end-3"
  | "md:row-end-3"
  | "sm:row-end-3"
  | "xs:row-end-3"
  | "2xl:row-end-4"
  | "1xl:row-end-4"
  | "xl:row-end-4"
  | "lg:row-end-4"
  | "md:row-end-4"
  | "sm:row-end-4"
  | "xs:row-end-4"
  | "2xl:row-end-5"
  | "1xl:row-end-5"
  | "xl:row-end-5"
  | "lg:row-end-5"
  | "md:row-end-5"
  | "sm:row-end-5"
  | "xs:row-end-5"
  | "2xl:row-end-6"
  | "1xl:row-end-6"
  | "xl:row-end-6"
  | "lg:row-end-6"
  | "md:row-end-6"
  | "sm:row-end-6"
  | "xs:row-end-6"
  | "2xl:row-end-7"
  | "1xl:row-end-7"
  | "xl:row-end-7"
  | "lg:row-end-7"
  | "md:row-end-7"
  | "sm:row-end-7"
  | "xs:row-end-7"
  | "2xl:row-end-auto"
  | "1xl:row-end-auto"
  | "xl:row-end-auto"
  | "lg:row-end-auto"
  | "md:row-end-auto"
  | "sm:row-end-auto"
  | "xs:row-end-auto"
  | "2xl:row-start-1"
  | "1xl:row-start-1"
  | "xl:row-start-1"
  | "lg:row-start-1"
  | "md:row-start-1"
  | "sm:row-start-1"
  | "xs:row-start-1"
  | "2xl:row-start-2"
  | "1xl:row-start-2"
  | "xl:row-start-2"
  | "lg:row-start-2"
  | "md:row-start-2"
  | "sm:row-start-2"
  | "xs:row-start-2"
  | "2xl:row-start-3"
  | "1xl:row-start-3"
  | "xl:row-start-3"
  | "lg:row-start-3"
  | "md:row-start-3"
  | "sm:row-start-3"
  | "xs:row-start-3"
  | "2xl:row-start-4"
  | "1xl:row-start-4"
  | "xl:row-start-4"
  | "lg:row-start-4"
  | "md:row-start-4"
  | "sm:row-start-4"
  | "xs:row-start-4"
  | "2xl:row-start-5"
  | "1xl:row-start-5"
  | "xl:row-start-5"
  | "lg:row-start-5"
  | "md:row-start-5"
  | "sm:row-start-5"
  | "xs:row-start-5"
  | "2xl:row-start-6"
  | "1xl:row-start-6"
  | "xl:row-start-6"
  | "lg:row-start-6"
  | "md:row-start-6"
  | "sm:row-start-6"
  | "xs:row-start-6"
  | "2xl:row-start-7"
  | "1xl:row-start-7"
  | "xl:row-start-7"
  | "lg:row-start-7"
  | "md:row-start-7"
  | "sm:row-start-7"
  | "xs:row-start-7"
  | "2xl:row-start-auto"
  | "1xl:row-start-auto"
  | "xl:row-start-auto"
  | "lg:row-start-auto"
  | "md:row-start-auto"
  | "sm:row-start-auto"
  | "xs:row-start-auto"
  | "2xl:grid-cols-1"
  | "1xl:grid-cols-1"
  | "xl:grid-cols-1"
  | "lg:grid-cols-1"
  | "md:grid-cols-1"
  | "sm:grid-cols-1"
  | "xs:grid-cols-1"
  | "2xl:grid-cols-2"
  | "1xl:grid-cols-2"
  | "xl:grid-cols-2"
  | "lg:grid-cols-2"
  | "md:grid-cols-2"
  | "sm:grid-cols-2"
  | "xs:grid-cols-2"
  | "2xl:grid-cols-3"
  | "1xl:grid-cols-3"
  | "xl:grid-cols-3"
  | "lg:grid-cols-3"
  | "md:grid-cols-3"
  | "sm:grid-cols-3"
  | "xs:grid-cols-3"
  | "2xl:grid-cols-4"
  | "1xl:grid-cols-4"
  | "xl:grid-cols-4"
  | "lg:grid-cols-4"
  | "md:grid-cols-4"
  | "sm:grid-cols-4"
  | "xs:grid-cols-4"
  | "2xl:grid-cols-5"
  | "1xl:grid-cols-5"
  | "xl:grid-cols-5"
  | "lg:grid-cols-5"
  | "md:grid-cols-5"
  | "sm:grid-cols-5"
  | "xs:grid-cols-5"
  | "2xl:grid-cols-6"
  | "1xl:grid-cols-6"
  | "xl:grid-cols-6"
  | "lg:grid-cols-6"
  | "md:grid-cols-6"
  | "sm:grid-cols-6"
  | "xs:grid-cols-6"
  | "2xl:grid-cols-7"
  | "1xl:grid-cols-7"
  | "xl:grid-cols-7"
  | "lg:grid-cols-7"
  | "md:grid-cols-7"
  | "sm:grid-cols-7"
  | "xs:grid-cols-7"
  | "2xl:grid-cols-8"
  | "1xl:grid-cols-8"
  | "xl:grid-cols-8"
  | "lg:grid-cols-8"
  | "md:grid-cols-8"
  | "sm:grid-cols-8"
  | "xs:grid-cols-8"
  | "2xl:grid-cols-9"
  | "1xl:grid-cols-9"
  | "xl:grid-cols-9"
  | "lg:grid-cols-9"
  | "md:grid-cols-9"
  | "sm:grid-cols-9"
  | "xs:grid-cols-9"
  | "2xl:grid-cols-10"
  | "1xl:grid-cols-10"
  | "xl:grid-cols-10"
  | "lg:grid-cols-10"
  | "md:grid-cols-10"
  | "sm:grid-cols-10"
  | "xs:grid-cols-10"
  | "2xl:grid-cols-11"
  | "1xl:grid-cols-11"
  | "xl:grid-cols-11"
  | "lg:grid-cols-11"
  | "md:grid-cols-11"
  | "sm:grid-cols-11"
  | "xs:grid-cols-11"
  | "2xl:grid-cols-12"
  | "1xl:grid-cols-12"
  | "xl:grid-cols-12"
  | "lg:grid-cols-12"
  | "md:grid-cols-12"
  | "sm:grid-cols-12"
  | "xs:grid-cols-12"
  | "2xl:grid-cols-none"
  | "1xl:grid-cols-none"
  | "xl:grid-cols-none"
  | "lg:grid-cols-none"
  | "md:grid-cols-none"
  | "sm:grid-cols-none"
  | "xs:grid-cols-none"
  | "2xl:grid-rows-1"
  | "1xl:grid-rows-1"
  | "xl:grid-rows-1"
  | "lg:grid-rows-1"
  | "md:grid-rows-1"
  | "sm:grid-rows-1"
  | "xs:grid-rows-1"
  | "2xl:grid-rows-2"
  | "1xl:grid-rows-2"
  | "xl:grid-rows-2"
  | "lg:grid-rows-2"
  | "md:grid-rows-2"
  | "sm:grid-rows-2"
  | "xs:grid-rows-2"
  | "2xl:grid-rows-3"
  | "1xl:grid-rows-3"
  | "xl:grid-rows-3"
  | "lg:grid-rows-3"
  | "md:grid-rows-3"
  | "sm:grid-rows-3"
  | "xs:grid-rows-3"
  | "2xl:grid-rows-4"
  | "1xl:grid-rows-4"
  | "xl:grid-rows-4"
  | "lg:grid-rows-4"
  | "md:grid-rows-4"
  | "sm:grid-rows-4"
  | "xs:grid-rows-4"
  | "2xl:grid-rows-5"
  | "1xl:grid-rows-5"
  | "xl:grid-rows-5"
  | "lg:grid-rows-5"
  | "md:grid-rows-5"
  | "sm:grid-rows-5"
  | "xs:grid-rows-5"
  | "2xl:grid-rows-6"
  | "1xl:grid-rows-6"
  | "xl:grid-rows-6"
  | "lg:grid-rows-6"
  | "md:grid-rows-6"
  | "sm:grid-rows-6"
  | "xs:grid-rows-6"
  | "2xl:grid-rows-none"
  | "1xl:grid-rows-none"
  | "xl:grid-rows-none"
  | "lg:grid-rows-none"
  | "md:grid-rows-none"
  | "sm:grid-rows-none"
  | "xs:grid-rows-none"
  | "2xl:grid-rows-fit"
  | "1xl:grid-rows-fit"
  | "xl:grid-rows-fit"
  | "lg:grid-rows-fit"
  | "md:grid-rows-fit"
  | "sm:grid-rows-fit"
  | "xs:grid-rows-fit"
  | "2xl:h-0"
  | "1xl:h-0"
  | "xl:h-0"
  | "lg:h-0"
  | "md:h-0"
  | "sm:h-0"
  | "xs:h-0"
  | "2xl:h-1"
  | "1xl:h-1"
  | "xl:h-1"
  | "lg:h-1"
  | "md:h-1"
  | "sm:h-1"
  | "xs:h-1"
  | "2xl:h-2"
  | "1xl:h-2"
  | "xl:h-2"
  | "lg:h-2"
  | "md:h-2"
  | "sm:h-2"
  | "xs:h-2"
  | "2xl:h-3"
  | "1xl:h-3"
  | "xl:h-3"
  | "lg:h-3"
  | "md:h-3"
  | "sm:h-3"
  | "xs:h-3"
  | "2xl:h-4"
  | "1xl:h-4"
  | "xl:h-4"
  | "lg:h-4"
  | "md:h-4"
  | "sm:h-4"
  | "xs:h-4"
  | "2xl:h-5"
  | "1xl:h-5"
  | "xl:h-5"
  | "lg:h-5"
  | "md:h-5"
  | "sm:h-5"
  | "xs:h-5"
  | "2xl:h-6"
  | "1xl:h-6"
  | "xl:h-6"
  | "lg:h-6"
  | "md:h-6"
  | "sm:h-6"
  | "xs:h-6"
  | "2xl:h-7"
  | "1xl:h-7"
  | "xl:h-7"
  | "lg:h-7"
  | "md:h-7"
  | "sm:h-7"
  | "xs:h-7"
  | "2xl:h-8"
  | "1xl:h-8"
  | "xl:h-8"
  | "lg:h-8"
  | "md:h-8"
  | "sm:h-8"
  | "xs:h-8"
  | "2xl:h-9"
  | "1xl:h-9"
  | "xl:h-9"
  | "lg:h-9"
  | "md:h-9"
  | "sm:h-9"
  | "xs:h-9"
  | "2xl:h-10"
  | "1xl:h-10"
  | "xl:h-10"
  | "lg:h-10"
  | "md:h-10"
  | "sm:h-10"
  | "xs:h-10"
  | "2xl:h-11"
  | "1xl:h-11"
  | "xl:h-11"
  | "lg:h-11"
  | "md:h-11"
  | "sm:h-11"
  | "xs:h-11"
  | "2xl:h-12"
  | "1xl:h-12"
  | "xl:h-12"
  | "lg:h-12"
  | "md:h-12"
  | "sm:h-12"
  | "xs:h-12"
  | "2xl:h-14"
  | "1xl:h-14"
  | "xl:h-14"
  | "lg:h-14"
  | "md:h-14"
  | "sm:h-14"
  | "xs:h-14"
  | "2xl:h-16"
  | "1xl:h-16"
  | "xl:h-16"
  | "lg:h-16"
  | "md:h-16"
  | "sm:h-16"
  | "xs:h-16"
  | "2xl:h-20"
  | "1xl:h-20"
  | "xl:h-20"
  | "lg:h-20"
  | "md:h-20"
  | "sm:h-20"
  | "xs:h-20"
  | "2xl:h-24"
  | "1xl:h-24"
  | "xl:h-24"
  | "lg:h-24"
  | "md:h-24"
  | "sm:h-24"
  | "xs:h-24"
  | "2xl:h-28"
  | "1xl:h-28"
  | "xl:h-28"
  | "lg:h-28"
  | "md:h-28"
  | "sm:h-28"
  | "xs:h-28"
  | "2xl:h-32"
  | "1xl:h-32"
  | "xl:h-32"
  | "lg:h-32"
  | "md:h-32"
  | "sm:h-32"
  | "xs:h-32"
  | "2xl:h-36"
  | "1xl:h-36"
  | "xl:h-36"
  | "lg:h-36"
  | "md:h-36"
  | "sm:h-36"
  | "xs:h-36"
  | "2xl:h-40"
  | "1xl:h-40"
  | "xl:h-40"
  | "lg:h-40"
  | "md:h-40"
  | "sm:h-40"
  | "xs:h-40"
  | "2xl:h-44"
  | "1xl:h-44"
  | "xl:h-44"
  | "lg:h-44"
  | "md:h-44"
  | "sm:h-44"
  | "xs:h-44"
  | "2xl:h-48"
  | "1xl:h-48"
  | "xl:h-48"
  | "lg:h-48"
  | "md:h-48"
  | "sm:h-48"
  | "xs:h-48"
  | "2xl:h-52"
  | "1xl:h-52"
  | "xl:h-52"
  | "lg:h-52"
  | "md:h-52"
  | "sm:h-52"
  | "xs:h-52"
  | "2xl:h-56"
  | "1xl:h-56"
  | "xl:h-56"
  | "lg:h-56"
  | "md:h-56"
  | "sm:h-56"
  | "xs:h-56"
  | "2xl:h-60"
  | "1xl:h-60"
  | "xl:h-60"
  | "lg:h-60"
  | "md:h-60"
  | "sm:h-60"
  | "xs:h-60"
  | "2xl:h-64"
  | "1xl:h-64"
  | "xl:h-64"
  | "lg:h-64"
  | "md:h-64"
  | "sm:h-64"
  | "xs:h-64"
  | "2xl:h-72"
  | "1xl:h-72"
  | "xl:h-72"
  | "lg:h-72"
  | "md:h-72"
  | "sm:h-72"
  | "xs:h-72"
  | "2xl:h-80"
  | "1xl:h-80"
  | "xl:h-80"
  | "lg:h-80"
  | "md:h-80"
  | "sm:h-80"
  | "xs:h-80"
  | "2xl:h-96"
  | "1xl:h-96"
  | "xl:h-96"
  | "lg:h-96"
  | "md:h-96"
  | "sm:h-96"
  | "xs:h-96"
  | "2xl:h-192"
  | "1xl:h-192"
  | "xl:h-192"
  | "lg:h-192"
  | "md:h-192"
  | "sm:h-192"
  | "xs:h-192"
  | "2xl:h-auto"
  | "1xl:h-auto"
  | "xl:h-auto"
  | "lg:h-auto"
  | "md:h-auto"
  | "sm:h-auto"
  | "xs:h-auto"
  | "2xl:h-px"
  | "1xl:h-px"
  | "xl:h-px"
  | "lg:h-px"
  | "md:h-px"
  | "sm:h-px"
  | "xs:h-px"
  | "2xl:h-0.5"
  | "1xl:h-0.5"
  | "xl:h-0.5"
  | "lg:h-0.5"
  | "md:h-0.5"
  | "sm:h-0.5"
  | "xs:h-0.5"
  | "2xl:h-1.5"
  | "1xl:h-1.5"
  | "xl:h-1.5"
  | "lg:h-1.5"
  | "md:h-1.5"
  | "sm:h-1.5"
  | "xs:h-1.5"
  | "2xl:h-2.5"
  | "1xl:h-2.5"
  | "xl:h-2.5"
  | "lg:h-2.5"
  | "md:h-2.5"
  | "sm:h-2.5"
  | "xs:h-2.5"
  | "2xl:h-3.5"
  | "1xl:h-3.5"
  | "xl:h-3.5"
  | "lg:h-3.5"
  | "md:h-3.5"
  | "sm:h-3.5"
  | "xs:h-3.5"
  | "2xl:h-1/2"
  | "1xl:h-1/2"
  | "xl:h-1/2"
  | "lg:h-1/2"
  | "md:h-1/2"
  | "sm:h-1/2"
  | "xs:h-1/2"
  | "2xl:h-1/3"
  | "1xl:h-1/3"
  | "xl:h-1/3"
  | "lg:h-1/3"
  | "md:h-1/3"
  | "sm:h-1/3"
  | "xs:h-1/3"
  | "2xl:h-2/3"
  | "1xl:h-2/3"
  | "xl:h-2/3"
  | "lg:h-2/3"
  | "md:h-2/3"
  | "sm:h-2/3"
  | "xs:h-2/3"
  | "2xl:h-1/4"
  | "1xl:h-1/4"
  | "xl:h-1/4"
  | "lg:h-1/4"
  | "md:h-1/4"
  | "sm:h-1/4"
  | "xs:h-1/4"
  | "2xl:h-2/4"
  | "1xl:h-2/4"
  | "xl:h-2/4"
  | "lg:h-2/4"
  | "md:h-2/4"
  | "sm:h-2/4"
  | "xs:h-2/4"
  | "2xl:h-3/4"
  | "1xl:h-3/4"
  | "xl:h-3/4"
  | "lg:h-3/4"
  | "md:h-3/4"
  | "sm:h-3/4"
  | "xs:h-3/4"
  | "2xl:h-1/5"
  | "1xl:h-1/5"
  | "xl:h-1/5"
  | "lg:h-1/5"
  | "md:h-1/5"
  | "sm:h-1/5"
  | "xs:h-1/5"
  | "2xl:h-2/5"
  | "1xl:h-2/5"
  | "xl:h-2/5"
  | "lg:h-2/5"
  | "md:h-2/5"
  | "sm:h-2/5"
  | "xs:h-2/5"
  | "2xl:h-3/5"
  | "1xl:h-3/5"
  | "xl:h-3/5"
  | "lg:h-3/5"
  | "md:h-3/5"
  | "sm:h-3/5"
  | "xs:h-3/5"
  | "2xl:h-4/5"
  | "1xl:h-4/5"
  | "xl:h-4/5"
  | "lg:h-4/5"
  | "md:h-4/5"
  | "sm:h-4/5"
  | "xs:h-4/5"
  | "2xl:h-1/6"
  | "1xl:h-1/6"
  | "xl:h-1/6"
  | "lg:h-1/6"
  | "md:h-1/6"
  | "sm:h-1/6"
  | "xs:h-1/6"
  | "2xl:h-2/6"
  | "1xl:h-2/6"
  | "xl:h-2/6"
  | "lg:h-2/6"
  | "md:h-2/6"
  | "sm:h-2/6"
  | "xs:h-2/6"
  | "2xl:h-3/6"
  | "1xl:h-3/6"
  | "xl:h-3/6"
  | "lg:h-3/6"
  | "md:h-3/6"
  | "sm:h-3/6"
  | "xs:h-3/6"
  | "2xl:h-4/6"
  | "1xl:h-4/6"
  | "xl:h-4/6"
  | "lg:h-4/6"
  | "md:h-4/6"
  | "sm:h-4/6"
  | "xs:h-4/6"
  | "2xl:h-5/6"
  | "1xl:h-5/6"
  | "xl:h-5/6"
  | "lg:h-5/6"
  | "md:h-5/6"
  | "sm:h-5/6"
  | "xs:h-5/6"
  | "2xl:h-full"
  | "1xl:h-full"
  | "xl:h-full"
  | "lg:h-full"
  | "md:h-full"
  | "sm:h-full"
  | "xs:h-full"
  | "2xl:h-screen"
  | "1xl:h-screen"
  | "xl:h-screen"
  | "lg:h-screen"
  | "md:h-screen"
  | "sm:h-screen"
  | "xs:h-screen"
  | "2xl:h-4.5"
  | "1xl:h-4.5"
  | "xl:h-4.5"
  | "lg:h-4.5"
  | "md:h-4.5"
  | "sm:h-4.5"
  | "xs:h-4.5"
  | "2xl:h-vh-full"
  | "1xl:h-vh-full"
  | "xl:h-vh-full"
  | "lg:h-vh-full"
  | "md:h-vh-full"
  | "sm:h-vh-full"
  | "xs:h-vh-full"
  | "2xl:h-full-dynamic"
  | "1xl:h-full-dynamic"
  | "xl:h-full-dynamic"
  | "lg:h-full-dynamic"
  | "md:h-full-dynamic"
  | "sm:h-full-dynamic"
  | "xs:h-full-dynamic"
  | "2xl:hue-rotate-0"
  | "1xl:hue-rotate-0"
  | "xl:hue-rotate-0"
  | "lg:hue-rotate-0"
  | "md:hue-rotate-0"
  | "sm:hue-rotate-0"
  | "xs:hue-rotate-0"
  | "2xl:hue-rotate-15"
  | "1xl:hue-rotate-15"
  | "xl:hue-rotate-15"
  | "lg:hue-rotate-15"
  | "md:hue-rotate-15"
  | "sm:hue-rotate-15"
  | "xs:hue-rotate-15"
  | "2xl:hue-rotate-30"
  | "1xl:hue-rotate-30"
  | "xl:hue-rotate-30"
  | "lg:hue-rotate-30"
  | "md:hue-rotate-30"
  | "sm:hue-rotate-30"
  | "xs:hue-rotate-30"
  | "2xl:hue-rotate-60"
  | "1xl:hue-rotate-60"
  | "xl:hue-rotate-60"
  | "lg:hue-rotate-60"
  | "md:hue-rotate-60"
  | "sm:hue-rotate-60"
  | "xs:hue-rotate-60"
  | "2xl:hue-rotate-90"
  | "1xl:hue-rotate-90"
  | "xl:hue-rotate-90"
  | "lg:hue-rotate-90"
  | "md:hue-rotate-90"
  | "sm:hue-rotate-90"
  | "xs:hue-rotate-90"
  | "2xl:hue-rotate-180"
  | "1xl:hue-rotate-180"
  | "xl:hue-rotate-180"
  | "lg:hue-rotate-180"
  | "md:hue-rotate-180"
  | "sm:hue-rotate-180"
  | "xs:hue-rotate-180"
  | "2xl:hue-rotate--180"
  | "1xl:hue-rotate--180"
  | "xl:hue-rotate--180"
  | "lg:hue-rotate--180"
  | "md:hue-rotate--180"
  | "sm:hue-rotate--180"
  | "xs:hue-rotate--180"
  | "2xl:hue-rotate--90"
  | "1xl:hue-rotate--90"
  | "xl:hue-rotate--90"
  | "lg:hue-rotate--90"
  | "md:hue-rotate--90"
  | "sm:hue-rotate--90"
  | "xs:hue-rotate--90"
  | "2xl:hue-rotate--60"
  | "1xl:hue-rotate--60"
  | "xl:hue-rotate--60"
  | "lg:hue-rotate--60"
  | "md:hue-rotate--60"
  | "sm:hue-rotate--60"
  | "xs:hue-rotate--60"
  | "2xl:hue-rotate--30"
  | "1xl:hue-rotate--30"
  | "xl:hue-rotate--30"
  | "lg:hue-rotate--30"
  | "md:hue-rotate--30"
  | "sm:hue-rotate--30"
  | "xs:hue-rotate--30"
  | "2xl:hue-rotate--15"
  | "1xl:hue-rotate--15"
  | "xl:hue-rotate--15"
  | "lg:hue-rotate--15"
  | "md:hue-rotate--15"
  | "sm:hue-rotate--15"
  | "xs:hue-rotate--15"
  | "2xl:inset-0"
  | "1xl:inset-0"
  | "xl:inset-0"
  | "lg:inset-0"
  | "md:inset-0"
  | "sm:inset-0"
  | "xs:inset-0"
  | "2xl:inset-x-0"
  | "1xl:inset-x-0"
  | "xl:inset-x-0"
  | "lg:inset-x-0"
  | "md:inset-x-0"
  | "sm:inset-x-0"
  | "xs:inset-x-0"
  | "2xl:inset-y-0"
  | "1xl:inset-y-0"
  | "xl:inset-y-0"
  | "lg:inset-y-0"
  | "md:inset-y-0"
  | "sm:inset-y-0"
  | "xs:inset-y-0"
  | "2xl:top-0"
  | "1xl:top-0"
  | "xl:top-0"
  | "lg:top-0"
  | "md:top-0"
  | "sm:top-0"
  | "xs:top-0"
  | "2xl:right-0"
  | "1xl:right-0"
  | "xl:right-0"
  | "lg:right-0"
  | "md:right-0"
  | "sm:right-0"
  | "xs:right-0"
  | "2xl:bottom-0"
  | "1xl:bottom-0"
  | "xl:bottom-0"
  | "lg:bottom-0"
  | "md:bottom-0"
  | "sm:bottom-0"
  | "xs:bottom-0"
  | "2xl:left-0"
  | "1xl:left-0"
  | "xl:left-0"
  | "lg:left-0"
  | "md:left-0"
  | "sm:left-0"
  | "xs:left-0"
  | "2xl:inset-1"
  | "1xl:inset-1"
  | "xl:inset-1"
  | "lg:inset-1"
  | "md:inset-1"
  | "sm:inset-1"
  | "xs:inset-1"
  | "2xl:inset-x-1"
  | "1xl:inset-x-1"
  | "xl:inset-x-1"
  | "lg:inset-x-1"
  | "md:inset-x-1"
  | "sm:inset-x-1"
  | "xs:inset-x-1"
  | "2xl:inset-y-1"
  | "1xl:inset-y-1"
  | "xl:inset-y-1"
  | "lg:inset-y-1"
  | "md:inset-y-1"
  | "sm:inset-y-1"
  | "xs:inset-y-1"
  | "2xl:top-1"
  | "1xl:top-1"
  | "xl:top-1"
  | "lg:top-1"
  | "md:top-1"
  | "sm:top-1"
  | "xs:top-1"
  | "2xl:right-1"
  | "1xl:right-1"
  | "xl:right-1"
  | "lg:right-1"
  | "md:right-1"
  | "sm:right-1"
  | "xs:right-1"
  | "2xl:bottom-1"
  | "1xl:bottom-1"
  | "xl:bottom-1"
  | "lg:bottom-1"
  | "md:bottom-1"
  | "sm:bottom-1"
  | "xs:bottom-1"
  | "2xl:left-1"
  | "1xl:left-1"
  | "xl:left-1"
  | "lg:left-1"
  | "md:left-1"
  | "sm:left-1"
  | "xs:left-1"
  | "2xl:inset-2"
  | "1xl:inset-2"
  | "xl:inset-2"
  | "lg:inset-2"
  | "md:inset-2"
  | "sm:inset-2"
  | "xs:inset-2"
  | "2xl:inset-x-2"
  | "1xl:inset-x-2"
  | "xl:inset-x-2"
  | "lg:inset-x-2"
  | "md:inset-x-2"
  | "sm:inset-x-2"
  | "xs:inset-x-2"
  | "2xl:inset-y-2"
  | "1xl:inset-y-2"
  | "xl:inset-y-2"
  | "lg:inset-y-2"
  | "md:inset-y-2"
  | "sm:inset-y-2"
  | "xs:inset-y-2"
  | "2xl:top-2"
  | "1xl:top-2"
  | "xl:top-2"
  | "lg:top-2"
  | "md:top-2"
  | "sm:top-2"
  | "xs:top-2"
  | "2xl:right-2"
  | "1xl:right-2"
  | "xl:right-2"
  | "lg:right-2"
  | "md:right-2"
  | "sm:right-2"
  | "xs:right-2"
  | "2xl:bottom-2"
  | "1xl:bottom-2"
  | "xl:bottom-2"
  | "lg:bottom-2"
  | "md:bottom-2"
  | "sm:bottom-2"
  | "xs:bottom-2"
  | "2xl:left-2"
  | "1xl:left-2"
  | "xl:left-2"
  | "lg:left-2"
  | "md:left-2"
  | "sm:left-2"
  | "xs:left-2"
  | "2xl:inset-3"
  | "1xl:inset-3"
  | "xl:inset-3"
  | "lg:inset-3"
  | "md:inset-3"
  | "sm:inset-3"
  | "xs:inset-3"
  | "2xl:inset-x-3"
  | "1xl:inset-x-3"
  | "xl:inset-x-3"
  | "lg:inset-x-3"
  | "md:inset-x-3"
  | "sm:inset-x-3"
  | "xs:inset-x-3"
  | "2xl:inset-y-3"
  | "1xl:inset-y-3"
  | "xl:inset-y-3"
  | "lg:inset-y-3"
  | "md:inset-y-3"
  | "sm:inset-y-3"
  | "xs:inset-y-3"
  | "2xl:top-3"
  | "1xl:top-3"
  | "xl:top-3"
  | "lg:top-3"
  | "md:top-3"
  | "sm:top-3"
  | "xs:top-3"
  | "2xl:right-3"
  | "1xl:right-3"
  | "xl:right-3"
  | "lg:right-3"
  | "md:right-3"
  | "sm:right-3"
  | "xs:right-3"
  | "2xl:bottom-3"
  | "1xl:bottom-3"
  | "xl:bottom-3"
  | "lg:bottom-3"
  | "md:bottom-3"
  | "sm:bottom-3"
  | "xs:bottom-3"
  | "2xl:left-3"
  | "1xl:left-3"
  | "xl:left-3"
  | "lg:left-3"
  | "md:left-3"
  | "sm:left-3"
  | "xs:left-3"
  | "2xl:inset-4"
  | "1xl:inset-4"
  | "xl:inset-4"
  | "lg:inset-4"
  | "md:inset-4"
  | "sm:inset-4"
  | "xs:inset-4"
  | "2xl:inset-x-4"
  | "1xl:inset-x-4"
  | "xl:inset-x-4"
  | "lg:inset-x-4"
  | "md:inset-x-4"
  | "sm:inset-x-4"
  | "xs:inset-x-4"
  | "2xl:inset-y-4"
  | "1xl:inset-y-4"
  | "xl:inset-y-4"
  | "lg:inset-y-4"
  | "md:inset-y-4"
  | "sm:inset-y-4"
  | "xs:inset-y-4"
  | "2xl:top-4"
  | "1xl:top-4"
  | "xl:top-4"
  | "lg:top-4"
  | "md:top-4"
  | "sm:top-4"
  | "xs:top-4"
  | "2xl:right-4"
  | "1xl:right-4"
  | "xl:right-4"
  | "lg:right-4"
  | "md:right-4"
  | "sm:right-4"
  | "xs:right-4"
  | "2xl:bottom-4"
  | "1xl:bottom-4"
  | "xl:bottom-4"
  | "lg:bottom-4"
  | "md:bottom-4"
  | "sm:bottom-4"
  | "xs:bottom-4"
  | "2xl:left-4"
  | "1xl:left-4"
  | "xl:left-4"
  | "lg:left-4"
  | "md:left-4"
  | "sm:left-4"
  | "xs:left-4"
  | "2xl:inset-5"
  | "1xl:inset-5"
  | "xl:inset-5"
  | "lg:inset-5"
  | "md:inset-5"
  | "sm:inset-5"
  | "xs:inset-5"
  | "2xl:inset-x-5"
  | "1xl:inset-x-5"
  | "xl:inset-x-5"
  | "lg:inset-x-5"
  | "md:inset-x-5"
  | "sm:inset-x-5"
  | "xs:inset-x-5"
  | "2xl:inset-y-5"
  | "1xl:inset-y-5"
  | "xl:inset-y-5"
  | "lg:inset-y-5"
  | "md:inset-y-5"
  | "sm:inset-y-5"
  | "xs:inset-y-5"
  | "2xl:top-5"
  | "1xl:top-5"
  | "xl:top-5"
  | "lg:top-5"
  | "md:top-5"
  | "sm:top-5"
  | "xs:top-5"
  | "2xl:right-5"
  | "1xl:right-5"
  | "xl:right-5"
  | "lg:right-5"
  | "md:right-5"
  | "sm:right-5"
  | "xs:right-5"
  | "2xl:bottom-5"
  | "1xl:bottom-5"
  | "xl:bottom-5"
  | "lg:bottom-5"
  | "md:bottom-5"
  | "sm:bottom-5"
  | "xs:bottom-5"
  | "2xl:left-5"
  | "1xl:left-5"
  | "xl:left-5"
  | "lg:left-5"
  | "md:left-5"
  | "sm:left-5"
  | "xs:left-5"
  | "2xl:inset-6"
  | "1xl:inset-6"
  | "xl:inset-6"
  | "lg:inset-6"
  | "md:inset-6"
  | "sm:inset-6"
  | "xs:inset-6"
  | "2xl:inset-x-6"
  | "1xl:inset-x-6"
  | "xl:inset-x-6"
  | "lg:inset-x-6"
  | "md:inset-x-6"
  | "sm:inset-x-6"
  | "xs:inset-x-6"
  | "2xl:inset-y-6"
  | "1xl:inset-y-6"
  | "xl:inset-y-6"
  | "lg:inset-y-6"
  | "md:inset-y-6"
  | "sm:inset-y-6"
  | "xs:inset-y-6"
  | "2xl:top-6"
  | "1xl:top-6"
  | "xl:top-6"
  | "lg:top-6"
  | "md:top-6"
  | "sm:top-6"
  | "xs:top-6"
  | "2xl:right-6"
  | "1xl:right-6"
  | "xl:right-6"
  | "lg:right-6"
  | "md:right-6"
  | "sm:right-6"
  | "xs:right-6"
  | "2xl:bottom-6"
  | "1xl:bottom-6"
  | "xl:bottom-6"
  | "lg:bottom-6"
  | "md:bottom-6"
  | "sm:bottom-6"
  | "xs:bottom-6"
  | "2xl:left-6"
  | "1xl:left-6"
  | "xl:left-6"
  | "lg:left-6"
  | "md:left-6"
  | "sm:left-6"
  | "xs:left-6"
  | "2xl:inset-7"
  | "1xl:inset-7"
  | "xl:inset-7"
  | "lg:inset-7"
  | "md:inset-7"
  | "sm:inset-7"
  | "xs:inset-7"
  | "2xl:inset-x-7"
  | "1xl:inset-x-7"
  | "xl:inset-x-7"
  | "lg:inset-x-7"
  | "md:inset-x-7"
  | "sm:inset-x-7"
  | "xs:inset-x-7"
  | "2xl:inset-y-7"
  | "1xl:inset-y-7"
  | "xl:inset-y-7"
  | "lg:inset-y-7"
  | "md:inset-y-7"
  | "sm:inset-y-7"
  | "xs:inset-y-7"
  | "2xl:top-7"
  | "1xl:top-7"
  | "xl:top-7"
  | "lg:top-7"
  | "md:top-7"
  | "sm:top-7"
  | "xs:top-7"
  | "2xl:right-7"
  | "1xl:right-7"
  | "xl:right-7"
  | "lg:right-7"
  | "md:right-7"
  | "sm:right-7"
  | "xs:right-7"
  | "2xl:bottom-7"
  | "1xl:bottom-7"
  | "xl:bottom-7"
  | "lg:bottom-7"
  | "md:bottom-7"
  | "sm:bottom-7"
  | "xs:bottom-7"
  | "2xl:left-7"
  | "1xl:left-7"
  | "xl:left-7"
  | "lg:left-7"
  | "md:left-7"
  | "sm:left-7"
  | "xs:left-7"
  | "2xl:inset-8"
  | "1xl:inset-8"
  | "xl:inset-8"
  | "lg:inset-8"
  | "md:inset-8"
  | "sm:inset-8"
  | "xs:inset-8"
  | "2xl:inset-x-8"
  | "1xl:inset-x-8"
  | "xl:inset-x-8"
  | "lg:inset-x-8"
  | "md:inset-x-8"
  | "sm:inset-x-8"
  | "xs:inset-x-8"
  | "2xl:inset-y-8"
  | "1xl:inset-y-8"
  | "xl:inset-y-8"
  | "lg:inset-y-8"
  | "md:inset-y-8"
  | "sm:inset-y-8"
  | "xs:inset-y-8"
  | "2xl:top-8"
  | "1xl:top-8"
  | "xl:top-8"
  | "lg:top-8"
  | "md:top-8"
  | "sm:top-8"
  | "xs:top-8"
  | "2xl:right-8"
  | "1xl:right-8"
  | "xl:right-8"
  | "lg:right-8"
  | "md:right-8"
  | "sm:right-8"
  | "xs:right-8"
  | "2xl:bottom-8"
  | "1xl:bottom-8"
  | "xl:bottom-8"
  | "lg:bottom-8"
  | "md:bottom-8"
  | "sm:bottom-8"
  | "xs:bottom-8"
  | "2xl:left-8"
  | "1xl:left-8"
  | "xl:left-8"
  | "lg:left-8"
  | "md:left-8"
  | "sm:left-8"
  | "xs:left-8"
  | "2xl:inset-9"
  | "1xl:inset-9"
  | "xl:inset-9"
  | "lg:inset-9"
  | "md:inset-9"
  | "sm:inset-9"
  | "xs:inset-9"
  | "2xl:inset-x-9"
  | "1xl:inset-x-9"
  | "xl:inset-x-9"
  | "lg:inset-x-9"
  | "md:inset-x-9"
  | "sm:inset-x-9"
  | "xs:inset-x-9"
  | "2xl:inset-y-9"
  | "1xl:inset-y-9"
  | "xl:inset-y-9"
  | "lg:inset-y-9"
  | "md:inset-y-9"
  | "sm:inset-y-9"
  | "xs:inset-y-9"
  | "2xl:top-9"
  | "1xl:top-9"
  | "xl:top-9"
  | "lg:top-9"
  | "md:top-9"
  | "sm:top-9"
  | "xs:top-9"
  | "2xl:right-9"
  | "1xl:right-9"
  | "xl:right-9"
  | "lg:right-9"
  | "md:right-9"
  | "sm:right-9"
  | "xs:right-9"
  | "2xl:bottom-9"
  | "1xl:bottom-9"
  | "xl:bottom-9"
  | "lg:bottom-9"
  | "md:bottom-9"
  | "sm:bottom-9"
  | "xs:bottom-9"
  | "2xl:left-9"
  | "1xl:left-9"
  | "xl:left-9"
  | "lg:left-9"
  | "md:left-9"
  | "sm:left-9"
  | "xs:left-9"
  | "2xl:inset-10"
  | "1xl:inset-10"
  | "xl:inset-10"
  | "lg:inset-10"
  | "md:inset-10"
  | "sm:inset-10"
  | "xs:inset-10"
  | "2xl:inset-x-10"
  | "1xl:inset-x-10"
  | "xl:inset-x-10"
  | "lg:inset-x-10"
  | "md:inset-x-10"
  | "sm:inset-x-10"
  | "xs:inset-x-10"
  | "2xl:inset-y-10"
  | "1xl:inset-y-10"
  | "xl:inset-y-10"
  | "lg:inset-y-10"
  | "md:inset-y-10"
  | "sm:inset-y-10"
  | "xs:inset-y-10"
  | "2xl:top-10"
  | "1xl:top-10"
  | "xl:top-10"
  | "lg:top-10"
  | "md:top-10"
  | "sm:top-10"
  | "xs:top-10"
  | "2xl:right-10"
  | "1xl:right-10"
  | "xl:right-10"
  | "lg:right-10"
  | "md:right-10"
  | "sm:right-10"
  | "xs:right-10"
  | "2xl:bottom-10"
  | "1xl:bottom-10"
  | "xl:bottom-10"
  | "lg:bottom-10"
  | "md:bottom-10"
  | "sm:bottom-10"
  | "xs:bottom-10"
  | "2xl:left-10"
  | "1xl:left-10"
  | "xl:left-10"
  | "lg:left-10"
  | "md:left-10"
  | "sm:left-10"
  | "xs:left-10"
  | "2xl:inset-11"
  | "1xl:inset-11"
  | "xl:inset-11"
  | "lg:inset-11"
  | "md:inset-11"
  | "sm:inset-11"
  | "xs:inset-11"
  | "2xl:inset-x-11"
  | "1xl:inset-x-11"
  | "xl:inset-x-11"
  | "lg:inset-x-11"
  | "md:inset-x-11"
  | "sm:inset-x-11"
  | "xs:inset-x-11"
  | "2xl:inset-y-11"
  | "1xl:inset-y-11"
  | "xl:inset-y-11"
  | "lg:inset-y-11"
  | "md:inset-y-11"
  | "sm:inset-y-11"
  | "xs:inset-y-11"
  | "2xl:top-11"
  | "1xl:top-11"
  | "xl:top-11"
  | "lg:top-11"
  | "md:top-11"
  | "sm:top-11"
  | "xs:top-11"
  | "2xl:right-11"
  | "1xl:right-11"
  | "xl:right-11"
  | "lg:right-11"
  | "md:right-11"
  | "sm:right-11"
  | "xs:right-11"
  | "2xl:bottom-11"
  | "1xl:bottom-11"
  | "xl:bottom-11"
  | "lg:bottom-11"
  | "md:bottom-11"
  | "sm:bottom-11"
  | "xs:bottom-11"
  | "2xl:left-11"
  | "1xl:left-11"
  | "xl:left-11"
  | "lg:left-11"
  | "md:left-11"
  | "sm:left-11"
  | "xs:left-11"
  | "2xl:inset-12"
  | "1xl:inset-12"
  | "xl:inset-12"
  | "lg:inset-12"
  | "md:inset-12"
  | "sm:inset-12"
  | "xs:inset-12"
  | "2xl:inset-x-12"
  | "1xl:inset-x-12"
  | "xl:inset-x-12"
  | "lg:inset-x-12"
  | "md:inset-x-12"
  | "sm:inset-x-12"
  | "xs:inset-x-12"
  | "2xl:inset-y-12"
  | "1xl:inset-y-12"
  | "xl:inset-y-12"
  | "lg:inset-y-12"
  | "md:inset-y-12"
  | "sm:inset-y-12"
  | "xs:inset-y-12"
  | "2xl:top-12"
  | "1xl:top-12"
  | "xl:top-12"
  | "lg:top-12"
  | "md:top-12"
  | "sm:top-12"
  | "xs:top-12"
  | "2xl:right-12"
  | "1xl:right-12"
  | "xl:right-12"
  | "lg:right-12"
  | "md:right-12"
  | "sm:right-12"
  | "xs:right-12"
  | "2xl:bottom-12"
  | "1xl:bottom-12"
  | "xl:bottom-12"
  | "lg:bottom-12"
  | "md:bottom-12"
  | "sm:bottom-12"
  | "xs:bottom-12"
  | "2xl:left-12"
  | "1xl:left-12"
  | "xl:left-12"
  | "lg:left-12"
  | "md:left-12"
  | "sm:left-12"
  | "xs:left-12"
  | "2xl:inset-14"
  | "1xl:inset-14"
  | "xl:inset-14"
  | "lg:inset-14"
  | "md:inset-14"
  | "sm:inset-14"
  | "xs:inset-14"
  | "2xl:inset-x-14"
  | "1xl:inset-x-14"
  | "xl:inset-x-14"
  | "lg:inset-x-14"
  | "md:inset-x-14"
  | "sm:inset-x-14"
  | "xs:inset-x-14"
  | "2xl:inset-y-14"
  | "1xl:inset-y-14"
  | "xl:inset-y-14"
  | "lg:inset-y-14"
  | "md:inset-y-14"
  | "sm:inset-y-14"
  | "xs:inset-y-14"
  | "2xl:top-14"
  | "1xl:top-14"
  | "xl:top-14"
  | "lg:top-14"
  | "md:top-14"
  | "sm:top-14"
  | "xs:top-14"
  | "2xl:right-14"
  | "1xl:right-14"
  | "xl:right-14"
  | "lg:right-14"
  | "md:right-14"
  | "sm:right-14"
  | "xs:right-14"
  | "2xl:bottom-14"
  | "1xl:bottom-14"
  | "xl:bottom-14"
  | "lg:bottom-14"
  | "md:bottom-14"
  | "sm:bottom-14"
  | "xs:bottom-14"
  | "2xl:left-14"
  | "1xl:left-14"
  | "xl:left-14"
  | "lg:left-14"
  | "md:left-14"
  | "sm:left-14"
  | "xs:left-14"
  | "2xl:inset-16"
  | "1xl:inset-16"
  | "xl:inset-16"
  | "lg:inset-16"
  | "md:inset-16"
  | "sm:inset-16"
  | "xs:inset-16"
  | "2xl:inset-x-16"
  | "1xl:inset-x-16"
  | "xl:inset-x-16"
  | "lg:inset-x-16"
  | "md:inset-x-16"
  | "sm:inset-x-16"
  | "xs:inset-x-16"
  | "2xl:inset-y-16"
  | "1xl:inset-y-16"
  | "xl:inset-y-16"
  | "lg:inset-y-16"
  | "md:inset-y-16"
  | "sm:inset-y-16"
  | "xs:inset-y-16"
  | "2xl:top-16"
  | "1xl:top-16"
  | "xl:top-16"
  | "lg:top-16"
  | "md:top-16"
  | "sm:top-16"
  | "xs:top-16"
  | "2xl:right-16"
  | "1xl:right-16"
  | "xl:right-16"
  | "lg:right-16"
  | "md:right-16"
  | "sm:right-16"
  | "xs:right-16"
  | "2xl:bottom-16"
  | "1xl:bottom-16"
  | "xl:bottom-16"
  | "lg:bottom-16"
  | "md:bottom-16"
  | "sm:bottom-16"
  | "xs:bottom-16"
  | "2xl:left-16"
  | "1xl:left-16"
  | "xl:left-16"
  | "lg:left-16"
  | "md:left-16"
  | "sm:left-16"
  | "xs:left-16"
  | "2xl:inset-20"
  | "1xl:inset-20"
  | "xl:inset-20"
  | "lg:inset-20"
  | "md:inset-20"
  | "sm:inset-20"
  | "xs:inset-20"
  | "2xl:inset-x-20"
  | "1xl:inset-x-20"
  | "xl:inset-x-20"
  | "lg:inset-x-20"
  | "md:inset-x-20"
  | "sm:inset-x-20"
  | "xs:inset-x-20"
  | "2xl:inset-y-20"
  | "1xl:inset-y-20"
  | "xl:inset-y-20"
  | "lg:inset-y-20"
  | "md:inset-y-20"
  | "sm:inset-y-20"
  | "xs:inset-y-20"
  | "2xl:top-20"
  | "1xl:top-20"
  | "xl:top-20"
  | "lg:top-20"
  | "md:top-20"
  | "sm:top-20"
  | "xs:top-20"
  | "2xl:right-20"
  | "1xl:right-20"
  | "xl:right-20"
  | "lg:right-20"
  | "md:right-20"
  | "sm:right-20"
  | "xs:right-20"
  | "2xl:bottom-20"
  | "1xl:bottom-20"
  | "xl:bottom-20"
  | "lg:bottom-20"
  | "md:bottom-20"
  | "sm:bottom-20"
  | "xs:bottom-20"
  | "2xl:left-20"
  | "1xl:left-20"
  | "xl:left-20"
  | "lg:left-20"
  | "md:left-20"
  | "sm:left-20"
  | "xs:left-20"
  | "2xl:inset-24"
  | "1xl:inset-24"
  | "xl:inset-24"
  | "lg:inset-24"
  | "md:inset-24"
  | "sm:inset-24"
  | "xs:inset-24"
  | "2xl:inset-x-24"
  | "1xl:inset-x-24"
  | "xl:inset-x-24"
  | "lg:inset-x-24"
  | "md:inset-x-24"
  | "sm:inset-x-24"
  | "xs:inset-x-24"
  | "2xl:inset-y-24"
  | "1xl:inset-y-24"
  | "xl:inset-y-24"
  | "lg:inset-y-24"
  | "md:inset-y-24"
  | "sm:inset-y-24"
  | "xs:inset-y-24"
  | "2xl:top-24"
  | "1xl:top-24"
  | "xl:top-24"
  | "lg:top-24"
  | "md:top-24"
  | "sm:top-24"
  | "xs:top-24"
  | "2xl:right-24"
  | "1xl:right-24"
  | "xl:right-24"
  | "lg:right-24"
  | "md:right-24"
  | "sm:right-24"
  | "xs:right-24"
  | "2xl:bottom-24"
  | "1xl:bottom-24"
  | "xl:bottom-24"
  | "lg:bottom-24"
  | "md:bottom-24"
  | "sm:bottom-24"
  | "xs:bottom-24"
  | "2xl:left-24"
  | "1xl:left-24"
  | "xl:left-24"
  | "lg:left-24"
  | "md:left-24"
  | "sm:left-24"
  | "xs:left-24"
  | "2xl:inset-28"
  | "1xl:inset-28"
  | "xl:inset-28"
  | "lg:inset-28"
  | "md:inset-28"
  | "sm:inset-28"
  | "xs:inset-28"
  | "2xl:inset-x-28"
  | "1xl:inset-x-28"
  | "xl:inset-x-28"
  | "lg:inset-x-28"
  | "md:inset-x-28"
  | "sm:inset-x-28"
  | "xs:inset-x-28"
  | "2xl:inset-y-28"
  | "1xl:inset-y-28"
  | "xl:inset-y-28"
  | "lg:inset-y-28"
  | "md:inset-y-28"
  | "sm:inset-y-28"
  | "xs:inset-y-28"
  | "2xl:top-28"
  | "1xl:top-28"
  | "xl:top-28"
  | "lg:top-28"
  | "md:top-28"
  | "sm:top-28"
  | "xs:top-28"
  | "2xl:right-28"
  | "1xl:right-28"
  | "xl:right-28"
  | "lg:right-28"
  | "md:right-28"
  | "sm:right-28"
  | "xs:right-28"
  | "2xl:bottom-28"
  | "1xl:bottom-28"
  | "xl:bottom-28"
  | "lg:bottom-28"
  | "md:bottom-28"
  | "sm:bottom-28"
  | "xs:bottom-28"
  | "2xl:left-28"
  | "1xl:left-28"
  | "xl:left-28"
  | "lg:left-28"
  | "md:left-28"
  | "sm:left-28"
  | "xs:left-28"
  | "2xl:inset-32"
  | "1xl:inset-32"
  | "xl:inset-32"
  | "lg:inset-32"
  | "md:inset-32"
  | "sm:inset-32"
  | "xs:inset-32"
  | "2xl:inset-x-32"
  | "1xl:inset-x-32"
  | "xl:inset-x-32"
  | "lg:inset-x-32"
  | "md:inset-x-32"
  | "sm:inset-x-32"
  | "xs:inset-x-32"
  | "2xl:inset-y-32"
  | "1xl:inset-y-32"
  | "xl:inset-y-32"
  | "lg:inset-y-32"
  | "md:inset-y-32"
  | "sm:inset-y-32"
  | "xs:inset-y-32"
  | "2xl:top-32"
  | "1xl:top-32"
  | "xl:top-32"
  | "lg:top-32"
  | "md:top-32"
  | "sm:top-32"
  | "xs:top-32"
  | "2xl:right-32"
  | "1xl:right-32"
  | "xl:right-32"
  | "lg:right-32"
  | "md:right-32"
  | "sm:right-32"
  | "xs:right-32"
  | "2xl:bottom-32"
  | "1xl:bottom-32"
  | "xl:bottom-32"
  | "lg:bottom-32"
  | "md:bottom-32"
  | "sm:bottom-32"
  | "xs:bottom-32"
  | "2xl:left-32"
  | "1xl:left-32"
  | "xl:left-32"
  | "lg:left-32"
  | "md:left-32"
  | "sm:left-32"
  | "xs:left-32"
  | "2xl:inset-36"
  | "1xl:inset-36"
  | "xl:inset-36"
  | "lg:inset-36"
  | "md:inset-36"
  | "sm:inset-36"
  | "xs:inset-36"
  | "2xl:inset-x-36"
  | "1xl:inset-x-36"
  | "xl:inset-x-36"
  | "lg:inset-x-36"
  | "md:inset-x-36"
  | "sm:inset-x-36"
  | "xs:inset-x-36"
  | "2xl:inset-y-36"
  | "1xl:inset-y-36"
  | "xl:inset-y-36"
  | "lg:inset-y-36"
  | "md:inset-y-36"
  | "sm:inset-y-36"
  | "xs:inset-y-36"
  | "2xl:top-36"
  | "1xl:top-36"
  | "xl:top-36"
  | "lg:top-36"
  | "md:top-36"
  | "sm:top-36"
  | "xs:top-36"
  | "2xl:right-36"
  | "1xl:right-36"
  | "xl:right-36"
  | "lg:right-36"
  | "md:right-36"
  | "sm:right-36"
  | "xs:right-36"
  | "2xl:bottom-36"
  | "1xl:bottom-36"
  | "xl:bottom-36"
  | "lg:bottom-36"
  | "md:bottom-36"
  | "sm:bottom-36"
  | "xs:bottom-36"
  | "2xl:left-36"
  | "1xl:left-36"
  | "xl:left-36"
  | "lg:left-36"
  | "md:left-36"
  | "sm:left-36"
  | "xs:left-36"
  | "2xl:inset-40"
  | "1xl:inset-40"
  | "xl:inset-40"
  | "lg:inset-40"
  | "md:inset-40"
  | "sm:inset-40"
  | "xs:inset-40"
  | "2xl:inset-x-40"
  | "1xl:inset-x-40"
  | "xl:inset-x-40"
  | "lg:inset-x-40"
  | "md:inset-x-40"
  | "sm:inset-x-40"
  | "xs:inset-x-40"
  | "2xl:inset-y-40"
  | "1xl:inset-y-40"
  | "xl:inset-y-40"
  | "lg:inset-y-40"
  | "md:inset-y-40"
  | "sm:inset-y-40"
  | "xs:inset-y-40"
  | "2xl:top-40"
  | "1xl:top-40"
  | "xl:top-40"
  | "lg:top-40"
  | "md:top-40"
  | "sm:top-40"
  | "xs:top-40"
  | "2xl:right-40"
  | "1xl:right-40"
  | "xl:right-40"
  | "lg:right-40"
  | "md:right-40"
  | "sm:right-40"
  | "xs:right-40"
  | "2xl:bottom-40"
  | "1xl:bottom-40"
  | "xl:bottom-40"
  | "lg:bottom-40"
  | "md:bottom-40"
  | "sm:bottom-40"
  | "xs:bottom-40"
  | "2xl:left-40"
  | "1xl:left-40"
  | "xl:left-40"
  | "lg:left-40"
  | "md:left-40"
  | "sm:left-40"
  | "xs:left-40"
  | "2xl:inset-44"
  | "1xl:inset-44"
  | "xl:inset-44"
  | "lg:inset-44"
  | "md:inset-44"
  | "sm:inset-44"
  | "xs:inset-44"
  | "2xl:inset-x-44"
  | "1xl:inset-x-44"
  | "xl:inset-x-44"
  | "lg:inset-x-44"
  | "md:inset-x-44"
  | "sm:inset-x-44"
  | "xs:inset-x-44"
  | "2xl:inset-y-44"
  | "1xl:inset-y-44"
  | "xl:inset-y-44"
  | "lg:inset-y-44"
  | "md:inset-y-44"
  | "sm:inset-y-44"
  | "xs:inset-y-44"
  | "2xl:top-44"
  | "1xl:top-44"
  | "xl:top-44"
  | "lg:top-44"
  | "md:top-44"
  | "sm:top-44"
  | "xs:top-44"
  | "2xl:right-44"
  | "1xl:right-44"
  | "xl:right-44"
  | "lg:right-44"
  | "md:right-44"
  | "sm:right-44"
  | "xs:right-44"
  | "2xl:bottom-44"
  | "1xl:bottom-44"
  | "xl:bottom-44"
  | "lg:bottom-44"
  | "md:bottom-44"
  | "sm:bottom-44"
  | "xs:bottom-44"
  | "2xl:left-44"
  | "1xl:left-44"
  | "xl:left-44"
  | "lg:left-44"
  | "md:left-44"
  | "sm:left-44"
  | "xs:left-44"
  | "2xl:inset-48"
  | "1xl:inset-48"
  | "xl:inset-48"
  | "lg:inset-48"
  | "md:inset-48"
  | "sm:inset-48"
  | "xs:inset-48"
  | "2xl:inset-x-48"
  | "1xl:inset-x-48"
  | "xl:inset-x-48"
  | "lg:inset-x-48"
  | "md:inset-x-48"
  | "sm:inset-x-48"
  | "xs:inset-x-48"
  | "2xl:inset-y-48"
  | "1xl:inset-y-48"
  | "xl:inset-y-48"
  | "lg:inset-y-48"
  | "md:inset-y-48"
  | "sm:inset-y-48"
  | "xs:inset-y-48"
  | "2xl:top-48"
  | "1xl:top-48"
  | "xl:top-48"
  | "lg:top-48"
  | "md:top-48"
  | "sm:top-48"
  | "xs:top-48"
  | "2xl:right-48"
  | "1xl:right-48"
  | "xl:right-48"
  | "lg:right-48"
  | "md:right-48"
  | "sm:right-48"
  | "xs:right-48"
  | "2xl:bottom-48"
  | "1xl:bottom-48"
  | "xl:bottom-48"
  | "lg:bottom-48"
  | "md:bottom-48"
  | "sm:bottom-48"
  | "xs:bottom-48"
  | "2xl:left-48"
  | "1xl:left-48"
  | "xl:left-48"
  | "lg:left-48"
  | "md:left-48"
  | "sm:left-48"
  | "xs:left-48"
  | "2xl:inset-52"
  | "1xl:inset-52"
  | "xl:inset-52"
  | "lg:inset-52"
  | "md:inset-52"
  | "sm:inset-52"
  | "xs:inset-52"
  | "2xl:inset-x-52"
  | "1xl:inset-x-52"
  | "xl:inset-x-52"
  | "lg:inset-x-52"
  | "md:inset-x-52"
  | "sm:inset-x-52"
  | "xs:inset-x-52"
  | "2xl:inset-y-52"
  | "1xl:inset-y-52"
  | "xl:inset-y-52"
  | "lg:inset-y-52"
  | "md:inset-y-52"
  | "sm:inset-y-52"
  | "xs:inset-y-52"
  | "2xl:top-52"
  | "1xl:top-52"
  | "xl:top-52"
  | "lg:top-52"
  | "md:top-52"
  | "sm:top-52"
  | "xs:top-52"
  | "2xl:right-52"
  | "1xl:right-52"
  | "xl:right-52"
  | "lg:right-52"
  | "md:right-52"
  | "sm:right-52"
  | "xs:right-52"
  | "2xl:bottom-52"
  | "1xl:bottom-52"
  | "xl:bottom-52"
  | "lg:bottom-52"
  | "md:bottom-52"
  | "sm:bottom-52"
  | "xs:bottom-52"
  | "2xl:left-52"
  | "1xl:left-52"
  | "xl:left-52"
  | "lg:left-52"
  | "md:left-52"
  | "sm:left-52"
  | "xs:left-52"
  | "2xl:inset-56"
  | "1xl:inset-56"
  | "xl:inset-56"
  | "lg:inset-56"
  | "md:inset-56"
  | "sm:inset-56"
  | "xs:inset-56"
  | "2xl:inset-x-56"
  | "1xl:inset-x-56"
  | "xl:inset-x-56"
  | "lg:inset-x-56"
  | "md:inset-x-56"
  | "sm:inset-x-56"
  | "xs:inset-x-56"
  | "2xl:inset-y-56"
  | "1xl:inset-y-56"
  | "xl:inset-y-56"
  | "lg:inset-y-56"
  | "md:inset-y-56"
  | "sm:inset-y-56"
  | "xs:inset-y-56"
  | "2xl:top-56"
  | "1xl:top-56"
  | "xl:top-56"
  | "lg:top-56"
  | "md:top-56"
  | "sm:top-56"
  | "xs:top-56"
  | "2xl:right-56"
  | "1xl:right-56"
  | "xl:right-56"
  | "lg:right-56"
  | "md:right-56"
  | "sm:right-56"
  | "xs:right-56"
  | "2xl:bottom-56"
  | "1xl:bottom-56"
  | "xl:bottom-56"
  | "lg:bottom-56"
  | "md:bottom-56"
  | "sm:bottom-56"
  | "xs:bottom-56"
  | "2xl:left-56"
  | "1xl:left-56"
  | "xl:left-56"
  | "lg:left-56"
  | "md:left-56"
  | "sm:left-56"
  | "xs:left-56"
  | "2xl:inset-60"
  | "1xl:inset-60"
  | "xl:inset-60"
  | "lg:inset-60"
  | "md:inset-60"
  | "sm:inset-60"
  | "xs:inset-60"
  | "2xl:inset-x-60"
  | "1xl:inset-x-60"
  | "xl:inset-x-60"
  | "lg:inset-x-60"
  | "md:inset-x-60"
  | "sm:inset-x-60"
  | "xs:inset-x-60"
  | "2xl:inset-y-60"
  | "1xl:inset-y-60"
  | "xl:inset-y-60"
  | "lg:inset-y-60"
  | "md:inset-y-60"
  | "sm:inset-y-60"
  | "xs:inset-y-60"
  | "2xl:top-60"
  | "1xl:top-60"
  | "xl:top-60"
  | "lg:top-60"
  | "md:top-60"
  | "sm:top-60"
  | "xs:top-60"
  | "2xl:right-60"
  | "1xl:right-60"
  | "xl:right-60"
  | "lg:right-60"
  | "md:right-60"
  | "sm:right-60"
  | "xs:right-60"
  | "2xl:bottom-60"
  | "1xl:bottom-60"
  | "xl:bottom-60"
  | "lg:bottom-60"
  | "md:bottom-60"
  | "sm:bottom-60"
  | "xs:bottom-60"
  | "2xl:left-60"
  | "1xl:left-60"
  | "xl:left-60"
  | "lg:left-60"
  | "md:left-60"
  | "sm:left-60"
  | "xs:left-60"
  | "2xl:inset-64"
  | "1xl:inset-64"
  | "xl:inset-64"
  | "lg:inset-64"
  | "md:inset-64"
  | "sm:inset-64"
  | "xs:inset-64"
  | "2xl:inset-x-64"
  | "1xl:inset-x-64"
  | "xl:inset-x-64"
  | "lg:inset-x-64"
  | "md:inset-x-64"
  | "sm:inset-x-64"
  | "xs:inset-x-64"
  | "2xl:inset-y-64"
  | "1xl:inset-y-64"
  | "xl:inset-y-64"
  | "lg:inset-y-64"
  | "md:inset-y-64"
  | "sm:inset-y-64"
  | "xs:inset-y-64"
  | "2xl:top-64"
  | "1xl:top-64"
  | "xl:top-64"
  | "lg:top-64"
  | "md:top-64"
  | "sm:top-64"
  | "xs:top-64"
  | "2xl:right-64"
  | "1xl:right-64"
  | "xl:right-64"
  | "lg:right-64"
  | "md:right-64"
  | "sm:right-64"
  | "xs:right-64"
  | "2xl:bottom-64"
  | "1xl:bottom-64"
  | "xl:bottom-64"
  | "lg:bottom-64"
  | "md:bottom-64"
  | "sm:bottom-64"
  | "xs:bottom-64"
  | "2xl:left-64"
  | "1xl:left-64"
  | "xl:left-64"
  | "lg:left-64"
  | "md:left-64"
  | "sm:left-64"
  | "xs:left-64"
  | "2xl:inset-72"
  | "1xl:inset-72"
  | "xl:inset-72"
  | "lg:inset-72"
  | "md:inset-72"
  | "sm:inset-72"
  | "xs:inset-72"
  | "2xl:inset-x-72"
  | "1xl:inset-x-72"
  | "xl:inset-x-72"
  | "lg:inset-x-72"
  | "md:inset-x-72"
  | "sm:inset-x-72"
  | "xs:inset-x-72"
  | "2xl:inset-y-72"
  | "1xl:inset-y-72"
  | "xl:inset-y-72"
  | "lg:inset-y-72"
  | "md:inset-y-72"
  | "sm:inset-y-72"
  | "xs:inset-y-72"
  | "2xl:top-72"
  | "1xl:top-72"
  | "xl:top-72"
  | "lg:top-72"
  | "md:top-72"
  | "sm:top-72"
  | "xs:top-72"
  | "2xl:right-72"
  | "1xl:right-72"
  | "xl:right-72"
  | "lg:right-72"
  | "md:right-72"
  | "sm:right-72"
  | "xs:right-72"
  | "2xl:bottom-72"
  | "1xl:bottom-72"
  | "xl:bottom-72"
  | "lg:bottom-72"
  | "md:bottom-72"
  | "sm:bottom-72"
  | "xs:bottom-72"
  | "2xl:left-72"
  | "1xl:left-72"
  | "xl:left-72"
  | "lg:left-72"
  | "md:left-72"
  | "sm:left-72"
  | "xs:left-72"
  | "2xl:inset-80"
  | "1xl:inset-80"
  | "xl:inset-80"
  | "lg:inset-80"
  | "md:inset-80"
  | "sm:inset-80"
  | "xs:inset-80"
  | "2xl:inset-x-80"
  | "1xl:inset-x-80"
  | "xl:inset-x-80"
  | "lg:inset-x-80"
  | "md:inset-x-80"
  | "sm:inset-x-80"
  | "xs:inset-x-80"
  | "2xl:inset-y-80"
  | "1xl:inset-y-80"
  | "xl:inset-y-80"
  | "lg:inset-y-80"
  | "md:inset-y-80"
  | "sm:inset-y-80"
  | "xs:inset-y-80"
  | "2xl:top-80"
  | "1xl:top-80"
  | "xl:top-80"
  | "lg:top-80"
  | "md:top-80"
  | "sm:top-80"
  | "xs:top-80"
  | "2xl:right-80"
  | "1xl:right-80"
  | "xl:right-80"
  | "lg:right-80"
  | "md:right-80"
  | "sm:right-80"
  | "xs:right-80"
  | "2xl:bottom-80"
  | "1xl:bottom-80"
  | "xl:bottom-80"
  | "lg:bottom-80"
  | "md:bottom-80"
  | "sm:bottom-80"
  | "xs:bottom-80"
  | "2xl:left-80"
  | "1xl:left-80"
  | "xl:left-80"
  | "lg:left-80"
  | "md:left-80"
  | "sm:left-80"
  | "xs:left-80"
  | "2xl:inset-96"
  | "1xl:inset-96"
  | "xl:inset-96"
  | "lg:inset-96"
  | "md:inset-96"
  | "sm:inset-96"
  | "xs:inset-96"
  | "2xl:inset-x-96"
  | "1xl:inset-x-96"
  | "xl:inset-x-96"
  | "lg:inset-x-96"
  | "md:inset-x-96"
  | "sm:inset-x-96"
  | "xs:inset-x-96"
  | "2xl:inset-y-96"
  | "1xl:inset-y-96"
  | "xl:inset-y-96"
  | "lg:inset-y-96"
  | "md:inset-y-96"
  | "sm:inset-y-96"
  | "xs:inset-y-96"
  | "2xl:top-96"
  | "1xl:top-96"
  | "xl:top-96"
  | "lg:top-96"
  | "md:top-96"
  | "sm:top-96"
  | "xs:top-96"
  | "2xl:right-96"
  | "1xl:right-96"
  | "xl:right-96"
  | "lg:right-96"
  | "md:right-96"
  | "sm:right-96"
  | "xs:right-96"
  | "2xl:bottom-96"
  | "1xl:bottom-96"
  | "xl:bottom-96"
  | "lg:bottom-96"
  | "md:bottom-96"
  | "sm:bottom-96"
  | "xs:bottom-96"
  | "2xl:left-96"
  | "1xl:left-96"
  | "xl:left-96"
  | "lg:left-96"
  | "md:left-96"
  | "sm:left-96"
  | "xs:left-96"
  | "2xl:inset-auto"
  | "1xl:inset-auto"
  | "xl:inset-auto"
  | "lg:inset-auto"
  | "md:inset-auto"
  | "sm:inset-auto"
  | "xs:inset-auto"
  | "2xl:inset-x-auto"
  | "1xl:inset-x-auto"
  | "xl:inset-x-auto"
  | "lg:inset-x-auto"
  | "md:inset-x-auto"
  | "sm:inset-x-auto"
  | "xs:inset-x-auto"
  | "2xl:inset-y-auto"
  | "1xl:inset-y-auto"
  | "xl:inset-y-auto"
  | "lg:inset-y-auto"
  | "md:inset-y-auto"
  | "sm:inset-y-auto"
  | "xs:inset-y-auto"
  | "2xl:top-auto"
  | "1xl:top-auto"
  | "xl:top-auto"
  | "lg:top-auto"
  | "md:top-auto"
  | "sm:top-auto"
  | "xs:top-auto"
  | "2xl:right-auto"
  | "1xl:right-auto"
  | "xl:right-auto"
  | "lg:right-auto"
  | "md:right-auto"
  | "sm:right-auto"
  | "xs:right-auto"
  | "2xl:bottom-auto"
  | "1xl:bottom-auto"
  | "xl:bottom-auto"
  | "lg:bottom-auto"
  | "md:bottom-auto"
  | "sm:bottom-auto"
  | "xs:bottom-auto"
  | "2xl:left-auto"
  | "1xl:left-auto"
  | "xl:left-auto"
  | "lg:left-auto"
  | "md:left-auto"
  | "sm:left-auto"
  | "xs:left-auto"
  | "2xl:inset-px"
  | "1xl:inset-px"
  | "xl:inset-px"
  | "lg:inset-px"
  | "md:inset-px"
  | "sm:inset-px"
  | "xs:inset-px"
  | "2xl:inset-x-px"
  | "1xl:inset-x-px"
  | "xl:inset-x-px"
  | "lg:inset-x-px"
  | "md:inset-x-px"
  | "sm:inset-x-px"
  | "xs:inset-x-px"
  | "2xl:inset-y-px"
  | "1xl:inset-y-px"
  | "xl:inset-y-px"
  | "lg:inset-y-px"
  | "md:inset-y-px"
  | "sm:inset-y-px"
  | "xs:inset-y-px"
  | "2xl:top-px"
  | "1xl:top-px"
  | "xl:top-px"
  | "lg:top-px"
  | "md:top-px"
  | "sm:top-px"
  | "xs:top-px"
  | "2xl:right-px"
  | "1xl:right-px"
  | "xl:right-px"
  | "lg:right-px"
  | "md:right-px"
  | "sm:right-px"
  | "xs:right-px"
  | "2xl:bottom-px"
  | "1xl:bottom-px"
  | "xl:bottom-px"
  | "lg:bottom-px"
  | "md:bottom-px"
  | "sm:bottom-px"
  | "xs:bottom-px"
  | "2xl:left-px"
  | "1xl:left-px"
  | "xl:left-px"
  | "lg:left-px"
  | "md:left-px"
  | "sm:left-px"
  | "xs:left-px"
  | "2xl:inset-0.5"
  | "1xl:inset-0.5"
  | "xl:inset-0.5"
  | "lg:inset-0.5"
  | "md:inset-0.5"
  | "sm:inset-0.5"
  | "xs:inset-0.5"
  | "2xl:inset-x-0.5"
  | "1xl:inset-x-0.5"
  | "xl:inset-x-0.5"
  | "lg:inset-x-0.5"
  | "md:inset-x-0.5"
  | "sm:inset-x-0.5"
  | "xs:inset-x-0.5"
  | "2xl:inset-y-0.5"
  | "1xl:inset-y-0.5"
  | "xl:inset-y-0.5"
  | "lg:inset-y-0.5"
  | "md:inset-y-0.5"
  | "sm:inset-y-0.5"
  | "xs:inset-y-0.5"
  | "2xl:top-0.5"
  | "1xl:top-0.5"
  | "xl:top-0.5"
  | "lg:top-0.5"
  | "md:top-0.5"
  | "sm:top-0.5"
  | "xs:top-0.5"
  | "2xl:right-0.5"
  | "1xl:right-0.5"
  | "xl:right-0.5"
  | "lg:right-0.5"
  | "md:right-0.5"
  | "sm:right-0.5"
  | "xs:right-0.5"
  | "2xl:bottom-0.5"
  | "1xl:bottom-0.5"
  | "xl:bottom-0.5"
  | "lg:bottom-0.5"
  | "md:bottom-0.5"
  | "sm:bottom-0.5"
  | "xs:bottom-0.5"
  | "2xl:left-0.5"
  | "1xl:left-0.5"
  | "xl:left-0.5"
  | "lg:left-0.5"
  | "md:left-0.5"
  | "sm:left-0.5"
  | "xs:left-0.5"
  | "2xl:inset-1.5"
  | "1xl:inset-1.5"
  | "xl:inset-1.5"
  | "lg:inset-1.5"
  | "md:inset-1.5"
  | "sm:inset-1.5"
  | "xs:inset-1.5"
  | "2xl:inset-x-1.5"
  | "1xl:inset-x-1.5"
  | "xl:inset-x-1.5"
  | "lg:inset-x-1.5"
  | "md:inset-x-1.5"
  | "sm:inset-x-1.5"
  | "xs:inset-x-1.5"
  | "2xl:inset-y-1.5"
  | "1xl:inset-y-1.5"
  | "xl:inset-y-1.5"
  | "lg:inset-y-1.5"
  | "md:inset-y-1.5"
  | "sm:inset-y-1.5"
  | "xs:inset-y-1.5"
  | "2xl:top-1.5"
  | "1xl:top-1.5"
  | "xl:top-1.5"
  | "lg:top-1.5"
  | "md:top-1.5"
  | "sm:top-1.5"
  | "xs:top-1.5"
  | "2xl:right-1.5"
  | "1xl:right-1.5"
  | "xl:right-1.5"
  | "lg:right-1.5"
  | "md:right-1.5"
  | "sm:right-1.5"
  | "xs:right-1.5"
  | "2xl:bottom-1.5"
  | "1xl:bottom-1.5"
  | "xl:bottom-1.5"
  | "lg:bottom-1.5"
  | "md:bottom-1.5"
  | "sm:bottom-1.5"
  | "xs:bottom-1.5"
  | "2xl:left-1.5"
  | "1xl:left-1.5"
  | "xl:left-1.5"
  | "lg:left-1.5"
  | "md:left-1.5"
  | "sm:left-1.5"
  | "xs:left-1.5"
  | "2xl:inset-2.5"
  | "1xl:inset-2.5"
  | "xl:inset-2.5"
  | "lg:inset-2.5"
  | "md:inset-2.5"
  | "sm:inset-2.5"
  | "xs:inset-2.5"
  | "2xl:inset-x-2.5"
  | "1xl:inset-x-2.5"
  | "xl:inset-x-2.5"
  | "lg:inset-x-2.5"
  | "md:inset-x-2.5"
  | "sm:inset-x-2.5"
  | "xs:inset-x-2.5"
  | "2xl:inset-y-2.5"
  | "1xl:inset-y-2.5"
  | "xl:inset-y-2.5"
  | "lg:inset-y-2.5"
  | "md:inset-y-2.5"
  | "sm:inset-y-2.5"
  | "xs:inset-y-2.5"
  | "2xl:top-2.5"
  | "1xl:top-2.5"
  | "xl:top-2.5"
  | "lg:top-2.5"
  | "md:top-2.5"
  | "sm:top-2.5"
  | "xs:top-2.5"
  | "2xl:right-2.5"
  | "1xl:right-2.5"
  | "xl:right-2.5"
  | "lg:right-2.5"
  | "md:right-2.5"
  | "sm:right-2.5"
  | "xs:right-2.5"
  | "2xl:bottom-2.5"
  | "1xl:bottom-2.5"
  | "xl:bottom-2.5"
  | "lg:bottom-2.5"
  | "md:bottom-2.5"
  | "sm:bottom-2.5"
  | "xs:bottom-2.5"
  | "2xl:left-2.5"
  | "1xl:left-2.5"
  | "xl:left-2.5"
  | "lg:left-2.5"
  | "md:left-2.5"
  | "sm:left-2.5"
  | "xs:left-2.5"
  | "2xl:inset-3.5"
  | "1xl:inset-3.5"
  | "xl:inset-3.5"
  | "lg:inset-3.5"
  | "md:inset-3.5"
  | "sm:inset-3.5"
  | "xs:inset-3.5"
  | "2xl:inset-x-3.5"
  | "1xl:inset-x-3.5"
  | "xl:inset-x-3.5"
  | "lg:inset-x-3.5"
  | "md:inset-x-3.5"
  | "sm:inset-x-3.5"
  | "xs:inset-x-3.5"
  | "2xl:inset-y-3.5"
  | "1xl:inset-y-3.5"
  | "xl:inset-y-3.5"
  | "lg:inset-y-3.5"
  | "md:inset-y-3.5"
  | "sm:inset-y-3.5"
  | "xs:inset-y-3.5"
  | "2xl:top-3.5"
  | "1xl:top-3.5"
  | "xl:top-3.5"
  | "lg:top-3.5"
  | "md:top-3.5"
  | "sm:top-3.5"
  | "xs:top-3.5"
  | "2xl:right-3.5"
  | "1xl:right-3.5"
  | "xl:right-3.5"
  | "lg:right-3.5"
  | "md:right-3.5"
  | "sm:right-3.5"
  | "xs:right-3.5"
  | "2xl:bottom-3.5"
  | "1xl:bottom-3.5"
  | "xl:bottom-3.5"
  | "lg:bottom-3.5"
  | "md:bottom-3.5"
  | "sm:bottom-3.5"
  | "xs:bottom-3.5"
  | "2xl:left-3.5"
  | "1xl:left-3.5"
  | "xl:left-3.5"
  | "lg:left-3.5"
  | "md:left-3.5"
  | "sm:left-3.5"
  | "xs:left-3.5"
  | "2xl:-inset-0"
  | "1xl:-inset-0"
  | "xl:-inset-0"
  | "lg:-inset-0"
  | "md:-inset-0"
  | "sm:-inset-0"
  | "xs:-inset-0"
  | "2xl:-inset-x-0"
  | "1xl:-inset-x-0"
  | "xl:-inset-x-0"
  | "lg:-inset-x-0"
  | "md:-inset-x-0"
  | "sm:-inset-x-0"
  | "xs:-inset-x-0"
  | "2xl:-inset-y-0"
  | "1xl:-inset-y-0"
  | "xl:-inset-y-0"
  | "lg:-inset-y-0"
  | "md:-inset-y-0"
  | "sm:-inset-y-0"
  | "xs:-inset-y-0"
  | "2xl:-top-0"
  | "1xl:-top-0"
  | "xl:-top-0"
  | "lg:-top-0"
  | "md:-top-0"
  | "sm:-top-0"
  | "xs:-top-0"
  | "2xl:-right-0"
  | "1xl:-right-0"
  | "xl:-right-0"
  | "lg:-right-0"
  | "md:-right-0"
  | "sm:-right-0"
  | "xs:-right-0"
  | "2xl:-bottom-0"
  | "1xl:-bottom-0"
  | "xl:-bottom-0"
  | "lg:-bottom-0"
  | "md:-bottom-0"
  | "sm:-bottom-0"
  | "xs:-bottom-0"
  | "2xl:-left-0"
  | "1xl:-left-0"
  | "xl:-left-0"
  | "lg:-left-0"
  | "md:-left-0"
  | "sm:-left-0"
  | "xs:-left-0"
  | "2xl:-inset-1"
  | "1xl:-inset-1"
  | "xl:-inset-1"
  | "lg:-inset-1"
  | "md:-inset-1"
  | "sm:-inset-1"
  | "xs:-inset-1"
  | "2xl:-inset-x-1"
  | "1xl:-inset-x-1"
  | "xl:-inset-x-1"
  | "lg:-inset-x-1"
  | "md:-inset-x-1"
  | "sm:-inset-x-1"
  | "xs:-inset-x-1"
  | "2xl:-inset-y-1"
  | "1xl:-inset-y-1"
  | "xl:-inset-y-1"
  | "lg:-inset-y-1"
  | "md:-inset-y-1"
  | "sm:-inset-y-1"
  | "xs:-inset-y-1"
  | "2xl:-top-1"
  | "1xl:-top-1"
  | "xl:-top-1"
  | "lg:-top-1"
  | "md:-top-1"
  | "sm:-top-1"
  | "xs:-top-1"
  | "2xl:-right-1"
  | "1xl:-right-1"
  | "xl:-right-1"
  | "lg:-right-1"
  | "md:-right-1"
  | "sm:-right-1"
  | "xs:-right-1"
  | "2xl:-bottom-1"
  | "1xl:-bottom-1"
  | "xl:-bottom-1"
  | "lg:-bottom-1"
  | "md:-bottom-1"
  | "sm:-bottom-1"
  | "xs:-bottom-1"
  | "2xl:-left-1"
  | "1xl:-left-1"
  | "xl:-left-1"
  | "lg:-left-1"
  | "md:-left-1"
  | "sm:-left-1"
  | "xs:-left-1"
  | "2xl:-inset-2"
  | "1xl:-inset-2"
  | "xl:-inset-2"
  | "lg:-inset-2"
  | "md:-inset-2"
  | "sm:-inset-2"
  | "xs:-inset-2"
  | "2xl:-inset-x-2"
  | "1xl:-inset-x-2"
  | "xl:-inset-x-2"
  | "lg:-inset-x-2"
  | "md:-inset-x-2"
  | "sm:-inset-x-2"
  | "xs:-inset-x-2"
  | "2xl:-inset-y-2"
  | "1xl:-inset-y-2"
  | "xl:-inset-y-2"
  | "lg:-inset-y-2"
  | "md:-inset-y-2"
  | "sm:-inset-y-2"
  | "xs:-inset-y-2"
  | "2xl:-top-2"
  | "1xl:-top-2"
  | "xl:-top-2"
  | "lg:-top-2"
  | "md:-top-2"
  | "sm:-top-2"
  | "xs:-top-2"
  | "2xl:-right-2"
  | "1xl:-right-2"
  | "xl:-right-2"
  | "lg:-right-2"
  | "md:-right-2"
  | "sm:-right-2"
  | "xs:-right-2"
  | "2xl:-bottom-2"
  | "1xl:-bottom-2"
  | "xl:-bottom-2"
  | "lg:-bottom-2"
  | "md:-bottom-2"
  | "sm:-bottom-2"
  | "xs:-bottom-2"
  | "2xl:-left-2"
  | "1xl:-left-2"
  | "xl:-left-2"
  | "lg:-left-2"
  | "md:-left-2"
  | "sm:-left-2"
  | "xs:-left-2"
  | "2xl:-inset-3"
  | "1xl:-inset-3"
  | "xl:-inset-3"
  | "lg:-inset-3"
  | "md:-inset-3"
  | "sm:-inset-3"
  | "xs:-inset-3"
  | "2xl:-inset-x-3"
  | "1xl:-inset-x-3"
  | "xl:-inset-x-3"
  | "lg:-inset-x-3"
  | "md:-inset-x-3"
  | "sm:-inset-x-3"
  | "xs:-inset-x-3"
  | "2xl:-inset-y-3"
  | "1xl:-inset-y-3"
  | "xl:-inset-y-3"
  | "lg:-inset-y-3"
  | "md:-inset-y-3"
  | "sm:-inset-y-3"
  | "xs:-inset-y-3"
  | "2xl:-top-3"
  | "1xl:-top-3"
  | "xl:-top-3"
  | "lg:-top-3"
  | "md:-top-3"
  | "sm:-top-3"
  | "xs:-top-3"
  | "2xl:-right-3"
  | "1xl:-right-3"
  | "xl:-right-3"
  | "lg:-right-3"
  | "md:-right-3"
  | "sm:-right-3"
  | "xs:-right-3"
  | "2xl:-bottom-3"
  | "1xl:-bottom-3"
  | "xl:-bottom-3"
  | "lg:-bottom-3"
  | "md:-bottom-3"
  | "sm:-bottom-3"
  | "xs:-bottom-3"
  | "2xl:-left-3"
  | "1xl:-left-3"
  | "xl:-left-3"
  | "lg:-left-3"
  | "md:-left-3"
  | "sm:-left-3"
  | "xs:-left-3"
  | "2xl:-inset-4"
  | "1xl:-inset-4"
  | "xl:-inset-4"
  | "lg:-inset-4"
  | "md:-inset-4"
  | "sm:-inset-4"
  | "xs:-inset-4"
  | "2xl:-inset-x-4"
  | "1xl:-inset-x-4"
  | "xl:-inset-x-4"
  | "lg:-inset-x-4"
  | "md:-inset-x-4"
  | "sm:-inset-x-4"
  | "xs:-inset-x-4"
  | "2xl:-inset-y-4"
  | "1xl:-inset-y-4"
  | "xl:-inset-y-4"
  | "lg:-inset-y-4"
  | "md:-inset-y-4"
  | "sm:-inset-y-4"
  | "xs:-inset-y-4"
  | "2xl:-top-4"
  | "1xl:-top-4"
  | "xl:-top-4"
  | "lg:-top-4"
  | "md:-top-4"
  | "sm:-top-4"
  | "xs:-top-4"
  | "2xl:-right-4"
  | "1xl:-right-4"
  | "xl:-right-4"
  | "lg:-right-4"
  | "md:-right-4"
  | "sm:-right-4"
  | "xs:-right-4"
  | "2xl:-bottom-4"
  | "1xl:-bottom-4"
  | "xl:-bottom-4"
  | "lg:-bottom-4"
  | "md:-bottom-4"
  | "sm:-bottom-4"
  | "xs:-bottom-4"
  | "2xl:-left-4"
  | "1xl:-left-4"
  | "xl:-left-4"
  | "lg:-left-4"
  | "md:-left-4"
  | "sm:-left-4"
  | "xs:-left-4"
  | "2xl:-inset-5"
  | "1xl:-inset-5"
  | "xl:-inset-5"
  | "lg:-inset-5"
  | "md:-inset-5"
  | "sm:-inset-5"
  | "xs:-inset-5"
  | "2xl:-inset-x-5"
  | "1xl:-inset-x-5"
  | "xl:-inset-x-5"
  | "lg:-inset-x-5"
  | "md:-inset-x-5"
  | "sm:-inset-x-5"
  | "xs:-inset-x-5"
  | "2xl:-inset-y-5"
  | "1xl:-inset-y-5"
  | "xl:-inset-y-5"
  | "lg:-inset-y-5"
  | "md:-inset-y-5"
  | "sm:-inset-y-5"
  | "xs:-inset-y-5"
  | "2xl:-top-5"
  | "1xl:-top-5"
  | "xl:-top-5"
  | "lg:-top-5"
  | "md:-top-5"
  | "sm:-top-5"
  | "xs:-top-5"
  | "2xl:-right-5"
  | "1xl:-right-5"
  | "xl:-right-5"
  | "lg:-right-5"
  | "md:-right-5"
  | "sm:-right-5"
  | "xs:-right-5"
  | "2xl:-bottom-5"
  | "1xl:-bottom-5"
  | "xl:-bottom-5"
  | "lg:-bottom-5"
  | "md:-bottom-5"
  | "sm:-bottom-5"
  | "xs:-bottom-5"
  | "2xl:-left-5"
  | "1xl:-left-5"
  | "xl:-left-5"
  | "lg:-left-5"
  | "md:-left-5"
  | "sm:-left-5"
  | "xs:-left-5"
  | "2xl:-inset-6"
  | "1xl:-inset-6"
  | "xl:-inset-6"
  | "lg:-inset-6"
  | "md:-inset-6"
  | "sm:-inset-6"
  | "xs:-inset-6"
  | "2xl:-inset-x-6"
  | "1xl:-inset-x-6"
  | "xl:-inset-x-6"
  | "lg:-inset-x-6"
  | "md:-inset-x-6"
  | "sm:-inset-x-6"
  | "xs:-inset-x-6"
  | "2xl:-inset-y-6"
  | "1xl:-inset-y-6"
  | "xl:-inset-y-6"
  | "lg:-inset-y-6"
  | "md:-inset-y-6"
  | "sm:-inset-y-6"
  | "xs:-inset-y-6"
  | "2xl:-top-6"
  | "1xl:-top-6"
  | "xl:-top-6"
  | "lg:-top-6"
  | "md:-top-6"
  | "sm:-top-6"
  | "xs:-top-6"
  | "2xl:-right-6"
  | "1xl:-right-6"
  | "xl:-right-6"
  | "lg:-right-6"
  | "md:-right-6"
  | "sm:-right-6"
  | "xs:-right-6"
  | "2xl:-bottom-6"
  | "1xl:-bottom-6"
  | "xl:-bottom-6"
  | "lg:-bottom-6"
  | "md:-bottom-6"
  | "sm:-bottom-6"
  | "xs:-bottom-6"
  | "2xl:-left-6"
  | "1xl:-left-6"
  | "xl:-left-6"
  | "lg:-left-6"
  | "md:-left-6"
  | "sm:-left-6"
  | "xs:-left-6"
  | "2xl:-inset-7"
  | "1xl:-inset-7"
  | "xl:-inset-7"
  | "lg:-inset-7"
  | "md:-inset-7"
  | "sm:-inset-7"
  | "xs:-inset-7"
  | "2xl:-inset-x-7"
  | "1xl:-inset-x-7"
  | "xl:-inset-x-7"
  | "lg:-inset-x-7"
  | "md:-inset-x-7"
  | "sm:-inset-x-7"
  | "xs:-inset-x-7"
  | "2xl:-inset-y-7"
  | "1xl:-inset-y-7"
  | "xl:-inset-y-7"
  | "lg:-inset-y-7"
  | "md:-inset-y-7"
  | "sm:-inset-y-7"
  | "xs:-inset-y-7"
  | "2xl:-top-7"
  | "1xl:-top-7"
  | "xl:-top-7"
  | "lg:-top-7"
  | "md:-top-7"
  | "sm:-top-7"
  | "xs:-top-7"
  | "2xl:-right-7"
  | "1xl:-right-7"
  | "xl:-right-7"
  | "lg:-right-7"
  | "md:-right-7"
  | "sm:-right-7"
  | "xs:-right-7"
  | "2xl:-bottom-7"
  | "1xl:-bottom-7"
  | "xl:-bottom-7"
  | "lg:-bottom-7"
  | "md:-bottom-7"
  | "sm:-bottom-7"
  | "xs:-bottom-7"
  | "2xl:-left-7"
  | "1xl:-left-7"
  | "xl:-left-7"
  | "lg:-left-7"
  | "md:-left-7"
  | "sm:-left-7"
  | "xs:-left-7"
  | "2xl:-inset-8"
  | "1xl:-inset-8"
  | "xl:-inset-8"
  | "lg:-inset-8"
  | "md:-inset-8"
  | "sm:-inset-8"
  | "xs:-inset-8"
  | "2xl:-inset-x-8"
  | "1xl:-inset-x-8"
  | "xl:-inset-x-8"
  | "lg:-inset-x-8"
  | "md:-inset-x-8"
  | "sm:-inset-x-8"
  | "xs:-inset-x-8"
  | "2xl:-inset-y-8"
  | "1xl:-inset-y-8"
  | "xl:-inset-y-8"
  | "lg:-inset-y-8"
  | "md:-inset-y-8"
  | "sm:-inset-y-8"
  | "xs:-inset-y-8"
  | "2xl:-top-8"
  | "1xl:-top-8"
  | "xl:-top-8"
  | "lg:-top-8"
  | "md:-top-8"
  | "sm:-top-8"
  | "xs:-top-8"
  | "2xl:-right-8"
  | "1xl:-right-8"
  | "xl:-right-8"
  | "lg:-right-8"
  | "md:-right-8"
  | "sm:-right-8"
  | "xs:-right-8"
  | "2xl:-bottom-8"
  | "1xl:-bottom-8"
  | "xl:-bottom-8"
  | "lg:-bottom-8"
  | "md:-bottom-8"
  | "sm:-bottom-8"
  | "xs:-bottom-8"
  | "2xl:-left-8"
  | "1xl:-left-8"
  | "xl:-left-8"
  | "lg:-left-8"
  | "md:-left-8"
  | "sm:-left-8"
  | "xs:-left-8"
  | "2xl:-inset-9"
  | "1xl:-inset-9"
  | "xl:-inset-9"
  | "lg:-inset-9"
  | "md:-inset-9"
  | "sm:-inset-9"
  | "xs:-inset-9"
  | "2xl:-inset-x-9"
  | "1xl:-inset-x-9"
  | "xl:-inset-x-9"
  | "lg:-inset-x-9"
  | "md:-inset-x-9"
  | "sm:-inset-x-9"
  | "xs:-inset-x-9"
  | "2xl:-inset-y-9"
  | "1xl:-inset-y-9"
  | "xl:-inset-y-9"
  | "lg:-inset-y-9"
  | "md:-inset-y-9"
  | "sm:-inset-y-9"
  | "xs:-inset-y-9"
  | "2xl:-top-9"
  | "1xl:-top-9"
  | "xl:-top-9"
  | "lg:-top-9"
  | "md:-top-9"
  | "sm:-top-9"
  | "xs:-top-9"
  | "2xl:-right-9"
  | "1xl:-right-9"
  | "xl:-right-9"
  | "lg:-right-9"
  | "md:-right-9"
  | "sm:-right-9"
  | "xs:-right-9"
  | "2xl:-bottom-9"
  | "1xl:-bottom-9"
  | "xl:-bottom-9"
  | "lg:-bottom-9"
  | "md:-bottom-9"
  | "sm:-bottom-9"
  | "xs:-bottom-9"
  | "2xl:-left-9"
  | "1xl:-left-9"
  | "xl:-left-9"
  | "lg:-left-9"
  | "md:-left-9"
  | "sm:-left-9"
  | "xs:-left-9"
  | "2xl:-inset-10"
  | "1xl:-inset-10"
  | "xl:-inset-10"
  | "lg:-inset-10"
  | "md:-inset-10"
  | "sm:-inset-10"
  | "xs:-inset-10"
  | "2xl:-inset-x-10"
  | "1xl:-inset-x-10"
  | "xl:-inset-x-10"
  | "lg:-inset-x-10"
  | "md:-inset-x-10"
  | "sm:-inset-x-10"
  | "xs:-inset-x-10"
  | "2xl:-inset-y-10"
  | "1xl:-inset-y-10"
  | "xl:-inset-y-10"
  | "lg:-inset-y-10"
  | "md:-inset-y-10"
  | "sm:-inset-y-10"
  | "xs:-inset-y-10"
  | "2xl:-top-10"
  | "1xl:-top-10"
  | "xl:-top-10"
  | "lg:-top-10"
  | "md:-top-10"
  | "sm:-top-10"
  | "xs:-top-10"
  | "2xl:-right-10"
  | "1xl:-right-10"
  | "xl:-right-10"
  | "lg:-right-10"
  | "md:-right-10"
  | "sm:-right-10"
  | "xs:-right-10"
  | "2xl:-bottom-10"
  | "1xl:-bottom-10"
  | "xl:-bottom-10"
  | "lg:-bottom-10"
  | "md:-bottom-10"
  | "sm:-bottom-10"
  | "xs:-bottom-10"
  | "2xl:-left-10"
  | "1xl:-left-10"
  | "xl:-left-10"
  | "lg:-left-10"
  | "md:-left-10"
  | "sm:-left-10"
  | "xs:-left-10"
  | "2xl:-inset-11"
  | "1xl:-inset-11"
  | "xl:-inset-11"
  | "lg:-inset-11"
  | "md:-inset-11"
  | "sm:-inset-11"
  | "xs:-inset-11"
  | "2xl:-inset-x-11"
  | "1xl:-inset-x-11"
  | "xl:-inset-x-11"
  | "lg:-inset-x-11"
  | "md:-inset-x-11"
  | "sm:-inset-x-11"
  | "xs:-inset-x-11"
  | "2xl:-inset-y-11"
  | "1xl:-inset-y-11"
  | "xl:-inset-y-11"
  | "lg:-inset-y-11"
  | "md:-inset-y-11"
  | "sm:-inset-y-11"
  | "xs:-inset-y-11"
  | "2xl:-top-11"
  | "1xl:-top-11"
  | "xl:-top-11"
  | "lg:-top-11"
  | "md:-top-11"
  | "sm:-top-11"
  | "xs:-top-11"
  | "2xl:-right-11"
  | "1xl:-right-11"
  | "xl:-right-11"
  | "lg:-right-11"
  | "md:-right-11"
  | "sm:-right-11"
  | "xs:-right-11"
  | "2xl:-bottom-11"
  | "1xl:-bottom-11"
  | "xl:-bottom-11"
  | "lg:-bottom-11"
  | "md:-bottom-11"
  | "sm:-bottom-11"
  | "xs:-bottom-11"
  | "2xl:-left-11"
  | "1xl:-left-11"
  | "xl:-left-11"
  | "lg:-left-11"
  | "md:-left-11"
  | "sm:-left-11"
  | "xs:-left-11"
  | "2xl:-inset-12"
  | "1xl:-inset-12"
  | "xl:-inset-12"
  | "lg:-inset-12"
  | "md:-inset-12"
  | "sm:-inset-12"
  | "xs:-inset-12"
  | "2xl:-inset-x-12"
  | "1xl:-inset-x-12"
  | "xl:-inset-x-12"
  | "lg:-inset-x-12"
  | "md:-inset-x-12"
  | "sm:-inset-x-12"
  | "xs:-inset-x-12"
  | "2xl:-inset-y-12"
  | "1xl:-inset-y-12"
  | "xl:-inset-y-12"
  | "lg:-inset-y-12"
  | "md:-inset-y-12"
  | "sm:-inset-y-12"
  | "xs:-inset-y-12"
  | "2xl:-top-12"
  | "1xl:-top-12"
  | "xl:-top-12"
  | "lg:-top-12"
  | "md:-top-12"
  | "sm:-top-12"
  | "xs:-top-12"
  | "2xl:-right-12"
  | "1xl:-right-12"
  | "xl:-right-12"
  | "lg:-right-12"
  | "md:-right-12"
  | "sm:-right-12"
  | "xs:-right-12"
  | "2xl:-bottom-12"
  | "1xl:-bottom-12"
  | "xl:-bottom-12"
  | "lg:-bottom-12"
  | "md:-bottom-12"
  | "sm:-bottom-12"
  | "xs:-bottom-12"
  | "2xl:-left-12"
  | "1xl:-left-12"
  | "xl:-left-12"
  | "lg:-left-12"
  | "md:-left-12"
  | "sm:-left-12"
  | "xs:-left-12"
  | "2xl:-inset-14"
  | "1xl:-inset-14"
  | "xl:-inset-14"
  | "lg:-inset-14"
  | "md:-inset-14"
  | "sm:-inset-14"
  | "xs:-inset-14"
  | "2xl:-inset-x-14"
  | "1xl:-inset-x-14"
  | "xl:-inset-x-14"
  | "lg:-inset-x-14"
  | "md:-inset-x-14"
  | "sm:-inset-x-14"
  | "xs:-inset-x-14"
  | "2xl:-inset-y-14"
  | "1xl:-inset-y-14"
  | "xl:-inset-y-14"
  | "lg:-inset-y-14"
  | "md:-inset-y-14"
  | "sm:-inset-y-14"
  | "xs:-inset-y-14"
  | "2xl:-top-14"
  | "1xl:-top-14"
  | "xl:-top-14"
  | "lg:-top-14"
  | "md:-top-14"
  | "sm:-top-14"
  | "xs:-top-14"
  | "2xl:-right-14"
  | "1xl:-right-14"
  | "xl:-right-14"
  | "lg:-right-14"
  | "md:-right-14"
  | "sm:-right-14"
  | "xs:-right-14"
  | "2xl:-bottom-14"
  | "1xl:-bottom-14"
  | "xl:-bottom-14"
  | "lg:-bottom-14"
  | "md:-bottom-14"
  | "sm:-bottom-14"
  | "xs:-bottom-14"
  | "2xl:-left-14"
  | "1xl:-left-14"
  | "xl:-left-14"
  | "lg:-left-14"
  | "md:-left-14"
  | "sm:-left-14"
  | "xs:-left-14"
  | "2xl:-inset-16"
  | "1xl:-inset-16"
  | "xl:-inset-16"
  | "lg:-inset-16"
  | "md:-inset-16"
  | "sm:-inset-16"
  | "xs:-inset-16"
  | "2xl:-inset-x-16"
  | "1xl:-inset-x-16"
  | "xl:-inset-x-16"
  | "lg:-inset-x-16"
  | "md:-inset-x-16"
  | "sm:-inset-x-16"
  | "xs:-inset-x-16"
  | "2xl:-inset-y-16"
  | "1xl:-inset-y-16"
  | "xl:-inset-y-16"
  | "lg:-inset-y-16"
  | "md:-inset-y-16"
  | "sm:-inset-y-16"
  | "xs:-inset-y-16"
  | "2xl:-top-16"
  | "1xl:-top-16"
  | "xl:-top-16"
  | "lg:-top-16"
  | "md:-top-16"
  | "sm:-top-16"
  | "xs:-top-16"
  | "2xl:-right-16"
  | "1xl:-right-16"
  | "xl:-right-16"
  | "lg:-right-16"
  | "md:-right-16"
  | "sm:-right-16"
  | "xs:-right-16"
  | "2xl:-bottom-16"
  | "1xl:-bottom-16"
  | "xl:-bottom-16"
  | "lg:-bottom-16"
  | "md:-bottom-16"
  | "sm:-bottom-16"
  | "xs:-bottom-16"
  | "2xl:-left-16"
  | "1xl:-left-16"
  | "xl:-left-16"
  | "lg:-left-16"
  | "md:-left-16"
  | "sm:-left-16"
  | "xs:-left-16"
  | "2xl:-inset-20"
  | "1xl:-inset-20"
  | "xl:-inset-20"
  | "lg:-inset-20"
  | "md:-inset-20"
  | "sm:-inset-20"
  | "xs:-inset-20"
  | "2xl:-inset-x-20"
  | "1xl:-inset-x-20"
  | "xl:-inset-x-20"
  | "lg:-inset-x-20"
  | "md:-inset-x-20"
  | "sm:-inset-x-20"
  | "xs:-inset-x-20"
  | "2xl:-inset-y-20"
  | "1xl:-inset-y-20"
  | "xl:-inset-y-20"
  | "lg:-inset-y-20"
  | "md:-inset-y-20"
  | "sm:-inset-y-20"
  | "xs:-inset-y-20"
  | "2xl:-top-20"
  | "1xl:-top-20"
  | "xl:-top-20"
  | "lg:-top-20"
  | "md:-top-20"
  | "sm:-top-20"
  | "xs:-top-20"
  | "2xl:-right-20"
  | "1xl:-right-20"
  | "xl:-right-20"
  | "lg:-right-20"
  | "md:-right-20"
  | "sm:-right-20"
  | "xs:-right-20"
  | "2xl:-bottom-20"
  | "1xl:-bottom-20"
  | "xl:-bottom-20"
  | "lg:-bottom-20"
  | "md:-bottom-20"
  | "sm:-bottom-20"
  | "xs:-bottom-20"
  | "2xl:-left-20"
  | "1xl:-left-20"
  | "xl:-left-20"
  | "lg:-left-20"
  | "md:-left-20"
  | "sm:-left-20"
  | "xs:-left-20"
  | "2xl:-inset-24"
  | "1xl:-inset-24"
  | "xl:-inset-24"
  | "lg:-inset-24"
  | "md:-inset-24"
  | "sm:-inset-24"
  | "xs:-inset-24"
  | "2xl:-inset-x-24"
  | "1xl:-inset-x-24"
  | "xl:-inset-x-24"
  | "lg:-inset-x-24"
  | "md:-inset-x-24"
  | "sm:-inset-x-24"
  | "xs:-inset-x-24"
  | "2xl:-inset-y-24"
  | "1xl:-inset-y-24"
  | "xl:-inset-y-24"
  | "lg:-inset-y-24"
  | "md:-inset-y-24"
  | "sm:-inset-y-24"
  | "xs:-inset-y-24"
  | "2xl:-top-24"
  | "1xl:-top-24"
  | "xl:-top-24"
  | "lg:-top-24"
  | "md:-top-24"
  | "sm:-top-24"
  | "xs:-top-24"
  | "2xl:-right-24"
  | "1xl:-right-24"
  | "xl:-right-24"
  | "lg:-right-24"
  | "md:-right-24"
  | "sm:-right-24"
  | "xs:-right-24"
  | "2xl:-bottom-24"
  | "1xl:-bottom-24"
  | "xl:-bottom-24"
  | "lg:-bottom-24"
  | "md:-bottom-24"
  | "sm:-bottom-24"
  | "xs:-bottom-24"
  | "2xl:-left-24"
  | "1xl:-left-24"
  | "xl:-left-24"
  | "lg:-left-24"
  | "md:-left-24"
  | "sm:-left-24"
  | "xs:-left-24"
  | "2xl:-inset-28"
  | "1xl:-inset-28"
  | "xl:-inset-28"
  | "lg:-inset-28"
  | "md:-inset-28"
  | "sm:-inset-28"
  | "xs:-inset-28"
  | "2xl:-inset-x-28"
  | "1xl:-inset-x-28"
  | "xl:-inset-x-28"
  | "lg:-inset-x-28"
  | "md:-inset-x-28"
  | "sm:-inset-x-28"
  | "xs:-inset-x-28"
  | "2xl:-inset-y-28"
  | "1xl:-inset-y-28"
  | "xl:-inset-y-28"
  | "lg:-inset-y-28"
  | "md:-inset-y-28"
  | "sm:-inset-y-28"
  | "xs:-inset-y-28"
  | "2xl:-top-28"
  | "1xl:-top-28"
  | "xl:-top-28"
  | "lg:-top-28"
  | "md:-top-28"
  | "sm:-top-28"
  | "xs:-top-28"
  | "2xl:-right-28"
  | "1xl:-right-28"
  | "xl:-right-28"
  | "lg:-right-28"
  | "md:-right-28"
  | "sm:-right-28"
  | "xs:-right-28"
  | "2xl:-bottom-28"
  | "1xl:-bottom-28"
  | "xl:-bottom-28"
  | "lg:-bottom-28"
  | "md:-bottom-28"
  | "sm:-bottom-28"
  | "xs:-bottom-28"
  | "2xl:-left-28"
  | "1xl:-left-28"
  | "xl:-left-28"
  | "lg:-left-28"
  | "md:-left-28"
  | "sm:-left-28"
  | "xs:-left-28"
  | "2xl:-inset-32"
  | "1xl:-inset-32"
  | "xl:-inset-32"
  | "lg:-inset-32"
  | "md:-inset-32"
  | "sm:-inset-32"
  | "xs:-inset-32"
  | "2xl:-inset-x-32"
  | "1xl:-inset-x-32"
  | "xl:-inset-x-32"
  | "lg:-inset-x-32"
  | "md:-inset-x-32"
  | "sm:-inset-x-32"
  | "xs:-inset-x-32"
  | "2xl:-inset-y-32"
  | "1xl:-inset-y-32"
  | "xl:-inset-y-32"
  | "lg:-inset-y-32"
  | "md:-inset-y-32"
  | "sm:-inset-y-32"
  | "xs:-inset-y-32"
  | "2xl:-top-32"
  | "1xl:-top-32"
  | "xl:-top-32"
  | "lg:-top-32"
  | "md:-top-32"
  | "sm:-top-32"
  | "xs:-top-32"
  | "2xl:-right-32"
  | "1xl:-right-32"
  | "xl:-right-32"
  | "lg:-right-32"
  | "md:-right-32"
  | "sm:-right-32"
  | "xs:-right-32"
  | "2xl:-bottom-32"
  | "1xl:-bottom-32"
  | "xl:-bottom-32"
  | "lg:-bottom-32"
  | "md:-bottom-32"
  | "sm:-bottom-32"
  | "xs:-bottom-32"
  | "2xl:-left-32"
  | "1xl:-left-32"
  | "xl:-left-32"
  | "lg:-left-32"
  | "md:-left-32"
  | "sm:-left-32"
  | "xs:-left-32"
  | "2xl:-inset-36"
  | "1xl:-inset-36"
  | "xl:-inset-36"
  | "lg:-inset-36"
  | "md:-inset-36"
  | "sm:-inset-36"
  | "xs:-inset-36"
  | "2xl:-inset-x-36"
  | "1xl:-inset-x-36"
  | "xl:-inset-x-36"
  | "lg:-inset-x-36"
  | "md:-inset-x-36"
  | "sm:-inset-x-36"
  | "xs:-inset-x-36"
  | "2xl:-inset-y-36"
  | "1xl:-inset-y-36"
  | "xl:-inset-y-36"
  | "lg:-inset-y-36"
  | "md:-inset-y-36"
  | "sm:-inset-y-36"
  | "xs:-inset-y-36"
  | "2xl:-top-36"
  | "1xl:-top-36"
  | "xl:-top-36"
  | "lg:-top-36"
  | "md:-top-36"
  | "sm:-top-36"
  | "xs:-top-36"
  | "2xl:-right-36"
  | "1xl:-right-36"
  | "xl:-right-36"
  | "lg:-right-36"
  | "md:-right-36"
  | "sm:-right-36"
  | "xs:-right-36"
  | "2xl:-bottom-36"
  | "1xl:-bottom-36"
  | "xl:-bottom-36"
  | "lg:-bottom-36"
  | "md:-bottom-36"
  | "sm:-bottom-36"
  | "xs:-bottom-36"
  | "2xl:-left-36"
  | "1xl:-left-36"
  | "xl:-left-36"
  | "lg:-left-36"
  | "md:-left-36"
  | "sm:-left-36"
  | "xs:-left-36"
  | "2xl:-inset-40"
  | "1xl:-inset-40"
  | "xl:-inset-40"
  | "lg:-inset-40"
  | "md:-inset-40"
  | "sm:-inset-40"
  | "xs:-inset-40"
  | "2xl:-inset-x-40"
  | "1xl:-inset-x-40"
  | "xl:-inset-x-40"
  | "lg:-inset-x-40"
  | "md:-inset-x-40"
  | "sm:-inset-x-40"
  | "xs:-inset-x-40"
  | "2xl:-inset-y-40"
  | "1xl:-inset-y-40"
  | "xl:-inset-y-40"
  | "lg:-inset-y-40"
  | "md:-inset-y-40"
  | "sm:-inset-y-40"
  | "xs:-inset-y-40"
  | "2xl:-top-40"
  | "1xl:-top-40"
  | "xl:-top-40"
  | "lg:-top-40"
  | "md:-top-40"
  | "sm:-top-40"
  | "xs:-top-40"
  | "2xl:-right-40"
  | "1xl:-right-40"
  | "xl:-right-40"
  | "lg:-right-40"
  | "md:-right-40"
  | "sm:-right-40"
  | "xs:-right-40"
  | "2xl:-bottom-40"
  | "1xl:-bottom-40"
  | "xl:-bottom-40"
  | "lg:-bottom-40"
  | "md:-bottom-40"
  | "sm:-bottom-40"
  | "xs:-bottom-40"
  | "2xl:-left-40"
  | "1xl:-left-40"
  | "xl:-left-40"
  | "lg:-left-40"
  | "md:-left-40"
  | "sm:-left-40"
  | "xs:-left-40"
  | "2xl:-inset-44"
  | "1xl:-inset-44"
  | "xl:-inset-44"
  | "lg:-inset-44"
  | "md:-inset-44"
  | "sm:-inset-44"
  | "xs:-inset-44"
  | "2xl:-inset-x-44"
  | "1xl:-inset-x-44"
  | "xl:-inset-x-44"
  | "lg:-inset-x-44"
  | "md:-inset-x-44"
  | "sm:-inset-x-44"
  | "xs:-inset-x-44"
  | "2xl:-inset-y-44"
  | "1xl:-inset-y-44"
  | "xl:-inset-y-44"
  | "lg:-inset-y-44"
  | "md:-inset-y-44"
  | "sm:-inset-y-44"
  | "xs:-inset-y-44"
  | "2xl:-top-44"
  | "1xl:-top-44"
  | "xl:-top-44"
  | "lg:-top-44"
  | "md:-top-44"
  | "sm:-top-44"
  | "xs:-top-44"
  | "2xl:-right-44"
  | "1xl:-right-44"
  | "xl:-right-44"
  | "lg:-right-44"
  | "md:-right-44"
  | "sm:-right-44"
  | "xs:-right-44"
  | "2xl:-bottom-44"
  | "1xl:-bottom-44"
  | "xl:-bottom-44"
  | "lg:-bottom-44"
  | "md:-bottom-44"
  | "sm:-bottom-44"
  | "xs:-bottom-44"
  | "2xl:-left-44"
  | "1xl:-left-44"
  | "xl:-left-44"
  | "lg:-left-44"
  | "md:-left-44"
  | "sm:-left-44"
  | "xs:-left-44"
  | "2xl:-inset-48"
  | "1xl:-inset-48"
  | "xl:-inset-48"
  | "lg:-inset-48"
  | "md:-inset-48"
  | "sm:-inset-48"
  | "xs:-inset-48"
  | "2xl:-inset-x-48"
  | "1xl:-inset-x-48"
  | "xl:-inset-x-48"
  | "lg:-inset-x-48"
  | "md:-inset-x-48"
  | "sm:-inset-x-48"
  | "xs:-inset-x-48"
  | "2xl:-inset-y-48"
  | "1xl:-inset-y-48"
  | "xl:-inset-y-48"
  | "lg:-inset-y-48"
  | "md:-inset-y-48"
  | "sm:-inset-y-48"
  | "xs:-inset-y-48"
  | "2xl:-top-48"
  | "1xl:-top-48"
  | "xl:-top-48"
  | "lg:-top-48"
  | "md:-top-48"
  | "sm:-top-48"
  | "xs:-top-48"
  | "2xl:-right-48"
  | "1xl:-right-48"
  | "xl:-right-48"
  | "lg:-right-48"
  | "md:-right-48"
  | "sm:-right-48"
  | "xs:-right-48"
  | "2xl:-bottom-48"
  | "1xl:-bottom-48"
  | "xl:-bottom-48"
  | "lg:-bottom-48"
  | "md:-bottom-48"
  | "sm:-bottom-48"
  | "xs:-bottom-48"
  | "2xl:-left-48"
  | "1xl:-left-48"
  | "xl:-left-48"
  | "lg:-left-48"
  | "md:-left-48"
  | "sm:-left-48"
  | "xs:-left-48"
  | "2xl:-inset-52"
  | "1xl:-inset-52"
  | "xl:-inset-52"
  | "lg:-inset-52"
  | "md:-inset-52"
  | "sm:-inset-52"
  | "xs:-inset-52"
  | "2xl:-inset-x-52"
  | "1xl:-inset-x-52"
  | "xl:-inset-x-52"
  | "lg:-inset-x-52"
  | "md:-inset-x-52"
  | "sm:-inset-x-52"
  | "xs:-inset-x-52"
  | "2xl:-inset-y-52"
  | "1xl:-inset-y-52"
  | "xl:-inset-y-52"
  | "lg:-inset-y-52"
  | "md:-inset-y-52"
  | "sm:-inset-y-52"
  | "xs:-inset-y-52"
  | "2xl:-top-52"
  | "1xl:-top-52"
  | "xl:-top-52"
  | "lg:-top-52"
  | "md:-top-52"
  | "sm:-top-52"
  | "xs:-top-52"
  | "2xl:-right-52"
  | "1xl:-right-52"
  | "xl:-right-52"
  | "lg:-right-52"
  | "md:-right-52"
  | "sm:-right-52"
  | "xs:-right-52"
  | "2xl:-bottom-52"
  | "1xl:-bottom-52"
  | "xl:-bottom-52"
  | "lg:-bottom-52"
  | "md:-bottom-52"
  | "sm:-bottom-52"
  | "xs:-bottom-52"
  | "2xl:-left-52"
  | "1xl:-left-52"
  | "xl:-left-52"
  | "lg:-left-52"
  | "md:-left-52"
  | "sm:-left-52"
  | "xs:-left-52"
  | "2xl:-inset-56"
  | "1xl:-inset-56"
  | "xl:-inset-56"
  | "lg:-inset-56"
  | "md:-inset-56"
  | "sm:-inset-56"
  | "xs:-inset-56"
  | "2xl:-inset-x-56"
  | "1xl:-inset-x-56"
  | "xl:-inset-x-56"
  | "lg:-inset-x-56"
  | "md:-inset-x-56"
  | "sm:-inset-x-56"
  | "xs:-inset-x-56"
  | "2xl:-inset-y-56"
  | "1xl:-inset-y-56"
  | "xl:-inset-y-56"
  | "lg:-inset-y-56"
  | "md:-inset-y-56"
  | "sm:-inset-y-56"
  | "xs:-inset-y-56"
  | "2xl:-top-56"
  | "1xl:-top-56"
  | "xl:-top-56"
  | "lg:-top-56"
  | "md:-top-56"
  | "sm:-top-56"
  | "xs:-top-56"
  | "2xl:-right-56"
  | "1xl:-right-56"
  | "xl:-right-56"
  | "lg:-right-56"
  | "md:-right-56"
  | "sm:-right-56"
  | "xs:-right-56"
  | "2xl:-bottom-56"
  | "1xl:-bottom-56"
  | "xl:-bottom-56"
  | "lg:-bottom-56"
  | "md:-bottom-56"
  | "sm:-bottom-56"
  | "xs:-bottom-56"
  | "2xl:-left-56"
  | "1xl:-left-56"
  | "xl:-left-56"
  | "lg:-left-56"
  | "md:-left-56"
  | "sm:-left-56"
  | "xs:-left-56"
  | "2xl:-inset-60"
  | "1xl:-inset-60"
  | "xl:-inset-60"
  | "lg:-inset-60"
  | "md:-inset-60"
  | "sm:-inset-60"
  | "xs:-inset-60"
  | "2xl:-inset-x-60"
  | "1xl:-inset-x-60"
  | "xl:-inset-x-60"
  | "lg:-inset-x-60"
  | "md:-inset-x-60"
  | "sm:-inset-x-60"
  | "xs:-inset-x-60"
  | "2xl:-inset-y-60"
  | "1xl:-inset-y-60"
  | "xl:-inset-y-60"
  | "lg:-inset-y-60"
  | "md:-inset-y-60"
  | "sm:-inset-y-60"
  | "xs:-inset-y-60"
  | "2xl:-top-60"
  | "1xl:-top-60"
  | "xl:-top-60"
  | "lg:-top-60"
  | "md:-top-60"
  | "sm:-top-60"
  | "xs:-top-60"
  | "2xl:-right-60"
  | "1xl:-right-60"
  | "xl:-right-60"
  | "lg:-right-60"
  | "md:-right-60"
  | "sm:-right-60"
  | "xs:-right-60"
  | "2xl:-bottom-60"
  | "1xl:-bottom-60"
  | "xl:-bottom-60"
  | "lg:-bottom-60"
  | "md:-bottom-60"
  | "sm:-bottom-60"
  | "xs:-bottom-60"
  | "2xl:-left-60"
  | "1xl:-left-60"
  | "xl:-left-60"
  | "lg:-left-60"
  | "md:-left-60"
  | "sm:-left-60"
  | "xs:-left-60"
  | "2xl:-inset-64"
  | "1xl:-inset-64"
  | "xl:-inset-64"
  | "lg:-inset-64"
  | "md:-inset-64"
  | "sm:-inset-64"
  | "xs:-inset-64"
  | "2xl:-inset-x-64"
  | "1xl:-inset-x-64"
  | "xl:-inset-x-64"
  | "lg:-inset-x-64"
  | "md:-inset-x-64"
  | "sm:-inset-x-64"
  | "xs:-inset-x-64"
  | "2xl:-inset-y-64"
  | "1xl:-inset-y-64"
  | "xl:-inset-y-64"
  | "lg:-inset-y-64"
  | "md:-inset-y-64"
  | "sm:-inset-y-64"
  | "xs:-inset-y-64"
  | "2xl:-top-64"
  | "1xl:-top-64"
  | "xl:-top-64"
  | "lg:-top-64"
  | "md:-top-64"
  | "sm:-top-64"
  | "xs:-top-64"
  | "2xl:-right-64"
  | "1xl:-right-64"
  | "xl:-right-64"
  | "lg:-right-64"
  | "md:-right-64"
  | "sm:-right-64"
  | "xs:-right-64"
  | "2xl:-bottom-64"
  | "1xl:-bottom-64"
  | "xl:-bottom-64"
  | "lg:-bottom-64"
  | "md:-bottom-64"
  | "sm:-bottom-64"
  | "xs:-bottom-64"
  | "2xl:-left-64"
  | "1xl:-left-64"
  | "xl:-left-64"
  | "lg:-left-64"
  | "md:-left-64"
  | "sm:-left-64"
  | "xs:-left-64"
  | "2xl:-inset-72"
  | "1xl:-inset-72"
  | "xl:-inset-72"
  | "lg:-inset-72"
  | "md:-inset-72"
  | "sm:-inset-72"
  | "xs:-inset-72"
  | "2xl:-inset-x-72"
  | "1xl:-inset-x-72"
  | "xl:-inset-x-72"
  | "lg:-inset-x-72"
  | "md:-inset-x-72"
  | "sm:-inset-x-72"
  | "xs:-inset-x-72"
  | "2xl:-inset-y-72"
  | "1xl:-inset-y-72"
  | "xl:-inset-y-72"
  | "lg:-inset-y-72"
  | "md:-inset-y-72"
  | "sm:-inset-y-72"
  | "xs:-inset-y-72"
  | "2xl:-top-72"
  | "1xl:-top-72"
  | "xl:-top-72"
  | "lg:-top-72"
  | "md:-top-72"
  | "sm:-top-72"
  | "xs:-top-72"
  | "2xl:-right-72"
  | "1xl:-right-72"
  | "xl:-right-72"
  | "lg:-right-72"
  | "md:-right-72"
  | "sm:-right-72"
  | "xs:-right-72"
  | "2xl:-bottom-72"
  | "1xl:-bottom-72"
  | "xl:-bottom-72"
  | "lg:-bottom-72"
  | "md:-bottom-72"
  | "sm:-bottom-72"
  | "xs:-bottom-72"
  | "2xl:-left-72"
  | "1xl:-left-72"
  | "xl:-left-72"
  | "lg:-left-72"
  | "md:-left-72"
  | "sm:-left-72"
  | "xs:-left-72"
  | "2xl:-inset-80"
  | "1xl:-inset-80"
  | "xl:-inset-80"
  | "lg:-inset-80"
  | "md:-inset-80"
  | "sm:-inset-80"
  | "xs:-inset-80"
  | "2xl:-inset-x-80"
  | "1xl:-inset-x-80"
  | "xl:-inset-x-80"
  | "lg:-inset-x-80"
  | "md:-inset-x-80"
  | "sm:-inset-x-80"
  | "xs:-inset-x-80"
  | "2xl:-inset-y-80"
  | "1xl:-inset-y-80"
  | "xl:-inset-y-80"
  | "lg:-inset-y-80"
  | "md:-inset-y-80"
  | "sm:-inset-y-80"
  | "xs:-inset-y-80"
  | "2xl:-top-80"
  | "1xl:-top-80"
  | "xl:-top-80"
  | "lg:-top-80"
  | "md:-top-80"
  | "sm:-top-80"
  | "xs:-top-80"
  | "2xl:-right-80"
  | "1xl:-right-80"
  | "xl:-right-80"
  | "lg:-right-80"
  | "md:-right-80"
  | "sm:-right-80"
  | "xs:-right-80"
  | "2xl:-bottom-80"
  | "1xl:-bottom-80"
  | "xl:-bottom-80"
  | "lg:-bottom-80"
  | "md:-bottom-80"
  | "sm:-bottom-80"
  | "xs:-bottom-80"
  | "2xl:-left-80"
  | "1xl:-left-80"
  | "xl:-left-80"
  | "lg:-left-80"
  | "md:-left-80"
  | "sm:-left-80"
  | "xs:-left-80"
  | "2xl:-inset-96"
  | "1xl:-inset-96"
  | "xl:-inset-96"
  | "lg:-inset-96"
  | "md:-inset-96"
  | "sm:-inset-96"
  | "xs:-inset-96"
  | "2xl:-inset-x-96"
  | "1xl:-inset-x-96"
  | "xl:-inset-x-96"
  | "lg:-inset-x-96"
  | "md:-inset-x-96"
  | "sm:-inset-x-96"
  | "xs:-inset-x-96"
  | "2xl:-inset-y-96"
  | "1xl:-inset-y-96"
  | "xl:-inset-y-96"
  | "lg:-inset-y-96"
  | "md:-inset-y-96"
  | "sm:-inset-y-96"
  | "xs:-inset-y-96"
  | "2xl:-top-96"
  | "1xl:-top-96"
  | "xl:-top-96"
  | "lg:-top-96"
  | "md:-top-96"
  | "sm:-top-96"
  | "xs:-top-96"
  | "2xl:-right-96"
  | "1xl:-right-96"
  | "xl:-right-96"
  | "lg:-right-96"
  | "md:-right-96"
  | "sm:-right-96"
  | "xs:-right-96"
  | "2xl:-bottom-96"
  | "1xl:-bottom-96"
  | "xl:-bottom-96"
  | "lg:-bottom-96"
  | "md:-bottom-96"
  | "sm:-bottom-96"
  | "xs:-bottom-96"
  | "2xl:-left-96"
  | "1xl:-left-96"
  | "xl:-left-96"
  | "lg:-left-96"
  | "md:-left-96"
  | "sm:-left-96"
  | "xs:-left-96"
  | "2xl:-inset-px"
  | "1xl:-inset-px"
  | "xl:-inset-px"
  | "lg:-inset-px"
  | "md:-inset-px"
  | "sm:-inset-px"
  | "xs:-inset-px"
  | "2xl:-inset-x-px"
  | "1xl:-inset-x-px"
  | "xl:-inset-x-px"
  | "lg:-inset-x-px"
  | "md:-inset-x-px"
  | "sm:-inset-x-px"
  | "xs:-inset-x-px"
  | "2xl:-inset-y-px"
  | "1xl:-inset-y-px"
  | "xl:-inset-y-px"
  | "lg:-inset-y-px"
  | "md:-inset-y-px"
  | "sm:-inset-y-px"
  | "xs:-inset-y-px"
  | "2xl:-top-px"
  | "1xl:-top-px"
  | "xl:-top-px"
  | "lg:-top-px"
  | "md:-top-px"
  | "sm:-top-px"
  | "xs:-top-px"
  | "2xl:-right-px"
  | "1xl:-right-px"
  | "xl:-right-px"
  | "lg:-right-px"
  | "md:-right-px"
  | "sm:-right-px"
  | "xs:-right-px"
  | "2xl:-bottom-px"
  | "1xl:-bottom-px"
  | "xl:-bottom-px"
  | "lg:-bottom-px"
  | "md:-bottom-px"
  | "sm:-bottom-px"
  | "xs:-bottom-px"
  | "2xl:-left-px"
  | "1xl:-left-px"
  | "xl:-left-px"
  | "lg:-left-px"
  | "md:-left-px"
  | "sm:-left-px"
  | "xs:-left-px"
  | "2xl:-inset-0.5"
  | "1xl:-inset-0.5"
  | "xl:-inset-0.5"
  | "lg:-inset-0.5"
  | "md:-inset-0.5"
  | "sm:-inset-0.5"
  | "xs:-inset-0.5"
  | "2xl:-inset-x-0.5"
  | "1xl:-inset-x-0.5"
  | "xl:-inset-x-0.5"
  | "lg:-inset-x-0.5"
  | "md:-inset-x-0.5"
  | "sm:-inset-x-0.5"
  | "xs:-inset-x-0.5"
  | "2xl:-inset-y-0.5"
  | "1xl:-inset-y-0.5"
  | "xl:-inset-y-0.5"
  | "lg:-inset-y-0.5"
  | "md:-inset-y-0.5"
  | "sm:-inset-y-0.5"
  | "xs:-inset-y-0.5"
  | "2xl:-top-0.5"
  | "1xl:-top-0.5"
  | "xl:-top-0.5"
  | "lg:-top-0.5"
  | "md:-top-0.5"
  | "sm:-top-0.5"
  | "xs:-top-0.5"
  | "2xl:-right-0.5"
  | "1xl:-right-0.5"
  | "xl:-right-0.5"
  | "lg:-right-0.5"
  | "md:-right-0.5"
  | "sm:-right-0.5"
  | "xs:-right-0.5"
  | "2xl:-bottom-0.5"
  | "1xl:-bottom-0.5"
  | "xl:-bottom-0.5"
  | "lg:-bottom-0.5"
  | "md:-bottom-0.5"
  | "sm:-bottom-0.5"
  | "xs:-bottom-0.5"
  | "2xl:-left-0.5"
  | "1xl:-left-0.5"
  | "xl:-left-0.5"
  | "lg:-left-0.5"
  | "md:-left-0.5"
  | "sm:-left-0.5"
  | "xs:-left-0.5"
  | "2xl:-inset-1.5"
  | "1xl:-inset-1.5"
  | "xl:-inset-1.5"
  | "lg:-inset-1.5"
  | "md:-inset-1.5"
  | "sm:-inset-1.5"
  | "xs:-inset-1.5"
  | "2xl:-inset-x-1.5"
  | "1xl:-inset-x-1.5"
  | "xl:-inset-x-1.5"
  | "lg:-inset-x-1.5"
  | "md:-inset-x-1.5"
  | "sm:-inset-x-1.5"
  | "xs:-inset-x-1.5"
  | "2xl:-inset-y-1.5"
  | "1xl:-inset-y-1.5"
  | "xl:-inset-y-1.5"
  | "lg:-inset-y-1.5"
  | "md:-inset-y-1.5"
  | "sm:-inset-y-1.5"
  | "xs:-inset-y-1.5"
  | "2xl:-top-1.5"
  | "1xl:-top-1.5"
  | "xl:-top-1.5"
  | "lg:-top-1.5"
  | "md:-top-1.5"
  | "sm:-top-1.5"
  | "xs:-top-1.5"
  | "2xl:-right-1.5"
  | "1xl:-right-1.5"
  | "xl:-right-1.5"
  | "lg:-right-1.5"
  | "md:-right-1.5"
  | "sm:-right-1.5"
  | "xs:-right-1.5"
  | "2xl:-bottom-1.5"
  | "1xl:-bottom-1.5"
  | "xl:-bottom-1.5"
  | "lg:-bottom-1.5"
  | "md:-bottom-1.5"
  | "sm:-bottom-1.5"
  | "xs:-bottom-1.5"
  | "2xl:-left-1.5"
  | "1xl:-left-1.5"
  | "xl:-left-1.5"
  | "lg:-left-1.5"
  | "md:-left-1.5"
  | "sm:-left-1.5"
  | "xs:-left-1.5"
  | "2xl:-inset-2.5"
  | "1xl:-inset-2.5"
  | "xl:-inset-2.5"
  | "lg:-inset-2.5"
  | "md:-inset-2.5"
  | "sm:-inset-2.5"
  | "xs:-inset-2.5"
  | "2xl:-inset-x-2.5"
  | "1xl:-inset-x-2.5"
  | "xl:-inset-x-2.5"
  | "lg:-inset-x-2.5"
  | "md:-inset-x-2.5"
  | "sm:-inset-x-2.5"
  | "xs:-inset-x-2.5"
  | "2xl:-inset-y-2.5"
  | "1xl:-inset-y-2.5"
  | "xl:-inset-y-2.5"
  | "lg:-inset-y-2.5"
  | "md:-inset-y-2.5"
  | "sm:-inset-y-2.5"
  | "xs:-inset-y-2.5"
  | "2xl:-top-2.5"
  | "1xl:-top-2.5"
  | "xl:-top-2.5"
  | "lg:-top-2.5"
  | "md:-top-2.5"
  | "sm:-top-2.5"
  | "xs:-top-2.5"
  | "2xl:-right-2.5"
  | "1xl:-right-2.5"
  | "xl:-right-2.5"
  | "lg:-right-2.5"
  | "md:-right-2.5"
  | "sm:-right-2.5"
  | "xs:-right-2.5"
  | "2xl:-bottom-2.5"
  | "1xl:-bottom-2.5"
  | "xl:-bottom-2.5"
  | "lg:-bottom-2.5"
  | "md:-bottom-2.5"
  | "sm:-bottom-2.5"
  | "xs:-bottom-2.5"
  | "2xl:-left-2.5"
  | "1xl:-left-2.5"
  | "xl:-left-2.5"
  | "lg:-left-2.5"
  | "md:-left-2.5"
  | "sm:-left-2.5"
  | "xs:-left-2.5"
  | "2xl:-inset-3.5"
  | "1xl:-inset-3.5"
  | "xl:-inset-3.5"
  | "lg:-inset-3.5"
  | "md:-inset-3.5"
  | "sm:-inset-3.5"
  | "xs:-inset-3.5"
  | "2xl:-inset-x-3.5"
  | "1xl:-inset-x-3.5"
  | "xl:-inset-x-3.5"
  | "lg:-inset-x-3.5"
  | "md:-inset-x-3.5"
  | "sm:-inset-x-3.5"
  | "xs:-inset-x-3.5"
  | "2xl:-inset-y-3.5"
  | "1xl:-inset-y-3.5"
  | "xl:-inset-y-3.5"
  | "lg:-inset-y-3.5"
  | "md:-inset-y-3.5"
  | "sm:-inset-y-3.5"
  | "xs:-inset-y-3.5"
  | "2xl:-top-3.5"
  | "1xl:-top-3.5"
  | "xl:-top-3.5"
  | "lg:-top-3.5"
  | "md:-top-3.5"
  | "sm:-top-3.5"
  | "xs:-top-3.5"
  | "2xl:-right-3.5"
  | "1xl:-right-3.5"
  | "xl:-right-3.5"
  | "lg:-right-3.5"
  | "md:-right-3.5"
  | "sm:-right-3.5"
  | "xs:-right-3.5"
  | "2xl:-bottom-3.5"
  | "1xl:-bottom-3.5"
  | "xl:-bottom-3.5"
  | "lg:-bottom-3.5"
  | "md:-bottom-3.5"
  | "sm:-bottom-3.5"
  | "xs:-bottom-3.5"
  | "2xl:-left-3.5"
  | "1xl:-left-3.5"
  | "xl:-left-3.5"
  | "lg:-left-3.5"
  | "md:-left-3.5"
  | "sm:-left-3.5"
  | "xs:-left-3.5"
  | "2xl:inset-1/2"
  | "1xl:inset-1/2"
  | "xl:inset-1/2"
  | "lg:inset-1/2"
  | "md:inset-1/2"
  | "sm:inset-1/2"
  | "xs:inset-1/2"
  | "2xl:inset-x-1/2"
  | "1xl:inset-x-1/2"
  | "xl:inset-x-1/2"
  | "lg:inset-x-1/2"
  | "md:inset-x-1/2"
  | "sm:inset-x-1/2"
  | "xs:inset-x-1/2"
  | "2xl:inset-y-1/2"
  | "1xl:inset-y-1/2"
  | "xl:inset-y-1/2"
  | "lg:inset-y-1/2"
  | "md:inset-y-1/2"
  | "sm:inset-y-1/2"
  | "xs:inset-y-1/2"
  | "2xl:top-1/2"
  | "1xl:top-1/2"
  | "xl:top-1/2"
  | "lg:top-1/2"
  | "md:top-1/2"
  | "sm:top-1/2"
  | "xs:top-1/2"
  | "2xl:right-1/2"
  | "1xl:right-1/2"
  | "xl:right-1/2"
  | "lg:right-1/2"
  | "md:right-1/2"
  | "sm:right-1/2"
  | "xs:right-1/2"
  | "2xl:bottom-1/2"
  | "1xl:bottom-1/2"
  | "xl:bottom-1/2"
  | "lg:bottom-1/2"
  | "md:bottom-1/2"
  | "sm:bottom-1/2"
  | "xs:bottom-1/2"
  | "2xl:left-1/2"
  | "1xl:left-1/2"
  | "xl:left-1/2"
  | "lg:left-1/2"
  | "md:left-1/2"
  | "sm:left-1/2"
  | "xs:left-1/2"
  | "2xl:inset-1/3"
  | "1xl:inset-1/3"
  | "xl:inset-1/3"
  | "lg:inset-1/3"
  | "md:inset-1/3"
  | "sm:inset-1/3"
  | "xs:inset-1/3"
  | "2xl:inset-x-1/3"
  | "1xl:inset-x-1/3"
  | "xl:inset-x-1/3"
  | "lg:inset-x-1/3"
  | "md:inset-x-1/3"
  | "sm:inset-x-1/3"
  | "xs:inset-x-1/3"
  | "2xl:inset-y-1/3"
  | "1xl:inset-y-1/3"
  | "xl:inset-y-1/3"
  | "lg:inset-y-1/3"
  | "md:inset-y-1/3"
  | "sm:inset-y-1/3"
  | "xs:inset-y-1/3"
  | "2xl:top-1/3"
  | "1xl:top-1/3"
  | "xl:top-1/3"
  | "lg:top-1/3"
  | "md:top-1/3"
  | "sm:top-1/3"
  | "xs:top-1/3"
  | "2xl:right-1/3"
  | "1xl:right-1/3"
  | "xl:right-1/3"
  | "lg:right-1/3"
  | "md:right-1/3"
  | "sm:right-1/3"
  | "xs:right-1/3"
  | "2xl:bottom-1/3"
  | "1xl:bottom-1/3"
  | "xl:bottom-1/3"
  | "lg:bottom-1/3"
  | "md:bottom-1/3"
  | "sm:bottom-1/3"
  | "xs:bottom-1/3"
  | "2xl:left-1/3"
  | "1xl:left-1/3"
  | "xl:left-1/3"
  | "lg:left-1/3"
  | "md:left-1/3"
  | "sm:left-1/3"
  | "xs:left-1/3"
  | "2xl:inset-2/3"
  | "1xl:inset-2/3"
  | "xl:inset-2/3"
  | "lg:inset-2/3"
  | "md:inset-2/3"
  | "sm:inset-2/3"
  | "xs:inset-2/3"
  | "2xl:inset-x-2/3"
  | "1xl:inset-x-2/3"
  | "xl:inset-x-2/3"
  | "lg:inset-x-2/3"
  | "md:inset-x-2/3"
  | "sm:inset-x-2/3"
  | "xs:inset-x-2/3"
  | "2xl:inset-y-2/3"
  | "1xl:inset-y-2/3"
  | "xl:inset-y-2/3"
  | "lg:inset-y-2/3"
  | "md:inset-y-2/3"
  | "sm:inset-y-2/3"
  | "xs:inset-y-2/3"
  | "2xl:top-2/3"
  | "1xl:top-2/3"
  | "xl:top-2/3"
  | "lg:top-2/3"
  | "md:top-2/3"
  | "sm:top-2/3"
  | "xs:top-2/3"
  | "2xl:right-2/3"
  | "1xl:right-2/3"
  | "xl:right-2/3"
  | "lg:right-2/3"
  | "md:right-2/3"
  | "sm:right-2/3"
  | "xs:right-2/3"
  | "2xl:bottom-2/3"
  | "1xl:bottom-2/3"
  | "xl:bottom-2/3"
  | "lg:bottom-2/3"
  | "md:bottom-2/3"
  | "sm:bottom-2/3"
  | "xs:bottom-2/3"
  | "2xl:left-2/3"
  | "1xl:left-2/3"
  | "xl:left-2/3"
  | "lg:left-2/3"
  | "md:left-2/3"
  | "sm:left-2/3"
  | "xs:left-2/3"
  | "2xl:inset-1/4"
  | "1xl:inset-1/4"
  | "xl:inset-1/4"
  | "lg:inset-1/4"
  | "md:inset-1/4"
  | "sm:inset-1/4"
  | "xs:inset-1/4"
  | "2xl:inset-x-1/4"
  | "1xl:inset-x-1/4"
  | "xl:inset-x-1/4"
  | "lg:inset-x-1/4"
  | "md:inset-x-1/4"
  | "sm:inset-x-1/4"
  | "xs:inset-x-1/4"
  | "2xl:inset-y-1/4"
  | "1xl:inset-y-1/4"
  | "xl:inset-y-1/4"
  | "lg:inset-y-1/4"
  | "md:inset-y-1/4"
  | "sm:inset-y-1/4"
  | "xs:inset-y-1/4"
  | "2xl:top-1/4"
  | "1xl:top-1/4"
  | "xl:top-1/4"
  | "lg:top-1/4"
  | "md:top-1/4"
  | "sm:top-1/4"
  | "xs:top-1/4"
  | "2xl:right-1/4"
  | "1xl:right-1/4"
  | "xl:right-1/4"
  | "lg:right-1/4"
  | "md:right-1/4"
  | "sm:right-1/4"
  | "xs:right-1/4"
  | "2xl:bottom-1/4"
  | "1xl:bottom-1/4"
  | "xl:bottom-1/4"
  | "lg:bottom-1/4"
  | "md:bottom-1/4"
  | "sm:bottom-1/4"
  | "xs:bottom-1/4"
  | "2xl:left-1/4"
  | "1xl:left-1/4"
  | "xl:left-1/4"
  | "lg:left-1/4"
  | "md:left-1/4"
  | "sm:left-1/4"
  | "xs:left-1/4"
  | "2xl:inset-2/4"
  | "1xl:inset-2/4"
  | "xl:inset-2/4"
  | "lg:inset-2/4"
  | "md:inset-2/4"
  | "sm:inset-2/4"
  | "xs:inset-2/4"
  | "2xl:inset-x-2/4"
  | "1xl:inset-x-2/4"
  | "xl:inset-x-2/4"
  | "lg:inset-x-2/4"
  | "md:inset-x-2/4"
  | "sm:inset-x-2/4"
  | "xs:inset-x-2/4"
  | "2xl:inset-y-2/4"
  | "1xl:inset-y-2/4"
  | "xl:inset-y-2/4"
  | "lg:inset-y-2/4"
  | "md:inset-y-2/4"
  | "sm:inset-y-2/4"
  | "xs:inset-y-2/4"
  | "2xl:top-2/4"
  | "1xl:top-2/4"
  | "xl:top-2/4"
  | "lg:top-2/4"
  | "md:top-2/4"
  | "sm:top-2/4"
  | "xs:top-2/4"
  | "2xl:right-2/4"
  | "1xl:right-2/4"
  | "xl:right-2/4"
  | "lg:right-2/4"
  | "md:right-2/4"
  | "sm:right-2/4"
  | "xs:right-2/4"
  | "2xl:bottom-2/4"
  | "1xl:bottom-2/4"
  | "xl:bottom-2/4"
  | "lg:bottom-2/4"
  | "md:bottom-2/4"
  | "sm:bottom-2/4"
  | "xs:bottom-2/4"
  | "2xl:left-2/4"
  | "1xl:left-2/4"
  | "xl:left-2/4"
  | "lg:left-2/4"
  | "md:left-2/4"
  | "sm:left-2/4"
  | "xs:left-2/4"
  | "2xl:inset-3/4"
  | "1xl:inset-3/4"
  | "xl:inset-3/4"
  | "lg:inset-3/4"
  | "md:inset-3/4"
  | "sm:inset-3/4"
  | "xs:inset-3/4"
  | "2xl:inset-x-3/4"
  | "1xl:inset-x-3/4"
  | "xl:inset-x-3/4"
  | "lg:inset-x-3/4"
  | "md:inset-x-3/4"
  | "sm:inset-x-3/4"
  | "xs:inset-x-3/4"
  | "2xl:inset-y-3/4"
  | "1xl:inset-y-3/4"
  | "xl:inset-y-3/4"
  | "lg:inset-y-3/4"
  | "md:inset-y-3/4"
  | "sm:inset-y-3/4"
  | "xs:inset-y-3/4"
  | "2xl:top-3/4"
  | "1xl:top-3/4"
  | "xl:top-3/4"
  | "lg:top-3/4"
  | "md:top-3/4"
  | "sm:top-3/4"
  | "xs:top-3/4"
  | "2xl:right-3/4"
  | "1xl:right-3/4"
  | "xl:right-3/4"
  | "lg:right-3/4"
  | "md:right-3/4"
  | "sm:right-3/4"
  | "xs:right-3/4"
  | "2xl:bottom-3/4"
  | "1xl:bottom-3/4"
  | "xl:bottom-3/4"
  | "lg:bottom-3/4"
  | "md:bottom-3/4"
  | "sm:bottom-3/4"
  | "xs:bottom-3/4"
  | "2xl:left-3/4"
  | "1xl:left-3/4"
  | "xl:left-3/4"
  | "lg:left-3/4"
  | "md:left-3/4"
  | "sm:left-3/4"
  | "xs:left-3/4"
  | "2xl:inset-full"
  | "1xl:inset-full"
  | "xl:inset-full"
  | "lg:inset-full"
  | "md:inset-full"
  | "sm:inset-full"
  | "xs:inset-full"
  | "2xl:inset-x-full"
  | "1xl:inset-x-full"
  | "xl:inset-x-full"
  | "lg:inset-x-full"
  | "md:inset-x-full"
  | "sm:inset-x-full"
  | "xs:inset-x-full"
  | "2xl:inset-y-full"
  | "1xl:inset-y-full"
  | "xl:inset-y-full"
  | "lg:inset-y-full"
  | "md:inset-y-full"
  | "sm:inset-y-full"
  | "xs:inset-y-full"
  | "2xl:top-full"
  | "1xl:top-full"
  | "xl:top-full"
  | "lg:top-full"
  | "md:top-full"
  | "sm:top-full"
  | "xs:top-full"
  | "2xl:right-full"
  | "1xl:right-full"
  | "xl:right-full"
  | "lg:right-full"
  | "md:right-full"
  | "sm:right-full"
  | "xs:right-full"
  | "2xl:bottom-full"
  | "1xl:bottom-full"
  | "xl:bottom-full"
  | "lg:bottom-full"
  | "md:bottom-full"
  | "sm:bottom-full"
  | "xs:bottom-full"
  | "2xl:left-full"
  | "1xl:left-full"
  | "xl:left-full"
  | "lg:left-full"
  | "md:left-full"
  | "sm:left-full"
  | "xs:left-full"
  | "2xl:-inset-1/2"
  | "1xl:-inset-1/2"
  | "xl:-inset-1/2"
  | "lg:-inset-1/2"
  | "md:-inset-1/2"
  | "sm:-inset-1/2"
  | "xs:-inset-1/2"
  | "2xl:-inset-x-1/2"
  | "1xl:-inset-x-1/2"
  | "xl:-inset-x-1/2"
  | "lg:-inset-x-1/2"
  | "md:-inset-x-1/2"
  | "sm:-inset-x-1/2"
  | "xs:-inset-x-1/2"
  | "2xl:-inset-y-1/2"
  | "1xl:-inset-y-1/2"
  | "xl:-inset-y-1/2"
  | "lg:-inset-y-1/2"
  | "md:-inset-y-1/2"
  | "sm:-inset-y-1/2"
  | "xs:-inset-y-1/2"
  | "2xl:-top-1/2"
  | "1xl:-top-1/2"
  | "xl:-top-1/2"
  | "lg:-top-1/2"
  | "md:-top-1/2"
  | "sm:-top-1/2"
  | "xs:-top-1/2"
  | "2xl:-right-1/2"
  | "1xl:-right-1/2"
  | "xl:-right-1/2"
  | "lg:-right-1/2"
  | "md:-right-1/2"
  | "sm:-right-1/2"
  | "xs:-right-1/2"
  | "2xl:-bottom-1/2"
  | "1xl:-bottom-1/2"
  | "xl:-bottom-1/2"
  | "lg:-bottom-1/2"
  | "md:-bottom-1/2"
  | "sm:-bottom-1/2"
  | "xs:-bottom-1/2"
  | "2xl:-left-1/2"
  | "1xl:-left-1/2"
  | "xl:-left-1/2"
  | "lg:-left-1/2"
  | "md:-left-1/2"
  | "sm:-left-1/2"
  | "xs:-left-1/2"
  | "2xl:-inset-1/3"
  | "1xl:-inset-1/3"
  | "xl:-inset-1/3"
  | "lg:-inset-1/3"
  | "md:-inset-1/3"
  | "sm:-inset-1/3"
  | "xs:-inset-1/3"
  | "2xl:-inset-x-1/3"
  | "1xl:-inset-x-1/3"
  | "xl:-inset-x-1/3"
  | "lg:-inset-x-1/3"
  | "md:-inset-x-1/3"
  | "sm:-inset-x-1/3"
  | "xs:-inset-x-1/3"
  | "2xl:-inset-y-1/3"
  | "1xl:-inset-y-1/3"
  | "xl:-inset-y-1/3"
  | "lg:-inset-y-1/3"
  | "md:-inset-y-1/3"
  | "sm:-inset-y-1/3"
  | "xs:-inset-y-1/3"
  | "2xl:-top-1/3"
  | "1xl:-top-1/3"
  | "xl:-top-1/3"
  | "lg:-top-1/3"
  | "md:-top-1/3"
  | "sm:-top-1/3"
  | "xs:-top-1/3"
  | "2xl:-right-1/3"
  | "1xl:-right-1/3"
  | "xl:-right-1/3"
  | "lg:-right-1/3"
  | "md:-right-1/3"
  | "sm:-right-1/3"
  | "xs:-right-1/3"
  | "2xl:-bottom-1/3"
  | "1xl:-bottom-1/3"
  | "xl:-bottom-1/3"
  | "lg:-bottom-1/3"
  | "md:-bottom-1/3"
  | "sm:-bottom-1/3"
  | "xs:-bottom-1/3"
  | "2xl:-left-1/3"
  | "1xl:-left-1/3"
  | "xl:-left-1/3"
  | "lg:-left-1/3"
  | "md:-left-1/3"
  | "sm:-left-1/3"
  | "xs:-left-1/3"
  | "2xl:-inset-2/3"
  | "1xl:-inset-2/3"
  | "xl:-inset-2/3"
  | "lg:-inset-2/3"
  | "md:-inset-2/3"
  | "sm:-inset-2/3"
  | "xs:-inset-2/3"
  | "2xl:-inset-x-2/3"
  | "1xl:-inset-x-2/3"
  | "xl:-inset-x-2/3"
  | "lg:-inset-x-2/3"
  | "md:-inset-x-2/3"
  | "sm:-inset-x-2/3"
  | "xs:-inset-x-2/3"
  | "2xl:-inset-y-2/3"
  | "1xl:-inset-y-2/3"
  | "xl:-inset-y-2/3"
  | "lg:-inset-y-2/3"
  | "md:-inset-y-2/3"
  | "sm:-inset-y-2/3"
  | "xs:-inset-y-2/3"
  | "2xl:-top-2/3"
  | "1xl:-top-2/3"
  | "xl:-top-2/3"
  | "lg:-top-2/3"
  | "md:-top-2/3"
  | "sm:-top-2/3"
  | "xs:-top-2/3"
  | "2xl:-right-2/3"
  | "1xl:-right-2/3"
  | "xl:-right-2/3"
  | "lg:-right-2/3"
  | "md:-right-2/3"
  | "sm:-right-2/3"
  | "xs:-right-2/3"
  | "2xl:-bottom-2/3"
  | "1xl:-bottom-2/3"
  | "xl:-bottom-2/3"
  | "lg:-bottom-2/3"
  | "md:-bottom-2/3"
  | "sm:-bottom-2/3"
  | "xs:-bottom-2/3"
  | "2xl:-left-2/3"
  | "1xl:-left-2/3"
  | "xl:-left-2/3"
  | "lg:-left-2/3"
  | "md:-left-2/3"
  | "sm:-left-2/3"
  | "xs:-left-2/3"
  | "2xl:-inset-1/4"
  | "1xl:-inset-1/4"
  | "xl:-inset-1/4"
  | "lg:-inset-1/4"
  | "md:-inset-1/4"
  | "sm:-inset-1/4"
  | "xs:-inset-1/4"
  | "2xl:-inset-x-1/4"
  | "1xl:-inset-x-1/4"
  | "xl:-inset-x-1/4"
  | "lg:-inset-x-1/4"
  | "md:-inset-x-1/4"
  | "sm:-inset-x-1/4"
  | "xs:-inset-x-1/4"
  | "2xl:-inset-y-1/4"
  | "1xl:-inset-y-1/4"
  | "xl:-inset-y-1/4"
  | "lg:-inset-y-1/4"
  | "md:-inset-y-1/4"
  | "sm:-inset-y-1/4"
  | "xs:-inset-y-1/4"
  | "2xl:-top-1/4"
  | "1xl:-top-1/4"
  | "xl:-top-1/4"
  | "lg:-top-1/4"
  | "md:-top-1/4"
  | "sm:-top-1/4"
  | "xs:-top-1/4"
  | "2xl:-right-1/4"
  | "1xl:-right-1/4"
  | "xl:-right-1/4"
  | "lg:-right-1/4"
  | "md:-right-1/4"
  | "sm:-right-1/4"
  | "xs:-right-1/4"
  | "2xl:-bottom-1/4"
  | "1xl:-bottom-1/4"
  | "xl:-bottom-1/4"
  | "lg:-bottom-1/4"
  | "md:-bottom-1/4"
  | "sm:-bottom-1/4"
  | "xs:-bottom-1/4"
  | "2xl:-left-1/4"
  | "1xl:-left-1/4"
  | "xl:-left-1/4"
  | "lg:-left-1/4"
  | "md:-left-1/4"
  | "sm:-left-1/4"
  | "xs:-left-1/4"
  | "2xl:-inset-2/4"
  | "1xl:-inset-2/4"
  | "xl:-inset-2/4"
  | "lg:-inset-2/4"
  | "md:-inset-2/4"
  | "sm:-inset-2/4"
  | "xs:-inset-2/4"
  | "2xl:-inset-x-2/4"
  | "1xl:-inset-x-2/4"
  | "xl:-inset-x-2/4"
  | "lg:-inset-x-2/4"
  | "md:-inset-x-2/4"
  | "sm:-inset-x-2/4"
  | "xs:-inset-x-2/4"
  | "2xl:-inset-y-2/4"
  | "1xl:-inset-y-2/4"
  | "xl:-inset-y-2/4"
  | "lg:-inset-y-2/4"
  | "md:-inset-y-2/4"
  | "sm:-inset-y-2/4"
  | "xs:-inset-y-2/4"
  | "2xl:-top-2/4"
  | "1xl:-top-2/4"
  | "xl:-top-2/4"
  | "lg:-top-2/4"
  | "md:-top-2/4"
  | "sm:-top-2/4"
  | "xs:-top-2/4"
  | "2xl:-right-2/4"
  | "1xl:-right-2/4"
  | "xl:-right-2/4"
  | "lg:-right-2/4"
  | "md:-right-2/4"
  | "sm:-right-2/4"
  | "xs:-right-2/4"
  | "2xl:-bottom-2/4"
  | "1xl:-bottom-2/4"
  | "xl:-bottom-2/4"
  | "lg:-bottom-2/4"
  | "md:-bottom-2/4"
  | "sm:-bottom-2/4"
  | "xs:-bottom-2/4"
  | "2xl:-left-2/4"
  | "1xl:-left-2/4"
  | "xl:-left-2/4"
  | "lg:-left-2/4"
  | "md:-left-2/4"
  | "sm:-left-2/4"
  | "xs:-left-2/4"
  | "2xl:-inset-3/4"
  | "1xl:-inset-3/4"
  | "xl:-inset-3/4"
  | "lg:-inset-3/4"
  | "md:-inset-3/4"
  | "sm:-inset-3/4"
  | "xs:-inset-3/4"
  | "2xl:-inset-x-3/4"
  | "1xl:-inset-x-3/4"
  | "xl:-inset-x-3/4"
  | "lg:-inset-x-3/4"
  | "md:-inset-x-3/4"
  | "sm:-inset-x-3/4"
  | "xs:-inset-x-3/4"
  | "2xl:-inset-y-3/4"
  | "1xl:-inset-y-3/4"
  | "xl:-inset-y-3/4"
  | "lg:-inset-y-3/4"
  | "md:-inset-y-3/4"
  | "sm:-inset-y-3/4"
  | "xs:-inset-y-3/4"
  | "2xl:-top-3/4"
  | "1xl:-top-3/4"
  | "xl:-top-3/4"
  | "lg:-top-3/4"
  | "md:-top-3/4"
  | "sm:-top-3/4"
  | "xs:-top-3/4"
  | "2xl:-right-3/4"
  | "1xl:-right-3/4"
  | "xl:-right-3/4"
  | "lg:-right-3/4"
  | "md:-right-3/4"
  | "sm:-right-3/4"
  | "xs:-right-3/4"
  | "2xl:-bottom-3/4"
  | "1xl:-bottom-3/4"
  | "xl:-bottom-3/4"
  | "lg:-bottom-3/4"
  | "md:-bottom-3/4"
  | "sm:-bottom-3/4"
  | "xs:-bottom-3/4"
  | "2xl:-left-3/4"
  | "1xl:-left-3/4"
  | "xl:-left-3/4"
  | "lg:-left-3/4"
  | "md:-left-3/4"
  | "sm:-left-3/4"
  | "xs:-left-3/4"
  | "2xl:-inset-full"
  | "1xl:-inset-full"
  | "xl:-inset-full"
  | "lg:-inset-full"
  | "md:-inset-full"
  | "sm:-inset-full"
  | "xs:-inset-full"
  | "2xl:-inset-x-full"
  | "1xl:-inset-x-full"
  | "xl:-inset-x-full"
  | "lg:-inset-x-full"
  | "md:-inset-x-full"
  | "sm:-inset-x-full"
  | "xs:-inset-x-full"
  | "2xl:-inset-y-full"
  | "1xl:-inset-y-full"
  | "xl:-inset-y-full"
  | "lg:-inset-y-full"
  | "md:-inset-y-full"
  | "sm:-inset-y-full"
  | "xs:-inset-y-full"
  | "2xl:-top-full"
  | "1xl:-top-full"
  | "xl:-top-full"
  | "lg:-top-full"
  | "md:-top-full"
  | "sm:-top-full"
  | "xs:-top-full"
  | "2xl:-right-full"
  | "1xl:-right-full"
  | "xl:-right-full"
  | "lg:-right-full"
  | "md:-right-full"
  | "sm:-right-full"
  | "xs:-right-full"
  | "2xl:-bottom-full"
  | "1xl:-bottom-full"
  | "xl:-bottom-full"
  | "lg:-bottom-full"
  | "md:-bottom-full"
  | "sm:-bottom-full"
  | "xs:-bottom-full"
  | "2xl:-left-full"
  | "1xl:-left-full"
  | "xl:-left-full"
  | "lg:-left-full"
  | "md:-left-full"
  | "sm:-left-full"
  | "xs:-left-full"
  | "2xl:inset-top-full"
  | "1xl:inset-top-full"
  | "xl:inset-top-full"
  | "lg:inset-top-full"
  | "md:inset-top-full"
  | "sm:inset-top-full"
  | "xs:inset-top-full"
  | "2xl:inset-x-top-full"
  | "1xl:inset-x-top-full"
  | "xl:inset-x-top-full"
  | "lg:inset-x-top-full"
  | "md:inset-x-top-full"
  | "sm:inset-x-top-full"
  | "xs:inset-x-top-full"
  | "2xl:inset-y-top-full"
  | "1xl:inset-y-top-full"
  | "xl:inset-y-top-full"
  | "lg:inset-y-top-full"
  | "md:inset-y-top-full"
  | "sm:inset-y-top-full"
  | "xs:inset-y-top-full"
  | "2xl:top-top-full"
  | "1xl:top-top-full"
  | "xl:top-top-full"
  | "lg:top-top-full"
  | "md:top-top-full"
  | "sm:top-top-full"
  | "xs:top-top-full"
  | "2xl:right-top-full"
  | "1xl:right-top-full"
  | "xl:right-top-full"
  | "lg:right-top-full"
  | "md:right-top-full"
  | "sm:right-top-full"
  | "xs:right-top-full"
  | "2xl:bottom-top-full"
  | "1xl:bottom-top-full"
  | "xl:bottom-top-full"
  | "lg:bottom-top-full"
  | "md:bottom-top-full"
  | "sm:bottom-top-full"
  | "xs:bottom-top-full"
  | "2xl:left-top-full"
  | "1xl:left-top-full"
  | "xl:left-top-full"
  | "lg:left-top-full"
  | "md:left-top-full"
  | "sm:left-top-full"
  | "xs:left-top-full"
  | "2xl:inset-right-full"
  | "1xl:inset-right-full"
  | "xl:inset-right-full"
  | "lg:inset-right-full"
  | "md:inset-right-full"
  | "sm:inset-right-full"
  | "xs:inset-right-full"
  | "2xl:inset-x-right-full"
  | "1xl:inset-x-right-full"
  | "xl:inset-x-right-full"
  | "lg:inset-x-right-full"
  | "md:inset-x-right-full"
  | "sm:inset-x-right-full"
  | "xs:inset-x-right-full"
  | "2xl:inset-y-right-full"
  | "1xl:inset-y-right-full"
  | "xl:inset-y-right-full"
  | "lg:inset-y-right-full"
  | "md:inset-y-right-full"
  | "sm:inset-y-right-full"
  | "xs:inset-y-right-full"
  | "2xl:top-right-full"
  | "1xl:top-right-full"
  | "xl:top-right-full"
  | "lg:top-right-full"
  | "md:top-right-full"
  | "sm:top-right-full"
  | "xs:top-right-full"
  | "2xl:right-right-full"
  | "1xl:right-right-full"
  | "xl:right-right-full"
  | "lg:right-right-full"
  | "md:right-right-full"
  | "sm:right-right-full"
  | "xs:right-right-full"
  | "2xl:bottom-right-full"
  | "1xl:bottom-right-full"
  | "xl:bottom-right-full"
  | "lg:bottom-right-full"
  | "md:bottom-right-full"
  | "sm:bottom-right-full"
  | "xs:bottom-right-full"
  | "2xl:left-right-full"
  | "1xl:left-right-full"
  | "xl:left-right-full"
  | "lg:left-right-full"
  | "md:left-right-full"
  | "sm:left-right-full"
  | "xs:left-right-full"
  | "2xl:inset-bottom-full"
  | "1xl:inset-bottom-full"
  | "xl:inset-bottom-full"
  | "lg:inset-bottom-full"
  | "md:inset-bottom-full"
  | "sm:inset-bottom-full"
  | "xs:inset-bottom-full"
  | "2xl:inset-x-bottom-full"
  | "1xl:inset-x-bottom-full"
  | "xl:inset-x-bottom-full"
  | "lg:inset-x-bottom-full"
  | "md:inset-x-bottom-full"
  | "sm:inset-x-bottom-full"
  | "xs:inset-x-bottom-full"
  | "2xl:inset-y-bottom-full"
  | "1xl:inset-y-bottom-full"
  | "xl:inset-y-bottom-full"
  | "lg:inset-y-bottom-full"
  | "md:inset-y-bottom-full"
  | "sm:inset-y-bottom-full"
  | "xs:inset-y-bottom-full"
  | "2xl:top-bottom-full"
  | "1xl:top-bottom-full"
  | "xl:top-bottom-full"
  | "lg:top-bottom-full"
  | "md:top-bottom-full"
  | "sm:top-bottom-full"
  | "xs:top-bottom-full"
  | "2xl:right-bottom-full"
  | "1xl:right-bottom-full"
  | "xl:right-bottom-full"
  | "lg:right-bottom-full"
  | "md:right-bottom-full"
  | "sm:right-bottom-full"
  | "xs:right-bottom-full"
  | "2xl:bottom-bottom-full"
  | "1xl:bottom-bottom-full"
  | "xl:bottom-bottom-full"
  | "lg:bottom-bottom-full"
  | "md:bottom-bottom-full"
  | "sm:bottom-bottom-full"
  | "xs:bottom-bottom-full"
  | "2xl:left-bottom-full"
  | "1xl:left-bottom-full"
  | "xl:left-bottom-full"
  | "lg:left-bottom-full"
  | "md:left-bottom-full"
  | "sm:left-bottom-full"
  | "xs:left-bottom-full"
  | "2xl:inset-left-full"
  | "1xl:inset-left-full"
  | "xl:inset-left-full"
  | "lg:inset-left-full"
  | "md:inset-left-full"
  | "sm:inset-left-full"
  | "xs:inset-left-full"
  | "2xl:inset-x-left-full"
  | "1xl:inset-x-left-full"
  | "xl:inset-x-left-full"
  | "lg:inset-x-left-full"
  | "md:inset-x-left-full"
  | "sm:inset-x-left-full"
  | "xs:inset-x-left-full"
  | "2xl:inset-y-left-full"
  | "1xl:inset-y-left-full"
  | "xl:inset-y-left-full"
  | "lg:inset-y-left-full"
  | "md:inset-y-left-full"
  | "sm:inset-y-left-full"
  | "xs:inset-y-left-full"
  | "2xl:top-left-full"
  | "1xl:top-left-full"
  | "xl:top-left-full"
  | "lg:top-left-full"
  | "md:top-left-full"
  | "sm:top-left-full"
  | "xs:top-left-full"
  | "2xl:right-left-full"
  | "1xl:right-left-full"
  | "xl:right-left-full"
  | "lg:right-left-full"
  | "md:right-left-full"
  | "sm:right-left-full"
  | "xs:right-left-full"
  | "2xl:bottom-left-full"
  | "1xl:bottom-left-full"
  | "xl:bottom-left-full"
  | "lg:bottom-left-full"
  | "md:bottom-left-full"
  | "sm:bottom-left-full"
  | "xs:bottom-left-full"
  | "2xl:left-left-full"
  | "1xl:left-left-full"
  | "xl:left-left-full"
  | "lg:left-left-full"
  | "md:left-left-full"
  | "sm:left-left-full"
  | "xs:left-left-full"
  | "2xl:invert-0"
  | "1xl:invert-0"
  | "xl:invert-0"
  | "lg:invert-0"
  | "md:invert-0"
  | "sm:invert-0"
  | "xs:invert-0"
  | "2xl:invert"
  | "1xl:invert"
  | "xl:invert"
  | "lg:invert"
  | "md:invert"
  | "sm:invert"
  | "xs:invert"
  | "2xl:isolate"
  | "1xl:isolate"
  | "xl:isolate"
  | "lg:isolate"
  | "md:isolate"
  | "sm:isolate"
  | "xs:isolate"
  | "2xl:isolation-auto"
  | "1xl:isolation-auto"
  | "xl:isolation-auto"
  | "lg:isolation-auto"
  | "md:isolation-auto"
  | "sm:isolation-auto"
  | "xs:isolation-auto"
  | "2xl:justify-start"
  | "1xl:justify-start"
  | "xl:justify-start"
  | "lg:justify-start"
  | "md:justify-start"
  | "sm:justify-start"
  | "xs:justify-start"
  | "2xl:justify-center"
  | "1xl:justify-center"
  | "xl:justify-center"
  | "lg:justify-center"
  | "md:justify-center"
  | "sm:justify-center"
  | "xs:justify-center"
  | "2xl:justify-end"
  | "1xl:justify-end"
  | "xl:justify-end"
  | "lg:justify-end"
  | "md:justify-end"
  | "sm:justify-end"
  | "xs:justify-end"
  | "2xl:justify-between"
  | "1xl:justify-between"
  | "xl:justify-between"
  | "lg:justify-between"
  | "md:justify-between"
  | "sm:justify-between"
  | "xs:justify-between"
  | "2xl:justify-around"
  | "1xl:justify-around"
  | "xl:justify-around"
  | "lg:justify-around"
  | "md:justify-around"
  | "sm:justify-around"
  | "xs:justify-around"
  | "2xl:justify-items-auto"
  | "1xl:justify-items-auto"
  | "xl:justify-items-auto"
  | "lg:justify-items-auto"
  | "md:justify-items-auto"
  | "sm:justify-items-auto"
  | "xs:justify-items-auto"
  | "2xl:justify-items-start"
  | "1xl:justify-items-start"
  | "xl:justify-items-start"
  | "lg:justify-items-start"
  | "md:justify-items-start"
  | "sm:justify-items-start"
  | "xs:justify-items-start"
  | "2xl:justify-items-center"
  | "1xl:justify-items-center"
  | "xl:justify-items-center"
  | "lg:justify-items-center"
  | "md:justify-items-center"
  | "sm:justify-items-center"
  | "xs:justify-items-center"
  | "2xl:justify-items-end"
  | "1xl:justify-items-end"
  | "xl:justify-items-end"
  | "lg:justify-items-end"
  | "md:justify-items-end"
  | "sm:justify-items-end"
  | "xs:justify-items-end"
  | "2xl:justify-items-stretch"
  | "1xl:justify-items-stretch"
  | "xl:justify-items-stretch"
  | "lg:justify-items-stretch"
  | "md:justify-items-stretch"
  | "sm:justify-items-stretch"
  | "xs:justify-items-stretch"
  | "2xl:justify-self-auto"
  | "1xl:justify-self-auto"
  | "xl:justify-self-auto"
  | "lg:justify-self-auto"
  | "md:justify-self-auto"
  | "sm:justify-self-auto"
  | "xs:justify-self-auto"
  | "2xl:justify-self-start"
  | "1xl:justify-self-start"
  | "xl:justify-self-start"
  | "lg:justify-self-start"
  | "md:justify-self-start"
  | "sm:justify-self-start"
  | "xs:justify-self-start"
  | "2xl:justify-self-center"
  | "1xl:justify-self-center"
  | "xl:justify-self-center"
  | "lg:justify-self-center"
  | "md:justify-self-center"
  | "sm:justify-self-center"
  | "xs:justify-self-center"
  | "2xl:justify-self-end"
  | "1xl:justify-self-end"
  | "xl:justify-self-end"
  | "lg:justify-self-end"
  | "md:justify-self-end"
  | "sm:justify-self-end"
  | "xs:justify-self-end"
  | "2xl:justify-self-stretch"
  | "1xl:justify-self-stretch"
  | "xl:justify-self-stretch"
  | "lg:justify-self-stretch"
  | "md:justify-self-stretch"
  | "sm:justify-self-stretch"
  | "xs:justify-self-stretch"
  | "2xl:tracking-tighter"
  | "1xl:tracking-tighter"
  | "xl:tracking-tighter"
  | "lg:tracking-tighter"
  | "md:tracking-tighter"
  | "sm:tracking-tighter"
  | "xs:tracking-tighter"
  | "2xl:tracking-tight"
  | "1xl:tracking-tight"
  | "xl:tracking-tight"
  | "lg:tracking-tight"
  | "md:tracking-tight"
  | "sm:tracking-tight"
  | "xs:tracking-tight"
  | "2xl:tracking-normal"
  | "1xl:tracking-normal"
  | "xl:tracking-normal"
  | "lg:tracking-normal"
  | "md:tracking-normal"
  | "sm:tracking-normal"
  | "xs:tracking-normal"
  | "2xl:tracking-wide"
  | "1xl:tracking-wide"
  | "xl:tracking-wide"
  | "lg:tracking-wide"
  | "md:tracking-wide"
  | "sm:tracking-wide"
  | "xs:tracking-wide"
  | "2xl:tracking-wider"
  | "1xl:tracking-wider"
  | "xl:tracking-wider"
  | "lg:tracking-wider"
  | "md:tracking-wider"
  | "sm:tracking-wider"
  | "xs:tracking-wider"
  | "2xl:tracking-widest"
  | "1xl:tracking-widest"
  | "xl:tracking-widest"
  | "lg:tracking-widest"
  | "md:tracking-widest"
  | "sm:tracking-widest"
  | "xs:tracking-widest"
  | "2xl:leading-3"
  | "1xl:leading-3"
  | "xl:leading-3"
  | "lg:leading-3"
  | "md:leading-3"
  | "sm:leading-3"
  | "xs:leading-3"
  | "2xl:leading-4"
  | "1xl:leading-4"
  | "xl:leading-4"
  | "lg:leading-4"
  | "md:leading-4"
  | "sm:leading-4"
  | "xs:leading-4"
  | "2xl:leading-5"
  | "1xl:leading-5"
  | "xl:leading-5"
  | "lg:leading-5"
  | "md:leading-5"
  | "sm:leading-5"
  | "xs:leading-5"
  | "2xl:leading-6"
  | "1xl:leading-6"
  | "xl:leading-6"
  | "lg:leading-6"
  | "md:leading-6"
  | "sm:leading-6"
  | "xs:leading-6"
  | "2xl:leading-7"
  | "1xl:leading-7"
  | "xl:leading-7"
  | "lg:leading-7"
  | "md:leading-7"
  | "sm:leading-7"
  | "xs:leading-7"
  | "2xl:leading-8"
  | "1xl:leading-8"
  | "xl:leading-8"
  | "lg:leading-8"
  | "md:leading-8"
  | "sm:leading-8"
  | "xs:leading-8"
  | "2xl:leading-9"
  | "1xl:leading-9"
  | "xl:leading-9"
  | "lg:leading-9"
  | "md:leading-9"
  | "sm:leading-9"
  | "xs:leading-9"
  | "2xl:leading-10"
  | "1xl:leading-10"
  | "xl:leading-10"
  | "lg:leading-10"
  | "md:leading-10"
  | "sm:leading-10"
  | "xs:leading-10"
  | "2xl:leading-none"
  | "1xl:leading-none"
  | "xl:leading-none"
  | "lg:leading-none"
  | "md:leading-none"
  | "sm:leading-none"
  | "xs:leading-none"
  | "2xl:leading-tight"
  | "1xl:leading-tight"
  | "xl:leading-tight"
  | "lg:leading-tight"
  | "md:leading-tight"
  | "sm:leading-tight"
  | "xs:leading-tight"
  | "2xl:leading-snug"
  | "1xl:leading-snug"
  | "xl:leading-snug"
  | "lg:leading-snug"
  | "md:leading-snug"
  | "sm:leading-snug"
  | "xs:leading-snug"
  | "2xl:leading-normal"
  | "1xl:leading-normal"
  | "xl:leading-normal"
  | "lg:leading-normal"
  | "md:leading-normal"
  | "sm:leading-normal"
  | "xs:leading-normal"
  | "2xl:leading-relaxed"
  | "1xl:leading-relaxed"
  | "xl:leading-relaxed"
  | "lg:leading-relaxed"
  | "md:leading-relaxed"
  | "sm:leading-relaxed"
  | "xs:leading-relaxed"
  | "2xl:leading-loose"
  | "1xl:leading-loose"
  | "xl:leading-loose"
  | "lg:leading-loose"
  | "md:leading-loose"
  | "sm:leading-loose"
  | "xs:leading-loose"
  | "2xl:list-inside"
  | "1xl:list-inside"
  | "xl:list-inside"
  | "lg:list-inside"
  | "md:list-inside"
  | "sm:list-inside"
  | "xs:list-inside"
  | "2xl:list-outside"
  | "1xl:list-outside"
  | "xl:list-outside"
  | "lg:list-outside"
  | "md:list-outside"
  | "sm:list-outside"
  | "xs:list-outside"
  | "2xl:list-none"
  | "1xl:list-none"
  | "xl:list-none"
  | "lg:list-none"
  | "md:list-none"
  | "sm:list-none"
  | "xs:list-none"
  | "2xl:list-disc"
  | "1xl:list-disc"
  | "xl:list-disc"
  | "lg:list-disc"
  | "md:list-disc"
  | "sm:list-disc"
  | "xs:list-disc"
  | "2xl:list-decimal"
  | "1xl:list-decimal"
  | "xl:list-decimal"
  | "lg:list-decimal"
  | "md:list-decimal"
  | "sm:list-decimal"
  | "xs:list-decimal"
  | "2xl:m-0"
  | "1xl:m-0"
  | "xl:m-0"
  | "lg:m-0"
  | "md:m-0"
  | "sm:m-0"
  | "xs:m-0"
  | "2xl:m-1"
  | "1xl:m-1"
  | "xl:m-1"
  | "lg:m-1"
  | "md:m-1"
  | "sm:m-1"
  | "xs:m-1"
  | "2xl:m-2"
  | "1xl:m-2"
  | "xl:m-2"
  | "lg:m-2"
  | "md:m-2"
  | "sm:m-2"
  | "xs:m-2"
  | "2xl:m-3"
  | "1xl:m-3"
  | "xl:m-3"
  | "lg:m-3"
  | "md:m-3"
  | "sm:m-3"
  | "xs:m-3"
  | "2xl:m-4"
  | "1xl:m-4"
  | "xl:m-4"
  | "lg:m-4"
  | "md:m-4"
  | "sm:m-4"
  | "xs:m-4"
  | "2xl:m-5"
  | "1xl:m-5"
  | "xl:m-5"
  | "lg:m-5"
  | "md:m-5"
  | "sm:m-5"
  | "xs:m-5"
  | "2xl:m-6"
  | "1xl:m-6"
  | "xl:m-6"
  | "lg:m-6"
  | "md:m-6"
  | "sm:m-6"
  | "xs:m-6"
  | "2xl:m-7"
  | "1xl:m-7"
  | "xl:m-7"
  | "lg:m-7"
  | "md:m-7"
  | "sm:m-7"
  | "xs:m-7"
  | "2xl:m-8"
  | "1xl:m-8"
  | "xl:m-8"
  | "lg:m-8"
  | "md:m-8"
  | "sm:m-8"
  | "xs:m-8"
  | "2xl:m-9"
  | "1xl:m-9"
  | "xl:m-9"
  | "lg:m-9"
  | "md:m-9"
  | "sm:m-9"
  | "xs:m-9"
  | "2xl:m-10"
  | "1xl:m-10"
  | "xl:m-10"
  | "lg:m-10"
  | "md:m-10"
  | "sm:m-10"
  | "xs:m-10"
  | "2xl:m-11"
  | "1xl:m-11"
  | "xl:m-11"
  | "lg:m-11"
  | "md:m-11"
  | "sm:m-11"
  | "xs:m-11"
  | "2xl:m-12"
  | "1xl:m-12"
  | "xl:m-12"
  | "lg:m-12"
  | "md:m-12"
  | "sm:m-12"
  | "xs:m-12"
  | "2xl:m-14"
  | "1xl:m-14"
  | "xl:m-14"
  | "lg:m-14"
  | "md:m-14"
  | "sm:m-14"
  | "xs:m-14"
  | "2xl:m-16"
  | "1xl:m-16"
  | "xl:m-16"
  | "lg:m-16"
  | "md:m-16"
  | "sm:m-16"
  | "xs:m-16"
  | "2xl:m-20"
  | "1xl:m-20"
  | "xl:m-20"
  | "lg:m-20"
  | "md:m-20"
  | "sm:m-20"
  | "xs:m-20"
  | "2xl:m-24"
  | "1xl:m-24"
  | "xl:m-24"
  | "lg:m-24"
  | "md:m-24"
  | "sm:m-24"
  | "xs:m-24"
  | "2xl:m-28"
  | "1xl:m-28"
  | "xl:m-28"
  | "lg:m-28"
  | "md:m-28"
  | "sm:m-28"
  | "xs:m-28"
  | "2xl:m-32"
  | "1xl:m-32"
  | "xl:m-32"
  | "lg:m-32"
  | "md:m-32"
  | "sm:m-32"
  | "xs:m-32"
  | "2xl:m-36"
  | "1xl:m-36"
  | "xl:m-36"
  | "lg:m-36"
  | "md:m-36"
  | "sm:m-36"
  | "xs:m-36"
  | "2xl:m-40"
  | "1xl:m-40"
  | "xl:m-40"
  | "lg:m-40"
  | "md:m-40"
  | "sm:m-40"
  | "xs:m-40"
  | "2xl:m-44"
  | "1xl:m-44"
  | "xl:m-44"
  | "lg:m-44"
  | "md:m-44"
  | "sm:m-44"
  | "xs:m-44"
  | "2xl:m-48"
  | "1xl:m-48"
  | "xl:m-48"
  | "lg:m-48"
  | "md:m-48"
  | "sm:m-48"
  | "xs:m-48"
  | "2xl:m-52"
  | "1xl:m-52"
  | "xl:m-52"
  | "lg:m-52"
  | "md:m-52"
  | "sm:m-52"
  | "xs:m-52"
  | "2xl:m-56"
  | "1xl:m-56"
  | "xl:m-56"
  | "lg:m-56"
  | "md:m-56"
  | "sm:m-56"
  | "xs:m-56"
  | "2xl:m-60"
  | "1xl:m-60"
  | "xl:m-60"
  | "lg:m-60"
  | "md:m-60"
  | "sm:m-60"
  | "xs:m-60"
  | "2xl:m-64"
  | "1xl:m-64"
  | "xl:m-64"
  | "lg:m-64"
  | "md:m-64"
  | "sm:m-64"
  | "xs:m-64"
  | "2xl:m-72"
  | "1xl:m-72"
  | "xl:m-72"
  | "lg:m-72"
  | "md:m-72"
  | "sm:m-72"
  | "xs:m-72"
  | "2xl:m-80"
  | "1xl:m-80"
  | "xl:m-80"
  | "lg:m-80"
  | "md:m-80"
  | "sm:m-80"
  | "xs:m-80"
  | "2xl:m-96"
  | "1xl:m-96"
  | "xl:m-96"
  | "lg:m-96"
  | "md:m-96"
  | "sm:m-96"
  | "xs:m-96"
  | "2xl:m-auto"
  | "1xl:m-auto"
  | "xl:m-auto"
  | "lg:m-auto"
  | "md:m-auto"
  | "sm:m-auto"
  | "xs:m-auto"
  | "2xl:m-px"
  | "1xl:m-px"
  | "xl:m-px"
  | "lg:m-px"
  | "md:m-px"
  | "sm:m-px"
  | "xs:m-px"
  | "2xl:m-0.5"
  | "1xl:m-0.5"
  | "xl:m-0.5"
  | "lg:m-0.5"
  | "md:m-0.5"
  | "sm:m-0.5"
  | "xs:m-0.5"
  | "2xl:m-1.5"
  | "1xl:m-1.5"
  | "xl:m-1.5"
  | "lg:m-1.5"
  | "md:m-1.5"
  | "sm:m-1.5"
  | "xs:m-1.5"
  | "2xl:m-2.5"
  | "1xl:m-2.5"
  | "xl:m-2.5"
  | "lg:m-2.5"
  | "md:m-2.5"
  | "sm:m-2.5"
  | "xs:m-2.5"
  | "2xl:m-3.5"
  | "1xl:m-3.5"
  | "xl:m-3.5"
  | "lg:m-3.5"
  | "md:m-3.5"
  | "sm:m-3.5"
  | "xs:m-3.5"
  | "2xl:-m-0"
  | "1xl:-m-0"
  | "xl:-m-0"
  | "lg:-m-0"
  | "md:-m-0"
  | "sm:-m-0"
  | "xs:-m-0"
  | "2xl:-m-1"
  | "1xl:-m-1"
  | "xl:-m-1"
  | "lg:-m-1"
  | "md:-m-1"
  | "sm:-m-1"
  | "xs:-m-1"
  | "2xl:-m-2"
  | "1xl:-m-2"
  | "xl:-m-2"
  | "lg:-m-2"
  | "md:-m-2"
  | "sm:-m-2"
  | "xs:-m-2"
  | "2xl:-m-3"
  | "1xl:-m-3"
  | "xl:-m-3"
  | "lg:-m-3"
  | "md:-m-3"
  | "sm:-m-3"
  | "xs:-m-3"
  | "2xl:-m-4"
  | "1xl:-m-4"
  | "xl:-m-4"
  | "lg:-m-4"
  | "md:-m-4"
  | "sm:-m-4"
  | "xs:-m-4"
  | "2xl:-m-5"
  | "1xl:-m-5"
  | "xl:-m-5"
  | "lg:-m-5"
  | "md:-m-5"
  | "sm:-m-5"
  | "xs:-m-5"
  | "2xl:-m-6"
  | "1xl:-m-6"
  | "xl:-m-6"
  | "lg:-m-6"
  | "md:-m-6"
  | "sm:-m-6"
  | "xs:-m-6"
  | "2xl:-m-7"
  | "1xl:-m-7"
  | "xl:-m-7"
  | "lg:-m-7"
  | "md:-m-7"
  | "sm:-m-7"
  | "xs:-m-7"
  | "2xl:-m-8"
  | "1xl:-m-8"
  | "xl:-m-8"
  | "lg:-m-8"
  | "md:-m-8"
  | "sm:-m-8"
  | "xs:-m-8"
  | "2xl:-m-9"
  | "1xl:-m-9"
  | "xl:-m-9"
  | "lg:-m-9"
  | "md:-m-9"
  | "sm:-m-9"
  | "xs:-m-9"
  | "2xl:-m-10"
  | "1xl:-m-10"
  | "xl:-m-10"
  | "lg:-m-10"
  | "md:-m-10"
  | "sm:-m-10"
  | "xs:-m-10"
  | "2xl:-m-11"
  | "1xl:-m-11"
  | "xl:-m-11"
  | "lg:-m-11"
  | "md:-m-11"
  | "sm:-m-11"
  | "xs:-m-11"
  | "2xl:-m-12"
  | "1xl:-m-12"
  | "xl:-m-12"
  | "lg:-m-12"
  | "md:-m-12"
  | "sm:-m-12"
  | "xs:-m-12"
  | "2xl:-m-14"
  | "1xl:-m-14"
  | "xl:-m-14"
  | "lg:-m-14"
  | "md:-m-14"
  | "sm:-m-14"
  | "xs:-m-14"
  | "2xl:-m-16"
  | "1xl:-m-16"
  | "xl:-m-16"
  | "lg:-m-16"
  | "md:-m-16"
  | "sm:-m-16"
  | "xs:-m-16"
  | "2xl:-m-20"
  | "1xl:-m-20"
  | "xl:-m-20"
  | "lg:-m-20"
  | "md:-m-20"
  | "sm:-m-20"
  | "xs:-m-20"
  | "2xl:-m-24"
  | "1xl:-m-24"
  | "xl:-m-24"
  | "lg:-m-24"
  | "md:-m-24"
  | "sm:-m-24"
  | "xs:-m-24"
  | "2xl:-m-28"
  | "1xl:-m-28"
  | "xl:-m-28"
  | "lg:-m-28"
  | "md:-m-28"
  | "sm:-m-28"
  | "xs:-m-28"
  | "2xl:-m-32"
  | "1xl:-m-32"
  | "xl:-m-32"
  | "lg:-m-32"
  | "md:-m-32"
  | "sm:-m-32"
  | "xs:-m-32"
  | "2xl:-m-36"
  | "1xl:-m-36"
  | "xl:-m-36"
  | "lg:-m-36"
  | "md:-m-36"
  | "sm:-m-36"
  | "xs:-m-36"
  | "2xl:-m-40"
  | "1xl:-m-40"
  | "xl:-m-40"
  | "lg:-m-40"
  | "md:-m-40"
  | "sm:-m-40"
  | "xs:-m-40"
  | "2xl:-m-44"
  | "1xl:-m-44"
  | "xl:-m-44"
  | "lg:-m-44"
  | "md:-m-44"
  | "sm:-m-44"
  | "xs:-m-44"
  | "2xl:-m-48"
  | "1xl:-m-48"
  | "xl:-m-48"
  | "lg:-m-48"
  | "md:-m-48"
  | "sm:-m-48"
  | "xs:-m-48"
  | "2xl:-m-52"
  | "1xl:-m-52"
  | "xl:-m-52"
  | "lg:-m-52"
  | "md:-m-52"
  | "sm:-m-52"
  | "xs:-m-52"
  | "2xl:-m-56"
  | "1xl:-m-56"
  | "xl:-m-56"
  | "lg:-m-56"
  | "md:-m-56"
  | "sm:-m-56"
  | "xs:-m-56"
  | "2xl:-m-60"
  | "1xl:-m-60"
  | "xl:-m-60"
  | "lg:-m-60"
  | "md:-m-60"
  | "sm:-m-60"
  | "xs:-m-60"
  | "2xl:-m-64"
  | "1xl:-m-64"
  | "xl:-m-64"
  | "lg:-m-64"
  | "md:-m-64"
  | "sm:-m-64"
  | "xs:-m-64"
  | "2xl:-m-72"
  | "1xl:-m-72"
  | "xl:-m-72"
  | "lg:-m-72"
  | "md:-m-72"
  | "sm:-m-72"
  | "xs:-m-72"
  | "2xl:-m-80"
  | "1xl:-m-80"
  | "xl:-m-80"
  | "lg:-m-80"
  | "md:-m-80"
  | "sm:-m-80"
  | "xs:-m-80"
  | "2xl:-m-96"
  | "1xl:-m-96"
  | "xl:-m-96"
  | "lg:-m-96"
  | "md:-m-96"
  | "sm:-m-96"
  | "xs:-m-96"
  | "2xl:-m-px"
  | "1xl:-m-px"
  | "xl:-m-px"
  | "lg:-m-px"
  | "md:-m-px"
  | "sm:-m-px"
  | "xs:-m-px"
  | "2xl:-m-0.5"
  | "1xl:-m-0.5"
  | "xl:-m-0.5"
  | "lg:-m-0.5"
  | "md:-m-0.5"
  | "sm:-m-0.5"
  | "xs:-m-0.5"
  | "2xl:-m-1.5"
  | "1xl:-m-1.5"
  | "xl:-m-1.5"
  | "lg:-m-1.5"
  | "md:-m-1.5"
  | "sm:-m-1.5"
  | "xs:-m-1.5"
  | "2xl:-m-2.5"
  | "1xl:-m-2.5"
  | "xl:-m-2.5"
  | "lg:-m-2.5"
  | "md:-m-2.5"
  | "sm:-m-2.5"
  | "xs:-m-2.5"
  | "2xl:-m-3.5"
  | "1xl:-m-3.5"
  | "xl:-m-3.5"
  | "lg:-m-3.5"
  | "md:-m-3.5"
  | "sm:-m-3.5"
  | "xs:-m-3.5"
  | "2xl:my-0"
  | "1xl:my-0"
  | "xl:my-0"
  | "lg:my-0"
  | "md:my-0"
  | "sm:my-0"
  | "xs:my-0"
  | "2xl:my-1"
  | "1xl:my-1"
  | "xl:my-1"
  | "lg:my-1"
  | "md:my-1"
  | "sm:my-1"
  | "xs:my-1"
  | "2xl:my-2"
  | "1xl:my-2"
  | "xl:my-2"
  | "lg:my-2"
  | "md:my-2"
  | "sm:my-2"
  | "xs:my-2"
  | "2xl:my-3"
  | "1xl:my-3"
  | "xl:my-3"
  | "lg:my-3"
  | "md:my-3"
  | "sm:my-3"
  | "xs:my-3"
  | "2xl:my-4"
  | "1xl:my-4"
  | "xl:my-4"
  | "lg:my-4"
  | "md:my-4"
  | "sm:my-4"
  | "xs:my-4"
  | "2xl:my-5"
  | "1xl:my-5"
  | "xl:my-5"
  | "lg:my-5"
  | "md:my-5"
  | "sm:my-5"
  | "xs:my-5"
  | "2xl:my-6"
  | "1xl:my-6"
  | "xl:my-6"
  | "lg:my-6"
  | "md:my-6"
  | "sm:my-6"
  | "xs:my-6"
  | "2xl:my-7"
  | "1xl:my-7"
  | "xl:my-7"
  | "lg:my-7"
  | "md:my-7"
  | "sm:my-7"
  | "xs:my-7"
  | "2xl:my-8"
  | "1xl:my-8"
  | "xl:my-8"
  | "lg:my-8"
  | "md:my-8"
  | "sm:my-8"
  | "xs:my-8"
  | "2xl:my-9"
  | "1xl:my-9"
  | "xl:my-9"
  | "lg:my-9"
  | "md:my-9"
  | "sm:my-9"
  | "xs:my-9"
  | "2xl:my-10"
  | "1xl:my-10"
  | "xl:my-10"
  | "lg:my-10"
  | "md:my-10"
  | "sm:my-10"
  | "xs:my-10"
  | "2xl:my-11"
  | "1xl:my-11"
  | "xl:my-11"
  | "lg:my-11"
  | "md:my-11"
  | "sm:my-11"
  | "xs:my-11"
  | "2xl:my-12"
  | "1xl:my-12"
  | "xl:my-12"
  | "lg:my-12"
  | "md:my-12"
  | "sm:my-12"
  | "xs:my-12"
  | "2xl:my-14"
  | "1xl:my-14"
  | "xl:my-14"
  | "lg:my-14"
  | "md:my-14"
  | "sm:my-14"
  | "xs:my-14"
  | "2xl:my-16"
  | "1xl:my-16"
  | "xl:my-16"
  | "lg:my-16"
  | "md:my-16"
  | "sm:my-16"
  | "xs:my-16"
  | "2xl:my-20"
  | "1xl:my-20"
  | "xl:my-20"
  | "lg:my-20"
  | "md:my-20"
  | "sm:my-20"
  | "xs:my-20"
  | "2xl:my-24"
  | "1xl:my-24"
  | "xl:my-24"
  | "lg:my-24"
  | "md:my-24"
  | "sm:my-24"
  | "xs:my-24"
  | "2xl:my-28"
  | "1xl:my-28"
  | "xl:my-28"
  | "lg:my-28"
  | "md:my-28"
  | "sm:my-28"
  | "xs:my-28"
  | "2xl:my-32"
  | "1xl:my-32"
  | "xl:my-32"
  | "lg:my-32"
  | "md:my-32"
  | "sm:my-32"
  | "xs:my-32"
  | "2xl:my-36"
  | "1xl:my-36"
  | "xl:my-36"
  | "lg:my-36"
  | "md:my-36"
  | "sm:my-36"
  | "xs:my-36"
  | "2xl:my-40"
  | "1xl:my-40"
  | "xl:my-40"
  | "lg:my-40"
  | "md:my-40"
  | "sm:my-40"
  | "xs:my-40"
  | "2xl:my-44"
  | "1xl:my-44"
  | "xl:my-44"
  | "lg:my-44"
  | "md:my-44"
  | "sm:my-44"
  | "xs:my-44"
  | "2xl:my-48"
  | "1xl:my-48"
  | "xl:my-48"
  | "lg:my-48"
  | "md:my-48"
  | "sm:my-48"
  | "xs:my-48"
  | "2xl:my-52"
  | "1xl:my-52"
  | "xl:my-52"
  | "lg:my-52"
  | "md:my-52"
  | "sm:my-52"
  | "xs:my-52"
  | "2xl:my-56"
  | "1xl:my-56"
  | "xl:my-56"
  | "lg:my-56"
  | "md:my-56"
  | "sm:my-56"
  | "xs:my-56"
  | "2xl:my-60"
  | "1xl:my-60"
  | "xl:my-60"
  | "lg:my-60"
  | "md:my-60"
  | "sm:my-60"
  | "xs:my-60"
  | "2xl:my-64"
  | "1xl:my-64"
  | "xl:my-64"
  | "lg:my-64"
  | "md:my-64"
  | "sm:my-64"
  | "xs:my-64"
  | "2xl:my-72"
  | "1xl:my-72"
  | "xl:my-72"
  | "lg:my-72"
  | "md:my-72"
  | "sm:my-72"
  | "xs:my-72"
  | "2xl:my-80"
  | "1xl:my-80"
  | "xl:my-80"
  | "lg:my-80"
  | "md:my-80"
  | "sm:my-80"
  | "xs:my-80"
  | "2xl:my-96"
  | "1xl:my-96"
  | "xl:my-96"
  | "lg:my-96"
  | "md:my-96"
  | "sm:my-96"
  | "xs:my-96"
  | "2xl:my-auto"
  | "1xl:my-auto"
  | "xl:my-auto"
  | "lg:my-auto"
  | "md:my-auto"
  | "sm:my-auto"
  | "xs:my-auto"
  | "2xl:my-px"
  | "1xl:my-px"
  | "xl:my-px"
  | "lg:my-px"
  | "md:my-px"
  | "sm:my-px"
  | "xs:my-px"
  | "2xl:my-0.5"
  | "1xl:my-0.5"
  | "xl:my-0.5"
  | "lg:my-0.5"
  | "md:my-0.5"
  | "sm:my-0.5"
  | "xs:my-0.5"
  | "2xl:my-1.5"
  | "1xl:my-1.5"
  | "xl:my-1.5"
  | "lg:my-1.5"
  | "md:my-1.5"
  | "sm:my-1.5"
  | "xs:my-1.5"
  | "2xl:my-2.5"
  | "1xl:my-2.5"
  | "xl:my-2.5"
  | "lg:my-2.5"
  | "md:my-2.5"
  | "sm:my-2.5"
  | "xs:my-2.5"
  | "2xl:my-3.5"
  | "1xl:my-3.5"
  | "xl:my-3.5"
  | "lg:my-3.5"
  | "md:my-3.5"
  | "sm:my-3.5"
  | "xs:my-3.5"
  | "2xl:-my-0"
  | "1xl:-my-0"
  | "xl:-my-0"
  | "lg:-my-0"
  | "md:-my-0"
  | "sm:-my-0"
  | "xs:-my-0"
  | "2xl:-my-1"
  | "1xl:-my-1"
  | "xl:-my-1"
  | "lg:-my-1"
  | "md:-my-1"
  | "sm:-my-1"
  | "xs:-my-1"
  | "2xl:-my-2"
  | "1xl:-my-2"
  | "xl:-my-2"
  | "lg:-my-2"
  | "md:-my-2"
  | "sm:-my-2"
  | "xs:-my-2"
  | "2xl:-my-3"
  | "1xl:-my-3"
  | "xl:-my-3"
  | "lg:-my-3"
  | "md:-my-3"
  | "sm:-my-3"
  | "xs:-my-3"
  | "2xl:-my-4"
  | "1xl:-my-4"
  | "xl:-my-4"
  | "lg:-my-4"
  | "md:-my-4"
  | "sm:-my-4"
  | "xs:-my-4"
  | "2xl:-my-5"
  | "1xl:-my-5"
  | "xl:-my-5"
  | "lg:-my-5"
  | "md:-my-5"
  | "sm:-my-5"
  | "xs:-my-5"
  | "2xl:-my-6"
  | "1xl:-my-6"
  | "xl:-my-6"
  | "lg:-my-6"
  | "md:-my-6"
  | "sm:-my-6"
  | "xs:-my-6"
  | "2xl:-my-7"
  | "1xl:-my-7"
  | "xl:-my-7"
  | "lg:-my-7"
  | "md:-my-7"
  | "sm:-my-7"
  | "xs:-my-7"
  | "2xl:-my-8"
  | "1xl:-my-8"
  | "xl:-my-8"
  | "lg:-my-8"
  | "md:-my-8"
  | "sm:-my-8"
  | "xs:-my-8"
  | "2xl:-my-9"
  | "1xl:-my-9"
  | "xl:-my-9"
  | "lg:-my-9"
  | "md:-my-9"
  | "sm:-my-9"
  | "xs:-my-9"
  | "2xl:-my-10"
  | "1xl:-my-10"
  | "xl:-my-10"
  | "lg:-my-10"
  | "md:-my-10"
  | "sm:-my-10"
  | "xs:-my-10"
  | "2xl:-my-11"
  | "1xl:-my-11"
  | "xl:-my-11"
  | "lg:-my-11"
  | "md:-my-11"
  | "sm:-my-11"
  | "xs:-my-11"
  | "2xl:-my-12"
  | "1xl:-my-12"
  | "xl:-my-12"
  | "lg:-my-12"
  | "md:-my-12"
  | "sm:-my-12"
  | "xs:-my-12"
  | "2xl:-my-14"
  | "1xl:-my-14"
  | "xl:-my-14"
  | "lg:-my-14"
  | "md:-my-14"
  | "sm:-my-14"
  | "xs:-my-14"
  | "2xl:-my-16"
  | "1xl:-my-16"
  | "xl:-my-16"
  | "lg:-my-16"
  | "md:-my-16"
  | "sm:-my-16"
  | "xs:-my-16"
  | "2xl:-my-20"
  | "1xl:-my-20"
  | "xl:-my-20"
  | "lg:-my-20"
  | "md:-my-20"
  | "sm:-my-20"
  | "xs:-my-20"
  | "2xl:-my-24"
  | "1xl:-my-24"
  | "xl:-my-24"
  | "lg:-my-24"
  | "md:-my-24"
  | "sm:-my-24"
  | "xs:-my-24"
  | "2xl:-my-28"
  | "1xl:-my-28"
  | "xl:-my-28"
  | "lg:-my-28"
  | "md:-my-28"
  | "sm:-my-28"
  | "xs:-my-28"
  | "2xl:-my-32"
  | "1xl:-my-32"
  | "xl:-my-32"
  | "lg:-my-32"
  | "md:-my-32"
  | "sm:-my-32"
  | "xs:-my-32"
  | "2xl:-my-36"
  | "1xl:-my-36"
  | "xl:-my-36"
  | "lg:-my-36"
  | "md:-my-36"
  | "sm:-my-36"
  | "xs:-my-36"
  | "2xl:-my-40"
  | "1xl:-my-40"
  | "xl:-my-40"
  | "lg:-my-40"
  | "md:-my-40"
  | "sm:-my-40"
  | "xs:-my-40"
  | "2xl:-my-44"
  | "1xl:-my-44"
  | "xl:-my-44"
  | "lg:-my-44"
  | "md:-my-44"
  | "sm:-my-44"
  | "xs:-my-44"
  | "2xl:-my-48"
  | "1xl:-my-48"
  | "xl:-my-48"
  | "lg:-my-48"
  | "md:-my-48"
  | "sm:-my-48"
  | "xs:-my-48"
  | "2xl:-my-52"
  | "1xl:-my-52"
  | "xl:-my-52"
  | "lg:-my-52"
  | "md:-my-52"
  | "sm:-my-52"
  | "xs:-my-52"
  | "2xl:-my-56"
  | "1xl:-my-56"
  | "xl:-my-56"
  | "lg:-my-56"
  | "md:-my-56"
  | "sm:-my-56"
  | "xs:-my-56"
  | "2xl:-my-60"
  | "1xl:-my-60"
  | "xl:-my-60"
  | "lg:-my-60"
  | "md:-my-60"
  | "sm:-my-60"
  | "xs:-my-60"
  | "2xl:-my-64"
  | "1xl:-my-64"
  | "xl:-my-64"
  | "lg:-my-64"
  | "md:-my-64"
  | "sm:-my-64"
  | "xs:-my-64"
  | "2xl:-my-72"
  | "1xl:-my-72"
  | "xl:-my-72"
  | "lg:-my-72"
  | "md:-my-72"
  | "sm:-my-72"
  | "xs:-my-72"
  | "2xl:-my-80"
  | "1xl:-my-80"
  | "xl:-my-80"
  | "lg:-my-80"
  | "md:-my-80"
  | "sm:-my-80"
  | "xs:-my-80"
  | "2xl:-my-96"
  | "1xl:-my-96"
  | "xl:-my-96"
  | "lg:-my-96"
  | "md:-my-96"
  | "sm:-my-96"
  | "xs:-my-96"
  | "2xl:-my-px"
  | "1xl:-my-px"
  | "xl:-my-px"
  | "lg:-my-px"
  | "md:-my-px"
  | "sm:-my-px"
  | "xs:-my-px"
  | "2xl:-my-0.5"
  | "1xl:-my-0.5"
  | "xl:-my-0.5"
  | "lg:-my-0.5"
  | "md:-my-0.5"
  | "sm:-my-0.5"
  | "xs:-my-0.5"
  | "2xl:-my-1.5"
  | "1xl:-my-1.5"
  | "xl:-my-1.5"
  | "lg:-my-1.5"
  | "md:-my-1.5"
  | "sm:-my-1.5"
  | "xs:-my-1.5"
  | "2xl:-my-2.5"
  | "1xl:-my-2.5"
  | "xl:-my-2.5"
  | "lg:-my-2.5"
  | "md:-my-2.5"
  | "sm:-my-2.5"
  | "xs:-my-2.5"
  | "2xl:-my-3.5"
  | "1xl:-my-3.5"
  | "xl:-my-3.5"
  | "lg:-my-3.5"
  | "md:-my-3.5"
  | "sm:-my-3.5"
  | "xs:-my-3.5"
  | "2xl:mx-0"
  | "1xl:mx-0"
  | "xl:mx-0"
  | "lg:mx-0"
  | "md:mx-0"
  | "sm:mx-0"
  | "xs:mx-0"
  | "2xl:mx-1"
  | "1xl:mx-1"
  | "xl:mx-1"
  | "lg:mx-1"
  | "md:mx-1"
  | "sm:mx-1"
  | "xs:mx-1"
  | "2xl:mx-2"
  | "1xl:mx-2"
  | "xl:mx-2"
  | "lg:mx-2"
  | "md:mx-2"
  | "sm:mx-2"
  | "xs:mx-2"
  | "2xl:mx-3"
  | "1xl:mx-3"
  | "xl:mx-3"
  | "lg:mx-3"
  | "md:mx-3"
  | "sm:mx-3"
  | "xs:mx-3"
  | "2xl:mx-4"
  | "1xl:mx-4"
  | "xl:mx-4"
  | "lg:mx-4"
  | "md:mx-4"
  | "sm:mx-4"
  | "xs:mx-4"
  | "2xl:mx-5"
  | "1xl:mx-5"
  | "xl:mx-5"
  | "lg:mx-5"
  | "md:mx-5"
  | "sm:mx-5"
  | "xs:mx-5"
  | "2xl:mx-6"
  | "1xl:mx-6"
  | "xl:mx-6"
  | "lg:mx-6"
  | "md:mx-6"
  | "sm:mx-6"
  | "xs:mx-6"
  | "2xl:mx-7"
  | "1xl:mx-7"
  | "xl:mx-7"
  | "lg:mx-7"
  | "md:mx-7"
  | "sm:mx-7"
  | "xs:mx-7"
  | "2xl:mx-8"
  | "1xl:mx-8"
  | "xl:mx-8"
  | "lg:mx-8"
  | "md:mx-8"
  | "sm:mx-8"
  | "xs:mx-8"
  | "2xl:mx-9"
  | "1xl:mx-9"
  | "xl:mx-9"
  | "lg:mx-9"
  | "md:mx-9"
  | "sm:mx-9"
  | "xs:mx-9"
  | "2xl:mx-10"
  | "1xl:mx-10"
  | "xl:mx-10"
  | "lg:mx-10"
  | "md:mx-10"
  | "sm:mx-10"
  | "xs:mx-10"
  | "2xl:mx-11"
  | "1xl:mx-11"
  | "xl:mx-11"
  | "lg:mx-11"
  | "md:mx-11"
  | "sm:mx-11"
  | "xs:mx-11"
  | "2xl:mx-12"
  | "1xl:mx-12"
  | "xl:mx-12"
  | "lg:mx-12"
  | "md:mx-12"
  | "sm:mx-12"
  | "xs:mx-12"
  | "2xl:mx-14"
  | "1xl:mx-14"
  | "xl:mx-14"
  | "lg:mx-14"
  | "md:mx-14"
  | "sm:mx-14"
  | "xs:mx-14"
  | "2xl:mx-16"
  | "1xl:mx-16"
  | "xl:mx-16"
  | "lg:mx-16"
  | "md:mx-16"
  | "sm:mx-16"
  | "xs:mx-16"
  | "2xl:mx-20"
  | "1xl:mx-20"
  | "xl:mx-20"
  | "lg:mx-20"
  | "md:mx-20"
  | "sm:mx-20"
  | "xs:mx-20"
  | "2xl:mx-24"
  | "1xl:mx-24"
  | "xl:mx-24"
  | "lg:mx-24"
  | "md:mx-24"
  | "sm:mx-24"
  | "xs:mx-24"
  | "2xl:mx-28"
  | "1xl:mx-28"
  | "xl:mx-28"
  | "lg:mx-28"
  | "md:mx-28"
  | "sm:mx-28"
  | "xs:mx-28"
  | "2xl:mx-32"
  | "1xl:mx-32"
  | "xl:mx-32"
  | "lg:mx-32"
  | "md:mx-32"
  | "sm:mx-32"
  | "xs:mx-32"
  | "2xl:mx-36"
  | "1xl:mx-36"
  | "xl:mx-36"
  | "lg:mx-36"
  | "md:mx-36"
  | "sm:mx-36"
  | "xs:mx-36"
  | "2xl:mx-40"
  | "1xl:mx-40"
  | "xl:mx-40"
  | "lg:mx-40"
  | "md:mx-40"
  | "sm:mx-40"
  | "xs:mx-40"
  | "2xl:mx-44"
  | "1xl:mx-44"
  | "xl:mx-44"
  | "lg:mx-44"
  | "md:mx-44"
  | "sm:mx-44"
  | "xs:mx-44"
  | "2xl:mx-48"
  | "1xl:mx-48"
  | "xl:mx-48"
  | "lg:mx-48"
  | "md:mx-48"
  | "sm:mx-48"
  | "xs:mx-48"
  | "2xl:mx-52"
  | "1xl:mx-52"
  | "xl:mx-52"
  | "lg:mx-52"
  | "md:mx-52"
  | "sm:mx-52"
  | "xs:mx-52"
  | "2xl:mx-56"
  | "1xl:mx-56"
  | "xl:mx-56"
  | "lg:mx-56"
  | "md:mx-56"
  | "sm:mx-56"
  | "xs:mx-56"
  | "2xl:mx-60"
  | "1xl:mx-60"
  | "xl:mx-60"
  | "lg:mx-60"
  | "md:mx-60"
  | "sm:mx-60"
  | "xs:mx-60"
  | "2xl:mx-64"
  | "1xl:mx-64"
  | "xl:mx-64"
  | "lg:mx-64"
  | "md:mx-64"
  | "sm:mx-64"
  | "xs:mx-64"
  | "2xl:mx-72"
  | "1xl:mx-72"
  | "xl:mx-72"
  | "lg:mx-72"
  | "md:mx-72"
  | "sm:mx-72"
  | "xs:mx-72"
  | "2xl:mx-80"
  | "1xl:mx-80"
  | "xl:mx-80"
  | "lg:mx-80"
  | "md:mx-80"
  | "sm:mx-80"
  | "xs:mx-80"
  | "2xl:mx-96"
  | "1xl:mx-96"
  | "xl:mx-96"
  | "lg:mx-96"
  | "md:mx-96"
  | "sm:mx-96"
  | "xs:mx-96"
  | "2xl:mx-auto"
  | "1xl:mx-auto"
  | "xl:mx-auto"
  | "lg:mx-auto"
  | "md:mx-auto"
  | "sm:mx-auto"
  | "xs:mx-auto"
  | "2xl:mx-px"
  | "1xl:mx-px"
  | "xl:mx-px"
  | "lg:mx-px"
  | "md:mx-px"
  | "sm:mx-px"
  | "xs:mx-px"
  | "2xl:mx-0.5"
  | "1xl:mx-0.5"
  | "xl:mx-0.5"
  | "lg:mx-0.5"
  | "md:mx-0.5"
  | "sm:mx-0.5"
  | "xs:mx-0.5"
  | "2xl:mx-1.5"
  | "1xl:mx-1.5"
  | "xl:mx-1.5"
  | "lg:mx-1.5"
  | "md:mx-1.5"
  | "sm:mx-1.5"
  | "xs:mx-1.5"
  | "2xl:mx-2.5"
  | "1xl:mx-2.5"
  | "xl:mx-2.5"
  | "lg:mx-2.5"
  | "md:mx-2.5"
  | "sm:mx-2.5"
  | "xs:mx-2.5"
  | "2xl:mx-3.5"
  | "1xl:mx-3.5"
  | "xl:mx-3.5"
  | "lg:mx-3.5"
  | "md:mx-3.5"
  | "sm:mx-3.5"
  | "xs:mx-3.5"
  | "2xl:-mx-0"
  | "1xl:-mx-0"
  | "xl:-mx-0"
  | "lg:-mx-0"
  | "md:-mx-0"
  | "sm:-mx-0"
  | "xs:-mx-0"
  | "2xl:-mx-1"
  | "1xl:-mx-1"
  | "xl:-mx-1"
  | "lg:-mx-1"
  | "md:-mx-1"
  | "sm:-mx-1"
  | "xs:-mx-1"
  | "2xl:-mx-2"
  | "1xl:-mx-2"
  | "xl:-mx-2"
  | "lg:-mx-2"
  | "md:-mx-2"
  | "sm:-mx-2"
  | "xs:-mx-2"
  | "2xl:-mx-3"
  | "1xl:-mx-3"
  | "xl:-mx-3"
  | "lg:-mx-3"
  | "md:-mx-3"
  | "sm:-mx-3"
  | "xs:-mx-3"
  | "2xl:-mx-4"
  | "1xl:-mx-4"
  | "xl:-mx-4"
  | "lg:-mx-4"
  | "md:-mx-4"
  | "sm:-mx-4"
  | "xs:-mx-4"
  | "2xl:-mx-5"
  | "1xl:-mx-5"
  | "xl:-mx-5"
  | "lg:-mx-5"
  | "md:-mx-5"
  | "sm:-mx-5"
  | "xs:-mx-5"
  | "2xl:-mx-6"
  | "1xl:-mx-6"
  | "xl:-mx-6"
  | "lg:-mx-6"
  | "md:-mx-6"
  | "sm:-mx-6"
  | "xs:-mx-6"
  | "2xl:-mx-7"
  | "1xl:-mx-7"
  | "xl:-mx-7"
  | "lg:-mx-7"
  | "md:-mx-7"
  | "sm:-mx-7"
  | "xs:-mx-7"
  | "2xl:-mx-8"
  | "1xl:-mx-8"
  | "xl:-mx-8"
  | "lg:-mx-8"
  | "md:-mx-8"
  | "sm:-mx-8"
  | "xs:-mx-8"
  | "2xl:-mx-9"
  | "1xl:-mx-9"
  | "xl:-mx-9"
  | "lg:-mx-9"
  | "md:-mx-9"
  | "sm:-mx-9"
  | "xs:-mx-9"
  | "2xl:-mx-10"
  | "1xl:-mx-10"
  | "xl:-mx-10"
  | "lg:-mx-10"
  | "md:-mx-10"
  | "sm:-mx-10"
  | "xs:-mx-10"
  | "2xl:-mx-11"
  | "1xl:-mx-11"
  | "xl:-mx-11"
  | "lg:-mx-11"
  | "md:-mx-11"
  | "sm:-mx-11"
  | "xs:-mx-11"
  | "2xl:-mx-12"
  | "1xl:-mx-12"
  | "xl:-mx-12"
  | "lg:-mx-12"
  | "md:-mx-12"
  | "sm:-mx-12"
  | "xs:-mx-12"
  | "2xl:-mx-14"
  | "1xl:-mx-14"
  | "xl:-mx-14"
  | "lg:-mx-14"
  | "md:-mx-14"
  | "sm:-mx-14"
  | "xs:-mx-14"
  | "2xl:-mx-16"
  | "1xl:-mx-16"
  | "xl:-mx-16"
  | "lg:-mx-16"
  | "md:-mx-16"
  | "sm:-mx-16"
  | "xs:-mx-16"
  | "2xl:-mx-20"
  | "1xl:-mx-20"
  | "xl:-mx-20"
  | "lg:-mx-20"
  | "md:-mx-20"
  | "sm:-mx-20"
  | "xs:-mx-20"
  | "2xl:-mx-24"
  | "1xl:-mx-24"
  | "xl:-mx-24"
  | "lg:-mx-24"
  | "md:-mx-24"
  | "sm:-mx-24"
  | "xs:-mx-24"
  | "2xl:-mx-28"
  | "1xl:-mx-28"
  | "xl:-mx-28"
  | "lg:-mx-28"
  | "md:-mx-28"
  | "sm:-mx-28"
  | "xs:-mx-28"
  | "2xl:-mx-32"
  | "1xl:-mx-32"
  | "xl:-mx-32"
  | "lg:-mx-32"
  | "md:-mx-32"
  | "sm:-mx-32"
  | "xs:-mx-32"
  | "2xl:-mx-36"
  | "1xl:-mx-36"
  | "xl:-mx-36"
  | "lg:-mx-36"
  | "md:-mx-36"
  | "sm:-mx-36"
  | "xs:-mx-36"
  | "2xl:-mx-40"
  | "1xl:-mx-40"
  | "xl:-mx-40"
  | "lg:-mx-40"
  | "md:-mx-40"
  | "sm:-mx-40"
  | "xs:-mx-40"
  | "2xl:-mx-44"
  | "1xl:-mx-44"
  | "xl:-mx-44"
  | "lg:-mx-44"
  | "md:-mx-44"
  | "sm:-mx-44"
  | "xs:-mx-44"
  | "2xl:-mx-48"
  | "1xl:-mx-48"
  | "xl:-mx-48"
  | "lg:-mx-48"
  | "md:-mx-48"
  | "sm:-mx-48"
  | "xs:-mx-48"
  | "2xl:-mx-52"
  | "1xl:-mx-52"
  | "xl:-mx-52"
  | "lg:-mx-52"
  | "md:-mx-52"
  | "sm:-mx-52"
  | "xs:-mx-52"
  | "2xl:-mx-56"
  | "1xl:-mx-56"
  | "xl:-mx-56"
  | "lg:-mx-56"
  | "md:-mx-56"
  | "sm:-mx-56"
  | "xs:-mx-56"
  | "2xl:-mx-60"
  | "1xl:-mx-60"
  | "xl:-mx-60"
  | "lg:-mx-60"
  | "md:-mx-60"
  | "sm:-mx-60"
  | "xs:-mx-60"
  | "2xl:-mx-64"
  | "1xl:-mx-64"
  | "xl:-mx-64"
  | "lg:-mx-64"
  | "md:-mx-64"
  | "sm:-mx-64"
  | "xs:-mx-64"
  | "2xl:-mx-72"
  | "1xl:-mx-72"
  | "xl:-mx-72"
  | "lg:-mx-72"
  | "md:-mx-72"
  | "sm:-mx-72"
  | "xs:-mx-72"
  | "2xl:-mx-80"
  | "1xl:-mx-80"
  | "xl:-mx-80"
  | "lg:-mx-80"
  | "md:-mx-80"
  | "sm:-mx-80"
  | "xs:-mx-80"
  | "2xl:-mx-96"
  | "1xl:-mx-96"
  | "xl:-mx-96"
  | "lg:-mx-96"
  | "md:-mx-96"
  | "sm:-mx-96"
  | "xs:-mx-96"
  | "2xl:-mx-px"
  | "1xl:-mx-px"
  | "xl:-mx-px"
  | "lg:-mx-px"
  | "md:-mx-px"
  | "sm:-mx-px"
  | "xs:-mx-px"
  | "2xl:-mx-0.5"
  | "1xl:-mx-0.5"
  | "xl:-mx-0.5"
  | "lg:-mx-0.5"
  | "md:-mx-0.5"
  | "sm:-mx-0.5"
  | "xs:-mx-0.5"
  | "2xl:-mx-1.5"
  | "1xl:-mx-1.5"
  | "xl:-mx-1.5"
  | "lg:-mx-1.5"
  | "md:-mx-1.5"
  | "sm:-mx-1.5"
  | "xs:-mx-1.5"
  | "2xl:-mx-2.5"
  | "1xl:-mx-2.5"
  | "xl:-mx-2.5"
  | "lg:-mx-2.5"
  | "md:-mx-2.5"
  | "sm:-mx-2.5"
  | "xs:-mx-2.5"
  | "2xl:-mx-3.5"
  | "1xl:-mx-3.5"
  | "xl:-mx-3.5"
  | "lg:-mx-3.5"
  | "md:-mx-3.5"
  | "sm:-mx-3.5"
  | "xs:-mx-3.5"
  | "2xl:mt-0"
  | "1xl:mt-0"
  | "xl:mt-0"
  | "lg:mt-0"
  | "md:mt-0"
  | "sm:mt-0"
  | "xs:mt-0"
  | "2xl:mt-1"
  | "1xl:mt-1"
  | "xl:mt-1"
  | "lg:mt-1"
  | "md:mt-1"
  | "sm:mt-1"
  | "xs:mt-1"
  | "2xl:mt-2"
  | "1xl:mt-2"
  | "xl:mt-2"
  | "lg:mt-2"
  | "md:mt-2"
  | "sm:mt-2"
  | "xs:mt-2"
  | "2xl:mt-3"
  | "1xl:mt-3"
  | "xl:mt-3"
  | "lg:mt-3"
  | "md:mt-3"
  | "sm:mt-3"
  | "xs:mt-3"
  | "2xl:mt-4"
  | "1xl:mt-4"
  | "xl:mt-4"
  | "lg:mt-4"
  | "md:mt-4"
  | "sm:mt-4"
  | "xs:mt-4"
  | "2xl:mt-5"
  | "1xl:mt-5"
  | "xl:mt-5"
  | "lg:mt-5"
  | "md:mt-5"
  | "sm:mt-5"
  | "xs:mt-5"
  | "2xl:mt-6"
  | "1xl:mt-6"
  | "xl:mt-6"
  | "lg:mt-6"
  | "md:mt-6"
  | "sm:mt-6"
  | "xs:mt-6"
  | "2xl:mt-7"
  | "1xl:mt-7"
  | "xl:mt-7"
  | "lg:mt-7"
  | "md:mt-7"
  | "sm:mt-7"
  | "xs:mt-7"
  | "2xl:mt-8"
  | "1xl:mt-8"
  | "xl:mt-8"
  | "lg:mt-8"
  | "md:mt-8"
  | "sm:mt-8"
  | "xs:mt-8"
  | "2xl:mt-9"
  | "1xl:mt-9"
  | "xl:mt-9"
  | "lg:mt-9"
  | "md:mt-9"
  | "sm:mt-9"
  | "xs:mt-9"
  | "2xl:mt-10"
  | "1xl:mt-10"
  | "xl:mt-10"
  | "lg:mt-10"
  | "md:mt-10"
  | "sm:mt-10"
  | "xs:mt-10"
  | "2xl:mt-11"
  | "1xl:mt-11"
  | "xl:mt-11"
  | "lg:mt-11"
  | "md:mt-11"
  | "sm:mt-11"
  | "xs:mt-11"
  | "2xl:mt-12"
  | "1xl:mt-12"
  | "xl:mt-12"
  | "lg:mt-12"
  | "md:mt-12"
  | "sm:mt-12"
  | "xs:mt-12"
  | "2xl:mt-14"
  | "1xl:mt-14"
  | "xl:mt-14"
  | "lg:mt-14"
  | "md:mt-14"
  | "sm:mt-14"
  | "xs:mt-14"
  | "2xl:mt-16"
  | "1xl:mt-16"
  | "xl:mt-16"
  | "lg:mt-16"
  | "md:mt-16"
  | "sm:mt-16"
  | "xs:mt-16"
  | "2xl:mt-20"
  | "1xl:mt-20"
  | "xl:mt-20"
  | "lg:mt-20"
  | "md:mt-20"
  | "sm:mt-20"
  | "xs:mt-20"
  | "2xl:mt-24"
  | "1xl:mt-24"
  | "xl:mt-24"
  | "lg:mt-24"
  | "md:mt-24"
  | "sm:mt-24"
  | "xs:mt-24"
  | "2xl:mt-28"
  | "1xl:mt-28"
  | "xl:mt-28"
  | "lg:mt-28"
  | "md:mt-28"
  | "sm:mt-28"
  | "xs:mt-28"
  | "2xl:mt-32"
  | "1xl:mt-32"
  | "xl:mt-32"
  | "lg:mt-32"
  | "md:mt-32"
  | "sm:mt-32"
  | "xs:mt-32"
  | "2xl:mt-36"
  | "1xl:mt-36"
  | "xl:mt-36"
  | "lg:mt-36"
  | "md:mt-36"
  | "sm:mt-36"
  | "xs:mt-36"
  | "2xl:mt-40"
  | "1xl:mt-40"
  | "xl:mt-40"
  | "lg:mt-40"
  | "md:mt-40"
  | "sm:mt-40"
  | "xs:mt-40"
  | "2xl:mt-44"
  | "1xl:mt-44"
  | "xl:mt-44"
  | "lg:mt-44"
  | "md:mt-44"
  | "sm:mt-44"
  | "xs:mt-44"
  | "2xl:mt-48"
  | "1xl:mt-48"
  | "xl:mt-48"
  | "lg:mt-48"
  | "md:mt-48"
  | "sm:mt-48"
  | "xs:mt-48"
  | "2xl:mt-52"
  | "1xl:mt-52"
  | "xl:mt-52"
  | "lg:mt-52"
  | "md:mt-52"
  | "sm:mt-52"
  | "xs:mt-52"
  | "2xl:mt-56"
  | "1xl:mt-56"
  | "xl:mt-56"
  | "lg:mt-56"
  | "md:mt-56"
  | "sm:mt-56"
  | "xs:mt-56"
  | "2xl:mt-60"
  | "1xl:mt-60"
  | "xl:mt-60"
  | "lg:mt-60"
  | "md:mt-60"
  | "sm:mt-60"
  | "xs:mt-60"
  | "2xl:mt-64"
  | "1xl:mt-64"
  | "xl:mt-64"
  | "lg:mt-64"
  | "md:mt-64"
  | "sm:mt-64"
  | "xs:mt-64"
  | "2xl:mt-72"
  | "1xl:mt-72"
  | "xl:mt-72"
  | "lg:mt-72"
  | "md:mt-72"
  | "sm:mt-72"
  | "xs:mt-72"
  | "2xl:mt-80"
  | "1xl:mt-80"
  | "xl:mt-80"
  | "lg:mt-80"
  | "md:mt-80"
  | "sm:mt-80"
  | "xs:mt-80"
  | "2xl:mt-96"
  | "1xl:mt-96"
  | "xl:mt-96"
  | "lg:mt-96"
  | "md:mt-96"
  | "sm:mt-96"
  | "xs:mt-96"
  | "2xl:mt-auto"
  | "1xl:mt-auto"
  | "xl:mt-auto"
  | "lg:mt-auto"
  | "md:mt-auto"
  | "sm:mt-auto"
  | "xs:mt-auto"
  | "2xl:mt-px"
  | "1xl:mt-px"
  | "xl:mt-px"
  | "lg:mt-px"
  | "md:mt-px"
  | "sm:mt-px"
  | "xs:mt-px"
  | "2xl:mt-0.5"
  | "1xl:mt-0.5"
  | "xl:mt-0.5"
  | "lg:mt-0.5"
  | "md:mt-0.5"
  | "sm:mt-0.5"
  | "xs:mt-0.5"
  | "2xl:mt-1.5"
  | "1xl:mt-1.5"
  | "xl:mt-1.5"
  | "lg:mt-1.5"
  | "md:mt-1.5"
  | "sm:mt-1.5"
  | "xs:mt-1.5"
  | "2xl:mt-2.5"
  | "1xl:mt-2.5"
  | "xl:mt-2.5"
  | "lg:mt-2.5"
  | "md:mt-2.5"
  | "sm:mt-2.5"
  | "xs:mt-2.5"
  | "2xl:mt-3.5"
  | "1xl:mt-3.5"
  | "xl:mt-3.5"
  | "lg:mt-3.5"
  | "md:mt-3.5"
  | "sm:mt-3.5"
  | "xs:mt-3.5"
  | "2xl:-mt-0"
  | "1xl:-mt-0"
  | "xl:-mt-0"
  | "lg:-mt-0"
  | "md:-mt-0"
  | "sm:-mt-0"
  | "xs:-mt-0"
  | "2xl:-mt-1"
  | "1xl:-mt-1"
  | "xl:-mt-1"
  | "lg:-mt-1"
  | "md:-mt-1"
  | "sm:-mt-1"
  | "xs:-mt-1"
  | "2xl:-mt-2"
  | "1xl:-mt-2"
  | "xl:-mt-2"
  | "lg:-mt-2"
  | "md:-mt-2"
  | "sm:-mt-2"
  | "xs:-mt-2"
  | "2xl:-mt-3"
  | "1xl:-mt-3"
  | "xl:-mt-3"
  | "lg:-mt-3"
  | "md:-mt-3"
  | "sm:-mt-3"
  | "xs:-mt-3"
  | "2xl:-mt-4"
  | "1xl:-mt-4"
  | "xl:-mt-4"
  | "lg:-mt-4"
  | "md:-mt-4"
  | "sm:-mt-4"
  | "xs:-mt-4"
  | "2xl:-mt-5"
  | "1xl:-mt-5"
  | "xl:-mt-5"
  | "lg:-mt-5"
  | "md:-mt-5"
  | "sm:-mt-5"
  | "xs:-mt-5"
  | "2xl:-mt-6"
  | "1xl:-mt-6"
  | "xl:-mt-6"
  | "lg:-mt-6"
  | "md:-mt-6"
  | "sm:-mt-6"
  | "xs:-mt-6"
  | "2xl:-mt-7"
  | "1xl:-mt-7"
  | "xl:-mt-7"
  | "lg:-mt-7"
  | "md:-mt-7"
  | "sm:-mt-7"
  | "xs:-mt-7"
  | "2xl:-mt-8"
  | "1xl:-mt-8"
  | "xl:-mt-8"
  | "lg:-mt-8"
  | "md:-mt-8"
  | "sm:-mt-8"
  | "xs:-mt-8"
  | "2xl:-mt-9"
  | "1xl:-mt-9"
  | "xl:-mt-9"
  | "lg:-mt-9"
  | "md:-mt-9"
  | "sm:-mt-9"
  | "xs:-mt-9"
  | "2xl:-mt-10"
  | "1xl:-mt-10"
  | "xl:-mt-10"
  | "lg:-mt-10"
  | "md:-mt-10"
  | "sm:-mt-10"
  | "xs:-mt-10"
  | "2xl:-mt-11"
  | "1xl:-mt-11"
  | "xl:-mt-11"
  | "lg:-mt-11"
  | "md:-mt-11"
  | "sm:-mt-11"
  | "xs:-mt-11"
  | "2xl:-mt-12"
  | "1xl:-mt-12"
  | "xl:-mt-12"
  | "lg:-mt-12"
  | "md:-mt-12"
  | "sm:-mt-12"
  | "xs:-mt-12"
  | "2xl:-mt-14"
  | "1xl:-mt-14"
  | "xl:-mt-14"
  | "lg:-mt-14"
  | "md:-mt-14"
  | "sm:-mt-14"
  | "xs:-mt-14"
  | "2xl:-mt-16"
  | "1xl:-mt-16"
  | "xl:-mt-16"
  | "lg:-mt-16"
  | "md:-mt-16"
  | "sm:-mt-16"
  | "xs:-mt-16"
  | "2xl:-mt-20"
  | "1xl:-mt-20"
  | "xl:-mt-20"
  | "lg:-mt-20"
  | "md:-mt-20"
  | "sm:-mt-20"
  | "xs:-mt-20"
  | "2xl:-mt-24"
  | "1xl:-mt-24"
  | "xl:-mt-24"
  | "lg:-mt-24"
  | "md:-mt-24"
  | "sm:-mt-24"
  | "xs:-mt-24"
  | "2xl:-mt-28"
  | "1xl:-mt-28"
  | "xl:-mt-28"
  | "lg:-mt-28"
  | "md:-mt-28"
  | "sm:-mt-28"
  | "xs:-mt-28"
  | "2xl:-mt-32"
  | "1xl:-mt-32"
  | "xl:-mt-32"
  | "lg:-mt-32"
  | "md:-mt-32"
  | "sm:-mt-32"
  | "xs:-mt-32"
  | "2xl:-mt-36"
  | "1xl:-mt-36"
  | "xl:-mt-36"
  | "lg:-mt-36"
  | "md:-mt-36"
  | "sm:-mt-36"
  | "xs:-mt-36"
  | "2xl:-mt-40"
  | "1xl:-mt-40"
  | "xl:-mt-40"
  | "lg:-mt-40"
  | "md:-mt-40"
  | "sm:-mt-40"
  | "xs:-mt-40"
  | "2xl:-mt-44"
  | "1xl:-mt-44"
  | "xl:-mt-44"
  | "lg:-mt-44"
  | "md:-mt-44"
  | "sm:-mt-44"
  | "xs:-mt-44"
  | "2xl:-mt-48"
  | "1xl:-mt-48"
  | "xl:-mt-48"
  | "lg:-mt-48"
  | "md:-mt-48"
  | "sm:-mt-48"
  | "xs:-mt-48"
  | "2xl:-mt-52"
  | "1xl:-mt-52"
  | "xl:-mt-52"
  | "lg:-mt-52"
  | "md:-mt-52"
  | "sm:-mt-52"
  | "xs:-mt-52"
  | "2xl:-mt-56"
  | "1xl:-mt-56"
  | "xl:-mt-56"
  | "lg:-mt-56"
  | "md:-mt-56"
  | "sm:-mt-56"
  | "xs:-mt-56"
  | "2xl:-mt-60"
  | "1xl:-mt-60"
  | "xl:-mt-60"
  | "lg:-mt-60"
  | "md:-mt-60"
  | "sm:-mt-60"
  | "xs:-mt-60"
  | "2xl:-mt-64"
  | "1xl:-mt-64"
  | "xl:-mt-64"
  | "lg:-mt-64"
  | "md:-mt-64"
  | "sm:-mt-64"
  | "xs:-mt-64"
  | "2xl:-mt-72"
  | "1xl:-mt-72"
  | "xl:-mt-72"
  | "lg:-mt-72"
  | "md:-mt-72"
  | "sm:-mt-72"
  | "xs:-mt-72"
  | "2xl:-mt-80"
  | "1xl:-mt-80"
  | "xl:-mt-80"
  | "lg:-mt-80"
  | "md:-mt-80"
  | "sm:-mt-80"
  | "xs:-mt-80"
  | "2xl:-mt-96"
  | "1xl:-mt-96"
  | "xl:-mt-96"
  | "lg:-mt-96"
  | "md:-mt-96"
  | "sm:-mt-96"
  | "xs:-mt-96"
  | "2xl:-mt-px"
  | "1xl:-mt-px"
  | "xl:-mt-px"
  | "lg:-mt-px"
  | "md:-mt-px"
  | "sm:-mt-px"
  | "xs:-mt-px"
  | "2xl:-mt-0.5"
  | "1xl:-mt-0.5"
  | "xl:-mt-0.5"
  | "lg:-mt-0.5"
  | "md:-mt-0.5"
  | "sm:-mt-0.5"
  | "xs:-mt-0.5"
  | "2xl:-mt-1.5"
  | "1xl:-mt-1.5"
  | "xl:-mt-1.5"
  | "lg:-mt-1.5"
  | "md:-mt-1.5"
  | "sm:-mt-1.5"
  | "xs:-mt-1.5"
  | "2xl:-mt-2.5"
  | "1xl:-mt-2.5"
  | "xl:-mt-2.5"
  | "lg:-mt-2.5"
  | "md:-mt-2.5"
  | "sm:-mt-2.5"
  | "xs:-mt-2.5"
  | "2xl:-mt-3.5"
  | "1xl:-mt-3.5"
  | "xl:-mt-3.5"
  | "lg:-mt-3.5"
  | "md:-mt-3.5"
  | "sm:-mt-3.5"
  | "xs:-mt-3.5"
  | "2xl:mr-0"
  | "1xl:mr-0"
  | "xl:mr-0"
  | "lg:mr-0"
  | "md:mr-0"
  | "sm:mr-0"
  | "xs:mr-0"
  | "2xl:mr-1"
  | "1xl:mr-1"
  | "xl:mr-1"
  | "lg:mr-1"
  | "md:mr-1"
  | "sm:mr-1"
  | "xs:mr-1"
  | "2xl:mr-2"
  | "1xl:mr-2"
  | "xl:mr-2"
  | "lg:mr-2"
  | "md:mr-2"
  | "sm:mr-2"
  | "xs:mr-2"
  | "2xl:mr-3"
  | "1xl:mr-3"
  | "xl:mr-3"
  | "lg:mr-3"
  | "md:mr-3"
  | "sm:mr-3"
  | "xs:mr-3"
  | "2xl:mr-4"
  | "1xl:mr-4"
  | "xl:mr-4"
  | "lg:mr-4"
  | "md:mr-4"
  | "sm:mr-4"
  | "xs:mr-4"
  | "2xl:mr-5"
  | "1xl:mr-5"
  | "xl:mr-5"
  | "lg:mr-5"
  | "md:mr-5"
  | "sm:mr-5"
  | "xs:mr-5"
  | "2xl:mr-6"
  | "1xl:mr-6"
  | "xl:mr-6"
  | "lg:mr-6"
  | "md:mr-6"
  | "sm:mr-6"
  | "xs:mr-6"
  | "2xl:mr-7"
  | "1xl:mr-7"
  | "xl:mr-7"
  | "lg:mr-7"
  | "md:mr-7"
  | "sm:mr-7"
  | "xs:mr-7"
  | "2xl:mr-8"
  | "1xl:mr-8"
  | "xl:mr-8"
  | "lg:mr-8"
  | "md:mr-8"
  | "sm:mr-8"
  | "xs:mr-8"
  | "2xl:mr-9"
  | "1xl:mr-9"
  | "xl:mr-9"
  | "lg:mr-9"
  | "md:mr-9"
  | "sm:mr-9"
  | "xs:mr-9"
  | "2xl:mr-10"
  | "1xl:mr-10"
  | "xl:mr-10"
  | "lg:mr-10"
  | "md:mr-10"
  | "sm:mr-10"
  | "xs:mr-10"
  | "2xl:mr-11"
  | "1xl:mr-11"
  | "xl:mr-11"
  | "lg:mr-11"
  | "md:mr-11"
  | "sm:mr-11"
  | "xs:mr-11"
  | "2xl:mr-12"
  | "1xl:mr-12"
  | "xl:mr-12"
  | "lg:mr-12"
  | "md:mr-12"
  | "sm:mr-12"
  | "xs:mr-12"
  | "2xl:mr-14"
  | "1xl:mr-14"
  | "xl:mr-14"
  | "lg:mr-14"
  | "md:mr-14"
  | "sm:mr-14"
  | "xs:mr-14"
  | "2xl:mr-16"
  | "1xl:mr-16"
  | "xl:mr-16"
  | "lg:mr-16"
  | "md:mr-16"
  | "sm:mr-16"
  | "xs:mr-16"
  | "2xl:mr-20"
  | "1xl:mr-20"
  | "xl:mr-20"
  | "lg:mr-20"
  | "md:mr-20"
  | "sm:mr-20"
  | "xs:mr-20"
  | "2xl:mr-24"
  | "1xl:mr-24"
  | "xl:mr-24"
  | "lg:mr-24"
  | "md:mr-24"
  | "sm:mr-24"
  | "xs:mr-24"
  | "2xl:mr-28"
  | "1xl:mr-28"
  | "xl:mr-28"
  | "lg:mr-28"
  | "md:mr-28"
  | "sm:mr-28"
  | "xs:mr-28"
  | "2xl:mr-32"
  | "1xl:mr-32"
  | "xl:mr-32"
  | "lg:mr-32"
  | "md:mr-32"
  | "sm:mr-32"
  | "xs:mr-32"
  | "2xl:mr-36"
  | "1xl:mr-36"
  | "xl:mr-36"
  | "lg:mr-36"
  | "md:mr-36"
  | "sm:mr-36"
  | "xs:mr-36"
  | "2xl:mr-40"
  | "1xl:mr-40"
  | "xl:mr-40"
  | "lg:mr-40"
  | "md:mr-40"
  | "sm:mr-40"
  | "xs:mr-40"
  | "2xl:mr-44"
  | "1xl:mr-44"
  | "xl:mr-44"
  | "lg:mr-44"
  | "md:mr-44"
  | "sm:mr-44"
  | "xs:mr-44"
  | "2xl:mr-48"
  | "1xl:mr-48"
  | "xl:mr-48"
  | "lg:mr-48"
  | "md:mr-48"
  | "sm:mr-48"
  | "xs:mr-48"
  | "2xl:mr-52"
  | "1xl:mr-52"
  | "xl:mr-52"
  | "lg:mr-52"
  | "md:mr-52"
  | "sm:mr-52"
  | "xs:mr-52"
  | "2xl:mr-56"
  | "1xl:mr-56"
  | "xl:mr-56"
  | "lg:mr-56"
  | "md:mr-56"
  | "sm:mr-56"
  | "xs:mr-56"
  | "2xl:mr-60"
  | "1xl:mr-60"
  | "xl:mr-60"
  | "lg:mr-60"
  | "md:mr-60"
  | "sm:mr-60"
  | "xs:mr-60"
  | "2xl:mr-64"
  | "1xl:mr-64"
  | "xl:mr-64"
  | "lg:mr-64"
  | "md:mr-64"
  | "sm:mr-64"
  | "xs:mr-64"
  | "2xl:mr-72"
  | "1xl:mr-72"
  | "xl:mr-72"
  | "lg:mr-72"
  | "md:mr-72"
  | "sm:mr-72"
  | "xs:mr-72"
  | "2xl:mr-80"
  | "1xl:mr-80"
  | "xl:mr-80"
  | "lg:mr-80"
  | "md:mr-80"
  | "sm:mr-80"
  | "xs:mr-80"
  | "2xl:mr-96"
  | "1xl:mr-96"
  | "xl:mr-96"
  | "lg:mr-96"
  | "md:mr-96"
  | "sm:mr-96"
  | "xs:mr-96"
  | "2xl:mr-auto"
  | "1xl:mr-auto"
  | "xl:mr-auto"
  | "lg:mr-auto"
  | "md:mr-auto"
  | "sm:mr-auto"
  | "xs:mr-auto"
  | "2xl:mr-px"
  | "1xl:mr-px"
  | "xl:mr-px"
  | "lg:mr-px"
  | "md:mr-px"
  | "sm:mr-px"
  | "xs:mr-px"
  | "2xl:mr-0.5"
  | "1xl:mr-0.5"
  | "xl:mr-0.5"
  | "lg:mr-0.5"
  | "md:mr-0.5"
  | "sm:mr-0.5"
  | "xs:mr-0.5"
  | "2xl:mr-1.5"
  | "1xl:mr-1.5"
  | "xl:mr-1.5"
  | "lg:mr-1.5"
  | "md:mr-1.5"
  | "sm:mr-1.5"
  | "xs:mr-1.5"
  | "2xl:mr-2.5"
  | "1xl:mr-2.5"
  | "xl:mr-2.5"
  | "lg:mr-2.5"
  | "md:mr-2.5"
  | "sm:mr-2.5"
  | "xs:mr-2.5"
  | "2xl:mr-3.5"
  | "1xl:mr-3.5"
  | "xl:mr-3.5"
  | "lg:mr-3.5"
  | "md:mr-3.5"
  | "sm:mr-3.5"
  | "xs:mr-3.5"
  | "2xl:-mr-0"
  | "1xl:-mr-0"
  | "xl:-mr-0"
  | "lg:-mr-0"
  | "md:-mr-0"
  | "sm:-mr-0"
  | "xs:-mr-0"
  | "2xl:-mr-1"
  | "1xl:-mr-1"
  | "xl:-mr-1"
  | "lg:-mr-1"
  | "md:-mr-1"
  | "sm:-mr-1"
  | "xs:-mr-1"
  | "2xl:-mr-2"
  | "1xl:-mr-2"
  | "xl:-mr-2"
  | "lg:-mr-2"
  | "md:-mr-2"
  | "sm:-mr-2"
  | "xs:-mr-2"
  | "2xl:-mr-3"
  | "1xl:-mr-3"
  | "xl:-mr-3"
  | "lg:-mr-3"
  | "md:-mr-3"
  | "sm:-mr-3"
  | "xs:-mr-3"
  | "2xl:-mr-4"
  | "1xl:-mr-4"
  | "xl:-mr-4"
  | "lg:-mr-4"
  | "md:-mr-4"
  | "sm:-mr-4"
  | "xs:-mr-4"
  | "2xl:-mr-5"
  | "1xl:-mr-5"
  | "xl:-mr-5"
  | "lg:-mr-5"
  | "md:-mr-5"
  | "sm:-mr-5"
  | "xs:-mr-5"
  | "2xl:-mr-6"
  | "1xl:-mr-6"
  | "xl:-mr-6"
  | "lg:-mr-6"
  | "md:-mr-6"
  | "sm:-mr-6"
  | "xs:-mr-6"
  | "2xl:-mr-7"
  | "1xl:-mr-7"
  | "xl:-mr-7"
  | "lg:-mr-7"
  | "md:-mr-7"
  | "sm:-mr-7"
  | "xs:-mr-7"
  | "2xl:-mr-8"
  | "1xl:-mr-8"
  | "xl:-mr-8"
  | "lg:-mr-8"
  | "md:-mr-8"
  | "sm:-mr-8"
  | "xs:-mr-8"
  | "2xl:-mr-9"
  | "1xl:-mr-9"
  | "xl:-mr-9"
  | "lg:-mr-9"
  | "md:-mr-9"
  | "sm:-mr-9"
  | "xs:-mr-9"
  | "2xl:-mr-10"
  | "1xl:-mr-10"
  | "xl:-mr-10"
  | "lg:-mr-10"
  | "md:-mr-10"
  | "sm:-mr-10"
  | "xs:-mr-10"
  | "2xl:-mr-11"
  | "1xl:-mr-11"
  | "xl:-mr-11"
  | "lg:-mr-11"
  | "md:-mr-11"
  | "sm:-mr-11"
  | "xs:-mr-11"
  | "2xl:-mr-12"
  | "1xl:-mr-12"
  | "xl:-mr-12"
  | "lg:-mr-12"
  | "md:-mr-12"
  | "sm:-mr-12"
  | "xs:-mr-12"
  | "2xl:-mr-14"
  | "1xl:-mr-14"
  | "xl:-mr-14"
  | "lg:-mr-14"
  | "md:-mr-14"
  | "sm:-mr-14"
  | "xs:-mr-14"
  | "2xl:-mr-16"
  | "1xl:-mr-16"
  | "xl:-mr-16"
  | "lg:-mr-16"
  | "md:-mr-16"
  | "sm:-mr-16"
  | "xs:-mr-16"
  | "2xl:-mr-20"
  | "1xl:-mr-20"
  | "xl:-mr-20"
  | "lg:-mr-20"
  | "md:-mr-20"
  | "sm:-mr-20"
  | "xs:-mr-20"
  | "2xl:-mr-24"
  | "1xl:-mr-24"
  | "xl:-mr-24"
  | "lg:-mr-24"
  | "md:-mr-24"
  | "sm:-mr-24"
  | "xs:-mr-24"
  | "2xl:-mr-28"
  | "1xl:-mr-28"
  | "xl:-mr-28"
  | "lg:-mr-28"
  | "md:-mr-28"
  | "sm:-mr-28"
  | "xs:-mr-28"
  | "2xl:-mr-32"
  | "1xl:-mr-32"
  | "xl:-mr-32"
  | "lg:-mr-32"
  | "md:-mr-32"
  | "sm:-mr-32"
  | "xs:-mr-32"
  | "2xl:-mr-36"
  | "1xl:-mr-36"
  | "xl:-mr-36"
  | "lg:-mr-36"
  | "md:-mr-36"
  | "sm:-mr-36"
  | "xs:-mr-36"
  | "2xl:-mr-40"
  | "1xl:-mr-40"
  | "xl:-mr-40"
  | "lg:-mr-40"
  | "md:-mr-40"
  | "sm:-mr-40"
  | "xs:-mr-40"
  | "2xl:-mr-44"
  | "1xl:-mr-44"
  | "xl:-mr-44"
  | "lg:-mr-44"
  | "md:-mr-44"
  | "sm:-mr-44"
  | "xs:-mr-44"
  | "2xl:-mr-48"
  | "1xl:-mr-48"
  | "xl:-mr-48"
  | "lg:-mr-48"
  | "md:-mr-48"
  | "sm:-mr-48"
  | "xs:-mr-48"
  | "2xl:-mr-52"
  | "1xl:-mr-52"
  | "xl:-mr-52"
  | "lg:-mr-52"
  | "md:-mr-52"
  | "sm:-mr-52"
  | "xs:-mr-52"
  | "2xl:-mr-56"
  | "1xl:-mr-56"
  | "xl:-mr-56"
  | "lg:-mr-56"
  | "md:-mr-56"
  | "sm:-mr-56"
  | "xs:-mr-56"
  | "2xl:-mr-60"
  | "1xl:-mr-60"
  | "xl:-mr-60"
  | "lg:-mr-60"
  | "md:-mr-60"
  | "sm:-mr-60"
  | "xs:-mr-60"
  | "2xl:-mr-64"
  | "1xl:-mr-64"
  | "xl:-mr-64"
  | "lg:-mr-64"
  | "md:-mr-64"
  | "sm:-mr-64"
  | "xs:-mr-64"
  | "2xl:-mr-72"
  | "1xl:-mr-72"
  | "xl:-mr-72"
  | "lg:-mr-72"
  | "md:-mr-72"
  | "sm:-mr-72"
  | "xs:-mr-72"
  | "2xl:-mr-80"
  | "1xl:-mr-80"
  | "xl:-mr-80"
  | "lg:-mr-80"
  | "md:-mr-80"
  | "sm:-mr-80"
  | "xs:-mr-80"
  | "2xl:-mr-96"
  | "1xl:-mr-96"
  | "xl:-mr-96"
  | "lg:-mr-96"
  | "md:-mr-96"
  | "sm:-mr-96"
  | "xs:-mr-96"
  | "2xl:-mr-px"
  | "1xl:-mr-px"
  | "xl:-mr-px"
  | "lg:-mr-px"
  | "md:-mr-px"
  | "sm:-mr-px"
  | "xs:-mr-px"
  | "2xl:-mr-0.5"
  | "1xl:-mr-0.5"
  | "xl:-mr-0.5"
  | "lg:-mr-0.5"
  | "md:-mr-0.5"
  | "sm:-mr-0.5"
  | "xs:-mr-0.5"
  | "2xl:-mr-1.5"
  | "1xl:-mr-1.5"
  | "xl:-mr-1.5"
  | "lg:-mr-1.5"
  | "md:-mr-1.5"
  | "sm:-mr-1.5"
  | "xs:-mr-1.5"
  | "2xl:-mr-2.5"
  | "1xl:-mr-2.5"
  | "xl:-mr-2.5"
  | "lg:-mr-2.5"
  | "md:-mr-2.5"
  | "sm:-mr-2.5"
  | "xs:-mr-2.5"
  | "2xl:-mr-3.5"
  | "1xl:-mr-3.5"
  | "xl:-mr-3.5"
  | "lg:-mr-3.5"
  | "md:-mr-3.5"
  | "sm:-mr-3.5"
  | "xs:-mr-3.5"
  | "2xl:mb-0"
  | "1xl:mb-0"
  | "xl:mb-0"
  | "lg:mb-0"
  | "md:mb-0"
  | "sm:mb-0"
  | "xs:mb-0"
  | "2xl:mb-1"
  | "1xl:mb-1"
  | "xl:mb-1"
  | "lg:mb-1"
  | "md:mb-1"
  | "sm:mb-1"
  | "xs:mb-1"
  | "2xl:mb-2"
  | "1xl:mb-2"
  | "xl:mb-2"
  | "lg:mb-2"
  | "md:mb-2"
  | "sm:mb-2"
  | "xs:mb-2"
  | "2xl:mb-3"
  | "1xl:mb-3"
  | "xl:mb-3"
  | "lg:mb-3"
  | "md:mb-3"
  | "sm:mb-3"
  | "xs:mb-3"
  | "2xl:mb-4"
  | "1xl:mb-4"
  | "xl:mb-4"
  | "lg:mb-4"
  | "md:mb-4"
  | "sm:mb-4"
  | "xs:mb-4"
  | "2xl:mb-5"
  | "1xl:mb-5"
  | "xl:mb-5"
  | "lg:mb-5"
  | "md:mb-5"
  | "sm:mb-5"
  | "xs:mb-5"
  | "2xl:mb-6"
  | "1xl:mb-6"
  | "xl:mb-6"
  | "lg:mb-6"
  | "md:mb-6"
  | "sm:mb-6"
  | "xs:mb-6"
  | "2xl:mb-7"
  | "1xl:mb-7"
  | "xl:mb-7"
  | "lg:mb-7"
  | "md:mb-7"
  | "sm:mb-7"
  | "xs:mb-7"
  | "2xl:mb-8"
  | "1xl:mb-8"
  | "xl:mb-8"
  | "lg:mb-8"
  | "md:mb-8"
  | "sm:mb-8"
  | "xs:mb-8"
  | "2xl:mb-9"
  | "1xl:mb-9"
  | "xl:mb-9"
  | "lg:mb-9"
  | "md:mb-9"
  | "sm:mb-9"
  | "xs:mb-9"
  | "2xl:mb-10"
  | "1xl:mb-10"
  | "xl:mb-10"
  | "lg:mb-10"
  | "md:mb-10"
  | "sm:mb-10"
  | "xs:mb-10"
  | "2xl:mb-11"
  | "1xl:mb-11"
  | "xl:mb-11"
  | "lg:mb-11"
  | "md:mb-11"
  | "sm:mb-11"
  | "xs:mb-11"
  | "2xl:mb-12"
  | "1xl:mb-12"
  | "xl:mb-12"
  | "lg:mb-12"
  | "md:mb-12"
  | "sm:mb-12"
  | "xs:mb-12"
  | "2xl:mb-14"
  | "1xl:mb-14"
  | "xl:mb-14"
  | "lg:mb-14"
  | "md:mb-14"
  | "sm:mb-14"
  | "xs:mb-14"
  | "2xl:mb-16"
  | "1xl:mb-16"
  | "xl:mb-16"
  | "lg:mb-16"
  | "md:mb-16"
  | "sm:mb-16"
  | "xs:mb-16"
  | "2xl:mb-20"
  | "1xl:mb-20"
  | "xl:mb-20"
  | "lg:mb-20"
  | "md:mb-20"
  | "sm:mb-20"
  | "xs:mb-20"
  | "2xl:mb-24"
  | "1xl:mb-24"
  | "xl:mb-24"
  | "lg:mb-24"
  | "md:mb-24"
  | "sm:mb-24"
  | "xs:mb-24"
  | "2xl:mb-28"
  | "1xl:mb-28"
  | "xl:mb-28"
  | "lg:mb-28"
  | "md:mb-28"
  | "sm:mb-28"
  | "xs:mb-28"
  | "2xl:mb-32"
  | "1xl:mb-32"
  | "xl:mb-32"
  | "lg:mb-32"
  | "md:mb-32"
  | "sm:mb-32"
  | "xs:mb-32"
  | "2xl:mb-36"
  | "1xl:mb-36"
  | "xl:mb-36"
  | "lg:mb-36"
  | "md:mb-36"
  | "sm:mb-36"
  | "xs:mb-36"
  | "2xl:mb-40"
  | "1xl:mb-40"
  | "xl:mb-40"
  | "lg:mb-40"
  | "md:mb-40"
  | "sm:mb-40"
  | "xs:mb-40"
  | "2xl:mb-44"
  | "1xl:mb-44"
  | "xl:mb-44"
  | "lg:mb-44"
  | "md:mb-44"
  | "sm:mb-44"
  | "xs:mb-44"
  | "2xl:mb-48"
  | "1xl:mb-48"
  | "xl:mb-48"
  | "lg:mb-48"
  | "md:mb-48"
  | "sm:mb-48"
  | "xs:mb-48"
  | "2xl:mb-52"
  | "1xl:mb-52"
  | "xl:mb-52"
  | "lg:mb-52"
  | "md:mb-52"
  | "sm:mb-52"
  | "xs:mb-52"
  | "2xl:mb-56"
  | "1xl:mb-56"
  | "xl:mb-56"
  | "lg:mb-56"
  | "md:mb-56"
  | "sm:mb-56"
  | "xs:mb-56"
  | "2xl:mb-60"
  | "1xl:mb-60"
  | "xl:mb-60"
  | "lg:mb-60"
  | "md:mb-60"
  | "sm:mb-60"
  | "xs:mb-60"
  | "2xl:mb-64"
  | "1xl:mb-64"
  | "xl:mb-64"
  | "lg:mb-64"
  | "md:mb-64"
  | "sm:mb-64"
  | "xs:mb-64"
  | "2xl:mb-72"
  | "1xl:mb-72"
  | "xl:mb-72"
  | "lg:mb-72"
  | "md:mb-72"
  | "sm:mb-72"
  | "xs:mb-72"
  | "2xl:mb-80"
  | "1xl:mb-80"
  | "xl:mb-80"
  | "lg:mb-80"
  | "md:mb-80"
  | "sm:mb-80"
  | "xs:mb-80"
  | "2xl:mb-96"
  | "1xl:mb-96"
  | "xl:mb-96"
  | "lg:mb-96"
  | "md:mb-96"
  | "sm:mb-96"
  | "xs:mb-96"
  | "2xl:mb-auto"
  | "1xl:mb-auto"
  | "xl:mb-auto"
  | "lg:mb-auto"
  | "md:mb-auto"
  | "sm:mb-auto"
  | "xs:mb-auto"
  | "2xl:mb-px"
  | "1xl:mb-px"
  | "xl:mb-px"
  | "lg:mb-px"
  | "md:mb-px"
  | "sm:mb-px"
  | "xs:mb-px"
  | "2xl:mb-0.5"
  | "1xl:mb-0.5"
  | "xl:mb-0.5"
  | "lg:mb-0.5"
  | "md:mb-0.5"
  | "sm:mb-0.5"
  | "xs:mb-0.5"
  | "2xl:mb-1.5"
  | "1xl:mb-1.5"
  | "xl:mb-1.5"
  | "lg:mb-1.5"
  | "md:mb-1.5"
  | "sm:mb-1.5"
  | "xs:mb-1.5"
  | "2xl:mb-2.5"
  | "1xl:mb-2.5"
  | "xl:mb-2.5"
  | "lg:mb-2.5"
  | "md:mb-2.5"
  | "sm:mb-2.5"
  | "xs:mb-2.5"
  | "2xl:mb-3.5"
  | "1xl:mb-3.5"
  | "xl:mb-3.5"
  | "lg:mb-3.5"
  | "md:mb-3.5"
  | "sm:mb-3.5"
  | "xs:mb-3.5"
  | "2xl:-mb-0"
  | "1xl:-mb-0"
  | "xl:-mb-0"
  | "lg:-mb-0"
  | "md:-mb-0"
  | "sm:-mb-0"
  | "xs:-mb-0"
  | "2xl:-mb-1"
  | "1xl:-mb-1"
  | "xl:-mb-1"
  | "lg:-mb-1"
  | "md:-mb-1"
  | "sm:-mb-1"
  | "xs:-mb-1"
  | "2xl:-mb-2"
  | "1xl:-mb-2"
  | "xl:-mb-2"
  | "lg:-mb-2"
  | "md:-mb-2"
  | "sm:-mb-2"
  | "xs:-mb-2"
  | "2xl:-mb-3"
  | "1xl:-mb-3"
  | "xl:-mb-3"
  | "lg:-mb-3"
  | "md:-mb-3"
  | "sm:-mb-3"
  | "xs:-mb-3"
  | "2xl:-mb-4"
  | "1xl:-mb-4"
  | "xl:-mb-4"
  | "lg:-mb-4"
  | "md:-mb-4"
  | "sm:-mb-4"
  | "xs:-mb-4"
  | "2xl:-mb-5"
  | "1xl:-mb-5"
  | "xl:-mb-5"
  | "lg:-mb-5"
  | "md:-mb-5"
  | "sm:-mb-5"
  | "xs:-mb-5"
  | "2xl:-mb-6"
  | "1xl:-mb-6"
  | "xl:-mb-6"
  | "lg:-mb-6"
  | "md:-mb-6"
  | "sm:-mb-6"
  | "xs:-mb-6"
  | "2xl:-mb-7"
  | "1xl:-mb-7"
  | "xl:-mb-7"
  | "lg:-mb-7"
  | "md:-mb-7"
  | "sm:-mb-7"
  | "xs:-mb-7"
  | "2xl:-mb-8"
  | "1xl:-mb-8"
  | "xl:-mb-8"
  | "lg:-mb-8"
  | "md:-mb-8"
  | "sm:-mb-8"
  | "xs:-mb-8"
  | "2xl:-mb-9"
  | "1xl:-mb-9"
  | "xl:-mb-9"
  | "lg:-mb-9"
  | "md:-mb-9"
  | "sm:-mb-9"
  | "xs:-mb-9"
  | "2xl:-mb-10"
  | "1xl:-mb-10"
  | "xl:-mb-10"
  | "lg:-mb-10"
  | "md:-mb-10"
  | "sm:-mb-10"
  | "xs:-mb-10"
  | "2xl:-mb-11"
  | "1xl:-mb-11"
  | "xl:-mb-11"
  | "lg:-mb-11"
  | "md:-mb-11"
  | "sm:-mb-11"
  | "xs:-mb-11"
  | "2xl:-mb-12"
  | "1xl:-mb-12"
  | "xl:-mb-12"
  | "lg:-mb-12"
  | "md:-mb-12"
  | "sm:-mb-12"
  | "xs:-mb-12"
  | "2xl:-mb-14"
  | "1xl:-mb-14"
  | "xl:-mb-14"
  | "lg:-mb-14"
  | "md:-mb-14"
  | "sm:-mb-14"
  | "xs:-mb-14"
  | "2xl:-mb-16"
  | "1xl:-mb-16"
  | "xl:-mb-16"
  | "lg:-mb-16"
  | "md:-mb-16"
  | "sm:-mb-16"
  | "xs:-mb-16"
  | "2xl:-mb-20"
  | "1xl:-mb-20"
  | "xl:-mb-20"
  | "lg:-mb-20"
  | "md:-mb-20"
  | "sm:-mb-20"
  | "xs:-mb-20"
  | "2xl:-mb-24"
  | "1xl:-mb-24"
  | "xl:-mb-24"
  | "lg:-mb-24"
  | "md:-mb-24"
  | "sm:-mb-24"
  | "xs:-mb-24"
  | "2xl:-mb-28"
  | "1xl:-mb-28"
  | "xl:-mb-28"
  | "lg:-mb-28"
  | "md:-mb-28"
  | "sm:-mb-28"
  | "xs:-mb-28"
  | "2xl:-mb-32"
  | "1xl:-mb-32"
  | "xl:-mb-32"
  | "lg:-mb-32"
  | "md:-mb-32"
  | "sm:-mb-32"
  | "xs:-mb-32"
  | "2xl:-mb-36"
  | "1xl:-mb-36"
  | "xl:-mb-36"
  | "lg:-mb-36"
  | "md:-mb-36"
  | "sm:-mb-36"
  | "xs:-mb-36"
  | "2xl:-mb-40"
  | "1xl:-mb-40"
  | "xl:-mb-40"
  | "lg:-mb-40"
  | "md:-mb-40"
  | "sm:-mb-40"
  | "xs:-mb-40"
  | "2xl:-mb-44"
  | "1xl:-mb-44"
  | "xl:-mb-44"
  | "lg:-mb-44"
  | "md:-mb-44"
  | "sm:-mb-44"
  | "xs:-mb-44"
  | "2xl:-mb-48"
  | "1xl:-mb-48"
  | "xl:-mb-48"
  | "lg:-mb-48"
  | "md:-mb-48"
  | "sm:-mb-48"
  | "xs:-mb-48"
  | "2xl:-mb-52"
  | "1xl:-mb-52"
  | "xl:-mb-52"
  | "lg:-mb-52"
  | "md:-mb-52"
  | "sm:-mb-52"
  | "xs:-mb-52"
  | "2xl:-mb-56"
  | "1xl:-mb-56"
  | "xl:-mb-56"
  | "lg:-mb-56"
  | "md:-mb-56"
  | "sm:-mb-56"
  | "xs:-mb-56"
  | "2xl:-mb-60"
  | "1xl:-mb-60"
  | "xl:-mb-60"
  | "lg:-mb-60"
  | "md:-mb-60"
  | "sm:-mb-60"
  | "xs:-mb-60"
  | "2xl:-mb-64"
  | "1xl:-mb-64"
  | "xl:-mb-64"
  | "lg:-mb-64"
  | "md:-mb-64"
  | "sm:-mb-64"
  | "xs:-mb-64"
  | "2xl:-mb-72"
  | "1xl:-mb-72"
  | "xl:-mb-72"
  | "lg:-mb-72"
  | "md:-mb-72"
  | "sm:-mb-72"
  | "xs:-mb-72"
  | "2xl:-mb-80"
  | "1xl:-mb-80"
  | "xl:-mb-80"
  | "lg:-mb-80"
  | "md:-mb-80"
  | "sm:-mb-80"
  | "xs:-mb-80"
  | "2xl:-mb-96"
  | "1xl:-mb-96"
  | "xl:-mb-96"
  | "lg:-mb-96"
  | "md:-mb-96"
  | "sm:-mb-96"
  | "xs:-mb-96"
  | "2xl:-mb-px"
  | "1xl:-mb-px"
  | "xl:-mb-px"
  | "lg:-mb-px"
  | "md:-mb-px"
  | "sm:-mb-px"
  | "xs:-mb-px"
  | "2xl:-mb-0.5"
  | "1xl:-mb-0.5"
  | "xl:-mb-0.5"
  | "lg:-mb-0.5"
  | "md:-mb-0.5"
  | "sm:-mb-0.5"
  | "xs:-mb-0.5"
  | "2xl:-mb-1.5"
  | "1xl:-mb-1.5"
  | "xl:-mb-1.5"
  | "lg:-mb-1.5"
  | "md:-mb-1.5"
  | "sm:-mb-1.5"
  | "xs:-mb-1.5"
  | "2xl:-mb-2.5"
  | "1xl:-mb-2.5"
  | "xl:-mb-2.5"
  | "lg:-mb-2.5"
  | "md:-mb-2.5"
  | "sm:-mb-2.5"
  | "xs:-mb-2.5"
  | "2xl:-mb-3.5"
  | "1xl:-mb-3.5"
  | "xl:-mb-3.5"
  | "lg:-mb-3.5"
  | "md:-mb-3.5"
  | "sm:-mb-3.5"
  | "xs:-mb-3.5"
  | "2xl:ml-0"
  | "1xl:ml-0"
  | "xl:ml-0"
  | "lg:ml-0"
  | "md:ml-0"
  | "sm:ml-0"
  | "xs:ml-0"
  | "2xl:ml-1"
  | "1xl:ml-1"
  | "xl:ml-1"
  | "lg:ml-1"
  | "md:ml-1"
  | "sm:ml-1"
  | "xs:ml-1"
  | "2xl:ml-2"
  | "1xl:ml-2"
  | "xl:ml-2"
  | "lg:ml-2"
  | "md:ml-2"
  | "sm:ml-2"
  | "xs:ml-2"
  | "2xl:ml-3"
  | "1xl:ml-3"
  | "xl:ml-3"
  | "lg:ml-3"
  | "md:ml-3"
  | "sm:ml-3"
  | "xs:ml-3"
  | "2xl:ml-4"
  | "1xl:ml-4"
  | "xl:ml-4"
  | "lg:ml-4"
  | "md:ml-4"
  | "sm:ml-4"
  | "xs:ml-4"
  | "2xl:ml-5"
  | "1xl:ml-5"
  | "xl:ml-5"
  | "lg:ml-5"
  | "md:ml-5"
  | "sm:ml-5"
  | "xs:ml-5"
  | "2xl:ml-6"
  | "1xl:ml-6"
  | "xl:ml-6"
  | "lg:ml-6"
  | "md:ml-6"
  | "sm:ml-6"
  | "xs:ml-6"
  | "2xl:ml-7"
  | "1xl:ml-7"
  | "xl:ml-7"
  | "lg:ml-7"
  | "md:ml-7"
  | "sm:ml-7"
  | "xs:ml-7"
  | "2xl:ml-8"
  | "1xl:ml-8"
  | "xl:ml-8"
  | "lg:ml-8"
  | "md:ml-8"
  | "sm:ml-8"
  | "xs:ml-8"
  | "2xl:ml-9"
  | "1xl:ml-9"
  | "xl:ml-9"
  | "lg:ml-9"
  | "md:ml-9"
  | "sm:ml-9"
  | "xs:ml-9"
  | "2xl:ml-10"
  | "1xl:ml-10"
  | "xl:ml-10"
  | "lg:ml-10"
  | "md:ml-10"
  | "sm:ml-10"
  | "xs:ml-10"
  | "2xl:ml-11"
  | "1xl:ml-11"
  | "xl:ml-11"
  | "lg:ml-11"
  | "md:ml-11"
  | "sm:ml-11"
  | "xs:ml-11"
  | "2xl:ml-12"
  | "1xl:ml-12"
  | "xl:ml-12"
  | "lg:ml-12"
  | "md:ml-12"
  | "sm:ml-12"
  | "xs:ml-12"
  | "2xl:ml-14"
  | "1xl:ml-14"
  | "xl:ml-14"
  | "lg:ml-14"
  | "md:ml-14"
  | "sm:ml-14"
  | "xs:ml-14"
  | "2xl:ml-16"
  | "1xl:ml-16"
  | "xl:ml-16"
  | "lg:ml-16"
  | "md:ml-16"
  | "sm:ml-16"
  | "xs:ml-16"
  | "2xl:ml-20"
  | "1xl:ml-20"
  | "xl:ml-20"
  | "lg:ml-20"
  | "md:ml-20"
  | "sm:ml-20"
  | "xs:ml-20"
  | "2xl:ml-24"
  | "1xl:ml-24"
  | "xl:ml-24"
  | "lg:ml-24"
  | "md:ml-24"
  | "sm:ml-24"
  | "xs:ml-24"
  | "2xl:ml-28"
  | "1xl:ml-28"
  | "xl:ml-28"
  | "lg:ml-28"
  | "md:ml-28"
  | "sm:ml-28"
  | "xs:ml-28"
  | "2xl:ml-32"
  | "1xl:ml-32"
  | "xl:ml-32"
  | "lg:ml-32"
  | "md:ml-32"
  | "sm:ml-32"
  | "xs:ml-32"
  | "2xl:ml-36"
  | "1xl:ml-36"
  | "xl:ml-36"
  | "lg:ml-36"
  | "md:ml-36"
  | "sm:ml-36"
  | "xs:ml-36"
  | "2xl:ml-40"
  | "1xl:ml-40"
  | "xl:ml-40"
  | "lg:ml-40"
  | "md:ml-40"
  | "sm:ml-40"
  | "xs:ml-40"
  | "2xl:ml-44"
  | "1xl:ml-44"
  | "xl:ml-44"
  | "lg:ml-44"
  | "md:ml-44"
  | "sm:ml-44"
  | "xs:ml-44"
  | "2xl:ml-48"
  | "1xl:ml-48"
  | "xl:ml-48"
  | "lg:ml-48"
  | "md:ml-48"
  | "sm:ml-48"
  | "xs:ml-48"
  | "2xl:ml-52"
  | "1xl:ml-52"
  | "xl:ml-52"
  | "lg:ml-52"
  | "md:ml-52"
  | "sm:ml-52"
  | "xs:ml-52"
  | "2xl:ml-56"
  | "1xl:ml-56"
  | "xl:ml-56"
  | "lg:ml-56"
  | "md:ml-56"
  | "sm:ml-56"
  | "xs:ml-56"
  | "2xl:ml-60"
  | "1xl:ml-60"
  | "xl:ml-60"
  | "lg:ml-60"
  | "md:ml-60"
  | "sm:ml-60"
  | "xs:ml-60"
  | "2xl:ml-64"
  | "1xl:ml-64"
  | "xl:ml-64"
  | "lg:ml-64"
  | "md:ml-64"
  | "sm:ml-64"
  | "xs:ml-64"
  | "2xl:ml-72"
  | "1xl:ml-72"
  | "xl:ml-72"
  | "lg:ml-72"
  | "md:ml-72"
  | "sm:ml-72"
  | "xs:ml-72"
  | "2xl:ml-80"
  | "1xl:ml-80"
  | "xl:ml-80"
  | "lg:ml-80"
  | "md:ml-80"
  | "sm:ml-80"
  | "xs:ml-80"
  | "2xl:ml-96"
  | "1xl:ml-96"
  | "xl:ml-96"
  | "lg:ml-96"
  | "md:ml-96"
  | "sm:ml-96"
  | "xs:ml-96"
  | "2xl:ml-auto"
  | "1xl:ml-auto"
  | "xl:ml-auto"
  | "lg:ml-auto"
  | "md:ml-auto"
  | "sm:ml-auto"
  | "xs:ml-auto"
  | "2xl:ml-px"
  | "1xl:ml-px"
  | "xl:ml-px"
  | "lg:ml-px"
  | "md:ml-px"
  | "sm:ml-px"
  | "xs:ml-px"
  | "2xl:ml-0.5"
  | "1xl:ml-0.5"
  | "xl:ml-0.5"
  | "lg:ml-0.5"
  | "md:ml-0.5"
  | "sm:ml-0.5"
  | "xs:ml-0.5"
  | "2xl:ml-1.5"
  | "1xl:ml-1.5"
  | "xl:ml-1.5"
  | "lg:ml-1.5"
  | "md:ml-1.5"
  | "sm:ml-1.5"
  | "xs:ml-1.5"
  | "2xl:ml-2.5"
  | "1xl:ml-2.5"
  | "xl:ml-2.5"
  | "lg:ml-2.5"
  | "md:ml-2.5"
  | "sm:ml-2.5"
  | "xs:ml-2.5"
  | "2xl:ml-3.5"
  | "1xl:ml-3.5"
  | "xl:ml-3.5"
  | "lg:ml-3.5"
  | "md:ml-3.5"
  | "sm:ml-3.5"
  | "xs:ml-3.5"
  | "2xl:-ml-0"
  | "1xl:-ml-0"
  | "xl:-ml-0"
  | "lg:-ml-0"
  | "md:-ml-0"
  | "sm:-ml-0"
  | "xs:-ml-0"
  | "2xl:-ml-1"
  | "1xl:-ml-1"
  | "xl:-ml-1"
  | "lg:-ml-1"
  | "md:-ml-1"
  | "sm:-ml-1"
  | "xs:-ml-1"
  | "2xl:-ml-2"
  | "1xl:-ml-2"
  | "xl:-ml-2"
  | "lg:-ml-2"
  | "md:-ml-2"
  | "sm:-ml-2"
  | "xs:-ml-2"
  | "2xl:-ml-3"
  | "1xl:-ml-3"
  | "xl:-ml-3"
  | "lg:-ml-3"
  | "md:-ml-3"
  | "sm:-ml-3"
  | "xs:-ml-3"
  | "2xl:-ml-4"
  | "1xl:-ml-4"
  | "xl:-ml-4"
  | "lg:-ml-4"
  | "md:-ml-4"
  | "sm:-ml-4"
  | "xs:-ml-4"
  | "2xl:-ml-5"
  | "1xl:-ml-5"
  | "xl:-ml-5"
  | "lg:-ml-5"
  | "md:-ml-5"
  | "sm:-ml-5"
  | "xs:-ml-5"
  | "2xl:-ml-6"
  | "1xl:-ml-6"
  | "xl:-ml-6"
  | "lg:-ml-6"
  | "md:-ml-6"
  | "sm:-ml-6"
  | "xs:-ml-6"
  | "2xl:-ml-7"
  | "1xl:-ml-7"
  | "xl:-ml-7"
  | "lg:-ml-7"
  | "md:-ml-7"
  | "sm:-ml-7"
  | "xs:-ml-7"
  | "2xl:-ml-8"
  | "1xl:-ml-8"
  | "xl:-ml-8"
  | "lg:-ml-8"
  | "md:-ml-8"
  | "sm:-ml-8"
  | "xs:-ml-8"
  | "2xl:-ml-9"
  | "1xl:-ml-9"
  | "xl:-ml-9"
  | "lg:-ml-9"
  | "md:-ml-9"
  | "sm:-ml-9"
  | "xs:-ml-9"
  | "2xl:-ml-10"
  | "1xl:-ml-10"
  | "xl:-ml-10"
  | "lg:-ml-10"
  | "md:-ml-10"
  | "sm:-ml-10"
  | "xs:-ml-10"
  | "2xl:-ml-11"
  | "1xl:-ml-11"
  | "xl:-ml-11"
  | "lg:-ml-11"
  | "md:-ml-11"
  | "sm:-ml-11"
  | "xs:-ml-11"
  | "2xl:-ml-12"
  | "1xl:-ml-12"
  | "xl:-ml-12"
  | "lg:-ml-12"
  | "md:-ml-12"
  | "sm:-ml-12"
  | "xs:-ml-12"
  | "2xl:-ml-14"
  | "1xl:-ml-14"
  | "xl:-ml-14"
  | "lg:-ml-14"
  | "md:-ml-14"
  | "sm:-ml-14"
  | "xs:-ml-14"
  | "2xl:-ml-16"
  | "1xl:-ml-16"
  | "xl:-ml-16"
  | "lg:-ml-16"
  | "md:-ml-16"
  | "sm:-ml-16"
  | "xs:-ml-16"
  | "2xl:-ml-20"
  | "1xl:-ml-20"
  | "xl:-ml-20"
  | "lg:-ml-20"
  | "md:-ml-20"
  | "sm:-ml-20"
  | "xs:-ml-20"
  | "2xl:-ml-24"
  | "1xl:-ml-24"
  | "xl:-ml-24"
  | "lg:-ml-24"
  | "md:-ml-24"
  | "sm:-ml-24"
  | "xs:-ml-24"
  | "2xl:-ml-28"
  | "1xl:-ml-28"
  | "xl:-ml-28"
  | "lg:-ml-28"
  | "md:-ml-28"
  | "sm:-ml-28"
  | "xs:-ml-28"
  | "2xl:-ml-32"
  | "1xl:-ml-32"
  | "xl:-ml-32"
  | "lg:-ml-32"
  | "md:-ml-32"
  | "sm:-ml-32"
  | "xs:-ml-32"
  | "2xl:-ml-36"
  | "1xl:-ml-36"
  | "xl:-ml-36"
  | "lg:-ml-36"
  | "md:-ml-36"
  | "sm:-ml-36"
  | "xs:-ml-36"
  | "2xl:-ml-40"
  | "1xl:-ml-40"
  | "xl:-ml-40"
  | "lg:-ml-40"
  | "md:-ml-40"
  | "sm:-ml-40"
  | "xs:-ml-40"
  | "2xl:-ml-44"
  | "1xl:-ml-44"
  | "xl:-ml-44"
  | "lg:-ml-44"
  | "md:-ml-44"
  | "sm:-ml-44"
  | "xs:-ml-44"
  | "2xl:-ml-48"
  | "1xl:-ml-48"
  | "xl:-ml-48"
  | "lg:-ml-48"
  | "md:-ml-48"
  | "sm:-ml-48"
  | "xs:-ml-48"
  | "2xl:-ml-52"
  | "1xl:-ml-52"
  | "xl:-ml-52"
  | "lg:-ml-52"
  | "md:-ml-52"
  | "sm:-ml-52"
  | "xs:-ml-52"
  | "2xl:-ml-56"
  | "1xl:-ml-56"
  | "xl:-ml-56"
  | "lg:-ml-56"
  | "md:-ml-56"
  | "sm:-ml-56"
  | "xs:-ml-56"
  | "2xl:-ml-60"
  | "1xl:-ml-60"
  | "xl:-ml-60"
  | "lg:-ml-60"
  | "md:-ml-60"
  | "sm:-ml-60"
  | "xs:-ml-60"
  | "2xl:-ml-64"
  | "1xl:-ml-64"
  | "xl:-ml-64"
  | "lg:-ml-64"
  | "md:-ml-64"
  | "sm:-ml-64"
  | "xs:-ml-64"
  | "2xl:-ml-72"
  | "1xl:-ml-72"
  | "xl:-ml-72"
  | "lg:-ml-72"
  | "md:-ml-72"
  | "sm:-ml-72"
  | "xs:-ml-72"
  | "2xl:-ml-80"
  | "1xl:-ml-80"
  | "xl:-ml-80"
  | "lg:-ml-80"
  | "md:-ml-80"
  | "sm:-ml-80"
  | "xs:-ml-80"
  | "2xl:-ml-96"
  | "1xl:-ml-96"
  | "xl:-ml-96"
  | "lg:-ml-96"
  | "md:-ml-96"
  | "sm:-ml-96"
  | "xs:-ml-96"
  | "2xl:-ml-px"
  | "1xl:-ml-px"
  | "xl:-ml-px"
  | "lg:-ml-px"
  | "md:-ml-px"
  | "sm:-ml-px"
  | "xs:-ml-px"
  | "2xl:-ml-0.5"
  | "1xl:-ml-0.5"
  | "xl:-ml-0.5"
  | "lg:-ml-0.5"
  | "md:-ml-0.5"
  | "sm:-ml-0.5"
  | "xs:-ml-0.5"
  | "2xl:-ml-1.5"
  | "1xl:-ml-1.5"
  | "xl:-ml-1.5"
  | "lg:-ml-1.5"
  | "md:-ml-1.5"
  | "sm:-ml-1.5"
  | "xs:-ml-1.5"
  | "2xl:-ml-2.5"
  | "1xl:-ml-2.5"
  | "xl:-ml-2.5"
  | "lg:-ml-2.5"
  | "md:-ml-2.5"
  | "sm:-ml-2.5"
  | "xs:-ml-2.5"
  | "2xl:-ml-3.5"
  | "1xl:-ml-3.5"
  | "xl:-ml-3.5"
  | "lg:-ml-3.5"
  | "md:-ml-3.5"
  | "sm:-ml-3.5"
  | "xs:-ml-3.5"
  | "2xl:max-h-0"
  | "1xl:max-h-0"
  | "xl:max-h-0"
  | "lg:max-h-0"
  | "md:max-h-0"
  | "sm:max-h-0"
  | "xs:max-h-0"
  | "2xl:max-h-1"
  | "1xl:max-h-1"
  | "xl:max-h-1"
  | "lg:max-h-1"
  | "md:max-h-1"
  | "sm:max-h-1"
  | "xs:max-h-1"
  | "2xl:max-h-2"
  | "1xl:max-h-2"
  | "xl:max-h-2"
  | "lg:max-h-2"
  | "md:max-h-2"
  | "sm:max-h-2"
  | "xs:max-h-2"
  | "2xl:max-h-3"
  | "1xl:max-h-3"
  | "xl:max-h-3"
  | "lg:max-h-3"
  | "md:max-h-3"
  | "sm:max-h-3"
  | "xs:max-h-3"
  | "2xl:max-h-4"
  | "1xl:max-h-4"
  | "xl:max-h-4"
  | "lg:max-h-4"
  | "md:max-h-4"
  | "sm:max-h-4"
  | "xs:max-h-4"
  | "2xl:max-h-5"
  | "1xl:max-h-5"
  | "xl:max-h-5"
  | "lg:max-h-5"
  | "md:max-h-5"
  | "sm:max-h-5"
  | "xs:max-h-5"
  | "2xl:max-h-6"
  | "1xl:max-h-6"
  | "xl:max-h-6"
  | "lg:max-h-6"
  | "md:max-h-6"
  | "sm:max-h-6"
  | "xs:max-h-6"
  | "2xl:max-h-7"
  | "1xl:max-h-7"
  | "xl:max-h-7"
  | "lg:max-h-7"
  | "md:max-h-7"
  | "sm:max-h-7"
  | "xs:max-h-7"
  | "2xl:max-h-8"
  | "1xl:max-h-8"
  | "xl:max-h-8"
  | "lg:max-h-8"
  | "md:max-h-8"
  | "sm:max-h-8"
  | "xs:max-h-8"
  | "2xl:max-h-9"
  | "1xl:max-h-9"
  | "xl:max-h-9"
  | "lg:max-h-9"
  | "md:max-h-9"
  | "sm:max-h-9"
  | "xs:max-h-9"
  | "2xl:max-h-10"
  | "1xl:max-h-10"
  | "xl:max-h-10"
  | "lg:max-h-10"
  | "md:max-h-10"
  | "sm:max-h-10"
  | "xs:max-h-10"
  | "2xl:max-h-11"
  | "1xl:max-h-11"
  | "xl:max-h-11"
  | "lg:max-h-11"
  | "md:max-h-11"
  | "sm:max-h-11"
  | "xs:max-h-11"
  | "2xl:max-h-12"
  | "1xl:max-h-12"
  | "xl:max-h-12"
  | "lg:max-h-12"
  | "md:max-h-12"
  | "sm:max-h-12"
  | "xs:max-h-12"
  | "2xl:max-h-14"
  | "1xl:max-h-14"
  | "xl:max-h-14"
  | "lg:max-h-14"
  | "md:max-h-14"
  | "sm:max-h-14"
  | "xs:max-h-14"
  | "2xl:max-h-16"
  | "1xl:max-h-16"
  | "xl:max-h-16"
  | "lg:max-h-16"
  | "md:max-h-16"
  | "sm:max-h-16"
  | "xs:max-h-16"
  | "2xl:max-h-20"
  | "1xl:max-h-20"
  | "xl:max-h-20"
  | "lg:max-h-20"
  | "md:max-h-20"
  | "sm:max-h-20"
  | "xs:max-h-20"
  | "2xl:max-h-24"
  | "1xl:max-h-24"
  | "xl:max-h-24"
  | "lg:max-h-24"
  | "md:max-h-24"
  | "sm:max-h-24"
  | "xs:max-h-24"
  | "2xl:max-h-28"
  | "1xl:max-h-28"
  | "xl:max-h-28"
  | "lg:max-h-28"
  | "md:max-h-28"
  | "sm:max-h-28"
  | "xs:max-h-28"
  | "2xl:max-h-32"
  | "1xl:max-h-32"
  | "xl:max-h-32"
  | "lg:max-h-32"
  | "md:max-h-32"
  | "sm:max-h-32"
  | "xs:max-h-32"
  | "2xl:max-h-36"
  | "1xl:max-h-36"
  | "xl:max-h-36"
  | "lg:max-h-36"
  | "md:max-h-36"
  | "sm:max-h-36"
  | "xs:max-h-36"
  | "2xl:max-h-40"
  | "1xl:max-h-40"
  | "xl:max-h-40"
  | "lg:max-h-40"
  | "md:max-h-40"
  | "sm:max-h-40"
  | "xs:max-h-40"
  | "2xl:max-h-44"
  | "1xl:max-h-44"
  | "xl:max-h-44"
  | "lg:max-h-44"
  | "md:max-h-44"
  | "sm:max-h-44"
  | "xs:max-h-44"
  | "2xl:max-h-48"
  | "1xl:max-h-48"
  | "xl:max-h-48"
  | "lg:max-h-48"
  | "md:max-h-48"
  | "sm:max-h-48"
  | "xs:max-h-48"
  | "2xl:max-h-52"
  | "1xl:max-h-52"
  | "xl:max-h-52"
  | "lg:max-h-52"
  | "md:max-h-52"
  | "sm:max-h-52"
  | "xs:max-h-52"
  | "2xl:max-h-56"
  | "1xl:max-h-56"
  | "xl:max-h-56"
  | "lg:max-h-56"
  | "md:max-h-56"
  | "sm:max-h-56"
  | "xs:max-h-56"
  | "2xl:max-h-60"
  | "1xl:max-h-60"
  | "xl:max-h-60"
  | "lg:max-h-60"
  | "md:max-h-60"
  | "sm:max-h-60"
  | "xs:max-h-60"
  | "2xl:max-h-64"
  | "1xl:max-h-64"
  | "xl:max-h-64"
  | "lg:max-h-64"
  | "md:max-h-64"
  | "sm:max-h-64"
  | "xs:max-h-64"
  | "2xl:max-h-72"
  | "1xl:max-h-72"
  | "xl:max-h-72"
  | "lg:max-h-72"
  | "md:max-h-72"
  | "sm:max-h-72"
  | "xs:max-h-72"
  | "2xl:max-h-80"
  | "1xl:max-h-80"
  | "xl:max-h-80"
  | "lg:max-h-80"
  | "md:max-h-80"
  | "sm:max-h-80"
  | "xs:max-h-80"
  | "2xl:max-h-96"
  | "1xl:max-h-96"
  | "xl:max-h-96"
  | "lg:max-h-96"
  | "md:max-h-96"
  | "sm:max-h-96"
  | "xs:max-h-96"
  | "2xl:max-h-px"
  | "1xl:max-h-px"
  | "xl:max-h-px"
  | "lg:max-h-px"
  | "md:max-h-px"
  | "sm:max-h-px"
  | "xs:max-h-px"
  | "2xl:max-h-0.5"
  | "1xl:max-h-0.5"
  | "xl:max-h-0.5"
  | "lg:max-h-0.5"
  | "md:max-h-0.5"
  | "sm:max-h-0.5"
  | "xs:max-h-0.5"
  | "2xl:max-h-1.5"
  | "1xl:max-h-1.5"
  | "xl:max-h-1.5"
  | "lg:max-h-1.5"
  | "md:max-h-1.5"
  | "sm:max-h-1.5"
  | "xs:max-h-1.5"
  | "2xl:max-h-2.5"
  | "1xl:max-h-2.5"
  | "xl:max-h-2.5"
  | "lg:max-h-2.5"
  | "md:max-h-2.5"
  | "sm:max-h-2.5"
  | "xs:max-h-2.5"
  | "2xl:max-h-3.5"
  | "1xl:max-h-3.5"
  | "xl:max-h-3.5"
  | "lg:max-h-3.5"
  | "md:max-h-3.5"
  | "sm:max-h-3.5"
  | "xs:max-h-3.5"
  | "2xl:max-h-full"
  | "1xl:max-h-full"
  | "xl:max-h-full"
  | "lg:max-h-full"
  | "md:max-h-full"
  | "sm:max-h-full"
  | "xs:max-h-full"
  | "2xl:max-h-screen"
  | "1xl:max-h-screen"
  | "xl:max-h-screen"
  | "lg:max-h-screen"
  | "md:max-h-screen"
  | "sm:max-h-screen"
  | "xs:max-h-screen"
  | "2xl:max-h-full-without-header"
  | "1xl:max-h-full-without-header"
  | "xl:max-h-full-without-header"
  | "lg:max-h-full-without-header"
  | "md:max-h-full-without-header"
  | "sm:max-h-full-without-header"
  | "xs:max-h-full-without-header"
  | "2xl:max-w-0"
  | "1xl:max-w-0"
  | "xl:max-w-0"
  | "lg:max-w-0"
  | "md:max-w-0"
  | "sm:max-w-0"
  | "xs:max-w-0"
  | "2xl:max-w-10"
  | "1xl:max-w-10"
  | "xl:max-w-10"
  | "lg:max-w-10"
  | "md:max-w-10"
  | "sm:max-w-10"
  | "xs:max-w-10"
  | "2xl:max-w-20"
  | "1xl:max-w-20"
  | "xl:max-w-20"
  | "lg:max-w-20"
  | "md:max-w-20"
  | "sm:max-w-20"
  | "xs:max-w-20"
  | "2xl:max-w-30"
  | "1xl:max-w-30"
  | "xl:max-w-30"
  | "lg:max-w-30"
  | "md:max-w-30"
  | "sm:max-w-30"
  | "xs:max-w-30"
  | "2xl:max-w-40"
  | "1xl:max-w-40"
  | "xl:max-w-40"
  | "lg:max-w-40"
  | "md:max-w-40"
  | "sm:max-w-40"
  | "xs:max-w-40"
  | "2xl:max-w-50"
  | "1xl:max-w-50"
  | "xl:max-w-50"
  | "lg:max-w-50"
  | "md:max-w-50"
  | "sm:max-w-50"
  | "xs:max-w-50"
  | "2xl:max-w-60"
  | "1xl:max-w-60"
  | "xl:max-w-60"
  | "lg:max-w-60"
  | "md:max-w-60"
  | "sm:max-w-60"
  | "xs:max-w-60"
  | "2xl:max-w-70"
  | "1xl:max-w-70"
  | "xl:max-w-70"
  | "lg:max-w-70"
  | "md:max-w-70"
  | "sm:max-w-70"
  | "xs:max-w-70"
  | "2xl:max-w-80"
  | "1xl:max-w-80"
  | "xl:max-w-80"
  | "lg:max-w-80"
  | "md:max-w-80"
  | "sm:max-w-80"
  | "xs:max-w-80"
  | "2xl:max-w-90"
  | "1xl:max-w-90"
  | "xl:max-w-90"
  | "lg:max-w-90"
  | "md:max-w-90"
  | "sm:max-w-90"
  | "xs:max-w-90"
  | "2xl:max-w-none"
  | "1xl:max-w-none"
  | "xl:max-w-none"
  | "lg:max-w-none"
  | "md:max-w-none"
  | "sm:max-w-none"
  | "xs:max-w-none"
  | "2xl:max-w-xs"
  | "1xl:max-w-xs"
  | "xl:max-w-xs"
  | "lg:max-w-xs"
  | "md:max-w-xs"
  | "sm:max-w-xs"
  | "xs:max-w-xs"
  | "2xl:max-w-sm"
  | "1xl:max-w-sm"
  | "xl:max-w-sm"
  | "lg:max-w-sm"
  | "md:max-w-sm"
  | "sm:max-w-sm"
  | "xs:max-w-sm"
  | "2xl:max-w-md"
  | "1xl:max-w-md"
  | "xl:max-w-md"
  | "lg:max-w-md"
  | "md:max-w-md"
  | "sm:max-w-md"
  | "xs:max-w-md"
  | "2xl:max-w-lg"
  | "1xl:max-w-lg"
  | "xl:max-w-lg"
  | "lg:max-w-lg"
  | "md:max-w-lg"
  | "sm:max-w-lg"
  | "xs:max-w-lg"
  | "2xl:max-w-xl"
  | "1xl:max-w-xl"
  | "xl:max-w-xl"
  | "lg:max-w-xl"
  | "md:max-w-xl"
  | "sm:max-w-xl"
  | "xs:max-w-xl"
  | "2xl:max-w-2xl"
  | "1xl:max-w-2xl"
  | "xl:max-w-2xl"
  | "lg:max-w-2xl"
  | "md:max-w-2xl"
  | "sm:max-w-2xl"
  | "xs:max-w-2xl"
  | "2xl:max-w-3xl"
  | "1xl:max-w-3xl"
  | "xl:max-w-3xl"
  | "lg:max-w-3xl"
  | "md:max-w-3xl"
  | "sm:max-w-3xl"
  | "xs:max-w-3xl"
  | "2xl:max-w-4xl"
  | "1xl:max-w-4xl"
  | "xl:max-w-4xl"
  | "lg:max-w-4xl"
  | "md:max-w-4xl"
  | "sm:max-w-4xl"
  | "xs:max-w-4xl"
  | "2xl:max-w-5xl"
  | "1xl:max-w-5xl"
  | "xl:max-w-5xl"
  | "lg:max-w-5xl"
  | "md:max-w-5xl"
  | "sm:max-w-5xl"
  | "xs:max-w-5xl"
  | "2xl:max-w-6xl"
  | "1xl:max-w-6xl"
  | "xl:max-w-6xl"
  | "lg:max-w-6xl"
  | "md:max-w-6xl"
  | "sm:max-w-6xl"
  | "xs:max-w-6xl"
  | "2xl:max-w-7xl"
  | "1xl:max-w-7xl"
  | "xl:max-w-7xl"
  | "lg:max-w-7xl"
  | "md:max-w-7xl"
  | "sm:max-w-7xl"
  | "xs:max-w-7xl"
  | "2xl:max-w-full"
  | "1xl:max-w-full"
  | "xl:max-w-full"
  | "lg:max-w-full"
  | "md:max-w-full"
  | "sm:max-w-full"
  | "xs:max-w-full"
  | "2xl:max-w-min"
  | "1xl:max-w-min"
  | "xl:max-w-min"
  | "lg:max-w-min"
  | "md:max-w-min"
  | "sm:max-w-min"
  | "xs:max-w-min"
  | "2xl:max-w-max"
  | "1xl:max-w-max"
  | "xl:max-w-max"
  | "lg:max-w-max"
  | "md:max-w-max"
  | "sm:max-w-max"
  | "xs:max-w-max"
  | "2xl:max-w-prose"
  | "1xl:max-w-prose"
  | "xl:max-w-prose"
  | "lg:max-w-prose"
  | "md:max-w-prose"
  | "sm:max-w-prose"
  | "xs:max-w-prose"
  | "2xl:max-w-screen-2xl"
  | "1xl:max-w-screen-2xl"
  | "xl:max-w-screen-2xl"
  | "lg:max-w-screen-2xl"
  | "md:max-w-screen-2xl"
  | "sm:max-w-screen-2xl"
  | "xs:max-w-screen-2xl"
  | "2xl:max-w-screen-1xl"
  | "1xl:max-w-screen-1xl"
  | "xl:max-w-screen-1xl"
  | "lg:max-w-screen-1xl"
  | "md:max-w-screen-1xl"
  | "sm:max-w-screen-1xl"
  | "xs:max-w-screen-1xl"
  | "2xl:max-w-screen-xl"
  | "1xl:max-w-screen-xl"
  | "xl:max-w-screen-xl"
  | "lg:max-w-screen-xl"
  | "md:max-w-screen-xl"
  | "sm:max-w-screen-xl"
  | "xs:max-w-screen-xl"
  | "2xl:max-w-screen-lg"
  | "1xl:max-w-screen-lg"
  | "xl:max-w-screen-lg"
  | "lg:max-w-screen-lg"
  | "md:max-w-screen-lg"
  | "sm:max-w-screen-lg"
  | "xs:max-w-screen-lg"
  | "2xl:max-w-screen-md"
  | "1xl:max-w-screen-md"
  | "xl:max-w-screen-md"
  | "lg:max-w-screen-md"
  | "md:max-w-screen-md"
  | "sm:max-w-screen-md"
  | "xs:max-w-screen-md"
  | "2xl:max-w-screen-sm"
  | "1xl:max-w-screen-sm"
  | "xl:max-w-screen-sm"
  | "lg:max-w-screen-sm"
  | "md:max-w-screen-sm"
  | "sm:max-w-screen-sm"
  | "xs:max-w-screen-sm"
  | "2xl:max-w-screen-xs"
  | "1xl:max-w-screen-xs"
  | "xl:max-w-screen-xs"
  | "lg:max-w-screen-xs"
  | "md:max-w-screen-xs"
  | "sm:max-w-screen-xs"
  | "xs:max-w-screen-xs"
  | "2xl:min-h-0"
  | "1xl:min-h-0"
  | "xl:min-h-0"
  | "lg:min-h-0"
  | "md:min-h-0"
  | "sm:min-h-0"
  | "xs:min-h-0"
  | "2xl:min-h-4"
  | "1xl:min-h-4"
  | "xl:min-h-4"
  | "lg:min-h-4"
  | "md:min-h-4"
  | "sm:min-h-4"
  | "xs:min-h-4"
  | "2xl:min-h-8"
  | "1xl:min-h-8"
  | "xl:min-h-8"
  | "lg:min-h-8"
  | "md:min-h-8"
  | "sm:min-h-8"
  | "xs:min-h-8"
  | "2xl:min-h-12"
  | "1xl:min-h-12"
  | "xl:min-h-12"
  | "lg:min-h-12"
  | "md:min-h-12"
  | "sm:min-h-12"
  | "xs:min-h-12"
  | "2xl:min-h-16"
  | "1xl:min-h-16"
  | "xl:min-h-16"
  | "lg:min-h-16"
  | "md:min-h-16"
  | "sm:min-h-16"
  | "xs:min-h-16"
  | "2xl:min-h-20"
  | "1xl:min-h-20"
  | "xl:min-h-20"
  | "lg:min-h-20"
  | "md:min-h-20"
  | "sm:min-h-20"
  | "xs:min-h-20"
  | "2xl:min-h-24"
  | "1xl:min-h-24"
  | "xl:min-h-24"
  | "lg:min-h-24"
  | "md:min-h-24"
  | "sm:min-h-24"
  | "xs:min-h-24"
  | "2xl:min-h-36"
  | "1xl:min-h-36"
  | "xl:min-h-36"
  | "lg:min-h-36"
  | "md:min-h-36"
  | "sm:min-h-36"
  | "xs:min-h-36"
  | "2xl:min-h-40"
  | "1xl:min-h-40"
  | "xl:min-h-40"
  | "lg:min-h-40"
  | "md:min-h-40"
  | "sm:min-h-40"
  | "xs:min-h-40"
  | "2xl:min-h-44"
  | "1xl:min-h-44"
  | "xl:min-h-44"
  | "lg:min-h-44"
  | "md:min-h-44"
  | "sm:min-h-44"
  | "xs:min-h-44"
  | "2xl:min-h-48"
  | "1xl:min-h-48"
  | "xl:min-h-48"
  | "lg:min-h-48"
  | "md:min-h-48"
  | "sm:min-h-48"
  | "xs:min-h-48"
  | "2xl:min-h-52"
  | "1xl:min-h-52"
  | "xl:min-h-52"
  | "lg:min-h-52"
  | "md:min-h-52"
  | "sm:min-h-52"
  | "xs:min-h-52"
  | "2xl:min-h-56"
  | "1xl:min-h-56"
  | "xl:min-h-56"
  | "lg:min-h-56"
  | "md:min-h-56"
  | "sm:min-h-56"
  | "xs:min-h-56"
  | "2xl:min-h-60"
  | "1xl:min-h-60"
  | "xl:min-h-60"
  | "lg:min-h-60"
  | "md:min-h-60"
  | "sm:min-h-60"
  | "xs:min-h-60"
  | "2xl:min-h-64"
  | "1xl:min-h-64"
  | "xl:min-h-64"
  | "lg:min-h-64"
  | "md:min-h-64"
  | "sm:min-h-64"
  | "xs:min-h-64"
  | "2xl:min-h-72"
  | "1xl:min-h-72"
  | "xl:min-h-72"
  | "lg:min-h-72"
  | "md:min-h-72"
  | "sm:min-h-72"
  | "xs:min-h-72"
  | "2xl:min-h-80"
  | "1xl:min-h-80"
  | "xl:min-h-80"
  | "lg:min-h-80"
  | "md:min-h-80"
  | "sm:min-h-80"
  | "xs:min-h-80"
  | "2xl:min-h-96"
  | "1xl:min-h-96"
  | "xl:min-h-96"
  | "lg:min-h-96"
  | "md:min-h-96"
  | "sm:min-h-96"
  | "xs:min-h-96"
  | "2xl:min-h-full"
  | "1xl:min-h-full"
  | "xl:min-h-full"
  | "lg:min-h-full"
  | "md:min-h-full"
  | "sm:min-h-full"
  | "xs:min-h-full"
  | "2xl:min-h-screen"
  | "1xl:min-h-screen"
  | "xl:min-h-screen"
  | "lg:min-h-screen"
  | "md:min-h-screen"
  | "sm:min-h-screen"
  | "xs:min-h-screen"
  | "2xl:min-h-1/2"
  | "1xl:min-h-1/2"
  | "xl:min-h-1/2"
  | "lg:min-h-1/2"
  | "md:min-h-1/2"
  | "sm:min-h-1/2"
  | "xs:min-h-1/2"
  | "2xl:min-h-1/4"
  | "1xl:min-h-1/4"
  | "xl:min-h-1/4"
  | "lg:min-h-1/4"
  | "md:min-h-1/4"
  | "sm:min-h-1/4"
  | "xs:min-h-1/4"
  | "2xl:min-h-3/4"
  | "1xl:min-h-3/4"
  | "xl:min-h-3/4"
  | "lg:min-h-3/4"
  | "md:min-h-3/4"
  | "sm:min-h-3/4"
  | "xs:min-h-3/4"
  | "2xl:min-h-vh-1/2"
  | "1xl:min-h-vh-1/2"
  | "xl:min-h-vh-1/2"
  | "lg:min-h-vh-1/2"
  | "md:min-h-vh-1/2"
  | "sm:min-h-vh-1/2"
  | "xs:min-h-vh-1/2"
  | "2xl:min-h-vh-1/4"
  | "1xl:min-h-vh-1/4"
  | "xl:min-h-vh-1/4"
  | "lg:min-h-vh-1/4"
  | "md:min-h-vh-1/4"
  | "sm:min-h-vh-1/4"
  | "xs:min-h-vh-1/4"
  | "2xl:min-h-vh-3/4"
  | "1xl:min-h-vh-3/4"
  | "xl:min-h-vh-3/4"
  | "lg:min-h-vh-3/4"
  | "md:min-h-vh-3/4"
  | "sm:min-h-vh-3/4"
  | "xs:min-h-vh-3/4"
  | "2xl:min-w-0"
  | "1xl:min-w-0"
  | "xl:min-w-0"
  | "lg:min-w-0"
  | "md:min-w-0"
  | "sm:min-w-0"
  | "xs:min-w-0"
  | "2xl:min-w-40"
  | "1xl:min-w-40"
  | "xl:min-w-40"
  | "lg:min-w-40"
  | "md:min-w-40"
  | "sm:min-w-40"
  | "xs:min-w-40"
  | "2xl:min-w-44"
  | "1xl:min-w-44"
  | "xl:min-w-44"
  | "lg:min-w-44"
  | "md:min-w-44"
  | "sm:min-w-44"
  | "xs:min-w-44"
  | "2xl:min-w-48"
  | "1xl:min-w-48"
  | "xl:min-w-48"
  | "lg:min-w-48"
  | "md:min-w-48"
  | "sm:min-w-48"
  | "xs:min-w-48"
  | "2xl:min-w-52"
  | "1xl:min-w-52"
  | "xl:min-w-52"
  | "lg:min-w-52"
  | "md:min-w-52"
  | "sm:min-w-52"
  | "xs:min-w-52"
  | "2xl:min-w-56"
  | "1xl:min-w-56"
  | "xl:min-w-56"
  | "lg:min-w-56"
  | "md:min-w-56"
  | "sm:min-w-56"
  | "xs:min-w-56"
  | "2xl:min-w-60"
  | "1xl:min-w-60"
  | "xl:min-w-60"
  | "lg:min-w-60"
  | "md:min-w-60"
  | "sm:min-w-60"
  | "xs:min-w-60"
  | "2xl:min-w-64"
  | "1xl:min-w-64"
  | "xl:min-w-64"
  | "lg:min-w-64"
  | "md:min-w-64"
  | "sm:min-w-64"
  | "xs:min-w-64"
  | "2xl:min-w-72"
  | "1xl:min-w-72"
  | "xl:min-w-72"
  | "lg:min-w-72"
  | "md:min-w-72"
  | "sm:min-w-72"
  | "xs:min-w-72"
  | "2xl:min-w-80"
  | "1xl:min-w-80"
  | "xl:min-w-80"
  | "lg:min-w-80"
  | "md:min-w-80"
  | "sm:min-w-80"
  | "xs:min-w-80"
  | "2xl:min-w-96"
  | "1xl:min-w-96"
  | "xl:min-w-96"
  | "lg:min-w-96"
  | "md:min-w-96"
  | "sm:min-w-96"
  | "xs:min-w-96"
  | "2xl:min-w-112"
  | "1xl:min-w-112"
  | "xl:min-w-112"
  | "lg:min-w-112"
  | "md:min-w-112"
  | "sm:min-w-112"
  | "xs:min-w-112"
  | "2xl:min-w-128"
  | "1xl:min-w-128"
  | "xl:min-w-128"
  | "lg:min-w-128"
  | "md:min-w-128"
  | "sm:min-w-128"
  | "xs:min-w-128"
  | "2xl:min-w-144"
  | "1xl:min-w-144"
  | "xl:min-w-144"
  | "lg:min-w-144"
  | "md:min-w-144"
  | "sm:min-w-144"
  | "xs:min-w-144"
  | "2xl:min-w-160"
  | "1xl:min-w-160"
  | "xl:min-w-160"
  | "lg:min-w-160"
  | "md:min-w-160"
  | "sm:min-w-160"
  | "xs:min-w-160"
  | "2xl:min-w-280"
  | "1xl:min-w-280"
  | "xl:min-w-280"
  | "lg:min-w-280"
  | "md:min-w-280"
  | "sm:min-w-280"
  | "xs:min-w-280"
  | "2xl:min-w-full"
  | "1xl:min-w-full"
  | "xl:min-w-full"
  | "lg:min-w-full"
  | "md:min-w-full"
  | "sm:min-w-full"
  | "xs:min-w-full"
  | "2xl:min-w-min"
  | "1xl:min-w-min"
  | "xl:min-w-min"
  | "lg:min-w-min"
  | "md:min-w-min"
  | "sm:min-w-min"
  | "xs:min-w-min"
  | "2xl:min-w-max"
  | "1xl:min-w-max"
  | "xl:min-w-max"
  | "lg:min-w-max"
  | "md:min-w-max"
  | "sm:min-w-max"
  | "xs:min-w-max"
  | "2xl:min-w-vw-1/2"
  | "1xl:min-w-vw-1/2"
  | "xl:min-w-vw-1/2"
  | "lg:min-w-vw-1/2"
  | "md:min-w-vw-1/2"
  | "sm:min-w-vw-1/2"
  | "xs:min-w-vw-1/2"
  | "2xl:min-w-vw-1/4"
  | "1xl:min-w-vw-1/4"
  | "xl:min-w-vw-1/4"
  | "lg:min-w-vw-1/4"
  | "md:min-w-vw-1/4"
  | "sm:min-w-vw-1/4"
  | "xs:min-w-vw-1/4"
  | "2xl:min-w-vw-3/4"
  | "1xl:min-w-vw-3/4"
  | "xl:min-w-vw-3/4"
  | "lg:min-w-vw-3/4"
  | "md:min-w-vw-3/4"
  | "sm:min-w-vw-3/4"
  | "xs:min-w-vw-3/4"
  | "2xl:mix-blend-normal"
  | "1xl:mix-blend-normal"
  | "xl:mix-blend-normal"
  | "lg:mix-blend-normal"
  | "md:mix-blend-normal"
  | "sm:mix-blend-normal"
  | "xs:mix-blend-normal"
  | "2xl:mix-blend-multiply"
  | "1xl:mix-blend-multiply"
  | "xl:mix-blend-multiply"
  | "lg:mix-blend-multiply"
  | "md:mix-blend-multiply"
  | "sm:mix-blend-multiply"
  | "xs:mix-blend-multiply"
  | "2xl:mix-blend-screen"
  | "1xl:mix-blend-screen"
  | "xl:mix-blend-screen"
  | "lg:mix-blend-screen"
  | "md:mix-blend-screen"
  | "sm:mix-blend-screen"
  | "xs:mix-blend-screen"
  | "2xl:mix-blend-overlay"
  | "1xl:mix-blend-overlay"
  | "xl:mix-blend-overlay"
  | "lg:mix-blend-overlay"
  | "md:mix-blend-overlay"
  | "sm:mix-blend-overlay"
  | "xs:mix-blend-overlay"
  | "2xl:mix-blend-darken"
  | "1xl:mix-blend-darken"
  | "xl:mix-blend-darken"
  | "lg:mix-blend-darken"
  | "md:mix-blend-darken"
  | "sm:mix-blend-darken"
  | "xs:mix-blend-darken"
  | "2xl:mix-blend-lighten"
  | "1xl:mix-blend-lighten"
  | "xl:mix-blend-lighten"
  | "lg:mix-blend-lighten"
  | "md:mix-blend-lighten"
  | "sm:mix-blend-lighten"
  | "xs:mix-blend-lighten"
  | "2xl:mix-blend-color-dodge"
  | "1xl:mix-blend-color-dodge"
  | "xl:mix-blend-color-dodge"
  | "lg:mix-blend-color-dodge"
  | "md:mix-blend-color-dodge"
  | "sm:mix-blend-color-dodge"
  | "xs:mix-blend-color-dodge"
  | "2xl:mix-blend-color-burn"
  | "1xl:mix-blend-color-burn"
  | "xl:mix-blend-color-burn"
  | "lg:mix-blend-color-burn"
  | "md:mix-blend-color-burn"
  | "sm:mix-blend-color-burn"
  | "xs:mix-blend-color-burn"
  | "2xl:mix-blend-hard-light"
  | "1xl:mix-blend-hard-light"
  | "xl:mix-blend-hard-light"
  | "lg:mix-blend-hard-light"
  | "md:mix-blend-hard-light"
  | "sm:mix-blend-hard-light"
  | "xs:mix-blend-hard-light"
  | "2xl:mix-blend-soft-light"
  | "1xl:mix-blend-soft-light"
  | "xl:mix-blend-soft-light"
  | "lg:mix-blend-soft-light"
  | "md:mix-blend-soft-light"
  | "sm:mix-blend-soft-light"
  | "xs:mix-blend-soft-light"
  | "2xl:mix-blend-difference"
  | "1xl:mix-blend-difference"
  | "xl:mix-blend-difference"
  | "lg:mix-blend-difference"
  | "md:mix-blend-difference"
  | "sm:mix-blend-difference"
  | "xs:mix-blend-difference"
  | "2xl:mix-blend-exclusion"
  | "1xl:mix-blend-exclusion"
  | "xl:mix-blend-exclusion"
  | "lg:mix-blend-exclusion"
  | "md:mix-blend-exclusion"
  | "sm:mix-blend-exclusion"
  | "xs:mix-blend-exclusion"
  | "2xl:mix-blend-hue"
  | "1xl:mix-blend-hue"
  | "xl:mix-blend-hue"
  | "lg:mix-blend-hue"
  | "md:mix-blend-hue"
  | "sm:mix-blend-hue"
  | "xs:mix-blend-hue"
  | "2xl:mix-blend-saturation"
  | "1xl:mix-blend-saturation"
  | "xl:mix-blend-saturation"
  | "lg:mix-blend-saturation"
  | "md:mix-blend-saturation"
  | "sm:mix-blend-saturation"
  | "xs:mix-blend-saturation"
  | "2xl:mix-blend-color"
  | "1xl:mix-blend-color"
  | "xl:mix-blend-color"
  | "lg:mix-blend-color"
  | "md:mix-blend-color"
  | "sm:mix-blend-color"
  | "xs:mix-blend-color"
  | "2xl:mix-blend-luminosity"
  | "1xl:mix-blend-luminosity"
  | "xl:mix-blend-luminosity"
  | "lg:mix-blend-luminosity"
  | "md:mix-blend-luminosity"
  | "sm:mix-blend-luminosity"
  | "xs:mix-blend-luminosity"
  | "2xl:object-contain"
  | "1xl:object-contain"
  | "xl:object-contain"
  | "lg:object-contain"
  | "md:object-contain"
  | "sm:object-contain"
  | "xs:object-contain"
  | "2xl:object-cover"
  | "1xl:object-cover"
  | "xl:object-cover"
  | "lg:object-cover"
  | "md:object-cover"
  | "sm:object-cover"
  | "xs:object-cover"
  | "2xl:object-fill"
  | "1xl:object-fill"
  | "xl:object-fill"
  | "lg:object-fill"
  | "md:object-fill"
  | "sm:object-fill"
  | "xs:object-fill"
  | "2xl:object-none"
  | "1xl:object-none"
  | "xl:object-none"
  | "lg:object-none"
  | "md:object-none"
  | "sm:object-none"
  | "xs:object-none"
  | "2xl:object-scale-down"
  | "1xl:object-scale-down"
  | "xl:object-scale-down"
  | "lg:object-scale-down"
  | "md:object-scale-down"
  | "sm:object-scale-down"
  | "xs:object-scale-down"
  | "2xl:object-bottom"
  | "1xl:object-bottom"
  | "xl:object-bottom"
  | "lg:object-bottom"
  | "md:object-bottom"
  | "sm:object-bottom"
  | "xs:object-bottom"
  | "2xl:object-center"
  | "1xl:object-center"
  | "xl:object-center"
  | "lg:object-center"
  | "md:object-center"
  | "sm:object-center"
  | "xs:object-center"
  | "2xl:object-left"
  | "1xl:object-left"
  | "xl:object-left"
  | "lg:object-left"
  | "md:object-left"
  | "sm:object-left"
  | "xs:object-left"
  | "2xl:object-left-bottom"
  | "1xl:object-left-bottom"
  | "xl:object-left-bottom"
  | "lg:object-left-bottom"
  | "md:object-left-bottom"
  | "sm:object-left-bottom"
  | "xs:object-left-bottom"
  | "2xl:object-left-top"
  | "1xl:object-left-top"
  | "xl:object-left-top"
  | "lg:object-left-top"
  | "md:object-left-top"
  | "sm:object-left-top"
  | "xs:object-left-top"
  | "2xl:object-right"
  | "1xl:object-right"
  | "xl:object-right"
  | "lg:object-right"
  | "md:object-right"
  | "sm:object-right"
  | "xs:object-right"
  | "2xl:object-right-bottom"
  | "1xl:object-right-bottom"
  | "xl:object-right-bottom"
  | "lg:object-right-bottom"
  | "md:object-right-bottom"
  | "sm:object-right-bottom"
  | "xs:object-right-bottom"
  | "2xl:object-right-top"
  | "1xl:object-right-top"
  | "xl:object-right-top"
  | "lg:object-right-top"
  | "md:object-right-top"
  | "sm:object-right-top"
  | "xs:object-right-top"
  | "2xl:object-top"
  | "1xl:object-top"
  | "xl:object-top"
  | "lg:object-top"
  | "md:object-top"
  | "sm:object-top"
  | "xs:object-top"
  | "2xl:opacity-0"
  | "1xl:opacity-0"
  | "xl:opacity-0"
  | "lg:opacity-0"
  | "md:opacity-0"
  | "sm:opacity-0"
  | "xs:opacity-0"
  | "group-hover:opacity-0"
  | "focus-within:opacity-0"
  | "hover:opacity-0"
  | "focus:opacity-0"
  | "2xl:opacity-5"
  | "1xl:opacity-5"
  | "xl:opacity-5"
  | "lg:opacity-5"
  | "md:opacity-5"
  | "sm:opacity-5"
  | "xs:opacity-5"
  | "group-hover:opacity-5"
  | "focus-within:opacity-5"
  | "hover:opacity-5"
  | "focus:opacity-5"
  | "2xl:opacity-10"
  | "1xl:opacity-10"
  | "xl:opacity-10"
  | "lg:opacity-10"
  | "md:opacity-10"
  | "sm:opacity-10"
  | "xs:opacity-10"
  | "group-hover:opacity-10"
  | "focus-within:opacity-10"
  | "hover:opacity-10"
  | "focus:opacity-10"
  | "2xl:opacity-20"
  | "1xl:opacity-20"
  | "xl:opacity-20"
  | "lg:opacity-20"
  | "md:opacity-20"
  | "sm:opacity-20"
  | "xs:opacity-20"
  | "group-hover:opacity-20"
  | "focus-within:opacity-20"
  | "hover:opacity-20"
  | "focus:opacity-20"
  | "2xl:opacity-25"
  | "1xl:opacity-25"
  | "xl:opacity-25"
  | "lg:opacity-25"
  | "md:opacity-25"
  | "sm:opacity-25"
  | "xs:opacity-25"
  | "group-hover:opacity-25"
  | "focus-within:opacity-25"
  | "hover:opacity-25"
  | "focus:opacity-25"
  | "2xl:opacity-30"
  | "1xl:opacity-30"
  | "xl:opacity-30"
  | "lg:opacity-30"
  | "md:opacity-30"
  | "sm:opacity-30"
  | "xs:opacity-30"
  | "group-hover:opacity-30"
  | "focus-within:opacity-30"
  | "hover:opacity-30"
  | "focus:opacity-30"
  | "2xl:opacity-40"
  | "1xl:opacity-40"
  | "xl:opacity-40"
  | "lg:opacity-40"
  | "md:opacity-40"
  | "sm:opacity-40"
  | "xs:opacity-40"
  | "group-hover:opacity-40"
  | "focus-within:opacity-40"
  | "hover:opacity-40"
  | "focus:opacity-40"
  | "2xl:opacity-50"
  | "1xl:opacity-50"
  | "xl:opacity-50"
  | "lg:opacity-50"
  | "md:opacity-50"
  | "sm:opacity-50"
  | "xs:opacity-50"
  | "group-hover:opacity-50"
  | "focus-within:opacity-50"
  | "hover:opacity-50"
  | "focus:opacity-50"
  | "2xl:opacity-60"
  | "1xl:opacity-60"
  | "xl:opacity-60"
  | "lg:opacity-60"
  | "md:opacity-60"
  | "sm:opacity-60"
  | "xs:opacity-60"
  | "group-hover:opacity-60"
  | "focus-within:opacity-60"
  | "hover:opacity-60"
  | "focus:opacity-60"
  | "2xl:opacity-70"
  | "1xl:opacity-70"
  | "xl:opacity-70"
  | "lg:opacity-70"
  | "md:opacity-70"
  | "sm:opacity-70"
  | "xs:opacity-70"
  | "group-hover:opacity-70"
  | "focus-within:opacity-70"
  | "hover:opacity-70"
  | "focus:opacity-70"
  | "2xl:opacity-75"
  | "1xl:opacity-75"
  | "xl:opacity-75"
  | "lg:opacity-75"
  | "md:opacity-75"
  | "sm:opacity-75"
  | "xs:opacity-75"
  | "group-hover:opacity-75"
  | "focus-within:opacity-75"
  | "hover:opacity-75"
  | "focus:opacity-75"
  | "2xl:opacity-80"
  | "1xl:opacity-80"
  | "xl:opacity-80"
  | "lg:opacity-80"
  | "md:opacity-80"
  | "sm:opacity-80"
  | "xs:opacity-80"
  | "group-hover:opacity-80"
  | "focus-within:opacity-80"
  | "hover:opacity-80"
  | "focus:opacity-80"
  | "2xl:opacity-90"
  | "1xl:opacity-90"
  | "xl:opacity-90"
  | "lg:opacity-90"
  | "md:opacity-90"
  | "sm:opacity-90"
  | "xs:opacity-90"
  | "group-hover:opacity-90"
  | "focus-within:opacity-90"
  | "hover:opacity-90"
  | "focus:opacity-90"
  | "2xl:opacity-95"
  | "1xl:opacity-95"
  | "xl:opacity-95"
  | "lg:opacity-95"
  | "md:opacity-95"
  | "sm:opacity-95"
  | "xs:opacity-95"
  | "group-hover:opacity-95"
  | "focus-within:opacity-95"
  | "hover:opacity-95"
  | "focus:opacity-95"
  | "2xl:opacity-100"
  | "1xl:opacity-100"
  | "xl:opacity-100"
  | "lg:opacity-100"
  | "md:opacity-100"
  | "sm:opacity-100"
  | "xs:opacity-100"
  | "group-hover:opacity-100"
  | "focus-within:opacity-100"
  | "hover:opacity-100"
  | "focus:opacity-100"
  | "2xl:order-1"
  | "1xl:order-1"
  | "xl:order-1"
  | "lg:order-1"
  | "md:order-1"
  | "sm:order-1"
  | "xs:order-1"
  | "2xl:order-2"
  | "1xl:order-2"
  | "xl:order-2"
  | "lg:order-2"
  | "md:order-2"
  | "sm:order-2"
  | "xs:order-2"
  | "2xl:order-3"
  | "1xl:order-3"
  | "xl:order-3"
  | "lg:order-3"
  | "md:order-3"
  | "sm:order-3"
  | "xs:order-3"
  | "2xl:order-4"
  | "1xl:order-4"
  | "xl:order-4"
  | "lg:order-4"
  | "md:order-4"
  | "sm:order-4"
  | "xs:order-4"
  | "2xl:order-5"
  | "1xl:order-5"
  | "xl:order-5"
  | "lg:order-5"
  | "md:order-5"
  | "sm:order-5"
  | "xs:order-5"
  | "2xl:order-6"
  | "1xl:order-6"
  | "xl:order-6"
  | "lg:order-6"
  | "md:order-6"
  | "sm:order-6"
  | "xs:order-6"
  | "2xl:order-7"
  | "1xl:order-7"
  | "xl:order-7"
  | "lg:order-7"
  | "md:order-7"
  | "sm:order-7"
  | "xs:order-7"
  | "2xl:order-8"
  | "1xl:order-8"
  | "xl:order-8"
  | "lg:order-8"
  | "md:order-8"
  | "sm:order-8"
  | "xs:order-8"
  | "2xl:order-9"
  | "1xl:order-9"
  | "xl:order-9"
  | "lg:order-9"
  | "md:order-9"
  | "sm:order-9"
  | "xs:order-9"
  | "2xl:order-10"
  | "1xl:order-10"
  | "xl:order-10"
  | "lg:order-10"
  | "md:order-10"
  | "sm:order-10"
  | "xs:order-10"
  | "2xl:order-11"
  | "1xl:order-11"
  | "xl:order-11"
  | "lg:order-11"
  | "md:order-11"
  | "sm:order-11"
  | "xs:order-11"
  | "2xl:order-12"
  | "1xl:order-12"
  | "xl:order-12"
  | "lg:order-12"
  | "md:order-12"
  | "sm:order-12"
  | "xs:order-12"
  | "2xl:order-first"
  | "1xl:order-first"
  | "xl:order-first"
  | "lg:order-first"
  | "md:order-first"
  | "sm:order-first"
  | "xs:order-first"
  | "2xl:order-last"
  | "1xl:order-last"
  | "xl:order-last"
  | "lg:order-last"
  | "md:order-last"
  | "sm:order-last"
  | "xs:order-last"
  | "2xl:order-none"
  | "1xl:order-none"
  | "xl:order-none"
  | "lg:order-none"
  | "md:order-none"
  | "sm:order-none"
  | "xs:order-none"
  | "2xl:outline-none"
  | "1xl:outline-none"
  | "xl:outline-none"
  | "lg:outline-none"
  | "md:outline-none"
  | "sm:outline-none"
  | "xs:outline-none"
  | "focus-within:outline-none"
  | "focus:outline-none"
  | "2xl:outline-white"
  | "1xl:outline-white"
  | "xl:outline-white"
  | "lg:outline-white"
  | "md:outline-white"
  | "sm:outline-white"
  | "xs:outline-white"
  | "focus-within:outline-white"
  | "focus:outline-white"
  | "2xl:outline-black"
  | "1xl:outline-black"
  | "xl:outline-black"
  | "lg:outline-black"
  | "md:outline-black"
  | "sm:outline-black"
  | "xs:outline-black"
  | "focus-within:outline-black"
  | "focus:outline-black"
  | "2xl:overflow-auto"
  | "1xl:overflow-auto"
  | "xl:overflow-auto"
  | "lg:overflow-auto"
  | "md:overflow-auto"
  | "sm:overflow-auto"
  | "xs:overflow-auto"
  | "2xl:overflow-hidden"
  | "1xl:overflow-hidden"
  | "xl:overflow-hidden"
  | "lg:overflow-hidden"
  | "md:overflow-hidden"
  | "sm:overflow-hidden"
  | "xs:overflow-hidden"
  | "2xl:overflow-visible"
  | "1xl:overflow-visible"
  | "xl:overflow-visible"
  | "lg:overflow-visible"
  | "md:overflow-visible"
  | "sm:overflow-visible"
  | "xs:overflow-visible"
  | "2xl:overflow-scroll"
  | "1xl:overflow-scroll"
  | "xl:overflow-scroll"
  | "lg:overflow-scroll"
  | "md:overflow-scroll"
  | "sm:overflow-scroll"
  | "xs:overflow-scroll"
  | "2xl:overflow-x-auto"
  | "1xl:overflow-x-auto"
  | "xl:overflow-x-auto"
  | "lg:overflow-x-auto"
  | "md:overflow-x-auto"
  | "sm:overflow-x-auto"
  | "xs:overflow-x-auto"
  | "2xl:overflow-y-auto"
  | "1xl:overflow-y-auto"
  | "xl:overflow-y-auto"
  | "lg:overflow-y-auto"
  | "md:overflow-y-auto"
  | "sm:overflow-y-auto"
  | "xs:overflow-y-auto"
  | "2xl:overflow-x-hidden"
  | "1xl:overflow-x-hidden"
  | "xl:overflow-x-hidden"
  | "lg:overflow-x-hidden"
  | "md:overflow-x-hidden"
  | "sm:overflow-x-hidden"
  | "xs:overflow-x-hidden"
  | "2xl:overflow-y-hidden"
  | "1xl:overflow-y-hidden"
  | "xl:overflow-y-hidden"
  | "lg:overflow-y-hidden"
  | "md:overflow-y-hidden"
  | "sm:overflow-y-hidden"
  | "xs:overflow-y-hidden"
  | "2xl:overflow-x-visible"
  | "1xl:overflow-x-visible"
  | "xl:overflow-x-visible"
  | "lg:overflow-x-visible"
  | "md:overflow-x-visible"
  | "sm:overflow-x-visible"
  | "xs:overflow-x-visible"
  | "2xl:overflow-y-visible"
  | "1xl:overflow-y-visible"
  | "xl:overflow-y-visible"
  | "lg:overflow-y-visible"
  | "md:overflow-y-visible"
  | "sm:overflow-y-visible"
  | "xs:overflow-y-visible"
  | "2xl:overflow-x-scroll"
  | "1xl:overflow-x-scroll"
  | "xl:overflow-x-scroll"
  | "lg:overflow-x-scroll"
  | "md:overflow-x-scroll"
  | "sm:overflow-x-scroll"
  | "xs:overflow-x-scroll"
  | "2xl:overflow-y-scroll"
  | "1xl:overflow-y-scroll"
  | "xl:overflow-y-scroll"
  | "lg:overflow-y-scroll"
  | "md:overflow-y-scroll"
  | "sm:overflow-y-scroll"
  | "xs:overflow-y-scroll"
  | "2xl:overscroll-auto"
  | "1xl:overscroll-auto"
  | "xl:overscroll-auto"
  | "lg:overscroll-auto"
  | "md:overscroll-auto"
  | "sm:overscroll-auto"
  | "xs:overscroll-auto"
  | "2xl:overscroll-contain"
  | "1xl:overscroll-contain"
  | "xl:overscroll-contain"
  | "lg:overscroll-contain"
  | "md:overscroll-contain"
  | "sm:overscroll-contain"
  | "xs:overscroll-contain"
  | "2xl:overscroll-none"
  | "1xl:overscroll-none"
  | "xl:overscroll-none"
  | "lg:overscroll-none"
  | "md:overscroll-none"
  | "sm:overscroll-none"
  | "xs:overscroll-none"
  | "2xl:overscroll-y-auto"
  | "1xl:overscroll-y-auto"
  | "xl:overscroll-y-auto"
  | "lg:overscroll-y-auto"
  | "md:overscroll-y-auto"
  | "sm:overscroll-y-auto"
  | "xs:overscroll-y-auto"
  | "2xl:overscroll-y-contain"
  | "1xl:overscroll-y-contain"
  | "xl:overscroll-y-contain"
  | "lg:overscroll-y-contain"
  | "md:overscroll-y-contain"
  | "sm:overscroll-y-contain"
  | "xs:overscroll-y-contain"
  | "2xl:overscroll-y-none"
  | "1xl:overscroll-y-none"
  | "xl:overscroll-y-none"
  | "lg:overscroll-y-none"
  | "md:overscroll-y-none"
  | "sm:overscroll-y-none"
  | "xs:overscroll-y-none"
  | "2xl:overscroll-x-auto"
  | "1xl:overscroll-x-auto"
  | "xl:overscroll-x-auto"
  | "lg:overscroll-x-auto"
  | "md:overscroll-x-auto"
  | "sm:overscroll-x-auto"
  | "xs:overscroll-x-auto"
  | "2xl:overscroll-x-contain"
  | "1xl:overscroll-x-contain"
  | "xl:overscroll-x-contain"
  | "lg:overscroll-x-contain"
  | "md:overscroll-x-contain"
  | "sm:overscroll-x-contain"
  | "xs:overscroll-x-contain"
  | "2xl:overscroll-x-none"
  | "1xl:overscroll-x-none"
  | "xl:overscroll-x-none"
  | "lg:overscroll-x-none"
  | "md:overscroll-x-none"
  | "sm:overscroll-x-none"
  | "xs:overscroll-x-none"
  | "2xl:p-0"
  | "1xl:p-0"
  | "xl:p-0"
  | "lg:p-0"
  | "md:p-0"
  | "sm:p-0"
  | "xs:p-0"
  | "2xl:p-1"
  | "1xl:p-1"
  | "xl:p-1"
  | "lg:p-1"
  | "md:p-1"
  | "sm:p-1"
  | "xs:p-1"
  | "2xl:p-2"
  | "1xl:p-2"
  | "xl:p-2"
  | "lg:p-2"
  | "md:p-2"
  | "sm:p-2"
  | "xs:p-2"
  | "2xl:p-3"
  | "1xl:p-3"
  | "xl:p-3"
  | "lg:p-3"
  | "md:p-3"
  | "sm:p-3"
  | "xs:p-3"
  | "2xl:p-4"
  | "1xl:p-4"
  | "xl:p-4"
  | "lg:p-4"
  | "md:p-4"
  | "sm:p-4"
  | "xs:p-4"
  | "2xl:p-5"
  | "1xl:p-5"
  | "xl:p-5"
  | "lg:p-5"
  | "md:p-5"
  | "sm:p-5"
  | "xs:p-5"
  | "2xl:p-6"
  | "1xl:p-6"
  | "xl:p-6"
  | "lg:p-6"
  | "md:p-6"
  | "sm:p-6"
  | "xs:p-6"
  | "2xl:p-7"
  | "1xl:p-7"
  | "xl:p-7"
  | "lg:p-7"
  | "md:p-7"
  | "sm:p-7"
  | "xs:p-7"
  | "2xl:p-8"
  | "1xl:p-8"
  | "xl:p-8"
  | "lg:p-8"
  | "md:p-8"
  | "sm:p-8"
  | "xs:p-8"
  | "2xl:p-9"
  | "1xl:p-9"
  | "xl:p-9"
  | "lg:p-9"
  | "md:p-9"
  | "sm:p-9"
  | "xs:p-9"
  | "2xl:p-10"
  | "1xl:p-10"
  | "xl:p-10"
  | "lg:p-10"
  | "md:p-10"
  | "sm:p-10"
  | "xs:p-10"
  | "2xl:p-11"
  | "1xl:p-11"
  | "xl:p-11"
  | "lg:p-11"
  | "md:p-11"
  | "sm:p-11"
  | "xs:p-11"
  | "2xl:p-12"
  | "1xl:p-12"
  | "xl:p-12"
  | "lg:p-12"
  | "md:p-12"
  | "sm:p-12"
  | "xs:p-12"
  | "2xl:p-14"
  | "1xl:p-14"
  | "xl:p-14"
  | "lg:p-14"
  | "md:p-14"
  | "sm:p-14"
  | "xs:p-14"
  | "2xl:p-16"
  | "1xl:p-16"
  | "xl:p-16"
  | "lg:p-16"
  | "md:p-16"
  | "sm:p-16"
  | "xs:p-16"
  | "2xl:p-20"
  | "1xl:p-20"
  | "xl:p-20"
  | "lg:p-20"
  | "md:p-20"
  | "sm:p-20"
  | "xs:p-20"
  | "2xl:p-24"
  | "1xl:p-24"
  | "xl:p-24"
  | "lg:p-24"
  | "md:p-24"
  | "sm:p-24"
  | "xs:p-24"
  | "2xl:p-28"
  | "1xl:p-28"
  | "xl:p-28"
  | "lg:p-28"
  | "md:p-28"
  | "sm:p-28"
  | "xs:p-28"
  | "2xl:p-32"
  | "1xl:p-32"
  | "xl:p-32"
  | "lg:p-32"
  | "md:p-32"
  | "sm:p-32"
  | "xs:p-32"
  | "2xl:p-36"
  | "1xl:p-36"
  | "xl:p-36"
  | "lg:p-36"
  | "md:p-36"
  | "sm:p-36"
  | "xs:p-36"
  | "2xl:p-40"
  | "1xl:p-40"
  | "xl:p-40"
  | "lg:p-40"
  | "md:p-40"
  | "sm:p-40"
  | "xs:p-40"
  | "2xl:p-44"
  | "1xl:p-44"
  | "xl:p-44"
  | "lg:p-44"
  | "md:p-44"
  | "sm:p-44"
  | "xs:p-44"
  | "2xl:p-48"
  | "1xl:p-48"
  | "xl:p-48"
  | "lg:p-48"
  | "md:p-48"
  | "sm:p-48"
  | "xs:p-48"
  | "2xl:p-52"
  | "1xl:p-52"
  | "xl:p-52"
  | "lg:p-52"
  | "md:p-52"
  | "sm:p-52"
  | "xs:p-52"
  | "2xl:p-56"
  | "1xl:p-56"
  | "xl:p-56"
  | "lg:p-56"
  | "md:p-56"
  | "sm:p-56"
  | "xs:p-56"
  | "2xl:p-60"
  | "1xl:p-60"
  | "xl:p-60"
  | "lg:p-60"
  | "md:p-60"
  | "sm:p-60"
  | "xs:p-60"
  | "2xl:p-64"
  | "1xl:p-64"
  | "xl:p-64"
  | "lg:p-64"
  | "md:p-64"
  | "sm:p-64"
  | "xs:p-64"
  | "2xl:p-72"
  | "1xl:p-72"
  | "xl:p-72"
  | "lg:p-72"
  | "md:p-72"
  | "sm:p-72"
  | "xs:p-72"
  | "2xl:p-80"
  | "1xl:p-80"
  | "xl:p-80"
  | "lg:p-80"
  | "md:p-80"
  | "sm:p-80"
  | "xs:p-80"
  | "2xl:p-96"
  | "1xl:p-96"
  | "xl:p-96"
  | "lg:p-96"
  | "md:p-96"
  | "sm:p-96"
  | "xs:p-96"
  | "2xl:p-px"
  | "1xl:p-px"
  | "xl:p-px"
  | "lg:p-px"
  | "md:p-px"
  | "sm:p-px"
  | "xs:p-px"
  | "2xl:p-0.5"
  | "1xl:p-0.5"
  | "xl:p-0.5"
  | "lg:p-0.5"
  | "md:p-0.5"
  | "sm:p-0.5"
  | "xs:p-0.5"
  | "2xl:p-1.5"
  | "1xl:p-1.5"
  | "xl:p-1.5"
  | "lg:p-1.5"
  | "md:p-1.5"
  | "sm:p-1.5"
  | "xs:p-1.5"
  | "2xl:p-2.5"
  | "1xl:p-2.5"
  | "xl:p-2.5"
  | "lg:p-2.5"
  | "md:p-2.5"
  | "sm:p-2.5"
  | "xs:p-2.5"
  | "2xl:p-3.5"
  | "1xl:p-3.5"
  | "xl:p-3.5"
  | "lg:p-3.5"
  | "md:p-3.5"
  | "sm:p-3.5"
  | "xs:p-3.5"
  | "2xl:py-0"
  | "1xl:py-0"
  | "xl:py-0"
  | "lg:py-0"
  | "md:py-0"
  | "sm:py-0"
  | "xs:py-0"
  | "2xl:py-1"
  | "1xl:py-1"
  | "xl:py-1"
  | "lg:py-1"
  | "md:py-1"
  | "sm:py-1"
  | "xs:py-1"
  | "2xl:py-2"
  | "1xl:py-2"
  | "xl:py-2"
  | "lg:py-2"
  | "md:py-2"
  | "sm:py-2"
  | "xs:py-2"
  | "2xl:py-3"
  | "1xl:py-3"
  | "xl:py-3"
  | "lg:py-3"
  | "md:py-3"
  | "sm:py-3"
  | "xs:py-3"
  | "2xl:py-4"
  | "1xl:py-4"
  | "xl:py-4"
  | "lg:py-4"
  | "md:py-4"
  | "sm:py-4"
  | "xs:py-4"
  | "2xl:py-5"
  | "1xl:py-5"
  | "xl:py-5"
  | "lg:py-5"
  | "md:py-5"
  | "sm:py-5"
  | "xs:py-5"
  | "2xl:py-6"
  | "1xl:py-6"
  | "xl:py-6"
  | "lg:py-6"
  | "md:py-6"
  | "sm:py-6"
  | "xs:py-6"
  | "2xl:py-7"
  | "1xl:py-7"
  | "xl:py-7"
  | "lg:py-7"
  | "md:py-7"
  | "sm:py-7"
  | "xs:py-7"
  | "2xl:py-8"
  | "1xl:py-8"
  | "xl:py-8"
  | "lg:py-8"
  | "md:py-8"
  | "sm:py-8"
  | "xs:py-8"
  | "2xl:py-9"
  | "1xl:py-9"
  | "xl:py-9"
  | "lg:py-9"
  | "md:py-9"
  | "sm:py-9"
  | "xs:py-9"
  | "2xl:py-10"
  | "1xl:py-10"
  | "xl:py-10"
  | "lg:py-10"
  | "md:py-10"
  | "sm:py-10"
  | "xs:py-10"
  | "2xl:py-11"
  | "1xl:py-11"
  | "xl:py-11"
  | "lg:py-11"
  | "md:py-11"
  | "sm:py-11"
  | "xs:py-11"
  | "2xl:py-12"
  | "1xl:py-12"
  | "xl:py-12"
  | "lg:py-12"
  | "md:py-12"
  | "sm:py-12"
  | "xs:py-12"
  | "2xl:py-14"
  | "1xl:py-14"
  | "xl:py-14"
  | "lg:py-14"
  | "md:py-14"
  | "sm:py-14"
  | "xs:py-14"
  | "2xl:py-16"
  | "1xl:py-16"
  | "xl:py-16"
  | "lg:py-16"
  | "md:py-16"
  | "sm:py-16"
  | "xs:py-16"
  | "2xl:py-20"
  | "1xl:py-20"
  | "xl:py-20"
  | "lg:py-20"
  | "md:py-20"
  | "sm:py-20"
  | "xs:py-20"
  | "2xl:py-24"
  | "1xl:py-24"
  | "xl:py-24"
  | "lg:py-24"
  | "md:py-24"
  | "sm:py-24"
  | "xs:py-24"
  | "2xl:py-28"
  | "1xl:py-28"
  | "xl:py-28"
  | "lg:py-28"
  | "md:py-28"
  | "sm:py-28"
  | "xs:py-28"
  | "2xl:py-32"
  | "1xl:py-32"
  | "xl:py-32"
  | "lg:py-32"
  | "md:py-32"
  | "sm:py-32"
  | "xs:py-32"
  | "2xl:py-36"
  | "1xl:py-36"
  | "xl:py-36"
  | "lg:py-36"
  | "md:py-36"
  | "sm:py-36"
  | "xs:py-36"
  | "2xl:py-40"
  | "1xl:py-40"
  | "xl:py-40"
  | "lg:py-40"
  | "md:py-40"
  | "sm:py-40"
  | "xs:py-40"
  | "2xl:py-44"
  | "1xl:py-44"
  | "xl:py-44"
  | "lg:py-44"
  | "md:py-44"
  | "sm:py-44"
  | "xs:py-44"
  | "2xl:py-48"
  | "1xl:py-48"
  | "xl:py-48"
  | "lg:py-48"
  | "md:py-48"
  | "sm:py-48"
  | "xs:py-48"
  | "2xl:py-52"
  | "1xl:py-52"
  | "xl:py-52"
  | "lg:py-52"
  | "md:py-52"
  | "sm:py-52"
  | "xs:py-52"
  | "2xl:py-56"
  | "1xl:py-56"
  | "xl:py-56"
  | "lg:py-56"
  | "md:py-56"
  | "sm:py-56"
  | "xs:py-56"
  | "2xl:py-60"
  | "1xl:py-60"
  | "xl:py-60"
  | "lg:py-60"
  | "md:py-60"
  | "sm:py-60"
  | "xs:py-60"
  | "2xl:py-64"
  | "1xl:py-64"
  | "xl:py-64"
  | "lg:py-64"
  | "md:py-64"
  | "sm:py-64"
  | "xs:py-64"
  | "2xl:py-72"
  | "1xl:py-72"
  | "xl:py-72"
  | "lg:py-72"
  | "md:py-72"
  | "sm:py-72"
  | "xs:py-72"
  | "2xl:py-80"
  | "1xl:py-80"
  | "xl:py-80"
  | "lg:py-80"
  | "md:py-80"
  | "sm:py-80"
  | "xs:py-80"
  | "2xl:py-96"
  | "1xl:py-96"
  | "xl:py-96"
  | "lg:py-96"
  | "md:py-96"
  | "sm:py-96"
  | "xs:py-96"
  | "2xl:py-px"
  | "1xl:py-px"
  | "xl:py-px"
  | "lg:py-px"
  | "md:py-px"
  | "sm:py-px"
  | "xs:py-px"
  | "2xl:py-0.5"
  | "1xl:py-0.5"
  | "xl:py-0.5"
  | "lg:py-0.5"
  | "md:py-0.5"
  | "sm:py-0.5"
  | "xs:py-0.5"
  | "2xl:py-1.5"
  | "1xl:py-1.5"
  | "xl:py-1.5"
  | "lg:py-1.5"
  | "md:py-1.5"
  | "sm:py-1.5"
  | "xs:py-1.5"
  | "2xl:py-2.5"
  | "1xl:py-2.5"
  | "xl:py-2.5"
  | "lg:py-2.5"
  | "md:py-2.5"
  | "sm:py-2.5"
  | "xs:py-2.5"
  | "2xl:py-3.5"
  | "1xl:py-3.5"
  | "xl:py-3.5"
  | "lg:py-3.5"
  | "md:py-3.5"
  | "sm:py-3.5"
  | "xs:py-3.5"
  | "2xl:px-0"
  | "1xl:px-0"
  | "xl:px-0"
  | "lg:px-0"
  | "md:px-0"
  | "sm:px-0"
  | "xs:px-0"
  | "2xl:px-1"
  | "1xl:px-1"
  | "xl:px-1"
  | "lg:px-1"
  | "md:px-1"
  | "sm:px-1"
  | "xs:px-1"
  | "2xl:px-2"
  | "1xl:px-2"
  | "xl:px-2"
  | "lg:px-2"
  | "md:px-2"
  | "sm:px-2"
  | "xs:px-2"
  | "2xl:px-3"
  | "1xl:px-3"
  | "xl:px-3"
  | "lg:px-3"
  | "md:px-3"
  | "sm:px-3"
  | "xs:px-3"
  | "2xl:px-4"
  | "1xl:px-4"
  | "xl:px-4"
  | "lg:px-4"
  | "md:px-4"
  | "sm:px-4"
  | "xs:px-4"
  | "2xl:px-5"
  | "1xl:px-5"
  | "xl:px-5"
  | "lg:px-5"
  | "md:px-5"
  | "sm:px-5"
  | "xs:px-5"
  | "2xl:px-6"
  | "1xl:px-6"
  | "xl:px-6"
  | "lg:px-6"
  | "md:px-6"
  | "sm:px-6"
  | "xs:px-6"
  | "2xl:px-7"
  | "1xl:px-7"
  | "xl:px-7"
  | "lg:px-7"
  | "md:px-7"
  | "sm:px-7"
  | "xs:px-7"
  | "2xl:px-8"
  | "1xl:px-8"
  | "xl:px-8"
  | "lg:px-8"
  | "md:px-8"
  | "sm:px-8"
  | "xs:px-8"
  | "2xl:px-9"
  | "1xl:px-9"
  | "xl:px-9"
  | "lg:px-9"
  | "md:px-9"
  | "sm:px-9"
  | "xs:px-9"
  | "2xl:px-10"
  | "1xl:px-10"
  | "xl:px-10"
  | "lg:px-10"
  | "md:px-10"
  | "sm:px-10"
  | "xs:px-10"
  | "2xl:px-11"
  | "1xl:px-11"
  | "xl:px-11"
  | "lg:px-11"
  | "md:px-11"
  | "sm:px-11"
  | "xs:px-11"
  | "2xl:px-12"
  | "1xl:px-12"
  | "xl:px-12"
  | "lg:px-12"
  | "md:px-12"
  | "sm:px-12"
  | "xs:px-12"
  | "2xl:px-14"
  | "1xl:px-14"
  | "xl:px-14"
  | "lg:px-14"
  | "md:px-14"
  | "sm:px-14"
  | "xs:px-14"
  | "2xl:px-16"
  | "1xl:px-16"
  | "xl:px-16"
  | "lg:px-16"
  | "md:px-16"
  | "sm:px-16"
  | "xs:px-16"
  | "2xl:px-20"
  | "1xl:px-20"
  | "xl:px-20"
  | "lg:px-20"
  | "md:px-20"
  | "sm:px-20"
  | "xs:px-20"
  | "2xl:px-24"
  | "1xl:px-24"
  | "xl:px-24"
  | "lg:px-24"
  | "md:px-24"
  | "sm:px-24"
  | "xs:px-24"
  | "2xl:px-28"
  | "1xl:px-28"
  | "xl:px-28"
  | "lg:px-28"
  | "md:px-28"
  | "sm:px-28"
  | "xs:px-28"
  | "2xl:px-32"
  | "1xl:px-32"
  | "xl:px-32"
  | "lg:px-32"
  | "md:px-32"
  | "sm:px-32"
  | "xs:px-32"
  | "2xl:px-36"
  | "1xl:px-36"
  | "xl:px-36"
  | "lg:px-36"
  | "md:px-36"
  | "sm:px-36"
  | "xs:px-36"
  | "2xl:px-40"
  | "1xl:px-40"
  | "xl:px-40"
  | "lg:px-40"
  | "md:px-40"
  | "sm:px-40"
  | "xs:px-40"
  | "2xl:px-44"
  | "1xl:px-44"
  | "xl:px-44"
  | "lg:px-44"
  | "md:px-44"
  | "sm:px-44"
  | "xs:px-44"
  | "2xl:px-48"
  | "1xl:px-48"
  | "xl:px-48"
  | "lg:px-48"
  | "md:px-48"
  | "sm:px-48"
  | "xs:px-48"
  | "2xl:px-52"
  | "1xl:px-52"
  | "xl:px-52"
  | "lg:px-52"
  | "md:px-52"
  | "sm:px-52"
  | "xs:px-52"
  | "2xl:px-56"
  | "1xl:px-56"
  | "xl:px-56"
  | "lg:px-56"
  | "md:px-56"
  | "sm:px-56"
  | "xs:px-56"
  | "2xl:px-60"
  | "1xl:px-60"
  | "xl:px-60"
  | "lg:px-60"
  | "md:px-60"
  | "sm:px-60"
  | "xs:px-60"
  | "2xl:px-64"
  | "1xl:px-64"
  | "xl:px-64"
  | "lg:px-64"
  | "md:px-64"
  | "sm:px-64"
  | "xs:px-64"
  | "2xl:px-72"
  | "1xl:px-72"
  | "xl:px-72"
  | "lg:px-72"
  | "md:px-72"
  | "sm:px-72"
  | "xs:px-72"
  | "2xl:px-80"
  | "1xl:px-80"
  | "xl:px-80"
  | "lg:px-80"
  | "md:px-80"
  | "sm:px-80"
  | "xs:px-80"
  | "2xl:px-96"
  | "1xl:px-96"
  | "xl:px-96"
  | "lg:px-96"
  | "md:px-96"
  | "sm:px-96"
  | "xs:px-96"
  | "2xl:px-px"
  | "1xl:px-px"
  | "xl:px-px"
  | "lg:px-px"
  | "md:px-px"
  | "sm:px-px"
  | "xs:px-px"
  | "2xl:px-0.5"
  | "1xl:px-0.5"
  | "xl:px-0.5"
  | "lg:px-0.5"
  | "md:px-0.5"
  | "sm:px-0.5"
  | "xs:px-0.5"
  | "2xl:px-1.5"
  | "1xl:px-1.5"
  | "xl:px-1.5"
  | "lg:px-1.5"
  | "md:px-1.5"
  | "sm:px-1.5"
  | "xs:px-1.5"
  | "2xl:px-2.5"
  | "1xl:px-2.5"
  | "xl:px-2.5"
  | "lg:px-2.5"
  | "md:px-2.5"
  | "sm:px-2.5"
  | "xs:px-2.5"
  | "2xl:px-3.5"
  | "1xl:px-3.5"
  | "xl:px-3.5"
  | "lg:px-3.5"
  | "md:px-3.5"
  | "sm:px-3.5"
  | "xs:px-3.5"
  | "2xl:pt-0"
  | "1xl:pt-0"
  | "xl:pt-0"
  | "lg:pt-0"
  | "md:pt-0"
  | "sm:pt-0"
  | "xs:pt-0"
  | "2xl:pt-1"
  | "1xl:pt-1"
  | "xl:pt-1"
  | "lg:pt-1"
  | "md:pt-1"
  | "sm:pt-1"
  | "xs:pt-1"
  | "2xl:pt-2"
  | "1xl:pt-2"
  | "xl:pt-2"
  | "lg:pt-2"
  | "md:pt-2"
  | "sm:pt-2"
  | "xs:pt-2"
  | "2xl:pt-3"
  | "1xl:pt-3"
  | "xl:pt-3"
  | "lg:pt-3"
  | "md:pt-3"
  | "sm:pt-3"
  | "xs:pt-3"
  | "2xl:pt-4"
  | "1xl:pt-4"
  | "xl:pt-4"
  | "lg:pt-4"
  | "md:pt-4"
  | "sm:pt-4"
  | "xs:pt-4"
  | "2xl:pt-5"
  | "1xl:pt-5"
  | "xl:pt-5"
  | "lg:pt-5"
  | "md:pt-5"
  | "sm:pt-5"
  | "xs:pt-5"
  | "2xl:pt-6"
  | "1xl:pt-6"
  | "xl:pt-6"
  | "lg:pt-6"
  | "md:pt-6"
  | "sm:pt-6"
  | "xs:pt-6"
  | "2xl:pt-7"
  | "1xl:pt-7"
  | "xl:pt-7"
  | "lg:pt-7"
  | "md:pt-7"
  | "sm:pt-7"
  | "xs:pt-7"
  | "2xl:pt-8"
  | "1xl:pt-8"
  | "xl:pt-8"
  | "lg:pt-8"
  | "md:pt-8"
  | "sm:pt-8"
  | "xs:pt-8"
  | "2xl:pt-9"
  | "1xl:pt-9"
  | "xl:pt-9"
  | "lg:pt-9"
  | "md:pt-9"
  | "sm:pt-9"
  | "xs:pt-9"
  | "2xl:pt-10"
  | "1xl:pt-10"
  | "xl:pt-10"
  | "lg:pt-10"
  | "md:pt-10"
  | "sm:pt-10"
  | "xs:pt-10"
  | "2xl:pt-11"
  | "1xl:pt-11"
  | "xl:pt-11"
  | "lg:pt-11"
  | "md:pt-11"
  | "sm:pt-11"
  | "xs:pt-11"
  | "2xl:pt-12"
  | "1xl:pt-12"
  | "xl:pt-12"
  | "lg:pt-12"
  | "md:pt-12"
  | "sm:pt-12"
  | "xs:pt-12"
  | "2xl:pt-14"
  | "1xl:pt-14"
  | "xl:pt-14"
  | "lg:pt-14"
  | "md:pt-14"
  | "sm:pt-14"
  | "xs:pt-14"
  | "2xl:pt-16"
  | "1xl:pt-16"
  | "xl:pt-16"
  | "lg:pt-16"
  | "md:pt-16"
  | "sm:pt-16"
  | "xs:pt-16"
  | "2xl:pt-20"
  | "1xl:pt-20"
  | "xl:pt-20"
  | "lg:pt-20"
  | "md:pt-20"
  | "sm:pt-20"
  | "xs:pt-20"
  | "2xl:pt-24"
  | "1xl:pt-24"
  | "xl:pt-24"
  | "lg:pt-24"
  | "md:pt-24"
  | "sm:pt-24"
  | "xs:pt-24"
  | "2xl:pt-28"
  | "1xl:pt-28"
  | "xl:pt-28"
  | "lg:pt-28"
  | "md:pt-28"
  | "sm:pt-28"
  | "xs:pt-28"
  | "2xl:pt-32"
  | "1xl:pt-32"
  | "xl:pt-32"
  | "lg:pt-32"
  | "md:pt-32"
  | "sm:pt-32"
  | "xs:pt-32"
  | "2xl:pt-36"
  | "1xl:pt-36"
  | "xl:pt-36"
  | "lg:pt-36"
  | "md:pt-36"
  | "sm:pt-36"
  | "xs:pt-36"
  | "2xl:pt-40"
  | "1xl:pt-40"
  | "xl:pt-40"
  | "lg:pt-40"
  | "md:pt-40"
  | "sm:pt-40"
  | "xs:pt-40"
  | "2xl:pt-44"
  | "1xl:pt-44"
  | "xl:pt-44"
  | "lg:pt-44"
  | "md:pt-44"
  | "sm:pt-44"
  | "xs:pt-44"
  | "2xl:pt-48"
  | "1xl:pt-48"
  | "xl:pt-48"
  | "lg:pt-48"
  | "md:pt-48"
  | "sm:pt-48"
  | "xs:pt-48"
  | "2xl:pt-52"
  | "1xl:pt-52"
  | "xl:pt-52"
  | "lg:pt-52"
  | "md:pt-52"
  | "sm:pt-52"
  | "xs:pt-52"
  | "2xl:pt-56"
  | "1xl:pt-56"
  | "xl:pt-56"
  | "lg:pt-56"
  | "md:pt-56"
  | "sm:pt-56"
  | "xs:pt-56"
  | "2xl:pt-60"
  | "1xl:pt-60"
  | "xl:pt-60"
  | "lg:pt-60"
  | "md:pt-60"
  | "sm:pt-60"
  | "xs:pt-60"
  | "2xl:pt-64"
  | "1xl:pt-64"
  | "xl:pt-64"
  | "lg:pt-64"
  | "md:pt-64"
  | "sm:pt-64"
  | "xs:pt-64"
  | "2xl:pt-72"
  | "1xl:pt-72"
  | "xl:pt-72"
  | "lg:pt-72"
  | "md:pt-72"
  | "sm:pt-72"
  | "xs:pt-72"
  | "2xl:pt-80"
  | "1xl:pt-80"
  | "xl:pt-80"
  | "lg:pt-80"
  | "md:pt-80"
  | "sm:pt-80"
  | "xs:pt-80"
  | "2xl:pt-96"
  | "1xl:pt-96"
  | "xl:pt-96"
  | "lg:pt-96"
  | "md:pt-96"
  | "sm:pt-96"
  | "xs:pt-96"
  | "2xl:pt-px"
  | "1xl:pt-px"
  | "xl:pt-px"
  | "lg:pt-px"
  | "md:pt-px"
  | "sm:pt-px"
  | "xs:pt-px"
  | "2xl:pt-0.5"
  | "1xl:pt-0.5"
  | "xl:pt-0.5"
  | "lg:pt-0.5"
  | "md:pt-0.5"
  | "sm:pt-0.5"
  | "xs:pt-0.5"
  | "2xl:pt-1.5"
  | "1xl:pt-1.5"
  | "xl:pt-1.5"
  | "lg:pt-1.5"
  | "md:pt-1.5"
  | "sm:pt-1.5"
  | "xs:pt-1.5"
  | "2xl:pt-2.5"
  | "1xl:pt-2.5"
  | "xl:pt-2.5"
  | "lg:pt-2.5"
  | "md:pt-2.5"
  | "sm:pt-2.5"
  | "xs:pt-2.5"
  | "2xl:pt-3.5"
  | "1xl:pt-3.5"
  | "xl:pt-3.5"
  | "lg:pt-3.5"
  | "md:pt-3.5"
  | "sm:pt-3.5"
  | "xs:pt-3.5"
  | "2xl:pr-0"
  | "1xl:pr-0"
  | "xl:pr-0"
  | "lg:pr-0"
  | "md:pr-0"
  | "sm:pr-0"
  | "xs:pr-0"
  | "2xl:pr-1"
  | "1xl:pr-1"
  | "xl:pr-1"
  | "lg:pr-1"
  | "md:pr-1"
  | "sm:pr-1"
  | "xs:pr-1"
  | "2xl:pr-2"
  | "1xl:pr-2"
  | "xl:pr-2"
  | "lg:pr-2"
  | "md:pr-2"
  | "sm:pr-2"
  | "xs:pr-2"
  | "2xl:pr-3"
  | "1xl:pr-3"
  | "xl:pr-3"
  | "lg:pr-3"
  | "md:pr-3"
  | "sm:pr-3"
  | "xs:pr-3"
  | "2xl:pr-4"
  | "1xl:pr-4"
  | "xl:pr-4"
  | "lg:pr-4"
  | "md:pr-4"
  | "sm:pr-4"
  | "xs:pr-4"
  | "2xl:pr-5"
  | "1xl:pr-5"
  | "xl:pr-5"
  | "lg:pr-5"
  | "md:pr-5"
  | "sm:pr-5"
  | "xs:pr-5"
  | "2xl:pr-6"
  | "1xl:pr-6"
  | "xl:pr-6"
  | "lg:pr-6"
  | "md:pr-6"
  | "sm:pr-6"
  | "xs:pr-6"
  | "2xl:pr-7"
  | "1xl:pr-7"
  | "xl:pr-7"
  | "lg:pr-7"
  | "md:pr-7"
  | "sm:pr-7"
  | "xs:pr-7"
  | "2xl:pr-8"
  | "1xl:pr-8"
  | "xl:pr-8"
  | "lg:pr-8"
  | "md:pr-8"
  | "sm:pr-8"
  | "xs:pr-8"
  | "2xl:pr-9"
  | "1xl:pr-9"
  | "xl:pr-9"
  | "lg:pr-9"
  | "md:pr-9"
  | "sm:pr-9"
  | "xs:pr-9"
  | "2xl:pr-10"
  | "1xl:pr-10"
  | "xl:pr-10"
  | "lg:pr-10"
  | "md:pr-10"
  | "sm:pr-10"
  | "xs:pr-10"
  | "2xl:pr-11"
  | "1xl:pr-11"
  | "xl:pr-11"
  | "lg:pr-11"
  | "md:pr-11"
  | "sm:pr-11"
  | "xs:pr-11"
  | "2xl:pr-12"
  | "1xl:pr-12"
  | "xl:pr-12"
  | "lg:pr-12"
  | "md:pr-12"
  | "sm:pr-12"
  | "xs:pr-12"
  | "2xl:pr-14"
  | "1xl:pr-14"
  | "xl:pr-14"
  | "lg:pr-14"
  | "md:pr-14"
  | "sm:pr-14"
  | "xs:pr-14"
  | "2xl:pr-16"
  | "1xl:pr-16"
  | "xl:pr-16"
  | "lg:pr-16"
  | "md:pr-16"
  | "sm:pr-16"
  | "xs:pr-16"
  | "2xl:pr-20"
  | "1xl:pr-20"
  | "xl:pr-20"
  | "lg:pr-20"
  | "md:pr-20"
  | "sm:pr-20"
  | "xs:pr-20"
  | "2xl:pr-24"
  | "1xl:pr-24"
  | "xl:pr-24"
  | "lg:pr-24"
  | "md:pr-24"
  | "sm:pr-24"
  | "xs:pr-24"
  | "2xl:pr-28"
  | "1xl:pr-28"
  | "xl:pr-28"
  | "lg:pr-28"
  | "md:pr-28"
  | "sm:pr-28"
  | "xs:pr-28"
  | "2xl:pr-32"
  | "1xl:pr-32"
  | "xl:pr-32"
  | "lg:pr-32"
  | "md:pr-32"
  | "sm:pr-32"
  | "xs:pr-32"
  | "2xl:pr-36"
  | "1xl:pr-36"
  | "xl:pr-36"
  | "lg:pr-36"
  | "md:pr-36"
  | "sm:pr-36"
  | "xs:pr-36"
  | "2xl:pr-40"
  | "1xl:pr-40"
  | "xl:pr-40"
  | "lg:pr-40"
  | "md:pr-40"
  | "sm:pr-40"
  | "xs:pr-40"
  | "2xl:pr-44"
  | "1xl:pr-44"
  | "xl:pr-44"
  | "lg:pr-44"
  | "md:pr-44"
  | "sm:pr-44"
  | "xs:pr-44"
  | "2xl:pr-48"
  | "1xl:pr-48"
  | "xl:pr-48"
  | "lg:pr-48"
  | "md:pr-48"
  | "sm:pr-48"
  | "xs:pr-48"
  | "2xl:pr-52"
  | "1xl:pr-52"
  | "xl:pr-52"
  | "lg:pr-52"
  | "md:pr-52"
  | "sm:pr-52"
  | "xs:pr-52"
  | "2xl:pr-56"
  | "1xl:pr-56"
  | "xl:pr-56"
  | "lg:pr-56"
  | "md:pr-56"
  | "sm:pr-56"
  | "xs:pr-56"
  | "2xl:pr-60"
  | "1xl:pr-60"
  | "xl:pr-60"
  | "lg:pr-60"
  | "md:pr-60"
  | "sm:pr-60"
  | "xs:pr-60"
  | "2xl:pr-64"
  | "1xl:pr-64"
  | "xl:pr-64"
  | "lg:pr-64"
  | "md:pr-64"
  | "sm:pr-64"
  | "xs:pr-64"
  | "2xl:pr-72"
  | "1xl:pr-72"
  | "xl:pr-72"
  | "lg:pr-72"
  | "md:pr-72"
  | "sm:pr-72"
  | "xs:pr-72"
  | "2xl:pr-80"
  | "1xl:pr-80"
  | "xl:pr-80"
  | "lg:pr-80"
  | "md:pr-80"
  | "sm:pr-80"
  | "xs:pr-80"
  | "2xl:pr-96"
  | "1xl:pr-96"
  | "xl:pr-96"
  | "lg:pr-96"
  | "md:pr-96"
  | "sm:pr-96"
  | "xs:pr-96"
  | "2xl:pr-px"
  | "1xl:pr-px"
  | "xl:pr-px"
  | "lg:pr-px"
  | "md:pr-px"
  | "sm:pr-px"
  | "xs:pr-px"
  | "2xl:pr-0.5"
  | "1xl:pr-0.5"
  | "xl:pr-0.5"
  | "lg:pr-0.5"
  | "md:pr-0.5"
  | "sm:pr-0.5"
  | "xs:pr-0.5"
  | "2xl:pr-1.5"
  | "1xl:pr-1.5"
  | "xl:pr-1.5"
  | "lg:pr-1.5"
  | "md:pr-1.5"
  | "sm:pr-1.5"
  | "xs:pr-1.5"
  | "2xl:pr-2.5"
  | "1xl:pr-2.5"
  | "xl:pr-2.5"
  | "lg:pr-2.5"
  | "md:pr-2.5"
  | "sm:pr-2.5"
  | "xs:pr-2.5"
  | "2xl:pr-3.5"
  | "1xl:pr-3.5"
  | "xl:pr-3.5"
  | "lg:pr-3.5"
  | "md:pr-3.5"
  | "sm:pr-3.5"
  | "xs:pr-3.5"
  | "2xl:pb-0"
  | "1xl:pb-0"
  | "xl:pb-0"
  | "lg:pb-0"
  | "md:pb-0"
  | "sm:pb-0"
  | "xs:pb-0"
  | "2xl:pb-1"
  | "1xl:pb-1"
  | "xl:pb-1"
  | "lg:pb-1"
  | "md:pb-1"
  | "sm:pb-1"
  | "xs:pb-1"
  | "2xl:pb-2"
  | "1xl:pb-2"
  | "xl:pb-2"
  | "lg:pb-2"
  | "md:pb-2"
  | "sm:pb-2"
  | "xs:pb-2"
  | "2xl:pb-3"
  | "1xl:pb-3"
  | "xl:pb-3"
  | "lg:pb-3"
  | "md:pb-3"
  | "sm:pb-3"
  | "xs:pb-3"
  | "2xl:pb-4"
  | "1xl:pb-4"
  | "xl:pb-4"
  | "lg:pb-4"
  | "md:pb-4"
  | "sm:pb-4"
  | "xs:pb-4"
  | "2xl:pb-5"
  | "1xl:pb-5"
  | "xl:pb-5"
  | "lg:pb-5"
  | "md:pb-5"
  | "sm:pb-5"
  | "xs:pb-5"
  | "2xl:pb-6"
  | "1xl:pb-6"
  | "xl:pb-6"
  | "lg:pb-6"
  | "md:pb-6"
  | "sm:pb-6"
  | "xs:pb-6"
  | "2xl:pb-7"
  | "1xl:pb-7"
  | "xl:pb-7"
  | "lg:pb-7"
  | "md:pb-7"
  | "sm:pb-7"
  | "xs:pb-7"
  | "2xl:pb-8"
  | "1xl:pb-8"
  | "xl:pb-8"
  | "lg:pb-8"
  | "md:pb-8"
  | "sm:pb-8"
  | "xs:pb-8"
  | "2xl:pb-9"
  | "1xl:pb-9"
  | "xl:pb-9"
  | "lg:pb-9"
  | "md:pb-9"
  | "sm:pb-9"
  | "xs:pb-9"
  | "2xl:pb-10"
  | "1xl:pb-10"
  | "xl:pb-10"
  | "lg:pb-10"
  | "md:pb-10"
  | "sm:pb-10"
  | "xs:pb-10"
  | "2xl:pb-11"
  | "1xl:pb-11"
  | "xl:pb-11"
  | "lg:pb-11"
  | "md:pb-11"
  | "sm:pb-11"
  | "xs:pb-11"
  | "2xl:pb-12"
  | "1xl:pb-12"
  | "xl:pb-12"
  | "lg:pb-12"
  | "md:pb-12"
  | "sm:pb-12"
  | "xs:pb-12"
  | "2xl:pb-14"
  | "1xl:pb-14"
  | "xl:pb-14"
  | "lg:pb-14"
  | "md:pb-14"
  | "sm:pb-14"
  | "xs:pb-14"
  | "2xl:pb-16"
  | "1xl:pb-16"
  | "xl:pb-16"
  | "lg:pb-16"
  | "md:pb-16"
  | "sm:pb-16"
  | "xs:pb-16"
  | "2xl:pb-20"
  | "1xl:pb-20"
  | "xl:pb-20"
  | "lg:pb-20"
  | "md:pb-20"
  | "sm:pb-20"
  | "xs:pb-20"
  | "2xl:pb-24"
  | "1xl:pb-24"
  | "xl:pb-24"
  | "lg:pb-24"
  | "md:pb-24"
  | "sm:pb-24"
  | "xs:pb-24"
  | "2xl:pb-28"
  | "1xl:pb-28"
  | "xl:pb-28"
  | "lg:pb-28"
  | "md:pb-28"
  | "sm:pb-28"
  | "xs:pb-28"
  | "2xl:pb-32"
  | "1xl:pb-32"
  | "xl:pb-32"
  | "lg:pb-32"
  | "md:pb-32"
  | "sm:pb-32"
  | "xs:pb-32"
  | "2xl:pb-36"
  | "1xl:pb-36"
  | "xl:pb-36"
  | "lg:pb-36"
  | "md:pb-36"
  | "sm:pb-36"
  | "xs:pb-36"
  | "2xl:pb-40"
  | "1xl:pb-40"
  | "xl:pb-40"
  | "lg:pb-40"
  | "md:pb-40"
  | "sm:pb-40"
  | "xs:pb-40"
  | "2xl:pb-44"
  | "1xl:pb-44"
  | "xl:pb-44"
  | "lg:pb-44"
  | "md:pb-44"
  | "sm:pb-44"
  | "xs:pb-44"
  | "2xl:pb-48"
  | "1xl:pb-48"
  | "xl:pb-48"
  | "lg:pb-48"
  | "md:pb-48"
  | "sm:pb-48"
  | "xs:pb-48"
  | "2xl:pb-52"
  | "1xl:pb-52"
  | "xl:pb-52"
  | "lg:pb-52"
  | "md:pb-52"
  | "sm:pb-52"
  | "xs:pb-52"
  | "2xl:pb-56"
  | "1xl:pb-56"
  | "xl:pb-56"
  | "lg:pb-56"
  | "md:pb-56"
  | "sm:pb-56"
  | "xs:pb-56"
  | "2xl:pb-60"
  | "1xl:pb-60"
  | "xl:pb-60"
  | "lg:pb-60"
  | "md:pb-60"
  | "sm:pb-60"
  | "xs:pb-60"
  | "2xl:pb-64"
  | "1xl:pb-64"
  | "xl:pb-64"
  | "lg:pb-64"
  | "md:pb-64"
  | "sm:pb-64"
  | "xs:pb-64"
  | "2xl:pb-72"
  | "1xl:pb-72"
  | "xl:pb-72"
  | "lg:pb-72"
  | "md:pb-72"
  | "sm:pb-72"
  | "xs:pb-72"
  | "2xl:pb-80"
  | "1xl:pb-80"
  | "xl:pb-80"
  | "lg:pb-80"
  | "md:pb-80"
  | "sm:pb-80"
  | "xs:pb-80"
  | "2xl:pb-96"
  | "1xl:pb-96"
  | "xl:pb-96"
  | "lg:pb-96"
  | "md:pb-96"
  | "sm:pb-96"
  | "xs:pb-96"
  | "2xl:pb-px"
  | "1xl:pb-px"
  | "xl:pb-px"
  | "lg:pb-px"
  | "md:pb-px"
  | "sm:pb-px"
  | "xs:pb-px"
  | "2xl:pb-0.5"
  | "1xl:pb-0.5"
  | "xl:pb-0.5"
  | "lg:pb-0.5"
  | "md:pb-0.5"
  | "sm:pb-0.5"
  | "xs:pb-0.5"
  | "2xl:pb-1.5"
  | "1xl:pb-1.5"
  | "xl:pb-1.5"
  | "lg:pb-1.5"
  | "md:pb-1.5"
  | "sm:pb-1.5"
  | "xs:pb-1.5"
  | "2xl:pb-2.5"
  | "1xl:pb-2.5"
  | "xl:pb-2.5"
  | "lg:pb-2.5"
  | "md:pb-2.5"
  | "sm:pb-2.5"
  | "xs:pb-2.5"
  | "2xl:pb-3.5"
  | "1xl:pb-3.5"
  | "xl:pb-3.5"
  | "lg:pb-3.5"
  | "md:pb-3.5"
  | "sm:pb-3.5"
  | "xs:pb-3.5"
  | "2xl:pl-0"
  | "1xl:pl-0"
  | "xl:pl-0"
  | "lg:pl-0"
  | "md:pl-0"
  | "sm:pl-0"
  | "xs:pl-0"
  | "2xl:pl-1"
  | "1xl:pl-1"
  | "xl:pl-1"
  | "lg:pl-1"
  | "md:pl-1"
  | "sm:pl-1"
  | "xs:pl-1"
  | "2xl:pl-2"
  | "1xl:pl-2"
  | "xl:pl-2"
  | "lg:pl-2"
  | "md:pl-2"
  | "sm:pl-2"
  | "xs:pl-2"
  | "2xl:pl-3"
  | "1xl:pl-3"
  | "xl:pl-3"
  | "lg:pl-3"
  | "md:pl-3"
  | "sm:pl-3"
  | "xs:pl-3"
  | "2xl:pl-4"
  | "1xl:pl-4"
  | "xl:pl-4"
  | "lg:pl-4"
  | "md:pl-4"
  | "sm:pl-4"
  | "xs:pl-4"
  | "2xl:pl-5"
  | "1xl:pl-5"
  | "xl:pl-5"
  | "lg:pl-5"
  | "md:pl-5"
  | "sm:pl-5"
  | "xs:pl-5"
  | "2xl:pl-6"
  | "1xl:pl-6"
  | "xl:pl-6"
  | "lg:pl-6"
  | "md:pl-6"
  | "sm:pl-6"
  | "xs:pl-6"
  | "2xl:pl-7"
  | "1xl:pl-7"
  | "xl:pl-7"
  | "lg:pl-7"
  | "md:pl-7"
  | "sm:pl-7"
  | "xs:pl-7"
  | "2xl:pl-8"
  | "1xl:pl-8"
  | "xl:pl-8"
  | "lg:pl-8"
  | "md:pl-8"
  | "sm:pl-8"
  | "xs:pl-8"
  | "2xl:pl-9"
  | "1xl:pl-9"
  | "xl:pl-9"
  | "lg:pl-9"
  | "md:pl-9"
  | "sm:pl-9"
  | "xs:pl-9"
  | "2xl:pl-10"
  | "1xl:pl-10"
  | "xl:pl-10"
  | "lg:pl-10"
  | "md:pl-10"
  | "sm:pl-10"
  | "xs:pl-10"
  | "2xl:pl-11"
  | "1xl:pl-11"
  | "xl:pl-11"
  | "lg:pl-11"
  | "md:pl-11"
  | "sm:pl-11"
  | "xs:pl-11"
  | "2xl:pl-12"
  | "1xl:pl-12"
  | "xl:pl-12"
  | "lg:pl-12"
  | "md:pl-12"
  | "sm:pl-12"
  | "xs:pl-12"
  | "2xl:pl-14"
  | "1xl:pl-14"
  | "xl:pl-14"
  | "lg:pl-14"
  | "md:pl-14"
  | "sm:pl-14"
  | "xs:pl-14"
  | "2xl:pl-16"
  | "1xl:pl-16"
  | "xl:pl-16"
  | "lg:pl-16"
  | "md:pl-16"
  | "sm:pl-16"
  | "xs:pl-16"
  | "2xl:pl-20"
  | "1xl:pl-20"
  | "xl:pl-20"
  | "lg:pl-20"
  | "md:pl-20"
  | "sm:pl-20"
  | "xs:pl-20"
  | "2xl:pl-24"
  | "1xl:pl-24"
  | "xl:pl-24"
  | "lg:pl-24"
  | "md:pl-24"
  | "sm:pl-24"
  | "xs:pl-24"
  | "2xl:pl-28"
  | "1xl:pl-28"
  | "xl:pl-28"
  | "lg:pl-28"
  | "md:pl-28"
  | "sm:pl-28"
  | "xs:pl-28"
  | "2xl:pl-32"
  | "1xl:pl-32"
  | "xl:pl-32"
  | "lg:pl-32"
  | "md:pl-32"
  | "sm:pl-32"
  | "xs:pl-32"
  | "2xl:pl-36"
  | "1xl:pl-36"
  | "xl:pl-36"
  | "lg:pl-36"
  | "md:pl-36"
  | "sm:pl-36"
  | "xs:pl-36"
  | "2xl:pl-40"
  | "1xl:pl-40"
  | "xl:pl-40"
  | "lg:pl-40"
  | "md:pl-40"
  | "sm:pl-40"
  | "xs:pl-40"
  | "2xl:pl-44"
  | "1xl:pl-44"
  | "xl:pl-44"
  | "lg:pl-44"
  | "md:pl-44"
  | "sm:pl-44"
  | "xs:pl-44"
  | "2xl:pl-48"
  | "1xl:pl-48"
  | "xl:pl-48"
  | "lg:pl-48"
  | "md:pl-48"
  | "sm:pl-48"
  | "xs:pl-48"
  | "2xl:pl-52"
  | "1xl:pl-52"
  | "xl:pl-52"
  | "lg:pl-52"
  | "md:pl-52"
  | "sm:pl-52"
  | "xs:pl-52"
  | "2xl:pl-56"
  | "1xl:pl-56"
  | "xl:pl-56"
  | "lg:pl-56"
  | "md:pl-56"
  | "sm:pl-56"
  | "xs:pl-56"
  | "2xl:pl-60"
  | "1xl:pl-60"
  | "xl:pl-60"
  | "lg:pl-60"
  | "md:pl-60"
  | "sm:pl-60"
  | "xs:pl-60"
  | "2xl:pl-64"
  | "1xl:pl-64"
  | "xl:pl-64"
  | "lg:pl-64"
  | "md:pl-64"
  | "sm:pl-64"
  | "xs:pl-64"
  | "2xl:pl-72"
  | "1xl:pl-72"
  | "xl:pl-72"
  | "lg:pl-72"
  | "md:pl-72"
  | "sm:pl-72"
  | "xs:pl-72"
  | "2xl:pl-80"
  | "1xl:pl-80"
  | "xl:pl-80"
  | "lg:pl-80"
  | "md:pl-80"
  | "sm:pl-80"
  | "xs:pl-80"
  | "2xl:pl-96"
  | "1xl:pl-96"
  | "xl:pl-96"
  | "lg:pl-96"
  | "md:pl-96"
  | "sm:pl-96"
  | "xs:pl-96"
  | "2xl:pl-px"
  | "1xl:pl-px"
  | "xl:pl-px"
  | "lg:pl-px"
  | "md:pl-px"
  | "sm:pl-px"
  | "xs:pl-px"
  | "2xl:pl-0.5"
  | "1xl:pl-0.5"
  | "xl:pl-0.5"
  | "lg:pl-0.5"
  | "md:pl-0.5"
  | "sm:pl-0.5"
  | "xs:pl-0.5"
  | "2xl:pl-1.5"
  | "1xl:pl-1.5"
  | "xl:pl-1.5"
  | "lg:pl-1.5"
  | "md:pl-1.5"
  | "sm:pl-1.5"
  | "xs:pl-1.5"
  | "2xl:pl-2.5"
  | "1xl:pl-2.5"
  | "xl:pl-2.5"
  | "lg:pl-2.5"
  | "md:pl-2.5"
  | "sm:pl-2.5"
  | "xs:pl-2.5"
  | "2xl:pl-3.5"
  | "1xl:pl-3.5"
  | "xl:pl-3.5"
  | "lg:pl-3.5"
  | "md:pl-3.5"
  | "sm:pl-3.5"
  | "xs:pl-3.5"
  | "2xl:place-content-start"
  | "1xl:place-content-start"
  | "xl:place-content-start"
  | "lg:place-content-start"
  | "md:place-content-start"
  | "sm:place-content-start"
  | "xs:place-content-start"
  | "2xl:place-content-center"
  | "1xl:place-content-center"
  | "xl:place-content-center"
  | "lg:place-content-center"
  | "md:place-content-center"
  | "sm:place-content-center"
  | "xs:place-content-center"
  | "2xl:place-content-end"
  | "1xl:place-content-end"
  | "xl:place-content-end"
  | "lg:place-content-end"
  | "md:place-content-end"
  | "sm:place-content-end"
  | "xs:place-content-end"
  | "2xl:place-content-between"
  | "1xl:place-content-between"
  | "xl:place-content-between"
  | "lg:place-content-between"
  | "md:place-content-between"
  | "sm:place-content-between"
  | "xs:place-content-between"
  | "2xl:place-content-around"
  | "1xl:place-content-around"
  | "xl:place-content-around"
  | "lg:place-content-around"
  | "md:place-content-around"
  | "sm:place-content-around"
  | "xs:place-content-around"
  | "2xl:place-content-evenly"
  | "1xl:place-content-evenly"
  | "xl:place-content-evenly"
  | "lg:place-content-evenly"
  | "md:place-content-evenly"
  | "sm:place-content-evenly"
  | "xs:place-content-evenly"
  | "2xl:place-content-stretch"
  | "1xl:place-content-stretch"
  | "xl:place-content-stretch"
  | "lg:place-content-stretch"
  | "md:place-content-stretch"
  | "sm:place-content-stretch"
  | "xs:place-content-stretch"
  | "2xl:place-items-auto"
  | "1xl:place-items-auto"
  | "xl:place-items-auto"
  | "lg:place-items-auto"
  | "md:place-items-auto"
  | "sm:place-items-auto"
  | "xs:place-items-auto"
  | "2xl:place-items-start"
  | "1xl:place-items-start"
  | "xl:place-items-start"
  | "lg:place-items-start"
  | "md:place-items-start"
  | "sm:place-items-start"
  | "xs:place-items-start"
  | "2xl:place-items-center"
  | "1xl:place-items-center"
  | "xl:place-items-center"
  | "lg:place-items-center"
  | "md:place-items-center"
  | "sm:place-items-center"
  | "xs:place-items-center"
  | "2xl:place-items-end"
  | "1xl:place-items-end"
  | "xl:place-items-end"
  | "lg:place-items-end"
  | "md:place-items-end"
  | "sm:place-items-end"
  | "xs:place-items-end"
  | "2xl:place-items-stretch"
  | "1xl:place-items-stretch"
  | "xl:place-items-stretch"
  | "lg:place-items-stretch"
  | "md:place-items-stretch"
  | "sm:place-items-stretch"
  | "xs:place-items-stretch"
  | "2xl:place-self-auto"
  | "1xl:place-self-auto"
  | "xl:place-self-auto"
  | "lg:place-self-auto"
  | "md:place-self-auto"
  | "sm:place-self-auto"
  | "xs:place-self-auto"
  | "2xl:place-self-start"
  | "1xl:place-self-start"
  | "xl:place-self-start"
  | "lg:place-self-start"
  | "md:place-self-start"
  | "sm:place-self-start"
  | "xs:place-self-start"
  | "2xl:place-self-center"
  | "1xl:place-self-center"
  | "xl:place-self-center"
  | "lg:place-self-center"
  | "md:place-self-center"
  | "sm:place-self-center"
  | "xs:place-self-center"
  | "2xl:place-self-end"
  | "1xl:place-self-end"
  | "xl:place-self-end"
  | "lg:place-self-end"
  | "md:place-self-end"
  | "sm:place-self-end"
  | "xs:place-self-end"
  | "2xl:place-self-stretch"
  | "1xl:place-self-stretch"
  | "xl:place-self-stretch"
  | "lg:place-self-stretch"
  | "md:place-self-stretch"
  | "sm:place-self-stretch"
  | "xs:place-self-stretch"
  | "2xl:placeholder-transparent"
  | "1xl:placeholder-transparent"
  | "xl:placeholder-transparent"
  | "lg:placeholder-transparent"
  | "md:placeholder-transparent"
  | "sm:placeholder-transparent"
  | "xs:placeholder-transparent"
  | "focus:placeholder-transparent"
  | "2xl:placeholder-current"
  | "1xl:placeholder-current"
  | "xl:placeholder-current"
  | "lg:placeholder-current"
  | "md:placeholder-current"
  | "sm:placeholder-current"
  | "xs:placeholder-current"
  | "focus:placeholder-current"
  | "2xl:placeholder-black"
  | "1xl:placeholder-black"
  | "xl:placeholder-black"
  | "lg:placeholder-black"
  | "md:placeholder-black"
  | "sm:placeholder-black"
  | "xs:placeholder-black"
  | "focus:placeholder-black"
  | "2xl:placeholder-white"
  | "1xl:placeholder-white"
  | "xl:placeholder-white"
  | "lg:placeholder-white"
  | "md:placeholder-white"
  | "sm:placeholder-white"
  | "xs:placeholder-white"
  | "focus:placeholder-white"
  | "2xl:placeholder-gray-50"
  | "1xl:placeholder-gray-50"
  | "xl:placeholder-gray-50"
  | "lg:placeholder-gray-50"
  | "md:placeholder-gray-50"
  | "sm:placeholder-gray-50"
  | "xs:placeholder-gray-50"
  | "focus:placeholder-gray-50"
  | "2xl:placeholder-gray-100"
  | "1xl:placeholder-gray-100"
  | "xl:placeholder-gray-100"
  | "lg:placeholder-gray-100"
  | "md:placeholder-gray-100"
  | "sm:placeholder-gray-100"
  | "xs:placeholder-gray-100"
  | "focus:placeholder-gray-100"
  | "2xl:placeholder-gray-200"
  | "1xl:placeholder-gray-200"
  | "xl:placeholder-gray-200"
  | "lg:placeholder-gray-200"
  | "md:placeholder-gray-200"
  | "sm:placeholder-gray-200"
  | "xs:placeholder-gray-200"
  | "focus:placeholder-gray-200"
  | "2xl:placeholder-gray-300"
  | "1xl:placeholder-gray-300"
  | "xl:placeholder-gray-300"
  | "lg:placeholder-gray-300"
  | "md:placeholder-gray-300"
  | "sm:placeholder-gray-300"
  | "xs:placeholder-gray-300"
  | "focus:placeholder-gray-300"
  | "2xl:placeholder-gray-400"
  | "1xl:placeholder-gray-400"
  | "xl:placeholder-gray-400"
  | "lg:placeholder-gray-400"
  | "md:placeholder-gray-400"
  | "sm:placeholder-gray-400"
  | "xs:placeholder-gray-400"
  | "focus:placeholder-gray-400"
  | "2xl:placeholder-gray-500"
  | "1xl:placeholder-gray-500"
  | "xl:placeholder-gray-500"
  | "lg:placeholder-gray-500"
  | "md:placeholder-gray-500"
  | "sm:placeholder-gray-500"
  | "xs:placeholder-gray-500"
  | "focus:placeholder-gray-500"
  | "2xl:placeholder-gray-600"
  | "1xl:placeholder-gray-600"
  | "xl:placeholder-gray-600"
  | "lg:placeholder-gray-600"
  | "md:placeholder-gray-600"
  | "sm:placeholder-gray-600"
  | "xs:placeholder-gray-600"
  | "focus:placeholder-gray-600"
  | "2xl:placeholder-gray-700"
  | "1xl:placeholder-gray-700"
  | "xl:placeholder-gray-700"
  | "lg:placeholder-gray-700"
  | "md:placeholder-gray-700"
  | "sm:placeholder-gray-700"
  | "xs:placeholder-gray-700"
  | "focus:placeholder-gray-700"
  | "2xl:placeholder-gray-800"
  | "1xl:placeholder-gray-800"
  | "xl:placeholder-gray-800"
  | "lg:placeholder-gray-800"
  | "md:placeholder-gray-800"
  | "sm:placeholder-gray-800"
  | "xs:placeholder-gray-800"
  | "focus:placeholder-gray-800"
  | "2xl:placeholder-gray-900"
  | "1xl:placeholder-gray-900"
  | "xl:placeholder-gray-900"
  | "lg:placeholder-gray-900"
  | "md:placeholder-gray-900"
  | "sm:placeholder-gray-900"
  | "xs:placeholder-gray-900"
  | "focus:placeholder-gray-900"
  | "2xl:placeholder-red-50"
  | "1xl:placeholder-red-50"
  | "xl:placeholder-red-50"
  | "lg:placeholder-red-50"
  | "md:placeholder-red-50"
  | "sm:placeholder-red-50"
  | "xs:placeholder-red-50"
  | "focus:placeholder-red-50"
  | "2xl:placeholder-red-100"
  | "1xl:placeholder-red-100"
  | "xl:placeholder-red-100"
  | "lg:placeholder-red-100"
  | "md:placeholder-red-100"
  | "sm:placeholder-red-100"
  | "xs:placeholder-red-100"
  | "focus:placeholder-red-100"
  | "2xl:placeholder-red-200"
  | "1xl:placeholder-red-200"
  | "xl:placeholder-red-200"
  | "lg:placeholder-red-200"
  | "md:placeholder-red-200"
  | "sm:placeholder-red-200"
  | "xs:placeholder-red-200"
  | "focus:placeholder-red-200"
  | "2xl:placeholder-red-300"
  | "1xl:placeholder-red-300"
  | "xl:placeholder-red-300"
  | "lg:placeholder-red-300"
  | "md:placeholder-red-300"
  | "sm:placeholder-red-300"
  | "xs:placeholder-red-300"
  | "focus:placeholder-red-300"
  | "2xl:placeholder-red-400"
  | "1xl:placeholder-red-400"
  | "xl:placeholder-red-400"
  | "lg:placeholder-red-400"
  | "md:placeholder-red-400"
  | "sm:placeholder-red-400"
  | "xs:placeholder-red-400"
  | "focus:placeholder-red-400"
  | "2xl:placeholder-red-500"
  | "1xl:placeholder-red-500"
  | "xl:placeholder-red-500"
  | "lg:placeholder-red-500"
  | "md:placeholder-red-500"
  | "sm:placeholder-red-500"
  | "xs:placeholder-red-500"
  | "focus:placeholder-red-500"
  | "2xl:placeholder-red-600"
  | "1xl:placeholder-red-600"
  | "xl:placeholder-red-600"
  | "lg:placeholder-red-600"
  | "md:placeholder-red-600"
  | "sm:placeholder-red-600"
  | "xs:placeholder-red-600"
  | "focus:placeholder-red-600"
  | "2xl:placeholder-red-700"
  | "1xl:placeholder-red-700"
  | "xl:placeholder-red-700"
  | "lg:placeholder-red-700"
  | "md:placeholder-red-700"
  | "sm:placeholder-red-700"
  | "xs:placeholder-red-700"
  | "focus:placeholder-red-700"
  | "2xl:placeholder-red-800"
  | "1xl:placeholder-red-800"
  | "xl:placeholder-red-800"
  | "lg:placeholder-red-800"
  | "md:placeholder-red-800"
  | "sm:placeholder-red-800"
  | "xs:placeholder-red-800"
  | "focus:placeholder-red-800"
  | "2xl:placeholder-red-900"
  | "1xl:placeholder-red-900"
  | "xl:placeholder-red-900"
  | "lg:placeholder-red-900"
  | "md:placeholder-red-900"
  | "sm:placeholder-red-900"
  | "xs:placeholder-red-900"
  | "focus:placeholder-red-900"
  | "2xl:placeholder-yellow-50"
  | "1xl:placeholder-yellow-50"
  | "xl:placeholder-yellow-50"
  | "lg:placeholder-yellow-50"
  | "md:placeholder-yellow-50"
  | "sm:placeholder-yellow-50"
  | "xs:placeholder-yellow-50"
  | "focus:placeholder-yellow-50"
  | "2xl:placeholder-yellow-100"
  | "1xl:placeholder-yellow-100"
  | "xl:placeholder-yellow-100"
  | "lg:placeholder-yellow-100"
  | "md:placeholder-yellow-100"
  | "sm:placeholder-yellow-100"
  | "xs:placeholder-yellow-100"
  | "focus:placeholder-yellow-100"
  | "2xl:placeholder-yellow-200"
  | "1xl:placeholder-yellow-200"
  | "xl:placeholder-yellow-200"
  | "lg:placeholder-yellow-200"
  | "md:placeholder-yellow-200"
  | "sm:placeholder-yellow-200"
  | "xs:placeholder-yellow-200"
  | "focus:placeholder-yellow-200"
  | "2xl:placeholder-yellow-300"
  | "1xl:placeholder-yellow-300"
  | "xl:placeholder-yellow-300"
  | "lg:placeholder-yellow-300"
  | "md:placeholder-yellow-300"
  | "sm:placeholder-yellow-300"
  | "xs:placeholder-yellow-300"
  | "focus:placeholder-yellow-300"
  | "2xl:placeholder-yellow-400"
  | "1xl:placeholder-yellow-400"
  | "xl:placeholder-yellow-400"
  | "lg:placeholder-yellow-400"
  | "md:placeholder-yellow-400"
  | "sm:placeholder-yellow-400"
  | "xs:placeholder-yellow-400"
  | "focus:placeholder-yellow-400"
  | "2xl:placeholder-yellow-500"
  | "1xl:placeholder-yellow-500"
  | "xl:placeholder-yellow-500"
  | "lg:placeholder-yellow-500"
  | "md:placeholder-yellow-500"
  | "sm:placeholder-yellow-500"
  | "xs:placeholder-yellow-500"
  | "focus:placeholder-yellow-500"
  | "2xl:placeholder-yellow-600"
  | "1xl:placeholder-yellow-600"
  | "xl:placeholder-yellow-600"
  | "lg:placeholder-yellow-600"
  | "md:placeholder-yellow-600"
  | "sm:placeholder-yellow-600"
  | "xs:placeholder-yellow-600"
  | "focus:placeholder-yellow-600"
  | "2xl:placeholder-yellow-700"
  | "1xl:placeholder-yellow-700"
  | "xl:placeholder-yellow-700"
  | "lg:placeholder-yellow-700"
  | "md:placeholder-yellow-700"
  | "sm:placeholder-yellow-700"
  | "xs:placeholder-yellow-700"
  | "focus:placeholder-yellow-700"
  | "2xl:placeholder-yellow-800"
  | "1xl:placeholder-yellow-800"
  | "xl:placeholder-yellow-800"
  | "lg:placeholder-yellow-800"
  | "md:placeholder-yellow-800"
  | "sm:placeholder-yellow-800"
  | "xs:placeholder-yellow-800"
  | "focus:placeholder-yellow-800"
  | "2xl:placeholder-yellow-900"
  | "1xl:placeholder-yellow-900"
  | "xl:placeholder-yellow-900"
  | "lg:placeholder-yellow-900"
  | "md:placeholder-yellow-900"
  | "sm:placeholder-yellow-900"
  | "xs:placeholder-yellow-900"
  | "focus:placeholder-yellow-900"
  | "2xl:placeholder-green-50"
  | "1xl:placeholder-green-50"
  | "xl:placeholder-green-50"
  | "lg:placeholder-green-50"
  | "md:placeholder-green-50"
  | "sm:placeholder-green-50"
  | "xs:placeholder-green-50"
  | "focus:placeholder-green-50"
  | "2xl:placeholder-green-100"
  | "1xl:placeholder-green-100"
  | "xl:placeholder-green-100"
  | "lg:placeholder-green-100"
  | "md:placeholder-green-100"
  | "sm:placeholder-green-100"
  | "xs:placeholder-green-100"
  | "focus:placeholder-green-100"
  | "2xl:placeholder-green-200"
  | "1xl:placeholder-green-200"
  | "xl:placeholder-green-200"
  | "lg:placeholder-green-200"
  | "md:placeholder-green-200"
  | "sm:placeholder-green-200"
  | "xs:placeholder-green-200"
  | "focus:placeholder-green-200"
  | "2xl:placeholder-green-300"
  | "1xl:placeholder-green-300"
  | "xl:placeholder-green-300"
  | "lg:placeholder-green-300"
  | "md:placeholder-green-300"
  | "sm:placeholder-green-300"
  | "xs:placeholder-green-300"
  | "focus:placeholder-green-300"
  | "2xl:placeholder-green-400"
  | "1xl:placeholder-green-400"
  | "xl:placeholder-green-400"
  | "lg:placeholder-green-400"
  | "md:placeholder-green-400"
  | "sm:placeholder-green-400"
  | "xs:placeholder-green-400"
  | "focus:placeholder-green-400"
  | "2xl:placeholder-green-500"
  | "1xl:placeholder-green-500"
  | "xl:placeholder-green-500"
  | "lg:placeholder-green-500"
  | "md:placeholder-green-500"
  | "sm:placeholder-green-500"
  | "xs:placeholder-green-500"
  | "focus:placeholder-green-500"
  | "2xl:placeholder-green-600"
  | "1xl:placeholder-green-600"
  | "xl:placeholder-green-600"
  | "lg:placeholder-green-600"
  | "md:placeholder-green-600"
  | "sm:placeholder-green-600"
  | "xs:placeholder-green-600"
  | "focus:placeholder-green-600"
  | "2xl:placeholder-green-700"
  | "1xl:placeholder-green-700"
  | "xl:placeholder-green-700"
  | "lg:placeholder-green-700"
  | "md:placeholder-green-700"
  | "sm:placeholder-green-700"
  | "xs:placeholder-green-700"
  | "focus:placeholder-green-700"
  | "2xl:placeholder-green-800"
  | "1xl:placeholder-green-800"
  | "xl:placeholder-green-800"
  | "lg:placeholder-green-800"
  | "md:placeholder-green-800"
  | "sm:placeholder-green-800"
  | "xs:placeholder-green-800"
  | "focus:placeholder-green-800"
  | "2xl:placeholder-green-900"
  | "1xl:placeholder-green-900"
  | "xl:placeholder-green-900"
  | "lg:placeholder-green-900"
  | "md:placeholder-green-900"
  | "sm:placeholder-green-900"
  | "xs:placeholder-green-900"
  | "focus:placeholder-green-900"
  | "2xl:placeholder-blue-50"
  | "1xl:placeholder-blue-50"
  | "xl:placeholder-blue-50"
  | "lg:placeholder-blue-50"
  | "md:placeholder-blue-50"
  | "sm:placeholder-blue-50"
  | "xs:placeholder-blue-50"
  | "focus:placeholder-blue-50"
  | "2xl:placeholder-blue-100"
  | "1xl:placeholder-blue-100"
  | "xl:placeholder-blue-100"
  | "lg:placeholder-blue-100"
  | "md:placeholder-blue-100"
  | "sm:placeholder-blue-100"
  | "xs:placeholder-blue-100"
  | "focus:placeholder-blue-100"
  | "2xl:placeholder-blue-200"
  | "1xl:placeholder-blue-200"
  | "xl:placeholder-blue-200"
  | "lg:placeholder-blue-200"
  | "md:placeholder-blue-200"
  | "sm:placeholder-blue-200"
  | "xs:placeholder-blue-200"
  | "focus:placeholder-blue-200"
  | "2xl:placeholder-blue-300"
  | "1xl:placeholder-blue-300"
  | "xl:placeholder-blue-300"
  | "lg:placeholder-blue-300"
  | "md:placeholder-blue-300"
  | "sm:placeholder-blue-300"
  | "xs:placeholder-blue-300"
  | "focus:placeholder-blue-300"
  | "2xl:placeholder-blue-400"
  | "1xl:placeholder-blue-400"
  | "xl:placeholder-blue-400"
  | "lg:placeholder-blue-400"
  | "md:placeholder-blue-400"
  | "sm:placeholder-blue-400"
  | "xs:placeholder-blue-400"
  | "focus:placeholder-blue-400"
  | "2xl:placeholder-blue-500"
  | "1xl:placeholder-blue-500"
  | "xl:placeholder-blue-500"
  | "lg:placeholder-blue-500"
  | "md:placeholder-blue-500"
  | "sm:placeholder-blue-500"
  | "xs:placeholder-blue-500"
  | "focus:placeholder-blue-500"
  | "2xl:placeholder-blue-600"
  | "1xl:placeholder-blue-600"
  | "xl:placeholder-blue-600"
  | "lg:placeholder-blue-600"
  | "md:placeholder-blue-600"
  | "sm:placeholder-blue-600"
  | "xs:placeholder-blue-600"
  | "focus:placeholder-blue-600"
  | "2xl:placeholder-blue-700"
  | "1xl:placeholder-blue-700"
  | "xl:placeholder-blue-700"
  | "lg:placeholder-blue-700"
  | "md:placeholder-blue-700"
  | "sm:placeholder-blue-700"
  | "xs:placeholder-blue-700"
  | "focus:placeholder-blue-700"
  | "2xl:placeholder-blue-800"
  | "1xl:placeholder-blue-800"
  | "xl:placeholder-blue-800"
  | "lg:placeholder-blue-800"
  | "md:placeholder-blue-800"
  | "sm:placeholder-blue-800"
  | "xs:placeholder-blue-800"
  | "focus:placeholder-blue-800"
  | "2xl:placeholder-blue-900"
  | "1xl:placeholder-blue-900"
  | "xl:placeholder-blue-900"
  | "lg:placeholder-blue-900"
  | "md:placeholder-blue-900"
  | "sm:placeholder-blue-900"
  | "xs:placeholder-blue-900"
  | "focus:placeholder-blue-900"
  | "2xl:placeholder-indigo-50"
  | "1xl:placeholder-indigo-50"
  | "xl:placeholder-indigo-50"
  | "lg:placeholder-indigo-50"
  | "md:placeholder-indigo-50"
  | "sm:placeholder-indigo-50"
  | "xs:placeholder-indigo-50"
  | "focus:placeholder-indigo-50"
  | "2xl:placeholder-indigo-100"
  | "1xl:placeholder-indigo-100"
  | "xl:placeholder-indigo-100"
  | "lg:placeholder-indigo-100"
  | "md:placeholder-indigo-100"
  | "sm:placeholder-indigo-100"
  | "xs:placeholder-indigo-100"
  | "focus:placeholder-indigo-100"
  | "2xl:placeholder-indigo-200"
  | "1xl:placeholder-indigo-200"
  | "xl:placeholder-indigo-200"
  | "lg:placeholder-indigo-200"
  | "md:placeholder-indigo-200"
  | "sm:placeholder-indigo-200"
  | "xs:placeholder-indigo-200"
  | "focus:placeholder-indigo-200"
  | "2xl:placeholder-indigo-300"
  | "1xl:placeholder-indigo-300"
  | "xl:placeholder-indigo-300"
  | "lg:placeholder-indigo-300"
  | "md:placeholder-indigo-300"
  | "sm:placeholder-indigo-300"
  | "xs:placeholder-indigo-300"
  | "focus:placeholder-indigo-300"
  | "2xl:placeholder-indigo-400"
  | "1xl:placeholder-indigo-400"
  | "xl:placeholder-indigo-400"
  | "lg:placeholder-indigo-400"
  | "md:placeholder-indigo-400"
  | "sm:placeholder-indigo-400"
  | "xs:placeholder-indigo-400"
  | "focus:placeholder-indigo-400"
  | "2xl:placeholder-indigo-500"
  | "1xl:placeholder-indigo-500"
  | "xl:placeholder-indigo-500"
  | "lg:placeholder-indigo-500"
  | "md:placeholder-indigo-500"
  | "sm:placeholder-indigo-500"
  | "xs:placeholder-indigo-500"
  | "focus:placeholder-indigo-500"
  | "2xl:placeholder-indigo-600"
  | "1xl:placeholder-indigo-600"
  | "xl:placeholder-indigo-600"
  | "lg:placeholder-indigo-600"
  | "md:placeholder-indigo-600"
  | "sm:placeholder-indigo-600"
  | "xs:placeholder-indigo-600"
  | "focus:placeholder-indigo-600"
  | "2xl:placeholder-indigo-700"
  | "1xl:placeholder-indigo-700"
  | "xl:placeholder-indigo-700"
  | "lg:placeholder-indigo-700"
  | "md:placeholder-indigo-700"
  | "sm:placeholder-indigo-700"
  | "xs:placeholder-indigo-700"
  | "focus:placeholder-indigo-700"
  | "2xl:placeholder-indigo-800"
  | "1xl:placeholder-indigo-800"
  | "xl:placeholder-indigo-800"
  | "lg:placeholder-indigo-800"
  | "md:placeholder-indigo-800"
  | "sm:placeholder-indigo-800"
  | "xs:placeholder-indigo-800"
  | "focus:placeholder-indigo-800"
  | "2xl:placeholder-indigo-900"
  | "1xl:placeholder-indigo-900"
  | "xl:placeholder-indigo-900"
  | "lg:placeholder-indigo-900"
  | "md:placeholder-indigo-900"
  | "sm:placeholder-indigo-900"
  | "xs:placeholder-indigo-900"
  | "focus:placeholder-indigo-900"
  | "2xl:placeholder-purple-50"
  | "1xl:placeholder-purple-50"
  | "xl:placeholder-purple-50"
  | "lg:placeholder-purple-50"
  | "md:placeholder-purple-50"
  | "sm:placeholder-purple-50"
  | "xs:placeholder-purple-50"
  | "focus:placeholder-purple-50"
  | "2xl:placeholder-purple-100"
  | "1xl:placeholder-purple-100"
  | "xl:placeholder-purple-100"
  | "lg:placeholder-purple-100"
  | "md:placeholder-purple-100"
  | "sm:placeholder-purple-100"
  | "xs:placeholder-purple-100"
  | "focus:placeholder-purple-100"
  | "2xl:placeholder-purple-200"
  | "1xl:placeholder-purple-200"
  | "xl:placeholder-purple-200"
  | "lg:placeholder-purple-200"
  | "md:placeholder-purple-200"
  | "sm:placeholder-purple-200"
  | "xs:placeholder-purple-200"
  | "focus:placeholder-purple-200"
  | "2xl:placeholder-purple-300"
  | "1xl:placeholder-purple-300"
  | "xl:placeholder-purple-300"
  | "lg:placeholder-purple-300"
  | "md:placeholder-purple-300"
  | "sm:placeholder-purple-300"
  | "xs:placeholder-purple-300"
  | "focus:placeholder-purple-300"
  | "2xl:placeholder-purple-400"
  | "1xl:placeholder-purple-400"
  | "xl:placeholder-purple-400"
  | "lg:placeholder-purple-400"
  | "md:placeholder-purple-400"
  | "sm:placeholder-purple-400"
  | "xs:placeholder-purple-400"
  | "focus:placeholder-purple-400"
  | "2xl:placeholder-purple-500"
  | "1xl:placeholder-purple-500"
  | "xl:placeholder-purple-500"
  | "lg:placeholder-purple-500"
  | "md:placeholder-purple-500"
  | "sm:placeholder-purple-500"
  | "xs:placeholder-purple-500"
  | "focus:placeholder-purple-500"
  | "2xl:placeholder-purple-600"
  | "1xl:placeholder-purple-600"
  | "xl:placeholder-purple-600"
  | "lg:placeholder-purple-600"
  | "md:placeholder-purple-600"
  | "sm:placeholder-purple-600"
  | "xs:placeholder-purple-600"
  | "focus:placeholder-purple-600"
  | "2xl:placeholder-purple-700"
  | "1xl:placeholder-purple-700"
  | "xl:placeholder-purple-700"
  | "lg:placeholder-purple-700"
  | "md:placeholder-purple-700"
  | "sm:placeholder-purple-700"
  | "xs:placeholder-purple-700"
  | "focus:placeholder-purple-700"
  | "2xl:placeholder-purple-800"
  | "1xl:placeholder-purple-800"
  | "xl:placeholder-purple-800"
  | "lg:placeholder-purple-800"
  | "md:placeholder-purple-800"
  | "sm:placeholder-purple-800"
  | "xs:placeholder-purple-800"
  | "focus:placeholder-purple-800"
  | "2xl:placeholder-purple-900"
  | "1xl:placeholder-purple-900"
  | "xl:placeholder-purple-900"
  | "lg:placeholder-purple-900"
  | "md:placeholder-purple-900"
  | "sm:placeholder-purple-900"
  | "xs:placeholder-purple-900"
  | "focus:placeholder-purple-900"
  | "2xl:placeholder-pink-50"
  | "1xl:placeholder-pink-50"
  | "xl:placeholder-pink-50"
  | "lg:placeholder-pink-50"
  | "md:placeholder-pink-50"
  | "sm:placeholder-pink-50"
  | "xs:placeholder-pink-50"
  | "focus:placeholder-pink-50"
  | "2xl:placeholder-pink-100"
  | "1xl:placeholder-pink-100"
  | "xl:placeholder-pink-100"
  | "lg:placeholder-pink-100"
  | "md:placeholder-pink-100"
  | "sm:placeholder-pink-100"
  | "xs:placeholder-pink-100"
  | "focus:placeholder-pink-100"
  | "2xl:placeholder-pink-200"
  | "1xl:placeholder-pink-200"
  | "xl:placeholder-pink-200"
  | "lg:placeholder-pink-200"
  | "md:placeholder-pink-200"
  | "sm:placeholder-pink-200"
  | "xs:placeholder-pink-200"
  | "focus:placeholder-pink-200"
  | "2xl:placeholder-pink-300"
  | "1xl:placeholder-pink-300"
  | "xl:placeholder-pink-300"
  | "lg:placeholder-pink-300"
  | "md:placeholder-pink-300"
  | "sm:placeholder-pink-300"
  | "xs:placeholder-pink-300"
  | "focus:placeholder-pink-300"
  | "2xl:placeholder-pink-400"
  | "1xl:placeholder-pink-400"
  | "xl:placeholder-pink-400"
  | "lg:placeholder-pink-400"
  | "md:placeholder-pink-400"
  | "sm:placeholder-pink-400"
  | "xs:placeholder-pink-400"
  | "focus:placeholder-pink-400"
  | "2xl:placeholder-pink-500"
  | "1xl:placeholder-pink-500"
  | "xl:placeholder-pink-500"
  | "lg:placeholder-pink-500"
  | "md:placeholder-pink-500"
  | "sm:placeholder-pink-500"
  | "xs:placeholder-pink-500"
  | "focus:placeholder-pink-500"
  | "2xl:placeholder-pink-600"
  | "1xl:placeholder-pink-600"
  | "xl:placeholder-pink-600"
  | "lg:placeholder-pink-600"
  | "md:placeholder-pink-600"
  | "sm:placeholder-pink-600"
  | "xs:placeholder-pink-600"
  | "focus:placeholder-pink-600"
  | "2xl:placeholder-pink-700"
  | "1xl:placeholder-pink-700"
  | "xl:placeholder-pink-700"
  | "lg:placeholder-pink-700"
  | "md:placeholder-pink-700"
  | "sm:placeholder-pink-700"
  | "xs:placeholder-pink-700"
  | "focus:placeholder-pink-700"
  | "2xl:placeholder-pink-800"
  | "1xl:placeholder-pink-800"
  | "xl:placeholder-pink-800"
  | "lg:placeholder-pink-800"
  | "md:placeholder-pink-800"
  | "sm:placeholder-pink-800"
  | "xs:placeholder-pink-800"
  | "focus:placeholder-pink-800"
  | "2xl:placeholder-pink-900"
  | "1xl:placeholder-pink-900"
  | "xl:placeholder-pink-900"
  | "lg:placeholder-pink-900"
  | "md:placeholder-pink-900"
  | "sm:placeholder-pink-900"
  | "xs:placeholder-pink-900"
  | "focus:placeholder-pink-900"
  | "2xl:placeholder-title-color-1"
  | "1xl:placeholder-title-color-1"
  | "xl:placeholder-title-color-1"
  | "lg:placeholder-title-color-1"
  | "md:placeholder-title-color-1"
  | "sm:placeholder-title-color-1"
  | "xs:placeholder-title-color-1"
  | "focus:placeholder-title-color-1"
  | "2xl:placeholder-primary-bg-color"
  | "1xl:placeholder-primary-bg-color"
  | "xl:placeholder-primary-bg-color"
  | "lg:placeholder-primary-bg-color"
  | "md:placeholder-primary-bg-color"
  | "sm:placeholder-primary-bg-color"
  | "xs:placeholder-primary-bg-color"
  | "focus:placeholder-primary-bg-color"
  | "2xl:placeholder-secondary-bg-color"
  | "1xl:placeholder-secondary-bg-color"
  | "xl:placeholder-secondary-bg-color"
  | "lg:placeholder-secondary-bg-color"
  | "md:placeholder-secondary-bg-color"
  | "sm:placeholder-secondary-bg-color"
  | "xs:placeholder-secondary-bg-color"
  | "focus:placeholder-secondary-bg-color"
  | "2xl:placeholder-sidebar-bg-color"
  | "1xl:placeholder-sidebar-bg-color"
  | "xl:placeholder-sidebar-bg-color"
  | "lg:placeholder-sidebar-bg-color"
  | "md:placeholder-sidebar-bg-color"
  | "sm:placeholder-sidebar-bg-color"
  | "xs:placeholder-sidebar-bg-color"
  | "focus:placeholder-sidebar-bg-color"
  | "2xl:placeholder-header-bg-color"
  | "1xl:placeholder-header-bg-color"
  | "xl:placeholder-header-bg-color"
  | "lg:placeholder-header-bg-color"
  | "md:placeholder-header-bg-color"
  | "sm:placeholder-header-bg-color"
  | "xs:placeholder-header-bg-color"
  | "focus:placeholder-header-bg-color"
  | "2xl:placeholder-body-bg-color"
  | "1xl:placeholder-body-bg-color"
  | "xl:placeholder-body-bg-color"
  | "lg:placeholder-body-bg-color"
  | "md:placeholder-body-bg-color"
  | "sm:placeholder-body-bg-color"
  | "xs:placeholder-body-bg-color"
  | "focus:placeholder-body-bg-color"
  | "2xl:placeholder-main-color"
  | "1xl:placeholder-main-color"
  | "xl:placeholder-main-color"
  | "lg:placeholder-main-color"
  | "md:placeholder-main-color"
  | "sm:placeholder-main-color"
  | "xs:placeholder-main-color"
  | "focus:placeholder-main-color"
  | "2xl:placeholder-tertiary-bg-color"
  | "1xl:placeholder-tertiary-bg-color"
  | "xl:placeholder-tertiary-bg-color"
  | "lg:placeholder-tertiary-bg-color"
  | "md:placeholder-tertiary-bg-color"
  | "sm:placeholder-tertiary-bg-color"
  | "xs:placeholder-tertiary-bg-color"
  | "focus:placeholder-tertiary-bg-color"
  | "2xl:placeholder-quaternary-bg-color"
  | "1xl:placeholder-quaternary-bg-color"
  | "xl:placeholder-quaternary-bg-color"
  | "lg:placeholder-quaternary-bg-color"
  | "md:placeholder-quaternary-bg-color"
  | "sm:placeholder-quaternary-bg-color"
  | "xs:placeholder-quaternary-bg-color"
  | "focus:placeholder-quaternary-bg-color"
  | "2xl:placeholder-earnings-bg-color"
  | "1xl:placeholder-earnings-bg-color"
  | "xl:placeholder-earnings-bg-color"
  | "lg:placeholder-earnings-bg-color"
  | "md:placeholder-earnings-bg-color"
  | "sm:placeholder-earnings-bg-color"
  | "xs:placeholder-earnings-bg-color"
  | "focus:placeholder-earnings-bg-color"
  | "2xl:placeholder-card-secondary-bg-color"
  | "1xl:placeholder-card-secondary-bg-color"
  | "xl:placeholder-card-secondary-bg-color"
  | "lg:placeholder-card-secondary-bg-color"
  | "md:placeholder-card-secondary-bg-color"
  | "sm:placeholder-card-secondary-bg-color"
  | "xs:placeholder-card-secondary-bg-color"
  | "focus:placeholder-card-secondary-bg-color"
  | "2xl:placeholder-pink-color"
  | "1xl:placeholder-pink-color"
  | "xl:placeholder-pink-color"
  | "lg:placeholder-pink-color"
  | "md:placeholder-pink-color"
  | "sm:placeholder-pink-color"
  | "xs:placeholder-pink-color"
  | "focus:placeholder-pink-color"
  | "2xl:placeholder-go-live-text"
  | "1xl:placeholder-go-live-text"
  | "xl:placeholder-go-live-text"
  | "lg:placeholder-go-live-text"
  | "md:placeholder-go-live-text"
  | "sm:placeholder-go-live-text"
  | "xs:placeholder-go-live-text"
  | "focus:placeholder-go-live-text"
  | "2xl:placeholder-go-live-bg-color"
  | "1xl:placeholder-go-live-bg-color"
  | "xl:placeholder-go-live-bg-color"
  | "lg:placeholder-go-live-bg-color"
  | "md:placeholder-go-live-bg-color"
  | "sm:placeholder-go-live-bg-color"
  | "xs:placeholder-go-live-bg-color"
  | "focus:placeholder-go-live-bg-color"
  | "2xl:placeholder-gray-button"
  | "1xl:placeholder-gray-button"
  | "xl:placeholder-gray-button"
  | "lg:placeholder-gray-button"
  | "md:placeholder-gray-button"
  | "sm:placeholder-gray-button"
  | "xs:placeholder-gray-button"
  | "focus:placeholder-gray-button"
  | "2xl:placeholder-toggle-disabled"
  | "1xl:placeholder-toggle-disabled"
  | "xl:placeholder-toggle-disabled"
  | "lg:placeholder-toggle-disabled"
  | "md:placeholder-toggle-disabled"
  | "sm:placeholder-toggle-disabled"
  | "xs:placeholder-toggle-disabled"
  | "focus:placeholder-toggle-disabled"
  | "2xl:placeholder-tab-not-selected-color"
  | "1xl:placeholder-tab-not-selected-color"
  | "xl:placeholder-tab-not-selected-color"
  | "lg:placeholder-tab-not-selected-color"
  | "md:placeholder-tab-not-selected-color"
  | "sm:placeholder-tab-not-selected-color"
  | "xs:placeholder-tab-not-selected-color"
  | "focus:placeholder-tab-not-selected-color"
  | "2xl:placeholder-text-area-bg-color"
  | "1xl:placeholder-text-area-bg-color"
  | "xl:placeholder-text-area-bg-color"
  | "lg:placeholder-text-area-bg-color"
  | "md:placeholder-text-area-bg-color"
  | "sm:placeholder-text-area-bg-color"
  | "xs:placeholder-text-area-bg-color"
  | "focus:placeholder-text-area-bg-color"
  | "2xl:placeholder-table-primary-bg-color"
  | "1xl:placeholder-table-primary-bg-color"
  | "xl:placeholder-table-primary-bg-color"
  | "lg:placeholder-table-primary-bg-color"
  | "md:placeholder-table-primary-bg-color"
  | "sm:placeholder-table-primary-bg-color"
  | "xs:placeholder-table-primary-bg-color"
  | "focus:placeholder-table-primary-bg-color"
  | "2xl:placeholder-table-secondary-color"
  | "1xl:placeholder-table-secondary-color"
  | "xl:placeholder-table-secondary-color"
  | "lg:placeholder-table-secondary-color"
  | "md:placeholder-table-secondary-color"
  | "sm:placeholder-table-secondary-color"
  | "xs:placeholder-table-secondary-color"
  | "focus:placeholder-table-secondary-color"
  | "2xl:placeholder-modal-button-color"
  | "1xl:placeholder-modal-button-color"
  | "xl:placeholder-modal-button-color"
  | "lg:placeholder-modal-button-color"
  | "md:placeholder-modal-button-color"
  | "sm:placeholder-modal-button-color"
  | "xs:placeholder-modal-button-color"
  | "focus:placeholder-modal-button-color"
  | "2xl:placeholder-opacity-0"
  | "1xl:placeholder-opacity-0"
  | "xl:placeholder-opacity-0"
  | "lg:placeholder-opacity-0"
  | "md:placeholder-opacity-0"
  | "sm:placeholder-opacity-0"
  | "xs:placeholder-opacity-0"
  | "focus:placeholder-opacity-0"
  | "2xl:placeholder-opacity-5"
  | "1xl:placeholder-opacity-5"
  | "xl:placeholder-opacity-5"
  | "lg:placeholder-opacity-5"
  | "md:placeholder-opacity-5"
  | "sm:placeholder-opacity-5"
  | "xs:placeholder-opacity-5"
  | "focus:placeholder-opacity-5"
  | "2xl:placeholder-opacity-10"
  | "1xl:placeholder-opacity-10"
  | "xl:placeholder-opacity-10"
  | "lg:placeholder-opacity-10"
  | "md:placeholder-opacity-10"
  | "sm:placeholder-opacity-10"
  | "xs:placeholder-opacity-10"
  | "focus:placeholder-opacity-10"
  | "2xl:placeholder-opacity-20"
  | "1xl:placeholder-opacity-20"
  | "xl:placeholder-opacity-20"
  | "lg:placeholder-opacity-20"
  | "md:placeholder-opacity-20"
  | "sm:placeholder-opacity-20"
  | "xs:placeholder-opacity-20"
  | "focus:placeholder-opacity-20"
  | "2xl:placeholder-opacity-25"
  | "1xl:placeholder-opacity-25"
  | "xl:placeholder-opacity-25"
  | "lg:placeholder-opacity-25"
  | "md:placeholder-opacity-25"
  | "sm:placeholder-opacity-25"
  | "xs:placeholder-opacity-25"
  | "focus:placeholder-opacity-25"
  | "2xl:placeholder-opacity-30"
  | "1xl:placeholder-opacity-30"
  | "xl:placeholder-opacity-30"
  | "lg:placeholder-opacity-30"
  | "md:placeholder-opacity-30"
  | "sm:placeholder-opacity-30"
  | "xs:placeholder-opacity-30"
  | "focus:placeholder-opacity-30"
  | "2xl:placeholder-opacity-40"
  | "1xl:placeholder-opacity-40"
  | "xl:placeholder-opacity-40"
  | "lg:placeholder-opacity-40"
  | "md:placeholder-opacity-40"
  | "sm:placeholder-opacity-40"
  | "xs:placeholder-opacity-40"
  | "focus:placeholder-opacity-40"
  | "2xl:placeholder-opacity-50"
  | "1xl:placeholder-opacity-50"
  | "xl:placeholder-opacity-50"
  | "lg:placeholder-opacity-50"
  | "md:placeholder-opacity-50"
  | "sm:placeholder-opacity-50"
  | "xs:placeholder-opacity-50"
  | "focus:placeholder-opacity-50"
  | "2xl:placeholder-opacity-60"
  | "1xl:placeholder-opacity-60"
  | "xl:placeholder-opacity-60"
  | "lg:placeholder-opacity-60"
  | "md:placeholder-opacity-60"
  | "sm:placeholder-opacity-60"
  | "xs:placeholder-opacity-60"
  | "focus:placeholder-opacity-60"
  | "2xl:placeholder-opacity-70"
  | "1xl:placeholder-opacity-70"
  | "xl:placeholder-opacity-70"
  | "lg:placeholder-opacity-70"
  | "md:placeholder-opacity-70"
  | "sm:placeholder-opacity-70"
  | "xs:placeholder-opacity-70"
  | "focus:placeholder-opacity-70"
  | "2xl:placeholder-opacity-75"
  | "1xl:placeholder-opacity-75"
  | "xl:placeholder-opacity-75"
  | "lg:placeholder-opacity-75"
  | "md:placeholder-opacity-75"
  | "sm:placeholder-opacity-75"
  | "xs:placeholder-opacity-75"
  | "focus:placeholder-opacity-75"
  | "2xl:placeholder-opacity-80"
  | "1xl:placeholder-opacity-80"
  | "xl:placeholder-opacity-80"
  | "lg:placeholder-opacity-80"
  | "md:placeholder-opacity-80"
  | "sm:placeholder-opacity-80"
  | "xs:placeholder-opacity-80"
  | "focus:placeholder-opacity-80"
  | "2xl:placeholder-opacity-90"
  | "1xl:placeholder-opacity-90"
  | "xl:placeholder-opacity-90"
  | "lg:placeholder-opacity-90"
  | "md:placeholder-opacity-90"
  | "sm:placeholder-opacity-90"
  | "xs:placeholder-opacity-90"
  | "focus:placeholder-opacity-90"
  | "2xl:placeholder-opacity-95"
  | "1xl:placeholder-opacity-95"
  | "xl:placeholder-opacity-95"
  | "lg:placeholder-opacity-95"
  | "md:placeholder-opacity-95"
  | "sm:placeholder-opacity-95"
  | "xs:placeholder-opacity-95"
  | "focus:placeholder-opacity-95"
  | "2xl:placeholder-opacity-100"
  | "1xl:placeholder-opacity-100"
  | "xl:placeholder-opacity-100"
  | "lg:placeholder-opacity-100"
  | "md:placeholder-opacity-100"
  | "sm:placeholder-opacity-100"
  | "xs:placeholder-opacity-100"
  | "focus:placeholder-opacity-100"
  | "2xl:pointer-events-none"
  | "1xl:pointer-events-none"
  | "xl:pointer-events-none"
  | "lg:pointer-events-none"
  | "md:pointer-events-none"
  | "sm:pointer-events-none"
  | "xs:pointer-events-none"
  | "2xl:pointer-events-auto"
  | "1xl:pointer-events-auto"
  | "xl:pointer-events-auto"
  | "lg:pointer-events-auto"
  | "md:pointer-events-auto"
  | "sm:pointer-events-auto"
  | "xs:pointer-events-auto"
  | "2xl:static"
  | "1xl:static"
  | "xl:static"
  | "lg:static"
  | "md:static"
  | "sm:static"
  | "xs:static"
  | "2xl:fixed"
  | "1xl:fixed"
  | "xl:fixed"
  | "lg:fixed"
  | "md:fixed"
  | "sm:fixed"
  | "xs:fixed"
  | "2xl:absolute"
  | "1xl:absolute"
  | "xl:absolute"
  | "lg:absolute"
  | "md:absolute"
  | "sm:absolute"
  | "xs:absolute"
  | "2xl:relative"
  | "1xl:relative"
  | "xl:relative"
  | "lg:relative"
  | "md:relative"
  | "sm:relative"
  | "xs:relative"
  | "2xl:sticky"
  | "1xl:sticky"
  | "xl:sticky"
  | "lg:sticky"
  | "md:sticky"
  | "sm:sticky"
  | "xs:sticky"
  | "2xl:resize-none"
  | "1xl:resize-none"
  | "xl:resize-none"
  | "lg:resize-none"
  | "md:resize-none"
  | "sm:resize-none"
  | "xs:resize-none"
  | "2xl:resize"
  | "1xl:resize"
  | "xl:resize"
  | "lg:resize"
  | "md:resize"
  | "sm:resize"
  | "xs:resize"
  | "2xl:resize-y"
  | "1xl:resize-y"
  | "xl:resize-y"
  | "lg:resize-y"
  | "md:resize-y"
  | "sm:resize-y"
  | "xs:resize-y"
  | "2xl:resize-x"
  | "1xl:resize-x"
  | "xl:resize-x"
  | "lg:resize-x"
  | "md:resize-x"
  | "sm:resize-x"
  | "xs:resize-x"
  | "2xl:ring-transparent"
  | "1xl:ring-transparent"
  | "xl:ring-transparent"
  | "lg:ring-transparent"
  | "md:ring-transparent"
  | "sm:ring-transparent"
  | "xs:ring-transparent"
  | "focus-within:ring-transparent"
  | "focus:ring-transparent"
  | "2xl:ring-current"
  | "1xl:ring-current"
  | "xl:ring-current"
  | "lg:ring-current"
  | "md:ring-current"
  | "sm:ring-current"
  | "xs:ring-current"
  | "focus-within:ring-current"
  | "focus:ring-current"
  | "2xl:ring-black"
  | "1xl:ring-black"
  | "xl:ring-black"
  | "lg:ring-black"
  | "md:ring-black"
  | "sm:ring-black"
  | "xs:ring-black"
  | "focus-within:ring-black"
  | "focus:ring-black"
  | "2xl:ring-white"
  | "1xl:ring-white"
  | "xl:ring-white"
  | "lg:ring-white"
  | "md:ring-white"
  | "sm:ring-white"
  | "xs:ring-white"
  | "focus-within:ring-white"
  | "focus:ring-white"
  | "2xl:ring-gray"
  | "1xl:ring-gray"
  | "xl:ring-gray"
  | "lg:ring-gray"
  | "md:ring-gray"
  | "sm:ring-gray"
  | "xs:ring-gray"
  | "focus-within:ring-gray"
  | "focus:ring-gray"
  | "2xl:ring-red-50"
  | "1xl:ring-red-50"
  | "xl:ring-red-50"
  | "lg:ring-red-50"
  | "md:ring-red-50"
  | "sm:ring-red-50"
  | "xs:ring-red-50"
  | "focus-within:ring-red-50"
  | "focus:ring-red-50"
  | "2xl:ring-red-100"
  | "1xl:ring-red-100"
  | "xl:ring-red-100"
  | "lg:ring-red-100"
  | "md:ring-red-100"
  | "sm:ring-red-100"
  | "xs:ring-red-100"
  | "focus-within:ring-red-100"
  | "focus:ring-red-100"
  | "2xl:ring-red-200"
  | "1xl:ring-red-200"
  | "xl:ring-red-200"
  | "lg:ring-red-200"
  | "md:ring-red-200"
  | "sm:ring-red-200"
  | "xs:ring-red-200"
  | "focus-within:ring-red-200"
  | "focus:ring-red-200"
  | "2xl:ring-red-300"
  | "1xl:ring-red-300"
  | "xl:ring-red-300"
  | "lg:ring-red-300"
  | "md:ring-red-300"
  | "sm:ring-red-300"
  | "xs:ring-red-300"
  | "focus-within:ring-red-300"
  | "focus:ring-red-300"
  | "2xl:ring-red-400"
  | "1xl:ring-red-400"
  | "xl:ring-red-400"
  | "lg:ring-red-400"
  | "md:ring-red-400"
  | "sm:ring-red-400"
  | "xs:ring-red-400"
  | "focus-within:ring-red-400"
  | "focus:ring-red-400"
  | "2xl:ring-red-500"
  | "1xl:ring-red-500"
  | "xl:ring-red-500"
  | "lg:ring-red-500"
  | "md:ring-red-500"
  | "sm:ring-red-500"
  | "xs:ring-red-500"
  | "focus-within:ring-red-500"
  | "focus:ring-red-500"
  | "2xl:ring-red-600"
  | "1xl:ring-red-600"
  | "xl:ring-red-600"
  | "lg:ring-red-600"
  | "md:ring-red-600"
  | "sm:ring-red-600"
  | "xs:ring-red-600"
  | "focus-within:ring-red-600"
  | "focus:ring-red-600"
  | "2xl:ring-red-700"
  | "1xl:ring-red-700"
  | "xl:ring-red-700"
  | "lg:ring-red-700"
  | "md:ring-red-700"
  | "sm:ring-red-700"
  | "xs:ring-red-700"
  | "focus-within:ring-red-700"
  | "focus:ring-red-700"
  | "2xl:ring-red-800"
  | "1xl:ring-red-800"
  | "xl:ring-red-800"
  | "lg:ring-red-800"
  | "md:ring-red-800"
  | "sm:ring-red-800"
  | "xs:ring-red-800"
  | "focus-within:ring-red-800"
  | "focus:ring-red-800"
  | "2xl:ring-red-900"
  | "1xl:ring-red-900"
  | "xl:ring-red-900"
  | "lg:ring-red-900"
  | "md:ring-red-900"
  | "sm:ring-red-900"
  | "xs:ring-red-900"
  | "focus-within:ring-red-900"
  | "focus:ring-red-900"
  | "2xl:ring-yellow-50"
  | "1xl:ring-yellow-50"
  | "xl:ring-yellow-50"
  | "lg:ring-yellow-50"
  | "md:ring-yellow-50"
  | "sm:ring-yellow-50"
  | "xs:ring-yellow-50"
  | "focus-within:ring-yellow-50"
  | "focus:ring-yellow-50"
  | "2xl:ring-yellow-100"
  | "1xl:ring-yellow-100"
  | "xl:ring-yellow-100"
  | "lg:ring-yellow-100"
  | "md:ring-yellow-100"
  | "sm:ring-yellow-100"
  | "xs:ring-yellow-100"
  | "focus-within:ring-yellow-100"
  | "focus:ring-yellow-100"
  | "2xl:ring-yellow-200"
  | "1xl:ring-yellow-200"
  | "xl:ring-yellow-200"
  | "lg:ring-yellow-200"
  | "md:ring-yellow-200"
  | "sm:ring-yellow-200"
  | "xs:ring-yellow-200"
  | "focus-within:ring-yellow-200"
  | "focus:ring-yellow-200"
  | "2xl:ring-yellow-300"
  | "1xl:ring-yellow-300"
  | "xl:ring-yellow-300"
  | "lg:ring-yellow-300"
  | "md:ring-yellow-300"
  | "sm:ring-yellow-300"
  | "xs:ring-yellow-300"
  | "focus-within:ring-yellow-300"
  | "focus:ring-yellow-300"
  | "2xl:ring-yellow-400"
  | "1xl:ring-yellow-400"
  | "xl:ring-yellow-400"
  | "lg:ring-yellow-400"
  | "md:ring-yellow-400"
  | "sm:ring-yellow-400"
  | "xs:ring-yellow-400"
  | "focus-within:ring-yellow-400"
  | "focus:ring-yellow-400"
  | "2xl:ring-yellow-500"
  | "1xl:ring-yellow-500"
  | "xl:ring-yellow-500"
  | "lg:ring-yellow-500"
  | "md:ring-yellow-500"
  | "sm:ring-yellow-500"
  | "xs:ring-yellow-500"
  | "focus-within:ring-yellow-500"
  | "focus:ring-yellow-500"
  | "2xl:ring-yellow-600"
  | "1xl:ring-yellow-600"
  | "xl:ring-yellow-600"
  | "lg:ring-yellow-600"
  | "md:ring-yellow-600"
  | "sm:ring-yellow-600"
  | "xs:ring-yellow-600"
  | "focus-within:ring-yellow-600"
  | "focus:ring-yellow-600"
  | "2xl:ring-yellow-700"
  | "1xl:ring-yellow-700"
  | "xl:ring-yellow-700"
  | "lg:ring-yellow-700"
  | "md:ring-yellow-700"
  | "sm:ring-yellow-700"
  | "xs:ring-yellow-700"
  | "focus-within:ring-yellow-700"
  | "focus:ring-yellow-700"
  | "2xl:ring-yellow-800"
  | "1xl:ring-yellow-800"
  | "xl:ring-yellow-800"
  | "lg:ring-yellow-800"
  | "md:ring-yellow-800"
  | "sm:ring-yellow-800"
  | "xs:ring-yellow-800"
  | "focus-within:ring-yellow-800"
  | "focus:ring-yellow-800"
  | "2xl:ring-yellow-900"
  | "1xl:ring-yellow-900"
  | "xl:ring-yellow-900"
  | "lg:ring-yellow-900"
  | "md:ring-yellow-900"
  | "sm:ring-yellow-900"
  | "xs:ring-yellow-900"
  | "focus-within:ring-yellow-900"
  | "focus:ring-yellow-900"
  | "2xl:ring-green-50"
  | "1xl:ring-green-50"
  | "xl:ring-green-50"
  | "lg:ring-green-50"
  | "md:ring-green-50"
  | "sm:ring-green-50"
  | "xs:ring-green-50"
  | "focus-within:ring-green-50"
  | "focus:ring-green-50"
  | "2xl:ring-green-100"
  | "1xl:ring-green-100"
  | "xl:ring-green-100"
  | "lg:ring-green-100"
  | "md:ring-green-100"
  | "sm:ring-green-100"
  | "xs:ring-green-100"
  | "focus-within:ring-green-100"
  | "focus:ring-green-100"
  | "2xl:ring-green-200"
  | "1xl:ring-green-200"
  | "xl:ring-green-200"
  | "lg:ring-green-200"
  | "md:ring-green-200"
  | "sm:ring-green-200"
  | "xs:ring-green-200"
  | "focus-within:ring-green-200"
  | "focus:ring-green-200"
  | "2xl:ring-green-300"
  | "1xl:ring-green-300"
  | "xl:ring-green-300"
  | "lg:ring-green-300"
  | "md:ring-green-300"
  | "sm:ring-green-300"
  | "xs:ring-green-300"
  | "focus-within:ring-green-300"
  | "focus:ring-green-300"
  | "2xl:ring-green-400"
  | "1xl:ring-green-400"
  | "xl:ring-green-400"
  | "lg:ring-green-400"
  | "md:ring-green-400"
  | "sm:ring-green-400"
  | "xs:ring-green-400"
  | "focus-within:ring-green-400"
  | "focus:ring-green-400"
  | "2xl:ring-green-500"
  | "1xl:ring-green-500"
  | "xl:ring-green-500"
  | "lg:ring-green-500"
  | "md:ring-green-500"
  | "sm:ring-green-500"
  | "xs:ring-green-500"
  | "focus-within:ring-green-500"
  | "focus:ring-green-500"
  | "2xl:ring-green-600"
  | "1xl:ring-green-600"
  | "xl:ring-green-600"
  | "lg:ring-green-600"
  | "md:ring-green-600"
  | "sm:ring-green-600"
  | "xs:ring-green-600"
  | "focus-within:ring-green-600"
  | "focus:ring-green-600"
  | "2xl:ring-green-700"
  | "1xl:ring-green-700"
  | "xl:ring-green-700"
  | "lg:ring-green-700"
  | "md:ring-green-700"
  | "sm:ring-green-700"
  | "xs:ring-green-700"
  | "focus-within:ring-green-700"
  | "focus:ring-green-700"
  | "2xl:ring-green-800"
  | "1xl:ring-green-800"
  | "xl:ring-green-800"
  | "lg:ring-green-800"
  | "md:ring-green-800"
  | "sm:ring-green-800"
  | "xs:ring-green-800"
  | "focus-within:ring-green-800"
  | "focus:ring-green-800"
  | "2xl:ring-green-900"
  | "1xl:ring-green-900"
  | "xl:ring-green-900"
  | "lg:ring-green-900"
  | "md:ring-green-900"
  | "sm:ring-green-900"
  | "xs:ring-green-900"
  | "focus-within:ring-green-900"
  | "focus:ring-green-900"
  | "2xl:ring-blue-50"
  | "1xl:ring-blue-50"
  | "xl:ring-blue-50"
  | "lg:ring-blue-50"
  | "md:ring-blue-50"
  | "sm:ring-blue-50"
  | "xs:ring-blue-50"
  | "focus-within:ring-blue-50"
  | "focus:ring-blue-50"
  | "2xl:ring-blue-100"
  | "1xl:ring-blue-100"
  | "xl:ring-blue-100"
  | "lg:ring-blue-100"
  | "md:ring-blue-100"
  | "sm:ring-blue-100"
  | "xs:ring-blue-100"
  | "focus-within:ring-blue-100"
  | "focus:ring-blue-100"
  | "2xl:ring-blue-200"
  | "1xl:ring-blue-200"
  | "xl:ring-blue-200"
  | "lg:ring-blue-200"
  | "md:ring-blue-200"
  | "sm:ring-blue-200"
  | "xs:ring-blue-200"
  | "focus-within:ring-blue-200"
  | "focus:ring-blue-200"
  | "2xl:ring-blue-300"
  | "1xl:ring-blue-300"
  | "xl:ring-blue-300"
  | "lg:ring-blue-300"
  | "md:ring-blue-300"
  | "sm:ring-blue-300"
  | "xs:ring-blue-300"
  | "focus-within:ring-blue-300"
  | "focus:ring-blue-300"
  | "2xl:ring-blue-400"
  | "1xl:ring-blue-400"
  | "xl:ring-blue-400"
  | "lg:ring-blue-400"
  | "md:ring-blue-400"
  | "sm:ring-blue-400"
  | "xs:ring-blue-400"
  | "focus-within:ring-blue-400"
  | "focus:ring-blue-400"
  | "2xl:ring-blue-500"
  | "1xl:ring-blue-500"
  | "xl:ring-blue-500"
  | "lg:ring-blue-500"
  | "md:ring-blue-500"
  | "sm:ring-blue-500"
  | "xs:ring-blue-500"
  | "focus-within:ring-blue-500"
  | "focus:ring-blue-500"
  | "2xl:ring-blue-600"
  | "1xl:ring-blue-600"
  | "xl:ring-blue-600"
  | "lg:ring-blue-600"
  | "md:ring-blue-600"
  | "sm:ring-blue-600"
  | "xs:ring-blue-600"
  | "focus-within:ring-blue-600"
  | "focus:ring-blue-600"
  | "2xl:ring-blue-700"
  | "1xl:ring-blue-700"
  | "xl:ring-blue-700"
  | "lg:ring-blue-700"
  | "md:ring-blue-700"
  | "sm:ring-blue-700"
  | "xs:ring-blue-700"
  | "focus-within:ring-blue-700"
  | "focus:ring-blue-700"
  | "2xl:ring-blue-800"
  | "1xl:ring-blue-800"
  | "xl:ring-blue-800"
  | "lg:ring-blue-800"
  | "md:ring-blue-800"
  | "sm:ring-blue-800"
  | "xs:ring-blue-800"
  | "focus-within:ring-blue-800"
  | "focus:ring-blue-800"
  | "2xl:ring-blue-900"
  | "1xl:ring-blue-900"
  | "xl:ring-blue-900"
  | "lg:ring-blue-900"
  | "md:ring-blue-900"
  | "sm:ring-blue-900"
  | "xs:ring-blue-900"
  | "focus-within:ring-blue-900"
  | "focus:ring-blue-900"
  | "2xl:ring-indigo-50"
  | "1xl:ring-indigo-50"
  | "xl:ring-indigo-50"
  | "lg:ring-indigo-50"
  | "md:ring-indigo-50"
  | "sm:ring-indigo-50"
  | "xs:ring-indigo-50"
  | "focus-within:ring-indigo-50"
  | "focus:ring-indigo-50"
  | "2xl:ring-indigo-100"
  | "1xl:ring-indigo-100"
  | "xl:ring-indigo-100"
  | "lg:ring-indigo-100"
  | "md:ring-indigo-100"
  | "sm:ring-indigo-100"
  | "xs:ring-indigo-100"
  | "focus-within:ring-indigo-100"
  | "focus:ring-indigo-100"
  | "2xl:ring-indigo-200"
  | "1xl:ring-indigo-200"
  | "xl:ring-indigo-200"
  | "lg:ring-indigo-200"
  | "md:ring-indigo-200"
  | "sm:ring-indigo-200"
  | "xs:ring-indigo-200"
  | "focus-within:ring-indigo-200"
  | "focus:ring-indigo-200"
  | "2xl:ring-indigo-300"
  | "1xl:ring-indigo-300"
  | "xl:ring-indigo-300"
  | "lg:ring-indigo-300"
  | "md:ring-indigo-300"
  | "sm:ring-indigo-300"
  | "xs:ring-indigo-300"
  | "focus-within:ring-indigo-300"
  | "focus:ring-indigo-300"
  | "2xl:ring-indigo-400"
  | "1xl:ring-indigo-400"
  | "xl:ring-indigo-400"
  | "lg:ring-indigo-400"
  | "md:ring-indigo-400"
  | "sm:ring-indigo-400"
  | "xs:ring-indigo-400"
  | "focus-within:ring-indigo-400"
  | "focus:ring-indigo-400"
  | "2xl:ring-indigo-500"
  | "1xl:ring-indigo-500"
  | "xl:ring-indigo-500"
  | "lg:ring-indigo-500"
  | "md:ring-indigo-500"
  | "sm:ring-indigo-500"
  | "xs:ring-indigo-500"
  | "focus-within:ring-indigo-500"
  | "focus:ring-indigo-500"
  | "2xl:ring-indigo-600"
  | "1xl:ring-indigo-600"
  | "xl:ring-indigo-600"
  | "lg:ring-indigo-600"
  | "md:ring-indigo-600"
  | "sm:ring-indigo-600"
  | "xs:ring-indigo-600"
  | "focus-within:ring-indigo-600"
  | "focus:ring-indigo-600"
  | "2xl:ring-indigo-700"
  | "1xl:ring-indigo-700"
  | "xl:ring-indigo-700"
  | "lg:ring-indigo-700"
  | "md:ring-indigo-700"
  | "sm:ring-indigo-700"
  | "xs:ring-indigo-700"
  | "focus-within:ring-indigo-700"
  | "focus:ring-indigo-700"
  | "2xl:ring-indigo-800"
  | "1xl:ring-indigo-800"
  | "xl:ring-indigo-800"
  | "lg:ring-indigo-800"
  | "md:ring-indigo-800"
  | "sm:ring-indigo-800"
  | "xs:ring-indigo-800"
  | "focus-within:ring-indigo-800"
  | "focus:ring-indigo-800"
  | "2xl:ring-indigo-900"
  | "1xl:ring-indigo-900"
  | "xl:ring-indigo-900"
  | "lg:ring-indigo-900"
  | "md:ring-indigo-900"
  | "sm:ring-indigo-900"
  | "xs:ring-indigo-900"
  | "focus-within:ring-indigo-900"
  | "focus:ring-indigo-900"
  | "2xl:ring-purple-50"
  | "1xl:ring-purple-50"
  | "xl:ring-purple-50"
  | "lg:ring-purple-50"
  | "md:ring-purple-50"
  | "sm:ring-purple-50"
  | "xs:ring-purple-50"
  | "focus-within:ring-purple-50"
  | "focus:ring-purple-50"
  | "2xl:ring-purple-100"
  | "1xl:ring-purple-100"
  | "xl:ring-purple-100"
  | "lg:ring-purple-100"
  | "md:ring-purple-100"
  | "sm:ring-purple-100"
  | "xs:ring-purple-100"
  | "focus-within:ring-purple-100"
  | "focus:ring-purple-100"
  | "2xl:ring-purple-200"
  | "1xl:ring-purple-200"
  | "xl:ring-purple-200"
  | "lg:ring-purple-200"
  | "md:ring-purple-200"
  | "sm:ring-purple-200"
  | "xs:ring-purple-200"
  | "focus-within:ring-purple-200"
  | "focus:ring-purple-200"
  | "2xl:ring-purple-300"
  | "1xl:ring-purple-300"
  | "xl:ring-purple-300"
  | "lg:ring-purple-300"
  | "md:ring-purple-300"
  | "sm:ring-purple-300"
  | "xs:ring-purple-300"
  | "focus-within:ring-purple-300"
  | "focus:ring-purple-300"
  | "2xl:ring-purple-400"
  | "1xl:ring-purple-400"
  | "xl:ring-purple-400"
  | "lg:ring-purple-400"
  | "md:ring-purple-400"
  | "sm:ring-purple-400"
  | "xs:ring-purple-400"
  | "focus-within:ring-purple-400"
  | "focus:ring-purple-400"
  | "2xl:ring-purple-500"
  | "1xl:ring-purple-500"
  | "xl:ring-purple-500"
  | "lg:ring-purple-500"
  | "md:ring-purple-500"
  | "sm:ring-purple-500"
  | "xs:ring-purple-500"
  | "focus-within:ring-purple-500"
  | "focus:ring-purple-500"
  | "2xl:ring-purple-600"
  | "1xl:ring-purple-600"
  | "xl:ring-purple-600"
  | "lg:ring-purple-600"
  | "md:ring-purple-600"
  | "sm:ring-purple-600"
  | "xs:ring-purple-600"
  | "focus-within:ring-purple-600"
  | "focus:ring-purple-600"
  | "2xl:ring-purple-700"
  | "1xl:ring-purple-700"
  | "xl:ring-purple-700"
  | "lg:ring-purple-700"
  | "md:ring-purple-700"
  | "sm:ring-purple-700"
  | "xs:ring-purple-700"
  | "focus-within:ring-purple-700"
  | "focus:ring-purple-700"
  | "2xl:ring-purple-800"
  | "1xl:ring-purple-800"
  | "xl:ring-purple-800"
  | "lg:ring-purple-800"
  | "md:ring-purple-800"
  | "sm:ring-purple-800"
  | "xs:ring-purple-800"
  | "focus-within:ring-purple-800"
  | "focus:ring-purple-800"
  | "2xl:ring-purple-900"
  | "1xl:ring-purple-900"
  | "xl:ring-purple-900"
  | "lg:ring-purple-900"
  | "md:ring-purple-900"
  | "sm:ring-purple-900"
  | "xs:ring-purple-900"
  | "focus-within:ring-purple-900"
  | "focus:ring-purple-900"
  | "2xl:ring-pink-50"
  | "1xl:ring-pink-50"
  | "xl:ring-pink-50"
  | "lg:ring-pink-50"
  | "md:ring-pink-50"
  | "sm:ring-pink-50"
  | "xs:ring-pink-50"
  | "focus-within:ring-pink-50"
  | "focus:ring-pink-50"
  | "2xl:ring-pink-100"
  | "1xl:ring-pink-100"
  | "xl:ring-pink-100"
  | "lg:ring-pink-100"
  | "md:ring-pink-100"
  | "sm:ring-pink-100"
  | "xs:ring-pink-100"
  | "focus-within:ring-pink-100"
  | "focus:ring-pink-100"
  | "2xl:ring-pink-200"
  | "1xl:ring-pink-200"
  | "xl:ring-pink-200"
  | "lg:ring-pink-200"
  | "md:ring-pink-200"
  | "sm:ring-pink-200"
  | "xs:ring-pink-200"
  | "focus-within:ring-pink-200"
  | "focus:ring-pink-200"
  | "2xl:ring-pink-300"
  | "1xl:ring-pink-300"
  | "xl:ring-pink-300"
  | "lg:ring-pink-300"
  | "md:ring-pink-300"
  | "sm:ring-pink-300"
  | "xs:ring-pink-300"
  | "focus-within:ring-pink-300"
  | "focus:ring-pink-300"
  | "2xl:ring-pink-400"
  | "1xl:ring-pink-400"
  | "xl:ring-pink-400"
  | "lg:ring-pink-400"
  | "md:ring-pink-400"
  | "sm:ring-pink-400"
  | "xs:ring-pink-400"
  | "focus-within:ring-pink-400"
  | "focus:ring-pink-400"
  | "2xl:ring-pink-500"
  | "1xl:ring-pink-500"
  | "xl:ring-pink-500"
  | "lg:ring-pink-500"
  | "md:ring-pink-500"
  | "sm:ring-pink-500"
  | "xs:ring-pink-500"
  | "focus-within:ring-pink-500"
  | "focus:ring-pink-500"
  | "2xl:ring-pink-600"
  | "1xl:ring-pink-600"
  | "xl:ring-pink-600"
  | "lg:ring-pink-600"
  | "md:ring-pink-600"
  | "sm:ring-pink-600"
  | "xs:ring-pink-600"
  | "focus-within:ring-pink-600"
  | "focus:ring-pink-600"
  | "2xl:ring-pink-700"
  | "1xl:ring-pink-700"
  | "xl:ring-pink-700"
  | "lg:ring-pink-700"
  | "md:ring-pink-700"
  | "sm:ring-pink-700"
  | "xs:ring-pink-700"
  | "focus-within:ring-pink-700"
  | "focus:ring-pink-700"
  | "2xl:ring-pink-800"
  | "1xl:ring-pink-800"
  | "xl:ring-pink-800"
  | "lg:ring-pink-800"
  | "md:ring-pink-800"
  | "sm:ring-pink-800"
  | "xs:ring-pink-800"
  | "focus-within:ring-pink-800"
  | "focus:ring-pink-800"
  | "2xl:ring-pink-900"
  | "1xl:ring-pink-900"
  | "xl:ring-pink-900"
  | "lg:ring-pink-900"
  | "md:ring-pink-900"
  | "sm:ring-pink-900"
  | "xs:ring-pink-900"
  | "focus-within:ring-pink-900"
  | "focus:ring-pink-900"
  | "2xl:ring-title-color-1-50"
  | "1xl:ring-title-color-1-50"
  | "xl:ring-title-color-1-50"
  | "lg:ring-title-color-1-50"
  | "md:ring-title-color-1-50"
  | "sm:ring-title-color-1-50"
  | "xs:ring-title-color-1-50"
  | "focus-within:ring-title-color-1-50"
  | "focus:ring-title-color-1-50"
  | "2xl:ring-title-color-1-100"
  | "1xl:ring-title-color-1-100"
  | "xl:ring-title-color-1-100"
  | "lg:ring-title-color-1-100"
  | "md:ring-title-color-1-100"
  | "sm:ring-title-color-1-100"
  | "xs:ring-title-color-1-100"
  | "focus-within:ring-title-color-1-100"
  | "focus:ring-title-color-1-100"
  | "2xl:ring-title-color-1-200"
  | "1xl:ring-title-color-1-200"
  | "xl:ring-title-color-1-200"
  | "lg:ring-title-color-1-200"
  | "md:ring-title-color-1-200"
  | "sm:ring-title-color-1-200"
  | "xs:ring-title-color-1-200"
  | "focus-within:ring-title-color-1-200"
  | "focus:ring-title-color-1-200"
  | "2xl:ring-title-color-1-300"
  | "1xl:ring-title-color-1-300"
  | "xl:ring-title-color-1-300"
  | "lg:ring-title-color-1-300"
  | "md:ring-title-color-1-300"
  | "sm:ring-title-color-1-300"
  | "xs:ring-title-color-1-300"
  | "focus-within:ring-title-color-1-300"
  | "focus:ring-title-color-1-300"
  | "2xl:ring-title-color-1-400"
  | "1xl:ring-title-color-1-400"
  | "xl:ring-title-color-1-400"
  | "lg:ring-title-color-1-400"
  | "md:ring-title-color-1-400"
  | "sm:ring-title-color-1-400"
  | "xs:ring-title-color-1-400"
  | "focus-within:ring-title-color-1-400"
  | "focus:ring-title-color-1-400"
  | "2xl:ring-title-color-1-500"
  | "1xl:ring-title-color-1-500"
  | "xl:ring-title-color-1-500"
  | "lg:ring-title-color-1-500"
  | "md:ring-title-color-1-500"
  | "sm:ring-title-color-1-500"
  | "xs:ring-title-color-1-500"
  | "focus-within:ring-title-color-1-500"
  | "focus:ring-title-color-1-500"
  | "2xl:ring-title-color-1-600"
  | "1xl:ring-title-color-1-600"
  | "xl:ring-title-color-1-600"
  | "lg:ring-title-color-1-600"
  | "md:ring-title-color-1-600"
  | "sm:ring-title-color-1-600"
  | "xs:ring-title-color-1-600"
  | "focus-within:ring-title-color-1-600"
  | "focus:ring-title-color-1-600"
  | "2xl:ring-title-color-1-700"
  | "1xl:ring-title-color-1-700"
  | "xl:ring-title-color-1-700"
  | "lg:ring-title-color-1-700"
  | "md:ring-title-color-1-700"
  | "sm:ring-title-color-1-700"
  | "xs:ring-title-color-1-700"
  | "focus-within:ring-title-color-1-700"
  | "focus:ring-title-color-1-700"
  | "2xl:ring-title-color-1-800"
  | "1xl:ring-title-color-1-800"
  | "xl:ring-title-color-1-800"
  | "lg:ring-title-color-1-800"
  | "md:ring-title-color-1-800"
  | "sm:ring-title-color-1-800"
  | "xs:ring-title-color-1-800"
  | "focus-within:ring-title-color-1-800"
  | "focus:ring-title-color-1-800"
  | "2xl:ring-title-color-1-900"
  | "1xl:ring-title-color-1-900"
  | "xl:ring-title-color-1-900"
  | "lg:ring-title-color-1-900"
  | "md:ring-title-color-1-900"
  | "sm:ring-title-color-1-900"
  | "xs:ring-title-color-1-900"
  | "focus-within:ring-title-color-1-900"
  | "focus:ring-title-color-1-900"
  | "2xl:ring-primary-bg-color"
  | "1xl:ring-primary-bg-color"
  | "xl:ring-primary-bg-color"
  | "lg:ring-primary-bg-color"
  | "md:ring-primary-bg-color"
  | "sm:ring-primary-bg-color"
  | "xs:ring-primary-bg-color"
  | "focus-within:ring-primary-bg-color"
  | "focus:ring-primary-bg-color"
  | "2xl:ring-secondary-bg-color"
  | "1xl:ring-secondary-bg-color"
  | "xl:ring-secondary-bg-color"
  | "lg:ring-secondary-bg-color"
  | "md:ring-secondary-bg-color"
  | "sm:ring-secondary-bg-color"
  | "xs:ring-secondary-bg-color"
  | "focus-within:ring-secondary-bg-color"
  | "focus:ring-secondary-bg-color"
  | "2xl:ring-sidebar-bg-color"
  | "1xl:ring-sidebar-bg-color"
  | "xl:ring-sidebar-bg-color"
  | "lg:ring-sidebar-bg-color"
  | "md:ring-sidebar-bg-color"
  | "sm:ring-sidebar-bg-color"
  | "xs:ring-sidebar-bg-color"
  | "focus-within:ring-sidebar-bg-color"
  | "focus:ring-sidebar-bg-color"
  | "2xl:ring-header-bg-color"
  | "1xl:ring-header-bg-color"
  | "xl:ring-header-bg-color"
  | "lg:ring-header-bg-color"
  | "md:ring-header-bg-color"
  | "sm:ring-header-bg-color"
  | "xs:ring-header-bg-color"
  | "focus-within:ring-header-bg-color"
  | "focus:ring-header-bg-color"
  | "2xl:ring-body-bg-color"
  | "1xl:ring-body-bg-color"
  | "xl:ring-body-bg-color"
  | "lg:ring-body-bg-color"
  | "md:ring-body-bg-color"
  | "sm:ring-body-bg-color"
  | "xs:ring-body-bg-color"
  | "focus-within:ring-body-bg-color"
  | "focus:ring-body-bg-color"
  | "2xl:ring-main-color"
  | "1xl:ring-main-color"
  | "xl:ring-main-color"
  | "lg:ring-main-color"
  | "md:ring-main-color"
  | "sm:ring-main-color"
  | "xs:ring-main-color"
  | "focus-within:ring-main-color"
  | "focus:ring-main-color"
  | "2xl:ring-tertiary-bg-color"
  | "1xl:ring-tertiary-bg-color"
  | "xl:ring-tertiary-bg-color"
  | "lg:ring-tertiary-bg-color"
  | "md:ring-tertiary-bg-color"
  | "sm:ring-tertiary-bg-color"
  | "xs:ring-tertiary-bg-color"
  | "focus-within:ring-tertiary-bg-color"
  | "focus:ring-tertiary-bg-color"
  | "2xl:ring-quaternary-bg-color"
  | "1xl:ring-quaternary-bg-color"
  | "xl:ring-quaternary-bg-color"
  | "lg:ring-quaternary-bg-color"
  | "md:ring-quaternary-bg-color"
  | "sm:ring-quaternary-bg-color"
  | "xs:ring-quaternary-bg-color"
  | "focus-within:ring-quaternary-bg-color"
  | "focus:ring-quaternary-bg-color"
  | "2xl:ring-earnings-bg-color"
  | "1xl:ring-earnings-bg-color"
  | "xl:ring-earnings-bg-color"
  | "lg:ring-earnings-bg-color"
  | "md:ring-earnings-bg-color"
  | "sm:ring-earnings-bg-color"
  | "xs:ring-earnings-bg-color"
  | "focus-within:ring-earnings-bg-color"
  | "focus:ring-earnings-bg-color"
  | "2xl:ring-card-secondary-bg-color"
  | "1xl:ring-card-secondary-bg-color"
  | "xl:ring-card-secondary-bg-color"
  | "lg:ring-card-secondary-bg-color"
  | "md:ring-card-secondary-bg-color"
  | "sm:ring-card-secondary-bg-color"
  | "xs:ring-card-secondary-bg-color"
  | "focus-within:ring-card-secondary-bg-color"
  | "focus:ring-card-secondary-bg-color"
  | "2xl:ring-pink-color"
  | "1xl:ring-pink-color"
  | "xl:ring-pink-color"
  | "lg:ring-pink-color"
  | "md:ring-pink-color"
  | "sm:ring-pink-color"
  | "xs:ring-pink-color"
  | "focus-within:ring-pink-color"
  | "focus:ring-pink-color"
  | "2xl:ring-go-live-text"
  | "1xl:ring-go-live-text"
  | "xl:ring-go-live-text"
  | "lg:ring-go-live-text"
  | "md:ring-go-live-text"
  | "sm:ring-go-live-text"
  | "xs:ring-go-live-text"
  | "focus-within:ring-go-live-text"
  | "focus:ring-go-live-text"
  | "2xl:ring-go-live-bg-color"
  | "1xl:ring-go-live-bg-color"
  | "xl:ring-go-live-bg-color"
  | "lg:ring-go-live-bg-color"
  | "md:ring-go-live-bg-color"
  | "sm:ring-go-live-bg-color"
  | "xs:ring-go-live-bg-color"
  | "focus-within:ring-go-live-bg-color"
  | "focus:ring-go-live-bg-color"
  | "2xl:ring-gray-button"
  | "1xl:ring-gray-button"
  | "xl:ring-gray-button"
  | "lg:ring-gray-button"
  | "md:ring-gray-button"
  | "sm:ring-gray-button"
  | "xs:ring-gray-button"
  | "focus-within:ring-gray-button"
  | "focus:ring-gray-button"
  | "2xl:ring-toggle-disabled"
  | "1xl:ring-toggle-disabled"
  | "xl:ring-toggle-disabled"
  | "lg:ring-toggle-disabled"
  | "md:ring-toggle-disabled"
  | "sm:ring-toggle-disabled"
  | "xs:ring-toggle-disabled"
  | "focus-within:ring-toggle-disabled"
  | "focus:ring-toggle-disabled"
  | "2xl:ring-tab-not-selected-color"
  | "1xl:ring-tab-not-selected-color"
  | "xl:ring-tab-not-selected-color"
  | "lg:ring-tab-not-selected-color"
  | "md:ring-tab-not-selected-color"
  | "sm:ring-tab-not-selected-color"
  | "xs:ring-tab-not-selected-color"
  | "focus-within:ring-tab-not-selected-color"
  | "focus:ring-tab-not-selected-color"
  | "2xl:ring-text-area-bg-color"
  | "1xl:ring-text-area-bg-color"
  | "xl:ring-text-area-bg-color"
  | "lg:ring-text-area-bg-color"
  | "md:ring-text-area-bg-color"
  | "sm:ring-text-area-bg-color"
  | "xs:ring-text-area-bg-color"
  | "focus-within:ring-text-area-bg-color"
  | "focus:ring-text-area-bg-color"
  | "2xl:ring-table-primary-bg-color"
  | "1xl:ring-table-primary-bg-color"
  | "xl:ring-table-primary-bg-color"
  | "lg:ring-table-primary-bg-color"
  | "md:ring-table-primary-bg-color"
  | "sm:ring-table-primary-bg-color"
  | "xs:ring-table-primary-bg-color"
  | "focus-within:ring-table-primary-bg-color"
  | "focus:ring-table-primary-bg-color"
  | "2xl:ring-table-secondary-color"
  | "1xl:ring-table-secondary-color"
  | "xl:ring-table-secondary-color"
  | "lg:ring-table-secondary-color"
  | "md:ring-table-secondary-color"
  | "sm:ring-table-secondary-color"
  | "xs:ring-table-secondary-color"
  | "focus-within:ring-table-secondary-color"
  | "focus:ring-table-secondary-color"
  | "2xl:ring-modal-button-color"
  | "1xl:ring-modal-button-color"
  | "xl:ring-modal-button-color"
  | "lg:ring-modal-button-color"
  | "md:ring-modal-button-color"
  | "sm:ring-modal-button-color"
  | "xs:ring-modal-button-color"
  | "focus-within:ring-modal-button-color"
  | "focus:ring-modal-button-color"
  | "2xl:ring-offset-transparent"
  | "1xl:ring-offset-transparent"
  | "xl:ring-offset-transparent"
  | "lg:ring-offset-transparent"
  | "md:ring-offset-transparent"
  | "sm:ring-offset-transparent"
  | "xs:ring-offset-transparent"
  | "focus-within:ring-offset-transparent"
  | "focus:ring-offset-transparent"
  | "2xl:ring-offset-current"
  | "1xl:ring-offset-current"
  | "xl:ring-offset-current"
  | "lg:ring-offset-current"
  | "md:ring-offset-current"
  | "sm:ring-offset-current"
  | "xs:ring-offset-current"
  | "focus-within:ring-offset-current"
  | "focus:ring-offset-current"
  | "2xl:ring-offset-black"
  | "1xl:ring-offset-black"
  | "xl:ring-offset-black"
  | "lg:ring-offset-black"
  | "md:ring-offset-black"
  | "sm:ring-offset-black"
  | "xs:ring-offset-black"
  | "focus-within:ring-offset-black"
  | "focus:ring-offset-black"
  | "2xl:ring-offset-white"
  | "1xl:ring-offset-white"
  | "xl:ring-offset-white"
  | "lg:ring-offset-white"
  | "md:ring-offset-white"
  | "sm:ring-offset-white"
  | "xs:ring-offset-white"
  | "focus-within:ring-offset-white"
  | "focus:ring-offset-white"
  | "2xl:ring-offset-gray-50"
  | "1xl:ring-offset-gray-50"
  | "xl:ring-offset-gray-50"
  | "lg:ring-offset-gray-50"
  | "md:ring-offset-gray-50"
  | "sm:ring-offset-gray-50"
  | "xs:ring-offset-gray-50"
  | "focus-within:ring-offset-gray-50"
  | "focus:ring-offset-gray-50"
  | "2xl:ring-offset-gray-100"
  | "1xl:ring-offset-gray-100"
  | "xl:ring-offset-gray-100"
  | "lg:ring-offset-gray-100"
  | "md:ring-offset-gray-100"
  | "sm:ring-offset-gray-100"
  | "xs:ring-offset-gray-100"
  | "focus-within:ring-offset-gray-100"
  | "focus:ring-offset-gray-100"
  | "2xl:ring-offset-gray-200"
  | "1xl:ring-offset-gray-200"
  | "xl:ring-offset-gray-200"
  | "lg:ring-offset-gray-200"
  | "md:ring-offset-gray-200"
  | "sm:ring-offset-gray-200"
  | "xs:ring-offset-gray-200"
  | "focus-within:ring-offset-gray-200"
  | "focus:ring-offset-gray-200"
  | "2xl:ring-offset-gray-300"
  | "1xl:ring-offset-gray-300"
  | "xl:ring-offset-gray-300"
  | "lg:ring-offset-gray-300"
  | "md:ring-offset-gray-300"
  | "sm:ring-offset-gray-300"
  | "xs:ring-offset-gray-300"
  | "focus-within:ring-offset-gray-300"
  | "focus:ring-offset-gray-300"
  | "2xl:ring-offset-gray-400"
  | "1xl:ring-offset-gray-400"
  | "xl:ring-offset-gray-400"
  | "lg:ring-offset-gray-400"
  | "md:ring-offset-gray-400"
  | "sm:ring-offset-gray-400"
  | "xs:ring-offset-gray-400"
  | "focus-within:ring-offset-gray-400"
  | "focus:ring-offset-gray-400"
  | "2xl:ring-offset-gray-500"
  | "1xl:ring-offset-gray-500"
  | "xl:ring-offset-gray-500"
  | "lg:ring-offset-gray-500"
  | "md:ring-offset-gray-500"
  | "sm:ring-offset-gray-500"
  | "xs:ring-offset-gray-500"
  | "focus-within:ring-offset-gray-500"
  | "focus:ring-offset-gray-500"
  | "2xl:ring-offset-gray-600"
  | "1xl:ring-offset-gray-600"
  | "xl:ring-offset-gray-600"
  | "lg:ring-offset-gray-600"
  | "md:ring-offset-gray-600"
  | "sm:ring-offset-gray-600"
  | "xs:ring-offset-gray-600"
  | "focus-within:ring-offset-gray-600"
  | "focus:ring-offset-gray-600"
  | "2xl:ring-offset-gray-700"
  | "1xl:ring-offset-gray-700"
  | "xl:ring-offset-gray-700"
  | "lg:ring-offset-gray-700"
  | "md:ring-offset-gray-700"
  | "sm:ring-offset-gray-700"
  | "xs:ring-offset-gray-700"
  | "focus-within:ring-offset-gray-700"
  | "focus:ring-offset-gray-700"
  | "2xl:ring-offset-gray-800"
  | "1xl:ring-offset-gray-800"
  | "xl:ring-offset-gray-800"
  | "lg:ring-offset-gray-800"
  | "md:ring-offset-gray-800"
  | "sm:ring-offset-gray-800"
  | "xs:ring-offset-gray-800"
  | "focus-within:ring-offset-gray-800"
  | "focus:ring-offset-gray-800"
  | "2xl:ring-offset-gray-900"
  | "1xl:ring-offset-gray-900"
  | "xl:ring-offset-gray-900"
  | "lg:ring-offset-gray-900"
  | "md:ring-offset-gray-900"
  | "sm:ring-offset-gray-900"
  | "xs:ring-offset-gray-900"
  | "focus-within:ring-offset-gray-900"
  | "focus:ring-offset-gray-900"
  | "2xl:ring-offset-red-50"
  | "1xl:ring-offset-red-50"
  | "xl:ring-offset-red-50"
  | "lg:ring-offset-red-50"
  | "md:ring-offset-red-50"
  | "sm:ring-offset-red-50"
  | "xs:ring-offset-red-50"
  | "focus-within:ring-offset-red-50"
  | "focus:ring-offset-red-50"
  | "2xl:ring-offset-red-100"
  | "1xl:ring-offset-red-100"
  | "xl:ring-offset-red-100"
  | "lg:ring-offset-red-100"
  | "md:ring-offset-red-100"
  | "sm:ring-offset-red-100"
  | "xs:ring-offset-red-100"
  | "focus-within:ring-offset-red-100"
  | "focus:ring-offset-red-100"
  | "2xl:ring-offset-red-200"
  | "1xl:ring-offset-red-200"
  | "xl:ring-offset-red-200"
  | "lg:ring-offset-red-200"
  | "md:ring-offset-red-200"
  | "sm:ring-offset-red-200"
  | "xs:ring-offset-red-200"
  | "focus-within:ring-offset-red-200"
  | "focus:ring-offset-red-200"
  | "2xl:ring-offset-red-300"
  | "1xl:ring-offset-red-300"
  | "xl:ring-offset-red-300"
  | "lg:ring-offset-red-300"
  | "md:ring-offset-red-300"
  | "sm:ring-offset-red-300"
  | "xs:ring-offset-red-300"
  | "focus-within:ring-offset-red-300"
  | "focus:ring-offset-red-300"
  | "2xl:ring-offset-red-400"
  | "1xl:ring-offset-red-400"
  | "xl:ring-offset-red-400"
  | "lg:ring-offset-red-400"
  | "md:ring-offset-red-400"
  | "sm:ring-offset-red-400"
  | "xs:ring-offset-red-400"
  | "focus-within:ring-offset-red-400"
  | "focus:ring-offset-red-400"
  | "2xl:ring-offset-red-500"
  | "1xl:ring-offset-red-500"
  | "xl:ring-offset-red-500"
  | "lg:ring-offset-red-500"
  | "md:ring-offset-red-500"
  | "sm:ring-offset-red-500"
  | "xs:ring-offset-red-500"
  | "focus-within:ring-offset-red-500"
  | "focus:ring-offset-red-500"
  | "2xl:ring-offset-red-600"
  | "1xl:ring-offset-red-600"
  | "xl:ring-offset-red-600"
  | "lg:ring-offset-red-600"
  | "md:ring-offset-red-600"
  | "sm:ring-offset-red-600"
  | "xs:ring-offset-red-600"
  | "focus-within:ring-offset-red-600"
  | "focus:ring-offset-red-600"
  | "2xl:ring-offset-red-700"
  | "1xl:ring-offset-red-700"
  | "xl:ring-offset-red-700"
  | "lg:ring-offset-red-700"
  | "md:ring-offset-red-700"
  | "sm:ring-offset-red-700"
  | "xs:ring-offset-red-700"
  | "focus-within:ring-offset-red-700"
  | "focus:ring-offset-red-700"
  | "2xl:ring-offset-red-800"
  | "1xl:ring-offset-red-800"
  | "xl:ring-offset-red-800"
  | "lg:ring-offset-red-800"
  | "md:ring-offset-red-800"
  | "sm:ring-offset-red-800"
  | "xs:ring-offset-red-800"
  | "focus-within:ring-offset-red-800"
  | "focus:ring-offset-red-800"
  | "2xl:ring-offset-red-900"
  | "1xl:ring-offset-red-900"
  | "xl:ring-offset-red-900"
  | "lg:ring-offset-red-900"
  | "md:ring-offset-red-900"
  | "sm:ring-offset-red-900"
  | "xs:ring-offset-red-900"
  | "focus-within:ring-offset-red-900"
  | "focus:ring-offset-red-900"
  | "2xl:ring-offset-yellow-50"
  | "1xl:ring-offset-yellow-50"
  | "xl:ring-offset-yellow-50"
  | "lg:ring-offset-yellow-50"
  | "md:ring-offset-yellow-50"
  | "sm:ring-offset-yellow-50"
  | "xs:ring-offset-yellow-50"
  | "focus-within:ring-offset-yellow-50"
  | "focus:ring-offset-yellow-50"
  | "2xl:ring-offset-yellow-100"
  | "1xl:ring-offset-yellow-100"
  | "xl:ring-offset-yellow-100"
  | "lg:ring-offset-yellow-100"
  | "md:ring-offset-yellow-100"
  | "sm:ring-offset-yellow-100"
  | "xs:ring-offset-yellow-100"
  | "focus-within:ring-offset-yellow-100"
  | "focus:ring-offset-yellow-100"
  | "2xl:ring-offset-yellow-200"
  | "1xl:ring-offset-yellow-200"
  | "xl:ring-offset-yellow-200"
  | "lg:ring-offset-yellow-200"
  | "md:ring-offset-yellow-200"
  | "sm:ring-offset-yellow-200"
  | "xs:ring-offset-yellow-200"
  | "focus-within:ring-offset-yellow-200"
  | "focus:ring-offset-yellow-200"
  | "2xl:ring-offset-yellow-300"
  | "1xl:ring-offset-yellow-300"
  | "xl:ring-offset-yellow-300"
  | "lg:ring-offset-yellow-300"
  | "md:ring-offset-yellow-300"
  | "sm:ring-offset-yellow-300"
  | "xs:ring-offset-yellow-300"
  | "focus-within:ring-offset-yellow-300"
  | "focus:ring-offset-yellow-300"
  | "2xl:ring-offset-yellow-400"
  | "1xl:ring-offset-yellow-400"
  | "xl:ring-offset-yellow-400"
  | "lg:ring-offset-yellow-400"
  | "md:ring-offset-yellow-400"
  | "sm:ring-offset-yellow-400"
  | "xs:ring-offset-yellow-400"
  | "focus-within:ring-offset-yellow-400"
  | "focus:ring-offset-yellow-400"
  | "2xl:ring-offset-yellow-500"
  | "1xl:ring-offset-yellow-500"
  | "xl:ring-offset-yellow-500"
  | "lg:ring-offset-yellow-500"
  | "md:ring-offset-yellow-500"
  | "sm:ring-offset-yellow-500"
  | "xs:ring-offset-yellow-500"
  | "focus-within:ring-offset-yellow-500"
  | "focus:ring-offset-yellow-500"
  | "2xl:ring-offset-yellow-600"
  | "1xl:ring-offset-yellow-600"
  | "xl:ring-offset-yellow-600"
  | "lg:ring-offset-yellow-600"
  | "md:ring-offset-yellow-600"
  | "sm:ring-offset-yellow-600"
  | "xs:ring-offset-yellow-600"
  | "focus-within:ring-offset-yellow-600"
  | "focus:ring-offset-yellow-600"
  | "2xl:ring-offset-yellow-700"
  | "1xl:ring-offset-yellow-700"
  | "xl:ring-offset-yellow-700"
  | "lg:ring-offset-yellow-700"
  | "md:ring-offset-yellow-700"
  | "sm:ring-offset-yellow-700"
  | "xs:ring-offset-yellow-700"
  | "focus-within:ring-offset-yellow-700"
  | "focus:ring-offset-yellow-700"
  | "2xl:ring-offset-yellow-800"
  | "1xl:ring-offset-yellow-800"
  | "xl:ring-offset-yellow-800"
  | "lg:ring-offset-yellow-800"
  | "md:ring-offset-yellow-800"
  | "sm:ring-offset-yellow-800"
  | "xs:ring-offset-yellow-800"
  | "focus-within:ring-offset-yellow-800"
  | "focus:ring-offset-yellow-800"
  | "2xl:ring-offset-yellow-900"
  | "1xl:ring-offset-yellow-900"
  | "xl:ring-offset-yellow-900"
  | "lg:ring-offset-yellow-900"
  | "md:ring-offset-yellow-900"
  | "sm:ring-offset-yellow-900"
  | "xs:ring-offset-yellow-900"
  | "focus-within:ring-offset-yellow-900"
  | "focus:ring-offset-yellow-900"
  | "2xl:ring-offset-green-50"
  | "1xl:ring-offset-green-50"
  | "xl:ring-offset-green-50"
  | "lg:ring-offset-green-50"
  | "md:ring-offset-green-50"
  | "sm:ring-offset-green-50"
  | "xs:ring-offset-green-50"
  | "focus-within:ring-offset-green-50"
  | "focus:ring-offset-green-50"
  | "2xl:ring-offset-green-100"
  | "1xl:ring-offset-green-100"
  | "xl:ring-offset-green-100"
  | "lg:ring-offset-green-100"
  | "md:ring-offset-green-100"
  | "sm:ring-offset-green-100"
  | "xs:ring-offset-green-100"
  | "focus-within:ring-offset-green-100"
  | "focus:ring-offset-green-100"
  | "2xl:ring-offset-green-200"
  | "1xl:ring-offset-green-200"
  | "xl:ring-offset-green-200"
  | "lg:ring-offset-green-200"
  | "md:ring-offset-green-200"
  | "sm:ring-offset-green-200"
  | "xs:ring-offset-green-200"
  | "focus-within:ring-offset-green-200"
  | "focus:ring-offset-green-200"
  | "2xl:ring-offset-green-300"
  | "1xl:ring-offset-green-300"
  | "xl:ring-offset-green-300"
  | "lg:ring-offset-green-300"
  | "md:ring-offset-green-300"
  | "sm:ring-offset-green-300"
  | "xs:ring-offset-green-300"
  | "focus-within:ring-offset-green-300"
  | "focus:ring-offset-green-300"
  | "2xl:ring-offset-green-400"
  | "1xl:ring-offset-green-400"
  | "xl:ring-offset-green-400"
  | "lg:ring-offset-green-400"
  | "md:ring-offset-green-400"
  | "sm:ring-offset-green-400"
  | "xs:ring-offset-green-400"
  | "focus-within:ring-offset-green-400"
  | "focus:ring-offset-green-400"
  | "2xl:ring-offset-green-500"
  | "1xl:ring-offset-green-500"
  | "xl:ring-offset-green-500"
  | "lg:ring-offset-green-500"
  | "md:ring-offset-green-500"
  | "sm:ring-offset-green-500"
  | "xs:ring-offset-green-500"
  | "focus-within:ring-offset-green-500"
  | "focus:ring-offset-green-500"
  | "2xl:ring-offset-green-600"
  | "1xl:ring-offset-green-600"
  | "xl:ring-offset-green-600"
  | "lg:ring-offset-green-600"
  | "md:ring-offset-green-600"
  | "sm:ring-offset-green-600"
  | "xs:ring-offset-green-600"
  | "focus-within:ring-offset-green-600"
  | "focus:ring-offset-green-600"
  | "2xl:ring-offset-green-700"
  | "1xl:ring-offset-green-700"
  | "xl:ring-offset-green-700"
  | "lg:ring-offset-green-700"
  | "md:ring-offset-green-700"
  | "sm:ring-offset-green-700"
  | "xs:ring-offset-green-700"
  | "focus-within:ring-offset-green-700"
  | "focus:ring-offset-green-700"
  | "2xl:ring-offset-green-800"
  | "1xl:ring-offset-green-800"
  | "xl:ring-offset-green-800"
  | "lg:ring-offset-green-800"
  | "md:ring-offset-green-800"
  | "sm:ring-offset-green-800"
  | "xs:ring-offset-green-800"
  | "focus-within:ring-offset-green-800"
  | "focus:ring-offset-green-800"
  | "2xl:ring-offset-green-900"
  | "1xl:ring-offset-green-900"
  | "xl:ring-offset-green-900"
  | "lg:ring-offset-green-900"
  | "md:ring-offset-green-900"
  | "sm:ring-offset-green-900"
  | "xs:ring-offset-green-900"
  | "focus-within:ring-offset-green-900"
  | "focus:ring-offset-green-900"
  | "2xl:ring-offset-blue-50"
  | "1xl:ring-offset-blue-50"
  | "xl:ring-offset-blue-50"
  | "lg:ring-offset-blue-50"
  | "md:ring-offset-blue-50"
  | "sm:ring-offset-blue-50"
  | "xs:ring-offset-blue-50"
  | "focus-within:ring-offset-blue-50"
  | "focus:ring-offset-blue-50"
  | "2xl:ring-offset-blue-100"
  | "1xl:ring-offset-blue-100"
  | "xl:ring-offset-blue-100"
  | "lg:ring-offset-blue-100"
  | "md:ring-offset-blue-100"
  | "sm:ring-offset-blue-100"
  | "xs:ring-offset-blue-100"
  | "focus-within:ring-offset-blue-100"
  | "focus:ring-offset-blue-100"
  | "2xl:ring-offset-blue-200"
  | "1xl:ring-offset-blue-200"
  | "xl:ring-offset-blue-200"
  | "lg:ring-offset-blue-200"
  | "md:ring-offset-blue-200"
  | "sm:ring-offset-blue-200"
  | "xs:ring-offset-blue-200"
  | "focus-within:ring-offset-blue-200"
  | "focus:ring-offset-blue-200"
  | "2xl:ring-offset-blue-300"
  | "1xl:ring-offset-blue-300"
  | "xl:ring-offset-blue-300"
  | "lg:ring-offset-blue-300"
  | "md:ring-offset-blue-300"
  | "sm:ring-offset-blue-300"
  | "xs:ring-offset-blue-300"
  | "focus-within:ring-offset-blue-300"
  | "focus:ring-offset-blue-300"
  | "2xl:ring-offset-blue-400"
  | "1xl:ring-offset-blue-400"
  | "xl:ring-offset-blue-400"
  | "lg:ring-offset-blue-400"
  | "md:ring-offset-blue-400"
  | "sm:ring-offset-blue-400"
  | "xs:ring-offset-blue-400"
  | "focus-within:ring-offset-blue-400"
  | "focus:ring-offset-blue-400"
  | "2xl:ring-offset-blue-500"
  | "1xl:ring-offset-blue-500"
  | "xl:ring-offset-blue-500"
  | "lg:ring-offset-blue-500"
  | "md:ring-offset-blue-500"
  | "sm:ring-offset-blue-500"
  | "xs:ring-offset-blue-500"
  | "focus-within:ring-offset-blue-500"
  | "focus:ring-offset-blue-500"
  | "2xl:ring-offset-blue-600"
  | "1xl:ring-offset-blue-600"
  | "xl:ring-offset-blue-600"
  | "lg:ring-offset-blue-600"
  | "md:ring-offset-blue-600"
  | "sm:ring-offset-blue-600"
  | "xs:ring-offset-blue-600"
  | "focus-within:ring-offset-blue-600"
  | "focus:ring-offset-blue-600"
  | "2xl:ring-offset-blue-700"
  | "1xl:ring-offset-blue-700"
  | "xl:ring-offset-blue-700"
  | "lg:ring-offset-blue-700"
  | "md:ring-offset-blue-700"
  | "sm:ring-offset-blue-700"
  | "xs:ring-offset-blue-700"
  | "focus-within:ring-offset-blue-700"
  | "focus:ring-offset-blue-700"
  | "2xl:ring-offset-blue-800"
  | "1xl:ring-offset-blue-800"
  | "xl:ring-offset-blue-800"
  | "lg:ring-offset-blue-800"
  | "md:ring-offset-blue-800"
  | "sm:ring-offset-blue-800"
  | "xs:ring-offset-blue-800"
  | "focus-within:ring-offset-blue-800"
  | "focus:ring-offset-blue-800"
  | "2xl:ring-offset-blue-900"
  | "1xl:ring-offset-blue-900"
  | "xl:ring-offset-blue-900"
  | "lg:ring-offset-blue-900"
  | "md:ring-offset-blue-900"
  | "sm:ring-offset-blue-900"
  | "xs:ring-offset-blue-900"
  | "focus-within:ring-offset-blue-900"
  | "focus:ring-offset-blue-900"
  | "2xl:ring-offset-indigo-50"
  | "1xl:ring-offset-indigo-50"
  | "xl:ring-offset-indigo-50"
  | "lg:ring-offset-indigo-50"
  | "md:ring-offset-indigo-50"
  | "sm:ring-offset-indigo-50"
  | "xs:ring-offset-indigo-50"
  | "focus-within:ring-offset-indigo-50"
  | "focus:ring-offset-indigo-50"
  | "2xl:ring-offset-indigo-100"
  | "1xl:ring-offset-indigo-100"
  | "xl:ring-offset-indigo-100"
  | "lg:ring-offset-indigo-100"
  | "md:ring-offset-indigo-100"
  | "sm:ring-offset-indigo-100"
  | "xs:ring-offset-indigo-100"
  | "focus-within:ring-offset-indigo-100"
  | "focus:ring-offset-indigo-100"
  | "2xl:ring-offset-indigo-200"
  | "1xl:ring-offset-indigo-200"
  | "xl:ring-offset-indigo-200"
  | "lg:ring-offset-indigo-200"
  | "md:ring-offset-indigo-200"
  | "sm:ring-offset-indigo-200"
  | "xs:ring-offset-indigo-200"
  | "focus-within:ring-offset-indigo-200"
  | "focus:ring-offset-indigo-200"
  | "2xl:ring-offset-indigo-300"
  | "1xl:ring-offset-indigo-300"
  | "xl:ring-offset-indigo-300"
  | "lg:ring-offset-indigo-300"
  | "md:ring-offset-indigo-300"
  | "sm:ring-offset-indigo-300"
  | "xs:ring-offset-indigo-300"
  | "focus-within:ring-offset-indigo-300"
  | "focus:ring-offset-indigo-300"
  | "2xl:ring-offset-indigo-400"
  | "1xl:ring-offset-indigo-400"
  | "xl:ring-offset-indigo-400"
  | "lg:ring-offset-indigo-400"
  | "md:ring-offset-indigo-400"
  | "sm:ring-offset-indigo-400"
  | "xs:ring-offset-indigo-400"
  | "focus-within:ring-offset-indigo-400"
  | "focus:ring-offset-indigo-400"
  | "2xl:ring-offset-indigo-500"
  | "1xl:ring-offset-indigo-500"
  | "xl:ring-offset-indigo-500"
  | "lg:ring-offset-indigo-500"
  | "md:ring-offset-indigo-500"
  | "sm:ring-offset-indigo-500"
  | "xs:ring-offset-indigo-500"
  | "focus-within:ring-offset-indigo-500"
  | "focus:ring-offset-indigo-500"
  | "2xl:ring-offset-indigo-600"
  | "1xl:ring-offset-indigo-600"
  | "xl:ring-offset-indigo-600"
  | "lg:ring-offset-indigo-600"
  | "md:ring-offset-indigo-600"
  | "sm:ring-offset-indigo-600"
  | "xs:ring-offset-indigo-600"
  | "focus-within:ring-offset-indigo-600"
  | "focus:ring-offset-indigo-600"
  | "2xl:ring-offset-indigo-700"
  | "1xl:ring-offset-indigo-700"
  | "xl:ring-offset-indigo-700"
  | "lg:ring-offset-indigo-700"
  | "md:ring-offset-indigo-700"
  | "sm:ring-offset-indigo-700"
  | "xs:ring-offset-indigo-700"
  | "focus-within:ring-offset-indigo-700"
  | "focus:ring-offset-indigo-700"
  | "2xl:ring-offset-indigo-800"
  | "1xl:ring-offset-indigo-800"
  | "xl:ring-offset-indigo-800"
  | "lg:ring-offset-indigo-800"
  | "md:ring-offset-indigo-800"
  | "sm:ring-offset-indigo-800"
  | "xs:ring-offset-indigo-800"
  | "focus-within:ring-offset-indigo-800"
  | "focus:ring-offset-indigo-800"
  | "2xl:ring-offset-indigo-900"
  | "1xl:ring-offset-indigo-900"
  | "xl:ring-offset-indigo-900"
  | "lg:ring-offset-indigo-900"
  | "md:ring-offset-indigo-900"
  | "sm:ring-offset-indigo-900"
  | "xs:ring-offset-indigo-900"
  | "focus-within:ring-offset-indigo-900"
  | "focus:ring-offset-indigo-900"
  | "2xl:ring-offset-purple-50"
  | "1xl:ring-offset-purple-50"
  | "xl:ring-offset-purple-50"
  | "lg:ring-offset-purple-50"
  | "md:ring-offset-purple-50"
  | "sm:ring-offset-purple-50"
  | "xs:ring-offset-purple-50"
  | "focus-within:ring-offset-purple-50"
  | "focus:ring-offset-purple-50"
  | "2xl:ring-offset-purple-100"
  | "1xl:ring-offset-purple-100"
  | "xl:ring-offset-purple-100"
  | "lg:ring-offset-purple-100"
  | "md:ring-offset-purple-100"
  | "sm:ring-offset-purple-100"
  | "xs:ring-offset-purple-100"
  | "focus-within:ring-offset-purple-100"
  | "focus:ring-offset-purple-100"
  | "2xl:ring-offset-purple-200"
  | "1xl:ring-offset-purple-200"
  | "xl:ring-offset-purple-200"
  | "lg:ring-offset-purple-200"
  | "md:ring-offset-purple-200"
  | "sm:ring-offset-purple-200"
  | "xs:ring-offset-purple-200"
  | "focus-within:ring-offset-purple-200"
  | "focus:ring-offset-purple-200"
  | "2xl:ring-offset-purple-300"
  | "1xl:ring-offset-purple-300"
  | "xl:ring-offset-purple-300"
  | "lg:ring-offset-purple-300"
  | "md:ring-offset-purple-300"
  | "sm:ring-offset-purple-300"
  | "xs:ring-offset-purple-300"
  | "focus-within:ring-offset-purple-300"
  | "focus:ring-offset-purple-300"
  | "2xl:ring-offset-purple-400"
  | "1xl:ring-offset-purple-400"
  | "xl:ring-offset-purple-400"
  | "lg:ring-offset-purple-400"
  | "md:ring-offset-purple-400"
  | "sm:ring-offset-purple-400"
  | "xs:ring-offset-purple-400"
  | "focus-within:ring-offset-purple-400"
  | "focus:ring-offset-purple-400"
  | "2xl:ring-offset-purple-500"
  | "1xl:ring-offset-purple-500"
  | "xl:ring-offset-purple-500"
  | "lg:ring-offset-purple-500"
  | "md:ring-offset-purple-500"
  | "sm:ring-offset-purple-500"
  | "xs:ring-offset-purple-500"
  | "focus-within:ring-offset-purple-500"
  | "focus:ring-offset-purple-500"
  | "2xl:ring-offset-purple-600"
  | "1xl:ring-offset-purple-600"
  | "xl:ring-offset-purple-600"
  | "lg:ring-offset-purple-600"
  | "md:ring-offset-purple-600"
  | "sm:ring-offset-purple-600"
  | "xs:ring-offset-purple-600"
  | "focus-within:ring-offset-purple-600"
  | "focus:ring-offset-purple-600"
  | "2xl:ring-offset-purple-700"
  | "1xl:ring-offset-purple-700"
  | "xl:ring-offset-purple-700"
  | "lg:ring-offset-purple-700"
  | "md:ring-offset-purple-700"
  | "sm:ring-offset-purple-700"
  | "xs:ring-offset-purple-700"
  | "focus-within:ring-offset-purple-700"
  | "focus:ring-offset-purple-700"
  | "2xl:ring-offset-purple-800"
  | "1xl:ring-offset-purple-800"
  | "xl:ring-offset-purple-800"
  | "lg:ring-offset-purple-800"
  | "md:ring-offset-purple-800"
  | "sm:ring-offset-purple-800"
  | "xs:ring-offset-purple-800"
  | "focus-within:ring-offset-purple-800"
  | "focus:ring-offset-purple-800"
  | "2xl:ring-offset-purple-900"
  | "1xl:ring-offset-purple-900"
  | "xl:ring-offset-purple-900"
  | "lg:ring-offset-purple-900"
  | "md:ring-offset-purple-900"
  | "sm:ring-offset-purple-900"
  | "xs:ring-offset-purple-900"
  | "focus-within:ring-offset-purple-900"
  | "focus:ring-offset-purple-900"
  | "2xl:ring-offset-pink-50"
  | "1xl:ring-offset-pink-50"
  | "xl:ring-offset-pink-50"
  | "lg:ring-offset-pink-50"
  | "md:ring-offset-pink-50"
  | "sm:ring-offset-pink-50"
  | "xs:ring-offset-pink-50"
  | "focus-within:ring-offset-pink-50"
  | "focus:ring-offset-pink-50"
  | "2xl:ring-offset-pink-100"
  | "1xl:ring-offset-pink-100"
  | "xl:ring-offset-pink-100"
  | "lg:ring-offset-pink-100"
  | "md:ring-offset-pink-100"
  | "sm:ring-offset-pink-100"
  | "xs:ring-offset-pink-100"
  | "focus-within:ring-offset-pink-100"
  | "focus:ring-offset-pink-100"
  | "2xl:ring-offset-pink-200"
  | "1xl:ring-offset-pink-200"
  | "xl:ring-offset-pink-200"
  | "lg:ring-offset-pink-200"
  | "md:ring-offset-pink-200"
  | "sm:ring-offset-pink-200"
  | "xs:ring-offset-pink-200"
  | "focus-within:ring-offset-pink-200"
  | "focus:ring-offset-pink-200"
  | "2xl:ring-offset-pink-300"
  | "1xl:ring-offset-pink-300"
  | "xl:ring-offset-pink-300"
  | "lg:ring-offset-pink-300"
  | "md:ring-offset-pink-300"
  | "sm:ring-offset-pink-300"
  | "xs:ring-offset-pink-300"
  | "focus-within:ring-offset-pink-300"
  | "focus:ring-offset-pink-300"
  | "2xl:ring-offset-pink-400"
  | "1xl:ring-offset-pink-400"
  | "xl:ring-offset-pink-400"
  | "lg:ring-offset-pink-400"
  | "md:ring-offset-pink-400"
  | "sm:ring-offset-pink-400"
  | "xs:ring-offset-pink-400"
  | "focus-within:ring-offset-pink-400"
  | "focus:ring-offset-pink-400"
  | "2xl:ring-offset-pink-500"
  | "1xl:ring-offset-pink-500"
  | "xl:ring-offset-pink-500"
  | "lg:ring-offset-pink-500"
  | "md:ring-offset-pink-500"
  | "sm:ring-offset-pink-500"
  | "xs:ring-offset-pink-500"
  | "focus-within:ring-offset-pink-500"
  | "focus:ring-offset-pink-500"
  | "2xl:ring-offset-pink-600"
  | "1xl:ring-offset-pink-600"
  | "xl:ring-offset-pink-600"
  | "lg:ring-offset-pink-600"
  | "md:ring-offset-pink-600"
  | "sm:ring-offset-pink-600"
  | "xs:ring-offset-pink-600"
  | "focus-within:ring-offset-pink-600"
  | "focus:ring-offset-pink-600"
  | "2xl:ring-offset-pink-700"
  | "1xl:ring-offset-pink-700"
  | "xl:ring-offset-pink-700"
  | "lg:ring-offset-pink-700"
  | "md:ring-offset-pink-700"
  | "sm:ring-offset-pink-700"
  | "xs:ring-offset-pink-700"
  | "focus-within:ring-offset-pink-700"
  | "focus:ring-offset-pink-700"
  | "2xl:ring-offset-pink-800"
  | "1xl:ring-offset-pink-800"
  | "xl:ring-offset-pink-800"
  | "lg:ring-offset-pink-800"
  | "md:ring-offset-pink-800"
  | "sm:ring-offset-pink-800"
  | "xs:ring-offset-pink-800"
  | "focus-within:ring-offset-pink-800"
  | "focus:ring-offset-pink-800"
  | "2xl:ring-offset-pink-900"
  | "1xl:ring-offset-pink-900"
  | "xl:ring-offset-pink-900"
  | "lg:ring-offset-pink-900"
  | "md:ring-offset-pink-900"
  | "sm:ring-offset-pink-900"
  | "xs:ring-offset-pink-900"
  | "focus-within:ring-offset-pink-900"
  | "focus:ring-offset-pink-900"
  | "2xl:ring-offset-title-color-1"
  | "1xl:ring-offset-title-color-1"
  | "xl:ring-offset-title-color-1"
  | "lg:ring-offset-title-color-1"
  | "md:ring-offset-title-color-1"
  | "sm:ring-offset-title-color-1"
  | "xs:ring-offset-title-color-1"
  | "focus-within:ring-offset-title-color-1"
  | "focus:ring-offset-title-color-1"
  | "2xl:ring-offset-primary-bg-color"
  | "1xl:ring-offset-primary-bg-color"
  | "xl:ring-offset-primary-bg-color"
  | "lg:ring-offset-primary-bg-color"
  | "md:ring-offset-primary-bg-color"
  | "sm:ring-offset-primary-bg-color"
  | "xs:ring-offset-primary-bg-color"
  | "focus-within:ring-offset-primary-bg-color"
  | "focus:ring-offset-primary-bg-color"
  | "2xl:ring-offset-secondary-bg-color"
  | "1xl:ring-offset-secondary-bg-color"
  | "xl:ring-offset-secondary-bg-color"
  | "lg:ring-offset-secondary-bg-color"
  | "md:ring-offset-secondary-bg-color"
  | "sm:ring-offset-secondary-bg-color"
  | "xs:ring-offset-secondary-bg-color"
  | "focus-within:ring-offset-secondary-bg-color"
  | "focus:ring-offset-secondary-bg-color"
  | "2xl:ring-offset-sidebar-bg-color"
  | "1xl:ring-offset-sidebar-bg-color"
  | "xl:ring-offset-sidebar-bg-color"
  | "lg:ring-offset-sidebar-bg-color"
  | "md:ring-offset-sidebar-bg-color"
  | "sm:ring-offset-sidebar-bg-color"
  | "xs:ring-offset-sidebar-bg-color"
  | "focus-within:ring-offset-sidebar-bg-color"
  | "focus:ring-offset-sidebar-bg-color"
  | "2xl:ring-offset-header-bg-color"
  | "1xl:ring-offset-header-bg-color"
  | "xl:ring-offset-header-bg-color"
  | "lg:ring-offset-header-bg-color"
  | "md:ring-offset-header-bg-color"
  | "sm:ring-offset-header-bg-color"
  | "xs:ring-offset-header-bg-color"
  | "focus-within:ring-offset-header-bg-color"
  | "focus:ring-offset-header-bg-color"
  | "2xl:ring-offset-body-bg-color"
  | "1xl:ring-offset-body-bg-color"
  | "xl:ring-offset-body-bg-color"
  | "lg:ring-offset-body-bg-color"
  | "md:ring-offset-body-bg-color"
  | "sm:ring-offset-body-bg-color"
  | "xs:ring-offset-body-bg-color"
  | "focus-within:ring-offset-body-bg-color"
  | "focus:ring-offset-body-bg-color"
  | "2xl:ring-offset-main-color"
  | "1xl:ring-offset-main-color"
  | "xl:ring-offset-main-color"
  | "lg:ring-offset-main-color"
  | "md:ring-offset-main-color"
  | "sm:ring-offset-main-color"
  | "xs:ring-offset-main-color"
  | "focus-within:ring-offset-main-color"
  | "focus:ring-offset-main-color"
  | "2xl:ring-offset-tertiary-bg-color"
  | "1xl:ring-offset-tertiary-bg-color"
  | "xl:ring-offset-tertiary-bg-color"
  | "lg:ring-offset-tertiary-bg-color"
  | "md:ring-offset-tertiary-bg-color"
  | "sm:ring-offset-tertiary-bg-color"
  | "xs:ring-offset-tertiary-bg-color"
  | "focus-within:ring-offset-tertiary-bg-color"
  | "focus:ring-offset-tertiary-bg-color"
  | "2xl:ring-offset-quaternary-bg-color"
  | "1xl:ring-offset-quaternary-bg-color"
  | "xl:ring-offset-quaternary-bg-color"
  | "lg:ring-offset-quaternary-bg-color"
  | "md:ring-offset-quaternary-bg-color"
  | "sm:ring-offset-quaternary-bg-color"
  | "xs:ring-offset-quaternary-bg-color"
  | "focus-within:ring-offset-quaternary-bg-color"
  | "focus:ring-offset-quaternary-bg-color"
  | "2xl:ring-offset-earnings-bg-color"
  | "1xl:ring-offset-earnings-bg-color"
  | "xl:ring-offset-earnings-bg-color"
  | "lg:ring-offset-earnings-bg-color"
  | "md:ring-offset-earnings-bg-color"
  | "sm:ring-offset-earnings-bg-color"
  | "xs:ring-offset-earnings-bg-color"
  | "focus-within:ring-offset-earnings-bg-color"
  | "focus:ring-offset-earnings-bg-color"
  | "2xl:ring-offset-card-secondary-bg-color"
  | "1xl:ring-offset-card-secondary-bg-color"
  | "xl:ring-offset-card-secondary-bg-color"
  | "lg:ring-offset-card-secondary-bg-color"
  | "md:ring-offset-card-secondary-bg-color"
  | "sm:ring-offset-card-secondary-bg-color"
  | "xs:ring-offset-card-secondary-bg-color"
  | "focus-within:ring-offset-card-secondary-bg-color"
  | "focus:ring-offset-card-secondary-bg-color"
  | "2xl:ring-offset-pink-color"
  | "1xl:ring-offset-pink-color"
  | "xl:ring-offset-pink-color"
  | "lg:ring-offset-pink-color"
  | "md:ring-offset-pink-color"
  | "sm:ring-offset-pink-color"
  | "xs:ring-offset-pink-color"
  | "focus-within:ring-offset-pink-color"
  | "focus:ring-offset-pink-color"
  | "2xl:ring-offset-go-live-text"
  | "1xl:ring-offset-go-live-text"
  | "xl:ring-offset-go-live-text"
  | "lg:ring-offset-go-live-text"
  | "md:ring-offset-go-live-text"
  | "sm:ring-offset-go-live-text"
  | "xs:ring-offset-go-live-text"
  | "focus-within:ring-offset-go-live-text"
  | "focus:ring-offset-go-live-text"
  | "2xl:ring-offset-go-live-bg-color"
  | "1xl:ring-offset-go-live-bg-color"
  | "xl:ring-offset-go-live-bg-color"
  | "lg:ring-offset-go-live-bg-color"
  | "md:ring-offset-go-live-bg-color"
  | "sm:ring-offset-go-live-bg-color"
  | "xs:ring-offset-go-live-bg-color"
  | "focus-within:ring-offset-go-live-bg-color"
  | "focus:ring-offset-go-live-bg-color"
  | "2xl:ring-offset-gray-button"
  | "1xl:ring-offset-gray-button"
  | "xl:ring-offset-gray-button"
  | "lg:ring-offset-gray-button"
  | "md:ring-offset-gray-button"
  | "sm:ring-offset-gray-button"
  | "xs:ring-offset-gray-button"
  | "focus-within:ring-offset-gray-button"
  | "focus:ring-offset-gray-button"
  | "2xl:ring-offset-toggle-disabled"
  | "1xl:ring-offset-toggle-disabled"
  | "xl:ring-offset-toggle-disabled"
  | "lg:ring-offset-toggle-disabled"
  | "md:ring-offset-toggle-disabled"
  | "sm:ring-offset-toggle-disabled"
  | "xs:ring-offset-toggle-disabled"
  | "focus-within:ring-offset-toggle-disabled"
  | "focus:ring-offset-toggle-disabled"
  | "2xl:ring-offset-tab-not-selected-color"
  | "1xl:ring-offset-tab-not-selected-color"
  | "xl:ring-offset-tab-not-selected-color"
  | "lg:ring-offset-tab-not-selected-color"
  | "md:ring-offset-tab-not-selected-color"
  | "sm:ring-offset-tab-not-selected-color"
  | "xs:ring-offset-tab-not-selected-color"
  | "focus-within:ring-offset-tab-not-selected-color"
  | "focus:ring-offset-tab-not-selected-color"
  | "2xl:ring-offset-text-area-bg-color"
  | "1xl:ring-offset-text-area-bg-color"
  | "xl:ring-offset-text-area-bg-color"
  | "lg:ring-offset-text-area-bg-color"
  | "md:ring-offset-text-area-bg-color"
  | "sm:ring-offset-text-area-bg-color"
  | "xs:ring-offset-text-area-bg-color"
  | "focus-within:ring-offset-text-area-bg-color"
  | "focus:ring-offset-text-area-bg-color"
  | "2xl:ring-offset-table-primary-bg-color"
  | "1xl:ring-offset-table-primary-bg-color"
  | "xl:ring-offset-table-primary-bg-color"
  | "lg:ring-offset-table-primary-bg-color"
  | "md:ring-offset-table-primary-bg-color"
  | "sm:ring-offset-table-primary-bg-color"
  | "xs:ring-offset-table-primary-bg-color"
  | "focus-within:ring-offset-table-primary-bg-color"
  | "focus:ring-offset-table-primary-bg-color"
  | "2xl:ring-offset-table-secondary-color"
  | "1xl:ring-offset-table-secondary-color"
  | "xl:ring-offset-table-secondary-color"
  | "lg:ring-offset-table-secondary-color"
  | "md:ring-offset-table-secondary-color"
  | "sm:ring-offset-table-secondary-color"
  | "xs:ring-offset-table-secondary-color"
  | "focus-within:ring-offset-table-secondary-color"
  | "focus:ring-offset-table-secondary-color"
  | "2xl:ring-offset-modal-button-color"
  | "1xl:ring-offset-modal-button-color"
  | "xl:ring-offset-modal-button-color"
  | "lg:ring-offset-modal-button-color"
  | "md:ring-offset-modal-button-color"
  | "sm:ring-offset-modal-button-color"
  | "xs:ring-offset-modal-button-color"
  | "focus-within:ring-offset-modal-button-color"
  | "focus:ring-offset-modal-button-color"
  | "2xl:ring-offset-0"
  | "1xl:ring-offset-0"
  | "xl:ring-offset-0"
  | "lg:ring-offset-0"
  | "md:ring-offset-0"
  | "sm:ring-offset-0"
  | "xs:ring-offset-0"
  | "focus-within:ring-offset-0"
  | "focus:ring-offset-0"
  | "2xl:ring-offset-1"
  | "1xl:ring-offset-1"
  | "xl:ring-offset-1"
  | "lg:ring-offset-1"
  | "md:ring-offset-1"
  | "sm:ring-offset-1"
  | "xs:ring-offset-1"
  | "focus-within:ring-offset-1"
  | "focus:ring-offset-1"
  | "2xl:ring-offset-2"
  | "1xl:ring-offset-2"
  | "xl:ring-offset-2"
  | "lg:ring-offset-2"
  | "md:ring-offset-2"
  | "sm:ring-offset-2"
  | "xs:ring-offset-2"
  | "focus-within:ring-offset-2"
  | "focus:ring-offset-2"
  | "2xl:ring-offset-4"
  | "1xl:ring-offset-4"
  | "xl:ring-offset-4"
  | "lg:ring-offset-4"
  | "md:ring-offset-4"
  | "sm:ring-offset-4"
  | "xs:ring-offset-4"
  | "focus-within:ring-offset-4"
  | "focus:ring-offset-4"
  | "2xl:ring-offset-8"
  | "1xl:ring-offset-8"
  | "xl:ring-offset-8"
  | "lg:ring-offset-8"
  | "md:ring-offset-8"
  | "sm:ring-offset-8"
  | "xs:ring-offset-8"
  | "focus-within:ring-offset-8"
  | "focus:ring-offset-8"
  | "2xl:ring-opacity-0"
  | "1xl:ring-opacity-0"
  | "xl:ring-opacity-0"
  | "lg:ring-opacity-0"
  | "md:ring-opacity-0"
  | "sm:ring-opacity-0"
  | "xs:ring-opacity-0"
  | "focus-within:ring-opacity-0"
  | "focus:ring-opacity-0"
  | "2xl:ring-opacity-5"
  | "1xl:ring-opacity-5"
  | "xl:ring-opacity-5"
  | "lg:ring-opacity-5"
  | "md:ring-opacity-5"
  | "sm:ring-opacity-5"
  | "xs:ring-opacity-5"
  | "focus-within:ring-opacity-5"
  | "focus:ring-opacity-5"
  | "2xl:ring-opacity-10"
  | "1xl:ring-opacity-10"
  | "xl:ring-opacity-10"
  | "lg:ring-opacity-10"
  | "md:ring-opacity-10"
  | "sm:ring-opacity-10"
  | "xs:ring-opacity-10"
  | "focus-within:ring-opacity-10"
  | "focus:ring-opacity-10"
  | "2xl:ring-opacity-20"
  | "1xl:ring-opacity-20"
  | "xl:ring-opacity-20"
  | "lg:ring-opacity-20"
  | "md:ring-opacity-20"
  | "sm:ring-opacity-20"
  | "xs:ring-opacity-20"
  | "focus-within:ring-opacity-20"
  | "focus:ring-opacity-20"
  | "2xl:ring-opacity-25"
  | "1xl:ring-opacity-25"
  | "xl:ring-opacity-25"
  | "lg:ring-opacity-25"
  | "md:ring-opacity-25"
  | "sm:ring-opacity-25"
  | "xs:ring-opacity-25"
  | "focus-within:ring-opacity-25"
  | "focus:ring-opacity-25"
  | "2xl:ring-opacity-30"
  | "1xl:ring-opacity-30"
  | "xl:ring-opacity-30"
  | "lg:ring-opacity-30"
  | "md:ring-opacity-30"
  | "sm:ring-opacity-30"
  | "xs:ring-opacity-30"
  | "focus-within:ring-opacity-30"
  | "focus:ring-opacity-30"
  | "2xl:ring-opacity-40"
  | "1xl:ring-opacity-40"
  | "xl:ring-opacity-40"
  | "lg:ring-opacity-40"
  | "md:ring-opacity-40"
  | "sm:ring-opacity-40"
  | "xs:ring-opacity-40"
  | "focus-within:ring-opacity-40"
  | "focus:ring-opacity-40"
  | "2xl:ring-opacity-50"
  | "1xl:ring-opacity-50"
  | "xl:ring-opacity-50"
  | "lg:ring-opacity-50"
  | "md:ring-opacity-50"
  | "sm:ring-opacity-50"
  | "xs:ring-opacity-50"
  | "focus-within:ring-opacity-50"
  | "focus:ring-opacity-50"
  | "2xl:ring-opacity-60"
  | "1xl:ring-opacity-60"
  | "xl:ring-opacity-60"
  | "lg:ring-opacity-60"
  | "md:ring-opacity-60"
  | "sm:ring-opacity-60"
  | "xs:ring-opacity-60"
  | "focus-within:ring-opacity-60"
  | "focus:ring-opacity-60"
  | "2xl:ring-opacity-70"
  | "1xl:ring-opacity-70"
  | "xl:ring-opacity-70"
  | "lg:ring-opacity-70"
  | "md:ring-opacity-70"
  | "sm:ring-opacity-70"
  | "xs:ring-opacity-70"
  | "focus-within:ring-opacity-70"
  | "focus:ring-opacity-70"
  | "2xl:ring-opacity-75"
  | "1xl:ring-opacity-75"
  | "xl:ring-opacity-75"
  | "lg:ring-opacity-75"
  | "md:ring-opacity-75"
  | "sm:ring-opacity-75"
  | "xs:ring-opacity-75"
  | "focus-within:ring-opacity-75"
  | "focus:ring-opacity-75"
  | "2xl:ring-opacity-80"
  | "1xl:ring-opacity-80"
  | "xl:ring-opacity-80"
  | "lg:ring-opacity-80"
  | "md:ring-opacity-80"
  | "sm:ring-opacity-80"
  | "xs:ring-opacity-80"
  | "focus-within:ring-opacity-80"
  | "focus:ring-opacity-80"
  | "2xl:ring-opacity-90"
  | "1xl:ring-opacity-90"
  | "xl:ring-opacity-90"
  | "lg:ring-opacity-90"
  | "md:ring-opacity-90"
  | "sm:ring-opacity-90"
  | "xs:ring-opacity-90"
  | "focus-within:ring-opacity-90"
  | "focus:ring-opacity-90"
  | "2xl:ring-opacity-95"
  | "1xl:ring-opacity-95"
  | "xl:ring-opacity-95"
  | "lg:ring-opacity-95"
  | "md:ring-opacity-95"
  | "sm:ring-opacity-95"
  | "xs:ring-opacity-95"
  | "focus-within:ring-opacity-95"
  | "focus:ring-opacity-95"
  | "2xl:ring-opacity-100"
  | "1xl:ring-opacity-100"
  | "xl:ring-opacity-100"
  | "lg:ring-opacity-100"
  | "md:ring-opacity-100"
  | "sm:ring-opacity-100"
  | "xs:ring-opacity-100"
  | "focus-within:ring-opacity-100"
  | "focus:ring-opacity-100"
  | "2xl:ring-opacity"
  | "1xl:ring-opacity"
  | "xl:ring-opacity"
  | "lg:ring-opacity"
  | "md:ring-opacity"
  | "sm:ring-opacity"
  | "xs:ring-opacity"
  | "focus-within:ring-opacity"
  | "focus:ring-opacity"
  | "2xl:ring-0"
  | "1xl:ring-0"
  | "xl:ring-0"
  | "lg:ring-0"
  | "md:ring-0"
  | "sm:ring-0"
  | "xs:ring-0"
  | "focus-within:ring-0"
  | "focus:ring-0"
  | "2xl:ring-1"
  | "1xl:ring-1"
  | "xl:ring-1"
  | "lg:ring-1"
  | "md:ring-1"
  | "sm:ring-1"
  | "xs:ring-1"
  | "focus-within:ring-1"
  | "focus:ring-1"
  | "2xl:ring-2"
  | "1xl:ring-2"
  | "xl:ring-2"
  | "lg:ring-2"
  | "md:ring-2"
  | "sm:ring-2"
  | "xs:ring-2"
  | "focus-within:ring-2"
  | "focus:ring-2"
  | "2xl:ring-4"
  | "1xl:ring-4"
  | "xl:ring-4"
  | "lg:ring-4"
  | "md:ring-4"
  | "sm:ring-4"
  | "xs:ring-4"
  | "focus-within:ring-4"
  | "focus:ring-4"
  | "2xl:ring-8"
  | "1xl:ring-8"
  | "xl:ring-8"
  | "lg:ring-8"
  | "md:ring-8"
  | "sm:ring-8"
  | "xs:ring-8"
  | "focus-within:ring-8"
  | "focus:ring-8"
  | "2xl:ring"
  | "1xl:ring"
  | "xl:ring"
  | "lg:ring"
  | "md:ring"
  | "sm:ring"
  | "xs:ring"
  | "focus-within:ring"
  | "focus:ring"
  | "2xl:inset"
  | "1xl:inset"
  | "xl:inset"
  | "lg:inset"
  | "md:inset"
  | "sm:inset"
  | "xs:inset"
  | "focus-within:inset"
  | "focus:inset"
  | "2xl:rotate-0"
  | "1xl:rotate-0"
  | "xl:rotate-0"
  | "lg:rotate-0"
  | "md:rotate-0"
  | "sm:rotate-0"
  | "xs:rotate-0"
  | "hover:rotate-0"
  | "focus:rotate-0"
  | "2xl:rotate-1"
  | "1xl:rotate-1"
  | "xl:rotate-1"
  | "lg:rotate-1"
  | "md:rotate-1"
  | "sm:rotate-1"
  | "xs:rotate-1"
  | "hover:rotate-1"
  | "focus:rotate-1"
  | "2xl:rotate-2"
  | "1xl:rotate-2"
  | "xl:rotate-2"
  | "lg:rotate-2"
  | "md:rotate-2"
  | "sm:rotate-2"
  | "xs:rotate-2"
  | "hover:rotate-2"
  | "focus:rotate-2"
  | "2xl:rotate-3"
  | "1xl:rotate-3"
  | "xl:rotate-3"
  | "lg:rotate-3"
  | "md:rotate-3"
  | "sm:rotate-3"
  | "xs:rotate-3"
  | "hover:rotate-3"
  | "focus:rotate-3"
  | "2xl:rotate-6"
  | "1xl:rotate-6"
  | "xl:rotate-6"
  | "lg:rotate-6"
  | "md:rotate-6"
  | "sm:rotate-6"
  | "xs:rotate-6"
  | "hover:rotate-6"
  | "focus:rotate-6"
  | "2xl:rotate-12"
  | "1xl:rotate-12"
  | "xl:rotate-12"
  | "lg:rotate-12"
  | "md:rotate-12"
  | "sm:rotate-12"
  | "xs:rotate-12"
  | "hover:rotate-12"
  | "focus:rotate-12"
  | "2xl:rotate-45"
  | "1xl:rotate-45"
  | "xl:rotate-45"
  | "lg:rotate-45"
  | "md:rotate-45"
  | "sm:rotate-45"
  | "xs:rotate-45"
  | "hover:rotate-45"
  | "focus:rotate-45"
  | "2xl:rotate-90"
  | "1xl:rotate-90"
  | "xl:rotate-90"
  | "lg:rotate-90"
  | "md:rotate-90"
  | "sm:rotate-90"
  | "xs:rotate-90"
  | "hover:rotate-90"
  | "focus:rotate-90"
  | "2xl:rotate-180"
  | "1xl:rotate-180"
  | "xl:rotate-180"
  | "lg:rotate-180"
  | "md:rotate-180"
  | "sm:rotate-180"
  | "xs:rotate-180"
  | "hover:rotate-180"
  | "focus:rotate-180"
  | "2xl:-rotate-180"
  | "1xl:-rotate-180"
  | "xl:-rotate-180"
  | "lg:-rotate-180"
  | "md:-rotate-180"
  | "sm:-rotate-180"
  | "xs:-rotate-180"
  | "hover:-rotate-180"
  | "focus:-rotate-180"
  | "2xl:-rotate-90"
  | "1xl:-rotate-90"
  | "xl:-rotate-90"
  | "lg:-rotate-90"
  | "md:-rotate-90"
  | "sm:-rotate-90"
  | "xs:-rotate-90"
  | "hover:-rotate-90"
  | "focus:-rotate-90"
  | "2xl:-rotate-45"
  | "1xl:-rotate-45"
  | "xl:-rotate-45"
  | "lg:-rotate-45"
  | "md:-rotate-45"
  | "sm:-rotate-45"
  | "xs:-rotate-45"
  | "hover:-rotate-45"
  | "focus:-rotate-45"
  | "2xl:-rotate-12"
  | "1xl:-rotate-12"
  | "xl:-rotate-12"
  | "lg:-rotate-12"
  | "md:-rotate-12"
  | "sm:-rotate-12"
  | "xs:-rotate-12"
  | "hover:-rotate-12"
  | "focus:-rotate-12"
  | "2xl:-rotate-6"
  | "1xl:-rotate-6"
  | "xl:-rotate-6"
  | "lg:-rotate-6"
  | "md:-rotate-6"
  | "sm:-rotate-6"
  | "xs:-rotate-6"
  | "hover:-rotate-6"
  | "focus:-rotate-6"
  | "2xl:-rotate-3"
  | "1xl:-rotate-3"
  | "xl:-rotate-3"
  | "lg:-rotate-3"
  | "md:-rotate-3"
  | "sm:-rotate-3"
  | "xs:-rotate-3"
  | "hover:-rotate-3"
  | "focus:-rotate-3"
  | "2xl:-rotate-2"
  | "1xl:-rotate-2"
  | "xl:-rotate-2"
  | "lg:-rotate-2"
  | "md:-rotate-2"
  | "sm:-rotate-2"
  | "xs:-rotate-2"
  | "hover:-rotate-2"
  | "focus:-rotate-2"
  | "2xl:-rotate-1"
  | "1xl:-rotate-1"
  | "xl:-rotate-1"
  | "lg:-rotate-1"
  | "md:-rotate-1"
  | "sm:-rotate-1"
  | "xs:-rotate-1"
  | "hover:-rotate-1"
  | "focus:-rotate-1"
  | "2xl:saturate-0"
  | "1xl:saturate-0"
  | "xl:saturate-0"
  | "lg:saturate-0"
  | "md:saturate-0"
  | "sm:saturate-0"
  | "xs:saturate-0"
  | "2xl:saturate-50"
  | "1xl:saturate-50"
  | "xl:saturate-50"
  | "lg:saturate-50"
  | "md:saturate-50"
  | "sm:saturate-50"
  | "xs:saturate-50"
  | "2xl:saturate-100"
  | "1xl:saturate-100"
  | "xl:saturate-100"
  | "lg:saturate-100"
  | "md:saturate-100"
  | "sm:saturate-100"
  | "xs:saturate-100"
  | "2xl:saturate-150"
  | "1xl:saturate-150"
  | "xl:saturate-150"
  | "lg:saturate-150"
  | "md:saturate-150"
  | "sm:saturate-150"
  | "xs:saturate-150"
  | "2xl:saturate-200"
  | "1xl:saturate-200"
  | "xl:saturate-200"
  | "lg:saturate-200"
  | "md:saturate-200"
  | "sm:saturate-200"
  | "xs:saturate-200"
  | "2xl:scale-0"
  | "1xl:scale-0"
  | "xl:scale-0"
  | "lg:scale-0"
  | "md:scale-0"
  | "sm:scale-0"
  | "xs:scale-0"
  | "hover:scale-0"
  | "focus:scale-0"
  | "2xl:scale-50"
  | "1xl:scale-50"
  | "xl:scale-50"
  | "lg:scale-50"
  | "md:scale-50"
  | "sm:scale-50"
  | "xs:scale-50"
  | "hover:scale-50"
  | "focus:scale-50"
  | "2xl:scale-75"
  | "1xl:scale-75"
  | "xl:scale-75"
  | "lg:scale-75"
  | "md:scale-75"
  | "sm:scale-75"
  | "xs:scale-75"
  | "hover:scale-75"
  | "focus:scale-75"
  | "2xl:scale-90"
  | "1xl:scale-90"
  | "xl:scale-90"
  | "lg:scale-90"
  | "md:scale-90"
  | "sm:scale-90"
  | "xs:scale-90"
  | "hover:scale-90"
  | "focus:scale-90"
  | "2xl:scale-95"
  | "1xl:scale-95"
  | "xl:scale-95"
  | "lg:scale-95"
  | "md:scale-95"
  | "sm:scale-95"
  | "xs:scale-95"
  | "hover:scale-95"
  | "focus:scale-95"
  | "2xl:scale-100"
  | "1xl:scale-100"
  | "xl:scale-100"
  | "lg:scale-100"
  | "md:scale-100"
  | "sm:scale-100"
  | "xs:scale-100"
  | "hover:scale-100"
  | "focus:scale-100"
  | "2xl:scale-105"
  | "1xl:scale-105"
  | "xl:scale-105"
  | "lg:scale-105"
  | "md:scale-105"
  | "sm:scale-105"
  | "xs:scale-105"
  | "hover:scale-105"
  | "focus:scale-105"
  | "2xl:scale-110"
  | "1xl:scale-110"
  | "xl:scale-110"
  | "lg:scale-110"
  | "md:scale-110"
  | "sm:scale-110"
  | "xs:scale-110"
  | "hover:scale-110"
  | "focus:scale-110"
  | "2xl:scale-125"
  | "1xl:scale-125"
  | "xl:scale-125"
  | "lg:scale-125"
  | "md:scale-125"
  | "sm:scale-125"
  | "xs:scale-125"
  | "hover:scale-125"
  | "focus:scale-125"
  | "2xl:scale-150"
  | "1xl:scale-150"
  | "xl:scale-150"
  | "lg:scale-150"
  | "md:scale-150"
  | "sm:scale-150"
  | "xs:scale-150"
  | "hover:scale-150"
  | "focus:scale-150"
  | "2xl:scale-x-0"
  | "1xl:scale-x-0"
  | "xl:scale-x-0"
  | "lg:scale-x-0"
  | "md:scale-x-0"
  | "sm:scale-x-0"
  | "xs:scale-x-0"
  | "hover:scale-x-0"
  | "focus:scale-x-0"
  | "2xl:scale-x-50"
  | "1xl:scale-x-50"
  | "xl:scale-x-50"
  | "lg:scale-x-50"
  | "md:scale-x-50"
  | "sm:scale-x-50"
  | "xs:scale-x-50"
  | "hover:scale-x-50"
  | "focus:scale-x-50"
  | "2xl:scale-x-75"
  | "1xl:scale-x-75"
  | "xl:scale-x-75"
  | "lg:scale-x-75"
  | "md:scale-x-75"
  | "sm:scale-x-75"
  | "xs:scale-x-75"
  | "hover:scale-x-75"
  | "focus:scale-x-75"
  | "2xl:scale-x-90"
  | "1xl:scale-x-90"
  | "xl:scale-x-90"
  | "lg:scale-x-90"
  | "md:scale-x-90"
  | "sm:scale-x-90"
  | "xs:scale-x-90"
  | "hover:scale-x-90"
  | "focus:scale-x-90"
  | "2xl:scale-x-95"
  | "1xl:scale-x-95"
  | "xl:scale-x-95"
  | "lg:scale-x-95"
  | "md:scale-x-95"
  | "sm:scale-x-95"
  | "xs:scale-x-95"
  | "hover:scale-x-95"
  | "focus:scale-x-95"
  | "2xl:scale-x-100"
  | "1xl:scale-x-100"
  | "xl:scale-x-100"
  | "lg:scale-x-100"
  | "md:scale-x-100"
  | "sm:scale-x-100"
  | "xs:scale-x-100"
  | "hover:scale-x-100"
  | "focus:scale-x-100"
  | "2xl:scale-x-105"
  | "1xl:scale-x-105"
  | "xl:scale-x-105"
  | "lg:scale-x-105"
  | "md:scale-x-105"
  | "sm:scale-x-105"
  | "xs:scale-x-105"
  | "hover:scale-x-105"
  | "focus:scale-x-105"
  | "2xl:scale-x-110"
  | "1xl:scale-x-110"
  | "xl:scale-x-110"
  | "lg:scale-x-110"
  | "md:scale-x-110"
  | "sm:scale-x-110"
  | "xs:scale-x-110"
  | "hover:scale-x-110"
  | "focus:scale-x-110"
  | "2xl:scale-x-125"
  | "1xl:scale-x-125"
  | "xl:scale-x-125"
  | "lg:scale-x-125"
  | "md:scale-x-125"
  | "sm:scale-x-125"
  | "xs:scale-x-125"
  | "hover:scale-x-125"
  | "focus:scale-x-125"
  | "2xl:scale-x-150"
  | "1xl:scale-x-150"
  | "xl:scale-x-150"
  | "lg:scale-x-150"
  | "md:scale-x-150"
  | "sm:scale-x-150"
  | "xs:scale-x-150"
  | "hover:scale-x-150"
  | "focus:scale-x-150"
  | "2xl:scale-y-0"
  | "1xl:scale-y-0"
  | "xl:scale-y-0"
  | "lg:scale-y-0"
  | "md:scale-y-0"
  | "sm:scale-y-0"
  | "xs:scale-y-0"
  | "hover:scale-y-0"
  | "focus:scale-y-0"
  | "2xl:scale-y-50"
  | "1xl:scale-y-50"
  | "xl:scale-y-50"
  | "lg:scale-y-50"
  | "md:scale-y-50"
  | "sm:scale-y-50"
  | "xs:scale-y-50"
  | "hover:scale-y-50"
  | "focus:scale-y-50"
  | "2xl:scale-y-75"
  | "1xl:scale-y-75"
  | "xl:scale-y-75"
  | "lg:scale-y-75"
  | "md:scale-y-75"
  | "sm:scale-y-75"
  | "xs:scale-y-75"
  | "hover:scale-y-75"
  | "focus:scale-y-75"
  | "2xl:scale-y-90"
  | "1xl:scale-y-90"
  | "xl:scale-y-90"
  | "lg:scale-y-90"
  | "md:scale-y-90"
  | "sm:scale-y-90"
  | "xs:scale-y-90"
  | "hover:scale-y-90"
  | "focus:scale-y-90"
  | "2xl:scale-y-95"
  | "1xl:scale-y-95"
  | "xl:scale-y-95"
  | "lg:scale-y-95"
  | "md:scale-y-95"
  | "sm:scale-y-95"
  | "xs:scale-y-95"
  | "hover:scale-y-95"
  | "focus:scale-y-95"
  | "2xl:scale-y-100"
  | "1xl:scale-y-100"
  | "xl:scale-y-100"
  | "lg:scale-y-100"
  | "md:scale-y-100"
  | "sm:scale-y-100"
  | "xs:scale-y-100"
  | "hover:scale-y-100"
  | "focus:scale-y-100"
  | "2xl:scale-y-105"
  | "1xl:scale-y-105"
  | "xl:scale-y-105"
  | "lg:scale-y-105"
  | "md:scale-y-105"
  | "sm:scale-y-105"
  | "xs:scale-y-105"
  | "hover:scale-y-105"
  | "focus:scale-y-105"
  | "2xl:scale-y-110"
  | "1xl:scale-y-110"
  | "xl:scale-y-110"
  | "lg:scale-y-110"
  | "md:scale-y-110"
  | "sm:scale-y-110"
  | "xs:scale-y-110"
  | "hover:scale-y-110"
  | "focus:scale-y-110"
  | "2xl:scale-y-125"
  | "1xl:scale-y-125"
  | "xl:scale-y-125"
  | "lg:scale-y-125"
  | "md:scale-y-125"
  | "sm:scale-y-125"
  | "xs:scale-y-125"
  | "hover:scale-y-125"
  | "focus:scale-y-125"
  | "2xl:scale-y-150"
  | "1xl:scale-y-150"
  | "xl:scale-y-150"
  | "lg:scale-y-150"
  | "md:scale-y-150"
  | "sm:scale-y-150"
  | "xs:scale-y-150"
  | "hover:scale-y-150"
  | "focus:scale-y-150"
  | "2xl:sepia-0"
  | "1xl:sepia-0"
  | "xl:sepia-0"
  | "lg:sepia-0"
  | "md:sepia-0"
  | "sm:sepia-0"
  | "xs:sepia-0"
  | "2xl:sepia"
  | "1xl:sepia"
  | "xl:sepia"
  | "lg:sepia"
  | "md:sepia"
  | "sm:sepia"
  | "xs:sepia"
  | "2xl:skew-x-0"
  | "1xl:skew-x-0"
  | "xl:skew-x-0"
  | "lg:skew-x-0"
  | "md:skew-x-0"
  | "sm:skew-x-0"
  | "xs:skew-x-0"
  | "hover:skew-x-0"
  | "focus:skew-x-0"
  | "2xl:skew-x-1"
  | "1xl:skew-x-1"
  | "xl:skew-x-1"
  | "lg:skew-x-1"
  | "md:skew-x-1"
  | "sm:skew-x-1"
  | "xs:skew-x-1"
  | "hover:skew-x-1"
  | "focus:skew-x-1"
  | "2xl:skew-x-2"
  | "1xl:skew-x-2"
  | "xl:skew-x-2"
  | "lg:skew-x-2"
  | "md:skew-x-2"
  | "sm:skew-x-2"
  | "xs:skew-x-2"
  | "hover:skew-x-2"
  | "focus:skew-x-2"
  | "2xl:skew-x-3"
  | "1xl:skew-x-3"
  | "xl:skew-x-3"
  | "lg:skew-x-3"
  | "md:skew-x-3"
  | "sm:skew-x-3"
  | "xs:skew-x-3"
  | "hover:skew-x-3"
  | "focus:skew-x-3"
  | "2xl:skew-x-6"
  | "1xl:skew-x-6"
  | "xl:skew-x-6"
  | "lg:skew-x-6"
  | "md:skew-x-6"
  | "sm:skew-x-6"
  | "xs:skew-x-6"
  | "hover:skew-x-6"
  | "focus:skew-x-6"
  | "2xl:skew-x-12"
  | "1xl:skew-x-12"
  | "xl:skew-x-12"
  | "lg:skew-x-12"
  | "md:skew-x-12"
  | "sm:skew-x-12"
  | "xs:skew-x-12"
  | "hover:skew-x-12"
  | "focus:skew-x-12"
  | "2xl:-skew-x-12"
  | "1xl:-skew-x-12"
  | "xl:-skew-x-12"
  | "lg:-skew-x-12"
  | "md:-skew-x-12"
  | "sm:-skew-x-12"
  | "xs:-skew-x-12"
  | "hover:-skew-x-12"
  | "focus:-skew-x-12"
  | "2xl:-skew-x-6"
  | "1xl:-skew-x-6"
  | "xl:-skew-x-6"
  | "lg:-skew-x-6"
  | "md:-skew-x-6"
  | "sm:-skew-x-6"
  | "xs:-skew-x-6"
  | "hover:-skew-x-6"
  | "focus:-skew-x-6"
  | "2xl:-skew-x-3"
  | "1xl:-skew-x-3"
  | "xl:-skew-x-3"
  | "lg:-skew-x-3"
  | "md:-skew-x-3"
  | "sm:-skew-x-3"
  | "xs:-skew-x-3"
  | "hover:-skew-x-3"
  | "focus:-skew-x-3"
  | "2xl:-skew-x-2"
  | "1xl:-skew-x-2"
  | "xl:-skew-x-2"
  | "lg:-skew-x-2"
  | "md:-skew-x-2"
  | "sm:-skew-x-2"
  | "xs:-skew-x-2"
  | "hover:-skew-x-2"
  | "focus:-skew-x-2"
  | "2xl:-skew-x-1"
  | "1xl:-skew-x-1"
  | "xl:-skew-x-1"
  | "lg:-skew-x-1"
  | "md:-skew-x-1"
  | "sm:-skew-x-1"
  | "xs:-skew-x-1"
  | "hover:-skew-x-1"
  | "focus:-skew-x-1"
  | "2xl:skew-y-0"
  | "1xl:skew-y-0"
  | "xl:skew-y-0"
  | "lg:skew-y-0"
  | "md:skew-y-0"
  | "sm:skew-y-0"
  | "xs:skew-y-0"
  | "hover:skew-y-0"
  | "focus:skew-y-0"
  | "2xl:skew-y-1"
  | "1xl:skew-y-1"
  | "xl:skew-y-1"
  | "lg:skew-y-1"
  | "md:skew-y-1"
  | "sm:skew-y-1"
  | "xs:skew-y-1"
  | "hover:skew-y-1"
  | "focus:skew-y-1"
  | "2xl:skew-y-2"
  | "1xl:skew-y-2"
  | "xl:skew-y-2"
  | "lg:skew-y-2"
  | "md:skew-y-2"
  | "sm:skew-y-2"
  | "xs:skew-y-2"
  | "hover:skew-y-2"
  | "focus:skew-y-2"
  | "2xl:skew-y-3"
  | "1xl:skew-y-3"
  | "xl:skew-y-3"
  | "lg:skew-y-3"
  | "md:skew-y-3"
  | "sm:skew-y-3"
  | "xs:skew-y-3"
  | "hover:skew-y-3"
  | "focus:skew-y-3"
  | "2xl:skew-y-6"
  | "1xl:skew-y-6"
  | "xl:skew-y-6"
  | "lg:skew-y-6"
  | "md:skew-y-6"
  | "sm:skew-y-6"
  | "xs:skew-y-6"
  | "hover:skew-y-6"
  | "focus:skew-y-6"
  | "2xl:skew-y-12"
  | "1xl:skew-y-12"
  | "xl:skew-y-12"
  | "lg:skew-y-12"
  | "md:skew-y-12"
  | "sm:skew-y-12"
  | "xs:skew-y-12"
  | "hover:skew-y-12"
  | "focus:skew-y-12"
  | "2xl:-skew-y-12"
  | "1xl:-skew-y-12"
  | "xl:-skew-y-12"
  | "lg:-skew-y-12"
  | "md:-skew-y-12"
  | "sm:-skew-y-12"
  | "xs:-skew-y-12"
  | "hover:-skew-y-12"
  | "focus:-skew-y-12"
  | "2xl:-skew-y-6"
  | "1xl:-skew-y-6"
  | "xl:-skew-y-6"
  | "lg:-skew-y-6"
  | "md:-skew-y-6"
  | "sm:-skew-y-6"
  | "xs:-skew-y-6"
  | "hover:-skew-y-6"
  | "focus:-skew-y-6"
  | "2xl:-skew-y-3"
  | "1xl:-skew-y-3"
  | "xl:-skew-y-3"
  | "lg:-skew-y-3"
  | "md:-skew-y-3"
  | "sm:-skew-y-3"
  | "xs:-skew-y-3"
  | "hover:-skew-y-3"
  | "focus:-skew-y-3"
  | "2xl:-skew-y-2"
  | "1xl:-skew-y-2"
  | "xl:-skew-y-2"
  | "lg:-skew-y-2"
  | "md:-skew-y-2"
  | "sm:-skew-y-2"
  | "xs:-skew-y-2"
  | "hover:-skew-y-2"
  | "focus:-skew-y-2"
  | "2xl:-skew-y-1"
  | "1xl:-skew-y-1"
  | "xl:-skew-y-1"
  | "lg:-skew-y-1"
  | "md:-skew-y-1"
  | "sm:-skew-y-1"
  | "xs:-skew-y-1"
  | "hover:-skew-y-1"
  | "focus:-skew-y-1"
  | "2xl:space-x-0"
  | "1xl:space-x-0"
  | "xl:space-x-0"
  | "lg:space-x-0"
  | "md:space-x-0"
  | "sm:space-x-0"
  | "xs:space-x-0"
  | "2xl:space-x-1"
  | "1xl:space-x-1"
  | "xl:space-x-1"
  | "lg:space-x-1"
  | "md:space-x-1"
  | "sm:space-x-1"
  | "xs:space-x-1"
  | "2xl:space-x-2"
  | "1xl:space-x-2"
  | "xl:space-x-2"
  | "lg:space-x-2"
  | "md:space-x-2"
  | "sm:space-x-2"
  | "xs:space-x-2"
  | "2xl:space-x-3"
  | "1xl:space-x-3"
  | "xl:space-x-3"
  | "lg:space-x-3"
  | "md:space-x-3"
  | "sm:space-x-3"
  | "xs:space-x-3"
  | "2xl:space-x-4"
  | "1xl:space-x-4"
  | "xl:space-x-4"
  | "lg:space-x-4"
  | "md:space-x-4"
  | "sm:space-x-4"
  | "xs:space-x-4"
  | "2xl:space-x-5"
  | "1xl:space-x-5"
  | "xl:space-x-5"
  | "lg:space-x-5"
  | "md:space-x-5"
  | "sm:space-x-5"
  | "xs:space-x-5"
  | "2xl:space-x-6"
  | "1xl:space-x-6"
  | "xl:space-x-6"
  | "lg:space-x-6"
  | "md:space-x-6"
  | "sm:space-x-6"
  | "xs:space-x-6"
  | "2xl:space-x-7"
  | "1xl:space-x-7"
  | "xl:space-x-7"
  | "lg:space-x-7"
  | "md:space-x-7"
  | "sm:space-x-7"
  | "xs:space-x-7"
  | "2xl:space-x-8"
  | "1xl:space-x-8"
  | "xl:space-x-8"
  | "lg:space-x-8"
  | "md:space-x-8"
  | "sm:space-x-8"
  | "xs:space-x-8"
  | "2xl:space-x-9"
  | "1xl:space-x-9"
  | "xl:space-x-9"
  | "lg:space-x-9"
  | "md:space-x-9"
  | "sm:space-x-9"
  | "xs:space-x-9"
  | "2xl:space-x-10"
  | "1xl:space-x-10"
  | "xl:space-x-10"
  | "lg:space-x-10"
  | "md:space-x-10"
  | "sm:space-x-10"
  | "xs:space-x-10"
  | "2xl:space-x-11"
  | "1xl:space-x-11"
  | "xl:space-x-11"
  | "lg:space-x-11"
  | "md:space-x-11"
  | "sm:space-x-11"
  | "xs:space-x-11"
  | "2xl:space-x-12"
  | "1xl:space-x-12"
  | "xl:space-x-12"
  | "lg:space-x-12"
  | "md:space-x-12"
  | "sm:space-x-12"
  | "xs:space-x-12"
  | "2xl:space-x-14"
  | "1xl:space-x-14"
  | "xl:space-x-14"
  | "lg:space-x-14"
  | "md:space-x-14"
  | "sm:space-x-14"
  | "xs:space-x-14"
  | "2xl:space-x-16"
  | "1xl:space-x-16"
  | "xl:space-x-16"
  | "lg:space-x-16"
  | "md:space-x-16"
  | "sm:space-x-16"
  | "xs:space-x-16"
  | "2xl:space-x-20"
  | "1xl:space-x-20"
  | "xl:space-x-20"
  | "lg:space-x-20"
  | "md:space-x-20"
  | "sm:space-x-20"
  | "xs:space-x-20"
  | "2xl:space-x-24"
  | "1xl:space-x-24"
  | "xl:space-x-24"
  | "lg:space-x-24"
  | "md:space-x-24"
  | "sm:space-x-24"
  | "xs:space-x-24"
  | "2xl:space-x-28"
  | "1xl:space-x-28"
  | "xl:space-x-28"
  | "lg:space-x-28"
  | "md:space-x-28"
  | "sm:space-x-28"
  | "xs:space-x-28"
  | "2xl:space-x-32"
  | "1xl:space-x-32"
  | "xl:space-x-32"
  | "lg:space-x-32"
  | "md:space-x-32"
  | "sm:space-x-32"
  | "xs:space-x-32"
  | "2xl:space-x-36"
  | "1xl:space-x-36"
  | "xl:space-x-36"
  | "lg:space-x-36"
  | "md:space-x-36"
  | "sm:space-x-36"
  | "xs:space-x-36"
  | "2xl:space-x-40"
  | "1xl:space-x-40"
  | "xl:space-x-40"
  | "lg:space-x-40"
  | "md:space-x-40"
  | "sm:space-x-40"
  | "xs:space-x-40"
  | "2xl:space-x-44"
  | "1xl:space-x-44"
  | "xl:space-x-44"
  | "lg:space-x-44"
  | "md:space-x-44"
  | "sm:space-x-44"
  | "xs:space-x-44"
  | "2xl:space-x-48"
  | "1xl:space-x-48"
  | "xl:space-x-48"
  | "lg:space-x-48"
  | "md:space-x-48"
  | "sm:space-x-48"
  | "xs:space-x-48"
  | "2xl:space-x-52"
  | "1xl:space-x-52"
  | "xl:space-x-52"
  | "lg:space-x-52"
  | "md:space-x-52"
  | "sm:space-x-52"
  | "xs:space-x-52"
  | "2xl:space-x-56"
  | "1xl:space-x-56"
  | "xl:space-x-56"
  | "lg:space-x-56"
  | "md:space-x-56"
  | "sm:space-x-56"
  | "xs:space-x-56"
  | "2xl:space-x-60"
  | "1xl:space-x-60"
  | "xl:space-x-60"
  | "lg:space-x-60"
  | "md:space-x-60"
  | "sm:space-x-60"
  | "xs:space-x-60"
  | "2xl:space-x-64"
  | "1xl:space-x-64"
  | "xl:space-x-64"
  | "lg:space-x-64"
  | "md:space-x-64"
  | "sm:space-x-64"
  | "xs:space-x-64"
  | "2xl:space-x-72"
  | "1xl:space-x-72"
  | "xl:space-x-72"
  | "lg:space-x-72"
  | "md:space-x-72"
  | "sm:space-x-72"
  | "xs:space-x-72"
  | "2xl:space-x-80"
  | "1xl:space-x-80"
  | "xl:space-x-80"
  | "lg:space-x-80"
  | "md:space-x-80"
  | "sm:space-x-80"
  | "xs:space-x-80"
  | "2xl:space-x-96"
  | "1xl:space-x-96"
  | "xl:space-x-96"
  | "lg:space-x-96"
  | "md:space-x-96"
  | "sm:space-x-96"
  | "xs:space-x-96"
  | "2xl:space-x-px"
  | "1xl:space-x-px"
  | "xl:space-x-px"
  | "lg:space-x-px"
  | "md:space-x-px"
  | "sm:space-x-px"
  | "xs:space-x-px"
  | "2xl:space-x-0.5"
  | "1xl:space-x-0.5"
  | "xl:space-x-0.5"
  | "lg:space-x-0.5"
  | "md:space-x-0.5"
  | "sm:space-x-0.5"
  | "xs:space-x-0.5"
  | "2xl:space-x-1.5"
  | "1xl:space-x-1.5"
  | "xl:space-x-1.5"
  | "lg:space-x-1.5"
  | "md:space-x-1.5"
  | "sm:space-x-1.5"
  | "xs:space-x-1.5"
  | "2xl:space-x-2.5"
  | "1xl:space-x-2.5"
  | "xl:space-x-2.5"
  | "lg:space-x-2.5"
  | "md:space-x-2.5"
  | "sm:space-x-2.5"
  | "xs:space-x-2.5"
  | "2xl:space-x-3.5"
  | "1xl:space-x-3.5"
  | "xl:space-x-3.5"
  | "lg:space-x-3.5"
  | "md:space-x-3.5"
  | "sm:space-x-3.5"
  | "xs:space-x-3.5"
  | "2xl:-space-x-0"
  | "1xl:-space-x-0"
  | "xl:-space-x-0"
  | "lg:-space-x-0"
  | "md:-space-x-0"
  | "sm:-space-x-0"
  | "xs:-space-x-0"
  | "2xl:-space-x-1"
  | "1xl:-space-x-1"
  | "xl:-space-x-1"
  | "lg:-space-x-1"
  | "md:-space-x-1"
  | "sm:-space-x-1"
  | "xs:-space-x-1"
  | "2xl:-space-x-2"
  | "1xl:-space-x-2"
  | "xl:-space-x-2"
  | "lg:-space-x-2"
  | "md:-space-x-2"
  | "sm:-space-x-2"
  | "xs:-space-x-2"
  | "2xl:-space-x-3"
  | "1xl:-space-x-3"
  | "xl:-space-x-3"
  | "lg:-space-x-3"
  | "md:-space-x-3"
  | "sm:-space-x-3"
  | "xs:-space-x-3"
  | "2xl:-space-x-4"
  | "1xl:-space-x-4"
  | "xl:-space-x-4"
  | "lg:-space-x-4"
  | "md:-space-x-4"
  | "sm:-space-x-4"
  | "xs:-space-x-4"
  | "2xl:-space-x-5"
  | "1xl:-space-x-5"
  | "xl:-space-x-5"
  | "lg:-space-x-5"
  | "md:-space-x-5"
  | "sm:-space-x-5"
  | "xs:-space-x-5"
  | "2xl:-space-x-6"
  | "1xl:-space-x-6"
  | "xl:-space-x-6"
  | "lg:-space-x-6"
  | "md:-space-x-6"
  | "sm:-space-x-6"
  | "xs:-space-x-6"
  | "2xl:-space-x-7"
  | "1xl:-space-x-7"
  | "xl:-space-x-7"
  | "lg:-space-x-7"
  | "md:-space-x-7"
  | "sm:-space-x-7"
  | "xs:-space-x-7"
  | "2xl:-space-x-8"
  | "1xl:-space-x-8"
  | "xl:-space-x-8"
  | "lg:-space-x-8"
  | "md:-space-x-8"
  | "sm:-space-x-8"
  | "xs:-space-x-8"
  | "2xl:-space-x-9"
  | "1xl:-space-x-9"
  | "xl:-space-x-9"
  | "lg:-space-x-9"
  | "md:-space-x-9"
  | "sm:-space-x-9"
  | "xs:-space-x-9"
  | "2xl:-space-x-10"
  | "1xl:-space-x-10"
  | "xl:-space-x-10"
  | "lg:-space-x-10"
  | "md:-space-x-10"
  | "sm:-space-x-10"
  | "xs:-space-x-10"
  | "2xl:-space-x-11"
  | "1xl:-space-x-11"
  | "xl:-space-x-11"
  | "lg:-space-x-11"
  | "md:-space-x-11"
  | "sm:-space-x-11"
  | "xs:-space-x-11"
  | "2xl:-space-x-12"
  | "1xl:-space-x-12"
  | "xl:-space-x-12"
  | "lg:-space-x-12"
  | "md:-space-x-12"
  | "sm:-space-x-12"
  | "xs:-space-x-12"
  | "2xl:-space-x-14"
  | "1xl:-space-x-14"
  | "xl:-space-x-14"
  | "lg:-space-x-14"
  | "md:-space-x-14"
  | "sm:-space-x-14"
  | "xs:-space-x-14"
  | "2xl:-space-x-16"
  | "1xl:-space-x-16"
  | "xl:-space-x-16"
  | "lg:-space-x-16"
  | "md:-space-x-16"
  | "sm:-space-x-16"
  | "xs:-space-x-16"
  | "2xl:-space-x-20"
  | "1xl:-space-x-20"
  | "xl:-space-x-20"
  | "lg:-space-x-20"
  | "md:-space-x-20"
  | "sm:-space-x-20"
  | "xs:-space-x-20"
  | "2xl:-space-x-24"
  | "1xl:-space-x-24"
  | "xl:-space-x-24"
  | "lg:-space-x-24"
  | "md:-space-x-24"
  | "sm:-space-x-24"
  | "xs:-space-x-24"
  | "2xl:-space-x-28"
  | "1xl:-space-x-28"
  | "xl:-space-x-28"
  | "lg:-space-x-28"
  | "md:-space-x-28"
  | "sm:-space-x-28"
  | "xs:-space-x-28"
  | "2xl:-space-x-32"
  | "1xl:-space-x-32"
  | "xl:-space-x-32"
  | "lg:-space-x-32"
  | "md:-space-x-32"
  | "sm:-space-x-32"
  | "xs:-space-x-32"
  | "2xl:-space-x-36"
  | "1xl:-space-x-36"
  | "xl:-space-x-36"
  | "lg:-space-x-36"
  | "md:-space-x-36"
  | "sm:-space-x-36"
  | "xs:-space-x-36"
  | "2xl:-space-x-40"
  | "1xl:-space-x-40"
  | "xl:-space-x-40"
  | "lg:-space-x-40"
  | "md:-space-x-40"
  | "sm:-space-x-40"
  | "xs:-space-x-40"
  | "2xl:-space-x-44"
  | "1xl:-space-x-44"
  | "xl:-space-x-44"
  | "lg:-space-x-44"
  | "md:-space-x-44"
  | "sm:-space-x-44"
  | "xs:-space-x-44"
  | "2xl:-space-x-48"
  | "1xl:-space-x-48"
  | "xl:-space-x-48"
  | "lg:-space-x-48"
  | "md:-space-x-48"
  | "sm:-space-x-48"
  | "xs:-space-x-48"
  | "2xl:-space-x-52"
  | "1xl:-space-x-52"
  | "xl:-space-x-52"
  | "lg:-space-x-52"
  | "md:-space-x-52"
  | "sm:-space-x-52"
  | "xs:-space-x-52"
  | "2xl:-space-x-56"
  | "1xl:-space-x-56"
  | "xl:-space-x-56"
  | "lg:-space-x-56"
  | "md:-space-x-56"
  | "sm:-space-x-56"
  | "xs:-space-x-56"
  | "2xl:-space-x-60"
  | "1xl:-space-x-60"
  | "xl:-space-x-60"
  | "lg:-space-x-60"
  | "md:-space-x-60"
  | "sm:-space-x-60"
  | "xs:-space-x-60"
  | "2xl:-space-x-64"
  | "1xl:-space-x-64"
  | "xl:-space-x-64"
  | "lg:-space-x-64"
  | "md:-space-x-64"
  | "sm:-space-x-64"
  | "xs:-space-x-64"
  | "2xl:-space-x-72"
  | "1xl:-space-x-72"
  | "xl:-space-x-72"
  | "lg:-space-x-72"
  | "md:-space-x-72"
  | "sm:-space-x-72"
  | "xs:-space-x-72"
  | "2xl:-space-x-80"
  | "1xl:-space-x-80"
  | "xl:-space-x-80"
  | "lg:-space-x-80"
  | "md:-space-x-80"
  | "sm:-space-x-80"
  | "xs:-space-x-80"
  | "2xl:-space-x-96"
  | "1xl:-space-x-96"
  | "xl:-space-x-96"
  | "lg:-space-x-96"
  | "md:-space-x-96"
  | "sm:-space-x-96"
  | "xs:-space-x-96"
  | "2xl:-space-x-px"
  | "1xl:-space-x-px"
  | "xl:-space-x-px"
  | "lg:-space-x-px"
  | "md:-space-x-px"
  | "sm:-space-x-px"
  | "xs:-space-x-px"
  | "2xl:-space-x-0.5"
  | "1xl:-space-x-0.5"
  | "xl:-space-x-0.5"
  | "lg:-space-x-0.5"
  | "md:-space-x-0.5"
  | "sm:-space-x-0.5"
  | "xs:-space-x-0.5"
  | "2xl:-space-x-1.5"
  | "1xl:-space-x-1.5"
  | "xl:-space-x-1.5"
  | "lg:-space-x-1.5"
  | "md:-space-x-1.5"
  | "sm:-space-x-1.5"
  | "xs:-space-x-1.5"
  | "2xl:-space-x-2.5"
  | "1xl:-space-x-2.5"
  | "xl:-space-x-2.5"
  | "lg:-space-x-2.5"
  | "md:-space-x-2.5"
  | "sm:-space-x-2.5"
  | "xs:-space-x-2.5"
  | "2xl:-space-x-3.5"
  | "1xl:-space-x-3.5"
  | "xl:-space-x-3.5"
  | "lg:-space-x-3.5"
  | "md:-space-x-3.5"
  | "sm:-space-x-3.5"
  | "xs:-space-x-3.5"
  | "2xl:space-x-reverse"
  | "1xl:space-x-reverse"
  | "xl:space-x-reverse"
  | "lg:space-x-reverse"
  | "md:space-x-reverse"
  | "sm:space-x-reverse"
  | "xs:space-x-reverse"
  | "2xl:space-y-0"
  | "1xl:space-y-0"
  | "xl:space-y-0"
  | "lg:space-y-0"
  | "md:space-y-0"
  | "sm:space-y-0"
  | "xs:space-y-0"
  | "2xl:space-y-1"
  | "1xl:space-y-1"
  | "xl:space-y-1"
  | "lg:space-y-1"
  | "md:space-y-1"
  | "sm:space-y-1"
  | "xs:space-y-1"
  | "2xl:space-y-2"
  | "1xl:space-y-2"
  | "xl:space-y-2"
  | "lg:space-y-2"
  | "md:space-y-2"
  | "sm:space-y-2"
  | "xs:space-y-2"
  | "2xl:space-y-3"
  | "1xl:space-y-3"
  | "xl:space-y-3"
  | "lg:space-y-3"
  | "md:space-y-3"
  | "sm:space-y-3"
  | "xs:space-y-3"
  | "2xl:space-y-4"
  | "1xl:space-y-4"
  | "xl:space-y-4"
  | "lg:space-y-4"
  | "md:space-y-4"
  | "sm:space-y-4"
  | "xs:space-y-4"
  | "2xl:space-y-5"
  | "1xl:space-y-5"
  | "xl:space-y-5"
  | "lg:space-y-5"
  | "md:space-y-5"
  | "sm:space-y-5"
  | "xs:space-y-5"
  | "2xl:space-y-6"
  | "1xl:space-y-6"
  | "xl:space-y-6"
  | "lg:space-y-6"
  | "md:space-y-6"
  | "sm:space-y-6"
  | "xs:space-y-6"
  | "2xl:space-y-7"
  | "1xl:space-y-7"
  | "xl:space-y-7"
  | "lg:space-y-7"
  | "md:space-y-7"
  | "sm:space-y-7"
  | "xs:space-y-7"
  | "2xl:space-y-8"
  | "1xl:space-y-8"
  | "xl:space-y-8"
  | "lg:space-y-8"
  | "md:space-y-8"
  | "sm:space-y-8"
  | "xs:space-y-8"
  | "2xl:space-y-9"
  | "1xl:space-y-9"
  | "xl:space-y-9"
  | "lg:space-y-9"
  | "md:space-y-9"
  | "sm:space-y-9"
  | "xs:space-y-9"
  | "2xl:space-y-10"
  | "1xl:space-y-10"
  | "xl:space-y-10"
  | "lg:space-y-10"
  | "md:space-y-10"
  | "sm:space-y-10"
  | "xs:space-y-10"
  | "2xl:space-y-11"
  | "1xl:space-y-11"
  | "xl:space-y-11"
  | "lg:space-y-11"
  | "md:space-y-11"
  | "sm:space-y-11"
  | "xs:space-y-11"
  | "2xl:space-y-12"
  | "1xl:space-y-12"
  | "xl:space-y-12"
  | "lg:space-y-12"
  | "md:space-y-12"
  | "sm:space-y-12"
  | "xs:space-y-12"
  | "2xl:space-y-14"
  | "1xl:space-y-14"
  | "xl:space-y-14"
  | "lg:space-y-14"
  | "md:space-y-14"
  | "sm:space-y-14"
  | "xs:space-y-14"
  | "2xl:space-y-16"
  | "1xl:space-y-16"
  | "xl:space-y-16"
  | "lg:space-y-16"
  | "md:space-y-16"
  | "sm:space-y-16"
  | "xs:space-y-16"
  | "2xl:space-y-20"
  | "1xl:space-y-20"
  | "xl:space-y-20"
  | "lg:space-y-20"
  | "md:space-y-20"
  | "sm:space-y-20"
  | "xs:space-y-20"
  | "2xl:space-y-24"
  | "1xl:space-y-24"
  | "xl:space-y-24"
  | "lg:space-y-24"
  | "md:space-y-24"
  | "sm:space-y-24"
  | "xs:space-y-24"
  | "2xl:space-y-28"
  | "1xl:space-y-28"
  | "xl:space-y-28"
  | "lg:space-y-28"
  | "md:space-y-28"
  | "sm:space-y-28"
  | "xs:space-y-28"
  | "2xl:space-y-32"
  | "1xl:space-y-32"
  | "xl:space-y-32"
  | "lg:space-y-32"
  | "md:space-y-32"
  | "sm:space-y-32"
  | "xs:space-y-32"
  | "2xl:space-y-36"
  | "1xl:space-y-36"
  | "xl:space-y-36"
  | "lg:space-y-36"
  | "md:space-y-36"
  | "sm:space-y-36"
  | "xs:space-y-36"
  | "2xl:space-y-40"
  | "1xl:space-y-40"
  | "xl:space-y-40"
  | "lg:space-y-40"
  | "md:space-y-40"
  | "sm:space-y-40"
  | "xs:space-y-40"
  | "2xl:space-y-44"
  | "1xl:space-y-44"
  | "xl:space-y-44"
  | "lg:space-y-44"
  | "md:space-y-44"
  | "sm:space-y-44"
  | "xs:space-y-44"
  | "2xl:space-y-48"
  | "1xl:space-y-48"
  | "xl:space-y-48"
  | "lg:space-y-48"
  | "md:space-y-48"
  | "sm:space-y-48"
  | "xs:space-y-48"
  | "2xl:space-y-52"
  | "1xl:space-y-52"
  | "xl:space-y-52"
  | "lg:space-y-52"
  | "md:space-y-52"
  | "sm:space-y-52"
  | "xs:space-y-52"
  | "2xl:space-y-56"
  | "1xl:space-y-56"
  | "xl:space-y-56"
  | "lg:space-y-56"
  | "md:space-y-56"
  | "sm:space-y-56"
  | "xs:space-y-56"
  | "2xl:space-y-60"
  | "1xl:space-y-60"
  | "xl:space-y-60"
  | "lg:space-y-60"
  | "md:space-y-60"
  | "sm:space-y-60"
  | "xs:space-y-60"
  | "2xl:space-y-64"
  | "1xl:space-y-64"
  | "xl:space-y-64"
  | "lg:space-y-64"
  | "md:space-y-64"
  | "sm:space-y-64"
  | "xs:space-y-64"
  | "2xl:space-y-72"
  | "1xl:space-y-72"
  | "xl:space-y-72"
  | "lg:space-y-72"
  | "md:space-y-72"
  | "sm:space-y-72"
  | "xs:space-y-72"
  | "2xl:space-y-80"
  | "1xl:space-y-80"
  | "xl:space-y-80"
  | "lg:space-y-80"
  | "md:space-y-80"
  | "sm:space-y-80"
  | "xs:space-y-80"
  | "2xl:space-y-96"
  | "1xl:space-y-96"
  | "xl:space-y-96"
  | "lg:space-y-96"
  | "md:space-y-96"
  | "sm:space-y-96"
  | "xs:space-y-96"
  | "2xl:space-y-px"
  | "1xl:space-y-px"
  | "xl:space-y-px"
  | "lg:space-y-px"
  | "md:space-y-px"
  | "sm:space-y-px"
  | "xs:space-y-px"
  | "2xl:space-y-0.5"
  | "1xl:space-y-0.5"
  | "xl:space-y-0.5"
  | "lg:space-y-0.5"
  | "md:space-y-0.5"
  | "sm:space-y-0.5"
  | "xs:space-y-0.5"
  | "2xl:space-y-1.5"
  | "1xl:space-y-1.5"
  | "xl:space-y-1.5"
  | "lg:space-y-1.5"
  | "md:space-y-1.5"
  | "sm:space-y-1.5"
  | "xs:space-y-1.5"
  | "2xl:space-y-2.5"
  | "1xl:space-y-2.5"
  | "xl:space-y-2.5"
  | "lg:space-y-2.5"
  | "md:space-y-2.5"
  | "sm:space-y-2.5"
  | "xs:space-y-2.5"
  | "2xl:space-y-3.5"
  | "1xl:space-y-3.5"
  | "xl:space-y-3.5"
  | "lg:space-y-3.5"
  | "md:space-y-3.5"
  | "sm:space-y-3.5"
  | "xs:space-y-3.5"
  | "2xl:-space-y-0"
  | "1xl:-space-y-0"
  | "xl:-space-y-0"
  | "lg:-space-y-0"
  | "md:-space-y-0"
  | "sm:-space-y-0"
  | "xs:-space-y-0"
  | "2xl:-space-y-1"
  | "1xl:-space-y-1"
  | "xl:-space-y-1"
  | "lg:-space-y-1"
  | "md:-space-y-1"
  | "sm:-space-y-1"
  | "xs:-space-y-1"
  | "2xl:-space-y-2"
  | "1xl:-space-y-2"
  | "xl:-space-y-2"
  | "lg:-space-y-2"
  | "md:-space-y-2"
  | "sm:-space-y-2"
  | "xs:-space-y-2"
  | "2xl:-space-y-3"
  | "1xl:-space-y-3"
  | "xl:-space-y-3"
  | "lg:-space-y-3"
  | "md:-space-y-3"
  | "sm:-space-y-3"
  | "xs:-space-y-3"
  | "2xl:-space-y-4"
  | "1xl:-space-y-4"
  | "xl:-space-y-4"
  | "lg:-space-y-4"
  | "md:-space-y-4"
  | "sm:-space-y-4"
  | "xs:-space-y-4"
  | "2xl:-space-y-5"
  | "1xl:-space-y-5"
  | "xl:-space-y-5"
  | "lg:-space-y-5"
  | "md:-space-y-5"
  | "sm:-space-y-5"
  | "xs:-space-y-5"
  | "2xl:-space-y-6"
  | "1xl:-space-y-6"
  | "xl:-space-y-6"
  | "lg:-space-y-6"
  | "md:-space-y-6"
  | "sm:-space-y-6"
  | "xs:-space-y-6"
  | "2xl:-space-y-7"
  | "1xl:-space-y-7"
  | "xl:-space-y-7"
  | "lg:-space-y-7"
  | "md:-space-y-7"
  | "sm:-space-y-7"
  | "xs:-space-y-7"
  | "2xl:-space-y-8"
  | "1xl:-space-y-8"
  | "xl:-space-y-8"
  | "lg:-space-y-8"
  | "md:-space-y-8"
  | "sm:-space-y-8"
  | "xs:-space-y-8"
  | "2xl:-space-y-9"
  | "1xl:-space-y-9"
  | "xl:-space-y-9"
  | "lg:-space-y-9"
  | "md:-space-y-9"
  | "sm:-space-y-9"
  | "xs:-space-y-9"
  | "2xl:-space-y-10"
  | "1xl:-space-y-10"
  | "xl:-space-y-10"
  | "lg:-space-y-10"
  | "md:-space-y-10"
  | "sm:-space-y-10"
  | "xs:-space-y-10"
  | "2xl:-space-y-11"
  | "1xl:-space-y-11"
  | "xl:-space-y-11"
  | "lg:-space-y-11"
  | "md:-space-y-11"
  | "sm:-space-y-11"
  | "xs:-space-y-11"
  | "2xl:-space-y-12"
  | "1xl:-space-y-12"
  | "xl:-space-y-12"
  | "lg:-space-y-12"
  | "md:-space-y-12"
  | "sm:-space-y-12"
  | "xs:-space-y-12"
  | "2xl:-space-y-14"
  | "1xl:-space-y-14"
  | "xl:-space-y-14"
  | "lg:-space-y-14"
  | "md:-space-y-14"
  | "sm:-space-y-14"
  | "xs:-space-y-14"
  | "2xl:-space-y-16"
  | "1xl:-space-y-16"
  | "xl:-space-y-16"
  | "lg:-space-y-16"
  | "md:-space-y-16"
  | "sm:-space-y-16"
  | "xs:-space-y-16"
  | "2xl:-space-y-20"
  | "1xl:-space-y-20"
  | "xl:-space-y-20"
  | "lg:-space-y-20"
  | "md:-space-y-20"
  | "sm:-space-y-20"
  | "xs:-space-y-20"
  | "2xl:-space-y-24"
  | "1xl:-space-y-24"
  | "xl:-space-y-24"
  | "lg:-space-y-24"
  | "md:-space-y-24"
  | "sm:-space-y-24"
  | "xs:-space-y-24"
  | "2xl:-space-y-28"
  | "1xl:-space-y-28"
  | "xl:-space-y-28"
  | "lg:-space-y-28"
  | "md:-space-y-28"
  | "sm:-space-y-28"
  | "xs:-space-y-28"
  | "2xl:-space-y-32"
  | "1xl:-space-y-32"
  | "xl:-space-y-32"
  | "lg:-space-y-32"
  | "md:-space-y-32"
  | "sm:-space-y-32"
  | "xs:-space-y-32"
  | "2xl:-space-y-36"
  | "1xl:-space-y-36"
  | "xl:-space-y-36"
  | "lg:-space-y-36"
  | "md:-space-y-36"
  | "sm:-space-y-36"
  | "xs:-space-y-36"
  | "2xl:-space-y-40"
  | "1xl:-space-y-40"
  | "xl:-space-y-40"
  | "lg:-space-y-40"
  | "md:-space-y-40"
  | "sm:-space-y-40"
  | "xs:-space-y-40"
  | "2xl:-space-y-44"
  | "1xl:-space-y-44"
  | "xl:-space-y-44"
  | "lg:-space-y-44"
  | "md:-space-y-44"
  | "sm:-space-y-44"
  | "xs:-space-y-44"
  | "2xl:-space-y-48"
  | "1xl:-space-y-48"
  | "xl:-space-y-48"
  | "lg:-space-y-48"
  | "md:-space-y-48"
  | "sm:-space-y-48"
  | "xs:-space-y-48"
  | "2xl:-space-y-52"
  | "1xl:-space-y-52"
  | "xl:-space-y-52"
  | "lg:-space-y-52"
  | "md:-space-y-52"
  | "sm:-space-y-52"
  | "xs:-space-y-52"
  | "2xl:-space-y-56"
  | "1xl:-space-y-56"
  | "xl:-space-y-56"
  | "lg:-space-y-56"
  | "md:-space-y-56"
  | "sm:-space-y-56"
  | "xs:-space-y-56"
  | "2xl:-space-y-60"
  | "1xl:-space-y-60"
  | "xl:-space-y-60"
  | "lg:-space-y-60"
  | "md:-space-y-60"
  | "sm:-space-y-60"
  | "xs:-space-y-60"
  | "2xl:-space-y-64"
  | "1xl:-space-y-64"
  | "xl:-space-y-64"
  | "lg:-space-y-64"
  | "md:-space-y-64"
  | "sm:-space-y-64"
  | "xs:-space-y-64"
  | "2xl:-space-y-72"
  | "1xl:-space-y-72"
  | "xl:-space-y-72"
  | "lg:-space-y-72"
  | "md:-space-y-72"
  | "sm:-space-y-72"
  | "xs:-space-y-72"
  | "2xl:-space-y-80"
  | "1xl:-space-y-80"
  | "xl:-space-y-80"
  | "lg:-space-y-80"
  | "md:-space-y-80"
  | "sm:-space-y-80"
  | "xs:-space-y-80"
  | "2xl:-space-y-96"
  | "1xl:-space-y-96"
  | "xl:-space-y-96"
  | "lg:-space-y-96"
  | "md:-space-y-96"
  | "sm:-space-y-96"
  | "xs:-space-y-96"
  | "2xl:-space-y-px"
  | "1xl:-space-y-px"
  | "xl:-space-y-px"
  | "lg:-space-y-px"
  | "md:-space-y-px"
  | "sm:-space-y-px"
  | "xs:-space-y-px"
  | "2xl:-space-y-0.5"
  | "1xl:-space-y-0.5"
  | "xl:-space-y-0.5"
  | "lg:-space-y-0.5"
  | "md:-space-y-0.5"
  | "sm:-space-y-0.5"
  | "xs:-space-y-0.5"
  | "2xl:-space-y-1.5"
  | "1xl:-space-y-1.5"
  | "xl:-space-y-1.5"
  | "lg:-space-y-1.5"
  | "md:-space-y-1.5"
  | "sm:-space-y-1.5"
  | "xs:-space-y-1.5"
  | "2xl:-space-y-2.5"
  | "1xl:-space-y-2.5"
  | "xl:-space-y-2.5"
  | "lg:-space-y-2.5"
  | "md:-space-y-2.5"
  | "sm:-space-y-2.5"
  | "xs:-space-y-2.5"
  | "2xl:-space-y-3.5"
  | "1xl:-space-y-3.5"
  | "xl:-space-y-3.5"
  | "lg:-space-y-3.5"
  | "md:-space-y-3.5"
  | "sm:-space-y-3.5"
  | "xs:-space-y-3.5"
  | "2xl:space-y-reverse"
  | "1xl:space-y-reverse"
  | "xl:space-y-reverse"
  | "lg:space-y-reverse"
  | "md:space-y-reverse"
  | "sm:space-y-reverse"
  | "xs:space-y-reverse"
  | "2xl:stroke-current"
  | "1xl:stroke-current"
  | "xl:stroke-current"
  | "lg:stroke-current"
  | "md:stroke-current"
  | "sm:stroke-current"
  | "xs:stroke-current"
  | "2xl:stroke-0"
  | "1xl:stroke-0"
  | "xl:stroke-0"
  | "lg:stroke-0"
  | "md:stroke-0"
  | "sm:stroke-0"
  | "xs:stroke-0"
  | "2xl:stroke-1"
  | "1xl:stroke-1"
  | "xl:stroke-1"
  | "lg:stroke-1"
  | "md:stroke-1"
  | "sm:stroke-1"
  | "xs:stroke-1"
  | "2xl:stroke-2"
  | "1xl:stroke-2"
  | "xl:stroke-2"
  | "lg:stroke-2"
  | "md:stroke-2"
  | "sm:stroke-2"
  | "xs:stroke-2"
  | "2xl:table-auto"
  | "1xl:table-auto"
  | "xl:table-auto"
  | "lg:table-auto"
  | "md:table-auto"
  | "sm:table-auto"
  | "xs:table-auto"
  | "2xl:table-fixed"
  | "1xl:table-fixed"
  | "xl:table-fixed"
  | "lg:table-fixed"
  | "md:table-fixed"
  | "sm:table-fixed"
  | "xs:table-fixed"
  | "2xl:text-left"
  | "1xl:text-left"
  | "xl:text-left"
  | "lg:text-left"
  | "md:text-left"
  | "sm:text-left"
  | "xs:text-left"
  | "2xl:text-center"
  | "1xl:text-center"
  | "xl:text-center"
  | "lg:text-center"
  | "md:text-center"
  | "sm:text-center"
  | "xs:text-center"
  | "2xl:text-right"
  | "1xl:text-right"
  | "xl:text-right"
  | "lg:text-right"
  | "md:text-right"
  | "sm:text-right"
  | "xs:text-right"
  | "2xl:text-justify"
  | "1xl:text-justify"
  | "xl:text-justify"
  | "lg:text-justify"
  | "md:text-justify"
  | "sm:text-justify"
  | "xs:text-justify"
  | "2xl:text-transparent"
  | "1xl:text-transparent"
  | "xl:text-transparent"
  | "lg:text-transparent"
  | "md:text-transparent"
  | "sm:text-transparent"
  | "xs:text-transparent"
  | "group-hover:text-transparent"
  | "focus-within:text-transparent"
  | "hover:text-transparent"
  | "focus:text-transparent"
  | "hover:text-transparent"
  | "hover:text-transparent"
  | "2xl:text-current"
  | "1xl:text-current"
  | "xl:text-current"
  | "lg:text-current"
  | "md:text-current"
  | "sm:text-current"
  | "xs:text-current"
  | "group-hover:text-current"
  | "focus-within:text-current"
  | "hover:text-current"
  | "focus:text-current"
  | "hover:text-current"
  | "hover:text-current"
  | "2xl:text-black"
  | "1xl:text-black"
  | "xl:text-black"
  | "lg:text-black"
  | "md:text-black"
  | "sm:text-black"
  | "xs:text-black"
  | "group-hover:text-black"
  | "focus-within:text-black"
  | "hover:text-black"
  | "focus:text-black"
  | "hover:text-black"
  | "hover:text-black"
  | "2xl:text-white"
  | "1xl:text-white"
  | "xl:text-white"
  | "lg:text-white"
  | "md:text-white"
  | "sm:text-white"
  | "xs:text-white"
  | "group-hover:text-white"
  | "focus-within:text-white"
  | "hover:text-white"
  | "focus:text-white"
  | "hover:text-white"
  | "hover:text-white"
  | "2xl:text-gray-50"
  | "1xl:text-gray-50"
  | "xl:text-gray-50"
  | "lg:text-gray-50"
  | "md:text-gray-50"
  | "sm:text-gray-50"
  | "xs:text-gray-50"
  | "group-hover:text-gray-50"
  | "focus-within:text-gray-50"
  | "hover:text-gray-50"
  | "focus:text-gray-50"
  | "hover:text-gray-50"
  | "hover:text-gray-50"
  | "2xl:text-gray-100"
  | "1xl:text-gray-100"
  | "xl:text-gray-100"
  | "lg:text-gray-100"
  | "md:text-gray-100"
  | "sm:text-gray-100"
  | "xs:text-gray-100"
  | "group-hover:text-gray-100"
  | "focus-within:text-gray-100"
  | "hover:text-gray-100"
  | "focus:text-gray-100"
  | "hover:text-gray-100"
  | "hover:text-gray-100"
  | "2xl:text-gray-200"
  | "1xl:text-gray-200"
  | "xl:text-gray-200"
  | "lg:text-gray-200"
  | "md:text-gray-200"
  | "sm:text-gray-200"
  | "xs:text-gray-200"
  | "group-hover:text-gray-200"
  | "focus-within:text-gray-200"
  | "hover:text-gray-200"
  | "focus:text-gray-200"
  | "hover:text-gray-200"
  | "hover:text-gray-200"
  | "2xl:text-gray-300"
  | "1xl:text-gray-300"
  | "xl:text-gray-300"
  | "lg:text-gray-300"
  | "md:text-gray-300"
  | "sm:text-gray-300"
  | "xs:text-gray-300"
  | "group-hover:text-gray-300"
  | "focus-within:text-gray-300"
  | "hover:text-gray-300"
  | "focus:text-gray-300"
  | "hover:text-gray-300"
  | "hover:text-gray-300"
  | "2xl:text-gray-400"
  | "1xl:text-gray-400"
  | "xl:text-gray-400"
  | "lg:text-gray-400"
  | "md:text-gray-400"
  | "sm:text-gray-400"
  | "xs:text-gray-400"
  | "group-hover:text-gray-400"
  | "focus-within:text-gray-400"
  | "hover:text-gray-400"
  | "focus:text-gray-400"
  | "hover:text-gray-400"
  | "hover:text-gray-400"
  | "2xl:text-gray-500"
  | "1xl:text-gray-500"
  | "xl:text-gray-500"
  | "lg:text-gray-500"
  | "md:text-gray-500"
  | "sm:text-gray-500"
  | "xs:text-gray-500"
  | "group-hover:text-gray-500"
  | "focus-within:text-gray-500"
  | "hover:text-gray-500"
  | "focus:text-gray-500"
  | "hover:text-gray-500"
  | "hover:text-gray-500"
  | "2xl:text-gray-600"
  | "1xl:text-gray-600"
  | "xl:text-gray-600"
  | "lg:text-gray-600"
  | "md:text-gray-600"
  | "sm:text-gray-600"
  | "xs:text-gray-600"
  | "group-hover:text-gray-600"
  | "focus-within:text-gray-600"
  | "hover:text-gray-600"
  | "focus:text-gray-600"
  | "hover:text-gray-600"
  | "hover:text-gray-600"
  | "2xl:text-gray-700"
  | "1xl:text-gray-700"
  | "xl:text-gray-700"
  | "lg:text-gray-700"
  | "md:text-gray-700"
  | "sm:text-gray-700"
  | "xs:text-gray-700"
  | "group-hover:text-gray-700"
  | "focus-within:text-gray-700"
  | "hover:text-gray-700"
  | "focus:text-gray-700"
  | "hover:text-gray-700"
  | "hover:text-gray-700"
  | "2xl:text-gray-800"
  | "1xl:text-gray-800"
  | "xl:text-gray-800"
  | "lg:text-gray-800"
  | "md:text-gray-800"
  | "sm:text-gray-800"
  | "xs:text-gray-800"
  | "group-hover:text-gray-800"
  | "focus-within:text-gray-800"
  | "hover:text-gray-800"
  | "focus:text-gray-800"
  | "hover:text-gray-800"
  | "hover:text-gray-800"
  | "2xl:text-gray-900"
  | "1xl:text-gray-900"
  | "xl:text-gray-900"
  | "lg:text-gray-900"
  | "md:text-gray-900"
  | "sm:text-gray-900"
  | "xs:text-gray-900"
  | "group-hover:text-gray-900"
  | "focus-within:text-gray-900"
  | "hover:text-gray-900"
  | "focus:text-gray-900"
  | "hover:text-gray-900"
  | "hover:text-gray-900"
  | "2xl:text-red-50"
  | "1xl:text-red-50"
  | "xl:text-red-50"
  | "lg:text-red-50"
  | "md:text-red-50"
  | "sm:text-red-50"
  | "xs:text-red-50"
  | "group-hover:text-red-50"
  | "focus-within:text-red-50"
  | "hover:text-red-50"
  | "focus:text-red-50"
  | "hover:text-red-50"
  | "hover:text-red-50"
  | "2xl:text-red-100"
  | "1xl:text-red-100"
  | "xl:text-red-100"
  | "lg:text-red-100"
  | "md:text-red-100"
  | "sm:text-red-100"
  | "xs:text-red-100"
  | "group-hover:text-red-100"
  | "focus-within:text-red-100"
  | "hover:text-red-100"
  | "focus:text-red-100"
  | "hover:text-red-100"
  | "hover:text-red-100"
  | "2xl:text-red-200"
  | "1xl:text-red-200"
  | "xl:text-red-200"
  | "lg:text-red-200"
  | "md:text-red-200"
  | "sm:text-red-200"
  | "xs:text-red-200"
  | "group-hover:text-red-200"
  | "focus-within:text-red-200"
  | "hover:text-red-200"
  | "focus:text-red-200"
  | "hover:text-red-200"
  | "hover:text-red-200"
  | "2xl:text-red-300"
  | "1xl:text-red-300"
  | "xl:text-red-300"
  | "lg:text-red-300"
  | "md:text-red-300"
  | "sm:text-red-300"
  | "xs:text-red-300"
  | "group-hover:text-red-300"
  | "focus-within:text-red-300"
  | "hover:text-red-300"
  | "focus:text-red-300"
  | "hover:text-red-300"
  | "hover:text-red-300"
  | "2xl:text-red-400"
  | "1xl:text-red-400"
  | "xl:text-red-400"
  | "lg:text-red-400"
  | "md:text-red-400"
  | "sm:text-red-400"
  | "xs:text-red-400"
  | "group-hover:text-red-400"
  | "focus-within:text-red-400"
  | "hover:text-red-400"
  | "focus:text-red-400"
  | "hover:text-red-400"
  | "hover:text-red-400"
  | "2xl:text-red-500"
  | "1xl:text-red-500"
  | "xl:text-red-500"
  | "lg:text-red-500"
  | "md:text-red-500"
  | "sm:text-red-500"
  | "xs:text-red-500"
  | "group-hover:text-red-500"
  | "focus-within:text-red-500"
  | "hover:text-red-500"
  | "focus:text-red-500"
  | "hover:text-red-500"
  | "hover:text-red-500"
  | "2xl:text-red-600"
  | "1xl:text-red-600"
  | "xl:text-red-600"
  | "lg:text-red-600"
  | "md:text-red-600"
  | "sm:text-red-600"
  | "xs:text-red-600"
  | "group-hover:text-red-600"
  | "focus-within:text-red-600"
  | "hover:text-red-600"
  | "focus:text-red-600"
  | "hover:text-red-600"
  | "hover:text-red-600"
  | "2xl:text-red-700"
  | "1xl:text-red-700"
  | "xl:text-red-700"
  | "lg:text-red-700"
  | "md:text-red-700"
  | "sm:text-red-700"
  | "xs:text-red-700"
  | "group-hover:text-red-700"
  | "focus-within:text-red-700"
  | "hover:text-red-700"
  | "focus:text-red-700"
  | "hover:text-red-700"
  | "hover:text-red-700"
  | "2xl:text-red-800"
  | "1xl:text-red-800"
  | "xl:text-red-800"
  | "lg:text-red-800"
  | "md:text-red-800"
  | "sm:text-red-800"
  | "xs:text-red-800"
  | "group-hover:text-red-800"
  | "focus-within:text-red-800"
  | "hover:text-red-800"
  | "focus:text-red-800"
  | "hover:text-red-800"
  | "hover:text-red-800"
  | "2xl:text-red-900"
  | "1xl:text-red-900"
  | "xl:text-red-900"
  | "lg:text-red-900"
  | "md:text-red-900"
  | "sm:text-red-900"
  | "xs:text-red-900"
  | "group-hover:text-red-900"
  | "focus-within:text-red-900"
  | "hover:text-red-900"
  | "focus:text-red-900"
  | "hover:text-red-900"
  | "hover:text-red-900"
  | "2xl:text-yellow-50"
  | "1xl:text-yellow-50"
  | "xl:text-yellow-50"
  | "lg:text-yellow-50"
  | "md:text-yellow-50"
  | "sm:text-yellow-50"
  | "xs:text-yellow-50"
  | "group-hover:text-yellow-50"
  | "focus-within:text-yellow-50"
  | "hover:text-yellow-50"
  | "focus:text-yellow-50"
  | "hover:text-yellow-50"
  | "hover:text-yellow-50"
  | "2xl:text-yellow-100"
  | "1xl:text-yellow-100"
  | "xl:text-yellow-100"
  | "lg:text-yellow-100"
  | "md:text-yellow-100"
  | "sm:text-yellow-100"
  | "xs:text-yellow-100"
  | "group-hover:text-yellow-100"
  | "focus-within:text-yellow-100"
  | "hover:text-yellow-100"
  | "focus:text-yellow-100"
  | "hover:text-yellow-100"
  | "hover:text-yellow-100"
  | "2xl:text-yellow-200"
  | "1xl:text-yellow-200"
  | "xl:text-yellow-200"
  | "lg:text-yellow-200"
  | "md:text-yellow-200"
  | "sm:text-yellow-200"
  | "xs:text-yellow-200"
  | "group-hover:text-yellow-200"
  | "focus-within:text-yellow-200"
  | "hover:text-yellow-200"
  | "focus:text-yellow-200"
  | "hover:text-yellow-200"
  | "hover:text-yellow-200"
  | "2xl:text-yellow-300"
  | "1xl:text-yellow-300"
  | "xl:text-yellow-300"
  | "lg:text-yellow-300"
  | "md:text-yellow-300"
  | "sm:text-yellow-300"
  | "xs:text-yellow-300"
  | "group-hover:text-yellow-300"
  | "focus-within:text-yellow-300"
  | "hover:text-yellow-300"
  | "focus:text-yellow-300"
  | "hover:text-yellow-300"
  | "hover:text-yellow-300"
  | "2xl:text-yellow-400"
  | "1xl:text-yellow-400"
  | "xl:text-yellow-400"
  | "lg:text-yellow-400"
  | "md:text-yellow-400"
  | "sm:text-yellow-400"
  | "xs:text-yellow-400"
  | "group-hover:text-yellow-400"
  | "focus-within:text-yellow-400"
  | "hover:text-yellow-400"
  | "focus:text-yellow-400"
  | "hover:text-yellow-400"
  | "hover:text-yellow-400"
  | "2xl:text-yellow-500"
  | "1xl:text-yellow-500"
  | "xl:text-yellow-500"
  | "lg:text-yellow-500"
  | "md:text-yellow-500"
  | "sm:text-yellow-500"
  | "xs:text-yellow-500"
  | "group-hover:text-yellow-500"
  | "focus-within:text-yellow-500"
  | "hover:text-yellow-500"
  | "focus:text-yellow-500"
  | "hover:text-yellow-500"
  | "hover:text-yellow-500"
  | "2xl:text-yellow-600"
  | "1xl:text-yellow-600"
  | "xl:text-yellow-600"
  | "lg:text-yellow-600"
  | "md:text-yellow-600"
  | "sm:text-yellow-600"
  | "xs:text-yellow-600"
  | "group-hover:text-yellow-600"
  | "focus-within:text-yellow-600"
  | "hover:text-yellow-600"
  | "focus:text-yellow-600"
  | "hover:text-yellow-600"
  | "hover:text-yellow-600"
  | "2xl:text-yellow-700"
  | "1xl:text-yellow-700"
  | "xl:text-yellow-700"
  | "lg:text-yellow-700"
  | "md:text-yellow-700"
  | "sm:text-yellow-700"
  | "xs:text-yellow-700"
  | "group-hover:text-yellow-700"
  | "focus-within:text-yellow-700"
  | "hover:text-yellow-700"
  | "focus:text-yellow-700"
  | "hover:text-yellow-700"
  | "hover:text-yellow-700"
  | "2xl:text-yellow-800"
  | "1xl:text-yellow-800"
  | "xl:text-yellow-800"
  | "lg:text-yellow-800"
  | "md:text-yellow-800"
  | "sm:text-yellow-800"
  | "xs:text-yellow-800"
  | "group-hover:text-yellow-800"
  | "focus-within:text-yellow-800"
  | "hover:text-yellow-800"
  | "focus:text-yellow-800"
  | "hover:text-yellow-800"
  | "hover:text-yellow-800"
  | "2xl:text-yellow-900"
  | "1xl:text-yellow-900"
  | "xl:text-yellow-900"
  | "lg:text-yellow-900"
  | "md:text-yellow-900"
  | "sm:text-yellow-900"
  | "xs:text-yellow-900"
  | "group-hover:text-yellow-900"
  | "focus-within:text-yellow-900"
  | "hover:text-yellow-900"
  | "focus:text-yellow-900"
  | "hover:text-yellow-900"
  | "hover:text-yellow-900"
  | "2xl:text-green-50"
  | "1xl:text-green-50"
  | "xl:text-green-50"
  | "lg:text-green-50"
  | "md:text-green-50"
  | "sm:text-green-50"
  | "xs:text-green-50"
  | "group-hover:text-green-50"
  | "focus-within:text-green-50"
  | "hover:text-green-50"
  | "focus:text-green-50"
  | "hover:text-green-50"
  | "hover:text-green-50"
  | "2xl:text-green-100"
  | "1xl:text-green-100"
  | "xl:text-green-100"
  | "lg:text-green-100"
  | "md:text-green-100"
  | "sm:text-green-100"
  | "xs:text-green-100"
  | "group-hover:text-green-100"
  | "focus-within:text-green-100"
  | "hover:text-green-100"
  | "focus:text-green-100"
  | "hover:text-green-100"
  | "hover:text-green-100"
  | "2xl:text-green-200"
  | "1xl:text-green-200"
  | "xl:text-green-200"
  | "lg:text-green-200"
  | "md:text-green-200"
  | "sm:text-green-200"
  | "xs:text-green-200"
  | "group-hover:text-green-200"
  | "focus-within:text-green-200"
  | "hover:text-green-200"
  | "focus:text-green-200"
  | "hover:text-green-200"
  | "hover:text-green-200"
  | "2xl:text-green-300"
  | "1xl:text-green-300"
  | "xl:text-green-300"
  | "lg:text-green-300"
  | "md:text-green-300"
  | "sm:text-green-300"
  | "xs:text-green-300"
  | "group-hover:text-green-300"
  | "focus-within:text-green-300"
  | "hover:text-green-300"
  | "focus:text-green-300"
  | "hover:text-green-300"
  | "hover:text-green-300"
  | "2xl:text-green-400"
  | "1xl:text-green-400"
  | "xl:text-green-400"
  | "lg:text-green-400"
  | "md:text-green-400"
  | "sm:text-green-400"
  | "xs:text-green-400"
  | "group-hover:text-green-400"
  | "focus-within:text-green-400"
  | "hover:text-green-400"
  | "focus:text-green-400"
  | "hover:text-green-400"
  | "hover:text-green-400"
  | "2xl:text-green-500"
  | "1xl:text-green-500"
  | "xl:text-green-500"
  | "lg:text-green-500"
  | "md:text-green-500"
  | "sm:text-green-500"
  | "xs:text-green-500"
  | "group-hover:text-green-500"
  | "focus-within:text-green-500"
  | "hover:text-green-500"
  | "focus:text-green-500"
  | "hover:text-green-500"
  | "hover:text-green-500"
  | "2xl:text-green-600"
  | "1xl:text-green-600"
  | "xl:text-green-600"
  | "lg:text-green-600"
  | "md:text-green-600"
  | "sm:text-green-600"
  | "xs:text-green-600"
  | "group-hover:text-green-600"
  | "focus-within:text-green-600"
  | "hover:text-green-600"
  | "focus:text-green-600"
  | "hover:text-green-600"
  | "hover:text-green-600"
  | "2xl:text-green-700"
  | "1xl:text-green-700"
  | "xl:text-green-700"
  | "lg:text-green-700"
  | "md:text-green-700"
  | "sm:text-green-700"
  | "xs:text-green-700"
  | "group-hover:text-green-700"
  | "focus-within:text-green-700"
  | "hover:text-green-700"
  | "focus:text-green-700"
  | "hover:text-green-700"
  | "hover:text-green-700"
  | "2xl:text-green-800"
  | "1xl:text-green-800"
  | "xl:text-green-800"
  | "lg:text-green-800"
  | "md:text-green-800"
  | "sm:text-green-800"
  | "xs:text-green-800"
  | "group-hover:text-green-800"
  | "focus-within:text-green-800"
  | "hover:text-green-800"
  | "focus:text-green-800"
  | "hover:text-green-800"
  | "hover:text-green-800"
  | "2xl:text-green-900"
  | "1xl:text-green-900"
  | "xl:text-green-900"
  | "lg:text-green-900"
  | "md:text-green-900"
  | "sm:text-green-900"
  | "xs:text-green-900"
  | "group-hover:text-green-900"
  | "focus-within:text-green-900"
  | "hover:text-green-900"
  | "focus:text-green-900"
  | "hover:text-green-900"
  | "hover:text-green-900"
  | "2xl:text-blue-50"
  | "1xl:text-blue-50"
  | "xl:text-blue-50"
  | "lg:text-blue-50"
  | "md:text-blue-50"
  | "sm:text-blue-50"
  | "xs:text-blue-50"
  | "group-hover:text-blue-50"
  | "focus-within:text-blue-50"
  | "hover:text-blue-50"
  | "focus:text-blue-50"
  | "hover:text-blue-50"
  | "hover:text-blue-50"
  | "2xl:text-blue-100"
  | "1xl:text-blue-100"
  | "xl:text-blue-100"
  | "lg:text-blue-100"
  | "md:text-blue-100"
  | "sm:text-blue-100"
  | "xs:text-blue-100"
  | "group-hover:text-blue-100"
  | "focus-within:text-blue-100"
  | "hover:text-blue-100"
  | "focus:text-blue-100"
  | "hover:text-blue-100"
  | "hover:text-blue-100"
  | "2xl:text-blue-200"
  | "1xl:text-blue-200"
  | "xl:text-blue-200"
  | "lg:text-blue-200"
  | "md:text-blue-200"
  | "sm:text-blue-200"
  | "xs:text-blue-200"
  | "group-hover:text-blue-200"
  | "focus-within:text-blue-200"
  | "hover:text-blue-200"
  | "focus:text-blue-200"
  | "hover:text-blue-200"
  | "hover:text-blue-200"
  | "2xl:text-blue-300"
  | "1xl:text-blue-300"
  | "xl:text-blue-300"
  | "lg:text-blue-300"
  | "md:text-blue-300"
  | "sm:text-blue-300"
  | "xs:text-blue-300"
  | "group-hover:text-blue-300"
  | "focus-within:text-blue-300"
  | "hover:text-blue-300"
  | "focus:text-blue-300"
  | "hover:text-blue-300"
  | "hover:text-blue-300"
  | "2xl:text-blue-400"
  | "1xl:text-blue-400"
  | "xl:text-blue-400"
  | "lg:text-blue-400"
  | "md:text-blue-400"
  | "sm:text-blue-400"
  | "xs:text-blue-400"
  | "group-hover:text-blue-400"
  | "focus-within:text-blue-400"
  | "hover:text-blue-400"
  | "focus:text-blue-400"
  | "hover:text-blue-400"
  | "hover:text-blue-400"
  | "2xl:text-blue-500"
  | "1xl:text-blue-500"
  | "xl:text-blue-500"
  | "lg:text-blue-500"
  | "md:text-blue-500"
  | "sm:text-blue-500"
  | "xs:text-blue-500"
  | "group-hover:text-blue-500"
  | "focus-within:text-blue-500"
  | "hover:text-blue-500"
  | "focus:text-blue-500"
  | "hover:text-blue-500"
  | "hover:text-blue-500"
  | "2xl:text-blue-600"
  | "1xl:text-blue-600"
  | "xl:text-blue-600"
  | "lg:text-blue-600"
  | "md:text-blue-600"
  | "sm:text-blue-600"
  | "xs:text-blue-600"
  | "group-hover:text-blue-600"
  | "focus-within:text-blue-600"
  | "hover:text-blue-600"
  | "focus:text-blue-600"
  | "hover:text-blue-600"
  | "hover:text-blue-600"
  | "2xl:text-blue-700"
  | "1xl:text-blue-700"
  | "xl:text-blue-700"
  | "lg:text-blue-700"
  | "md:text-blue-700"
  | "sm:text-blue-700"
  | "xs:text-blue-700"
  | "group-hover:text-blue-700"
  | "focus-within:text-blue-700"
  | "hover:text-blue-700"
  | "focus:text-blue-700"
  | "hover:text-blue-700"
  | "hover:text-blue-700"
  | "2xl:text-blue-800"
  | "1xl:text-blue-800"
  | "xl:text-blue-800"
  | "lg:text-blue-800"
  | "md:text-blue-800"
  | "sm:text-blue-800"
  | "xs:text-blue-800"
  | "group-hover:text-blue-800"
  | "focus-within:text-blue-800"
  | "hover:text-blue-800"
  | "focus:text-blue-800"
  | "hover:text-blue-800"
  | "hover:text-blue-800"
  | "2xl:text-blue-900"
  | "1xl:text-blue-900"
  | "xl:text-blue-900"
  | "lg:text-blue-900"
  | "md:text-blue-900"
  | "sm:text-blue-900"
  | "xs:text-blue-900"
  | "group-hover:text-blue-900"
  | "focus-within:text-blue-900"
  | "hover:text-blue-900"
  | "focus:text-blue-900"
  | "hover:text-blue-900"
  | "hover:text-blue-900"
  | "2xl:text-indigo-50"
  | "1xl:text-indigo-50"
  | "xl:text-indigo-50"
  | "lg:text-indigo-50"
  | "md:text-indigo-50"
  | "sm:text-indigo-50"
  | "xs:text-indigo-50"
  | "group-hover:text-indigo-50"
  | "focus-within:text-indigo-50"
  | "hover:text-indigo-50"
  | "focus:text-indigo-50"
  | "hover:text-indigo-50"
  | "hover:text-indigo-50"
  | "2xl:text-indigo-100"
  | "1xl:text-indigo-100"
  | "xl:text-indigo-100"
  | "lg:text-indigo-100"
  | "md:text-indigo-100"
  | "sm:text-indigo-100"
  | "xs:text-indigo-100"
  | "group-hover:text-indigo-100"
  | "focus-within:text-indigo-100"
  | "hover:text-indigo-100"
  | "focus:text-indigo-100"
  | "hover:text-indigo-100"
  | "hover:text-indigo-100"
  | "2xl:text-indigo-200"
  | "1xl:text-indigo-200"
  | "xl:text-indigo-200"
  | "lg:text-indigo-200"
  | "md:text-indigo-200"
  | "sm:text-indigo-200"
  | "xs:text-indigo-200"
  | "group-hover:text-indigo-200"
  | "focus-within:text-indigo-200"
  | "hover:text-indigo-200"
  | "focus:text-indigo-200"
  | "hover:text-indigo-200"
  | "hover:text-indigo-200"
  | "2xl:text-indigo-300"
  | "1xl:text-indigo-300"
  | "xl:text-indigo-300"
  | "lg:text-indigo-300"
  | "md:text-indigo-300"
  | "sm:text-indigo-300"
  | "xs:text-indigo-300"
  | "group-hover:text-indigo-300"
  | "focus-within:text-indigo-300"
  | "hover:text-indigo-300"
  | "focus:text-indigo-300"
  | "hover:text-indigo-300"
  | "hover:text-indigo-300"
  | "2xl:text-indigo-400"
  | "1xl:text-indigo-400"
  | "xl:text-indigo-400"
  | "lg:text-indigo-400"
  | "md:text-indigo-400"
  | "sm:text-indigo-400"
  | "xs:text-indigo-400"
  | "group-hover:text-indigo-400"
  | "focus-within:text-indigo-400"
  | "hover:text-indigo-400"
  | "focus:text-indigo-400"
  | "hover:text-indigo-400"
  | "hover:text-indigo-400"
  | "2xl:text-indigo-500"
  | "1xl:text-indigo-500"
  | "xl:text-indigo-500"
  | "lg:text-indigo-500"
  | "md:text-indigo-500"
  | "sm:text-indigo-500"
  | "xs:text-indigo-500"
  | "group-hover:text-indigo-500"
  | "focus-within:text-indigo-500"
  | "hover:text-indigo-500"
  | "focus:text-indigo-500"
  | "hover:text-indigo-500"
  | "hover:text-indigo-500"
  | "2xl:text-indigo-600"
  | "1xl:text-indigo-600"
  | "xl:text-indigo-600"
  | "lg:text-indigo-600"
  | "md:text-indigo-600"
  | "sm:text-indigo-600"
  | "xs:text-indigo-600"
  | "group-hover:text-indigo-600"
  | "focus-within:text-indigo-600"
  | "hover:text-indigo-600"
  | "focus:text-indigo-600"
  | "hover:text-indigo-600"
  | "hover:text-indigo-600"
  | "2xl:text-indigo-700"
  | "1xl:text-indigo-700"
  | "xl:text-indigo-700"
  | "lg:text-indigo-700"
  | "md:text-indigo-700"
  | "sm:text-indigo-700"
  | "xs:text-indigo-700"
  | "group-hover:text-indigo-700"
  | "focus-within:text-indigo-700"
  | "hover:text-indigo-700"
  | "focus:text-indigo-700"
  | "hover:text-indigo-700"
  | "hover:text-indigo-700"
  | "2xl:text-indigo-800"
  | "1xl:text-indigo-800"
  | "xl:text-indigo-800"
  | "lg:text-indigo-800"
  | "md:text-indigo-800"
  | "sm:text-indigo-800"
  | "xs:text-indigo-800"
  | "group-hover:text-indigo-800"
  | "focus-within:text-indigo-800"
  | "hover:text-indigo-800"
  | "focus:text-indigo-800"
  | "hover:text-indigo-800"
  | "hover:text-indigo-800"
  | "2xl:text-indigo-900"
  | "1xl:text-indigo-900"
  | "xl:text-indigo-900"
  | "lg:text-indigo-900"
  | "md:text-indigo-900"
  | "sm:text-indigo-900"
  | "xs:text-indigo-900"
  | "group-hover:text-indigo-900"
  | "focus-within:text-indigo-900"
  | "hover:text-indigo-900"
  | "focus:text-indigo-900"
  | "hover:text-indigo-900"
  | "hover:text-indigo-900"
  | "2xl:text-purple-50"
  | "1xl:text-purple-50"
  | "xl:text-purple-50"
  | "lg:text-purple-50"
  | "md:text-purple-50"
  | "sm:text-purple-50"
  | "xs:text-purple-50"
  | "group-hover:text-purple-50"
  | "focus-within:text-purple-50"
  | "hover:text-purple-50"
  | "focus:text-purple-50"
  | "hover:text-purple-50"
  | "hover:text-purple-50"
  | "2xl:text-purple-100"
  | "1xl:text-purple-100"
  | "xl:text-purple-100"
  | "lg:text-purple-100"
  | "md:text-purple-100"
  | "sm:text-purple-100"
  | "xs:text-purple-100"
  | "group-hover:text-purple-100"
  | "focus-within:text-purple-100"
  | "hover:text-purple-100"
  | "focus:text-purple-100"
  | "hover:text-purple-100"
  | "hover:text-purple-100"
  | "2xl:text-purple-200"
  | "1xl:text-purple-200"
  | "xl:text-purple-200"
  | "lg:text-purple-200"
  | "md:text-purple-200"
  | "sm:text-purple-200"
  | "xs:text-purple-200"
  | "group-hover:text-purple-200"
  | "focus-within:text-purple-200"
  | "hover:text-purple-200"
  | "focus:text-purple-200"
  | "hover:text-purple-200"
  | "hover:text-purple-200"
  | "2xl:text-purple-300"
  | "1xl:text-purple-300"
  | "xl:text-purple-300"
  | "lg:text-purple-300"
  | "md:text-purple-300"
  | "sm:text-purple-300"
  | "xs:text-purple-300"
  | "group-hover:text-purple-300"
  | "focus-within:text-purple-300"
  | "hover:text-purple-300"
  | "focus:text-purple-300"
  | "hover:text-purple-300"
  | "hover:text-purple-300"
  | "2xl:text-purple-400"
  | "1xl:text-purple-400"
  | "xl:text-purple-400"
  | "lg:text-purple-400"
  | "md:text-purple-400"
  | "sm:text-purple-400"
  | "xs:text-purple-400"
  | "group-hover:text-purple-400"
  | "focus-within:text-purple-400"
  | "hover:text-purple-400"
  | "focus:text-purple-400"
  | "hover:text-purple-400"
  | "hover:text-purple-400"
  | "2xl:text-purple-500"
  | "1xl:text-purple-500"
  | "xl:text-purple-500"
  | "lg:text-purple-500"
  | "md:text-purple-500"
  | "sm:text-purple-500"
  | "xs:text-purple-500"
  | "group-hover:text-purple-500"
  | "focus-within:text-purple-500"
  | "hover:text-purple-500"
  | "focus:text-purple-500"
  | "hover:text-purple-500"
  | "hover:text-purple-500"
  | "2xl:text-purple-600"
  | "1xl:text-purple-600"
  | "xl:text-purple-600"
  | "lg:text-purple-600"
  | "md:text-purple-600"
  | "sm:text-purple-600"
  | "xs:text-purple-600"
  | "group-hover:text-purple-600"
  | "focus-within:text-purple-600"
  | "hover:text-purple-600"
  | "focus:text-purple-600"
  | "hover:text-purple-600"
  | "hover:text-purple-600"
  | "2xl:text-purple-700"
  | "1xl:text-purple-700"
  | "xl:text-purple-700"
  | "lg:text-purple-700"
  | "md:text-purple-700"
  | "sm:text-purple-700"
  | "xs:text-purple-700"
  | "group-hover:text-purple-700"
  | "focus-within:text-purple-700"
  | "hover:text-purple-700"
  | "focus:text-purple-700"
  | "hover:text-purple-700"
  | "hover:text-purple-700"
  | "2xl:text-purple-800"
  | "1xl:text-purple-800"
  | "xl:text-purple-800"
  | "lg:text-purple-800"
  | "md:text-purple-800"
  | "sm:text-purple-800"
  | "xs:text-purple-800"
  | "group-hover:text-purple-800"
  | "focus-within:text-purple-800"
  | "hover:text-purple-800"
  | "focus:text-purple-800"
  | "hover:text-purple-800"
  | "hover:text-purple-800"
  | "2xl:text-purple-900"
  | "1xl:text-purple-900"
  | "xl:text-purple-900"
  | "lg:text-purple-900"
  | "md:text-purple-900"
  | "sm:text-purple-900"
  | "xs:text-purple-900"
  | "group-hover:text-purple-900"
  | "focus-within:text-purple-900"
  | "hover:text-purple-900"
  | "focus:text-purple-900"
  | "hover:text-purple-900"
  | "hover:text-purple-900"
  | "2xl:text-pink-50"
  | "1xl:text-pink-50"
  | "xl:text-pink-50"
  | "lg:text-pink-50"
  | "md:text-pink-50"
  | "sm:text-pink-50"
  | "xs:text-pink-50"
  | "group-hover:text-pink-50"
  | "focus-within:text-pink-50"
  | "hover:text-pink-50"
  | "focus:text-pink-50"
  | "hover:text-pink-50"
  | "hover:text-pink-50"
  | "2xl:text-pink-100"
  | "1xl:text-pink-100"
  | "xl:text-pink-100"
  | "lg:text-pink-100"
  | "md:text-pink-100"
  | "sm:text-pink-100"
  | "xs:text-pink-100"
  | "group-hover:text-pink-100"
  | "focus-within:text-pink-100"
  | "hover:text-pink-100"
  | "focus:text-pink-100"
  | "hover:text-pink-100"
  | "hover:text-pink-100"
  | "2xl:text-pink-200"
  | "1xl:text-pink-200"
  | "xl:text-pink-200"
  | "lg:text-pink-200"
  | "md:text-pink-200"
  | "sm:text-pink-200"
  | "xs:text-pink-200"
  | "group-hover:text-pink-200"
  | "focus-within:text-pink-200"
  | "hover:text-pink-200"
  | "focus:text-pink-200"
  | "hover:text-pink-200"
  | "hover:text-pink-200"
  | "2xl:text-pink-300"
  | "1xl:text-pink-300"
  | "xl:text-pink-300"
  | "lg:text-pink-300"
  | "md:text-pink-300"
  | "sm:text-pink-300"
  | "xs:text-pink-300"
  | "group-hover:text-pink-300"
  | "focus-within:text-pink-300"
  | "hover:text-pink-300"
  | "focus:text-pink-300"
  | "hover:text-pink-300"
  | "hover:text-pink-300"
  | "2xl:text-pink-400"
  | "1xl:text-pink-400"
  | "xl:text-pink-400"
  | "lg:text-pink-400"
  | "md:text-pink-400"
  | "sm:text-pink-400"
  | "xs:text-pink-400"
  | "group-hover:text-pink-400"
  | "focus-within:text-pink-400"
  | "hover:text-pink-400"
  | "focus:text-pink-400"
  | "hover:text-pink-400"
  | "hover:text-pink-400"
  | "2xl:text-pink-500"
  | "1xl:text-pink-500"
  | "xl:text-pink-500"
  | "lg:text-pink-500"
  | "md:text-pink-500"
  | "sm:text-pink-500"
  | "xs:text-pink-500"
  | "group-hover:text-pink-500"
  | "focus-within:text-pink-500"
  | "hover:text-pink-500"
  | "focus:text-pink-500"
  | "hover:text-pink-500"
  | "hover:text-pink-500"
  | "2xl:text-pink-600"
  | "1xl:text-pink-600"
  | "xl:text-pink-600"
  | "lg:text-pink-600"
  | "md:text-pink-600"
  | "sm:text-pink-600"
  | "xs:text-pink-600"
  | "group-hover:text-pink-600"
  | "focus-within:text-pink-600"
  | "hover:text-pink-600"
  | "focus:text-pink-600"
  | "hover:text-pink-600"
  | "hover:text-pink-600"
  | "2xl:text-pink-700"
  | "1xl:text-pink-700"
  | "xl:text-pink-700"
  | "lg:text-pink-700"
  | "md:text-pink-700"
  | "sm:text-pink-700"
  | "xs:text-pink-700"
  | "group-hover:text-pink-700"
  | "focus-within:text-pink-700"
  | "hover:text-pink-700"
  | "focus:text-pink-700"
  | "hover:text-pink-700"
  | "hover:text-pink-700"
  | "2xl:text-pink-800"
  | "1xl:text-pink-800"
  | "xl:text-pink-800"
  | "lg:text-pink-800"
  | "md:text-pink-800"
  | "sm:text-pink-800"
  | "xs:text-pink-800"
  | "group-hover:text-pink-800"
  | "focus-within:text-pink-800"
  | "hover:text-pink-800"
  | "focus:text-pink-800"
  | "hover:text-pink-800"
  | "hover:text-pink-800"
  | "2xl:text-pink-900"
  | "1xl:text-pink-900"
  | "xl:text-pink-900"
  | "lg:text-pink-900"
  | "md:text-pink-900"
  | "sm:text-pink-900"
  | "xs:text-pink-900"
  | "group-hover:text-pink-900"
  | "focus-within:text-pink-900"
  | "hover:text-pink-900"
  | "focus:text-pink-900"
  | "hover:text-pink-900"
  | "hover:text-pink-900"
  | "2xl:text-title-color-1"
  | "1xl:text-title-color-1"
  | "xl:text-title-color-1"
  | "lg:text-title-color-1"
  | "md:text-title-color-1"
  | "sm:text-title-color-1"
  | "xs:text-title-color-1"
  | "group-hover:text-title-color-1"
  | "focus-within:text-title-color-1"
  | "hover:text-title-color-1"
  | "focus:text-title-color-1"
  | "hover:text-title-color-1"
  | "hover:text-title-color-1"
  | "2xl:text-primary-bg-color"
  | "1xl:text-primary-bg-color"
  | "xl:text-primary-bg-color"
  | "lg:text-primary-bg-color"
  | "md:text-primary-bg-color"
  | "sm:text-primary-bg-color"
  | "xs:text-primary-bg-color"
  | "group-hover:text-primary-bg-color"
  | "focus-within:text-primary-bg-color"
  | "hover:text-primary-bg-color"
  | "focus:text-primary-bg-color"
  | "hover:text-primary-bg-color"
  | "hover:text-primary-bg-color"
  | "2xl:text-secondary-bg-color"
  | "1xl:text-secondary-bg-color"
  | "xl:text-secondary-bg-color"
  | "lg:text-secondary-bg-color"
  | "md:text-secondary-bg-color"
  | "sm:text-secondary-bg-color"
  | "xs:text-secondary-bg-color"
  | "group-hover:text-secondary-bg-color"
  | "focus-within:text-secondary-bg-color"
  | "hover:text-secondary-bg-color"
  | "focus:text-secondary-bg-color"
  | "hover:text-secondary-bg-color"
  | "hover:text-secondary-bg-color"
  | "2xl:text-sidebar-bg-color"
  | "1xl:text-sidebar-bg-color"
  | "xl:text-sidebar-bg-color"
  | "lg:text-sidebar-bg-color"
  | "md:text-sidebar-bg-color"
  | "sm:text-sidebar-bg-color"
  | "xs:text-sidebar-bg-color"
  | "group-hover:text-sidebar-bg-color"
  | "focus-within:text-sidebar-bg-color"
  | "hover:text-sidebar-bg-color"
  | "focus:text-sidebar-bg-color"
  | "hover:text-sidebar-bg-color"
  | "hover:text-sidebar-bg-color"
  | "2xl:text-header-bg-color"
  | "1xl:text-header-bg-color"
  | "xl:text-header-bg-color"
  | "lg:text-header-bg-color"
  | "md:text-header-bg-color"
  | "sm:text-header-bg-color"
  | "xs:text-header-bg-color"
  | "group-hover:text-header-bg-color"
  | "focus-within:text-header-bg-color"
  | "hover:text-header-bg-color"
  | "focus:text-header-bg-color"
  | "hover:text-header-bg-color"
  | "hover:text-header-bg-color"
  | "2xl:text-body-bg-color"
  | "1xl:text-body-bg-color"
  | "xl:text-body-bg-color"
  | "lg:text-body-bg-color"
  | "md:text-body-bg-color"
  | "sm:text-body-bg-color"
  | "xs:text-body-bg-color"
  | "group-hover:text-body-bg-color"
  | "focus-within:text-body-bg-color"
  | "hover:text-body-bg-color"
  | "focus:text-body-bg-color"
  | "hover:text-body-bg-color"
  | "hover:text-body-bg-color"
  | "2xl:text-main-color"
  | "1xl:text-main-color"
  | "xl:text-main-color"
  | "lg:text-main-color"
  | "md:text-main-color"
  | "sm:text-main-color"
  | "xs:text-main-color"
  | "group-hover:text-main-color"
  | "focus-within:text-main-color"
  | "hover:text-main-color"
  | "focus:text-main-color"
  | "hover:text-main-color"
  | "hover:text-main-color"
  | "2xl:text-tertiary-bg-color"
  | "1xl:text-tertiary-bg-color"
  | "xl:text-tertiary-bg-color"
  | "lg:text-tertiary-bg-color"
  | "md:text-tertiary-bg-color"
  | "sm:text-tertiary-bg-color"
  | "xs:text-tertiary-bg-color"
  | "group-hover:text-tertiary-bg-color"
  | "focus-within:text-tertiary-bg-color"
  | "hover:text-tertiary-bg-color"
  | "focus:text-tertiary-bg-color"
  | "hover:text-tertiary-bg-color"
  | "hover:text-tertiary-bg-color"
  | "2xl:text-quaternary-bg-color"
  | "1xl:text-quaternary-bg-color"
  | "xl:text-quaternary-bg-color"
  | "lg:text-quaternary-bg-color"
  | "md:text-quaternary-bg-color"
  | "sm:text-quaternary-bg-color"
  | "xs:text-quaternary-bg-color"
  | "group-hover:text-quaternary-bg-color"
  | "focus-within:text-quaternary-bg-color"
  | "hover:text-quaternary-bg-color"
  | "focus:text-quaternary-bg-color"
  | "hover:text-quaternary-bg-color"
  | "hover:text-quaternary-bg-color"
  | "2xl:text-earnings-bg-color"
  | "1xl:text-earnings-bg-color"
  | "xl:text-earnings-bg-color"
  | "lg:text-earnings-bg-color"
  | "md:text-earnings-bg-color"
  | "sm:text-earnings-bg-color"
  | "xs:text-earnings-bg-color"
  | "group-hover:text-earnings-bg-color"
  | "focus-within:text-earnings-bg-color"
  | "hover:text-earnings-bg-color"
  | "focus:text-earnings-bg-color"
  | "hover:text-earnings-bg-color"
  | "hover:text-earnings-bg-color"
  | "2xl:text-card-secondary-bg-color"
  | "1xl:text-card-secondary-bg-color"
  | "xl:text-card-secondary-bg-color"
  | "lg:text-card-secondary-bg-color"
  | "md:text-card-secondary-bg-color"
  | "sm:text-card-secondary-bg-color"
  | "xs:text-card-secondary-bg-color"
  | "group-hover:text-card-secondary-bg-color"
  | "focus-within:text-card-secondary-bg-color"
  | "hover:text-card-secondary-bg-color"
  | "focus:text-card-secondary-bg-color"
  | "hover:text-card-secondary-bg-color"
  | "hover:text-card-secondary-bg-color"
  | "2xl:text-pink-color"
  | "1xl:text-pink-color"
  | "xl:text-pink-color"
  | "lg:text-pink-color"
  | "md:text-pink-color"
  | "sm:text-pink-color"
  | "xs:text-pink-color"
  | "group-hover:text-pink-color"
  | "focus-within:text-pink-color"
  | "hover:text-pink-color"
  | "focus:text-pink-color"
  | "hover:text-pink-color"
  | "hover:text-pink-color"
  | "2xl:text-go-live-text"
  | "1xl:text-go-live-text"
  | "xl:text-go-live-text"
  | "lg:text-go-live-text"
  | "md:text-go-live-text"
  | "sm:text-go-live-text"
  | "xs:text-go-live-text"
  | "group-hover:text-go-live-text"
  | "focus-within:text-go-live-text"
  | "hover:text-go-live-text"
  | "focus:text-go-live-text"
  | "hover:text-go-live-text"
  | "hover:text-go-live-text"
  | "2xl:text-go-live-bg-color"
  | "1xl:text-go-live-bg-color"
  | "xl:text-go-live-bg-color"
  | "lg:text-go-live-bg-color"
  | "md:text-go-live-bg-color"
  | "sm:text-go-live-bg-color"
  | "xs:text-go-live-bg-color"
  | "group-hover:text-go-live-bg-color"
  | "focus-within:text-go-live-bg-color"
  | "hover:text-go-live-bg-color"
  | "focus:text-go-live-bg-color"
  | "hover:text-go-live-bg-color"
  | "hover:text-go-live-bg-color"
  | "2xl:text-gray-button"
  | "1xl:text-gray-button"
  | "xl:text-gray-button"
  | "lg:text-gray-button"
  | "md:text-gray-button"
  | "sm:text-gray-button"
  | "xs:text-gray-button"
  | "group-hover:text-gray-button"
  | "focus-within:text-gray-button"
  | "hover:text-gray-button"
  | "focus:text-gray-button"
  | "hover:text-gray-button"
  | "hover:text-gray-button"
  | "2xl:text-toggle-disabled"
  | "1xl:text-toggle-disabled"
  | "xl:text-toggle-disabled"
  | "lg:text-toggle-disabled"
  | "md:text-toggle-disabled"
  | "sm:text-toggle-disabled"
  | "xs:text-toggle-disabled"
  | "group-hover:text-toggle-disabled"
  | "focus-within:text-toggle-disabled"
  | "hover:text-toggle-disabled"
  | "focus:text-toggle-disabled"
  | "hover:text-toggle-disabled"
  | "hover:text-toggle-disabled"
  | "2xl:text-tab-not-selected-color"
  | "1xl:text-tab-not-selected-color"
  | "xl:text-tab-not-selected-color"
  | "lg:text-tab-not-selected-color"
  | "md:text-tab-not-selected-color"
  | "sm:text-tab-not-selected-color"
  | "xs:text-tab-not-selected-color"
  | "group-hover:text-tab-not-selected-color"
  | "focus-within:text-tab-not-selected-color"
  | "hover:text-tab-not-selected-color"
  | "focus:text-tab-not-selected-color"
  | "hover:text-tab-not-selected-color"
  | "hover:text-tab-not-selected-color"
  | "2xl:text-text-area-bg-color"
  | "1xl:text-text-area-bg-color"
  | "xl:text-text-area-bg-color"
  | "lg:text-text-area-bg-color"
  | "md:text-text-area-bg-color"
  | "sm:text-text-area-bg-color"
  | "xs:text-text-area-bg-color"
  | "group-hover:text-text-area-bg-color"
  | "focus-within:text-text-area-bg-color"
  | "hover:text-text-area-bg-color"
  | "focus:text-text-area-bg-color"
  | "hover:text-text-area-bg-color"
  | "hover:text-text-area-bg-color"
  | "2xl:text-table-primary-bg-color"
  | "1xl:text-table-primary-bg-color"
  | "xl:text-table-primary-bg-color"
  | "lg:text-table-primary-bg-color"
  | "md:text-table-primary-bg-color"
  | "sm:text-table-primary-bg-color"
  | "xs:text-table-primary-bg-color"
  | "group-hover:text-table-primary-bg-color"
  | "focus-within:text-table-primary-bg-color"
  | "hover:text-table-primary-bg-color"
  | "focus:text-table-primary-bg-color"
  | "hover:text-table-primary-bg-color"
  | "hover:text-table-primary-bg-color"
  | "2xl:text-table-secondary-color"
  | "1xl:text-table-secondary-color"
  | "xl:text-table-secondary-color"
  | "lg:text-table-secondary-color"
  | "md:text-table-secondary-color"
  | "sm:text-table-secondary-color"
  | "xs:text-table-secondary-color"
  | "group-hover:text-table-secondary-color"
  | "focus-within:text-table-secondary-color"
  | "hover:text-table-secondary-color"
  | "focus:text-table-secondary-color"
  | "hover:text-table-secondary-color"
  | "hover:text-table-secondary-color"
  | "2xl:text-modal-button-color"
  | "1xl:text-modal-button-color"
  | "xl:text-modal-button-color"
  | "lg:text-modal-button-color"
  | "md:text-modal-button-color"
  | "sm:text-modal-button-color"
  | "xs:text-modal-button-color"
  | "group-hover:text-modal-button-color"
  | "focus-within:text-modal-button-color"
  | "hover:text-modal-button-color"
  | "focus:text-modal-button-color"
  | "hover:text-modal-button-color"
  | "hover:text-modal-button-color"
  | "2xl:underline"
  | "1xl:underline"
  | "xl:underline"
  | "lg:underline"
  | "md:underline"
  | "sm:underline"
  | "xs:underline"
  | "group-hover:underline"
  | "focus-within:underline"
  | "hover:underline"
  | "focus:underline"
  | "2xl:line-through"
  | "1xl:line-through"
  | "xl:line-through"
  | "lg:line-through"
  | "md:line-through"
  | "sm:line-through"
  | "xs:line-through"
  | "group-hover:line-through"
  | "focus-within:line-through"
  | "hover:line-through"
  | "focus:line-through"
  | "2xl:no-underline"
  | "1xl:no-underline"
  | "xl:no-underline"
  | "lg:no-underline"
  | "md:no-underline"
  | "sm:no-underline"
  | "xs:no-underline"
  | "group-hover:no-underline"
  | "focus-within:no-underline"
  | "hover:no-underline"
  | "focus:no-underline"
  | "2xl:text-opacity-0"
  | "1xl:text-opacity-0"
  | "xl:text-opacity-0"
  | "lg:text-opacity-0"
  | "md:text-opacity-0"
  | "sm:text-opacity-0"
  | "xs:text-opacity-0"
  | "group-hover:text-opacity-0"
  | "focus-within:text-opacity-0"
  | "hover:text-opacity-0"
  | "focus:text-opacity-0"
  | "2xl:text-opacity-5"
  | "1xl:text-opacity-5"
  | "xl:text-opacity-5"
  | "lg:text-opacity-5"
  | "md:text-opacity-5"
  | "sm:text-opacity-5"
  | "xs:text-opacity-5"
  | "group-hover:text-opacity-5"
  | "focus-within:text-opacity-5"
  | "hover:text-opacity-5"
  | "focus:text-opacity-5"
  | "2xl:text-opacity-10"
  | "1xl:text-opacity-10"
  | "xl:text-opacity-10"
  | "lg:text-opacity-10"
  | "md:text-opacity-10"
  | "sm:text-opacity-10"
  | "xs:text-opacity-10"
  | "group-hover:text-opacity-10"
  | "focus-within:text-opacity-10"
  | "hover:text-opacity-10"
  | "focus:text-opacity-10"
  | "2xl:text-opacity-20"
  | "1xl:text-opacity-20"
  | "xl:text-opacity-20"
  | "lg:text-opacity-20"
  | "md:text-opacity-20"
  | "sm:text-opacity-20"
  | "xs:text-opacity-20"
  | "group-hover:text-opacity-20"
  | "focus-within:text-opacity-20"
  | "hover:text-opacity-20"
  | "focus:text-opacity-20"
  | "2xl:text-opacity-25"
  | "1xl:text-opacity-25"
  | "xl:text-opacity-25"
  | "lg:text-opacity-25"
  | "md:text-opacity-25"
  | "sm:text-opacity-25"
  | "xs:text-opacity-25"
  | "group-hover:text-opacity-25"
  | "focus-within:text-opacity-25"
  | "hover:text-opacity-25"
  | "focus:text-opacity-25"
  | "2xl:text-opacity-30"
  | "1xl:text-opacity-30"
  | "xl:text-opacity-30"
  | "lg:text-opacity-30"
  | "md:text-opacity-30"
  | "sm:text-opacity-30"
  | "xs:text-opacity-30"
  | "group-hover:text-opacity-30"
  | "focus-within:text-opacity-30"
  | "hover:text-opacity-30"
  | "focus:text-opacity-30"
  | "2xl:text-opacity-40"
  | "1xl:text-opacity-40"
  | "xl:text-opacity-40"
  | "lg:text-opacity-40"
  | "md:text-opacity-40"
  | "sm:text-opacity-40"
  | "xs:text-opacity-40"
  | "group-hover:text-opacity-40"
  | "focus-within:text-opacity-40"
  | "hover:text-opacity-40"
  | "focus:text-opacity-40"
  | "2xl:text-opacity-50"
  | "1xl:text-opacity-50"
  | "xl:text-opacity-50"
  | "lg:text-opacity-50"
  | "md:text-opacity-50"
  | "sm:text-opacity-50"
  | "xs:text-opacity-50"
  | "group-hover:text-opacity-50"
  | "focus-within:text-opacity-50"
  | "hover:text-opacity-50"
  | "focus:text-opacity-50"
  | "2xl:text-opacity-60"
  | "1xl:text-opacity-60"
  | "xl:text-opacity-60"
  | "lg:text-opacity-60"
  | "md:text-opacity-60"
  | "sm:text-opacity-60"
  | "xs:text-opacity-60"
  | "group-hover:text-opacity-60"
  | "focus-within:text-opacity-60"
  | "hover:text-opacity-60"
  | "focus:text-opacity-60"
  | "2xl:text-opacity-70"
  | "1xl:text-opacity-70"
  | "xl:text-opacity-70"
  | "lg:text-opacity-70"
  | "md:text-opacity-70"
  | "sm:text-opacity-70"
  | "xs:text-opacity-70"
  | "group-hover:text-opacity-70"
  | "focus-within:text-opacity-70"
  | "hover:text-opacity-70"
  | "focus:text-opacity-70"
  | "2xl:text-opacity-75"
  | "1xl:text-opacity-75"
  | "xl:text-opacity-75"
  | "lg:text-opacity-75"
  | "md:text-opacity-75"
  | "sm:text-opacity-75"
  | "xs:text-opacity-75"
  | "group-hover:text-opacity-75"
  | "focus-within:text-opacity-75"
  | "hover:text-opacity-75"
  | "focus:text-opacity-75"
  | "2xl:text-opacity-80"
  | "1xl:text-opacity-80"
  | "xl:text-opacity-80"
  | "lg:text-opacity-80"
  | "md:text-opacity-80"
  | "sm:text-opacity-80"
  | "xs:text-opacity-80"
  | "group-hover:text-opacity-80"
  | "focus-within:text-opacity-80"
  | "hover:text-opacity-80"
  | "focus:text-opacity-80"
  | "2xl:text-opacity-90"
  | "1xl:text-opacity-90"
  | "xl:text-opacity-90"
  | "lg:text-opacity-90"
  | "md:text-opacity-90"
  | "sm:text-opacity-90"
  | "xs:text-opacity-90"
  | "group-hover:text-opacity-90"
  | "focus-within:text-opacity-90"
  | "hover:text-opacity-90"
  | "focus:text-opacity-90"
  | "2xl:text-opacity-95"
  | "1xl:text-opacity-95"
  | "xl:text-opacity-95"
  | "lg:text-opacity-95"
  | "md:text-opacity-95"
  | "sm:text-opacity-95"
  | "xs:text-opacity-95"
  | "group-hover:text-opacity-95"
  | "focus-within:text-opacity-95"
  | "hover:text-opacity-95"
  | "focus:text-opacity-95"
  | "2xl:text-opacity-100"
  | "1xl:text-opacity-100"
  | "xl:text-opacity-100"
  | "lg:text-opacity-100"
  | "md:text-opacity-100"
  | "sm:text-opacity-100"
  | "xs:text-opacity-100"
  | "group-hover:text-opacity-100"
  | "focus-within:text-opacity-100"
  | "hover:text-opacity-100"
  | "focus:text-opacity-100"
  | "2xl:truncate"
  | "1xl:truncate"
  | "xl:truncate"
  | "lg:truncate"
  | "md:truncate"
  | "sm:truncate"
  | "xs:truncate"
  | "2xl:overflow-ellipsis"
  | "1xl:overflow-ellipsis"
  | "xl:overflow-ellipsis"
  | "lg:overflow-ellipsis"
  | "md:overflow-ellipsis"
  | "sm:overflow-ellipsis"
  | "xs:overflow-ellipsis"
  | "2xl:overflow-clip"
  | "1xl:overflow-clip"
  | "xl:overflow-clip"
  | "lg:overflow-clip"
  | "md:overflow-clip"
  | "sm:overflow-clip"
  | "xs:overflow-clip"
  | "2xl:uppercase"
  | "1xl:uppercase"
  | "xl:uppercase"
  | "lg:uppercase"
  | "md:uppercase"
  | "sm:uppercase"
  | "xs:uppercase"
  | "2xl:lowercase"
  | "1xl:lowercase"
  | "xl:lowercase"
  | "lg:lowercase"
  | "md:lowercase"
  | "sm:lowercase"
  | "xs:lowercase"
  | "2xl:capitalize"
  | "1xl:capitalize"
  | "xl:capitalize"
  | "lg:capitalize"
  | "md:capitalize"
  | "sm:capitalize"
  | "xs:capitalize"
  | "2xl:normal-case"
  | "1xl:normal-case"
  | "xl:normal-case"
  | "lg:normal-case"
  | "md:normal-case"
  | "sm:normal-case"
  | "xs:normal-case"
  | "2xl:origin-center"
  | "1xl:origin-center"
  | "xl:origin-center"
  | "lg:origin-center"
  | "md:origin-center"
  | "sm:origin-center"
  | "xs:origin-center"
  | "2xl:origin-top"
  | "1xl:origin-top"
  | "xl:origin-top"
  | "lg:origin-top"
  | "md:origin-top"
  | "sm:origin-top"
  | "xs:origin-top"
  | "2xl:origin-top-right"
  | "1xl:origin-top-right"
  | "xl:origin-top-right"
  | "lg:origin-top-right"
  | "md:origin-top-right"
  | "sm:origin-top-right"
  | "xs:origin-top-right"
  | "2xl:origin-right"
  | "1xl:origin-right"
  | "xl:origin-right"
  | "lg:origin-right"
  | "md:origin-right"
  | "sm:origin-right"
  | "xs:origin-right"
  | "2xl:origin-bottom-right"
  | "1xl:origin-bottom-right"
  | "xl:origin-bottom-right"
  | "lg:origin-bottom-right"
  | "md:origin-bottom-right"
  | "sm:origin-bottom-right"
  | "xs:origin-bottom-right"
  | "2xl:origin-bottom"
  | "1xl:origin-bottom"
  | "xl:origin-bottom"
  | "lg:origin-bottom"
  | "md:origin-bottom"
  | "sm:origin-bottom"
  | "xs:origin-bottom"
  | "2xl:origin-bottom-left"
  | "1xl:origin-bottom-left"
  | "xl:origin-bottom-left"
  | "lg:origin-bottom-left"
  | "md:origin-bottom-left"
  | "sm:origin-bottom-left"
  | "xs:origin-bottom-left"
  | "2xl:origin-left"
  | "1xl:origin-left"
  | "xl:origin-left"
  | "lg:origin-left"
  | "md:origin-left"
  | "sm:origin-left"
  | "xs:origin-left"
  | "2xl:origin-top-left"
  | "1xl:origin-top-left"
  | "xl:origin-top-left"
  | "lg:origin-top-left"
  | "md:origin-top-left"
  | "sm:origin-top-left"
  | "xs:origin-top-left"
  | "2xl:delay-75"
  | "1xl:delay-75"
  | "xl:delay-75"
  | "lg:delay-75"
  | "md:delay-75"
  | "sm:delay-75"
  | "xs:delay-75"
  | "2xl:delay-100"
  | "1xl:delay-100"
  | "xl:delay-100"
  | "lg:delay-100"
  | "md:delay-100"
  | "sm:delay-100"
  | "xs:delay-100"
  | "2xl:delay-150"
  | "1xl:delay-150"
  | "xl:delay-150"
  | "lg:delay-150"
  | "md:delay-150"
  | "sm:delay-150"
  | "xs:delay-150"
  | "2xl:delay-200"
  | "1xl:delay-200"
  | "xl:delay-200"
  | "lg:delay-200"
  | "md:delay-200"
  | "sm:delay-200"
  | "xs:delay-200"
  | "2xl:delay-300"
  | "1xl:delay-300"
  | "xl:delay-300"
  | "lg:delay-300"
  | "md:delay-300"
  | "sm:delay-300"
  | "xs:delay-300"
  | "2xl:delay-500"
  | "1xl:delay-500"
  | "xl:delay-500"
  | "lg:delay-500"
  | "md:delay-500"
  | "sm:delay-500"
  | "xs:delay-500"
  | "2xl:delay-700"
  | "1xl:delay-700"
  | "xl:delay-700"
  | "lg:delay-700"
  | "md:delay-700"
  | "sm:delay-700"
  | "xs:delay-700"
  | "2xl:delay-1000"
  | "1xl:delay-1000"
  | "xl:delay-1000"
  | "lg:delay-1000"
  | "md:delay-1000"
  | "sm:delay-1000"
  | "xs:delay-1000"
  | "2xl:duration-75"
  | "1xl:duration-75"
  | "xl:duration-75"
  | "lg:duration-75"
  | "md:duration-75"
  | "sm:duration-75"
  | "xs:duration-75"
  | "2xl:duration-100"
  | "1xl:duration-100"
  | "xl:duration-100"
  | "lg:duration-100"
  | "md:duration-100"
  | "sm:duration-100"
  | "xs:duration-100"
  | "2xl:duration-150"
  | "1xl:duration-150"
  | "xl:duration-150"
  | "lg:duration-150"
  | "md:duration-150"
  | "sm:duration-150"
  | "xs:duration-150"
  | "2xl:duration-200"
  | "1xl:duration-200"
  | "xl:duration-200"
  | "lg:duration-200"
  | "md:duration-200"
  | "sm:duration-200"
  | "xs:duration-200"
  | "2xl:duration-300"
  | "1xl:duration-300"
  | "xl:duration-300"
  | "lg:duration-300"
  | "md:duration-300"
  | "sm:duration-300"
  | "xs:duration-300"
  | "2xl:duration-500"
  | "1xl:duration-500"
  | "xl:duration-500"
  | "lg:duration-500"
  | "md:duration-500"
  | "sm:duration-500"
  | "xs:duration-500"
  | "2xl:duration-700"
  | "1xl:duration-700"
  | "xl:duration-700"
  | "lg:duration-700"
  | "md:duration-700"
  | "sm:duration-700"
  | "xs:duration-700"
  | "2xl:duration-1000"
  | "1xl:duration-1000"
  | "xl:duration-1000"
  | "lg:duration-1000"
  | "md:duration-1000"
  | "sm:duration-1000"
  | "xs:duration-1000"
  | "2xl:duration"
  | "1xl:duration"
  | "xl:duration"
  | "lg:duration"
  | "md:duration"
  | "sm:duration"
  | "xs:duration"
  | "2xl:transition-none"
  | "1xl:transition-none"
  | "xl:transition-none"
  | "lg:transition-none"
  | "md:transition-none"
  | "sm:transition-none"
  | "xs:transition-none"
  | "2xl:transition-all"
  | "1xl:transition-all"
  | "xl:transition-all"
  | "lg:transition-all"
  | "md:transition-all"
  | "sm:transition-all"
  | "xs:transition-all"
  | "2xl:transition"
  | "1xl:transition"
  | "xl:transition"
  | "lg:transition"
  | "md:transition"
  | "sm:transition"
  | "xs:transition"
  | "2xl:transition-colors"
  | "1xl:transition-colors"
  | "xl:transition-colors"
  | "lg:transition-colors"
  | "md:transition-colors"
  | "sm:transition-colors"
  | "xs:transition-colors"
  | "2xl:transition-opacity"
  | "1xl:transition-opacity"
  | "xl:transition-opacity"
  | "lg:transition-opacity"
  | "md:transition-opacity"
  | "sm:transition-opacity"
  | "xs:transition-opacity"
  | "2xl:transition-shadow"
  | "1xl:transition-shadow"
  | "xl:transition-shadow"
  | "lg:transition-shadow"
  | "md:transition-shadow"
  | "sm:transition-shadow"
  | "xs:transition-shadow"
  | "2xl:transition-transform"
  | "1xl:transition-transform"
  | "xl:transition-transform"
  | "lg:transition-transform"
  | "md:transition-transform"
  | "sm:transition-transform"
  | "xs:transition-transform"
  | "2xl:ease-linear"
  | "1xl:ease-linear"
  | "xl:ease-linear"
  | "lg:ease-linear"
  | "md:ease-linear"
  | "sm:ease-linear"
  | "xs:ease-linear"
  | "2xl:ease-in"
  | "1xl:ease-in"
  | "xl:ease-in"
  | "lg:ease-in"
  | "md:ease-in"
  | "sm:ease-in"
  | "xs:ease-in"
  | "2xl:ease-out"
  | "1xl:ease-out"
  | "xl:ease-out"
  | "lg:ease-out"
  | "md:ease-out"
  | "sm:ease-out"
  | "xs:ease-out"
  | "2xl:ease-in-out"
  | "1xl:ease-in-out"
  | "xl:ease-in-out"
  | "lg:ease-in-out"
  | "md:ease-in-out"
  | "sm:ease-in-out"
  | "xs:ease-in-out"
  | "2xl:translate-x-0"
  | "1xl:translate-x-0"
  | "xl:translate-x-0"
  | "lg:translate-x-0"
  | "md:translate-x-0"
  | "sm:translate-x-0"
  | "xs:translate-x-0"
  | "hover:translate-x-0"
  | "focus:translate-x-0"
  | "2xl:translate-x-1"
  | "1xl:translate-x-1"
  | "xl:translate-x-1"
  | "lg:translate-x-1"
  | "md:translate-x-1"
  | "sm:translate-x-1"
  | "xs:translate-x-1"
  | "hover:translate-x-1"
  | "focus:translate-x-1"
  | "2xl:translate-x-2"
  | "1xl:translate-x-2"
  | "xl:translate-x-2"
  | "lg:translate-x-2"
  | "md:translate-x-2"
  | "sm:translate-x-2"
  | "xs:translate-x-2"
  | "hover:translate-x-2"
  | "focus:translate-x-2"
  | "2xl:translate-x-3"
  | "1xl:translate-x-3"
  | "xl:translate-x-3"
  | "lg:translate-x-3"
  | "md:translate-x-3"
  | "sm:translate-x-3"
  | "xs:translate-x-3"
  | "hover:translate-x-3"
  | "focus:translate-x-3"
  | "2xl:translate-x-4"
  | "1xl:translate-x-4"
  | "xl:translate-x-4"
  | "lg:translate-x-4"
  | "md:translate-x-4"
  | "sm:translate-x-4"
  | "xs:translate-x-4"
  | "hover:translate-x-4"
  | "focus:translate-x-4"
  | "2xl:translate-x-5"
  | "1xl:translate-x-5"
  | "xl:translate-x-5"
  | "lg:translate-x-5"
  | "md:translate-x-5"
  | "sm:translate-x-5"
  | "xs:translate-x-5"
  | "hover:translate-x-5"
  | "focus:translate-x-5"
  | "2xl:translate-x-6"
  | "1xl:translate-x-6"
  | "xl:translate-x-6"
  | "lg:translate-x-6"
  | "md:translate-x-6"
  | "sm:translate-x-6"
  | "xs:translate-x-6"
  | "hover:translate-x-6"
  | "focus:translate-x-6"
  | "2xl:translate-x-7"
  | "1xl:translate-x-7"
  | "xl:translate-x-7"
  | "lg:translate-x-7"
  | "md:translate-x-7"
  | "sm:translate-x-7"
  | "xs:translate-x-7"
  | "hover:translate-x-7"
  | "focus:translate-x-7"
  | "2xl:translate-x-8"
  | "1xl:translate-x-8"
  | "xl:translate-x-8"
  | "lg:translate-x-8"
  | "md:translate-x-8"
  | "sm:translate-x-8"
  | "xs:translate-x-8"
  | "hover:translate-x-8"
  | "focus:translate-x-8"
  | "2xl:translate-x-9"
  | "1xl:translate-x-9"
  | "xl:translate-x-9"
  | "lg:translate-x-9"
  | "md:translate-x-9"
  | "sm:translate-x-9"
  | "xs:translate-x-9"
  | "hover:translate-x-9"
  | "focus:translate-x-9"
  | "2xl:translate-x-10"
  | "1xl:translate-x-10"
  | "xl:translate-x-10"
  | "lg:translate-x-10"
  | "md:translate-x-10"
  | "sm:translate-x-10"
  | "xs:translate-x-10"
  | "hover:translate-x-10"
  | "focus:translate-x-10"
  | "2xl:translate-x-11"
  | "1xl:translate-x-11"
  | "xl:translate-x-11"
  | "lg:translate-x-11"
  | "md:translate-x-11"
  | "sm:translate-x-11"
  | "xs:translate-x-11"
  | "hover:translate-x-11"
  | "focus:translate-x-11"
  | "2xl:translate-x-12"
  | "1xl:translate-x-12"
  | "xl:translate-x-12"
  | "lg:translate-x-12"
  | "md:translate-x-12"
  | "sm:translate-x-12"
  | "xs:translate-x-12"
  | "hover:translate-x-12"
  | "focus:translate-x-12"
  | "2xl:translate-x-14"
  | "1xl:translate-x-14"
  | "xl:translate-x-14"
  | "lg:translate-x-14"
  | "md:translate-x-14"
  | "sm:translate-x-14"
  | "xs:translate-x-14"
  | "hover:translate-x-14"
  | "focus:translate-x-14"
  | "2xl:translate-x-16"
  | "1xl:translate-x-16"
  | "xl:translate-x-16"
  | "lg:translate-x-16"
  | "md:translate-x-16"
  | "sm:translate-x-16"
  | "xs:translate-x-16"
  | "hover:translate-x-16"
  | "focus:translate-x-16"
  | "2xl:translate-x-20"
  | "1xl:translate-x-20"
  | "xl:translate-x-20"
  | "lg:translate-x-20"
  | "md:translate-x-20"
  | "sm:translate-x-20"
  | "xs:translate-x-20"
  | "hover:translate-x-20"
  | "focus:translate-x-20"
  | "2xl:translate-x-24"
  | "1xl:translate-x-24"
  | "xl:translate-x-24"
  | "lg:translate-x-24"
  | "md:translate-x-24"
  | "sm:translate-x-24"
  | "xs:translate-x-24"
  | "hover:translate-x-24"
  | "focus:translate-x-24"
  | "2xl:translate-x-28"
  | "1xl:translate-x-28"
  | "xl:translate-x-28"
  | "lg:translate-x-28"
  | "md:translate-x-28"
  | "sm:translate-x-28"
  | "xs:translate-x-28"
  | "hover:translate-x-28"
  | "focus:translate-x-28"
  | "2xl:translate-x-32"
  | "1xl:translate-x-32"
  | "xl:translate-x-32"
  | "lg:translate-x-32"
  | "md:translate-x-32"
  | "sm:translate-x-32"
  | "xs:translate-x-32"
  | "hover:translate-x-32"
  | "focus:translate-x-32"
  | "2xl:translate-x-36"
  | "1xl:translate-x-36"
  | "xl:translate-x-36"
  | "lg:translate-x-36"
  | "md:translate-x-36"
  | "sm:translate-x-36"
  | "xs:translate-x-36"
  | "hover:translate-x-36"
  | "focus:translate-x-36"
  | "2xl:translate-x-40"
  | "1xl:translate-x-40"
  | "xl:translate-x-40"
  | "lg:translate-x-40"
  | "md:translate-x-40"
  | "sm:translate-x-40"
  | "xs:translate-x-40"
  | "hover:translate-x-40"
  | "focus:translate-x-40"
  | "2xl:translate-x-44"
  | "1xl:translate-x-44"
  | "xl:translate-x-44"
  | "lg:translate-x-44"
  | "md:translate-x-44"
  | "sm:translate-x-44"
  | "xs:translate-x-44"
  | "hover:translate-x-44"
  | "focus:translate-x-44"
  | "2xl:translate-x-48"
  | "1xl:translate-x-48"
  | "xl:translate-x-48"
  | "lg:translate-x-48"
  | "md:translate-x-48"
  | "sm:translate-x-48"
  | "xs:translate-x-48"
  | "hover:translate-x-48"
  | "focus:translate-x-48"
  | "2xl:translate-x-52"
  | "1xl:translate-x-52"
  | "xl:translate-x-52"
  | "lg:translate-x-52"
  | "md:translate-x-52"
  | "sm:translate-x-52"
  | "xs:translate-x-52"
  | "hover:translate-x-52"
  | "focus:translate-x-52"
  | "2xl:translate-x-56"
  | "1xl:translate-x-56"
  | "xl:translate-x-56"
  | "lg:translate-x-56"
  | "md:translate-x-56"
  | "sm:translate-x-56"
  | "xs:translate-x-56"
  | "hover:translate-x-56"
  | "focus:translate-x-56"
  | "2xl:translate-x-60"
  | "1xl:translate-x-60"
  | "xl:translate-x-60"
  | "lg:translate-x-60"
  | "md:translate-x-60"
  | "sm:translate-x-60"
  | "xs:translate-x-60"
  | "hover:translate-x-60"
  | "focus:translate-x-60"
  | "2xl:translate-x-64"
  | "1xl:translate-x-64"
  | "xl:translate-x-64"
  | "lg:translate-x-64"
  | "md:translate-x-64"
  | "sm:translate-x-64"
  | "xs:translate-x-64"
  | "hover:translate-x-64"
  | "focus:translate-x-64"
  | "2xl:translate-x-72"
  | "1xl:translate-x-72"
  | "xl:translate-x-72"
  | "lg:translate-x-72"
  | "md:translate-x-72"
  | "sm:translate-x-72"
  | "xs:translate-x-72"
  | "hover:translate-x-72"
  | "focus:translate-x-72"
  | "2xl:translate-x-80"
  | "1xl:translate-x-80"
  | "xl:translate-x-80"
  | "lg:translate-x-80"
  | "md:translate-x-80"
  | "sm:translate-x-80"
  | "xs:translate-x-80"
  | "hover:translate-x-80"
  | "focus:translate-x-80"
  | "2xl:translate-x-96"
  | "1xl:translate-x-96"
  | "xl:translate-x-96"
  | "lg:translate-x-96"
  | "md:translate-x-96"
  | "sm:translate-x-96"
  | "xs:translate-x-96"
  | "hover:translate-x-96"
  | "focus:translate-x-96"
  | "2xl:translate-x-px"
  | "1xl:translate-x-px"
  | "xl:translate-x-px"
  | "lg:translate-x-px"
  | "md:translate-x-px"
  | "sm:translate-x-px"
  | "xs:translate-x-px"
  | "hover:translate-x-px"
  | "focus:translate-x-px"
  | "2xl:translate-x-0.5"
  | "1xl:translate-x-0.5"
  | "xl:translate-x-0.5"
  | "lg:translate-x-0.5"
  | "md:translate-x-0.5"
  | "sm:translate-x-0.5"
  | "xs:translate-x-0.5"
  | "hover:translate-x-0.5"
  | "focus:translate-x-0.5"
  | "2xl:translate-x-1.5"
  | "1xl:translate-x-1.5"
  | "xl:translate-x-1.5"
  | "lg:translate-x-1.5"
  | "md:translate-x-1.5"
  | "sm:translate-x-1.5"
  | "xs:translate-x-1.5"
  | "hover:translate-x-1.5"
  | "focus:translate-x-1.5"
  | "2xl:translate-x-2.5"
  | "1xl:translate-x-2.5"
  | "xl:translate-x-2.5"
  | "lg:translate-x-2.5"
  | "md:translate-x-2.5"
  | "sm:translate-x-2.5"
  | "xs:translate-x-2.5"
  | "hover:translate-x-2.5"
  | "focus:translate-x-2.5"
  | "2xl:translate-x-3.5"
  | "1xl:translate-x-3.5"
  | "xl:translate-x-3.5"
  | "lg:translate-x-3.5"
  | "md:translate-x-3.5"
  | "sm:translate-x-3.5"
  | "xs:translate-x-3.5"
  | "hover:translate-x-3.5"
  | "focus:translate-x-3.5"
  | "2xl:-translate-x-0"
  | "1xl:-translate-x-0"
  | "xl:-translate-x-0"
  | "lg:-translate-x-0"
  | "md:-translate-x-0"
  | "sm:-translate-x-0"
  | "xs:-translate-x-0"
  | "hover:-translate-x-0"
  | "focus:-translate-x-0"
  | "2xl:-translate-x-1"
  | "1xl:-translate-x-1"
  | "xl:-translate-x-1"
  | "lg:-translate-x-1"
  | "md:-translate-x-1"
  | "sm:-translate-x-1"
  | "xs:-translate-x-1"
  | "hover:-translate-x-1"
  | "focus:-translate-x-1"
  | "2xl:-translate-x-2"
  | "1xl:-translate-x-2"
  | "xl:-translate-x-2"
  | "lg:-translate-x-2"
  | "md:-translate-x-2"
  | "sm:-translate-x-2"
  | "xs:-translate-x-2"
  | "hover:-translate-x-2"
  | "focus:-translate-x-2"
  | "2xl:-translate-x-3"
  | "1xl:-translate-x-3"
  | "xl:-translate-x-3"
  | "lg:-translate-x-3"
  | "md:-translate-x-3"
  | "sm:-translate-x-3"
  | "xs:-translate-x-3"
  | "hover:-translate-x-3"
  | "focus:-translate-x-3"
  | "2xl:-translate-x-4"
  | "1xl:-translate-x-4"
  | "xl:-translate-x-4"
  | "lg:-translate-x-4"
  | "md:-translate-x-4"
  | "sm:-translate-x-4"
  | "xs:-translate-x-4"
  | "hover:-translate-x-4"
  | "focus:-translate-x-4"
  | "2xl:-translate-x-5"
  | "1xl:-translate-x-5"
  | "xl:-translate-x-5"
  | "lg:-translate-x-5"
  | "md:-translate-x-5"
  | "sm:-translate-x-5"
  | "xs:-translate-x-5"
  | "hover:-translate-x-5"
  | "focus:-translate-x-5"
  | "2xl:-translate-x-6"
  | "1xl:-translate-x-6"
  | "xl:-translate-x-6"
  | "lg:-translate-x-6"
  | "md:-translate-x-6"
  | "sm:-translate-x-6"
  | "xs:-translate-x-6"
  | "hover:-translate-x-6"
  | "focus:-translate-x-6"
  | "2xl:-translate-x-7"
  | "1xl:-translate-x-7"
  | "xl:-translate-x-7"
  | "lg:-translate-x-7"
  | "md:-translate-x-7"
  | "sm:-translate-x-7"
  | "xs:-translate-x-7"
  | "hover:-translate-x-7"
  | "focus:-translate-x-7"
  | "2xl:-translate-x-8"
  | "1xl:-translate-x-8"
  | "xl:-translate-x-8"
  | "lg:-translate-x-8"
  | "md:-translate-x-8"
  | "sm:-translate-x-8"
  | "xs:-translate-x-8"
  | "hover:-translate-x-8"
  | "focus:-translate-x-8"
  | "2xl:-translate-x-9"
  | "1xl:-translate-x-9"
  | "xl:-translate-x-9"
  | "lg:-translate-x-9"
  | "md:-translate-x-9"
  | "sm:-translate-x-9"
  | "xs:-translate-x-9"
  | "hover:-translate-x-9"
  | "focus:-translate-x-9"
  | "2xl:-translate-x-10"
  | "1xl:-translate-x-10"
  | "xl:-translate-x-10"
  | "lg:-translate-x-10"
  | "md:-translate-x-10"
  | "sm:-translate-x-10"
  | "xs:-translate-x-10"
  | "hover:-translate-x-10"
  | "focus:-translate-x-10"
  | "2xl:-translate-x-11"
  | "1xl:-translate-x-11"
  | "xl:-translate-x-11"
  | "lg:-translate-x-11"
  | "md:-translate-x-11"
  | "sm:-translate-x-11"
  | "xs:-translate-x-11"
  | "hover:-translate-x-11"
  | "focus:-translate-x-11"
  | "2xl:-translate-x-12"
  | "1xl:-translate-x-12"
  | "xl:-translate-x-12"
  | "lg:-translate-x-12"
  | "md:-translate-x-12"
  | "sm:-translate-x-12"
  | "xs:-translate-x-12"
  | "hover:-translate-x-12"
  | "focus:-translate-x-12"
  | "2xl:-translate-x-14"
  | "1xl:-translate-x-14"
  | "xl:-translate-x-14"
  | "lg:-translate-x-14"
  | "md:-translate-x-14"
  | "sm:-translate-x-14"
  | "xs:-translate-x-14"
  | "hover:-translate-x-14"
  | "focus:-translate-x-14"
  | "2xl:-translate-x-16"
  | "1xl:-translate-x-16"
  | "xl:-translate-x-16"
  | "lg:-translate-x-16"
  | "md:-translate-x-16"
  | "sm:-translate-x-16"
  | "xs:-translate-x-16"
  | "hover:-translate-x-16"
  | "focus:-translate-x-16"
  | "2xl:-translate-x-20"
  | "1xl:-translate-x-20"
  | "xl:-translate-x-20"
  | "lg:-translate-x-20"
  | "md:-translate-x-20"
  | "sm:-translate-x-20"
  | "xs:-translate-x-20"
  | "hover:-translate-x-20"
  | "focus:-translate-x-20"
  | "2xl:-translate-x-24"
  | "1xl:-translate-x-24"
  | "xl:-translate-x-24"
  | "lg:-translate-x-24"
  | "md:-translate-x-24"
  | "sm:-translate-x-24"
  | "xs:-translate-x-24"
  | "hover:-translate-x-24"
  | "focus:-translate-x-24"
  | "2xl:-translate-x-28"
  | "1xl:-translate-x-28"
  | "xl:-translate-x-28"
  | "lg:-translate-x-28"
  | "md:-translate-x-28"
  | "sm:-translate-x-28"
  | "xs:-translate-x-28"
  | "hover:-translate-x-28"
  | "focus:-translate-x-28"
  | "2xl:-translate-x-32"
  | "1xl:-translate-x-32"
  | "xl:-translate-x-32"
  | "lg:-translate-x-32"
  | "md:-translate-x-32"
  | "sm:-translate-x-32"
  | "xs:-translate-x-32"
  | "hover:-translate-x-32"
  | "focus:-translate-x-32"
  | "2xl:-translate-x-36"
  | "1xl:-translate-x-36"
  | "xl:-translate-x-36"
  | "lg:-translate-x-36"
  | "md:-translate-x-36"
  | "sm:-translate-x-36"
  | "xs:-translate-x-36"
  | "hover:-translate-x-36"
  | "focus:-translate-x-36"
  | "2xl:-translate-x-40"
  | "1xl:-translate-x-40"
  | "xl:-translate-x-40"
  | "lg:-translate-x-40"
  | "md:-translate-x-40"
  | "sm:-translate-x-40"
  | "xs:-translate-x-40"
  | "hover:-translate-x-40"
  | "focus:-translate-x-40"
  | "2xl:-translate-x-44"
  | "1xl:-translate-x-44"
  | "xl:-translate-x-44"
  | "lg:-translate-x-44"
  | "md:-translate-x-44"
  | "sm:-translate-x-44"
  | "xs:-translate-x-44"
  | "hover:-translate-x-44"
  | "focus:-translate-x-44"
  | "2xl:-translate-x-48"
  | "1xl:-translate-x-48"
  | "xl:-translate-x-48"
  | "lg:-translate-x-48"
  | "md:-translate-x-48"
  | "sm:-translate-x-48"
  | "xs:-translate-x-48"
  | "hover:-translate-x-48"
  | "focus:-translate-x-48"
  | "2xl:-translate-x-52"
  | "1xl:-translate-x-52"
  | "xl:-translate-x-52"
  | "lg:-translate-x-52"
  | "md:-translate-x-52"
  | "sm:-translate-x-52"
  | "xs:-translate-x-52"
  | "hover:-translate-x-52"
  | "focus:-translate-x-52"
  | "2xl:-translate-x-56"
  | "1xl:-translate-x-56"
  | "xl:-translate-x-56"
  | "lg:-translate-x-56"
  | "md:-translate-x-56"
  | "sm:-translate-x-56"
  | "xs:-translate-x-56"
  | "hover:-translate-x-56"
  | "focus:-translate-x-56"
  | "2xl:-translate-x-60"
  | "1xl:-translate-x-60"
  | "xl:-translate-x-60"
  | "lg:-translate-x-60"
  | "md:-translate-x-60"
  | "sm:-translate-x-60"
  | "xs:-translate-x-60"
  | "hover:-translate-x-60"
  | "focus:-translate-x-60"
  | "2xl:-translate-x-64"
  | "1xl:-translate-x-64"
  | "xl:-translate-x-64"
  | "lg:-translate-x-64"
  | "md:-translate-x-64"
  | "sm:-translate-x-64"
  | "xs:-translate-x-64"
  | "hover:-translate-x-64"
  | "focus:-translate-x-64"
  | "2xl:-translate-x-72"
  | "1xl:-translate-x-72"
  | "xl:-translate-x-72"
  | "lg:-translate-x-72"
  | "md:-translate-x-72"
  | "sm:-translate-x-72"
  | "xs:-translate-x-72"
  | "hover:-translate-x-72"
  | "focus:-translate-x-72"
  | "2xl:-translate-x-80"
  | "1xl:-translate-x-80"
  | "xl:-translate-x-80"
  | "lg:-translate-x-80"
  | "md:-translate-x-80"
  | "sm:-translate-x-80"
  | "xs:-translate-x-80"
  | "hover:-translate-x-80"
  | "focus:-translate-x-80"
  | "2xl:-translate-x-96"
  | "1xl:-translate-x-96"
  | "xl:-translate-x-96"
  | "lg:-translate-x-96"
  | "md:-translate-x-96"
  | "sm:-translate-x-96"
  | "xs:-translate-x-96"
  | "hover:-translate-x-96"
  | "focus:-translate-x-96"
  | "2xl:-translate-x-px"
  | "1xl:-translate-x-px"
  | "xl:-translate-x-px"
  | "lg:-translate-x-px"
  | "md:-translate-x-px"
  | "sm:-translate-x-px"
  | "xs:-translate-x-px"
  | "hover:-translate-x-px"
  | "focus:-translate-x-px"
  | "2xl:-translate-x-0.5"
  | "1xl:-translate-x-0.5"
  | "xl:-translate-x-0.5"
  | "lg:-translate-x-0.5"
  | "md:-translate-x-0.5"
  | "sm:-translate-x-0.5"
  | "xs:-translate-x-0.5"
  | "hover:-translate-x-0.5"
  | "focus:-translate-x-0.5"
  | "2xl:-translate-x-1.5"
  | "1xl:-translate-x-1.5"
  | "xl:-translate-x-1.5"
  | "lg:-translate-x-1.5"
  | "md:-translate-x-1.5"
  | "sm:-translate-x-1.5"
  | "xs:-translate-x-1.5"
  | "hover:-translate-x-1.5"
  | "focus:-translate-x-1.5"
  | "2xl:-translate-x-2.5"
  | "1xl:-translate-x-2.5"
  | "xl:-translate-x-2.5"
  | "lg:-translate-x-2.5"
  | "md:-translate-x-2.5"
  | "sm:-translate-x-2.5"
  | "xs:-translate-x-2.5"
  | "hover:-translate-x-2.5"
  | "focus:-translate-x-2.5"
  | "2xl:-translate-x-3.5"
  | "1xl:-translate-x-3.5"
  | "xl:-translate-x-3.5"
  | "lg:-translate-x-3.5"
  | "md:-translate-x-3.5"
  | "sm:-translate-x-3.5"
  | "xs:-translate-x-3.5"
  | "hover:-translate-x-3.5"
  | "focus:-translate-x-3.5"
  | "2xl:translate-x-1/2"
  | "1xl:translate-x-1/2"
  | "xl:translate-x-1/2"
  | "lg:translate-x-1/2"
  | "md:translate-x-1/2"
  | "sm:translate-x-1/2"
  | "xs:translate-x-1/2"
  | "hover:translate-x-1/2"
  | "focus:translate-x-1/2"
  | "2xl:translate-x-1/3"
  | "1xl:translate-x-1/3"
  | "xl:translate-x-1/3"
  | "lg:translate-x-1/3"
  | "md:translate-x-1/3"
  | "sm:translate-x-1/3"
  | "xs:translate-x-1/3"
  | "hover:translate-x-1/3"
  | "focus:translate-x-1/3"
  | "2xl:translate-x-2/3"
  | "1xl:translate-x-2/3"
  | "xl:translate-x-2/3"
  | "lg:translate-x-2/3"
  | "md:translate-x-2/3"
  | "sm:translate-x-2/3"
  | "xs:translate-x-2/3"
  | "hover:translate-x-2/3"
  | "focus:translate-x-2/3"
  | "2xl:translate-x-1/4"
  | "1xl:translate-x-1/4"
  | "xl:translate-x-1/4"
  | "lg:translate-x-1/4"
  | "md:translate-x-1/4"
  | "sm:translate-x-1/4"
  | "xs:translate-x-1/4"
  | "hover:translate-x-1/4"
  | "focus:translate-x-1/4"
  | "2xl:translate-x-2/4"
  | "1xl:translate-x-2/4"
  | "xl:translate-x-2/4"
  | "lg:translate-x-2/4"
  | "md:translate-x-2/4"
  | "sm:translate-x-2/4"
  | "xs:translate-x-2/4"
  | "hover:translate-x-2/4"
  | "focus:translate-x-2/4"
  | "2xl:translate-x-3/4"
  | "1xl:translate-x-3/4"
  | "xl:translate-x-3/4"
  | "lg:translate-x-3/4"
  | "md:translate-x-3/4"
  | "sm:translate-x-3/4"
  | "xs:translate-x-3/4"
  | "hover:translate-x-3/4"
  | "focus:translate-x-3/4"
  | "2xl:translate-x-full"
  | "1xl:translate-x-full"
  | "xl:translate-x-full"
  | "lg:translate-x-full"
  | "md:translate-x-full"
  | "sm:translate-x-full"
  | "xs:translate-x-full"
  | "hover:translate-x-full"
  | "focus:translate-x-full"
  | "2xl:-translate-x-1/2"
  | "1xl:-translate-x-1/2"
  | "xl:-translate-x-1/2"
  | "lg:-translate-x-1/2"
  | "md:-translate-x-1/2"
  | "sm:-translate-x-1/2"
  | "xs:-translate-x-1/2"
  | "hover:-translate-x-1/2"
  | "focus:-translate-x-1/2"
  | "2xl:-translate-x-1/3"
  | "1xl:-translate-x-1/3"
  | "xl:-translate-x-1/3"
  | "lg:-translate-x-1/3"
  | "md:-translate-x-1/3"
  | "sm:-translate-x-1/3"
  | "xs:-translate-x-1/3"
  | "hover:-translate-x-1/3"
  | "focus:-translate-x-1/3"
  | "2xl:-translate-x-2/3"
  | "1xl:-translate-x-2/3"
  | "xl:-translate-x-2/3"
  | "lg:-translate-x-2/3"
  | "md:-translate-x-2/3"
  | "sm:-translate-x-2/3"
  | "xs:-translate-x-2/3"
  | "hover:-translate-x-2/3"
  | "focus:-translate-x-2/3"
  | "2xl:-translate-x-1/4"
  | "1xl:-translate-x-1/4"
  | "xl:-translate-x-1/4"
  | "lg:-translate-x-1/4"
  | "md:-translate-x-1/4"
  | "sm:-translate-x-1/4"
  | "xs:-translate-x-1/4"
  | "hover:-translate-x-1/4"
  | "focus:-translate-x-1/4"
  | "2xl:-translate-x-2/4"
  | "1xl:-translate-x-2/4"
  | "xl:-translate-x-2/4"
  | "lg:-translate-x-2/4"
  | "md:-translate-x-2/4"
  | "sm:-translate-x-2/4"
  | "xs:-translate-x-2/4"
  | "hover:-translate-x-2/4"
  | "focus:-translate-x-2/4"
  | "2xl:-translate-x-3/4"
  | "1xl:-translate-x-3/4"
  | "xl:-translate-x-3/4"
  | "lg:-translate-x-3/4"
  | "md:-translate-x-3/4"
  | "sm:-translate-x-3/4"
  | "xs:-translate-x-3/4"
  | "hover:-translate-x-3/4"
  | "focus:-translate-x-3/4"
  | "2xl:-translate-x-full"
  | "1xl:-translate-x-full"
  | "xl:-translate-x-full"
  | "lg:-translate-x-full"
  | "md:-translate-x-full"
  | "sm:-translate-x-full"
  | "xs:-translate-x-full"
  | "hover:-translate-x-full"
  | "focus:-translate-x-full"
  | "2xl:translate-y-0"
  | "1xl:translate-y-0"
  | "xl:translate-y-0"
  | "lg:translate-y-0"
  | "md:translate-y-0"
  | "sm:translate-y-0"
  | "xs:translate-y-0"
  | "hover:translate-y-0"
  | "focus:translate-y-0"
  | "2xl:translate-y-1"
  | "1xl:translate-y-1"
  | "xl:translate-y-1"
  | "lg:translate-y-1"
  | "md:translate-y-1"
  | "sm:translate-y-1"
  | "xs:translate-y-1"
  | "hover:translate-y-1"
  | "focus:translate-y-1"
  | "2xl:translate-y-2"
  | "1xl:translate-y-2"
  | "xl:translate-y-2"
  | "lg:translate-y-2"
  | "md:translate-y-2"
  | "sm:translate-y-2"
  | "xs:translate-y-2"
  | "hover:translate-y-2"
  | "focus:translate-y-2"
  | "2xl:translate-y-3"
  | "1xl:translate-y-3"
  | "xl:translate-y-3"
  | "lg:translate-y-3"
  | "md:translate-y-3"
  | "sm:translate-y-3"
  | "xs:translate-y-3"
  | "hover:translate-y-3"
  | "focus:translate-y-3"
  | "2xl:translate-y-4"
  | "1xl:translate-y-4"
  | "xl:translate-y-4"
  | "lg:translate-y-4"
  | "md:translate-y-4"
  | "sm:translate-y-4"
  | "xs:translate-y-4"
  | "hover:translate-y-4"
  | "focus:translate-y-4"
  | "2xl:translate-y-5"
  | "1xl:translate-y-5"
  | "xl:translate-y-5"
  | "lg:translate-y-5"
  | "md:translate-y-5"
  | "sm:translate-y-5"
  | "xs:translate-y-5"
  | "hover:translate-y-5"
  | "focus:translate-y-5"
  | "2xl:translate-y-6"
  | "1xl:translate-y-6"
  | "xl:translate-y-6"
  | "lg:translate-y-6"
  | "md:translate-y-6"
  | "sm:translate-y-6"
  | "xs:translate-y-6"
  | "hover:translate-y-6"
  | "focus:translate-y-6"
  | "2xl:translate-y-7"
  | "1xl:translate-y-7"
  | "xl:translate-y-7"
  | "lg:translate-y-7"
  | "md:translate-y-7"
  | "sm:translate-y-7"
  | "xs:translate-y-7"
  | "hover:translate-y-7"
  | "focus:translate-y-7"
  | "2xl:translate-y-8"
  | "1xl:translate-y-8"
  | "xl:translate-y-8"
  | "lg:translate-y-8"
  | "md:translate-y-8"
  | "sm:translate-y-8"
  | "xs:translate-y-8"
  | "hover:translate-y-8"
  | "focus:translate-y-8"
  | "2xl:translate-y-9"
  | "1xl:translate-y-9"
  | "xl:translate-y-9"
  | "lg:translate-y-9"
  | "md:translate-y-9"
  | "sm:translate-y-9"
  | "xs:translate-y-9"
  | "hover:translate-y-9"
  | "focus:translate-y-9"
  | "2xl:translate-y-10"
  | "1xl:translate-y-10"
  | "xl:translate-y-10"
  | "lg:translate-y-10"
  | "md:translate-y-10"
  | "sm:translate-y-10"
  | "xs:translate-y-10"
  | "hover:translate-y-10"
  | "focus:translate-y-10"
  | "2xl:translate-y-11"
  | "1xl:translate-y-11"
  | "xl:translate-y-11"
  | "lg:translate-y-11"
  | "md:translate-y-11"
  | "sm:translate-y-11"
  | "xs:translate-y-11"
  | "hover:translate-y-11"
  | "focus:translate-y-11"
  | "2xl:translate-y-12"
  | "1xl:translate-y-12"
  | "xl:translate-y-12"
  | "lg:translate-y-12"
  | "md:translate-y-12"
  | "sm:translate-y-12"
  | "xs:translate-y-12"
  | "hover:translate-y-12"
  | "focus:translate-y-12"
  | "2xl:translate-y-14"
  | "1xl:translate-y-14"
  | "xl:translate-y-14"
  | "lg:translate-y-14"
  | "md:translate-y-14"
  | "sm:translate-y-14"
  | "xs:translate-y-14"
  | "hover:translate-y-14"
  | "focus:translate-y-14"
  | "2xl:translate-y-16"
  | "1xl:translate-y-16"
  | "xl:translate-y-16"
  | "lg:translate-y-16"
  | "md:translate-y-16"
  | "sm:translate-y-16"
  | "xs:translate-y-16"
  | "hover:translate-y-16"
  | "focus:translate-y-16"
  | "2xl:translate-y-20"
  | "1xl:translate-y-20"
  | "xl:translate-y-20"
  | "lg:translate-y-20"
  | "md:translate-y-20"
  | "sm:translate-y-20"
  | "xs:translate-y-20"
  | "hover:translate-y-20"
  | "focus:translate-y-20"
  | "2xl:translate-y-24"
  | "1xl:translate-y-24"
  | "xl:translate-y-24"
  | "lg:translate-y-24"
  | "md:translate-y-24"
  | "sm:translate-y-24"
  | "xs:translate-y-24"
  | "hover:translate-y-24"
  | "focus:translate-y-24"
  | "2xl:translate-y-28"
  | "1xl:translate-y-28"
  | "xl:translate-y-28"
  | "lg:translate-y-28"
  | "md:translate-y-28"
  | "sm:translate-y-28"
  | "xs:translate-y-28"
  | "hover:translate-y-28"
  | "focus:translate-y-28"
  | "2xl:translate-y-32"
  | "1xl:translate-y-32"
  | "xl:translate-y-32"
  | "lg:translate-y-32"
  | "md:translate-y-32"
  | "sm:translate-y-32"
  | "xs:translate-y-32"
  | "hover:translate-y-32"
  | "focus:translate-y-32"
  | "2xl:translate-y-36"
  | "1xl:translate-y-36"
  | "xl:translate-y-36"
  | "lg:translate-y-36"
  | "md:translate-y-36"
  | "sm:translate-y-36"
  | "xs:translate-y-36"
  | "hover:translate-y-36"
  | "focus:translate-y-36"
  | "2xl:translate-y-40"
  | "1xl:translate-y-40"
  | "xl:translate-y-40"
  | "lg:translate-y-40"
  | "md:translate-y-40"
  | "sm:translate-y-40"
  | "xs:translate-y-40"
  | "hover:translate-y-40"
  | "focus:translate-y-40"
  | "2xl:translate-y-44"
  | "1xl:translate-y-44"
  | "xl:translate-y-44"
  | "lg:translate-y-44"
  | "md:translate-y-44"
  | "sm:translate-y-44"
  | "xs:translate-y-44"
  | "hover:translate-y-44"
  | "focus:translate-y-44"
  | "2xl:translate-y-48"
  | "1xl:translate-y-48"
  | "xl:translate-y-48"
  | "lg:translate-y-48"
  | "md:translate-y-48"
  | "sm:translate-y-48"
  | "xs:translate-y-48"
  | "hover:translate-y-48"
  | "focus:translate-y-48"
  | "2xl:translate-y-52"
  | "1xl:translate-y-52"
  | "xl:translate-y-52"
  | "lg:translate-y-52"
  | "md:translate-y-52"
  | "sm:translate-y-52"
  | "xs:translate-y-52"
  | "hover:translate-y-52"
  | "focus:translate-y-52"
  | "2xl:translate-y-56"
  | "1xl:translate-y-56"
  | "xl:translate-y-56"
  | "lg:translate-y-56"
  | "md:translate-y-56"
  | "sm:translate-y-56"
  | "xs:translate-y-56"
  | "hover:translate-y-56"
  | "focus:translate-y-56"
  | "2xl:translate-y-60"
  | "1xl:translate-y-60"
  | "xl:translate-y-60"
  | "lg:translate-y-60"
  | "md:translate-y-60"
  | "sm:translate-y-60"
  | "xs:translate-y-60"
  | "hover:translate-y-60"
  | "focus:translate-y-60"
  | "2xl:translate-y-64"
  | "1xl:translate-y-64"
  | "xl:translate-y-64"
  | "lg:translate-y-64"
  | "md:translate-y-64"
  | "sm:translate-y-64"
  | "xs:translate-y-64"
  | "hover:translate-y-64"
  | "focus:translate-y-64"
  | "2xl:translate-y-72"
  | "1xl:translate-y-72"
  | "xl:translate-y-72"
  | "lg:translate-y-72"
  | "md:translate-y-72"
  | "sm:translate-y-72"
  | "xs:translate-y-72"
  | "hover:translate-y-72"
  | "focus:translate-y-72"
  | "2xl:translate-y-80"
  | "1xl:translate-y-80"
  | "xl:translate-y-80"
  | "lg:translate-y-80"
  | "md:translate-y-80"
  | "sm:translate-y-80"
  | "xs:translate-y-80"
  | "hover:translate-y-80"
  | "focus:translate-y-80"
  | "2xl:translate-y-96"
  | "1xl:translate-y-96"
  | "xl:translate-y-96"
  | "lg:translate-y-96"
  | "md:translate-y-96"
  | "sm:translate-y-96"
  | "xs:translate-y-96"
  | "hover:translate-y-96"
  | "focus:translate-y-96"
  | "2xl:translate-y-px"
  | "1xl:translate-y-px"
  | "xl:translate-y-px"
  | "lg:translate-y-px"
  | "md:translate-y-px"
  | "sm:translate-y-px"
  | "xs:translate-y-px"
  | "hover:translate-y-px"
  | "focus:translate-y-px"
  | "2xl:translate-y-0.5"
  | "1xl:translate-y-0.5"
  | "xl:translate-y-0.5"
  | "lg:translate-y-0.5"
  | "md:translate-y-0.5"
  | "sm:translate-y-0.5"
  | "xs:translate-y-0.5"
  | "hover:translate-y-0.5"
  | "focus:translate-y-0.5"
  | "2xl:translate-y-1.5"
  | "1xl:translate-y-1.5"
  | "xl:translate-y-1.5"
  | "lg:translate-y-1.5"
  | "md:translate-y-1.5"
  | "sm:translate-y-1.5"
  | "xs:translate-y-1.5"
  | "hover:translate-y-1.5"
  | "focus:translate-y-1.5"
  | "2xl:translate-y-2.5"
  | "1xl:translate-y-2.5"
  | "xl:translate-y-2.5"
  | "lg:translate-y-2.5"
  | "md:translate-y-2.5"
  | "sm:translate-y-2.5"
  | "xs:translate-y-2.5"
  | "hover:translate-y-2.5"
  | "focus:translate-y-2.5"
  | "2xl:translate-y-3.5"
  | "1xl:translate-y-3.5"
  | "xl:translate-y-3.5"
  | "lg:translate-y-3.5"
  | "md:translate-y-3.5"
  | "sm:translate-y-3.5"
  | "xs:translate-y-3.5"
  | "hover:translate-y-3.5"
  | "focus:translate-y-3.5"
  | "2xl:-translate-y-0"
  | "1xl:-translate-y-0"
  | "xl:-translate-y-0"
  | "lg:-translate-y-0"
  | "md:-translate-y-0"
  | "sm:-translate-y-0"
  | "xs:-translate-y-0"
  | "hover:-translate-y-0"
  | "focus:-translate-y-0"
  | "2xl:-translate-y-1"
  | "1xl:-translate-y-1"
  | "xl:-translate-y-1"
  | "lg:-translate-y-1"
  | "md:-translate-y-1"
  | "sm:-translate-y-1"
  | "xs:-translate-y-1"
  | "hover:-translate-y-1"
  | "focus:-translate-y-1"
  | "2xl:-translate-y-2"
  | "1xl:-translate-y-2"
  | "xl:-translate-y-2"
  | "lg:-translate-y-2"
  | "md:-translate-y-2"
  | "sm:-translate-y-2"
  | "xs:-translate-y-2"
  | "hover:-translate-y-2"
  | "focus:-translate-y-2"
  | "2xl:-translate-y-3"
  | "1xl:-translate-y-3"
  | "xl:-translate-y-3"
  | "lg:-translate-y-3"
  | "md:-translate-y-3"
  | "sm:-translate-y-3"
  | "xs:-translate-y-3"
  | "hover:-translate-y-3"
  | "focus:-translate-y-3"
  | "2xl:-translate-y-4"
  | "1xl:-translate-y-4"
  | "xl:-translate-y-4"
  | "lg:-translate-y-4"
  | "md:-translate-y-4"
  | "sm:-translate-y-4"
  | "xs:-translate-y-4"
  | "hover:-translate-y-4"
  | "focus:-translate-y-4"
  | "2xl:-translate-y-5"
  | "1xl:-translate-y-5"
  | "xl:-translate-y-5"
  | "lg:-translate-y-5"
  | "md:-translate-y-5"
  | "sm:-translate-y-5"
  | "xs:-translate-y-5"
  | "hover:-translate-y-5"
  | "focus:-translate-y-5"
  | "2xl:-translate-y-6"
  | "1xl:-translate-y-6"
  | "xl:-translate-y-6"
  | "lg:-translate-y-6"
  | "md:-translate-y-6"
  | "sm:-translate-y-6"
  | "xs:-translate-y-6"
  | "hover:-translate-y-6"
  | "focus:-translate-y-6"
  | "2xl:-translate-y-7"
  | "1xl:-translate-y-7"
  | "xl:-translate-y-7"
  | "lg:-translate-y-7"
  | "md:-translate-y-7"
  | "sm:-translate-y-7"
  | "xs:-translate-y-7"
  | "hover:-translate-y-7"
  | "focus:-translate-y-7"
  | "2xl:-translate-y-8"
  | "1xl:-translate-y-8"
  | "xl:-translate-y-8"
  | "lg:-translate-y-8"
  | "md:-translate-y-8"
  | "sm:-translate-y-8"
  | "xs:-translate-y-8"
  | "hover:-translate-y-8"
  | "focus:-translate-y-8"
  | "2xl:-translate-y-9"
  | "1xl:-translate-y-9"
  | "xl:-translate-y-9"
  | "lg:-translate-y-9"
  | "md:-translate-y-9"
  | "sm:-translate-y-9"
  | "xs:-translate-y-9"
  | "hover:-translate-y-9"
  | "focus:-translate-y-9"
  | "2xl:-translate-y-10"
  | "1xl:-translate-y-10"
  | "xl:-translate-y-10"
  | "lg:-translate-y-10"
  | "md:-translate-y-10"
  | "sm:-translate-y-10"
  | "xs:-translate-y-10"
  | "hover:-translate-y-10"
  | "focus:-translate-y-10"
  | "2xl:-translate-y-11"
  | "1xl:-translate-y-11"
  | "xl:-translate-y-11"
  | "lg:-translate-y-11"
  | "md:-translate-y-11"
  | "sm:-translate-y-11"
  | "xs:-translate-y-11"
  | "hover:-translate-y-11"
  | "focus:-translate-y-11"
  | "2xl:-translate-y-12"
  | "1xl:-translate-y-12"
  | "xl:-translate-y-12"
  | "lg:-translate-y-12"
  | "md:-translate-y-12"
  | "sm:-translate-y-12"
  | "xs:-translate-y-12"
  | "hover:-translate-y-12"
  | "focus:-translate-y-12"
  | "2xl:-translate-y-14"
  | "1xl:-translate-y-14"
  | "xl:-translate-y-14"
  | "lg:-translate-y-14"
  | "md:-translate-y-14"
  | "sm:-translate-y-14"
  | "xs:-translate-y-14"
  | "hover:-translate-y-14"
  | "focus:-translate-y-14"
  | "2xl:-translate-y-16"
  | "1xl:-translate-y-16"
  | "xl:-translate-y-16"
  | "lg:-translate-y-16"
  | "md:-translate-y-16"
  | "sm:-translate-y-16"
  | "xs:-translate-y-16"
  | "hover:-translate-y-16"
  | "focus:-translate-y-16"
  | "2xl:-translate-y-20"
  | "1xl:-translate-y-20"
  | "xl:-translate-y-20"
  | "lg:-translate-y-20"
  | "md:-translate-y-20"
  | "sm:-translate-y-20"
  | "xs:-translate-y-20"
  | "hover:-translate-y-20"
  | "focus:-translate-y-20"
  | "2xl:-translate-y-24"
  | "1xl:-translate-y-24"
  | "xl:-translate-y-24"
  | "lg:-translate-y-24"
  | "md:-translate-y-24"
  | "sm:-translate-y-24"
  | "xs:-translate-y-24"
  | "hover:-translate-y-24"
  | "focus:-translate-y-24"
  | "2xl:-translate-y-28"
  | "1xl:-translate-y-28"
  | "xl:-translate-y-28"
  | "lg:-translate-y-28"
  | "md:-translate-y-28"
  | "sm:-translate-y-28"
  | "xs:-translate-y-28"
  | "hover:-translate-y-28"
  | "focus:-translate-y-28"
  | "2xl:-translate-y-32"
  | "1xl:-translate-y-32"
  | "xl:-translate-y-32"
  | "lg:-translate-y-32"
  | "md:-translate-y-32"
  | "sm:-translate-y-32"
  | "xs:-translate-y-32"
  | "hover:-translate-y-32"
  | "focus:-translate-y-32"
  | "2xl:-translate-y-36"
  | "1xl:-translate-y-36"
  | "xl:-translate-y-36"
  | "lg:-translate-y-36"
  | "md:-translate-y-36"
  | "sm:-translate-y-36"
  | "xs:-translate-y-36"
  | "hover:-translate-y-36"
  | "focus:-translate-y-36"
  | "2xl:-translate-y-40"
  | "1xl:-translate-y-40"
  | "xl:-translate-y-40"
  | "lg:-translate-y-40"
  | "md:-translate-y-40"
  | "sm:-translate-y-40"
  | "xs:-translate-y-40"
  | "hover:-translate-y-40"
  | "focus:-translate-y-40"
  | "2xl:-translate-y-44"
  | "1xl:-translate-y-44"
  | "xl:-translate-y-44"
  | "lg:-translate-y-44"
  | "md:-translate-y-44"
  | "sm:-translate-y-44"
  | "xs:-translate-y-44"
  | "hover:-translate-y-44"
  | "focus:-translate-y-44"
  | "2xl:-translate-y-48"
  | "1xl:-translate-y-48"
  | "xl:-translate-y-48"
  | "lg:-translate-y-48"
  | "md:-translate-y-48"
  | "sm:-translate-y-48"
  | "xs:-translate-y-48"
  | "hover:-translate-y-48"
  | "focus:-translate-y-48"
  | "2xl:-translate-y-52"
  | "1xl:-translate-y-52"
  | "xl:-translate-y-52"
  | "lg:-translate-y-52"
  | "md:-translate-y-52"
  | "sm:-translate-y-52"
  | "xs:-translate-y-52"
  | "hover:-translate-y-52"
  | "focus:-translate-y-52"
  | "2xl:-translate-y-56"
  | "1xl:-translate-y-56"
  | "xl:-translate-y-56"
  | "lg:-translate-y-56"
  | "md:-translate-y-56"
  | "sm:-translate-y-56"
  | "xs:-translate-y-56"
  | "hover:-translate-y-56"
  | "focus:-translate-y-56"
  | "2xl:-translate-y-60"
  | "1xl:-translate-y-60"
  | "xl:-translate-y-60"
  | "lg:-translate-y-60"
  | "md:-translate-y-60"
  | "sm:-translate-y-60"
  | "xs:-translate-y-60"
  | "hover:-translate-y-60"
  | "focus:-translate-y-60"
  | "2xl:-translate-y-64"
  | "1xl:-translate-y-64"
  | "xl:-translate-y-64"
  | "lg:-translate-y-64"
  | "md:-translate-y-64"
  | "sm:-translate-y-64"
  | "xs:-translate-y-64"
  | "hover:-translate-y-64"
  | "focus:-translate-y-64"
  | "2xl:-translate-y-72"
  | "1xl:-translate-y-72"
  | "xl:-translate-y-72"
  | "lg:-translate-y-72"
  | "md:-translate-y-72"
  | "sm:-translate-y-72"
  | "xs:-translate-y-72"
  | "hover:-translate-y-72"
  | "focus:-translate-y-72"
  | "2xl:-translate-y-80"
  | "1xl:-translate-y-80"
  | "xl:-translate-y-80"
  | "lg:-translate-y-80"
  | "md:-translate-y-80"
  | "sm:-translate-y-80"
  | "xs:-translate-y-80"
  | "hover:-translate-y-80"
  | "focus:-translate-y-80"
  | "2xl:-translate-y-96"
  | "1xl:-translate-y-96"
  | "xl:-translate-y-96"
  | "lg:-translate-y-96"
  | "md:-translate-y-96"
  | "sm:-translate-y-96"
  | "xs:-translate-y-96"
  | "hover:-translate-y-96"
  | "focus:-translate-y-96"
  | "2xl:-translate-y-px"
  | "1xl:-translate-y-px"
  | "xl:-translate-y-px"
  | "lg:-translate-y-px"
  | "md:-translate-y-px"
  | "sm:-translate-y-px"
  | "xs:-translate-y-px"
  | "hover:-translate-y-px"
  | "focus:-translate-y-px"
  | "2xl:-translate-y-0.5"
  | "1xl:-translate-y-0.5"
  | "xl:-translate-y-0.5"
  | "lg:-translate-y-0.5"
  | "md:-translate-y-0.5"
  | "sm:-translate-y-0.5"
  | "xs:-translate-y-0.5"
  | "hover:-translate-y-0.5"
  | "focus:-translate-y-0.5"
  | "2xl:-translate-y-1.5"
  | "1xl:-translate-y-1.5"
  | "xl:-translate-y-1.5"
  | "lg:-translate-y-1.5"
  | "md:-translate-y-1.5"
  | "sm:-translate-y-1.5"
  | "xs:-translate-y-1.5"
  | "hover:-translate-y-1.5"
  | "focus:-translate-y-1.5"
  | "2xl:-translate-y-2.5"
  | "1xl:-translate-y-2.5"
  | "xl:-translate-y-2.5"
  | "lg:-translate-y-2.5"
  | "md:-translate-y-2.5"
  | "sm:-translate-y-2.5"
  | "xs:-translate-y-2.5"
  | "hover:-translate-y-2.5"
  | "focus:-translate-y-2.5"
  | "2xl:-translate-y-3.5"
  | "1xl:-translate-y-3.5"
  | "xl:-translate-y-3.5"
  | "lg:-translate-y-3.5"
  | "md:-translate-y-3.5"
  | "sm:-translate-y-3.5"
  | "xs:-translate-y-3.5"
  | "hover:-translate-y-3.5"
  | "focus:-translate-y-3.5"
  | "2xl:translate-y-1/2"
  | "1xl:translate-y-1/2"
  | "xl:translate-y-1/2"
  | "lg:translate-y-1/2"
  | "md:translate-y-1/2"
  | "sm:translate-y-1/2"
  | "xs:translate-y-1/2"
  | "hover:translate-y-1/2"
  | "focus:translate-y-1/2"
  | "2xl:translate-y-1/3"
  | "1xl:translate-y-1/3"
  | "xl:translate-y-1/3"
  | "lg:translate-y-1/3"
  | "md:translate-y-1/3"
  | "sm:translate-y-1/3"
  | "xs:translate-y-1/3"
  | "hover:translate-y-1/3"
  | "focus:translate-y-1/3"
  | "2xl:translate-y-2/3"
  | "1xl:translate-y-2/3"
  | "xl:translate-y-2/3"
  | "lg:translate-y-2/3"
  | "md:translate-y-2/3"
  | "sm:translate-y-2/3"
  | "xs:translate-y-2/3"
  | "hover:translate-y-2/3"
  | "focus:translate-y-2/3"
  | "2xl:translate-y-1/4"
  | "1xl:translate-y-1/4"
  | "xl:translate-y-1/4"
  | "lg:translate-y-1/4"
  | "md:translate-y-1/4"
  | "sm:translate-y-1/4"
  | "xs:translate-y-1/4"
  | "hover:translate-y-1/4"
  | "focus:translate-y-1/4"
  | "2xl:translate-y-2/4"
  | "1xl:translate-y-2/4"
  | "xl:translate-y-2/4"
  | "lg:translate-y-2/4"
  | "md:translate-y-2/4"
  | "sm:translate-y-2/4"
  | "xs:translate-y-2/4"
  | "hover:translate-y-2/4"
  | "focus:translate-y-2/4"
  | "2xl:translate-y-3/4"
  | "1xl:translate-y-3/4"
  | "xl:translate-y-3/4"
  | "lg:translate-y-3/4"
  | "md:translate-y-3/4"
  | "sm:translate-y-3/4"
  | "xs:translate-y-3/4"
  | "hover:translate-y-3/4"
  | "focus:translate-y-3/4"
  | "2xl:translate-y-full"
  | "1xl:translate-y-full"
  | "xl:translate-y-full"
  | "lg:translate-y-full"
  | "md:translate-y-full"
  | "sm:translate-y-full"
  | "xs:translate-y-full"
  | "hover:translate-y-full"
  | "focus:translate-y-full"
  | "2xl:-translate-y-1/2"
  | "1xl:-translate-y-1/2"
  | "xl:-translate-y-1/2"
  | "lg:-translate-y-1/2"
  | "md:-translate-y-1/2"
  | "sm:-translate-y-1/2"
  | "xs:-translate-y-1/2"
  | "hover:-translate-y-1/2"
  | "focus:-translate-y-1/2"
  | "2xl:-translate-y-1/3"
  | "1xl:-translate-y-1/3"
  | "xl:-translate-y-1/3"
  | "lg:-translate-y-1/3"
  | "md:-translate-y-1/3"
  | "sm:-translate-y-1/3"
  | "xs:-translate-y-1/3"
  | "hover:-translate-y-1/3"
  | "focus:-translate-y-1/3"
  | "2xl:-translate-y-2/3"
  | "1xl:-translate-y-2/3"
  | "xl:-translate-y-2/3"
  | "lg:-translate-y-2/3"
  | "md:-translate-y-2/3"
  | "sm:-translate-y-2/3"
  | "xs:-translate-y-2/3"
  | "hover:-translate-y-2/3"
  | "focus:-translate-y-2/3"
  | "2xl:-translate-y-1/4"
  | "1xl:-translate-y-1/4"
  | "xl:-translate-y-1/4"
  | "lg:-translate-y-1/4"
  | "md:-translate-y-1/4"
  | "sm:-translate-y-1/4"
  | "xs:-translate-y-1/4"
  | "hover:-translate-y-1/4"
  | "focus:-translate-y-1/4"
  | "2xl:-translate-y-2/4"
  | "1xl:-translate-y-2/4"
  | "xl:-translate-y-2/4"
  | "lg:-translate-y-2/4"
  | "md:-translate-y-2/4"
  | "sm:-translate-y-2/4"
  | "xs:-translate-y-2/4"
  | "hover:-translate-y-2/4"
  | "focus:-translate-y-2/4"
  | "2xl:-translate-y-3/4"
  | "1xl:-translate-y-3/4"
  | "xl:-translate-y-3/4"
  | "lg:-translate-y-3/4"
  | "md:-translate-y-3/4"
  | "sm:-translate-y-3/4"
  | "xs:-translate-y-3/4"
  | "hover:-translate-y-3/4"
  | "focus:-translate-y-3/4"
  | "2xl:-translate-y-full"
  | "1xl:-translate-y-full"
  | "xl:-translate-y-full"
  | "lg:-translate-y-full"
  | "md:-translate-y-full"
  | "sm:-translate-y-full"
  | "xs:-translate-y-full"
  | "hover:-translate-y-full"
  | "focus:-translate-y-full"
  | "2xl:select-none"
  | "1xl:select-none"
  | "xl:select-none"
  | "lg:select-none"
  | "md:select-none"
  | "sm:select-none"
  | "xs:select-none"
  | "2xl:select-text"
  | "1xl:select-text"
  | "xl:select-text"
  | "lg:select-text"
  | "md:select-text"
  | "sm:select-text"
  | "xs:select-text"
  | "2xl:select-all"
  | "1xl:select-all"
  | "xl:select-all"
  | "lg:select-all"
  | "md:select-all"
  | "sm:select-all"
  | "xs:select-all"
  | "2xl:select-auto"
  | "1xl:select-auto"
  | "xl:select-auto"
  | "lg:select-auto"
  | "md:select-auto"
  | "sm:select-auto"
  | "xs:select-auto"
  | "2xl:align-baseline"
  | "1xl:align-baseline"
  | "xl:align-baseline"
  | "lg:align-baseline"
  | "md:align-baseline"
  | "sm:align-baseline"
  | "xs:align-baseline"
  | "2xl:align-top"
  | "1xl:align-top"
  | "xl:align-top"
  | "lg:align-top"
  | "md:align-top"
  | "sm:align-top"
  | "xs:align-top"
  | "2xl:align-middle"
  | "1xl:align-middle"
  | "xl:align-middle"
  | "lg:align-middle"
  | "md:align-middle"
  | "sm:align-middle"
  | "xs:align-middle"
  | "2xl:align-bottom"
  | "1xl:align-bottom"
  | "xl:align-bottom"
  | "lg:align-bottom"
  | "md:align-bottom"
  | "sm:align-bottom"
  | "xs:align-bottom"
  | "2xl:align-text-top"
  | "1xl:align-text-top"
  | "xl:align-text-top"
  | "lg:align-text-top"
  | "md:align-text-top"
  | "sm:align-text-top"
  | "xs:align-text-top"
  | "2xl:align-text-bottom"
  | "1xl:align-text-bottom"
  | "xl:align-text-bottom"
  | "lg:align-text-bottom"
  | "md:align-text-bottom"
  | "sm:align-text-bottom"
  | "xs:align-text-bottom"
  | "2xl:visible"
  | "1xl:visible"
  | "xl:visible"
  | "lg:visible"
  | "md:visible"
  | "sm:visible"
  | "xs:visible"
  | "2xl:invisible"
  | "1xl:invisible"
  | "xl:invisible"
  | "lg:invisible"
  | "md:invisible"
  | "sm:invisible"
  | "xs:invisible"
  | "2xl:whitespace-normal"
  | "1xl:whitespace-normal"
  | "xl:whitespace-normal"
  | "lg:whitespace-normal"
  | "md:whitespace-normal"
  | "sm:whitespace-normal"
  | "xs:whitespace-normal"
  | "2xl:whitespace-nowrap"
  | "1xl:whitespace-nowrap"
  | "xl:whitespace-nowrap"
  | "lg:whitespace-nowrap"
  | "md:whitespace-nowrap"
  | "sm:whitespace-nowrap"
  | "xs:whitespace-nowrap"
  | "2xl:whitespace-pre"
  | "1xl:whitespace-pre"
  | "xl:whitespace-pre"
  | "lg:whitespace-pre"
  | "md:whitespace-pre"
  | "sm:whitespace-pre"
  | "xs:whitespace-pre"
  | "2xl:whitespace-pre-line"
  | "1xl:whitespace-pre-line"
  | "xl:whitespace-pre-line"
  | "lg:whitespace-pre-line"
  | "md:whitespace-pre-line"
  | "sm:whitespace-pre-line"
  | "xs:whitespace-pre-line"
  | "2xl:whitespace-pre-wrap"
  | "1xl:whitespace-pre-wrap"
  | "xl:whitespace-pre-wrap"
  | "lg:whitespace-pre-wrap"
  | "md:whitespace-pre-wrap"
  | "sm:whitespace-pre-wrap"
  | "xs:whitespace-pre-wrap"
  | "2xl:w-0"
  | "1xl:w-0"
  | "xl:w-0"
  | "lg:w-0"
  | "md:w-0"
  | "sm:w-0"
  | "xs:w-0"
  | "2xl:w-1"
  | "1xl:w-1"
  | "xl:w-1"
  | "lg:w-1"
  | "md:w-1"
  | "sm:w-1"
  | "xs:w-1"
  | "2xl:w-2"
  | "1xl:w-2"
  | "xl:w-2"
  | "lg:w-2"
  | "md:w-2"
  | "sm:w-2"
  | "xs:w-2"
  | "2xl:w-3"
  | "1xl:w-3"
  | "xl:w-3"
  | "lg:w-3"
  | "md:w-3"
  | "sm:w-3"
  | "xs:w-3"
  | "2xl:w-4"
  | "1xl:w-4"
  | "xl:w-4"
  | "lg:w-4"
  | "md:w-4"
  | "sm:w-4"
  | "xs:w-4"
  | "2xl:w-5"
  | "1xl:w-5"
  | "xl:w-5"
  | "lg:w-5"
  | "md:w-5"
  | "sm:w-5"
  | "xs:w-5"
  | "2xl:w-6"
  | "1xl:w-6"
  | "xl:w-6"
  | "lg:w-6"
  | "md:w-6"
  | "sm:w-6"
  | "xs:w-6"
  | "2xl:w-7"
  | "1xl:w-7"
  | "xl:w-7"
  | "lg:w-7"
  | "md:w-7"
  | "sm:w-7"
  | "xs:w-7"
  | "2xl:w-8"
  | "1xl:w-8"
  | "xl:w-8"
  | "lg:w-8"
  | "md:w-8"
  | "sm:w-8"
  | "xs:w-8"
  | "2xl:w-9"
  | "1xl:w-9"
  | "xl:w-9"
  | "lg:w-9"
  | "md:w-9"
  | "sm:w-9"
  | "xs:w-9"
  | "2xl:w-10"
  | "1xl:w-10"
  | "xl:w-10"
  | "lg:w-10"
  | "md:w-10"
  | "sm:w-10"
  | "xs:w-10"
  | "2xl:w-11"
  | "1xl:w-11"
  | "xl:w-11"
  | "lg:w-11"
  | "md:w-11"
  | "sm:w-11"
  | "xs:w-11"
  | "2xl:w-12"
  | "1xl:w-12"
  | "xl:w-12"
  | "lg:w-12"
  | "md:w-12"
  | "sm:w-12"
  | "xs:w-12"
  | "2xl:w-14"
  | "1xl:w-14"
  | "xl:w-14"
  | "lg:w-14"
  | "md:w-14"
  | "sm:w-14"
  | "xs:w-14"
  | "2xl:w-16"
  | "1xl:w-16"
  | "xl:w-16"
  | "lg:w-16"
  | "md:w-16"
  | "sm:w-16"
  | "xs:w-16"
  | "2xl:w-20"
  | "1xl:w-20"
  | "xl:w-20"
  | "lg:w-20"
  | "md:w-20"
  | "sm:w-20"
  | "xs:w-20"
  | "2xl:w-24"
  | "1xl:w-24"
  | "xl:w-24"
  | "lg:w-24"
  | "md:w-24"
  | "sm:w-24"
  | "xs:w-24"
  | "2xl:w-28"
  | "1xl:w-28"
  | "xl:w-28"
  | "lg:w-28"
  | "md:w-28"
  | "sm:w-28"
  | "xs:w-28"
  | "2xl:w-32"
  | "1xl:w-32"
  | "xl:w-32"
  | "lg:w-32"
  | "md:w-32"
  | "sm:w-32"
  | "xs:w-32"
  | "2xl:w-36"
  | "1xl:w-36"
  | "xl:w-36"
  | "lg:w-36"
  | "md:w-36"
  | "sm:w-36"
  | "xs:w-36"
  | "2xl:w-40"
  | "1xl:w-40"
  | "xl:w-40"
  | "lg:w-40"
  | "md:w-40"
  | "sm:w-40"
  | "xs:w-40"
  | "2xl:w-44"
  | "1xl:w-44"
  | "xl:w-44"
  | "lg:w-44"
  | "md:w-44"
  | "sm:w-44"
  | "xs:w-44"
  | "2xl:w-48"
  | "1xl:w-48"
  | "xl:w-48"
  | "lg:w-48"
  | "md:w-48"
  | "sm:w-48"
  | "xs:w-48"
  | "2xl:w-52"
  | "1xl:w-52"
  | "xl:w-52"
  | "lg:w-52"
  | "md:w-52"
  | "sm:w-52"
  | "xs:w-52"
  | "2xl:w-56"
  | "1xl:w-56"
  | "xl:w-56"
  | "lg:w-56"
  | "md:w-56"
  | "sm:w-56"
  | "xs:w-56"
  | "2xl:w-60"
  | "1xl:w-60"
  | "xl:w-60"
  | "lg:w-60"
  | "md:w-60"
  | "sm:w-60"
  | "xs:w-60"
  | "2xl:w-64"
  | "1xl:w-64"
  | "xl:w-64"
  | "lg:w-64"
  | "md:w-64"
  | "sm:w-64"
  | "xs:w-64"
  | "2xl:w-72"
  | "1xl:w-72"
  | "xl:w-72"
  | "lg:w-72"
  | "md:w-72"
  | "sm:w-72"
  | "xs:w-72"
  | "2xl:w-80"
  | "1xl:w-80"
  | "xl:w-80"
  | "lg:w-80"
  | "md:w-80"
  | "sm:w-80"
  | "xs:w-80"
  | "2xl:w-96"
  | "1xl:w-96"
  | "xl:w-96"
  | "lg:w-96"
  | "md:w-96"
  | "sm:w-96"
  | "xs:w-96"
  | "2xl:w-112"
  | "1xl:w-112"
  | "xl:w-112"
  | "lg:w-112"
  | "md:w-112"
  | "sm:w-112"
  | "xs:w-112"
  | "2xl:w-192"
  | "1xl:w-192"
  | "xl:w-192"
  | "lg:w-192"
  | "md:w-192"
  | "sm:w-192"
  | "xs:w-192"
  | "2xl:w-auto"
  | "1xl:w-auto"
  | "xl:w-auto"
  | "lg:w-auto"
  | "md:w-auto"
  | "sm:w-auto"
  | "xs:w-auto"
  | "2xl:w-px"
  | "1xl:w-px"
  | "xl:w-px"
  | "lg:w-px"
  | "md:w-px"
  | "sm:w-px"
  | "xs:w-px"
  | "2xl:w-0.5"
  | "1xl:w-0.5"
  | "xl:w-0.5"
  | "lg:w-0.5"
  | "md:w-0.5"
  | "sm:w-0.5"
  | "xs:w-0.5"
  | "2xl:w-1.5"
  | "1xl:w-1.5"
  | "xl:w-1.5"
  | "lg:w-1.5"
  | "md:w-1.5"
  | "sm:w-1.5"
  | "xs:w-1.5"
  | "2xl:w-2.5"
  | "1xl:w-2.5"
  | "xl:w-2.5"
  | "lg:w-2.5"
  | "md:w-2.5"
  | "sm:w-2.5"
  | "xs:w-2.5"
  | "2xl:w-3.5"
  | "1xl:w-3.5"
  | "xl:w-3.5"
  | "lg:w-3.5"
  | "md:w-3.5"
  | "sm:w-3.5"
  | "xs:w-3.5"
  | "2xl:w-1/2"
  | "1xl:w-1/2"
  | "xl:w-1/2"
  | "lg:w-1/2"
  | "md:w-1/2"
  | "sm:w-1/2"
  | "xs:w-1/2"
  | "2xl:w-1/3"
  | "1xl:w-1/3"
  | "xl:w-1/3"
  | "lg:w-1/3"
  | "md:w-1/3"
  | "sm:w-1/3"
  | "xs:w-1/3"
  | "2xl:w-2/3"
  | "1xl:w-2/3"
  | "xl:w-2/3"
  | "lg:w-2/3"
  | "md:w-2/3"
  | "sm:w-2/3"
  | "xs:w-2/3"
  | "2xl:w-1/4"
  | "1xl:w-1/4"
  | "xl:w-1/4"
  | "lg:w-1/4"
  | "md:w-1/4"
  | "sm:w-1/4"
  | "xs:w-1/4"
  | "2xl:w-2/4"
  | "1xl:w-2/4"
  | "xl:w-2/4"
  | "lg:w-2/4"
  | "md:w-2/4"
  | "sm:w-2/4"
  | "xs:w-2/4"
  | "2xl:w-3/4"
  | "1xl:w-3/4"
  | "xl:w-3/4"
  | "lg:w-3/4"
  | "md:w-3/4"
  | "sm:w-3/4"
  | "xs:w-3/4"
  | "2xl:w-1/5"
  | "1xl:w-1/5"
  | "xl:w-1/5"
  | "lg:w-1/5"
  | "md:w-1/5"
  | "sm:w-1/5"
  | "xs:w-1/5"
  | "2xl:w-2/5"
  | "1xl:w-2/5"
  | "xl:w-2/5"
  | "lg:w-2/5"
  | "md:w-2/5"
  | "sm:w-2/5"
  | "xs:w-2/5"
  | "2xl:w-3/5"
  | "1xl:w-3/5"
  | "xl:w-3/5"
  | "lg:w-3/5"
  | "md:w-3/5"
  | "sm:w-3/5"
  | "xs:w-3/5"
  | "2xl:w-4/5"
  | "1xl:w-4/5"
  | "xl:w-4/5"
  | "lg:w-4/5"
  | "md:w-4/5"
  | "sm:w-4/5"
  | "xs:w-4/5"
  | "2xl:w-1/6"
  | "1xl:w-1/6"
  | "xl:w-1/6"
  | "lg:w-1/6"
  | "md:w-1/6"
  | "sm:w-1/6"
  | "xs:w-1/6"
  | "2xl:w-2/6"
  | "1xl:w-2/6"
  | "xl:w-2/6"
  | "lg:w-2/6"
  | "md:w-2/6"
  | "sm:w-2/6"
  | "xs:w-2/6"
  | "2xl:w-3/6"
  | "1xl:w-3/6"
  | "xl:w-3/6"
  | "lg:w-3/6"
  | "md:w-3/6"
  | "sm:w-3/6"
  | "xs:w-3/6"
  | "2xl:w-4/6"
  | "1xl:w-4/6"
  | "xl:w-4/6"
  | "lg:w-4/6"
  | "md:w-4/6"
  | "sm:w-4/6"
  | "xs:w-4/6"
  | "2xl:w-5/6"
  | "1xl:w-5/6"
  | "xl:w-5/6"
  | "lg:w-5/6"
  | "md:w-5/6"
  | "sm:w-5/6"
  | "xs:w-5/6"
  | "2xl:w-1/12"
  | "1xl:w-1/12"
  | "xl:w-1/12"
  | "lg:w-1/12"
  | "md:w-1/12"
  | "sm:w-1/12"
  | "xs:w-1/12"
  | "2xl:w-2/12"
  | "1xl:w-2/12"
  | "xl:w-2/12"
  | "lg:w-2/12"
  | "md:w-2/12"
  | "sm:w-2/12"
  | "xs:w-2/12"
  | "2xl:w-3/12"
  | "1xl:w-3/12"
  | "xl:w-3/12"
  | "lg:w-3/12"
  | "md:w-3/12"
  | "sm:w-3/12"
  | "xs:w-3/12"
  | "2xl:w-4/12"
  | "1xl:w-4/12"
  | "xl:w-4/12"
  | "lg:w-4/12"
  | "md:w-4/12"
  | "sm:w-4/12"
  | "xs:w-4/12"
  | "2xl:w-5/12"
  | "1xl:w-5/12"
  | "xl:w-5/12"
  | "lg:w-5/12"
  | "md:w-5/12"
  | "sm:w-5/12"
  | "xs:w-5/12"
  | "2xl:w-6/12"
  | "1xl:w-6/12"
  | "xl:w-6/12"
  | "lg:w-6/12"
  | "md:w-6/12"
  | "sm:w-6/12"
  | "xs:w-6/12"
  | "2xl:w-7/12"
  | "1xl:w-7/12"
  | "xl:w-7/12"
  | "lg:w-7/12"
  | "md:w-7/12"
  | "sm:w-7/12"
  | "xs:w-7/12"
  | "2xl:w-8/12"
  | "1xl:w-8/12"
  | "xl:w-8/12"
  | "lg:w-8/12"
  | "md:w-8/12"
  | "sm:w-8/12"
  | "xs:w-8/12"
  | "2xl:w-9/12"
  | "1xl:w-9/12"
  | "xl:w-9/12"
  | "lg:w-9/12"
  | "md:w-9/12"
  | "sm:w-9/12"
  | "xs:w-9/12"
  | "2xl:w-10/12"
  | "1xl:w-10/12"
  | "xl:w-10/12"
  | "lg:w-10/12"
  | "md:w-10/12"
  | "sm:w-10/12"
  | "xs:w-10/12"
  | "2xl:w-11/12"
  | "1xl:w-11/12"
  | "xl:w-11/12"
  | "lg:w-11/12"
  | "md:w-11/12"
  | "sm:w-11/12"
  | "xs:w-11/12"
  | "2xl:w-full"
  | "1xl:w-full"
  | "xl:w-full"
  | "lg:w-full"
  | "md:w-full"
  | "sm:w-full"
  | "xs:w-full"
  | "2xl:w-screen"
  | "1xl:w-screen"
  | "xl:w-screen"
  | "lg:w-screen"
  | "md:w-screen"
  | "sm:w-screen"
  | "xs:w-screen"
  | "2xl:w-min"
  | "1xl:w-min"
  | "xl:w-min"
  | "lg:w-min"
  | "md:w-min"
  | "sm:w-min"
  | "xs:w-min"
  | "2xl:w-max"
  | "1xl:w-max"
  | "xl:w-max"
  | "lg:w-max"
  | "md:w-max"
  | "sm:w-max"
  | "xs:w-max"
  | "2xl:w-4.5"
  | "1xl:w-4.5"
  | "xl:w-4.5"
  | "lg:w-4.5"
  | "md:w-4.5"
  | "sm:w-4.5"
  | "xs:w-4.5"
  | "2xl:w-vw-full"
  | "1xl:w-vw-full"
  | "xl:w-vw-full"
  | "lg:w-vw-full"
  | "md:w-vw-full"
  | "sm:w-vw-full"
  | "xs:w-vw-full"
  | "2xl:break-normal"
  | "1xl:break-normal"
  | "xl:break-normal"
  | "lg:break-normal"
  | "md:break-normal"
  | "sm:break-normal"
  | "xs:break-normal"
  | "2xl:break-words"
  | "1xl:break-words"
  | "xl:break-words"
  | "lg:break-words"
  | "md:break-words"
  | "sm:break-words"
  | "xs:break-words"
  | "2xl:break-all"
  | "1xl:break-all"
  | "xl:break-all"
  | "lg:break-all"
  | "md:break-all"
  | "sm:break-all"
  | "xs:break-all"
  | "2xl:z-0"
  | "1xl:z-0"
  | "xl:z-0"
  | "lg:z-0"
  | "md:z-0"
  | "sm:z-0"
  | "xs:z-0"
  | "focus-within:z-0"
  | "focus:z-0"
  | "2xl:z-10"
  | "1xl:z-10"
  | "xl:z-10"
  | "lg:z-10"
  | "md:z-10"
  | "sm:z-10"
  | "xs:z-10"
  | "focus-within:z-10"
  | "focus:z-10"
  | "2xl:z-20"
  | "1xl:z-20"
  | "xl:z-20"
  | "lg:z-20"
  | "md:z-20"
  | "sm:z-20"
  | "xs:z-20"
  | "focus-within:z-20"
  | "focus:z-20"
  | "2xl:z-30"
  | "1xl:z-30"
  | "xl:z-30"
  | "lg:z-30"
  | "md:z-30"
  | "sm:z-30"
  | "xs:z-30"
  | "focus-within:z-30"
  | "focus:z-30"
  | "2xl:z-40"
  | "1xl:z-40"
  | "xl:z-40"
  | "lg:z-40"
  | "md:z-40"
  | "sm:z-40"
  | "xs:z-40"
  | "focus-within:z-40"
  | "focus:z-40"
  | "2xl:z-50"
  | "1xl:z-50"
  | "xl:z-50"
  | "lg:z-50"
  | "md:z-50"
  | "sm:z-50"
  | "xs:z-50"
  | "focus-within:z-50"
  | "focus:z-50"
  | "2xl:z-100"
  | "1xl:z-100"
  | "xl:z-100"
  | "lg:z-100"
  | "md:z-100"
  | "sm:z-100"
  | "xs:z-100"
  | "focus-within:z-100"
  | "focus:z-100"
  | "2xl:z-200"
  | "1xl:z-200"
  | "xl:z-200"
  | "lg:z-200"
  | "md:z-200"
  | "sm:z-200"
  | "xs:z-200"
  | "focus-within:z-200"
  | "focus:z-200"
  | "2xl:z-300"
  | "1xl:z-300"
  | "xl:z-300"
  | "lg:z-300"
  | "md:z-300"
  | "sm:z-300"
  | "xs:z-300"
  | "focus-within:z-300"
  | "focus:z-300"
  | "2xl:z-400"
  | "1xl:z-400"
  | "xl:z-400"
  | "lg:z-400"
  | "md:z-400"
  | "sm:z-400"
  | "xs:z-400"
  | "focus-within:z-400"
  | "focus:z-400"
  | "2xl:z-500"
  | "1xl:z-500"
  | "xl:z-500"
  | "lg:z-500"
  | "md:z-500"
  | "sm:z-500"
  | "xs:z-500"
  | "focus-within:z-500"
  | "focus:z-500"
  | "2xl:z-600"
  | "1xl:z-600"
  | "xl:z-600"
  | "lg:z-600"
  | "md:z-600"
  | "sm:z-600"
  | "xs:z-600"
  | "focus-within:z-600"
  | "focus:z-600"
  | "2xl:z-700"
  | "1xl:z-700"
  | "xl:z-700"
  | "lg:z-700"
  | "md:z-700"
  | "sm:z-700"
  | "xs:z-700"
  | "focus-within:z-700"
  | "focus:z-700"
  | "2xl:z-800"
  | "1xl:z-800"
  | "xl:z-800"
  | "lg:z-800"
  | "md:z-800"
  | "sm:z-800"
  | "xs:z-800"
  | "focus-within:z-800"
  | "focus:z-800"
  | "2xl:z-900"
  | "1xl:z-900"
  | "xl:z-900"
  | "lg:z-900"
  | "md:z-900"
  | "sm:z-900"
  | "xs:z-900"
  | "focus-within:z-900"
  | "focus:z-900"
  | "2xl:z-auto"
  | "1xl:z-auto"
  | "xl:z-auto"
  | "lg:z-auto"
  | "md:z-auto"
  | "sm:z-auto"
  | "xs:z-auto"
  | "focus-within:z-auto"
  | "focus:z-auto"
  | "2xl:sr-only"
  | "1xl:sr-only"
  | "xl:sr-only"
  | "lg:sr-only"
  | "md:sr-only"
  | "sm:sr-only"
  | "xs:sr-only"
  | "focus-within:sr-only"
  | "focus:sr-only"
  | "2xl:not-sr-only"
  | "1xl:not-sr-only"
  | "xl:not-sr-only"
  | "lg:not-sr-only"
  | "md:not-sr-only"
  | "sm:not-sr-only"
  | "xs:not-sr-only"
  | "focus-within:not-sr-only"
  | "focus:not-sr-only";

export type TClasses =
  | TAccessibility
  | TBackgrounds
  | TBorders
  | TTables
  | TEffects
  | TTransitionsAndAnimations
  | TFilters
  | TFlexBox
  | TGrid
  | TSpacing
  | TInteractivity
  | TLayout
  | TSizing
  | TSVG
  | TTransforms
  | TTypography
  | TPseudoClasses;

export type TTailwindString = "TAILWIND_STRING";

export type TKey = TClasses | TTailwindString;

export type TArg =
  | TClasses
  | null
  | undefined
  | { [key in TKey]?: boolean }
  | TTailwindString;

export type TTailwind = (...args: TArg[]) => TTailwindString;

export const classnames: TTailwind = classnamesLib as any;

export const tw = classnames;

export default tw;
