import React, { useCallback, useMemo } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import { MediaManagerMediaCardFileUploadProps } from "./media-manager-media-card-file-upload";

export type MediaManagerMediaCardHeaderActionsProps = {
  onEditClicked?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSetCoverOrProfilePicClicked?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isCover?: boolean;
  onDeleteClicked?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  setProfilePictureByType?: any;
  fileUploadProps?: MediaManagerMediaCardFileUploadProps;
  isProfilePic?: boolean
  isPGImg?: boolean;
  isNonNudeProfilePic?: boolean;
  isApprovedImg?: boolean;
  isProfile?: boolean;
  isRejected?: boolean
};

const MediaManagerMediaCardHeaderActions: React.ComponentType<MediaManagerMediaCardHeaderActionsProps> = ({
    onSetCoverOrProfilePicClicked,
    isCover,
    isProfilePic,
    isNonNudeProfilePic,
    isPGImg,
    isApprovedImg,
    isProfile,
    setProfilePictureByType,
    isRejected
  }) => {
    const onSetCoverOrProfilePicClick = useCallback(
      (event, actionType?:string | undefined) => {
        if (onSetCoverOrProfilePicClicked && !isCover && !isProfilePic && !isNonNudeProfilePic) {
          if(isProfile) {
            setProfilePictureByType(actionType);
          }
          event.preventDefault();
          event.stopPropagation();
          onSetCoverOrProfilePicClicked(event);
        }
      },
      [onSetCoverOrProfilePicClicked, isCover, isProfilePic, isNonNudeProfilePic]
    );
    const isProfilePicture = useMemo(()=> isProfilePic || isNonNudeProfilePic ,[isProfilePic, isNonNudeProfilePic]);
    const isCoverAndNoProfileAlbum = useMemo(()=> !isCover && !isProfile ,[isCover, isProfile]);
    return (
      <TailwindFlex
        position={"absolute"}
        height={"h-auto"}
        width={"w-8"}
        margin={["mt-0"]}
        flexDirection={"flex-col"}
        justifyContent={"justify-end"}
        alignItems={"items-center"}
        style={{
          right: "2.3rem",
          top: "0rem",
        }}>
        {isProfile && onSetCoverOrProfilePicClicked && !isCoverAndNoProfileAlbum && isApprovedImg && !isProfilePicture && 
        <>
            {isPGImg && (
              <TailwindButton
                rounded
                fullWidth={false}
                display={"flex"}
                height={"h-8"}
                width={"w-8"}
                backgroundColor={"bg-gray-600"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                margin={["mb-2"]}
                onClick={(e:React.MouseEvent<HTMLButtonElement>) => onSetCoverOrProfilePicClick(e, 'PG')}
                textProps={{
                  textTransform: "capitalize",
                  fontSize: "text-sm",
                  textColor: isCover ? "text-white" : undefined,
                }}
                leftIconProps={{
                  name: "star",
                  fontSize: "text-xl",
                  textColor: "text-white",
                }}
                bold
              />
            )}
              <TailwindButton
                rounded
                fullWidth={false}
                display={"flex"}
                height={"h-8"}
                width={"w-8"}
                backgroundColor={"bg-gray-600"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                onClick={(e:React.MouseEvent<HTMLButtonElement>) => onSetCoverOrProfilePicClick(e, 'NUDE')}
                textProps={{
                  textTransform: "capitalize",
                  fontSize: "text-sm",
                  textColor: isCover ? "text-white" : undefined,
                }}
                leftIconProps={{
                  name: "nudeListing",
                  fontSize: "text-sm",
                  textColor: "text-white",
                }}
                bold
              />
          </>
        }
        {!isProfile && onSetCoverOrProfilePicClicked && !isCover && !isRejected && (
          <TailwindButton
            rounded
            fullWidth={false}
            display={"flex"}
            height={"h-8"}
            width={"w-8"}
            backgroundColor={"bg-gray-600"}
            alignItems={"items-center"}
            justifyContent={"justify-center"}
            onClick={onSetCoverOrProfilePicClick}
            textProps={{
              textTransform: "capitalize",
              fontSize: "text-sm",
              textColor: isCover ? "text-white" : undefined,
            }}
            leftIconProps={{
              name: "star",
              fontSize: "text-xl",
              textColor: "text-white",
            }}
            bold
          />
        )}
      </TailwindFlex>
    );
  }

export default MediaManagerMediaCardHeaderActions;  
