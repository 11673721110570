import React from "react";
import TailwindSpinner from "../_tailwind/spinner";

type Props = {};

const ImageLoader: React.ComponentType<Props> = () => {
  return <TailwindSpinner />;
};

export default ImageLoader;
