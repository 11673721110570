import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import MediaManagerStore from "./store/MediaManagerStore";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import { inject, observer } from "mobx-react";
import { ModelMediaManagerMode, ModelMediaRouteMode } from "./store/enums";
import { ModelAlbum, ModelImage, ModelVideo } from "./store/types";
import TailwindFlex from "library/components/_tailwind/flex";
import DragAndDropProvider from "library/components/_tailwind/drag-and-drop-provider";
import DraggableItem from "library/components/_tailwind/draggable-item";
import TailwindMasonry, {
  TailwindMasonryBreakPoint,
} from "library/components/_tailwind/masonry";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import TailwindSpinner from "library/components/_tailwind/spinner";
import TailwindText from "library/components/_tailwind/text";
import "./styles.scss";
import MediaManagerSidebar from "containers/_components/media-manager/media-manager-sidebar";
import MediaManagerAlbumManageNoMedia from "containers/_components/media-manager/media-manager-no-media";
import { AppCamsModelRouteKey } from "core/stores/route/enums";
import { useLocation, useParams } from "react-router-dom";
import RouteStore from "core/stores/route/RouteStore";
import MediaManagerMasonryAlbum from "containers/_components/media-manager/media-manager-masonry-album";
import MediaManagerMasonryMedia from "containers/_components/media-manager/media-manager-masonry-media";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import ModalStore from "library/core/stores/modal";
import RecordedShowsModal from "./recorded-shows-modal";
import { useScroll } from "library/core/utility/hooks";

type Props = {
  mediaManagerStore?: MediaManagerStore;
  profileStore?: ProfileStore;
  layoutStore?: LayoutStore;
  routeStore?: RouteStore;
  mediaType?: ModelMediaRouteMode;
  modalStore?: ModalStore;
};

const MediaManagerAlbumManage: React.ComponentType<Props> = ({
  mediaManagerStore,
  layoutStore,
  routeStore,
  mediaType,
}) => {
  const { setBodyPadding } = layoutStore!;
  const {
    selectedAlbum,
    setSelectedAlbumId,
    selectedAlbumImagesAndVideos,
    reorderAlbumMedia,
    isSelectedAlbumProfileAlbum,
    recordedShowsAlbum,
    videoAlbum,
    createDraftAlbum,
    createAlbum,
    setManagerMode,
    managerMode,
    setRouteMode,
    routeMode,
    customAlbums,
    albums,
    reorderAlbums,
    albumNextHash,
    getVideosOfAlbumNextPage,
  } = mediaManagerStore!;
  const { getSiteRouteURL } = routeStore!;

  const { albumId: routeAlbumId }: { albumId: string } = useParams();
  const location = useLocation();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  function useCheckClickOutside(ref, handleCloseRSMenu, recordedShowsMenuIcon) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (
          ref.current &&
          recordedShowsMenuIcon.current &&
          !ref.current.contains(event.target) &&
          !recordedShowsMenuIcon.current.contains(event.target)
        ) {
          handleCloseRSMenu();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, recordedShowsMenuIcon]);
  }

  const recordedShowsMenuIcon = useRef(null);
  const recordedShowsMenu = useRef(null);

  const [recordedShowsMenuOpen, setRecordedShowsMenuOpen] = useState(false);
  const handleRecordedShowsMenu = () => setRecordedShowsMenuOpen(!recordedShowsMenuOpen);
  const handleCloseRSMenu = () => setRecordedShowsMenuOpen(false);
  useCheckClickOutside(recordedShowsMenu, handleCloseRSMenu, recordedShowsMenuIcon);

  useEffect(() => {
    if (mediaType === ModelMediaRouteMode.PHOTOS) {
      if (
        location.pathname.includes(
          getSiteRouteURL(AppCamsModelRouteKey.myPhotosList)
        )
      ) {
        setRouteMode(ModelMediaRouteMode.PHOTOS);
        setManagerMode(ModelMediaManagerMode.LIST);
      } else if (
        location.pathname.includes(
          getSiteRouteURL(AppCamsModelRouteKey.myPhotosCreate)
        )
      ) {
        setRouteMode(ModelMediaRouteMode.PHOTOS);
        setManagerMode(ModelMediaManagerMode.CREATE);
      } else if (
        location.pathname.includes(
          getSiteRouteURL(AppCamsModelRouteKey.myPhotosEdit)
        )
      ) {
        setRouteMode(ModelMediaRouteMode.PHOTOS);
        setManagerMode(ModelMediaManagerMode.EDIT);
      }
    } else if (mediaType === ModelMediaRouteMode.RECORDED_VIDEOS) {
      setRouteMode(ModelMediaRouteMode.RECORDED_VIDEOS);
      setManagerMode(ModelMediaManagerMode.EDIT);
    } else if (mediaType === ModelMediaRouteMode.UPLOADED_VIDEOS) {
      setRouteMode(ModelMediaRouteMode.UPLOADED_VIDEOS);
      setManagerMode(ModelMediaManagerMode.EDIT);
    }
  }, [location.pathname, mediaType]);

  useEffect(() => {
    async function setAlbum() {
      if (
        mediaType === ModelMediaRouteMode.RECORDED_VIDEOS &&
        recordedShowsAlbum?.id
      ) {
        setSelectedAlbumId(recordedShowsAlbum?.id);
      } else if (
        mediaType === ModelMediaRouteMode.UPLOADED_VIDEOS &&
        videoAlbum?.id
      ) {
        setSelectedAlbumId(videoAlbum?.id);
      } else if (mediaType === ModelMediaRouteMode.PHOTOS) {
        if (managerMode === "list") {
          const draftAlbum: ModelAlbum = createDraftAlbum();
          setSelectedAlbumId(draftAlbum.id);
        } else if (managerMode === "create") {
          const draftAlbum: ModelAlbum = createDraftAlbum();
          const newlyCreatedAlbum = await createAlbum(draftAlbum);
          if (newlyCreatedAlbum) {
            setSelectedAlbumId(newlyCreatedAlbum?.id);
          }
        } else if (managerMode === "edit") {
          setSelectedAlbumId(routeAlbumId);
        }
      }
    }

    setAlbum();
  }, [routeAlbumId, recordedShowsAlbum, videoAlbum, mediaType, managerMode]);

  useEffect(() => {
    return () => {
      setRouteMode(undefined);
      setManagerMode(undefined);
      setSelectedAlbumId(null);
    };
  }, []);

  const reorderMedia = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (selectedAlbum) {
        reorderAlbumMedia(selectedAlbum, dragIndex, hoverIndex);
      }
    },
    [selectedAlbum]
  );

  const reorderAlbum = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      reorderAlbums(dragIndex, hoverIndex);
    },
    [albums]
  );

  const reorder = useCallback(
    (isAlbum: boolean, dragIndex: number, hoverIndex: number) => {
      if (isAlbum) {
        reorderAlbum(dragIndex, hoverIndex);
      } else {
        reorderMedia(dragIndex, hoverIndex);
      }
    },
    [reorderMedia, reorderAlbum]
  );

  const masonryBreakpoints = [
    { size: 400, cols: 1 },
    { size: 1400, cols: 2 },
    { size: 1800, cols: 3 },
    { size: 2200, cols: 4 },
    { size: "default", cols: 5 },
  ] as TailwindMasonryBreakPoint[];

  useEffect(() => {
    setBodyPadding(0);
    return () => {
      setBodyPadding(15);
      setSelectedAlbumId(null);
    };
  }, []);

  const shouldShowSidebar = useMemo(() => {
    if (
      routeMode === ModelMediaRouteMode.PHOTOS &&
      managerMode === ModelMediaManagerMode.LIST
    ) {
      return true;
    }
    return !isSelectedAlbumProfileAlbum && selectedAlbum;
  }, [isSelectedAlbumProfileAlbum, selectedAlbum, routeMode, managerMode]);

  const isLoadingMedia = useMemo(() => {
    if (
      routeMode === ModelMediaRouteMode.PHOTOS &&
      managerMode === ModelMediaManagerMode.LIST
    ) {
      return false;
    }
    return !selectedAlbum || selectedAlbum?.is_loading_media;
  }, [selectedAlbum, routeMode, managerMode]);

  const hasMedia = useMemo(() => {
    if (
      routeMode === ModelMediaRouteMode.PHOTOS &&
      managerMode === ModelMediaManagerMode.LIST
    ) {
      return customAlbums.length > 0;
    }
    return selectedAlbumImagesAndVideos?.length > 0;
  }, [selectedAlbumImagesAndVideos, routeMode, managerMode, customAlbums]);

  const medias = useMemo(() => {
    if (
      routeMode === ModelMediaRouteMode.PHOTOS &&
      managerMode === ModelMediaManagerMode.LIST
    ) {
      return customAlbums as ModelAlbum[];
    }
    return selectedAlbumImagesAndVideos as (ModelImage | ModelVideo)[];
  }, [selectedAlbumImagesAndVideos, routeMode, managerMode, customAlbums]);

  const hasNext = useMemo(
    () => albumNextHash[selectedAlbum?.id || ""],
    [albumNextHash, selectedAlbum, medias]
  );

  const getNextPage = useCallback(() => {
    if (selectedAlbum?.id && !selectedAlbum?.is_loading_media && hasNext) {
      getVideosOfAlbumNextPage(selectedAlbum?.id);
    }
  }, [hasNext, selectedAlbum]);

  useScroll({
    element: scrollContainerRef.current,
    onBottomReached: () => {
      getNextPage();
    },
  });

  useEffect(() => {
    setTimeout(() => {
      if (
        scrollContainerRef?.current &&
        scrollContainerRef.current?.scrollHeight <=
          scrollContainerRef.current?.clientHeight &&
        hasNext
      ) {
        // load more media if we have not filled the page
        getNextPage();
      }
    }, 1000);
  }, [medias]);

  return (
    <TailwindFlex
      flexDirection={"flex-row"}
      flexWrap={"flex-wrap"}
      height={"h-full"}
      overflow={["overflow-hidden"]}>
      <TailwindFlex
        alignItems={"items-start"}
        flexDirection={"flex-row"}
        height={"h-full"}
        pseudoClasses={["md:flex-col"]}
        overflow={["overflow-x-hidden", "overflow-y-auto"]}
        ref={scrollContainerRef}>
        {shouldShowSidebar && selectedAlbum?.album_type !== "recorded" && (
          <MediaManagerSidebar />
        )}
        {isLoadingMedia ? (
          <TailwindFlex
            justifyContent={"justify-center"}
            alignItems={"items-center"}
            width={"w-full"}
            height={"h-full"}>
            <TailwindSpinner />
          </TailwindFlex>
        ) : (
          <TailwindFlex
            flexDirection={"flex-col"}
            minHeight={"min-h-full"}
            backgroundColor={"bg-primary-bg-color"}
            padding={["pt-5", "px-4"]}
            flexGrow={"flex-grow"}>
            {selectedAlbum?.album_type === "recorded" && (
              <TailwindText margin={["mb-3"]} display={"inline-block"}>
                <TailwindTranslatedText
                  fontWeight={"font-bold"}
                  style={{
                    fontSize: "1.5rem",
                  }}
                  descriptor={{
                    id: "recordedShows.header",
                    defaultMessage: "Recorded Shows",
                  }}
                />
                <TailwindIcon
                  fontSize={"text-xl"}
                  margin={["ml-1"]}
                  onClick={handleRecordedShowsMenu}
                  ref={recordedShowsMenuIcon}
                  style={{
                    position: "relative",
                    top: "4px",
                  }}
                  name={CustomIconName["info-circle"]}
                />
                {recordedShowsMenuOpen &&
                  <TailwindFlex ref={recordedShowsMenu}>
                    <RecordedShowsModal  close={handleCloseRSMenu}/>
                  </TailwindFlex>
                }
              </TailwindText>
            )}
            {!hasMedia ? (
              <MediaManagerAlbumManageNoMedia />
            ) : (
              <DragAndDropProvider>
                <TailwindMasonry breakPoints={masonryBreakpoints}>
                  {(medias as (ModelAlbum | ModelImage | ModelVideo)[]).map(
                    (media, index) => {
                      return (
                        <DraggableItem
                          key={media?.id}
                          id={media?.id}
                          index={index}
                          reorder={(dragIndex: number, hoverIndex: number) =>
                            reorder(
                              (media as ModelAlbum).album_type !== undefined,
                              dragIndex,
                              hoverIndex
                            )
                          }
                          itemType={
                            (media as ModelAlbum).album_type
                              ? "album"
                              : "album-media"
                          }>
                          {(media as ModelAlbum).album_type ? (
                            <MediaManagerMasonryAlbum
                              album={media as ModelAlbum}
                            />
                          ) : (
                            <MediaManagerMasonryMedia
                              media={media as ModelImage | ModelVideo}
                            />
                          )}
                        </DraggableItem>
                      );
                    }
                  )}
                </TailwindMasonry>
                {hasNext ? (
                  <TailwindFlex
                    justifyContent={"justify-center"}
                    alignItems={"items-center"}
                    margin={["m-4"]}>
                    <TailwindSpinner />
                  </TailwindFlex>
                ) : null}
              </DragAndDropProvider>
            )}
          </TailwindFlex>
        )}
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default inject(
  "mediaManagerStore",
  "profileStore",
  "layoutStore",
  "routeStore",
  "modalStore"
)(observer(MediaManagerAlbumManage));
