import React from "react";
import { inject, observer } from "mobx-react";
import ProfileStore from "../my-page/stores/profile/ProfileStore";
import TailwindCard from "library/components/_tailwind/card";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindHeading from "library/components/_tailwind/heading";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindSpinner from "library/components/_tailwind/spinner";
import { TailwindBoxProps } from "library/components/_tailwind/box";

type StudioModelAccessRightsProps = {
  children?: React.ReactNode;
  shouldShow: boolean;
  isIndividualModel?: boolean;
  hideContent?: boolean;
  comingSoon?: boolean;
  noAccessChildren?: React.ReactNode;
  type?: "pricing" | "access";
  boxProps?: TailwindBoxProps;
  profileStore?: ProfileStore;
};

const StudioModelAccessRights: React.FunctionComponent<StudioModelAccessRightsProps> =
  ({
    children,
    shouldShow,
    hideContent,
    comingSoon,
    noAccessChildren,
    type,
    boxProps,
    profileStore,
  }) => {
    const { profileLoaded } = profileStore!;

    if (!profileLoaded) {
      return (
        <TailwindFlex
          justifyContent={"justify-center"}
          alignItems={"items-center"}
          alignContent={"content-center"}
          width={"w-full"}
          height={"h-full"}>
          <TailwindSpinner />
        </TailwindFlex>
      );
    } else if (shouldShow) {
      return <React.Fragment>{children}</React.Fragment>;
    } else {
      if (hideContent) {
        if (comingSoon) {
          return (
            <TailwindHeading level={6} textAlign={"text-center"}>
              Coming Soon
            </TailwindHeading>
          );
        } else if (noAccessChildren){
          return <React.Fragment>{noAccessChildren}</React.Fragment>;
        }
        return null;
      }

      return (
        <TailwindCard
          width={"w-full"}
          height={"h-full"}
          display={"flex"}
          alignItems={"items-center"}
          justifyContent={"justify-center"}
          {...boxProps}>
          <TailwindTranslatedText
            headingProps={{ level: 6, textAlign: "text-center" }}
            descriptor={
              type === "pricing"
                ? {
                    id: "access-rights.model-no-rights-pricing",
                    defaultMessage:
                      "You don't have access to this resource. Please contact your studio representative for access.",
                  }
                : {
                    id: "access-rights.model-no-rights",
                    defaultMessage:
                      "You don't have access to this resource. Please contact your studio representative for access.",
                  }
            }
          />
        </TailwindCard>
      );
    }
  };

export default inject("profileStore")(observer(StudioModelAccessRights));
