import React, { useEffect, useState } from "react";
import TailwindBox from "library/components/_tailwind/box";
import TailwindText from "library/components/_tailwind/text";
import "./styles.scss";
import axios from "core/config/axios";
import { FALLBACK_TOU } from "core/constants";
import LoadingResourceSpinner from "common/messages/messages-loading-spinner";
import { dateProcess } from "library/core/utility";

type TouContent = {
  modified_at: string;
  text_version: string;
  text: string;
};

type Props = {};

const PaymentRenewalTermsOfUse: React.FC<Props> = ({}) => {
  const [isLoadingTou, setIsLoadingTou] = useState<boolean>(true);
  const [touContent, setTouContent] = useState<TouContent>({
    modified_at: "2019-10-22T16:52:07.710627Z",
    text_version: "2.9",
    text: FALLBACK_TOU,
  });

  const getTouContent = async () => {
    try {
      setIsLoadingTou(true);
      const { data } = await axios.get(`/members/tou/latest/`);
      setTouContent(data);
    } catch (e) {
    } finally {
      setIsLoadingTou(false);
    }
  };

  useEffect(() => {
    getTouContent();
  }, []);

  return (
    <div className={"PaymentRenewalTermsOfUse"}>
      <TailwindText
        display={"block"}
        fontSize={"text-3xl"}
        fontWeight={"font-bold"}
        padding={["py-2"]}
        margin={["mb-4"]}>
        Terms of Use
      </TailwindText>
      {isLoadingTou ? (
        <LoadingResourceSpinner />
      ) : (
        <TailwindBox>
          {touContent.modified_at && touContent.text_version && (
            <p className='PaymentRenewalTermsOfUse__updated-date'>
              {`Last Updated ${dateProcess.formatDate(
                touContent?.modified_at
              )}, Version ${touContent?.text_version}`}
            </p>
          )}
          <TailwindBox
            dangerouslySetInnerHTML={{
              __html: touContent.text,
            }}></TailwindBox>
        </TailwindBox>
      )}
    </div>
  );
};

export default PaymentRenewalTermsOfUse;
