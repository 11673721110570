import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";

type Props = {};

const StorybookArchitectureTests: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Architecture &gt; Tests</StorybookHeading>
      <StorybookParagraph>
        Although we have no tests, we will write minimum amount of viable tests
        to ensure some of the functionalities (Unit Tests) a little bit later.
        The libraries used for testing are:
      </StorybookParagraph>
      <Code>
        {`- jest
- enzyme
`}
      </Code>
      <StorybookParagraph>
        Tests can be run via <Code inline>yarn test</Code> command
      </StorybookParagraph>
      <StorybookParagraph>
        Tests should be written either at the very beginning (which is hard to
        do in our environment of unknowns) or they should come after the code
        has been refactored. Code that is not refactored, will not have tests
        written as the tests will also be duplicated or test cases will be
        extremely long and hard to maintain like the code is.
      </StorybookParagraph>
    </StorybookWrapper>
  );
};

export default StorybookArchitectureTests;
