import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import config from "core/config";
import { SHOWCASE_TARGET_CLASSNAMES } from "core/stores/showcase/const";
import "../styles.scss";

type Props = {};

const EarningsTableHead: React.ComponentType<Props> = ({}) => {
  const { showYouMakeColumnOnEarningsReport } = config!;

  return (
    <TailwindFlex
      padding={["p-3", "pl-5", "pr-5"]}
      backgroundColor={"bg-table-primary-bg-color"}
      borderRadius={"rounded-t-xl"}
      textColor='text-main-color'
      className={["EarningsTable__header"]}>
      <TailwindFlex width={"w-4/12"} justifyContent={"justify-start"}>
        <TailwindTranslatedText
          fontSize={"text-lg"}
          fontWeight={"font-semibold"}
          descriptor={{
            id: "modelPayoutTable.source",
            defaultMessage: "Source",
          }}
        />
      </TailwindFlex>
      {showYouMakeColumnOnEarningsReport && (
        <TailwindFlex
          width={"w-2/12"}
          justifyContent={"justify-start"}
          className={[SHOWCASE_TARGET_CLASSNAMES.earningsYouMake]}>
          <TailwindTranslatedText
            fontSize={"text-lg"}
            fontWeight={"font-semibold"}
            descriptor={{
              id: "modelPayoutTable.you_make",
              defaultMessage: "You Make",
            }}
          />
        </TailwindFlex>
      )}

      <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedText
          fontSize={"text-lg"}
          fontWeight={"font-semibold"}
          descriptor={{
            id: "modelPayoutTable.tokens",
            defaultMessage: "Tokens",
          }}
        />
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedText
          whiteSpace={"whitespace-nowrap"}
          fontSize={"text-lg"}
          fontWeight={"font-semibold"}
          descriptor={{
            id: "modelPayoutTable.cost",
            defaultMessage: "Cost",
          }}
        />
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedText
          fontSize={"text-lg"}
          fontWeight={"font-semibold"}
          textAlign={"text-right"}
          descriptor={{
            id: "modelPayoutTable.yourCut",
            defaultMessage: "Your Cut",
          }}
        />
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedText
          whiteSpace={"whitespace-nowrap"}
          fontSize={"text-lg"}
          fontWeight={"font-semibold"}
          descriptor={{
            id: "modelPayoutTable.earnings",
            defaultMessage: "Earnings",
          }}
        />
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default EarningsTableHead;
