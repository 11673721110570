import {
  // useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
  // useCallback
} from "react";
import { useLocation } from "react-router-dom";
import LayoutStore from "../../stores/layout/LayoutStore";

export function usePrevious<T>(value): T | undefined {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
// A custom hook that builds on useLocation to parse the query string
// https://reactrouter.com/web/example/query-parameters
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

type useScrollOptions = {
  element?: HTMLElement | null;
  reversedY?: boolean;
  reversedX?: boolean;
  preventCallback?: boolean;
  onTopReached?: () => void;
  onBottomReached?: () => void;
  onLeftReached?: () => void;
  onRightReached?: () => void;
};

type useScrollDefaultOptions = useScrollOptions & {
  element?: HTMLElement | null;
  preventCallback: boolean;
};

const getCorrectedScrollPosition = (
  direction: "up" | "down" | "left" | "right",
  scrollTopOrLeft: number,
  reversed = false
) => {
  let scrollTopOrLeft_ = Math.abs(scrollTopOrLeft);
  if (reversed && LayoutStore.isChrome()) {
    if (direction === "down" || direction === "right") {
      scrollTopOrLeft_ -= 1;
    } else {
      scrollTopOrLeft_ += 1;
    }
  }

  return Math.abs(scrollTopOrLeft_);
};

export const useScroll = (options?: useScrollOptions) => {
  const defaultOptions: useScrollDefaultOptions = {
    element: typeof document !== "undefined" ? document.body : null,
    preventCallback: false,
    ...options,
  };
  const [element, setElement] = useState<HTMLElement | null | undefined>(
    defaultOptions.element
  );
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const [scrollWidth, setScrollWidth] = useState<number>(0);
  useEffect(() => {
    const element_ = options?.element
      ? options?.element
      : defaultOptions.element;
    if (element_) {
      setElement(element_);
    }
  }, [options]);
  const [lastScrollTop, setLastScrollTop] = useState<number>(0);
  const [lastScrollLeft, setLastScrollLeft] = useState<number>(0);
  const [scrollY, setScrollY] = useState<number>(0);
  const [scrollX, setScrollX] = useState<number>(0);
  const [scrollYDirection, setScrollYDirection] = useState<
    "down" | "up" | undefined
  >();
  const [scrollXDirection, setScrollXDirection] = useState<
    "left" | "right" | undefined
  >();

  const listener = useCallback(() => {
    if (element) {
      const scrollTop = element.scrollTop;
      const scrollLeft = element.scrollLeft;
      const scrollHeight = element.scrollHeight - element.clientHeight;
      const scrollWidth = element.scrollWidth - element.clientWidth;
      const directionY = lastScrollTop > scrollTop ? "up" : "down";
      const directionX = lastScrollLeft > scrollLeft ? "left" : "right";
      setScrollY(element.scrollTop);
      setScrollX(element.scrollLeft);
      setScrollYDirection(directionY);
      setScrollXDirection(directionX);
      setLastScrollTop(scrollTop);
      setLastScrollLeft(scrollLeft);
      setScrollHeight(scrollHeight);
      setScrollWidth(scrollWidth);

      const hasYScroll = scrollHeight > 0;
      const fixedScrollTop = getCorrectedScrollPosition(
        directionY,
        scrollTop,
        defaultOptions?.reversedY
      );
      const topEdge = !defaultOptions?.reversedY ? 0 : scrollHeight;
      const bottomEdge = !defaultOptions?.reversedY ? scrollHeight : 0;
      // one some browsers pixel calculation fails so have a 1px fail safe
      const hasReachedTop = Math.abs(fixedScrollTop - topEdge) < 3;
      const hasReachedBottom = Math.abs(fixedScrollTop - bottomEdge) < 3;

      const hasXScroll = element.clientWidth < scrollWidth;
      const leftEdge = !defaultOptions?.reversedX ? 0 : scrollWidth;
      const rightEdge = !defaultOptions?.reversedX ? scrollWidth : 0;
      const fixedScrollLeft = getCorrectedScrollPosition(
        directionX,
        scrollLeft,
        defaultOptions?.reversedX
      );
      // one some browsers pixel calculation fails so have a 1px fail safe
      const hasReachedLeft =
        Math.abs(fixedScrollLeft - leftEdge) === 0 ||
        Math.abs(fixedScrollLeft - leftEdge) === 1;
      const hasReachedRight =
        Math.abs(fixedScrollLeft - rightEdge) === 0 ||
        Math.abs(fixedScrollLeft - rightEdge) === 1;

      if (!defaultOptions.preventCallback) {
        if (hasYScroll) {
          if (hasReachedTop && defaultOptions?.onTopReached) {
            defaultOptions?.onTopReached();
          }
          if (hasReachedBottom && defaultOptions?.onBottomReached) {
            defaultOptions?.onBottomReached();
          }
        }

        if (hasXScroll) {
          if (hasReachedLeft && defaultOptions?.onLeftReached) {
            defaultOptions?.onLeftReached();
          }
          if (hasReachedRight && defaultOptions?.onRightReached) {
            defaultOptions?.onRightReached();
          }
        }
      }
    }
  }, [
    element,
    defaultOptions,
    scrollHeight,
    scrollWidth,
    lastScrollTop,
    lastScrollLeft,
  ]);

  useEffect(() => {
    if (typeof window !== "undefined" && element) {
      element.addEventListener("scroll", listener);
      return () => {
        element.removeEventListener("scroll", listener);
      };
    }
  });

  return {
    scrollY,
    scrollHeight,
    scrollX,
    scrollWidth,
    scrollYDirection,
    scrollXDirection,
  };
};
