import React from "react";
import TailwindBox from "library/components/_tailwind/box";
import TailwindText from "library/components/_tailwind/text";
import "./styles.scss";
import { FALLBACK_DMCA } from "core/constants";

type Props = {};
const DMCA = ({}: Props) => {
  return (
    <div className={"DMCA"}>
      <TailwindText
        display={"block"}
        fontSize={"text-3xl"}
        fontWeight={"font-bold"}
        padding={["py-2"]}
        margin={["mb-4"]}>
        Important Legal Information and Information Regarding the Digital
        Millenium Copyright Act
      </TailwindText>
      <TailwindBox
        dangerouslySetInnerHTML={{
          __html: FALLBACK_DMCA,
        }}></TailwindBox>
    </div>
  );
};

export default DMCA;
