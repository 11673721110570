import React from "react";
import TailwindText, {
  TailwindTextProps,
} from "library/components/_tailwind/text";
import TailwindFlex from "library/components/_tailwind/flex";
import { TailwindBoxProps } from "library/components/_tailwind/box";

export type MediaManagerMediaCardVideoDurationProps = TailwindTextProps & {
  duration: string | undefined;
  DurationBoxProps?: TailwindBoxProps;
  DurationBoxProps2?: TailwindBoxProps;
};

const MediaManagerMediaCardVideoDuration: React.ComponentType<MediaManagerMediaCardVideoDurationProps> =
  ({ 
    duration,
    DurationBoxProps,
  }) => {
    if (!duration) {
      return null; // we don't want to show empty pills or durations with 0:00
    }

    return (
      <TailwindFlex
        width='w-auto'
        backgroundColor='bg-black'
        backgroundOpacity='bg-opacity-90'
        padding={["px-1", "py-0.5"]}
        {...DurationBoxProps}>
        <TailwindText textColor='text-white' fontSize="text-base" pseudoClasses={["md:text-xs"]}>
          {duration}
        </TailwindText>
      </TailwindFlex>
    );
  };

export default MediaManagerMediaCardVideoDuration;
