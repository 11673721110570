import React, { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import { inject, observer } from "mobx-react";
import { Popover } from "../../popover";
import TailwindButton from "library/components/_tailwind/button";
import NodeChatStore from "../_stores/nodechat/NodeChatStore";
import TailwindInput from "library/components/_tailwind/input";
import { FormattedMessage } from "react-intl";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindBox from "library/components/_tailwind/box";

interface IRoomTopicPopover {
  nodeChatStore?: NodeChatStore;
  children: any;
}

const RoomTopicPopover = ({
  nodeChatStore,
  children,
}: IRoomTopicPopover) => {
  const [ newTopic, setNewTopic ] = useState<string>('');
  const { roomTopic, setTopic } = nodeChatStore!;


  const [show, setShow] = useState(false);

  const onHide = useCallback(() => {
    setShow(false);
  }, []);

  const onShow = useCallback(() => {
    setShow(true);
  }, []);

  const onSubmit = () => {
    setShow(false);
    setTopic(newTopic);
  }

  useEffect(() => {
    setNewTopic(roomTopic);
  }, [
    roomTopic
  ])
  

  return (
    <Popover
      placement='top-start'
      onHide={onHide}
      onShow={onShow}
      show={show}
      content={
        <TailwindFlex gap={'gap-1.5'} flexDirection={"flex-col"} justifyContent={"justify-end"} alignItems="items-end" width={"w-64"} borderWidth={["border-2"]} borderColor={"border-blue-500"} padding={['p-2']} backgroundColor={"bg-primary-bg-color"}>
          <TailwindInput
            label={"Set Room Topic"}
            backgroundColor={"secondary"}
            onChange={(e) => {setNewTopic(e.target.value)}}
            onBlur={() => {}}
            placeholder={"Enter a room topic"}
            value={newTopic}
            error={undefined}
          />
          <TailwindBox>
            <TailwindButton onClick={onSubmit} backgroundColor="bg-pink-600" textColor="text-white" fullWidth={false}>
              <FormattedMessage id="common.submit" />
            </TailwindButton>
          </TailwindBox>
        </TailwindFlex>
      }>
      {children}
    </Popover>
  );
};

export default inject("nodeChatStore")(observer(RoomTopicPopover));
