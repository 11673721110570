import React from "react";
import "./styles.scss";
import classnames from "classnames";

type HighValueProps = {
  className?: string;
};

const HighValueIcon: React.ComponentType<HighValueProps> = ({ className }) => (
  <span className={classnames("HighValue", className && className)}>
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <g>
        <polygon style={{fill: "#fae300", strokeWidth: "0px"}} points="0 27.02 0 40 8.46 40 8.46 19.78 0 27.02"/>
        <polygon style={{fill: "#f4af4d", strokeWidth: "0px"}} points="31.6 16.05 31.6 40 40 40 40 10.5 31.6 16.05"/>
        <polygon style={{fill: "#fae300", strokeWidth: "0px"}} points="20.03 23.68 20.03 40 31.6 40 31.6 16.05 20.03 23.68"/>
        <polygon style={{fill: "#f4af4d", strokeWidth: "0px"}} points="8.71 19.57 8.46 19.78 8.46 40 20.03 40 20.03 23.68 8.71 19.57"/>
      </g>
      <g>
        <polyline style={{fill: "none", stroke: "#f4af4d", strokeLinecap: "round", strokeMiterlimit: 10}} className="cls-1" points=".63 16.87 7.18 10.7 18.75 14.77 35.8 3.06"/>
        <path style={{fill: "#f4af4d", strokeWidth: "0px"}} d="m34.28.75l5.72-.75c-1.1,2.61-3.31,7.87-4.4,10.48-1.75-2.24-5.29-6.75-7.04-8.98l5.72-.75Z"/>
      </g>
    </svg>
  </span>
);

export default HighValueIcon;
