import React from "react";
import { IChatMessage } from "common/broadcast/_stores/chat/interfaces";
import { languageStore } from "library/core/stores/language/LanguageStore";

type Props = {
  message: IChatMessage;
};

const ChatMessageGift: React.ComponentType<Props> = ({ message }) => {
  const { intl } = languageStore!;
  const backgroundImage = "linear-gradient(90deg, #2abd76 0%, #124a87 100% )";
  return (
    <div className='ChatItem__box' style={{ backgroundImage }}>
      <img
        src='https://s3.amazonaws.com/upload.uxpin/files/168180/173507/virtual_gift_vdaybearbundle-a333a8.png'
        alt='Token'
      />
      <span data-testid={"chat-message-gift"}>
        {intl.formatMessage(
          {
            id: `broadcast.chatItem.hasSentYouGift`,
            defaultMessage: `{chatfrom} has sent you a gift of {chatValue} tokens`,
          },
          { chatfrom: message.from, chatValue: message.value }
        )}
      </span>
    </div>
  );
};

export default ChatMessageGift;
