import React, { useMemo } from "react";
import TailwindBox, { TailwindBoxProps } from "../box";
import {
  TGap,
  TGridTemplateColumns,
  TGridTemplateRows,
  TWidth,
} from "../../../styles/tailwind-classnames";

type TailwindGridResponsiveProps = {
  cols?: TGridTemplateColumns;
  rows?: TGridTemplateRows;
};

export type TailwindGridProps = TailwindBoxProps & {
  sm?: TailwindGridResponsiveProps;
  md?: TailwindGridResponsiveProps;
  lg?: TailwindGridResponsiveProps;
  xl?: TailwindGridResponsiveProps;
  xxl?: TailwindGridResponsiveProps;
  width?: TWidth;
  gap?: TGap;
  cols?: TGridTemplateColumns;
  rows?: TGridTemplateRows;
  inline?: boolean;
};

const TailwindGrid: React.ComponentType<TailwindGridProps> = ({
  sm,
  md,
  lg,
  xl,
  xxl,
  width = "w-full",
  inline,
  gap,
  cols,
  rows = "grid-rows-fit",
  children,
  ...props
}) => {
  const smClass = useMemo(() => {
    if (sm) {
      const _sm: any[] = [];
      if (sm.cols) {
        _sm.push(`sm:${sm.cols}` as any);
      }
      if (sm.rows) {
        _sm.push(`sm:${sm.rows}` as any);
      }
      return _sm;
    }
    return [] as any[];
  }, [sm]);

  const mdClass = useMemo(() => {
    if (md) {
      const _md: any[] = [];
      if (md.cols) {
        _md.push(`md:${md.cols}` as any);
      }
      if (md.rows) {
        _md.push(`md:${md.rows}` as any);
      }
      return _md;
    }
    return [] as any[];
  }, [md]);

  const lgClass = useMemo(() => {
    if (lg) {
      const _lg: any[] = [];
      if (lg.cols) {
        _lg.push(`lg:${lg.cols}` as any);
      }
      if (lg.rows) {
        _lg.push(`lg:${lg.rows}` as any);
      }
      return _lg;
    }
    return [] as any[];
  }, [lg]);

  const xlClass = useMemo(() => {
    if (xl) {
      const _xl: any[] = [];
      if (xl.cols) {
        _xl.push(`xl:${xl.cols}` as any);
      }
      if (xl.rows) {
        _xl.push(`xl:${xl.rows}` as any);
      }
      return _xl;
    }
    return [] as any[];
  }, [xl]);

  const xxlClass = useMemo(() => {
    if (xxl) {
      const _xxl: any[] = [];
      if (xxl.cols) {
        _xxl.push(`2xl:${xxl.cols}` as any);
      }
      if (xxl.rows) {
        _xxl.push(`2xl:${xxl.rows}` as any);
      }
      return _xxl;
    }
    return [] as any[];
  }, [xxl]);

  return (
    <TailwindBox
      {...props}
      width={width}
      display={inline ? "inline-grid" : "grid"}
      gap={gap}
      gridCols={cols}
      gridRows={rows}
      pseudoClasses={[
        ...smClass,
        ...mdClass,
        ...lgClass,
        ...xlClass,
        ...xxlClass,
        ...(props.pseudoClasses || []),
      ]}>
      {children}
    </TailwindBox>
  );
};

export default TailwindGrid;
