import React from "react";
import "./styles.scss";
import classnames from "classnames";

type FlagIconProps = {
  className?: string;
};

const FlagIcon: React.ComponentType<FlagIconProps> = ({ className }) => (
  <span className={classnames("FlagIcon", className && className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  </span>
);

export default FlagIcon;
