import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import TailwindText, { TailwindTextProps } from "../text";
import TailwindIcon, { TailwindIconProps } from "../icon";
import tw, {
  TAppearance,
  TBackgroundColor,
  TBackgroundOpacity,
  TBorderRadius,
  TCursor,
  TFlexGrow,
  TFontSize,
  TFontWeight,
  THeight,
  TInset,
  TOpacity,
  TOutline,
  TPadding,
  TPlaceholderColor,
  TPlaceholderOpacity,
  TPosition,
  TPseudoClasses,
  TTextDecoration,
  TTransitionProperty,
  TWidth,
  TZIndex,
} from "../../../styles/tailwind-classnames";
import classnames from "classnames";
import TailwindBox, { TailwindBoxProps } from "../box";
import TextareaAutosize from "react-textarea-autosize";
import { TextareaHeightChangeMeta } from "react-textarea-autosize/dist/declarations/src";
import mergeRefs from "react-merge-refs";
import { CleaveOptions } from "cleave.js/options";
import Cleave from "cleave.js";

export type MaskProps = {
  maskProps?: CleaveOptions;
};

export enum TailwindInputType {
  "button" = "button",
  "checkbox" = "checkbox",
  "color" = "color",
  "date" = "date",
  "datetime-local" = "datetime-local",
  "email" = "email",
  "file" = "file",
  "hidden" = "hidden",
  "image" = "image",
  "month" = "month",
  "number" = "number",
  "password" = "password",
  "radio" = "radio",
  "range" = "range",
  "reset" = "reset",
  "search" = "search",
  "submit" = "submit",
  "tel" = "tel",
  "text" = "text",
  "time" = "time",
  "url" = "url",
  "week" = "week",
  "textarea" = "textarea",
}

export type TailwindInputBaseProps = Omit<
  React.HTMLProps<HTMLInputElement | HTMLTextAreaElement>,
  "className"
> &
  MaskProps & {
    maxRows?: number;
    minRows?: number;
    onHeightChange?: (height: number, meta: TextareaHeightChangeMeta) => void;
  } & {
    type?: TailwindInputType;
    name?: string;
    id?: string;
    label?: React.ReactNode;
    labelProps?: TailwindTextProps;
    leftIconProps?: TailwindIconProps;
    rightIconProps?: TailwindIconProps;
    appearance?: TAppearance;
    transitionProperty?: TTransitionProperty;
    cursor?: TCursor;
    width?: TWidth;
    height?: THeight;
    padding?: TPadding[];
    borderRadius?: TBorderRadius;
    outlineColor?: TOutline;
    backgroundColor?: TBackgroundColor;
    backgroundOpacity?: TBackgroundOpacity;
    opacity?: TOpacity;
    position?: TPosition;
    psuedoClasses?: TPseudoClasses[];
    checked?: boolean;
    zIndex?: TZIndex;
    placeholder?: string;
    placeholderOpacity?: TPlaceholderOpacity;
    placeholderColor?: TPlaceholderColor;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    wrapInput?: boolean;
    wrapperProps?: TailwindBoxProps;
    boxProps?: TailwindBoxProps;
    flexGrow?: TFlexGrow;
    fontSize?: TFontSize;
    fontWeight?: TFontWeight;
    textDecoration?: TTextDecoration;
    inset?: TInset[];
    autoFocus?: boolean;
    value?: string | number;
    className?: (string | undefined | boolean)[];
    disabled?: boolean;
    src?: string;
  };

const TailwindInputBase = React.forwardRef<
  HTMLInputElement,
  TailwindInputBaseProps
>(
  (
    {
      label,
      labelProps,
      leftIconProps,
      rightIconProps,
      id,
      name,
      type = TailwindInputType.text,
      appearance,
      transitionProperty,
      cursor,
      width,
      height,
      padding,
      borderRadius,
      outlineColor,
      backgroundColor,
      backgroundOpacity,
      opacity,
      position,
      psuedoClasses = [],
      onChange = () => {},
      zIndex,
      placeholder,
      placeholderColor,
      placeholderOpacity,
      flexGrow,
      fontSize,
      fontWeight,
      textDecoration,
      inset,
      checked,
      wrapInput,
      wrapperProps,
      boxProps,
      className,
      autoFocus,
      value,
      disabled,
      src,
      min,
      max,
      maskProps,
      ...props
    },
    ref
  ) => {
    const [_type, setType] = useState<TailwindInputType>(type);
    const localRef = useRef();

    // on safari datepicker, onChange is fired multiple times,
    // not sure if this is a bug on Safari or react's event system
    const [
      dateMultipleOnChangeCallPatchCallTime,
      setDateMultipleOnChangeCallPatchCallTime,
    ] = useState<Date | undefined>(undefined);

    const wrapper = useMemo(() => {
      let wrapper = <React.Fragment />;
      if (wrapInput) {
        wrapper = <TailwindBox {...boxProps} />;
      }

      return wrapper;
    }, [wrapInput, boxProps, label, labelProps]);

    const _backgroundColor = useMemo(() => {
      if (boxProps?.backgroundOpacity) {
        return "bg-transparent";
      } else if (wrapInput && boxProps?.backgroundColor) {
        return boxProps?.backgroundColor;
      }

      return backgroundColor;
    }, [backgroundColor, wrapInput, boxProps]);

    const inputMinMax = useMemo(() => {
      const minMax: {
        min?: number | string;
        max?: number | string;
      } = {
        min: undefined,
        max: undefined,
      };

      if (
        min &&
        ((typeof min === "number" && !isNaN(min as number)) ||
          typeof min === "string")
      ) {
        minMax.min = min;
      }
      if (
        max &&
        ((typeof max === "number" && !isNaN(max as number)) ||
          typeof max === "string")
      ) {
        minMax.max = max;
      }

      return minMax;
    }, [min, max]);

    const getShouldPreventDateFromFiringSeveralTimes = () => {
      const isFiringTooFrequent =
        ((new Date() as unknown) as number) -
          ((dateMultipleOnChangeCallPatchCallTime as unknown) as number) <
        400;

      // a second onChange is fired with value "" therefore it resets the value of the date input
      // if the second onChange is too close to the first when on a date input, we should prevent running it

      return _type === TailwindInputType.date && isFiringTooFrequent;
    };

    const _onChange = useCallback(
      event => {
        const shouldPreventDateFromFiringSeveralTimes = getShouldPreventDateFromFiringSeveralTimes();
        if (!shouldPreventDateFromFiringSeveralTimes) {
          onChange(event);
          if (_type === TailwindInputType.date) {
            setDateMultipleOnChangeCallPatchCallTime(new Date());
          }
        }
      },
      [_type, onChange]
    );

    const commonProps = useMemo(() => {
      return {
        autoFocus: autoFocus,
        type: _type,
        name: name,
        id: id,
        ref: mergeRefs([localRef, ref]),
        placeholder: placeholder,
        className: tw(
          appearance,
          transitionProperty,
          disabled ? "cursor-not-allowed" : cursor,
          width,
          height,
          borderRadius,
          outlineColor,
          _backgroundColor,
          backgroundOpacity,
          opacity,
          position,
          zIndex,
          ...(padding || []),
          placeholderColor,
          placeholderOpacity,
          flexGrow,
          fontSize,
          fontWeight,
          textDecoration,
          ...(inset || []),
          "text-current",
          "max-w-full",
          ...[...psuedoClasses, "focus:outline-none" as any]
        ),
        checked: checked,
        onChange: _onChange,
        value: value === undefined || value === null ? "" : value,
        disabled: disabled,
        src: src,
        min: inputMinMax.min,
        max: inputMinMax.max,
        ...props,
      };
    }, [
      inputMinMax,
      props,
      src,
      disabled,
      value,
      onChange,
      checked,
      psuedoClasses,
      inset,
      textDecoration,
      fontWeight,
      fontSize,
      flexGrow,
      placeholderOpacity,
      placeholderColor,
      padding,
      zIndex,
      position,
      opacity,
      backgroundOpacity,
      _backgroundColor,
      outlineColor,
      borderRadius,
      width,
      height,
      cursor,
      transitionProperty,
      appearance,
      placeholder,
      localRef,
      ref,
      id,
      name,
      _type,
      autoFocus,
    ]);

    useEffect(() => {
      if (localRef?.current && maskProps) {
        new Cleave((localRef.current as unknown) as HTMLElement, maskProps);
      }
    }, [localRef, maskProps]);

    const onClickRevealPassword = useCallback(() => {
      setType(
        _type === TailwindInputType.password
          ? TailwindInputType.text
          : TailwindInputType.password
      );
    }, [_type]);

    return (
      <TailwindBox
        {...wrapperProps}
        as={"div"}
        className={[classnames(className)]}>
        {label && (
          <TailwindText
            {...labelProps}
            as={"label"}
            htmlFor={name}
            margin={[...(labelProps?.margin || ["ml-2", "mb-1"])]}
            fontSize={labelProps?.fontSize || "text-base"}
            display={"flex"}
            textColor={labelProps?.textColor || "text-gray-300"}>
            {label}
          </TailwindText>
        )}
        {React.cloneElement(
          wrapper,
          { ...boxProps },
          <React.Fragment>
            {leftIconProps && (
              <TailwindIcon
                {...leftIconProps}
                margin={[
                  ...(leftIconProps?.margin || []),
                  "mr-2",
                ]}></TailwindIcon>
            )}
            {_type !== "textarea" ? (
              <input
                {...(commonProps as React.RefAttributes<HTMLInputElement>)}
              />
            ) : (
              <TextareaAutosize
                {...(commonProps as Omit<
                  React.RefAttributes<HTMLTextAreaElement>,
                  "style"
                >)}
                className={classnames(
                  commonProps.className,
                  "pretty-scroll"
                )}></TextareaAutosize>
            )}

            {(rightIconProps || type === TailwindInputType.password) && (
              <TailwindIcon
                {...rightIconProps}
                name={
                  type === TailwindInputType.password
                    ? "eye"
                    : rightIconProps?.name
                }
                onClick={
                  type === TailwindInputType.password
                    ? onClickRevealPassword
                    : rightIconProps?.onClick
                }
                margin={[
                  ...(leftIconProps?.margin || []),
                  "ml-2",
                ]}></TailwindIcon>
            )}
          </React.Fragment>
        )}
      </TailwindBox>
    );
  }
);

export default TailwindInputBase;
