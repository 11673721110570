export const parseResolutionString = (
  resolutionString: string
): { width: number; height: number } => {
  const resolutionStringSplitted = resolutionString.split("x");
  const resolution = {
    width: +resolutionStringSplitted[0],
    height: +resolutionStringSplitted[1],
  };
  return resolution;
};

export const getReadableResolutionFromResolutionString = (
  resolutionString?: string
) => {
  if (resolutionString) {
    const { height, width } = parseResolutionString(resolutionString);
    if (height <= 480) {
      return "360p";
    } else if (height <= 540) {
      return "540p";
    } else if (height <= 720) {
      return "720p";
    } else if (width <= 1920) {
      return "1080p";
    } else if (width <= 2560) {
      return "1440p";
    } else if (width <= 3840) {
      return "2160p";
    } else if (width > 3840) {
      return "4K";
    }
  }

  return "Unknown";
};

export const convertMbpsToKbps = (mbps: number) => {
  return mbps * 1000;
};

export const convertKbpsToBps = (mbps: number) => {
  return mbps * 125;
};

export const convertMbpsToBps = (mbps: number) => {
  return convertKbpsToBps(convertMbpsToKbps(mbps));
};

export const convertKbpsToMbps = (kbps: number) => {
  return kbps / 1000;
};

export const convertBpsToKbps = (bps: number) => {
  return bps / 125;
};

export const convertBpsToMbps = (bps: number) => {
  return convertKbpsToMbps(convertBpsToKbps(bps));
};
