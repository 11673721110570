import React, { useRef, useState, useCallback } from "react";
import { Overlay } from "react-overlays";
import { OverlayProps } from "react-overlays/cjs/Overlay";

interface Props {
  content?: React.ReactNode;
  show?: boolean;
  onShow?: () => void;
  onHide?: () => void;
  placement?: OverlayProps["placement"];
}

export const Popover: React.FunctionComponent<Props> = ({
  children,
  content,
  onHide,
  show: showFromProps,
  onShow,
  placement,
}) => {
  const hasShowFromProps = showFromProps !== undefined;
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const [show, setShow] = useState(false);
  const handleShow = useCallback(() => {
    if (hasShowFromProps) {
      onShow && onShow();
    } else {
      setShow(true);
    }
  }, [hasShowFromProps, onShow]);
  const handleHide = useCallback(() => {
    if (hasShowFromProps) {
      onHide && onHide();
    } else {
      setShow(false);
    }
  }, [hasShowFromProps, onHide]);

  return (
    <React.Fragment>
      <div onClick={handleShow} ref={triggerRef}>
        {children}
      </div>
      <Overlay
        rootClose
        placement={placement}
        show={hasShowFromProps ? showFromProps : show}
        onHide={handleHide}
        target={triggerRef}>
        {({ props }) => (
          <div {...props} style={{ ...props.style, zIndex: 1000 }}>
            {content}
          </div>
        )}
      </Overlay>
    </React.Fragment>
  );
};
