import React from "react";
import TailwindBox from "library/components/_tailwind/box";
import TailwindText from "library/components/_tailwind/text";
import "./styles.scss";

type Props = {};
const RecordKeepingRequirementsCompliance = ({}: Props) => {
  return (
    <div className={"RecordKeepingRequirementsCompliance"}>
      <TailwindText
        display={"block"}
        fontSize={"text-3xl"}
        fontWeight={"font-bold"}
        padding={["py-2"]}
        margin={["mb-4"]}>
        18 U.S.C. 2257 Record Keeping Requirements Compliance Statement
      </TailwindText>
      <TailwindBox>
        <p className={"mb-4"}>
          Any records required to be maintained pursuant to 18 USC § 2257 and
          its applicable regulations are kept by the following person:
        </p>
        <p>
          Custodian of Records <br />
          Confirm ID, Inc. <br />
          1615 S. Congress Avenue, Suite 103, <br />
          Delray Beach, Florida 33445
        </p>
      </TailwindBox>
    </div>
  );
};

export default RecordKeepingRequirementsCompliance;
