import React from "react";
import "./styles.scss";
import classnames from "classnames";

type ColumnLayoutIconProps = {
  className?: string;
};

const ColumnLayoutIcon: React.ComponentType<ColumnLayoutIconProps> = ({
  className,
}) => (
  <span className={classnames("ColumnLayoutIcon", className && className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 20 18"
    >
      <g transform="translate(0, 0)">
        <g transform="translate(0, 0) rotate(0)">
          <path
            d="M19,0h-18c-0.55,0 -1,0.45 -1,1v16c0,0.55 0.45,1 1,1h18c0.55,0 1,-0.45 1,-1v-16c0,-0.55 -0.45,-1 -1,-1zM19,17h-18v-8h18z"
            style={{
              strokeWidth: 0,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
            }}
            vectorEffect="non-scaling-stroke"
          />
        </g>
        <defs>
          <path
            id="path-16157649384531281"
            d="M19,0h-18c-0.55,0 -1,0.45 -1,1v16c0,0.55 0.45,1 1,1h18c0.55,0 1,-0.45 1,-1v-16c0,-0.55 -0.45,-1 -1,-1zM19,17h-18v-8h18z"
            vectorEffect="non-scaling-stroke"
          />
        </defs>
      </g>
    </svg>
  </span>
);

export default ColumnLayoutIcon;
