import React, { useEffect } from "react";
import "./styles.scss";
import { inject, observer } from "mobx-react";
import ProfileStore from "./stores/profile/ProfileStore";
import MediaManagerStore from "containers/_components/media-manager/store/MediaManagerStore";
import MyPageProfileBio from "./profile-bio";
import TailwindFlex from "library/components/_tailwind/flex";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import AuthStore from "core/stores/auth/AuthStore";
import SessionStorage from "library/core/utility/session-storage";
import { CAMS_MODEL_SESSION_STORAGE_KEYS } from "library/core/config/storage";
//import BlockUnblock from "containers/common/account-settings/model-block-unblock";
//import UpdateDocuments from "containers/cams/_components/update-documents";

type Props = {
  mediaManagerStore?: MediaManagerStore;
  profileStore?: ProfileStore;
  layoutStore?: LayoutStore;
  authStore?: AuthStore;
};

const MyPageContainer: React.ComponentType<Props> = ({
  layoutStore,
  authStore,
  profileStore,
}) => {
  const { setBodyPadding } = layoutStore!;
  const { openRegisterModalToCompleteProfile } = authStore!;
  const { modelProfile } = profileStore!;
  useEffect(() => {
    setBodyPadding(0);
    if (
      !SessionStorage.get(CAMS_MODEL_SESSION_STORAGE_KEYS.SUMMARY_MODAL) &&
      modelProfile.status == "DENIED"
    ) {
      openRegisterModalToCompleteProfile(true, true);
      SessionStorage.set(CAMS_MODEL_SESSION_STORAGE_KEYS.SUMMARY_MODAL, true);
    }
    return () => {
      setBodyPadding(15);
    };
  }, []);

  return (
    <TailwindFlex alignItems={"items-start"} width={"w-full"}>
      <MyPageProfileBio />
    </TailwindFlex>
  );
};

export default inject(
  "mediaManagerStore",
  "profileStore",
  "layoutStore",
  "authStore"
)(observer(MyPageContainer));
