import { EARNING_GRAPH_CATEGORY_PRODUCTS, EARNING_GRAPH_COLORS } from "common/earnings/_stores/earnings/consts";
import EarningsStore from "common/earnings/_stores/earnings/EarningsStore";
import { getTransactionInProducts } from "common/earnings/_stores/earnings/utils";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindTranslatedNumber from "library/components/_tailwind/translated-number";
import { inject, observer } from "mobx-react";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";

type Props = {
  earningsStore?: EarningsStore;
};

const PayoutGraph = ({ earningsStore }: Props) => {
  const transactions = earningsStore!.transactions;
  const intl = useIntl();
  const parts = useMemo(() => {
    const SHOWS = {
      color: EARNING_GRAPH_COLORS.SHOWS,
      labelIntl: {
        id: "payout.graph.shows",
        defaultMessage: "Shows",
      },
      sum: getTransactionInProducts(transactions, EARNING_GRAPH_CATEGORY_PRODUCTS.SHOWS),
      percent: 0,
    };
    const TIPS_AND_GIFTS = {
      color: EARNING_GRAPH_COLORS.TIPS_AND_GIFTS,
      labelIntl: {
        id: "payout.graph.tips_and_gifts",
        defaultMessage: "Tips and Gifts",
      },
      sum: getTransactionInProducts(transactions, EARNING_GRAPH_CATEGORY_PRODUCTS.TIPS_AND_GIFTS),
      percent: 0,
    };
    const REFERRALS = {
      color: EARNING_GRAPH_COLORS.REFERRALS,
      labelIntl: {
        id: "payout.graph.referrals",
        defaultMessage: "Referrals",
      },
      sum: getTransactionInProducts(transactions, []),
      percent: 0,
    };

    const GAMES = {
      color: EARNING_GRAPH_COLORS.GAMES,
      labelIntl: {
        id: "payout.graph.games",
        defaultMessage: "Games",
      },
      sum: getTransactionInProducts(transactions, EARNING_GRAPH_CATEGORY_PRODUCTS.GAMES),
      percent: 0,
    };

    const OFFLINE_CONTENT = {
      color: EARNING_GRAPH_COLORS.OFFLINE_CONTENT,
      labelIntl: {
        id: "payout.graph.offline_content",
        defaultMessage: "Offline Content",
      },
      sum: getTransactionInProducts(transactions, EARNING_GRAPH_CATEGORY_PRODUCTS.OFFLINE_CONTENT),
      percent: 0,
    };

    const FANCLUB = {
      color: EARNING_GRAPH_COLORS.FANCLUB,
      labelIntl: {
        id: "payout.graph.fanclub",
        defaultMessage: "Fanclub",
      },
      sum: getTransactionInProducts(transactions, EARNING_GRAPH_CATEGORY_PRODUCTS.FANCLUB),
      percent: 0,
    };

    const PARTS = [
      SHOWS,
      OFFLINE_CONTENT,
      TIPS_AND_GIFTS,
      GAMES,
      REFERRALS,
      FANCLUB
    ];

    const PARTS_TOTAL = PARTS.reduce(
      (acc: number, part) => (acc += part.sum),
      0
    );
    PARTS.forEach((part, index) => {
      const partPercent = part.sum / PARTS_TOTAL;
      part.percent =
        index === 0 ? partPercent : partPercent + PARTS[index - 1].percent;
    });

    return PARTS;
  }, [transactions]);

  const circleParts = useMemo(() => {
    return [...parts].reverse();
  }, [parts])

  return (
    <TailwindFlex
      flexDirection={"flex-row"}
      height={"h-full"}
      width={"w-full"}
      alignItems='items-center'
      textColor='text-main-color'
      borderRadius={"rounded-xl"}
      backgroundColor={"bg-primary-bg-color"}
      justifyContent="justify-around"
      padding={["p-4"]}>
      <div className='EarningPie'>
        <svg viewBox='0 0 32 32'>
          <circle strokeDasharray="100 100" stroke="var(--primary-bg-color)" />
          {circleParts.map((part, index) => (
            <circle
              stroke={part.color}
              key={index}
              strokeDasharray={`${Math.round(
                part.percent * 100
              )} 100`}></circle>
          ))}
        </svg>

        <div className='EarningPie__Inner'>
          <TailwindText textTransform='uppercase'>
            {intl.formatMessage({
              id: "payout.earnings.graph-total",
              defaultMessage: "Total Income",
            })}
          </TailwindText>

          <TailwindTranslatedNumber
            textProps={{
              fontSize: "text-2xl",
              className: 'fontAnton'
            }}
            value={+(earningsStore?.currentPeriodSums?.grandTotal || 0)}
            style='currency'
            currency='USD'
          />
        </div>
      </div>

      <TailwindFlex width="w-auto" margin={["ml-4"]} flexDirection='flex-row' flexWrap="flex-wrap">
        {parts.map((part, index) => (
          <TailwindFlex width={'w-1/2'} alignItems="items-center" margin={["my-1"]} key={index}>
            <div
              className='EarningGraphCircle'
              style={{
                backgroundColor: part.color,
              }}></div>
            <TailwindFlex margin={['ml-1']} flexDirection='flex-col' key={index}>
              <TailwindTranslatedNumber
                textProps={{}}
                value={part.sum}
                style='currency'
                currency='USD'
              />
              <TailwindText>{intl.formatMessage(part.labelIntl)}</TailwindText>
            </TailwindFlex>
          </TailwindFlex>
        ))}
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default inject("earningsStore")(observer(PayoutGraph));
