import React from "react";
import classnames from "classnames";
import "./styles.scss";

type Props = {
  className?: string;
};

const NavigationItemAction: React.ComponentType<Props> = ({
  className,
  children,
}) => {
  return (
    <span
      className={classnames("NavigationItem__action", className && className)}
    >
      {children}
    </span>
  );
};

export default NavigationItemAction;
