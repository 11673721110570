import React, { useMemo } from "react";
import TailwindBox, { TailwindBoxProps } from "../box";
import { TWidth } from "../../../styles/tailwind-classnames";

export type TailwindFlexProps = TailwindBoxProps & {
  xs?: TWidth;
  sm?: TWidth;
  md?: TWidth;
  lg?: TWidth;
  xl?: TWidth;
  xxl?: TWidth;
  width?: TWidth;
  inline?: boolean;
};

const TailwindFlex = React.forwardRef<HTMLDivElement, TailwindFlexProps>(
  (
    {
      xs,
      sm,
      md,
      lg,
      xl,
      xxl,
      width = "w-full",
      inline,
      display,
      children,
      ...props
    },
    ref
  ) => {
    const xsClass = useMemo(() => {
      if (xs) {
        return `xs:${xs}` as any;
      }
      return undefined;
    }, [xs]);

    const smClass = useMemo(() => {
      if (sm) {
        return `sm:${sm}` as any;
      }
      return undefined;
    }, [sm]);

    const mdClass = useMemo(() => {
      if (md) {
        return `md:${md}` as any;
      }
      return undefined;
    }, [md]);

    const lgClass = useMemo(() => {
      if (lg) {
        return `lg:${lg}` as any;
      }
      return undefined;
    }, [lg]);

    const xlClass = useMemo(() => {
      if (xl) {
        return `xl:${xl}` as any;
      }
      return undefined;
    }, [xl]);

    const xxlClass = useMemo(() => {
      if (xxl) {
        return `xxl:${xxl}` as any;
      }
      return undefined;
    }, [xxl]);

    return (
      <TailwindBox
        {...props}
        ref={ref}
        width={width}
        display={display ? display : inline ? "inline-flex" : "flex"}
        pseudoClasses={[
          xsClass,
          smClass,
          mdClass,
          lgClass,
          xlClass,
          xxlClass,
          ...(props.pseudoClasses || []),
        ]}>
        {children}
      </TailwindBox>
    );
  }
);

export default TailwindFlex;
