import config, { enableFrequentSocketLogs } from "core/config";
import { setTimeout } from "timers";
import { IBroadcastSocketMethods } from "./interfaces";
import { logger } from "library/core/utility";
import { validate } from "uuid";
import {
  SocketMiddlewareCallback,
  SocketOnAuthFailedCallback,
  SocketOnConnectedCallback,
  SocketOnDisconnectedCallback,
} from "common/broadcast/_stores/common/types";
import {
  broadcastStore,
  nodeChatStore,
  profileStore,
  logToGraylog,
} from "core/stores";
import { pricingStore } from "core/stores";
const BROADCASTING_NAMESPACE = "/ws/broadcasting/socket/";
const pongsMissedLimit = 4;

const logPrefix = "[BroadcastSocket]:";

class BroadcastSocket {
  public isConnected: boolean = false;
  private webSocket: WebSocket | undefined = undefined;
  private pongsMissed: number = 0;
  private pingTimeout: NodeJS.Timeout | undefined = undefined;
  private reloadRetryCount: number = 0;
  private isRetrying: boolean = false;
  private accessToken: string = "";
  private onConnectedCallback: SocketOnConnectedCallback = undefined;
  private onDisconnectedCallback: SocketOnDisconnectedCallback = undefined;
  private onAuthFailedCallback: SocketOnAuthFailedCallback = undefined;
  private middlewareCallback: SocketMiddlewareCallback = undefined;
  private listeners: IBroadcastSocketMethods | undefined = undefined;

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  public connect = (
    accessToken: string,
    onAuthFailedCallback: SocketOnAuthFailedCallback,
    onConnectedCallback: SocketOnConnectedCallback,
    onDisconnectedCallback: SocketOnDisconnectedCallback,
    middlewareCallback: SocketMiddlewareCallback,
    listeners: IBroadcastSocketMethods
  ) => {
    this.log("connect started");
    this.reloadRetryCount = 0;
    this.accessToken = accessToken;
    this.onAuthFailedCallback = onAuthFailedCallback;
    this.onConnectedCallback = onConnectedCallback;
    this.onDisconnectedCallback = onDisconnectedCallback;
    this.middlewareCallback = middlewareCallback;
    this.listeners = listeners;
    this.openSocket(accessToken);
    this.log("connect finished");
  };

  private setIsRetrying = (isRetrying: boolean) => {
    if (enableFrequentSocketLogs) {
      this.log("setIsRetrying started", isRetrying);
    }
    this.isRetrying = isRetrying;
    broadcastStore.onIsRetryingChanged(isRetrying);
    if (enableFrequentSocketLogs) {
      this.log("setIsRetrying finished");
    }
  };

  public disconnect = (): void => {
    this.log("disconnect started");
    this.closeSocket();
    this.pingTimeout && clearTimeout(this.pingTimeout);
    this.log("disconnect finished");
  };

  public closeSocket = () => {
    this.pingTimeout && clearTimeout(this.pingTimeout);
    this.webSocket && this.webSocket.close();
    this.webSocket = undefined;
  };

  private reload = async (): Promise<void> => {
    try {
      this.log("reload started");
      if (
        this.onAuthFailedCallback &&
        this.onConnectedCallback &&
        this.onDisconnectedCallback
      ) {
        this.setIsRetrying(true);
        const newAccessToken = await this.onAuthFailedCallback();
        this.accessToken = newAccessToken;
        this.log("reload got accessToken", newAccessToken);
        await this.onDisconnectedCallback();
        this.closeSocket();
        const delay = Math.min(
          2000 * Math.pow(2, this.reloadRetryCount),
          10000
        );
        const jitter = Math.random() * delay;
        const backoffDelay = delay / 2 + jitter / 2;
        setTimeout(() => {
          this.openSocket(this.accessToken);
          this.log("reload finished");
        }, backoffDelay);
      }
    } catch (error) {
      this.log("reload failed", error);
      this.reloadRetryCount += 1;

      this.log(
        "reload failed & started retrying with current iteration",
        this.reloadRetryCount
      );
      await this.reload();
    } finally {
      this.log("reload finished");
    }
  };

  private ping = (): void => {
    if (enableFrequentSocketLogs) {
      this.log("ping started");
    }
    this.pingTimeout = setTimeout(() => {
      if (this.pongsMissed < pongsMissedLimit) {
        this.pongsMissed++;
        if (this.isConnected) {
          this.webSocket?.send(JSON.stringify({ command: "ping" }));
          this.ping();
        }
      } else {
        this.reload();
      }
    }, 4000);
    if (enableFrequentSocketLogs) {
      this.log("ping finished");
    }
  };

  public emit = (command: string, data: any) => {
    this.log("emit started with command", command, "and data", data);
    this.webSocket?.send(JSON.stringify({ command: command, data: data }));
  };

  onOpenSocket = () => {
    this.log("onOpenSocket started");
    this.isConnected = true;
    this.setIsRetrying(false);
    this.ping();
    this.log("onOpenSocket finished");
    this.onConnectedCallback && this.onConnectedCallback();
  };

  onCloseSocket = async e => {
    this.log("onCloseSocket started", e);
    this.isConnected = false;
    const code = parseInt(e?.code);
    if (code === 4003 || code === 1006) {
      await this.reload();
    } else if (this.onDisconnectedCallback) {
      await this.onDisconnectedCallback();
    }
    if (this.middlewareCallback) {
      this.middlewareCallback("close", e);
    }
    this.log("onCloseSocket finished");
  };

  onErrorSocket = async e => {
    this.log("onErrorSocket received", e);
    if (this.middlewareCallback) {
      this.middlewareCallback("error", e);
    }
  };

  private triggerEvents = (
    method: keyof IBroadcastSocketMethods,
    data: any
  ): void => {
    this.log("triggerEvents started", method);
    const args = [data.payload || data, data.properties, data.reason];
    this.listeners?.[method]?.(...args);
    this.log("triggerEvents finished", method);
  };

  onMessageSocket = e => {
    if (enableFrequentSocketLogs) {
      this.log("onMessageSocket started");
    }
    this.setIsRetrying(false);
    const data = JSON.parse(e.data);

    if (!!data?.message_type) {
      if (enableFrequentSocketLogs) {
        this.log("handleEvents started");
      }

      switch (data.message_type) {
        case "pricing_change":
          if (data.data) {
            const {
              pending_wheel_of_fun_rewards,
              wheel_of_fun_rewards,
              ...newData
            } = data?.data;
            pricingStore.modelProducts = {
              ...newData,
              pending_wheel_of_fun_rewards:
                pricingStore.modelProducts.pending_wheel_of_fun_rewards,
              wheel_of_fun_rewards:
                pricingStore.modelProducts.wheel_of_fun_rewards,
            };
          }
          break;
        case "broadcasting":
          if (
            data?.data?.properties?.showType === "C2C"
          ) {
            switch (data?.data?.type) {
              case "SHOW_START":
                this.triggerEvents("c2c_show_start", data);
                break;
              case "SHOW_STOP":
                this.triggerEvents("c2c_show_stop", data);
                break;
              default:
                break;
            }
          } else {
            switch (data?.data?.type) {
              case "BROADCAST_START":
                this.triggerEvents("broadcast_start", data?.data);
                break;
              case "BROADCAST_STOP":
                this.triggerEvents("broadcast_stop", data?.data);
                break;
              case "SHOW_START":
                this.triggerEvents("show_start", data?.data);
                break;
              case "SHOW_STOP":
                this.triggerEvents("show_stop", data?.data);
                break;
              case "VIEW_START":
                this.triggerEvents("view_start", data?.data);
                break;
              case "VIEW_STOP":
                this.triggerEvents("view_stop", data?.data);
                break;
              case "VIEW_INTERRUPTED":
                this.triggerEvents("view_interrupted", data?.data);
                break;
              case "VIEW_RESUMED":
                this.triggerEvents("view_resumed", data?.data);
                break;
              case "PPM_CALL":
                this.triggerEvents("ppm_call", data);
                break;
              default:
                break;
            }
          }
          break;
        case "tipping_session":
          this.triggerEvents("tipping_session", data?.data);
          break;
        case "private_show_request":
          this.triggerEvents("private_show_request", data?.data);
          break;
        case "stream_stats":
          this.triggerEvents("stream_stats", data?.data);
          break;
        case "model_private_blocked":
          this.triggerEvents("model_private_blocked", {
            member_id: data?.data?.member_id,
          });
          break;
        case "subscribed_members":
          this.triggerEvents("subscribed_members", data?.data);
          break;
        case "member_joined":
          this.triggerEvents("member_joined", data?.data);
          break;
        case "member_left":
          this.triggerEvents("member_left", data?.data);
          break;
        case "model_favored":
          nodeChatStore.handleFavouredModel(data?.data);
          break;
        case "bounty_order":
          nodeChatStore.handleBountyOrder(data?.data);
          break;
        case "order_approved":
          nodeChatStore.handleFanClubOrder(data?.data);
          break;
        case "pong":
          this.pongsMissed = 0;
          break;
        default:
          break;
      }
      if (enableFrequentSocketLogs) {
        this.log("handleEvents finished");
      }
    }
    if (this.middlewareCallback) {
      this.middlewareCallback(
        data?.message_type || "Unknown message type",
        data
      );
    }
    if (enableFrequentSocketLogs) {
      this.log("onMessageSocket finished");
    }
  };

  private openSocket = (accessToken: string): void => {
    this.log("openSocket started");
    this.pongsMissed = 0;
    if (!this.isRetrying) {
      this.reloadRetryCount = 0;
    }
    if (this.webSocket) {
      return;
    }
    if (validate(profileStore.modelProfile.id) === false) {
      logToGraylog("[BroadcastSocket]", "Invalid model id", {
        modelId: profileStore.modelProfile.id,
      });
      return;
    }
    const wsUrl = this.constuctWsUrl(accessToken);
    this.webSocket = new WebSocket(wsUrl);

    Object.assign(this.webSocket, {
      onopen: this.onOpenSocket,
      onclose: this.onCloseSocket,
      onerror: this.onErrorSocket,
      onmessage: this.onMessageSocket,
    });
    this.log("openSocket finished");
  };

  private constuctWsUrl = (accessToken: string): string => {
    const wsUrl = new URL(
      `${config.broadcastApiSocket}${BROADCASTING_NAMESPACE}`
    );
    wsUrl.searchParams.append("jwt", accessToken);
    return wsUrl.toString();
  };
}

export default BroadcastSocket;
