import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import TailwindFlex from "../../components/_tailwind/flex";
import StorybookSubHeading from "../_internal/sub-heading";

type Props = {};

const StorybookFlowDeployments: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Flow &gt; Deployments</StorybookHeading>
      <StorybookParagraph>
        Deployment pipelines use Jenkins tool as well as some Docker related
        files. The branches that are created with <Code inline>feature/</Code>{" "}
        gets deployed as a feature branch under the URLs:
      </StorybookParagraph>
      <StorybookParagraph>For Models/Studios/AFF/Passion</StorybookParagraph>
      <TailwindFlex>
        <Code>feature-branch-name-models.logicbeta.com</Code>
        <Code>feature-branch-name-studios.logicbeta.com</Code>
        <Code>feature-branch-name-aff.logicbeta.com</Code>
        <Code>feature-branch-name-passion.logicbeta.com</Code>
      </TailwindFlex>
      <StorybookParagraph>For Members</StorybookParagraph>
      <TailwindFlex>
        <Code>feature-branch-name.logicbeta.com</Code>
      </TailwindFlex>
      <StorybookSubHeading>Deploying to QA & Production</StorybookSubHeading>
      <StorybookParagraph>
        When you have your branch in develop branch you need to do:
      </StorybookParagraph>
      <StorybookParagraph>For Models/Studios/AFF/Passion:</StorybookParagraph>
      <TailwindFlex>
        <Code>
          {`create MR from develop to QA
approve it yourself and merge to QA (if you don't have the rights to approve your own MR, ask the lead)
QA automatically gets deployed
grab a list of task numbers from the "commits" tab of the develop to QA MR and post them in qarequests channel with a bulletpoint list:
- BRCST-299-some-task
- BRCST-300-some-task-2
so that the tasks are tested by QA people
when tasks have been tested and approved. MRs from the tasks that got approved need to be created to master. alternatively commits on QA branch can be cherry-picked under one branch and merged to master via an MR.
when the code is on master, go to jenkins.logicbeta.com and login with your LDAP credentials.
go to https://jenkins.logicbeta.com/job/FrontendModelsReact/job/PROD/ and click Build With Parameters on the sidebar then click Build on the next page. it should be deploying to production.
after production deploy finishes, ask QA to make a live-site check.
`}
        </Code>
      </TailwindFlex>
      <StorybookParagraph>For Members:</StorybookParagraph>
      <TailwindFlex>
        <Code>
          {`create MR from develop to QA
approve it yourself and merge to QA (if you don't have the rights to approve your own MR, ask the lead)
QA automatically gets deployed
grab a list of task numbers from the "commits" tab of the develop to QA MR and post them in qarequests channel with a bulletpoint list:
- BRCST-299-some-task
- BRCST-300-some-task-2
so that the tasks are tested by QA people
when tasks have been tested and approved. MRs from the tasks that got approved need to be created to master. alternatively commits on QA branch can be cherry-picked under one branch and merged to master via an MR.
when the code is on master, go to jenkins.logicbeta.com and login with your LDAP credentials.
go to https://jenkins.logicbeta.com/job/FrontendMain/job/PROD/ and click Build With Parameters on the sidebar then click Build on the next page. it should be deploying to production.
after production deploy finishes, ask QA to make a live-site check.
`}
        </Code>
      </TailwindFlex>
    </StorybookWrapper>
  );
};

export default StorybookFlowDeployments;
