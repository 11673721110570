import React from "react";
import "./styles.scss";
import clsx from "clsx";

type KickIconProps = {
  className?: string;
};

const KickIcon: React.ComponentType<KickIconProps> = ({ className }) => (
  <span className={clsx("KickIcon", className && className)}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
      <path d='M79.1,67.24a2.49,2.49,0,0,0-3.53,0l-5,5-5-5A2.5,2.5,0,1,0,62,70.78l5,5-5,5a2.52,2.52,0,0,0,0,3.54,2.51,2.51,0,0,0,3.54,0l5-5,5,5a2.49,2.49,0,0,0,1.77.73,2.45,2.45,0,0,0,1.76-.73,2.49,2.49,0,0,0,0-3.53l-5-5,5-5A2.5,2.5,0,0,0,79.1,67.24Z' />
      <path d='M45.11,37.28A16.36,16.36,0,1,0,28.75,20.92,16.38,16.38,0,0,0,45.11,37.28Zm0-27.72A11.36,11.36,0,1,1,33.75,20.92,11.38,11.38,0,0,1,45.11,9.56Z' />
      <path d='M45.11,85.29H26.27a5,5,0,0,1-5-5V68.87A23.89,23.89,0,0,1,61.42,51.42a2.5,2.5,0,0,0,3.41-3.65,28.88,28.88,0,0,0-48.6,21.1V80.25a10,10,0,0,0,10,10H45.11a2.5,2.5,0,0,0,0-5Z' />
      <path d='M70.56,54.56A21.23,21.23,0,1,0,91.79,75.79,21.25,21.25,0,0,0,70.56,54.56Zm0,37.45A16.23,16.23,0,1,1,86.79,75.79,16.25,16.25,0,0,1,70.56,92Z' />
    </svg>
  </span>
);

export default KickIcon;
