import React from "react";
import OBSModalTabStep5 from "common/broadcast/broadcast-obs-modal/obs-modal-tab-step5";
import TailwindBox from "library/components/_tailwind/box";
import TailwindButton from "library/components/_tailwind/button";
import TailwindFlex from "library/components/_tailwind/flex";
import ModalStore from "library/core/stores/modal";
import { inject, observer } from "mobx-react";
import TailwindImage from "library/components/_tailwind/image";
import TailwindCard from "library/components/_tailwind/card";
import TailwindTranslatedText from 'library/components/_tailwind/translated-text';

type Props = {
  modalStore?: ModalStore;
};

const BroadcastObsInstructions: React.ComponentType<Props> = ({
  modalStore,
}) => {
  const { openPrimaryModal } = modalStore!;
  const onClickShowOBSModal = () => {
    openPrimaryModal(
      <TailwindCard
        backgroundColor={"primary"}
        width={"w-full"}
        pseudoClasses={["md:w-full"]}
        padding={["p-8", "pt-8"]}
        display={"flex"}
        flexDirection={"flex-col"}>
        <TailwindFlex width={"w-full"}>
          <TailwindFlex
            width={"w-1/2"}
            flexDirection={"flex-col"}
            flexGrow={"flex-grow"}
            alignItems={"items-center"}
            justifyContent={"justify-center"}>
            <TailwindTranslatedText 
              textColor='text-main-color' 
              margin={["mb-4"]}
              descriptor={{
                id: 'broadcast-obs-modal.follow-steps-image',
                defaultMessage: 'Follow the steps on the image. Numbers in the image correspond to the items in this list below.'
              }}
            />
            <OBSModalTabStep5 />
          </TailwindFlex>
          <TailwindFlex width={"w-1/2"}>
            <TailwindImage
              src={"/images/broadcast/obs-stream-setup-screenshot.png"}
            />
          </TailwindFlex>
        </TailwindFlex>
      </TailwindCard>
    );
  };
  return (
    <TailwindBox width={"w-full"}>
      <TailwindBox margin={["mb-4"]}>
        <OBSModalTabStep5 />
      </TailwindBox>
      <TailwindBox>
        <TailwindFlex
          width={"w-full"}
          justifyContent={"justify-end"}
          alignItems={"items-center"}>
          <TailwindButton
            leftIconProps={{
              name: "question-mark",
              margin: ['mr-0.5']
            }}
            fullWidth={false}
            underline
            onClick={onClickShowOBSModal}>
            <TailwindTranslatedText
              fontSize="text-sm"
              
              descriptor={{
                id: 'broadcast-obs-modal.help-im-stuck',
                defaultMessage: 'Help me, I am stuck!'
              }}
            ></TailwindTranslatedText>
          </TailwindButton>
        </TailwindFlex>
      </TailwindBox>
    </TailwindBox>
  );
};

export default inject("modalStore")(observer(BroadcastObsInstructions));
