import React, { useCallback, useMemo } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindDropdown, {
  TailwindDropdownValue,
} from "library/components/_tailwind/dropdown";
import { MEDIA_MANAGER_ALBUM_PRICE_DROPDOWN_ITEMS } from "containers/_components/media-manager/store/consts";
import TailwindText from "library/components/_tailwind/text";
import TailwindButton from "library/components/_tailwind/button";
import MediaManagerStore from "containers/_components/media-manager/store/MediaManagerStore";
import { inject, observer } from "mobx-react";

type Props = {
  mediaManagerStore?: MediaManagerStore;
};

const MediaManagerSidebarBodyDescriptionPhotosEdit: React.ComponentType<Props> =
  ({ mediaManagerStore }) => {
    const { setAlbumPrice, selectedAlbum } = mediaManagerStore!;

    const onChangeAlbumPrice = useCallback(
      (value: TailwindDropdownValue) => {
        if (value !== null && selectedAlbum) {
          setAlbumPrice(value as number, selectedAlbum);
        }
      },
      [selectedAlbum]
    );

    const priceDropdownValue = useMemo(() => {
      if (
        selectedAlbum?.price_in_token !== undefined &&
        selectedAlbum?.price_in_token !== null
      ) {
        return MEDIA_MANAGER_ALBUM_PRICE_DROPDOWN_ITEMS.find(
          item => item.value === selectedAlbum?.price_in_token
        )?.value;
      }

      return MEDIA_MANAGER_ALBUM_PRICE_DROPDOWN_ITEMS[0].value;
    }, [selectedAlbum?.price_in_token]);

    return (
      <React.Fragment>
        <TailwindFlex margin={["my-4"]}>
          <TailwindDropdown
            backgroundColor={"bg-primary-bg-color"}
            items={MEDIA_MANAGER_ALBUM_PRICE_DROPDOWN_ITEMS}
            value={priceDropdownValue}
            placeholder={"Select token amount"}
            onChange={onChangeAlbumPrice}
            name={"album-price"}
            label={"Album Price"}
            fullWidth
          />
        </TailwindFlex>
        <TailwindFlex
          flexDirection={"flex-col"}
          margin={["my-3"]}
          textColor='text-main-color'>
          <TailwindText>
            Photo albums can be free for members, or have a price set in tokens
            for the entire album
          </TailwindText>
          <TailwindText margin={["mt-4"]}>
            By default, fan club members are granted full access to all of your
            photo albums.
          </TailwindText>
          <TailwindFlex margin={["mt-4"]} flexDirection={"flex-col"}>
            <TailwindText display={"flex"} alignItems={"items-center"}>
              Click the{" "}
              <TailwindButton
                rounded
                fullWidth={false}
                display={"flex"}
                height={"h-8"}
                width={"w-8"}
                cursor={"cursor-text"}
                margin={["mr-1", "ml-2"]}
                backgroundColor={"bg-gray-600"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                leftIconProps={{
                  name: "star",
                  fontSize: "text-xl",
                  textColor: "text-white",
                }}
                bold
              />{" "}
              icon to set that image as the cover{" "}
            </TailwindText>
            <TailwindText>photo for the album</TailwindText>
          </TailwindFlex>
        </TailwindFlex>
      </React.Fragment>
    );
  };

export default inject("mediaManagerStore")(
  observer(MediaManagerSidebarBodyDescriptionPhotosEdit)
);
