import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindTabs from "../../components/_tailwind/tabs";
import { CustomIconName } from "../../components/_tailwind/icon/icons/enums";
import TailwindHeading from "../../components/_tailwind/heading";
import TailwindDivider from "../../components/_tailwind/divider";
import TailwindDropdown from "../../components/_tailwind/dropdown";
import TailwindText from "../../components/_tailwind/text";
import TailwindBox from "../../components/_tailwind/box";
import TailwindIcon from "../../components/_tailwind/icon";
import TailwindFlex from "../../components/_tailwind/flex";
import TailwindCard from "../../components/_tailwind/card";
import Avatar from "../../components/avatar";
import TailwindBadge from "../../components/_tailwind/badge";

type Props = {};

const StorybookComponentsInteractionsPage: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Interactions Page</StorybookHeading>
      <StorybookParagraph>
        These components are so far only used on AFF but will be used in other
        projects later.
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindFlex alignItems={"items-center"}>
          <TailwindIcon
            name={"notifications"}
            fontSize={"text-3xl"}
            className={"mr-4"}
          />
          <TailwindHeading level={6} className={"flex-grow"}>
            My Interactions
          </TailwindHeading>
          <TailwindBox margin={["mx-4"]}>
            <TailwindDropdown
              backgroundColor={"bg-gray-600"}

              focusBorderColor={"md:border-white"}
              items={[
                {
                  label: "Friends",
                  value: "friends",
                },
                {
                  label: "Flirt",
                  value: "Flirt",
                },
                {
                  label: "Hot List",
                  value: "hot-list",
                },
                {
                  label: "Top Fans",
                  value: "top-fans",
                },
                {
                  label: "Profile View",
                  value: "profile-view",
                },
              ]}
              value={"friends"}
              name={"list"}
            />
          </TailwindBox>
          <TailwindDropdown
            backgroundColor={"bg-gray-600"}

            focusBorderColor={"md:border-white"}
            items={[
              {
                label: "Last 30 Days",
                value: "last-30-days",
              },
              {
                label: "Last 10 Days",
                value: "last-10-days",
              },
            ]}
            value={"last-30-days"}
            name={"date"}
          />
        </TailwindFlex>
        <TailwindTabs
          margin={["my-4"]}
          headerProps={{
            buttonProps: {
              padding: ["py-6", "px-4"],
            },
          }}
          tabs={[
            {
              id: "1",
              label: (
                <TailwindBadge badgeContent={69}>Hotlisted Me</TailwindBadge>
              ),
              component: (
                <TailwindFlex
                  margin={["my-6", "mx-8"]}
                  flexDirection={"flex-col"}
                >
                  <TailwindText fontSize={"text-sm"}>Yesterday</TailwindText>
                  <TailwindCard
                    backgroundColor={"primary"}
                    margin={["mt-4"]}
                    borderRadius={"rounded-3xl"}
                  >
                    <TailwindFlex
                      alignItems={"items-center"}
                      justifyContent={"justify-between"}
                      height={"h-20"}
                    >
                      <TailwindText
                        fontSize={"text-sm"}
                        width={"w-24"}
                        textAlign={"text-center"}
                      >
                        18:40
                      </TailwindText>
                      <TailwindFlex>
                        <Avatar
                          size={"small"}
                          photoURL={
                            "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r20_s2_478203148_64132_1288112670_0_main-c740565d2b4b8c36dba717195e321857.jpg"
                          }
                          borderSize={"none"}
                          className={"mr-2"}
                        />
                        <TailwindFlex
                          flexDirection={"flex-col"}
                          width={"w-auto"}
                        >
                          <TailwindFlex>
                            <TailwindText fontSize={"text-sm"}>
                              Memberusername
                            </TailwindText>
                            <TailwindIcon
                              name={CustomIconName["gold-crown"]}
                              margin={["ml-3", "mr-2"]}
                            />
                            <TailwindIcon name={CustomIconName.verified} />
                          </TailwindFlex>
                          <TailwindText
                            textColor={"text-gray-500"}
                            fontSize={"text-xs"}
                          >
                            22F • Long Beach, CA
                          </TailwindText>
                        </TailwindFlex>
                        <TailwindFlex
                          margin={["mx-8"]}
                          width={"w-auto"}
                          alignItems={"items-center"}
                          flexGrow={"flex-grow"}
                          justifyContent={"justify-center"}
                        >
                          <TailwindFlex
                            width={"w-6"}
                            height={"h-6"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-pink-500"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mr-4"]}
                          >
                            <TailwindIcon name={CustomIconName.fire} />
                          </TailwindFlex>
                          <TailwindText>Added me to Hotlist</TailwindText>
                        </TailwindFlex>
                        <TailwindFlex width={"w-auto"} margin={["mr-3"]}>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon name={"mood"} fontSize={"text-3xl"} />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName["add-friend"]}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName.fire}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={"more_vert"}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                        </TailwindFlex>
                      </TailwindFlex>
                    </TailwindFlex>
                    <TailwindDivider style={{ height: "1px" }} />
                    <TailwindFlex
                      alignItems={"items-center"}
                      justifyContent={"justify-between"}
                      height={"h-20"}
                    >
                      <TailwindText
                        fontSize={"text-sm"}
                        width={"w-24"}
                        textAlign={"text-center"}
                      >
                        18:40
                      </TailwindText>
                      <TailwindFlex>
                        <Avatar
                          size={"small"}
                          photoURL={
                            "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r20_s2_478203148_64132_1288112670_0_main-c740565d2b4b8c36dba717195e321857.jpg"
                          }
                          borderSize={"none"}
                          className={"mr-2"}
                        />
                        <TailwindFlex
                          flexDirection={"flex-col"}
                          width={"w-auto"}
                        >
                          <TailwindFlex>
                            <TailwindText fontSize={"text-sm"}>
                              Memberusername
                            </TailwindText>
                            <TailwindIcon
                              name={CustomIconName["gold-crown"]}
                              margin={["ml-3", "mr-2"]}
                            />
                            <TailwindIcon name={CustomIconName.verified} />
                          </TailwindFlex>
                          <TailwindText
                            textColor={"text-gray-500"}
                            fontSize={"text-xs"}
                          >
                            22F • Long Beach, CA
                          </TailwindText>
                        </TailwindFlex>
                        <TailwindFlex
                          margin={["mx-8"]}
                          width={"w-auto"}
                          alignItems={"items-center"}
                          flexGrow={"flex-grow"}
                          justifyContent={"justify-center"}
                        >
                          <TailwindFlex
                            width={"w-6"}
                            height={"h-6"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-pink-500"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mr-4"]}
                          >
                            <TailwindIcon name={CustomIconName.fire} />
                          </TailwindFlex>
                          <TailwindText>Added me to Hotlist</TailwindText>
                        </TailwindFlex>
                        <TailwindFlex width={"w-auto"} margin={["mr-3"]}>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon name={"mood"} fontSize={"text-3xl"} />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName["add-friend"]}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName.fire}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={"more_vert"}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                        </TailwindFlex>
                      </TailwindFlex>
                    </TailwindFlex>
                  </TailwindCard>
                  <TailwindCard
                    borderRadius={"rounded-3xl"}
                    backgroundColor={"primary"}
                    margin={["mt-4"]}
                  >
                    <TailwindFlex
                      alignItems={"items-center"}
                      justifyContent={"justify-between"}
                      height={"h-20"}
                    >
                      <TailwindText
                        fontSize={"text-sm"}
                        width={"w-24"}
                        textAlign={"text-center"}
                      >
                        18:40
                      </TailwindText>
                      <TailwindFlex>
                        <Avatar
                          size={"small"}
                          photoURL={
                            "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r20_s2_478203148_64132_1288112670_0_main-c740565d2b4b8c36dba717195e321857.jpg"
                          }
                          borderSize={"none"}
                          className={"mr-2"}
                        />
                        <TailwindFlex
                          flexDirection={"flex-col"}
                          width={"w-auto"}
                        >
                          <TailwindFlex>
                            <TailwindText fontSize={"text-sm"}>
                              Memberusername
                            </TailwindText>
                            <TailwindIcon
                              name={CustomIconName["gold-crown"]}
                              margin={["ml-3", "mr-2"]}
                            />
                            <TailwindIcon name={CustomIconName.verified} />
                          </TailwindFlex>
                          <TailwindText
                            textColor={"text-gray-500"}
                            fontSize={"text-xs"}
                          >
                            22F • Long Beach, CA
                          </TailwindText>
                        </TailwindFlex>
                        <TailwindFlex
                          margin={["mx-8"]}
                          width={"w-auto"}
                          alignItems={"items-center"}
                          flexGrow={"flex-grow"}
                          justifyContent={"justify-center"}
                        >
                          <TailwindFlex
                            width={"w-6"}
                            height={"h-6"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-pink-500"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mr-4"]}
                          >
                            <TailwindIcon name={CustomIconName.fire} />
                          </TailwindFlex>
                          <TailwindText>Added me to Hotlist</TailwindText>
                        </TailwindFlex>
                        <TailwindFlex width={"w-auto"} margin={["mr-3"]}>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon name={"mood"} fontSize={"text-3xl"} />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName["add-friend"]}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName.fire}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={"more_vert"}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                        </TailwindFlex>
                      </TailwindFlex>
                    </TailwindFlex>
                  </TailwindCard>
                </TailwindFlex>
              ),
            },
            {
              id: "2",
              label: (
                <TailwindBadge badgeContent={21}>My Hotlist</TailwindBadge>
              ),
              component: (
                <TailwindFlex
                  margin={["my-6", "mx-8"]}
                  flexDirection={"flex-col"}
                >
                  <TailwindText fontSize={"text-sm"}>Yesterday</TailwindText>
                  <TailwindCard
                    backgroundColor={"primary"}
                    margin={["mt-4"]}
                    borderRadius={"rounded-3xl"}
                  >
                    <TailwindFlex
                      alignItems={"items-center"}
                      justifyContent={"justify-between"}
                      height={"h-20"}
                    >
                      <TailwindText
                        fontSize={"text-sm"}
                        width={"w-24"}
                        textAlign={"text-center"}
                      >
                        18:40
                      </TailwindText>
                      <TailwindFlex>
                        <Avatar
                          size={"small"}
                          photoURL={
                            "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r20_s2_478203148_64132_1288112670_0_main-c740565d2b4b8c36dba717195e321857.jpg"
                          }
                          borderSize={"none"}
                          className={"mr-2"}
                        />
                        <TailwindFlex
                          flexDirection={"flex-col"}
                          width={"w-auto"}
                        >
                          <TailwindFlex>
                            <TailwindText fontSize={"text-sm"}>
                              Memberusername
                            </TailwindText>
                            <TailwindIcon
                              name={CustomIconName["gold-crown"]}
                              margin={["ml-3", "mr-2"]}
                            />
                            <TailwindIcon name={CustomIconName.verified} />
                          </TailwindFlex>
                          <TailwindText
                            textColor={"text-gray-500"}
                            fontSize={"text-xs"}
                          >
                            22F • Long Beach, CA
                          </TailwindText>
                        </TailwindFlex>
                        <TailwindFlex
                          margin={["mx-8"]}
                          width={"w-auto"}
                          alignItems={"items-center"}
                          flexGrow={"flex-grow"}
                          justifyContent={"justify-center"}
                        >
                          <TailwindFlex
                            width={"w-6"}
                            height={"h-6"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-pink-500"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mr-4"]}
                          >
                            <TailwindIcon name={CustomIconName.fire} />
                          </TailwindFlex>
                          <TailwindText>Added me to Hotlist</TailwindText>
                        </TailwindFlex>
                        <TailwindFlex width={"w-auto"} margin={["mr-3"]}>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon name={"mood"} fontSize={"text-3xl"} />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName["add-friend"]}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName.fire}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={"more_vert"}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                        </TailwindFlex>
                      </TailwindFlex>
                    </TailwindFlex>
                    <TailwindDivider style={{ height: "1px" }} />
                    <TailwindFlex
                      alignItems={"items-center"}
                      justifyContent={"justify-between"}
                      height={"h-20"}
                    >
                      <TailwindText
                        fontSize={"text-sm"}
                        width={"w-24"}
                        textAlign={"text-center"}
                      >
                        18:40
                      </TailwindText>
                      <TailwindFlex>
                        <Avatar
                          size={"small"}
                          photoURL={
                            "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r20_s2_478203148_64132_1288112670_0_main-c740565d2b4b8c36dba717195e321857.jpg"
                          }
                          borderSize={"none"}
                          className={"mr-2"}
                        />
                        <TailwindFlex
                          flexDirection={"flex-col"}
                          width={"w-auto"}
                        >
                          <TailwindFlex>
                            <TailwindText fontSize={"text-sm"}>
                              Memberusername
                            </TailwindText>
                            <TailwindIcon
                              name={CustomIconName["gold-crown"]}
                              margin={["ml-3", "mr-2"]}
                            />
                            <TailwindIcon name={CustomIconName.verified} />
                          </TailwindFlex>
                          <TailwindText
                            textColor={"text-gray-500"}
                            fontSize={"text-xs"}
                          >
                            22F • Long Beach, CA
                          </TailwindText>
                        </TailwindFlex>
                        <TailwindFlex
                          margin={["mx-8"]}
                          width={"w-auto"}
                          alignItems={"items-center"}
                          flexGrow={"flex-grow"}
                          justifyContent={"justify-center"}
                        >
                          <TailwindFlex
                            width={"w-6"}
                            height={"h-6"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-pink-500"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mr-4"]}
                          >
                            <TailwindIcon name={CustomIconName.fire} />
                          </TailwindFlex>
                          <TailwindText>Added me to Hotlist</TailwindText>
                        </TailwindFlex>
                        <TailwindFlex width={"w-auto"} margin={["mr-3"]}>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon name={"mood"} fontSize={"text-3xl"} />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName["add-friend"]}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName.fire}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={"more_vert"}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                        </TailwindFlex>
                      </TailwindFlex>
                    </TailwindFlex>
                  </TailwindCard>
                  <TailwindCard
                    borderRadius={"rounded-3xl"}
                    backgroundColor={"primary"}
                    margin={["mt-4"]}
                  >
                    <TailwindFlex
                      alignItems={"items-center"}
                      justifyContent={"justify-between"}
                      height={"h-20"}
                    >
                      <TailwindText
                        fontSize={"text-sm"}
                        width={"w-24"}
                        textAlign={"text-center"}
                      >
                        18:40
                      </TailwindText>
                      <TailwindFlex>
                        <Avatar
                          size={"small"}
                          photoURL={
                            "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r20_s2_478203148_64132_1288112670_0_main-c740565d2b4b8c36dba717195e321857.jpg"
                          }
                          borderSize={"none"}
                          className={"mr-2"}
                        />
                        <TailwindFlex
                          flexDirection={"flex-col"}
                          width={"w-auto"}
                        >
                          <TailwindFlex>
                            <TailwindText fontSize={"text-sm"}>
                              Memberusername
                            </TailwindText>
                            <TailwindIcon
                              name={CustomIconName["gold-crown"]}
                              margin={["ml-3", "mr-2"]}
                            />
                            <TailwindIcon name={CustomIconName.verified} />
                          </TailwindFlex>
                          <TailwindText
                            textColor={"text-gray-500"}
                            fontSize={"text-xs"}
                          >
                            22F • Long Beach, CA
                          </TailwindText>
                        </TailwindFlex>
                        <TailwindFlex
                          margin={["mx-8"]}
                          width={"w-auto"}
                          alignItems={"items-center"}
                          flexGrow={"flex-grow"}
                          justifyContent={"justify-center"}
                        >
                          <TailwindFlex
                            width={"w-6"}
                            height={"h-6"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-pink-500"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mr-4"]}
                          >
                            <TailwindIcon name={CustomIconName.fire} />
                          </TailwindFlex>
                          <TailwindText>Added me to Hotlist</TailwindText>
                        </TailwindFlex>
                        <TailwindFlex width={"w-auto"} margin={["mr-3"]}>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon name={"mood"} fontSize={"text-3xl"} />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName["add-friend"]}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName.fire}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={"more_vert"}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                        </TailwindFlex>
                      </TailwindFlex>
                    </TailwindFlex>
                  </TailwindCard>
                </TailwindFlex>
              ),
            },
          ]}
        />`}
      >
        <TailwindFlex alignItems={"items-center"}>
          <TailwindIcon
            name={"notifications"}
            fontSize={"text-3xl"}
            className={"mr-4"}
          />
          <TailwindHeading level={6} className={"flex-grow"}>
            My Interactions
          </TailwindHeading>
          <TailwindBox margin={["mx-4"]}>
            <TailwindDropdown
              backgroundColor={"bg-gray-600"}

              focusBorderColor={"md:border-white"}
              items={[
                {
                  label: "Friends",
                  value: "friends",
                },
                {
                  label: "Flirt",
                  value: "Flirt",
                },
                {
                  label: "Hot List",
                  value: "hot-list",
                },
                {
                  label: "Top Fans",
                  value: "top-fans",
                },
                {
                  label: "Profile View",
                  value: "profile-view",
                },
              ]}
              value={"friends"}
              name={"list"}
            />
          </TailwindBox>
          <TailwindDropdown
            backgroundColor={"bg-gray-600"}

            focusBorderColor={"md:border-white"}
            items={[
              {
                label: "Last 30 Days",
                value: "last-30-days",
              },
              {
                label: "Last 10 Days",
                value: "last-10-days",
              },
            ]}
            value={"last-30-days"}
            name={"date"}
          />
        </TailwindFlex>
        <TailwindTabs
          margin={["my-4"]}
          headerProps={{
            buttonProps: {
              padding: ["py-6", "px-4"],
            },
          }}
          tabs={[
            {
              id: "1",
              label: (
                <TailwindBadge badgeContent={69}>Hotlisted Me</TailwindBadge>
              ),
              component: (
                <TailwindFlex
                  margin={["my-6", "mx-8"]}
                  flexDirection={"flex-col"}
                >
                  <TailwindText fontSize={"text-sm"}>Yesterday</TailwindText>
                  <TailwindCard
                    backgroundColor={"primary"}
                    margin={["mt-4"]}
                    borderRadius={"rounded-3xl"}
                  >
                    <TailwindFlex
                      alignItems={"items-center"}
                      justifyContent={"justify-between"}
                      height={"h-20"}
                    >
                      <TailwindText
                        fontSize={"text-sm"}
                        width={"w-24"}
                        textAlign={"text-center"}
                      >
                        18:40
                      </TailwindText>
                      <TailwindFlex>
                        <Avatar
                          size={"small"}
                          photoURL={
                            "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r20_s2_478203148_64132_1288112670_0_main-c740565d2b4b8c36dba717195e321857.jpg"
                          }
                          borderSize={"none"}
                          className={"mr-2"}
                        />
                        <TailwindFlex
                          flexDirection={"flex-col"}
                          width={"w-auto"}
                        >
                          <TailwindFlex>
                            <TailwindText fontSize={"text-sm"}>
                              Memberusername
                            </TailwindText>
                            <TailwindIcon
                              name={CustomIconName["gold-crown"]}
                              margin={["ml-3", "mr-2"]}
                            />
                            <TailwindIcon name={CustomIconName.verified} />
                          </TailwindFlex>
                          <TailwindText
                            textColor={"text-gray-500"}
                            fontSize={"text-xs"}
                          >
                            22F • Long Beach, CA
                          </TailwindText>
                        </TailwindFlex>
                        <TailwindFlex
                          margin={["mx-8"]}
                          width={"w-auto"}
                          alignItems={"items-center"}
                          flexGrow={"flex-grow"}
                          justifyContent={"justify-center"}
                        >
                          <TailwindFlex
                            width={"w-6"}
                            height={"h-6"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-pink-500"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mr-4"]}
                          >
                            <TailwindIcon name={CustomIconName.fire} />
                          </TailwindFlex>
                          <TailwindText>Added me to Hotlist</TailwindText>
                        </TailwindFlex>
                        <TailwindFlex width={"w-auto"} margin={["mr-3"]}>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon name={"mood"} fontSize={"text-3xl"} />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName["add-friend"]}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName.fire}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={"more_vert"}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                        </TailwindFlex>
                      </TailwindFlex>
                    </TailwindFlex>
                    <TailwindDivider style={{ height: "1px" }} />
                    <TailwindFlex
                      alignItems={"items-center"}
                      justifyContent={"justify-between"}
                      height={"h-20"}
                    >
                      <TailwindText
                        fontSize={"text-sm"}
                        width={"w-24"}
                        textAlign={"text-center"}
                      >
                        18:40
                      </TailwindText>
                      <TailwindFlex>
                        <Avatar
                          size={"small"}
                          photoURL={
                            "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r20_s2_478203148_64132_1288112670_0_main-c740565d2b4b8c36dba717195e321857.jpg"
                          }
                          borderSize={"none"}
                          className={"mr-2"}
                        />
                        <TailwindFlex
                          flexDirection={"flex-col"}
                          width={"w-auto"}
                        >
                          <TailwindFlex>
                            <TailwindText fontSize={"text-sm"}>
                              Memberusername
                            </TailwindText>
                            <TailwindIcon
                              name={CustomIconName["gold-crown"]}
                              margin={["ml-3", "mr-2"]}
                            />
                            <TailwindIcon name={CustomIconName.verified} />
                          </TailwindFlex>
                          <TailwindText
                            textColor={"text-gray-500"}
                            fontSize={"text-xs"}
                          >
                            22F • Long Beach, CA
                          </TailwindText>
                        </TailwindFlex>
                        <TailwindFlex
                          margin={["mx-8"]}
                          width={"w-auto"}
                          alignItems={"items-center"}
                          flexGrow={"flex-grow"}
                          justifyContent={"justify-center"}
                        >
                          <TailwindFlex
                            width={"w-6"}
                            height={"h-6"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-pink-500"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mr-4"]}
                          >
                            <TailwindIcon name={CustomIconName.fire} />
                          </TailwindFlex>
                          <TailwindText>Added me to Hotlist</TailwindText>
                        </TailwindFlex>
                        <TailwindFlex width={"w-auto"} margin={["mr-3"]}>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon name={"mood"} fontSize={"text-3xl"} />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName["add-friend"]}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName.fire}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={"more_vert"}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                        </TailwindFlex>
                      </TailwindFlex>
                    </TailwindFlex>
                  </TailwindCard>
                  <TailwindCard
                    borderRadius={"rounded-3xl"}
                    backgroundColor={"primary"}
                    margin={["mt-4"]}
                  >
                    <TailwindFlex
                      alignItems={"items-center"}
                      justifyContent={"justify-between"}
                      height={"h-20"}
                    >
                      <TailwindText
                        fontSize={"text-sm"}
                        width={"w-24"}
                        textAlign={"text-center"}
                      >
                        18:40
                      </TailwindText>
                      <TailwindFlex>
                        <Avatar
                          size={"small"}
                          photoURL={
                            "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r20_s2_478203148_64132_1288112670_0_main-c740565d2b4b8c36dba717195e321857.jpg"
                          }
                          borderSize={"none"}
                          className={"mr-2"}
                        />
                        <TailwindFlex
                          flexDirection={"flex-col"}
                          width={"w-auto"}
                        >
                          <TailwindFlex>
                            <TailwindText fontSize={"text-sm"}>
                              Memberusername
                            </TailwindText>
                            <TailwindIcon
                              name={CustomIconName["gold-crown"]}
                              margin={["ml-3", "mr-2"]}
                            />
                            <TailwindIcon name={CustomIconName.verified} />
                          </TailwindFlex>
                          <TailwindText
                            textColor={"text-gray-500"}
                            fontSize={"text-xs"}
                          >
                            22F • Long Beach, CA
                          </TailwindText>
                        </TailwindFlex>
                        <TailwindFlex
                          margin={["mx-8"]}
                          width={"w-auto"}
                          alignItems={"items-center"}
                          flexGrow={"flex-grow"}
                          justifyContent={"justify-center"}
                        >
                          <TailwindFlex
                            width={"w-6"}
                            height={"h-6"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-pink-500"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mr-4"]}
                          >
                            <TailwindIcon name={CustomIconName.fire} />
                          </TailwindFlex>
                          <TailwindText>Added me to Hotlist</TailwindText>
                        </TailwindFlex>
                        <TailwindFlex width={"w-auto"} margin={["mr-3"]}>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon name={"mood"} fontSize={"text-3xl"} />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName["add-friend"]}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName.fire}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={"more_vert"}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                        </TailwindFlex>
                      </TailwindFlex>
                    </TailwindFlex>
                  </TailwindCard>
                </TailwindFlex>
              ),
            },
            {
              id: "2",
              label: (
                <TailwindBadge badgeContent={21}>My Hotlist</TailwindBadge>
              ),
              component: (
                <TailwindFlex
                  margin={["my-6", "mx-8"]}
                  flexDirection={"flex-col"}
                >
                  <TailwindText fontSize={"text-sm"}>Yesterday</TailwindText>
                  <TailwindCard
                    backgroundColor={"primary"}
                    margin={["mt-4"]}
                    borderRadius={"rounded-3xl"}
                  >
                    <TailwindFlex
                      alignItems={"items-center"}
                      justifyContent={"justify-between"}
                      height={"h-20"}
                    >
                      <TailwindText
                        fontSize={"text-sm"}
                        width={"w-24"}
                        textAlign={"text-center"}
                      >
                        18:40
                      </TailwindText>
                      <TailwindFlex>
                        <Avatar
                          size={"small"}
                          photoURL={
                            "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r20_s2_478203148_64132_1288112670_0_main-c740565d2b4b8c36dba717195e321857.jpg"
                          }
                          borderSize={"none"}
                          className={"mr-2"}
                        />
                        <TailwindFlex
                          flexDirection={"flex-col"}
                          width={"w-auto"}
                        >
                          <TailwindFlex>
                            <TailwindText fontSize={"text-sm"}>
                              Memberusername
                            </TailwindText>
                            <TailwindIcon
                              name={CustomIconName["gold-crown"]}
                              margin={["ml-3", "mr-2"]}
                            />
                            <TailwindIcon name={CustomIconName.verified} />
                          </TailwindFlex>
                          <TailwindText
                            textColor={"text-gray-500"}
                            fontSize={"text-xs"}
                          >
                            22F • Long Beach, CA
                          </TailwindText>
                        </TailwindFlex>
                        <TailwindFlex
                          margin={["mx-8"]}
                          width={"w-auto"}
                          alignItems={"items-center"}
                          flexGrow={"flex-grow"}
                          justifyContent={"justify-center"}
                        >
                          <TailwindFlex
                            width={"w-6"}
                            height={"h-6"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-pink-500"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mr-4"]}
                          >
                            <TailwindIcon name={CustomIconName.fire} />
                          </TailwindFlex>
                          <TailwindText>Added me to Hotlist</TailwindText>
                        </TailwindFlex>
                        <TailwindFlex width={"w-auto"} margin={["mr-3"]}>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon name={"mood"} fontSize={"text-3xl"} />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName["add-friend"]}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName.fire}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={"more_vert"}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                        </TailwindFlex>
                      </TailwindFlex>
                    </TailwindFlex>
                    <TailwindDivider style={{ height: "1px" }} />
                    <TailwindFlex
                      alignItems={"items-center"}
                      justifyContent={"justify-between"}
                      height={"h-20"}
                    >
                      <TailwindText
                        fontSize={"text-sm"}
                        width={"w-24"}
                        textAlign={"text-center"}
                      >
                        18:40
                      </TailwindText>
                      <TailwindFlex>
                        <Avatar
                          size={"small"}
                          photoURL={
                            "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r20_s2_478203148_64132_1288112670_0_main-c740565d2b4b8c36dba717195e321857.jpg"
                          }
                          borderSize={"none"}
                          className={"mr-2"}
                        />
                        <TailwindFlex
                          flexDirection={"flex-col"}
                          width={"w-auto"}
                        >
                          <TailwindFlex>
                            <TailwindText fontSize={"text-sm"}>
                              Memberusername
                            </TailwindText>
                            <TailwindIcon
                              name={CustomIconName["gold-crown"]}
                              margin={["ml-3", "mr-2"]}
                            />
                            <TailwindIcon name={CustomIconName.verified} />
                          </TailwindFlex>
                          <TailwindText
                            textColor={"text-gray-500"}
                            fontSize={"text-xs"}
                          >
                            22F • Long Beach, CA
                          </TailwindText>
                        </TailwindFlex>
                        <TailwindFlex
                          margin={["mx-8"]}
                          width={"w-auto"}
                          alignItems={"items-center"}
                          flexGrow={"flex-grow"}
                          justifyContent={"justify-center"}
                        >
                          <TailwindFlex
                            width={"w-6"}
                            height={"h-6"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-pink-500"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mr-4"]}
                          >
                            <TailwindIcon name={CustomIconName.fire} />
                          </TailwindFlex>
                          <TailwindText>Added me to Hotlist</TailwindText>
                        </TailwindFlex>
                        <TailwindFlex width={"w-auto"} margin={["mr-3"]}>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon name={"mood"} fontSize={"text-3xl"} />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName["add-friend"]}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName.fire}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={"more_vert"}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                        </TailwindFlex>
                      </TailwindFlex>
                    </TailwindFlex>
                  </TailwindCard>
                  <TailwindCard
                    borderRadius={"rounded-3xl"}
                    backgroundColor={"primary"}
                    margin={["mt-4"]}
                  >
                    <TailwindFlex
                      alignItems={"items-center"}
                      justifyContent={"justify-between"}
                      height={"h-20"}
                    >
                      <TailwindText
                        fontSize={"text-sm"}
                        width={"w-24"}
                        textAlign={"text-center"}
                      >
                        18:40
                      </TailwindText>
                      <TailwindFlex>
                        <Avatar
                          size={"small"}
                          photoURL={
                            "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/photo_ffadult_i_r20_s2_478203148_64132_1288112670_0_main-c740565d2b4b8c36dba717195e321857.jpg"
                          }
                          borderSize={"none"}
                          className={"mr-2"}
                        />
                        <TailwindFlex
                          flexDirection={"flex-col"}
                          width={"w-auto"}
                        >
                          <TailwindFlex>
                            <TailwindText fontSize={"text-sm"}>
                              Memberusername
                            </TailwindText>
                            <TailwindIcon
                              name={CustomIconName["gold-crown"]}
                              margin={["ml-3", "mr-2"]}
                            />
                            <TailwindIcon name={CustomIconName.verified} />
                          </TailwindFlex>
                          <TailwindText
                            textColor={"text-gray-500"}
                            fontSize={"text-xs"}
                          >
                            22F • Long Beach, CA
                          </TailwindText>
                        </TailwindFlex>
                        <TailwindFlex
                          margin={["mx-8"]}
                          width={"w-auto"}
                          alignItems={"items-center"}
                          flexGrow={"flex-grow"}
                          justifyContent={"justify-center"}
                        >
                          <TailwindFlex
                            width={"w-6"}
                            height={"h-6"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-pink-500"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mr-4"]}
                          >
                            <TailwindIcon name={CustomIconName.fire} />
                          </TailwindFlex>
                          <TailwindText>Added me to Hotlist</TailwindText>
                        </TailwindFlex>
                        <TailwindFlex width={"w-auto"} margin={["mr-3"]}>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon name={"mood"} fontSize={"text-3xl"} />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName["add-friend"]}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={CustomIconName.fire}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                          <TailwindFlex
                            width={"w-12"}
                            height={"h-12"}
                            borderRadius={"rounded-full"}
                            backgroundColor={"bg-gray-800"}
                            justifyContent={"justify-center"}
                            alignItems={"items-center"}
                            margin={["mx-1"]}
                            cursor={"cursor-pointer"}
                          >
                            <TailwindIcon
                              name={"more_vert"}
                              fontSize={"text-3xl"}
                            />
                          </TailwindFlex>
                        </TailwindFlex>
                      </TailwindFlex>
                    </TailwindFlex>
                  </TailwindCard>
                </TailwindFlex>
              ),
            },
          ]}
        />
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsInteractionsPage;
