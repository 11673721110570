import React, { useCallback, useMemo } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import PayoutStore from "./stores/payout/PayoutStore";
import { inject, observer } from "mobx-react";
import { CamsPayoutSettingDirectDeposit } from "./stores/payout/types";
import TailwindInput from "library/components/_tailwind/input";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import TailwindDropdown, { TailwindDropdownValue } from "library/components/_tailwind/dropdown";

type Props = {
  payoutStore?: PayoutStore;
  validationStore?: ValidationStore;
};

const CamsPayoutDirectDepositForm: React.ComponentType<Props> = ({ payoutStore, validationStore }) => {
  const { errors, validate } = validationStore!;

  const { activePayoutSetting, updatePaymentDetails } = payoutStore!;

  const onChangeAccountType = useCallback(
    (value: TailwindDropdownValue) => {
      if (value) {
        updatePaymentDetails(activePayoutSetting, "account_type", value as string);
      }
    },
    [activePayoutSetting]
  );

  const accountTypes = useMemo(
    () => [
      {
        value: "checking",
        label: "Checking",
      },
      {
        value: "saving",
        label: "Savings",
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <TailwindFlex margin={["mb-2"]}>
        Receive money directly to your bank account only in the United States
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>Minimum payment is $50.</TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindDropdown
          backgroundColor={"secondary"}
          fullWidth
          name={"account_type"}
          label={"Account Type"}
          value={(activePayoutSetting?.payout_settings as CamsPayoutSettingDirectDeposit)?.account_type}
          onChange={onChangeAccountType}
          items={accountTypes}
        />
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindInput
          backgroundColor={"secondary"}
          required={true}
          variant={"contained"}
          label={"Account Name"}
          error={errors["account_name"] || ""}
          onBlur={_ =>
            validate(
              "account_name",
              (activePayoutSetting?.payout_settings as CamsPayoutSettingDirectDeposit)?.account_name
            )
          }
          value={(activePayoutSetting?.payout_settings as CamsPayoutSettingDirectDeposit)?.account_name}
          onChange={e => updatePaymentDetails(activePayoutSetting, "account_name", e.target.value)}
          name={"account_name"}
        />
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindInput
          backgroundColor={"secondary"}
          required={true}
          variant={"contained"}
          label={"Routing Number"}
          error={errors["routing_number"] || ""}
          onBlur={_ =>
            validate(
              "routing_number",
              (activePayoutSetting?.payout_settings as CamsPayoutSettingDirectDeposit)?.routing_number
            )
          }
          value={(activePayoutSetting?.payout_settings as CamsPayoutSettingDirectDeposit)?.routing_number}
          onChange={e => updatePaymentDetails(activePayoutSetting, "routing_number", e.target.value)}
          name={"routing_number"}
        />
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindInput
          backgroundColor={"secondary"}
          required={true}
          variant={"contained"}
          label={"Account Number"}
          error={errors["account_number"] || ""}
          onBlur={_ =>
            validate(
              "account_number",
              (activePayoutSetting?.payout_settings as CamsPayoutSettingDirectDeposit)?.account_number
            )
          }
          value={(activePayoutSetting?.payout_settings as CamsPayoutSettingDirectDeposit)?.account_number}
          onChange={e => updatePaymentDetails(activePayoutSetting, "account_number", e.target.value)}
          name={"account_number"}
        />
      </TailwindFlex>
    </React.Fragment>
  );
};

export default inject("payoutStore", "validationStore")(observer(CamsPayoutDirectDepositForm));
