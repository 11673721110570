import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import SwipeableSlider from "../../components/swipeable-slider";
import TailwindFlex from "../../components/_tailwind/flex";
import { TBackgroundColor } from "../../styles/tailwind-classnames";
import TailwindText from "../../components/_tailwind/text";
import chunk from "lodash/chunk";

type Props = {};

const StorybookComponentsSwipeableSlider: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Swipeable Slider</StorybookHeading>
      <StorybookParagraph>
        Swipeable Slider is named this way because you can also use it on mobile
        devices. It is basically a carousel component that you swipe manually
        however it can also act like a Carousel via its autoPlay plugin.
      </StorybookParagraph>
      <StorybookParagraph>
        For slider to work, you provide it "slides". If for example you have 10
        photos to show in one slide, you need to chunk the array of photos into
        amounts of slides per photo (let's say 10) for it to work properly. For
        chunking you can use{" "}
        <Code inline>import chunk from "lodash/chunk"</Code> Here are some of
        the props it can take:
      </StorybookParagraph>
      <Code>
        {`export type SwipeableSliderProps = {
  children?: any;
  arrowSize?: SwipeableSliderArrowSize;
  iconClassName?: string;
  className?: string;
  swipeableViewsProps?: SwipeableViewsProps;
};

export type SwipeableViewsProps = SwipeableViewsAutoplayProps & {
  index?: number;
  onChangeIndex?: (index: number, indexLatest: number, meta: any) => void;
};

type SwipeableViewsAutoplayProps = {
  autoplay?: boolean;
  direction?: "incremental" | "decremental";
  interval?: number;
};

export type SwipeableSliderArrowSize =
  | "large"
  | "medium"
  | "small"
  | "extra-small";

`}
      </Code>
      <StorybookParagraph>
        For the sake of showcasing appropriately, source code and the showcase
        are seperate
      </StorybookParagraph>
      <TailwindFlex height={"h-96"}>
        <SwipeableSlider>
          {[0, 1, 2].map((slide) => (
            <TailwindFlex
              key={slide}
              height={"h-full"}
              alignItems={"items-center"}
              justifyContent={"justify-center"}
              backgroundColor={
                ["bg-red-500", "bg-yellow-500", "bg-blue-500"][
                  slide
                ] as TBackgroundColor
              }
            >
              <TailwindText fontSize={"text-4xl"}>{slide + 1}</TailwindText>
            </TailwindFlex>
          ))}
        </SwipeableSlider>
      </TailwindFlex>
      <Code>
        {`<TailwindFlex height={"h-96"}>
  <SwipeableSlider>
    {[0, 1, 2].map((slide) => (
      <TailwindFlex
        key={slide}
        height={"h-full"}
        alignItems={"items-center"}
        justifyContent={"justify-center"}
        backgroundColor={
          ["bg-red-500", "bg-yellow-500", "bg-blue-500"][
            slide
          ] as TBackgroundColor
        }
      >
        <TailwindText fontSize={"text-4xl"}>{slide + 1}</TailwindText>
      </TailwindFlex>
    ))}
  </SwipeableSlider>
</TailwindFlex>       
`}
      </Code>
      <TailwindFlex height={"h-96"}>
        <SwipeableSlider>
          {chunk([0, 1, 2, 3, 4, 5, 6, 7], 2).map((chunk, index) => (
            <TailwindFlex key={`chunk-index-${index}`} height={"h-full"}>
              {chunk.map((slide) => (
                <TailwindFlex
                  key={slide}
                  height={"h-full"}
                  alignItems={"items-center"}
                  justifyContent={"justify-center"}
                  backgroundColor={
                    [
                      "bg-red-500",
                      "bg-yellow-500",
                      "bg-blue-500",
                      "bg-pink-500",
                      "bg-purple-500",
                      "bg-yellow-900",
                      "bg-green-500",
                      "bg-indigo-500",
                    ][slide] as TBackgroundColor
                  }
                >
                  <TailwindText fontSize={"text-4xl"}>{slide + 1}</TailwindText>
                </TailwindFlex>
              ))}
            </TailwindFlex>
          ))}
        </SwipeableSlider>
      </TailwindFlex>
      <Code>
        {`<TailwindFlex height={"h-96"}>
  <SwipeableSlider>
    {chunk([0, 1, 2, 3, 4, 5, 6, 7], 2).map((chunk, index) => (
      <TailwindFlex key={\`chunk-index-\${index}\`} height={"h-full"}>
        {chunk.map((slide) => (
          <TailwindFlex
            key={slide}
            height={"h-full"}
            alignItems={"items-center"}
            justifyContent={"justify-center"}
            backgroundColor={
              [
                "bg-red-500",
                "bg-yellow-500",
                "bg-blue-500",
                "bg-pink-500",
                "bg-purple-500",
                "bg-yellow-900",
                "bg-green-500",
                "bg-indigo-500",
              ][slide] as TBackgroundColor
            }
          >
            <TailwindText fontSize={"text-4xl"}>{slide + 1}</TailwindText>
          </TailwindFlex>
        ))}
      </TailwindFlex>
    ))}
  </SwipeableSlider>
</TailwindFlex>
`}
      </Code>
      <StorybookParagraph>Autoplay example</StorybookParagraph>
      <TailwindFlex height={"h-96"}>
        <SwipeableSlider
          swipeableViewsProps={{ autoplay: true, interval: 2000 }}
        >
          {chunk([0, 1, 2, 3, 4, 5, 6, 7], 2).map((chunk, index) => (
            <TailwindFlex key={`chunk-index-${index}`} height={"h-full"}>
              {chunk.map((slide) => (
                <TailwindFlex
                  key={slide}
                  height={"h-full"}
                  alignItems={"items-center"}
                  justifyContent={"justify-center"}
                  backgroundColor={
                    [
                      "bg-red-500",
                      "bg-yellow-500",
                      "bg-blue-500",
                      "bg-pink-500",
                      "bg-purple-500",
                      "bg-yellow-900",
                      "bg-green-500",
                      "bg-indigo-500",
                    ][slide] as TBackgroundColor
                  }
                >
                  <TailwindText fontSize={"text-4xl"}>{slide + 1}</TailwindText>
                </TailwindFlex>
              ))}
            </TailwindFlex>
          ))}
        </SwipeableSlider>
      </TailwindFlex>
      <Code>
        {`<TailwindFlex height={"h-96"}>
  <SwipeableSlider
    swipeableViewsProps={{ autoplay: true, interval: 2000 }}
  >
    {chunk([0, 1, 2, 3, 4, 5, 6, 7], 2).map((chunk, index) => (
      <TailwindFlex key={\`chunk-index-\${index}\`} height={"h-full"}>
        {chunk.map((slide) => (
          <TailwindFlex
            key={slide}
            height={"h-full"}
            alignItems={"items-center"}
            justifyContent={"justify-center"}
            backgroundColor={
              [
                "bg-red-500",
                "bg-yellow-500",
                "bg-blue-500",
                "bg-pink-500",
                "bg-purple-500",
                "bg-yellow-900",
                "bg-green-500",
                "bg-indigo-500",
              ][slide] as TBackgroundColor
            }
          >
            <TailwindText fontSize={"text-4xl"}>{slide + 1}</TailwindText>
          </TailwindFlex>
        ))}
      </TailwindFlex>
    ))}
  </SwipeableSlider>
</TailwindFlex>
        
`}
      </Code>
    </StorybookWrapper>
  );
};

export default StorybookComponentsSwipeableSlider;
