import React, { CSSProperties, useMemo } from "react";
import "./styles.scss";
import { injectIntl, WrappedComponentProps } from "react-intl";
import classnames from "classnames";
import ProfileSilhouetteIcon from "../_tailwind/icon/icons/profile-silhouette";
import Image from "../image";
import { TBackgroundColor, TFontSize } from "../../styles/tailwind-classnames";
import TailwindBox from "../_tailwind/box";
import TailwindIcon from "../_tailwind/icon";

type AvatarProps = {
  photoURL?: string;
  username?: string;
  getPhotoURLFromStore?: boolean;
  borderSize?: "thick" | "normal" | "thin" | "none";
  borderColor?: "title-color-1" | "yellow" | "purple" | "none";
  backgroundColor?: TBackgroundColor;
  isSelected?: boolean;
  checkMarl?: JSX.Element;
  size?:
    | "normal"
    | "small"
    | "extra-small"
    | "large"
    | "larger"
    | "fit"
    | "2extra-small";
  shapeVariant?: "circle" | "none" | "soft";
  className?: string;
  isTwoLettersInitial?: boolean;
  customLabel?: string;
  fontSize?: TFontSize;
  icon?: "person" | "group";
  randomBackgroundColor?: boolean;
  showPing?: boolean;
};

const Avatar: React.FunctionComponent<AvatarProps & WrappedComponentProps> = ({
  photoURL = "",
  username = "",
  borderSize = "none",
  borderColor,
  size = "normal",
  shapeVariant = "circle",
  backgroundColor = "bg-avatar-primary-bg-color",
  className,
  isTwoLettersInitial = false,
  customLabel = "",
  fontSize = "text-xl",
  icon,
  isSelected = false,
  checkMarl = null,
  randomBackgroundColor = false,
  showPing = false,
}) => {
  const backgroundColors = [
    "#EA2323",
    "#8922B5",
    "#A603CE",
    "#51149B",
    "#532AB7",
    "#3243AB",
    "#157DE3",
    "#0392E0",
    "#36A13B",
    "#6EAD37",
    "#FCC439",
    "#FEA715",
    "#F88A19",
  ];
  const background = useMemo(() => {
    const userNumber = Array.from(username)
      .map(char => char.charCodeAt(0))
      .join("");
    const color =
      backgroundColors[parseInt(userNumber, 10) % backgroundColors.length];

    return color;
  }, [username]);

  const initial = useMemo(() => {
    if (customLabel) {
      return customLabel;
    } else {
      return !isTwoLettersInitial
        ? username?.charAt(0)?.toUpperCase()
        : username?.split(" ")[0].charAt(0)?.toUpperCase() +
            username?.split(" ")[1].charAt(0)?.toUpperCase();
    }
  }, [username]);

  const __style = useMemo((): CSSProperties => {
    const customStyles = {
      overflow: "visible",
    };
    if (randomBackgroundColor) {
      customStyles["backgroundColor"] = background;
    }
    return customStyles;
  }, [background]);
  
  return (
    <div
      className={classnames(
        "Avatar",
        `Avatar--size-${size}`,
        `Avatar--shape-${shapeVariant}`,
        `Avatar--border-size-${borderSize}`,
        `Avatar--border-color-${borderColor}`,
        backgroundColor && backgroundColor,
        className && className
      )}
      style={__style}>
      {showPing ? (
        <TailwindBox
          width='w-full'
          height='h-full'
          className={["animate-ping"]}
          style={{
            backgroundColor: __style.backgroundColor,
            zIndex: -1,
          }}
          position='absolute'
          borderRadius='rounded-full'
        />
      ) : null}
      <Image
        className="rounded-full"
        src={photoURL}
        unloader={
          username || customLabel ? (
            <TailwindBox
              className={["initial-avatar"]}
              textColor='text-white'
              fontWeight='font-bold'
              fontSize={fontSize}>
              {initial}
            </TailwindBox>
          ) : icon === "group" ? (
            <TailwindIcon
              name='people-icon'
              textColor='text-white'
              fontSize={fontSize}
            />
          ) : (
            <ProfileSilhouetteIcon className={"Avatar__profile-silhouette"} />
          )
        }
      />
      {isSelected && checkMarl && (
        <TailwindBox
          className={["filterCheck"]}
          position='absolute'
          zIndex='z-10'
          width='w-auto'
          style={{
            bottom: "-7px",
            right: "-10px",
          }}>
          {checkMarl}
        </TailwindBox>
      )}
    </div>
  );
};

export default injectIntl(Avatar);
