import React, { FunctionComponent, useCallback } from "react";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import "../styles.scss";
import AuthStore from "core/stores/auth/AuthStore";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import {
  CAMS_STREAMS_EMAIL,
  CAMS_SUPPORT_PHONE_NUMBER,
} from "library/core/config/urls";

type LandingCoverProps = {
  authStore?: AuthStore;
};

const LandingCover: FunctionComponent<
  LandingCoverProps & WrappedComponentProps
> = ({ intl, authStore }) => {
  const { userRole } = authStore!;

  const sendTo = useCallback(url => {
    window.location.href = url;
  }, []);

  return (
    <React.Fragment>
      <div className={"LandingCover"}>
        <div className='LandingCover__content'>
          <div className={"LandingCover__block"}>
            <h1 className={"LandingCover__title"}>
              {intl.formatMessage({
                id: "landing.youNeed.title",
                defaultMessage: "You Need",
              })}
            </h1>
            <div className={"LandingCover__text"}>
              <ul>
                <li>
                  {intl.formatMessage({
                    id: "landing.youNeed.aComputer",
                    defaultMessage: "A Computer",
                  })}
                </li>
                <li>
                  {intl.formatMessage({
                    id: "landing.youNeed.aWebcam",
                    defaultMessage: "A Webcam",
                  })}
                </li>
                <li>
                  {intl.formatMessage({
                    id: "landing.youNeed.anInternetConnection",
                    defaultMessage: "An Internet Connection",
                  })}
                </li>
              </ul>
            </div>
          </div>
          <div
            className={"LandingCover__block LandingCover__block--arrow-border"}>
            <h1 className={"LandingCover__title"}>
              {intl.formatMessage({
                id: "landing.provideUs.title",
                defaultMessage: "Provide Us",
              })}
            </h1>
            <div className={"LandingCover__text"}>
              <ul>
                <li>
                  {intl.formatMessage({
                    id: "landing.provideUs.oneFormOfIdentification",
                    defaultMessage: "One Form of Identification",
                  })}
                </li>
                <li>
                  {intl.formatMessage({
                    id: `${
                      userRole === "model"
                        ? "landing.provideUs.aUniqueModelName"
                        : "landing.provideUs.aUniqueStudioNamePassword"
                    }`,
                    defaultMessage: `A Unique ${
                      (userRole as string) || ""
                    } Name`,
                  })}
                </li>
              </ul>
            </div>
          </div>
          <div className={"LandingCover__block"}>
            <h1 className={"LandingCover__title"}>
              {intl.formatMessage({
                id: "landing.questions.title",
                defaultMessage: "Questions?",
              })}
            </h1>
            <div className={"LandingCover__text"}>
              <ul className={"LandingCover__list"}>
                <li
                  className={
                    "LandingCover__list-item dimelo_chat_hide_when_offline"
                  }>
                  <span className={"LandingCover__list-item--chat"}></span>
                </li>

                <li className={"LandingCover__list-item"}>
                  <span className={"LandingCover__list-item--phone"}></span>
                  <span className={"text-lg"}>{CAMS_SUPPORT_PHONE_NUMBER}</span>
                </li>
                <li
                  onClick={_ => sendTo(`mailto: ${CAMS_STREAMS_EMAIL}`)}
                  className={"LandingCover__link LandingCover__list-item"}>
                  <span className={"LandingCover__list-item--mail"}></span>
                  <TailwindTranslatedText
                    fontSize={"text-lg"}
                    descriptor={{
                      id: "landing.questions.email-us",
                      defaultMessage: "Email Us",
                    }}
                  />
                </li>
                {userRole !== "model" && (
                  <li
                    className={"LandingCover__link LandingCover__list-items"}
                    onClick={_ =>
                      sendTo(
                        "https://models.streamray.com/p/page.cgi?p=obs_support&stream=1"
                      )
                    }>
                    <span className={"LandingCover__list-item--obs"}></span>
                    <TailwindTranslatedText
                      descriptor={{
                        id: "landing.questions.obs-support",
                        defaultMessage: "OBS Support",
                      }}
                    />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default injectIntl(
  inject("authStore", "modalStore")(observer(LandingCover))
);
