import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import TailwindText from "library/components/_tailwind/text";
import {
  CAMS_MODEL_APPLICATIONS_EMAIL,
  CAMS_STUDIO_APPLICATIONS_EMAIL,
  CAMS_SUPPORT_PHONE_NUMBER,
  CAMS_SUPPORT_TALL_PHONE_NUMBER,
} from "library/core/config/urls";

const PleaseContactUs: FunctionComponent<{ isStudio: boolean }> = ({
  isStudio,
}) => {
  return (
    <TailwindText textColor='text-main-color' fontSize={"text-sm"}>
      <FormattedMessage
        id='landing.summaryStep.pleaseContactUs'
        defaultMessage={`Please contact us if you have any questions:
                        {emailLink}
                        Phone:
                        {phoneLocal} (U.S.A. and International)
                        {phoneToll} (Toll free domestic)`}
        values={{
          emailLink: (
            <React.Fragment>
              <br />
              Email:
              <a
                href={`email:${
                  isStudio
                    ? CAMS_STUDIO_APPLICATIONS_EMAIL
                    : CAMS_MODEL_APPLICATIONS_EMAIL
                }`}
                className='ml-2 underline text-blue-500 cursor-pointer text-sm'>
                {isStudio
                  ? CAMS_STUDIO_APPLICATIONS_EMAIL
                  : CAMS_MODEL_APPLICATIONS_EMAIL}
              </a>
              <br />
            </React.Fragment>
          ),
          phoneLocal: (
            <React.Fragment>
              <br />
              <a
                href={`tel:${CAMS_SUPPORT_PHONE_NUMBER}`}
                className='underline text-blue-500 cursor-pointer  text-sm'>
                {CAMS_SUPPORT_PHONE_NUMBER}
              </a>
            </React.Fragment>
          ),
          phoneToll: (
            <React.Fragment>
              <br />
              <a
                href={`tel:${CAMS_SUPPORT_TALL_PHONE_NUMBER}`}
                className='underline text-blue-500 cursor-pointer  text-sm'>
                {CAMS_SUPPORT_TALL_PHONE_NUMBER}
              </a>
            </React.Fragment>
          ),
        }}
      />
    </TailwindText>
  );
};
export default PleaseContactUs;
