import React from "react";
import { inject, observer } from "mobx-react";

import TailwindCard from "library/components/_tailwind/card";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindFlex from "library/components/_tailwind/flex";
import EarningsStore from "../_stores/earnings/EarningsStore";
import TailwindTranslatedDate from "library/components/_tailwind/translated-date";
import TailwindTranslatedNumber from "library/components/_tailwind/translated-number";
import TailwindText from "library/components/_tailwind/text";

type PeriodEarningsModalProps = {
  earningsStore?: EarningsStore;
};

const PeriodEarningsModal: React.FunctionComponent<PeriodEarningsModalProps> =
  ({ earningsStore }) => {
    const { periodEarningSummary, errorLoadingFilters } = earningsStore!;

    return (
      <TailwindCard
        display={"flex"}
        flexDirection={"flex-col"}
        width={"w-96"}
        padding={["p-4"]}
        backgroundColor={"bg-gray-100"}
        pseudoClasses={["md:w-full"]}>
        <TailwindFlex
          maxHeight={"max-h-full"}
          overflow={["overflow-y-auto"]}
          width={"w-full"}
          flexDirection={"flex-col"}>
          <TailwindFlex padding={["pb-3"]}>
            <TailwindTranslatedText
              descriptor={{
                id: "studioPayout.earning.periodEarningSummary",
                defaultMessage: "PERIOD EARNING SUMMARY",
              }}
              headingProps={{ level: 6 }}
            />
          </TailwindFlex>
          <TailwindFlex flexDirection={"flex-col-reverse"} width={"w-full"}>
            {periodEarningSummary.length === 0 ? (
              <TailwindFlex
                justifyContent={"justify-center"}
                alignItems={"items-center"}
                width={"w-full"}
                height={"h-full"}>
                {errorLoadingFilters
                  ? "Error getting period earning summary"
                  : "No data"}
              </TailwindFlex>
            ) : (
              periodEarningSummary.map((period, index) => (
                <TailwindFlex
                  padding={["p-1", "pl-4", "pr-4"]}
                  pseudoClasses={["even:bg-gray-100", "odd:bg-gray-200"]}
                  key={`row${index + 1}`}
                  flexDirection={"flex-row"}
                  width={"w-full"}>
                  <TailwindFlex width={"w-1/2"}>
                    <TailwindTranslatedDate
                      value={period?.start_time}
                      day={"2-digit"}
                      month={"2-digit"}
                      textProps={{ fontSize: "text-base" }}
                    />
                    <TailwindText
                      fontSize={"text-base"}
                      margin={["ml-1", "mr-1"]}>
                      -
                    </TailwindText>
                    <TailwindTranslatedDate
                      value={period?.end_time}
                      day={"2-digit"}
                      month={"2-digit"}
                      textProps={{ fontSize: "text-base" }}
                    />
                  </TailwindFlex>
                  <TailwindFlex width={"w-1/2"} justifyContent={"justify-end"}>
                    <TailwindTranslatedNumber
                      value={period?.period_total_earnings * 0.1}
                      style='currency'
                      currency='USD'
                    />
                  </TailwindFlex>
                </TailwindFlex>
              ))
            )}
          </TailwindFlex>
        </TailwindFlex>
      </TailwindCard>
    );
  };

export default inject("earningsStore")(observer(PeriodEarningsModal));
