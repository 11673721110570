import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";

type Props = {};

const StorybookArchitectureMonoRepo: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Architecture &gt; Monorepo</StorybookHeading>
      <StorybookParagraph>
        The Model/Studio center code also hosts AFF & Passion projects. The
        reason behind that is there is code in there that makes a single piece
        of code act differently based on the domain it serves the FE code. There
        are already a set of reusable components as well as utilities/stores
        that enables single piece of FE code to work between different
        looks/functionalities.
      </StorybookParagraph>
      <StorybookParagraph>
        The idea is with this documentation app is to slowly extract
        components/utilities in a flexible and reusable way so that if we decide
        in the future to extract AFF & Passion project code to another
        repository (which we may, as those projects will most likely need NextJS
        for Server Side Rendering), we can keep using the same set of components
        and utilities across projects.
      </StorybookParagraph>
      <StorybookParagraph>
        The Architecture section explains the core functionalities that allows
        multiple products residing in single piece of FE code.
      </StorybookParagraph>
    </StorybookWrapper>
  );
};

export default StorybookArchitectureMonoRepo;
