import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindAnchor from "library/components/_tailwind/anchor";
import { injectIntl, WrappedComponentProps } from "react-intl";
import PayoutStore from "./stores/payout/PayoutStore";
import { inject, observer } from "mobx-react";
import { CamsPayoutSettingPaxum } from "./stores/payout/types";
import TailwindInput from "library/components/_tailwind/input";
import ValidationStore from "library/core/stores/validation/ValidationStore";

type Props = {
  payoutStore?: PayoutStore;
  validationStore?: ValidationStore;
};

const CamsPayoutPaxumForm: React.ComponentType<Props & WrappedComponentProps> = ({
  payoutStore,
  validationStore,
  intl,
}) => {
  const { errors, validate } = validationStore!;

  const { activePayoutSetting, updatePaymentDetails } = payoutStore!;

  return (
    <React.Fragment>
      <TailwindFlex margin={["mb-2"]} alignItems={"items-center"} flexDirection={"flex-col"}>
        <span>
          Since Paypal does not support adult-oriented payments, we use a similar service called PAXUM (3% fee
          charged).&nbsp;
          {intl.formatMessage({
            id: `payout.payoutSettings.dontHaveAccount`,
            defaultMessage: "If you don't have an account click",
          })}
          &nbsp;
          <TailwindAnchor to={"https://secure.paxum.com/payment/register.php?view=views/register.xsl"}>
            here
          </TailwindAnchor>
        </span>
      </TailwindFlex>
      <TailwindFlex margin={["mb-4"]}>
        <TailwindInput
          backgroundColor={"secondary"}
          required={true}
          variant={"contained"}
          label={"Paxum E-mail Address"}
          error={errors["email"] || ""}
          onBlur={_ => validate("email", (activePayoutSetting?.payout_settings as CamsPayoutSettingPaxum)?.email)}
          value={(activePayoutSetting?.payout_settings as CamsPayoutSettingPaxum)?.email}
          onChange={e => updatePaymentDetails(activePayoutSetting, "email", e.target.value)}
          name={"email"}
        />
      </TailwindFlex>
    </React.Fragment>
  );
};

export default injectIntl(inject("payoutStore", "validationStore")(observer(CamsPayoutPaxumForm)));
