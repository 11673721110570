import React from "react";
import TailwindBox from "library/components/_tailwind/box";
import TailwindText from "library/components/_tailwind/text";
import "./styles.scss";
import { FALLBACK_PAYOUT_SCHEDULE } from "core/constants";

type Props = {};
const PercentagePayoutSchedule = ({}: Props) => {
  return (
    <div className={"PayoutSchedule"}>
      <TailwindText
        display={"block"}
        fontSize={"text-3xl"}
        fontWeight={"font-bold"}
        padding={["py-2"]}
        margin={["mb-4"]}>
        Payout Schedule
      </TailwindText>
      <TailwindBox
        dangerouslySetInnerHTML={{
          __html: FALLBACK_PAYOUT_SCHEDULE,
        }}></TailwindBox>
    </div>
  );
};

export default PercentagePayoutSchedule;
