import React, { useCallback, useMemo } from "react";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import Sidebar from "containers/_layout/dashboard/sidebar";
import { inject, observer } from "mobx-react";
import "./styles.scss";
import MiniStreamViewer from "../../../common/broadcast/mini-stream-viewer";
import NanoPlayerRoot from "../../../common/broadcast/nano-player-root";
import DashboardBody from "./dashboard-body";
import Header from "./header";
import Modal from "library/components/modal";
import ModalStore from "library/core/stores/modal";
import TailwindFlex from "library/components/_tailwind/flex";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import { BreakPoints } from "library/core/stores/layout/types";
import MessageStore from "common/messages/stores/messages/MessageStore";

type DashboardLayoutProps = {
  modalStore?: ModalStore;
  profileStore?: ProfileStore;
  layoutStore?: LayoutStore;
  messageStore?: MessageStore;
};

const DashboardLayout: React.ComponentType<DashboardLayoutProps> = ({
  modalStore,
  layoutStore,
  messageStore,
  children,
}) => {
  const { closePrimaryModal, closeSecondaryModal, closeAuthModal, modals } =
    modalStore!;
  const { isMobileDevice, screenOrientation, windowWidth, userAgent } =
    layoutStore!;
  const { messageInputFocused } = messageStore!;

  const onClosePrimaryModalClicked = useCallback(() => {
    closePrimaryModal();
  }, []);

  const onCloseSecondaryModalClicked = useCallback(() => {
    closeSecondaryModal();
  }, []);
  const onCloseAuthModalClicked = useCallback(() => {
    closeAuthModal();
  }, []);

  const showHeader = useMemo(() => {
    if (
      isMobileDevice &&
      screenOrientation === "landscape" &&
      windowWidth !== null &&
      windowWidth <= BreakPoints.md &&
      userAgent === "Android" &&
      messageInputFocused
    ) {
      return false;
    }

    return true;
  }, [
    isMobileDevice,
    screenOrientation,
    windowWidth,
    userAgent,
    messageInputFocused,
  ]);

  return (
    <>
      <TailwindFlex
        height={"h-full"}
        maxHeight={"max-h-full"}
        overflow={["overflow-hidden"]}
        zIndex={"z-900"}
        flexDirection={"flex-col"}
        flexGrow={"flex-grow"}
        backgroundColor={"bg-primary-bg-color"}>
        {showHeader && <Header />}
        <TailwindFlex
          flexGrow={"flex-grow"}
          position={"relative"}
          overflow={["overflow-hidden"]}>
          <Sidebar />
          <DashboardBody>{children}</DashboardBody>
        </TailwindFlex>
      </TailwindFlex>
      <Modal
        className={"DashboardModal"}
        open={modals["primary"].active}
        closeModal={onClosePrimaryModalClicked}
        {...(modals["primary"].modalProps || {})}>
        <>{modals["primary"].content}</>
      </Modal>
      <Modal
        className={"DashboardModal"}
        open={modals["secondary"].active}
        closeModal={onCloseSecondaryModalClicked}
        {...(modals["secondary"].modalProps || {})}>
        <>{modals["secondary"].content}</>
      </Modal>
      <Modal
        className={"DashboardModal"}
        open={modals["auth"].active}
        closeModal={onCloseAuthModalClicked}
        {...(modals["auth"].modalProps || {})}>
        <>{modals["auth"].content}</>
      </Modal>
      <MiniStreamViewer />
      <NanoPlayerRoot />
    </>
  );
};

export default inject(
  "modalStore",
  "profileStore",
  "layoutStore",
  "messageStore"
)(observer(DashboardLayout));
