import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import StudioPayoutTableRow from "./studio-payout-table-row";
import { inject, observer } from "mobx-react";
import EarningsStore from "common/earnings/_stores/earnings/EarningsStore";
import TailwindSpinner from "library/components/_tailwind/spinner";

type Props = {
  earningsStore?: EarningsStore;
};

const StudioPayoutTableBody: React.ComponentType<Props> = ({
  earningsStore,
}) => {
  const { earnings, loadingEarnings, errorLoadingEarnings } = earningsStore!;

  if (loadingEarnings) {
    return (
      <TailwindFlex
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        width={"w-full"}
        height={"h-full"}>
        <TailwindSpinner />
      </TailwindFlex>
    );
  }

  if (!earnings.length || errorLoadingEarnings) {
    return (
      <TailwindFlex
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        width={"w-full"}
        height={"h-full"}
        padding={["p-5"]}>
        There are no earnings for the selected period.
      </TailwindFlex>
    );
  }

  return (
    <TailwindFlex
      flexDirection={"flex-col"}
      height={"h-full"}
      overflow={["overflow-y-auto", "overflow-x-hidden"]}>
      {earnings?.map((earning, index) => (
        <TailwindFlex
          key={`${index}-t`}
          borderColor={"border-table-secondary-color"}
          borderStyle={"border-solid"}
          borderWidth={["border-b"]}>
          <StudioPayoutTableRow earning={earning} />
        </TailwindFlex>
      ))}
    </TailwindFlex>
  );
};

export default inject("earningsStore")(observer(StudioPayoutTableBody));
