import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import MyFansMemberTransactionsTableRow from "containers/model/my-fans/member-transactions-table-row";
import TailwindText from "library/components/_tailwind/text";
import MyFansStore from "containers/model/my-fans/stores/my-fans/MyFansStore";
import { inject, observer } from "mobx-react";
import { MY_FANS_TAB } from "./stores/my-fans/enums";
import TailwindSpinner from "library/components/_tailwind/spinner";

type Props = {
  myFansStore?: MyFansStore;
};

const MyFansMemberTransactionsTableBody: React.ComponentType<Props> = ({
  myFansStore,
}) => {
  const {
    loadingMemberTransactions,
    selectedMemberId,
    sortedSelectedMemberTransactions,
    activeTab,
  } = myFansStore!;

  if (loadingMemberTransactions) {
    return (
      <TailwindFlex
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        width={"w-full"}
        height={"h-full"}>
        <TailwindSpinner />
      </TailwindFlex>
    );
  }

  if (!selectedMemberId || !sortedSelectedMemberTransactions.length) {
    return (
      <TailwindFlex
        justifyContent={"justify-center"}
        alignItems={"items-center"}
        width={"w-full"}
        height={"h-2/3"}>
        <TailwindText fontSize={"text-lg"} textColor={"text-main-color"}>
          There are no transactions {selectedMemberId ? "for this member" : ""}{" "}
          in the past{" "}
          {activeTab === MY_FANS_TAB.LAST_30_DAYS ? "30 days." : "year."}
        </TailwindText>
      </TailwindFlex>
    );
  }

  return (
    <TailwindFlex
      height={"h-full"}
      flexDirection={"flex-col"}
      overflow={["overflow-y-auto", "overflow-x-hidden"]}>
      {sortedSelectedMemberTransactions.map(transaction => (
        <MyFansMemberTransactionsTableRow
          key={`${transaction.id}-t`}
          transaction={transaction}
        />
      ))}
    </TailwindFlex>
  );
};

export default inject("myFansStore")(
  observer(MyFansMemberTransactionsTableBody)
);
