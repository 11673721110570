import TailwindText from "library/components/_tailwind/text";
import React from "react";
import { VIDEO_REJECT_REASONS_MAP } from "./store/consts";
import TailwindTooltip from "library/components/_tailwind/tooltip";

export interface MediaManagerMediaCardRejectReasonProps {
  titleRejectionReason?: string;
  descriptionRejectionReason?: string;
  videoRejectionReason?: string;
}

const MediaManagerMediaCardRejectReason: React.FunctionComponent<MediaManagerMediaCardRejectReasonProps> = ({
  titleRejectionReason,
  descriptionRejectionReason,
  videoRejectionReason,
  children,
}) => {
  return (
    <TailwindTooltip
      content={
        <TailwindText>
          Rejection reasons: <br />
          {videoRejectionReason &&
            `-Video: ${VIDEO_REJECT_REASONS_MAP[videoRejectionReason]}`}{" "}
          <br />
          {titleRejectionReason &&
            `-Title: ${VIDEO_REJECT_REASONS_MAP[titleRejectionReason]}`}{" "}
          <br />
          {descriptionRejectionReason &&
            `-Description: ${VIDEO_REJECT_REASONS_MAP[descriptionRejectionReason]}`}
        </TailwindText>
      }>
      {children as any}
    </TailwindTooltip>
  );
};

export default MediaManagerMediaCardRejectReason;
