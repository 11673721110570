import React, { useEffect, useMemo, useState } from "react";
import { TMargin } from "../../../styles/tailwind-classnames";
import TailwindBox from "../box";
import TailwindFlex, { TailwindFlexProps } from "../flex";

export type TailwindMasonryBreakPoint = {
  size: number | "default",
  cols: number
}

type TailwindMasonryProps = TailwindFlexProps & {
  colGapMargin?: TMargin[],
  rowGapMargin?: TMargin[],
  breakPoints?: TailwindMasonryBreakPoint[],
}

const TailwindMasonry: React.ComponentType<TailwindMasonryProps> = ({
  colGapMargin = ["ml-4"],
  rowGapMargin = ["mb-4"],
  breakPoints = [
    {size: 500, cols: 1},
    {size: 768, cols: 2},
    {size: 1000, cols: 3},
    {size: 1440, cols: 4},
    {size: 1900, cols: 5},
    {size: 3000, cols: 7},
    {size: "default", cols: 4},
  ],

  children,
  ...props
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentCols, setCurrentCols] = useState<number>(1); 

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth);
    return () => window.removeEventListener("resize", updateWindowWidth);
  });

  const columns = useMemo(() => {
      if(!children || !children.length) {
        return [];
      }
      let n = 0;
      let dflt;
      breakPoints.sort((a, b) => {
        return a.size > b.size ? 1 : -1;
      }).forEach((bp: TailwindMasonryBreakPoint) => {
        if(bp.size === "default") {
          dflt = bp.cols;
        } else {
          if(windowWidth <= bp.size && n === 0) {
            n = bp.cols;
          }
        }
      })

      if(n === 0 && dflt) {
        n = dflt;
      } else if (n === 0) {
        n = 1;
      }
      setCurrentCols(n);
      const _children: React.ReactNode[] = React.Children.toArray(children);
      const _columns: React.ReactNode[][] = [];
      for(let c = 0; c < n; c++) {
          _columns[c] = [];
      }
      for(let i = 0; i < _children.length; i++) {
          const colIdx = i % n;
          _columns[colIdx].push(_children[i]);
      }
      return _columns;
  }, [children, windowWidth])

  return (
    <TailwindFlex {...props}>
      {columns.map((col, i) => {
        return (
            <TailwindFlex key={"masonry" + i} flexDirection={"flex-col"} margin={i !== 0 ? colGapMargin: []} style={{width: `${(1 / currentCols) * 100}%` }}>
                {col.map((child: React.ReactNode, _i) => {
                  return (
                    <TailwindBox key={"masonry-child" + _i} margin={_i !== (col.length - 1) ? rowGapMargin : []}>
                      {child}
                    </TailwindBox>
                  );
                })}
            </TailwindFlex>
        );
      })}
    </TailwindFlex>
  );
};
export default TailwindMasonry;