export enum PAYOUT_METHOD_WIRE_TRANSFER_TYPES_ENUM {
  DOMESTIC = "Domestic",
  INTERNATIONAL = "International",
}

export enum CAMS_MODEL_PAYOUT_METHOD_KEY {
  check = "check",
  "wired_transfer" = "wired_transfer",
  direct_deposit = "direct_deposit",
  "paxum" = "paxum",
}
