import React from "react";
import TailwindText from "library/components/_tailwind/text";

type Props = {};

const MediaManagerSidebarBodyDescriptionVideos: React.ComponentType<Props> =
  ({}) => {
    return (
      <TailwindText margin={["mb-3"]} textColor='text-main-color'>
        Videos uploaded here will appear on your profile page. Click each video
        to set price, title, and visibility options
      </TailwindText>
    );
  };

export default MediaManagerSidebarBodyDescriptionVideos;
