import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import { api, tokenHelper } from "core/utility";
import config from "core/config";
import { createFormData, isImage, isVideo } from "core/utility/misc";
import axios from "axios";
import shortid from "shortid";
import {
  ModelAlbum,
  ModelAlbumType,
  ModelImage,
  ModelMedia,
  ModelMediaAlbumDefaultPrice,
  ModelMediaPendingConversionInterval,
  ModelMediaSorting,
  ModelMediaStatusFilter,
  ModelMediaType,
  ModelMediaTypeFilter,
  ModelVideo,
} from "./types";
import {
  ModelMediaManagerMode,
  ModelMediaRouteMode,
  ModelMediaVisibility,
  MyPagePromptMode,
} from "./enums";
import { InitialData, MEDIA_PER_PAGE_LIMIT } from "./consts";
import { modalStore } from "library/core/stores/modal";
import debounce from "lodash/debounce";
import arrayMove from "array-move";
import { validationStore } from "library/core/stores/validation/ValidationStore";
import { snackbarStore } from "library/core/stores/snackbar/SnackbarStore";
import { logger } from "library/core/utility";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";
import moment from "moment";
import { routeStore } from "core/stores/route/RouteStore";
import { AppCamsModelRouteKey } from "core/stores/route/enums";
import { profileStore } from "core/stores";

const logPrefix = "[MediaManagerStore]:";

export default class MediaManagerStore {
  albums: ModelAlbum[] = [];
  albumIds: string[] = [];

  isLoadingAlbums: boolean = InitialData.isLoadingAlbums;

  promptMode: MyPagePromptMode | null = InitialData.promptMode;
  promptResolveCb: Function | null = InitialData.promptResolveCb;
  promptRejectCb: Function | null = InitialData.promptRejectCb;
  isSaving: boolean = InitialData.isSaving;

  activeAlbumFilter: ModelMediaTypeFilter =
    InitialData.activeAlbumFilter as any;
  activeStatusFilter: ModelMediaStatusFilter =
    InitialData.activeAlbumFilter as any;
  activeMediaSorting: ModelMediaSorting = InitialData.activeMediaSorting as any;

  selectedAlbumId: string | null = InitialData.selectedAlbumId;

  formErrors: Object = InitialData.formErrors;
  lastImageOrVideo: ModelImage | ModelVideo | undefined;
  pendingConversionMediasPollingIntervals: ModelMediaPendingConversionInterval[] =
    [];
  pendingConversionMediasReaction: IReactionDisposer | undefined =
    InitialData.pendingConversionMediasReaction;
  currentRoutePathNameReaction: IReactionDisposer | undefined =
    InitialData.currentRoutePathNameReaction;
  defaultAlbumPrices: ModelMediaAlbumDefaultPrice =
    InitialData.defaultAlbumPrices;
  managerMode: ModelMediaManagerMode | undefined = undefined;
  routeMode: ModelMediaRouteMode | undefined = undefined;
  albumNextHash = InitialData.albumNextHash;

  constructor() {
    makeAutoObservable(this);
    //this.setPendingConversionMediasReaction();
    this.setCurrentRoutePathNameReaction();
  }

  resetStore = () => {
    if (this.pendingConversionMediasPollingIntervals.length) {
      this.pendingConversionMediasPollingIntervals.forEach(interval =>
        clearInterval(interval.intervalId as any)
      );
    }
    this.clearPendingConversionMediasReaction();
    this.clearCurrentRoutePathNameReaction();
    Object.entries(InitialData).map(([key, value]) => (this[key] = value));
  };

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  setManagerMode = (mode: ModelMediaManagerMode | undefined) => {
    this.log("setManagerMode started", mode);
    this.managerMode = mode;
    this.log("setManagerMode finished");
  };

  setRouteMode = (mode: ModelMediaRouteMode | undefined) => {
    this.log("setRouteMode started", mode);
    this.routeMode = mode;
    this.log("setRouteMode finished");
  };

  getAlbumsWithoutAnyMedia = (albums: ModelAlbum[]) => {
    return albums.filter(album => album.num_media === 0);
  };

  getAlbumsWithMedia = (albums: ModelAlbum[]) => {
    return albums.filter(album => album.num_media > 0);
  };

  setCurrentRoutePathNameReaction = () => {
    this.clearCurrentRoutePathNameReaction();
    this.currentRoutePathNameReaction = reaction(
      () => routeStore.currentRoutePathname,
      () => {
        this.clearEmptyCustomAlbumsOnRouteChange();
      }
    );
  };

  clearCurrentRoutePathNameReaction = () => {
    if (this.currentRoutePathNameReaction) {
      this.currentRoutePathNameReaction();
      this.currentRoutePathNameReaction = undefined;
    }
  };

  clearEmptyCustomAlbumsOnRouteChange = () => {
    if (
      !routeStore.currentRoutePathname.includes(
        routeStore.getSiteRouteURL(AppCamsModelRouteKey.myPhotosEdit)
      )
    ) {
      const albumsWithoutMedia = this.getAlbumsWithoutAnyMedia(this.albums);
      albumsWithoutMedia
        .filter(album => album.album_type === "custom")
        .filter(
          album =>
            (album.images || []).length === 0 &&
            (album.videos || []).length === 0
        )
        .forEach(album => {
          this.deleteMedia(album, true);
        });
    }
  };

  clearPendingConversionMediaPollingInterval = (media: ModelMedia) => {
    //this.log("clearPendingConversionMediaPollingInterval started");
    const interval = this.pendingConversionMediasPollingIntervals.find(
      interval => interval.mediaId === media.id
    );
    if (interval) {
      /*this.log(
        "clearPendingConversionMediaPollingInterval found existing interval, clearing"
      );*/
      clearInterval(interval.intervalId as any);
      this.pendingConversionMediasPollingIntervals =
        this.pendingConversionMediasPollingIntervals.filter(
          _interval => (_interval.intervalId = interval.intervalId)
        );
    }
    //this.log("clearPendingConversionMediaPollingInterval finished");
  };

  checkAndAddPollingIntervalToPendingConversionMedia = () => {
    //this.log("checkAndAddPollingIntervalToPendingConversionMedia started");

    const allMedia = this.getAllMediaFromAllAlbums();
    const hasAnyMediaInPendingConversation =
      allMedia.find(media => media.media_status === "pending_conversion") !==
      undefined;

    if (hasAnyMediaInPendingConversation) {
      /*this.log(
        "checkAndAddPollingIntervalToPendingConversionMedia found album has media"
      );*/
      allMedia.forEach((media: ModelImage | ModelVideo) => {
        if (media.media_status === "pending_conversion") {
          /*this.log(
            "checkAndAddPollingIntervalToPendingConversionMedia found media is in pending conversion"
          );*/
          const existingInterval =
            this.pendingConversionMediasPollingIntervals.find(
              interval => interval.mediaId === media.id
            );
          if (!existingInterval) {
            /*this.log(
              "checkAndAddPollingIntervalToPendingConversionMedia found media is in pending conversion and there is NOT an existing interval, adding interval"
            );*/
            const interval = setInterval(async () => {
              /*this.log(
                "checkAndAddPollingIntervalToPendingConversionMedia fetching media to see if it has converted"
              );*/
              const _media = await this.getAlbumMediaById(media);
              if (_media) {
                if (_media?.media_status !== "pending_conversion") {
                  /*this.log(
                    "checkAndAddPollingIntervalToPendingConversionMedia media has been converted, clearing interval"
                  );*/
                  this.clearPendingConversionMediaPollingInterval(media);
                  this.manageImageOrVideo(_media, "replace");
                } else {
                  /*this.log(
                    "checkAndAddPollingIntervalToPendingConversionMedia media still has not been converted"
                  );*/
                }
              }
            }, 2000);
            this.pendingConversionMediasPollingIntervals.push({
              mediaId: media.id,
              intervalId: interval as unknown as number,
            });
          } else {
            /*this.log(
              "checkAndAddPollingIntervalToPendingConversionMedia found media is in pending conversion and there is an existing interval, did not add new interval"
            );*/
          }
        } else {
          this.clearPendingConversionMediaPollingInterval(media);
        }
      });
    }
    //this.log("checkAndAddPollingIntervalToPendingConversionMedia finished");
  };

  clearPendingConversionMediasReaction = () => {
    if (this.pendingConversionMediasReaction) {
      this.pendingConversionMediasReaction();
      this.pendingConversionMediasReaction = undefined;
    }
  };

  setPendingConversionMediasReaction = () => {
    this.clearPendingConversionMediasReaction();
    this.pendingConversionMediasReaction = reaction(
      () => this.albums,
      () => {
        this.checkAndAddPollingIntervalToPendingConversionMedia();
      }
    );
  };

  public static getMediaPosterUrl = (media: any, preview = false) => {
    if (media) {
      let image;
      if (preview) {
        // !FIXME - using thumb (cropped) is a temporary workaround for not having the "main" (non-cropped) version available on new images
        image =
          media.poster_image ||
          media.images?.thumb ||
          media.preview_image ||
          media.image ||
          media.cover_image?.image;
      } else
        image =
          media.poster_image ||
          media.image ||
          media.preview_image ||
          media.image ||
          media.cover_image?.image;
      if (image) {
        const fixedImage = image.replace(".original.", ".superphoto.");
        return fixedImage;
      }
    }

    return "";
  };

  public static isImageUrlLegacy = (url: string) => {
    const regex = /(?:\.([^.]+))?$/;
    const validatedRegex = regex.exec(url);
    if (validatedRegex && validatedRegex[1]) {
      const extension = validatedRegex[1];
      return extension === "gif";
    }
    return false;
  };

  updateAlbumSortOrder = async () => {
    if (this.isSelectedAlbumProfileAlbum) return;
    const ids = this.albums
      .filter(a => {
        if (a.album_type === "custom" || a.album_type === "default") {
          return true;
        } else {
          return false;
        }
      })
      .map((a, i, arr) => {
        return { id: a.id, sort_order: arr.length - i };
      });
    const response = await api.albums.post(ids, `sorting/`);
    if (response && response.data?.status === "submitted") {
      snackbarStore.enqueueSimpleSnackbar(
        "info.albumsSortingUpdated",
        SnackbarVariants.SUCCESS
      );
    } else {
      snackbarStore.enqueueSimpleSnackbar(
        "info.albumsSortingError",
        SnackbarVariants.ERROR
      );
    }
  };

  updateInAlbumMediaSortOrder = async (
    album: ModelAlbum,
    redorderedMedia: (ModelImage | ModelVideo)[]
  ) => {
    if (this.isSelectedAlbumProfileAlbum) return;
    return new Promise((resolve, reject) => {
      const videos: { id: string; sort_order: number }[] = [];
      const images: { id: string; sort_order: number }[] = [];
      album.images = [];
      album.videos = [];
      const proms: any = [];
      redorderedMedia.forEach((m, i) => {
        const v = { id: m.id, sort_order: redorderedMedia.length - i };
        if (m.type === "image") {
          images.push(v);
          m.sort_order = v.sort_order;
          album.images.push(m as ModelImage);
        } else {
          videos.push(v);
          m.sort_order = v.sort_order;
          album.videos.push(m as ModelVideo);
        }
      });

      if (images.length) {
        proms.push(api.albums.post(images, `${album.id}/images/sorting/`));
      }
      if (videos.length) {
        proms.push(api.albums.post(videos, `${album.id}/videos/sorting/`));
      }
      Promise.all(proms).then(res => {
        if (res && res.length) {
          snackbarStore.enqueueSimpleSnackbar(
            "info.albumsSortingUpdated",
            SnackbarVariants.SUCCESS
          );
          resolve(album);
        } else {
          snackbarStore.enqueueSimpleSnackbar(
            "info.albumsSortingError",
            SnackbarVariants.ERROR
          );
          reject();
        }
      });
    });
  };

  public reorderAlbums = (dragIndex: number, hoverIndex: number) => {
    this.setAlbums(arrayMove(this.albums, dragIndex, hoverIndex));
    this.updateAlbumSortOrder();
  };

  public reorderAlbumMedia = (
    album: ModelAlbum,
    dragIndex: number,
    hoverIndex: number
  ) => {
    const reorderedMedia = arrayMove(
      [...(album.images ?? []), ...(album.videos ?? [])],
      dragIndex,
      hoverIndex
    );
    this.updateInAlbumMediaSortOrder(album, reorderedMedia);
    album.images = reorderedMedia.filter(
      media => media.type === "image"
    ) as ModelImage[];
    album.videos = reorderedMedia.filter(
      media => media.type === "video"
    ) as ModelVideo[];
  };

  initMediaDefaultPricing = async () => {
    const { data } = await api.mediaDefaultPricing.get();

    this.defaultAlbumPrices = {
      photo_album_default: data.photo_album_default || 0,
      video_default: data.video_default || 0,
      custom_video_default: data.custom_video_default || 0,
    };
  };

  initAllMedia = async () => {
    this.setSelectedAlbumId(null);
    await this.initListViewData();
    await this.initMediaDefaultPricing();
  };

  get hasUnsavedFiles() {
    return this.selectedAlbumImagesAndVideos.some(media => media.is_file);
  }

  get isSelectedAlbumProfileAlbum() {
    return this.selectedAlbum?.album_type === "profile";
  }

  setIsPromptOpen = (
    component: JSX.Element | null,
    mode?: MyPagePromptMode | null,
    resolveCb?: Function,
    rejectCb?: Function
  ) => {
    if (resolveCb) {
      this.promptResolveCb = resolveCb;
    }
    if (rejectCb) {
      this.promptRejectCb = rejectCb;
    }
    if (mode === undefined || component === null) {
      modalStore.closeSecondaryModal();
    } else {
      this.promptMode = mode;
      modalStore.toggleModal(component, true, "secondary", {
        showNativeCloseButton: false,
      });
    }
  };

  get defaultAlbum() {
    return this.albums?.find(album => album.album_type === "default");
  }

  get customAlbums() {
    return this.albums?.filter(album => album.album_type == "custom");
  }

  get videoAlbum() {
    return this.albums?.find(album => album.album_type == "videos");
  }

  get profileAlbum() {
    return this.albums?.find(album => album.album_type == "profile");
  }

  get recordedShowsAlbum() {
    return this.albums?.find(album => album.album_type == "recorded");
  }

  public setSelectedAlbumId = (id: string | null) => {
    this.log("setSelectedAlbumId started", id);
    this.selectedAlbumId = id;
    this.log("setSelectedAlbumId finished");
  };

  public getAlbumById = (id: string) => {
    return this.albums?.find(album => album.id === id);
  };

  public uploadModelMediaFile = async (
    media: Blob,
    fileName: string,
    album_id: string
  ) => {
    try {
      this.isSaving = true;
      const file = media;
      const fileType = isVideo(fileName, media) ? "video" : "image";

      const draftMedia = this.addFileToAlbum(file, album_id);
      let imageOrVideo;
      const _album = this.selectedAlbum || this.getAlbumById(album_id);
      if (fileType === "video") {
        imageOrVideo = _album?.videos?.find(
          video => video.id === draftMedia.id
        );
      } else {
        imageOrVideo = _album?.images?.find(
          image => image.id === draftMedia.id
        );
      }
      this.lastImageOrVideo = imageOrVideo;
      imageOrVideo.media_status = "uploading";
      imageOrVideo.uploadProgress = 1;
      let response: any;
      if (fileType === "image") {
        response = await api.albums.post(
          { file_name: fileName },
          `${album_id}/images/sign-image-upload/`
        );
      } else if (fileType === "video") {
        response = await api.albums.post(
          { file_name: fileName },
          `${album_id}/videos/sign-video-upload/`
        );
      }
      const {
        presigned_post: { post_url, form_data },
        key,
      } = response.data;
      const uploadData = createFormData(file, form_data);
      const uploadConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          imageOrVideo.uploadProgress = percentCompleted;
        },
      };
      await axios.post(post_url, uploadData, uploadConfig);
      const request: any = {
        type: fileType,
      };
      if (fileType === "image") {
        request.image_key = key;
        const { data: image } = await api.albums.post(
          request,
          `${album_id}/images/`
        );
        const uploadedImage = {
          ...image,
          image: imageOrVideo.image,
          type: "image",
        } as ModelImage;
        this.manageImageOrVideo(imageOrVideo, "remove");
        this.manageImageOrVideo(uploadedImage, "add");
      } else if (fileType === "video") {
        request.video_key = key;
        request.price_in_token = this.defaultAlbumPrices.custom_video_default;
        const { data: video } = await api.albums.post(
          request,
          `${album_id}/videos/`
        );
        const uploadedVideo = {
          ...video,
          type: "video",
        } as ModelVideo;
        this.manageImageOrVideo(imageOrVideo, "remove");
        this.manageImageOrVideo(uploadedVideo, "add");
      }

      this.lastImageOrVideo = undefined;
    } catch (error) {
      if (this.lastImageOrVideo) {
        this.manageImageOrVideo(this.lastImageOrVideo, "remove");
      }
      snackbarStore.enqueueSimpleSnackbar(
        "error.failedFileUpload",
        SnackbarVariants.ERROR
      );
      this.log("Error uploading media", error);
    } finally {
      this.isSaving = false;
    }
  };

  public getAlbumMediaById = async (media: ModelMedia) => {
    const response = await api.albums.get(
      `${(media as ModelImage).album}/${
        media.type === "image" ? "images" : "videos"
      }/${media.id}/`
    );
    if (response?.data) {
      return this.getMediaWithType(response?.data);
    }
    return undefined;
  };

  private updateModelMedia = async (media: ModelMedia, updates: Object) => {
    try {
      if (media.type === "album") {
        await api.albums.patch(updates, `${media.id}/`);
        if (!this.isSelectedAlbumProfileAlbum) {
          snackbarStore.enqueueSimpleSnackbar(
            "info.albumUpdated",
            SnackbarVariants.SUCCESS
          );
        }
      } else if (media.type === "image") {
        await api.albums.patch(
          updates,
          `${(media as ModelImage).album}/images/${media.id}/`
        );
        snackbarStore.enqueueSimpleSnackbar(
          "info.imageUpdated",
          SnackbarVariants.SUCCESS
        );
      } else if (media.type === "video") {
        await api.albums.patch(
          updates,
          `${(media as ModelVideo).album}/videos/${media.id}/`
        );
        snackbarStore.enqueueSimpleSnackbar(
          "info.videoUpdated",
          SnackbarVariants.SUCCESS
        );
      }
    } catch (error) {
      this.log("error updating media", error);
    }
  };

  public updateModelMediaDebounced = debounce(this.updateModelMedia, 2000);

  public createAlbum = async (album: ModelAlbum) => {
    try {
      const { data } = await api.albums.post(album);
      const _album = this.getMediaWithType(data);
      this.manageAlbum(_album, "add");
      return _album;
    } catch (error) {
      return null;
    }
  };

  public addFileToAlbum = (file: any, albumId: string) => {
    const existingAlbum: ModelAlbum | undefined = this.getAlbumById(albumId);
    if (existingAlbum) {
      const imageOrVideo: any = {
        id: shortid.generate(),
        file: file,
        temporary_file_name: file.name,
        is_file: true,
        album: albumId,
      };

      if (isVideo(file.name, file)) {
        imageOrVideo.type = "video";
        imageOrVideo.preview_video = URL.createObjectURL(file);
        imageOrVideo.price = this.defaultAlbumPrices.video_default;
        existingAlbum.videos = [imageOrVideo, ...(existingAlbum.videos || [])];
      } else if (isImage(file.name)) {
        imageOrVideo.type = "image";
        imageOrVideo.image = URL.createObjectURL(file);
        existingAlbum.images = [imageOrVideo, ...(existingAlbum.images || [])];
      }
      existingAlbum.num_media =
        (existingAlbum?.videos?.filter(video => video["status"] === "APPROVED")
          ?.length || 0) +
        (existingAlbum?.images?.filter(image => image["status"] === "APPROVED")
          ?.length || 0);
      this.manageAlbum(existingAlbum, "replace");
      return imageOrVideo;
    }
  };

  public get selectedAlbum() {
    const album = this.getAlbumById(this.selectedAlbumId || "");
    return album;
  }

  public getMediaWithType = (media: ModelMedia, type?: ModelMediaType): any => {
    let _type = type;
    if (!type) {
      if (!(media as ModelImage).album) {
        // if there is no album, it is an album
        _type = "album";
      } else if ((media as ModelVideo).video) {
        _type = "video";
      } else {
        _type = "image";
      }
    }
    if (!media.type && _type) {
      media.type = _type;
    }
    return media as any;
  };

  public get listViewData(): ModelAlbum[] {
    const customAlbums = this.customAlbums;
    let results: any[] = customAlbums;
    results = [...results];
    /*if (this.activeAlbumFilter === 'all') {
			results = [
				...results,
			]
			if (videoAlbum) {
				results.push(videoAlbum)
			}

			if (recordedShowsAlbum) {
				results.push(recordedShowsAlbum)
			}
		} else if (this.activeAlbumFilter === "photos") {
			results = this.defaultAlbum?.images || []
		} else if (this.activeAlbumFilter === "videos") {
			results = this.defaultAlbum?.videos || []
		} else if (this.activeAlbumFilter === "albums") {
			results = this.customAlbums || []
		}*/

    if (this.activeStatusFilter === "pending_approval") {
      results = results.filter(
        (media: ModelMedia) =>
          media.media_status === "new" || media.media_status === "pending"
      );
    } else if (this.activeStatusFilter === "approved") {
      results = results.filter(
        (media: ModelMedia) => media.media_status === "active"
      );
    }

    if (this.activeMediaSorting === "name-ascending") {
      results = MediaManagerStore.sortMediaByName(results);
    } else if (this.activeMediaSorting === "name-descending") {
      results = MediaManagerStore.sortMediaByName(results, false);
    } else if (this.activeMediaSorting === "latest") {
      results = MediaManagerStore.sortMediaByCreatedAt(results);
    } else if (this.activeMediaSorting === "oldest") {
      results = MediaManagerStore.sortMediaByCreatedAt(results, false);
    }

    //@todo remove this filtering and do it on BE
    results = results.filter(media => media.num_media > 0);

    return results;
  }

  public get mostViewedMedias() {
    return MediaManagerStore.sortMediaByNumberOfViews(this.listViewData);
  }

  public get mostPurchasedMedias() {
    return MediaManagerStore.sortMediaByNumberOfPurchases(this.listViewData);
  }

  public get selectedAlbumImagesAndVideos() {
    const images = this.selectedAlbum?.images || [];
    const videos = this.selectedAlbum?.videos || [];
    const allMedia = [...images, ...videos];
    const filteredAndSortedMedia =
      videos?.length && !images?.length
        ? allMedia
        : this.applyFiltersAndSortingToAlbumImagesAndVideos(allMedia);

    return filteredAndSortedMedia;
  }

  public getAlbumImagesAndVideos = (album: ModelAlbum) => {
    const images = album.images || [];
    const videos = album.videos || [];

    const allMedia = [...images, ...videos];
    const filteredAndSortedMedia =
      this.applyFiltersAndSortingToAlbumImagesAndVideos(allMedia);

    return filteredAndSortedMedia;
  };

  public getAllMediaFromAllAlbums = () => {
    let medias: (ModelImage | ModelVideo)[] = [];
    this.albums.forEach(album => {
      medias = medias.concat(...this.getAlbumImagesAndVideos(album));
    });

    return medias;
  };

  public applyFiltersAndSortingToAlbumImagesAndVideos = (
    medias: (ModelImage | ModelVideo)[]
  ) => {
    //sorting in profile album will always be by created_at and
    const sortType = this.isSelectedAlbumProfileAlbum
      ? "created_at"
      : "sort_order";
    return medias.sort((a, b) => {
      return this.isSelectedAlbumProfileAlbum
        ? b[sortType] > a[sortType]
          ? 1
          : -1
        : b[sortType] < a[sortType]
        ? 1
        : -1;
    });
  };

  public setActiveAlbumFilter = (filter: ModelMediaTypeFilter) => {
    if (this.activeAlbumFilter === filter) {
      this.activeAlbumFilter = "all";
    } else {
      this.activeAlbumFilter = filter;
    }
  };

  public setActiveStatusFilter = (filter: ModelMediaStatusFilter) => {
    if (this.activeStatusFilter === filter) {
      this.activeStatusFilter = "all";
    } else {
      this.activeStatusFilter = filter;
    }
  };

  public setActiveMediaSorting = (filter: ModelMediaSorting) => {
    if (this.activeMediaSorting === filter) {
      this.activeMediaSorting = "latest";
    } else {
      this.activeMediaSorting = filter;
    }
  };

  public static sortMediaByKey = (
    sortKey: string,
    typeofData: "string" | "date" | "number",
    highToLow = true
  ) => {
    // isAscending = true means A-Z for strings
    // isAscending = true means newest to oldest for dates
    // isAscending = true means highest to lowest for numbers

    // reverse the order for date and numbers so it is like alphabethical sort
    const _highToLow =
      typeofData === "date" || typeofData === "number" ? !highToLow : highToLow;
    return (a, b) => {
      let aVal: any;
      let bVal: any;

      if (typeofData === "date") {
        aVal = new Date(a[sortKey] || 0);
        bVal = new Date(b[sortKey] || 0);
      } else if (typeofData === "string") {
        aVal = (a[sortKey] || "").toUpperCase();
        bVal = (b[sortKey] || "").toUpperCase();
      } else if (typeofData === "number") {
        aVal = a[sortKey];
        bVal = b[sortKey];
      } else {
        return 0;
      }

      const isASoonerOrFirst = aVal > bVal;
      if (isASoonerOrFirst) {
        return !_highToLow ? -1 : 1;
      } else if (!isASoonerOrFirst) {
        return !_highToLow ? 1 : -1;
      } else {
        return 0;
      }
    };
  };

  public static sortMediaByCreatedAt = (
    array: any[],
    newest: boolean = true
  ) => {
    return array.sort(
      MediaManagerStore.sortMediaByKey("created_at", "date", newest)
    );
  };

  public static sortMediaByName = (array: any[], aToZ: boolean = true) => {
    return array.sort(MediaManagerStore.sortMediaByKey("name", "string", aToZ));
  };

  public static sortMediaByNumberOfViews = (
    array: any[],
    highest: boolean = true
  ) => {
    return array.sort(
      MediaManagerStore.sortMediaByKey("num_views", "number", highest)
    );
  };

  public static sortMediaByNumberOfPurchases = (
    array: any[],
    highest: boolean = true
  ) => {
    return array.sort(
      MediaManagerStore.sortMediaByKey("num_purchases", "number", highest)
    );
  };

  public getPageOptions(
    from: number,
    to: number,
    chunk: number
  ): {
    pageCalculated: number;
    countCalculated: number;
    offsetCalculated: number;
  } {
    return {
      pageCalculated: to / chunk,
      countCalculated: chunk,
      offsetCalculated: from % chunk,
    };
  }

  runValidations = (resolveCb: Function, rejectCb?: Function) => {
    const isValid = true;

    if (isValid) {
      resolveCb();
    } else if (rejectCb) {
      rejectCb();
    }
  };

  setAlbumName = (name: string, album: ModelAlbum) => {
    album.name = name;
    album.pending_title = name;
    album.pending_title_status = "PENDING";

    this.updateModelMediaDebounced(album, {
      name,
    });
  };

  setAlbumPrice = (price_in_token: number, album: ModelAlbum) => {
    album.price_in_token = price_in_token;
    album.price_in_usd = tokenHelper.tokenToCurrency(price_in_token);

    this.updateModelMediaDebounced(album, {
      price_in_token,
    });
  };

  setAlbumDefaultItemsDefaultPrice = (
    default_price_item_in_tokens: number,
    album: ModelAlbum
  ) => {
    album.default_price_item_in_tokens = default_price_item_in_tokens;
    this.updateModelMediaDebounced(album, {
      default_price_item_in_tokens,
    });
  };

  setMediaDefaultItemsDefaultPrice = async (
    default_price_item_in_tokens: number,
    album_type: ModelAlbumType,
    album?: ModelAlbum
  ) => {
    let pricing_data = {};
    if (album_type === "videos") {
      pricing_data = {
        custom_video_default: default_price_item_in_tokens,
      };
    } else if (album_type === "recorded") {
      pricing_data = {
        video_default: default_price_item_in_tokens,
      };
    } else {
      pricing_data = {
        photo_album_default: default_price_item_in_tokens,
      };
    }

    if (album) {
      this.setAlbumDefaultItemsDefaultPrice(
        default_price_item_in_tokens,
        album
      );
    }
    try {
      await api.mediaDefaultPricing.patch(pricing_data);
      this.defaultAlbumPrices = {
        ...this.defaultAlbumPrices,
        ...pricing_data,
      };
      snackbarStore.enqueueSimpleSnackbar(
        "info.albumUpdated",
        SnackbarVariants.SUCCESS
      );
    } catch (error) {
      this.log("error updating album price", error);
    }
  };

  setAlbumCoverImage = async (album: ModelAlbum, image: ModelImage) => {
    await this.updateModelMedia(album, {
      cover_image_id: image.id,
    });
    album.cover_image = image;
  };

  setMediaName = (name: string, imageOrVideo: ModelImage | ModelVideo) => {
    imageOrVideo.name = name;
    imageOrVideo.pending_title = name;
    imageOrVideo.pending_title_status = "PENDING";

    this.updateModelMediaDebounced(imageOrVideo, {
      name,
    });
  };

  getMediaDuration = (length?: number) => {
    if (!length) {
      return undefined; // do not return a default like 0:00 as we don't want to show duration pill in that case
    }
    const minutes = Math.floor(length / 60);
    const secs = length % 60;
    return `${minutes}:${secs < 10 ? "0" + secs : secs}`;
  };

  getMediaName = (media: ModelImage | ModelVideo) => {
    if (
      this.selectedAlbum &&
      this.selectedAlbum.album_type === "recorded" &&
      !media?.pending_title &&
      !media?.name
    ) {
      return moment(media?.created_at).format("MM/DD/yyyy").toString();
    }
    return media?.pending_title && media?.pending_title_status === "PENDING"
      ? moment(media?.created_at).format("MM/DD/yyyy").toString()
      : media?.name || "";
  };

  getMediaDateWithTime = (media: ModelImage | ModelVideo) => {
    return moment(media?.created_at).format("MM/DD/yyyy h:mma").toString();
  };

  getRecordedShowMediaName = (media: ModelImage | ModelVideo) => {
    if (media?.name) {
      return media?.name;
    } else {
      return (
        profileStore?.modelProfile?.username +
        " Recorded Show " +
        moment(media?.created_at).format("MM/DD/yyyy h:mm:ssa").toString()
      );
    }
  };

  isDefaultMediaName = (media: ModelImage | ModelVideo) => {
    const media_name =
      media?.pending_title && media?.pending_title_status === "PENDING"
        ? media?.pending_title
        : media?.name || "";
    const defaultRegex = /Recorded Show \d\d\/\d\d\/\d\d \d\d:\d\d:\d\d/;
    const isDefaultTitle = media_name.match(defaultRegex);

    return isDefaultTitle?.length;
  };

  setMediaDescription = (description: string, video: ModelVideo) => {
    video.description = description;
    video.pending_description = description;
    video.pending_description_status = "PENDING";

    this.updateModelMediaDebounced(video, {
      description,
    });
  };

  setMediaPrice = (
    price_in_token: number,
    imageOrVideo: ModelImage | ModelVideo
  ) => {
    imageOrVideo.price_in_token = price_in_token;
    imageOrVideo.price_in_usd = tokenHelper.tokenToCurrency(price_in_token);

    this.updateModelMedia(imageOrVideo, {
      price_in_token,
    });
  };

  setMediaVis = async (media: ModelMedia, vis: ModelMediaVisibility) => {
    await this.updateModelMedia(media, {
      media_visibility: vis,
    });
    media.media_visibility = vis;
  };

  private getImagesOfAlbum = async (
    album_id: string,
    {
      //page, count, offset,
      from,
      to,
      chunk,
    }: {
      page?: number;
      count?: number;
      offset?: number;
      from?: number;
      to?: number;
      chunk?: number;
    } = {}
  ) => {
    this.log("getImagesOfAlbum started");
    const album = this.getAlbumById(album_id);
    if (album) {
      this.log("getImagesOfAlbum found album");
      try {
        if (from !== undefined && to && chunk) {
          this.getPageOptions(from, to, chunk);
        }
        //const queryParams = (page || count || album_id) ? api.formatQueryParams({album: album_id, page, count, offset}) : ''
        const res = await api.albums.get(`${album_id}/images/`);
        const images = res?.data?.results;
        return images;
      } catch (error) {
        this.log("getImagesOfAlbum failed", error);
        validationStore.storeBackendErrors(error);
      } finally {
        this.log("getImagesOfAlbum finished");
      }
    }
  };

  public getVideosOfAlbum = async (
    album_id: string,
    {
      //page, count, offset,
      from,
      to,
      chunk,
    }: {
      page?: number;
      count?: number;
      offset?: number;
      from?: number;
      to?: number;
      chunk?: number;
    } = {}
  ) => {
    this.log("getVideosOfAlbum started");
    const album = this.getAlbumById(album_id);

    if (
      album &&
      (album.album_type === "videos" || album?.album_type === "recorded")
    ) {
      this.log("getVideosOfAlbum found album");
      try {
        if (from !== undefined && to && chunk) {
          this.getPageOptions(from, to, chunk);
        }
        //const queryParams = (page || count || album_id) ? api.formatQueryParams({album: album_id, page, count, offset}) : ''
        const videosLoaded = album?.videos?.length || 0;
        const res = await api.albums.get(
          this.albumNextHash[album_id] ||
            `${album_id}/videos/?order=${
              album?.album_type === "recorded" ? "-created_at" : "-sort_order"
            }&offset=0&limit=${
              videosLoaded ? videosLoaded : MEDIA_PER_PAGE_LIMIT
            }`
        );

        const videos = res?.data?.results;
        const next = res?.data?.next;
        this.albumNextHash[album_id] = null;

        if (next) {
          const nextUrl = next.split("?");
          this.albumNextHash[album_id] = `${album_id}/videos/?${
            nextUrl[nextUrl?.length - 1]
          }`;
        }

        return videos;
      } catch (error) {
        this.log("getVideosOfAlbum failed", error);
        validationStore.storeBackendErrors(error);
      } finally {
        this.log("getVideosOfAlbum finished");
      }
    }
  };

  public getVideosOfAlbumNextPage = async (album_id: string) => {
    this.log("getVideosOfAlbumNextPage started");
    const album = this.albums.find(_album => _album.id === album_id);

    if (album) {
      try {
        album.is_loading_media = true;
        const newVideos = await this.getVideosOfAlbum(album_id);
        const newVideosMapped =
          newVideos?.map(video => this.getMediaWithType(video, "video")) || [];
        album.videos = [...album.videos, ...newVideosMapped];
      } catch (error) {
        this.log("getVideosOfAlbumNextPage failed", error);
      } finally {
        album.is_loading_media = false;
        this.log("getVideosOfAlbumNextPage finished");
      }
    }
  };

  public getAlbum = async (id: string) => {
    try {
      const res = await api.albums.get(
        `${id}/?ordering=-sort_order,-created_at`
      );
      return {
        ...this.getMediaWithType(res.data),
        images: res.data.images.map(image =>
          this.getMediaWithType(image, "image")
        ),
        videos: res.data.videos.map(video =>
          this.getMediaWithType(video, "video")
        ),
      };
    } catch (error) {}
  };

  public getAlbumTitle = (album: ModelAlbum): string => {
    switch (album.album_type) {
      case "videos": {
        return "Uploaded Videos";
      }
      case "profile": {
        return "Profile Photos";
      }
      case "recorded": {
        return "Recorded Shows";
      }
      case "custom": {
        return this.getCustomAlbumTitle(album);
      }
      case "default": {
        return "Profile Page Photos";
      }
      default: {
        return "Untitled";
      }
    }
  };

  public getAlbumPageLeftColumnTitle = (album: ModelAlbum) => {
    switch (album.album_type) {
      case "videos": {
        return "Uploaded Videos";
      }
      case "profile": {
        return "Upload Profile Photos";
      }
      case "recorded": {
        return "Recorded Shows";
      }
      case "custom": {
        return this.getCustomAlbumTitle(album);
      }
      case "default": {
        return "Upload Profile Page Photos";
      }
      default: {
        return "Untitled";
      }
    }
  };

  public getAlbumPageTitle = (album: ModelAlbum) => {
    switch (album.album_type) {
      case "videos": {
        return "My Uploaded Videos";
      }
      case "profile": {
        return "My Profile Photos";
      }
      case "recorded": {
        return "My Recorded Shows";
      }
      case "custom": {
        return this.getCustomAlbumTitle(album);
      }
      case "default": {
        return "My Profile Page Photos";
      }
      default: {
        return "Untitled";
      }
    }
  };

  private getCustomAlbumTitle = (album: ModelAlbum): string => {
    if (album.pending_title_status === "PENDING") {
      return album.pending_title || album.name || "";
    } else {
      return album.name || "";
    }
  };

  public getEmptyAlbumMessage = () => {
    if (this.routeMode === ModelMediaRouteMode.PHOTOS) {
      if (
        this.managerMode === ModelMediaManagerMode.CREATE ||
        this.managerMode === ModelMediaManagerMode.EDIT
      ) {
        return `You currently do not have any photo(s) in this album`;
      } else {
        return "You currently do not have any photo albums";
      }
    } else if (this.routeMode === ModelMediaRouteMode.RECORDED_VIDEOS) {
      return "You currently do not have any recorded shows";
    } else if (this.routeMode === ModelMediaRouteMode.UPLOADED_VIDEOS) {
      return "You currently do not have any custom videos";
    }
  };

  public initListViewData = async () => {
    this.isLoadingAlbums = true;
    try {
      const res = await api.albums.get(
        undefined,
        `?ordering=-sort_order,-created_at`
      );
      const albums = res?.data?.results;
      if (!this.albums || !this.albums.length) {
        this.setAlbums(albums);
      }
      const proms = albums.map((album: ModelAlbum) => {
        this.albumIds.push(album.id);
        return new Promise(resolve => {
          this.getAlbumMedias(album).then((_album: ModelAlbum) => {
            resolve(this.getMediaWithType(_album));
          });
        });
      });
      Promise.all(proms).then(_albums => {
        this.setAlbums(_albums as ModelAlbum[]);
        return this.albums;
      });
    } catch (e) {
      this.log("album retrieval issue", config.showAjaxErrors ? e : "");
      return {};
    } finally {
      this.isLoadingAlbums = false;
    }
  };

  /*private addCustomFrontendPropertiesToAlbums = (albums: ModelAlbum[]) => {
    return albums.map(album => ({
      ...album,
      hasNoMedia:
        album.album_type === "custom" //only filter out custom empty albums, recorded, default and videos albums can be empty
          ? this.getAlbumImagesAndVideos(album).length === 0
          : false,
    }));
  };*/

  public getAlbumMedias = async (album: ModelAlbum) => {
    try {
      this.log("getAlbumMedias started");
      album.is_loading_media = true;
      this.albumNextHash = InitialData.albumNextHash;
      const [images, videos] = await Promise.all([
        this.getImagesOfAlbum(album.id),
        this.getVideosOfAlbum(album.id),
      ]);
      album.images =
        images?.map(image => this.getMediaWithType(image, "image")) || [];
      album.videos = album.videos?.length
        ? album.videos
        : videos?.map(video => this.getMediaWithType(video, "video")) || [];
      return this.getMediaWithType(album);
    } catch (error) {
      this.log("getAlbumMedias failed", error);
    } finally {
      album.is_loading_media = false;
      this.log("getAlbumMedias finished");
    }
  };

  isProfilePictureAndCanBeDeleted = (imgID: string) => {
    let imgType = "non_nude_profile_image";
    if (profileStore?.modelProfile?.non_nude_profile_image?.id === imgID)
      imgType = "non_nude_profile_image";
    if (profileStore?.modelProfile?.profile_image?.id === imgID)
      imgType = "profile_image";
    if (
      profileStore?.modelProfile?.non_nude_profile_image?.id === imgID ||
      profileStore?.modelProfile?.profile_image?.id === imgID
    ) {
      snackbarStore.enqueueSnackbar({
        message: { id: `messages.error.${imgType}` },
        variant: SnackbarVariants.ERROR,
      });
      return false;
    }
    return true;
  };

  canDeleteImage = (imgID: string) => {
    if (imgID === this.selectedAlbum?.cover_image?.id) {
      snackbarStore.enqueueSnackbar({
        message: { id: `messages.error.coverImg` },
        variant: SnackbarVariants.ERROR,
      });
      return false;
    }
    return true;
  };

  public deleteMedia = async (
    media: ModelMedia,
    skipToast: boolean = false
  ) => {
    try {
      if (media.type === "image") {
        if ((media as ModelImage).album && !(media as any).is_file) {
          if (!this.isProfilePictureAndCanBeDeleted(media.id)) return;
          if (!this.canDeleteImage(media.id)) return;
          await api.albums.delete(
            {},
            `${(media as ModelImage).album}/images/${media.id}/`
          );
        }
        this.manageImageOrVideo(media as ModelImage, "remove");
        if (!skipToast) {
          snackbarStore.enqueueSimpleSnackbar(
            "info.imageDeleted",
            SnackbarVariants.SUCCESS
          );
        }
      } else if (media.type === "video") {
        if ((media as ModelVideo).album && !(media as any).is_file) {
          await api.albums.delete(
            {},
            `${(media as ModelVideo).album}/videos/${media.id}/`
          );
        }
        this.manageImageOrVideo(media as ModelVideo, "remove");
        if (!skipToast) {
          snackbarStore.enqueueSimpleSnackbar(
            "info.videoDeleted",
            SnackbarVariants.SUCCESS
          );
        }
      } else if (media.type === "album") {
        if (!(media as any).is_draft) {
          await api.albums.delete(`${media.id}/`);
        }
        if (this.selectedAlbumId === media.id) {
          this.setSelectedAlbumId(null);
        }
        this.manageAlbum(media as ModelAlbum, "remove");
        if (!skipToast) {
          snackbarStore.enqueueSimpleSnackbar(
            "info.albumDeleted",
            SnackbarVariants.SUCCESS
          );
        }
      }
    } catch (error) {
      if (!skipToast) {
        snackbarStore.enqueueSimpleSnackbar(
          "error.imageCouldNotDeleted",
          SnackbarVariants.ERROR
        );
      }
    }
  };

  setAlbums = (albums: ModelAlbum[]) => {
    this.log("setAlbums started");
    this.albums = albums;
    this.log("setAlbums finished");
  };

  public manageAlbum = (
    album: ModelAlbum,
    action: "remove" | "add" | "replace" = "replace"
  ) => {
    const albumWithType = this.getMediaWithType(album);
    if (action === "add") {
      this.setAlbums([album, ...this.albums]);
    } else if (action === "remove") {
      this.setAlbums(this.albums.filter(_album => _album.id !== album.id));
      if (this.selectedAlbum?.id === album.id) {
        this.setSelectedAlbumId(null);
      }
    } else {
      this.setAlbums(
        this.albums.map(_album => {
          if (_album.id === albumWithType.id) {
            return albumWithType;
          }
          return _album;
        })
      );
    }
  };

  public createDraftAlbum = (name?: string) => {
    const draft: ModelAlbum = {
      id: shortid.generate(),
      created_at: "",
      modified_at: "",
      name: name,
      default_price_item_in_tokens:
        this.defaultAlbumPrices.photo_album_default || 0,
      price_in_token: this.defaultAlbumPrices.photo_album_default || 0,
      price_in_usd: 0,
      media_status: "new",
      type: "album",
      media_visibility: ModelMediaVisibility.MEMBERS,
      num_views: 0,
      num_purchases: 0,
      pending_title: "",
      pending_title_status: null,
      num_media: 0,
      album_type: "custom",
      cover_image: null,
      videos: [],
      images: [],
      uploadProgress: 100,
      is_draft: true,
      is_loading_media: true,
    };

    return draft;
  };

  public manageImageOrVideo = (
    media: ModelImage | ModelVideo,
    action: "remove" | "add" | "replace" = "replace"
  ) => {
    if (media) {
      const album: any = this.getAlbumById(media.album);
      const mediaWithType = this.getMediaWithType(media);
      if (album) {
        if (mediaWithType.type === "image") {
          if (action === "add") {
            album.images = [mediaWithType, ...(album.images || [])];
          } else if (action === "remove") {
            album.images = album.images?.filter(image => image.id !== media.id);
          } else {
            album.images = album.images?.map(image => {
              if (image.id === mediaWithType.id) {
                return mediaWithType;
              }
              return image;
            });
          }
        } else {
          if (action === "add") {
            album.videos = [mediaWithType, ...(album.videos || [])];
          } else if (action === "remove") {
            album.videos = album.videos?.filter(video => video.id !== media.id);
          } else {
            album.videos = album.videos?.map(video => {
              if (video.id === mediaWithType.id) {
                return mediaWithType;
              }
              return video;
            });
          }
        }
        this.manageAlbum(album);
      }
    }
  };
}
