import React, { useCallback, useRef, useState } from "react";
import { NullableString } from "../../../core/types";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";
import RecaptchaV2 from "./recaptcha-v2";
import { TMargin, TPadding } from "../../../styles/tailwind-classnames";
import { inject, observer } from "mobx-react";
import RecaptchaStore from "./store";
import { logger } from "../../../core/utility";

type Props = {
  className?: string;
  beforeExecuteRecaptcha?: () => void;
  callback: (recaptchaKey: NullableString) => Promise<void>;
  captchaSiteKey: string | undefined;
  margin?: (TMargin | undefined)[];
  padding?: (TPadding | undefined)[];
  recaptchaStore?: RecaptchaStore;
  hideBadge?: boolean;
};

/**
 * Wrap this component in GoogleReCaptchaProvider with reCaptchaKey v3 to provide recaptcha context
 */
const RecaptchaForm: React.ComponentType<Props> = ({
  className,
  beforeExecuteRecaptcha,
  callback,
  captchaSiteKey,
  children,
  margin = [],
  padding = [],
  hideBadge = false,
  recaptchaStore,
}) => {
  const recaptchaV2Ref = useRef<ReCAPTCHA>(null);
  const [shouldResetRecaptcha, setShouldResetRecaptchaV2] =
    useState<boolean>(false);

  const { executeRecaptcha: executeV3Fn } = useGoogleReCaptcha();
  const { getRecaptchaToken, useReCaptchaV3Default } = recaptchaStore!;

  const onErrored = useCallback(() => {
    setShouldResetRecaptchaV2(true);
  }, []);

  const onExpired = useCallback(() => {
    console.error("Recaptcha expired");
  }, []);

  const getV2Token = async () => {
    const recaptchaEl = recaptchaV2Ref.current;
    if (recaptchaEl) {
      if (shouldResetRecaptcha) {
        recaptchaEl.reset();
      }
      return recaptchaEl.executeAsync();
    }
  };

  const getV3Token = async () => {
    return executeV3Fn!("submit");
  };

  const executor = async () => getRecaptchaToken(getV2Token, getV3Token);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (beforeExecuteRecaptcha) {
        beforeExecuteRecaptcha();
      }
      const token = await executor();
      await callback(token);
    } catch (error) {
      if (captchaSiteKey) {
        // retry with v2
        setShouldResetRecaptchaV2(true);
        // if v3 is not used, we don't want to retry v2 again
        if (useReCaptchaV3Default) {
          try {
            const token = await executor();
            await callback(token);
          } catch (error) {
            logger.log("RecaptchaForm: ignoring final catch");
            //ignore this catch
          }
        }
      } else {
        console.error("Recaptcha site key missing");
      }
    }
  };

  return (
    <form className={className || ""} onSubmit={onSubmit}>
      {children}
      {captchaSiteKey && (
        <RecaptchaV2
          captchaSiteKey={captchaSiteKey}
          ref={recaptchaV2Ref}
          onExpired={onExpired}
          onErrored={onErrored}
          margin={[...margin]}
          padding={[...padding]}
          hideBadge={hideBadge}
        />
      )}
    </form>
  );
};

export default inject("recaptchaStore")(observer(RecaptchaForm));
