import { IStepCompletionStatus } from "./types";

export enum ModelCreateStep {
  SUBMIT_MODEL_INFO,
  SUBMIT_MODEL_ID,
  MODEL_AGREEMENTS,
  SUMMARY,
}

export enum FILTER_MODELS_LOGIN_TIME {
  ALL = "all",
  ONLINE_NOW = "h",
  LAST_24_HOURS = "d",
  LAST_7_DAYS = "w",
  LAST_30_DAYS = "M",
  LAST_YEAR = "y",
}

export type StudioModelAccessRights = {
  id: string;
  model: string;
  is_pricing_settings_change_allowed: boolean;
  is_earnings_and_transactions_visible: boolean;
  is_transaction_detail_visible: boolean;
  is_quick_text_changes_allowed: boolean;
  is_allow_to_invite_allowed: boolean; //start a session with a guest model
  is_allow_to_be_invited_allowed: boolean; //join a session as a guest model
};
export interface IStudioModel {
  modelId: string;
  modified_at: string; //used this in place of last_login_datetime as last_login_datetime does not seem to work
  model_full_name?: string;
  first_name: string;
  last_name: string;
  other_name: string;
  username: string;
  gender: string;
  birthdate: string;
  phone_number: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  iso_country: string;
  email: string;
  password: string;
  password_confirm: string;
  document: {
    document_type: string;
    issue_country: string;
    expire_date: string;
    document_front_key: string;
    document_back_key: string;
    document_verification_key: string;
    document_front_key_view_url: string;
    document_back_key_view_url: string;
    document_verification_key_view_url: string;
  };
  missing_signup_fields: string[];
  status: string;
  access_rights?: StudioModelAccessRights;
  profile_image?: string;
  registered_at?: string;
  is_online: boolean;
  last_login_datetime: string; //use modified_at instead
}
export interface IStepsCompletionStatuses {
  [ModelCreateStep.SUBMIT_MODEL_INFO]: IStepCompletionStatus;
  [ModelCreateStep.SUBMIT_MODEL_ID]: IStepCompletionStatus;
  [ModelCreateStep.MODEL_AGREEMENTS]: IStepCompletionStatus;
  [ModelCreateStep.SUMMARY]: IStepCompletionStatus;
}
