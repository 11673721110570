import React from "react";
import "./styles.scss";
import classnames from "classnames";

type NotificationSuperBuzzProps = {
  className?: string;
  style?: React.CSSProperties;
};

const NotificationSuperBuzz: React.ComponentType<NotificationSuperBuzzProps> = ({ className, style }) => (
  <span className={classnames(className)} style={style}>
      <svg
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          xmlSpace='preserve'
          x='0px'
          y='0px'
          viewBox='0 0 64 64'>
          <polygon
            id='lightning-icon'
            points='42.4,29.1 32,29.1 32,12.9 21.6,34.1 32,34.1 32,50.3 '></polygon>
          <g>
            <path
              className='waves'
              id='left-inner'
              d='M16.5,31.6c0-2.7,0.7-5.2,1.9-7.4c0.2-0.4,0.2-1-0.2-1.3l-3.1-3.1c-0.5-0.5-1.3-0.4-1.7,0.2 c-2.1,3.4-3.4,7.4-3.4,11.7c0,4.2,1.2,8.2,3.3,11.5c0.4,0.6,1.2,0.7,1.7,0.2l3.2-3.2c0.3-0.3,0.4-0.9,0.2-1.3 C17.2,36.8,16.5,34.3,16.5,31.6z'></path>
            <path
              className='waves'
              id='left-outer'
              d='M6.5,31.6c0-5.4,1.7-10.5,4.6-14.7c0.3-0.4,0.3-1-0.1-1.4l-3.1-3.1c-0.5-0.5-1.2-0.4-1.6,0.1 C2.3,17.9,0,24.5,0,31.6c0,7.1,2.3,13.6,6.2,18.9c0.4,0.5,1.2,0.6,1.6,0.1l3.1-3.1c0.4-0.4,0.4-0.9,0.1-1.4C8.2,42,6.5,37,6.5,31.6 z'></path>
            <path
              className='waves'
              id='right-inner'
              d='M47.5,31.6c0,2.6-0.7,5.1-1.8,7.3c-0.2,0.4-0.2,1,0.2,1.3l3.2,3.2c0.5,0.5,1.3,0.4,1.7-0.2 c2.1-3.4,3.3-7.3,3.3-11.5c0-4.3-1.3-8.3-3.4-11.7c-0.4-0.6-1.2-0.7-1.7-0.2l-3.1,3.1c-0.3,0.3-0.4,0.9-0.2,1.3 C46.8,26.4,47.5,29,47.5,31.6z'></path>
            <path
              className='waves'
              id='right-outer'
              d='M57.5,31.6c0,5.4-1.7,10.4-4.5,14.5c-0.3,0.4-0.3,1,0.1,1.4l3.1,3.1c0.5,0.5,1.2,0.4,1.6-0.1 c3.9-5.3,6.2-11.8,6.2-18.9c0-7.1-2.3-13.7-6.3-19c-0.4-0.5-1.2-0.6-1.6-0.1L53,15.6c-0.4,0.4-0.4,1-0.1,1.4 C55.8,21.1,57.5,26.2,57.5,31.6z'></path>
            </g>
        </svg>
  </span>
);

export default NotificationSuperBuzz;
