import React from "react";
import classnames from "classnames";

type CloseIconProps = {
  className?: string;
};

const CloseThickerIcon: React.FunctionComponent<CloseIconProps> = ({
  className = "",
}) => {
  return (
    <span className={classnames("CloseThickerIcon", className)}>
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        viewBox='0 0 63.8 64.2'
        xmlSpace='preserve'>
        <path
          d='M40,32.1L63.4,8.7c0.6-0.6,0.3-1.9-0.6-2.9l-4.6-4.6c-1-1-2.3-1.3-2.9-0.7L31.9,23.9L8.7,0.3C8-0.3,6.8,0,5.8,1L1.2,5.6
	c-1,1-1.3,2.3-0.6,2.9l23.2,23.5L0.3,55.4C-0.3,56.1,0,57.4,1,58.3L5.6,63c1,1,2.3,1.3,2.9,0.7l23.4-23.3l23.2,23.6
	c0.6,0.6,1.9,0.3,2.9-0.6l4.6-4.6c1-1,1.3-2.3,0.6-2.9L40,32.1z'
        />
      </svg>
    </span>
  );
};

export default CloseThickerIcon;
