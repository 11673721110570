import React, { ReactNode, useCallback, useMemo } from "react";
import TailwindFlex, {
  TailwindFlexProps,
} from "library/components/_tailwind/flex";
import Image from "library/components/image";
import TailwindTooltip from "library/components/_tailwind/tooltip";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import { MessageDescriptor } from "react-intl";

export type MediaManagerMediaCardBodyProps = {
  backgroundImage?: string | null;
  fallbackBackgroundImage?: string;
  boxProps?: TailwindFlexProps;
  imageProps?: TailwindFlexProps;
  isCover?: boolean;
  brokenPlaceholderChildren?: ReactNode;
  isProfilePic?: boolean;
  isNonNudeProfilePic?: boolean;
  isProfile?: boolean;
};

const MediaManagerMediaCardBody: React.ComponentType<MediaManagerMediaCardBodyProps> =
  ({
    backgroundImage,
    fallbackBackgroundImage,
    children,
    boxProps,
    imageProps,
    isCover,
    brokenPlaceholderChildren,
    isProfilePic,
    isNonNudeProfilePic,
    isProfile,
  }) => {
    const shouldShowBorder = useMemo(() => {
      return (isCover && !isProfile) || isProfilePic || isNonNudeProfilePic;
    }, [isCover, isProfilePic, isNonNudeProfilePic]);

    const tooltipText: MessageDescriptor = useMemo(
      () =>
        isProfilePic
          ? {
              id: "dashboard.mediaCard.nudePhotoTooltip",
              defaultMessage: "Nude Profile Photo, visible to Members",
            }
          : {
              id: "dashboard.mediaCard.profilePhotoTooltip",
              defaultMessage: "Profile Photo, visible to Guests",
            },
      [isProfilePic, shouldShowBorder]
    );

    const getImg = useCallback(() => {
      return (
        <TailwindFlex {...imageProps} width='w-full' pseudoClasses={["md:h-32"]} justifyContent='justify-center'>
          <Image
            src={backgroundImage as string}
            showFallback={false}
            className={"h-auto w-full"}
            brokenPlaceholderChildren={brokenPlaceholderChildren}
            fallbacksrc={fallbackBackgroundImage as string}
          />
        </TailwindFlex>
      );
    }, [backgroundImage, brokenPlaceholderChildren, fallbackBackgroundImage]);

    return (
      <TailwindFlex
        position={"relative"}
        alignItems={"items-center"}
        justifyContent={"justify-center"}
        flex={"flex-1"}
        maxHeight={"max-h-full"}
        minHeight={"min-h-40"}
        borderWidth={shouldShowBorder ? ["border-8"] : ["border-0"]}
        borderColor={isProfilePic ? "border-purple-400" : "border-pink-400"}
        borderStyle={shouldShowBorder ? "border-solid" : "border-none"}
        {...boxProps}>
        {(backgroundImage || fallbackBackgroundImage) &&
        isProfile &&
        shouldShowBorder ? (
          <TailwindTooltip
            content={<TailwindTranslatedText descriptor={tooltipText} />}>
            {getImg()}
          </TailwindTooltip>
        ) : (
          (backgroundImage || fallbackBackgroundImage) && getImg()
        )}
        {children}
      </TailwindFlex>
    );
  };

export default MediaManagerMediaCardBody;
