import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookSubHeading from "../_internal/sub-heading";
import Code from "../../components/_tailwind/code";
import TailwindAnchor from "../../components/_tailwind/anchor";

type Props = {};

const StorybookConventionsJavascriptAndTypescriptAndReact: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>
        Conventions &gt; Javascript, Typescript & React
      </StorybookHeading>
      <StorybookSubHeading>Javascript</StorybookSubHeading>
      <StorybookParagraph>
        - Write all functions as anonymous functions
      </StorybookParagraph>
      <StorybookParagraph>
        - Wrap API calls or things like websocket connections inside Try Catch
        blocks
      </StorybookParagraph>
      <StorybookParagraph>
        - Avoid writing long functions that are not testable, write long code
        first then refactor into small reusable chunks, preferably in Functional
        Programming way where you can compose different functions together
      </StorybookParagraph>
      <StorybookParagraph>
        - Try not to destruct variables in function arguments, have arguments
        and optional arguments in an ordered way. If you happen to have a lot of
        properties, type out the argument for type safety.
      </StorybookParagraph>
      <StorybookParagraph>
        - Try to destruct variables from arrays and objects and destruct
        functions from stores:
      </StorybookParagraph>
      <Code>
        {` const someObj = {name: "Test", priceInTokens: 10}
const {name, priceInTokens} = someObj;

const {openPrimaryModal} = modalStore!;
`}
      </Code>
      <StorybookSubHeading>Typescript</StorybookSubHeading>
      <StorybookParagraph>
        <strong>- Do not use any</strong>
      </StorybookParagraph>
      <StorybookParagraph>
        - Type out everything - Do not use interfaces, use types
      </StorybookParagraph>
      <StorybookParagraph>
        - Utilize Typescript Helper types like Partial, Record, Omit, Pick etc.
      </StorybookParagraph>
      <StorybookParagraph>
        - Structure your types in a way they are usable
      </StorybookParagraph>
      <Code>
        {`type ModelMediaStatus = "approved" | "pending"
        
type ModelMediaBase {
  name: string
  price: number
  status: ModelMediaStatus
}
        
type ModelAlbum = ModelMediaBase & {
  num_media: number
}

type ModelMediaDimensions {
  width?: number
  height?: number
}

type ModelMediaURL {
  url: string
}

type ModelImageImages {
  main: string
  superphoto: string
  thumb: string
}

type ModelImage = ModelMediaBase & ModelMediaDimensions & ModelMediaURL & {
  images: ModelImageImages
}

type ModelVideo = ModelMediaBase & ModelMediaURL & {
  poster_image: ModelImage
}

type ModelMedia = ModelAlbum | ModelImage | ModelVideo
`}
      </Code>
      <StorybookSubHeading>React</StorybookSubHeading>
      <StorybookParagraph>
        <strong>- Do not write class components</strong>
      </StorybookParagraph>
      <StorybookParagraph>
        - Try to utilize HOCs and write them yourself
      </StorybookParagraph>
      <StorybookParagraph>
        - Compose Container components that are composed of other smaller pure
        components:
      </StorybookParagraph>
      <Code>
        {`// from MediaManagerCardHeader component
type MediaManagerCardHeaderProps {
  ... some props
}

// from MediaManagerCardBody component
type MediaManagerCardBodyProps {
  ... some props
}        

// from MediaManagerCardFooter component
type MediaManagerCardFooterProps {
  ... some props
}

type MediaManagerCardProps {
  headerProps?: MediaManagerCardHeaderProps
  bodyProps?: MediaManagerCardBodyProps
  footerProps?: MediaManagerCardFooterProps
}

const MediaManagerCard: React.FC<MediaManagerCardProps>...
`}
      </Code>
      <StorybookParagraph>
        - When making composable container components, make the props optional
        if they are not really needed. Conditionally show the component that
        gets the props if the props are defined on the parent component
      </StorybookParagraph>
      <Code>
        {`// from MediaManagerCardHeader component
type MediaManagerCardHeaderProps {
  ... some props
}

// from MediaManagerCardBody component
type MediaManagerCardBodyProps {
  ... some props
}      

type MediaManagerCardProps {
  headerProps?: MediaManagerCardHeaderProps
} 

const MediaManagerCard: React.FC<MediaManagerCardProps> = ({
  headerProps,
 
}) => {
  return (
    <div>
      {headerProps && <MediaManagerCardHeader {...headerProps} />}
    </div>
  )
}        
`}
      </Code>
      <StorybookParagraph>
        - Utilize React's children as much as possible
      </StorybookParagraph>
      <StorybookParagraph>
        - For components that are pure and primitive, try to use{" "}
        <TailwindAnchor to={"https://reactjs.org/docs/react-api.html"}>
          React's Top Level API
        </TailwindAnchor>{" "}
        such as React.cloneElement, React.Children.map
      </StorybookParagraph>
      <StorybookParagraph>
        <strong>
          - Do not opinionate components with things that are not generic. A
          generic example that is good is that a button can have{" "}
          <Code inline>rounded: boolean</Code> prop that applies the Tailwind's
          rounded-full or rounded-3xl class. A bad example is{" "}
          <Code inline>showRoundedBordersWhenXisY</Code>
        </strong>
      </StorybookParagraph>
      <StorybookParagraph>
        - Stop using Material-UI components and start using Tailwind related
        components. If there are missing components, create a task on the
        Component Library board.
      </StorybookParagraph>
      <StorybookParagraph>
        - When creating components that are based off of Tailwind components,
        leave entry points for further customisation of the Tailwind components.
        An example can be that a very custom button that has the type{" "}
        <Code
          inline
        >{`type SomeCustomButtonProps = TailwindButtonProps & {...some custom props}`}</Code>{" "}
        will have the props from TailwindButton for customizing. If it is a
        nested component then have the type as a property of the parent type
        like{" "}
        <Code
          inline
        >{`type SomeComponent { textProps?: TailwindTextProps }`}</Code>
      </StorybookParagraph>
      <StorybookParagraph>
        - Try not to inject stores that are opinionated into reusable
        components. Reusable components will have only the injections for stores
        that are kind of a utility like LayoutStore, ThemeStore, ModalStore etc.
      </StorybookParagraph>
      <StorybookParagraph>
        - Try not to inject react-intl with injectIntl, instead use components
        that already has it injected to them like TranslatedDate,
        TranslatedNumber, TranslatedText, TranslatedTime
      </StorybookParagraph>
      <StorybookParagraph>
        - For custom hooks:{" "}
        <strong>please do not install hooks packages.</strong> Instead try to
        copy from the source code of the hook package or{" "}
        <TailwindAnchor to={"https://usehooks.com/"}>
          get hooks from here
        </TailwindAnchor>{" "}
        and paste into core/utilities/hooks folder. This gives us more control
        over the hooks and extend them if needed.
      </StorybookParagraph>
    </StorybookWrapper>
  );
};

export default StorybookConventionsJavascriptAndTypescriptAndReact;
