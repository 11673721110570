import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import TailwindAnchor from "../../components/_tailwind/anchor";

type Props = {};

const StorybookConventionsSCSS: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Conventions &gt; CSS & SCSS</StorybookHeading>
      <StorybookParagraph>
        The project uses both Material-UI and TailwindCSS for components &
        styling. The Material-UI components will be removed soon by switching to
        TailwindCSS components. If at any point you need to deliver something
        fast without worrying too much about replacing Material-UI components
        with TailwindCSS components or you have a very custom file that you
        cannot accomplish with TailwindCSS (like maybe 0.01 of the cases),
        please use a scss file.
      </StorybookParagraph>
      <Code>
        {`avatar/index.tsx
styles.scss
`}
      </Code>
      <StorybookParagraph>
        We use&nbsp;
        <TailwindAnchor to={"http://getbem.com/introduction/"}>
          Block Element Modifier (BEM)
        </TailwindAnchor>
        &nbsp; methodology when writing SCSS/CSS.
      </StorybookParagraph>
      <Code>
        {`.MediaManager {

  &__title {
    //some-css
  }
  &__album {
    //some album related css
    
    &--shape {
      &-rectangle {
        // some shape related css
      }
    }
  }        
}
`}
      </Code>
      <StorybookParagraph fontWeight={"font-bold"} textColor={"text-red-500"}>
        The write of CSS is not encouraged, please use Tailwind components and
        the props on them to set a classname dynamically.
      </StorybookParagraph>
      <StorybookParagraph>
        If it any case you need to use custom classNames please use classnames.
        The Tailwind components that accept a custom className already uses an
        array style of classNames that under the hood uses classnames package
        already.
      </StorybookParagraph>
      <StorybookParagraph>
        For concatenating classes we use{" "}
        <TailwindAnchor to={"https://github.com/JedWatson/classnames"}>
          classnames
        </TailwindAnchor>{" "}
        package. Please do not do string literals or string concatenation with
        the plus sign. For maintaining current code while gradually switching to
        Tailwind components, you can use classnames as below:
      </StorybookParagraph>
      <Code>
        {`import React from "react";
import classnames from "classnames";

type Props = {}

const SomeComponent: React.ComponentType<Props> = ({}) => {
  return (
    <div
      className={classnames("SomeComponent", isActive && "SomeComponent--active")}
    >
      some child
    </div>
  )
}

export default SomeComponent  
`}
      </Code>
    </StorybookWrapper>
  );
};

export default StorybookConventionsSCSS;
