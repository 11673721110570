import React from "react";
import TailwindBox, { TailwindBoxProps } from "../../../components/_tailwind/box";
type TailwindListItemProps = TailwindBoxProps & {}
const TailwindListItem: React.ComponentType<TailwindListItemProps> = ({
  children,
  ...props
}) => {
  return (
    <TailwindBox {...props} as={"li"}>{children}</TailwindBox>
  );
};
export default TailwindListItem;