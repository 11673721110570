import React from "react";
import "./styles.scss";
import classnames from "classnames";

type WheelOfFunActiveProps = {
  className?: string;
};

const WheelOfFunActive: React.ComponentType<WheelOfFunActiveProps> = ({ className }) => (
  <span className={classnames("WheelOfFunActive", className && className)}>
    <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 280 280'>
      <g>
        <circle fill='#FFFFFF' cx='140' cy='140' r='140' />
        <g>
          <path fill='#FFBB17' d='M139,138.2V4.7C89.7,5.1,46.7,31.8,23.3,71.4L139,138.2z' />
          <path fill='#EC5941' d='M141,138.2l115.7-66.8C233.3,31.8,190.3,5.1,141,4.7V138.2z' />
          <path
            fill='#FF328E'
            d='M142.1,140l115.7,66.8c11.2-19.7,17.6-42.5,17.6-66.8c0-24.3-6.4-47.1-17.6-66.8L142.1,140z'
          />
          <path fill='#B432FF' d='M141,141.8v133.5c49.3-0.4,92.3-27.1,115.7-66.8L141,141.8z' />
          <path fill='#228FFF' d='M139,141.8L23.3,208.6c23.4,39.7,66.4,66.4,115.7,66.8V141.8z' />
          <path
            fill='#29D170'
            d='M137.9,140L22.3,73.2C11.1,92.9,4.7,115.7,4.7,140c0,24.3,6.4,47.1,17.6,66.8L137.9,140z'
          />
        </g>
      </g>
    </svg>
  </span>
);

export default WheelOfFunActive;
