import { inject, observer } from "mobx-react";
import React, { useMemo, useEffect } from "react";
import BroadcastStore from "../../_stores/broadcast/BroadcastStore";
import BroadcastC2cViewer from "./broadcast-c2c-viewer";
import "./style.scss";
import WebRTCPlayer from "common/broadcast/webrtc-player";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import { WebRTCStatus } from "common/broadcast/_stores/broadcast/enums";
import config from "core/config";
interface Props {
  broadcastStore?: BroadcastStore;
  broadcastStreamStore?: BroadcastStreamStore;
}

const BroadcastC2CList: React.FunctionComponent<Props> = ({ broadcastStore }) => {
  const { setWebRTCStatus } = broadcastStore!;
  const c2cList = useMemo(() => {
    return Object.values(broadcastStore?.c2cHash || {});
  }, [broadcastStore?.c2cHash]);

  useEffect(() => {
    if (!config.disableWebrtcC2CVideo && c2cList.length === 0) setWebRTCStatus(WebRTCStatus.Stopped);
  }, [c2cList])
  
  useEffect(() => {
    console.log('TEST env var', config);
  }, [config])
  
  if (c2cList.length === 0) {
    return null;
  }
  return (
    <div className='BroadcastC2CList'>
      {!config.disableWebrtcC2CVideo ? (
        c2cList.map(c2c => (
          <WebRTCPlayer key={`${c2c.memberId}`} c2c={c2c} />
        ))
      ) : (
        c2cList.map(c2c => (
          <BroadcastC2cViewer key={`${c2c.memberId}`} c2c={c2c} />
        ))
      )}


      
    </div>
  );
};

export default inject("broadcastStore", "broadcastStreamStore")(observer(BroadcastC2CList));
