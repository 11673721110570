import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";

type Props = {};

const StudioPayoutTableHead: React.ComponentType<Props> = ({}) => {
  return (
    <TailwindFlex
      backgroundColor={"bg-table-primary-bg-color"}
      padding={["p-4"]}>
      {[
        "stream",
        "broadcastHours",
        "tierPayout",
        "bonuses",
        "payoutAdjustment",
        "total",
      ].map((earningField, index) => {
        return (
          <TailwindFlex
            key={index}
            width={"w-2/12"}
            justifyContent={index > 1 ? "justify-end" : "justify-start"}>
            <TailwindTranslatedText
              fontWeight='font-bold'
              descriptor={{
                id: "studioPayout.earning." + earningField,
                defaultMessage: earningField,
              }}
            />
          </TailwindFlex>
        );
      })}
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          descriptor={{
            id: "studioPayout.earning.details",
            defaultMessage: "Details",
          }}
        />
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default StudioPayoutTableHead;
