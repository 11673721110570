import React from "react";
import FeedHeader, { FeedHeaderProps } from "./feed-header";
import FeedFooter from "./feed-footer";
import FeedCover, { FeedCoverProps } from "./feed-cover";
import TailwindIcon from "../icon";
import { CustomIconName } from "../icon/icons/enums";
import TailwindCard, { TailwindCardProps } from "../card";

export type FeedStoryProps = TailwindCardProps & {
  feedCoverProps?: FeedCoverProps;
  feedHeaderProps: FeedHeaderProps;
  isPinned?: boolean;
  viewCount: number;
  commentCount: number;
};

const FeedStory: React.ComponentType<FeedStoryProps> = ({
  feedCoverProps,
  feedHeaderProps,
  viewCount,
  commentCount,
  ...props
}) => {
  return (
    <TailwindCard backgroundColor={"bg-gray-800"} rounded {...props}>
      <FeedHeader {...feedHeaderProps} />
      {feedCoverProps && <FeedCover {...feedCoverProps} />}
      <FeedFooter
        wrapperProps={{
          display: "flex",
          flexGrow: "flex-grow-0",
          alignItems: "items-center",
        }}
      >
        <TailwindIcon name={CustomIconName.eye}></TailwindIcon>
        &nbsp;{viewCount}&nbsp;&nbsp;
        <TailwindIcon name={CustomIconName["chat-bubble"]}></TailwindIcon>
        &nbsp;{commentCount}
      </FeedFooter>
    </TailwindCard>
  );
};

export default FeedStory;
