import React, { FunctionComponent } from "react";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import "./styles.scss";
import ProfileStore from "../my-page/stores/profile/ProfileStore";
import CamsStudioLandingContainer from "./cams/studio-landing-container";
import CamsModelLandingContainer from "./cams/model-landing-container";

type LandingContainerProps = {
  profileStore?: ProfileStore;
};

const LandingContainer: FunctionComponent<
  LandingContainerProps & WrappedComponentProps
> = ({ profileStore }) => {
  const { isStudio } = profileStore!;
  if (isStudio) {
    return <CamsStudioLandingContainer />;
  } else {
    return <CamsModelLandingContainer />;
  }
};

export default injectIntl(inject("profileStore")(observer(LandingContainer)));
