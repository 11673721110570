import React, { useCallback, useState } from "react";
import "./styles.scss";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import { TFontSize } from "library/styles/tailwind-classnames";
import { inject, observer } from "mobx-react";
import MessageStore from "../../messages/stores/messages/MessageStore";
import { Popover } from "../../popover";
import TailwindBox from "library/components/_tailwind/box";

const FONTS = [12, 14, 16, 20, 24, 30, 36, 42, 50];
const TAILWINDFONTS: TFontSize[] = [
  "text-xs",
  "text-sm",
  "text-lg",
  "text-xl",
  "text-2xl",
];
const fontSizeInfo = {
  "text-xs": {
    id: "extra-small",
    defaultMessage: "Extra Small",
  },
  "text-sm": {
    id: "small",
    defaultMessage: "Small",
  },
  "text-lg": {
    id: "normal",
    defaultMessage: "Normal",
  },
  "text-xl": {
    id: "large",
    defaultMessage: "Large",
  },
  "text-2xl": {
    id: "extra-large ",
    defaultMessage: "Extra Large",
  },
};
interface IFontSizePopoverProps {
  messageStore?: MessageStore;
  fontSize: number | TFontSize;
  onSetFont: (fontSize: number | TFontSize) => void;
  children: any;
}

const BroadcastChatFontSizePopover = ({
  messageStore,
  fontSize,
  onSetFont,
  children,
}: IFontSizePopoverProps) => {
  const { messageFontSize } = messageStore!;

  const [show, setShow] = useState(false);

  const onHide = useCallback(() => {
    setShow(false);
  }, []);

  const onShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleSetFont = useCallback(data => {
    onSetFont(data);
    setShow(false);
  }, []);

  return (
    <Popover
      placement='top-start'
      onHide={onHide}
      onShow={onShow}
      show={show}
      content={
        <TailwindBox borderWidth={["border-2"]} borderColor={"border-blue-500"}>
          {typeof fontSize === "number" && (
            <div className='ChatFontSizePopover'>
              {FONTS.map((font: number) => (
                <div
                  key={font}
                  className={`fontSizeItem text-main-color ${
                    fontSize === font ? "selectedFontSize" : ""
                  }`}
                  onClick={() => {
                    handleSetFont(font);
                  }}>
                  {font}px
                </div>
              ))}
            </div>
          )}
          {typeof fontSize === "string" && (
            <div className='ChatFontSizePopover ChatFontSizePopover__tailwind-sizes'>
              {TAILWINDFONTS.map((font: TFontSize) => (
                <div
                  key={font as string}
                  className={`fontSizeItem ${
                    fontSize === font ? "selectedFontSize" : ""
                  }`}
                  onClick={() => {
                    handleSetFont(font);
                  }}>
                  <TailwindTranslatedText
                    textColor={
                      messageFontSize === font
                        ? "text-gray-500"
                        : "text-main-color"
                    }
                    fontSize={font as TFontSize}
                    descriptor={{
                      id: `font-size-popover.${
                        fontSizeInfo[font as TFontSize]["id"]
                      }`,
                      defaultMessage:
                        fontSizeInfo[font as string]["defaultMessage"],
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </TailwindBox>
      }>
      {children}
    </Popover>
  );
};

export default inject("messageStore")(observer(BroadcastChatFontSizePopover));
