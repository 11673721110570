import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import Avatar from "../../components/avatar";
import TailwindFlex from "../../components/_tailwind/flex";

type Props = {};

const StorybookComponentsAvatar: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Avatar</StorybookHeading>
      <StorybookParagraph>
        Avatar has not yet been refactored to use Tailwind components. It takes
        static props for coloring via styles.scss therefore it is not as
        flexible as Tailwind components yet. Here are the props it takes:
      </StorybookParagraph>
      <Code>
        {` photoURL?: string;
getPhotoURLFromStore?: boolean;
customOverlayIcon?: string;
showOverlayIcon?: boolean;
borderSize?: "thick" | "normal" | "thin" | "none";
borderColor?: "title-color-1" | "yellow" | "purple" | "none";
size?: "normal" | "small" | "extra-small" | "large" | "larger" | "fit";
shapeVariant?: "circle" | "none" | "soft";
className?: string;
`}
      </Code>
      <StorybookParagraph>Here are some examples:</StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<Avatar
  photoURL={
    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb01-1b1f87ed74cf90fd190f20abb17c5414-9211b9.jpg"
  }
/>
`}
      >
        <Avatar
          photoURL={
            "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb01-1b1f87ed74cf90fd190f20abb17c5414-9211b9.jpg"
          }
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<Avatar />
`}
      >
        <StorybookParagraph>
          An avatar that has not been set yet
        </StorybookParagraph>
        <Avatar />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<Avatar shapeVariant={"soft"} />
`}
      >
        <StorybookParagraph>An avatar that has soft edges</StorybookParagraph>
        <Avatar shapeVariant={"soft"} />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<Avatar shapeVariant={"soft"} borderSize={"none"} />
`}
      >
        <StorybookParagraph>Border none</StorybookParagraph>
        <Avatar shapeVariant={"soft"} borderSize={"none"} />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<Avatar
  shapeVariant={"soft"}
  borderSize={"thin"}
  borderColor={"purple"}
/>
`}
      >
        <StorybookParagraph>Border thin & purple</StorybookParagraph>
        <Avatar
          shapeVariant={"soft"}
          borderSize={"thin"}
          borderColor={"purple"}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<Avatar
  shapeVariant={"soft"}
  size={"large"}
  borderSize={"thin"}
  borderColor={"purple"}
/>
`}
      >
        <StorybookParagraph>Large, Border thin & purple</StorybookParagraph>
        <Avatar
          shapeVariant={"soft"}
          size={"large"}
          borderSize={"thin"}
          borderColor={"purple"}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindFlex width={"w-4/12"}>
  <Avatar
    shapeVariant={"soft"}
    size={"fit"}
    borderSize={"thin"}
    borderColor={"purple"}
  />
</TailwindFlex>
`}
      >
        <StorybookParagraph>
          Fit to container, Border thin & purple
        </StorybookParagraph>
        <TailwindFlex width={"w-4/12"}>
          <Avatar
            shapeVariant={"soft"}
            size={"fit"}
            borderSize={"thin"}
            borderColor={"purple"}
          />
        </TailwindFlex>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsAvatar;
