import React from "react";
import "./styles.scss";
import classnames from "classnames";

type CommentsIconProps = {
  className?: string;
};

const CommentsIcon: React.ComponentType<CommentsIconProps> = ({
  className,
}) => (
  <span className={classnames("CommentsIcon", className && className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 36 36"
    >
      <g transform="translate(0, 0)">
        <defs>
          <path
            id="path-161463783389694693"
            d="M0 18 C0 8.0588745036 8.0588745036 0 18 0 C18 0 18 0 18 0 C27.941125496399998 0 36 8.0588745036 36 18 C36 18 36 18.000000000000014 36 18.000000000000014 C36 27.941125496400012 27.941125496399998 36.000000000000014 18 36.000000000000014 C18 36.000000000000014 18 36.000000000000014 18 36.000000000000014 C8.0588745036 36.000000000000014 0 27.941125496400012 0 18.000000000000014 C0 18.000000000000014 0 18 0 18 Z"
            vectorEffect="non-scaling-stroke"
          />
        </defs>
        <g transform="translate(0, 0)">
          <path
            d="M0 18 C0 8.0588745036 8.0588745036 0 18 0 C18 0 18 0 18 0 C27.941125496399998 0 36 8.0588745036 36 18 C36 18 36 18.000000000000014 36 18.000000000000014 C36 27.941125496400012 27.941125496399998 36.000000000000014 18 36.000000000000014 C18 36.000000000000014 18 36.000000000000014 18 36.000000000000014 C8.0588745036 36.000000000000014 0 27.941125496400012 0 18.000000000000014 C0 18.000000000000014 0 18 0 18 Z"
            style={{
              stroke: "rgb(140, 140, 140)",
              strokeWidth: 0,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
            }}
            vectorEffect="non-scaling-stroke"
          />
        </g>
      </g>
    </svg>
  </span>
);

export default CommentsIcon;
