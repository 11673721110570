import React from "react";
import Avatar from "../../avatar";
import TailwindCard from "../card";
import TailwindFlex, { TailwindFlexProps } from "../flex";
import TailwindTranslatedTime from "../translated-time";

export type FeedCommentProps = TailwindFlexProps & {
  profile: any;
  date: Date;
  comment: string;
  id: string;
};

const FeedComment: React.ComponentType<FeedCommentProps> = ({
  profile,
  comment,
  date,
  ...props
}) => {
  return (
    <TailwindFlex {...props} margin={["mb-2"]}>
      <Avatar photoURL={profile.profilePhoto} />
      <TailwindFlex width={"w-auto"}>
        <TailwindCard
          borderRadius={[
            "rounded-tl-none",
            "rounded-br-2xl",
            "rounded-tr-2xl",
            "rounded-bl-2xl",
          ]}
          overflow={["overflow-hidden"]}
          backgroundColor={"bg-gray-600"}
          margin={["mx-2"]}
          padding={["p-2"]}
          alignItems={"items-center"}
        >
          <TailwindFlex>{profile.username}</TailwindFlex>
          <TailwindFlex>{comment}</TailwindFlex>
        </TailwindCard>
      </TailwindFlex>
      <TailwindTranslatedTime
        value={date}
        textProps={{ display: "inline-flex", alignItems: "items-end" }}
      />
    </TailwindFlex>
  );
};

export default FeedComment;
