import React from "react";
import TailwindText, { TailwindTextProps } from "../text";
import CustomIcon, { CustomIconProps } from "./icons";
import { CustomIconName } from "./icons/enums";
import classnames from "classnames";
import tw from "../../../styles/tailwind-classnames";

export type TailwindIconProps = TailwindTextProps & {
  name: CustomIconName | string | undefined | React.ReactNode;
  className?: string;
  iconProps?: CustomIconProps;
  style?: React.CSSProperties;
  iconStyleProps?: CustomIconProps;
  textProps?: CustomIconProps;
};

const TailwindIcon = React.forwardRef<HTMLElement, TailwindIconProps>(
  (
    {
      name,
      className,
      fontSize,
      textColor,
      width,
      height,
      style,
      iconProps,
      iconStyleProps,
      textProps,
      ...props
    },
    ref
  ) => {
    if (name) {
      return (
        <TailwindText
          {...props}
          ref={ref}
          fontSize={fontSize}
          textColor={textColor}
          {...textProps}
          display={"inline-flex"}
          style={style}>
          <CustomIcon
            props={iconProps}
            name={name}
            className={classnames(
              className,
              tw(fontSize, textColor, width, height)
            )}
            {...iconStyleProps}
          />
        </TailwindText>
      );
    }

    return null;
  }
);

export default TailwindIcon;
