import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindCard from "library/components/_tailwind/card";
import TailwindText from "library/components/_tailwind/text";
import TailwindTranslatedNumber from "library/components/_tailwind/translated-number";
import EarningsStore from "common/earnings/_stores/earnings/EarningsStore";
import { inject, observer } from "mobx-react";

type Props = {
  earningsStore?: EarningsStore;
};

const StudioPayoutTableTotal: React.ComponentType<Props> = ({
  earningsStore,
}) => {
  const { studioEarningsPeriodSum, totalBroadcastHours } = earningsStore!;

  return (
    <TailwindCard
      flexDirection={"flex-col"}
      width={"w-full"}
      padding={["p-4", "pb-4"]}
      backgroundColor={"bg-table-primary-bg-color"}>
      <TailwindFlex width={"w-full"} flexDirection={"flex-col"}>
        <TailwindFlex>
          <TailwindFlex
            width={"w-2/12"}
            justifyContent={"justify-start"}
            overflow={"overflow-hidden"}>
            <TailwindText
              width={"w-full"}
              padding={["pr-1"]}
              className={"capitalize-first"}
              textOverflow={"overflow-ellipsis"}
              overflow={"overflow-hidden"}>
              Totals
            </TailwindText>
          </TailwindFlex>

          <TailwindFlex
            width={"w-2/12"}
            justifyContent={"justify-start"}
            overflow={"overflow-hidden"}>
            <TailwindTranslatedNumber
              value={totalBroadcastHours || 0}
              style={"decimal"}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          </TailwindFlex>

          {[
            "total_tiered_tokens_earned",
            "total_fan_club_tokens",
            "total_chargeback_tokens",
            "total_period_earnings",
          ].map((earningField, index) => {
            return (
              <TailwindFlex
                key={index}
                width={"w-2/12"}
                justifyContent={"justify-end"}
                overflow={"overflow-hidden"}>
                {earningField === "total_chargeback_tokens" ? (
                  <TailwindTranslatedNumber
                    value={
                      -(
                        (studioEarningsPeriodSum?.total_chargeback_tokens ||
                          0) * 0.1
                      )
                    }
                    style={"currency"}
                    currency='USD'
                  />
                ) : (
                  <TailwindTranslatedNumber
                    value={(studioEarningsPeriodSum?.[earningField] || 0) * 0.1}
                    style={"currency"}
                    currency='USD'
                  />
                )}
              </TailwindFlex>
            );
          })}
          <TailwindFlex
            width={"w-2/12"}
            justifyContent={"justify-end"}
            overflow={"overflow-hidden"}></TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindCard>
  );
};

export default inject("earningsStore")(observer(StudioPayoutTableTotal));
