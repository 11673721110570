import React from "react";
import TailwindFlex, { TailwindFlexProps } from "library/components/_tailwind/flex";

type Props = TailwindFlexProps & {};

const MediaManagerMediaCardFooter: React.ComponentType<Props> = ({ children, ...props }) => {
  return (
    <TailwindFlex
      {...props}
      position={"absolute"}
      inset={["bottom-0"]}
      padding={["py-2", "px-2"]}
      justifyContent={"justify-between"}>
      {children}
    </TailwindFlex>
  );
};

export default MediaManagerMediaCardFooter;
