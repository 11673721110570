import { useEffect, useRef } from "react";

export function useInterval(
  callback: Function,
  delay?: number,
  reset?: boolean
) {
  const savedCallback = useRef<Function>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (typeof delay !== "undefined") {
      const id = setInterval(tick, delay);

      if (reset) {
        clearInterval(id);
      }

      return () => clearInterval(id);
    }
  }, [delay, reset]);
}
