import React, { useCallback, useEffect, useMemo, useState } from "react";
import MediaManagerStore from "./store/MediaManagerStore";
import { MEDIA_MANAGER_ALBUM_PRICE_DROPDOWN_ITEMS } from "./store/consts";
import { inject, observer } from "mobx-react";
import { ModelImage, ModelVideo } from "./store/types";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindDropdown, {
  TailwindDropdownValue,
} from "library/components/_tailwind/dropdown";
import TailwindCard from "library/components/_tailwind/card";
import TailwindInput from "library/components/_tailwind/input";
import Image from "library/components/image";
import TailwindParagraph from "library/components/_tailwind/paragraph";
import VideoPlayer from "library/components/_tailwind/video-player";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import TailwindBox from "library/components/_tailwind/box";
import TailwindPill from "library/components/_tailwind/pill";
import TailwindText from "library/components/_tailwind/text";
import LanguageStore from "library/core/stores/language/LanguageStore";

type Props = {
  mediaManagerStore?: MediaManagerStore;
  media: ModelImage | ModelVideo;
  languageStore?: LanguageStore;
};

const MediaManagerMediaManage: React.ComponentType<Props> = ({
  mediaManagerStore,
  media,
  languageStore,
}) => {
  const {
    //setIsPromptOpen,
    setMediaName,
    setMediaPrice,
    getMediaDateWithTime,
    setMediaDescription,
    getMediaName,
    getMediaDuration,
    getRecordedShowMediaName,
    isDefaultMediaName,
    selectedAlbum,
  } = mediaManagerStore!;

  const { intl } = languageStore!;

  const [mediaLoaded, setMediaLoaded] = useState(false);

  useEffect(() => {
    if (media.type !== "image") {
      setMediaLoaded(true);
    }
  }, [media.type]);

  const onChangeMediaName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMediaName(event.target.value, media);
    },
    [media]
  );

  const priceDropdownValue = useMemo(() => {
    if (media?.price_in_token !== undefined && media?.price_in_token !== null) {
      return MEDIA_MANAGER_ALBUM_PRICE_DROPDOWN_ITEMS.find(
        item => item.value === media?.price_in_token
      );
    }

    return MEDIA_MANAGER_ALBUM_PRICE_DROPDOWN_ITEMS[0];
  }, [media?.price_in_token]);

  const onChangePrice = (value: TailwindDropdownValue) => {
    if ((value && media) || (value === 0 && media)) {
      setMediaPrice(value as number, media);
    }
  };

  const shouldShowPriceDropdown = useMemo(() => {
    if ((selectedAlbum?.price_in_token || 0) > 0 || media.type === "image") {
      return false;
    }

    return true;
  }, [selectedAlbum]);

  const onChangeMediaDescription = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMediaDescription(event.target.value, media as ModelVideo);
    },
    [media]
  );

  return (
    <TailwindCard
      className={["MediaManagerMediaManage"]}
      display={"flex"}
      flexDirection={"flex-col"}
      width={"w-full"}
      backgroundColor={"primary"}
      borderRadius={"rounded"}
      padding={[media.type === "video" ? "p-7" : "p-4"]}
      style={{
        maxHeight: "98vh",
        maxWidth: selectedAlbum?.album_type === "recorded" ? "535px" : "98vw",
        display: mediaLoaded ? "block" : "none",
      }}
      pseudoClasses={["lg:max-w-full", "lg:p-2", "md:overflow-auto"]}>
      <TailwindFlex
        flexDirection={"flex-row"}
        justifyContent={"justify-center"}
        pseudoClasses={["lg:flex-col-reverse"]}>
        <TailwindFlex
          width={"w-full"}
          flexDirection={"flex-col"}
          justifyContent={"justify-center"}
          alignItems={"items-center"}
          pseudoClasses={["lg:w-full", "md:mt-10"]}>
          {media.type === "video" && (
            <VideoPlayer
              src={(media as ModelVideo).video}
              useHls={(media as ModelVideo).video.includes(".m3u8")}
              fullHeight={false}
              fullWidth={false}
              wrapperProps={{
                display: "flex",
                width: "w-full",
                height: "h-full",
                justifyContent: "justify-center",
              }}
              style={{
                aspectRatio: "16 / 9",
                maxHeight: "80vh",
                width:
                  selectedAlbum?.album_type === "recorded" ? "475px" : "100%",
              }}></VideoPlayer>
          )}
          {media.type === "image" && (
            <Image
              src={(media as ModelImage).images?.superphoto}
              style={{ maxHeight: "80vh" }}
              onLoad={() => setMediaLoaded(true)}
            />
          )}
        </TailwindFlex>
        {selectedAlbum?.album_type !== "recorded" && (
          <TailwindFlex
            width={"w-1/3"}
            flexDirection={"flex-col"}
            padding={["pl-4", "pr-6"]}
            pseudoClasses={["lg:w-full", "md:pl-0"]}>
            {(selectedAlbum?.album_type === "custom" ||
              selectedAlbum?.album_type === "videos") && (
              <>
                <TailwindBox margin={["mb-2"]}>
                  <TailwindInput
                    value={isDefaultMediaName(media) ? "" : getMediaName(media)}
                    placeholder={"Optional title"}
                    labelProps={{
                      textColor: "text-gray-500",
                    }}
                    onChange={onChangeMediaName}
                    fontWeight={"font-bold"}
                    wrapInput
                    width={"w-full"}
                    autoFocus
                    boxProps={{
                      backgroundColor: "bg-primary-bg-color",
                      margin: ["mb-3"],
                    }}
                    psuedoClasses={["focus:outline-none"]}
                  />
                  {media?.pending_title &&
                    media?.pending_title_status === "PENDING" && (
                      <TailwindPill
                        borderRadius={"rounded-full"}
                        backgroundColor={"bg-gray-600"}
                        size='sm'
                        padding={["px-4", "py-2"]}
                        textColor={"text-white"}>
                        <TailwindText fontSize={"text-sm"}>
                          {"Pending"}
                        </TailwindText>
                      </TailwindPill>
                    )}
                </TailwindBox>
                {media.type === "video" && (
                  <TailwindBox margin={["mb-2"]}>
                    <TailwindInput
                      value={
                        (media as ModelVideo)?.pending_description &&
                        (media as ModelVideo)?.pending_description_status ===
                          "PENDING"
                          ? (media as ModelVideo)?.pending_description
                          : (media as ModelVideo)?.description || ""
                      }
                      maxLength={375}
                      type={TailwindInputType.textarea}
                      onChange={onChangeMediaDescription}
                      fontWeight={"font-bold"}
                      wrapInput
                      width={"w-full"}
                      autoFocus
                      boxProps={{
                        backgroundColor: "bg-primary-bg-color",
                        height: "h-full",
                        textColor: "text-gray-500",
                        margin: ["mb-3"],
                      }}
                      labelProps={{
                        textColor: "text-gray-500",
                      }}
                      psuedoClasses={["focus:outline-none"]}
                      maxRows={4}
                      minRows={4}
                    />
                    {(media as ModelVideo)?.pending_description &&
                      (media as ModelVideo)?.pending_description_status ===
                        "PENDING" && (
                        <TailwindPill
                          borderRadius={"rounded-full"}
                          backgroundColor={"bg-gray-600"}
                          size='sm'
                          padding={["px-4", "py-2"]}
                          textColor={"text-white"}>
                          <TailwindText fontSize={"text-sm"}>
                            {"Pending"}
                          </TailwindText>
                        </TailwindPill>
                      )}
                  </TailwindBox>
                )}
                {shouldShowPriceDropdown && (
                  <TailwindBox margin={["mb-2"]}>
                    <TailwindDropdown
                      items={MEDIA_MANAGER_ALBUM_PRICE_DROPDOWN_ITEMS}
                      value={priceDropdownValue?.value as any}
                      onChange={onChangePrice}
                      name={"media-price"}
                      label={"Price"}
                      width={"w-full"}
                      fullWidth={true}
                      backgroundColor={"bg-primary-bg-color"}
                      menuProps={{
                        maxHeight: "max-h-40",
                      }}
                      disabled={false}
                    />
                  </TailwindBox>
                )}
              </>
            )}
            <TailwindFlex flexGrow='flex-grow' />
            <TailwindFlex flexDirection={"flex-col"}>
              <TailwindParagraph
                width={"w-full"}
                fontSize={"text-xs"}
                margin={["mt-3", "mb-1"]}
                textColor={"text-gray-600"}>
                {intl.formatMessage({
                  id: "myPage.editor.disclaimer-terms-of-use",
                  defaultMessage:
                    "Photos & videos must not have copyright protection, poor resolution, pixelation, custom frames, text or graphic overlays, unauthorized endorsements or badges, contact information, or subject material that otherwise violates the Terms of Use.",
                })}
              </TailwindParagraph>
              <TailwindParagraph
                width={"w-full"}
                fontSize={"text-xs"}
                textColor={"text-gray-600"}>
                {intl.formatMessage({
                  id: "myPage.editor.disclaimer-reviews",
                  defaultMessage:
                    "All photos/videos are reviewed by Streamray staff. Until approved or rejected, media(s) will not be visible to members. Rejected media will be removed from your media list.",
                })}
              </TailwindParagraph>
            </TailwindFlex>
          </TailwindFlex>
        )}
      </TailwindFlex>
      {selectedAlbum?.album_type === "recorded" && (
        <>
          <TailwindFlex
            justifyContent={"justify-center"}
            flexDirection={"flex-row"}
            margin={["mt-3"]}
            width={"w-full"}>
            <TailwindFlex
              flexDirection={"flex-col"}
              justifyContent={"justify-center"}
              backgroundColor={"bg-table-primary-bg-color"}
              padding={["pl-5"]}
              width={"w-full"}>
              <TailwindText style={{ fontSize: "12px" }}>
                {intl.formatMessage({
                  id: "recordedShows.file_name",
                  defaultMessage: "File Name",
                })}
              </TailwindText>
            </TailwindFlex>
            <TailwindFlex
              flexDirection={"flex-col"}
              justifyContent={"justify-center"}
              backgroundColor={"bg-table-primary-bg-color"}
              width={"w-full"}>
              <TailwindText
                style={{ fontSize: "12px" }}
                fontWeight={"font-bold"}>
                {getRecordedShowMediaName(media)}
              </TailwindText>
              <TailwindFlex />
            </TailwindFlex>
          </TailwindFlex>
          <TailwindFlex
            justifyContent={"justify-center"}
            flexDirection={"flex-row"}
            width={"w-full"}>
            <TailwindFlex
              flexDirection={"flex-col"}
              justifyContent={"justify-center"}
              padding={["pl-5"]}
              width={"w-full"}>
              <TailwindText style={{ fontSize: "12px" }}>
                {intl.formatMessage({
                  id: "recordedShows.number_purchased",
                  defaultMessage: "Number Purchased",
                })}
              </TailwindText>
            </TailwindFlex>
            <TailwindFlex
              flexDirection={"flex-col"}
              justifyContent={"justify-center"}
              width={"w-full"}>
              <TailwindText
                style={{ fontSize: "12px" }}
                fontWeight={"font-bold"}>
                {media.num_purchases}
              </TailwindText>
              <TailwindFlex />
            </TailwindFlex>
          </TailwindFlex>
          <TailwindFlex
            justifyContent={"justify-center"}
            flexDirection={"flex-row"}
            width={"w-full"}>
            <TailwindFlex
              flexDirection={"flex-col"}
              justifyContent={"justify-center"}
              padding={["pl-5"]}
              backgroundColor={"bg-table-primary-bg-color"}
              width={"w-full"}>
              <TailwindText style={{ fontSize: "12px" }}>
                {intl.formatMessage({
                  id: "recordedShows.video_id",
                  defaultMessage: "Video ID",
                })}
              </TailwindText>
            </TailwindFlex>
            <TailwindFlex
              flexDirection={"flex-col"}
              justifyContent={"justify-center"}
              backgroundColor={"bg-table-primary-bg-color"}
              width={"w-full"}>
              <TailwindText
                style={{ fontSize: "12px" }}
                fontWeight={"font-bold"}>
                {media.id}
              </TailwindText>
              <TailwindFlex />
            </TailwindFlex>
          </TailwindFlex>
          <TailwindFlex
            justifyContent={"justify-center"}
            flexDirection={"flex-row"}
            width={"w-full"}>
            <TailwindFlex
              flexDirection={"flex-col"}
              justifyContent={"justify-center"}
              padding={["pl-5"]}
              width={"w-full"}>
              <TailwindText style={{ fontSize: "12px" }}>
                {intl.formatMessage({
                  id: "recordedShows.length",
                  defaultMessage: "Length",
                })}
              </TailwindText>
            </TailwindFlex>
            <TailwindFlex
              flexDirection={"flex-col"}
              justifyContent={"justify-center"}
              width={"w-full"}>
              <TailwindText
                style={{ fontSize: "12px" }}
                fontWeight={"font-bold"}>
                {getMediaDuration((media as ModelVideo).length)}
              </TailwindText>
              <TailwindFlex />
            </TailwindFlex>
          </TailwindFlex>
          <TailwindFlex
            justifyContent={"justify-center"}
            flexDirection={"flex-row"}
            width={"w-full"}>
            <TailwindFlex
              flexDirection={"flex-col"}
              justifyContent={"justify-center"}
              backgroundColor={"bg-table-primary-bg-color"}
              padding={["pl-5"]}
              width={"w-full"}>
              <TailwindText style={{ fontSize: "12px" }}>
                {intl.formatMessage({
                  id: "recordedShows.creation_date",
                  defaultMessage: "Creation Date",
                })}
              </TailwindText>
            </TailwindFlex>
            <TailwindFlex
              flexDirection={"flex-col"}
              justifyContent={"justify-center"}
              backgroundColor={"bg-table-primary-bg-color"}
              width={"w-full"}>
              <TailwindText
                style={{ fontSize: "12px" }}
                fontWeight={"font-bold"}>
                {getMediaDateWithTime(media)}
              </TailwindText>
              <TailwindFlex />
            </TailwindFlex>
          </TailwindFlex>
        </>
      )}
    </TailwindCard>
  );
};

export default inject(
  "mediaManagerStore",
  "languageStore"
)(observer(MediaManagerMediaManage));
