import React, { useCallback, useMemo } from "react";
import { Tab } from "./types";
import TailwindFlex, { TailwindFlexProps } from "../flex";
import TailwindTabButton from "./tab-button";
import TailwindBox, { TailwindBoxProps } from "../box";
import { TailwindButtonProps } from "../button";
import { TOverflow } from "../../../styles/tailwind-classnames";

export type TailwindTabHeaderProps = TailwindFlexProps & {
  tabs: Omit<Tab, "component">[];
  activeTabIndex: number;
  setActiveTab: (index: number, id: string) => void;
  buttonProps?: TailwindButtonProps;
  buttonContainerProps?: TailwindBoxProps;
  activeButtonProps?: TailwindButtonProps;
  fitWidthAndScrollHorizontally?: boolean;
  showOverflow?: boolean;
  leftChild?: JSX.Element;
  rightChild?: JSX.Element;
};

const TailwindTabHeader: React.ComponentType<TailwindTabHeaderProps> = ({
  tabs,
  activeTabIndex,
  setActiveTab,
  buttonProps,
  buttonContainerProps,
  activeButtonProps,
  fitWidthAndScrollHorizontally = false,
  showOverflow = false,
  leftChild,
  rightChild,
  ...props
}) => {
  const getButtonProps = useCallback(
    (index: number) => {
      const defaultTextColor = "text-main-color";
      const defaultRounded = false;
      const _props: TailwindButtonProps = {
        ...(activeTabIndex === index
          ? { ...buttonProps, ...activeButtonProps } || buttonProps
          : buttonProps),
        fullWidth:
          buttonProps?.fullWidth !== undefined ? buttonProps?.fullWidth : false,
        margin: [index !== tabs.length - 1 ? "mr-4" : "mr-0"],
        backgroundColor: buttonProps?.backgroundColor || "bg-gray-100",
        textColor: buttonProps?.textColor || defaultTextColor,
        isActive: false,
        padding: ["px-1"],
        rounded:
          buttonProps?.rounded !== undefined
            ? buttonProps.rounded
            : defaultRounded,
      };
      const isActive = activeTabIndex === index;
      if (isActive) {
        _props.isActive = true
        _props.backgroundColor =
          activeButtonProps?.backgroundColor || "bg-gray-200";
        _props.textColor = activeButtonProps?.textColor || defaultTextColor;
        _props.rounded = activeButtonProps?.rounded || defaultRounded;
      }

      return _props;
    },
    [activeTabIndex, activeButtonProps, buttonProps]
  );

  const overflow = useMemo(() => {
    if (fitWidthAndScrollHorizontally) {
      return ["overflow-x-auto", "overflow-y-hidden"] as TOverflow[];
    } else if (showOverflow) {
      return [] as TOverflow[];
    }
    return ["overflow-hidden"] as TOverflow[];
  }, [fitWidthAndScrollHorizontally, showOverflow]);

  return (
    <TailwindFlex
      {...props}
      margin={[...(props?.margin ?? ["mb-4"])]}
      padding={fitWidthAndScrollHorizontally ? ["pb-4"] : []}
      overflow={overflow}>
      {leftChild}
      {tabs.map((tab, index) =>
        tab.hideHeader ? (
          <TailwindBox></TailwindBox>
        ) : (
          <TailwindBox
            className={
              tab.isHidden
                ? ["tab-hidden"]
                : !tab.isHidden && tab.id === "viewers"
                ? ["tab-active"]
                : []
            }
            {...buttonContainerProps}
            key={`tab-header-button-wrapper-${index}`}>
            <TailwindTabButton
              data-testid={tab.dataTestId}
              data-tab-label={tab.dataTabLabel}
              className={["text-center"]}
              {...getButtonProps(index)}
              key={`tab-header-button-${index}`}
              onClick={() => setActiveTab(index, tab.id)}
              rightIconProps={tab.rightIconProps}>
              {tab.label}
            </TailwindTabButton>
          </TailwindBox>
        )
      )}
      {rightChild}
    </TailwindFlex>
  );
};

export default TailwindTabHeader;
