export type ChatSocketMessage = any & { action: string };

export enum MemberSortTypes {
  TOP_SPENDER = "top_spender",
  TIME_JOINED = "time_joined",
  REG_DATE = "reg_date",
  ALPHA = "alpha",
}

export type LovenseToyStatus = {
  id?: string;
  name?: string;
  type?: string;
  status?: string;
  version?: string;
  battery?: number;
};
export type QuickTextID = 'F1'| 'F2' | 'F3' | 'F4' | 'F5' | 'F6' | 'F7' | 'F8' | 'F9' | 'F10'

export type QuickText = {
  id: QuickTextID,
  status: string,
  text: string
}