import React from "react";
import "./styles.scss";
import classnames from "classnames";

type WheelOfFunIconProps = {
    className?: string;
    style?: React.CSSProperties;
}

const WheelOfFunIcon: React.ComponentType<WheelOfFunIconProps> = ({ className, style }) => (
    <span className={classnames(className)} style={style}>
        <img
            src='/images/broadcast/notifications/wheel-of-fun.png'
            alt='Wheel of Fun'
        />
    </span>
    
);

export default WheelOfFunIcon;