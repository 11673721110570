import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import TailwindAnchor from "../../components/_tailwind/anchor";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindIcon from "../../components/_tailwind/icon";
import { CustomIconName } from "../../components/_tailwind/icon/icons/enums";
import TailwindBox from "../../components/_tailwind/box";
import StorybookSubHeading from "../_internal/sub-heading";
import Code from "../../components/_tailwind/code";

type Props = {};

const StorybookComponentsIcon: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Icon</StorybookHeading>
      <StorybookParagraph>
        Icon is a wrapper for our custom SVG icons as well as string versions of{" "}
        <TailwindAnchor
          to={"https://material-ui.com/components/material-icons/"}
        >
          Material-UI icons
        </TailwindAnchor>
        . It is wrapped by TailwindText for coloring & sizing icons easily. It
        takes all props from TailwindText component.
      </StorybookParagraph>
      <StorybookSubHeading>How to add new icons:</StorybookSubHeading>
      <Code>
        {`- Go to root folder of TailwindIcon component
- Find icons folder & duplicate an icon component
- Change component name as well as the className inside
- Go to https://transform.tools/html-to-jsx paste on left column the svg code, copy the converted one from the right
- Delete width and height from the <svg> and delete any fill: some-hex from the elements inside svg as <g> or <polyline> and stuff
- Add to enums.ts inside that folder the name of the icon
- Go to index.tsx and add the icon in switch case statement        
`}
      </Code>
      <StorybookComponentShowcase
        flexWrap={"flex-wrap"}
        flexDirection={"flex-row"}
        sourceCode={`<TailwindIcon name={CustomIconName["arrows-pointing-center"]} />
<TailwindIcon name={CustomIconName["add-friend"]} />    

<TailwindIcon name={CustomIconName["alcohol-glass"]} />  

<TailwindIcon name={CustomIconName["anatomy"]} />   

<TailwindIcon name={CustomIconName["chat"]} />       

<TailwindIcon name={CustomIconName["chat-bubble"]} />    

<TailwindIcon name={CustomIconName["comments"]} /> 

<TailwindIcon name={CustomIconName["dashboard"]} />   

<TailwindIcon name={CustomIconName["double-hearts"]} />   

<TailwindIcon name={CustomIconName["emoji"]} />  

<TailwindIcon name={CustomIconName["eye"]} />  

<TailwindIcon name={CustomIconName["filter-narrow-down"]} />       

<TailwindIcon name={CustomIconName["fire-and-pepper"]} />   

<TailwindIcon name={CustomIconName["flag"]} />     

<TailwindIcon name={CustomIconName["flirt"]} />  

<TailwindIcon name={CustomIconName["friends"]} />    

<TailwindIcon name={CustomIconName["gift"]} />     

<TailwindIcon name={CustomIconName["gold-crown"]} />     

<TailwindIcon name={CustomIconName["horizontal-dots"]} />    

<TailwindIcon name={CustomIconName["fire"]} />    

<TailwindIcon name={CustomIconName["medal"]} />  

<TailwindIcon name={CustomIconName["pin"]} />    

<TailwindIcon name={CustomIconName["points"]} />    

<TailwindIcon name={CustomIconName["profile-silhouette"]} />  

<TailwindIcon name={CustomIconName["scroll-top"]} /> 

<TailwindIcon name={CustomIconName["search"]} /> 

<TailwindIcon name={CustomIconName["star"]} />   

<TailwindIcon name={CustomIconName["token"]} />   

<TailwindIcon name={CustomIconName["verified"]} />     

<TailwindIcon name={CustomIconName["vertical-dots"]} />   

<TailwindIcon name={CustomIconName["video-camera"]} /> 

<TailwindIcon name={CustomIconName["clock"]} />   

<TailwindIcon name={CustomIconName["tips"]} />   

<TailwindIcon name={CustomIconName["winking-emoji"]} />  

<TailwindIcon name={CustomIconName["person-holding-hands-up"]} /> 

<TailwindIcon name={CustomIconName["two-people-icon"]} />  

<TailwindIcon name={CustomIconName["three-people-icon"]} />  

<TailwindIcon name={CustomIconName["show-type"]} />    

<TailwindIcon name={CustomIconName["dice"]} />        

<TailwindIcon name={CustomIconName["info-circle"]} />     

<TailwindIcon name={CustomIconName["question-mark"]} />  

<TailwindIcon name={CustomIconName["cancel-circle"]} />  

<TailwindIcon name={CustomIconName["buzzmode"]} />    

<TailwindIcon name={CustomIconName["optimization"]} />  

<TailwindIcon name={CustomIconName["person"]} />       

<TailwindIcon name={CustomIconName["private-message"]} />     

<TailwindIcon name={CustomIconName["invite-to-private"]} />  

<TailwindIcon name={CustomIconName["ban"]} />     

<TailwindIcon name={CustomIconName["notes"]} />   

<TailwindIcon name={CustomIconName["kick"]} />  

<TailwindIcon name={CustomIconName["spinner"]} textColor={"text-red-500"} />    

<TailwindIcon name={CustomIconName["masonry-layout"]} />    

<TailwindIcon name={CustomIconName["column-layout"]} />        
`}
      >
        <TailwindIcon name={CustomIconName["arrows-pointing-center"]} />
        <br />
        <TailwindIcon name={CustomIconName["add-friend"]} />
        <br />
        <TailwindIcon name={CustomIconName["alcohol-glass"]} />
        <br />
        <TailwindIcon name={CustomIconName["anatomy"]} />
        <br />
        <TailwindIcon name={CustomIconName["chat"]} />
        <br />
        <TailwindIcon name={CustomIconName["chat-bubble"]} />
        <br />
        <TailwindIcon name={CustomIconName["comments"]} />
        <br />
        <TailwindIcon name={CustomIconName["dashboard"]} />
        <br />
        <TailwindIcon name={CustomIconName["double-hearts"]} />
        <br />
        <TailwindIcon name={CustomIconName["emoji"]} />
        <br />
        <TailwindIcon name={CustomIconName["eye"]} />
        <br />
        <TailwindIcon name={CustomIconName["filter-narrow-down"]} />
        <br />
        <TailwindIcon name={CustomIconName["fire-and-pepper"]} />
        <br />
        <TailwindIcon name={CustomIconName["flag"]} />
        <br />
        <TailwindIcon name={CustomIconName["flirt"]} />
        <br />
        <TailwindIcon name={CustomIconName["friends"]} />
        <br />
        <TailwindIcon name={CustomIconName["gift"]} />
        <br />
        <TailwindIcon name={CustomIconName["gold-crown"]} />
        <br />
        <TailwindIcon name={CustomIconName["horizontal-dots"]} />
        <br />
        <TailwindIcon name={CustomIconName["fire"]} />
        <br />
        <TailwindIcon name={CustomIconName["medal"]} />
        <br />
        <TailwindIcon name={CustomIconName["pin"]} />
        <br />
        <TailwindIcon name={CustomIconName["points"]} />
        <br />
        <TailwindIcon name={CustomIconName["profile-silhouette"]} />
        <br />
        <TailwindIcon name={CustomIconName["scroll-top"]} />
        <br />
        <TailwindIcon name={CustomIconName["search"]} />
        <br />
        <TailwindIcon name={CustomIconName["star"]} />
        <br />
        <TailwindIcon name={CustomIconName["token"]} />
        <br />
        <TailwindIcon name={CustomIconName["verified"]} />
        <br />
        <TailwindIcon name={CustomIconName["vertical-dots"]} />
        <br />
        <TailwindIcon name={CustomIconName["video-camera"]} />
        <br />
        <TailwindIcon name={CustomIconName["clock"]} />
        <br />
        <TailwindIcon name={CustomIconName["tips"]} />
        <br />
        <TailwindIcon name={CustomIconName["winking-emoji"]} />
        <br />
        <TailwindIcon name={CustomIconName["person-holding-hands-up"]} />
        <br />
        <TailwindIcon name={CustomIconName["two-people-icon"]} />
        <br />
        <TailwindIcon name={CustomIconName["three-people-icon"]} />
        <br />
        <TailwindIcon name={CustomIconName["show-type"]} />
        <br />
        <TailwindIcon name={CustomIconName["dice"]} />
        <br />
        <TailwindIcon name={CustomIconName["info-circle"]} />
        <br />
        <TailwindIcon name={CustomIconName["question-mark"]} />
        <br />
        <TailwindIcon name={CustomIconName["cancel-circle"]} />
        <br />
        <TailwindIcon name={CustomIconName["buzzmode"]} />
        <br />
        <TailwindIcon name={CustomIconName["optimization"]} />
        <br />
        <TailwindIcon name={CustomIconName["person"]} />
        <br />
        <TailwindIcon name={CustomIconName["private-message"]} />
        <br />
        <TailwindIcon name={CustomIconName["invite-to-private"]} />
        <br />
        <TailwindIcon name={CustomIconName["ban"]} />
        <br />
        <TailwindIcon name={CustomIconName["notes"]} />
        <br />
        <TailwindIcon name={CustomIconName["kick"]} />
        <br />
        <TailwindIcon
          name={CustomIconName["spinner"]}
          textColor={"text-red-500"}
        />
        <br />
        <TailwindIcon name={CustomIconName["masonry-layout"]} />
        <br />
        <TailwindIcon name={CustomIconName["column-layout"]} />
      </StorybookComponentShowcase>
      <StorybookParagraph>Example of TailwindText props</StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindIcon name={CustomIconName["column-layout"]} textColor={"text-red-500"} />
<TailwindIcon
  name={CustomIconName["column-layout"]}
  fontSize={"text-3xl"}
/>
<TailwindIcon
  name={CustomIconName["column-layout"]}
  fontSize={"text-3xl"}
  margin={["mt-4"]}
/>
`}
      >
        <TailwindIcon
          name={CustomIconName["column-layout"]}
          textColor={"text-red-500"}
        />
        <br />
        <TailwindIcon
          name={CustomIconName["column-layout"]}
          fontSize={"text-3xl"}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindIcon
  name={CustomIconName["column-layout"]}
  fontSize={"text-3xl"}
  margin={["mt-4"]}
/>`}
      >
        <TailwindIcon
          name={CustomIconName["column-layout"]}
          fontSize={"text-3xl"}
          margin={["mt-4"]}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindIcon
  name={CustomIconName["column-layout"]}
  fontSize={"text-3xl"}
  margin={["mt-4"]}
  position={"absolute"}
  inset={["bottom-0", "right-20"]}
/>
`}
      >
        <TailwindBox position={"relative"} height={"h-20"}>
          <TailwindIcon
            name={CustomIconName["column-layout"]}
            fontSize={"text-3xl"}
            margin={["mt-4"]}
            position={"absolute"}
            inset={["bottom-0", "right-20"]}
          />
        </TailwindBox>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsIcon;
