import React from "react";
import { Route, Switch } from "react-router-dom";
import StorybookIntroduction from "../introduction";
import StorybookSetup from "../setup";
import StorybookConventions from "../conventions";
import StorybookArchitecture from "../architecture";
import StorybookFlow from "../flow";
import TailwindFlex from "../../components/_tailwind/flex";
import StorybookComponents from "../components";
import StorybookTypesAndConstants from "../types-and-constants";

type Props = {};

const StorybookBody: React.ComponentType<Props> = ({}) => {
  return (
    <TailwindFlex
      width={"w-10/12"}
      padding={["py-4", "pl-4"]}
      margin={["mr-2"]}
      overflow={["overflow-hidden"]}
    >
      <Switch>
        <Route exact path={"/"}>
          <StorybookIntroduction />
        </Route>
        <Route path={"/setup"}>
          <StorybookSetup />
        </Route>
        <Route path={"/conventions"}>
          <StorybookConventions />
        </Route>
        <Route path={"/architecture"}>
          <StorybookArchitecture />
        </Route>
        <Route path={"/flow"}>
          <StorybookFlow />
        </Route>
        <Route path={"/components"}>
          <StorybookComponents />
        </Route>
        <Route path={"/types-and-constants"}>
          <StorybookTypesAndConstants />
        </Route>
      </Switch>
    </TailwindFlex>
  );
};

export default StorybookBody;
