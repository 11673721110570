import React, { useCallback, useMemo, useState } from "react";
import TailwindFlex, {
  TailwindFlexProps,
} from "../../components/_tailwind/flex";
import TailwindButton from "../../components/_tailwind/button";
import Code from "../../components/_tailwind/code";

type Props = TailwindFlexProps & {
  sourceCode: string;
};

const StorybookComponentShowcase: React.ComponentType<Props> = ({
  children,
  sourceCode,
  ...props
}) => {
  const [isShowingSourceCode, setIsShowingSourceCode] = useState<boolean>(
    false
  );

  const _children = useMemo(() => {
    return isShowingSourceCode ? (
      <Code disableMarginsAndPaddings>{sourceCode}</Code>
    ) : (
      children
    );
  }, [isShowingSourceCode, sourceCode, children]);

  const onClickShowSource = useCallback(() => {
    setIsShowingSourceCode(!isShowingSourceCode);
  }, [isShowingSourceCode]);

  return (
    <TailwindFlex
      flexDirection={"flex-col"}
      padding={["p-2"]}
      margin={["my-8"]}
      overflow={["overflow-visible"]}
      position={"relative"}
      backgroundColor={"bg-gray-700"}
      textColor={"text-white"}
      {...props}
    >
      <TailwindButton
        fullWidth={false}
        rounded={false}
        position={"absolute"}
        inset={["-top-10", "right-0"]}
        onClick={onClickShowSource}
        textColor={"text-white"}
        backgroundColor={"bg-gray-700"}
        padding={["p-2"]}
      >
        {isShowingSourceCode ? "Show component" : "Show source"}
      </TailwindButton>
      {_children}
    </TailwindFlex>
  );
};

export default StorybookComponentShowcase;
