import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import Icon from "@material-ui/core/Icon";
import ModelStore from "../stores/models/ModelStore";
import { inject, observer } from "mobx-react";

type Props = {
  modelStore?: ModelStore;
};

const ModelsTableHead: React.ComponentType<Props> = ({ modelStore }) => {
  const { sortModels, sortKey, sortDirection } = modelStore!;

  const orderModels = (value: string) => {
    sortModels(value);
  };

  return (
    <TailwindFlex
      pseudoClasses={["xs:p-3", "xs:pl-5", "xs:pr-5"]}
      padding={["p-4", "pl-10", "pr-10"]}
      backgroundColor={"bg-table-primary-bg-color"}
      textColor='text-main-color'
      alignItems={"items-center"}>
      <TailwindFlex
        width={"w-3/12"}
        alignItems={"items-center"}
        justifyContent={"justify-start"}
        onClick={() => orderModels("username")}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          descriptor={{
            id: "studio-model-management.model-list.table-headings.username",
            defaultMessage: "Model",
          }}
        />
        {sortKey == "username" && (
          <Icon>
            {sortDirection === "asc"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"}
          </Icon>
        )}
      </TailwindFlex>
      <TailwindFlex
        width={"w-2/12"}
        justifyContent={"justify-start"}
        onClick={() => orderModels("model_full_name")}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          descriptor={{
            id: "studio-model-management.model-list.table-headings.full-name",
            defaultMessage: "Full Name",
          }}
        />
        {sortKey == "model_full_name" && (
          <Icon>
            {sortDirection === "asc"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"}
          </Icon>
        )}
      </TailwindFlex>
      <TailwindFlex
        width={"w-2/12"}
        justifyContent={"justify-end"}
        onClick={() => orderModels("modified_at")}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          whiteSpace={"whitespace-nowrap"}
          descriptor={{
            id: "studio-model-management.model-list.table-headings.last-login",
            defaultMessage: "Last Login",
          }}
        />
        {sortKey == "modified_at" && (
          <Icon>
            {sortDirection === "asc"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"}
          </Icon>
        )}
      </TailwindFlex>
      <TailwindFlex
        width={"w-2/12"}
        justifyContent={"justify-end"}
        onClick={() => orderModels("registered_at")}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          descriptor={{
            id: "studio-model-management.model-list.table-headings.registered",
            defaultMessage: "Registered",
          }}
        />
        {sortKey == "registered_at" && (
          <Icon>
            {sortDirection === "asc"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"}
          </Icon>
        )}
      </TailwindFlex>
      <TailwindFlex
        width={"w-2/12"}
        pseudoClasses={["xs:w-1/12"]}
        justifyContent={"justify-end"}
        onClick={() => orderModels("status")}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          descriptor={{
            id: "studio-model-management.model-list.table-headings.status",
            defaultMessage: "Status",
          }}
        />
        {sortKey == "status" && (
          <Icon>
            {sortDirection === "asc"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"}
          </Icon>
        )}
      </TailwindFlex>
      <TailwindFlex
        width={"w-2/12"}
        pseudoClasses={["xs:w-1/12"]}
        justifyContent={"justify-end"}
        onClick={() => orderModels("tokens")}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          descriptor={{
            id: "studio-model-management.model-list.table-headings.tokens",
            defaultMessage: "Tokens",
          }}
        />
        {sortKey == "tokens" && (
          <Icon>
            {sortDirection === "asc"
              ? "keyboard_arrow_down"
              : "keyboard_arrow_up"}
          </Icon>
        )}
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default inject("modelStore")(observer(ModelsTableHead));
