import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";

type Props = {};

const MemberTokenActivityTableHead: React.ComponentType<Props> = ({}) => {
  return (
    <TailwindFlex
      padding={["p-4", "pl-5", "pr-5"]}
      borderRadius={"rounded-t-xl"}
      backgroundColor={"bg-table-primary-bg-color"}>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-start"}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          padding={["pl-1", "pr-1"]}
          descriptor={{
            id: "modelTokenActivityTable.customer",
            defaultMessage: "Customer",
          }}
        />
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-start"}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          whiteSpace={"whitespace-nowrap"}
          descriptor={{
            id: "modelTokenActivityTable.website",
            defaultMessage: "Website",
          }}
        />
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-start"}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          descriptor={{
            id: "modelTokenActivityTable.productTitle",
            defaultMessage: "Product",
          }}
        />
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-start"}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          descriptor={{
            id: "modelTokenActivityTable.date",
            defaultMessage: "Date",
          }}
        />
      </TailwindFlex>
      <TailwindFlex width={"w-1/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          descriptor={{
            id: "modelTokenActivityTable.memberSpend",
            defaultMessage: "Member Spend",
          }}
        />
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          descriptor={{
            id: "modelTokenActivityTable.percentagePayout",
            defaultMessage: "Percentage Payout",
          }}
        />
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedText
          fontWeight='font-bold'
          whiteSpace={"whitespace-nowrap"}
          descriptor={{
            id: "modelTokenActivityTable.earnings",
            defaultMessage: "Earnings",
          }}
        />
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default MemberTokenActivityTableHead;
