import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import Code from "../../components/_tailwind/code";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import TailwindAnchor from "../../components/_tailwind/anchor";
import StorybookSubHeading from "../_internal/sub-heading";

type Props = {};

const StorybookSetupCode: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Setup &gt; Code</StorybookHeading>
      <StorybookParagraph>Clone the repo and checkout to develop branch</StorybookParagraph>
      <Code>{`git clone https://gitlab.com/cams2/cams-frontend-models-react`}</Code>
      <StorybookParagraph>Copy env file from sample</StorybookParagraph>
      <Code>{`cp .env.sample .env`}</Code>
      <StorybookParagraph>
        Request <Code inline>REACT_APP_GOOGLE_CAPTCHA_SITE_KEY</Code> from lead/mentor
      </StorybookParagraph>
      <StorybookParagraph>
        Add the following host entries to your hosts file so that you can access different dashboards simultaneously
        without needing to specify
        <Code inline>REACT_APP_USER_ROLE</Code> (studio, model, aff, passion are accepted values)
      </StorybookParagraph>
      <StorybookParagraph>
        If in any case aff.local kind of URLs are hitting issues like CORS, add <Code inline>REACT_APP_USER_ROLE</Code>{" "}
        to your env var and start using the app from localhost instead of the proxy urls below.{" "}
      </StorybookParagraph>
      <Code>
        {`127.0.0.1 aff.local
127.0.0.1 passion.local
127.0.0.1 models.local
127.0.0.1 studios.local
`}
      </Code>
      <Code>
        {`yarn - npm i --global yarn
yarn install
git submodule update --init --recursive //for the first time setup. after code is setup do yarn pull
yarn start //run the app OR 
yarn build //rarely needed
`}
      </Code>
      <StorybookParagraph>
        After you setup the code via commands above, you can pull the code on your current branch along with the
        submodule via <Code inline>yarn pull</Code> command
      </StorybookParagraph>
      <StorybookParagraph>
        You can request a user from your manager to log in or register your own user (will need approval from Admin to
        access certain pages depending on the project)
      </StorybookParagraph>
      <StorybookParagraph>
        Direct your browser to{" "}
        <TailwindAnchor fullWidth={false} to={"http://models.local:3000/"}>
          http://models.local:3000/
        </TailwindAnchor>
      </StorybookParagraph>
      <StorybookSubHeading>Extra commands</StorybookSubHeading>
      <Code>
        {`yarn tsx // check TS errors, rarely needed
yarn lint:fix // lint your files
yarn test // test your work
yarn prettier // DO NOT run this without checking with the lead
`}
      </Code>
    </StorybookWrapper>
  );
};

export default StorybookSetupCode;
