import React from "react";
import "./styles.scss";
import classnames from "classnames";

type SharedMediaIconAltProps = {
  className?: string;
};

const SharedMediaIconAlt: React.ComponentType<SharedMediaIconAltProps> = ({
  className,
}) => (
  <span className={classnames("SharedMediaIconAlt", className && className)}>
    <svg 
      version="1.2" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlnsXlink="http://www.w3.org/1999/xlink" 
      overflow="visible" 
      preserveAspectRatio="none" 
      viewBox="0 0 17 17">
        <g transform="translate(0, 0)">
          <g transform="translate(0, 0) rotate(0)">
            <path 
              d="M8.5,0c-4.675,0 -8.5,3.825 -8.5,8.5c0,4.675 3.825,8.5 8.5,8.5c4.675,0 8.5,-3.825 8.5,-8.5c0,-4.675 -3.825,-8.5 -8.5,-8.5zM8.5,15.9375c-4.14375,0 -7.4375,-3.29375 -7.4375,-7.4375c0,-4.14375 3.29375,-7.4375 7.4375,-7.4375c4.14375,0 7.4375,3.29375 7.4375,7.4375c0,4.14375 -3.29375,7.4375 -7.4375,7.4375zM8.97812,5.36563c0.6375,0 1.16875,0.6375 1.16875,1.38125c0,0.2125 0.15937,0.37188 0.31875,0.37188c0.15938,0 0.31875,-0.15937 0.31875,-0.37188c0,-1.16875 -0.79687,-2.125 -1.75313,-2.125h-0.15938v-1.00937c0,-0.2125 -0.15938,-0.37188 -0.31875,-0.37188c-0.15938,0 -0.31875,0.15937 -0.31875,0.37188v1.00937h-0.10625c-1.00938,0 -1.80625,0.95625 -1.80625,2.125v0.05313c0,1.4875 1.11563,2.01875 1.7,2.07187h0.2125v2.81562c-0.26562,0 -0.58438,-0.10625 -0.79687,-0.26562c-0.31875,-0.2125 -0.53125,-0.69062 -0.53125,-1.11562c0,-0.2125 -0.15938,-0.37188 -0.31875,-0.37188c-0.15937,0 -0.31875,0.15937 -0.31875,0.37188c0,0.69062 0.31875,1.38125 0.79688,1.75312c0.31875,0.26563 0.74375,0.37188 1.11562,0.37188v0.95625c0,0.2125 0.15938,0.37188 0.31875,0.37188c0.15938,0 0.31875,-0.15937 0.31875,-0.37188v-0.95625h0.15937c0.95625,0 1.7,-0.85 1.75313,-1.96562c0,-0.05313 0,-0.10625 0,-0.15937c0,-0.58438 -0.2125,-1.11563 -0.53125,-1.54063c-0.31875,-0.37188 -0.74375,-0.58437 -1.22188,-0.58437h-0.15937v-2.86875zM8.97812,8.925c0.31875,0 0.58438,0.15937 0.85,0.37188c0.2125,0.26563 0.37187,0.6375 0.37187,1.00937c0,0.05313 0,0.05313 0,0.10625c-0.10625,0.74375 -0.6375,1.32813 -1.22188,1.32813h-0.15937v-2.81563zM8.18125,8.12812h-0.15937c-0.05313,0 -1.16875,-0.10625 -1.16875,-1.32812v-0.05313c0,-0.79687 0.58438,-1.43437 1.22188,-1.43437h0.10625z" 
              vectorEffect="non-scaling-stroke"/>
          </g>
          <defs>
            <path
              id="path-1715217230551107755" 
              d="M8.5,0c-4.675,0 -8.5,3.825 -8.5,8.5c0,4.675 3.825,8.5 8.5,8.5c4.675,0 8.5,-3.825 8.5,-8.5c0,-4.675 -3.825,-8.5 -8.5,-8.5zM8.5,15.9375c-4.14375,0 -7.4375,-3.29375 -7.4375,-7.4375c0,-4.14375 3.29375,-7.4375 7.4375,-7.4375c4.14375,0 7.4375,3.29375 7.4375,7.4375c0,4.14375 -3.29375,7.4375 -7.4375,7.4375zM8.97812,5.36563c0.6375,0 1.16875,0.6375 1.16875,1.38125c0,0.2125 0.15937,0.37188 0.31875,0.37188c0.15938,0 0.31875,-0.15937 0.31875,-0.37188c0,-1.16875 -0.79687,-2.125 -1.75313,-2.125h-0.15938v-1.00937c0,-0.2125 -0.15938,-0.37188 -0.31875,-0.37188c-0.15938,0 -0.31875,0.15937 -0.31875,0.37188v1.00937h-0.10625c-1.00938,0 -1.80625,0.95625 -1.80625,2.125v0.05313c0,1.4875 1.11563,2.01875 1.7,2.07187h0.2125v2.81562c-0.26562,0 -0.58438,-0.10625 -0.79687,-0.26562c-0.31875,-0.2125 -0.53125,-0.69062 -0.53125,-1.11562c0,-0.2125 -0.15938,-0.37188 -0.31875,-0.37188c-0.15937,0 -0.31875,0.15937 -0.31875,0.37188c0,0.69062 0.31875,1.38125 0.79688,1.75312c0.31875,0.26563 0.74375,0.37188 1.11562,0.37188v0.95625c0,0.2125 0.15938,0.37188 0.31875,0.37188c0.15938,0 0.31875,-0.15937 0.31875,-0.37188v-0.95625h0.15937c0.95625,0 1.7,-0.85 1.75313,-1.96562c0,-0.05313 0,-0.10625 0,-0.15937c0,-0.58438 -0.2125,-1.11563 -0.53125,-1.54063c-0.31875,-0.37188 -0.74375,-0.58437 -1.22188,-0.58437h-0.15937v-2.86875zM8.97812,8.925c0.31875,0 0.58438,0.15937 0.85,0.37188c0.2125,0.26563 0.37187,0.6375 0.37187,1.00937c0,0.05313 0,0.05313 0,0.10625c-0.10625,0.74375 -0.6375,1.32813 -1.22188,1.32813h-0.15937v-2.81563zM8.18125,8.12812h-0.15937c-0.05313,0 -1.16875,-0.10625 -1.16875,-1.32812v-0.05313c0,-0.79687 0.58438,-1.43437 1.22188,-1.43437h0.10625z" 
              vectorEffect="non-scaling-stroke"/>
          </defs>
        </g>
  </svg>
  </span>
);

export default SharedMediaIconAlt;
