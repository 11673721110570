import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import Image from "../../components/image";

type Props = {};

const StorybookComponentsImage: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Image</StorybookHeading>
      <StorybookParagraph>
        Image component is a component that uses <Code inline>react-image</Code>{" "}
        package to handle lazy-loading of images as well as showing some kind of
        loading component while that component loads. It has been a little
        opinionated to for ease of use for{" "}
        <Code inline>src and fallbackSrc</Code> so that we don't need to worry
        about the underlying implementation of react-image. Here are the
        accepted props:
      </StorybookParagraph>
      <Code>
        {`brokenPlaceholderChildren?: ReactNode;
showFallback?: boolean;
showUnloader?: boolean;
showLoader?: boolean;
`}
      </Code>
      <StorybookParagraph>Here are some examples</StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<Image src={"https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb01-1b1f87ed74cf90fd190f20abb17c5414-9211b9.jpg"} />`}>
        <StorybookParagraph>Image that loads</StorybookParagraph>
        <Image
          src={
            "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb01-1b1f87ed74cf90fd190f20abb17c5414-9211b9.jpg"
          }
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<Image
  src={
    "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb01-1b1f87ed74cf90fd190f20abb17c5414-92afs.jpg"
  }
  fallbackSrc={
    "https://image.shutterstock.com/image-vector/ui-image-placeholder-wireframes-apps-260nw-1037719204.jpg"
  }
/>
`}>
        <StorybookParagraph>
          Image that fails to load and fallsback to fallbackSrc
        </StorybookParagraph>
        <Image
          src={
            "https://s3.amazonaws.com/upload.uxpin/files/168180/173507/Male_thumb01-1b1f87ed74cf90fd190f20abb17c5414-92afs.jpg"
          }
          fallbacksrc={
            "https://image.shutterstock.com/image-vector/ui-image-placeholder-wireframes-apps-260nw-1037719204.jpg"
          }
        />
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsImage;
