import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import TailwindAnchor from "../../components/_tailwind/anchor";

type Props = {};

const StorybookArchitectureTheming: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Architecture &gt; Theming</StorybookHeading>
      <StorybookParagraph>
        Theming is a store inside core/stores/Themingstore folder that manages
        the theming of the site
      </StorybookParagraph>
      <StorybookParagraph>
        ThemeStore applies classes to the body in case a very custom CSS is
        needed that is very nested but it will never be needed. Please do not
        write CSS with the class applied to the body.
      </StorybookParagraph>
      <StorybookParagraph>
        ThemeStore folder has some variables inside JS in cams, aff & passion.ts
        files for both dark & light theme. These variables are collected by
        ThemeStore then added to the <Code inline>{`<head>`}</Code> section of
        the app. The variables are the same as in those TS files and can be
        referenced inside CSS via{" "}
        <Code inline>{`color: var(--title-color-1);`}</Code> for example but the
        use of CSS is strictly discouraged as we have the ability to create
        utility TailwindCSS classes via{" "}
        <Code inline>{`src/tailwind.config.js`}</Code> file that has the
        following:
      </StorybookParagraph>
      <Code>
        {`extend: {
  colors: {
    "title-color-1": "var(--title-color-1)",
    "primary-bg-color": "var(--primary-bg-color)",
    "secondary-bg-color": "var(--secondary-bg-color)",
  },
}
        `}
      </Code>
      <StorybookParagraph>
        Above snippet inside the config file creates the following classes:
      </StorybookParagraph>
      <Code>
        {`bg-primary-bg-color
text-primary-bg-color
and so forth.        
`}
      </Code>
      <StorybookParagraph>
        The use of these classes is also prohibited as explained in the{" "}
        <TailwindAnchor to={"/architecture/tailwindcss"}>
          Tailwind section of Architecture
        </TailwindAnchor>{" "}
        the names are not so descriptive and we don't know what primary-color-1
        is (there are 1,2,3,4,5,6...) and it takes a while to go to the :root
        and check what the colors are or jump back and forth between theme files
        in ThemeStore folder to know about a color.
      </StorybookParagraph>
      <StorybookParagraph>
        The idea with Theming is that all shared components will have ThemeStore
        injected to them that will have the access to the theme related
        variables in which we can just use TailwindCSS classes as variable
        values in a way:
      </StorybookParagraph>
      <Code>
        {`public currentThemePalette: ThemeColorPalette //ThemeStore
type Props = {
  themeStore?: ThemeStore
}

const SomeComponent: React.FC<Props> = ({themeStore}) => {
  const {currentThemePalette} = themeStore;
  
  return <TailwindBox backgroundColor={currentThemePalette.someComponent.backgroundColor}>some children</TailwindBox>
}
`}
      </Code>
      <StorybookParagraph>
        With the new setup, we will be creating per component theming, we are
        not there yet though. There are already some examples of variables as
        such inside <Code inline>ThemeStore/types.ts</Code>:
      </StorybookParagraph>
      <Code>
        {` "card-primary-bg-color": string;
"card-secondary-bg-color": string;
        
"sidebar-width": string;
"sidebar-border-right-color": string;
"sidebar-bg-color": string;
"sidebar-text-color": string;
"sidebar-text-font-size": string;
"sidebar-text-active-hover-color": string;
"sidebar-border-radius": string;

"header-bg-color": string;
"header-text-color": string;
"header-height": string;

"logo-color": string;

"body-padding": string;
"body-bg-color": string;

"tab-active-text-color": string;
"tab-active-tab-line-color": string;
"tabs-bottom-border-color": string;

"feed-card-bg-color": string;
"feed-card-comment-bg-color": string;
"feed-card-comment-border-radius": string;

"snackbar-bg": string;
"snackbar-border": string;

"image-editor-sidebar-bg": string;
"image-editor-sidebar-header-bg": string;

"input-border-radius": string;
"form-elements-font-size": string;
`}
      </Code>
      <StorybookParagraph>
        Some of the color variables also require RGB, or opaque/dark versions
        therefore some of them also has variables that are referencing the
        original value like:
      </StorybookParagraph>
      <Code>
        {`"primary-bg-color": camsDarkPrimaryBgColor,
"primary-bg-color-darker": Color(camsDarkPrimaryBgColor).darken(0.1).hex(),
"primary-bg-color-rgb": Color(camsDarkPrimaryBgColor).rgb().array(),        
`}
      </Code>
      <StorybookParagraph>
        This will not be needed as we are moving towards a more consistent look
        of the apps as well as good color schema and less gradients. TailwindCSS
        already provides us gray-100 to gray-900 values that already have the
        darker values of those. If needed, we can extend tailwind.config.js to
        also include rgb values of the colors we have or implement it ourselves
        if it does not have it. Currently there is no need for it.
      </StorybookParagraph>
    </StorybookWrapper>
  );
};

export default StorybookArchitectureTheming;
