import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindInput from "../../components/_tailwind/input";
import { CustomIconName } from "../../components/_tailwind/icon/icons/enums";

type Props = {};

const StorybookComponentsInput: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Input</StorybookHeading>
      <StorybookParagraph>
        TailwindInput is a wrapper for TailwindInputBase that has some opinions
        to match the use case in our designs in terms of both design and label
        and error states. It takes all props from TailwindInputBase as well as
        the following (more to come to be added to props):
      </StorybookParagraph>
      <Code>
        {` className?: (string | undefined | boolean)[];
backgroundOpacity?: TBackgroundOpacity;
error?: string;
errorProps?: TailwindTextProps;
`}
      </Code>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindInput placeholder={"Underlined Input"} variant={"underlined"}/>`}
      >
        <TailwindInput
          placeholder={"Underlined Input"}
          variant={"underlined"}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindInput placeholder={"Some placeholder"} label={"Some label"} />`}
      >
        <TailwindInput placeholder={"Some placeholder"} label={"Some label"} />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindInput
  placeholder={"Some placeholder"}
  label={"Some label"}
  value={"Some value"}
/>
      `}
      >
        <TailwindInput
          placeholder={"Some placeholder"}
          label={"Some label"}
          value={"Some value"}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindInput
  placeholder={"Some placeholder"}
  label={"Some label"}
  value={"Some value"}
  error={"Some error"}
/>
      `}
      >
        <TailwindInput
          placeholder={"Some placeholder"}
          label={"Some label"}
          value={"Some value"}
          error={"Some error"}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindInput
  placeholder={"Some placeholder"}
  label={"Some label"}
  value={"Some value"}
  error={"Some error"}
/>
      `}
      >
        <TailwindInput
          placeholder={"Some placeholder"}
          label={"Some label"}
          value={"Some value"}
          error={"Some error"}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindInput
  placeholder={"Some placeholder"}
  label={"Some label"}
  value={"Some value"}
  error={"Orange Error"}
  errorProps={{
    textColor: "text-yellow-500",
  }}
/>
      `}
      >
        <TailwindInput
          placeholder={"Some placeholder"}
          label={"Some label"}
          value={"Some value"}
          error={"Orange Error"}
          errorProps={{
            textColor: "text-yellow-500",
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindInput
  placeholder={"Some placeholder"}
  label={"Some label"}
  value={"Some value"}
  error={"Some error"}
  leftIconProps={{
    name: CustomIconName.star
  }}
/>
      `}
      >
        <TailwindInput
          placeholder={"Some placeholder"}
          label={"Some label"}
          value={"Some value"}
          error={"Orange Error"}
          errorProps={{
            textColor: "text-yellow-500",
          }}
          leftIconProps={{
            name: CustomIconName.star,
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindInput
  placeholder={"Some placeholder"}
  label={"Some label"}
  value={"Some value"}
  error={"Some error"}
  leftIconProps={{
    name: CustomIconName.star
  }}
  rightIconProps={{
    name: CustomIconName.search
  }}
/>
      `}
      >
        <TailwindInput
          placeholder={"Some placeholder"}
          label={"Some label"}
          value={"Some value"}
          error={"Orange Error"}
          errorProps={{
            textColor: "text-yellow-500",
          }}
          leftIconProps={{
            name: CustomIconName.star,
          }}
          rightIconProps={{
            name: CustomIconName.search,
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindInput
  placeholder={"Some placeholder"}
  label={"Bigger Label"}
  labelProps={{
    fontSize: "text-4xl",
  }}
  value={"Some value"}
  error={"Orange Error"}
  errorProps={{
    textColor: "text-yellow-500",
  }}
  leftIconProps={{
    name: CustomIconName.star,
  }}
  rightIconProps={{
    name: CustomIconName.search,
  }}
/>
      `}
      >
        <TailwindInput
          placeholder={"Some placeholder"}
          label={"Bigger Label"}
          labelProps={{
            fontSize: "text-4xl",
          }}
          value={"Some value"}
          error={"Orange Error"}
          errorProps={{
            textColor: "text-yellow-500",
          }}
          leftIconProps={{
            name: CustomIconName.star,
          }}
          rightIconProps={{
            name: CustomIconName.search,
          }}
        />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        flexDirection={"flex-row"}
        sourceCode={`<TailwindInput
  placeholder={"Some placeholder"}
  label={"Auto Width"}
  flexProps={{
    width: "w-auto"
  }}
  leftIconProps={{
    name: CustomIconName.star,
  }}
  rightIconProps={{
    name: CustomIconName.search,
  }}
/>
      `}
      >
        <TailwindInput
          placeholder={"Some placeholder"}
          label={"Auto Width"}
          flexProps={{
            width: "w-auto",
          }}
          leftIconProps={{
            name: CustomIconName.star,
          }}
          rightIconProps={{
            name: CustomIconName.search,
          }}
        />
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsInput;
