import React from "react";
import TailwindHeading from "../components/_tailwind/heading";
import TailwindFlex from "../components/_tailwind/flex";
import TailwindParagraph from "../components/_tailwind/paragraph";

type Props = {};

const StorybookIntroduction: React.ComponentType<Props> = ({}) => {
  return (
    <TailwindFlex
      height={"h-full"}
      alignItems={"items-center"}
      justifyContent={"justify-center"}
    >
      <TailwindFlex
        width={"w-8/12"}
        flexDirection={"flex-col"}
        alignItems={"items-center"}
      >
        <TailwindHeading level={5} margin={["mb-8"]}>
          Welcome
        </TailwindHeading>
        <TailwindParagraph textAlign={"text-center"} margin={["mb-4"]}>
          The goal of this application is to give a walkthrough of the app and
          ease up onboarding of new developers as well as ensuring consistent
          development flow across teams.
        </TailwindParagraph>
        <TailwindParagraph textAlign={"text-center"}>
          This will be a company-wide documentation for all of the features used
          across multiple projects and the code will reside in this repository.
        </TailwindParagraph>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default StorybookIntroduction;
