import TailwindText from "library/components/_tailwind/text";
import React, { FunctionComponent } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";

type Props = {
  isVerificationIncomplete: boolean;
  openRegisterUploadModal: () => void;
  isApplicationPending: boolean;
  isVerificationPending: boolean;
  verifyIDStatusDone: boolean;
};

const VerifyIdStep: FunctionComponent<Props & WrappedComponentProps> = ({
  isVerificationIncomplete,
  openRegisterUploadModal,
  isApplicationPending,
  isVerificationPending,
  verifyIDStatusDone,
  intl,
}) => {
  return (
    <React.Fragment>
      <div className='w-6/12 mr-1'>
        <TailwindText textColor='text-main-color'>
          {intl.formatMessage({
            id: "landing.summaryStep.step2",
            defaultMessage: "Verify Your ID",
          })}
        </TailwindText>
      </div>
      <div className='w-6/12 mr-1'>
        {isVerificationIncomplete ? (
          <React.Fragment>
            <TailwindText textColor={"text-red-500"} fontWeight={"font-bold"}>
              {intl.formatMessage({
                id: "landing.summaryStep.incomplete",
                defaultMessage: "Incomplete",
              })}
            </TailwindText>
            <br />
            <TailwindText
              fontSize={"text-sm"}
              cursor={"cursor-pointer"}
              textColor={"text-blue-500"}
              textDecoration={"underline"}
              className={"hover:no-underline"}
              onClick={openRegisterUploadModal}>
              (
              {intl.formatMessage({
                id: "landing.summaryStep.clickToSubmit",
                defaultMessage: "Click to Submit",
              })}
              )
            </TailwindText>
          </React.Fragment>
        ) : isApplicationPending || isVerificationPending ? (
          <React.Fragment>
            <TailwindText
              textColor={"text-yellow-500"}
              fontWeight={"font-bold"}>
              {intl.formatMessage({
                id: "landing.summaryStep.pending",
                defaultMessage: "Pending",
              })}
            </TailwindText>
          </React.Fragment>
        ) : verifyIDStatusDone ? (
          <TailwindText textColor={"text-green-500"} fontWeight={"font-bold"}>
            {intl.formatMessage({
              id: "landing.summaryStep.done",
              defaultMessage: "Done",
            })}
          </TailwindText>
        ) : (
          <React.Fragment>
            <TailwindText fontWeight={"font-bold"} textColor={"text-red-500"}>
              {intl.formatMessage({
                id: "landing.summaryStep.denied",
                defaultMessage: "Denied",
              })}
            </TailwindText>{" "}
            <br />
            <TailwindText
              fontSize={"text-sm"}
              textColor={"text-blue-500"}
              textDecoration={"underline"}
              cursor={"cursor-pointer"}
              className={"hover:no-underline"}
              onClick={openRegisterUploadModal}>
              (
              {intl.formatMessage({
                id: "landing.summaryStep.clickToResubmit",
                defaultMessage: "Click to Resubmit",
              })}
              )
            </TailwindText>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};
export default injectIntl(VerifyIdStep);
