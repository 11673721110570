import React from "react";
import "./styles.scss";
import clsx from "clsx";

type BrokenIconProps = {
  className?: string;
};

const BrokenIcon: React.ComponentType<BrokenIconProps> = ({ className }) => (
  <span className={clsx("BrokenIcon", className && className)}>
    <svg
      version='1.2'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      overflow='visible'
      preserveAspectRatio='none'
      viewBox='0 0 53 62'>
      <g>
        <g style={{ transform: "scale(2)" }}>
          <path
            d='M20,1.18333c-0.38333,-0.36667 -0.83333,-0.65 -1.31667,-0.83333c-0.48333,-0.21667 -1,-0.33333 -1.53333,-0.35h-15.48333c-0.91667,0 -1.66667,0.75 -1.66667,1.66667v27.76667c0,0.91667 0.75,1.66667 1.66667,1.66667h23.33333c0.91667,0 1.66667,-0.75 1.66667,-1.66667v-20c-0.01667,-0.53333 -0.13333,-1.05 -0.35,-1.53333c-0.18333,-0.48333 -0.46667,-0.93333 -0.83333,-1.31667zM18.41667,2.75l5.43333,5.43333c0.18333,0.21667 0.31667,0.45 0.38333,0.71667h-6.45v-6.53333c0.25,0.08333 0.5,0.2 0.7,0.38333zM24.43333,28.88333h-22.2v-26.66667h13.33333v7.23333c0,0.91667 0.75,1.66667 1.66667,1.66667h7.21667v17.76667v0z'
            vectorEffect='non-scaling-stroke'
          />
        </g>
        <g
          style={{
            transform: "translateX(30%) translateY(45%)",
          }}>
          <polygon
            fillRule='evenodd'
            clipRule='evenodd'
            points='20,3 17,0 10,7 3,0 0,3 7,10 0,17 3,20 10,13 17,20 20,17 13,10 '
            vectorEffect='non-scaling-stroke'
          />
        </g>
      </g>
    </svg>
  </span>
);

export default BrokenIcon;
