import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";
import Code from "../../components/_tailwind/code";
import TailwindTranslatedNumber from "../../components/_tailwind/translated-number";
import TailwindAnchor from "../../components/_tailwind/anchor";

type Props = {};

const StorybookComponentsTranslatedDate: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; TranslatedNumber</StorybookHeading>
      <StorybookParagraph>
        TranslatedNumber component is a combination of primitive Text component
        as well as the react-intl. It makes it possible to format numbers
        according to user's computer localization. Some languages have symbols
        of the currency at the end for example; some have commas instead of dots
        as decimal point or decimal comma.
      </StorybookParagraph>
      <StorybookParagraph>
        Currently it takes all the props from TailwindText under{" "}
        <Code inline>textProps</Code> prop as well as the props from react-intl{" "}
        <TailwindAnchor
          to={"https://formatjs.io/docs/react-intl/components/#formattednumber"}
        >
          FormattedNumber component
        </TailwindAnchor>
      </StorybookParagraph>
      <StorybookParagraph>
        Here are some examples with the TailwindNumber component. As it is very
        flexible, you can create variants easily so not all combinations are
        showcased here.
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindTranslatedNumber
  value={100}
  style="currency"
  currency="USD"
/>
`}
      >
        <TailwindTranslatedNumber value={100} style="currency" currency="USD" />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindTranslatedNumber
  textProps={{
    textColor: "text-red-500"
  }}
  value={100}
  style="currency"
  currency="EUR"
/>
`}
      >
        <TailwindTranslatedNumber
          textProps={{
            textColor: "text-red-500",
          }}
          value={100}
          style="currency"
          currency="EUR"
        />
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsTranslatedDate;
