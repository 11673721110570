import React from "react";
import "./styles.scss";

type ReferalsIconProps = {
  className?: string;
};

const ReferalsIcon: React.ComponentType<ReferalsIconProps> = ({
  className,
}) => (
  <span className={`${className} camsIcon`}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
      <path d='M31.37,36.28A16.36,16.36,0,1,0,15,19.92,16.38,16.38,0,0,0,31.37,36.28Zm0-27.72A11.36,11.36,0,1,1,20,19.92,11.38,11.38,0,0,1,31.37,8.56Z' />
      <path d='M68.45,36.28A16.36,16.36,0,1,0,52.09,19.92,16.37,16.37,0,0,0,68.45,36.28Zm0-27.72A11.36,11.36,0,1,1,57.09,19.92,11.37,11.37,0,0,1,68.45,8.56Z' />
      <path d='M31.37,39A28.91,28.91,0,0,0,2.49,67.87V79.25a10,10,0,0,0,10,10H50.21a10,10,0,0,0,10-10V67.87A28.92,28.92,0,0,0,31.37,39ZM55.25,79.25a5,5,0,0,1-5,5H12.52a5,5,0,0,1-5-5V67.87a23.88,23.88,0,0,1,47.76,0Z' />
      <path d='M68.82,39c-5,0-9.08.59-13.83,3.53a2.5,2.5,0,1,0,2.63,4.25C61,44.69,63.81,44,68.82,44A23.9,23.9,0,0,1,92.7,67.87V79.25a5,5,0,0,1-5,5h-23a2.5,2.5,0,0,0,0,5h23a10,10,0,0,0,10-10V67.87A28.92,28.92,0,0,0,68.82,39Z' />
    </svg>
  </span>
);

export default ReferalsIcon;
