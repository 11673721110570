import React from "react";
import classnames from "classnames";

type Props = {
  className?: string;
};

const MicOutlineIcon: React.ComponentType<Props> = ({ className }) => {
  return (
    <span className={classnames("MicOutlineIcon", className && className)}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
        <path
          d='M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm-1.2-9.1c0-.66.54-1.2 1.2-1.2.66 0 1.2.54 1.2 1.2l-.01 6.2c0 .66-.53 1.2-1.19 1.2-.66 0-1.2-.54-1.2-1.2V4.9zm6.5 6.1c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z'
          vectorEffect='non-scaling-stroke'
        />
      </svg>
    </span>
  );
};

export default MicOutlineIcon;
