import React, { useState } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import MessageCenterUnavailableAttachment from "./messages-unavailable-attachment";

type Props = {
  imageURL: string;
  isSenderCurrentUser?: boolean;
};

const MessageCenterImage: React.ComponentType<Props> = ({
  imageURL,
  isSenderCurrentUser,
}) => {
  const [imageLoadError, setImageLoadError] = useState<boolean>(false);

  return (
    <TailwindFlex
      height='h-full'
      justifyContent='justify-center'
      alignItems='items-center'
      textColor={isSenderCurrentUser ? "text-white" : "text-main-color"}
      style={{
        backgroundColor: isSenderCurrentUser ? "#84a3ba" : "#fff",
      }}>
      {imageLoadError ? (
        <MessageCenterUnavailableAttachment />
      ) : (
        <img
          src={imageURL}
          onError={() => {
            setImageLoadError(true);
          }}
          style={{
            maxHeight: "85vh",
          }}
        />
      )}
    </TailwindFlex>
  );
};

export default MessageCenterImage;
