import { SharedMediaTabData } from "./types";

export const SharedMediaTabArray: Array<SharedMediaTabData> = [
   {
     name: "sold",
     icon: "sold-icon",
     icon2: "sold-icon-alt" 
   },
   {
     name: "for-sale",
     icon: "lock2",
     icon2: "lock-icon-alt" 
  },
  {
    name: "all",
    icon: "shared-media-icon",
    icon2: "shared-media-icon-alt" 
  },
];
