import React from "react";
import ReCAPTCHA, { ReCAPTCHAProps } from "react-google-recaptcha";
import "./styles.scss";
import TailwindTranslatedText from "../translated-text";
import TailwindFlex from "../flex";
import TailwindText from "../text";
import TailwindBox from "../box";
import { TMargin, TPadding } from "../../../styles/tailwind-classnames";

type Props = {
  captchaSiteKey: string;
  hideBadge?: boolean;
  margin?: (TMargin | undefined)[];
  padding?: (TPadding | undefined)[];
  onErrored: () => void;
  onExpired: () => void;
} & Omit<ReCAPTCHAProps, "sitekey" | "onChange">;

const RecaptchaV2 = React.forwardRef<ReCAPTCHA, Props>(
  (
    {
      captchaSiteKey,
      hideBadge = false,
      margin = [],
      padding = [],
      onErrored,
      onExpired,
    },
    ref
  ) => {
    return (
      <React.Fragment>
        {!hideBadge && (
          <TailwindFlex
            className={["Recaptcha"]}
            margin={[...margin]}
            padding={[...padding]}
            width={"w-full"}
            justifyContent={"justify-center"}
            alignContent={"content-center"}>
            <TailwindFlex
              className={["Recaptcha__wrapper"]}
              borderWidth={["border"]}
              borderColor={"border-main-color"}>
              <TailwindFlex
                className={["Recaptcha__text"]}
                flexDirection={"flex-col"}
                width={"w-3/4"}
                justifyContent={"justify-center"}
                padding={["p-2.5"]}>
                <TailwindBox
                  className={["Recaptcha__protected-by"]}
                  margin={["mb-2.5"]}>
                  <TailwindTranslatedText
                    descriptor={{
                      id: "recaptcha.protected-by",
                      defaultMessage: "protected by",
                    }}
                  />
                  <TailwindText>&nbsp;reCAPTCHA</TailwindText>
                </TailwindBox>
                <TailwindFlex className={["Recaptcha__links"]}>
                  <TailwindFlex margin={["mr-2.5"]} width={"w-auto"}>
                    <a
                      href='https://www.google.com/intl/en/policies/privacy/'
                      target={"_blank"}
                      rel='noreferrer'>
                      <TailwindTranslatedText
                        descriptor={{
                          id: "recaptcha.privacy-policy",
                          defaultMessage: "Privacy",
                        }}
                      />
                    </a>
                  </TailwindFlex>
                  <TailwindFlex>
                    <a
                      href='https://www.google.com/intl/en/policies/terms/'
                      target={"_blank"}
                      rel='noreferrer'>
                      <TailwindTranslatedText
                        descriptor={{
                          id: "recaptcha.terms-of-use",
                          defaultMessage: "Terms",
                        }}
                      />
                    </a>
                  </TailwindFlex>
                </TailwindFlex>
              </TailwindFlex>
              <TailwindFlex
                width={"w-1/4"}
                className={["Recaptcha__logo"]}
                backgroundColor={"bg-white"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}>
                <img
                  src='https://www.gstatic.com/recaptcha/api2/logo_48.png'
                  alt='reCAPTCHA Logo'
                />
              </TailwindFlex>
            </TailwindFlex>
          </TailwindFlex>
        )}
        <ReCAPTCHA
          ref={ref}
          sitekey={captchaSiteKey}
          size='invisible'
          onErrored={onErrored}
          onExpired={onExpired}
        />
      </React.Fragment>
    );
  }
);

export default RecaptchaV2;
