import React, { useCallback, useMemo } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import Avatar from "library/components/avatar";
import TailwindText from "library/components/_tailwind/text";
import MessageCenterNavigatorCheckMark from "./message-navigator-checkmark";
import { TBackgroundColor } from "tailwindcss-classnames";
import { TailwindBoxProps } from "library/components/_tailwind/box";

type Props = {
  name: string;
  custom_label?: string;
  backgroundColor: TBackgroundColor;
  setSelectedFilter: (id: string) => void;
  setDeselectfilter: (id: string) => void;
  filtersSelected: string[];
  filterType: string;
  isContactsLoading?: boolean;
  count: number;
  boxProps?: TailwindBoxProps;
  avatarBoxProps?: TailwindBoxProps;
  countBoxProps?: TailwindBoxProps;
};

const FilterContactList: React.ComponentType<Props> = ({
  name,
  custom_label,
  backgroundColor,
  setSelectedFilter,
  filtersSelected,
  setDeselectfilter,
  filterType,
  isContactsLoading,
  count,
  boxProps,
  avatarBoxProps,
  countBoxProps,
}) => {
  const isSelected = useMemo(
    () => filtersSelected.includes(filterType),
    [filtersSelected]
  );

  const onClickSelectHandler = useCallback(
    filterType => {
      if (isContactsLoading) return;
      !isSelected
        ? setSelectedFilter(filterType)
        : setDeselectfilter(filterType);
    },
    [filterType, isContactsLoading, isSelected]
  );

  return (
    <>
      <TailwindFlex
        padding={["py-2"]}
        flexDirection='flex-row'
        position={"relative"}
        justifyContent='justify-center'
        alignItems='items-center'
        onClick={() => onClickSelectHandler(filterType)}
        cursor={isContactsLoading ? "cursor-wait" : "cursor-pointer"}
        {...boxProps}>
        <TailwindFlex
          width={"w-auto"}
          justifyContent='justify-center'
          alignItems='items-center'
          flexDirection='flex-col'
          padding={["pr-2"]}
          {...avatarBoxProps}>
          <Avatar
            size={"small"}
            fontSize={"text-base"}
            username={name}
            customLabel={custom_label || ""}
            isTwoLettersInitial={name.split(" ").length > 1}
            backgroundColor={backgroundColor}
            className='avatar-customCSS'
            isSelected={isSelected}
            checkMarl={<MessageCenterNavigatorCheckMark />}
          />
        </TailwindFlex>
        <TailwindFlex>
          <TailwindText
            className={"text-center"}
            fontSize='text-sm'
            whiteSpace='whitespace-nowrap'
            textOverflow='overflow-ellipsis'>
            {name}
          </TailwindText>
        </TailwindFlex>
        <TailwindFlex
          width={"w-auto"}
          justifyContent='justify-center'
          alignItems='items-center'
          flexDirection='flex-col'
          {...countBoxProps}>{`(${count})`}</TailwindFlex>
      </TailwindFlex>
    </>
  );
};

export default FilterContactList;
