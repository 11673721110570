import React, { useCallback } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindSpinner from "library/components/_tailwind/spinner";
import { format } from "date-fns";
import AccountSettingsStore from "common/account-settings/stores/account-settings/AccountSettingsStore";
import TailwindButton from "library/components/_tailwind/button";
import { inject, observer } from "mobx-react";
import TailwindText from "library/components/_tailwind/text";
import TailwindBox from "library/components/_tailwind/box";
import TailwindCard from "library/components/_tailwind/card";
import BlockAddMember from "common/account-settings/model-block-unblock/add-member-block";
import ModalStore from "library/core/stores/modal";
import TailwindDivider from "library/components/_tailwind/divider";

type Props = {
  accountSettingsStore?: AccountSettingsStore;
  modalStore?: ModalStore;
};

const BlockedMembersTable: React.ComponentType<Props> = ({
  accountSettingsStore,
  modalStore,
}) => {
  const { isBlockMembersLoading, blockedMembers, unblockMember } =
    accountSettingsStore!;
  const { openSecondaryModal, closeSecondaryModal } = modalStore!;

  const onClickAddBlock = useCallback(() => {
    openSecondaryModal(
      <TailwindCard
        backgroundColor={"bg-primary-bg-color"}
        padding={["p-10"]}
        minWidth={"min-w-160"}>
        <BlockAddMember onBlockMemberSuccess={closeSecondaryModal} />
      </TailwindCard>
    );
  }, []);

  return (
    <React.Fragment>
      <TailwindFlex margin={["mb-2"]} justifyContent={"justify-between"}>
        <TailwindTranslatedText
          headingProps={{ level: 6 }}
          descriptor={{
            id: "accountSettings.blockUnblock.blockedMembers",
            defaultMessage: "Members",
          }}
        />
        <TailwindButton
          fullWidth={false}
          size={"sm"}
          backgroundColor={"bg-primary-bg-color"}
          onClick={onClickAddBlock}>
          Add Block
        </TailwindButton>
      </TailwindFlex>
      <TailwindBox margin={["mb-4"]}>
        {isBlockMembersLoading ? (
          <TailwindSpinner />
        ) : blockedMembers.length === 0 ? (
          <TailwindCard
            backgroundColor={"primary"}
            display={"flex"}
            alignItems={"items-center"}
            padding={["p-4"]}>
            <TailwindText display={"block"} textColor='text-main-color'>
              No members are blocked
            </TailwindText>
          </TailwindCard>
        ) : (
          <TailwindBox backgroundColor={"bg-primary-bg-color"}>
            <TailwindFlex
              backgroundColor={"bg-table-primary-bg-color"}
              padding={["p-3"]}
              height={"h-12"}
              textColor='text-main-color'
              justifyContent={"justify-center"}
              alignItems={"items-center"}>
              <TailwindBox width={"w-3/12"} padding={["p-2"]}>
                <TailwindTranslatedText
                  fontWeight={"font-bold"}
                  descriptor={{
                    id: "accountSettings.blockUnblock.memberName",
                    defaultMessage: "Member name",
                  }}
                />
              </TailwindBox>
              <TailwindBox width={"w-3/12"} padding={["p-2"]}>
                <TailwindTranslatedText
                  fontWeight={"font-bold"}
                  descriptor={{
                    id: "common.date",
                    defaultMessage: "Date",
                  }}
                />
              </TailwindBox>
              <TailwindBox width={"w-4/12"} padding={["p-2"]}>
                <TailwindTranslatedText
                  fontWeight={"font-bold"}
                  descriptor={{
                    id: "accountSettings.blockUnblock.note",
                    defaultMessage: "Note",
                  }}
                />
              </TailwindBox>
              <TailwindBox width={"w-2/12"} padding={["p-2"]} />
            </TailwindFlex>
            {blockedMembers.map((member, index) => (
              <React.Fragment key={`row${index + 1}`}>
                {index > 0 && (
                  <TailwindDivider
                    color='bg-table-primary-bg-color'
                    opacity='opacity-100'
                    style={{ height: "1px" }}
                  />
                )}
                <TailwindFlex
                  padding={["px-3"]}
                  alignItems={"items-center"}
                  textColor='text-main-color'>
                  <TailwindFlex width={"w-3/12"} padding={["p-2"]}>
                    {member.username}
                    {member.site ? ` - ${member.site}` : ""}
                  </TailwindFlex>
                  <TailwindFlex width={"w-3/12"} padding={["p-2"]}>
                    {format(new Date(member.created_at), "MMM d, yyyy")}
                  </TailwindFlex>
                  <TailwindFlex width={"w-4/12"} padding={["p-2"]}>
                    {member.reason}
                  </TailwindFlex>
                  <TailwindFlex
                    width={"w-2/12"}
                    padding={["p-2"]}
                    justifyContent={"justify-end"}>
                    <TailwindButton
                      backgroundColor={"bg-secondary-bg-color"}
                      rounded={false}
                      justifyContent={"justify-center"}
                      fullWidth={false}
                      width={"w-32"}
                      onClick={_ => {
                        unblockMember(member);
                      }}>
                      <TailwindTranslatedText
                        fontWeight={"font-bold"}
                        descriptor={{
                          id: "accountSettings.blockUnblock.unblock",
                          defaultMessage: "Unblock",
                        }}
                      />
                    </TailwindButton>
                  </TailwindFlex>
                </TailwindFlex>
              </React.Fragment>
            ))}
          </TailwindBox>
        )}
      </TailwindBox>
    </React.Fragment>
  );
};

export default inject(
  "accountSettingsStore",
  "modalStore"
)(observer(BlockedMembersTable));
