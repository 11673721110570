import React from "react";
import "./styles.scss";
import classnames from "classnames";

type HorizontalDotsIconProps = {
  className?: string;
};

const HorizontalDotsIcon: React.ComponentType<HorizontalDotsIconProps> = ({
  className,
}) => (
  <span className={classnames("HorizontalDotsIcon", className && className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 24 24"
    >
      <g>
        <path
          d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  </span>
);

export default HorizontalDotsIcon;
