import { makeAutoObservable } from "mobx";
import { logger } from "library/core/utility";
import { BroadcastStreamType } from "common/broadcast/_stores/broadcast-stream/enums";
import { BroadcastStreamState } from "common/broadcast/_stores/broadcast/enums";
import {
  authStore,
  broadcastStore,
  broadcastStreamStore,
  chatStore,
  pricingStore,
} from "core/stores";
import { BROADCASTER_OFFLINE_ERROR_MESSAGE_ID } from "common/broadcast/_stores/broadcast/consts";

const logPrefix = "[BroadcastStrategy]:";

export default class BroadcastStrategy {
  constructor() {
    makeAutoObservable(this);
  }

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  public connect = async () => {
    this.log("connect started");

    if (!authStore.logoutInProgress) {
      await broadcastStore.init();
      await broadcastStreamStore.init();
    }
    this.log("connect finished");
  };

  public disconnect = async (stopAction?: "logout" | "offline") => {
    broadcastStore.isCountUpTimerOn = false;
    this.log("disconnect started with stopAction", stopAction);
    //if model is streaming, we should stop
    if (
      [
        BroadcastStreamState.startingStream,
        BroadcastStreamState.started,
      ].includes(broadcastStore.streamState)
    ) {
      const stopMessage =
        stopAction === "offline"
          ? {
              id: BROADCASTER_OFFLINE_ERROR_MESSAGE_ID,
              defaultMessage:
                "You are now offline. Your will be able to start streaming once you are online.",
            }
          : undefined;

      const stopActionButton =
        stopAction === "offline"
          ? {
              buttonLabel: "Confirm",
              onClick: () => {
                broadcastStreamStore.clearErrors();
              },
            }
          : undefined;

      if (broadcastStore.isInTippingShow) {
        await broadcastStore.endTippingShow();
      }
      await broadcastStore.stopStreaming(stopMessage, stopActionButton);
    } else if (broadcastStreamStore.streamType === BroadcastStreamType.WEBRTC) {
      await broadcastStreamStore.stopWebRTCBroadcast();
    }
    await broadcastStore.resetStore();
    await broadcastStreamStore.resetStore(stopAction);
    await chatStore.resetStore();
    pricingStore.resetStore();

    // if stopAction is logout, we should not connect because the connect will be called when broadcast page mounts
    // if it is not a logout action then model stopped stream intentionally and might start streaming again
    // therefore we should connect and make things ready for her
    if (!stopAction || (stopAction !== "logout" && stopAction !== "offline")) {
      await pricingStore.getPriceData();
      await this.connect();
    }
    this.log("disconnect finished");
  };
}

export const broadcastStrategy = new BroadcastStrategy();
