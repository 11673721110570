import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindButton from "library/components/_tailwind/button";
import TailwindTranslatedDate from "library/components/_tailwind/translated-date";
import { IStudioModel } from "../stores/models/interfaces";
import { MODEL_APPROVAL_STATUSES } from "../stores/models/consts";
import Media from "library/components/_tailwind/media";
import TailwindIcon from "library/components/_tailwind/icon";

type Props = {
  model: IStudioModel;
  openAccessRightsModal: (model: IStudioModel) => void;
  loginAs: (modelScreenName: string) => void;
};

const ModelTableRow: React.ComponentType<Props> = ({
  model,
  openAccessRightsModal,
  loginAs,
}) => {
  const {
    username,
    model_full_name,
    modified_at,
    status,
    registered_at,
    profile_image,
  } = model || {};

  return (
    <TailwindFlex
      padding={["p-3", "pl-10", "pr-8"]}
      pseudoClasses={["hover:bg-secondary-bg-color", "xs:pl-5", "xs:pr-5"]}
      backgroundColor={"bg-primary-bg-color"}>
      <TailwindFlex
        width={"w-3/12"}
        justifyContent={"justify-start"}
        alignItems={"items-center"}>
        <TailwindFlex
          width={"w-12"}
          height={"h-12"}
          justifyContent={"justify-start"}>
          <Media
            rounded={true}
            borderRadius='rounded'
            overflow={"overflow-hidden"}
            src={profile_image || ""}
          />
        </TailwindFlex>
        <TailwindButton
          backgroundColor={"bg-transparent"}
          fullWidth={false}
          onClick={() => loginAs(username)}
          margin={["m-0"]}
          padding={["p-0"]}
          cursor={"cursor-move"}
          textProps={{
            textAlign: "text-center",
            textColor: "text-blue-700",
            textTransform: "uppercase",
          }}>
          <TailwindText
            width={"w-full"}
            padding={["pl-2", "pr-1"]}
            className={"capitalize-first"}
            textOverflow={"overflow-ellipsis"}
            whiteSpace={"whitespace-nowrap"}
            textAlign={"text-left"}
            overflow={"overflow-hidden"}>
            {username}
          </TailwindText>
        </TailwindButton>
        <TailwindButton
          fullWidth={false}
          onClick={() => openAccessRightsModal(model)}>
          <TailwindIcon fontSize={"text-xl"} name={"settings"} />
        </TailwindButton>
      </TailwindFlex>
      <TailwindFlex
        width={"w-2/12"}
        justifyContent={"justify-end"}
        overflow={"overflow-hidden"}
        alignItems={"items-center"}>
        <TailwindText
          width={"w-full"}
          padding={["pl-1", "pr-1"]}
          className={"capitalize-first"}
          textOverflow={"overflow-ellipsis"}
          whiteSpace={"whitespace-nowrap"}
          overflow={"overflow-hidden"}>
          {model_full_name}
        </TailwindText>
      </TailwindFlex>
      <TailwindFlex
        width={"w-2/12"}
        justifyContent={"justify-end"}
        overflow={"overflow-hidden"}
        alignItems={"items-center"}>
        <TailwindTranslatedDate
          value={modified_at}
          day={"2-digit"}
          month={"2-digit"}
          year={"2-digit"}
          hour={"2-digit"}
          minute={"2-digit"}
          textProps={{ fontSize: "text-base" }}
        />
      </TailwindFlex>
      <TailwindFlex
        width={"w-2/12"}
        justifyContent={"justify-end"}
        alignItems={"items-center"}>
        <TailwindTranslatedDate
          value={registered_at}
          textProps={{ fontSize: "text-base" }}
        />
      </TailwindFlex>
      <TailwindFlex
        width={"w-2/12"}
        pseudoClasses={["xs:w-1/12"]}
        justifyContent={"justify-end"}
        alignItems={"items-center"}>
        <TailwindText
          width={"w-auto"}
          padding={["pl-1", "pr-1"]}
          className={"capitalize-first"}
          textOverflow={"overflow-ellipsis"}
          whiteSpace={"whitespace-nowrap"}
          overflow={"overflow-hidden"}>
          {MODEL_APPROVAL_STATUSES[status]}
        </TailwindText>
      </TailwindFlex>
      <TailwindFlex
        width={"w-2/12"}
        pseudoClasses={["xs:w-1/12"]}
        justifyContent={"justify-end"}
        alignItems={"items-center"}>
        <TailwindText
          width={"w-auto"}
          padding={["pl-1", "pr-1"]}
          className={"capitalize-first"}
          textOverflow={"overflow-ellipsis"}
          whiteSpace={"whitespace-nowrap"}
          overflow={"overflow-hidden"}>
          0
        </TailwindText>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default ModelTableRow;
