import React, { FunctionComponent, useCallback, useEffect } from "react";
import { inject, observer } from "mobx-react";
import AuthStore from "core/stores/auth/AuthStore";
import ValidationStore from "library/core/stores/validation/ValidationStore";
// import TextField from "components/textfield";
// import Button from "components/button";
import { injectIntl, WrappedComponentProps } from "react-intl";
import CreateAccountStepHeader from "./create-account-step-info";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindInput from "library/components/_tailwind/input";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import TailwindButton from "library/components/_tailwind/button";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";

type CreateAccountStepProps = {
  authStore?: AuthStore;
  validationStore?: ValidationStore;
};

const CreateAccountStep: FunctionComponent<
  CreateAccountStepProps & WrappedComponentProps
> = ({ authStore, validationStore, intl }) => {
  const {
    validateFirstStepData,
    userRole,
    formRegistrationData,
    updateRegistrationFormField,
    isActionInProgress,
  } = authStore!;
  const { email, password } = formRegistrationData!;
  const { validate, clearErrors, clearError, errors } = validationStore!;

  const register = useCallback(() => {
    if (userRole) {
      validateFirstStepData();
    }
  }, [userRole]);

  useEffect(() => {
    if (!(email || password)) {
      clearErrors();
    }
  }, []);

  return (
    <TailwindFlex sm={"w-full"} width={"w-96"} height={"h-full"}>
      <form className={"RegisterModal__form"} noValidate autoComplete='false'>
        <CreateAccountStepHeader userRole={userRole} />
        <TailwindFlex margin={["mt-6", "mb-4"]}>
          <TailwindInput
            required
            autoFocus
            rounded={false}
            backgroundColor={"transparent"}
            borderColor={"border-main-color"}
            borderWidth={["border"]}
            label={intl.formatMessage({
              id: "landing.createAccount.email",
              defaultMessage: "E-mail",
            })}
            errorProps={{
              textAlign: "text-right",
            }}
            value={email || ""}
            type={TailwindInputType.email}
            name={"email"}
            onChange={e => {
              clearError("email");
              updateRegistrationFormField(null, e.target);
            }}
            error={errors?.email || undefined}
            onBlur={_e => validate("email", email)}
          />
        </TailwindFlex>
        <TailwindFlex margin={["mb-8"]}>
          <TailwindInput
            required
            rounded={false}
            backgroundColor={"transparent"}
            borderColor={"border-main-color"}
            borderWidth={["border"]}
            label={intl.formatMessage({
              id: "landing.createAccount.password",
              defaultMessage: "password",
            })}
            errorProps={{
              textAlign: "text-right",
            }}
            value={password || ""}
            type={TailwindInputType.password}
            name={"password"}
            onChange={e => {
              clearError("password");
              updateRegistrationFormField(null, e.target);
            }}
            error={errors?.password || undefined}
            onBlur={_e => validate("password", password)}
          />
        </TailwindFlex>
        {userRole && (
          <TailwindFlex
            alignItems={"items-center"}
            justifyContent={"justify-between"}>
            <TailwindButton
              showSpinner={isActionInProgress}
              disabled={isActionInProgress}
              fullWidth
              size={"lg"}
              backgroundColor={"primary"}
              onClick={e => {
                e.preventDefault();
                register();
              }}
              justifyContent={"justify-center"}
              textColor={"text-white"}
              rounded={false}>
              <TailwindTranslatedText
                descriptor={{
                  id: "common.next",
                  defaultMessage: "Next",
                }}
                textTransform={"uppercase"}
                fontWeight={"font-bold"}
              />
            </TailwindButton>
          </TailwindFlex>
        )}
      </form>
    </TailwindFlex>
  );
};
export default injectIntl(
  inject("authStore", "validationStore")(observer(CreateAccountStep))
);
