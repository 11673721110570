import React, { useCallback, useMemo } from "react";
import TailwindCard from "library/components/_tailwind/card";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import TailwindIcon from "library/components/_tailwind/icon";
import MyFansMemberTransactionsTableHead from "./member-transactions-table-head";
import TailwindButton from "library/components/_tailwind/button";
import MyFansMemberTransactionsTableRow from "./member-transactions-table-row";
import MemberBadges from "./member-badges";
import { inject, observer } from "mobx-react";
import { MyFansPageMemberStats } from "containers/model/my-fans/stores/my-fans/types";
import MyFansStore from "containers/model/my-fans/stores/my-fans/MyFansStore";
import MyFansMemberTransactionsTablePagination from "containers/model/my-fans/member-transactions-table-pagination";
import TailwindTranslatedNumber from "library/components/_tailwind/translated-number";
import TailwindSpinner from "library/components/_tailwind/spinner";
import ThemeStore from "library/core/stores/theme/ThemeStore";
import { ThemeColorContrast } from "library/core/stores/theme/enums";

type Props = {
  fan: MyFansPageMemberStats;
  myFansStore?: MyFansStore;
  themeStore?: ThemeStore;
};

const MyFansMemberTableRowMobile: React.ComponentType<Props> = ({
  fan,
  myFansStore,
  themeStore,
}) => {
  const {
    setSelectedMemberId,
    selectedMemberId,
    selectedMemberTransactions,
    loadingMemberTransactions,
    sendMessageToMember,
  } = myFansStore!;
  const { theme } = themeStore!;
  const onClickSendMessage = useCallback(() => {
    sendMessageToMember(fan?.member?.user_id || "");
  }, [fan]);

  const isSelected = useMemo(() => {
    return fan?.member?.id === selectedMemberId;
  }, [selectedMemberId, fan]);

  const onClickToggleTransactions = useCallback(() => {
    setSelectedMemberId(fan?.member?.id);
  }, [fan]);
  const checkDark = ThemeColorContrast && theme === ThemeColorContrast.light;
  return (
    <TailwindCard
      flexDirection={"flex-col"}
      width={"w-full"}
      maxHeight={"max-h-full-without-header"}
      padding={["p-4", "pb-4"]}
      backgroundColor={"bg-primary-bg-color"}
      borderColor={"border-table-secondary-color"}
      borderStyle={"border-solid"}
      borderWidth={["border-b"]}
      pseudoClasses={
        checkDark
          ? ["hover:bg-gray-300"]
          : ["odd:bg-gray-900", "even:bg-gray-800", "hover:bg-gray-700"]
      }>
      <TailwindFlex width={"w-full"}>
        <TailwindFlex flexDirection={"flex-col"} alignItems={"items-center"}>
          <TailwindFlex alignItems={"items-center"}>
            <TailwindFlex width={"w-auto"} alignItems={"items-center"}>
              <TailwindText
                fontSize={"text-base"}
                fontWeight={isSelected ? "font-bold" : "font-normal"}
                overflow={"overflow-hidden"}
                textColor='text-main-color'
                textOverflow={"overflow-ellipsis"}
                justifyContent={"justify-start"}
                whiteSpace={"whitespace-nowrap"}>
                {fan?.member?.username}
              </TailwindText>
              <TailwindButton
                onClick={onClickSendMessage}
                fullWidth={false}
                rightIconProps={{
                  name: "email",
                  fontSize: "text-lg",
                }}
              />
              <MemberBadges fan={fan} />
            </TailwindFlex>
            <TailwindFlex
              margin={["mt-1", "mb-1"]}
              justifyContent={"justify-start"}
              alignItems={"items-center"}
            />
            <TailwindTranslatedNumber
              textProps={{
                textColor: "text-main-color",
                fontWeight: isSelected ? "font-bold" : "font-normal",
                margin: ["mr-1"],
              }}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
              value={parseInt(fan?.amount_spent_in_token)}
              style='decimal'
            />
            <TailwindIcon
              textColor={"text-yellow-500"}
              name={CustomIconName.token}
            />
          </TailwindFlex>
          <TailwindFlex
            alignItems={"items-center"}
            justifyContent={"justify-end"}>
            <TailwindFlex
              width={"w-40"}
              padding={["pl-2"]}
              overflow={"overflow-hidden"}
              alignContent={"content-end"}>
              <TailwindButton
                height={"h-full"}
                width={"w-auto"}
                className={[
                  "whitespace-nowrap",
                  "overflow-hidden",
                  "justify-end",
                ]}
                alignSelf={"self-end"}
                padding={["p-0"]}
                onClick={onClickToggleTransactions}>
                <TailwindText
                  width={"w-full"}
                  textAlign={"text-center"}
                  textOverflow={"overflow-ellipsis"}
                  fontWeight={"font-normal"}
                  textColor={"text-blue-500"}
                  fontSize={"text-xs"}>
                  {isSelected ? "Hide" : "Show"} Transactions
                </TailwindText>
              </TailwindButton>
            </TailwindFlex>
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
      {isSelected && (
        <TailwindFlex flexDirection={"flex-col"}>
          <TailwindFlex
            maxHeight={"max-h-full-without-header"}
            width={"w-full"}
            overflow={["overflow-y-hidden", "overflow-x-auto"]}>
            {loadingMemberTransactions ? (
              <TailwindFlex
                justifyContent={"justify-center"}
                alignItems={"items-center"}
                width={"w-full"}
                height={"h-20"}>
                <TailwindSpinner />
              </TailwindFlex>
            ) : selectedMemberTransactions.length ? (
              <TailwindFlex
                flexDirection={"flex-col"}
                padding={["pt-4"]}
                overflow={["overflow-x-hidden", "overflow-y-auto"]}
                minWidth={"min-w-160"}
                width={"w-full"}>
                <MyFansMemberTransactionsTableHead />
                <TailwindFlex
                  flexDirection={"flex-col"}
                  width={"w-full"}
                  maxHeight={"max-h-96"}
                  overflow={["overflow-auto"]}
                  borderColor={"border-table-secondary-color"}
                  borderStyle={"border-solid"}
                  borderWidth={["border-l", "border-r"]}>
                  {selectedMemberTransactions.map(transaction => (
                    <MyFansMemberTransactionsTableRow
                      key={`${transaction.id}-t`}
                      transaction={transaction}
                    />
                  ))}
                </TailwindFlex>
              </TailwindFlex>
            ) : (
              <TailwindFlex
                justifyContent={"justify-center"}
                alignItems={"items-center"}
                width={"w-full"}
                height={"h-10"}>
                <TailwindText fontSize={"text-sm"} textColor='text-main-color'>
                  There are no transactions for this member in the past 30 days.
                </TailwindText>
              </TailwindFlex>
            )}
          </TailwindFlex>
          <MyFansMemberTransactionsTablePagination />
        </TailwindFlex>
      )}
    </TailwindCard>
  );
};

export default inject(
  "myFansStore",
  "themeStore"
)(observer(MyFansMemberTableRowMobile));
