import React, { useState } from "react";
import TailwindFlex, { TailwindFlexProps } from "../flex";
import TailwindIcon, { TailwindIconProps } from "../icon";
import TailwindText, { TailwindTextProps } from "../text";
import TailwindButton, { TailwindButtonProps } from "../button";
import TailwindCard, { TailwindCardProps } from "../card";

type Props = {
  label: string;
  initCollapsed?: boolean;
  onClick?: Function;
  buttonProps?: TailwindButtonProps & {
    showArrowOnRight?: boolean;
  };
  buttonFlexProps?: TailwindFlexProps;
  iconProps?: Omit<TailwindIconProps, "name"> & {
    collapsedIconName?: string;
    expandedIconName?: string;
  };
  cardProps?: TailwindCardProps;
  textLabelProps?: TailwindTextProps;
} & TailwindFlexProps;

const TailwindAccordion: React.ComponentType<Props> = ({
  label,
  initCollapsed = true,
  buttonProps,
  buttonFlexProps,
  iconProps,
  cardProps,
  textLabelProps,
  children,
  ...props
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(initCollapsed);

  return (
    <React.Fragment>
      <TailwindFlex alignItems={"items-center"} {...props}>
        <TailwindButton
          {...buttonProps}
          fullWidth={true}
          onClick={() => setIsCollapsed(!isCollapsed)}>
          <TailwindFlex {...buttonFlexProps}>
            {!buttonProps?.showArrowOnRight && (
              <TailwindIcon
                {...iconProps}
                name={
                  isCollapsed
                    ? iconProps?.collapsedIconName || "keyboard_arrow_right"
                    : iconProps?.expandedIconName || "keyboard_arrow_down"
                }
              />
            )}
            <TailwindText {...textLabelProps} fontWeight={"font-bold"}>
              {label}
            </TailwindText>
            {buttonProps?.showArrowOnRight && (
              <TailwindIcon
                {...iconProps}
                name={
                  isCollapsed
                    ? iconProps?.collapsedIconName || "keyboard_arrow_right"
                    : iconProps?.expandedIconName || "keyboard_arrow_down"
                }
              />
            )}
          </TailwindFlex>
        </TailwindButton>
      </TailwindFlex>
      <TailwindCard
        {...cardProps}
        width={"w-full"}
        height={isCollapsed ? "h-0" : "h-auto"}
        padding={isCollapsed ? [] : cardProps?.padding || []}
        margin={isCollapsed ? [] : cardProps?.margin || []}
        flexDirection={"flex-col"}
        overflow={["overflow-hidden"]}>
        {children}
      </TailwindCard>
    </React.Fragment>
  );
};

export default TailwindAccordion;
