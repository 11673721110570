import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindBox from "../../components/_tailwind/box";
import TailwindParagraph from "../../components/_tailwind/paragraph";

type Props = {};

const StorybookComponentsParagraph: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Paragraph</StorybookHeading>
      <StorybookParagraph>
        Paragraph is exactly the same as TranslatedText but for convenience of
        being specific, it has been created with different name such as
        Paragraph. In the future we might opinionate it a little bit like
        putting some margin bottom but for now it is exactly the same.
      </StorybookParagraph>
      <StorybookParagraph>
        Currently it takes all the props from TailwindText.
      </StorybookParagraph>
      <StorybookParagraph>
        Here are some examples with the TailwindText component. As it is very
        flexible, you can create variants easily so not all combinations are
        showcased here.
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindParagraph fontSize={"text-3xl"}>text-3xl</TailwindParagraph>`}
      >
        <TailwindParagraph fontSize={"text-3xl"}>text-3xl</TailwindParagraph>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindParagraph cursor={"cursor-pointer"}>cursor-pointer</TailwindParagraph>`}
      >
        <TailwindParagraph cursor={"cursor-pointer"}>
          cursor-pointer
        </TailwindParagraph>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindParagraph textColor={"text-red-500"}>text-red-500</TailwindParagraph>`}
      >
        <TailwindParagraph textColor={"text-red-500"}>
          text-red-500
        </TailwindParagraph>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindParagraph fontWeight={"font-bold"}>font-bold</TailwindParagraph>`}
      >
        <TailwindParagraph fontWeight={"font-bold"}>
          font-bold
        </TailwindParagraph>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindParagraph
  textColor={"text-yellow-500"}
  pseudoClasses={["hover:text-red-500"]}
>
  hover:text-red-500
</TailwindParagraph>     
`}
      >
        <TailwindParagraph
          textColor={"text-yellow-500"}
          pseudoClasses={["hover:text-red-500"]}
        >
          hover:text-red-500
        </TailwindParagraph>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindParagraph
  textColor={"text-red-500"}
  textOpacity={"text-opacity-30"}
>
  text-opacity-30
</TailwindParagraph>
`}
      >
        <TailwindParagraph
          textColor={"text-red-500"}
          textOpacity={"text-opacity-30"}
        >
          text-opacity-30
        </TailwindParagraph>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindParagraph textColor={"text-red-500"} margin={["mx-4", "my-8"]}>
  margin mx-4 my-8
</TailwindParagraph>  
`}
      >
        <TailwindParagraph textColor={"text-red-500"} margin={["mx-4", "my-8"]}>
          margin mx-4 my-8
        </TailwindParagraph>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindParagraph textColor={"text-red-500"} padding={["px-4", "py-8"]}>
  padding px-4 py-8
</TailwindParagraph>
`}
      >
        <TailwindParagraph
          textColor={"text-red-500"}
          padding={["px-4", "py-8"]}
        >
          padding px-4 py-8
        </TailwindParagraph>
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindParagraph
  textColor={"text-red-500"}
  position={"absolute"}
  inset={["bottom-0", "right-20"]}
>
  position absolute bottom-0 right-20
</TailwindParagraph>
`}
      >
        <TailwindBox position={"relative"} height={"h-20"}>
          <TailwindParagraph
            textColor={"text-red-500"}
            position={"absolute"}
            inset={["bottom-0", "right-20"]}
          >
            position absolute bottom-0 right-20
          </TailwindParagraph>
        </TailwindBox>
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsParagraph;
