import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./styles.scss";
import { DropzoneProps, useDropzone } from "react-dropzone";
import CONFIG from "../../core/config";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { Icon } from "@material-ui/core";
import TailwindBox, { TailwindBoxProps } from "../_tailwind/box";
import shortid from "shortid";
import TailwindText from "../_tailwind/text";
import TailwindSpinner from "../_tailwind/spinner";

export type FileUploadProcessStatus =
  | "processing"
  | "accepted"
  | "rejected"
  | "validation"
  | undefined;

export type FileUploadProps = TailwindBoxProps & {
  previewUrl?: string;
  showPreviewUrlAsBackgroundImage?: boolean;
  processStatus?: FileUploadProcessStatus;
  showStatus?: boolean;
  fileName?: string;
  shape?: "rectangle" | "circle" | "soft-rectangle";
  error?: boolean;
  helperText?: string;
  showHelperText?: boolean;
  customIcon?: React.ReactElement;
  acceptedText?: string;
  dropzoneProps?: DropzoneProps;
  stopPropagation?: boolean;
  altBaseClassName?: string;
  isUploading?: boolean;
};

const FileUpload: React.ComponentType<FileUploadProps> = ({
  dropzoneProps: _dropzoneProps,
  previewUrl,
  processStatus = undefined,
  showStatus = true,
  fileName,
  shape = "rectangle",
  helperText,
  showHelperText = true,
  error,
  className = "",
  customIcon,
  showPreviewUrlAsBackgroundImage = false,
  acceptedText = "Your file(s) have been uploaded",
  borderColor = "border-gray-400",
  textColor = "text-gray-300",
  borderStyle = "border-solid",
  borderWidth = ["border"],
  stopPropagation,
  altBaseClassName,
  isUploading = false,
  ...props
}) => {
  const [prevIsLoadingState, setPrevIsLoadingState] = useState<boolean>(false);
  const dropzoneProps: DropzoneProps = useMemo(() => {
    return {
      maxSize: CONFIG.maximumImageSize.size,
      accept: ["image/jpeg", "image/jpg", "image/png", "application/pdf"],
      maxFiles: 5,
      ..._dropzoneProps,
    };
  }, [_dropzoneProps]);

  const id = shortid.generate();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    draggedFiles,
    inputRef,
  } = useDropzone({
    ...dropzoneProps,
  });

  const wrapperClass = useMemo(() => {
    let _className;
    if (altBaseClassName) {
      _className = `${altBaseClassName} ${altBaseClassName}-${shape} ${className}`;
    } else {
      _className = `GenericFileUploadDropzone GenericFileUploadDropzone--shape-${shape} ${className}`;
    }

    if (isDragActive) {
      _className += " GenericFileUploadDropzone__active";
    }
    if (isDragAccept) {
      _className += " GenericFileUploadDropzone__accept";
    }
    if (isDragReject || error) {
      _className += " GenericFileUploadDropzone__reject";
    }

    return _className;
  }, [isDragActive, isDragAccept, isDragReject, className, error]);

  const acceptedFileTypesWithoutMime = useMemo(() => {
    let fileTypes = "";
    if (typeof dropzoneProps.accept === "string") {
      const mimes = dropzoneProps.accept?.split(" ");
      mimes.forEach(mime => {
        const splitStr = mime.split("/");
        if (splitStr.length > 1 && splitStr[1]) {
          if (fileTypes.length) {
            fileTypes += ", ";
          }
          fileTypes += splitStr[1];
        }
      });
    } else {
      dropzoneProps.accept?.forEach(mime => {
        const splitStr = mime.split("/");
        if (splitStr.length > 1 && splitStr[1]) {
          if (fileTypes.length) {
            fileTypes += ", ";
          }
          fileTypes += splitStr[1];
        }
      });
    }

    return fileTypes;
  }, [dropzoneProps]);

  const isOverMaxFiles = useMemo(
    () => draggedFiles.length > (dropzoneProps?.maxFiles || 1),
    [draggedFiles, dropzoneProps]
  );

  const hasInvalidFileType = useMemo(
    () => !isOverMaxFiles && isDragReject,
    [isOverMaxFiles, isDragReject]
  );

  const processIndicatorIconColor = useMemo(() => {
    if (processStatus === "processing") {
      return "#ef951b";
    } else if (processStatus === "accepted") {
      return "#28a745";
    } else if (processStatus === "rejected") {
      return "#cd264f";
    } else if (dropzoneProps?.disabled) {
      return "#ef951b";
    }
    return "#3B70EB";
  }, [processStatus, props, shape, dropzoneProps]);

  const iconProps = useMemo(() => {
    const props: any = {};

    if (!processStatus && !error) {
      props.className = "GenericFileUploadDropzone__icon ";
    } else if (processStatus === "processing") {
      props.customColor = processIndicatorIconColor;
    } else if (processStatus === "accepted" || processStatus === "rejected") {
      props.style = {
        ...props.style,
        color: processIndicatorIconColor,
      };
    }

    return props;
  }, [processStatus, error, processIndicatorIconColor]);

  const promptStyle = useMemo(() => {
    if (showPreviewUrlAsBackgroundImage) {
      return {
        backgroundImage: `url('${previewUrl}')`,
        backgroundSize: `cover`,
        backgroundPosition: `center center`,
      };
    }

    return {};
  }, [showPreviewUrlAsBackgroundImage, previewUrl]);

  const _onClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (stopPropagation) {
        event.preventDefault();
        event.stopPropagation();
        const inputEl = document.getElementById(id);
        inputEl?.click();
      }
    },
    [stopPropagation, id]
  );

  useEffect(() => {
    if (prevIsLoadingState && !isUploading && inputRef.current) {
      inputRef.current.value = "";
    }
    setPrevIsLoadingState(isUploading);
  }, [isUploading]);

  return (
    <TailwindBox
      {...(getRootProps({
        className: [wrapperClass] as any,
        onClick: _onClick,
      }) as any)}
      {...props}
      borderWidth={borderWidth}
      borderStyle={borderStyle}
      borderColor={borderColor}
      textColor={textColor}>
      <input id={id} {...getInputProps()} />
      {(!previewUrl || (showStatus && isDragActive)) && (
        <React.Fragment>
          <div className={"GenericFileUploadDropzone__prompt text-current"}>
            <div className={"GenericFileUploadDropzone__icon-wrapper"}>
              {!dropzoneProps?.disabled && (
                <React.Fragment>
                  {customIcon && React.cloneElement(customIcon, iconProps)}
                  {!customIcon && (
                    <React.Fragment>
                      {!processStatus && !error && (
                        <PhotoLibraryIcon {...iconProps} />
                      )}
                      {processStatus === "processing" && <TailwindSpinner />}
                      {processStatus === "accepted" && (
                        <Icon {...iconProps}>check</Icon>
                      )}
                      {processStatus === "rejected" && (
                        <Icon {...iconProps}>error_outline</Icon>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              {dropzoneProps?.disabled && (
                <Icon style={{ color: processIndicatorIconColor }}>
                  warning
                </Icon>
              )}
            </div>
            {showStatus && shape !== "circle" && (
              <React.Fragment>
                {!dropzoneProps?.disabled && (
                  <React.Fragment>
                    {!processStatus && (
                      <React.Fragment>
                        {!isOverMaxFiles &&
                          !hasInvalidFileType &&
                          showHelperText && (
                            <React.Fragment>
                              {helperText ? (
                                <TailwindText
                                  textAlign={"text-center"}
                                  dangerouslySetInnerHTML={{
                                    __html: helperText,
                                  }}
                                />
                              ) : (
                                "Drop files here to upload or click here to browse and upload"
                              )}
                            </React.Fragment>
                          )}
                        {(isOverMaxFiles || hasInvalidFileType) &&
                          `You can only upload ${dropzoneProps?.maxFiles} and ${acceptedFileTypesWithoutMime} files`}
                      </React.Fragment>
                    )}
                    {processStatus === "processing" && "Please wait..."}
                    {processStatus === "accepted" && acceptedText}
                    {processStatus === "rejected" &&
                      "Something went wrong. Please try again"}
                  </React.Fragment>
                )}
                {dropzoneProps?.disabled &&
                  "Please fill out other fields first"}
              </React.Fragment>
            )}
            <br />
            {fileName && fileName !== "" && fileName}
          </div>
        </React.Fragment>
      )}
      {previewUrl && (
        <div
          className={"GenericFileUploadDropzone__prompt text-current"}
          style={promptStyle}>
          {fileName && fileName !== "" && (
            <div className={"GenericFileUploadDropzone__filename"}>
              {fileName}
            </div>
          )}
          {!showPreviewUrlAsBackgroundImage && <img src={previewUrl} alt='' />}
        </div>
      )}
    </TailwindBox>
  );
};

export default FileUpload;
