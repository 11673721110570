import {
  AppCamsModelRouteKey,
  AppCamsStudioRouteKey,
  RouteSidebarLocations,
} from "./enums";
import { AppRoute } from "./types";
import { TUTORIAL_ROUTE } from "core/stores/route/variant";
import { UserRole } from "library/core/types";

export const CAMS_LANDING_TITLE = "Welcome to Cams";
export const CAMS_MODEL_LANDING_TITLE = "Welcome to Cams Models Center";
export const CAMS_STUDIO_LANDING_TITLE = "Welcome to Cams Studios Center";

export const CAMS_MODEL_ROUTES: Record<AppCamsModelRouteKey, AppRoute> = {
  ...TUTORIAL_ROUTE,
  [AppCamsModelRouteKey.contests]: {
    key: AppCamsModelRouteKey.contests,
    link: "contests",
    pageTitle: "Contests",
    hasAccess: ["model"],
    /*sidebarOptions: {
      location: RouteSidebarLocations.top,
      index: 8,
      icon: "bar_chart",
      label: "Contests",
      userRole: ["model"],
    },*/
  },
  [AppCamsModelRouteKey.albums]: {
    key: AppCamsModelRouteKey.albums,
    link: "albums",
    pageTitle: "Albums",
    hasAccess: ["model"],
  },
  [AppCamsModelRouteKey.recordedVideos]: {
    key: AppCamsModelRouteKey.recordedVideos,
    link: "my-recorded-videos",
    pageTitle: "Recorded Videos",
    hasAccess: ["model"],
  },
  [AppCamsModelRouteKey.uploadedVideos]: {
    key: AppCamsModelRouteKey.uploadedVideos,
    link: "my-uploaded-videos",
    pageTitle: "Uploaded Videos",
    hasAccess: ["model"],
  },
  [AppCamsModelRouteKey.leaderboards]: {
    key: AppCamsModelRouteKey.leaderboards,
    link: "leaderboards",
    pageTitle: "Leaderboards",
    hasAccess: ["model"],
    sidebarOptions: {
      location: RouteSidebarLocations.top,
      index: 10,
      label: "Top Models",
      isCustomIcon: true,
      icon: "topmodels",
    },
  },
  [AppCamsModelRouteKey.myPage]: {
    key: AppCamsModelRouteKey.myPage,
    link: "my-profile",
    pageTitle: "My Profile",
    hasAccess: (
      userRole: UserRole,
      _isIndividualModel: boolean,
      profileLoaded: boolean
    ) => {
      return userRole === "model" && profileLoaded;
    },
    sidebarOptions: {
      location: RouteSidebarLocations.top,
      index: 1,
      label: "My Profile",
      isCustomIcon: true,
      icon: "profile",
    },
  },
  [AppCamsModelRouteKey.myPhotosRoot]: {
    key: AppCamsModelRouteKey.myPhotosRoot,
    link: "my-photos",
    pageTitle: "My Photos",
    hasAccess: ["model"],
  },
  [AppCamsModelRouteKey.myPhotosList]: {
    key: AppCamsModelRouteKey.myPhotosList,
    link: "my-photos/list",
    pageTitle: "My Photos",
    hasAccess: ["model"],
    sidebarOptions: {
      location: RouteSidebarLocations.top,
      index: 2,
      label: "My Photos",
      icon: "photos",
    },
  },
  [AppCamsModelRouteKey.myPhotosCreate]: {
    key: AppCamsModelRouteKey.myPhotosCreate,
    link: "my-photos/create",
    pageTitle: "My Photos",
    hasAccess: ["model"],
  },
  [AppCamsModelRouteKey.myPhotosEdit]: {
    key: AppCamsModelRouteKey.myPhotosEdit,
    link: "my-photos/edit",
    pageTitle: "My Photos",
    hasAccess: ["model"],
  },
  [AppCamsModelRouteKey.giftsAndAwards]: {
    key: AppCamsModelRouteKey.giftsAndAwards,
    link: "gifts",
    pageTitle: "Gifts",
    hasAccess: ["model"],
    sidebarOptions: {
      location: RouteSidebarLocations.top,
      index: 9,
      icon: "giftcards",
      label: "Gifts",
    },
  },
  [AppCamsModelRouteKey.myFans]: {
    key: AppCamsModelRouteKey.myFans,
    link: "my-fans",
    pageTitle: "My Fans",
    hasAccess: ["model"],
    sidebarOptions: {
      location: RouteSidebarLocations.top,
      index: 3,
      icon: "fans",
      label: "My Fans",
    },
  },
  [AppCamsModelRouteKey.referrals]: {
    key: AppCamsModelRouteKey.referrals,
    link: "referrals",
    pageTitle: "Referrals",
    hasAccess: ["model"],
    sidebarOptions: {
      location: RouteSidebarLocations.top,
      index: 4,
      icon: "referals",
      label: "Referrals",
    },
  },
  [AppCamsModelRouteKey.blocks]: {
    key: AppCamsModelRouteKey.blocks,
    link: "blocks",
    pageTitle: "Blocks",
    hasAccess: ["model"],
  },
};

export const CAMS_STUDIO_ROUTES: Record<AppCamsStudioRouteKey, AppRoute> = {
  [AppCamsStudioRouteKey.dashboard]: {
    key: AppCamsStudioRouteKey.dashboard,
    link: "dashboard",
    pageTitle: "Dashboard",
    hasAccess: ["studio"],
    /*sidebarOptions: {
      icon: "home",
      label: "Dashboard",
      location: RouteSidebarLocations.top,
      index: 1,
    },*/
  },
  [AppCamsStudioRouteKey.models]: {
    key: AppCamsStudioRouteKey.models,
    link: "models",
    pageTitle: "Models",
    hasAccess: ["studio"],
    sidebarOptions: {
      location: RouteSidebarLocations.top,
      index: 3,
      label: "Models",
      icon: "referals",
    },
  },
};
