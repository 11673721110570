import { inject, observer } from "mobx-react";
import React, { useCallback, useEffect } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import PricingStore from "../_stores/pricing/PricingStore";
import ProductDropdown from "../broadcast-pricing-product-dropdown";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindButton from "library/components/_tailwind/button";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindDropdown, {
  TailwindDropdownValue,
} from "library/components/_tailwind/dropdown";
import {
  BROADCASTER_IS_CONNEXION_ONE_WAY_ENABLED,
  BROADCASTER_IS_CONNEXION_TWO_WAY_ENABLED,
  BROADCASTER_IS_PARTY_CHAT_ENABLED,
} from "../_stores/broadcast/consts";
import StudioModelAccessRights from "../../studio-models-access-rights";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import HelpTooltip from "library/components/_tailwind/help-tooltip";
import TailwindBox from "library/components/_tailwind/box";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import BroadcastingSettingsModal from "../broadcasting-settings-modal";
import { MODAL_WRAPPER_CLASSNAME } from "library/core/stores/modal/consts";
import NodeChatStore from "../_stores/nodechat/NodeChatStore";
import LanguageStore from "library/core/stores/language/LanguageStore";
import { LANGUAGES } from "library/i18n/locales";

interface Props {
  broadcastStore?: BroadcastStore;
  pricingStore?: PricingStore;
  validationStore?: ValidationStore;
  profileStore?: ProfileStore;
  layoutStore?: LayoutStore;
  nodeChatStore?: NodeChatStore;
  languageStore?: LanguageStore;
}

const BroadcastSettingsPopover: React.FunctionComponent<
  Props & WrappedComponentProps
> = ({
  broadcastStore,
  pricingStore,
  validationStore,
  profileStore,
  nodeChatStore,
  languageStore,
}) => {
  const { hideVideoSettingsPopover, isShowStarted } = broadcastStore!;
  const { setFanWhisperFree, setMembersNeedBalanceToChat } = nodeChatStore!;
  const { intl, language, updateCookieAndSetLang } = languageStore!;
  const {
    modelProfile: { access_rights },
    isStudioModel,
  } = profileStore!;
  const {
    formatPricesByID,
    modelProducts,
    submitNewPrice,
    submitPrice,
    submitIsRecordingPrivate,
    connexionOneWayPriceOptions,
    connexionTwoWayPriceOptions,
    partyChatPriceOptions,
    whisperMessagePriceOptions,
    buzzPriceOptions,
    superBuzzPriceOptions,
    submitBuzzPrice,
    submitSystemSettings,
  } = pricingStore!;
  const { clearErrors } = validationStore!;

  useEffect(() => {
    clearErrors();
  }, []);

  const clickAway = () => {
    hideVideoSettingsPopover() as any;
  };

  const onChangeIsRecordingAllPrivateShows = useCallback(isChecked => {
    submitIsRecordingPrivate(isChecked);
  }, []);

  const onChangeConnexionTwoWayPrice = useCallback(
    (value: any) => submitPrice("connexion_two_way", value),
    []
  );

  const onChangeConnexionOneWayPrice = useCallback(
    (value: any) => submitPrice("connexion_one_way", value),
    []
  );

  const onChangePartyChatPrice = useCallback(
    (value: any) => submitNewPrice("party_chat", "is_party_allowed", value),
    []
  );

  const onChangeWhisperMessagePrice = useCallback(
    (value: any) => submitPrice("whisper_message", value),
    []
  );

  const onChangeBuzzPrice = useCallback((value: TailwindDropdownValue) => {
    if (typeof value === "number") {
      submitBuzzPrice(true, value);
    } else {
      submitBuzzPrice(false);
    }
  }, []);

  const onChangeSoundEnterLeave = useCallback((value: any) => {
    submitSystemSettings({
      sound_enter_leave: value,
    });
  }, []);

  const onChangeFanClubWhisperFree = useCallback((value: any) => {
    setFanWhisperFree(value);
    submitSystemSettings({
      fan_whisper_free: value,
    });
  }, []);

  const onChangeAllowMembersWithFundToChat = useCallback((value: any) => {
    setMembersNeedBalanceToChat(value);
    submitSystemSettings({
      only_allow_members_with_fund_to_chat: value,
    });
  }, []);

  const onChangeWhisperTabsLayout = useCallback((value: any) => {
    submitSystemSettings({
      whispers_in_separate_tabs: value,
    });
  }, []);

  return (
    <BroadcastingSettingsModal onClose={hideVideoSettingsPopover}>
      <TailwindBox className={[MODAL_WRAPPER_CLASSNAME]}>
        <TailwindFlex
          justifyContent={"justify-between"}
          position='relative'
          alignItems={"items-center"}>
          <TailwindTranslatedText
            descriptor={{
              id: "settings.other-options",
              defaultMessage: "Other options",
            }}
            paragraphProps={{
              fontSize: "text-xl",
              fontWeight: "font-bold",
              margin: ["mb-3"],
            }}
          />
          <TailwindButton
            position='absolute'
            padding={["p-0"]}
            inset={["-top-3", "-right-2"]}
            fullWidth={false}
            onClick={clickAway}>
            <TailwindIcon fontSize={"text-3xl"} name={"close"} />
          </TailwindButton>
        </TailwindFlex>
        <StudioModelAccessRights
          isIndividualModel={!isStudioModel}
          shouldShow={!!access_rights?.is_pricing_settings_change_allowed}
          type={"pricing"}
          boxProps={{
            margin: ["mb-2"],
          }}>
          <TailwindFlex justifyContent='justify-between' margin={["mb-5"]}>
            <TailwindTranslatedText
              textColor='text-main-color'
              descriptor={{
                id: "free-to-enter-private.whisper",
                defaultMessage: "Whisper/private message",
              }}
              padding={["pt-2"]}
            />
            <ProductDropdown
              showSearch={false}
              className={["h-10", "w-60"]}
              fullHeight={true}
              disabled={isShowStarted}
              options={whisperMessagePriceOptions}
              onChange={onChangeWhisperMessagePrice}
              price={modelProducts?.whisper_message}
              currency={intl.formatMessage({
                id: `common.units.tokens-long`,
                defaultMessage: "tokens",
              })}
              unit={intl.formatMessage({
                id: `common.units.message-long`,
                defaultMessage: "message",
              })}
            />
          </TailwindFlex>
          <TailwindFlex justifyContent='justify-between' margin={["mb-5"]}>
            <TailwindTranslatedText
              textColor='text-main-color'
              descriptor={{
                id: "common.buzz",
                defaultMessage: "Buzz",
              }}
              padding={["pt-2"]}
            />
            <ProductDropdown
              showSearch={false}
              className={["h-10", "w-60"]}
              fullHeight={true}
              disabled={isShowStarted}
              options={buzzPriceOptions}
              onChange={onChangeBuzzPrice}
              price={formatPricesByID("buzz_price", "buzz_status")}
              currency={intl.formatMessage({
                id: `common.units.tokens-long`,
                defaultMessage: "tokens",
              })}
              unit={intl.formatMessage({
                id: `common.units.buzz`,
                defaultMessage: "buzz",
              })}
            />
          </TailwindFlex>
          <TailwindFlex justifyContent='justify-between' margin={["mb-5"]}>
            <TailwindTranslatedText
              textColor='text-main-color'
              descriptor={{
                id: "payout-product-types.super_buzz",
                defaultMessage: "SuperBuzz",
              }}
              padding={["pt-2"]}
            />
            <TailwindFlex width={"w-60"}>
              <ProductDropdown
                showSearch={false}
                className={["h-10", "w-60"]}
                fullHeight={true}
                disabled={true}
                options={superBuzzPriceOptions}
                onChange={() => {}}
                price={formatPricesByID("superbuzz_price", "buzz_status")}
                currency={intl.formatMessage({
                  id: `common.units.tokens-long`,
                  defaultMessage: "tokens",
                })}
                unit={intl.formatMessage({
                  id: `common.units.superbuzz`,
                  defaultMessage: "superbuzz",
                })}
              />
            </TailwindFlex>
          </TailwindFlex>
        </StudioModelAccessRights>
        {BROADCASTER_IS_PARTY_CHAT_ENABLED && (
          <TailwindFlex justifyContent='justify-between' margin={["mb-5"]}>
            <TailwindTranslatedText
              textColor='text-main-color'
              descriptor={{
                id: "free-to-enter-private.party",
                defaultMessage: "Party show",
              }}
              padding={["pt-2"]}
            />
            <TailwindFlex width={"w-60"}>
              <ProductDropdown
                showSearch={false}
                className={["h-10"]}
                fullHeight={true}
                disabled={isShowStarted}
                options={partyChatPriceOptions}
                onChange={onChangePartyChatPrice}
                price={formatPricesByID("party_chat", "is_party_allowed")}
                currency={intl.formatMessage({
                  id: `common.units.tokens-long`,
                  defaultMessage: "tokens",
                })}
                unit={intl.formatMessage({
                  id: `common.units.minute`,
                  defaultMessage: "minute",
                })}
              />
            </TailwindFlex>
          </TailwindFlex>
        )}

        {BROADCASTER_IS_CONNEXION_ONE_WAY_ENABLED && (
          <TailwindFlex justifyContent='justify-between' margin={["mb-5"]}>
            <TailwindTranslatedText
              textColor='text-main-color'
              descriptor={{
                id: "connexion.connexion1",
                defaultMessage: "Connexion-1-way",
              }}
              padding={["pt-2"]}
            />
            <TailwindFlex width={"w-60"}>
              <ProductDropdown
                showSearch={false}
                className={["h-10"]}
                // fullWidth={true}
                fullHeight={true}
                disabled={isShowStarted}
                options={connexionOneWayPriceOptions}
                onChange={onChangeConnexionOneWayPrice}
                price={modelProducts?.connexion_one_way}
                currency={intl.formatMessage({
                  id: `common.units.tokens`,
                  defaultMessage: "tkns",
                })}
                unit={intl.formatMessage({
                  id: `common.units.min`,
                  defaultMessage: "min",
                })}
              />
            </TailwindFlex>
          </TailwindFlex>
        )}
        {BROADCASTER_IS_CONNEXION_TWO_WAY_ENABLED && (
          <TailwindFlex justifyContent='justify-between' margin={["mb-5"]}>
            <TailwindTranslatedText
              textColor='text-main-color'
              descriptor={{
                id: "connexion.connexion2",
                defaultMessage: "Connexion-2-way",
              }}
              padding={["pt-2"]}
            />
            <TailwindFlex width={"w-60"}>
              <ProductDropdown
                showSearch={false}
                className={["h-10"]}
                // fullWidth={true}
                fullHeight={true}
                disabled={isShowStarted}
                options={connexionTwoWayPriceOptions}
                onChange={onChangeConnexionTwoWayPrice}
                price={modelProducts?.connexion_two_way}
                currency={intl.formatMessage({
                  id: `common.units.tokens`,
                  defaultMessage: "tkns",
                })}
                unit={intl.formatMessage({
                  id: `common.units.min`,
                  defaultMessage: "min",
                })}
              />
            </TailwindFlex>
          </TailwindFlex>
        )}

        <TailwindFlex justifyContent="justify-between" margin={["mb-5"]}>
          <TailwindFlex width="w-8/12">
            <TailwindFlex display="inline-block">
              <TailwindTranslatedText
                className="mr-1"
                textColor="text-main-color"
                descriptor={{
                  id: "settings.record-private-party-shows",
                  defaultMessage:
                    "Automatically record my Private and Party shows",
                }}
                padding={["pt-2"]}
              />
              <HelpTooltip
                className="dis"
                tooltip={intl.formatMessage({
                  id: "broadcast-system-settings-control.automatically-recorded",
                  defaultMessage:
                    "If this setting is turned on, your Private and Party shows lasting longer than 2 minutes will be recorded automatically. If this setting is turned off, you can manually record all your paid shows without any time limitations, including Nude and Hidden Goal shows.",
                })}
              />
            </TailwindFlex>
          </TailwindFlex>
          <TailwindFlex width={"w-60"}>
            <TailwindDropdown
              showSearch={false}
              className={["h-10"]}
              name='record_prviate_shows'
              fullHeight={true}
              fullWidth={true}
              borderColor={"border-gray-500"}
              backgroundColor={"primary"}
              borderWidth={["border"]}
              value={
                modelProducts?.chat_settings?.is_recording_all_private_shows
              }
              items={[
                {
                  label: intl.formatMessage({
                    id: "common.yes",
                    defaultMessage: "Yes",
                  }),
                  value: true,
                },
                {
                  label: intl.formatMessage({
                    id: "common.no",
                    defaultMessage: "No",
                  }),
                  value: false,
                },
              ]}
              onChange={onChangeIsRecordingAllPrivateShows}
            />
          </TailwindFlex>
        </TailwindFlex>

        <TailwindFlex
          alignItems='items-center'
          justifyContent='justify-between'
          margin={["mb-5"]}>
          <TailwindFlex width='w-8/12'>
            <TailwindFlex display='inline-block'>
              <TailwindTranslatedText
                className='mr-1'
                textColor='text-main-color'
                descriptor={{
                  id: "broadcast-system-settings-control.only-allow-members-with-fund-to-chat",
                  defaultMessage: "Who can chat with me",
                }}
              />
              <HelpTooltip
                className='dis'
                tooltip={intl.formatMessage({
                  id: "broadcast-system-settings-control.prevent-zero-fund",
                  defaultMessage:
                    "Prevent zero fund members from chatting with me.",
                })}
              />
            </TailwindFlex>
          </TailwindFlex>
          <TailwindBox flexGrow={"flex-grow"} />

          <TailwindFlex width={"w-60"}>
            <TailwindDropdown
              showSearch={false}
              className={["h-10"]}
              name='sound_enter_leave'
              fullHeight={true}
              fullWidth={true}
              borderColor={"border-gray-500"}
              backgroundColor={"primary"}
              borderWidth={["border"]}
              value={
                modelProducts?.chat_settings?.system_settings
                  ?.only_allow_members_with_fund_to_chat
              }
              items={[
                {
                  label: intl.formatMessage({
                    id: "broadcast-system-settings-control.everyone",
                    defaultMessage: "Everyone",
                  }),
                  value: false,
                },
                {
                  label: intl.formatMessage({
                    id: "broadcast-system-settings-control.paid-members-only",
                    defaultMessage: "Members with tokens",
                  }),
                  value: true,
                },
              ]}
              onChange={onChangeAllowMembersWithFundToChat}
            />
          </TailwindFlex>
        </TailwindFlex>

        <TailwindFlex alignItems='items-center' margin={["mb-5"]}>
          <TailwindFlex width='w-8/12'>
            <TailwindFlex display='inline-block'>
              <TailwindTranslatedText
                className='mr-1'
                textColor='text-main-color'
                descriptor={{
                  id: "broadcast-system-settings-control.fan-club",
                  defaultMessage: "Fan Club members whisper for free",
                }}
              />
              <HelpTooltip
                tooltip={intl.formatMessage({
                  id: "broadcast-system-settings-control.fanclub-tooltip",
                  defaultMessage:
                    "Use this setting to encourage members to join your Fan Club, as well as limit the number of Whispers you receive during chat.",
                })}
              />
            </TailwindFlex>
          </TailwindFlex>
          <TailwindBox flexGrow={"flex-grow"} />

          <TailwindFlex width={"w-60"}>
            <TailwindDropdown
              showSearch={false}
              className={["h-10"]}
              name='fan_whisper_free'
              fullHeight={true}
              fullWidth={true}
              borderColor={"border-gray-500"}
              backgroundColor={"primary"}
              borderWidth={["border"]}
              value={
                modelProducts?.chat_settings?.system_settings?.fan_whisper_free
              }
              items={[
                {
                  label: intl.formatMessage({
                    id: "common.yes",
                    defaultMessage: "Yes",
                  }),
                  value: true,
                },
                {
                  label: intl.formatMessage({
                    id: "common.no",
                    defaultMessage: "No",
                  }),
                  value: false,
                },
              ]}
              onChange={onChangeFanClubWhisperFree}
            />
          </TailwindFlex>
        </TailwindFlex>

        <TailwindFlex alignItems='items-center' margin={["mb-5"]}>
          <TailwindFlex width='w-8/12'>
            <TailwindFlex display='inline-block'>
              <TailwindTranslatedText
                className='mr-1'
                textColor='text-main-color'
                descriptor={{
                  id: "broadcast-system-settings-control.sound-alert",
                  defaultMessage:
                    "Play sound alert when viewers enter or leave",
                }}
              />
              <HelpTooltip
                tooltip={intl.formatMessage({
                  id: "broadcast-system-settings-control.enable-sound-enter-leave",
                  defaultMessage:
                    "Enable this setting to hear a sound alert when a member leaves the room and when a new member enters. This will help you stay on top of greeting new viewers as they enter the room.",
                })}
              />
            </TailwindFlex>
          </TailwindFlex>
          <TailwindBox flexGrow={"flex-grow"} />

          <TailwindFlex width={"w-60"}>
            <TailwindDropdown
              showSearch={false}
              className={["h-10"]}
              name='sound_enter_leave'
              fullHeight={true}
              fullWidth={true}
              borderColor={"border-gray-500"}
              backgroundColor={"primary"}
              borderWidth={["border"]}
              value={
                modelProducts?.chat_settings?.system_settings?.sound_enter_leave
              }
              items={[
                {
                  label: intl.formatMessage({
                    id: "common.yes",
                    defaultMessage: "Yes",
                  }),
                  value: true,
                },
                {
                  label: intl.formatMessage({
                    id: "common.no",
                    defaultMessage: "No",
                  }),
                  value: false,
                },
              ]}
              onChange={onChangeSoundEnterLeave}
            />
          </TailwindFlex>
        </TailwindFlex>
        <TailwindFlex margin={["mb-5"]} alignItems='items-center'>
          <TailwindTranslatedText
            textColor='text-main-color'
            descriptor={{
              id: "broadcast-system-settings-control.set-language",
              defaultMessage: "Set default language",
            }}
          />
          <TailwindBox flexGrow={"flex-grow"} />
          <TailwindFlex width={"w-60"}>
            <TailwindDropdown
              showSearch={false}
              className={["h-10"]}
              name='lang-picker'
              fullHeight={true}
              fullWidth={true}
              borderColor={"border-gray-500"}
              backgroundColor={"primary"}
              borderWidth={["border"]}
              value={language}
              items={LANGUAGES.map(lang => {
                return {
                  label: lang.name,
                  value: lang.key,
                };
              })}
              onChange={e => {
                updateCookieAndSetLang(e as string);
              }}
            />
          </TailwindFlex>
        </TailwindFlex>
        <TailwindFlex alignItems='items-center' margin={["mb-5"]}>
          <TailwindFlex width='w-8/12'>
            <TailwindFlex display='inline-block'>
              <TailwindTranslatedText
                className='mr-1'
                textColor='text-main-color'
                descriptor={{
                  id: "broadcast-system-settings-control.display-tabs",
                  defaultMessage: "Display whispers on separate tabs",
                }}
              />
              <HelpTooltip
                tooltip={intl.formatMessage({
                  id: "broadcast-system-settings-control.help-switch-layout-whisper",
                  defaultMessage:
                    "Here, you can choose between two options: viewing whispers in the main chatroom or organizing each member's whispers on separate tabs.",
                })}
              />
            </TailwindFlex>
          </TailwindFlex>
          <TailwindBox flexGrow={"flex-grow"} />

          <TailwindFlex width={"w-60"}>
            <TailwindDropdown
              showSearch={false}
              className={["h-10"]}
              name='sound_enter_leave'
              fullHeight={true}
              fullWidth={true}
              borderColor={"border-gray-500"}
              backgroundColor={"primary"}
              borderWidth={["border"]}
              value={
                modelProducts?.chat_settings?.system_settings
                  .whispers_in_separate_tabs
                  ? true
                  : false
              }
              items={[
                {
                  label: intl.formatMessage({
                    id: "common.yes",
                    defaultMessage: "Yes",
                  }),
                  value: true,
                },
                {
                  label: intl.formatMessage({
                    id: "common.no",
                    defaultMessage: "No",
                  }),
                  value: false,
                },
              ]}
              onChange={onChangeWhisperTabsLayout}
            />
          </TailwindFlex>
        </TailwindFlex>
      </TailwindBox>
    </BroadcastingSettingsModal>
  );
};

export default injectIntl(
  inject(
    "broadcastStore",
    "broadcastStreamStore",
    "pricingStore",
    "validationStore",
    "profileStore",
    "layoutStore",
    "nodeChatStore",
    "languageStore"
  )(observer(BroadcastSettingsPopover))
);
