import React, { FunctionComponent, useEffect } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindFlex from "library/components/_tailwind/flex";
import "./styles.scss";

type Props = {
  profileStore?: ProfileStore;
};

const LiveChat8x8: FunctionComponent<Props & WrappedComponentProps> = ({
  profileStore,
}) => {
  const { isStudio } = profileStore!;
  const BUTTON_CLASS_NAME = "live_chat_button_8x8";

  useEffect(() => {
    window["__8x8Chat"] = {
      uuid: "script_145427927765baa7a839aef5.72341446",
      tenant: "ZnJpZW5kZmluZGVybmV0d281MTkwMQ",
      channel: "Chat Cams Model Studio",
      channelUuid: "q1gIEfwuQaCu8VDnhURC9w",
      domain: "https://vcc-na15.8x8.com",
      path: "/.",
      buttonContainerId:
        "__8x8-chat-button-container-script_145427927765baa7a839aef5.72341446",
      align: "right",
    };

    const se = document.createElement("script");
    se.type = "text/javascript";
    se.async = true;
    se.src =
      window["__8x8Chat"].domain +
      window["__8x8Chat"].path +
      "/CHAT/common/js/chat.js";

    const os = document.getElementsByTagName("script")[0];
    os?.parentNode?.insertBefore(se, os);
  }, [isStudio]);

  const openLiveChat = () => {
    const btn = document.querySelector(
      `.${BUTTON_CLASS_NAME} img`
    ) as HTMLImageElement;
    btn?.click();
  };

  return (
    <TailwindFlex justifyContent='justify-center' alignItems='items-center'>
      <TailwindFlex>
        <TailwindIcon
          fontSize={"text-xl"}
          className={"text-main-color cursor-pointer"}
          name={"support"}
          onClick={openLiveChat}
        />
      </TailwindFlex>
      <TailwindFlex
        position='absolute'
        opacity='opacity-0'
        width={"w-0"}
        height={"h-0"}>
        <div
          className={BUTTON_CLASS_NAME}
          id='__8x8-chat-button-container-script_145427927765baa7a839aef5.72341446'></div>
      </TailwindFlex>
    </TailwindFlex>
  );
};
export default injectIntl(inject("profileStore")(observer(LiveChat8x8)));
