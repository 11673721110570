import React from "react";
import TailwindBox from "library/components/_tailwind/box";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import {
  BROADCAST_MAX_ALLOWED_FRAME_RATE,
  BROADCAST_MAX_ALLOWED_RESOLUTION,
  BROADCAST_RECOMMENDED_MAXIMUM_BITRATE_IN_KBPS,
  BROADCAST_RECOMMENDED_MAXIMUM_BITRATE_IN_MBPS,
  BROADCAST_RECOMMENDED_MINIMUM_BITRATE_IN_KBPS,
  BROADCAST_RECOMMENDED_MINIMUM_BITRATE_IN_MBPS,
  BROADCAST_RECOMMENDED_MINIMUM_RESOLUTION,
} from "common/broadcast/_stores/broadcast-stream/consts";

interface Props {}

const OBSModalTabStep4: React.FunctionComponent<Props> = () => {
  return (
    <TailwindBox>
      <TailwindTranslatedText
        paragraphProps={{
          margin: ["mb-2"],
        }}
        descriptor={{
          id: "broadcast-obs-modal.step-4-body",
          defaultMessage: "Here are our recommended settings for maximum quality:",
        }}
      />
      <ul className="list-disc list-inside mb-2">
        <li>
          <TailwindTranslatedText
            descriptor={{
              id: "broadcast-obs-modal.step-4-body-setting-1",
              defaultMessage: "Resolution: {pixels} pixels",
            }}
            values={{
              pixels: BROADCAST_MAX_ALLOWED_RESOLUTION.slice(0, -1) + "0",
            }}
          />
        </li>
        <li>
          <TailwindTranslatedText
            descriptor={{
              id: "broadcast-obs-modal.step-4-body-setting-2",
              defaultMessage: "Frame Rate: {fps} FPS (frames per second)",
            }}
            values={{ fps: BROADCAST_MAX_ALLOWED_FRAME_RATE }}
          />
        </li>
        <li>
          <TailwindTranslatedText
            descriptor={{
              id: "broadcast-obs-modal.step-4-body-setting-3",
              defaultMessage:
                "Bitrate: {mbps} Mbps ({kbps} kbps) Video, 96 kbps Audio",
            }}
            values={{
              mbps: BROADCAST_RECOMMENDED_MAXIMUM_BITRATE_IN_MBPS,
              kbps: BROADCAST_RECOMMENDED_MAXIMUM_BITRATE_IN_KBPS,
            }}
          />
        </li>
      </ul>
      <TailwindTranslatedText
        paragraphProps={{
          margin: ["mb-2"],
        }}
        descriptor={{
          id: "broadcast-obs-modal.step-4-body-minimum",
          defaultMessage: "Minimum Recommended Settings:",
        }}
      />
      <ul className="list-disc list-inside">
        <li>
          <TailwindTranslatedText
            descriptor={{
              id: "broadcast-obs-modal.step-4-body-setting-1",
              defaultMessage: "Resolution: {pixels} pixels",
            }}
            values={{
              pixels: BROADCAST_RECOMMENDED_MINIMUM_RESOLUTION,
            }}
          />
        </li>
        <li>
          <TailwindTranslatedText
            descriptor={{
              id: "broadcast-obs-modal.step-4-body-setting-2",
              defaultMessage: "Frame Rate: {fps} FPS (frames per second)",
            }}
            values={{ fps: BROADCAST_MAX_ALLOWED_FRAME_RATE }}
          />
        </li>
        <li>
          <TailwindTranslatedText
            descriptor={{
              id: "broadcast-obs-modal.step-4-body-setting-3",
              defaultMessage:
                "Bitrate: {mbps} Mbps ({kbps} kbps) Video, 96 kbps Audio",
            }}
            values={{
              mbps: BROADCAST_RECOMMENDED_MINIMUM_BITRATE_IN_MBPS,
              kbps: BROADCAST_RECOMMENDED_MINIMUM_BITRATE_IN_KBPS,
            }}
          />
        </li>
      </ul>
    </TailwindBox>
  );
};

export default OBSModalTabStep4;
