import React, { ReactElement } from "react";
import TailwindIcon from "../_tailwind/icon";
import "./styles.scss";

type Direction = "left" | "right" | "up" | "down";
type CarouselProps = {
  direction?: Direction;
  autoSlide?: boolean;
  slideTime?: number;
  showProgress?: boolean;
  fullHeight?: boolean;
  hideButton?: boolean;
  showNav?: boolean;
  vertical?: boolean;
  restart?: number;
  children: ReactElement[];
  click?: {
    onClick: Function;
    slides: number[];
  };
};

type CarouselState = {
  index: number;
  direction: Direction;
  autoSlideActive: boolean;
};

export default class Carousel extends React.Component<
  CarouselProps,
  CarouselState
> {
  static defaultProps = {
    slides: [],
    autoSlide: false,
    showProgress: true,
    vertical: false,
    fullHeight: false,
    hideButton: false,
    showNav: false,
  };
  private directions: Array<Direction> = !!this.props.vertical
    ? ["down", "up"]
    : ["left", "right"];
  public state: CarouselState = {
    index: 0,
    direction: this.directions[0],
    autoSlideActive: this.props.autoSlide || false,
  };
  private timerID: any;

  private changeIndexWithinSlides = (newIndex: number) => {
    const lastSlideIndex = this.props.children.length - 1;
    return newIndex < 0
      ? lastSlideIndex
      : newIndex > lastSlideIndex
      ? 0
      : newIndex;
  };

  private slide = (direction: Direction, newIndex?: number) =>
    this.setState((prevState: CarouselState) => {
      if (newIndex === undefined) {
        newIndex =
          direction === this.directions[0]
            ? prevState.index - 1
            : prevState.index + 1;
      }
      return {
        index: this.changeIndexWithinSlides(newIndex),
        direction,
      };
    });

  private toggleAutoSlide = autoSlideActive =>
    this.setState((prevState: CarouselState) => ({
      autoSlideActive:
        autoSlideActive !== undefined ? autoSlideActive : prevState,
    }));

  private getDirectionClass = (itemIndex: number): Direction => {
    if (
      this.state.index === 0 &&
      itemIndex === this.props.children.length - 1
    ) {
      return this.props.vertical ? "down" : "left";
    } else if (
      this.state.index === this.props.children.length - 1 &&
      itemIndex === 0
    ) {
      return this.props.vertical ? "up" : "right";
    } else if (this.state.index > itemIndex) {
      return this.props.vertical ? "down" : "left";
    } else {
      return this.props.vertical ? "up" : "right";
    }
  };

  componentDidMount() {
    if (this.props.autoSlide) {
      this.timerID = setInterval(
        () =>
          this.state.autoSlideActive ? this.slide(this.directions[1]) : {},
        this.props.slideTime || 5000
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    const { props, state } = this;
    return (
      <div
        className={`Carousel
					${props.fullHeight ? " h-full " : " "}
					${props?.click?.slides?.includes(state.index) ? " Carousel--clickable " : " "}`}
        onMouseOver={_ => this.toggleAutoSlide(false)}
        onMouseLeave={_ => this.toggleAutoSlide(true)}>
        <div className='Carousel__SlideContainer'>
          {React.Children.map(props.children, (slide: any, i: number) => (
            <div
              className={`Carousel__Slide ${
                state.index === i
                  ? "Carousel__Slide--show"
                  : `Carousel__Slide--hide_${this.getDirectionClass(i)}`
              }`}
              key={i}
              onClick={_ => props.click && props.click.onClick(state.index)}>
              <div>{slide}</div>
            </div>
          ))}
        </div>
        {props.children.length > 1 && (
          <>
            {props.showProgress && (
              <div className='Carousel__Progress'>
                {state.index + 1} / {props.children.length}
              </div>
            )}
            {!props.hideButton && (
              <>
                <SlideButton
                  {...{
                    direction: this.directions[0],
                    onClick: () => this.slide(this.directions[0]),
                  }}
                />
                <SlideButton
                  {...{
                    direction: this.directions[1],
                    onClick: () => this.slide(this.directions[1]),
                  }}
                />
              </>
            )}
            {!!props.showNav && (
              <CarouselNav
                {...{
                  currentIndex: state.index,
                  vertical: props.vertical,
                  items: props.children,
                  onClick: i => this.slide(this.directions[0], i),
                }}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

const SlideButton: React.FunctionComponent<{ direction; onClick }> = ({
  direction,
  onClick,
}) => {
  return (
    <div
      className={`Carousel__SlideButton ${
        direction ? "Carousel__SlideButton--" + direction : ""
      }`}
      onClick={_ => onClick(direction)}>
      <TailwindIcon
        name={`keyboard_arrow_${direction}`}
        fontSize={"text-3xl"}
      />
    </div>
  );
};

const CarouselNav: React.FunctionComponent<{
  vertical;
  currentIndex;
  items;
  onClick;
}> = ({ vertical, currentIndex, items, onClick }) => {
  return (
    <div
      className={`Carousel__NavButtons ${
        vertical ? "Carousel__NavButtons--vertical" : ""
      }`}>
      {items.map((_, i: number) => (
        <span
          key={i}
          className={`Carousel__NavButton ${
            currentIndex === i ? "ChosenNavButton--" : ""
          }`}
          onClick={_ => onClick(i)}></span>
      ))}
    </div>
  );
};
