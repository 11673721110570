import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import "./index.css";
import config from "core/config";
import { SENTRY_IGNORED_ERRORS } from "core/config/sentry";
import App from "./App";

const { sentryEnv, sentryDsn, sentryRelease } = config!;

Sentry.init({
    dsn: sentryDsn,
    release: sentryRelease,
    enabled: true,
    shutdownTimeout: 3000,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: sentryEnv !== 'production' ? 1.0 : 0.1,
    ignoreErrors: SENTRY_IGNORED_ERRORS,
    debug: sentryEnv !== 'production',
    beforeSend(event) {
      if (event.exception && event.exception.values) {
        const message = event.exception.values[0].value;
        if (SENTRY_IGNORED_ERRORS.some(ignoredError => message!.includes(ignoredError))) {
          return null;
        }
      }
      return event;
    }
  });

ReactDOM.render(<App />, document.getElementById("root"));
