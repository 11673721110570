import React, { useCallback, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classnames from "classnames";
import { AppRoute, RouteSidebarOptions } from "core/stores/route/types";
import "./styles.scss";
import NavigationItemDropdown from "./navigation-item-dropdown";
import NavigationItem from "./index";
import { inject, observer } from "mobx-react";
import RouteStore from "core/stores/route/RouteStore";

type Props = {
  routeStore?: RouteStore;
  route: AppRoute;
  sidebarOptions?: RouteSidebarOptions;
  className?: string;
  activeClassName?: string;
  onClick: (
    event: React.MouseEvent<HTMLElement> | undefined,
    route: AppRoute,
    isClickingFromSubRoute?: boolean
  ) => void;
  capitalize?: boolean;
  hideHoverBackgroundGradient: boolean;
  hasDropdownMenu: boolean;
  isDropdownExpanded: boolean;
  getNavigationItemIcon: (route: AppRoute) => string | JSX.Element | undefined;
  getNavigationItemLabel: (
    route: AppRoute
  ) => string | ((...args: any[]) => string) | undefined;
  isLast?: boolean;
  preventDefault?: boolean;
};

const NavigationItemContainer: React.ComponentType<Props> = ({
  routeStore,
  route,
  sidebarOptions,
  className,
  activeClassName,
  onClick,
  capitalize,
  hideHoverBackgroundGradient,
  hasDropdownMenu,
  isDropdownExpanded,
  getNavigationItemIcon,
  getNavigationItemLabel,
  isLast,
  preventDefault,
  children,
}) => {
  const { getSiteRouteURL } = routeStore!;
  const url = useMemo(() => getSiteRouteURL(route.key, true), [route.key]);
  const isActive = useLocation().pathname === url;
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const _className = useMemo(
    () =>
      classnames(
        "NavigationItem",
        `NavigationItem__${route?.key}`,
        isActive && "bg-white",
        !hideHoverBackgroundGradient && (isActive || isHovering),
        sidebarOptions?.hoverBackgroundGradient !== undefined &&
          `NavigationItem--background-gradient-${sidebarOptions?.hoverBackgroundGradient}`,
        hasDropdownMenu && "cursor-pointer",
        hasDropdownMenu && isDropdownExpanded && "NavigationItem--expanded",
        hasDropdownMenu && "NavigationItem--has-dropdown",
        capitalize && "NavigationItem--capitalize",
        isLast && "NavigationItem--last",
        className && className,
        "cursor-pointer"
      ),
    [
      className,
      capitalize,
      sidebarOptions,
      isActive,
      isLast,
      isHovering,
      isDropdownExpanded,
      hasDropdownMenu,
      hideHoverBackgroundGradient,
    ]
  );

  const _activeClassName = useMemo(
    () =>
      classnames(
        "ActiveNavigationItem",
        sidebarOptions?.hoverBackgroundGradient !== undefined &&
          !hideHoverBackgroundGradient &&
          `NavigationItem--background-gradient-${sidebarOptions?.hoverBackgroundGradient}`,
        activeClassName && activeClassName
      ),
    [
      activeClassName,
      capitalize,
      sidebarOptions,
      hideHoverBackgroundGradient,
      isDropdownExpanded,
    ]
  );

  const onMouseEnter = useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, []);

  const onClickSubItem = useCallback(
    (event: React.MouseEvent<HTMLElement> | undefined, route: AppRoute) => {
      onClick(event, route, true);
    },
    [onClick]
  );

  if (
    route?.link &&
    !Array.isArray(sidebarOptions?.action?.item) &&
    !preventDefault
  ) {
    return (
      <NavLink
        to={url}
        activeClassName={_activeClassName}
        onClick={event => onClick(event, route, true)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}>
        <div className={_className}>{children}</div>
        {hasDropdownMenu && (
          <NavigationItemDropdown isExpanded={isDropdownExpanded}>
            {Array.isArray(sidebarOptions?.action?.item) &&
              sidebarOptions?.action?.item.map((route, index) => (
                <NavigationItem
                  key={`navigation-dropdown-item-${route.key}`}
                  getNavigationItemLabel={getNavigationItemLabel}
                  getNavigationItemIcon={getNavigationItemIcon}
                  isDropdownExpanded={isDropdownExpanded}
                  route={route}
                  sidebarOptions={{
                    ...route.sidebarOptions,
                    icon: getNavigationItemIcon(route),
                    label: getNavigationItemLabel(route),
                  }}
                  preventDefault={!route.link}
                  onClick={(event, route) => onClickSubItem(event, route)}
                  isLast={
                    index === (sidebarOptions?.action?.item || []).length - 1
                  }
                />
              ))}
          </NavigationItemDropdown>
        )}
      </NavLink>
    );
  }

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={event => onClick(event, route, false)}>
      <div
        className={classnames(
          _className,
          (isActive || isDropdownExpanded) && _activeClassName
        )}>
        {children}
      </div>
      {hasDropdownMenu && (
        <NavigationItemDropdown isExpanded={isDropdownExpanded}>
          {Array.isArray(sidebarOptions?.action?.item) &&
            sidebarOptions?.action?.item.map((route, index) => (
              <NavigationItem
                key={`navigation-dropdown-item-${route.key}`}
                route={route}
                getNavigationItemLabel={getNavigationItemLabel}
                getNavigationItemIcon={getNavigationItemIcon}
                isDropdownExpanded={isDropdownExpanded}
                sidebarOptions={
                  route.sidebarOptions
                    ? {
                        ...route.sidebarOptions,
                        icon: getNavigationItemIcon(route) || "",
                        label: getNavigationItemLabel(route) || "",
                      }
                    : undefined
                }
                preventDefault={!route.link}
                onClick={(event, route) => onClickSubItem(event, route)}
                isLast={
                  index === (sidebarOptions?.action?.item || []).length - 1
                }
              />
            ))}
        </NavigationItemDropdown>
      )}
    </div>
  );
};

export default inject("routeStore")(observer(NavigationItemContainer));
