import React from "react";
import { Router } from "react-router";
import "./App.scss";
import { Provider } from "mobx-react";
import { history } from "./core/utility";
import { IntlProvider } from "react-intl";
import { CssBaseline } from "@material-ui/core";
import Snackbar from "./components/snackbar";
import { stores } from "./core/stores";
import { languageStore } from "./core/stores/language/LanguageStore";
import TailwindFlex from "./components/_tailwind/flex";
import StorybookSidebar from "./storybook/_internal/sidebar";
import StorybookBody from "./storybook/_internal/body";

type AppProps = {};

const App: React.FunctionComponent<AppProps> = () => {
  return (
    <Provider {...stores}>
      <Router history={history}>
        <IntlProvider
          locale={languageStore.language}
          messages={languageStore.messages}>
          <CssBaseline />
          <Snackbar />
          <TailwindFlex height={"h-full"}>
            <StorybookSidebar />
            <StorybookBody />
          </TailwindFlex>
        </IntlProvider>
      </Router>
    </Provider>
  );
};

export default App;
