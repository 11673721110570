import React from "react";
import "./styles.scss";
import classnames from "classnames";

type FlirtIconProps = {
  className?: string;
};

const FlirtIcon: React.ComponentType<FlirtIconProps> = ({ className }) => (
  <span className={classnames("FlirtIcon", className && className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 604 604"
      x="0px"
      y="0px"
      xmlSpace="preserve"
    >
      <g transform="translate(1, 1)">
        <g transform="translate(1, 1)">
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html:
                "\n\t.st0_1606882146761{display:none;}\n\t.st1_1606882146761{display:inline;fill:#FFFFFF;}\n\t.st2_1606882146761{display:inline;fill:none;stroke:#FFFFFF;stroke-width:10;stroke-miterlimit:10;}\n\t.st3_1606882146761{display:inline;fill:none;stroke:#FFFFFF;stroke-width:18;stroke-linecap:round;stroke-miterlimit:10;}\n\t.st4_1606882146761{display:inline;fill:none;stroke:#FFFFFF;stroke-width:24;stroke-linecap:round;stroke-miterlimit:10;}\n\t.st5_1606882146761{display:inline;}\n",
            }}
          />
          <g id="Layer_1_1606882146761">
            <g>
              <path
                d="M300,60C167.45,60,60,167.45,60,300s107.45,240,240,240s240-107.45,240-240S432.55,60,300,60z M135,265    c0-33.14,26.86-60,60-60s60,26.86,60,60s-26.86,60-60,60S135,298.14,135,265z M302.84,472c-51.63,0-100.61-23.4-131.04-62.6    c-4.06-5.24-3.11-12.77,2.12-16.84c5.23-4.06,12.77-3.11,16.84,2.12C216.68,428.07,258.58,448,302.84,448    c76.09,0,138-57.42,138-128c0-6.63,5.37-12,12-12s12,5.37,12,12C464.84,403.81,392.17,472,302.84,472z M468.33,210.15    c-3.01,5.91-10.23,8.26-16.14,5.25c-23.44-11.94-51.93-9.83-74.35,5.51c-20.13,13.77-30.36,34.65-28.09,57.31    c0.66,6.59-4.15,12.48-10.74,13.14c-0.41,0.04-0.81,0.06-1.21,0.06c-6.09,0-11.3-4.62-11.93-10.8    c-3.13-31.19,11.23-60.92,38.42-79.51c29.67-20.3,67.53-23.01,98.79-7.09C468.99,197.02,471.34,204.24,468.33,210.15z"
                vectorEffect="non-scaling-stroke"
              />
            </g>
          </g>
          <g id="Layer_2_1606882146761" className="st0_1606882146761">
            <circle
              className="st1_1606882146761"
              cx={195}
              cy={265}
              r={60}
              vectorEffect="non-scaling-stroke"
            />
            <path
              className="st2_1606882146761"
              d="M353.67-67.77c-8.18-30.55,9.94-61.94,40.49-70.13c30.55-8.18,61.94,9.94,70.13,40.49"
              vectorEffect="non-scaling-stroke"
            />
            <path
              className="st3_1606882146761"
              d="M452.84,320c0,77.32-67.16,140-150,140c-50.01,0-94.3-22.84-121.56-57.96"
              vectorEffect="non-scaling-stroke"
            />
            <path
              className="st3_1606882146761"
              d="M342,285c-12-60,54.19-108.67,112-86"
              vectorEffect="non-scaling-stroke"
            />
          </g>
          <g id="_x32_4_1606882146761" className="st0_1606882146761">
            <circle
              className="st1_1606882146761"
              cx={195}
              cy={265}
              r={60}
              vectorEffect="non-scaling-stroke"
            />
            <path
              className="st2_1606882146761"
              d="M353.67-67.77c-8.18-30.55,9.94-61.94,40.49-70.13c30.55-8.18,61.94,9.94,70.13,40.49"
              vectorEffect="non-scaling-stroke"
            />
            <path
              className="st4_1606882146761"
              d="M452.84,320c0,77.32-67.16,140-150,140c-50.01,0-94.3-22.84-121.56-57.96"
              vectorEffect="non-scaling-stroke"
            />
            <path
              className="st4_1606882146761"
              d="M337.81,279.41c-6.11-60.88,64.49-102.89,119.83-74.71"
              vectorEffect="non-scaling-stroke"
            />
          </g>
          <g id="_x32_4_copy_1606882146761"></g>
          <g id="Layer_3_1606882146761" className="st0_1606882146761">
            <path
              className="st5_1606882146761"
              d="M300,60C167.45,60,60,167.45,60,300s107.45,240,240,240s240-107.45,240-240S432.55,60,300,60z M135,265   c0-33.14,26.86-60,60-60s60,26.86,60,60s-26.86,60-60,60S135,298.14,135,265z M302.84,469c-50.71,0-98.81-22.97-128.67-61.44   c-3.05-3.93-2.34-9.58,1.59-12.63c3.93-3.05,9.58-2.34,12.63,1.59C214.87,430.64,257.66,451,302.84,451c77.75,0,141-58.77,141-131   c0-4.97,4.03-9,9-9s9,4.03,9,9C461.84,402.16,390.52,469,302.84,469z M462.38,202.29c-1.81,4.63-7.04,6.91-11.67,5.09   c-25.51-10-54.82-4.95-76.49,13.2c-19.27,16.14-28.02,39.56-23.4,62.65c0.97,4.87-2.19,9.62-7.06,10.59   c-0.59,0.12-1.19,0.18-1.77,0.18c-4.2,0-7.96-2.96-8.82-7.24c-5.93-29.65,5.1-59.55,29.5-79.98   c26.72-22.38,62.97-28.57,94.62-16.16C461.91,192.44,464.19,197.66,462.38,202.29z"
              vectorEffect="non-scaling-stroke"
            />
          </g>
        </g>
      </g>
    </svg>
  </span>
);

export default FlirtIcon;
