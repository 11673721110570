import React, { useCallback, useEffect, useMemo } from "react";
import { inject, observer } from "mobx-react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindHeading from "library/components/_tailwind/heading";
import PayoutSettingsForm from "./payout-settings-form-wrapper";
import { injectIntl, WrappedComponentProps } from "react-intl";
import PayoutStore from "./stores/payout/PayoutStore";
import TailwindDropdown, {
  TailwindDropdownValue,
} from "library/components/_tailwind/dropdown";
import TailwindParagraph from "library/components/_tailwind/paragraph";
import { CAMS_MODEL_PAYOUT_METHOD_KEY } from "./stores/payout/enums";
import TailwindCheckboxRadio from "library/components/_tailwind/checkbox-radio";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import AuthStore from "core/stores/auth/AuthStore";
import Showcase from "library/components/_tailwind/showcase";
import ShowcaseStore from "core/stores/showcase/ShowcaseStore";
import { AppCommonRouteKey } from "core/stores/route/enums";
import { SHOWCASE_TARGET_CLASSNAMES } from "core/stores/showcase/const";

type CamsPayoutContainerProps = {
  payoutStore?: PayoutStore;
  authStore?: AuthStore;
  showcaseStore?: ShowcaseStore;
};

const CamsPayoutContainer: React.ComponentType<
  CamsPayoutContainerProps & WrappedComponentProps
> = ({ payoutStore, authStore, showcaseStore }) => {
  const {
    getWelcomeShowcaseSteps,
    nextWelcomeShowcasePage,
    playingShowcase,
    disableShowcase,
  } = showcaseStore!;
  const { userRole, countries } = authStore!;

  const {
    getPayoutMethods,
    selectedPaymentMethod,
    setSelectedPaymentCountry,
    setSelectedPaymentMethod,
    availableMethods,
    selectedPaymentCountry,
    toggleHasModelsLocatedInUsa,
    activePayoutSetting,
    shouldDisableWireTransferFormFields,
  } = payoutStore!;

  useEffect(() => {
    getPayoutMethods();
  }, []);

  const onChangeHasModelsLocatedInUsa = useCallback(() => {
    toggleHasModelsLocatedInUsa();
  }, []);

  const onChangeSelectedPaymentCountry = useCallback(
    (item: TailwindDropdownValue) => {
      setSelectedPaymentCountry(item as string);
    },
    []
  );

  const onChangeSelectedPaymentMethod = useCallback(
    (item: TailwindDropdownValue) => {
      setSelectedPaymentMethod(item as CAMS_MODEL_PAYOUT_METHOD_KEY);
    },
    []
  );

  const shouldDisableRadioButtons = useMemo(
    () =>
      shouldDisableWireTransferFormFields &&
      selectedPaymentMethod === CAMS_MODEL_PAYOUT_METHOD_KEY.wired_transfer,
    [selectedPaymentMethod, shouldDisableWireTransferFormFields]
  );

  return (
    <TailwindFlex
      flexDirection={"flex-col"}
      height={"h-full"}
      pseudoClasses={["md:h-auto"]}
      padding={["px-2"]}
      className={["Payout"]}>
      {playingShowcase && (
        <Showcase
          steps={getWelcomeShowcaseSteps(AppCommonRouteKey.payout)}
          onFinish={nextWelcomeShowcasePage}
          enableDoneButton={true}
          disableOverlay={true}
          showSkipButton={true}
          onSkipped={() => {
            disableShowcase();
          }}
        />
      )}
      <TailwindFlex margin={["mb-4"]}>
        <TailwindFlex width={"w-auto"}>
          <TailwindHeading
            level={5}
            className={SHOWCASE_TARGET_CLASSNAMES.payoutTitle}>
            Payment Options
          </TailwindHeading>
        </TailwindFlex>
      </TailwindFlex>
      <TailwindFlex flexDirection={"flex-col"} alignItems={"items-center"}>
        <TailwindFlex margin={["mb-4"]}>
          <TailwindParagraph>
            We offer multiple ways for you to get paid. For fastest payment, we
            recommend Wire Transfer.
          </TailwindParagraph>
        </TailwindFlex>
        <TailwindFlex
          flexDirection={"flex-row-reverse"}
          pseudoClasses={["md:flex-col"]}>
          <TailwindFlex
            flexDirection={"flex-col"}
            margin={["ml-4"]}
            pseudoClasses={["md:ml-0"]}>
            <TailwindFlex width={"w-auto"} margin={["mb-2"]}>
              <TailwindDropdown
                labelPosition={"left"}
                label={"Payment Country"}
                backgroundColor={"primary"}
                name={"payout-country"}
                items={Object.values(countries).map((label: string) => ({
                  label: label,
                  value: label,
                }))}
                value={selectedPaymentCountry}
                width={"w-72"}
                fullWidth={false}
                onChange={onChangeSelectedPaymentCountry}
                placeholder={"Select payment country"}
              />
            </TailwindFlex>
            <TailwindFlex width={"w-auto"} margin={["mb-4"]}>
              <TailwindDropdown
                labelPosition={"left"}
                label={"Payment Method"}
                backgroundColor={"primary"}
                name={"payout-method"}
                items={availableMethods}
                value={selectedPaymentMethod}
                onChange={onChangeSelectedPaymentMethod}
                width={"w-72"}
                fullWidth={false}
                disabled={selectedPaymentCountry === null}
                placeholder={
                  selectedPaymentCountry === null
                    ? "Select payment country first"
                    : "Select payment method"
                }
              />
            </TailwindFlex>
          </TailwindFlex>
          <TailwindFlex flexDirection={"flex-col"}>
            {userRole === "studio" &&
              selectedPaymentCountry !== "United States" && (
                <TailwindFlex margin={["mb-4"]} flexDirection={"flex-col"}>
                  <TailwindFlex margin={["mb-2"]} width={"w-full"}>
                    <TailwindCheckboxRadio
                      borderColor={"border-black"}
                      type={TailwindInputType.radio}
                      disabled={shouldDisableRadioButtons}
                      isChecked={
                        activePayoutSetting?.has_models_located_in_usa === false
                      }
                      onChange={onChangeHasModelsLocatedInUsa}
                      label={
                        "My studio and models are located outside the United States"
                      }
                    />
                  </TailwindFlex>
                  <TailwindFlex />
                  <TailwindFlex width={"w-full"}>
                    <TailwindCheckboxRadio
                      borderColor={"border-black"}
                      type={TailwindInputType.radio}
                      disabled={shouldDisableRadioButtons}
                      isChecked={
                        activePayoutSetting?.has_models_located_in_usa === true
                      }
                      onChange={onChangeHasModelsLocatedInUsa}
                      label={
                        "For foreign Studios with model(s) broadcasting in the United States"
                      }
                    />
                  </TailwindFlex>
                </TailwindFlex>
              )}
            <TailwindFlex
              overflow={["overflow-hidden"]}
              flexGrow={"flex-grow"}
              justifyContent={"justify-center"}>
              <PayoutSettingsForm />
            </TailwindFlex>
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject(
    "payoutStore",
    "authStore",
    "showcaseStore"
  )(observer(CamsPayoutContainer))
);
