import React from "react";
import TailwindFlex from "../flex";
import Avatar from "../../avatar";
import TailwindText from "../text";
import ProfileInfo from "../../profile/info";
import TailwindTranslatedDate from "../translated-date";

export type FeedHeaderProfileDetailsProps = {
  profile: any;
  date: Date;
  description?: string;
};

const FeedHeaderProfileDetails: React.ComponentType<FeedHeaderProfileDetailsProps> = ({
  profile,
  date,
  description,
}) => {
  return (
    <TailwindFlex flexDirection={"flex-col"} padding={["py-2", "px-3"]}>
      <TailwindFlex>
        <Avatar photoURL={profile.profilePhoto} borderSize={"none"} />
        <TailwindFlex
          width={"w-auto"}
          flexDirection={"flex-col"}
          margin={["ml-2"]}
        >
          <TailwindFlex alignItems={"items-center"}>
            <TailwindText textColor={"text-gray-400"}>by</TailwindText>&nbsp;
            <ProfileInfo {...profile} />
          </TailwindFlex>
          <TailwindTranslatedDate
            textProps={{
              textColor: "text-gray-400",
            }}
            value={date}
            year="numeric"
            month="long"
            day="2-digit"
          />
        </TailwindFlex>
      </TailwindFlex>
      {description && (
        <TailwindText margin={["mt-2"]}>{description}</TailwindText>
      )}
    </TailwindFlex>
  );
};

export default FeedHeaderProfileDetails;
