import React, { useCallback, useEffect, useState } from "react";
import StudioModelsAccessRightsStore from "containers/studio/studio-models/stores/studio-models-access-rights/StudioModelsAccessRightsStore";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import { Icon, IconButton } from "@material-ui/core";
import ChatStore from "../_stores/chat/ChatStore";
import { BROADCAST_CHAT_INPUT_MAX_CHARS } from "./chat-tab";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import { IQuickMessage } from "../_stores/broadcast/interfaces";
import { IQuickMessageStatuses } from "../_stores/broadcast/enums";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import StudioModelAccessRights from "../../studio-models-access-rights";
import { SHOWCASE_TARGET_CLASSNAMES } from "core/stores/showcase/const";
import { inject, observer } from "mobx-react";

type Props = {
  studioModelsAccessRightsStore?: StudioModelsAccessRightsStore;
  chatStore?: ChatStore;
  broadcastStore?: BroadcastStore;
  sendQuickReply: (message: IQuickMessage) => void;
  showLimitMessage: boolean;
  setShowLimitMessage: (isOverLimit: boolean) => void;
  isMaxChars: boolean;
  setIsMaxChars: (isOverMaxChars: boolean) => void;
};

const BroadcastChatQuickText: React.ComponentType<Props> = ({
  showLimitMessage,
  isMaxChars,
  setIsMaxChars,
  sendQuickReply,
  setShowLimitMessage,
  studioModelsAccessRightsStore,
  broadcastStore,
}) => {
  const { getQuickMessages, quickMessages, deleteQuickMessage } =
    broadcastStore!;

  const MESSAGES_LIMIT: number = 10;
  const [editingId, setEditingId] = useState("");
  const [editedTexts, setEditedTexts] = useState(new Map());
  const [quickReplyOpen, setQuickReplyOpen] = useState(false);

  const toggleQuickReply = useCallback(() => {
    setQuickReplyOpen(!quickReplyOpen);
  }, [quickReplyOpen]);

  const closeLimitMessage = event => {
    event.stopPropagation();
    setShowLimitMessage(false);
  };

  useEffect(() => {
    getQuickMessages().then(messages => {
      if (messages) {
        setShowLimitMessage(messages!.length >= MESSAGES_LIMIT);
      }
    });
  }, []);

  const handleEditedInput = (event, id) => {
    event.stopPropagation();
    const newEditedTexts = new Map(editedTexts);
    newEditedTexts[id] = event.target.value;
    setEditedTexts(newEditedTexts);
    const isMax = event.target.value.length > BROADCAST_CHAT_INPUT_MAX_CHARS;
    setIsMaxChars(isMax);
  };

  const handleCloseEditing = (event, id) => {
    event.stopPropagation();
    setEditingId("");
    editedTexts.delete(id);
    setEditedTexts(editedTexts);
    setIsMaxChars(false);
  };

  const editingIdHandler = useCallback(
    (event, id: string) => {
      event.stopPropagation();
      const message = quickMessages.find(x => x.id === id);
      setEditingId(id);
      const newEditedTexts = new Map(editedTexts);
      newEditedTexts[id] = message!.message;
      setEditedTexts(newEditedTexts);
    },
    [setEditingId, editedTexts, broadcastStore, quickMessages]
  );

  const deleteHandler = useCallback((event, id: string) => {
    event.stopPropagation();
    deleteQuickMessage(id);
  }, []);

  const handleSendQuickReply = quickReply => {
    if (quickReply.status !== IQuickMessageStatuses.pending) {
      sendQuickReply(quickReply);
    }
  };

  const putQuickMessageHandler = (event, id: string) => {
    event.stopPropagation();
    if (isMaxChars) {
      return;
    }

    const newMessages = [...broadcastStore!.quickMessages];
    newMessages.find(x => x.id === id)!.message = editedTexts[id];
    broadcastStore!.quickMessages = newMessages;

    const message = broadcastStore!.quickMessages.find(x => x.id === id);
    if (message) {
      broadcastStore!.putQuickMessage(message);
      setEditingId("");
      setShowLimitMessage(
        broadcastStore!.quickMessages!.length >= MESSAGES_LIMIT
      );
    }
  };

  return (
    <StudioModelAccessRights
      shouldShow={studioModelsAccessRightsStore!.isQuickTextChangesAllowed}
      hideContent>
      <TailwindFlex
        className={["ChatTab__quick-text"]}
        height={quickReplyOpen ? "h-96" : "h-12"}
        alignItems={"items-center"}
        display={"hidden"}
        borderColor={"border-gray-300"}
        borderWidth={["border-b"]}
        borderRadius={"rounded-t-xl"}
        backgroundColor={"bg-body-bg-color"}
        flexDirection={"flex-col"}>
        <TailwindFlex
          alignItems={"items-center"}
          justifyContent={"justify-center"}
          padding={["px-4"]}>
          <TailwindButton
            rightIconProps={{
              name: quickReplyOpen
                ? "keyboard_arrow_down"
                : "keyboard_arrow_up",
              fontSize: "text-sm",
              textColor: "text-gray-500",
            }}
            padding={["p-0"]}
            fullWidth={false}
            onClick={toggleQuickReply}>
            <TailwindTranslatedText
              className={SHOWCASE_TARGET_CLASSNAMES.quickReplyTitle}
              fontSize={"text-sm"}
              textColor={"text-gray-500"}
              descriptor={{
                id: "broadcast.quick",
                defaultMessage: "Quick Reply",
              }}
            />
          </TailwindButton>
        </TailwindFlex>
        {quickReplyOpen && (
          <TailwindFlex flexDirection={"flex-col"} overflow={"overflow-hidden"}>
            {showLimitMessage && (
              <div className={"ChatTab__quick-reply__error"}>
                <TailwindTranslatedText
                  descriptor={{
                    id: "broadcast.quickReplies.limit",
                    defaultMessage: `You can save up to ${MESSAGES_LIMIT} Quick Replies`,
                  }}
                  values={{ limit: MESSAGES_LIMIT }}
                />
                <IconButton
                  onClick={event => closeLimitMessage(event)}
                  className={"ChatTab__quick-icon__error"}>
                  <Icon>close</Icon>
                </IconButton>
              </div>
            )}
            <TailwindFlex
              flexDirection={"flex-col"}
              overflow={"overflow-y-scroll"}>
              {quickMessages.map(
                quickReply =>
                  (editingId !== quickReply.id && (
                    <div key={quickReply.id} className={"ChatTab__quick-reply"}>
                      <div
                        onClick={() => handleSendQuickReply(quickReply)}
                        className={`ChatTab__quick-content ${
                          quickReply.status === IQuickMessageStatuses.pending
                            ? "pointer-events-none"
                            : ""
                        }`}>
                        {quickReply.status ===
                          IQuickMessageStatuses.pending && (
                          <TailwindTranslatedText
                            verticalAlign={"align-middle"}
                            borderRadius={"rounded-full"}
                            margin={["mr-2"]}
                            whiteSpace={"whitespace-nowrap"}
                            padding={["p-1"]}
                            backgroundColor={"bg-yellow-200"}
                            fontSize={"text-xs"}
                            descriptor={{
                              id: "verbiage.pending-review",
                              defaultMessage: "Pending Review",
                            }}
                          />
                        )}
                        <span
                          className={`${
                            quickReply.status === IQuickMessageStatuses.pending
                              ? "opacity-50 pointer-events-none"
                              : ""
                          }`}>
                          {quickReply.message}
                        </span>
                      </div>
                      <div>
                        <IconButton
                          onClick={event =>
                            editingIdHandler(event, quickReply.id)
                          }
                          className={"ChatTab__quick-icon"}>
                          <Icon>edit</Icon>
                        </IconButton>
                        <IconButton
                          className={"ChatTab__quick-icon"}
                          onClick={event =>
                            deleteHandler(event, quickReply.id)
                          }>
                          <Icon>delete</Icon>
                        </IconButton>
                      </div>
                    </div>
                  )) || (
                    <div key={quickReply.id}>
                      <div className={"ChatTab__quick-reply"}>
                        <input
                          type='text'
                          className={"ChatTab__input"}
                          value={editedTexts[quickReply.id]}
                          onChange={event =>
                            handleEditedInput(event, quickReply.id)
                          }
                          onClick={event => event.stopPropagation()}></input>

                        <div>
                          <IconButton
                            onClick={event =>
                              putQuickMessageHandler(event, quickReply.id)
                            }
                            className={"ChatTab__quick-icon"}>
                            <Icon>check</Icon>
                          </IconButton>
                          <IconButton
                            className={"ChatTab__quick-icon"}
                            onClick={event =>
                              handleCloseEditing(event, quickReply.id)
                            }>
                            <Icon>close</Icon>
                          </IconButton>
                        </div>
                      </div>
                      {/*{isBannedWord && (
                        <p className={"ChatTab__quick-reply__banned"}>
                          <TailwindTranslatedText
                            descriptor={{
                              id: "broadcast.quickReplies.banned",
                              defaultMessage: "Banned Word",
                            }}
                          />
                        </p>
                      )}*/}
                      {isMaxChars && (
                        <p className={"ChatTab__quick-reply__banned"}>
                          <TailwindTranslatedText
                            descriptor={{
                              id: "broadcast.quickReplies.maxChars",
                              defaultMessage:
                                "You reached maximum number of characters",
                            }}
                          />
                        </p>
                      )}
                    </div>
                  )
              )}
            </TailwindFlex>
          </TailwindFlex>
        )}
      </TailwindFlex>
    </StudioModelAccessRights>
  );
};

export default inject(
  "studioModelsAccessRightsStore",
  "chatStore",
  "broadcastStore"
)(observer(BroadcastChatQuickText));
