import React from "react";
import "./styles.scss";
import classnames from "classnames";

type GoldCrownIconProps = {
  className?: string;
  color?: "black" | "gold" | "none";
};

const GoldCrownIcon: React.ComponentType<GoldCrownIconProps> = ({
  className,
  color = "gold",
}) => (
  <span
    className={classnames(
      "GoldCrownIcon",
      `GoldCrownIcon--color-${color}`,
      className && className
    )}
  >
    <svg
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 34 34"
    >
      <g transform="translate(1, 1)">
        <g stroke="none">
          <path
            stroke="none"
            d="M21.5,23.5c1,0,1.8,0.8,1.8,1.8l0,0c0,1-0.8,1.8-1.8,1.8H10.6c-1,0-1.8-0.7-1.8-1.8l0,0c0-1,0.8-1.8,1.8-1.8H21.5zM26.8,8.2c-1.3,0-2.3,1-2.3,2c0,0.5,0.2,1,0.5,1.3l-3.2,3.2l-1.2-5.8c1.1-0.3,1.7-1,1.7-1.9c0-1.2-1.2-2.1-2.4-2.1c-1.4,0-2.4,1-2.4,2.1c0,0.6,0.3,1.3,1,1.7l-2.2,5.7H16l-2.3-5.7c0.7-0.3,1.2-1.1,1.2-1.7c0-1.2-1.2-2.1-2.4-2.1c-1.4,0-2.4,1-2.4,2.1c0,1.1,1,1.9,1.8,1.9l-1.4,5.8L7,11.4c0.4-0.6,0.5-0.8,0.5-1.2c0-1.1-1.1-2-2.3-2s-2.3,1-2.3,2s1.1,2,2.1,2l3.3,7.7c0,0.1,0.1,0.2,0.1,0.3v0.1l0,0c0.3,0.6,1,1.1,1.6,1.1h11.9c0.6,0,1.2-0.4,1.6-1.1l0,0v-0.1c0.1-0.1,0.1-0.2,0.1-0.3l3.4-7.7c1.2-0.1,2.1-1,2.1-2C29.2,9.1,28.1,8.2,26.8,8.2z"
          />
        </g>
      </g>
    </svg>
  </span>
);

export default GoldCrownIcon;
