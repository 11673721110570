import {
  TBackgroundColor,
  TBorderColor,
  TTextColor,
} from "../../../styles/tailwind-classnames";
import { TailwindCardBackgroundColor } from "../card";
import { ThemeColorPalette } from "../../../core/stores/theme/types";

export const getThemeColors = (
  backgroundColor?: TailwindCardBackgroundColor,
  currentThemePalette?: ThemeColorPalette,
  textColor?: TTextColor
) => {
  const colors: {
    backgroundColor?: TBackgroundColor;
    textColor: TTextColor;
    focusButtonBorderColor: TBorderColor;
    multipleCounterBackgroundColor: TBackgroundColor;
    multipleCounterTextColor: TTextColor;
    multipleCheckboxBorderColor?: TBorderColor;
  } = {
    backgroundColor: "bg-white",
    textColor: textColor || "text-main-color",
    focusButtonBorderColor: "border-black",
    multipleCounterBackgroundColor: "bg-black",
    multipleCounterTextColor: "text-white",
    multipleCheckboxBorderColor: undefined,
  };

  switch (backgroundColor as TailwindCardBackgroundColor) {
    case "primary": {
      const backgroundClass =
        currentThemePalette?.["dropdown-primary-bg-color-tw-class"];
      if (backgroundClass) {
        colors.backgroundColor = backgroundClass;
      }
      const textColorClass =
        currentThemePalette?.["dropdown-primary-text-color-tw-class"];
      if (textColorClass) {
        colors.textColor = textColorClass;
      }
      const focusButtonBorderColorClass =
        currentThemePalette?.[
        "dropdown-primary-focus-button-border-color-tw-class"
        ];
      if (focusButtonBorderColorClass) {
        colors.focusButtonBorderColor = focusButtonBorderColorClass;
      }
      const multipleCounterBackgroundColorClass =
        currentThemePalette?.[
        "dropdown-primary-multiple-counter-background-color-tw-class"
        ];
      if (multipleCounterBackgroundColorClass) {
        colors.multipleCounterBackgroundColor =
          multipleCounterBackgroundColorClass;
      }
      const multipleCounterTextColorClass =
        currentThemePalette?.[
        "dropdown-primary-multiple-counter-text-color-tw-class"
        ];
      if (multipleCounterTextColorClass) {
        colors.multipleCounterTextColor = multipleCounterTextColorClass;
      }
      const multipleCheckboxBorderColorClass =
        currentThemePalette?.[
        "dropdown-primary-multiple-checkbox-border-color-tw-class"
        ];
      if (multipleCheckboxBorderColorClass) {
        colors.multipleCheckboxBorderColor = multipleCheckboxBorderColorClass;
      }
      break;
    }
    case "secondary": {
      const backgroundClass =
        currentThemePalette?.["dropdown-secondary-bg-color-tw-class"];
      if (backgroundClass) {
        colors.backgroundColor = backgroundClass;
      }
      const textColorClass =
        currentThemePalette?.["dropdown-secondary-text-color-tw-class"];
      if (textColorClass) {
        colors.textColor = textColorClass;
      }
      const focusButtonBorderColorClass =
        currentThemePalette?.[
        "dropdown-secondary-focus-button-border-color-tw-class"
        ];
      if (focusButtonBorderColorClass) {
        colors.focusButtonBorderColor = focusButtonBorderColorClass;
      }
      const multipleCounterBackgroundColorClass =
        currentThemePalette?.[
        "dropdown-secondary-multiple-counter-background-color-tw-class"
        ];
      if (multipleCounterBackgroundColorClass) {
        colors.multipleCounterBackgroundColor =
          multipleCounterBackgroundColorClass;
      }
      const multipleCounterTextColorClass =
        currentThemePalette?.[
        "dropdown-secondary-multiple-counter-text-color-tw-class"
        ];
      if (multipleCounterTextColorClass) {
        colors.multipleCounterTextColor = multipleCounterTextColorClass;
      }
      const multipleCheckboxBorderColorClass =
        currentThemePalette?.[
        "dropdown-secondary-multiple-checkbox-border-color-tw-class"
        ];
      if (multipleCheckboxBorderColorClass) {
        colors.multipleCheckboxBorderColor = multipleCheckboxBorderColorClass;
      }
      break;
    }
    case "transparent": {
      colors.backgroundColor = "bg-transparent";
      break;
    }
    default: {
      colors.backgroundColor = backgroundColor as TBackgroundColor;
      break;
    }
  }
  return colors;
};
