import React, { FunctionComponent, useMemo } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import "./styles.scss";
import { openLoginModal, openRegisterModal } from "core/stores/auth/utils";
import TailwindButton from "library/components/_tailwind/button";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindBox from "library/components/_tailwind/box";
import TailwindAnchor from "library/components/_tailwind/anchor";

type LearnMoreContainerProps = {};

const LearnMoreContainer: FunctionComponent<
  LearnMoreContainerProps & WrappedComponentProps
> = ({ intl }) => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <div className={"LearnMore"}>
      <div className={"LearnMore__container"}>
        <div className='LearnMore__btn-container'>
          <h1 className={"LearnMore__title"}>How Models Get Paid</h1>
          <TailwindButton
            margin={["my-4"]}
            justifyContent={"justify-center"}
            backgroundColor={"primary"}
            rounded={false}
            size={"lg"}
            onClick={() => openRegisterModal()}
            display={"block"}
            position={"relative"}>
            Become a Model Now!
          </TailwindButton>
        </div>
        <h1 className={"LearnMore__subtitle"}>
          100% For Every Member you bring and more
        </h1>
        <p className={"LearnMore__description"}>
          <em>YOU TALKED, WE LISTENED...</em>We've worked hard to ensure models
          are paid regularly and rewarded in a way that motivates great
          performances. Models love our tiered payouts and bonus programs
          because they're simpler and more rewarding.
        </p>

        <TailwindFlex pseudoClasses={["sm:flex-col"]}>
          <TailwindBox pseudoClasses={["sm:w-full", "md:w-4/12", "md:mr-2"]}>
            <div className={"LearnMore__block"}>
              <img
                src='https://secureimage.securedataimages.com/images/cams/cams2/images/188x136_SimpleAndRewarding.svg'
                alt=''
              />
              <h3 className={"LearnMore__block-title"}>
                Simple and Rewarding...
              </h3>
              <div className={"LearnMore__block-text"}>
                <p>
                  You work hard to put on great performances, so you deserve a
                  program that is easy-to-use. We've nixed complex rate and fee
                  structures, as well as hours-based rewards. We now have one
                  tiered rate, so there will be no fees before payment
                  calculation and huge rewards for generating more cash!
                </p>
              </div>
            </div>
          </TailwindBox>
          <TailwindBox pseudoClasses={["xs:w-full", "sm:w-full", "md:w-4/12"]}>
            <div className={"LearnMore__block"}>
              <img
                src='https://secureimage.securedataimages.com/images/cams/cams2/images/100x100_100icon.svg'
                alt=''
              />
              <h3 className={"LearnMore__block-title"}>
                You Get 100% For New Members
              </h3>
              <div className={"LearnMore__block-text"}>
                <p>
                  You work hard to put on great performances, so you deserve a
                  program that is easy-to-use. We've nixed complex rate and fee
                  structures, as well as hours-based rewards. We now have one
                  tiered rate, so there will be no fees before payment
                  calculation and huge rewards for generating more cash!
                </p>
              </div>
            </div>
          </TailwindBox>
          <TailwindBox
            pseudoClasses={["xs:w-full", "sm:w-full", "md:w-4/12", "md:ml-2"]}>
            <div className={"LearnMore__block"}>
              <img
                src='https://secureimage.securedataimages.com/images/cams/cams2/images/100x100_PLus70.svg'
                alt=''
              />
              <h3 className={"LearnMore__block-title"}>
                Plus Up To 70% For Members We Bring You
              </h3>
              <div className={"LearnMore__block-text"}>
                <p>
                  You work hard to put on great performances, so you deserve a
                  program that is easy-to-use. We've nixed complex rate and fee
                  structures, as well as hours-based rewards. We now have one
                  tiered rate, so there will be no fees before payment
                  calculation and huge rewards for generating more cash!
                </p>
              </div>
            </div>
          </TailwindBox>
        </TailwindFlex>

        <section className={"LearnMore__table-container"}>
          <TailwindFlex pseudoClasses={["xs:flex-col"]}>
            <TailwindFlex
              pseudoClasses={["xs:w-full", "md:w-4/12"]}
              alignItems={"items-center"}
              justifyContent={"justify-center"}
              className={["LearnMore__image-grid"]}>
              <div className='LearnMore__table-image' />
            </TailwindFlex>
            <TailwindBox pseudoClasses={["xs:w-full", "md:w-8/12"]}>
              <table className={"LearnMore__tiertable"}>
                <thead>
                  <tr>
                    <th className={"LearnMore__tiertable-head"}>Tier</th>
                    <th className={"LearnMore__tiertable-head"}>
                      {" "}
                      Members Pay You Every Pay Period (2 Weeks)
                    </th>
                    <th
                      className={
                        "LearnMore__tiertable-head LearnMore__tiertable-head--padding"
                      }>
                      What You Earn (Model %)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>4</td>
                    <td>$10,001 +</td>
                    <td>70%</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>$6,001 - $10,000</td>
                    <td>60%</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>$5,001 - $6,000</td>
                    <td>50%</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>0 - $5,000</td>
                    <td>32%</td>
                  </tr>
                </tbody>
              </table>
              <TailwindFlex
                flexDirection={"flex-col"}
                alignItems={"items-center"}
                justifyContent={"justify-center"}
                margin={["mt-12"]}>
                <TailwindButton
                  fullWidth={false}
                  backgroundColor={"primary"}
                  rounded={false}
                  onClick={() => openRegisterModal()}>
                  Get Started to Become a Model Now!
                </TailwindButton>
                <div
                  className={"LearnMore__link text-main-color"}
                  onClick={() => openLoginModal()}>
                  Login as current model
                </div>
              </TailwindFlex>
            </TailwindBox>
          </TailwindFlex>
        </section>

        <section className='_layout-row _intro-text _layout-qa'>
          <h1 className='LearnMore__title'>
            What's Our Tiered Payout Program All About?
          </h1>
          <h2 className='LearnMore__subtitle'>100% Proof It's All About You</h2>
          <ul className='LearnMore__question-list'>
            <li>
              <h4 className={"LearnMore__question-title"}>
                <span className='LearnMore__question-icon'>Q</span>How does the
                tiered payout work for me? Give me a real example
              </h4>
              <p>
                Say members give you $12,000 in a pay period. A pay period is
                every two weeks. The example below shows how we would calculate
                your payment:
              </p>
              <br />
              <table className='LearnMore__tiertable'>
                <thead>
                  <tr>
                    <th className='LearnMore__tiertable-head LearnMore__tiertable-head--center'>
                      Dollars You Received
                    </th>
                    <th className='LearnMore__tiertable-head LearnMore__tiertable-head--center'>
                      % You’re Paid For Each Tier
                    </th>
                    <th className='LearnMore__tiertable-head LearnMore__tiertable-head--center'>
                      Your Earnings In Cash (USD)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>$10,001 - $12,000</td>
                    <td>70%</td>
                    <td>$1,400</td>
                  </tr>
                  <tr>
                    <td>$6,001 - $10,000</td>
                    <td>60%</td>
                    <td>$2,400</td>
                  </tr>
                  <tr>
                    <td>$5,001 - $6,000</td>
                    <td>50%</td>
                    <td>$500</td>
                  </tr>
                  <tr>
                    <td>0 - $5,000</td>
                    <td>32%</td>
                    <td>$1,600</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={2}>Total</td>
                    <td>$5,900</td>
                  </tr>
                </tfoot>
              </table>
              <div className='mt10'>
                Your first $5,000 will be paid out at 32%, everything after that
                (up to $6,000) is paid out at 50%, everything after that (up to
                $10,000) is paid out at 60%, and everything after that is paid
                out at 70%. We're removing any hidden fees so it’s based on the
                total your members contribute to you.
              </div>
            </li>
            <li>
              <h4 className={"LearnMore__question-title"}>
                <span className='LearnMore__question-icon'>Q</span>How exactly
                does the 100% payout for referrals work?
              </h4>
              <p>
                When you bring in new members through your custom member
                referral link, you'll earn 100% payout for the money they spend
                on you for 6 months and 80% payout thereafter for your Tips,
                Gifts, Private Chat, Party Chat, Nude Chat, Cam2Cam, Voyeur Chat
                and Fan Clubs. Note: Member spend for referral will not be
                calculated towards your tiered payout.
              </p>
            </li>
            <li>
              <h4 className={"LearnMore__question-title"}>
                <span className='LearnMore__question-icon'>Q</span>I heard the
                site changed from cash to tokens. Where can I get more
                information on tokens?
              </h4>
              <p>
                There were several reasons to change to tokens. The biggest
                reason was consistent feedback from our models that they were
                making more money when members didn’t feel like they were using
                real money. For more information on our tokens system,{" "}
                <a href='https://models.streamray.com/p/help.cgi?answer=504&amp;stream=1&amp;category=OTHER_FEATURES_MODELS'>
                  Click Here
                </a>
                .
              </p>
            </li>
            <li>
              <h4 className={"LearnMore__question-title"}>
                <span className='LearnMore__question-icon'>Q</span>Where can I
                get details on the bonus programs for models?
              </h4>
              <p>
                We’ve put together updated details on our Payout Schedule that
                gives you all the information you need on how models make money
                on the site, and how we calculate earnings.{" "}
                <TailwindAnchor to={"/percentage-payout"} openInNewTab>
                  Click Here
                </TailwindAnchor>{" "}
                for Payout Schedule details.
              </p>
            </li>
            <li>
              <h4 className={"LearnMore__question-title"}>
                <span className='LearnMore__question-icon'>Q</span>How do I sign
                up?
              </h4>
              <p>
                Signing up to become a model is just a click away!{" "}
                <a href='' onClick={() => openRegisterModal()}>
                  Click Here
                </a>
              </p>

              <TailwindBox margin={["my-3"]}>
                <TailwindFlex pseudoClasses={["sm:flex-col"]}>
                  <TailwindBox
                    margin={["mr-2"]}
                    pseudoClasses={[
                      "xs:w-full",
                      "sm:w-full",
                      "md:w-6/12",
                      "md:mr-1",
                      "sm:mr-0",
                      "sm:mb-1",
                    ]}>
                    <TailwindButton
                      borderRadius={["rounded-lg"]}
                      backgroundColor={"bg-yellow-500"}
                      textColor={"text-white"}
                      size={"lg"}
                      onClick={() => openLoginModal()}
                      fullWidth
                      justifyContent={"justify-center"}>
                      Login to My Dashboard Page
                    </TailwindButton>
                  </TailwindBox>
                  <TailwindBox
                    pseudoClasses={[
                      "xs:w-full",
                      "sm:w-full",
                      "md:w-6/12",
                      "md:ml-1",
                      "sm:ml-0",
                    ]}>
                    <TailwindButton
                      backgroundColor={"primary"}
                      borderRadius={["rounded-lg"]}
                      size={"lg"}
                      onClick={() => openRegisterModal()}
                      fullWidth
                      justifyContent={"justify-center"}>
                      Become a Model Now!
                    </TailwindButton>
                  </TailwindBox>
                </TailwindFlex>
              </TailwindBox>

              <div className='LearnMore__az-banner-box'>
                <div className='LearnMore__az-banner'>
                  <div className='LearnMore__testimonial'>
                    <blockquote>
                      <i>"</i>
                      <img
                        src='https://secureimage.securedataimages.com/images/cams/model_center/payouts/logo-cams-english.png'
                        width='225'
                        height='69'
                      />
                      <span>Cams.com</span> has always been known for the way
                      they support models. They're great, and really
                      professional." - AlenaCroft
                    </blockquote>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </section>
      </div>

      <footer className='Landing__footer'>
        <div className={"Landing__footer--copyright textCenter"}>
          <p>
            18 U.S.C. 2257{" "}
            {intl.formatMessage({
              id: "landing.recordKeeping",
              defaultMessage:
                "Record Keeping Requirements Compliance Statement",
            })}
          </p>
          <p>
            © 1999-{currentYear}{" "}
            {intl.formatMessage({
              id: "landing.streamrayInc",
              defaultMessage: "Streamray Inc. All Rights Reserved",
            })}
          </p>
        </div>
      </footer>
    </div>
  );
};

export default injectIntl(LearnMoreContainer);
