import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookComponentShowcase from "../_internal/component-showcase";
import Code from "../../components/_tailwind/code";
import TailwindAnchor from "../../components/_tailwind/anchor";
import TailwindTranslatedTime from "../../components/_tailwind/translated-time";

type Props = {};

const StorybookComponentsTranslatedTime: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; TranslatedTime</StorybookHeading>
      <StorybookParagraph>
        TranslatedTime component is a combination of primitive Text component as
        well as the react-intl. It makes it possible to format dates according
        to user's computer localization.
      </StorybookParagraph>
      <StorybookParagraph>
        Currently it takes all the props from TailwindText under{" "}
        <Code inline>textProps</Code> prop as well as the props from react-intl{" "}
        <TailwindAnchor
          to={"https://formatjs.io/docs/react-intl/components/#formattedtime"}
        >
          FormattedTime component
        </TailwindAnchor>
      </StorybookParagraph>
      <StorybookParagraph>
        Here are some examples with the TailwindDate component. As it is very
        flexible, you can create variants easily so not all combinations are
        showcased here.
      </StorybookParagraph>
      <StorybookComponentShowcase
        sourceCode={`<TailwindTranslatedTime
          value={new Date(1459832991883)}
        />
`}
      >
        <TailwindTranslatedTime value={new Date(1459832991883)} />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindTranslatedTime
          value={new Date(1459832991883)}
           textProps={{textColor: "text-red-500"}}
        />
`}
      >
        <TailwindTranslatedTime
          value={new Date(1459832991883)}
          textProps={{ textColor: "text-red-500" }}
        />
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsTranslatedTime;
