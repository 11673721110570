import React, { useCallback, useMemo } from "react";
import TailwindSpinner from "library/components/_tailwind/spinner";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import BroadcastStore from "common/broadcast/_stores/broadcast/BroadcastStore";
import { inject, observer } from "mobx-react";
import TailwindText from "library/components/_tailwind/text";
import { BroadcastStreamState } from "common/broadcast/_stores/broadcast/enums";
import { BroadcastStreamType } from "common/broadcast/_stores/broadcast-stream/enums";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import PricingStore from "common/broadcast/_stores/pricing/PricingStore";
import classNames from "classnames";
import LanguageStore from "library/core/stores/language/LanguageStore";

type Props = {
  broadcastStore?: BroadcastStore;
  broadcastStreamStore?: BroadcastStreamStore;
  pricingStore?: PricingStore;
  languageStore?: LanguageStore;
};

const BroadcastStartStreamingButton: React.ComponentType<
  Props
> = ({ broadcastStore, broadcastStreamStore, pricingStore, languageStore }) => {
  const { obsStreamConnected, streamType, mediaStream } = broadcastStreamStore!;
  const { isStartingOrStoppingStream, streamState, startStreaming } =
    broadcastStore!;
  const { areTippingFieldsValid } = pricingStore!;
  const { intl } = languageStore!;

  const onClickStartShow = useCallback(() => {
    if (areTippingFieldsValid) {
      startStreaming();
    }
  }, [startStreaming, areTippingFieldsValid]);

  const shouldShowButton = useMemo(() => {
    return (
      streamState !== BroadcastStreamState.started &&
      ((obsStreamConnected && streamType == BroadcastStreamType.OBS) ||
        (mediaStream && streamType === BroadcastStreamType.WEBRTC))
    );
  }, [obsStreamConnected, streamType, streamState, mediaStream]);

  if (!shouldShowButton) {
    return null;
  }

  return (
    <span
      data-testid={"start-streaming-button"}
      className={classNames(
        "BroadcastPreview__StartStreamingButton",
        "bg-green-300",
        !areTippingFieldsValid && "cursor-not-allowed"
      )}
      onClick={onClickStartShow}>
      {isStartingOrStoppingStream ? (
        <TailwindSpinner size={1} margin={["my-auto", "mr-2"]} />
      ) : (
        <div className='BroadcastPreview__StartStreamingButtonIcon'>
          <PlayArrowIcon fontSize={"large"} />
        </div>
      )}
      <TailwindText
        className='BroadcastPreview__StartStreamingButtonText'
        fontSize='text-sm'>
        {isStartingOrStoppingStream
          ? 
            streamState === BroadcastStreamState.startingStream
              ? intl.formatMessage({
                id: 'broadcast.broadcastPreview.startingStream',
                defaultMessage: 'Starting Broadcast... Please Wait.'
              })
              : intl.formatMessage({
                id: 'broadcast.broadcastPreview.stoppingStream',
                defaultMessage: 'Stopping Broadcast... Please Wait.'
              })
            
          : intl.formatMessage({
              id: "broadcast.broadcastPreview.startStreaming",
              defaultMessage: "Start Broadcasting",
            })}
      </TailwindText>
    </span>
  );
};

export default inject(
    "broadcastStore",
    "broadcastStreamStore",
    "pricingStore",
    "languageStore"
  )(observer(BroadcastStartStreamingButton));
