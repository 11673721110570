import React, { useCallback, useEffect, useMemo, useState } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import ProfileStore from "./stores/profile/ProfileStore";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import { dateProcess, history } from "library/core/utility";
import MediaManagerStore from "containers/_components/media-manager/store/MediaManagerStore";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindHeading from "library/components/_tailwind/heading";
import TailwindPill from "library/components/_tailwind/pill";
import TailwindText from "library/components/_tailwind/text";

import MyPageProfileBioProfilePhotoUploadCard from "./profile-photo-upload-card";
import {
  ASTROLOGICAL_SIGN_LIST,
  BREAST_SIZE_LIST,
  ETHNICITY_LIST,
  EYE_COLOR_LIST,
  FEMALE_BODY_BUILD_LIST,
  FEMALE_SEXUAL_ORIENTATION_LIST,
  GENDER_LIST,
  HAIR_COLOR_LIST,
  MALE_BODY_BUILD_LIST,
  MALE_SEXUAL_ORIENTATION_LIST,
  PENIS_SIZE_LIST,
  TRANS_BODY_BUILD_LIST,
  TRANS_SEXUAL_ORIENTATION_LIST,
} from "library/core/constants";
import {
  MY_PAGE_ACCEPTED_IMAGE_EXTENSIONS,
  MY_PAGE_ACCEPTED_MAXIMUM_FILES_FOR_PROFILE_PHOTO,
} from "containers/_components/media-manager/store/consts";
import { AppCamsModelRouteKey } from "core/stores/route/enums";
import MyPageCamsModelSearchAttributes from "./cams-model-search-attributes";
import TailwindGrid from "library/components/_tailwind/grid";
import TailwindInput from "library/components/_tailwind/input";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import moment from "moment";
import TailwindParagraph from "library/components/_tailwind/paragraph";
import { FileRejection } from "react-dropzone";
import TailwindButton from "library/components/_tailwind/button";
import TailwindBox from "library/components/_tailwind/box";
import ProfilePhotoCropCard from "./profile-photo-crop-card";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import TailwindIcon from "library/components/_tailwind/icon";
import AuthStore from "core/stores/auth/AuthStore";
import Showcase from "library/components/_tailwind/showcase";
import ShowcaseStore from "core/stores/showcase/ShowcaseStore";
import { SHOWCASE_TARGET_CLASSNAMES } from "core/stores/showcase/const";
import RouteStore from "core/stores/route/RouteStore";
import GoLiveButton from "containers/_layout/dashboard/header/go-live-button";
import TailwindAnchor from "library/components/_tailwind/anchor";
import config from "core/config";

type MyPageProfileBioProps = {
  profileStore?: ProfileStore;
  validationStore?: ValidationStore;
  mediaManagerStore?: MediaManagerStore;
  layoutStore?: LayoutStore;
  authStore?: AuthStore;
  showcaseStore?: ShowcaseStore;
  routeStore?: RouteStore;
};

type TempPhoto = {
  image: string;
  media_status: "pending";
  uploadProgress?: 0 | null;
};

const MyPageProfileBio: React.FunctionComponent<
  MyPageProfileBioProps & WrappedComponentProps
> = ({
  intl,
  profileStore,
  mediaManagerStore,
  validationStore,
  layoutStore,
  authStore,
  showcaseStore,
  routeStore,
}) => {
  const { getSiteRouteURL } = routeStore!;
  const { calculateAge } = dateProcess;
  const { isMobileScreenSize, isLiteMode } = layoutStore!;
  const [doCrop, setDoCrop] = useState<boolean>(false);
  const [cropSrc, setCropSrc] = useState<File | null>(null);
  const [showProfilePhotos, setShowProfilePhotos] = useState<boolean>(
    !isMobileScreenSize
  );
  const [cropImageType, setCropImageType] = useState<
    "profile_image" | "non_nude_profile_image" | null
  >(null);
  const { showNotificationOnRejectedFiles } = validationStore!;
  const [hasAttemptedSubmit, setHasSttemptedSubmit] = useState<boolean>(false);
  const [isSavingProfile, setIsSavingProfile] = useState<boolean>(false);
  const [isShowingLiveInfo, setIsShowingLiveInfo] = useState<boolean>(false);
  const [isShowingLiveFunFact, setIsShowingLiveFunFact] =
    useState<boolean>(true);
  const [tempNudePhoto, setTempNudePhoto] = useState<TempPhoto>();
  const [tempNonNudePhoto, setTempNonNudePhoto] = useState<TempPhoto>();
  const [isMyBioActive, setIsMyBioActive] = useState<boolean>(false);
  const [tempBioText, setTempBioText] = useState<string | null>(null);
  const [isMyStatisticsActive, setIsMyStatisticsActive] =
    useState<boolean>(false);
  const { profileAlbum, initAllMedia } = mediaManagerStore!;
  const { countries } = authStore!;
  const {
    modelProfile,
    liveModelProfile,
    hasModelPendingProfileProperty,
    uploadProfilePhoto,
    updateProfileStoreProperty,
    languages: allLanguages,
    languagesHashmap,
    saveProfile,
    publicProfileImage,
    profileImage,
    publicNonNudeProfileImage,
    nonNudeProfileImage,
    getLanguages,
    kinks,
    kinksHashmap,
    specialities,
    specialitiesHashmap,
    attributes,
    attributesHashmap,
    nonNudeProfileImageProgress,
    profileImageProgress,
    setFunFactSubmitted,
    isProfileBioFieldsComplete,
  } = profileStore!;
  const { errors } = validationStore!;
  const {
    playingShowcase,
    getWelcomeShowcaseSteps,
    nextWelcomeShowcasePage,
    disableShowcase,
  } = showcaseStore!;

  useEffect(() => {
    getLanguages();
    initAllMedia();
  }, []);

  const changePropertyValue = useCallback(
    (property, value) => {
      if (property === "languages") {
        value = allLanguages.filter(language =>
          value.includes(language.alpha_3)
        );
      } else if (property === "kinks" && kinks) {
        value = kinks.filter(kink => value.includes(kink.id));
      } else if (property === "specialities" && specialities) {
        value = specialities.filter(speciality =>
          value.includes(speciality.id)
        );
      } else if (property === "attributes" && attributes) {
        value = attributes.filter(attribute => value.includes(attribute.id));
      }
      updateProfileStoreProperty(property, value);
      if (property !== "fun_fact") setIsMyStatisticsActive(true);
    },
    [modelProfile?.languages, allLanguages, kinks, specialities, attributes]
  );

  const onAgeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      changePropertyValue("age", event.target.value);
    },
    [changePropertyValue]
  );

  const onBirthDateChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      changePropertyValue(
        "birthdate",
        moment(event.target.value).format("yyyy-M-d")
      );
    },
    [changePropertyValue]
  );

  const onFunFactChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsMyBioActive(true);
      setTempBioText(event.target.value);
      changePropertyValue("pending_fun_fact", event.target.value);
    },
    [changePropertyValue, tempBioText]
  );

  const onSaveProfile = useCallback(async () => {
    try {
      setIsSavingProfile(true);
      setHasSttemptedSubmit(true);
      await saveProfile();
      setIsMyStatisticsActive(false);
      setIsMyBioActive(false);
    } catch (error) {
      // error
    } finally {
      setIsSavingProfile(false);
      setIsShowingLiveInfo(false);
    }
  }, []);

  const onSaveProfileFunFact = useCallback(async () => {
    if (tempBioText) changePropertyValue("fun_fact", tempBioText);
    try {
      setIsSavingProfile(true);
      setHasSttemptedSubmit(true);
      setFunFactSubmitted(true);
      await saveProfile();
      setIsMyStatisticsActive(false);
      setIsMyBioActive(false);
    } catch (error) {
      // error
    } finally {
      setFunFactSubmitted(false);
      setIsSavingProfile(false);
      setTempBioText(null);
    }
  }, [tempBioText]);

  const onViewLiveFunFactVersionClicked = useCallback(() => {
    setIsShowingLiveFunFact(!isShowingLiveFunFact);
  }, [isShowingLiveFunFact]);

  const age = useMemo(
    () => (isShowingLiveInfo ? liveModelProfile?.age : modelProfile?.age),
    [modelProfile?.age, liveModelProfile?.age, isShowingLiveInfo]
  );
  const astrologicalSign = useMemo(
    () =>
      isShowingLiveInfo
        ? liveModelProfile?.astrological_sign
        : modelProfile?.astrological_sign,
    [
      modelProfile?.astrological_sign,
      liveModelProfile?.astrological_sign,
      isShowingLiveInfo,
    ]
  );
  const sexualOrientation = useMemo(
    () =>
      isShowingLiveInfo
        ? liveModelProfile?.sexual_orientation
        : modelProfile?.sexual_orientation,
    [
      modelProfile?.sexual_orientation,
      liveModelProfile?.sexual_orientation,
      isShowingLiveInfo,
    ]
  );
  const breastSize = useMemo(
    () =>
      isShowingLiveInfo
        ? liveModelProfile?.breast_size
        : modelProfile?.breast_size,
    [
      modelProfile?.breast_size,
      liveModelProfile?.breast_size,
      isShowingLiveInfo,
    ]
  );
  const penisSize = useMemo(
    () =>
      isShowingLiveInfo
        ? liveModelProfile?.penis_size
        : modelProfile?.penis_size,
    [modelProfile?.penis_size, liveModelProfile?.penis_size, isShowingLiveInfo]
  );
  const bodyType = useMemo(
    () =>
      isShowingLiveInfo ? liveModelProfile?.body_type : modelProfile?.body_type,
    [modelProfile?.body_type, liveModelProfile?.body_type, isShowingLiveInfo]
  );

  const languages = useMemo(() => {
    const _languages = isShowingLiveInfo
      ? liveModelProfile?.languages
      : modelProfile?.languages;
    return _languages.map(language => language.alpha_3);
  }, [modelProfile?.languages, liveModelProfile?.languages, isShowingLiveInfo]);

  const funFact = useMemo(() => {
    return isShowingLiveFunFact
      ? modelProfile?.fun_fact
      : modelProfile?.pending_fun_fact;
  }, [
    modelProfile?.pending_fun_fact,
    modelProfile?.fun_fact,
    isShowingLiveFunFact,
    tempBioText,
  ]);

  const nudeProfilePhoto = useMemo(() => {
    if (tempNudePhoto) return tempNudePhoto;
    if (profileImage) return profileImage;
    return publicProfileImage;
  }, [
    publicProfileImage,
    profileImage,
    isShowingLiveInfo,
    tempNudePhoto,
    modelProfile.pending_profile_image,
    modelProfile.profile_image,
  ]);

  const nonNudeProfilePhoto = useMemo(() => {
    if (tempNonNudePhoto) return tempNonNudePhoto;
    if (nonNudeProfileImage) return nonNudeProfileImage;
    return publicNonNudeProfileImage;
  }, [
    publicProfileImage,
    profileImage,
    isShowingLiveInfo,
    tempNudePhoto,
    modelProfile.pending_non_nude_profile_image,
    modelProfile.non_nude_profile_image,
  ]);

  const onUploadNonNudeProfileImage = useCallback((files: File[]) => {
    if (files && files.length) {
      showCropPhotoTool(files, "non_nude_profile_image");
    }
  }, []);

  const onUploadNudeProfileImage = useCallback((files: File[]) => {
    if (files && files.length) {
      showCropPhotoTool(files, "profile_image");
    }
  }, []);

  const showCropPhotoTool = useCallback(
    (files: File[], _imageType: "profile_image" | "non_nude_profile_image") => {
      if (files && files.length) {
        setCropSrc(files[0]);
        setCropImageType(_imageType);
        setDoCrop(true);
      }
    },
    []
  );

  const onDropRejectedForNudeOrNonNudePhoto = useCallback(
    (rejections: FileRejection[]) => {
      showNotificationOnRejectedFiles(
        rejections,
        MY_PAGE_ACCEPTED_MAXIMUM_FILES_FOR_PROFILE_PHOTO,
        MY_PAGE_ACCEPTED_IMAGE_EXTENSIONS.join(", ")
      );
    },
    []
  );

  const cropFinished = async (
    file?: File,
    imageType?: "profile_image" | "non_nude_profile_image",
    objUrl?: string
  ) => {
    setCropSrc(null);
    setDoCrop(false);
    if (file && imageType && objUrl) {
      if (imageType === "profile_image") {
        setTempNudePhoto({
          image: (await blobToBase64(file)) as string,
          media_status: "pending",
          uploadProgress: 0,
        });
      } else {
        setTempNonNudePhoto({
          image: (await blobToBase64(file)) as string,
          media_status: "pending",
          uploadProgress: 0,
        });
      }
      uploadProfilePhoto([file], imageType);
    }
  };

  const blobToBase64 = (file: File) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const onClickViewProfileAlbum = () => {
    if (profileAlbum) {
      history.push(
        `${getSiteRouteURL(AppCamsModelRouteKey.myPhotosEdit)}/${
          profileAlbum.id
        }`
      );
    }
  };

  const minMaxOfAge = useMemo(() => {
    return {
      min: calculateAge(modelProfile.birthdate) - 10,
      max: calculateAge(modelProfile.birthdate) + 10,
    };
  }, [modelProfile.birthdate]);

  useMemo(() => {
    const isMobile = isMobileScreenSize || false;
    setShowProfilePhotos(!isMobile);
  }, [isMobileScreenSize]);

  const toggleProfilePhotos = () => {
    if (isMobileScreenSize) {
      setShowProfilePhotos(!showProfilePhotos);
    } else {
      setShowProfilePhotos(true);
    }
  };

  const isPendingFunFactAvailable = useMemo(() => {
    return modelProfile?.pending_fun_fact !== modelProfile?.fun_fact;
  }, [isSavingProfile]);

  useEffect(() => {
    !isPendingFunFactAvailable && setIsShowingLiveFunFact(false);
  }, []);

  return (
    <TailwindFlex
      minHeight={"min-h-full"}
      height={"h-full"}
      justifyContent={isLiteMode ? "justify-center" : "justify-start"}
      pseudoClasses={["md:flex-col", "md:h-auto", "md:min-h-0"]}
      className={["MyPageProfileBio"]}
      position={"relative"}
    >
      {playingShowcase && (
        <Showcase
          steps={getWelcomeShowcaseSteps(AppCamsModelRouteKey.myPage)}
          onFinish={nextWelcomeShowcasePage}
          disableOverlay={true}
          showSkipButton={true}
          onSkipped={() => {
            disableShowcase();
          }}
          disableScrolling={true}
        />
      )}

      <TailwindFlex
        backgroundColor={"bg-transparent"}
        padding={["px-4", "pb-4", "pt-2"]}
        width={"w-full"}
        maxWidth={"max-w-md"}
        minWidth={"min-w-96"}
        pseudoClasses={["md:max-w-full", "md:px-4", "md:max-h-full"]}
        maxHeight={"max-h-full-without-header"}
        flexDirection={"flex-col"}
      >
        <TailwindFlex
          margin={[showProfilePhotos ? "mb-4" : "mb-0"]}
          flexDirection={"flex-col"}
        >
          <TailwindFlex flexDirection={"flex-col"}>
            <TailwindFlex
              margin={[showProfilePhotos ? "mb-4" : "mb-0"]}
              flexDirection={"flex-col"}
            >
              <TailwindFlex
                alignItems={"items-center"}
                justifyContent={"justify-start"}
                pseudoClasses={["md:justify-between"]}
                onClick={toggleProfilePhotos}
              >
                <TailwindFlex justifyContent={"justify-between"}>
                  <TailwindHeading level={5} margin={["mb-3"]}>
                    My Profile
                  </TailwindHeading>
                  {isLiteMode && isProfileBioFieldsComplete && (
                    <TailwindBox height='h-12'>
                      <GoLiveButton />
                    </TailwindBox>
                  )}
                </TailwindFlex>
                <TailwindBox
                  height={"h-auto"}
                  display={isMobileScreenSize ? "flex" : "hidden"}
                >
                  <TailwindButton fullWidth={false}>
                    <TailwindIcon
                      fontSize={"text-4xl"}
                      name={
                        !showProfilePhotos
                          ? "keyboard_arrow_left"
                          : "keyboard_arrow_down"
                      }
                    />
                  </TailwindButton>
                </TailwindBox>
              </TailwindFlex>
            </TailwindFlex>

            <TailwindBox
              width={"w-auto"}
              display={showProfilePhotos ? "flex" : "hidden"}
              margin={["mb-1"]}
            >
              <TailwindFlex
                width={"w-full"}
                justifyContent={"justify-start"}
                alignItems={"items-start"}
                flexDirection={"flex-col"}
                maxWidth={"max-w-3xl"}
                position={"relative"}
                pseudoClasses={["xs:flex-col", "xs:justify-center"]}
              >
                {/* View All */}
                <TailwindBox
                  display={showProfilePhotos ? "flex" : "hidden"}
                  width={"w-auto"}
                  margin={["ml-auto"]}
                  position={"absolute"}
                  style={{ right: "5px", top: "-5px", padding: 0 }}
                  pseudoClasses={["md:ml-0", "md:mb-4"]}
                >
                  <TailwindButton
                    backgroundColor={"bg-transparent"}
                    rounded={false}
                    textProps={{
                      fontSize: "text-base",
                    }}
                    fullWidth={false}
                    height={"h-10"}
                    onClick={onClickViewProfileAlbum}
                  >
                    View All
                  </TailwindButton>
                </TailwindBox>
                {/* View All */}
                <TailwindBox
                  width={"w-full"}
                  padding={["pr-4"]}
                  margin={["mb-8"]}
                  pseudoClasses={["md:w-full", "md:pr-2"]}
                >
                  <MyPageProfileBioProfilePhotoUploadCard
                    name={"Profile Photo"}
                    isMyProfile={true}
                    backgroundImage={nonNudeProfilePhoto?.image}
                    fileUploadProps={{
                      backgroundColor: "bg-transparent",
                      borderWidth: ["border-0"],
                      dropzoneProps: {
                        onDrop: onUploadNonNudeProfileImage,
                        onDropRejected: onDropRejectedForNudeOrNonNudePhoto,
                        accept: ["image/jpeg", "image/jpg", "image/png"],
                      },
                    }}
                    status={nonNudeProfilePhoto?.media_status}
                    uploadProgress={
                      nonNudeProfileImageProgress! ||
                      tempNonNudePhoto?.uploadProgress!
                    }
                  />
                </TailwindBox>
                <TailwindBox
                  width={"w-full"}
                  padding={["pr-4"]}
                  margin={["mb-4"]}
                  position={"relative"}
                  pseudoClasses={["md:w-full", "md:pr-2"]}
                >
                  <MyPageProfileBioProfilePhotoUploadCard
                    name={"Nude Profile Photo (optional)"}
                    optionalTitle={"Optional"}
                    isMyProfile={true}
                    nameProps={{
                      className:
                        SHOWCASE_TARGET_CLASSNAMES.myPageProfilePhotoNude,
                    }}
                    backgroundImage={nudeProfilePhoto?.image}
                    fileUploadProps={{
                      width: "w-full",
                      backgroundColor: "bg-transparent",
                      borderWidth: ["border-0"],
                      dropzoneProps: {
                        onDrop: onUploadNudeProfileImage,
                        onDropRejected: onDropRejectedForNudeOrNonNudePhoto,
                        accept: ["image/jpeg", "image/jpg", "image/png"],
                      },
                    }}
                    status={nudeProfilePhoto?.media_status}
                    uploadProgress={
                      profileImageProgress! || tempNudePhoto?.uploadProgress!
                    }
                  />
                </TailwindBox>
              </TailwindFlex>
            </TailwindBox>
            <TailwindBox display={showProfilePhotos ? "flex" : "hidden"}>
              <TailwindParagraph width={"w-full"} fontSize={"text-lg"}>
                These photos are for your profile and promotional listing
              </TailwindParagraph>
            </TailwindBox>
            {doCrop && (
              <ProfilePhotoCropCard
                cropSrc={cropSrc}
                cropImageType={cropImageType}
                cropFinished={cropFinished}
              />
            )}
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>

      <TailwindFlex
        backgroundColor={"bg-transparent"}
        padding={["px-4", "pb-5", "pt-16"]}
        width={"w-full"}
        maxWidth={"max-w-md"}
        minWidth={"min-w-96"}
        pseudoClasses={["md:max-w-full", "md:px-4", "md:max-h-full"]}
        maxHeight={"max-h-full-without-header"}
        flexDirection={"flex-col"}
        position={"relative"}
      >
        <TailwindFlex
          margin={["mt-2"]}
          padding={["pb-0.5"]}
          flexDirection={"flex-row"}
          alignItems={"items-center"}
        >
          <TailwindHeading level={6} className={"text-xl"}>
            My Bio
          </TailwindHeading>
          {isPendingFunFactAvailable && (
            <TailwindButton
              margin={["ml-auto"]}
              maxHeight={"max-h-6"}
              backgroundColor={
                isMobileScreenSize ? "bg-gray-100" : "bg-transparent"
              }
              padding={["p-0"]}
              fullWidth={false}
              onClick={onViewLiveFunFactVersionClicked}
            >
              View {isShowingLiveFunFact ? "Pending" : "Live"}
            </TailwindButton>
          )}
        </TailwindFlex>

        <TailwindFlex flexDirection='flex-col' margin={["mb-8"]}>
          <TailwindFlex margin={["mb-3"]} width={"w-full"}>
            <TailwindInput
              boxProps={{
                height: "h-auto",
              }}
              backgroundColor={
                isPendingFunFactAvailable
                  ? "bg-text-area-bg-color"
                  : "bg-primary-bg-color"
              }
              type={TailwindInputType.textarea}
              rows={5}
              minRows={5}
              maxRows={5}
              value={funFact || ""}
              disabled={isShowingLiveFunFact}
              style={{
                color:
                  isPendingFunFactAvailable && !isShowingLiveFunFact
                    ? "gray"
                    : "black",
              }}
              disabledProps={
                isShowingLiveFunFact
                  ? {
                      backgroundColor: "bg-primary-bg-color",
                    }
                  : undefined
              }
              maxLength={2000}
              onChange={onFunFactChange}
              className={["MyPage_my_bio_field"]}
            />
          </TailwindFlex>
          <div className='w-full' style={{ margin: "auto", minHeight: "40px" }}>
            {!isShowingLiveFunFact && (
              <TailwindFlex flexDirection='flex-row' alignItems={"items-start"}>
                {isPendingFunFactAvailable && (
                  <TailwindPill
                    borderRadius={"rounded-full"}
                    backgroundColor={"bg-gray-600"}
                    size='sm'
                    padding={["px-4", "py-2"]}
                    textColor={"text-white"}
                  >
                    <TailwindText fontSize={"text-sm"}>
                      {"Pending"}
                    </TailwindText>
                  </TailwindPill>
                )}
                <TailwindButton
                  margin={["ml-auto"]}
                  backgroundColor={"bg-pink-400"}
                  rounded={false}
                  onClick={onSaveProfileFunFact}
                  showSpinner={isSavingProfile}
                  textColor={"text-white"}
                  borderRadius={"rounded"}
                  fullWidth={false}
                  settings={true}
                  justifyContent={"justify-center"}
                  disabled={!isMyBioActive}
                >
                  {intl.formatMessage({
                    id: "common.save",
                    defaultMessage: "Save",
                  })}
                </TailwindButton>
              </TailwindFlex>
            )}
          </div>
        </TailwindFlex>

        <TailwindFlex margin={["mb-1"]} flexDirection={"flex-col"}>
          <TailwindHeading width={"w-full"} level={6} className={"text-xl"}>
            My Data
          </TailwindHeading>
        </TailwindFlex>
        <TailwindFlex flexDirection={"flex-col"}>
          <TailwindFlex margin={["mb-4"]}>
            <TailwindFlex width={"w-1/2"} margin={["mr-2"]}>
              <TailwindInput
                boxProps={{
                  height: "h-10",
                }}
                backgroundColor={"bg-primary-bg-color"}
                type={TailwindInputType.number}
                value={age}
                onChange={onAgeChange}
                label={"Age"}
                error={(hasAttemptedSubmit && errors?.age) || undefined}
                min={minMaxOfAge.min}
                max={minMaxOfAge.max}
              />
            </TailwindFlex>
            <TailwindFlex width={"w-1/2"} margin={["ml-2"]}>
              <MyPageCamsModelSearchAttributes
                name={"gender"}
                value={modelProfile.gender}
                updateProperty={changePropertyValue}
                values={GENDER_LIST}
                label={intl.formatMessage({
                  id: `accountSettings.searchAttributes.gender`,
                  defaultMessage: "Gender",
                })}
                error={(hasAttemptedSubmit && errors?.gender) || undefined}
              />
            </TailwindFlex>
          </TailwindFlex>
          <TailwindFlex margin={["mb-4"]}>
            <TailwindFlex width={"w-1/2"} margin={["mr-2"]}>
              <MyPageCamsModelSearchAttributes
                name={"sexual_orientation"}
                value={sexualOrientation}
                updateProperty={changePropertyValue}
                values={
                  modelProfile?.gender === "male"
                    ? MALE_SEXUAL_ORIENTATION_LIST
                    : modelProfile?.gender === "female"
                    ? FEMALE_SEXUAL_ORIENTATION_LIST
                    : TRANS_SEXUAL_ORIENTATION_LIST
                }
                label={"Sexual Orientation"}
                error={
                  (hasAttemptedSubmit && errors?.sexual_orientation) ||
                  undefined
                }
              />
            </TailwindFlex>
            <TailwindFlex width={"w-1/2"} margin={["ml-2"]}>
              <MyPageCamsModelSearchAttributes
                name={"body_type"}
                value={bodyType}
                updateProperty={changePropertyValue}
                values={
                  modelProfile?.gender === "male"
                    ? MALE_BODY_BUILD_LIST
                    : modelProfile?.gender === "female"
                    ? FEMALE_BODY_BUILD_LIST
                    : TRANS_BODY_BUILD_LIST
                }
                label={"Body Type"}
                error={(hasAttemptedSubmit && errors?.body_type) || undefined}
              />
            </TailwindFlex>
          </TailwindFlex>
          <TailwindFlex margin={["mb-4"]}>
            {(modelProfile?.gender === "female" ||
              modelProfile?.gender === "trans") && (
              <TailwindFlex width={"w-1/2"} margin={["mr-2"]}>
                <MyPageCamsModelSearchAttributes
                  name={"breast_size"}
                  value={breastSize}
                  updateProperty={changePropertyValue}
                  values={BREAST_SIZE_LIST}
                  label={"Breast Size"}
                  error={
                    (hasAttemptedSubmit && errors?.breast_size) || undefined
                  }
                />
              </TailwindFlex>
            )}
            {(modelProfile?.gender === "male" ||
              modelProfile?.gender === "trans") && (
              <TailwindFlex
                width={"w-1/2"}
                margin={[modelProfile?.gender === "trans" ? "mx-2" : "mr-2"]}
              >
                <MyPageCamsModelSearchAttributes
                  name={"penis_size"}
                  value={penisSize}
                  updateProperty={changePropertyValue}
                  values={PENIS_SIZE_LIST}
                  label={"Penis Size"}
                  error={
                    (hasAttemptedSubmit && errors?.penis_size) || undefined
                  }
                />
              </TailwindFlex>
            )}
            <TailwindFlex width={"w-1/2"} margin={["ml-2"]}>
              <MyPageCamsModelSearchAttributes
                name={"languages"}
                value={languages}
                updateProperty={changePropertyValue}
                values={languagesHashmap}
                label={"Languages"}
                error={(hasAttemptedSubmit && errors?.languages) || undefined}
                multiple
              />
            </TailwindFlex>
          </TailwindFlex>
        </TailwindFlex>
        <TailwindFlex>
          <TailwindInput
            boxProps={{
              height: "h-10",
            }}
            label={intl.formatMessage({
              id: "accountSettings.searchAttributes.birthdate",
              defaultMessage: "Birth Date",
            })}
            shape={"rounded"}
            backgroundColor={"bg-primary-bg-color"}
            name={"birthdate"}
            maskProps={{
              date: true,
              delimiter: "/",
              datePattern: ["m", "d"],
            }}
            value={moment(modelProfile.birthdate).format("MM/DD")}
            onChange={onBirthDateChanged}
            placeholder={"Enter month and date (ex. 06/14)"}
            error={(hasAttemptedSubmit && errors?.birthdate) || undefined}
          />
        </TailwindFlex>
        <TailwindGrid margin={["mt-4"]} cols={"grid-cols-2"} gap={"gap-2"}>
          <TailwindFlex margin={["mb-2"]} padding={["pr-1"]}>
            <MyPageCamsModelSearchAttributes
              name={"iso_country"}
              value={modelProfile.iso_country}
              updateProperty={changePropertyValue}
              values={countries}
              label={intl.formatMessage({
                id: `accountSettings.searchAttributes.country`,
                defaultMessage: "Country",
              })}
              error={(hasAttemptedSubmit && errors?.country) || undefined}
            />
          </TailwindFlex>
          <TailwindFlex margin={["mb-2"]} padding={["pl-1"]}>
            <MyPageCamsModelSearchAttributes
              name={"astrological_sign"}
              value={astrologicalSign}
              updateProperty={changePropertyValue}
              values={ASTROLOGICAL_SIGN_LIST}
              label={"Astrological Sign"}
              error={
                (hasAttemptedSubmit && errors?.astrological_sign) || undefined
              }
            />
          </TailwindFlex>
          <TailwindFlex margin={["mb-2"]} padding={["pr-1"]}>
            <MyPageCamsModelSearchAttributes
              name={"ethnicity"}
              value={modelProfile.ethnicity}
              updateProperty={changePropertyValue}
              values={ETHNICITY_LIST}
              label={intl.formatMessage({
                id: `accountSettings.searchAttributes.ethnicity`,
                defaultMessage: "Ethnicity",
              })}
              error={(hasAttemptedSubmit && errors?.ethnicity) || undefined}
            />
          </TailwindFlex>
          <TailwindFlex margin={["mb-2"]} padding={["pl-1"]}>
            <MyPageCamsModelSearchAttributes
              name={"eye_color"}
              value={modelProfile.eye_color}
              updateProperty={changePropertyValue}
              values={EYE_COLOR_LIST}
              label={intl.formatMessage({
                id: `accountSettings.searchAttributes.eye_color`,
                defaultMessage: "Eyes",
              })}
              error={(hasAttemptedSubmit && errors?.eye_color) || undefined}
            />
          </TailwindFlex>
          <TailwindFlex margin={["mb-2"]} padding={["pr-1"]}>
            <MyPageCamsModelSearchAttributes
              name={"hair_color"}
              value={modelProfile.hair_color}
              updateProperty={changePropertyValue}
              values={HAIR_COLOR_LIST}
              label={intl.formatMessage({
                id: `accountSettings.searchAttributes.hair_color`,
                defaultMessage: "Hair",
              })}
              error={(hasAttemptedSubmit && errors?.hair_color) || undefined}
            />
          </TailwindFlex>
          <TailwindFlex margin={["mb-2"]} padding={["pl-1"]}>
            <MyPageCamsModelSearchAttributes
              name={"specialities"}
              value={modelProfile.specialities.map(speciality => speciality.id)}
              updateProperty={changePropertyValue}
              multiple={true}
              values={specialitiesHashmap || {}}
              label={intl.formatMessage({
                id: `accountSettings.searchAttributes.specialities`,
                defaultMessage: "Specialities",
              })}
              error={(hasAttemptedSubmit && errors?.specialities) || undefined}
            />
          </TailwindFlex>
          <TailwindFlex margin={["mb-2"]} padding={["pr-1"]}>
            <MyPageCamsModelSearchAttributes
              name={"kinks"}
              value={modelProfile.kinks.map(kink => kink.id)}
              updateProperty={changePropertyValue}
              multiple={true}
              values={kinksHashmap || {}}
              label={intl.formatMessage({
                id: `accountSettings.searchAttributes.kinks`,
                defaultMessage: "Fetishes",
              })}
              error={(hasAttemptedSubmit && errors?.kinks) || undefined}
            />
          </TailwindFlex>
          <TailwindFlex margin={["mb-2"]} padding={["pl-1"]}>
            <MyPageCamsModelSearchAttributes
              name={"attributes"}
              value={modelProfile?.attributes?.map(attribute => attribute.id)}
              updateProperty={changePropertyValue}
              multiple={true}
              values={attributesHashmap || {}}
              label={intl.formatMessage({
                id: `accountSettings.searchAttributes.attributes`,
                defaultMessage: "Attributes",
              })}
              error={(hasAttemptedSubmit && errors?.attributes) || undefined}
            />
          </TailwindFlex>
        </TailwindGrid>

        <TailwindFlex
          justifyContent={"justify-end"}
          margin={[
            "mt-4",
            hasModelPendingProfileProperty && isMobileScreenSize
              ? "mb-14"
              : "mb-0",
          ]}
        >
          <TailwindFlex width={"w-full"}>
            {
              <div className='w-auto' style={{ margin: "0 0 0 auto" }}>
                <TailwindButton
                  backgroundColor={"bg-pink-400"}
                  rounded={false}
                  onClick={onSaveProfile}
                  showSpinner={isSavingProfile}
                  width={"w-full"}
                  textColor={"text-white"}
                  borderRadius={"rounded"}
                  fullWidth={true}
                  justifyContent={"justify-center"}
                  disabled={!isMyStatisticsActive}
                  settings={true}
                >
                  {intl.formatMessage({
                    id: "common.save",
                    defaultMessage: "Save",
                  })}
                </TailwindButton>
              </div>
            }
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
      <TailwindFlex
        width='w-full'
        padding={["px-8"]}
        justifyContent='justify-end'
      >
        <TailwindAnchor
          to={`${config.membersUrl}/${modelProfile.username}`}
          openInNewTab={true}
          height={"h-10"}
          margin={["mt-2"]}
          underline={false}
        >
          <TailwindText
            fontWeight='font-bold'
            fontSize='text-base'
            textColor='text-pink-color'
          >
            View as Guest
          </TailwindText>
        </TailwindAnchor>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject(
    "profileStore",
    "validationStore",
    "mediaManagerStore",
    "layoutStore",
    "authStore",
    "showcaseStore",
    "routeStore"
  )(observer(MyPageProfileBio))
);
