import React, { useCallback, useMemo } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import TailwindInputBase from "library/components/_tailwind/input-base";

type Props = {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  placeholder?: string;
  disabled?: boolean;
  isComposing?: boolean;
  isMobile?: boolean;
  style?: React.CSSProperties;
};

const MessageCenterSearchBox: React.ComponentType<
  Props & WrappedComponentProps
> = ({
  setSearchTerm,
  searchTerm,
  placeholder,
  disabled,
  isMobile = false,
  style,
  intl,
}) => {
  const inputPlaceholder = useMemo(() => {
    let placeholder_ = `${intl.formatMessage({
      id: "verbiage.search",
      defaultMessage: "Search",
    })} `;
    if (placeholder) {
      placeholder_ += placeholder;
    }

    return placeholder_;
  }, [placeholder]);

  const onChangeSearchTerm = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    },
    [setSearchTerm]
  );

  return (
    <TailwindInputBase
      rightIconProps={{
        name: "search-alt",
        textColor: "text-secondary-color",
      }}
      wrapperProps={{
        display: "flex",
        backgroundColor: "bg-white",
        alignItems: "items-center",
        borderRadius: "rounded",
        padding: isMobile ? ["py-2", "px-4"] : ["py-1", "px-3"],
        width: "w-full",
      }}
      placeholderColor={"placeholder-gray-600"}
      flexGrow={"flex-grow"}
      backgroundColor={"bg-transparent"}
      height={"h-8"}
      onChange={onChangeSearchTerm}
      value={searchTerm}
      disabled={disabled}
      placeholder={inputPlaceholder}
      className={["searchInput"]}
      style={style}
    />
  );
};

export default injectIntl(MessageCenterSearchBox);
