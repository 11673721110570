import React from "react";
import TailwindButton, { TailwindButtonProps } from "../button";

type Props = TailwindButtonProps & {};

const TailwindTabButton: React.ComponentType<Props> = ({
  children,
  ...props
}) => {
  return <TailwindButton {...props}>{children}</TailwindButton>;
};

export default TailwindTabButton;
