import React from "react";
import "./styles.scss";
import classnames from "classnames";

type ChatBubbleIconProps = {
  className?: string;
  color?: "green";
};

const ChatBubbleIcon: React.ComponentType<ChatBubbleIconProps> = ({
  className,
  color,
}) => (
  <span
    className={classnames(
      "ChatBubbleIcon",
      color && `ChatBubbleIcon--background-${color}`,
      className && className
    )}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 28 36"
    >
      <g transform="translate(-3, 1)">
        <g transform="translate(1, 1)">
          <g stroke="none" fill="#ffffff" strokeWidth="1px">
            <path
              d="M28.8,26.2l-2-5.5c0.6-1.5,1-3.1,1-4.8c0-6.8-5.5-12.4-12.4-12.4C8.6,3.6,3,9.1,3,16c0,6.8,5.5,12.4,12.4,12.4c3,0,5.6-1.1,7.7-2.8l4.5,1.8C28.7,27.7,29.2,27.3,28.8,26.2zM8.4,18.2c-1.3,0-2.3-1.1-2.3-2.3s1.1-2.3,2.3-2.3s2.3,1.1,2.3,2.3C10.9,17.1,9.8,18.2,8.4,18.2zM15.4,18.2c-1.3,0-2.3-1.1-2.3-2.3s1.1-2.3,2.3-2.3c1.3,0,2.3,1.1,2.3,2.3S16.7,18.2,15.4,18.2zM22.3,18.2c-1.3,0-2.3-1.1-2.3-2.3s1.1-2.3,2.3-2.3s2.3,1.1,2.3,2.3S23.6,18.2,22.3,18.2z"
              stroke="none"
              fill="#ffffff"
              strokeWidth="1px"
              vectorEffect="non-scaling-stroke"
            />
          </g>
        </g>
      </g>
    </svg>
  </span>
);

export default ChatBubbleIcon;
