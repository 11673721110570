import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";

export type MediaManagerMediaCardFooterUploadProgressProps = {
  uploadProgress: number;
};

const MediaManagerMediaCardFooterUploadProgress: React.ComponentType<MediaManagerMediaCardFooterUploadProgressProps> = ({
  uploadProgress,
}) => {
  return (
    <TailwindFlex
      position={"absolute"}
      inset={["top-0", "bottom-0", "right-0", "left-0"]}
      backgroundColor={"bg-gray-400"}
      style={{ width: `${uploadProgress}%` }}>
      &nbsp;
    </TailwindFlex>
  );
};

export default MediaManagerMediaCardFooterUploadProgress;
