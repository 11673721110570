import React, { useState } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindTranslatedDate from "library/components/_tailwind/translated-date";
import TailwindTranslatedNumber from "library/components/_tailwind/translated-number";
import { MyFanMemberTransaction } from "containers/model/my-fans/stores/my-fans/types";

import Media from "library/components/_tailwind/media";

type Props = {
  transaction: MyFanMemberTransaction;
};

const MyFansMemberTransactionsTableRow: React.ComponentType<Props> = ({
  transaction,
}) => {
  if(!transaction || !transaction?.product || transaction?.product_subtype === 'minute_refund') {
    return null;
  }
  const percentageCalc =
    (transaction.earning_usd * 100) / transaction.member_spent_usd / 100;
  const [visible, setVisible] = useState(false);

  const activityName =
    transaction.product === "virtual_gift"
      ? "Gift"
      : transaction.product.split("_").join(" ");

  return (
    <TailwindFlex
      padding={["p-3", "pl-10", "pr-10"]}
      pseudoClasses={[
        "odd:bg-primary-bg-color",
        "even:bg-primary-bg-color",
        "hover:bg-secondary-bg-color",
        "xs:pl-5",
        "xs:pl-5",
      ]}
      onMouseEnter={() => {
        setVisible(true);
      }}
      onMouseLeave={() => {
        setVisible(false);
      }}
      borderColor={"border-table-secondary-color"}
      borderStyle={"border-solid"}
      borderWidth={["border-b"]}>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-start"}>
        <TailwindTranslatedDate
          value={transaction.created_date}
          textProps={{ fontSize: "text-base" }}
        />
      </TailwindFlex>
      <TailwindFlex
        width={"w-2/12"}
        justifyContent={"justify-start"}
        overflow={"overflow-hidden"}>
        <TailwindText
          width={"w-full"}
          padding={["pl-1", "pr-1"]}
          className={"capitalize-first"}
          textOverflow={"overflow-ellipsis"}
          whiteSpace={"whitespace-nowrap"}
          overflow={"overflow-hidden"}>
          {transaction.website}
        </TailwindText>
      </TailwindFlex>
      <TailwindFlex
        width={"w-2/12"}
        justifyContent={"justify-start"}
        overflow={"overflow-hidden"}>
        <TailwindText
          width={"w-auto"}
          padding={["pl-1", "pr-1"]}
          className={"capitalize-first"}
          whiteSpace={"whitespace-nowrap"}
          textOverflow={"overflow-ellipsis"}
          overflow={"overflow-hidden"}>
          {activityName}
        </TailwindText>
        {transaction.product_info?.image && (
          <TailwindFlex
            visibility={visible ? "visible" : "invisible"}
            margin={["ml-1"]}
            width={"w-6"}
            height={"h-6"}
            justifyContent={"justify-start"}>
            <Media
              rounded={true}
              borderRadius='rounded'
              overflow={"overflow-hidden"}
              src={transaction.product_info?.image || ""}
            />
          </TailwindFlex>
        )}
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedNumber
          minimumFractionDigits={0}
          maximumFractionDigits={0}
          value={transaction.member_spent_tokens}
          style='decimal'
        />
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedNumber
          minimumFractionDigits={0}
          maximumFractionDigits={0}
          value={percentageCalc}
          style='percent'
        />
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedNumber
          value={transaction.earning_usd}
          style='currency'
          currency='USD'
        />
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default MyFansMemberTransactionsTableRow;
