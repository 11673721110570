import TailwindFlex from 'library/components/_tailwind/flex';
import TailwindText from 'library/components/_tailwind/text';
import React from 'react';
import { observer } from 'mobx-react';

type Props = {};

const MissingPgProfilePhotoError: React.ComponentType<Props> = ({}) => {

  return (
    <TailwindFlex
      height={"h-full"}
      width={"w-full"}
      flexDirection={"flex-col"}
      alignItems={"items-center"}
      justifyContent={'justify-center'}
    >
      <TailwindFlex
        width={"w-full"}
        maxWidth={'max-w-3xl'}
        flexDirection={'flex-col'}
        alignItems={"items-center"}
        justifyContent={'justify-center'}
      >
        <TailwindText
            fontSize={"text-xl"}
            margin={["mb-2"]}
            fontWeight={"font-bold"}
            textAlign={"text-center"}>
            Access Denied
        </TailwindText>
        <TailwindText
          margin={["mb-8"]}
          textAlign={"text-center"}
          textColor={"text-red-500"}
        >
          You do not currently have an approved PG profile photo. In the Model Center, please go to My Stuff -{'>'} My Photo Albums and upload a photo to your Promotional Image Album. It must be in landscape orientation, measure between 200px and 100px on the longest side, and contain no nudity. If you have any questions, please contact our live support for assistance.
        </TailwindText>
        <TailwindText
          margin={["mb-4"]}
          textAlign={"text-center"}
        >
          For further assistance, please contact the compliance department (408)702-1048 or email.
        </TailwindText>
        <TailwindText
          margin={["mb-8"]}
          textAlign={"text-center"}
          fontWeight={"font-bold"}
        >
          The Cams Staff
        </TailwindText>
      </TailwindFlex>
    </TailwindFlex>
  );
}

export default observer(MissingPgProfilePhotoError);