import React, { useEffect, useRef, useState } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import { formatter } from "library/core/utility";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import TailwindBox from "library/components/_tailwind/box";

type MessagesAudioPlayerProps = {
  src: string;
  duration?: number;
  showDuration?: boolean;
  style?: React.CSSProperties;
  onPlay?: (playing: boolean) => void;
};

const MessagesAudioPlayer: React.ComponentType<MessagesAudioPlayerProps> = ({
  src,
  duration,
  showDuration = true,
  style,
  onPlay,
}) => {
  const [trackProgress, setTrackProgress] = useState<number>(0);
  const [trackStart, setTrackStart] = useState<number>(0);
  const [trackEnd, setTrackEnd] = useState<number>(0);
  const [isAudioPlaying, setIsAudioPlaying] = useState<boolean>(false);
  const [audioReady, setAudioReady] = useState<boolean>(false);
  const [audioDuration, setAudioDuration] = useState<number>(
    duration ? duration : 0
  );
  const audioRef = useRef<HTMLAudioElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);

  const startAudioProgress = () => {
    if (
      audioReady &&
      audioRef?.current &&
      audioDuration &&
      audioRef?.current?.currentTime < audioDuration
    ) {
      const progress = Math.round(
        100 * (audioRef.current.currentTime / audioDuration)
      );
      setTrackProgress(progress);
      window.requestAnimationFrame(startAudioProgress);
    } else if (
      audioRef?.current &&
      audioDuration &&
      audioRef?.current?.currentTime >= audioDuration
    ) {
      setTrackProgress(100);
    }
  };

  const playRecordedAudio = () => {
    audioRef?.current?.play();
    startAudioProgress();
  };

  const pauseRecordedAudio = () => {
    audioRef?.current?.pause();
  };

  useEffect(() => {
    if (audioRef?.current) {
      audioRef.current.onloadedmetadata = () => {
        if (
          audioRef?.current?.duration &&
          (audioRef.current.duration === Infinity ||
            isNaN(Number(audioRef.current.duration)))
        ) {
          audioRef.current.currentTime = 1e101;
        } else if (audioRef?.current?.duration) {
          setAudioDuration(audioRef.current.duration);
          setAudioReady(true);
        }
      };
      audioRef.current.ontimeupdate = () => {
        if (
          audioRef?.current?.duration &&
          audioRef.current.duration !== Infinity &&
          !isNaN(Number(audioRef.current.duration))
        ) {
          audioRef.current.currentTime = 0;
          setAudioDuration(audioRef.current.duration);
          setAudioReady(true);
          audioRef.current.ontimeupdate = null;
        }
      };
      audioRef.current.onended = () => {
        setIsAudioPlaying(false);
        onPlay?.(false);
      };
      audioRef.current.onpause = () => {
        setIsAudioPlaying(false);
        onPlay?.(false);
      };
      audioRef.current.onplay = () => {
        setIsAudioPlaying(true);
        onPlay?.(true);
      };
    }

    return () => {
      setAudioReady(false);
      if (audioRef?.current) {
        audioRef.current.ontimeupdate = null;
        audioRef.current.onended = null;
        audioRef.current.onpause = null;
        audioRef.current.onplay = null;
      }
    };
  }, [audioRef]);

  const computeTrack = () => {
    if (trackRef?.current) {
      const dimensions = trackRef.current.getBoundingClientRect();

      setTrackStart(dimensions.left);
      setTrackEnd(dimensions.right);
    }
  };

  const handleMoveThumb = event => {
    if (audioRef.current) {
      const x = event?.clientX || event.touches?.[0]?.clientX;
      const trackTotal = trackEnd - trackStart;
      const newX = Math.max(x - trackStart, 0);
      const percentage = (newX / trackTotal) * 100;
      const progress = percentage > 100 ? 100 : percentage < 0 ? 0 : percentage;
      const newTime =
        percentage > 100
          ? audioRef.current.duration
          : percentage < 0
          ? 0
          : audioRef.current.duration * (newX / trackTotal);

      setTrackProgress(progress);
      audioRef.current.currentTime = newTime;
    }
  };

  const handleMouseDownOnThumb = event => {
    handleMoveThumb(event);
    document.addEventListener("mousemove", handleMoveThumb);
  };

  const handleTouchStartOnThumb = event => {
    handleMoveThumb(event);
    document.addEventListener("touchmove", handleMoveThumb);
  };

  const handleMouseUpOnThumb = () => {
    document.removeEventListener("mousemove", handleMoveThumb);
  };

  const handleTouchEndOnThumb = () => {
    document.removeEventListener("touchmove", handleMoveThumb);
  };

  useEffect(() => {
    computeTrack();

    window.addEventListener("resize", computeTrack);

    return () => {
      window.removeEventListener("resize", computeTrack);
    };
  }, [trackRef]);

  useEffect(() => {
    trackRef?.current?.addEventListener("mousedown", handleMouseDownOnThumb);
    document?.addEventListener("mouseup", handleMouseUpOnThumb);
    trackRef?.current?.addEventListener("touchstart", handleTouchStartOnThumb);
    document?.addEventListener("touchend", handleTouchEndOnThumb);

    return () => {
      trackRef?.current?.removeEventListener(
        "mousedown",
        handleMouseDownOnThumb
      );
      document?.removeEventListener("mouseup", handleMouseUpOnThumb);
      trackRef?.current?.removeEventListener(
        "touchstart",
        handleTouchStartOnThumb
      );
      document?.removeEventListener("touchend", handleTouchEndOnThumb);
    };
  }, [trackRef, trackStart, trackEnd]);

  useEffect(() => {
    const fetchAudio = async () => {
      try {
        if (audioRef?.current) {
          const response = await fetch(src);
          const blob = await response.blob();
          const url = (URL || webkitURL).createObjectURL(blob);

          audioRef.current.src = url;
          audioRef.current.load();
        }
      } catch {}
    };

    fetchAudio();
  }, [audioRef, src]);

  return (
    <TailwindFlex
      className={["MessagesAudioPlayer"]}
      flexDirection='flex-row'
      width='w-full'
      height='h-full'
      alignItems='items-center'
      justifyContent='justify-center'
      gap='gap-1.5'
      style={style}>
      <audio ref={audioRef} />
      <TailwindFlex width='w-auto'>
        {isAudioPlaying ? (
          <TailwindIcon
            cursor={audioReady ? "cursor-pointer" : "cursor-default"}
            name={CustomIconName.pause}
            fontSize='text-7xl'
            onClick={() => {
              if (audioReady) {
                pauseRecordedAudio();
              }
            }}
          />
        ) : (
          <TailwindIcon
            cursor={audioReady ? "cursor-pointer" : "cursor-default"}
            name={CustomIconName.play}
            fontSize='text-7xl'
            onClick={() => {
              if (
                audioRef?.current &&
                (trackProgress >= audioRef?.current?.duration ||
                  trackProgress >= 100)
              ) {
                setTrackProgress(0);
              }
              if (audioReady) {
                playRecordedAudio();
              }
            }}
          />
        )}
      </TailwindFlex>
      <TailwindFlex ref={trackRef} padding={["pr-1", "pl-1"]}>
        {audioReady ? (
          <>
            <TailwindFlex
              className={["MessagesAudioPlayer__progress-bar-container"]}>
              <TailwindBox className={["MessagesAudioPlayer__progress-bar"]}>
                <TailwindBox
                  className={[
                    "MessagesAudioPlayer__progress-bar-fill",
                    isAudioPlaying
                      ? "MessagesAudioPlayer__progress-bar-fill-playing"
                      : "",
                  ]}
                  style={{
                    width: `${trackProgress}%`,
                  }}></TailwindBox>

                <TailwindBox
                  className={["MessagesAudioPlayer__progress-bar--thumb"]}
                />
              </TailwindBox>
            </TailwindFlex>
          </>
        ) : (
          <TailwindIcon name={CustomIconName.waveform} />
        )}
      </TailwindFlex>
      {showDuration ? (
        <TailwindFlex width='w-auto' fontSize='text-sm'>
          {audioDuration
            ? formatter.formatSeconds(audioDuration, "MM:SS")
            : null}
        </TailwindFlex>
      ) : null}
    </TailwindFlex>
  );
};

export default MessagesAudioPlayer;
