import React from "react";

type VoiceIconProps = {
  className?: string;
};

const VoiceIcon: React.FunctionComponent<VoiceIconProps> = ({
  className = "",
}) => {
  return (
    <svg
      className={`VoiceIcon ${className}`}
      version='1.1'
      x='0'
      y='0'
      viewBox='0 0 31.000006418991212 23'>
      <g transform='translate(5,0)'>
        <circle r='4.5' cx='10' cy='9' />
        <path
          d='M9.45508,15.73684c-3.15563,0 -9.45508,1.62211 -9.45508,4.84211v2.42105h18.91017v-2.42105c0,-3.22 -6.29945,-4.84211 -9.45508,-4.84211zM18.62652,4.06737l-1.98557,2.04579c0.99278,1.42842 0.99278,3.28053 0,4.70895l1.98557,2.04579c2.38741,-2.44526 2.38741,-6.13737 0,-8.80053zM22.53856,0l-1.92647,1.97316c3.27382,3.65579 3.27382,9.15158 0,13.00105l1.92647,1.97316c4.60935,-4.70895 4.62117,-12.04474 0,-16.94737z'
          vectorEffect='non-scaling-stroke'
        />
      </g>
    </svg>
  );
};

export default VoiceIcon;