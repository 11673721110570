import React from "react";
import TailwindFlex, {
  TailwindFlexProps,
} from "../../../components/_tailwind/flex";

type Props = TailwindFlexProps & {
  isCollapsed: boolean;
};

const StorybookSidebarSubmenu: React.ComponentType<Props> = ({
  children,
  isCollapsed,
  ...props
}) => {
  if (isCollapsed) {
    return null;
  }

  return (
    <TailwindFlex {...props} flexDirection={"flex-col"} padding={["pl-4"]}>
      {children}
    </TailwindFlex>
  );
};

export default StorybookSidebarSubmenu;
