import React from "react";
import "./styles.scss";

type FansIconProps = {
  className?: string;
};

const FansIcon: React.ComponentType<FansIconProps> = ({ className }) => (
  <span className={`${className} camsIcon`}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
      <path d='M50,1.07A49.06,49.06,0,1,0,99.06,50.12,49.11,49.11,0,0,0,50,1.07Zm0,93.11A44.06,44.06,0,1,1,94.06,50.12,44.12,44.12,0,0,1,50,94.18Z' />
      <path d='M81.57,42.5a5.47,5.47,0,0,0-4.45-3.75L61.8,36.52,55,22.64h0a5.51,5.51,0,0,0-9.89,0L38.2,36.52,22.88,38.75a5.52,5.52,0,0,0-3.06,9.41L30.91,59,28.29,74.23a5.51,5.51,0,0,0,8,5.81L50,72.83,63.71,80a5.52,5.52,0,0,0,8-5.81L69.09,59,80.18,48.16A5.49,5.49,0,0,0,81.57,42.5Zm-4.88,2.08-12,11.72a2.51,2.51,0,0,0-.72,2.22l2.84,16.55a.48.48,0,0,1-.21.51.48.48,0,0,1-.54,0L51.16,67.8a2.45,2.45,0,0,0-2.32,0L34,75.61a.48.48,0,0,1-.54,0,.48.48,0,0,1-.21-.51l2.84-16.55a2.51,2.51,0,0,0-.72-2.22l-12-11.72a.5.5,0,0,1-.13-.53.49.49,0,0,1,.42-.35l16.62-2.42a2.49,2.49,0,0,0,1.88-1.36l7.44-15.07a.51.51,0,0,1,.92,0L57.9,39.92a2.49,2.49,0,0,0,1.88,1.36L76.4,43.7a.49.49,0,0,1,.42.35A.5.5,0,0,1,76.69,44.58Z' />
    </svg>
  </span>
);

export default FansIcon;
