import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";
import StorybookComponentShowcase from "../_internal/component-showcase";
import TailwindDivider from "../../components/_tailwind/divider";

type Props = {};

const StorybookComponentsDivider: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Components &gt; Divider</StorybookHeading>
      <StorybookParagraph>
        TailwindDivider is a wrapper of TailwindBox that means it can take all
        props from TailwindBox but has the following props opinionated to easily
        get the look of a divider:
      </StorybookParagraph>
      <Code>
        {`thickness?: 1 | 2 | 3;
color?: TBackgroundColor;
orientation?: "vertical" | "horizontal";    
`}
      </Code>
      <StorybookComponentShowcase sourceCode={`<TailwindDivider />`}>
        <TailwindDivider />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindDivider thickness={2} />`}
      >
        <TailwindDivider thickness={2} />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindDivider orientation={"vertical"} />`}
        height={"h-96"}
      >
        <TailwindDivider orientation={"vertical"} />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindDivider color={"bg-red-500"} orientation={"vertical"} />`}
        height={"h-96"}
      >
        <TailwindDivider color={"bg-red-500"} orientation={"vertical"} />
      </StorybookComponentShowcase>
      <StorybookComponentShowcase
        sourceCode={`<TailwindDivider
  color={"bg-red-500"}
  backgroundOpacity={"bg-opacity-40"}
  orientation={"vertical"}
/>
`}
        height={"h-96"}
      >
        <TailwindDivider
          color={"bg-red-500"}
          backgroundOpacity={"bg-opacity-40"}
          orientation={"vertical"}
        />
      </StorybookComponentShowcase>
    </StorybookWrapper>
  );
};

export default StorybookComponentsDivider;
