import React from "react";
import classnames from "classnames";

type CloseIconProps = {
  className?: string;
};

const CloseThinIcon: React.FunctionComponent<CloseIconProps> = ({
  className = "",
}) => {
  return (
    <span className={classnames("CloseThinIcon", className)}>
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 32 32'
        xmlSpace='preserve'>
        <polygon
          points='32.5,1.4 31.1,0 16.3,14.8 1.4,0 0,1.4 14.8,16.3 0,31.1 1.4,32.5 16.3,17.7 31.1,32.5 32.5,31.1     17.7,16.3   '
          vectorEffect='non-scaling-stroke'
        />
      </svg>
    </span>
  );
};

export default CloseThinIcon;
