import React from "react";
import SignUpContainer from "./sign-up";
import ResetPasswordContainer from "./reset-password";
import ResetUsernameContainer from "./reset-username";
import AccountClosedContainer from "./account-closed";
import LearnMoreContainer from "./learn-more";
import UpdateDocumentsContainer from "./update-documents";
import UpdatePasswordContainer from "./account-settings/update-password";
import LogoutContainer from "./logout";
import PrivateRoute from "core/stores/route/private-route";
import BroadcastContainer from "./broadcast";
import MemberVerificationBroadcastContainer from "./broadcast/memberVerification";
import LandingContainer from "./landing";
import LoginContainer from "./login";
import MessagesContainer from "./messages";
import NoticesContainer from "./messages/notices";
import PublicRoute from "core/stores/route/public-route";
import { Route, Switch } from "react-router-dom";
import { AppCommonRouteKey } from "core/stores/route/enums";
import ReferralRedirectContainer from "./referral-redirect-container";
import PayoutContainer from "./payout";
import { inject, observer } from "mobx-react";
import ProfileStore from "./my-page/stores/profile/ProfileStore";
import NotFoundRoute from "core/stores/route/not-found-redirect";
import StudiosAcceptableUsePolicy from "./acceptable-use-policy";
import RouteStore from "core/stores/route/RouteStore";
import EarningsContainer from "common/earnings";
import RecordKeepingRequirementsCompliance from "common/record-keeping-requirements-compliance";
import ProtectKids from "common/protect-kids";
import PrivacyPolicy from "common/privacy-policy";
import PaymentRenewalTermsOfUse from "common/payment-renewal-terms-of-use";
import PersonsAppearingOnStreamray from "common/persons-appearing-on-streamray";
import DMCA from "common/dmca";
import Cookies from "common/cookies";
import CaliforniaAct from "common/california-act";
import PercentagePayoutSchedule from "common/percentage-payout";
import BountyLegalInfo from "common/bounty-legal-info";
import PerformersAgreement from "common/performers-acknowledgement-and-agreement";
import BroadcastAgreement from "common/broadcast-agreement";
import BroadcastRelease from "common/broadcast-release";
import OBSSupport from "common/obs-support";

type Props = {
  profileStore?: ProfileStore;
  routeStore?: RouteStore;
};

const CommonRoutes: React.ComponentType<Props> = ({
  profileStore,
  routeStore,
}) => {
  const { getSiteRouteURL } = routeStore!;
  const { isStudioModel } = profileStore!;
  return (
    <Switch>
      <PrivateRoute exact path={getSiteRouteURL(AppCommonRouteKey.earnings)}>
        <EarningsContainer />
      </PrivateRoute>
      <PublicRoute
        exact
        redirectToMainPageWhenLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.landing)}>
        <LandingContainer />
      </PublicRoute>
      <PublicRoute
        exact
        redirectToMainPageWhenLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.login)}>
        <LoginContainer />
      </PublicRoute>
      <PublicRoute
        exact
        redirectToMainPageWhenLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.register)}>
        <SignUpContainer />
      </PublicRoute>
      <PublicRoute
        exact
        redirectToMainPageWhenLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.resetPassword)}>
        <ResetPasswordContainer />
      </PublicRoute>
      <PublicRoute
        exact
        redirectToMainPageWhenLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.resetUsername)}>
        <ResetUsernameContainer />
      </PublicRoute>
      <PublicRoute
        exact
        path={getSiteRouteURL(AppCommonRouteKey.accountClosed)}>
        <AccountClosedContainer />
      </PublicRoute>
      <PublicRoute
        exact
        redirectToMainPageWhenLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.learnMore)}>
        <LearnMoreContainer />
      </PublicRoute>
      <PublicRoute
        exact
        accessibleWhileLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.studiosAcceptableUsePolicy)}>
        <StudiosAcceptableUsePolicy />
      </PublicRoute>
      <PublicRoute
        exact
        accessibleWhileLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.acceptableUsePolicy)}>
        <StudiosAcceptableUsePolicy />
      </PublicRoute>
      <PublicRoute
        exact
        accessibleWhileLoggedIn
        path={getSiteRouteURL(
          AppCommonRouteKey.recordKeepingRequirementsCompliance
        )}>
        <RecordKeepingRequirementsCompliance />
      </PublicRoute>
      <PublicRoute
        exact
        accessibleWhileLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.protectKids)}>
        <ProtectKids />
      </PublicRoute>
      <PublicRoute
        exact
        accessibleWhileLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.privacyPolicy)}>
        <PrivacyPolicy />
      </PublicRoute>
      <PublicRoute
        exact
        accessibleWhileLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.paymentRenewalTermsOfUse)}>
        <PaymentRenewalTermsOfUse />
      </PublicRoute>
      <PublicRoute
        exact
        accessibleWhileLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.personsAppearingOnStreamray)}>
        <PersonsAppearingOnStreamray />
      </PublicRoute>
      <PublicRoute
        exact
        accessibleWhileLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.dmca)}>
        <DMCA />
      </PublicRoute>
      <PublicRoute
        exact
        accessibleWhileLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.cookies)}>
        <Cookies />
      </PublicRoute>
      <PublicRoute
        exact
        accessibleWhileLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.californiaAct)}>
        <CaliforniaAct />
      </PublicRoute>
      <PublicRoute
        exact
        accessibleWhileLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.percentagePayout)}>
        <PercentagePayoutSchedule />
      </PublicRoute>
      <PublicRoute
        exact
        accessibleWhileLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.bountyLegalInfo)}>
        <BountyLegalInfo />
      </PublicRoute>
      <PublicRoute
        exact
        accessibleWhileLoggedIn
        path={getSiteRouteURL(
          AppCommonRouteKey.performersAcknowledgementAndAgreement
        )}>
        <PerformersAgreement />
      </PublicRoute>
      <PublicRoute
        exact
        accessibleWhileLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.broadcastAgreement)}>
        <BroadcastAgreement />
      </PublicRoute>
      <PublicRoute
        exact
        accessibleWhileLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.broadcastRelease)}>
        <BroadcastRelease />
      </PublicRoute>
      <PublicRoute
        exact
        accessibleWhileLoggedIn
        path={getSiteRouteURL(AppCommonRouteKey.obsSupport)}>
        <OBSSupport />
      </PublicRoute>
      <PrivateRoute
        exact
        path={getSiteRouteURL(AppCommonRouteKey.legalDocuments)}>
        <UpdateDocumentsContainer />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={getSiteRouteURL(AppCommonRouteKey.updatePassword)}>
        <UpdatePasswordContainer />
      </PrivateRoute>
      <PrivateRoute exact path={getSiteRouteURL(AppCommonRouteKey.broadcast)}>
        <BroadcastContainer />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={getSiteRouteURL(AppCommonRouteKey.broadcastMemberVerification)}>
        <MemberVerificationBroadcastContainer />
      </PrivateRoute>
      <PrivateRoute exact path={getSiteRouteURL(AppCommonRouteKey.notices)}>
        <NoticesContainer />
      </PrivateRoute>
      <PrivateRoute exact path={getSiteRouteURL(AppCommonRouteKey.messages)}>
        <MessagesContainer />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={`${getSiteRouteURL(AppCommonRouteKey.messages)}${getSiteRouteURL(
          AppCommonRouteKey.compose
        )}`}>
        <MessagesContainer />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={`${getSiteRouteURL(AppCommonRouteKey.messages)}${getSiteRouteURL(
          AppCommonRouteKey.searchMessages
        )}`}>
        <MessagesContainer />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={`${getSiteRouteURL(AppCommonRouteKey.messages)}${getSiteRouteURL(
          AppCommonRouteKey.contacts
        )}`}>
        <MessagesContainer />
      </PrivateRoute>
      <PublicRoute
        exact
        redirectToMainPageWhenLoggedIn
        path={"/friend/:referringModelOrStudio"}>
        <ReferralRedirectContainer />
      </PublicRoute>
      <Route exact path={getSiteRouteURL(AppCommonRouteKey.logout)}>
        <LogoutContainer />
      </Route>
      <PrivateRoute
        accessRestrictionOptions={{
          approvedOnly: ["model", "studio"],
        }}
        hasAccess={!isStudioModel}
        exact
        path={getSiteRouteURL(AppCommonRouteKey.payout)}>
        <PayoutContainer />
      </PrivateRoute>
      <NotFoundRoute />
    </Switch>
  );
};

export default inject("profileStore", "routeStore")(observer(CommonRoutes));
