import React, { useMemo } from "react";
import MediaManagerMediaCardHeader from "containers/_components/media-manager/media-manager-media-card-header";
import MediaManagerMediaCardBody from "containers/_components/media-manager/media-manager-media-card-body";
import MediaManagerMediaCard from "containers/_components/media-manager/media-manager-media-card";
import MediaManagerMediaCardFileUpload from "containers/_components/media-manager/media-manager-media-card-file-upload";
import MediaManagerMediaCardFooter from "containers/_components/media-manager/media-manager-media-card-footer";
import MediaManagerMediaCardHeaderTitle from "containers/_components/media-manager/media-manager-media-card-header-title";
import { inject, observer } from "mobx-react";
import ProfileStore from "./stores/profile/ProfileStore";
import { NonManagerProfile } from "./stores/profile/types";
import MediaManagerMediaCardFooterStatus from "containers/_components/media-manager/media-manager-media-card-footer-status";
import { ModelMediaStatus } from "containers/_components/media-manager/store/types";
// import MediaManagerMediaCardFooterUploadProgress from "containers/_components/media-manager/media-manager-media-card-footer-upload-progress";
// import TailwindIcon from "library/components/_tailwind/icon";
import { FileUploadProps } from "library/components/file-upload";
import TailwindText, {
  TailwindTextProps,
} from "library/components/_tailwind/text";
import TailwindButton from "library/components/_tailwind/button";
import TailwindFlex from "library/components/_tailwind/flex";
import { TailwindBoxClassName } from "library/components/_tailwind/box";
import TailwindSpinner from "library/components/_tailwind/spinner";

type Props = {
  name: string;
  status: ModelMediaStatus | undefined;
  uploadProgress: number;
  backgroundImage: string | undefined;
  fileUploadProps: FileUploadProps;
  profileStore?: ProfileStore;
  className?: TailwindBoxClassName;
  nameProps?: TailwindTextProps;
  optionalTitle?: string;
  isMyProfile?: boolean;
};

const MyPageProfileBioProfilePhotoUploadCard: React.ComponentType<Props> = ({
  name,
  status,
  uploadProgress,
  backgroundImage,
  fileUploadProps,
  profileStore,
  className,
  nameProps,
  optionalTitle,
  isMyProfile,
}) => {
  const { profile } = profileStore!;

  const showUploadProgress = useMemo(() => {
    return uploadProgress && uploadProgress < 100;
  }, [uploadProgress]);
  return (
    <>
      {isMyProfile && (
        <MediaManagerMediaCardHeader isMyProfile={true} height={"h-auto"}>
          <MediaManagerMediaCardHeaderTitle
            width={"w-auto"}
            name={name}
            isMyProfile={true}
            textProps={{
              ...nameProps,
              fontSize: "text-base",
              textColor: "text-main-color",
            }}
          />
        </MediaManagerMediaCardHeader>
      )}
      <MediaManagerMediaCard width={"w-full"} className={className}>
        {!isMyProfile && (
          <MediaManagerMediaCardHeader height={"h-12"}>
            <MediaManagerMediaCardHeaderTitle
              width={"w-auto"}
              name={name}
              textProps={{ ...nameProps, fontSize: "text-base" }}
            />
          </MediaManagerMediaCardHeader>
        )}
        <MediaManagerMediaCardBody
          brokenPlaceholderChildren={
            <TailwindFlex>
              {!backgroundImage && optionalTitle && (
                <TailwindText
                  margin={["mt-16"]}
                  width={"w-full"}
                  textAlign={"text-center"}>
                  {optionalTitle}
                </TailwindText>
              )}
              {backgroundImage && (
                <TailwindText textAlign={"text-center"}>
                  Loading {name}...
                </TailwindText>
              )}
            </TailwindFlex>
          }
          backgroundImage={backgroundImage}
          fallbackBackgroundImage={
            (profile as NonManagerProfile)?.public_profile_image
          }>
          <MediaManagerMediaCardFileUpload
            position={"absolute"}
            style={
              !isMyProfile
                ? { top: 0, left: 0 }
                : { top: 0, left: 0, height: "100%" }
            }
            fileUploadProps={{
              ...fileUploadProps,
              position: "absolute",
              zIndex: "z-20",
              showHelperText: false,
              customIcon: (
                <TailwindButton
                  rounded
                  fullWidth={false}
                  display={"flex"}
                  height={"h-10"}
                  width={"w-10"}
                  backgroundColor={"bg-black"}
                  backgroundOpacity={"bg-opacity-50"}
                  alignItems={"items-center"}
                  justifyContent={"justify-center"}
                  position={"absolute"}
                  style={
                    !isMyProfile
                      ? { right: "10px", top: "5px" }
                      : {
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                        }
                  }
                  textProps={{
                    textTransform: "capitalize",
                    fontSize: "text-sm",
                    textColor: "text-white",
                  }}
                  leftIconProps={{
                    name: "upload",
                    fontSize: "text-2xl",
                    textColor: "text-white",
                  }}
                  bold
                />
              ),
            }}
          />
          {showUploadProgress ? (
            <MediaManagerMediaCardFooter
              height={"h-full"}
              backgroundColor={"bg-gray-600"}
              backgroundOpacity={"bg-opacity-30"}>
              <TailwindSpinner />
            </MediaManagerMediaCardFooter>
          ) : (
            <MediaManagerMediaCardFooter height={isMyProfile ? "h-10" : "h-8"}>
              <MediaManagerMediaCardFooterStatus
                fontSize={isMyProfile ? "text-base" : "text-sm"}
                status={status}
              />
            </MediaManagerMediaCardFooter>
          )}
        </MediaManagerMediaCardBody>
      </MediaManagerMediaCard>
    </>
  );
};

export default inject("profileStore")(
  observer(MyPageProfileBioProfilePhotoUploadCard)
);
