import React, { FunctionComponent, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import TailwindButton from "../_tailwind/button";

interface Props {
  startAt?: number;
  max?: number;
  overScan?: number;
}

const CarouselPaginator: FunctionComponent<Props> = ({
  startAt = 0,
  max = 5,
  overScan = 5,
  children,
}) => {
  const [startIndex, setStartIndex] = useState<number>(startAt);
  const [endIndex, setEndIndex] = useState<number>(startAt + max);
  const childrenCount = React.Children.count(children);

  const handleBack = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
      setEndIndex(endIndex - 1);
    }
  };

  const handleNext = () => {
    if (endIndex < childrenCount) {
      setStartIndex(startIndex + 1);
      setEndIndex(endIndex + 1);
    }
  };

  useEffect(() => {
    if (startAt < startIndex) {
      handleBack();
    } else if (startAt >= startIndex + max) {
      handleNext();
    }
  }, [startAt]);

  useEffect(() => {
    if (childrenCount - (startAt + 1) < max && childrenCount >= max) {
      // handle end of list
      const newStartIndex = childrenCount - max;
      setStartIndex(newStartIndex);
      setEndIndex(newStartIndex + max);
    }
  }, []);

  return (
    <Box
      className={"justify-center"}
      display={"flex"}
      justifyContent={"justify-center"}
      flexGrow={1}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        visibility={startIndex != 0 ? "visible" : "hidden"}
        width={"auto"}>
        <TailwindButton
          className={["SharedMediaModal__back-button"]}
          padding={["p-0", "pr-4"]}
          onClick={() => {
            handleBack();
          }}
          alignSelf='self-center'
          rightIconProps={{
            name: "keyboard_arrow_left",
            fontSize: "text-4xl",
            textColor: "text-gray-400",
            style: {
              width: "12px",
            },
          }}
        />
      </Box>
      <Box display={"flex"}>
        {React.Children.map(children, (child, index) => (
          <div
            key={index}
            style={{
              display:
                index >= startIndex && index < endIndex ? "flex" : "none",
            }}>
            {index >= startIndex - overScan && index < endIndex + overScan
              ? child
              : null}
          </div>
        ))}
      </Box>

      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        visibility={childrenCount > endIndex ? "visible" : "hidden"}
        width={"auto"}>
        <TailwindButton
          className={["SharedMediaModal__back-button"]}
          padding={["pl-4", "pr-4"]}
          onClick={() => {
            handleNext();
          }}
          alignSelf='self-center'
          rightIconProps={{
            name: "keyboard_arrow_right",
            fontSize: "text-4xl",
            textColor: "text-gray-400",
            style: {
              width: "12px",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default CarouselPaginator;
