import React, { FunctionComponent, useEffect } from "react";
import ResetPasswordForm from "containers/_components/reset-password-form";
import UpdatePasswordForm from "containers/_components/update-password-form";
import { inject, observer } from "mobx-react";
import { useQuery } from "library/core/utility/hooks";
import AccountSettingsStore from "../account-settings/stores/account-settings/AccountSettingsStore";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindBox from "library/components/_tailwind/box";

type Props = {
  accountSettingsStore?: AccountSettingsStore;
};

const SHOW_ALSO_FORGOT_USERNAME = false;

const ResetPassword: FunctionComponent<Props> = ({ accountSettingsStore }) => {
  const { resetPasswordToken, setResetPasswordToken } = accountSettingsStore!;

  const query = useQuery();

  useEffect(() => {
    if (query.has("token") && !!query.get("token")) {
      setResetPasswordToken(query.get("token") as string);
    } else {
      setResetPasswordToken(undefined);
    }
  }, []);

  return (
    <TailwindFlex
      justifyContent={"justify-center"}
      alignItems={"items-center"}
      className={["ForgotPassword"]}
      height={"h-full"}
      width={"w-auto"}
      pseudoClasses={["xs:flex-col"]}>
      <TailwindBox
        width={"w-auto"}
        pseudoClasses={[
          "xs:w-full",
          "sm:w-1/2",
          "md:w-1/2",
          "lg:w-4/12",
          "xl:w-3/12",
        ]}>
        <div className='w-full mt-2 mb-4'>
          {!resetPasswordToken ? (
            <ResetPasswordForm
              btnShapeVariant={["rounded-sm"]}
              showForgotUsername={SHOW_ALSO_FORGOT_USERNAME}
            />
          ) : (
            <UpdatePasswordForm
              buttonProps={{ borderRadius: "rounded-md" }}
              token={resetPasswordToken}
              showOldPassword={false}
            />
          )}
        </div>
      </TailwindBox>
    </TailwindFlex>
  );
};

export default inject("accountSettingsStore")(observer(ResetPassword));
