import { inject, observer } from "mobx-react";
import React, { useMemo, useEffect } from "react";
import BroadcastStore from "../../_stores/broadcast/BroadcastStore";
import "./style.scss";
import WebRTCPlayer from "common/broadcast/webrtc-player";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import { WebRTCStatus } from "common/broadcast/_stores/broadcast/enums";
import config from "core/config";
import TailwindBox from "library/components/_tailwind/box";

interface Props {
  broadcastStore?: BroadcastStore;
  broadcastStreamStore?: BroadcastStreamStore;
}

const BroadcastMemberVerifyViewer: React.FunctionComponent<Props> = ({
  broadcastStore,
}) => {
  const { setWebRTCStatus } = broadcastStore!;

  // use c2c until we broadcast socket sends a show start for verify
  const c2cList = useMemo(() => {
    return Object.values(broadcastStore?.c2cHash || {});
  }, [broadcastStore?.c2cHash]);

  useEffect(() => {
    if (!config.disableWebrtcC2CVideo && c2cList.length === 0)
      setWebRTCStatus(WebRTCStatus.Stopped);
  }, [c2cList]);

  if (c2cList.length === 0) {
    return null;
  }

  return (
    <TailwindBox className={["BroadcastMemberVerifyViewer"]} width='w-full'>
      <WebRTCPlayer
        key={`${c2cList[0].memberId}`}
        c2c={c2cList[0]}
        style={{
          width: "100%",
          height: "auto",
        }}
      />
    </TailwindBox>
  );
};

export default inject(
  "broadcastStore",
  "broadcastStreamStore",
  "profileStore"
)(observer(BroadcastMemberVerifyViewer));
