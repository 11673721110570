import React, { useState } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindTranslatedDate from "library/components/_tailwind/translated-date";
import TailwindTranslatedNumber from "library/components/_tailwind/translated-number";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import { Transaction } from "common/earnings/_stores/earnings/types";
import Media from "library/components/_tailwind/media";
import {
  adjustedActivityProduct,
  getTransactionDetails,
} from "../../_stores/earnings/utils";

type Props = {
  activity: Transaction;
};

const MemberTokenActivityTableRow: React.ComponentType<Props> = ({
  activity,
}) => {
  const percentageCalc =
    (activity.earning_usd * 100) / activity.member_spent_usd / 100;
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <TailwindFlex
      padding={["p-2", "pl-5", "pr-5"]}
      borderColor={"border-table-secondary-color"}
      borderStyle={"border-solid"}
      borderWidth={["border-b"]}
      pseudoClasses={[
        "odd:bg-primary-bg-color",
        "even:bg-primary-bg-color",
        "hover:bg-secondary-bg-color",
      ]}
      onMouseEnter={() => {
        setVisible(true);
      }}
      onMouseLeave={() => {
        setVisible(false);
      }}>
      <TailwindFlex
        width={"w-2/12"}
        justifyContent={"justify-start"}
        overflow={"overflow-hidden"}>
        <TailwindText
          width={"w-full"}
          padding={["pl-1", "pr-1"]}
          className={"capitalize-first"}
          textOverflow={"overflow-ellipsis"}
          whiteSpace={"whitespace-nowrap"}
          overflow={"overflow-hidden"}>
          {activity.member_username}
        </TailwindText>
      </TailwindFlex>
      <TailwindFlex
        width={"w-2/12"}
        justifyContent={"justify-start"}
        overflow={"overflow-hidden"}>
        <TailwindText
          width={"w-full"}
          padding={["pl-1", "pr-1"]}
          className={"capitalize-first"}
          textOverflow={"overflow-ellipsis"}
          whiteSpace={"whitespace-nowrap"}
          overflow={"overflow-hidden"}>
          {activity.website}
        </TailwindText>
      </TailwindFlex>
      <TailwindFlex
        width={"w-2/12"}
        justifyContent={"justify-start"}
        overflow={"overflow-hidden"}>
        <TailwindTranslatedText
          padding={["pl-1", "pr-1"]}
          className={"capitalize-first"}
          whiteSpace={"whitespace-nowrap"}
          textOverflow={"overflow-ellipsis"}
          overflow={"overflow-hidden"}
          descriptor={{
            id: `payout-product-types.${adjustedActivityProduct(activity)}`,
            defaultMessage: getTransactionDetails(activity),
          }}
        />
        {activity.refunded ? " Refund" : ""}
        {activity.product_info?.image && (
          <TailwindFlex
            visibility={visible ? "visible" : "invisible"}
            margin={["ml-1"]}
            width={"w-6"}
            height={"h-6"}
            justifyContent={"justify-start"}>
            <Media
              rounded={true}
              borderRadius='rounded'
              overflow={"overflow-hidden"}
              src={activity.product_info?.image || ""}
            />
          </TailwindFlex>
        )}
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-start"}>
        <TailwindTranslatedDate
          value={activity.created_date}
          textProps={{ fontSize: "text-base" }}
        />
      </TailwindFlex>
      <TailwindFlex width={"w-1/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedNumber
          minimumFractionDigits={0}
          maximumFractionDigits={0}
          value={activity.member_spent_tokens}
          style='decimal'
        />
      </TailwindFlex>
      <TailwindFlex width={"w-2/12"} justifyContent={"justify-end"}>
        <TailwindTranslatedNumber
          minimumFractionDigits={0}
          maximumFractionDigits={0}
          value={percentageCalc}
          style='percent'
        />
      </TailwindFlex>
      <TailwindFlex textColor={activity.refunded ? 'text-red-500' : 'text-main-color'} width={"w-2/12"} justifyContent={"justify-end"}>
        {activity.refunded ? "-" : ""}
        <TailwindTranslatedNumber
          value={activity.earning_usd}
          style='currency'
          currency='USD'
        />
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default MemberTokenActivityTableRow;
