import React from "react";
import TailwindFlex from "../flex";
import { BUTTON_DEFAULT_ROUNDED } from "../button";

type Props = {};

const TailwindButtonGroup: React.ComponentType<Props> = ({ children }) => {
  const childrenCount = React.Children.count(children);

  const isFirst = (index: number) => {
    return index === 0;
  };

  const isLast = (index: number) => {
    return index === childrenCount - 1;
  };

  const getChildPropsWithEditedBorders = (props: any, index: number) => {
    const updatedProps = {
      borderRadius: props.borderRadius || [],
      ...props,
    };

    if (childrenCount > 0) {
      if (isFirst(index)) {
        updatedProps.borderRadius = [
          ...updatedProps.borderRadius,
          ...(updatedProps.rounded === undefined && BUTTON_DEFAULT_ROUNDED
            ? ["rounded-tl-full", "rounded-bl-full"]
            : []),
          "rounded-tr-none",
          "rounded-br-none",
        ];

        if (childrenCount === 2) {
          updatedProps.margin = [updatedProps.margin, "mr-1"];
        }
      } else if (isLast(index)) {
        updatedProps.borderRadius = [
          ...updatedProps.borderRadius,
          ...(updatedProps.rounded === undefined && BUTTON_DEFAULT_ROUNDED
            ? ["rounded-tr-full", "rounded-br-full"]
            : []),
          "rounded-tl-none",
          "rounded-bl-none",
        ];
      } else {
        updatedProps.borderRadius = [
          ...updatedProps.borderRadius,
          "rounded-tl-none",
          "rounded-tr-none",
          "rounded-bl-none",
          "rounded-br-none",
        ];
        updatedProps.margin = [updatedProps.margin, "mx-1"];
      }
    }

    return updatedProps;
  };

  return (
    <TailwindFlex>
      {React.Children.map(children, (child, index) =>
        React.isValidElement(child)
          ? React.cloneElement(
              child,
              getChildPropsWithEditedBorders(child.props, index)
            )
          : child
      )}
    </TailwindFlex>
  );
};

export default TailwindButtonGroup;
