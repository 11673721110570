import React, { useCallback } from "react";
import { inject, observer } from "mobx-react";
import "./styles.scss";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { tokenHelper } from "core/utility";
import { history } from "library/core/utility";
import AuthStore from "core/stores/auth/AuthStore";
import { AppCommonRouteKey } from "core/stores/route/enums";
import TailwindButton from "library/components/_tailwind/button";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import EarningsStore from "../../../../common/earnings/_stores/earnings/EarningsStore";
//import { useInterval } from "library/core/utility/hooks/interval";
import RouteStore from "core/stores/route/RouteStore";

type EarningsPageButtonProps = {
  authStore?: AuthStore;
  earningsStore?: EarningsStore;
  layoutStore?: LayoutStore;
  routeStore?: RouteStore;
  className?: string;
};

//const INTERVAL_FIVE_MIN = 5 * 60 * 1000;

const EarningsPageButton: React.FunctionComponent<
  EarningsPageButtonProps & WrappedComponentProps
> = ({ layoutStore, earningsStore, routeStore }) => {
  const { getSiteRouteURL } = routeStore!;
  // const { userRole } = authStore!;
  const { isMobileDevice } = layoutStore!;
  const {
    // getStudioCurrentPeriodEarning,
    // getModelCurrentEarnings,
    earningsForCurrentPeriod,
  } = earningsStore!;

  // const updateSummary = async () => {
  //   if (userRole === "studio") {
  //     await getStudioCurrentPeriodEarning();
  //   } else {
  //     await getModelCurrentEarnings();
  //   }
  // };

  // useEffect(() => {
  //   updateSummary();
  // }, [userRole]);

  // useInterval(() => {
  //   updateSummary();
  // }, INTERVAL_FIVE_MIN);

  const onEarningsClick = useCallback(() => {
    history.push(getSiteRouteURL(AppCommonRouteKey.earnings, true));
  }, []);

  return (
    <TailwindButton
      rounded={false}
      fullWidth={false}
      display={"flex"}
      backgroundColor={"bg-earnings-bg-color"}
      alignItems={"items-center"}
      onClick={onEarningsClick}
      textProps={{
        textTransform: "capitalize",
        fontSize: "text-lg",
        fontWeight: "font-bold",
        textColor: "text-main-color",
      }}
      size={"lg"}
      margin={["mr-2"]}
      bold>
      {!isMobileDevice && "Earnings:"}&nbsp;$
      {tokenHelper.tokenToCurrency(earningsForCurrentPeriod).toFixed(2)}
    </TailwindButton>
  );
};
export default injectIntl(
  inject(
    "authStore",
    "earningsStore",
    "layoutStore",
    "routeStore"
  )(observer(EarningsPageButton))
);
