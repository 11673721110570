import { makeAutoObservable } from "mobx";
import { api, tokenHelper } from "core/utility";
import config from "core/config";
import { DEFAULT_BONUS_CUT_PERCENTAGE, EarningsInitialData } from "./consts";
// import { format as formatDate } from "date-fns";
import {
  CurrentPeriodSums,
  CurrentPeriodTotals,
  IMemberTransactions30Days,
  IModelEarningsForCurrentPeriod,
  PayPeriod,
  Period,
  StudioEarnings,
  StudioEarningsPeriodSum,
  StudioModelEarningsLight,
  StudioPayment,
  StudioPeriodEarning,
  Transaction,
  PayoutFilter,
} from "./types";
import calculateModelsBonusEarnings from "core/utility/earning-helpers";
import { MODEL_TRANSACTION_EARNING_TYPE, PERIOD_TYPES } from "./enums";
import {
  calculateModelCutPercentage,
  calculateStudioPayPeriods,
  getFormattedPeriodFullDateYYYYMMDD,
} from "./utils";
import { dateProcess, logger } from "library/core/utility";
import { ModelProfile } from "../../../my-page/stores/profile/types";
import { TransactionProductFilter } from "../../../payout/stores/payout/interfaces";
import { PayoutInitialData } from "../../../payout/stores/payout/consts";
import { utcToZonedTime } from "date-fns-tz";
import _ from "lodash";
import moment from "moment";
import { authStore } from "core/stores";

export default class EarningsStore {
  constructor() {
    makeAutoObservable(this);

    this.init();
  }

  activeTab = EarningsInitialData.activeTab;

  currentPeriodId: number = EarningsInitialData.currentPeriodId;
  currentPeriodSums: CurrentPeriodSums | null =
    EarningsInitialData.currentPeriodSums;
  currentPeriodTotals: CurrentPeriodTotals[] | null =
    EarningsInitialData.currentPeriodTotals;
  currentProduct: TransactionProductFilter | null | undefined =
    EarningsInitialData.initialProduct;
  transactions: Transaction[] = EarningsInitialData.transactions;
  bonusTransactions: Transaction[] = EarningsInitialData.transactions;
  filteredTransactions: Transaction[] =
    EarningsInitialData.filteredTransactions;
  filteredBonusTransactions: Transaction[] =
    EarningsInitialData.filteredTransactions;
  totalForFiltered = EarningsInitialData.totalForFiltered;

  loadingFilters: boolean = EarningsInitialData.loadingFilters;
  loadingEarnings: boolean = EarningsInitialData.loadingEarnings;
  loadingModelEarnings: boolean = EarningsInitialData.loadingModelEarnings;
  loadingPeriodEarningSummary: boolean =
    EarningsInitialData.loadingPeriodEarningSummary;

  errorLoadingFilters: boolean = EarningsInitialData.errorLoadingFilters;
  errorLoadingEarnings: boolean = EarningsInitialData.errorLoadingEarnings;
  errorLoadingModelEarnings: boolean =
    EarningsInitialData.errorLoadingModelEarnings;
  errorLoadingPeriodEarningSummary: boolean =
    EarningsInitialData.errorLoadingPeriodEarningSummary;

  // periods
  periodType: PERIOD_TYPES = EarningsInitialData.periodType;
  periods: (PayPeriod | Period)[] = EarningsInitialData.periods;
  selectedPeriod: PayPeriod = EarningsInitialData.selectedPeriod;

  models: Pick<ModelProfile, "id" | "screen_name">[] =
    EarningsInitialData.models;
  selectedModel: Pick<ModelProfile, "id" | "screen_name"> | null =
    EarningsInitialData.selectedModel;
  modelIsSelected: boolean = EarningsInitialData.modelIsSelected;
  total: string = EarningsInitialData.total;
  studioTotal: string = EarningsInitialData.studioTotal;
  totalBroadcastHours: number = EarningsInitialData.totalBroadcastHours;
  studioEarningsPeriodSum: StudioEarningsPeriodSum | null =
    EarningsInitialData.studioEarningsPeriodSum;
  currentTier: string = EarningsInitialData.currentTier;
  earnings: StudioEarnings[] = EarningsInitialData.earnings;
  periodEarningSummary: StudioPeriodEarning[] =
    EarningsInitialData.periodEarningSummary;
  payments: StudioPayment[] = EarningsInitialData.payments;

  pageCounter: number = EarningsInitialData.pageCounter;
  hasMoreTransactions: boolean = EarningsInitialData.hasMoreTransactions;

  loadingPeriods: boolean = EarningsInitialData.loadingPeriods;
  loadingEarningsForCurrentPeriod: boolean =
    EarningsInitialData.loadingEarningsForCurrentPeriod;
  earningsForCurrentPeriod: IModelEarningsForCurrentPeriod | null =
    EarningsInitialData.earningsForCurrentPeriod;
  studioModelEarningsForPeriodLight: StudioModelEarningsLight[] | null =
    EarningsInitialData.studioModelEarningsForPeriodLight;
  loadingCurrentTierAndTotal: boolean =
    EarningsInitialData.loadingCurrentTierAndTotal;
  currentPeriodString: string = EarningsInitialData.currentPeriodString;
  amountNeededToNextTier: number = EarningsInitialData.amountNeededToNextTier;
  currentPeriodTitle: string = "";
  currentTotal: number = EarningsInitialData.currentTotal;
  chosenPeriod: Period = EarningsInitialData.chosenPeriod;

  allTransactions: Transaction[] = EarningsInitialData.allTransactions;
  allBonusTransactions: Transaction[] = EarningsInitialData.allTransactions;

  products: TransactionProductFilter[] = EarningsInitialData.products;
  initialProduct: TransactionProductFilter = EarningsInitialData.initialProduct;

  loadingMemberTransactions: boolean =
    EarningsInitialData.loadingMemberTransactions;

  selectedMemberTransactions: IMemberTransactions30Days[] =
    EarningsInitialData.selectedMemberTransactions;

  payoutPageDataInitialized: boolean =
    EarningsInitialData.payoutPageDataInitialized;

  payoutFilters: PayoutFilter = EarningsInitialData.payoutFilters;

  public async init() {
    this.resetStore();

    this.models = [EarningsInitialData.initModelFilter];
    this.selectedModel = EarningsInitialData.initModelFilter;

    this.payments = [];
  }

  public resetStore() {
    Object.entries(EarningsInitialData).map(
      ([key, value]) => (this[key] = value)
    );
  }

  public initPayoutPageData = async () => {

    if (authStore.userRole === "studio") {
      await this.getStudioModels();
      //commented out until earnings are implemented with legacy wallet
      //await this.getStudioPeriodEarningSummary();
      //await this.getStudioEarnings();
    } else {
      //commented out until earnings are implemented with legacy wallet
      //await this.getModelPeriods();
      //await this.getModelCurrentEarnings();
    }

    this.payoutPageDataInitialized = true;
  };

  public formatDateRangeOld = (date: string, options: any) => {
    const dates = date.split(" - ");
    const newDates = dates.map(date => this.formatDate(date, options));
    return `${newDates[0]} - ${newDates[1]}`;
  };

  public formatDateRange = (start: string, end: string, options) => {
    const newStart = this.formatDate(start, options);
    const newEnd = this.formatDate(end, options);
    return `${newStart} - ${newEnd}`;
  };

  public formatDateYear = (date: string) => {
    return new Date(date).getFullYear().toString();
  };

  public formatDate = (date: string, options: any) => {
    return utcToZonedTime(new Date(date), dateProcess.timezone).toLocaleString(
      "en-US",
      options
    );
  };

  // MODEL

  public getModelCurrentEarnings = async () => {
    this.loadingEarningsForCurrentPeriod = true;
    try {
      const response = await api.walletModelCurrent.get();
      // this.currentPeriodString = this.getPeriodString(response.data.data.period);
      this.earningsForCurrentPeriod =
        response?.data?.data?.period_total_earnings || 0;
      // this.currentTier = response.data.data.tier || 0;
    } catch (error) {
      logger.log("Wallet api error getModelCurrentEarnings", error);
    } finally {
      this.loadingEarningsForCurrentPeriod = false;
    }
  };

  public getModelEarnings = async (period: Period) => {
    this.loadingEarnings = true;
    let transactionsData: any;
    const periodId = period.id;
    try {
      const periodRequestId =
        periodId !== undefined ? periodId : this.currentPeriodId;
      const transactionsResponse = await api.walletModelPeriods.get(
        undefined,
        `${periodRequestId}/transactions/?pageSize=1000&transaction_type=tiered_transaction`
      );
      const bonusResponse = await api.walletModelPeriods.get(
        undefined,
        `${periodRequestId}/transactions/?pageSize=1000&transaction_type=bonus`
      );
      transactionsData = transactionsResponse?.data?.data?.transactions.concat(
        bonusResponse?.data?.data?.transactions
      );
      this.currentPeriodSums = transactionsResponse?.data?.data?.sums;

      this.studioModelEarningsForPeriodLight = this.getModelTokenEarningsList(
        period.model_token_earnings
      );

    } catch (error) {
      logger.log("earning api error ", config.showAjaxErrors ? error : "");
    } finally {
      this.handleModelTransactionsData(transactionsData);
      this.handleModelProductsForFilter(transactionsData);
      this.loadingEarnings = false;
    }
  };

  // MEMBER OF MODEL

  private handleModelTransactionsData = transactionsData => {
    this.transactions = (transactionsData || []).map(transaction => ({
      ...transaction,
      created_date: this.formatDate(transaction.created_date, {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      }),
    }));
    this.filteredTransactions = [...this.transactions];
    this.allTransactions = this.filteredTransactions;
    this.calculateModelEarnings();
  };

  private handleModelProductsForFilter = transactionsData => {
    if (transactionsData) {
      let uniqueProducts;
      uniqueProducts = _.uniq(transactionsData.map(t => t.product));
      if (uniqueProducts && Array.isArray(uniqueProducts)) {
        uniqueProducts = uniqueProducts.map((unique: string) => {
          if (unique) {
            return {
              label: unique.charAt(0).toUpperCase() + unique.slice(1),
              filterKey: unique,
            };
          }
        });
      }
      this.products = [
        { ...PayoutInitialData.initialProduct },
        ...uniqueProducts,
      ];
    }
  };

  public calculateModelEarnings = () => {
    if (Array.isArray(this.transactions)) {
      this.filteredTransactions = this.transactions.filter(
        transaction =>
          this.currentProduct?.filterKey === "all" ||
          transaction.product === this.currentProduct?.filterKey
      );
      const totalEarned = this.filteredTransactions.reduce(
        (sum: number, t: any) => sum + t.amount,
        0
      );
      this.totalForFiltered = Math.ceil(totalEarned * 100) / 100;
    } else {
      logger.log("transaction filer error");
    }
  };

  public setModelCurrentPeriod = async (value: number) => {
    this.currentPeriodId = +value;
    let chosenPeriod: Period | undefined = (this.periods as Period[]).find(
      period => period.id === value
    );

    if (chosenPeriod) {
      if (!chosenPeriod.amounts) {
        const fullPeriodData = await api.walletModelPeriods.get(
          undefined,
          `${value}`
        );

        if (fullPeriodData) {
          chosenPeriod = fullPeriodData.data?.data?.period;
          this.periods = this.periods.map(p => {
            if ((p as Period).id === value) {
              return { ...p, ...chosenPeriod };
            }
            return p;
          });
          this.amountNeededToNextTier =
            chosenPeriod?.amount_to_next_tier_tokens || 0;
        }
      }

      this.currentPeriodTitle = (chosenPeriod || {}).title || "";
      this.currentTotal = (chosenPeriod as Period)?.period_total_earnings
        ? +(chosenPeriod as Period).period_total_earnings
        : 0;
      this.currentTier = (chosenPeriod as Period).tier
        ? (chosenPeriod as Period).tier
        : PayoutInitialData.initialTier;
      if (!!chosenPeriod) {
        this.chosenPeriod = chosenPeriod;
      }
      this.getModelEarnings(chosenPeriod!);
      this.updateEarningsDetailsData(undefined, chosenPeriod);
    }
  };

  private getModelTokenEarningsList = (data: any) => {
    return [
      {
        source: "Your shows",
        youMake: MODEL_TRANSACTION_EARNING_TYPE.BASED_ON_TIER,
        tokens: data.your_shows,
      },
      {
        source: "Your paid albums",
        youMake: MODEL_TRANSACTION_EARNING_TYPE.BASED_ON_TIER,
        tokens: data.your_paid_albums,
      },
      {
        source: "Your Fan clubs",
        youMake: MODEL_TRANSACTION_EARNING_TYPE.BASED_ON_TIER,
        tokens: data.your_fan_clubs,
      },
      {
        source: "Bonuses",
        youMake: MODEL_TRANSACTION_EARNING_TYPE.FIRST_PAID_SHOW,
        tokens: data.bonus_tokens,
      },
      {
        source: "Credits/Refunds",
        tokens: data.charge_back_tokens,
      },
      {
        source: "Total",
        tokens: data.total_earnings,
      },
    ];
  };

  // private getModelEarningsData = (data: any) => {
  //   if (data) {
  //     return {
  //       private_show_earnings: parseFloat(
  //         (
  //           ((data.earned_tokens.fan_club_private_chat || 0) +
  //             (data.earned_tokens.fan_club_party_chat || 0) +
  //             (data.earned_tokens.private_chat || 0) +
  //             (data.earned_tokens.voyeur_chat || 0) +
  //             (data.earned_tokens.party_chat || 0) +
  //             (data.earned_tokens.nude_chat || 0) +
  //             (data.earned_tokens.cam2cam_chat || 0)) /
  //           10
  //         ).toFixed(2)
  //       ),
  //       tipping_show_earnings: parseFloat(
  //         (
  //           ((data.earned_tokens.tip || 0) +
  //             (data.earned_tokens.virtual_gift || 0) +
  //             (data.earned_tokens.whisper_message || 0) +
  //             (data.earned_tokens.wheel_of_fun || 0) +
  //             (data.earned_tokens.buzz || 0) +
  //             (data.earned_tokens.super_buzz || 0)) /
  //           10
  //         ).toFixed(2)
  //       ),
  //       fan_club_earnings: parseFloat(((data.earnings.fan_club_earned || 0) / 10).toFixed(2)),
  //       photos_and_videos: parseFloat(
  //         (
  //           (data.earned_tokens.image || 0) +
  //           (data.earned_tokens.album || 0) +
  //           (data.earned_tokens.gallery || 0) +
  //           (data.earned_tokens.video || 0) / 10
  //         ).toFixed(2)
  //       ),
  //       referrals_and_bounty: parseFloat(
  //         (
  //           (data.earnings.member_referrals_earned || 0) +
  //           (data.earnings.model_referral_earned || 0) +
  //           (data.earnings.member_bounty_referrals_earned || 0) / 10
  //         ).toFixed(2)
  //       ),
  //       total_earnings: Number(
  //         parseFloat(data.sums.tieredPayoutTotal) +
  //           parseFloat(data.sums.bonusPayoutTotal) +
  //           parseFloat(data.sums.payoutAdjustment)
  //       ),
  //       amount_until_next_tier: parseFloat(((data.amount_to_next_tier_tokens || 0) / 10).toFixed(2)),
  //     };
  //   }
  //   return {
  //     private_show_earnings: 0,
  //     tipping_show_earnings: 0,
  //     fan_club_earnings: 0,
  //     photos_and_videos: 0,
  //     referrals_and_bounty: 0,
  //     total_earnings: 0,
  //     amount_until_next_tier: 0,
  //   };
  // };

  // private getPeriodString = (date: string) => {
  //   const dates = date.split(" - ");
  //   if (dates.length) {
  //     const startDate = new Date(dates[0].replace("-08:00", ""));
  //     const endDate = new Date(dates[1].replace("-08:00", ""));
  //     const fromMonth = formatDate(startDate, "MMMM");
  //     const toMonth = formatDate(endDate, "MMMM");
  //     const fromDate = formatDate(startDate, "do");
  //     const toDate = formatDate(endDate, "do");
  //     if (fromMonth === toMonth) {
  //       return `${fromMonth} ${fromDate} - ${toDate}`;
  //     }
  //     return `${fromMonth} ${fromDate} - ${toMonth} ${toDate}`;
  //   }
  //   return "NO PERIODS";
  // };

  public async getModelCurrentTierAndTotal() {
    this.loadingCurrentTierAndTotal = true;
    let currentData;
    try {
      const response = await api.walletModelCurrent.get();
      currentData = response.data.data;
    } catch (error) {
      logger.log(
        "Wallet api error getModelCurrentTierAndTotal",
        config.showAjaxErrors ? error : ""
      );
    } finally {
      if (!!currentData) {
        if (!!currentData.period_total_earnings) {
          this.currentTotal = currentData.period_total_earnings;
        }
        if (!!currentData.tier) {
          this.currentTier = currentData.tier;
        }
        if (!!currentData.amount_to_next_tier) {
          this.amountNeededToNextTier = currentData.amount_to_next_tier;
        }
      }
      this.loadingCurrentTierAndTotal = false;
    }
  }

  private handleModelPeriodData = periodData => {
    this.periods = (periodData || []).map((period, index) => {
      if (index === 0) {
        return {
          ...period,
          title: "Current Period",
        };
      } else {
        /**
         * TODO: below is temporary code applied to handle
         * issue reported in CAMS-3360 this code should be
         * fixed after ticket CAMS-3432 is done.
         */
        if (period.start_time && period.end_time) {
          return {
            ...period,
            title:
              this.formatDateRange(period.start_time, period.end_time, {
                day: "2-digit",
                month: "2-digit",
              }) +
              ", " +
              this.formatDateYear(period.start_time),
          };
        }
        return {
          ...period,
          title:
            this.formatDateRangeOld(period.period, {
              day: "2-digit",
              month: "2-digit",
            }) +
            ", " +
            period.year,
        };
      }
    });
    if (periodData && periodData.length && (periodData[0] || {}).id) {
      this.setModelCurrentPeriod(periodData[0].id);
      this.setModelCurrentProduct(this.currentProduct);
    }
  };

  public setModelCurrentProduct = (
    productFilter?: TransactionProductFilter | null
  ) => {
    if (productFilter) {
      this.currentProduct = productFilter;
    }
    this.calculateModelEarnings();
  };

  public getModelPeriods = async () => {
    this.loadingPeriods = true;
    let periodData;
    try {
      const response = await api.walletModelPeriods.get();
      periodData = response.data.data.periods;
    } catch (error) {
      logger.log(
        "Wallet api error getModelPeriods",
        config.showAjaxErrors ? error : ""
      );
    } finally {
      this.handleModelPeriodData(periodData);
      this.loadingPeriods = false;
    }
  };

  // MODEL END

  // STUDIO

  public setStudioBroadcastingHours = (models: any): void => {
    this.earnings.forEach(earning => {
      const modelBroadcastingHours = models.models.find(
        model =>
          model.model_name.toLowerCase() ===
          earning.model_username.toLowerCase()
      );
      if (modelBroadcastingHours) {
        earning.broadcast_hours = modelBroadcastingHours.broadcasting_hours;
      }
    });
  };

  public setStudioSelectedPeriodYear = (year: number) => {
    this.setStudioSelectedPeriod({
      startYear: year,
    });
    this.periods = calculateStudioPayPeriods(
      this.selectedPeriod.startYear,
      this.periodType
    );
    this.selectedPeriod = this.periods[0] as PayPeriod;
    this.getStudioEarnings();
  };

  public setStudioSelectedPeriodDay = (periodLabel: string) => {
    const period = this.periods.find(p => p.period === periodLabel);

    if (period) {
      this.setStudioSelectedPeriod(period);
      this.getStudioEarnings();
    }
  };

  public setStudioSelectedPeriod = (updates: Object) => {
    this.selectedPeriod = {
      ...this.selectedPeriod,
      ...updates,
    };
  };

  public getStudioModels = async () => {
    this.loadingFilters = true;
    this.errorLoadingFilters = false;
    try {
      const response = await api.studioModels.get();
      this.periodType = response?.data?.id_period_type;
      this.models = [
        { ...EarningsInitialData.initModelFilter },
        ...response?.data?.data?.models,
      ];
    } catch (error) {
      logger.log(
        "Wallet api error getStudioModels",
        config.showAjaxErrors ? error : ""
      );
      this.errorLoadingFilters = true;
    } finally {
      this.periods = calculateStudioPayPeriods(
        this.selectedPeriod.startYear,
        this.periodType
      );
      this.selectedPeriod = this.periods[0] as PayPeriod;
      this.loadingFilters = false;
    }
  };

  public calculateStudioModelsBonus = () => {
    this.earnings.forEach(earning => {
      earning.amounts.bonus_tokens = calculateModelsBonusEarnings(earning);
    });
  };

  private setStudioEarningsPeriodSum = (
    period_earnings?: StudioEarningsPeriodSum
  ) => {
    this.studioEarningsPeriodSum = period_earnings
      ? { ...period_earnings }
      : null;
  };

  public getStudioEarnings = async () => {
    this.loadingEarnings = true;
    this.errorLoadingEarnings = false;
    try {
      const startDate = getFormattedPeriodFullDateYYYYMMDD(
        this.selectedPeriod,
        "start"
      );
      const endDate = getFormattedPeriodFullDateYYYYMMDD(
        this.selectedPeriod,
        "end"
      );
      const response = await api.studioEarnings.get(
        undefined,
        `?startDate=${startDate}`
      );
      const broadcastingHoursResponse =
        await api.studioModelsBroadcastingHoursForPeriod.get(
          undefined,
          `?start_date=${startDate}&end_date=${endDate}`
        );
      this.studioTotal = response?.data?.data?.total_period_earnings;
      this.setStudioEarningsPeriodSum(response?.data?.data);
      this.earnings = response?.data?.data?.period_earnings;
      this.setStudioBroadcastingHours(broadcastingHoursResponse?.data);
      this.calculateStudioModelsBonus();
      this.totalBroadcastHours =
        broadcastingHoursResponse?.data?.broadcasting_hours;
    } catch (error) {
      logger.log(
        "Wallet api error getStudioEarnings",
        config.showAjaxErrors ? error : ""
      );
      this.errorLoadingEarnings = true;
    } finally {
      this.loadingEarnings = false;
    }
  };

  public getStudioModelEarnings = async () => {
    this.loadingModelEarnings = true;
    this.errorLoadingModelEarnings = false;
    try {
      const startDate = getFormattedPeriodFullDateYYYYMMDD(
        this.selectedPeriod,
        "start"
      );
      const response = await api.studioModelEarnings.get(
        undefined,
        `${this.selectedModel?.id}/earnings?pageSize=1000&page=${this.pageCounter}&startDate=${startDate}`
      );
      this.total = response.data.data.earnings.period_total_earnings;
      this.currentTier = response.data.data.earnings.current_tier;
      this.transactions = [
        ...this.transactions,
        ...response.data.data.earnings.transactions,
      ];
      this.pageCounter++;
      this.hasMoreTransactions =
        this.transactions.length < response.data.data.pagination.total;
      return this.transactions;
    } catch (error) {
      logger.log(
        "Wallet api error getStudioModelEarnings",
        config.showAjaxErrors ? error : ""
      );
      this.errorLoadingModelEarnings = true;
      return this.transactions;
    } finally {
      this.handleModelTransactionsData(this.transactions);
      this.loadingModelEarnings = false;
    }
  };

  public setStudioActiveModel = (modelId: string) => {
    this.pageCounter = EarningsInitialData.pageCounter;
    this.hasMoreTransactions = EarningsInitialData.hasMoreTransactions;
    this.transactions = EarningsInitialData.transactions;
    this.currentPeriodTotals = EarningsInitialData.currentPeriodTotals;

    if (modelId === "0") {
      this.modelIsSelected = false;
      this.selectedModel = null;
      // this.getStudioEarnings();
    } else {
      this.models.forEach(model => {
        if (model.id === modelId || model.screen_name === modelId) {
          this.selectedModel = model;
          this.modelIsSelected = true;
          this.getStudioModelEarnings();
          if (model.screen_name === modelId) {
            this.updateEarningsDetailsData(modelId);
          }
        }
      });
    }
  };

  private updateEarningsDetailsData = (
    screen_name?: string,
    forPeriod?: Period
  ) => {
    const chosenPeriod = forPeriod
      ? forPeriod
      : this.earnings.filter(
          earning => earning.model_username === screen_name
        )[0];
    if (chosenPeriod?.earnings && chosenPeriod?.tier) {
      this.currentPeriodTotals = [
        {
          group: "Shows with members from us",
          youMake: MODEL_TRANSACTION_EARNING_TYPE.BASED_ON_TIER,
          tokens: chosenPeriod?.earnings?.tiered_tokens_received,
          cost: tokenHelper.tokenToCurrency(
            chosenPeriod?.earnings?.tiered_tokens_received
          ),
          yourCut:
            calculateModelCutPercentage(
              chosenPeriod?.earnings?.tiered_tokens_received,
              chosenPeriod?.earnings?.tiered_tokens_earned
            ) || chosenPeriod?.tier / 100,
          earnings: tokenHelper.tokenToCurrency(
            chosenPeriod?.earnings?.tiered_tokens_earned
          ),
        },
        {
          group: "Your Paid Albums and Videos",
          youMake: MODEL_TRANSACTION_EARNING_TYPE.BASED_ON_TIER,
          tokens: chosenPeriod?.earnings?.media_tokens_received,
          cost: tokenHelper.tokenToCurrency(
            chosenPeriod?.earnings?.media_tokens_received
          ),
          yourCut:
            calculateModelCutPercentage(
              chosenPeriod?.earnings?.media_tokens_received,
              chosenPeriod?.earnings?.media_tokens_received
            ) || chosenPeriod?.tier / 100,
          earnings: tokenHelper.tokenToCurrency(
            chosenPeriod?.earnings?.media_tokens_earned
          ),
        },
        {
          group: "Your Fan Clubs",
          youMake: MODEL_TRANSACTION_EARNING_TYPE.BASED_ON_TIER,
          tokens: chosenPeriod?.earnings?.fan_club_received,
          cost: tokenHelper.tokenToCurrency(
            chosenPeriod?.earnings?.fan_club_received
          ),
          yourCut:
            calculateModelCutPercentage(
              chosenPeriod?.earnings?.fan_club_received,
              chosenPeriod?.earnings?.fan_club_earned
            ) || chosenPeriod?.tier / 100,
          earnings: tokenHelper.tokenToCurrency(
            chosenPeriod?.earnings?.fan_club_earned
          ),
        },
        {
          group: "TIERED-TOTALS",
          tokens: chosenPeriod?.member_spent_tokens || 0,
          earnings: tokenHelper.tokenToCurrency(
            chosenPeriod?.period_total_earnings!
          ),
        },
        { group: "Bonuses", header: true, earnings: 0, tokens: 0 },
        {
          group: "Bonuses",
          category: "Shows with members you referred",
          youMake: MODEL_TRANSACTION_EARNING_TYPE.REFERED_BY_YOU,
          tokens: chosenPeriod?.earnings?.member_referrals_received,
          cost: tokenHelper.tokenToCurrency(
            chosenPeriod?.earnings?.member_referrals_received
          ),
          yourCut: "80-100%",
          earnings: tokenHelper.tokenToCurrency(
            chosenPeriod?.earnings?.member_referrals_earned
          ),
        },
        {
          group: "Bonuses",
          category: "By referring models",
          youMake: MODEL_TRANSACTION_EARNING_TYPE.PERC_ON_TIPS,
          tokens: chosenPeriod?.earnings?.model_referral_received,
          cost: tokenHelper.tokenToCurrency(
            chosenPeriod?.earnings?.model_referral_received
          ),
          yourCut:
            calculateModelCutPercentage(
              chosenPeriod?.earnings?.model_referral_received,
              chosenPeriod?.earnings?.model_referral_earned
            ) || DEFAULT_BONUS_CUT_PERCENTAGE,
          earnings: tokenHelper.tokenToCurrency(
            chosenPeriod?.earnings?.model_referral_earned
          ),
        },
        {
          group: "Bonuses",
          category: "By referring members who tip other models",
          youMake: MODEL_TRANSACTION_EARNING_TYPE.PERC_FOR_YEAR,
          tokens:
            chosenPeriod?.earnings?.member_referral_to_other_models_received,
          cost: tokenHelper.tokenToCurrency(
            chosenPeriod?.earnings?.member_referral_to_other_models_received
          ),
          yourCut:
            calculateModelCutPercentage(
              chosenPeriod?.earnings?.member_referral_to_other_models_received,
              chosenPeriod?.earnings?.member_referral_to_other_models_earned
            ) || DEFAULT_BONUS_CUT_PERCENTAGE,
          earnings: tokenHelper.tokenToCurrency(
            chosenPeriod?.earnings?.member_referral_to_other_models_earned
          ),
        },
        {
          group: "Bonuses",
          category: "By being a member's first paid show",
          youMake: MODEL_TRANSACTION_EARNING_TYPE.PERC_FOR_YEAR,
          tokens: chosenPeriod?.earnings?.member_bounty_referrals_received,
          cost: tokenHelper.tokenToCurrency(
            chosenPeriod?.earnings?.member_bounty_referrals_received
          ),
          yourCut:
            calculateModelCutPercentage(
              chosenPeriod?.earnings?.member_bounty_referrals_received,
              chosenPeriod?.earnings?.member_bounty_referrals_earned
            ) || DEFAULT_BONUS_CUT_PERCENTAGE,
          earnings: tokenHelper.tokenToCurrency(
            chosenPeriod?.earnings?.member_bounty_referrals_earned
          ),
        },
        {
          group: "Credits/Refunds",
          tokens: chosenPeriod?.earnings?.chargeback_tokens,
          cost: tokenHelper.tokenToCurrency(
            chosenPeriod?.earnings?.chargeback_tokens
          ),
          yourCut: DEFAULT_BONUS_CUT_PERCENTAGE,
          earnings: tokenHelper.tokenToCurrency(
            chosenPeriod?.earnings?.chargeback_tokens
          ),
        },
        {
          group: "TOTALS",
          tokens: chosenPeriod?.member_spent_tokens || 0,
          earnings: tokenHelper.tokenToCurrency(
            chosenPeriod?.period_total_earnings!
          ),
        },
      ];
    } else {
      this.currentPeriodTotals = null;
    }
  };

  public getStudioPeriodEarningSummary = async () => {
    this.loadingPeriodEarningSummary = true;
    this.errorLoadingPeriodEarningSummary = false;
    try {
      const response = await api.studioPeriodEarningSummary.get();
      this.periodEarningSummary = response?.data?.data?.period_earning_summary;
    } catch (error) {
      logger.log(
        "Wallet api error getStudioPeriodEarningSummary",
        config.showAjaxErrors ? error : ""
      );
      this.errorLoadingPeriodEarningSummary = true;
    } finally {
      this.loadingPeriodEarningSummary = false;
    }
  };

  public getStudioCurrentPeriodEarning = async () => {
    this.loadingPeriodEarningSummary = true;
    try {
      const periods = calculateStudioPayPeriods(
        moment().year(),
        this.periodType
      );
      const currentPeriod = periods[0] as PayPeriod;
      const startDate = getFormattedPeriodFullDateYYYYMMDD(
        currentPeriod,
        "start"
      );
      const response = await api.studioEarnings.get(
        undefined,
        `?startDate=${startDate}`
      );
      this.earningsForCurrentPeriod =
        response?.data?.data?.total_period_earnings;
    } catch (error) {
      logger.log(
        "Wallet api error getStudioEarnings",
        config.showAjaxErrors ? error : ""
      );
      this.errorLoadingPeriodEarningSummary = true;
    } finally {
      this.loadingPeriodEarningSummary = false;
    }
  };
  // STUDIO END

  setPayoutFilters = (filters: PayoutFilter) => {
    this.payoutFilters = filters;
  };
}
