import React from "react";
import clsx from "clsx";

type EmojiIconProps = {
  className?: string;
};

const EmojiIcon: React.FunctionComponent<EmojiIconProps> = ({
  className = "",
}) => (
  <span className={clsx("EmojiIcon", className && className)}>
    <svg viewBox="0 0 26 26">
      <path
        transform="translate(1, 1)"
        d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10  c5.5,0,10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S16.4,20,12,20z M15.5,11c0.8,0,1.5-0.7,1.5-1.5  S16.3,8,15.5,8S14,8.7,14,9.5S14.7,11,15.5,11z M8.5,11c0.8,0,1.5-0.7,1.5-1.5S9.3,8,8.5,8S7,8.7,7,9.5S7.7,11,8.5,11z M12,17.5  c2.3,0,4.3-1.5,5.1-3.5H6.9C7.7,16,9.7,17.5,12,17.5z"
      />
    </svg>
  </span>
);

export default EmojiIcon;
