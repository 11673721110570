import LocalStorage from "./local-storage";
import DateProcess from "./date-process";
import Formatter from "./formatter";
import CookieStorage from "./cookie-storage";
import { createBrowserHistory } from "history";
import SessionStorage from "./session-storage";
import Logger from "./logger";
import Cache from "./cache";
import UUID from "./uuid";

export const formatter = new Formatter();
export const cookieStorage = new CookieStorage();
export const localStorage = new LocalStorage();
export const sessionStorage = new SessionStorage();
export const logger = new Logger();
export const dateProcess = new DateProcess(formatter);
export const cache = new Cache();
export const uuid = new UUID();
export const history = createBrowserHistory();

history.listen(location => {
  const scrollToElId = (location as any)?.hash?.replace("#", "");
  if (scrollToElId) {
    setTimeout(() => {
      const el = document.getElementById(scrollToElId);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  }
});
