import React, { useCallback, useMemo } from "react";
import "./styles.scss";
import { inject, observer } from "mobx-react";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import { ShowTab } from "../_stores/broadcast/enums";
import BroadcastPrivateSettings from "./broadcast-private-settings";
import BroadcastTippingSettings from "./broadcast-tipping-settings";
import TailwindCard from "library/components/_tailwind/card";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTabs from "library/components/_tailwind/tabs";
import { Tab } from "library/components/_tailwind/tabs/types";
import { SHOWCASE_TARGET_CLASSNAMES } from "core/stores/showcase/const";
import BroadcastNudeSettings from "./broadcast-nude-settings";
import BroadcastShowTypeSelector from "../broadcast-preview/broadcast-type-of-show-selector";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import TailwindText from "library/components/_tailwind/text";
import { SHOW_TYPE_ACTIVE_SHOW_TAB_MAP } from "../_stores/broadcast/consts";
import TailwindIcon from "library/components/_tailwind/icon";
import BroadcastControlTab from "common/broadcast/broadcast-control/broadcast-control-tab";
import BroadcastConfirmStartTippingShowModal from "../broadcast-tipping-modal/confirm-start-tipping-show-modal";
import BroadcastEndTippingShowModal from "../broadcast-tipping-modal/end-tipping-show-modal";
import LanguageStore from "library/core/stores/language/LanguageStore";

interface Props {
  languageStore?: LanguageStore;
  broadcastStore?: BroadcastStore;
  layoutStore?: LayoutStore;
}

const BroadcastControlTabs: React.FunctionComponent<Props> = ({
  languageStore,
  broadcastStore,
  layoutStore,
}) => {
  const {
    setCurrentShowTab,
    currentShowTab,
    isInPrivateOrAdminShow,
    shouldShowEndTippingShowConfirmation,
    shouldShowStartTippingShowConfirmation,
    currentShowType,
  } = broadcastStore!;
  const { isMobileScreenSize } = layoutStore!;
  const { language, intl } = languageStore!;

  const showTypePanel = useMemo(() => {
    return {
      [ShowTab.NUDE]: (
        <BroadcastControlTab
          showTab={ShowTab.NUDE}
          className={"BroadcastControl__Panels"}
          allowScroll={isMobileScreenSize ? false : true}>
          <BroadcastNudeSettings />
        </BroadcastControlTab>
      ),
      [ShowTab.TIPPING]: (
        <BroadcastControlTab
          showTab={ShowTab.TIPPING}
          className={"BroadcastControl__Panels"}
          allowScroll={isMobileScreenSize ? false : true}>
          <BroadcastTippingSettings />
        </BroadcastControlTab>
      ),
      [ShowTab.FREE]: (
        <BroadcastControlTab
          showTab={ShowTab.FREE}
          className={"BroadcastControl__Panels"}
          allowScroll={isMobileScreenSize ? false : true}>
          <BroadcastPrivateSettings />
        </BroadcastControlTab>
      ),
    };
  }, [language]);

  const showSubTypes = useMemo(() => {
    return Object.values(ShowTab);
  }, []);

  const tabs: Tab[] = useMemo(() => {
    return showSubTypes.map(subType => ({
      dataTabLabel: subType.toLowerCase(),
      id: subType,
      label: (
        <TailwindText
          fontWeight='font-bold'
          display='flex'
          alignItems='items-center'>
          {subType === "goal" &&
            intl.formatMessage({
              id: "broadcast-show-type-control.tipping.item-title",
              defaultMessage: "GOAL",
            })}
          {(subType === "free" || subType === "nude") &&
            intl.formatMessage({
              id: `broadcast-show-type-control.${subType}.item-title`,
              defaultMessage: subType.toUpperCase(),
            })}
          {SHOW_TYPE_ACTIVE_SHOW_TAB_MAP[currentShowType] === subType && (
            <TailwindIcon
              style={{
                color: "#DB378E",
                marginTop: "1px",
                verticalAlign: "bottom",
              }}
              name='video-camera'
            />
          )}
        </TailwindText>
      ),
      component: showTypePanel[subType],
    }));
  }, [showSubTypes, showTypePanel, currentShowType]);

  const onChangeShowType = useCallback(
    (tabId: string) => {
      setCurrentShowTab(tabId as ShowTab);
    },
    [setCurrentShowTab]
  );

  return (
    <TailwindCard
      height='h-full'
      minHeight={"min-h-0"}
      className={["FreeToEnter"]}
      backgroundColor={"bg-card-secondary-bg-color"}
      overflow={isMobileScreenSize ? "overflow-visible" : "overflow-hidden"}>
      <TailwindFlex
        height='h-full'
        minHeight={"min-h-0"}
        flexDirection={"flex-col"}
        alignItems={"items-center"}
        justifyContent={"justify-center"}>
        <TailwindFlex
          flex={"flex-1"}
          minHeight={"min-h-0"}
          height={"h-full"}
          className={["BroadcastControl__wrapper"]}>
          <TailwindTabs
            onChange={(_tabIndex: number, tabId: string) => {
              onChangeShowType(tabId);
            }}
            activeTabIndex={showSubTypes.indexOf(currentShowTab)}
            tabs={tabs}
            height={"h-full"}
            headerProps={{
              className: [SHOWCASE_TARGET_CLASSNAMES.broadcastShowTypes],
              buttonProps: {
                dataTestId: "broadcast-show-type-selector",
                isActive: false,
                fullWidth: true,
                disabled: isInPrivateOrAdminShow,
                //padding: ["px-12"],
                justifyContent: "justify-center",
                backgroundColor: "bg-card-secondary-bg-color",
                textColor: "text-gray-500",
              },
              buttonContainerProps: {
                flex: "flex-1",
                alignItems: "items-center",
              },
              activeButtonProps: {
                isActive: true,
                disabled: false,
                textColor: "text-main-color",
                fontWeight: "font-bold",
                backgroundColor: "bg-primary-bg-color",
              },
              alignItems: "items-center",
              margin: ["mb-0"],
              width: "w-full",
              rightChild: <BroadcastShowTypeSelector />,
            }}
            bodyProps={{
              height: "h-full",
              flexDirection: "flex-col",
              backgroundColor: "bg-primary-bg-color",
              minHeight: "min-h-0",
              width: "w-full",
              maxWidth: "max-w-full",
              flexGrow: "flex-grow",
            }}
          />
        </TailwindFlex>
        {!!shouldShowEndTippingShowConfirmation && (
          <BroadcastEndTippingShowModal />
        )}
        {!!shouldShowStartTippingShowConfirmation && (
          <BroadcastConfirmStartTippingShowModal />
        )}
      </TailwindFlex>
    </TailwindCard>
  );
};

export default inject(
  "languageStore",
  "broadcastStore",
  "layoutStore"
)(observer(BroadcastControlTabs));
