import React from "react";

type Props = {};

const Gitflow: React.ComponentType<Props> = ({}) => {
  return (
    <svg
      xmlnsXlink="http://www.w3.org/1999/xlink"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 1660 763.33"
    >
      <g transform="translate(0 -20)">
        <path d="M0 0h1870.87v1322.83H0z" fill="#fff" />
        <path
          d="M700 46c0-3.3 2.7-6 6-6h388c3.3 0 6 2.7 6 6v48c0 3.3-2.7 6-6 6H706c-3.3 0-6-2.7-6-6z"
          stroke="#5e5e5e"
          strokeWidth={3}
          fill="#fff"
        />
        <use
          xlinkHref="#a"
          transform="matrix(1,0,0,1,705,45) translate(105.61728395061729 30.27777777777778)"
        />
        <use
          xlinkHref="#b"
          transform="matrix(1,0,0,1,705,45) translate(156.60493827160494 30.27777777777778)"
        />
        <use
          xlinkHref="#c"
          transform="matrix(1,0,0,1,705,45) translate(214.07407407407408 30.27777777777778)"
        />
        <path
          d="M380 366c0-3.3 2.7-6 6-6h408c3.3 0 6 2.7 6 6v51.33c0 3.32-2.7 6-6 6H386c-3.3 0-6-2.68-6-6z"
          stroke="#5e5e5e"
          strokeWidth={3}
          fill="#fff"
        />
        <use
          xlinkHref="#d"
          transform="matrix(1,0,0,1,385,364.99999999999994) translate(166.14197530864197 31.27777777777778)"
        />
        <path
          d="M1220 386c0-3.3 2.7-6 6-6h408c3.3 0 6 2.7 6 6v51.33c0 3.32-2.7 6-6 6h-408c-3.3 0-6-2.68-6-6z"
          stroke="#5e5e5e"
          strokeWidth={3}
          fill="#fff"
        />
        <use
          xlinkHref="#e"
          transform="matrix(1,0,0,1,1225,385) translate(171.11111111111111 31.27777777777778)"
        />
        <path
          d="M380 666c0-3.3 2.7-6 6-6h408c3.3 0 6 2.7 6 6v51.33c0 3.32-2.7 6-6 6H386c-3.3 0-6-2.68-6-6z"
          stroke="#5e5e5e"
          strokeWidth={3}
          fill="#fff"
        />
        <use
          xlinkHref="#f"
          transform="matrix(1,0,0,1,385,665.0000000000001) translate(192.65432098765433 31.27777777777778)"
        />
        <path d="M590 301v39" stroke="#5e5e5e" strokeWidth={2} fill="none" />
        <path d="M591 301.03h-2V300h2z" fill="#5e5e5e" />
        <path
          d="M590 355.26L585.36 341h9.28z"
          stroke="#5e5e5e"
          strokeWidth={2}
          fill="#5e5e5e"
        />
        <path
          d="M900 102.5v133.17c0 3.3-2.7 6-6 6H778.5"
          stroke="#5e5e5e"
          strokeWidth={2}
          fill="none"
        />
        <path d="M901 102.53h-2v-1.03h2z" fill="#5e5e5e" />
        <path
          d="M763.24 241.67l14.26-4.64v9.27z"
          stroke="#5e5e5e"
          strokeWidth={2}
          fill="#5e5e5e"
        />
        <path
          d="M900 102.5v133.17c0 3.3 2.7 6 6 6h95.5"
          stroke="#5e5e5e"
          strokeWidth={2}
          fill="none"
        />
        <path d="M901 102.53h-2v-1.03h2z" fill="#5e5e5e" />
        <path
          d="M1016.76 241.67l-14.26 4.63v-9.27z"
          stroke="#5e5e5e"
          strokeWidth={2}
          fill="#5e5e5e"
        />
        <path
          d="M1361 241.67h63c3.3 0 6 2.68 6 6V360"
          stroke="#5e5e5e"
          strokeWidth={2}
          fill="none"
        />
        <path d="M1361.03 242.67H1360v-2h1.03z" fill="#5e5e5e" />
        <path
          d="M1430 375.26l-4.64-14.26h9.28z"
          stroke="#5e5e5e"
          strokeWidth={2}
          fill="#5e5e5e"
        />
        <path d="M590 601v39" stroke="#5e5e5e" strokeWidth={2} fill="none" />
        <path d="M591 601.03h-2V600h2z" fill="#5e5e5e" />
        <path
          d="M590 655.26L585.36 641h9.28z"
          stroke="#5e5e5e"
          strokeWidth={2}
          fill="#5e5e5e"
        />
        <path d="M590 425.83v39" stroke="#5e5e5e" strokeWidth={2} fill="none" />
        <path d="M591 425.86h-2v-1.03h2z" fill="#5e5e5e" />
        <path
          d="M590 480.1l-4.64-14.27h9.28z"
          stroke="#5e5e5e"
          strokeWidth={2}
          fill="#5e5e5e"
        />
        <path
          d="M300 489.33c0-3.3 2.7-6 6-6h568c3.3 0 6 2.7 6 6V594c0 3.3-2.7 6-6 6H306c-3.3 0-6-2.7-6-6z"
          stroke="#000"
          strokeOpacity={0}
          strokeWidth={3}
          fill="#fff"
          fillOpacity={0}
        />
        <use
          xlinkHref="#g"
          transform="matrix(1,0,0,1,305.00000000000006,488.3333333333334) translate(26.080246913580254 44.52777777777778)"
        />
        <use
          xlinkHref="#h"
          transform="matrix(1,0,0,1,305.00000000000006,488.3333333333334) translate(109.84567901234568 44.52777777777778)"
        />
        <use
          xlinkHref="#i"
          transform="matrix(1,0,0,1,305.00000000000006,488.3333333333334) translate(241.82098765432102 44.52777777777778)"
        />
        <use
          xlinkHref="#j"
          transform="matrix(1,0,0,1,305.00000000000006,488.3333333333334) translate(292.31481481481484 44.52777777777778)"
        />
        <use
          xlinkHref="#k"
          transform="matrix(1,0,0,1,305.00000000000006,488.3333333333334) translate(376.2037037037037 44.52777777777778)"
        />
        <use
          xlinkHref="#l"
          transform="matrix(1,0,0,1,305.00000000000006,488.3333333333334) translate(400.89506172839504 44.52777777777778)"
        />
        <use
          xlinkHref="#m"
          transform="matrix(1,0,0,1,305.00000000000006,488.3333333333334) translate(437.9320987654321 44.52777777777778)"
        />
        <use
          xlinkHref="#n"
          transform="matrix(1,0,0,1,305.00000000000006,488.3333333333334) translate(472.4382716049383 44.52777777777778)"
        />
        <use
          xlinkHref="#o"
          transform="matrix(1,0,0,1,305.00000000000006,488.3333333333334) translate(503.3024691358025 44.52777777777778)"
        />
        <use
          xlinkHref="#p"
          transform="matrix(1,0,0,1,305.00000000000006,488.3333333333334) translate(164.16666666666666 71.19444444444446)"
        />
        <use
          xlinkHref="#q"
          transform="matrix(1,0,0,1,305.00000000000006,488.3333333333334) translate(250.52469135802468 71.19444444444446)"
        />
        <use
          xlinkHref="#r"
          transform="matrix(1,0,0,1,305.00000000000006,488.3333333333334) translate(287.5617283950617 71.19444444444446)"
        />
        <use
          xlinkHref="#s"
          transform="matrix(1,0,0,1,305.00000000000006,488.3333333333334) translate(328.179012345679 71.19444444444446)"
        />
        <path
          d="M420 189.33c0-3.3 2.7-6 6-6h328c3.3 0 6 2.7 6 6V294c0 3.3-2.7 6-6 6H426c-3.3 0-6-2.7-6-6z"
          stroke="#000"
          strokeOpacity={0}
          strokeWidth={3}
          fill="#fff"
          fillOpacity={0}
        />
        <g>
          <use
            xlinkHref="#t"
            transform="matrix(1,0,0,1,425,188.33333333333314) translate(33.67283950617286 44.52777777777778)"
          />
          <use
            xlinkHref="#k"
            transform="matrix(1,0,0,1,425,188.33333333333314) translate(74.29012345679016 44.52777777777778)"
          />
          <use
            xlinkHref="#j"
            transform="matrix(1,0,0,1,425,188.33333333333314) translate(98.98148148148151 44.52777777777778)"
          />
          <use
            xlinkHref="#u"
            transform="matrix(1,0,0,1,425,188.33333333333314) translate(182.87037037037038 44.52777777777778)"
          />
          <use
            xlinkHref="#v"
            transform="matrix(1,0,0,1,425,188.33333333333314) translate(212.5 44.52777777777778)"
          />
          <use
            xlinkHref="#w"
            transform="matrix(1,0,0,1,425,188.33333333333314) translate(285.27777777777777 44.52777777777778)"
          />
          <use
            xlinkHref="#x"
            transform="matrix(1,0,0,1,425,188.33333333333314) translate(120.64814814814815 71.19444444444446)"
          />
        </g>
        <path
          d="M1020 189.33c0-3.3 2.7-6 6-6h328c3.3 0 6 2.7 6 6V294c0 3.3-2.7 6-6 6h-328c-3.3 0-6-2.7-6-6z"
          stroke="#000"
          strokeOpacity={0}
          strokeWidth={3}
          fill="#fff"
          fillOpacity={0}
        />
        <g>
          <use
            xlinkHref="#t"
            transform="matrix(1,0,0,1,1025,188.33333333333314) translate(9.043209876543187 31.15277777777778)"
          />
          <use
            xlinkHref="#k"
            transform="matrix(1,0,0,1,1025,188.33333333333314) translate(49.66049382716047 31.15277777777778)"
          />
          <use
            xlinkHref="#y"
            transform="matrix(1,0,0,1,1025,188.33333333333314) translate(74.35185185185183 31.15277777777778)"
          />
          <use
            xlinkHref="#z"
            transform="matrix(1,0,0,1,1025,188.33333333333314) translate(148.30246913580245 31.15277777777778)"
          />
          <use
            xlinkHref="#l"
            transform="matrix(1,0,0,1,1025,188.33333333333314) translate(198.85802469135803 31.15277777777778)"
          />
          <use
            xlinkHref="#A"
            transform="matrix(1,0,0,1,1025,188.33333333333314) translate(235.89506172839506 31.15277777777778)"
          />
          <use
            xlinkHref="#B"
            transform="matrix(1,0,0,1,1025,188.33333333333314) translate(20.679012345679013 57.81944444444445)"
          />
          <use
            xlinkHref="#C"
            transform="matrix(1,0,0,1,1025,188.33333333333314) translate(47.839506172839506 57.81944444444445)"
          />
          <use
            xlinkHref="#D"
            transform="matrix(1,0,0,1,1025,188.33333333333314) translate(155.1851851851852 57.81944444444445)"
          />
          <use
            xlinkHref="#E"
            transform="matrix(1,0,0,1,1025,188.33333333333314) translate(226.79012345679013 57.81944444444445)"
          />
          <use
            xlinkHref="#F"
            transform="matrix(1,0,0,1,1025,188.33333333333314) translate(273.51851851851853 57.81944444444445)"
          />
          <use
            xlinkHref="#G"
            transform="matrix(1,0,0,1,1025,188.33333333333314) translate(70.06172839506172 84.48611111111111)"
          />
          <use
            xlinkHref="#H"
            transform="matrix(1,0,0,1,1025,188.33333333333314) translate(125.49382716049382 84.48611111111111)"
          />
          <use
            xlinkHref="#I"
            transform="matrix(1,0,0,1,1025,188.33333333333314) translate(156.35802469135803 84.48611111111111)"
          />
        </g>
        <path
          d="M20 326c0-3.3 2.7-6 6-6h328c3.3 0 6 2.7 6 6v131.33c0 3.32-2.7 6-6 6H26c-3.3 0-6-2.68-6-6z"
          stroke="#000"
          strokeOpacity={0}
          strokeWidth={3}
          fill="#fff"
          fillOpacity={0}
        />
        <g>
          <use
            xlinkHref="#J"
            transform="matrix(1,0,0,1,25,325) translate(0.030864197530860338 30.34027777777778)"
          />
          <use
            xlinkHref="#K"
            transform="matrix(1,0,0,1,25,325) translate(39.04320987654321 30.34027777777778)"
          />
          <use
            xlinkHref="#L"
            transform="matrix(1,0,0,1,25,325) translate(75.83333333333334 30.34027777777778)"
          />
          <use
            xlinkHref="#M"
            transform="matrix(1,0,0,1,25,325) translate(106.69753086419755 30.34027777777778)"
          />
          <use
            xlinkHref="#N"
            transform="matrix(1,0,0,1,25,325) translate(144.90740740740742 30.34027777777778)"
          />
          <use
            xlinkHref="#O"
            transform="matrix(1,0,0,1,25,325) translate(191.75925925925927 30.34027777777778)"
          />
          <use
            xlinkHref="#P"
            transform="matrix(1,0,0,1,25,325) translate(233.73456790123458 30.34027777777778)"
          />
          <use
            xlinkHref="#Q"
            transform="matrix(1,0,0,1,25,325) translate(305.2777777777778 30.34027777777778)"
          />
          <use
            xlinkHref="#R"
            transform="matrix(1,0,0,1,25,325) translate(2.129629629629619 57.00694444444445)"
          />
          <use
            xlinkHref="#k"
            transform="matrix(1,0,0,1,25,325) translate(92.19135802469134 57.00694444444445)"
          />
          <use
            xlinkHref="#S"
            transform="matrix(1,0,0,1,25,325) translate(116.8827160493827 57.00694444444445)"
          />
          <use
            xlinkHref="#M"
            transform="matrix(1,0,0,1,25,325) translate(158.858024691358 57.00694444444445)"
          />
          <use
            xlinkHref="#T"
            transform="matrix(1,0,0,1,25,325) translate(197.06790123456787 57.00694444444445)"
          />
          <use
            xlinkHref="#U"
            transform="matrix(1,0,0,1,25,325) translate(287.19135802469134 57.00694444444445)"
          />
          <use
            xlinkHref="#V"
            transform="matrix(1,0,0,1,25,325) translate(14.290123456790127 83.67361111111111)"
          />
          <use
            xlinkHref="#W"
            transform="matrix(1,0,0,1,25,325) translate(77.12962962962965 83.67361111111111)"
          />
          <use
            xlinkHref="#X"
            transform="matrix(1,0,0,1,25,325) translate(122.68518518518519 83.67361111111111)"
          />
          <use
            xlinkHref="#Y"
            transform="matrix(1,0,0,1,25,325) translate(179.41358024691357 83.67361111111111)"
          />
          <use
            xlinkHref="#Z"
            transform="matrix(1,0,0,1,25,325) translate(236.69753086419752 83.67361111111111)"
          />
          <use
            xlinkHref="#Q"
            transform="matrix(1,0,0,1,25,325) translate(291.01851851851853 83.67361111111111)"
          />
          <use
            xlinkHref="#aa"
            transform="matrix(1,0,0,1,25,325) translate(126.14197530864197 110.3402777777778)"
          />
        </g>
        <path
          d="M20 626c0-3.3 2.7-6 6-6h328c3.3 0 6 2.7 6 6v131.33c0 3.32-2.7 6-6 6H26c-3.3 0-6-2.68-6-6z"
          stroke="#000"
          strokeOpacity={0}
          strokeWidth={3}
          fill="#fff"
          fillOpacity={0}
        />
        <g>
          <use
            xlinkHref="#ab"
            transform="matrix(1,0,0,1,25,625.0000000000002) translate(1.0185185185185048 72.21527777777777)"
          />
          <use
            xlinkHref="#ac"
            transform="matrix(1,0,0,1,25,625.0000000000002) translate(38.05555555555554 72.21527777777777)"
          />
          <use
            xlinkHref="#K"
            transform="matrix(1,0,0,1,25,625.0000000000002) translate(110.83333333333331 72.21527777777777)"
          />
          <use
            xlinkHref="#ad"
            transform="matrix(1,0,0,1,25,625.0000000000002) translate(147.62345679012344 72.21527777777777)"
          />
          <use
            xlinkHref="#H"
            transform="matrix(1,0,0,1,25,625.0000000000002) translate(189.59876543209876 72.21527777777777)"
          />
          <use
            xlinkHref="#ae"
            transform="matrix(1,0,0,1,25,625.0000000000002) translate(220.46296296296296 72.21527777777777)"
          />
          <use
            xlinkHref="#af"
            transform="matrix(1,0,0,1,25,625.0000000000002) translate(261.14197530864203 72.21527777777777)"
          />
        </g>
        <defs>
          <path
            fill="#333"
            d="M137-103V0h-34v-103L8-248h37l75 118 75-118h37"
            id="ag"
          />
          <path
            fill="#333"
            d="M100-194c62-1 85 37 85 99 1 63-27 99-86 99S16-35 15-95c0-66 28-99 85-99zM99-20c44 1 53-31 53-75 0-43-8-75-51-75s-53 32-53 75 10 74 51 75"
            id="ah"
          />
          <path
            fill="#333"
            d="M84 4C-5 8 30-112 23-190h32v120c0 31 7 50 39 49 72-2 45-101 50-169h31l1 190h-30c-1-10 1-25-2-33-11 22-28 36-60 37"
            id="ai"
          />
          <path
            fill="#333"
            d="M114-163C36-179 61-72 57 0H25l-1-190h30c1 12-1 29 2 39 6-27 23-49 58-41v29"
            id="aj"
          />
          <g id="a">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ag"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.777777777777777,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,25.123456790123456,0)"
              xlinkHref="#ai"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,37.46913580246913,0)"
              xlinkHref="#aj"
            />
          </g>
          <path
            fill="#333"
            d="M266 0h-40l-56-210L115 0H75L2-248h35L96-30l15-64 43-154h32l59 218 59-218h35"
            id="ak"
          />
          <path
            fill="#333"
            d="M143 0L79-87 56-68V0H24v-261h32v163l83-92h37l-77 82L181 0h-38"
            id="al"
          />
          <g id="b">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ak"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,20.493827160493826,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,32.839506172839506,0)"
              xlinkHref="#aj"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,40.18518518518518,0)"
              xlinkHref="#al"
            />
          </g>
          <path
            fill="#333"
            d="M160-131c35 5 61 23 61 61C221 17 115-2 30 0v-248c76 3 177-17 177 60 0 33-19 50-47 57zm-97-11c50-1 110 9 110-42 0-47-63-36-110-37v79zm0 115c55-2 124 14 124-45 0-56-70-42-124-44v89"
            id="am"
          />
          <path
            fill="#333"
            d="M141-36C126-15 110 5 73 4 37 3 15-17 15-53c-1-64 63-63 125-63 3-35-9-54-41-54-24 1-41 7-42 31l-33-3c5-37 33-52 76-52 45 0 72 20 72 64v82c-1 20 7 32 28 27v20c-31 9-61-2-59-35zM48-53c0 20 12 33 32 33 41-3 63-29 60-74-43 2-92-5-92 41"
            id="an"
          />
          <path
            fill="#333"
            d="M117-194c89-4 53 116 60 194h-32v-121c0-31-8-49-39-48C34-167 62-67 57 0H25l-1-190h30c1 10-1 24 2 32 11-22 29-35 61-36"
            id="ao"
          />
          <path
            fill="#333"
            d="M96-169c-40 0-48 33-48 73s9 75 48 75c24 0 41-14 43-38l32 2c-6 37-31 61-74 61-59 0-76-41-82-99-10-93 101-131 147-64 4 7 5 14 7 22l-32 3c-4-21-16-35-41-35"
            id="ap"
          />
          <path
            fill="#333"
            d="M106-169C34-169 62-67 57 0H25v-261h32l-1 103c12-21 28-36 61-36 89 0 53 116 60 194h-32v-121c2-32-8-49-39-48"
            id="aq"
          />
          <g id="c">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#am"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,14.814814814814813,0)"
              xlinkHref="#aj"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,22.160493827160494,0)"
              xlinkHref="#an"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,34.50617283950617,0)"
              xlinkHref="#ao"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,46.85185185185185,0)"
              xlinkHref="#ap"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,57.96296296296296,0)"
              xlinkHref="#aq"
            />
          </g>
          <path
            fill="#333"
            d="M85-194c31 0 48 13 60 33l-1-100h32l1 261h-30c-2-10 0-23-3-31C134-8 116 4 85 4 32 4 16-35 15-94c0-66 23-100 70-100zm9 24c-40 0-46 34-46 75 0 40 6 74 45 74 42 0 51-32 51-76 0-42-9-74-50-73"
            id="ar"
          />
          <path
            fill="#333"
            d="M100-194c63 0 86 42 84 106H49c0 40 14 67 53 68 26 1 43-12 49-29l28 8c-11 28-37 45-77 45C44 4 14-33 15-96c1-61 26-98 85-98zm52 81c6-60-76-77-97-28-3 7-6 17-6 28h103"
            id="as"
          />
          <path fill="#333" d="M108 0H70L1-190h34L89-25l56-165h34" id="at" />
          <path fill="#333" d="M24 0v-261h32V0H24" id="au" />
          <path
            fill="#333"
            d="M115-194c55 1 70 41 70 98S169 2 115 4C84 4 66-9 55-30l1 105H24l-1-265h31l2 30c10-21 28-34 59-34zm-8 174c40 0 45-34 45-75s-6-73-45-74c-42 0-51 32-51 76 0 43 10 73 51 73"
            id="av"
          />
          <g id="d">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ar"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,24.691358024691358,0)"
              xlinkHref="#at"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,35.80246913580247,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,48.148148148148145,0)"
              xlinkHref="#au"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,53.02469135802469,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,65.37037037037037,0)"
              xlinkHref="#av"
            />
          </g>
          <path
            fill="#333"
            d="M210-169c-67 3-38 105-44 169h-31v-121c0-29-5-50-35-48C34-165 62-65 56 0H25l-1-190h30c1 10-1 24 2 32 10-44 99-50 107 0 11-21 27-35 58-36 85-2 47 119 55 194h-31v-121c0-29-5-49-35-48"
            id="aw"
          />
          <path
            fill="#333"
            d="M135-143c-3-34-86-38-87 0 15 53 115 12 119 90S17 21 10-45l28-5c4 36 97 45 98 0-10-56-113-15-118-90-4-57 82-63 122-42 12 7 21 19 24 35"
            id="ax"
          />
          <path
            fill="#333"
            d="M59-47c-2 24 18 29 38 22v24C64 9 27 4 27-40v-127H5v-23h24l9-43h21v43h35v23H59v120"
            id="ay"
          />
          <g id="e">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aw"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,18.456790123456788,0)"
              xlinkHref="#an"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,30.80246913580247,0)"
              xlinkHref="#ax"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,41.91358024691358,0)"
              xlinkHref="#ay"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,48.086419753086425,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,60.4320987654321,0)"
              xlinkHref="#aj"
            />
          </g>
          <path
            fill="#333"
            d="M145-31C134-9 116 4 85 4 32 4 16-35 15-94c0-59 17-99 70-100 32-1 48 14 60 33 0-11-1-24 2-32h30l-1 268h-32zM93-21c41 0 51-33 51-76s-8-73-50-73c-40 0-46 35-46 75s5 74 45 74"
            id="az"
          />
          <g id="f">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#az"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#an"
            />
          </g>
          <path
            fill="#333"
            d="M233-177c-1 41-23 64-60 70L243 0h-38l-65-103H63V0H30v-248c88 3 205-21 203 71zM63-129c60-2 137 13 137-47 0-61-80-42-137-45v92"
            id="aA"
          />
          <path
            fill="#333"
            d="M177-190C167-65 218 103 67 71c-23-6-38-20-44-43l32-5c15 47 100 32 89-28v-30C133-14 115 1 83 1 29 1 15-40 15-95c0-56 16-97 71-98 29-1 48 16 59 35 1-10 0-23 2-32h30zM94-22c36 0 50-32 50-73 0-42-14-75-50-75-39 0-46 34-46 75s6 73 46 73"
            id="aB"
          />
          <g id="g">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aA"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,15.987654320987653,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,28.333333333333332,0)"
              xlinkHref="#aB"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,40.67901234567901,0)"
              xlinkHref="#ai"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,53.02469135802469,0)"
              xlinkHref="#au"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,57.901234567901234,0)"
              xlinkHref="#an"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,70.24691358024691,0)"
              xlinkHref="#aj"
            />
          </g>
          <path
            fill="#333"
            d="M179-190L93 31C79 59 56 82 12 73V49c39 6 53-20 64-50L1-190h34L92-34l54-156h33"
            id="aC"
          />
          <g id="h">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ar"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,24.691358024691358,0)"
              xlinkHref="#av"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,37.03703703703704,0)"
              xlinkHref="#au"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,41.91358024691358,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,54.25925925925926,0)"
              xlinkHref="#aC"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,65.37037037037037,0)"
              xlinkHref="#aw"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,83.82716049382715,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,96.17283950617283,0)"
              xlinkHref="#ao"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,108.51851851851852,0)"
              xlinkHref="#ay"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,114.69135802469135,0)"
              xlinkHref="#ax"
            />
          </g>
          <path
            fill="#333"
            d="M101-234c-31-9-42 10-38 44h38v23H63V0H32v-167H5v-23h27c-7-52 17-82 69-68v24"
            id="aD"
          />
          <g id="i">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aD"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,6.172839506172839,0)"
              xlinkHref="#aj"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,13.518518518518519,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,25.864197530864196,0)"
              xlinkHref="#aw"
            />
          </g>
          <g id="j">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ar"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,24.691358024691358,0)"
              xlinkHref="#at"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,35.80246913580247,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,48.148148148148145,0)"
              xlinkHref="#au"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,53.02469135802469,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,65.37037037037037,0)"
              xlinkHref="#av"
            />
          </g>
          <g id="k">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ay"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,6.172839506172839,0)"
              xlinkHref="#ah"
            />
          </g>
          <path
            fill="#333"
            d="M140-251c81 0 123 46 123 126C263-53 228-8 163 1c7 30 30 48 69 40v23c-55 16-95-15-103-61C56-3 17-48 17-125c0-80 42-126 123-126zm0 227c63 0 89-41 89-101s-29-99-89-99c-61 0-89 39-89 99S79-25 140-24"
            id="aE"
          />
          <path
            fill="#333"
            d="M205 0l-28-72H64L36 0H1l101-248h38L239 0h-34zm-38-99l-47-123c-12 45-31 82-46 123h93"
            id="aF"
          />
          <g id="l">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aE"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,17.28395061728395,0)"
              xlinkHref="#aF"
            />
          </g>
          <path
            fill="#333"
            d="M24-231v-30h32v30H24zM24 0v-190h32V0H24"
            id="aG"
          />
          <g id="m">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#at"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,11.11111111111111,0)"
              xlinkHref="#aG"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,15.987654320987653,0)"
              xlinkHref="#an"
            />
          </g>
          <g id="n">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#an"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#ao"
            />
          </g>
          <path
            fill="#333"
            d="M240 0l2-218c-23 76-54 145-80 218h-23L58-218 59 0H30v-248h44l77 211c21-75 51-140 76-211h43V0h-30"
            id="aH"
          />
          <path fill="#333" d="M33 0v-38h34V0H33" id="aI" />
          <g id="o">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aH"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,18.456790123456788,0)"
              xlinkHref="#aA"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,34.44444444444444,0)"
              xlinkHref="#aI"
            />
          </g>
          <g id="p">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#an"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#av"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,24.691358024691358,0)"
              xlinkHref="#av"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,37.03703703703704,0)"
              xlinkHref="#aj"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,44.382716049382715,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,56.72839506172839,0)"
              xlinkHref="#at"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,67.8395061728395,0)"
              xlinkHref="#as"
            />
          </g>
          <g id="q">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ay"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,6.172839506172839,0)"
              xlinkHref="#aq"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,18.51851851851852,0)"
              xlinkHref="#as"
            />
          </g>
          <g id="r">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aH"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,18.456790123456788,0)"
              xlinkHref="#aA"
            />
          </g>
          <g id="s">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aC"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,11.11111111111111,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,23.45679012345679,0)"
              xlinkHref="#ai"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,35.80246913580247,0)"
              xlinkHref="#aj"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,43.148148148148145,0)"
              xlinkHref="#ax"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,54.25925925925925,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,66.60493827160494,0)"
              xlinkHref="#au"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,71.48148148148148,0)"
              xlinkHref="#aD"
            />
          </g>
          <g id="t">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aH"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,18.456790123456788,0)"
              xlinkHref="#aA"
            />
          </g>
          <path
            fill="#333"
            d="M115-194c53 0 69 39 70 98 0 66-23 100-70 100C84 3 66-7 56-30L54 0H23l1-261h32v101c10-23 28-34 59-34zm-8 174c40 0 45-34 45-75 0-40-5-75-45-74-42 0-51 32-51 76 0 43 10 73 51 73"
            id="aJ"
          />
          <g id="u">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aJ"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#aC"
            />
          </g>
          <g id="v">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aB"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,24.691358024691358,0)"
              xlinkHref="#ay"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,30.864197530864196,0)"
              xlinkHref="#ay"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,37.03703703703704,0)"
              xlinkHref="#aG"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,41.91358024691358,0)"
              xlinkHref="#ao"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,54.25925925925926,0)"
              xlinkHref="#aB"
            />
          </g>
          <g id="w">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aG"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,4.876543209876543,0)"
              xlinkHref="#ay"
            />
          </g>
          <path
            fill="#333"
            d="M206 0h-36l-40-164L89 0H53L-1-190h32L70-26l43-164h34l41 164 42-164h31"
            id="aK"
          />
          <g id="x">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aj"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,7.345679012345679,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,19.691358024691358,0)"
              xlinkHref="#at"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,30.80246913580247,0)"
              xlinkHref="#aG"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,35.67901234567901,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,48.02469135802469,0)"
              xlinkHref="#aK"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,64.01234567901234,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,76.35802469135803,0)"
              xlinkHref="#ar"
            />
          </g>
          <g id="y">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aw"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,18.456790123456788,0)"
              xlinkHref="#an"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,30.80246913580247,0)"
              xlinkHref="#ax"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,41.91358024691358,0)"
              xlinkHref="#ay"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,48.086419753086425,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,60.4320987654321,0)"
              xlinkHref="#aj"
            />
          </g>
          <g id="z">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#an"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#aD"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,18.51851851851852,0)"
              xlinkHref="#ay"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,24.691358024691358,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,37.03703703703704,0)"
              xlinkHref="#aj"
            />
          </g>
          <g id="A">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#an"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#av"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,24.691358024691358,0)"
              xlinkHref="#av"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,37.03703703703704,0)"
              xlinkHref="#aj"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,44.382716049382715,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,56.72839506172839,0)"
              xlinkHref="#at"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,67.8395061728395,0)"
              xlinkHref="#an"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,80.18518518518519,0)"
              xlinkHref="#au"
            />
          </g>
          <path
            fill="#333"
            d="M234-2c-28 10-62 0-77-18C117 19 8 12 13-65c3-43 29-61 60-76-25-40-17-114 47-108 35 3 59 15 59 50 0 44-40 53-71 69 14 26 32 50 51 72 14-21 24-43 30-72l26 8c-9 33-21 57-38 82 13 13 33 22 57 15v23zM97-151c25-10 52-18 56-48-1-18-13-29-33-29-42 0-39 50-23 77zM42-66c-3 51 71 58 98 28-20-24-41-51-56-80-23 10-40 24-42 52"
            id="aL"
          />
          <use
            transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
            xlinkHref="#aL"
            id="B"
          />
          <g id="C">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ao"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,24.691358024691358,0)"
              xlinkHref="#ap"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,35.80246913580247,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,48.148148148148145,0)"
              xlinkHref="#ax"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,59.25925925925925,0)"
              xlinkHref="#ax"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,70.37037037037037,0)"
              xlinkHref="#an"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,82.71604938271605,0)"
              xlinkHref="#aj"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,90.06172839506173,0)"
              xlinkHref="#aC"
            />
          </g>
          <g id="D">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ax"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,11.11111111111111,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,23.45679012345679,0)"
              xlinkHref="#ay"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,29.62962962962963,0)"
              xlinkHref="#ai"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,41.97530864197531,0)"
              xlinkHref="#av"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,54.32098765432099,0)"
              xlinkHref="#ax"
            />
          </g>
          <path
            fill="#333"
            d="M87 75C49 33 22-17 22-94c0-76 28-126 65-167h31c-38 41-64 92-64 168S80 34 118 75H87"
            id="aM"
          />
          <g id="E">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aM"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,7.345679012345679,0)"
              xlinkHref="#au"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.222222222222221,0)"
              xlinkHref="#aG"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,17.098765432098766,0)"
              xlinkHref="#al"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,28.209876543209877,0)"
              xlinkHref="#as"
            />
          </g>
          <g id="F">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#ao"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,24.691358024691358,0)"
              xlinkHref="#at"
            />
          </g>
          <path
            fill="#333"
            d="M33-261c38 41 65 92 65 168S71 34 33 75H2C39 34 66-17 66-93S39-220 2-261h31"
            id="aN"
          />
          <g id="G">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#at"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,11.11111111111111,0)"
              xlinkHref="#an"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,23.45679012345679,0)"
              xlinkHref="#aj"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,30.80246913580247,0)"
              xlinkHref="#ax"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,41.91358024691358,0)"
              xlinkHref="#aN"
            />
          </g>
          <g id="H">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#ao"
            />
          </g>
          <g id="I">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#av"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#aj"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,19.691358024691358,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,32.03703703703704,0)"
              xlinkHref="#ar"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,44.382716049382715,0)"
              xlinkHref="#ai"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,56.72839506172839,0)"
              xlinkHref="#ap"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,67.8395061728395,0)"
              xlinkHref="#ay"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,74.01234567901234,0)"
              xlinkHref="#aG"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,78.88888888888889,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,91.23456790123457,0)"
              xlinkHref="#ao"
            />
          </g>
          <g id="J">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ak"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,20.493827160493826,0)"
              xlinkHref="#as"
            />
          </g>
          <g id="K">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aK"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,15.987654320987653,0)"
              xlinkHref="#aG"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,20.864197530864196,0)"
              xlinkHref="#au"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,25.74074074074074,0)"
              xlinkHref="#au"
            />
          </g>
          <g id="L">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ar"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#ah"
            />
          </g>
          <g id="M">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#ai"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,24.691358024691358,0)"
              xlinkHref="#aj"
            />
          </g>
          <g id="N">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#aK"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,28.333333333333332,0)"
              xlinkHref="#ao"
            />
          </g>
          <g id="O">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ar"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,24.691358024691358,0)"
              xlinkHref="#at"
            />
          </g>
          <g id="P">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ay"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,6.172839506172839,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,18.51851851851852,0)"
              xlinkHref="#ax"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,29.62962962962963,0)"
              xlinkHref="#ay"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,35.80246913580247,0)"
              xlinkHref="#aG"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,40.67901234567901,0)"
              xlinkHref="#ao"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,53.02469135802469,0)"
              xlinkHref="#aB"
            />
          </g>
          <g id="Q">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#ao"
            />
          </g>
          <path
            fill="#333"
            d="M68-38c1 34 0 65-14 84H32c9-13 17-26 17-46H33v-38h35"
            id="aO"
          />
          <g id="R">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ar"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,24.691358024691358,0)"
              xlinkHref="#at"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,35.80246913580247,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,48.148148148148145,0)"
              xlinkHref="#au"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,53.02469135802469,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,65.37037037037037,0)"
              xlinkHref="#av"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,77.71604938271605,0)"
              xlinkHref="#aO"
            />
          </g>
          <g id="S">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ax"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,11.11111111111111,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,23.45679012345679,0)"
              xlinkHref="#as"
            />
          </g>
          <g id="T">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ap"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,11.11111111111111,0)"
              xlinkHref="#aq"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,23.45679012345679,0)"
              xlinkHref="#an"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,35.80246913580247,0)"
              xlinkHref="#ao"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,48.148148148148145,0)"
              xlinkHref="#aB"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,60.49382716049382,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,72.8395061728395,0)"
              xlinkHref="#ax"
            />
          </g>
          <g id="U">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#av"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#au"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,17.22222222222222,0)"
              xlinkHref="#an"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,29.5679012345679,0)"
              xlinkHref="#aC"
            />
          </g>
          <g id="V">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ao"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#aG"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,17.22222222222222,0)"
              xlinkHref="#ap"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,28.333333333333332,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,40.67901234567901,0)"
              xlinkHref="#au"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,45.55555555555556,0)"
              xlinkHref="#aC"
            />
          </g>
          <g id="W">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aK"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,15.987654320987653,0)"
              xlinkHref="#aG"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,20.864197530864196,0)"
              xlinkHref="#ay"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,27.037037037037035,0)"
              xlinkHref="#aq"
            />
          </g>
          <g id="X">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#ay"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,18.51851851851852,0)"
              xlinkHref="#aq"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,30.864197530864196,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,43.20987654320987,0)"
              xlinkHref="#aj"
            />
          </g>
          <path fill="#333" d="M47-170H22l-4-78h33" id="aP" />
          <g id="Y">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ar"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,24.691358024691358,0)"
              xlinkHref="#at"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,35.80246913580247,0)"
              xlinkHref="#aP"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,40,0)"
              xlinkHref="#ax"
            />
          </g>
          <g id="Z">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ap"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,11.11111111111111,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,23.45679012345679,0)"
              xlinkHref="#ar"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,35.80246913580247,0)"
              xlinkHref="#as"
            />
          </g>
          <g id="aa">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ar"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,24.691358024691358,0)"
              xlinkHref="#at"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,35.80246913580247,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,48.148148148148145,0)"
              xlinkHref="#au"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,53.02469135802469,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,65.37037037037037,0)"
              xlinkHref="#av"
            />
          </g>
          <g id="ab">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aE"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,17.28395061728395,0)"
              xlinkHref="#aF"
            />
          </g>
          <g id="ac">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#av"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,24.691358024691358,0)"
              xlinkHref="#ah"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,37.03703703703704,0)"
              xlinkHref="#av"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,49.382716049382715,0)"
              xlinkHref="#au"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,54.25925925925926,0)"
              xlinkHref="#as"
            />
          </g>
          <g id="ad">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ay"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,6.172839506172839,0)"
              xlinkHref="#as"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,18.51851851851852,0)"
              xlinkHref="#ax"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,29.62962962962963,0)"
              xlinkHref="#ay"
            />
          </g>
          <g id="ae">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#ay"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,6.172839506172839,0)"
              xlinkHref="#aq"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,18.51851851851852,0)"
              xlinkHref="#aG"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,23.395061728395063,0)"
              xlinkHref="#ax"
            />
          </g>
          <g id="af">
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,0,0)"
              xlinkHref="#aJ"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,12.345679012345679,0)"
              xlinkHref="#aj"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,19.691358024691358,0)"
              xlinkHref="#an"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,32.03703703703704,0)"
              xlinkHref="#ao"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,44.382716049382715,0)"
              xlinkHref="#ap"
            />
            <use
              transform="matrix(0.06172839506172839,0,0,0.06172839506172839,55.49382716049382,0)"
              xlinkHref="#aq"
            />
          </g>
        </defs>
      </g>
    </svg>
  );
};

export default Gitflow;
