import React from "react";
import { inject, observer } from "mobx-react";
import BroadcastStore from "common/broadcast/_stores/broadcast/BroadcastStore";
import TailwindButton from "library/components/_tailwind/button";

type Props = {
  broadcastStore?: BroadcastStore;
};

const QABroadcaster: React.ComponentType<Props> = ({ broadcastStore }) => {
  const {
    setRecordingDurationInSeconds,
    recordingDurationInSeconds,
    isRecording,
  } = broadcastStore!;
  return (
    <React.Fragment>
      {isRecording && (
        <React.Fragment>
          <div>
            Current Duration in Minutes:{" "}
            {Math.round(recordingDurationInSeconds / 60)}
          </div>
          <div>
            Remaining seconds left until it is full minute:{" "}
            {60 - (recordingDurationInSeconds % 60)}
          </div>
          <TailwindButton
            backgroundColor={"primary"}
            onClick={() => setRecordingDurationInSeconds(58 * 60)}>
            Set recording duration to 58th minute to see if it stops at end of
            59th minute
          </TailwindButton>
          <div>Note: recording stops at 59 minutes 59seconds</div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default inject("broadcastStore")(observer(QABroadcaster));
