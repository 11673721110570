import io from "socket.io-client";
import config, { enableFrequentSocketLogs } from "core/config";
import { logger } from "library/core/utility";
import { IChatSocketMethods } from "common/broadcast/_stores/chat/interfaces";
import {
  SocketMiddlewareCallback,
  SocketOnAuthFailedCallback,
  SocketOnConnectedCallback,
  SocketOnDisconnectedCallback,
} from "common/broadcast/_stores/common/types";
import { snackbarStore } from "library/core/stores/snackbar/SnackbarStore";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";

const CHAT_NAMESPACE = "/chat";

export type IChatLanguageTranslation =
  | "en"
  | "de"
  | "es"
  | "it"
  | "pt"
  | "ro"
  | "ru"
  | "fr";

const logPrefix = "[ChatSocket]:";
const maxReloadRetryCount = 5;

class ChatSocket {
  socket: io | undefined = undefined;
  private openTimeout;
  private reloadRetryCount: number = 0;
  private isRetrying: boolean = false;
  private onConnectedCallback: SocketOnConnectedCallback = undefined;
  private onDisconnectedCallback: SocketOnDisconnectedCallback = undefined;
  private onAuthFailedCallback: SocketOnAuthFailedCallback = undefined;
  private middlewareCallback: SocketMiddlewareCallback = undefined;
  private username: string = "";
  private accessToken: string = "";
  private events: any = {};
  private listeners: IChatSocketMethods | undefined = undefined;

  constructor() {
    this.setSocketInstance();
  }

  setSocketInstance = () => {
    this.socket = io(`${config.chatServer}${CHAT_NAMESPACE}`, {
      autoConnect: false,
      reconnection: false,
      transports: ["websocket"],
    });
  };

  unsetSocketInstance = () => {
    this.socket?.destroy();
    this.socket = undefined;
  };

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  on = (eventName: string, callback: Function) => {
    this.socket?.on(eventName, args => {
      if (this.middlewareCallback) {
        this.middlewareCallback(eventName, args);
      }
      callback(args);
    });
  };

  off = (eventName: string) => {
    this.socket?.off(eventName);
  };

  connect = (
    username: string,
    accessToken: string,
    onAuthFailedCallback: SocketOnAuthFailedCallback,
    onConnectedCallback: SocketOnConnectedCallback,
    onDisconnectedCallback: SocketOnDisconnectedCallback,
    middlewareCallback: SocketMiddlewareCallback,
    listeners: IChatSocketMethods
  ) => {
    this.log("init started");
    this.username = username;
    this.accessToken = accessToken;
    this.onAuthFailedCallback = onAuthFailedCallback;
    this.onConnectedCallback = onConnectedCallback;
    this.onDisconnectedCallback = onDisconnectedCallback;
    this.middlewareCallback = middlewareCallback;
    this.listeners = listeners;
    this.openSocket({
      username,
      token: accessToken,
    });
    this.log("init finished");
  };

  addEventListeners = () => {
    this.removeEventListeners();
    if (this.listeners) {
      Object.keys(this.listeners).forEach(eventName => {
        this.on(eventName, this.listeners![eventName]);
      });
    }
  };

  removeEventListeners = () => {
    if (this.listeners) {
      Object.keys(this.listeners).forEach(eventName => {
        this.off(eventName);
      });
    }
  };

  addConnectionListeners = () => {
    this.log("addListeners started");
    this.removeConnectionListeners();
    this.on("connect", this.handleConnected);
    this.on("connect_error", this.handleConnectErrored);
    this.on("connect_timeout", this.handleConnectTimedOut);
    this.on("disconnect", this.handleDisconnected);
    this.log("addListeners finished");
  };

  removeConnectionListeners = () => {
    this.log("removeListeners started");
    this.off("connect");
    this.off("connect_error");
    this.off("connect_timeout");
    this.off("disconnect");
    this.log("removeListeners finished");
  };

  addEngineListeners = () => {
    this.log("addEngineListeners started");
    this.removeEngineListeners();
    if (this.socket?.io?.engine) {
      this.socket?.io.engine.on("packet", this.handlePacketReceived);
    }
    this.log("addEngineListeners finished");
  };

  removeEngineListeners = () => {
    this.log("removeEngineListeners started");
    if (this.socket?.io?.engine) {
      this.socket?.io.engine.off("packet", this.handlePacketReceived);
    }
    this.log("removeEngineListeners finished");
  };

  openSocket = query => {
    if (!this.isRetrying) {
      this.reloadRetryCount = 0;
    }
    this.setSocketInstance();
    this.addConnectionListeners();
    this.removeEngineListeners();
    this.log("openSocket started with query", query);
    if (this.socket) {
      this.socket.io.opts.query = query;
      this.socket.open();
    }
    // these need to be added after socket is opened
    this.addEngineListeners();
    this.addEventListeners();
    this.log("openSocket finished");
  };

  handlePacketReceived = async data => {
    // listen to all events, even for the non-namespace ones.
    if (enableFrequentSocketLogs) {
      this.log(
        "handlePacketReceived started"
        //data
      );
    }
    if (data?.data?.includes("Unauthorized - Token not provided")) {
      this.log(
        "handlePacketReceived found user token is invalid or not provided"
      );
      await this.reload();
    } else {
      this.setIsRetrying(false);
    }
    if (enableFrequentSocketLogs) {
      this.log("handlePacketReceived finished");
    }
  };

  handleConnected = async () => {
    this.log("handleConnected started");
    try {
      this.setIsRetrying(false);
      if (this.onConnectedCallback) {
        await this.onConnectedCallback();
      }
      if (this.middlewareCallback) {
        await this.middlewareCallback("connect");
      }
      this.log("handleConnected success");
    } catch (error) {
      this.log("handleConnected failed", error);
    }
    this.log("handleConnected finished");
  };

  handleConnectErrored = async error => {
    this.log("handleConnectError started", error);
    if (this.middlewareCallback) {
      await this.middlewareCallback("connect_error");
    }
    await this.reload();
    this.log("handleConnectError finished");
  };

  handleConnectTimedOut = async error => {
    this.log("handleConnectTimeout started", error);
    if (this.middlewareCallback) {
      await this.middlewareCallback("connect_timeout");
    }
    await this.reload();
    this.log("handleConnectTimeout finished");
  };

  handleDisconnected = async (reason: string) => {
    this.log("handleDisconnect started with reason", reason);
    if (this.middlewareCallback) {
      await this.middlewareCallback("disconnect", reason);
    }
    if (reason === "ping timeout" || reason === "transport close") {
      await this.reload();
    }
    this.log("handleDisconnect finished");
  };

  public reload = async (): Promise<void> => {
    try {
      this.log("reload started");
      if (
        this.onAuthFailedCallback &&
        this.onConnectedCallback &&
        this.onDisconnectedCallback
      ) {
        this.setIsRetrying(true);
        const newAccessToken = await this.onAuthFailedCallback();
        this.accessToken = newAccessToken;
        this.log("reload got accessToken", newAccessToken);
        await this.onDisconnectedCallback();
        this.closeSocket();
        // give disconnect 2 secs to finish
        setTimeout(() => {
          this.openSocket({
            username: this.username,
            token: this.accessToken,
          });
          this.log("reload finished");
        }, 2000);
      }
    } catch (error) {
      this.log("reload failed", error);
      this.reloadRetryCount += 1;
      if (this.reloadRetryCount < maxReloadRetryCount) {
        this.log(
          "reload failed & started retrying with current iteration",
          this.reloadRetryCount,
          "and max retry count",
          maxReloadRetryCount
        );
        await this.reload();
      } else {
        this.setIsRetrying(false);
        this.log("reload failed, reached retry limit");
      }
    }
  };

  private setIsRetrying = (isRetrying: boolean) => {
    if (enableFrequentSocketLogs) {
      this.log("setIsRetrying started", isRetrying);
    }
    this.isRetrying = isRetrying;
    if (isRetrying) {
      snackbarStore.enqueueSnackbar({
        message: {
          id: "notificationMessage.chatSocketRetrying",
          default: "Please wait, trying to reconnect to chat...",
        },
        variant: SnackbarVariants.INFO,
        options: {
          duration: Infinity,
        },
      });
    } else if (
      snackbarStore.snackbars.includes("notificationMessage.chatSocketRetrying")
    ) {
      snackbarStore.dismissSnackbar("notificationMessage.chatSocketRetrying");
    }
    if (enableFrequentSocketLogs) {
      this.log("setIsRetrying finished");
    }
  };

  closeSocket = () => {
    this.log("closeSocket started");
    this.removeConnectionListeners();
    this.removeEngineListeners();
    this.socket?.close();
    this.unsetSocketInstance();
    this.log("closeSocket finished");
  };

  emit = async (
    eventName: string,
    data: any = {},
    namespace: string = "callModel"
  ) => {
    return new Promise((resolve, reject) => {
      this.socket?.emit(namespace, eventName, data, (err, res) => {
        if (err) {
          this.log("emit received error", err);
          reject(err);
        } else {
          this.log("emit received response", res);
          resolve(res);
        }
      });
    });
  };

  trigger = (eventName: string, args) => {
    this.log("trigger started with eventName", eventName);
    if (this.events[eventName]) {
      this.events[eventName].forEach(callback => {
        callback(args);
      });
    }
    this.log("trigger finished");
  };

  restartSocket = () => {
    this.log("restartSocket started");
    this.socket?.close();
    if (this.openTimeout) {
      clearTimeout(this.openTimeout);
    }
    this.openTimeout = setTimeout(() => {
      this.socket?.open();
    }, 100);
    this.log("restartSocket finished");
  };
}

export default ChatSocket;
