import React from "react";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import { IChatMessage } from "common/broadcast/_stores/chat/interfaces";
import { languageStore } from "library/core/stores/language/LanguageStore";

type Props = {
  message: IChatMessage;
};

const ChatMessageTip: React.ComponentType<Props> = ({ message }) => {
  const { intl } = languageStore!;
  const backgroundImage =
    "linear-gradient(90deg, #00ff37 0%, rgba(166, 255, 0, 0.7) 100% )";
  return (
    <div className='ChatItem__box' style={{ backgroundImage }}>
      <TailwindIcon name={CustomIconName.token} />
      <span data-testid={"chat-message-tip"}>
        {intl.formatMessage(
          {
            id: `broadcast.chatItem.tippedYouTkns`,
            defaultMessage: `{chatfrom} tipped you {chatValue} tokens`,
          },
          { chatfrom: message.from, chatValue: message.value }
        )}
      </span>
    </div>
  );
};

export default ChatMessageTip;
