import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import ProfileStore from "../my-page/stores/profile/ProfileStore";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import TailwindInput from "library/components/_tailwind/input";
import PayoutStore from "./stores/payout/PayoutStore";
import TailwindButton from "library/components/_tailwind/button";
import { CAMS_SUPPORT_PHONE_NUMBER } from "library/core/config/urls";
import TailwindFlex from "library/components/_tailwind/flex";

type PayoutSettingsConfirmationProps = {
  profileStore?: ProfileStore;
  validationStore?: ValidationStore;
  payoutStore?: PayoutStore;
};

const PayoutSettingsConfirmation = ({
  validationStore,
  profileStore,
  payoutStore,
  intl,
}: PayoutSettingsConfirmationProps & WrappedComponentProps) => {
  const {
    resendCode,
    confirmationCode,
    setConfirmationCode,
    activePayoutSetting,
  } = payoutStore!;

  if (!activePayoutSetting) {
    return null;
  }

  return (
    <TailwindFlex
      width={"w-full"}
      height={"h-full"}
      className={["PayoutSettingsConfirmation"]}
      alignItems={"items-center"}
      justifyContent={"justify-center"}>
      <div className='PayoutSettingsConfirmation__Content w-full'>
        <div className='w-full PayoutSettingsConfirmation__Description mb-4'>
          <p>
            {intl.formatMessage(
              {
                id: "payout.payoutSettings.youllReceiveEmail",
                defaultMessage: `You'll receive an email with your payment change confirmation code. Check your email at {email} and enter the confirmation code below. Requests that are not confirmed within 24 hours will NOT be processed. If you are missing the email, check your spam filter, or click Resend Confirmation Link.`,
              },
              {
                email: profileStore!.profile.email,
              }
            )}
          </p>
        </div>
        <div className='PayoutSettingsConfirmation__Form'>
          <div className='w-full mt-2 mb-4'>
            <TailwindInput
              required
              variant={"contained"}
              backgroundColor={"secondary"}
              label={intl.formatMessage({
                id: `payout.payoutSettings.input.enterConfirmCode`,
                defaultMessage: "Enter Confirmation Code From Email",
              })}
              error={validationStore!.errors.confirmation_code || ""}
              value={confirmationCode}
              onChange={e => {
                if (e.target.value.length <= 10) {
                  setConfirmationCode(e.target.value);
                }
              }}
              name={"confirmation_code"}
            />
          </div>
          <div className='w-full PayoutSettingsConfirmation__Description'>
            <TailwindFlex>
              <TailwindButton
                textProps={{ textDecoration: "underline" }}
                onClick={() => resendCode(activePayoutSetting?.method_name)}>
                {intl.formatMessage({
                  id: `payout.payoutSettings.resendConfirmation`,
                  defaultMessage: "Resend Confirmation",
                })}
              </TailwindButton>
            </TailwindFlex>
            <p>
              {intl.formatMessage(
                {
                  id: `payout.payoutSettings.ifYourEmailChanged`,
                  defaultMessage: `* If your email has changed and you need to update your records with us, please contact us at {phoneNumber}.`,
                },
                {
                  phoneNumber: CAMS_SUPPORT_PHONE_NUMBER,
                }
              )}
            </p>
          </div>
        </div>
      </div>
    </TailwindFlex>
  );
};

export default injectIntl(
  inject(
    "validationStore",
    "profileStore",
    "payoutStore"
  )(observer(PayoutSettingsConfirmation))
);
