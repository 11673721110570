import React, { useCallback, useState } from "react";
import "./styles.scss";
import AccountSettingsStore from "../../../common/account-settings/stores/account-settings/AccountSettingsStore";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Link } from "react-router-dom";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import TailwindInput from "library/components/_tailwind/input";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import TailwindButton from "library/components/_tailwind/button";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindFlex from "library/components/_tailwind/flex";
import config from "core/config";
import { NullableString } from "library/core/types";
import RecaptchaForm from "library/components/_tailwind/recaptcha";
import AuthStore from "core/stores/auth/AuthStore";

type ResetUsernameFormProps = {
  accountSettingsStore?: AccountSettingsStore;
  validationStore?: ValidationStore;
  authStore?: AuthStore;
};

const ResetUsernameForm: React.FunctionComponent<
  ResetUsernameFormProps & WrappedComponentProps
> = ({ accountSettingsStore, validationStore, authStore, intl }) => {
  const [email, setEmail] = useState<string>("");

  const { isPasswordResetInProgress } = accountSettingsStore!;
  const { errors, validate } = validationStore!;
  const { resetUsername } = authStore!;
  const { googleCaptchaSiteKey } = config!;

  const onResetUsernameClick = useCallback(
    async (recaptchaToken: NullableString) => {
      try {
        await resetUsername(email, recaptchaToken);
      } catch (error) {
        // do not remove as this is used to fallback to v2
        throw new Error(error);
      }
    },
    [email]
  );

  const onCaptchaChange = async (recaptchaKey: NullableString) => {
    const isValid = validate("email", email);

    if (isValid && recaptchaKey) {
      onResetUsernameClick(recaptchaKey);
    }
  };

  return (
    <RecaptchaForm
      margin={["mt-6"]}
      className={"w-full"}
      captchaSiteKey={googleCaptchaSiteKey}
      callback={onCaptchaChange}>
      <TailwindFlex flexDirection={"flex-col"}>
        <TailwindFlex width={"w-full"}>
          <TailwindInput
            required
            rounded={false}
            className={["w-full"]}
            backgroundColor={"transparent"}
            borderColor={"border-black"}
            label={intl.formatMessage({
              id: "common.email",
              defaultMessage: "E-mail",
            })}
            errorProps={{
              textAlign: "text-right",
            }}
            value={email || ""}
            type={TailwindInputType.text}
            name={"emailOrUsername"}
            onChange={e => setEmail(e.target.value)}
            error={errors.email || undefined}
          />
        </TailwindFlex>
        <TailwindFlex
          width={"w-full"}
          flexDirection={"flex-row-reverse"}
          margin={["mt-2"]}
          className={["ResetUsernameForm__forgot-password"]}>
          <TailwindFlex width={"w-auto"}>
            <Link
              to={"/reset-password"}
              className={
                "ResetUsernameForm__forgot-password-text textCapitalize coral large"
              }>
              <TailwindTranslatedText
                descriptor={{
                  id: "landing.welcomeBack.forgotPassword",
                  defaultMessage: "Forgot Password?",
                }}
              />
            </Link>
          </TailwindFlex>
        </TailwindFlex>
        {errors?.resetUsername && (
          <TailwindFlex
            alignItems={"items-center"}
            justifyContent={"justify-center"}
            className={["mt-4", "ResetUsernameForm__error"]}>
            {errors?.resetUsername}
          </TailwindFlex>
        )}
        <TailwindFlex className={["mt-4"]} width={"w-full"}>
          <TailwindButton
            type={"submit"}
            showSpinner={isPasswordResetInProgress}
            disabled={isPasswordResetInProgress}
            size={"lg"}
            backgroundColor={"primary"}
            justifyContent={"justify-center"}
            textColor={"text-white"}
            rounded={false}>
            <TailwindTranslatedText
              descriptor={{
                id: "common.sendResetLink",
                defaultMessage: "Send Reset Link",
              }}
              textTransform={"uppercase"}
              fontWeight={"font-bold"}
            />
          </TailwindButton>
        </TailwindFlex>
      </TailwindFlex>
    </RecaptchaForm>
  );
};

export default injectIntl(
  inject(
    "accountSettingsStore",
    "authStore",
    "profileStore",
    "validationStore"
  )(observer(ResetUsernameForm))
);
