import { makeAutoObservable } from "mobx";
import { api } from "core/utility";
import { history, logger } from "library/core/utility";
import {
  InitialData,
  mockModel30Days,
  mockModelPagination30Days,
} from "containers/model/my-fans/stores/my-fans/consts";
import {
  MyFanMemberTransaction,
  MyFanMemberTransactionPagination,
  MyFansPageMemberStats,
} from "containers/model/my-fans/stores/my-fans/types";

import config from "core/config";
import {
  MY_FANS_SORTING_PROPERTY,
  MY_FANS_TAB,
} from "containers/model/my-fans/stores/my-fans/enums";
import { AppCommonRouteKey } from "core/stores/route/enums";
import { propertySortMethod } from "library/core/utility/misc";
import { routeStore } from "core/stores/route/RouteStore";
import { earningsStore, messageStore } from "core/stores";
import { getUserRoleFromDomain } from "library/core/stores/theme/utils";
import DateProcess from "library/core/utility/date-process";
import { dateProcess } from "library/core/utility";

const MY_FANS_USE_MOCK_DATA_FOR_MEMBER_TRANSACTIONS = false;

class MyFansStore {
  myFans: MyFansPageMemberStats[] = InitialData.myFans;
  public loadingMyFans: boolean = InitialData.loadingMyFans;

  selectedMemberTransactionsPagination: MyFanMemberTransactionPagination =
    InitialData.selectedMemberTransactionsPagination;
  loadingMemberTransactions: boolean = InitialData.loadingMemberTransactions;
  selectedMemberId: string | null = InitialData.selectedMemberId;
  memberTransactionsPageNumber: number =
    InitialData.memberTransactionsPageNumber;
  activeTab: MY_FANS_TAB = InitialData.activeTab;
  activeSortingProperty: MY_FANS_SORTING_PROPERTY =
    InitialData.activeSortingProperty;
  selectedMemberTransactions: MyFanMemberTransaction[] =
    InitialData.selectedMemberTransactions;
  fromFilter = InitialData.fromFilter;
  toFilter = InitialData.toFilter;

  constructor() {
    makeAutoObservable(this);
  }

  get activeTabMembers(): MyFansPageMemberStats[] {
    switch (this.activeTab) {
      case MY_FANS_TAB.THIS_YEAR: {
        return this.getFilteredFansThatHasSpendings(this.myFans);
      }
      case MY_FANS_TAB.PAST_YEAR: {
        return this.getFilteredFansThatHasSpendings(this.myFans);
      }
      case MY_FANS_TAB.LAST_30_DAYS: {
        return this.getFilteredFansThatHasSpendings(
          this.myFans,
          fan => parseInt(fan.last_30_days_amount_spent_in_token) > 0
        );
      }
      case MY_FANS_TAB.ONLY_FAN_CLUB: {
        return this.getFilteredFansThatHasSpendings(
          this.myFans,
          fan => fan.is_fanclub
        );
      }
      case MY_FANS_TAB.DATE_RANGE: {
        return this.getFilteredFansThatHasSpendings(this.myFans);
      }
      default:
        return [];
    }
  }

  getFilteredFansThatHasSpendings = (
    fans: MyFansPageMemberStats[],
    predicate?: Function
  ) => {
    return fans.filter(fan => {
      return (
        parseInt(fan.amount_spent_in_token) > 0 &&
        (predicate ? predicate(fan) : true)
      );
    });
  };

  get sortedSelectedMemberTransactions(): MyFanMemberTransaction[] {
    return this.selectedMemberTransactions
      .slice()
      .sort((a, b) =>
        propertySortMethod(
          a,
          b,
          this.activeSortingProperty,
          this.activeSortingProperty === MY_FANS_SORTING_PROPERTY.ACTIVITY
            ? false
            : true
        )
      );
  }

  setActiveSortingProperty = async (property: MY_FANS_SORTING_PROPERTY) => {
    this.activeSortingProperty = property;
    await this.getMemberTransactions();
  };

  setSelectedMemberId = async (memberId: string | null) => {
    this.memberTransactionsPageNumber = 1;
    if (this.selectedMemberId === memberId) {
      this.selectedMemberId = null;
    } else {
      this.selectedMemberId = memberId;
      await this.getMemberTransactions();
    }
  };

  setActiveTab = async (tab: MY_FANS_TAB, isMobileScreenSize?: boolean) => {
    this.activeTab = tab;
    this.memberTransactionsPageNumber = 1;

    if (!isMobileScreenSize) {
      this.selectedMemberId = this.activeTabMembers?.[0]?.member?.id;
      await this.getMemberTransactions();
    } else {
      this.selectedMemberId = null;
    }
  };

  sendMessageToMember = (memberId: string) => {
    messageStore.sendMessageToMember(memberId);
    history.push(routeStore.getSiteRouteURL(AppCommonRouteKey.messages));
  };

  setMemberTransactionsPageNumber = (pageNum: number) => {
    this.memberTransactionsPageNumber = pageNum;
    this.getMemberTransactions();
  };

  setFromFilter = (date: string) => {
    this.fromFilter = date;
  };

  setToFilter = (date: string) => {
    this.toFilter = date;
  };

  public getMyFans = async (isMobileScreenSize?: boolean) => {
    this.loadingMyFans = true;
    this.activeTab = MY_FANS_TAB.LAST_30_DAYS;
    this.memberTransactionsPageNumber = 1;
    this.selectedMemberId = null;

    try {
      const userRole = getUserRoleFromDomain();
      const res = await api.getMeEndpoint(userRole as any).get("my-fans/");

      this.myFans = res.data.map(fan => {
        if (fan.member.username === "Greenfuzz") {
          fan.is_fanclub = false;
        }

        return fan;
      });

      if (this.myFans.length > 0 && !isMobileScreenSize) {
        await this.setSelectedMemberId(this.myFans[0].member?.id);
      }
    } catch (e) {
      logger.log("MyFansStore: getMyFans error");
    } finally {
      this.loadingMyFans = false;
    }
  };

  public getMemberTransactions = async (
    pageSize: number = 100
  ): Promise<void> => {
    if (this.selectedMemberId) {
      this.loadingMemberTransactions = true;
      let transactionsData: any = MY_FANS_USE_MOCK_DATA_FOR_MEMBER_TRANSACTIONS
        ? mockModel30Days
        : [];
      let transactionsPagination: any =
        MY_FANS_USE_MOCK_DATA_FOR_MEMBER_TRANSACTIONS
          ? mockModelPagination30Days
          : {};

      const walletModelMemberTransactionApi =
        this.activeTab === MY_FANS_TAB.LAST_30_DAYS
          ? api.walletModelMembersTransactions30days
          : this.activeTab === MY_FANS_TAB.PAST_YEAR ||
            this.activeTab === MY_FANS_TAB.THIS_YEAR
          ? api.walletModelMembersTransactionsPastYear
          : api.walletModelMembersTransactions;

      try {
        const currentYear = new Date().getFullYear();
        const firstOfYear = dateProcess.formatDate(
          new Date(currentYear, 0, 1).toISOString(),
          "YYYY-MM-DD"
        );
        const now = dateProcess.formatDate(
          DateProcess.today.toISOString(),
          "YYYY-MM-DD"
        );

        const dateRange =
          this.activeTab === MY_FANS_TAB.THIS_YEAR
            ? `&fromDate=${firstOfYear}&toDate=${now}`
            : this.activeTab === MY_FANS_TAB.DATE_RANGE
            ? `&fromDate=${this.fromFilter || firstOfYear}&toDate=${
                this.toFilter || now
              }`
            : "";

        const transactionsResponse = await walletModelMemberTransactionApi.get(
          undefined,
          `?member=${this.selectedMemberId}&pageSize=${pageSize}&page=${this.memberTransactionsPageNumber}&sort=${this.activeSortingProperty}${dateRange}`
        );

        transactionsData =
          transactionsResponse?.data?.data?.transactions || transactionsData;
        transactionsPagination =
          transactionsResponse?.data?.data?.pagination ||
          transactionsPagination;
      } catch (error) {
        logger.log(
          "MyFansStore: error getMemberTransactions ",
          config.showAjaxErrors ? error : ""
        );
      } finally {
        this.selectedMemberTransactions = transactionsData.map(transaction => ({
          ...transaction,
          created_date: earningsStore.formatDate(transaction.created_date, {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          }),
        }));
        this.selectedMemberTransactionsPagination = transactionsPagination;
        this.loadingMemberTransactions = false;
      }
    }
  };
}

export default MyFansStore;
