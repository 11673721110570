import React from "react";
import {
  MessageDescriptor
} from "react-intl";
import TailwindText, { TailwindTextProps } from "../text";
import TailwindHeading, { TailwindHeadingProps } from "../heading";
import TailwindParagraph, { TailwindParagraphProps } from "../paragraph";
import { PrimitiveType } from "../../../core/types";
import LanguageStore from "../../../core/stores/language/LanguageStore";
import { inject, observer } from "mobx-react";

export type TailwindTranslatedTextValues = Record<string, PrimitiveType>;

export type FormatMessageProps = {
  descriptor: MessageDescriptor;
  values?: TailwindTranslatedTextValues;
  headingProps?: TailwindHeadingProps;
  paragraphProps?: TailwindParagraphProps;
  languageStore?: LanguageStore;
};

export type TailwindTranslatedTextProps = TailwindTextProps &
  FormatMessageProps & {};

const TailwindTranslatedText: React.ComponentType<TailwindTranslatedTextProps> =
  ({
    descriptor,
    values,
    headingProps,
    paragraphProps,
    children,
    languageStore,
    ...props
  }) => {
    
    const { intl } = languageStore!;

    if (headingProps) {
      return (
        <TailwindHeading
          {...headingProps}
          dangerouslySetInnerHTML={{ __html: intl.formatMessage(descriptor, values) }}
        />
      );
    }

    if (paragraphProps) {
      return (
        <TailwindParagraph
          {...paragraphProps}
          dangerouslySetInnerHTML={{ __html: intl.formatMessage(descriptor, values) }}
        />
      );
    }

    return (
      <TailwindText {...props} dangerouslySetInnerHTML={{ __html: intl.formatMessage(descriptor, values) }} />
    );
  };

export default inject("languageStore")(observer(TailwindTranslatedText));