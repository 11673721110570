import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import { THeight, TWidth } from "library/styles/tailwind-classnames";
import MediaManagerMediaCardRejectReason, {
  MediaManagerMediaCardRejectReasonProps,
} from "./media-manager-media-card-rejection-reason";
import { TailwindBoxClassName } from "library/components/_tailwind/box";

export type MediaManagerMediaCardProps = {
  height?: THeight;
  width?: TWidth;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  rejectionReasonProps?: MediaManagerMediaCardRejectReasonProps;
  className?: TailwindBoxClassName;
};

const MediaManagerMediaCard: React.ComponentType<MediaManagerMediaCardProps> =
  ({ height, width, onClick, children, rejectionReasonProps, className }) => {
    const CardContent = (
      <TailwindFlex
        className={className}
        backgroundColor={"bg-primary-bg-color"}
        overflow={["overflow-hidden"]}
        flexDirection={"flex-col"}
        justifyContent={"justify-between"}
        borderRadius={"rounded"}
        minWidth={"min-w-40"}
        height={height}
        position={"relative"}
        width={width ? width : "w-full"}
        pseudoClasses={["md:w-full", "md:max-w-full"]}
        boxSizing={"box-border"}
        onClick={onClick}>
        {children}
      </TailwindFlex>
    );

    if (!!rejectionReasonProps) {
      return (
        <MediaManagerMediaCardRejectReason {...rejectionReasonProps}>
          {CardContent}
        </MediaManagerMediaCardRejectReason>
      );
    }

    return CardContent;
  };

export default MediaManagerMediaCard;
