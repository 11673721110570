import TailwindDropdown, {
  TailwindDropdownItem,
  TailwindDropdownValue,
} from "library/components/_tailwind/dropdown";
import TailwindFlex from "library/components/_tailwind/flex";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import React, { useCallback, useMemo } from "react";
import BroadcastStreamStore from "./_stores/broadcast-stream/BroadcastStreamStore";
import TailwindCard from "library/components/_tailwind/card";
import TailwindButton from "library/components/_tailwind/button";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindIcon from "library/components/_tailwind/icon";
import { inject, observer } from "mobx-react";
import BroadcastStore from "./_stores/broadcast/BroadcastStore";
import LanguageStore from "library/core/stores/language/LanguageStore";

interface Props {
  broadcastStreamStore?: BroadcastStreamStore;
  broadcastStore?: BroadcastStore;
  layoutStore?: LayoutStore;
  languageStore?: LanguageStore;
  mini?: boolean;
}
const BroadcastSettingsOverlay: React.FunctionComponent<
  Props
> = ({ layoutStore, broadcastStreamStore, broadcastStore, languageStore, mini = false }) => {
  const { isFirefox } = layoutStore!;
  const { hideVideoSettingsOverlay } = broadcastStore!;
  const {
    setSelectedCameraDevice,

    setSelectedResolution,
    setSelectedMicrophoneDevice,
    isStreamingOBS,
    cameras,
    selectedCameraDeviceId,
    selectedResolution,
    availableResolutionsForSelectedCamera,
    microphones,
    selectedMicrophoneDeviceId,
    isChangingCameraDevice,
  } = broadcastStreamStore!;
  const handleCameraChange = useCallback((deviceId: TailwindDropdownValue) => {
    setSelectedCameraDevice(deviceId as string);
  }, []);

  const handleResolutionChange = useCallback(value => {
    setSelectedResolution(value);
  }, []);

  const  {intl} = languageStore!;

  const availableResolutionDropdownItems: TailwindDropdownItem[] = useMemo(
    () =>
      availableResolutionsForSelectedCamera.map(resolution => ({
        label: resolution.label,
        value: resolution.value,
      })) || [],
    [availableResolutionsForSelectedCamera, selectedCameraDeviceId]
  );

  const cameraDeviceDropdownItems: TailwindDropdownItem[] = useMemo(
    () =>
      cameras?.map(cameraDevice => ({
        disabled: !cameraDevice.canBeUsed,
        label: cameraDevice.canBeUsed
          ? cameraDevice.label
          : `${cameraDevice.label} - Does not meet requirements`,
        value: cameraDevice.deviceId,
      })) || [],
    [cameras]
  );

  const microphoneDeviceDropdownItems: TailwindDropdownItem[] = useMemo(
    () =>
      microphones?.map(microphoneDevice => ({
        label: microphoneDevice.canBeUsed
          ? microphoneDevice.label
          : `${microphoneDevice.label} - Does not meet requirements`,
        value: microphoneDevice.deviceId,
      })) || [],
    [microphones]
  );

  const handleMicrophoneChange = useCallback(
    (deviceId: TailwindDropdownValue) => {
      setSelectedMicrophoneDevice(deviceId as string);
    },
    []
  );
  const clickAway = () => {
    hideVideoSettingsOverlay() as any;
  };

  return (
    <TailwindCard
      position={mini ? "relative": "absolute"}
      inset={["inset-0"]}
      backgroundColor={"primary"}
      zIndex={mini ? "z-10" : "z-900"}
      padding={["p-4"]}
      overflow={mini ? ["overflow-visible"] : ["overflow-y-auto"]}
      width={mini ? 'w-full' : 'w-auto'}
      height={"h-full"}>
      {!mini && (
        <TailwindFlex justifyContent={"justify-between"}>
          <TailwindTranslatedText
            descriptor={{
              id: "settings.video",
              defaultMessage: "Settings",
            }}
            paragraphProps={{
              fontSize: "text-xl",
              fontWeight: "font-bold",
              margin: ["mb-3"],
            }}
          />
          <TailwindButton fullWidth={false} onClick={clickAway}>
            <TailwindIcon fontSize={"text-3xl"} name={"close"} />
          </TailwindButton>
        </TailwindFlex>
      )}
      
      {!isStreamingOBS && (
        <TailwindFlex gap={mini ? "gap-4": 'gap-0'} minHeight={mini ? "min-h-16": "min-h-96"} flexDirection={mini ? "flex-row": "flex-col"}>
          <>
            <TailwindDropdown
              disabled={isChangingCameraDevice}
              showSearch={false}
              className={["h-10"]}
              margin={["mb-3"]}
              name={"camera"}
              direction={"bottom"}
              value={selectedCameraDeviceId}
              onChange={handleCameraChange}
              fullWidth={true}
              fullHeight={true}
              truncate={!!mini}
              borderColor={"border-gray-500"}
              backgroundColor={"primary"}
              borderWidth={["border"]}
              items={cameraDeviceDropdownItems}
              label={intl.formatMessage({
                id: "common.camera",
                defaultMessage: "Camera",
              })}
            />
            {(!mini && !isFirefox) && (
              <TailwindDropdown
                isLoading={isChangingCameraDevice}
                disabled={isChangingCameraDevice}
                showSearch={false}
                className={["h-10"]}
                margin={["mb-3"]}
                name={"resolution"}
                value={selectedResolution}
                onChange={handleResolutionChange}
                fullWidth={true}
                fullHeight={true}
                borderColor={"border-gray-500"}
                backgroundColor={"primary"}
                borderWidth={["border"]}
                truncate={!!mini}
                items={availableResolutionDropdownItems}
                label={intl.formatMessage({
                  id: "common.resolution",
                  defaultMessage: "Resolution",
                })}
              />
            )}
          </>

          <TailwindDropdown
            showSearch={false}
            name='microphone'
            value={selectedMicrophoneDeviceId}
            onChange={handleMicrophoneChange}
            borderColor={"border-gray-500"}
            backgroundColor={"primary"}
            borderWidth={["border"]}
            fullWidth={true}
            truncate={!!mini}
            items={microphoneDeviceDropdownItems}
            label={intl.formatMessage({
              id: "settings.microphone",
              defaultMessage: "Microphone",
            })}
            margin={mini ? ['mb-0'] : ["mb-5"]}
            direction={"bottom"}
          />
        </TailwindFlex>
      )}
    </TailwindCard>
  );
};

export default inject(
    "layoutStore",
    "broadcastStreamStore",
    "broadcastStore",
    "languageStore"
  )(observer(BroadcastSettingsOverlay));
