import TailwindText from "library/components/_tailwind/text";
import React, { FunctionComponent } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";

type Props = {
  isSignAgreementIncomplete: boolean;
  openRegisterAgreementModal: () => void;
  isApplicationPending: boolean;
  isSignAgreementPending: boolean;
  signedAgreementStatusDone: boolean;
  isModel: boolean;
};

const SignAgreementStep: FunctionComponent<Props & WrappedComponentProps> = ({
  isSignAgreementIncomplete,
  openRegisterAgreementModal,
  isApplicationPending,
  isSignAgreementPending,
  signedAgreementStatusDone,
  isModel,
  intl,
}) => {
  return (
    <React.Fragment>
      <div className='w-6/12 mr-1'>
        {isModel ? 
          <TailwindText textColor='text-main-color'>
            {intl.formatMessage({
              id: "landing.summaryStep.step3_model",
              defaultMessage: "Sign Agreements",
            })}
          </TailwindText>
          :
          <TailwindText textColor='text-main-color'>
            {intl.formatMessage({
              id: "landing.summaryStep.step3",
              defaultMessage: "Sign Agreement",
            })}
          </TailwindText>
        }
      </div>
      <div className='w-6/12 mr-1'>
        {isSignAgreementIncomplete ? (
          <React.Fragment>
            <TailwindText textColor={"text-red-500"} fontWeight={"font-bold"}>
              {intl.formatMessage({
                id: "landing.summaryStep.incomplete",
                defaultMessage: "Incomplete",
              })}
            </TailwindText>
            <br />
            <TailwindText
              fontSize={"text-sm"}
              cursor={"cursor-pointer"}
              textColor={"text-blue-500"}
              textDecoration={"underline"}
              className={"hover:no-underline"}
              onClick={openRegisterAgreementModal}>
              (
              {intl.formatMessage({
                id: "landing.summaryStep.clickToSubmit",
                defaultMessage: "Click to Submit",
              })}
              )
            </TailwindText>
          </React.Fragment>
        ) : isApplicationPending && isSignAgreementPending ? (
          <React.Fragment>
            <TailwindText
              textColor={"text-yellow-500"}
              fontWeight={"font-bold"}>
              {intl.formatMessage({
                id: "landing.summaryStep.pending",
                defaultMessage: "Pending",
              })}
            </TailwindText>
          </React.Fragment>
        ) : signedAgreementStatusDone ? (
          <TailwindText textColor={"text-green-500"} fontWeight={"font-bold"}>
            {intl.formatMessage({
              id: "landing.summaryStep.done",
              defaultMessage: "Done",
            })}
          </TailwindText>
        ) : (
          <React.Fragment>
            <TailwindText fontWeight={"font-bold"} textColor={"text-red-500"}>
              {intl.formatMessage({
                id: "landing.summaryStep.denied",
                defaultMessage: "Denied",
              })}
            </TailwindText>
            <br />
            <TailwindText
              fontSize={"text-sm"}
              cursor={"cursor-pointer"}
              textColor={"text-blue-500"}
              textDecoration={"underline"}
              className={"hover:no-underline"}
              onClick={openRegisterAgreementModal}>
              (
              {intl.formatMessage({
                id: "landing.summaryStep.clickToResubmit",
                defaultMessage: "Click to Resubmit",
              })}
              )
            </TailwindText>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};
export default injectIntl(SignAgreementStep);
