import React, { useMemo, useState } from "react";
import TailwindInput from "library/components/_tailwind/input";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import "./style.scss";
import NotesCard from "./note-card";
import TailwindBox from "library/components/_tailwind/box";
import { IMemberNotes } from "common/broadcast/_stores/chat/interfaces";
import TailwindText from "library/components/_tailwind/text";
const NotesDrawer: React.FunctionComponent<{
  notes: IMemberNotes[];
  onEdit: Function;
  onDelete: Function;
  memberId: string;
  onAdd: Function;
  isEditingNotes: string;
  setisEditingNotes: Function;
  activeDrawer: string;
  onCloseDrawer: Function;
}> = ({
  notes,
  onEdit,
  isEditingNotes,
  setisEditingNotes,
  memberId,
  onDelete,
  onAdd,
  onCloseDrawer,
  activeDrawer,
}) => {
  const [value, setValue] = useState("");
  const [warning, setWarning] = useState("");

  const handleAddNote = () => {
    if (value.length <= 500) {
      onAdd(value, memberId);
    } else {
      setWarning("note length exceeded");
    }
    setValue("");
  };

  const handleKeyPress = (e) => {
    if(e.keyCode == 13){
      handleAddNote();
    }
  }

  const memberNotes = useMemo(() => {
    return notes.filter(note => note.member === memberId);
  }, [notes, memberId]);
  const minHeight = useMemo(() => {
    if (activeDrawer === memberId) {
      if (memberNotes.length > 2) {
        return "Notes-list--minHeight-3";
      } else if (memberNotes.length === 0) {
        return "Notes-list--minHeight-1";
      } else {
        return "Notes-list--minHeight-0";
      }
    }
  }, [memberNotes]);
  return (
    <TailwindFlex
      flexDirection='flex-col'
      display='flex'
      position='relative'
      padding={["px-4"]}
      overflow='overflow-hidden'
      justifyContent='justify-center'
      alignItems='items-center'
      backgroundColor='bg-card-secondary-bg-color'
      className={[
        `NotesDrawer${
          activeDrawer === memberId ? "--active" : ""
        } ${minHeight}`,
      ]}>
      <TailwindButton
        width='w-full'
        textProps={{
          fontSize: "text-2xl",
          textAlign: "text-center",
          width: "w-full",
        }}
        onClick={() => onCloseDrawer()}>
        ^
      </TailwindButton>

      <TailwindInput
        rightIconProps={{
          name: "cancel-circle",
          onClick: () => setValue(""),
          cursor: "cursor-pointer",
        }}
        placeholder='Enter Notes Here..'
        onChange={e => {
          setValue(e.target.value);
        }}
        onKeyDown={handleKeyPress}
        value={value}
        maxLength={500}
        warning={value.length <= 500 ? undefined : warning}
        boxProps={{
          borderColor: "border-main-color",
          borderWidth: ["border"],
          borderRadius: "rounded-lg",
          padding: ["px-4", "py-6"],
          backgroundColor: "bg-primary-bg-color",
        }}></TailwindInput>

      <TailwindFlex
        margin={["mt-1", "mb-4"]}
        padding={["p-0"]}
        textColor={"text-main-color"}
        width='w-full'
        justifyContent='justify-between'>
        {value.length}/500
        <TailwindText
          padding={["p-0"]}
          width='w-8'
          display={"block"}
          alignItems='items-end'
          textColor='text-green-500'
          onClick={handleAddNote}
          height='h-full'
          cursor='cursor-pointer'>
          Save
        </TailwindText>
      </TailwindFlex>
      {memberNotes.length > 0 && (
        <TailwindBox
          className={[`Notes-list`]}
          overflow={"overflow-y-auto"}
          width='w-full'>
          {memberNotes.map((note) => (
            <NotesCard
              note={note}
              key={note.id}
              memberId={memberId}
              isEditingNotes={isEditingNotes}
              setisEditingNotes={setisEditingNotes}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          ))}
        </TailwindBox>
      )}
    </TailwindFlex>
  );
};
export default NotesDrawer;
