import React from "react";
import TailwindFlex from "../flex";
import TailwindText from "../text";
import TailwindButton from "../button";
import TailwindDivider from "../divider";

export type MediaUnlockOverlayProps = {
  unlockDay: number;
  point: number;
  onClickUnlockDayButton: () => void;
  onClickGetMorePointsButton: () => void;
};
const MediaUnlockOverlay = ({
  onClickGetMorePointsButton,
  onClickUnlockDayButton,
  point,
  unlockDay,
}: MediaUnlockOverlayProps) => {
  return (
    <TailwindFlex
      alignItems={"items-center"}
      justifyContent={"justify-center"}
      flexDirection={"flex-col"}
      height={"h-full"}
      style={{
        backdropFilter: "blur(0.75rem)",
        background: "rgba(0,0,0,0.8)",
      }}>
      <TailwindFlex
        alignItems={"items-center"}
        justifyContent={"justify-center"}
        flexDirection={"flex-col"}
        width={"w-auto"}
        padding={["p-10"]}>
        <TailwindText
          fontSize={"text-2xl"}
          textColor={"text-white"}
          fontWeight={"font-bold"}
          lineHeight={"leading-none"}>
          Unlock for {unlockDay} days
        </TailwindText>
        <TailwindText
          fontSize={"text-sm"}
          textColor={"text-white"}
          padding={["py-3"]}
          lineHeight={"leading-none"}>
          Instant access to this sexy photo!
        </TailwindText>
        <TailwindButton
          size={"sm"}
          fullWidth={false}
          width={"w-auto"}
          backgroundColor={"bg-yellow-500"}
          padding={["px-14"]}
          onClick={onClickUnlockDayButton}>
          Unlock for {point} Points
        </TailwindButton>
        <TailwindDivider
          width={"w-auto"}
          style={{ height: "1px" }}
          margin={["my-6", "mx-20"]}
        />
        <TailwindText
          textColor={"text-white"}
          textAlign={"text-center"}
          fontSize={"text-xs"}>
          To send a message with your gifts and view as many profiles as you
          like,{" "}
          <TailwindText
            fontSize={"text-xs"}
            textColor={"text-yellow-300"}
            fontWeight={"font-bold"}>
            Upgrade to GOLD!
          </TailwindText>
        </TailwindText>
        <TailwindButton
          size={"sm"}
          fullWidth={false}
          width={"w-auto"}
          backgroundColor={"bg-yellow-400"}
          padding={["px-14"]}
          margin={["my-4"]}
          onClick={onClickGetMorePointsButton}>
          Get more points now
        </TailwindButton>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default MediaUnlockOverlay;
