import React from "react";
import "./styles.scss";
import TailwindIcon from "../_tailwind/icon";
import { CustomIconName } from "../_tailwind/icon/icons/enums";
import TailwindFlex, { TailwindFlexProps } from "../_tailwind/flex";

export type ProfileBadgesProps = TailwindFlexProps & {
  isVerified?: boolean;
  isPremium?: boolean;
  isPremiumVIP?: boolean;
  showChat?: boolean;
  className?: string;
};

const ProfileBadges: React.ComponentType<ProfileBadgesProps> = ({
  isVerified,
  isPremium,
  isPremiumVIP,
  showChat,
  className,
  ...props
}) => {
  return (
    <TailwindFlex width={"w-auto"} margin={["ml-2"]} className={className} alignItems={"items-center"} {...props}>
      {(isPremiumVIP || isPremium) && (
        <TailwindIcon
          name={CustomIconName["gold-crown"]}
          iconProps={{ color: isPremiumVIP ? "black" : "gold" }}
          margin={["mr-1"]}
          fontSize={"text-sm"}
        />
      )}
      {isVerified && (
        <TailwindIcon
          name={CustomIconName.verified}
          iconProps={{ color: "purple" }}
          margin={["mr-1"]}
          fontSize={"text-sm"}
        />
      )}
      {showChat && (
        <TailwindIcon
          name={CustomIconName["chat-bubble"]}
          iconProps={{ color: "green" }}
          margin={["mr-1"]}
          fontSize={"text-sm"}
        />
      )}
    </TailwindFlex>
  );
};

export default ProfileBadges;
