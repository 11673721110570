import { inject, observer } from "mobx-react";
import React, { CSSProperties, useMemo } from "react";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import PricingStore from "../_stores/pricing/PricingStore";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindText from "library/components/_tailwind/text";
import ThemeStore from "library/core/stores/theme/ThemeStore";
import { ThemeColorContrast } from "library/core/stores/theme/enums";

interface Props {
  pricingStore?: PricingStore;
  broadcastStore?: BroadcastStore;
  themeStore?: ThemeStore;
}

const BroadcastTippingGoal: React.FunctionComponent<Props> = ({
  pricingStore,
  broadcastStore,
  themeStore,
}) => {
  const { showTippingSum, isInTippingShow } = broadcastStore!;
  const { modelProducts } = pricingStore!;
  const { theme } = themeStore!;

  const hasGoalBeenMet = useMemo(() => {
    const current = showTippingSum || 0;
    const goal = modelProducts?.chat_settings?.tipping?.["goal"] || 0;

    return goal !== 0 && current >= goal;
  }, [showTippingSum, modelProducts?.chat_settings?.tipping?.["goal"]]);

  const circleStyle = useMemo(() => {
    const styles: CSSProperties = {
      zIndex: 100,
    };
    if (hasGoalBeenMet) {
      styles.background = theme === ThemeColorContrast.dark ? 'var(--secondary-bg-color)' : `linear-gradient(
        90deg,
        rgba(11, 143, 35, 0.8) 0%,
        rgba(242, 255, 0, 0.8) 100%
      )`;
    }
    return styles;
  }, [hasGoalBeenMet, theme]);

  const tippingSumTextColor = useMemo(() => {

    if (hasGoalBeenMet) {
      return theme === ThemeColorContrast.dark ? "text-yellow-500" : "text-green-100"
    } 
    else {
      return "text-yellow-500"
    }
  }, [hasGoalBeenMet, theme])


  if (!isInTippingShow) {
    return null;
  }

  return (
    <TailwindFlex
      data-testid={"broadcast-tipping-goal"}
      data-current-tipping-sum={showTippingSum}
      data-goal={modelProducts?.chat_settings?.tipping?.goal}
      position={"absolute"}
      justifyContent={"justify-center"}
      alignItems={"items-center"}
      width={"w-24"}
      inset={["right-1", "bottom-1"]}
      borderRadius={"rounded-full"}
      style={circleStyle}
      backgroundColor='bg-tertiary-bg-color'
      height={"h-24"}
      textColor={"text-white"}
      flexDirection={"flex-col"}>
      <TailwindFlex alignItems={"items-center"} justifyContent='justify-center'>
        <TailwindText
          fontWeight="font-semibold"
          margin={["mr-1"]}
          textColor={tippingSumTextColor}
          fontSize={"text-2xl"}>
          {showTippingSum}
        </TailwindText>
        <TailwindIcon
          textColor={tippingSumTextColor}
          name={CustomIconName.token}
          fontSize={"text-lg"}
        />
      </TailwindFlex>
      <TailwindFlex textColor={hasGoalBeenMet ? "text-white" : "text-gray-500"} justifyContent={"justify-center"}>
        <TailwindTranslatedText
          fontSize={"text-xs"}
          descriptor={{
            id: "broadcast-show-type-control.tipping.goal",
            defaultMessage: "Goal",
          }}
        />
        <TailwindText fontSize={"text-xs"}>:&nbsp;</TailwindText>
        <TailwindText fontSize={"text-xs"}>
          {modelProducts?.chat_settings?.tipping?.goal}
        </TailwindText>
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default inject(
  "pricingStore",
  "broadcastStore",
  "themeStore"
)(observer(BroadcastTippingGoal));
