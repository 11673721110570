import { NullableString, RecaptchaResponse } from "../../../core/types";
import { makeAutoObservable } from "mobx";

class RecaptchaStore {
  // all comments from members code but may be valid here also
  // use of recaptcha v3 is decided after init based on being a cobrand or not
  // cobrands are not whitelisted on v3 and it always fails
  useReCaptchaV3: boolean = true;
  useReCaptchaV3Default: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  public setUseRecaptchaV3 = (shouldUse: boolean) => {
    // be careful changing these recaptcha variables, app should try to validate v3 first on cams (cobrands has it disabled)
    // then it should fallback to v2 if it fails (for cobrands its always v2)
    // these are set
    this.useReCaptchaV3 = shouldUse;
    this.useReCaptchaV3Default = this.useReCaptchaV3;
  };

  checkResponseForRecaptchaError = (error: { response: { data: Object } }) => {
    if (error?.response?.data["g-recaptcha-v3-response"]) {
      this.useReCaptchaV3 = false;
      this.useReCaptchaV3Default = false;
    }
    throw new Error(error as any);
  };

  attachRecaptchaTokenToRequest = (
    request: Object,
    recaptchaKey: NullableString
  ): RecaptchaResponse & Object => {
    if (this.useReCaptchaV3) {
      request["g-recaptcha-v3-response"] = recaptchaKey;
    } else {
      request["g-recaptcha-v2-response"] = recaptchaKey;
    }
    return request;
  };

  getRecaptchaToken = async (getV2Token: Function, getV3Token: Function) => {
    return this.useReCaptchaV3 ? await getV3Token() : await getV2Token();
  };
}
export const recaptchaStore = new RecaptchaStore();

export default RecaptchaStore;
