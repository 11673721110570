import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import StorybookSubHeading from "../_internal/sub-heading";
import Code from "../../components/_tailwind/code";

type Props = {};

const StorybookTypesAndConstants: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Types and Constants</StorybookHeading>
      <StorybookParagraph>
        There are some types and constants that can be used across projects.
        Here are all of them listed:
      </StorybookParagraph>
      <StorybookHeading>Types</StorybookHeading>
      <StorybookSubHeading>
        UserRole:{" "}
        <Code
          inline
        >{`export type UserRole = "aff" | "passion" | "model" | "studio";`}</Code>
      </StorybookSubHeading>
      <StorybookSubHeading>
        PrimitiveType:
        <Code>
          {`export declare type PrimitiveType =
| string
| number
| boolean
| null
| undefined
| Date;   
`}
        </Code>
      </StorybookSubHeading>
      <StorybookHeading>Constants</StorybookHeading>
      <StorybookParagraph>
        Here are list of available constants, you can check the source for the
        content of the constants:
      </StorybookParagraph>
      <StorybookSubHeading>- GENDER_LIST</StorybookSubHeading>
      <StorybookSubHeading>- COUNTRY_LIST</StorybookSubHeading>
      <StorybookSubHeading>- MIN_AGE</StorybookSubHeading>
      <StorybookSubHeading>- MAX_AGE</StorybookSubHeading>
      <StorybookSubHeading>- AGE_LIST</StorybookSubHeading>
      <StorybookSubHeading>- ASTROLOGICAL_SIGN_LIST</StorybookSubHeading>
      <StorybookSubHeading>- FEMALE_BODY_BUILD_LIST</StorybookSubHeading>
      <StorybookSubHeading>- BREAST_SIZE_LIST</StorybookSubHeading>
      <StorybookSubHeading>- MARITAL_STATUS_LIST</StorybookSubHeading>
      <StorybookSubHeading>- LANGUAGE_LIST</StorybookSubHeading>
      <StorybookSubHeading>- EYE_COLOR_LIST</StorybookSubHeading>
      <StorybookSubHeading>- HAIR_COLOR_LIST</StorybookSubHeading>
      <StorybookSubHeading>- PENIS_SIZE_LIST</StorybookSubHeading>
      <StorybookSubHeading>- ETHNICITY_LIST</StorybookSubHeading>
      <StorybookSubHeading>
        - FEMALE_SEXUAL_ORIENTATION_LIST
      </StorybookSubHeading>
      <StorybookSubHeading>- MALE_SEXUAL_ORIENTATION_LIST</StorybookSubHeading>
      <StorybookSubHeading>- TRANS_SEXUAL_ORIENTATION_LIST</StorybookSubHeading>
      <StorybookSubHeading>- MALE_BODY_BUILD_LIST</StorybookSubHeading>
      <StorybookSubHeading>- TRANS_BODY_BUILD_LIST</StorybookSubHeading>
      <StorybookSubHeading>- ORDINAL_NUMBERS</StorybookSubHeading>
      <StorybookSubHeading>- CONTACT_MODAL_KEYS</StorybookSubHeading>
      <StorybookSubHeading>- DAY_LIST</StorybookSubHeading>
      <StorybookSubHeading>- MONTH_LIST</StorybookSubHeading>
      <StorybookSubHeading>- PAYOUT_METHODS</StorybookSubHeading>
      <StorybookSubHeading>- US_DOCUMENT_TYPES</StorybookSubHeading>
      <StorybookSubHeading>- DOCUMENT_TYPES</StorybookSubHeading>
      <StorybookSubHeading>- BANNED_WORDS</StorybookSubHeading>
    </StorybookWrapper>
  );
};

export default StorybookTypesAndConstants;
