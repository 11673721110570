import React from "react";
import "./styles.scss";
import classnames from "classnames";

type MasonryLayoutIconProps = {
  className?: string;
};

const MasonryLayoutIcon: React.ComponentType<MasonryLayoutIconProps> = ({
  className,
}) => (
  <span className={classnames("MasonryLayoutIcon", className && className)}>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.36 29.41"
    >
      <path
        d="M31.27,14H26a1.25,1.25,0,0,0-1.32,1.16V31.48A1.26,1.26,0,0,0,26,32.65h5.29a1.26,1.26,0,0,0,1.33-1.17V15.16A1.26,1.26,0,0,0,31.27,14Zm0,17.48H26V23.32h5.29Z"
        transform="translate(-3.24 -3.24)"
      />
      <path
        d="M20.44,3.24H15.15A1.26,1.26,0,0,0,13.82,4.4V20.72a1.26,1.26,0,0,0,1.33,1.16h5.29a1.25,1.25,0,0,0,1.32-1.16V4.4A1.25,1.25,0,0,0,20.44,3.24Zm0,17.48H15.15V12.56h5.29Z"
        transform="translate(-3.24 -3.24)"
      />
      <path
        d="M9.85,10.23H4.56a1.25,1.25,0,0,0-1.32,1.16V27.71a1.26,1.26,0,0,0,1.32,1.17H9.85a1.26,1.26,0,0,0,1.33-1.17V11.39A1.26,1.26,0,0,0,9.85,10.23Zm0,17.48H4.56V19.55H9.85Z"
        transform="translate(-3.24 -3.24)"
      />
    </svg>
  </span>
);

export default MasonryLayoutIcon;
