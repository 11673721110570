import React from "react";
import StorybookWrapper from "../_internal/wrapper";
import StorybookHeading from "../_internal/heading";
import StorybookParagraph from "../_internal/paragraph";
import Code from "../../components/_tailwind/code";

type Props = {};

const StorybookConventionsNaming: React.ComponentType<Props> = ({}) => {
  return (
    <StorybookWrapper>
      <StorybookHeading>Conventions &gt; Naming</StorybookHeading>
      <StorybookParagraph>
        - Component names, should be PascalCase - CSS classes should be Pascal
        Case while Element & Modifier classnames should be all lowercase - File
        & folder names should be kebab-case - Interfaces should start with the
        letter I and be Pascal Case. Types do not need to have anything in the
        name - Classes should be PascalCase - Members (and static members) of a
        class should be camelCase - variables, functions etc. should be
        camelCase - Enums & constants should be CONSTANT_CASE - Exceptions on
        some names that can be uppercase are for example HTTP, CSS, XML etc,
        these can be uppercase.
      </StorybookParagraph>
      <StorybookParagraph>
        The names of the functions should be descriptive.{" "}
        <Code inline>const price = 10</Code> is a bad example because we have
        token & usd prices in the code. This leads to bugs that Typescript
        cannot catch. A good example is to have{" "}
        <Code inline>const priceInTokens = 10; const priceInUSD = 10;</Code>
      </StorybookParagraph>
      <StorybookParagraph>
        The files should be named clearly as while having 10 different tabs open
        in an editor window, developers need to differentiate each tab easily.
      </StorybookParagraph>
      <StorybookParagraph>
        We did not take naming seriously. A more fine-grained approach would be
        to name everything like Angular does such as:
      </StorybookParagraph>
      <Code>
        {`module.component.tsx
module.utils.tsx
module.types.tsx        
`}
      </Code>
      <StorybookParagraph>
        Contrary to the above example we have adapted somewhat working naming
        convention as follows:
      </StorybookParagraph>
      <Code>
        {`sidebar/index.tsx
sidebar/sidebar-header.tsx
sidebar/sidebar-footer.tsx
sidebar/sidebar-item.tsx
sidebar/buttons/sidebar-buttons-some-button.tsx        
`}
      </Code>
      <StorybookParagraph>
        The above example gives an insight into the component-tree hierarchy by
        simply in the name. Although it might seem redundant or repetitive, this
        is the best way to ensure developer's day to day flow to be optimal in a
        way time is utilized better when some other developer just started
        reading the code and does not know how stuff work.
      </StorybookParagraph>
      <StorybookParagraph fontWeight={"font-bold"}>
        When structuring the hierarchy tree, make sure that the names are as
        generic as possible such as:
        <Code inline>
          {`sidebar-header, sidebar-footer, sidebar-header-logo, sidebar-header-actions`}
        </Code>
      </StorybookParagraph>
      <StorybookParagraph>
        Naming of the components should match the name of the folder hierarchy:
      </StorybookParagraph>
      <Code>
        {`const MediaManager: React.FC..., index.tsx
const MediaManagerAlbum: React.FC..., media-manager-album.tsx
const MediaManagerAlbumHeader: React.FC..., media-manager-album-header.tsx
const MediaManagerAlbumHeaderTitle: React.FC..., media-manager-album-header-title.tsx
const MediaManagerAlbumManage: React.FC..., media-manager-album-manage.tsx
const MediaManagerMedia: React.FC..., media-manager-media.tsx
const MediaManagerMediaManage: React.FC..., media-manager-media-manage.tsx
const MediaManagerMediaUpload: React.FC..., media-manager-media-upload.tsx,
`}
      </Code>
      <StorybookParagraph fontWeight={"font-bold"} textColor={"text-red-500"}>
        When naming components, try to stay away from abbreviations unless the
        abbreviation is 100% clear to everyone such as TS for Typescript, CSS
        for Cascading Style Sheets. A bad example for an abbreviation can be{" "}
        <Code inline>MediaCardFC</Code> where FC is actually Fan Club but is
        most likely unknown to a developer that just started working on the code
      </StorybookParagraph>
      <StorybookParagraph>
        When naming functions, try to name in a generic way like:&nbsp;
        <Code inline>
          getSomething, setSomething, updateSomething, replaceSomething,
          addSomething
        </Code>
      </StorybookParagraph>
    </StorybookWrapper>
  );
};

export default StorybookConventionsNaming;
