import { inject, observer } from "mobx-react";
import React, { useCallback, useMemo } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import "./styles.scss";
import ModalCloseButton from "library/components/modal/modal-close-button";
import Modal from "library/components/modal";
import TailwindButton from "library/components/_tailwind/button";
import { BROADCAST_END_TIPPING_SHOW_EARLY_SHOW_STOP_WARNING_TIME_IN_MINUTES } from "common/broadcast/_stores/broadcast/consts";
import { ShowTab } from "common/broadcast/_stores/broadcast/enums";
import TailwindBox from "library/components/_tailwind/box";
import LanguageStore from "library/core/stores/language/LanguageStore";

interface Props {
  broadcastStore?: BroadcastStore;
  languageStore?: LanguageStore;
}

const BroadcastEndTippingShowModal: React.FunctionComponent<
  Props & WrappedComponentProps
> = ({ broadcastStore, languageStore }) => {
  const { intl } = languageStore!;
  const {
    setShouldShowEndTippingShowConfirmation,
    switchToShow,
    currentShowTab,
    previousShowTab,
    setCurrentShowTab,
    shouldShowEndTippingShowConfirmation,
    lastShowTrailDetail,
    endTippingShowEarlyShowStopWarningTimeInSeconds,
  } = broadcastStore!;

  const hasPassedNMinutes = useMemo(() => {
    const now = new Date();
    const startedAt = lastShowTrailDetail?.startedAt;

    if (startedAt) {
      const durationInSeconds = (now.getTime() - startedAt.getTime()) / 1000;
      const threshold = endTippingShowEarlyShowStopWarningTimeInSeconds;
      if (durationInSeconds <= threshold) {
        return true;
      }
    }

    return false;
  }, [lastShowTrailDetail, endTippingShowEarlyShowStopWarningTimeInSeconds]);

  const onClickNo = useCallback(() => {
    setCurrentShowTab(previousShowTab);
    setShouldShowEndTippingShowConfirmation(false);
  }, [previousShowTab]);

  const onClickYes = useCallback(() => {
    switchToShow(
      currentShowTab === ShowTab.TIPPING ? ShowTab.FREE : currentShowTab,
      true
    );
    setShouldShowEndTippingShowConfirmation(false);
  }, [currentShowTab]);

  return (
    <Modal open={shouldShowEndTippingShowConfirmation || false}>
      <TailwindBox
        className={["BroadcastShowTypeModal"]}
        backgroundColor={"bg-primary-bg-color"}>
        <ModalCloseButton closeModal={() => onClickNo()} />
        <p>
          {hasPassedNMinutes 
            ? intl.formatMessage(
            {
              id: "broadcast.areYouSureGoal1",
              defaultMessage: "This show was started less than {time} minutes ago, are you sure you want to end goal show?",
            },
            {
              time: `${BROADCAST_END_TIPPING_SHOW_EARLY_SHOW_STOP_WARNING_TIME_IN_MINUTES}`,
            }
          ) :
          intl.formatMessage(
            {
              id: "broadcast.areYouSureGoal2",
              defaultMessage: "Are you sure you want to end goal show?",
            }
          )}
        </p>
        <div className='agree-or-cancel-tipping'>
          <TailwindButton
            onClick={onClickYes}
            // backgroundColor={"bg-blue-400"}
            fullWidth={false}
            rounded={false}
            justifyContent={"justify-center"}
            margin={["ml-3"]}
            borderColor={'border-pink-300'}
            borderWidth={['border-2']}
            borderStyle={'border-solid'}
            width={"w-32"}>
            {intl.formatMessage({
              id: "broadcast-show-type-control.tipping.end-show-no",
              defaultMessage: "END SHOW",
            })}
          </TailwindButton>
          <TailwindButton
            onClick={onClickNo}
            fullWidth={false}
            backgroundColor={"bg-pink-300"}
            rounded={false}
            justifyContent={"justify-center"}
            margin={["ml-3"]}
            width={"w-56"}>
            {intl.formatMessage({
              id: "broadcast-show-type-control.tipping.end-show-yes",
              defaultMessage: "CONTINUE GOAL SHOW",
            })}
          </TailwindButton>
        </div>
      </TailwindBox>
    </Modal>
  );
};

export default injectIntl(
  inject(
    "broadcastStore",
    "languageStore"
  )(observer(BroadcastEndTippingShowModal))
);
