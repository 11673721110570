import {
  getLandingRouteMetaTitle,
  isRouteVisibleOnSidebarForSite,
} from "core/stores/route/utils";
import {
  AppAllRouteKeys,
  AppCamsRoute,
  AppRoute,
  AppRouteBase,
  HistoryLocation,
  RouteSidebarOptions,
} from "core/stores/route/types";
import {
  AppCamsModelRouteKey,
  AppCamsStudioRouteKey,
  AppCommonRouteKey,
  AppVariantRouteKey,
  RouteSidebarLocations,
} from "core/stores/route/enums";
import { COMMON_ROUTES } from "core/stores/route/common";
import { CAMS_MODEL_ROUTES, CAMS_STUDIO_ROUTES } from "core/stores/route/cams";
import VARIANT_ROUTES, { TUTORIAL_ROUTE } from "core/stores/route/variant";
import _, { isArray } from "lodash";
import { userRole } from "library/core/stores/consts";
import { layoutStore } from "library/core/stores/layout/LayoutStore";
import { ROUTE_PRODUCT_PREFIXES } from "core/stores/route/index";
import { history, logger } from "library/core/utility";
import { makeAutoObservable } from "mobx";
import { profileStore } from "core/stores";

const logPrefix = "[RouteStore]";

export default class RouteStore {
  private defaultStudioLoggedInRouteKey:
    | AppCamsStudioRouteKey
    | AppCommonRouteKey
    | AppVariantRouteKey = AppCommonRouteKey.earnings;
  private defaultModelLoggedInRouteKey:
    | AppCamsModelRouteKey
    | AppCommonRouteKey
    | AppVariantRouteKey = AppCamsModelRouteKey.myPage;
  currentRoutePathname: string = "";

  constructor() {
    makeAutoObservable(this);
    this.addRouteChangeListener();
    this.setCurrentRoutePathname(location.pathname); //set initial value as listener only acts to changes and does not run initially
  }

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  addRouteChangeListener = () => {
    history.listen(this.onRouteChange as any);
  };

  onRouteChange = (location: HistoryLocation) => {
    this.setCurrentRoutePathname(location.pathname);
  };

  setCurrentRoutePathname = (pathname: string) => {
    this.log("setCurrentRoutePathName started", pathname);
    this.currentRoutePathname = pathname;
    this.log("setCurrentRoutePathName finished", pathname);
  };

  get sidebarRoutes() {
    const isIndividualModel =
      profileStore.isIndividualModel || profileStore.isStudio;
    let routes = Object.assign({}, { ...COMMON_ROUTES, ...this.variantRoutes });
    if (userRole === "model") {
      routes = Object.assign(routes, CAMS_MODEL_ROUTES);
    } else if (userRole === "studio") {
      routes = Object.assign(routes, CAMS_STUDIO_ROUTES);
    }

    const clonedRoutes = _.cloneDeep(routes);
    const filteredRoutes = Object.values(clonedRoutes).map(route => {
      if (
        route?.sidebarOptions?.action?.item &&
        isArray(route?.sidebarOptions?.action?.item)
      ) {
        route.sidebarOptions.action.item =
          route.sidebarOptions?.action?.item.filter(subroute =>
            isRouteVisibleOnSidebarForSite(
              userRole,
              isIndividualModel,
              profileStore.profileLoaded,
              profileStore.profileRegistrationCompleted,
              subroute
            )
          );
      }
      return route;
    });

    const sortedRoutes = _.sortBy(filteredRoutes, [
      route => {
        return this.getRouteSidebarOptions(route?.key)?.index;
      },
    ]);

    const topRoutes = sortedRoutes
      .filter(
        route => route?.sidebarOptions?.location === RouteSidebarLocations.top
      )
      .filter(route =>
        isRouteVisibleOnSidebarForSite(
          userRole,
          isIndividualModel,
          profileStore.profileLoaded,
          profileStore.profileRegistrationCompleted,
          route
        )
      );
    const bottomRoutes = sortedRoutes
      .filter(
        route =>
          route?.sidebarOptions?.location === RouteSidebarLocations.bottom
      )
      .filter(route =>
        isRouteVisibleOnSidebarForSite(
          userRole,
          isIndividualModel,
          profileStore.profileLoaded,
          profileStore.profileRegistrationCompleted,
          route
        )
      );

    return {
      topRoutes,
      bottomRoutes,
    };
  }

  get routes() {
    return {
      ...COMMON_ROUTES,
      ...this.variantRoutes,
      ...CAMS_MODEL_ROUTES,
      ...CAMS_STUDIO_ROUTES,
    };
  }

  get variantRoutes() {
    const routes = _.cloneDeep(VARIANT_ROUTES);

    if (userRole === "model") {
      let tutorialRoute: AppRouteBase = _.cloneDeep(
        TUTORIAL_ROUTE as AppRouteBase
      );
      if (
        !profileStore.shouldShowTutorialToModel &&
        routes.settings?.sidebarOptions?.action?.item &&
        Array.isArray(routes.settings.sidebarOptions.action.item)
      ) {
        tutorialRoute = {
          ...tutorialRoute,
          sidebarOptions: {
            location: RouteSidebarLocations.subMenu,
            index: 5,
            icon: "",
            label: "Tutorial",
          },
        };
        (routes.settings.sidebarOptions.action.item as AppCamsRoute[]).push(
          tutorialRoute as AppRoute
        );
      } else {
        tutorialRoute = {
          ...tutorialRoute,
          pageTitle: "Welcome",
          sidebarOptions: {
            location: RouteSidebarLocations.top,
            index: 0,
            label: "Welcome",
            icon: "dvr",
          },
        };
      }
      routes.tutorial = tutorialRoute as AppRoute;
    }

    return routes;
  }

  getRouteSidebarOptions = (routeKey?: AppAllRouteKeys) => {
    let sidebarOptions;
    if (routeKey) {
      sidebarOptions = this.routes[routeKey]?.sidebarOptions;
    }

    return sidebarOptions || ({} as RouteSidebarOptions);
  };

  getRouteTitle = (pathname: string) => {
    const siteTitle = getLandingRouteMetaTitle();
    const pageKey = Object.keys(this.routes).find(
      key => this.routes[key].link === pathname
    );
    if (pageKey) {
      return this.routes[pageKey]?.pageTitle || getLandingRouteMetaTitle();
    }

    return siteTitle;
  };

  getMainRouteForSite = () => {
    if (userRole) {
      let url;
      if (userRole === "model") {
        if (layoutStore?.isLiteMode) {
          url = this.getSiteRouteURL(this.getLiteModePageOverride(), true);
        } else {
          url = this.getSiteRouteURL(this.defaultModelLoggedInRouteKey, true);
        }
      } else if (userRole === "studio") {
        url = this.getSiteRouteURL(this.defaultStudioLoggedInRouteKey, true);
      } else {
        url = "/";
      }
      return url;
    }

    return undefined;
  };

  getLiteModePageOverride = (): AppCamsModelRouteKey | AppCommonRouteKey => {
    const urlParams = new URLSearchParams(window.location.search);
    const litePage = urlParams.get("litepage");
    if(litePage === AppCamsModelRouteKey.recordedVideos) { 
      return AppCamsModelRouteKey.recordedVideos;
    } else if(litePage === AppCamsModelRouteKey.myPage) {
      return AppCamsModelRouteKey.myPage;
    } else if(litePage === AppCommonRouteKey.messages) {
      return AppCommonRouteKey.messages;
    } else {
      return AppCommonRouteKey.broadcast;
    }
  }

  getSiteRouteURL = (
    routeKey: AppAllRouteKeys,
    includePrefix: boolean = true,
    includeStartingSlash: boolean = true
  ) => {
    const variantRoute = this.variantRoutes[routeKey];
    const isCommonRoute = COMMON_ROUTES[routeKey] !== undefined;
    const isCommonVariantRoute =
      Boolean(variantRoute?.hasAccess?.includes("model")) &&
      Boolean(variantRoute?.hasAccess?.includes("studio"));
    const isCamsModelRoute = CAMS_MODEL_ROUTES[routeKey] !== undefined;
    const isCamsModelVariantRoute = variantRoute?.hasAccess?.includes("model");
    const isCamsStudioRoute = CAMS_STUDIO_ROUTES[routeKey] !== undefined;
    const isCamsStudioVariantRoute =
      variantRoute?.hasAccess?.includes("studio");

    const prefix =
      isCommonRoute || isCommonVariantRoute
        ? ""
        : userRole === "model" || isCamsModelRoute || isCamsModelVariantRoute
        ? ROUTE_PRODUCT_PREFIXES.model
        : userRole === "studio" || isCamsStudioRoute || isCamsStudioVariantRoute
        ? ROUTE_PRODUCT_PREFIXES.studio
        : undefined;

    let url = "";

    if (prefix && includePrefix) {
      url += "/" + prefix;
    }

    const routeLink = this.routes[routeKey]?.link;

    // do not change !== undefined
    if (routeLink !== undefined) {
      if (includeStartingSlash) {
        url = `${url}/`;
      }
      url += routeLink;
    }

    return url;
  };
}

export const routeStore = new RouteStore();
