import React, { useCallback } from "react";
import { inject, observer } from "mobx-react";
import ThemeStore from "library/core/stores/theme/ThemeStore";
import { ThemeColorContrast } from "library/core/stores/theme/enums";
import TailwindButton from "library/components/_tailwind/button";
import TailwindDropdown, {
  TailwindDropdownValue,
} from "library/components/_tailwind/dropdown";

type Props = {
  themeStore?: ThemeStore;
};

const QAWidgetTheming: React.ComponentType<Props> = ({ themeStore }) => {
  const {
    //currentThemePalette,
    theme,
    setTheme,
    defaultThemeContrast,
    applyTheme,
    //setColor,
  } = themeStore!;

  /*const onChangeColor = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, colorKey: string) => {
      setColor(colorKey, event?.target?.value);
      /!*setMappedColors({
        [colorKey]: event?.target?.value,
      });*!/
    },
    []
  );*/

  const onClickSetDefaultTheme = useCallback(() => {
    setTheme(defaultThemeContrast);
    //handleThemeChange(CAMS_DEFAULT_THEME);
  }, []);

  const onChangeThemeType = useCallback((value: TailwindDropdownValue) => {
    applyTheme(ThemeColorContrast[value as ThemeColorContrast]);
  }, []);

  return (
    <React.Fragment>
      <div className='FeatureSwitch__Cobrand-item'>
        <TailwindDropdown
          shape={"rounded"}
          backgroundColor={"primary"}
          fullWidth={true}
          name={"palette"}
          label={"Palette"}
          value={theme}
          onChange={(value: TailwindDropdownValue) => onChangeThemeType(value)}
          items={[
            // {
            //   label: "Dark",
            //   value: ThemeColorContrast.dark,
            // },
            {
              label: "Light",
              value: ThemeColorContrast.light,
            },
          ]}
        />
      </div>
      {/*{Object.keys(currentThemePalette || {})
        .sort()
        .map((colorKey) => (
          <div className="FeatureSwitch__Cobrand-item" key={colorKey}>
            <span>{colorKey}</span>
            <span>
              <input
                type={
                  currentThemePalette?.[colorKey].includes("px")
                    ? "text"
                    : "color"
                }
                style={{ color: "var(--title-color-1-inversed)" }}
                onChange={(event) => onChangeColor(event, colorKey)}
                value={currentThemePalette?.[colorKey]}
              ></input>
            </span>
          </div>
        ))}*/}

      <div className='FeatureSwitch__Cobrand-item'>
        <TailwindButton
          onClick={onClickSetDefaultTheme}
          fullWidth
          justifyContent={"justify-center"}>
          Set To Default Theme
        </TailwindButton>
      </div>
    </React.Fragment>
  );
};

export default inject("themeStore")(observer(QAWidgetTheming));
