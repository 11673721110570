import React from "react";
import "./styles.scss";
import classnames from "classnames";

type PlayIconProps = {
  className?: string;
};

const PlayIcon: React.ComponentType<PlayIconProps> = ({ className }) => (
  <span className={classnames("PlayIcon", className && className)}>
    <svg 
      version="1.2" 
      xmlns="http://www.w3.org/2000/svg" 
      xmlnsXlink="http://www.w3.org/1999/xlink" 
      overflow="visible" 
      preserveAspectRatio="none" 
      viewBox="0 0 8 8">
        <g transform="translate(0, 0)">
          <defs>
            <path 
              id="path-17169963469711928" 
              d="M0 3.9999999999999973 C0 1.7908610007999988 1.790861000799999 0 3.9999999999999973 0 C3.9999999999999973 0 3.9999999999999973 0 3.9999999999999973 0 C6.209138999199996 0 7.999999999999995 1.790861000799999 7.999999999999995 3.9999999999999973 C7.999999999999995 3.9999999999999973 7.999999999999995 4.000000000000002 7.999999999999995 4.000000000000002 C7.999999999999995 6.2091389992 6.209138999199996 7.999999999999999 3.9999999999999973 7.999999999999999 C3.9999999999999973 7.999999999999999 3.9999999999999973 7.999999999999999 3.9999999999999973 7.999999999999999 C1.7908610007999988 7.999999999999999 0 6.2091389992 0 4.000000000000002 C0 4.000000000000002 0 3.9999999999999973 0 3.9999999999999973 Z" 
              vectorEffect="non-scaling-stroke"/>
          </defs>
          <g transform="translate(0, 0)">
            <path 
              d="M0 3.9999999999999973 C0 1.7908610007999988 1.790861000799999 0 3.9999999999999973 0 C3.9999999999999973 0 3.9999999999999973 0 3.9999999999999973 0 C6.209138999199996 0 7.999999999999995 1.790861000799999 7.999999999999995 3.9999999999999973 C7.999999999999995 3.9999999999999973 7.999999999999995 4.000000000000002 7.999999999999995 4.000000000000002 C7.999999999999995 6.2091389992 6.209138999199996 7.999999999999999 3.9999999999999973 7.999999999999999 C3.9999999999999973 7.999999999999999 3.9999999999999973 7.999999999999999 3.9999999999999973 7.999999999999999 C1.7908610007999988 7.999999999999999 0 6.2091389992 0 4.000000000000002 C0 4.000000000000002 0 3.9999999999999973 0 3.9999999999999973 Z" 
              vectorEffect="non-scaling-stroke"/>
          </g>
        </g>
    </svg>
  </span>
);

export default PlayIcon;
