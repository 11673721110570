import React from "react";
import "./styles.scss";
import classnames from "classnames";

type NudeListingIconProps = {
  className?: string;
};

const NudeListingIcon: React.ComponentType<NudeListingIconProps> = ({
  className,
}) => (
  <span className={classnames("nude-listing-icon", className && className)}>
    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" viewBox="0 0 15.9999932169335 20.00002" width="15.9999932169335" height="20.00002">
      <g transform="translate(0, 0)">
        <g transform="translate(-0.000006731690632151566, 0.000009999999997845066) rotate(0)">
          <path d="M15.20215,19.81651h-6.45352h-0.74823v0h-0.74823h-6.45352c-2.80588,-5.22936 2.71235,-8.16514 2.33823,-11.65138c-0.09353,-2.84404 -3.08647,-5.3211 -0.18706,-8.16514h5.05058v0h5.05058c2.89941,2.84404 -0.09353,5.3211 -0.28059,8.16514c-0.28059,3.48624 5.23764,6.42202 2.43176,11.65138zM10.61922,14.49541l-0.84176,-0.73394c-0.6547,0.73394 -1.21588,1.10092 -1.77706,1.10092v0c-0.46765,0 -1.12235,-0.36697 -1.77706,-1.00917l-0.84176,0.73394c0.74823,0.73394 1.40294,1.19266 2.05764,1.37615v4.0367h1.12235v-4.0367c0.6547,-0.27523 1.30941,-0.73394 2.05764,-1.46789z"
            style={{
              strokeWidth: 0,
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              fill: 'rgb(255, 255, 255)'
            }} vectorEffect="non-scaling-stroke" />
        </g>
        <defs>
          <path id="path-16547262331278722" d="M15.20215,19.81651h-6.45352h-0.74823v0h-0.74823h-6.45352c-2.80588,-5.22936 2.71235,-8.16514 2.33823,-11.65138c-0.09353,-2.84404 -3.08647,-5.3211 -0.18706,-8.16514h5.05058v0h5.05058c2.89941,2.84404 -0.09353,5.3211 -0.28059,8.16514c-0.28059,3.48624 5.23764,6.42202 2.43176,11.65138zM10.61922,14.49541l-0.84176,-0.73394c-0.6547,0.73394 -1.21588,1.10092 -1.77706,1.10092v0c-0.46765,0 -1.12235,-0.36697 -1.77706,-1.00917l-0.84176,0.73394c0.74823,0.73394 1.40294,1.19266 2.05764,1.37615v4.0367h1.12235v-4.0367c0.6547,-0.27523 1.30941,-0.73394 2.05764,-1.46789z" vectorEffect="non-scaling-stroke" />
        </defs>
      </g>
    </svg>
  </span>
);

export default NudeListingIcon;
