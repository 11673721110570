import { makeAutoObservable } from "mobx";
import { InitialData } from "containers/model/my-fans/stores/my-fans/consts";
import { ReferredUser } from "containers/model/referrals/stores/referrals/types";
import { api } from "core/utility";
import { logger } from "library/core/utility";

class ReferralsStore {
  referrals: ReferredUser[] = InitialData.referrals;
  isLoadingReferrals: boolean = InitialData.isLoadingReferrals;

  constructor() {
    makeAutoObservable(this);
  }

  public getReferrals = async () => {
    try {
      this.isLoadingReferrals = true;
      const res = await api.referrals.get();
      this.referrals = (res?.data || []).map(item => ({
        ...item,
        join_date: item.profile?.registration_completed_at || item.profile?.created_at || item.join_date
      }));
    } catch (e) {
      logger.log("ReferralsStore: get Referrals error");
    } finally {
      this.isLoadingReferrals = false;
    }
  };

  get memberReferrals() {
    return this.referrals && Array.isArray(this.referrals)
      ? this.referrals.filter(referral => referral.profile_type === "member")
      : [];
  }

  get modelReferrals() {
    return this.referrals && Array.isArray(this.referrals)
      ? this.referrals.filter(referral => referral.profile_type === "model")
      : [];
  }

  get studioReferrals() {
    return this.referrals && Array.isArray(this.referrals)
      ? this.referrals.filter(referral => referral.profile_type === "studio")
      : [];
  }
}

export default ReferralsStore;
