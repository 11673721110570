import React, { useMemo } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText, {
  TailwindTextProps,
} from "library/components/_tailwind/text";
import { TWidth } from "library/styles/tailwind-classnames";
import { useIntl } from "react-intl";
import { NEW_ALBUM_DEFAULT_NAME } from "./store/consts";

export type MediaManagerMediaCardHeaderTitleProps = {
  name: string | null;
  textProps?: TailwindTextProps;
  width: TWidth;
  isMyProfile?: boolean;
  status?: string;
};

const MediaManagerMediaCardHeaderTitle: React.ComponentType<MediaManagerMediaCardHeaderTitleProps> =
  ({ name, width, textProps, isMyProfile, status }) => {
    const intl = useIntl();

    const showAddTitle = useMemo(() => {
      return !name || name === NEW_ALBUM_DEFAULT_NAME;
    }, [name]);

    return (
      <TailwindFlex width={width} maxWidth={"max-w-90"}>
        <TailwindText
          textColor={!isMyProfile ? (status && status== "APPROVED" ? "text-white" : "text-gray-400") : "text-main-color"}
          whiteSpace={"whitespace-nowrap"}
          borderRadius={!isMyProfile ? "rounded-3xl" : "rounded-none"}
          backgroundColor={!isMyProfile ? "bg-gray-600" : "bg-transparent"}
          padding={!isMyProfile ? ["py-1", "px-4"] : ["px-0", "py-1"]}
          textOverflow={"overflow-ellipsis"}
          fontSize="text-base"
          overflow={"overflow-hidden"}
          {...textProps}
          pseudoClasses={["md:text-xs"]}>
          {showAddTitle
            ? intl.formatMessage({
                id: "media-card-header.title-fallback",
                defaultMessage: "Add Title",
              })
            : name}
        </TailwindText>
      </TailwindFlex>
    );
  };

export default MediaManagerMediaCardHeaderTitle;
