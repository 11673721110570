import React from "react";
import FeedHeader, { FeedHeaderProps } from "./feed-header";
import FeedFooter from "./feed-footer";
import FeedComment, { FeedCommentProps } from "./feed-comment";
import FeedCover, { FeedCoverProps } from "./feed-cover";
import TailwindCard from "../card";

export type FeedActivityProps = {
  feedCoverProps?: FeedCoverProps;
  feedHeaderProps: FeedHeaderProps;
  comments?: FeedCommentProps[];
};

const FeedActivity: React.ComponentType<FeedActivityProps> = ({
  feedHeaderProps,
  feedCoverProps,
  comments,
}) => {
  return (
    <TailwindCard backgroundColor={"bg-gray-800"}>
      {feedCoverProps && <FeedCover {...feedCoverProps} />}
      <FeedHeader {...feedHeaderProps} />
      {comments && comments.length > 0 && (
        <FeedFooter>
          {comments.map(comment => (
            <FeedComment key={comment.id} {...comment} />
          ))}
        </FeedFooter>
      )}
    </TailwindCard>
  );
};

export default FeedActivity;
