import React from "react";
import TailwindBox from "../box";
import "./style.scss";

type Props = {
  progress: number;
};

const ProgressBarCircular: React.ComponentType<Props> = ({
  progress = 0,
  children,
}) => {
  return (
    <TailwindBox className={["ProgressBarCicular"]}>
      <svg
        viewBox='0 0 25 25'
        className='circular-progress'
        style={
          {
            "--progress": progress,
          } as React.CSSProperties
        }>
        <circle className='bg'></circle>
        <circle className='fg'></circle>
      </svg>
      {children}
    </TailwindBox>
  );
};

export default ProgressBarCircular;
