import config from "core/config";
import TailwindBox from "library/components/_tailwind/box";
import TailwindDivider from "library/components/_tailwind/divider";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindKebabMenu from "library/components/_tailwind/kebab-menu";
import TailwindList from "library/components/_tailwind/list";
import TailwindListItem from "library/components/_tailwind/list/list-item";
import TailwindParagraph from "library/components/_tailwind/paragraph";
import TailwindText from "library/components/_tailwind/text";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
//import TailwindHeading from "library/components/_tailwind/heading";
import TailwindIcon from "library/components/_tailwind/icon";
import Modal from "library/components/modal";
import ModalCloseButton from "library/components/modal/modal-close-button";
import ModalStore from "library/core/stores/modal";
import LanguageStore from "library/core/stores/language/LanguageStore";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import MessageStore from "./stores/messages/MessageStore";
import PricingModal from "../messages/pricing-modal";
import { inject, observer } from "mobx-react";
import React, { useCallback, useState } from "react";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";

type Props = {
  layoutStore?: LayoutStore;
  modalStore?: ModalStore;
  messageStore?: MessageStore;
  languageStore?: LanguageStore;
};

const MessageCenterMenuButton: React.ComponentType<Props> = ({
  layoutStore,
  modalStore,
  messageStore,
  languageStore
}) => {
  const { isMobileDevice, screenOrientation } = layoutStore!;
  const { openPrimaryModal } = modalStore!;
  const { logArchivedMsgVisit, isMCPhase2Active } = messageStore!;
  const { intl } = languageStore!;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const onOpenPricingModalClicked = useCallback(() => {
    openPrimaryModal(<PricingModal />);
  }, []);

  return (
    isMCPhase2Active ? (<TailwindBox className={["MessageCenterMenuButton"]}>
      <TailwindKebabMenu
        margin={isMobileDevice ? ["ml-2"] : []}
        width={"w-auto"}
        textColor={"text-secondary-color"}
        borderRadius={"rounded-lg"}
        padding={["p-1"]}>
        <TailwindFlex
          flexDirection={"flex-col"}
          justifyContent={"justify-start"}
          alignItems='items-start'>
            <TailwindTranslatedText
              descriptor={{
                id: "settings.other-options",
                defaultMessage: "Other options",
              }}
              paragraphProps={{
                fontSize: "text-base",
                fontWeight: "font-bold",
                margin: ["ml-4"],
              }}
            />
            <TailwindDivider
            color='bg-header-bg-color'
            margin={["my-4"]}
            opacity='opacity-50'
            style={{ height: "2px" }}
            />
            <TailwindFlex flexDirection={"flex-row"}>
              <TailwindFlex
              width={"w-auto"}
              flexDirection={"flex-col"}>
                <TailwindText
                  fontSize={"text-sm"}
                  onClick={onOpenPricingModalClicked}
                  cursor='cursor-pointer'>
                  <TailwindIcon 
                    verticalAlign={"align-middle"}
                    fontSize={"text-xl"}
                    margin={["mr-2", "ml-4"]} 
                    name={CustomIconName.token}
                    pseudoClasses={[
                    ]}
                    className={"KebabIcon"}/> 
                      {intl.formatMessage({
                        id: "messageCenter.kebab.message-price",
                        defaultMessage: "Message Price",
                      })}
                </TailwindText>
              </TailwindFlex>
              <TailwindFlex
              flexDirection={"flex-col"}
              width={"w-auto"}
              margin={["ml-auto", "mt-1"]}>
                <TailwindIcon
                  onClick={onOpenPricingModalClicked}
                  cursor='cursor-pointer'
                  name={CustomIconName["arrow-right"]} 
                  fontSize={"text-xs"}
                  margin={["mr-3"]}/>
              </TailwindFlex>
            </TailwindFlex>
          <TailwindDivider
            color='bg-header-bg-color'
            margin={["my-4"]}
            opacity='opacity-50'
            style={{ height: "2px" }}
          />
          <TailwindFlex flexDirection={"flex-row"}>
            <TailwindFlex
            width={"w-auto"}
            flexDirection={"flex-col"}>
              <TailwindText
                fontSize={"text-sm"}
                onClick={handleOpenDialog}
                cursor='cursor-pointer'>
                <TailwindIcon
                  verticalAlign={"align-middle"}
                  fontSize={"text-xl"}
                  margin={["mr-2", "ml-4"]} 
                  name={CustomIconName["guidelines-icon"]} 
                  className={"KebabIcon"}/> 
                    {intl.formatMessage({
                      id: "messageCenter.kebab.guidelines",
                      defaultMessage: "Guidelines",
                    })}
              </TailwindText>
            </TailwindFlex>
            <TailwindFlex
            flexDirection={"flex-col"}
            width={"w-auto"}
            margin={["ml-auto", "mt-1"]}>
            <TailwindIcon
              onClick={handleOpenDialog}
              cursor='cursor-pointer'
              name={CustomIconName["arrow-right"]} 
              fontSize={"text-xs"}
              margin={["mr-3"]}/>
            </TailwindFlex>
          </TailwindFlex>
          <TailwindDivider
            color='bg-header-bg-color'
            margin={["my-4"]}
            opacity='opacity-50'
            style={{ height: "2px" }}
          />
          <a
            href={`${config.legacyModelsUrl}/p/mc/folder.cgi?folder=inbox`}
            target='_blank'
            rel='noreferrer'
            style={{
              display: "contents",
              color: "rgb(92, 92, 92)"
            }}>
            <TailwindFlex flexDirection={"flex-row"}>
              <TailwindFlex
              width={"w-auto"}
              flexDirection={"flex-col"}>
                <TailwindText
                  fontSize={"text-sm"}
                  onClick={logArchivedMsgVisit}
                  cursor='cursor-pointer'>
                  <TailwindIcon
                    verticalAlign={"align-middle"}
                    fontSize={"text-xl"}
                    margin={["mr-2", "ml-4"]}
                    name={CustomIconName["archived-icon"]} 
                    className={"KebabIcon"}/> 
                      {intl.formatMessage({
                        id: "messageCenter.kebab.archived-messages",
                        defaultMessage: "Archived Messages",
                      })}
                </TailwindText>
              </TailwindFlex>
              <TailwindFlex
              flexDirection={"flex-col"}
              width={"w-auto"}
              margin={["ml-auto", "mt-1"]}>
                <TailwindIcon
                  name={CustomIconName["arrow-right"]} 
                  fontSize={"text-xs"}
                  margin={["mr-3"]}/>
              </TailwindFlex>
            </TailwindFlex>
          </a>
        </TailwindFlex>
      </TailwindKebabMenu>
      <Modal open={isDialogOpen}>
        <TailwindBox
          // className={["BroadcastShowTypeModal"]}
          backgroundColor={"bg-primary-bg-color"}
          maxWidth='max-w-3xl'
          borderRadius={"rounded-3xl"}>
          <ModalCloseButton
            className={`p-2 ${
              isMobileDevice && screenOrientation === "landscape" ? "mt-8" : ""
            }`}
            closeModal={handleCloseDialog}
          />
          <TailwindBox
            padding={
              isMobileDevice && screenOrientation === "landscape"
                ? ["py-24", "px-10"]
                : ["py-16", "px-10"]
            }>
            <TailwindParagraph margin={["my-2"]}>
              {intl.formatMessage({
                id: "messageCenter.guidelinesModal.connect",
                defaultMessage: "Connect with members using our message center. All messages are reviewed by our support team to ensure they meet our communication guidelines (e.g., no contact information allowed).",
              })}
            </TailwindParagraph>

            <TailwindParagraph margin={["my-2"]}>
              <TailwindText
                margin={["mb-2"]}
                as={"div"}
                fontWeight='font-bold'
                textAlign={"text-left"}>
                {intl.formatMessage({
                  id: "messageCenter.guidelinesModal.guidelines",
                  defaultMessage: "Our communication guidelines prohibit the following:",
                })}
              </TailwindText>
              <TailwindList fontSize={"text-sm"}>
                <TailwindListItem listStyleType={"list-disc"}>
                  {intl.formatMessage({
                    id: "messageCenter.guidelinesModal.bullet-1",
                    defaultMessage: "Sending solicitations to any website",
                  })}
                </TailwindListItem>
                <TailwindListItem listStyleType={"list-disc"}>
                  {intl.formatMessage({
                    id: "messageCenter.guidelinesModal.bullet-2",
                    defaultMessage: "Sending harassing or threatening messages",
                  })}
                </TailwindListItem>
                <TailwindListItem listStyleType={"list-disc"}>
                  {intl.formatMessage({
                    id: "messageCenter.guidelinesModal.bullet-3",
                    defaultMessage: "Posting fake/inappropriate accounts or photos",
                  })}
                </TailwindListItem>
                <TailwindListItem listStyleType={"list-disc"}>
                  {intl.formatMessage({
                    id: "messageCenter.guidelinesModal.bullet-4",
                    defaultMessage: "Being abusive or sending solicitations",
                  })}
                </TailwindListItem>
              </TailwindList>
            </TailwindParagraph>
          </TailwindBox>
        </TailwindBox>
      </Modal>
    </TailwindBox>) : 
    <TailwindBox className={["MessageCenterMenuButton"]}>
    <TailwindKebabMenu
      margin={isMobileDevice ? ["ml-2"] : []}
      width={"w-auto"}
      fontSize={isMobileDevice ? "text-3xl" : "text-5xl"}
      textColor={"text-secondary-color"}
      backgroundColor={
        isMobileDevice ? "bg-tertiary-bg-color" : "bg-quaternary-bg-color"
      }
      borderRadius={"rounded-full"}
      padding={["p-1"]}>
      <TailwindFlex
        flexDirection={"flex-col"}
        justifyContent={"justify-start"}
        alignItems='items-start'>
        <TailwindText
          textColor={"text-pink-color"}
          onClick={handleOpenDialog}
          cursor='cursor-pointer'>
          Guidelines
        </TailwindText>
        <TailwindDivider
          color='bg-header-bg-color'
          margin={["my-4"]}
          opacity='opacity-50'
          style={{ height: "2px" }}
        />
        <a
          href={`${config.legacyModelsUrl}/p/mc/folder.cgi?folder=inbox`}
          target='_blank'
          rel='noreferrer'
          style={{
            display: "flex",
          }}>
          <TailwindText
            textColor={"text-pink-color"}
            onClick={logArchivedMsgVisit}>
            Archived Messages
          </TailwindText>
        </a>
      </TailwindFlex>
    </TailwindKebabMenu>
    <Modal open={isDialogOpen}>
      <TailwindBox
        // className={["BroadcastShowTypeModal"]}
        backgroundColor={"bg-primary-bg-color"}
        maxWidth='max-w-3xl'
        borderRadius={"rounded-3xl"}>
        <ModalCloseButton
          className={`p-2 ${
            isMobileDevice && screenOrientation === "landscape" ? "mt-8" : ""
          }`}
          closeModal={handleCloseDialog}
        />
        <TailwindBox
          padding={
            isMobileDevice && screenOrientation === "landscape"
              ? ["py-24", "px-10"]
              : ["py-16", "px-10"]
          }>
          <TailwindParagraph margin={["my-2"]}>
            Connect with members using our message center. All messages are
            reviewed by our support team to ensure they meet our communication
            guidelines (e.g., no contact information allowed).
          </TailwindParagraph>

          <TailwindParagraph margin={["my-2"]}>
            <TailwindText
              margin={["mb-2"]}
              as={"div"}
              fontWeight='font-bold'
              textAlign={"text-left"}>
              Our communication guidelines prohibit the following:
            </TailwindText>
            <TailwindList fontSize={"text-sm"}>
              <TailwindListItem listStyleType={"list-disc"}>
                Sending solicitations to any website
              </TailwindListItem>
              <TailwindListItem listStyleType={"list-disc"}>
                Sending harassing or threatening messages
              </TailwindListItem>
              <TailwindListItem listStyleType={"list-disc"}>
                Posting fake/inappropriate accounts or photos
              </TailwindListItem>
              <TailwindListItem listStyleType={"list-disc"}>
                Being abusive or sending solicitations
              </TailwindListItem>
            </TailwindList>
          </TailwindParagraph>
        </TailwindBox>
      </TailwindBox>
    </Modal>
  </TailwindBox>)
};

export default inject(
  "layoutStore",
  "modalStore",
  "messageStore",
  "languageStore"
)(observer(MessageCenterMenuButton));
