import React, { useCallback, useMemo } from "react";
import TailwindCard from "library/components/_tailwind/card";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindButton from "library/components/_tailwind/button";
import { inject, observer } from "mobx-react";
import MemberBadges from "./member-badges";
import { MyFansPageMemberStats } from "containers/model/my-fans/stores/my-fans/types";
import MyFansStore from "containers/model/my-fans/stores/my-fans/MyFansStore";
import TailwindTranslatedNumber from "library/components/_tailwind/translated-number";

type Props = {
  fan: MyFansPageMemberStats;
  myFansStore?: MyFansStore;
  showDesktopDivider?: boolean;
};

const MyFansMemberTableRowDesktop: React.ComponentType<Props> = ({
  fan,
  myFansStore,
  showDesktopDivider = false,
}) => {
  const { setSelectedMemberId, selectedMemberId, sendMessageToMember } =
    myFansStore!;

  const onClickSendMessage = useCallback(() => {
    sendMessageToMember(fan?.member?.user_id || "");
  }, [fan]);

  const onClickCard = useCallback(() => {
    if (selectedMemberId !== fan?.member?.id) {
      setSelectedMemberId(fan?.member?.id);
    }
  }, [fan, selectedMemberId]);

  const isSelected = useMemo(() => {
    return fan?.member?.id === selectedMemberId;
  }, [selectedMemberId, fan]);

  return (
    <TailwindCard
      padding={["p-4"]}
      flexDirection={"flex-col"}
      pseudoClasses={["hover:bg-secondary-bg-color"]}
      className={
        isSelected ? ["bg-card-secondary-bg-color"] : ["bg-primary-bg-color"]
      }
      borderColor={"border-table-secondary-color"}
      borderStyle={"border-solid"}
      borderWidth={[showDesktopDivider ? "border-b" : "border-0"]}
      onClick={onClickCard}>
      <TailwindFlex
        flexDirection={"flex-row"}
        alignItems={"items-baseline"}
        width={"w-full"}
        justifyContent={"justify-between"}
        justifyItems={"justify-items-center"}
        overflow={"overflow-hidden"}>
        <TailwindFlex
          width={"w-auto"}
          flex={"flex-1"}
          padding={["pr-4"]}
          alignItems={"items-center"}>
          <TailwindText
            fontSize={"text-base"}
            fontWeight={isSelected ? "font-bold" : "font-normal"}
            overflow={"overflow-hidden"}
            textColor='text-main-color'
            textOverflow={"overflow-ellipsis"}
            whiteSpace={"whitespace-nowrap"}>
            {fan.member.username}
          </TailwindText>
          <TailwindButton
            onClick={onClickSendMessage}
            fullWidth={false}
            padding={["p-0", "pl-2", "pr-2"]}
            height={"h-full"}
            rightIconProps={{
              name: "email",
              fontSize: "text-lg",
            }}
          />
          <MemberBadges fan={fan} />
        </TailwindFlex>
        <TailwindFlex width={"w-auto"}>
          <TailwindTranslatedNumber
            textProps={{
              textColor: "text-main-color",
              fontWeight: isSelected ? "font-bold" : "font-normal",
              margin: ["mr-1"],
            }}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
            value={parseInt(fan.amount_spent_in_token)}
            style='decimal'
          />
          <TailwindIcon
            textColor={"text-yellow-500"}
            name={CustomIconName.token}
          />
        </TailwindFlex>
      </TailwindFlex>
    </TailwindCard>
  );
};

export default inject("myFansStore")(observer(MyFansMemberTableRowDesktop));
