import React, { useMemo } from 'react';
import { COUNTRY_FLAGS } from "library/core/constants";
import find from 'lodash/find';
import { ICountryFlags } from 'library/core/interfaces';
import { languageStore } from "library/core/stores/language/LanguageStore";

interface Props {
  code: string;
}

const EmojiFlag: React.FC<Props> = ({ code}) => {
  const { language } = languageStore!;
  const extractLanguage = useMemo(() => language.split('-')[0], [language]);
  const country: ICountryFlags | undefined = useMemo(() =>  find(COUNTRY_FLAGS, (country) => country.ISO === code.toUpperCase()), [code]);
  const countryName = useMemo(() => country?.country[extractLanguage] || country?.country['en'], [country, extractLanguage]);

  if (!country) return null;

  return (
    <>
    <span className='user-country'>
      <span className='user-country__flag'>{country.emoji}</span>
      <span className='user-country__name'>{countryName}</span>
    </span>
    </>
  );
};

export default EmojiFlag;
