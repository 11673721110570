import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import AuthStore from "core/stores/auth/AuthStore";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import config from "core/config";
import "./styles.scss";
import ModalStore from "library/core/stores/modal";
import ProfileStore from "../my-page/stores/profile/ProfileStore";
import TailwindFlex from "library/components/_tailwind/flex";
import { openRegisterModal } from "core/stores/auth/utils";
import TailwindInput from "library/components/_tailwind/input";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import TailwindCheckboxRadio from "library/components/_tailwind/checkbox-radio";
import TailwindAnchor from "library/components/_tailwind/anchor";
import TailwindButton from "library/components/_tailwind/button";
import RecaptchaForm from "library/components/_tailwind/recaptcha";
import { NullableString } from "library/core/types";
import TailwindParagraph from "library/components/_tailwind/paragraph";

type LoginModalProps = {
  authStore?: AuthStore;
  modalStore?: ModalStore;
  validationStore?: ValidationStore;
  profileStore?: ProfileStore;
};

const LoginModal: FunctionComponent<LoginModalProps & WrappedComponentProps> =
  ({ profileStore, authStore, modalStore, validationStore, intl }) => {
    const {
      rememberMe,
      toggleRememberMe,
      login,
      loginInProgress,
      isActionInProgress,
      loginFormData: { emailOrUsername, password },
      updateLoginFormField,
      userRole,
    } = authStore!;
    const { clearErrors, clearError, errors, validate } = validationStore!;
    const { closeAuthModal } = modalStore!;
    const {
      //isCams
    } = profileStore!;
    const { googleCaptchaSiteKey } = config!;

    const isWaitingOnResponse = useMemo(() => {
      return loginInProgress || isActionInProgress;
    }, [loginInProgress, isActionInProgress]);

    const handleRememberMeChange = useCallback(
      isChecked => {
        toggleRememberMe(isChecked);
      },
      [authStore]
    );

    const onOpenRegisterModalClicked = useCallback(() => {
      openRegisterModal();
    }, []);

    const onCaptchaChange = async (recaptchaKey: NullableString) => {
      if (recaptchaKey) {
        try {
          await login(recaptchaKey);
        } catch (error) {
          // do not remove as this is used to fallback to v2
          throw new Error(error);
        }
      }
    };

    useEffect(() => {
      clearErrors();
      return () => {
        clearErrors();
      };
    }, []);

    return (
      <TailwindFlex width={"w-96"} sm={"w-full"}>
        <RecaptchaForm
          className={"LoginModal__form"}
          margin={["mt-6"]}
          captchaSiteKey={googleCaptchaSiteKey}
          beforeExecuteRecaptcha={clearErrors}
          callback={onCaptchaChange}>
          <TailwindTranslatedText
            headingProps={{ level: 5, textAlign: "text-center" }}
            descriptor={{
              id: "landing.welcomeBack.title",
              defaultMessage: "Welcome Back",
            }}
          />
          <TailwindFlex margin={["mt-8", "mb-2"]}>
            <TailwindInput
              required
              rounded={false}
              backgroundColor={"transparent"}
              borderColor={"border-main-color"}
              borderWidth={["border"]}
              label={intl.formatMessage({
                id: "landing.welcomeBack.emailOrUsername",
                defaultMessage: "Email or Username",
              })}
              labelProps={{
                margin: [],
              }}
              errorProps={{
                textAlign: "text-right",
              }}
              value={emailOrUsername || ""}
              type={TailwindInputType.text}
              name={"emailOrUsername"}
              onChange={e => {
                clearError("emailOrUsername");
                updateLoginFormField(e.target);
              }}
              error={errors?.emailOrUsername || undefined}
              onBlur={_e => validate("emailOrUsername", emailOrUsername)}
            />
          </TailwindFlex>
          <TailwindFlex margin={["mt-4", "mb-2"]}>
            <TailwindInput
              required
              rounded={false}
              backgroundColor={"transparent"}
              borderColor={"border-main-color"}
              borderWidth={["border"]}
              label={intl.formatMessage({
                id: "landing.welcomeBack.password",
                defaultMessage: "Password",
              })}
              labelProps={{
                margin: [],
              }}
              errorProps={{
                textAlign: "text-right",
              }}
              value={password || ""}
              type={TailwindInputType.password}
              name={"password"}
              onChange={e => {
                clearError("password_login");
                updateLoginFormField(e.target);
              }}
              error={errors?.password_login || errors?.password || undefined}
              onBlur={_e => validate("password_login", password)}
            />
          </TailwindFlex>
          {!!errors?.detail && (
            <TailwindParagraph margin={["mb-4"]} textColor={"text-red-500"}>
              {errors?.detail}
            </TailwindParagraph>
          )}
          <TailwindFlex
            width='w-full'
            alignItems={"items-center"}
            justifyContent={"justify-between"}
            margin={["mb-4"]}>
            <TailwindFlex width={"w-auto"} alignItems={"items-center"}>
              <TailwindAnchor
                to={"/reset-password"}
                onClick={closeAuthModal}
                underline={false}>
                <TailwindTranslatedText
                  fontWeight={"font-bold"}
                  textColor={"text-yellow-500"}
                  descriptor={{
                    id: "landing.welcomeBack.forgotPassword",
                    defaultMessage: "Forgot Password?",
                  }}
                />
              </TailwindAnchor>
            </TailwindFlex>
            <TailwindFlex width={"w-auto"} alignItems={"items-center"}>
              <TailwindCheckboxRadio
                type={TailwindInputType.checkbox}
                isChecked={rememberMe}
                onChange={handleRememberMeChange}
                label={intl.formatMessage({
                  id: "landing.welcomeBack.keepMeLoggedIn",
                  defaultMessage: "Keep me logged in",
                })}
                labelProps={{
                  display: "inline-flex",
                  textColor: "text-gray-400",
                  className: "capitalize-first",
                }}
              />
            </TailwindFlex>
          </TailwindFlex>
          <TailwindFlex margin={["mt-2", "mb-4"]}>
            <TailwindButton
              type={"submit"}
              margin={["mt-4", "mb-4"]}
              showSpinner={isWaitingOnResponse}
              fullWidth
              size={"lg"}
              backgroundColor={"primary"}
              justifyContent={"justify-center"}
              textColor={"text-white"}
              rounded={false}>
              <TailwindTranslatedText
                descriptor={{
                  id: "landing.welcomeBack.login",
                  defaultMessage: "Login",
                }}
                textTransform={"uppercase"}
                fontWeight={"font-bold"}
              />
            </TailwindButton>
          </TailwindFlex>
          <TailwindFlex
            alignItems={"items-center"}
            textColor='text-main-color'
            justifyContent={"justify-center"}>
            {userRole === "model" ? "Not a model?" : "Not a studio?"}
            <TailwindButton
              fullWidth={false}
              onClick={onOpenRegisterModalClicked}
              padding={["p-0"]}
              margin={["ml-2"]}>
              <TailwindTranslatedText
                textColor={"text-yellow-500"}
                descriptor={{
                  id: "landing.welcomeBack.signUpNow",
                  defaultMessage: "Signup now",
                }}
              />
            </TailwindButton>
          </TailwindFlex>
        </RecaptchaForm>
      </TailwindFlex>
    );
  };

export default injectIntl(
  inject(
    "modalStore",
    "authStore",
    "validationStore",
    "profileStore"
  )(observer(LoginModal))
);
