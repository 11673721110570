import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import { injectIntl, WrappedComponentProps } from "react-intl";

export const tierInfoData = [
  {
    label: "Month 1 & 2",
    value: "36%",
  },
  {
    label: "Month 3 & 4",
    value: "24%",
  },
  {
    label: "Month 5 & 6",
    value: "12%",
  },
  {
    label: "Month 7 & 8",
    value: "6%",
  },
  {
    label: "Month 9 & 10",
    value: "4%",
  },
  {
    label: "Month 11 & 12",
    value: "2%",
  },
];

type Props = {};

const ReferralsEarningsTier: React.ComponentType<
  Props & WrappedComponentProps
> = (
  {
    /*intl*/
  }
) => {
  return (
    <TailwindFlex
      height={"h-full"}
      width={"w-full"}
      maxWidth={"max-w-lg"}
      flexDirection={"flex-col"}
      alignItems={"items-start"}
      justifyContent={"justify-center"}
      pseudoClasses={["md:h-auto"]}
      backgroundColor={"bg-primary-bg-color"}>
      <TailwindFlex flexWrap={"flex-wrap"} padding={["p-4"]}>
        <TailwindFlex width={"w-full"} margin={["mb-2"]}>
          <TailwindTranslatedText
            headingProps={{
              level: 5,
            }}
            descriptor={{
              id: "admirers.referrals.title",
              defaultMessage: "How the Model Referral Program works:",
            }}
          />
        </TailwindFlex>
        <TailwindFlex flexDirection={"flex-col"} margin={["mb-4"]}>
          {tierInfoData.map((tierInfoItem, index) => (
            <TailwindFlex
              pseudoClasses={["odd:bg-gray-100", "even:bg-gray-200"]}
              key={`tier-info-${index}`}
              padding={["p-2", "pl-5", "pr-5"]}
              flexDirection={"flex-row"}>
              <TailwindFlex width={"w-1/2"}>
                <TailwindText>{tierInfoItem.label}</TailwindText>
              </TailwindFlex>
              <TailwindFlex width={"w-1/2"}>
                <TailwindText textAlign={"text-right"} width={"w-full"}>
                  {tierInfoItem.value}
                </TailwindText>
              </TailwindFlex>
            </TailwindFlex>
          ))}
        </TailwindFlex>
        <TailwindTranslatedText
          textColor='text-main-color'
          descriptor={{
            id: "admirers.referrals.programMoreInfo",
            defaultMessage:
              "Tips, pay-per-minute tokens, and virtual gifts are eligible for model referral bonuses.  We only require that you broadcast at least once in the last 30 days to stay eligible for referral bonuses.",
          }}
        />
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(ReferralsEarningsTier);
