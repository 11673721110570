import { inject, observer } from "mobx-react";
import React, { useMemo } from "react";
import TailwindCard from "library/components/_tailwind/card";
import TailwindHeading from "library/components/_tailwind/heading";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindTabs from "library/components/_tailwind/tabs";
import TailwindBox from "library/components/_tailwind/box";
import OBSModalTab from "./obs-modal-tab";
import OBSModalTabStep1 from "./obs-modal-tab-step1";
import OBSModalTabStep2 from "./obs-modal-tab-step2";
import OBSModalTabStep3 from "./obs-modal-tab-step3";
import OBSModalTabStep4 from "./obs-modal-tab-step4";
import OBSModalTabStep5 from "./obs-modal-tab-step5";
import OBSModalTabStep5ForObsSupportPage from "common/broadcast/broadcast-obs-modal/obs-modal-tab-step5-for-obs-support-page";
import LanguageStore from "library/core/stores/language/LanguageStore";

type Props = {
  showDifferentStepStreamUrlForStandaloneObsHelpPage?: boolean;
  showStreamCredentialsStep?: boolean;
  languageStore?: LanguageStore;
};

const BroadcastOBSModal: React.FunctionComponent<
  Props
> = ({
  languageStore,
  showDifferentStepStreamUrlForStandaloneObsHelpPage,
  showStreamCredentialsStep,
}) => {
  const { intl } = languageStore!;

  const tabs = useMemo(() => {
    const _tabs = [
      {
        id: "step1",
        label: (
          <OBSModalTab
            stepNumber={1}
            title={{
              id: "broadcast-obs-modal.obs-step-1-title",
              defaultMessage: "Requirements",
            }}
          />
        ),
        component: (
          <>
            <TailwindBox margin={["mr-1"]} textColor={"text-gray-500"}>
              &#8226;
            </TailwindBox>
            <TailwindBox minHeight='min-h-72'>
              <OBSModalTabStep1 />
            </TailwindBox>
          </>
        ),
      },
      {
        id: "step2",
        label: (
          <OBSModalTab
            stepNumber={2}
            title={{
              id: "broadcast-obs-modal.obs-step-2-title",
              defaultMessage: "Download & Install",
            }}
          />
        ),
        component: (
          <>
            <TailwindBox margin={["mr-1"]} textColor={"text-gray-500"}>
              &#8226;
            </TailwindBox>
            <TailwindBox minHeight='min-h-72'>
              <OBSModalTabStep2 />
            </TailwindBox>
          </>
        ),
      },
      {
        id: "step3",
        label: (
          <OBSModalTab
            stepNumber={3}
            title={{
              id: "broadcast-obs-modal.obs-step-3-title",
              defaultMessage: "Configuration",
            }}
          />
        ),
        component: (
          <>
            <TailwindBox margin={["mr-1"]} textColor={"text-gray-500"}>
              &#8226;
            </TailwindBox>
            <TailwindBox minHeight='min-h-72'>
              <OBSModalTabStep3 />
            </TailwindBox>
          </>
        ),
      },
      {
        id: "step4",
        label: (
          <OBSModalTab
            stepNumber={4}
            title={{
              id: "broadcast-obs-modal.obs-step-4-title",
              defaultMessage: "Recommended Settings",
            }}
          />
        ),
        component: (
          <>
            <TailwindBox margin={["mr-1"]} textColor={"text-gray-500"}>
              &#8226;
            </TailwindBox>
            <TailwindBox minHeight='min-h-72'>
              <OBSModalTabStep4 />
            </TailwindBox>
          </>
        ),
      },
    ];

    if (showStreamCredentialsStep) {
      _tabs.push({
        id: "step5",
        label: (
          <OBSModalTab
            stepNumber={5}
            title={{
              id: "broadcast-obs-modal.obs-step-5-title",
              defaultMessage: "Stream URL",
            }}
          />
        ),
        component: (
          <>
            <TailwindBox margin={["mr-1"]} textColor={"text-gray-500"}>
              &#8226;
            </TailwindBox>
            <TailwindBox minHeight='min-h-72'>
              {showDifferentStepStreamUrlForStandaloneObsHelpPage ? (
                <OBSModalTabStep5ForObsSupportPage />
              ) : (
                <OBSModalTabStep5 />
              )}
            </TailwindBox>
          </>
        ),
      });
    }

    return _tabs;
  }, [
    showStreamCredentialsStep,
    showDifferentStepStreamUrlForStandaloneObsHelpPage,
  ]);

  return (
    <TailwindCard
      backgroundColor={"primary"}
      padding={["p-10"]}
      width={"w-192"}
      pseudoClasses={["md:w-full"]}>
      <TailwindHeading level={6} margin={["mb-4"]}>
        {intl.formatMessage({
          id: "broadcast-obs-modal.obs-broadcasting-title",
          defaultMessage: "OBS Broadcasting Information",
        })}
      </TailwindHeading>
      <TailwindTranslatedText
        paragraphProps={{
          margin: ["mb-2"],
        }}
        descriptor={{
          id: "broadcast-obs-modal.obs-instructions",
          defaultMessage:
            "Your earning potential expands when your stream quality is clear. Our most successful models have a beautifully sharp stream. And you can, too. Broadcast in the highest quality video possible with OBS, a popular program for Mac and PC computers. Follow these step-by-step instructions to increase your chances to earn:",
        }}
      />
      <TailwindBox margin={["mb-4"]}>
        <a
          className={"text-indigo-700"}
          href='https://help.streamray.com/models/knowledgebase/how-to-use-obs-for-broadcasting-in-hd-high-definition/'
          target='new'>
          https://help.streamray.com/models/knowledgebase/how-to-use-obs-for-broadcasting-in-hd-high-definition/
        </a>
      </TailwindBox>
      <TailwindHeading level={6}>
        {intl.formatMessage({
          id: "broadcast-obs-modal.obs-step-by-step-title",
          defaultMessage: "Step-by-Step Instruction",
        })}
      </TailwindHeading>
      <TailwindTabs
        margin={["mt-2"]}
        headerProps={{
          buttonProps: {
            height: "h-full",
            width: "w-full",
            justifyContent: "justify-center",
            padding: ["p-2"],
            textProps: { textAlign: "text-center" },
            backgroundColor: "bg-gray-300",
          },
        }}
        key={"obs-step"}
        tabs={tabs}></TailwindTabs>
    </TailwindCard>
  );
};

export default inject("languageStore")(observer(BroadcastOBSModal));
