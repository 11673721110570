import TokenHelper from "../token-helper";
const tokenHelper = new TokenHelper();

const calculateModelsBonusEarnings = (model: any): number => {
  const sum =
    model.earned_tokens.fan_club_membership +
    model.amounts.member_referrals_earned +
    model.amounts.model_referral_earned +
    model.amounts.member_bounty_referrals_earned +
    model.earned_tokens.album +
    model.earned_tokens.image +
    model.earned_tokens.video +
    model.earned_tokens.gallery +
    model.earned_tokens.fan_club_membership_chargeback;

  return Number(tokenHelper.tokenToCurrency(sum).toFixed(2));
};

export default calculateModelsBonusEarnings;
