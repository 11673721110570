import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { inject, observer } from "mobx-react";
import MessageStore from "./stores/messages/MessageStore";
import TailwindFlex from "library/components/_tailwind/flex";
import FilterContactList from "./message-navigator-filter-contact";
import { TBackgroundColor } from "tailwindcss-classnames";
import TailwindText from "library/components/_tailwind/text";
import TailwindButton from "library/components/_tailwind/button";
import {
  LOYAL_FANS_FILTERS,
  LoyalFanFiltersEnum,
  LoyalFanFiltersKey,
} from "./stores/messages/consts";
import TailwindBox from "library/components/_tailwind/box";
import TailwindCheckboxRadio from "library/components/_tailwind/checkbox-radio";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import { Popover } from "common/popover";
import TailwindIcon from "library/components/_tailwind/icon";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";

type Props = {
  isMobile?: boolean;
  messageStore?: MessageStore;
};

export type ContactListFilter = {
  name: string;
  custom_label?: string;
  background: TBackgroundColor;
  type: string;
};

type ContactFilterCount = {
  [key in LoyalFanFiltersEnum]: number;
};

const MessageContactListFilter: React.ComponentType<
  Props & WrappedComponentProps
> = ({ isMobile, messageStore }) => {
  const {
    unFilteredContacts,
    searchedContacts,
    contactsSearchTerm,
    getContacts,
    isBulkMessageView,
    isContactsLoading,
    contactsFilterTypes,
  } = messageStore!;
  const didMountRef = useRef(false);
  const [filterSelected, setSelectedFilter] = useState<string>("");
  const [deselectFilter, setDeselectfilter] = useState<string>("");
  const [filtersSelected, setSelectedFilters] = useState<string[]>([]);
  const [tempSelectedFilters, setTempSelectedFilters] = useState<string[]>([]);
  const [tempSelectedFilterNames, setTempSelectedFilterNames] = useState<
    string[]
  >([]);
  const [filtersSelectedNames, setSelectedFiltersNames] = useState<string[]>(
    []
  );
  const [contactListFilters, SetContactListFilters] = useState<
    ContactListFilter[]
  >([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const popoverRef = useRef<HTMLDivElement>(null);

  const counts: ContactFilterCount = useMemo(() => {
    const filterCounts = {
      fanclub_members: 0,
      bounty_members: 0,
      top_admirers: 0,
      top_spenders: 0,
      recent_visitors: 0,
    };
    contactListFilters.forEach(filter => {
      const contactList = contactsSearchTerm
        ? searchedContacts
        : unFilteredContacts;
      const filteredContacts = contactList.filter(contact => {
        const filters = LoyalFanFiltersKey[filter.type];

        return filters.some(filter => contact[filter]);
      });
      filterCounts[filter.type] = filteredContacts?.length || 0;
    });

    return filterCounts;
  }, [
    unFilteredContacts,
    searchedContacts,
    contactListFilters,
    contactsSearchTerm,
  ]);

  const _style = useMemo(() => {
    const newStyle = {};

    if (maxHeight) {
      newStyle["maxHeight"] = `${maxHeight - 10}px`;
    }

    return newStyle;
  }, [maxHeight]);

  useEffect(() => {
    if (isBulkMessageView) {
      setSelectedFilters([]);
      setSelectedFiltersNames([]);
    }
  }, [isBulkMessageView]);

  useEffect(() => {
    SetContactListFilters(LOYAL_FANS_FILTERS);
  }, [filtersSelected, filterSelected]);

  useEffect(() => {
    if (deselectFilter) {
      setTempSelectedFilterNames(
        tempSelectedFilterNames.filter(type => type !== deselectFilter)
      );
      setTempSelectedFilters(
        tempSelectedFilters.filter(type => type !== deselectFilter)
      );
    } else if (filterSelected) {
      filterSelected &&
        setTempSelectedFilters(prev => [...prev, filterSelected]);
      filterSelected &&
        setTempSelectedFilterNames(prev => [...prev, filterSelected]);
    }
    setSelectedFilter("");
    setDeselectfilter("");
  }, [filterSelected, deselectFilter]);

  useEffect(() => {
    if (isContactsLoading) return;
    filtersSelected.length > 0
      ? getContacts(false, filtersSelected)
      : didMountRef.current && getContacts();

    didMountRef.current = true;
  }, [filtersSelected, contactsSearchTerm]);

  const isAllFiltersSelected = useMemo(() => {
    return tempSelectedFilters?.length >= contactListFilters?.length;
  }, [tempSelectedFilters, contactListFilters]);

  const selectAllFilters = () => {
    if (isAllFiltersSelected) {
      setTempSelectedFilters([]);
      setTempSelectedFilterNames([]);
    } else {
      setTempSelectedFilters(contactListFilters.map(filter => filter.type));
      setTempSelectedFilterNames(contactListFilters.map(filter => filter.name));
    }
  };

  const resetFilters = () => {
    setTempSelectedFilters([]);
    setTempSelectedFilterNames([]);
  };

  const handleApplyFilters = () => {
    // apply new filters
    setSelectedFilters(tempSelectedFilters);
    setSelectedFiltersNames(tempSelectedFilterNames);
    setFilterOpen(false);
  };

  const onHide = useCallback(() => {
    setFilterOpen(false);
  }, []);

  const onShow = useCallback(() => {
    // apply previously selected filters
    setTempSelectedFilters(filtersSelected);
    setTempSelectedFilterNames(filtersSelectedNames);

    setFilterOpen(true);
  }, [filtersSelected, filtersSelectedNames]);

  const handlePopoverResize = () => {
    const popoverRect = popoverRef?.current?.getBoundingClientRect();
    setMaxHeight(0);

    if (popoverRect) {
      const offsetY = popoverRect.y;
      const isOverflowing = popoverRect.bottom > window.innerHeight;

      if (isOverflowing) {
        setMaxHeight(window.innerHeight - offsetY);
      }
    }
  };

  useEffect(() => {
    if (filterOpen) {
      setTimeout(() => {
        handlePopoverResize();
      });
    } else {
      setMaxHeight(0);
    }
  }, [filterOpen]);

  useEffect(() => {
    window.addEventListener("resize", handlePopoverResize);
    return () => window.removeEventListener("resize", handlePopoverResize);
  });

  return (
    <>
      <TailwindIcon
        onClick={onShow}
        backgroundColor={"bg-tertiary-bg-color"}
        padding={["p-2.5"]}
        borderRadius={"rounded-full"}
        name={CustomIconName.filterIcon}
        cursor={"cursor-pointer"}
      />
      <div>
        <Popover
          placement='bottom-end'
          onHide={onHide}
          onShow={onShow}
          show={filterOpen}
          content={
            <>
              <TailwindFlex
                minWidth='min-w-80'
                flexDirection='flex-col'
                backgroundColor='bg-primary-bg-color'
                borderRadius='rounded-lg'
                padding={["p-3"]}
                boxShadow='shadow-2xl'
                ref={popoverRef}
                style={_style}>
                <TailwindFlex
                  alignItems='items-center'
                  justifyContent='justify-end'
                  onClick={() => {
                    setFilterOpen(false);
                  }}>
                  <TailwindBox cursor='cursor-pointer'>
                    <TailwindIcon fontSize={"text-base"} name={"close"} />
                  </TailwindBox>
                </TailwindFlex>

                {!isBulkMessageView && (
                  <TailwindText
                    backgroundColor='bg-primary-bg-color'
                    display='block'
                    padding={["pb-2"]}
                    fontSize='text-base'>
                    <strong>Filter Contacts by My Fan Groups</strong>
                  </TailwindText>
                )}

                <TailwindBox height={"h-auto"} overflow={"overflow-auto"}>
                  <TailwindFlex
                    alignItems='items-center'
                    justifyContent={"justify-between"}
                    padding={["py-2"]}
                    borderColor={"border-quaternary-bg-color"}
                    borderStyle={"border-solid"}
                    borderWidth={["border-t-2", "border-b-2"]}>
                    <TailwindFlex
                      alignItems='items-center'
                      cursor={
                        isContactsLoading ? "cursor-wait" : "cursor-pointer"
                      }>
                      <TailwindFlex
                        position={"relative"}
                        justifyContent={"justify-center"}
                        alignItems='items-center'
                        margin={["mx-4"]}
                        style={{
                          width: "40px",
                        }}>
                        <TailwindFlex width={"w-auto"}>
                          <TailwindCheckboxRadio
                            borderColor='border-main-color'
                            boxProps={{
                              flexDirection: "flex-col",
                              textAlign: "text-center",
                            }}
                            onChange={selectAllFilters}
                            isChecked={isAllFiltersSelected}
                            type={TailwindInputType.radio}
                          />
                        </TailwindFlex>
                      </TailwindFlex>
                      <TailwindText
                        justifyContent={"justify-end"}
                        fontSize='text-sm'>
                        All
                      </TailwindText>
                    </TailwindFlex>
                    <TailwindFlex
                      alignItems='items-center'
                      justifyContent='justify-end'
                      width='w-max'>
                      <TailwindText
                        cursor={"cursor-pointer"}
                        fontSize='text-sm'
                        textAlign='text-right'
                        textColor='text-pink-color'
                        padding={["px-4"]}
                        onClick={resetFilters}>
                        Reset
                      </TailwindText>
                    </TailwindFlex>
                  </TailwindFlex>

                  <TailwindFlex
                    height={"h-full"}
                    flexDirection='flex-col'
                    className={["MessageNavigatorConversation"]}
                    backgroundColor={"bg-primary-bg-color"}
                    opacity={
                      isContactsLoading && contactsFilterTypes.length
                        ? "opacity-70"
                        : "opacity-100"
                    }>
                    {contactListFilters.map(
                      ({ name, background, custom_label, type }, index) => (
                        <FilterContactList
                          key={type + index}
                          filterType={type}
                          name={name}
                          custom_label={custom_label}
                          backgroundColor={background}
                          setSelectedFilter={setSelectedFilter}
                          setDeselectfilter={setDeselectfilter}
                          isContactsLoading={isContactsLoading}
                          filtersSelected={tempSelectedFilters}
                          count={counts[type]}
                          boxProps={{
                            borderColor: "border-quaternary-bg-color",
                            borderStyle: "border-solid",
                            borderWidth:
                              index < contactListFilters?.length - 1
                                ? ["border-b-2"]
                                : [],
                          }}
                          avatarBoxProps={{
                            padding: ["px-4"],
                          }}
                          countBoxProps={{
                            padding: ["px-4"],
                          }}
                        />
                      )
                    )}
                  </TailwindFlex>
                </TailwindBox>

                <TailwindFlex
                  gap='gap-2'
                  justifyContent='justify-end'
                  padding={isMobile ? ["pb-6"] : ["pb-6", "pr-1.5"]}>
                  <TailwindButton
                    onClick={onHide}
                    fullWidth={false}
                    backgroundColor={"bg-primary-bg-color"}
                    borderRadius={"rounded"}
                    textColor={"text-modal-button-color"}
                    width={"w-auto"}
                    alignItems={"items-center"}
                    justifyContent={"justify-center"}
                    padding={["px-6"]}
                    borderColor={"border-table-secondary-color"}
                    borderStyle={"border-solid"}
                    borderWidth={["border-2"]}>
                    Cancel
                  </TailwindButton>
                  <TailwindButton
                    onClick={handleApplyFilters}
                    fullWidth={false}
                    backgroundColor={"bg-modal-button-color"}
                    borderRadius={"rounded"}
                    textColor={"text-white"}
                    width={"w-auto"}
                    alignItems={"items-center"}
                    justifyContent={"justify-center"}
                    padding={["px-6"]}>
                    Apply
                  </TailwindButton>
                </TailwindFlex>
              </TailwindFlex>
            </>
          }></Popover>
      </div>
    </>
  );
};

export default injectIntl(
  inject("messageStore")(observer(MessageContactListFilter))
);
