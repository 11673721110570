import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import TailwindButton from "library/components/_tailwind/button";
import TailwindFlex from "library/components/_tailwind/flex";
import { injectIntl, WrappedComponentProps } from "react-intl";

type Props = {
  openRegistrationModal: () => void;
};

const LandingSlider1: React.FunctionComponent<
  Props & RouteComponentProps & WrappedComponentProps
> = ({ openRegistrationModal, history, intl }) => {
  const handleClick = () => {
    history.push("/learn-more");
  };

  return (
    <div className={"LandingSlider__text LandingSlider__text--1"}>
      <div className={"LandingSlider__title"}>
        {intl.formatMessage({
          id: "landing.slider1.title",
          defaultMessage: "It's Easy and Safe",
        })}
      </div>
      <div className={"LandingSlider__text-body"}>
        {intl.formatMessage({
          id: "landing.slider1.body",
          defaultMessage:
            "Get a world-class support team and control when and where you broadcast.",
        })}
      </div>
      <TailwindFlex justifyContent={"justify-start"} margin={["mb-4"]}>
        <TailwindButton
          position={"relative"}
          zIndex={"z-100"}
          justifyContent={"justify-center"}
          fullWidth={false}
          margin={["mt-8", "mr-2"]}
          borderRadius={"rounded-none"}
          width={"w-5/12"}
          size={"lg"}
          backgroundColor={"primary"}
          rounded={false}
          backgroundOpacity={"bg-opacity-70"}
          onClick={openRegistrationModal}>
          {intl.formatMessage({
            id: "landing.getStarted",
            defaultMessage: "Get Started",
          })}
        </TailwindButton>
        <TailwindButton
          borderWidth={["border-2"]}
          borderColor={"border-white"}
          position={"relative"}
          zIndex={"z-100"}
          justifyContent={"justify-center"}
          fullWidth={false}
          margin={["mt-8"]}
          borderRadius={"rounded-none"}
          textProps={{ textColor: "text-white" }}
          width={"w-5/12"}
          size={"lg"}
          onClick={handleClick}>
          {intl.formatMessage({
            id: "landing.learnMore",
            defaultMessage: "Learn More",
          })}
        </TailwindButton>
      </TailwindFlex>
    </div>
  );
};

export default withRouter(injectIntl(LandingSlider1));
