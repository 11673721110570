import { getDate, getMonth, getYear } from "date-fns";
import { dateProcess } from "library/core/utility";
import { PayoutFilterMainType } from "./types";
import { generatePayPeriodYears } from "./utils";

export const EarningsInitialData = {
  payoutPageDataInitialized: false,

  currentPeriodId: 0,
  activeTab: 0,
  allTransactions: [],

  periods: [],
  products: [],
  initialProduct: { filterKey: "all", label: "All" },
  currentProduct: {},
  transactions: [],
  filteredTransactions: [],
  totalForFiltered: 0,
  total: "100",
  studioTotal: "",
  studioEarningsPeriodSum: null,

  initialTier: 32,
  currentTotal: 0,
  currentEarnings: [],
  chosenPeriod: {
    period_total_earnings: 0,
    id: 0,
    period: "",
    year: "2019",
    tier: 32,
    locked: false,
  },

  loadingPeriods: false,
  loadingCurrentTierAndTotal: false,
  loadingEarnings: false,

  currentPeriodSums: null,

  currentPeriodTotals: null,
  currentPeriodTotalsSum: null,
  loadingEarningsForCurrentPeriod: false,
  earningsForCurrentPeriod: null,
  studioModelEarningsForPeriodLight: null,
  currentPeriodString: "",
  amountNeededToNextTier: 0,

  ///studio

  errorLoadingFilters: false,
  errorLoadingEarnings: false,
  errorLoadingModelEarnings: false,
  errorLoadingPeriodEarningSummary: false,

  periodType: 0,
  years: [],
  selectedPeriodStartYear: 0,
  selectedPeriodEndYear: 0,
  selectedPeriod: {
    startDay: getDate(dateProcess.today),
    startMonth: getMonth(dateProcess.today),
    startYear: getYear(dateProcess.today),
    endDay: getDate(dateProcess.today),
    endMonth: getMonth(dateProcess.today),
    endYear: getYear(dateProcess.today),
    period: "",
  },
  initModelFilter: { id: "0", screen_name: "All" },
  models: [],
  selectedModel: null,
  modelIsSelected: false,
  totalBroadcastHours: 0,
  currentTier: "",
  earnings: [],
  periodEarningSummary: [],
  payments: [],

  loadingFilters: true,
  loadingModelEarnings: false,
  loadingPeriodEarningSummary: true,
  pageCounter: 1,
  hasMoreTransactions: true,

  loadingMemberTransactions: false,
  selectedMemberTransactions: [],
  payoutFilters: {
    category: PayoutFilterMainType.ALL,
    productTypes: [],
  },
};

export const PAY_PERIOD_YEARS = generatePayPeriodYears();

export const tierInfoData = [
  {
    label: "100k+",
    value: "70%",
  },
  {
    label: "60k+ - 100k",
    value: "60%",
  },
  {
    label: "50k+ - 60k",
    value: "50%",
  },
  {
    label: "0 - 50k",
    value: "32%",
  },
];

export const DEFAULT_BONUS_CUT_PERCENTAGE = 0.15;

export const EARNING_GRAPH_COLORS = {
  SHOWS: "#ffa05d",
  TIPS_AND_GIFTS: "#ff5497",
  REFERRALS: "#9d3171",
  OFFLINE_CONTENT: "#379979",
  GAMES: "#465bca",
  FANCLUB: "#31829d",
};

export const EARNING_GRAPH_CATEGORY_PRODUCTS = {
  SHOWS: [
    "private_chat",
    "party_chat",
    "voyeur_chat",
    "nude_chat",
    "whisper_message",
    "cam2cam_chat",
  ],
  TIPS_AND_GIFTS: [
    "tip",
    "buzz",
    "super_buzz",
    "virtual_gift",
    "tip_offline",
    "virtual_gift_offline",
  ],
  OFFLINE_CONTENT: [
    "gallery",
    "album",
    "image",
    "video",
  ],
  GAMES: [
    "wheel_of_fun"
  ],
  FANCLUB: [
    "fan_club_membership",
  ]
};

export const PAYOUT_ITEMS = [
  {
    spendKey: "memberSpentTotalOnTiered",
    payoutKey: "tieredPayoutTotal",
    labelIntl: {
      id: "payout.member.tiered_label",
      defaultMessage: "Tiered",
    },
  },
  {
    spendKey: "memberSpentTotalOnFixed",
    payoutKey: "fixedPayoutTotal",
    labelIntl: {
      id: "payout.member.fixed_label",
      defaultMessage: "Fixed",
    },
  },
  {
    spendKey: "memberSpentTotalOnBonus",
    payoutKey: "bonusPayoutTotal",
    labelIntl: {
      id: "payout.member.bonus_label",
      defaultMessage: "Bonus",
    },
  },
];

export const PAYOUT_FILTER_MAP = {
  [PayoutFilterMainType.ALL]: [],
  [PayoutFilterMainType.BONUS_PAYOUT]: [
    "member_referral",
    "bounty_referral",
    "model_referral",
  ],
  [PayoutFilterMainType.TIER_PAYOUT]: [
    "tips",
    "buzz",
    "whisper",
    "cam2cam",
    "private_chat",
    "party_chat",
    "nude_chat",
    "voyeur_chat",
    "virtual_gift",
    "bonus_referred_fan_clubs",
  ],
  [PayoutFilterMainType.FLAT_PAYOUT]: [
    "photo",
    "video",
    "album",
    "recorded_show",
    "fan_club_membership",
  ],
  [PayoutFilterMainType.CREDIT_REFUND]: [],
};

export const PAYOUT_FILTER_MAP_CATEGORY_KEYS = Object.keys(
  PAYOUT_FILTER_MAP
).reduce((acc, key) => {
  return {
    ...acc,
    [key]: PAYOUT_FILTER_MAP[key].sort().join(","),
  };
}, {});

export const PAYOUT_FILTER_PRODUCT_MAPPINGS = {
  member_referral: ["member_bounty_referral"],
  model_referral: ["model_bounty_referral"],
  bounty_referral: [],
  tips: ["tip"],
  buzz: ["buzz", "super_buzz"],
  whisper: ["whisper"],
  cam2cam: ["cam2cam"],
  private_chat: ["private_chat"],
  party_chat: ["party_chat"],
  nude_chat: ["nude_chat"],
  voyeur_chat: ["voyeur_chat"],
  virtual_gift: ["virtual_gift"],
  bonus_referred_fan_clubs: [],
  photo: ["photo"],
  video: ["video"],
  album: ["album"],
  recorded_show: ["recorded_show"],
  fan_club_membership: ["fan_club_membership"],
};
