import { inject, observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import PricingStore from "../_stores/pricing/PricingStore";
import TailwindBox from "library/components/_tailwind/box";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindIcon from "library/components/_tailwind/icon";
import { SHOWCASE_TARGET_CLASSNAMES } from "core/stores/showcase/const";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import BroadcastTapeButton from "../broadcast-tape-button";

interface TypeIfShowSelector {
  broadcastStore?: BroadcastStore;
  broadcastStreamStore?: BroadcastStreamStore;
  pricingStore?: PricingStore;
}

const BroadcastShowTypeSelector: FunctionComponent<
  TypeIfShowSelector & WrappedComponentProps
> = ({ broadcastStore, pricingStore }) => {
  const {
    showWheelOfFunSettingsPopover,
    showVideoSettingsPopover,
    broadcasterVariant,
  } = broadcastStore!;
  const { modelProducts } = pricingStore!;

  return (
    <TailwindFlex
      className={["BroadcastShowTypeSelector"]}
      alignItems={"items-center"}
      justifyContent={"justify-between"}
      padding={["pl-0.5"]}
      width={"w-auto"}>
      <TailwindFlex
        className={["BroadcastShowTypeSelector"]}
        alignItems={"items-center"}
        justifyContent={"justify-end"}>
        {broadcasterVariant !== "member-verification" && (
          <TailwindBox
            data-testid={"broadcast-wheel-of-fun-settings"}
            onClick={showWheelOfFunSettingsPopover}
            display={"inline-flex"}
            alignItems={"items-center"}
            className={[SHOWCASE_TARGET_CLASSNAMES.broadcastWheelOfFun]}>
            <TailwindIcon
              className={"text-lg"}
              name={
                modelProducts?.is_wheel_of_fun_allowed
                  ? "wheel-of-fun-active"
                  : "wheel-of-fun-inactive"
              }></TailwindIcon>
          </TailwindBox>
        )}
        <BroadcastTapeButton
          data-testid={"broadcast-settings-button"}
          settings={true}
          fullWidth={false}
          onClick={showVideoSettingsPopover}>
          <TailwindIcon
            fontSize={"text-lg"}
            padding={["px-0.5"]}
            name={"setting_side"}
          />
        </BroadcastTapeButton>
      </TailwindFlex>
    </TailwindFlex>
  );
};
export default injectIntl(
  inject(
    "broadcastStore",
    "broadcastStreamStore",
    "pricingStore"
  )(observer(BroadcastShowTypeSelector))
);
