import React, { useCallback } from "react";
import "./styles.scss";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { history } from "library/core/utility";
import { AppCommonRouteKey } from "core/stores/route/enums";
import { inject, observer } from "mobx-react";
import TailwindButton from "library/components/_tailwind/button";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import RouteStore from "core/stores/route/RouteStore";
import TailwindText from "library/components/_tailwind/text";
import ProfileStore from "common/my-page/stores/profile/ProfileStore";

type GoLiveButtonProps = {
  className?: string;
  layoutStore?: LayoutStore;
  routeStore?: RouteStore;
  profileStore?: ProfileStore;
};

const GoLiveButton: React.FunctionComponent<
  GoLiveButtonProps & WrappedComponentProps
> = ({ routeStore, profileStore }) => {
  const { getSiteRouteURL } = routeStore!;
  const { modelProfile } = profileStore!;

  const onGoLiveClick = useCallback(() => {
    const url = getSiteRouteURL(
      modelProfile?.flags?.opted_in_for_member_verification
        ? AppCommonRouteKey.broadcastMemberVerification
        : AppCommonRouteKey.broadcast,
      true
    );
    if (url) {
      history.push(url);
    }
  }, []);

  return (
    <TailwindButton
      data-testid={"go-live-button"}
      rounded={false}
      fullWidth={false}
      display={"flex"}
      backgroundColor={"bg-transparent"}
      alignItems={"items-center"}
      onClick={onGoLiveClick}
      leftIconProps={{
        name: "video_call",
        fontSize: "text-3xl",
        textColor: "text-white",
      }}
      size={"lg"}
      margin={["mr-2"]}
      className={["bg-no-repeat", "bg-contain", "bg-go-live-bg-color"]}
      style={{
        borderTopRightRadius: "2px",
        borderBottomRightRadius: "20px",
        borderTopLeftRadius: "20px",
        borderBottomLeftRadius: "2px",
      }}
      bold>
      <TailwindText
        textColor='text-white'
        fontSize='text-lg'
        fontWeight='font-bold'
        className='text-white'>
        Go Live
      </TailwindText>
    </TailwindButton>
  );
};
export default injectIntl(
  inject("layoutStore", "routeStore", "profileStore")(observer(GoLiveButton))
);
