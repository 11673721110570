export enum LoginErrorCodes {
  SUSPENDED = "account_suspended",
  SUSPENDED_TWICE = "account_suspended_twice",
  CANCELLED = "account_cancelled",
  CLOSED = "account_closed",
}

export enum RegistrationStepShorthand {
  //do not change, if you do notify BE. they use these to open up popup from emails
  document_upload = "document_upload",
  agreement = "agreement",
  summary = "summary",
}

export enum RegistrationStep {
  AUTH_CREDENTIALS = "auth_credentials",
  MODEL_ACCOUNT_INFO = "model_account_info",
  MODEL_DOCUMENT_SELECTION_AND_UPLOAD = "model_document_upload",
  MODEL_CERTIFICATION_UPLOAD = "model_certification_upload",
  MODEL_AGREEMENT = "model_agreement",
  MODEL_SUMMARY = "model_summary",
  STUDIO_ACCOUNT_INFO = "studio_account_info",
  STUDIO_DOCUMENT_SELECTION_AND_UPLOAD = "studio_document_upload",
  STUDIO_AGREEMENT = "studio_agreement",
  STUDIO_SUMMARY = "studio_summary",
}
