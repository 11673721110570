import React from "react";
import "./styles.scss";
import classnames from "classnames";

type ThreePeopleIconProps = {
  className?: string;
};

const ThreePeopleIcon: React.ComponentType<ThreePeopleIconProps> = ({
  className,
}) => (
  <span className={classnames("ThreePeopleIcon", className && className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.2"
      overflow="visible"
      preserveAspectRatio="none"
      viewBox="0 0 62 62"
      xmlSpace="preserve"
      y="0px"
      x="0px"
      id="Layer_1_1606361739342"
    >
      <g transform="translate(1, 1)">
        <circle r="6.2" cy="17.7" cx="13.4" vectorEffect="non-scaling-stroke" />
        <circle r="6.2" cy="17.7" cx="46.6" vectorEffect="non-scaling-stroke" />
        <g>
          <circle r="6.9" cy="24.9" cx={30} vectorEffect="non-scaling-stroke" />
          <path
            d="M30,34.6c-7.7,0-13.9,3.1-13.9,6.9v6.9h27.7v-6.9C43.9,37.7,37.7,34.6,30,34.6z"
            vectorEffect="non-scaling-stroke"
          />
        </g>
        <path
          d="M14,38.7c1.4-3,5-5.3,9.9-6.3c-1.5-1.2-2.7-2.9-3.2-4.8c-2.1-0.8-4.6-1.2-7.3-1.2C6.5,26.4,1,29.1,1,32.6v6.2h12.4H14z"
          vectorEffect="non-scaling-stroke"
        />
        <path
          d="M46.6,26.4c-2.8,0-5.3,0.5-7.3,1.2c-0.6,1.9-1.7,3.6-3.2,4.8c4.9,1,8.5,3.4,9.9,6.3h0.6H59v-6.2  C59,29.1,53.5,26.4,46.6,26.4z"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </svg>
  </span>
);

export default ThreePeopleIcon;
