import React, { useMemo } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindDropdown, {
  TailwindDropdownItem,
} from "library/components/_tailwind/dropdown";

import MyFansStore from "containers/model/my-fans/stores/my-fans/MyFansStore";
import { inject, observer } from "mobx-react";
import TailwindText from "library/components/_tailwind/text";

type Props = {
  myFansStore?: MyFansStore;
};

const MyFansMemberTransactionsTablePagination: React.ComponentType<Props> = ({
  myFansStore,
}) => {
  const {
    selectedMemberId,
    sortedSelectedMemberTransactions,
    loadingMemberTransactions,
    memberTransactionsPageNumber,
    setMemberTransactionsPageNumber,
    selectedMemberTransactionsPagination,
  } = myFansStore!;

  const dropdownPages = useMemo(() => {
    const options: TailwindDropdownItem[] = [];
    for (
      let i = 1, l = selectedMemberTransactionsPagination.totalPages;
      i <= l;
      i++
    ) {
      options.push({
        label: i.toString(),
        value: i,
      });
    }

    return options;
  }, [selectedMemberTransactionsPagination.totalPages]);

  if (loadingMemberTransactions) {
    return <></>;
  }

  if (!selectedMemberId || !sortedSelectedMemberTransactions.length) {
    return <></>;
  }

  return (
    <TailwindFlex
      backgroundColor={"bg-table-primary-bg-color"}
      padding={["p-2", "pr-10", "pl-10"]}
      height={"h-10"}
      flexDirection={"flex-row"}
      justifyContent={"justify-between"}
      justifyItems={"justify-items-center"}
      borderRadius={"rounded-b-xl"}>
      <TailwindFlex>
        <TailwindText margin={["mr-6"]}>
          Transactions: {selectedMemberTransactionsPagination.total}
        </TailwindText>
      </TailwindFlex>
      <TailwindFlex
        width={"w-36"}
        flexDirection={"flex-row"}
        justifyContent={"justify-between"}>
        <TailwindText margin={["mr-2"]}>Page:</TailwindText>
        <TailwindDropdown
          fullHeight={true}
          fullWidth={false}
          width={"w-20"}
          items={dropdownPages}
          backgroundColor={"bg-primary-bg-color"}
          placeholder={""}
          value={memberTransactionsPageNumber}
          name={"member-transactions-page"}
          onChange={value => setMemberTransactionsPageNumber(value as number)}
        />
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default inject("myFansStore")(
  observer(MyFansMemberTransactionsTablePagination)
);
