import React from "react";
import CamsLandingLayout from "./cams";
import { userRole } from "library/core/stores/consts";

type Props = {
  showHeader?: boolean;
};

const ACCESS_MODEL_LANDING_LAYOUT_TEMPORARILY = true;

const LandingLayout: React.ComponentType<Props> = ({ showHeader = true, children }) => {
  if (userRole === "model" || userRole === "studio" || ACCESS_MODEL_LANDING_LAYOUT_TEMPORARILY) {
    return <CamsLandingLayout showHeader={showHeader}>{children}</CamsLandingLayout>;
  }

  return null;
};

export default LandingLayout;
