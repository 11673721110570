import React, { useCallback, useState } from "react";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import MediaManagerStore from "./store/MediaManagerStore";
import { MyPagePromptMode } from "./store/enums";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindHeading from "library/components/_tailwind/heading";
import TailwindButton from "library/components/_tailwind/button";
import TailwindBox from "library/components/_tailwind/box";

type Props = {
  mediaManagerStore?: MediaManagerStore;
};

const MyPagePrompt: React.ComponentType<Props & WrappedComponentProps> = ({
  mediaManagerStore,
  intl,
}) => {
  const {
    promptMode,
    promptRejectCb,
    promptResolveCb,
    /*activeAlbumFilter,
    setActiveAlbumFilter,*/
    /*activeStatusFilter,
    setActiveStatusFilter,
    activeMediaSorting,
    setActiveMediaSorting,*/
    //setIsPromptOpen,
  } = mediaManagerStore!;

  const [isPromptInProgress, setIsPromptInProgress] = useState<boolean>(false);

  const onPromptDecline = useCallback(async () => {
    if (promptRejectCb) {
      setIsPromptInProgress(true);
      await promptRejectCb();
      setIsPromptInProgress(false);
    }
  }, [promptRejectCb]);

  const onPromptAgree = useCallback(async () => {
    if (promptResolveCb) {
      setIsPromptInProgress(true);
      await promptResolveCb();
      setIsPromptInProgress(false);
    }
  }, [promptResolveCb]);

  return (
    <TailwindFlex
      width={"w-192"}
      md={"w-11/12"}
      sm={"w-full"}
      height={"h-full"}
      alignItems={"items-center"}
      justifyContent={"justify-center"}>
      <TailwindFlex
        justifyContent={"justify-center"}
        padding={["p-10"]}
        flexDirection={"flex-col"}
        backgroundColor={"bg-primary-bg-color"}
        borderRadius={"rounded-3xl"}>
        {promptMode !== MyPagePromptMode.SORT &&
          promptMode !== MyPagePromptMode.FILTER && (
            <React.Fragment>
              <TailwindFlex justifyContent={"justify-center"} margin={["mb-4"]}>
                <TailwindHeading level={5} textAlign={"text-center"}>
                  {(promptMode === MyPagePromptMode.EXIT ||
                    promptMode === MyPagePromptMode.SWITCH) &&
                    intl.formatMessage({
                      id: "myPage.prompt.keepCurrentMedia",
                      defaultMessage:
                        "Would you like to save and keep your current media?",
                    })}
                  {promptMode === MyPagePromptMode.DELETE &&
                    intl.formatMessage({
                      id: "myPage.prompt.deleteCurrentMedia",
                      defaultMessage:
                        "Are you sure you want to delete this media?",
                    })}
                  {promptMode === MyPagePromptMode.DELETE_PREMIUM &&
                    intl.formatMessage({
                      id: "myPage.prompt.deletePremiumMedia",
                      defaultMessage:
                        "Are you sure you want to delete this media? Customers who have purchased access might ask for a refund which will be deducted from your earnings.",
                    })}
                  {promptMode === MyPagePromptMode.VIDEOALBUM &&
                    intl.formatMessage({
                      id: "myPage.prompt.movedToVideos",
                      defaultMessage:
                        "Your video files will be added to your Custom Videos album.",
                    })}
                </TailwindHeading>
              </TailwindFlex>
              <TailwindFlex justifyContent={"justify-around"}>
                {promptMode !== MyPagePromptMode.VIDEOALBUM && (
                  <TailwindFlex
                    width={"w-3/12"}
                    md={"w-1/3"}
                    justifyContent={"justify-center"}>
                    <TailwindButton
                      onClick={onPromptDecline}
                      fullWidth
                      rounded={false}
                      showSpinner={isPromptInProgress}
                      disabled={isPromptInProgress}
                      textProps={{
                        textColor: "text-main-color",
                        textTransform: "uppercase",
                      }}
                      display={"flex"}
                      backgroundColor={"bg-transparent"}
                      borderColor={"border-modal-button-color"}
                      borderWidth={["border-2"]}
                      alignItems={"items-center"}
                      justifyContent={"justify-center"}>
                      {(promptMode === MyPagePromptMode.DELETE ||
                        promptMode === MyPagePromptMode.DELETE_PREMIUM) &&
                        intl.formatMessage({
                          id: "verbiage.cancel",
                          defaultMessage: "Cancel",
                        })}
                      {(promptMode === MyPagePromptMode.SWITCH ||
                        promptMode === MyPagePromptMode.EXIT) &&
                        intl.formatMessage({
                          id: "verbiage.discard",
                          defaultMessage: "Discard",
                        })}
                    </TailwindButton>
                  </TailwindFlex>
                )}
                <TailwindFlex
                  width={"w-3/12"}
                  md={"w-1/3"}
                  justifyContent={"justify-center"}>
                  <TailwindButton
                    onClick={onPromptAgree}
                    showSpinner={isPromptInProgress}
                    disabled={isPromptInProgress}
                    rounded={false}
                    backgroundColor={"bg-modal-button-color"}
                    display={"flex"}
                    alignItems={"items-center"}
                    justifyContent={"justify-center"}
                    textProps={{
                      textColor: "text-white",
                      textTransform: "uppercase",
                    }}>
                    {(promptMode === MyPagePromptMode.SWITCH ||
                      promptMode === MyPagePromptMode.EXIT) &&
                      intl.formatMessage({
                        id: "verbiage.save",
                        defaultMessage: "Save",
                      })}
                    {(promptMode === MyPagePromptMode.DELETE ||
                      promptMode === MyPagePromptMode.DELETE_PREMIUM) &&
                      intl.formatMessage({
                        id: "verbiage.delete",
                        defaultMessage: "Delete",
                      })}
                    {promptMode === MyPagePromptMode.VIDEOALBUM &&
                      intl.formatMessage({
                        id: "verbiage.ok",
                        defaultMessage: "Ok",
                      })}
                  </TailwindButton>
                </TailwindFlex>
              </TailwindFlex>
            </React.Fragment>
          )}

        {(promptMode === MyPagePromptMode.SORT ||
          promptMode === MyPagePromptMode.FILTER) && (
          <TailwindFlex
            className={["MyPagePrompt__filter-sort"]}
            width={"w-4/12"}>
            <TailwindBox width={"w-auto"} margin={["mr-2", "mb-2"]}>
              <h6 className={"uppercase"}>
                {promptMode === MyPagePromptMode.FILTER &&
                  intl.formatMessage({
                    id: "verbiage.filter",
                    defaultMessage: "Filter",
                  })}
                {promptMode === MyPagePromptMode.SORT &&
                  intl.formatMessage({
                    id: "verbiage.sort",
                    defaultMessage: "Sort",
                  })}
              </h6>
            </TailwindBox>
            {/*{promptMode === MyPagePromptMode.FILTER && (
              <React.Fragment>
                <TailwindFlex width={"w-full"}>
                    <MyPageGenericPill
                      isActive={activeAlbumFilter === 'all'}
                      onClick={() => setActiveAlbumFilter("all")}
                      columnSize={3}
                    >
                      {intl.formatMessage({id: 'verbiage.all-media', defaultMessage: 'All Media'})}
                    </MyPageGenericPill>
                    <MyPageGenericPill
                      isActive={activeAlbumFilter === 'photos'}
                      onClick={() => setActiveAlbumFilter("photos")}
                      columnSize={3}
                    >
                      {intl.formatMessage({id: 'verbiage.photos', defaultMessage: 'Photos'})}
                    </MyPageGenericPill>
                    <MyPageGenericPill
                      isActive={activeAlbumFilter === 'videos'}
                      onClick={() => setActiveAlbumFilter("videos")}
                      columnSize={3}
                    >
                      {intl.formatMessage({id: 'verbiage.videos', defaultMessage: 'Videos'})}
                    </MyPageGenericPill>
                    <MyPageGenericPill
                      isActive={activeAlbumFilter === 'albums'}
                      onClick={() => setActiveAlbumFilter("albums")}
                      columnSize={3}
                    >
                      {intl.formatMessage({id: 'verbiage.albums', defaultMessage: 'Albums'})}
                    </MyPageGenericPill>
                  </TailwindFlex>
                <TailwindFlex className={["mt-2"]}>
                  <MyPageGenericPill
                    isActive={activeStatusFilter === "all"}
                    onClick={() => setActiveStatusFilter("all")}
                    columnSize={4}
                  >
                    {intl.formatMessage({
                      id: "verbiage.all-statuses",
                      defaultMessage: "All Statuses",
                    })}
                  </MyPageGenericPill>
                  <MyPageGenericPill
                    isActive={activeStatusFilter === "pending_approval"}
                    onClick={() => setActiveStatusFilter("pending_approval")}
                    columnSize={4}
                  >
                    {intl.formatMessage({
                      id: "verbiage.pending-review",
                      defaultMessage: "Pending Review",
                    })}
                  </MyPageGenericPill>
                  <MyPageGenericPill
                    isActive={activeStatusFilter === "approved"}
                    onClick={() => setActiveStatusFilter("approved")}
                    columnSize={4}
                  >
                    {intl.formatMessage({
                      id: "verbiage.approved",
                      defaultMessage: "Approved",
                    })}
                  </MyPageGenericPill>
                </TailwindFlex>
              </React.Fragment>
            )}*/}
            {/*{promptMode === MyPagePromptMode.SORT && (
              <TailwindFlex width={"w-full"} spacing={2}>
                <MyPageGenericPill
                  isActive={activeMediaSorting === "name-ascending"}
                  onClick={() => setActiveMediaSorting("name-ascending")}
                  columnSize={3}
                >
                  {intl.formatMessage({
                    id: "verbiage.sort-alphabetical-a-z",
                    defaultMessage: "A-Z",
                  })}
                </MyPageGenericPill>
                <MyPageGenericPill
                  isActive={activeMediaSorting === "name-descending"}
                  onClick={() => setActiveMediaSorting("name-descending")}
                  columnSize={3}
                >
                  {intl.formatMessage({
                    id: "verbiage.sort-alphabetical-z-a",
                    defaultMessage: "Z-A",
                  })}
                </MyPageGenericPill>
                <MyPageGenericPill
                  isActive={activeMediaSorting === "latest"}
                  onClick={() => setActiveMediaSorting("latest")}
                  columnSize={3}
                >
                  {intl.formatMessage({
                    id: "verbiage.latest",
                    defaultMessage: "Latest",
                  })}
                </MyPageGenericPill>
                <MyPageGenericPill
                  isActive={activeMediaSorting === "oldest"}
                  onClick={() => setActiveMediaSorting("oldest")}
                  columnSize={3}
                >
                  {intl.formatMessage({
                    id: "verbiage.oldest",
                    defaultMessage: "Oldest",
                  })}
                </MyPageGenericPill>
              </TailwindFlex>
            )}*/}
          </TailwindFlex>
        )}
      </TailwindFlex>
    </TailwindFlex>
  );
};

export default injectIntl(inject("mediaManagerStore")(observer(MyPagePrompt)));
