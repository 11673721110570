import { makeAutoObservable } from "mobx";
import validator from "validator";
import differenceInYears from "date-fns/differenceInYears";
import { formatter, logger } from "../../utility";
import {
  ASTROLOGICAL_SIGN_LIST,
  BREAST_SIZE_LIST,
  COUNTRY_LIST,
  DOCUMENT_TYPES,
  GENDER_VALUE_OPTIONS,
  PENIS_SIZE_LIST,
  US_DOCUMENT_TYPES,
} from "../../constants";
import { FileRejection } from "react-dropzone";
import CONFIG from "../../config";

import { ValidateOptions } from "./types";
import { HashMap, ICountry } from "../../interfaces";
import { snackbarStore } from "../snackbar/SnackbarStore";
import { SnackbarVariants } from "../snackbar/enums";

export type Gender = "male" | "female" | "trans";

export default class ValidationStore {
  public errors: {
    [key: string]: string | false | 0 | "" | null | undefined;
  } = {};
  private countries: ICountry = {};

  constructor() {
    makeAutoObservable(this);
  }

  public setCountries = (countries: ICountry) => {
    this.countries = countries;
  };

  public validateMultiple = (
    inputs: string[],
    formValues: Object,
    options?: ValidateOptions,
    validateOneByOne = true
  ): boolean => {
    if (!validateOneByOne) {
      return inputs
        .map(fieldName => {
          return this.validate(
            fieldName,
            formValues[fieldName],
            options ? options[fieldName] : undefined
          );
        })
        .every(isValid => isValid === true);
    }

    return inputs.reduce(
      (validity: boolean, fieldName: string) =>
        validity &&
        this.validate(
          fieldName,
          formValues[fieldName],
          options ? options[fieldName] : undefined
        ),
      true
    );
  };

  public validateMultipleObj = (
    data: {},
    options?: HashMap<ValidateOptions>,
    validateOneByOne: boolean = true
  ) => {
    return this.validateMultiple(
      Object.keys(data),
      data,
      options,
      validateOneByOne
    );
  };

  isEmpty = (value: any) => {
    return typeof value === "string" && value.trim() === "";
  };

  public validate = (
    input: string,
    value: any = "",
    options?: ValidateOptions
  ): boolean => {
    if (input) {
      const isEmpty =
        options && options.checkForEmptyString
          ? !value || this.isEmpty(value)
          : !value;
      const validatorFunction = this.validators[input];
      const isValid =
        validatorFunction === undefined ||
        validatorFunction(value, options?.gender);

      if ((!options?.canBeEmpty && isEmpty) || (!isEmpty && !isValid)) {
        this.setErrorMessage(input, isEmpty, isValid, options?.dynamicInput);
        return false;
      } else {
        this.clearError(input);
        return true;
      }
    }
    return false;
  };

  public clearErrors = (): void => {
    this.errors = {};
  };

  public clearError = (key: string): void => {
    delete this.errors[key];
  };

  public setError = (key: string, error: string): void => {
    this.errors[key] = error;
  };

  private updateErrorsState = (newErrors: {}) => {
    this.errors = {
      ...this.errors,
      ...newErrors,
    };
  };

  public storeBackendErrors = (e, errorArea?: string, errorKeys?: string[]) => {
    const newErrors = this.formatBackendErrors(e, errorArea, errorKeys);
    this.updateErrorsState(newErrors);
  };

  public showNotificationOnRejectedFiles = (
    rejections: FileRejection[],
    maxFiles: number,
    fileTypes: string
  ) => {
    if (rejections[0] && rejections[0].errors && rejections[0].errors.length) {
      const firstError = rejections[0].errors[0];
      if (firstError.code === "too-many-files") {
        snackbarStore.enqueueSnackbar({
          message: {
            id: "messages.warning.maximum-files",
            default: "You can only upload {quantity} file(s)",
            parameters: {
              quantity: maxFiles,
            },
          },
          variant: SnackbarVariants.WARNING,
        });
      } else if (firstError.code === "file-too-large") {
        snackbarStore.enqueueSnackbar({
          message: {
            id: "messages.warning.maximum-file-size",
            default:
              "You can only upload file(s) with maximum size of {size}s.",
            parameters: {
              size: formatter.formatBytes(CONFIG.maximumImageSize.size),
            },
          },
          variant: SnackbarVariants.WARNING,
        });
      } else {
        snackbarStore.enqueueSnackbar({
          message: {
            id: "messages.warning.invalid-filetypes",
            default:
              "Some of the files you uploaded have invalid file type. Supported file types: {filetypes}",
            parameters: {
              filetypes: fileTypes,
            },
          },
          variant: SnackbarVariants.WARNING,
        });
      }
    }
  };

  private usernameValidator = (value: string) => {
    return (
      value.length > 3 &&
      new RegExp(/^[A-Za-z0-9]+(?:[_][A-Za-z0-9]+)*$/).test(value)
    );
  };

  private get countryList() {
    if (Object.keys(this.countries).length > 0) {
      return this.countries;
    } else {
      return COUNTRY_LIST;
    }
  }

  private validators: {
    [key: string]: (value: any, gender?: Gender) => boolean;
  } = {
    age: (value: number) => this.isBetweenRange(value, 18, 120),
    astrological_sign: (value: string) =>
      !!(value && value in ASTROLOGICAL_SIGN_LIST),
    captchaKey: (value: string) => value !== "",
    sexual_orientation: (value: string, _gender?: Gender) => {
      return value !== "";
    },
    body_type: (value: string, _gender?: Gender) => {
      return value !== "";
    },
    breast_size: (value: string, gender) =>
      gender === "male" || (!!value && value in BREAST_SIZE_LIST),
    penis_size: (value: string, gender) =>
      gender === "female" || value in PENIS_SIZE_LIST,
    languages: (value: string[]) => value.length > 0,
    description: (value: string) => value.length > 0 && value.length <= 1000,
    non_nude_profile_photo: value => validator.isURL(value),
    nude_profile_photo: value => validator.isURL(value),
    password: (value: string) =>
      new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/).test(value),
    passwordConfirm: (passwords: { new: string; newConfirm: string }) =>
      passwords.new === passwords.newConfirm,
    new_password: (value: string) =>
      new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/).test(value),
    password_login: (value: string) => value.length > 0,
    username: this.usernameValidator,
    email: (value: string) => validator.isEmail(value),
    gender: (value: any) => value in GENDER_VALUE_OPTIONS,
    birthdate: (value: any) =>
      !isNaN(Date.parse(value)) &&
      differenceInYears(new Date(), new Date(value)) > 17,
    emailOrUsername: (value: any) =>
      validator.isEmail(value) || this.usernameValidator(value),
    reward: (value: string) => value.length <= 35,
    document_type: (value: string) =>
      value in DOCUMENT_TYPES ||
      value in US_DOCUMENT_TYPES ||
      value === "studio",
    issue_country: (value: string) => value in this.countryList,
    address: (value: string) => value.length > 0 && value.length <= 100,
    city: (value: string) => value.length > 0 && value.length <= 100,
    state: (value: string) => value.length > 0 && value.length <= 100,
    zip: (value: string) => value.length > 0 && value.length <= 10,
    country: (value: string) => value.length > 0,
    expire_date: (value: string) => !isNaN(Date.parse(value)),
    document_front_key: (value: string) => value.length > 0,
    document_back_key: (value: string) => value.length > 0,
    document_verification_key: (value: string) => value.length > 0,
    signature: (value: string) => value.length > 0,
    zip_code: (value: string) => value.length > 0,
    phone_number: (value: string) => value.length > 0,
    promo_code: (value: string) => new RegExp(/^[A-Za-z0-9]+$/).test(value),
    matchingString: (text: { new: string; newConfirm: string }) =>
      text.new === text.newConfirm,
  };

  // connect these to translate function of react-intl
  private ERROR_MESSAGES = {
    empty: {
      default: "Cannot be left blank",
      age: "Age cannot be left blank",
      astrological_sign: "Astrological Sign cannot be left blank",
      sexual_orientation: "Sexual Orientation cannot be left blank",
      body_type: "Body Type cannot be left blank",
      breast_size: "Breast Size cannot be left blank",
      penis_size: "Penis Size cannot be left blank",
      languages: "Language cannot be left blank",
      description: "Description cannot be left blank",
      non_nude_profile_photo: "Non-Nude Profile Photo cannot be left blank",
      nude_profile_photo: "Nude Profile Photo cannot be left blank",
      email: "E-mail cannot be left blank",
      username: "Username cannot be left blank",
      password: "Password cannot be left blank",
      password_login: "Password cannot be left blank",
      new_password: "Password cannot be left blank",
      gender: "Gender cannot be left blank",
      emailOrUsername: "Email or Username cannot be left blank",
      reward: "This field is required",
      issue_country: "This field is required",
      document_type: "This field is required",
      address: "Address cannot be left blank",
      city: "City cannot be left blank",
      state: "State cannot be left blank",
      zipCode: "Zip Code cannot be left blank",
      expire_date: "This field is required",
      document_front_key: "This field is required",
      document_back_key: "This field is required",
      document_verification_key: "This field is required",
      zip_code: "This field is required",
      phone_number: "This field is required",
      birthdate: "This field is required",
      other_name: "This field is required",
      matchingString: "This field is required",
    },
    invalid: {
      default: "Invalid Entry",
      age: "Age must be between 18 and 120",
      astrological_sign: "Astrological Sign must be one of the provided fields",
      sexual_orientation:
        "Sexual Orientation must be one of the provided fields",
      body_type: "Body Type must be one of the provided fields",
      breast_size: "Breast Size must be one of the provided fields",
      penis_size: "Penis Size must be one of the provided fields",
      languages: "Language must be one of the provided fields",
      description: "Description cannot be more than 1000 characters",
      non_nude_profile_photo:
        "Non-Nude Profile Photo must be uploaded or chosen from gallery",
      nude_profile_photo:
        "Nude Profile Photo must be uploaded or chosen from gallery",
      email: "E-mail must be valid",
      username:
        "Username should be 4-16 characters (a-z, A-Z, 0-9, or underscore)",
      password:
        "Password needs to contain 8 characters with at least one number, one uppercase and one lowercase letter",
      passwordConfirm: "Passwords have to match",
      new_password:
        "Password needs to contain 8 characters with at least one number, one uppercase and one lowercase letter",
      password_login: "Password cannot be left blank",
      gender: "Gender must be valid",
      email_or_password: "E-mail or password is invalid",
      emailOrUsername: "Invalid Email or Username",
      reward: "Reward text cannot be more than 35 characters",
      issue_country: "Invalid issue country",
      document_type: "Invalid document type",
      expire_date: "Expire date invalid",
      document_front_key: "Invalid entry",
      document_back_key: "Invalid entry",
      document_verification_key: "Invalid entry",
      zip_code: "Invalid zip code",
      phone_number: "Invalid phone number",
      birthdate: "Invalid birthdate, you have to be 18+ to register!",
      promo_code: "Invalid referral code",
      matchingString: "Invalid entry, please try again",
    },
  };

  private formatBackendErrors(
    error,
    errorArea: string = "detail",
    errorKeys = ["non_field_errors"]
  ): {} {
    const backendErrors = error?.response?.data;
    const newErrors = {};
    if (!!backendErrors) {
      Object.keys(backendErrors).forEach(key => {
        const beErrors = backendErrors[key];
        const newKey =
          errorKeys.includes(key) || key === "detail" ? errorArea : key;
        const beErrorsAreArray = Array.isArray(beErrors);
        newErrors[newKey] = beErrorsAreArray ? beErrors.join(", ") : beErrors;
        if (key === "detail") {
          logger.log("Call Being done incorrectly");
        } else if (!beErrorsAreArray) {
          logger.log("Backend Error Response Incorrect for ", error);
        }
      });
    } else {
      newErrors[errorArea] =
        "Something unexpected happened, please try again later";
    }
    return newErrors;
  }

  public setErrorMessage = (
    input: string,
    isEmpty: boolean,
    isValid: boolean,
    dynamicInput?: string,
    customErrorMessage?: string
  ) => {
    this.updateErrorsState({
      [dynamicInput || input]:
        this.getErrorMessage(input, isEmpty, isValid) || customErrorMessage,
    });
  };

  private getEmptyMessage = property =>
    property in this.ERROR_MESSAGES.empty
      ? this.ERROR_MESSAGES.empty[property]
      : this.ERROR_MESSAGES.empty["default"];
  private getInvalidMessage = property =>
    property in this.ERROR_MESSAGES.invalid
      ? this.ERROR_MESSAGES.invalid[property]
      : this.ERROR_MESSAGES.invalid["default"];
  private getErrorMessage = (
    property: string,
    isEmpty: boolean,
    isValid: boolean
  ) => {
    return isValid && !isEmpty
      ? false
      : isEmpty
      ? this.getEmptyMessage(property)
      : this.getInvalidMessage(property);
  };

  private isBetweenRange(value: number, min: number, max: number) {
    return value >= min && value <= max;
  }
}

export const validationStore = new ValidationStore();
