import { inject, observer } from "mobx-react";
import React, { useCallback } from "react";
import Modal from "library/components/modal";
import TailwindCard from "library/components/_tailwind/card";
import TailwindButton from "library/components/_tailwind/button";
import TailwindFlex from "library/components/_tailwind/flex";
import BroadcastStrategy from "./_stores/BroadcastStrategy";
import NodeChatStore from "./_stores/nodechat/NodeChatStore";
import LanguageStore from "library/core/stores/language/LanguageStore";

interface Props {
  nodeChatStore?: NodeChatStore;
  broadcastStrategy?: BroadcastStrategy;
  languageStore?: LanguageStore;
}

const BroadcastPrivateMemberLeftConfirmationModal: React.FunctionComponent<Props> =
  ({ nodeChatStore, 
    broadcastStrategy,
    languageStore
  }) => {
    const { intl } = languageStore!;
    const { disconnect } = broadcastStrategy!;

    const {
      shouldShowPrivatememberLeftConfirmation,
      setShouldShowPrivatememberLeftConfirmation,
    } = nodeChatStore!;

    const closeModal = useCallback(() => {
      setShouldShowPrivatememberLeftConfirmation(false);
    }, []);

    const onClickDisconnect = useCallback(async () => {
      setShouldShowPrivatememberLeftConfirmation(false);
      disconnect();
    }, []);

    return (
      <Modal
        open={shouldShowPrivatememberLeftConfirmation}
        showNativeCloseButton
        closeModal={closeModal}>
        <TailwindCard
          backgroundColor={"primary"}
          flexDirection={"flex-col"}
          height={"h-auto"}
          minHeight={"min-h-96"}
          maxHeight={"max-h-screen"}
          width={"w-192"}
          pseudoClasses={["md:w-full"]}
          padding={["p-4"]}
          display={"flex"}
          alignItems={"items-center"}
          justifyContent={"justify-center"}>

          {intl.formatMessage({
            id: "broadcast-private-member-left-confirmation.content",
            defaultMessage:
              "The private chat user has left, but there are still voyeurs paying. Would you like to stay?",
          })}

          <TailwindFlex justifyContent="justify-center" margin={["mt-8"]} flexDirection='flex-row'>
            <TailwindButton
              margin={["mr-8"]}
              fullWidth={false}
              backgroundColor={"bg-gray-100"}
              onClick={closeModal}>
              {intl.formatMessage({
                id: "common.continue",
                defaultMessage: "Continue",
              })}
            </TailwindButton>

            <TailwindButton
              fullWidth={false}
              backgroundColor={"primary"}
              onClick={onClickDisconnect}>
              {intl.formatMessage({
                id: "common.disconnect",
                defaultMessage: "Disconnect",
              })}
            </TailwindButton>
          </TailwindFlex>
        </TailwindCard>
      </Modal>
    );
  };

export default inject(
  "broadcastStrategy",
  "nodeChatStore",
  "languageStore"
)(observer(BroadcastPrivateMemberLeftConfirmationModal));
